import { AgoyTable } from '@agoy/document';
import {
  AnnualReportPart,
  AnnualReportSection,
  StatementSection,
} from '../../../document';

// Den löpande verksamheten
export interface CurrentOperations extends AnnualReportSection {
  // Kassaflöde före förändring av rörelsekapital
  beforeChangeWorkingCapital: AgoyTable;

  // Kassaflöde från förändringar av rörelsekapital
  changeWorkingCapital: AgoyTable;
}

// Investeringsverksamheten
export interface InvestmentActivities extends AnnualReportSection {
  table: AgoyTable;
}

// Finansieringsverksamheten
export interface FinancialActivities extends AnnualReportSection {
  table: AgoyTable;
}

export interface ChangeLiquidAssets extends AnnualReportSection {
  table: AgoyTable;
  otherAccounts: AgoyTable;
}

// Kassaflödesanalys
export type CashFlowStatement = AnnualReportPart & {
  section: AnnualReportSection & {
    main: AgoyTable;
    otherAccounts: AgoyTable;
  };
};

export type CashFlowStatementGeneral = CashFlowStatement | StatementSection; // StetementSection is for V2

const boolean = { type: 'boolean' as const };

// TODO: Move this to another file
export const statementSectionV2 = {
  type: 'part' as const,
  children: {
    active: boolean,
    section: {
      type: 'part' as const,
      children: {
        active: boolean,
        table: { type: 'table' as const },
      },
    },
  },
};

// TODO: Remove this
export const cashFlowStatement = {
  type: 'part' as const,
  children: {
    active: boolean,
    section: {
      type: 'part' as const,
      children: {
        active: boolean,
        main: { type: 'table' as const },
        otherAccounts: { type: 'table' as const },
      },
    },
  },
};

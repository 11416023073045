import { useMemo } from 'react';
import { CommonNotes } from '@agoy/annual-report-document';

const useNotesToRender = (
  notes: CommonNotes,
  additionalFilter: ([string, any]) => boolean = () => true
) => {
  const notesToRender = useMemo(() => {
    if (!notes) {
      return [];
    }

    const customNotes =
      notes.custom?.sections
        ?.map<[string, any]>((note, index) => [`custom-${index}`, note])
        .filter(([_, note]) => note) || [];

    const notesList = Object.entries(notes)
      .filter(
        // filter out that is not a note with external filters
        ([noteId, note]) =>
          noteId.includes('note') && additionalFilter([noteId, note])
      )
      .concat(customNotes);

    return (
      notesList
        // numbered notes
        .filter(([_, note]) => !!note.number.value)
        .sort((a, b) => a[1].number.value - b[1].number.value)
        .concat(
          // unnumbered notes (company active and custom notes)
          notesList.filter(([_, note]) => !note.number.value)
        )
    );
  }, [additionalFilter, notes]);

  return notesToRender;
};

export default useNotesToRender;

import React, { useEffect, Suspense } from 'react';
import { useRouteMatch } from 'react-router-dom';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';
import * as Sentry from '@sentry/react';

import { ClientsRouteParams } from 'routes/types';
import { setCurrentCustomer } from '_clients/redux/customer-view/actions';
import FallbackComponent from '_shared/components/ErrorBoundary';
import LoadingLogo from '_shared/components/LoadingLogo';
import ScrollTo from 'ScrollTo';
import NotFound from '_shared/components/NotFoundView';

const TaxDeclarationView = React.lazy(
  () => import('_tax/components/TaxDeclarationView/tax-declaration-person')
);

const LoadingFallback = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const PersonProduct = (): JSX.Element => {
  const dispatch = useDispatch();
  const {
    params: { program },
  } = useRouteMatch<ClientsRouteParams>();

  useEffect(() => {
    // Clear current customer when leaving
    return () => {
      dispatch(setCurrentCustomer(undefined, undefined));
    };
  }, [dispatch]);

  return (
    <ScrollTo>
      <Sentry.ErrorBoundary fallback={FallbackComponent}>
        <Suspense
          fallback={
            <LoadingFallback>
              <LoadingLogo size="medium" />
            </LoadingFallback>
          }
        >
          {program === 'taxdeclaration' && (
            <>
              <TaxDeclarationView />
            </>
          )}
          {program !== 'taxdeclaration' && <NotFound />}
        </Suspense>
      </Sentry.ErrorBoundary>
    </ScrollTo>
  );
};

export default (): JSX.Element => <PersonProduct />;

import React from 'react';
import { useHistory } from 'react-router-dom';

import SuccessPage from '_payment/components/pages/Success';
import { usePaymentStatus } from '_payment/payment-status/usePaymentStatus';

const WAIT_FOR_STRIPE_WEBHOOK = 4000;

const Container = () => {
  const history = useHistory();
  const { reloadPaymentStatus } = usePaymentStatus();

  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      reloadPaymentStatus().then(() => {
        setIsLoading(false);
      });
    }, WAIT_FOR_STRIPE_WEBHOOK);

    return () => clearTimeout(timer);
  }, []);

  const handleSelectRedirect = () => {
    history.push('/');
  };

  return (
    <SuccessPage
      isLoading={isLoading}
      handleSelectRedirect={handleSelectRedirect}
    />
  );
};

export default Container;

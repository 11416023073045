import { SET_CHECKLIST_DRAWER_EXPANDED } from '../action-types';

interface SetChecklistDrawerExpandedAction {
  type: typeof SET_CHECKLIST_DRAWER_EXPANDED;
  expanded: boolean;
}

export const setChecklistDrawerExpanded = (
  expanded: boolean
): SetChecklistDrawerExpandedAction => ({
  type: SET_CHECKLIST_DRAWER_EXPANDED,
  expanded,
});

export type DrawerActions = SetChecklistDrawerExpandedAction;

import {
  ref,
  value,
  or,
  id,
  sum,
  multiply,
  div,
  label,
  msg,
} from '@agoy/document';
import { AnnualReportType, ConfiguredFinancialYear } from '../../document';
import { FoundationManagementReport } from './types';

import { companyTaxPerYear } from '../../common/taxPercentagePerYear';
import { active, field, table } from '../../common/utils/util';

export const managementReportConfig = (
  reportType: AnnualReportType,
  transitionK3toK2: boolean,
  financialYears: ConfiguredFinancialYear[]
): FoundationManagementReport => {
  return {
    // Verksamheten for K2
    active: undefined,
    activities: {
      boardText: field(''),
      boardTextWithCeo: field(''),
      boardWithoutCeo: field(''),
      liquidatorText: field(''),
      currencyText: reportType === 'k2' ? field('') : field(''),
      heading: field(''),
      active: reportType === 'k2',
      activityLabel: field('Verksamheten'),
      generallyLabel: field('Allmänt om verksamheten'),
      generally: active(
        msg(
          'Stiftelsen har sitt säte i {city}.\n\nStiftelsens verksamhet består av {activityText}\n\nStiftelsen har under räkenskapsåret bytt företagsnamn till [namn].\n\nSpeciella förhållanden som i särskild grad berör stiftelsen är\n\nRättelse av fel från ett tidigare år\n\nStiftelsen är förvaltare av andra stiftelser med ändamål att främja den förvaltande stiftelsens verksamhet. Antalet förvaltade stiftelser uppgår till följande antal: [antal]. Deras totala egna kapital uppgår till: [summa].',
          {
            activityText: id('managementReport.hidden.activityText'),
            city: id('managementReport.hidden.city'),
          }
        )
      ),
      accountant: active(
        field(
          '[namn, byrå], som är auktoriserad redovisnings-/lönekonsult (FAR / SRF), har anlitats för biträde med följande tjänster: bokföring, lön, bokslut och årsredovisning.'
        )
      ),
      purposePromotionLabel: field('Främjande av ändamålet'),
      purposePromotion: active(
        field(
          'Organisationens ändamål är enligt stiftelseförordnandet att\n\nUppgift om hur stiftelsens ändamål har främjats under räkenskapsåret och på vilket sätt ändamålen har uppfyllts. Under räkenskapsåret har ändamålet främjats genom att\n\nStiftelsens ändamål har uppfyllts genom att'
        )
      ),
      eventsLabel: field('Väsentliga händelser under räkenskapsåret'),
      events: active(
        field(
          'Beslutade viktiga förändringar i verksamheten är\n\nFör stiftelsen viktiga externa faktorer som påverkat dess ställning och resultat är\n\nStiftelsen förutsätts inte längre fortsätta sin verksamhet'
        )
      ),
      otherLabel: field('Övrigt'),
      other: active(field('')),
    },
    // Verksamheten for K3
    business: {
      boardText: field(''),
      businessLabel: field('Verksamheten'),
      currencyText: reportType === 'k2' ? field('') : field(''),
      heading: field(''),
      active: reportType === 'k3',
      conditionsAndEventsLabel: field(
        'Viktiga förhållanden och väsentliga händelser'
      ),
      conditionsAndEvents: active(field('')),
      businessNatureLabel: field('Verksamhetens art och inriktning'),
      businessNature: active(field('')),
      businessChangesLabel: field('Viktiga förändringar i verksamheten'),
      businessChanges: active(field('')),
      externalFactorsLabel: field(
        'Viktiga externa faktorer som påverkat företagets ställning och resultat'
      ),
      externalFactors: active(field('')),
      specialCircumstancesLabel: field(
        'Speciella omständigheter som i särskild grad berör företaget'
      ),
      specialCircumstances: active(field('')),
      mainBusinessOwnersLabel: field(
        'Uppgift om ägare som har mer än tio procent av antalet andelar eller röster i företaget'
      ),
      mainBusinessOwners: active(field('')),
      limitedPartnershipsLabel: field('Komplementär i kommanditbolag'),
      limitedPartnerships: active(field('')),
      ownershipChangesLabel: field('Väsentliga förändringar i ägarstrukturen'),
      ownershipChanges: active(field('')),
      noSurvivalLabel: field(
        'Företaget tillämpar inte längre fortlevnadsprincipen'
      ),
      noSurvival: active(field('')),
      importantConditionsAndEventsLabel: field(
        'Övriga viktiga förhållanden och väsentliga händelser'
      ),
      importantConditionsAndEvents: active(field('')),
      controlBalanceSheetLabel: field('Kontrollbalansräkning'),
      controlBalanceSheet: active(field('')),
    },
    multiYearOverview: {
      active: true,
      multiYearLabel: field('Flerårsöversikt'),
      multiYearTextLabel: field('Kommentar flerårsöversikt'),
      multiYearText: active(field('')),
      table: table(
        'managementReport.multiYearOverview.table',
        { id: 'label', label: '' },
        ...financialYears.map((year, index) => ({
          id: `period${index}`,
          label: year.label,
        }))
      )
        .addRows((rows) =>
          rows
            .addRow(
              'netto',
              label('Huvudintäkter'),
              ref(id('incomeStatement.netSales.year')),
              ref(id('incomeStatement.netSales.previousYear')),
              ...financialYears.slice(2).map((year, index) => value(undefined))
            )
            .addRowActive(true)
            .addRow(
              'result',
              label('Årets resultat'),
              ref(id('incomeStatement.yearResult.year')),
              ref(id('incomeStatement.yearResult.previousYear')),
              ...financialYears.slice(2).map((year, index) => value(undefined))
            )
            .addRow(
              'solidity',
              label('Soliditet (%)'),
              // ("Summa eget kapital" / "Summa tillgångar"
              ref(
                or(
                  div(
                    or(id('balanceSheet.sumEquity.year'), 0),
                    id('balanceSheet.sumAssets.year')
                  ),
                  0
                )
              ),
              ref(
                or(
                  div(
                    sum(
                      or(id('balanceSheet.sumEquity.previousYear'), 0),
                      multiply(
                        sum(
                          1,
                          multiply(
                            -1,
                            companyTaxPerYear[
                              financialYears[1]?.end.substring(0, 4)
                            ]
                          )
                        ),
                        or(
                          id('balanceSheet.sumUntaxedReserves.previousYear'),
                          0
                        )
                      )
                    ),
                    id('balanceSheet.sumAssets.previousYear')
                  ),
                  0
                )
              ),
              ...financialYears.slice(2).map((year, index) => value(undefined))
            )
            .build()
        )
        .newRowTemplate(value(''), ...financialYears.map(() => value(0)))
        .build(),
    },
    hidden: {
      activityText: ref(id('settings.clientInformation.activityText')),
      city: ref(id('settings.clientInformation.city')),
    },
  };
};

export const managementReportReferences = (): Record<string, string> => ({
  solidity: sum(
    id('managementReport.multiYearOverview.table.nettoAndResult.*.value')
  ),
});

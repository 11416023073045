import React from 'react';
import {
  FieldError,
  FieldValues,
  UseFormRegisterReturn,
  UseFormSetValue,
} from 'react-hook-form';
import styled from '@emotion/styled';

import { SimplifiedTextfield } from '_shared/components/Inputs';
import { gray } from '@agoy/theme/src/colors';
import {
  ClientInformationDirector,
  ClientInformationField,
} from '@agoy/api-sdk-core';
import FieldWrapper from './FieldWrapper';

interface ClientCardInformationBlockProps extends UseFormRegisterReturn {
  setValue: UseFormSetValue<FieldValues>;
  source?: ClientInformationField['source'];
  timestamp?: ClientInformationField['timestamp'] | number;
  role?: string;
  user?: string;
  field: string;
  value?: string | number | boolean | ClientInformationDirector[];
  labelField?: string;
  error?: FieldError;
  placeholder?: string;
  isEditing?: boolean;
  disabled?: boolean;
}

const StyledSimplifiedTextfield = styled(SimplifiedTextfield)`
  padding: 0 ${(props) => (!props.editing ? props.theme.spacing(2) : 0)}px;
  .MuiOutlinedInput-root {
    border-radius: 8px;
  }
  .MuiOutlinedInput-notchedOutline {
    border-color: ${gray.HIGH};
  }
`;

const ClientCardInformationBlock = React.forwardRef(
  (props: ClientCardInformationBlockProps, ref) => {
    const {
      source,
      timestamp,
      role,
      user,
      field,
      labelField,
      error,
      isEditing,
      setValue,
      ...inputProps
    } = props;

    return (
      <FieldWrapper
        source={source}
        timestamp={timestamp}
        role={role}
        user={user}
        field={field}
        labelField={labelField}
        error={error}
        isEditing={isEditing}
      >
        <StyledSimplifiedTextfield
          {...inputProps}
          inputRef={ref}
          editing={isEditing}
          fullWidth
          setValue={setValue}
          field={field}
        />
      </FieldWrapper>
    );
  }
);

export default ClientCardInformationBlock;

import React from 'react';
import styled from '@emotion/styled';
import { Typography, Card, Tooltip, IconButton } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import { FieldError } from 'react-hook-form';
import Alert from '_shared/components/AlertBanners/Alert';
import { useIntl } from 'react-intl';
import { gray } from '@agoy/theme/src/colors';
import FormError from '_shared/components/Inputs/FormError';
import { ClientInformationField } from '@agoy/api-sdk-core';
import {
  calcSource,
  calcTimestamp,
  formatTimestamp,
  validation,
} from '../utils';
import { CommonText, SourceText, SourceWrapper, TimestampText } from './Common';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const TextFieldWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: ${(props) => props.theme.spacing(2)}px;
  align-items: stretch;
  border-radius: 12px;
  border: 1px solid ${gray.HIGH};
`;

const FieldLabel = styled(Typography)`
  font-weight: 700;
  color: #000000;
  font-size: 18x;
`;

const LabelWrapper = styled.div`
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 4px;
`;

const Required = styled(Typography)`
  color: #000000;
  font-size: 18x;
  font-weight: 700;
`;

const SourceContainer = styled.div`
  margin-top: ${(props) => props.theme.spacing(2)}px;
  padding: 0 ${(props) => props.theme.spacing(2)}px;
`;

export interface FieldWrapperProps {
  source?: ClientInformationField['source'];
  timestamp?: ClientInformationField['timestamp'] | number;
  role?: string;
  user?: string | undefined;
  field: string;
  toolTip?: string;
  labelField?: string;
  error?: FieldError;
  isEditing?: boolean;
  children?: React.ReactNode;
}

const FieldWrapper = (props: FieldWrapperProps) => {
  const { formatMessage } = useIntl();

  const {
    source,
    timestamp,
    role,
    user,
    field,
    toolTip,
    labelField,
    error,
    isEditing,
    children,
  } = props;

  const getString = (key: string) => {
    return formatMessage({ id: `clientInformation.${key}` });
  };

  const getErrorMessage = () => {
    const validationMessages = {
      validate: getString(`${field}.wrongFormat`),
      minLength: getString(`${field}.wrongFormat`),
      pattern: getString(`${field}.wrongFormat`),
      required: getString('required'),
    };

    return error ? validationMessages[error.type] : '';
  };

  const date = calcTimestamp(timestamp);

  return (
    <Container>
      {labelField && (
        <LabelWrapper>
          <FieldLabel>{getString(labelField)}</FieldLabel>
          {isEditing && validation[field]?.required && <Required>*</Required>}
          {toolTip && (
            <Tooltip title={toolTip} placement="right">
              <IconButton size="medium">
                <Info color="primary" />
              </IconButton>
            </Tooltip>
          )}
        </LabelWrapper>
      )}
      <TextFieldWrapper variant="outlined">
        {children}

        {role && (
          <CommonText>
            {formatMessage({ id: `clientInformation.${role}` })}
          </CommonText>
        )}

        {source !== 'user' && (
          <>
            <SourceWrapper>
              {user && (
                <Alert type="clientBanner" size="medium">
                  {formatMessage({ id: 'clientInformation.source' }, { date })}
                </Alert>
              )}
            </SourceWrapper>
          </>
        )}

        {source && timestamp && source === 'user' && (
          <SourceContainer>
            <SourceWrapper>
              <SourceText display="inline" source={source}>
                {calcSource(source)}{' '}
                {user &&
                  formatMessage({ id: 'clientInformation.fromUser' }, { user })}
              </SourceText>
            </SourceWrapper>

            <TimestampText display="inline">
              {formatTimestamp(timestamp)}
            </TimestampText>
          </SourceContainer>
        )}
      </TextFieldWrapper>
      {isEditing && <FormError>{getErrorMessage()}</FormError>}
    </Container>
  );
};

export default FieldWrapper;

import React from 'react';
import { Box } from '@material-ui/core';
import { NoteInputFieldSection } from '@agoy/annual-report-document';
import { numberValue, stringValue } from '@agoy/document';
import Comment from './Comment';
import FieldSection from './FieldSection';
import Header from './Header';
import { Note } from './Note';

type InputFieldNoteProps = {
  noteId: string;
  print?: boolean;
  title: string;
  isDigitalSubmission: boolean;
} & NoteInputFieldSection;

const InputFieldNote = ({
  noteId,
  active,
  number,
  inputField,
  comment,
  title,
  commentLabel,
  isDigitalSubmission,
}: InputFieldNoteProps) => {
  if (!active) return null;

  return (
    <Note id={noteId}>
      <Header noteId={noteId} noteNumber={numberValue(number)} title={title} />

      <Box mt={1} mb={1}>
        <FieldSection
          field={inputField}
          value={stringValue(inputField) || ''}
        />
      </Box>

      {/* Comment */}
      {!isDigitalSubmission && (
        <Comment
          field={comment}
          value={stringValue(comment) || ''}
          labelValue={stringValue(commentLabel) || ''}
        />
      )}
    </Note>
  );
};

export default InputFieldNote;

import React, { useCallback } from 'react';
import {
  AppBar as MuiAppBar,
  Divider as MuiDivider,
  Toolbar as MuiToolbar,
  Typography,
} from '@material-ui/core';
import { useSelector } from 'redux/reducers';
import { Route, useHistory } from 'react-router-dom';
import styled from '@emotion/styled';
import { formatFinancialYear } from '@agoy/common';
import CustomerButton from '../CustomerSubMenu/CustomerButton';
import SavingStatusButton from '../SavingStatusButton';

const AppBar = styled(MuiAppBar)`
  background: ${({ theme }) => theme.palette.grey[200]};
`;

const Toolbar = styled(MuiToolbar)`
  display: flex;
  justify-content: space-between;
  padding-top: ${({ theme }) => theme.spacing(1)}px;
  padding-bottom: ${({ theme }) => theme.spacing(1)}px;
`;

const Grouper = styled.div`
  display: flex;
  align-items: center;
`;

const Divider = styled(MuiDivider)`
  margin: ${({ theme }) => theme.spacing(1 / 2, 2, 1 / 2, 2)};
`;

export const TaxDeclarationCompaniesSubMenu = () => {
  const history = useHistory();
  const customerId = useSelector((state) => state.customerView.currentCustomer);
  const { currentFinancialYear } = useSelector((state) => state.customerView);
  const customer = useSelector((state) =>
    customerId ? state.customers[customerId] : undefined
  );

  const handleClick = useCallback(() => {
    history.push(`/clients/${customerId}/`);
  }, [history, customerId]);

  if (!customer) return null;
  return (
    <AppBar position="sticky" elevation={2}>
      <Toolbar>
        {customer && (
          <Route path="/clients/:clientId/:financialYear/:program">
            <Grouper>
              <CustomerButton
                logoUrl={customer.logo}
                name={customer.name}
                handleClick={handleClick}
              />
            </Grouper>
            <div style={{ flex: 1 }} />
            <>
              <Typography
                color="textPrimary"
                style={{ fontWeight: 500, fontSize: '1.25rem' }}
              >
                {currentFinancialYear &&
                  formatFinancialYear(currentFinancialYear)}
              </Typography>
              <Divider orientation="vertical" flexItem />
            </>
            <SavingStatusButton />
          </Route>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default TaxDeclarationCompaniesSubMenu;

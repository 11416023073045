import { AgoyDocument, AgoyDocumentChanges } from '@agoy/document';
import { balanceSheet } from '../common/types/balanceSheet';
import { confirmationCertificate } from '../common/types/confirmationCertificate';
import { incomeStatement } from '../common/types/incomeStatement';
import { manualSubmission } from '../common/types/manualSubmission';
import { settings } from '../common/types/settings';
import { signatures } from '../common/types/signatures';
import { foundationNotes } from './notes/types';
import { foundationManagementReport } from './management-report/types';

export const foundationContentDefinition = {
  type: 'document' as const,
  children: {
    settings,
    confirmationCertificate,
    managementReport: foundationManagementReport,
    incomeStatement,
    balanceSheet,
    notes: foundationNotes,
    manualSubmission,
    signatures,
  },
  documentType: 'foundation' as const,
  version: '1' as const,
};

export const getNoteNumbersOf = (notesStructure: any): string[] => {
  return Object.keys(notesStructure);
};

export type FoundationAnnualReport = AgoyDocument<
  typeof foundationContentDefinition
>;

export type FoundationAnnualReportStructure =
  typeof foundationContentDefinition;

export type FoundationAnnualReportChanges = AgoyDocumentChanges<
  typeof foundationContentDefinition
>;

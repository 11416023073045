import { setUiStatusType } from 'redux/actions/UI';
import { SET_UI_STATUS } from 'redux/actionsTypes';
import { initialState } from './types';

const initialUiState: initialState = {
  userAuthenticating: false,
  userSignedIn: false,
  fetchingOrganisation: false,
  fetchingOrgisationMembers: false,
  fetchingOrgisationInvites: false,
  fetchingClients: false,
  fetchingPersons: false,
  clientsPopulated: false,
  updatingConnections: false,
  updatingPaymentMethod: false,
};

export default (
  state: initialState = initialUiState,
  action: ReturnType<setUiStatusType>
): initialState => {
  switch (action.type) {
    case SET_UI_STATUS:
      return {
        ...state,
        ...action.state,
      };
    default:
      return state;
  }
};

/*
 * This is a GENERATED file, do not change manually!
 * Generated:
 * - from file k2-arsredovisning-2021-10-31-rev20230131_sv - Noter.csv
 * - Comments: nan
 */

import {
  value,
  ref,
  sumAllowNull,
  id,
  AgoyDocumentPart,
} from '@agoy/document';
import { IxbrlCell } from '../../../../../common';
import {
  field,
  ixbrlCell,
  ixbrlField,
  table,
} from '../../../../../common/utils/util';
import { previousYear, year } from '../../../../../common/utils/date-util';
import {
  sumAccountsIBs,
} from '../../../../../common/utils/accountSumsUtil';

const cell = { type: 'field' as const };
const boolean = { type: 'boolean' as const };
const type = { type: 'type' as const };
const number = { type: 'number' as const };

export const getNote2037K2v2 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null
): AgoyDocumentPart<typeof note2037K2v2ContentDefinition> => ({
  active: false,
  number: value(undefined),
  name: noteLabel('Förskott avseende immateriella anläggningstillgångar'),
  data: {
    active: true,
    ForskottImmateriellaAnlaggningstillgangarAbstract: {
      type: 'group',
      active: true,
      level: 0,
      title: field('Förskott avseende immateriella anläggningstillgångar'),
      table: table<IxbrlCell>(
        'notes.note37.data.ForskottImmateriellaAnlaggningstillgangarAbstract.table',
        {
          id: 'ForskottImmateriellaAnlaggningstillgangarAbstract',
          label: '',
          dataType: 'text',
          active: true,
        },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) ?? '',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) ?? '',
          dataType: 'numeric',
          active: true,
        }
      )
        .addRows((rows) => {
          rows
            .addRowWithType(
              'ForskottImmateriellaAnlaggningstillgangarAbstract',
              'hidden',
              undefined,
              undefined,
              undefined
            )
            .addSubRows((rows) => {
              rows.addRowWithType(
                'ForskottImmateriellaAnlaggningstillgangarBetaldaForskottIngaende',
                'sum_accounts',
                noteLabel('Ingående anskaffningsvärden'),
                ixbrlCell(ref(sumAccountsIBs('1080:1089', false)), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:ForskottImmateriellaAnlaggningstillgangarBetaldaForskott',
                  standardRubrik:
                    'Anskaffningsvärden förskott avseende immateriella anläggningstillgångar',
                  saldo: 'debit',
                  negateValue: false,
                }),
                ixbrlCell(
                  ref(sumAccountsIBs('1080:1089', false, 'previousYear')),
                  {
                    contextRef: 'balans2',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:ForskottImmateriellaAnlaggningstillgangarBetaldaForskott',
                    standardRubrik:
                      'Anskaffningsvärden förskott avseende immateriella anläggningstillgångar',
                    saldo: 'debit',
                    negateValue: false,
                  }
                )
              );
              rows
                .addRowWithType(
                  'ForskottForandringImmateriellaAnlaggningstillgangarAbstract',
                  'header',
                  noteLabel('Förändringar av anskaffningsvärden'),
                  undefined,
                  undefined
                )
                .addSubRows((rows) => {
                  rows.addRowWithType(
                    'PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarForandringarAnskaffningsvardenUtbetaldaForskott',
                    'row',
                    noteLabel('Utbetalda förskott'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarForandringarAnskaffningsvardenUtbetaldaForskott',
                      standardRubrik:
                        'Ökning av anskaffningsvärden i samband med periodens utbetalda förskott avseende immateriella anläggningstillgångar',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarForandringarAnskaffningsvardenUtbetaldaForskott',
                      standardRubrik:
                        'Ökning av anskaffningsvärden i samband med periodens utbetalda förskott avseende immateriella anläggningstillgångar',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'ForskottImmateriellaAnlaggningstillgangarRorelseforvarv',
                    'row',
                    noteLabel('Rörelseförvärv'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForskottImmateriellaAnlaggningstillgangarRorelseforvarv',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden för förskott avseende immateriella anläggningstillgångar genom rörelseförvärv',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForskottImmateriellaAnlaggningstillgangarRorelseforvarv',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden för förskott avseende immateriella anläggningstillgångar genom rörelseförvärv',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'ForskottImmateriellaAnlaggningstillgangarFusion',
                    'row',
                    noteLabel('Fusion'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForskottImmateriellaAnlaggningstillgangarFusion',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden för förskott avseende immateriella anläggningstillgångar genom fusion',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForskottImmateriellaAnlaggningstillgangarFusion',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden för förskott avseende immateriella anläggningstillgångar genom fusion',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarForandringarAnskaffningsvardenAterbetaldaForskott',
                    'row',
                    noteLabel('Återbetalda förskott'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarForandringarAnskaffningsvardenAterbetaldaForskott',
                      standardRubrik:
                        'Minskning av anskaffningsvärden i samband med periodens återbetalda förskott avseende immateriella anläggningstillgångar',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarForandringarAnskaffningsvardenAterbetaldaForskott',
                      standardRubrik:
                        'Minskning av anskaffningsvärden i samband med periodens återbetalda förskott avseende immateriella anläggningstillgångar',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarForandringarAnskaffningsvardenBortskrivnaForskott',
                    'row',
                    noteLabel('Bortskrivna förskott'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarForandringarAnskaffningsvardenBortskrivnaForskott',
                      standardRubrik:
                        'Minskning av anskaffningsvärden i samband med bortskrivning av förskott avseende immateriella anläggningstillgångar',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarForandringarAnskaffningsvardenBortskrivnaForskott',
                      standardRubrik:
                        'Minskning av anskaffningsvärden i samband med bortskrivning av förskott avseende immateriella anläggningstillgångar',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'ForskottImmateriellaAnlaggningstillgangarOmklassificeringar',
                    'row',
                    noteLabel('Omklassificeringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForskottImmateriellaAnlaggningstillgangarOmklassificeringar',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden i samband med omklassificeringar av förskott avseende immateriella anläggningstillgångar',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForskottImmateriellaAnlaggningstillgangarOmklassificeringar',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden i samband med omklassificeringar av förskott avseende immateriella anläggningstillgångar',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'ForskottImmateriellaAnlaggningstillgangarOmrakningsdifferenser',
                    'row',
                    noteLabel('Omräkningsdifferenser'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForskottImmateriellaAnlaggningstillgangarOmrakningsdifferenser',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden i samband med omräkningsdifferenser av förskott avseende immateriella anläggningstillgångar',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForskottImmateriellaAnlaggningstillgangarOmrakningsdifferenser',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden i samband med omräkningsdifferenser av förskott avseende immateriella anläggningstillgångar',
                      negateValue: false,
                    })
                  );
                  return rows.build();
                });
              rows.addRowWithType(
                'ForskottImmateriellaAnlaggningstillgangarBetaldaForskottUtgaende',
                'sum',
                noteLabel('Utgående anskaffningsvärden'),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note37.data.ForskottImmateriellaAnlaggningstillgangarAbstract.table.ForskottImmateriellaAnlaggningstillgangarAbstract.ForskottImmateriellaAnlaggningstillgangarBetaldaForskottIngaende.year'
                      ),
                      id(
                        'notes.note37.data.ForskottImmateriellaAnlaggningstillgangarAbstract.table.ForskottImmateriellaAnlaggningstillgangarAbstract.ForskottForandringImmateriellaAnlaggningstillgangarAbstract.*.year'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans0',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:ForskottImmateriellaAnlaggningstillgangarBetaldaForskott',
                    standardRubrik:
                      'Anskaffningsvärden förskott avseende immateriella anläggningstillgångar',
                    saldo: 'debit',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note37.data.ForskottImmateriellaAnlaggningstillgangarAbstract.table.ForskottImmateriellaAnlaggningstillgangarAbstract.ForskottImmateriellaAnlaggningstillgangarBetaldaForskottIngaende.previousYear'
                      ),
                      id(
                        'notes.note37.data.ForskottImmateriellaAnlaggningstillgangarAbstract.table.ForskottImmateriellaAnlaggningstillgangarAbstract.ForskottForandringImmateriellaAnlaggningstillgangarAbstract.*.previousYear'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans1',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:ForskottImmateriellaAnlaggningstillgangarBetaldaForskott',
                    standardRubrik:
                      'Anskaffningsvärden förskott avseende immateriella anläggningstillgångar',
                    saldo: 'debit',
                    negateValue: false,
                  }
                )
              );
              rows.addRowWithType(
                'ForskottImmateriellaAnlaggningstillgangarNedskrivningarIngaende',
                'row',
                noteLabel('Ingående nedskrivningar'),
                ixbrlCell(value(undefined), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:ForskottImmateriellaAnlaggningstillgangarNedskrivningar',
                  standardRubrik:
                    'Ackumulerade nedskrivningar förskott avseende immateriella anläggningstillgångar',
                  saldo: 'credit',
                  negateValue: true,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'balans2',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:ForskottImmateriellaAnlaggningstillgangarNedskrivningar',
                  standardRubrik:
                    'Ackumulerade nedskrivningar förskott avseende immateriella anläggningstillgångar',
                  saldo: 'credit',
                  negateValue: true,
                })
              );
              rows
                .addRowWithType(
                  'ForskottImmateriellaAnlaggningstillgangarForandringarNedskrivningarAbstract',
                  'header',
                  noteLabel('Förändringar av nedskrivningar'),
                  undefined,
                  undefined
                )
                .addSubRows((rows) => {
                  rows.addRowWithType(
                    'ForskottImmateriellaAnlaggningstillgangarForandringarNedskrivningarFusion',
                    'row',
                    noteLabel('Fusion'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForskottImmateriellaAnlaggningstillgangarForandringarNedskrivningarFusion',
                      standardRubrik:
                        'Ökning (minskning) av nedskrivningar för förskott avseende immateriella anläggningstillgångar genom fusion',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForskottImmateriellaAnlaggningstillgangarForandringarNedskrivningarFusion',
                      standardRubrik:
                        'Ökning (minskning) av nedskrivningar för förskott avseende immateriella anläggningstillgångar genom fusion',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'ForskottImmateriellaAnlaggningstillgangarForandringarNedskrivningarAterfordaNedskrivningar',
                    'row',
                    noteLabel('Återförda nedskrivningar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForskottImmateriellaAnlaggningstillgangarForandringarNedskrivningarAterfordaNedskrivningar',
                      standardRubrik:
                        'Periodens återförda nedskrivningar av förskott avseende immateriella anläggningstillgångar',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForskottImmateriellaAnlaggningstillgangarForandringarNedskrivningarAterfordaNedskrivningar',
                      standardRubrik:
                        'Periodens återförda nedskrivningar av förskott avseende immateriella anläggningstillgångar',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'ForskottImmateriellaAnlaggningstillgangarForandringarNedskrivningarBortskrivnaForskott',
                    'row',
                    noteLabel('Bortskrivna förskott'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForskottImmateriellaAnlaggningstillgangarForandringarNedskrivningarBortskrivnaForskott',
                      standardRubrik:
                        'Minskning av nedskrivningar i samband med bortskrivning av förskott avseende immateriella anläggningstillgångar',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForskottImmateriellaAnlaggningstillgangarForandringarNedskrivningarBortskrivnaForskott',
                      standardRubrik:
                        'Minskning av nedskrivningar i samband med bortskrivning av förskott avseende immateriella anläggningstillgångar',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'ForskottImmateriellaAnlaggningstillgangarForandringarNedskrivningarOmklassificeringar',
                    'row',
                    noteLabel('Omklassificeringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForskottImmateriellaAnlaggningstillgangarForandringarNedskrivningarOmklassificeringar',
                      standardRubrik:
                        'Minskning (ökning) av nedskrivningar i samband med omklassificeringar av förskott avseende immateriella anläggningstillgångar',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForskottImmateriellaAnlaggningstillgangarForandringarNedskrivningarOmklassificeringar',
                      standardRubrik:
                        'Minskning (ökning) av nedskrivningar i samband med omklassificeringar av förskott avseende immateriella anläggningstillgångar',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'ForskottImmateriellaAnlaggningstillgangarForandringarNedskrivningarAretsNedskrivningar',
                    'row',
                    noteLabel('Årets nedskrivningar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForskottImmateriellaAnlaggningstillgangarForandringarNedskrivningarAretsNedskrivningar',
                      standardRubrik:
                        'Periodens nedskrivningar av förskott avseende immateriella anläggningstillgångar',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForskottImmateriellaAnlaggningstillgangarForandringarNedskrivningarAretsNedskrivningar',
                      standardRubrik:
                        'Periodens nedskrivningar av förskott avseende immateriella anläggningstillgångar',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'ForskottImmateriellaAnlaggningstillgangarForandringarNedskrivningarOmrakningsdifferenser',
                    'row',
                    noteLabel('Omräkningsdifferenser'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForskottImmateriellaAnlaggningstillgangarForandringarNedskrivningarOmrakningsdifferenser',
                      standardRubrik:
                        'Minskning (ökning) av nedskrivningar i samband med omräkningsdifferenser av förskott avseende immateriella anläggningstillgångar',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForskottImmateriellaAnlaggningstillgangarForandringarNedskrivningarOmrakningsdifferenser',
                      standardRubrik:
                        'Minskning (ökning) av nedskrivningar i samband med omräkningsdifferenser av förskott avseende immateriella anläggningstillgångar',
                      negateValue: false,
                    })
                  );
                  return rows.build();
                });
              rows.addRowWithType(
                'ForskottImmateriellaAnlaggningstillgangarUtgaende',
                'sum',
                noteLabel('Utgående nedskrivningar'),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note37.data.ForskottImmateriellaAnlaggningstillgangarAbstract.table.ForskottImmateriellaAnlaggningstillgangarAbstract.ForskottImmateriellaAnlaggningstillgangarNedskrivningarIngaende.year'
                      ),
                      id(
                        'notes.note37.data.ForskottImmateriellaAnlaggningstillgangarAbstract.table.ForskottImmateriellaAnlaggningstillgangarAbstract.ForskottImmateriellaAnlaggningstillgangarForandringarNedskrivningarAbstract.*.year'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans0',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:ForskottImmateriellaAnlaggningstillgangarNedskrivningar',
                    standardRubrik:
                      'Ackumulerade nedskrivningar förskott avseende immateriella anläggningstillgångar',
                    saldo: 'credit',
                    negateValue: true,
                  }
                ),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note37.data.ForskottImmateriellaAnlaggningstillgangarAbstract.table.ForskottImmateriellaAnlaggningstillgangarAbstract.ForskottImmateriellaAnlaggningstillgangarNedskrivningarIngaende.previousYear'
                      ),
                      id(
                        'notes.note37.data.ForskottImmateriellaAnlaggningstillgangarAbstract.table.ForskottImmateriellaAnlaggningstillgangarAbstract.ForskottImmateriellaAnlaggningstillgangarForandringarNedskrivningarAbstract.*.previousYear'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans1',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:ForskottImmateriellaAnlaggningstillgangarNedskrivningar',
                    standardRubrik:
                      'Ackumulerade nedskrivningar förskott avseende immateriella anläggningstillgångar',
                    saldo: 'credit',
                    negateValue: true,
                  }
                )
              );
              return rows.build();
            });
          rows.addRowWithType(
            'ForskottImmateriellaAnlaggningstillgangar',
            'sum',
            noteLabel('Redovisat värde'),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note37.data.ForskottImmateriellaAnlaggningstillgangarAbstract.table.ForskottImmateriellaAnlaggningstillgangarAbstract.ForskottImmateriellaAnlaggningstillgangarBetaldaForskottUtgaende.year'
                  ),
                  id(
                    'notes.note37.data.ForskottImmateriellaAnlaggningstillgangarAbstract.table.ForskottImmateriellaAnlaggningstillgangarAbstract.ForskottImmateriellaAnlaggningstillgangarUtgaende.year'
                  )
                )
              ),
              {
                contextRef: 'balans0',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:ForskottImmateriellaAnlaggningstillgangar',
                standardRubrik:
                  'Förskott avseende immateriella anläggningstillgångar',
                saldo: 'debit',
                negateValue: false,
              }
            ),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note37.data.ForskottImmateriellaAnlaggningstillgangarAbstract.table.ForskottImmateriellaAnlaggningstillgangarAbstract.ForskottImmateriellaAnlaggningstillgangarBetaldaForskottUtgaende.previousYear'
                  ),
                  id(
                    'notes.note37.data.ForskottImmateriellaAnlaggningstillgangarAbstract.table.ForskottImmateriellaAnlaggningstillgangarAbstract.ForskottImmateriellaAnlaggningstillgangarUtgaende.previousYear'
                  )
                )
              ),
              {
                contextRef: 'balans1',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:ForskottImmateriellaAnlaggningstillgangar',
                standardRubrik:
                  'Förskott avseende immateriella anläggningstillgångar',
                saldo: 'debit',
                negateValue: false,
              }
            )
          );
          return rows.build();
        })
        .build(),
      NotForskottImmateriellaAnlaggningstillgangarKommentar: {
        type: 'group',
        active: true,
        level: 0,
        title: field(
          'Kommentar till specifikation av förskott avseende immateriella anläggningstillgångar'
        ),
        value: ixbrlField(field(''), {
          contextRef: 'period0',
          type: 'stringItemType',
          name: 'se-gen-base:NotForskottImmateriellaAnlaggningstillgangarKommentar',
          standardRubrik:
            'Kommentar till specifikation av förskott avseende immateriella anläggningstillgångar',
        }),
      },
    },
  },
  group: value(undefined),
  section: value(undefined),
  validForReportType: {
    k2: true,
    k3: true,
  },
});
export const note2037K2v2ContentDefinition = {
  type: 'part' as const,
  children: {
    active: boolean,
    number: cell,
    name: cell,
    data: {
      type: 'part' as const,
      children: {
        active: boolean,

        ForskottImmateriellaAnlaggningstillgangarAbstract: {
          type: 'part' as const,
          children: {
            type: type,
            level: number,
            active: boolean,

            title: cell,

            table: { type: 'table' as const },

            NotForskottImmateriellaAnlaggningstillgangarKommentar: {
              type: 'part' as const,
              children: {
                type: type,
                level: number,
                active: boolean,

                title: cell,

                value: cell,
              },
            },
          },
        },
      },
    },
    group: cell,
    section: cell,
    validForReportType: {
      type: 'part' as const,
      children: {
        k2: boolean,
        k3: boolean,
      },
    },
  },
};

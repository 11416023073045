import { AgoyDocumentStructure } from '../..';
import { traverseDocument, Actions } from '../../traverse';
import { Errors, OperationResult, State } from '../types';
import splitId from './splitId';

type Props = Parameters<Actions[keyof Actions]>[2];

export const withError = <T extends Props>(props: T, error: Errors): T => {
  return { ...props, error };
};

const updateDocument = <T extends AgoyDocumentStructure>(
  structure: T,
  state: State<T>,
  id: string,
  actions: Partial<Actions>
): OperationResult<T> => {
  const result = traverseDocument(
    splitId(id),
    structure,
    state.document,
    state.changes,
    actions
  );
  if (result.error) {
    return result.error;
  }
  if (state.document !== result.document || state.changes !== result.changes) {
    return {
      document: result.document,
      changes: result.changes || state.changes,
    };
  }
  return false;
};

export default updateDocument;

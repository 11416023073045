import {
  EconomicAssociationManagementReport,
  IndividualCompanyManagementReport,
  LimitedCompaniesManagementReport,
  SharesCompanyManagementReport as ManagementReportType,
  AnnualGeneralMeeting as AnnualGeneralMeetingType,
} from '@agoy/annual-report-document';
import { AgoyTableRow, Cell, NumberCell, stringValue } from '@agoy/document';

export const mapAdjusterNames = (
  adjusterArray: AgoyTableRow<Cell>[]
): string => {
  if (adjusterArray.length === 0) return '';

  if (adjusterArray.length > 1) {
    const arrayNames = adjusterArray.map((person) => {
      return stringValue(person.cells?.name);
    });
    return `${arrayNames.join(', ')}.`;
  }

  return `${stringValue(adjusterArray[0]?.cells?.name)}.` || '';
};

export const hasAdjuster = (adjusterArray: AgoyTableRow<Cell>[]) => {
  if (adjusterArray.length > 0) return true;

  return false;
};

export const isMatchingResultDisposition = (
  managementReport:
    | ManagementReportType
    | IndividualCompanyManagementReport
    | EconomicAssociationManagementReport
    | LimitedCompaniesManagementReport,
  annualGeneralMeeting: AnnualGeneralMeetingType
): boolean => {
  if ('resultsDisposition' in managementReport) {
    const proposalCell = managementReport.resultsDisposition.proposal.rows[2]
      ?.cells?.value as NumberCell;

    const proposalCellInProtocol =
      annualGeneralMeeting.main.resultsDisposition.rows.filter(
        (item) => item.id === 'dividend'
      )[0]?.cells?.value as NumberCell;

    const toTransferCellInProtocol =
      annualGeneralMeeting.main.resultsDisposition.rows.filter(
        (item) => item.id === 'result'
      )[0]?.cells?.value as NumberCell;

    const toTransferValue =
      managementReport.resultsDisposition.toTransfer?.value || 0;

    const toTransferInProtocol = toTransferCellInProtocol?.value || 0;

    const proposalValueInProtocol = proposalCellInProtocol?.value || 0;

    const proposalValue = proposalCell?.value || 0;

    return (
      toTransferValue === toTransferInProtocol &&
      proposalValue === proposalValueInProtocol
    );
  }
  return false;
};

import React from 'react';
import { useIntl } from 'react-intl';
import { Menu, MenuItem, Link, Box } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import config from '_shared/services/config';
import Button from '_shared/components/Buttons/Button';

const links = [
  {
    url: 'https://agoyit.atlassian.net/servicedesk/customer/portal/2/topic/41c5fe26-8a2f-4c4d-a5f3-ea2b12c41896',
    label: 'app.videoinstructions',
  },
  {
    url: 'https://agoyit.atlassian.net/servicedesk/customer/portal/2',
    label: 'app.helpcenter',
  },
];

const HelpMenu = (): JSX.Element => {
  const intl = useIntl();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const isFortnoxMode = config.whiteLabelUI === 'fortnox';
  const menuItems = links;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  if (isFortnoxMode) {
    return (
      <Box paddingRight={1}>
        <Link
          href="https://support.fortnox.se/sv_SE/fortnox-bokslut-skatt"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button
            label={intl.formatMessage({ id: 'app.help' })}
            variant="outlined"
            testId="onboarding-help"
            startIcon={<HelpOutlineIcon />}
          />
        </Link>
      </Box>
    );
  }

  return (
    <>
      <Box paddingRight={1}>
        <Button
          label={intl.formatMessage({ id: 'app.help' })}
          onClick={handleClick}
          variant="outlined"
          testId="onboarding-help"
          startIcon={<HelpOutlineIcon />}
        />
      </Box>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        onClose={closeMenu}
        open={Boolean(anchorEl)}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {menuItems.map((item) => (
          <MenuItem key={item.url}>
            <Link href={item.url} target="_blank" rel="noopener noreferrer">
              {intl.formatMessage({ id: item.label })}
            </Link>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default HelpMenu;

import { shallowCompare } from './utils/shallowCompare';
import {
  calculateReferences,
  resolveReference,
  TimePeriod,
  updateValues,
  AgoyDocument,
  collectResolvedValues,
} from '@agoy/document';
import { filterRecord, mapRecord, reformatDate } from '@agoy/common';
import {
  TaxCalculationConfig,
  TaxCalculationInput,
  TaxView,
  TaxTable,
  TaxCalculationContext,
  TaxCalculation,
  TaxCalculationRow,
  PossibleDepositionToAccrualFund,
  contentDefinition,
  TaxViewDocument,
} from './types';
import { calculateTaxTable, calculateRow } from './calculateTaxTable';
import { resolveParticularSalaryTax } from './particularSalaryTax';
import { resolveYearEndPlanning } from './yearEndPlanning';
import { isLastPeriod } from './context';
import {
  accrualFundInterest,
  calculateAccrualFunds,
  getAccrualDate,
} from './calculateAccrualFunds';
import calculateAdjustments from './calculateAdjustments';
import { isEqual } from 'lodash';

type AccountingPeriod = {
  /**
   * id of period.
   */
  id: number;
  /**
   * id of financial year.
   */
  financialYearId: number;
  /**
   * Periods start date, yyyy-MM-dd
   */
  start: string;
  /**
   * Periods end date, yyyy-MM-dd
   */
  end: string;
  /**
   * type 'month' or 'year_end'
   */
  type: 'month' | 'year_end';
};

const resolveNonDeductibleExpenses = (
  context: TaxCalculationContext,
  taxView: TaxView | null
): TaxTable => {
  const taxTable = calculateTaxTable(
    taxView?.nonDeductibleExpenses || context.config.nonDeductibleExpenses,
    context
  );
  if (!taxView?.nonDeductibleExpenses) {
    return taxTable;
  }
  return shallowCompare(taxView?.nonDeductibleExpenses, taxTable)
    ? taxView.nonDeductibleExpenses
    : taxTable;
};

const resolveNonTaxableIncomes = (
  context: TaxCalculationContext,
  taxView: TaxView | null
): TaxTable => {
  const taxTable: TaxTable = calculateTaxTable(
    taxView?.nonTaxableIncomes || context.config.nonTaxableIncomes,
    context
  );

  if (!taxView?.nonTaxableIncomes) {
    return taxTable;
  }

  return shallowCompare(taxView?.nonTaxableIncomes, taxTable)
    ? taxView.nonTaxableIncomes
    : taxTable;
};

const createDefaultTaxCalculation = (
  config: TaxCalculationConfig
): TaxCalculation => ({
  ...config.taxCalculation,
  moreRows: config.taxCalculationMoreRows,
  warningLowAmount: false,
});

const adjustLabel = (
  row: TaxCalculationRow,
  resultIsAlreadyBooked: boolean
): TaxCalculationRow => {
  const newRow = {
    ...row,
    labelId: resultIsAlreadyBooked ? `${row.id}Adjustment` : row.id,
  };

  return row.labelId === newRow.labelId ? row : newRow;
};

const resolveTaxCalculationMoreRows = (
  context: TaxCalculationContext,
  taxView: TaxView | null
): TaxCalculationRow[] => {
  const initialMoreRows =
    taxView?.taxCalculation?.moreRows || context.config.taxCalculationMoreRows;

  // Need to calculate 'alreadyBookedResult' in advance to know which
  // label to use for 'taxToBook' and 'resultToBook'
  const alreadyBookedResult = initialMoreRows.find(
    (row) => row.id === 'alreadyBookedResult'
  );
  let resultIsAlreadyBooked = false;
  if (alreadyBookedResult) {
    const { value } = calculateRow(alreadyBookedResult, context);
    resultIsAlreadyBooked = value !== undefined && value > 0;
  }

  const moreRows = initialMoreRows.map((row) =>
    calculateRow(
      row.id === 'taxToBook' || row.id === 'resultToBook'
        ? adjustLabel(row, resultIsAlreadyBooked)
        : row,
      context
    )
  );

  if (!taxView?.taxCalculation?.moreRows) {
    return moreRows;
  }
  return shallowCompare(taxView?.taxCalculation?.moreRows, moreRows)
    ? taxView?.taxCalculation?.moreRows
    : moreRows;
};

const resolveMaxPossibleDepositionToAccrualFund = (
  context: TaxCalculationContext,
  taxView: TaxView | null
): PossibleDepositionToAccrualFund => {
  const bookedAccrualFund = resolveReference('id(bookedAccrualFund)', context);
  const furtherDeposition =
    typeof bookedAccrualFund === 'number' && bookedAccrualFund > 0;
  const ref = furtherDeposition
    ? resolveReference('id(maxPossibleFurtherDepositionToAccrualFund)', context)
    : resolveReference('id(maxPossibleDepositionToAccrualFund)', context);

  const result: PossibleDepositionToAccrualFund = {
    value: typeof ref === 'number' ? ref : 0,
    furtherDeposition,
  };

  if (!taxView?.maxPossibleDepositionToAccrualFund) {
    return result;
  }
  return shallowCompare(taxView.maxPossibleDepositionToAccrualFund, result)
    ? taxView.maxPossibleDepositionToAccrualFund
    : result;
};

const checkLowAmount = (context: TaxCalculationContext): boolean => {
  const resultToBook = resolveReference('id(resultToBook)', context);
  if (typeof resultToBook === 'number' && Math.abs(resultToBook) <= 5) {
    const particularSalaryTaxToBook = resolveReference(
      'id(particularSalaryTaxToBook)',
      context
    );
    return (
      typeof particularSalaryTaxToBook === 'number' &&
      Math.round(particularSalaryTaxToBook) > 0
    );
  }
  return false;
};

const resolveTaxCalculation = (
  context: TaxCalculationContext,
  taxView: TaxView | null
): TaxCalculation => {
  const taxTable = calculateTaxTable(
    taxView?.taxCalculation || createDefaultTaxCalculation(context.config),
    context
  );
  const moreRows = resolveTaxCalculationMoreRows(context, taxView);

  const warningLowAmount = checkLowAmount(context);
  const taxCalculation: TaxCalculation = {
    ...taxTable,
    moreRows,
    warningLowAmount,
  };

  if (!taxView?.taxCalculation) {
    return taxCalculation;
  }
  return shallowCompare(taxView?.taxCalculation, taxCalculation)
    ? taxView?.taxCalculation
    : taxCalculation;
};

export const getYearPercentage = (
  period: TimePeriod,
  allPeriods: AccountingPeriod[]
): number => {
  const periods = allPeriods.filter((period) => period.type === 'month');
  const end = reformatDate(period.end, 'yyyyMMdd', 'yyyy-MM-dd');
  return (periods.findIndex((p) => p.end === end) + 1) / periods.length;
};

export const collectTaxViewValues = (
  rowsById: Record<string, TaxCalculationRow>,
  document?: AgoyDocument<typeof contentDefinition>
): Record<string, number | string | boolean | undefined> => {
  return {
    ...filterRecord(
      mapRecord(rowsById, (row: TaxCalculationRow) => row.value),
      (value): value is number => typeof value === 'number'
    ),
    ...(document && collectResolvedValues(contentDefinition, document)),
  };
};

const collectTaxViewReferences = (
  rowsById: Record<string, TaxCalculationRow>
): Record<string, string> => {
  return filterRecord(
    mapRecord(rowsById, (row: TaxCalculationRow) => row.reference),
    (value): value is string => value !== undefined
  );
};

const getUpdatedRefs = (
  context: TaxCalculationContext,
  document: TaxViewDocument,
  input: TaxCalculationInput
) => {
  return calculateReferences(
    contentDefinition,
    document,
    [collectTaxViewValues(context.rowsById)],
    context,
    collectTaxViewReferences(context.rowsById),
    input
  );
};

const taxCalculation = (
  config: TaxCalculationConfig,
  input: TaxCalculationInput,
  taxView: TaxView | null,
  externalDocument: Record<
    string,
    Record<string, number | string | boolean | undefined>
  >,
  recurseLimit = 10
): TaxView => {
  const context: TaxCalculationContext = {
    refs: {},
    resolveById: (id, inContext) => {
      const row = context.rowsById[id];

      if (`id(${id})` in context.refs) {
        return context.refs[`id(${id})`];
      }
      if (row) {
        if (row.value !== undefined) {
          return row.value;
        }
        if (row.reference) {
          return resolveReference(row.reference, inContext);
        }
      }
      if (id.includes('.')) {
        const [prefix, subId] = id.split(/\./);
        return externalDocument[prefix]?.[subId] ?? undefined;
      }
      return undefined;
    },
    resolveConfig: (name) => {
      if (name === 'yearPercentage') {
        return input.yearPercentage;
      }
      if (name.startsWith('accrualInterestPercentage')) {
        const index = name.split('.')[1];
        return accrualFundInterest(
          getAccrualDate(
            context.input.defaultPeriod.startDate,
            context.input.defaultPeriod.endDate,
            Number(index)
          ),
          context.input.defaultPeriod.end
        );
      }
      if (name === 'financialYear.start') {
        return context.input.periods.financialYear?.startDateISO;
      }
      if (name === 'financialYear.end') {
        return context.input.periods.financialYear?.endDateISO;
      }
      if (name === 'calendarYear.start') {
        return context.input.periods.calendarYear?.startDateISO;
      }
      if (name === 'calendarYear.end') {
        return context.input.periods.calendarYear?.endDateISO;
      }
      if (name === 'brokenFinancialYear') {
        return (
          !context.input.periods.financialYear.startDateISO.endsWith(
            '-01-01'
          ) ||
          !context.input.periods.financialYear.endDateISO.endsWith('-12-31')
        );
      }
      if (name.includes('date')) {
        const index = name.split('.')[2];
        const row = config.accrualFunds.rows.find(
          (row) => row.id === `accrualFund-${index}`
        );

        if (row) {
          return row.label;
        } else {
          return getAccrualDate(
            context.input.defaultPeriod.startDate,
            context.input.defaultPeriod.endDate,
            Number(index)
          );
        }
      }
      const value = context.config[name];
      return typeof value === 'number' ? value : undefined;
    },
    config,
    rowsById: taxView?.rowsById || {},
    input,
  };

  const resultBeforeTaxes = calculateRow(
    taxView?.resultBeforeTaxes || config.resultBeforeTaxes,
    context,
    isLastPeriod(context)
  );

  const nonTaxableIncomes = resolveNonTaxableIncomes(context, taxView);
  const nonDeductibleExpenses = resolveNonDeductibleExpenses(context, taxView);
  const particularSalaryTax = resolveParticularSalaryTax(context, taxView);

  const accrualFundsAccounts = calculateTaxTable(
    taxView?.accrualFundsAccounts || config.accrualFundsAccounts,
    context
  );

  if (isLastPeriod(context)) {
    // Called before resolveYearEndPlanning to resolve the accrualFunds accounts
    calculateAccrualFunds(context, taxView?.accrualFunds || null);
  }

  resolveYearEndPlanning(context, taxView);
  const accrualFundsBeforeTaxCalculation = isLastPeriod(context)
    ? calculateAccrualFunds(context, taxView?.accrualFunds || null)
    : null;

  resolveTaxCalculation(context, taxView);
  // because of depending of parts on each other need to get initial values and recalculate it later
  // need to refactor it
  if (isLastPeriod(context)) {
    calculateAccrualFunds(context, accrualFundsBeforeTaxCalculation);
  }

  const maxPossibleDepositionToAccrualFund = isLastPeriod(context)
    ? resolveMaxPossibleDepositionToAccrualFund(context, taxView)
    : null;

  const document = taxView?.document || config.document;

  context.refs = getUpdatedRefs(context, document, input);
  updateValues(contentDefinition)(document, context.refs);

  const notBookedParticualSalaryTax = calculateRow(
    taxView?.notBookedParticualSalaryTax || config.notBookedParticualSalaryTax,
    context,
    !isLastPeriod(context)
  );

  const resultBeforeFrom3000to8799 = calculateRow(
    taxView?.resultBeforeFrom3000to8799 || config.resultBeforeFrom3000to8799,
    context,
    !isLastPeriod(context)
  );

  const yearEndPlanning = resolveYearEndPlanning(context, taxView);

  const taxCalculationPart = resolveTaxCalculation(context, taxView);
  // update refs after getting final taxCalculation
  context.refs = getUpdatedRefs(context, document, input);

  const adjustments = calculateAdjustments(context, taxView);
  const accrualFunds = isLastPeriod(context)
    ? calculateAccrualFunds(context, accrualFundsBeforeTaxCalculation)
    : null;

  // issue with order of resolving references, should be refactored ASAP
  const updatedDocument = updateValues(contentDefinition)(
    document,
    context.refs
  );

  const result: TaxView = {
    notBookedParticualSalaryTax,
    resultBeforeFrom3000to8799,
    yearEndPlanning,
    resultBeforeTaxes,
    taxCalculation: taxCalculationPart,
    nonTaxableIncomes,
    nonDeductibleExpenses,
    particularSalaryTax,
    accrualFunds,
    accrualFundsAccounts,
    maxPossibleDepositionToAccrualFund,
    adjustments,
    taxDocuments: config.taxDocuments,
    rowsById: taxView?.rowsById
      ? shallowCompare(context.rowsById, taxView.rowsById)
        ? taxView.rowsById
        : context.rowsById
      : context.rowsById,
    document: updatedDocument,
    internspecifications: taxView?.internspecifications || {},
    externspecifications: taxView?.externspecifications || {},
    overdepreciation: taxView?.overdepreciation || {},
  };

  //
  // Since the calculations in Bokslut are done in a sequence
  // there are cases when some fields needs to be recalculated.
  // To solves this, we can run the calculations a couple of
  // times until the result stays the same.
  //
  // The preferred solution would be to rewrite Bokslut to
  // the regular agoy-document style calculations where the
  // values are calculated as they are referenced so no particular
  // order is needed to maintain.
  //
  if (isEqual(taxView, result) || recurseLimit === 0) {
    if (recurseLimit === 0) {
      // Still not the same result, have to bail out.
      // Can be due to a circular reference problem where
      // a value affects itself.
      console.error("Tax calculation didn't settle");
    }
    return result;
  }

  //
  // Recursive call
  //
  return taxCalculation(
    config,
    input,
    result,
    externalDocument,
    recurseLimit - 1
  );
};

export default taxCalculation;

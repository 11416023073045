import { AgoyTable } from '@agoy/document';
import { AnnualReportPart, AnnualReportSection } from '../../document';

export interface IncomeStatementSection extends AnnualReportSection {
  table: AgoyTable;
}

// Resultaträkning
export interface IncomeStatement extends AnnualReportPart {
  section: IncomeStatementSection;
}

const boolean = { type: 'boolean' as const };

export const incomeStatement = {
  type: 'part' as const,
  children: {
    active: boolean,
    section: {
      type: 'part' as const,
      children: {
        active: boolean,
        table: { type: 'table' as const },
      },
    },
  },
};

import { parseFormat } from '@agoy/dates';
import { asResultClass, getApiSdk } from 'api-sdk';
import { getContext } from 'utils/AgoyAppClient/contextHolder';
import getFileExtension from 'utils/fileExtension';

// eslint-disable-next-line import/prefer-default-export
export const printDocument = async (
  clientId: string,
  financialYear: string,
  docType: string,
  docName: string,
  parts: string[],
  printState: any,
  header = '',
  footer = ''
) => {
  const sdk = getApiSdk(getContext());

  const formattedFinancialYear = financialYear
    .split('-')
    .map((item) => parseFormat(item, 'yyyyMM'))
    .join('-');

  const result = await asResultClass(
    sdk.printReport({
      clientId,
      year: formattedFinancialYear,
      docType,
      name: docName,
      requestBody: {
        parts,
        printState,
        header,
        footer,
        includeIndex: false,
      },
    })
  );

  if (result.ok) {
    const link = document.createElement('a');
    const response = await fetch(result.val.res.url);

    if (response.ok) {
      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));

      link.href = url;
      const fileName =
        getFileExtension(docName) === '.pdf' ? docName : `${docName}.pdf`;

      link.setAttribute('download', fileName);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode?.removeChild(link);
    }

    return result.val.res;
  }

  throw result.val;
};

import { AccountingBalances, AccountingPeriod } from 'types/Accounting';
import groupAccounts from '_reconciliation/components/ReconciliationView/HiddenRow/HiddenGroupRow/utils/groupAccounts';

import {
  generateAccountGroup,
  generateCsvRow,
  generateGroupSummary,
  generateCsvSubGroups,
} from './helpers';

export const DEFAULT_CSV_HEADERS = [
  'Konto',
  'Balans- och resultaträkning',
  'Ing. balans från föregående år',
  'Tot. förändring innevarande år',
  'Utg. balans innevarande år',
];

/**
 * Generate the CSV column header, composing default and period specific headers.
 */
export const extractHeaders = (periods: AccountingPeriod[]): string[] => {
  const headers: string[] = [];

  headers.push(...DEFAULT_CSV_HEADERS);

  periods
    .filter((p) => p.type !== 'year_end')
    .forEach((period) => {
      headers.push(
        `${period.start.substring(0, 4)}${period.start.substring(
          5,
          7
        )} - Förändring`,
        `${period.start.substring(0, 4)}${period.start.substring(
          5,
          7
        )} - Utgående balans period`
      );
    });

  return headers;
};

/**
 * Extract account from sieData, in a matrix format.
 * Each row in the matrix represents a CSV row.
 */
export const extractAccounts = (
  accountingBalances: AccountingBalances
): string[][] => {
  const accounts: string[][] = [];
  const { periods: allPeriods } = accountingBalances;
  const periods = allPeriods.filter((p) => p.type !== 'year_end');

  const grouppedAccounts = groupAccounts(accountingBalances, periods);

  grouppedAccounts.forEach((group) => {
    const { name, subGroups, summary } = group;

    const accountGroup = generateAccountGroup({ name, subGroups, periods });
    if (accountGroup) {
      accounts.push(accountGroup);
    }

    const subGroupMatrix = generateCsvSubGroups({ subGroups, periods });
    if (subGroupMatrix) {
      accounts.push(...subGroupMatrix);
    }

    const groupSummary = generateGroupSummary({ summary });
    if (groupSummary) {
      accounts.push(groupSummary);
    }
  });

  return accounts;
};

/**
 * Generate CSV file string from sieData. UTF-8 encoding.
 */
export const generateCsvString = (
  accountingBalances: AccountingBalances | undefined
): string => {
  let csvString = '';

  if (!accountingBalances) return '';

  const { periods } = accountingBalances;

  // generate headers
  const headers: string[] = extractHeaders(periods);

  // generate accounts
  const accounts: string[][] = extractAccounts(accountingBalances);

  // tranform headers and accounts into csv
  csvString += generateCsvRow(headers);
  accounts.forEach((account) => (csvString += generateCsvRow(account)));

  return csvString;
};

/**
 * Generate a blob from string. Add BOM to fix encoding issues in Windows MS Excel.
 * https://github.com/eligrey/FileSaver.js/issues/28
 */
export const generateBlob = (csvString: string) => {
  const BOM = '\uFEFF';
  return new Blob([BOM + csvString], { type: 'text/csv;charset=utf-8' });
};

/**
 * Generate encoded blob from SieData, so it can be saved to a file.
 */
const generateCsv = (
  accountigBalances: AccountingBalances | undefined
): Blob => {
  const csvString = generateCsvString(accountigBalances);
  return generateBlob(csvString);
};

export default generateCsv;

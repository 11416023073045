import React from 'react';
import {
  AnnualReportVersion,
  BalanceSheet,
  Settings,
} from '@agoy/annual-report-document';
import { BalanceSheetPage } from '@agoy/annual-report-view';
import Page from '_shared/components/PrintedDocument/Page';
import PreviewSection from '../../UI/PreviewSection';

interface BalanceSheetPreviewProps {
  balanceSheet: BalanceSheet;
  settings: Settings;
  version: AnnualReportVersion;
}

const BalanceSheetPreview = ({
  balanceSheet,
  settings,
  version,
}: BalanceSheetPreviewProps): JSX.Element => {
  return (
    <Page>
      <PreviewSection id="balanceSheet">
        <BalanceSheetPage
          settings={settings}
          balanceSheet={balanceSheet}
          version={version}
        />
      </PreviewSection>
    </Page>
  );
};

export default BalanceSheetPreview;

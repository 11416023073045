import { AgoyDocument } from '@agoy/document';
import { confirmationCertificateContentDefinition } from './document';
import { settingsConfig } from './settings/settings';
import { digitalSubmissionConfig } from '../shares-company/digital-submission/digitalSubmission';
import { confirmationCertificateConfig as sharesCompanyConfirmationCertificateConfig } from '../shares-company/confirmation-certificate/confirmationCertificate';
import {
  confirmationCertificateConfig,
  manualSubmissionConfig,
} from '../common';

const getConfirmationCertificateConfigs = (documentType: string) => {
  const resultConfigs = {
    confirmationCertificateConfig,
    manualSubmissionConfig,
    settingsConfig,
    digitalSubmissionConfig,
  };

  if (documentType === 'shares') {
    resultConfigs.confirmationCertificateConfig = () =>
      sharesCompanyConfirmationCertificateConfig(true);
  }

  return resultConfigs;
};

export const confirmationCertificateDocumentConfig = (
  documentType = 'shares'
): AgoyDocument<typeof confirmationCertificateContentDefinition> => {
  const configs = getConfirmationCertificateConfigs(documentType);

  return {
    documentType: 'confirmationCertificate',
    confirmationCertificate: configs.confirmationCertificateConfig(),
    manualSubmission: configs.manualSubmissionConfig(),
    digitalSubmission: configs.digitalSubmissionConfig(),
    settings: configs.settingsConfig(),
    // need to have it, to make external document references work, check contentDefinition
    annualReport: undefined,
  };
};

export { confirmationCertificateContentDefinition };

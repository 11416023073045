import React, { useRef, useEffect, useState } from 'react';
import { TableCell, IconButton, Button, Typography } from '@material-ui/core';
import styled from '@emotion/styled';
import DeleteIcon from '@material-ui/icons/Delete';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import { useIntl } from 'react-intl';
import { useDrag, useDrop } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';

import { AgoyTableColumn } from '@agoy/document';
import EditField from '_shared/components/Inputs/EditField';
import { DraggableItemTypes } from 'contants';
import StringInput from './Cell/Field/StringInput';

interface TableHeadCellProps {
  className?: string;
  column: AgoyTableColumn;
  baseId: string;
  tableId: string;
  headerCellTitle?: string;
  onDeleteColumn: (columnId: string) => void;
  onEditColumn: (columnId: string, value: string) => void;
  onDragHoverColumn: (
    draggedColumn: AgoyTableColumn,
    column: AgoyTableColumn
  ) => void;
  onEndDragColumn: (column: AgoyTableColumn) => void;
  onHeaderColumnPress?: (column: AgoyTableColumn | undefined) => void;
  canPress?: boolean;
  renderCell?: (values: {
    column: AgoyTableColumn;
    isDragging: boolean;
  }) => React.ReactElement | string | null;
  editing?: boolean;
  canDrag: boolean;
  canDelete?: boolean;
  canEdit?: boolean;
  textAlign?: string;
}

const StyledTableCell = styled(TableCell)`
  background-color: ${(props) =>
    props['data-is-dragging']
      ? props.theme.palette.accountingView.cell.ok
      : 'inherit'};
  &.right {
    text-align: right;
  }
  &.left {
    text-align: left;
  }
`;

const ClickableStyledTableCell = styled(StyledTableCell)`
  &:hover {
    cursor: pointer;
  }
`;

const ClickableTableCellButton = styled(Button)`
  position: absolute;
  display: block;
  margin-top: 10px;
  margin-left: -10px;
  box-shadow: ${(props) => props.theme.shadows[4]};
  background-color: ${(props) => props.theme.palette.common.white};
  z-index: 10;
`;

const ClickableTableCellTitle = styled(Typography)`
  font-size: 14px;
  text-transform: capitalize;
  font-family: inherit;
  color: inherit;
`;

const EditingColumn = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
`;

const NonEditableDraggableColumn = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const DragIconWrapper = styled.div`
  display: flex;
  cursor: grab;
`;

const StyledIconButton = styled(IconButton)`
  margin-left: 5px;
`;

const TableHeadCell = ({
  className,
  column,
  baseId,
  tableId,
  headerCellTitle,
  onDeleteColumn,
  onEditColumn,
  onDragHoverColumn,
  onEndDragColumn,
  onHeaderColumnPress,
  renderCell,
  editing = false,
  canDrag,
  canEdit = false,
  canDelete = true,
  canPress = false,
  textAlign = 'right',
}: TableHeadCellProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const cellRef = useRef(null);
  const [isCellButtonShown, setCellButtonShown] = useState(false);

  const deleteColumn = (columnId: string) => onDeleteColumn(columnId);

  const [{ isDragging }, connectColumnDrag, dragPreviewRef] = useDrag({
    type: DraggableItemTypes.MANAGEMENT_REPORT_COLUMN,
    item: { column },
    canDrag: () => editing,
    end: () => {
      onEndDragColumn(column);
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const [, connectColumnDrop] = useDrop({
    accept: DraggableItemTypes.MANAGEMENT_REPORT_COLUMN,
    hover: ({ column: draggedColumn }: any) => {
      onDragHoverColumn(draggedColumn, column);
    },
  });

  const renderColumnLabel = () => {
    const label =
      column.label !== undefined
        ? column.label
        : formatMessage({
            id: `${baseId}.${tableId}.${column.id}`,
            defaultMessage: column.id,
          });

    if (editing && !canEdit && canDrag) {
      return (
        <NonEditableDraggableColumn>
          <div>{label}</div>

          {canDrag && (
            <DragIconWrapper ref={connectColumnDrag}>
              <DragIndicatorIcon />
            </DragIconWrapper>
          )}
        </NonEditableDraggableColumn>
      );
    }

    if (editing && canEdit) {
      return (
        <EditingColumn>
          {renderCell ? (
            renderCell({ column, isDragging })
          ) : (
            <EditField
              value={label}
              onChange={(value: string) => {
                onEditColumn(column.id, value);
              }}
              component={StringInput}
            />
          )}

          {canDelete && (
            <StyledIconButton
              onClick={() => {
                deleteColumn(column.id);
              }}
              size="small"
            >
              <DeleteIcon fontSize="small" />
            </StyledIconButton>
          )}

          {canDrag && (
            <DragIconWrapper ref={connectColumnDrag}>
              <DragIndicatorIcon />
            </DragIconWrapper>
          )}
        </EditingColumn>
      );
    }

    return renderCell ? renderCell({ column, isDragging }) : label;
  };

  useEffect(() => {
    dragPreviewRef(getEmptyImage(), { captureDraggingState: true });
  }, [dragPreviewRef]);

  if (editing && canDrag) {
    connectColumnDrop(cellRef);
  }

  useEffect(() => {
    if (editing) {
      setCellButtonShown(false);
    }
  }, [editing]);

  const handleHeaderCellClick = (column: AgoyTableColumn | undefined) => {
    if (onHeaderColumnPress) {
      onHeaderColumnPress(column);
    }
  };

  return (
    <>
      {canPress && !editing ? (
        <>
          <ClickableStyledTableCell
            className={`${textAlign} ${className || ''}`}
            data-is-dragging={isDragging}
            ref={cellRef}
            onClick={() => setCellButtonShown(!isCellButtonShown)}
          >
            {renderColumnLabel()}
            {isCellButtonShown && (
              <ClickableTableCellButton
                disableRipple
                onClick={() => handleHeaderCellClick(column)}
              >
                <ClickableTableCellTitle>
                  {formatMessage({
                    id: `${headerCellTitle}`,
                  })}
                </ClickableTableCellTitle>
              </ClickableTableCellButton>
            )}
          </ClickableStyledTableCell>
        </>
      ) : (
        <StyledTableCell
          className={`${textAlign} ${className || ''}`}
          data-is-dragging={isDragging}
          ref={cellRef}
        >
          {renderColumnLabel()}
        </StyledTableCell>
      )}
    </>
  );
};

export default TableHeadCell;

export const VOUCHERS_DESCRIPTION = 'Bokslutverification';
export const VOUCHERS_TRANSACTION_DESCRIPTION = 'Lagerinkurans';
export const VOUCHERS_SPECIFICATION_STOCK = 'Lagervärde efter justering';
export const INVENTORY_OBSOLESCENCE_DEFAULT_DESCRIPTION =
  'Lagervärde enligt inventeringslista';

export const formatAmount = (value, decimals) => {
  return Number(
    `${Math.round(parseFloat(`${value}e${decimals}`))}e-${decimals}`
  ).toFixed(decimals);
};

import React from 'react';
import { Typography } from '@material-ui/core';
import styled from '@emotion/styled';

import { UnstyledButton } from '_shared/components/Buttons';

const DefaultWrapper = styled(UnstyledButton)`
  margin: auto;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: lightgray;
  }
`;

const SuccessWrapper = styled(UnstyledButton)`
  margin: auto;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${(props) => props.theme.palette.primary.main};
`;

const DisabledWrapper = styled.div`
  margin: auto;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;

  user-select: none;
`;

const CellText = styled(Typography)``;

const SelectedCellText = styled(Typography)`
  color: ${(props) => props.theme.palette.secondary.contrastText};
`;

type Props = {
  isActive: boolean;
  isSelected: boolean;
  handleClick: () => void;
  text: string;
};

const PeriodCell = ({
  isActive,
  isSelected,
  handleClick,
  text,
}: Props): JSX.Element => {
  if (!isActive) {
    return (
      <DisabledWrapper>
        <CellText variant="body1" color="textSecondary">
          {text}
        </CellText>
      </DisabledWrapper>
    );
  }

  if (isSelected) {
    return (
      <SuccessWrapper onClick={handleClick}>
        <SelectedCellText variant="body1">{text}</SelectedCellText>
      </SuccessWrapper>
    );
  }

  return (
    <DefaultWrapper onClick={handleClick}>
      <CellText variant="body1">{text}</CellText>
    </DefaultWrapper>
  );
};

export default PeriodCell;

import React, { useMemo } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.vite';

type PdfPreviewProps = {
  src: string;
};

const PdfPreview = ({ src }: PdfPreviewProps): JSX.Element => {
  const fileSrc = useMemo(() => ({ url: src }), [src]);
  return (
    <Document file={fileSrc}>
      <Page
        renderAnnotationLayer={false}
        renderForms={false}
        renderTextLayer={false}
        width={248}
        pageNumber={1}
      />
    </Document>
  );
};

export default PdfPreview;

import { AgoyDocumentStructure } from '../document';
import updateDocument from './helpers/updateDocument';
import { OperationResult, State } from './types';
import { Source } from '../../types/source';
import { mergeSource } from './helpers/mergeSource';

const updateTableSource = <T extends AgoyDocumentStructure>(
  structure: T,
  state: State<T>,
  id: string,
  sourceUpdate: Partial<Source>
): OperationResult<T> => {
  return updateDocument(structure, state, id, {
    table: (keys, tableId, props) => {
      const { source } = props.node;

      const newSource = mergeSource(source, sourceUpdate);
      if (newSource !== source) {
        return {
          ...props,
          changes: {
            type: 'update',
            ...props.changes,
            source: newSource,
          },
          node: {
            ...props.node,
            source: newSource,
          },
        };
      }
      return props;
    },
  });
};

export default updateTableSource;

import { id, or, ref, sum, table, value } from '@agoy/document';
import {
  active,
  field,
  ixbrlCell,
  ixbrlField,
  tupleRef,
} from '../../../../common/utils/util';
import { NoteTwoTableSectionWithTwoSum } from '../../../../common';

export const getNote98 = (
  noteLabel: any,
  documentTypeVersion: string
): NoteTwoTableSectionWithTwoSum => {
  return {
    active: false,
    number: value(undefined),
    name: value('Uppskjuten skatt'),
    table: table(
      'notes.note98.table',
      { id: 'total', label: 'Typ av skillnad/avdrag' },
      { id: 'temporaryDifference', label: 'Temporär skillnad/avdrag' },
      { id: 'taxValue', label: 'Skattefordran värde' }
    )
      .addRows((rows) =>
        rows
          .addRow(
            'totalSpecificationDeferredTaxClaim',
            ixbrlCell(value(''), {
              type: 'stringItemType',
              name: 'se-gen-base:UppskjutenSkattSkillnaderSkattefordranTyp',
              contextRef: 'balans0',
              standardRubrik:
                'Typ av temporär skillnad eller skattemässiga underskottsavdrag hänförlig till uppskjuten skattefordran',
              tuple: {
                name: 'se-gaap-ext:UppskjutenSkattSkillnaderSkattefordranTuple',
                id: `${rows.getBaseId()}.totalSpecificationDeferredTaxClaim.tuple`,
                tupleID: `${rows.getBaseId()}.totalSpecificationDeferredTaxClaim.tuple`,
              },
              tupleRef: tupleRef(
                1,
                `${rows.getBaseId()}.totalSpecificationDeferredTaxClaim.tuple`
              ),
            }),
            ixbrlCell(value(undefined), {
              type: 'monetaryItemType',
              name: 'se-gen-base:UppskjutenSkattSkillnaderSkattefordranTemporarSkillnad',
              contextRef: 'balans0',
              standardRubrik:
                'Temporär skillnad eller avdrag hänförlig till uppskjuten skattefordran, per typ av skillnad eller avdrag',
              saldo: 'debit',
              tupleRef: tupleRef(
                2,
                `${rows.getBaseId()}.totalSpecificationDeferredTaxClaim.tuple`
              ),
            }),
            ixbrlCell(value(undefined), {
              type: 'monetaryItemType',
              name: 'se-gen-base:UppskjutenSkattSkillnaderSkattefodran',
              contextRef: 'balans0',
              standardRubrik:
                'Uppskjuten skattefordran per typ av temporär skillnad eller avdrag',
              saldo: 'debit',
              tupleRef: tupleRef(
                3,
                `${rows.getBaseId()}.totalSpecificationDeferredTaxClaim.tuple`
              ),
            })
          )
          .build()
      )
      .newRowTemplateGenerator((id, baseId) => ({
        id,
        active: true,
        cells: {
          total: ixbrlCell(value(''), {
            type: 'stringItemType',
            name: 'se-gen-base:UppskjutenSkattSkillnaderSkattefordranTyp',
            contextRef: 'balans0',
            standardRubrik:
              'Typ av temporär skillnad eller skattemässiga underskottsavdrag hänförlig till uppskjuten skattefordran',
            tuple: {
              name: 'se-gaap-ext:UppskjutenSkattSkillnaderSkattefordranTuple',
              id: `${baseId}.${id}.tuple`,
              tupleID: `${baseId}.${id}.tuple`,
            },
            tupleRef: tupleRef(1, `${baseId}.${id}.tuple`),
          }),
          temporaryDifference: ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base:UppskjutenSkattSkillnaderSkattefordranTemporarSkillnad',
            contextRef: 'balans0',
            standardRubrik:
              'Temporär skillnad eller avdrag hänförlig till uppskjuten skattefordran, per typ av skillnad eller avdrag',
            saldo: 'debit',
            tupleRef: tupleRef(2, `${baseId}.${id}.tuple`),
          }),
          taxValue: ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base:UppskjutenSkattSkillnaderSkattefodran',
            contextRef: 'balans0',
            standardRubrik:
              'Uppskjuten skattefordran per typ av temporär skillnad eller avdrag',
            saldo: 'debit',
            tupleRef: tupleRef(3, `${baseId}.${id}.tuple`),
          }),
        },
      }))
      .build(),
    sumTable: table(
      'notes.note98.sumTable',
      { id: 'label', label: '' },
      { id: 'taxClaimValue', label: 'Skattefordran värde' }
    )
      .addRows((rows) =>
        rows
          .addRow(
            'sum',
            noteLabel('Summa specifikation uppskjuten skattefordran'),
            ixbrlCell(ref(or(sum(id('notes.note98.table.*.taxValue')), 0)), {
              type: 'monetaryItemType',
              name: 'se-gen-base:UppskjutenSkattSkillnaderUppskjutenSkattefordran',
              contextRef: 'balans0',
              standardRubrik: 'Summa specifikation uppskjuten skattefordran',
              saldo: 'debit',
            })
          )
          .addRow(
            'amountOffsetAgainstDeferredTaxAssets',
            noteLabel('Belopp som kvittas mot uppskjutna skattefordringar'),
            ixbrlCell(value(0), {
              type: 'monetaryItemType',
              name: 'se-gen-base:UppskjutenSkattSkillnaderKvittasUppskjutenSkattefordran',
              contextRef: 'balans0',
              standardRubrik:
                'Belopp som kvittas mot uppskjutna skattefordringar',
              saldo: 'credit',
            })
          )
          .addRow(
            'total',
            noteLabel('Uppskjuten skattefordran'),
            ref(
              or(
                sum(
                  or(id('notes.note98.sumTable.sum.taxClaimValue'), 0),
                  or(
                    id(
                      'notes.note98.sumTable.amountOffsetAgainstDeferredTaxAssets.taxClaimValue'
                    ),
                    0
                  )
                ),
                0
              )
            )
          )
          .build()
      )
      .build(),

    secondTable: table(
      'notes.note98.secondTable',
      { id: 'total', label: 'Typ av skillnad/avdrag' },
      { id: 'temporaryDifference', label: 'Temporär skillnad/avdrag' },
      { id: 'taxValue', label: 'Skatteskuld' }
    )
      .addRows((rows) =>
        rows
          .addRow(
            'totalSpecificationDeferredTaxLiability',
            ixbrlCell(value(''), {
              type: 'stringItemType',
              name: 'se-gen-base:UppskjutenSkattSkillnaderSkatteskuldTyp',
              contextRef: 'balans0',
              standardRubrik:
                'Typ av temporär skillnad eller avdrag hänförlig till uppskjuten skatteskuld',
              tuple: {
                name: 'se-gaap-ext:UppskjutenSkattSkillnaderSkatteskuldTuple',
                id: `${rows.getBaseId()}.totalSpecificationDeferredTaxLiability.tuple`,
                tupleID: `${rows.getBaseId()}.totalSpecificationDeferredTaxLiability.tuple`,
              },
              tupleRef: tupleRef(
                1,
                `${rows.getBaseId()}.totalSpecificationDeferredTaxLiability.tuple`
              ),
            }),
            ixbrlCell(value(undefined), {
              type: 'monetaryItemType',
              name: 'se-gen-base:UppskjutenSkattSkillnaderSkatteskuldTemporarSkillnad',
              contextRef: 'balans0',
              standardRubrik:
                'Temporär skillnad eller avdrag hänförlig till uppskjuten skatteskuld, per typ av skillnad eller avdrag',
              saldo: 'credit',
              tupleRef: tupleRef(
                2,
                `${rows.getBaseId()}.totalSpecificationDeferredTaxLiability.tuple`
              ),
            }),
            ixbrlCell(value(undefined), {
              type: 'monetaryItemType',
              name: 'se-gen-base:UppskjutenSkattSkillnaderSkatteskuld',
              contextRef: 'balans0',
              standardRubrik:
                'Uppskjuten skatteskuld per typ av temporär skillnad eller avdrag',
              saldo: 'credit',
              tupleRef: tupleRef(
                3,
                `${rows.getBaseId()}.totalSpecificationDeferredTaxLiability.tuple`
              ),
            })
          )
          .build()
      )
      .newRowTemplateGenerator((id, baseId) => ({
        id,
        active: true,
        cells: {
          total: ixbrlCell(value(''), {
            type: 'stringItemType',
            name: 'se-gen-base:UppskjutenSkattSkillnaderSkatteskuldTyp',
            contextRef: 'balans0',
            standardRubrik:
              'Typ av temporär skillnad eller avdrag hänförlig till uppskjuten skatteskuld',
            tuple: {
              name: 'se-gaap-ext:UppskjutenSkattSkillnaderSkatteskuldTuple',
              id: `${baseId}.${id}.tuple`,
              tupleID: `${baseId}.${id}.tuple`,
            },
            tupleRef: tupleRef(1, `${baseId}.${id}.tuple`),
          }),
          temporaryDifference: ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base:UppskjutenSkattSkillnaderSkatteskuldTemporarSkillnad',
            contextRef: 'balans0',
            standardRubrik:
              'Temporär skillnad eller avdrag hänförlig till uppskjuten skatteskuld, per typ av skillnad eller avdrag',
            saldo: 'credit',
            tupleRef: tupleRef(2, `${baseId}.${id}.tuple`),
          }),
          taxValue: ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base:UppskjutenSkattSkillnaderSkatteskuld',
            contextRef: 'balans0',
            standardRubrik:
              'Uppskjuten skatteskuld per typ av temporär skillnad eller avdrag',
            saldo: 'credit',
            tupleRef: tupleRef(3, `${baseId}.${id}.tuple`),
          }),
        },
      }))
      .build(),
    secondSumTable: table(
      'notes.note98.secondSumTable',
      { id: 'label', label: '' },
      { id: 'taxClaimValue', label: 'Skattefordran värde' }
    )
      .addRows((rows) =>
        rows
          .addRow(
            'sum',
            noteLabel('Summa specifikation uppskjuten skatteskuld'),
            ixbrlCell(
              ref(or(sum(id('notes.note98.secondTable.*.taxValue')), 0)),
              {
                type: 'monetaryItemType',
                name: 'se-gen-base:UppskjutenSkattSkillnaderUppskjutenSkatteskuld',
                contextRef: 'balans0',
                standardRubrik: 'Summa specifikation uppskjuten skattefordran',
                saldo: 'debit',
              }
            )
          )
          .addRow(
            'amountOffsetAgainstDeferredTaxLiability',
            noteLabel('Belopp som kvittas mot uppskjutna skatteskulder'),
            ixbrlCell(value(0), {
              type: 'monetaryItemType',
              name: 'se-gen-base:UppskjutenSkattSkillnaderKvittasUppskjutenSkatteskuld',
              contextRef: 'balans0',
              standardRubrik: 'Belopp som kvittas mot uppskjutna skatteskulder',
              saldo: 'debit',
            })
          )
          .addRow(
            'total',
            noteLabel('Uppskjuten skatteskuld'),
            ref(
              or(
                sum(
                  or(id('notes.note98.secondSumTable.sum.taxClaimValue'), 0),
                  or(
                    id(
                      'notes.note98.secondSumTable.amountOffsetAgainstDeferredTaxLiability.taxClaimValue'
                    ),
                    0
                  )
                ),
                0
              )
            )
          )
          .build()
      )
      .build(),
    commentLabel: field('Kommentar till not'),
    comment: ixbrlField(active(field('')), {
      type: 'stringItemType',
      name: 'se-gen-base:NotUppskjutenSkattKommentar',
      contextRef: 'period0',
    }),
    group: value(undefined),
    section: value(undefined),
    validForReportType: {
      k2: false,
      k3: documentTypeVersion !== '1',
    },
  };
};

import React, { useCallback } from 'react';
import { TableBody } from '@material-ui/core';
import {
  AgoyTableRow,
  AgoyTableColumn,
  Cell as CellType,
} from '@agoy/document';
import { NumberFormatType } from '@agoy/common';
import CustomTableRow from './TableRow';

export type SubRowType = {
  row: AgoyTableRow;
  baseId: string;
};

interface TableBodyProps {
  tableId: string;
  rows: AgoyTableRow[];
  columns: AgoyTableColumn[];
  isXBRLRender?: boolean;
  numberFormat?: (rowId: string) => NumberFormatType;
  numberFormatCell?: (colId: string) => NumberFormatType;
  renderRow?: (subRow: SubRowType) => React.ReactElement | null;
  renderCell?: (values: {
    cell: CellType;
    column: AgoyTableColumn;
    row: AgoyTableRow;
    baseId: string;
  }) => React.ReactElement | undefined;
}

const TableBodyComponent = ({
  tableId,
  rows,
  columns,
  isXBRLRender,
  numberFormat,
  numberFormatCell,
  renderRow,
}: TableBodyProps): JSX.Element => {
  const getSubRows = useCallback((row: AgoyTableRow, id: string) => {
    let result: { row: AgoyTableRow; baseId: string }[] = [];

    if (row.rows) {
      row.rows.forEach((item) => {
        const newBaseId = `${id}.${row.id}`;
        const resultRows = getSubRows(item, newBaseId);
        result = [...result, ...resultRows];
      });
    }

    if (row.cells) {
      return [{ baseId: id, row }];
    }

    return result;
  }, []);

  const sortSubRows = (currentSubRows: SubRowType[]) => {
    const newSubRows: SubRowType[] = [];
    const newSubRowsWithSortKey: SubRowType[] = [];
    currentSubRows.forEach((item) => {
      if (typeof item.row.sortKey === 'number') {
        newSubRowsWithSortKey.push(item);
      } else {
        newSubRows.push(item);
      }
    });

    newSubRowsWithSortKey.sort(
      (a: SubRowType, b: SubRowType) =>
        (a.row?.sortKey || 0) - (b.row?.sortKey || 0)
    );
    newSubRowsWithSortKey.forEach((item) => {
      newSubRows.splice(item.row.sortKey || 0, 0, item);
    });
    return newSubRows;
  };

  let subRows: SubRowType[] = [];
  rows.forEach((item) => {
    subRows = [...subRows, ...getSubRows(item, tableId)];
  });
  subRows = sortSubRows(subRows);

  return (
    <TableBody>
      {subRows.map((item) => (
        <React.Fragment key={`${item.baseId}.${item.row.id}`}>
          {renderRow ? (
            renderRow(item)
          ) : (
            <CustomTableRow
              baseId={item.baseId}
              row={item.row}
              columns={columns}
              numberFormat={
                numberFormat ? numberFormat(item.row.id) : undefined
              }
              numberFormatCell={numberFormatCell}
              isXBRLRender={isXBRLRender}
            />
          )}
        </React.Fragment>
      ))}
    </TableBody>
  );
};

export default TableBodyComponent;

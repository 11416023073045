import React, { useCallback, useContext, useMemo } from 'react';
import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { stringValue } from '@agoy/document';
import { FinancialYear } from '@agoy/api-sdk-core';
import usePrintState from '_tax/hooks/usePrintState';
import TaxesDataContext from '_tax/service/TaxesDataContext';
import { currentParticularSalaryFORATaxDocument } from '_tax/redux/tax-view/selectors';
import { printDocument } from '_shared/components/PrintedDocument/utils';
import { useSelector } from 'redux/reducers';
import { addGlobalErrorMessage } from 'redux/actions';
import ExpandableTwoColPanel from '../UI/ExpandableTwoColPanel/ExpandableTwoColPanel';
import { SingleCalculationComponent } from '../UI/SingleCalculationComponent/SingleCalculationComponent';
import TaxDocumentTable from '../TaxDocumentTable';
import { CalculationsList, InputField, Label, NoteField, Row } from './Layout';

const LeftPanelContainer = styled.div``;

const isYearBroken = (year: FinancialYear) =>
  !year.start.endsWith('-01-01') || !year.end.endsWith('-12-31');

type LeftPanelProps = {
  isEditing?: boolean;
  isPrint?: boolean;
};

const LeftPanel = ({
  isEditing = false,
  isPrint = false,
}: LeftPanelProps): JSX.Element | null => {
  const { formatMessage } = useIntl();
  const state = useSelector(currentParticularSalaryFORATaxDocument);

  if (!state) {
    return null;
  }

  const {
    accounts,
    accruedDebtNote,
    accruedDebtToFORA,
    unpaidFORA,
    paidPremiumsPreviousYear,
  } = state;

  return (
    <LeftPanelContainer>
      <Typography style={{ padding: '8px 8px 8px 12px' }} variant="h6">
        {formatMessage({
          id: 'taxCalculations.fora.left.title',
        })}
      </Typography>

      <Row noMargin>
        <TaxDocumentTable
          baseId="taxDocumentView"
          columns={accounts.columns}
          rows={accounts.rows}
          editing={isEditing}
          tableId="particularSalaryTax.fora.accounts"
          isPrint={isPrint}
        />
      </Row>

      <Row>
        <Label>
          {formatMessage({
            id: 'taxCalculations.fora.unpaidFora.label',
          })}
        </Label>
        <InputField
          id="particularSalaryTax.fora.unpaidFORA"
          cell={unpaidFORA}
          isField
          editing={isEditing}
        />
      </Row>
      <Row>
        <Label>
          {formatMessage({
            id: 'taxCalculations.fora.accruelDept.label',
          })}
        </Label>
        {isEditing ? (
          <NoteField
            id="particularSalaryTax.fora.accruedDebtNote"
            cell={accruedDebtNote}
            isField
            editing={isEditing}
            placeholder="Notering..."
          />
        ) : (
          <Typography>{stringValue(accruedDebtNote)}</Typography>
        )}
        <InputField
          id="particularSalaryTax.fora.accruedDebtToFORA"
          cell={accruedDebtToFORA}
          isField
          editing={isEditing}
        />
      </Row>
      <Row>
        <Label>
          {formatMessage({
            id: 'taxCalculations.fora.paidPremiums.label',
          })}
        </Label>
        <InputField
          id="particularSalaryTax.fora.paidPremiumsPreviousYear"
          cell={paidPremiumsPreviousYear}
          isField
          editing={isEditing}
        />
      </Row>
    </LeftPanelContainer>
  );
};

const LeftPanelBroken = (): JSX.Element | null => {
  const { formatMessage } = useIntl();
  const state = useSelector(currentParticularSalaryFORATaxDocument);

  if (!state) {
    return null;
  }

  const { salaryBase } = state;

  return (
    <LeftPanelContainer>
      <Row>
        <Label>
          {formatMessage({
            id: 'taxCalculations.fora.salaryBase.label',
          })}
        </Label>
        <InputField
          id="particularSalaryTax.fora.salaryBase"
          cell={salaryBase}
          isField
          editing={false}
        />
      </Row>
    </LeftPanelContainer>
  );
};

const editablePercentages = [
  'previousYearSeveranceSupplementaryPension',
  'severanceSupplementaryPension',
  'adjustmentOfSalaryBase1',
  'adjustmentOfSalaryBase2',
  'other',
];

type RightPanelProps = {
  isEditing: boolean;
};

const isEditMode = (rowId: string, isEditing: boolean) => {
  switch (rowId) {
    case 'sum':
    case 'totalSum':
    case 'previousYearSum':
      return false;
    case 'previousYearDiff':
      return isEditing;
    default:
      return isEditing;
  }
};

const RightPanel = ({ isEditing }: RightPanelProps): JSX.Element => {
  const state = useSelector(currentParticularSalaryFORATaxDocument);

  const rows = state?.helper.salaryBase.rows;

  if (rows) {
    return (
      <CalculationsList>
        {rows.map((row) => (
          <SingleCalculationComponent
            key={row.id}
            rowId={`particularSalaryTax.fora.helper.salaryBase.${row.id}`}
            isEditMode={isEditMode(row.id, isEditing)}
            rows={row.rows}
            title={row.cells?.title}
            label={row.cells?.label}
            sum={row.cells?.sum}
            value={row.cells?.value}
            percentage={row.cells?.percentage}
            note={row.cells?.note}
            isPercentageEditable={editablePercentages.includes(row.id)}
          />
        ))}
      </CalculationsList>
    );
  }

  return <></>;
};

export const ForaCalculationsLeftPanel = ({
  isEditing = false,
  isPrint = false,
}: LeftPanelProps) => {
  const { financialYearObj, isLastPeriod } = useContext(TaxesDataContext);

  const isBrokenYear = useMemo(
    () => isYearBroken(financialYearObj),
    [financialYearObj]
  );

  return isBrokenYear || !isLastPeriod ? (
    <LeftPanelBroken />
  ) : (
    <LeftPanel isEditing={isEditing} isPrint={isPrint} />
  );
};

export const ForaCalculationsPrint = (): JSX.Element => {
  const { formatMessage } = useIntl();

  return (
    <div>
      <Typography variant="h2">
        {formatMessage({
          id: 'taxCalculations.fora.left-col.title',
        })}
      </Typography>
      <ForaCalculationsLeftPanel isPrint />
    </div>
  );
};

const ForaCalculationsPanel = (): JSX.Element => {
  const { formatMessage } = useIntl();
  const printState = usePrintState();
  const dispatch = useDispatch();

  const { service, financialYear, financialYearObj, isLastPeriod, clientId } =
    useContext(TaxesDataContext);

  const foraData = useSelector(currentParticularSalaryFORATaxDocument);

  // A broken financial year doesn't follow the calendar years
  const isBrokenYear = useMemo(
    () => isYearBroken(financialYearObj),
    [financialYearObj]
  );

  const handleReset = useCallback(() => {
    if (isBrokenYear || !isLastPeriod) {
      service.resetDocumentPart('particularSalaryTax.fora.helper');
    } else {
      const fields = Object.keys(foraData || {});

      fields.forEach((field) => {
        if (field !== 'helper')
          service.resetDocumentPart(`particularSalaryTax.fora.${field}`);
      });
    }
  }, [foraData, isBrokenYear, isLastPeriod, service]);

  const handlePrint = useCallback(async () => {
    try {
      await printDocument(
        clientId,
        financialYear,
        'taxes',
        formatMessage({
          id: 'taxCalculations.fora.left-col.title',
        }),
        ['foraCalculations'],
        printState
      );
    } catch (e) {
      dispatch(addGlobalErrorMessage('error'));
    }
  }, [clientId, dispatch, financialYear, formatMessage, printState]);

  return (
    <ExpandableTwoColPanel
      isExpandable={isBrokenYear || !isLastPeriod}
      leftColTitle={formatMessage({
        id: 'taxCalculations.fora.left-col.title',
      })}
      leftTooltip={formatMessage({
        id: 'taxCalculations.fora.left-col.tooltip',
      })}
      rightColTitle={formatMessage({
        id: 'taxCalculations.fora.right-col.title',
      })}
      rightTooltip={formatMessage({
        id: 'taxCalculations.fora.right-col.tooltip',
      })}
      leftCol={(isEditing) => (
        <ForaCalculationsLeftPanel isEditing={isEditing} />
      )}
      rightCol={(isEditing = false) => {
        return <RightPanel isEditing={isEditing} />;
      }}
      onResetClick={handleReset}
      onPrintClick={handlePrint}
    />
  );
};

export default ForaCalculationsPanel;

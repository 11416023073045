export type SIEMetadata = {
  financialYearStart: string;
  financialYearEnd: string;
  clientName: string;
  organisationNumber: string;
  program: string;
  dateOfLastVoucher: string | null;
  createdAt: string;
  invalidRows?: string[];
};

/**
 * The date format for AccountingPeriod
 */
export const ACCOUNT_PERIOD_DATE_FORMAT = 'yyyy-MM-dd';

export type AccountingPeriod = {
  /**
   * id of period, may look like a date but isn't. It's just a number.
   */
  id: number;
  /**
   * id of financial year, may look like a date but isn't. It's just a number.
   */
  financialYearId: number;
  /**
   * Periods start date, yyyy-MM-dd
   */
  start: string;
  /**
   * Periods end date, yyyy-MM-dd
   */
  end: string;
  /**
   * type, either:
   * - 'month' for a regular month
   * - 'year_end' for the special year end period.
   */
  type: 'month' | 'year_end';
};

export type AccountBalance = {
  /**
   * Incoming balance, 0 for result accounts
   */
  in?: number;

  /**
   * Outgoing balance/result
   */
  out?: number;

  /**
   * The sum of all debit transactions, with cancellation
   */
  debits?: number;

  /**
   * The sum of all credit transactions, with cancellation
   */
  credits?: number;
};

export type AccountingPeriodBalance = {
  in: number;
  out: number;
  transactionCount?: number;
};

export type AccountingAccount = {
  number: string;
  name: string;
  balances: Record<string, AccountBalance>;
  periods: Record<string, AccountingPeriodBalance>;
};

export type FinancialYear = {
  id: number;
  start: string;
  end: string;
};

export type AccountingBalancesResult = {
  accountingBalances: AccountingBalances;
  updatedAt: number;
};

export type AccountingBalances =
  | {
      sourceType: 'file';
      metadata: SIEMetadata;
      financialYears: FinancialYear[];
      periods: AccountingPeriod[];
      accounts: AccountingAccount[];
    }
  | {
      sourceType: 'agoy';
      financialYears: FinancialYear[];
      periods: AccountingPeriod[];
      accounts: AccountingAccount[];
    };

export type AccountingObjects = {
  dimNo: number;
  objectNo: string;
  name: string;
};

export type AccountingTransactionObject = {
  dimNo: number;
  objectNo: string;
};

export type AccountingTransaction = {
  index: number;
  credit: number;
  debit: number;
  date?: string;
  text: string;
  account: string;
  objects?: AccountingTransactionObject[];
  voucherId?: string | null;
};

export type AccountingVoucher = {
  series: string;
  number: number;
  text: string | null;
  date: string;
  regdate: string | null;
  transactions?: AccountingTransaction[];
};

export type AccountingVouchers = {
  pagination: {
    currentPage: number;
    totalPages: number;
    totalResults: number;
  };
  objects?: AccountingObjects[];
  vouchers: AccountingVoucher[];
};

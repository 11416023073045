import React, { useState, useCallback, useEffect } from 'react';
import _ from 'lodash';

type EditFieldProps<T = any> = {
  className?: string;
  value?: string | React.ReactElement;
  onChange?: (label: string) => void;
  component: React.ComponentType<T>;
  disabled?: boolean;
  placeholder?: string;
  topLabel?: string;
  styles?: any;
  handleEnter?: boolean;
  onBlur?: (newValue: string) => void;
  InputProps?: T;
};

const EditField = ({
  className,
  value,
  onChange,
  disabled = false,
  component: Component,
  placeholder,
  topLabel,
  styles,
  handleEnter = true,
  onBlur,
  InputProps = {},
}: EditFieldProps): JSX.Element => {
  const [label, setLabel] = useState(value);

  const { readOnly = false } = InputProps;

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      setLabel(event.currentTarget.value);
    },
    []
  );

  useEffect(() => {
    setLabel(value);
  }, [value, setLabel]);

  const handleBlur = useCallback(() => {
    if (readOnly) return;

    if (onBlur != null && label !== value && !_.isNil(label)) {
      onBlur?.(label.toString());
    }

    if (typeof label !== 'string') return;
    if (label !== value) {
      onChange?.(label);
    }
  }, [onBlur, label, value, onChange, readOnly]);

  const handleKeyPress = useCallback(
    (event) => {
      if (event.key === 'Enter') {
        if (typeof label !== 'string') return;
        if (label !== value) {
          onChange?.(label);
        }
      }
    },
    [onChange, value, label]
  );

  return (
    <Component
      className={className}
      onKeyPress={handleEnter ? handleKeyPress : undefined}
      styles={styles}
      disabled={disabled}
      value={label}
      onChange={handleChange}
      onBlur={handleBlur}
      placeholder={placeholder}
      label={topLabel}
      {...InputProps}
    />
  );
};

export default EditField;

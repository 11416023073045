import React, { memo } from 'react';
import { Tooltip, Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import {
  NumberFormatType,
  StandardNumberFormatType,
  ccyFormat,
  ccyParse,
  formatPercentage,
  parsePercentage,
} from '@agoy/common';
import { FormattingContext } from '@agoy/formatting';
import { ResolvedReference } from '@agoy/document';
import NumberField from './Field/NumberField';
import StringField from './Field/StringField';
import NumberCell from './NumberCell';

interface ReferenceCellProps {
  className?: string;
  id: string;
  editing?: boolean;
  numberFormatType?: NumberFormatType;
  value: ResolvedReference;
  active: boolean;
  placeholder?: string;
  warning?: string;
  isField?: boolean;
}

const ReferenceCell = memo(
  ({
    value,
    className,
    id,
    editing,
    numberFormatType = StandardNumberFormatType,
    active,
    placeholder,
    warning,
    isField,
  }: ReferenceCellProps) => {
    const { formatMessage } = useIntl();
    const formattingContext = React.useContext(FormattingContext);

    const { type, precision } = numberFormatType;
    const cellId = id.split('.').at(-1);
    const numberFormat = type === 'percentage' ? formatPercentage : ccyFormat;
    const numberParser = type === 'percentage' ? parsePercentage : ccyParse;
    const decimals = precision;

    if (editing) {
      if (typeof value === 'string') {
        return (
          <StringField
            className={className}
            id={id}
            value={value}
            isField={isField}
          />
        );
      }
      return (
        <NumberField
          className={className}
          id={id}
          value={typeof value === 'number' && active ? value : undefined}
          formatter={numberFormat}
          parser={numberParser}
          displayDecimals={decimals}
          InputProps={{ disabled: !active }}
          warning={warning}
          isField={isField}
        />
      );
    }
    if (value === undefined || typeof value === 'boolean') {
      return null;
    }
    if (typeof value === 'string') {
      return <span className={className}>{active ? value : ''}</span>;
    }
    if (typeof value === 'object') {
      if (value.error === 'missingPeriod') return null;
      return (
        <Typography
          color={value.error === 'missingAccount' ? 'textPrimary' : 'error'}
          className={className}
          style={{
            textAlign: 'right',
            fontStyle: value.error === 'missingAccount' ? 'italic' : '',
          }}
        >
          {formatMessage({
            id: `ref.error.${value.error}`,
          })}
        </Typography>
      );
    }
    if (cellId === 'accountNumber') {
      return <NumberCell className={className}>{value}</NumberCell>;
    }

    const displayValue = formattingContext.formatNumber(value, type);
    return warning ? (
      <Tooltip title={warning} placement="bottom-end">
        <NumberCell className={className} warning={warning}>
          {active && numberFormat(displayValue, decimals)}
        </NumberCell>
      </Tooltip>
    ) : (
      <NumberCell className={className}>
        {active && numberFormat(displayValue, decimals)}
      </NumberCell>
    );
  }
);

export default ReferenceCell;

import { isEqual } from 'lodash';
import { Source } from '../../../types/source';

export const mergeSource = (
  current: Source | undefined,
  update: Partial<Source> | undefined
): Source | undefined => {
  if (current === undefined) {
    if (update === undefined) {
      return undefined;
    }
    // Check that all required fields are present in the update.
    if (
      'type' in update &&
      'id' in update &&
      'updatedAt' in update &&
      update.type !== undefined &&
      update.updatedAt !== undefined &&
      update.type !== undefined
    ) {
      return update as Source;
    }
    return undefined;
  }
  if (update === undefined) {
    return current;
  }
  const newSource = { ...current, ...update };
  if (isEqual(newSource, current)) {
    return current;
  }
  return newSource;
};

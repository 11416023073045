import React, { useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import Arrow from '@material-ui/icons/Forward';
import ArrowForward from '@material-ui/icons/ArrowForwardIos';
import { IconButton, Tooltip } from '@material-ui/core';
import styled from '@emotion/styled';

import { DocumentPreview } from '_shared/components/UploadDocument';
import { useGroupDocuments } from 'utils/usePeriodDocuments';
import getFileExtension from 'utils/fileExtension';
import { Period } from '@agoy/api-sdk-core';
import PeriodDataContext from '_reconciliation/components/ReconciliationView/HiddenRow/Rows/PeriodDataContext';
import { PeriodDocument } from './GroupDocumentsContext';

interface DocumentPreviewItemProps {
  id: number;
  period: Period;
  document: PeriodDocument;
  onOpenPreviewModal: (id: number | string) => void;
}

const CopyButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  height: 100%;
`;

const DoubleRightIcon = styled(ArrowForward)`
  position: absolute;
  left: 24px;
  font-size: 18px;
`;

const DocumentPreviewItem = ({
  id,
  period,
  document,
  onOpenPreviewModal,
}: DocumentPreviewItemProps): JSX.Element => {
  const { formatMessage } = useIntl();

  const [loading, setLoading] = useState(false);
  const [documentUploadError, setDocumentUploadError] = useState('');

  const { deleteDocument, renameDocument, copyDocument } = useGroupDocuments();

  const { nextPeriod, periodLocked, financialYear, yearEndPeriod } =
    useContext(PeriodDataContext);

  const handleDeleteDocument = async () => {
    try {
      setLoading(true);
      await deleteDocument(document);
    } catch (error) {
      setDocumentUploadError(
        formatMessage({ id: 'hidden.errorDeletingImage' })
      );
    } finally {
      setLoading(false);
    }
  };

  const copyDocumentTo = async (newPeriod: Period) => {
    try {
      setLoading(true);
      if (financialYear) {
        await copyDocument(document, newPeriod);
      }
    } catch (error) {
      setDocumentUploadError(formatMessage({ id: 'hidden.errorCopyingImage' }));
    } finally {
      setLoading(false);
    }
  };

  const handleCopyToNextPeriod = async () => {
    if (nextPeriod) {
      await copyDocumentTo(nextPeriod);
    }
  };

  const handleCopyToLastPeriod = async () => {
    if (yearEndPeriod) {
      await copyDocumentTo(yearEndPeriod);
    }
  };

  const handleDocumentNameSave = async (newDocumentName: string) => {
    try {
      setLoading(true);
      if (financialYear) {
        await renameDocument(
          document,
          `${newDocumentName}${getFileExtension(document.name)}`
        );
      }
      setDocumentUploadError('');
    } catch (error) {
      setDocumentUploadError(
        formatMessage({ id: 'hidden.errorRenamingImage' })
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <DocumentPreview
      id={id}
      loading={loading}
      uploading={!!document.uploading}
      document={document}
      onEditName={handleDocumentNameSave}
      onDelete={handleDeleteDocument}
      deleteDisabled={periodLocked}
      onOpenPreviewModal={onOpenPreviewModal}
      errorMessage={documentUploadError}
      columnButtons
      extraOverlayContent={
        <CopyButtons>
          <Tooltip
            title={formatMessage({ id: 'hidden.transactions.copyToNext' })}
          >
            <span>
              <IconButton
                color="primary"
                disabled={!nextPeriod || !!document.uploading || loading}
                onClick={handleCopyToNextPeriod}
              >
                <Arrow />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip
            title={formatMessage(
              { id: 'hidden.transactions.commentToEnd' },
              { target: formatMessage({ id: 'periodType.yearEndPeriod' }) }
            )}
          >
            <span>
              <IconButton
                color="primary"
                disabled={
                  yearEndPeriod.id === period.id ||
                  !!document.uploading ||
                  loading
                }
                onClick={handleCopyToLastPeriod}
              >
                <DoubleRightIcon />
                <Arrow />
              </IconButton>
            </span>
          </Tooltip>
        </CopyButtons>
      }
    />
  );
};

export default DocumentPreviewItem;

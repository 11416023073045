/* eslint-disable react/destructuring-assignment */
import React from 'react';
import styled from '@emotion/styled';
import ClientProfilePage from 'assets/client_profile_page.jpeg';
import { Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import ClientHeaderTitle from '_shared/components/ClientView/ClientHeaderTitle';

const Header = styled.div`
  background-color: ${(props) => props.theme.palette.secondary.contrastText};
  padding: 2rem 2rem 0.5rem;
`;

const HeaderTitle = styled.div`
  float: left;
`;

const HeaderLogo = styled.div`
  float: right;
`;

const StyledInfo = styled(Typography)`
  font-weight: 700;
`;

const StyledIllustration = styled.img`
  float: right;
  width: 190px;
  height: 90px;
`;

type Props = {
  name?: string;
};

const PersonViewHeader = (props: Props): JSX.Element => {
  const { formatMessage } = useIntl();
  const text = (key: string) => formatMessage({ id: `info.${key}` });

  return (
    <Header>
      <HeaderTitle>
        <ClientHeaderTitle clientName={props.name || ''} clientType="person" />
        <Typography variant="subtitle2">{text('client.page')}</Typography>
      </HeaderTitle>
      <HeaderLogo>
        <StyledIllustration src={ClientProfilePage} alt="Client Profile Page" />
      </HeaderLogo>
    </Header>
  );
};

export default PersonViewHeader;

import React from 'react';
import { Field } from '@agoy/document';
import { Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import styled from '@emotion/styled';

import { IXElement } from '../../../ARKeyToIX';

const Body = styled(Typography)`
  font-size: inherit;
  white-space: pre-line;
`;

type CommentProps = {
  field: Field;
  value: string;
  labelValue?: string;
};

const Comment = ({
  field,
  value,
  labelValue,
}: CommentProps): JSX.Element | null => {
  const { formatMessage } = useIntl();

  const Title = (
    <Typography variant="h4">
      {labelValue ||
        formatMessage({
          id: 'comment',
        })}
    </Typography>
  );

  if (!field || !value) {
    return null;
  }

  const valueByNewLine = value.split('\n');
  valueByNewLine.forEach((item) => item.replace('\n', ''));

  return (
    <>
      {Title}
      <Body key={value}>
        <IXElement arPart={field}>
          {valueByNewLine.map((item) => item)}
        </IXElement>
      </Body>
    </>
  );
};

export default Comment;

import { ReferenceAccountInformation } from '@agoy/document';

import {
  CompaniesAnnualReports,
  DocumentConfiguration,
} from '@agoy/annual-report-document';
import { FinancialReport, FinancialReportCustomerType } from '../types';

import { config as configIndividual } from './individual-company';
import { config as configShares } from './shares-company';

import { references as referencesShares } from './shares-company';

export const config = (
  userName: string,
  customer: FinancialReportCustomerType,
  period: string,
  financialYear: string,
  accounts: Record<string, ReferenceAccountInformation>,
  isSinglePeriod: boolean,
  documentConfiguration: DocumentConfiguration,
  companyType?: keyof CompaniesAnnualReports
): FinancialReport => {
  if (companyType === 'individual') {
    return configIndividual(
      userName,
      customer,
      period,
      financialYear,
      accounts,
      isSinglePeriod
    );
  }

  return configShares(
    userName,
    customer,
    period,
    financialYear,
    accounts,
    isSinglePeriod,
    documentConfiguration
  );
};

export const references = () => {
  // for now it does not seem like we need specific references in the rapport for each company type
  // if (companyType === 'individual') {
  //   return referencesIndividual();
  // }

  return referencesShares();
};

import { getApiSdk, asResultClass } from 'api-sdk';
import { setUiStatus } from 'redux/actions/UI';
import { getContext } from 'utils/AgoyAppClient/contextHolder';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { RootState } from 'redux/reducers';
import {
  StockTransaction,
  StockTotal,
  CompanyConnection,
  ShareOwners,
} from '_client-connections/types';
import PersonActions from '_person/redux/persons/action-types';
import * as CompanyActions from '_clients/redux/customers/action-types';

type ThunkResponse = ThunkAction<void, RootState, unknown, Action<string>>;

export const setConnectionsToCompanies = (
  type: string,
  clientId: string,
  connections: CompanyConnection[]
) => ({
  type,
  clientId,
  connections,
});

export const getClientConnectionsToCompanies =
  (clientId: string, clientType: 'C' | 'P'): ThunkResponse =>
  async (dispatch) => {
    try {
      dispatch(setUiStatus({ updatingConnections: true }));
      const sdk = getApiSdk(getContext());

      const result = await asResultClass(
        sdk.getClientRelations({ clientid: clientId })
      );

      if (result.ok) {
        dispatch(
          setConnectionsToCompanies(
            clientType === 'P'
              ? PersonActions.SET_CONNECTIONS_TO_COMPANIES
              : CompanyActions.SET_CONNECTIONS_TO_COMPANIES,
            clientId,
            result.val
          )
        );
      }
    } finally {
      dispatch(setUiStatus({ updatingConnections: false }));
    }
  };

export const createConnection =
  (
    clientId: string,
    companyId: string,
    stockTransaction?: StockTransaction
  ): ThunkResponse =>
  async () => {
    const sdk = getApiSdk(getContext());

    // prepare the requestBody as is expected by the backend
    const requestBody = {
      relation: {
        fromClientId: clientId,
        toCompanyId: companyId,
      },
      stockTransaction: stockTransaction
        ? {
            ...stockTransaction,
            comment: stockTransaction.comment || undefined,
          }
        : undefined,
    };

    try {
      const result = await asResultClass(
        sdk.addClientRelation({
          clientid: clientId,
          requestBody,
        })
      );

      if (result.err) {
        throw Error(result.val.message);
      }
    } finally {
    }
  };

const actionDeleteConnectionForPerson = (
  type: string,
  clientId: string,
  connectionId: number
) => ({
  type,
  clientId,
  connectionId,
});

export const deleteConnectionToCompany =
  (
    clientId: string,
    connectionId: number,
    clientType: 'C' | 'P'
  ): ThunkResponse =>
  async (dispatch) => {
    dispatch(setUiStatus({ updatingConnections: true }));
    const sdk = getApiSdk(getContext());
    try {
      const result = await asResultClass(
        sdk.deleteClientRelation({
          clientid: clientId,
          relationid: connectionId,
        })
      );

      if (result.err) {
        throw Error(result.val.message);
      }

      dispatch(
        actionDeleteConnectionForPerson(
          clientType === 'P'
            ? PersonActions.DELETE_CONNECTION
            : CompanyActions.DELETE_CONNECTION,
          clientId,
          connectionId
        )
      );

      return result.val;
    } finally {
      dispatch(setUiStatus({ updatingConnections: false }));
    }
  };

export const updateTransactions =
  (
    clientRelationId: number,
    oldTransactions: StockTransaction[],
    newTransactions: StockTransaction[]
  ): ThunkResponse =>
  async (dispatch) => {
    try {
      const sdk = getApiSdk(getContext());

      const toDeletePromises = oldTransactions
        .filter(
          (transaction) =>
            !newTransactions.find((tr) => tr.id === transaction.id)
        )
        .map((transaction) =>
          asResultClass(
            transaction.id
              ? sdk.deleteStockTransaction({ transactionid: transaction.id })
              : Promise.resolve()
          )
        );

      const toAddPromises = newTransactions
        .filter((transaction) => !transaction.id)
        .map((transaction) =>
          asResultClass(
            sdk.addStockTransaction({
              requestBody: {
                clientRelationId,
                shareChange: transaction.shareChange,
                transactionDate: transaction.transactionDate,
                valuePerShare: transaction.valuePerShare,
                comment: transaction.comment || undefined,
              },
            })
          )
        );

      const deleteResult = await Promise.all(toDeletePromises);
      if (deleteResult.some((res) => res.err)) {
        throw Error(deleteResult.find((res) => res.err)?.[0].val.message);
      }

      const createResult = await Promise.all(toAddPromises);
      if (createResult.some((res) => res.err)) {
        throw Error(createResult.find((res) => res.err)?.[0].val.message);
      }
    } finally {
    }
  };

export const updateStockTotals =
  (
    companyId: string,
    oldStockTotals: StockTotal[],
    newStockTotals: StockTotal[]
  ): ThunkResponse =>
  async () => {
    try {
      const sdk = getApiSdk(getContext());

      const toDeletePromises = oldStockTotals
        .filter((total) => !newStockTotals.find((tr) => tr.id === total.id))
        .map((total) =>
          asResultClass(
            total.id
              ? sdk.deleteStockTotal({ clientid: companyId, stockid: total.id })
              : Promise.resolve()
          )
        );

      const toAddPromises = newStockTotals
        .filter((total) => !total.id)
        .map((total) =>
          asResultClass(
            sdk.addStockTotal({
              clientid: companyId,
              requestBody: {
                shareTotal: total.shareTotal,
                type: total.type,
                date: total.date,
                comment: total.comment || undefined,
              },
            })
          )
        );

      const deleteResult = await Promise.all(toDeletePromises);
      if (deleteResult.some((res) => res.err)) {
        throw Error(deleteResult.find((res) => res.err)?.[0].val.message);
      }

      const createResult = await Promise.all(toAddPromises);
      if (createResult.some((res) => res.err)) {
        throw Error(createResult.find((res) => res.err)?.[0].val.message);
      }
    } finally {
    }
  };

export const setCompanyShareOwners = (
  clientId: string,
  owners: ShareOwners[]
) => ({
  type: CompanyActions.SET_COMPANY_SHARE_OWNERS,
  clientId,
  owners,
});

export const getCompanyShareOwners =
  (clientId: string, financialYear?: string): ThunkResponse =>
  async (dispatch) => {
    try {
      const sdk = getApiSdk(getContext());

      const result = await asResultClass(
        sdk.getShareOwnerRelations({ clientid: clientId, financialYear })
      );
      if (result.ok) {
        dispatch(setCompanyShareOwners(clientId, result.val));
      }
    } finally {
    }
  };

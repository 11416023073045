import React, { useContext } from 'react';
import styled from '@emotion/styled';

import SpecificationInfo from '_shared/components/SpecificationInfo';
import { InputData } from '_reconciliation/types';

import SpecificationTable from './SpecificationTable';
import PeriodDataContext from '../PeriodDataContext';

const Content = styled.div`
  width: 100%;
  min-width: 800px;
`;

const TableWrapper = styled.div`
  margin-top: 20px;
`;

type SpecificationPrintModalProps = {
  specification: Client.LegacySpecification[];
  account: string;
  accountName: string;
  yearIB: number | undefined;
  userData: InputData;
};

const SpecificationPrint = ({
  specification,
  account,
  accountName,
  yearIB,
  userData,
}: SpecificationPrintModalProps): JSX.Element => {
  const { period, financialYear } = useContext(PeriodDataContext);

  return (
    <Content>
      {financialYear && yearIB !== undefined && (
        <SpecificationInfo
          period={period}
          financialYear={financialYear}
          inputData={userData}
          accountName={accountName}
          accountNumber={account}
          yearIB={yearIB}
        />
      )}
      <TableWrapper>
        <SpecificationTable
          specification={specification}
          accountNumber={account}
          print
        />
      </TableWrapper>
    </Content>
  );
};

export default SpecificationPrint;

import { Middleware } from 'redux';
import { RootState } from 'redux/reducers';
import {
  UPDATE_FINANCIAL_REPORT_FIELD,
  SET_FIRST_FINANCIAL_YEAR,
  UPDATE_FINANCIAL_REPORT_CHANGES,
  TOGGLE_FINANCIAL_REPORT_FIELD,
  TOGGLE_FINANCIAL_REPORT_SECTION_ACTIVE,
  TOGGLE_FINANCIAL_REPORT_INCOME_STATEMENT_SECTION_ACTIVE,
  TOGGLE_FINANCIAL_REPORT_SETTINGS_PARAMETER,
  TOGGLE_FINANCIAL_REPORT_ADDITIONAL_TEXT_ACTIVE,
} from '_financial-report/redux/action-types';
import debounceByKey from 'utils/debounceByKey';
import { storeFinancialReport } from 'Api/Client/financialReport';
import { FinancialReportChanges } from '@agoy/financial-report-document';
import {
  savingUserDataFailed,
  savingUserDataSuccess,
  startedSavingUserData,
} from '_reconciliation/redux/accounting-view/actions';
import { isClientPeriodAction } from 'redux/actions';
import { selectClientPeriod } from '_financial-report/redux/selectors';
import { currentPeriod } from '_clients/redux/customer-view/selectors';

const triggers = [
  UPDATE_FINANCIAL_REPORT_FIELD,
  SET_FIRST_FINANCIAL_YEAR,
  UPDATE_FINANCIAL_REPORT_CHANGES,
  TOGGLE_FINANCIAL_REPORT_FIELD,
  TOGGLE_FINANCIAL_REPORT_SECTION_ACTIVE,
  TOGGLE_FINANCIAL_REPORT_INCOME_STATEMENT_SECTION_ACTIVE,
  TOGGLE_FINANCIAL_REPORT_SETTINGS_PARAMETER,
  TOGGLE_FINANCIAL_REPORT_ADDITIONAL_TEXT_ACTIVE,
];

const saveFinancialReport = async (
  dispatch,
  customerId: string,
  periodId: number,
  changes: FinancialReportChanges
) => {
  dispatch(startedSavingUserData());
  try {
    await storeFinancialReport(customerId, periodId, changes);
    dispatch(savingUserDataSuccess());
  } catch (e) {
    dispatch(savingUserDataFailed());
  }
};

const key = (
  dispatch,
  customerId: string,
  periodId: number,
  changes: FinancialReportChanges
): string => `${customerId}_${periodId}`;

const save = debounceByKey(key, saveFinancialReport, 2000);

const saveFinancialReportMiddleware: Middleware<{}, RootState> =
  (store) => (next) => (action) => {
    if (isClientPeriodAction(action)) {
      const { clientId, period } = action;
      const prevState = selectClientPeriod(store.getState(), clientId, period);
      const result = next(action);
      const financialReport = selectClientPeriod(
        store.getState(),
        clientId,
        period
      );
      if (
        prevState &&
        financialReport &&
        prevState.changes !== financialReport.changes &&
        triggers.includes(action.type)
      ) {
        const { currentCustomer } = store.getState().customerView;
        const periodId = currentPeriod(store.getState())?.id;
        if (currentCustomer && periodId) {
          save(
            store.dispatch,
            currentCustomer,
            periodId,
            financialReport.changes
          );
        }
      }
      return result;
    }

    return next(action);
  };

export default saveFinancialReportMiddleware;

import {
  AgoyDocumentNode,
  AgoyTable,
  BooleanCell,
  Field,
  ReferenceCell,
} from '@agoy/document';
import { AnnualReport, AnnualReportSection } from '../../document';
import { IxbrlField } from '../index';
import { notes } from '../../shares-company/types';

export interface ValidForReportType {
  k2: boolean | BooleanCell | undefined;
  k3: boolean | BooleanCell | undefined;
}

export interface NotesSection {
  validForReportType: ValidForReportType;
  number: Field;
  name: Field;
  group: Field; //*: Property, that contain information about what group note belongs to ("IncomeStatement", "BalanceSheet" or "Other");
  section: Field; //*: Property, that contain information about where / in which section current note was referenced ("CashFlowStatement, IncomeStatement, BalanceSheet, ManagementReport");
}

export interface HeadingsSection extends AnnualReportSection, NotesSection {
  notesIncomeStatementTitle: Field;
  notesBalanceSheetTitle: Field;
  notesOtherTitle: Field;
}

export interface Note2Section extends AnnualReportSection, NotesSection {
  noEmployees: Field;
  avgNumberOfEmployees: Field;
  avgNumberOfEmployeesTable: AgoyTable;
  commentLabel: Field;
  comment: IxbrlField;
}

export interface NoteOneTableSection extends AnnualReportSection, NotesSection {
  table: AgoyTable;
  commentLabel: Field;
  comment: IxbrlField;
}

export interface NoteOneTableWithSum extends NoteOneTableSection {
  sumTable: AgoyTable;
}

export interface NoteTwoTableSection extends AnnualReportSection, NotesSection {
  table: AgoyTable;
  secondTable: AgoyTable;
  commentLabel: Field;
  comment: IxbrlField;
}

export interface NoteTwoTableSectionWithSum extends NoteTwoTableSection {
  sumTable: AgoyTable;
}

export interface NoteTwoTableSectionWithTwoSum extends NoteTwoTableSection {
  sumTable: AgoyTable;
  secondSumTable: AgoyTable;
}

export interface NoteThreeCommentTwoTableSection
  extends AnnualReportSection,
    NotesSection {
  table: AgoyTable;
  firstTableCommentLabel: Field;
  firstTableComment: IxbrlField;
  secondTableTitle: IxbrlField;
  secondTable: AgoyTable;
  secondTableCommentLabel: Field;
  secondTableComment: IxbrlField;
  commentLabel: Field;
  comment: IxbrlField;
}

export interface NoteThreeCommentThreeTableSection
  extends AnnualReportSection,
    NotesSection {
  table: AgoyTable;
  firstTableCommentLabel: Field;
  firstTableComment: IxbrlField;
  secondTable: AgoyTable;
  thirdTable: AgoyTable;
  thirdTableCommentLabel: Field;
  thirdTableComment: IxbrlField;
  commentLabel: Field;
  comment: IxbrlField;
}

export interface NoteSixTableSection extends AnnualReportSection, NotesSection {
  table: AgoyTable;
  tableCommentLabel: Field;
  tableComment: IxbrlField;
  secondTable: AgoyTable;
  secondTableCommentLabel: Field;
  secondTableComment: IxbrlField;
  thirdTable: AgoyTable;
  thirdTableCommentLabel: Field;
  thirdTableComment: IxbrlField;
  fourthTable: AgoyTable;
  fourthTableCommentLabel: Field;
  fourthTableComment: IxbrlField;
  fifthTable: AgoyTable;
  fifthTableCommentLabel: Field;
  fifthTableComment: IxbrlField;
  sixthTable: AgoyTable;
  sixthTableCommentLabel: Field;
  sixthTableComment: IxbrlField;
  commentLabel: Field;
  comment: IxbrlField;
}

export interface NoteFiveTableTwoCommentSection
  extends AnnualReportSection,
    NotesSection {
  table: AgoyTable;
  tableCommentLabel: Field;
  tableComment: IxbrlField;
  secondTable: AgoyTable;
  secondTableCommentLabel: Field;
  secondTableComment: IxbrlField;
  thirdTable: AgoyTable;
  thirdTableCommentLabel: Field;
  thirdTableComment: IxbrlField;
  fourthTable: AgoyTable;
  fourthTableCommentLabel: Field;
  fourthTableComment: IxbrlField;
  fifthTable: AgoyTable;
  fifthTableCommentLabel: Field;
  fifthTableComment: IxbrlField;
  sixthCommentLabel: Field;
  sixthComment: IxbrlField;
  commentLabel: Field;
  comment: IxbrlField;
}

export interface NoteFourTablesWithCommentsAndSum
  extends AnnualReportSection,
    NotesSection {
  table: AgoyTable;
  firstTableCommentLabel: Field;
  firstTableComment: IxbrlField;

  secondTable: AgoyTable;
  secondTableCommentLabel: Field;
  secondTableComment: IxbrlField;

  thirdTable: AgoyTable;
  thirdTableCommentLabel: Field;
  thirdTableComment: IxbrlField;

  fourthTable: AgoyTable;
  fourthTableComment?: IxbrlField;

  sumTable: AgoyTable;
  commentLabel: Field;
  comment: IxbrlField;
}

export interface NoteFourTablesWithCaptionsCommentsAndSum
  extends AnnualReportSection,
    NotesSection {
  subTitle: Field;
  tableCaption: Field;
  table: AgoyTable;
  firstTableCommentLabel: Field;
  firstTableComment: IxbrlField;

  secondTableCaption: Field;
  secondTable: AgoyTable;
  secondTableCommentLabel: Field;
  secondTableComment: IxbrlField;

  thirdTableCaption: Field;
  thirdTable: AgoyTable;
  thirdTableCommentLabel: Field;
  thirdTableComment: IxbrlField;

  fourthTableCaption: Field;
  fourthTable: AgoyTable;
  fourthTableCommentLabel?: Field;
  fourthTableComment?: IxbrlField;

  sumTable: AgoyTable;
  commentLabel: Field;
  comment: IxbrlField;
}

export interface Note28Section extends NoteTwoTableSection {
  general: Field;
  sumTable: AgoyTable;
  commentLabel: Field;
}

/**
 * Note 74 consists of 8 tables
 */
export interface Note74Section extends AnnualReportSection, NotesSection {
  noEmployeesTable: AgoyTable;
  noEmployeesTableCommentLabel: Field;
  noEmployeesTableComment: IxbrlField;
  noEmployeesPerCountryTable: AgoyTable;
  noEmployeesPerCountryTableCommentLabel: Field;
  noEmployeesPerCountryTableComment: IxbrlField;
  noOfBoardMembersAndExecutivesTable: AgoyTable;
  noOfCEOAndOtherExecutivesTable: AgoyTable;
  noOfCEOAndOtherExecutivesTableCommentLabel: Field;
  noOfCEOAndOtherExecutivesTableComment: IxbrlField;
  salariesAndBenefitsTable: AgoyTable;
  salariesAndBenefitsSumTable: AgoyTable;
  salariesAndBenefitsTableCommentLabel: Field;
  salariesAndBenefitsTableComment: IxbrlField;
  socialCostsTable: AgoyTable;
  socialCostsSumTable: AgoyTable;
  socialCostsTableCommentLabel: Field;
  socialCostsTableComment: IxbrlField;
  pensionCostsTable: AgoyTable;
  pensionCostsTableCommentLabel: Field;
  pensionCostsTableComment: IxbrlField;
  specificationOfSalariesPensionsAndOtherBenefitsTable: AgoyTable;
  specificationOfSalariesPensionsAndOtherBenefitsTableCommentLabel: Field;
  specificationOfSalariesPensionsAndOtherBenefitsTableComment: IxbrlField;
  nrOfPeopleUnderTheCompanyManagementTable: AgoyTable;
  severancePayAgreementLabel: Field;
  severancePayAgreement: IxbrlField;
}
export type NoteTable<T extends string> = {
  id: T;
  title?: string;
  rows: {
    id: string;
    label: string;
    elementName: string;
    contextRef: string;
    saldo?: 'credit' | 'debit';
    refGenerator?: (column: string) => ReferenceCell;
    isTitleRow?: boolean;
    type?: 'monetaryItemType' | 'pureItemType' | 'stringItemType';
    unitRef?: 'procent' | 'antal-anstallda' | 'SEK';
  }[];
};

export type Note84Tables =
  | 'table'
  | 'secondTable'
  | 'thirdTable'
  | 'fourthTable'
  | 'fifthTable'
  | 'sixthTable';
export type Note86Tables = 'table' | 'sumTable';
export type Note93Tables = 'table' | 'secondTable';

export type Note84Section = AnnualReportSection &
  NotesSection &
  {
    [key in NoteTable<Note84Tables>['id']]: AgoyTable;
  } & {
    commentLabel: Field;
    comment: IxbrlField;
    secondCommentLabel: Field;
    secondComment: IxbrlField;
  };

export interface NoteInputFieldSection
  extends AnnualReportSection,
    NotesSection {
  inputField: Field;
  comment: IxbrlField;
  commentLabel: Field;
}

export interface NoteInputFieldSectionNoComment
  extends AnnualReportSection,
    NotesSection {
  inputField: Field;
}

export interface CustomNotesSection extends AnnualReportSection, NotesSection {
  commentTitle: Field;
  typeOne: Field;
  typeTwo: AgoyTable;
  typeThree: AgoyTable;
}

/**
 * To use with notes 16, 18 and 20 while in this one
 * the secondTable comes first in the config. That was done
 * in order to persist legacy values.
 */
export interface NoteInvertedTwoTableSection
  extends AnnualReportSection,
    NotesSection {
  secondTable: AgoyTable;
  table: AgoyTable;
  commentLabel: Field;
  comment: IxbrlField;
}

export interface Note95Section extends NotesSection {
  active: boolean;
  tables: {
    sections: [
      {
        type: IxbrlField;
        year: IxbrlField;
        previousYear: IxbrlField;
        table: AgoyTable;
        customYear: IxbrlField;
        customPreviousYear: IxbrlField;
        customTable: AgoyTable;
      }
    ];
  };
  sumTable: AgoyTable;
}

export type Note95 = AgoyDocumentNode<typeof notes.children.note95>;
export interface Note100Section extends NotesSection {
  active: boolean;
  tables: {
    sections: [
      {
        type: IxbrlField;
        year: IxbrlField;
        previousYear: IxbrlField;
        table: AgoyTable;
      }
    ];
  };
}

export type Note100 = AgoyDocumentNode<typeof notes.children.note100>;

export type CustomNotes = AnnualReport['notes']['custom'];

export const cell = { type: 'field' as const };
export const boolean = { type: 'boolean' as const };

export const noteHeadingsStructure = {
  type: 'part' as const,
  children: {
    active: boolean,
    number: cell,
    name: cell,
    notesIncomeStatementTitle: cell,
    notesBalanceSheetTitle: cell,
    notesOtherTitle: cell,
    group: cell,
    section: cell,
    validForReportType: {
      type: 'part' as const,
      children: {
        k2: boolean,
        k3: boolean,
      },
    },
  },
};

export const noteOneTableSectionStructure = {
  type: 'part' as const,
  children: {
    active: boolean,
    number: cell,
    name: cell,
    table: { type: 'table' as const },
    commentLabel: cell,
    comment: cell,
    group: cell,
    section: cell,
    validForReportType: {
      type: 'part' as const,
      children: {
        k2: boolean,
        k3: boolean,
      },
    },
  },
};

export const noteTwoTableSectionStructure = {
  type: 'part' as const,
  children: {
    active: boolean,
    number: cell,
    name: cell,
    table: { type: 'table' as const },
    secondTable: { type: 'table' as const },
    commentLabel: cell,
    comment: cell,
    group: cell,
    section: cell,
    validForReportType: {
      type: 'part' as const,
      children: {
        k2: boolean,
        k3: boolean,
      },
    },
  },
};

export const noteInputFieldSectionStructure = {
  type: 'part' as const,
  children: {
    active: boolean,
    number: cell,
    name: cell,
    inputField: cell,
    commentLabel: cell,
    comment: cell,
    group: cell,
    section: cell,
    validForReportType: {
      type: 'part' as const,
      children: {
        k2: boolean,
        k3: boolean,
      },
    },
  },
};

export const noteInvertedTwoTableSectionStructure = {
  type: 'part' as const,
  children: {
    active: boolean,
    number: cell,
    name: cell,
    secondTable: { type: 'table' as const },
    table: { type: 'table' as const },
    commentLabel: cell,
    comment: cell,
    group: cell,
    section: cell,
    validForReportType: {
      type: 'part' as const,
      children: {
        k2: boolean,
        k3: boolean,
      },
    },
  },
};

export const noteOneTableWithSumStructure = {
  type: 'part' as const,
  children: {
    active: boolean,
    number: cell,
    name: cell,
    table: { type: 'table' as const },
    sumTable: { type: 'table' as const },
    commentLabel: cell,
    comment: cell,
    group: cell,
    section: cell,
    validForReportType: {
      type: 'part' as const,
      children: {
        k2: boolean,
        k3: boolean,
      },
    },
  },
};

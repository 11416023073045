import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';
import { useSelector } from 'redux/reducers';
import { addClient } from '_clients/redux/customers/actions';
import { ClientCompanyType } from '_clients/types/types';
import { asResultClass, useApiSdk } from 'api-sdk';
import { mapClient } from '_clients/services/mappings';
import { setUiStatus } from 'redux/actions/UI';
import CompanySharesOwnersTable from '_client-connections/components/CompanySharesOwnersTable';
import { getCompanyShareOwners } from '_client-connections/redux/persons/actions';
import LoadingPlaceholder from '_shared/components/LoadingPlaceholder';

const Container = styled.div`
  flex: 1;
  height: 100%;
  max-width: 1200px;
  width: 75%;
  min-width: 850px;
`;

const CompanyOwnersView = ({
  client,
}: {
  client: ClientCompanyType;
}): JSX.Element => {
  const dispatch = useDispatch();
  const { getClientById } = useApiSdk();

  const isLoading = useSelector((state) => state.ui.updatingConnections);

  useEffect(() => {
    (async () => {
      dispatch(setUiStatus({ updatingConnections: true }));

      const result = await asResultClass(
        getClientById({ clientid: client.id })
      );

      if (result.ok) {
        dispatch(addClient(mapClient(result.val)));
      }

      await dispatch(getCompanyShareOwners(client.id));

      dispatch(setUiStatus({ updatingConnections: false }));
    })();
  }, []);

  return (
    <Container>
      {isLoading ? (
        <LoadingPlaceholder />
      ) : (
        <CompanySharesOwnersTable client={client} />
      )}
    </Container>
  );
};

export default CompanyOwnersView;

import jwt_decode from 'jwt-decode';

/**
 * AwsAsync.tsx
 *
 * Amazon functions rewritten to use promises so that we can use async/await
 * instead of callback spaghetti
 */

import { createUserPool } from 'utils/AuthenticatedClient';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import { camelToSnake } from 'utils/camelSnake';

interface UserAuth {
  username: string;
  password: string;
}

export const resendConfirmation = async (email: string) => {
  return new Promise((resolve, reject) => {
    const userPool = createUserPool();

    const userData = {
      Username: email,
      Pool: userPool,
    };

    const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

    if (cognitoUser !== null) {
      cognitoUser.resendConfirmationCode((err, result) => {
        if (err) {
          reject(err);
        }
        resolve(result);
      });
    }
  });
};

export const authenticateUser = ({
  username,
  password,
}: UserAuth): Promise<AmazonCognitoIdentity.CognitoUserSession> => {
  const lowerCaseUsername = username.toLowerCase();
  return new Promise((resolve, reject) => {
    const userPool = createUserPool();
    const authenticationDetails =
      new AmazonCognitoIdentity.AuthenticationDetails({
        Username: lowerCaseUsername,
        Password: password,
      });
    const userData = {
      Username: lowerCaseUsername,
      Pool: userPool,
    };
    const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: async (session) => {
        resolve(session);
      },
      onFailure: reject,
    });
  });
};

export const refreshSession = (refreshToken) => {
  return new Promise((resolve, reject) => {
    const userPool = createUserPool();
    const cognitoUser = userPool.getCurrentUser();

    if (cognitoUser != null) {
      cognitoUser.refreshSession(refreshToken, (err, newSession) => {
        if (err) {
          return reject(err);
        }
        return resolve(newSession);
      });
    }
  });
};

const parseJWTClaims = (claims: any) => {
  const newClaims = { ...claims };
  newClaims.roles = JSON.parse(newClaims['custom:roles']);
  delete newClaims['custom:roles'];

  newClaims.organisationId = newClaims['custom:organisationId'];
  delete newClaims['custom:organisationId'];

  newClaims.personNr = newClaims['custom:person_nr'];
  delete newClaims['custom:person_nr'];

  return newClaims;
};

interface GetCurrentSessionProps {
  returnUserAttributes?: boolean;
  forceRefreshSession?: boolean;
}

export const getCurrentSession = (
  options?: GetCurrentSessionProps
): Promise<{
  session: Record<string, unknown> | null;
  userAttributes?: Record<string, unknown>;
}> => {
  const { returnUserAttributes, forceRefreshSession } = options || {
    returnUserAttributes: false,
  };
  return new Promise((resolve, reject) => {
    const userPool = createUserPool();
    const cognitoUser = userPool.getCurrentUser();

    if (cognitoUser != null) {
      cognitoUser.getSession(async (sessionError, session) => {
        if (!session) {
          return resolve({
            session: null,
          });
        }

        let newSession = session;

        if (sessionError) {
          return reject(sessionError);
        }

        if (forceRefreshSession || !session.isValid()) {
          newSession = await refreshSession(session.getRefreshToken());
        }

        const token = newSession.getIdToken().getJwtToken();
        const claims: Partial<Member.MemberType> = parseJWTClaims(
          jwt_decode(token)
        );

        const resolveData = { session: newSession, userAttributes: {} };

        if (returnUserAttributes) {
          resolveData.userAttributes = claims;
        }

        return resolve(resolveData);
      });
    } else {
      resolve({
        session: null,
      });
    }
  });
};

interface UserAttributes {
  organisationId: string;
  email: string;
}

export const getUserAttributes = () => {
  return new Promise<Partial<UserAttributes> | void>((resolve, reject) => {
    const userPool = createUserPool();
    const cognitoUser = userPool.getCurrentUser();

    if (cognitoUser !== null) {
      cognitoUser.getSession((err, session) => {
        if (err) {
          reject(err);
          return;
        }

        const token = session.getIdToken().getJwtToken();
        const claims: Partial<Member.MemberType> = jwt_decode(token);

        parseJWTClaims(claims);

        resolve(claims);
      });
    } else {
      reject(Error('Cognito user can not be null'));
    }
  });
};

export const forgetPasswordRequest = ({ username }) => {
  return new Promise<Partial<UserAttributes>>((resolve, reject) => {
    const userPool = createUserPool();
    const cognitoUser = new AmazonCognitoIdentity.CognitoUser({
      Username: username.toLowerCase(),
      Pool: userPool,
    });
    cognitoUser.forgotPassword({
      onSuccess: async (data) => {
        resolve(data);
      },
      onFailure: async (err) => {
        reject(err);
      },
    });
  });
};

interface UserSignUpArgs {
  username: string;
  password: string;
  givenName: string;
  familyName: string;
}

export const userSignUp = ({
  username,
  password,
  givenName,
  familyName,
}: UserSignUpArgs) => {
  const userAttributeGivenName = new AmazonCognitoIdentity.CognitoUserAttribute(
    {
      Name: 'given_name',
      Value: givenName,
    }
  );

  const userAttributeFamilyName =
    new AmazonCognitoIdentity.CognitoUserAttribute({
      Name: 'family_name',
      Value: familyName,
    });

  return new Promise((resolve, reject) => {
    const userPool = createUserPool();
    userPool.signUp(
      username.toLowerCase(),
      password,
      [userAttributeGivenName, userAttributeFamilyName],
      [],
      (error, result) => {
        if (error) {
          reject(error);
        } else if (result) {
          resolve(result.user);
        }
      }
    );
  });
};

type SetUserAttributes = {
  [index: string]: string | number | boolean | undefined;
};

export const setUserAttributes = (attributes: SetUserAttributes) => {
  return new Promise((resolve, reject) => {
    const attributeList: AmazonCognitoIdentity.ICognitoUserAttributeData[] = [];
    Object.keys(attributes).forEach((key) => {
      const value = attributes[key];
      if (typeof value === 'string') {
        attributeList.push({
          Name: camelToSnake(key === 'personNr' ? 'custom:personNr' : key),
          Value: value,
        });
      }
    });

    const userPool = createUserPool();
    const cognitoUser = userPool.getCurrentUser();

    if (cognitoUser !== null) {
      cognitoUser.getSession((sessionError, session) => {
        cognitoUser.updateAttributes(attributeList, async (err, result) => {
          if (err) {
            reject(err);
          } else {
            await refreshSession(session.getRefreshToken());
            resolve(result);
          }
        });
      });
    } else {
      reject(Error('Cognito user can not be null'));
    }
  });
};

interface ConfirmUser {
  username: string;
  code: string;
}

export const confirmUser = ({
  username,
  code,
}: ConfirmUser): Promise<Record<string, unknown>> => {
  return new Promise((resolve, reject) => {
    const userPool = createUserPool();
    const userData = {
      Username: username.toLowerCase(),
      Pool: userPool,
    };
    const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
    cognitoUser.confirmRegistration(code, true, (err, result) => {
      if (err) {
        reject(err);
      } else {
        resolve(result);
      }
    });
  });
};

export const changeUserPassword = (oldPassword, newPassword) => {
  return new Promise((resolve, reject) => {
    const userPool = createUserPool();
    const cognitoUser = userPool.getCurrentUser();

    if (cognitoUser !== null) {
      cognitoUser.getSession((err) => {
        if (err) {
          reject(err);
        } else {
          cognitoUser.changePassword(
            oldPassword,
            newPassword,
            (pwdError, result) => {
              if (pwdError) {
                // eslint-disable-next-line no-console
                console.error(pwdError);
                reject(pwdError);
              } else {
                resolve(result);
              }
            }
          );
        }
      });
    } else {
      reject(Error('Cognito user can not be null'));
    }
  });
};

interface ConfirmPassword extends ConfirmUser {
  newPassword: string;
}

export const confirmNewPassword = ({
  username,
  code,
  newPassword,
}: ConfirmPassword): Promise<Record<string, unknown> | void> => {
  return new Promise((resolve, reject) => {
    const userPool = createUserPool();
    const cognitoUser = new AmazonCognitoIdentity.CognitoUser({
      Username: username.toLowerCase(),
      Pool: userPool,
    });

    cognitoUser.confirmPassword(code, newPassword, {
      onFailure: (err) => {
        reject(err);
      },
      onSuccess: () => {
        resolve();
      },
    });
  });
};

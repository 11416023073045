/**
 * Replaces an object in an array by looking at the property `id`.
 *
 * Note that if multiple object have the same id, they all will be replaced
 * by the same object.
 *
 * @param array items to replace among, must be objects with property `id: string`
 * @param item  The item to replace with
 * @returns A new array with the item replaced.
 */
export const replaceById = <T extends { id: string }>(
  array: T[] | undefined,
  item: T
): T[] | undefined => array?.map((i) => (i.id === item.id ? item : i));

/**
 * Replaces an object in an array by looking at the properties `id` and `type`.
 *
 * Note that if multiple object have the same id, they all will be replaced
 * by the same object.
 *
 * @param array items to replace among, must be objects with properties `id: string, type: string`
 * @param item  The item to replace with
 * @returns A new array with the item replaced.
 */
export const replaceByIdAndType = <T extends { id: string; type: string }>(
  array: T[] | undefined,
  item: T
): T[] | undefined =>
  array?.map((i) => (i.id === item.id && i.type === item.type ? item : i));

/**
 * Helper function to replace an item in an array without mutating the
 * orginal array.
 *
 * @param array Array, may be undefined
 * @param index 0-based index
 * @param item Item to insert.
 * @returns a new array with the item at `index` replaced with `item`. If the array
 * is undefined, then it will return undefined.
 */
export const replaceByIndex = <T>(
  array: T[] | undefined,
  index: number,
  item: T
): T[] | undefined =>
  array
    ? [...array.slice(0, index), item, ...array.slice(index + 1)]
    : undefined;

/**
 * Helper function to add items without mutating.
 *
 * @param array Array to append
 * @param item Item
 * @returns New array with `item` appended last
 */
export const addItem = <T>(array: T[] | undefined, item: T): T[] =>
  array ? [...array, item] : [item];

/**
 * Inserts a `item` in `array` at `index` without mutating.
 *
 * @param array
 * @param item
 * @param index
 * @returns
 */
export const insertAt = <T>(array: T[], item: T, index: number): T[] => [
  ...array.slice(0, index),
  item,
  ...array.slice(index),
];

/**
 * Helper function parse a custom section id.
 *
 * @param section a section-level id, in format "[name]-[no]"
 * @returns A two-element array with name and number
 */
export const splitSection = (section: string): [string, number] => {
  const [name, no] = section.split('-');
  return [name, parseInt(no)];
};

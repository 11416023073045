import { ToggleFinancialReportBalanceSheetSectionActive } from '../../action-type-declarations';
import { FinancialReportClientPeriodState } from '../types';

const toggleFinancialReportBalanceSheetSectionActive = (
  state: FinancialReportClientPeriodState,
  action: ToggleFinancialReportBalanceSheetSectionActive
) => {
  const { section } = action;

  if (!state.report) {
    return state;
  }

  return {
    ...state,
    report: {
      ...state.report,
      balanceSheet: {
        ...state.report.balanceSheet,
        section: {
          ...state.report.balanceSheet.section,
          [section]: {
            ...state.report.balanceSheet.section[section],
            active: !state.report.balanceSheet.section[section].active,
          },
        },
      },
    },

    changes: {
      ...state.changes,
      balanceSheet: {
        ...state.changes.balanceSheet,
        section: {
          ...state.changes.balanceSheet?.section,
          [section]: {
            active: !state.report.balanceSheet.section[section].active,
          },
        },
      },
    },
  };
};

export default toggleFinancialReportBalanceSheetSectionActive;

import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import LockIcon from '@material-ui/icons/Lock';
import { Typography } from '@material-ui/core';

export type PeriodItem = {
  period: string;
  label: string;
  statusColor?: string;
  locked: boolean;
};

const Timeline = styled.div`
  display: flex;
`;

const TimelineEntry = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  color: ${({ theme }) => theme.palette.text.secondary};
  width: 56px;
  overflow-x: visible;
`;

const TaxIconWrapper = styled.div`
  pointer-events: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;

  color: ${({ theme }) => theme.palette.text.primary};

  &[data-statuscolor] {
    color: white;
  }

  > * {
    padding: ${({ theme }) => theme.spacing(1 / 2)}px;
    width: 100%;
    height: 100%;
  }
  svg path {
    fill: ${(props) => props['data-statuscolor']} !important;
  }

  :hover {
    cursor: pointer;
  }
`;
const EntryLabel = styled(Typography)`
  padding: ${({ theme }) => theme.spacing(0, 1 / 2)};
  border-radius: ${({ theme }) => theme.spacing(1 / 4)}px;
  text-transform: capitalize;
  line-height: 1.2rem;
  white-space: nowrap;
  color: ${(props) =>
    props['data-selected'] ? props.theme.palette.common.white : 'inherit'};
  background: ${(props) =>
    props['data-selected'] ? props.theme.palette.primary.main : 'inherit'};
`;

const EntrySeparator = styled.div`
  display: flex;
  align-items: center;
  width: ${({ theme }) => theme.spacing(3)}px;
  height: 32px;
`;

const Line = styled.div`
  background: ${({ theme }) => theme.palette.grey[400]};
  height: 2px;
  width: 100%;
`;

const LockDiv = styled.div`
  pointer-events: none;
  position: absolute;
  margin-left: 23px;
  margin-top: -5px;
  color: #00313c;
`;

type Props = {
  periods: PeriodItem[];
  selectedPeriod: string | null;
  icon: React.ReactNode;
  onPeriodClick?: (period: string) => void;
};

export const PeriodTimeline = ({
  periods,
  selectedPeriod,
  icon,
  onPeriodClick,
}: Props): JSX.Element => {
  const onClick = useCallback(
    (period) => {
      if (onPeriodClick) onPeriodClick(period);
    },
    [onPeriodClick]
  );

  if (!periods || periods.length < 1) return <div>No periods found</div>;

  return (
    <Timeline>
      {periods.map((period, index) => {
        return (
          <React.Fragment key={period.label}>
            <TimelineEntry>
              <TaxIconWrapper
                data-statuscolor={period?.statusColor}
                data-selected={selectedPeriod?.startsWith(period.period)}
                onClick={() => {
                  onClick(period.period);
                }}
              >
                {icon}
              </TaxIconWrapper>
              <EntryLabel
                variant="body2"
                data-selected={selectedPeriod?.startsWith(period.period)}
              >
                {period.label}
              </EntryLabel>
              {period.locked && (
                <LockDiv>
                  <LockIcon fontSize="small" />
                </LockDiv>
              )}
            </TimelineEntry>
            {index + 1 < periods.length && (
              <EntrySeparator key={Math.random() * 100}>
                <Line />
              </EntrySeparator>
            )}
          </React.Fragment>
        );
      })}
    </Timeline>
  );
};

export default PeriodTimeline;

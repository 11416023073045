import React from 'react';
import { TableContainer, Table, Paper } from '@material-ui/core';
import styled from '@emotion/styled';
import {
  AgoyTableRow,
  AgoyTableColumn,
  Cell as CellType,
} from '@agoy/document';
import { CommonNotes } from '@agoy/annual-report-document';
import NotesContext from './Context/NotesContext';
import { NumberFormatType } from '@agoy/common';
import TableBody, { SubRowType } from './TableBody';
import TableHead from './TableHead';
import noRows from './noRows';

interface TableProps {
  className?: string;
  baseId: string;
  tableId: string;
  rows: AgoyTableRow[];
  columns: AgoyTableColumn[];
  notes?: CommonNotes;
  hideHeader?: boolean;
  isXBRLRender?: boolean;
  numberFormat?: (rowId: string) => NumberFormatType;
  numberFormatCell?: (colId: string) => NumberFormatType;
  renderRow?: (subRow: SubRowType) => React.ReactElement | null;
  renderCell?: (values: {
    cell: CellType;
    column: AgoyTableColumn;
    row: AgoyTableRow;
    baseId: string;
  }) => React.ReactElement;
}

const StyledTableContainer = styled(({ ...props }) => (
  <TableContainer {...props} component={Paper} />
))`
  overflow-x: visible;
  width: 100%;

  .MuiTableCell-root {
    border: none;
  }

  box-shadow: none;
`;

const shouldShowPercentColumn = (
  rows: AgoyTableRow[],
  typeTable: 'percent' | 'procent'
) => {
  let value: boolean | undefined;
  rows.forEach((row) => {
    const rowValue = row?.cells?.[typeTable] as { type: string; value: string };
    if (rowValue?.value) value = true;
  });
  return !!value;
};

const CommonPrintTable = ({
  className,
  baseId,
  tableId,
  rows,
  columns,
  notes,
  hideHeader = false,
  isXBRLRender = false,
  numberFormat,
  numberFormatCell,
  renderRow,
  renderCell,
}: TableProps): JSX.Element | null => {
  let currentColumns: AgoyTableColumn[] = [];

  const newColumns: AgoyTableColumn[] = [];
  const columnsWithSortKey: AgoyTableColumn[] = [];

  columns.forEach((item) => {
    if (item.id === 'percent' && !shouldShowPercentColumn(rows, item.id))
      return;
    if (item.id === 'procent' && !shouldShowPercentColumn(rows, item.id))
      return;
    if (typeof item.sortKey === 'number') {
      columnsWithSortKey.push(item);
    } else {
      newColumns.push(item);
    }
  });
  columnsWithSortKey.sort(
    (a: AgoyTableColumn, b: AgoyTableColumn) =>
      (a?.sortKey || 0) - (b?.sortKey || 0)
  );
  columnsWithSortKey.forEach((item) => {
    newColumns.splice(item.sortKey || 0, 0, item);
  });
  currentColumns = newColumns;

  if (noRows(rows)) {
    return null;
  }

  return (
    <NotesContext.Provider value={notes}>
      <StyledTableContainer className={className}>
        <Table>
          {!hideHeader && (
            <TableHead
              baseId={baseId}
              tableId={tableId}
              columns={currentColumns}
            />
          )}
          <TableBody
            tableId={tableId}
            columns={currentColumns}
            rows={rows}
            numberFormat={numberFormat}
            numberFormatCell={numberFormatCell}
            renderRow={renderRow}
            renderCell={renderCell}
            isXBRLRender={isXBRLRender}
          />
        </Table>
      </StyledTableContainer>
    </NotesContext.Provider>
  );
};

export default CommonPrintTable;

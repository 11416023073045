import {
  NotesSection,
  CommonNotes,
  NotesStructure,
  NOTES_DIGITAL_K2_NOTES_NOTE,
  AnnualReportType,
  K3_NOTES_NOTE_ID,
} from '@agoy/annual-report-document';
import { stringValue } from '@agoy/document';
import { GroupedNotes, IdNote, NoteItem } from './types';

const idPattern = /id\((.*).number\)/;

export const referencedNotes = (references: string[]): string[] =>
  references
    .map((ref) => idPattern.exec(ref)?.[1] || '')
    .filter((ref) => ref !== '');

const mapCustomNotes = (key: string, notes: NotesSection[]): IdNote[] =>
  notes
    .map((note, index) => ({ id: `notes.${key}-${index}`, note }))
    .filter((note) => note.note);

export const mapNotes = (notes: CommonNotes | undefined): IdNote[] => {
  if (!notes) {
    return [];
  }

  return Object.keys(notes)
    .filter((key) => key !== 'active' && key !== 'headings' && notes[key])
    .flatMap((key: string): IdNote[] =>
      notes[key].sections
        ? mapCustomNotes(key, notes[key].sections)
        : [{ id: `notes.${key}`, note: notes[key] }]
    );
};

export const getNotesByReportType = (
  notes: IdNote[],
  reportType: AnnualReportType | undefined,
  isDigitalSubmission: boolean
): IdNote[] => {
  if (!reportType || !['k2', 'k3'].includes(reportType)) {
    return notes;
  }

  const noteToCheck = [
    ...NOTES_DIGITAL_K2_NOTES_NOTE(),
    ...(reportType === 'k3' ? K3_NOTES_NOTE_ID() : []),
  ];

  return (
    isDigitalSubmission
      ? notes.filter((note: IdNote) => noteToCheck.includes(note.id))
      : notes
  ).filter((note: IdNote) => note.note.validForReportType[reportType]);
};

export const groupNotesByStructure = (
  notesSectionName: string,
  noteGroups: NotesStructure,
  allNotes: IdNote[]
): GroupedNotes => {
  const showIncomeStatementNotes =
    notesSectionName === 'incomeStatement' ||
    notesSectionName === 'cashFlowStatement';

  const showBalanceSheetNotes =
    notesSectionName === 'balanceSheet' ||
    notesSectionName === 'cashFlowStatement';

  const getNoteGroup = (note: IdNote): keyof GroupedNotes | undefined => {
    const noteNumber = note.id.replace('notes.', '');
    const checkNote = (noteGroup) => noteGroups[noteGroup].includes(noteNumber);

    if (showIncomeStatementNotes && checkNote('incomeStatementNotes')) {
      return 'incomeStatementNotes';
    }
    if (showBalanceSheetNotes && checkNote('balanceSheetNotes')) {
      return 'balanceSheetNotes';
    }
    if (checkNote('otherNotes') || noteNumber.includes('custom')) {
      return 'otherNotes';
    }
    return undefined;
  };

  const groupedNotes: GroupedNotes = {
    incomeStatementNotes: [],
    balanceSheetNotes: [],
    otherNotes: [],
  };

  allNotes.forEach((note: IdNote) => {
    const sectionValue = stringValue(note.note.section);
    const noteSection = getNoteGroup(note);

    if (noteSection) {
      groupedNotes[noteSection].push({
        ...note,
        disabled: !!sectionValue && sectionValue !== notesSectionName,
      });
    }
  });

  return groupedNotes;
};

export const sortNotes = (
  notes: NoteItem[],
  initialSelection: string[]
): NoteItem[] => {
  const getSortValue = (note: NoteItem) => {
    if (!note.disabled) {
      return initialSelection.includes(note.id) ? 1 : 0;
    }
    return -1;
  };

  return notes.sort((a, b) => getSortValue(b) - getSortValue(a));
};

export const filterNotes = (
  notes: NoteItem[],
  searchValue: string
): NoteItem[] =>
  notes.filter((note) =>
    (stringValue(note.note.name) || '').toLowerCase().includes(searchValue)
  );

import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { AgoyTable, AgoyTableRow, AgoyTableColumn } from '@agoy/document';
import { Table } from '../../../UI/CommonPrintTable';
import {
  flattenRows,
  hasPreviousYearColumn,
  includeStringRow,
} from '../../../utils/utils';

const StyledSixColumnTable = styled(Table)`
  margin-bottom: 20px;

  .MuiTableCell-root {
    min-width: 58px;
    max-width: 150px;

    &:first-of-type {
      width: auto;
    }

    &:last-child {
      width: 150px;
    }
  }
`;
interface SixColumnTableProps {
  table: AgoyTable;
  tableId: string;
  hasPreviousYear?: boolean;
  className?: string;
}

const PrintCustomSixColumnTable = ({
  table,
  tableId,
  hasPreviousYear = false,
  className,
}: SixColumnTableProps): JSX.Element => {
  const [rows, setRows] = useState<AgoyTableRow[]>([]);
  const [columns, setColumns] = useState<AgoyTableColumn[]>([]);

  useEffect(() => {
    const newRows = flattenRows(table.rows).filter((row) =>
      includeStringRow(row)
    );
    setRows(newRows);
  }, [table.rows]);

  useEffect(() => {
    const newColumns = table.columns.filter(
      (col) => hasPreviousYear || !hasPreviousYearColumn(col)
    );
    setColumns(newColumns);
  }, [hasPreviousYear, table.columns]);

  return (
    <StyledSixColumnTable
      baseId="annualReport"
      className={className}
      tableId={tableId}
      rows={rows}
      columns={columns}
    />
  );
};

export default PrintCustomSixColumnTable;

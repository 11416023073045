import {
  BooleanCell,
  NumberCell,
  StringCell,
  ReferenceCell,
  FormatMessageCell,
  Cell,
} from '../Cell';

export type FieldProperties = {
  multiline?: boolean;
};

export type Field = (
  | NumberCell
  | StringCell
  | ReferenceCell
  | BooleanCell
  | FormatMessageCell
) & {
  active?: boolean | ReferenceCell;
  visible?: boolean | ReferenceCell;
  level?: number;
  props?: FieldProperties;
};

/**
 * All cell types are not valid field types, returns true for the cell types that
 * are included in the Field type.
 *
 * @param cell Cell
 */
export const isFieldType = (cell: Cell): cell is Field => {
  switch (cell.type) {
    case 'boolean':
    case 'number':
    case 'string':
    case 'msg':
    case 'ref':
      return true;
    default:
      return false;
  }
};

export const isDefined = <T>(item: T | undefined): item is T =>
  item !== undefined;

export const isUndefined = (item: unknown): item is undefined =>
  item === undefined;

export const isNotNull = <T>(item: T | null): item is T => item !== null;

type PropNonNullable<T, TKey extends keyof T> = T &
  { [P in TKey]-?: NonNullable<T[P]> };

/**
 * Returns a predicate for use in array filtering etc. that checks that a
 * property of the object is not undefined or null.
 *
 * @param key
 * @returns
 */
export const hasValue =
  <T, TKey extends keyof T>(key: TKey) =>
  (vals: T): vals is PropNonNullable<T, TKey> =>
    vals != null && vals[key] != null;

import {
  TaxDeclarationFormDefinition,
  TaxDeclarationFormDataConfig,
  PersonMetaData,
} from '../../types';
import { ref, value, id } from '@agoy/document';
import { mapFormId, toInitial, toStructure } from '../forms-util';

const configK7 = (
  form: TaxDeclarationFormDefinition,
  person: PersonMetaData
): TaxDeclarationFormDataConfig => {
  const name = `${person?.firstName || ''} ${person?.lastName || ''}`;
  const K7 = mapFormId(form, 'K7');
  // NORMAL FIELDS: Y - 2
  // Checkbox: Y + 2 X + 2
  const initial = {
    K7: {
      partType: 'form' as const,
      id: K7,
      fields: {
        datum: value(''),
        numreringFleraK7: value(undefined),
        namn: value(name),
        personnummer: value(person.personNumber || ''),
        A_fastighetens_beteckning: value(''),
        A_forsaljningen_delforsaljning: value(false),
        A_forsaljningsdatum: value(''),
        A_fastighetens_taxeringsvarde: value(false),
        A_inkopssdatum: value(''),
        B_1_forsaljningspris: value(undefined),
        B_2_forsaljningsutgifter: value(undefined),
        B_3_inkopspris: value(undefined),
        B_4_forbattringsutgifter_grundforbattringar: value(undefined),
        B_5_forbattringsutgifter_forbattrande: value(undefined),
        B_6_avskrivningar: value(undefined),
        B_7_aterforing: value(undefined),
        B_8_vinst_eller_forlust: value(undefined),
        B_9_andel_procent: value(undefined),
        B_9_andel_kronor: value(undefined),
        B_10_aterforing_uppskovsbelopp: value(undefined),
        B_11_vinst_eller_forlust: value(undefined),
        B_12_avgar_fran_vinst: value(undefined),
        B_13_vinst: value(undefined),
        B_14_forlust: value(undefined),
      },
      derivedFields: {
        B_forlust: ref(id('K7.fields.B_14_forlust')),
      },
      sru: {
        '7014': ref(id('K7.fields.numreringFleraK7')),
        '4100': ref(id('K7.fields.A_fastighetens_beteckning')),
        '4105': ref(id('K7.fields.A_forsaljningen_delforsaljning')),
        '4101': ref(id('K7.fields.A_forsaljningsdatum')),
        '4102': ref(id('K7.fields.A_fastighetens_taxeringsvarde')),
        '4104': ref(id('K7.fields.A_inkopssdatum')),
        '4110': ref(id('K7.fields.B_1_forsaljningspris')),
        '4111': ref(id('K7.fields.B_2_forsaljningsutgifter')),
        '4112': ref(id('K7.fields.B_3_inkopspris')),
        '4113': ref(
          id('K7.fields.B_4_forbattringsutgifter_grundforbattringar')
        ),
        '4114': ref(id('K7.fields.B_5_forbattringsutgifter_forbattrande')),
        '4115': ref(id('K7.fields.B_6_avskrivningar')),
        '4116': ref(id('K7.fields.B_7_aterforing')),
        '4117': ref(id('K7.fields.B_8_vinst_eller_forlust')),
        '4118': ref(id('K7.fields.B_9_andel_procent')),
        '4119': ref(id('K7.fields.B_9_andel_kronor')),
        '4120': ref(id('K7.fields.B_10_aterforing_uppskovsbelopp')),
        '4121': ref(id('K7.fields.B_11_vinst_eller_forlust')),
        '4122': ref(id('K7.fields.B_12_avgar_fran_vinst')),
        '4123': ref(id('K7.fields.B_13_vinst')),
        '4124': ref(id('K7.fields.B_14_forlust')),
      },
    },
  };
  return {
    initial: toInitial(initial, form),
    definition: toStructure(initial, form),
  };
};

export default configK7;

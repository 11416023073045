import React, { useEffect, useMemo, useState } from 'react';
import {
  AgoyTableColumn,
  AgoyTableRow,
  Cell,
  StringCell,
} from '@agoy/document';
import { Table, TableServiceType } from '_shared/components/CommonTable';
import { NumberFormatType } from '@agoy/common';

type Props = {
  isEditing: boolean;
  isPrint?: boolean;
  defaultColumnKeys: string[];
  initialColumnKeys?: string[];
  baseId?: string;
  defaultColumnTypes?: Record<string, string>;
  tableId: string;
  initialColumns: AgoyTableColumn[];
  initialData?: AgoyTableRow[];
  nonEditableRowColumns?: string[];
  columnsWithClicking?: string[];
  canDragRows?: boolean;
  headerCellTitle?: string;
  onHeaderColumnPress?: (column: AgoyTableColumn | undefined) => void;
  onRowUpdate?: (data: AgoyTableRow | undefined) => void;
  onDataUpdate?: (data: ExpandableTableData) => void;
  onDefaultColumnLabelUpdate?: (oldColumn: string, newColumn: string) => void;
  renderFooterRow?: () => React.ReactElement;
  columnsWithoutDrag?: string[];
  widthFitContent?: boolean;
  numberFormatColumn?: (id: string) => NumberFormatType;
  defaultCells?: Cell | {};
  handleShowRowInfo?: (
    cells: Record<string, Cell> | undefined
  ) => string | undefined;
};

export type ExpandableTableData = {
  columns: AgoyTableColumn[];
  rows: AgoyTableRow[];
};

const setCellValueInRow = (
  row: AgoyTableRow,
  keyToOverride: string,
  value: string | undefined | boolean | number
) => {
  return {
    ...row,
    cells: Object.keys(row.cells ?? []).reduce((result, key) => {
      result[key] = (row.cells ?? [])[key];

      if (key === keyToOverride) {
        result[key] = {
          ...result[key],
          value,
        };
      }

      return result;
    }, {}),
  };
};

const setCheckboxValueInRow = (
  row: AgoyTableRow,
  keyToOverride: string,
  value: Cell | string | number | undefined
) => {
  let cellValue;
  if (typeof value !== 'number' && typeof value !== 'string' && value) {
    if (value.type === 'boolean') {
      cellValue = value.value;
    }
  } else {
    cellValue = value;
  }
  return {
    ...row,
    cells: Object.keys(row.cells ?? []).reduce((result, key) => {
      result[key] = (row.cells ?? [])[key];

      if (key === keyToOverride) {
        result[key] = {
          ...result[key],
          value: cellValue,
        };
      }

      return result;
    }, {}),
  };
};

const ExpandableTable = ({
  isEditing,
  baseId = 'hidden',
  tableId,
  defaultColumnKeys = [],
  initialColumnKeys = defaultColumnKeys,
  initialColumns = [],
  isPrint = false,
  onRowUpdate,
  onDataUpdate,
  onHeaderColumnPress,
  columnsWithClicking,
  initialData = [],
  canDragRows = true,
  headerCellTitle,
  columnsWithoutDrag = [],
  widthFitContent = true,
  numberFormatColumn,
  defaultCells,
  defaultColumnTypes = {},
  nonEditableRowColumns = [],
  renderFooterRow,
  onDefaultColumnLabelUpdate = (_oldColumn: string, _newColumn: string) => {},
  handleShowRowInfo,
}: Props) => {
  const [columns, setColumns] = useState<AgoyTableColumn[]>(initialColumns);

  const emitDataUpdate = (data: ExpandableTableData) => {
    if (onDataUpdate) {
      onDataUpdate(data);
    }
  };

  const setRowsAndEmitUpdate = (newRows: AgoyTableRow[]) => {
    setRows(newRows);
    emitDataUpdate({
      rows: newRows,
      columns,
    });
  };

  const emitRowUpdate = (row: AgoyTableRow) => {
    if (onRowUpdate) {
      onRowUpdate(row);
    }
  };

  const setColumnsAndEmitUpdate = (newColumns: AgoyTableColumn[]) => {
    setColumns(newColumns);
    emitDataUpdate({
      columns: newColumns,
      rows,
    });
  };

  const setNewDataAndEmitUpdate = (newData: ExpandableTableData) => {
    setColumns(newData.columns);
    setRows(newData.rows);

    emitDataUpdate(newData);
  };

  const generateEmptyRowCells = () => {
    if (defaultCells) {
      return defaultCells;
    }
    return columns.reduce((res, col) => {
      return {
        ...res,
        [col.id]: {
          type: defaultColumnTypes[col.id] ?? 'string',
          value: defaultColumnTypes[col.id] === 'number' ? 0 : '',
        },
      };
    }, {});
  };

  const initialDataWithEmptyCellsFilled = initialData.map((data) => {
    const allKeys = initialColumns.map((col) => col.id);

    return {
      ...data,
      cells: allKeys.reduce((res, key) => {
        res[key] =
          data.cells && data.cells[key]
            ? data.cells[key]
            : {
                type: defaultColumnTypes[key] || 'string',
                value: defaultColumnTypes[key] === 'number' ? 0 : '',
              };

        return res;
      }, {}),
    };
  });

  const [rows, setRows] = useState<AgoyTableRow[]>(
    initialDataWithEmptyCellsFilled
  );

  const renameDefaultColumn = (oldColumn, newColumn) => {
    onDefaultColumnLabelUpdate(oldColumn, newColumn);
  };

  const renameColumn = (oldColumn, newColumn) => {
    const newRows = rows.map((row) => {
      const cells = {
        ...row.cells,
      };
      cells[newColumn] = cells[oldColumn];
      delete cells[oldColumn];

      return {
        ...row,
        cells,
      };
    });

    const newColumns = columns.map((column) => {
      if (column.id === oldColumn) {
        return {
          id: newColumn,
          label: newColumn,
        };
      }

      return column;
    });

    setNewDataAndEmitUpdate({
      columns: newColumns,
      rows: newRows,
    });
  };

  const handleUpdateCell = (
    cellId: string,
    value: string | number | boolean | undefined
  ) => {
    const [_, indexPlusOne, cellKey] = cellId.split('.');
    if (indexPlusOne && cellId) {
      const newRows = rows.map((row, i) => {
        if (i + 1 === parseInt(indexPlusOne)) {
          const newRow = setCellValueInRow(row, cellKey, value);
          emitRowUpdate(newRow);
          return newRow;
        }

        return row;
      });
      setRowsAndEmitUpdate(newRows);
    }
  };

  const handleUpdateCheckbox = (
    cellId: string,
    value: Cell | string | number | undefined
  ) => {
    const [_, indexPlusOne, cellKey] = cellId.split('.');
    if (indexPlusOne && cellId) {
      const newRows = rows.map((row, i) => {
        if (i + 1 === parseInt(indexPlusOne)) {
          const newRow = setCheckboxValueInRow(row, cellKey, value);
          emitRowUpdate(newRow);
          return newRow;
        }

        return row;
      });

      setRowsAndEmitUpdate(newRows);
    }
  };

  const handleUpdateColumnLabel = (columnId: string, value: string) => {
    const [_, columnLabel] = columnId.split('.');

    if (defaultColumnKeys.includes(columnLabel)) {
      renameDefaultColumn(columnLabel, value);
    } else {
      renameColumn(columnLabel, value);
    }
  };

  const handleUpdateColumnSortKey = (columnsWithSortKeys) => {
    if (columnsWithSortKeys.length > 0) {
      const newColumns = columns;
      const to = columnsWithSortKeys[0].sortKey;
      const [_, key] = columnsWithSortKeys[0].id.split('.');
      const from = columns.findIndex((column) => column.id === key);

      if (from > -1) {
        newColumns.splice(to, 0, newColumns.splice(from, 1)[0]);
        setColumnsAndEmitUpdate(newColumns);
      }
    }
  };

  const handleUpdateRowsSortKey = (rowsWithSortKeys) => {
    if (rowsWithSortKeys.length > 0) {
      const newRows = rows;

      const to = rowsWithSortKeys[0].sortKey;
      const [_, key] = rowsWithSortKeys[0].id.split('.');
      const from = rows.findIndex((column) => column.id === key);

      if (from > -1) {
        newRows.splice(to, 0, newRows.splice(from, 1)[0]);
        setRowsAndEmitUpdate(newRows);
      }
    }
  };

  const handleDeleteRow = (pathId: string) => {
    const [_tableId, rowId] = pathId.split('.');

    const newRows = rows.filter((row) => row.id !== rowId);

    setRowsAndEmitUpdate(newRows);
  };

  const onAddRow = () => {
    setRowsAndEmitUpdate([
      ...rows,
      {
        id: `${rows.length + 1}`,
        active: true,
        cells: generateEmptyRowCells(),
      },
    ]);
  };

  const onAddColumn = () => {
    const newColumns = [
      ...columns,
      {
        id: `new_column${columns.length}`,
        label: '',
      },
    ];

    const newRows = rows.map((row) => {
      return {
        ...row,
        cells: {
          ...row.cells,
          [`new_column${columns.length}`]: {
            type: 'string',
            value: '',
          } as StringCell,
        },
      };
    });

    setNewDataAndEmitUpdate({
      columns: newColumns,
      rows: newRows,
    });
  };

  const handleDeleteColumn = (pathId: string) => {
    const [_tableId, columnId] = pathId.split('.');

    const newColumns = columns.filter((column) => column.id !== columnId);
    const newRows = rows.map((row) => {
      return {
        ...row,
        cells: Object.keys(row.cells ?? {}).reduce((cell, key) => {
          if (key !== columnId) {
            cell[key] = row.cells![key];
          }

          return cell;
        }, {}),
      };
    });

    setNewDataAndEmitUpdate({
      columns: newColumns,
      rows: newRows,
    });
  };

  const service = useMemo(() => {
    const newService = {} as TableServiceType;
    newService.updateField = handleUpdateCell;
    newService.updateColumnLabel = handleUpdateColumnLabel;
    newService.updateColumnSortKey = handleUpdateColumnSortKey;
    newService.deleteRow = handleDeleteRow;
    newService.deleteColumn = handleDeleteColumn;
    newService.updateCellValue = handleUpdateCheckbox;
    newService.updateRows = handleUpdateRowsSortKey;

    return newService;
  }, [handleUpdateCell]);

  useEffect(() => {
    setColumns(initialColumns);
  }, [initialColumns]);

  useEffect(() => {
    setRows(initialDataWithEmptyCellsFilled);
  }, [initialData]);

  return (
    <Table
      baseId={baseId}
      tableId={tableId}
      rows={rows}
      columns={columns}
      service={service}
      canAddRows
      canDeleteColumns
      canDragColumns
      canDragRows={canDragRows}
      canDeleteRows
      canEditColumns
      canAddColumns
      headerCellTitle={headerCellTitle}
      numberFormatColumn={numberFormatColumn}
      widthFitContent={widthFitContent}
      columnsWithoutDelete={defaultColumnKeys}
      columnsWithoutEditing={initialColumnKeys}
      columnsWithoutDrag={columnsWithoutDrag}
      editing={isEditing}
      onColumnBlur={handleUpdateCell}
      print={isPrint}
      onAddRow={onAddRow}
      onAddColumn={onAddColumn}
      nonEditableRowColumns={nonEditableRowColumns}
      renderFooterRow={renderFooterRow}
      onHeaderColumnPress={onHeaderColumnPress}
      columnsWithClicking={columnsWithClicking}
      handleShowRowInfo={handleShowRowInfo}
    />
  );
};

export default ExpandableTable;

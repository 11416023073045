export const sanitizeOrgNumber = (orgNumber: string): string => {
  return orgNumber.trim();
};

/**
 * Matches if two organisation numbers are the same
 * @param firstOrgNr
 * @param secondOrgNr
 * @returns boolean
 */
export const isMatchingOrgNumbers = (
  firstOrgNr: string,
  secondOrgNr: string
): boolean => {
  return stripOrgNumber(firstOrgNr) === stripOrgNumber(secondOrgNr);
};

export const stripOrgNumber = (orgNumber: string): string =>
  orgNumber.replace(' ', '').replace('-', '');

import React, { useMemo } from 'react';
import { Typography } from '@material-ui/core';

import { MultiReferenceCell } from '@agoy/document';
import useDocumentConfiguration from '_annual-report/components/AnnualReportView/useDocumentConfiguration';
import useNotesSelectionList from '_shared/hooks/useNotesSelectionList';
import Select from './Select';

interface NoteSelectorProps {
  className?: string;
  cellId: string;
  cell: MultiReferenceCell;
  active?: boolean;
  editing?: boolean;
}

type EditNoteSelectorProps = Omit<NoteSelectorProps, 'editing'>;

const EditingNoteSelector = ({
  cell,
  cellId,
  active,
  className = '',
}: EditNoteSelectorProps) => {
  const { reportType, isDigitalSubmission } = useDocumentConfiguration() || {};

  const { selectedNotes, groupedNotes } = useNotesSelectionList(
    cell,
    cellId.split('.')[0]
  );

  const textValue = useMemo(() => cell.values.join(', '), [cell]);

  if (!reportType || typeof isDigitalSubmission !== 'boolean') {
    return null;
  }

  return (
    <Select
      cellId={cellId}
      selectedNotes={selectedNotes}
      noteGroups={groupedNotes}
      disabled={!active}
      textValue={textValue}
      className={className}
    />
  );
};

const NoteSelector = (props: NoteSelectorProps) => {
  const { editing, cell, className = '' } = props;

  return editing ? (
    <EditingNoteSelector {...props} />
  ) : (
    <Typography className={`notes ${className}`}>
      {cell.values.join(', ')}
    </Typography>
  );
};

export default NoteSelector;

import { annualGeneralMeeting } from '../shares-company/annual-general-meeting/types';

/**
 * Content definition for the stand-alone Årsstämmoprotokoll document.
 */
export const annualGeneralMeetingContentDefinition = {
  type: 'document' as const,
  children: {
    annualReport: {
      type: 'externalDocument' as const,
      documentType: 'annualReport',
    },
    annualGeneralMeeting,
  },
  documentType: 'annualGeneralMeeting' as const,
};

import { measurements } from '@agoy/theme';

const {
  overviewCellPadding,
  overviewColumnMargin,
  overviewStickyColumnMargin,
  overviewRowMargin,
  overviewTableWidth,
  appHeaderHeight,
  floatinfFabMargin,
  minWidthStickyColumns,
} = measurements;

export {
  overviewCellPadding,
  overviewColumnMargin,
  overviewStickyColumnMargin,
  overviewRowMargin,
  overviewTableWidth,
  appHeaderHeight,
  floatinfFabMargin,
  minWidthStickyColumns,
};

import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { IxbrlField, Note95Section } from '@agoy/annual-report-document';
import { numberValue, stringValue } from '@agoy/document';
import { Note } from '../../UI/Note';
import Comment from '../../UI/Comment';
import Header from '../../UI/Header';
import NoteTable from '../../UI/NoteTable';
import { Cell } from '../../../../UI';

type Note100Props = {
  noteId: string;
  hasPreviousYear?: boolean;
  comment: IxbrlField;
  commentLabel: IxbrlField;
} & Note95Section;

const Note100 = ({
  noteId,
  hasPreviousYear,
  comment,
  commentLabel,
  ...note
}: Note100Props): JSX.Element | null => {
  const { active, number, tables } = note;

  if (!active) return null;

  const title = stringValue(note.name) || '';

  return (
    <Note id={noteId}>
      <Header noteId={noteId} noteNumber={numberValue(number)} title={title} />
      {tables.sections.map((section, index) => {
        if (section === null) {
          return null;
        }

        // If the user leaves the custom year field empty, the value will be an empty string.
        if (section.year.value === '') {
          section.year.value = ' ';
          section.previousYear.value = ' ';
        }

        return (
          <Box mt={1} mb={1} key={section.year.ixbrl?.tuple?.id}>
            <div style={{ display: 'none' }}>
              <>
                <Cell cell={section.year} />
                <Cell cell={section.previousYear} />
              </>
            </div>
            <Typography>{stringValue(section.year)}</Typography>
            <NoteTable
              table={section.table}
              hasPreviousYear={hasPreviousYear}
              tableId={`notes.${noteId}.tables.${index}.table`}
              displayUnfiltered
            />
          </Box>
        );
      })}
      <Comment
        field={comment}
        labelValue={stringValue(commentLabel) || ''}
        value={stringValue(comment) || ''}
      />
    </Note>
  );
};

export default Note100;

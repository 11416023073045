import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { Container, Button, Grid } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import styled from '@emotion/styled';
import { isValidPersonalNumber, normalizePersonalNumber } from '@agoy/common';

import { CTAButton } from '_shared/components/Buttons';
import FormError from '_shared/components/Inputs/FormError';
import UserClientImage from 'utils/UserClientImage';
import {
  IMAGE_SIZE_LIMIT,
  IMAGE_MIN_WIDTH_LIMIT,
  IMAGE_MAX_WIDTH_LIMIT,
  IMAGE_MAX_HEIGHT_LIMIT,
} from 'contants';
import { SimplifiedTextfield } from '_shared/components/Inputs';
import getImageResolution from 'utils/getImageResolution';
import { UserType } from '_users/types';
import When from '_shared/components/When/When';
import PersonalNumber from '_shared/components/PersonalNumber';
import { Label } from '_shared/components/Inputs/GenericSimplefied';

interface WrapperProps {
  editing: boolean;
}

const ProfileEditContainer = styled.div`
  overflow: auto;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: ${(props) => `${props.theme.spacing(4)}px;`} 0 0 0;
  > * {
    margin: 0 0 0 ${(props) => `${props.theme.spacing(2)}px;`};
  }
`;

const Wrapper = styled.div<WrapperProps>`
  display: block;
  margin: auto;
`;

// Remove the arrows from the input's number type
const PersonNrField = styled(SimplifiedTextfield)`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

type Props = {
  isFortnoxWhiteLabel: boolean;
  user: UserType;
  sendErrorMessage: (message: string) => void;
  handleProfileUpdate: (profileImage: File) => Promise<void>;
  handleMemberUpdate: (newMemberData: Partial<UserType>) => Promise<void>;
  handleUpdatePassword: (
    oldPassword: string,
    newPassword: string
  ) => Promise<void>;
};

const ProfileEdit = ({
  isFortnoxWhiteLabel,
  user,
  sendErrorMessage,
  handleProfileUpdate,
  handleMemberUpdate,
  handleUpdatePassword,
}: Props) => {
  const { formatMessage } = useIntl();

  const {
    givenName,
    familyName,
    fullName,
    email,
    phoneNumber,
    personNr,
    picture,
  } = user;

  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const [submitError, setSubmitError] = useState('');
  const [saveEnabled, setSaveEnabled] = useState(false);
  const [newProfilePicture, setNewProfilePicture] = useState<File | null>(null);

  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields },
    watch,
    clearErrors,
    reset,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      fullName: '',
      givenName,
      familyName,
      phoneNumber,
      personNr,
      newPassword: '',
      oldPassword: '',
    },
  });

  const newGivenName = watch('givenName');
  const newFamilyName = watch('familyName');
  const newPhoneNumber = watch('phoneNumber');
  const newPassword = watch('newPassword');
  const newPersonNr = watch('personNr');

  const PasswordError = 'Error setting password';

  const onSubmit = async (data) => {
    setLoading(true);

    // Storing profile picture
    if (newProfilePicture) {
      try {
        await handleProfileUpdate(newProfilePicture);
      } catch (error: any) {
        if (error.message === 'Incorrect mimetype') {
          setSubmitError(formatMessage({ id: 'hidden.invalidFileType' }));
        } else if (error.message === 'File too big') {
          setSubmitError(formatMessage({ id: 'file.tooBig' }));
        }
      }
    }

    // change password
    if (data.newPassword) {
      await handleUpdatePassword(data.oldPassword, data.newPassword);
    }

    // Save and update user attributes -> could be a FETCH
    if (Object.values(touchedFields).indexOf(true) !== -1) {
      try {
        const normalizedPersonNumber = normalizePersonalNumber(
          data.personNr,
          true,
          true
        );
        await handleMemberUpdate({
          givenName: data.givenName,
          familyName: data.familyName,
          phoneNumber: data.phoneNumber,
          personNr: normalizedPersonNumber,
        });
      } catch (error: any) {
        console.error(error);
        switch (error) {
          case error.givenName:
            setSubmitError('Error setting given name');
            break;
          case error.familyName:
            setSubmitError('Error setting family name');
            break;
          case error.phoneNumber:
            setSubmitError('Error setting phone number');
            break;
          default:
            setSubmitError('Error setting personal information');
        }
      }
    }

    setDone(true);
    reset({
      givenName: newGivenName,
      familyName: newFamilyName,
      phoneNumber: newPhoneNumber,
      personNr: newPersonNr,
      newPassword: '',
    });

    setLoading(false);
    toggleEdit();
  };

  const toggleEdit = () => {
    if (submitError !== PasswordError) {
      setEditing(!editing);
      setSubmitError('');
    } else {
      setEditing(true);
    }
    clearErrors();
  };

  const handlePictureChange = async (file: File) => {
    if (file) {
      if (file.size > IMAGE_SIZE_LIMIT) {
        sendErrorMessage(formatMessage({ id: 'error.imageSize' }));
        return;
      }

      try {
        const fileAsDataURL = URL.createObjectURL(file);
        const { width, height } = await getImageResolution(fileAsDataURL);

        if (width < IMAGE_MIN_WIDTH_LIMIT) {
          sendErrorMessage(formatMessage({ id: 'error.imageMinWidth' }));
          return;
        }

        if (width > IMAGE_MAX_WIDTH_LIMIT || height > IMAGE_MAX_HEIGHT_LIMIT) {
          sendErrorMessage(formatMessage({ id: 'error.imageMaxWidth' }));
          return;
        }
      } catch (e) {
        sendErrorMessage(formatMessage({ id: 'error.imageonly' }));
      }

      setSaveEnabled(true);
      setNewProfilePicture(file);
    }
  };

  const handleChange = (e) => {
    if (e.target.value !== Object.values(touchedFields)) setSaveEnabled(true);
  };

  return (
    <ProfileEditContainer>
      <Container maxWidth="sm">
        <UserClientImage
          editing={editing}
          sendErrorMessage={sendErrorMessage}
          handleChange={handlePictureChange}
          square={false}
          imageUrl={
            newProfilePicture ? URL.createObjectURL(newProfilePicture) : picture
          }
        />
        <form onSubmit={handleSubmit(onSubmit)}>
          {submitError !== '' && <FormError>{submitError}</FormError>}

          {/* Name */}
          <Wrapper editing={editing}>
            {isFortnoxWhiteLabel && (
              <SimplifiedTextfield
                {...register('fullName', {
                  required: true,
                })}
                labelPlacement="top"
                label={formatMessage({ id: 'editprofile.fullName' })}
                variant="outlined"
                fullWidth
                placeholder={fullName}
                value={fullName}
              />
            )}
            {!isFortnoxWhiteLabel && (
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <SimplifiedTextfield
                    {...register('givenName', {
                      required: true,
                    })}
                    labelPlacement="top"
                    label={formatMessage({ id: 'editprofile.firstName' })}
                    variant="outlined"
                    fullWidth
                    placeholder={givenName}
                    value={editing ? undefined : givenName}
                    editing={editing}
                    onChange={handleChange}
                    autoComplete="given-name"
                  />

                  {errors.givenName && (
                    <FormError>
                      {formatMessage({ id: 'fieldrequired' })}
                    </FormError>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <SimplifiedTextfield
                    {...register('familyName', {
                      required: true,
                    })}
                    labelPlacement="top"
                    label={formatMessage({ id: 'editprofile.lastName' })}
                    variant="outlined"
                    fullWidth
                    placeholder={familyName}
                    value={editing ? undefined : familyName}
                    editing={editing}
                    onChange={handleChange}
                    autoComplete="family-name"
                  />

                  {errors.familyName && (
                    <FormError>
                      {formatMessage({ id: 'fieldrequired' })}
                    </FormError>
                  )}
                </Grid>
              </Grid>
            )}

            {/* Personal number */}
            <When
              isTrue={editing}
              fallback={
                <>
                  <Label variant="subtitle1" color="textSecondary">
                    {formatMessage({ id: 'editprofile.personNr' })}
                  </Label>
                  <PersonalNumber value={personNr || ''} />
                </>
              }
            >
              <PersonNrField
                {...register('personNr', {
                  required: false,
                  validate: (val) => isValidPersonalNumber(val!),
                  minLength: 10,
                })}
                label={formatMessage({ id: 'editprofile.personNr' })}
                fullWidth
                labelPlacement="top"
                placeholder={personNr}
                value={editing ? undefined : personNr}
                editing={editing}
                onChange={handleChange}
              />
            </When>

            {errors.personNr && (
              <FormError>
                {formatMessage({ id: 'editprofile.personNr.wrongFormat' })}
                <br />
                {formatMessage({
                  id: 'editprofile.personNr.wrongLength',
                })}
              </FormError>
            )}

            {/* Phone number */}
            <SimplifiedTextfield
              {...register('phoneNumber', {
                required: false,
                minLength: 12,
                maxLength: 12,
              })}
              label={formatMessage({ id: 'phone.number' })}
              fullWidth
              labelPlacement="top"
              type="tel"
              placeholder={phoneNumber}
              value={editing ? undefined : phoneNumber}
              editing={editing}
              onChange={handleChange}
              autoComplete="tel"
            />
            {errors.phoneNumber && (
              <FormError>
                {formatMessage({ id: 'phonenumber.error' })}
              </FormError>
            )}

            {/* Email */}
            <SimplifiedTextfield
              labelPlacement="top"
              value={email || ''}
              label={formatMessage({ id: 'email' })}
              fullWidth
              editing={editing}
              disabled
            />

            {/* Password */}
            {editing && (
              <SimplifiedTextfield
                {...register('newPassword')}
                labelPlacement="top"
                label={formatMessage({ id: 'new.password' })}
                type="password"
                fullWidth
                editing={editing}
                autoComplete="new-password"
              />
            )}
            {errors.newPassword && (
              <FormError>{formatMessage({ id: 'fieldrequired' })}</FormError>
            )}
            {editing && newPassword !== undefined && newPassword !== '' && (
              <SimplifiedTextfield
                {...register('oldPassword')}
                labelPlacement="top"
                label={formatMessage({ id: 'old.password' })}
                type="password"
                variant="outlined"
                fullWidth
                editing={editing}
                onChange={handleChange}
              />
            )}
          </Wrapper>
          <ButtonsWrapper>
            {editing ? (
              <>
                <Button onClick={toggleEdit}>
                  {formatMessage({ id: 'cancel' })}
                </Button>
                <CTAButton
                  type="submit"
                  loading={loading}
                  startIcon={done && <DoneIcon />}
                  disabled={!saveEnabled}
                >
                  {formatMessage({ id: 'save' })}
                </CTAButton>
              </>
            ) : (
              <CTAButton onClick={toggleEdit} disabled={isFortnoxWhiteLabel}>
                {formatMessage({ id: 'edit' })}
              </CTAButton>
            )}
          </ButtonsWrapper>
        </form>
      </Container>
    </ProfileEditContainer>
  );
};

export default ProfileEdit;

import React from 'react';
import EditIcon from '@material-ui/icons/Edit';
import { useIntl } from 'react-intl';
import styled from '@emotion/styled';
import { IconButton, Tooltip } from '@material-ui/core';

const StyledIcon = styled(IconButton)`
  ${(props) =>
    props.color === 'primary'
      ? `color: ${props.theme.palette.success.main}`
      : ''};
`;

interface EditingIconButtonProps {
  className?: string;
  value: boolean;
  onClick: () => void;
  disabled?: boolean;
}

const EditingIconButton = ({
  className,
  value,
  onClick,
  disabled = false,
}: EditingIconButtonProps) => {
  const { formatMessage } = useIntl();

  return (
    <Tooltip
      title={
        !value
          ? formatMessage({ id: 'edit' })
          : formatMessage({ id: 'stop.edit' })
      }
    >
      <span>
        <StyledIcon
          className={className}
          onClick={onClick}
          color={value ? 'primary' : undefined}
          disabled={disabled}
        >
          <EditIcon />
        </StyledIcon>
      </span>
    </Tooltip>
  );
};

export default EditingIconButton;

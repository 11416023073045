import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';

import { AgoyTable, ResolvedReference } from '@agoy/document';
import { useSelector } from 'redux/reducers';
import Typography from '_shared/components/Typography/Typography';

import Table from './Table';
import PrintHeader from './PrintHeader';

const PrintTable = styled(Table)`
  .MuiTableCell-root {
    border: 1px ${({ theme }) => theme.palette.grey[400]} solid;
  }
`;

type ImprovementsAccountPrintProps = {
  table: AgoyTable;
  propertyCode: ResolvedReference;
};

const ImprovementsAccountPrint = ({
  table,
  propertyCode,
}: ImprovementsAccountPrintProps) => {
  const { formatMessage } = useIntl();

  const { currentCustomer = '', currentFinancialYear = '' } = useSelector(
    (state) => state.customerView
  );

  const company = useSelector((state) => state.customers[currentCustomer]);

  const columns = useMemo(() => {
    return [...table.columns].map((column, index) => {
      return { id: column.id, align: index === 2 ? 'right' : 'left' };
    });
  }, [table.columns]);

  return (
    <>
      <PrintHeader
        financialYear={currentFinancialYear}
        name={company.name}
        number={company.orgNumber || ''}
        propertyCode={propertyCode}
      />
      <Typography variant="h2">
        {formatMessage({
          id: `tax.declaration.form.helper.improvements.title`,
        })}
      </Typography>
      <PrintTable
        baseId="taxDeclaration"
        tableId="improvements"
        rows={table.rows}
        columns={columns}
        print
      />
    </>
  );
};

export default ImprovementsAccountPrint;

import styled from '@emotion/styled';
import shouldForwardProp from '@emotion/is-prop-valid';

const Table = styled('table')`
  width: 100%;
  display: table;
  table-layout: fixed;
  border-spacing: 0;
  border-collapse: collapse;
  border: none;

  caption {
    text-align: left;
  }
`;

const TableHead = styled.thead`
  display: table-header-group;
  white-space: nowrap;
`;

const TableBody = styled.tbody``;

const TableBodyCell = styled('td', {
  shouldForwardProp,
})<{ cellWidth?: number; colNumber?: number }>`
  padding: ${({ theme }) => theme.spacing(0.5)}px;

  > * {
    hyphens: auto;
  }

  &.numeric {
    justify-content: flex-end;
    text-align: right;
  }

  &.text {
    justify-content: flex-start;
    text-align: left;
  }

  &.uppercase {
    text-transform: uppercase;
  }

  &.compact {
    padding: 0px ${({ theme }) => theme.spacing(0.5)}px;
  }
`;

const TableHeadCell = styled('th', {
  shouldForwardProp,
})<{ cellWidth?: string }>`
  box-sizing: border-box;
  width: ${({ cellWidth }) => (cellWidth ? `${cellWidth}` : 'auto')};

  padding: ${({ theme }) => theme.spacing(0.5)}px;
  border-bottom: 2px solid #000000;

  > * {
    hyphens: auto;
  }

  &.numeric {
    justify-content: flex-end;
    text-align: right;
  }

  &.text {
    justify-content: flex-start;
    text-align: left;
  }
`;

const BaseRow = styled.tr`
  vertical-align: bottom;
  position: relative;
  page-break-inside: avoid;
  width: 100%;

  // border on rows that are
  // - not spacers or sums
  // - not followed by a sum (sum has border top)
  :not(.spacer, .sum, .alwaysDisplaySum, :has(+ .sum, + .alwaysDisplaySum)) td {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }

  &.sum td,
  &.alwaysDisplaySum td {
    border-top: 1px solid #000;
  }

  :last-of-type.spacer {
    // temporal fix for cases when level 1 doesn't have summary,
    // but deeper level has (it will have spacer after it)
    display: none;
  }
`;

export { Table, TableHead, TableBody, BaseRow, TableHeadCell, TableBodyCell };

import { Typography, TextField as MuiTextField, Box } from '@material-ui/core';
import styled from '@emotion/styled';
import React, { useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import DocumentViewServiceContext from '_shared/services/document/DocumentViewServiceContext';
import EditField from '_shared/components/Inputs/EditField';
import Heading from '../../UI/Heading';
import EditingIconButton from '../../UI/EditingIconButton';
import MultilineField from '../../UI/MultilineField';
import HeadingForPrint from '../../UI/HeadingForPrint';

interface ParagraphProps {
  id: string;
  headingId: string;
  no: number;
  heading: string;
  editable?: boolean;
  hideSubheading?: boolean;
  children?: (editing: boolean) => React.ReactChild;
  text?: string;
  textId?: string;
  defaultText?: string;
  disabled?: boolean;
  optionalComment?: boolean;
  print?: boolean;
  isPreview?: boolean;
}

const ParagraphWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 800px;
`;

const CommentText = styled(Typography)<{ print?: boolean }>`
  white-space: pre-wrap;
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  max-width: 800px;
`;

const NoteNameField = styled(({ disableUnderline, ...rest }) => (
  <MuiTextField size="small" variant="outlined" fullWidth {...rest} />
))`
  .MuiInputBase-root {
    font-size: inherit;
    width: 100%;
  }
`;

const TitleContent = styled.div`
  width: 800px;
`;

const Paragraph = ({
  id,
  headingId,
  no,
  heading,
  editable,
  hideSubheading = false,
  children,
  text,
  textId,
  defaultText,
  optionalComment = false,
  disabled = false,
  print = false,
  isPreview = false,
}: ParagraphProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const [editing, setEditing] = useState(false);
  const service = useContext(DocumentViewServiceContext);

  const numberedTitle = `§${no} ${heading}`;

  const onFieldChange = (inputField) => {
    service.updateField(headingId, inputField);
  };

  const onSubHeadingChange = (inputField) => {
    if (textId !== undefined) {
      service.updateField(textId, inputField);
    }
  };

  const HeadingComponent = !isPreview ? (
    <Heading
      title={numberedTitle}
      controls={
        <>
          {editable && (
            <EditingIconButton
              value={editing}
              onClick={() => setEditing((value) => !value)}
            />
          )}
        </>
      }
    />
  ) : (
    <HeadingForPrint
      title={numberedTitle}
      controls={
        <>
          {editable && (
            <EditingIconButton
              value={editing}
              onClick={() => setEditing((value) => !value)}
            />
          )}
        </>
      }
    />
  );
  return (
    <>
      <TitleContent>
        {!editing ? (
          HeadingComponent
        ) : (
          <ParagraphWrapper>
            <Box display="flex" width="75%">
              <Typography variant="h2" display="inline" color="textSecondary">
                {`§${no}`}
              </Typography>
              <Box ml=".5rem" mt="1.75rem" fontSize="1.75rem">
                <EditField
                  component={NoteNameField}
                  onChange={onFieldChange}
                  value={heading}
                  placeholder={formatMessage({
                    id: `annualReport.annualGeneralMeeting.main.${id}`,
                  })}
                />
              </Box>
            </Box>
            {editable && (
              <EditingIconButton
                value={editing}
                onClick={() => setEditing((value) => !value)}
              />
            )}
          </ParagraphWrapper>
        )}
      </TitleContent>
      {!hideSubheading && editable && editing && !disabled && (
        <MultilineField
          onChange={onSubHeadingChange}
          disabled={!editing && disabled}
          placeholder={defaultText}
          value={optionalComment ? text || '' : text || defaultText || ''}
          print={print}
        />
      )}
      {!hideSubheading && !editing && (
        <CommentText>
          {optionalComment ? text || '' : text || defaultText || ''}
        </CommentText>
      )}

      {children && children(editing)}
    </>
  );
};

export default Paragraph;

import { INIT_STATE, SET_CURRENT_PERIOD } from '_shared/redux/action-types';
import { GlobalActions } from '_shared/redux/actions';
import { CustomerViewAction } from './actions';
import {
  SET_CURRENT_CUSTOMER,
  TOGGLE_NEW_SIE_FILE_UPDATED,
  LOAD_CUSTOMER,
  LOAD_YEAR,
} from './action-types';

import CustomerViewState from './types';

export const initialState: CustomerViewState = {
  next: {},
  sieFileJustUpdated: false,
  currentPeriod: null,
};

const reducer = (
  state: CustomerViewState = initialState,
  action: GlobalActions | CustomerViewAction
): CustomerViewState => {
  switch (action.type) {
    // Global actions, shared by other reducers
    case INIT_STATE:
      return action.state.customerView || state;

    case LOAD_CUSTOMER:
      return {
        ...state,
        next: { clientId: action.clientId },
      };
    case LOAD_YEAR: {
      const { clientId, year } = action;
      return {
        ...state,
        next: { clientId, year },
      };
    }

    case SET_CURRENT_CUSTOMER: {
      const {
        currentCustomer,
        currentYear,
        currentFinancialYear,
        currentPeriod,
        currentFinancialYearId,
      } = action;
      if (
        state.currentCustomer === currentCustomer &&
        state.currentYear === currentYear &&
        state.currentFinancialYear === currentFinancialYear &&
        state.currentPeriod === currentPeriod &&
        state.currentFinancialYearId === currentFinancialYearId
      ) {
        return state;
      }
      return {
        ...state,
        currentCustomer: action.currentCustomer,
        currentYear: action.currentYear,
        currentFinancialYear: action.currentFinancialYear,
        currentPeriod: action.currentPeriod,
        next: currentYear ? {} : { year: state.next.year },
        currentFinancialYearId: action.currentFinancialYearId,
      };
    }

    case TOGGLE_NEW_SIE_FILE_UPDATED:
      return {
        ...state,
        sieFileJustUpdated: !state.sieFileJustUpdated,
        sieFileUpdatedAt: state.sieFileJustUpdated
          ? Date.now()
          : state.sieFileUpdatedAt,
      };

    case SET_CURRENT_PERIOD:
      return {
        ...state,
        currentCustomer: action.clientId,
        currentYear: action.financialYear.substring(0, 4),
        currentFinancialYear: action.financialYear,
        currentPeriod: action.period,
      };

    default:
      return state;
  }
};

export default reducer;

import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { AgoyTable, AgoyTableRow, AgoyTableColumn } from '@agoy/document';
import { Table } from '../../../UI/CommonPrintTable';
import {
  flattenRows,
  hasPreviousYearColumn,
  includeStringRow,
} from '../../../utils/utils';

interface ThreeColumnTableProps {
  table: AgoyTable;
  tableId: string;
  hasPreviousYear?: boolean;
  hideHeader?: boolean;
  className?: string;
}

const StyledTable = styled(Table)`
  .MuiTableCell-root {
    width: 150px;

    &:first-of-type {
      width: auto;
    }

    &:last-child {
      width: 150px;
    }
    input {
      min-width: 5rem;
    }
  }
`;

const PrintCustomThreeColumnTable = ({
  className,
  table,
  tableId,
  hasPreviousYear = false,
  hideHeader = false,
}: ThreeColumnTableProps): JSX.Element => {
  const [rows, setRows] = useState<AgoyTableRow[]>([]);
  const [columns, setColumns] = useState<AgoyTableColumn[]>([]);

  useEffect(() => {
    const newRows = flattenRows(table.rows).filter((row) =>
      includeStringRow(row)
    );

    setRows(newRows);
  }, [table.rows]);

  useEffect(() => {
    const newColumns = table.columns.filter(
      (col) =>
        col.id !== 'hidden' && (hasPreviousYear || !hasPreviousYearColumn(col))
    );
    setColumns(newColumns);
  }, [hasPreviousYear, table.columns]);

  return (
    <>
      <StyledTable
        baseId="annualReport"
        tableId={tableId}
        rows={rows}
        columns={columns}
        className={className}
        hideHeader={hideHeader}
      />
    </>
  );
};

export default PrintCustomThreeColumnTable;

export const ACCOUNTANT_LABELS = {
  ACCOUNTANT: 'Revisor',
  ACCOUNTANT_ALTERNATE: 'Revisorssuppleant',
  AUTHORIZED: 'Auktoriserad',
  APPROVED: 'Godkänd',
  MAIN_ACCOUNTANT: 'Huvudansvarig revisor',
  MAIN_ACCOUNTANT_IS_ACCOUNTANT_FIRM: 'Huvudrevisor är revisionsbolaget',
  ACCREDITED_ACCOUNTANT: 'Förtroendevald revisor',
};

export const BOARD_LABELS = {
  CHAIRMAN_OF_THE_BOARD: 'Styrelseordförande',
  BOARD_MEMBER: 'Styrelseledamot',
  BOARD_ALTERNATE: 'Styrelsesuppleant',
  CEO: 'Verkställande direktör',
};

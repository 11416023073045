/* eslint-disable import/extensions */
import React from 'react';
import { useIntl } from 'react-intl';
import appConfig from '_shared/services/config';

import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';

import FroalaEditor from 'react-froala-wysiwyg';
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';

import 'froala-editor/js/plugins/align.min.js';
import 'froala-editor/js/plugins/colors.min.js';
import 'froala-editor/js/plugins/draggable.min.js';
import 'froala-editor/js/plugins/entities.min.js';
import 'froala-editor/js/plugins/font_family.min.js';
import 'froala-editor/js/plugins/font_size.min.js';
import 'froala-editor/js/plugins/line_height.min.js';
import 'froala-editor/js/plugins/link.min.js';
import 'froala-editor/js/plugins/paragraph_format.min.js';
import 'froala-editor/js/plugins/paragraph_style.min.js';
import 'froala-editor/js/plugins/save.min.js';
import 'froala-editor/js/plugins/table.min.js';
import 'froala-editor/js/plugins/word_paste.min.js';
import 'froala-editor/js/plugins/lists.min.js';

interface RichEditorProps {
  value: string;
  isEditing: boolean;
  config?: any;
  moreRich?: any;
  height?: number | string;
  width?: number | string;
  onChange?: (html: string) => void;
}

const RichEditor = ({
  value,
  config,
  moreRich,
  isEditing = true,
  height,
  width,
  onChange = () => {},
}: RichEditorProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const { froalaKey } = appConfig;

  const editorConfig = {
    key: froalaKey,
    height,
    width,
    toolbarButtons: {
      moreMisc: {
        buttons: ['undo', 'redo'],
        align: 'left',
        buttonsVisible: 3,
      },
      moreText: {
        buttons: [
          'bold',
          'italic',
          'underline',
          'fontSize',
          'textColor',
          'backgroundColor',
          'strikeThrough',
          'subscript',
          'superscript',
          'clearFormatting',
        ],
        buttonsVisible: 5,
      },
      moreParagraph: {
        buttons: [
          'alignLeft',
          'alignCenter',
          'alignRight',
          'alignJustify',
          'formatOLSimple',
          'formatOL',
          'formatUL',
          'lineHeight',
          'outdent',
          'indent',
          'quote',
        ],
        align: 'left',
        buttonsVisible: 4,
      },
    },
    toolbarBottom: true,
    fontFamily: {
      'Roboto,sans-serif': 'Roboto',
    },
    fontSizeSelection: true,
    fontSizeDefaultSelection: '12',
    fontSizeUnit: 'pt',
    attribution: false,
    placeholderText: formatMessage({ id: 'richEditor.placeholder' }),
    ...config,
  };

  if (moreRich) {
    editorConfig.toolbarButtons.moreRich = moreRich;
  }

  if (isEditing) {
    return (
      <FroalaEditor
        config={editorConfig}
        model={value}
        onModelChange={onChange}
      />
    );
  }

  return (
    <FroalaEditorView
      model={value}
      config={{
        key: froalaKey,
        height,
        width,
        attribution: false,
        ...config,
      }}
    />
  );
};

export default RichEditor;

import React from 'react';
import { useSelector } from 'redux/reducers';
import styled from '@emotion/styled';

import InventoryStockTable from './InventoryStockTable';

const Wrapper = styled.div`
  width: 100%;
`;

const InventoryStockViewPrint = () => {
  const { stockInventoryTable } = useSelector((state) => state.accountingView);

  return (
    <Wrapper>
      {stockInventoryTable && (
        <InventoryStockTable tableData={stockInventoryTable} isPrint />
      )}
    </Wrapper>
  );
};

export default InventoryStockViewPrint;

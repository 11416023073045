import updateDocument from './helpers/updateDocument';
import { AgoyDocumentStructure } from '../document';
import { OperationResult, State } from './types';

const toggleSectionActive = <T extends AgoyDocumentStructure>(
  structure: T,
  state: State<T>,
  id: string
): OperationResult<T> => {
  return updateDocument(structure, state, `${id}.active`, {
    boolean: (key, id, props) => {
      const active =
        typeof props.node === 'object' ? props.node.value : props.node;

      return {
        ...props,
        node: !active,
        changes: !active,
      };
    },
  });
};

export default toggleSectionActive;

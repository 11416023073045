import React from 'react';
import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-bottom: ${(props) => props.theme.spacing(2)}px;
`;

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Card = styled.div`
  width: 400px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;

  e5e5e5background: #e5e5e5;
  border-radius: ${(props) => props.theme.spacing(1)}px;
`;

type Props = {
  k2Quantity: number;
  k3Quantity: number;
  /**
   * Total SEK of PAYG cost in the current cycle
   */
  total: number;
};

const Page = ({ k2Quantity, k3Quantity, total: paygTotal }: Props) => {
  const { formatMessage } = useIntl();

  return (
    <Wrapper>
      <Typography variant="h3">
        {formatMessage({ id: 'manage.payg' })}
      </Typography>

      <CardWrapper>
        <Card>
          <Typography variant="h5">
            {formatMessage({ id: 'annualReport.settings.annualReportTitle' })}{' '}
            K2
          </Typography>

          <Typography variant="h5">x{k2Quantity}</Typography>
        </Card>
        <Card>
          <Typography variant="h5">
            {formatMessage({ id: 'annualReport.settings.annualReportTitle' })}{' '}
            K3
          </Typography>
          <Typography variant="h5">x{k3Quantity}</Typography>
        </Card>
      </CardWrapper>

      <Typography variant="h2" style={{ marginTop: '32px', marginBottom: '0' }}>
        Total: {paygTotal * 200} {formatMessage({ id: 'curr.min' })}
      </Typography>
      <Typography variant="subtitle2">
        {formatMessage({
          id: `checkout.pricePerMonth.payg`,
        })}
      </Typography>
    </Wrapper>
  );
};

export default Page;

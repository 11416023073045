import { balanceSheetReferences } from '../balance-sheet/foundationBalanceSheet';
import { incomeStatementReferences } from '../income-statement/foundationIncomeStatement';

type References = {
  [key: string]: string;
};

export const foundationReferences = (): References[] => {
  const partReferences = [
    balanceSheetReferences(),
    incomeStatementReferences(),
  ];

  return partReferences;
};

import React from 'react';

import styled from '@emotion/styled';
import { Snackbar as MuiSnackbar, SnackbarProps } from '@material-ui/core';

const SnackBar = styled(MuiSnackbar)`
  padding: ${({ theme }) => theme.spacing(4)}px;
`;

const Snackbar = ({
  children,
  autoHideDuration,
  ...rest
}: SnackbarProps): JSX.Element => (
  <SnackBar
    {...rest}
    autoHideDuration={autoHideDuration !== undefined ? autoHideDuration : 5000}
    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
  >
    {children}
  </SnackBar>
);
export default Snackbar;

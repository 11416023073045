import React from 'react';
import styled from '@emotion/styled';
import { Typography, Card } from '@material-ui/core';

const InformationSectionLabelWrapper = styled.div`
  margin-left: ${(props) => props.theme.spacing(1)}px;
`;

const ClientCard = styled(Card)`
  padding: ${(props) => props.theme.spacing(2)}px;
`;

const MainWrapper = styled.div`
  display: block;
`;
interface ClientSectionWrapper {
  label: string;
  children: any;
}
const ClientSectionWrapper = ({ label, children }: ClientSectionWrapper) => (
  <MainWrapper>
    <InformationSectionLabelWrapper>
      <Typography variant="h3">{label}</Typography>
    </InformationSectionLabelWrapper>
    <ClientCard>{children}</ClientCard>
  </MainWrapper>
);

export default ClientSectionWrapper;

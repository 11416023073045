import { useEffect, useState } from 'react';
import { Observable } from 'rxjs';
import { isEqual } from 'lodash';

/**
 * Use the latest value from the subscription.
 *
 * If no subscription or no value came yet, undefined is returned.
 *
 * @param subscription
 * @param deps
 */
const useObservable = <T>(
  observable: Observable<T> | undefined
): T | undefined => {
  const [value, setValue] = useState<T | undefined>(() => {
    // When the observable has a value ready we want that
    // as the initial value for the state.
    // During the subscribe call, it will then use the callback
    // directly to pass the current value.
    let currentValue: T | undefined;
    observable
      ?.subscribe((v) => {
        currentValue = v;
      })
      .unsubscribe();
    return currentValue;
  });

  useEffect(() => {
    // Subscribe to the observable for future values
    // but ignore values that are deep equal to the previous
    // to reduce the number of updates in React.
    const sub = observable?.subscribe((newValue) =>
      setValue((oldValue) =>
        isEqual(oldValue, newValue) ? oldValue : newValue
      )
    );
    return () => {
      sub?.unsubscribe();
    };
  }, [observable]);

  return value;
};

export default useObservable;

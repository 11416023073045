import React from 'react';
import { Select, MenuItem } from '@material-ui/core';
import { useIntl } from 'react-intl';

import { PERIODICITY } from '_shared/redux/checklist/constants';
import { Program } from '_shared/redux/checklist/types';

interface PeriodSelectProps {
  program: Program;
  period: string;
  onChange: (period: string) => void;
}

const PeriodSelect = ({ program, period, onChange }: PeriodSelectProps) => {
  const { formatMessage } = useIntl();

  const handleChangePeriod = (event) => {
    onChange(event.target.value);
  };

  return (
    <Select value={period} onChange={handleChangePeriod} variant="outlined">
      {PERIODICITY.map((item) => (
        <MenuItem
          key={item}
          value={item}
          disabled={
            program === 'tax_declaration_person' && item !== 'last_period'
          }
        >
          {formatMessage({ id: `checklist.create.periodicity.${item}` })}
        </MenuItem>
      ))}
    </Select>
  );
};

export default PeriodSelect;

import React from 'react';
import styled from '@emotion/styled';
import { Dialog, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useIntl } from 'react-intl';

import { Voucher } from 'types/Voucher';
import Button from '_shared/components/Buttons/Button';
import { FinancialYear } from '@agoy/api-sdk-core';

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    padding: ${({ theme }) => theme.spacing(4)}px;
    max-width: 700px;
    display: flex;
    flex-direction: column;
    position: relative;
    row-gap: 24px;
  }
`;

const Text = styled(Typography)`
  font-size: 16px;
`;

const VoucherPart = styled(Typography)`
  font-size: 16px;
  font-weight: 700;
`;

const CloseButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  column-gap: 16px;
`;

const Status = styled.div`
  display: flex;
  align-items: center;
  height: 28px;
  padding: 4px 12px;
  border-radius: 28px;
  background-color: ${({ theme }) =>
    theme.palette.taxDeclarationView.formStatus.STARTED};
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 360;
`;

const ListItem = styled.div`
  display: grid;
  grid-template-columns: 180px auto 1fr;
  grid-column-gap: 32px;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.grey[200]};
  padding: 24px;
  height: 80px;
`;

type VoucherModalProps = {
  open: boolean;
  vouchers: Voucher[];
  financialYear: FinancialYear;
  onClose: () => void;
  onSubmit: () => void;
};

const VoucherModal = ({
  open,
  vouchers,
  financialYear,
  onClose,
  onSubmit,
}: VoucherModalProps) => {
  const { formatMessage } = useIntl();

  return (
    <StyledDialog fullWidth open={open} onClose={onClose}>
      <CloseButton onClick={onClose}>
        <CloseIcon fontSize="large" />
      </CloseButton>

      <Text>
        {formatMessage({ id: 'overview.preliminaryVouchersModal.title' })}
      </Text>

      <List>
        {vouchers.map((voucher) => (
          <ListItem key={voucher.id}>
            <VoucherPart>
              {formatMessage({ id: `voucher.reference.${voucher.reference}` })}
            </VoucherPart>
            <Status>{formatMessage({ id: 'voucher.status.prel' })}</Status>
            <Text>{voucher.transDate}</Text>
          </ListItem>
        ))}
      </List>

      <Text>
        {formatMessage(
          { id: 'overview.preliminaryVouchersModal.message' },
          { financialYear: `${financialYear.start} - ${financialYear.end}` }
        )}
      </Text>

      <Buttons>
        <Button
          label={formatMessage({
            id: 'overview.preliminaryVouchersModal.submit',
          })}
          onClick={onSubmit}
        />
        <Button
          variant="outlined"
          label={formatMessage({ id: 'cancel' })}
          onClick={onClose}
        />
      </Buttons>
    </StyledDialog>
  );
};

export default VoucherModal;

import React, { createContext } from 'react';

// Module refers to view module, overview, periods, taxes etc...
type ModuleType = 'UNSET' | 'TAX_DECLARATION' | 'TAX_DECLARATION_PERSON';

export const ModuleContext = createContext<ModuleType>('UNSET');

type ModuleContextProviderProps = {
  moduleContext: ModuleType;
};

const withModule =
  ({ moduleContext = 'UNSET' }: ModuleContextProviderProps) =>
  (Component) =>
  (props) =>
    (
      <ModuleContext.Provider value={moduleContext}>
        <Component {...props} />
      </ModuleContext.Provider>
    );

export default withModule;

/*
 * This is a GENERATED file, do not change manually!
 * Generated:
 * - from file k3-arsredovisning-2021-10-31-rev20230131 - Noter.csv
 * - Comments: AGOY-6813 (Former Note 94)
 */

import {
  value,
  ref,
  sumAllowNull,
  id,
  AgoyDocumentPart,
} from '@agoy/document';
import { IxbrlCell } from '../../../../../common';
import {
  field,
  ixbrlCell,
  ixbrlField,
  table,
} from '../../../../../common/utils/util';
import { previousYear, year } from '../../../../../common/utils/date-util';
import {
  sumAccountsIBs,
} from '../../../../../common/utils/accountSumsUtil';

const cell = { type: 'field' as const };
const boolean = { type: 'boolean' as const };
const type = { type: 'type' as const };
const number = { type: 'number' as const };

export const getNote2033K3v2 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null
): AgoyDocumentPart<typeof note2033K3v2ContentDefinition> => ({
  active: false,
  number: value(undefined),
  name: noteLabel(
    'Balanserade utgifter för utvecklingsarbeten och liknande arbeten'
  ),
  data: {
    active: true,
    BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenAbstract: {
      type: 'group',
      active: true,
      level: 0,
      title: field(
        'Balanserade utgifter för utvecklingsarbeten och liknande arbeten'
      ),
      table: table<IxbrlCell>(
        'notes.note33.data.BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenAbstract.table',
        {
          id: 'BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenAbstract',
          label: '',
          dataType: 'text',
          active: true,
        },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) ?? '',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) ?? '',
          dataType: 'numeric',
          active: true,
        }
      )
        .addRows((rows) => {
          rows
            .addRowWithType(
              'BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenAbstract',
              'hidden',
              undefined,
              undefined,
              undefined
            )
            .addSubRows((rows) => {
              rows.addRowWithType(
                'BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenAnskaffningsvardenIngaende',
                'sum_accounts',
                noteLabel('Ingående anskaffningsvärden'),
                ixbrlCell(ref(sumAccountsIBs('1010:1012', false)), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenAnskaffningsvarden',
                  standardRubrik:
                    'Anskaffningsvärden balanserade utgifter för utvecklingsarbeten och liknande arbeten',
                  saldo: 'debit',
                  negateValue: false,
                }),
                ixbrlCell(
                  ref(sumAccountsIBs('1010:1012', false, 'previousYear')),
                  {
                    contextRef: 'balans2',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenAnskaffningsvarden',
                    standardRubrik:
                      'Anskaffningsvärden balanserade utgifter för utvecklingsarbeten och liknande arbeten',
                    saldo: 'debit',
                    negateValue: false,
                  }
                )
              );
              rows
                .addRowWithType(
                  'BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenAnskaffningsvardenAbstract',
                  'header',
                  noteLabel('Förändringar av anskaffningsvärden'),
                  undefined,
                  undefined
                )
                .addSubRows((rows) => {
                  rows.addRowWithType(
                    'BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringAnskaffningsvardenAktiveradeUtgifter',
                    'row',
                    noteLabel('Aktiverade utgifter'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringAnskaffningsvardenAktiveradeUtgifter',
                      standardRubrik:
                        'Ökning av anskaffningsvärden i samband med periodens aktiverade utgifter för balanserade utgifter',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringAnskaffningsvardenAktiveradeUtgifter',
                      standardRubrik:
                        'Ökning av anskaffningsvärden i samband med periodens aktiverade utgifter för balanserade utgifter',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringAnskaffningsvardenRorelseforvarv',
                    'row',
                    noteLabel('Rörelseförvärv'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringAnskaffningsvardenRorelseforvarv',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden för balanserade utgifter för utvecklingsarbeten och liknande arbeten genom rörelseförvärv',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringAnskaffningsvardenRorelseforvarv',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden för balanserade utgifter för utvecklingsarbeten och liknande arbeten genom rörelseförvärv',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringAnskaffningsvardenFusion',
                    'row',
                    noteLabel('Fusion'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringAnskaffningsvardenFusion',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden för balanserade utgifter för utvecklingsarbeten och liknande arbeten genom fusion',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringAnskaffningsvardenFusion',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden för balanserade utgifter för utvecklingsarbeten och liknande arbeten genom fusion',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringAnskaffningsvardenForsaljningarUtrangeringar',
                    'row',
                    noteLabel('Försäljningar/utrangeringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringAnskaffningsvardenForsaljningarUtrangeringar',
                      standardRubrik:
                        'Minskning av anskaffningsvärden i samband med försäljningar eller utrangeringar av balanserade utgifter för utvecklingsarbeten och liknande arbeten',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringAnskaffningsvardenForsaljningarUtrangeringar',
                      standardRubrik:
                        'Minskning av anskaffningsvärden i samband med försäljningar eller utrangeringar av balanserade utgifter för utvecklingsarbeten och liknande arbeten',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringAnskaffningsvardenOmklassificeringar',
                    'row',
                    noteLabel('Omklassificeringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringAnskaffningsvardenOmklassificeringar',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden i samband med omklassificeringar av balanserade utgifter för utvecklingsarbeten och liknande arbeten',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringAnskaffningsvardenOmklassificeringar',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden i samband med omklassificeringar av balanserade utgifter för utvecklingsarbeten och liknande arbeten',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringAnskaffningsvardenOmrakningsdifferenser',
                    'row',
                    noteLabel('Omräkningsdifferenser'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringAnskaffningsvardenOmrakningsdifferenser',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden i samband med omräkningsdifferenser av balanserade utgifter för utvecklingsarbeten och liknande arbeten',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringAnskaffningsvardenOmrakningsdifferenser',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden i samband med omräkningsdifferenser av balanserade utgifter för utvecklingsarbeten och liknande arbeten',
                      negateValue: false,
                    })
                  );
                  return rows.build();
                });
              rows.addRowWithType(
                'BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenAnskaffningsvardenUtgaende',
                'sum',
                noteLabel('Utgående anskaffningsvärden'),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note33.data.BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenAbstract.table.BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenAbstract.BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenAnskaffningsvardenIngaende.year'
                      ),
                      id(
                        'notes.note33.data.BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenAbstract.table.BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenAbstract.BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenAnskaffningsvardenAbstract.*.year'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans0',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenAnskaffningsvarden',
                    standardRubrik:
                      'Anskaffningsvärden balanserade utgifter för utvecklingsarbeten och liknande arbeten',
                    saldo: 'debit',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note33.data.BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenAbstract.table.BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenAbstract.BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenAnskaffningsvardenIngaende.previousYear'
                      ),
                      id(
                        'notes.note33.data.BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenAbstract.table.BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenAbstract.BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenAnskaffningsvardenAbstract.*.previousYear'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans1',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenAnskaffningsvarden',
                    standardRubrik:
                      'Anskaffningsvärden balanserade utgifter för utvecklingsarbeten och liknande arbeten',
                    saldo: 'debit',
                    negateValue: false,
                  }
                )
              );
              rows.addRowWithType(
                'BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenAvskrivningarIngaende',
                'sum_accounts',
                noteLabel('Ingående avskrivningar'),
                ixbrlCell(ref(sumAccountsIBs('1019', false)), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenAvskrivningar',
                  standardRubrik:
                    'Ackumulerade avskrivningar balanserade utgifter för utvecklingsarbeten och liknande arbeten',
                  saldo: 'credit',
                  negateValue: true,
                }),
                ixbrlCell(ref(sumAccountsIBs('1019', false, 'previousYear')), {
                  contextRef: 'balans2',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenAvskrivningar',
                  standardRubrik:
                    'Ackumulerade avskrivningar balanserade utgifter för utvecklingsarbeten och liknande arbeten',
                  saldo: 'credit',
                  negateValue: true,
                })
              );
              rows
                .addRowWithType(
                  'BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringAvskrivningarAbstract',
                  'header',
                  noteLabel('Förändringar av avskrivningar'),
                  undefined,
                  undefined
                )
                .addSubRows((rows) => {
                  rows.addRowWithType(
                    'BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringAvskrivningarFusion',
                    'row',
                    noteLabel('Fusion'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringAvskrivningarFusion',
                      standardRubrik:
                        'Ökning (minskning) av avskrivningar för balanserade utgifter för utvecklingsarbeten och liknande arbeten genom fusion',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringAvskrivningarFusion',
                      standardRubrik:
                        'Ökning (minskning) av avskrivningar för balanserade utgifter för utvecklingsarbeten och liknande arbeten genom fusion',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringAvskrivningarForsaljningarUtrangeringar',
                    'row',
                    noteLabel('Försäljningar/utrangeringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringAvskrivningarForsaljningarUtrangeringar',
                      standardRubrik:
                        'Återförda avskrivningar i samband med försäljningar eller utrangeringar av balanserade utgifter för utvecklingsarbeten och liknande arbeten',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringAvskrivningarForsaljningarUtrangeringar',
                      standardRubrik:
                        'Återförda avskrivningar i samband med försäljningar eller utrangeringar av balanserade utgifter för utvecklingsarbeten och liknande arbeten',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringAvskrivningarOmklassificeringar',
                    'row',
                    noteLabel('Omklassificeringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringAvskrivningarOmklassificeringar',
                      standardRubrik:
                        'Minskning (ökning) av avskrivningar i samband med omklassificeringar av balanserade utgifter för utvecklingsarbeten och liknande arbeten',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringAvskrivningarOmklassificeringar',
                      standardRubrik:
                        'Minskning (ökning) av avskrivningar i samband med omklassificeringar av balanserade utgifter för utvecklingsarbeten och liknande arbeten',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringAvskrivningarAretsAvskrivningar',
                    'row',
                    noteLabel('Årets avskrivningar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringAvskrivningarAretsAvskrivningar',
                      standardRubrik:
                        'Periodens avskrivningar balanserade utgifter för utvecklingsarbeten och liknande arbeten',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringAvskrivningarAretsAvskrivningar',
                      standardRubrik:
                        'Periodens avskrivningar balanserade utgifter för utvecklingsarbeten och liknande arbeten',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringAvskrivningarOmrakningsdifferenser',
                    'row',
                    noteLabel('Omräkningsdifferenser'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringAvskrivningarOmrakningsdifferenser',
                      standardRubrik:
                        'Minskning (ökning) av avskrivningar i samband med omräkningsdifferenser av balanserade utgifter för utvecklingsarbeten och liknande arbeten',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringAvskrivningarOmrakningsdifferenser',
                      standardRubrik:
                        'Minskning (ökning) av avskrivningar i samband med omräkningsdifferenser av balanserade utgifter för utvecklingsarbeten och liknande arbeten',
                      negateValue: false,
                    })
                  );
                  return rows.build();
                });
              rows.addRowWithType(
                'BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenAvskrivningarUtgaende',
                'sum',
                noteLabel('Utgående avskrivningar'),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note33.data.BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenAbstract.table.BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenAbstract.BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenAvskrivningarIngaende.year'
                      ),
                      id(
                        'notes.note33.data.BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenAbstract.table.BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenAbstract.BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringAvskrivningarAbstract.*.year'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans0',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenAvskrivningar',
                    standardRubrik:
                      'Ackumulerade avskrivningar balanserade utgifter för utvecklingsarbeten och liknande arbeten',
                    saldo: 'credit',
                    negateValue: true,
                  }
                ),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note33.data.BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenAbstract.table.BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenAbstract.BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenAvskrivningarIngaende.previousYear'
                      ),
                      id(
                        'notes.note33.data.BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenAbstract.table.BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenAbstract.BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringAvskrivningarAbstract.*.previousYear'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans1',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenAvskrivningar',
                    standardRubrik:
                      'Ackumulerade avskrivningar balanserade utgifter för utvecklingsarbeten och liknande arbeten',
                    saldo: 'credit',
                    negateValue: true,
                  }
                )
              );
              rows.addRowWithType(
                'BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenUppskrivningarIngaende',
                'row',
                noteLabel('Ingående uppskrivningar'),
                ixbrlCell(value(undefined), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenUppskrivningar',
                  standardRubrik:
                    'Ackumulerade uppskrivningar balanserade utgifter för utvecklingsarbeten och liknande arbeten',
                  saldo: 'debit',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'balans2',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenUppskrivningar',
                  standardRubrik:
                    'Ackumulerade uppskrivningar balanserade utgifter för utvecklingsarbeten och liknande arbeten',
                  saldo: 'debit',
                  negateValue: false,
                })
              );
              rows
                .addRowWithType(
                  'BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringUppskrivningarAbstract',
                  'header',
                  noteLabel('Förändringar av uppskrivningar'),
                  undefined,
                  undefined
                )
                .addSubRows((rows) => {
                  rows.addRowWithType(
                    'BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringUppskrivningarFusion',
                    'row',
                    noteLabel('Fusion'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringUppskrivningarFusion',
                      standardRubrik:
                        'Ökning (minskning) av uppskrivningar för balanserade utgifter för utvecklingsarbeten och liknande arbeten genom fusion',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringUppskrivningarFusion',
                      standardRubrik:
                        'Ökning (minskning) av uppskrivningar för balanserade utgifter för utvecklingsarbeten och liknande arbeten genom fusion',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringUppskrivningarForsaljningarUtrangeringar',
                    'row',
                    noteLabel('Försäljningar/utrangeringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringUppskrivningarForsaljningarUtrangeringar',
                      standardRubrik:
                        'Återförda uppskrivningar i samband med försäljningar eller utrangeringar av balanserade utgifter för utvecklingsarbeten och liknande arbeten',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringUppskrivningarForsaljningarUtrangeringar',
                      standardRubrik:
                        'Återförda uppskrivningar i samband med försäljningar eller utrangeringar av balanserade utgifter för utvecklingsarbeten och liknande arbeten',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringUppskrivningarOmklassificeringar',
                    'row',
                    noteLabel('Omklassificeringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringUppskrivningarOmklassificeringar',
                      standardRubrik:
                        'Ökning (minskning) av uppskrivningar i samband med omklassificeringar av balanserade utgifter för utvecklingsarbeten och liknande arbeten',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringUppskrivningarOmklassificeringar',
                      standardRubrik:
                        'Ökning (minskning) av uppskrivningar i samband med omklassificeringar av balanserade utgifter för utvecklingsarbeten och liknande arbeten',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringUppskrivningarAretsUppskrivningar',
                    'row',
                    noteLabel('Årets uppskrivningar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringUppskrivningarAretsUppskrivningar',
                      standardRubrik:
                        'Periodens uppskrivningar av balanserade utgifter för utvecklingsarbeten och liknande arbeten',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringUppskrivningarAretsUppskrivningar',
                      standardRubrik:
                        'Periodens uppskrivningar av balanserade utgifter för utvecklingsarbeten och liknande arbeten',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringUppskrivningarNedskrivningarUppskrivetBelopp',
                    'row',
                    noteLabel('Årets nedskrivningar på uppskrivet belopp'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringUppskrivningarNedskrivningarUppskrivetBelopp',
                      standardRubrik:
                        'Nedskrivningar av uppskrivet belopp på balanserade utgifter för utvecklingsarbeten och liknande arbeten',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringUppskrivningarNedskrivningarUppskrivetBelopp',
                      standardRubrik:
                        'Nedskrivningar av uppskrivet belopp på balanserade utgifter för utvecklingsarbeten och liknande arbeten',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringUppskrivningarAvskrivningarUppskrivetBelopp',
                    'row',
                    noteLabel('Årets avskrivningar på uppskrivet belopp'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringUppskrivningarAvskrivningarUppskrivetBelopp',
                      standardRubrik:
                        'Avskrivningar av uppskrivet belopp på balanserade utgifter för utvecklingsarbeten och liknande arbeten',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringUppskrivningarAvskrivningarUppskrivetBelopp',
                      standardRubrik:
                        'Avskrivningar av uppskrivet belopp på balanserade utgifter för utvecklingsarbeten och liknande arbeten',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringUppskrivningarOmrakningsdifferenser',
                    'row',
                    noteLabel('Omräkningsdifferenser'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringUppskrivningarOmrakningsdifferenser',
                      standardRubrik:
                        'Ökning (minskning) av uppskrivningar i samband med omräkningsdifferenser av balanserade utgifter för utvecklingsarbeten och liknande arbeten',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringUppskrivningarOmrakningsdifferenser',
                      standardRubrik:
                        'Ökning (minskning) av uppskrivningar i samband med omräkningsdifferenser av balanserade utgifter för utvecklingsarbeten och liknande arbeten',
                      negateValue: false,
                    })
                  );
                  return rows.build();
                });
              rows.addRowWithType(
                'BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenUppskrivningarUtgaende',
                'sum',
                noteLabel('Utgående uppskrivningar'),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note33.data.BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenAbstract.table.BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenAbstract.BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenUppskrivningarIngaende.year'
                      ),
                      id(
                        'notes.note33.data.BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenAbstract.table.BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenAbstract.BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringUppskrivningarAbstract.*.year'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans0',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenUppskrivningar',
                    standardRubrik:
                      'Ackumulerade uppskrivningar balanserade utgifter för utvecklingsarbeten och liknande arbeten',
                    saldo: 'debit',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note33.data.BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenAbstract.table.BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenAbstract.BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenUppskrivningarIngaende.previousYear'
                      ),
                      id(
                        'notes.note33.data.BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenAbstract.table.BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenAbstract.BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringUppskrivningarAbstract.*.previousYear'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans1',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenUppskrivningar',
                    standardRubrik:
                      'Ackumulerade uppskrivningar balanserade utgifter för utvecklingsarbeten och liknande arbeten',
                    saldo: 'debit',
                    negateValue: false,
                  }
                )
              );
              rows.addRowWithType(
                'BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenNedskrivningarIngaende',
                'sum_accounts',
                noteLabel('Ingående nedskrivningar'),
                ixbrlCell(ref(sumAccountsIBs('1018', false)), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenNedskrivningar',
                  standardRubrik:
                    'Ackumulerade nedskrivningar balanserade utgifter för utvecklingsarbeten och liknande arbeten',
                  saldo: 'credit',
                  negateValue: true,
                }),
                ixbrlCell(ref(sumAccountsIBs('1018', false, 'previousYear')), {
                  contextRef: 'balans2',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenNedskrivningar',
                  standardRubrik:
                    'Ackumulerade nedskrivningar balanserade utgifter för utvecklingsarbeten och liknande arbeten',
                  saldo: 'credit',
                  negateValue: true,
                })
              );
              rows
                .addRowWithType(
                  'BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringNedskrivningarAbstract',
                  'header',
                  noteLabel('Förändringar av nedskrivningar'),
                  undefined,
                  undefined
                )
                .addSubRows((rows) => {
                  rows.addRowWithType(
                    'BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringNedskrivningarFusion',
                    'row',
                    noteLabel('Fusion'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringNedskrivningarFusion',
                      standardRubrik:
                        'Ökning (minskning) av nedskrivningar för balanserade utgifter för utvecklingsarbeten och liknande arbeten genom fusion',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringNedskrivningarFusion',
                      standardRubrik:
                        'Ökning (minskning) av nedskrivningar för balanserade utgifter för utvecklingsarbeten och liknande arbeten genom fusion',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringNedskrivningarForsaljningarUtrangeringar',
                    'row',
                    noteLabel('Försäljningar/utrangeringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringNedskrivningarForsaljningarUtrangeringar',
                      standardRubrik:
                        'Återförda nedskrivningar i samband med försäljningar eller utrangeringar av balanserade utgifter för utvecklingsarbeten och liknande arbeten',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringNedskrivningarForsaljningarUtrangeringar',
                      standardRubrik:
                        'Återförda nedskrivningar i samband med försäljningar eller utrangeringar av balanserade utgifter för utvecklingsarbeten och liknande arbeten',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringNedskrivningarOmklassificeringar',
                    'row',
                    noteLabel('Omklassificeringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringNedskrivningarOmklassificeringar',
                      standardRubrik:
                        'Minskning (ökning) av nedskrivningar i samband med omklassificeringar av balanserade utgifter för utvecklingsarbeten och liknande arbeten',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringNedskrivningarOmklassificeringar',
                      standardRubrik:
                        'Minskning (ökning) av nedskrivningar i samband med omklassificeringar av balanserade utgifter för utvecklingsarbeten och liknande arbeten',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringNedskrivningarAterfordaNedskrivningar',
                    'row',
                    noteLabel('Återförda nedskrivningar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringNedskrivningarAterfordaNedskrivningar',
                      standardRubrik:
                        'Periodens återförda nedskrivningar av balanserade utgifter för utvecklingsarbeten och liknande arbeten',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringNedskrivningarAterfordaNedskrivningar',
                      standardRubrik:
                        'Periodens återförda nedskrivningar av balanserade utgifter för utvecklingsarbeten och liknande arbeten',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringNedskrivningarAretsNedskrivningar',
                    'row',
                    noteLabel('Årets nedskrivningar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringNedskrivningarAretsNedskrivningar',
                      standardRubrik:
                        'Periodens nedskrivningar balanserade utgifter för utvecklingsarbeten och liknande arbeten',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringNedskrivningarAretsNedskrivningar',
                      standardRubrik:
                        'Periodens nedskrivningar balanserade utgifter för utvecklingsarbeten och liknande arbeten',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringNedskrivningarOmrakningsdifferenser',
                    'row',
                    noteLabel('Omräkningsdifferenser'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringNedskrivningarOmrakningsdifferenser',
                      standardRubrik:
                        'Minskning (ökning) av nedskrivningar i samband med omräkningsdifferenser av balanserade utgifter för utvecklingsarbeten och liknande arbeten',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringNedskrivningarOmrakningsdifferenser',
                      standardRubrik:
                        'Minskning (ökning) av nedskrivningar i samband med omräkningsdifferenser av balanserade utgifter för utvecklingsarbeten och liknande arbeten',
                      negateValue: false,
                    })
                  );
                  return rows.build();
                });
              rows.addRowWithType(
                'BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenNedskrivningarUtgaende',
                'sum',
                noteLabel('Utgående nedskrivningar'),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note33.data.BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenAbstract.table.BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenAbstract.BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenNedskrivningarIngaende.year'
                      ),
                      id(
                        'notes.note33.data.BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenAbstract.table.BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenAbstract.BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringNedskrivningarAbstract.*.year'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans0',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenNedskrivningar',
                    standardRubrik:
                      'Ackumulerade nedskrivningar balanserade utgifter för utvecklingsarbeten och liknande arbeten',
                    saldo: 'credit',
                    negateValue: true,
                  }
                ),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note33.data.BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenAbstract.table.BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenAbstract.BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenNedskrivningarIngaende.previousYear'
                      ),
                      id(
                        'notes.note33.data.BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenAbstract.table.BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenAbstract.BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringNedskrivningarAbstract.*.previousYear'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans1',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenNedskrivningar',
                    standardRubrik:
                      'Ackumulerade nedskrivningar balanserade utgifter för utvecklingsarbeten och liknande arbeten',
                    saldo: 'credit',
                    negateValue: true,
                  }
                )
              );
              return rows.build();
            });
          rows.addRowWithType(
            'BalanseradeUtgifterUtvecklingsarbetenLiknandeArbeten',
            'sum',
            noteLabel('Redovisat värde'),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note33.data.BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenAbstract.table.BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenAbstract.BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenAnskaffningsvardenUtgaende.year'
                  ),
                  id(
                    'notes.note33.data.BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenAbstract.table.BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenAbstract.BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenAvskrivningarUtgaende.year'
                  ),
                  id(
                    'notes.note33.data.BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenAbstract.table.BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenAbstract.BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenUppskrivningarUtgaende.year'
                  ),
                  id(
                    'notes.note33.data.BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenAbstract.table.BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenAbstract.BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenNedskrivningarUtgaende.year'
                  )
                )
              ),
              {
                contextRef: 'balans0',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknandeArbeten',
                standardRubrik:
                  'Balanserade utgifter för utvecklingsarbeten och liknande arbeten',
                saldo: 'debit',
                negateValue: false,
              }
            ),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note33.data.BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenAbstract.table.BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenAbstract.BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenAnskaffningsvardenUtgaende.previousYear'
                  ),
                  id(
                    'notes.note33.data.BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenAbstract.table.BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenAbstract.BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenAvskrivningarUtgaende.previousYear'
                  ),
                  id(
                    'notes.note33.data.BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenAbstract.table.BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenAbstract.BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenUppskrivningarUtgaende.previousYear'
                  ),
                  id(
                    'notes.note33.data.BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenAbstract.table.BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenAbstract.BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenNedskrivningarUtgaende.previousYear'
                  )
                )
              ),
              {
                contextRef: 'balans1',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknandeArbeten',
                standardRubrik:
                  'Balanserade utgifter för utvecklingsarbeten och liknande arbeten',
                saldo: 'debit',
                negateValue: false,
              }
            )
          );
          rows.addRowWithType(
            'BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenOffentligaBidrag',
            'row',
            noteLabel('Offentliga bidrag som reducerat anskaffningsvärdet'),
            ixbrlCell(value(undefined), {
              contextRef: 'balans0',
              type: 'monetaryItemType',
              unitRef: 'SEK',
              name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenOffentligaBidrag',
              standardRubrik:
                'Offentliga bidrag som reducerat anskaffningsvärdet på balanserade utgifter för utvecklingsarbeten och liknande arbeten',
              saldo: 'credit',
              negateValue: true,
            }),
            ixbrlCell(value(undefined), {
              contextRef: 'balans1',
              type: 'monetaryItemType',
              unitRef: 'SEK',
              name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenOffentligaBidrag',
              standardRubrik:
                'Offentliga bidrag som reducerat anskaffningsvärdet på balanserade utgifter för utvecklingsarbeten och liknande arbeten',
              saldo: 'credit',
              negateValue: true,
            })
          );
          return rows.build();
        })
        .build(),
      NotBalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenKommentar: {
        type: 'group',
        active: true,
        level: 0,
        title: field(
          'Kommentar till specifikation av balanserade utgifter för utvecklingsarbeten och liknande arbeten'
        ),
        value: ixbrlField(field(''), {
          contextRef: 'period0',
          type: 'stringItemType',
          name: 'se-gen-base:NotBalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenKommentar',
          standardRubrik:
            'Kommentar till specifikation av balanserade utgifter för utvecklingsarbeten och liknande arbeten',
        }),
      },
    },
  },
  group: value(undefined),
  section: value(undefined),
  validForReportType: {
    k2: true,
    k3: true,
  },
});
export const note2033K3v2ContentDefinition = {
  type: 'part' as const,
  children: {
    active: boolean,
    number: cell,
    name: cell,
    data: {
      type: 'part' as const,
      children: {
        active: boolean,

        BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenAbstract: {
          type: 'part' as const,
          children: {
            type: type,
            level: number,
            active: boolean,

            title: cell,

            table: { type: 'table' as const },

            NotBalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenKommentar: {
              type: 'part' as const,
              children: {
                type: type,
                level: number,
                active: boolean,

                title: cell,

                value: cell,
              },
            },
          },
        },
      },
    },
    group: cell,
    section: cell,
    validForReportType: {
      type: 'part' as const,
      children: {
        k2: boolean,
        k3: boolean,
      },
    },
  },
};

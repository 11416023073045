import { ref, table, value } from '@agoy/document';
import { NoteOneTableSection } from '../../../../common';
import {
  field,
  ixbrlCell,
  ixbrlField,
  range,
} from '../../../../common/utils/util';
import {
  sumAccountsIBs,
  sumNotesRowsById,
} from '../../../../common/utils/accountSumsUtil';
import { year, previousYear } from '../../../../common/utils/date-util';
import { NoteAccountRanges } from '../types';

const MONETARY_ITEM_TYPE = 'monetaryItemType';

export const note92Accounts: NoteAccountRanges[] = [
  {
    rowId: '1',
    ranges: [[1200, 1213]],
    cellLabel: 'Ingående anskaffningsvärden',
    action: 'sumAccountsIBs',
  },
  {
    rowId: '9',
    ranges: [[1219]],
    cellLabel: 'Ingående avskrivningar',
    action: 'sumAccountsIBs',
  },
  {
    rowId: '25',
    ranges: [[1218]],
    cellLabel: 'Ingående nedskrivningar',
    action: 'sumAccountsIBs',
  },
];

export const getNote92 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null,
  documentTypeVersion = '1'
): NoteOneTableSection => {
  return {
    active: false,
    number: value(undefined),
    name: value('Maskiner och andra tekniska anläggningar'),
    table: table(
      `notes.note92.table`,
      { id: 'label', label: '' },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      // 'ingående' = previous period outgoing balance
      .addRows((rows) =>
        rows
          .addRow(
            '1',
            noteLabel('Ingående anskaffningsvärden'),
            ixbrlCell(ref(sumAccountsIBs('1200:1213', false)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarAnskaffningsvarden',
              contextRef: 'balans1',
              saldo: 'debit',
              standardRubrik:
                'Anskaffningsvärden maskiner och andra tekniska anläggningar',
            }),
            ixbrlCell(ref(sumAccountsIBs('1200:1213', false, 'previousYear')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarAnskaffningsvarden',
              contextRef: 'balans2',
              saldo: 'debit',
              standardRubrik:
                'Anskaffningsvärden maskiner och andra tekniska anläggningar',
            })
          )
          .addRow(
            'ChangesInAcquisitionValuesTitle',
            noteLabel('Förändringar av anskaffningsvärden')
          )
          .addRow(
            '2',
            noteLabel('Inköp'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarForandringAnskaffningsvardenInkop',
              contextRef: 'period0',
              standardRubrik:
                'Ökning av anskaffningsvärden i samband med inköp av maskiner och andra tekniska anläggningar',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarForandringAnskaffningsvardenInkop',
              contextRef: 'period1',
              standardRubrik:
                'Ökning av anskaffningsvärden i samband med inköp av maskiner och andra tekniska anläggningar',
            })
          )
          .addRow(
            '3',
            noteLabel('Rörelseförvärv'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarForandringAnskaffningsvardenRorelseforvarv',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden för maskiner och andra tekniska anläggningar genom rörelseförvärv',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarForandringAnskaffningsvardenRorelseforvarv',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden för maskiner och andra tekniska anläggningar genom rörelseförvärv',
            })
          )
          .addRow(
            '4',
            noteLabel('Fusion'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarForandringAnskaffningsvardenFusion',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden för maskiner och andra tekniska anläggningar genom fusion',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarForandringAnskaffningsvardenFusion',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden för maskiner och andra tekniska anläggningar genom fusion',
            })
          )
          .addRow(
            '5',
            noteLabel('Försäljningar/utrangeringar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarForandringAnskaffningsvardenForsaljningarUtrangeringar',
              contextRef: 'period0',
              standardRubrik:
                'Minskning av anskaffningsvärden i samband med försäljningar eller utrangeringar av maskiner och andra tekniska anläggningar',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarForandringAnskaffningsvardenForsaljningarUtrangeringar',
              contextRef: 'period1',
              standardRubrik:
                'Minskning av anskaffningsvärden i samband med försäljningar eller utrangeringar av maskiner och andra tekniska anläggningar',
            })
          )
          .addRow(
            '6',
            noteLabel('Omklassificeringar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarForandringAnskaffningsvardenOmklassificeringar',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden i samband med omklassificeringar av maskiner och andra tekniska anläggningar',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarForandringAnskaffningsvardenOmklassificeringar',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden i samband med omklassificeringar av maskiner och andra tekniska anläggningar',
            })
          )
          .addRow(
            '7',
            noteLabel('Omräkningsdifferenser'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarForandringAnskaffningsvardenOmrakningsdifferenser',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden i samband med omräkningsdifferenser av maskiner och andra tekniska anläggningar',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarForandringAnskaffningsvardenOmrakningsdifferenser',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden i samband med omräkningsdifferenser av maskiner och andra tekniska anläggningar',
            })
          )
          .addRow(
            '8',
            noteLabel('Utgående anskaffningsvärden'),
            ixbrlCell(sumNotesRowsById(92, [...range(1, 8)], 'year'), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarAnskaffningsvarden',
              contextRef: 'balans0',
              saldo: 'debit',
              standardRubrik:
                'Anskaffningsvärden maskiner och andra tekniska anläggningar',
            }),
            ixbrlCell(sumNotesRowsById(92, [...range(1, 8)], 'previousYear'), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarAnskaffningsvarden',
              contextRef: 'balans1',
              saldo: 'debit',
              standardRubrik:
                'Anskaffningsvärden maskiner och andra tekniska anläggningar',
            })
          )
          .addRow(
            '9',
            noteLabel('Ingående avskrivningar'),
            ixbrlCell(ref(sumAccountsIBs('1219', false)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarAvskrivningar',
              contextRef: 'balans1',
              saldo: 'credit',
              standardRubrik:
                'Ackumulerade avskrivningar maskiner och andra tekniska anläggningar',
            }),
            ixbrlCell(ref(sumAccountsIBs('1219', false, 'previousYear')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarAvskrivningar',
              contextRef: 'balans2',
              saldo: 'credit',
              standardRubrik:
                'Ackumulerade avskrivningar maskiner och andra tekniska anläggningar',
            })
          )
          .addRow(
            'ChangesInDepreciationTitle',
            noteLabel('Förändringar av avskrivningar')
          )
          .addRow(
            '10',
            noteLabel('Fusion'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarForandringAvskrivningarFusion',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av avskrivningar för maskiner och andra tekniska anläggningar genom fusion',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarForandringAvskrivningarFusion',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av avskrivningar för maskiner och andra tekniska anläggningar genom fusion',
            })
          )
          .addRow(
            '11',
            noteLabel('Försäljningar/utrangeringar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarForandringAvskrivningarAterfordaAvskrivningarForsaljningarRespUtrangeringar',
              contextRef: 'period0',
              standardRubrik:
                'Återförda avskrivningar i samband med försäljningar eller utrangeringar av maskiner och andra tekniska anläggningar',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarForandringAvskrivningarAterfordaAvskrivningarForsaljningarRespUtrangeringar',
              contextRef: 'period1',
              standardRubrik:
                'Återförda avskrivningar i samband med försäljningar eller utrangeringar av maskiner och andra tekniska anläggningar',
            })
          )
          .addRow(
            '12',
            noteLabel('Omklassificeringar'), // no accounts
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarForandringAvskrivningarOmklassificeringar',
              contextRef: 'period0',
              standardRubrik:
                'Minskning (ökning) av avskrivningar i samband med omklassificeringar av maskiner och andra tekniska anläggningar',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarForandringAvskrivningarOmklassificeringar',
              contextRef: 'period1',
              standardRubrik:
                'Minskning (ökning) av avskrivningar i samband med omklassificeringar av maskiner och andra tekniska anläggningar',
            })
          )
          .addRow(
            '13',
            noteLabel('Årets avskrivningar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarForandringAvskrivningarAretsAvskrivningar',
              contextRef: 'period0',
              standardRubrik:
                'Periodens avskrivningar maskiner och andra tekniska anläggningar',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarForandringAvskrivningarAretsAvskrivningar',
              contextRef: 'period1',
              standardRubrik:
                'Periodens avskrivningar maskiner och andra tekniska anläggningar',
            })
          )
          .addRow(
            '14',
            noteLabel('Omräkningsdifferenser'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarForandringAvskrivningarOmrakningsdifferenser',
              contextRef: 'period0',
              standardRubrik:
                'Minskning (ökning) av avskrivningar i samband med omräkningsdifferenser av maskiner och andra tekniska anläggningar',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarForandringAvskrivningarOmrakningsdifferenser',
              contextRef: 'period1',
              standardRubrik:
                'Minskning (ökning) av avskrivningar i samband med omräkningsdifferenser av maskiner och andra tekniska anläggningar',
            })
          )
          .addRow(
            '15',
            noteLabel('Utgående avskrivningar'),
            ixbrlCell(sumNotesRowsById(92, [...range(9, 15)], 'year'), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarAvskrivningar',
              contextRef: 'balans0',
              saldo: 'credit',
              standardRubrik:
                'Ackumulerade avskrivningar maskiner och andra tekniska anläggningar',
            }),
            ixbrlCell(sumNotesRowsById(92, [...range(9, 14)], 'previousYear'), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarAvskrivningar',
              contextRef: 'balans1',
              saldo: 'credit',
              standardRubrik:
                'Ackumulerade avskrivningar maskiner och andra tekniska anläggningar',
            })
          )
          .addRow(
            '16',
            noteLabel('Ingående uppskrivningar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarUppskrivningar',
              contextRef: 'balans1',
              saldo: 'debit',
              standardRubrik:
                'Ackumulerade uppskrivningar maskiner och andra tekniska anläggningar',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarUppskrivningar',
              contextRef: 'balans2',
              saldo: 'debit',
              standardRubrik:
                'Ackumulerade uppskrivningar maskiner och andra tekniska anläggningar',
            })
          )
          .addRow(
            'ChangesToRevaluationsTitle',
            noteLabel('Förändringar av uppskrivningar')
          )
          .addRow(
            '17',
            noteLabel('Fusion'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarForandringUppskrivningarFusion',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av uppskrivningar för maskiner och andra tekniska anläggningar genom fusion',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarForandringUppskrivningarFusion',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av uppskrivningar för maskiner och andra tekniska anläggningar genom fusion',
            })
          )
          .addRow(
            '18',
            noteLabel('Försäljningar/utrangeringar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarForandringUppskrivningarForsaljningarUtrangeringar',
              contextRef: 'period0',
              standardRubrik:
                'Återförda uppskrivningar i samband med försäljningar eller utrangeringar av maskiner och andra tekniska anläggningar',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarForandringUppskrivningarForsaljningarUtrangeringar',
              contextRef: 'period1',
              standardRubrik:
                'Återförda uppskrivningar i samband med försäljningar eller utrangeringar av maskiner och andra tekniska anläggningar',
            })
          )
          .addRow(
            '19',
            noteLabel('Omklassificeringar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarForandringUppskrivningarOmklassificeringar',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av uppskrivningar i samband med omklassificeringar av maskiner och andra tekniska anläggningar',
            }), // no accounts
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarForandringUppskrivningarOmklassificeringar',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av uppskrivningar i samband med omklassificeringar av maskiner och andra tekniska anläggningar',
            })
          )
          .addRow(
            '20',
            noteLabel('Årets uppskrivningar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarForandringUppskrivningarAretsUppskrivningar',
              contextRef: 'period0',
              standardRubrik:
                'Periodens uppskrivningar av maskiner och andra tekniska anläggningar',
            }), // no accounts
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarForandringUppskrivningarAretsUppskrivningar',
              contextRef: 'period1',
              standardRubrik:
                'Periodens uppskrivningar av maskiner och andra tekniska anläggningar',
            })
          )
          .addRow(
            '21',
            noteLabel('Årets nedskrivningar på uppskrivet belopp'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarForandringUppskrivningarAretsNedskrivningarUppskrivetBelopp',
              contextRef: 'period0',
              standardRubrik:
                'Nedskrivningar av uppskrivet belopp på maskiner och andra tekniska anläggningar',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarForandringUppskrivningarAretsNedskrivningarUppskrivetBelopp',
              contextRef: 'period1',
              standardRubrik:
                'Nedskrivningar av uppskrivet belopp på maskiner och andra tekniska anläggningar',
            })
          )
          .addRow(
            '22',
            noteLabel('Årets avskrivningar på uppskrivet belopp'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarForandringUppskrivningarAretsAvskrivningarUppskrivetBelopp',
              contextRef: 'period0',
              standardRubrik:
                'Avskrivningar av uppskrivet belopp på maskiner och andra tekniska anläggningar',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarForandringUppskrivningarAretsAvskrivningarUppskrivetBelopp',
              contextRef: 'period1',
              standardRubrik:
                'Avskrivningar av uppskrivet belopp på maskiner och andra tekniska anläggningar',
            })
          )
          .addRow(
            '23',
            noteLabel('Omräkningsdifferenser'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarForandringUppskrivningarOmrakningsdifferenser',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av uppskrivningar i samband med omräkningsdifferenser av maskiner och andra tekniska anläggningar',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarForandringUppskrivningarOmrakningsdifferenser',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av uppskrivningar i samband med omräkningsdifferenser av maskiner och andra tekniska anläggningar',
            })
          )
          .addRow(
            '24',
            noteLabel('Utgående uppskrivningar'),
            ixbrlCell(sumNotesRowsById(92, [...range(16, 23)], 'year'), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarUppskrivningar',
              contextRef: 'balans0',
              saldo: 'debit',
              standardRubrik:
                'Ackumulerade uppskrivningar maskiner och andra tekniska anläggningar',
            }),
            ixbrlCell(
              sumNotesRowsById(92, [...range(16, 23)], 'previousYear'),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarUppskrivningar',
                contextRef: 'balans1',
                saldo: 'debit',
                standardRubrik:
                  'Ackumulerade uppskrivningar maskiner och andra tekniska anläggningar',
              }
            )
          )
          .addRow(
            '25',
            noteLabel('Ingående nedskrivningar'),
            ixbrlCell(ref(sumAccountsIBs('1218', false)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarNedskrivningar',
              contextRef: 'balans1',
              saldo: 'credit',
              standardRubrik:
                'Ackumulerade nedskrivningar maskiner och andra tekniska anläggningar',
            }),
            ixbrlCell(ref(sumAccountsIBs('1218', false, 'previousYear')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarNedskrivningar',
              contextRef: 'balans2',
              saldo: 'credit',
              standardRubrik:
                'Ackumulerade nedskrivningar maskiner och andra tekniska anläggningar',
            })
          )
          .addRow(
            'ChangesInImpairmentChargesTitle',
            noteLabel('Förändringar av nedskrivningar')
          )
          .addRow(
            '26',
            noteLabel('Fusion'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarForandringNedskrivningarFusion',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av nedskrivningar för maskiner och andra tekniska anläggningar genom fusion',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarForandringNedskrivningarFusion',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av nedskrivningar för maskiner och andra tekniska anläggningar genom fusion',
            })
          )
          .addRow(
            '27',
            noteLabel('Försäljningar/utrangeringar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarForandringNedskrivningarAterfordaNedskrivningarForsaljningarRespUtrangeringar',
              contextRef: 'period0',
              standardRubrik:
                'Återförda nedskrivningar i samband med försäljningar eller utrangeringar av maskiner och andra tekniska anläggningarr',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarForandringNedskrivningarAterfordaNedskrivningarForsaljningarRespUtrangeringar',
              contextRef: 'period1',
              standardRubrik:
                'Återförda nedskrivningar i samband med försäljningar eller utrangeringar av maskiner och andra tekniska anläggningarr',
            })
          )
          .addRow(
            '28',
            noteLabel('Omklassificeringar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarForandringNedskrivningarOmklassificeringar',
              contextRef: 'period0',
              standardRubrik:
                'Minskning (ökning) av nedskrivningar i samband med omklassificeringar av maskiner och andra tekniska anläggningar',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarForandringNedskrivningarOmklassificeringar',
              contextRef: 'period1',
              standardRubrik:
                'Minskning (ökning) av nedskrivningar i samband med omklassificeringar av maskiner och andra tekniska anläggningar',
            })
          )
          .addRow(
            '29',
            noteLabel('Återförda nedskrivningar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarForandringNedskrivningarAterfordaNedskrivningar',
              contextRef: 'period0',
              standardRubrik:
                'Periodens återförda nedskrivningar av maskiner och andra tekniska anläggningar',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarForandringNedskrivningarAterfordaNedskrivningar',
              contextRef: 'period1',
              standardRubrik:
                'Periodens återförda nedskrivningar av maskiner och andra tekniska anläggningar',
            })
          )
          .addRow(
            '30',
            noteLabel('Årets nedskrivningar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarForandringNedskrivningarAretsNedskrivningar',
              contextRef: 'period0',
              standardRubrik:
                'Periodens nedskrivningar av maskiner och andra tekniska anläggningar',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarForandringNedskrivningarAretsNedskrivningar',
              contextRef: 'period1',
              standardRubrik:
                'Periodens nedskrivningar av maskiner och andra tekniska anläggningar',
            })
          )
          .addRow(
            '31',
            noteLabel('Omräkningsdifferenser'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarForandringNedskrivningarOmrakningsdifferenser',
              contextRef: 'period0',
              saldo: 'credit',
              standardRubrik:
                'Minskning (ökning) av nedskrivningar i samband med omräkningsdifferenser av maskiner och andra tekniska anläggningar',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarForandringNedskrivningarOmrakningsdifferenser',
              contextRef: 'period1',
              saldo: 'credit',
              standardRubrik:
                'Minskning (ökning) av nedskrivningar i samband med omräkningsdifferenser av maskiner och andra tekniska anläggningar',
            })
          )
          .addRow(
            '32',
            noteLabel('Utgående nedskrivningar'),
            ixbrlCell(sumNotesRowsById(92, [...range(25, 31)], 'year'), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarNedskrivningar',
              contextRef: 'balans0',
              saldo: 'credit',
              standardRubrik:
                'Ackumulerade nedskrivningar maskiner och andra tekniska anläggningar',
            }),
            ixbrlCell(
              sumNotesRowsById(92, [...range(25, 31)], 'previousYear'),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarNedskrivningar',
                contextRef: 'balans1',
                saldo: 'credit',
                standardRubrik:
                  'Ackumulerade nedskrivningar maskiner och andra tekniska anläggningar',
              }
            )
          )
          .addRow(
            '33',
            noteLabel('Redovisat värde'),
            ixbrlCell(sumNotesRowsById(92, ['8', '15', '24', '32'], 'year'), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningar',
              contextRef: 'balans0',
              saldo: 'debit',
              standardRubrik: 'Maskiner och andra tekniska anläggningar',
            }),
            ixbrlCell(
              sumNotesRowsById(92, ['8', '15', '24', '32'], 'previousYear'),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningar',
                contextRef: 'balans1',
                saldo: 'debit',
                standardRubrik: 'Maskiner och andra tekniska anläggningar',
              }
            )
          )
          .addRow(
            '34',
            noteLabel('Offentliga bidrag som reducerat anskaffningsvärdet'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarOffentligaBidrag',
              contextRef: 'balans0',
              saldo: 'credit',
              standardRubrik:
                'Offentliga bidrag som reducerat anskaffningsvärdet på maskiner och andra tekniska anläggningar',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarOffentligaBidrag',
              contextRef: 'balans1',
              saldo: 'credit',
              standardRubrik:
                'Offentliga bidrag som reducerat anskaffningsvärdet på maskiner och andra tekniska anläggningar',
            })
          )
          .build()
      )
      .newRowTemplate(value(''), value(undefined), value(undefined))
      .build(),
    commentLabel: field('Kommentar'),
    comment: ixbrlField(field(''), {
      type: 'stringItemType',
      name: 'se-gen-base:NotMaskinerAndraTekniskaAnlaggningarKommentar',
      contextRef: 'period0',
      standardRubrik:
        'Kommentar till specifikation av maskiner och andra tekniska anläggningar',
    }),
    group: value(undefined),
    section: value(undefined),
    validForReportType: {
      k2: false,
      k3: documentTypeVersion !== '1',
    },
  };
};

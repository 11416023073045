import React, { useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { useIntl } from 'react-intl';
import { Box } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import styled from '@emotion/styled';

import { TimePeriod } from '@agoy/document';
import { reformatDate } from '@agoy/common';

import { TaxesDataContextProvider } from '_tax/service/TaxesDataContext';

import AccrualFunds from '_tax/components/Taxes/AccrualFunds/AccrualFunds';
import AccrualFundDeposition from '_tax/components/Taxes/AccrualFunds/AccrualFundDeposition';
import Button from '_shared/components/Buttons/Button';
import PeriodDataContext from '../PeriodDataContext';

const Container = styled(Box)`
  display: grid;
  grid-template-columns: auto auto auto;
`;

const AccrualFundView = (): JSX.Element | null => {
  const history = useHistory();
  const { formatMessage } = useIntl();

  const { clientId, financialYear, lastPeriod } = useContext(PeriodDataContext);

  const financialYearAsString = TimePeriod.fromISODates(financialYear).value;

  const onClickTaxCalculation = useCallback(() => {
    history.push(`/clients/${clientId}/${financialYearAsString}/tax/accruals`);
  }, [history, clientId, financialYearAsString]);

  return (
    <TaxesDataContextProvider
      clientId={clientId}
      financialYearId={financialYear.id}
      financialYear={financialYearAsString}
      periods={financialYear.periods}
      period={reformatDate(lastPeriod.end, 'yyyy-MM-dd', 'yyyyMMdd')}
    >
      <Container>
        <AccrualFundDeposition print={false} />

        <AccrualFunds />

        <Button
          label={formatMessage({ id: 'tax' })}
          endIcon={<ArrowForwardIcon />}
          onClick={onClickTaxCalculation}
        />
      </Container>
    </TaxesDataContextProvider>
  );
};

export default AccrualFundView;

import {
  value,
  ref,
  table,
  sum,
  id,
  BooleanCell,
  NumberCell,
  StringCell,
  LabelCell,
} from '@agoy/document';
import { NoteOneTableSection, IxbrlCell } from '../../../../common';
import {
  sumAccountsUBs,
  sumNotesRowsById,
} from '../../../../common/utils/accountSumsUtil';
import {
  active,
  field,
  ixbrlCell,
  ixbrlField,
} from '../../../../common/utils/util';
import { year, previousYear } from '../../../../common/utils/date-util';

const MONETARY_ITEM_TYPE = 'monetaryItemType';

export const getNote71 = (
  noteLabel: (
    name: string
  ) => BooleanCell | NumberCell | StringCell | LabelCell,
  financialYear: string,
  financialYears: string[],
  period: string | null
): NoteOneTableSection => {
  return {
    active: false,
    number: value(undefined),
    name: value('Upparbetad men ej fakturerad intäkt'),
    table: table<IxbrlCell>(
      'notes.note71.table',
      {
        id: 'label',
        label: 'Upparbetad men ej fakturerad intäkt',
      },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      .addRows((rows) =>
        rows
          .addRow(
            '1',
            noteLabel('Upparbetad intäkt'),
            ixbrlCell(ref(sumAccountsUBs('1620', false)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:UpparbetadEjFaktureradIntaktUpparbetat',
              contextRef: 'balans0',
            }),
            ixbrlCell(ref(sumAccountsUBs('1620', false, 'previousYear')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:UpparbetadEjFaktureradIntaktUpparbetat',
              contextRef: 'balans1',
            })
          )
          .addRow(
            '2',
            noteLabel('Fakturerat belopp'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:UpparbetadEjFaktureradIntaktFakturerat',
              contextRef: 'balans0',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:UpparbetadEjFaktureradIntaktFakturerat',
              contextRef: 'balans1',
            })
          )
          .addRow(
            '3',
            noteLabel('Redovisat värde'),
            ixbrlCell(sumNotesRowsById(71, [1, 2], 'year'), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:UpparbetadEjFaktureradIntakt',
              contextRef: 'balans0',
              saldo: 'debit',
            }),
            ixbrlCell(sumNotesRowsById(71, [1, 2], 'previousYear'), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:UpparbetadEjFaktureradIntakt',
              contextRef: 'balans1',
              saldo: 'debit',
            })
          )
          .addRow(
            '4',
            noteLabel('Av beställaren innehållet belopp'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:UpparbetadEjFaktureradIntaktBestallarenInnehalletBelopp',
              contextRef: 'balans0',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:UpparbetadEjFaktureradIntaktBestallarenInnehalletBelopp',
              contextRef: 'balans1',
            })
          )
          .newRowTemplate(value(''), value(undefined), value(undefined))
          .build()
      )
      .build(),
    commentLabel: field('Kommentar'),
    comment: ixbrlField(active(field('')), {
      type: 'stringItemType',
      name: 'se-gen-base:NotUpparbetadEjFaktureradIntaktKommentar',
      contextRef: 'period0',
    }),
    group: value(undefined),
    section: value(undefined),
    validForReportType: {
      k2: false,
      k3: true,
    },
  };
};

import {
  programStatusMapSchema,
  agoyStatuses,
  intervalTypes,
} from './status-map-schema';

type ProgramStatusMapSchemaType = typeof programStatusMapSchema;
interface ProgramStatus {
  isValidSchema(schema: ProgramStatusMapSchemaType): boolean;

  createProgramStatus<
    programKey extends keyof typeof programStatusMapSchema & string,
    intervalTypeKey extends keyof typeof programStatusMapSchema[programKey] &
      string,
    statusKey extends keyof typeof programStatusMapSchema[programKey][intervalTypeKey] &
      string
  >(args: {
    program: programKey;
    intervalType: intervalTypeKey;
    interval: string;
    status: statusKey;
  }): {
    program: programKey;
    period?: string;
    financialYear?: string;
    status: statusKey;
  };
}

class ProgramStatusImpl implements ProgramStatus {
  private programStatusMapSchema: ProgramStatusMapSchemaType;

  constructor(programStatusMapSchema: ProgramStatusMapSchemaType) {
    this.programStatusMapSchema = programStatusMapSchema;

    if (!this.isValidSchema(programStatusMapSchema)) {
      throw new Error('The programStatusMapSchema is not valid');
    }
  }

  isValidSchema(schema: ProgramStatusMapSchemaType): boolean {
    try {
      for (const programKey in schema) {
        for (const intervalType in schema[programKey]) {
          if (!intervalTypes.includes(intervalType as any)) {
            console.error(
              `Interval type ${intervalType} not valid for ${programKey}`
            );
            return false;
          }
          for (const statusKey in schema[programKey][intervalType]) {
            if (!agoyStatuses.includes(statusKey as any)) {
              return false;
            }
          }
        }
      }
      return true;
    } catch (error) {
      return false;
    }
  }

  createProgramStatus<
    programKey extends keyof typeof programStatusMapSchema & string,
    intervalTypeKey extends keyof typeof programStatusMapSchema[programKey] &
      string,
    statusKey extends keyof typeof programStatusMapSchema[programKey][intervalTypeKey] &
      string
  >(args: {
    program: programKey;
    intervalType: intervalTypeKey;
    interval: string;
    status: statusKey;
  }): {
    program: programKey;
    period?: string;
    financialYear?: string;
    status: statusKey;
  } {
    const { program, intervalType, interval, status } = args;
    return {
      program,
      status,
      [intervalType]: interval,
    };
  }
}

export default ProgramStatusImpl;

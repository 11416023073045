import React, { memo } from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { AgoyTableColumn, AgoyTableRow } from '@agoy/document';
import styled from '@emotion/styled';
import { Cell } from '../CommonPrintTable/Cell';
import { rowHaveValues } from '../../utils/rowHaveValues';

export type HierarchicalPrintTableRowProps = {
  className?: string;
  classes?: Record<string, string>;
  baseId: string;
  row: AgoyTableRow;
  columns: AgoyTableColumn[];
  level: number;
  rowComponents: React.ComponentType<HierarchicalPrintTableRowProps>[];
  print?: boolean;
  zoom?: number;
  isSinglePeriodSelected?: boolean;
};

const classes = {};

const HierarchicalPrintTableRow = memo(
  React.forwardRef<HTMLTableRowElement, HierarchicalPrintTableRowProps>(
    (
      {
        className,
        row,
        columns,
        baseId,
        level,
        rowComponents,
        print,
        zoom,
        isSinglePeriodSelected,
      },
      fwdRef
    ) => {
      const SubRowComponent = rowComponents[level];

      // Don't render the row if it has no values and no notes
      if (!rowHaveValues(row)) {
        return null;
      }

      /**
       * https://agoyit.atlassian.net/browse/AGOY-4326
       * In the page we show the accounts that make up Årets resultat
       * but in the preview we just want to show the title Årets resultat without the sub rows
       * as it was and is in `Summa resultat`
       * so here we get the `year` and `previousYear` value to display it with `Årets resultat`
       */
      if (
        row.id === 'result' &&
        row.rows != null &&
        row?.rows[0]?.cells?.year != null
      ) {
        row = {
          ...row,
          cells: {
            ...row.cells,
            previousYear: row?.rows[0]?.cells?.previousYear,
            year: row?.rows[0]?.cells?.year,
          },
        };
      }

      return (
        <>
          {row.cells && (
            <TableRow ref={fwdRef} key={row.id} className={className}>
              {columns.map((col) => {
                const cell = row.cells?.[col.id];
                const key = `${row.id}.${col.id}`;
                if (!cell) {
                  return <TableCell key={key} />;
                }
                // AGOY-886 do not render table cells that belong to column hidden
                if (col.id.includes('hidden')) {
                  return null;
                }
                return (
                  <TableCell key={key}>
                    <Cell cell={cell} />
                  </TableCell>
                );
              })}
            </TableRow>
          )}
          {SubRowComponent &&
            row.rows?.map((subRow) => (
              <SubRowComponent
                classes={classes}
                key={`${baseId}.${row.id}.${subRow.id}`}
                row={subRow}
                baseId={`${baseId}.${row.id}`}
                columns={columns}
                level={level + 1}
                rowComponents={rowComponents}
                print={print}
                zoom={zoom}
                isSinglePeriodSelected={isSinglePeriodSelected}
              />
            ))}
        </>
      );
    }
  )
);

export const RowLevel1 = styled(HierarchicalPrintTableRow)<{
  sumRow?: boolean;
  zoom?: number;
}>`
  padding: ${({ theme, zoom }) =>
      zoom ? theme.spacing(1) * zoom : theme.spacing(1)}px
    0px;

  .MuiTableCell-body {
    font-size: ${({ theme, zoom }) => {
      const size = theme.typography.fontSize;

      return zoom ? size * zoom : size;
    }}px;
    font-weight: 700;
    font-style: italic;
    ${({ sumRow }) => (sumRow ? 'font-style: italic;' : '')}
    p {
      font-size: ${({ theme, zoom }) =>
        zoom ? theme.typography.fontSize * zoom : theme.typography.fontSize}px;
    }
  }

  .MuiTableCell-head {
    font-size: ${({ theme, zoom }) => {
      const size = theme.typography.fontSize;

      return zoom ? size * zoom : size;
    }}px;
    font-weight: 700;
    font-style: italic;
    ${({ sumRow }) => (sumRow ? 'font-style: italic;' : '')}
    p {
      font-size: ${({ theme, zoom }) =>
        zoom ? theme.typography.fontSize * zoom : theme.typography.fontSize}px;
    }
  }

  .MuiTableCell-body:first-of-type {
    padding-left: 0;
  }
`;

export const RowLevel2 = styled(HierarchicalPrintTableRow)<{
  heading?: boolean;
  sumRow?: boolean;
  zoom?: number;
}>`
  padding: ${({ theme, zoom }) =>
      zoom ? theme.spacing(1) * zoom : theme.spacing(1)}px
    0px;

  .MuiTableCell-body {
    font-size: ${({ theme, zoom }) => {
      const size = theme.typography.fontSize;

      return zoom ? size * zoom : size;
    }}px;
    font-weight: 700;
    ${({ sumRow }) => (sumRow ? 'font-style: italic;' : '')}

    p {
      font-size: ${({ theme, zoom }) =>
        zoom ? theme.typography.fontSize * zoom : theme.typography.fontSize}px;
    }
  }
  .MuiTableCell-body:first-of-type {
    padding-left: 0;
    color: ${({ theme }) => theme.palette.text.primary};
  }
`;

export const RowLevel3 = styled(HierarchicalPrintTableRow)<{
  zoom?: number;
  hideRow?: boolean;
}>`
  padding: ${({ theme, zoom }) =>
    zoom ? theme.spacing((1 / 2) * zoom, 1 * zoom) : theme.spacing(1 / 2, 1)}px;

  .MuiTableCell-body {
    font-size: ${({ theme, zoom }) =>
      zoom ? theme.typography.fontSize * zoom : theme.typography.fontSize}px;

    p {
      font-size: ${({ theme, zoom }) =>
        zoom ? theme.typography.fontSize * zoom : theme.typography.fontSize}px;
    }
  }

  .MuiTableCell-body:first-of-type {
    padding-left: ${({ theme }) => theme.spacing(2)}px;
  }
  // AGOY-3942: hiding title and sum rows from the normal view
  ${({ hideRow }) => hideRow && `display: none`};
`;

export default HierarchicalPrintTableRow;

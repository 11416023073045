/*
 * This is a GENERATED file, do not change manually!
 * Generated:
 * - from file k2-arsredovisning-2021-10-31-rev20230131_sv - Noter.csv
 * - Comments: nan
 */

import {
  value,
  ref,
  sumAllowNull,
  id,
  AgoyDocumentPart,
} from '@agoy/document';
import { IxbrlCell } from '../../../../../common';
import {
  field,
  ixbrlCell,
  ixbrlField,
  table,
} from '../../../../../common/utils/util';
import { previousYear, year } from '../../../../../common/utils/date-util';
import {
  sumAccountsIBs,
} from '../../../../../common/utils/accountSumsUtil';

const cell = { type: 'field' as const };
const boolean = { type: 'boolean' as const };
const type = { type: 'type' as const };
const number = { type: 'number' as const };

export const getNote2043K2v2 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null
): AgoyDocumentPart<typeof note2043K2v2ContentDefinition> => ({
  active: false,
  number: value(undefined),
  name: noteLabel(
    'Pågående nyanläggningar och förskott avseende materiella anläggningstillgångar'
  ),
  data: {
    active: true,
    PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarAbstract: {
      type: 'group',
      active: true,
      level: 0,
      title: field(
        'Pågående nyanläggningar och förskott avseende materiella anläggningstillgångar'
      ),
      table: table<IxbrlCell>(
        'notes.note43.data.PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarAbstract.table',
        {
          id: 'PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarAbstract',
          label: '',
          dataType: 'text',
          active: true,
        },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) ?? '',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) ?? '',
          dataType: 'numeric',
          active: true,
        }
      )
        .addRows((rows) => {
          rows
            .addRowWithType(
              'PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarAbstract',
              'hidden',
              undefined,
              undefined,
              undefined
            )
            .addSubRows((rows) => {
              rows.addRowWithType(
                'PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarAnskaffningsvardenIngaende',
                'sum_accounts',
                noteLabel('Ingående anskaffningsvärden'),
                ixbrlCell(
                  ref(sumAccountsIBs('1180:1181+1188+1280:1281+1288', false)),
                  {
                    contextRef: 'balans1',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarAnskaffningsvarden',
                    standardRubrik:
                      'Anskaffningsvärden pågående nyanläggningar och förskott avseende materiella anläggningstillgångar',
                    saldo: 'debit',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sumAccountsIBs(
                      '1180:1181+1188+1280:1281+1288',
                      false,
                      'previousYear'
                    )
                  ),
                  {
                    contextRef: 'balans2',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarAnskaffningsvarden',
                    standardRubrik:
                      'Anskaffningsvärden pågående nyanläggningar och förskott avseende materiella anläggningstillgångar',
                    saldo: 'debit',
                    negateValue: false,
                  }
                )
              );
              rows
                .addRowWithType(
                  'PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringAnskaffningsvardenAbstract',
                  'header',
                  noteLabel('Förändringar av anskaffningsvärden'),
                  undefined,
                  undefined
                )
                .addSubRows((rows) => {
                  rows.addRowWithType(
                    'PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringAnskaffningsvardenNedlagdaUtgifter',
                    'row',
                    noteLabel('Nedlagda utgifter'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringAnskaffningsvardenNedlagdaUtgifter',
                      standardRubrik:
                        'Ökning av anskaffningsvärden i samband med produktion av pågående nyanläggningar avseende materiella anläggningstillgångar',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringAnskaffningsvardenNedlagdaUtgifter',
                      standardRubrik:
                        'Ökning av anskaffningsvärden i samband med produktion av pågående nyanläggningar avseende materiella anläggningstillgångar',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringAnskaffningsvardenUtbetaladeForskott',
                    'row',
                    noteLabel('Utbetalda förskott'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringAnskaffningsvardenUtbetaladeForskott',
                      standardRubrik:
                        'Ökning av anskaffningsvärden i samband med periodens utbetalda förskott avseende materiella anläggningstillgångar',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringAnskaffningsvardenUtbetaladeForskott',
                      standardRubrik:
                        'Ökning av anskaffningsvärden i samband med periodens utbetalda förskott avseende materiella anläggningstillgångar',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringAnskaffningsvardenRorelseforvarv',
                    'row',
                    noteLabel('Rörelseförvärv'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringAnskaffningsvardenRorelseforvarv',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden för pågående nyanläggningar och förskott avseende materiella anläggningstillgångar genom rörelseförvärv',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringAnskaffningsvardenRorelseforvarv',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden för pågående nyanläggningar och förskott avseende materiella anläggningstillgångar genom rörelseförvärv',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringAnskaffningsvardenFusion',
                    'row',
                    noteLabel('Fusion'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringAnskaffningsvardenFusion',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden för pågående nyanläggningar och förskott avseende materiella anläggningstillgångar genom fusion',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringAnskaffningsvardenFusion',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden för pågående nyanläggningar och förskott avseende materiella anläggningstillgångar genom fusion',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringAnskaffningsvardenForsaljningarUtrangeringar',
                    'row',
                    noteLabel('Försäljningar/utrangeringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringAnskaffningsvardenForsaljningarUtrangeringar',
                      standardRubrik:
                        'Minskning av anskaffningsvärden i samband med försäljningar eller utrangeringar av pågående nyanläggningar avseende materiella anläggningstillgångar',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringAnskaffningsvardenForsaljningarUtrangeringar',
                      standardRubrik:
                        'Minskning av anskaffningsvärden i samband med försäljningar eller utrangeringar av pågående nyanläggningar avseende materiella anläggningstillgångar',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringAnskaffningsvardenAterbetaladeForskott',
                    'row',
                    noteLabel('Återbetalda förskott'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringAnskaffningsvardenAterbetaladeForskott',
                      standardRubrik:
                        'Minskning av anskaffningsvärden i samband med periodens återbetalda förskott avseende materiella anläggningstillgångar',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringAnskaffningsvardenAterbetaladeForskott',
                      standardRubrik:
                        'Minskning av anskaffningsvärden i samband med periodens återbetalda förskott avseende materiella anläggningstillgångar',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringAnskaffningsvardenBortskrivnaForskott',
                    'row',
                    noteLabel('Bortskrivna förskott'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringAnskaffningsvardenBortskrivnaForskott',
                      standardRubrik:
                        'Minskning av anskaffningsvärden i samband med bortskrivning av förskott avseende materiella anläggningstillgångar',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringAnskaffningsvardenBortskrivnaForskott',
                      standardRubrik:
                        'Minskning av anskaffningsvärden i samband med bortskrivning av förskott avseende materiella anläggningstillgångar',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringAnskaffningsvardenOmklassificeringar',
                    'row',
                    noteLabel('Omklassificeringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringAnskaffningsvardenOmklassificeringar',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden i samband med omklassificeringar av pågående nyanläggningar och förskott avseende materiella anläggningstillgångar',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringAnskaffningsvardenOmklassificeringar',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden i samband med omklassificeringar av pågående nyanläggningar och förskott avseende materiella anläggningstillgångar',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringAnskaffningsvardenOmrakningsdifferenser',
                    'row',
                    noteLabel('Omräkningsdifferenser'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringAnskaffningsvardenOmrakningsdifferenser',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden i samband med omräkningsdifferenser av pågående nyanläggningar och förskott avseende materiella anläggningstillgångar',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringAnskaffningsvardenOmrakningsdifferenser',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden i samband med omräkningsdifferenser av pågående nyanläggningar och förskott avseende materiella anläggningstillgångar',
                      negateValue: false,
                    })
                  );
                  return rows.build();
                });
              rows.addRowWithType(
                'PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarAnskaffningsvardenUtgaende',
                'sum',
                noteLabel('Utgående anskaffningsvärden'),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note43.data.PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarAbstract.table.PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarAbstract.PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarAnskaffningsvardenIngaende.year'
                      ),
                      id(
                        'notes.note43.data.PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarAbstract.table.PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarAbstract.PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringAnskaffningsvardenAbstract.*.year'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans0',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarAnskaffningsvarden',
                    standardRubrik:
                      'Anskaffningsvärden pågående nyanläggningar och förskott avseende materiella anläggningstillgångar',
                    saldo: 'debit',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note43.data.PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarAbstract.table.PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarAbstract.PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarAnskaffningsvardenIngaende.previousYear'
                      ),
                      id(
                        'notes.note43.data.PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarAbstract.table.PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarAbstract.PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringAnskaffningsvardenAbstract.*.previousYear'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans1',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarAnskaffningsvarden',
                    standardRubrik:
                      'Anskaffningsvärden pågående nyanläggningar och förskott avseende materiella anläggningstillgångar',
                    saldo: 'debit',
                    negateValue: false,
                  }
                )
              );
              rows.addRowWithType(
                'PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarNedskrivningarIngaende',
                'row',
                noteLabel('Ingående nedskrivningar'),
                ixbrlCell(value(undefined), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarNedskrivningar',
                  standardRubrik:
                    'Ackumulerade nedskrivningar pågående nyanläggningar och förskott avseende materiella anläggningstillgångar',
                  saldo: 'credit',
                  negateValue: true,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'balans2',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarNedskrivningar',
                  standardRubrik:
                    'Ackumulerade nedskrivningar pågående nyanläggningar och förskott avseende materiella anläggningstillgångar',
                  saldo: 'credit',
                  negateValue: true,
                })
              );
              rows
                .addRowWithType(
                  'PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringNedskrivningarAbstract',
                  'header',
                  noteLabel('Förändringar av nedskrivningar'),
                  undefined,
                  undefined
                )
                .addSubRows((rows) => {
                  rows.addRowWithType(
                    'PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringNedskrivningarFusion',
                    'row',
                    noteLabel('Fusion'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringNedskrivningarFusion',
                      standardRubrik:
                        'Ökning (minskning) av nedskrivningar för pågående nyanläggningar och förskott avseende materiella anläggningstillgångar genom fusion',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringNedskrivningarFusion',
                      standardRubrik:
                        'Ökning (minskning) av nedskrivningar för pågående nyanläggningar och förskott avseende materiella anläggningstillgångar genom fusion',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringNedskrivningarAterfordaNedskrivningarForsaljningarRespUtrangeringar',
                    'row',
                    noteLabel('Försäljningar/utrangeringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringNedskrivningarAterfordaNedskrivningarForsaljningarRespUtrangeringar',
                      standardRubrik:
                        'Återförda nedskrivningar i samband med försäljningar eller utrangeringar av pågående nyanläggningar avseende materiella anläggningstillgångar',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringNedskrivningarAterfordaNedskrivningarForsaljningarRespUtrangeringar',
                      standardRubrik:
                        'Återförda nedskrivningar i samband med försäljningar eller utrangeringar av pågående nyanläggningar avseende materiella anläggningstillgångar',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringNedskrivningarAterfordaNedskrivningar',
                    'row',
                    noteLabel('Återförda nedskrivningar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringNedskrivningarAterfordaNedskrivningar',
                      standardRubrik:
                        'Periodens återförda nedskrivningar av pågående nyanläggningar och förskott avseende materiella anläggningstillgångar',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringNedskrivningarAterfordaNedskrivningar',
                      standardRubrik:
                        'Periodens återförda nedskrivningar av pågående nyanläggningar och förskott avseende materiella anläggningstillgångar',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringNedskrivningarBortskrivnaForskott',
                    'row',
                    noteLabel('Bortskrivna förskott'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringNedskrivningarBortskrivnaForskott',
                      standardRubrik:
                        'Minskning av nedskrivningar i samband med bortskrivning av förskott avseende materiella anläggningstillgångar',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringNedskrivningarBortskrivnaForskott',
                      standardRubrik:
                        'Minskning av nedskrivningar i samband med bortskrivning av förskott avseende materiella anläggningstillgångar',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringNedskrivningarOmklassificeringar',
                    'row',
                    noteLabel('Omklassificeringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringNedskrivningarOmklassificeringar',
                      standardRubrik:
                        'Minskning (ökning) av nedskrivningar i samband med omklassificeringar av pågående nyanläggningar och förskott avseende materiella anläggningstillgångar',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringNedskrivningarOmklassificeringar',
                      standardRubrik:
                        'Minskning (ökning) av nedskrivningar i samband med omklassificeringar av pågående nyanläggningar och förskott avseende materiella anläggningstillgångar',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringNedskrivningarAretsNedskrivningar',
                    'row',
                    noteLabel('Årets nedskrivningar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringNedskrivningarAretsNedskrivningar',
                      standardRubrik:
                        'Periodens nedskrivningar av pågående nyanläggningar och förskott avseende materiella anläggningstillgångar',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringNedskrivningarAretsNedskrivningar',
                      standardRubrik:
                        'Periodens nedskrivningar av pågående nyanläggningar och förskott avseende materiella anläggningstillgångar',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringNedskrivningarOmrakningsdifferenser',
                    'row',
                    noteLabel('Omräkningsdifferenser'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringNedskrivningarOmrakningsdifferenser',
                      standardRubrik:
                        'Minskning (ökning) av nedskrivningar i samband med omräkningsdifferenser av pågående nyanläggningar och förskott avseende materiella anläggningstillgångar',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringNedskrivningarOmrakningsdifferenser',
                      standardRubrik:
                        'Minskning (ökning) av nedskrivningar i samband med omräkningsdifferenser av pågående nyanläggningar och förskott avseende materiella anläggningstillgångar',
                      negateValue: false,
                    })
                  );
                  return rows.build();
                });
              rows.addRowWithType(
                'PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarNedskrivningarUtgaende',
                'sum',
                noteLabel('Utgående nedskrivningar'),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note43.data.PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarAbstract.table.PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarAbstract.PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarNedskrivningarIngaende.year'
                      ),
                      id(
                        'notes.note43.data.PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarAbstract.table.PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarAbstract.PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringNedskrivningarAbstract.*.year'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans0',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarNedskrivningar',
                    standardRubrik:
                      'Ackumulerade nedskrivningar pågående nyanläggningar och förskott avseende materiella anläggningstillgångar',
                    saldo: 'credit',
                    negateValue: true,
                  }
                ),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note43.data.PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarAbstract.table.PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarAbstract.PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarNedskrivningarIngaende.previousYear'
                      ),
                      id(
                        'notes.note43.data.PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarAbstract.table.PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarAbstract.PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringNedskrivningarAbstract.*.previousYear'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans1',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarNedskrivningar',
                    standardRubrik:
                      'Ackumulerade nedskrivningar pågående nyanläggningar och förskott avseende materiella anläggningstillgångar',
                    saldo: 'credit',
                    negateValue: true,
                  }
                )
              );
              return rows.build();
            });
          rows.addRowWithType(
            'PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangar',
            'sum',
            noteLabel('Redovisat värde'),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note43.data.PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarAbstract.table.PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarAbstract.PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarAnskaffningsvardenUtgaende.year'
                  ),
                  id(
                    'notes.note43.data.PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarAbstract.table.PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarAbstract.PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarNedskrivningarUtgaende.year'
                  )
                )
              ),
              {
                contextRef: 'balans0',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangar',
                standardRubrik:
                  'Pågående nyanläggningar och förskott avseende materiella anläggningstillgångar',
                saldo: 'debit',
                negateValue: false,
              }
            ),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note43.data.PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarAbstract.table.PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarAbstract.PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarAnskaffningsvardenUtgaende.previousYear'
                  ),
                  id(
                    'notes.note43.data.PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarAbstract.table.PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarAbstract.PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarNedskrivningarUtgaende.previousYear'
                  )
                )
              ),
              {
                contextRef: 'balans1',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangar',
                standardRubrik:
                  'Pågående nyanläggningar och förskott avseende materiella anläggningstillgångar',
                saldo: 'debit',
                negateValue: false,
              }
            )
          );
          return rows.build();
        })
        .build(),
      NotPagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarKommentar:
        {
          type: 'group',
          active: true,
          level: 0,
          title: field(
            'Kommentar till specifikation av pågående nyanläggningar och förskott avseende materiella anläggningstillgångar'
          ),
          value: ixbrlField(field(''), {
            contextRef: 'period0',
            type: 'stringItemType',
            name: 'se-gen-base:NotPagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarKommentar',
            standardRubrik:
              'Kommentar till specifikation av pågående nyanläggningar och förskott avseende materiella anläggningstillgångar',
          }),
        },
    },
  },
  group: value(undefined),
  section: value(undefined),
  validForReportType: {
    k2: true,
    k3: true,
  },
});
export const note2043K2v2ContentDefinition = {
  type: 'part' as const,
  children: {
    active: boolean,
    number: cell,
    name: cell,
    data: {
      type: 'part' as const,
      children: {
        active: boolean,

        PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarAbstract: {
          type: 'part' as const,
          children: {
            type: type,
            level: number,
            active: boolean,

            title: cell,

            table: { type: 'table' as const },

            NotPagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarKommentar:
              {
                type: 'part' as const,
                children: {
                  type: type,
                  level: number,
                  active: boolean,

                  title: cell,

                  value: cell,
                },
              },
          },
        },
      },
    },
    group: cell,
    section: cell,
    validForReportType: {
      type: 'part' as const,
      children: {
        k2: boolean,
        k3: boolean,
      },
    },
  },
};

import React from 'react';
import { useIntl } from 'react-intl';
import { Typography, Breadcrumbs } from '@material-ui/core';

type Props = {
  clientName: string;
};

const Header = ({ clientName }: Props): JSX.Element => {
  const { formatMessage } = useIntl();

  return (
    <>
      <Breadcrumbs>
        <Typography variant="subtitle1">{clientName}</Typography>
        <Typography variant="subtitle1">
          {formatMessage({ id: 'activitylog.title' })}
        </Typography>
      </Breadcrumbs>
      <Typography variant="h2">
        {formatMessage({ id: 'activitylog.title' })}
      </Typography>
    </>
  );
};

export default Header;

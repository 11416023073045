import React, { useMemo } from 'react';
import { Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import {
  ChangesInEquity as ChangesInEquityType,
  SharesCompanyManagementReport,
  EconomicAssociationManagementReport,
  EconomicAssociationChangesInEquity,
  isActive,
  CommonNotes,
  AnnualReportType,
} from '@agoy/annual-report-document';
import styled from '@emotion/styled';
import { stringValue } from '@agoy/document';

import { Heading, Section as BaseSection } from '../../../../UI';
import YearTable from '../../UI/YearTable';
import BigTable from './BigTable';
import MultilineField from '../../UI/MultilineField';
import { FormattingContext } from '@agoy/formatting';
import splitTableByColumns from '../../../../utils/splitTableByColumns';

const Section = styled(BaseSection)`
  width: 100%;
`;

interface ChangesInEquityProps {
  managementReport:
    | SharesCompanyManagementReport
    | EconomicAssociationManagementReport;
  reportPart: ChangesInEquityType | EconomicAssociationChangesInEquity;
  notes?: CommonNotes;
  transitionK3toK2: boolean;
  isDigitalSubmission: boolean | null | undefined;
  version: '1' | '2';
  reportType: AnnualReportType;
}

const ChangesInEquity = ({
  managementReport,
  reportPart,
  notes,
  transitionK3toK2,
  isDigitalSubmission,
  version,
  reportType,
}: ChangesInEquityProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const { table } = reportPart;

  const isK3WithVersion2 = reportType === 'k3' && version === '2';

  const formattingContext = React.useContext(FormattingContext);
  const displayLabel = formattingContext.shouldPrintLabel();

  const title =
    stringValue(managementReport.changesInEquity.title) ||
    formatMessage({
      id: 'annualReport.menu.managementReport.changesInEquity',
    });

  const commentLabelValue =
    stringValue(managementReport.changesInEquity.commentLabel) ||
    formatMessage({
      id: `annualReport.managementReport.changesInEquity.commentLabel`,
    });

  const isCommentEmpty = reportPart.hasOwnProperty('comment')
    ? stringValue(reportPart.comment) === ''
    : true;

  const isYearTableActive =
    'conditionalShareholderContributions' in reportPart &&
    reportPart.conditionalShareholderContributions.rows.some(
      (row) => isActive(row.active) !== false
    );

  const splittedTable = isK3WithVersion2 && splitTableByColumns(table);

  return (
    <>
      <Section>
        <Heading title={title} />
        {displayLabel && (
          <Typography variant="h5">
            {formatMessage(
              {
                id: 'annualReport.menu.managementReport.currencyPart',
              },
              {
                type: 'Förändringar i eget kapital',
              }
            )}
          </Typography>
        )}

        {!isK3WithVersion2 && (
          <BigTable
            table={table}
            transitionK3ToK2={transitionK3toK2}
            notes={notes}
          />
        )}

        {splittedTable &&
          splittedTable.map((table, index) => {
            return (
              <BigTable
                key={`changesInEquityTable-${index}`}
                table={table}
                transitionK3ToK2={transitionK3toK2}
                notes={notes}
              />
            );
          })}

        {!isCommentEmpty && (
          <MultilineField
            header={<Typography variant="h4">{commentLabelValue}</Typography>}
            field={'comment' in reportPart ? reportPart.comment : undefined}
          />
        )}
      </Section>
      {!isDigitalSubmission &&
        'conditionalShareholderContributions' in reportPart &&
        isYearTableActive && (
          <Section>
            <Typography variant="h4" color="textSecondary">
              {formatMessage({
                id: 'annualReport.menu.managementReport.changesInEquity.conditionalShareholderContributions',
              })}
            </Typography>
            <YearTable
              tableId="managementReport.changesInEquity.conditionalShareholderContributions"
              table={reportPart.conditionalShareholderContributions}
              isDigitalSubmission={isDigitalSubmission}
            />
          </Section>
        )}
    </>
  );
};

export default ChangesInEquity;

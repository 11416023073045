import { Action } from 'redux';
import { CompanyType } from '_clients/types/types';

export * from '_reconciliation/redux/accounting-view/actions';
export * from '_users/redux/actions';
export * from '_clients/redux/customers/actions';
export * from '_organization/redux/actions';
export * from './TaxDeclarationView';
export * from '_clients/redux/customers-overview/actions';
export * from '_clients/redux/customer-view/actions';
export * from '_messages/redux/actions';

export interface ClientAction extends Action<any> {
  clientId: string;
}

export interface ClientYearAction extends ClientAction {
  financialYear: string;
}

/**
 * ! Temporary interface, need to be replaced or removed after re-writing baseConfig
 */
export interface ClientAdjustmentsAction extends ClientYearAction {
  periods: string[];
  companyType: CompanyType;
}

export interface ClientPeriodAction extends ClientAction {
  period: string;
}

export function isClientYearAction(action): action is ClientYearAction {
  return action && 'clientId' in action && 'financialYear' in action;
}

export function isClientPeriodAction(action): action is ClientPeriodAction {
  return action && 'clientId' in action && 'period' in action;
}

export function isClientAction(action): action is ClientAction {
  return action && 'clientId' in action;
}

import React, { useEffect } from 'react';
import { useSelector } from 'redux/reducers';
import { useDispatch } from 'react-redux';
import { fetchProgramStatus } from '_shared/redux/actions';
import { Program } from '_shared/types';

type LoadProductStatusesProps = {
  program: Program;
};

const LoadProductStatuses = ({
  program,
}: LoadProductStatusesProps): JSX.Element | null => {
  const dispatch = useDispatch();
  const { currentCustomer: clientId, currentYear } = useSelector(
    (state) => state.customerView
  );

  useEffect(() => {
    if (clientId && currentYear) {
      dispatch(fetchProgramStatus(clientId, program));
    }
  }, [dispatch, clientId, currentYear, program]);

  return null;
};

export default LoadProductStatuses;

import {
  ReferenceCell,
  ref,
  value,
  or,
  id,
  sum,
  account,
  multiply,
  ifOrElse,
} from '@agoy/document';
import { AnnualReportType } from '../../../document';
import { IxbrlCell } from '../../../common/types/ixbrl';
import { table, ixbrlCell } from '../../../common/utils/util';
import { getWarningCell } from '../../settings/settings';
import { changesInEquityIxbrl } from '../managementReport';

export const v1ChangesInEquityK2OrTransitionK3toK2DigitalSubmission = (
  reportType: AnnualReportType,
  transitionK3toK2: boolean
) => {
  const sumRowValues = (
    baseId: string,
    reportType: AnnualReportType,
    transitioning = false
  ): ReferenceCell => {
    return ref(
      or(
        sum(
          or(id(`${baseId}.shareCapital`), 0),
          or(id(`${baseId}.ejRegistreratAktiekapital`), 0),
          or(id(`${baseId}.bundenOverkursfond`), 0),
          or(id(`${baseId}.uppskrivingsFond`), 0),
          or(id(`${baseId}.reservFond`), 0),
          transitioning ? or(id(`${baseId}.fondForUtvecklingsutgifter`), 0) : 0, // used when transitioning from K3 to K2
          transitioning ? or(id(`${baseId}.ovrigaBundnaFonder`), 0) : 0, // used when transitioning from K3 to K2
          or(id(`${baseId}.friOverkursfond`), 0),
          transitioning ? or(id(`${baseId}.fondForVerkligtVarde`), 0) : 0, // used when transitioning from K3 to K2
          transitioning ? or(id(`${baseId}.ovrigaFriaFonder`), 0) : 0, // used when transitioning from K3 to K2
          or(id(`${baseId}.balanseratResultat`), 0),
          or(id(`${baseId}.yearResult`), 0)
        ),
        0
      )
    );
  };

  return table<IxbrlCell>(
    'managementReport.changesInEquity.table',
    { id: 'label', label: '' },
    { id: 'shareCapital', label: 'Aktiekapital' },
    {
      id: 'ejRegistreratAktiekapital',
      label: 'Ej registrerat aktiekapital',
    },
    // These undefined are to compensate for the missing columns in K2
    undefined,
    { id: 'bundenOverkursfond', label: 'Bunden överkursfond' },
    { id: 'uppskrivingsFond', label: 'Uppskrivningsfond' },
    { id: 'reservFond', label: 'Reservfond' },
    undefined,
    transitionK3toK2
      ? {
          id: 'fondForUtvecklingsutgifter',
          label: 'Fond för utvecklingsutgifter',
        }
      : undefined,
    transitionK3toK2
      ? {
          id: 'ovrigaBundnaFonder',
          label: 'Övriga bundna fonder',
        }
      : undefined,
    { id: 'friOverkursfond', label: 'Fri överkursfond' },
    transitionK3toK2
      ? {
          id: 'fondForVerkligtVarde',
          label: 'Fond för verkligt värde',
        }
      : undefined,
    transitionK3toK2
      ? {
          id: 'ovrigaFriaFonder',
          label: 'Övriga fria fonder',
        }
      : undefined,
    undefined,
    { id: 'balanseratResultat', label: 'Balanserat resultat' },
    { id: 'yearResult', label: 'Årets resultat' },
    { id: 'total', label: 'Totalt' },
    { id: 'balanseratResultatWarning' },
    { id: 'yearResultWarning' }
  )
    .addRows((rows) =>
      rows
        // Additional rows to display if report type is K2 and transition from K3 is toggled
        .addRow('transitionToK2')
        .addSubRows((rows) =>
          rows
            .addRowActive(true)
            .addRow(
              'amountPrevYear',
              value('Belopp vid årets ingång enligt fastställd balansräkning'),
              ixbrlCell(
                value(0),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2Aktiekapital',
                  'balans1',
                  'credit'
                )
              ),
              ixbrlCell(
                value(0),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2EjRegistreratAktiekapital',
                  'balans1',
                  'credit'
                )
              ),
              undefined,
              ixbrlCell(
                value(0),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2BundenOverkursfond',
                  'balans1',
                  'credit'
                )
              ),
              ixbrlCell(
                value(0),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2Uppskrivningsfond',
                  'balans1',
                  'credit'
                )
              ),
              ixbrlCell(
                value(0),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2Reservfond',
                  'balans1',
                  'credit'
                )
              ),
              undefined,
              ixbrlCell(
                value(0),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2FondUtvecklingsutgift',
                  'balans1',
                  'credit'
                )
              ),
              ixbrlCell(
                value(0),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2OvrigaBundnaFonder',
                  'balans1',
                  'credit'
                )
              ),
              ixbrlCell(
                value(0),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2Overkursfond',
                  'balans1',
                  'credit'
                )
              ),
              ixbrlCell(
                value(0),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2FondVerkligtVarde',
                  'balans1',
                  'credit'
                )
              ),
              ixbrlCell(
                value(0),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2OvrigaFriaFonder',
                  'balans1',
                  'credit'
                )
              ),
              undefined,
              ixbrlCell(
                value(0),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2BalanseratResultat',
                  'balans1',
                  'credit'
                )
              ),
              ixbrlCell(
                value(0),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2AretsResultat',
                  'balans1',
                  'credit'
                )
              ),
              ixbrlCell(
                sumRowValues(
                  'managementReport.changesInEquity.table.transitionToK2.amountPrevYear',
                  'k2',
                  true
                ),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2',
                  'balans1',
                  'credit'
                )
              )
            )
            .addRowActive(false)
            .addRow(
              '3',
              value('Egenupparbetad immateriell anläggningstillgång'),
              value(0),
              value(0),
              undefined,
              value(0),
              value(0),
              value(0),
              undefined,
              ixbrlCell(
                value(0),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2FondUtvecklingsutgiftEgenupparbetadImmateriellAnlaggningstillgang',
                  'balans0',
                  undefined,
                  'Minskning av ingående fond för utvecklingsutgifter till följd av korrigering vid övergång till K2 avseende egenupparbetad immateriell anläggningstillgång',
                  true
                )
              ),
              value(0),
              value(0),
              value(0),
              value(0),
              undefined,
              ixbrlCell(
                value(0),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2BalanseratResultatKorrigeringEgenupparbetadImmateriellAnlaggningstillgang',
                  'balans0',
                  undefined,
                  'Minskning av ingående balanserat resultat till följd av korrigering vid övergång till K2 avseende egen upparbetad immateriell anläggningstillgång',
                  true
                )
              ),
              value(0),
              ixbrlCell(
                sumRowValues(
                  'managementReport.changesInEquity.table.transitionToK2.3',
                  'k2',
                  true
                ),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2KorrigeringEgenupparbetadImmateriellAnlaggningstillgang',
                  'balans0',
                  undefined,
                  'Minskning av eget kapital vid övergång till K2 avseende egenupparbetad immateriell anläggningstillgång',
                  true
                )
              )
            )
            .addRow(
              '4',
              value(
                'Materiell anläggningstillgång till följd av att ett leasingavtal redovisats som finansiell leasing'
              ),
              value(0),
              value(0),
              undefined,
              value(0),
              value(0),
              value(0),
              undefined,
              value(0),
              value(0),
              value(0),
              value(0),
              value(0),
              undefined,
              ixbrlCell(
                value(0),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2BalanseratResultatKorrigeringMateriellAnlaggningstillgangFinansiellLeasing',
                  'balans0',
                  undefined,
                  'Minskning av ingående balanserat resultat till följd av korrigering vid övergång till K2 avseende materiell anläggningstillgång till följd av att ett leasingavtal redovisats som finansiell leasing',
                  true
                )
              ),
              value(0),
              ixbrlCell(
                sumRowValues(
                  'managementReport.changesInEquity.table.transitionToK2.4',
                  'k2',
                  true
                ),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2KorrigeringMateriellAnlaggningstillgangFinansiellLeasing',
                  'balans0',
                  undefined,
                  'Minskning av eget kapital vid övergång till K2 avseende materiell anläggningstillgång till följd av att ett leasingavtal redovisats som finansiell leasing',
                  true
                )
              )
            )
            .addRow(
              '5',
              value(
                'Skuld till följd av att ett leasingavtal redovisats som finansiell leasing'
              ),
              value(0),
              value(0),
              undefined,
              value(0),
              value(0),
              value(0),
              undefined,
              value(0),
              value(0),
              value(0),
              value(0),
              value(0),
              undefined,
              ixbrlCell(
                value(0),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2BalanseratResultatKorrigeringSkuldFinansiellLeasing',
                  'balans0',
                  undefined,
                  'Ökning av ingående balanserat resultat till följd av korrigering vid övergång till K2 avseende skuld till följd av att ett leasingavtal redovisats som finansiell leasing'
                )
              ),
              value(0),
              ixbrlCell(
                sumRowValues(
                  'managementReport.changesInEquity.table.transitionToK2.5',
                  'k2',
                  true
                ),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2KorrigeringSkuldFinansiellLeasing',
                  'balans0',
                  undefined,
                  'Ökning av eget kapital vid övergång till K2 avseende skuld till följd av att ett leasingavtal redovisats som finansiell leasing'
                )
              )
            )
            .addRow(
              '6',
              value('Uppskjuten skattefordran'),
              value(0),
              value(0),
              undefined,
              value(0),
              value(0),
              value(0),
              undefined,
              value(0),
              value(0),
              value(0),
              value(0),
              value(0),
              undefined,
              ixbrlCell(
                value(0),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2BalanseratResultatKorrigeringUppskjutenSkattefordran',
                  'balans0',
                  undefined,
                  'Minskning av ingående balanserat resultat till följd av korrigering vid övergång till K2 avseende uppskjuten skattefordran',
                  true
                )
              ),
              value(0),
              ixbrlCell(
                sumRowValues(
                  'managementReport.changesInEquity.table.transitionToK2.6',
                  'k2',
                  true
                ),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2KorrigeringUppskjutenSkattefordran',
                  'balans0',
                  undefined,
                  'Minskning av eget kapital vid övergång till K2 avseende uppskjuten skattefordran',
                  true
                )
              )
            )
            .addRow(
              '7',
              value('Uppskjuten skatteskuld'),
              value(0),
              value(0),
              undefined,
              value(0),
              ixbrlCell(
                value(0),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2UppskrivningsfondKorrigeringUppskjutenSkatteskuld',
                  'balans0',
                  undefined,
                  'Ökning av ingående uppskrivningsfond till följd av korrigering vid övergång till K2 avseende uppskjuten skatteskuld till följd av att en anläggningstillgång tidigare har skrivits upp'
                )
              ),
              value(0),
              undefined,
              value(0),
              value(0),
              value(0),
              value(0),
              value(0),
              undefined,
              ixbrlCell(
                value(0),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2BalanseratResultatKorrigeringUppskjutenSkatteskuld',
                  'balans0',
                  undefined,
                  'Ökning av ingående balanserat resultat till följd av korrigering vid övergång till K2 avseende uppskjuten skatteskuld'
                )
              ),
              value(0),
              ixbrlCell(
                sumRowValues(
                  'managementReport.changesInEquity.table.transitionToK2.7',
                  'k2',
                  true
                ),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2KorrigeringResultatUppskjutenSkatteskuld',
                  'balans0',
                  undefined,
                  'Ökning av eget kapital vid övergång till K2 avseende uppskjuten skatteskuld'
                )
              )
            )
            .addRow(
              '8',
              value('Finansiell anläggningstillgång'),
              value(0),
              value(0),
              undefined,
              value(0),
              value(0),
              value(0),
              undefined,
              value(0),
              value(0),
              value(0),
              value(0),
              value(0),
              undefined,
              ixbrlCell(
                value(0),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2BalanseratResultatKorrigeringFinansiellAnlaggningstillgang',
                  'balans0',
                  undefined,
                  'Minskning av ingående balanserat resultat till följd av korrigering vid övergång till K2 avseende finansiella anläggningstillgångar',
                  true
                )
              ),
              value(0),
              ixbrlCell(
                sumRowValues(
                  'managementReport.changesInEquity.table.transitionToK2.8',
                  'k2',
                  true
                ),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2KorrigeringResultatFinansiellAnlaggningstillgang',
                  'balans0',
                  undefined,
                  'Minskning av eget kapital vid övergång till K2 avseende finansiella anläggningstillgångar',
                  true
                )
              )
            )
            .addRow(
              '9',
              value('Åtaganden'),
              value(0),
              value(0),
              undefined,
              value(0),
              value(0),
              value(0),
              undefined,
              value(0),
              value(0),
              value(0),
              value(0),
              value(0),
              undefined,
              ixbrlCell(
                value(0),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2BalanseratResultatKorrigeringAtaganden',
                  'balans0',
                  undefined,
                  'Ökning av ingående balanserat resultat till följd av korrigering vid övergång till K2 avseende åtaganden'
                )
              ),
              value(0),
              ixbrlCell(
                sumRowValues(
                  'managementReport.changesInEquity.table.transitionToK2.9',
                  'k2',
                  true
                ),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2KorrigeringResultatAtaganden',
                  'balans0',
                  undefined,
                  'Ökning av eget kapital vid övergång till K2 avseende åtaganden'
                )
              )
            )
            .addRow(
              '10',
              value(
                'Andra uppskrivna anläggningstillgångar än byggnad och mark'
              ),
              value(0),
              value(0),
              undefined,
              value(0),
              ixbrlCell(
                value(0),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2UppskrivningsfondKorrigeringAndraUppskrivnaAnlaggningstillgangarAnByggnadMark',
                  'balans0',
                  undefined,
                  'Minskning av ingående uppskrivningsfond till följd av korrigering vid övergång till K2 avseende andra uppskrivna anläggningstillgångar än byggnad och mark',
                  true
                )
              ),
              value(0),
              undefined,
              value(0),
              value(0),
              value(0),
              value(0),
              value(0),
              undefined,
              ixbrlCell(
                value(0),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2BalanseratResultatKorrigeringAndraUppskrivnaAnlaggningstillgangarAnByggnadMark',
                  'balans0',
                  undefined,
                  'Minskning av ingående balanserat resultat till följd av korrigering vid övergång till K2 avseende andra uppskrivna anläggningstillgångar än byggnad och mark',
                  true
                )
              ),
              value(0),
              ixbrlCell(
                sumRowValues(
                  'managementReport.changesInEquity.table.transitionToK2.10',
                  'k2',
                  true
                ),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2KorrigeringAndraUppskrivnaAnlaggningstillgangarAnByggnadMark',
                  'balans0',
                  undefined,
                  'Minskning av eget kapital vid övergång till K2 avseende andra uppskrivna anläggningstillgångar än byggnad och mark',
                  true
                )
              )
            )
            .addRow(
              '11',
              value(
                'Finansiella instrument värderade till verkligt värde enligt 4 kap. 14 a § årsredovisningslagen (1995:1554)'
              ),
              value(0),
              value(0),
              undefined,
              value(0),
              value(0),
              value(0),
              undefined,
              value(0),
              value(0),
              value(0),
              ixbrlCell(
                value(0),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2FondVerkligtVardeKorrigeringFinansiellaInstrument',
                  'balans0',
                  undefined,
                  'Minskning av ingående fond för verkligt värde till följd av korrigering vid övergång till K2 avseende finansiella instrument',
                  true
                )
              ),
              value(0),
              undefined,
              ixbrlCell(
                value(0),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2BalanseratResultatKorrigeringFinansiellaInstrument',
                  'balans0',
                  undefined,
                  'Minskning av ingående balanserat resultat till följd av korrigering vid övergång till K2 avseende finansiella instrument värderade till verkligt värde',
                  true
                )
              ),
              value(0),
              ixbrlCell(
                sumRowValues(
                  'managementReport.changesInEquity.table.transitionToK2.11',
                  'k2',
                  true
                ),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2KorrigeringFinansiellaInstrument',
                  'balans0',
                  undefined,
                  'Minskning av eget kapital vid övergång till K2 avseende finansiella instrument värderade till verkligt värde',
                  true
                )
              )
            )
            .addRow(
              '12',
              value('Övriga korrigeringar'),
              value(0),
              value(0),
              undefined,
              value(0),
              value(0),
              value(0),
              undefined,
              value(0),
              ixbrlCell(
                value(0),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2OvrigaBundnaFonderKorrigeratOvrigaBundnaFonderOvrigaJusteringar',
                  'balans0',
                  undefined,
                  'Minskning av ingående övriga bundna fonder till följd av korrigering vid övergång till K2 avseende övriga korrigeringar',
                  true
                )
              ),
              value(0),
              value(0),
              ixbrlCell(
                value(0),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2OvrigaFriaFonderKorrigeringOvrigaJusteringar',
                  'balans0',
                  undefined,
                  'Minskning av ingående övriga fria fonder till följd av korrigering vid övergång till K2 avseende övriga korrigeringar',
                  true
                )
              ),
              undefined,
              ixbrlCell(
                value(0),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2BalanseratResultatKorrigeringOvrigaJusteringar',
                  'balans0',
                  undefined,
                  'Ökning av ingående balanserat resultat till följd av korrigering vid övergång till K2 avseende övriga korrigeringar'
                )
              ),
              value(0),
              ixbrlCell(
                sumRowValues(
                  'managementReport.changesInEquity.table.transitionToK2.12',
                  'k2',
                  true
                ),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2KorrigeringResultatOvrigaJusteringar',
                  'balans0'
                )
              )
            )
            .addRow(
              '13',
              value('Korrigerat belopp vid årets ingång'),
              ixbrlCell(
                value(0),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2Aktiekapital',
                  'balans1',
                  'credit'
                )
              ),
              ixbrlCell(
                value(0),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2EjRegistreratAktiekapital',
                  'balans1',
                  'credit'
                )
              ),
              undefined,
              ixbrlCell(
                value(0),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2BundenOverkursfond',
                  'balans1',
                  'credit'
                )
              ),
              ixbrlCell(
                value(0),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2Uppskrivningsfond',
                  'balans1',
                  'credit'
                )
              ),
              ixbrlCell(
                value(0),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2Reservfond',
                  'balans1',
                  'credit'
                )
              ),
              undefined,
              ixbrlCell(
                value(0),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2FondUtvecklingsutgift',
                  'balans1',
                  'credit'
                )
              ),
              ixbrlCell(
                value(0),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2OvrigaBundnaFonder',
                  'balans1',
                  'credit'
                )
              ),
              ixbrlCell(
                value(0),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2Overkursfond',
                  'balans1',
                  'credit'
                )
              ),
              ixbrlCell(
                value(0),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2FondVerkligtVarde',
                  'balans1',
                  'credit'
                )
              ),
              ixbrlCell(
                value(0),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2OvrigaFriaFonder',
                  'balans1',
                  'credit'
                )
              ),
              undefined,
              ixbrlCell(
                value(0),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2BalanseratResultat',
                  'balans1',
                  'credit'
                )
              ),
              ixbrlCell(
                value(0),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2AretsResultat',
                  'balans1',
                  'credit'
                )
              ),
              ixbrlCell(
                sumRowValues(
                  'managementReport.changesInEquity.table.transitionToK2.13',
                  'k2',
                  true
                ),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2',
                  'balans1',
                  'credit'
                )
              )
            )
            .build()
        )
        // These rows were being displayed for both K2 and K3
        .addRow('main')
        .addSubRows((rows) =>
          rows
            .addRow('content')
            .addSubRows((rows) =>
              rows
                .addRowManagementReport(
                  reportType === 'k2' && !transitionK3toK2
                    ? {
                        id: 'incomingAmount',
                        cells: [
                          value('Belopp vid årets ingång'),
                          ixbrlCell(
                            ref(multiply(-1, account('2081', 'ib'))),
                            changesInEquityIxbrl(
                              'Aktiekapital',
                              'balans1',
                              'credit'
                            )
                          ),
                          ixbrlCell(
                            value(0),
                            changesInEquityIxbrl(
                              'EjRegistreratAktiekapital',
                              'balans1',
                              'credit'
                            )
                          ),
                          undefined,
                          ixbrlCell(
                            value(0),
                            changesInEquityIxbrl(
                              'OverkursfondBunden',
                              'balans1',
                              'credit'
                            )
                          ),
                          ixbrlCell(
                            value(0),
                            changesInEquityIxbrl(
                              'Uppskrivningsfond',
                              'balans1',
                              'credit'
                            )
                          ),
                          ixbrlCell(
                            value(0),
                            changesInEquityIxbrl(
                              'Reservfond',
                              'balans1',
                              'credit'
                            )
                          ),
                          undefined,
                          transitionK3toK2 ? value(0) : undefined,
                          transitionK3toK2 ? value(0) : undefined,
                          ixbrlCell(
                            value(0),
                            changesInEquityIxbrl(
                              'Overkursfond',
                              'balans1',
                              'credit'
                            )
                          ),
                          transitionK3toK2 ? value(0) : undefined,
                          transitionK3toK2 ? value(0) : undefined,
                          undefined,
                          ixbrlCell(
                            ref(
                              ifOrElse(
                                id(
                                  'balanceSheet.section.equityAndLiabilities.equityAndLiabilities.equity.unrestrictedEquity.2.previousYear'
                                ),
                                id(
                                  'balanceSheet.section.equityAndLiabilities.equityAndLiabilities.equity.unrestrictedEquity.2.previousYear'
                                ),
                                '0'
                              )
                            ),
                            changesInEquityIxbrl(
                              'BalanseratResultat',
                              'balans1',
                              'credit'
                            )
                          ),

                          ixbrlCell(
                            ref(or(multiply(-1, account('2099', 'ib')), 0)),
                            changesInEquityIxbrl(
                              'AretsResultatEgetKapital',
                              'balans1',
                              'credit'
                            )
                          ),
                          ixbrlCell(
                            sumRowValues(
                              'managementReport.changesInEquity.table.main.content.incomingAmount',
                              'k2',
                              transitionK3toK2
                            ),
                            changesInEquityIxbrl(
                              'ForandringEgetKapitalTotalt',
                              'balans1',
                              'credit'
                            )
                          ),
                          getWarningCell(
                            `${rows.getBaseId()}.incomingAmount.balanseratResultat`
                          ),
                          getWarningCell(
                            `${rows.getBaseId()}.incomingAmount.yearResult`
                          ),
                        ],
                        isRowActive: true,
                      }
                    : undefined
                )
                .addRowManagementReport({
                  id: 'dividendToShareHolders',
                  cells: [
                    value('Utdelning till aktieägare'),
                    value(0),
                    reportType === 'k2' ? value(0) : undefined,
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    reportType === 'k2' && transitionK3toK2
                      ? value(0)
                      : undefined,
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalOverkursfondUtdelning',
                        undefined,
                        undefined,
                        'Minskning av fri överkursfond vid utdelning',
                        true
                      )
                    ),
                    value(0),
                    reportType === 'k2' && transitionK3toK2
                      ? value(0)
                      : undefined,
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalBalanseratResultatInklusivePeriodensResultatUtdelning'
                      )
                    ),
                    ixbrlCell(
                      ref(or(id('payedDividend'))),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalBalanseratResultatUtdelning',
                        undefined,
                        undefined,
                        'Minskning av balanserat resultat vid utdelning',
                        true
                      )
                    ),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalAretsResultatUtdelning',
                        undefined,
                        undefined,
                        'Minskning av periodens resultat vid utdelning',
                        true
                      )
                    ),
                    ixbrlCell(
                      sumRowValues(
                        'managementReport.changesInEquity.table.main.content.dividendToShareHolders',
                        reportType,
                        transitionK3toK2
                      ),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalTotaltUtdelning',
                        undefined,
                        undefined,
                        'Minskning av eget kapital vid utdelning',
                        true
                      )
                    ),
                  ],
                  isRowActive: true,
                })
                .addRowManagementReport({
                  id: 'shareholderContributions',
                  cells: [
                    value('Erhållna aktieägartillskott'),
                    value(0),
                    reportType === 'k2' ? value(0) : undefined,
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    reportType === 'k2' && transitionK3toK2
                      ? value(0)
                      : undefined,
                    value(0),
                    value(0),
                    reportType === 'k2' && transitionK3toK2
                      ? value(0)
                      : undefined,
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalBalanseratResultatInklusivePeriodensResultatErhallnaAktieagartillskott'
                      )
                    ),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalBalanseratResultatErhallnaAktieagartillskott',
                        undefined,
                        undefined,
                        'Ökning av balanserat resultat vid erhållna aktieägartillskott'
                      )
                    ),
                    value(0),
                    ixbrlCell(
                      sumRowValues(
                        'managementReport.changesInEquity.table.main.content.shareholderContributions',
                        reportType,
                        transitionK3toK2
                      ),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalTotaltErhallnaAktieagartillskott',
                        undefined,
                        undefined,
                        'Ökning av eget kapital vid erhållna aktieägartillskott'
                      )
                    ),
                  ],
                  isRowActive: false,
                })
                .addRowManagementReport({
                  id: 'refundShareholders',
                  cells: [
                    value('Återbetalning av aktieägartillskott'),
                    value(0),
                    reportType === 'k2' ? value(0) : undefined,
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    reportType === 'k2' && transitionK3toK2
                      ? value(0)
                      : undefined,
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalOverkursfondAterbetalningAktieagartillskott',
                        undefined,
                        undefined,
                        'Minskning av fri överkursfond vid återbetalning av aktieägartillskott',
                        true
                      )
                    ),
                    value(0),
                    reportType === 'k2' && transitionK3toK2
                      ? value(0)
                      : undefined,
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalBalanseratResultatInklusivePeriodensResultatAterbetalningAktieagartillskott'
                      )
                    ),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalBalanseratResultatAterbetalningAktieagartillskott',
                        undefined,
                        undefined,
                        'Minskning av balanserat resultat vid återbetalning av aktieägartillskott',
                        true
                      )
                    ),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalAretsResultatAterbetalningAktieagartillskott',
                        undefined,
                        undefined,
                        'Minskning av periodens resultat vid återbetalning av aktieägartillskott',
                        true
                      )
                    ),
                    ixbrlCell(
                      sumRowValues(
                        'managementReport.changesInEquity.table.main.content.refundShareholders',
                        reportType,
                        transitionK3toK2
                      ),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalTotaltAterbetalningAktieagartillskott',
                        undefined,
                        undefined,
                        'Minskning av eget kapital vid återbetalning av aktieägartillskott',
                        true
                      )
                    ),
                  ],
                  isRowActive: false,
                })
                .addRowManagementReport({
                  id: 'balanserasINyRakning',
                  cells: [
                    value('Balanseras i ny räkning'),
                    value(0),
                    reportType === 'k2' ? value(0) : undefined,
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    reportType === 'k2' && transitionK3toK2
                      ? value(0)
                      : undefined,
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalOverkursfondBalanserasNyRakning',
                        undefined,
                        undefined,
                        'Minskning av fri överkursfond vid balansering i ny räkning',
                        true
                      )
                    ),
                    value(0),
                    reportType === 'k2' && transitionK3toK2
                      ? value(0)
                      : undefined,
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalBalanseratResultatInklusivePeriodensResultatBalanserasNyRakning'
                      )
                    ),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalBalanseratResultatBalanserasNyRakning',
                        undefined,
                        undefined,
                        'Ökning (minskning) av balanserat resultat vid balansering i ny räkning'
                      )
                    ),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalAretsResultatBalanserasNyRakning',
                        undefined,
                        undefined,
                        'Ökning (minskning) av periodens resultat vid balansering i ny räkning'
                      )
                    ),
                    ixbrlCell(
                      sumRowValues(
                        'managementReport.changesInEquity.table.main.content.balanserasINyRakning',
                        reportType,
                        transitionK3toK2
                      ),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalTotaltBalanserasNyRakning'
                      )
                    ),
                  ],
                  isRowActive: false,
                })
                .addRowManagementReport({
                  id: 'forandringAvReservfond',
                  cells: [
                    value('Förändring av reservfond'),
                    value(0),
                    reportType === 'k2' ? value(0) : undefined,
                    value(0),
                    value(0),
                    value(0),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalReservfondForandringReservfond',
                        undefined,
                        undefined,
                        'Ökning (minskning) av reservfond'
                      )
                    ),
                    value(0),
                    value(0),
                    reportType === 'k2' && transitionK3toK2
                      ? value(0)
                      : undefined,
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalOverkursfondForandringReservfond',
                        undefined,
                        undefined,
                        'Minskning av fri överkursfond vid förändring av reservfond',
                        true
                      )
                    ),
                    value(0),
                    reportType === 'k2' && transitionK3toK2
                      ? value(0)
                      : undefined,
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalBalanseratResultatInklusivePeriodensResultatForandringReservfond'
                      )
                    ),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalBalanseratResultatForandringReservfond',
                        undefined,
                        undefined,
                        'Ökning (minskning) av balanserat resultat vid förändring av reservfond'
                      )
                    ),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalAretsResultatReservfond',
                        undefined,
                        undefined,
                        'Ökning (minskning) av periodens resultat vid förändring av reservfond'
                      )
                    ),
                    ixbrlCell(
                      sumRowValues(
                        'managementReport.changesInEquity.table.main.content.forandringAvReservfond',
                        reportType,
                        transitionK3toK2
                      ),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalTotaltForandringReservfond'
                      )
                    ),
                  ],
                  isRowActive: false,
                })
                .addRowManagementReport({
                  id: 'tackningAvForlust',
                  cells: [
                    value('Täckning av förlust'),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalAktiekapitalTackningForlust',
                        undefined,
                        undefined,
                        'Minskning av aktiekapitalet vid täckning av förlust',
                        true
                      )
                    ),
                    reportType === 'k2' ? value(0) : undefined,
                    value(0),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalBundenOverkursfondTackningForlust',
                        undefined,
                        undefined,
                        'Minskning av bunden överkursfond vid täckning av förlust',
                        true
                      )
                    ),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalUppskrivningsfondTackningForlust',
                        undefined,
                        undefined,
                        'Minskning av uppskrivningsfond vid täckning av förlust',
                        true
                      )
                    ),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalReservfondTackningForlust',
                        undefined,
                        undefined,
                        'Minskning av reservfond vid täckning av förlust',
                        true
                      )
                    ),
                    value(0),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalFondUtvecklingsutgifterTackningForlust'
                      )
                    ),
                    reportType === 'k2' && transitionK3toK2
                      ? value(0)
                      : undefined,
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalOverkursfondTackningForlust',
                        undefined,
                        undefined,
                        'Minskning av fri överkursfond vid täckning av förlust',
                        true
                      )
                    ),
                    value(0),
                    reportType === 'k2' && transitionK3toK2
                      ? value(0)
                      : undefined,
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalBalanseratResultatInklusivePeriodensResultatTackningForlust'
                      )
                    ),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalBalanseratResultatTackningForlust',
                        undefined,
                        undefined,
                        'Ökning av balanserat resultat vid täckning av förlust'
                      )
                    ),
                    value(0),
                    ixbrlCell(
                      sumRowValues(
                        'managementReport.changesInEquity.table.main.content.tackningAvForlust',
                        reportType,
                        transitionK3toK2
                      ),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalTotaltTackningForlust'
                      )
                    ),
                  ],
                  isRowActive: false,
                })
                .addRowManagementReport({
                  id: 'fundEmission',
                  cells: [
                    value('Fondemission'),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalAktiekapitalFondemission',
                        undefined,
                        undefined,
                        'Ökning av aktiekapital vid fondemission'
                      )
                    ),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalEjRegistreratAktiekapitalFondemission',
                        undefined,
                        undefined,
                        'Ökning (minskning) av ej registrerat aktiekapital vid fondemission'
                      )
                    ),
                    undefined,
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalBundenOverkursfondFondemission',
                        undefined,
                        undefined,
                        'Minskning av bunden överkursfond vid fondemission',
                        true
                      )
                    ),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitaUppskrivningsfondFondemission',
                        undefined,
                        undefined,
                        'Minskning av uppskrivningsfond vid fondemission',
                        true
                      )
                    ),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalReservfondFondemission',
                        undefined,
                        undefined,
                        'Minskning av reservfond vid fondemission',
                        true
                      )
                    ),
                    value(0),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalFondUtvecklingsutgifterFondemission'
                      )
                    ),
                    reportType === 'k2' && transitionK3toK2
                      ? value(0)
                      : undefined,
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalOverkursfondFondemission',
                        undefined,
                        undefined,
                        'Minskning av fri överkursfond vid fondemission',
                        true
                      )
                    ),
                    value(0),
                    reportType === 'k2' && transitionK3toK2
                      ? value(0)
                      : undefined,
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalBalanseratResultatInklusivePeriodensResultatFondemission'
                      )
                    ),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalBalanseratResultatFondemission',
                        undefined,
                        undefined,
                        'Minskning av balanserat resultat vid fondemission',
                        true
                      )
                    ),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalAretsResultatFondemission',
                        undefined,
                        undefined,
                        'Minskning av periodens resultat vid fondemission',
                        true
                      )
                    ),
                    ixbrlCell(
                      sumRowValues(
                        'managementReport.changesInEquity.table.main.content.fundEmission',
                        reportType,
                        transitionK3toK2
                      ),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalTotaltFondemission'
                      )
                    ),
                  ],
                  isRowActive: false,
                })
                .addRowManagementReport({
                  id: 'newSplit',
                  cells: [
                    value('Nyemission'),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalAktiekapitalNyemission',
                        undefined,
                        undefined,
                        'Ökning av aktiekapital vid nyemission'
                      )
                    ),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalEjRegistreratAktiekapitalNyemission',
                        undefined,
                        undefined,
                        'Ökning (minskning) av ej registrerat aktiekapital vid nyemission'
                      )
                    ),
                    undefined,
                    ixbrlCell(
                      ref(or(multiply(-1, account('2087', 'change')), 0)),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalBundenOverkursfondNyemission',
                        undefined,
                        undefined,
                        'Ökning av bunden överkursfond vid nyemission'
                      )
                    ),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    reportType === 'k2' && transitionK3toK2
                      ? value(0)
                      : undefined,
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalOverkursfondNyemission',
                        undefined,
                        undefined,
                        'Ökning av fri överkursfond vid nyemission'
                      )
                    ),
                    value(0),
                    reportType === 'k2' && transitionK3toK2
                      ? value(0)
                      : undefined,
                    value(0),
                    value(0),
                    value(0),
                    ixbrlCell(
                      sumRowValues(
                        'managementReport.changesInEquity.table.main.content.newSplit',
                        reportType,
                        transitionK3toK2
                      ),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalTotaltNyemission',
                        undefined,
                        undefined,
                        'Ökning av eller förändring inom eget kapital vid nyemission'
                      )
                    ),
                  ],
                  isRowActive: false,
                })
                .addRowManagementReport({
                  id: 'minskningAvAktiekaptial',
                  cells: [
                    value('Minskning av aktiekapital'),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalAktiekapitalOverforingFrittEgetKapitalAterbetalningAktieagare',
                        undefined,
                        undefined,
                        'Minskning av aktiekapital vid överföring till fritt eget kapital eller återbetalning till aktieägarna',
                        true
                      )
                    ),
                    reportType === 'k2' ? value(0) : undefined,
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    reportType === 'k2' && transitionK3toK2
                      ? value(0)
                      : undefined,
                    value(0),
                    value(0),
                    reportType === 'k2' && transitionK3toK2
                      ? value(0)
                      : undefined,
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalBalanseratResultatInklusivePeriodensResultatMinskningAktiekapital'
                      )
                    ),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalBalanseratResultatMinskningAktiekapital',
                        undefined,
                        undefined,
                        'Ökning av balanserat resultat vid minskat aktiekapital'
                      )
                    ),
                    value(0),
                    ixbrlCell(
                      sumRowValues(
                        'managementReport.changesInEquity.table.main.content.minskningAvAktiekaptial',
                        reportType,
                        transitionK3toK2
                      ),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalTotaltMinskningAktiekapital'
                      )
                    ),
                  ],
                  isRowActive: false,
                })
                .addRowManagementReport({
                  id: 'minskningAvBundenOverkursfond',
                  cells: [
                    value('Minskning av bunden överkursfond'),
                    value(0),
                    reportType === 'k2' ? value(0) : undefined,
                    value(0),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalBundenOverkursfondMinskningBundenOverskursfond',
                        undefined,
                        undefined,
                        'Minskning av bunden överkursfond',
                        true
                      )
                    ),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    reportType === 'k2' && transitionK3toK2
                      ? value(0)
                      : undefined,
                    value(0),
                    value(0),
                    reportType === 'k2' && transitionK3toK2
                      ? value(0)
                      : undefined,
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalBalanseratResultatInklusivePeriodensResultatMinskningBundenOverkursfond'
                      )
                    ),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalBalanseratResultatMinskningBundenOverskursfond',
                        undefined,
                        undefined,
                        'Ökning av balanserat resultat vid minskning av bunden överkursfond'
                      )
                    ),
                    value(0),
                    ixbrlCell(
                      sumRowValues(
                        'managementReport.changesInEquity.table.main.content.minskningAvBundenOverkursfond',
                        reportType,
                        transitionK3toK2
                      ),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalTotaltMinskningBundenOverkursfond'
                      )
                    ),
                  ],
                  isRowActive: false,
                })
                .addRowManagementReport({
                  id: 'forvarvAvEgnaAktier',
                  cells: [
                    value('Förvärv av egna aktier'),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalBalanseratResultatEgnaAktier',
                        undefined,
                        undefined,
                        'Minskning av balanserat resultat vid förvärv av egna aktier',
                        true
                      )
                    ),
                    value(0),
                    ixbrlCell(
                      sumRowValues(
                        'managementReport.changesInEquity.table.main.content.forvarvAvEgnaAktier',
                        'k2',
                        transitionK3toK2
                      ),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalTotaltForandringEgnaAktier',
                        undefined,
                        undefined,
                        'Minskning av eget kapital vid förvärv av egna aktier',
                        true
                      )
                    ),
                  ],
                  isRowActive: false,
                })
                .addRowManagementReport({
                  id: 'overlatelseAvEgnaAktier',
                  cells: [
                    value('Överlåtelse av egna aktier'),
                    value(0),
                    reportType === 'k2' ? value(0) : undefined,
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    reportType === 'k2' && transitionK3toK2
                      ? value(0)
                      : undefined,
                    value(0),
                    value(0),
                    reportType === 'k2' && transitionK3toK2
                      ? value(0)
                      : undefined,
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalBalanseratResultatInklusivePeriodensResultatOverlatelseEgnaAktier'
                      )
                    ),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalBalanseratResultatOverlatelseEgnaAktier',
                        undefined,
                        undefined,
                        'Ökning av balanserat resultat vid överlåtelse av egna aktier'
                      )
                    ),
                    value(0),
                    ixbrlCell(
                      sumRowValues(
                        'managementReport.changesInEquity.table.main.content.overlatelseAvEgnaAktier',
                        reportType,
                        transitionK3toK2
                      ),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalTotaltOverlatelseEgnaAktier',
                        undefined,
                        undefined,
                        'Ökning av eget kapital vid överlåtelse av egna aktier'
                      )
                    ),
                  ],
                  isRowActive: false,
                })
                .addRowManagementReport({
                  id: 'uppskrivningAvAnlaggningstillgång',
                  cells: [
                    value('Uppskrivning av anläggningstillgång'),
                    value(0),
                    reportType === 'k2' ? value(0) : undefined,
                    value(0),
                    value(0),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalUppskrivningsfondUppskrivningAnlaggningstillgang',
                        undefined,
                        undefined,
                        'Ökning av uppskrivningsfond vid uppskrivning av anläggningstillgång'
                      )
                    ),
                    value(0),
                    value(0),
                    value(0),
                    reportType === 'k2' && transitionK3toK2
                      ? value(0)
                      : undefined,
                    value(0),
                    value(0),
                    reportType === 'k2' && transitionK3toK2
                      ? value(0)
                      : undefined,
                    value(0),
                    value(0),
                    value(0),
                    ixbrlCell(
                      sumRowValues(
                        'managementReport.changesInEquity.table.main.content.uppskrivningAvAnlaggningstillgång',
                        reportType,
                        transitionK3toK2
                      ),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalTotaltUppskrivningAnlaggningstillgang',
                        undefined,
                        undefined,
                        'Ökning av eget kapital vid uppskrivning av anläggningstillgång'
                      )
                    ),
                  ],
                  isRowActive: false,
                })
                .addRowManagementReport({
                  id: 'upplosningAvUppskrivningsfond',
                  cells: [
                    value('Upplösning av uppskrivningsfond'),
                    value(0),
                    reportType === 'k2' ? value(0) : undefined,
                    value(0),
                    value(0),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalUppskrivningsfondUpplosningUppskrivningsfond',
                        undefined,
                        undefined,
                        'Minskning av uppskrivningsfond vid upplösning',
                        true
                      )
                    ),
                    value(0),
                    value(0),
                    value(0),
                    reportType === 'k2' && transitionK3toK2
                      ? value(0)
                      : undefined,
                    value(0),
                    value(0),
                    reportType === 'k2' && transitionK3toK2
                      ? value(0)
                      : undefined,
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalBalanseratResultatInklusivePeriodensResultatUpplosningUppskrivningsfond'
                      )
                    ),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalBalanseratResultatUpplosningUppskrivningsfond',
                        undefined,
                        undefined,
                        'Ökning av balanserat resultat vid upplösning av uppskrivningsfond'
                      )
                    ),
                    value(0),
                    ixbrlCell(
                      sumRowValues(
                        'managementReport.changesInEquity.table.main.content.upplosningAvUppskrivningsfond',
                        reportType,
                        transitionK3toK2
                      ),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalTotaltUpplosningUppskrivningsfond'
                      )
                    ),
                  ],
                  isRowActive: false,
                })
                .addRowManagementReport({
                  // AGOY-3783: did not fix typo in id below to not risk losing users data
                  id: 'fussionsdifferens',
                  cells: [
                    value('Fusionsdifferens'),
                    value(0),
                    reportType === 'k2' ? value(0) : undefined,
                    value(0),
                    value(0),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalUppskrivningsfondFusionsdifferens',
                        undefined,
                        undefined,
                        'Ökning av uppskrivningsfond vid fusionsdifferens'
                      )
                    ),
                    value(0),
                    value(0),
                    value(0),
                    reportType === 'k2' && transitionK3toK2
                      ? value(0)
                      : undefined,
                    value(0),
                    value(0),
                    reportType === 'k2' && transitionK3toK2
                      ? value(0)
                      : undefined,
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalBalanseratResultatInklusivePeriodensResultatFusionsdifferens'
                      )
                    ),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalBalanseratResultatFusionsdifferens',
                        undefined,
                        undefined,
                        'Ökning (minskning) av balanserat resultat vid fusionsdifferens'
                      )
                    ),
                    value(0),
                    ixbrlCell(
                      sumRowValues(
                        'managementReport.changesInEquity.table.main.content.fussionsdifferens',
                        reportType,
                        transitionK3toK2
                      ),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalTotaltFusionsdifferens',
                        undefined,
                        undefined,
                        'Ökning (minskning) av eget kapital vid fusionsdifferens'
                      )
                    ),
                  ],
                  isRowActive: false,
                })
                .addRowManagementReport({
                  id: 'yearResult',
                  cells: [
                    value('Årets resultat'),
                    value(0),
                    reportType === 'k2' ? value(0) : undefined,
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    reportType === 'k2' && transitionK3toK2
                      ? value(0)
                      : undefined,
                    value(0),
                    value(0),
                    reportType === 'k2' && transitionK3toK2
                      ? value(0)
                      : undefined,
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalBalanseratResultatInklusivePeriodensResultatAretsResultat'
                      )
                    ),
                    value(0),
                    ixbrlCell(
                      ref(multiply(-1, account('2099'))),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalAretsResultatAretsResultat',
                        undefined,
                        undefined,
                        'Ökning (minskning) av periodens resultat till följd av periodens resultat'
                      )
                    ),
                    ixbrlCell(
                      sumRowValues(
                        'managementReport.changesInEquity.table.main.content.yearResult',
                        reportType,
                        transitionK3toK2
                      ),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalTotaltAretsResultat',
                        undefined,
                        undefined,
                        'Ökning (minskning) av eget kapital till följd av periodens resultat'
                      )
                    ),
                    getWarningCell(
                      `${rows.getBaseId()}.yearResult.balanseratResultat`
                    ),
                    getWarningCell(`${rows.getBaseId()}.yearResult.yearResult`),
                  ],
                  isRowActive: true,
                })
                .build()
            )
            // final row, sums up each column in the case of K2
            .addRowManagementReport(
              reportType === 'k2'
                ? {
                    id: 'sumByYearEnd',
                    cells: [
                      value('Belopp vid årets utgång'),
                      ixbrlCell(
                        ref(
                          sum(
                            id(
                              `managementReport.changesInEquity.table.main.content.*.shareCapital`
                            ),
                            id(
                              `managementReport.changesInEquity.table.transitionToK2.*.shareCapital`
                            )
                          )
                        ),
                        changesInEquityIxbrl(
                          'Aktiekapital',
                          'balans0',
                          'credit'
                        )
                      ),
                      ixbrlCell(
                        ref(
                          sum(
                            id(
                              `managementReport.changesInEquity.table.main.content.*.ejRegistreratAktiekapital`
                            ),
                            id(
                              `managementReport.changesInEquity.table.transitionToK2.*.ejRegistreratAktiekapital`
                            )
                          )
                        ),
                        changesInEquityIxbrl(
                          'EjRegistreratAktiekapital',
                          'balans0',
                          'credit'
                        )
                      ),
                      undefined,
                      ixbrlCell(
                        ref(
                          sum(
                            id(
                              `managementReport.changesInEquity.table.main.content.*.bundenOverkursfond`
                            ),
                            id(
                              `managementReport.changesInEquity.table.transitionToK2.*.bundenOverkursfond`
                            )
                          )
                        ),
                        changesInEquityIxbrl(
                          'OverkursfondBunden',
                          'balans0',
                          'credit'
                        )
                      ),
                      ixbrlCell(
                        ref(
                          sum(
                            id(
                              `managementReport.changesInEquity.table.main.content.*.uppskrivingsFond`
                            ),
                            id(
                              `managementReport.changesInEquity.table.transitionToK2.*.uppskrivingsFond`
                            )
                          )
                        ),
                        changesInEquityIxbrl(
                          'Uppskrivningsfond',
                          'balans0',
                          'credit'
                        )
                      ),
                      ixbrlCell(
                        ref(
                          sum(
                            id(
                              `managementReport.changesInEquity.table.main.content.*.reservFond`
                            ),
                            id(
                              `managementReport.changesInEquity.table.transitionToK2.*.reservFond`
                            )
                          )
                        ),
                        changesInEquityIxbrl('Reservfond', 'balans0', 'credit')
                      ),
                      undefined,
                      transitionK3toK2
                        ? ref(
                            sum(
                              id(
                                `managementReport.changesInEquity.table.main.content.*.fondForUtvecklingsutgifter`
                              ),
                              id(
                                `managementReport.changesInEquity.table.transitionToK2.*.fondForUtvecklingsutgifter`
                              )
                            )
                          )
                        : undefined,
                      transitionK3toK2
                        ? ref(
                            sum(
                              id(
                                `managementReport.changesInEquity.table.main.content.*.ovrigaBundnaFonder`
                              ),
                              id(
                                `managementReport.changesInEquity.table.transitionToK2.*.ovrigaBundnaFonder`
                              )
                            )
                          )
                        : undefined,
                      ixbrlCell(
                        ref(
                          sum(
                            id(
                              `managementReport.changesInEquity.table.main.content.*.friOverkursfond`
                            ),
                            id(
                              `managementReport.changesInEquity.table.transitionToK2.*.friOverkursfond`
                            )
                          )
                        ),
                        changesInEquityIxbrl(
                          'Overkursfond',
                          'balans0',
                          'credit'
                        )
                      ),
                      transitionK3toK2
                        ? ref(
                            sum(
                              id(
                                `managementReport.changesInEquity.table.main.content.*.fondForVerkligtVarde`
                              ),
                              id(
                                `managementReport.changesInEquity.table.transitionToK2.*.fondForVerkligtVarde`
                              )
                            )
                          )
                        : undefined,
                      transitionK3toK2
                        ? ref(
                            sum(
                              id(
                                `managementReport.changesInEquity.table.main.content.*.ovrigaFriaFonder`
                              ),
                              id(
                                `managementReport.changesInEquity.table.transitionToK2.*.ovrigaFriaFonder`
                              )
                            )
                          )
                        : undefined,
                      undefined,
                      ixbrlCell(
                        ref(
                          sum(
                            id(
                              `managementReport.changesInEquity.table.main.content.*.balanseratResultat`
                            ),
                            id(
                              `managementReport.changesInEquity.table.transitionToK2.*.balanseratResultat`
                            )
                          )
                        ),
                        changesInEquityIxbrl(
                          'BalanseratResultat',
                          'balans0',
                          'credit'
                        )
                      ),
                      ixbrlCell(
                        ref(
                          sum(
                            id(
                              `managementReport.changesInEquity.table.main.content.*.yearResult`
                            ),
                            id(
                              `managementReport.changesInEquity.table.transitionToK2.*.yearResult`
                            )
                          )
                        ),
                        changesInEquityIxbrl(
                          'AretsResultatEgetKapital',
                          'balans0',
                          'credit'
                        )
                      ),
                      ixbrlCell(
                        ref(
                          sum(
                            id(
                              `managementReport.changesInEquity.table.main.content.*.total`
                            ),
                            id(
                              `managementReport.changesInEquity.table.transitionToK2.*.total`
                            )
                          )
                        ),
                        changesInEquityIxbrl(
                          'ForandringEgetKapitalTotalt',
                          'balans0',
                          'credit'
                        )
                      ),
                    ],
                    isRowActive: true,
                  }
                : undefined
            )
            .build()
        )
        .build()
    )
    .build();
};

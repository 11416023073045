import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { endOfQuarter, getMonth, parse, startOfMonth } from 'date-fns';
import { LockOutlined } from '@material-ui/icons';
import { format } from '@agoy/dates';

import {
  ClientCompanyType,
  ClosingMonthType,
  Lamps,
} from '_clients/types/types';
import { PeriodStatusesType } from './utils';
import LampItem from './LampItem';

const Wrapper = styled.div<{ template: string }>`
  width: 100%;
  display: grid;
  grid-template-columns: ${({ template }) => template};
  grid-column-gap: ${({ theme }) => theme.spacing(1)}px;
`;

const Periods = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(1)}px;
`;

const LockedPeriods = styled(Periods)`
  background-color: #dde1e0;
  border-radius: 8px;
  padding-right: 40px;
  position: relative;
`;

const LockIcon = styled(LockOutlined)`
  position: absolute;
  right: ${({ theme }) => theme.spacing(1)}px;
  font-size: 24px;
  color: ${({ theme }) => theme.palette.grey[500]};
`;

type PeriodObject = {
  date: string;
  hasData: boolean;
};

type LampRowProps = {
  expanded: boolean;
  lamps: {
    [index: string]: Lamps;
  };
  periods: PeriodObject[];
  programStatuses: { [key: string]: PeriodStatusesType };
  closingMonth: ClosingMonthType | null;
  closingPeriod: ClientCompanyType['closingPeriod'];
  financialYears: ClientCompanyType['financialYears'];
};

const LampRow = ({
  expanded,
  lamps = {},
  periods,
  programStatuses,
  closingMonth,
  closingPeriod,
  financialYears,
}: LampRowProps) => {
  const sortedPeriods = useMemo(() => {
    const result: PeriodObject[][] = [];

    let lastStatus = '';

    periods.forEach((period) => {
      const status = programStatuses[period.date].RECONCILIATION;

      if (
        !result.length ||
        (status !== lastStatus &&
          (status === 'LOCKED' || lastStatus === 'LOCKED'))
      ) {
        result.push([period]);
      } else {
        result[result.length - 1].push(period);
      }

      lastStatus = status;
    });

    return result;
  }, [periods, programStatuses]);

  const wrapperTemplate = useMemo(() => {
    let result = '';

    sortedPeriods.forEach((item) => {
      result += `${item.length}fr `;
    });

    return result;
  }, [sortedPeriods]);

  const renderItem = (period: PeriodObject) => {
    const periodStart = period.date;
    const parsedPeriodStart = parse(periodStart, 'yyyyMMdd', Date.now());

    let lampPeriod = periodStart;

    if (closingPeriod === 'quarter') {
      lampPeriod = format(
        startOfMonth(endOfQuarter(parsedPeriodStart)),
        'yyyyMMdd'
      );
    } else if (closingPeriod === 'year') {
      // Find a matching financial year
      const financialYear = financialYears.find((fy) => {
        const [start, end] = fy.split('-');
        return periodStart >= start && periodStart <= end;
      });
      if (financialYear) {
        const financialYearEnd = parse(
          financialYear.substring(9),
          'yyyyMMdd',
          Date.now()
        );
        lampPeriod = format(startOfMonth(financialYearEnd), 'yyyyMMdd');
      }
    }
    const isClosingPeriod = getMonth(parsedPeriodStart) + 1 === closingMonth;

    return (
      <LampItem
        period={period}
        periodLamp={lamps[lampPeriod]}
        programStatus={programStatuses[periodStart]}
        isClosingPeriod={isClosingPeriod}
        expanded={expanded}
        key={period.date}
      />
    );
  };

  return (
    <Wrapper template={wrapperTemplate}>
      {sortedPeriods.map((item, index) => {
        const status = programStatuses[item[0].date].RECONCILIATION;

        if (status === 'LOCKED') {
          return (
            <LockedPeriods key={index}>
              {item.map((period) => renderItem(period))}
              <LockIcon />
            </LockedPeriods>
          );
        }

        return (
          <Periods key={index}>
            {item.map((period) => renderItem(period))}
          </Periods>
        );
      })}
    </Wrapper>
  );
};

export default LampRow;

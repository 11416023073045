type EditingRule = '+' | '-';

const rules: Record<string, EditingRule> = {
  chosenSentGroupContributions: '-',
  chosenReceivedGroupContributions: '+',
  chosenIncreaseOfOverdepreciation: '-',
  chosenDecreaseOfOverdepreciation: '+',
  chosenReversalOfAccrualFund: '+',
  chosenDepositionToAccrualFund: '-',
};

export const getEditingRule = (id: string): EditingRule | undefined =>
  rules[id];

import React from 'react';
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import PersonalNumber from '_shared/components/PersonalNumber';

const NameTableCIDiff = ({
  rows,
  roles,
}: {
  rows: Record<string, string | boolean>[];
  roles: Record<string, string>;
}): JSX.Element => {
  return (
    <Table size="small">
      <TableBody>
        {rows.map((row, id) => (
          // eslint-disable-next-line react/no-array-index-key
          <TableRow key={id}>
            <TableCell>{row.name}</TableCell>
            <TableCell>
              {typeof row.personNr === 'string' && (
                <PersonalNumber
                  value={row.personNr}
                  style={{ fontSize: '0.875rem' }}
                />
              )}
            </TableCell>
            <TableCell>
              {typeof row.role === 'string' && roles[row.role]
                ? roles[row.role]
                : ''}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default NameTableCIDiff;

/**
 * Wrapper for a linked item.
 */
export type LinkedNode<T> = {
  /**
   * Wrapped item
   */
  value: T;

  /**
   * Previous node, null if it doesn't exist
   */
  prev: LinkedNode<T> | null;

  /**
   * Next node, null if it doesn't exist
   */
  next: LinkedNode<T> | null;
};

/**
 * Wraps a list of items with links between them for
 * next and previous.
 *
 * @param items Items
 * @returns A list of wrapped item with links to
 * next and previous nodes.
 */
export const createLinkedNodes = <T>(items: T[]): LinkedNode<T>[] => {
  return items
    .map((value) => ({ value, prev: null, next: null }))
    .map((node: LinkedNode<T>, index, nodes) => {
      node.prev = index > 0 ? nodes[index - 1] : null;
      node.next = index < nodes.length - 1 ? nodes[index + 1] : null;
      return node;
    });
};

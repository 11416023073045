import React, { useState, useMemo, useEffect } from 'react';
import styled from '@emotion/styled';
import { Redirect } from 'react-router-dom';

// @ts-ignore
import { ReactComponent as Logo } from 'assets/agoy_login.svg';
import LoginBackground from 'assets/login-background.jpg';
import useAuth from '_authentication/hooks/useAuth';
import useQuery from '_authentication/hooks/useQuery';

import LoginContent from './LoginContent';
import ForgotContent from './ForgotContent';
import ResetPasswordContent from './ResetPasswordContent';
import WarningMessage from './WarningMessage';

const AgoyLogo = styled(Logo)`
  position: absolute;
  top: 32px;
  left: 80px;
  width: 160px;
  color: ${(props) => props.theme.palette.secondary.main};
`;

const Wrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  height: 100vh;
`;

const AuthorizationWrapper = styled.div`
  background-color: #fff;
  width: 50vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 60px;
`;

const AuthorizationImageContainer = styled.div`
  display: flex;
  background: url(${LoginBackground});
  background-size: cover;
  width: 50vw;
`;

const AdContainer = styled.div`
  margin: auto;
  border-radius: 4px;
  background-color: rgba(0, 49, 60, 0.6);
  padding: 1.5rem;
  color: white;
  max-width: 685px;
  & > h2 {
    font-size: 2.5rem;
    margin: 0;
    color: #00ce7c;
  }
  & > p:last-of-type {
    margin-bottom: 0;
  }
  & a {
    color: #00ce7c;
  }
`;

type LoginViewProps = {
  location: {
    pathname: string;
    state: {
      reset?: boolean;
      email?: string;
    };
  };
  history: {
    push: (path: string) => void;
  };
};

const LoginView = ({ location, history }: LoginViewProps) => {
  const auth = useAuth();

  const [openView, setOpenView] = useState<'login' | 'forgot' | 'reset'>(
    'login'
  );

  const openLogin = () => setOpenView('login');
  const openForgot = () => setOpenView('forgot');
  const openReset = () => setOpenView('reset');
  const openCreate = () => history.push('register');
  const emailQuery = useQuery().get('email');

  const currentView = useMemo(() => {
    switch (openView) {
      case 'reset':
        return <ResetPasswordContent backToLogin={openLogin} />;
      case 'forgot':
        return (
          <ForgotContent
            backToLogin={openLogin}
            goToResetPassword={openReset}
            queryEmail={location.state?.email}
          />
        );
      case 'login':
        return (
          <LoginContent
            createUserCallback={openCreate}
            forgotPasswordCallback={openForgot}
            email={emailQuery || undefined}
          />
        );
      default: {
        break;
      }
    }
    return null;
  }, [openView]);

  useEffect(() => {
    // Fetch current session
    auth.getCurrentSessionAWS();

    // If reset is set in state, redirect to reset view
    // We send reset (and the e-mail) from the CreateContent component
    if (location.state && location.state.reset) {
      setOpenView('forgot');
    }
  }, []);

  if (!auth.isLoading && auth.isAuthenticated) {
    return <Redirect to="/" />;
  }

  return (
    <Wrapper>
      <AuthorizationWrapper>
        <AgoyLogo />
        <WarningMessage />
        {currentView}
      </AuthorizationWrapper>
      <AuthorizationImageContainer>
        <AdContainer>
          <h2>Vill du vara med och revolutionera redovisningsvärlden?</h2>
          <p>Välkommen till Agoy!</p>
        </AdContainer>
      </AuthorizationImageContainer>
    </Wrapper>
  );
};

export default LoginView;

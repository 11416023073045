/*
 * This is a GENERATED file, do not change manually!
 * Generated:
 * - from file k3-arsredovisning-2021-10-31-rev20230131 - Noter.csv
 * - Comments: AGOY-6856
 */

import {
  value,
  ref,
  sumAllowNull,
  id,
  AgoyDocumentPart,
} from '@agoy/document';
import { IxbrlCell } from '../../../../../common';
import {
  field,
  ixbrlCell,
  ixbrlField,
  table,
  tupleRef,
} from '../../../../../common/utils/util';
import { previousYear, year } from '../../../../../common/utils/date-util';



const cell = { type: 'field' as const };
const boolean = { type: 'boolean' as const };
const type = { type: 'type' as const };
const number = { type: 'number' as const };

export const getNote2070K3v2 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null
): AgoyDocumentPart<typeof note2070K3v2ContentDefinition> => ({
  active: false,
  number: value(undefined),
  name: noteLabel('Obeskattade reserver'),
  data: {
    active: true,
    PerioderingsfonderSpecifikationTuple: {
      type: 'group',
      active: true,
      level: 0,
      title: field('Specifikation av periodiseringsfonder'),
      table: table<IxbrlCell>(
        'notes.note70.data.PerioderingsfonderSpecifikationTuple.table',
        {
          id: 'PerioderingsfonderSpecifikationFondAr',
          label: 'Periodiseringsfond år',
          dataType: 'text',
          active: true,
        },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) ?? '',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) ?? '',
          dataType: 'numeric',
          active: true,
        }
      )
        .addRows((rows) => {
          rows
            .addRowWithType(
              'PerioderingsfonderSpecifikationTuple',
              'hidden',
              undefined,
              undefined,
              undefined
            )
            .addSubRows((rows) => {
              rows.addRowWithType(
                '1',
                'row',
                ixbrlCell(value('Periodiseringsfond år'), {
                  type: 'stringItemType',
                  name: 'se-gen-base:PerioderingsfonderSpecifikationFondAr',
                  contextRef: 'balans0',
                  standardRubrik: 'Periodiseringsfond per räkenskapsår',
                  negateValue: false,
                  tuple: {
                    name: 'se-gaap-ext:PerioderingsfonderSpecifikationTuple',
                    id: `${rows.getBaseId()}.1.tuple`,
                    tupleID: `${rows.getBaseId()}.1.tuple`,
                  },
                  tupleRef: tupleRef(1, `${rows.getBaseId()}.1.tuple`),
                  previousYearTuple: true,
                }),
                ixbrlCell(value(undefined), {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:PerioderingsfonderSpecifikation',
                  contextRef: 'balans0',
                  standardRubrik: 'Belopp per periodiseringsfond',
                  saldo: 'credit',
                  negateValue: false,
                  tupleRef: tupleRef(2, `${rows.getBaseId()}.1.tuple_balans0`),
                  previousYearTuple: false,
                }),
                ixbrlCell(value(undefined), {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:PerioderingsfonderSpecifikation',
                  contextRef: 'balans1',
                  standardRubrik: 'Belopp per periodiseringsfond',
                  saldo: 'credit',
                  negateValue: false,
                  tupleRef: tupleRef(3, `${rows.getBaseId()}.1.tuple_balans1`),
                  previousYearTuple: false,
                })
              );
              return rows.build();
            });
          rows.newRowTemplateGenerator((_id, baseId) => ({
            id: _id,
            active: true,
            type: 'row',
            cells: {
              PerioderingsfonderSpecifikationFondAr: ixbrlCell(
                value('Periodiseringsfond år'),
                {
                  type: 'stringItemType',
                  name: 'se-gen-base:PerioderingsfonderSpecifikationFondAr',
                  contextRef: 'balans0',
                  standardRubrik: 'Periodiseringsfond per räkenskapsår',
                  negateValue: false,
                  tuple: {
                    name: 'se-gaap-ext:PerioderingsfonderSpecifikationTuple',
                    id: `${baseId}.${_id}.tuple`,
                    tupleID: `${baseId}.${_id}.tuple`,
                  },
                  tupleRef: tupleRef(1, `${baseId}.${_id}.tuple`),
                  previousYearTuple: true,
                }
              ),
              year: ixbrlCell(value(undefined), {
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:PerioderingsfonderSpecifikation',
                contextRef: 'balans0',
                standardRubrik: 'Belopp per periodiseringsfond',
                saldo: 'credit',
                negateValue: false,
                tupleRef: tupleRef(2, `${baseId}.${_id}.tuple_balans0`),
                previousYearTuple: false,
              }),
              previousYear: ixbrlCell(value(undefined), {
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:PerioderingsfonderSpecifikation',
                contextRef: 'balans1',
                standardRubrik: 'Belopp per periodiseringsfond',
                saldo: 'credit',
                negateValue: false,
                tupleRef: tupleRef(3, `${baseId}.${_id}.tuple_balans1`),
                previousYearTuple: false,
              }),
            },
          }));
          rows.addRowWithType(
            'Periodiseringsfonder',
            'sum',
            noteLabel('Summa'),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note70.data.PerioderingsfonderSpecifikationTuple.table.PerioderingsfonderSpecifikationTuple.*.year'
                  )
                )
              ),
              {
                contextRef: 'balans0',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:Periodiseringsfonder',
                standardRubrik: 'Periodiseringsfonder',
                saldo: 'credit',
                negateValue: false,
              }
            ),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note70.data.PerioderingsfonderSpecifikationTuple.table.PerioderingsfonderSpecifikationTuple.*.previousYear'
                  )
                )
              ),
              {
                contextRef: 'balans1',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:Periodiseringsfonder',
                standardRubrik: 'Periodiseringsfonder',
                saldo: 'credit',
                negateValue: false,
              }
            )
          );
          return rows.build();
        })
        .build(),
      ObeskattadeReserverPeriodiseringsfonderKommentar: {
        type: 'group',
        active: true,
        level: 0,
        visible: ref(
          id('notes.note70.data.PerioderingsfonderSpecifikationTuple.active')
        ),
        title: field(
          'Kommentar till obeskattade reserver i form av periodiseringsfonder'
        ),
        value: ixbrlField(field(''), {
          contextRef: 'period0',
          type: 'stringItemType',
          name: 'se-gen-base:ObeskattadeReserverPeriodiseringsfonderKommentar',
          standardRubrik:
            'Kommentar till obeskattade reserver i form av periodiseringsfonder',
        }),
      },
    },
    AckumuleradeOveravskrivningar: {
      type: 'group',
      active: true,
      level: 0,
      title: field('Ackumulerade överavskrivningar'),
      table: table<IxbrlCell>(
        'notes.note70.data.AckumuleradeOveravskrivningar.table',
        {
          id: 'AckumuleradeOveravskrivningar',
          label: '',
          dataType: 'text',
          active: true,
        },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) ?? '',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) ?? '',
          dataType: 'numeric',
          active: true,
        }
      )
        .addRows((rows) => {
          rows.addRowWithType(
            'AckumuleradeOveravskrivningar',
            'row',
            noteLabel('Ackumulerade överavskrivningar'),
            ixbrlCell(value(undefined), {
              contextRef: 'balans0',
              type: 'monetaryItemType',
              unitRef: 'SEK',
              name: 'se-gen-base:AckumuleradeOveravskrivningar',
              standardRubrik: 'Ackumulerade överavskrivningar',
              saldo: 'credit',
              negateValue: false,
            }),
            ixbrlCell(value(undefined), {
              contextRef: 'balans1',
              type: 'monetaryItemType',
              unitRef: 'SEK',
              name: 'se-gen-base:AckumuleradeOveravskrivningar',
              standardRubrik: 'Ackumulerade överavskrivningar',
              saldo: 'credit',
              negateValue: false,
            })
          );
          return rows.build();
        })
        .build(),
    },
    ErsattningsfonderTuple: {
      type: 'group',
      active: true,
      level: 0,
      title: field('Specifikation av ersättningsfonder'),
      table: table<IxbrlCell>(
        'notes.note70.data.ErsattningsfonderTuple.table',
        {
          id: 'ErsattningsfonderTyp',
          label: '',
          dataType: 'text',
          active: true,
        },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) ?? '',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) ?? '',
          dataType: 'numeric',
          active: true,
        }
      )
        .addRows((rows) => {
          rows
            .addRowWithType(
              'ErsattningsfonderTuple',
              'hidden',
              undefined,
              undefined,
              undefined
            )
            .addSubRows((rows) => {
              rows.addRowWithType(
                '1',
                'row',
                ixbrlCell(value('Typ av ersättningsfond'), {
                  type: 'stringItemType',
                  name: 'se-gen-base:ErsattningsfonderTyp',
                  contextRef: 'balans0',
                  standardRubrik: 'Typ av ersättningsfond',
                  negateValue: false,
                  tuple: {
                    name: 'se-gaap-ext:ErsattningsfonderTuple',
                    id: `${rows.getBaseId()}.1.tuple`,
                    tupleID: `${rows.getBaseId()}.1.tuple`,
                  },
                  tupleRef: tupleRef(1, `${rows.getBaseId()}.1.tuple`),
                  previousYearTuple: true,
                }),
                ixbrlCell(value(undefined), {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:ErsattningsfonderPerTyp',
                  contextRef: 'balans0',
                  standardRubrik: 'Belopp per typ av ersättningsfond',
                  saldo: 'credit',
                  negateValue: false,
                  tupleRef: tupleRef(2, `${rows.getBaseId()}.1.tuple_balans0`),
                  previousYearTuple: false,
                }),
                ixbrlCell(value(undefined), {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:ErsattningsfonderPerTyp',
                  contextRef: 'balans1',
                  standardRubrik: 'Belopp per typ av ersättningsfond',
                  saldo: 'credit',
                  negateValue: false,
                  tupleRef: tupleRef(3, `${rows.getBaseId()}.1.tuple_balans1`),
                  previousYearTuple: false,
                })
              );
              return rows.build();
            });
          rows.newRowTemplateGenerator((_id, baseId) => ({
            id: _id,
            active: true,
            type: 'row',
            cells: {
              ErsattningsfonderTyp: ixbrlCell(value('Typ av ersättningsfond'), {
                type: 'stringItemType',
                name: 'se-gen-base:ErsattningsfonderTyp',
                contextRef: 'balans0',
                standardRubrik: 'Typ av ersättningsfond',
                negateValue: false,
                tuple: {
                  name: 'se-gaap-ext:ErsattningsfonderTuple',
                  id: `${baseId}.${_id}.tuple`,
                  tupleID: `${baseId}.${_id}.tuple`,
                },
                tupleRef: tupleRef(1, `${baseId}.${_id}.tuple`),
                previousYearTuple: true,
              }),
              year: ixbrlCell(value(undefined), {
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:ErsattningsfonderPerTyp',
                contextRef: 'balans0',
                standardRubrik: 'Belopp per typ av ersättningsfond',
                saldo: 'credit',
                negateValue: false,
                tupleRef: tupleRef(2, `${baseId}.${_id}.tuple_balans0`),
                previousYearTuple: false,
              }),
              previousYear: ixbrlCell(value(undefined), {
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:ErsattningsfonderPerTyp',
                contextRef: 'balans1',
                standardRubrik: 'Belopp per typ av ersättningsfond',
                saldo: 'credit',
                negateValue: false,
                tupleRef: tupleRef(3, `${baseId}.${_id}.tuple_balans1`),
                previousYearTuple: false,
              }),
            },
          }));
          rows.addRowWithType(
            'Ersattningsfonder',
            'sum',
            noteLabel('Summa'),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note70.data.ErsattningsfonderTuple.table.ErsattningsfonderTuple.*.year'
                  )
                )
              ),
              {
                contextRef: 'balans0',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:Ersattningsfonder',
                standardRubrik: 'Ersättningsfonder',
                saldo: 'credit',
                negateValue: false,
              }
            ),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note70.data.ErsattningsfonderTuple.table.ErsattningsfonderTuple.*.previousYear'
                  )
                )
              ),
              {
                contextRef: 'balans1',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:Ersattningsfonder',
                standardRubrik: 'Ersättningsfonder',
                saldo: 'credit',
                negateValue: false,
              }
            )
          );
          return rows.build();
        })
        .build(),
      ErsattningsfonderKommentar: {
        type: 'group',
        active: true,
        level: 0,
        visible: ref(id('notes.note70.data.ErsattningsfonderTuple.active')),
        title: field(
          'Kommentar till obeskattade reserver i form av ersättningsfonder'
        ),
        value: ixbrlField(field(''), {
          contextRef: 'period0',
          type: 'stringItemType',
          name: 'se-gen-base:ErsattningsfonderKommentar',
          standardRubrik:
            'Kommentar till obeskattade reserver i form av ersättningsfonder',
        }),
      },
    },
    AndraObeskattadeReserverTuple: {
      type: 'group',
      active: true,
      level: 0,
      title: field('Specifikation av andra obeskattade reserver'),
      table: table<IxbrlCell>(
        'notes.note70.data.AndraObeskattadeReserverTuple.table',
        {
          id: 'AndraObeskattadeReserverTyp',
          label: '',
          dataType: 'text',
          active: true,
        },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) ?? '',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) ?? '',
          dataType: 'numeric',
          active: true,
        }
      )
        .addRows((rows) => {
          rows
            .addRowWithType(
              'AndraObeskattadeReserverTuple',
              'hidden',
              undefined,
              undefined,
              undefined
            )
            .addSubRows((rows) => {
              rows.addRowWithType(
                '1',
                'row',
                ixbrlCell(value('Typ av annan obeskattad reserv'), {
                  type: 'stringItemType',
                  name: 'se-gen-base:AndraObeskattadeReserverTyp',
                  contextRef: 'balans0',
                  standardRubrik: 'Typ av annan obeskattad reserv',
                  negateValue: false,
                  tuple: {
                    name: 'se-gaap-ext:AndraObeskattadeReserverTuple',
                    id: `${rows.getBaseId()}.1.tuple`,
                    tupleID: `${rows.getBaseId()}.1.tuple`,
                  },
                  tupleRef: tupleRef(1, `${rows.getBaseId()}.1.tuple`),
                  previousYearTuple: true,
                }),
                ixbrlCell(value(undefined), {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:AndraObeskattadeReserverPerTyp',
                  contextRef: 'balans0',
                  standardRubrik: 'Belopp per annan obeskattad reserv',
                  saldo: 'credit',
                  negateValue: false,
                  tupleRef: tupleRef(2, `${rows.getBaseId()}.1.tuple_balans0`),
                  previousYearTuple: false,
                }),
                ixbrlCell(value(undefined), {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:AndraObeskattadeReserverPerTyp',
                  contextRef: 'balans1',
                  standardRubrik: 'Belopp per annan obeskattad reserv',
                  saldo: 'credit',
                  negateValue: false,
                  tupleRef: tupleRef(3, `${rows.getBaseId()}.1.tuple_balans1`),
                  previousYearTuple: false,
                })
              );
              return rows.build();
            });
          rows.newRowTemplateGenerator((_id, baseId) => ({
            id: _id,
            active: true,
            type: 'row',
            cells: {
              AndraObeskattadeReserverTyp: ixbrlCell(
                value('Typ av annan obeskattad reserv'),
                {
                  type: 'stringItemType',
                  name: 'se-gen-base:AndraObeskattadeReserverTyp',
                  contextRef: 'balans0',
                  standardRubrik: 'Typ av annan obeskattad reserv',
                  negateValue: false,
                  tuple: {
                    name: 'se-gaap-ext:AndraObeskattadeReserverTuple',
                    id: `${baseId}.${_id}.tuple`,
                    tupleID: `${baseId}.${_id}.tuple`,
                  },
                  tupleRef: tupleRef(1, `${baseId}.${_id}.tuple`),
                  previousYearTuple: true,
                }
              ),
              year: ixbrlCell(value(undefined), {
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:AndraObeskattadeReserverPerTyp',
                contextRef: 'balans0',
                standardRubrik: 'Belopp per annan obeskattad reserv',
                saldo: 'credit',
                negateValue: false,
                tupleRef: tupleRef(2, `${baseId}.${_id}.tuple_balans0`),
                previousYearTuple: false,
              }),
              previousYear: ixbrlCell(value(undefined), {
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:AndraObeskattadeReserverPerTyp',
                contextRef: 'balans1',
                standardRubrik: 'Belopp per annan obeskattad reserv',
                saldo: 'credit',
                negateValue: false,
                tupleRef: tupleRef(3, `${baseId}.${_id}.tuple_balans1`),
                previousYearTuple: false,
              }),
            },
          }));
          rows.addRowWithType(
            'AndraObeskattadeReserver',
            'sum',
            noteLabel('Summa'),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note70.data.AndraObeskattadeReserverTuple.table.AndraObeskattadeReserverTuple.*.year'
                  )
                )
              ),
              {
                contextRef: 'balans0',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:AndraObeskattadeReserver',
                standardRubrik: 'Andra obeskattade reserver',
                saldo: 'credit',
                negateValue: false,
              }
            ),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note70.data.AndraObeskattadeReserverTuple.table.AndraObeskattadeReserverTuple.*.previousYear'
                  )
                )
              ),
              {
                contextRef: 'balans1',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:AndraObeskattadeReserver',
                standardRubrik: 'Andra obeskattade reserver',
                saldo: 'credit',
                negateValue: false,
              }
            )
          );
          return rows.build();
        })
        .build(),
      AndraObeskattadeReserverKommentar: {
        type: 'group',
        active: true,
        level: 0,
        visible: ref(
          id('notes.note70.data.AndraObeskattadeReserverTuple.active')
        ),
        title: field('Kommentar till andra obeskattade reserver'),
        value: ixbrlField(field(''), {
          contextRef: 'period0',
          type: 'stringItemType',
          name: 'se-gen-base:AndraObeskattadeReserverKommentar',
          standardRubrik: 'Kommentar till andra obeskattade reserver',
        }),
      },
    },
    ObeskattadeReserver: {
      type: 'group',
      active: true,
      level: 0,
      title: field('Obeskattade reserver'),
      table: table<IxbrlCell>(
        'notes.note70.data.ObeskattadeReserver.table',
        {
          id: 'ObeskattadeReserver',
          label: 'Summa obeskattade reserver',
          dataType: 'text',
          active: true,
        },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) ?? '',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) ?? '',
          dataType: 'numeric',
          active: true,
        }
      )
        .addRows((rows) => {
          rows.addRowWithType(
            'ObeskattadeReserver',
            'sum',
            noteLabel('Summa obeskattade reserver'),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note70.data.PerioderingsfonderSpecifikationTuple.table.Periodiseringsfonder.year'
                  ),
                  id(
                    'notes.note70.data.AckumuleradeOveravskrivningar.table.AckumuleradeOveravskrivningar.year'
                  ),
                  id(
                    'notes.note70.data.ErsattningsfonderTuple.table.Ersattningsfonder.year'
                  ),
                  id(
                    'notes.note70.data.AndraObeskattadeReserverTuple.table.AndraObeskattadeReserver.year'
                  )
                )
              ),
              {
                contextRef: 'balans0',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:ObeskattadeReserver',
                standardRubrik: 'Obeskattade reserver',
                saldo: 'credit',
                negateValue: false,
              }
            ),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note70.data.PerioderingsfonderSpecifikationTuple.table.Periodiseringsfonder.previousYear'
                  ),
                  id(
                    'notes.note70.data.AckumuleradeOveravskrivningar.table.AckumuleradeOveravskrivningar.previousYear'
                  ),
                  id(
                    'notes.note70.data.ErsattningsfonderTuple.table.Ersattningsfonder.previousYear'
                  ),
                  id(
                    'notes.note70.data.AndraObeskattadeReserverTuple.table.AndraObeskattadeReserver.previousYear'
                  )
                )
              ),
              {
                contextRef: 'balans1',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:ObeskattadeReserver',
                standardRubrik: 'Obeskattade reserver',
                saldo: 'credit',
                negateValue: false,
              }
            )
          );
          return rows.build();
        })
        .build(),
    },
    NotObeskattadeReserverPeriodiseringsfonderKommentar: {
      type: 'group',
      active: true,
      level: 0,
      title: field('Kommentar till specifikation av obeskattade reserver'),
      value: ixbrlField(field(''), {
        contextRef: 'period0',
        type: 'stringItemType',
        name: 'se-gen-base:NotObeskattadeReserverPeriodiseringsfonderKommentar',
        standardRubrik: 'Kommentar till specifikation av obeskattade reserver',
      }),
    },
  },
  group: value(undefined),
  section: value(undefined),
  validForReportType: {
    k2: true,
    k3: true,
  },
});
export const note2070K3v2ContentDefinition = {
  type: 'part' as const,
  children: {
    active: boolean,
    number: cell,
    name: cell,
    data: {
      type: 'part' as const,
      children: {
        active: boolean,

        PerioderingsfonderSpecifikationTuple: {
          type: 'part' as const,
          children: {
            type: type,
            level: number,
            active: boolean,

            title: cell,

            table: { type: 'table' as const },

            ObeskattadeReserverPeriodiseringsfonderKommentar: {
              type: 'part' as const,
              children: {
                type: type,
                level: number,
                active: boolean,
                visible: cell,

                title: cell,

                value: cell,
              },
            },
          },
        },

        AckumuleradeOveravskrivningar: {
          type: 'part' as const,
          children: {
            type: type,
            level: number,
            active: boolean,

            title: cell,

            table: { type: 'table' as const },
          },
        },

        ErsattningsfonderTuple: {
          type: 'part' as const,
          children: {
            type: type,
            level: number,
            active: boolean,

            title: cell,

            table: { type: 'table' as const },

            ErsattningsfonderKommentar: {
              type: 'part' as const,
              children: {
                type: type,
                level: number,
                active: boolean,
                visible: cell,

                title: cell,

                value: cell,
              },
            },
          },
        },

        AndraObeskattadeReserverTuple: {
          type: 'part' as const,
          children: {
            type: type,
            level: number,
            active: boolean,

            title: cell,

            table: { type: 'table' as const },

            AndraObeskattadeReserverKommentar: {
              type: 'part' as const,
              children: {
                type: type,
                level: number,
                active: boolean,
                visible: cell,

                title: cell,

                value: cell,
              },
            },
          },
        },

        ObeskattadeReserver: {
          type: 'part' as const,
          children: {
            type: type,
            level: number,
            active: boolean,

            title: cell,

            table: { type: 'table' as const },
          },
        },

        NotObeskattadeReserverPeriodiseringsfonderKommentar: {
          type: 'part' as const,
          children: {
            type: type,
            level: number,
            active: boolean,

            title: cell,

            value: cell,
          },
        },
      },
    },
    group: cell,
    section: cell,
    validForReportType: {
      type: 'part' as const,
      children: {
        k2: boolean,
        k3: boolean,
      },
    },
  },
};

import {
  value,
  table,
  BooleanCell,
  NumberCell,
  StringCell,
  LabelCell,
} from '@agoy/document';
import { NoteSixTableSection, IxbrlCell } from '../../../../common';
import { sumNotesRowsById } from '../../../../common/utils/accountSumsUtil';
import {
  active,
  field,
  ixbrlCell,
  ixbrlField,
  range,
} from '../../../../common/utils/util';
import { year, previousYear } from '../../../../common/utils/date-util';

const MONETARY_ITEM_TYPE = 'monetaryItemType';

export const getNote75 = (
  noteLabel: (
    name: string
  ) => BooleanCell | NumberCell | StringCell | LabelCell,
  financialYear: string,
  financialYears: string[],
  period: string | null
): NoteSixTableSection => {
  return {
    active: false,
    number: value(undefined),
    name: value(
      'Av- och nedskrivningar av materiella och immateriella anläggningstillgångar'
    ),

    table: table<IxbrlCell>(
      'notes.note75.table',
      {
        id: 'label',
        label: 'Avskrivningar enligt plan fördelade per tillgång',
      },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      .addRows((rows) =>
        rows
          .addRow(
            '1',
            noteLabel(
              'Balanserade utgifter för utvecklingsarbeten och liknande arbeten'
            ),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AvskrivningarBalanseradeutgifterUtvecklingsarbetenLiknandeArbetenInklusiveAvskrivningarUppskrivningar',
              contextRef: 'period0',
              saldo: 'debit',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AvskrivningarBalanseradeutgifterUtvecklingsarbetenLiknandeArbetenInklusiveAvskrivningarUppskrivningar',
              contextRef: 'period1',
              saldo: 'debit',
            })
          )
          .addRow(
            '2',
            noteLabel('Koncessioner, patent, licenser och varumärken'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AvskrivningarKoncessionerPatentLicenserVarumarkenLiknandeRattigheterInklusiveAvskrivningarUppskrivningar',
              contextRef: 'period0',
              saldo: 'debit',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AvskrivningarKoncessionerPatentLicenserVarumarkenLiknandeRattigheterInklusiveAvskrivningarUppskrivningar',
              contextRef: 'period1',
              saldo: 'debit',
            })
          )
          .addRow(
            '3',
            noteLabel('Hyresrätter och liknande rättigheter'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AvskrivningarHyresratterLiknandeRattigheterInklusiveAvskrivningarUppskrivningar',
              contextRef: 'period0',
              saldo: 'debit',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AvskrivningarHyresratterLiknandeRattigheterInklusiveAvskrivningarUppskrivningar',
              contextRef: 'period1',
              saldo: 'debit',
            })
          )
          .addRow(
            '4',
            noteLabel('Goodwill'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:GoodwillForandringAvskrivningarAretsAvskrivningar',
              contextRef: 'period0',
              saldo: 'debit',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:GoodwillForandringAvskrivningarAretsAvskrivningar',
              contextRef: 'period1',
              saldo: 'debit',
            })
          )
          .addRow(
            '5',
            noteLabel('Byggnader och mark'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AvskrivningarByggnaderMarkInklusiveAvskrivningarUppskrivningar',
              contextRef: 'period0',
              saldo: 'debit',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AvskrivningarByggnaderMarkInklusiveAvskrivningarUppskrivningar',
              contextRef: 'period1',
              saldo: 'debit',
            })
          )
          .addRow(
            '6',
            noteLabel('Maskiner och andra tekniska anläggningar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AvskrivningarMaskinerAndraTekniskaAnlaggningarInklusiveAvskrivningarUppskrivningar',
              contextRef: 'period0',
              saldo: 'debit',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AvskrivningarMaskinerAndraTekniskaAnlaggningarInklusiveAvskrivningarUppskrivningar',
              contextRef: 'period1',
              saldo: 'debit',
            })
          )
          .addRow(
            '7',
            noteLabel('Inventarier, verktyg och installationer'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AvskrivningarInventarierVerktygInstallationerInklusiveAvskrivningarUppskrivningar',
              contextRef: 'period0',
              saldo: 'debit',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AvskrivningarInventarierVerktygInstallationerInklusiveAvskrivningarUppskrivningar',
              contextRef: 'period1',
              saldo: 'debit',
            })
          )
          .addRow(
            '8',
            noteLabel('Förbättringsutgifter på annans fastighet'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AvskrivningarForbattringsutgifterAnnansFastighetInklusiveAvskrivningarUppskrivningar',
              contextRef: 'period0',
              saldo: 'debit',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AvskrivningarForbattringsutgifterAnnansFastighetInklusiveAvskrivningarUppskrivningar',
              contextRef: 'period1',
              saldo: 'debit',
            })
          )
          .addRow(
            '9',
            noteLabel('Övriga materiella anläggningstillgångar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AvskrivningarOvrigaMateriellaAnlaggningstillgangarInklusiveAvskrivningarUppskrivningar',
              contextRef: 'period0',
              saldo: 'debit',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AvskrivningarOvrigaMateriellaAnlaggningstillgangarInklusiveAvskrivningarUppskrivningar',
              contextRef: 'period1',
              saldo: 'debit',
            })
          )
          .addRow(
            '10',
            noteLabel('Summa'),
            ixbrlCell(sumNotesRowsById(75, range(1, 10), 'year'), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AvskrivningarEnligtPlan',
              contextRef: 'period0',
              saldo: 'debit',
            }),
            ixbrlCell(sumNotesRowsById(75, range(1, 10), 'previousYear'), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AvskrivningarEnligtPlan',
              contextRef: 'period1',
              saldo: 'debit',
            })
          )
          .build()
      )
      .build(),
    tableCommentLabel: field('Kommentar'),
    tableComment: ixbrlField(active(field('')), {
      type: 'stringItemType',
      name: 'se-gen-base:AvskrivningarEnligtPlanTillgangKommentar',
      contextRef: 'period0',
    }),

    secondTable: table<IxbrlCell>(
      'notes.note75.secondTable',
      {
        id: 'label',
        label: 'Avskrivningar enligt plan fördelade per funktion',
      },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      .addRows((rows) =>
        rows
          .addRow(
            '1',
            noteLabel('Kostnad för sålda varor'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AvskrivningarEnligtPlanFordeladFunktionKostnadSaldaVaror',
              saldo: 'debit',
              contextRef: 'period0',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AvskrivningarEnligtPlanFordeladFunktionKostnadSaldaVaror',
              saldo: 'debit',
              contextRef: 'period1',
            })
          )
          .addRow(
            '2',
            noteLabel('Försäljningskostnader'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AvskrivningarEnligtPlanFordeladFunktionForsaljningskostnader',
              saldo: 'debit',
              contextRef: 'period0',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AvskrivningarEnligtPlanFordeladFunktionForsaljningskostnader',
              saldo: 'debit',
              contextRef: 'period1',
            })
          )
          .addRow(
            '3',
            noteLabel('Administrationskostnader'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AvskrivningarEnligtPlanFordeladFunktionAdministrationskostnader',
              saldo: 'debit',
              contextRef: 'period0',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AvskrivningarEnligtPlanFordeladFunktionAdministrationskostnader',
              saldo: 'debit',
              contextRef: 'period1',
            })
          )
          .addRow(
            '4',
            noteLabel('Forsknings- och utvecklingskostnader'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AvskrivningarEnligtPlanFordeladFunktionForskningUtvecklingskostnader',
              saldo: 'debit',
              contextRef: 'period0',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AvskrivningarEnligtPlanFordeladFunktionForskningUtvecklingskostnader',
              saldo: 'debit',
              contextRef: 'period1',
            })
          )
          .addRow(
            '5',
            noteLabel('Övriga rörelsekostnader'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AvskrivningarEnligtPlanFordeladFunktionOvrigaRorelsekostnader',
              saldo: 'debit',
              contextRef: 'period0',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AvskrivningarEnligtPlanFordeladFunktionOvrigaRorelsekostnader',
              saldo: 'debit',
              contextRef: 'period1',
            })
          )
          .addRow(
            '6',
            noteLabel('Summa'),
            ixbrlCell(
              sumNotesRowsById(75, range(1, 6), 'year', 'secondTable'),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:AvskrivningarEnligtPlan',
                saldo: 'debit',
                contextRef: 'period0',
              }
            ),
            ixbrlCell(
              sumNotesRowsById(75, range(1, 6), 'previousYear', 'secondTable'),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:AvskrivningarEnligtPlan',
                saldo: 'debit',
                contextRef: 'period1',
              }
            )
          )
          .build()
      )
      .build(),
    secondTableCommentLabel: field('Kommentar'),
    secondTableComment: ixbrlField(active(field('')), {
      type: 'stringItemType',
      name: 'se-gen-base:AvskrivningarEnligtPlanFordeladFunktionKommentar',
      contextRef: 'period0',
    }),

    thirdTable: table<IxbrlCell>(
      'notes.note75.thirdTable',
      {
        id: 'label',
        label: 'Nedskrivningar fördelade per tillgång',
      },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      .addRows((rows) =>
        rows
          .addRow(
            '1',
            noteLabel(
              'Balanserade utgifter för utvecklingsarbeten och liknande arbeten'
            ),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:NedskrivningarBalanseradeUtgifterUtvecklingsarbetenLiknandeArbetenInklusiveNedskrivningarUppskrivningar',
              saldo: 'debit',
              contextRef: 'period0',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:NedskrivningarBalanseradeUtgifterUtvecklingsarbetenLiknandeArbetenInklusiveNedskrivningarUppskrivningar',
              saldo: 'debit',
              contextRef: 'period1',
            })
          )
          .addRow(
            '2',
            noteLabel('Koncessioner, patent, licenser och varumärken'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:NedskrivningarKoncessionerPatentLicenserVarumarkenLiknandeRattigheterInklusiveNedskrivningarUppskrivningar',
              saldo: 'debit',
              contextRef: 'period0',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:NedskrivningarKoncessionerPatentLicenserVarumarkenLiknandeRattigheterInklusiveNedskrivningarUppskrivningar',
              saldo: 'debit',
              contextRef: 'period1',
            })
          )
          .addRow(
            '3',
            noteLabel('Hyresrätter och liknande rättigheter'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:NedskrivningarHyresratterLiknandeRattigheterInklusiveNedskrivningarUppskrivningar',
              saldo: 'debit',
              contextRef: 'period0',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:NedskrivningarHyresratterLiknandeRattigheterInklusiveNedskrivningarUppskrivningar',
              saldo: 'debit',
              contextRef: 'period1',
            })
          )
          .addRow(
            '4',
            noteLabel('Goodwill'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:GoodwillForandringNedskrivningarAretsNedskrivningar',
              saldo: 'debit',
              contextRef: 'period0',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:GoodwillForandringNedskrivningarAretsNedskrivningar',
              saldo: 'debit',
              contextRef: 'period1',
            })
          )
          .addRow(
            '5',
            noteLabel('Byggnader och mark'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:NedskrivningarByggnaderMarkInklusiveNedskrivningarUppskrivningar',
              saldo: 'debit',
              contextRef: 'period0',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:NedskrivningarByggnaderMarkInklusiveNedskrivningarUppskrivningar',
              saldo: 'debit',
              contextRef: 'period1',
            })
          )
          .addRow(
            '6',
            noteLabel('Maskiner och andra tekniska anläggningar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:NedskrivningarMaskinerAndraTekniskaAnlaggningarInklusiveNedskrivningarUppskrivningar',
              saldo: 'debit',
              contextRef: 'period0',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:NedskrivningarMaskinerAndraTekniskaAnlaggningarInklusiveNedskrivningarUppskrivningar',
              saldo: 'debit',
              contextRef: 'period1',
            })
          )
          .addRow(
            '7',
            noteLabel('Inventarier, verktyg och installationer'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:NedskrivningarInventarierVerktygInstallationerInklusiveNedskrivningarUppskrivningar',
              saldo: 'debit',
              contextRef: 'period0',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:NedskrivningarInventarierVerktygInstallationerInklusiveNedskrivningarUppskrivningar',
              saldo: 'debit',
              contextRef: 'period1',
            })
          )
          .addRow(
            '8',
            noteLabel('Förbättringsutgifter på annans fastighet'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:NedskrivningarForbattringsutgifterAnnansFastighetInklusiveNedskrivningarUppskrivningar',
              saldo: 'debit',
              contextRef: 'period0',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:NedskrivningarForbattringsutgifterAnnansFastighetInklusiveNedskrivningarUppskrivningar',
              saldo: 'debit',
              contextRef: 'period1',
            })
          )
          .addRow(
            '9',
            noteLabel('Övriga materiella anläggningstillgångar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:NedskrivningarOvrigaMateriellaAnlaggningstillgAngarInklusiveNedskrivningarUppskrivningar',
              saldo: 'debit',
              contextRef: 'period0',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:NedskrivningarOvrigaMateriellaAnlaggningstillgAngarInklusiveNedskrivningarUppskrivningar',
              saldo: 'debit',
              contextRef: 'period1',
            })
          )
          .addRow(
            '10',
            noteLabel('Summa'),
            ixbrlCell(
              sumNotesRowsById(75, range(1, 10), 'year', 'thirdTable'),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:NedskrivningarImmateriellaMateriellaAnlaggningstillgangar',
                saldo: 'debit',
                contextRef: 'period0',
              }
            ),
            ixbrlCell(
              sumNotesRowsById(75, range(1, 10), 'previousYear', 'thirdTable'),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:NedskrivningarImmateriellaMateriellaAnlaggningstillgangar',
                saldo: 'debit',
                contextRef: 'period1',
              }
            )
          )
          .build()
      )
      .build(),
    thirdTableCommentLabel: field('Kommentar'),
    thirdTableComment: ixbrlField(active(field('')), {
      type: 'stringItemType',
      name: 'se-gen-base:NedskrivningarFordeladTillgangKommentar',
      contextRef: 'period0',
    }),

    fourthTable: table<IxbrlCell>(
      'notes.note75.fourthTable',
      {
        id: 'label',
        label: 'Nedskrivningar fördelade per funktion',
      },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      .addRows((rows) =>
        rows
          .addRow(
            '1',
            noteLabel('Kostnad för sålda varor'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:NedskrivningarFordeladFunktionKostnadSaldaVaror',
              saldo: 'debit',
              contextRef: 'period0',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:NedskrivningarFordeladFunktionKostnadSaldaVaror',
              saldo: 'debit',
              contextRef: 'period1',
            })
          )
          .addRow(
            '2',
            noteLabel('Försäljningskostnader'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:NedskrivningarFordeladFunktionForsaljningskostnader',
              saldo: 'debit',
              contextRef: 'period0',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:NedskrivningarFordeladFunktionForsaljningskostnader',
              saldo: 'debit',
              contextRef: 'period1',
            })
          )
          .addRow(
            '3',
            noteLabel('Administrationskostnader'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:NedskrivningarFordeladFunktionAdministrationskostnader',
              saldo: 'debit',
              contextRef: 'period0',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:NedskrivningarFordeladFunktionAdministrationskostnader',
              saldo: 'debit',
              contextRef: 'period1',
            })
          )
          .addRow(
            '4',
            noteLabel('Forsknings- och utvecklingskostnader'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:NedskrivningarFordeladFunktionForskningUtvecklingskostnader',
              saldo: 'debit',
              contextRef: 'period0',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:NedskrivningarFordeladFunktionForskningUtvecklingskostnader',
              saldo: 'debit',
              contextRef: 'period1',
            })
          )
          .addRow(
            '5',
            noteLabel('Övriga rörelsekostnader'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:NedskrivningarFordeladFunktionOvrigaRorelsekostnader',
              saldo: 'debit',
              contextRef: 'period0',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:NedskrivningarFordeladFunktionOvrigaRorelsekostnader',
              saldo: 'debit',
              contextRef: 'period1',
            })
          )
          .addRow(
            '6',
            noteLabel('Summa'),
            ixbrlCell(
              sumNotesRowsById(75, range(1, 6), 'year', 'fourthTable'),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:NedskrivningarImmateriellaMateriellaAnlaggningstillgangar',
                saldo: 'debit',
                contextRef: 'period0',
              }
            ),
            ixbrlCell(
              sumNotesRowsById(75, range(1, 6), 'previousYear', 'fourthTable'),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:NedskrivningarImmateriellaMateriellaAnlaggningstillgangar',
                saldo: 'debit',
                contextRef: 'period1',
              }
            )
          )
          .build()
      )
      .build(),
    fourthTableCommentLabel: field('Kommentar'),
    fourthTableComment: ixbrlField(active(field('')), {
      type: 'stringItemType',
      name: 'se-gen-base:NedskrivningarFordeladFunktionKommentar',
      contextRef: 'period0',
    }),

    fifthTable: table<IxbrlCell>(
      'notes.note75.fifthTable',
      {
        id: 'label',
        label: 'Återförda nedskrivningar fördelade per tillgång',
      },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      .addRows((rows) =>
        rows
          .addRow(
            '1',
            noteLabel(
              'Balanserade utgifter för utvecklingsarbeten och liknande arbeten'
            ),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringNedskrivningarAterfordaNedskrivningar',
              contextRef: 'period0',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringNedskrivningarAterfordaNedskrivningar',
              contextRef: 'period1',
            })
          )
          .addRow(
            '2',
            noteLabel('Koncessioner, patent, licenser och varumärken'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringNedskrivningarAterfordaNedskrivningar',
              contextRef: 'period0',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringNedskrivningarAterfordaNedskrivningar',
              contextRef: 'period1',
            })
          )
          .addRow(
            '3',
            noteLabel('Hyresrätter och liknande rättigheter'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:HyresratterLiknandeRattigheterForandringNedskrivningarAterfordaNedskrivningar',
              contextRef: 'period0',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:HyresratterLiknandeRattigheterForandringNedskrivningarAterfordaNedskrivningar',
              contextRef: 'period1',
            })
          )
          .addRow(
            '4',
            noteLabel('Goodwill'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:GoodwillForandringNedskrivningarAterfordaNedskrivningar',
              contextRef: 'period0',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:GoodwillForandringNedskrivningarAterfordaNedskrivningar',
              contextRef: 'period1',
            })
          )
          .addRow(
            '5',
            noteLabel('Byggnader och mark'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ByggnaderMarkForandringNedskrivningarAterfordaNedskrivningar',
              contextRef: 'period0',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ByggnaderMarkForandringNedskrivningarAterfordaNedskrivningar',
              contextRef: 'period1',
            })
          )
          .addRow(
            '6',
            noteLabel('Maskiner och andra tekniska anläggningar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarForandringNedskrivningarAterfordaNedskrivningar',
              contextRef: 'period0',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningarForandringNedskrivningarAterfordaNedskrivningar',
              contextRef: 'period1',
            })
          )
          .addRow(
            '7',
            noteLabel('Inventarier, verktyg och installationer'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:InventarierVerktygInstallationerForandringNedskrivningarAterfordaNedskrivningar',
              contextRef: 'period0',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:InventarierVerktygInstallationerForandringNedskrivningarAterfordaNedskrivningar',
              contextRef: 'period1',
            })
          )
          .addRow(
            '8',
            noteLabel('Förbättringsutgifter på annans fastighet'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForbattringsutgifterAnnansFastighetForandringNedskrivningarAterfordaNedskrivningar',
              contextRef: 'period0',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForbattringsutgifterAnnansFastighetForandringNedskrivningarAterfordaNedskrivningar',
              contextRef: 'period1',
            })
          )
          .addRow(
            '9',
            noteLabel('Övriga materiella anläggningstillgångar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OvrigaMateriellaAnlaggningstillgangarForandringNedskrivningarAterfordaNedskrivningar',
              contextRef: 'period0',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OvrigaMateriellaAnlaggningstillgangarForandringNedskrivningarAterfordaNedskrivningar',
              contextRef: 'period1',
            })
          )
          .addRow(
            '10',
            noteLabel('Summa'),
            ixbrlCell(
              sumNotesRowsById(75, range(1, 10), 'year', 'fifthTable'),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:AterfordaNedskrivningarImmateriellaMateriellaAnlaggningstillgangar',
                saldo: 'credit',
                contextRef: 'period0',
              }
            ),
            ixbrlCell(
              sumNotesRowsById(75, range(1, 10), 'previousYear', 'fifthTable'),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:AterfordaNedskrivningarImmateriellaMateriellaAnlaggningstillgangar',
                saldo: 'credit',
                contextRef: 'period1',
              }
            )
          )
          .build()
      )
      .build(),
    fifthTableCommentLabel: field('Kommentar'),
    fifthTableComment: ixbrlField(active(field('')), {
      type: 'stringItemType',
      name: 'se-gen-base:AterfordaNedskrivningarFordeladTillgangKommentar',
      contextRef: 'period0',
    }),

    sixthTable: table<IxbrlCell>(
      'notes.note75.sixthTable',
      {
        id: 'label',
        label: 'Återförda nedskrivningar fördelade per funktion',
      },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      .addRows((rows) =>
        rows
          .addRow(
            '1',
            noteLabel('Kostnad för sålda varor'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AterfordaNedskrivningarFordeladFunktionKostnadSaldaVaror',
              saldo: 'credit',
              contextRef: 'period0',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AterfordaNedskrivningarFordeladFunktionKostnadSaldaVaror',
              saldo: 'credit',
              contextRef: 'period1',
            })
          )
          .addRow(
            '2',
            noteLabel('Försäljningskostnader'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AterfordaNedskrivningarFordeladFunktionForsaljningskostnader',
              saldo: 'credit',
              contextRef: 'period0',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AterfordaNedskrivningarFordeladFunktionForsaljningskostnader',
              saldo: 'credit',
              contextRef: 'period1',
            })
          )
          .addRow(
            '3',
            noteLabel('Administrationskostnader'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AterfordaNedskrivningarFordeladFunktionAdministrationskostnader',
              saldo: 'credit',
              contextRef: 'period0',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AterfordaNedskrivningarFordeladFunktionAdministrationskostnader',
              saldo: 'credit',
              contextRef: 'period1',
            })
          )
          .addRow(
            '4',
            noteLabel('Forsknings- och utvecklingskostnader'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AterfordaNedskrivningarFordeladFunktionForskningUtvecklingskostnader',
              saldo: 'credit',
              contextRef: 'period0',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AterfordaNedskrivningarFordeladFunktionForskningUtvecklingskostnader',
              saldo: 'credit',
              contextRef: 'period1',
            })
          )
          .addRow(
            '5',
            noteLabel('Övriga rörelsekostnader'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AterfordaNedskrivningarFordeladFunktionOvrigaRorelsekostnader',
              saldo: 'credit',
              contextRef: 'period0',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AterfordaNedskrivningarFordeladFunktionOvrigaRorelsekostnader',
              saldo: 'credit',
              contextRef: 'period1',
            })
          )
          .addRow(
            '6',
            noteLabel('Summa'),
            ixbrlCell(sumNotesRowsById(75, range(1, 6), 'year', 'sixthTable'), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AterfordaNedskrivningarImmateriellaMateriellaAnlaggningstillgangar',
              saldo: 'credit',
              contextRef: 'period0',
            }),
            ixbrlCell(
              sumNotesRowsById(75, range(1, 6), 'previousYear', 'sixthTable'),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:AterfordaNedskrivningarImmateriellaMateriellaAnlaggningstillgangar',
                saldo: 'credit',
                contextRef: 'period1',
              }
            )
          )
          .build()
      )
      .build(),
    sixthTableCommentLabel: field('Kommentar'),
    sixthTableComment: ixbrlField(active(field('')), {
      type: 'stringItemType',
      name: 'se-gen-base:AterfordaNedskrivningarFordeladFunktionKommentar',
      contextRef: 'period0',
    }),

    commentLabel: field('Kommentar till not'),
    comment: ixbrlField(active(field('')), {
      type: 'stringItemType',
      name: 'se-gen-base:NotAvNedskrivningarMateriellaImmateriellaAnlaggningstillgangarKommentar',
      contextRef: 'period0',
    }),
    group: value(undefined),
    section: value(undefined),
    validForReportType: {
      k2: false,
      k3: true,
    },
  };
};

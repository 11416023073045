/* eslint-disable no-console */
// eslint-disable-next-line import/no-cycle
import { UpdateTaxDeclarationFormField } from 'redux/actions';
import { Field, isError, operations } from '@agoy/document';
import {
  TaxDeclarationFormData,
  TaxDeclarationFormStructure,
  isTaxDeclarationCoverLetter,
  isTaxDeclarationFormPartData,
} from '@agoy/tax-declaration-document';
import TaxDeclarationViewState from '../types';

const validateField = (
  formDefintion: TaxDeclarationFormStructure,
  partId: string,
  id: string
) => {
  const part = formDefintion.children[partId];
  if (part && 'children' in part && part.partType === 'form') {
    return part.children.fields.children[id]?.type === 'field';
  }
  return false;
};

const updateStandardField = (
  selectedFormId: string,
  formData: TaxDeclarationViewState['formData'][string],
  state: TaxDeclarationViewState,
  action: UpdateTaxDeclarationFormField
): TaxDeclarationViewState => {
  if (!formData) {
    return state;
  }
  const { partId, id, value } = action;

  const result = operations.updateField(
    formData.definition,
    formData,
    `${partId}.${id}`,
    value
  );

  if (!result || isError(result)) {
    console.warn(result);
    return state;
  }

  return {
    ...state,
    formData: {
      ...state.formData,
      [selectedFormId]: {
        ...formData,
        document: result.document as TaxDeclarationFormData,
        changes: result.changes,
      },
    },
  };
};

const updateTaxDeclarationFormField = (
  state: TaxDeclarationViewState,
  action: UpdateTaxDeclarationFormField
): TaxDeclarationViewState => {
  if (Object.keys(state.formData).length === 0) {
    return state;
  }

  const selectedFormId = state.viewFormId;
  if (!selectedFormId) return state;

  const formData = state.formData[selectedFormId];
  if (!formData) {
    return state;
  }
  const { partId, id, value } = action;

  // validate that field is part of definition of form
  if (!validateField(formData.definition, partId, id)) {
    // Not among the fields, treat it as a standard document
    return updateStandardField(selectedFormId, formData, state, action);
  }

  const part = formData.document[partId];
  if (
    !isTaxDeclarationFormPartData(part) &&
    !isTaxDeclarationCoverLetter(part)
  ) {
    return state;
  }

  const currentCell: Field = part.fields[id];

  if (!currentCell) return state;

  if (currentCell.type === 'string' && typeof value !== 'string') {
    console.error(
      `Cell ${id} has type string but value is of type ${typeof value}`
    );
    return state;
  }
  if (
    currentCell.type === 'number' &&
    typeof value !== 'number' &&
    typeof value !== 'undefined'
  ) {
    console.error(`Field ${id} has type number but value is ${typeof value}`);
    return state;
  }

  if (currentCell.type === 'boolean' && typeof value !== 'boolean') {
    console.error(`Field ${id} has type boolean but value is ${typeof value}`);
    return state;
  }

  if (currentCell.value === value) {
    return state;
  }
  return updateStandardField(selectedFormId, formData, state, {
    ...action,
    partId: `${action.partId}.fields`,
  });
};

export default updateTaxDeclarationFormField;

import { parseFormat } from '@agoy/dates';
import {
  differenceInCalendarYears,
  differenceInMonths,
  parse,
  sub,
} from 'date-fns';

export const prepareReportTitle = (
  title: string,
  currentFinancialYear: string | undefined,
  currentPeriod: string | null,
  comparePeriod: number
): string => {
  if (!currentFinancialYear || !currentPeriod) {
    return title;
  }
  const [start] = currentFinancialYear.split('-');
  const startFinancialYear = parse(start, 'yyyyMMdd', new Date());
  const endPeriod = parse(currentPeriod, 'yyyyMMdd', new Date());
  let startPeriod = endPeriod;
  switch (comparePeriod) {
    case 1: {
      break;
    }
    case 12: {
      startPeriod = startFinancialYear;
      break;
    }
    default: {
      startPeriod = sub(endPeriod, { months: comparePeriod - 1 });
      if (differenceInCalendarYears(startPeriod, startFinancialYear) !== 0) {
        startPeriod = startFinancialYear;
      }
      break;
    }
  }

  let periodTitle = `${parseFormat(startPeriod, 'MMM')}-${parseFormat(
    endPeriod,
    'MMM yyyy'
  )}`;
  if (differenceInCalendarYears(startPeriod, endPeriod) !== 0) {
    periodTitle = `${parseFormat(startPeriod, 'MMM-yy')}-${parseFormat(
      endPeriod,
      'MMM-yy'
    )}`;
  }
  if (differenceInMonths(startPeriod, endPeriod) === 0) {
    periodTitle = `${parseFormat(startPeriod, 'MMM-yy')}`;
  }
  const result = title.replace(/{periodTitle}/g, periodTitle);
  return result;
};

import { value } from '@agoy/document';
import { NoteInputFieldSectionNoComment } from '../../../../common';
import { active, field, ixbrlField } from '../../../../common/utils/util';

const getNote60 = (): NoteInputFieldSectionNoComment => {
  return {
    active: false,
    number: value(undefined),
    name: value('Övriga upplysningar till resultaträkningen'),
    inputField: ixbrlField(active(field('')), {
      type: 'stringItemType',
      name: 'se-gen-base:NotOvrigaUpplysningarTillResultatrakningen',
      contextRef: 'period0',
    }),
    group: value(undefined),
    section: value(undefined),
    validForReportType: {
      k2: true,
      k3: true,
    },
  };
};

export default getNote60;

import { value } from '@agoy/document';
import { NoteInputFieldSection } from '../../../../common';
import { active, field, ixbrlField } from '../../../../common/utils/util';

export const getNote65 = (): NoteInputFieldSection => {
  return {
    active: false,
    number: value(undefined),
    name: value(
      'Lån, säkerheter, åtaganden m.m. till förmån för ledande befattningshavare'
    ),
    inputField: ixbrlField(active(field('')), {
      type: 'stringItemType',
      name: 'se-gen-base:NotLanSakerheterAtagandenFormanLedandeBefattningshavare',
      contextRef: 'period0',
    }),
    commentLabel: field('Kommentar'),
    comment: field(''),
    group: value(undefined),
    section: value(undefined),
    validForReportType: {
      k2: true,
      k3: true,
    },
  };
};

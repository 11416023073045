import { balanceSheetReferences } from '../balance-sheet/nonProfitOrgBalanceSheet';
import { incomeStatementReferences } from '../income-statement/nonProfitOrgIncomeStatement';

type References = {
  [key: string]: string;
};

export const nonProfitOrgReferences = (): References[] => {
  const partReferences = [
    balanceSheetReferences(),
    incomeStatementReferences(),
  ];

  return partReferences;
};

import React, { useState } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { AWSError } from 'aws-sdk/lib/error';
import { Typography, Button } from '@material-ui/core';

import config from '_shared/services/config';
import { CTAButton } from '_shared/components/Buttons';
import FormError from '_shared/components/Inputs/FormError';
import useAuth from '_authentication/hooks/useAuth';
import {
  LoginBox,
  LinkWrapper,
  TextField,
  UnderlineLink,
  ActionsWrapper,
} from './SharedComponents';

interface LoginContentProps {
  forgotPasswordCallback: () => void;
  createUserCallback: () => void;
  email?: string;
}

const getErrorMessage = (intl: IntlShape, error: AWSError): string => {
  switch (error.name) {
    case 'UserNotFoundException':
    case 'NotAuthorizedException':
      return intl.formatMessage({ id: 'login.userOrPassWordWrong' });
    case 'UserNotConfirmedException':
      return intl.formatMessage({ id: 'login.userNotConfirmed' });
    case 'UserLambdaValidationException':
      if (error.message.includes('origin=fortnox')) {
        return intl.formatMessage({ id: 'login.throughFortnox' });
      }
      if (error.message.includes('Login has been disabled')) {
        return intl.formatMessage({ id: 'login.disabled' });
      }
      return intl.formatMessage({ id: 'error' });
    default:
      return intl.formatMessage({ id: 'error' });
  }
};

const LoginContent: React.FC<LoginContentProps> = ({
  forgotPasswordCallback,
  createUserCallback,
  email,
}: LoginContentProps) => {
  const intl = useIntl();
  const { logInAWS, isLoading } = useAuth();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      username: email || '',
      password: '',
    },
  });
  const [submitError, setSubmitError] = useState('');

  const msg = (id: string) => intl.formatMessage({ id });

  const handleFormSubmit = async (data) => {
    const { username, password } = data;
    try {
      await logInAWS({ username, password });
    } catch (error) {
      setSubmitError(getErrorMessage(intl, error));
    }
  };

  return (
    <LoginBox onSubmit={handleSubmit(handleFormSubmit)}>
      <Typography variant="h2">{msg('login.welcome')}</Typography>
      <Typography>{msg('login.text')}</Typography>

      <TextField
        {...register('username', {
          required: msg('form.fieldRequired'),
        })}
        variant="outlined"
        size="small"
        placeholder={msg('email.placeholder')}
        type="email"
        data-cy="login-username-input"
      />

      <FormError>{errors.username?.message}</FormError>

      <TextField
        {...register('password', {
          required: msg('form.fieldRequired'),
        })}
        variant="outlined"
        size="small"
        placeholder={msg('password')}
        type="password"
        data-cy="login-password-input"
      />

      <FormError>{errors.password?.message}</FormError>

      {submitError && (
        <FormError data-cy="login-error">{submitError}</FormError>
      )}

      <LinkWrapper>
        <UnderlineLink onClick={forgotPasswordCallback}>
          {msg('login.forgot')}
        </UnderlineLink>
      </LinkWrapper>

      <ActionsWrapper>
        {config.runningEnvironment !== 'production' && (
          <Button onClick={createUserCallback}>{msg('create.user')}</Button>
        )}
        <CTAButton type="submit" loading={isLoading} data-cy="login-submit-btn">
          {msg('login')}
        </CTAButton>
      </ActionsWrapper>
    </LoginBox>
  );
};

export default LoginContent;

import { format, parse, parseISO } from 'date-fns';
import sv from 'date-fns/locale/sv';
import { capitalize } from './formatting';

/**
 * Parses a date from string and then formats in another format.
 *
 * @param date date as string in `currentFormat`
 * @param currentFormat format as specified by `date-fns` and unicode standard
 * @param newFormat format as specified by `date-fns` and unicode standard
 * @returns
 */
export const reformatDate = (
  date: string,
  currentFormat: string,
  newFormat: string
): string => {
  return format(parse(date, currentFormat, Date.now()), newFormat, {
    locale: sv,
  });
};

/**
 * Formats a date and uses capitalize to get initial capital letters
 *
 * @param date date as js Date or number
 * @param dateFormat format as specified by `date-fns` and unicode standard
 * @returns Date formatted using swedish locale.
 */
export const formatDate = (date: Date | number, dateFormat: string): string =>
  capitalize(format(date, dateFormat, { locale: sv }));

/**
 * Format a date given in yyyy-MM-dd format to some other format
 *
 * @param date in format yyyy-MM-dd
 * @param format format as specified by `date-fns` and unicode standard
 * @returns Date formatted using swedish locale.
 */
export const reformatISODate = (date: string, format: string): string =>
  reformatDate(date, 'yyyy-MM-dd', format);

/**
 * Formats dates to common format yyyyMMdd-yyyyMMdd
 *
 * @param Object with start and end dates in yyyy-MM-dd, like in Period or FinancialYear
 * @returns A good old yyyyMMdd-yyyyMMdd formatted string
 */
export const datesToString = ({ start, end }: { start: string; end: string }) =>
  `${reformatDate(start, 'yyyy-MM-dd', 'yyyyMMdd')}-${reformatDate(
    end,
    'yyyy-MM-dd',
    'yyyyMMdd'
  )}`;

import React, { memo, useState, useMemo } from 'react';
import styled from '@emotion/styled';
import { Tooltip, TooltipProps } from '@material-ui/core';
import { useIntl } from 'react-intl';

// @ts-ignore
import { ReactComponent as AnnualReportIcon } from 'assets/program/AnnualReportIcon.svg';
// @ts-ignore
import { ReactComponent as FinancialReportIcon } from 'assets/program/FinancialReportIcon.svg';
// @ts-ignore
import { ReactComponent as FinancialStatementIcon } from 'assets/program/FinancialStatementIcon.svg';
// @ts-ignore
import { ReactComponent as TaxDeclarationIcon } from 'assets/program/TaxDeclarationIcon.svg';
import { translations } from '@agoy/program-status';
import { colors } from '@agoy/theme';
import config from '_shared/services/config';
import { PeriodStatusesType } from './utils';

const isFortnoxMode = config.whiteLabelUI === 'fortnox';

const StatusContainer = styled.div<{ count: number }>`
  display: grid;
  grid-template-columns: repeat(${({ count }) => count}, 1fr);
  grid-column-gap: 2px;
`;

const IconContainer = styled.div<{ color: string }>`
  display: flex;
  height: min-content;
  max-width: 31px;
  height: auto;
  justify-self: center;

  svg {
    color: ${({ theme }) =>
      isFortnoxMode ? theme.palette.grey[800] : 'white'};

    width: 100%;
    height: auto;

    path {
      fill: ${({ color }) => color} !important;
    }
  }
`;

const programStatusIcons = {
  AN_REPORT: <AnnualReportIcon />,
  FIN_STATEMENT_REPORT: <FinancialReportIcon />,
  FIN_STATEMENT: <FinancialStatementIcon />,
  TAX_DECLARATION: <TaxDeclarationIcon />,
};

type PeriodStatusProps = {
  periodStatuses?: PeriodStatusesType;
};

interface CustomTooltipProps extends TooltipProps {
  iconColor: string;
}

const CustomTooltip = ({
  iconColor,
  children,
  ...rest
}: CustomTooltipProps) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <IconContainer
      onMouseEnter={() => !showTooltip && setShowTooltip(true)}
      color={iconColor}
    >
      {!showTooltip && children}
      {showTooltip && <Tooltip {...rest}>{children}</Tooltip>}
    </IconContainer>
  );
};

const PeriodStatuses = ({ periodStatuses }: PeriodStatusProps) => {
  const { formatMessage } = useIntl();

  const filteredPeriodStatuses = useMemo(() => {
    if (periodStatuses) {
      const result = {};

      Object.keys(periodStatuses).forEach((key) => {
        if (key !== 'RECONCILIATION') {
          result[key] = periodStatuses[key];
        }
      });

      return result;
    }

    return {};
  }, [periodStatuses]);

  const statuses = Object.keys(filteredPeriodStatuses);

  return (
    <StatusContainer count={statuses.length}>
      {statuses.map((item) => (
        <CustomTooltip
          title={`${formatMessage({ id: item })} (${formatMessage(
            translations[filteredPeriodStatuses[item]]
          )})`}
          iconColor={colors[filteredPeriodStatuses[item]]}
          key={item}
        >
          {programStatusIcons[item]}
        </CustomTooltip>
      ))}
    </StatusContainer>
  );
};

export default memo(PeriodStatuses);

import { AgoyTable, AgoyTableColumn, AgoyTableRow } from '@agoy/document';
import React from 'react';
import {
  BaseRow,
  Table,
  TableBody,
  TableBodyCell,
  TableHead,
  TableHeadCell,
} from './TableComponents';
import { useIntl } from 'react-intl';
import { getClasses } from '@agoy/common';
import Cell from './Cell';
import Typography from '../../Typography/Typography';

const getColumnSize = (
  index: number,
  id: string,
  columnsLength: number
): string => {
  if (index === 0) {
    switch (columnsLength) {
      case 2:
        return '70%';
      case 3:
        return '60%';
      case 4:
        return '55%';
      default:
        return '30%';
    }
  }

  if (id === 'notes') {
    return '64px';
  }

  return 'auto';
};

export interface HierarchicalTableProps {
  tableId: string;
  table: AgoyTable;
  columnLabelFormatter?: (columnCell: AgoyTableColumn) => React.ReactNode;
  // Emil insisted on this
  level1ThiccRows?: boolean;
  compact?: boolean;
  caption?: string;
}

export type HierarchicalRowProps = {
  row: AgoyTableRow;
  columns: AgoyTableColumn[];
  compact: boolean;
  className?: string;
  hasPreviousYear?: boolean;
};

/**
 * if we only use empty row without td, BV fails with 'Empty content is not allowed'
 * @returns an empty row with a single empty cell
 */
const EmptyBaseRow = ({
  columns,
  compact,
}: Pick<HierarchicalRowProps, 'columns' | 'compact'>) => (
  <BaseRow className="spacer">
    {columns.map(({ id }) => (
      <TableBodyCell key={id} className={getClasses({ compact })}>
        <Typography variant="caption" margin="none" as="div">
          {' '}
        </Typography>
      </TableBodyCell>
    ))}
  </BaseRow>
);

const HierarchicalTableRow = ({
  row,
  columns,
  compact,
  className = '',
  hasPreviousYear = false,
}: HierarchicalRowProps) => {
  const subRows = row.rows ?? [];
  const rowType = row.type ?? 'row';

  if (rowType === 'spacer') {
    return <EmptyBaseRow columns={columns} compact={compact} />;
  }

  const classes = getClasses({
    compact,
  });

  return (
    <React.Fragment>
      {rowType !== 'hidden' && (
        <BaseRow className={`${rowType} ${className} ${classes}`}>
          {columns.map((col) => {
            const cell = row.cells?.[col.id];
            const key = `${row.id}.${col.id}`;

            if (!cell) {
              return <TableBodyCell key={key} />;
            }

            return (
              <TableBodyCell
                key={key}
                className={`${rowType} ${col.id} ${className} ${classes} ${
                  col.dataType ?? 'text'
                }`}
              >
                <Cell
                  cell={cell}
                  rowType={rowType}
                  hasPreviousYear={hasPreviousYear}
                />
              </TableBodyCell>
            );
          })}
        </BaseRow>
      )}
      {subRows.map((subRow) => (
        <React.Fragment key={subRow.id}>
          <HierarchicalTableRow
            row={subRow}
            columns={columns}
            compact={compact}
            hasPreviousYear={hasPreviousYear}
          />
        </React.Fragment>
      ))}
    </React.Fragment>
  );
};
/**
 * Print table
 *
 * @param table tabular data
 * @param tableId id of a table
 * @param columnLabelFormatter handles special cases of table headers
 * @param level1ThiccRows (don't mind the name) handles special cases of level 1 rows
 * @param compact compact tables have smaller paddings
 * @returns
 */
const HierarchicalPrintTable = ({
  table,
  tableId,
  columnLabelFormatter,
  level1ThiccRows = false,
  compact = false,
  caption,
}: HierarchicalTableProps) => {
  const { formatMessage } = useIntl();

  const defaultFormatter = (cell: AgoyTableColumn) => {
    if (cell.label && ['year', 'previousYear'].includes(cell.id)) {
      const formattedYear = cell.label.split(' ');
      return (
        <>
          <div>{formattedYear[0]}</div>
          <div>{formattedYear[1]}</div>
        </>
      );
    }

    return cell.label;
  };

  const getTableColumnLabel = (cell: AgoyTableColumn): React.ReactNode => {
    // Use the provided formatter or fallback to the default one
    const formatter = columnLabelFormatter || defaultFormatter;

    return (
      formatter(cell) ??
      formatMessage({
        id: `annualReport.${tableId}.${cell.id}`,
        defaultMessage: cell.id,
      })
    );
  };

  const { rows, columns } = table;

  const hasPreviousYearColumn = columns.some(
    (column) => column.id === 'previousYear'
  );

  const getColumnCount = (columns: AgoyTableColumn[]) => {
    const activeColumns = columns.filter(
      (column) => 'active' in column && column.active
    );
    return activeColumns.length > 0 ? activeColumns.length : columns.length;
  };

  return (
    <Table>
      {caption && (
        <Typography variant="caption" margin="none">
          {caption}
        </Typography>
      )}
      <TableHead>
        <BaseRow className="table-header">
          {columns.map((col, index) => (
            <TableHeadCell
              cellWidth={getColumnSize(index, col.id, getColumnCount(columns))}
              key={col.id}
              className={col.dataType ?? 'text'}
            >
              <Typography variant="h4" as="div" margin="none">
                {getTableColumnLabel(col)}
              </Typography>
            </TableHeadCell>
          ))}
        </BaseRow>
      </TableHead>
      <TableBody>
        {rows.map((row) => (
          <HierarchicalTableRow
            key={row.id}
            row={row}
            columns={columns}
            compact={compact}
            className={getClasses({
              uppercase:
                !!row.type &&
                ['header', 'sum', 'alwaysDisplaySum'].includes(row.type) &&
                level1ThiccRows,
              compact,
            })}
            hasPreviousYear={hasPreviousYearColumn}
          />
        ))}
        <EmptyBaseRow columns={columns} compact={compact} />
      </TableBody>
    </Table>
  );
};

export default HierarchicalPrintTable;

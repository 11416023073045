import React from 'react';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';
import { Typography } from '@material-ui/core';
import {
  SharesCompanyManagementReport,
  ResultsDisposition as ResultsDispositionType,
  EconomicAssociationManagementReport,
  isActive,
} from '@agoy/annual-report-document';
import { numberValue, stringValue } from '@agoy/document';
import Cell from '../../../../UI/CommonPrintTable/Cell/Cell';
import renderResultsDispositionRows from './util';

const GridTable = styled.div`
  width: 50%;
  display: block;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;

  > span {
    display: block;
    width: inherit;
  }

  > span:first-of-type {
    flex: 1;
  }
`;
interface ProposalSectionProps {
  managementReport:
    | SharesCompanyManagementReport
    | EconomicAssociationManagementReport;
  reportPart: ResultsDispositionType;
  isDigitalSubmission: boolean | null | undefined;
}

const ProposalSection = ({
  managementReport,
  reportPart,
  isDigitalSubmission,
}: ProposalSectionProps): JSX.Element | null => {
  const { formatMessage } = useIntl();

  const boardProposalHeader =
    stringValue(managementReport.resultsDisposition.boardProposalHeader) ||
    formatMessage({
      id: 'annualReport.menu.managementReport.boardProposal',
    });

  const customToTransfer =
    stringValue(managementReport.resultsDisposition.customToTransfer) ||
    formatMessage({
      id: 'annualReport.menu.managementReport.toTransfer',
    });

  const customInTotal =
    stringValue(managementReport.resultsDisposition.customInTotal) ||
    formatMessage({
      id: 'sum',
    });

  const dividend = numberValue(reportPart.proposal.rows[0].cells?.value);

  /**
   * If digital submission to BV, rows added by user are not displayed.
   */
  const rowsToDisplay = renderResultsDispositionRows(
    reportPart.proposal.rows,
    isDigitalSubmission
  );

  return (
    <>
      {reportPart.boardProposalHeader.active ? (
        <Typography variant="h4">{boardProposalHeader}</Typography>
      ) : null}

      <GridTable>
        {rowsToDisplay.map((row, index) => {
          if (index === 0 && !dividend) return null;
          return (
            isActive(row.active) &&
            row.cells?.label &&
            row.cells.value && (
              <Row key={row.id}>
                <Cell cell={row.cells?.label} />
                <Cell cell={row.cells?.value} />
              </Row>
            )
          );
        })}

        {reportPart.toTransfer.active ? (
          <Row>
            <span>
              <Typography variant="inherit">{customToTransfer}</Typography>
            </span>
            <Cell cell={reportPart.toTransfer} />
          </Row>
        ) : null}

        {reportPart.inTotal.active ? (
          <Row>
            <span>
              <Typography variant="inherit">{customInTotal}</Typography>
            </span>
            <Cell cell={reportPart.inTotal} />
          </Row>
        ) : null}
      </GridTable>
    </>
  );
};

export default ProposalSection;

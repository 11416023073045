import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { IxbrlField, Note95Section } from '@agoy/annual-report-document';
import { numberValue, stringValue } from '@agoy/document';
import { Note } from '../../UI/Note';
import Comment from '../../UI/Comment';
import Header from '../../UI/Header';
import NoteTable from '../../UI/NoteTable';
import { Cell } from '../../../../UI';

type Note95Props = {
  noteId: string;
  hasPreviousYear?: boolean;
  comment: IxbrlField;
  commentLabel: IxbrlField;
} & Note95Section;

const Note95 = ({
  noteId,
  hasPreviousYear,
  comment,
  commentLabel,
  ...note
}: Note95Props): JSX.Element | null => {
  const { active, number, tables, sumTable } = note;

  if (!active) return null;

  const title = stringValue(note.name) || '';

  return (
    <Note id={noteId}>
      <Header noteId={noteId} noteNumber={numberValue(number)} title={title} />
      {tables.sections.map((section, index) => {
        if (section === null || !('format' in section.type)) {
          return null;
        }

        const isCustomType = stringValue(section.type) === 'custom';

        // If the user leaves the custom year field empty, the value will be an empty string.
        if (isCustomType && section.customYear.value === '') {
          section.customYear.value = ' ';
          section.customPreviousYear.value = ' ';
        }

        return (
          <Box mt={1} mb={1} key={section.year.ixbrl?.tuple?.id}>
            <div style={{ display: 'none' }}>
              {isCustomType ? (
                <>
                  <Cell cell={section.customYear} />
                  <Cell cell={section.customPreviousYear} />
                </>
              ) : (
                <>
                  <Cell cell={section.previousYear} />
                  <Cell cell={section.year} />
                </>
              )}
            </div>
            <Typography>
              {!isCustomType
                ? section?.type?.format?.options?.find(
                    (option) => option?.value === stringValue(section?.type)
                  )?.label || ''
                : stringValue(section.customYear)}
            </Typography>

            {isCustomType ? (
              <NoteTable
                table={section.customTable}
                hasPreviousYear={hasPreviousYear}
                tableId={`notes.${noteId}.tables.${index}.customTable`}
              />
            ) : (
              <NoteTable
                table={section.table}
                hasPreviousYear={hasPreviousYear}
                tableId={`notes.${noteId}.tables.${index}.table`}
              />
            )}
          </Box>
        );
      })}
      <NoteTable
        table={sumTable}
        hasPreviousYear={hasPreviousYear}
        tableId={`notes.${noteId}.sumTable`}
        displayUnfiltered
      />
      <Comment
        field={comment}
        labelValue={stringValue(commentLabel) || ''}
        value={stringValue(comment) || ''}
      />
    </Note>
  );
};

export default Note95;

const helpStructureNE = {
  type: 'part' as const,
  _type: 'NE' as const,
  children: {
    improvements: { type: 'table' as const },
    renovation: { type: 'table' as const },
  },
};

export default helpStructureNE;

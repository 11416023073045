import { useContext, useLayoutEffect, useState } from 'react';
import { SystemMessagesContext } from '_shared/HOC/withSystemMessages';

const useHeaderHeight = (): number => {
  const { messages } = useContext(SystemMessagesContext);

  const [height, setHeight] = useState(64);

  useLayoutEffect(() => {
    const element = document.querySelector('#app-header');
    const rect = element?.getBoundingClientRect();
    setHeight(rect?.height || 64);
  }, [messages]);

  return height;
};

export default useHeaderHeight;

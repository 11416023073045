import React from 'react';
import styled from '@emotion/styled';
import Typography from '../../UI/Typography/Typography';
import { Typography as MuiTypography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import {
  AnnualReportVersion,
  IncomeStatement,
  Settings,
} from '@agoy/annual-report-document';
import { default as IncomeStatementTable } from '../../UI/BRRRPrintTable';
import HierarchicalPrintTable from '../../UI/HierarchicalPrintTable/v2/HierarchicalPrintTable';
import { filterRows } from '@agoy/document';
import { addPrintSpacers } from '../../utils/notes/addSpacers';

const Content = styled.div`
  page-break-before: always;

  @media screen {
    min-width: 100%;
    width: min-content;
  }

  @media print {
    width: 100%;
  }
`;

interface IncomeStatementPageProps {
  incomeStatement: IncomeStatement;
  settings: Settings;
  version: AnnualReportVersion;
}

const IncomeStatementPage = ({
  incomeStatement,
  settings,
  version,
}: IncomeStatementPageProps): JSX.Element => {
  const { formatMessage } = useIntl();

  const title = formatMessage({ id: 'annualReport.menu.incomeStatement' });

  return (
    <Content>
      {version === '2' ? (
        <>
          <Typography variant="h1">{title}</Typography>
          <HierarchicalPrintTable
            tableId="incomeStatement.section.table"
            table={addPrintSpacers(
              filterRows(incomeStatement.section.table, ['account'], false)
            )}
            compact
          />
        </>
      ) : (
        <>
          <MuiTypography variant="h1">{title}</MuiTypography>
          <IncomeStatementTable
            table={incomeStatement.section.table}
            tableId="incomeStatement.section.table"
            settings={settings}
          />
        </>
      )}
    </Content>
  );
};

export default IncomeStatementPage;

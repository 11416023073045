import React from 'react';
import { useIntl } from 'react-intl';
import { Grid, Button, Typography, Paper, Avatar } from '@material-ui/core';
import { VerifiedUser } from '@material-ui/icons';
import styled from '@emotion/styled';

import { ClientCompanyType } from '_clients/types/types';
import config from '_shared/services/config';

const Container = styled(Paper)`
  padding: ${(props) => props.theme.spacing(2)}px;
  min-width: 330px;
`;

const MemberAvatar = styled(Avatar)`
  height: 50px;
  width: 50px;
`;

const AccessBadge = styled.div<{ authorized?: boolean }>`
  display: flex;
  color: ${(props) =>
    props.authorized
      ? props.theme.palette.success.main
      : props.theme.palette.error.main};
`;

interface UserAccessListItemProps {
  member: Member.MemberType;
  updatingAccess: boolean;
  accessChangeHandler: () => void;
}

const isFortnoxWhiteLabel = config.whiteLabelUI === 'fortnox';

export const UserAccessListItem = ({
  member: { givenName, familyName, email, isAuthorisedEditedValue, picture },
  updatingAccess,
  accessChangeHandler,
}: UserAccessListItemProps): JSX.Element => {
  const intl = useIntl();

  const name =
    givenName && familyName ? `${givenName} ${familyName}` : undefined;

  return (
    <Container elevation={2}>
      <Grid container spacing={1}>
        <Grid item style={{ alignSelf: 'center' }}>
          <MemberAvatar src={picture || undefined} />
        </Grid>
        <Grid container direction="column" xs item>
          {name && (
            <Grid item>
              <Typography>{name}</Typography>
            </Grid>
          )}
          {email && (
            <Grid item>
              <Typography>{email}</Typography>
            </Grid>
          )}
          <Grid container justifyContent="space-between" item>
            <Grid container alignItems="center" item xs>
              {isAuthorisedEditedValue !== undefined && (
                <AccessBadge authorized={isAuthorisedEditedValue}>
                  <VerifiedUser fontSize="small" />
                </AccessBadge>
              )}
              <Typography variant="body2" component="span">
                {isAuthorisedEditedValue &&
                  intl.formatMessage({
                    id: 'dashboard.customers.edit.access',
                  })}
                {isAuthorisedEditedValue === false &&
                  intl.formatMessage({
                    id: 'dashboard.customers.edit.noAccess',
                  })}
              </Typography>
            </Grid>
            <Grid item>
              {!config.whiteLabel && !isFortnoxWhiteLabel && (
                <Button
                  color="primary"
                  size="small"
                  onClick={() => accessChangeHandler()}
                  disabled={updatingAccess}
                >
                  {isAuthorisedEditedValue
                    ? intl.formatMessage({
                        id: 'dashboard.customers.edit.removeAccess',
                      })
                    : intl.formatMessage({
                        id: 'dashboard.customers.edit.grantAccess',
                      })}
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

interface ClientAccessListItemProps {
  client: Partial<ClientCompanyType>;
  authorized: boolean;
  accessChangeHandler: () => void;
}

export const ClientAccessListItem = ({
  client,
  authorized,
  accessChangeHandler,
}: ClientAccessListItemProps): JSX.Element => {
  const intl = useIntl();

  return (
    <Container elevation={2}>
      <Grid container spacing={1}>
        <Grid item style={{ alignSelf: 'center' }}>
          <MemberAvatar src={client.logo ?? undefined} />
        </Grid>
        <Grid container direction="column" xs item>
          <Grid item>
            <Typography variant="body1">{client.name}</Typography>
          </Grid>
          <Grid container justifyContent="space-between" item>
            <Grid container alignItems="center" item xs>
              <AccessBadge authorized={authorized}>
                <VerifiedUser fontSize="small" />
              </AccessBadge>
              <Typography variant="body2" component="span">
                {authorized
                  ? intl.formatMessage({
                      id: 'dashboard.members.edit.access',
                    })
                  : intl.formatMessage({
                      id: 'dashboard.members.edit.noAccess',
                    })}
              </Typography>
            </Grid>
            <Grid item>
              {!config.whiteLabel && !isFortnoxWhiteLabel && (
                <Button
                  color="primary"
                  size="small"
                  onClick={() => accessChangeHandler()}
                >
                  {authorized
                    ? intl.formatMessage({
                        id: 'dashboard.customers.edit.removeAccess',
                      })
                    : intl.formatMessage({
                        id: 'dashboard.customers.edit.grantAccess',
                      })}
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default UserAccessListItem;

import { signatures } from '../common/types/signatures';

/**
 * Content definition for the stand-alone Årsstämmoprotokoll document.
 */
export const annualReportSignaturesContentDefinition = {
  type: 'document' as const,
  children: {
    signatures,
  },
  documentType: 'annualReportSignatures' as const,
};

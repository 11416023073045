import React, { useEffect, useState } from 'react';
import { TextField } from '@material-ui/core';

interface InputProps {
  placeholder: string;
  defaultValue: string;
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  className?: string;
  isLocked?: boolean;
}

const Input = ({
  placeholder,
  onBlur,
  defaultValue,
  className,
  isLocked = false,
}: InputProps): JSX.Element => {
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <TextField
      placeholder={placeholder || ''}
      value={value}
      onChange={handleChange}
      onBlur={onBlur}
      className={className}
      disabled={isLocked}
      fullWidth
      minRows={4}
      maxRows={4}
      variant="outlined"
      multiline
    />
  );
};

export default Input;

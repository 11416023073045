import React from 'react';
import { useIntl } from 'react-intl';
import { Typography } from '@material-ui/core';
import styled from '@emotion/styled';
import { CommonNotes } from '@agoy/annual-report-document';

const Section = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0.25rem 0;
`;

type ToggableSection = {
  type: 'field' | 'table';
  section: string;
  id: string;
};

type ToggleMultiSectionProps = {
  headingTranslationKey: string;
  toggleSections: ToggableSection[];
  notes: CommonNotes;
  children: React.ReactNode;
  title?: string;
};

const ToggleMultiSection = ({
  headingTranslationKey,
  toggleSections,
  notes,
  children,
  title,
}: ToggleMultiSectionProps) => {
  const { formatMessage } = useIntl();

  const active = toggleSections.every(
    (toggleSection) => notes[toggleSection.section][toggleSection.id]?.active
  );

  if (!active) {
    return null;
  }

  return (
    <>
      <Section>
        <Typography variant="h3">
          {title ||
            formatMessage({
              id: `annualReport.notes.${headingTranslationKey}`,
            })}
        </Typography>
      </Section>
      {active && children}
    </>
  );
};

export default ToggleMultiSection;

import React from 'react';
import { useIntl } from 'react-intl';
import { Typography } from '@material-ui/core';
import styled from '@emotion/styled';

import Chrome from 'assets/Chrome.svg';
import Safari from 'assets/safari.svg';
import Edge from 'assets/Edge.svg';
import Firefox from 'assets/firefox.svg';
import Email from 'assets/email-24px.svg';
import Phone from 'assets/phone-24px.svg';
import DrawnIlustration from 'assets/browserIllustration.svg';

const Wrapper = styled.div`
  height: 100vh;

  padding: 20px;

  background-color: white;

  display: flex;
  flex-direction: column;
`;

const Title = styled(Typography)`
  margin: ${(props) => props.theme.spacing(2)}px;
  text-align: left;
`;

const Subtitle = styled(Typography)`
  margin: ${(props) => props.theme.spacing(2)}px;
  text-align: left;
`;

const BrowserWrapper = styled.div`
  display: flex;
  align-self: flex-start;
  p {
    margin-left: 38px;
  }
`;

const Image = styled.img`
  height: 40px;
  width: 40px;
  margin-right: 40px;
  margin-left: 40px;
  margin-top: 60px;
`;

const BottomWrapper = styled.div`
  display: flex;
  align-self: flex-start;
`;

const BottomImage = styled.img`
  margin-top: 300px;
  margin-left: 30px;
  height: 24px;
  width: 24px;
`;

const BottomText = styled(Typography)`
  margin-top: 285px;
  margin-left: 8px;
`;

const Illustration = styled.img`
  margin-top: 170px;
  height: 160px;
  margin-left: 50px;
`;

const NonSuppBrowserSplash = () => {
  const { formatMessage } = useIntl();

  return (
    <Wrapper>
      <Title variant="h3">
        {formatMessage({ id: 'app.tsx.welcome.non.supp.browser' })}
      </Title>
      <Subtitle color="textSecondary">
        {formatMessage({ id: 'app.tsx.welcome.non.supp.browser2' })}
      </Subtitle>
      <BrowserWrapper>
        <div>
          <Image src={Chrome} alt="chromeBrowserIcon" />
          <p>Chrome</p>
        </div>
        <div>
          <Image src={Safari} alt="safariBrowserIcon" />
          <p>Safari</p>
        </div>
        <div>
          <Image src={Edge} alt="edgeBrowserIcon" />
          <p>Edge</p>
        </div>
        <div>
          <Image src={Firefox} alt="firefoxBrowserIcon" />
          <p>Firefox</p>
        </div>
      </BrowserWrapper>
      <BottomWrapper>
        <BottomImage src={Email} />
        <BottomText>
          <p>info@agoy.se</p>
        </BottomText>
        <BottomImage src={Phone} />
        <BottomText>
          <p>08-865 555</p>
        </BottomText>
        <Illustration src={DrawnIlustration} />
      </BottomWrapper>
    </Wrapper>
  );
};

export default NonSuppBrowserSplash;

import React from 'react';
import { useSelector } from 'redux/reducers';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import {
  differenceInCalendarMonths,
  differenceInMonths,
  sub,
  add,
} from 'date-fns';

import { format, parse } from '@agoy/dates';

import { FinancialReport } from '@agoy/financial-report-document';
import { AgoyTableRow, stringValue } from '@agoy/document';

import EditableTextBlock from '../../UI/EditableTextBlock';
import CashFlowDiagramChart from './CashFlowDiagram';
import CashFlowTable from './CashFlowTable';
import OrganisationLogo from '../../UI/OrganisationLogo';
import { formatDataInThousands, ReportColors } from '../../../../utils';

type ReportColorsProps = {
  customColor: string | undefined;
};

type SubheaderProps = {
  customTextColor: string | undefined;
};

const A4Page = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 960px;
  width: 1400px;
`;

const Subheader = styled(Typography, {
  shouldForwardProp: (prop) =>
    prop !== 'customColor' && prop !== 'customTextColor',
})<ReportColorsProps & SubheaderProps>`
  margin: 0;
  padding: 15px 30px;
  font-size: 2rem;
  font-weight: bold;
  color: ${(props) =>
    props.customTextColor || props.theme.palette.text.primary};
  background-color: ${(props) =>
    props.customColor ||
    props.theme.palette.financialReportView.accentBackground};
`;

const DataWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'customColor',
})<ReportColorsProps>`
  padding: 10px 30px;
  height: 100%;
  width: 85.5%;
  background-color: ${(props) =>
    props.customColor ||
    props.theme.palette.financialReportView.accentBackground};
  overflow: hidden;
`;

interface CashFlowStatementPageProps {
  cashFlowDiagram: FinancialReport['cashFlowDiagram'];
  reportColors: ReportColors;
  settings: FinancialReport['settings'];
}

const emptyData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

const generatePeriods = (period: string, start: string): string[] => {
  const periodEnd = parse(period, 'yyyyMMdd');
  let periodStart = parse(start, 'yyyyMMdd');
  const monthCount = differenceInMonths(periodEnd, periodStart);

  if (monthCount > 12) {
    periodStart = sub(periodEnd, { months: 11 });
  }

  return Array.from(Array(12)).map((el, index) => {
    return format(add(periodStart, { months: index }), 'MMM-yy');
  });
};

const generateDataIds = (period: string, start: string): string[] => {
  const periodEnd = parse(period, 'yyyyMMdd');
  const periodStart = parse(start, 'yyyyMMdd');
  let monthCount = 12;
  if (differenceInCalendarMonths(periodEnd, periodStart) < 12) {
    monthCount = differenceInCalendarMonths(periodEnd, periodStart) + 1;
  }
  const calcMonth = Array.from(Array(monthCount).keys()).map((item) =>
    sub(periodEnd, { months: item })
  );
  return calcMonth.reverse().map((item) => {
    return format(item, 'MMMyy');
  });
};

const prepareData = (
  row: AgoyTableRow,
  period: string,
  periodStart: string
): number[] => {
  if (!row || !row.cells) {
    return emptyData;
  }
  const ids = generateDataIds(period, periodStart);
  return ids.map((item) => {
    if (row.cells) {
      const cell = row.cells[item];
      if (cell && cell.type === 'ref' && typeof cell.value === 'number') {
        return cell.value;
      }
    }
    return 0;
  });
};

const generateStartPeriod = (date: string | undefined): string | undefined => {
  if (!date) {
    return undefined;
  }
  const parsedDate = parse(date, 'MMMyy');
  const startPeriod = format(parsedDate, 'yyyyMMdd');

  return startPeriod;
};

const CashFlowStatementPreview = ({
  cashFlowDiagram,
  reportColors,
  settings,
}: CashFlowStatementPageProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const { currentPeriod, currentFinancialYear } = useSelector(
    (state) => state.customerView
  );
  const title = formatMessage({ id: 'financialreport.cashFlowStatement' });

  const start = generateStartPeriod(
    cashFlowDiagram.section.result.columns[1].id
  );
  if (!currentPeriod || !currentFinancialYear || !start) {
    return <></>;
  }
  const periods = generatePeriods(currentPeriod, start);

  let cashFlowDataTable = prepareData(
    cashFlowDiagram.section.result.rows[0],
    currentPeriod,
    start
  );
  let cashFlowDataDiagram = cashFlowDataTable;
  if (settings.section.formatDiagramValuesInThousands) {
    cashFlowDataDiagram = formatDataInThousands(cashFlowDataDiagram);
  }
  if (settings.section.formatTableValuesInThousands) {
    cashFlowDataTable = formatDataInThousands(cashFlowDataTable);
  }
  return (
    <A4Page>
      <Subheader
        variant="h3"
        customColor={reportColors.background}
        customTextColor={reportColors.textSecondary}
      >
        {title}
      </Subheader>
      <DataWrapper customColor={reportColors.background}>
        <EditableTextBlock
          fieldId="cashFlowDiagram.section.secondaryText"
          defaultText={
            settings.section.isTextRandomizationEnabled
              ? stringValue(cashFlowDiagram.section.secondaryText) || ''
              : stringValue(cashFlowDiagram.section.secondaryTextStatic) || ''
          }
          isEditing={false}
          styles={{
            height: '110px',
            marginBottom: '5px',
            color: reportColors.textSecondary,
          }}
          print
        />
        <CashFlowDiagramChart
          periods={periods}
          data={cashFlowDataDiagram}
          height={660}
          print
        />
        <CashFlowTable periods={periods} data={cashFlowDataTable} print />
      </DataWrapper>
      <OrganisationLogo print />
    </A4Page>
  );
};

export default CashFlowStatementPreview;

import { FinancialReportStructure } from '../../types';

export const tableOfContentsConfig =
  (): FinancialReportStructure['tableOfContents'] => {
    return {
      section: {
        active: true,
      },
    };
  };

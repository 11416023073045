import React from 'react';
import styled from '@emotion/styled';

const Icon = styled.div<{
  statusColor: string;
  small?: boolean;
  detailIcon?: boolean;
}>`
  display: flex;
  align-self: ${({ detailIcon }) => (detailIcon ? 'baseline' : 'center')};
  margin-right: ${({ detailIcon }) => (detailIcon ? '4px' : '0px')};
  // Rule for the TaxDeclaration icon which adds a weird margin
  width: ${({ small }) => (small ? '20px' : '24px')};
  height: ${({ small }) => (small ? '20px' : '24px')};
  color: ${({ theme }) => theme.palette.common.white};
  border-radius: 50%;
  background-color: ${({ statusColor }) => statusColor};

  path:first-of-type {
    fill: ${({ statusColor }) => statusColor};
  }

  > * {
    border-radius: 50%;
    // Rule for the TaxDeclaration icon which adds a weird margin
    width: ${({ small }) => (small ? '99%' : '100%')};
    height: 100%;
  }
`;

export type StatusDotProps = {
  statusColor: string;
  icon?: JSX.Element;
  small?: boolean;
  detailIcon?: boolean;
};

export const StatusIcon = ({
  statusColor,
  icon,
  small,
  detailIcon,
}: StatusDotProps): JSX.Element => {
  return (
    <Icon statusColor={statusColor} small={small} detailIcon={detailIcon}>
      {icon}
    </Icon>
  );
};

export default StatusIcon;

import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import styled from '@emotion/styled';

import {
  AccountPlanList,
  ReferencesTypes,
  VoucherStatusType,
  VoucherTransaction,
} from 'types/Voucher';
import CommonPreviewModal from '../../CommonPreviewModal';
import PreviewVoucherContent from './PreviewVoucherContent';
import { Document } from '../Documents';

interface PreviewVoucherModalProps {
  selectedDate: string;
  voucherDescription: string;
  transactions: VoucherTransaction[];
  seriesNumber: string;
  open: boolean;
  documents: Document[];
  accounts?: AccountPlanList[];
  title?: string;
  status?: VoucherStatusType;
  reference?: ReferencesTypes;
  printOrientation?: 'landscape' | 'portrait';
  onClose: () => void;
}

const PrintWrapper = styled.div`
  @media print {
    padding: 32px;
    size: portrait;
  }
`;

const PreviewVoucherModal = ({
  selectedDate,
  voucherDescription,
  transactions,
  accounts = [],
  documents = [],
  printOrientation,
  open,
  title,
  status,
  seriesNumber,
  onClose,
  reference,
}: PreviewVoucherModalProps) => {
  const voucherRef = useRef(null);

  const printComponent = useReactToPrint({
    content: () => voucherRef.current,
  });

  return (
    <CommonPreviewModal
      isOpen={open}
      handleShow={onClose}
      onPrint={printComponent}
      printOrientation={printOrientation}
    >
      <PrintWrapper ref={voucherRef}>
        <PreviewVoucherContent
          selectedDate={selectedDate}
          voucherDescription={voucherDescription}
          transactions={transactions}
          accounts={accounts}
          documents={documents}
          title={title}
          status={status}
          seriesNumber={seriesNumber}
          reference={reference}
        />
      </PrintWrapper>
    </CommonPreviewModal>
  );
};

export default PreviewVoucherModal;

import styled from '@emotion/styled';
import { Typography, Tooltip } from '@material-ui/core';
import React, { memo, useContext } from 'react';
import { useIntl } from 'react-intl';
import IntlMessageFormat from 'intl-messageformat';
import { Cell as CellType, ResolvedReference } from '@agoy/document';
import {
  NumberFormatType,
  StandardNumberFormatType,
  ccyFormat,
  ccyParse,
  formatPercentage,
  parsePercentage,
  mapRecord,
} from '@agoy/common';
import { FormattingContext } from '@agoy/formatting';
import { Info } from '@material-ui/icons';
import NoteSelector from '_shared/components/CommonTable/Cell/Field/NoteSelector';
import NumberField from './NumberField';
import StringField from './StringField';
import NumberCellWithHover from './CellWithHover';

interface CellProps {
  className?: string;
  id: string;
  cell: CellType;
  editing?: boolean;
  numberFormatType?: NumberFormatType;
  active?: boolean;
  warning?: string;
  tooltip?: string;
  accountRangeTooltip?: string;
  shouldShowHoveredValues?: boolean;
  showHoveredValues?: boolean;
  sumOrResultCell?: boolean;
}

const NumberCell = styled.div<{
  warning?: string;
}>`
  position: relative;
  display: inline-block;
  text-align: right;
  width: 100%;

  ${({ warning, theme }) => {
    return warning ? `color: ${theme.palette.error.main}` : '';
  }};
`;

const TooltipContainer = styled.div`
  white-space: pre-wrap;
`;

const StringCell = styled.span`
  word-break: break-word;
`;

const EditModeCellWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

interface MessageCellProps {
  className?: string;
  id: string;
  message: string;
  parameters: Record<string, ResolvedReference> | undefined;
  editing?: boolean;
  references?: Record<string, string> | undefined;
}

const MessageCell = ({
  id,
  message,
  parameters,
  className,
  editing,
  references,
}: MessageCellProps) => {
  const value =
    references && !parameters
      ? ''
      : new IntlMessageFormat(message).format(
          mapRecord(parameters || {}, (v) =>
            typeof v === 'object' || v === undefined ? '' : v
          )
        );
  if (editing && typeof value === 'string') {
    return <StringField id={id} value={value} className={className} />;
  }
  return <span className={className}>{value}</span>;
};

interface NonReferenceCellProps extends Omit<CellProps, 'numberFormatType'> {
  numberFormatType: NumberFormatType;
}

const NonReferenceCell = memo(
  ({
    className,
    id,
    cell,
    editing,
    numberFormatType,
    active,
    warning,
    tooltip = '',
    accountRangeTooltip = '',
    shouldShowHoveredValues,
    showHoveredValues = false,
    sumOrResultCell,
  }: NonReferenceCellProps) => {
    const { type, precision } = numberFormatType;
    const isPercentage = type === 'percentage';
    const formattingContext = useContext(FormattingContext);

    const numberFormat = isPercentage ? formatPercentage : ccyFormat;
    const numberParser = isPercentage ? parsePercentage : ccyParse;

    if (cell.type === 'label') {
      return <StringCell className={className}>{cell.value}</StringCell>;
    }
    if (cell.type === 'number') {
      if (editing) {
        return (
          <NumberField
            className={className}
            id={id}
            value={active ? cell.value : undefined}
            formatter={numberFormat}
            parser={numberParser}
            displayDecimals={precision}
            InputProps={{ disabled: !active }}
            warning={warning}
          />
        );
      }
      const displayValue = formattingContext.formatNumber(cell.value, type);

      if (shouldShowHoveredValues) {
        return (
          <NumberCellWithHover
            active={active}
            displayValue={displayValue}
            cellValue={cell.value}
            className={className}
            numberFormat={numberFormat}
            showHoveredValues={showHoveredValues}
            warning={warning}
            sumCell={sumOrResultCell}
          />
        );
      }

      return warning || tooltip ? (
        <Tooltip title={warning || tooltip} placement="bottom-end">
          <NumberCell className={className} warning={warning}>
            {!active || cell.value === undefined
              ? ''
              : numberFormat(displayValue, precision)}
          </NumberCell>
        </Tooltip>
      ) : (
        <NumberCell className={className}>
          {!active || cell.value === undefined
            ? ''
            : numberFormat(displayValue, precision)}
        </NumberCell>
      );
    }

    const TooltipText = (
      <TooltipContainer>{accountRangeTooltip}</TooltipContainer>
    );

    if (cell.type === 'string') {
      if (editing) {
        return (
          <EditModeCellWrapper>
            <StringField
              className={className}
              id={id}
              value={active ? cell.value : ''}
              disabled={!active}
            />
            {accountRangeTooltip.length > 0 && (
              <Tooltip title={TooltipText} placement="bottom">
                <Info color="primary" />
              </Tooltip>
            )}
          </EditModeCellWrapper>
        );
      }

      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <StringCell
            className={className}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {active ? cell.value : ''}
          </StringCell>
          {accountRangeTooltip.length > 0 && (
            <Tooltip title={TooltipText} placement="bottom">
              <Info color="primary" />
            </Tooltip>
          )}
        </div>
      );
    }
    return null;
  }
);

interface ReferenceCellProps
  extends Omit<CellProps, 'numberFormatType' | 'cell'> {
  numberFormatType: NumberFormatType;
  value: ResolvedReference;
  active: boolean;
  warning?: string;
}

const ReferenceCell = memo(
  ({
    value,
    className,
    id,
    editing,
    numberFormatType,
    active,
    warning,
    tooltip = '',
    shouldShowHoveredValues,
    showHoveredValues,
    sumOrResultCell,
  }: ReferenceCellProps) => {
    const { formatMessage } = useIntl();

    const formattingContext = useContext(FormattingContext);
    const { type, precision } = numberFormatType;
    const isPercentage = type === 'percentage';

    const numberFormat = isPercentage ? formatPercentage : ccyFormat;
    const numberParser = isPercentage ? parsePercentage : ccyParse;

    if (editing) {
      return (
        <NumberField
          className={className}
          id={id}
          value={typeof value === 'number' && active ? value : undefined}
          formatter={numberFormat}
          parser={numberParser}
          displayDecimals={precision}
          InputProps={{ disabled: !active }}
          warning={warning}
        />
      );
    }
    if (value === undefined || typeof value === 'boolean') {
      return null;
    }
    if (typeof value === 'string') {
      return <StringField className={className} id={id} value={value} />;
    }
    if (typeof value === 'object') {
      return (
        <Typography
          color="error"
          className={`${className || ''} error`}
          style={{ textAlign: 'right' }}
        >
          {formatMessage({
            id: `ref.error.${value.error}`,
          })}
        </Typography>
      );
    }

    const displayValue = formattingContext.formatNumber(value, type);

    if (shouldShowHoveredValues) {
      return (
        <NumberCellWithHover
          active={active}
          displayValue={displayValue}
          cellValue={displayValue}
          className={className}
          numberFormat={numberFormat}
          showHoveredValues={showHoveredValues}
          sumCell={sumOrResultCell}
          warning={warning}
        />
      );
    }

    const TooltipText = <TooltipContainer>{tooltip}</TooltipContainer>;

    return warning || tooltip ? (
      <Tooltip title={warning || TooltipText} placement="bottom-end">
        <NumberCell className={className} warning={warning}>
          {active && numberFormat(displayValue, precision)}
        </NumberCell>
      </Tooltip>
    ) : (
      <NumberCell className={className}>
        {active && numberFormat(displayValue, precision)}
      </NumberCell>
    );
  }
);

const Cell = ({
  className,
  id,
  cell,
  editing,
  numberFormatType = StandardNumberFormatType,
  active = true,
  warning,
  tooltip,
  accountRangeTooltip,
  shouldShowHoveredValues = true,
  showHoveredValues,
  sumOrResultCell,
}: CellProps) => {
  if (!cell) {
    return null;
  }

  if (cell.type === 'refs') {
    return (
      <NoteSelector
        className={className}
        cell={cell}
        editing={editing}
        cellId={id}
        active={active}
      />
    );
  }

  if (cell.type === 'ref') {
    const { value } = cell;
    return (
      <ReferenceCell
        value={value}
        className={className}
        id={id}
        editing={editing}
        numberFormatType={numberFormatType}
        active={active}
        warning={warning}
        tooltip={tooltip}
        showHoveredValues={showHoveredValues}
        shouldShowHoveredValues={shouldShowHoveredValues}
        sumOrResultCell={sumOrResultCell}
      />
    );
  }
  if (cell.type === 'msg') {
    return (
      <MessageCell
        id={id}
        message={cell.message}
        parameters={cell.parameterValues}
        editing={editing}
        references={cell.parameterReferences}
      />
    );
  }
  return (
    <NonReferenceCell
      className={className}
      id={id}
      cell={cell}
      editing={editing}
      numberFormatType={numberFormatType}
      active={active}
      warning={warning}
      tooltip={tooltip}
      accountRangeTooltip={accountRangeTooltip}
      shouldShowHoveredValues={shouldShowHoveredValues}
      showHoveredValues={showHoveredValues}
      sumOrResultCell={sumOrResultCell}
    />
  );
};

export default Cell;

import { HiddenRowType } from './HiddenRowsContext';
import { Row } from './types';

/**
 * Injects rows of type 'hidden' recursively by account number.
 *
 * @param rows
 * @param hiddenRows
 * @returns Rows with hidden rows.
 */
const injectHiddenRows = (
  rows: Row[],
  hiddenRows: Record<string, HiddenRowType>,
  parentGroupId?: string
): Row[] => {
  let modified = false;
  const newRows = rows.reduce((result: Row[], row) => {
    if (
      row.type === 'account' &&
      hiddenRows[row.accountNumber] &&
      hiddenRows[row.accountNumber].groupId === parentGroupId
    ) {
      modified = true;
      result.push(row, hiddenRows[row.accountNumber].row);
    } else if (row.type === 'groupSum' && hiddenRows[row.id]) {
      modified = true;
      result.push(row, hiddenRows[row.id].row);
    } else if (row.type === 'group') {
      const newSubRows = injectHiddenRows(row.rows, hiddenRows, row.id);
      if (newSubRows !== row.rows) {
        modified = true;
        result.push({ ...row, rows: newSubRows });
      } else {
        result.push(row);
      }
    } else {
      result.push(row);
    }

    return result;
  }, []);

  return modified ? newRows : rows;
};

export default injectHiddenRows;

import React from 'react';
import Alert from '@material-ui/lab/Alert';

interface PropTypes {
  children: React.ReactNode;
}

const InformationBanner = ({ children }: PropTypes): JSX.Element => {
  return <Alert severity="info">{children}</Alert>;
};

export default InformationBanner;

import React, { useCallback, useMemo, useRef } from 'react';

export type DragOverGroup = {
  groupId: string;
  periodStart: string;
  isHeader?: boolean;
} | null;

type DndContextType = {
  setDragOverGroup: (group: DragOverGroup) => void;
};

type DndContextProviderProps = React.PropsWithChildren<DndContextType>;

const DndContext = React.createContext<DndContextType>({
  setDragOverGroup: () => {},
});

export const DndContextProvider = ({
  setDragOverGroup,
  children,
}: DndContextProviderProps) => {
  const lastValueRef = useRef<DragOverGroup>(null);

  const handleSetDragOverGroup = useCallback(
    (group: DragOverGroup) => {
      const lastValue = lastValueRef.current;

      if (
        lastValue &&
        lastValue.groupId === group?.groupId &&
        lastValue.periodStart === group?.periodStart
      ) {
        return;
      }

      lastValueRef.current = group;

      setDragOverGroup(group);
    },
    [setDragOverGroup]
  );

  const value = useMemo(
    () => ({ setDragOverGroup: handleSetDragOverGroup }),
    [handleSetDragOverGroup]
  );

  return <DndContext.Provider value={value}>{children}</DndContext.Provider>;
};

export default DndContext;

import React from 'react';
import { AgoyTable } from '@agoy/document';
import { isActive } from '@agoy/annual-report-document';
import { NumberFormatType } from '@agoy/common';
import { Table as PrintTable } from '../../../UI/CommonPrintTable';
import { stripUserAddedRows } from '../../../utils/table';

interface YearTableProps {
  tableId: string;
  table: AgoyTable;
  numberFormat?: (rowId: string) => NumberFormatType;
  isDigitalSubmission: boolean | null | undefined;
}

const YearTable = ({
  tableId,
  table,
  numberFormat,
  isDigitalSubmission,
}: YearTableProps): JSX.Element | null => {
  const currentRows = isDigitalSubmission
    ? stripUserAddedRows(table.rows)
    : table.rows;

  if (!table) return null;

  if (currentRows.every((row) => isActive(row.active) === false)) {
    return null;
  }

  return (
    <PrintTable
      baseId="annualReport"
      tableId={tableId}
      rows={currentRows}
      columns={table.columns.filter((column) => !column.id.includes('Warning'))}
      numberFormat={numberFormat}
    />
  );
};

export default YearTable;

import React from 'react';
import { Notes, isActive } from '@agoy/annual-report-document';
import { Field, stringValue } from '@agoy/document';
import FieldSection from '../../UI/FieldSection';

type ToggableSection = {
  type: 'field' | 'table';
  section: string;
  id: string;
  active: Field['active'];
};

type EmployeesToggleProps = {
  toggleSections: ToggableSection[];
  notes: Notes;
  children: React.ReactNode;
};

const EmployeesToggle = ({
  toggleSections,
  notes,
  children,
}: EmployeesToggleProps) => {
  const { noEmployees: noEmployeesLabel } = notes.note2;

  // Check the passed in toggle sections, this iterates over the
  // two fields that will be active if the company has had employees
  const companyHadEmployees = toggleSections.every((toggleSection) =>
    isActive(notes[toggleSection.section][toggleSection.id].active)
  );

  return (
    <>
      {companyHadEmployees && children}
      {!companyHadEmployees && (
        <FieldSection value={stringValue(noEmployeesLabel) || ''} />
      )}
    </>
  );
};

export default EmployeesToggle;

// helpers for config
// see also resolveReference.ts and its tests
import { ConfigType } from '@agoy/common';
import { AccountValueType } from './types';

const fn =
  (operator: string) =>
  (...args: (string | number)[]): string =>
    `${operator}(${args.join(',')})`;

export const multiply = fn('multiply');
export const div = fn('div');
// sum is to add references, to subtract we usually do a sum too but with
// but with multiply(-1, ref()) on the references we want negative values
export const sum = fn('sum');

export const formatDate = fn('formatDate');

// sumAllowNull is used for tax declarations where we want to sum up expanded
// ids with null values, i ex sum(id('K10-*.K10.fields.X))
export const sumAllowNull = fn('sumAllowNull');
export const or = fn('or');
export const max = fn('max');
export const min = fn('min');
export const id = (id: string) => `id(${id})`;
// We use multiplyPercentage to multiply a percentage with a reference * (value / 100)
export const multiplyPercentage = (...args: (string | number)[]) =>
  multiply(...args, 0.01);

// sumAllowEmpty is used for tax declarations where we want to sum up multiple values
// where some ids can be undefined
export const sumAllowEmpty = fn('sumAllowEmpty');

/**
 * You can use account() to sum one account or multiple account ranges
 * ex: (1000:1002+1004)
 * @name: The account range you want to use as a string
 * @modifier if not specified, it will return the outgoing balance for the account
 * @periodname e.g 'year' or 'previousYear'
 */
export const account = (
  name: string,
  modifier?: AccountValueType,
  periodName?: string
) =>
  `account(${name}${modifier ? `,${modifier}` : ''}${
    periodName ? `,period(${periodName})` : ''
  })`;

/**
 * accountName() gives you the name of an account.
 * Can be used with a reference to another cell/field with
 * the account number.
 *
 * Examples:
 * * accountName(id(myAccountNumber))
 * * accountName(1010)
 */
export const accountName = (accountNumber: string): string =>
  `accountName(${accountNumber})`;

export const round = (arg: string) => `round(${arg})`;
export const floor = (arg: string) => `round(sum(-0.5,${arg}))`;
export const abs = (arg: string) => `abs(${arg})`;
export const not = (arg: string) => `not(${arg})`;

/**
 * ifOrElse() returns one value if the condition is true, another if false.
 *
 * Examples:
 * * ifOrElse(isSmallerThan(100, 200), "A", "B")
 */
export const ifOrElse = (
  ifStatement: string,
  thenArg: string,
  elseArg: string | undefined
) => `if(${ifStatement},${thenArg},${elseArg})`;

/**
 * isSmallerThan() checks whether the first argument is smaller than the second argument.
 * Can be used for numerical comparisons in various financial calculations or logic flows.
 *
 * Examples:
 * * isSmallerThan(100, 200) // returns true
 *
 * It can be combined with other functions like ifOrElse to create conditional logic.
 * Examples with ifOrElse:
 * * ifOrElse(isSmallerThan(value1, value2), "doThis", "doThat")
 *
 * Note: If the two numbers are equal, it will return true by design, allowing for
 * comparisons where the "equal to" case should also pass.
 */
export const isSmallerThan = (arg1: string, arg2: string) =>
  `isSmallerThan(${arg1},${arg2})`;

/**
 * isGreaterThan() checks whether the first argument is greater than the second argument.
 * Can be used for numerical comparisons in various financial calculations or logic flows.
 *
 * Examples:
 * * isGreaterThan(200, 100) // returns true
 *
 * This function can also be combined with other functions like ifOrElse to create conditional logic.
 * Examples with ifOrElse:
 * * ifOrElse(isGreaterThan(value1, value2), "doThis", "doThat")
 *
 * Note: If the two numbers are equal, it will return false, only passing in cases where
 * the first argument is strictly greater than the second.
 */
export const isGreaterThan = (arg1: string, arg2: string) =>
  `isGreaterThan(${arg1},${arg2})`;

/**
 * limitMin() ensures that a value does not fall below a specified minimum threshold.
 * If the value is below the minimum, the minimum is returned; otherwise, the value is returned.
 * This is useful for calculations where a minimum boundary is required, such as ensuring that
 * a financial calculation does not result in a negative value.
 *
 * Examples:
 * * limitMin(10, 5) // returns 10, because 10 is greater than 5
 * * limitMin(3, 5)  // returns 5, because 3 is less than the minimum of 5
 * * limitMin(-10, 0) // returns 0, ensuring no negative result
 *
 * Real-World Use Case:
 * In tax calculations, ensure that a deduction value does not go below zero:
 * * limitMin(sumAllowNull(deduction1, deduction2), 0) // returns 0 if the sum is negative
 */
export const limitMin = (arg1: string, arg2: string) =>
  `limitMin(${arg1},${arg2})`;

/**
 * limitMax() ensures that a value does not exceed a specified maximum threshold.
 * If the value exceeds the maximum, the maximum is returned; otherwise, the value is returned.
 * This is useful for calculations where an upper limit is required, such as capping a tax deduction
 * or ensuring that an income cap is not exceeded.
 *
 * Examples:
 * * limitMax(10, 15) // returns 10, because 10 is within the maximum of 15
 * * limitMax(20, 15) // returns 15, because 20 exceeds the maximum allowed of 15
 * * limitMax(0, 10)  // returns 0, because it is within the allowed range
 *
 * Real-World Use Case:
 * In financial reporting, ensure that a calculated income does not exceed a legal cap:
 * * limitMax(calculatedIncome, incomeCap) // limits income to incomeCap if it exceeds this value
 */
export const limitMax = (arg1: string, arg2: string) =>
  `limitMax(${arg1},${arg2})`;

/**
 * config(name) resolves a value from the ResolveReferenceContext.resolveConfig.
 *
 * Bokslut has its own set of names. See `@agoy/tax-document`
 * Skatt uses the annualConfig from `@agoy/common`.
 *
 * @param name
 * @returns
 */
export const config = (name: keyof ConfigType): string => `config(${name})`;

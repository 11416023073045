import { makeStyles } from '@material-ui/core';

const tooltipStyles = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.fortnoxTooltip.background,
    borderRadius: theme.spacing(1),
    padding: `${theme.spacing(0.5)}px ${theme.spacing(2)}px`,
  },
  arrow: {
    color: theme.palette.fortnoxTooltip.background,
  },
}));

export default tooltipStyles;

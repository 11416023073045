import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Typography, Button } from '@material-ui/core';
import { useForm } from 'react-hook-form';

import { CTAButton } from '_shared/components/Buttons';
import FormError from '_shared/components/Inputs/FormError';
import useAuth from '_authentication/hooks/useAuth';
import { LoginBox, TextField, ActionsWrapper } from './SharedComponents';

interface Props {
  backToConfirm: (username?) => void;
  email: string;
}

const ResendCodeContent: React.FC<Props> = ({
  backToConfirm,
  email,
}: Props) => {
  const { formatMessage } = useIntl();
  const msg = (id: string) => formatMessage({ id });

  const auth = useAuth();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const [submitError, setSubmitError] = useState('');

  const isRequired = {
    required: msg('form.fieldRequired'),
  };

  const submitConfirmation = async (data) => {
    try {
      await auth.resendConfirmationAWS(data.username);
      backToConfirm(data);
    } catch (error) {
      backToConfirm(data);
      setSubmitError(msg('confirm.error'));
    }
  };

  return (
    <LoginBox onSubmit={handleSubmit(submitConfirmation)}>
      <Typography variant="h2">{msg('confirm.title')}</Typography>
      <Typography>{msg('confirm.resendCodeText')}</Typography>
      <TextField
        {...register('username', isRequired)}
        variant="outlined"
        size="small"
        defaultValue={email || ''}
        placeholder={msg('email.placeholder')}
        type="email"
      />
      <FormError>{errors.username?.message}</FormError>
      {submitError && <FormError>{submitError}</FormError>}
      <ActionsWrapper>
        <Button onClick={() => backToConfirm()}>{msg('forgot.back')}</Button>
        <CTAButton type="submit" loading={auth.isLoading}>
          {msg('confirm.verify')}
        </CTAButton>
      </ActionsWrapper>
    </LoginBox>
  );
};

export default ResendCodeContent;

import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';
import { Tooltip, TextField as MuiTextField } from '@material-ui/core';
import { AnnualReportType } from '@agoy/annual-report-document';
import { default as EditFieldBase } from '_shared/components/Inputs/EditField';
import isPropValid from '@emotion/is-prop-valid';
import AnnualReportViewServiceContext from '_annual-report/service/AnnualReportViewServiceContext';
import { TypographyProps } from '_shared/components/Typography/Typography.types';
import Typography from '_shared/components/Typography/Typography';

interface HeadingProps {
  id?: string;
  title: string | React.ReactElement;
  variant?: TypographyProps['variant'];
  controls?: React.ReactElement | undefined;
  tooltip?: string;
  reportType?: AnnualReportType;
  noteId?: string;
  editing?: boolean;
  noteNumber?: number | undefined;
  isDigitalSubmission?: boolean;
  part?: string;
}

const HeadingWithControls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  min-width: 800px;
`;

const Controls = styled.div`
  flex-shrink: 0;
`;

const TextFieldBase = (props) => {
  return <MuiTextField variant="outlined" size="small" {...props} />;
};

const SingleLineTextField = styled(TextFieldBase, {
  shouldForwardProp: (prop) => isPropValid(prop),
})`
  width: 100%;
`;

const Heading = ({
  title,
  variant = 'h2',
  controls,
  id,
  tooltip,
  noteId,
  editing,
  noteNumber,
  isDigitalSubmission = false,
  part,
}: HeadingProps) => {
  const intl = useIntl();
  const service = useContext(AnnualReportViewServiceContext);

  const onFieldChange = (value) => {
    const fullId = noteId ? `notes.${noteId}.${id}` : `${part}.${id}`;
    service.updateField(fullId, value);
  };

  if (controls) {
    return (
      <Tooltip
        title={tooltip ? intl.formatMessage({ id: tooltip }) : ''}
        placement="right"
      >
        <HeadingWithControls id={id}>
          {editing && !isDigitalSubmission ? (
            <EditFieldBase
              component={SingleLineTextField}
              value={title}
              onChange={onFieldChange}
            />
          ) : (
            <Typography variant={variant}>
              {noteNumber ? `${noteNumber} ${title}` : title}
            </Typography>
          )}
          <Controls>{controls}</Controls>
        </HeadingWithControls>
      </Tooltip>
    );
  }

  return (
    <Typography variant={variant}>
      {noteNumber ? `${noteNumber} ${title}` : title}
    </Typography>
  );
};

export default Heading;

/*
 * This is a GENERATED file, do not change manually!
 * Generated:
 * - from file k2-arsredovisning-2021-10-31-rev20230131_sv - Noter.csv
 * - Comments: nan
 */

import {
  value,
  ref,
  sumAllowNull,
  id,
  AgoyDocumentPart,
} from '@agoy/document';
import { IxbrlCell } from '../../../../../common';
import {
  field,
  ixbrlCell,
  ixbrlField,
  table,
} from '../../../../../common/utils/util';
import { previousYear, year } from '../../../../../common/utils/date-util';
import {
  sumAccountsIBs,
} from '../../../../../common/utils/accountSumsUtil';

const cell = { type: 'field' as const };
const boolean = { type: 'boolean' as const };
const type = { type: 'type' as const };
const number = { type: 'number' as const };

export const getNote2061K2v2 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null
): AgoyDocumentPart<typeof note2061K2v2ContentDefinition> => ({
  active: false,
  number: value(undefined),
  name: noteLabel('Andra långfristiga fordringar'),
  data: {
    active: true,
    AndraLangfristigaFordringarAbstract: {
      type: 'group',
      active: true,
      level: 0,
      title: field('Andra långfristiga fordringar'),
      table: table<IxbrlCell>(
        'notes.note61.data.AndraLangfristigaFordringarAbstract.table',
        {
          id: 'AndraLangfristigaFordringarAbstract',
          label: '',
          dataType: 'text',
          active: true,
        },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) ?? '',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) ?? '',
          dataType: 'numeric',
          active: true,
        }
      )
        .addRows((rows) => {
          rows
            .addRowWithType(
              'AndraLangfristigaFordringarAbstract',
              'hidden',
              undefined,
              undefined,
              undefined
            )
            .addSubRows((rows) => {
              rows.addRowWithType(
                'AndraLangfristigaFordringarAnskaffningsvardenIngaende',
                'sum_accounts',
                noteLabel('Ingående anskaffningsvärden'),
                ixbrlCell(ref(sumAccountsIBs('1380:1388', false)), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:AndraLangfristigaFordringarAnskaffningsvarden',
                  standardRubrik:
                    'Anskaffningsvärden andra långfristiga fordringar',
                  saldo: 'debit',
                  negateValue: false,
                }),
                ixbrlCell(
                  ref(sumAccountsIBs('1380:1388', false, 'previousYear')),
                  {
                    contextRef: 'balans2',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:AndraLangfristigaFordringarAnskaffningsvarden',
                    standardRubrik:
                      'Anskaffningsvärden andra långfristiga fordringar',
                    saldo: 'debit',
                    negateValue: false,
                  }
                )
              );
              rows
                .addRowWithType(
                  'AndraLangfristigaFordringarForandringAnskaffningsvardenAbstract',
                  'header',
                  noteLabel('Förändringar av anskaffningsvärden'),
                  undefined,
                  undefined
                )
                .addSubRows((rows) => {
                  rows.addRowWithType(
                    'AndraLangfristigaFordringarForandringAnskaffningsvardenTillkommandeFordringar',
                    'row',
                    noteLabel('Tillkommande fordringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndraLangfristigaFordringarForandringAnskaffningsvardenTillkommandeFordringar',
                      standardRubrik:
                        'Ökning av anskaffningsvärden i samband med periodens tillkommande andra långfristiga fordringar',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndraLangfristigaFordringarForandringAnskaffningsvardenTillkommandeFordringar',
                      standardRubrik:
                        'Ökning av anskaffningsvärden i samband med periodens tillkommande andra långfristiga fordringar',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'AndraLangfristigaFordringarForandringAnskaffningsvardenFusion',
                    'row',
                    noteLabel('Fusion'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndraLangfristigaFordringarForandringAnskaffningsvardenFusion',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden för andra långfristiga fordringar genom fusion',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndraLangfristigaFordringarForandringAnskaffningsvardenFusion',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden för andra långfristiga fordringar genom fusion',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'AndraLangfristigaFordringarForandringAnskaffningsvardenRegleradeFordringar',
                    'row',
                    noteLabel('Reglerade fordringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndraLangfristigaFordringarForandringAnskaffningsvardenRegleradeFordringar',
                      standardRubrik:
                        'Minskning av anskaffningsvärden i samband med periodens reglering av andra långfristiga fordringar',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndraLangfristigaFordringarForandringAnskaffningsvardenRegleradeFordringar',
                      standardRubrik:
                        'Minskning av anskaffningsvärden i samband med periodens reglering av andra långfristiga fordringar',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'AndraLangfristigaFordringarForandringAnskaffningsvardenBortskrivnafordringar',
                    'row',
                    noteLabel('Bortskrivna fordringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndraLangfristigaFordringarForandringAnskaffningsvardenBortskrivnafordringar',
                      standardRubrik:
                        'Minskning av anskaffningsvärden i samband med periodens bortskrivning av andra långfristiga fordringar',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndraLangfristigaFordringarForandringAnskaffningsvardenBortskrivnafordringar',
                      standardRubrik:
                        'Minskning av anskaffningsvärden i samband med periodens bortskrivning av andra långfristiga fordringar',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'AndraLangfristigaFordringarForandringAnskaffningsvardenOmklassificeringar',
                    'row',
                    noteLabel('Omklassificeringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndraLangfristigaFordringarForandringAnskaffningsvardenOmklassificeringar',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden i samband med omklassificeringar av andra långfristiga fordringar',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndraLangfristigaFordringarForandringAnskaffningsvardenOmklassificeringar',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden i samband med omklassificeringar av andra långfristiga fordringar',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'AndraLangfristigaFordringarForandringAnskaffningsvardenOmrakningsdifferenser',
                    'row',
                    noteLabel('Omräkningsdifferenser'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndraLangfristigaFordringarForandringAnskaffningsvardenOmrakningsdifferenser',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden i samband med omräkningsdifferenser av andra långfristiga fordringar',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndraLangfristigaFordringarForandringAnskaffningsvardenOmrakningsdifferenser',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden i samband med omräkningsdifferenser av andra långfristiga fordringar',
                      negateValue: false,
                    })
                  );
                  return rows.build();
                });
              rows.addRowWithType(
                'AndraLangfristigaFordringarAnskaffningsvardenUtgaende',
                'sum',
                noteLabel('Utgående anskaffningsvärden'),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note61.data.AndraLangfristigaFordringarAbstract.table.AndraLangfristigaFordringarAbstract.AndraLangfristigaFordringarAnskaffningsvardenIngaende.year'
                      ),
                      id(
                        'notes.note61.data.AndraLangfristigaFordringarAbstract.table.AndraLangfristigaFordringarAbstract.AndraLangfristigaFordringarForandringAnskaffningsvardenAbstract.*.year'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans0',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:AndraLangfristigaFordringarAnskaffningsvarden',
                    standardRubrik:
                      'Anskaffningsvärden andra långfristiga fordringar',
                    saldo: 'debit',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note61.data.AndraLangfristigaFordringarAbstract.table.AndraLangfristigaFordringarAbstract.AndraLangfristigaFordringarAnskaffningsvardenIngaende.previousYear'
                      ),
                      id(
                        'notes.note61.data.AndraLangfristigaFordringarAbstract.table.AndraLangfristigaFordringarAbstract.AndraLangfristigaFordringarForandringAnskaffningsvardenAbstract.*.previousYear'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans1',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:AndraLangfristigaFordringarAnskaffningsvarden',
                    standardRubrik:
                      'Anskaffningsvärden andra långfristiga fordringar',
                    saldo: 'debit',
                    negateValue: false,
                  }
                )
              );
              rows.addRowWithType(
                'AndraLangfristigaFordringarNedskrivningarIngaende',
                'sum_accounts',
                noteLabel('Ingående nedskrivningar'),
                ixbrlCell(ref(sumAccountsIBs('1389', false)), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:AndraLangfristigaFordringarNedskrivningar',
                  standardRubrik:
                    'Ackumulerade nedskrivningar andra långfristiga fordringar',
                  saldo: 'credit',
                  negateValue: true,
                }),
                ixbrlCell(ref(sumAccountsIBs('1389', false, 'previousYear')), {
                  contextRef: 'balans2',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:AndraLangfristigaFordringarNedskrivningar',
                  standardRubrik:
                    'Ackumulerade nedskrivningar andra långfristiga fordringar',
                  saldo: 'credit',
                  negateValue: true,
                })
              );
              rows
                .addRowWithType(
                  'AndraLangfristigaFordringarForandringNedskrivningarAbstract',
                  'header',
                  noteLabel('Förändring av nedskrivningar'),
                  undefined,
                  undefined
                )
                .addSubRows((rows) => {
                  rows.addRowWithType(
                    'AndraLangfristigaFordringarForandringNedskrivningarFusion',
                    'row',
                    noteLabel('Fusion'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndraLangfristigaFordringarForandringNedskrivningarFusion',
                      standardRubrik:
                        'Ökning (minskning) av nedskrivningar av andra långfristiga fordringar genom fusion',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndraLangfristigaFordringarForandringNedskrivningarFusion',
                      standardRubrik:
                        'Ökning (minskning) av nedskrivningar av andra långfristiga fordringar genom fusion',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'AndraLangfristigaFordringarForandringNedskrivningarRegleradeFordringar',
                    'row',
                    noteLabel('Reglerade fordringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndraLangfristigaFordringarForandringNedskrivningarRegleradeFordringar',
                      standardRubrik:
                        'Återförda nedskrivningar avseende reglerade andra långfristiga fordringar',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndraLangfristigaFordringarForandringNedskrivningarRegleradeFordringar',
                      standardRubrik:
                        'Återförda nedskrivningar avseende reglerade andra långfristiga fordringar',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'AndraLangfristigaFordringarForandringNedskrivningarAterfordaNedskrivningar',
                    'row',
                    noteLabel('Återförda nedskrivningar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndraLangfristigaFordringarForandringNedskrivningarAterfordaNedskrivningar',
                      standardRubrik:
                        'Periodens återförda nedskrivningar av andra långfristiga fordringar',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndraLangfristigaFordringarForandringNedskrivningarAterfordaNedskrivningar',
                      standardRubrik:
                        'Periodens återförda nedskrivningar av andra långfristiga fordringar',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'AndraLangfristigaFordringarForandringNedskrivningarBortskrivnafordringar',
                    'row',
                    noteLabel('Bortskrivna fordringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndraLangfristigaFordringarForandringNedskrivningarBortskrivnafordringar',
                      standardRubrik:
                        'Minskning av nedskrivningar i samband med bortskrivningar av andra långfristiga fordringar',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndraLangfristigaFordringarForandringNedskrivningarBortskrivnafordringar',
                      standardRubrik:
                        'Minskning av nedskrivningar i samband med bortskrivningar av andra långfristiga fordringar',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'AndraLangfristigaFordringarForandringNedskrivningarOmklassificeringar',
                    'row',
                    noteLabel('Omklassificeringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndraLangfristigaFordringarForandringNedskrivningarOmklassificeringar',
                      standardRubrik:
                        'Minskning (ökning) av nedskrivningar i samband med omklassificeringar av andra långfristiga fordringar',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndraLangfristigaFordringarForandringNedskrivningarOmklassificeringar',
                      standardRubrik:
                        'Minskning (ökning) av nedskrivningar i samband med omklassificeringar av andra långfristiga fordringar',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'AndraLangfristigaFordringarForandringNedskrivningarAretsNedskrivningar',
                    'row',
                    noteLabel('Årets nedskrivningar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndraLangfristigaFordringarForandringNedskrivningarAretsNedskrivningar',
                      standardRubrik:
                        'Periodens nedskrivningar av andra långfristiga fordringar',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndraLangfristigaFordringarForandringNedskrivningarAretsNedskrivningar',
                      standardRubrik:
                        'Periodens nedskrivningar av andra långfristiga fordringar',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'AndraLangfristigaFordringarForandringNedskrivningarOmrakningsdifferenser',
                    'row',
                    noteLabel('Omräkningsdifferenser'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndraLangfristigaFordringarForandringNedskrivningarOmrakningsdifferenser',
                      standardRubrik:
                        'Minskning (ökning) av nedskrivningar i samband med omräkningsdifferenser av andra långfristiga fordringar',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndraLangfristigaFordringarForandringNedskrivningarOmrakningsdifferenser',
                      standardRubrik:
                        'Minskning (ökning) av nedskrivningar i samband med omräkningsdifferenser av andra långfristiga fordringar',
                      negateValue: false,
                    })
                  );
                  return rows.build();
                });
              rows.addRowWithType(
                'AndraLangfristigaFordringarNedskrivningarUtgaende',
                'sum',
                noteLabel('Utgående nedskrivningar'),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note61.data.AndraLangfristigaFordringarAbstract.table.AndraLangfristigaFordringarAbstract.AndraLangfristigaFordringarNedskrivningarIngaende.year'
                      ),
                      id(
                        'notes.note61.data.AndraLangfristigaFordringarAbstract.table.AndraLangfristigaFordringarAbstract.AndraLangfristigaFordringarForandringNedskrivningarAbstract.*.year'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans0',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:AndraLangfristigaFordringarNedskrivningar',
                    standardRubrik:
                      'Ackumulerade nedskrivningar andra långfristiga fordringar',
                    saldo: 'credit',
                    negateValue: true,
                  }
                ),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note61.data.AndraLangfristigaFordringarAbstract.table.AndraLangfristigaFordringarAbstract.AndraLangfristigaFordringarNedskrivningarIngaende.previousYear'
                      ),
                      id(
                        'notes.note61.data.AndraLangfristigaFordringarAbstract.table.AndraLangfristigaFordringarAbstract.AndraLangfristigaFordringarForandringNedskrivningarAbstract.*.previousYear'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans1',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:AndraLangfristigaFordringarNedskrivningar',
                    standardRubrik:
                      'Ackumulerade nedskrivningar andra långfristiga fordringar',
                    saldo: 'credit',
                    negateValue: true,
                  }
                )
              );
              return rows.build();
            });
          rows.addRowWithType(
            'AndraLangfristigaFordringar',
            'sum',
            noteLabel('Redovisat värde'),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note61.data.AndraLangfristigaFordringarAbstract.table.AndraLangfristigaFordringarAbstract.AndraLangfristigaFordringarAnskaffningsvardenUtgaende.year'
                  ),
                  id(
                    'notes.note61.data.AndraLangfristigaFordringarAbstract.table.AndraLangfristigaFordringarAbstract.AndraLangfristigaFordringarNedskrivningarUtgaende.year'
                  )
                )
              ),
              {
                contextRef: 'balans0',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:AndraLangfristigaFordringar',
                standardRubrik: 'Andra långfristiga fordringar',
                saldo: 'debit',
                negateValue: false,
              }
            ),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note61.data.AndraLangfristigaFordringarAbstract.table.AndraLangfristigaFordringarAbstract.AndraLangfristigaFordringarAnskaffningsvardenUtgaende.previousYear'
                  ),
                  id(
                    'notes.note61.data.AndraLangfristigaFordringarAbstract.table.AndraLangfristigaFordringarAbstract.AndraLangfristigaFordringarNedskrivningarUtgaende.previousYear'
                  )
                )
              ),
              {
                contextRef: 'balans1',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:AndraLangfristigaFordringar',
                standardRubrik: 'Andra långfristiga fordringar',
                saldo: 'debit',
                negateValue: false,
              }
            )
          );
          return rows.build();
        })
        .build(),
      NotAndraLangfristigaFordringarKommentar: {
        type: 'group',
        active: true,
        level: 0,
        title: field(
          'Kommentar till specifikation av andra långfristiga fordringar'
        ),
        value: ixbrlField(field(''), {
          contextRef: 'period0',
          type: 'stringItemType',
          name: 'se-gen-base:NotAndraLangfristigaFordringarKommentar',
          standardRubrik:
            'Kommentar till specifikation av andra långfristiga fordringar',
        }),
      },
    },
  },
  group: value(undefined),
  section: value(undefined),
  validForReportType: {
    k2: true,
    k3: true,
  },
});
export const note2061K2v2ContentDefinition = {
  type: 'part' as const,
  children: {
    active: boolean,
    number: cell,
    name: cell,
    data: {
      type: 'part' as const,
      children: {
        active: boolean,

        AndraLangfristigaFordringarAbstract: {
          type: 'part' as const,
          children: {
            type: type,
            level: number,
            active: boolean,

            title: cell,

            table: { type: 'table' as const },

            NotAndraLangfristigaFordringarKommentar: {
              type: 'part' as const,
              children: {
                type: type,
                level: number,
                active: boolean,

                title: cell,

                value: cell,
              },
            },
          },
        },
      },
    },
    group: cell,
    section: cell,
    validForReportType: {
      type: 'part' as const,
      children: {
        k2: boolean,
        k3: boolean,
      },
    },
  },
};

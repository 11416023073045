import {
  AgoyTable,
  Cell,
  id,
  multiply,
  or,
  ref,
  sumAllowEmpty,
  table,
  value,
} from '@agoy/document';
import {
  IxbrlCell,
  Note84Section,
  Note84Tables,
  NoteTable,
} from '../../../../common';
import { previousYear, year } from '../../../../common/utils/date-util';
import {
  active,
  field,
  ixbrlCell,
  ixbrlField,
  tupleRef,
} from '../../../../common/utils/util';

const MONETARY_ITEM_TYPE = 'monetaryItemType';

const tableStructure: NoteTable<Note84Tables>[] = [
  {
    id: 'table',
    title: 'Till årsstämmans förfogande står följande medel',
    rows: [
      {
        id: 'rowFreePremiumFund',
        label: 'Fri överkursfond',
        elementName: 'se-gen-base:Overkursfond',
        contextRef: 'balans0',
        saldo: 'credit',
      },
      {
        id: 'rowFairValueFund',
        label: 'Fond för verkligt värde',
        elementName: 'se-gen-base:FondVerkligtVarde',
        contextRef: 'balans0',
        saldo: 'credit',
      },
      {
        id: 'rowBalancedResult',
        label: 'Balanserat resultat',
        elementName: 'se-gen-base:BalanseratResultat',
        contextRef: 'balans0',
        saldo: 'credit',
      },
      {
        id: 'rowYearResult',
        label: 'Årets resultat',
        elementName: 'se-gen-base:AretsResultatEgetKapital',
        contextRef: 'balans0',
        saldo: 'credit',
      },
      {
        id: 'sum',
        label: 'Medel att disponera',
        elementName: 'se-gen-base:FrittEgetKapital',
        contextRef: 'balans0',
        saldo: 'credit',
        refGenerator: (column) =>
          ref(or(sumAllowEmpty(id(`notes.note84.table.row*.${column}`)), 0)),
      },
    ],
  },
  {
    id: 'secondTable',
    title: 'Utdelning beslutad på extra bolagsstämma',
    rows: [
      {
        id: 'rowDistribution',
        label: 'Efterutdelning',
        elementName: 'se-gen-base:UtdelningBeslutadExtraBolagsstamma',
        contextRef: 'balans0',
        saldo: 'debit',
      },
    ],
  },
  {
    id: 'thirdTable',
    rows: [
      {
        id: 'sum',
        label: 'Summa',
        elementName:
          'se-gen-base:FrittEgetKapitalEfterUtdelningBeslutadExtraBolagsstamma',
        contextRef: 'balans0',
        saldo: 'credit',
        refGenerator: (column) =>
          ref(
            sumAllowEmpty(
              id(`notes.note84.table.sum.${column}`),
              id(`notes.note84.secondTable.rowDistribution.${column}`)
            )
          ),
      },
    ],
  },
  {
    id: 'fourthTable',
    title: 'Förslag till utdelning',
    rows: [
      {
        id: 'rowDividendProposalRepayment',
        label: 'Återbetalning av villkorat aktieägartillskott',
        elementName:
          'se-gen-base:ForslagDispositionUtdelningAterbetalningVillkoratAktieagartillskott',
        contextRef: 'balans0',
      },
      {
        id: 'rowDividendProposalOther',
        label: 'Annan utdelning',
        elementName: 'se-gen-base:ForslagDispositionUtdelningAnnanUtdelning',
        contextRef: 'balans0',
      },
    ],
  },
  {
    id: 'fifthTable',
    rows: [
      {
        id: 'rowDividend',
        label: 'Utdelning',
        elementName: 'se-gen-base:ForslagDispositionUtdelning',
        contextRef: 'balans0',
      },
      {
        id: 'rowBonus',
        label: 'Fondemission',
        elementName: 'se-gen-base:ForslagDispositionFondemission',
        contextRef: 'balans0',
      },
      {
        id: 'rowReserveFundAllocation',
        label: 'Avsättning till reservfond',
        elementName: 'se-gen-base:ForslagDispositionAvsattningReservfond',
        contextRef: 'balans0',
      },
      {
        id: 'rowReserveFundUse',
        label: 'Ianspråktagande av reservfond',
        elementName: 'se-gen-base:ForslagDispositionIanspraktagandeReservfond',
        contextRef: 'balans0',
      },
      {
        id: 'rowTiedFund',
        label: 'Ianspråktagande av bunden överkursfond',
        elementName:
          'se-gen-base:ForslagDispositionIanspraktagandeBundenOverkursfond',
        contextRef: 'balans0',
      },
      {
        id: 'rowRevaluationFund',
        label: 'Ianspråktagande av uppskrivningsfond',
        elementName:
          'se-gen-base:ForslagDispositionIanspraktagandeUppskrivningsfond',
        contextRef: 'balans0',
      },
      {
        id: 'rowDevelopmentFund',
        label: 'Ianspråktagande av fond för utvecklingsutgifter',
        elementName:
          'se-gen-base:ForslagDispositionIanspraktagandeFondUtvecklingsutgifter',
        contextRef: 'balans0',
      },
      {
        id: 'balanced',
        label: 'Balanseras i ny räkning',
        elementName: 'se-gen-base:ForslagDispositionBalanserasINyRakning',
        contextRef: 'balans0',
        refGenerator: (column) =>
          ref(
            sumAllowEmpty(
              or(id(`notes.note84.thirdTable.sum.${column}`), 0),
              multiply(
                -1,
                or(
                  sumAllowEmpty(id(`notes.note84.fifthTable.row*.${column}`)),
                  0
                )
              )
            )
          ),
      },
      {
        id: 'sum',
        label: 'Summa',
        elementName: 'se-gen-base:ForslagDisposition',
        saldo: 'credit',
        contextRef: 'balans0',
        refGenerator: (column) =>
          ref(
            or(
              sumAllowEmpty(
                id(`notes.note84.fifthTable.row*.${column}`),
                id(`notes.note84.fifthTable.balanced.${column}`)
              ),
              0
            )
          ),
      },
    ],
  },
];

export const getNote84 = (
  noteLabel: (name: string) => Cell,
  financialYear: string,
  financialYears: string[],
  period: string | null
): Note84Section => {
  const tables = tableStructure.reduce<
    {
      [key in Note84Tables]: AgoyTable;
    }
  >(
    (tables, currentTable) => ({
      ...tables,
      [currentTable.id]: table<IxbrlCell>(
        `notes.note84.${currentTable.id}`,
        {
          id: 'label',
          label: currentTable.title || '',
        },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        .addRows((rows) => {
          currentTable.rows.forEach((row) => {
            rows.addRow(
              row.id,
              noteLabel(row.label),
              ixbrlCell(
                !row.refGenerator ? value(undefined) : row.refGenerator('year'),
                {
                  type: MONETARY_ITEM_TYPE,
                  name: row.elementName,
                  contextRef: row.contextRef,
                  saldo: row.saldo,
                }
              ),
              !row.refGenerator
                ? value(undefined)
                : row.refGenerator('previousYear')
            );
          });
          return rows.build();
        })
        .build(),
    }),
    {} as { [key in Note84Tables]: AgoyTable }
  );

  return {
    active: false,
    number: value(undefined),
    name: value('Resultatdisposition'),
    ...tables,
    sixthTable: table<IxbrlCell>(
      `notes.note84.sixthTable`,
      {
        id: 'shareClass',
        label: 'Aktieslag',
      },
      {
        id: 'dividendPerShare',
        label: 'Utdelningsbelopp per aktie',
      },
      {
        id: 'recordDate',
        label: 'Avstämningsdag',
      },
      {
        id: 'paymentDate',
        label: 'Tidpunkt för betalning av utdelning',
      },
      {
        id: 'assetType',
        label: 'Typ av tillgång vid utdelning av annat än pengar',
      }
    )
      .newRowTemplateGenerator((id, baseId) => ({
        id,
        active: true,
        cells: {
          shareClass: ixbrlCell(value(''), {
            type: 'stringItemType',
            name: 'se-gen-base:ForslagUtdelningAktieslag',
            contextRef: 'period0',
            tuple: {
              name: 'se-gaap-ext:SpecifikationForslagUtdelningAktieslagTuple',
              id: `${baseId}.${id}.tuple`,
              tupleID: `${baseId}.${id}.tuple`,
            },
            tupleRef: tupleRef(1, `${baseId}.${id}.tuple`),
          }),
          dividendPerShare: ixbrlCell(value(undefined), {
            type: MONETARY_ITEM_TYPE,
            name: 'se-gen-base:ForslagUtdelningBeloppAktie',
            contextRef: 'period0',
            tupleRef: tupleRef(2, `${baseId}.${id}.tuple`),
          }),
          recordDate: ixbrlCell(value(''), {
            type: 'dateItemType',
            name: 'se-gen-base:ForslagUtdelningAvstamningsdag',
            contextRef: 'period0',
            tupleRef: tupleRef(3, `${baseId}.${id}.tuple`),
          }),
          paymentDate: ixbrlCell(value(''), {
            type: 'dateItemType',
            name: 'se-gen-base:ForslagUtdelningTidpunktBetalning',
            contextRef: 'period0',
            tupleRef: tupleRef(4, `${baseId}.${id}.tuple`),
          }),
          assetType: ixbrlCell(value(''), {
            type: 'stringItemType',
            name: 'se-gen-base:ForslagUtdelningTillgang',
            contextRef: 'period0',
            tupleRef: tupleRef(5, `${baseId}.${id}.tuple`),
          }),
        },
      }))
      .build(),
    commentLabel: field('Kommentar till not'),
    comment: ixbrlField(active(field('')), {
      type: 'stringItemType',
      name: 'se-gen-base:DispositionerVinstForlustKommentar',
      contextRef: 'balans0',
    }),
    secondCommentLabel: field('Styrelsens yttrande om vinstutdelning'),
    secondComment: ixbrlField(active(field('')), {
      type: 'stringItemType',
      name: 'se-gen-base:StyrelsensYttrandeVinstutdelning',
      contextRef: 'period0',
    }),
    group: value(undefined),
    section: value(undefined),
    validForReportType: {
      k2: false,
      k3: true,
    },
  };
};

import { useEffect, useState } from 'react';
import { asResultClass, useApiSdk } from 'api-sdk';
import { StockTransaction } from '_client-connections/types';

const useSharesInCompany = (connectedCompanyId: string, clientId: string) => {
  const [sharesInCurrentCompany, setSharesInCurrentCompany] = useState<
    StockTransaction[]
  >([]);
  const { getShareOwnerRelations, getClientRelations } = useApiSdk();

  useEffect(() => {
    (async () => {
      const ownersResult = await asResultClass(
        getShareOwnerRelations({
          clientid: connectedCompanyId,
        })
      );

      if (ownersResult.ok) {
        const connectionsPromises = ownersResult.val
          .filter((owner) => owner.ownerId !== clientId)
          .map((owner) =>
            asResultClass(getClientRelations({ clientid: owner.ownerId || '' }))
          );

        const connectionsResults = await Promise.all(connectionsPromises);
        const sharesInCompany = connectionsResults.reduce(
          (sharesList: StockTransaction[], connectionsResult) => {
            if (connectionsResult.ok) {
              const shares =
                connectionsResult.val.find(
                  (conn) => conn.orgId === connectedCompanyId
                )?.stockTransactions || [];

              return [...sharesList, ...shares];
            }
            return sharesList;
          },
          []
        );
        setSharesInCurrentCompany(sharesInCompany);
      }
    })();
  }, []);

  return sharesInCurrentCompany;
};

export default useSharesInCompany;

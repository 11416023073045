import React from 'react';
import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.spacing(2)}px;
  width: 55%;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 16px;
  margin-left: -42px;
`;

const OneLineText = styled(Typography)`
  display: inline;
`;

const Title = styled(Typography)`
  font-size: 24px;
`;

const LinkText = styled(Typography)`
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  padding-left: 4px;
`;

const WarningMessage = () => {
  const handleClick = () => {
    window.open('https://www.fortnox.se/agoy', 'FortnoxLink')?.focus();
  };

  return (
    <Container>
      <Header>
        <InfoOutlined />
        <Title>Vill du veta mer om Agoy?</Title>
      </Header>

      <div>
        <OneLineText>Läs mer här:</OneLineText>
        <LinkText onClick={handleClick}>www.fortnox.se/agoy</LinkText>
      </div>
    </Container>
  );
};

export default WarningMessage;

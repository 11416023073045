import { AgoyAppClientContextType } from './types';

let context: AgoyAppClientContextType | undefined;

export const getContext = (): AgoyAppClientContextType => {
  if (!context) {
    throw new Error('context not set yet');
  }
  return context;
};

export const setContext = (newContext: AgoyAppClientContextType) => {
  context = newContext;
};

import {
  TaxDeclarationFormDefinition,
  TaxDeclarationFormDataConfig,
  PersonMetaData,
} from '../../types';
import { id, ref, value } from '@agoy/document';
import { mapFormId, toInitial, toStructure } from '../forms-util';

const configK8 = (
  form: TaxDeclarationFormDefinition,
  person: PersonMetaData
): TaxDeclarationFormDataConfig => {
  const name = `${person?.firstName || ''} ${person?.lastName || ''}`;
  const K8 = mapFormId(form, 'K8');
  const initial = {
    K8: {
      partType: 'form' as const,
      id: K8,
      fields: {
        datum: value(''),
        numrering_vid_flera_k8: value(undefined),
        namn: value(name),
        personnummer: value(person.personNumber || ''),
        A_bostadsrattsforeningens_namn: value(''),
        A_bostadsrattsforeningens_organisationsnummer: value(''),
        A_lagenhetsbeteckning: value(''),
        A_forsaljningsdatum: value(''),
        A_inkopsdatum: value(''),
        A_150_procent_bostadsrattens_andel: value(false),
        B_1_forsaljningspris: value(undefined),
        B_2_forsaljningsutgifter: value(undefined),
        B_3_inkopspris: value(undefined),
        B_4_forbattringsutgifter_grundfarbattringar: value(undefined),
        B_5_forbattringsutgifter_forbattrande_reparationer: value(undefined),
        B_6_kapitaltillskott: value(undefined),
        B_7_bostadrattens_andel_reparationsfond_forsaljningen: value(undefined),
        B_8_bostadrattens_andel_reparationsfond_kopet: value(undefined),
        B_9_vinst_eller_forlust: value(undefined),
        B_10_andel_procent: value(undefined),
        B_10_andel_kronor: value(undefined),
        B_11_aterforing_uppskovsbelopp: value(undefined),
        B_12_vinst_eller_forlust: value(undefined),
        B_13_avgar_fran_vinst: value(undefined),
        B_14_vinst_efter_avdrag: value(undefined),
        B_15_forlust: value(undefined),
      },
      derivedFields: {
        B_forlust: ref(id('K8.fields.B_15_forlust')),
      },
      sru: {
        '7014': ref(id('K8.fields.numrering_vid_flera_k8')),
        '4000': ref(id('K8.fields.A_bostadsrattsforeningens_namn')),
        '4006': ref(
          id('K8.fields.A_bostadsrattsforeningens_organisationsnummer')
        ),
        '4001': ref(id('K8.fields.A_lagenhetsbeteckning')),
        '4002': ref(id('K8.fields.A_forsaljningsdatum')),
        '4003': ref(id('K8.fields.A_150_procent_bostadsrattens_andel')),
        '4005': ref(id('K8.fields.A_inkopsdatum')),
        '4010': ref(id('K8.fields.B_1_forsaljningspris')),
        '4011': ref(id('K8.fields.B_2_forsaljningsutgifter')),
        '4012': ref(id('K8.fields.B_3_inkopspris')),
        '4013': ref(
          id('K8.fields.B_4_forbattringsutgifter_grundfarbattringar')
        ),
        '4014': ref(
          id('K8.fields.B_5_forbattringsutgifter_forbattrande_reparationer')
        ),
        '4015': ref(id('K8.fields.B_6_kapitaltillskott')),
        '4016': ref(
          id('K8.fields.B_7_bostadrattens_andel_reparationsfond_forsaljningen')
        ),
        '4017': ref(
          id('K8.fields.B_8_bostadrattens_andel_reparationsfond_kopet')
        ),
        '4018': ref(id('K8.fields.B_9_vinst_eller_forlust')),
        '4019': ref(id('K8.fields.B_10_andel_procent')),
        '4020': ref(id('K8.fields.B_10_andel_kronor')),
        '4021': ref(id('K8.fields.B_11_aterforing_uppskovsbelopp')),
        '4022': ref(id('K8.fields.B_12_vinst_eller_forlust')),
        '4023': ref(id('K8.fields.B_13_avgar_fran_vinst')),
        '4024': ref(id('K8.fields.B_14_vinst_efter_avdrag')),
        '4025': ref(id('K8.fields.B_15_forlust')),
      },
    },
  };
  return {
    initial: toInitial(initial, form),
    definition: toStructure(initial, form),
  };
};

export default configK8;

import React, { useCallback, useState, useEffect, useContext } from 'react';
import { useIntl } from 'react-intl';
import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';

import { PrintContext } from '_shared/HOC/withPrintContext';
import Drawer from '_shared/components/Drawer';

import Legend from './Legend';

const DRAWER_EXPANDED_WIDTH = 800;

const RightColumn = styled(Typography)`
  width: 50%;
`;

interface ChecklistDrawerProps {
  open: boolean;
  onClose: () => void;
}

const LegendDrawer = ({ open, onClose }: ChecklistDrawerProps) => {
  const { formatMessage } = useIntl();

  const { hideMainContent } = useContext(PrintContext);

  const [rendered, setRendered] = useState(false);

  useEffect(() => {
    if (open) {
      setRendered(true);
    } else {
      setTimeout(() => setRendered(false), 500);
    }
  }, [open]);

  const toggleExpanded = useCallback(() => {
    onClose();
  }, [onClose]);

  if (!rendered || hideMainContent) {
    return null;
  }

  return (
    <Drawer
      open={open}
      onClose={toggleExpanded}
      width={`${DRAWER_EXPANDED_WIDTH}px`}
      headerTitle={formatMessage({
        id: 'legend.leftColumn',
      })}
      headerContent={
        <RightColumn>
          {formatMessage({
            id: 'legend.rightColumn',
          })}
        </RightColumn>
      }
    >
      <Legend />
    </Drawer>
  );
};

export default LegendDrawer;

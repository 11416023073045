import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  AppBar,
  Toolbar as MuiToolbar,
  IconButton,
  Box,
} from '@material-ui/core';
// @ts-ignore
import { ReactComponent as HomeIcon } from 'assets/fa_house.svg';
import styled from '@emotion/styled';
import { appHeaderHeight } from 'theme/measurements';
import FortnoxLogo from 'assets/fortnox-logo.svg';
import { UserMenu, HelpMenu } from './parts';
import { useSelector } from '../../../redux/reducers';
import { isFortnoxCompanyTenantSelector } from '../../redux/selectors';
import { useApiSdk } from '../../../api-sdk';

const TopBar = styled(AppBar)`
  border: none;
  background-color: ${(props) => props.theme.palette.common.white};
`;

const HomeLink = styled(Link)`
  display: flex;
  height: ${appHeaderHeight}px;
  width: ${appHeaderHeight}px;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.palette.primary.main};
  fill: ${(props) => props.theme.palette.common.white};
  &:hover {
    background-color: #00662c;
  }
`;

const Logo = styled.img`
  width: 150px;
  margin-left: 32px;
`;

const Toolbar = styled(MuiToolbar)`
  justify-content: space-between;
  border-bottom: 1px solid ${(props) => props.theme.palette.grey[200]};
`;

const UserMenuWrapper = styled.div`
  padding: 0 ${(props) => props.theme.spacing(1)}px;
  color: ${(props) => props.theme.palette.text.primary};
`;

// EVENING_300 as background, from Fortnox style guide
const HelpMenuWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${appHeaderHeight}px;
  color: ${(props) => props.theme.palette.common.white};
  padding-left: ${(props) => props.theme.spacing(1)}px;
`;

const TopHeader = () => {
  const { pathname } = useLocation();
  const { getClients } = useApiSdk();
  const { currentCustomer } = useSelector((state) => state.customerView);
  const [homeLocation, setHomeLocation] = useState('/');

  const isFortnoxCompanyTenant = useSelector(isFortnoxCompanyTenantSelector);

  useEffect(() => {
    const isPersonMode = pathname.includes('persons');
    if (isPersonMode) {
      setHomeLocation('/persons');
    } else if (isFortnoxCompanyTenant) {
      // Company tenants have only one client (e.g. organisation and client are the same tenant)
      (async () => {
        let clientId = currentCustomer;
        if (!clientId) {
          const clients = await getClients({});
          clientId = clients[0].id;
        }
        setHomeLocation(`/clients/${clientId}/`);
      })();
    } else {
      setHomeLocation('/');
    }
  }, [currentCustomer, getClients, isFortnoxCompanyTenant, pathname]);

  return (
    <TopBar position="static" elevation={0}>
      <Toolbar disableGutters>
        <Box display="flex" height={appHeaderHeight}>
          <HomeLink to={homeLocation}>
            <IconButton color="inherit">
              <HomeIcon />
            </IconButton>
          </HomeLink>
          <Logo src={FortnoxLogo} />
        </Box>

        <Box display="flex" alignItems="center">
          <UserMenuWrapper>
            <UserMenu disableAvatar />
          </UserMenuWrapper>
          <HelpMenuWrapper>
            <HelpMenu />
          </HelpMenuWrapper>
        </Box>
      </Toolbar>
    </TopBar>
  );
};

export default TopHeader;

import {
  TaxDeclarationFormDefinition,
  TaxDeclarationFormDataConfig,
  PersonMetaData,
} from '../../types';
import { ref, value, id, ifOrElse } from '@agoy/document';
import { mapFormId, toInitial, toStructure } from '../forms-util';

const configK15B = (
  form: TaxDeclarationFormDefinition,
  person: PersonMetaData
): TaxDeclarationFormDataConfig => {
  const name = `${person?.firstName || ''} ${person?.lastName || ''}`;
  const K15B = mapFormId(form, 'K15B');
  const initial = {
    K15B: {
      partType: 'form' as const,
      id: K15B,
      fields: {
        datum: value(''),
        numrering_vid_flera_k15b: value(undefined),
        namn: value(name),
        personnummer: value(person.personNumber || ''),
        A_namn: value(''),
        A_organisationsnummer: value(''),
        A_forsaljningsdatum: value(''),
        A_ersattning_minus_utgifter: value(undefined),
        A_justerad_anskaffningsutgift: value(undefined),
        A_vinst: value(undefined),
        A_forlust: value(undefined),
        A_summa_vinst: value(undefined),
        A_kedjeforsaljning: value(undefined),
        A_kedjeforsaljning_vinst: value(undefined),
        A_kedjeforsaljning_forlust: value(undefined),
        A_kedjeforsaljning_summa: value(undefined),
        B_namn: value(''),
        B_organisationsnummer: value(''),
        B_forsaljningsdatum: value(''),
        B_ersattning_minus_utgifter: value(undefined),
        B_justerad_anskaffningsutgift: value(undefined),
        B_vinst: value(undefined),
        B_forlust: value(undefined),
        B_vinst_summa: value(undefined),
        B_forlust_summa: value(undefined),
      },
      derivedFields: {
        A_huvudregel_forlust: ref(
          ifOrElse(
            id('K15B.fields.A_kedjeforsaljning_summa'),
            id('K15B.fields.A_kedjeforsaljning_summa'),
            id('K15B.fields.A_kedjeforsaljning_forlust')
          )
        ),
        A_huvudregel_vinst: ref(
          ifOrElse(
            id('K15B.fields.A_kedjeforsaljning_vinst'),
            id('K15B.fields.A_kedjeforsaljning_vinst'),
            id('K15B.fields.A_summa_vinst')
          )
        ),
      },
      sru: {
        '7014': ref(id('K15B.fields.numrering_vid_flera_k15b')),
        '5801': ref(id('K15B.fields.A_organisationsnummer')),
        '5802': ref(id('K15B.fields.A_forsaljningsdatum')),
        '5803': ref(id('K15B.fields.A_ersattning_minus_utgifter')),
        '5804': ref(id('K15B.fields.A_justerad_anskaffningsutgift')),
        '5805': ref(id('K15B.fields.A_vinst')),
        '5806': ref(id('K15B.fields.A_forlust')),
        '5810': ref(id('K15B.fields.A_summa_vinst')),
        '5811': ref(id('K15B.fields.A_kedjeforsaljning')),
        '5812': ref(id('K15B.fields.A_kedjeforsaljning_vinst')),
        '5820': ref(id('K15B.fields.A_kedjeforsaljning_forlust')),
        '5840': ref(id('K15B.fields.A_kedjeforsaljning_summa')),
        '5851': ref(id('K15B.fields.B_organisationsnummer')),
        '5852': ref(id('K15B.fields.B_forsaljningsdatum')),
        '5853': ref(id('K15B.fields.B_ersattning_minus_utgifter')),
        '5854': ref(id('K15B.fields.B_justerad_anskaffningsutgift')),
        '5855': ref(id('K15B.fields.B_vinst')),
        '5856': ref(id('K15B.fields.B_forlust')),
        '5860': ref(id('K15B.fields.B_vinst_summa')),
        '5870': ref(id('K15B.fields.B_forlust_summa')),
      },
    },
  };
  return {
    initial: toInitial(initial, form),
    definition: toStructure(initial, form),
  };
};

export default configK15B;

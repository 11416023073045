import { FinancialReportCustomerType } from '../types';

export const isFirstFinancialYear = (customer: FinancialReportCustomerType) => {
  return (
    [...customer.financialYears].sort((a, b) => {
      const [aStart] = a.split('-').map((item) => parseInt(item, 10));
      const [bStart] = b.split('-').map((item) => parseInt(item, 10));

      return aStart - bStart;
    }).length > 1
  );
};

import { balanceSheetReferences } from '../balance-sheet/economicAssociationBalanceSheet';
import { incomeStatementReferences } from '../income-statement/economicAssociationIncomeStatement';

type References = {
  [key: string]: string;
};

export const economicAssociationReferences = (): References[] => {
  const partReferences = [
    balanceSheetReferences(),
    incomeStatementReferences(),
  ];

  return partReferences;
};

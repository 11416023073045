import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { asResultClass, isApiErrorType, useApiSdk } from 'api-sdk';
import { mapUserInputData } from '_clients/services/mappings';
import {
  setUserInputData,
  setUserInputDocuments,
} from '_reconciliation/redux/accounting-view/actions';
import { useSelector } from 'redux/reducers';

type UserInputLoaderProps = {
  clientId: string;
  financialYearId: number;
};

/**
 * UserInputLoader
 *
 * Loads the "userInput" data for a specific client and year.
 */
const UserInputLoader = ({
  clientId,
  financialYearId,
}: UserInputLoaderProps): null => {
  const dispatch = useDispatch();
  const sdk = useApiSdk();

  const financialYearObj = useSelector((state) => {
    return state.customers[clientId]?.rawFinancialYears?.find(
      (y) => y.id === financialYearId
    );
  });

  useEffect(() => {
    const fetch = async () => {
      if (!financialYearObj) {
        // waiting for a financialYearId
        return;
      }

      const year = financialYearObj.start.substring(0, 4);

      const userInputResult = await asResultClass(
        sdk.getUserInput({
          clientId,
          accountingYear: year,
          financialYearId: financialYearObj.id,
        })
      );

      if (userInputResult.err) {
        if (
          isApiErrorType(userInputResult.val) &&
          userInputResult.val.handled
        ) {
          // Ignore the handled error
          return;
        }
        // eslint-disable-next-line no-console
        console.warn(userInputResult.val.message);

        // Reload the client list, solves the issue where
        // credentials has been changed in another tab.
        window.location.href = '/';
        return;
      }

      const { inputData } = userInputResult.val;
      const parsed = JSON.parse(inputData);
      const { userInput, userInputDocuments } = mapUserInputData(parsed);

      await dispatch(setUserInputData(clientId, financialYearObj, userInput));
      await dispatch(
        setUserInputDocuments(clientId, financialYearObj, userInputDocuments)
      );
    };
    fetch();
  }, [clientId, financialYearId, financialYearObj, sdk, dispatch]);

  return null;
};

export default UserInputLoader;

/*
 * This is a GENERATED file, do not change manually!
 * Generated:
 * - from file k2-arsredovisning-2021-10-31-rev20230131_sv - Noter.csv
 * - Comments: nan
 */

import {
  value,
  ref,
  sumAllowNull,
  id,
  AgoyDocumentPart,
  SelectFormat,
} from '@agoy/document';
import { IxbrlCell } from '../../../../../common';
import {
  field,
  ixbrlCell,
  ixbrlField,
  table,
  tupleRef,
} from '../../../../../common/utils/util';
import { previousYear, year } from '../../../../../common/utils/date-util';
import {
  sumAccountsUBs,
} from '../../../../../common/utils/accountSumsUtil';

const cell = { type: 'field' as const };
const boolean = { type: 'boolean' as const };
const type = { type: 'type' as const };
const number = { type: 'number' as const };

const RevisorerRevisionsforetagUppdragList: SelectFormat = {
  type: 'select',
  options: [
    {
      value:
        'se-mem-base:RevisorerRevisionsforetagUppdragRevisionsuppdragMember',
      label: 'Revisionsuppdrag',
      ranges: '6420:6421',
      action: 'sumAccountsUBs',
    },
    {
      value:
        'se-mem-base:RevisorerRevisionsforetagUppdragRevisionsverksamhetUtoverRevisionsuppdragetMember',
      label: 'Revisionsverksamhet utöver revisionsuppdraget',
      ranges: '6422',
      action: 'sumAccountsUBs',
    },
    {
      value:
        'se-mem-base:RevisorerRevisionsforetagUppdragSkatteradgivningMember',
      label: 'Skatterådgivning',
      ranges: '6423',
      action: 'sumAccountsUBs',
    },
    {
      value: 'se-mem-base:RevisorerRevisionsforetagOvrigaTjansterMember',
      label: 'Övriga tjänster',
      ranges: '6424',
      action: 'sumAccountsUBs',
    },
  ],
};

export const getNote2009K2v2 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null
): AgoyDocumentPart<typeof note2009K2v2ContentDefinition> => ({
  active: false,
  number: value(undefined),
  name: noteLabel('Ersättningar till revisorer'),
  data: {
    active: true,
    ErsattningRevisorerTuple: {
      type: 'group',
      active: true,
      level: 0,
      title: field('Ersättningar till revisorer och revisionsföretag'),
      table: table<IxbrlCell>(
        'notes.note9.data.ErsattningRevisorerTuple.table',
        {
          id: 'RevisorerRevisionsforetagNamn',
          label: 'Revisor och revisionsföretag',
          dataType: 'text',
          active: true,
        },
        {
          id: 'RevisorerRevisionsforetagUppdragList',
          label: 'Typ av uppdrag',
          dataType: 'text',
          active: true,
        },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) ?? '',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) ?? '',
          dataType: 'numeric',
          active: true,
        }
      )
        .addRows((rows) => {
          rows
            .addRowWithType(
              'ErsattningRevisorerTuple',
              'hidden',
              undefined,
              undefined,
              undefined,
              undefined
            )
            .addSubRows((rows) => {
              rows.addRowWithType(
                '1',
                'row',
                ixbrlCell(value('Revisor och revisionsföretag'), {
                  type: 'stringItemType',
                  name: 'se-gen-base:RevisorerRevisionsforetagNamn',
                  contextRef: 'period0',
                  standardRubrik:
                    'Namn på revisor och revisionsföretag som erhållit ersättning',
                  negateValue: false,
                  tuple: {
                    name: 'se-gaap-ext:ErsattningRevisorerTuple',
                    id: `${rows.getBaseId()}.1.tuple`,
                    tupleID: `${rows.getBaseId()}.1.tuple`,
                  },
                  tupleRef: tupleRef(1, `${rows.getBaseId()}.1.tuple`),
                  previousYearTuple: true,
                }),
                ixbrlCell(
                  {
                    type: 'string',
                    value:
                      RevisorerRevisionsforetagUppdragList.options[0].value,
                    format: RevisorerRevisionsforetagUppdragList,
                  },
                  {
                    type: 'enumerationItemType',
                    name: 'se-gen-base:RevisorerRevisionsforetagUppdragList',
                    contextRef: 'period0',
                    standardRubrik:
                      'Typ av uppdrag för revisorer och revisionsföretag',
                    negateValue: false,
                    tupleRef: tupleRef(2, `${rows.getBaseId()}.1.tuple`),
                    previousYearTuple: true,
                  }
                ),
                ixbrlCell(ref(sumAccountsUBs('6420:6421', true)), {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:RevisorerRevisionsforetagErsattningUppdrag',
                  contextRef: 'period0',
                  standardRubrik:
                    'Ersättning till revisor och revisionsföretag per uppdrag',
                  saldo: 'debit',
                  negateValue: false,
                  tupleRef: tupleRef(3, `${rows.getBaseId()}.1.tuple_period0`),
                  previousYearTuple: false,
                }),
                ixbrlCell(
                  ref(sumAccountsUBs('6420:6421', true, 'previousYear')),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:RevisorerRevisionsforetagErsattningUppdrag',
                    contextRef: 'period1',
                    standardRubrik:
                      'Ersättning till revisor och revisionsföretag per uppdrag',
                    saldo: 'debit',
                    negateValue: false,
                    tupleRef: tupleRef(
                      4,
                      `${rows.getBaseId()}.1.tuple_period1`
                    ),
                    previousYearTuple: false,
                  }
                )
              );
              return rows.build();
            });
          rows.newRowTemplateGenerator((_id, baseId) => ({
            id: _id,
            active: true,
            type: 'row',
            cells: {
              RevisorerRevisionsforetagNamn: ixbrlCell(
                value('Revisor och revisionsföretag'),
                {
                  type: 'stringItemType',
                  name: 'se-gen-base:RevisorerRevisionsforetagNamn',
                  contextRef: 'period0',
                  standardRubrik:
                    'Namn på revisor och revisionsföretag som erhållit ersättning',
                  negateValue: false,
                  tuple: {
                    name: 'se-gaap-ext:ErsattningRevisorerTuple',
                    id: `${baseId}.${_id}.tuple`,
                    tupleID: `${baseId}.${_id}.tuple`,
                  },
                  tupleRef: tupleRef(1, `${baseId}.${_id}.tuple`),
                  previousYearTuple: true,
                }
              ),
              RevisorerRevisionsforetagUppdragList: ixbrlCell(
                {
                  type: 'string',
                  value: RevisorerRevisionsforetagUppdragList.options[0].value,
                  format: RevisorerRevisionsforetagUppdragList,
                },
                {
                  type: 'enumerationItemType',
                  name: 'se-gen-base:RevisorerRevisionsforetagUppdragList',
                  contextRef: 'period0',
                  standardRubrik:
                    'Typ av uppdrag för revisorer och revisionsföretag',
                  negateValue: false,
                  tupleRef: tupleRef(2, `${baseId}.${_id}.tuple`),
                  previousYearTuple: true,
                }
              ),
              year: ixbrlCell(ref(sumAccountsUBs('6420:6421', true)), {
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:RevisorerRevisionsforetagErsattningUppdrag',
                contextRef: 'period0',
                standardRubrik:
                  'Ersättning till revisor och revisionsföretag per uppdrag',
                saldo: 'debit',
                negateValue: false,
                tupleRef: tupleRef(3, `${baseId}.${_id}.tuple_period0`),
                previousYearTuple: false,
              }),
              previousYear: ixbrlCell(
                ref(sumAccountsUBs('6420:6421', true, 'previousYear')),
                {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:RevisorerRevisionsforetagErsattningUppdrag',
                  contextRef: 'period1',
                  standardRubrik:
                    'Ersättning till revisor och revisionsföretag per uppdrag',
                  saldo: 'debit',
                  negateValue: false,
                  tupleRef: tupleRef(4, `${baseId}.${_id}.tuple_period1`),
                  previousYearTuple: false,
                }
              ),
            },
          }));
          rows.addRowWithType(
            'RevisorerRevisionsforetagErsattning',
            'sum',
            noteLabel('Summa'),
            undefined,
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note9.data.ErsattningRevisorerTuple.table.ErsattningRevisorerTuple.*.year'
                  )
                )
              ),
              {
                contextRef: 'period0',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:RevisorerRevisionsforetagErsattning',
                standardRubrik: 'Ersättning till revisor och revisionsföretag',
                saldo: 'debit',
                negateValue: false,
              }
            ),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note9.data.ErsattningRevisorerTuple.table.ErsattningRevisorerTuple.*.previousYear'
                  )
                )
              ),
              {
                contextRef: 'period1',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:RevisorerRevisionsforetagErsattning',
                standardRubrik: 'Ersättning till revisor och revisionsföretag',
                saldo: 'debit',
                negateValue: false,
              }
            )
          );
          return rows.build();
        })
        .build(),
      NotErsattningRevisorerKommentar: {
        type: 'group',
        active: true,
        level: 0,
        title: field(
          'Kommentar till ersättningar till revisorer och revisionsföretag'
        ),
        value: ixbrlField(field(''), {
          contextRef: 'period0',
          type: 'stringItemType',
          name: 'se-gen-base:NotErsattningRevisorerKommentar',
          standardRubrik:
            'Kommentar till ersättningar till revisorer och revisionsföretag',
        }),
      },
    },
  },
  group: value(undefined),
  section: value(undefined),
  validForReportType: {
    k2: true,
    k3: true,
  },
});
export const note2009K2v2ContentDefinition = {
  type: 'part' as const,
  children: {
    active: boolean,
    number: cell,
    name: cell,
    data: {
      type: 'part' as const,
      children: {
        active: boolean,

        ErsattningRevisorerTuple: {
          type: 'part' as const,
          children: {
            type: type,
            level: number,
            active: boolean,

            title: cell,

            table: { type: 'table' as const },

            NotErsattningRevisorerKommentar: {
              type: 'part' as const,
              children: {
                type: type,
                level: number,
                active: boolean,

                title: cell,

                value: cell,
              },
            },
          },
        },
      },
    },
    group: cell,
    section: cell,
    validForReportType: {
      type: 'part' as const,
      children: {
        k2: boolean,
        k3: boolean,
      },
    },
  },
};

import { messages } from '@agoy/common';

import ActivityLogClass, {
  ActivityLogEventType as ActivityLogEvent,
} from './ActivityLog';
import { EventMapSchema } from './event-map-schema';

export const ActivityLog = new ActivityLogClass(EventMapSchema, messages.sv);
export type ActivityLogEventType = ActivityLogEvent;
export const EventSchema = EventMapSchema;

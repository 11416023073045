import React from 'react';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';
import Button from '_shared/components/Buttons/Button';
import PersonAvatar from './PersonAvatar';

const Header = styled.div`
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 1056px;
  margin-left: -${(props) => props.theme.spacing(2)}px;
`;

const AvatarAndName = styled.div`
  display: flex;
  flex-direction: row;
`;

const NameContainer = styled.div`
  width: 500px;
  gap: ${(props) => props.theme.spacing(5)}px;
  display: flex;
  flexdirection: row;
`;

const Name = styled.p`
  font-size: 2rem;
`;

const ActionButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

type Props = {
  name: string;
  personNumber?: string;
  isEditing?: boolean;
  children: React.ReactNode;
  toggleEditing: () => void;
  onSave: () => void;
};

const PersonEditFormHeader = (props: Props): JSX.Element => {
  const { children, name, personNumber, isEditing, toggleEditing, onSave } =
    props;
  const { formatMessage } = useIntl();

  const ActionButtons = (): JSX.Element => {
    if (isEditing) {
      return (
        <>
          <Button
            label={formatMessage({ id: 'discard.changes' })}
            onClick={toggleEditing}
            variant="text"
          />
          <Button
            label={formatMessage({ id: 'save.changes' })}
            onClick={onSave}
          />
        </>
      );
    }

    return (
      <Button
        label={formatMessage({ id: 'edit' })}
        variant="outlined"
        onClick={toggleEditing}
      />
    );
  };

  return (
    <Header>
      <AvatarAndName>
        <PersonAvatar personNumber={personNumber || ''} />
        <NameContainer>
          {isEditing ? children : <Name>{name}</Name>}
        </NameContainer>
      </AvatarAndName>
      <ActionButtonsContainer>
        <ActionButtons />
      </ActionButtonsContainer>
    </Header>
  );
};

export default PersonEditFormHeader;

import {
  ReferenceCell,
  ref,
  value,
  or,
  id,
  sum,
  account,
  multiply,
} from '@agoy/document';
import { IxbrlCell } from '../../../common/types/ixbrl';
import { table, ixbrlCell } from '../../../common/utils/util';
import { changesInEquityIxbrl } from '../managementReport';

export const version2ChangesInEquityK3 = () => {
  const sumK3RowValues = (baseId: string): ReferenceCell => {
    return ref(
      or(
        sum(
          or(id(`${baseId}.shareCapital`), 0),
          or(id(`${baseId}.ongoingNewAndBonusIssue`), 0),
          or(id(`${baseId}.premiumFund`), 0),
          or(id(`${baseId}.revaluationFund`), 0),
          or(id(`${baseId}.reserveFund`), 0),
          or(id(`${baseId}.equityFund`), 0),
          or(id(`${baseId}.developmentFund`), 0),
          or(id(`${baseId}.freePremiumFund`), 0),
          or(id(`${baseId}.fairValueFund`), 0),
          or(id(`${baseId}.balanceResultIncludingYearResult`), 0),
          or(id(`${baseId}.balanceResult`), 0),
          or(id(`${baseId}.yearResult`), 0)
        ),
        0
      )
    );
  };

  // info about IXBRL tags
  // The tags in the bolagsverkett requirements are organized by columns
  // When there is a row with equivalent for that column, then the value is tagged
  // Last cell in row sums up all values in row
  // Last cell in column sums all values from above
  // Each row has a cell specified for each column
  return table<IxbrlCell>(
    'managementReport.changesInEquity.table',
    { id: 'label', label: '', active: true },
    { id: 'shareCapital', label: 'Aktiekapital', active: true },
    {
      id: 'ongoingNewAndBonusIssue',
      label: 'Pågående ny- och fondemission',
      active: false,
    },
    { id: 'premiumFund', label: 'Bunden överkursfond', active: false },
    { id: 'revaluationFund', label: 'Uppskrivningsfond', active: false },
    { id: 'reserveFund', label: 'Reservfond', active: false },
    {
      id: 'equityFund',
      label: 'Kapitalandelsfond',
      active: false,
    },
    {
      id: 'developmentFund',
      label: 'Fond för utvecklingsutgifter',
      active: false,
    },
    { id: 'freePremiumFund', label: 'Fri överkursfond', active: false },
    {
      id: 'fairValueFund',
      label: 'Fond för verkligt värde',
      active: false,
    },
    {
      id: 'balanceResultIncludingYearResult',
      label: 'Balanserat resultat inklusive årets resultat',
      active: false,
    },
    {
      id: 'balanceResult',
      label: 'Balanserat resultat',
      active: true,
    },
    {
      id: 'yearResult',
      label: 'Årets resultat',
      active: true,
    },
    { id: 'total', label: 'Totalt', active: true }
  )
    .addRows((rows) =>
      rows
        // We need one title and eleven values for every row
        // So it matches the twelve columns in total
        .addRow('main')
        .addSubRows((rows) =>
          rows
            .addRow('content')
            .addSubRows((rows) =>
              rows
                .addRowManagementReport({
                  id: 'incomingAmount',
                  cells: [
                    value('Belopp vid årets ingång'),
                    ixbrlCell(
                      ref(or(multiply(-1, account('2081', 'ib')), 0)),
                      changesInEquityIxbrl(
                        'Aktiekapital',
                        'balans1',
                        'credit',
                        'Aktiekapital'
                      )
                    ),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'EjRegistreratAktiekapital',
                        'balans1',
                        'credit',
                        'Ej registrerat aktiekapital vid pågående ny- och fondemission'
                      )
                    ),
                    ixbrlCell(
                      ref(or(multiply(-1, account('2087', 'ib')), 0)),
                      changesInEquityIxbrl(
                        'OverkursfondBunden',
                        'balans1',
                        'credit',
                        'Bunden överkursfond'
                      )
                    ),
                    ixbrlCell(
                      ref(or(multiply(-1, account('2085', 'ib')), 0)),
                      changesInEquityIxbrl(
                        'Uppskrivningsfond',
                        'balans1',
                        'credit',
                        'Uppskrivningsfond'
                      )
                    ),
                    ixbrlCell(
                      ref(or(multiply(-1, account('2086', 'ib')), 0)),
                      changesInEquityIxbrl(
                        'Reservfond',
                        'balans1',
                        'credit',
                        'Reservfond'
                      )
                    ),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'Kapitalandelsfond',
                        'balans1',
                        'credit',
                        'Kapitalandelsfond'
                      )
                    ),
                    ixbrlCell(
                      ref(or(multiply(-1, account('2089', 'ib')), 0)),
                      changesInEquityIxbrl(
                        'FondUtvecklingsutgifter',
                        'balans1',
                        'credit',
                        'Fond för utvecklingsutgifter'
                      )
                    ),
                    ixbrlCell(
                      ref(or(multiply(-1, account('2097', 'ib')), 0)),
                      changesInEquityIxbrl(
                        'Overkursfond',
                        'balans1',
                        'credit',
                        'Fri överkursfond'
                      )
                    ),
                    ixbrlCell(
                      ref(or(multiply(-1, account('2096', 'ib')), 0)),
                      changesInEquityIxbrl(
                        'FondVerkligtVarde',
                        'balans1',
                        'credit',
                        'Fond för verkligt värde'
                      )
                    ),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'BalanseratResultatInklusivePeriodensResultat',
                        'balans1',
                        'credit',
                        'Balanserat resultat inklusive periodens resultat'
                      )
                    ),
                    ixbrlCell(
                      ref(or(multiply(-1, account('2091', 'ib')), 0)),
                      changesInEquityIxbrl(
                        'BalanseratResultat',
                        'balans1',
                        'credit',
                        'Balanserat resultat'
                      )
                    ),
                    ixbrlCell(
                      ref(or(multiply(-1, account('2099', 'ib')), 0)),
                      changesInEquityIxbrl(
                        'AretsResultatEgetKapital',
                        'balans1',
                        'credit',
                        'Periodens resultat i eget kapital'
                      )
                    ),
                    ixbrlCell(
                      sumK3RowValues(
                        'managementReport.changesInEquity.table.main.content.incomingAmount'
                      ),
                      changesInEquityIxbrl(
                        'EgetKapital',
                        'balans1',
                        'credit',
                        'Eget kapital'
                      )
                    ),
                  ],
                  isRowActive: true,
                })
                .addRowManagementReport({
                  id: 'dividend',
                  cells: [
                    value('Utdelning'),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalOverkursfondUtdelning',
                        'period0',
                        undefined,
                        'Minskning av fri överkursfond vid utdelning'
                      )
                    ),
                    value(0),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalBalanseratResultatInklusivePeriodensResultatUtdelning',
                        'period0',
                        undefined,
                        'Minskning av balanserat resultat inklusive periodens resultat vid utdelning'
                      )
                    ),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalBalanseratResultatUtdelning',
                        'period0',
                        undefined,
                        'Minskning av balanserat resultat vid utdelning'
                      )
                    ),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalAretsResultatUtdelning',
                        'period0',
                        undefined,
                        'Minskning av periodens resultat vid utdelning'
                      )
                    ),
                    ixbrlCell(
                      sumK3RowValues(
                        'managementReport.changesInEquity.table.main.content.dividend'
                      ),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalTotaltUtdelning',
                        'period0',
                        undefined,
                        'Minskning av eget kapital vid utdelning'
                      )
                    ),
                  ],
                  isRowActive: true,
                })
                .addRowManagementReport({
                  id: 'shareholderContributions',
                  cells: [
                    value('Erhållna aktieägartillskott'),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalBalanseratResultatInklusivePeriodensResultatErhallnaAktieagartillskott',
                        'period0',
                        undefined,
                        'Ökning av balanserat resultat inklusive periodens resultat vid erhållna aktieägartillskott'
                      )
                    ),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalBalanseratResultatErhallnaAktieagartillskott',
                        'period0',
                        undefined,
                        'Ökning av balanserat resultat vid erhållna aktieägartillskott'
                      )
                    ),
                    value(0),
                    ixbrlCell(
                      sumK3RowValues(
                        'managementReport.changesInEquity.table.main.content.shareholderContributions'
                      ),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalTotaltErhallnaAktieagartillskott',
                        'period0'
                      )
                    ),
                  ],
                  isRowActive: true,
                })
                .addRowManagementReport({
                  id: 'refundShareholders',
                  cells: [
                    value('Återbetalning av aktieägartillskott'),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalOverkursfondAterbetalningAktieagartillskott',
                        'period0'
                      )
                    ),
                    value(0),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalBalanseratResultatInklusivePeriodensResultatAterbetalningAktieagartillskott',
                        'period0'
                      )
                    ),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalBalanseratResultatAterbetalningAktieagartillskott',
                        'period0'
                      )
                    ),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalAretsResultatAterbetalningAktieagartillskott',
                        'period0'
                      )
                    ),
                    ixbrlCell(
                      sumK3RowValues(
                        'managementReport.changesInEquity.table.main.content.refundShareholders'
                      ),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalTotaltAterbetalningAktieagartillskott',
                        'period0'
                      )
                    ),
                  ],
                  isRowActive: true,
                })
                .addRowManagementReport({
                  id: 'balanceInNew',
                  cells: [
                    value('Balanseras i ny räkning'),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalOverkursfondBalanserasNyRakning',
                        'period0'
                      )
                    ),
                    value(0),
                    ixbrlCell(
                      ref(or(account('8999', 'ib'), 0)),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalBalanseratResultatInklusivePeriodensResultatBalanserasNyRakning',
                        'period0'
                      )
                    ),
                    ixbrlCell(
                      ref(or(multiply(-1, account('2099', 'ib')), 0)),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalBalanseratResultatBalanserasNyRakning',
                        'period0'
                      )
                    ),
                    ixbrlCell(
                      ref(or(account('2099', 'ib'), 0)),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalAretsResultatBalanserasNyRakning',
                        'period0'
                      )
                    ),
                    ixbrlCell(
                      sumK3RowValues(
                        'managementReport.changesInEquity.table.main.content.balanceInNew'
                      ),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalTotaltBalanserasNyRakning',
                        'period0'
                      )
                    ),
                  ],
                  isRowActive: true,
                })
                .addRowManagementReport({
                  id: 'changeOfReserveFund',
                  cells: [
                    value('Förändring av reservfond'),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    ixbrlCell(
                      ref(
                        or(
                          multiply(
                            -1,
                            sum(
                              account('2086', 'creditWithoutInverse'),
                              account('2086', 'creditWithoutInverse')
                            )
                          ),
                          0
                        )
                      ),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalReservfondForandringReservfond',
                        'period0'
                      )
                    ),
                    value(0),
                    value(0),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalOverkursfondForandringReservfond',
                        'period0'
                      )
                    ),
                    value(0),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalBalanseratResultatInklusivePeriodensResultatForandringReservfond',
                        'period0'
                      )
                    ),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalBalanseratResultatForandringReservfond',
                        'period0'
                      )
                    ),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalAretsResultatReservfond',
                        'period0'
                      )
                    ),
                    ixbrlCell(
                      sumK3RowValues(
                        'managementReport.changesInEquity.table.main.content.changeOfReserveFund'
                      ),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalTotaltForandringReservfond',
                        'period0'
                      )
                    ),
                  ],
                  isRowActive: true,
                })
                .addRowManagementReport({
                  id: 'coverageOfLoss',
                  cells: [
                    value('Täckning av förlust'),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalAktiekapitalTackningForlust',
                        'period0'
                      )
                    ),
                    value(0),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalBundenOverkursfondTackningForlust',
                        'period0'
                      )
                    ),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalUppskrivningsfondTackningForlust',
                        'period0'
                      )
                    ),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalReservfondTackningForlust',
                        'period0'
                      )
                    ),
                    value(0),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalFondUtvecklingsutgifterTackningForlust',
                        'period0'
                      )
                    ),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalOverkursfondTackningForlust',
                        'period0'
                      )
                    ),
                    value(0),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalBalanseratResultatInklusivePeriodensResultatTackningForlust',
                        'period0'
                      )
                    ),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalBalanseratResultatTackningForlust',
                        'period0'
                      )
                    ),
                    value(0),
                    ixbrlCell(
                      sumK3RowValues(
                        'managementReport.changesInEquity.table.main.content.coverageOfLoss'
                      ),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalTotaltTackningForlust',
                        'period0'
                      )
                    ),
                  ],
                  isRowActive: false,
                })
                .addRowManagementReport({
                  id: 'fundEmission',
                  cells: [
                    value('Fondemission'),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalAktiekapitalFondemission',
                        'period0'
                      )
                    ),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalEjRegistreratAktiekapitalFondemission',
                        'period0'
                      )
                    ),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalBundenOverkursfondFondemission',
                        'period0'
                      )
                    ),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitaUppskrivningsfondFondemission',
                        'period0'
                      )
                    ),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalReservfondFondemission',
                        'period0'
                      )
                    ),
                    value(0),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalFondUtvecklingsutgifterFondemission',
                        'period0'
                      )
                    ),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalOverkursfondFondemission',
                        'period0'
                      )
                    ),
                    value(0),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalBalanseratResultatInklusivePeriodensResultatFondemission',
                        'period0'
                      )
                    ),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalBalanseratResultatFondemission',
                        'period0'
                      )
                    ),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalAretsResultatFondemission',
                        'period0'
                      )
                    ),
                    ixbrlCell(
                      sumK3RowValues(
                        'managementReport.changesInEquity.table.main.content.fundEmission'
                      ),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalTotaltFondemission',
                        'period0'
                      )
                    ),
                  ],
                  isRowActive: false,
                })
                .addRowManagementReport({
                  id: 'newSplit',
                  cells: [
                    value('Nyemission'),
                    ixbrlCell(
                      ref(
                        or(
                          multiply(-1, account('2081', 'creditWithoutInverse')),
                          0
                        )
                      ),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalAktiekapitalNyemission',
                        'period0'
                      )
                    ),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalEjRegistreratAktiekapitalNyemission',
                        'period0'
                      )
                    ),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalBundenOverkursfondNyemission',
                        'period0'
                      )
                    ),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalOverkursfondNyemission',
                        'period0'
                      )
                    ),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    ixbrlCell(
                      sumK3RowValues(
                        'managementReport.changesInEquity.table.main.content.newSplit'
                      ),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalTotaltNyemission',
                        'period0'
                      )
                    ),
                  ],
                  isRowActive: true,
                })
                .addRowManagementReport({
                  id: 'reductionOfShareCapital',
                  cells: [
                    value('Minskning av aktiekapital'),
                    ref(
                      or(
                        multiply(-1, account('2081', 'debitWithoutInverse')),
                        0
                      )
                    ),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalBalanseratResultatInklusivePeriodensResultatMinskningAktiekapital',
                        'period0'
                      )
                    ),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalBalanseratResultatMinskningAktiekapital',
                        'period0'
                      )
                    ),
                    value(0),
                    ixbrlCell(
                      sumK3RowValues(
                        'managementReport.changesInEquity.table.main.content.reductionOfShareCapital'
                      ),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalTotaltMinskningAktiekapital',
                        'period0'
                      )
                    ),
                  ],
                  isRowActive: true,
                })
                .addRowManagementReport({
                  id: 'reductionOfPremiumFund',
                  cells: [
                    value('Minskning av bunden överkursfond'),
                    value(0),
                    value(0),
                    ixbrlCell(
                      ref(
                        or(
                          multiply(-1, account('2087', 'debitWithoutInverse')),
                          0
                        )
                      ),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalBundenOverkursfondMinskningBundenOverskursfond',
                        'period0'
                      )
                    ),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    ixbrlCell(
                      sumK3RowValues(
                        'managementReport.changesInEquity.table.main.content.reductionOfPremiumFund'
                      ),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalTotaltMinskningBundenOverkursfond',
                        'period0'
                      )
                    ),
                  ],
                  isRowActive: true,
                })
                .addRowManagementReport({
                  id: 'acquisitionOfOwnShares',
                  cells: [
                    value('Förvärv av egna aktier'),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalBalanseratResultatInklusivePeriodensResultatForvarvEgnaAktier',
                        'period0'
                      )
                    ),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalBalanseratResultatEgnaAktier',
                        'period0'
                      )
                    ),
                    value(0),
                    ixbrlCell(
                      sumK3RowValues(
                        'managementReport.changesInEquity.table.main.content.acquisitionOfOwnShares'
                      ),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalTotaltForandringEgnaAktier',
                        'period0'
                      )
                    ),
                  ],
                  isRowActive: true,
                })
                .addRowManagementReport({
                  id: 'transferOfOwnShares',
                  cells: [
                    value('Överlåtelse av egna aktier'),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalBalanseratResultatInklusivePeriodensResultatOverlatelseEgnaAktier',
                        'period0'
                      )
                    ),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalBalanseratResultatOverlatelseEgnaAktier',
                        'period0'
                      )
                    ),
                    value(0),
                    ixbrlCell(
                      sumK3RowValues(
                        'managementReport.changesInEquity.table.main.content.transferOfOwnShares'
                      ),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalTotaltOverlatelseEgnaAktier',
                        'period0'
                      )
                    ),
                  ],
                  isRowActive: true,
                })
                .addRowManagementReport({
                  id: 'revaluationOfFixedAssets',
                  cells: [
                    value('Uppskrivning av anläggningstillgång'),
                    value(0),
                    value(0),
                    value(0),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalUppskrivningsfondUppskrivningAnlaggningstillgang',
                        'period0'
                      )
                    ),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    ixbrlCell(
                      sumK3RowValues(
                        'managementReport.changesInEquity.table.main.content.revaluationOfFixedAssets'
                      ),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalTotaltUppskrivningAnlaggningstillgang',
                        'period0'
                      )
                    ),
                  ],
                  isRowActive: false,
                })
                .addRowManagementReport({
                  id: 'dissolutionOfRevaluationFund',
                  cells: [
                    value('Upplösning av uppskrivningsfond'),
                    value(0),
                    value(0),
                    value(0),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalUppskrivningsfondUpplosningUppskrivningsfond',
                        'period0'
                      )
                    ),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalBalanseratResultatInklusivePeriodensResultatUpplosningUppskrivningsfond',
                        'period0'
                      )
                    ),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalBalanseratResultatUpplosningUppskrivningsfond',
                        'period0'
                      )
                    ),
                    value(0),
                    ixbrlCell(
                      sumK3RowValues(
                        'managementReport.changesInEquity.table.main.content.dissolutionOfRevaluationFund'
                      ),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalTotaltUpplosningUppskrivningsfond',
                        'period0'
                      )
                    ),
                  ],
                  isRowActive: false,
                })
                .addRowManagementReport({
                  id: 'fusionDifference',
                  cells: [
                    value('Fusionsdifferens'),
                    value(0),
                    value(0),
                    value(0),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalUppskrivningsfondFusionsdifferens',
                        'period0'
                      )
                    ),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalBalanseratResultatInklusivePeriodensResultatFusionsdifferens',
                        'period0'
                      )
                    ),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalBalanseratResultatFusionsdifferens',
                        'period0'
                      )
                    ),
                    value(0),
                    ixbrlCell(
                      sumK3RowValues(
                        'managementReport.changesInEquity.table.main.content.fusionDifference'
                      ),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalTotaltFusionsdifferens',
                        'period0'
                      )
                    ),
                  ],
                  isRowActive: false,
                })
                .addRowManagementReport({
                  id: 'errorCorrectionEffect',
                  cells: [
                    value('Effekt av rättelse av fel'),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalAktiekapitalEffektRattelseFel',
                        'balans0'
                      )
                    ),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalEjRegistreratAktiekapitalEffektRattelseFel',
                        'balans0'
                      )
                    ),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalBundenOverkursfondEffektRattelseFel',
                        'balans0'
                      )
                    ),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalUppskrivningsfondEffektRattelseFel',
                        'balans0'
                      )
                    ),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalReservfondEffektRattelseFel',
                        'balans0'
                      )
                    ),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalKapitalandelsfondEffektRattelseFel',
                        'balans0'
                      )
                    ),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalFondUtvecklingsutgifterEffektRattelseFel',
                        'balans0'
                      )
                    ),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalOverkursfondEffektRattelseFel',
                        'balans0'
                      )
                    ),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalFondVerkligtVardeEffektRattelseFel',
                        'balans0'
                      )
                    ),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalBalanseratResultatInklusivePeriodensResultatEffektRattelseFel',
                        'balans0'
                      )
                    ),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalBalanseratResultatEffektRattelseFel',
                        'balans0'
                      )
                    ),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalAretsResultatEffektRattelseFel',
                        'period0'
                      )
                    ),
                    ixbrlCell(
                      sumK3RowValues(
                        'managementReport.changesInEquity.table.main.content.errorCorrectionEffect'
                      ),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalTotaltEffektRattelseFel',
                        'balans0'
                      )
                    ),
                  ],
                  isRowActive: false,
                })
                .addRowManagementReport({
                  id: 'attributableExpenses',
                  cells: [
                    value('Utgifter hänförliga till emission'),
                    value(0),
                    value(0),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalBundenOverkursfondUtgifterHanforligaEmission',
                        'period0'
                      )
                    ),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalOverkursfondUtgifterHanforligaEmission',
                        'period0'
                      )
                    ),
                    value(0),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalOverkursfondUtgifterHanforligaEmission',
                        'period0'
                      )
                    ),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalBalanseratResultatUtgifterHanforligaEmission',
                        'period0'
                      )
                    ),
                    value(0),
                    ixbrlCell(
                      sumK3RowValues(
                        'managementReport.changesInEquity.table.main.content.attributableExpenses'
                      ),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalTotaltUtgifterHanforligaEmission',
                        'period0'
                      )
                    ),
                  ],
                  isRowActive: false,
                })
                .addRowManagementReport({
                  id: 'taxEffect',
                  cells: [
                    value('Skatteeffekt'),
                    value(0),
                    value(0),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalBundenOverkursfondSkatteeffekt',
                        'period0'
                      )
                    ),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalUppskrivningsfondSkatteeffektUppskrivningsfond',
                        'period0'
                      )
                    ),
                    value(0),
                    value(0),
                    value(0),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalOverkursfondSkatteeffekterEmissionsutgifter',
                        'period0'
                      )
                    ),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalFondVerkligtVardeSkatteffekterVarderingVerkligtVarde',
                        'period0'
                      )
                    ),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalBalanseratResultatInklusivePeriodensResultatSkatteffekter',
                        'period0'
                      )
                    ),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalBalanseratResultatSkatteeffekter',
                        'period0'
                      )
                    ),
                    value(0),
                    ixbrlCell(
                      sumK3RowValues(
                        'managementReport.changesInEquity.table.main.content.taxEffect'
                      ),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalTotaltSkatteeffekt',
                        'period0'
                      )
                    ),
                  ],
                  isRowActive: false,
                })
                .addRowManagementReport({
                  id: 'convertibleDebentures',
                  cells: [
                    value('Konvertibla skuldebrev eget kapitalandel'),
                    value(0),
                    value(0),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalBundenOverkursfondKonvertiblaSkuldebrevEgetKapitalandel',
                        'period0'
                      )
                    ),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalOverkursfondKonvertiblaSkuldebrevEgetKapitalandel',
                        'period0'
                      )
                    ),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    ixbrlCell(
                      sumK3RowValues(
                        'managementReport.changesInEquity.table.main.content.convertibleDebentures'
                      ),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalTotaltKonvertiblaSkuldebrevEgetKapitalandel',
                        'period0'
                      )
                    ),
                  ],
                  isRowActive: false,
                })
                .addRowManagementReport({
                  id: 'accountingPrincipleChangeEffect',
                  cells: [
                    value('Effekt av byte av redovisningsprincip'),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalKapitalandelsfondEffektByteRedovisningsprincip',
                        'balans0'
                      )
                    ),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalFondUtvecklingsutgifterEffektByteRedovisningsprincip',
                        'balans0'
                      )
                    ),
                    value(0),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalFondVerkligtVardeEffektByteRedovisningsprincip',
                        'balans0'
                      )
                    ),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalBalanseratResultatInklusivePeriodensResultatEffektByteRedovisningsprincip',
                        'balans0'
                      )
                    ),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalBalanseratResultatEffektByteRedovisningsprincip',
                        'balans0'
                      )
                    ),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalAretsResultatEffektByteRedovisningsprincip',
                        'balans0'
                      )
                    ),
                    ixbrlCell(
                      sumK3RowValues(
                        'managementReport.changesInEquity.table.main.content.accountingPrincipleChangeEffect'
                      ),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalTotaltEffektByteRedovisningsprincip',
                        'balans0'
                      )
                    ),
                  ],
                  isRowActive: false,
                })
                .addRowManagementReport({
                  id: 'changeOfEquityFund',
                  cells: [
                    value('Förändring av kapitalandelsfond'),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalReservfondForandringKapitalandelsfond',
                        'period0'
                      )
                    ),
                    value(0),
                    value(0),
                    value(0),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalBalanseratResultatInklusivePeriodensResultatForandringKapitalandelsfond',
                        'period0'
                      )
                    ),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalBalanseratResultatForandringKapitalandelsfond',
                        'period0'
                      )
                    ),
                    value(0),
                    ixbrlCell(
                      sumK3RowValues(
                        'managementReport.changesInEquity.table.main.content.changeOfEquityFund'
                      ),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalTotaltForandringKapitalandelsfond',
                        'period0'
                      )
                    ),
                  ],
                  isRowActive: false,
                })
                .addRowManagementReport({
                  id: 'activationOfDevelopment',
                  cells: [
                    value('Aktivering av utvecklingsutgifter'),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalFondUtvecklingsutgifterAktiveringUtvecklingsutgifter',
                        'period0'
                      )
                    ),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalOverkursfondAktiveringUtvecklingsutgifter',
                        'period0'
                      )
                    ),
                    value(0),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalBalanseratResultatInklusivePeriodensResultatAktiveringUtvecklingsutgifter',
                        'period0'
                      )
                    ),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalBalanseratResultatAktiveringUtvecklingsutgifter',
                        'period0'
                      )
                    ),
                    value(0),
                    ixbrlCell(
                      sumK3RowValues(
                        'managementReport.changesInEquity.table.main.content.activationOfDevelopment'
                      ),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalTotaltAktiveringUtvecklingsutgifter',
                        'period0'
                      )
                    ),
                  ],
                  isRowActive: false,
                })
                .addRowManagementReport({
                  id: 'developmentFundDissolution',
                  cells: [
                    value('Upplösning av fond för utvecklingsutgifter'),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalFondUtvecklingsutgifterUpplosningFondUtvecklingsutgifter',
                        'period0'
                      )
                    ),
                    value(0),
                    value(0),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalBalanseratResultatInklusivePeriodensResultatUpplosningFondUtvecklingsutgifter',
                        'period0'
                      )
                    ),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalBalanseratResultatUpplosningFondUtvecklingsutgifter',
                        'period0'
                      )
                    ),
                    value(0),
                    ixbrlCell(
                      sumK3RowValues(
                        'managementReport.changesInEquity.table.main.content.developmentFundDissolution'
                      ),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalTotaltUpplosningFondUtvecklingsutgifter',
                        'period0'
                      )
                    ),
                  ],
                  isRowActive: false,
                })
                .addRowManagementReport({
                  id: 'realValueValuation',
                  cells: [
                    value('Värdering till verkligt värde'),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalFondVerkligtVardeVarderingVerkligtVarde',
                        'period0'
                      )
                    ),
                    value(0),
                    value(0),
                    value(0),
                    ixbrlCell(
                      sumK3RowValues(
                        'managementReport.changesInEquity.table.main.content.realValueValuation'
                      ),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalTotaltVarderingVerkligtVarde',
                        'period0'
                      )
                    ),
                  ],
                  isRowActive: false,
                })
                .addRowManagementReport({
                  id: 'securingFutureCashFlow',
                  cells: [
                    value('Säkring av framtida kassaflöde'),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalFondVerkligtVardeSakringFramtidaKassaflode',
                        'period0'
                      )
                    ),
                    value(0),
                    value(0),
                    value(0),
                    ixbrlCell(
                      sumK3RowValues(
                        'managementReport.changesInEquity.table.main.content.securingFutureCashFlow'
                      ),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalTotaltSakringFramtidaKassaflode',
                        'period0'
                      )
                    ),
                  ],
                  isRowActive: false,
                })
                .addRowManagementReport({
                  id: 'foreignOperations',
                  cells: [
                    value('Omräkning av utlandsverksamhet'),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalFondVerkligtVardeOmrakningUtlandsverksamhet',
                        'period0'
                      )
                    ),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalBalanseratResultatInklusivePeriodensResultatOmrakningUtlandsverksamhet',
                        'period0'
                      )
                    ),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalBalanseratResultatOmrakningUtlandsverksamhet',
                        'period0'
                      )
                    ),
                    value(0),
                    ixbrlCell(
                      sumK3RowValues(
                        'managementReport.changesInEquity.table.main.content.foreignOperations'
                      ),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalTotaltOmrakningUtlandsverksamhet',
                        'period0'
                      )
                    ),
                  ],
                  isRowActive: false,
                })
                .addRowManagementReport({
                  id: 'foreignOperationsNetInvestments',
                  cells: [
                    value('Säkring av nettoinvesteringar i utlandsverksamhet'),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalFondVerkligtVardeSakringNettoinvesteringUtlandsverksamhet',
                        'period0'
                      )
                    ),
                    value(0),
                    value(0),
                    value(0),
                    ixbrlCell(
                      sumK3RowValues(
                        'managementReport.changesInEquity.table.main.content.foreignOperationsNetInvestments'
                      ),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalTotaltSakringNettoinvesteringUtlandsverksamhet',
                        'period0'
                      )
                    ),
                  ],
                  isRowActive: false,
                })
                .addRowManagementReport({
                  id: 'pensionLiabilityRevaluation',
                  cells: [
                    value('Omvärdering av pensionsskuld'),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalBalanseratResultatInklusivePeriodensResultatOmvarderingPensionsskuld',
                        'period0'
                      )
                    ),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalBalanseratResultatOmvarderingPensionsskuld',
                        'period0'
                      )
                    ),
                    value(0),
                    ixbrlCell(
                      sumK3RowValues(
                        'managementReport.changesInEquity.table.main.content.pensionLiabilityRevaluation'
                      ),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalTotaltOmvarderingPensionsskuld',
                        'period0'
                      )
                    ),
                  ],
                  isRowActive: false,
                })
                .addRowManagementReport({
                  id: 'shareRelatedCompensation',
                  cells: [
                    value('Aktierelaterade ersättningar'),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalBalanseratResultatInklusivePeriodensResultatAktierelateradeErsattningar',
                        'period0'
                      )
                    ),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalBalanseratResultatAktierelateradeErsattningar',
                        'period0'
                      )
                    ),
                    value(0),
                    ixbrlCell(
                      sumK3RowValues(
                        'managementReport.changesInEquity.table.main.content.shareRelatedCompensation'
                      ),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalTotaltAktierelateradeErsattningar',
                        'period0'
                      )
                    ),
                  ],
                  isRowActive: false,
                })
                .addRowManagementReport({
                  id: 'yearResult',
                  cells: [
                    value('Årets resultat'),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    ixbrlCell(
                      value(0),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalBalanseratResultatInklusivePeriodensResultatAretsResultat',
                        'period0'
                      )
                    ),
                    value(0),
                    ixbrlCell(
                      ref(or(multiply(account('8990:8999')), 0)),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalAretsResultatAretsResultat',
                        'period0'
                      )
                    ),
                    ixbrlCell(
                      sumK3RowValues(
                        'managementReport.changesInEquity.table.main.content.yearResult'
                      ),
                      changesInEquityIxbrl(
                        'ForandringEgetKapitalTotaltAretsResultat',
                        'period0'
                      )
                    ),
                  ],
                  isRowActive: true,
                })
                .build()
            )
            .newRowTemplate(
              value(''),
              value(0),
              value(0),
              value(0),
              value(0),
              value(0),
              value(0),
              value(0),
              value(0),
              value(0),
              value(0),
              value(0),
              value(0),
              value(0)
            )

            // Create function to determine whether there is a value in row or not then assign its return to isRowActive

            // Row dedicated to show the sum of all columns
            .addRowManagementReport({
              id: 'sumByYearEnd',
              cells: [
                value('Belopp vid årets utgång'),
                ixbrlCell(
                  ref(
                    or(
                      sum(
                        id(
                          'managementReport.changesInEquity.table.main.content.*.shareCapital'
                        )
                      ),
                      0
                    )
                  ),
                  changesInEquityIxbrl('Aktiekapital', 'balans0', 'credit')
                ),
                ixbrlCell(
                  ref(
                    or(
                      sum(
                        id(
                          'managementReport.changesInEquity.table.main.content.*.ongoingNewAndBonusIssue'
                        )
                      ),
                      0
                    )
                  ),
                  changesInEquityIxbrl(
                    'EjRegistreratAktiekapital',
                    'balans0',
                    'credit'
                  )
                ),
                ixbrlCell(
                  ref(
                    or(
                      sum(
                        id(
                          'managementReport.changesInEquity.table.main.content.*.premiumFund'
                        )
                      ),
                      0
                    )
                  ),
                  changesInEquityIxbrl(
                    'OverkursfondBunden',
                    'balans0',
                    'credit'
                  )
                ),
                ixbrlCell(
                  ref(
                    or(
                      sum(
                        id(
                          'managementReport.changesInEquity.table.main.content.*.revaluationFund'
                        )
                      ),
                      0
                    )
                  ),
                  changesInEquityIxbrl('Uppskrivningsfond', 'balans0', 'credit')
                ),
                ixbrlCell(
                  ref(
                    or(
                      sum(
                        id(
                          'managementReport.changesInEquity.table.main.content.*.reserveFund'
                        )
                      ),
                      0
                    )
                  ),
                  changesInEquityIxbrl('Reservfond', 'balans0', 'credit')
                ),
                ixbrlCell(
                  ref(
                    or(
                      sum(
                        id(
                          'managementReport.changesInEquity.table.main.content.*.equityFund'
                        )
                      ),
                      0
                    )
                  ),
                  changesInEquityIxbrl('Kapitalandelsfond', 'balans0', 'credit')
                ),
                ixbrlCell(
                  ref(
                    or(
                      sum(
                        id(
                          'managementReport.changesInEquity.table.main.content.*.developmentFund'
                        )
                      ),
                      0
                    )
                  ),
                  changesInEquityIxbrl(
                    'FondUtvecklingsutgifter',
                    'balans0',
                    'credit'
                  )
                ),
                ixbrlCell(
                  ref(
                    or(
                      sum(
                        id(
                          'managementReport.changesInEquity.table.main.content.*.freePremiumFund'
                        )
                      ),
                      0
                    )
                  ),
                  changesInEquityIxbrl('Overkursfond', 'balans0', 'credit')
                ),
                ixbrlCell(
                  ref(
                    or(
                      sum(
                        id(
                          'managementReport.changesInEquity.table.main.content.*.fairValueFund'
                        )
                      ),
                      0
                    )
                  ),
                  changesInEquityIxbrl('FondVerkligtVarde', 'balans0', 'credit')
                ),
                ixbrlCell(
                  ref(
                    or(
                      sum(
                        id(
                          'managementReport.changesInEquity.table.main.content.*.balanceResultIncludingYearResult'
                        )
                      ),
                      0
                    )
                  ),
                  changesInEquityIxbrl(
                    'BalanseratResultatInklusivePeriodensResultat',
                    'balans0',
                    'credit'
                  )
                ),
                ixbrlCell(
                  ref(
                    or(
                      sum(
                        id(
                          'managementReport.changesInEquity.table.main.content.*.balanceResult'
                        )
                      ),
                      0
                    )
                  ),
                  changesInEquityIxbrl(
                    'BalanseratResultat',
                    'balans0',
                    'credit'
                  )
                ),
                ixbrlCell(
                  ref(
                    or(
                      sum(
                        id(
                          'managementReport.changesInEquity.table.main.content.*.yearResult'
                        )
                      ),
                      0
                    )
                  ),
                  changesInEquityIxbrl(
                    'AretsResultatEgetKapital',
                    'balans0',
                    'credit'
                  )
                ),
                ixbrlCell(
                  ref(
                    or(
                      sum(
                        id(
                          'managementReport.changesInEquity.table.main.content.*.total'
                        )
                      ),
                      0
                    )
                  ),
                  changesInEquityIxbrl('EgetKapital', 'balans0', 'credit')
                ),
              ],
              isRowActive: true,
            })
            .build()
        )
        .build()
    )
    .build();
};

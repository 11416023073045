import { isLastMonth, reformatDate } from '@agoy/common';
import { FinancialYear } from '@agoy/api-sdk-core';
import { RootState } from 'redux/reducers';
import { ClientCompanyType } from '_clients/types/types';

export const currentCustomer = (
  state: RootState
): ClientCompanyType | undefined => {
  const { currentCustomer: clientId } = state.customerView;
  return clientId ? state.customers[clientId] : undefined;
};

export const isLastPeriodSelected = (state: RootState): boolean | undefined => {
  const { currentFinancialYear, currentPeriod } = state.customerView;
  return currentFinancialYear && currentPeriod
    ? isLastMonth(currentFinancialYear, currentPeriod)
    : undefined;
};

export const selectPreviousFinancialYear = (
  state: RootState
): string | undefined => {
  const { currentFinancialYear, currentCustomer: clientId } =
    state.customerView;
  const client = clientId ? state.customers[clientId] : undefined;
  const financialYears = client?.financialYears || [];

  if (financialYears.length > 1 && currentFinancialYear) {
    const index = financialYears.indexOf(currentFinancialYear);
    return financialYears[index - 1];
  }

  return undefined;
};

/**
 * Redux selector for getting the current financial year's period objects
 *
 * The current financial year is controlled by the redux path customerView.currentFinancialYearId
 * and locates the periods in the customer.<clientId>.rawFinancialYears by id.
 *
 * __Note:__ The periods include the year end period and not only months.
 *
 * @param state Redux root state.
 * @returns The period objects.
 */
export const currentPeriods = (state: RootState) => {
  const { currentFinancialYearId, currentCustomer: clientId } =
    state.customerView;
  const client = clientId ? state.customers[clientId] : undefined;

  return client?.rawFinancialYears.find((y) => y.id === currentFinancialYearId)
    ?.periods;
};

/**
 * Redux selector for getting the current period object
 *
 * The current period is controlled by the redux path customerView.currentPeriod
 * which is a string with the start date of the period in the form `yyyyMMdd`
 *
 * @param state Redux root state.
 * @returns The period object.
 */
export const currentPeriod = (state: RootState) => {
  const { currentPeriod } = state.customerView;
  if (!currentPeriod) {
    return undefined;
  }
  const start = reformatDate(currentPeriod, 'yyyyMMdd', 'yyyy-MM-dd');

  return currentPeriods(state)?.find((period) => period.start === start);
};

/**
 * Redux selector for getting the period object for a given start date.
 *
 * @param period The period start in format `yyyyMMdd`
 * @param state Redux root state.
 * @returns The period object.
 */
export const selectPeriod = (period: string) => (state: RootState) => {
  const start = reformatDate(period, 'yyyyMMdd', 'yyyy-MM-dd');

  return currentPeriods(state)?.find((period) => period.start === start);
};

export const currentClientFinancialYear = (
  state: RootState
): {
  clientId: string | undefined;
  financialYear: FinancialYear | undefined;
} => {
  const { currentCustomer, currentFinancialYearId } = state.customerView;
  if (!currentCustomer || !currentFinancialYearId) {
    return {
      clientId: currentCustomer || undefined,
      financialYear: undefined,
    };
  }
  return {
    clientId: currentCustomer,
    financialYear: state.customers[currentCustomer].rawFinancialYears.find(
      (y) => y.id === currentFinancialYearId
    ),
  };
};

import React, { useRef } from 'react';
import { TableCell } from '@material-ui/core';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';
import { AgoyTableColumn } from '@agoy/document';

interface TableHeadCellProps {
  className?: string;
  column: AgoyTableColumn;
  baseId: string;
  tableId: string;
  renderCell?: (values: {
    column: AgoyTableColumn;
  }) => React.ReactElement | string | null;

  textAlign?: string;
}

const StyledTableCell = styled(TableCell)`
  background-color: ${(props) =>
    props['data-is-dragging']
      ? props.theme.palette.accountingView.cell.ok
      : 'inherit'};

  &.right {
    text-align: right;
  }
  &.left {
    text-align: left;
  }
`;

const TableHeadCell = ({
  className,
  column,
  baseId,
  tableId,
  renderCell,
  textAlign = 'right',
}: TableHeadCellProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const cellRef = useRef(null);

  const renderColumnLabel = () => {
    const label =
      column.label !== undefined
        ? column.label
        : formatMessage({
            id: `${baseId}.${tableId}.${column.id}`,
            defaultMessage: column.id,
          });

    return renderCell ? renderCell({ column }) : label;
  };

  return (
    <StyledTableCell
      className={`${className || ''} ${textAlign}`}
      ref={cellRef}
    >
      {renderColumnLabel()}
    </StyledTableCell>
  );
};

export default TableHeadCell;

import React from 'react';
import { Typography } from '@material-ui/core';
import styled from '@emotion/styled';

const Text = styled(Typography)`
  color: ${({ theme }) => theme.palette.error.main};
`;

interface ErrorTextProps {
  errorText: string;
}

const ErrorText = ({ errorText }: ErrorTextProps): JSX.Element => {
  return <Text>{errorText}</Text>;
};

export default ErrorText;

import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Typography, Button } from '@material-ui/core';
import { useForm } from 'react-hook-form';

import FormError from '_shared/components/Inputs/FormError';
import { CTAButton } from '_shared/components/Buttons';
import useAuth from '_authentication/hooks/useAuth';

import {
  LoginBox,
  ActionsWrapper,
  TextField,
  LinkWrapper,
  UnderlineLink,
} from './SharedComponents';

type Props = {
  backToCreate: () => void;
  backToLogin: (email?: string) => void;
  openResend: () => void;
  email: string;
};

const ConfirmContent: React.FC<Props> = ({
  backToCreate,
  backToLogin,
  openResend,
  email,
}: Props) => {
  const auth = useAuth();
  const { formatMessage } = useIntl();
  const msg = (id: string) => formatMessage({ id });
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const [submitError, setSubmitError] = useState('');

  const isRequired = {
    required: msg('form.fieldRequired'),
  };

  const submitConfirmation = async (data) => {
    try {
      await auth.confirmAccountAWS(data);
      backToLogin(data.username);
    } catch (error) {
      setSubmitError(msg('confirm.error'));
    }
  };

  return (
    <>
      <LoginBox onSubmit={handleSubmit(submitConfirmation)}>
        <Typography variant="h2">{msg('confirm.title')}</Typography>
        <Typography>{msg('confirm.confirmationSubTitle')}</Typography>

        <TextField
          {...register('username', isRequired)}
          variant="outlined"
          size="small"
          defaultValue={email || ''}
          placeholder={msg('email.placeholder')}
          type="email"
        />

        <FormError>{errors.username?.message}</FormError>

        <TextField
          {...register('code', isRequired)}
          variant="outlined"
          size="small"
          placeholder={msg('code')}
        />

        <FormError>{errors.code?.message}</FormError>

        {submitError && <FormError>{submitError}</FormError>}

        <LinkWrapper>
          <UnderlineLink onClick={openResend}>
            {msg('login.resendConfirmation')}
          </UnderlineLink>
        </LinkWrapper>

        <ActionsWrapper>
          <Button onClick={backToCreate}>{msg('forgot.back')}</Button>
          <CTAButton type="submit" loading={auth.isLoading}>
            {msg('confirm.verify')}
          </CTAButton>
        </ActionsWrapper>
      </LoginBox>
    </>
  );
};

export default ConfirmContent;

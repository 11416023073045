import { TaxCalculationPart, TaxCalculationConfig } from '@agoy/tax-document';
import { getContext } from 'utils/AgoyAppClient/contextHolder';
import { asResultClass, getApiSdk, isApiErrorType } from 'api-sdk';

type StoredTaxConfig = Pick<TaxCalculationConfig, TaxCalculationPart>;

const storeTaxConfig = async (
  clientId: string,
  financialYear: string,
  taxCalculation: StoredTaxConfig
): Promise<void> => {
  const context = getContext();

  const response = await asResultClass(
    getApiSdk(context).postClientsTax({
      clientid: clientId,
      requestBody: taxCalculation,
      financialYear,
    })
  );

  if (response.err) {
    if (isApiErrorType(response.val)) {
      if (!response.val.handled) {
        return Promise.reject(response.val?.body.code || response.val.status);
      }
    }
  }

  return Promise.resolve();
};

export default storeTaxConfig;

import { TimePeriod } from '@agoy/document';
import { PrivatePerson } from '_person/_types/person';
import { INIT_STATE } from '_shared/redux/action-types';
import PersonActionTypes from './action-types';
import updatePersonsProgramStatusReducer from './reducers/updatePersonsStatusesReducer';

export type PersonsState = {
  [key: string]: PrivatePerson;
};

const initialCustomersState: PersonsState = {};

export default (
  state: PersonsState = initialCustomersState,
  action
): PersonsState => {
  switch (action.type) {
    // Global actions, shared by other reducers
    case INIT_STATE:
      return action.state.persons || state;
    case PersonActionTypes.POPULATE_PERSONS: {
      return {
        ...state,
        ...action.persons.reduce((acc, current) => {
          acc[current.id] = current;
          return acc;
        }, {}),
      };
    }
    case PersonActionTypes.ADD_PERSON: {
      return {
        ...state,
        [action.person.id]: {
          ...action.person,
          financialYears: action.person.rawFinancialYears.map(
            (year) => TimePeriod.fromISODates(year).value
          ),
        },
      };
    }
    case PersonActionTypes.DELETE_PERSON: {
      const newState = { ...state };
      delete newState[action.personId];
      return newState;
    }
    case PersonActionTypes.UPDATE_PERSONS_PROGRAM_STATUSES: {
      return updatePersonsProgramStatusReducer(state, action);
    }
    case PersonActionTypes.SET_CONNECTIONS_TO_COMPANIES: {
      const person = state[action.clientId];
      return {
        ...state,
        [action.clientId]: { ...person, connections: action.connections || [] },
      };
    }
    case PersonActionTypes.DELETE_CONNECTION: {
      const person = state[action.clientId];
      return {
        ...state,
        [action.clientId]: {
          ...person,
          connections: person.connections?.filter(
            (connection) => connection.relationId !== action.connectionId
          ),
        },
      };
    }
    case PersonActionTypes.SET_PERSON_CHECKLISTS: {
      const person = state[action.personId];
      return {
        ...state,
        [action.personId]: {
          ...person,
          checkLists: action.checklists,
        },
      };
    }
    default: {
      return state;
    }
  }
};

import React, { useContext, useEffect, useState } from 'react';
import { Checkbox, Chip, TextField, Popper } from '@material-ui/core';
import MuiAutocomplete from '@material-ui/lab/Autocomplete';
import styled from '@emotion/styled';

import { useApiSdk } from 'api-sdk';
import { Period } from '@agoy/api-sdk-core';
import {
  GroupDocumentContext,
  PeriodDocument,
} from './Documents/GroupDocumentsContext';
import { DocumentReferenceTypes } from '../../utils';

const Autocomplete = styled(MuiAutocomplete)`
  .MuiOutlinedInput-root {
    padding: ${({ theme }) => theme.spacing(1 / 2)}px;
    row-gap: 4px;
    column-gap: 4px;
  }

  .MuiAutocomplete-tag {
    margin: 0;
  }

  .MuiChip-root {
    height: 24px;
    border-radius: 4px;
    max-width: 300px;
  }

  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']
    .MuiAutocomplete-input {
    padding: 0 4px;
    height: 24px;
  }
` as typeof MuiAutocomplete;

const StyledAutocompletePopper = styled(Popper)`
  .MuiAutocomplete-option {
    padding: 4px 12px;
  }
  .MuiAutocomplete-option[aria-selected='true']:not([data-focus]) {
    background-color: ${({ theme }) => theme.palette.common.white};
  }
`;

const SmallCheckbox = styled(Checkbox)`
  padding: 2px;
  margin-right: 4px;
`;

// Function to remove the extension of the file's name
const removeExtension = (fileName: string) => {
  const lastDotPosition = fileName.lastIndexOf('.');
  if (lastDotPosition === -1) return fileName;
  return fileName.slice(0, lastDotPosition);
};

type SelectAccountDocumentsProps = {
  clientId: string;
  lastPeriod: Period;
  accountGroup: string;
  accountNumber: string;
};

const SelectAccountDocuments = ({
  clientId,
  lastPeriod,
  accountGroup,
  accountNumber,
}: SelectAccountDocumentsProps) => {
  const sdk = useApiSdk();
  const [state] = useContext(GroupDocumentContext);
  const { documents } = state;

  const [selectedDocuments, setSelectedDocuments] = useState<PeriodDocument[]>(
    []
  );

  useEffect(() => {
    setSelectedDocuments(
      documents.filter((document) =>
        document.references.find(
          (ref) =>
            ref.type === DocumentReferenceTypes.account &&
            ref.key === accountNumber
        )
      )
    );
  }, [accountNumber, documents]);

  const handleChangeDocument = async (updatedDocuments: PeriodDocument[]) => {
    const documentToAdd = updatedDocuments.find(
      (doc) => !selectedDocuments.includes(doc)
    );
    if (documentToAdd) {
      await sdk.updatePeriodDocumentReference({
        clientid: clientId,
        periodId: lastPeriod.id,
        documentId: documentToAdd.id,
        accountNumber,
      });
    }

    const documentsToDelete = selectedDocuments.filter(
      (doc) => !updatedDocuments.includes(doc)
    );
    if (documentsToDelete.length) {
      const results = documentsToDelete.map(async (item) => {
        await sdk.deletePeriodDocumentReference({
          clientid: clientId,
          periodId: lastPeriod.id,
          documentId: item.id,
          accountNumber,
          accountGroup,
        });
      });

      await Promise.all(results);
    }

    setSelectedDocuments(updatedDocuments);
  };

  return (
    <Autocomplete
      options={documents}
      value={selectedDocuments}
      onChange={(e, value) => handleChangeDocument(value)}
      getOptionLabel={(option) => option.name}
      renderOption={(option, { selected }) => (
        <div>
          <SmallCheckbox checked={selected} size="small" />
          {removeExtension(option.name)}
        </div>
      )}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Chip
            key={option.name}
            label={option.name}
            {...getTagProps({ index })}
          />
        ))
      }
      renderInput={(params) => <TextField variant="outlined" {...params} />}
      PopperComponent={StyledAutocompletePopper}
      fullWidth
      multiple
    />
  );
};

export default SelectAccountDocuments;

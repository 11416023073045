import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';
import {
  AgoyTableColumn,
  numberValue,
  ReferenceCell,
  stringValue,
} from '@agoy/document';
import { PlainIntegerNumberFormatType } from '@agoy/common';
import { useIntl } from 'react-intl';
import { TableRow as CommonTableRow } from '_shared/components/CommonTable';
import { TableCell as BorderTableCell } from '_shared/components/Table/BorderTable';
import { Cell } from '_shared/components/CommonTable/Cell';
import TableServiceContext from '_shared/components/CommonTable/Context/TableServiceContext';
import { SubRowType } from '_shared/components/CommonTable/TableBody';
import CommonEditLabel from './EditLabel';

type TaxDocumentTableAccountRowProps = {
  editing: boolean;
  subRow: SubRowType;
  columns: AgoyTableColumn[];
  selectedAccounts: string[];
};

const EditLabel = styled(CommonEditLabel)`
  width: initial;
`;

const TableCell = styled(BorderTableCell)`
  border: none;
  padding-left: 0;
  font-size: 1rem;

  .account-number {
    font-weight: 700;
    white-space: nowrap;
  }

  .account-name {
    max-width: 500px;
    word-break: break-all;
  }
`;

const TableRow = styled(CommonTableRow)`
  td {
    border: none;
  }
`;

const TaxDocumentTableAccountRow = ({
  editing,
  columns,
  subRow,
  selectedAccounts,
}: TaxDocumentTableAccountRowProps) => {
  const { formatMessage } = useIntl();
  const service = useContext(TableServiceContext);
  if (!service) {
    return null;
  }

  return (
    <TableRow
      columns={columns}
      baseId="taxViewDocument"
      tableId={subRow.baseId}
      editing={editing}
      canDeleteRows
      row={subRow.row}
      renderCell={({ row, column, cell }) => {
        const cellId = `${subRow.baseId}.${row.id}.${column.id}`;

        if (column.id === 'accountNumber') {
          const accountNumber = numberValue(cell);

          if (editing && accountNumber === undefined) {
            return (
              <TableCell key={cellId} align="left">
                <EditLabel
                  tooltip={formatMessage({ id: 'edit.newrow.tooltip' })}
                  rowId={row.id}
                  value={
                    'value' in cell && typeof cell.value === 'string'
                      ? cell.value
                      : ''
                  }
                  tableSelectedAccounts={selectedAccounts}
                  onChange={(value) => {
                    service.updateCellValue(
                      `${subRow.baseId}.${row.id}.accountNumber`,
                      value
                    );
                  }}
                  onAccountSelected={(
                    selectedAccount,
                    _selectedAccountName,
                    _accountId,
                    close
                  ) => {
                    if (selectedAccount === undefined) {
                      return;
                    }

                    service
                      .updateCellValue(
                        `${subRow.baseId}.${row.id}.accountNumber`,
                        parseInt(selectedAccount, 10)
                      )
                      ?.then(() => {
                        service.updateCellValue(
                          `${subRow.baseId}.${row.id}.value`,
                          {
                            ...row.cells?.value,
                            type: 'ref',
                          } as ReferenceCell
                        );
                      });

                    if (close) {
                      close();
                    }
                  }}
                />
              </TableCell>
            );
          }
          return (
            <TableCell key={cellId} align="left">
              <Cell
                id={cellId}
                cell={cell}
                numberFormatType={PlainIntegerNumberFormatType}
                className={
                  cell.type !== 'string' ? 'account-number' : 'account-name'
                }
              />
            </TableCell>
          );
        }

        if (column.id === 'label') {
          const refValue = stringValue(cell);

          return (
            <TableCell key={cellId} align="left" className="label-cell">
              <Typography color="textSecondary">{refValue}</Typography>
            </TableCell>
          );
        }
        if (column.id === 'value') {
          const refValue = numberValue(cell);

          if (
            (cell.type !== 'ref' || refValue === undefined) &&
            row.id.startsWith('@') &&
            editing
          ) {
            return (
              <TableCell key={cellId} align="right">
                <Cell editing id={cellId} cell={cell} />
              </TableCell>
            );
          }

          return (
            <TableCell key={cellId} align="right">
              <Cell id={cellId} cell={cell} />
            </TableCell>
          );
        }

        return null;
      }}
    />
  );
};

export default TaxDocumentTableAccountRow;

import React from 'react';
import { Box } from '@material-ui/core';
import styled from '@emotion/styled';

const Header = styled(({ mainTable, ...props }) => <Box {...props} />)<{
  mainTable: boolean;
}>`
  > * {
    margin: 0;
  }
`;

export default Header;

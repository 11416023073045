import {
  ClientCustomersAssigneeType,
  ClientCustomersTagsType,
} from '_clients/types/types';
import {
  TOGGLE_LAMPS_EXPANDED,
  TOGGLE_HIDE_CLEAN_CLIENTS,
  SET_SORT_TYPE,
  SET_SORT_SEARCH,
  SET_ACTIVE_TAGS,
  SET_ACTIVE_ASSIGNEES,
  SET_COMPANY_TYPE_FILTER,
  SET_FILTERS,
} from './action-types';
import { SortType, CompanyTypeFilterType, Filters } from './types';

interface SetSortByType {
  type: typeof SET_SORT_TYPE;
  sortType: SortType;
}

interface ToggleLampsExpanded {
  type: typeof TOGGLE_LAMPS_EXPANDED;
}

interface ToggleHideCleanClients {
  type: typeof TOGGLE_HIDE_CLEAN_CLIENTS;
}

interface SetSortSearch {
  type: typeof SET_SORT_SEARCH;
  search: string;
}

interface SetActiveTags {
  type: typeof SET_ACTIVE_TAGS;
  activeClientTags: ClientCustomersTagsType;
}

interface SetActiveAssignees {
  type: typeof SET_ACTIVE_ASSIGNEES;
  activeClientAssignees: ClientCustomersAssigneeType;
}
interface SetCompanyTypeFilter {
  type: typeof SET_COMPANY_TYPE_FILTER;
  companyTypeFilter: CompanyTypeFilterType;
}

interface SetFilters {
  type: typeof SET_FILTERS;
  filters: Filters;
}

export const toggleLampsExpanded = (): ToggleLampsExpanded => ({
  type: TOGGLE_LAMPS_EXPANDED,
});

export const toggleHideCleanClients = (): ToggleHideCleanClients => ({
  type: TOGGLE_HIDE_CLEAN_CLIENTS,
});

export const setSortByType = (sortType: SortType): SetSortByType => ({
  type: SET_SORT_TYPE,
  sortType,
});

export const setSortBySearch = (search: string): SetSortSearch => ({
  type: SET_SORT_SEARCH,
  search,
});

export const setFilters = (filters: Filters): SetFilters => ({
  type: SET_FILTERS,
  filters,
});

export const setActiveTags = (activeClientTags: ClientCustomersTagsType) => ({
  type: SET_ACTIVE_TAGS,
  activeClientTags,
});

export const setActiveAssignees = (
  activeClientAssignees: ClientCustomersAssigneeType
) => ({
  type: SET_ACTIVE_ASSIGNEES,
  activeClientAssignees,
});

export type SetSortClientsActions =
  | ToggleLampsExpanded
  | SetSortByType
  | SetSortSearch
  | ToggleHideCleanClients
  | SetCompanyTypeFilter
  | SetActiveTags
  | SetActiveAssignees
  | SetFilters;

import { initialState } from 'redux/reducers/UI/types';
import { SET_UI_STATUS } from '../../actionsTypes';

export type setUiStatusType = (state: Partial<initialState>) => {
  type: typeof SET_UI_STATUS;
  state: Partial<initialState>;
};

export const setUiStatus: setUiStatusType = (state) => ({
  type: SET_UI_STATUS,
  state,
});

import React, { useCallback, useContext, useMemo } from 'react';
import { AgoyTableRow } from '@agoy/document';
import { Table, TableServiceType } from '_shared/components/CommonTable';
import {
  ccyFormat,
  NumberFormatType,
  PercentageNumberFormatType,
  StandardNumberFormatType,
} from '@agoy/common';

import { InventoryObsolescenceDataType } from './types';
import {
  VOUCHERS_SPECIFICATION_STOCK,
  VOUCHERS_TRANSACTION_DESCRIPTION,
} from './lagerjusteringUtil';
import PeriodDataContext from '../PeriodDataContext';

type Props = {
  data: InventoryObsolescenceDataType | undefined;
  handleUpdateData?: (
    id: string,
    cell: string,
    value: string | number | boolean | undefined
  ) => void;
  ub: number | undefined;
  isEditing: boolean;
  isPrint?: boolean;
  handleStockValueChange?: (value: number) => void;
};

const defaultColumns = [
  { id: 'description', align: 'left' },
  { id: 'reference', align: 'right' },
  { id: 'account', align: 'right' },
  { id: 'value', align: 'right' },
];

const usePercentageForColumn = (colId: string): NumberFormatType =>
  colId === 'reference' ? PercentageNumberFormatType : StandardNumberFormatType;

const LagerjusteringTable = ({
  data,
  handleUpdateData,
  ub,
  isEditing,
  isPrint,
  handleStockValueChange,
}: Props) => {
  const { periodLocked: isLocked } = useContext(PeriodDataContext);
  const defaultRows = [
    {
      active: true,
      id: '1',
      cells: {
        description: {
          type: 'string',
          value: '',
          align: 'left',
        },
        reference: {
          type: 'ref',
          value: undefined,
          align: 'right',
        },
        account: {
          type: 'number',
          value: 0,
          align: 'right',
        },
        value: {
          type: 'string',
          value: 0,
          align: 'right',
        },
      },
    },
    {
      active: true,
      id: '2',
      cells: {
        description: {
          type: 'label',
          value: VOUCHERS_TRANSACTION_DESCRIPTION,
          align: 'left',
        },
        reference: {
          type: 'ref',
          value: 0.03,
          align: 'right',
        },
        account: {
          type: 'number',
          value: 0,
          align: 'right',
        },
        value: {
          type: 'string',
          value: 0,
          align: 'right',
        },
      },
    },
    {
      active: true,
      id: '3',
      cells: {
        description: {
          type: 'label',
          value: VOUCHERS_SPECIFICATION_STOCK,
          align: 'left',
        },
        reference: {
          type: 'label',
          value: undefined,
          align: 'right',
        },
        account: {
          type: 'number',
          value: null,
          align: 'right',
        },
        value: {
          type: 'string',
          value: 0,
          align: 'right',
        },
      },
    },
  ];

  const rows: AgoyTableRow[] = useMemo(() => {
    if (data && ub) {
      return defaultRows.map((item) => {
        const updatedCells = {};
        const { cells } = item;
        defaultColumns.forEach((col) => {
          updatedCells[col.id] = cells[col.id];
          if (col.id === 'account') {
            updatedCells[col.id].type = 'string';
          }

          if (cells.description.value === VOUCHERS_TRANSACTION_DESCRIPTION) {
            if (col.id === 'reference') {
              updatedCells[col.id].value = data.rate;
            }

            if (col.id === 'account') {
              updatedCells[col.id].value = data.account;
            }

            if (col.id === 'value') {
              updatedCells[col.id].value = ccyFormat(ub * data.rate, 2);
            }
          }

          if (cells.description.value === VOUCHERS_SPECIFICATION_STOCK) {
            if (col.id === 'value') {
              if (handleStockValueChange) {
                handleStockValueChange(ub - ub * data.rate);
              }
              updatedCells[col.id].value = ccyFormat(ub - ub * data.rate, 2);
            }
          }

          if (
            cells.description.value !== VOUCHERS_TRANSACTION_DESCRIPTION &&
            cells.description.value !== VOUCHERS_SPECIFICATION_STOCK
          ) {
            if (col.id === 'description') {
              updatedCells[col.id].value = data.description;
            }

            if (col.id === 'value') {
              updatedCells[col.id].value = ub;
            }

            if (col.id === 'account') {
              updatedCells[col.id].value = data.account;
            }

            if (col.id === 'reference') {
              updatedCells[col.id].value = data.reference;
              updatedCells[col.id].type = 'string';
            }
          }
        });

        return {
          id: `${item.id}`,
          active: true,
          cells: updatedCells,
        };
      });
    }

    return defaultRows.map((item) => {
      const updatedCells = {};
      const { cells } = item;
      defaultColumns.forEach((col) => {
        updatedCells[col.id] = cells[col.id];

        if (
          cells.description.value !== VOUCHERS_TRANSACTION_DESCRIPTION &&
          cells.description.value !== VOUCHERS_SPECIFICATION_STOCK
        ) {
          if (col.id === 'value') {
            updatedCells[col.id].value = ub;
          }
        }
      });

      return {
        id: `${item.id}`,
        active: true,
        cells: updatedCells,
      };
    });
  }, [data]);

  const handleUpdateCell = useCallback(
    (cellId: string, value: string | number | boolean | undefined) => {
      if (handleUpdateData) {
        const [, rowId, cell] = cellId.split('.');
        handleUpdateData(rowId, cell, value);
      }
    },
    [handleUpdateData]
  );

  const service = useMemo(() => {
    const newService = {} as TableServiceType;
    newService.updateField = handleUpdateCell;
    return newService;
  }, [handleUpdateCell]);

  return (
    <>
      <Table
        baseId="hidden"
        tableId="lagerjustering"
        rows={rows}
        columns={defaultColumns}
        service={service}
        canAddRows={false}
        canDeleteRows={false}
        editing={isEditing}
        numberFormatColumn={usePercentageForColumn}
        nonEditableRowColumns={['account', 'value']}
        isLocked={isLocked}
        onColumnBlur={handleUpdateCell}
        print={isPrint}
      />
    </>
  );
};

export default LagerjusteringTable;

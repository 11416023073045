import {
  abs,
  account,
  id,
  ifOrElse,
  label,
  multiply,
  or,
  ref,
  ReferenceCell,
  sum,
  value,
} from '@agoy/document';
import {
  boolean,
  cell,
  Note28Section,
  NoteInvertedTwoTableSection,
  noteInvertedTwoTableSectionStructure,
  NoteOneTableSection,
  noteOneTableSectionStructure,
  NoteOneTableWithSum,
  noteOneTableWithSumStructure,
  NoteTwoTableSection,
  noteTwoTableSectionStructure,
} from '../../../common';
import { previousYear, year } from '../../../common/utils/date-util';
import { active, field, range, table } from '../../../common/utils/util';

export interface BalanceSheetNotes {
  note43: NoteOneTableSection;
  note6: NoteOneTableSection;
  note7: NoteOneTableSection;
  note8: NoteOneTableSection;
  note9: NoteOneTableSection;
  note10: NoteOneTableSection;
  note11: NoteOneTableSection;
  note12: NoteOneTableSection;
  note13: NoteOneTableSection;
  note14: NoteOneTableSection;
  note15: NoteOneTableSection;
  note16: NoteInvertedTwoTableSection;
  note17: NoteOneTableSection;
  note18: NoteInvertedTwoTableSection;
  note19: NoteOneTableSection;
  note20: NoteInvertedTwoTableSection;
  note21: NoteOneTableSection;
  note22: NoteOneTableSection;
  note23: NoteOneTableSection;
  note24: NoteOneTableSection;
  note25: NoteOneTableSection;
  note26: NoteOneTableWithSum;
  note27: NoteOneTableSection;
  note28: Note28Section;
  note29: NoteOneTableWithSum;
  note30: NoteOneTableWithSum;
  note31: NoteOneTableWithSum;
  note32: NoteOneTableWithSum;
  note40: NoteOneTableSection;
  note41: NoteOneTableSection;
  note42: NoteOneTableSection;
  note44: NoteOneTableSection;
  note55: NoteOneTableSection;
  note57: NoteTwoTableSection;
}

export const balanceSheetNotesStructure = {
  note43: noteOneTableSectionStructure,
  note6: noteOneTableSectionStructure,
  note7: noteOneTableSectionStructure,
  note8: noteOneTableSectionStructure,
  note9: noteOneTableSectionStructure,
  note10: noteOneTableSectionStructure,
  note11: noteOneTableSectionStructure,
  note12: noteOneTableSectionStructure,
  note13: noteOneTableSectionStructure,
  note14: noteOneTableSectionStructure,
  note15: noteOneTableSectionStructure,
  note16: noteInvertedTwoTableSectionStructure,
  note17: noteOneTableSectionStructure,
  // only secondTable to inherit tableId
  note18: noteInvertedTwoTableSectionStructure,
  note19: noteOneTableSectionStructure,
  // only secondTable to inherit tableId
  note20: noteInvertedTwoTableSectionStructure,
  note21: noteOneTableSectionStructure,
  note22: noteOneTableSectionStructure,
  note23: noteOneTableSectionStructure,
  note24: noteOneTableSectionStructure,
  note25: noteOneTableSectionStructure,
  note26: noteOneTableWithSumStructure,
  note27: noteOneTableSectionStructure,
  note28: {
    type: 'part' as const,
    children: {
      active: boolean,
      number: cell,
      name: cell,
      general: cell,
      table: { type: 'table' as const },
      secondTable: { type: 'table' as const },
      sumTable: { type: 'table' as const },
      commentLabel: cell,
      comment: cell,
      group: cell,
      section: cell,
      validForReportType: {
        type: 'part' as const,
        children: {
          k2: boolean,
          k3: boolean,
        },
      },
    },
  },
  note29: noteOneTableWithSumStructure,
  note30: noteOneTableWithSumStructure,
  note31: noteOneTableWithSumStructure,
  note32: noteOneTableWithSumStructure,
  // 40-42 and 44 must come after note 32 and before 33
  // they are all balance sheet notes
  note40: noteOneTableSectionStructure,
  note41: noteOneTableSectionStructure,
  note42: noteOneTableSectionStructure,
  note44: noteOneTableSectionStructure,
  note55: noteOneTableSectionStructure,
  note57: noteTwoTableSectionStructure,
};

/**
 * Returns the sum of all IBs (incoming balances) for the specified
 * accounts or range(s) of accounts for the client accounting period(s).
 * Supports getting IBs from any period
 *
 * Example: ref(sumPositiveTransactions('1220:1227+1230:1237'))
 * @param accounts: string with account(s).
 * @param areAccountsOver2000: false if the accounts used go until 1999.
 * @optionalParam periodName: period name to be used when resolving references.
 * The default is the current financial year.
 */
const sumAccountsIBs = (
  accounts: string,
  areAccountsOver2000: boolean,
  periodName?: string
) =>
  or(
    sum(
      areAccountsOver2000
        ? multiply(-1, account(accounts, 'ib', periodName))
        : account(accounts, 'ib', periodName),
      0
    ),
    0
  );

/**
 * Returns the sum of all negative (Credit) transactions for the specified
 * accounts or range(s) of accounts for the client's currently selected accounting year.
 * Should not include inverse transactions in the same date for an account.
 * Example: ref(sumNegativeTransactions('1220:1227+1230:1237'))
 * @param accounts: string with account(s).
 * @optionalParam periodName: period name to be used when resolving references.
 * The default is the current financial year.
 */
const sumNegativeTransactions = (accounts: string, periodName?: string) =>
  or(sum(account(accounts, 'creditWithoutInverse', periodName), 0), 0);

/**
 * Returns the sum of all positive (Debit) transactions for the specified
 * accounts or range(s) of accounts for the client's currently selected accounting year.
 * Should not include inverse transactions in the same date for an account.
 *  Example: ref(sumPositiveTransactions('1220:1227+1230:1237'))
 * @param accounts: string with account(s).
 * @optionalParam periodName: period name to be used when resolving references.
 * The default is the current financial year.
 */
const sumPositiveTransactions = (accounts: string, periodName?: string) =>
  or(sum(account(accounts, 'debitWithoutInverse', periodName), 0), 0);

/**
 * Returns the sum for a specified NoteNumber for the rowIds specified
 * in the current year or previous year.
 * @param NoteNumber: Ex: 1 = Redovisningsprinciper
 * @param rowIds: array of row Ids in a note.
 * @param endId: string that represents a column's id.
 * @param table = 'table' is default. This is the usually the case when there's only one table.
 */
const sumNotesRowsById = (
  NoteNumber: number,
  rowIds: (string | number)[],
  endId:
    | 'year'
    | 'previousYear'
    | 'reportedValue'
    | 'taxValue'
    | 'temporaryDifference',
  table = 'table'
): ReferenceCell =>
  ref(
    ifOrElse(
      sum(
        ...[...rowIds].map((idNumber) => {
          return abs(
            or(id(`notes.note${NoteNumber}.${table}.${idNumber}.${endId}`), 0)
          );
        })
      ),
      sum(
        ...[...rowIds].map((idNumber) => {
          return or(
            id(`notes.note${NoteNumber}.${table}.${idNumber}.${endId}`),
            0
          );
        })
      ),
      undefined
    )
  );

/**
 * Returns the sum of all UBs (outgoing balances) for the specified
 * accounts or range(s) of accounts for the client's currently selected accounting year.
 * @param accounts: string with account(s)
 * @param areAccountsOver2000: false if the accounts used go until 1999.
 * @optionalParam periodName: period name to be used when resolving references.
 * The default is the current financial year.
 */
const sumAccountsUBs = (
  accounts: string,
  areAccountsOver2000: boolean,
  periodName?: string
) =>
  or(
    sum(
      areAccountsOver2000
        ? multiply(-1, account(accounts, 'ub', periodName))
        : account(accounts, 'ub', periodName),
      0
    ),
    0
  );

export const getFoundationBalanceSheetNotes = (
  financialYears: string[],
  financialYear: string,
  period: string | null,
  isDigitalSubmission: boolean
): BalanceSheetNotes => {
  const noteLabel = (name: string) =>
    isDigitalSubmission ? label(name) : value(name);

  return {
    note43: {
      active: false,
      number: value(undefined),
      name: value(
        'Balanserade utgifter för utvecklingsarbeten och liknande arbeten'
      ),
      table: table(
        `notes.note43.table`,
        { id: 'label', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        .addRows((rows) =>
          rows
            .addRow(
              '1',
              noteLabel('Ingående utgifter för utvecklingsarbete'),
              ref(sumAccountsIBs('1000:1017', false)),
              ref(sumAccountsIBs('1000:1017', false, 'previousYear'))
            )
            .addRow(
              '2',
              noteLabel('Upparbetat under året'),
              ref(sumPositiveTransactions('1000:1017')),
              ref(sumPositiveTransactions('1000:1017', 'previousYear'))
            )
            .addRow(
              '3',
              noteLabel('Försäljningar/utrangeringar'),
              ref(sumNegativeTransactions('1000:1017')),
              ref(sumNegativeTransactions('1000:1017', 'previousYear'))
            )
            .addRow(
              '4',
              noteLabel('Omklassificeringar'), // no accounts
              value(undefined),
              value(undefined)
            )
            .addRow(
              '5',
              noteLabel('Utgående ackumulerade utgifter för utvecklingsarbete'),
              sumNotesRowsById(43, range(1, 5), 'year'),
              sumNotesRowsById(43, range(1, 5), 'previousYear')
            )
            .addRow(
              '6',
              noteLabel('Ingående avskrivningar'),
              ref(sumAccountsIBs('1019', false)),
              ref(sumAccountsIBs('1019', false, 'previousYear'))
            )
            .addRow(
              '7',
              noteLabel('Försäljningar/utrangeringar'),
              ref(sumPositiveTransactions('1019')),
              ref(sumPositiveTransactions('1019', 'previousYear'))
            )
            .addRow(
              '8',
              noteLabel('Omklassificeringar'), // no accounts
              value(undefined),
              value(undefined)
            )
            .addRow(
              '9',
              noteLabel('Årets avskrivningar'),
              ref(sumNegativeTransactions('1019')),
              ref(sumNegativeTransactions('1019', 'previousYear'))
            )
            .addRow(
              '10',
              noteLabel('Utgående ackumulerade avskrivningar'),
              sumNotesRowsById(43, range(6, 10), 'year'),
              sumNotesRowsById(43, range(6, 10), 'previousYear')
            )
            .addRow(
              '11',
              noteLabel('Ingående nedskrivningar'),
              ref(sumAccountsIBs('1018', false)),
              ref(sumAccountsIBs('1018', false, 'previousYear'))
            )
            .addRow(
              '12',
              noteLabel('Försäljningar/utrangeringar'),
              ref(sumPositiveTransactions('1018')),
              ref(sumPositiveTransactions('1018', 'previousYear'))
            )
            .addRow(
              '13',
              noteLabel('Återförd nedskrivning'),
              value(undefined), // no accounts
              value(undefined)
            )
            .addRow(
              '14',
              noteLabel('Omklassificeringar'),
              value(undefined), // no accounts
              value(undefined)
            )
            .addRow(
              '15',
              noteLabel('Årets nedskrivningar'),
              ref(sumNegativeTransactions('1018')),
              ref(sumNegativeTransactions('1018', 'previousYear'))
            )
            .addRow(
              '16',
              noteLabel('Utgående ackumulerade nedskrivningar'),
              sumNotesRowsById(43, range(11, 16), 'year'),
              sumNotesRowsById(43, range(11, 16), 'previousYear')
            )
            .addRow(
              '17',
              noteLabel('Utgående redovisat värde'),
              sumNotesRowsById(43, [5, 10, 16], 'year'),
              sumNotesRowsById(43, [5, 10, 16], 'previousYear')
            )
            .addRow(
              '18',
              noteLabel(
                'Tillgångar anskaffade med offentligt bidrag ingår med redovisat anskaffningsvärde'
              ),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '19',
              noteLabel(
                'I ovanstående redovisat värde ingår leasade tillgångar med'
              ),
              value(undefined),
              value(undefined)
            )
            .build()
        )
        .newRowTemplate(value(''), value(undefined), value(undefined))
        .build(),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note6: {
      active: false,
      number: value(undefined),
      name: value(
        'Koncessioner, patent, licenser, varumärken samt liknande rättigheter'
      ),
      table: table(
        `notes.note6.table`,
        { id: 'label', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        // 'ingående' = previous period outgoing balance
        .addRows((rows) =>
          rows
            .addRow(
              '1',
              noteLabel('Ingående anskaffningsvärden'),
              ref(sumAccountsIBs('1020+1030+1040+1050', false)),
              ref(sumAccountsIBs('1020+1030+1040+1050', false, 'previousYear'))
            )
            .addRow(
              '2',
              noteLabel('Inköp'),
              ref(
                sumPositiveTransactions(
                  '1020:1027+1030:1037+1040:1047+1050:1057'
                )
              ),
              ref(
                sumPositiveTransactions(
                  '1020:1027+1030:1037+1040:1047+1050:1057',
                  'previousYear'
                )
              )
            )
            .addRow(
              'businessAcquisitions',
              noteLabel('Rörelseförvärv'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'anskaffningsvardenFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '3',
              noteLabel('Försäljningar/utrangeringar'),
              ref(
                sumNegativeTransactions(
                  '1020:1027+1030:1037+1040:1047+1050:1057'
                )
              ),
              ref(
                sumNegativeTransactions(
                  '1020:1027+1030:1037+1040:1047+1050:1057',
                  'previousYear'
                )
              )
            )
            .addRow(
              '4',
              noteLabel('Omklassificeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'anskaffningsvardenConversionDifferences',
              noteLabel('Omräkningsdifferenser'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '5',
              noteLabel('Utgående anskaffningsvärden'),
              sumNotesRowsById(
                6,
                [
                  ...range(1, 5),
                  'anskaffningsvardenConversionDifferences',
                  'businessAcquisitions',
                  'anskaffningsvardenFusion',
                ],
                'year'
              ),
              sumNotesRowsById(
                6,
                [
                  ...range(1, 5),
                  'anskaffningsvardenConversionDifferences',
                  'businessAcquisitions',
                  'anskaffningsvardenFusion',
                ],
                'previousYear'
              )
            )
            .addRow(
              '6',
              noteLabel('Ingående avskrivningar'),
              ref(sumAccountsIBs('1029+1039+1049+1059', false)),
              ref(sumAccountsIBs('1029+1039+1049+1059', false, 'previousYear'))
            )
            .addRow(
              'avskrivningarFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '7',
              noteLabel('Försäljningar/utrangeringar'),
              ref(sumPositiveTransactions('1029+1039+1049+1059')),
              ref(
                sumPositiveTransactions('1029+1039+1049+1059', 'previousYear')
              )
            )
            .addRow(
              '8',
              noteLabel('Omklassificeringar'), // no accounts
              value(undefined),
              value(undefined)
            )
            .addRow(
              '9',
              noteLabel('Årets avskrivningar'),
              ref(sumNegativeTransactions('1029+1039+1049+1059')),
              ref(
                sumNegativeTransactions('1029+1039+1049+1059', 'previousYear')
              )
            )
            .addRow(
              'avskrivningarConversionDifferences',
              noteLabel('Omräkningsdifferenser'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '10',
              noteLabel('Utgående avskrivningar'),
              sumNotesRowsById(
                6,
                [
                  ...range(6, 10),
                  'avskrivningarFusion',
                  'avskrivningarConversionDifferences',
                ],
                'year'
              ),
              sumNotesRowsById(
                6,
                [
                  ...range(6, 10),
                  'avskrivningarFusion',
                  'avskrivningarConversionDifferences',
                ],
                'previousYear'
              )
            )
            .addRow(
              '11',
              noteLabel('Ingående nedskrivningar'),
              ref(sumAccountsIBs('1028+1038+1048+1058', false)),
              ref(sumAccountsIBs('1028+1038+1048+1058', false, 'previousYear'))
            )
            .addRow(
              'nedskrivningarFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '12',
              noteLabel('Försäljningar/utrangeringar'),
              ref(sumPositiveTransactions('1028+1038+1048+1058')),
              ref(
                sumPositiveTransactions('1028+1038+1048+1058', 'previousYear')
              )
            )
            .addRow(
              '13',
              noteLabel('Återförda nedskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '14',
              noteLabel('Omklassificeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '15',
              noteLabel('Årets nedskrivningar'),
              ref(sumNegativeTransactions('1028+1038+1048+1058')),
              ref(
                sumNegativeTransactions('1028+1038+1048+1058', 'previousYear')
              )
            )
            .addRow(
              'nedskrivningarConversionDifferences',
              noteLabel('Omräkningsdifferenser'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '16',
              noteLabel('Utgående nedskrivningar'),
              sumNotesRowsById(
                6,
                [
                  ...range(11, 16),
                  'nedskrivningarFusion',
                  'nedskrivningarConversionDifferences',
                ],
                'year'
              ),
              sumNotesRowsById(
                6,
                [
                  ...range(11, 16),
                  'nedskrivningarFusion',
                  'nedskrivningarConversionDifferences',
                ],
                'previousYear'
              )
            )
            .addRow(
              '17',
              noteLabel('Utgående Redovisat värde'),
              sumNotesRowsById(6, [5, 10, 16], 'year'),
              sumNotesRowsById(6, [5, 10, 16], 'previousYear')
            )
            .addRow(
              '18',
              noteLabel('Förändringar av anskaffningsvärden'),
              ref(sumAccountsIBs('1020+1030+1040+1050', false)),
              ref(sumAccountsIBs('1020+1030+1040+1050', false, 'previousYear'))
            )
            .addRow(
              '19',
              noteLabel('Förändringar av avskrivningar'),
              ref(sumAccountsIBs('1029+1039+1049+1059', false)),
              ref(sumAccountsIBs('1029+1039+1049+1059', false, 'previousYear'))
            )
            .addRow(
              '20',
              noteLabel('Förändringar av nedskrivningar'),
              ref(sumAccountsIBs('1028+1038+1048+1058', false)),
              ref(sumAccountsIBs('1028+1038+1048+1058', false, 'previousYear'))
            )
            .build()
        )
        .newRowTemplate(value(''), value(undefined), value(undefined))
        .build(),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note7: {
      active: false,
      number: value(undefined),
      name: value('Hyresrätter och liknande rättigheter'),
      table: table(
        `notes.note7.table`,
        { id: 'label', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        // 'ingående' = previous period outgoing balance
        .addRows((rows) =>
          rows
            .addRow(
              '1',
              noteLabel('Ingående anskaffningsvärden'),
              ref(sumAccountsIBs('1060', false)),
              ref(sumAccountsIBs('1060', false, 'previousYear'))
            )
            .addRow(
              '2',
              noteLabel('Inköp'),
              ref(sumPositiveTransactions('1060:1067')),
              ref(sumPositiveTransactions('1060:1067', 'previousYear'))
            )
            .addRow(
              'businessAcquisitions',
              noteLabel('Rörelseförvärv'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'anskaffningsvardenFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '3',
              noteLabel('Försäljningar/utrangeringar'),
              ref(sumNegativeTransactions('1060:1067')),
              ref(sumNegativeTransactions('1060:1067', 'previousYear'))
            )
            .addRow(
              '4',
              noteLabel('Omklassificeringar'), // no accounts
              value(undefined),
              value(undefined)
            )
            .addRow(
              'anskaffningsvardenConversionDifferences',
              noteLabel('Omräkningsdifferenser'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '5',
              noteLabel('Utgående anskaffningsvärden'),
              sumNotesRowsById(
                7,
                [
                  ...range(1, 5),
                  'businessAcquisitions',
                  'anskaffningsvardenFusion',
                  'anskaffningsvardenConversionDifferences',
                ],
                'year'
              ),
              sumNotesRowsById(
                7,
                [
                  ...range(1, 5),
                  'businessAcquisitions',
                  'anskaffningsvardenFusion',
                  'anskaffningsvardenConversionDifferences',
                ],
                'previousYear'
              )
            )
            .addRow(
              '6',
              noteLabel('Ingående avskrivningar'),
              ref(sumAccountsIBs('1069', false)),
              ref(sumAccountsIBs('1069', false, 'previousYear'))
            )
            .addRow(
              'avskrivningarFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '7',
              noteLabel('Försäljningar/utrangeringar'),
              ref(sumPositiveTransactions('1069')),
              ref(sumPositiveTransactions('1069', 'previousYear'))
            )
            .addRow(
              '8',
              noteLabel('Omklassificeringar'), // no accounts
              value(undefined),
              value(undefined)
            )
            .addRow(
              '9',
              noteLabel('Årets avskrivningar'),
              ref(sumNegativeTransactions('1069')),
              ref(sumNegativeTransactions('1069', 'previousYear'))
            )
            .addRow(
              'avskrivningarConversionDifferences',
              noteLabel('Omräkningsdifferenser'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '10',
              noteLabel('Utgående avskrivningar'),
              sumNotesRowsById(
                7,
                [
                  ...range(6, 10),
                  'avskrivningarFusion',
                  'avskrivningarConversionDifferences',
                ],
                'year'
              ),
              sumNotesRowsById(
                7,
                [
                  ...range(6, 10),
                  'avskrivningarFusion',
                  'avskrivningarConversionDifferences',
                ],
                'previousYear'
              )
            )
            .addRow(
              '11',
              noteLabel('Ingående nedskrivningar'),
              ref(sumAccountsIBs('1068', false)),
              ref(sumAccountsIBs('1068', false, 'previousYear'))
            )
            .addRow(
              'nedskrivningarFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '12',
              noteLabel('Försäljningar/utrangeringar'),
              ref(sumPositiveTransactions('1068')),
              ref(sumPositiveTransactions('1068', 'previousYear')),
              value(undefined)
            )
            .addRow(
              '13',
              noteLabel('Återförda nedskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '14',
              noteLabel('Omklassificeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '15',
              noteLabel('Årets nedskrivningar'),
              ref(sumNegativeTransactions('1068')),
              ref(sumNegativeTransactions('1068', 'previousYear'))
            )
            .addRow(
              'nedskrivningarConversionDifferences',
              noteLabel('Omräkningsdifferenser'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '16',
              noteLabel('Utgående nedskrivningar'),
              sumNotesRowsById(
                7,
                [
                  ...range(11, 16),
                  'nedskrivningarFusion',
                  'nedskrivningarConversionDifferences',
                ],
                'year'
              ),
              sumNotesRowsById(
                7,
                [
                  ...range(11, 16),
                  'nedskrivningarFusion',
                  'nedskrivningarConversionDifferences',
                ],
                'previousYear'
              )
            )
            .addRow(
              '17',
              noteLabel('Redovisat värde'),
              sumNotesRowsById(7, [5, 10, 16], 'year'),
              sumNotesRowsById(7, [5, 10, 16], 'previousYear')
            )
            .addRow(
              '18',
              noteLabel('Förändringar av anskaffningsvärden'),
              undefined,
              undefined
            )
            .addRow(
              '19',
              noteLabel('Förändringar av avskrivningar'),
              undefined,
              undefined
            )
            .addRow(
              '20',
              noteLabel('Förändringar av nedskrivningar'),
              undefined,
              undefined
            )
            .build()
        )
        .newRowTemplate(value(''), value(undefined), value(undefined))
        .build(),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note8: {
      active: false,
      number: value(undefined),
      name: value('Goodwill'),
      table: table(
        `notes.note8.table`,
        { id: 'label', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        .addRows((rows) =>
          rows
            // 'ingående' = previous period outgoing balance
            .addRow(
              '1',
              noteLabel('Ingående anskaffningsvärden'),
              ref(sumAccountsIBs('1070', false)),
              ref(sumAccountsIBs('1070', false, 'previousYear'))
            )
            // In this note, Rörelseförvärv now contains what inköp used to contain
            .addRow(
              '2',
              noteLabel('Rörelseförvärv'),
              ref(sumPositiveTransactions('1070:1077')),
              ref(sumPositiveTransactions('1070:1077', 'previousYear'))
            )
            .addRow(
              'anskaffningsvardenFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '3',
              noteLabel('Försäljningar/utrangeringar'),
              ref(sumNegativeTransactions('1070:1077')),
              ref(sumNegativeTransactions('1070:1077', 'previousYear'))
            )
            .addRow(
              '4',
              noteLabel('Omklassificeringar'), // no accounts
              value(undefined),
              value(undefined)
            )
            .addRow(
              'anskaffningsvardenConversionDifferences',
              noteLabel('Omräkningsdifferenser'), // no accounts
              value(undefined),
              value(undefined)
            )
            .addRow(
              '5',
              noteLabel('Utgående anskaffningsvärden'),
              sumNotesRowsById(
                8,
                [
                  ...range(1, 5),
                  'anskaffningsvardenFusion',
                  'anskaffningsvardenConversionDifferences',
                ],
                'year'
              ),
              sumNotesRowsById(
                8,
                [
                  ...range(1, 5),
                  'anskaffningsvardenFusion',
                  'anskaffningsvardenConversionDifferences',
                ],
                'previousYear'
              )
            )
            .addRow(
              '6',
              noteLabel('Ingående avskrivningar'),
              ref(sumAccountsIBs('1079', false)),
              ref(sumAccountsIBs('1079', false, 'previousYear'))
            )
            .addRow(
              'avskrivningarFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '7',
              noteLabel('Försäljningar/utrangeringar'),
              ref(sumPositiveTransactions('1079')),
              ref(sumPositiveTransactions('1079', 'previousYear'))
            )
            .addRow(
              '8',
              noteLabel('Omklassificeringar'), // no accounts
              value(undefined),
              value(undefined)
            )
            .addRow(
              '9',
              noteLabel('Årets avskrivningar'),
              ref(sumNegativeTransactions('1079')),
              ref(sumNegativeTransactions('1079', 'previousYear'))
            )
            .addRow(
              'avskrivningarConversionDifferences',
              noteLabel('Omräkningsdifferenser'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '10',
              noteLabel('Utgående avskrivningar'),
              sumNotesRowsById(
                8,
                [
                  ...range(6, 10),
                  'avskrivningarFusion',
                  'avskrivningarConversionDifferences',
                ],
                'year'
              ),
              sumNotesRowsById(
                8,
                [
                  ...range(6, 10),
                  'avskrivningarFusion',
                  'avskrivningarConversionDifferences',
                ],
                'previousYear'
              )
            )
            .addRow(
              '11',
              noteLabel('Ingående nedskrivningar'),
              ref(sumAccountsIBs('1078', false)),
              ref(sumAccountsIBs('1078', false, 'previousYear'))
            )
            .addRow(
              'nedskrivningarFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '12',
              noteLabel('Försäljningar/utrangeringar'),
              ref(sumPositiveTransactions('1078')),
              ref(sumPositiveTransactions('1078', 'previousYear'))
            )
            .addRow(
              '13',
              noteLabel('Återförda nedskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '14',
              noteLabel('Omklassificeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '15',
              noteLabel('Årets nedskrivningar'),
              ref(sumNegativeTransactions('1078')),
              ref(sumNegativeTransactions('1078', 'previousYear'))
            )
            .addRow(
              'nedskrivningarConversionDifferences',
              noteLabel('Omräkningsdifferenser'),
              value(undefined),
              value(undefined)
            )

            .addRow(
              '16',
              noteLabel('Utgående nedskrivningar'),
              sumNotesRowsById(
                8,
                [
                  ...range(11, 16),
                  'nedskrivningarFusion',
                  'nedskrivningarConversionDifferences',
                ],
                'year'
              ),
              sumNotesRowsById(
                8,
                [
                  ...range(11, 16),
                  'nedskrivningarFusion',
                  'nedskrivningarConversionDifferences',
                ],
                'previousYear'
              )
            )
            .addRow(
              '17',
              noteLabel('Redovisat värde'),
              sumNotesRowsById(8, [5, 10, 16], 'year'),
              sumNotesRowsById(8, [5, 10, 16], 'previousYear')
            )
            .addRow(
              '18',
              noteLabel('Förändringar av anskaffningsvärden'),
              undefined,
              undefined
            )
            .addRow(
              '19',
              noteLabel('Förändringar av avskrivningar'),
              undefined,
              undefined
            )
            .addRow(
              '20',
              noteLabel('Förändringar av nedskrivningar'),
              undefined,
              undefined
            )
            .build()
        )
        .newRowTemplate(value(''), value(undefined), value(undefined))
        .build(),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note9: {
      active: false,
      number: value(undefined),
      name: value('Förskott avseende immateriella anläggningstillgångar'),
      table: table(
        'notes.note9.table',
        { id: 'label', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        // 'ingående' = previous period outgoing balance
        .addRows((rows) =>
          rows
            .addRow(
              '1',
              noteLabel('Ingående anskaffningsvärden'),
              ref(sumAccountsIBs('1080:1088', false)),
              ref(sumAccountsIBs('1080:1088', false, 'previousYear'))
            )
            .addRow(
              'advancesPaid',
              noteLabel('Utbetalda förskott'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'businessAcquisitions',
              noteLabel('Rörelseförvärv'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'anskaffningsvardenFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            // the order changed, but the old id must remain
            .addRow(
              '3',
              noteLabel('Återbetalda förskott'),
              ref(sumNegativeTransactions('1080:1089')),
              ref(sumNegativeTransactions('1080:1089', 'previousYear'))
            )
            .addRow(
              'anskaffningsvardenWrittenAdvances',
              noteLabel('Bortskrivna förskott'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '2',
              noteLabel('Omklassificeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'anskaffningsvardenConversionDifferences',
              noteLabel('Omräkningsdifferenser'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'anskaffningsvardenSum',
              noteLabel('Utgående anskaffningsvärden'),
              sumNotesRowsById(
                9,
                [
                  ...range(1, 4),
                  'advancesPaid',
                  'businessAcquisitions',
                  'anskaffningsvardenFusion',
                  'anskaffningsvardenConversionDifferences',
                ],
                'year'
              ),
              sumNotesRowsById(
                9,
                [
                  ...range(1, 4),
                  'advancesPaid',
                  'businessAcquisitions',
                  'anskaffningsvardenFusion',
                  'anskaffningsvardenConversionDifferences',
                ],
                'previousYear'
              )
            )
            .addRow(
              'incomingWriteDowns',
              noteLabel('Ingående nedskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'nedskrivningarFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'nedskrivningarReversal',
              noteLabel('Återförda nedskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'nedskrivningarWrittenAdvances',
              noteLabel('Bortskrivna förskott'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'nedskrivningarReclassifications',
              noteLabel('Omklassificeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'nedskrivningarYearWriteDowns',
              noteLabel('Årets nedskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'nedskrivningarConversionDifferences',
              noteLabel('Omräkningsdifferenser'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'nedskrivningarSum',
              noteLabel('Utgående nedskrivningar'),
              sumNotesRowsById(
                9,
                [
                  'incomingWriteDowns',
                  'nedskrivningarFusion',
                  'nedskrivningarReversal',
                  'nedskrivningarWrittenAdvances',
                  'nedskrivningarReclassifications',
                  'nedskrivningarYearWriteDowns',
                  'nedskrivningarConversionDifferences',
                ],
                'year'
              ),
              sumNotesRowsById(
                9,
                [
                  'incomingWriteDowns',
                  'nedskrivningarFusion',
                  'nedskrivningarReversal',
                  'nedskrivningarWrittenAdvances',
                  'nedskrivningarReclassifications',
                  'nedskrivningarYearWriteDowns',
                  'nedskrivningarConversionDifferences',
                ],
                'previousYear'
              )
            )
            .addRow(
              'totalSum',
              noteLabel('Redovisat värde'),
              sumNotesRowsById(
                9,
                ['anskaffningsvardenSum', 'nedskrivningarSum'],
                'year'
              ),
              sumNotesRowsById(
                9,
                ['anskaffningsvardenSum', 'nedskrivningarSum'],
                'previousYear'
              )
            )
            .addRow(
              '3',
              noteLabel('Förändringar av anskaffningsvärden'),
              undefined,
              undefined
            )
            .build()
        )
        .newRowTemplate(value(''), value(undefined), value(undefined))
        .build(),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note10: {
      active: false,
      number: value(undefined),
      name: value('Byggnader och mark'),
      table: table(
        `notes.note10.table`,
        { id: 'label', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        // 'ingående' = previous period outgoing balance
        .addRows((rows) =>
          rows
            .addRow(
              '1',
              noteLabel('Ingående anskaffningsvärden'),
              ref(sumAccountsIBs('1100:1117+1150:1157', false)),
              ref(sumAccountsIBs('1100:1117+1150:1157', false, 'previousYear'))
            )
            .addRow(
              '2',
              noteLabel('Inköp'),
              ref(
                sumPositiveTransactions(
                  '1100:1117+1130:1139+1140:1149+1150:1157'
                )
              ),
              ref(
                sumPositiveTransactions(
                  '1100:1117+1130:1139+1140:1149+1150:1157',
                  'previousYear'
                )
              )
            )
            .addRow(
              'businessAcquisitions',
              noteLabel('Rörelseförvärv'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'anskaffningsvardenFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '3',
              noteLabel('Försäljningar/utrangeringar'),
              ref(
                sumNegativeTransactions(
                  '1100:1117+1130:1139+1140:1149+1150:1157'
                )
              ),
              ref(
                sumNegativeTransactions(
                  '1100:1117+1130:1139+1140:1149+1150:1157',
                  'previousYear'
                )
              )
            )
            .addRow(
              '4',
              noteLabel('Omklassificeringar'), // no accounts
              value(undefined),
              value(undefined)
            )
            .addRow(
              'anskaffningsvardenConversionDifferences',
              noteLabel('Omräkningsdifferenser'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '5',
              noteLabel('Utgående anskaffningsvärden'),
              sumNotesRowsById(
                10,
                [
                  ...range(1, 5),
                  'businessAcquisitions',
                  'anskaffningsvardenFusion',
                  'anskaffningsvardenConversionDifferences',
                ],
                'year'
              ),
              sumNotesRowsById(
                10,
                [
                  ...range(1, 5),
                  'businessAcquisitions',
                  'anskaffningsvardenFusion',
                  'anskaffningsvardenConversionDifferences',
                ],
                'previousYear'
              )
            )
            .addRow(
              '6',
              noteLabel('Ingående avskrivningar'),
              ref(sumAccountsIBs('1119+1129+1159', false)),
              ref(sumAccountsIBs('1119+1129+1159', false, 'previousYear'))
            )
            .addRow(
              'avskrivningarFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '7',
              noteLabel('Försäljningar/utrangeringar'),
              ref(sumPositiveTransactions('1119+1159')),
              ref(sumPositiveTransactions('1119+1159', 'previousYear'))
            )
            .addRow(
              '8',
              noteLabel('Omklassificeringar'), // no accounts
              value(undefined),
              value(undefined)
            )
            .addRow(
              '9',
              noteLabel('Årets avskrivningar'),
              ref(sumNegativeTransactions('1119+1159')),
              ref(sumNegativeTransactions('1119+1159', 'previousYear'))
            )
            .addRow(
              'avskrivningarConversionDifferences',
              noteLabel('Omräkningsdifferenser'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '10',
              noteLabel('Utgående avskrivningar'),
              sumNotesRowsById(
                10,
                [
                  ...range(6, 10),
                  'avskrivningarFusion',
                  'avskrivningarConversionDifferences',
                ],
                'year'
              ),
              sumNotesRowsById(
                10,
                [
                  ...range(6, 10),
                  'avskrivningarFusion',
                  'avskrivningarConversionDifferences',
                ],
                'previousYear'
              )
            )
            .addRow(
              'incomingRevaluations',
              noteLabel('Ingående uppskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'uppskrivningarFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'uppskrivningarSales',
              noteLabel('Försäljningar/utrangeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'uppskrivningarReclassifications',
              noteLabel('Omklassificeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'uppskrivningarYearRevaluations',
              noteLabel('Årets uppskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'uppskrivningarDepreciation',
              noteLabel('Årets avskrivningar på uppskrivet belopp'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'uppskrivningarConversionDifferences',
              noteLabel('Omräkningsdifferenser'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'uppskrivningarSum',
              noteLabel('Utgående uppskrivningar'),
              sumNotesRowsById(
                10,
                [
                  'incomingRevaluations',
                  'uppskrivningarFusion',
                  'uppskrivningarSales',
                  'uppskrivningarReclassifications',
                  'uppskrivningarYearRevaluations',
                  'uppskrivningarDepreciation',
                  'uppskrivningarConversionDifferences',
                ],
                'year'
              ),
              sumNotesRowsById(
                10,
                [
                  'incomingRevaluations',
                  'uppskrivningarFusion',
                  'uppskrivningarSales',
                  'uppskrivningarReclassifications',
                  'uppskrivningarYearRevaluations',
                  'uppskrivningarDepreciation',
                  'uppskrivningarConversionDifferences',
                ],
                'previousYear'
              )
            )
            .addRow(
              '11',
              noteLabel('Ingående nedskrivningar'),
              ref(sumAccountsIBs('1118+1158', false)),
              ref(sumAccountsIBs('1118+1158', false, 'previousYear'))
            )
            .addRow(
              'nedskrivningarFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '12',
              noteLabel('Försäljningar/utrangeringar'),
              ref(sumPositiveTransactions('1118+1158')),
              ref(sumPositiveTransactions('1118+1158', 'previousYear'))
            )
            .addRow(
              '13',
              noteLabel('Återförda nedskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '14',
              noteLabel('Omklassificeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '15',
              noteLabel('Årets nedskrivningar'),
              ref(sumNegativeTransactions('1118+1158')),
              ref(sumNegativeTransactions('1118+1158', 'previousYear'))
            )
            .addRow(
              'nedskrivningarConversionDifferences',
              noteLabel('Omräkningsdifferenser'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '16',
              noteLabel('Utgående nedskrivningar'),
              sumNotesRowsById(
                10,
                [
                  ...range(11, 16),
                  'nedskrivningarFusion',
                  'nedskrivningarConversionDifferences',
                ],
                'year'
              ),
              sumNotesRowsById(
                10,
                [
                  ...range(11, 16),
                  'nedskrivningarFusion',
                  'nedskrivningarConversionDifferences',
                ],
                'previousYear'
              )
            )
            .addRow(
              '17',
              noteLabel('Redovisat värde'),
              sumNotesRowsById(10, [5, 10, 16, 'uppskrivningarSum'], 'year'),
              sumNotesRowsById(
                10,
                [5, 10, 16, 'uppskrivningarSum'],
                'previousYear'
              )
            )
            .addRow(
              '18',
              noteLabel('Mark'),
              ref(sumAccountsIBs('1130:1139+1140:1149', false)),
              ref(sumAccountsIBs('1130:1139+1140:1149', false, 'previousYear'))
            )
            .addRow(
              '19',
              noteLabel('Mark är inte föremål för avskrivningar.'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '20',
              noteLabel('Förändringar av anskaffningsvärden'),
              undefined
            )
            .addRow(
              '21',
              noteLabel('Förändringar av uppskrivningar'),
              undefined
            )
            .addRow(
              '22',
              noteLabel('Förändringar av nedskrivningar'),
              undefined,
              undefined
            )
            // AGOY-3022 DO NOT USE IDs of 18
            // or higher in this note, there used to be data stored until id 25
            .build()
        )
        .newRowTemplate(value(''), value(undefined), value(undefined))
        .build(),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note11: {
      active: false,
      number: value(undefined),
      name: value('Maskiner och andra tekniska anläggningar'),
      table: table(
        `notes.note11.table`,
        { id: 'label', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        .addRows((rows) =>
          rows
            // 'ingående' = previous period outgoing balance
            .addRow(
              '1',
              noteLabel('Ingående anskaffningsvärden'),
              ref(
                sumAccountsIBs(
                  '1210:1211+1213:1220+1221:1222+1223:1225+1230:1231+1232:1240+1241:1242+1243:1244+1245:1246+1247:1250+1251:1257',
                  false
                )
              ),
              ref(
                sumAccountsIBs(
                  '1210:1211+1213:1220+1221:1222+1223:1225+1230:1231+1232:1240+1241:1242+1243:1244+1245:1246+1247:1250+1251:1257',
                  false,
                  'previousYear'
                )
              )
            )
            .addRow(
              '2',
              noteLabel('Inköp'),
              ref(sumPositiveTransactions('1200:1217')),
              ref(sumPositiveTransactions('1200:1217', 'previousYear'))
            )
            .addRow(
              'businessAcquisitions',
              noteLabel('Rörelseförvärv'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'anskaffningsvardenFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '3',
              noteLabel('Försäljningar/utrangeringar'),
              ref(sumNegativeTransactions('1200:1217')),
              ref(sumNegativeTransactions('1200:1217', 'previousYear'))
            )
            .addRow(
              '4',
              noteLabel('Omklassificeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'anskaffningsvardenConversionDifferences',
              noteLabel('Omräkningsdifferenser'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '5',
              noteLabel('Utgående anskaffningsvärden'),
              sumNotesRowsById(
                11,
                [
                  ...range(1, 5),
                  'businessAcquisitions',
                  'anskaffningsvardenFusion',
                  'anskaffningsvardenConversionDifferences',
                ],
                'year'
              ),
              sumNotesRowsById(
                11,
                [
                  ...range(1, 5),
                  'businessAcquisitions',
                  'anskaffningsvardenFusion',
                  'anskaffningsvardenConversionDifferences',
                ],
                'previousYear'
              )
            )
            .addRow(
              '6',
              noteLabel('Ingående avskrivningar'),
              ref(sumAccountsIBs('1219:1249+1259:1269', false)),
              ref(sumAccountsIBs('1219:1249+1259:1269', false, 'previousYear'))
            )
            .addRow(
              'avskrivningarFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '7',
              noteLabel('Försäljningar/utrangeringar'),
              ref(sumPositiveTransactions('1219')),
              ref(sumPositiveTransactions('1219', 'previousYear'))
            )
            .addRow(
              '8',
              noteLabel('Omklassificeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '9',
              noteLabel('Årets avskrivningar'),
              ref(sumNegativeTransactions('1219')),
              ref(sumNegativeTransactions('1219', 'previousYear'))
            )
            .addRow(
              'avskrivningarConversionDifferences',
              noteLabel('Omräkningsdifferenser'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '10',
              noteLabel('Utgående avskrivningar'),
              sumNotesRowsById(
                11,
                [
                  ...range(6, 10),
                  'avskrivningarFusion',
                  'avskrivningarConversionDifferences',
                ],
                'year'
              ),
              sumNotesRowsById(
                11,
                [
                  ...range(6, 10),
                  'avskrivningarFusion',
                  'avskrivningarConversionDifferences',
                ],
                'previousYear'
              )
            )
            .addRow(
              '11',
              noteLabel('Ingående nedskrivningar'),
              ref(sumAccountsIBs('1218:1248+1258:1269', false)),
              ref(sumAccountsIBs('1218:1248+1258:1269', false, 'previousYear'))
            )
            .addRow(
              'nedskrivningarFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '12',
              noteLabel('Försäljningar/utrangeringar'),
              ref(sumPositiveTransactions('1218')),
              ref(sumPositiveTransactions('1218', 'previousYear'))
            )
            .addRow(
              '13',
              noteLabel('Återförda nedskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '14',
              noteLabel('Omklassificeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '15',
              noteLabel('Årets nedskrivningar'),
              ref(sumNegativeTransactions('1218')),
              ref(sumNegativeTransactions('1218', 'previousYear'))
            )
            .addRow(
              'nedskrivningarConversionDifferences',
              noteLabel('Omklassificeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '16',
              noteLabel('Utgående nedskrivningar'),
              sumNotesRowsById(
                11,
                [
                  ...range(11, 16),
                  'nedskrivningarFusion',
                  'nedskrivningarConversionDifferences',
                ],
                'year'
              ),
              sumNotesRowsById(
                11,
                [
                  ...range(11, 16),
                  'nedskrivningarFusion',
                  'nedskrivningarConversionDifferences',
                ],
                'previousYear'
              )
            )
            .addRow(
              '17',
              noteLabel('Redovisat värde'),
              sumNotesRowsById(11, [5, 10, 16], 'year'),
              sumNotesRowsById(11, [5, 10, 16], 'previousYear')
            )
            .addRow(
              '18',
              noteLabel('Förändringar av anskaffningsvärden'),
              undefined,
              undefined
            )
            .addRow(
              '19',
              noteLabel('Förändringar av avskrivningar'),
              undefined,
              undefined
            )
            .addRow(
              '20',
              noteLabel('Förändringar av nedskrivningar'),
              undefined,
              undefined
            )
            .build()
        )
        .newRowTemplate(value(''), value(undefined), value(undefined))
        .build(),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note12: {
      active: false,
      number: value(undefined),
      name: value('Inventarier, verktyg och installationer'),
      table: table(
        `notes.note12.table`,
        { id: 'label', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        .addRows((rows) =>
          rows
            // 'ingående' = previous period outgoing balance
            .addRow(
              '1',
              noteLabel('Ingående anskaffningsvärden'),
              ref(sumAccountsIBs('1220:1227+1230:1237+1240:1247', false)),
              ref(
                sumAccountsIBs(
                  '1220:1227+1230:1237+1240:1247',
                  false,
                  'previousYear'
                )
              )
            )
            .addRow(
              '2',
              noteLabel('Inköp'),
              ref(
                sumPositiveTransactions(
                  '1220:1227+1230:1237+1240:1247+1250:1257'
                )
              ),
              ref(
                sumPositiveTransactions(
                  '1220:1227+1230:1237+1240:1247+1250:1257',
                  'previousYear'
                )
              )
            )
            .addRow(
              'businessAcquisitions',
              noteLabel('Rörelseförvärv'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'anskaffningsvardenFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '3',
              noteLabel('Försäljningar/utrangeringar'),
              ref(
                sumNegativeTransactions(
                  '1220:1227+1230:1237+1240:1247+1250:1257'
                )
              ),
              ref(
                sumNegativeTransactions(
                  '1220:1227+1230:1237+1240:1247+1250:1257',
                  'previousYear'
                )
              )
            )
            .addRow(
              '4',
              noteLabel('Omklassificeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'anskaffningsvardenConversionDifferences',
              noteLabel('Omräkningsdifferenser'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '5',
              noteLabel('Utgående anskaffningsvärden'),
              sumNotesRowsById(
                12,
                [
                  ...range(1, 5),
                  'businessAcquisitions',
                  'anskaffningsvardenFusion',
                  'anskaffningsvardenConversionDifferences',
                ],
                'year'
              ),
              sumNotesRowsById(
                12,
                [
                  ...range(1, 5),
                  'businessAcquisitions',
                  'anskaffningsvardenFusion',
                  'anskaffningsvardenConversionDifferences',
                ],
                'previousYear'
              )
            )
            .addRow(
              '6',
              noteLabel('Ingående avskrivningar'),
              ref(sumAccountsIBs('1229+1239+1249', false)),
              ref(sumAccountsIBs('1229+1239+1249', false, 'previousYear'))
            )
            .addRow(
              'avskrivningarFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '7',
              noteLabel('Försäljningar/utrangeringar'),
              ref(sumPositiveTransactions('1229+1239+1249+1259')),
              ref(
                sumPositiveTransactions('1229+1239+1249+1259', 'previousYear')
              )
            )
            .addRow(
              '8',
              noteLabel('Omklassificeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '9',
              noteLabel('Årets avskrivningar'),
              ref(sumNegativeTransactions('1229+1239+1249+1259')),
              ref(
                sumNegativeTransactions('1229+1239+1249+1259', 'previousYear')
              )
            )
            .addRow(
              'avskrivningarConversionDifferences',
              noteLabel('Omräkningsdifferenser'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '10',
              noteLabel('Utgående avskrivningar'),
              sumNotesRowsById(
                12,
                [
                  ...range(6, 10),
                  'avskrivningarFusion',
                  'avskrivningarConversionDifferences',
                ],
                'year'
              ),
              sumNotesRowsById(
                12,
                [
                  ...range(6, 10),
                  'avskrivningarFusion',
                  'avskrivningarConversionDifferences',
                ],
                'previousYear'
              )
            )
            .addRow(
              '11',
              noteLabel('Ingående nedskrivningar'),
              ref(sumAccountsIBs('1228+1238+1248', false)),
              ref(sumAccountsIBs('1228+1238+1248', false, 'previousYear'))
            )
            .addRow(
              'nedskrivningarFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '12',
              noteLabel('Försäljningar/utrangeringar'),
              ref(sumPositiveTransactions('1228+1238+1248+1258')),
              ref(
                sumPositiveTransactions('1228+1238+1248+1258', 'previousYear')
              )
            )
            .addRow(
              '13',
              noteLabel('Återförda nedskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '14',
              noteLabel('Omklassificeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '15',
              noteLabel('Årets nedskrivningar'),
              ref(sumNegativeTransactions('1228+1238+1248+1258')),
              ref(
                sumNegativeTransactions('1228+1238+1248+1258', 'previousYear')
              )
            )
            .addRow(
              'nedskrivningarConversionDifferences',
              noteLabel('Omräkningsdifferenser'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '16',
              noteLabel('Utgående nedskrivningar'),
              sumNotesRowsById(
                12,
                [
                  ...range(11, 16),
                  'nedskrivningarFusion',
                  'nedskrivningarConversionDifferences',
                ],
                'year'
              ),
              sumNotesRowsById(
                12,
                [
                  ...range(11, 16),
                  'nedskrivningarFusion',
                  'nedskrivningarConversionDifferences',
                ],
                'previousYear'
              )
            )
            .addRow(
              '17',
              noteLabel('Redovisat värde'),
              sumNotesRowsById(12, [5, 10, 16], 'year'),
              sumNotesRowsById(12, [5, 10, 16], 'previousYear')
            )
            .addRow(
              '18',
              noteLabel('Förändringar av anskaffningsvärden'),
              undefined,
              undefined
            )
            .addRow(
              '19',
              noteLabel('Förändringar av avskrivningar'),
              undefined,
              undefined
            )
            .addRow(
              '20',
              noteLabel('Förändringar av nedskrivningar'),
              undefined,
              undefined
            )
            .build()
        )
        .newRowTemplate(value(''), value(undefined), value(undefined))
        .build(),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note13: {
      active: false,
      number: value(undefined),
      name: value('Förbättringsutgifter på annans fastighet'),
      table: table(
        `notes.note13.table`,
        { id: 'label', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        .addRows((rows) =>
          rows
            // 'ingående' = previous period outgoing balance
            .addRow(
              '1',
              noteLabel('Ingående anskaffningsvärden'),
              ref(sumAccountsIBs('1120', false)),
              ref(sumAccountsIBs('1120', false, 'previousYear'))
            )
            .addRow(
              '2',
              noteLabel('Inköp'),
              ref(sumPositiveTransactions('1120:1128')),
              ref(sumPositiveTransactions('1120:1128', 'previousYear'))
            )
            .addRow(
              'businessAcquisitions',
              noteLabel('Rörelseförvärv'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'anskaffningsvardenFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '3',
              noteLabel('Försäljningar/utrangeringar'),
              ref(sumNegativeTransactions('1120:1128')),
              ref(sumNegativeTransactions('1120:1128', 'previousYear'))
            )
            .addRow(
              '4',
              noteLabel('Omklassificeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'anskaffningsvardenConversionDifferences',
              noteLabel('Omräkningsdifferenser'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '5',
              noteLabel('Utgående anskaffningsvärden'),
              sumNotesRowsById(
                13,
                [
                  ...range(1, 5),
                  'businessAcquisitions',
                  'anskaffningsvardenFusion',
                  'anskaffningsvardenConversionDifferences',
                ],
                'year'
              ),
              sumNotesRowsById(
                13,
                [
                  ...range(1, 5),
                  'businessAcquisitions',
                  'anskaffningsvardenFusion',
                  'anskaffningsvardenConversionDifferences',
                ],
                'previousYear'
              )
            )
            .addRow(
              '6',
              noteLabel('Ingående avskrivningar'),
              ref(sumAccountsIBs('1129', false)),
              ref(sumAccountsIBs('1129', false, 'previousYear'))
            )
            .addRow(
              'avskrivningarFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '7',
              noteLabel('Försäljningar/utrangeringar'),
              ref(sumPositiveTransactions('1129')),
              ref(sumPositiveTransactions('1129', 'previousYear'))
            )
            .addRow(
              '8',
              noteLabel('Omklassificeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '9',
              noteLabel('Årets avskrivningar'),
              ref(sumNegativeTransactions('1129')),
              ref(sumNegativeTransactions('1129', 'previousYear'))
            )
            .addRow(
              'avskrivningarConversionDifferences',
              noteLabel('Omräkningsdifferenser'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '10',
              noteLabel('Utgående avskrivningar'),
              sumNotesRowsById(
                13,
                [
                  ...range(6, 10),
                  'avskrivningarFusion',
                  'avskrivningarConversionDifferences',
                ],
                'year'
              ),
              sumNotesRowsById(
                13,
                [
                  ...range(6, 10),
                  'avskrivningarFusion',
                  'avskrivningarConversionDifferences',
                ],
                'previousYear'
              )
            )
            // ID 11 was renamed to totalSum, because it contains the addition
            // of all other sums of note 13
            .addRow(
              '12',
              noteLabel('Ingående nedskrivningar'),
              ref(sumAccountsIBs('1128', false)),
              ref(sumAccountsIBs('1128', false, 'previousYear'))
            )
            .addRow(
              'nedskrivningarFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '13',
              noteLabel('Försäljningar/utrangeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '14',
              noteLabel('Återförda nedskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '15',
              noteLabel('Omklassificeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '16',
              noteLabel('Årets nedskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'nedskrivningarConversionDifferences',
              noteLabel('Omräkningsdifferenser'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '17',
              noteLabel('Utgående nedskrivningar'),
              sumNotesRowsById(
                13,
                [
                  ...range(12, 17),
                  'nedskrivningarFusion',
                  'nedskrivningarConversionDifferences',
                ],
                'year'
              ),
              sumNotesRowsById(
                13,
                [
                  ...range(12, 17),
                  'nedskrivningarFusion',
                  'nedskrivningarConversionDifferences',
                ],
                'previousYear'
              )
            )
            .addRow(
              'totalSum',
              noteLabel('Redovisat värde'),
              sumNotesRowsById(13, [5, 10, 17], 'year'),
              sumNotesRowsById(13, [5, 10, 17], 'previousYear')
            )
            .addRow(
              '18',
              noteLabel('Förändringar av anskaffningsvärden'),
              undefined,
              undefined
            )
            .addRow(
              '19',
              noteLabel('Förändringar av avskrivningar'),
              undefined,
              undefined
            )
            .addRow(
              '20',
              noteLabel('Förändringar av nedskrivningar'),
              undefined,
              undefined
            )
            .build()
        )
        .newRowTemplate(value(''), value(undefined), value(undefined))
        .build(),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note14: {
      active: false,
      number: value(undefined),
      name: value('Övriga materiella anläggningstillgångar'),
      table: table(
        `notes.note14.table`,
        { id: 'label', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        .addRows((rows) =>
          rows
            // 'ingående' = previous period outgoing balance
            .addRow(
              '1',
              noteLabel('Ingående anskaffningsvärden'),
              ref(sumAccountsIBs('1250+1260+1290+1291+1292', false)),
              ref(
                sumAccountsIBs(
                  '1250+1260+1290+1291+1292',
                  false,
                  'previousYear'
                )
              )
            )
            .addRow(
              'expensesIncurred',
              noteLabel('Nedlagda utgifter'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'advancesPaid',
              noteLabel('Utbetalda förskott'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '2',
              noteLabel('Rörelseförvärv'),
              ref(sumPositiveTransactions('1180:1189+1280:1289')),
              ref(
                sumPositiveTransactions('1180:1189+1280:1289', 'previousYear')
              )
            )
            .addRow(
              'avskrivningarFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '3',
              noteLabel('Försäljningar/utrangeringar'),
              ref(sumNegativeTransactions('1180:1189+1280:1289')),
              ref(
                sumNegativeTransactions('1180:1189+1280:1289', 'previousYear')
              )
            )
            .addRow(
              'refundedAdvances',
              noteLabel('Återbetalda förskott'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'anskaffningsvardenWrittenAdvances',
              noteLabel('Bortskrivna förskott'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '4',
              noteLabel('Omklassificeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'anskaffningsvardenConversionDifferences',
              noteLabel('Omräkningsdifferenser'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '5',
              noteLabel('Utgående anskaffningsvärden'),
              sumNotesRowsById(
                15,
                [
                  ...range(1, 5),
                  'expensesIncurred',
                  'advancesPaid',
                  'anskaffningsvardenFusion',
                  'refundedAdvances',
                  'anskaffningsvardenWrittenAdvances',
                  'anskaffningsvardenConversionDifferences',
                ],
                'year'
              ),
              sumNotesRowsById(
                15,
                [
                  ...range(1, 5),
                  'expensesIncurred',
                  'advancesPaid',
                  'anskaffningsvardenFusion',
                  'refundedAdvances',
                  'anskaffningsvardenWrittenAdvances',
                  'anskaffningsvardenConversionDifferences',
                ],
                'previousYear'
              )
            )
            .addRow(
              '6',
              noteLabel('Utgående avskrivningar'),
              sumNotesRowsById(
                15,
                [
                  ...range(1, 5),
                  'avskrivningarFusion',
                  'avskrivningarConversionDifferences',
                ],
                'year'
              ),
              sumNotesRowsById(
                15,
                [
                  ...range(1, 5),
                  'avskrivningarFusion',
                  'avskrivningarConversionDifferences',
                ],
                'previousYear'
              )
            )
            .addRow(
              '7',
              noteLabel('Ingående nedskrivningar'),
              ref(sumAccountsIBs('1258+1298', false)),
              ref(sumAccountsIBs('1258+1298', false, 'previousYear'))
            )
            .addRow(
              'nedskrivningarFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '8',
              noteLabel('Försäljningar/utrangeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '9',
              noteLabel('Återförda nedskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'nedskrivningarWrittenAdvances',
              noteLabel('Bortskrivna förskott'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '10',
              noteLabel('Omklassificeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '11',
              noteLabel('Årets nedskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'nedskrivningarConversionDifferences',
              noteLabel('Omräkningsdifferenser'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '12',
              noteLabel('Utgående nedskrivningar'),
              sumNotesRowsById(
                15,
                [
                  ...range(6, 11),
                  'nedskrivningarFusion',
                  'nedskrivningarWrittenAdvances',
                  'nedskrivningarConversionDifferences',
                ],
                'year'
              ),
              sumNotesRowsById(
                15,
                [
                  ...range(6, 11),
                  'nedskrivningarFusion',
                  'nedskrivningarWrittenAdvances',
                  'nedskrivningarConversionDifferences',
                ],
                'previousYear'
              )
            )
            .addRow(
              '13',
              noteLabel('Redovisat värde'),
              sumNotesRowsById(15, [5, 11], 'year'),
              sumNotesRowsById(15, [5, 11], 'previousYear')
            )
            .addRow(
              '14',
              noteLabel('Förändringar av anskaffningsvärden'),
              undefined,
              undefined
            )
            .addRow(
              '15',
              noteLabel('Förändringar av avskrivningar'),
              undefined,
              undefined
            )
            .addRow(
              '16',
              noteLabel('Förändringar av nedskrivningar'),
              undefined,
              undefined
            )
            .build()
        )
        .newRowTemplate(value(''), value(undefined), value(undefined))
        .build(),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note15: {
      active: false,
      number: value(undefined),
      name: value(
        'Pågående nyanläggningar och förskott avseende materiella anläggningstillgångar'
      ),
      table: table(
        'notes.note15.table',
        { id: 'label', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        .addRows((rows) =>
          rows
            // 'ingående' = previous period outgoing balance
            .addRow(
              '1',
              noteLabel('Ingående anskaffningsvärden'),
              ref(sumAccountsIBs('1280', false)),
              ref(sumAccountsIBs('1280', false, 'previousYear'))
            )
            .addRow(
              'expensesIncurred',
              noteLabel('Nedlagda utgifter'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'advancesPaid',
              noteLabel('Utbetalda förskott'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '2',
              noteLabel('Rörelseförvärv'),
              ref(sumPositiveTransactions('1180:1189+1280:1289')),
              ref(
                sumPositiveTransactions('1180:1189+1280:1289', 'previousYear')
              )
            )
            .addRow(
              'anskaffningsvardenFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '3',
              noteLabel('Försäljningar/utrangeringar'),
              ref(sumNegativeTransactions('1180:1189+1280:1289')),
              ref(
                sumNegativeTransactions('1180:1189+1280:1289', 'previousYear')
              )
            )
            .addRow(
              'refundedAdvances',
              noteLabel('Återbetalda förskott'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'anskaffningsvardenWrittenAdvances',
              noteLabel('Bortskrivna förskott'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '4',
              noteLabel('Omklassificeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'anskaffningsvardenConversionDifferences',
              noteLabel('Omräkningsdifferenser'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '5',
              noteLabel('Utgående anskaffningsvärden'),
              sumNotesRowsById(
                15,
                [
                  ...range(1, 5),
                  'expensesIncurred',
                  'advancesPaid',
                  'anskaffningsvardenFusion',
                  'refundedAdvances',
                  'anskaffningsvardenWrittenAdvances',
                  'anskaffningsvardenConversionDifferences',
                ],
                'year'
              ),
              sumNotesRowsById(
                15,
                [
                  ...range(1, 5),
                  'expensesIncurred',
                  'advancesPaid',
                  'anskaffningsvardenFusion',
                  'refundedAdvances',
                  'anskaffningsvardenWrittenAdvances',
                  'anskaffningsvardenConversionDifferences',
                ],
                'previousYear'
              )
            )
            .addRow(
              '6',
              noteLabel('Ingående avskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '7',
              noteLabel('Ingående nedskrivningar'),
              ref(sumAccountsIBs('1288', false)),
              ref(sumAccountsIBs('1288', false, 'previousYear'))
            )
            .addRow(
              'nedskrivningarFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '8',
              noteLabel('Försäljningar/utrangeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '9',
              noteLabel('Återförda nedskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'nedskrivningarWrittenAdvances',
              noteLabel('Bortskrivna förskott'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '10',
              noteLabel('Omklassificeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '11',
              noteLabel('Årets nedskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'nedskrivningarConversionDifferences',
              noteLabel('Omräkningsdifferenser'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '12',
              noteLabel('Utgående nedskrivningar'),
              sumNotesRowsById(
                15,
                [
                  ...range(6, 11),
                  'nedskrivningarFusion',
                  'nedskrivningarWrittenAdvances',
                  'nedskrivningarConversionDifferences',
                ],
                'year'
              ),
              sumNotesRowsById(
                15,
                [
                  ...range(6, 11),
                  'nedskrivningarFusion',
                  'nedskrivningarWrittenAdvances',
                  'nedskrivningarConversionDifferences',
                ],
                'previousYear'
              )
            )
            .addRow(
              '13',
              noteLabel('Redovisat värde'),
              sumNotesRowsById(15, [5, 11], 'year'),
              sumNotesRowsById(15, [5, 11], 'previousYear')
            )
            .addRow(
              '14',
              noteLabel('Förändringar av anskaffningsvärden'),
              undefined,
              undefined
            )
            .addRow(
              '15',
              noteLabel('Förändringar av nedskrivningar'),
              undefined,
              undefined
            )
            .build()
        )
        .newRowTemplate(value(''), value(undefined), value(undefined))
        .build(),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note16: {
      active: false,
      number: value(undefined),
      name: value('Andelar i koncernföretag'),
      secondTable: table(
        'notes.note16.secondTable',
        { id: 'companyName', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        .addRows((rows) =>
          rows
            // 'ingående' = previous period outgoing balance
            .addRow(
              '1',
              noteLabel('Ingående anskaffningsvärden'),
              ref(sumAccountsIBs('1310+1311+1312+1313+1314+1316+1317', false)),
              ref(
                sumAccountsIBs(
                  '1310+1311+1312+1313+1314+1316+1317',
                  false,
                  'previousYear'
                )
              )
            )
            .addRow('2', noteLabel('Inköp'), value(undefined), value(undefined))
            .addRow(
              'anskaffningsvardenFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '3',
              noteLabel('Försäljningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'shareholderContributions',
              noteLabel('Lämnade aktieägartillskott'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'repaymentShareholderContributions',
              noteLabel('Återbetalning av lämnat aktieägartillskott'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'yearProfit',
              noteLabel('Årets resultatandel'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '4',
              noteLabel('Omklassificeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'anskaffningsvardenConversionDifferences',
              noteLabel('Omräkningsdifferenser'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '5',
              noteLabel('Utgående anskaffningsvärden'),
              sumNotesRowsById(
                16,
                [
                  ...range(1, 5),
                  'anskaffningsvardenFusion',
                  'shareholderContributions',
                  'repaymentShareholderContributions',
                  'yearProfit',
                  'anskaffningsvardenConversionDifferences',
                ],
                'year',
                'secondTable'
              ),
              sumNotesRowsById(
                16,
                [
                  ...range(1, 5),
                  'anskaffningsvardenFusion',
                  'shareholderContributions',
                  'repaymentShareholderContributions',
                  'yearProfit',
                  'anskaffningsvardenConversionDifferences',
                ],
                'previousYear',
                'secondTable'
              )
            )
            .addRow(
              '6',
              noteLabel('Ingående nedskrivningar'),
              ref(sumAccountsIBs('1318', false)),
              ref(sumAccountsIBs('1318', false, 'previousYear'))
            )
            .addRow(
              'nedskrivningarFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '7',
              noteLabel('Försäljningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '8',
              noteLabel('Återförda nedskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '9',
              noteLabel('Omklassificeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '10',
              noteLabel('Årets nedskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'nedskrivningarConversionDifferences',
              noteLabel('Omräkningsdifferenser'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '11',
              noteLabel('Utgående nedskrivningar'),
              sumNotesRowsById(
                16,
                [
                  ...range(6, 11),
                  'nedskrivningarFusion',
                  'nedskrivningarConversionDifferences',
                ],
                'year',
                'secondTable'
              ),
              sumNotesRowsById(
                16,
                [
                  ...range(6, 11),
                  'nedskrivningarFusion',
                  'nedskrivningarConversionDifferences',
                ],
                'previousYear',
                'secondTable'
              )
            )
            .addRow(
              '12',
              noteLabel('Redovisat värde'),
              sumNotesRowsById(16, [5, 11], 'year', 'secondTable'),
              sumNotesRowsById(16, [5, 11], 'previousYear', 'secondTable')
            )
            .addRow(
              '13',
              noteLabel('Förändringar av anskaffningsvärden'),
              undefined,
              undefined
            )
            .addRow(
              '14',
              noteLabel('Förändringar av nedskrivningar'),
              undefined,
              undefined
            )
            .build()
        )
        .newRowTemplate(value(''), value(undefined), value(undefined))
        .build(),
      table: table(
        'notes.note16.table',
        { id: 'companyName', label: 'Företagets namn' },
        {
          id: 'organisationNumber',
          label: 'Organisationsnummer',
        },
        {
          id: 'place',
          label: 'Säte',
        },
        {
          id: 'capitalShare',
          label: 'Kapitalandel (%)',
        }
      )
        .addRows((rows) =>
          rows
            .addRow('1', value(''), value(''), value(''), value(undefined))
            .build()
        )
        .newRowTemplateGenerator((id, baseId) => ({
          id,
          active: true,
          cells: {
            companyName: value(''),
            organisationNumber: value(''),
            place: value(''),
            capitalShare: value(undefined),
          },
        }))
        .build(),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note17: {
      active: false,
      number: value(undefined),
      name: value('Fordringar hos koncernföretag'),
      table: table(
        'notes.note17.table',
        { id: 'label', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        .addRows((rows) =>
          rows
            // 'ingående' = previous period outgoing balance
            .addRow(
              '1',
              noteLabel('Ingående anskaffningsvärden'),
              ref(sumAccountsIBs('1320+1321+1322+1323', false)),
              ref(sumAccountsIBs('1320+1321+1322+1323', false, 'previousYear'))
            )
            .addRow(
              '2',
              noteLabel('Tillkommande fordringar'),
              ref(sumPositiveTransactions('1320:1327+1329+1660:1669')),
              ref(
                sumPositiveTransactions(
                  '1320:1327+1329+1660:1669',
                  'previousYear'
                )
              )
            )
            .addRow(
              'businessAcquisitions',
              noteLabel('Rörelseförvärv'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'anskaffningsvardenFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '3',
              noteLabel('Reglerade fordringar'),
              ref(sumNegativeTransactions('1320:1327+1329+1660:1669')),
              ref(
                sumNegativeTransactions(
                  '1320:1327+1329+1660:1669',
                  'previousYear'
                )
              )
            )
            .addRow(
              'anskaffningsvardenWrittenReceivables',
              noteLabel('Bortskrivna fordringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '4',
              noteLabel('Omklassificeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'anskaffningsvardenConversionDifferences',
              noteLabel('Omräkningsdifferenser'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '5',
              noteLabel('Utgående anskaffningsvärden'),
              sumNotesRowsById(
                17,
                [
                  ...range(1, 5),
                  'businessAcquisitions',
                  'anskaffningsvardenFusion',
                  'anskaffningsvardenWrittenReceivables',
                  'anskaffningsvardenConversionDifferences',
                ],
                'year'
              ),
              sumNotesRowsById(
                17,
                [
                  ...range(1, 5),
                  'businessAcquisitions',
                  'anskaffningsvardenFusion',
                  'anskaffningsvardenWrittenReceivables',
                  'anskaffningsvardenConversionDifferences',
                ],
                'previousYear'
              )
            )
            .addRow(
              '6',
              noteLabel('Ingående nedskrivningar'),
              ref(sumAccountsIBs('1328', false)),
              ref(sumAccountsIBs('1328', false, 'previousYear'))
            )
            .addRow(
              '7',
              noteLabel('Reglerade fordringar'), // it was Amortering, avgående fordringar
              ref(sumPositiveTransactions('1328')),
              ref(sumPositiveTransactions('1328', 'previousYear'))
            )
            .addRow(
              'nedskrivningarFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '8',
              noteLabel('Återförda nedskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'nedskrivningarWrittenReceivables',
              noteLabel('Bortskrivna fordringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '9',
              noteLabel('Omklassificeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '10',
              noteLabel('Årets nedskrivningar'),
              ref(sumNegativeTransactions('1328')),
              ref(sumNegativeTransactions('1328', 'previousYear'))
            )
            .addRow(
              'nedskrivningarConversionDifferences',
              noteLabel('Omräkningsdifferenser'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '11',
              noteLabel('Utgående nedskrivningar'),
              sumNotesRowsById(
                17,
                [
                  ...range(6, 11),
                  'nedskrivningarFusion',
                  'nedskrivningarWrittenReceivables',
                  'nedskrivningarConversionDifferences',
                ],
                'year'
              ),
              sumNotesRowsById(
                17,
                [
                  ...range(6, 11),
                  'nedskrivningarFusion',
                  'nedskrivningarWrittenReceivables',
                  'nedskrivningarConversionDifferences',
                ],
                'previousYear'
              )
            )
            .addRow(
              '12',
              noteLabel('Redovisat värde'),
              sumNotesRowsById(17, [5, 11], 'year'),
              sumNotesRowsById(17, [5, 11], 'previousYear')
            )
            .addRow(
              '13',
              noteLabel('Förändringar av anskaffningsvärden'),
              undefined,
              undefined
            )
            .addRow(
              '14',
              noteLabel('Förändringar av nedskrivningar'),
              undefined,
              undefined
            )
            .build()
        )
        .newRowTemplate(value(''), value(undefined), value(undefined))
        .build(),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note18: {
      active: false,
      number: value(undefined),
      name: value('Andelar i intresseföretag och gemensamt styrda företag'),
      // second table comes first in the view now
      // KEEP IT as secondTable to preserve older input values
      secondTable: table(
        'notes.note18.secondTable',
        { id: 'companyName', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        .addRows((rows) =>
          rows
            // 'ingående' = previous period outgoing balance
            .addRow(
              '1',
              noteLabel('Ingående anskaffningsvärden'),
              ref(sumAccountsIBs('1330+1331+1333+1336', false)),
              ref(sumAccountsIBs('1330+1331+1333+1336', false, 'previousYear'))
            )
            .addRow('2', noteLabel('Inköp'), value(undefined), value(undefined))
            .addRow(
              'anskaffningsvardenFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '3',
              noteLabel('Försäljningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'shareholderContributions',
              noteLabel('Lämnade aktieägartillskott'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'repaymentOfShareholderContributions',
              noteLabel('Återbetalning av lämnat aktieägartillskott'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'yearProfitShare',
              noteLabel('Årets resultatandel'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '4',
              noteLabel('Omklassificeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'anskaffningsvardenConversionDifferences',
              noteLabel('Omräkningsdifferenser'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '5',
              noteLabel('Utgående anskaffningsvärden'),
              sumNotesRowsById(
                18,
                [
                  ...range(1, 5),
                  'anskaffningsvardenFusion',
                  'shareholderContributions',
                  'repaymentOfShareholderContributions',
                  'yearProfitShare',
                  'anskaffningsvardenConversionDifferences',
                ],
                'year',
                'secondTable'
              ),
              sumNotesRowsById(
                18,
                [
                  ...range(1, 5),
                  'anskaffningsvardenFusion',
                  'shareholderContributions',
                  'repaymentOfShareholderContributions',
                  'yearProfitShare',
                  'anskaffningsvardenConversionDifferences',
                ],
                'previousYear',
                'secondTable'
              )
            )
            .addRow(
              '6',
              noteLabel('Ingående nedskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'nedskrivningarFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '7',
              noteLabel('Försäljningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '8',
              noteLabel('Återförda nedskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '9',
              noteLabel('Omklassificeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '10',
              noteLabel('Årets nedskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'nedskrivningarConversionDifferences',
              noteLabel('Omräkningsdifferenser'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '11',
              noteLabel('Utgående nedskrivningar'),
              sumNotesRowsById(
                18,
                [
                  ...range(6, 11),
                  'nedskrivningarFusion',
                  'nedskrivningarConversionDifferences',
                ],
                'year',
                'secondTable'
              ),
              sumNotesRowsById(
                18,
                [
                  ...range(6, 11),
                  'nedskrivningarFusion',
                  'nedskrivningarConversionDifferences',
                ],
                'previousYear',
                'secondTable'
              )
            )
            .addRow(
              '12',
              noteLabel('Redovisat värde'),
              sumNotesRowsById(18, [5, 11], 'year', 'secondTable'),
              sumNotesRowsById(18, [5, 11], 'previousYear', 'secondTable')
            )
            .addRow(
              '13',
              noteLabel('Förändringar av anskaffningsvärden'),
              undefined,
              undefined
            )
            .addRow(
              '14',
              noteLabel('Ingående nedskrivningar'),
              ref(sumAccountsIBs('11334+1337+1338', false)),
              ref(sumAccountsIBs('11334+1337+1338', false, 'previousYear'))
            )
            .addRow(
              '15',
              noteLabel('Förändringar av nedskrivningar'),
              undefined,
              undefined
            )
            .build()
        )
        .newRowTemplate(value(''), value(undefined), value(undefined))
        .build(),
      table: table(
        'notes.note18.table',
        { id: 'companyName', label: 'Företagets namn' },
        {
          id: 'organisationNumber',
          label: 'Organisationsnummer',
        },
        {
          id: 'place',
          label: 'Säte',
        },
        {
          id: 'capitalShare',
          label: 'Kapitalandel (%)',
        }
      )
        .addRows((rows) =>
          rows
            .addRow('1', value(''), value(''), value(''), value(undefined))
            .build()
        )
        .newRowTemplateGenerator((id, baseId) => ({
          id,
          active: true,
          cells: {
            companyName: value(''),
            organisationNumber: value(''),
            place: value(''),
            capitalShare: value(undefined),
          },
        }))
        .build(),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note19: {
      active: false,
      number: value(undefined),
      name: value(
        'Fordringar hos intresseföretag och gemensamt styrda företag'
      ),
      table: table(
        'notes.note19.table',
        { id: 'label', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        .addRows((rows) =>
          rows
            // 'ingående' = previous period outgoing balance
            .addRow(
              '1',
              noteLabel('Ingående anskaffningsvärden'),
              ref(sumAccountsIBs('1340+1341+1343+1346', false)),
              ref(sumAccountsIBs('1340+1341+1343+1346', false, 'previousYear'))
            )
            .addRow(
              '2',
              noteLabel('Tillkommande fordringar'),
              ref(
                sumPositiveTransactions('1340:1341+1343+1345+1349+1670:1672')
              ),
              ref(
                sumPositiveTransactions(
                  '1340:1341+1343+1345+1349+1670:1672',
                  'previousYear'
                )
              )
            )
            .addRow(
              'anskaffningsvardenFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '3',
              noteLabel('Reglerade fordringar'),
              ref(
                sumNegativeTransactions('1340:1341+1343+1345+1349+1670:1672')
              ),
              ref(
                sumNegativeTransactions(
                  '1340:1341+1343+1345+1349+1670:1672',
                  'previousYear'
                )
              )
            )
            .addRow(
              'anskaffningsvardenWrittenReceivables',
              noteLabel('Bortskrivna fordringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '4',
              noteLabel('Omklassificeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '5',
              noteLabel('Omräkningsdifferenser'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '6',
              noteLabel('Utgående anskaffningsvärden'),
              sumNotesRowsById(
                19,
                [
                  ...range(1, 6),
                  'anskaffningsvardenFusion',
                  'anskaffningsvardenWrittenReceivables',
                ],
                'year'
              ),
              sumNotesRowsById(
                19,
                [
                  ...range(1, 6),
                  'anskaffningsvardenFusion',
                  'anskaffningsvardenWrittenReceivables',
                ],
                'previousYear'
              )
            )
            .addRow(
              '7',
              noteLabel('Ingående nedskrivningar'),
              ref(sumAccountsIBs('1342+1344+1347+1348', false)),
              ref(sumAccountsIBs('1342+1344+1347+1348', false, 'previousYear'))
            )
            .addRow(
              '8',
              noteLabel('Reglerade fordringar'),
              ref(sumPositiveTransactions('1342+1344+1348')),
              ref(sumPositiveTransactions('1342+1344+1348', 'previousYear'))
            )
            .addRow(
              'nedskrivningarFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '9',
              noteLabel('Återförda nedskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'nedskrivningarWrittenReceivables',
              noteLabel('Bortskrivna fordringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '10',
              noteLabel('Omklassificeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '11',
              noteLabel('Årets nedskrivningar'),
              ref(sumNegativeTransactions('1342+1344+1348')),
              ref(sumNegativeTransactions('1342+1344+1348', 'previousYear'))
            )
            .addRow(
              '12',
              noteLabel('Omräkningsdifferenser'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '13',
              noteLabel('Utgående nedskrivningar'),
              sumNotesRowsById(
                19,
                [
                  ...range(7, 13),
                  'nedskrivningarFusion',
                  'nedskrivningarWrittenReceivables',
                ],
                'year'
              ),
              sumNotesRowsById(
                19,
                [
                  ...range(7, 13),
                  'nedskrivningarFusion',
                  'nedskrivningarWrittenReceivables',
                ],
                'previousYear'
              )
            )
            .addRow(
              '14',
              noteLabel('Redovisat värde'),
              sumNotesRowsById(19, [6, 13], 'year'),
              sumNotesRowsById(19, [6, 13], 'previousYear')
            )
            .addRow(
              '15',
              noteLabel('Förändringar av anskaffningsvärden'),
              undefined,
              undefined
            )
            .addRow(
              '16',
              noteLabel('Förändringar av nedskrivningar'),
              undefined,
              undefined
            )
            .build()
        )
        .newRowTemplate(value(''), value(undefined), value(undefined))
        .build(),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note20: {
      active: false,
      number: value(undefined),
      name: value('Ägarintressen i övriga företag'),
      // secondTable really comes first here
      secondTable: table(
        'notes.note20.secondTable',
        { id: 'companyName', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        .addRows((rows) =>
          rows
            // 'ingående' = previous period outgoing balance
            .addRow(
              '1',
              noteLabel('Ingående anskaffningsvärden'),
              ref(sumAccountsIBs('1350+1351+1352+1353+1354+1356+1357', false)),
              ref(
                sumAccountsIBs(
                  '1350+1351+1352+1353+1354+1356+1357',
                  false,
                  'previousYear'
                )
              )
            )
            .addRow('2', noteLabel('Inköp'), value(undefined), value(undefined))
            .addRow(
              'anskaffningsvardenFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '3',
              noteLabel('Försäljningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'shareholderContributions',
              noteLabel('Lämnade aktieägartillskott'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'repaymentShareholderContributions',
              noteLabel('Återbetalning av lämnat aktieägartillskott'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'yearProfit',
              noteLabel('Årets resultatandel'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '4',
              noteLabel('Omklassificeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'anskaffningsvardenConversionDifferences',
              noteLabel('Omräkningsdifferenser'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '5',
              noteLabel('Utgående anskaffningsvärden'),
              sumNotesRowsById(
                20,
                [
                  ...range(1, 5),
                  'anskaffningsvardenFusion',
                  'shareholderContributions',
                  'repaymentShareholderContributions',
                  'yearProfit',
                  'anskaffningsvardenConversionDifferences',
                ],
                'year',
                'secondTable'
              ),
              sumNotesRowsById(
                20,
                [
                  ...range(1, 5),
                  'anskaffningsvardenFusion',
                  'shareholderContributions',
                  'repaymentShareholderContributions',
                  'yearProfit',
                  'anskaffningsvardenConversionDifferences',
                ],
                'previousYear',
                'secondTable'
              )
            )
            .addRow(
              '6',
              noteLabel('Ingående nedskrivningar'),
              ref(sumAccountsIBs('1358', false)),
              ref(sumAccountsIBs('1358', false, 'previousYear'))
            )
            .addRow(
              'nedskrivningarFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '7',
              noteLabel('Försäljningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '8',
              noteLabel('Återförda nedskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '9',
              noteLabel('Omklassificeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '10',
              noteLabel('Årets nedskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'nedskrivningarConversionDifferences',
              noteLabel('Omräkningsdifferenser'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '11',
              noteLabel('Utgående nedskrivningar'),
              sumNotesRowsById(
                20,
                [
                  ...range(6, 11),
                  'nedskrivningarFusion',
                  'nedskrivningarConversionDifferences',
                ],
                'year',
                'secondTable'
              ),
              sumNotesRowsById(
                20,
                [
                  ...range(6, 11),
                  'nedskrivningarFusion',
                  'nedskrivningarConversionDifferences',
                ],
                'previousYear',
                'secondTable'
              )
            )
            .addRow(
              '12',
              noteLabel('Redovisat värde'),
              sumNotesRowsById(20, [5, 11], 'year', 'secondTable')
            )
            .addRow(
              '13',
              noteLabel('Förändringar av anskaffningsvärden'),
              undefined,
              undefined
            )
            .addRow(
              '14',
              noteLabel('Förändringar av nedskrivningar'),
              undefined,
              undefined
            )
            .build()
        )
        .newRowTemplate(value(''), value(undefined), value(undefined))
        .build(),
      table: table(
        'notes.note20.table',
        { id: 'companyName', label: 'Företagets namn' },
        {
          id: 'organisationNumber',
          label: 'Organisationsnummer',
        },
        {
          id: 'place',
          label: 'Säte',
        },
        {
          id: 'capitalShare',
          label: 'Kapitalandel',
        }
      )
        .addRows((rows) =>
          rows
            .addRow('1', value(''), value(''), value(''), value(undefined))
            .build()
        )
        .newRowTemplateGenerator((id, baseId) => ({
          id,
          active: true,
          cells: {
            companyName: value(''),
            organisationNumber: value(''),
            place: value(''),
            capitalShare: value(undefined),
          },
        }))
        .build(),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note21: {
      active: false,
      number: value(undefined),
      name: value(
        'Fordringar hos övriga företag som det finns ett ägarintresse i'
      ),
      table: table(
        'notes.note21.table',
        { id: 'label', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        .addRows((rows) =>
          rows
            // 'ingående' = previous period outgoing balance
            .addRow(
              '1',
              noteLabel('Ingående anskaffningsvärden'),
              ref(sumAccountsIBs('1346+1673:1679', false)),
              ref(sumAccountsIBs('1346+1673:1679', false, 'previousYear'))
            )
            .addRow(
              '2',
              noteLabel('Tillkommande fordringar'),
              ref(sumPositiveTransactions('1346+1673:1679')),
              ref(sumPositiveTransactions('1346+1673:1679', 'previousYear'))
            )
            .addRow(
              'anskaffningsvardenFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '3',
              noteLabel('Reglerade fordringar'),
              ref(sumNegativeTransactions('1346+1673:1679')),
              ref(sumNegativeTransactions('1346+1673:1679', 'previousYear'))
            )
            .addRow(
              'anskaffningsvardenWrittenReceivables',
              noteLabel('Bortskrivna fordringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '4',
              noteLabel('Omklassificeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'anskaffningsvardenConversionDifferences',
              noteLabel('Omräkningsdifferenser'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '5',
              noteLabel('Utgående anskaffningsvärden'),
              sumNotesRowsById(
                21,
                [
                  ...range(1, 5),
                  'anskaffningsvardenFusion',
                  'anskaffningsvardenWrittenReceivables',
                  'anskaffningsvardenConversionDifferences',
                ],
                'year'
              ),
              sumNotesRowsById(
                21,
                [
                  ...range(1, 5),
                  'anskaffningsvardenFusion',
                  'anskaffningsvardenWrittenReceivables',
                  'anskaffningsvardenConversionDifferences',
                ],
                'previousYear'
              )
            )
            .addRow(
              '7',
              noteLabel('Ingående nedskrivningar'),
              ref(sumAccountsIBs('1347', false)),
              ref(sumAccountsIBs('1347', false, 'previousYear'))
            )
            .addRow(
              '8',
              noteLabel('Reglerade fordringar'),
              ref(sumPositiveTransactions('1347')),
              ref(sumPositiveTransactions('1347', 'previousYear'))
            )
            .addRow(
              'nedskrivningarFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '9',
              noteLabel('Återförda nedskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'nedskrivningarWrittenReceivables',
              noteLabel('Bortskrivna fordringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '10',
              noteLabel('Omklassificeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '11',
              noteLabel('Årets nedskrivningar'),
              ref(sumNegativeTransactions('1347')),
              ref(sumNegativeTransactions('1347', 'previousYear'))
            )
            .addRow(
              'nedskrivningarConversionDifferences',
              noteLabel('Omräkningsdifferenser'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '12',
              noteLabel('Utgående nedskrivningar'),
              sumNotesRowsById(
                21,
                [
                  ...range(7, 12),
                  'nedskrivningarFusion',
                  'nedskrivningarWrittenReceivables',
                  'nedskrivningarConversionDifferences',
                ],
                'year'
              ),
              sumNotesRowsById(
                21,
                [
                  ...range(7, 12),
                  'nedskrivningarFusion',
                  'nedskrivningarWrittenReceivables',
                  'nedskrivningarConversionDifferences',
                ],
                'previousYear'
              )
            )
            .addRow(
              '13',
              noteLabel('Redovisat värde'),
              sumNotesRowsById(21, [5, 12], 'year'),
              sumNotesRowsById(21, [5, 12], 'previousYear')
            )
            .addRow(
              '14',
              noteLabel('Förändringar av anskaffningsvärden'),
              undefined,
              undefined
            )
            .addRow(
              '15',
              noteLabel('Förändringar av nedskrivningar'),
              undefined,
              undefined
            )
            .build()
        )
        .newRowTemplate(value(''), value(undefined), value(undefined))
        .build(),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note22: {
      active: false,
      number: value(undefined),
      name: value('Andra långfristiga värdepappersinnehav'),
      table: table(
        'notes.note22.table',
        { id: 'label', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        .addRows((rows) =>
          rows
            // 'ingående' = previous period outgoing balance
            .addRow(
              '1',
              noteLabel('Ingående anskaffningsvärden'),
              ref(sumAccountsIBs('1350:1357', false))
            )
            .addRow(
              '2',
              noteLabel('Inköp'),
              ref(sumPositiveTransactions('1350:1357')),
              ref(sumPositiveTransactions('1350:1357', 'previousYear'))
            )
            .addRow(
              'anskaffningsvardenFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '3',
              noteLabel('Försäljningar'),
              ref(sumNegativeTransactions('1350:1357')),
              ref(sumNegativeTransactions('1350:1357', 'previousYear'))
            )
            .addRow(
              'yearResults',
              noteLabel('Årets resultatandel'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '4',
              noteLabel('Omklassificeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'anskaffningsvardenConversionDifferences',
              noteLabel('Omräkningsdifferenser'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '5',
              noteLabel('Utgående anskaffningsvärden'),
              sumNotesRowsById(
                22,
                [
                  ...range(1, 5),
                  'anskaffningsvardenFusion',
                  'yearResults',
                  'anskaffningsvardenConversionDifferences',
                ],
                'year'
              ),
              sumNotesRowsById(
                22,
                [
                  ...range(1, 5),
                  'anskaffningsvardenFusion',
                  'yearResults',
                  'anskaffningsvardenConversionDifferences',
                ],
                'previousYear'
              )
            )
            .addRow(
              '6',
              noteLabel('Ingående nedskrivningar'),
              undefined,
              undefined
            )
            .addRow(
              'nedskrivningarFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '7',
              noteLabel('Försäljningar'),
              ref(sumPositiveTransactions('1358+1359')),
              ref(sumPositiveTransactions('1358+1359', 'previousYear'))
            )
            .addRow(
              '8',
              noteLabel('Återförda nedskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '9',
              noteLabel('Omklassificeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '10',
              noteLabel('Årets nedskrivningar'),
              ref(sumNegativeTransactions('1358+1359')),
              ref(sumNegativeTransactions('1358+1359', 'previousYear'))
            )
            .addRow(
              'nedskrivningarConversionDifferences',
              noteLabel('Omräkningsdifferenser'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '11',
              noteLabel('Utgående nedskrivningar'),
              sumNotesRowsById(
                22,
                [
                  ...range(6, 11),
                  'nedskrivningarFusion',
                  'nedskrivningarConversionDifferences',
                ],
                'year'
              ),
              sumNotesRowsById(
                22,
                [
                  ...range(6, 11),
                  'nedskrivningarFusion',
                  'nedskrivningarConversionDifferences',
                ],
                'previousYear'
              )
            )
            .addRow(
              '12',
              noteLabel('Redovisat värde'),
              sumNotesRowsById(22, [5, 11], 'year'),
              sumNotesRowsById(22, [5, 11], 'previousYear')
            )
            .addRow(
              '13',
              noteLabel('Förändringar av anskaffningsvärden'),
              undefined,
              undefined
            )
            .addRow(
              '14',
              noteLabel('Förändringar av nedskrivningar'),
              undefined,
              undefined
            )
            .build()
        )
        .newRowTemplate(value(''), value(undefined), value(undefined))
        .build(),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note23: {
      active: false,
      number: value(undefined),
      name: value('Lån till delägare eller närstående'),
      table: table(
        'notes.note23.table',
        { id: 'label', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        .addRows((rows) =>
          rows
            // 'ingående' = previous period outgoing balance
            .addRow(
              '1',
              noteLabel('Ingående anskaffningsvärden'),
              ref(sumAccountsIBs('1360', false)),
              ref(sumAccountsIBs('1360', false, 'previousYear'))
            )
            .addRow(
              '2',
              noteLabel('Årets lämnade lån'),
              ref(sumPositiveTransactions('1360:1368')),
              ref(sumPositiveTransactions('1360:1368', 'previousYear'))
            )
            .addRow(
              'anskaffningsvardenFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '3',
              noteLabel('Årets amorteringar'),
              ref(sumNegativeTransactions('1360:1368')),
              ref(sumNegativeTransactions('1360:1368', 'previousYear'))
            )
            .addRow(
              'anskaffningsvardenWrittenReceivables',
              noteLabel('Bortskrivna fordringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '4',
              noteLabel('Omklassificeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'anskaffningsvardenConversionDifferences',
              noteLabel('Omräkningsdifferenser'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '5',
              noteLabel('Utgående anskaffningsvärden'),
              sumNotesRowsById(
                23,
                [
                  ...range(1, 5),
                  'anskaffningsvardenFusion',
                  'anskaffningsvardenWrittenReceivables',
                  'anskaffningsvardenConversionDifferences',
                ],
                'year'
              ),
              sumNotesRowsById(
                23,
                [
                  ...range(1, 5),
                  'anskaffningsvardenFusion',
                  'anskaffningsvardenWrittenReceivables',
                  'anskaffningsvardenConversionDifferences',
                ],
                'previousYear'
              )
            )
            .addRow(
              '6',
              noteLabel('Ingående nedskrivningar'),
              ref(sumAccountsIBs('1369', false)),
              ref(sumAccountsIBs('1369', false, 'previousYear'))
            )
            .addRow(
              '7',
              noteLabel('Årets amorteringar'),
              ref(sumPositiveTransactions('1369')),
              ref(sumPositiveTransactions('1369', 'previousYear'))
            )
            .addRow(
              'nedskrivningarFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '8',
              noteLabel('Återförda nedskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'nedskrivningarWrittenReceivables',
              noteLabel('Bortskrivna fordringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '9',
              noteLabel('Omklassificeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '10',
              noteLabel('Årets nedskrivningar'),
              ref(sumNegativeTransactions('1369')),
              ref(sumNegativeTransactions('1369', 'previousYear'))
            )
            .addRow(
              '11',
              noteLabel('Utgående nedskrivningar'),
              sumNotesRowsById(
                23,
                [
                  ...range(6, 11),
                  'nedskrivningarFusion',
                  'nedskrivningarWrittenReceivables',
                  'nedskrivningarConversionDifferences',
                ],
                'year'
              ),
              sumNotesRowsById(
                23,
                [
                  ...range(6, 11),
                  'nedskrivningarFusion',
                  'nedskrivningarWrittenReceivables',
                  'nedskrivningarConversionDifferences',
                ],
                'previousYear'
              )
            )
            .addRow(
              '12',
              noteLabel('Redovisat värde'),
              sumNotesRowsById(23, [5, 11], 'year'),
              sumNotesRowsById(23, [5, 11], 'previousYear')
            )
            .addRow(
              '13',
              noteLabel('Förändringar av anskaffningsvärden'),
              undefined,
              undefined
            )
            .addRow(
              '14',
              noteLabel('Förändringar av nedskrivningar'),
              undefined,
              undefined
            )
            .build()
        )
        .newRowTemplate(value(''), value(undefined), value(undefined))
        .build(),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note24: {
      active: false,
      number: value(undefined),
      name: value('Andra långfristiga fordringar'),
      table: table(
        'notes.note24.table',
        { id: 'label', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        .addRows((rows) =>
          rows
            // 'ingående' = previous period outgoing balance
            .addRow(
              '1',
              noteLabel('Ingående anskaffningsvärden'),
              ref(
                sumAccountsIBs('1380+1381+1382+1383+1384+1385+1387+1388', false)
              ),
              ref(
                sumAccountsIBs(
                  '1380+1381+1382+1383+1384+1385+1387+1388',
                  false,
                  'previousYear'
                )
              )
            )
            .addRow(
              '2',
              noteLabel('Tillkommande fordringar'),
              ref(sumPositiveTransactions('1380:1388+1390:1399')),
              ref(
                sumPositiveTransactions('1380:1388+1390:1399', 'previousYear')
              )
            )
            .addRow(
              'anskaffningsvardenFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '3',
              noteLabel('Reglerade fordringar'),
              ref(sumNegativeTransactions('1380:1388+1390:1399')),
              ref(
                sumNegativeTransactions('1380:1388+1390:1399', 'previousYear')
              )
            )
            .addRow(
              'anskaffningsvardenWrittenReceivables',
              noteLabel('Bortskrivna fordringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '4',
              noteLabel('Omklassificeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'anskaffningsvardenConversionDifferences',
              noteLabel('Omräkningsdifferenser'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '5',
              noteLabel('Utgående anskaffningsvärden'),
              sumNotesRowsById(
                24,
                [
                  ...range(1, 5),
                  'anskaffningsvardenFusion',
                  'anskaffningsvardenWrittenReceivables',
                  'anskaffningsvardenConversionDifferences',
                ],
                'year'
              ),
              sumNotesRowsById(
                24,
                [
                  ...range(1, 5),
                  'anskaffningsvardenFusion',
                  'anskaffningsvardenWrittenReceivables',
                  'anskaffningsvardenConversionDifferences',
                ],
                'previousYear'
              )
            )
            .addRow(
              '6',
              noteLabel('Ingående nedskrivningar'),
              ref(sumAccountsIBs('1389', false)),
              ref(sumAccountsIBs('1389', false, 'previousYear'))
            )
            .addRow(
              'nedskrivningarFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '7',
              noteLabel('Reglerade fordringar'),
              ref(sumPositiveTransactions('1389')),
              ref(sumPositiveTransactions('1389', 'previousYear'))
            )
            .addRow(
              '8',
              noteLabel('Återförda nedskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'nedskrivningarWrittenReceivables',
              noteLabel('Bortskrivna fordringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '9',
              noteLabel('Omklassificeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '10',
              noteLabel('Årets nedskrivningar'),
              ref(sumNegativeTransactions('1389')),
              ref(sumNegativeTransactions('1389', 'previousYear'))
            )
            .addRow(
              'nedskrivningarConversionDifferences',
              noteLabel('Omräkningsdifferenser'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '11',
              noteLabel('Utgående nedskrivningar'),
              sumNotesRowsById(
                24,
                [
                  ...range(6, 11),
                  'nedskrivningarFusion',
                  'nedskrivningarWrittenReceivables',
                  'nedskrivningarConversionDifferences',
                ],
                'year'
              ),
              sumNotesRowsById(
                24,
                [
                  ...range(6, 11),
                  'nedskrivningarFusion',
                  'nedskrivningarWrittenReceivables',
                  'nedskrivningarConversionDifferences',
                ],
                'previousYear'
              )
            )
            .addRow(
              '12',
              noteLabel('Redovisat värde'),
              sumNotesRowsById(24, [5, 11], 'year'),
              sumNotesRowsById(24, [5, 11], 'previousYear')
            )
            .addRow(
              '13',
              noteLabel('Förändringar av anskaffningsvärden'),
              undefined,
              undefined
            )
            .addRow(
              '14',
              noteLabel('Förändringar av nedskrivningar'),
              undefined,
              undefined
            )
            .build()
        )
        .newRowTemplate(value(''), value(undefined), value(undefined))
        .build(),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note25: {
      active: false,
      number: value(undefined),
      name: value('Pågående arbete för annans räkning (Skuld)'),
      table: table(
        'notes.note25.table',
        { id: 'label', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        .addRows((rows) =>
          rows
            .addRow(
              '1',
              noteLabel('Aktiverade nedlagda utgifter'),
              ref(sumAccountsUBs('1470+1471+1479', true)),
              ref(sumAccountsUBs('1470+1471+1479', true, 'previousYear'))
            )
            .addRow(
              '2',
              noteLabel('Fakturerat belopp'),
              ref(sumAccountsUBs('1478', true)),
              ref(sumAccountsUBs('1478', true, 'previousYear'))
            )
            .addRow(
              'claimSum',
              noteLabel('Redovisat värde'),
              sumNotesRowsById(25, [1, 2], 'year'),
              sumNotesRowsById(25, [1, 2], 'previousYear')
            )
            .addRow(
              '3',
              noteLabel('Fakturerat belopp (Skuld)'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '4',
              noteLabel('Aktiverade nedlagda utgifter (Skuld)'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'debtSum',
              noteLabel('Redovisat värde (Skuld)'),
              sumNotesRowsById(25, [3, 4], 'year'),
              sumNotesRowsById(25, [3, 4], 'previousYear')
            )
            .addRow(
              '5',
              noteLabel('Utgående anskaffningsvärden'),
              sumNotesRowsById(26, range(1, 5), 'year'),
              sumNotesRowsById(26, range(1, 5), 'previousYear')
            )
            .addRow(
              '6',
              noteLabel('Kommentar till not'),
              value(undefined),
              value(undefined)
            )
            .build()
        )
        .newRowTemplate(value(''), value(undefined), value(undefined))
        .build(),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note26: {
      active: false,
      number: value(undefined),
      name: value('Uppskrivningsfond'),
      table: table(
        'notes.note26.table',
        { id: 'label', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        },
        'hidden'
      )
        .addRows((rows) =>
          rows
            // 'ingång' = previous period outgoing balance
            .addRow(
              '1',
              noteLabel('Belopp vid årets ingång'),
              ref(sumAccountsIBs('2080', true)),
              ref(sumAccountsIBs('2080', true, 'previousYear'))
            )
            .addRow(
              '2',
              noteLabel('Avsättning till fonden under räkenskapsåret'),
              ref(sumNegativeTransactions('2085')),
              ref(sumNegativeTransactions('2085', 'previousYear'))
            )
            .addRow(
              '3',
              noteLabel('Avskrivning av uppskrivna tillgångar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '4',
              noteLabel('Nedskrivning av uppskrivna tillgångar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '5',
              noteLabel('Försäljning/utrangering av uppskrivna tillgångar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '6',
              noteLabel('Ianspråktaget för fondemission'),
              value(undefined),
              value(undefined)
            )
            // row 'Nyemission' with ID 7 was removed, since it does not fit in current BV specs
            .addRow(
              '8',
              noteLabel('Ianspråktaget för täckande av förlust'),
              value(undefined),
              value(undefined)
            )
            .build()
        )
        .newRowTemplate(value(''), value(undefined), value(undefined))
        .build(),
      sumTable: table(
        'notes.note26.sumTable',
        { id: 'label', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        .addRows((rows) =>
          rows
            .addRow(
              'sum',
              label('Belopp vid årets utgång'),
              ref(or(sum(id('notes.note26.table.*.year')), 0)),
              ref(or(sum(id('notes.note26.table.*.previousYear')), 0))
            )
            .addRow(
              'revaluedAssetsExcludingRevaluation',
              label('Uppskrivna tillgångarnas värde exkl. uppskrivning'),
              value(undefined),
              value(undefined)
            )
            .build()
        )
        .build(),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note27: {
      active: false,
      number: value(undefined),
      name: value('Långfristiga skulder'),
      table: table(
        'notes.note27.table',
        { id: 'label', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        },
        'hidden'
      )
        // no account prefilling in note27
        .addRows((rows) =>
          rows
            .addRow(
              '1',
              noteLabel(
                'Belopp på skuldpost som förfaller till betalning senare än fem år efter balansdagen'
              ),
              ref(sumAccountsIBs('2300:2329', true)),
              ref(sumAccountsIBs('2300:2329', true, 'previousYear'))
            )
            .build()
        )
        .newRowTemplate(value(''), value(undefined), value(undefined))
        .build(),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note28: {
      active: false,
      number: value(undefined),
      name: value('Skulder som avser flera poster'),
      general: field(
        'Företagets banklån/skuld om yyy kronor redovisas under följande poster i balansräkningen.'
      ),
      table: table(
        'notes.note28.table',
        { id: 'label', label: 'Långfristiga skulder' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        .addRows((rows) =>
          rows
            .addRow(
              '1',
              noteLabel('Obligationslån'),
              ref(sumAccountsUBs('2300:2319', true)),
              ref(sumAccountsUBs('2300:2319', true, 'previousYear'))
            )
            .addRow(
              '2',
              noteLabel('Skulder till kreditinstitut'),
              ref(sumAccountsUBs('2350:2359', true)),
              ref(sumAccountsUBs('2350:2359', true, 'previousYear'))
            )
            .addRow(
              '3',
              noteLabel('Skulder till koncernföretag'),
              ref(sumAccountsUBs('2360:2369', true)),
              ref(sumAccountsUBs('2360:2369', true, 'previousYear'))
            )
            .addRow(
              '4',
              noteLabel(
                'Skulder till intresseföretag och gemensamt styrda företag'
              ),
              ref(sumAccountsUBs('2370:2372', true)),
              ref(sumAccountsUBs('2370:2372', true, 'previousYear'))
            )
            .addRow(
              '5',
              noteLabel(
                'Skulder till övriga företag som det finns ett ägarintresse i'
              ),
              ref(sumAccountsUBs('2373:2379', true)),
              ref(sumAccountsUBs('2373:2379', true, 'previousYear'))
            )
            .addRow(
              '6',
              noteLabel('Övriga skulder'),
              ref(sumAccountsUBs('2380:2399', true)),
              ref(sumAccountsUBs('2380:2399', true, 'previousYear'))
            )
            .build()
        )
        .newRowTemplate(value(''), value(undefined), value(undefined))
        .build(),
      secondTable: table(
        'notes.note28.secondTable',
        { id: 'label', label: 'Kortfristiga skulder' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        .addRows((rows) =>
          rows
            .addRow(
              '1',
              noteLabel('Skulder till kreditinstitut'),
              ref(sumAccountsUBs('2400:2419', true)),
              ref(sumAccountsUBs('2400:2419', true, 'previousYear'))
            )
            .addRow(
              '2',
              noteLabel('Skulder till koncernföretag'),
              ref(sumAccountsUBs('2460:2469', true)),
              ref(sumAccountsUBs('2460:2469+2860:2869', true, 'previousYear'))
            )
            .addRow(
              '3',
              noteLabel(
                'Skulder till intresseföretag och gemensamt styrda företag'
              ),
              ref(sumAccountsUBs('2470:2472+2870:2872', true)),
              ref(sumAccountsUBs('2470:2472+2870:2872', true, 'previousYear'))
            )
            .addRow(
              '4',
              noteLabel(
                'Skulder till övriga företag som det finns ett ägarintresse i'
              ),
              ref(sumAccountsUBs('2473:2479+2873:2879', true)),
              ref(sumAccountsUBs('2473:2479+2873:2879', true, 'previousYear'))
            )
            .addRow(
              '5',
              noteLabel('Övriga skulder'),
              value(undefined),
              value(undefined)
            )
            .build()
        )
        .newRowTemplate(value(''), value(undefined), value(undefined))
        .build(),
      sumTable: table(
        'notes.note28.sumTable',
        { id: 'label', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        .addRows((rows) =>
          rows
            .addRow(
              'sum',
              noteLabel('Summa'),
              ref(
                or(
                  sum(
                    sum(
                      id('notes.note28.table.*.year'),
                      id('notes.note28.secondTable.*.year')
                    )
                  ),
                  0
                )
              ),
              ref(
                or(
                  sum(
                    sum(
                      id('notes.note28.table.*.previousYear'),
                      id('notes.note28.secondTable.*.previousYear')
                    )
                  ),
                  0
                )
              )
            )
            .build()
        )
        .build(),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note29: {
      active: false,
      number: value(undefined),
      name: value('Långfristiga konvertibla lån'),
      table: table(
        'notes.note29.table',
        { id: 'morgages', label: 'Lån' },
        { id: 'terms', label: 'Tid och villkor' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        .addRows((rows) =>
          rows
            .addRow(
              '1',
              value(''),
              value(''),
              ref(sumAccountsUBs('2320', true)),
              ref(sumAccountsUBs('2320', true, 'previousYear'))
            )
            .addRow(
              '2',
              value(''),
              value(''),
              ref(sumAccountsUBs('2321', true)),
              ref(sumAccountsUBs('2321', true, 'previousYear'))
            )
            .addRow(
              '3',
              value(''),
              value(''),
              ref(sumAccountsUBs('2322:2329', true)),
              ref(sumAccountsUBs('2322:2329', true, 'previousYear'))
            )
            .build()
        )
        .newRowTemplate(
          value(''),
          value(''),
          value(undefined),
          value(undefined)
        )
        .build(),
      sumTable: table(
        'notes.note29.sumTable',
        { id: 'morgages', label: '' },
        { id: 'terms', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        .addRows((rows) =>
          rows
            .addRow(
              'sum',
              noteLabel('Summa'),
              value(''),
              ref(or(sum(id('notes.note29.table.*.year')), 0)),
              ref(or(sum(id('notes.note29.table.*.previousYear')), 0))
            )
            .build()
        )
        .build(),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note30: {
      active: false,
      number: value(undefined),
      name: value('Checkräkningskredit'),
      table: table(
        'notes.note30.table',
        { id: 'label', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        .addRows((rows) =>
          rows
            .addRow(
              '1',
              noteLabel('Beviljad checkräkningskredit uppgår till'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '2',
              noteLabel('Outnyttjad del'),
              ref(sumAccountsUBs('2330:2339', true)),
              ref(sumAccountsUBs('2330:2339', true, 'previousYear'))
            )
            .build()
        )
        .newRowTemplate(value(''), value(undefined), value(undefined))
        .build(),
      sumTable: table(
        'notes.note30.sumTable',
        { id: 'utilizedCreditAmount', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        .addRows((rows) =>
          rows
            .addRow(
              'sum',
              noteLabel('Utnyttjat kreditbelopp'),
              ref(or(sum(id('notes.note30.table.*.year')), 0)),
              ref(or(sum(id('notes.note30.table.*.previousYear')), 0))
            )
            .build()
        )
        .build(),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note31: {
      active: false,
      number: value(undefined),
      name: value('Byggnadskreditiv'),
      table: table(
        'notes.note31.table',
        { id: 'label', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        .addRows((rows) =>
          rows
            .addRow(
              '1',
              noteLabel('Beviljat byggnadskreditiv uppgår till'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '2',
              noteLabel('Outnyttjad del'),
              ref(sumAccountsUBs('2340:2349', true)),
              ref(sumAccountsUBs('2340:2349', true, 'previousYear'))
            )
            .build()
        )
        .newRowTemplate(value(''), value(undefined), value(undefined))
        .build(),
      sumTable: table(
        'notes.note31.sumTable',
        { id: 'utilizedCreditAmount', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        .addRows((rows) =>
          rows
            .addRow(
              'sum',
              noteLabel('Utnyttjat kreditbelopp'),
              ref(or(sum(id('notes.note31.table.*.year')), 0)),
              ref(or(sum(id('notes.note31.table.*.previousYear')), 0))
            )
            .build()
        )
        .build(),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note32: {
      active: false,
      number: value(undefined),
      name: value('Kortfristiga konvertibla lån'),
      table: table(
        'notes.note32.table',
        { id: 'morgages', label: 'Lån' },
        { id: 'terms', label: 'Tid och villkor' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        .addRows((rows) =>
          rows
            .addRow(
              '1',
              value(''),
              value(''),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '2',
              value(''),
              value(''),
              value(undefined),
              value(undefined)
            )
            .build()
        )
        .newRowTemplate(
          value(''),
          value(''),
          value(undefined),
          value(undefined)
        )
        .build(),
      sumTable: table(
        'notes.note32.sumTable',
        { id: 'morgages', label: '' },
        { id: 'terms', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        .addRows((rows) =>
          rows
            .addRow(
              'sum',
              noteLabel('Summa'),
              value(''),
              ref(or(sum(id('notes.note32.table.*.year')), 0)),
              ref(or(sum(id('notes.note32.table.*.previousYear')), 0))
            )
            .build()
        )
        .build(),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note40: {
      active: false,
      number: value(undefined),
      name: value('Fond för verkligt värde'),
      table: table(
        'notes.note40.table',
        { id: 'label', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        .addRows((rows) =>
          rows
            .addRow(
              '1',
              noteLabel('Belopp vid årets ingång'),
              ref(sumAccountsIBs('2096', true)),
              ref(sumAccountsIBs('2096', true, 'previousYear'))
            )
            .addRow(
              '2',
              noteLabel('Ökning av verkligt värde avseende'),
              value(undefined), // no account ranges for all rows, except 1st above
              value(undefined)
            )
            .addRow(
              '3',
              noteLabel('Finansiella tillgångar som kan säljas'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '4',
              noteLabel('Kassaflödessäkring'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '5',
              noteLabel('Säkring av nettoinvestering i utländska dotterbolag'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '6',
              noteLabel('Minskning av verkligt värde avseende'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '7',
              noteLabel('Finansiella tillgångar som kan säljas'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '8',
              noteLabel('Kassaflödessäkring'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '9',
              noteLabel('Säkring av nettoinvestering i utländska dotterbolag'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '10',
              noteLabel('Överfört till resultaträkningen till följd av'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '11',
              noteLabel('Försäljning av finansiella tillgångar som kan säljas'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '12',
              noteLabel('Avräkning av kassaflödessäkring'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '13',
              noteLabel('Belopp vid årets utgång'),
              sumNotesRowsById(40, range(1, 13), 'year'),
              sumNotesRowsById(40, range(1, 13), 'previousYear')
            )
            .build()
        )
        .newRowTemplate(value(''), value(''), value(undefined))
        .build(),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note41: {
      active: false,
      number: value(undefined),
      name: value('Uppskjuten skattefordran'),
      table: table(
        'notes.note41.table',
        { id: 'label', label: '' },
        { id: 'reportedValue', label: 'Redovisat värde' },
        { id: 'taxValue', label: 'Skattemässigt värde' },
        { id: 'temporaryDifference', label: 'Temporär skillnad' }
      )
        .addRows((rows) =>
          rows
            .addRow(
              '1',
              noteLabel('Avdragsgilla temporära skillnader'),
              value(undefined), // no acc ranges until id 7
              value(undefined),
              value(undefined)
            )
            .addRow(
              '2',
              value(''),
              value(undefined),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '3',
              value(''),
              value(undefined),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '4',
              value(''),
              value(undefined),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '5',
              value(''),
              value(undefined),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '6',
              noteLabel('Skattemässiga underskottsavdrag'),
              value(undefined),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '7',
              noteLabel('Balanserad vinst i eget kapital'),
              ref(sumAccountsUBs('2091+2098:2099', true)),
              value(undefined), //TODO in AGOY-2337 Formula that checks whether the input is bigger than value above * bolagskatt for year of the report
              value(undefined) // if above is true, warn the user. Similar to bokslut's field Vald återföring av periodiseringsfond från tidigare år
            )
            .addRow(
              '8',
              noteLabel('Andra outnyttjade skatteavdrag'),
              value(undefined), // no more acc ranges until end of note
              value(undefined),
              value(undefined)
            )
            .addRow(
              '9',
              value(''),
              value(undefined),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '10',
              value(''),
              value(undefined),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '11',
              value(''),
              value(undefined),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '12',
              value(''),
              value(undefined),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '13',
              noteLabel('Summering'),
              sumNotesRowsById(41, range(1, 13), 'reportedValue'),
              sumNotesRowsById(41, range(1, 13), 'taxValue'),
              sumNotesRowsById(41, range(1, 13), 'temporaryDifference')
            )
            .addRow(
              '14',
              noteLabel('Utgående redovisat värde uppskjuten skattefordran'),
              value(undefined),
              value(undefined),
              value(undefined)
            )
            .build()
        )
        .newRowTemplate(
          value(''),
          value(undefined),
          value(undefined),
          value(undefined)
        )
        .build(),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note42: {
      active: false,
      number: value(undefined),
      name: value('Övriga immateriella anläggningstillgångar'),
      table: table(
        'notes.note42.table',
        { id: 'label', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        .addRows((rows) =>
          rows
            .addRow(
              '1',
              noteLabel('Ingående anskaffningsvärden'),
              ref(sumAccountsIBs('1090:1099', false)),
              ref(sumAccountsIBs('1090:1099', false, 'previousYear'))
            )
            .addRow('2', noteLabel('Inköp'), value(undefined), value(undefined))
            .addRow(
              '3',
              noteLabel('Försäljningar/utrangeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '4',
              noteLabel('Omklassificeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '5',
              noteLabel('Utgående anskaffningsvärden'),
              sumNotesRowsById(42, range(1, 5), 'year'),
              sumNotesRowsById(42, range(1, 5), 'previousYear')
            )
            .addRow(
              '6',
              noteLabel('Ingående avskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '7',
              noteLabel('Försäljningar/utrangeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '8',
              noteLabel('Omklassificeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '9',
              noteLabel('Årets avskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '10',
              noteLabel('Utgående ackumulerade avskrivningar'),
              sumNotesRowsById(42, range(6, 10), 'year'),
              sumNotesRowsById(42, range(6, 10), 'previousYear')
            )
            .addRow(
              '11',
              noteLabel('Ingående uppskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '12',
              noteLabel('Försäljningar/utrangeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '13',
              noteLabel('Omklassificeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '14',
              noteLabel('Årets uppskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '15',
              noteLabel('Årets nedskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '16',
              noteLabel('Årets avskrivningar på uppskrivet belopp'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '17',
              noteLabel('Utgående ackumulerade uppskrivningar netto'),
              sumNotesRowsById(42, range(11, 17), 'year'),
              sumNotesRowsById(42, range(11, 17), 'previousYear')
            )
            .addRow(
              '18',
              noteLabel('Ingående nedskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '19',
              noteLabel('Försäljningar/utrangeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '20',
              noteLabel('Återförd nedskrivning'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '21',
              noteLabel('Omklassificeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '22',
              noteLabel('Årets nedskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '23',
              noteLabel('Utgående ackumulerade nedskrivningar'),
              sumNotesRowsById(42, range(18, 23), 'year'),
              sumNotesRowsById(42, range(18, 23), 'previousYear')
            )
            .addRow(
              '24',
              noteLabel('Utgående redovisat värde'),
              sumNotesRowsById(42, [5, 10, 17, 23], 'year'),
              sumNotesRowsById(42, [5, 10, 17, 23], 'previousYear')
            )
            .addRow(
              '25',
              noteLabel(
                'Tillgångar anskaffade med offentligt bidrag ingår med redovisat anskaffningsvärde'
              ),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '26',
              noteLabel(
                'I ovanstående redovisat värde ingår leasade tillgångar med'
              ),
              value(undefined),
              value(undefined)
            )
            .build()
        )
        .newRowTemplate(value(''), value(''), value(undefined))
        .build(),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note44: {
      active: false,
      number: value(undefined),
      name: value(
        'Tillgångar, avsättningar och skulder som avser flera poster'
      ),
      table: table(
        `notes.note44.table`,
        {
          id: 'typeOfAssets',
          label:
            'Typ av tillgångar, avsättningar och skulder som avser flera poster',
        },
        {
          id: 'balanceSheetItem',
          label: 'Typ av balanspost',
        },
        {
          id: 'amount',
          label: 'Belopp',
        }
      )
        .addRows((rows) =>
          rows.addRow('1', value(''), value(''), value(undefined)).build()
        )
        .newRowTemplateGenerator((id, baseId) => ({
          id,
          active: true,
          cells: {
            typeOfAssets: value(''),
            balanceSheetItem: value(''),
            amount: value(undefined),
          },
        }))
        .build(),
      commentLabel: field('Kommentar'),
      comment: active(field('')),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note55: {
      active: false,
      number: value(undefined),
      name: value('Upplupna kostnader och förutbetalda intäkter'),
      table: table(
        'notes.note55.table',
        { id: 'label', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        .addRows((rows) =>
          rows
            .addRow(
              '1',
              noteLabel('Upplupna löner'),
              ref(sumAccountsIBs('2910+2911+2912+2919', true)),
              ref(sumAccountsIBs('2910+2911+2912+2919', true, 'previousYear'))
            )
            .addRow(
              '2',
              noteLabel('Upplupna semesterlöner'),
              ref(sumAccountsIBs('2920', true)),
              ref(sumAccountsIBs('2920', true, 'previousYear'))
            )
            .addRow(
              '3',
              noteLabel('Upplupna sociala avgifter'),
              ref(sumAccountsIBs('2930', true)),
              ref(sumAccountsIBs('2930', true, 'previousYear'))
            )
            .addRow(
              '4',
              noteLabel('Upplupna räntekostnader'),
              ref(sumAccountsIBs('2960', true)),
              ref(sumAccountsIBs('2960', true, 'previousYear'))
            )
            .addRow(
              '5',
              noteLabel('Förutbetalda intäkter'),
              ref(sumAccountsIBs('2970+2972+2979', true)),
              ref(sumAccountsIBs('2970+2972+2979', true, 'previousYear'))
            )
            .addRow(
              '6',
              noteLabel('Förutbetalda hyresintäkter'),
              ref(sumAccountsIBs('2971', true)),
              ref(sumAccountsIBs('2971', true, 'previousYear'))
            )
            .addRow(
              '7',
              noteLabel('Övriga upplupna kostnader och förutbetalda intäkter'),
              ref(sumAccountsIBs('2390:2393+2398:2399', true)),
              ref(sumAccountsIBs('2390:2393+2398:2399', true, 'previousYear'))
            )
            .addRow(
              '8',
              noteLabel('Summa'),
              sumNotesRowsById(55, range(1, 8), 'year'),
              sumNotesRowsById(55, range(1, 8), 'previousYear')
            )
            .build()
        )
        .newRowTemplateGenerator((id, baseId) => ({
          id,
          active: true,
          cells: {
            typeOfAssets: value(''),
            balanceSheetItem: value(''),
            amount: value(undefined),
          },
        }))
        .build(),
      commentLabel: field('Kommentar'),
      comment: active(field('')),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note57: {
      active: false,
      number: value(undefined),
      name: value('Eget kapital'),
      table: table(
        'notes.note57.table',
        { id: 'label', label: '' },
        {
          id: 'restrictedEquity',
          label: 'Bundet eget kapital',
        },
        {
          id: 'unrestrictedEquity',
          label: 'Fritt eget kapital',
        }
      )
        .addRows((rows) =>
          rows
            .addRow(
              '1',
              noteLabel('Erhållna gåvor'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '2',
              noteLabel('Beviljade anslag'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '3',
              noteLabel('Årets resultat'),
              value(undefined),
              value(undefined)
            )
            .build()
        )
        .newRowTemplate(value(''), value(undefined), value(undefined))
        .build(),
      secondTable: table(
        'notes.note57.secondTable',
        {
          id: 'secondTableLabel',
          label: 'Omfört till och från bundet eget kapital',
        },
        {
          id: 'secondTableSecondLabel',
          label: '',
        },
        {
          id: 'secondTableThirdLabel',
          label: '',
        }
      )
        .addRows((rows) =>
          rows
            .addRow(
              '1',
              value('Kapitalisering'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '2',
              value('Realisationsresultat'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '3',
              value('Belopp vid räkenskapsårets slut'),
              value(undefined),
              value(undefined)
            )
            .build()
        )
        .newRowTemplate(value(''), value(undefined), value(undefined))
        .build(),
      commentLabel: field('Kommentar'),
      comment: active(field('')),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
  };
};

export type ConfigType = {
  /**
   * Företagsskatt
   */
  companyTax: number;

  /**
   * Schablonintäkt på periodiseringsfonder
   */
  templateTax: number | undefined;

  /**
   * Förenklingsregeln
   */
  simplificationRule: number;

  /**
   * Uppräkning_Sparat_Utrymme
   */
  adjustmentSavedSpace: number;

  /**
   * Takregel_för_beskattning_av_utdelning_i_tjänst_90IBB
   */
  maxTaxationOfDividendOfService90IBB: number;

  /**
   * Inkomstbasbelopp
   */
  incomeBaseAmount: number;

  /**
   * Procent_omkostnadsbeloppet
   */
  percentageOfCost: number;

  /**
   * Rörligt_lönekrav_fast_del
   */
  variableSalaryDemandFixedPart: number;

  /**
   * Lönekrav_procent
   */
  salaryDemandPercentage: number;

  /**
   * Fast_lönekrav
   */
  fixedSalaryDemand: number;

  /**
   * Beskattningsdel
   */
  taxablePart: number;

  /**
   * Takregel_för_beskattning_av_utdelning_i_tjänst_100IBB
   */
  maxTaxationOfDividendOfService100IBB: number;

  /**
   * Procent_löneunderlag
   */
  percentageSalaryBasis: number;

  /**
   * Maximal_expansionsfond
   */
  maxExpansionFund: number;
};

export const isAnnualConfigName = (name: string): name is keyof ConfigType =>
  name in configs[2022];

type AnnualConfig = Record<number, ConfigType>;

const configs: AnnualConfig = {
  2014: {
    companyTax: 0.22,
    templateTax: 0.0209,
    simplificationRule: 155650,
    adjustmentSavedSpace: 0.0509,
    maxTaxationOfDividendOfService90IBB: 5121000,
    incomeBaseAmount: 56900,
    percentageOfCost: 0.1109,
    variableSalaryDemandFixedPart: 339600,
    salaryDemandPercentage: 0.05,
    fixedSalaryDemand: 566000,
    taxablePart: 2 / 3,
    maxTaxationOfDividendOfService100IBB: 5690000,
    percentageSalaryBasis: 0.5,
    maxExpansionFund: 1.2821,
  },
  2015: {
    companyTax: 0.22,
    templateTax: 0.009,
    simplificationRule: 156475,
    adjustmentSavedSpace: 0.039,
    maxTaxationOfDividendOfService90IBB: 5229000,
    incomeBaseAmount: 58100,
    percentageOfCost: 0.099,
    variableSalaryDemandFixedPart: 341400,
    salaryDemandPercentage: 0.05,
    fixedSalaryDemand: 569000,
    taxablePart: 2 / 3,
    maxTaxationOfDividendOfService100IBB: 5810000,
    percentageSalaryBasis: 0.5,
    maxExpansionFund: 1.2821,
  },
  2016: {
    companyTax: 0.22,
    templateTax: 0.0065,
    simplificationRule: 159775,
    adjustmentSavedSpace: 0.0365,
    maxTaxationOfDividendOfService90IBB: 5337000,
    incomeBaseAmount: 59300,
    percentageOfCost: 0.0965,
    variableSalaryDemandFixedPart: 348600,
    salaryDemandPercentage: 0.05,
    fixedSalaryDemand: 557760,
    taxablePart: 2 / 3,
    maxTaxationOfDividendOfService100IBB: 5930000,
    percentageSalaryBasis: 0.5,
    maxExpansionFund: 1.2821,
  },
  2017: {
    companyTax: 0.22,
    templateTax: 0.005,
    simplificationRule: 163075,
    adjustmentSavedSpace: 0.0327,
    maxTaxationOfDividendOfService90IBB: 5535000,
    incomeBaseAmount: 61500,
    percentageOfCost: 0.0927,
    variableSalaryDemandFixedPart: 355800,
    salaryDemandPercentage: 0.05,
    fixedSalaryDemand: 569280,
    taxablePart: 2 / 3,
    maxTaxationOfDividendOfService100IBB: 6150000,
    percentageSalaryBasis: 0.5,
    maxExpansionFund: 1.2821,
  },
  2018: {
    companyTax: 0.22,
    templateTax: 0.005,
    simplificationRule: 169125,
    adjustmentSavedSpace: 0.0349,
    maxTaxationOfDividendOfService90IBB: 5625000,
    incomeBaseAmount: 62500,
    percentageOfCost: 0.0949,
    variableSalaryDemandFixedPart: 369000,
    salaryDemandPercentage: 0.05,
    fixedSalaryDemand: 590400,
    taxablePart: 2 / 3,
    maxTaxationOfDividendOfService100IBB: 6250000,
    percentageSalaryBasis: 0.5,
    maxExpansionFund: 1.2821,
  },
  2019: {
    companyTax: 0.214,
    templateTax: 0.0051,
    simplificationRule: 171875,
    adjustmentSavedSpace: 0.0351,
    maxTaxationOfDividendOfService90IBB: 5796000,
    incomeBaseAmount: 64400,
    percentageOfCost: 0.0951,
    variableSalaryDemandFixedPart: 375000,
    salaryDemandPercentage: 0.05,
    fixedSalaryDemand: 600000,
    taxablePart: 2 / 3,
    maxTaxationOfDividendOfService100IBB: 6440000,
    percentageSalaryBasis: 0.5,
    maxExpansionFund: 1.2821,
  },
  2020: {
    companyTax: 0.214,
    templateTax: 0.005,
    simplificationRule: 177100,
    adjustmentSavedSpace: 0.03,
    maxTaxationOfDividendOfService90IBB: 6012000,
    incomeBaseAmount: 66800,
    percentageOfCost: 0.09,
    variableSalaryDemandFixedPart: 386400,
    salaryDemandPercentage: 0.05,
    fixedSalaryDemand: 618240,
    taxablePart: 2 / 3,
    maxTaxationOfDividendOfService100IBB: 6680000,
    percentageSalaryBasis: 0.5,
    maxExpansionFund: 1.2821,
  },
  2021: {
    companyTax: 0.206,
    templateTax: 0.005,
    simplificationRule: 183700,
    adjustmentSavedSpace: 0.03,
    maxTaxationOfDividendOfService90IBB: 6138000,
    incomeBaseAmount: 68200,
    percentageOfCost: 0.09,
    variableSalaryDemandFixedPart: 400800,
    salaryDemandPercentage: 0.05,
    fixedSalaryDemand: 641280,
    taxablePart: 2 / 3,
    maxTaxationOfDividendOfService100IBB: 6820000,
    percentageSalaryBasis: 0.5,
    maxExpansionFund: Number.NaN, // x in the document.
  },
  2022: {
    companyTax: 0.206,
    templateTax: 0.005,
    simplificationRule: 187550,
    adjustmentSavedSpace: 0.0323,
    maxTaxationOfDividendOfService90IBB: 6390000,
    incomeBaseAmount: 71000,
    percentageOfCost: 0.0923,
    variableSalaryDemandFixedPart: 409200,
    salaryDemandPercentage: 0.05,
    fixedSalaryDemand: 654720,
    taxablePart: 2 / 3,
    maxTaxationOfDividendOfService100IBB: 7100000,
    percentageSalaryBasis: 0.5,
    maxExpansionFund: 1.2594,
  },
  2023: {
    companyTax: 0.206,
    templateTax: 0.0194,
    simplificationRule: 195250,
    adjustmentSavedSpace: 0.0494,
    maxTaxationOfDividendOfService90IBB: 6687000,
    incomeBaseAmount: 74300,
    percentageOfCost: 1.094,
    variableSalaryDemandFixedPart: 426000,
    salaryDemandPercentage: 0.05,
    fixedSalaryDemand: 681600,
    taxablePart: 2 / 3,
    maxTaxationOfDividendOfService100IBB: 7430000,
    percentageSalaryBasis: 0.5,
    maxExpansionFund: 1.2594,
  },
  2024: {
    companyTax: 0.206,
    templateTax: 0.0262,
    simplificationRule: 204325,
    adjustmentSavedSpace: 0.0562,
    maxTaxationOfDividendOfService90IBB: 6858000,
    incomeBaseAmount: 76200,
    percentageOfCost: 1.162,
    variableSalaryDemandFixedPart: 445800,
    salaryDemandPercentage: 0.05,
    fixedSalaryDemand: 713280,
    taxablePart: 2 / 3,
    maxTaxationOfDividendOfService100IBB: 7620000,
    percentageSalaryBasis: 0.5,
    maxExpansionFund: 1.2594,
  },
};

export const getAnnualConfig = (year: number): ConfigType | undefined => {
  // If year is later than the config's latest year, return the config for latest year
  const latestYear = Object.keys(configs).reduce((a, b) =>
    configs[Number(a)] > configs[Number(a)] ? a : b
  );

  if (year > Number(latestYear)) {
    console.info(
      `The financialYear ${year} is later than the latest config's year (${latestYear}).`
    );
    return configs[Number(latestYear)];
  }

  return configs[year];
};

import React from 'react';
// TODO: BUTTONS view design revision needed
import { Button, Tooltip } from '@material-ui/core';
import Logo from '_shared/components/Logo';
import styled from '@emotion/styled';

// We truncate the customer name if it is too long
// A tooltip is added with a delay to show the full name
const Wrapper = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  justify-content: normal;
  max-width: 300px;
`;

const StyledButton = styled(Button)`
  justify-content: flex-start;
`;

interface CustomerButtonProps {
  logoUrl: string | null;
  name: string;
  handleClick: () => void;
  className?: string;
}

const CustomerButton = ({
  className,
  logoUrl = '',
  name = '',
  handleClick,
}: CustomerButtonProps) => {
  const startIcon = (
    <Logo style={{ height: '45px', width: '45px' }} src={logoUrl} />
  );

  return (
    <Tooltip title={name} enterDelay={800}>
      <StyledButton
        className={className}
        startIcon={startIcon}
        onClick={handleClick}
      >
        <Wrapper>{name}</Wrapper>
      </StyledButton>
    </Tooltip>
  );
};

export default CustomerButton;

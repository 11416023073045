import React, { useRef, useContext, useEffect, useMemo } from 'react';
import styled from '@emotion/styled';
import {
  TableCell,
  TableRow,
  Switch,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import { useIntl } from 'react-intl';
import { useDrag, useDrop } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import {
  AgoyTableColumn,
  AgoyTableRow,
  Cell as CellType,
  referenceValue,
} from '@agoy/document';
import { DraggableItemTypes } from 'contants';
import {
  NumberFormatType,
  StandardNumberFormatType,
  getClasses,
} from '@agoy/common';
import { isActive } from '@agoy/annual-report-document';
// @ts-ignore
import { ReactComponent as DeleteIcon } from 'assets/delete.svg';
import { Cell } from './Cell';
import ServiceContext from './Context/TableServiceContext';

interface TableRowProps {
  tableId: string;
  baseId: string;
  row: AgoyTableRow;
  columns: AgoyTableColumn[];
  skipColumns?: number[];
  numberFormat?: NumberFormatType;
  editing?: boolean;
  print?: boolean;
  isLocked?: boolean;
  canDrag?: boolean;
  disableDrag?: boolean;
  hoverColumnId?: string;
  canToggleActive?: boolean;
  canAddRows?: boolean;
  canDeleteRows?: boolean;
  nonEditableRowColumns?: string[];
  selected?: boolean;
  selectable?: boolean;
  accountRanges?: any;
  onSelect?: (rowId: string) => void;
  numberFormatColumn?: (col: string) => NumberFormatType;
  onDragEnd?: (
    draggedRow: { baseId: string; row: AgoyTableRow },
    hoverRow: { baseId: string; row: AgoyTableRow }
  ) => void;
  onDragHover?: (
    draggedRow: { baseId: string; row: AgoyTableRow },
    hoverRow: { baseId: string; row: AgoyTableRow }
  ) => void;
  onDeleteRow?: (row: AgoyTableRow, id: string) => void;
  onToggleRow?: (row: AgoyTableRow, id: string) => void;
  onColumnBlur?: (columnId: string, newValue: string) => void;
  renderCell?: (values: {
    cell: CellType;
    column: AgoyTableColumn;
    row: AgoyTableRow;
    baseId: string;
    isDragging: boolean;
  }) => React.ReactElement | null;
  withPlaceholder?: boolean;
  handleShowRowInfo?: (
    cells: Record<string, CellType> | undefined
  ) => string | undefined;
  onValueChange?: (key: string, newValue: string) => void;
}

const StyledSwitch = styled(Switch)`
  position: absolute;
  left: -4rem;
  top: -1rem;
  .MuiSwitch-input {
    left: ${(props) => (props.checked ? '-50%' : '10%')};
    width: 150%;
  }
`;

const SwitchContainer = styled.div`
  position: relative;
`;

const StyledTableRow = styled(TableRow)`
  background-color: ${(props) =>
    props['data-is-dragging']
      ? props.theme.palette.accountingView.cell.ok
      : 'inherit'};
  cursor: ${(props) => (props['data-can-drag'] ? 'grab' : 'default')};
  &.active {
    display: table-row;
  }
  &.not-active {
    display: none;
  }

  &.selectable {
    cursor: pointer;
  }

  &.non-selectable td {
    color: ${({ theme }) => theme.palette.grey[400]};
  }
`;

const InfoIcon = styled(InfoOutlinedIcon)`
  height: 20px;
  color: ${(props) => props.theme.palette.grey[600]};
`;

const IconTableCell = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledTableCell = styled(TableCell)`
  background-color: ${(props) =>
    props['data-is-column-dragging']
      ? props.theme.palette.accountingView.cell.ok
      : 'inherit'};
  &.right {
    text-align: right;
  }
  &.left {
    text-align: left;
  }

  &.ingoing {
    background-color: #f4f7fa;
    font-weight: 400;
  }

  &.ingoingDark {
    background-color: #ecf0f4;
    font-weight: 400;
  }

  &.outgoing {
    background-color: #faf9f4;
    font-weight: 400;
  }

  &.outgoingDark {
    background-color: #f4f2ec;
    font-weight: 400;
  }

  &.reported {
    background-color: #f3faf8;
    font-weight: 700;
  }

  &.reportedDark {
    background-color: #eef8f4;
    font-weight: 700;
  }

  &.debts {
    background-color: #fafafa;
    font-weight: 400;
  }

  &.debtsDark {
    background-color: #f5f5f5;
    font-weight: 400;
  }

  &.otherDark {
    background-color: #f5f5f5;
  }

  &.titleRow {
    font-weight: 700;
  }
`;

const SwitchCell = styled(TableCell)`
  width: 30px;
`;

type DragItemType = {
  row: {
    baseId: string;
    row: AgoyTableRow<CellType>;
  };
};

type DragCollectType = {
  isDragging: boolean;
};

const GeneralTableRow = ({
  tableId,
  baseId,
  row,
  columns,
  skipColumns = [],
  numberFormat = StandardNumberFormatType,
  editing = false,
  print = false,
  canDrag = false,
  disableDrag = false,
  isLocked = false,
  hoverColumnId,
  canToggleActive,
  canAddRows = false,
  canDeleteRows = false,
  nonEditableRowColumns = [],
  selected,
  selectable = true,
  withPlaceholder,
  accountRanges,
  onSelect,
  numberFormatColumn,
  onDragEnd = () => {},
  onDragHover = () => {},
  onColumnBlur,
  onDeleteRow,
  onToggleRow,
  renderCell,
  handleShowRowInfo,
  onValueChange,
}: TableRowProps): JSX.Element | null => {
  const { formatMessage } = useIntl();
  const service = useContext(ServiceContext);
  const containerRef = useRef(null);

  const rowInfoMessage = useMemo(
    () => handleShowRowInfo?.(row.cells),
    [handleShowRowInfo, row.cells]
  );

  const toggleRow = (toggledRow: AgoyTableRow) => {
    if (onToggleRow) {
      onToggleRow(row, `${tableId}.${toggledRow.id}`);
    } else {
      service?.toggleTableRowActive(`${tableId}.${toggledRow.id}`);
    }
  };

  const deleteRow = () => {
    if (onDeleteRow) {
      onDeleteRow(row, `${tableId}.${row.id}`);
    } else {
      service?.deleteRow(`${tableId}.${row.id}`);
    }
  };

  const [{ isDragging }, connectDrag, preview] = useDrag<
    DragItemType,
    unknown,
    DragCollectType
  >({
    type: DraggableItemTypes.MANAGEMENT_REPORT_ROW,
    item: {
      row: { baseId: tableId, row },
    },
    canDrag: () => editing,
    end: ({ row: draggedRow }) => {
      onDragEnd(draggedRow, { baseId: tableId, row });
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const [, connectDrop] = useDrop<DragItemType, unknown, DragCollectType>({
    accept: DraggableItemTypes.MANAGEMENT_REPORT_ROW,
    hover: ({ row: draggedRow }) => {
      if (draggedRow.row.id !== row.id) {
        onDragHover(draggedRow, { baseId: tableId, row });
      }
    },
    drop: ({ row: draggedRow }) => {
      onDragEnd(draggedRow, { baseId: tableId, row });
    },
  });

  const handleColumnBlur = (colId: string, newColumnValue: string) => {
    if (onColumnBlur == null) return;

    onColumnBlur(colId, newColumnValue);
  };

  const getRowType = (cellId: string): string => {
    const label = row?.cells?.label as { type: string; value: string };
    const rowType = label?.value;
    const rowId = row.id;

    if (rowType?.includes('Ingående')) {
      return cellId === 'previousYear' ? 'ingoingDark' : 'ingoing';
    }

    if (rowType?.includes('Utgående')) {
      return cellId === 'previousYear' ? 'outgoingDark' : 'outgoing';
    }

    if (
      rowType?.includes('Redovisat') ||
      rowType?.includes('Summa') ||
      rowType?.includes('Medel att disponera')
    ) {
      return cellId === 'previousYear' ? 'reportedDark' : 'reported';
    }

    if (rowType?.includes('Övriga skulder')) {
      return cellId === 'previousYear' ? 'debtsDark' : 'debts';
    }

    if (rowType?.includes('Förändringar')) {
      return 'titleRow';
    }

    if (rowId.includes('title')) {
      return 'titleRow';
    }

    if (cellId === 'previousYear') {
      return 'otherDark';
    }

    return '';
  };

  const convertAccountRangesToString = (ranges: number[][]) => {
    let accountRangesString = '';
    ranges.forEach((range) => {
      const [first, last] = range;
      accountRangesString +=
        first === last || last == null
          ? `${first},\n`
          : `${first} - ${last},\n`;
    });

    // account ranges without the last coma and new line
    return accountRangesString.slice(0, accountRangesString.length - 2);
  };

  const getAccountRangeTooltip = (
    rowNr: string,
    labelCell: { id: string; value: string }
  ) => {
    if (!accountRanges) return '';

    const isAMatch = (range: any) => {
      if (range?.rowId != null) {
        return range.rowId === rowNr && range.cellLabel === labelCell.value;
      }

      if (range?.row != null) {
        return range.row === rowNr && range.name === labelCell.value;
      }

      return false;
    };

    const calculationAccounts = accountRanges.find(isAMatch);

    if (!calculationAccounts) return '';

    const getRanges = () => {
      if (calculationAccounts.ranges != null) {
        return calculationAccounts.ranges;
      }

      return calculationAccounts.accounts;
    };

    const ranges = convertAccountRangesToString(getRanges());
    if (ranges.length === 0) {
      return '';
    }

    const label = formatMessage({ id: 'ref.interval' });
    return `${label}\n${ranges}`;
  };

  if (editing && canDrag) {
    connectDrag(containerRef);
    connectDrop(containerRef);
  }

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, [preview]);

  if (print && isActive(row.active) === false) {
    return null;
  }

  const classNames = getClasses({
    selectable: !editing && !!onSelect && selectable,
    'non-selectable': !editing && !!onSelect && !selectable,
    active: editing || !!isActive(row.active),
    'not-active': !editing && !isActive(row.active),
  });

  return (
    <>
      {row.cells && (
        <StyledTableRow
          key={row.id}
          ref={containerRef}
          data-is-dragging={isDragging}
          data-can-drag={canDrag && editing}
          className={classNames}
          selected={selected}
          onClick={(e) => {
            if (!editing && onSelect) {
              e.stopPropagation();
              if (selectable) {
                onSelect(row.id);
              }
            }
          }}
        >
          {editing && (
            <>
              {canToggleActive && (
                <SwitchCell size="small">
                  <SwitchContainer>
                    <Tooltip
                      title={formatMessage({
                        id: 'annualReport.include.in.report',
                      })}
                    >
                      <StyledSwitch
                        edge="start"
                        checked={isActive(row.active)}
                        onChange={() => toggleRow(row)}
                      />
                    </Tooltip>
                  </SwitchContainer>
                </SwitchCell>
              )}
              {canDrag && (
                <TableCell size="small">
                  {!disableDrag && (
                    <IconTableCell>
                      <DragIndicatorIcon />
                    </IconTableCell>
                  )}
                </TableCell>
              )}
            </>
          )}

          {columns.map((col, index) => {
            const cell = row.cells?.[col.id];
            const key = `${row.id}.${col.id}`;
            const output: React.ReactNode[] = [];
            if (skipColumns.indexOf(index) !== -1) {
              output.push(<TableCell key={`${key}.1`} />);
            }

            if (!cell) {
              output.push(<TableCell key={key} />);
            } else if (
              renderCell &&
              renderCell({
                cell,
                column: col,
                row,
                baseId: tableId,
                isDragging,
              }) !== null
            ) {
              output.push(
                <React.Fragment key={key}>
                  {renderCell({
                    cell,
                    column: col,
                    row,
                    baseId: tableId,
                    isDragging,
                  })}
                </React.Fragment>
              );
            } else {
              let numberFormatType = numberFormat;
              if (numberFormatColumn) {
                numberFormatType = numberFormatColumn(col.id);
              }

              const placeholder =
                col.label !== undefined
                  ? col.label
                  : formatMessage({
                      id: `${baseId}.${tableId}.${col.id}`,
                      defaultMessage: col.id,
                    });

              let align: string;
              if (cell.align) {
                align = cell.align;
              } else {
                align = index === 0 ? 'left' : 'right';
              }

              const warning = referenceValue(row.cells?.[`${col.id}Warning`]);

              output.push(
                <StyledTableCell
                  data-is-column-dragging={hoverColumnId === col.id}
                  className={`${align} ${getRowType(col.id)}`}
                  key={key}
                >
                  <Cell
                    cell={cell}
                    id={`${tableId}.${key}`}
                    numberFormatType={numberFormatType}
                    editing={editing && !nonEditableRowColumns.includes(col.id)}
                    isLocked={isLocked}
                    active={
                      col.id === 'label' || isActive(row.active) !== false
                    }
                    onBlur={(newValue) => handleColumnBlur(col.id, newValue)}
                    placeholder={withPlaceholder ? placeholder : undefined}
                    warning={typeof warning === 'string' ? warning : undefined}
                    accountRangeTooltip={getAccountRangeTooltip(
                      row.id,
                      cell as any
                    )}
                    onValueChange={onValueChange}
                  />
                </StyledTableCell>
              );
            }

            return output;
          })}

          {editing && (canAddRows || canDeleteRows) && (
            <TableCell className="delete-cell">
              {(row.id.startsWith('@') || canDeleteRows) && (
                <Tooltip
                  title={formatMessage({
                    id: 'annualReport.delete.row',
                  })}
                >
                  <IconButton
                    className="delete_icon"
                    size="small"
                    disabled={isLocked}
                    onClick={deleteRow}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
            </TableCell>
          )}
          {rowInfoMessage && (
            <TableCell>
              <Tooltip title={rowInfoMessage}>
                <InfoIcon />
              </Tooltip>
            </TableCell>
          )}
        </StyledTableRow>
      )}
    </>
  );
};

export default GeneralTableRow;

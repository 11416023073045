import { AgoyDocumentStructure } from '../document';
import { OperationResult, State } from './types';
import updateDocument from './helpers/updateDocument';
import { isFieldType } from '../../types/field';

/*
 * resetField
 *
 * @param structure       AgoyDocumentStructure (contentDefinition)
 * @param state           The document and changes to update
 * @param id              Field id
 */
const resetField = <T extends AgoyDocumentStructure>(
  structure: T,
  state: State<T>,
  id: string
): OperationResult<T> => {
  return updateDocument(structure, state, id, {
    field: (key, id, props) => {
      const node = props.node;
      const { original } = node;

      if (!original) {
        if (props.changes) {
          console.warn(
            `Changes exist but no original, can't reset field ${id}`
          );
        }
        return props;
      }

      if (!isFieldType(original)) {
        // Just for type checking as not all cell types are valid fields.
        return props;
      }

      return {
        ...props,
        node: original,
        changes: undefined,
      };
    },
  });
};

export default resetField;

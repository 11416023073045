import React from 'react';
import { Settings } from '@agoy/annual-report-document';
import { AgoyTable } from '@agoy/document';
import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';
import { ReportColors } from '_financial-report/utils';
import { getClasses } from '@agoy/common';
import ReportTable from '../ReportTableV2/ReportTable';
import OrganisationLogo from '../OrganisationLogo';
import Heading from '../Heading';

type ReportColorsProps = {
  customColor: string | undefined;
};

const Content = styled.div`
  :not(.is-print-table) {
    min-width: 800px;
    width: min-content;
    margin-left: ${({ theme }) => theme.spacing(4)}px;
  }
`;

const Container = styled('div', {
  shouldForwardProp: (prop) => prop !== 'customColor',
})<ReportColorsProps>`
  height: 100%;
  width: 83%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  background: ${(props) => props.theme.palette.background.paper};

  :not(.is-print-table) {
    width: 80%;
    padding-top: ${({ theme }) => theme.spacing(4)}px;
  }
`;

const TableContainer = styled.div`
  height: 100%;
  width: 80%;
  padding: ${({ theme }) => theme.spacing(1)}px
    ${({ theme }) => theme.spacing(4)}px;
  background: ${(props) => props.theme.palette.background.paper};

  :not(.is-print-table) {
    padding: ${({ theme }) => theme.spacing(1)}px
      ${({ theme }) => theme.spacing(2)}px;
  }
`;

const A4Page = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 960px;
  width: 1400px;
  background: ${(props) => props.theme.palette.background.paper};

  :not(.is-print-table) {
    width: 842px;
    height: 595px;
    padding: ${({ theme }) => theme.spacing(1)}px;
    border-radius: ${({ theme }) => `${theme.shape.borderRadius}px`};
    box-shadow: ${({ theme }) => theme.shadows[4]};
  }
`;

const SmallTitle = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'customColor',
})<ReportColorsProps>`
  font-size: 35px;
  line-height: 38px;
  margin-bottom: ${(props) => props.theme.spacing(1)}px;
  color: ${(props) => props.customColor ?? props.theme.palette.text.primary};

  :not(.is-print-table) {
    font-size: ${(props) => props.theme.typography.fontSize}px;
    margin-bottom: ${(props) => props.theme.spacing(0.5)}px;
  }
`;

interface BalanceSheetViewProps {
  reportColors: ReportColors;
  settings: Settings;
  table: AgoyTable;
  tableId: string;
  pageTitle: string;
  tableTitle: string;
  print?: boolean;
}

const SKIP_COLUMNS = ['notes', 'hidden', 'yearWarning', 'previousYearWarning'];

const StatementTableView = ({
  reportColors,
  settings,
  table,
  tableId,
  pageTitle,
  tableTitle,
  print,
}: BalanceSheetViewProps): JSX.Element | null => {
  const classNames = getClasses({
    'is-print-table': !!print,
  });

  return (
    <Content className={classNames}>
      {!print && pageTitle ? <Heading variant="h1" title={pageTitle} /> : null}
      <A4Page className={classNames}>
        <Container customColor={reportColors.background} className={classNames}>
          <TableContainer className={classNames}>
            <SmallTitle
              customColor={reportColors.textMain}
              className={classNames}
            >
              {tableTitle}
            </SmallTitle>
            <ReportTable
              table={table}
              tableId={tableId}
              skipColumns={SKIP_COLUMNS}
              print={print}
            />
          </TableContainer>
          <OrganisationLogo print={print} />
        </Container>
      </A4Page>
    </Content>
  );
};

export default StatementTableView;

import CurrencyField from '_shared/components/Inputs/CurrencyField';
import styled from '@emotion/styled';

const EditValue = styled(CurrencyField)`
  width: 100%;
  .MuiInputBase-input {
    text-align: right;
  }
`;

export default EditValue;

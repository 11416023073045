import React from 'react';
import { Typography } from '@material-ui/core';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';
import { AnnualReport, AnnualReportType } from '@agoy/annual-report-document';
import { stringValue } from '@agoy/document';
import ImageLoader from '_shared/components/ImageLoader';
import Page from '_shared/components/PrintedDocument/Page';
import extractPartsToPrint from './extractPartsToPrint';

const StyledPage = styled(Page)`
  text-align: center;
`;

const Title = styled(Typography)`
  margin-top: 130px;
  margin-bottom: 40px;
  font-size: 3rem;
  text-transform: uppercase;
`;

const For = styled(Typography)`
  font-size: 1.5rem;
  margin-bottom: 40px;
`;

const LogoImage = styled(ImageLoader)`
  margin-bottom: 40px;
  max-height: 250px;
  max-width: 80%;
`;

const CompanyName = styled(Typography)`
  font-size: 2rem;
`;

const SubTitle = styled(Typography)`
  margin-top: 8px;
  margin-bottom: 16px;
`;

const Index = styled.table`
  text-align: left;
  width: 60%;
  margin: 0 auto;

  thead td {
    font-weight: 700;
  }

  tr td :first-of-type {
    width: 90%;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
`;

interface PeriodIndexPageProps {
  pageNumbers: Record<string, number>;
  annualReport: AnnualReport;
  reportType: AnnualReportType;
}

const PeriodIndexPage = ({
  pageNumbers,
  annualReport,
  reportType,
}: PeriodIndexPageProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const parts = extractPartsToPrint(annualReport, reportType);
  const { settings } = annualReport;
  const logo = stringValue(settings.section.logoLargeUrl);

  return (
    <StyledPage>
      <Title variant="h1">Periodbokslut</Title>
      <For>för</For>
      {logo && <LogoImage src={logo} />}

      <CompanyName>{stringValue(settings.section.clientName)}</CompanyName>
      <SubTitle>{`Org.nr. ${stringValue(
        settings.section.organisationNumber
      )}, Period ${stringValue(settings.section.periodEnd)}`}</SubTitle>

      <Index>
        <thead>
          <tr>
            <th>Innehåll</th>
            <th align="right">Sida</th>
          </tr>
        </thead>
        <tbody>
          {parts.map(
            (part) =>
              pageNumbers[part] && (
                <tr key={`index-page-part-${part}`}>
                  <td>{formatMessage({ id: `annualReport.menu.${part}` })}</td>
                  <td align="right">
                    <a href={`#${part}`}>{pageNumbers[part]}</a>
                  </td>
                </tr>
              )
          )}
        </tbody>
      </Index>
    </StyledPage>
  );
};

export default PeriodIndexPage;

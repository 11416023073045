import { AccountRow, KeyFigure, SummarizationContext } from '../types';

const sum = (accounts: AccountRow[], value: (account: AccountRow) => number) =>
  accounts.reduce((sum, account) => sum + value(account), 0);

const ib = (account: AccountRow) => account.ib;

const ub = (account: AccountRow) => account.ub;

const change = (account: AccountRow) => account.change;

const round = (n: number) => Math.round(1000 * n) / 1000;

const solidity = (
  context: SummarizationContext,
  accounts: (start: number, end: number) => AccountRow[]
): KeyFigure => {
  const a20xx = accounts(2000, 2099);
  const a21xx = accounts(2100, 2199);
  const a1xxx = accounts(1000, 1999);

  return {
    type: 'key',
    id: 'solidity',
    ib: round(
      -(
        sum(a20xx, ib) +
        (1 - context.companyTax(context.startFinancialYear.end)) *
          sum(a21xx, ib)
      ) / sum(a1xxx, ib)
    ),
    ub: round(
      -(
        sum(a20xx, ub) +
        (1 - context.companyTax(context.endFinancialYear.end)) * sum(a21xx, ub)
      ) / sum(a1xxx, ub)
    ),
    change: round(
      -(
        sum(a20xx, change) +
        (1 - context.companyTax(context.startFinancialYear.end)) *
          sum(a21xx, change)
      ) / sum(a1xxx, change)
    ),
  };
};

export default solidity;

/* eslint-disable import/prefer-default-export */
export const getCompanyTypeLabels = (intl): { [key: string]: string } => ({
  shares: intl.formatMessage({ id: 'company.shares' }),
  limited: intl.formatMessage({ id: 'company.limited' }),
  limited_partnership: intl.formatMessage({
    id: 'company.limited.partnership',
  }),
  individual: intl.formatMessage({ id: 'company.individual' }),
  economic_association: intl.formatMessage({
    id: 'company.economic.association',
  }),
  non_profit_association: intl.formatMessage({
    id: 'company.non.profit.association',
  }),
  foundation: intl.formatMessage({ id: 'company.foundation' }),
  other: intl.formatMessage({ id: 'company.other' }),
});

export const getClosingMonthLabels = (intl): { [key: string]: string } => ({
  1: intl.formatMessage({ id: 'month.jan' }),
  2: intl.formatMessage({ id: 'month.feb' }),
  3: intl.formatMessage({ id: 'month.mar' }),
  4: intl.formatMessage({ id: 'month.apr' }),
  5: intl.formatMessage({ id: 'month.may' }),
  6: intl.formatMessage({ id: 'month.jun' }),
  7: intl.formatMessage({ id: 'month.jul' }),
  8: intl.formatMessage({ id: 'month.aug' }),
  9: intl.formatMessage({ id: 'month.sep' }),
  10: intl.formatMessage({ id: 'month.oct' }),
  11: intl.formatMessage({ id: 'month.nov' }),
  12: intl.formatMessage({ id: 'month.dec' }),
});

export const getClosingPeriodLabels = (intl): { [key: string]: string } => ({
  year: intl.formatMessage({ id: 'closingperiod.year' }),
  quarter: intl.formatMessage({
    id: 'closingperiod.quarter',
  }),
  month: intl.formatMessage({
    id: 'closingperiod.month',
  }),
});

export const getDeclarationPeriodLabels = (intl): { [key: string]: string } => {
  const period = intl.formatMessage({ id: 'period' });
  return {
    1: `${period} 1`,
    2: `${period} 2`,
    3: `${period} 3`,
    4: `${period} 4`,
  };
};

import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { numberValue, stringValue } from '@agoy/document';
import { Note74Section } from '@agoy/annual-report-document';
import { Box } from '@material-ui/core';
import { FormattingContextProvider } from '@agoy/formatting';
import Header from '../../UI/Header';
import Comment from '../../UI/Comment';
import NoteTable from '../../UI/NoteTable';
import { Note } from '../../UI/Note';
import { FieldSectionTitle } from '../../UI/FieldSectionTitle';
import { flattenRows, includeRowInPrint } from '../../../../utils/utils';

const TableSpacer = styled.div`
  margin-bottom: 1rem;
`;

type TableType =
  | 'noEmployeesTable'
  | 'noEmployeesPerCountryTable'
  | 'noOfBoardMembersAndExecutivesTable'
  | 'noOfCEOAndOtherExecutivesTable'
  | 'salariesAndBenefitsTable'
  | 'salariesAndBenefitsSumTable'
  | 'socialCostsTable'
  | 'socialCostsSumTable'
  | 'pensionCostsTable'
  | 'specificationOfSalariesPensionsAndOtherBenefitsTable'
  | 'nrOfPeopleUnderTheCompanyManagementTable';

type Props = {
  noteId: string;
  title: string;
  isDigitalSubmission?: boolean;
  hasPreviousYear?: boolean;
};

export const Note74 = (props: Props & Note74Section) => {
  const {
    noteId,
    title,
    number,
    hasPreviousYear = false,
    severancePayAgreementLabel,
    severancePayAgreement,
  } = props;

  type PersonalTableProps = {
    tableId: TableType;
    sumTableId?: TableType;
    noComment?: boolean;
  };

  const isAnyRowRendered = (tableId: string) => {
    return (
      flattenRows(props[tableId].rows).filter((row) => includeRowInPrint(row))
        .length > 0
    );
  };

  const [shouldRenderFirstTitle, setShouldRenderFirstTitle] = useState(false);

  useEffect(() => {
    const isEmployeesTableRendered = isAnyRowRendered(
      'noEmployeesPerCountryTable'
    );

    setShouldRenderFirstTitle(isEmployeesTableRendered);
  }, [props['noEmployeesPerCountryTable']]);

  const [shouldRenderSecondTitle, setShouldRenderSecondTitle] = useState(false);

  useEffect(() => {
    const isSalariesSpecificationTableRendered = isAnyRowRendered(
      'specificationOfSalariesPensionsAndOtherBenefitsTable'
    );

    setShouldRenderSecondTitle(isSalariesSpecificationTableRendered);
  }, [props['specificationOfSalariesPensionsAndOtherBenefitsTable']]);

  const PersonalTable = ({
    tableId,
    sumTableId,
    noComment = false,
  }: PersonalTableProps) => {
    return (
      <>
        <NoteTable
          hasPreviousYear={hasPreviousYear}
          table={props[tableId]}
          tableId={`notes.${noteId}.${tableId}`}
        />
        {sumTableId != null && (
          <NoteTable
            hasPreviousYear={hasPreviousYear}
            table={props[sumTableId]}
            tableId={`notes.${noteId}.${sumTableId}`}
          />
        )}
        {!noComment && (
          <Comment
            field={props[`${tableId}Comment`]}
            value={stringValue(props[`${tableId}Comment`]) || ''}
            labelValue={stringValue(props[`${tableId}CommentLabel`]) || ''}
          />
        )}
      </>
    );
  };

  return (
    <Note id={noteId}>
      <Header noteId={noteId} noteNumber={numberValue(number)} title={title} />

      <Box mt={1} mb={1}>
        <FormattingContextProvider displayInThousands={false}>
          <PersonalTable tableId="noEmployeesTable" />

          {shouldRenderFirstTitle ? (
            <FieldSectionTitle noteId="note74" id="noEmployeesPerCountry" />
          ) : (
            <></>
          )}

          <PersonalTable tableId="noEmployeesPerCountryTable" />

          <PersonalTable
            tableId="noOfBoardMembersAndExecutivesTable"
            noComment
          />
          <PersonalTable tableId="noOfCEOAndOtherExecutivesTable" />
        </FormattingContextProvider>
        <PersonalTable
          tableId="salariesAndBenefitsTable"
          sumTableId="salariesAndBenefitsSumTable"
        />
        <PersonalTable
          tableId="socialCostsTable"
          sumTableId="socialCostsSumTable"
        />
        <PersonalTable tableId="pensionCostsTable" />

        {shouldRenderSecondTitle && (
          <FieldSectionTitle
            noteId="note74"
            id="specificationOfSalariesPensionsAndOtherBenefits"
          />
        )}

        <TableSpacer>
          <PersonalTable
            tableId="specificationOfSalariesPensionsAndOtherBenefitsTable"
            noComment
          />
        </TableSpacer>
        <FormattingContextProvider displayInThousands={false}>
          <PersonalTable
            tableId="nrOfPeopleUnderTheCompanyManagementTable"
            noComment
          />
        </FormattingContextProvider>
        <Comment
          field={severancePayAgreement}
          value={stringValue(severancePayAgreement) || ''}
          labelValue={stringValue(severancePayAgreementLabel) || ''}
        />
      </Box>
    </Note>
  );
};

export default Note74;

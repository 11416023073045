import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import shortid from 'shortid';
import { useIntl } from 'react-intl';

import { ccyFormat } from '@agoy/common';
import { useSelector } from 'redux/reducers';

import Header from './Header';
import DebtItem from './DebtItem';

import {
  GroupedHolidayProps,
  DebtSummProps,
  DebtFieldsProps,
  SemesterskuldPreviewProps,
  SummariesProps,
  SummaryDeptType,
} from './types';
import {
  debtHeaders,
  summary,
  totalSummary,
  checkForZeros,
  calculateDebtCategorySum,
  calculateTotalDebtSum,
  checkForNumbers,
} from './semesterskuldUtil';

const LIST_ITEM_WIDTH = 120;

type CustomWidthProps = {
  customWidth: number;
};

const A4Page = styled.main`
  width: ${(p: CustomWidthProps) => p.customWidth}px;
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 80vh;
`;

const GroupList = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  @media print {
    display: block;
    position: relative;
  }
`;

const ScrollableGroupList = styled(GroupList)`
  overflow-y: auto;
  overflow-x: hidden;
`;

const SummaryGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  width: 65%;
  padding: 10px;
  padding-right: 0;
  border-bottom: 2px solid black;
  margin-bottom: 10px;
`;

const SummaryRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const SummaryItem = styled.div`
  display: flex;
`;

const SummaryItemBold = styled.div`
  font-weight: bold;
`;

const Info = styled.p`
  width: ${(p: DebtFieldsProps) => p.customWidth}px;
  margin: 0px 0px 5px 0px;
  text-align: ${(p: DebtFieldsProps) => p.customAlign || 'left'};
`;

const DebtInfo = styled(Info)`
  font-weight: bold;
  white-space: nowrap;
`;

const DebtHeaderInfo = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-bottom: solid 2px black;
  margin-bottom: 10px;
`;

const SummaryGroupData = ({ data }: SummariesProps): JSX.Element => {
  return (
    <SummaryGroup>
      {data.map((item) => (
        <SummaryRow key={item.title}>
          <SummaryItem>{item.title}</SummaryItem>
          <SummaryItem>{checkForZeros(ccyFormat(item.value, 2))}</SummaryItem>
        </SummaryRow>
      ))}
    </SummaryGroup>
  );
};

const SummaryData = ({ data }: SummariesProps): JSX.Element => {
  return (
    <SummaryGroup>
      {data.map((item) => (
        <SummaryRow key={item.title}>
          <SummaryItemBold>{item.title}</SummaryItemBold>
          <SummaryItem>{checkForZeros(ccyFormat(item.value, 2))}</SummaryItem>
        </SummaryRow>
      ))}
    </SummaryGroup>
  );
};

const SemesterskuldlistaPreview = ({
  clientId,
  data,
  periodEnd,
  isPrint = false,
}: SemesterskuldPreviewProps): JSX.Element | null => {
  const { formatMessage } = useIntl();
  const [groupedHolidayDebts, setGroupedHolidayDebts] = useState<
    GroupedHolidayProps[]
  >([]);
  const [summaryDebt, setSummaryDebt] = useState<DebtSummProps[]>([]);
  const [totalSummaryDebt, setTotalSummaryDebt] = useState<DebtSummProps[]>([]);

  const customer = useSelector((state) => state.customers[clientId]);

  const summaryDebtData: SummaryDeptType = {
    debtAdvance: [],
    wageSaved: [],
    variableUnused: [],
    wageUnused: [],
    variableEarned: [],
    wageEarned: [],
    total: [],
  };

  const DebtList = isPrint ? GroupList : ScrollableGroupList;

  useEffect(() => {
    if (data) {
      data.forEach((item) => {
        summaryDebtData.wageEarned.push(item.WageEarned);
        summaryDebtData.variableEarned.push(item.VariableEarned);
        summaryDebtData.wageUnused.push(item.WageUnused);
        summaryDebtData.variableUnused.push(item.VariableUnused);
        summaryDebtData.wageSaved.push(item.WageSaved);
        summaryDebtData.debtAdvance.push(item.DebtAdvance);
        summaryDebtData.total.push(item.TotalDebtEmployee);
      });

      summary.forEach((item, i) => {
        item.value = calculateDebtCategorySum(
          Object.values(summaryDebtData)[i]
        );
      });

      const totalData = calculateTotalDebtSum(summaryDebtData.total);

      totalSummary.forEach((item, i) => {
        item.value = totalData[i];
      });
    }
    setSummaryDebt(summary);
    setTotalSummaryDebt(totalSummary);
  }, [data]);

  useEffect(() => {
    if (data) {
      const employees = data.map((employee) => {
        return {
          employeeId: employee.EmployeeId,
          employeeName: employee.EmployeeName,
          debtCategories: {
            earned: formatMessage({
              id: 'reconciliation.hidden_row.semesterskuld.preview.debtEarned',
            }),
            unused: formatMessage({
              id: 'reconciliation.hidden_row.semesterskuld.preview.debtUnused',
            }),
            saved: formatMessage({
              id: 'reconciliation.hidden_row.semesterskuld.preview.debtSaved',
            }),
            advance: formatMessage({
              id: 'reconciliation.hidden_row.semesterskuld.preview.debtAdvance',
            }),
          },
          days: {
            earned: employee.DaysEarned,
            unused: employee.DaysUnused,
            saved: employee.DaysSaved,
            advance: '',
          },
          variable: {
            earned: employee.VariableEarned,
            unused: employee.VariableUnused,
            saved: '',
            advance: '',
          },
          wage: {
            earned: employee.WageEarned,
            unused: employee.WageUnused,
            saved: employee.WageSaved,
            advance: '',
          },
          debt: {
            earned: employee.DebtEarned,
            unused: employee.DebtUnused,
            saved: employee.DebtSaved,
            advance: employee.DebtAdvance,
            total: employee.TotalDebtEmployee,
            totalContribution: employee.TotalDebtEmployerContribution,
          },
        };
      });

      const resultList = employees.map((employee) => ({
        id: shortid(),
        itemData: Object.entries(employee).map((value) => {
          if (typeof value[1] === 'string') {
            return {
              id: shortid(),
              align: 'left',
              value: value[1],
            };
          }
          if (value[0] === 'debtCategories' && typeof value[1] === 'object') {
            return Object.entries(value[1]).map((item) => ({
              id: shortid(),
              align: 'left',
              value: item[1].toString(),
              title: item[0],
            }));
          }
          return Object.entries(value[1]).map((item) => ({
            id: shortid(),
            align: 'right',
            value: checkForNumbers(item[1])
              ? checkForZeros(ccyFormat(item[1], 2))
              : item[1].toString(),
            title: item[0],
          }));
        }),
      }));

      setGroupedHolidayDebts(resultList);
    }
  }, [data]);

  return (
    <A4Page customWidth={LIST_ITEM_WIDTH * debtHeaders.length}>
      <Header
        title={formatMessage({
          id: 'reconciliation.hidden_row.semesterskuld.preview.title',
        })}
        headerData={{
          customerName: customer?.name || '',
          customerNumber: customer?.orgNumber || '',
        }}
        printInfo={{
          title: formatMessage({
            id: 'reconciliation.hidden_row.semesterskuld.preview.period',
          }),
          period: periodEnd,
        }}
      />
      <SummaryData data={totalSummaryDebt} />
      <SummaryGroupData data={summaryDebt} />
      <DebtHeaderInfo>
        {debtHeaders.map((item) => (
          <DebtInfo
            key={item.id}
            customWidth={LIST_ITEM_WIDTH}
            customAlign={item.align}
          >
            {item.id}
          </DebtInfo>
        ))}
      </DebtHeaderInfo>
      <DebtList>
        {groupedHolidayDebts?.map((employee) => (
          <DebtItem
            key={employee.id}
            colsAmount={LIST_ITEM_WIDTH}
            fields={employee.itemData}
          />
        ))}
      </DebtList>
    </A4Page>
  );
};

export default SemesterskuldlistaPreview;

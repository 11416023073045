import { value } from '@agoy/document';
import { NoteInvertedTwoTableSection, IxbrlCell } from '../../../../common';
import { sumNotesRowsById } from '../../../../common/utils/accountSumsUtil';
import { year, previousYear } from '../../../../common/utils/date-util';
import {
  field,
  ixbrlCell,
  ixbrlField,
  range,
  table,
  tupleRef,
} from '../../../../common/utils/util';

const MONETARY_ITEM_TYPE = 'monetaryItemType';

export const getNote20 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null,
  documentTypeVersion = '1'
): NoteInvertedTwoTableSection => {
  return {
    active: false,
    number: value(undefined),
    name: value('Ägarintressen i övriga företag'),
    // secondTable really comes first here
    secondTable: table<IxbrlCell>(
      'notes.note20.secondTable',
      { id: 'label', label: '' },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      .addRows((rows) =>
        rows
          // 'ingående' = previous period outgoing balance
          .addRow(
            '1',
            noteLabel('Ingående anskaffningsvärden'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AgarintressenOvrigaForetagAnskaffningsvarden',
              contextRef: 'balans1',
              saldo: 'debit',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AgarintressenOvrigaForetagAnskaffningsvarden',
              contextRef: 'balans2',
              saldo: 'debit',
            })
          )
          .addRow(
            '2',
            noteLabel('Inköp'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AgarintressenOvrigaForetagAnskaffningsvardenInkop',
              contextRef: 'period0',
              standardRubrik:
                'Ökning av anskaffningsvärden i samband med inköp av ägarintressen i övriga företag',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AgarintressenOvrigaForetagAnskaffningsvardenInkop',
              contextRef: 'period1',
              standardRubrik:
                'Ökning av anskaffningsvärden i samband med inköp av ägarintressen i övriga företag',
            })
          )
          .addRow(
            'anskaffningsvardenFusion',
            noteLabel('Fusion'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AgarintressenOvrigaForetagAnskaffningsvardenFusion',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden för ägarintressen i övriga företag genom fusion',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AgarintressenOvrigaForetagAnskaffningsvardenFusion',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden för ägarintressen i övriga företag genom fusion',
            })
          )
          .addRow(
            '3',
            noteLabel('Försäljningar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AgarintressenOvrigaForetagAnskaffningsvardenForsaljningar',
              contextRef: 'period0',
              standardRubrik:
                'Minskning av anskaffningsvärden i samband med försäljningar av ägarintressen i övriga företag',
              negateValue: true,
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AgarintressenOvrigaForetagAnskaffningsvardenForsaljningar',
              contextRef: 'period1',
              standardRubrik:
                'Minskning av anskaffningsvärden i samband med försäljningar av ägarintressen i övriga företag',
              negateValue: true,
            })
          )
          .addRow(
            'shareholderContributions',
            noteLabel('Lämnade aktieägartillskott'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AgarintressenOvrigaForetagAnskaffningsvardenLamnadeAktieagartillskott',
              contextRef: 'period0',
              standardRubrik:
                'Ökning av anskaffningsvärden i samband med lämnande aktieägartillskott till ägarintressen i övriga företag',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AgarintressenOvrigaForetagAnskaffningsvardenLamnadeAktieagartillskott',
              contextRef: 'period1',
              standardRubrik:
                'Ökning av anskaffningsvärden i samband med lämnande aktieägartillskott till ägarintressen i övriga företag',
            })
          )
          .addRow(
            'repaymentShareholderContributions',
            noteLabel('Återbetalning av lämnat aktieägartillskott'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AgarintressenOvrigaForetagAnskaffningsvardenAterbetalningLamnatAktieagartillskott',
              contextRef: 'period0',
              standardRubrik:
                'Minskning av anskaffningsvärden i samband med återbetalning av lämnat aktieägartillskott till ägarintressen i övriga företag',
              negateValue: true,
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AgarintressenOvrigaForetagAnskaffningsvardenAterbetalningLamnatAktieagartillskott',
              contextRef: 'period1',
              standardRubrik:
                'Minskning av anskaffningsvärden i samband med återbetalning av lämnat aktieägartillskott till ägarintressen i övriga företag',
              negateValue: true,
            })
          )
          .addRow(
            'yearProfit',
            noteLabel('Årets resultatandel'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AgarintressenOvrigaForetagAnskaffningsvardenAretsResultatAndel',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden i samband med periodens resultatandel i handelsbolag som är ägarintressen i övriga företag',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AgarintressenOvrigaForetagAnskaffningsvardenAretsResultatAndel',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden i samband med periodens resultatandel i handelsbolag som är ägarintressen i övriga företag',
            })
          )
          .addRow(
            '4',
            noteLabel('Omklassificeringar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AgarintressenOvrigaForetagAnskaffningsvardenOmklassificeringar',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden i samband med omklassificeringar av ägarintressen i övriga företag',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AgarintressenOvrigaForetagAnskaffningsvardenOmklassificeringar',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden i samband med omklassificeringar av ägarintressen i övriga företag',
            })
          )
          .addRow(
            'anskaffningsvardenConversionDifferences',
            noteLabel('Omräkningsdifferenser'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AgarintressenOvrigaForetagAnskaffningsvardenOmrakningsdifferenser',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden i samband med omräkningsdifferenser av ägarintressen i övriga företag',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AgarintressenOvrigaForetagAnskaffningsvardenOmrakningsdifferenser',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden i samband med omräkningsdifferenser av ägarintressen i övriga företag',
            })
          )
          .addRow(
            '5',
            noteLabel('Utgående anskaffningsvärden'),
            ixbrlCell(
              sumNotesRowsById(
                20,
                [
                  ...range(1, 5),
                  'anskaffningsvardenFusion',
                  'shareholderContributions',
                  'repaymentShareholderContributions',
                  'yearProfit',
                  'anskaffningsvardenConversionDifferences',
                ],
                'year',
                'secondTable'
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:AgarintressenOvrigaForetagAnskaffningsvarden',
                contextRef: 'balans0',
                saldo: 'debit',
              }
            ),
            ixbrlCell(
              sumNotesRowsById(
                20,
                [
                  ...range(1, 5),
                  'anskaffningsvardenFusion',
                  'shareholderContributions',
                  'repaymentShareholderContributions',
                  'yearProfit',
                  'anskaffningsvardenConversionDifferences',
                ],
                'previousYear',
                'secondTable'
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:AgarintressenOvrigaForetagAnskaffningsvarden',
                contextRef: 'balans1',
                saldo: 'debit',
              }
            )
          )
          .addRow(
            '6',
            noteLabel('Ingående nedskrivningar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AgarintressenOvrigaForetagNedskrivningar',
              contextRef: 'balans1',
              saldo: 'credit',
              negateValue: true,
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AgarintressenOvrigaForetagNedskrivningar',
              contextRef: 'balans2',
              saldo: 'credit',
              negateValue: true,
            })
          )
          .addRow(
            'nedskrivningarFusion',
            noteLabel('Fusion'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AgarintressenOvrigaForetagForandringNedskrivningarFusion',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av nedskrivningar för ägarintressen i övriga företag genom fusion',
              negateValue: true,
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AgarintressenOvrigaForetagForandringNedskrivningarFusion',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av nedskrivningar för ägarintressen i övriga företag genom fusion',
              negateValue: true,
            })
          )
          .addRow(
            '7',
            noteLabel('Försäljningar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AgarintressenOvrigaForetagForandringNedskrivningarAterfordaNedskrivningarForsaljningar',
              contextRef: 'period0',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AgarintressenOvrigaForetagForandringNedskrivningarAterfordaNedskrivningarForsaljningar',
              contextRef: 'period1',
            })
          )
          .addRow(
            '8',
            noteLabel('Återförda nedskrivningar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AgarintressenOvrigaForetagForandringNedskrivningarAterfordaNedskrivningar',
              contextRef: 'period0',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AgarintressenOvrigaForetagForandringNedskrivningarAterfordaNedskrivningar',
              contextRef: 'period1',
            })
          )
          .addRow(
            '9',
            noteLabel('Omklassificeringar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AgarintressenOvrigaForetagForandringNedskrivningarOmklassificeringar',
              contextRef: 'period0',
              standardRubrik:
                'Minskning (ökning) av nedskrivningar i samband med omklassificeringar av ägarintressen i övriga företag',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AgarintressenOvrigaForetagForandringNedskrivningarOmklassificeringar',
              contextRef: 'period1',
              standardRubrik:
                'Minskning (ökning) av nedskrivningar i samband med omklassificeringar av ägarintressen i övriga företag',
            })
          )
          .addRow(
            '10',
            noteLabel('Årets nedskrivningar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AgarintressenOvrigaForetagForandringNedskrivningarAretsNedskrivningar',
              contextRef: 'period0',
              negateValue: true,
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AgarintressenOvrigaForetagForandringNedskrivningarAretsNedskrivningar',
              contextRef: 'period1',
              negateValue: true,
            })
          )
          .addRow(
            'nedskrivningarConversionDifferences',
            noteLabel('Omräkningsdifferenser'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AgarintressenOvrigaForetagForandringNedskrivningarOmrakningsdifferenser',
              contextRef: 'period0',
              standardRubrik:
                'Minskning (ökning) av nedskrivningar i samband med omräkningsdifferenser av ägarintressen i övriga företag',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AgarintressenOvrigaForetagForandringNedskrivningarOmrakningsdifferenser',
              contextRef: 'period1',
              standardRubrik:
                'Minskning (ökning) av nedskrivningar i samband med omräkningsdifferenser av ägarintressen i övriga företag',
            })
          )
          .addRow(
            '11',
            noteLabel('Utgående nedskrivningar'),
            ixbrlCell(
              sumNotesRowsById(
                20,
                [
                  ...range(6, 11),
                  'nedskrivningarFusion',
                  'nedskrivningarConversionDifferences',
                ],
                'year',
                'secondTable'
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:AgarintressenOvrigaForetagNedskrivningar',
                contextRef: 'balans0',
                saldo: 'credit',
                negateValue: true,
              }
            ),
            ixbrlCell(
              sumNotesRowsById(
                20,
                [
                  ...range(6, 11),
                  'nedskrivningarFusion',
                  'nedskrivningarConversionDifferences',
                ],
                'previousYear',
                'secondTable'
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:AgarintressenOvrigaForetagNedskrivningar',
                contextRef: 'balans1',
                saldo: 'credit',
                negateValue: true,
              }
            )
          )
          .addRow(
            '12',
            noteLabel('Redovisat värde'),
            ixbrlCell(sumNotesRowsById(20, [5, 11], 'year', 'secondTable'), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AgarintressenOvrigaForetag',
              contextRef: 'balans0',
              saldo: 'debit',
            }),
            ixbrlCell(
              sumNotesRowsById(20, [5, 11], 'previousYear', 'secondTable'),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:AgarintressenOvrigaForetag',
                contextRef: 'balans1',
                saldo: 'debit',
              }
            )
          )
          .build()
      )
      .newRowTemplate(value(''), value(undefined), value(undefined))
      .build(),
    table: table<IxbrlCell>(
      'notes.note20.table',
      { id: 'companyName', label: 'Företagets namn' },
      {
        id: 'organisationNumber',
        label: 'Organisationsnummer',
      },
      {
        id: 'place',
        label: 'Säte',
      },
      {
        id: 'capitalShare',
        label: 'Kapitalandel',
      }
    )
      .addRows((rows) =>
        rows
          .addRow(
            '1',
            ixbrlCell(value(''), {
              type: 'stringItemType',
              name: 'se-cd-base:ForetagetsNamn',
              contextRef: 'period0',
              tuple: {
                name: 'se-gaap-ext:InnehavAndelarAgarintressenOvrigaForetagInklusiveUppgiftForetagTuple',
                id: `${rows.getBaseId()}.1.tuple`,
                tupleID: `${rows.getBaseId()}.1.tuple`,
              },
              tupleRef: tupleRef(1, `${rows.getBaseId()}.1.tuple`),
            }),
            ixbrlCell(value(''), {
              type: 'stringItemType',
              name: 'se-cd-base:Organisationsnummer',
              contextRef: 'period0',
              tupleRef: tupleRef(2, `${rows.getBaseId()}.1.tuple`),
            }),
            ixbrlCell(value(''), {
              type: 'stringItemType',
              name: 'se-cd-base:ForetagetsSate',
              contextRef: 'period0',
              tupleRef: tupleRef(3, `${rows.getBaseId()}.1.tuple`),
            }),
            ixbrlCell(value(undefined), {
              type: 'pureItemType',
              name: 'se-gen-base:InnehavAndelarAgarintressenOvrigaForetagKapitalandel',
              contextRef: 'balans0',
              tupleRef: tupleRef(7, `${rows.getBaseId()}.1.tuple`),
            })
          )
          .build()
      )
      .newRowTemplateGenerator((id, baseId) => ({
        id,
        active: true,
        cells: {
          companyName: ixbrlCell(value(''), {
            type: 'stringItemType',
            name: 'se-cd-base:ForetagetsNamn',
            contextRef: 'period0',
            tuple: {
              name: 'se-gaap-ext:InnehavAndelarAgarintressenOvrigaForetagInklusiveUppgiftForetagTuple',
              id: `${baseId}.${id}.tuple`,
              tupleID: `${baseId}.${id}.tuple`,
            },
            tupleRef: tupleRef(1, `${baseId}.${id}.tuple`),
          }),
          organisationNumber: ixbrlCell(value(''), {
            type: 'stringItemType',
            name: 'se-cd-base:Organisationsnummer',
            contextRef: 'period0',
            tupleRef: tupleRef(2, `${baseId}.${id}.tuple`),
          }),
          place: ixbrlCell(value(''), {
            type: 'stringItemType',
            name: 'se-cd-base:ForetagetsSate',
            contextRef: 'period0',
            tupleRef: tupleRef(3, `${baseId}.${id}.tuple`),
          }),
          capitalShare: ixbrlCell(value(undefined), {
            type: 'pureItemType',
            name: 'se-gen-base:InnehavAndelarAgarintressenOvrigaForetagKapitalandel',
            contextRef: 'balans0',
            tupleRef: tupleRef(7, `${baseId}.${id}.tuple`),
          }),
        },
      }))
      .build(),
    commentLabel: field('Kommentar'),
    comment: ixbrlField(field(''), {
      type: 'stringItemType',
      name: 'se-gen-base:NotAgarintressenOvrigaForetagKommentar',
      contextRef: 'period0',
    }),
    group: value(undefined),
    section: value(undefined),
    validForReportType: {
      k2: true,
      k3: documentTypeVersion === '1',
    },
  };
};

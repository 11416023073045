/*
 * This is a GENERATED file, do not change manually!
 * Generated:
 * - from file k3-arsredovisning-2021-10-31-rev20230131 - Balansräkning.csv
 * - Comments: nan
 */

import {
  id,
  account,
  sum,
  sumAllowNull,
  or,
  multiply,
  label,
  ref,
  value,
  refs,
  ReferenceAccountInformation,
  TimePeriod,
  AccountValueType,
} from '@agoy/document';
import { IxbrlCell } from '../../../../common';
import {
  table,
  RowsBuilder,
  ixbrlCell,
} from '../../../../common/utils/util';
import { AnnualReportType, AnnualReportVersion } from '../../../../document';
import { range, BalanceSheet } from '@agoy/annual-report-document';

const accountRangeRows = (
  accounts: Record<string, ReferenceAccountInformation>,
  first: number,
  last: number,
  rows: RowsBuilder,
  switchSign: boolean,
  currentPeriod: string,
  previousPeriod: string,
  modifier?: AccountValueType | undefined
): RowsBuilder => {
  range(first, last + 1).forEach((n) => {
    const accountNumber = n.toString();
    const accountInformation = accounts[accountNumber];
    if (accountInformation) {
      rows
        .addRowWithType(
          accountNumber,
          'account',
          label(`${accountInformation.accountName}`),
          undefined,
          ref(
            switchSign
              ? multiply(
                  -1,
                  or(account(accountNumber, modifier, currentPeriod), 0)
                )
              : or(account(accountNumber, modifier, currentPeriod), 0)
          ),
          ref(
            switchSign
              ? multiply(
                  -1,
                  or(account(accountNumber, modifier, previousPeriod), 0)
                )
              : or(account(accountNumber, modifier, previousPeriod), 0)
          )
        )
        .setSortKey(n);
    }
  });
  return rows;
};

export const balanceStatementK3v2Config = (
  accounts: Record<string, ReferenceAccountInformation>,
  period: TimePeriod,
  previousPeriod: TimePeriod | null,
  type: AnnualReportType,
  isDigitalSubmission: boolean | null,
  documentTypeVersion: AnnualReportVersion = '2',
  accountModifier: AccountValueType = 'ub'
): BalanceSheet => ({
  active: undefined,
  section: {
    active: undefined,
    assets: table<IxbrlCell>(
      'balanceSheet.section.assets',
      { id: 'label', dataType: 'text' },
      { id: 'notes', dataType: 'numeric' },
      { id: 'year', label: `${period.endDateISO}`, dataType: 'numeric' },
      previousPeriod
        ? {
            id: 'previousYear',
            label: `${previousPeriod.endDateISO}`,
            dataType: 'numeric',
          }
        : undefined
    )
      .addRows((rows) => {
        rows
          .addRowWithType(
            'TillgangarAbstract',
            'header',
            value('Tillgångar'),
            refs(),
            undefined,
            undefined
          )
          .addSubRows((rows) => {
            rows
              .addRowWithType(
                'TecknatEjInbetaltKapital',
                'row',
                value('Tecknat men ej inbetalt kapital'),
                refs(),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'balanceSheet.section.assets.TillgangarAbstract.TecknatEjInbetaltKapital.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:TecknatEjInbetaltKapital',
                    contextRef: 'balans0',
                    standardRubrik: 'Tecknat men ej inbetalt kapital',
                    saldo: 'debit',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'balanceSheet.section.assets.TillgangarAbstract.TecknatEjInbetaltKapital.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:TecknatEjInbetaltKapital',
                    contextRef: 'balans1',
                    standardRubrik: 'Tecknat men ej inbetalt kapital',
                    saldo: 'debit',
                    negateValue: false,
                  }
                )
              ) // <<< end row_empty_value ---
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(or(account('$ub', accountModifier, 'year'), 0)),
                ref(or(account('$ib', accountModifier, 'previousYear'), 0))
              )
              .addSubRows((rows) => {
                return rows.build();
              });
            rows
              .addRowWithType(
                'AnlaggningstillgangarAbstract',
                'header',
                value('Anläggningstillgångar'),
                refs(),
                undefined,
                undefined
              )
              .addSubRows((rows) => {
                rows
                  .addRowWithType(
                    'ImmateriellaAnlaggningstillgangarAbstract',
                    'header',
                    value('Immateriella anläggningstillgångar'),
                    refs(),
                    undefined,
                    undefined
                  )
                  .addSubRows((rows) => {
                    rows
                      .addRowWithType(
                        'BalanseradeUtgifterUtvecklingsarbetenLiknandeArbeten',
                        'row',
                        value(
                          'Balanserade utgifter för utvecklingsarbeten och liknande arbeten'
                        ),
                        refs(),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.BalanseradeUtgifterUtvecklingsarbetenLiknandeArbeten.*.year'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknandeArbeten',
                            contextRef: 'balans0',
                            standardRubrik:
                              'Balanserade utgifter för utvecklingsarbeten och liknande arbeten',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        ),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.BalanseradeUtgifterUtvecklingsarbetenLiknandeArbeten.*.previousYear'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknandeArbeten',
                            contextRef: 'balans1',
                            standardRubrik:
                              'Balanserade utgifter för utvecklingsarbeten och liknande arbeten',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        )
                      ) // <<< end row_empty_value ---
                      .newRowTemplateWithType(
                        'account',
                        label('$label'),
                        undefined,
                        ref(or(account('$ub', accountModifier, 'year'), 0)),
                        ref(
                          or(account('$ib', accountModifier, 'previousYear'), 0)
                        )
                      )
                      .addSubRows((rows) => {
                        accountRangeRows(
                          accounts,
                          1000,
                          1019,
                          rows,
                          false,
                          'year',
                          'previousYear',
                          accountModifier
                        );

                        return rows.build();
                      });
                    rows
                      .addRowWithType(
                        'KoncessionerPatentLicenserVarumarkenLiknandeRattigheter',
                        'row',
                        value(
                          'Koncessioner, patent, licenser, varumärken samt liknande rättigheter'
                        ),
                        refs(),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.KoncessionerPatentLicenserVarumarkenLiknandeRattigheter.*.year'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheter',
                            contextRef: 'balans0',
                            standardRubrik:
                              'Koncessioner, patent, licenser, varumärken samt liknande rättigheter',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        ),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.KoncessionerPatentLicenserVarumarkenLiknandeRattigheter.*.previousYear'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheter',
                            contextRef: 'balans1',
                            standardRubrik:
                              'Koncessioner, patent, licenser, varumärken samt liknande rättigheter',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        )
                      ) // <<< end row_empty_value ---
                      .newRowTemplateWithType(
                        'account',
                        label('$label'),
                        undefined,
                        ref(or(account('$ub', accountModifier, 'year'), 0)),
                        ref(
                          or(account('$ib', accountModifier, 'previousYear'), 0)
                        )
                      )
                      .addSubRows((rows) => {
                        accountRangeRows(
                          accounts,
                          1020,
                          1059,
                          rows,
                          false,
                          'year',
                          'previousYear',
                          accountModifier
                        );

                        return rows.build();
                      });
                    rows
                      .addRowWithType(
                        'HyresratterLiknandeRattigheter',
                        'row',
                        value('Hyresrätter och liknande rättigheter'),
                        refs(),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.HyresratterLiknandeRattigheter.*.year'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:HyresratterLiknandeRattigheter',
                            contextRef: 'balans0',
                            standardRubrik:
                              'Hyresrätter och liknande rättigheter',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        ),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.HyresratterLiknandeRattigheter.*.previousYear'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:HyresratterLiknandeRattigheter',
                            contextRef: 'balans1',
                            standardRubrik:
                              'Hyresrätter och liknande rättigheter',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        )
                      ) // <<< end row_empty_value ---
                      .newRowTemplateWithType(
                        'account',
                        label('$label'),
                        undefined,
                        ref(or(account('$ub', accountModifier, 'year'), 0)),
                        ref(
                          or(account('$ib', accountModifier, 'previousYear'), 0)
                        )
                      )
                      .addSubRows((rows) => {
                        accountRangeRows(
                          accounts,
                          1060,
                          1069,
                          rows,
                          false,
                          'year',
                          'previousYear',
                          accountModifier
                        );

                        return rows.build();
                      });
                    rows
                      .addRowWithType(
                        'Goodwill',
                        'row',
                        value('Goodwill'),
                        refs(),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.Goodwill.*.year'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:Goodwill',
                            contextRef: 'balans0',
                            standardRubrik: 'Goodwill',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        ),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.Goodwill.*.previousYear'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:Goodwill',
                            contextRef: 'balans1',
                            standardRubrik: 'Goodwill',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        )
                      ) // <<< end row_empty_value ---
                      .newRowTemplateWithType(
                        'account',
                        label('$label'),
                        undefined,
                        ref(or(account('$ub', accountModifier, 'year'), 0)),
                        ref(
                          or(account('$ib', accountModifier, 'previousYear'), 0)
                        )
                      )
                      .addSubRows((rows) => {
                        accountRangeRows(
                          accounts,
                          1070,
                          1079,
                          rows,
                          false,
                          'year',
                          'previousYear',
                          accountModifier
                        );

                        return rows.build();
                      });
                    rows
                      .addRowWithType(
                        'PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangar',
                        'row',
                        value(
                          'Pågående utvecklingsarbeten och förskott avseende immateriella anläggningstillgångar'
                        ),
                        refs(),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangar.*.year'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangar',
                            contextRef: 'balans0',
                            standardRubrik:
                              'Pågående utvecklingsarbeten och förskott avseende immateriella anläggningstillgångar',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        ),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangar.*.previousYear'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangar',
                            contextRef: 'balans1',
                            standardRubrik:
                              'Pågående utvecklingsarbeten och förskott avseende immateriella anläggningstillgångar',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        )
                      ) // <<< end row_empty_value ---
                      .newRowTemplateWithType(
                        'account',
                        label('$label'),
                        undefined,
                        ref(or(account('$ub', accountModifier, 'year'), 0)),
                        ref(
                          or(account('$ib', accountModifier, 'previousYear'), 0)
                        )
                      )
                      .addSubRows((rows) => {
                        accountRangeRows(
                          accounts,
                          1080,
                          1099,
                          rows,
                          false,
                          'year',
                          'previousYear',
                          accountModifier
                        );

                        return rows.build();
                      });
                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'ImmateriellaAnlaggningstillgangar',
                    'sum',
                    value('Summa immateriella anläggningstillgångar'),
                    undefined,
                    ixbrlCell(
                      ref(
                        sumAllowNull(
                          id(
                            'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        name: 'se-gen-base:ImmateriellaAnlaggningstillgangar',
                        contextRef: 'balans0',
                        standardRubrik: 'Immateriella anläggningstillgångar',
                        saldo: 'debit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sumAllowNull(
                          id(
                            'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        name: 'se-gen-base:ImmateriellaAnlaggningstillgangar',
                        contextRef: 'balans1',
                        standardRubrik: 'Immateriella anläggningstillgångar',
                        saldo: 'debit',
                        negateValue: false,
                      }
                    )
                  )
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(or(account('$ub', accountModifier, 'year'), 0)),
                    ref(or(account('$ib', accountModifier, 'previousYear'), 0))
                  )
                  .addSubRows((rows) => {
                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'MateriellaAnlaggningstillgangarAbstract',
                    'header',
                    value('Materiella anläggningstillgångar'),
                    refs(),
                    undefined,
                    undefined
                  )
                  .addSubRows((rows) => {
                    rows
                      .addRowWithType(
                        'ByggnaderMark',
                        'row',
                        value('Byggnader och mark'),
                        refs(),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.MateriellaAnlaggningstillgangarAbstract.ByggnaderMark.*.year'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:ByggnaderMark',
                            contextRef: 'balans0',
                            standardRubrik: 'Byggnader och mark',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        ),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.MateriellaAnlaggningstillgangarAbstract.ByggnaderMark.*.previousYear'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:ByggnaderMark',
                            contextRef: 'balans1',
                            standardRubrik: 'Byggnader och mark',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        )
                      ) // <<< end row_empty_value ---
                      .newRowTemplateWithType(
                        'account',
                        label('$label'),
                        undefined,
                        ref(or(account('$ub', accountModifier, 'year'), 0)),
                        ref(
                          or(account('$ib', accountModifier, 'previousYear'), 0)
                        )
                      )
                      .addSubRows((rows) => {
                        accountRangeRows(
                          accounts,
                          1100,
                          1119,
                          rows,
                          false,
                          'year',
                          'previousYear',
                          accountModifier
                        );
                        accountRangeRows(
                          accounts,
                          1130,
                          1199,
                          rows,
                          false,
                          'year',
                          'previousYear',
                          accountModifier
                        );

                        return rows.build();
                      });
                    rows
                      .addRowWithType(
                        'MaskinerAndraTekniskaAnlaggningar',
                        'row',
                        value('Maskiner och andra tekniska anläggningar'),
                        refs(),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.MateriellaAnlaggningstillgangarAbstract.MaskinerAndraTekniskaAnlaggningar.*.year'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningar',
                            contextRef: 'balans0',
                            standardRubrik:
                              'Maskiner och andra tekniska anläggningar',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        ),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.MateriellaAnlaggningstillgangarAbstract.MaskinerAndraTekniskaAnlaggningar.*.previousYear'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningar',
                            contextRef: 'balans1',
                            standardRubrik:
                              'Maskiner och andra tekniska anläggningar',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        )
                      ) // <<< end row_empty_value ---
                      .newRowTemplateWithType(
                        'account',
                        label('$label'),
                        undefined,
                        ref(or(account('$ub', accountModifier, 'year'), 0)),
                        ref(
                          or(account('$ib', accountModifier, 'previousYear'), 0)
                        )
                      )
                      .addSubRows((rows) => {
                        accountRangeRows(
                          accounts,
                          1200,
                          1219,
                          rows,
                          false,
                          'year',
                          'previousYear',
                          accountModifier
                        );

                        return rows.build();
                      });
                    rows
                      .addRowWithType(
                        'InventarierVerktygInstallationer',
                        'row',
                        value('Inventarier, verktyg och installationer'),
                        refs(),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.MateriellaAnlaggningstillgangarAbstract.InventarierVerktygInstallationer.*.year'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:InventarierVerktygInstallationer',
                            contextRef: 'balans0',
                            standardRubrik:
                              'Inventarier, verktyg och installationer',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        ),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.MateriellaAnlaggningstillgangarAbstract.InventarierVerktygInstallationer.*.previousYear'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:InventarierVerktygInstallationer',
                            contextRef: 'balans1',
                            standardRubrik:
                              'Inventarier, verktyg och installationer',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        )
                      ) // <<< end row_empty_value ---
                      .newRowTemplateWithType(
                        'account',
                        label('$label'),
                        undefined,
                        ref(or(account('$ub', accountModifier, 'year'), 0)),
                        ref(
                          or(account('$ib', accountModifier, 'previousYear'), 0)
                        )
                      )
                      .addSubRows((rows) => {
                        accountRangeRows(
                          accounts,
                          1220,
                          1279,
                          rows,
                          false,
                          'year',
                          'previousYear',
                          accountModifier
                        );

                        return rows.build();
                      });
                    rows
                      .addRowWithType(
                        'ForbattringsutgifterAnnansFastighet',
                        'row',
                        value('Förbättringsutgifter på annans fastighet'),
                        refs(),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.MateriellaAnlaggningstillgangarAbstract.ForbattringsutgifterAnnansFastighet.*.year'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:ForbattringsutgifterAnnansFastighet',
                            contextRef: 'balans0',
                            standardRubrik:
                              'Förbättringsutgifter på annans fastighet',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        ),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.MateriellaAnlaggningstillgangarAbstract.ForbattringsutgifterAnnansFastighet.*.previousYear'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:ForbattringsutgifterAnnansFastighet',
                            contextRef: 'balans1',
                            standardRubrik:
                              'Förbättringsutgifter på annans fastighet',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        )
                      ) // <<< end row_empty_value ---
                      .newRowTemplateWithType(
                        'account',
                        label('$label'),
                        undefined,
                        ref(or(account('$ub', accountModifier, 'year'), 0)),
                        ref(
                          or(account('$ib', accountModifier, 'previousYear'), 0)
                        )
                      )
                      .addSubRows((rows) => {
                        accountRangeRows(
                          accounts,
                          1120,
                          1129,
                          rows,
                          false,
                          'year',
                          'previousYear',
                          accountModifier
                        );

                        return rows.build();
                      });
                    rows
                      .addRowWithType(
                        'PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangar',
                        'row',
                        value(
                          'Pågående nyanläggningar och förskott avseende materiella anläggningstillgångar'
                        ),
                        refs(),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.MateriellaAnlaggningstillgangarAbstract.PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangar.*.year'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangar',
                            contextRef: 'balans0',
                            standardRubrik:
                              'Pågående nyanläggningar och förskott avseende materiella anläggningstillgångar',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        ),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.MateriellaAnlaggningstillgangarAbstract.PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangar.*.previousYear'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangar',
                            contextRef: 'balans1',
                            standardRubrik:
                              'Pågående nyanläggningar och förskott avseende materiella anläggningstillgångar',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        )
                      ) // <<< end row_empty_value ---
                      .newRowTemplateWithType(
                        'account',
                        label('$label'),
                        undefined,
                        ref(or(account('$ub', accountModifier, 'year'), 0)),
                        ref(
                          or(account('$ib', accountModifier, 'previousYear'), 0)
                        )
                      )
                      .addSubRows((rows) => {
                        accountRangeRows(
                          accounts,
                          1280,
                          1289,
                          rows,
                          false,
                          'year',
                          'previousYear',
                          accountModifier
                        );

                        return rows.build();
                      });
                    rows
                      .addRowWithType(
                        'OvrigaMateriellaAnlaggningstillgangar',
                        'row',
                        value('Övriga materiella anläggningstillgångar'),
                        refs(),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.MateriellaAnlaggningstillgangarAbstract.OvrigaMateriellaAnlaggningstillgangar.*.year'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:OvrigaMateriellaAnlaggningstillgangar',
                            contextRef: 'balans0',
                            standardRubrik:
                              'Övriga materiella anläggningstillgångar',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        ),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.MateriellaAnlaggningstillgangarAbstract.OvrigaMateriellaAnlaggningstillgangar.*.previousYear'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:OvrigaMateriellaAnlaggningstillgangar',
                            contextRef: 'balans1',
                            standardRubrik:
                              'Övriga materiella anläggningstillgångar',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        )
                      ) // <<< end row_empty_value ---
                      .newRowTemplateWithType(
                        'account',
                        label('$label'),
                        undefined,
                        ref(or(account('$ub', accountModifier, 'year'), 0)),
                        ref(
                          or(account('$ib', accountModifier, 'previousYear'), 0)
                        )
                      )
                      .addSubRows((rows) => {
                        accountRangeRows(
                          accounts,
                          1290,
                          1299,
                          rows,
                          false,
                          'year',
                          'previousYear',
                          accountModifier
                        );

                        return rows.build();
                      });
                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'MateriellaAnlaggningstillgangar',
                    'sum',
                    value('Summa materiella anläggningstillgångar'),
                    undefined,
                    ixbrlCell(
                      ref(
                        sumAllowNull(
                          id(
                            'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.MateriellaAnlaggningstillgangarAbstract.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        name: 'se-gen-base:MateriellaAnlaggningstillgangar',
                        contextRef: 'balans0',
                        standardRubrik: 'Materiella anläggningstillgångar',
                        saldo: 'debit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sumAllowNull(
                          id(
                            'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.MateriellaAnlaggningstillgangarAbstract.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        name: 'se-gen-base:MateriellaAnlaggningstillgangar',
                        contextRef: 'balans1',
                        standardRubrik: 'Materiella anläggningstillgångar',
                        saldo: 'debit',
                        negateValue: false,
                      }
                    )
                  )
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(or(account('$ub', accountModifier, 'year'), 0)),
                    ref(or(account('$ib', accountModifier, 'previousYear'), 0))
                  )
                  .addSubRows((rows) => {
                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'FinansiellaAnlaggningstillgangarAbstract',
                    'header',
                    value('Finansiella anläggningstillgångar'),
                    refs(),
                    undefined,
                    undefined
                  )
                  .addSubRows((rows) => {
                    rows
                      .addRowWithType(
                        'AndelarKoncernforetag',
                        'row',
                        value('Andelar i koncernföretag'),
                        refs(),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.FinansiellaAnlaggningstillgangarAbstract.AndelarKoncernforetag.*.year'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:AndelarKoncernforetag',
                            contextRef: 'balans0',
                            standardRubrik: 'Andelar i koncernföretag',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        ),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.FinansiellaAnlaggningstillgangarAbstract.AndelarKoncernforetag.*.previousYear'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:AndelarKoncernforetag',
                            contextRef: 'balans1',
                            standardRubrik: 'Andelar i koncernföretag',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        )
                      ) // <<< end row_empty_value ---
                      .newRowTemplateWithType(
                        'account',
                        label('$label'),
                        undefined,
                        ref(or(account('$ub', accountModifier, 'year'), 0)),
                        ref(
                          or(account('$ib', accountModifier, 'previousYear'), 0)
                        )
                      )
                      .addSubRows((rows) => {
                        accountRangeRows(
                          accounts,
                          1300,
                          1319,
                          rows,
                          false,
                          'year',
                          'previousYear',
                          accountModifier
                        );

                        return rows.build();
                      });
                    rows
                      .addRowWithType(
                        'FordringarKoncernforetagLangfristiga',
                        'row',
                        value('Fordringar hos koncernföretag'),
                        refs(),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.FinansiellaAnlaggningstillgangarAbstract.FordringarKoncernforetagLangfristiga.*.year'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:FordringarKoncernforetagLangfristiga',
                            contextRef: 'balans0',
                            standardRubrik:
                              'Långfristiga fordringar hos koncernföretag',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        ),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.FinansiellaAnlaggningstillgangarAbstract.FordringarKoncernforetagLangfristiga.*.previousYear'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:FordringarKoncernforetagLangfristiga',
                            contextRef: 'balans1',
                            standardRubrik:
                              'Långfristiga fordringar hos koncernföretag',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        )
                      ) // <<< end row_empty_value ---
                      .newRowTemplateWithType(
                        'account',
                        label('$label'),
                        undefined,
                        ref(or(account('$ub', accountModifier, 'year'), 0)),
                        ref(
                          or(account('$ib', accountModifier, 'previousYear'), 0)
                        )
                      )
                      .addSubRows((rows) => {
                        accountRangeRows(
                          accounts,
                          1320,
                          1329,
                          rows,
                          false,
                          'year',
                          'previousYear',
                          accountModifier
                        );

                        return rows.build();
                      });
                    rows
                      .addRowWithType(
                        'AndelarIntresseforetagGemensamtStyrdaForetag',
                        'row',
                        value(
                          'Andelar i intresseföretag och gemensamt styrda företag'
                        ),
                        refs(),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.FinansiellaAnlaggningstillgangarAbstract.AndelarIntresseforetagGemensamtStyrdaForetag.*.year'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetag',
                            contextRef: 'balans0',
                            standardRubrik:
                              'Andelar i intresseföretag och gemensamt styrda företag',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        ),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.FinansiellaAnlaggningstillgangarAbstract.AndelarIntresseforetagGemensamtStyrdaForetag.*.previousYear'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetag',
                            contextRef: 'balans1',
                            standardRubrik:
                              'Andelar i intresseföretag och gemensamt styrda företag',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        )
                      ) // <<< end row_empty_value ---
                      .newRowTemplateWithType(
                        'account',
                        label('$label'),
                        undefined,
                        ref(or(account('$ub', accountModifier, 'year'), 0)),
                        ref(
                          or(account('$ib', accountModifier, 'previousYear'), 0)
                        )
                      )
                      .addSubRows((rows) => {
                        accountRangeRows(
                          accounts,
                          1330,
                          1335,
                          rows,
                          false,
                          'year',
                          'previousYear',
                          accountModifier
                        );
                        accountRangeRows(
                          accounts,
                          1337,
                          1339,
                          rows,
                          false,
                          'year',
                          'previousYear',
                          accountModifier
                        );

                        return rows.build();
                      });
                    rows
                      .addRowWithType(
                        'FordringarIntresseforetagGemensamtStyrdaForetagLangfristiga',
                        'row',
                        value(
                          'Fordringar hos intresseföretag och gemensamt styrda företag'
                        ),
                        refs(),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.FinansiellaAnlaggningstillgangarAbstract.FordringarIntresseforetagGemensamtStyrdaForetagLangfristiga.*.year'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:FordringarIntresseforetagGemensamtStyrdaForetagLangfristiga',
                            contextRef: 'balans0',
                            standardRubrik:
                              'Långfristiga fordringar hos intresseföretag och gemensamt styrda företag',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        ),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.FinansiellaAnlaggningstillgangarAbstract.FordringarIntresseforetagGemensamtStyrdaForetagLangfristiga.*.previousYear'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:FordringarIntresseforetagGemensamtStyrdaForetagLangfristiga',
                            contextRef: 'balans1',
                            standardRubrik:
                              'Långfristiga fordringar hos intresseföretag och gemensamt styrda företag',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        )
                      ) // <<< end row_empty_value ---
                      .newRowTemplateWithType(
                        'account',
                        label('$label'),
                        undefined,
                        ref(or(account('$ub', accountModifier, 'year'), 0)),
                        ref(
                          or(account('$ib', accountModifier, 'previousYear'), 0)
                        )
                      )
                      .addSubRows((rows) => {
                        accountRangeRows(
                          accounts,
                          1340,
                          1345,
                          rows,
                          false,
                          'year',
                          'previousYear',
                          accountModifier
                        );
                        accountRangeRows(
                          accounts,
                          1348,
                          1349,
                          rows,
                          false,
                          'year',
                          'previousYear',
                          accountModifier
                        );

                        return rows.build();
                      });
                    rows
                      .addRowWithType(
                        'AgarintressenOvrigaForetag',
                        'row',
                        value('Ägarintressen i övriga företag'),
                        refs(),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.FinansiellaAnlaggningstillgangarAbstract.AgarintressenOvrigaForetag.*.year'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:AgarintressenOvrigaForetag',
                            contextRef: 'balans0',
                            standardRubrik: 'Ägarintressen i övriga företag',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        ),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.FinansiellaAnlaggningstillgangarAbstract.AgarintressenOvrigaForetag.*.previousYear'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:AgarintressenOvrigaForetag',
                            contextRef: 'balans1',
                            standardRubrik: 'Ägarintressen i övriga företag',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        )
                      ) // <<< end row_empty_value ---
                      .newRowTemplateWithType(
                        'account',
                        label('$label'),
                        undefined,
                        ref(or(account('$ub', accountModifier, 'year'), 0)),
                        ref(
                          or(account('$ib', accountModifier, 'previousYear'), 0)
                        )
                      )
                      .addSubRows((rows) => {
                        accountRangeRows(
                          accounts,
                          1336,
                          1336,
                          rows,
                          false,
                          'year',
                          'previousYear',
                          accountModifier
                        );

                        return rows.build();
                      });
                    rows
                      .addRowWithType(
                        'FordringarOvrigaForetagAgarintresseLangfristiga',
                        'row',
                        value(
                          'Fordringar hos övriga företag som det finns ett ägarintresse i'
                        ),
                        refs(),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.FinansiellaAnlaggningstillgangarAbstract.FordringarOvrigaForetagAgarintresseLangfristiga.*.year'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:FordringarOvrigaForetagAgarintresseLangfristiga',
                            contextRef: 'balans0',
                            standardRubrik:
                              'Långfristiga fordringar hos övriga företag som det finns ägarintresse i',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        ),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.FinansiellaAnlaggningstillgangarAbstract.FordringarOvrigaForetagAgarintresseLangfristiga.*.previousYear'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:FordringarOvrigaForetagAgarintresseLangfristiga',
                            contextRef: 'balans1',
                            standardRubrik:
                              'Långfristiga fordringar hos övriga företag som det finns ägarintresse i',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        )
                      ) // <<< end row_empty_value ---
                      .newRowTemplateWithType(
                        'account',
                        label('$label'),
                        undefined,
                        ref(or(account('$ub', accountModifier, 'year'), 0)),
                        ref(
                          or(account('$ib', accountModifier, 'previousYear'), 0)
                        )
                      )
                      .addSubRows((rows) => {
                        accountRangeRows(
                          accounts,
                          1346,
                          1347,
                          rows,
                          false,
                          'year',
                          'previousYear',
                          accountModifier
                        );

                        return rows.build();
                      });
                    rows
                      .addRowWithType(
                        'AndraLangfristigaVardepappersinnehav',
                        'row',
                        value('Andra långfristiga värdepappersinnehav'),
                        refs(),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.FinansiellaAnlaggningstillgangarAbstract.AndraLangfristigaVardepappersinnehav.*.year'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:AndraLangfristigaVardepappersinnehav',
                            contextRef: 'balans0',
                            standardRubrik:
                              'Andra långfristiga värdepappersinnehav',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        ),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.FinansiellaAnlaggningstillgangarAbstract.AndraLangfristigaVardepappersinnehav.*.previousYear'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:AndraLangfristigaVardepappersinnehav',
                            contextRef: 'balans1',
                            standardRubrik:
                              'Andra långfristiga värdepappersinnehav',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        )
                      ) // <<< end row_empty_value ---
                      .newRowTemplateWithType(
                        'account',
                        label('$label'),
                        undefined,
                        ref(or(account('$ub', accountModifier, 'year'), 0)),
                        ref(
                          or(account('$ib', accountModifier, 'previousYear'), 0)
                        )
                      )
                      .addSubRows((rows) => {
                        accountRangeRows(
                          accounts,
                          1350,
                          1359,
                          rows,
                          false,
                          'year',
                          'previousYear',
                          accountModifier
                        );

                        return rows.build();
                      });
                    rows
                      .addRowWithType(
                        'LanDelagareNarstaende',
                        'row',
                        value('Lån till delägare eller närstående'),
                        refs(),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.FinansiellaAnlaggningstillgangarAbstract.LanDelagareNarstaende.*.year'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:LanDelagareNarstaende',
                            contextRef: 'balans0',
                            standardRubrik:
                              'Långfristiga lån till delägare eller närstående',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        ),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.FinansiellaAnlaggningstillgangarAbstract.LanDelagareNarstaende.*.previousYear'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:LanDelagareNarstaende',
                            contextRef: 'balans1',
                            standardRubrik:
                              'Långfristiga lån till delägare eller närstående',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        )
                      ) // <<< end row_empty_value ---
                      .newRowTemplateWithType(
                        'account',
                        label('$label'),
                        undefined,
                        ref(or(account('$ub', accountModifier, 'year'), 0)),
                        ref(
                          or(account('$ib', accountModifier, 'previousYear'), 0)
                        )
                      )
                      .addSubRows((rows) => {
                        accountRangeRows(
                          accounts,
                          1360,
                          1369,
                          rows,
                          false,
                          'year',
                          'previousYear',
                          accountModifier
                        );

                        return rows.build();
                      });
                    rows
                      .addRowWithType(
                        'UppskjutenSkattefordran',
                        'row',
                        value('Uppskjuten skattefordran'),
                        refs(),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.FinansiellaAnlaggningstillgangarAbstract.UppskjutenSkattefordran.*.year'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:UppskjutenSkattefordran',
                            contextRef: 'balans0',
                            standardRubrik: 'Uppskjuten skattefordran',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        ),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.FinansiellaAnlaggningstillgangarAbstract.UppskjutenSkattefordran.*.previousYear'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:UppskjutenSkattefordran',
                            contextRef: 'balans1',
                            standardRubrik: 'Uppskjuten skattefordran',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        )
                      ) // <<< end row_empty_value ---
                      .newRowTemplateWithType(
                        'account',
                        label('$label'),
                        undefined,
                        ref(or(account('$ub', accountModifier, 'year'), 0)),
                        ref(
                          or(account('$ib', accountModifier, 'previousYear'), 0)
                        )
                      )
                      .addSubRows((rows) => {
                        accountRangeRows(
                          accounts,
                          1370,
                          1379,
                          rows,
                          false,
                          'year',
                          'previousYear',
                          accountModifier
                        );

                        return rows.build();
                      });
                    rows
                      .addRowWithType(
                        'AndraLangfristigaFordringar',
                        'row',
                        value('Andra långfristiga fordringar'),
                        refs(),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.FinansiellaAnlaggningstillgangarAbstract.AndraLangfristigaFordringar.*.year'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:AndraLangfristigaFordringar',
                            contextRef: 'balans0',
                            standardRubrik: 'Andra långfristiga fordringar',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        ),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.FinansiellaAnlaggningstillgangarAbstract.AndraLangfristigaFordringar.*.previousYear'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:AndraLangfristigaFordringar',
                            contextRef: 'balans1',
                            standardRubrik: 'Andra långfristiga fordringar',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        )
                      ) // <<< end row_empty_value ---
                      .newRowTemplateWithType(
                        'account',
                        label('$label'),
                        undefined,
                        ref(or(account('$ub', accountModifier, 'year'), 0)),
                        ref(
                          or(account('$ib', accountModifier, 'previousYear'), 0)
                        )
                      )
                      .addSubRows((rows) => {
                        accountRangeRows(
                          accounts,
                          1380,
                          1399,
                          rows,
                          false,
                          'year',
                          'previousYear',
                          accountModifier
                        );

                        return rows.build();
                      });
                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'FinansiellaAnlaggningstillgangar',
                    'sum',
                    value('Summa finansiella anläggningstillgångar'),
                    undefined,
                    ixbrlCell(
                      ref(
                        sumAllowNull(
                          id(
                            'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.FinansiellaAnlaggningstillgangarAbstract.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        name: 'se-gen-base:FinansiellaAnlaggningstillgangar',
                        contextRef: 'balans0',
                        standardRubrik: 'Finansiella anläggningstillgångar',
                        saldo: 'debit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sumAllowNull(
                          id(
                            'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.FinansiellaAnlaggningstillgangarAbstract.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        name: 'se-gen-base:FinansiellaAnlaggningstillgangar',
                        contextRef: 'balans1',
                        standardRubrik: 'Finansiella anläggningstillgångar',
                        saldo: 'debit',
                        negateValue: false,
                      }
                    )
                  )
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(or(account('$ub', accountModifier, 'year'), 0)),
                    ref(or(account('$ib', accountModifier, 'previousYear'), 0))
                  )
                  .addSubRows((rows) => {
                    return rows.build();
                  });
                return rows.build();
              });
            rows
              .addRowWithType(
                'Anlaggningstillgangar',
                'sum',
                value('Summa anläggningstillgångar'),
                undefined,
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    name: 'se-gen-base:Anlaggningstillgangar',
                    contextRef: 'balans0',
                    standardRubrik: 'Anläggningstillgångar',
                    saldo: 'debit',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    name: 'se-gen-base:Anlaggningstillgangar',
                    contextRef: 'balans1',
                    standardRubrik: 'Anläggningstillgångar',
                    saldo: 'debit',
                    negateValue: false,
                  }
                )
              )
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(or(account('$ub', accountModifier, 'year'), 0)),
                ref(or(account('$ib', accountModifier, 'previousYear'), 0))
              )
              .addSubRows((rows) => {
                return rows.build();
              });
            rows
              .addRowWithType(
                'OmsattningstillgangarAbstract',
                'header',
                value('Omsättningstillgångar'),
                refs(),
                undefined,
                undefined
              )
              .addSubRows((rows) => {
                rows
                  .addRowWithType(
                    'VarulagerMmAbstract',
                    'header',
                    value('Varulager m.m.'),
                    refs(),
                    undefined,
                    undefined
                  )
                  .addSubRows((rows) => {
                    rows
                      .addRowWithType(
                        'LagerRavarorFornodenheter',
                        'row',
                        value('Råvaror och förnödenheter'),
                        refs(),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.VarulagerMmAbstract.LagerRavarorFornodenheter.*.year'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:LagerRavarorFornodenheter',
                            contextRef: 'balans0',
                            standardRubrik:
                              'Lager av råvaror och förnödenheter',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        ),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.VarulagerMmAbstract.LagerRavarorFornodenheter.*.previousYear'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:LagerRavarorFornodenheter',
                            contextRef: 'balans1',
                            standardRubrik:
                              'Lager av råvaror och förnödenheter',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        )
                      ) // <<< end row_empty_value ---
                      .newRowTemplateWithType(
                        'account',
                        label('$label'),
                        undefined,
                        ref(or(account('$ub', accountModifier, 'year'), 0)),
                        ref(
                          or(account('$ib', accountModifier, 'previousYear'), 0)
                        )
                      )
                      .addSubRows((rows) => {
                        accountRangeRows(
                          accounts,
                          1400,
                          1439,
                          rows,
                          false,
                          'year',
                          'previousYear',
                          accountModifier
                        );

                        return rows.build();
                      });
                    rows
                      .addRowWithType(
                        'LagerVarorUnderTillverkning',
                        'row',
                        value('Varor under tillverkning'),
                        refs(),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.VarulagerMmAbstract.LagerVarorUnderTillverkning.*.year'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:LagerVarorUnderTillverkning',
                            contextRef: 'balans0',
                            standardRubrik: 'Lager av varor under tillverkning',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        ),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.VarulagerMmAbstract.LagerVarorUnderTillverkning.*.previousYear'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:LagerVarorUnderTillverkning',
                            contextRef: 'balans1',
                            standardRubrik: 'Lager av varor under tillverkning',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        )
                      ) // <<< end row_empty_value ---
                      .newRowTemplateWithType(
                        'account',
                        label('$label'),
                        undefined,
                        ref(or(account('$ub', accountModifier, 'year'), 0)),
                        ref(
                          or(account('$ib', accountModifier, 'previousYear'), 0)
                        )
                      )
                      .addSubRows((rows) => {
                        accountRangeRows(
                          accounts,
                          1440,
                          1449,
                          rows,
                          false,
                          'year',
                          'previousYear',
                          accountModifier
                        );

                        return rows.build();
                      });
                    rows
                      .addRowWithType(
                        'LagerFardigaVarorHandelsvaror',
                        'row',
                        value('Färdiga varor och handelsvaror'),
                        refs(),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.VarulagerMmAbstract.LagerFardigaVarorHandelsvaror.*.year'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:LagerFardigaVarorHandelsvaror',
                            contextRef: 'balans0',
                            standardRubrik:
                              'Lager av färdiga egentillverkade varor samt varor som har köpts för vidareförsäljning',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        ),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.VarulagerMmAbstract.LagerFardigaVarorHandelsvaror.*.previousYear'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:LagerFardigaVarorHandelsvaror',
                            contextRef: 'balans1',
                            standardRubrik:
                              'Lager av färdiga egentillverkade varor samt varor som har köpts för vidareförsäljning',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        )
                      ) // <<< end row_empty_value ---
                      .newRowTemplateWithType(
                        'account',
                        label('$label'),
                        undefined,
                        ref(or(account('$ub', accountModifier, 'year'), 0)),
                        ref(
                          or(account('$ib', accountModifier, 'previousYear'), 0)
                        )
                      )
                      .addSubRows((rows) => {
                        accountRangeRows(
                          accounts,
                          1450,
                          1469,
                          rows,
                          false,
                          'year',
                          'previousYear',
                          accountModifier
                        );

                        return rows.build();
                      });
                    rows
                      .addRowWithType(
                        'OvrigaLagertillgangar',
                        'row',
                        value('Övriga lagertillgångar'),
                        refs(),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.VarulagerMmAbstract.OvrigaLagertillgangar.*.year'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:OvrigaLagertillgangar',
                            contextRef: 'balans0',
                            standardRubrik: 'Övriga lagertillgångar',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        ),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.VarulagerMmAbstract.OvrigaLagertillgangar.*.previousYear'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:OvrigaLagertillgangar',
                            contextRef: 'balans1',
                            standardRubrik: 'Övriga lagertillgångar',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        )
                      ) // <<< end row_empty_value ---
                      .newRowTemplateWithType(
                        'account',
                        label('$label'),
                        undefined,
                        ref(or(account('$ub', accountModifier, 'year'), 0)),
                        ref(
                          or(account('$ib', accountModifier, 'previousYear'), 0)
                        )
                      )
                      .addSubRows((rows) => {
                        accountRangeRows(
                          accounts,
                          1490,
                          1499,
                          rows,
                          false,
                          'year',
                          'previousYear',
                          accountModifier
                        );

                        return rows.build();
                      });
                    rows
                      .addRowWithType(
                        'PagaendeArbetenAnnansRakningOmsattningstillgangar',
                        'row',
                        value('Pågående arbete för annans räkning'),
                        refs(),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.VarulagerMmAbstract.PagaendeArbetenAnnansRakningOmsattningstillgangar.*.year'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:PagaendeArbetenAnnansRakningOmsattningstillgangar',
                            contextRef: 'balans0',
                            standardRubrik:
                              'Pågående arbete för annans räkning, tillgång',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        ),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.VarulagerMmAbstract.PagaendeArbetenAnnansRakningOmsattningstillgangar.*.previousYear'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:PagaendeArbetenAnnansRakningOmsattningstillgangar',
                            contextRef: 'balans1',
                            standardRubrik:
                              'Pågående arbete för annans räkning, tillgång',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        )
                      ) // <<< end row_empty_value ---
                      .newRowTemplateWithType(
                        'account',
                        label('$label'),
                        undefined,
                        ref(or(account('$ub', accountModifier, 'year'), 0)),
                        ref(
                          or(account('$ib', accountModifier, 'previousYear'), 0)
                        )
                      )
                      .addSubRows((rows) => {
                        accountRangeRows(
                          accounts,
                          1470,
                          1479,
                          rows,
                          false,
                          'year',
                          'previousYear',
                          accountModifier
                        );

                        return rows.build();
                      });
                    rows
                      .addRowWithType(
                        'ForskottTillLeverantorer',
                        'row',
                        value('Förskott till leverantörer'),
                        refs(),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.VarulagerMmAbstract.ForskottTillLeverantorer.*.year'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:ForskottTillLeverantorer',
                            contextRef: 'balans0',
                            standardRubrik: 'Förskott till leverantörer',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        ),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.VarulagerMmAbstract.ForskottTillLeverantorer.*.previousYear'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:ForskottTillLeverantorer',
                            contextRef: 'balans1',
                            standardRubrik: 'Förskott till leverantörer',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        )
                      ) // <<< end row_empty_value ---
                      .newRowTemplateWithType(
                        'account',
                        label('$label'),
                        undefined,
                        ref(or(account('$ub', accountModifier, 'year'), 0)),
                        ref(
                          or(account('$ib', accountModifier, 'previousYear'), 0)
                        )
                      )
                      .addSubRows((rows) => {
                        accountRangeRows(
                          accounts,
                          1480,
                          1489,
                          rows,
                          false,
                          'year',
                          'previousYear',
                          accountModifier
                        );

                        return rows.build();
                      });
                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'VarulagerMm',
                    'sum',
                    value('Summa varulager m.m.'),
                    undefined,
                    ixbrlCell(
                      ref(
                        sumAllowNull(
                          id(
                            'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.VarulagerMmAbstract.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        name: 'se-gen-base:VarulagerMm',
                        contextRef: 'balans0',
                        standardRubrik: 'Varulager m.m.',
                        saldo: 'debit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sumAllowNull(
                          id(
                            'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.VarulagerMmAbstract.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        name: 'se-gen-base:VarulagerMm',
                        contextRef: 'balans1',
                        standardRubrik: 'Varulager m.m.',
                        saldo: 'debit',
                        negateValue: false,
                      }
                    )
                  )
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(or(account('$ub', accountModifier, 'year'), 0)),
                    ref(or(account('$ib', accountModifier, 'previousYear'), 0))
                  )
                  .addSubRows((rows) => {
                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'KortfristigaFordringarAbstract',
                    'header',
                    value('Kortfristiga fordringar'),
                    refs(),
                    undefined,
                    undefined
                  )
                  .addSubRows((rows) => {
                    rows
                      .addRowWithType(
                        'Kundfordringar',
                        'row',
                        value('Kundfordringar'),
                        refs(),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaFordringarAbstract.Kundfordringar.*.year'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:Kundfordringar',
                            contextRef: 'balans0',
                            standardRubrik: 'Kundfordringar',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        ),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaFordringarAbstract.Kundfordringar.*.previousYear'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:Kundfordringar',
                            contextRef: 'balans1',
                            standardRubrik: 'Kundfordringar',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        )
                      ) // <<< end row_empty_value ---
                      .newRowTemplateWithType(
                        'account',
                        label('$label'),
                        undefined,
                        ref(or(account('$ub', accountModifier, 'year'), 0)),
                        ref(
                          or(account('$ib', accountModifier, 'previousYear'), 0)
                        )
                      )
                      .addSubRows((rows) => {
                        accountRangeRows(
                          accounts,
                          1500,
                          1599,
                          rows,
                          false,
                          'year',
                          'previousYear',
                          accountModifier
                        );

                        return rows.build();
                      });
                    rows
                      .addRowWithType(
                        'FordringarKoncernforetagKortfristiga',
                        'row',
                        value('Fordringar hos koncernföretag'),
                        refs(),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaFordringarAbstract.FordringarKoncernforetagKortfristiga.*.year'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:FordringarKoncernforetagKortfristiga',
                            contextRef: 'balans0',
                            standardRubrik:
                              'Kortfristiga fordringar hos koncernföretag',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        ),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaFordringarAbstract.FordringarKoncernforetagKortfristiga.*.previousYear'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:FordringarKoncernforetagKortfristiga',
                            contextRef: 'balans1',
                            standardRubrik:
                              'Kortfristiga fordringar hos koncernföretag',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        )
                      ) // <<< end row_empty_value ---
                      .newRowTemplateWithType(
                        'account',
                        label('$label'),
                        undefined,
                        ref(or(account('$ub', accountModifier, 'year'), 0)),
                        ref(
                          or(account('$ib', accountModifier, 'previousYear'), 0)
                        )
                      )
                      .addSubRows((rows) => {
                        accountRangeRows(
                          accounts,
                          1660,
                          1669,
                          rows,
                          false,
                          'year',
                          'previousYear',
                          accountModifier
                        );

                        return rows.build();
                      });
                    rows
                      .addRowWithType(
                        'FordringarIntresseforetagGemensamtStyrdaForetagKortfristiga',
                        'row',
                        value(
                          'Fordringar hos intresseföretag och gemensamt styrda företag'
                        ),
                        refs(),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaFordringarAbstract.FordringarIntresseforetagGemensamtStyrdaForetagKortfristiga.*.year'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:FordringarIntresseforetagGemensamtStyrdaForetagKortfristiga',
                            contextRef: 'balans0',
                            standardRubrik:
                              'Kortfristiga fordringar hos intresseföretag och gemensamt styrda företag',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        ),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaFordringarAbstract.FordringarIntresseforetagGemensamtStyrdaForetagKortfristiga.*.previousYear'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:FordringarIntresseforetagGemensamtStyrdaForetagKortfristiga',
                            contextRef: 'balans1',
                            standardRubrik:
                              'Kortfristiga fordringar hos intresseföretag och gemensamt styrda företag',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        )
                      ) // <<< end row_empty_value ---
                      .newRowTemplateWithType(
                        'account',
                        label('$label'),
                        undefined,
                        ref(or(account('$ub', accountModifier, 'year'), 0)),
                        ref(
                          or(account('$ib', accountModifier, 'previousYear'), 0)
                        )
                      )
                      .addSubRows((rows) => {
                        accountRangeRows(
                          accounts,
                          1670,
                          1672,
                          rows,
                          false,
                          'year',
                          'previousYear',
                          accountModifier
                        );

                        return rows.build();
                      });
                    rows
                      .addRowWithType(
                        'FordringarOvrigaforetagAgarintresseKortfristiga',
                        'row',
                        value(
                          'Fordringar hos övriga företag som det finns ett ägarintresse i'
                        ),
                        refs(),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaFordringarAbstract.FordringarOvrigaforetagAgarintresseKortfristiga.*.year'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:FordringarOvrigaforetagAgarintresseKortfristiga',
                            contextRef: 'balans0',
                            standardRubrik:
                              'Kortfristiga fordringar hos övriga företag som det finns ett ägarintresse i',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        ),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaFordringarAbstract.FordringarOvrigaforetagAgarintresseKortfristiga.*.previousYear'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:FordringarOvrigaforetagAgarintresseKortfristiga',
                            contextRef: 'balans1',
                            standardRubrik:
                              'Kortfristiga fordringar hos övriga företag som det finns ett ägarintresse i',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        )
                      ) // <<< end row_empty_value ---
                      .newRowTemplateWithType(
                        'account',
                        label('$label'),
                        undefined,
                        ref(or(account('$ub', accountModifier, 'year'), 0)),
                        ref(
                          or(account('$ib', accountModifier, 'previousYear'), 0)
                        )
                      )
                      .addSubRows((rows) => {
                        accountRangeRows(
                          accounts,
                          1673,
                          1679,
                          rows,
                          false,
                          'year',
                          'previousYear',
                          accountModifier
                        );

                        return rows.build();
                      });
                    rows
                      .addRowWithType(
                        'AktuellSkattefodran',
                        'row',
                        value('Aktuell skattefordran'),
                        refs(),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaFordringarAbstract.AktuellSkattefodran.*.year'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:AktuellSkattefodran',
                            contextRef: 'balans0',
                            standardRubrik: 'Aktuell skattefordran',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        ),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaFordringarAbstract.AktuellSkattefodran.*.previousYear'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:AktuellSkattefodran',
                            contextRef: 'balans1',
                            standardRubrik: 'Aktuell skattefordran',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        )
                      ) // <<< end row_empty_value ---
                      .newRowTemplateWithType(
                        'account',
                        label('$label'),
                        undefined,
                        ref(or(account('$ub', accountModifier, 'year'), 0)),
                        ref(
                          or(account('$ib', accountModifier, 'previousYear'), 0)
                        )
                      )
                      .addSubRows((rows) => {
                        accountRangeRows(
                          accounts,
                          1640,
                          1649,
                          rows,
                          false,
                          'year',
                          'previousYear',
                          accountModifier
                        );

                        return rows.build();
                      });
                    rows
                      .addRowWithType(
                        'OvrigaFordringarKortfristiga',
                        'row',
                        value('Övriga fordringar'),
                        refs(),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaFordringarAbstract.OvrigaFordringarKortfristiga.*.year'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:OvrigaFordringarKortfristiga',
                            contextRef: 'balans0',
                            standardRubrik: 'Övriga kortfristiga fordringar',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        ),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaFordringarAbstract.OvrigaFordringarKortfristiga.*.previousYear'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:OvrigaFordringarKortfristiga',
                            contextRef: 'balans1',
                            standardRubrik: 'Övriga kortfristiga fordringar',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        )
                      ) // <<< end row_empty_value ---
                      .newRowTemplateWithType(
                        'account',
                        label('$label'),
                        undefined,
                        ref(or(account('$ub', accountModifier, 'year'), 0)),
                        ref(
                          or(account('$ib', accountModifier, 'previousYear'), 0)
                        )
                      )
                      .addSubRows((rows) => {
                        accountRangeRows(
                          accounts,
                          1600,
                          1619,
                          rows,
                          false,
                          'year',
                          'previousYear',
                          accountModifier
                        );
                        accountRangeRows(
                          accounts,
                          1630,
                          1639,
                          rows,
                          false,
                          'year',
                          'previousYear',
                          accountModifier
                        );
                        accountRangeRows(
                          accounts,
                          1650,
                          1659,
                          rows,
                          false,
                          'year',
                          'previousYear',
                          accountModifier
                        );
                        accountRangeRows(
                          accounts,
                          1680,
                          1689,
                          rows,
                          false,
                          'year',
                          'previousYear',
                          accountModifier
                        );

                        return rows.build();
                      });
                    rows
                      .addRowWithType(
                        'UpparbetadEjFaktureradIntakt',
                        'row',
                        value('Upparbetad men ej fakturerad intäkt'),
                        refs(),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaFordringarAbstract.UpparbetadEjFaktureradIntakt.*.year'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:UpparbetadEjFaktureradIntakt',
                            contextRef: 'balans0',
                            standardRubrik:
                              'Upparbetad men ej fakturerad intäkt',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        ),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaFordringarAbstract.UpparbetadEjFaktureradIntakt.*.previousYear'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:UpparbetadEjFaktureradIntakt',
                            contextRef: 'balans1',
                            standardRubrik:
                              'Upparbetad men ej fakturerad intäkt',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        )
                      ) // <<< end row_empty_value ---
                      .newRowTemplateWithType(
                        'account',
                        label('$label'),
                        undefined,
                        ref(or(account('$ub', accountModifier, 'year'), 0)),
                        ref(
                          or(account('$ib', accountModifier, 'previousYear'), 0)
                        )
                      )
                      .addSubRows((rows) => {
                        accountRangeRows(
                          accounts,
                          1620,
                          1629,
                          rows,
                          false,
                          'year',
                          'previousYear',
                          accountModifier
                        );

                        return rows.build();
                      });
                    rows
                      .addRowWithType(
                        'ForutbetaldaKostnaderUpplupnaIntakter',
                        'row',
                        value('Förutbetalda kostnader och upplupna intäkter'),
                        refs(),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaFordringarAbstract.ForutbetaldaKostnaderUpplupnaIntakter.*.year'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:ForutbetaldaKostnaderUpplupnaIntakter',
                            contextRef: 'balans0',
                            standardRubrik:
                              'Förutbetalda kostnader och upplupna intäkter',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        ),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaFordringarAbstract.ForutbetaldaKostnaderUpplupnaIntakter.*.previousYear'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:ForutbetaldaKostnaderUpplupnaIntakter',
                            contextRef: 'balans1',
                            standardRubrik:
                              'Förutbetalda kostnader och upplupna intäkter',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        )
                      ) // <<< end row_empty_value ---
                      .newRowTemplateWithType(
                        'account',
                        label('$label'),
                        undefined,
                        ref(or(account('$ub', accountModifier, 'year'), 0)),
                        ref(
                          or(account('$ib', accountModifier, 'previousYear'), 0)
                        )
                      )
                      .addSubRows((rows) => {
                        accountRangeRows(
                          accounts,
                          1700,
                          1799,
                          rows,
                          false,
                          'year',
                          'previousYear',
                          accountModifier
                        );

                        return rows.build();
                      });
                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'KortfristigaFordringar',
                    'sum',
                    value('Summa kortfristiga fordringar'),
                    undefined,
                    ixbrlCell(
                      ref(
                        sumAllowNull(
                          id(
                            'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaFordringarAbstract.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        name: 'se-gen-base:KortfristigaFordringar',
                        contextRef: 'balans0',
                        standardRubrik: 'Kortfristiga fordringar',
                        saldo: 'debit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sumAllowNull(
                          id(
                            'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaFordringarAbstract.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        name: 'se-gen-base:KortfristigaFordringar',
                        contextRef: 'balans1',
                        standardRubrik: 'Kortfristiga fordringar',
                        saldo: 'debit',
                        negateValue: false,
                      }
                    )
                  )
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(or(account('$ub', accountModifier, 'year'), 0)),
                    ref(or(account('$ib', accountModifier, 'previousYear'), 0))
                  )
                  .addSubRows((rows) => {
                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'KortfristigaPlaceringarAbstract',
                    'header',
                    value('Kortfristiga placeringar'),
                    refs(),
                    undefined,
                    undefined
                  )
                  .addSubRows((rows) => {
                    rows
                      .addRowWithType(
                        'AndelarKoncernforetagKortfristiga',
                        'row',
                        value('Andelar i koncernföretag'),
                        refs(),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaPlaceringarAbstract.AndelarKoncernforetagKortfristiga.*.year'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:AndelarKoncernforetagKortfristiga',
                            contextRef: 'balans0',
                            standardRubrik:
                              'Kortfristiga andelar i koncernföretag',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        ),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaPlaceringarAbstract.AndelarKoncernforetagKortfristiga.*.previousYear'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:AndelarKoncernforetagKortfristiga',
                            contextRef: 'balans1',
                            standardRubrik:
                              'Kortfristiga andelar i koncernföretag',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        )
                      ) // <<< end row_empty_value ---
                      .newRowTemplateWithType(
                        'account',
                        label('$label'),
                        undefined,
                        ref(or(account('$ub', accountModifier, 'year'), 0)),
                        ref(
                          or(account('$ib', accountModifier, 'previousYear'), 0)
                        )
                      )
                      .addSubRows((rows) => {
                        accountRangeRows(
                          accounts,
                          1860,
                          1869,
                          rows,
                          false,
                          'year',
                          'previousYear',
                          accountModifier
                        );

                        return rows.build();
                      });
                    rows
                      .addRowWithType(
                        'OvrigaKortfristigaPlaceringar',
                        'row',
                        value('Övriga kortfristiga placeringar'),
                        refs(),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaPlaceringarAbstract.OvrigaKortfristigaPlaceringar.*.year'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:OvrigaKortfristigaPlaceringar',
                            contextRef: 'balans0',
                            standardRubrik: 'Övriga kortfristiga placeringar',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        ),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaPlaceringarAbstract.OvrigaKortfristigaPlaceringar.*.previousYear'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:OvrigaKortfristigaPlaceringar',
                            contextRef: 'balans1',
                            standardRubrik: 'Övriga kortfristiga placeringar',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        )
                      ) // <<< end row_empty_value ---
                      .newRowTemplateWithType(
                        'account',
                        label('$label'),
                        undefined,
                        ref(or(account('$ub', accountModifier, 'year'), 0)),
                        ref(
                          or(account('$ib', accountModifier, 'previousYear'), 0)
                        )
                      )
                      .addSubRows((rows) => {
                        accountRangeRows(
                          accounts,
                          1800,
                          1859,
                          rows,
                          false,
                          'year',
                          'previousYear',
                          accountModifier
                        );
                        accountRangeRows(
                          accounts,
                          1870,
                          1899,
                          rows,
                          false,
                          'year',
                          'previousYear',
                          accountModifier
                        );

                        return rows.build();
                      });
                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'KortfristigaPlaceringar',
                    'sum',
                    value('Summa kortfristiga placeringar'),
                    undefined,
                    ixbrlCell(
                      ref(
                        sumAllowNull(
                          id(
                            'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaPlaceringarAbstract.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        name: 'se-gen-base:KortfristigaPlaceringar',
                        contextRef: 'balans0',
                        standardRubrik: 'Kortfristiga placeringar',
                        saldo: 'debit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sumAllowNull(
                          id(
                            'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaPlaceringarAbstract.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        name: 'se-gen-base:KortfristigaPlaceringar',
                        contextRef: 'balans1',
                        standardRubrik: 'Kortfristiga placeringar',
                        saldo: 'debit',
                        negateValue: false,
                      }
                    )
                  )
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(or(account('$ub', accountModifier, 'year'), 0)),
                    ref(or(account('$ib', accountModifier, 'previousYear'), 0))
                  )
                  .addSubRows((rows) => {
                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'KassaBankAbstract',
                    'header',
                    value('Kassa och bank'),
                    refs(),
                    undefined,
                    undefined
                  )
                  .addSubRows((rows) => {
                    rows
                      .addRowWithType(
                        'KassaBankExklRedovisningsmedel',
                        'row',
                        value('Kassa och bank'),
                        refs(),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KassaBankAbstract.KassaBankExklRedovisningsmedel.*.year'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:KassaBankExklRedovisningsmedel',
                            contextRef: 'balans0',
                            standardRubrik:
                              'Kassa och bank exklusive redovisningsmedel',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        ),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KassaBankAbstract.KassaBankExklRedovisningsmedel.*.previousYear'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:KassaBankExklRedovisningsmedel',
                            contextRef: 'balans1',
                            standardRubrik:
                              'Kassa och bank exklusive redovisningsmedel',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        )
                      ) // <<< end row_empty_value ---
                      .newRowTemplateWithType(
                        'account',
                        label('$label'),
                        undefined,
                        ref(or(account('$ub', accountModifier, 'year'), 0)),
                        ref(
                          or(account('$ib', accountModifier, 'previousYear'), 0)
                        )
                      )
                      .addSubRows((rows) => {
                        accountRangeRows(
                          accounts,
                          1900,
                          1989,
                          rows,
                          false,
                          'year',
                          'previousYear',
                          accountModifier
                        );

                        return rows.build();
                      });
                    rows
                      .addRowWithType(
                        'Redovisningsmedel',
                        'row',
                        value('Redovisningsmedel'),
                        refs(),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KassaBankAbstract.Redovisningsmedel.*.year'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:Redovisningsmedel',
                            contextRef: 'balans0',
                            standardRubrik: 'Redovisningsmedel',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        ),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KassaBankAbstract.Redovisningsmedel.*.previousYear'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:Redovisningsmedel',
                            contextRef: 'balans1',
                            standardRubrik: 'Redovisningsmedel',
                            saldo: 'debit',
                            negateValue: false,
                          }
                        )
                      ) // <<< end row_empty_value ---
                      .newRowTemplateWithType(
                        'account',
                        label('$label'),
                        undefined,
                        ref(or(account('$ub', accountModifier, 'year'), 0)),
                        ref(
                          or(account('$ib', accountModifier, 'previousYear'), 0)
                        )
                      )
                      .addSubRows((rows) => {
                        accountRangeRows(
                          accounts,
                          1990,
                          1999,
                          rows,
                          false,
                          'year',
                          'previousYear',
                          accountModifier
                        );

                        return rows.build();
                      });
                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'KassaBank',
                    'sum',
                    value('Summa kassa och bank'),
                    undefined,
                    ixbrlCell(
                      ref(
                        sumAllowNull(
                          id(
                            'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KassaBankAbstract.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        name: 'se-gen-base:KassaBank',
                        contextRef: 'balans0',
                        standardRubrik: 'Kassa och bank',
                        saldo: 'debit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sumAllowNull(
                          id(
                            'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KassaBankAbstract.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        name: 'se-gen-base:KassaBank',
                        contextRef: 'balans1',
                        standardRubrik: 'Kassa och bank',
                        saldo: 'debit',
                        negateValue: false,
                      }
                    )
                  )
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(or(account('$ub', accountModifier, 'year'), 0)),
                    ref(or(account('$ib', accountModifier, 'previousYear'), 0))
                  )
                  .addSubRows((rows) => {
                    return rows.build();
                  });
                return rows.build();
              });
            rows
              .addRowWithType(
                'Omsattningstillgangar',
                'sum',
                value('Summa omsättningstillgångar'),
                undefined,
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    name: 'se-gen-base:Omsattningstillgangar',
                    contextRef: 'balans0',
                    standardRubrik: 'Omsättningstillgångar',
                    saldo: 'debit',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    name: 'se-gen-base:Omsattningstillgangar',
                    contextRef: 'balans1',
                    standardRubrik: 'Omsättningstillgångar',
                    saldo: 'debit',
                    negateValue: false,
                  }
                )
              )
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(or(account('$ub', accountModifier, 'year'), 0)),
                ref(or(account('$ib', accountModifier, 'previousYear'), 0))
              )
              .addSubRows((rows) => {
                return rows.build();
              });
            return rows.build();
          });
        rows
          .addRowWithType(
            'Tillgangar',
            'alwaysDisplaySum',
            value('Summa tillgångar'),
            undefined,
            ixbrlCell(
              ref(
                sumAllowNull(
                  id('balanceSheet.section.assets.TillgangarAbstract.*.year')
                )
              ),
              {
                type: 'monetaryItemType',
                name: 'se-gen-base:Tillgangar',
                contextRef: 'balans0',
                standardRubrik: 'Tillgångar',
                saldo: 'debit',
                negateValue: false,
              }
            ),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'balanceSheet.section.assets.TillgangarAbstract.*.previousYear'
                  )
                )
              ),
              {
                type: 'monetaryItemType',
                name: 'se-gen-base:Tillgangar',
                contextRef: 'balans1',
                standardRubrik: 'Tillgångar',
                saldo: 'debit',
                negateValue: false,
              }
            )
          )
          .newRowTemplateWithType(
            'account',
            label('$label'),
            undefined,
            ref(or(account('$ub', accountModifier, 'year'), 0)),
            ref(or(account('$ib', accountModifier, 'previousYear'), 0))
          )
          .addSubRows((rows) => {
            return rows.build();
          });
        rows
          .addRowWithType(
            'accountsNotAssigned',
            'accountsNotAssigned',
            label('Ej tilldelade konton'),
            undefined,
            undefined,
            undefined
          ) // >>> begin `add_button` (NL 1) [Id `balanceSheet.section.assets`] ---
          .newRowTemplateWithType(
            'account',
            label('$label'),
            undefined,
            ref(or(account('$ub', accountModifier, 'year'), 0)),
            ref(or(account('$ib', accountModifier, 'previousYear'), 0))
          )
          .addSubRows((rows) => {
            accountRangeRows(
              accounts,
              1690,
              1699,
              rows,
              false,
              'year',
              'previousYear',
              accountModifier
            );

            return rows.build();
          });
        return rows.build();
      })
      .build(),
    equityAndLiabilities: table<IxbrlCell>(
      'balanceSheet.section.equityAndLiabilities',
      { id: 'label', dataType: 'text' },
      { id: 'notes', dataType: 'numeric' },
      { id: 'year', label: `${period.endDateISO}`, dataType: 'numeric' },
      previousPeriod
        ? {
            id: 'previousYear',
            label: `${previousPeriod.endDateISO}`,
            dataType: 'numeric',
          }
        : undefined
    )
      .addRows((rows) => {
        rows
          .addRowWithType(
            'EgetKapitalSkulderAbstract',
            'header',
            value('Eget kapital och skulder'),
            refs(),
            undefined,
            undefined
          )
          .addSubRows((rows) => {
            rows
              .addRowWithType(
                'EgetKapitalAbstract',
                'header',
                value('Eget kapital'),
                refs(),
                undefined,
                undefined
              )
              .addSubRows((rows) => {
                rows
                  .addRowWithType(
                    'BundetEgetKapitalAbstract',
                    'header',
                    value('Bundet eget kapital'),
                    refs(),
                    undefined,
                    undefined
                  )
                  .addSubRows((rows) => {
                    rows
                      .addRowWithType(
                        'Aktiekapital',
                        'row',
                        value('Aktiekapital'),
                        refs(),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.EgetKapitalAbstract.BundetEgetKapitalAbstract.Aktiekapital.*.year'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:Aktiekapital',
                            contextRef: 'balans0',
                            standardRubrik: 'Aktiekapital',
                            saldo: 'credit',
                            negateValue: false,
                          }
                        ),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.EgetKapitalAbstract.BundetEgetKapitalAbstract.Aktiekapital.*.previousYear'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:Aktiekapital',
                            contextRef: 'balans1',
                            standardRubrik: 'Aktiekapital',
                            saldo: 'credit',
                            negateValue: false,
                          }
                        )
                      ) // <<< end row_empty_value ---
                      .newRowTemplateWithType(
                        'account',
                        label('$label'),
                        undefined,
                        ref(
                          multiply(
                            -1,
                            or(account('$ub', accountModifier, 'year'), 0)
                          )
                        ),
                        ref(
                          multiply(
                            -1,
                            or(
                              account('$ib', accountModifier, 'previousYear'),
                              0
                            )
                          )
                        )
                      )
                      .addSubRows((rows) => {
                        accountRangeRows(
                          accounts,
                          2000,
                          2081,
                          rows,
                          true,
                          'year',
                          'previousYear',
                          accountModifier
                        );

                        return rows.build();
                      });
                    rows
                      .addRowWithType(
                        'EjRegistreratAktiekapital',
                        'row',
                        value('Pågående ny- och fondemission'),
                        refs(),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.EgetKapitalAbstract.BundetEgetKapitalAbstract.EjRegistreratAktiekapital.*.year'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:EjRegistreratAktiekapital',
                            contextRef: 'balans0',
                            standardRubrik:
                              'Ej registrerat aktiekapital vid pågående ny- och fondemission',
                            saldo: 'credit',
                            negateValue: false,
                          }
                        ),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.EgetKapitalAbstract.BundetEgetKapitalAbstract.EjRegistreratAktiekapital.*.previousYear'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:EjRegistreratAktiekapital',
                            contextRef: 'balans1',
                            standardRubrik:
                              'Ej registrerat aktiekapital vid pågående ny- och fondemission',
                            saldo: 'credit',
                            negateValue: false,
                          }
                        )
                      ) // <<< end row_empty_value ---
                      .newRowTemplateWithType(
                        'account',
                        label('$label'),
                        undefined,
                        ref(
                          multiply(
                            -1,
                            or(account('$ub', accountModifier, 'year'), 0)
                          )
                        ),
                        ref(
                          multiply(
                            -1,
                            or(
                              account('$ib', accountModifier, 'previousYear'),
                              0
                            )
                          )
                        )
                      )
                      .addSubRows((rows) => {
                        accountRangeRows(
                          accounts,
                          2082,
                          2084,
                          rows,
                          true,
                          'year',
                          'previousYear',
                          accountModifier
                        );

                        return rows.build();
                      });
                    rows
                      .addRowWithType(
                        'OverkursfondBunden',
                        'row',
                        value('Bunden överkursfond'),
                        refs(),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.EgetKapitalAbstract.BundetEgetKapitalAbstract.OverkursfondBunden.*.year'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:OverkursfondBunden',
                            contextRef: 'balans0',
                            standardRubrik: 'Bunden överkursfond',
                            saldo: 'credit',
                            negateValue: false,
                          }
                        ),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.EgetKapitalAbstract.BundetEgetKapitalAbstract.OverkursfondBunden.*.previousYear'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:OverkursfondBunden',
                            contextRef: 'balans1',
                            standardRubrik: 'Bunden överkursfond',
                            saldo: 'credit',
                            negateValue: false,
                          }
                        )
                      ) // <<< end row_empty_value ---
                      .newRowTemplateWithType(
                        'account',
                        label('$label'),
                        undefined,
                        ref(
                          multiply(
                            -1,
                            or(account('$ub', accountModifier, 'year'), 0)
                          )
                        ),
                        ref(
                          multiply(
                            -1,
                            or(
                              account('$ib', accountModifier, 'previousYear'),
                              0
                            )
                          )
                        )
                      )
                      .addSubRows((rows) => {
                        accountRangeRows(
                          accounts,
                          2087,
                          2088,
                          rows,
                          true,
                          'year',
                          'previousYear',
                          accountModifier
                        );

                        return rows.build();
                      });
                    rows
                      .addRowWithType(
                        'Uppskrivningsfond',
                        'row',
                        value('Uppskrivningsfond'),
                        refs(),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.EgetKapitalAbstract.BundetEgetKapitalAbstract.Uppskrivningsfond.*.year'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:Uppskrivningsfond',
                            contextRef: 'balans0',
                            standardRubrik: 'Uppskrivningsfond',
                            saldo: 'credit',
                            negateValue: false,
                          }
                        ),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.EgetKapitalAbstract.BundetEgetKapitalAbstract.Uppskrivningsfond.*.previousYear'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:Uppskrivningsfond',
                            contextRef: 'balans1',
                            standardRubrik: 'Uppskrivningsfond',
                            saldo: 'credit',
                            negateValue: false,
                          }
                        )
                      ) // <<< end row_empty_value ---
                      .newRowTemplateWithType(
                        'account',
                        label('$label'),
                        undefined,
                        ref(
                          multiply(
                            -1,
                            or(account('$ub', accountModifier, 'year'), 0)
                          )
                        ),
                        ref(
                          multiply(
                            -1,
                            or(
                              account('$ib', accountModifier, 'previousYear'),
                              0
                            )
                          )
                        )
                      )
                      .addSubRows((rows) => {
                        accountRangeRows(
                          accounts,
                          2085,
                          2085,
                          rows,
                          true,
                          'year',
                          'previousYear',
                          accountModifier
                        );

                        return rows.build();
                      });
                    rows
                      .addRowWithType(
                        'Reservfond',
                        'row',
                        value('Reservfond'),
                        refs(),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.EgetKapitalAbstract.BundetEgetKapitalAbstract.Reservfond.*.year'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:Reservfond',
                            contextRef: 'balans0',
                            standardRubrik: 'Reservfond',
                            saldo: 'credit',
                            negateValue: false,
                          }
                        ),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.EgetKapitalAbstract.BundetEgetKapitalAbstract.Reservfond.*.previousYear'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:Reservfond',
                            contextRef: 'balans1',
                            standardRubrik: 'Reservfond',
                            saldo: 'credit',
                            negateValue: false,
                          }
                        )
                      ) // <<< end row_empty_value ---
                      .newRowTemplateWithType(
                        'account',
                        label('$label'),
                        undefined,
                        ref(
                          multiply(
                            -1,
                            or(account('$ub', accountModifier, 'year'), 0)
                          )
                        ),
                        ref(
                          multiply(
                            -1,
                            or(
                              account('$ib', accountModifier, 'previousYear'),
                              0
                            )
                          )
                        )
                      )
                      .addSubRows((rows) => {
                        accountRangeRows(
                          accounts,
                          2086,
                          2086,
                          rows,
                          true,
                          'year',
                          'previousYear',
                          accountModifier
                        );

                        return rows.build();
                      });
                    rows
                      .addRowWithType(
                        'Kapitalandelsfond',
                        'row',
                        value('Kapitalandelsfond'),
                        refs(),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.EgetKapitalAbstract.BundetEgetKapitalAbstract.Kapitalandelsfond.*.year'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:Kapitalandelsfond',
                            contextRef: 'balans0',
                            standardRubrik: 'Kapitalandelsfond',
                            saldo: 'credit',
                            negateValue: false,
                          }
                        ),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.EgetKapitalAbstract.BundetEgetKapitalAbstract.Kapitalandelsfond.*.previousYear'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:Kapitalandelsfond',
                            contextRef: 'balans1',
                            standardRubrik: 'Kapitalandelsfond',
                            saldo: 'credit',
                            negateValue: false,
                          }
                        )
                      ) // <<< end row_empty_value ---
                      .newRowTemplateWithType(
                        'account',
                        label('$label'),
                        undefined,
                        ref(
                          multiply(
                            -1,
                            or(account('$ub', accountModifier, 'year'), 0)
                          )
                        ),
                        ref(
                          multiply(
                            -1,
                            or(
                              account('$ib', accountModifier, 'previousYear'),
                              0
                            )
                          )
                        )
                      )
                      .addSubRows((rows) => {
                        return rows.build();
                      });
                    rows
                      .addRowWithType(
                        'FondUtvecklingsutgifter',
                        'row',
                        value('Fond för utvecklingsutgifter'),
                        refs(),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.EgetKapitalAbstract.BundetEgetKapitalAbstract.FondUtvecklingsutgifter.*.year'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:FondUtvecklingsutgifter',
                            contextRef: 'balans0',
                            standardRubrik: 'Fond för utvecklingsutgifter',
                            saldo: 'credit',
                            negateValue: false,
                          }
                        ),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.EgetKapitalAbstract.BundetEgetKapitalAbstract.FondUtvecklingsutgifter.*.previousYear'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:FondUtvecklingsutgifter',
                            contextRef: 'balans1',
                            standardRubrik: 'Fond för utvecklingsutgifter',
                            saldo: 'credit',
                            negateValue: false,
                          }
                        )
                      ) // <<< end row_empty_value ---
                      .newRowTemplateWithType(
                        'account',
                        label('$label'),
                        undefined,
                        ref(
                          multiply(
                            -1,
                            or(account('$ub', accountModifier, 'year'), 0)
                          )
                        ),
                        ref(
                          multiply(
                            -1,
                            or(
                              account('$ib', accountModifier, 'previousYear'),
                              0
                            )
                          )
                        )
                      )
                      .addSubRows((rows) => {
                        accountRangeRows(
                          accounts,
                          2089,
                          2089,
                          rows,
                          true,
                          'year',
                          'previousYear',
                          accountModifier
                        );

                        return rows.build();
                      });
                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'BundetEgetKapital',
                    'sum',
                    value('Summa bundet eget kapital'),
                    undefined,
                    ixbrlCell(
                      ref(
                        sumAllowNull(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.EgetKapitalAbstract.BundetEgetKapitalAbstract.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        name: 'se-gen-base:BundetEgetKapital',
                        contextRef: 'balans0',
                        standardRubrik: 'Bundet eget kapital',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sumAllowNull(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.EgetKapitalAbstract.BundetEgetKapitalAbstract.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        name: 'se-gen-base:BundetEgetKapital',
                        contextRef: 'balans1',
                        standardRubrik: 'Bundet eget kapital',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    )
                  )
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(or(account('$ub', accountModifier, 'year'), 0)),
                    ref(or(account('$ib', accountModifier, 'previousYear'), 0))
                  )
                  .addSubRows((rows) => {
                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'FrittEgetKapitalAbstract',
                    'header',
                    value('Fritt eget kapital'),
                    refs(),
                    undefined,
                    undefined
                  )
                  .addSubRows((rows) => {
                    rows
                      .addRowWithType(
                        'Overkursfond',
                        'row',
                        value('Fri överkursfond'),
                        refs(),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.EgetKapitalAbstract.FrittEgetKapitalAbstract.Overkursfond.*.year'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:Overkursfond',
                            contextRef: 'balans0',
                            standardRubrik: 'Fri överkursfond',
                            saldo: 'credit',
                            negateValue: false,
                          }
                        ),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.EgetKapitalAbstract.FrittEgetKapitalAbstract.Overkursfond.*.previousYear'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:Overkursfond',
                            contextRef: 'balans1',
                            standardRubrik: 'Fri överkursfond',
                            saldo: 'credit',
                            negateValue: false,
                          }
                        )
                      ) // <<< end row_empty_value ---
                      .newRowTemplateWithType(
                        'account',
                        label('$label'),
                        undefined,
                        ref(
                          multiply(
                            -1,
                            or(account('$ub', accountModifier, 'year'), 0)
                          )
                        ),
                        ref(
                          multiply(
                            -1,
                            or(
                              account('$ib', accountModifier, 'previousYear'),
                              0
                            )
                          )
                        )
                      )
                      .addSubRows((rows) => {
                        accountRangeRows(
                          accounts,
                          2097,
                          2097,
                          rows,
                          true,
                          'year',
                          'previousYear',
                          accountModifier
                        );

                        return rows.build();
                      });
                    rows
                      .addRowWithType(
                        'FondVerkligtVarde',
                        'row',
                        value('Fond för verkligt värde'),
                        refs(),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.EgetKapitalAbstract.FrittEgetKapitalAbstract.FondVerkligtVarde.*.year'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:FondVerkligtVarde',
                            contextRef: 'balans0',
                            standardRubrik: 'Fond för verkligt värde',
                            saldo: 'credit',
                            negateValue: false,
                          }
                        ),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.EgetKapitalAbstract.FrittEgetKapitalAbstract.FondVerkligtVarde.*.previousYear'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:FondVerkligtVarde',
                            contextRef: 'balans1',
                            standardRubrik: 'Fond för verkligt värde',
                            saldo: 'credit',
                            negateValue: false,
                          }
                        )
                      ) // <<< end row_empty_value ---
                      .newRowTemplateWithType(
                        'account',
                        label('$label'),
                        undefined,
                        ref(
                          multiply(
                            -1,
                            or(account('$ub', accountModifier, 'year'), 0)
                          )
                        ),
                        ref(
                          multiply(
                            -1,
                            or(
                              account('$ib', accountModifier, 'previousYear'),
                              0
                            )
                          )
                        )
                      )
                      .addSubRows((rows) => {
                        accountRangeRows(
                          accounts,
                          2096,
                          2096,
                          rows,
                          true,
                          'year',
                          'previousYear',
                          accountModifier
                        );

                        return rows.build();
                      });
                    rows
                      .addRowWithType(
                        'BalanseratResultat',
                        'row',
                        value('Balanserat resultat'),
                        refs(),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.EgetKapitalAbstract.FrittEgetKapitalAbstract.BalanseratResultat.*.year'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:BalanseratResultat',
                            contextRef: 'balans0',
                            standardRubrik: 'Balanserat resultat',
                            saldo: 'credit',
                            negateValue: false,
                          }
                        ),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.EgetKapitalAbstract.FrittEgetKapitalAbstract.BalanseratResultat.*.previousYear'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:BalanseratResultat',
                            contextRef: 'balans1',
                            standardRubrik: 'Balanserat resultat',
                            saldo: 'credit',
                            negateValue: false,
                          }
                        )
                      ) // <<< end row_empty_value ---
                      .newRowTemplateWithType(
                        'account',
                        label('$label'),
                        undefined,
                        ref(
                          multiply(
                            -1,
                            or(account('$ub', accountModifier, 'year'), 0)
                          )
                        ),
                        ref(
                          multiply(
                            -1,
                            or(
                              account('$ib', accountModifier, 'previousYear'),
                              0
                            )
                          )
                        )
                      )
                      .addSubRows((rows) => {
                        accountRangeRows(
                          accounts,
                          2090,
                          2095,
                          rows,
                          true,
                          'year',
                          'previousYear',
                          accountModifier
                        );
                        accountRangeRows(
                          accounts,
                          2098,
                          2098,
                          rows,
                          true,
                          'year',
                          'previousYear',
                          accountModifier
                        );

                        return rows.build();
                      });
                    rows
                      .addRowWithType(
                        'AretsResultatEgetKapital',
                        'row',
                        value('Årets resultat'),
                        refs(),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.EgetKapitalAbstract.FrittEgetKapitalAbstract.AretsResultatEgetKapital.*.year'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:AretsResultatEgetKapital',
                            contextRef: 'balans0',
                            standardRubrik: 'Periodens resultat i eget kapital',
                            saldo: 'credit',
                            negateValue: false,
                          }
                        ),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.EgetKapitalAbstract.FrittEgetKapitalAbstract.AretsResultatEgetKapital.*.previousYear'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:AretsResultatEgetKapital',
                            contextRef: 'balans1',
                            standardRubrik: 'Periodens resultat i eget kapital',
                            saldo: 'credit',
                            negateValue: false,
                          }
                        )
                      ) // <<< end row_empty_value ---
                      .newRowTemplateWithType(
                        'account',
                        label('$label'),
                        undefined,
                        ref(
                          multiply(
                            -1,
                            or(account('$ub', accountModifier, 'year'), 0)
                          )
                        ),
                        ref(
                          multiply(
                            -1,
                            or(
                              account('$ib', accountModifier, 'previousYear'),
                              0
                            )
                          )
                        )
                      )
                      .addSubRows((rows) => {
                        accountRangeRows(
                          accounts,
                          2099,
                          2099,
                          rows,
                          true,
                          'year',
                          'previousYear',
                          accountModifier
                        );

                        return rows.build();
                      });
                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'FrittEgetKapital',
                    'sum',
                    value('Summa fritt eget kapital'),
                    undefined,
                    ixbrlCell(
                      ref(
                        sumAllowNull(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.EgetKapitalAbstract.FrittEgetKapitalAbstract.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        name: 'se-gen-base:FrittEgetKapital',
                        contextRef: 'balans0',
                        standardRubrik: 'Fritt eget kapital',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sumAllowNull(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.EgetKapitalAbstract.FrittEgetKapitalAbstract.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        name: 'se-gen-base:FrittEgetKapital',
                        contextRef: 'balans1',
                        standardRubrik: 'Fritt eget kapital',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    )
                  )
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(or(account('$ub', accountModifier, 'year'), 0)),
                    ref(or(account('$ib', accountModifier, 'previousYear'), 0))
                  )
                  .addSubRows((rows) => {
                    return rows.build();
                  });
                return rows.build();
              });
            rows
              .addRowWithType(
                'EgetKapital',
                'alwaysDisplaySum',
                value('Summa eget kapital'),
                undefined,
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.EgetKapitalAbstract.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    name: 'se-gen-base:EgetKapital',
                    contextRef: 'balans0',
                    standardRubrik: 'Eget kapital',
                    saldo: 'credit',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.EgetKapitalAbstract.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    name: 'se-gen-base:EgetKapital',
                    contextRef: 'balans1',
                    standardRubrik: 'Eget kapital',
                    saldo: 'credit',
                    negateValue: false,
                  }
                )
              )
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(or(account('$ub', accountModifier, 'year'), 0)),
                ref(or(account('$ib', accountModifier, 'previousYear'), 0))
              )
              .addSubRows((rows) => {
                return rows.build();
              });
            rows
              .addRowWithType(
                'ObeskattadeReserverAbstract',
                'header',
                value('Obeskattade reserver'),
                refs(),
                undefined,
                undefined
              )
              .addSubRows((rows) => {
                rows
                  .addRowWithType(
                    'Periodiseringsfonder',
                    'row',
                    value('Periodiseringsfonder'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.ObeskattadeReserverAbstract.Periodiseringsfonder.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:Periodiseringsfonder',
                        contextRef: 'balans0',
                        standardRubrik: 'Periodiseringsfonder',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.ObeskattadeReserverAbstract.Periodiseringsfonder.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:Periodiseringsfonder',
                        contextRef: 'balans1',
                        standardRubrik: 'Periodiseringsfonder',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    accountRangeRows(
                      accounts,
                      2100,
                      2149,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );

                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'AckumuleradeOveravskrivningar',
                    'row',
                    value('Ackumulerade överavskrivningar'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.ObeskattadeReserverAbstract.AckumuleradeOveravskrivningar.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:AckumuleradeOveravskrivningar',
                        contextRef: 'balans0',
                        standardRubrik: 'Ackumulerade överavskrivningar',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.ObeskattadeReserverAbstract.AckumuleradeOveravskrivningar.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:AckumuleradeOveravskrivningar',
                        contextRef: 'balans1',
                        standardRubrik: 'Ackumulerade överavskrivningar',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    accountRangeRows(
                      accounts,
                      2150,
                      2159,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );

                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'Ersattningsfonder',
                    'row',
                    value('Ersättningsfonder'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.ObeskattadeReserverAbstract.Ersattningsfonder.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:Ersattningsfonder',
                        contextRef: 'balans0',
                        standardRubrik: 'Ersättningsfonder',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.ObeskattadeReserverAbstract.Ersattningsfonder.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:Ersattningsfonder',
                        contextRef: 'balans1',
                        standardRubrik: 'Ersättningsfonder',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    accountRangeRows(
                      accounts,
                      2160,
                      2169,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );

                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'AndraObeskattadeReserver',
                    'row',
                    value('Andra obeskattade reserver'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.ObeskattadeReserverAbstract.AndraObeskattadeReserver.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:AndraObeskattadeReserver',
                        contextRef: 'balans0',
                        standardRubrik: 'Andra obeskattade reserver',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.ObeskattadeReserverAbstract.AndraObeskattadeReserver.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:AndraObeskattadeReserver',
                        contextRef: 'balans1',
                        standardRubrik: 'Andra obeskattade reserver',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    accountRangeRows(
                      accounts,
                      2170,
                      2199,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );

                    return rows.build();
                  });
                return rows.build();
              });
            rows
              .addRowWithType(
                'ObeskattadeReserver',
                'sum',
                value('Summa obeskattade reserver'),
                undefined,
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.ObeskattadeReserverAbstract.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    name: 'se-gen-base:ObeskattadeReserver',
                    contextRef: 'balans0',
                    standardRubrik: 'Obeskattade reserver',
                    saldo: 'credit',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.ObeskattadeReserverAbstract.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    name: 'se-gen-base:ObeskattadeReserver',
                    contextRef: 'balans1',
                    standardRubrik: 'Obeskattade reserver',
                    saldo: 'credit',
                    negateValue: false,
                  }
                )
              )
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(or(account('$ub', accountModifier, 'year'), 0)),
                ref(or(account('$ib', accountModifier, 'previousYear'), 0))
              )
              .addSubRows((rows) => {
                return rows.build();
              });
            rows
              .addRowWithType(
                'AvsattningarAbstract',
                'header',
                value('Avsättningar'),
                refs(),
                undefined,
                undefined
              )
              .addSubRows((rows) => {
                rows
                  .addRowWithType(
                    'AvsattningarPensionerLiknandeForpliktelserEnligtLag',
                    'row',
                    value(
                      'Avsättningar för pensioner och liknande förpliktelser enligt tryggandelagen'
                    ),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.AvsattningarAbstract.AvsattningarPensionerLiknandeForpliktelserEnligtLag.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:AvsattningarPensionerLiknandeForpliktelserEnligtLag',
                        contextRef: 'balans0',
                        standardRubrik:
                          'Avsättningar för pensioner och liknande förpliktelser enligt lagen om tryggande av pensionsutfästelse m.m.',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.AvsattningarAbstract.AvsattningarPensionerLiknandeForpliktelserEnligtLag.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:AvsattningarPensionerLiknandeForpliktelserEnligtLag',
                        contextRef: 'balans1',
                        standardRubrik:
                          'Avsättningar för pensioner och liknande förpliktelser enligt lagen om tryggande av pensionsutfästelse m.m.',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    accountRangeRows(
                      accounts,
                      2200,
                      2229,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );

                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'OvrigaAvsattningarPensionerLiknandeForpliktelser',
                    'row',
                    value(
                      'Övriga avsättningar för pensioner och liknande förpliktelser'
                    ),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.AvsattningarAbstract.OvrigaAvsattningarPensionerLiknandeForpliktelser.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:OvrigaAvsattningarPensionerLiknandeForpliktelser',
                        contextRef: 'balans0',
                        standardRubrik:
                          'Övriga avsättningar för pensioner och liknande förpliktelser exklusive avsättningar enligt tryggandelagen',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.AvsattningarAbstract.OvrigaAvsattningarPensionerLiknandeForpliktelser.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:OvrigaAvsattningarPensionerLiknandeForpliktelser',
                        contextRef: 'balans1',
                        standardRubrik:
                          'Övriga avsättningar för pensioner och liknande förpliktelser exklusive avsättningar enligt tryggandelagen',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    accountRangeRows(
                      accounts,
                      2230,
                      2239,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );

                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'UppskjutenSkatteskuld',
                    'row',
                    value('Uppskjuten skatteskuld'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.AvsattningarAbstract.UppskjutenSkatteskuld.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:UppskjutenSkatteskuld',
                        contextRef: 'balans0',
                        standardRubrik: 'Avsättningar för uppskjutna skatter',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.AvsattningarAbstract.UppskjutenSkatteskuld.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:UppskjutenSkatteskuld',
                        contextRef: 'balans1',
                        standardRubrik: 'Avsättningar för uppskjutna skatter',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    accountRangeRows(
                      accounts,
                      2240,
                      2249,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );

                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'AvsattningarOvrigSkatt',
                    'row',
                    value('Avsättningar för övriga skatter'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.AvsattningarAbstract.AvsattningarOvrigSkatt.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:AvsattningarOvrigSkatt',
                        contextRef: 'balans0',
                        standardRubrik: 'Avsättningar för övriga skatter',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.AvsattningarAbstract.AvsattningarOvrigSkatt.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:AvsattningarOvrigSkatt',
                        contextRef: 'balans1',
                        standardRubrik: 'Avsättningar för övriga skatter',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    accountRangeRows(
                      accounts,
                      2250,
                      2259,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );

                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'OvrigaAvsattningar',
                    'row',
                    value('Övriga avsättningar'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.AvsattningarAbstract.OvrigaAvsattningar.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:OvrigaAvsattningar',
                        contextRef: 'balans0',
                        standardRubrik: 'Övriga avsättningar',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.AvsattningarAbstract.OvrigaAvsattningar.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:OvrigaAvsattningar',
                        contextRef: 'balans1',
                        standardRubrik: 'Övriga avsättningar',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    accountRangeRows(
                      accounts,
                      2290,
                      2299,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );

                    return rows.build();
                  });
                return rows.build();
              });
            rows
              .addRowWithType(
                'Avsattningar',
                'sum',
                value('Summa avsättningar'),
                undefined,
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.AvsattningarAbstract.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    name: 'se-gen-base:Avsattningar',
                    contextRef: 'balans0',
                    standardRubrik: 'Avsättningar',
                    saldo: 'credit',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.AvsattningarAbstract.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    name: 'se-gen-base:Avsattningar',
                    contextRef: 'balans1',
                    standardRubrik: 'Avsättningar',
                    saldo: 'credit',
                    negateValue: false,
                  }
                )
              )
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(or(account('$ub', accountModifier, 'year'), 0)),
                ref(or(account('$ib', accountModifier, 'previousYear'), 0))
              )
              .addSubRows((rows) => {
                return rows.build();
              });
            rows
              .addRowWithType(
                'LangfristigaSkulderAbstract',
                'header',
                value('Långfristiga skulder'),
                refs(),
                undefined,
                undefined
              )
              .addSubRows((rows) => {
                rows
                  .addRowWithType(
                    'Obligationslan',
                    'row',
                    value('Obligationslån'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.LangfristigaSkulderAbstract.Obligationslan.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:Obligationslan',
                        contextRef: 'balans0',
                        standardRubrik: 'Obligationslån',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.LangfristigaSkulderAbstract.Obligationslan.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:Obligationslan',
                        contextRef: 'balans1',
                        standardRubrik: 'Obligationslån',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    accountRangeRows(
                      accounts,
                      2300,
                      2329,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );

                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'CheckrakningskreditLangfristig',
                    'row',
                    value('Checkräkningskredit'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.LangfristigaSkulderAbstract.CheckrakningskreditLangfristig.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:CheckrakningskreditLangfristig',
                        contextRef: 'balans0',
                        standardRubrik: 'Långfristig checkräkningskredit',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.LangfristigaSkulderAbstract.CheckrakningskreditLangfristig.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:CheckrakningskreditLangfristig',
                        contextRef: 'balans1',
                        standardRubrik: 'Långfristig checkräkningskredit',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    accountRangeRows(
                      accounts,
                      2330,
                      2339,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );

                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'OvrigaLangfristigaSkulderKreditinstitut',
                    'row',
                    value('Övriga skulder till kreditinstitut'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.LangfristigaSkulderAbstract.OvrigaLangfristigaSkulderKreditinstitut.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:OvrigaLangfristigaSkulderKreditinstitut',
                        contextRef: 'balans0',
                        standardRubrik:
                          'Övriga långfristiga skulder till kreditinstitut',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.LangfristigaSkulderAbstract.OvrigaLangfristigaSkulderKreditinstitut.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:OvrigaLangfristigaSkulderKreditinstitut',
                        contextRef: 'balans1',
                        standardRubrik:
                          'Övriga långfristiga skulder till kreditinstitut',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    accountRangeRows(
                      accounts,
                      2340,
                      2359,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );

                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'SkulderKoncernforetagLangfristiga',
                    'row',
                    value('Skulder till koncernföretag'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.LangfristigaSkulderAbstract.SkulderKoncernforetagLangfristiga.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:SkulderKoncernforetagLangfristiga',
                        contextRef: 'balans0',
                        standardRubrik:
                          'Långfristiga skulder till koncernföretag',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.LangfristigaSkulderAbstract.SkulderKoncernforetagLangfristiga.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:SkulderKoncernforetagLangfristiga',
                        contextRef: 'balans1',
                        standardRubrik:
                          'Långfristiga skulder till koncernföretag',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    accountRangeRows(
                      accounts,
                      2360,
                      2369,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );

                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'SkulderIntresseforetagGemensamtStyrdaForetagLangfristiga',
                    'row',
                    value(
                      'Skulder till intresseföretag och gemensamt styrda företag'
                    ),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.LangfristigaSkulderAbstract.SkulderIntresseforetagGemensamtStyrdaForetagLangfristiga.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:SkulderIntresseforetagGemensamtStyrdaForetagLangfristiga',
                        contextRef: 'balans0',
                        standardRubrik:
                          'Långfristiga skulder till intresseföretag och gemensamt styrda företag',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.LangfristigaSkulderAbstract.SkulderIntresseforetagGemensamtStyrdaForetagLangfristiga.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:SkulderIntresseforetagGemensamtStyrdaForetagLangfristiga',
                        contextRef: 'balans1',
                        standardRubrik:
                          'Långfristiga skulder till intresseföretag och gemensamt styrda företag',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    accountRangeRows(
                      accounts,
                      2370,
                      2372,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );

                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'SkulderOvrigaForetagAgarintresseLangfristiga',
                    'row',
                    value(
                      'Skulder till övriga företag som det finns ett ägarintresse i'
                    ),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.LangfristigaSkulderAbstract.SkulderOvrigaForetagAgarintresseLangfristiga.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:SkulderOvrigaForetagAgarintresseLangfristiga',
                        contextRef: 'balans0',
                        standardRubrik:
                          'Långfristiga skulder till övriga företag som det finns ett ägarintresse i',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.LangfristigaSkulderAbstract.SkulderOvrigaForetagAgarintresseLangfristiga.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:SkulderOvrigaForetagAgarintresseLangfristiga',
                        contextRef: 'balans1',
                        standardRubrik:
                          'Långfristiga skulder till övriga företag som det finns ett ägarintresse i',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    accountRangeRows(
                      accounts,
                      2373,
                      2379,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );

                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'OvrigaLangfristigaSkulder',
                    'row',
                    value('Övriga skulder'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.LangfristigaSkulderAbstract.OvrigaLangfristigaSkulder.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:OvrigaLangfristigaSkulder',
                        contextRef: 'balans0',
                        standardRubrik: 'Övriga långfristiga skulder',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.LangfristigaSkulderAbstract.OvrigaLangfristigaSkulder.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:OvrigaLangfristigaSkulder',
                        contextRef: 'balans1',
                        standardRubrik: 'Övriga långfristiga skulder',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    accountRangeRows(
                      accounts,
                      2380,
                      2399,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );

                    return rows.build();
                  });
                return rows.build();
              });
            rows
              .addRowWithType(
                'LangfristigaSkulder',
                'sum',
                value('Summa långfristiga skulder'),
                undefined,
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.LangfristigaSkulderAbstract.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    name: 'se-gen-base:LangfristigaSkulder',
                    contextRef: 'balans0',
                    standardRubrik: 'Långfristiga skulder',
                    saldo: 'credit',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.LangfristigaSkulderAbstract.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    name: 'se-gen-base:LangfristigaSkulder',
                    contextRef: 'balans1',
                    standardRubrik: 'Långfristiga skulder',
                    saldo: 'credit',
                    negateValue: false,
                  }
                )
              )
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(or(account('$ub', accountModifier, 'year'), 0)),
                ref(or(account('$ib', accountModifier, 'previousYear'), 0))
              )
              .addSubRows((rows) => {
                return rows.build();
              });
            rows
              .addRowWithType(
                'KortfristigaSkulderAbstract',
                'header',
                value('Kortfristiga skulder'),
                refs(),
                undefined,
                undefined
              )
              .addSubRows((rows) => {
                rows
                  .addRowWithType(
                    'CheckrakningskreditKortfristig',
                    'row',
                    value('Checkräkningskredit'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.CheckrakningskreditKortfristig.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:CheckrakningskreditKortfristig',
                        contextRef: 'balans0',
                        standardRubrik: 'Kortfristig checkräkningskredit',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.CheckrakningskreditKortfristig.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:CheckrakningskreditKortfristig',
                        contextRef: 'balans1',
                        standardRubrik: 'Kortfristig checkräkningskredit',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    accountRangeRows(
                      accounts,
                      2480,
                      2489,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );

                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'OvrigaKortfristigaSkulderKreditinstitut',
                    'row',
                    value('Övriga skulder till kreditinstitut'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.OvrigaKortfristigaSkulderKreditinstitut.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:OvrigaKortfristigaSkulderKreditinstitut',
                        contextRef: 'balans0',
                        standardRubrik:
                          'Övriga kortfristiga skulder till kreditinstitut',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.OvrigaKortfristigaSkulderKreditinstitut.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:OvrigaKortfristigaSkulderKreditinstitut',
                        contextRef: 'balans1',
                        standardRubrik:
                          'Övriga kortfristiga skulder till kreditinstitut',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    accountRangeRows(
                      accounts,
                      2400,
                      2419,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );

                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'ForskottFranKunder',
                    'row',
                    value('Förskott från kunder'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.ForskottFranKunder.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:ForskottFranKunder',
                        contextRef: 'balans0',
                        standardRubrik: 'Förskott från kunder',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.ForskottFranKunder.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:ForskottFranKunder',
                        contextRef: 'balans1',
                        standardRubrik: 'Förskott från kunder',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    accountRangeRows(
                      accounts,
                      2420,
                      2429,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );

                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'PagaendeArbetenAnnansRakningKortfristigaSkulder',
                    'row',
                    value('Pågående arbete för annans räkning'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.PagaendeArbetenAnnansRakningKortfristigaSkulder.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:PagaendeArbetenAnnansRakningKortfristigaSkulder',
                        contextRef: 'balans0',
                        standardRubrik:
                          'Pågående arbete för annans räkning, skuld',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.PagaendeArbetenAnnansRakningKortfristigaSkulder.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:PagaendeArbetenAnnansRakningKortfristigaSkulder',
                        contextRef: 'balans1',
                        standardRubrik:
                          'Pågående arbete för annans räkning, skuld',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    accountRangeRows(
                      accounts,
                      2430,
                      2439,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );

                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'Leverantorsskulder',
                    'row',
                    value('Leverantörsskulder'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.Leverantorsskulder.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:Leverantorsskulder',
                        contextRef: 'balans0',
                        standardRubrik: 'Leverantörsskulder',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.Leverantorsskulder.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:Leverantorsskulder',
                        contextRef: 'balans1',
                        standardRubrik: 'Leverantörsskulder',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    accountRangeRows(
                      accounts,
                      2440,
                      2449,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );

                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'Vaxelskulder',
                    'row',
                    value('Växelskulder'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.Vaxelskulder.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:Vaxelskulder',
                        contextRef: 'balans0',
                        standardRubrik: 'Växelskulder',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.Vaxelskulder.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:Vaxelskulder',
                        contextRef: 'balans1',
                        standardRubrik: 'Växelskulder',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    accountRangeRows(
                      accounts,
                      2492,
                      2492,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );

                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'SkulderKoncernforetagKortfristiga',
                    'row',
                    value('Skulder till koncernföretag'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.SkulderKoncernforetagKortfristiga.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:SkulderKoncernforetagKortfristiga',
                        contextRef: 'balans0',
                        standardRubrik:
                          'Kortfristiga skulder till koncernföretag',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.SkulderKoncernforetagKortfristiga.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:SkulderKoncernforetagKortfristiga',
                        contextRef: 'balans1',
                        standardRubrik:
                          'Kortfristiga skulder till koncernföretag',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    accountRangeRows(
                      accounts,
                      2460,
                      2469,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );
                    accountRangeRows(
                      accounts,
                      2860,
                      2869,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );

                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'SkulderIntresseforetagGemensamtStyrdaForetagKortfristiga',
                    'row',
                    value(
                      'Skulder till intresseföretag och gemensamt styrda företag'
                    ),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.SkulderIntresseforetagGemensamtStyrdaForetagKortfristiga.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:SkulderIntresseforetagGemensamtStyrdaForetagKortfristiga',
                        contextRef: 'balans0',
                        standardRubrik:
                          'Kortfristiga skulder till intresseföretag och gemensamt styrda företag',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.SkulderIntresseforetagGemensamtStyrdaForetagKortfristiga.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:SkulderIntresseforetagGemensamtStyrdaForetagKortfristiga',
                        contextRef: 'balans1',
                        standardRubrik:
                          'Kortfristiga skulder till intresseföretag och gemensamt styrda företag',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    accountRangeRows(
                      accounts,
                      2470,
                      2472,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );
                    accountRangeRows(
                      accounts,
                      2870,
                      2872,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );

                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'SkulderOvrigaForetagAgarintresseKortfristiga',
                    'row',
                    value(
                      'Skulder till övriga företag som det finns ett ägarintresse i'
                    ),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.SkulderOvrigaForetagAgarintresseKortfristiga.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:SkulderOvrigaForetagAgarintresseKortfristiga',
                        contextRef: 'balans0',
                        standardRubrik:
                          'Kortfristiga skulder till övriga företag som det finns ett ägarintresse i',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.SkulderOvrigaForetagAgarintresseKortfristiga.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:SkulderOvrigaForetagAgarintresseKortfristiga',
                        contextRef: 'balans1',
                        standardRubrik:
                          'Kortfristiga skulder till övriga företag som det finns ett ägarintresse i',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    accountRangeRows(
                      accounts,
                      2473,
                      2479,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );
                    accountRangeRows(
                      accounts,
                      2873,
                      2879,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );

                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'Skatteskulder',
                    'row',
                    value('Aktuella skatteskulder'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.Skatteskulder.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:Skatteskulder',
                        contextRef: 'balans0',
                        standardRubrik: 'Skatteskulder',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.Skatteskulder.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:Skatteskulder',
                        contextRef: 'balans1',
                        standardRubrik: 'Skatteskulder',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    accountRangeRows(
                      accounts,
                      2500,
                      2599,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );

                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'OvrigaKortfristigaSkulder',
                    'row',
                    value('Övriga skulder'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.OvrigaKortfristigaSkulder.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:OvrigaKortfristigaSkulder',
                        contextRef: 'balans0',
                        standardRubrik: 'Övriga kortfristiga skulder',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.OvrigaKortfristigaSkulder.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:OvrigaKortfristigaSkulder',
                        contextRef: 'balans1',
                        standardRubrik: 'Övriga kortfristiga skulder',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    accountRangeRows(
                      accounts,
                      2490,
                      2491,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );
                    accountRangeRows(
                      accounts,
                      2493,
                      2499,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );
                    accountRangeRows(
                      accounts,
                      2600,
                      2799,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );
                    accountRangeRows(
                      accounts,
                      2800,
                      2859,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );
                    accountRangeRows(
                      accounts,
                      2880,
                      2899,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );

                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'FaktureradEjUpparbetadIntakt',
                    'row',
                    value('Fakturerad men ej upparbetad intäkt'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.FaktureradEjUpparbetadIntakt.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:FaktureradEjUpparbetadIntakt',
                        contextRef: 'balans0',
                        standardRubrik: 'Fakturerad men ej upparbetad intäkt',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.FaktureradEjUpparbetadIntakt.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:FaktureradEjUpparbetadIntakt',
                        contextRef: 'balans1',
                        standardRubrik: 'Fakturerad men ej upparbetad intäkt',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    accountRangeRows(
                      accounts,
                      2450,
                      2459,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );

                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'UpplupnaKostnaderForutbetaldaIntakter',
                    'row',
                    value('Upplupna kostnader och förutbetalda intäkter'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.UpplupnaKostnaderForutbetaldaIntakter.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:UpplupnaKostnaderForutbetaldaIntakter',
                        contextRef: 'balans0',
                        standardRubrik:
                          'Upplupna kostnader och förutbetalda intäkter',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.UpplupnaKostnaderForutbetaldaIntakter.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:UpplupnaKostnaderForutbetaldaIntakter',
                        contextRef: 'balans1',
                        standardRubrik:
                          'Upplupna kostnader och förutbetalda intäkter',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    accountRangeRows(
                      accounts,
                      2900,
                      2999,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );

                    return rows.build();
                  });
                return rows.build();
              });
            rows
              .addRowWithType(
                'KortfristigaSkulder',
                'sum',
                value('Summa kortfristiga skulder'),
                undefined,
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    name: 'se-gen-base:KortfristigaSkulder',
                    contextRef: 'balans0',
                    standardRubrik: 'Kortfristiga skulder',
                    saldo: 'credit',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    name: 'se-gen-base:KortfristigaSkulder',
                    contextRef: 'balans1',
                    standardRubrik: 'Kortfristiga skulder',
                    saldo: 'credit',
                    negateValue: false,
                  }
                )
              )
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(or(account('$ub', accountModifier, 'year'), 0)),
                ref(or(account('$ib', accountModifier, 'previousYear'), 0))
              )
              .addSubRows((rows) => {
                return rows.build();
              });
            return rows.build();
          });
        rows
          .addRowWithType(
            'EgetKapitalSkulder',
            'alwaysDisplaySum',
            value('Summa eget kapital och skulder'),
            undefined,
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.*.year'
                  )
                )
              ),
              {
                type: 'monetaryItemType',
                name: 'se-gen-base:EgetKapitalSkulder',
                contextRef: 'balans0',
                standardRubrik: 'Eget kapital och skulder',
                saldo: 'credit',
                negateValue: false,
              }
            ),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.*.previousYear'
                  )
                )
              ),
              {
                type: 'monetaryItemType',
                name: 'se-gen-base:EgetKapitalSkulder',
                contextRef: 'balans1',
                standardRubrik: 'Eget kapital och skulder',
                saldo: 'credit',
                negateValue: false,
              }
            )
          )
          .newRowTemplateWithType(
            'account',
            label('$label'),
            undefined,
            ref(or(account('$ub', accountModifier, 'year'), 0)),
            ref(or(account('$ib', accountModifier, 'previousYear'), 0))
          )
          .addSubRows((rows) => {
            return rows.build();
          });
        rows
          .addRowWithType(
            'accountsNotAssigned',
            'accountsNotAssigned',
            label('Ej tilldelade konton'),
            undefined,
            undefined,
            undefined
          ) // >>> begin `add_button` (NL 1) [Id `balanceSheet.section.equityAndLiabilities`] ---
          .newRowTemplateWithType(
            'account',
            label('$label'),
            undefined,
            ref(or(account('$ub', accountModifier, 'year'), 0)),
            ref(or(account('$ib', accountModifier, 'previousYear'), 0))
          )
          .addSubRows((rows) => {
            accountRangeRows(
              accounts,
              2260,
              2289,
              rows,
              true,
              'year',
              'previousYear',
              accountModifier
            );

            return rows.build();
          });
        return rows.build();
      })
      .build(),
  },
});
export const balanceStatementK3v2References = (): Record<string, string> => ({
  'balanceSheet.TecknatEjInbetaltKapital.year': id(
    'balanceSheet.section.assets.TillgangarAbstract.TecknatEjInbetaltKapital.year'
  ),
  'balanceSheet.TecknatEjInbetaltKapital.previousYear': id(
    'balanceSheet.section.assets.TillgangarAbstract.TecknatEjInbetaltKapital.previousYear'
  ),
  'balanceSheet.BalanseradeUtgifterUtvecklingsarbetenLiknandeArbeten.year': id(
    'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.BalanseradeUtgifterUtvecklingsarbetenLiknandeArbeten.year'
  ),
  'balanceSheet.BalanseradeUtgifterUtvecklingsarbetenLiknandeArbeten.previousYear':
    id(
      'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.BalanseradeUtgifterUtvecklingsarbetenLiknandeArbeten.previousYear'
    ),
  'balanceSheet.KoncessionerPatentLicenserVarumarkenLiknandeRattigheter.year':
    id(
      'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.KoncessionerPatentLicenserVarumarkenLiknandeRattigheter.year'
    ),
  'balanceSheet.KoncessionerPatentLicenserVarumarkenLiknandeRattigheter.previousYear':
    id(
      'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.KoncessionerPatentLicenserVarumarkenLiknandeRattigheter.previousYear'
    ),
  'balanceSheet.HyresratterLiknandeRattigheter.year': id(
    'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.HyresratterLiknandeRattigheter.year'
  ),
  'balanceSheet.HyresratterLiknandeRattigheter.previousYear': id(
    'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.HyresratterLiknandeRattigheter.previousYear'
  ),
  'balanceSheet.Goodwill.year': id(
    'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.Goodwill.year'
  ),
  'balanceSheet.Goodwill.previousYear': id(
    'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.Goodwill.previousYear'
  ),
  'balanceSheet.PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangar.year':
    id(
      'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangar.year'
    ),
  'balanceSheet.PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangar.previousYear':
    id(
      'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.ImmateriellaAnlaggningstillgangarAbstract.PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangar.previousYear'
    ),
  'balanceSheet.ImmateriellaAnlaggningstillgangar.year': id(
    'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.ImmateriellaAnlaggningstillgangar.year'
  ),
  'balanceSheet.ImmateriellaAnlaggningstillgangar.previousYear': id(
    'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.ImmateriellaAnlaggningstillgangar.previousYear'
  ),
  'balanceSheet.ByggnaderMark.year': id(
    'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.MateriellaAnlaggningstillgangarAbstract.ByggnaderMark.year'
  ),
  'balanceSheet.ByggnaderMark.previousYear': id(
    'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.MateriellaAnlaggningstillgangarAbstract.ByggnaderMark.previousYear'
  ),
  'balanceSheet.MaskinerAndraTekniskaAnlaggningar.year': id(
    'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.MateriellaAnlaggningstillgangarAbstract.MaskinerAndraTekniskaAnlaggningar.year'
  ),
  'balanceSheet.MaskinerAndraTekniskaAnlaggningar.previousYear': id(
    'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.MateriellaAnlaggningstillgangarAbstract.MaskinerAndraTekniskaAnlaggningar.previousYear'
  ),
  'balanceSheet.InventarierVerktygInstallationer.year': id(
    'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.MateriellaAnlaggningstillgangarAbstract.InventarierVerktygInstallationer.year'
  ),
  'balanceSheet.InventarierVerktygInstallationer.previousYear': id(
    'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.MateriellaAnlaggningstillgangarAbstract.InventarierVerktygInstallationer.previousYear'
  ),
  'balanceSheet.ForbattringsutgifterAnnansFastighet.year': id(
    'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.MateriellaAnlaggningstillgangarAbstract.ForbattringsutgifterAnnansFastighet.year'
  ),
  'balanceSheet.ForbattringsutgifterAnnansFastighet.previousYear': id(
    'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.MateriellaAnlaggningstillgangarAbstract.ForbattringsutgifterAnnansFastighet.previousYear'
  ),
  'balanceSheet.PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangar.year':
    id(
      'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.MateriellaAnlaggningstillgangarAbstract.PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangar.year'
    ),
  'balanceSheet.PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangar.previousYear':
    id(
      'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.MateriellaAnlaggningstillgangarAbstract.PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangar.previousYear'
    ),
  'balanceSheet.OvrigaMateriellaAnlaggningstillgangar.year': id(
    'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.MateriellaAnlaggningstillgangarAbstract.OvrigaMateriellaAnlaggningstillgangar.year'
  ),
  'balanceSheet.OvrigaMateriellaAnlaggningstillgangar.previousYear': id(
    'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.MateriellaAnlaggningstillgangarAbstract.OvrigaMateriellaAnlaggningstillgangar.previousYear'
  ),
  'balanceSheet.MateriellaAnlaggningstillgangar.year': id(
    'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.MateriellaAnlaggningstillgangar.year'
  ),
  'balanceSheet.MateriellaAnlaggningstillgangar.previousYear': id(
    'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.MateriellaAnlaggningstillgangar.previousYear'
  ),
  'balanceSheet.AndelarKoncernforetag.year': id(
    'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.FinansiellaAnlaggningstillgangarAbstract.AndelarKoncernforetag.year'
  ),
  'balanceSheet.AndelarKoncernforetag.previousYear': id(
    'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.FinansiellaAnlaggningstillgangarAbstract.AndelarKoncernforetag.previousYear'
  ),
  'balanceSheet.FordringarKoncernforetagLangfristiga.year': id(
    'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.FinansiellaAnlaggningstillgangarAbstract.FordringarKoncernforetagLangfristiga.year'
  ),
  'balanceSheet.FordringarKoncernforetagLangfristiga.previousYear': id(
    'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.FinansiellaAnlaggningstillgangarAbstract.FordringarKoncernforetagLangfristiga.previousYear'
  ),
  'balanceSheet.AndelarIntresseforetagGemensamtStyrdaForetag.year': id(
    'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.FinansiellaAnlaggningstillgangarAbstract.AndelarIntresseforetagGemensamtStyrdaForetag.year'
  ),
  'balanceSheet.AndelarIntresseforetagGemensamtStyrdaForetag.previousYear': id(
    'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.FinansiellaAnlaggningstillgangarAbstract.AndelarIntresseforetagGemensamtStyrdaForetag.previousYear'
  ),
  'balanceSheet.FordringarIntresseforetagGemensamtStyrdaForetagLangfristiga.year':
    id(
      'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.FinansiellaAnlaggningstillgangarAbstract.FordringarIntresseforetagGemensamtStyrdaForetagLangfristiga.year'
    ),
  'balanceSheet.FordringarIntresseforetagGemensamtStyrdaForetagLangfristiga.previousYear':
    id(
      'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.FinansiellaAnlaggningstillgangarAbstract.FordringarIntresseforetagGemensamtStyrdaForetagLangfristiga.previousYear'
    ),
  'balanceSheet.AgarintressenOvrigaForetag.year': id(
    'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.FinansiellaAnlaggningstillgangarAbstract.AgarintressenOvrigaForetag.year'
  ),
  'balanceSheet.AgarintressenOvrigaForetag.previousYear': id(
    'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.FinansiellaAnlaggningstillgangarAbstract.AgarintressenOvrigaForetag.previousYear'
  ),
  'balanceSheet.FordringarOvrigaForetagAgarintresseLangfristiga.year': id(
    'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.FinansiellaAnlaggningstillgangarAbstract.FordringarOvrigaForetagAgarintresseLangfristiga.year'
  ),
  'balanceSheet.FordringarOvrigaForetagAgarintresseLangfristiga.previousYear':
    id(
      'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.FinansiellaAnlaggningstillgangarAbstract.FordringarOvrigaForetagAgarintresseLangfristiga.previousYear'
    ),
  'balanceSheet.AndraLangfristigaVardepappersinnehav.year': id(
    'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.FinansiellaAnlaggningstillgangarAbstract.AndraLangfristigaVardepappersinnehav.year'
  ),
  'balanceSheet.AndraLangfristigaVardepappersinnehav.previousYear': id(
    'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.FinansiellaAnlaggningstillgangarAbstract.AndraLangfristigaVardepappersinnehav.previousYear'
  ),
  'balanceSheet.LanDelagareNarstaende.year': id(
    'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.FinansiellaAnlaggningstillgangarAbstract.LanDelagareNarstaende.year'
  ),
  'balanceSheet.LanDelagareNarstaende.previousYear': id(
    'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.FinansiellaAnlaggningstillgangarAbstract.LanDelagareNarstaende.previousYear'
  ),
  'balanceSheet.UppskjutenSkattefordran.year': id(
    'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.FinansiellaAnlaggningstillgangarAbstract.UppskjutenSkattefordran.year'
  ),
  'balanceSheet.UppskjutenSkattefordran.previousYear': id(
    'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.FinansiellaAnlaggningstillgangarAbstract.UppskjutenSkattefordran.previousYear'
  ),
  'balanceSheet.AndraLangfristigaFordringar.year': id(
    'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.FinansiellaAnlaggningstillgangarAbstract.AndraLangfristigaFordringar.year'
  ),
  'balanceSheet.AndraLangfristigaFordringar.previousYear': id(
    'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.FinansiellaAnlaggningstillgangarAbstract.AndraLangfristigaFordringar.previousYear'
  ),
  'balanceSheet.FinansiellaAnlaggningstillgangar.year': id(
    'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.FinansiellaAnlaggningstillgangar.year'
  ),
  'balanceSheet.FinansiellaAnlaggningstillgangar.previousYear': id(
    'balanceSheet.section.assets.TillgangarAbstract.AnlaggningstillgangarAbstract.FinansiellaAnlaggningstillgangar.previousYear'
  ),
  'balanceSheet.Anlaggningstillgangar.year': id(
    'balanceSheet.section.assets.TillgangarAbstract.Anlaggningstillgangar.year'
  ),
  'balanceSheet.Anlaggningstillgangar.previousYear': id(
    'balanceSheet.section.assets.TillgangarAbstract.Anlaggningstillgangar.previousYear'
  ),
  'balanceSheet.LagerRavarorFornodenheter.year': id(
    'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.VarulagerMmAbstract.LagerRavarorFornodenheter.year'
  ),
  'balanceSheet.LagerRavarorFornodenheter.previousYear': id(
    'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.VarulagerMmAbstract.LagerRavarorFornodenheter.previousYear'
  ),
  'balanceSheet.LagerVarorUnderTillverkning.year': id(
    'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.VarulagerMmAbstract.LagerVarorUnderTillverkning.year'
  ),
  'balanceSheet.LagerVarorUnderTillverkning.previousYear': id(
    'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.VarulagerMmAbstract.LagerVarorUnderTillverkning.previousYear'
  ),
  'balanceSheet.LagerFardigaVarorHandelsvaror.year': id(
    'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.VarulagerMmAbstract.LagerFardigaVarorHandelsvaror.year'
  ),
  'balanceSheet.LagerFardigaVarorHandelsvaror.previousYear': id(
    'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.VarulagerMmAbstract.LagerFardigaVarorHandelsvaror.previousYear'
  ),
  'balanceSheet.OvrigaLagertillgangar.year': id(
    'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.VarulagerMmAbstract.OvrigaLagertillgangar.year'
  ),
  'balanceSheet.OvrigaLagertillgangar.previousYear': id(
    'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.VarulagerMmAbstract.OvrigaLagertillgangar.previousYear'
  ),
  'balanceSheet.PagaendeArbetenAnnansRakningOmsattningstillgangar.year': id(
    'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.VarulagerMmAbstract.PagaendeArbetenAnnansRakningOmsattningstillgangar.year'
  ),
  'balanceSheet.PagaendeArbetenAnnansRakningOmsattningstillgangar.previousYear':
    id(
      'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.VarulagerMmAbstract.PagaendeArbetenAnnansRakningOmsattningstillgangar.previousYear'
    ),
  'balanceSheet.ForskottTillLeverantorer.year': id(
    'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.VarulagerMmAbstract.ForskottTillLeverantorer.year'
  ),
  'balanceSheet.ForskottTillLeverantorer.previousYear': id(
    'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.VarulagerMmAbstract.ForskottTillLeverantorer.previousYear'
  ),
  'balanceSheet.VarulagerMm.year': id(
    'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.VarulagerMm.year'
  ),
  'balanceSheet.VarulagerMm.previousYear': id(
    'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.VarulagerMm.previousYear'
  ),
  'balanceSheet.Kundfordringar.year': id(
    'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaFordringarAbstract.Kundfordringar.year'
  ),
  'balanceSheet.Kundfordringar.previousYear': id(
    'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaFordringarAbstract.Kundfordringar.previousYear'
  ),
  'balanceSheet.FordringarKoncernforetagKortfristiga.year': id(
    'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaFordringarAbstract.FordringarKoncernforetagKortfristiga.year'
  ),
  'balanceSheet.FordringarKoncernforetagKortfristiga.previousYear': id(
    'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaFordringarAbstract.FordringarKoncernforetagKortfristiga.previousYear'
  ),
  'balanceSheet.FordringarIntresseforetagGemensamtStyrdaForetagKortfristiga.year':
    id(
      'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaFordringarAbstract.FordringarIntresseforetagGemensamtStyrdaForetagKortfristiga.year'
    ),
  'balanceSheet.FordringarIntresseforetagGemensamtStyrdaForetagKortfristiga.previousYear':
    id(
      'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaFordringarAbstract.FordringarIntresseforetagGemensamtStyrdaForetagKortfristiga.previousYear'
    ),
  'balanceSheet.FordringarOvrigaforetagAgarintresseKortfristiga.year': id(
    'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaFordringarAbstract.FordringarOvrigaforetagAgarintresseKortfristiga.year'
  ),
  'balanceSheet.FordringarOvrigaforetagAgarintresseKortfristiga.previousYear':
    id(
      'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaFordringarAbstract.FordringarOvrigaforetagAgarintresseKortfristiga.previousYear'
    ),
  'balanceSheet.AktuellSkattefodran.year': id(
    'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaFordringarAbstract.AktuellSkattefodran.year'
  ),
  'balanceSheet.AktuellSkattefodran.previousYear': id(
    'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaFordringarAbstract.AktuellSkattefodran.previousYear'
  ),
  'balanceSheet.OvrigaFordringarKortfristiga.year': id(
    'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaFordringarAbstract.OvrigaFordringarKortfristiga.year'
  ),
  'balanceSheet.OvrigaFordringarKortfristiga.previousYear': id(
    'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaFordringarAbstract.OvrigaFordringarKortfristiga.previousYear'
  ),
  'balanceSheet.UpparbetadEjFaktureradIntakt.year': id(
    'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaFordringarAbstract.UpparbetadEjFaktureradIntakt.year'
  ),
  'balanceSheet.UpparbetadEjFaktureradIntakt.previousYear': id(
    'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaFordringarAbstract.UpparbetadEjFaktureradIntakt.previousYear'
  ),
  'balanceSheet.ForutbetaldaKostnaderUpplupnaIntakter.year': id(
    'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaFordringarAbstract.ForutbetaldaKostnaderUpplupnaIntakter.year'
  ),
  'balanceSheet.ForutbetaldaKostnaderUpplupnaIntakter.previousYear': id(
    'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaFordringarAbstract.ForutbetaldaKostnaderUpplupnaIntakter.previousYear'
  ),
  'balanceSheet.KortfristigaFordringar.year': id(
    'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaFordringar.year'
  ),
  'balanceSheet.KortfristigaFordringar.previousYear': id(
    'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaFordringar.previousYear'
  ),
  'balanceSheet.AndelarKoncernforetagKortfristiga.year': id(
    'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaPlaceringarAbstract.AndelarKoncernforetagKortfristiga.year'
  ),
  'balanceSheet.AndelarKoncernforetagKortfristiga.previousYear': id(
    'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaPlaceringarAbstract.AndelarKoncernforetagKortfristiga.previousYear'
  ),
  'balanceSheet.OvrigaKortfristigaPlaceringar.year': id(
    'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaPlaceringarAbstract.OvrigaKortfristigaPlaceringar.year'
  ),
  'balanceSheet.OvrigaKortfristigaPlaceringar.previousYear': id(
    'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaPlaceringarAbstract.OvrigaKortfristigaPlaceringar.previousYear'
  ),
  'balanceSheet.KortfristigaPlaceringar.year': id(
    'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaPlaceringar.year'
  ),
  'balanceSheet.KortfristigaPlaceringar.previousYear': id(
    'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KortfristigaPlaceringar.previousYear'
  ),
  'balanceSheet.KassaBankExklRedovisningsmedel.year': id(
    'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KassaBankAbstract.KassaBankExklRedovisningsmedel.year'
  ),
  'balanceSheet.KassaBankExklRedovisningsmedel.previousYear': id(
    'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KassaBankAbstract.KassaBankExklRedovisningsmedel.previousYear'
  ),
  'balanceSheet.Redovisningsmedel.year': id(
    'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KassaBankAbstract.Redovisningsmedel.year'
  ),
  'balanceSheet.Redovisningsmedel.previousYear': id(
    'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KassaBankAbstract.Redovisningsmedel.previousYear'
  ),
  'balanceSheet.KassaBank.year': id(
    'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KassaBank.year'
  ),
  'balanceSheet.KassaBank.previousYear': id(
    'balanceSheet.section.assets.TillgangarAbstract.OmsattningstillgangarAbstract.KassaBank.previousYear'
  ),
  'balanceSheet.Omsattningstillgangar.year': id(
    'balanceSheet.section.assets.TillgangarAbstract.Omsattningstillgangar.year'
  ),
  'balanceSheet.Omsattningstillgangar.previousYear': id(
    'balanceSheet.section.assets.TillgangarAbstract.Omsattningstillgangar.previousYear'
  ),
  'balanceSheet.Tillgangar.year': id(
    'balanceSheet.section.assets.Tillgangar.year'
  ),
  'balanceSheet.Tillgangar.previousYear': id(
    'balanceSheet.section.assets.Tillgangar.previousYear'
  ),
  'balanceSheet.Aktiekapital.year': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.EgetKapitalAbstract.BundetEgetKapitalAbstract.Aktiekapital.year'
  ),
  'balanceSheet.Aktiekapital.previousYear': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.EgetKapitalAbstract.BundetEgetKapitalAbstract.Aktiekapital.previousYear'
  ),
  'balanceSheet.EjRegistreratAktiekapital.year': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.EgetKapitalAbstract.BundetEgetKapitalAbstract.EjRegistreratAktiekapital.year'
  ),
  'balanceSheet.EjRegistreratAktiekapital.previousYear': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.EgetKapitalAbstract.BundetEgetKapitalAbstract.EjRegistreratAktiekapital.previousYear'
  ),
  'balanceSheet.OverkursfondBunden.year': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.EgetKapitalAbstract.BundetEgetKapitalAbstract.OverkursfondBunden.year'
  ),
  'balanceSheet.OverkursfondBunden.previousYear': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.EgetKapitalAbstract.BundetEgetKapitalAbstract.OverkursfondBunden.previousYear'
  ),
  'balanceSheet.Uppskrivningsfond.year': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.EgetKapitalAbstract.BundetEgetKapitalAbstract.Uppskrivningsfond.year'
  ),
  'balanceSheet.Uppskrivningsfond.previousYear': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.EgetKapitalAbstract.BundetEgetKapitalAbstract.Uppskrivningsfond.previousYear'
  ),
  'balanceSheet.Reservfond.year': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.EgetKapitalAbstract.BundetEgetKapitalAbstract.Reservfond.year'
  ),
  'balanceSheet.Reservfond.previousYear': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.EgetKapitalAbstract.BundetEgetKapitalAbstract.Reservfond.previousYear'
  ),
  'balanceSheet.Kapitalandelsfond.year': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.EgetKapitalAbstract.BundetEgetKapitalAbstract.Kapitalandelsfond.year'
  ),
  'balanceSheet.Kapitalandelsfond.previousYear': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.EgetKapitalAbstract.BundetEgetKapitalAbstract.Kapitalandelsfond.previousYear'
  ),
  'balanceSheet.FondUtvecklingsutgifter.year': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.EgetKapitalAbstract.BundetEgetKapitalAbstract.FondUtvecklingsutgifter.year'
  ),
  'balanceSheet.FondUtvecklingsutgifter.previousYear': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.EgetKapitalAbstract.BundetEgetKapitalAbstract.FondUtvecklingsutgifter.previousYear'
  ),
  'balanceSheet.BundetEgetKapital.year': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.EgetKapitalAbstract.BundetEgetKapital.year'
  ),
  'balanceSheet.BundetEgetKapital.previousYear': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.EgetKapitalAbstract.BundetEgetKapital.previousYear'
  ),
  'balanceSheet.Overkursfond.year': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.EgetKapitalAbstract.FrittEgetKapitalAbstract.Overkursfond.year'
  ),
  'balanceSheet.Overkursfond.previousYear': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.EgetKapitalAbstract.FrittEgetKapitalAbstract.Overkursfond.previousYear'
  ),
  'balanceSheet.FondVerkligtVarde.year': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.EgetKapitalAbstract.FrittEgetKapitalAbstract.FondVerkligtVarde.year'
  ),
  'balanceSheet.FondVerkligtVarde.previousYear': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.EgetKapitalAbstract.FrittEgetKapitalAbstract.FondVerkligtVarde.previousYear'
  ),
  'balanceSheet.BalanseratResultat.year': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.EgetKapitalAbstract.FrittEgetKapitalAbstract.BalanseratResultat.year'
  ),
  'balanceSheet.BalanseratResultat.previousYear': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.EgetKapitalAbstract.FrittEgetKapitalAbstract.BalanseratResultat.previousYear'
  ),
  'balanceSheet.AretsResultatEgetKapital.year': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.EgetKapitalAbstract.FrittEgetKapitalAbstract.AretsResultatEgetKapital.year'
  ),
  'balanceSheet.AretsResultatEgetKapital.previousYear': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.EgetKapitalAbstract.FrittEgetKapitalAbstract.AretsResultatEgetKapital.previousYear'
  ),
  'balanceSheet.FrittEgetKapital.year': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.EgetKapitalAbstract.FrittEgetKapital.year'
  ),
  'balanceSheet.FrittEgetKapital.previousYear': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.EgetKapitalAbstract.FrittEgetKapital.previousYear'
  ),
  'balanceSheet.EgetKapital.year': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.EgetKapital.year'
  ),
  'balanceSheet.EgetKapital.previousYear': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.EgetKapital.previousYear'
  ),
  'balanceSheet.Periodiseringsfonder.year': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.ObeskattadeReserverAbstract.Periodiseringsfonder.year'
  ),
  'balanceSheet.Periodiseringsfonder.previousYear': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.ObeskattadeReserverAbstract.Periodiseringsfonder.previousYear'
  ),
  'balanceSheet.AckumuleradeOveravskrivningar.year': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.ObeskattadeReserverAbstract.AckumuleradeOveravskrivningar.year'
  ),
  'balanceSheet.AckumuleradeOveravskrivningar.previousYear': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.ObeskattadeReserverAbstract.AckumuleradeOveravskrivningar.previousYear'
  ),
  'balanceSheet.Ersattningsfonder.year': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.ObeskattadeReserverAbstract.Ersattningsfonder.year'
  ),
  'balanceSheet.Ersattningsfonder.previousYear': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.ObeskattadeReserverAbstract.Ersattningsfonder.previousYear'
  ),
  'balanceSheet.AndraObeskattadeReserver.year': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.ObeskattadeReserverAbstract.AndraObeskattadeReserver.year'
  ),
  'balanceSheet.AndraObeskattadeReserver.previousYear': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.ObeskattadeReserverAbstract.AndraObeskattadeReserver.previousYear'
  ),
  'balanceSheet.ObeskattadeReserver.year': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.ObeskattadeReserver.year'
  ),
  'balanceSheet.ObeskattadeReserver.previousYear': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.ObeskattadeReserver.previousYear'
  ),
  'balanceSheet.AvsattningarPensionerLiknandeForpliktelserEnligtLag.year': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.AvsattningarAbstract.AvsattningarPensionerLiknandeForpliktelserEnligtLag.year'
  ),
  'balanceSheet.AvsattningarPensionerLiknandeForpliktelserEnligtLag.previousYear':
    id(
      'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.AvsattningarAbstract.AvsattningarPensionerLiknandeForpliktelserEnligtLag.previousYear'
    ),
  'balanceSheet.OvrigaAvsattningarPensionerLiknandeForpliktelser.year': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.AvsattningarAbstract.OvrigaAvsattningarPensionerLiknandeForpliktelser.year'
  ),
  'balanceSheet.OvrigaAvsattningarPensionerLiknandeForpliktelser.previousYear':
    id(
      'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.AvsattningarAbstract.OvrigaAvsattningarPensionerLiknandeForpliktelser.previousYear'
    ),
  'balanceSheet.AvsattningarPensionerLiknandeForpliktelser.year': id(
    'AvsattningarPensionerLiknandeForpliktelser.year'
  ),
  'balanceSheet.AvsattningarPensionerLiknandeForpliktelser.previousYear': id(
    'AvsattningarPensionerLiknandeForpliktelser.previousYear'
  ),
  'balanceSheet.UppskjutenSkatteskuld.year': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.AvsattningarAbstract.UppskjutenSkatteskuld.year'
  ),
  'balanceSheet.UppskjutenSkatteskuld.previousYear': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.AvsattningarAbstract.UppskjutenSkatteskuld.previousYear'
  ),
  'balanceSheet.AvsattningarOvrigSkatt.year': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.AvsattningarAbstract.AvsattningarOvrigSkatt.year'
  ),
  'balanceSheet.AvsattningarOvrigSkatt.previousYear': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.AvsattningarAbstract.AvsattningarOvrigSkatt.previousYear'
  ),
  'balanceSheet.OvrigaAvsattningar.year': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.AvsattningarAbstract.OvrigaAvsattningar.year'
  ),
  'balanceSheet.OvrigaAvsattningar.previousYear': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.AvsattningarAbstract.OvrigaAvsattningar.previousYear'
  ),
  'balanceSheet.Avsattningar.year': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.Avsattningar.year'
  ),
  'balanceSheet.Avsattningar.previousYear': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.Avsattningar.previousYear'
  ),
  'balanceSheet.Obligationslan.year': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.LangfristigaSkulderAbstract.Obligationslan.year'
  ),
  'balanceSheet.Obligationslan.previousYear': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.LangfristigaSkulderAbstract.Obligationslan.previousYear'
  ),
  'balanceSheet.CheckrakningskreditLangfristig.year': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.LangfristigaSkulderAbstract.CheckrakningskreditLangfristig.year'
  ),
  'balanceSheet.CheckrakningskreditLangfristig.previousYear': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.LangfristigaSkulderAbstract.CheckrakningskreditLangfristig.previousYear'
  ),
  'balanceSheet.OvrigaLangfristigaSkulderKreditinstitut.year': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.LangfristigaSkulderAbstract.OvrigaLangfristigaSkulderKreditinstitut.year'
  ),
  'balanceSheet.OvrigaLangfristigaSkulderKreditinstitut.previousYear': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.LangfristigaSkulderAbstract.OvrigaLangfristigaSkulderKreditinstitut.previousYear'
  ),
  'balanceSheet.SkulderKreditinstitutLangfristiga.year': id(
    'SkulderKreditinstitutLangfristiga.year'
  ),
  'balanceSheet.SkulderKreditinstitutLangfristiga.previousYear': id(
    'SkulderKreditinstitutLangfristiga.previousYear'
  ),
  'balanceSheet.SkulderKoncernforetagLangfristiga.year': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.LangfristigaSkulderAbstract.SkulderKoncernforetagLangfristiga.year'
  ),
  'balanceSheet.SkulderKoncernforetagLangfristiga.previousYear': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.LangfristigaSkulderAbstract.SkulderKoncernforetagLangfristiga.previousYear'
  ),
  'balanceSheet.SkulderIntresseforetagGemensamtStyrdaForetagLangfristiga.year':
    id(
      'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.LangfristigaSkulderAbstract.SkulderIntresseforetagGemensamtStyrdaForetagLangfristiga.year'
    ),
  'balanceSheet.SkulderIntresseforetagGemensamtStyrdaForetagLangfristiga.previousYear':
    id(
      'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.LangfristigaSkulderAbstract.SkulderIntresseforetagGemensamtStyrdaForetagLangfristiga.previousYear'
    ),
  'balanceSheet.SkulderOvrigaForetagAgarintresseLangfristiga.year': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.LangfristigaSkulderAbstract.SkulderOvrigaForetagAgarintresseLangfristiga.year'
  ),
  'balanceSheet.SkulderOvrigaForetagAgarintresseLangfristiga.previousYear': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.LangfristigaSkulderAbstract.SkulderOvrigaForetagAgarintresseLangfristiga.previousYear'
  ),
  'balanceSheet.OvrigaLangfristigaSkulder.year': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.LangfristigaSkulderAbstract.OvrigaLangfristigaSkulder.year'
  ),
  'balanceSheet.OvrigaLangfristigaSkulder.previousYear': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.LangfristigaSkulderAbstract.OvrigaLangfristigaSkulder.previousYear'
  ),
  'balanceSheet.LangfristigaSkulder.year': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.LangfristigaSkulder.year'
  ),
  'balanceSheet.LangfristigaSkulder.previousYear': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.LangfristigaSkulder.previousYear'
  ),
  'balanceSheet.CheckrakningskreditKortfristig.year': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.CheckrakningskreditKortfristig.year'
  ),
  'balanceSheet.CheckrakningskreditKortfristig.previousYear': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.CheckrakningskreditKortfristig.previousYear'
  ),
  'balanceSheet.OvrigaKortfristigaSkulderKreditinstitut.year': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.OvrigaKortfristigaSkulderKreditinstitut.year'
  ),
  'balanceSheet.OvrigaKortfristigaSkulderKreditinstitut.previousYear': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.OvrigaKortfristigaSkulderKreditinstitut.previousYear'
  ),
  'balanceSheet.SkulderKreditinstitutKortfristiga.year': id(
    'SkulderKreditinstitutKortfristiga.year'
  ),
  'balanceSheet.SkulderKreditinstitutKortfristiga.previousYear': id(
    'SkulderKreditinstitutKortfristiga.previousYear'
  ),
  'balanceSheet.ForskottFranKunder.year': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.ForskottFranKunder.year'
  ),
  'balanceSheet.ForskottFranKunder.previousYear': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.ForskottFranKunder.previousYear'
  ),
  'balanceSheet.PagaendeArbetenAnnansRakningKortfristigaSkulder.year': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.PagaendeArbetenAnnansRakningKortfristigaSkulder.year'
  ),
  'balanceSheet.PagaendeArbetenAnnansRakningKortfristigaSkulder.previousYear':
    id(
      'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.PagaendeArbetenAnnansRakningKortfristigaSkulder.previousYear'
    ),
  'balanceSheet.Leverantorsskulder.year': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.Leverantorsskulder.year'
  ),
  'balanceSheet.Leverantorsskulder.previousYear': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.Leverantorsskulder.previousYear'
  ),
  'balanceSheet.Vaxelskulder.year': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.Vaxelskulder.year'
  ),
  'balanceSheet.Vaxelskulder.previousYear': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.Vaxelskulder.previousYear'
  ),
  'balanceSheet.SkulderKoncernforetagKortfristiga.year': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.SkulderKoncernforetagKortfristiga.year'
  ),
  'balanceSheet.SkulderKoncernforetagKortfristiga.previousYear': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.SkulderKoncernforetagKortfristiga.previousYear'
  ),
  'balanceSheet.SkulderIntresseforetagGemensamtStyrdaForetagKortfristiga.year':
    id(
      'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.SkulderIntresseforetagGemensamtStyrdaForetagKortfristiga.year'
    ),
  'balanceSheet.SkulderIntresseforetagGemensamtStyrdaForetagKortfristiga.previousYear':
    id(
      'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.SkulderIntresseforetagGemensamtStyrdaForetagKortfristiga.previousYear'
    ),
  'balanceSheet.SkulderOvrigaForetagAgarintresseKortfristiga.year': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.SkulderOvrigaForetagAgarintresseKortfristiga.year'
  ),
  'balanceSheet.SkulderOvrigaForetagAgarintresseKortfristiga.previousYear': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.SkulderOvrigaForetagAgarintresseKortfristiga.previousYear'
  ),
  'balanceSheet.Skatteskulder.year': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.Skatteskulder.year'
  ),
  'balanceSheet.Skatteskulder.previousYear': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.Skatteskulder.previousYear'
  ),
  'balanceSheet.OvrigaKortfristigaSkulder.year': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.OvrigaKortfristigaSkulder.year'
  ),
  'balanceSheet.OvrigaKortfristigaSkulder.previousYear': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.OvrigaKortfristigaSkulder.previousYear'
  ),
  'balanceSheet.FaktureradEjUpparbetadIntakt.year': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.FaktureradEjUpparbetadIntakt.year'
  ),
  'balanceSheet.FaktureradEjUpparbetadIntakt.previousYear': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.FaktureradEjUpparbetadIntakt.previousYear'
  ),
  'balanceSheet.UpplupnaKostnaderForutbetaldaIntakter.year': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.UpplupnaKostnaderForutbetaldaIntakter.year'
  ),
  'balanceSheet.UpplupnaKostnaderForutbetaldaIntakter.previousYear': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulderAbstract.UpplupnaKostnaderForutbetaldaIntakter.previousYear'
  ),
  'balanceSheet.KortfristigaSkulder.year': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulder.year'
  ),
  'balanceSheet.KortfristigaSkulder.previousYear': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulderAbstract.KortfristigaSkulder.previousYear'
  ),
  'balanceSheet.EgetKapitalSkulder.year': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulder.year'
  ),
  'balanceSheet.EgetKapitalSkulder.previousYear': id(
    'balanceSheet.section.equityAndLiabilities.EgetKapitalSkulder.previousYear'
  ),
});

/**
 * Convert a snake case string to camel case
 * @param name the snake case string to you want to convert to camel case
 * @returns a camel case version
 */
export const snakeToCamel = (name: string): string =>
  name.replace(/([_][a-z])/g, (group) => group.toUpperCase().replace('_', ''));

/**
 * Convert a camel case string to snake case
 * @param name the camel case string you want to be in snake case
 * @returns a snake case string
 */
export const camelToSnake = (name: string): string =>
  name.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

import { isFinancialYear } from '@agoy/common';
import {
  ApiReturnType,
  asResultClass,
  getApiSdk,
  isApiErrorType,
} from 'api-sdk';
import { AccountingBalancesResult } from 'types/Accounting';
import { getContext } from 'utils/AgoyAppClient/contextHolder';

export const getAccountingBalances = async (
  clientId: string,
  financialYear: string
): Promise<AccountingBalancesResult | null> => {
  if (!isFinancialYear(financialYear)) {
    throw new Error(`Wrong format of financialYear (${financialYear})`);
  }
  const sdk = getApiSdk(getContext());

  const response = await asResultClass(
    sdk.getAccountingBalances({ clientid: clientId, financialYear })
  );

  if (response.ok) {
    return { accountingBalances: response.val, updatedAt: Date.now() };
  }

  const errValue = response.val;
  if (isApiErrorType(errValue) && errValue.status === 404) {
    return null;
  }
  return Promise.reject(errValue);
};

export const getTransactions = async (
  clientId: string,
  periodId: number[],
  accountNumber: string,
  offset?: number,
  limit?: number
): Promise<ApiReturnType<'getTransactions'>> => {
  const sdk = getApiSdk(getContext());
  const res = await asResultClass(
    sdk.getTransactions({
      clientid: clientId,
      accountNumber,
      periodId,
      offset,
      limit,
    })
  );

  if (res.err) {
    throw new Error(`Error fetching transactions for client: ${clientId}`);
  }

  return res.val;
};

import React from 'react';
import { Route, useRouteMatch } from 'react-router-dom';
import styled from '@emotion/styled';

import Checklists from './Checklists';
import EditChecklist from './EditChecklist';
import CreateChecklist from './CreateChecklist';
import ChecklistDetails from './ChecklistDetails';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ChecklistsRoutes = (): JSX.Element => {
  const { path } = useRouteMatch();

  return (
    <Container>
      <Route path={`${path}`} exact>
        <Checklists />
      </Route>
      <Route path={`${path}/create`} exact>
        <CreateChecklist />
      </Route>
      <Route path={`${path}/details/:checklistId`} exact>
        <ChecklistDetails />
      </Route>
      <Route path={`${path}/edit/:checklistId`} exact>
        <EditChecklist />
      </Route>
    </Container>
  );
};

export default ChecklistsRoutes;

import { mapRecord } from '@agoy/common';
import { TimePeriod, Values, RelatedClientResolver } from '@agoy/document';
import { asResultClass, getApiSdk } from 'api-sdk';
import { Observable, ReplaySubject } from 'rxjs';

type ApiSdk = Pick<
  ReturnType<typeof getApiSdk>,
  'getClientInformation' | 'getClientRelations'
>;

/**
 * FE implementation of the RelatedClientResolver that gathers
 * information about a related client for a document.
 */
class WebRelatedClientResolver implements RelatedClientResolver {
  sdk: ApiSdk;

  clientId: string;

  relatedClients: Record<string, string>;

  financialYear: string;

  /**
   *
   * @param sdk
   * @param clientId
   * @param relatedClients A mapping of relation type to clientId.
   */
  constructor(
    sdk: ApiSdk,
    clientId: string,
    relatedClients: Record<string, string>,
    financialYear: string
  ) {
    this.sdk = sdk;
    this.clientId = clientId;
    this.relatedClients = relatedClients;
    this.financialYear = financialYear;
  }

  resolve(
    name: string,
    information: 'clientInformation' | 'ownership'
  ): Observable<Values> {
    const subject = new ReplaySubject<Values>(1);

    switch (information) {
      case 'clientInformation':
        {
          const relatedClientId = this.relatedClients[name];
          if (relatedClientId) {
            asResultClass(
              this.sdk.getClientInformation({ clientid: relatedClientId })
            )
              .then((result) => {
                if (result.err) {
                  subject.error(result);
                } else {
                  subject.next(
                    mapRecord(
                      result.val,
                      (field): string | number | boolean | undefined => {
                        switch (typeof field.value) {
                          case 'string':
                          case 'number':
                          case 'boolean':
                            return field.value;
                          default:
                            // No support for array types
                            return undefined;
                        }
                      }
                    )
                  );
                }
              })
              .catch((error) => subject.error(error));
          } else {
            subject.next({});
          }
        }
        break;

      case 'ownership':
        {
          const relatedClientId = this.relatedClients[name];
          if (relatedClientId) {
            asResultClass(
              this.sdk.getClientRelations({
                clientid: this.clientId,
                startOf: TimePeriod.fromFinancialYear(this.financialYear)
                  .startDateISO,
              })
            )
              .then((result) => {
                if (result.err) {
                  subject.error(result);
                } else {
                  const relation = result.val.find(
                    (item) => item.orgId === relatedClientId
                  );
                  if (!relation) {
                    subject.next({});
                  } else {
                    subject.next({
                      shareTotal: relation.stockTotals?.[0]?.shareTotal,
                      ownedShares: relation.ownedShares,
                    });
                  }
                }
              })
              .catch((error) => {
                subject.error(error);
              });
          } else {
            subject.next({});
          }
        }
        break;
      default:
        subject.next({});
    }
    return subject;
  }
}

export default WebRelatedClientResolver;

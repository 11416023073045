import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Checkbox, Typography } from '@material-ui/core';
import { BooleanCell } from '@agoy/document';
import ServiceContext from '../../Context/TableServiceContext';

interface CheckboxProps {
  className?: string;
  cellId: string;
  cell: BooleanCell;
  editing?: boolean;
  active?: boolean;
}

const CheckboxWrapper = (props: CheckboxProps) => {
  const { cell, cellId } = props;

  const checked = useMemo(() => {
    return cell.value;
  }, [cell.value]);

  const service = useContext(ServiceContext);

  const handleSelect = useCallback(() => {
    service?.updateCellValue(cellId, {
      ...cell,
      value: !checked,
    });
  }, [service, cellId, cell, checked]);

  return <Checkbox checked={checked} onChange={handleSelect} />;
};

const CheckboxSelector = (props: CheckboxProps) => {
  let value;
  if (typeof props.cell.value === 'boolean') {
    value = props.cell.value ? 'Ja' : 'Nej';
  }
  return props.editing ? (
    <CheckboxWrapper {...props} />
  ) : (
    <Typography className={props.className}>{value}</Typography>
  );
};

export default CheckboxSelector;

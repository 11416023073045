enum PersonActionTypes {
  ADD_PERSON = 'persons/ADD_PERSON',
  UPDATE_PERSON = 'persons/UPDATE_PERSON',
  DELETE_PERSON = 'persons/DELETE_PERSON',
  POPULATE_PERSONS = 'persons/POPULATE_PERSONS',
  UPDATE_PERSONS_PROGRAM_STATUSES = 'persons/UPDATE_PERSONS_PROGRAM_STATUSES',
  SET_CONNECTIONS_TO_COMPANIES = 'persons/SET_CONNECTIONS_TO_COMPANIES',
  DELETE_CONNECTION = 'persons/DELETE_CONNECTION',
  SET_PERSON_CHECKLISTS = 'persons/SET_PERSON_CHECKLISTS',
}

export default PersonActionTypes;

import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { booleanValue } from '@agoy/document';
import { FormattingContextProvider } from '@agoy/formatting';
import { CashFlowStatementGeneral } from '@agoy/annual-report-document';
import Page from '_shared/components/PrintedDocument/Page';
import CashFlowStatementPreview from './Parts/CashFlowStatement/CashFlowStatementPreview';
import BalanceSheetPreview from './Parts/BalanceSheet/BalanceSheetPreview';
import IncomeStatementPreview from './Parts/IncomeStatement/IncomeStatementPreview';
import { SettingsContextProvider } from './Context/SettingsContext';
import PeriodIndexPage from './UI/Print/PeriodIndexPage';
import useAnnualReport from './useAnnualReport';
import useDocumentConfiguration from './useDocumentConfiguration';

interface PeriodReportPrintProps {
  pageNumbers: Record<string, number>;
}

const PeriodReportPrint = ({
  pageNumbers,
}: PeriodReportPrintProps): JSX.Element | null => {
  const { path } = useRouteMatch();
  const annualReport = useAnnualReport();
  const reportType = useDocumentConfiguration()?.reportType;

  if (!annualReport || !reportType) {
    return null;
  }

  const { incomeStatement, balanceSheet, settings, version } = annualReport;

  const getCashflowStatement = (): CashFlowStatementGeneral | undefined => {
    if ('cashFlowStatement' in annualReport) {
      if (
        (annualReport.version === '1' &&
          annualReport.cashFlowStatement.section.active) ||
        (annualReport.version === '2' &&
          annualReport.settings.section.cashFlowStatementActive.value)
      ) {
        return annualReport.cashFlowStatement;
      }
    }
    return undefined;
  };

  return (
    <SettingsContextProvider settings={settings}>
      <FormattingContextProvider
        displayInThousands={booleanValue(
          annualReport.settings.section.formatting.displayInThousands
        )}
      >
        <Switch>
          <Route path={`${path}/index`}>
            <PeriodIndexPage
              reportType={reportType}
              pageNumbers={pageNumbers}
              annualReport={annualReport}
            />
          </Route>
          <Route path={`${path}/incomeStatement`}>
            <Page>
              <IncomeStatementPreview
                incomeStatement={incomeStatement}
                settings={settings}
                version={version}
              />
            </Page>
          </Route>
          <Route path={`${path}/balanceSheet`}>
            <Page>
              <BalanceSheetPreview
                balanceSheet={balanceSheet}
                settings={settings}
                version={version}
              />
            </Page>
          </Route>
          <Route path={`${path}/cashFlowStatement`}>
            {'cashFlowStatement' in annualReport && (
              <Page>
                <CashFlowStatementPreview
                  cashFlowStatement={getCashflowStatement()}
                  version={version}
                  settings={settings}
                />
              </Page>
            )}
          </Route>
          <Route path={path} exact>
            <PeriodIndexPage
              reportType={reportType}
              pageNumbers={pageNumbers}
              annualReport={annualReport}
            />
            <Page>
              <IncomeStatementPreview
                version={version}
                incomeStatement={incomeStatement}
                settings={settings}
              />
            </Page>
            <Page>
              <BalanceSheetPreview
                balanceSheet={balanceSheet}
                settings={settings}
                version={version}
              />
            </Page>
            {'cashFlowStatement' in annualReport &&
              'cashFlowStatement' in pageNumbers && (
                <Page>
                  <CashFlowStatementPreview
                    cashFlowStatement={getCashflowStatement()}
                    version={version}
                    settings={settings}
                  />
                </Page>
              )}
          </Route>
        </Switch>
      </FormattingContextProvider>
    </SettingsContextProvider>
  );
};

export default PeriodReportPrint;

import {
  TaxDeclarationFormDefinition,
  TaxDeclarationFormDataConfig,
} from '../../types';
import { ref, value, id, ifOrElse, not, sumAllowNull } from '@agoy/document';
import { mapFormId, toInitial, toStructure } from '../forms-util';

const configN3A = (
  form: TaxDeclarationFormDefinition,
  financialYearStart: string,
  financialYearEnd: string
): TaxDeclarationFormDataConfig => {
  const N3A = mapFormId(form, 'N3A');
  const initial = {
    N3A: {
      partType: 'form' as const,
      id: N3A,
      fields: {
        date: value(''),
        passiveBusiness: value(false),
        partnerName: value(''),
        partnerSocSecNr: value(''),
        firmName: value(''),
        firmOrgNr: value(''),
        isForeignCompany: value(false),
        '1_pos': value(undefined),
        '2_neg': value(undefined),
        '3_pos': value(undefined),
        '4_pos': value(undefined),
        '5_a_pos': value(undefined),
        '5_b_neg': value(undefined),
        '6_a_pos': value(undefined),
        '6_b_neg': value(undefined),
        '7_pos': ref(
          sumAllowNull(id('K15B-*.K15B.derivedFields.A_huvudregel_vinst'))
        ),
        '8_neg': value(undefined),
        '9_neg': ref(
          sumAllowNull(
            id('N4-*.N4.fields.A_2_andel'),
            id('N4-*.N4.fields.A_3_tidigare_uppskov')
          )
        ),
        '10_pos': ref(
          sumAllowNull(
            id('N4-*.N4.fields.A_4_uppskov_som_ska_aterforas'),
            id('N4-*.N4.fields.B_1_uppskov_som_ska_aterforas')
          )
        ),
        '11': value(undefined),
        '12_neg': value(undefined),
        '13_neg': value(undefined),
        '14': value(undefined),
        '15_neg': value(undefined),
        '16_pos': value(undefined),
        '17_pos': value(undefined),
        '18': value(undefined),
        '19_neg': value(undefined),
        '20': value(undefined),
        '21_neg': value(undefined),
        '22_pos': value(undefined),
        '23_neg': value(undefined),
        '24_neg': value(undefined),
        '25_pos': value(undefined),
        '26_neg': value(undefined),
        '27': value(undefined),
        '28_neg': value(undefined),
        '29_pos': value(undefined),
        '30_surplus': value(undefined),
        '30_deficit': value(undefined),
        '31_a_pos': value(undefined),
        '31_b_pos': value(undefined),
        '32': value(undefined),
        '33_pos': value(undefined),
        '34_neg': value(undefined),
        '35_neg': value(undefined),
        '36_neg': value(undefined),
        '37_pos': value(undefined),
        '38_pos': value(undefined),
        '39': value(undefined),
        '40': value(undefined),
        '41': value(undefined),
        '42_pos': value(undefined),
        '43': value(undefined),
        '44': value(undefined),
        '45_neg': value(undefined),
        '46': value(undefined),
        '47_pos': value(undefined),
        '48_neg': value(undefined),
        '49_neg': value(undefined),
        '50_neg': value(undefined),
        '51_pos': value(undefined),
        '52_pos': value(undefined),
        '53': value(undefined),
        '54_neg': value(undefined),
        '55_pos': value(undefined),
        '56': value(undefined),
        '57_pos': value(undefined),
        '58': value(undefined),
        '59_pos': value(undefined),
        '60': value(undefined),
        '61_pos': value(undefined),
        '62': value(undefined),
        '63_neg': value(undefined),
        '64_pos': value(undefined),
        '65_pos': value(undefined),
        '66_pos': value(undefined),
        '67': value(undefined),
        '68_pos': value(undefined),
        '69_neg': value(undefined),
        '70_pos': value(undefined),
        '71_pos': value(undefined),
        '72_neg': value(undefined),
        '73_neg': value(undefined),
        '74_neg': value(undefined),
        '75_pos': value(undefined),
        '76_neg': value(undefined),
        '77_pos': value(undefined),
        '78_neg': value(undefined),
        '79': value(undefined),
        '80_neg': value(undefined),
        '81_pos': value(undefined),
        '82_pos': value(undefined),
        '83_neg': value(undefined),
        '83_a_neg': value(undefined),
        '84': value(undefined),
        '85': value(undefined),
        '86_pos': value(undefined),
        '87_pos': value(undefined),
        '88_neg': value(undefined),
        '89': value(undefined),
        '1_otherInfo': value(false),
      },
      derivedFields: {
        to_10_1: ref(
          ifOrElse(
            not(id('N3A.fields.passiveBusiness')),
            id('N3A.fields.30_surplus'),
            undefined
          )
        ),
        to_10_3_passiv_naringsverksamhet: ref(
          ifOrElse(
            id('N3A.fields.passiveBusiness'),
            id('N3A.fields.30_surplus'),
            undefined
          )
        ),
        to_10_2: ref(
          ifOrElse(
            not(id('N3A.fields.passiveBusiness')),
            id('N3A.fields.32'),
            undefined
          )
        ),
        to_10_4_passiv_naringsverksamhet: ref(
          ifOrElse(
            id('N3A.fields.passiveBusiness'),
            id('N3A.fields.32'),
            undefined
          )
        ),
        ovriga_uppgifter_overskott: ref(
          ifOrElse(
            id('N3A.fields.1_otherInfo'),
            id('N3A.fields.30_surplus'),
            undefined
          )
        ),
      },
      sru: {
        '7011': value(financialYearStart),
        '7012': value(financialYearEnd),
        '7021': ref(id('N3A.fields.passiveBusiness')),
        '7030': ref(id('N3A.fields.firmOrgNr')),
        '7050': value(false),
        '6500': ref(id('N3A.fields.1_pos')),
        '6600': ref(id('N3A.fields.2_neg')),
        '6501': ref(id('N3A.fields.3_pos')),
        '6502': ref(id('N3A.fields.4_pos')),
        '6503': ref(id('N3A.fields.5_a_pos')),
        '6601': ref(id('N3A.fields.5_b_neg')),
        '6504': ref(id('N3A.fields.6_a_pos')),
        '6602': ref(id('N3A.fields.6_b_neg')),
        '6505': ref(id('N3A.fields.7_pos')),
        '6603': ref(id('N3A.fields.8_neg')),
        '6604': ref(id('N3A.fields.9_neg')),
        '6506': ref(id('N3A.fields.10_pos')),
        '6605': ref(id('N3A.fields.12_neg')),
        '6606': ref(id('N3A.fields.13_neg')),
        '6609': ref(id('N3A.fields.15_neg')),
        '6509': ref(id('N3A.fields.16_pos')),
        '6510': ref(id('N3A.fields.17_pos')),
        '6610': ref(id('N3A.fields.19_neg')),
        '6611': ref(id('N3A.fields.21_neg')),
        '6513': ref(id('N3A.fields.22_pos')),
        '6612': ref(id('N3A.fields.23_neg')),
        '6613': ref(id('N3A.fields.24_neg')),
        '6514': ref(id('N3A.fields.25_pos')),
        '6614': ref(id('N3A.fields.26_neg')),
        '6615': ref(id('N3A.fields.28_neg')),
        '6516': ref(id('N3A.fields.29_pos')),
        '6520': ref(id('N3A.fields.30_surplus')),
        '6620': ref(id('N3A.fields.30_deficit')),
        '6521': ref(id('N3A.fields.31_a_pos')),
        '6522': ref(id('N3A.fields.31_b_pos')),
        '6621': ref(id('N3A.fields.32')),
        '6530': ref(id('N3A.fields.33_pos')),
        '6630': ref(id('N3A.fields.34_neg')),
        '6631': ref(id('N3A.fields.35_neg')),
        '6632': ref(id('N3A.fields.36_neg')),
        '6531': ref(id('N3A.fields.37_pos')),
        '6532': ref(id('N3A.fields.38_pos')),
        '6533': ref(id('N3A.fields.39')),
        '6534': ref(id('N3A.fields.40')),
        '6633': ref(id('N3A.fields.41')),
        '6535': ref(id('N3A.fields.42_pos')),
        '6536': ref(id('N3A.fields.43')),
        '6634': ref(id('N3A.fields.44')),
        '6635': ref(id('N3A.fields.45_neg')),
        '6537': ref(id('N3A.fields.46')),
        '6540': ref(id('N3A.fields.47_pos')),
        '6640': ref(id('N3A.fields.48_neg')),
        '6641': ref(id('N3A.fields.49_neg')),
        '6642': ref(id('N3A.fields.50_neg')),
        '6541': ref(id('N3A.fields.51_pos')),
        '6542': ref(id('N3A.fields.52_pos')),
        '6543': ref(id('N3A.fields.53')),
        '6650': ref(id('N3A.fields.54_neg')),
        '6550': ref(id('N3A.fields.55_pos')),
        '6551': ref(id('N3A.fields.57_pos')),
        '6552': ref(id('N3A.fields.59_pos')),
        '6553': ref(id('N3A.fields.61_pos')),
        '6654': ref(id('N3A.fields.62')),
        '6660': ref(id('N3A.fields.63_neg')),
        '6560': ref(id('N3A.fields.64_pos')),
        '6561': ref(id('N3A.fields.65_pos')),
        '6562': ref(id('N3A.fields.66_pos')),
        '6661': ref(id('N3A.fields.67')),
        '6570': ref(id('N3A.fields.68_pos')),
        '6670': ref(id('N3A.fields.69_neg')),
        '6571': ref(id('N3A.fields.70_pos')),
        '6572': ref(id('N3A.fields.71_pos')),
        '6671': ref(id('N3A.fields.72_neg')),
        '6672': ref(id('N3A.fields.73_neg')),
        '6673': ref(id('N3A.fields.74_neg')),
        '6573': ref(id('N3A.fields.75_pos')),
        '6674': ref(id('N3A.fields.76_neg')),
        '6574': ref(id('N3A.fields.77_pos')),
        '6675': ref(id('N3A.fields.78_neg')),
        '6575': ref(id('N3A.fields.79')),
        '6676': ref(id('N3A.fields.80_neg')),
        '6576': ref(id('N3A.fields.81_pos')),
        '6577': ref(id('N3A.fields.82_pos')),
        '6677': ref(id('N3A.fields.83_neg')),
        '6580': ref(id('N3A.fields.84')),
        '6680': ref(id('N3A.fields.85')),
        '6590': ref(id('N3A.fields.86_pos')),
        '6591': ref(id('N3A.fields.87_pos')),
        '6691': ref(id('N3A.fields.88_neg')),
        '6599': ref(id('N3A.fields.89')),
        '8081': ref(id('N3A.fields.1_otherInfo')),
      },
    },
  };
  return {
    initial: toInitial(initial, form),
    definition: toStructure(initial, form),
  };
};

export default configN3A;

import React from 'react';
import { Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import styled from '@emotion/styled';
// @ts-ignore
import { ReactComponent as MaintenanceImage } from 'assets/maintenance.svg';

const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
`;

const Image = styled(MaintenanceImage)`
  width: 400px;
  height: auto;
  padding-bottom: 60px;
`;

const Title = styled(Typography)`
  width: 700px;
  text-align: center;
`;

const LicenseErrorPage = (): JSX.Element => {
  const { formatMessage } = useIntl();

  return (
    <Content>
      <Image title="" />
      <Title>{formatMessage({ id: 'customersview.license.error' })}</Title>
    </Content>
  );
};

export default LicenseErrorPage;

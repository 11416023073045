import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import styled from '@emotion/styled';
import config from '_shared/services/config';
import LoadingPlaceholder from '_shared/components/LoadingPlaceholder';
import { activeFeatureFlags } from '_shared/HOC/withFeatureFlags';
import { isFinancialYear } from '@agoy/common';
import { useIntl } from 'react-intl';
import { Typography, Box } from '@material-ui/core';
import { setUserLicense } from '_payment/licenses/useLicense';
import { DefaultService } from '@agoy/api-sdk-core';
import { batch, useDispatch } from 'react-redux';
import { addGlobalMessage } from 'redux/actions';
import { reformat } from '@agoy/dates';
import MaintenancePage from '_clients/components/MaintenancePage';
import { asResultClass, getApiSdk } from '../../api-sdk';
import { createJwtContext } from '../../utils/AgoyAppClient/AgoyAppClientContext';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const ErrorMessages = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
`;

type FortnoxJWTResponse = Awaited<
  ReturnType<typeof DefaultService.agoyFortnox>
>;

/**
 * Sync backend with Fortnox data and returns the clientId.
 * Verify the query params, and initalize the BE data to start working on Agoy.
 */
const verify = async (params: string): Promise<FortnoxJWTResponse> => {
  const existingJwt = window.localStorage.getItem('agoyJwt');

  const response = await fetch(
    `${config.appEndpoint}/v1/fn/fortnox-jwt${params}`,
    // If there is an existing JWT saved in local storage, attach it to headers in order for the
    // backend to not invalidate session on the same computer
    { ...(existingJwt && { headers: { 'agoy-jwt': existingJwt } }) }
  );
  if (response.ok) {
    const { agoyJwt, clientId, clientType, skippedClients } =
      await response.json();

    return { agoyJwt, clientId, clientType, skippedClients };
  }
  if (response.headers.get('Content-Type')?.includes('json')) {
    const { code } = await response.json();
    throw new Error(code);
  }
  throw new Error('Unexpected error');
};

const mapProgram = (fortnoxProgram: string, financialYear: string): string => {
  switch (fortnoxProgram) {
    case 'REC':
      return `reconciliation/${financialYear}`;
    case 'RTC':
      return `${financialYear}/financialreport`;
    case 'PEC':
    case 'FSA':
    case 'QFA':
      return `${financialYear}/tax`;
    case 'DEC':
    case 'FAR':
    case 'SAA':
      return `${financialYear}/year`;
    case 'EPD':
    case 'QID':
    case 'EPA':
    case 'IIT':
      return `${financialYear}/taxdeclaration`;
    default:
      return `${financialYear}/unknown`;
  }
};

async function getRedirectProgramParams(
  params: URLSearchParams,
  tenantType: string | null,
  clientId: string
) {
  let financialYearEnd = params.get('financialYearEnd');
  let financialYearStart = params.get('financialYearStart');
  let program = params.get('program');

  // Redirect company tenants to the reconciliation view if the user is not
  // already redirected to a specific program from Fortnox side.
  if (
    tenantType === 'company' &&
    !(financialYearEnd && financialYearStart && program)
  ) {
    try {
      const context = createJwtContext();
      const sdk = getApiSdk(context);
      const result = await asResultClass(
        sdk.getClientById({ clientid: clientId })
      );

      if (result.ok && 'client' in result.val) {
        const { financialYears } = result.val.client;
        if (financialYears?.length) {
          const lastFinancialYear = financialYears.sort().reverse()[0];
          financialYearStart = lastFinancialYear.start;
          financialYearEnd = lastFinancialYear.end;
          program = 'REC';
        }
      }
    } catch (error) {
      console.error(
        'Error while fetching financial years for redirection',
        error
      );
    }
  }
  return { financialYearEnd, financialYearStart, program };
}

const getRedirectUrl = async (
  clientId?: string,
  clientType: 'C' | 'P' = 'C',
  params?: URLSearchParams
): Promise<string> => {
  if (clientType === 'C' && clientId && params) {
    const tenantType = params.get('tenantType');
    const { financialYearEnd, financialYearStart, program } =
      await getRedirectProgramParams(params, tenantType, clientId);

    if (financialYearEnd && financialYearStart && program) {
      const financialYear = [financialYearStart, financialYearEnd]
        .map((date) => reformat(date, 'yyyy-MM-dd', 'yyyyMMdd'))
        .join('-');
      if (isFinancialYear(financialYear)) {
        return `/clients/${clientId}/${mapProgram(program, financialYear)}`;
      }
      console.error('Invalid financial year', financialYear);
    }
    return `/clients/${clientId}/`;
  }
  if (clientType === 'P' && clientId) {
    return `/persons/${clientId}/taxdeclaration`;
  }
  return '/';
};

/**
 * Let us do a redirect from fortnox.test.agoy.se to localhost when doing a login from Fortnox
 */
const testLocalhost = () => {
  return (
    config.runningEnvironment === 'test' &&
    activeFeatureFlags.get('test_fortnoxLoginLocalhost') === true
  );
};

/**
 * Takes of redirect handshake from Fnox, the BE creates the Org, clients and user (etc).
 *
 */
const FortnoxRedirectReceiver = () => {
  const [verifying, setVerifying] = useState(false);
  const [errorId, setError] = useState<string | null>(null);
  const history = useHistory();
  const intl = useIntl();
  const dispatch = useDispatch();
  const errorIds = {
    AUTH_CODE_INCORRECT: 'fortnox.whitelabel.auth.codeNotValid',
    CODE_NOT_VALID: 'fortnox.whitelabel.auth.codeNotValid',
    ERROR_SYNCING_CLIENTS: 'fortnox.whitelabel.auth.errorSyncingClients',
    ORG_HAS_NO_ORG_NR: 'fortnox.whitelabel.auth.orgNoOrgNr',
    AUTH_CODE_ALREADY_USED: 'fortnox.whitelabel.auth.codeused',
    USER_CONNECTED_TO_OTHER_ORGANISATION:
      'fortnox.whitelabel.auth.userInOtherOrg',
    SUPPORT_NO_ORG: 'fortnox.whitelabel.auth.supportNoOrg',
    REQUESTED_CLIENT_MISSING_ORGNUMBER: 'fortnox.whitelabel.auth.nullOrgNumber',
    ORG_TYPE_DOES_NOT_MATCH: 'fortnox.whitelabel.auth.orgTypeDoesNotMatch',
  };

  useEffect(() => {
    if (testLocalhost()) {
      window.location.href = `http://localhost:3000/fn-login${window.location.search}`;
      return;
    }
    const params = new URLSearchParams(window.location.search);
    if (!(params.get('code') && params.get('mac'))) {
      return;
    }
    setVerifying(true);
    (async () => {
      const license = params.get('license');

      if (license === 'AVSTAMNING') setUserLicense('small');
      if (license === 'BOKSLUTSKATT') setUserLicense('big');

      try {
        const { clientId, agoyJwt, clientType, skippedClients } = await verify(
          window.location.search
        );

        if (agoyJwt) {
          window.localStorage.setItem('agoyJwt', agoyJwt);
        }

        history.replace(
          await getRedirectUrl(clientId?.[0], clientType, params)
        );

        if (skippedClients && skippedClients.length > 0) {
          batch(() => {
            skippedClients.forEach((client) => {
              dispatch(
                addGlobalMessage(
                  'warning',
                  undefined,
                  intl.formatMessage(
                    { id: 'fortnox.whitelabel.skipped' },
                    { client }
                  )
                )
              );
            });
          });
        }
      } catch (err) {
        setVerifying(false);
        setError(err.message);
      }
    })();
  }, [history, intl]);

  return (
    <Wrapper>
      {verifying && <LoadingPlaceholder />}
      {errorId && errorIds.hasOwnProperty(errorId) && (
        <ErrorMessages>
          <Typography component="div">
            <Box fontWeight="fontWeightBold">
              {intl.formatMessage({ id: errorIds[errorId] })}
            </Box>
          </Typography>
          <Box>
            <Typography>
              {intl.formatMessage({ id: 'fortnox.whitelabel.auth.tryAgain' })}
            </Typography>
          </Box>
        </ErrorMessages>
      )}
      {errorId && !errorIds.hasOwnProperty(errorId) && <MaintenancePage />}
    </Wrapper>
  );
};

export default FortnoxRedirectReceiver;

import React from 'react';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';

import { useSelector } from 'redux/reducers';
import { FinancialReport } from '@agoy/financial-report-document';
import { numberValue, stringValue } from '@agoy/document';

import Typography from '@material-ui/core/Typography';

// @ts-ignore
import { ReactComponent as ShapesImage } from 'assets/agoyShapes.svg';
import Logo from '_shared/components/Logo';
import ReportTitle from './ReportTitle';
import { prepareReportTitle } from './utils';

type ReportColorsProps = {
  customColor: string | undefined;
};

const A4Page = styled.div`
  position: relative;
  display: flex;
  height: 940px;
  width: 1400px;
  padding: 40px 0 80px;
`;

const PageInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 50%;
  justify-content: space-between;
`;

const OrganisationInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const OrganisationTitle = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'customColor',
})<ReportColorsProps>`
  margin-top: 1rem;
  margin-bottom: 0;
  font-size: 3rem;
  color: ${(props) => props.customColor || props.theme.palette.text.primary};
`;

const StyledTitle = styled(ReportTitle)`
  font-size: 55px;
`;

const PictureWrapper = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  width: 50%;
`;

const StyledShapesImage = styled(ShapesImage, {
  shouldForwardProp: (prop) => prop !== 'customColor',
})<ReportColorsProps>`
  width: 100%;
  fill: ${(props) =>
    props.customColor ||
    props.theme.palette.financialReportView.accentBackground};
`;

type FrontPageProps = {
  frontPage: FinancialReport['frontPage'] | undefined;
  settings: FinancialReport['settings'];
};

const FrontPagePreview = ({
  frontPage,
  settings,
}: FrontPageProps): JSX.Element => {
  const { formatMessage } = useIntl();

  const customerId = useSelector((state) => state.customerView.currentCustomer);
  const customer = useSelector((state) =>
    customerId ? state.customers[customerId] : undefined
  );
  const organisationName = useSelector(
    (state) => state.organisation.name || ''
  );
  const organisationLogo =
    useSelector((state) => state.organisation.logo) || '';
  const { currentPeriod, currentFinancialYear } = useSelector(
    (state) => state.customerView
  );
  const clientLogo = customer?.logo || '';

  let reportTitle =
    stringValue(frontPage?.section.title) ||
    `${formatMessage({
      id: 'financialreport.frontpagereport',
    })} ${currentPeriod} för ${customer?.name || ''}`;

  reportTitle = prepareReportTitle(
    reportTitle,
    currentFinancialYear,
    currentPeriod,
    numberValue(settings.section.comparePeriod) || 1
  );
  return (
    <A4Page>
      <PageInfo>
        <StyledTitle
          reportTitle={reportTitle}
          isEditing={false}
          customColor={stringValue(settings.section.colorTextMain)}
        />
        <OrganisationInfo>
          <Logo
            style={{
              display: 'flex',
              alignSelf: 'center',
              height: '230px',
              width: '230px',
            }}
            fallbackIconStyle={{ height: '70px', width: '70px' }}
            src={settings.section.useClientLogo ? clientLogo : organisationLogo}
          />
          <OrganisationTitle
            variant="h2"
            customColor={stringValue(settings.section.colorTextMain)}
          >
            {organisationName}
          </OrganisationTitle>
        </OrganisationInfo>
      </PageInfo>
      <PictureWrapper>
        <StyledShapesImage
          customColor={stringValue(settings.section.colorBackground)}
        />
      </PictureWrapper>
    </A4Page>
  );
};

export default FrontPagePreview;

import React from 'react';
import styled from '@emotion/styled';
import { Typography, Button } from '@material-ui/core';

import { useIntl } from 'react-intl';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-bottom: ${(props) => props.theme.spacing(3)}px;
`;

type Props = {
  handleManageBilling: () => Promise<void>;
};

const Page = ({ handleManageBilling }: Props) => {
  const { formatMessage } = useIntl();

  return (
    <Wrapper>
      <Typography variant="h3">
        {formatMessage({ id: 'manage.billing' })}
      </Typography>
      <Button
        onClick={() => handleManageBilling()}
        variant="contained"
        color="secondary"
        disableElevation
      >
        {formatMessage({ id: 'manage' })}
      </Button>
    </Wrapper>
  );
};

export default Page;

import { ref, table, value } from '@agoy/document';
import { NoteOneTableSection } from '../../../../common';
import {
  field,
  ixbrlCell,
  ixbrlField,
  range,
} from '../../../../common/utils/util';
import {
  sumAccountsIBs,
  sumPositiveTransactions,
  sumNegativeTransactions,
  sumNotesRowsById,
} from '../../../../common/utils/accountSumsUtil';
import { year, previousYear } from '../../../../common/utils/date-util';
import { NoteAccountRanges } from '../types';

const MONETARY_ITEM_TYPE = 'monetaryItemType';

export const note6Accounts: NoteAccountRanges[] = [
  {
    rowId: '1',
    ranges: [
      [1020, 1027],
      [1030, 1037],
      [1040, 1047],
      [1050, 1057],
    ],
    cellLabel: 'Ingående anskaffningsvärden',
    action: 'sumAccountsIBs',
  },
  {
    rowId: '2',
    ranges: [
      [1020, 1027],
      [1030, 1037],
      [1040, 1047],
      [1050, 1057],
    ],
    cellLabel: 'Inköp',
    action: 'sumPositiveTransactions',
  },
  {
    rowId: '3',
    ranges: [
      [1020, 1027],
      [1030, 1037],
      [1040, 1047],
      [1050, 1057],
    ],
    cellLabel: 'Försäljningar/utrangeringar',
    action: 'sumNegativeTransactions',
  },
  {
    rowId: '6',
    ranges: [[1029], [1039], [1049], [1059]],
    cellLabel: 'Ingående avskrivningar',
    action: 'sumAccountsIBs',
  },
  {
    rowId: '7',
    ranges: [[1029], [1039], [1049], [1059]],
    cellLabel: 'Försäljningar/utrangeringar',
    action: 'sumPositiveTransactions',
  },
  {
    rowId: '9',
    ranges: [[1029], [1039], [1049], [1059]],
    cellLabel: 'Årets avskrivningar',
    action: 'sumNegativeTransactions',
  },
  {
    rowId: '11',
    ranges: [[1028], [1038], [1048], [1058]],
    cellLabel: 'Ingående nedskrivningar',
    action: 'sumAccountsIBs',
  },
  {
    rowId: '12',
    ranges: [[1028], [1038], [1048], [1058]],
    cellLabel: 'Försäljningar/utrangeringar',
    action: 'sumPositiveTransactions',
  },
  {
    rowId: '15',
    ranges: [[1028], [1038], [1048], [1058]],
    cellLabel: 'Årets nedskrivningar',
    action: 'sumNegativeTransactions',
  },
];

export const getNote6 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null,
  documentTypeVersion = '1'
): NoteOneTableSection => {
  return {
    active: false,
    number: value(undefined),
    name: value(
      'Koncessioner, patent, licenser, varumärken samt liknande rättigheter'
    ),
    table: table(
      `notes.note6.table`,
      { id: 'label', label: '' },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      // 'ingående' = previous period outgoing balance
      .addRows((rows) =>
        rows
          .addRow(
            '1',
            noteLabel('Ingående anskaffningsvärden'),
            ixbrlCell(
              ref(
                sumAccountsIBs('1020:1027+1030:1037+1040:1047+1050:1057', false)
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterAnskaffningsvarden',
                contextRef: 'balans1',
                saldo: 'debit',
              }
            ),
            ixbrlCell(
              ref(
                sumAccountsIBs(
                  '1020:1027+1030:1037+1040:1047+1050:1057',
                  false,
                  'previousYear'
                )
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterAnskaffningsvarden',
                contextRef: 'balans2',
                saldo: 'debit',
              }
            )
          )
          .addRow(
            '2',
            noteLabel('Inköp'),
            ixbrlCell(
              ref(
                sumPositiveTransactions(
                  '1020:1027+1030:1037+1040:1047+1050:1057'
                )
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringAnskaffningsvardenInkop',
                contextRef: 'period0',
                standardRubrik:
                  'Ökning av anskaffningsvärden i samband med inköp av koncessioner, patent, licenser, varumärken samt liknande rättigheter',
              }
            ),
            ixbrlCell(
              ref(
                sumPositiveTransactions(
                  '1020:1027+1030:1037+1040:1047+1050:1057',
                  'previousYear'
                )
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringAnskaffningsvardenInkop',
                contextRef: 'period1',
                standardRubrik:
                  'Ökning av anskaffningsvärden i samband med inköp av koncessioner, patent, licenser, varumärken samt liknande rättigheter',
              }
            )
          )
          .addRow(
            'businessAcquisitions',
            noteLabel('Rörelseförvärv'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringAnskaffningsvardenRorelseforvarv',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden för koncessioner, patent, licenser, varumärken samt liknande rättigheter genom rörelseförvärv',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringAnskaffningsvardenRorelseforvarv',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden för koncessioner, patent, licenser, varumärken samt liknande rättigheter genom rörelseförvärv',
            })
          )
          .addRow(
            'anskaffningsvardenFusion',
            noteLabel('Fusion'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringAnskaffningsvardenFusion',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden för koncessioner, patent, licenser, varumärken samt liknande rättigheter genom fusion',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringAnskaffningsvardenFusion',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden för koncessioner, patent, licenser, varumärken samt liknande rättigheter genom fusion',
            })
          )
          .addRow(
            '3',
            noteLabel('Försäljningar/utrangeringar'),
            ixbrlCell(
              ref(
                sumNegativeTransactions(
                  '1020:1027+1030:1037+1040:1047+1050:1057'
                )
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringAnskaffningsvardenForsaljningarUtrangeringar',
                contextRef: 'period0',
                standardRubrik:
                  'Minskning av anskaffningsvärden i samband med försäljningar eller utrangeringar av koncessioner, patent, licenser, varumärken samt liknande rättigheter',
                negateValue: true,
              }
            ),
            ixbrlCell(
              ref(
                sumNegativeTransactions(
                  '1020:1027+1030:1037+1040:1047+1050:1057',
                  'previousYear'
                )
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringAnskaffningsvardenForsaljningarUtrangeringar',
                contextRef: 'period1',
                standardRubrik:
                  'Minskning av anskaffningsvärden i samband med försäljningar eller utrangeringar av koncessioner, patent, licenser, varumärken samt liknande rättigheter',
                negateValue: true,
              }
            )
          )
          .addRow(
            '4',
            noteLabel('Omklassificeringar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringAnskaffningsvardenOmklassificeringar',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden i samband med omklassificeringar av koncessioner, patent, licenser, varumärken samt liknande rättigheter',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringAnskaffningsvardenOmklassificeringar',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden i samband med omklassificeringar av koncessioner, patent, licenser, varumärken samt liknande rättigheter',
            })
          )
          .addRow(
            'anskaffningsvardenConversionDifferences',
            noteLabel('Omräkningsdifferenser'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringAnskaffningsvardenOmrakningsdifferenser',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden i samband med omräkningsdifferenser av koncessioner, patent, licenser, varumärken samt liknande rättigheter',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringAnskaffningsvardenOmrakningsdifferenser',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden i samband med omräkningsdifferenser av koncessioner, patent, licenser, varumärken samt liknande rättigheter',
            })
          )
          .addRow(
            '5',
            noteLabel('Utgående anskaffningsvärden'),
            ixbrlCell(
              sumNotesRowsById(
                6,
                [
                  ...range(1, 5),
                  'anskaffningsvardenConversionDifferences',
                  'businessAcquisitions',
                  'anskaffningsvardenFusion',
                ],
                'year'
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterAnskaffningsvarden',
                contextRef: 'balans0',
                saldo: 'debit',
              }
            ),
            ixbrlCell(
              sumNotesRowsById(
                6,
                [
                  ...range(1, 5),
                  'anskaffningsvardenConversionDifferences',
                  'businessAcquisitions',
                  'anskaffningsvardenFusion',
                ],
                'previousYear'
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterAnskaffningsvarden',
                contextRef: 'balans1',
                saldo: 'debit',
              }
            )
          )
          .addRow(
            '6',
            noteLabel('Ingående avskrivningar'),
            ixbrlCell(ref(sumAccountsIBs('1029+1039+1049+1059', false)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterAvskrivningar',
              contextRef: 'balans1',
              saldo: 'credit',
              negateValue: true,
            }),
            ixbrlCell(
              ref(sumAccountsIBs('1029+1039+1049+1059', false, 'previousYear')),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterAvskrivningar',
                contextRef: 'balans2',
                saldo: 'credit',
                negateValue: true,
              }
            )
          )
          .addRow(
            'avskrivningarFusion',
            noteLabel('Fusion'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringAvskrivningarFusion',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av avskrivningar för koncessioner, patent, licenser, varumärken samt liknande rättigheter genom fusion',
              negateValue: true,
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringAvskrivningarFusion',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av avskrivningar för koncessioner, patent, licenser, varumärken samt liknande rättigheter genom fusion',
              negateValue: true,
            })
          )
          .addRow(
            '7',
            noteLabel('Försäljningar/utrangeringar'),
            ixbrlCell(ref(sumPositiveTransactions('1029+1039+1049+1059')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringAvskrivningarAterfordaAvskrivningarForsaljningarRespUtrangeringar',
              contextRef: 'period0',
            }),
            ixbrlCell(
              ref(
                sumPositiveTransactions('1029+1039+1049+1059', 'previousYear')
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringAvskrivningarAterfordaAvskrivningarForsaljningarRespUtrangeringar',
                contextRef: 'period1',
              }
            )
          )
          .addRow(
            '8',
            noteLabel('Omklassificeringar'), // no accounts
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringAvskrivningarOmklassificeringar',
              contextRef: 'period0',
              standardRubrik:
                'Minskning (ökning) av avskrivningar i samband med omklassificeringar av koncessioner, patent, licenser, varumärken samt liknande rättigheter',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringAvskrivningarOmklassificeringar',
              contextRef: 'period1',
              standardRubrik:
                'Minskning (ökning) av avskrivningar i samband med omklassificeringar av koncessioner, patent, licenser, varumärken samt liknande rättigheter',
            })
          )
          .addRow(
            '9',
            noteLabel('Årets avskrivningar'),
            ixbrlCell(ref(sumNegativeTransactions('1029+1039+1049+1059')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringAvskrivningarAretsAvskrivningar',
              contextRef: 'period0',
              negateValue: true,
            }),
            ixbrlCell(
              ref(
                sumNegativeTransactions('1029+1039+1049+1059', 'previousYear')
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringAvskrivningarAretsAvskrivningar',
                contextRef: 'period1',
                negateValue: true,
              }
            )
          )
          .addRow(
            'avskrivningarConversionDifferences',
            noteLabel('Omräkningsdifferenser'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringAvskrivningarOmrakningsdifferenser',
              contextRef: 'period0',
              standardRubrik:
                'Minskning (ökning) av avskrivningar i samband med omräkningsdifferenser av koncessioner, patent, licenser, varumärken samt liknande rättigheter',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringAvskrivningarOmrakningsdifferenser',
              contextRef: 'period1',
              standardRubrik:
                'Minskning (ökning) av avskrivningar i samband med omräkningsdifferenser av koncessioner, patent, licenser, varumärken samt liknande rättigheter',
            })
          )
          .addRow(
            '10',
            noteLabel('Utgående avskrivningar'),
            ixbrlCell(
              sumNotesRowsById(
                6,
                [
                  ...range(6, 10),
                  'avskrivningarFusion',
                  'avskrivningarConversionDifferences',
                ],
                'year'
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterAvskrivningar',
                contextRef: 'balans0',
                saldo: 'credit',
                negateValue: true,
              }
            ),
            ixbrlCell(
              sumNotesRowsById(
                6,
                [
                  ...range(6, 10),
                  'avskrivningarFusion',
                  'avskrivningarConversionDifferences',
                ],
                'previousYear'
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterAvskrivningar',
                contextRef: 'balans1',
                saldo: 'credit',
                negateValue: true,
              }
            )
          )
          .addRow(
            '11',
            noteLabel('Ingående nedskrivningar'),
            ixbrlCell(ref(sumAccountsIBs('1028+1038+1048+1058', false)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterNedskrivningar',
              contextRef: 'balans1',
              saldo: 'credit',
              negateValue: true,
            }),
            ixbrlCell(
              ref(sumAccountsIBs('1028+1038+1048+1058', false, 'previousYear')),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterNedskrivningar',
                contextRef: 'balans2',
                saldo: 'credit',
                negateValue: true,
              }
            )
          )
          .addRow(
            'nedskrivningarFusion',
            noteLabel('Fusion'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringNedskrivningarFusion',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av nedskrivningar för koncessioner, patent, licenser, varumärken samt liknande rättigheter genom fusion',
              negateValue: true,
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringNedskrivningarFusion',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av nedskrivningar för koncessioner, patent, licenser, varumärken samt liknande rättigheter genom fusion',
              negateValue: true,
            })
          )
          .addRow(
            '12',
            noteLabel('Försäljningar/utrangeringar'),
            ixbrlCell(ref(sumPositiveTransactions('1028+1038+1048+1058')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringNedskrivningarAterfordaNedskrivningarForsaljningarRespUtrangeringar',
              contextRef: 'period0',
            }),
            ixbrlCell(
              ref(
                sumPositiveTransactions('1028+1038+1048+1058', 'previousYear')
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringNedskrivningarAterfordaNedskrivningarForsaljningarRespUtrangeringar',
                contextRef: 'period1',
              }
            )
          )
          .addRow(
            '13',
            noteLabel('Återförda nedskrivningar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringNedskrivningarAterfordaNedskrivningar',
              contextRef: 'period0',
            }), // no accounts
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringNedskrivningarAterfordaNedskrivningar',
              contextRef: 'period1',
            })
          )
          .addRow(
            '14',
            noteLabel('Omklassificeringar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringNedskrivningarOmklassificeringar',
              contextRef: 'period0',
              standardRubrik:
                'Minskning (ökning) av nedskrivningar i samband med omklassificeringar av koncessioner, patent, licenser, varumärken samt liknande rättigheter',
            }), // no accounts
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringNedskrivningarOmklassificeringar',
              contextRef: 'period1',
              standardRubrik:
                'Minskning (ökning) av nedskrivningar i samband med omklassificeringar av koncessioner, patent, licenser, varumärken samt liknande rättigheter',
            })
          )
          .addRow(
            '15',
            noteLabel('Årets nedskrivningar'),
            ixbrlCell(ref(sumNegativeTransactions('1028+1038+1048+1058')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringNedskrivningarAretsNedskrivningar',
              contextRef: 'period0',
              negateValue: true,
            }),
            ixbrlCell(
              ref(
                sumNegativeTransactions('1028+1038+1048+1058', 'previousYear')
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringNedskrivningarAretsNedskrivningar',
                contextRef: 'period1',
                negateValue: true,
              }
            )
          )
          .addRow(
            'nedskrivningarConversionDifferences',
            noteLabel('Omräkningsdifferenser'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringNedskrivningarFusion',
              contextRef: 'period0',
              negateValue: true,
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringNedskrivningarFusion',
              contextRef: 'period1',
              negateValue: true,
            })
          )
          .addRow(
            '16',
            noteLabel('Utgående nedskrivningar'),
            ixbrlCell(
              sumNotesRowsById(
                6,
                [
                  ...range(11, 16),
                  'nedskrivningarFusion',
                  'nedskrivningarConversionDifferences',
                ],
                'year'
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterNedskrivningar',
                contextRef: 'balans0',
                saldo: 'credit',
                negateValue: true,
              }
            ),
            ixbrlCell(
              sumNotesRowsById(
                6,
                [
                  ...range(11, 16),
                  'nedskrivningarFusion',
                  'nedskrivningarConversionDifferences',
                ],
                'previousYear'
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterNedskrivningar',
                contextRef: 'balans1',
                saldo: 'credit',
                negateValue: true,
              }
            )
          )
          .addRow(
            '17',
            noteLabel('Redovisat värde'),
            ixbrlCell(sumNotesRowsById(6, [5, 10, 16], 'year'), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheter',
              contextRef: 'balans0',
              saldo: 'debit',
            }),
            ixbrlCell(sumNotesRowsById(6, [5, 10, 16], 'previousYear'), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheter',
              contextRef: 'balans1',
              saldo: 'debit',
            })
          )
          .build()
      )
      .newRowTemplate(value(''), value(undefined), value(undefined))
      .build(),
    commentLabel: field('Kommentar'),
    comment: ixbrlField(field(''), {
      type: 'stringItemType',
      name: 'se-gen-base:NotKoncessionerPatentLicenserVarumarkenLiknandeRattigheterKommentar',
      contextRef: 'period0',
    }),
    group: value(undefined),
    section: value(undefined),
    validForReportType: {
      k2: true,
      k3: documentTypeVersion === '1',
    },
  };
};

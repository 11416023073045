import { SelectFormat, id, or, ref, sum, table, value } from '@agoy/document';
import {
  IxbrlCell,
  NoteFourTablesWithCaptionsCommentsAndSum,
} from '../../../../common';
import {
  active,
  field,
  ixbrlCell,
  ixbrlField,
  tupleRef,
} from '../../../../common/utils/util';
import { previousYear, year } from '../../../../common/utils/date-util';

const typeOfLiabilityOrProvisionInputFormat: SelectFormat = {
  type: 'select',
  options: [
    {
      value: 'se-mem-base:SkulderAvsattningarObligationslanMember',
      label: 'Obligationslån',
    },
    {
      value: 'se-mem-base:SkulderAvsattningarCheckrakningskreditMember',
      label: 'Checkräkningskredit',
    },
    {
      value: 'se-mem-base:SkulderAvsattningarOvrigaSkulderKreditinstitutMember',
      label: 'Övriga skulder till kreditinstitut',
    },
    {
      value: 'se-mem-base:SkulderAvsattningarSkuldKoncernforetagMember',
      label: 'Skulder till koncernföretag',
    },
    {
      value:
        'se-mem-base:SkulderAvsattningarIntresseGemensamtStyrdaForetagMember',
      label: 'Skulder till intresseföretag och gemensamt styrda företag',
    },
    {
      value: 'se-mem-base:SkulderAvsattningarOvrigaForetagAgarintresseMember',
      label: 'Skulder till övriga företag som det finns ett ägarintresse i',
    },
    {
      value: 'se-mem-base:SkulderAvsattningarOvrigaSkulderMember',
      label: 'Övriga skulder',
    },
    {
      value: 'se-mem-base:SkulderAvsattningarAvsattningPensionerMember',
      label: 'Avsättning för pensioner',
    },
    {
      value: 'se-mem-base:SkulderAvsattningarOvrigaAvsattningarMember',
      label: 'Övriga avsättningar',
    },
  ],
};

const typeOfInsuranceInputFormat: SelectFormat = {
  type: 'select',
  options: [
    {
      value: 'se-mem-base:StalldaSakerheterForetagsinteckningarMember',
      label: 'Företagsinteckningar',
    },
    {
      value: 'se-mem-base:StalldaSakerheterFastighetsinteckningarMember',
      label: 'Fastighetsinteckningar',
    },
    {
      value: 'se-mem-base:StalldaSakerheterTomtrattsinteckningarMember',
      label: 'Tomträttsinteckningar',
    },
    {
      value: 'se-mem-base:StalldaSakerheterSkeppsinteckningarMember',
      label: 'Skeppsinteckningar',
    },
    {
      value: 'se-mem-base:StalldaSakerheterLuftfartygsinteckningarMember',
      label: 'Luftfartygsinteckningar',
    },
    {
      value: 'se-mem-base:StalldaSakerheterAndraInteckningarMember',
      label: 'Andra inteckningar',
    },
    {
      value:
        'se-mem-base:StalldaSakerheterTransportfordonAganderattsforbehallMember',
      label: 'Transportfordon med äganderättsförbehåll',
    },
    {
      value:
        'se-mem-base:StalldaSakerheterMaskinerInventarierAganderattsforbehallMember',
      label: 'Maskiner och inventarier med äganderättsförbehåll',
    },
    {
      value:
        'se-mem-base:StalldaSakerheterAktierAndraTillgangarAganderattsforbehallMember',
      label: 'Andra tillgångar med äganderättsförbehåll',
    },
    {
      value: 'se-mem-base:StalldaSakerheterAktierDotterbolagMember',
      label: 'Aktier i dotterföretag',
    },
    {
      value: 'se-mem-base:StalldaSakerheterAktierAnnatAnDotterbolagMember',
      label: 'Aktier i annat än dotterföretag',
    },
    {
      value: 'se-mem-base:StalldaSakerheterObligationerMember',
      label: 'Obligationer',
    },
    {
      value:
        'se-mem-base:StalldaSakerheterAndraVardepapperAnAktierObligationerMember',
      label: 'Andra värdepapper',
    },
    {
      value: 'se-mem-base:StalldaSakerheterBelanadeFordringarMember',
      label: 'Belånade fordringar',
    },
    {
      value: 'se-mem-base:StalldaSakerheterKundfordringarMember',
      label: 'Kundfordringar',
    },
    {
      value: 'se-mem-base:StalldaSakerheterSparradeBankmedelMember',
      label: 'Spärrade bankmedel',
    },
    {
      value: 'se-mem-base:StalldaSakerheterBankrakningarMember',
      label: 'Bankräkningar',
    },
    {
      value: 'se-mem-base:StalldaSakerheterVarulagerMember',
      label: 'Varulager',
    },
    {
      value: 'se-mem-base:StalldaSakerheterAndraStalldaSakerheterMember',
      label: 'Andra ställda säkerheter',
    },
  ],
};

export const getNote99 = (
  noteLabel,
  financialYear: string,
  financialYears: string[],
  period: string | null,
  documentTypeVersion = '1'
): NoteFourTablesWithCaptionsCommentsAndSum => {
  return {
    active: false,
    number: value(undefined),
    name: value('Ställda säkerheter'),
    subTitle: value('Ställda säkerheter för företagets egen räkning'),
    tableCaption: value('För egna skulder och avsättningar'),
    table: table<IxbrlCell>(
      `notes.note99.table`,
      {
        id: 'typeOfLiabilityOrProvision',
        label: 'Typ av skuld eller avsättning',
      },
      {
        id: 'typeOfInsurance',
        label: 'Typ av säkerhet',
      },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      .addRows((rows) =>
        rows
          .addRow(
            '1',
            ixbrlCell(
              {
                type: 'string',
                value: typeOfLiabilityOrProvisionInputFormat.options[0].label,
                format: typeOfLiabilityOrProvisionInputFormat,
              },
              {
                type: 'stringItemType',
                name: 'se-gen-base:SkulderAvsattningarList',
                contextRef: 'balans0',
                previousYearTuple: true,
                standardRubrik: 'Typ av skulder och avsättningar (Vallista)',
                tuple: {
                  name: 'se-gaap-ext:StalldaSakerheterEgnaSkulderAvsattningarTuple',
                  id: `${rows.getBaseId()}.1.tuple`,
                  tupleID: `${rows.getBaseId()}.1.tuple`,
                },
                tupleRef: tupleRef(1, `${rows.getBaseId()}.1.tuple`),
              }
            ),
            ixbrlCell(
              {
                type: 'string',
                value: typeOfInsuranceInputFormat.options[0].label,
                format: typeOfInsuranceInputFormat,
              },
              {
                type: 'stringItemType',
                name: 'se-gen-base:StalldaSakerheterList',
                contextRef: 'balans0',
                standardRubrik: 'Typ av ställd säkerhet (Vallista)',
                tupleRef: tupleRef(2, `${rows.getBaseId()}.1.tuple_balans0`),
              }
            ),
            ixbrlCell(value(undefined), {
              type: 'monetaryItemType',
              name: 'se-gen-base:StalldaSakerheterEgnaSkulderAvsattningar',
              contextRef: 'balans0',
              standardRubrik:
                'Belopp per ställd säkerhet för egna skulder och avsättningar',
              tupleRef: tupleRef(3, `${rows.getBaseId()}.1.tuple_balans0`),
            }),
            ixbrlCell(value(undefined), {
              type: 'monetaryItemType',
              name: 'se-gen-base:StalldaSakerheterEgnaSkulderAvsattningar',
              contextRef: 'balans1',
              standardRubrik:
                'Belopp per ställd säkerhet för egna skulder och avsättningar',
              tupleRef: tupleRef(4, `${rows.getBaseId()}.1.tuple_balans1`),
            })
          )
          .build()
      )
      .newRowTemplateGenerator((id, baseId) => ({
        id,
        active: true,
        cells: {
          typeOfLiabilityOrProvision: ixbrlCell(
            {
              type: 'string',
              value: typeOfLiabilityOrProvisionInputFormat.options[0].label,
              format: typeOfLiabilityOrProvisionInputFormat,
            },
            {
              type: 'stringItemType',
              name: 'se-gen-base:SkulderAvsattningarList',
              contextRef: 'balans0',
              previousYearTuple: true,
              standardRubrik:
                'Typ av tillgång, avsättning eller skuld som avser flera poster',
              tuple: {
                name: 'se-gaap-ext:StalldaSakerheterEgnaSkulderAvsattningarTuple',
                id: `${baseId}.${id}.tuple`,
                tupleID: `${baseId}.${id}.tuple`,
              },
              tupleRef: tupleRef(1, `${baseId}.${id}.tuple`),
            }
          ),
          typeOfInsurance: ixbrlCell(
            {
              type: 'string',
              value: typeOfInsuranceInputFormat.options[0].label,
              format: typeOfInsuranceInputFormat,
            },
            {
              type: 'stringItemType',
              contextRef: 'balans0',
              name: 'se-gen-base:StalldaSakerheterList',
              standardRubrik: 'Typ av ställd säkerhet (Vallista)',
              tupleRef: tupleRef(2, `${baseId}.${id}.tuple_balans0`),
            }
          ),
          year: ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base:StalldaSakerheterEgnaSkulderAvsattningar',
            contextRef: 'balans0',
            standardRubrik:
              'Belopp per ställd säkerhet för egna skulder och avsättningar',
            tupleRef: tupleRef(3, `${baseId}.${id}.tuple_balans0`),
          }),
          previousYear: ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base:StalldaSakerheterEgnaSkulderAvsattningar',
            contextRef: 'balans1',
            standardRubrik:
              'Belopp per ställd säkerhet för egna skulder och avsättningar',
            tupleRef: tupleRef(4, `${baseId}.${id}.tuple_balans1`),
          }),
        },
      }))
      .build(),
    firstTableCommentLabel: field('Kommentar'),
    firstTableComment: ixbrlField(active(field('')), {
      type: 'stringItemType',
      name: 'se-gen-base:StalldaSakerheterEgnaSkulderAvsattningarKommentar',
      contextRef: 'period0',
      standardRubrik:
        'Kommentar till ställda säkerheter för egna skulder och avsättningar',
    }),

    secondTableCaption: value('Övriga ställda säkerheter'),
    secondTable: table<IxbrlCell>(
      `notes.note99.secondTable`,
      {
        id: 'typeOfInsurance',
        label: 'Typ av säkerhet',
      },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      .addRows((rows) =>
        rows
          .addRow(
            '1',
            ixbrlCell(
              {
                type: 'string',
                value: typeOfInsuranceInputFormat.options[0].label,
                format: typeOfInsuranceInputFormat,
              },
              {
                type: 'stringItemType',
                name: 'se-gen-base:StalldaSakerheterList',
                contextRef: 'balans0',
                previousYearTuple: true,
                standardRubrik: 'Typ av ställd säkerhet (Vallista)',
                tuple: {
                  name: 'se-gaap-ext:OvrigaStalldaSakerheterEgnaSkulderAvsattningarTuple',
                  id: `${rows.getBaseId()}.1.tuple`,
                  tupleID: `${rows.getBaseId()}.1.tuple`,
                },
                tupleRef: tupleRef(1, `${rows.getBaseId()}.1.tuple`),
              }
            ),
            ixbrlCell(value(undefined), {
              type: 'monetaryItemType',
              name: 'se-gen-base:OvrigaStalldaSakerheterEgnaSkulderAvsattningar',
              contextRef: 'balans0',
              standardRubrik:
                'Belopp per övrig ställd säkerhet till förmån för företaget',
              tupleRef: tupleRef(3, `${rows.getBaseId()}.1.tuple_balans0`),
            }),
            ixbrlCell(value(undefined), {
              type: 'monetaryItemType',
              name: 'se-gen-base:OvrigaStalldaSakerheterEgnaSkulderAvsattningar',
              contextRef: 'balans1',
              standardRubrik:
                'Belopp per övrig ställd säkerhet till förmån för företaget',
              tupleRef: tupleRef(4, `${rows.getBaseId()}.1.tuple_balans1`),
            })
          )
          .build()
      )
      .newRowTemplateGenerator((id, baseId) => ({
        id,
        active: true,
        cells: {
          typeOfInsurance: ixbrlCell(
            {
              type: 'string',
              value: typeOfInsuranceInputFormat.options[0].label,
              format: typeOfInsuranceInputFormat,
            },
            {
              type: 'stringItemType',
              name: 'se-gen-base:StalldaSakerheterList',
              contextRef: 'balans0',
              previousYearTuple: true,
              standardRubrik: 'Typ av ställd säkerhet (Vallista)',
              tuple: {
                name: 'se-gaap-ext:OvrigaStalldaSakerheterEgnaSkulderAvsattningarTuple',
                id: `${baseId}.${id}.tuple`,
                tupleID: `${baseId}.${id}.tuple`,
              },
              tupleRef: tupleRef(1, `${baseId}.${id}.tuple`),
            }
          ),
          year: ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base:OvrigaStalldaSakerheterEgnaSkulderAvsattningar',
            contextRef: 'balans0',
            standardRubrik:
              'Belopp per övrig ställd säkerhet till förmån för företaget',
            tupleRef: tupleRef(3, `${baseId}.${id}.tuple_balans0`),
          }),
          previousYear: ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base:OvrigaStalldaSakerheterEgnaSkulderAvsattningar',
            contextRef: 'balans1',
            standardRubrik:
              'Belopp per övrig ställd säkerhet till förmån för företaget',
            tupleRef: tupleRef(4, `${baseId}.${id}.tuple_balans1`),
          }),
        },
      }))
      .build(),
    secondTableCommentLabel: field('Kommentar till not'),
    secondTableComment: ixbrlField(active(field('')), {
      type: 'stringItemType',
      name: 'se-gen-base:OvrigaStalldaSakerheterEgnaSkulderAvsattningarKommentar',
      contextRef: 'period0',
      standardRubrik:
        'Kommentar till övriga ställda säkerheter till förmån för företaget',
    }),

    thirdTableCaption: value(
      'Ställda säkerheter till förmån för koncernföretag, intresseföretag och gemensamt styrda företag'
    ),
    thirdTable: table<IxbrlCell>(
      `notes.note99.thirdTable`,
      {
        id: 'typeOfInsurance',
        label: 'Typ av säkerhet',
      },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      .addRows((rows) =>
        rows
          .addRow(
            '1',
            ixbrlCell(
              {
                type: 'string',
                value: typeOfInsuranceInputFormat.options[0].label,
                format: typeOfInsuranceInputFormat,
              },
              {
                type: 'stringItemType',
                name: 'se-gen-base:StalldaSakerheterList',
                contextRef: 'balans0',
                previousYearTuple: true,
                standardRubrik: 'Typ av ställd säkerhet (Vallista)',
                tuple: {
                  name: 'se-gaap-ext:StalldaSakerheterFormanKoncernIntresseGemensamtStyrdaForetagTuple',
                  id: `${rows.getBaseId()}.1.tuple`,
                  tupleID: `${rows.getBaseId()}.1.tuple`,
                },
                tupleRef: tupleRef(1, `${rows.getBaseId()}.1.tuple`),
              }
            ),
            ixbrlCell(value(undefined), {
              type: 'monetaryItemType',
              name: 'se-gen-base:StalldaSakerheterFormanKoncernIntresseGemensamtStyrdaForetag',
              contextRef: 'balans0',
              standardRubrik:
                'Belopp per ställd säkerhet till förmån för koncernföretag, intresseföretag och gemensamt styrda företag',
              tupleRef: tupleRef(3, `${rows.getBaseId()}.1.tuple_balans0`),
            }),
            ixbrlCell(value(undefined), {
              type: 'monetaryItemType',
              name: 'se-gen-base:StalldaSakerheterFormanKoncernIntresseGemensamtStyrdaForetag',
              contextRef: 'balans1',
              standardRubrik:
                'Belopp per ställd säkerhet till förmån för koncernföretag, intresseföretag och gemensamt styrda företag',
              tupleRef: tupleRef(4, `${rows.getBaseId()}.1.tuple_balans1`),
            })
          )
          .build()
      )
      .newRowTemplateGenerator((id, baseId) => ({
        id,
        active: true,
        cells: {
          typeOfInsurance: ixbrlCell(
            {
              type: 'string',
              value: typeOfInsuranceInputFormat.options[0].label,
              format: typeOfInsuranceInputFormat,
            },
            {
              type: 'stringItemType',
              name: 'se-gen-base:StalldaSakerheterList',
              contextRef: 'balans0',
              previousYearTuple: true,
              standardRubrik: 'Typ av ställd säkerhet (Vallista)',
              tuple: {
                name: 'se-gaap-ext:StalldaSakerheterFormanKoncernIntresseGemensamtStyrdaForetagTuple',
                id: `${baseId}.${id}.tuple`,
                tupleID: `${baseId}.${id}.tuple`,
              },
              tupleRef: tupleRef(1, `${baseId}.${id}.tuple`),
            }
          ),
          year: ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base:StalldaSakerheterFormanKoncernIntresseGemensamtStyrdaForetag',
            contextRef: 'balans0',
            standardRubrik:
              'Belopp per övrig ställd säkerhet till förmån för företaget',
            tupleRef: tupleRef(3, `${baseId}.${id}.tuple_balans0`),
          }),
          previousYear: ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base:StalldaSakerheterFormanKoncernIntresseGemensamtStyrdaForetag',
            contextRef: 'balans1',
            standardRubrik:
              'Belopp per övrig ställd säkerhet till förmån för företaget',
            tupleRef: tupleRef(4, `${baseId}.${id}.tuple_balans1`),
          }),
        },
      }))
      .build(),
    thirdTableCommentLabel: field('Kommentar till not'),
    thirdTableComment: ixbrlField(active(field('')), {
      type: 'stringItemType',
      name: 'se-gen-base:StalldaSakerheterFormanKoncernIntresseGemensamtStyrdaForetagKommentar',
      contextRef: 'period0',
      standardRubrik:
        'Kommentar till ställda säkerheter till förmån för koncernföretag, intresseföretag och gemensamt styrda företag',
    }),

    fourthTableCaption: value('Ställda säkerheter till förmån för andra'),
    fourthTable: table<IxbrlCell>(
      `notes.note99.fourthTable`,
      {
        id: 'typeOfInsurance',
        label: 'Typ av säkerhet',
      },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      .addRows((rows) =>
        rows
          .addRow(
            '1',
            ixbrlCell(
              {
                type: 'string',
                value: typeOfInsuranceInputFormat.options[0].label,
                format: typeOfInsuranceInputFormat,
              },
              {
                type: 'stringItemType',
                name: 'se-gen-base:StalldaSakerheterList',
                contextRef: 'balans0',
                previousYearTuple: true,
                standardRubrik: 'Typ av ställd säkerhet (Vallista)',
                tuple: {
                  name: 'se-gaap-ext:StalldaSakerheterFormanAndraTuple',
                  id: `${rows.getBaseId()}.1.tuple`,
                  tupleID: `${rows.getBaseId()}.1.tuple`,
                },
                tupleRef: tupleRef(1, `${rows.getBaseId()}.1.tuple`),
              }
            ),
            ixbrlCell(value(undefined), {
              type: 'monetaryItemType',
              name: 'se-gen-base:StalldaSakerheterFormanAndra',
              contextRef: 'balans0',
              standardRubrik:
                'Belopp per ställd säkerhet till förmån för andra',
              tupleRef: tupleRef(3, `${rows.getBaseId()}.1.tuple_balans0`),
            }),
            ixbrlCell(value(undefined), {
              type: 'monetaryItemType',
              name: 'se-gen-base:StalldaSakerheterFormanAndra',
              contextRef: 'balans1',
              standardRubrik:
                'Belopp per ställd säkerhet till förmån för andra',
              tupleRef: tupleRef(4, `${rows.getBaseId()}.1.tuple_balans1`),
            })
          )
          .build()
      )
      .newRowTemplateGenerator((id, baseId) => ({
        id,
        active: true,
        cells: {
          typeOfInsurance: ixbrlCell(
            {
              type: 'string',
              value: typeOfInsuranceInputFormat.options[0].label,
              format: typeOfInsuranceInputFormat,
            },
            {
              type: 'stringItemType',
              name: 'se-gen-base:StalldaSakerheterList',
              contextRef: 'balans0',
              previousYearTuple: true,
              standardRubrik: 'Typ av ställd säkerhet (Vallista)',
              tuple: {
                name: 'se-gaap-ext:StalldaSakerheterFormanAndraTuple',
                id: `${baseId}.${id}.tuple`,
                tupleID: `${baseId}.${id}.tuple`,
              },
              tupleRef: tupleRef(1, `${baseId}.${id}.tuple`),
            }
          ),
          year: ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base:StalldaSakerheterFormanAndra',
            contextRef: 'balans0',
            standardRubrik: 'Belopp per ställd säkerhet till förmån för andra',
            tupleRef: tupleRef(3, `${baseId}.${id}.tuple_balans0`),
          }),
          previousYear: ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base:StalldaSakerheterFormanAndra',
            contextRef: 'balans1',
            standardRubrik: 'Belopp per ställd säkerhet till förmån för andra',
            tupleRef: tupleRef(4, `${baseId}.${id}.tuple_balans1`),
          }),
        },
      }))
      .build(),
    fourthTableCommentLabel: field('Kommentar till not'),
    fourthTableComment: ixbrlField(active(field('')), {
      type: 'stringItemType',
      name: 'se-gen-base:StalldaSakerheterFormanAndraKommentar',
      contextRef: 'period0',
      standardRubrik: 'Kommentar till ställda säkerheter till förmån för andra',
    }),

    sumTable: table<IxbrlCell>(
      'notes.note99.sumTable',
      {
        id: 'label',
        label: 'Summa av obeskattade reserver',
      },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      .addRows((rows) =>
        rows
          .addRow(
            'sum',
            noteLabel('Summa ställda säkerheter'),
            ixbrlCell(
              ref(
                or(
                  sum(
                    id('notes.note99.table.*.year'),
                    id('notes.note99.secondTable.*.year'),
                    id('notes.note99.thirdTable.*.year'),
                    id('notes.note99.fourthTable.*.year')
                  ),
                  0
                )
              ),
              {
                type: 'monetaryItemType',
                name: 'se-gen-base:StalldaSakerheter',
                contextRef: 'balans0',
                saldo: 'credit',
              }
            ),
            ref(
              or(
                sum(
                  id('notes.note99.table.*.previousYear'),
                  id('notes.note99.secondTable.*.previousYear'),
                  id('notes.note99.thirdTable.*.previousYear'),
                  id('notes.note99.fourthTable.*.previousYear')
                ),
                0
              )
            )
          )
          .build()
      )
      .build(),

    commentLabel: field('Kommentar till not'),
    comment: ixbrlField(active(field('')), {
      type: 'stringItemType',
      name: 'se-gen-base:NotStalldaSakerheterKommentarer',
      contextRef: 'balans0',
      standardRubrik: 'Kommentar till specifikation av ställda säkerheter',
    }),
    group: value(undefined),
    section: value(undefined),
    validForReportType: {
      k2: false,
      k3: documentTypeVersion !== '1',
    },
  };
};

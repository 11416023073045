import React, { useEffect } from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
} from '@material-ui/core';
import styled from '@emotion/styled';

import { BOARD_LABELS } from '@agoy/annual-report-view/src/types/enums';
import { defaultRoles } from '@agoy/annual-report-document';
import { useIntl } from 'react-intl';
import AddOptionMember from './AddOptionMember';

const NewOptionWrapper = styled.div`
  .MuiFormControl-root {
    display: flex;
    margin-left: 2rem;
  }
  .MuiOutlinedInput-adornedEnd {
    padding-right: 4px;
  }
`;

type BoardMemberOption = {
  id: string;
  checked: boolean;
  label: string;
};

type BoardMemberTypeProps = {
  roles: string;
  onChange: (changes: string) => void;
};

const BoardMemberTypeSelection = ({
  roles,
  onChange,
}: BoardMemberTypeProps) => {
  const [options, setOptions] = React.useState<BoardMemberOption[]>([
    {
      id: 'option1',
      checked: false,
      label: BOARD_LABELS.CHAIRMAN_OF_THE_BOARD,
    },
    { id: 'option2', checked: false, label: BOARD_LABELS.BOARD_MEMBER },
    { id: 'option3', checked: false, label: BOARD_LABELS.BOARD_ALTERNATE },
    { id: 'option4', checked: false, label: BOARD_LABELS.CEO },
  ]);

  const { formatMessage } = useIntl();

  useEffect(() => {
    if (roles === '') return;

    const roleLabels = roles.split('|');

    const formattedAuditorRoles = defaultRoles.auditors.map((role) =>
      formatMessage({ id: `clientInformation.${role}` }).toLowerCase()
    );

    setOptions((prevOptions) => {
      const newOptions: BoardMemberOption[] = [];
      roleLabels
        .filter((role) => !formattedAuditorRoles.includes(role.toLowerCase()))
        .map((newOptionLabel, index) => {
          const existingOption = prevOptions.find(
            (option) => option.label === newOptionLabel
          );

          if (!existingOption) {
            newOptions.push({
              id: `option${prevOptions.length + index}`,
              checked: true,
              label: newOptionLabel,
            });
          }
          return undefined;
        });

      const allOptions = [...prevOptions, ...newOptions];
      return allOptions.map((option) =>
        roleLabels.includes(option.label)
          ? { ...option, checked: true }
          : option
      );
    });
  }, [roles]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const option = options.find((option) => option.id === event.target.name);
    if (!option) return;

    const updatedOptions = options.map((existingOption) =>
      existingOption.id === option.id
        ? { ...existingOption, checked: event.target.checked }
        : existingOption
    );
    setOptions(updatedOptions);

    const changes = updatedOptions
      .filter((item) => item.checked)
      .map((item) => item.label)
      .join('|');

    onChange(changes);
  };

  const handleAddOption = (optionLabel) => {
    if (optionLabel === '') {
      optionLabel = ' ';
    }

    setOptions([
      ...options,
      {
        id: `option${options.length + 1}`,
        checked: false,
        label: optionLabel,
      },
    ]);
  };

  return (
    <>
      <FormControl component="fieldset">
        <FormGroup>
          {options.map((option) => (
            <FormControlLabel
              key={option.id}
              control={
                <Checkbox
                  checked={option.checked}
                  onChange={handleChange}
                  name={option.id}
                />
              }
              label={option.label}
            />
          ))}
        </FormGroup>
      </FormControl>
      <NewOptionWrapper>
        <AddOptionMember onAddOption={handleAddOption} />
      </NewOptionWrapper>
    </>
  );
};

export default BoardMemberTypeSelection;

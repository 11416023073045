import React from 'react';
import {
  Paper,
  Table as MuiTable,
  TableContainer as MuiTableContainer,
  TableHead as MuiTableHead,
  TableBody as MuiTableBody,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
} from '@material-ui/core';
import styled from '@emotion/styled';
import { GetComponentProps } from '_shared/services/type-helpers';

type Props = {
  children?: React.ReactNode;
};

export const TableContainer = styled((props) => (
  <MuiTableContainer {...props} component={Paper} />
))`
  overflow-x: auto;
  flex: 1;

  .MuiTableCell-head {
    border: 1px solid rgba(224, 224, 224, 1);
    white-space: nowrap;
  }

  .MuiTableCell-body {
    border: 1px solid rgba(224, 224, 224, 1);
    white-space: nowrap;
  }
`;
export const Table = ({ children }: Props): JSX.Element => (
  <MuiTable size="small">{children}</MuiTable>
);
export const TableHead = ({ children }: Props): JSX.Element => (
  <MuiTableHead>{children}</MuiTableHead>
);
export const TableRow = ({ children }: Props): JSX.Element => (
  <MuiTableRow>{children}</MuiTableRow>
);
export const TableCell = ({
  children,
  align = 'center',
  ...props
}: Props & GetComponentProps<typeof MuiTableCell>): JSX.Element => (
  <MuiTableCell align={align} {...props}>
    {children}
  </MuiTableCell>
);
export const TableBody = ({ children }: Props): JSX.Element => (
  <MuiTableBody>{children}</MuiTableBody>
);

type ExampleProps = {
  headers: (number | string | JSX.Element)[];
  rows: (number | string | JSX.Element)[][];
};

const BorderTableExample = ({ headers, rows }: ExampleProps): JSX.Element => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {headers.map((header) => (
              <TableCell>{header}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow>
              {row.map((element) => (
                <TableCell>{element}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BorderTableExample;

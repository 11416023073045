import React from 'react';
import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';
import { ClientWithConnections } from '_client-connections/types';

const Container = styled.div`
  flex: 1;
  max-width: 1200px;
  width: 75%;
  min-width: 750px;
`;

const ContentContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing(4)}px;
  margin-bottom: ${({ theme }) => theme.spacing(4)}px;
`;

export type ConnectionTabProps = {
  client: ClientWithConnections;
  path?: 'clients' | 'persons';
};

type Props = Omit<ConnectionTabProps, 'path'> & {
  children: React.ReactNode;
  title: string;
  description: string | React.ReactNode;
  navigateBack?: React.ReactNode;
};

const ConnectionsTabWrapper = ({
  children,
  title,
  description,
  navigateBack,
}: Props): JSX.Element => (
  <Container>
    {navigateBack}
    <Typography variant="h3">{title}</Typography>
    {typeof description === 'string' ? (
      <Typography>{description}</Typography>
    ) : (
      description
    )}
    <ContentContainer>{children}</ContentContainer>
  </Container>
);

export default ConnectionsTabWrapper;

/* eslint-disable no-plusplus */
const MONTHS_PER_YEAR = 12;

/**
 * Return the year from periodString.
 * If no year is provided, the default value is 0.
 * @param period YYYYMMDD
 */
export const getPeriodYear = (periodString: string): number => {
  return parseInt(periodString.substring(0, 4), 10) || 0;
};

/**
 * Return the month from periodString.
 * If no month provided, the default value is 1.
 * @param period YYYYMMDD
 */
export const getPeriodMonth = (periodString: string): number => {
  return parseInt(periodString.substring(4, 6), 10) || 1;
};

/**
 * Return the day from periodString
 * @param period YYYYMMDD
 */
export const getPeriodDay = (periodString: string): string => {
  const dayNumber = parseInt(periodString.substring(6, 8), 10) || 1;
  return dayNumber < 10 ? `0${dayNumber}` : dayNumber.toString();
};

/**
 * Generates all the periodStrings w format YYYYMMDD between `from` and `to` periodStrings, both inclusive.
 */
export const generateAllPeriods = (from: string, to: string): string[] => {
  const generatedPeriods: string[] = [];

  const startYear = getPeriodYear(from);
  let startMonth = getPeriodMonth(from);
  let startDay = getPeriodDay(from);

  const endYear = getPeriodYear(to);
  const endMonth = getPeriodMonth(to);

  const rangeYears = endYear - startYear;

  for (let i = 0; i <= rangeYears; i++) {
    for (let j = 0; j < MONTHS_PER_YEAR; j++) {
      if (i === rangeYears && startMonth > endMonth) {
        break;
      }

      // default to first day of month
      generatedPeriods.push(
        `${startYear + i}${
          startMonth < 10
            ? `0${startMonth}${startDay}`
            : `${startMonth}${startDay}`
        }`
      );

      startMonth++;

      startDay = '01';

      if (startMonth > 12) {
        startMonth = 1;
        break;
      }
    }
  }

  return generatedPeriods;
};

import { AgoyDocumentStructure } from '../document';
import { OperationResult, State } from './types';
import { Cell } from '../../Cell';
import updateCell from './helpers/updateCell';
import updateDocument from './helpers/updateDocument';

const updateCellValue = <T extends AgoyDocumentStructure>(
  structure: T,
  state: State<T>,
  id: string,
  cell: Cell,
  keepOriginal = false
): OperationResult<T> => {
  return updateDocument(structure, state, id, {
    table: (key, id, props) => {
      if (!key) {
        return props;
      }
      const columnId = key[key.length - 1];
      if (!props.node.columns.some((col) => col.id === columnId)) {
        console.warn(`No column with id ${columnId}, tableId: ${id} `);
        return props;
      }
      const updater = updateCell(
        key.slice(0, key.length - 1),
        key[key.length - 1],
        (c) => ({ ...c, ...cell }),
        keepOriginal
      );
      const changes = props.changes || { type: 'update' };
      const result = updater(props.node, changes);
      if (Array.isArray(result)) {
        const [updatedTable, updatedChanges] = result;
        if (props.node !== updatedTable || changes !== updatedChanges) {
          return {
            ...props,
            node: updatedTable,
            changes: updatedChanges,
          };
        }
      }
      return props;
    },
  });
};

export default updateCellValue;

import React from 'react';
import ClientInfoContextProvider from './ClientInformationContext';

const withClientInformation = (Component) => (props) => {
  return (
    <ClientInfoContextProvider>
      <Component {...props} />
    </ClientInfoContextProvider>
  );
};

export default withClientInformation;

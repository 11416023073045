import { ApiReturnType } from 'api-sdk';
import config from '_shared/services/config';

type MessagesType = ApiReturnType<'getSystemMessages'>;

/**
 * Message filter function, based on the app's white label and
 * the messages stored in local storage.
 */
// eslint-disable-next-line import/prefer-default-export
export const filterMessages =
  (whiteLabel: typeof config.whiteLabel, localStorageMessageIds: number[]) =>
  (message: MessagesType[0]): boolean => {
    // messages stored in localStorage are filtered out
    if (localStorageMessageIds.includes(message.id)) {
      return false;
    }

    // only the messages relevant for the current app are shosn (whiteLabel config)
    switch (message.showInApp) {
      case 'all':
        return true;
      case 'agoy': // only render in agoy
        return whiteLabel !== 'fortnox';
      case 'fortnox': // only render in fortnox whitelabel
        return whiteLabel === 'fortnox';
      default:
        return false;
    }
  };

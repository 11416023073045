import React from 'react';
import { CssBaseline } from '@material-ui/core';

const withCssBaseline = (Component) => (props) =>
  (
    <>
      <CssBaseline />
      <Component {...props} />
    </>
  );

export default withCssBaseline;

const getFileExtension = (fileName: string): string => {
  const dotIndex = fileName.lastIndexOf('.');
  let extension = '';

  if (dotIndex !== -1) {
    extension = fileName.substring(dotIndex);
  }

  return extension;
};

export default getFileExtension;

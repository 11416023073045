import { useEffect, useState } from 'react';
import { useApiSdk, asResultClass } from 'api-sdk';
import { getLastFinancialYear } from '_client-connections/utils';

const usePersonHaveNEFormsInAlreadyConnectedCompanies = (clientId: string) => {
  const { getTaxOrganisationChart } = useApiSdk();

  const [hasNEForm, setHasNEForm] = useState(false);

  useEffect(() => {
    const fetchTaxOrganisationChart = async () => {
      const taxOrganisationChart = await asResultClass(
        getTaxOrganisationChart({
          clientId,
          financialYear: getLastFinancialYear(),
        })
      );

      if (taxOrganisationChart.ok) {
        return taxOrganisationChart.val;
      }
      return [];
    };

    const checkIfAnyPreviousConnectedCompanyHaveNEForm = async () => {
      const taxOrganisationChart = await fetchTaxOrganisationChart();

      const foundNeForms = taxOrganisationChart.some((organisation) =>
        organisation.forms.some((form) => form.name === 'NE')
      );

      setHasNEForm(foundNeForms);
    };

    checkIfAnyPreviousConnectedCompanyHaveNEForm();
  }, [clientId, getTaxOrganisationChart]);

  return hasNEForm;
};

export default usePersonHaveNEFormsInAlreadyConnectedCompanies;

import {
  Cell,
  or,
  id,
  sum,
  account,
  multiply,
  min,
  max,
  label,
  ref,
  value,
  msg,
  refs,
  ReferenceAccountInformation,
  TimePeriod,
  ifOrElse,
} from '@agoy/document';
import { IxbrlCell, IxbrlDataDefinition } from '../../../common';
import { table, RowsBuilder, range, ixbrlCell } from '../../../common/utils/util';
import { ConfiguredFinancialYear } from '../../../document';
import { CashFlowStatement } from './types';

const accountRangeRows = (
  accounts: Record<string, ReferenceAccountInformation>,
  first: number,
  last: number,
  rows: RowsBuilder,
  accountReference?: (
    accountNumber: string,
    previousIntervalKey?: string
  ) => string,
  addAdjustment = true,
  previousIntervalKey?: string
): RowsBuilder => {
  range(first, last + 1)
    .map((n) => n.toString())
    .forEach((n) => {
      const accountInformation = accounts[n];
      if (accountInformation) {
        const reference = accountReference
          ? or(accountReference(n), 0)
          : or(account(n), 0);
        rows.addRow(
          n,
          label(`${n} ${accountInformation.accountName}`),
          undefined,
          ref(
            addAdjustment
              ? sum(
                  multiply(-1, reference),
                  or(id(`${rows.getBaseId()}.${n}.adjustment`), 0)
                )
              : multiply(-1, reference)
          ),
          addAdjustment ? value(0) : undefined,
          ref(
            multiply(
              -1,
              accountReference
                ? or(accountReference(n, previousIntervalKey), 0)
                : or(account(n, undefined, previousIntervalKey), 0)
            )
          )
        );
      }
    });
  return rows;
};

const otherAccountsRange = (
  accounts: Record<string, ReferenceAccountInformation>,
  first: number,
  last: number,
  rows: RowsBuilder
): RowsBuilder => {
  range(first, last + 1)
    .map((n) => n.toString())
    .forEach((n) => {
      const accountInformation = accounts[n];
      if (accountInformation) {
        rows.addRow(
          n,
          label(`${n} ${accountInformation.accountName}`),
          ref(account(n, 'change'))
        );
      }
    });
  return rows;
};

const isIxbrlTagged = (
  referencePreviousInterval: string | undefined,
  ixbrlForPreviousYear: IxbrlDataDefinition | undefined
): IxbrlCell | undefined => {
  if (referencePreviousInterval) {
    if (ixbrlForPreviousYear) {
      return ixbrlCell(
        ref(or(referencePreviousInterval, 0)),
        ixbrlForPreviousYear
      );
    } else {
      return ref(or(referencePreviousInterval, 0));
    }
  }
  if (!referencePreviousInterval && ixbrlForPreviousYear) {
    return ixbrlCell(value(0), ixbrlForPreviousYear);
  }
  return value(0);
};

const addRowWithAdjustment = (
  rows: RowsBuilder,
  rowId: string,
  label: Cell,
  reference: string,
  referencePreviousInterval: string | undefined,
  ixbrlForReference?: IxbrlDataDefinition, // the ixbrl data definition used for the passed reference
  ixbrlForPreviousYear?: IxbrlDataDefinition
): RowsBuilder<IxbrlCell> => {
  return rows.addRow(
    rowId,
    label,
    refs(), // accepts a string reference for a note
    ixbrlForReference
      ? ixbrlCell(
          ref(
            sum(reference, or(id(`${rows.getBaseId()}.${rowId}.adjustment`), 0))
          ),
          ixbrlForReference
        )
      : ref(
          sum(reference, or(id(`${rows.getBaseId()}.${rowId}.adjustment`), 0))
        ),
    // adjustments row
    value(0),
    // check for previous year data and if IxBRL tag is present
    isIxbrlTagged(referencePreviousInterval, ixbrlForPreviousYear)
  );
};

const positiveChange = (accounts: string, periodName?: string) =>
  or(multiply(-1, max(account(accounts, 'change', periodName), 0)), 0);

const negativeChange = (accounts: string, periodName?: string) =>
  or(multiply(-1, min(account(accounts, 'change', periodName), 0)), 0);

const year = (
  financialYear: ConfiguredFinancialYear,
  period: TimePeriod
): string => {
  return `${financialYear.start} ${period.endDateISO}`;
};

const addInvestmentRows = (
  rows: RowsBuilder<Cell>,
  previousYearKey: string | undefined
) => {
  addRowWithAdjustment(
    rows,
    '1',
    label(
      'Förvärv av balanserade utgifter för utvecklingsarbeten och liknande arbeten'
    ),
    positiveChange('1010:1015'),
    positiveChange('1010:1015', previousYearKey)
  );
  addRowWithAdjustment(
    rows,
    '2',
    label(
      'Försäljning av balanserade utgifter för utvecklingsarbeten och liknande arbeten'
    ),
    negativeChange('1010:1015'),
    negativeChange('1010:1015', previousYearKey)
  );
  addRowWithAdjustment(
    rows,
    '3',
    label('Förvärv av koncessioner, patent, licenser m.m.'),
    positiveChange('1020:1025+1030:1035+1040:1045+1050:1055'),
    positiveChange('1020:1025+1030:1035+1040:1045+1050:1055', previousYearKey)
  );
  addRowWithAdjustment(
    rows,
    '4',
    label('Försäljning av koncessioner, patent, licenser m.m.'),
    negativeChange('1020:1025+1030:1035+1040:1045+1050:1055'),
    negativeChange('1020:1025+1030:1035+1040:1045+1050:1055', previousYearKey)
  );
  addRowWithAdjustment(
    rows,
    '5',
    label('Förvärv av hyresrätter och liknande rättigheter'),
    positiveChange('1060:1065'),
    positiveChange('1060:1065', previousYearKey)
  );
  addRowWithAdjustment(
    rows,
    '6',
    label('Försäljning av hyresrätter och liknande rättigheter'),
    negativeChange('1060:1065'),
    negativeChange('1060:1065', previousYearKey)
  );
  addRowWithAdjustment(
    rows,
    '7',
    label('Förvärv av goodwill'),
    positiveChange('1070:1075'),
    positiveChange('1070:1075', previousYearKey)
  );
  addRowWithAdjustment(
    rows,
    '8',
    label('Försäljning av goodwill'),
    negativeChange('1070:1075'),
    negativeChange('1070:1075', previousYearKey)
  );
  addRowWithAdjustment(
    rows,
    '9',
    label('Förvärv av övriga immateriella anläggningstillgångar'),
    positiveChange('1080:1085+1090:1095'),
    positiveChange('1080:1085+1090:1095', previousYearKey)
  );
  addRowWithAdjustment(
    rows,
    '10',
    label('Försäljning av övriga immateriella anläggningstillgångar'),
    negativeChange('1080:1085+1090:1095'),
    negativeChange('1080:1085+1090:1095', previousYearKey)
  );
  addRowWithAdjustment(
    rows,
    '11',
    label('Förvärv av byggnader och mark'),
    positiveChange(
      '1120:1125+1110:1115+1130:1135+1140:1145+1150:1155+1180:1185'
    ),
    positiveChange(
      '1120:1125+1110:1115+1130:1135+1140:1145+1150:1155+1180:1185',
      previousYearKey
    )
  );
  addRowWithAdjustment(
    rows,
    '12',
    label('Försäljning av byggnader och mark'),
    negativeChange(
      '1120:1125+1110:1115+1130:1135+1140:1145+1150:1155+1180:1185'
    ),
    negativeChange(
      '1120:1125+1110:1115+1130:1135+1140:1145+1150:1155+1180:1185',
      previousYearKey
    )
  );
  addRowWithAdjustment(
    rows,
    '13',
    label('Förvärv av maskiner och andra tekniska anläggningar'),
    positiveChange('1210:1215'),
    positiveChange('1210:1215', previousYearKey)
  );
  addRowWithAdjustment(
    rows,
    '14',
    label('Försäljning av maskiner o andra tekniska anläggningar'),
    negativeChange('1210:1215'),
    negativeChange('1210:1215', previousYearKey)
  );
  addRowWithAdjustment(
    rows,
    '15',
    label('Förvärv av inventarier, verktyg och installationer'),
    positiveChange('1220:1225+1230:1235+1240:1245+1250:1255'),
    positiveChange('1220:1225+1230:1235+1240:1245+1250:1255', previousYearKey)
  );
  addRowWithAdjustment(
    rows,
    '16',
    label('Försäljning av inventarier, verktyg och installationer'),
    negativeChange('1250:1255'),
    negativeChange('1250:1255', previousYearKey)
  );
  addRowWithAdjustment(
    rows,
    '17',
    label('Förvärv av leasingavtal m.m.'),
    positiveChange('1260:1265'),
    positiveChange('1260:1265', previousYearKey)
  );
  addRowWithAdjustment(
    rows,
    '18',
    label('Försäljning av leasingavtal'),
    negativeChange('1260:1265'),
    negativeChange('1260:1265', previousYearKey)
  );
  addRowWithAdjustment(
    rows,
    '19',
    label('Förvärv av konst m.m.'),
    positiveChange('1290:1295'),
    positiveChange('1290:1295', previousYearKey)
  );
  addRowWithAdjustment(
    rows,
    '20',
    label('Försäljning av konst m.m.'),
    negativeChange('1290:1295'),
    negativeChange('1290:1295', previousYearKey)
  );
  addRowWithAdjustment(
    rows,
    '21',
    label(
      'Förvärv av pågående nyanläggningar och förskott avseende materiella anläggningstillgångar'
    ),
    positiveChange('1280:1285'),
    positiveChange('1280:1285', previousYearKey)
  );
  addRowWithAdjustment(
    rows,
    '22',
    label(
      'Försäljning av pågående nyanläggningar och förskott avseende materiella anläggningstillgångar'
    ),
    negativeChange('1280:1285'),
    negativeChange('1280:1285', previousYearKey)
  );
  addRowWithAdjustment(
    rows,
    '23',
    label('Förvärv av koncernföretag'),
    positiveChange('1310:1316'),
    positiveChange('1310:1316', previousYearKey)
  );
  addRowWithAdjustment(
    rows,
    '25',
    label('Försäljning av andelar i koncernföretag'),
    negativeChange('1310:1316'),
    negativeChange('1310:1316', previousYearKey)
  );
  addRowWithAdjustment(
    rows,
    '26',
    label('Årets lämnade lån till koncernföretag'),
    positiveChange('1320:1325'),
    positiveChange('1320:1325', previousYearKey)
  );
  addRowWithAdjustment(
    rows,
    '27',
    label('Årets amorteringar från koncernföretag'),
    negativeChange('1320:1325'),
    negativeChange('1320:1325', previousYearKey)
  );
  addRowWithAdjustment(
    rows,
    '28',
    label('Förvärv av andelar i intresseföretag och gemensamt styrda företag'),
    positiveChange('1330:1331+1333+1336'),
    positiveChange('1330:1331+1333+1336', previousYearKey)
  );
  addRowWithAdjustment(
    rows,
    '30',
    label(
      'Försäljning av andelar i intresseföretag och gemensamt styrda företag'
    ),
    negativeChange('1330:1331+1333+1336'),
    negativeChange('1330:1331+1333+1336', previousYearKey)
  );
  addRowWithAdjustment(
    rows,
    '31',
    label(
      'Årets lämnade lån till intresseföretag och gemensamt styrda företag'
    ),
    positiveChange('1340:1341+1343+1346'),
    positiveChange('1340:1341+1343+1346', previousYearKey)
  );
  addRowWithAdjustment(
    rows,
    '32',
    label(
      'Årets amorteringar från intresseföretag och gemensamt styrda företag'
    ),
    negativeChange('1340:1341+1343+1346'),
    negativeChange('1340:1341+1343+1346', previousYearKey)
  );
  addRowWithAdjustment(
    rows,
    '33',
    label('Förvärv av långfristiga värdepapper'),
    positiveChange('1350:1357'),
    positiveChange('1350:1357', previousYearKey)
  );
  addRowWithAdjustment(
    rows,
    '34',
    label('Försäljning av långfristiga värdepapper'),
    negativeChange('1350:1357'),
    negativeChange('1350:1357', previousYearKey)
  );
  addRowWithAdjustment(
    rows,
    '35',
    label('Ny långfristig utlåning till närstående'),
    positiveChange('1360:1365'),
    positiveChange('1360:1365', previousYearKey)
  );
  addRowWithAdjustment(
    rows,
    '36',
    label('Återbetalning av långfristigt lån från närstående'),
    negativeChange('1360:1365'),
    negativeChange('1360:1365', previousYearKey)
  );
  addRowWithAdjustment(
    rows,
    '37',
    label('Ny långfristig utlåning till utomstående'),
    positiveChange('1380:1382'),
    positiveChange('1380:1382', previousYearKey)
  );
  addRowWithAdjustment(
    rows,
    '38',
    label('Återbetalning av långfristigt lån från utomstående'),
    negativeChange('1380:1382'),
    negativeChange('1380:1382', previousYearKey)
  );
};

// FYI: the value(0) in the middle refers to Justeringar (adjustments) column
const addInvestmentRowsDigitalSubmission = (rows: RowsBuilder<Cell>) => {
  rows
    .addRow(
      'lamnadeAktieagartillskott',
      label('Lämnade aktieägartillskott'),
      refs(),
      // UI: Current year
      ixbrlCell(value(0), {
        type: 'monetaryItemType',
        name: 'se-gen-base:LamnadeKapitaltillskottInvesteringsverksamheten',
        contextRef: 'period0',
        standardRubrik: 'Minskning av kassaflöde vid lämnade tillskott',
        negateValue: true,
      }),
      // UI: Justeringar (adjustments)
      value(0),
      // UI: Previous year
      ixbrlCell(value(0), {
        type: 'monetaryItemType',
        name: 'se-gen-base:LamnadeKapitaltillskottInvesteringsverksamheten',
        contextRef: 'period1',
        standardRubrik: 'Minskning av kassaflöde vid lämnade tillskott',
        negateValue: true,
      })
    )
    .addRow(
      'lamnadeKoncernbidrag',
      label('Lämnade koncernbidrag'),
      refs(),
      ixbrlCell(value(0), {
        type: 'monetaryItemType',
        name: 'se-gen-base:LamnadeKoncernbidragInvesteringsverksamheten',
        contextRef: 'period0',
        standardRubrik:
          'Minskning av kassaflöde vid lämnade koncernbidrag i investeringsverksamheten',
        negateValue: true,
      }),
      value(0),
      ixbrlCell(value(0), {
        type: 'monetaryItemType',
        name: 'se-gen-base:LamnadeKoncernbidragInvesteringsverksamheten',
        contextRef: 'period1',
        standardRubrik:
          'Minskning av kassaflöde vid lämnade koncernbidrag i investeringsverksamheten',
        negateValue: true,
      })
    )
    .addRow(
      'forvarvImmateriellaAnlaggningstillgangar',
      label('Förvärv av immateriella anläggningstillgångar'),
      refs(),
      ixbrlCell(value(0), {
        type: 'monetaryItemType',
        name: 'se-gen-base:InvesteringImmateriellaAnlaggningstillgangar',
        contextRef: 'period0',
        standardRubrik:
          'Minskning av kassaflöde vid förvärv av immateriella anläggningstillgångar',
        negateValue: true,
      }),
      value(0),
      ixbrlCell(value(0), {
        type: 'monetaryItemType',
        name: 'se-gen-base:InvesteringImmateriellaAnlaggningstillgangar',
        contextRef: 'period1',
        standardRubrik:
          'Minskning av kassaflöde vid förvärv av immateriella anläggningstillgångar',
        negateValue: true,
      })
    )
    .addRow(
      'avyttringImmateriallaAnlaggningstillgangar',
      label('Avyttring av immateriella anläggningstillgångar'),
      refs(),
      ixbrlCell(value(0), {
        type: 'monetaryItemType',
        name: 'se-gen-base:ForsaljningImmateriellaAnlaggningstillgangar',
        contextRef: 'period0',
        standardRubrik:
          'Ökning av kassaflöde vid avyttring av immateriella anläggningstillgångar',
      }),
      value(0),
      ixbrlCell(value(0), {
        type: 'monetaryItemType',
        name: 'se-gen-base:ForsaljningImmateriellaAnlaggningstillgangar',
        contextRef: 'period1',
        standardRubrik:
          'Ökning av kassaflöde vid avyttring av immateriella anläggningstillgångar',
      })
    )
    .addRow(
      'forvarvMateriellaAnlaggningstillgangar',
      label('Förvärv av materiella anläggningstillgångar'),
      refs(),
      ixbrlCell(value(0), {
        type: 'monetaryItemType',
        name: 'se-gen-base:InvesteringMateriellaAnlaggningstillgangar',
        contextRef: 'period0',
        standardRubrik:
          'Minskning av kassaflöde vid förvärv av materiella anläggningstillgångar',
        negateValue: true,
      }),
      value(0),
      ixbrlCell(value(0), {
        type: 'monetaryItemType',
        name: 'se-gen-base:InvesteringMateriellaAnlaggningstillgangar',
        contextRef: 'period1',
        standardRubrik:
          'Minskning av kassaflöde vid förvärv av materiella anläggningstillgångar',
        negateValue: true,
      })
    )
    .addRow(
      'avyttringMateriellaAnlaggningstillgangar',
      label('Avyttring av materiella anläggningstillgångar'),
      refs(),
      ixbrlCell(value(0), {
        type: 'monetaryItemType',
        name: 'se-gen-base:ForsaljningMateriellaAnlaggningstillgangar',
        contextRef: 'period0',
        standardRubrik:
          'Ökning av kassaflöde vid avyttring av materiella anläggningstillgångar',
      }),
      value(0),
      ixbrlCell(value(0), {
        type: 'monetaryItemType',
        name: 'se-gen-base:ForsaljningMateriellaAnlaggningstillgangar',
        contextRef: 'period1',
        standardRubrik:
          'Ökning av kassaflöde vid avyttring av materiella anläggningstillgångar',
      })
    )
    .addRow(
      'forvarvFinansiellaAnlaggningstillgangar',
      label('Förvarv av finansiella anläggningstillgångar'),
      refs(),
      ixbrlCell(value(0), {
        type: 'monetaryItemType',
        name: 'se-gen-base:InvesteringFinansiellaAnlaggningstillgangar',
        contextRef: 'period0',
        standardRubrik:
          'Minskning av kassaflöde vid förvärv av finansiella anläggningstillgångar',
        negateValue: true,
      }),
      value(0),
      ixbrlCell(value(0), {
        type: 'monetaryItemType',
        name: 'se-gen-base:InvesteringFinansiellaAnlaggningstillgangar',
        contextRef: 'period1',
        standardRubrik:
          'Minskning av kassaflöde vid förvärv av finansiella anläggningstillgångar',
        negateValue: true,
      })
    )
    .addRow(
      'avyttringFinansiellaAnlaggningstillgangar',
      label('Avyttring/amortering av finansiella anläggningstillgångar'),
      refs(),
      ixbrlCell(value(0), {
        type: 'monetaryItemType',
        name: 'se-gen-base:ForsaljningFinansiellaAnlaggningstillgangar',
        contextRef: 'period0',
        standardRubrik:
          'Ökning av kassaflöde vid avyttring eller amortering av finansiella anläggningstillgångar',
      }),
      value(0),
      ixbrlCell(value(0), {
        type: 'monetaryItemType',
        name: 'se-gen-base:ForsaljningFinansiellaAnlaggningstillgangar',
        contextRef: 'period1',
        standardRubrik:
          'Ökning av kassaflöde vid avyttring eller amortering av finansiella anläggningstillgångar',
      })
    )
    .addRow(
      'okningMinskningKortfristigaPlaceringar',
      label('Ökning/minskning av kortfristiga placeringar'),
      refs(),
      ixbrlCell(value(0), {
        type: 'monetaryItemType',
        name: 'se-gen-base:ForandringKassaflodeOvrigaKortfristigaPlaceringar',
        contextRef: 'period0',
        standardRubrik:
          'Ökning (minskning) av kassaflöde vid förändring av kortfristiga placeringar',
      }),
      value(0),
      ixbrlCell(value(0), {
        type: 'monetaryItemType',
        name: 'se-gen-base:ForandringKassaflodeOvrigaKortfristigaPlaceringar',
        contextRef: 'period1',
        standardRubrik:
          'Ökning (minskning) av kassaflöde vid förändring av kortfristiga placeringar',
      })
    );
};

const addFinancialActivitiesRows = (
  rows: RowsBuilder<Cell>,
  previousYearKey: string | undefined,
  isDigitalSubmission: boolean
) => {
  !isDigitalSubmission &&
    addRowWithAdjustment(
      rows,
      '1',
      label('Aktiekapital'),
      or(multiply(-1, account('2081', 'change')), 0),
      or(multiply(-1, account('2081', 'change', previousYearKey)), 0)
    );

  addRowWithAdjustment(
    rows,
    '2',
    label('Nyemission'),
    or(multiply(-1, account('2087+2097', 'change')), 0),
    or(multiply(-1, account('2087+2097', 'change', previousYearKey)), 0),
    isDigitalSubmission
      ? {
          type: 'monetaryItemType',
          name: 'se-gen-base:NyemissionKassaflode',
          contextRef: 'period0',
          standardRubrik: 'Ökning av kassaflöde vid nyemission',
        }
      : undefined,
    isDigitalSubmission
      ? {
          type: 'monetaryItemType',
          name: 'se-gen-base:NyemissionKassaflode',
          contextRef: 'period1',
          standardRubrik: 'Ökning av kassaflöde vid nyemission',
        }
      : undefined
  );

  addRowWithAdjustment(
    rows,
    '3',
    label('Erhållna aktieägartillskott'),
    negativeChange('2093'),
    negativeChange('2093', previousYearKey),
    isDigitalSubmission
      ? {
          type: 'monetaryItemType',
          name: 'se-gen-base:ErhallnaAktieagartillskottKassaflode',
          contextRef: 'period0',
          standardRubrik: 'Ökning av kassaflöde vid erhållna tillskott',
        }
      : undefined,
    isDigitalSubmission
      ? {
          type: 'monetaryItemType',
          name: 'se-gen-base:ErhallnaAktieagartillskottKassaflode',
          contextRef: 'period1',
          standardRubrik: 'Ökning av kassaflöde vid erhållna tillskott',
        }
      : undefined
  );

  !isDigitalSubmission &&
    addRowWithAdjustment(
      rows,
      '4',
      label('Återbetalt aktieägartillskott'),
      positiveChange('2093'),
      positiveChange('2093', previousYearKey)
    );

  isDigitalSubmission &&
    rows.addRow(
      'erhallnaKoncernbidrag',
      label('Erhållna koncernbidrag'),
      refs(),
      ixbrlCell(value(0), {
        type: 'monetaryItemType',
        name: 'se-gen-base:ForandringKassaflodeErhallnaKoncernbidragFinansieringsverksamheten',
        contextRef: 'period0',
        standardRubrik: 'Ökning av kassaflöde vid erhållna koncernbidrag',
      }),
      value(0),
      ixbrlCell(value(0), {
        type: 'monetaryItemType',
        name: 'se-gen-base:ForandringKassaflodeErhallnaKoncernbidragFinansieringsverksamheten',
        contextRef: 'period1',
        standardRubrik: 'Ökning av kassaflöde vid erhållna koncernbidrag',
      })
    );

  isDigitalSubmission &&
    rows.addRow(
      'lamnadeKoncernbidrag',
      label('Lämnade koncernbidrag'),
      refs(),
      ixbrlCell(value(0), {
        type: 'monetaryItemType',
        name: 'se-gen-base:ForandringKassaflodeLamnadeKoncernbidragFinansieringsverksamheten',
        contextRef: 'period0',
        standardRubrik:
          'Minskning av kassaflöde vid lämnade koncernbidrag i finansieringsverksamheten',
        negateValue: true,
      }),
      value(0),
      ixbrlCell(value(0), {
        type: 'monetaryItemType',
        name: 'se-gen-base:ForandringKassaflodeLamnadeKoncernbidragFinansieringsverksamheten',
        contextRef: 'period1',
        standardRubrik:
          'Minskning av kassaflöde vid lämnade koncernbidrag i finansieringsverksamheten',
        negateValue: true,
      })
    );

  addRowWithAdjustment(
    rows,
    '5',
    label(!isDigitalSubmission ? 'Upptagna långfristiga lån' : 'Upptagna lån'),
    negativeChange('2350:2399'),
    negativeChange('2350:2399', previousYearKey),
    isDigitalSubmission
      ? {
          type: 'monetaryItemType',
          name: 'se-gen-base:UpptagnaLan',
          contextRef: 'period0',
          standardRubrik: 'Ökning av kassaflöde till följd av upptagna lån',
        }
      : undefined,
    isDigitalSubmission
      ? {
          type: 'monetaryItemType',
          name: 'se-gen-base:UpptagnaLan',
          contextRef: 'period1',
          standardRubrik: 'Ökning av kassaflöde till följd av upptagna lån',
        }
      : undefined
  );

  addRowWithAdjustment(
    rows,
    '6',
    label(
      !isDigitalSubmission
        ? 'Amortering långfristiga lån'
        : 'Amortering av skuld'
    ),
    positiveChange('2350:2399'),
    positiveChange('2350:2399', previousYearKey),
    isDigitalSubmission
      ? {
          type: 'monetaryItemType',
          name: 'se-gen-base:AmorteringSkuld',
          contextRef: 'period0',
          standardRubrik: 'Minskning av kassaflöde vid amortering av skuld',
          negateValue: true,
        }
      : undefined,
    isDigitalSubmission
      ? {
          type: 'monetaryItemType',
          name: 'se-gen-base:AmorteringSkuld',
          contextRef: 'period1',
          standardRubrik: 'Minskning av kassaflöde vid amortering av skuld',
          negateValue: true,
        }
      : undefined
  );

  addRowWithAdjustment(
    rows,
    '7',
    label(
      !isDigitalSubmission
        ? 'Ändring kortfristiga finansiella skulder'
        : 'Ökning/minskning av kortfristiga finansiella skulder'
    ),
    or(multiply(-1, account('2300:2349', 'change')), 0),
    or(multiply(-1, account('2300:2349', 'change', previousYearKey)), 0),
    isDigitalSubmission
      ? {
          type: 'monetaryItemType',
          name: 'se-gen-base:KortfristigaFinansiellaSkulder',
          contextRef: 'period0',
          standardRubrik:
            'Ökning (minskning) av kassaflöde vid förändring av kortfristiga finansiella skulder',
        }
      : undefined,
    isDigitalSubmission
      ? {
          type: 'monetaryItemType',
          name: 'se-gen-base:KortfristigaFinansiellaSkulder',
          contextRef: 'period1',
          standardRubrik:
            'Ökning (minskning) av kassaflöde vid förändring av kortfristiga finansiella skulder',
        }
      : undefined
  );

  addRowWithAdjustment(
    rows,
    '8',
    label('Utbetald utdelning'),
    id('payedDividend'),
    id('payedDividendPreviousInterval'),
    isDigitalSubmission
      ? {
          type: 'monetaryItemType',
          name: 'se-gen-base:UtbetaldUtdelningKassaflode',
          contextRef: 'period0',
          standardRubrik:
            'Minskning av kassaflöde till följd av utbetald utdelning',
          negateValue: true,
        }
      : undefined,
    isDigitalSubmission
      ? {
          type: 'monetaryItemType',
          name: 'se-gen-base:UtbetaldUtdelningKassaflode',
          contextRef: 'period1',
          standardRubrik:
            'Minskning av kassaflöde till följd av utbetald utdelning',
          negateValue: true,
        }
      : undefined
  );

  !isDigitalSubmission &&
    addRowWithAdjustment(
      rows,
      '9',
      msg(
        '{choice,select, 1 {Mottagna koncernbidrag} 2 {Lämnade koncernbidrag} other {Koncernbidrag}}',
        {
          // choice will be
          //    1, if there are positive contributions,
          //    2, if there are negative contributions and
          //    0, when no contribution.
          choice: ifOrElse(
            id('positiveGroupContributions'),
            '1',
            ifOrElse(id('negativeGroupContributions'), '2', '0')
          ),
        }
      ),
      id('groupContributions'),
      id('groupContributionsPreviousInterval')
    );
};

export const cashFlowStatementConfig = (
  accounts: Record<string, ReferenceAccountInformation>,
  period: TimePeriod,
  previousPeriod: TimePeriod | null,
  financialYears: ConfiguredFinancialYear[],
  previousYearKey: string | undefined,
  isDigitalSubmission: boolean
): CashFlowStatement => ({
  active: undefined,
  section: {
    active: false,
    main: table<IxbrlCell>(
      'cashFlowStatement.section.main',
      'label',
      'notes',
      {
        id: 'value',
        label: year(financialYears[0], period),
      },
      'adjustment',
      previousPeriod
        ? {
            id: 'previousYear',
            label: year(financialYears[1], previousPeriod),
          }
        : undefined
    )
      .addRows((rows) =>
        rows
          .addRow(
            'topDifference',
            label('Differens'),
            undefined,
            ref(id('cashFlowDifference')),
            undefined,
            ref(id('cashFlowDifferencePreviousInterval'))
          )
          .addRow('currentOperations', label('Den löpande verksamheten'))
          .addSubRows((rows) =>
            rows
              .addRow(
                'beforeChangeWorkingCapital',
                label('Kassaflöde före förändring av rörelsekapital'),
                refs()
              )
              .addSubRows((rows) => {
                rows
                  .addRow(
                    'operatingProfit',
                    label('Rörelseresultat'),
                    refs(),
                    ixbrlCell(ref(id('operatingProfit')), {
                      type: 'monetaryItemType',
                      name: 'se-gen-base:Rorelseresultat',
                      contextRef: 'period0',
                      saldo: 'credit',
                    }),
                    undefined,
                    ixbrlCell(ref(id('operatingProfitPreviousInterval')), {
                      type: 'monetaryItemType',
                      name: 'se-gen-base:Rorelseresultat',
                      contextRef: 'period1',
                      saldo: 'credit',
                    })
                  )
                  .addRow(
                    'adjustmentNotInCashFlow',
                    label(
                      'Justeringar för poster som inte ingår i kassaflödet:'
                    ),
                    undefined
                  );

                !isDigitalSubmission &&
                  rows
                    .addRow(
                      'activatedWork',
                      label(
                        'Aktiverat arbete för egen räkning (material, omkostnader och personal)'
                      ),
                      refs(),
                      ref(id('sumActivatedWork')),
                      undefined,
                      ref(id('sumActivatedWorkPreviousInterval'))
                    )
                    .addSubRows((rows) =>
                      accountRangeRows(
                        accounts,
                        3800,
                        3899,
                        rows,
                        (n, previousYearKey) =>
                          account(n, 'change', previousYearKey),
                        true,
                        previousYearKey
                      ).build()
                    )
                    .newRowTemplate(
                      label('$label'),
                      undefined,
                      ref(
                        sum(
                          multiply(-1, account('$account', 'change')),
                          or(id('$id.adjustment'), 0)
                        )
                      ),
                      value(0),
                      ref(
                        or(
                          multiply(
                            -1,
                            account('$account', 'change', previousYearKey)
                          ),
                          0
                        )
                      )
                    );

                rows
                  .addRow(
                    'dividendsAndProfitShares',
                    label(
                      !isDigitalSubmission
                        ? 'Utdelning och resultatandelar'
                        : 'Erhållen utdelning'
                    ),
                    refs(),
                    ixbrlCell(ref(id('sumDividendsAndProfitShares')), {
                      type: 'monetaryItemType',
                      name: 'se-gen-base:ErhallenUtdelning',
                      contextRef: 'period0',
                      standardRubrik:
                        'Ökning av kassaflöde vid erhållen utdelning',
                    }),
                    undefined,
                    ixbrlCell(
                      ref(id('sumDividendsAndProfitSharesPreviousInterval')),
                      {
                        type: 'monetaryItemType',
                        name: 'se-gen-base:ErhallenUtdelning',
                        contextRef: 'period1',
                        standardRubrik:
                          'Ökning av kassaflöde vid erhållen utdelning',
                      }
                    )
                  )
                  .addSubRows((rows) =>
                    accountRangeRows(
                      accounts,
                      8000,
                      8299,
                      rows,
                      undefined,
                      true,
                      previousYearKey
                    ).build()
                  )
                  .newRowTemplate(
                    label('$label'),
                    undefined,
                    ref(
                      sum(
                        multiply(-1, account('$account', 'change')),
                        or(id('$id.adjustment'), 0)
                      )
                    ),
                    value(0),
                    ref(
                      or(
                        multiply(
                          -1,
                          account('$account', 'change', previousYearKey)
                        ),
                        0
                      )
                    )
                  );

                !isDigitalSubmission &&
                  rows
                    .addRow(
                      'capitalLoss',
                      label('Realisationsförlust'),
                      refs(),
                      ref(id('sumCapitalLoss')),
                      undefined,
                      ref(id('sumCapitalLossPreviousInterval'))
                    )
                    .addSubRows((rows) =>
                      accountRangeRows(
                        accounts,
                        7970,
                        7979,
                        rows,
                        (n, previousYearKey) =>
                          multiply(-1, account(n, undefined, previousYearKey)),
                        true,
                        previousYearKey
                      ).build()
                    )
                    .newRowTemplate(
                      label('$label'),
                      undefined,
                      ref(
                        sum(
                          account('$account', 'change'),
                          or(id('$id.adjustment'), 0)
                        )
                      ),
                      value(0),
                      ref(or(account('$account', 'change', previousYearKey), 0))
                    );

                !isDigitalSubmission &&
                  rows
                    .addRow(
                      'capitalGain',
                      label('Realisationsvinst'),
                      refs(),
                      ref(id('sumCapitalGain')),
                      undefined,
                      ref(id('sumCapitalGainPreviousInterval'))
                    )
                    .addSubRows((rows) =>
                      accountRangeRows(
                        accounts,
                        3970,
                        3979,
                        rows,
                        (n, previousYearKey) =>
                          multiply(-1, account(n, undefined, previousYearKey)),
                        true,
                        previousYearKey
                      ).build()
                    )
                    .newRowTemplate(
                      label('$label'),
                      undefined,
                      ref(
                        sum(
                          account('$account', 'change'),
                          or(id('$id.adjustment'), 0)
                        )
                      ),
                      value(0),
                      ref(or(account('$account', 'change', previousYearKey), 0))
                    );

                isDigitalSubmission &&
                  rows
                    .addRow(
                      'capitalGainCapitalLoss',
                      label('Realisationsvinst/Realisationsförlust'),
                      refs(),
                      ixbrlCell(ref(id('sumCapitalGainCapitalLoss')), {
                        type: 'monetaryItemType',
                        name: 'se-gen-base:RealisationsVinstForlustRorelseresultat',
                        contextRef: 'period0',
                      }),
                      undefined,
                      ixbrlCell(
                        ref(id('sumCapitalGainCapitalLossPreviousInterval')),
                        {
                          type: 'monetaryItemType',
                          name: 'se-gen-base:RealisationsVinstForlustRorelseresultat',
                          contextRef: 'period1',
                        }
                      )
                    )
                    .addSubRows((rows) =>
                      accountRangeRows(
                        accounts,
                        3970,
                        3979,
                        rows,
                        (n, previousYearKey) =>
                          multiply(-1, account(n, undefined, previousYearKey)),
                        true,
                        previousYearKey
                      ).build()
                    )
                    .addSubRows((rows) =>
                      accountRangeRows(
                        accounts,
                        7970,
                        7979,
                        rows,
                        (n, previousYearKey) =>
                          multiply(-1, account(n, undefined, previousYearKey)),
                        true,
                        previousYearKey
                      ).build()
                    )
                    .newRowTemplate(
                      label('$label'),
                      undefined,
                      ref(
                        sum(
                          account('$account', 'change'),
                          or(id('$id.adjustment'), 0)
                        )
                      ),
                      value(0),
                      ref(or(account('$account', 'change', previousYearKey), 0))
                    );

                !isDigitalSubmission &&
                  rows
                    .addRow(
                      'writeDownChange',
                      label('Nedskrivningar (förändring)'),
                      refs(),
                      ref(id('sumWriteDownChange')),
                      undefined,
                      ref(id('sumWriteDownChangePreviousInterval'))
                    )
                    .addSubRows((rows) =>
                      accountRangeRows(
                        accounts,
                        7700,
                        7799,
                        rows,
                        (n, previousYearKey) =>
                          account(n, 'change', previousYearKey),
                        true,
                        previousYearKey
                      ).build()
                    )
                    .newRowTemplate(
                      label('$label'),
                      undefined,
                      ref(
                        sum(
                          multiply(-1, account('$account', 'change')),
                          or(id('$id.adjustment'), 0)
                        )
                      ),
                      value(0),
                      ref(
                        or(
                          multiply(
                            -1,
                            account('$account', 'change', previousYearKey)
                          ),
                          0
                        )
                      )
                    );

                !isDigitalSubmission &&
                  rows
                    .addRow(
                      'depreciations',
                      label('Avskrivningar'),
                      refs(),
                      ref(id('sumDepreciations')),
                      undefined,
                      ref(id('sumDepreciationsPreviousInterval'))
                    )
                    .addSubRows((rows) =>
                      accountRangeRows(
                        accounts,
                        7800,
                        7899,
                        rows,
                        (n, previousYearKey) =>
                          multiply(-1, account(n, undefined, previousYearKey)),
                        true,
                        previousYearKey
                      ).build()
                    )
                    .newRowTemplate(
                      label('$label'),
                      undefined,
                      ref(
                        sum(
                          account('$account', 'change'),
                          or(id('$id.adjustment'), 0)
                        )
                      ),
                      value(0),
                      ref(or(account('$account', 'change', previousYearKey), 0))
                    );

                isDigitalSubmission &&
                  rows
                    .addRow(
                      'depreciationsAndWriteDownChange',
                      label('Avskrivningar och nedskrivningar'),
                      refs(),
                      ixbrlCell(ref(id('sumDepreciationsAndWriteDownChange')), {
                        type: 'monetaryItemType',
                        name: 'se-gen-base:JusteringPosterInteIngarKassaflodeAvskrivningarNedskrivningar',
                        contextRef: 'period0',
                      }),
                      undefined,
                      ixbrlCell(
                        ref(
                          id(
                            'sumDepreciationsAndWriteDownChangePreviousInterval'
                          )
                        ),
                        {
                          type: 'monetaryItemType',
                          name: 'se-gen-base:JusteringPosterInteIngarKassaflodeAvskrivningarNedskrivningar',
                          contextRef: 'period1',
                        }
                      )
                    )
                    .addSubRows((rows) =>
                      accountRangeRows(
                        accounts,
                        7800,
                        7899,
                        rows,
                        (n, previousYearKey) =>
                          multiply(-1, account(n, undefined, previousYearKey)),
                        true,
                        previousYearKey
                      ).build()
                    )
                    .addSubRows((rows) =>
                      accountRangeRows(
                        accounts,
                        7700,
                        7799,
                        rows,
                        (n, previousYearKey) =>
                          account(n, 'change', previousYearKey),
                        true,
                        previousYearKey
                      ).build()
                    )
                    .newRowTemplate(
                      label('$label'),
                      undefined,
                      ref(
                        sum(
                          account('$account', 'change'),
                          or(id('$id.adjustment'), 0)
                        )
                      ),
                      value(0),
                      ref(or(account('$account', 'change', previousYearKey), 0))
                    );

                isDigitalSubmission &&
                  rows
                    .addRow(
                      'valutakursDifferenser',
                      label('Valutakursdifferenser'),
                      refs(),
                      ixbrlCell(ref(id('sumValutakursDifferenser')), {
                        type: 'monetaryItemType',
                        name: 'se-gen-base:JusteringPosterInteIngarKassaflodeValutakursdifferenser',
                        contextRef: 'period0',
                      }),
                      undefined,
                      ixbrlCell(
                        ref(id('sumValutakursDifferenserPreviousInterval')),
                        {
                          type: 'monetaryItemType',
                          name: 'se-gen-base:JusteringPosterInteIngarKassaflodeValutakursdifferenser',
                          contextRef: 'period1',
                        }
                      )
                    )
                    .newRowTemplate(
                      label('$label'),
                      undefined,
                      ref(
                        sum(
                          account('$account', 'change'),
                          or(id('$id.adjustment'), 0)
                        )
                      ),
                      value(0),
                      ref(or(account('$account', 'change', previousYearKey), 0))
                    );

                isDigitalSubmission &&
                  rows
                    .addRow(
                      'avsattningPensioner',
                      label('Avsättning för pensioner'),
                      refs(),
                      ixbrlCell(ref(id('sumAvsattningPensioner')), {
                        type: 'monetaryItemType',
                        name: 'se-gen-base:JusteringPosterInteIngarKassaflodeAvsattningPensioner',
                        contextRef: 'period0',
                      }),
                      undefined,
                      ixbrlCell(
                        ref(id('sumAvsattningPensionerPreviousInterval')),
                        {
                          type: 'monetaryItemType',
                          name: 'se-gen-base:JusteringPosterInteIngarKassaflodeAvsattningPensioner',
                          contextRef: 'period1',
                        }
                      )
                    )
                    .newRowTemplate(
                      label('$label'),
                      undefined,
                      ref(
                        sum(
                          account('$account', 'change'),
                          or(id('$id.adjustment'), 0)
                        )
                      ),
                      value(0),
                      ref(or(account('$account', 'change', previousYearKey), 0))
                    );

                isDigitalSubmission &&
                  rows
                    .addRow(
                      'avsattningGarantier',
                      label('Avsättning för garantier'),
                      refs(),
                      ixbrlCell(ref(id('sumAvsattningGarantier')), {
                        type: 'monetaryItemType',
                        name: 'se-gen-base:JusteringPosterInteIngarKassaflodeAvsattningGarantier',
                        contextRef: 'period0',
                      }),
                      undefined,
                      ixbrlCell(
                        ref(id('sumAvsattningGarantierPreviousInterval')),
                        {
                          type: 'monetaryItemType',
                          name: 'se-gen-base:JusteringPosterInteIngarKassaflodeAvsattningGarantier',
                          contextRef: 'period1',
                        }
                      )
                    )
                    .newRowTemplate(
                      label('$label'),
                      undefined,
                      ref(
                        sum(
                          account('$account', 'change'),
                          or(id('$id.adjustment'), 0)
                        )
                      ),
                      value(0),
                      ref(or(account('$account', 'change', previousYearKey), 0))
                    );

                isDigitalSubmission &&
                  rows
                    .addRow(
                      'avsattningOmstrukturering',
                      label('Avsättning för omstrukturering'),
                      refs(),
                      ixbrlCell(ref(id('sumAvsattningOmstrukturering')), {
                        type: 'monetaryItemType',
                        name: 'se-gen-base:JusteringPosterInteIngarKassaflodeAvsattningOmstrukturering',
                        contextRef: 'period0',
                      }),
                      undefined,
                      ixbrlCell(
                        ref(id('sumAvsattningOmstruktureringPreviousInterval')),
                        {
                          type: 'monetaryItemType',
                          name: 'se-gen-base:JusteringPosterInteIngarKassaflodeAvsattningOmstrukturering',
                          contextRef: 'period1',
                        }
                      )
                    )
                    .newRowTemplate(
                      label('$label'),
                      undefined,
                      ref(
                        sum(
                          account('$account', 'change'),
                          or(id('$id.adjustment'), 0)
                        )
                      ),
                      value(0),
                      ref(or(account('$account', 'change', previousYearKey), 0))
                    );

                rows
                  .addRow(
                    'receivedInterest',
                    label('Erhållen ränta m.m.'),
                    refs(),
                    ixbrlCell(ref(id('sumReceivedInterest')), {
                      type: 'monetaryItemType',
                      name: 'se-gen-base:ErhallenRanta',
                      contextRef: 'period0',
                      standardRubrik: 'Ökning av kassaflöde vid erhållen ränta',
                    }),
                    undefined,
                    ixbrlCell(ref(id('sumReceivedInterestPreviousInterval')), {
                      type: 'monetaryItemType',
                      name: 'se-gen-base:ErhallenRanta',
                      contextRef: 'period1',
                      standardRubrik: 'Ökning av kassaflöde vid erhållen ränta',
                    })
                  )
                  .addSubRows((rows) =>
                    accountRangeRows(
                      accounts,
                      8300,
                      8399,
                      rows,
                      undefined,
                      true,
                      previousYearKey
                    ).build()
                  )
                  .newRowTemplate(
                    label('$label'),
                    undefined,
                    ref(
                      sum(
                        multiply(-1, account('$account', 'change')),
                        or(id('$id.adjustment'), 0)
                      )
                    ),
                    value(0),
                    ref(
                      or(
                        multiply(
                          -1,
                          account('$account', 'change', previousYearKey)
                        ),
                        0
                      )
                    )
                  );

                rows
                  .addRow(
                    'paidInterest',
                    label('Erlagd ränta'),
                    refs(),
                    ixbrlCell(ref(id('sumPaidInterest')), {
                      type: 'monetaryItemType',
                      name: 'se-gen-base:ErlagdRanta',
                      contextRef: 'period0',
                      standardRubrik:
                        'Minskning av kassaflöde vid erlagd ränta',
                      negateValue: true,
                    }),
                    undefined,
                    ixbrlCell(ref(id('sumPaidInterestPreviousInterval')), {
                      type: 'monetaryItemType',
                      name: 'se-gen-base:ErlagdRanta',
                      contextRef: 'period1',
                      standardRubrik:
                        'Minskning av kassaflöde vid erlagd ränta',
                      negateValue: true,
                    })
                  )
                  .addSubRows((rows) =>
                    accountRangeRows(
                      accounts,
                      8400,
                      8499,
                      rows,
                      undefined,
                      true,
                      previousYearKey
                    ).build()
                  )
                  .newRowTemplate(
                    label('$label'),
                    undefined,
                    ref(
                      sum(
                        multiply(-1, account('$account', 'change')),
                        or(id('$id.adjustment'), 0)
                      )
                    ),
                    value(0),
                    ref(
                      or(
                        multiply(
                          -1,
                          account('$account', 'change', previousYearKey)
                        ),
                        0
                      )
                    )
                  );

                rows
                  .addRow(
                    'paidIncomeTax',
                    label('Betald inkomstskatt'),
                    refs(),
                    ixbrlCell(ref(id('sumPaidIncomeTax')), {
                      type: 'monetaryItemType',
                      name: 'se-gen-base:BetaldSkatt',
                      contextRef: 'period0',
                      standardRubrik:
                        'Minskning av kassaflöde vid betald inkomstskatt',
                      negateValue: true,
                    }),
                    undefined,
                    ixbrlCell(ref(id('sumPaidIncomeTaxPreviousInterval')), {
                      type: 'monetaryItemType',
                      name: 'se-gen-base:BetaldSkatt',
                      contextRef: 'period1',
                      standardRubrik:
                        'Minskning av kassaflöde vid betald inkomstskatt',
                      negateValue: true,
                    })
                  )
                  .addSubRows((rows) =>
                    accountRangeRows(
                      accounts,
                      2500,
                      2599,
                      rows,
                      (n, previousYearKey) =>
                        account(n, 'change', previousYearKey),
                      true,
                      previousYearKey
                    )
                      .addRow(
                        'yearTax',
                        label('Årets skatt'),
                        undefined,
                        ref(id('yearTax')),
                        undefined,
                        ref(id('yearTaxPreviousInterval'))
                      )
                      .build()
                  )
                  .newRowTemplate(
                    label('$label'),
                    undefined,
                    ref(
                      sum(
                        multiply(-1, account('$account', 'change')),
                        or(id('$id.adjustment'), 0)
                      )
                    ),
                    value(0),
                    ref(
                      or(
                        multiply(
                          -1,
                          account('$account', 'change', previousYearKey)
                        ),
                        0
                      )
                    )
                  );

                return rows.build();
              })
              .addRow(
                'sum',
                label(
                  'Summa Kassaflöde från den löpande verksamheten före förändringar av rörelsekapital'
                ),
                refs(),
                ixbrlCell(ref(id('sumBeforeChangeWorkingCapital')), {
                  type: 'monetaryItemType',
                  name: 'se-gen-base:KassaflodeLopandeVerksamhetenForeForandringarRorelsekapital',
                  contextRef: 'period0',
                  standardRubrik:
                    'Ökning (minskning) av kassaflöde från den löpande verksamheten före förändringar av rörelsekapital',
                }),
                undefined,
                ixbrlCell(
                  ref(id('sumBeforeChangeWorkingCapitalPreviousInterval')),
                  {
                    type: 'monetaryItemType',
                    name: 'se-gen-base:KassaflodeLopandeVerksamhetenForeForandringarRorelsekapital',
                    contextRef: 'period1',
                    standardRubrik:
                      'Ökning (minskning) av kassaflöde från den löpande verksamheten före förändringar av rörelsekapital',
                  }
                )
              )
              .addRow(
                'changeWorkingCapital',
                label('Kassaflöde från förändringar av rörelsekapital'),
                refs()
              )
              .addSubRows((rows) =>
                rows
                  .addRow(
                    'changeStockAndWorkInProgress',
                    label(
                      'Minskning(+)/ökning(-) av varulager/pågående arbete'
                    ),
                    refs(),
                    ixbrlCell(ref(id('sumChangeStockAndWorkInProgress')), {
                      type: 'monetaryItemType',
                      name: 'se-gen-base:ForandringKassaflodeVarulagerPagaendeArbeten',
                      contextRef: 'period0',
                      standardRubrik:
                        'Ökning (minskning) av kassaflöde vid förändring av varulager och pågående arbeten',
                    }),
                    undefined,
                    ixbrlCell(
                      ref(
                        id('sumChangeStockAndWorkInProgressPreviousInterval')
                      ),
                      {
                        type: 'monetaryItemType',
                        name: 'se-gen-base:ForandringKassaflodeVarulagerPagaendeArbeten',
                        contextRef: 'period1',
                        standardRubrik:
                          'Ökning (minskning) av kassaflöde vid förändring av varulager och pågående arbeten',
                      }
                    )
                  )
                  .addSubRows((rows) =>
                    accountRangeRows(
                      accounts,
                      1400,
                      1499,
                      rows,
                      (n, previousYearKey) =>
                        account(n, 'change', previousYearKey),
                      true,
                      previousYearKey
                    ).build()
                  )
                  .newRowTemplate(
                    label('$label'),
                    undefined,
                    ref(
                      sum(
                        multiply(-1, account('$account', 'change')),
                        or(id('$id.adjustment'), 0)
                      )
                    ),
                    value(0),
                    ref(
                      or(
                        multiply(
                          -1,
                          account('$account', 'change', previousYearKey)
                        ),
                        0
                      )
                    )
                  )

                  .addRow(
                    'changeAccountReceivable',
                    label('Minskning(+)/ökning(-) av kundfordringar'),
                    refs(),
                    ixbrlCell(ref(id('sumChangeAccountReceivable')), {
                      type: 'monetaryItemType',
                      name: 'se-gen-base:ForandringKassaflodeKundfordringar',
                      contextRef: 'period0',
                      standardRubrik:
                        'Ökning (minskning) av kassaflöde vid förändring av kundfordringar',
                    }),
                    undefined,
                    ixbrlCell(
                      ref(id('sumChangeAccountReceivablePreviousInterval')),
                      {
                        type: 'monetaryItemType',
                        name: 'se-gen-base:ForandringKassaflodeKundfordringar',
                        contextRef: 'period1',
                        standardRubrik:
                          'Ökning (minskning) av kassaflöde vid förändring av kundfordringar',
                      }
                    )
                  )
                  .addSubRows((rows) =>
                    accountRangeRows(
                      accounts,
                      1500,
                      1599,
                      rows,
                      (n, previousYearKey) =>
                        account(n, 'change', previousYearKey),
                      true,
                      previousYearKey
                    ).build()
                  )
                  .newRowTemplate(
                    label('$label'),
                    undefined,
                    ref(
                      sum(
                        multiply(-1, account('$account', 'change')),
                        or(id('$id.adjustment'), 0)
                      )
                    ),
                    value(0),
                    ref(
                      or(
                        multiply(
                          -1,
                          account('$account', 'change', previousYearKey)
                        ),
                        0
                      )
                    )
                  )

                  .addRow(
                    'changeReceivables',
                    label('Minskning(+)/ökning(-) av kortfristiga fordringar'),
                    refs(),
                    ixbrlCell(ref(id('sumChangeReceivables')), {
                      type: 'monetaryItemType',
                      name: 'se-gen-base:ForandringKassaflodeOvrigaKortfristigaFordringar',
                      contextRef: 'period0',
                      standardRubrik:
                        'Ökning (minskning) av kassaflöde vid förändring av övriga kortfristiga fordringar',
                    }),
                    undefined,
                    ixbrlCell(ref(id('sumChangeReceivablesPreviousInterval')), {
                      type: 'monetaryItemType',
                      name: 'se-gen-base:ForandringKassaflodeOvrigaKortfristigaFordringar',
                      contextRef: 'period1',
                      standardRubrik:
                        'Ökning (minskning) av kassaflöde vid förändring av övriga kortfristiga fordringar',
                    })
                  )
                  .addSubRows((rows) =>
                    accountRangeRows(
                      accounts,
                      1600,
                      1869,
                      rows,
                      (n, previousYearKey) =>
                        account(n, 'change', previousYearKey),
                      true,
                      previousYearKey
                    ).build()
                  )
                  .newRowTemplate(
                    label('$label'),
                    undefined,
                    ref(
                      sum(
                        multiply(-1, account('$account', 'change')),
                        or(id('$id.adjustment'), 0)
                      )
                    ),
                    value(0),
                    ref(
                      or(
                        multiply(
                          -1,
                          account('$account', 'change', previousYearKey)
                        ),
                        0
                      )
                    )
                  )

                  .addRow(
                    'changeTradeCreditors',
                    label('Minskning(-)/ökning(+) av leverantörsskulder'),
                    refs(),
                    ixbrlCell(ref(id('sumChangeTradeCreditors')), {
                      type: 'monetaryItemType',
                      name: 'se-gen-base:ForandringKassaflodeLeverantorsskulder',
                      contextRef: 'period0',
                      standardRubrik:
                        'Ökning (minskning) av kassaflöde vid förändring av leverantörsskulder',
                    }),
                    undefined,
                    ixbrlCell(
                      ref(id('sumChangeTradeCreditorsPreviousInterval')),
                      {
                        type: 'monetaryItemType',
                        name: 'se-gen-base:ForandringKassaflodeLeverantorsskulder',
                        contextRef: 'period1',
                        standardRubrik:
                          'Ökning (minskning) av kassaflöde vid förändring av leverantörsskulder',
                      }
                    )
                  )
                  .addSubRows((rows) =>
                    accountRangeRows(
                      accounts,
                      2400,
                      2499,
                      rows,
                      (n, previousYearKey) =>
                        account(n, 'change', previousYearKey),
                      true,
                      previousYearKey
                    ).build()
                  )
                  .newRowTemplate(
                    label('$label'),
                    undefined,
                    ref(
                      sum(
                        multiply(-1, account('$account', 'change')),
                        or(id('$id.adjustment'), 0)
                      )
                    ),
                    value(0),
                    ref(
                      or(
                        multiply(
                          -1,
                          account('$account', 'change', previousYearKey)
                        ),
                        0
                      )
                    )
                  )
                  .addRow(
                    'changeCurrentLiabilities',
                    label('Minskning(-)/ökning(+) av kortfristiga skulder'),
                    refs(),
                    ixbrlCell(ref(id('sumChangeCurrentLiabilities')), {
                      type: 'monetaryItemType',
                      name: 'se-gen-base:ForandringKassaflodeOvrigaKortfristigaSkulder',
                      contextRef: 'period0',
                      standardRubrik:
                        'Ökning (minskning) av kassaflöde vid förändring av övriga kortfristiga skulder',
                    }),
                    undefined,
                    ixbrlCell(
                      ref(id('sumChangeCurrentLiabilitiesPreviousInterval')),
                      {
                        type: 'monetaryItemType',
                        name: 'se-gen-base:ForandringKassaflodeOvrigaKortfristigaSkulder',
                        contextRef: 'period1',
                        standardRubrik:
                          'Ökning (minskning) av kassaflöde vid förändring av övriga kortfristiga skulder',
                      }
                    )
                  )
                  .addSubRows((rows) =>
                    accountRangeRows(
                      accounts,
                      2600,
                      2999,
                      rows,
                      (n, previousYearKey) =>
                        account(n, 'change', previousYearKey),
                      true,
                      previousYearKey
                    ).build()
                  )
                  .newRowTemplate(
                    label('$label'),
                    undefined,
                    ref(
                      sum(
                        multiply(-1, account('$account', 'change')),
                        or(id('$id.adjustment'), 0)
                      )
                    ),
                    value(0),
                    ref(
                      or(
                        multiply(
                          -1,
                          account('$account', 'change', previousYearKey)
                        ),
                        0
                      )
                    )
                  )

                  .build()
              )
              .addRow(
                'sum2',
                label('Summa kassaflöde från förändringar av rörelsekapital'),
                refs(),
                ref(id('sumChangeWorkingCapital')),
                undefined,
                ref(id('sumChangeWorkingCapitalPreviousInterval'))
              )
              .build()
          )

          .addRow(
            'sumCurrentOperations',
            label('Summa kassaflöde från den löpande verksamheten'),
            undefined,
            ixbrlCell(ref(id('sumCurrentOperations')), {
              type: 'monetaryItemType',
              name: 'se-gen-base:KassaflodeLopandeVerksamheten',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av kassaflöde från den löpande verksamheten',
            }),
            undefined,
            ixbrlCell(ref(id('sumCurrentOperationsPreviousInterval')), {
              type: 'monetaryItemType',
              name: 'se-gen-base:KassaflodeLopandeVerksamheten',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av kassaflöde från den löpande verksamheten',
            })
          )
          .addRow('empty1', label(''))
          .addRow('investmentActivities', label('Investeringsverksamheten'))
          .addSubRows((rows) => {
            rows.addRow('content').addSubRows((rows) => {
              !isDigitalSubmission
                ? addInvestmentRows(rows, previousYearKey)
                : addInvestmentRowsDigitalSubmission(rows);

              return rows.build();
            });

            !isDigitalSubmission &&
              rows.newRowTemplate(
                value(''),
                refs(),
                value(0),
                value(0),
                value(0)
              );

            return rows.build();
          })
          .addRow(
            'sumInvestmentActivities',
            label('Summa kassaflöde från investeringsverksamheten'),
            undefined,
            ixbrlCell(ref(id('sumInvestmentActivities')), {
              type: 'monetaryItemType',
              name: 'se-gen-base:KassaflodeInvesteringsverksamheten',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av kassaflöde från investeringsverksamheten',
            }),
            undefined,
            ixbrlCell(ref(id('sumInvestmentActivitiesPreviousInterval')), {
              type: 'monetaryItemType',
              name: 'se-gen-base:KassaflodeInvesteringsverksamheten',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av kassaflöde från investeringsverksamheten',
            })
          )
          .addRow('empty2', label(''))
          .addRow('financialActivities', label('Finansieringsverksamheten'))
          .addSubRows((rows) => {
            rows.addRow('content').addSubRows((rows) => {
              addFinancialActivitiesRows(
                rows,
                previousYearKey,
                isDigitalSubmission
              );

              return rows.build();
            });

            !isDigitalSubmission &&
              rows.newRowTemplate(
                value(''),
                refs(),
                value(0),
                value(0),
                value(0)
              );

            return rows.build();
          })
          .addRow(
            'sumFinancialActivities',
            label('Summa kassaflöde från finansieringsverksamheten'),
            undefined,
            ixbrlCell(ref(id('sumFinancialActivities')), {
              type: 'monetaryItemType',
              name: 'se-gen-base:KassaflodeFinansieringsverksamheten',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av kassaflöde från finansieringsverksamheten',
            }),
            undefined,
            ixbrlCell(ref(id('sumFinancialActivitiesPreviousInterval')), {
              type: 'monetaryItemType',
              name: 'se-gen-base:KassaflodeFinansieringsverksamheten',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av kassaflöde från finansieringsverksamheten',
            })
          )
          .addRow('empty3', label(''))
          .addRow(
            'totalSum',
            label('Totalt kassaflöde från ovanstående 3 delar'), // tagged as Årets kassaflöde
            undefined,
            ixbrlCell(ref(id('sumOperationAndActivities')), {
              type: 'monetaryItemType',
              name: 'se-gen-base:AretsKassaflode',
              contextRef: 'period0',
              standardRubrik: 'Ökning (minskning) av kassaflöde',
            }),
            undefined,
            ixbrlCell(ref(id('sumOperationAndActivitiesPreviousInterval')), {
              type: 'monetaryItemType',
              name: 'se-gen-base:AretsKassaflode',
              contextRef: 'period1',
              standardRubrik: 'Ökning (minskning) av kassaflöde',
            })
          )
          .addRow('empty4', label(''))
          .addRow('changeLiquidAssets', label('Förändring av likvida medel'))
          .addSubRows((rows) =>
            rows
              .addRow(
                '1',
                msg(
                  'Förändring av likvida medel enligt denna kassaflödesanalys',
                  {
                    diff: 'id(cashFlowDifference)',
                  }
                ),
                undefined,
                ref(
                  sum(
                    id('sumCurrentOperations'),
                    id('sumFinancialActivities'),
                    id('sumInvestmentActivities')
                  )
                ),
                undefined,
                ref(
                  sum(
                    id('sumCurrentOperationsPreviousInterval'),
                    id('sumFinancialActivitiesPreviousInterval'),
                    id('sumInvestmentActivitiesPreviousInterval')
                  )
                )
              )
              .addRow(
                '2',
                label(
                  `Likvida medel vid ${
                    period === null ? 'årets' : 'periodens'
                  } början`
                ),
                refs(),
                // likvida medel vid årets början = balans1 (incoming)
                ixbrlCell(ref(id('liquidAssetsStart')), {
                  type: 'monetaryItemType',
                  name: 'se-gen-base:LikvidaMedel',
                  contextRef: 'balans1',
                  saldo: 'debit',
                }),
                undefined,
                ixbrlCell(ref(id('liquidAssetsStartPreviousInterval')), {
                  type: 'monetaryItemType',
                  name: 'se-gen-base:LikvidaMedel',
                  contextRef: 'balans2',
                  saldo: 'debit',
                })
              )
              .addRow(
                'booked',
                label(
                  'Förändring av likvida medel enligt bokföringen (Kassaflödet)'
                ),
                refs(),
                ref(id('liquidAssetsChange')),
                undefined,
                ref(id('liquidAssetsChangePreviousInterval'))
              )
              .addSubRows((rows) =>
                rows
                  .addRow('accounts')
                  .addSubRows((rows) =>
                    accountRangeRows(
                      accounts,
                      1870,
                      1999,
                      rows,
                      (n, previousYearKey) =>
                        multiply(-1, account(n, 'change', previousYearKey)),
                      true,
                      previousYearKey
                    ).build()
                  )
                  .newRowTemplate(
                    label('$label'),
                    undefined,
                    ref(
                      sum(
                        account('$account', 'change'),
                        or(id('$id.adjustment'), 0)
                      )
                    ),
                    value(0),
                    ref(or(account('$account', 'change', previousYearKey), 0))
                  )
                  .build()
              )
              .addRow(
                '4',
                label('Kursdifferens i likvida medel'),
                refs(),
                ixbrlCell(value(0), {
                  type: 'monetaryItemType',
                  name: 'se-gen-base:LikvidaMedelKursdifferens',
                  contextRef: 'period0',
                  standardRubrik:
                    'Ökning (minskning) av likvida medel vid kursdifferenser',
                }),
                undefined,
                ixbrlCell(value(0), {
                  type: 'monetaryItemType',
                  name: 'se-gen-base:LikvidaMedelKursdifferens',
                  contextRef: 'period1',
                  standardRubrik:
                    'Ökning (minskning) av likvida medel vid kursdifferenser',
                })
              )

              .build()
          )
          .addRow(
            '5',
            label(
              `Likvida medel vid ${
                period === null ? 'årets' : 'periodens'
              } slut`
            ),
            refs(),
            // likvida medel vid årets slut = balans0 (incoming)
            ixbrlCell(ref(id('liquidAssetsEnd')), {
              type: 'monetaryItemType',
              name: 'se-gen-base:LikvidaMedel',
              contextRef: 'balans0',
              saldo: 'debit',
            }),
            undefined,
            ixbrlCell(ref(id('liquidAssetsEndPreviousInterval')), {
              type: 'monetaryItemType',
              name: 'se-gen-base:LikvidaMedel',
              contextRef: 'balans1',
              saldo: 'debit',
            })
          )
          .addRow(
            'difference',
            label('Differens'),
            undefined,
            ref(id('cashFlowDifference')),
            undefined,
            ref(id('cashFlowDifferencePreviousInterval'))
          )
          .build()
      )
      .build(),
    otherAccounts: table(
      'cashFlowStatement.section.otherAccounts',
      'label',
      'value'
    )
      .addRows((rows) => {
        otherAccountsRange(accounts, 1000, 1009, rows);
        otherAccountsRange(accounts, 1016, 1019, rows);
        otherAccountsRange(accounts, 1026, 1029, rows);
        otherAccountsRange(accounts, 1036, 1039, rows);
        otherAccountsRange(accounts, 1046, 1049, rows);
        otherAccountsRange(accounts, 1056, 1059, rows);
        otherAccountsRange(accounts, 1066, 1069, rows);
        otherAccountsRange(accounts, 1076, 1079, rows);
        otherAccountsRange(accounts, 1086, 1089, rows);
        otherAccountsRange(accounts, 1096, 1099, rows);
        otherAccountsRange(accounts, 1101, 1109, rows);
        otherAccountsRange(accounts, 1116, 1118, rows);
        otherAccountsRange(accounts, 1126, 1128, rows);
        otherAccountsRange(accounts, 1136, 1138, rows);
        otherAccountsRange(accounts, 1146, 1148, rows);
        otherAccountsRange(accounts, 1156, 1179, rows);
        otherAccountsRange(accounts, 1186, 1209, rows);
        otherAccountsRange(accounts, 1216, 1218, rows);
        otherAccountsRange(accounts, 1226, 1228, rows);
        otherAccountsRange(accounts, 1236, 1238, rows);
        otherAccountsRange(accounts, 1246, 1248, rows);
        otherAccountsRange(accounts, 1256, 1258, rows);
        otherAccountsRange(accounts, 1266, 1268, rows);
        otherAccountsRange(accounts, 1270, 1289, rows);
        otherAccountsRange(accounts, 1296, 1309, rows);
        otherAccountsRange(accounts, 1317, 1319, rows);
        otherAccountsRange(accounts, 1326, 1329, rows);
        otherAccountsRange(accounts, 1332, 1332, rows);
        otherAccountsRange(accounts, 1334, 1335, rows);
        otherAccountsRange(accounts, 1337, 1339, rows);
        otherAccountsRange(accounts, 1342, 1342, rows);
        otherAccountsRange(accounts, 1344, 1345, rows);
        otherAccountsRange(accounts, 1347, 1349, rows);
        otherAccountsRange(accounts, 1358, 1359, rows);
        otherAccountsRange(accounts, 1366, 1379, rows);
        otherAccountsRange(accounts, 1383, 1399, rows);
        otherAccountsRange(accounts, 2082, 2086, rows);
        otherAccountsRange(accounts, 2088, 2090, rows);
        otherAccountsRange(accounts, 2092, 2092, rows);
        otherAccountsRange(accounts, 2094, 2096, rows);
        otherAccountsRange(accounts, 2100, 2299, rows);
        return rows.build();
      })
      .build(),
  },
});

const beforeChangeWorkingCapitalId =
  'cashFlowStatement.section.main.currentOperations.beforeChangeWorkingCapital';
const changeWorkingCapitalId =
  'cashFlowStatement.section.main.currentOperations.changeWorkingCapital';
const investmentActivitiesId =
  'cashFlowStatement.section.main.investmentActivities.content';
const financialActivitiesId =
  'cashFlowStatement.section.main.financialActivities.content';
const changeLiquidAssetsId =
  'cashFlowStatement.section.main.changeLiquidAssets';

export const cashFlowStatementReferences = (
  isDigitalSubmission: boolean,
  previousYearKey: string | undefined
): Record<string, string> => ({
  operatingProfit: or(multiply(-1, account('3000:7999')), 0),
  operatingProfitPreviousInterval: or(
    multiply(-1, account('3000:7999', undefined, previousYearKey)),
    0
  ),
  sumActivatedWork: or(
    sum(id(`${beforeChangeWorkingCapitalId}.activatedWork.*.value`)),
    0
  ),
  sumActivatedWorkPreviousInterval: or(
    sum(id(`${beforeChangeWorkingCapitalId}.activatedWork.*.previousYear`)),
    0
  ),
  sumDividendsAndProfitShares: or(
    sum(id(`${beforeChangeWorkingCapitalId}.dividendsAndProfitShares.*.value`)),
    0
  ),
  sumDividendsAndProfitSharesPreviousInterval: or(
    sum(
      id(
        `${beforeChangeWorkingCapitalId}.dividendsAndProfitShares.*.previousYear`
      )
    ),
    0
  ),
  sumCapitalLoss: or(
    sum(id(`${beforeChangeWorkingCapitalId}.capitalLoss.*.value`)),
    0
  ),
  sumCapitalLossPreviousInterval: or(
    sum(id(`${beforeChangeWorkingCapitalId}.capitalLoss.*.previousYear`)),
    0
  ),
  sumCapitalGain: or(
    sum(id(`${beforeChangeWorkingCapitalId}.capitalGain.*.value`)),
    0
  ),
  sumCapitalGainPreviousInterval: or(
    sum(id(`${beforeChangeWorkingCapitalId}.capitalGain.*.previousYear`)),
    0
  ),
  sumCapitalGainCapitalLoss: or(
    sum(id(`${beforeChangeWorkingCapitalId}.capitalGainCapitalLoss.*.value`)),
    0
  ),
  sumCapitalGainCapitalLossPreviousInterval: or(
    sum(
      id(
        `${beforeChangeWorkingCapitalId}.capitalGainCapitalLoss.*.previousYear`
      )
    ),
    0
  ),
  sumWriteDownChange: or(
    sum(id(`${beforeChangeWorkingCapitalId}.writeDownChange.*.value`)),
    0
  ),
  sumWriteDownChangePreviousInterval: or(
    sum(id(`${beforeChangeWorkingCapitalId}.writeDownChange.*.previousYear`)),
    0
  ),
  sumDepreciations: or(
    sum(id(`${beforeChangeWorkingCapitalId}.depreciations.*.value`)),
    0
  ),
  sumDepreciationsPreviousInterval: or(
    sum(id(`${beforeChangeWorkingCapitalId}.depreciations.*.previousYear`)),
    0
  ),
  sumDepreciationsAndWriteDownChange: or(
    sum(
      id(
        `${beforeChangeWorkingCapitalId}.depreciationsAndWriteDownChange.*.value`
      )
    ),
    0
  ),
  sumDepreciationsAndWriteDownChangePreviousInterval: or(
    sum(
      id(
        `${beforeChangeWorkingCapitalId}.depreciationsAndWriteDownChange.*.previousYear`
      )
    ),
    0
  ),
  sumValutakursDifferenser: or(
    sum(id(`${beforeChangeWorkingCapitalId}.valutakursDifferenser.*.value`)),
    0
  ),
  sumValutakursDifferenserPreviousInterval: or(
    sum(
      id(`${beforeChangeWorkingCapitalId}.valutakursDifferenser.*.previousYear`)
    ),
    0
  ),
  sumAvsattningPensioner: or(
    sum(id(`${beforeChangeWorkingCapitalId}.avsattningPensioner.*.value`)),
    0
  ),
  sumAvsattningPensionerPreviousInterval: or(
    sum(
      id(`${beforeChangeWorkingCapitalId}.avsattningPensioner.*.previousYear`)
    ),
    0
  ),
  sumAvsattningGarantier: or(
    sum(id(`${beforeChangeWorkingCapitalId}.avsattningGarantier.*.value`)),
    0
  ),
  sumAvsattningGarantierPreviousInterval: or(
    sum(
      id(`${beforeChangeWorkingCapitalId}.avsattningGarantier.*.previousYear`)
    ),
    0
  ),
  sumAvsattningOmstrukturering: or(
    sum(
      id(`${beforeChangeWorkingCapitalId}.avsattningOmstrukturering.*.value`)
    ),
    0
  ),
  sumAvsattningOmstruktureringPreviousInterval: or(
    sum(
      id(
        `${beforeChangeWorkingCapitalId}.avsattningOmstrukturering.*.previousYear`
      )
    ),
    0
  ),
  sumReceivedInterest: or(
    sum(id(`${beforeChangeWorkingCapitalId}.receivedInterest.*.value`)),
    0
  ),
  sumReceivedInterestPreviousInterval: or(
    sum(id(`${beforeChangeWorkingCapitalId}.receivedInterest.*.previousYear`)),
    0
  ),
  sumPaidInterest: or(
    sum(id(`${beforeChangeWorkingCapitalId}.paidInterest.*.value`)),
    0
  ),
  sumPaidInterestPreviousInterval: or(
    sum(id(`${beforeChangeWorkingCapitalId}.paidInterest.*.previousYear`)),
    0
  ),
  sumPaidIncomeTax: or(
    sum(id(`${beforeChangeWorkingCapitalId}.paidIncomeTax.*.value`)),
    0
  ),
  sumPaidIncomeTaxPreviousInterval: or(
    sum(id(`${beforeChangeWorkingCapitalId}.paidIncomeTax.*.previousYear`)),
    0
  ),
  yearTax: or(multiply(-1, account('8900:8989')), 0),
  yearTaxPreviousInterval: or(
    multiply(-1, account('8900:8989', undefined, previousYearKey)),
    0
  ),
  sumBeforeChangeWorkingCapital: sum(
    id('operatingProfit'),
    id('sumActivatedWork'),
    id('sumDividendsAndProfitShares'),
    !isDigitalSubmission ? id('sumCapitalLoss') : 0,
    !isDigitalSubmission ? id('sumCapitalGain') : 0,
    !isDigitalSubmission ? id('sumWriteDownChange') : 0,
    !isDigitalSubmission ? id('sumDepreciations') : 0,
    isDigitalSubmission ? id('sumCapitalGainCapitalLoss') : 0,
    isDigitalSubmission ? id('sumDepreciationsAndWriteDownChange') : 0,
    isDigitalSubmission ? id('sumValutakursDifferenser') : 0,
    isDigitalSubmission ? id('sumAvsattningPensioner') : 0,
    isDigitalSubmission ? id('sumAvsattningGarantier') : 0,
    isDigitalSubmission ? id('sumAvsattningOmstrukturering') : 0,
    id('sumReceivedInterest'),
    id('sumPaidInterest'),
    id('sumPaidIncomeTax')
  ),
  sumBeforeChangeWorkingCapitalPreviousInterval: sum(
    id('operatingProfitPreviousInterval'),
    id('sumActivatedWorkPreviousInterval'),
    id('sumDividendsAndProfitSharesPreviousInterval'),
    !isDigitalSubmission ? id('sumCapitalLossPreviousInterval') : 0,
    !isDigitalSubmission ? id('sumCapitalGainPreviousInterval') : 0,
    !isDigitalSubmission ? id('sumWriteDownChangePreviousInterval') : 0,
    !isDigitalSubmission ? id('sumDepreciationsPreviousInterval') : 0,
    isDigitalSubmission ? id('sumCapitalGainCapitalLossPreviousInterval') : 0,
    isDigitalSubmission
      ? id('sumDepreciationsAndWriteDownChangePreviousInterval')
      : 0,
    isDigitalSubmission ? id('sumValutakursDifferenserPreviousInterval') : 0,
    isDigitalSubmission ? id('sumAvsattningPensionerPreviousInterval') : 0,
    isDigitalSubmission ? id('sumAvsattningGarantierPreviousInterval') : 0,
    isDigitalSubmission
      ? id('sumAvsattningOmstruktureringPreviousInterval')
      : 0,
    id('sumReceivedInterestPreviousInterval'),
    id('sumPaidInterestPreviousInterval'),
    id('sumPaidIncomeTaxPreviousInterval')
  ),

  sumChangeStockAndWorkInProgress: or(
    sum(id(`${changeWorkingCapitalId}.changeStockAndWorkInProgress.*.value`)),
    0
  ),
  sumChangeStockAndWorkInProgressPreviousInterval: or(
    sum(
      id(
        `${changeWorkingCapitalId}.changeStockAndWorkInProgress.*.previousYear`
      )
    ),
    0
  ),
  sumChangeAccountReceivable: or(
    sum(id(`${changeWorkingCapitalId}.changeAccountReceivable.*.value`)),
    0
  ),
  sumChangeAccountReceivablePreviousInterval: or(
    sum(id(`${changeWorkingCapitalId}.changeAccountReceivable.*.previousYear`)),
    0
  ),
  sumChangeReceivables: or(
    sum(id(`${changeWorkingCapitalId}.changeReceivables.*.value`)),
    0
  ),
  sumChangeReceivablesPreviousInterval: or(
    sum(id(`${changeWorkingCapitalId}.changeReceivables.*.previousYear`)),
    0
  ),
  sumChangeTradeCreditors: or(
    sum(id(`${changeWorkingCapitalId}.changeTradeCreditors.*.value`)),
    0
  ),
  sumChangeTradeCreditorsPreviousInterval: or(
    sum(id(`${changeWorkingCapitalId}.changeTradeCreditors.*.previousYear`)),
    0
  ),
  sumChangeCurrentLiabilities: or(
    sum(id(`${changeWorkingCapitalId}.changeCurrentLiabilities.*.value`)),
    0
  ),
  sumChangeCurrentLiabilitiesPreviousInterval: or(
    sum(
      id(`${changeWorkingCapitalId}.changeCurrentLiabilities.*.previousYear`)
    ),
    0
  ),
  sumChangeWorkingCapital: sum(
    id('sumChangeStockAndWorkInProgress'),
    id('sumChangeAccountReceivable'),
    id('sumChangeReceivables'),
    id('sumChangeTradeCreditors'),
    id('sumChangeCurrentLiabilities')
  ),
  sumChangeWorkingCapitalPreviousInterval: sum(
    id('sumChangeStockAndWorkInProgressPreviousInterval'),
    id('sumChangeAccountReceivablePreviousInterval'),
    id('sumChangeReceivablesPreviousInterval'),
    id('sumChangeTradeCreditorsPreviousInterval'),
    id('sumChangeCurrentLiabilitiesPreviousInterval')
  ),
  sumCurrentOperations: sum(
    id('sumBeforeChangeWorkingCapital'),
    id('sumChangeWorkingCapital')
  ),
  sumCurrentOperationsPreviousInterval: sum(
    id('sumBeforeChangeWorkingCapitalPreviousInterval'),
    id('sumChangeWorkingCapitalPreviousInterval')
  ),

  // Investment activities
  sumInvestmentActivities: sum(id(`${investmentActivitiesId}.*.value`)),
  sumInvestmentActivitiesPreviousInterval: sum(
    id(`${investmentActivitiesId}.*.previousYear`)
  ),
  groupContributions: or(multiply(-1, account('8820:8839')), 0),
  groupContributionsPreviousInterval: or(
    multiply(-1, account('8820:8839', undefined, previousYearKey)),
    0
  ),
  // Trick to be able to format positive and negative group contributions differently
  // The one of 'positiveGroupContributions' and 'negativeGroupContributions' which is 0 indicate
  // which formatting label is used.
  positiveGroupContributions: min(0, id('groupContributions')),
  negativeGroupContributions: max(0, id('groupContributions')),

  payedDividend: sum(
    or(account('2091+2098+2099', 'ib'), 0),
    or(multiply(-1, account('2091+2098')), 0)
  ),
  payedDividendPreviousInterval: sum(
    or(account('2091+2098+2099', 'ib', previousYearKey), 0),
    or(multiply(-1, account('2091+2098', undefined, previousYearKey)), 0)
  ),
  sentGroupContributions: min(0, id('groupContributions')),
  sumFinancialActivities: sum(id(`${financialActivitiesId}.*.value`)),
  sumFinancialActivitiesPreviousInterval: sum(
    id(`${financialActivitiesId}.*.previousYear`)
  ),

  sumOperationAndActivities: sum(
    id('cashFlowStatement.section.main.sumCurrentOperations.value'),
    id('cashFlowStatement.section.main.sumInvestmentActivities.value'),
    id('cashFlowStatement.section.main.sumFinancialActivities.value')
  ),
  sumOperationAndActivitiesPreviousInterval: sum(
    id('cashFlowStatement.section.main.sumCurrentOperations.previousYear'),
    id('cashFlowStatement.section.main.sumInvestmentActivities.previousYear'),
    id('cashFlowStatement.section.main.sumFinancialActivities.previousYear')
  ),
  liquidAssetsStart: or(account('1870:1999', 'ib'), 0),
  liquidAssetsStartPreviousInterval: or(
    account('1870:1999', 'ib', previousYearKey),
    0
  ),
  liquidAssetsEnd: or(account('1870:1999'), 0),
  liquidAssetsEndPreviousInterval: or(
    account('1870:1999', undefined, previousYearKey),
    0
  ),
  liquidAssetsChange: or(
    sum(id(`${changeLiquidAssetsId}.booked.accounts.*.value`)),
    0
  ),
  liquidAssetsChangePreviousInterval: or(
    sum(id(`${changeLiquidAssetsId}.booked.accounts.*.previousYear`)),
    0
  ),
  cashFlowDifference: sum(
    id('liquidAssetsChange'),
    multiply(-1, id('sumOperationAndActivities'))
  ),
  cashFlowDifferencePreviousInterval: sum(
    id('liquidAssetsChangePreviousInterval'),
    multiply(-1, id('sumOperationAndActivitiesPreviousInterval'))
  ),
});

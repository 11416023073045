import React from 'react';

type Props = {
  isTrue: boolean;
  fallback?: React.ReactNode;
  children: React.ReactNode;
};

export default function When(props: Props) {
  const { isTrue, fallback = null, children } = props;

  return <>{isTrue ? children : fallback}</>;
}

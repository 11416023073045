import React from 'react';
import styled from '@emotion/styled';
import { IconButton as MuiButtonIcon } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const classes = {
  buttonStyle: {
    color: 'white',
  },
};

const IconButton = styled(MuiButtonIcon)`
  padding: 6px;
`;

const CloseIconButton = ({ stopPropagation = true, onClose }) => {
  return (
    <IconButton
      data-cy="modal-close-btn"
      css={classes.buttonStyle}
      onClick={(event) => {
        stopPropagation && event.stopPropagation();
        onClose();
      }}
    >
      <CloseIcon fontSize="large" />
    </IconButton>
  );
};

export default CloseIconButton;

/*
 * This is a GENERATED file, do not change manually!
 * Generated:
 * - from file k2-arsredovisning-2021-10-31-rev20230131_sv - Noter.csv
 * - Comments: nan
 */

import {
  value,
  ref,
  sumAllowNull,
  id,
  AgoyDocumentPart,
} from '@agoy/document';
import { IxbrlCell } from '../../../../../common';
import {
  field,
  ixbrlCell,
  ixbrlField,
  table,
} from '../../../../../common/utils/util';
import { previousYear, year } from '../../../../../common/utils/date-util';
import {
  sumAccountsIBs,
} from '../../../../../common/utils/accountSumsUtil';

const cell = { type: 'field' as const };
const boolean = { type: 'boolean' as const };
const type = { type: 'type' as const };
const number = { type: 'number' as const };

export const getNote2041K2v2 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null
): AgoyDocumentPart<typeof note2041K2v2ContentDefinition> => ({
  active: false,
  number: value(undefined),
  name: noteLabel('Inventarier, verktyg och installationer'),
  data: {
    active: true,
    InventarierVerktygInstallationerAbstract: {
      type: 'group',
      active: true,
      level: 0,
      title: field('Inventarier, verktyg och installationer'),
      table: table<IxbrlCell>(
        'notes.note41.data.InventarierVerktygInstallationerAbstract.table',
        {
          id: 'InventarierVerktygInstallationerAbstract',
          label: '',
          dataType: 'text',
          active: true,
        },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) ?? '',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) ?? '',
          dataType: 'numeric',
          active: true,
        }
      )
        .addRows((rows) => {
          rows
            .addRowWithType(
              'InventarierVerktygInstallationerAbstract',
              'hidden',
              undefined,
              undefined,
              undefined
            )
            .addSubRows((rows) => {
              rows.addRowWithType(
                'InventarierVerktygInstallationerAnskaffningsvardenIngaende',
                'sum_accounts',
                noteLabel('Ingående anskaffningsvärden'),
                ixbrlCell(
                  ref(
                    sumAccountsIBs(
                      '1220:1227+1230:1232+1240:1247+1250:1257+1260',
                      false
                    )
                  ),
                  {
                    contextRef: 'balans1',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:InventarierVerktygInstallationerAnskaffningsvarden',
                    standardRubrik:
                      'Anskaffningsvärden inventarier, verktyg och installationer',
                    saldo: 'debit',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sumAccountsIBs(
                      '1220:1227+1230:1232+1240:1247+1250:1257+1260',
                      false,
                      'previousYear'
                    )
                  ),
                  {
                    contextRef: 'balans2',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:InventarierVerktygInstallationerAnskaffningsvarden',
                    standardRubrik:
                      'Anskaffningsvärden inventarier, verktyg och installationer',
                    saldo: 'debit',
                    negateValue: false,
                  }
                )
              );
              rows
                .addRowWithType(
                  'InventarierVerktygInstallationerForandringAnskaffningsvardenAbstract',
                  'header',
                  noteLabel('Förändringar av anskaffningsvärden'),
                  undefined,
                  undefined
                )
                .addSubRows((rows) => {
                  rows.addRowWithType(
                    'InventarierVerktygInstallationerForandringAnskaffningsvardenInkop',
                    'row',
                    noteLabel('Inköp'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:InventarierVerktygInstallationerForandringAnskaffningsvardenInkop',
                      standardRubrik:
                        'Ökning av anskaffningsvärden i samband med inköp av inventarier, verktyg och installationer',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:InventarierVerktygInstallationerForandringAnskaffningsvardenInkop',
                      standardRubrik:
                        'Ökning av anskaffningsvärden i samband med inköp av inventarier, verktyg och installationer',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'InventarierVerktygInstallationerForandringAnskaffningsvardenRorelseforvarv',
                    'row',
                    noteLabel('Rörelseförvärv'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:InventarierVerktygInstallationerForandringAnskaffningsvardenRorelseforvarv',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden för inventarier, verktyg och installationer genom rörelseförvärv',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:InventarierVerktygInstallationerForandringAnskaffningsvardenRorelseforvarv',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden för inventarier, verktyg och installationer genom rörelseförvärv',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'InventarierVerktygInstallationerForandringAnskaffningsvardenFusion',
                    'row',
                    noteLabel('Fusion'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:InventarierVerktygInstallationerForandringAnskaffningsvardenFusion',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden för inventarier, verktyg och installationer genom fusion',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:InventarierVerktygInstallationerForandringAnskaffningsvardenFusion',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden för inventarier, verktyg och installationer genom fusion',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'InventarierVerktygInstallationerForandringAnskaffningsvardenForsaljningarUtrangeringar',
                    'row',
                    noteLabel('Försäljningar/utrangeringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:InventarierVerktygInstallationerForandringAnskaffningsvardenForsaljningarUtrangeringar',
                      standardRubrik:
                        'Minskning av anskaffningsvärden i samband med försäljningar eller utrangeringar av inventarier, verktyg och installationer',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:InventarierVerktygInstallationerForandringAnskaffningsvardenForsaljningarUtrangeringar',
                      standardRubrik:
                        'Minskning av anskaffningsvärden i samband med försäljningar eller utrangeringar av inventarier, verktyg och installationer',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'InventarierVerktygInstallationerForandringAnskaffningsvardenOmklassificeringar',
                    'row',
                    noteLabel('Omklassificeringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:InventarierVerktygInstallationerForandringAnskaffningsvardenOmklassificeringar',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden i samband med omklassificeringar av inventarier, verktyg och installationer',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:InventarierVerktygInstallationerForandringAnskaffningsvardenOmklassificeringar',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden i samband med omklassificeringar av inventarier, verktyg och installationer',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'InventarierVerktygInstallationerForandringAnskaffningsvardenOmrakningsdifferenser',
                    'row',
                    noteLabel('Omräkningsdifferenser'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:InventarierVerktygInstallationerForandringAnskaffningsvardenOmrakningsdifferenser',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden i samband med omräkningsdifferenser av inventarier, verktyg och installationer',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:InventarierVerktygInstallationerForandringAnskaffningsvardenOmrakningsdifferenser',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden i samband med omräkningsdifferenser av inventarier, verktyg och installationer',
                      negateValue: false,
                    })
                  );
                  return rows.build();
                });
              rows.addRowWithType(
                'InventarierVerktygInstallationerAnskaffningsvardenUtgaende',
                'sum',
                noteLabel('Utgående anskaffningsvärden'),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note41.data.InventarierVerktygInstallationerAbstract.table.InventarierVerktygInstallationerAbstract.InventarierVerktygInstallationerAnskaffningsvardenIngaende.year'
                      ),
                      id(
                        'notes.note41.data.InventarierVerktygInstallationerAbstract.table.InventarierVerktygInstallationerAbstract.InventarierVerktygInstallationerForandringAnskaffningsvardenAbstract.*.year'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans0',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:InventarierVerktygInstallationerAnskaffningsvarden',
                    standardRubrik:
                      'Anskaffningsvärden inventarier, verktyg och installationer',
                    saldo: 'debit',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note41.data.InventarierVerktygInstallationerAbstract.table.InventarierVerktygInstallationerAbstract.InventarierVerktygInstallationerAnskaffningsvardenIngaende.previousYear'
                      ),
                      id(
                        'notes.note41.data.InventarierVerktygInstallationerAbstract.table.InventarierVerktygInstallationerAbstract.InventarierVerktygInstallationerForandringAnskaffningsvardenAbstract.*.previousYear'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans1',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:InventarierVerktygInstallationerAnskaffningsvarden',
                    standardRubrik:
                      'Anskaffningsvärden inventarier, verktyg och installationer',
                    saldo: 'debit',
                    negateValue: false,
                  }
                )
              );
              rows.addRowWithType(
                'InventarierVerktygInstallationerAvskrivningarIngaende',
                'sum_accounts',
                noteLabel('Ingående avskrivningar'),
                ixbrlCell(
                  ref(sumAccountsIBs('1229+1239+1249+1259+1269', false)),
                  {
                    contextRef: 'balans1',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:InventarierVerktygInstallationerAvskrivningar',
                    standardRubrik:
                      'Ackumulerade avskrivningar inventarier, verktyg och installationer',
                    saldo: 'credit',
                    negateValue: true,
                  }
                ),
                ixbrlCell(
                  ref(
                    sumAccountsIBs(
                      '1229+1239+1249+1259+1269',
                      false,
                      'previousYear'
                    )
                  ),
                  {
                    contextRef: 'balans2',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:InventarierVerktygInstallationerAvskrivningar',
                    standardRubrik:
                      'Ackumulerade avskrivningar inventarier, verktyg och installationer',
                    saldo: 'credit',
                    negateValue: true,
                  }
                )
              );
              rows
                .addRowWithType(
                  'InventarierVerktygInstallationerForandringAvskrivningarAbstract',
                  'header',
                  noteLabel('Förändringar av avskrivningar'),
                  undefined,
                  undefined
                )
                .addSubRows((rows) => {
                  rows.addRowWithType(
                    'InventarierVerktygInstallationerForandringAvskrivningarFusion',
                    'row',
                    noteLabel('Fusion'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:InventarierVerktygInstallationerForandringAvskrivningarFusion',
                      standardRubrik:
                        'Ökning (minskning) av avskrivningar för inventarier, verktyg och installationer genom fusion',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:InventarierVerktygInstallationerForandringAvskrivningarFusion',
                      standardRubrik:
                        'Ökning (minskning) av avskrivningar för inventarier, verktyg och installationer genom fusion',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'InventarierVerktygInstallationerForandringAvskrivningarAterfordaAvskrivningarForsaljningarRespUtrangeringar',
                    'row',
                    noteLabel('Försäljningar/utrangeringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:InventarierVerktygInstallationerForandringAvskrivningarAterfordaAvskrivningarForsaljningarRespUtrangeringar',
                      standardRubrik:
                        'Återförda avskrivningar i samband med försäljningar eller utrangeringar av inventarier, verktyg och installationer',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:InventarierVerktygInstallationerForandringAvskrivningarAterfordaAvskrivningarForsaljningarRespUtrangeringar',
                      standardRubrik:
                        'Återförda avskrivningar i samband med försäljningar eller utrangeringar av inventarier, verktyg och installationer',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'InventarierVerktygInstallationerForandringAvskrivningarOmklassificeringar',
                    'row',
                    noteLabel('Omklassificeringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:InventarierVerktygInstallationerForandringAvskrivningarOmklassificeringar',
                      standardRubrik:
                        'Minskning (ökning) av avskrivningar i samband med omklassificeringar av inventarier, verktyg och installationer',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:InventarierVerktygInstallationerForandringAvskrivningarOmklassificeringar',
                      standardRubrik:
                        'Minskning (ökning) av avskrivningar i samband med omklassificeringar av inventarier, verktyg och installationer',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'InventarierVerktygInstallationerForandringAvskrivningarAretsAvskrivningar',
                    'row',
                    noteLabel('Årets avskrivningar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:InventarierVerktygInstallationerForandringAvskrivningarAretsAvskrivningar',
                      standardRubrik:
                        'Periodens avskrivningar inventarier, verktyg och installationer',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:InventarierVerktygInstallationerForandringAvskrivningarAretsAvskrivningar',
                      standardRubrik:
                        'Periodens avskrivningar inventarier, verktyg och installationer',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'InventarierVerktygInstallationerForandringAvskrivningarOmrakningsdifferenser',
                    'row',
                    noteLabel('Omräkningsdifferenser'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:InventarierVerktygInstallationerForandringAvskrivningarOmrakningsdifferenser',
                      standardRubrik:
                        'Minskning (ökning) av avskrivningar i samband med omräkningsdifferenser av inventarier, verktyg och installationer',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:InventarierVerktygInstallationerForandringAvskrivningarOmrakningsdifferenser',
                      standardRubrik:
                        'Minskning (ökning) av avskrivningar i samband med omräkningsdifferenser av inventarier, verktyg och installationer',
                      negateValue: false,
                    })
                  );
                  return rows.build();
                });
              rows.addRowWithType(
                'InventarierVerktygInstallationerAvskrivningarUtgaende',
                'sum',
                noteLabel('Utgående avskrivningar'),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note41.data.InventarierVerktygInstallationerAbstract.table.InventarierVerktygInstallationerAbstract.InventarierVerktygInstallationerAvskrivningarIngaende.year'
                      ),
                      id(
                        'notes.note41.data.InventarierVerktygInstallationerAbstract.table.InventarierVerktygInstallationerAbstract.InventarierVerktygInstallationerForandringAvskrivningarAbstract.*.year'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans0',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:InventarierVerktygInstallationerAvskrivningar',
                    standardRubrik:
                      'Ackumulerade avskrivningar inventarier, verktyg och installationer',
                    saldo: 'credit',
                    negateValue: true,
                  }
                ),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note41.data.InventarierVerktygInstallationerAbstract.table.InventarierVerktygInstallationerAbstract.InventarierVerktygInstallationerAvskrivningarIngaende.previousYear'
                      ),
                      id(
                        'notes.note41.data.InventarierVerktygInstallationerAbstract.table.InventarierVerktygInstallationerAbstract.InventarierVerktygInstallationerForandringAvskrivningarAbstract.*.previousYear'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans1',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:InventarierVerktygInstallationerAvskrivningar',
                    standardRubrik:
                      'Ackumulerade avskrivningar inventarier, verktyg och installationer',
                    saldo: 'credit',
                    negateValue: true,
                  }
                )
              );
              rows.addRowWithType(
                'InventarierVerktygInstallationerNedskrivningarIngaende',
                'sum_accounts',
                noteLabel('Ingående nedskrivningar'),
                ixbrlCell(ref(sumAccountsIBs('1228+1238+1248+1258', false)), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:InventarierVerktygInstallationerNedskrivningar',
                  standardRubrik:
                    'Ackumulerade nedskrivningar inventarier, verktyg och installationer',
                  saldo: 'credit',
                  negateValue: true,
                }),
                ixbrlCell(
                  ref(
                    sumAccountsIBs('1228+1238+1248+1258', false, 'previousYear')
                  ),
                  {
                    contextRef: 'balans2',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:InventarierVerktygInstallationerNedskrivningar',
                    standardRubrik:
                      'Ackumulerade nedskrivningar inventarier, verktyg och installationer',
                    saldo: 'credit',
                    negateValue: true,
                  }
                )
              );
              rows
                .addRowWithType(
                  'InventarierVerktygInstallationerForandringNedskrivningarAbstract',
                  'header',
                  noteLabel('Förändringar av nedskrivningar'),
                  undefined,
                  undefined
                )
                .addSubRows((rows) => {
                  rows.addRowWithType(
                    'InventarierVerktygInstallationerForandringNedskrivningarFusion',
                    'row',
                    noteLabel('Fusion'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:InventarierVerktygInstallationerForandringNedskrivningarFusion',
                      standardRubrik:
                        'Ökning (minskning) av nedskrivningar för inventarier, verktyg och installationer genom fusion',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:InventarierVerktygInstallationerForandringNedskrivningarFusion',
                      standardRubrik:
                        'Ökning (minskning) av nedskrivningar för inventarier, verktyg och installationer genom fusion',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'InventarierVerktygInstallationerForandringNedskrivningarAterfordaNedskrivningarForsaljningarRespUtrangeringar',
                    'row',
                    noteLabel('Försäljningar/utrangeringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:InventarierVerktygInstallationerForandringNedskrivningarAterfordaNedskrivningarForsaljningarRespUtrangeringar',
                      standardRubrik:
                        'Återförda nedskrivningar i samband med försäljningar eller utrangeringar av inventarier, verktyg och installationer',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:InventarierVerktygInstallationerForandringNedskrivningarAterfordaNedskrivningarForsaljningarRespUtrangeringar',
                      standardRubrik:
                        'Återförda nedskrivningar i samband med försäljningar eller utrangeringar av inventarier, verktyg och installationer',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'InventarierVerktygInstallationerForandringNedskrivningarAterfordaNedskrivningar',
                    'row',
                    noteLabel('Återförda nedskrivningar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:InventarierVerktygInstallationerForandringNedskrivningarAterfordaNedskrivningar',
                      standardRubrik:
                        'Periodens återförda nedskrivningar av inventarier, verktyg och installationer',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:InventarierVerktygInstallationerForandringNedskrivningarAterfordaNedskrivningar',
                      standardRubrik:
                        'Periodens återförda nedskrivningar av inventarier, verktyg och installationer',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'InventarierVerktygInstallationerForandringNedskrivningarOmklassificeringar',
                    'row',
                    noteLabel('Omklassificeringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:InventarierVerktygInstallationerForandringNedskrivningarOmklassificeringar',
                      standardRubrik:
                        'Minskning (ökning) av nedskrivningar i samband med omklassificeringar av inventarier, verktyg och installationer',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:InventarierVerktygInstallationerForandringNedskrivningarOmklassificeringar',
                      standardRubrik:
                        'Minskning (ökning) av nedskrivningar i samband med omklassificeringar av inventarier, verktyg och installationer',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'InventarierVerktygInstallationerForandringNedskrivningarAretsNedskrivningar',
                    'row',
                    noteLabel('Årets nedskrivningar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:InventarierVerktygInstallationerForandringNedskrivningarAretsNedskrivningar',
                      standardRubrik:
                        'Periodens nedskrivningar av inventarier, verktyg och installationer',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:InventarierVerktygInstallationerForandringNedskrivningarAretsNedskrivningar',
                      standardRubrik:
                        'Periodens nedskrivningar av inventarier, verktyg och installationer',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'InventarierVerktygInstallationerForandringNedskrivningarOmrakningsdifferenser',
                    'row',
                    noteLabel('Omräkningsdifferenser'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:InventarierVerktygInstallationerForandringNedskrivningarOmrakningsdifferenser',
                      standardRubrik:
                        'Minskning (ökning) av nedskrivningar i samband med omräkningsdifferenser av inventarier, verktyg och installationer',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:InventarierVerktygInstallationerForandringNedskrivningarOmrakningsdifferenser',
                      standardRubrik:
                        'Minskning (ökning) av nedskrivningar i samband med omräkningsdifferenser av inventarier, verktyg och installationer',
                      negateValue: false,
                    })
                  );
                  return rows.build();
                });
              rows.addRowWithType(
                'InventarierVerktygInstallationerNedskrivningarUtgaende',
                'sum',
                noteLabel('Utgående nedskrivningar'),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note41.data.InventarierVerktygInstallationerAbstract.table.InventarierVerktygInstallationerAbstract.InventarierVerktygInstallationerNedskrivningarIngaende.year'
                      ),
                      id(
                        'notes.note41.data.InventarierVerktygInstallationerAbstract.table.InventarierVerktygInstallationerAbstract.InventarierVerktygInstallationerForandringNedskrivningarAbstract.*.year'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans0',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:InventarierVerktygInstallationerNedskrivningar',
                    standardRubrik:
                      'Ackumulerade nedskrivningar inventarier, verktyg och installationer',
                    saldo: 'credit',
                    negateValue: true,
                  }
                ),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note41.data.InventarierVerktygInstallationerAbstract.table.InventarierVerktygInstallationerAbstract.InventarierVerktygInstallationerNedskrivningarIngaende.previousYear'
                      ),
                      id(
                        'notes.note41.data.InventarierVerktygInstallationerAbstract.table.InventarierVerktygInstallationerAbstract.InventarierVerktygInstallationerForandringNedskrivningarAbstract.*.previousYear'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans1',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:InventarierVerktygInstallationerNedskrivningar',
                    standardRubrik:
                      'Ackumulerade nedskrivningar inventarier, verktyg och installationer',
                    saldo: 'credit',
                    negateValue: true,
                  }
                )
              );
              return rows.build();
            });
          rows.addRowWithType(
            'InventarierVerktygInstallationer',
            'sum',
            noteLabel('Redovisat värde'),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note41.data.InventarierVerktygInstallationerAbstract.table.InventarierVerktygInstallationerAbstract.InventarierVerktygInstallationerAnskaffningsvardenUtgaende.year'
                  ),
                  id(
                    'notes.note41.data.InventarierVerktygInstallationerAbstract.table.InventarierVerktygInstallationerAbstract.InventarierVerktygInstallationerAvskrivningarUtgaende.year'
                  ),
                  id(
                    'notes.note41.data.InventarierVerktygInstallationerAbstract.table.InventarierVerktygInstallationerAbstract.InventarierVerktygInstallationerNedskrivningarUtgaende.year'
                  )
                )
              ),
              {
                contextRef: 'balans0',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:InventarierVerktygInstallationer',
                standardRubrik: 'Inventarier, verktyg och installationer',
                saldo: 'debit',
                negateValue: false,
              }
            ),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note41.data.InventarierVerktygInstallationerAbstract.table.InventarierVerktygInstallationerAbstract.InventarierVerktygInstallationerAnskaffningsvardenUtgaende.previousYear'
                  ),
                  id(
                    'notes.note41.data.InventarierVerktygInstallationerAbstract.table.InventarierVerktygInstallationerAbstract.InventarierVerktygInstallationerAvskrivningarUtgaende.previousYear'
                  ),
                  id(
                    'notes.note41.data.InventarierVerktygInstallationerAbstract.table.InventarierVerktygInstallationerAbstract.InventarierVerktygInstallationerNedskrivningarUtgaende.previousYear'
                  )
                )
              ),
              {
                contextRef: 'balans1',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:InventarierVerktygInstallationer',
                standardRubrik: 'Inventarier, verktyg och installationer',
                saldo: 'debit',
                negateValue: false,
              }
            )
          );
          return rows.build();
        })
        .build(),
      NotInventarierVerktygInstallationerKommentar: {
        type: 'group',
        active: true,
        level: 0,
        title: field(
          'Kommentar till specifikation av inventarier, verktyg och installationer'
        ),
        value: ixbrlField(field(''), {
          contextRef: 'period0',
          type: 'stringItemType',
          name: 'se-gen-base:NotInventarierVerktygInstallationerKommentar',
          standardRubrik:
            'Kommentar till specifikation av inventarier, verktyg och installationer',
        }),
      },
    },
  },
  group: value(undefined),
  section: value(undefined),
  validForReportType: {
    k2: true,
    k3: true,
  },
});
export const note2041K2v2ContentDefinition = {
  type: 'part' as const,
  children: {
    active: boolean,
    number: cell,
    name: cell,
    data: {
      type: 'part' as const,
      children: {
        active: boolean,

        InventarierVerktygInstallationerAbstract: {
          type: 'part' as const,
          children: {
            type: type,
            level: number,
            active: boolean,

            title: cell,

            table: { type: 'table' as const },

            NotInventarierVerktygInstallationerKommentar: {
              type: 'part' as const,
              children: {
                type: type,
                level: number,
                active: boolean,

                title: cell,

                value: cell,
              },
            },
          },
        },
      },
    },
    group: cell,
    section: cell,
    validForReportType: {
      type: 'part' as const,
      children: {
        k2: boolean,
        k3: boolean,
      },
    },
  },
};

import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { Result } from 'ts-results';

import { addGlobalErrorMessage } from 'redux/actions';
import { asResultClass, useApiSdk } from 'api-sdk';
import ManageBilling from '_payment/components/pages/ManageBilling';
import PaygSummary from '_payment/components/pages/PaygSummary';
import SubscriptionSeats from '_payment/components/pages/SubscriptionSeats';
import {
  SubscriptionPackages,
  SubscriptionProrationReturnType,
  PaygUsageReturnType,
} from '_payment/types';
import LoadingPlaceholder from '_shared/components/LoadingPlaceholder';
import { UnreachableCaseError } from 'utils/types';
import config from '_shared/services/config';
import { Paper } from '@material-ui/core';

const Wrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing(2)}px;
`;

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;

  height: 70vh;
`;

const PaymentDetailsContainer = () => {
  const dispatch = useDispatch();
  const sdk = useApiSdk();
  const { formatMessage } = useIntl();

  const [subscriptionPackages, setSubscriptionPackages] =
    useState<SubscriptionPackages | null>(null);
  const [updatedSubscriptionPackages, setUpdatedSubscriptionPackages] =
    useState<SubscriptionPackages | null>(null);
  const [proration, setProration] =
    useState<SubscriptionProrationReturnType | null>(null);
  const [isLoadingProration, setIsLoadingProration] = useState<boolean>(false);
  const [paygUsage, setPaygUsage] = useState<PaygUsageReturnType | null>(null);

  const fetchSubscriptionPackagesAndPayg = async () => {
    const orgPackagesReq = asResultClass(sdk.listOrganisationPackages());
    const paygUsageReq = asResultClass(sdk.getPaygUsage());

    const [orgPackagesRes, paygUsageRes] = await Promise.all([
      orgPackagesReq,
      paygUsageReq,
    ]);

    const results = Result.all(orgPackagesRes, paygUsageRes);

    if (results.ok) {
      const [orgPackages, paygUsage] = results.unwrap();

      setSubscriptionPackages(orgPackages);
      setUpdatedSubscriptionPackages(orgPackages);

      setPaygUsage(paygUsage);
    } else {
      dispatch(addGlobalErrorMessage('error'));
    }
  };

  React.useEffect(() => {
    fetchSubscriptionPackagesAndPayg();
  }, []);

  if (
    subscriptionPackages === null ||
    updatedSubscriptionPackages === null ||
    paygUsage === null
  ) {
    return (
      <LoadingWrapper>
        <LoadingPlaceholder />
      </LoadingWrapper>
    );
  }

  if (!subscriptionPackages.big || !subscriptionPackages.small) {
    return (
      <>
        {formatMessage({
          id: 'dashboard.members.invite.error.title',
        })}
      </>
    );
  }

  const handleManageBilling = async () => {
    const result = await asResultClass(
      sdk.createCustomerPortal({
        requestBody: {
          returnUrl: `${window.location.origin}/organisation/payment-details`,
        },
      })
    );

    if (result.ok) {
      window.location.href = result.val.url;
    } else {
      dispatch(addGlobalErrorMessage('error'));
    }
  };

  const handleUpdateSubscriptionsCount = async (
    type: keyof SubscriptionPackages,
    operation: 'add' | 'remove'
  ) => {
    if (!updatedSubscriptionPackages || !subscriptionPackages) return;

    const newPackageQuantities = {
      big: updatedSubscriptionPackages.big.quantity,
      small: updatedSubscriptionPackages.small.quantity,
    };

    switch (type) {
      case 'big': {
        newPackageQuantities.big =
          operation === 'add'
            ? updatedSubscriptionPackages.big.quantity + 1
            : updatedSubscriptionPackages.big.quantity - 1;
        break;
      }
      case 'small': {
        newPackageQuantities.small =
          operation === 'add'
            ? updatedSubscriptionPackages.small.quantity + 1
            : updatedSubscriptionPackages.small.quantity - 1;
        break;
      }
      default:
        throw new UnreachableCaseError(type);
    }

    // request proration when new seats are different from existing seat cound
    if (
      newPackageQuantities.big !== subscriptionPackages.big.quantity ||
      newPackageQuantities.small !== subscriptionPackages.small.quantity
    ) {
      setIsLoadingProration(true);
      setProration(
        await sdk.previewSubscriptionChanges({
          bigPackageId: subscriptionPackages.big.id,
          bigPackageQuantity: newPackageQuantities.big,
          smallPackageId: subscriptionPackages.small.id,
          smallPackageQuantity: newPackageQuantities.small,
        })
      );

      setIsLoadingProration(false);
    } else {
      // reset proration
      setProration(null);
    }

    setUpdatedSubscriptionPackages({
      big: {
        ...updatedSubscriptionPackages.big,
        quantity: newPackageQuantities.big,
      },
      small: {
        ...updatedSubscriptionPackages.small,
        quantity: newPackageQuantities.small,
      },
    });
  };

  const handleConfirmProration = async () => {
    const result = await asResultClass(
      sdk.udpateOrganisationPackages({
        requestBody: {
          bigPackageId: subscriptionPackages.big.id,
          bigPackageQuantity: updatedSubscriptionPackages.big.quantity,
          smallPackageId: subscriptionPackages.small.id,
          smallPackageQuantity: updatedSubscriptionPackages.small.quantity,
        },
      })
    );

    if (result.ok) {
      // cleans up proration values after update
      setProration(null);
      await fetchSubscriptionPackagesAndPayg();
    } else {
      dispatch(addGlobalErrorMessage('error'));
    }
  };

  return (
    <Wrapper>
      {config.whiteLabelUI !== 'fortnox' && (
        <Paper elevation={2}>
          <ManageBilling handleManageBilling={handleManageBilling} />
        </Paper>
      )}
      <Paper elevation={2}>
        <PaygSummary
          k2Quantity={paygUsage.k2Quantity}
          k3Quantity={paygUsage.k3Quantity}
          total={paygUsage.total}
        />
      </Paper>
      <Paper elevation={2}>
        <SubscriptionSeats
          subscriptions={{
            big: updatedSubscriptionPackages.big,
            small: updatedSubscriptionPackages.small,
            total:
              updatedSubscriptionPackages.small.quantity *
                updatedSubscriptionPackages.small.price +
              updatedSubscriptionPackages.big.quantity *
                updatedSubscriptionPackages.big.price,
          }}
          proration={proration}
          isLoadingProration={isLoadingProration}
          handleUpdateSubscriptionsCount={handleUpdateSubscriptionsCount}
          handleConfirmProration={handleConfirmProration}
        />
      </Paper>
    </Wrapper>
  );
};

export default PaymentDetailsContainer;

import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { AgoyTableColumn, AgoyTableRow, numberValue } from '@agoy/document';
import { Table as CommonTable } from '_shared/components/CommonTable';
import TaxesDataContext from '_tax/service/TaxesDataContext';
import TaxDocumentTableAccountRow from './TaxDocumentTableAccountRow';

const Table = styled(CommonTable)`
  box-shadow: none;
  border: none;

  tbody {
    display: block;

    tr.active {
      display: flex;
      gap: 8px;
      width: 100%;
      align-items: center;
      flex: 1;

      td {
        padding-right: 0;
      }
    }

    .label-cell {
      flex: 1;
    }
  }

  .delete-cell {
    border: none;
  }

  td {
    border: none;
  }
`;

const isNumber = (item: number | undefined): item is number => {
  return !!item;
};

export interface TaxDocumentTableProps {
  baseId: string;
  tableId: string;
  editing: boolean;
  rows: AgoyTableRow[];
  columns: AgoyTableColumn[];
  isPrint?: boolean;
}

const TaxDocumentTable = ({
  baseId,
  tableId,
  editing,
  rows,
  columns,
  isPrint = false,
}: TaxDocumentTableProps): JSX.Element => {
  const { service } = useContext(TaxesDataContext);

  const selectedAccounts: string[] = rows
    .map((row) => row?.cells?.accountNumber)
    .map((accountNumberCell) => numberValue(accountNumberCell))
    .filter(isNumber)
    .map((accountNumber) => accountNumber.toString());

  return (
    <Table
      baseId={baseId}
      tableId={tableId}
      editing={editing}
      rows={rows}
      columns={columns}
      canAddRows
      service={service}
      hideHeader
      print={isPrint}
      renderRow={(subRow) => (
        <TaxDocumentTableAccountRow
          columns={columns}
          editing={editing}
          selectedAccounts={selectedAccounts}
          subRow={subRow}
        />
      )}
    />
  );
};

export default TaxDocumentTable;

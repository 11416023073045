import React from 'react';
import Typography from '../../../../UI/Typography/Typography';
import { stringValue } from '@agoy/document';
import {
  Activities as SharesCompanyActivitiesType,
  EconomicAssociationActivities,
  LimitedCompaniesActivities,
} from '@agoy/annual-report-document';
import { useIntl } from 'react-intl';
import { Heading, Section } from '../../../../UI';
import MultilineField from '../../UI/MultilineField';
import Accountant from './Accountant';

const Activities = ({
  reportPart,
  title,
  generallyLabel,
}: {
  reportPart:
    | SharesCompanyActivitiesType
    | EconomicAssociationActivities
    | LimitedCompaniesActivities;
  title: string | undefined;
  generallyLabel: string | undefined;
}) => {
  const intl = useIntl();

  if (!reportPart) return null;

  const titleValue =
    title ||
    intl.formatMessage({
      id: 'annualReport.menu.managementReport.activities',
    });

  return (
    <>
      <Section>
        <Heading title={titleValue} />
      </Section>

      {/* GENERALLY FIELD */}
      <Typography variant="h4">
        {generallyLabel ||
          intl.formatMessage({
            id: 'annualReport.managementReport.activities.aboutBusiness',
          })}
      </Typography>

      <MultilineField field={reportPart.generally} />

      {/* ACCOUNTANT FIELD */}
      <Accountant accountant={reportPart.accountant} />

      {/* REST OF NON K3 FIELDS */}
      {Object.keys(reportPart)
        .filter(
          (key) =>
            key !== 'active' &&
            key !== 'generally' &&
            key !== 'accountant' &&
            key !== 'currencyText' &&
            key !== 'boardText' &&
            key !== 'boardTextWithCeo' &&
            key !== 'boardWithoutCeo' &&
            key !== 'liquidatorText' &&
            reportPart[key].active
        )
        .map((fieldName) => {
          const show = stringValue(reportPart[fieldName]);
          const fieldLabel = `${fieldName}Label`;
          return (
            <div key={fieldName}>
              {show && (
                <Typography variant="h4">
                  {stringValue(reportPart[fieldLabel]) ||
                    intl.formatMessage({
                      id: `annualReport.managementReport.activities.${fieldName}`,
                    })}
                </Typography>
              )}
              {show && <MultilineField field={reportPart[fieldName]} />}
            </div>
          );
        })}
    </>
  );
};

export default Activities;

import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Typography } from '@material-ui/core';
import useComments from '_shared/hooks/useComments';
import styled from '@emotion/styled';
import CommentsSection from '_shared/components/Comments/CommentsSection';
import { format } from '@agoy/dates';
import { groupBy } from 'lodash';
import { Period } from '@agoy/api-sdk-core';
import Button from '_shared/components/Buttons/Button';
import { asResultClass, useApiSdk } from 'api-sdk';
import { useSelector } from 'redux/reducers';
import { addGlobalErrorMessage } from 'redux/actions';
import { useDispatch } from 'react-redux';
import { Program, Comment } from '_shared/types';
import { onUserInputCallbackFunction } from '../types';
import PeriodDataContext from '../PeriodDataContext';

const InternalCommentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const ControlsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing(0.5)}px;
`;

export const getNotCopiedComments = (
  sourceComments: Comment[],
  copiedComments: Comment[]
) => {
  const fetchedCommentsOriginals = copiedComments
    .filter((comment) => !!comment.copiedFrom?.id)
    .map((comment) => comment.copiedFrom?.id);
  return sourceComments.filter(
    (comment) => !fetchedCommentsOriginals.includes(comment.id)
  );
};

const InternalComments = ({
  account,
  period,
  onUserInputCallback,
}: {
  account: string;
  period: Period;
  onUserInputCallback: onUserInputCallbackFunction;
}) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const { addComments } = useApiSdk();
  const userId = useSelector((state) => state.user['custom:userId']);
  const [showPreview, setShowPreview] = useState(false);

  const {
    clientId,
    previousPeriod,
    previousPeriodFinancialYear,
    financialYear,
    periodLocked,
    nextPeriod,
    nextPeriodLocked,
    nextPeriodFinancialYear,
    periodType,
  } = useContext(PeriodDataContext);

  const key = `account.${account}.internal`;
  const financialYearId = financialYear.id;
  const program: Program = 'RECONCILIATION';

  const {
    comments: internalComments,
    createComment,
    loading: loadingComments,
    reloadComments: reloadInternalComments,
    savingNewComment,
  } = useComments(clientId, program, financialYearId, period.id, key);

  const {
    comments: previousPeriodComments,
    loading: loadingPreview,
    reloadComments: reloadPreviousPeriodComments,
  } = useComments(
    clientId,
    program,
    financialYearId,
    previousPeriod ? previousPeriod.id : undefined,
    key
  );

  const {
    comments: nextPeriodComments,
    loading: loadingNextPeriodComments,
    reloadComments: reloadNextPeriodComments,
  } = useComments(
    clientId,
    program,
    financialYearId,
    nextPeriod ? nextPeriod.id : undefined,
    key
  );

  const notCopiedFromPrevious = useMemo(
    () => getNotCopiedComments(previousPeriodComments, internalComments),
    [internalComments, previousPeriodComments]
  );

  const notCopiedToNext = useMemo(
    () => getNotCopiedComments(internalComments, nextPeriodComments),
    [internalComments, nextPeriodComments]
  );

  useEffect(() => {
    if (internalComments.length === 0 && notCopiedFromPrevious.length > 0) {
      setShowPreview(true);
    }
  }, [internalComments.length, notCopiedFromPrevious.length]);

  useEffect(() => {
    // won't be needed for new reconciliation
    const newComments = internalComments.map((comment) => ({
      message: comment.comment,
      author: comment.userDisplayName,
      timestamp: comment.createdAt,
    }));
    onUserInputCallback(
      { internalComments: newComments.length > 0 },
      account,
      period
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [internalComments.length]);

  const previousPeriodFormat = periodType === 'quarter' ? 'QQQ' : 'MMM-yy';

  const internalCommentsPreview =
    previousPeriod && notCopiedFromPrevious.length > 0
      ? {
          comments: notCopiedFromPrevious,
          title: formatMessage(
            { id: 'comments.reconciliation.preview' },
            {
              period: format(
                new Date(previousPeriod.start),
                previousPeriodFormat
              ),
            }
          ),
        }
      : undefined;

  const commentsGroups = groupBy(internalComments, (comment) =>
    comment.copiedFrom ? 'copiedFromPrevious' : 'native'
  );

  const copiedComments = useMemo(() => {
    if (!commentsGroups.copiedFromPrevious) {
      return undefined;
    }

    const commentWithInfo = commentsGroups.copiedFromPrevious[0];

    return {
      title: formatMessage(
        { id: 'comments.reconciliation.copied' },
        {
          period: previousPeriod
            ? format(new Date(previousPeriod.start), previousPeriodFormat)
            : '',
        }
      ),
      comments: commentsGroups.copiedFromPrevious,
      additionalInfo: formatMessage(
        { id: 'comments.reconciliation.copiedBy' },
        {
          userName: commentWithInfo?.userDisplayName || '',
          timestamp: format(
            new Date(commentWithInfo.createdAt),
            'yyyy-MM-dd HH:mm'
          ),
        }
      ),
    };
  }, [
    commentsGroups.copiedFromPrevious,
    formatMessage,
    previousPeriod,
    previousPeriodFormat,
  ]);

  const copyComments = async (
    commentsToCopy: Comment[],
    newPeriodId: number,
    afterCopy: (comments: Comment[]) => Promise<void>
  ) => {
    if (!commentsToCopy.length) {
      return;
    }

    const newComments = commentsToCopy.map((oldComment) => ({
      userId,
      program,
      financialYearId,
      periodId: newPeriodId,
      key,
      comment: oldComment.comment,
      copiedFromId: oldComment.id,
    }));

    const addResult = await asResultClass(
      addComments({ clientId, requestBody: newComments })
    );

    if (addResult.err) {
      dispatch(addGlobalErrorMessage('comments.failed.sending'));
      return;
    }

    await afterCopy(commentsToCopy);
  };

  const copyFromPrevious = () =>
    copyComments(notCopiedFromPrevious, period.id, async () => {
      await reloadInternalComments();
      await reloadPreviousPeriodComments();
      setShowPreview(false);
    });

  const copyToNext = () =>
    nextPeriod &&
    copyComments(notCopiedToNext, nextPeriod.id, async (commentsToCopy) => {
      const newComments = commentsToCopy.map((comment) => ({
        message: comment.comment,
        author: comment.userDisplayName,
        timestamp: comment.createdAt,
      }));
      onUserInputCallback(
        { internalComments: newComments.length > 0 },
        account,
        nextPeriod
      );
      await reloadNextPeriodComments();
    });

  const getButtonName = (id) => formatMessage({ id: `comments.copy.${id}` });

  return (
    <>
      <Typography variant="body1" color="textSecondary">
        {formatMessage({ id: 'comments.internalComments.title' })}
      </Typography>
      <InternalCommentsContainer>
        <CommentsSection
          multiline
          comments={commentsGroups.native ?? []}
          addComment={createComment}
          loading={
            loadingComments || loadingPreview || loadingNextPeriodComments
          }
          savingNewComment={savingNewComment}
          showPreview={showPreview}
          preview={internalCommentsPreview}
          subsections={copiedComments ? [copiedComments] : undefined}
          disabled={periodLocked}
        />
      </InternalCommentsContainer>
      {(internalComments.length > 0 || notCopiedFromPrevious.length > 0) && (
        <ControlsContainer>
          {previousPeriod &&
            period.type !== 'year_end' &&
            financialYear.id === previousPeriodFinancialYear?.id &&
            !loadingComments &&
            !loadingPreview && (
              <Button
                label={getButtonName('fetchFromPrevious')}
                variant="text"
                size="small"
                disabled={notCopiedFromPrevious.length === 0 || periodLocked}
                onClick={copyFromPrevious}
                onMouseEnter={() =>
                  internalCommentsPreview && setShowPreview(true)
                }
                onMouseLeave={() =>
                  internalComments.length > 0 && setShowPreview(false)
                }
              />
            )}
          {nextPeriod &&
            nextPeriod.type !== 'year_end' &&
            financialYear.id === nextPeriodFinancialYear?.id &&
            !loadingComments && (
              <Button
                label={getButtonName('copyToNextPeriod')}
                variant="text"
                size="small"
                onClick={copyToNext}
                disabled={!!nextPeriodLocked || notCopiedToNext.length === 0}
              />
            )}
        </ControlsContainer>
      )}
    </>
  );
};

export default InternalComments;

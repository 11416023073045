export const ADD_CUSTOMER = 'customers/ADD_CUSTOMER';
export const UPDATE_CUSTOMER = 'customers/UPDATE_CUSTOMER';
export const SET_CUSTOMER_LOGO = 'customers/SET_CUSTOMER_LOGO';
export const DELETE_CLIENT = 'customers/DELETE_CLIENT';
export const POPULATE_CUSTOMERS = 'customers/POPULATE_CUSTOMERS';
export const RESET_CUSTOMERS = 'customers/RESET_CUSTOMERS';
export const SET_INTEGRATION_STATUS = 'customers/SET_INTEGRATION_STATUS';
export const SET_FINANCIAL_YEARS = 'customers/SET_FINANCIAL_YEARS';
export const SET_LAMP_STATUS = 'customers/SET_LAMP_STATUS';
export const SET_CONNECTIONS_TO_COMPANIES =
  'customers/SET_CONNECTIONS_TO_COMPANIES';
export const DELETE_CONNECTION = 'customers/DELETE_CONNECTION';
export const SET_COMPANY_SHARE_OWNERS = 'customers/SET_COMPANY_SHARE_OWNERS';
export const SET_CHECKLISTS = 'customers/SET_CHECKLISTS';

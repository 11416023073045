import React from 'react';
import { useDispatch } from 'react-redux';

import { useSelector } from 'redux/reducers';
import config from '_shared/services/config';
import { activeFeatureFlags } from '_shared/HOC/withFeatureFlags';
import { useMessagesContext } from '_messages/contexts/Messages';
import {
  addChannelMessage,
  addGlobalErrorMessage,
  removeChannelMessage,
} from '_messages/redux/actions';
import EditOrganisation from '_organization/components/organisms/EditOrganisation';
import { useOrganisationService } from '_organization/hooks/userOrganisationService';
import DisconnectFortnox from '_organization/components/organisms/DisconnectFortnox';
import useAuth from '_authentication/hooks/useAuth';
import { userLogout } from '_users/redux/actions';
import { useApiSdk } from 'api-sdk';

const IMAGE_UPLOAD_MESSAGE = 'uploadOrganizationImageError';

const useCanDisconnectFromFortnox = (): boolean => {
  return (
    useSelector((state) => state.user.organisationId?.startsWith('cus_')) ??
    false
  );
};

const GeneralContainer = () => {
  const dispatch = useDispatch();
  const { messageChannel } = useMessagesContext();
  const { data: organisation } = useOrganisationService();
  const { logOutAWS } = useAuth();

  // should be replaced by a statefull Hook (w. Context)
  const isAdmin =
    useSelector((state) => state.user.roles)?.includes('OrganisationAdmin') ||
    false;

  const featureDisconnectFortnox = activeFeatureFlags.get(
    'feature_disconnectFortnox'
  );
  const canDisconnectFromFortnox = useCanDisconnectFromFortnox();
  const sdk = useApiSdk();
  const handleDisconnect = async () => {
    if (organisation?.organisationId) {
      try {
        await sdk.disconnectFortnox({
          organisationId: organisation.organisationId,
        });
        logOutAWS();
        dispatch(userLogout());
      } catch (e) {
        dispatch(addGlobalErrorMessage('error'));
      }
    }
  };

  return (
    <>
      {config.whiteLabel &&
        featureDisconnectFortnox &&
        canDisconnectFromFortnox && (
          <DisconnectFortnox handleDisconnect={handleDisconnect} />
        )}
      <EditOrganisation
        isAdmin={isAdmin}
        addLogoUploadErrorNotification={(errorMessage: string) => {
          dispatch(
            addChannelMessage(
              'error',
              'global',
              IMAGE_UPLOAD_MESSAGE,
              errorMessage
            )
          );
        }}
        removeLogoUploadErrorNotification={() => {
          dispatch(removeChannelMessage(messageChannel, IMAGE_UPLOAD_MESSAGE));
        }}
      />
    </>
  );
};

export default GeneralContainer;

import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';
import { Typography, Box } from '@material-ui/core';

const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const SessionTimedOut = () => {
  const intl = useIntl();

  const timeout = setTimeout(() => {
    window.location.href = 'https://apps.fortnox.se/fs/fs/login.php';
  }, 10000);

  useEffect(() => {
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <Wrapper>
      <Typography component="div">
        <Box fontWeight="fontWeightBold">
          {intl.formatMessage({ id: 'fortnox.whitelabel.sessionTimedOut' })}
        </Box>
      </Typography>
      <Box>
        <Typography>
          {intl.formatMessage({
            id: 'fortnox.whitelabel.willBeRedirected',
          })}
        </Typography>
      </Box>
    </Wrapper>
  );
};

export default SessionTimedOut;

const splitId = (id: string): string[] => {
  if (id.includes('votingList') || id.includes('-signature')) {
    const signatureId = id.split('.');
    return signatureId;
  }

  return id.split(/[\.-]/g);
};

export default splitId;

import { parse } from '@agoy/dates';
import { differenceInYears, isAfter } from 'date-fns';

const checkLeapYear = (year: number) => {
  if ((0 == year % 4 && 0 != year % 100) || 0 == year % 400) {
    return true;
  } else {
    return false;
  }
};

/* Common function to validate day in month and year. Could be used to validate day for coordination number, but should be checked separately from Luhm algorithm */
const checkValidDay = (month: string, day: string, isLeapYear: boolean) => {
  if (month === '02') {
    if (isLeapYear) {
      if (+day > 29) {
        return false;
      }
    } else {
      if (+day > 28) {
        return false;
      }
    }
  } else if (
    (month === '04' || month === '06' || month === '09' || month === '11') &&
    +day > 30
  ) {
    return false;
  } else if (+day > 31) {
    return false;
  }

  return true;
};

/**
 * The coordination number (samordnignsnummer) is the Swedish national identification number. It is a ten or twelve digit number that is used in Sweden to identify individuals who have any sort of contact with Swedish authorities, but not yet have a Swedish citizenship. It is structured along the same lines, but with the day in the date of birth advanced by 60 (giving a number between 61 and 91).
 * DD - day + 60;
 */

const checkValidCoordinationNumberDay = (
  month: string,
  day: string,
  isLeapYear: boolean
) => {
  /* normalize coordination day to check if it's correct */
  const normalizedDay = (+day - 60).toString();
  return checkValidDay(month, normalizedDay, isLeapYear);
};

export const getYearFromYYMMDD = (yymmdd: string): string => {
  if (isAfter(parse(`20${yymmdd}`, 'yyyyMMdd'), Date.now())) {
    return '19';
  }
  return '20';
};

export const getCentenariansYearFromYYMMDD = (yymmdd: string): string => {
  if (isAfter(parse(`20${yymmdd}`, 'yyyyMMdd'), Date.now())) {
    return '18';
  }
  return '19';
};

/**
 * Normalizes a personal number to standard YYYYMMDDBBBC or YYYYMMDD-BBBC or YYYYMMDD+BBBC
 *
 * YY or YYYY - Year.
 * MM - Month.
 * DD - Day.
 * BB(B) - Birth number. Last B - odd numbers for male, and even numbers for female.
 * C - Checksum (Calculated using the Luhm algorithm).
 *
 * PIN with Hyphen delimiter or without it - persons age before 100 years;
 * PIN with Plus delimiter - persons age below 100 years;
 *
 * @param perNr format 10 or 12-digits, with or without a dash or plus
 * @param withDash optional, include dash (Hyphen delimiter). Defaults to false.
 * @param withPlus optional, include plus (Plus delimiter). Defaults to false.
 */

export const normalizePersonalNumber = (
  perNr: string | undefined,
  withDash = false,
  withPlus = false
): string => {
  if (!perNr) {
    return '';
  }

  const match = /^(18|19|20|)?(\d{6})[-–+]?(\d{4})$/g.exec(perNr.trim());

  if (!match) {
    throw new Error(`Invalid personal number ${perNr}`);
  }

  const isPlusDelimiter = /[+]/g.exec(perNr);

  let isCenturyBirthday = false;

  if (match[1]) {
    const yearsDiff = differenceInYears(parse(), parse(`${match[1]}${match[2]}`, 'yyyyMMdd'));
    if (yearsDiff >= 100) {
      isCenturyBirthday = true;
    }
  }

  if (isPlusDelimiter || isCenturyBirthday) {
    const year = match[1] || getCentenariansYearFromYYMMDD(match[2]);
    return `${year}${match[2]}${withPlus ? '+' : ''}${match[3]}`;
  }

  const year = match[1] || getYearFromYYMMDD(match[2]);
  return `${year}${match[2]}${withDash ? '-' : ''}${match[3]}`;
};

export const formatPersonalNumber = (
  perNr: string,
  withDash = false,
  withPlus = false,
  year: 'never' | 'ifNeeded' | 'always' = 'always'
): string => {
  const match = /^(18|19|20|)?(\d{6})[-–+]?(\d{4})$/g.exec(perNr.trim());

  if (!match) {
    return perNr;
  }

  const isPlusDelimiter = /[+]/g.exec(perNr);

  let isCenturyBirthday = false;

  if (match[1]) {
    const yearsDiff = differenceInYears(parse(), parse(`${match[1]}${match[2]}`, 'yyyyMMdd'));
    if (yearsDiff >= 100) {
      isCenturyBirthday = true;
    }
  }

  if (year === 'never') {
    if (isPlusDelimiter || isCenturyBirthday) {
      return `${match[2]}${withPlus ? '+' : ''}${match[3]}`;
    }

    return `${match[2]}${withDash ? '-' : ''}${match[3]}`;
  }
  if (year === 'always') {
    if (isPlusDelimiter || isCenturyBirthday) {
      const yearValue = match[1] || getCentenariansYearFromYYMMDD(match[2]);
      return `${yearValue}${match[2]}${withPlus ? '+' : ''}${match[3]}`;
    }

    const yearValue = match[1] || getYearFromYYMMDD(match[2]);
    return `${yearValue}${match[2]}${withDash ? '-' : ''}${match[3]}`;
  }

  if (isPlusDelimiter || isCenturyBirthday) {
    const inferredYear = getCentenariansYearFromYYMMDD(match[2]);
    const yearValue = !match[1] || match[1] === inferredYear ? '' : match[1];

    return `${yearValue}${match[2]}${withPlus ? '+' : ''}${match[3]}`;
  }

  const inferredYear = getYearFromYYMMDD(match[2]);
  const yearValue = !match[1] || match[1] === inferredYear ? '' : match[1];

  return `${yearValue}${match[2]}${withDash ? '-' : ''}${match[3]}`;
};

/**
 * Check if a given number is valid according to Luhn algorithm
 * @param {string} perNumber can contain `-`  or `+` chars
 */
export const isValidPersonalNumber = (perNumber: string): boolean => {
  if (typeof perNumber !== 'string') return false;

  let normalizedPersonalNumber: string;
  try {
    normalizedPersonalNumber = normalizePersonalNumber(perNumber);
  } catch {
    return false;
  }

  if (!normalizedPersonalNumber.length) {
    return false;
  }

  let sum = 0;
  let doubleUpDigit = false;

  if (normalizedPersonalNumber.length === 12) {
    /* check date from future */
    const fullDate = normalizedPersonalNumber.substring(0, 8);
    if (isAfter(parse(`${fullDate}`, 'yyyyMMdd'), Date.now())) {
      return false;
    }
    /* check for correct year (century) */
    const year = normalizedPersonalNumber.substring(0, 2);
    if (year !== '18' && year !== '19' && year !== '20') {
      return false;
    }

    /* check for leap year */
    const fullYear = normalizedPersonalNumber.substring(0, 4);
    const isLeapYear = checkLeapYear(+fullYear);

    /* check for correct month */
    const month = normalizedPersonalNumber.substring(4, 6);
    if (+month > 12) {
      return false;
    }

    /* check for valid day of month */
    const day = normalizedPersonalNumber.substring(6, 8);
    let isValidDay;

    /* if the number is coordination number, validate day according to documentation */
    if (+day >= 61 && +day <= 91) {
      isValidDay = checkValidCoordinationNumberDay(month, day, isLeapYear);
    } else {
      isValidDay = checkValidDay(month, day, isLeapYear);
    }

    if (!isValidDay) {
      return false;
    }
  }
  const last10digits = normalizedPersonalNumber.substring(
    normalizedPersonalNumber.length - 10
  );

  const last4digits = normalizedPersonalNumber.substring(
    normalizedPersonalNumber.length - 4
  );

  if (last4digits === '0000') {
    return true;
  }

  /* from the right to left, double every other digit starting with the second to last digit. */
  for (let i = last10digits.length - 1; i >= 0; i--) {
    const currentDigit = parseInt(last10digits.charAt(i), 10);

    /* double every other digit starting with the second to last digit */
    if (doubleUpDigit) {
      /* doubled number is greater than 9 than subtracted 9 */
      if (currentDigit * 2 > 9) {
        sum += currentDigit * 2 - 9;
      } else {
        sum += currentDigit * 2;
      }
    } else {
      sum += currentDigit;
    }
    doubleUpDigit = !doubleUpDigit;
  }

  /* sum and divide it by 10. If the remainder equals zero, the number is valid.  */
  return sum % 10 === 0;
};

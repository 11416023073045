import {
  FormatMessageCell,
  LabelCell,
  MultiReferenceCell,
  NumberCell,
  ReferenceCell,
  StringCell,
  BooleanCell,
  Cell,
} from './types';

export const label = (value: string): LabelCell => ({ type: 'label', value });
export const ref = (
  reference: string,
  tooltip?: string,
  unit?: string,
  warning?: ReferenceCell
): ReferenceCell => ({
  type: 'ref',
  reference,
  value: { error: 'notResolved' },
  ...(tooltip ? { tooltip } : {}),
  ...(unit ? { unit } : {}),
  ...(warning ? { warning } : {}),
});

export const refs = (...references: string[]): MultiReferenceCell => ({
  type: 'refs',
  references,
  values: references.map(() => ({ error: 'notResolved' })),
});

export const value = (
  val?: string | number | boolean | undefined,
  tooltip?: string,
  unit?: string,
  warning?: ReferenceCell // only ref and number cell support warnings, add as needed
): NumberCell | StringCell | BooleanCell => {
  const tooltipSpreadObj = tooltip ? { tooltip } : {};
  const unitSpreadObj = unit ? { unit } : {};
  const warningSpreadObj = warning ? { warning } : {};

  switch (typeof val) {
    case 'string':
      return {
        type: 'string',
        value: val,
        ...tooltipSpreadObj,
        ...unitSpreadObj,
      };
    case 'number':
      return {
        type: 'number',
        value: val,
        ...tooltipSpreadObj,
        ...unitSpreadObj,
        ...warningSpreadObj,
      };
    case 'undefined':
      return {
        type: 'number',
        value: val,
        ...tooltipSpreadObj,
        ...unitSpreadObj,
      };
    case 'boolean':
      return { type: 'boolean', value: val, ...tooltipSpreadObj };
    default:
      throw new Error(`Unsupported to of value ${typeof val}`);
  }
};

export const msg = (
  message: string,
  parameterReferences?: Record<string, string>
): FormatMessageCell => ({
  type: 'msg',
  message,
  value: { error: 'notResolved' },
  parameterReferences,
});

export const tooltip = (cell: Cell, tooltip: string): Cell => ({
  ...cell,
  tooltip,
});

import React from 'react';
import { useIntl } from 'react-intl';
import { Typography } from '@material-ui/core';
import styled from '@emotion/styled';
import { Variant } from '@material-ui/core/styles/createTypography';
import { Field } from '@agoy/document';

const Section = styled.div`
  justify-content: space-between;
  margin: 0.25rem 0;

  @media print {
    break-inside: avoid;
  }
`;

type ToggleSectionProps = {
  titleVariant?: Variant;
  id: string;
  section: string; // note1 ex
  target: Field | undefined;
  children?: React.ReactNode;
  title?: string;
};

const ToggleSection = ({
  titleVariant = 'h3',
  id,
  section,
  target,
  children,
  title,
}: ToggleSectionProps): JSX.Element | null => {
  const { formatMessage } = useIntl();

  if (!target) {
    console.error(id);
    return null;
  }

  const { active } = target;

  if (!active) {
    return null;
  }

  return (
    <>
      <Section>
        <Typography variant={titleVariant}>
          {title ||
            formatMessage({
              id: `annualReport.notes.${section}.${id}`,
            })}
        </Typography>
        {active && children}
      </Section>
    </>
  );
};

export default ToggleSection;

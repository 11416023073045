import React, { useRef } from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';
import { colors as ThemeColors } from '@agoy/theme';
import { ChecklistItemStatus } from '_shared/redux/checklist/types';
import { STATUSES } from '_shared/redux/checklist/constants';

const Columns = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
`;

const Button = styled(IconButton)`
  padding: 8px 8px;
  height: ${({ theme }) => theme.spacing(5)}px;
  justify-content: center;
`;

const ButtonStatusDot = styled.div<{ selected: boolean; statusColor?: string }>`
  display: flex;
  border-radius: 50%;
  height: ${({ theme }) => theme.spacing(3)}px;
  width: ${({ theme }) => theme.spacing(3)}px;
  ${({ selected, statusColor, theme }) => {
    if (selected) {
      return `background-color: ${statusColor || theme.palette.grey[400]};`;
    }
    return `border: 2px solid ${statusColor || theme.palette.grey[400]};`;
  }}
`;

type StatusColumnsProps = {
  value: ChecklistItemStatus;
  onOptionSelected: (optionId: ChecklistItemStatus) => void;
};

const StatusColumns = ({
  value,
  onOptionSelected,
}: StatusColumnsProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const anchorRef = useRef(null);

  const handleMenuItemClick = (optionId: ChecklistItemStatus) => {
    if (onOptionSelected) {
      onOptionSelected(optionId);
    }
  };

  return (
    <Columns>
      {STATUSES.map((status) => {
        return (
          <Tooltip
            key={status}
            title={formatMessage({ id: `checklist.status.${status}` })}
          >
            <Button
              size="small"
              onClick={() => handleMenuItemClick(status)}
              ref={anchorRef}
            >
              <ButtonStatusDot
                selected={value === status}
                statusColor={ThemeColors[`checklist_${status}`]?.main}
              />
            </Button>
          </Tooltip>
        );
      })}
    </Columns>
  );
};

export default StatusColumns;

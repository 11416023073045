import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { SnackbarProps } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import styled from '@emotion/styled';

import { useSelector } from 'redux/reducers';
import Snackbar from '_shared/components/Snackbar';
import { removeGlobalMessage } from '_messages/redux/actions';
import { Message } from '_messages/types';

const StackedSnackBar = styled(Snackbar)<SnackbarProps & { index: number }>`
  margin-top: ${(props) => props.theme.spacing(props.index * 8)}px;
  transition: margin-top 0.25s ease-in-out;
`;

const EMPTY_ARRAY = [];

const Messages = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const messages: Message[] = useSelector(
    (state) => state.messages.global || EMPTY_ARRAY
  );
  const [container, setContainer] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    const el = document.createElement('div');
    const portals = document.getElementById('portals');
    portals?.appendChild(el);
    setContainer(el);

    return () => {
      portals?.removeChild(el);
    };
  }, []);

  return (
    container &&
    createPortal(
      <>
        {messages.map((msg, index) => (
          <StackedSnackBar
            open
            key={msg.id}
            index={index}
            autoHideDuration={msg.severity === 'success' ? 5000 : 30000}
            onClose={() => {
              dispatch(removeGlobalMessage(msg.id));
            }}
          >
            <Alert
              onClose={() => {
                dispatch(removeGlobalMessage(msg.id));
              }}
              severity={msg.severity}
            >
              <AlertTitle>
                {msg.textId ? formatMessage({ id: msg.textId }) : msg.text}
              </AlertTitle>
            </Alert>
          </StackedSnackBar>
        ))}
      </>,
      container
    )
  );
};

export default Messages;

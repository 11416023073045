import { id, or, ref, sum, table, value } from '@agoy/document';
import { IxbrlCell, NoteOneTableWithSum } from '../../../../common';
import { year } from '../../../../common/utils/date-util';
import {
  active,
  field,
  ixbrlCell,
  ixbrlField,
  tupleRef,
} from '../../../../common/utils/util';
import { sumAccountsUBs } from '../../../../common/utils/accountSumsUtil';
import { NoteAccountRanges } from '../types';

const MONETARY_ITEM_TYPE = 'monetaryItemType';

export const note63Accounts: NoteAccountRanges[] = [
  {
    rowId: '1',
    ranges: [[6420]],
    cellLabel: 'Revisionsuppdrag',
    action: 'sumAccountsUBs',
  },
  {
    rowId: '2',
    ranges: [[6422]],
    cellLabel: 'Revisionsverksamhet utöver revisionsuppdraget',
    action: 'sumAccountsUBs',
  },
  {
    rowId: '3',
    ranges: [[6423]],
    cellLabel: 'Skatterådgivning, revisionsföretag',
    action: 'sumAccountsUBs',
  },
  {
    rowId: '4',
    ranges: [[6424]],
    cellLabel: 'Övriga tjänster',
    action: 'sumAccountsUBs',
  },
];

const getNote63 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null,
  documentTypeVersion = '1'
): NoteOneTableWithSum => {
  return {
    active: false,
    number: value(undefined),
    name: value('Ersättningar till revisorer'),
    table: table<IxbrlCell>(
      'notes.note63.table',
      {
        id: 'label',
        label: '',
      },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      }
    )
      .addRows((rows) =>
        rows
          .addRow(
            '1',
            ixbrlCell(value('Revisionsuppdrag'), {
              type: 'stringItemType',
              name: 'se-gen-base:RevisorerRevisionsforetagNamn',
              contextRef: 'period0',
              tuple: {
                name: 'se-gaap-ext:ErsattningRevisorerTuple',
                id: `${rows.getBaseId()}.1.tuple`,
                tupleID: `${rows.getBaseId()}.1.tuple`,
              },
              tupleRef: tupleRef(1, `${rows.getBaseId()}.1.tuple`),
            }),
            ixbrlCell(ref(sumAccountsUBs('6420', true)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:RevisorerRevisionsforetagErsattningUppdrag',
              contextRef: 'period0',
              saldo: 'debit',
              tupleRef: tupleRef(2, `${rows.getBaseId()}.1.tuple`),
            })
          )
          .addRow(
            '2',
            ixbrlCell(value('Revisionsverksamhet utöver revisionsuppdraget'), {
              type: 'stringItemType',
              name: 'se-gen-base:RevisorerRevisionsforetagNamn',
              contextRef: 'period0',
              tuple: {
                name: 'se-gaap-ext:ErsattningRevisorerTuple',
                id: `${rows.getBaseId()}.2.tuple`,
                tupleID: `${rows.getBaseId()}.2.tuple`,
              },
              tupleRef: tupleRef(1, `${rows.getBaseId()}.2.tuple`),
            }),
            ixbrlCell(ref(sumAccountsUBs('6422', true)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:RevisorerRevisionsforetagErsattningUppdrag',
              contextRef: 'period0',
              saldo: 'debit',
              tupleRef: tupleRef(2, `${rows.getBaseId()}.2.tuple`),
            })
          )
          .addRow(
            '3',
            ixbrlCell(value('Skatterådgivning, revisionsföretag'), {
              type: 'stringItemType',
              name: 'se-gen-base:RevisorerRevisionsforetagNamn',
              contextRef: 'period0',
              tuple: {
                name: 'se-gaap-ext:ErsattningRevisorerTuple',
                id: `${rows.getBaseId()}.3.tuple`,
                tupleID: `${rows.getBaseId()}.3.tuple`,
              },
              tupleRef: tupleRef(1, `${rows.getBaseId()}.3.tuple`),
            }),
            ixbrlCell(ref(sumAccountsUBs('6423', true)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:RevisorerRevisionsforetagErsattningUppdrag',
              contextRef: 'period0',
              saldo: 'debit',
              tupleRef: tupleRef(2, `${rows.getBaseId()}.3.tuple`),
            })
          )
          .addRow(
            '4',
            ixbrlCell(value('Övriga tjänster'), {
              type: 'stringItemType',
              name: 'se-gen-base:RevisorerRevisionsforetagNamn',
              contextRef: 'period0',
              tuple: {
                name: 'se-gaap-ext:ErsattningRevisorerTuple',
                id: `${rows.getBaseId()}.4.tuple`,
                tupleID: `${rows.getBaseId()}.4.tuple`,
              },
              tupleRef: tupleRef(1, `${rows.getBaseId()}.4.tuple`),
            }),
            ixbrlCell(ref(sumAccountsUBs('6424', true)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:RevisorerRevisionsforetagErsattningUppdrag',
              contextRef: 'period0',
              saldo: 'debit',
              tupleRef: tupleRef(2, `${rows.getBaseId()}.4.tuple`),
            })
          )
          .build()
      )
      .newRowTemplateGenerator((id, baseId) => ({
        id,
        active: true,
        cells: {
          label: ixbrlCell(value(''), {
            type: 'stringItemType',
            name: 'se-gen-base:RevisorerRevisionsforetagNamn',
            contextRef: 'period0',
            tuple: {
              name: 'se-gaap-ext:ErsattningRevisorerTuple',
              id: `${baseId}.${id}.tuple`,
              tupleID: `${baseId}.${id}.tuple`,
            },
            tupleRef: tupleRef(1, `${baseId}.${id}.tuple`),
          }),
          year: ixbrlCell(value(undefined), {
            type: MONETARY_ITEM_TYPE,
            name: 'se-gen-base:RevisorerRevisionsforetagErsattningUppdrag',
            contextRef: 'period0',
            saldo: 'debit',
            tupleRef: tupleRef(2, `${baseId}.${id}.tuple`),
          }),
        },
      }))
      .build(),
    sumTable: table(
      'notes.note63.sumTable',
      { id: 'label', label: '' },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      }
    )
      .addRows((rows) =>
        rows
          .addRow(
            'sum',
            noteLabel('Summa'),
            ixbrlCell(ref(or(sum(id('notes.note63.table.*.year')), 0)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:RevisorerRevisionsforetagErsattning',
              contextRef: 'period0',
            })
          )
          .build()
      )
      .build(),
    commentLabel: field('Kommentar till not'),
    comment: ixbrlField(active(field('')), {
      type: 'stringItemType',
      name: 'se-gen-base:NotErsattningRevisorerKommentar',
      contextRef: 'period0',
    }),
    group: value(undefined),
    section: value(undefined),
    validForReportType: {
      k2: true,
      k3: documentTypeVersion === '1',
    },
  };
};

export default getNote63;

import {
  account,
  AgoyTable,
  Field,
  label,
  not,
  ref,
  round,
  value,
} from '@agoy/document';
import {
  boolean,
  cell,
  Note2Section,
  NoteInputFieldSection,
  noteInputFieldSectionStructure,
  NoteOneTableSection,
  noteOneTableSectionStructure,
  NotesSection,
} from '../../../common';
import { previousYear, year } from '../../../common/utils/date-util';
import {
  active,
  activeReference,
  field,
  inactiveOnReportTypeK2,
  table,
} from '../../../common/utils/util';
import { AnnualReportSection, AnnualReportType } from '../../../document';

export interface FoundationNote1Section
  extends AnnualReportSection,
    NotesSection {
  disclaimerK2: Field;
  disclaimerK3: Field;
  changeOfAccountingRegulationsLabel: Field;
  changeOfAccountingRegulations: Field;
  tangibleFixedAssetsLabel: Field;
  tangibleFixedAssetsGeneral: Field;
  tangibleFixedAssets: AgoyTable;
  receivablesLabel: Field;
  receivables: Field;
  otherAssetsProvisionsAndLiabilitiesLabel: Field;
  otherAssetsProvisionsAndLiabilities: Field;
  revenueRecognitionLabel: Field;
  revenueRecognition: Field;
  saleOfGoodsLabel: Field;
  saleOfGoods: Field;
  servicesLabel: Field;
  services: Field;
  acquisitionGoodsLabel: Field;
  acquisitionGoods: Field;
  changedPrinciplesLabel: Field;
  changedPrinciplesHint: Field;
  changedPrinciples: Field;
  rentsLabel: Field;
  rents: Field;
  interestRoyaltyAndDividensLabel: Field;
  interestRoyaltyAndDividens: Field;
  intangibleAssetsGeneralLabel: Field;
  tangibleAssetsCommentLabel: Field;
  tangibleAssetsComment: Field;
  deviationsFromBasicAccountingPrinciplesLabel: Field;
  deviationsFromBasicAccountingPrinciples: Field;
  serviceAndContractingAssignmentsLabel: Field;
  serviceAndContractingAssignments: Field;
  serviceAndContractingAssignmentsParagraph1: Field;
  serviceAndContractingAssignmentsParagraph2: Field;
  intangibleAssetsGeneral: Field;
  intangibleAssetsTable: AgoyTable;
  intangibleAssetsProjectsClosed: Field;
  incomeTaxLabel: Field;
  incomeTaxParagraph1: Field;
  incomeTaxParagraph2: Field;
  incomeTaxParagraph3: Field;
  incomeTaxParagraph4: Field;
  incomeTaxParagraph5: Field;
  commentLabel: Field;
  comment: Field;
}

export interface IncomeStatementNotes {
  note1: FoundationNote1Section;
  note2: Note2Section;
  note3: NoteOneTableSection;
  note4: NoteOneTableSection;
  note5: NoteOneTableSection;
  note45: NoteInputFieldSection;
  note48: NoteInputFieldSection;
  note49: NoteOneTableSection;
  note52: NoteOneTableSection;
  note53: NoteInputFieldSection;
  note54: NoteInputFieldSection;
}

export const incomeStatementNotesStructure = {
  note1: {
    type: 'part' as const,
    children: {
      active: boolean,
      number: cell,
      name: cell,
      disclaimerK2: cell,
      disclaimerK3: cell,
      tangibleFixedAssetsLabel: cell,
      tangibleFixedAssetsGeneral: cell,
      changeOfAccountingRegulationsLabel: cell,
      changeOfAccountingRegulations: cell,
      tangibleFixedAssets: { type: 'table' as const },
      receivablesLabel: cell,
      receivables: cell,
      otherAssetsProvisionsAndLiabilitiesLabel: cell,
      otherAssetsProvisionsAndLiabilities: cell,
      revenueRecognitionLabel: cell,
      revenueRecognition: cell,
      saleOfGoodsLabel: cell,
      saleOfGoods: cell,
      servicesLabel: cell,
      services: cell,
      acquisitionGoodsLabel: cell,
      acquisitionGoods: cell,
      changedPrinciplesLabel: cell,
      changedPrinciplesHint: cell,
      changedPrinciples: cell,
      rentsLabel: cell,
      rents: cell,
      interestRoyaltyAndDividensLabel: cell,
      interestRoyaltyAndDividens: cell,
      intangibleAssetsGeneralLabel: cell,
      deviationsFromBasicAccountingPrinciplesLabel: cell,
      deviationsFromBasicAccountingPrinciples: cell,
      serviceAndContractingAssignmentsLabel: cell,
      serviceAndContractingAssignments: cell,
      serviceAndContractingAssignmentsParagraph1: cell,
      serviceAndContractingAssignmentsParagraph2: cell,
      tangibleAssetsCommentLabel: cell,
      tangibleAssetsComment: cell,
      intangibleAssetsGeneral: cell,
      intangibleAssetsTable: { type: 'table' as const },
      intangibleAssetsProjectsClosed: cell,
      incomeTaxLabel: cell,
      incomeTaxParagraph1: cell,
      incomeTaxParagraph2: cell,
      incomeTaxParagraph3: cell,
      incomeTaxParagraph4: cell,
      incomeTaxParagraph5: cell,
      commentLabel: cell,
      comment: cell,
      group: cell,
      section: cell,
      validForReportType: {
        type: 'part' as const,
        children: {
          k2: boolean,
          k3: boolean,
        },
      },
    },
  },
  note2: {
    type: 'part' as const,
    children: {
      active: boolean,
      number: cell,
      name: cell,
      noEmployees: cell,
      avgNumberOfEmployees: cell,
      avgNumberOfEmployeesTable: { type: 'table' as const },
      commentLabel: cell,
      comment: cell,
      group: cell,
      section: cell,
      validForReportType: {
        type: 'part' as const,
        children: {
          k2: boolean,
          k3: boolean,
        },
      },
    },
  },
  note3: noteOneTableSectionStructure,
  note4: noteOneTableSectionStructure,
  note5: noteOneTableSectionStructure,
  note45: noteInputFieldSectionStructure,
  note48: noteInputFieldSectionStructure,
  note49: noteOneTableSectionStructure,
  note52: noteOneTableSectionStructure,
  note53: noteInputFieldSectionStructure,
  note54: noteInputFieldSectionStructure,
};

export const getFoundationIncomeStatementNotes = (
  financialYears: string[],
  financialYear: string,
  period: string | null,
  reportType: AnnualReportType,
  isDigitalSubmission: boolean
): IncomeStatementNotes => {
  const noteLabel = (name: string) =>
    isDigitalSubmission ? label(name) : value(name);

  return {
    note1: {
      active: true,
      number: value(1),
      name: field('Redovisningsprinciper'),
      disclaimerK2: field(
        'Årsredovisningen är upprättad i enlighet med årsredovisningslagen och bokföringsnämndens allmänna råd (BFNAR2016:10) om årsredovisning i mindre företag.'
      ),
      disclaimerK3: field(
        'Årsredovisningen har upprättats i enlighet med årsredovisningslagen och Bokföringsnämndens allmänna råd BFNAR 2012:1 Årsredovisning och koncernredovisning (K3).'
      ),
      changeOfAccountingRegulationsLabel: field(
        'Byte av redovisningsregelverk'
      ),
      changeOfAccountingRegulations: field(
        'Årsredovisningen är upprättad i enlighet med årsredovisningslagen och Bokföringsnämndens allmänna råd (BFNAR 2016:10) om årsredovisning i mindre företag. Årsredovisningen upprättas för första gången i enlighet med Bokföringsnämndens allmänna råd (BFNAR 2016:10) om årsredovisning i mindre företag, vilket kan innebära en bristande jämförbarhet mellan räkenskapsåret och det närmast föregående räkenskapsåret. Jämförelsetalen har inte räknats om. Årsredovisningen har för det närmast föregående räkenskapsåret lämnats som bilaga.'
      ),
      tangibleFixedAssetsLabel: field('Materiella anläggningstillgångar'),
      tangibleFixedAssetsGeneral: field(
        'Materiella anläggningstillgångar redovisas till anskaffningsvärde med avdrag för ackumulerade avskrivningar och eventuella nedskrivningar. Tillgångarna skrivs av linjärt över tillgångarnas bedömda nyttjandeperiod förutom mark som inte skrivs av. Nyttjandeperioden omprövas per varje balansdag. Följande nyttjandeperioder tillämpas:'
      ),
      tangibleFixedAssets: table(
        'notes.note1.tangibleFixedAssets',
        'appliedDeprecationPeriods',
        'numberOfYears'
      )
        .addRows((rows) => {
          return [
            {
              id: '1',
              label: 'Byggnader',
            },
            {
              id: '2',
              label: 'Maskiner och andra tekniska anläggningar',
            },
            {
              id: '3',
              label: 'Inventarier, verktyg och installationer',
            },
            {
              id: '4',
              label: 'Förbättringsutgifter på annans fastighet',
            },
            {
              id: '5',
              label: 'Övriga materiella anläggningstillgångar',
            },
          ]
            .reduce(
              (rows, { id, label }) =>
                rows.addRow(id, noteLabel(label), value('')),
              rows
            )
            .build();
        })
        .newRowTemplate(value(''), value(''))
        .build(),
      receivablesLabel: inactiveOnReportTypeK2(field('Fordringar'), reportType),
      receivables: inactiveOnReportTypeK2(
        field(
          'Fordringar har upptagits till de belopp var med de beräknas inflyta.'
        ),
        reportType
      ),
      otherAssetsProvisionsAndLiabilitiesLabel: inactiveOnReportTypeK2(
        field('Övriga tillgångar, avsättningar och skulder'),
        reportType
      ),
      otherAssetsProvisionsAndLiabilities: inactiveOnReportTypeK2(
        field(
          'Övriga tillgångar, avsättningar och skulder har värderats till anskaffningsvärden om inget annat anges nedan.'
        ),
        reportType
      ),
      revenueRecognitionLabel: inactiveOnReportTypeK2(
        field('Intäktsredovisning'),
        reportType
      ),
      revenueRecognition: inactiveOnReportTypeK2(
        field(
          'Inkomsten redovisas till det verkliga värdet av vad som erhållits eller kommer att erhållas. Företaget redovisar därför inkomsten till nominellt värde (fakturabelopp) om ersättningen erhålls i likvida medel direkt vid leverans. Avdrag görs för lämnade rabatter.'
        ),
        reportType
      ),
      saleOfGoodsLabel: inactiveOnReportTypeK2(
        field('Varuförsäljning'),
        reportType
      ),
      saleOfGoods: inactiveOnReportTypeK2(
        field(
          'Försäljning av varor redovisas när företaget till köparen har överfört de väsentliga risker och förmåner som är förknippade med ägandet, normalt när kunden har varorna i sin besittning. Intäkter från försäljning av varor som inte har några betydande serviceförpliktelser redovisas vid leverans.'
        ),
        reportType
      ),
      servicesLabel: inactiveOnReportTypeK2(field('Tjänster'), reportType),
      services: inactiveOnReportTypeK2(
        field(
          'Intäkter från konsulttjänster intäktsredovisas när tjänsterna tillhandahålls.'
        ),
        reportType
      ),
      acquisitionGoodsLabel: field(
        'Anskaffningsvärde för egentillverkade varor'
      ),
      acquisitionGoods: field(
        'Företaget räknar in indirekta tillverkningskostnader i anskaffningsvärdet för egentillverkade varor. '
      ),
      changedPrinciplesLabel: field(
        'Bristande jämförbarhet vid ändrade principer'
      ),
      changedPrinciplesHint: field(
        'När en redovisningsprincip ändras måste organisationen beskriva vad den ändrade principen ger för effekt och på vilken post som påverkas och hur den ändrade effekten påverkar organisationens resultat.'
      ),
      changedPrinciples: field(
        'Från och med detta räkenskapsår [räkenskapsår] då det ger en bättre redovisning. Den ändrade principen innebär att [principen]  och ger följande resultateffekt innevarande år: [innevarande år].  Jämförelsetalen har inte räknats om.'
      ),
      rentsLabel: inactiveOnReportTypeK2(field('Hyror'), reportType),
      rents: inactiveOnReportTypeK2(
        field(
          'Hyresintäkter från uthyrning av företagets lokaler redovisas linjärt över leasingperioden.'
        ),
        reportType
      ),
      interestRoyaltyAndDividensLabel: inactiveOnReportTypeK2(
        field('Ränta, royalty och utdelning'),
        reportType
      ),
      interestRoyaltyAndDividens: inactiveOnReportTypeK2(
        field(
          'Ränta redovisas med tillämpning av effektiv räntemetoden. Royalty periodiseras i enlighet med den aktuella överenskommelsens ekonomiska innebörd. Utdelning redovisas när aktieägarens rätt att få utdelning bedöms som säker.'
        ),
        reportType
      ),
      tangibleAssetsCommentLabel: field('Kommentar'),
      tangibleAssetsComment: field(
        '[Följande avvikelser har gjorts från följande grundläggande redovisningsprinciper]'
      ),
      deviationsFromBasicAccountingPrinciplesLabel: field(
        'Avvikelser från de grundläggande redovisningsprinciperna'
      ),
      deviationsFromBasicAccountingPrinciples: field(
        '[Följande avvikelser har gjorts från följande grundläggande redovisningsprinciper]'
      ),
      intangibleAssetsGeneralLabel: field('Immateriella anläggningstillgångar'),
      intangibleAssetsGeneral: field(
        'Immateriella anläggningstillgångar redovisas till anskaffningsvärde med avdrag för ackumulerade avskrivningar och eventuella nedskrivningar. Tillgångarna skrivs av linjärt över tillgångarnas bedömda nyttjande period. Nyttjandeperioden omprövas per varje balansdag. Pågående projekt skrivs inte av utan nedskrivningsprövas årligen. Följande nyttjande perioder tillämpas:'
      ),
      serviceAndContractingAssignmentsLabel: field(
        'Tjänste- och entreprenaduppdrag'
      ),
      serviceAndContractingAssignments: field(
        'Tjänste- och entreprenaduppdrag till fast pris redovisas enligt'
      ),
      serviceAndContractingAssignmentsParagraph1: activeReference(
        field(
          'Företagets intäkter från uppdrag till fast pris redovisas enligt huvudregeln.'
        ),
        ref(account('3800:3879'))
      ),
      serviceAndContractingAssignmentsParagraph2: field(
        'Företagets intäkter från uppdrag till fast pris redovisa enligt alternativregeln. Företaget räknar in indirekta utgifter i värdet för pågående arbete för annans räkning.'
      ),
      intangibleAssetsTable: inactiveOnReportTypeK2(
        table('notes.note1.intangibleAssetsTable', 'empty', 'numberOfYears')
          .addRows((rows) => {
            return [
              {
                id: '1',
                label:
                  'Koncessioner, patent, licenser, varumärken samt liknande rättigheter',
              },
              {
                id: '2',
                label: 'Hyresrätter och liknande rättigheter',
              },
              {
                id: '3',
                label: 'Goodwill',
              },
              {
                id: '4',
                label:
                  'Bedömd avskrivningstid för goodwill tillämpas av följande skäl:',
              },
            ]
              .reduce(
                (rows, { id, label }) =>
                  rows.addRow(id, noteLabel(label), value('')),
                rows
              )
              .build();
          })
          .newRowTemplate(value(''), value(''))
          .build(),
        reportType
      ),
      intangibleAssetsProjectsClosed: inactiveOnReportTypeK2(
        field('Inga projekt var avslutade per bokslutsdatum.'),
        reportType
      ),
      incomeTaxLabel: inactiveOnReportTypeK2(field('Inkomstskatt'), reportType),
      incomeTaxParagraph1: inactiveOnReportTypeK2(
        field(
          'Det förelåg inget skattepliktigt resultat efter att ej avdragsgilla kostnader och ej skattepliktiga intäkter lagts till bolagets negativa redovisningsmässiga resultat.'
        ),
        reportType
      ),
      incomeTaxParagraph2: inactiveOnReportTypeK2(
        field(
          'Uppskjuten skatt är inkomstskatt för skattepliktigt resultat avseende framtida räkenskapsår till följd av tidigare transaktioner eller händelser. Uppskjuten skatt beräknas på temporära skillnader. En temporär skillnad finns när det redovisade värdet på en tillgång eller skuld skiljer sig från det skattemässiga värdet. Temporära skillnader beaktas ej i skillnader hänförliga till investeringar i dotterföretag, filialer, intresseföretag eller joint venture om företaget kan styra tidpunkten för återföring av de temporära skillnaderna och det inte är uppenbart att den temporära skillnaden kommer att återföras inom en överskådlig framtid. Skillnader som härrör från den första redovisningen av goodwill eller vid den första redovisningen skuld eller tillgång såvida inte den hänförliga transaktionen är ett rörelseförvärv eller påverkar skatt eller redovisat resultat utgör inte heller temporära. Uppskjuten skattefordran avseende underskottsavdrag eller andra framtida skattemässiga avdrag redovisas i den omfattning det är sannolikt att avdragen kan avräknas mot framtida skattemässiga överskott.'
        ),
        reportType
      ),
      incomeTaxParagraph3: inactiveOnReportTypeK2(
        field(
          'Företaget har inga temporära skillnader för utom de som återspeglas i obeskattade reserver.'
        ),
        reportType
      ),
      incomeTaxParagraph4: inactiveOnReportTypeK2(
        field(
          'Uppskjutna skatteskulder som är hänförlig till obeskattade reserver särredovisas inte, obeskattade reserver redovisas med bruttobeloppet i balansräkningen.'
        ),
        reportType
      ),
      incomeTaxParagraph5: inactiveOnReportTypeK2(
        field(
          'För poster som redovisas i resultaträkningen redovisas där med sammanhängande skatt i resultaträkningen. För poster som redovisas direkt mot eget kapital redovisas även skatten direkt mot eget kapital.'
        ),
        reportType
      ),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note2: {
      active: false,
      number: value(undefined),
      name: field('Medelantal anställda'),
      noEmployees: activeReference(
        field('Bolaget har ej haft några anställda under verksamhetsåret.'),
        ref(not(round(account('7000:7399'))))
      ),
      avgNumberOfEmployees: activeReference(
        field(
          'Medelantalet anställda bygger på av bolaget betalda närvarotimmar relaterade till en normal arbetstid.'
        ),
        ref(round(account('7000:7399')))
      ),
      avgNumberOfEmployeesTable: table(
        'notes.note2.avgNumberOfEmployeesTable',
        { id: 'label', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        .setActive(ref(round(account('7000:7399'))))
        .addRows((rows) => {
          rows.addRow(
            '1',
            noteLabel('Medelantal anställda'),
            value(undefined),
            value(undefined)
          );
          if (!isDigitalSubmission) {
            rows
              .addRow(
                '2',
                noteLabel('Varav kvinnor'),
                value(undefined),
                value(undefined)
              )
              .addRow(
                '3',
                noteLabel('Varav män'),
                value(undefined),
                value(undefined)
              );
          }
          return rows.build();
        })
        .newRowTemplate(value(''), value(undefined), value(undefined))
        .build(),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note3: {
      active: false,
      number: value(undefined),
      name: value('Resultat från övriga finansiella anläggningstillgångar'),
      table: table(
        'notes.note3.table',
        { id: 'label', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        .addRows((rows) =>
          rows
            .addRow(
              '1',
              noteLabel('Ränteintäkter och liknande resultatposter som avser'),
              value(undefined),
              value(undefined)
            )
            .build()
        )
        .newRowTemplate(value(''), value(undefined), value(undefined))
        .build(),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note4: {
      active: false,
      number: value(undefined),
      name: value('Övriga ränteintäkter och liknande resultatposter'),
      table: table(
        'notes.note4.table',
        { id: 'label', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        .addRows((rows) =>
          rows
            .addRow(
              '1',
              noteLabel('Ränteintäkter och liknande resultatposter som avser'),
              value(undefined),
              value(undefined)
            )
            .build()
        )
        .newRowTemplate(value(''), value(undefined), value(undefined))
        .build(),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note5: {
      active: false,
      number: value(undefined),
      name: value('Räntekostnader och liknande resultatposter'),
      table: table(
        'notes.note5.table',
        { id: 'label', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        .addRows((rows) =>
          rows
            .addRow(
              '1',
              noteLabel('Räntekostnader och liknande resultatposter som avser'),
              value(undefined),
              value(undefined)
            )
            .build()
        )
        .newRowTemplate(value(''), value(undefined), value(undefined))
        .build(),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note45: {
      active: false,
      number: value(undefined),
      name: value(
        'Ränteintäkter på finansiella anläggningstillgångar och omsättningstillgångar'
      ),
      inputField: active(field('')),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note48: {
      active: false,
      number: value(undefined),
      name: value('Annan löpande avkastning än utdelningar och ränteintäkter'),
      inputField: active(field('')),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note49: {
      active: false,
      number: value(undefined),
      name: value('Exceptionella intäkter och kostnader'),
      table: table(
        'notes.note49.table',
        { id: 'label', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        .addRows((rows) =>
          rows
            .addRow(
              '1',
              noteLabel('Avsättning för pågående rättstvist'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '2',
              noteLabel('Nedskrivning av materiella anläggningstillgångar'),
              value(undefined),
              value(undefined)
            )
            .build()
        )
        .newRowTemplate(value(''), value(undefined), value(undefined))
        .build(),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note52: {
      active: false,
      number: value(undefined),
      name: value('Utdelningar'),
      table: table(
        'notes.note52.table',
        { id: 'label', label: 'Erhållna utdelningar från:' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        .addRows((rows) =>
          rows
            .addRow(
              '1',
              noteLabel('Koncernföretag'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '2',
              noteLabel('Intresseföretag'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '3',
              noteLabel('Gemensamt styrda företag'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '4',
              noteLabel('Övriga företag som det finns ett ägarintresse i'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '5',
              noteLabel('Övriga finansiella anläggningstillgångar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '6',
              noteLabel('Finansiella omsättningstillgångar'),
              value(undefined),
              value(undefined)
            )
            .build()
        )
        .newRowTemplate(value(''), value(undefined), value(undefined))
        .build(),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note53: {
      active: false,
      number: value(undefined),
      name: value('Ränteintäkter'),
      inputField: active(
        field(
          'Ränteintäkter på finansiella anläggningstillgångar och omsättningstillgångar'
        )
      ),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note54: {
      active: false,
      number: value(undefined),
      name: value('Övriga finansiella intäkter'),
      inputField: active(
        field('Annan löpande avkastning än utdelningar och ränteintäkter')
      ),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
  };
};

import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';

export const SourceWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const TimestampText = styled(Typography)`
  font-family: 'Exo 2';
  font-size: 0.656rem;

  color: ${(props) => props.theme.palette.grey[700]};
`;

export const SourceText = styled(Typography)<{
  source: 'user' | 'cs' | 'fortnox';
}>`
  font-family: 'Exo 2';
  font-size: 0.875rem;
  ${(props) =>
    (props.source === 'cs' || props.source === 'fortnox') &&
    'color: dodgerblue; font-style: italic;'}
`;

export const CommonText = styled(Typography)`
  font-family: 'Exo 2';
  color: ${(props) => props.theme.palette.grey[700]};
  font-size: 0.875rem;
`;

import React, { memo, useCallback } from 'react';
import { useIntl } from 'react-intl';
import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';
import { Collapse, IconButton, Tooltip, Typography } from '@material-ui/core';

import { FinancialYear } from '@agoy/api-sdk-core';
import {
  AccountPlanList,
  ReferencesTypes,
  Voucher,
  VoucherSeries,
  VoucherStatusType,
} from 'types/Voucher';
import CreateVoucherView from '_shared/components/VoucherView/CreateVoucherView';
// @ts-ignore
import { ReactComponent as Expand } from 'assets/expand.svg';
import VoucherStatus from '_shared/components/VoucherView/VoucherStatus';
import VoucherChip from '_shared/components/VoucherView/VoucherChip';
import useWindowSize from '_shared/hooks/useWindowSize';

const StyledCreateVoucherView = styled(CreateVoucherView)`
  padding: 0;
  padding-top: 12px;
  border: none;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  overflow: auto;

  :not(:last-of-type) {
    border-bottom: 1px solid ${(props) => props.theme.palette.grey[300]};
  }
`;

const Header = styled('div', {
  shouldForwardProp: isPropValid,
})<{ isAdaptive: boolean }>`
  display: grid;
  align-items: center;
  grid-template-columns: ${(props) =>
    props.isAdaptive ? '2fr 2fr 1fr 1fr' : '1fr 1fr 1fr 1fr'};
  gap: 10px;
`;

const Title = styled(Typography, {
  shouldForwardProp: isPropValid,
})<{ isAdaptive: boolean }>`
  font-weight: 700;
  font-size: ${(props) => (props.isAdaptive ? '14px' : '16px')};
`;

const DateText = styled(Typography, {
  shouldForwardProp: isPropValid,
})<{ isAdaptive: boolean }>`
  font-size: ${(props) => (props.isAdaptive ? '14px' : '16px')};
`;

const TypeText = styled(Typography, {
  shouldForwardProp: isPropValid,
})<{ isAdaptive: boolean }>`
  font-size: 14px;
  display: ${(props) => (props.isAdaptive ? 'none' : 'block')};
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 12px;
  cursor: pointer;
`;

const ExpandedIcon = styled(Expand, {
  shouldForwardProp: isPropValid,
})<{ isAdaptive: boolean }>`
  height: ${(props) => (props.isAdaptive ? '15px' : '25px')};
  width: ${(props) => (props.isAdaptive ? '15px' : '25px')};
`;

const CollapsedIcon = styled(Expand, {
  shouldForwardProp: isPropValid,
})<{ isAdaptive: boolean }>`
  rotate: 180deg;
  height: ${(props) => (props.isAdaptive ? '15px' : '25px')};
  width: ${(props) => (props.isAdaptive ? '15px' : '25px')}; ;
`;
const StatusContainer = styled.div`
  display: flex;
  gap: 10px;
`;

interface VoucherListItemProps {
  clientId: string;
  financialYear: FinancialYear;
  voucher: Voucher;
  accounts: AccountPlanList[];
  seriesList: VoucherSeries[];
  isExpanded: boolean;
  integrationActive: boolean;
  nextSeriesNumber: number;
  onExpandChange: (voucherId: number) => void;
  onDeleteVoucher?: (voucher) => Promise<void>;
  onUpdateVoucher?: (voucher) => Promise<void>;
}

const VoucherListItem = ({
  clientId,
  financialYear,
  voucher,
  seriesList,
  accounts,
  isExpanded,
  integrationActive,
  nextSeriesNumber,
  onExpandChange,
  onDeleteVoucher,
  onUpdateVoucher,
}: VoucherListItemProps) => {
  const { formatMessage } = useIntl();
  const screenSize = useWindowSize();
  const isAdaptive = screenSize.width < 2100;

  const handleClickExpand = useCallback(
    (e) => {
      e.stopPropagation();
      if (voucher.id) {
        onExpandChange(voucher.id);
      }
    },
    [onExpandChange, voucher]
  );

  const handleDeleteVoucher = useCallback(async () => {
    if (onDeleteVoucher && voucher.id) {
      await onDeleteVoucher(voucher);
    }
  }, [onDeleteVoucher, voucher]);

  const handleUpdateVoucher = useCallback(async () => {
    const newVoucher = {
      ...voucher,
      preliminary: false,
      series: integrationActive ? 'I' : 'Agoy',
    };
    if (onUpdateVoucher) {
      await onUpdateVoucher(newVoucher);
    }
  }, [integrationActive, onUpdateVoucher, voucher]);

  return (
    <Container>
      <Header isAdaptive={isAdaptive}>
        <Title
          isAdaptive={isAdaptive}
        >{`Verifikation ${voucher.series} ${voucher.serNumber}`}</Title>
        <StatusContainer>
          <VoucherStatus
            status={
              voucher.preliminary
                ? VoucherStatusType.PRELIMINARY
                : VoucherStatusType.FINAL
            }
          />
          {!voucher.preliminary && (
            <VoucherChip
              voucherSeries={`${voucher.series}-${voucher.serNumber}`}
            />
          )}
        </StatusContainer>

        <DateText isAdaptive={isAdaptive}>{voucher.transDate}</DateText>

        <IconContainer>
          <IconWrapper onClick={handleClickExpand}>
            <TypeText isAdaptive={isAdaptive}>
              {isExpanded
                ? formatMessage({ id: 'voucher.minimize' })
                : formatMessage({ id: 'voucher.expand' })}
            </TypeText>
            <Tooltip
              title={
                isAdaptive
                  ? formatMessage({
                      id: `voucher.${isExpanded ? 'minimize' : 'expand'}`,
                    })
                  : ''
              }
            >
              <IconButton size="small" onClick={handleClickExpand}>
                {isExpanded ? (
                  <ExpandedIcon isAdaptive={isAdaptive} />
                ) : (
                  <CollapsedIcon isAdaptive={isAdaptive} />
                )}
              </IconButton>
            </Tooltip>
          </IconWrapper>
        </IconContainer>
      </Header>

      <Collapse in={isExpanded} timeout={200} unmountOnExit>
        <StyledCreateVoucherView
          titleText={`Verifikation ${voucher.series} ${voucher.serNumber}`}
          clientId={clientId}
          financialYear={financialYear}
          description={voucher.description || ''}
          date={voucher.transDate}
          transactions={voucher.transactions}
          documents={voucher.attachments?.map((item) => ({
            id: item.id,
            name: item.name,
            url: item.path || '',
          }))}
          series={voucher.series}
          seriesList={seriesList}
          accountsList={accounts}
          source={ReferencesTypes.GLIDER}
          voucherNumber={voucher.serNumber}
          editing={false}
          viewMode
          nextVoucherNumber={nextSeriesNumber}
          voucherStatus={
            voucher.preliminary
              ? VoucherStatusType.PRELIMINARY
              : VoucherStatusType.FINAL
          }
          fortnoxActive={integrationActive}
          onDeleteVoucher={handleDeleteVoucher}
          onSave={handleUpdateVoucher}
        />
      </Collapse>
    </Container>
  );
};

export default memo(VoucherListItem);

import { createTheme, ThemeOptions } from '@material-ui/core';
import { merge } from 'lodash/fp';
import { themeOptions } from './theme';

const printOptions: ThemeOptions = {
  typography: {
    fontFamily: '"Exo 2"',
  },
  overrides: {
    MuiTypography: {
      root: {
        fontFamily: '"Exo 2"',
        color: 'rgba(0, 0, 0)',
      },
      h1: {
        fontWeight: 200,
      },
      h2: {
        fontWeight: 200,
      },
      h3: {
        fontWeight: 200,
      },
      h4: {
        fontWeight: 200,
      },
      h5: {
        fontWeight: 200,
      },
      body1: {
        fontWeight: 200,
      },
      body2: {
        fontWeight: 200,
      },
    },
    MuiTableCell: {
      root: {
        padding: '6px',
        fontWeight: 200,
      },
    },
    MuiPaper: {
      root: {
        border: 'none',
      },
    },
    MuiTableRow: {
      root: {
        pageBreakInside: 'avoid',
      },
    },
  },
};

const printTheme = createTheme(merge(themeOptions, printOptions));

export default printTheme;

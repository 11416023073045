export const SET_CHECKLIST_DRAWER_EXPANDED =
  'checklist-drawer/SET_CHECKLIST_DRAWER_EXPANDED';

export const SET_CHECKLIST_PROGRAM = 'checklist-drawer/SET_CHECKLIST_PROGRAM';
export const SET_CHECKLIST_QUESTION_STATUS =
  'checklist-drawer/SET_CHECKLIST_QUESTION_STATUS';
export const SET_CHECKLIST_STATUSES = 'checklist-drawer/SET_CHECKLIST_STATUSES';
export const TOGGLE_INCLUDE_CHECKLIST_TO_REPORT =
  'checklist-drawer/TOGGLE_INCLUDE_CHECKLIST_TO_REPORT';

export const SET_PERSON_CHECKLIST_PROGRAM =
  'checklist-drawer/SET_PERSON_CHECKLIST_PROGRAM';
export const SET_PERSON_CHECKLIST_QUESTION_STATUS =
  'checklist-drawer/SET_PERSON_CHECKLIST_QUESTION_STATUS';
export const SET_PERSON_CHECKLIST_STATUSES =
  'checklist-drawer/SET_PERSON_CHECKLIST_STATUSES';

import { Field } from '@agoy/document';
import { AnnualReportPart, AnnualReportSection } from '../../document';
import {
  CustomNotes,
  HeadingsSection,
  NoteOneTableSection,
  NotesSection,
} from '../../common/types/noteSections';

interface IndividualCompanyNote1 extends AnnualReportSection, NotesSection {
  disclaimerK1: Field;
  disclaimerK2: Field;
  disclaimerK3: Field;
  intangibleAssetsGeneralLabel: Field;
  intangibleAssetsGeneral: Field;
  tangibleFixedAssetsLabel: Field;
  tangibleFixedAssetsGeneral: Field;
  commentLabel: Field;
  comment: Field;
}
export interface IndividualCompanyNotes extends AnnualReportPart {
  headings: HeadingsSection;
  note1: IndividualCompanyNote1;
  note6: NoteOneTableSection;
  note7: NoteOneTableSection;
  note8: NoteOneTableSection;
  note9: NoteOneTableSection;
  note10: NoteOneTableSection;
  note11: NoteOneTableSection;
  note12: NoteOneTableSection;
  note13: NoteOneTableSection;
  note14: NoteOneTableSection;
  note15: NoteOneTableSection;
  note56: NoteOneTableSection;
  custom: CustomNotes;
}

const cell = { type: 'field' as const };
const boolean = { type: 'boolean' as const };

export const individualCompanyNotes = {
  type: 'part' as const,
  children: {
    active: boolean,
    headings: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        notesIncomeStatementTitle: cell,
        notesBalanceSheetTitle: cell,
        notesOtherTitle: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note1: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        disclaimerK1: cell,
        disclaimerK2: cell,
        disclaimerK3: cell,
        intangibleAssetsGeneralLabel: cell,
        intangibleAssetsGeneral: cell,
        tangibleFixedAssetsLabel: cell,
        tangibleFixedAssetsGeneral: cell,
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note56: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note6: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note7: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note8: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note9: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note10: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note11: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note12: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note13: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note14: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note15: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    custom: {
      type: 'array' as const,
      children: {
        type: 'part' as const,
        children: {
          active: boolean,
          number: cell,
          name: cell,
          group: cell,
          section: cell,
          validForReportType: {
            type: 'part' as const,
            children: {
              k2: boolean,
              k3: boolean,
            },
          },
          commentTitle: cell,
          typeOne: cell,
          typeTwo: { type: 'table' as const },
          typeThree: { type: 'table' as const },
        },
      },
    },
  },
};

/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { ClientCompanyType } from '_clients/types/types';
import styled from '@emotion/styled';
import CardTabPanel from '_organization/components/organisms/CardTabPanel';
import SearchForCompanyToConnect from './SearchForCompanyToConnect';
import ConnectCompanyToClient from './ConnectCompanyToClient';
import { ConnectionTabProps } from './ConnectionsTabWrapper';

const StyledTabPanel = styled(CardTabPanel)`
  background-color: ${(props) => props.theme.palette.grey[100]};
  min-height: 500px;
  padding-left: ${(props) => props.theme.spacing(2)}px;
`;

const CreateConnectionToCompanyView = ({
  client,
  path,
}: ConnectionTabProps): JSX.Element => {
  const [activeStep, setActiveStep] = useState(0);
  const [companyToConnect, setCompanyToConnect] = useState<
    ClientCompanyType | undefined
  >();

  const setCompanyToConnectToPerson = (company) => {
    setCompanyToConnect(company);
    setActiveStep(1);
  };

  return (
    <>
      <StyledTabPanel name="search" value={activeStep} index={0}>
        <SearchForCompanyToConnect
          client={client}
          path={path}
          connectCompany={setCompanyToConnectToPerson}
        />
      </StyledTabPanel>
      <StyledTabPanel name="shares" value={activeStep} index={1}>
        {companyToConnect && (
          <ConnectCompanyToClient
            client={client}
            path={path}
            companyToConnect={companyToConnect}
          />
        )}
      </StyledTabPanel>
    </>
  );
};

export default CreateConnectionToCompanyView;

import React from 'react';
import { useIntl } from 'react-intl';
import { ccyFormat } from '@agoy/common';
import { Typography } from '@material-ui/core';

interface propTypes {
  sum: number;
  className?: string;
}

const SummaryRow = (props: propTypes) => {
  const { sum, className } = props;
  const intl = useIntl();

  return (
    <div className={className} css={{ gridColumn: 4 }}>
      <Typography variant="body1" color="textSecondary">
        {intl.formatMessage({ id: 'total' })}
      </Typography>
      <Typography variant="body1">
        {intl.formatMessage({ id: 'curr' })}{' '}
        {Number.isNaN(sum) ? '' : ccyFormat(sum)}
      </Typography>
    </div>
  );
};

export default SummaryRow;

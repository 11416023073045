import React, { memo } from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import { Cell as CellType } from '@agoy/document';
import {
  NumberFormatType,
  StandardNumberFormatType,
  ccyParse,
  parsePercentage,
  buildNumberFormatter,
} from '@agoy/common';
import { FormattingContext } from '@agoy/formatting';
import { Info } from '@material-ui/icons';
import styled from '@emotion/styled';
import NumberField from './Field/NumberField';
import StringField from './Field/StringField';
import NumberCell from './NumberCell';

const TooltipContainer = styled.div`
  white-space: pre-wrap;
`;

const AccountsTooltip = ({ text }: { text: string }) => (
  <Tooltip
    title={<TooltipContainer>{text}</TooltipContainer>}
    placement="bottom"
  >
    <IconButton size="small">
      <Info color="primary" />
    </IconButton>
  </Tooltip>
);

interface NonReferenceCellProps {
  className?: string;
  id: string;
  cell: CellType;
  editing?: boolean;
  numberFormatType?: NumberFormatType;
  active?: boolean;
  isLocked?: boolean;
  placeholder?: string;
  onBlur?: (newValue: string) => void;
  warning?: string;
  isField?: boolean;
  accountRangeTooltip?: string;
}

const NonReferenceCell = memo(
  ({
    className,
    id,
    cell,
    editing,
    numberFormatType = StandardNumberFormatType,
    active,
    isLocked = false,
    placeholder,
    onBlur,
    warning,
    isField,
    accountRangeTooltip,
  }: NonReferenceCellProps) => {
    const { type, precision } = numberFormatType;
    const formattingContext = React.useContext(FormattingContext);

    const numberFormat = buildNumberFormatter(type);
    const numberParser = type === 'percentage' ? parsePercentage : ccyParse;
    const decimals = precision;

    if (cell.type === 'label') {
      return (
        <div>
          <span className={className}>{cell.value}</span>
          {accountRangeTooltip != null && accountRangeTooltip?.length > 0 && (
            <AccountsTooltip text={accountRangeTooltip} />
          )}
        </div>
      );
    }
    if (cell.type === 'number') {
      if (editing) {
        return (
          <NumberField
            className={className}
            id={id}
            value={active ? cell.value : undefined}
            formatter={numberFormat}
            format={numberFormatType}
            parser={numberParser}
            displayDecimals={decimals}
            InputProps={{ disabled: !active || isLocked }}
            placeholder={placeholder}
            warning={warning}
            isField={isField}
          />
        );
      }

      const displayValue = formattingContext.formatNumber(cell.value, type);
      return warning ? (
        <Tooltip title={warning} placement="bottom-end">
          <NumberCell className={className} warning={warning}>
            {active && numberFormat(displayValue, decimals)}
          </NumberCell>
        </Tooltip>
      ) : (
        <NumberCell className={className}>
          {!active || cell.value === undefined
            ? ''
            : numberFormat(displayValue, decimals)}
        </NumberCell>
      );
    }

    if (cell.type === 'string') {
      if (editing) {
        return (
          <StringField
            className={className}
            id={id}
            value={active ? cell.value : ''}
            disabled={!active || isLocked}
            placeholder={placeholder}
            onBlur={onBlur}
            isField={isField}
          />
        );
      }

      return (
        <span className={className}>
          {active ? cell.value : ''}
          {accountRangeTooltip != null && accountRangeTooltip?.length > 0 && (
            <AccountsTooltip text={accountRangeTooltip} />
          )}
        </span>
      );
    }
    return null;
  }
);

export default NonReferenceCell;

import { AgoyTable, Field } from '@agoy/document';
import { AnnualReportPart, AnnualReportSection } from '../../document';

// Verksamheten
export interface NonProfitOrgActivities extends AnnualReportSection {
  // Allmänt om verksamheten
  boardText: Field;
  boardTextWithCeo: Field;
  boardWithoutCeo: Field;
  liquidatorText: Field;
  currencyText: Field;
  heading: Field;
  activityLabel: Field;
  generallyLabel: Field;
  generally: Field;
  accountant: Field;

  // Främjande av ändamålet
  purposePromotionLabel: Field;
  purposePromotion: Field;

  // Väsentliga händelser under räkenskapsåret
  eventsLabel: Field;
  events: Field;

  // Other
  otherLabel: Field;
  other: Field;
}

// Verksamheten K3
interface Business extends AnnualReportSection {
  boardText: Field;
  businessLabel: Field;
  currencyText: Field;
  heading: Field;

  // Viktiga förhållanden och väsentliga händelser
  conditionsAndEventsLabel: Field;
  conditionsAndEvents: Field;

  // Verksamhetens art och inriktning
  businessNatureLabel: Field;
  businessNature: Field;

  // Viktiga förändringar i verksamheten
  businessChangesLabel: Field;
  businessChanges: Field;

  // Viktiga externa faktorer som påverkat företagets ställning och resultat
  externalFactorsLabel: Field;
  externalFactors: Field;

  // Speciella omständigheter som i särskild grad berör företaget
  specialCircumstancesLabel: Field;
  specialCircumstances: Field;

  // Uppgift om ägare som har mer än tio procent av antalet andelar eller röster i företaget
  mainBusinessOwnersLabel: Field;
  mainBusinessOwners: Field;

  // Komplementär i kommanditbolag
  limitedPartnershipsLabel: Field;
  limitedPartnerships: Field;

  // Väsentliga förändringar i ägarstrukturen
  ownershipChangesLabel: Field;
  ownershipChanges: Field;

  // Företaget tillämpar inte längre fortlevnadsprincipen
  noSurvivalLabel: Field;
  noSurvival: Field;

  // Övriga viktiga förhållanden och väsentliga händelser
  importantConditionsAndEventsLabel: Field;
  importantConditionsAndEvents: Field;

  // Kontrollbalansräkning
  controlBalanceSheetLabel: Field;
  controlBalanceSheet: Field;
}

// Flerårsöversikt
export interface NonProfitOrgMultiYearOverview extends AnnualReportSection {
  table: AgoyTable;
  multiYearLabel: Field;
  multiYearTextLabel: Field;
  multiYearText: Field;
}

// Förvaltningsberättelse
export interface NonProfitOrgManagementReport extends AnnualReportPart {
  activities: NonProfitOrgActivities;

  // Verksamheten K3
  business: Business;

  multiYearOverview: NonProfitOrgMultiYearOverview;
  hidden: {
    activityText: Field;
    city: Field;
  };
}

const cell = { type: 'field' as const };
const boolean = { type: 'boolean' as const };

export const nonProfitOrgManagementReport = {
  type: 'part' as const,
  children: {
    active: boolean,
    activities: {
      type: 'part' as const,
      children: {
        boardText: cell,
        boardTextWithCeo: cell,
        boardWithoutCeo: cell,
        liquidatorText: cell,
        currencyText: cell,
        heading: cell,
        active: boolean,
        activityLabel: cell,
        generallyLabel: cell,
        generally: cell,
        accountant: cell,
        purposePromotionLabel: cell,
        purposePromotion: cell,
        eventsLabel: cell,
        events: cell,
        otherLabel: cell,
        other: cell,
      },
    },
    business: {
      type: 'part' as const,
      children: {
        boardText: cell,
        businessLabel: cell,
        currencyText: cell,
        heading: cell,
        active: boolean,
        conditionsAndEventsLabel: cell,
        conditionsAndEvents: cell,
        businessNatureLabel: cell,
        businessNature: cell,
        businessChangesLabel: cell,
        businessChanges: cell,
        externalFactorsLabel: cell,
        externalFactors: cell,
        specialCircumstancesLabel: cell,
        specialCircumstances: cell,
        mainBusinessOwnersLabel: cell,
        mainBusinessOwners: cell,
        limitedPartnershipsLabel: cell,
        limitedPartnerships: cell,
        ownershipChangesLabel: cell,
        ownershipChanges: cell,
        noSurvivalLabel: cell,
        noSurvival: cell,
        importantConditionsAndEventsLabel: cell,
        importantConditionsAndEvents: cell,
        controlBalanceSheetLabel: cell,
        controlBalanceSheet: cell,
      },
    },
    multiYearOverview: {
      type: 'part' as const,
      children: {
        active: boolean,
        table: { type: 'table' as const },
        multiYearLabel: cell,
        multiYearTextLabel: cell,
        multiYearText: cell,
      },
    },
    hidden: {
      type: 'part' as const,
      children: {
        activityText: cell,
        city: cell,
      },
    },
  },
};

import React, { useState, useContext } from 'react';
import ResetIcon from '@material-ui/icons/Restore';
import { IconButton, Tooltip } from '@material-ui/core';
import { useIntl } from 'react-intl';
import styled from '@emotion/styled';
import FinancialReportViewServiceContext from '_financial-report/service/FinancialReportViewServiceContext';
import ResetContentDialog from '_shared/components/ResetContent/ResetContentDialog';

const StyledIcon = styled(IconButton)`
  ${(props) =>
    props.color === 'primary'
      ? `color: ${props.theme.palette.success.main}`
      : ''};
`;
interface ResetContentButtonProps {
  className?: string;
  id: string;
  what: string;
  onReset?: () => void;
}

const ResetContentButton = ({
  className,
  id,
  what,
  onReset,
}: ResetContentButtonProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const service = useContext(FinancialReportViewServiceContext);
  const [open, setOpen] = useState(false);

  const onConfirm = () => {
    if (!onReset) {
      service.resetContent(id);
    } else {
      onReset();
    }
    setOpen(false);
  };

  return (
    <>
      <Tooltip title={formatMessage({ id: 'financialreport.reset.title' })}>
        <StyledIcon
          className={className}
          onClick={() => setOpen(true)}
          color={open ? 'primary' : undefined}
        >
          <ResetIcon />
        </StyledIcon>
      </Tooltip>
      <ResetContentDialog
        what={formatMessage({ id: what }) || ''}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        onConfirm={onConfirm}
      />
    </>
  );
};

export default ResetContentButton;

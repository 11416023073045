import React from 'react';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';
import { format } from 'date-fns';

import ResetIcon from '@material-ui/icons/Restore';
import WarningIcon from '@material-ui/icons/Warning';

import { IconButton, Tooltip } from '@material-ui/core';

import {
  ClientInformationSource,
  SourceInfo,
  SUPPORTED_SOURCE_TYPES,
} from './types';

const SourceLine = styled.div`
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spacing(2)}px;
  min-height: 43px;
`;

const Warning = styled.div`
  color: ${(props) => props.theme.palette.warning.main};
`;

const textColor = (sourceType: string | undefined) => {
  switch (sourceType) {
    case 'cs':
      return 'dodgerblue';
    case 'user':
      return 'orange';
    default:
      return 'inherit';
  }
};

const SourceText = styled('span', {
  shouldForwardProp: (prop) => prop !== 'type',
})<{ type: string }>`
  color: ${(props) => textColor(props.type)};
  margin: ${(props) => props.theme.spacing(0.5)}px 0;
`;

type SourceProps<T> = {
  className?: string;
  editing: boolean;
  sourceInfo: SourceInfo<T>;
  onReset: (
    id: string,
    clientInformationFieldId: string | undefined,
    clientInformationSource: ClientInformationSource,
    clientInformationValue: T
  ) => void;
  renderDifference: (sourceInfo: SourceInfo<T>) => React.ReactNode;
  isChanged: (source: SourceInfo<T>) => boolean;
};

function Source<T>(props: SourceProps<T>) {
  const { formatMessage } = useIntl();
  const { sourceInfo, className, editing, renderDifference, isChanged } = props;
  const {
    id,
    source,
    clientInformationFieldId,
    clientInformationSource,
    clientInformationValue,
  } = sourceInfo;

  if (!source || !SUPPORTED_SOURCE_TYPES.includes(source.type)) {
    return null;
  }

  const changed = isChanged(sourceInfo);

  return (
    <div className={className}>
      <SourceLine>
        <SourceText type={source.type}>
          {formatMessage(
            { id: `annualReport.source.${source.type}` },
            {
              name: formatMessage({ id: `annualReport.source.${id}` }),
              date: format(source.updatedAt, 'yyyy-MM-dd'),
            }
          )}
        </SourceText>
        {editing && changed && (
          <Tooltip title={formatMessage({ id: 'annualReport.reset.title' })}>
            <IconButton
              onClick={() =>
                props.onReset(
                  id,
                  clientInformationFieldId,
                  clientInformationSource,
                  clientInformationValue
                )
              }
            >
              <ResetIcon />
            </IconButton>
          </Tooltip>
        )}
      </SourceLine>
      {changed && (
        <SourceLine>
          <Warning>
            <WarningIcon />
          </Warning>
          <span>
            {formatMessage({
              id: 'annualReport.source.accordingTo.clientInformation',
            })}
            {renderDifference(sourceInfo)}
          </span>
        </SourceLine>
      )}
    </div>
  );
}

export default Source;

import React from 'react';
import { NoteOneTableSection } from '@agoy/annual-report-document';
import { numberValue, stringValue } from '@agoy/document';
import Comment from '../../UI/Comment';
import Header from '../../UI/Header';
import { Note } from '../../UI/Note';
import NoteTable from '../../UI/NoteTable';

type Note23Props = {
  title: string;
  hasPreviousYear?: boolean;
} & NoteOneTableSection;

const Note23 = ({
  active,
  number,
  table,
  comment,
  title,
  hasPreviousYear = false,
}: Note23Props) => {
  if (!active) return null;

  return (
    <Note id="note23">
      <Header noteId="note23" noteNumber={numberValue(number)} title={title} />

      <NoteTable
        hasPreviousYear={hasPreviousYear}
        table={table}
        tableId="notes.note23.table"
      />

      {/* Comment */}
      <Comment field={comment} value={stringValue(comment) || ''} />
    </Note>
  );
};

export default Note23;

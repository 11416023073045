import React from 'react';
import styled from '@emotion/styled';
import { Button, Paper, Typography } from '@material-ui/core';
import config from '_shared/services/config';
import { useIntl } from 'react-intl';
import { VerifiedUser } from '@material-ui/icons';

const Container = styled(Paper)`
  padding: ${(props) => props.theme.spacing(2)}px;
  width: 100%;
  min-width: 350px;
  display: flex;
  flex-direction: row;
`;

const PersonDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const AuthorisationDetails = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const BadgeAndStatus = styled.div`
  display: flex;
`;

const AuthorisationStatus = styled(Typography)`
  padding-top: ${(props) => props.theme.spacing(1)}px;
`;

const AccessBadge = styled.div<{ authorised?: boolean }>`
  display: flex;
  padding-top: ${(props) => props.theme.spacing(1)}px;
  color: ${(props) =>
    props.authorised
      ? props.theme.palette.success.main
      : props.theme.palette.error.main};
`;

type Props = {
  name: string;
  email?: string;
  Avatar: JSX.Element;
  isAuthorised: boolean;
  onAuthorisationToggle: () => void;
};

const AccessControl = (props: Props): JSX.Element => {
  const { name, email, Avatar, isAuthorised, onAuthorisationToggle } = props;
  const isFortnoxWhiteLabel = config.whiteLabelUI === 'fortnox';

  const { formatMessage } = useIntl();
  const textKey = 'dashboard.customers.edit';
  const labelId = isAuthorised ? `${textKey}.access` : `${textKey}.noAccess`;
  const buttonTextId = isAuthorised
    ? `${textKey}.removeAccess`
    : `${textKey}.grantAccess`;

  return (
    <Container elevation={2}>
      {Avatar}
      <PersonDetailsContainer>
        <Typography>{name}</Typography>
        <Typography>{email}</Typography>
        <AuthorisationDetails>
          <BadgeAndStatus>
            <AccessBadge authorised={isAuthorised}>
              <VerifiedUser fontSize="small" />
            </AccessBadge>
            <AuthorisationStatus variant="body2">
              {formatMessage({ id: labelId })}
            </AuthorisationStatus>
          </BadgeAndStatus>
          {!config.whiteLabel && !isFortnoxWhiteLabel && (
            <Button
              color="primary"
              size="small"
              onClick={onAuthorisationToggle}
            >
              {formatMessage({ id: buttonTextId })}
            </Button>
          )}
        </AuthorisationDetails>
      </PersonDetailsContainer>
    </Container>
  );
};

export default AccessControl;

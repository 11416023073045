import { value, ref } from '@agoy/document';
import { NoteOneTableSection, IxbrlCell } from '../../../../common';
import {
  sumAccountsIBs,
  sumPositiveTransactions,
  sumNegativeTransactions,
  sumNotesRowsById,
} from '../../../../common/utils/accountSumsUtil';
import { year, previousYear } from '../../../../common/utils/date-util';
import {
  field,
  ixbrlCell,
  ixbrlField,
  range,
  table,
} from '../../../../common/utils/util';
import { NoteAccountRanges } from '../types';

const MONETARY_ITEM_TYPE = 'monetaryItemType';

export const note15Accounts: NoteAccountRanges[] = [
  {
    rowId: '1',
    ranges: [
      [1180, 1189],
      [1280, 1289],
    ],
    cellLabel: 'Ingående anskaffningsvärden',
    action: 'sumAccountsIBs',
  },
  {
    rowId: '2',
    ranges: [
      [1180, 1189],
      [1280, 1289],
    ],
    cellLabel: 'Rörelseförvärv',
    action: 'sumPositiveTransactions',
  },
  {
    rowId: '3',
    ranges: [
      [1180, 1189],
      [1280, 1289],
    ],
    cellLabel: 'Försäljningar/utrangeringar',
    action: 'sumNegativeTransactions',
  },
];

export const getNote15 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null,
  documentTypeVersion = '1'
): NoteOneTableSection => {
  return {
    active: false,
    number: value(undefined),
    name: value(
      'Pågående nyanläggningar och förskott avseende materiella anläggningstillgångar'
    ),
    table: table<IxbrlCell>(
      'notes.note15.table',
      { id: 'label', label: '' },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      .addRows((rows) =>
        rows
          // 'ingående' = previous period outgoing balance
          .addRow(
            '1',
            noteLabel('Ingående anskaffningsvärden'),
            ixbrlCell(ref(sumAccountsIBs('1180:1189+1280:1289', false)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarAnskaffningsvarden',
              contextRef: 'balans1',
              saldo: 'debit',
            }),
            ixbrlCell(
              ref(sumAccountsIBs('1180:1189+1280:1289', false, 'previousYear')),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarAnskaffningsvarden',
                contextRef: 'balans2',
                saldo: 'debit',
              }
            )
          )
          .addRow(
            'expensesIncurred',
            noteLabel('Nedlagda utgifter'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringAnskaffningsvardenNedlagdaUtgifter',
              contextRef: 'period0',
              standardRubrik:
                'Ökning av anskaffningsvärden i samband med produktion av pågående nyanläggningar avseende materiella anläggningstillgångar',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringAnskaffningsvardenNedlagdaUtgifter',
              contextRef: 'period1',
              standardRubrik:
                'Ökning av anskaffningsvärden i samband med produktion av pågående nyanläggningar avseende materiella anläggningstillgångar',
            })
          )
          .addRow(
            'advancesPaid',
            noteLabel('Utbetalda förskott'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringAnskaffningsvardenUtbetaladeForskott',
              contextRef: 'period0',
              standardRubrik:
                'Ökning av anskaffningsvärden i samband med periodens utbetalda förskott avseende materiella anläggningstillgångar',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringAnskaffningsvardenUtbetaladeForskott',
              contextRef: 'period1',
              standardRubrik:
                'Ökning av anskaffningsvärden i samband med periodens utbetalda förskott avseende materiella anläggningstillgångar',
            })
          )
          .addRow(
            '2',
            noteLabel('Rörelseförvärv'),
            ixbrlCell(ref(sumPositiveTransactions('1180:1189+1280:1289')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringAnskaffningsvardenRorelseforvarv',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden för pågående nyanläggningar och förskott avseende materiella anläggningstillgångar genom rörelseförvärv',
            }),
            ixbrlCell(
              ref(
                sumPositiveTransactions('1180:1189+1280:1289', 'previousYear')
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringAnskaffningsvardenRorelseforvarv',
                contextRef: 'period1',
                standardRubrik:
                  'Ökning (minskning) av anskaffningsvärden för pågående nyanläggningar och förskott avseende materiella anläggningstillgångar genom rörelseförvärv',
              }
            )
          )
          .addRow(
            'anskaffningsvardenFusion',
            noteLabel('Fusion'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringAnskaffningsvardenFusion',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden för pågående nyanläggningar och förskott avseende materiella anläggningstillgångar genom fusion',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringAnskaffningsvardenFusion',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden för pågående nyanläggningar och förskott avseende materiella anläggningstillgångar genom fusion',
            })
          )
          .addRow(
            '3',
            noteLabel('Försäljningar/utrangeringar'),
            ixbrlCell(ref(sumNegativeTransactions('1180:1189+1280:1289')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringAnskaffningsvardenForsaljningarUtrangeringar',
              contextRef: 'period0',
              standardRubrik:
                'Minskning av anskaffningsvärden i samband med försäljningar eller utrangeringar av pågående nyanläggningar avseende materiella anläggningstillgångar',
              negateValue: true,
            }),
            ixbrlCell(
              ref(
                sumNegativeTransactions('1180:1189+1280:1289', 'previousYear')
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringAnskaffningsvardenForsaljningarUtrangeringar',
                contextRef: 'period1',
                standardRubrik:
                  'Minskning av anskaffningsvärden i samband med försäljningar eller utrangeringar av pågående nyanläggningar avseende materiella anläggningstillgångar',
                negateValue: true,
              }
            )
          )
          .addRow(
            'refundedAdvances',
            noteLabel('Återbetalda förskott'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringAnskaffningsvardenAterbetaladeForskott',
              contextRef: 'period0',
              standardRubrik:
                'Minskning av anskaffningsvärden i samband med periodens återbetalda förskott avseende materiella anläggningstillgångar',
              negateValue: true,
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringAnskaffningsvardenAterbetaladeForskott',
              contextRef: 'period1',
              standardRubrik:
                'Minskning av anskaffningsvärden i samband med periodens återbetalda förskott avseende materiella anläggningstillgångar',
              negateValue: true,
            })
          )
          .addRow(
            'anskaffningsvardenWrittenAdvances',
            noteLabel('Bortskrivna förskott'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringAnskaffningsvardenBortskrivnaForskott',
              contextRef: 'period0',
              standardRubrik:
                'Minskning av anskaffningsvärden i samband med bortskrivning av förskott avseende materiella anläggningstillgångar',
              negateValue: true,
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringAnskaffningsvardenBortskrivnaForskott',
              contextRef: 'period1',
              standardRubrik:
                'Minskning av anskaffningsvärden i samband med bortskrivning av förskott avseende materiella anläggningstillgångar',
              negateValue: true,
            })
          )
          .addRow(
            '4',
            noteLabel('Omklassificeringar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringAnskaffningsvardenOmklassificeringar',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden i samband med omklassificeringar av pågående nyanläggningar och förskott avseende materiella anläggningstillgångar',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringAnskaffningsvardenOmklassificeringar',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden i samband med omklassificeringar av pågående nyanläggningar och förskott avseende materiella anläggningstillgångar',
            })
          )
          .addRow(
            'anskaffningsvardenConversionDifferences',
            noteLabel('Omräkningsdifferenser'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringAnskaffningsvardenOmrakningsdifferenser',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden i samband med omräkningsdifferenser av pågående nyanläggningar och förskott avseende materiella anläggningstillgångar',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringAnskaffningsvardenOmrakningsdifferenser',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden i samband med omräkningsdifferenser av pågående nyanläggningar och förskott avseende materiella anläggningstillgångar',
            })
          )
          .addRow(
            '5',
            noteLabel('Utgående anskaffningsvärden'),
            ixbrlCell(
              sumNotesRowsById(
                15,
                [
                  ...range(1, 5),
                  'expensesIncurred',
                  'advancesPaid',
                  'anskaffningsvardenFusion',
                  'refundedAdvances',
                  'anskaffningsvardenWrittenAdvances',
                  'anskaffningsvardenConversionDifferences',
                ],
                'year'
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarAnskaffningsvarden',
                contextRef: 'balans0',
                saldo: 'debit',
              }
            ),
            ixbrlCell(
              sumNotesRowsById(
                15,
                [
                  ...range(1, 5),
                  'expensesIncurred',
                  'advancesPaid',
                  'anskaffningsvardenFusion',
                  'refundedAdvances',
                  'anskaffningsvardenWrittenAdvances',
                  'anskaffningsvardenConversionDifferences',
                ],
                'previousYear'
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarAnskaffningsvarden',
                contextRef: 'balans1',
                saldo: 'debit',
              }
            )
          )
          .addRow(
            '6',
            noteLabel('Ingående nedskrivningar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarNedskrivningar',
              contextRef: 'balans1',
              saldo: 'credit',
              negateValue: true,
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarNedskrivningar',
              contextRef: 'balans2',
              saldo: 'credit',
              negateValue: true,
            })
          )
          .addRow(
            'nedskrivningarFusion',
            noteLabel('Fusion'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringNedskrivningarFusion',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av nedskrivningar för pågående nyanläggningar och förskott avseende materiella anläggningstillgångar genom fusion',
              negateValue: true,
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringNedskrivningarFusion',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av nedskrivningar för pågående nyanläggningar och förskott avseende materiella anläggningstillgångar genom fusion',
              negateValue: true,
            })
          )
          .addRow(
            '7',
            noteLabel('Försäljningar/utrangeringar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringNedskrivningarAterfordaNedskrivningarForsaljningarRespUtrangeringar',
              contextRef: 'period0',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringNedskrivningarAterfordaNedskrivningarForsaljningarRespUtrangeringar',
              contextRef: 'period1',
            })
          )
          .addRow(
            '8',
            noteLabel('Återförda nedskrivningar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringNedskrivningarAterfordaNedskrivningar',
              contextRef: 'period0',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringNedskrivningarAterfordaNedskrivningar',
              contextRef: 'period1',
            })
          )
          .addRow(
            'nedskrivningarWrittenAdvances',
            noteLabel('Bortskrivna förskott'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringNedskrivningarBortskrivnaForskott',
              contextRef: 'period0',
              standardRubrik:
                'Minskning av nedskrivningar i samband med bortskrivning av förskott avseende materiella anläggningstillgångar',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringNedskrivningarBortskrivnaForskott',
              contextRef: 'period1',
              standardRubrik:
                'Minskning av nedskrivningar i samband med bortskrivning av förskott avseende materiella anläggningstillgångar',
            })
          )
          .addRow(
            '9',
            noteLabel('Omklassificeringar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringNedskrivningarOmklassificeringar',
              contextRef: 'period0',
              standardRubrik:
                'Minskning (ökning) av nedskrivningar i samband med omklassificeringar av pågående nyanläggningar och förskott avseende materiella anläggningstillgångar',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringNedskrivningarOmklassificeringar',
              contextRef: 'period1',
              standardRubrik:
                'Minskning (ökning) av nedskrivningar i samband med omklassificeringar av pågående nyanläggningar och förskott avseende materiella anläggningstillgångar',
            })
          )
          .addRow(
            '10',
            noteLabel('Årets nedskrivningar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringNedskrivningarAretsNedskrivningar',
              contextRef: 'period0',
              negateValue: true,
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringNedskrivningarAretsNedskrivningar',
              contextRef: 'period1',
              negateValue: true,
            })
          )
          .addRow(
            'nedskrivningarConversionDifferences',
            noteLabel('Omräkningsdifferenser'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringNedskrivningarOmrakningsdifferenser',
              contextRef: 'period0',
              standardRubrik:
                'Minskning (ökning) av nedskrivningar i samband med omräkningsdifferenser av pågående nyanläggningar och förskott avseende materiella anläggningstillgångar',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringNedskrivningarOmrakningsdifferenser',
              contextRef: 'period1',
              standardRubrik:
                'Minskning (ökning) av nedskrivningar i samband med omräkningsdifferenser av pågående nyanläggningar och förskott avseende materiella anläggningstillgångar',
            })
          )
          .addRow(
            '11',
            noteLabel('Utgående nedskrivningar'),
            ixbrlCell(
              sumNotesRowsById(
                15,
                [
                  ...range(6, 11),
                  'nedskrivningarFusion',
                  'nedskrivningarWrittenAdvances',
                  'nedskrivningarConversionDifferences',
                ],
                'year'
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarNedskrivningar',
                contextRef: 'balans0',
                saldo: 'credit',
                negateValue: true,
              }
            ),
            ixbrlCell(
              sumNotesRowsById(
                15,
                [
                  ...range(6, 11),
                  'nedskrivningarFusion',
                  'nedskrivningarWrittenAdvances',
                  'nedskrivningarConversionDifferences',
                ],
                'previousYear'
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarNedskrivningar',
                contextRef: 'balans1',
                saldo: 'credit',
                negateValue: true,
              }
            )
          )
          .addRow(
            '12',
            noteLabel('Redovisat värde'),
            ixbrlCell(sumNotesRowsById(15, [5, 11], 'year'), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangar',
              contextRef: 'balans0',
              saldo: 'debit',
            }),
            ixbrlCell(sumNotesRowsById(15, [5, 11], 'previousYear'), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangar',
              contextRef: 'balans1',
              saldo: 'debit',
            })
          )
          .build()
      )
      .newRowTemplate(value(''), value(undefined), value(undefined))
      .build(),
    commentLabel: field('Kommentar'),
    comment: ixbrlField(field(''), {
      type: 'stringItemType',
      name: 'se-gen-base:NotPagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarKommentar',
      contextRef: 'period0',
    }),
    group: value(undefined),
    section: value(undefined),
    validForReportType: {
      k2: true,
      k3: documentTypeVersion != '1' ? false : true,
    },
  };
};

import { actionPopulatePersonsStatuses } from '../actions';
import { PersonsState } from '../reducer';

export const updatePersonsProgramStatusReducer = (
  state: PersonsState,
  action: ReturnType<typeof actionPopulatePersonsStatuses>
): PersonsState => {
  const { personsStatuses } = action;
  const newState = { ...state };
  Object.keys(action.personsStatuses).forEach((personId) => {
    newState[personId].programStatus = personsStatuses[personId];
  });
  return newState;
};

export default updatePersonsProgramStatusReducer;

import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import styled from '@emotion/styled';

import { AgoyTableColumn, AgoyTableRow, Cell } from '@agoy/document';
import { StockInventoryTable } from '@agoy/api-sdk-core';
import ExpandableTable from '_shared/components/Table/ExpandableTable';
import Typography from '_shared/components/Typography/Typography';
import EditingIconButton from '_shared/components/Buttons/EditingIconButton';
import ResetContentButton from '_shared/components/ResetContent/ResetContentButton';
import PrintButton from '_shared/components/Buttons/PrintButton';
import {
  defaultColumnKeys,
  defaultColumnTypes,
  TableService,
  emptyTableService,
} from './types';
import { calculateTotalCellValue } from './utils';

const FooterRow = styled.div`
  font-weight: 600;
`;

const TableWrapper = styled.div`
  min-width: fit-content;
  width: 100%;

  & > * {
    min-width: 100%;
    width: 100%;
  }
`;

const TableHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
`;

type InventoryStockTableProps = {
  tableData: StockInventoryTable;
  tableService?: TableService;
  isPrint?: boolean;
  onOpenPreview?: () => void;
  onReset?: () => void;
};

const InventoryStockTable = ({
  tableData,
  tableService = emptyTableService,
  isPrint = false,
  onOpenPreview = () => {},
  onReset = () => {},
}: InventoryStockTableProps) => {
  const { formatMessage } = useIntl();

  const [isEditing, setEditing] = useState(false);

  const mapColumns = useCallback(
    (rawData: StockInventoryTable): AgoyTableColumn[] => {
      return rawData.headers.map(({ id, label }) => {
        return {
          id,
          label: label ?? undefined,
        };
      });
    },
    []
  );

  const mapRows = useCallback(
    (rawData: StockInventoryTable): AgoyTableRow[] => {
      const recordObjectToCells = (record, keyToIgnore?) => {
        return Object.keys(record).reduce((acc: Record<string, Cell>, c) => {
          if (c !== keyToIgnore) {
            acc[c] = {
              type: defaultColumnTypes[c] ?? ('string' as const),
              value: record[c],
            };
          }

          return acc;
        }, {});
      };

      return calculateTotalCellValue(
        rawData.rows.map((row, index): AgoyTableRow => {
          const additionalColumns = recordObjectToCells(row.additionalColumns);
          const cells = recordObjectToCells(row, 'additionalColumns');

          return {
            id: `${index + 1}`,
            active: true,
            cells: {
              ...cells,
              ...additionalColumns,
            },
          };
        })
      );
    },
    []
  );

  const renderFooterRow = useCallback(() => {
    const summary = tableData.rows.reduce((sum, row) => {
      return sum + row.total;
    }, 0);

    return <FooterRow>Summa varulager: {summary}</FooterRow>;
  }, [tableData]);

  return (
    <TableWrapper>
      <TableHeaderWrapper>
        <Typography>
          {formatMessage({
            id: `hidden.inventoryStock.title`,
          })}
        </Typography>

        {!isPrint && (
          <Buttons>
            <EditingIconButton
              value={isEditing}
              onClick={() => setEditing(!isEditing)}
            />
            <PrintButton
              onClick={onOpenPreview}
              tooltip={formatMessage({
                id: `hidden.inventoryStock.print`,
              })}
            />
            <ResetContentButton onReset={onReset} what="table" />
          </Buttons>
        )}
      </TableHeaderWrapper>
      <ExpandableTable
        tableId="inventoryStock"
        initialData={mapRows(tableData)}
        isEditing={isEditing}
        initialColumns={mapColumns(tableData)}
        defaultColumnKeys={defaultColumnKeys}
        defaultColumnTypes={defaultColumnTypes}
        onDataUpdate={tableService.onTableDataUpdate}
        nonEditableRowColumns={['total']}
        initialColumnKeys={['total']}
        renderFooterRow={renderFooterRow}
        onDefaultColumnLabelUpdate={tableService.onDefaultColumnLabelUpdate}
      />
    </TableWrapper>
  );
};

export default InventoryStockTable;

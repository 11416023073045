import {
  SelectFormat,
  abs,
  id,
  ifOrElse,
  or,
  ref,
  sum,
  table,
  value,
} from '@agoy/document';
import { IxbrlCell, Note95 } from '../../../../common';
import { previousYear, year } from '../../../../common/utils/date-util';
import {
  active,
  field,
  ixbrlCell,
  ixbrlField,
  range,
  tupleRef,
} from '../../../../common/utils/util';

const MONETARY_ITEM_TYPE = 'monetaryItemType';

const selectorOptions: SelectFormat = {
  type: 'select',
  options: [
    {
      label: 'Garantiåtaganden',
      value: 'se-mem-base:AvsattningarGarantiatagandenMember',
    },
    {
      label: 'Erättning till anställda',
      value: 'se-mem-base:AvsattningarErsattningAnstalldaMember',
    },
    {
      label: 'Förlustkontrakt',
      value: 'se-mem-base:AvsattningarForlustkontraktMember',
    },
    {
      label: 'Omstruktureringsåtgärder',
      value: 'se-mem-base:AvsattningarOmstruktureringsatgarderMember',
    },
    {
      label: 'Tvister',
      value: 'se-mem-base:AvsattningarTvisterMember',
    },
    {
      label: 'Andra övriga avsättningar',
      value: 'custom',
    },
  ],
};

export const getNote95 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null
): Note95 => {
  const getTable = (
    sectionId: string,
    sYear: string,
    sPreviousYear: string,
    name: string
  ) => {
    return table<IxbrlCell>(
      `${sectionId}.${name}`,
      {
        id: 'label',
        label: '',
      },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      .addRows((rows) =>
        rows
          .addRow(
            '1',
            noteLabel('Årets avsättningar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OvrigaAvsattningarSpecifikationPeriodensAvsattningar',
              contextRef: 'period0',
              tupleRef: tupleRef(3, `${sectionId}.${sYear}.tuple`),
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OvrigaAvsattningarSpecifikationPeriodensAvsattningar',
              contextRef: 'period1',
              tupleRef: tupleRef(3, `${sectionId}.${sPreviousYear}.tuple`),
            })
          )
          .addRow(
            '2',
            noteLabel('Justeringar till följd av förändring av nuvärdet'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OvrigaAvsattningarSpecifikationJusteringForandringNuvardet',
              contextRef: 'period0',
              tupleRef: tupleRef(4, `${sectionId}.${sYear}.tuple`),
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OvrigaAvsattningarSpecifikationJusteringForandringNuvardet',
              contextRef: 'period1',
              tupleRef: tupleRef(4, `${sectionId}.${sPreviousYear}.tuple`),
            })
          )
          .addRow(
            '3',
            noteLabel('Övriga värdeförändringar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OvrigaAvsattningarSpecifikationOvrigaVardeforandringar',
              tupleRef: tupleRef(5, `${sectionId}.${sYear}.tuple`),
              contextRef: 'period0',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OvrigaAvsattningarSpecifikationOvrigaVardeforandringar',
              contextRef: 'period1',
              tupleRef: tupleRef(5, `${sectionId}.${sPreviousYear}.tuple`),
            })
          )
          .addRow(
            '4',
            noteLabel('Rörelseförvärv'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OvrigaAvsattningarSpecifikationRorelseforvarv',
              contextRef: 'period0',
              tupleRef: tupleRef(6, `${sectionId}.${sYear}.tuple`),
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OvrigaAvsattningarSpecifikationRorelseforvarv',
              contextRef: 'period1',
              tupleRef: tupleRef(6, `${sectionId}.${sPreviousYear}.tuple`),
            })
          )
          .addRow(
            '5',
            noteLabel('Fusion'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OvrigaAvsattningarSpecifikationFusion',
              contextRef: 'period0',
              tupleRef: tupleRef(7, `${sectionId}.${sYear}.tuple`),
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OvrigaAvsattningarSpecifikationFusion',
              contextRef: 'period1',
              tupleRef: tupleRef(7, `${sectionId}.${sPreviousYear}.tuple`),
            })
          )
          .addRow(
            '6',
            noteLabel('Omklassificeringar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OvrigaAvsattningarSpecifikationOmklassificeringar',
              contextRef: 'period0',
              tupleRef: tupleRef(8, `${sectionId}.${sYear}.tuple`),
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OvrigaAvsattningarSpecifikationOmklassificeringar',
              contextRef: 'period1',
              tupleRef: tupleRef(8, `${sectionId}.${sPreviousYear}.tuple`),
            })
          )
          .addRow(
            '7',
            noteLabel('Omräkningsdifferenser'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OvrigaAvsattningarSpecifikationOmrakningsdifferenser',
              contextRef: 'period0',
              tupleRef: tupleRef(9, `${sectionId}.${sYear}.tuple`),
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OvrigaAvsattningarSpecifikationOmrakningsdifferenser',
              contextRef: 'period1',
              tupleRef: tupleRef(9, `${sectionId}.${sPreviousYear}.tuple`),
            })
          )
          .addRow(
            '8',
            noteLabel('Under året ianspråktagna belopp'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OvrigaAvsattningarSpecifikationIanspraktagnaBelopp',
              contextRef: 'period0',
              tupleRef: tupleRef(10, `${sectionId}.${sYear}.tuple`),
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OvrigaAvsattningarSpecifikationIanspraktagnaBelopp',
              contextRef: 'period1',
              tupleRef: tupleRef(10, `${sectionId}.${sPreviousYear}.tuple`),
            })
          )
          .addRow(
            '9',
            noteLabel('Under året återförda belopp'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OvrigaAvsattningarSpecifikationAterfordaBelopp',
              contextRef: 'balans0',
              tupleRef: tupleRef(11, `${sectionId}.${sYear}.tuple`),
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OvrigaAvsattningarSpecifikationAterfordaBelopp',
              contextRef: 'balans1',
              tupleRef: tupleRef(11, `${sectionId}.${sPreviousYear}.tuple`),
            })
          )
          .addRow(
            '10',
            noteLabel('Redovisat värde'),
            ixbrlCell(
              ref(
                ifOrElse(
                  sum(
                    ...[...range(1, 10)].map((idNumber) => {
                      return abs(
                        or(id(`${sectionId}.${name}.${idNumber}.year`), 0)
                      );
                    })
                  ),
                  sum(
                    ...[...range(1, 10)].map((idNumber) => {
                      return or(id(`${sectionId}.${name}.${idNumber}.year`), 0);
                    })
                  ),
                  undefined
                )
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:OvrigaAvsattningarSpecifikationBelopp',
                contextRef: 'balans0',
                saldo: 'credit',
                tupleRef: tupleRef(12, `${sectionId}.${sYear}.tuple`),
              }
            ),
            ixbrlCell(
              ref(
                ifOrElse(
                  sum(
                    ...[...range(1, 10)].map((idNumber) => {
                      return abs(
                        or(
                          id(`${sectionId}.${name}.${idNumber}.previousYear`),
                          0
                        )
                      );
                    })
                  ),
                  sum(
                    ...[...range(1, 10)].map((idNumber) => {
                      return or(
                        id(`${sectionId}.${name}.${idNumber}.previousYear`),
                        0
                      );
                    })
                  ),
                  undefined
                )
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:OvrigaAvsattningarSpecifikationBelopp',
                contextRef: 'balans1',
                saldo: 'credit',
                tupleRef: tupleRef(12, `${sectionId}.${sPreviousYear}.tuple`),
              }
            )
          )
          .build()
      )
      .build();
  };

  return {
    active: false,
    number: value(undefined),
    name: value('Övriga avsättningar'),
    tables: {
      type: 'sections',
      sections: [],
      newSectionTemplate: (sectionId) => {
        return {
          type: ixbrlField(
            {
              type: 'string',
              value: 'se-mem-base:AvsattningarGarantiatagandenMember',
              format: selectorOptions,
            },
            {
              type: 'stringItemType',
              name: '',
              contextRef: 'period0',
            }
          ),
          year: ixbrlField(
            value('se-mem-base:AvsattningarGarantiatagandenMember'),
            {
              type: 'stringItemType',
              name: 'se-gen-base:OvrigaAvsattningarList',
              tuple: {
                name: 'se-gaap-ext:OvrigaAvsattningarSpecifikationTuple',
                id: `${sectionId}.year.tuple`,
                tupleID: `${sectionId}.year.tuple`,
              },
              tupleRef: tupleRef(1, `${sectionId}.year.tuple`),
              contextRef: 'period0',
            }
          ),
          previousYear: ixbrlField(
            value('se-mem-base:AvsattningarGarantiatagandenMember'),
            {
              type: 'stringItemType',
              name: 'se-gen-base:OvrigaAvsattningarList',
              tuple: {
                name: 'se-gaap-ext:OvrigaAvsattningarSpecifikationTuple',
                id: `${sectionId}.previousYear.tuple`,
                tupleID: `${sectionId}.previousYear.tuple`,
              },
              tupleRef: tupleRef(2, `${sectionId}.previousYear.tuple`),
              contextRef: 'period1',
            }
          ),
          customYear: ixbrlField(value(''), {
            type: 'stringItemType',
            name: 'se-gen-base:OvrigaAvsattningarSpecifikationAndraOvrigaAvsattningarTyp',
            tuple: {
              name: 'se-gaap-ext:AndraOvrigaAvsattningarSpecifikationTuple',
              id: `${sectionId}.customYear.tuple`,
              tupleID: `${sectionId}.customYear.tuple`,
            },
            tupleRef: tupleRef(1, `${sectionId}.customYear.tuple`),
            contextRef: 'period0',
          }),
          customPreviousYear: ixbrlField(value(''), {
            type: 'stringItemType',
            name: 'se-gen-base:OvrigaAvsattningarSpecifikationAndraOvrigaAvsattningarTyp',
            tuple: {
              name: 'se-gaap-ext:AndraOvrigaAvsattningarSpecifikationTuple',
              id: `${sectionId}.customPreviousYear.tuple`,
              tupleID: `${sectionId}.customPreviousYear.tuple`,
            },
            tupleRef: tupleRef(2, `${sectionId}.customPreviousYear.tuple`),
            contextRef: 'period1',
          }),
          table: getTable(sectionId, 'year', 'previousYear', 'table'),
          customTable: getTable(
            sectionId,
            'customYear',
            'customPreviousYear',
            'customTable'
          ),
        };
      },
    },
    sumTable: table(
      `note95.sumTable`,
      { id: 'label', label: '' },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      .addRows((rows) =>
        rows
          .addRow(
            'sum',
            noteLabel('Summa'),
            ixbrlCell(
              ref(
                or(
                  sum(
                    or(sum(id(`notes.note95.tables-*.table.10.year`)), 0),
                    or(sum(id(`notes.note95.tables-*.customTable.10.year`)), 0)
                  ),
                  0
                )
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:OvrigaAvsattningar',
                contextRef: 'balans0',
              }
            ),
            ixbrlCell(
              ref(
                or(
                  sum(
                    or(
                      sum(id(`notes.note95.tables-*.table.10.previousYear`)),
                      0
                    ),
                    or(
                      sum(
                        id(`notes.note95.tables-*.customTable.10.previousYear`)
                      ),
                      0
                    )
                  ),
                  0
                )
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:OvrigaAvsattningar',
                contextRef: 'balans1',
              }
            )
          )
          .build()
      )
      .build(),
    commentLabel: field('Kommentar till not'),
    comment: ixbrlField(active(field('')), {
      type: 'stringItemType',
      name: 'se-gen-base:NotOvrigaAvsattningarKommentar',
      contextRef: 'period0',
    }),
    group: value(undefined),
    section: value(undefined),
    validForReportType: {
      k2: false,
      k3: true,
    },
  };
};

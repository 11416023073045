import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Card, MenuItem, Menu, Typography } from '@material-ui/core';
import Button from '_shared/components/Buttons/Button';
import AddIcon from '@material-ui/icons/Add';
import { gray } from '@agoy/theme/src/colors';
import { useIntl } from 'react-intl';
import { ClientInformationField } from '@agoy/api-sdk-core';
import FormError from '_shared/components/Inputs/FormError';
import { FieldError } from 'react-hook-form';
import { SourceText, SourceWrapper, TimestampText } from './Common';
import { calcSource, formatTimestamp } from '../utils';

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
`;

const TextFieldWrapper = styled(Card)`
  border-radius: 12px;
  border-color: ${gray.HIGH};
  display: flex;
  min-height: 52px;
  flex-direction: column;
  font-family: 'Exo 2';
  padding: ${(props) => props.theme.spacing(2)}px;
`;

const StyledButton = styled(Button)`
  font-size: 14px;
`;

const FieldLabel = styled(Typography)`
  font-family: 'Exo 2';
  font-weight: 700;
  color: #000000;
  font-size: 18px;
  margin-bottom: 0.5rem;
`;

const ListWrapper = styled.div`
  display: flex;
  position: relative;
  margin-top: 0.5rem;
  margin-bottom: 0.7rem;
`;

const Spacer = styled.div`
  margin-top: 0.5rem;
`;

const SourceContainer = styled.div`
  margin-top: ${(props) => props.theme.spacing(2)}px;
  padding: 0 ${(props) => props.theme.spacing(2)}px;
`;

type Props = {
  source?: ClientInformationField['source'];
  user?: string;
  timestamp?: ClientInformationField['timestamp'] | number;
  createPerson: (role: string) => void;
  isEditing?: boolean;
  labelField: string;
  roles?: string[];
  children?: React.ReactNode;
  hasPerson?: boolean;
  error?: FieldError;
};

const ClientCardDirectorsSection = ({
  user,
  timestamp,
  source,
  createPerson,
  isEditing,
  labelField,
  children,
  roles,
  hasPerson = false,
  error,
}: Props) => {
  const { formatMessage } = useIntl();

  const getFieldLabel = (label: string) => {
    if (label === 'ceo' || label === 'directors')
      return formatMessage({ id: `clientInformation.${label}.category` });
    return formatMessage({ id: `clientInformation.${label}` });
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpen = (event) => {
    if (roles?.length === 1) {
      // Single option
      createPerson(roles[0]);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleRoleSelect = (role) => {
    createPerson(role);
    handleClose();
  };

  return (
    <FieldWrapper>
      <FieldLabel>{getFieldLabel(labelField)}</FieldLabel>
      <TextFieldWrapper variant="outlined">
        {children}
        {!isEditing && source && timestamp && (
          <SourceContainer>
            <SourceWrapper>
              <SourceText display="inline" source={source}>
                {calcSource(source)}{' '}
                {user &&
                  formatMessage({ id: 'clientInformation.fromUser' }, { user })}
              </SourceText>
            </SourceWrapper>

            <TimestampText display="inline">
              {formatTimestamp(timestamp)}
            </TimestampText>
          </SourceContainer>
        )}
        {isEditing && error && <FormError>{error.message}</FormError>}
      </TextFieldWrapper>

      {isEditing && roles && !hasPerson && (
        <ListWrapper>
          <StyledButton
            onClick={handleOpen}
            label={formatMessage({
              id: `clientInformation.addNew_${labelField}`,
            })}
            variant="outlined"
            startIcon={<AddIcon />}
          />
          <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={handleClose}>
            {roles.map((role) => (
              <MenuItem
                key={role}
                value={role}
                onClick={() => handleRoleSelect(role)}
              >
                {formatMessage({ id: `clientInformation.${role}` })}
              </MenuItem>
            ))}
          </Menu>
        </ListWrapper>
      )}

      {hasPerson && <Spacer />}
    </FieldWrapper>
  );
};

export default ClientCardDirectorsSection;

import { ActivityLogEventType } from '@agoy/activity-log';

import { getContext } from 'utils/AgoyAppClient/contextHolder';
import { asResultClass, getApiSdk } from 'api-sdk';

/**
 * Sends activity log event to BE.
 *
 * @param event ActivityLog event
 * @param shouldCreateContext flag is used only when the user logs in, as context does not exist yet
 * @returns
 */
const sendActivityEvent = async (
  event: ActivityLogEventType
): Promise<void> => {
  const sdk = getApiSdk(getContext());

  await asResultClass(
    sdk.postActivityLogEvents({
      requestBody: {
        date: event.date.toISOString(),
        userId: event.userId || null,
        clientId: event.clientId,
        program: event.program,
        section: event.section,
        resource: event.resource,
        operation: event.operation,
        arguments: event.arguments,
      },
    })
  );
};

export default sendActivityEvent;

import React, { useMemo } from 'react';
import CircleIcon from '@material-ui/icons/FiberManualRecord';
import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';

import {
  ChecklistProgramPeriods,
  ChecklistStatuses,
  ChecklistItemStatus,
} from '_shared/redux/checklist/types';
import { colors as ThemeColors } from '@agoy/theme';
import { Period } from '@agoy/api-sdk-core';
import { parseFormat } from '@agoy/dates';

const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(40px, 1fr));
  position: sticky;
  top: 48px;
  z-index: 1;
`;

const PeriodItem = styled.div`
  max-width: 100px;
  cursor: pointer;
  border: 2px solid #eeeeee;

  :not(:first-of-type) {
    border-left: none;
  }
`;

const PeriodTop = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  background-color: ${({ theme }) => theme.palette.accountingView.headers.main};
`;

const PeriodBottom = styled.div<{ selected: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme, selected }) =>
    selected ? theme.palette.accountingView.headers.main : 'white'};
  color: ${({ theme, selected }) =>
    selected ? 'white' : theme.palette.primary.main};
  font-weight: 500;
  border-top: 2px solid #eeeeee;
`;

const StatusIcon = styled(CircleIcon, { shouldForwardProp: isPropValid })<{
  statusColor?: string;
}>`
  color: ${({ theme, statusColor }) => statusColor ?? theme.palette.grey[400]};
`;

export const formatPeriod = (periodStart: string) => {
  const formattedPeriod = parseFormat(periodStart, 'MMM - yy');
  return (
    formattedPeriod.charAt(0).toLocaleUpperCase() + formattedPeriod.slice(1)
  );
};

const getPeriodsStatuses = (
  checklistPeriods: ChecklistProgramPeriods,
  statuses: ChecklistStatuses
): Record<string, ChecklistItemStatus> => {
  const periodsStatuses = {};

  Object.keys(checklistPeriods).forEach((period) => {
    let status: ChecklistItemStatus = 'not_started';

    const { groups } = checklistPeriods[period];

    groups.forEach((group) => {
      const { questions } = group;

      questions.forEach((question) => {
        let questionStatus: ChecklistItemStatus = 'not_started';
        if (question.id !== undefined) {
          questionStatus = statuses[period]?.[question.id] || 'not_started';
        }

        if (questionStatus === 'not_applicable') {
          return;
        }

        if (status === 'not_started') {
          status = questionStatus;
        }
        if (status === 'in_progress') {
          if (questionStatus === 'no') {
            status = questionStatus;
          }
        }
        if (status === 'yes') {
          if (
            questionStatus === 'not_started' ||
            questionStatus === 'in_progress'
          ) {
            status = 'in_progress';
          } else {
            status = questionStatus;
          }
        }
      });
    });

    periodsStatuses[period] = status;
  });

  return periodsStatuses;
};

interface ChecklistProps {
  periods: Period[];
  dataByPeriods: ChecklistProgramPeriods;
  selectedPeriod?: Period;
  statuses: ChecklistStatuses;
  onClickPeriod: (period: Period) => void;
}

const PeriodsRow = ({
  periods,
  dataByPeriods,
  selectedPeriod,
  statuses,
  onClickPeriod,
}: ChecklistProps) => {
  const periodStatuses = useMemo(() => {
    return getPeriodsStatuses(dataByPeriods, statuses);
  }, [dataByPeriods, statuses]);

  return (
    <Row>
      {periods.map((period) => (
        <PeriodItem key={period.id} onClick={() => onClickPeriod(period)}>
          <PeriodTop>
            <StatusIcon
              statusColor={
                ThemeColors[`checklist_${periodStatuses[period.id]}`]?.main
              }
            />
          </PeriodTop>
          <PeriodBottom selected={period.id === selectedPeriod?.id}>
            {formatPeriod(period.start)}
          </PeriodBottom>
        </PeriodItem>
      ))}
    </Row>
  );
};

export default PeriodsRow;

import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Typography, TextField } from '@material-ui/core';
import { SelectProps } from '@material-ui/core/Select';
import GenericSimplefied from './GenericSimplefied';

const BaseField = styled(GenericSimplefied)`
  position: relative;
  padding: 0;
  width: 100%;

  .MuiOutlinedInput-root {
    padding-top: 12px;
    padding-left: 2px;
    border-radius: 8px;
    min-width: 210px;
  }
  .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${({ theme }) => theme.palette.grey[500]};
  }
`;

const Title = styled(Typography)`
  position: absolute;
  top: 8px;
  left: 14px;
  font-size: 10px;
  color: #656d6a;
  background-color: #fff;
  padding: 0 4px;
`;

const StyledField = styled(TextField)`
  .MuiOutlinedInput-root {
    padding-top: 15px;
    padding-left: 12px;
    padding-bottom: 2px;
  }

  .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${({ theme }) => theme.palette.grey[500]};
  }
`;

type Item = {
  value: string | number;
  label: string;
  disabled?: boolean;
};

interface SimplifiedSelectProps {
  title?: string;
  items: Array<Item>;
  editing?: boolean;
  width?: number;
  onValueChange?: (value: string | number | undefined) => void;
}

const LabelSelectorAutocomplete = (
  props: SelectProps & SimplifiedSelectProps
) => {
  const {
    value,
    items,
    editing = true,
    className,
    width,
    title,
    fullWidth,
    disabled,
    onValueChange,
  } = props;
  const [selected, setSelected] = useState<Item | null>(null);

  const displayValue =
    items &&
    items.length &&
    items.filter((item) => item.value === props.value)[0]?.label;

  const handleSelectedChanged = (newValue: Item | null) => {
    setSelected(newValue);

    if (onValueChange) {
      onValueChange(newValue?.value);
    }
  };

  useEffect(() => {
    setSelected(items.find((item) => item.value === value) || null);
  }, [items, value]);

  return (
    <BaseField
      value={displayValue}
      editing={editing}
      width={width}
      className={className}
    >
      <Autocomplete
        options={items}
        getOptionSelected={(option) => option.value === selected?.value}
        getOptionLabel={(option) => option.label}
        onChange={(_e, value) => handleSelectedChanged(value)}
        renderInput={(params) => <StyledField {...params} variant="outlined" />}
        fullWidth={fullWidth}
        size="medium"
        value={selected}
        disabled={disabled}
      />
      {title && <Title>{title}</Title>}
    </BaseField>
  );
};

export default LabelSelectorAutocomplete;

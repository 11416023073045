import React from 'react';
import { Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { Business as BusinessType } from '@agoy/annual-report-document';
import { stringValue } from '@agoy/document';
import { Heading, Section } from '../../../../UI';
import MultilineField from '../../UI/MultilineField';

const Business = ({
  reportPart,
  title,
}: {
  reportPart: BusinessType;
  title: string | undefined;
}) => {
  const intl = useIntl();

  if (!reportPart) return null;

  const titleValue =
    title ||
    intl.formatMessage({
      id: 'annualReport.menu.managementReport.business',
    });

  return (
    <>
      <Section>
        <Heading title={titleValue} />
      </Section>

      {/* Business K3 FIELDS */}

      {Object.keys(reportPart)
        .filter(
          (key) =>
            key !== 'active' &&
            key !== 'boardText' &&
            key !== 'heading' &&
            key !== 'boardTextWithCeo' &&
            key !== 'boardWithoutCeo' &&
            key !== 'liquidatorText' &&
            key !== 'currencyText' &&
            reportPart[key].active
        )
        .map((fieldName) => {
          const show = stringValue(reportPart[fieldName]);
          const fieldLabel = `${fieldName}Label`;
          return (
            <div key={fieldName}>
              {show && (
                <Typography variant="h4">
                  {stringValue(reportPart[fieldLabel]) ||
                    intl.formatMessage({
                      id: `annualReport.managementReport.business.${fieldName}`,
                    })}
                </Typography>
              )}
              <MultilineField field={reportPart[fieldName]} />
            </div>
          );
        })}
    </>
  );
};

export default Business;

import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { isFinancialYear } from '@agoy/common';
import { getTaxConfig } from '@agoy/tax-document';
import { setTaxViewConfig } from '_tax/redux/tax-view/actions';
import { LoaderFunction } from '_shared/components/ClientLoaderContext';
import toPeriods from 'utils/financialYear/toPeriods';
import { useApiSdk } from 'api-sdk';
import BaseLoader from './BaseLoader';

/**
 * TaxCalculationLoader
 *
 * Loads the tax calculation data for a specific client and year.
 */
const TaxCalculationLoader = (): JSX.Element => {
  const dispatch = useDispatch();
  const sdk = useApiSdk();

  const loader: LoaderFunction = useCallback(
    async (clientId, companyType, financialYear) => {
      if (financialYear === undefined || !isFinancialYear(financialYear)) {
        throw new Error('Expected a financial year');
      }
      const periods = toPeriods(financialYear);
      if (!periods) {
        throw new Error('Failed to get periods');
      }
      const config = await getTaxConfig(
        (clientId, financialYear) =>
          sdk.getClientsTax({ clientid: clientId, financialYear }),
        clientId,
        financialYear,
        periods,
        companyType
      );
      await dispatch(setTaxViewConfig(clientId, financialYear, config));
    },
    [dispatch, sdk]
  );

  return <BaseLoader id="taxCalculation" scope="year" loader={loader} />;
};

export default TaxCalculationLoader;

import React, { useState } from 'react';
import {
  List,
  ListItem,
  ListItemText,
  Typography,
  Radio,
  ListItemIcon,
} from '@material-ui/core';
import { useIntl } from 'react-intl';
import Skeleton from '@material-ui/lab/Skeleton';
import styled from '@emotion/styled';
import { CTAButton } from '_shared/components/Buttons';
import { ApiReturnType } from 'api-sdk';

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

interface SelectOrganisationFormProps {
  invites: ApiReturnType<'getInvites'>;
  onAcceptInvite: (selectedInvite: string) => void;
  loading: boolean;
}

const SelectOrganisationForm = ({
  invites,
  onAcceptInvite,
  loading,
}: SelectOrganisationFormProps): JSX.Element => {
  const intl = useIntl();

  const [selectedInvite, setSelectedInvite] = useState('');

  const handleAcceptInvite = () => onAcceptInvite(selectedInvite);

  return (
    <>
      <Typography variant="h5">
        {intl.formatMessage({ id: 'company.join.desc' })}
      </Typography>
      <List component="nav">
        {loading && (
          <ListItem button>
            <Skeleton variant="text" animation="wave" width="100%" />
          </ListItem>
        )}

        {invites.length === 0 ? (
          <ListItem button>
            <ListItemText
              primary={intl.formatMessage({
                id: 'company.join.no.invites',
              })}
            />
          </ListItem>
        ) : (
          invites.map((invite) => (
            <ListItem
              onClick={() => {
                setSelectedInvite(invite.id);
              }}
              button
              key={invite.id}
            >
              <ListItemIcon>
                <Radio checked={selectedInvite === invite.id} />
              </ListItemIcon>
              <ListItemText primary={invite.organisation.name} />
            </ListItem>
          ))
        )}
      </List>
      <ButtonsContainer>
        <CTAButton
          color="primary"
          type="submit"
          onClick={handleAcceptInvite}
          disabled={!selectedInvite === undefined || invites.length === 0}
        >
          {intl.formatMessage({ id: 'company.join' })}
        </CTAButton>
      </ButtonsContainer>
    </>
  );
};

export default SelectOrganisationForm;

import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import shortid from 'shortid';
import { useIntl } from 'react-intl';

import { useSelector } from 'redux/reducers';
import { ccyFormat } from '@agoy/common';
import { currentCustomer } from '_clients/redux/customer-view/selectors';
import { ReportSupplierData } from '_reconciliation/types';
import { Period } from '@agoy/api-sdk-core';

import { parseFormat } from '@agoy/dates';
import Header from './Header';
import GroupItem from './GroupItem';
import Footer from './Footer';

const LIST_ITEM_WIDTH = 110;

interface CustomWidthProps {
  customWidth: number;
}

const A4Page = styled.main`
  width: ${(p: CustomWidthProps) => p.customWidth}px;
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 80vh;
  width: 100%;
`;

const GroupList = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const ScrollableGroupList = styled(GroupList)`
  overflow-y: auto;
  overflow-x: hidden;
`;

type ItemProp = {
  id: string;
  align: string;
  value: string;
};

type SummariesProps = {
  id: string;
  title: string;
  amount: string;
};

type TemplateProps = {
  id: string;
  headerLine: string[];
  itemData: ItemProp[][];
  summaries: SummariesProps[] | null;
};

type FooterProps = {
  id: string;
  key: string;
  value: string;
};

type InvoicesPreviewProps = {
  data: ReportSupplierData;
  period: Period;
  isPrint?: boolean;
};

const TEMPLATE_HEADER: TemplateProps = {
  id: shortid(),
  headerLine: ['Levnr', 'Leverantör'],
  itemData: [
    [
      { id: shortid(), value: 'Löpnr', align: 'left' },
      { id: shortid(), value: 'OCR/Fakturanr', align: 'left' },
      { id: shortid(), value: 'Faktdat', align: 'left' },
      { id: shortid(), value: 'Förfdat', align: 'left' },
      { id: shortid(), value: 'Valuta', align: 'left' },
      { id: shortid(), value: 'Belopp', align: 'right' },
      { id: shortid(), value: 'Belopp i SEK', align: 'right' },
      { id: shortid(), value: 'Saldo', align: 'right' },
      { id: shortid(), value: 'Saldo i SEK', align: 'right' },
    ],
  ],
  summaries: null,
};

const InvoicesPreviewHeader: React.FC = () => (
  <GroupItem
    key={TEMPLATE_HEADER.headerLine[0] + TEMPLATE_HEADER.id}
    id={TEMPLATE_HEADER.id}
    colsAmount={LIST_ITEM_WIDTH}
    headlines={TEMPLATE_HEADER.headerLine}
    fields={TEMPLATE_HEADER.itemData}
    summaries={TEMPLATE_HEADER.summaries}
  />
);

const InvoicesPreview = ({
  data,
  period,
  isPrint = false,
}: InvoicesPreviewProps): JSX.Element | null => {
  const { formatMessage } = useIntl();

  const customer = useSelector(currentCustomer);

  const [groupedInvoices, setGroupedInvoices] = useState<TemplateProps[]>([]);
  const [totalData, setTotalData] = useState<FooterProps[]>([]);

  // when in print preview we remove the sticky header and footer so that
  // all the pages are printed correctly
  const InvoicesList = isPrint ? GroupList : ScrollableGroupList;

  useEffect(() => {
    const resultList = data.supplierInvoices.map((item) => ({
      id: shortid(),
      headerLine: [item.supplierNumber.toString(), item.supplierName],
      itemData: item.invoices.map((invoice) => {
        const result = Object.values({
          ...invoice,
          invoiceNumber: invoice.invoiceNumber.toString(),
        }).map((prop) => {
          if (typeof prop === 'number') {
            return {
              id: shortid(),
              align: 'right',
              value: ccyFormat(prop, 2),
            };
          }
          return {
            id: shortid(),
            align: 'left',
            value: prop.toString(),
          };
        });
        result.splice(1, 0, {
          id: shortid(),
          align: 'left',
          value: '',
        });
        return result;
      }),
      summaries: [
        {
          id: shortid(),
          title: formatMessage({
            id: 'reconciliation.hidden_row.supplierInvoices.preview.amount',
          }),
          amount: item.amount.toString(),
        },
        {
          id: shortid(),
          title: formatMessage({
            id: 'reconciliation.hidden_row.supplierInvoices.preview.totalSumSEK',
          }),
          amount: ccyFormat(item.totalSumSEK, 2),
        },
      ],
    }));

    setGroupedInvoices(resultList);
    const totalSums = [
      {
        id: shortid(),
        key: formatMessage({
          id: 'reconciliation.hidden_row.supplierInvoices.preview.amount',
        }),
        value: data.totalInvoices.toString(),
      },
      {
        id: shortid(),
        key: formatMessage({
          id: 'reconciliation.hidden_row.supplierInvoices.preview.totalSummaSEK',
        }),
        value: ccyFormat(data.totalSumSEK, 2),
      },
      {
        id: shortid(),
        key: formatMessage({
          id: 'reconciliation.hidden_row.supplierInvoices.preview.sumSEK',
        }),
        value: ccyFormat(data.sumSEK, 2),
      },
    ];
    Object.keys(data.sums).forEach((key) => {
      totalSums.push({
        id: shortid(),
        key: `${formatMessage({
          id: 'reconciliation.hidden_row.supplierInvoices.preview.totalCurrency',
        })} ${key}`,
        value: ccyFormat(data.sums[key], 2),
      });
    });
    setTotalData(totalSums);
  }, [
    data.sumSEK,
    data.sums,
    data.supplierInvoices,
    data.totalInvoices,
    data.totalSumSEK,
    formatMessage,
    isPrint,
  ]);

  return (
    <A4Page customWidth={LIST_ITEM_WIDTH * TEMPLATE_HEADER.itemData[0].length}>
      <Header
        title={formatMessage({
          id: 'reconciliation.hidden_row.supplierInvoices.preview.title',
        })}
        headerData={{
          customerName: customer?.name || '',
          customerNumber: customer?.orgNumber || '',
          selectedMonth: period.end,
        }}
        printInfo={parseFormat(new Date(), 'yyyy-MM-dd HH:mm')}
      />
      <InvoicesPreviewHeader />
      <InvoicesList>
        {groupedInvoices.length &&
          groupedInvoices.map((groupItem) => (
            <GroupItem
              key={groupItem.headerLine[0] + groupItem.id}
              id={groupItem.id}
              colsAmount={LIST_ITEM_WIDTH}
              headlines={groupItem.headerLine}
              fields={groupItem.itemData}
              summaries={groupItem.summaries}
            />
          ))}
      </InvoicesList>
      <Footer data={totalData} />
    </A4Page>
  );
};

export default InvoicesPreview;

import { AgoyDocumentStructure } from '../document';
import updateDocument from './helpers/updateDocument';
import updateRow from './helpers/updateRow';
import { Errors, State } from './types';
import { isReferenceCell, ReferenceCell } from '../../Cell';
import { AgoyTable } from '../table';
import { TableChange } from '../changes';

export const isActive = (
  value: boolean | ReferenceCell | undefined
): boolean | undefined => {
  if (typeof value === 'object' && isReferenceCell(value)) {
    if (typeof value.value !== 'boolean') return true;
    return value.value;
  }

  return value;
};

const toggleTableRowGroupActive = <T extends AgoyDocumentStructure>(
  structure: T,
  state: State<T>,
  id: string,
  value?: boolean
) => {
  return updateDocument(structure, state, id, {
    table: (key, tableId, props) => {
      if (!key) {
        return props;
      }

      let childrenActive = false;
      const result = key.reduce(
        (
          currentState: [AgoyTable, TableChange | undefined] | Errors,
          _,
          currentIndex
        ) => {
          if (typeof currentState === 'string') {
            return currentState;
          }

          const updater = updateRow(
            key.slice(0, key.length - currentIndex),
            (row, change) => {
              if (currentIndex === 0 && row.id === key[key.length - 1]) {
                childrenActive = !isActive(row.active ?? true);
              }

              const calculatedActive =
                currentIndex === 0
                  ? childrenActive
                  : row.rows?.some((subRow) => subRow.active) ?? true;

              const active = value ?? calculatedActive;

              return [
                { ...row, active },
                { ...change, row: { ...change.row, active } },
              ];
            },
            currentIndex === 0
          );

          const updatedState = updater(
            currentState[0],
            currentState[1] ?? { type: 'update' }
          );

          return updatedState || currentState;
        },
        [props.node, props.changes]
      );

      if (!Array.isArray(result)) {
        return props;
      }

      return {
        ...props,
        node: result[0],
        changes: result[1],
      };
    },
  });
};

export default toggleTableRowGroupActive;

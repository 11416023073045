import { FinancialYear } from '@agoy/api-sdk-core';
import {
  SET_ACCOUNT_LIST_DRAWER_EXPANDED,
  SET_ACCOUNT_LIST_DRAWER_MODE,
  ACCOUNT_LIST_DRAWER_ADD_ACCOUNT,
  ACCOUNT_LIST_DRAWER_REMOVE_ACCOUNT,
  ACCOUNT_LIST_DRAWER_CLEAR_SELECTION,
  ACCOUNT_LIST_DRAWER_SET_ACCOUNTS,
  ACCOUNT_LIST_DRAWER_SET_CURRENT_ACCOUNT,
  ACCOUNT_LIST_DRAWER_SET_ACCOUNT_DESELECTED,
} from './action-types';
import { AccountListDrawerMode } from './reducer';

interface SetAccountListDrawerExpanded {
  type: typeof SET_ACCOUNT_LIST_DRAWER_EXPANDED;
  expanded: boolean;
}

interface SetAccountListDrawerMode {
  type: typeof SET_ACCOUNT_LIST_DRAWER_MODE;
  mode: AccountListDrawerMode;
  selectionOwner: string;
  financialYear?: FinancialYear;
}

interface AccountListDrawerAddAccount {
  type: typeof ACCOUNT_LIST_DRAWER_ADD_ACCOUNT;
  account: string;
}

interface AccountListDrawerSetAccounts {
  type: typeof ACCOUNT_LIST_DRAWER_SET_ACCOUNTS;
  accounts: string[];
}

interface AccountListDrawerRemoveAccount {
  type: typeof ACCOUNT_LIST_DRAWER_REMOVE_ACCOUNT;
  account: string;
}

interface AccountListDrawerClearSelection {
  type: typeof ACCOUNT_LIST_DRAWER_CLEAR_SELECTION;
}

interface AccountListDrawerSetCurrentAccount {
  type: typeof ACCOUNT_LIST_DRAWER_SET_CURRENT_ACCOUNT;
  account?: string;
}

interface AccountListDrawerSetDeselectedAccount {
  type: typeof ACCOUNT_LIST_DRAWER_SET_ACCOUNT_DESELECTED;
  deselectedAccount?: string;
}

export const setAccountListDrawerExpanded = (
  expanded
): SetAccountListDrawerExpanded => ({
  type: SET_ACCOUNT_LIST_DRAWER_EXPANDED,
  expanded,
});

export const setAccountListDrawerMode = (
  mode: AccountListDrawerMode,
  financialYear?: FinancialYear,
  selectionOwner?: string
): SetAccountListDrawerMode => ({
  type: SET_ACCOUNT_LIST_DRAWER_MODE,
  mode,
  financialYear,
  selectionOwner:
    (mode === 'selecting-accounts' || mode === 'toggling-accounts') &&
    selectionOwner !== undefined
      ? selectionOwner
      : '',
});

export const addSelectedAccount = (account): AccountListDrawerAddAccount => ({
  type: ACCOUNT_LIST_DRAWER_ADD_ACCOUNT,
  account,
});

export const setSelectedAccounts = (
  accounts
): AccountListDrawerSetAccounts => ({
  type: ACCOUNT_LIST_DRAWER_SET_ACCOUNTS,
  accounts,
});

export const removeSelectedAccount = (
  account
): AccountListDrawerRemoveAccount => ({
  type: ACCOUNT_LIST_DRAWER_REMOVE_ACCOUNT,
  account,
});

export const setCurrentAccount = (
  account?: string
): AccountListDrawerSetCurrentAccount => ({
  type: ACCOUNT_LIST_DRAWER_SET_CURRENT_ACCOUNT,
  account,
});

export const clearAccountListDrawerSelection =
  (): AccountListDrawerClearSelection => ({
    type: ACCOUNT_LIST_DRAWER_CLEAR_SELECTION,
  });

export const setDeselectedAccount = (
  account?: string
): AccountListDrawerSetDeselectedAccount => ({
  type: ACCOUNT_LIST_DRAWER_SET_ACCOUNT_DESELECTED,
  deselectedAccount: account,
});

export type AccountListDrawerActions =
  | SetAccountListDrawerExpanded
  | SetAccountListDrawerMode
  | AccountListDrawerAddAccount
  | AccountListDrawerSetAccounts
  | AccountListDrawerRemoveAccount
  | AccountListDrawerClearSelection
  | AccountListDrawerSetCurrentAccount
  | AccountListDrawerSetDeselectedAccount;

import React from 'react';
import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
`;

export type GroupCellProps = {
  groupName: string;
};

const GroupCell = ({ groupName }: GroupCellProps) => {
  return (
    <Container>
      <Typography color="textSecondary">{groupName}</Typography>
    </Container>
  );
};

export default GroupCell;

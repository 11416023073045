import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import LoadingLogo from '_shared/components/LoadingLogo';
import ReceiptIcon from '@material-ui/icons/Receipt';
import BlockIcon from '@material-ui/icons/Block';
import IconButton from '@material-ui/core/IconButton/IconButton';
import { asResultClass, useApiSdk } from 'api-sdk';
import { addGlobalMessage } from '_messages/redux/actions';
import { PreviewFortnoxFileType } from 'types/PreviewFortnoxFile/types';
import { Tooltip } from '@material-ui/core';

interface Props {
  clientId: string;
  voucher: string;
  isBlock: boolean;
  voucherYear: string;
  onFetchDone: (transactionId: string, files: PreviewFortnoxFileType[]) => void;
}

const Wrapper = styled.div`
  min-width: 40px;
`;

const TooltipBlockIcon = styled(BlockIcon)`
  align-self: center;
`;

const AttachmentPreviewButton = ({
  clientId,
  voucher,
  isBlock,
  voucherYear,
  onFetchDone,
}: Props): JSX.Element => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const sdk = useApiSdk();

  const [isLoading, setIsLoading] = useState(false);
  const [isAttachmentBlock, setBlockState] = useState(isBlock);

  useEffect(() => {
    setBlockState(isBlock);
  }, [voucher, isBlock]);

  const fetchAttachment = async () => {
    setIsLoading(true);
    const result = await asResultClass(
      sdk.getTransactionAttachment({
        clientid: clientId || '',
        voucher,
        voucherYear,
      })
    );
    let files;
    if (result.ok) {
      files = result.val.map((item) => ({
        fileId: item.fileId || '',
        fileName: item.fileName || '',
        fileContent: item.fileContent || '',
      }));
    } else {
      files = [];
    }
    setIsLoading(false);

    onFetchDone(voucher, files);

    if (files.length === 0) {
      dispatch(addGlobalMessage('info', 'voucher.fortnox.attachment.info'));
    }
    setBlockState(files.length === 0);
  };

  return (
    <Wrapper>
      {!isAttachmentBlock && (
        <IconButton onClick={fetchAttachment} size="small">
          {isLoading ? (
            <LoadingLogo />
          ) : (
            <ReceiptIcon fontSize="small" color="primary" />
          )}
        </IconButton>
      )}
      {isAttachmentBlock && (
        <Tooltip
          title={formatMessage({
            id: 'voucher.fortnox.attachment.info.tooltip',
          })}
        >
          <div>
            <TooltipBlockIcon fontSize="small" color="primary" />
          </div>
        </Tooltip>
      )}
    </Wrapper>
  );
};

export default AttachmentPreviewButton;

import styled from '@emotion/styled';

const NumberCell = styled.span<{ warning?: string }>`
  display: inline-block;
  text-align: right;
  width: 100%;

  ${({ warning, theme }) =>
    warning ? `color: ${theme.palette.error.main}` : ''};
`;

export default NumberCell;

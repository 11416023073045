import { Err } from 'ts-results';
import { asResultClass, getApiSdk, isApiErrorType } from 'api-sdk';
import { NotificationService } from '_shared/services/Notifications/types';
import {
  AccountingBalancesDataLayer,
  REQUIRE_IMMIDIATE_UPDATE,
} from '../types';
import waitForSieImport from './utils/waitForSieImport';

const updateSieFromFortnox = async (
  sdk: ReturnType<typeof getApiSdk>,
  notificationService: NotificationService | null,
  clientId: string,
  financialYear: string
): ReturnType<AccountingBalancesDataLayer['updateSieFromFortnox']> => {
  const result = await asResultClass(
    sdk.updateSieFortnoxIntegration({
      clientid: clientId,
      financialYear,
    })
  );

  if (result.err) {
    if (isApiErrorType(result.val)) {
      return Err({
        errorCode: result.val.body.code || 'SIE_IMPORT_FAILED',
        errorDetails: {
          detailsMessage:
            result?.val?.body?.details?.Message &&
            result.val.body.details.Message,
          message: result.val.body.message,
        },
      });
    }

    return Err({
      errorCode: 'SIE_IMPORT_FAILED',
      errorDetails: {},
    });
  }

  if (result.val.jobId && notificationService !== null) {
    // Sie import is running, wait for the ok or error to return that.
    // The refresh of accounting balances will be done elsewhere.
    return waitForSieImport(notificationService, clientId, result.val.jobId);
  }
  return Err({ errorCode: REQUIRE_IMMIDIATE_UPDATE, errorDetails: {} });
};

export default updateSieFromFortnox;

import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Box, Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { asResultClass, useApiSdk } from 'api-sdk';
import PeriodDataContext from '_reconciliation/components/ReconciliationView/HiddenRow/Rows/PeriodDataContext';
import { useDispatch } from 'react-redux';
import { onUserInputCallbackFunction } from '_reconciliation/components/ReconciliationView/HiddenRow/Rows/types';
import {
  addGlobalErrorMessage,
  patchUserInputRoutineData,
} from 'redux/actions';

import MultilineTextField from '../Rows/EventView/Input';
import GroupedInternalComments from '../GroupedInternalComments';

import { HiddenGroupRow as HiddenGroupRowType } from '../../RowContext/types';
import { getFormattedPeriod } from './utils';
import { getRoutine } from '../Rows/EventView/routines';
import TransferComment from '../Rows/EventView/TransferComment';

type GropedAccountsCommentsProps = {
  row: HiddenGroupRowType;
  clientId: string;
};

const CommentAndRoutineColumn = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
`;

const GroupedAccountsComments = ({
  row,
  clientId,
}: GropedAccountsCommentsProps) => {
  const { formatMessage } = useIntl();
  const sdk = useApiSdk();
  const dispatch = useDispatch();
  const [comments, setComments] = useState<string>('');
  const [routines, setRoutines] = useState<string>('');

  const { period } = useContext(PeriodDataContext);
  const groupId = row.balance.id.split('.')[0];

  const onPutUserInput: onUserInputCallbackFunction = async (
    inputData,
    groupNumber,
    propsPeriod
  ) => {
    await sdk.putUserInput({
      clientid: clientId,
      periodId: propsPeriod.id,
      accountGroup: groupNumber,
      requestBody: inputData,
    });
    setComments(inputData.comment || '');
  };

  const onUserInputRoutine = useCallback(
    (routine) => {
      const formattedPeriod = getFormattedPeriod(period);
      const routineObj = {};
      routineObj[formattedPeriod] = routine;
      dispatch(
        patchUserInputRoutineData(clientId, {
          newUserInput: routineObj,
          accountNumber: groupId,
          formattedPeriod,
        })
      );
    },
    [clientId, dispatch, groupId, period]
  );
  const requestUserInput = useCallback(async () => {
    const result = await asResultClass(
      sdk.getPeriodUserInput({
        clientid: clientId,
        accountGroups: [groupId],
        periodId: period.id,
      })
    );
    if (result.err) {
      dispatch(addGlobalErrorMessage('error'));
      return;
    }
    const userComments = result.val.accounts[groupId]?.comment || '';
    setComments(userComments);
  }, [sdk, clientId, groupId, period, dispatch]);

  const requestRoutines = useCallback(async () => {
    const result = await asResultClass(sdk.getRoutines({ clientid: clientId }));
    if (result.err) {
      dispatch(addGlobalErrorMessage('error'));
      return;
    }

    const routinesOnAccount =
      result.val[`account${groupId}`] &&
      getRoutine(new Date(period.start), result.val[`account${groupId}`])
        ? getRoutine(new Date(period.start), result.val[`account${groupId}`])
        : (result.val[`account${groupId}`] &&
            result.val[`account${groupId}`][getFormattedPeriod(period)]) ||
          '';
    setRoutines(routinesOnAccount);
  }, [clientId, dispatch, groupId, period, sdk]);

  useEffect(() => {
    requestUserInput();
    requestRoutines();
  }, [requestUserInput, requestRoutines]);

  const onGroupedExternalCommentsUpdate = (event) => {
    const newComment = event.target.value;
    onPutUserInput({ comment: newComment }, groupId, period);
  };
  const onGroupedRutinUpdate = (event) => {
    const newRutin = event.target.value;
    onUserInputRoutine(newRutin);
    setRoutines(newRutin);
  };

  return (
    <CommentAndRoutineColumn>
      <GroupedInternalComments row={row} clientId={clientId} />
      <Box marginTop={2}>
        <Typography variant="body1" color="textSecondary">
          {formatMessage({ id: 'externalComment' })}
        </Typography>

        <MultilineTextField
          placeholder={formatMessage({ id: 'externalComment' })}
          defaultValue={comments || ''}
          isLocked={false}
          onBlur={onGroupedExternalCommentsUpdate}
        />
        <TransferComment
          comment={comments}
          period={period}
          accountNumber={groupId}
          userInputCallback={onPutUserInput}
          isGroupedAccounts
        />
      </Box>
      <Box marginTop={2}>
        <Typography variant="body1" color="textSecondary">
          {formatMessage({ id: 'routine' })}
        </Typography>

        <MultilineTextField
          placeholder={formatMessage({ id: 'routine' })}
          defaultValue={routines}
          isLocked={false}
          onBlur={onGroupedRutinUpdate}
        />
      </Box>
    </CommentAndRoutineColumn>
  );
};

export default GroupedAccountsComments;

import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import Button from '_shared/components/Buttons/Button';
import Alert, { AlertType } from '_shared/components/AlertBanners/Alert';
import styled from '@emotion/styled';
import CloseIcon from '@material-ui/icons/Close';
import { Variant } from '_shared/components/Buttons/Button.types';
import { getClasses } from '@agoy/common';

const DialogTitleWrapper = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
`;

const DialogActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing(2)}px;
  padding: ${({ theme }) => theme.spacing(1)}px
    ${({ theme }) => theme.spacing(2)}px;

  &.reverse-button-order {
    justify-content: flex-start;
    flex-direction: row-reverse;
  }
`;

type Props = {
  isOpen?: boolean;
  onAccept: () => void;
  onClose: () => void;
  reverseButtonOrder?: boolean;
  title?: string;
  text?: string;
  alertMessage?: string;
  alertType?: AlertType;
  showHeaderCloseButton?: boolean;
  acceptButtonColor?: 'primary' | 'danger';
  acceptButtonText?: string;
  acceptButtonStartIcon?: React.ReactNode;
  acceptButtonVariant?: Variant;
  closeButtonColor?: 'primary' | 'danger';
  closeButtonStartIcon?: React.ReactNode;
  closeButtonText?: string;
  closeButtonVariant?: Variant;
};

const ConfirmationDialog = ({
  isOpen = true,
  onAccept,
  onClose,
  text,
  title,
  showHeaderCloseButton = false,
  reverseButtonOrder = false,
  alertMessage,
  alertType = 'warning',
  acceptButtonColor = 'primary',
  acceptButtonStartIcon,
  acceptButtonText = 'OK',
  acceptButtonVariant = 'contained',
  closeButtonColor,
  closeButtonStartIcon,
  closeButtonText = 'Avbryt',
  closeButtonVariant = 'outlined',
}: Props): JSX.Element => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {(title || showHeaderCloseButton) && (
        <DialogTitleWrapper>
          <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
          {onClose && showHeaderCloseButton && (
            <IconButton aria-label="close" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          )}
        </DialogTitleWrapper>
      )}

      <DialogContent id="alert-dialog-description">
        {alertMessage && <Alert type={alertType}>{alertMessage}</Alert>}
        {text && <DialogContentText>{text}</DialogContentText>}
      </DialogContent>
      <DialogActions>
        <DialogActionsWrapper
          className={getClasses({ 'reverse-button-order': reverseButtonOrder })}
        >
          <Button
            variant={acceptButtonVariant}
            onClick={() => {
              onClose();
              onAccept();
            }}
            color={acceptButtonColor}
            label={acceptButtonText}
            startIcon={acceptButtonStartIcon}
          />
          <Button
            onClick={onClose}
            color={closeButtonColor}
            variant={closeButtonVariant}
            label={closeButtonText}
            startIcon={closeButtonStartIcon}
          />
        </DialogActionsWrapper>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;

import React, { useCallback, useContext } from 'react';
import { TableCell, TableRow, TableHead } from '@material-ui/core';
import styled from '@emotion/styled';
import AddIcon from '@material-ui/icons/Add';
import { AgoyTableColumn } from '@agoy/document';
import ServiceContext from './Context/TableServiceContext';
import TableHeadCell from './TableHeadCell';
import Button from '../Buttons/Button';

interface TableHeadComponentProps {
  baseId: string;
  tableId: string;
  columns: AgoyTableColumn[];
  editing?: boolean;
  print?: boolean;
  canDrag?: boolean;
  canDragRows?: boolean;
  canDeleteRows?: boolean;
  canDelete?: boolean;
  canEdit?: boolean;
  addNewColumnPosition?: number;
  columnsWithoutDrag?: string[];
  columnsWithoutDelete?: string[];
  columnsWithoutEditing?: string[];
  canAddColumns?: boolean;
  canToggleActive?: boolean;
  columnsWithClicking?: string[];
  headerCellTitle?: string;
  onHeaderColumnPress?: (column: AgoyTableColumn | undefined) => void;
  renderCell?: (values: {
    column: AgoyTableColumn;
    isDragging: boolean;
  }) => React.ReactElement | string | null;
  onDragHoverColumn: (
    draggedColumn: AgoyTableColumn,
    column: AgoyTableColumn
  ) => void;
  onEndDragColumn: (column: AgoyTableColumn) => void;
  onAddColumn?: () => void;
}

const IconCell = styled(TableCell)`
  width: 58px;
`;

const ClickableIconCell = styled(IconCell)`
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.palette.text.primary};
  }
`;

const TableHeadComponent = ({
  baseId,
  tableId,
  columns,
  editing = false,
  print = false,
  canDrag = false,
  canDragRows = false,
  canDeleteRows = false,
  canDelete = false,
  canEdit = false,
  canAddColumns = false,
  addNewColumnPosition,
  columnsWithoutDrag = [],
  columnsWithoutEditing = [],
  columnsWithoutDelete = [],
  canToggleActive,
  columnsWithClicking,
  headerCellTitle,
  onHeaderColumnPress,
  renderCell,
  onDragHoverColumn,
  onEndDragColumn,
  onAddColumn,
}: TableHeadComponentProps): JSX.Element => {
  const service = useContext(ServiceContext);

  const addColumn = useCallback(async () => {
    if (onAddColumn) {
      onAddColumn();
    } else if (addNewColumnPosition !== undefined) {
      const columnsWithSortKey: { id: string; sortKey: number }[] = [];

      columns.forEach((item, index) => {
        if (
          typeof item.sortKey === 'number' &&
          item.sortKey >= addNewColumnPosition
        ) {
          columnsWithSortKey.push({
            id: `${tableId}.${item.id}`,
            sortKey: index + 1,
          });
        }
      });

      await service?.updateColumnSortKey(columnsWithSortKey);
      await service?.addTableColumn(
        tableId,
        addNewColumnPosition + 1,
        '',
        addNewColumnPosition + 1
      );
    }
  }, [addNewColumnPosition, columns, onAddColumn, service, tableId]);

  const deleteColumn = useCallback(
    (columnId: string) => {
      const columnsWithSortKey: { id: string; sortKey: number }[] = [];
      const columnIndex = columns.findIndex((item) => item.id === columnId);

      columns.forEach((item, index) => {
        if (typeof item.sortKey === 'number' && item.sortKey > columnIndex) {
          columnsWithSortKey.push({
            id: `${tableId}.${item.id}`,
            sortKey: index - 1,
          });
        }
      });

      service?.updateColumnSortKey(columnsWithSortKey);
      service?.deleteColumn(`${tableId}.${columnId}`);
    },
    [columns, service, tableId]
  );

  const editColumn = useCallback(
    async (columnId: string, value: string) => {
      const newValue = value === '' ? ' ' : value;

      await service?.updateColumnLabel(`${tableId}.${columnId}`, newValue);
    },
    [service, tableId]
  );

  return (
    <TableHead>
      <TableRow>
        {editing && !print && (
          <>
            {canToggleActive && <TableCell />}
            {canDragRows && <IconCell />}
          </>
        )}

        {columns.map((col, index) => {
          let cellAlign;
          if (col.align) {
            cellAlign = col.align;
          } else {
            cellAlign = index === 0 ? 'left' : 'right';
          }
          return (
            <React.Fragment key={col.id}>
              <TableHeadCell
                column={col}
                baseId={baseId}
                tableId={tableId}
                editing={canEdit && editing}
                canEdit={canEdit && !columnsWithoutEditing.includes(col.id)}
                canDrag={canDrag && !columnsWithoutDrag.includes(col.id)}
                canDelete={canDelete && !columnsWithoutDelete.includes(col.id)}
                onDeleteColumn={deleteColumn}
                onEditColumn={editColumn}
                onDragHoverColumn={onDragHoverColumn}
                onEndDragColumn={onEndDragColumn}
                textAlign={cellAlign}
                renderCell={renderCell}
                onHeaderColumnPress={onHeaderColumnPress}
                headerCellTitle={headerCellTitle}
                canPress={columnsWithClicking?.includes(col.id)}
              />
              {index === addNewColumnPosition && !print && editing && (
                <TableCell>
                  <Button
                    label="Ny kolumn"
                    onClick={addColumn}
                    variant="text"
                    size="medium"
                  />
                </TableCell>
              )}
            </React.Fragment>
          );
        })}
        {editing && canDeleteRows && <IconCell />}
        {!print && editing && canAddColumns && (
          <ClickableIconCell onClick={addColumn}>
            <AddIcon />
          </ClickableIconCell>
        )}
      </TableRow>
    </TableHead>
  );
};

export default TableHeadComponent;

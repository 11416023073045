import { Err, Ok } from 'ts-results';
import { asResultClass, getApiSdk, isApiErrorType } from 'api-sdk';
import { AccountingBalancesDataLayer } from '../types';

const retryImport = async (
  sdk: ReturnType<typeof getApiSdk>,
  clientId: string,
  financialYear: string
): ReturnType<AccountingBalancesDataLayer['retryImport']> => {
  const result = await asResultClass(
    sdk.retryImportSieFile({
      clientid: clientId,
      financialYear,
    })
  );

  if (result.err) {
    if (isApiErrorType(result.val)) {
      if (result.val.handled) {
        return Ok.EMPTY;
      }
      return Err({
        errorCode: result.val.body.code || 'SIE_IMPORT_FAILED',
        errorDetails: {
          message: result.val.body.message,
        },
      });
    }
    return Err({
      errorCode: 'SIE_IMPORT_FAILED',
      errorDetails: {},
    });
  }
  return Ok.EMPTY;
};

export default retryImport;

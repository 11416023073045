import React, { useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import Arrow from '@material-ui/icons/Forward';
import ArrowForward from '@material-ui/icons/ArrowForwardIos';
import { IconButton, Tooltip } from '@material-ui/core';
import styled from '@emotion/styled';
import { DocumentPreview } from '_shared/components/UploadDocument';
import { PeriodDocument } from 'utils/usePeriodDocuments/Provider';
import { useAccountDocuments } from 'utils/usePeriodDocuments';
import getFileExtension from 'utils/fileExtension';
import { FinancialYear, Period } from '@agoy/api-sdk-core';
import PeriodDataContext from '../PeriodDataContext';

interface DocumentPreviewItemProps {
  id: number;
  period: Period;
  groupId: string;
  document: PeriodDocument;
  onOpenPreviewModal: (id: number | string) => void;
}

const CopyButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  height: 100%;
`;

const DoubleRightIcon = styled(ArrowForward)`
  position: absolute;
  left: 24px;
  font-size: 18px;
`;

const DocumentPreviewItem = ({
  id,
  period,
  groupId,
  document,
  onOpenPreviewModal,
}: DocumentPreviewItemProps): JSX.Element => {
  const { formatMessage } = useIntl();

  const [loading, setLoading] = useState(false);
  const [documentUploadError, setDocumentUploadError] = useState('');

  const { renameDocument, copyDocument, removeReference } =
    useAccountDocuments();

  const {
    nextPeriod,
    nextPeriodFinancialYear,
    periodLocked,
    financialYear,
    yearEndPeriod,
  } = useContext(PeriodDataContext);

  const handleDeleteDocument = async () => {
    try {
      setLoading(true);
      await removeReference(document, groupId);
    } catch (error) {
      setDocumentUploadError(
        formatMessage({ id: 'hidden.errorDeletingImage' })
      );
    } finally {
      setLoading(false);
    }
  };

  const copyDocumentTo = async (
    newFinancialYear: FinancialYear,
    newPeriod: Period
  ) => {
    try {
      setLoading(true);
      if (financialYear) {
        await copyDocument(document, newFinancialYear, newPeriod);
      }
    } catch (error) {
      setDocumentUploadError(formatMessage({ id: 'hidden.errorCopyingImage' }));
    } finally {
      setLoading(false);
    }
  };

  const handleCopyToNextPeriod = async () => {
    if (nextPeriod && nextPeriodFinancialYear) {
      await copyDocumentTo(nextPeriodFinancialYear, nextPeriod);
    }
  };

  const handleCopyToLastPeriod = async () => {
    if (financialYear && yearEndPeriod) {
      await copyDocumentTo(financialYear, yearEndPeriod);
    }
  };

  const handleDocumentNameSave = async (newDocumentName: string) => {
    try {
      setLoading(true);
      if (financialYear) {
        await renameDocument(
          document,
          `${newDocumentName}${getFileExtension(document.name)}`
        );
      }
      setDocumentUploadError('');
    } catch (error) {
      setDocumentUploadError(
        formatMessage({ id: 'hidden.errorRenamingImage' })
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <DocumentPreview
      id={id}
      loading={loading}
      uploading={!!document.uploading}
      document={document}
      onEditName={handleDocumentNameSave}
      onDelete={handleDeleteDocument}
      deleteDisabled={periodLocked}
      onOpenPreviewModal={onOpenPreviewModal}
      errorMessage={documentUploadError}
      columnButtons
      extraOverlayContent={
        <CopyButtons>
          <Tooltip
            title={formatMessage({ id: 'hidden.transactions.copyToNext' })}
          >
            <span>
              <IconButton
                color="primary"
                disabled={!nextPeriod || !!document.uploading || loading}
                onClick={handleCopyToNextPeriod}
              >
                <Arrow />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip
            title={formatMessage(
              { id: 'hidden.transactions.commentToEnd' },
              { target: formatMessage({ id: 'periodType.yearEndPeriod' }) }
            )}
          >
            <span>
              <IconButton
                color="primary"
                disabled={
                  yearEndPeriod.id === period.id ||
                  !!document.uploading ||
                  loading
                }
                onClick={handleCopyToLastPeriod}
              >
                <DoubleRightIcon />
                <Arrow />
              </IconButton>
            </span>
          </Tooltip>
        </CopyButtons>
      }
    />
  );
};

export default DocumentPreviewItem;

import { isApiErrorType } from 'api-sdk';
import { getAccountingBalances } from 'Api/Client/accounting';
import { createContext } from 'react';
import { Dispatch } from 'redux';
import { RootState } from 'redux/reducers';
import { Observable } from 'rxjs';
import { AccountingBalances } from 'types/Accounting';
import { UserError } from 'types/Error/types';
import { setAccountingBalances } from '_reconciliation/redux/accounting-view/actions';
import { AccountingViewState } from '_reconciliation/redux/accounting-view/reducer';
import { createProvider, ReduxDataSource, DataSource } from './ReduxSource';

class ReduxAccountingBalancesSource extends ReduxDataSource<
  AccountingBalances | null,
  AccountingViewState
> {
  dispatch: Dispatch<any>;

  constructor(state: Observable<AccountingViewState>, dispatch: Dispatch<any>) {
    super(state, 'AccountingBalances');
    this.dispatch = dispatch;
  }

  // eslint-disable-next-line class-methods-use-this
  mapStateToData(
    clientId: string,
    period: string
  ): (state: AccountingViewState) => AccountingBalances | null | undefined {
    return (state) => {
      const year = state.clients[clientId]?.years[period];
      if (year === null) {
        return null;
      }
      return year?.accountingBalances;
    };
  }

  async onMissingData(clientId: string, financialYear: string): Promise<void> {
    try {
      const result = await getAccountingBalances(clientId, financialYear);

      this.dispatch(
        setAccountingBalances(
          clientId,
          financialYear,
          result ? result.accountingBalances : null,
          result ? result.updatedAt : Date.now()
        )
      );
    } catch (error) {
      if (isApiErrorType(error)) {
        if (error.status === 403) {
          throw new UserError('sie.error.unauthorized');
        }
      }
      throw error;
    }
  }

  set() {
    // eslint-disable-next-line no-console
    console.warn('ReduxAccountingBalancesSource.set is not implemented');
  }
}

export const AccountingBalancesSourceContext = createContext<
  DataSource<AccountingBalances | null>
>({} as unknown as DataSource<AccountingBalances>);

const selectState = (rootState: RootState) => rootState.accountingView;

export const Provider = createProvider(
  AccountingBalancesSourceContext,
  selectState,
  (subject, dispatch) => new ReduxAccountingBalancesSource(subject, dispatch)
);

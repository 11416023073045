import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { Box } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { numberValue, stringValue } from '@agoy/document';
import styled from '@emotion/styled';
import DocumentViewServiceContext from '_shared/services/document/DocumentViewServiceContext';
import Alert from '_shared/components/AlertBanners/Alert';
import { AnnualGeneralMeeting as AnnualGeneralMeetingType } from '@agoy/annual-report-document';
import DeleteButton from '_shared/components/Buttons/DeleteButton';
import EditField from '_shared/components/Inputs/EditField';
import Paragraph from './ResultDispositionParagraph';
import GridRow from '../GridRow';
// eslint-disable-next-line import/no-cycle
import {
  AnnualGreetingAddButton,
  PaddingTop,
  StyledNameField,
  TypographyPreview,
} from '../AnnualGeneralMeeting';

const ResultContent = styled.div`
  display: grid;
  grid-template-columns: auto fit-content(100%);
`;

const ResultTable = styled.div`
  display: grid;
  grid-template-columns: 80% auto;
  gap: ${(props) => props.theme.spacing(2)}px
    ${(props) => props.theme.spacing(2)}px;
  width: 400px;
  margin-bottom: 10px;

  & > :nth-of-type(n + 1) {
    font-weight: 400;
    font-size: 1rem;
  }
`;

interface ResultDispositionProps {
  no: number;
  annualGeneralMeeting: AnnualGeneralMeetingType;
  print: boolean;
  result: string | boolean;
  isPreview?: boolean;
  editable?: boolean;
  isMatchingResultDisposition?: boolean;
}
const ResultDisposition = ({
  no,
  annualGeneralMeeting,
  print,
  result,
  isPreview,
  editable,
  isMatchingResultDisposition = true,
}: ResultDispositionProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const service = useContext(DocumentViewServiceContext);

  const agmApprovedResultsDisposition =
    stringValue(annualGeneralMeeting.main.agmApprovedResultsDisposition) ||
    'yes';

  const handleResultsDispositionText = (newValue) => {
    service.updateField(
      'annualGeneralMeeting.main.resultsDispositionText',
      newValue
    );
  };

  const handleToggleResultsDispositionText = () => {
    service.toggleFieldActive(
      'annualGeneralMeeting.main.resultsDispositionText'
    );
  };

  const resultsDispositionText =
    stringValue(annualGeneralMeeting.main.resultsDispositionText) ||
    formatMessage({ id: 'annualReport.annualGeneralMeeting.main.8.text2' });

  const renderApprovedResultsDisposition = (
    editingParagraph: boolean
  ): JSX.Element => {
    return (
      <>
        <ResultTable>
          {annualGeneralMeeting.main.resultsDisposition.rows.map((row) => (
            <GridRow
              key={row.id}
              baseId="annualGeneralMeeting.main.resultsDisposition"
              row={row}
              columns={annualGeneralMeeting.main.resultsDisposition.columns}
              editing={editingParagraph}
              print={print}
            />
          ))}
        </ResultTable>
        {!isMatchingResultDisposition && (
          <Alert type="warning">
            {formatMessage({
              id: 'annualReport.not.matching.resultDisposition.warning',
            })}
          </Alert>
        )}
        {editingParagraph ? (
          <>
            <PaddingTop>
              {annualGeneralMeeting.main.resultsDispositionText.active ? (
                <ResultContent>
                  <EditField
                    component={StyledNameField}
                    onChange={handleResultsDispositionText}
                    value={resultsDispositionText}
                    placeholder={resultsDispositionText}
                  />
                  <DeleteButton onClick={handleToggleResultsDispositionText} />
                </ResultContent>
              ) : (
                <Box>
                  <AnnualGreetingAddButton
                    startIcon={<AddIcon />}
                    onClick={handleToggleResultsDispositionText}
                  >
                    {formatMessage({
                      id: 'add.row',
                    })}
                  </AnnualGreetingAddButton>
                </Box>
              )}
            </PaddingTop>
          </>
        ) : (
          <>
            {annualGeneralMeeting.main.resultsDispositionText.active && (
              <Box marginY={2}>
                <TypographyPreview>{resultsDispositionText}</TypographyPreview>
              </Box>
            )}
          </>
        )}
      </>
    );
  };

  const renderRejectedResultsDisposition = (
    editingParagraph: boolean
  ): JSX.Element => {
    return (
      <>
        <ResultTable>
          {annualGeneralMeeting.main.resultsDispositionRejected.rows.map(
            (row) => {
              const cellValue = numberValue(row.cells?.value);
              return cellValue !== 0 || editingParagraph ? (
                <GridRow
                  key={row.id}
                  baseId="annualGeneralMeeting.main.resultsDispositionRejected"
                  row={row}
                  columns={
                    annualGeneralMeeting.main.resultsDispositionRejected.columns
                  }
                  editing={editingParagraph}
                  print={print}
                />
              ) : null;
            }
          )}
        </ResultTable>
      </>
    );
  };

  return (
    <Paragraph
      annualGeneralMeeting={annualGeneralMeeting}
      no={no}
      id="8"
      print={print}
      editable={!print && editable}
      result={result}
      agmApprovedResultsDisposition={agmApprovedResultsDisposition}
      isPreview={isPreview}
    >
      {(editingParagraph) => (
        <Box marginY={2} width={800}>
          {agmApprovedResultsDisposition === 'yes'
            ? renderApprovedResultsDisposition(editingParagraph)
            : renderRejectedResultsDisposition(editingParagraph)}
        </Box>
      )}
    </Paragraph>
  );
};

export default ResultDisposition;

import { AgoyTable, TableChange } from '../..';
import { Errors } from '../types';

const updateTable = (
  table: AgoyTable,
  changes: TableChange | undefined,
  updater: (
    table: AgoyTable,
    change: TableChange
  ) => [AgoyTable, TableChange] | false | Errors
): [AgoyTable, TableChange] | false | Errors => {
  const tableChange: TableChange = changes || { type: 'update' };

  const tableUpdateResult = updater(table, tableChange);
  if (!Array.isArray(tableUpdateResult)) {
    return tableUpdateResult;
  }

  const [newTable] = tableUpdateResult;

  if (newTable === table) {
    return false;
  }
  return tableUpdateResult;
};

export default updateTable;

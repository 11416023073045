import { ClientCompany } from '@agoy/api-sdk-core';
import { getIntervals, PeriodType } from '@agoy/reconciliation';
import {
  eachQuarterOfInterval,
  endOfQuarter,
  eachMonthOfInterval,
  endOfMonth,
} from 'date-fns';

type FinancialYears = ClientCompany['financialYears'];

export const getType = (closingPeriod: string | null): PeriodType => {
  if (closingPeriod === 'year') {
    return 'financialYear';
  }

  return closingPeriod as 'quarter' | 'month';
};

/**
 * Returns the quarter period that intersect with the
 * current financial year
 */
export const getQuarters = (
  clientId: string,
  financialYears: FinancialYears,
  current: FinancialYears[number]
) =>
  getIntervals(
    'quarter',
    clientId,
    financialYears,
    current,
    eachQuarterOfInterval,
    endOfQuarter
  );

/**
 * Returns the month periods that intersect with the
 * current financial year
 */
export const getMonths = (
  clientId: string,
  financialYears: FinancialYears,
  current: FinancialYears[number]
) =>
  getIntervals(
    'month',
    clientId,
    financialYears,
    current,
    eachMonthOfInterval,
    endOfMonth
  );

export const getGroupedPeriods = (
  clientId: string,
  periodType: PeriodType,
  financialYear: FinancialYears[number],
  financialYears: FinancialYears
) => {
  if (periodType === 'financialYear') {
    return [
      {
        type: 'financialYear' as const,
        start: financialYear.start,
        end: financialYear.end,
        clientId,
        periods:
          financialYear.periods
            ?.filter((p) => p.type === 'month')
            .map((p) => ({
              ...p,
              financialYear,
            })) ?? [],
        financialYears: [financialYear],
      },
    ];
  }
  if (periodType === 'quarter') {
    return getQuarters(clientId, financialYears, financialYear);
  }
  return getMonths(clientId, financialYears, financialYear);
};

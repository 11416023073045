import {
  BooleanCell,
  Cell,
  FormatMessageCell,
  MultiReferenceCell,
  NumberCell,
  ReferenceCell,
  StringCell,
} from '../Cell';
import { AgoyTableRow } from './table';
import {
  AgoyDocumentStructureNode,
  AgoyDocumentStructurePart,
  AgoyDocumentStructureTable,
  AgoyDocumentStructureField,
  AgoyDocumentStructureBoolean,
  AgoyDocumentStructureNumber,
  AgoyDocumentStructureArray,
  AgoyDocumentStructureExternalDocument,
  AgoyDocumentStructureExternalDocuments,
} from './document';
import { AgoyDocumentStructure } from '.';
import { Source } from '../types/source';

export type FieldUpdate = (
  | Omit<ReferenceCell, 'value'>
  | StringCell
  | NumberCell
  | BooleanCell
  | Omit<FormatMessageCell, 'value'>
) & { active?: boolean };

export interface AddRowChange {
  type: 'add';
  id: string;
  row?: AgoyTableRow; // deprecated, changes in added rows now come as updates after
  rows?: RowChange[];
  params?: Record<string, string>;
}

export interface DeleteRowChange {
  type: 'delete';
  id: string;
}

export type CellUpdate =
  | Omit<ReferenceCell, 'value'>
  | Omit<MultiReferenceCell, 'values'>
  | StringCell
  | NumberCell
  | BooleanCell;

export interface RowUpdate {
  active: boolean;
  cells?: Record<string, CellUpdate>;
  sortKey?: number;
}

export interface UpdateRowChange {
  type: 'update';
  id: string;
  row?: Partial<RowUpdate>;
  rows?: RowChange[];
}

export type RowChange = AddRowChange | DeleteRowChange | UpdateRowChange;

interface AddColumnChange {
  type: 'add';
  id: string;
  label?: string;
  index: number;
  cellType: 'string' | 'number';
  sortKey?: number;
  active?: boolean;
}

interface DeleteColumnChange {
  type: 'delete';
  id: string;
}

interface UpdateColumnChange {
  type: 'update';
  id: string;
  label?: string;
  sortKey?: number;
  active?: boolean;
}

export type ColumnChange =
  | AddColumnChange
  | DeleteColumnChange
  | UpdateColumnChange;

export interface TableChange {
  type: 'update';
  rows?: RowChange[];
  columns?: ColumnChange[];
  active?: boolean;
  source?: Source;
}

export type AgoyDocumentPartChanges<T extends AgoyDocumentStructurePart> =
  Partial<
    {
      [part in keyof T['children']]: AgoyDocumentNodeChanges<
        T['children'][part]
      >;
    }
  >;

export type AgoyDocumentArrayChanges<T extends AgoyDocumentStructureArray> =
  Record<string, Partial<AgoyDocumentPartChanges<T['children']>> | null>;

export type AgoyDocumentNodeChanges<T extends AgoyDocumentStructureNode> =
  T extends AgoyDocumentStructurePart
    ? AgoyDocumentPartChanges<T>
    : T extends AgoyDocumentStructureTable
    ? TableChange
    : T extends AgoyDocumentStructureField
    ? FieldUpdate
    : T extends AgoyDocumentStructureBoolean
    ? boolean
    : T extends AgoyDocumentStructureNumber
    ? number
    : T extends AgoyDocumentStructureArray
    ? AgoyDocumentArrayChanges<T>
    : T extends AgoyDocumentStructureExternalDocument
    ? undefined // No changes are created for the external documents
    : T extends AgoyDocumentStructureExternalDocuments
    ? undefined // No changes are created for the external documents
    : never;

export type AgoyDocumentChanges<T extends AgoyDocumentStructure> = Partial<
  {
    [part in keyof T['children']]: AgoyDocumentNodeChanges<T['children'][part]>;
  }
>;

export function isRowChange(
  change: TableChange | RowChange
): change is RowChange {
  return 'id' in change;
}

export function isUpdateRowChange(
  change: RowChange
): change is UpdateRowChange {
  return change.type === 'update';
}

export function isTableChange(
  change: TableChange | Cell
): change is TableChange {
  return change.type === 'update';
}

export function isCell(change: TableChange | Cell): change is Cell {
  return (
    change.type === 'string' ||
    change.type === 'number' ||
    change.type === 'ref' ||
    change.type === 'refs' ||
    change.type === 'msg' ||
    change.type === 'label'
  );
}

import { Field } from '@agoy/document';
import { ClientInformationField } from '@agoy/api-sdk-core';

export const SUPPORTED_SOURCE_TYPES = ['cs', 'user'];

export type ClientInformationSource = Pick<
  ClientInformationField,
  'source' | 'userId' | 'timestamp'
>;

export type SourceInfo<T> = {
  /**
   * id of the field that is, originally, referencing a field in settings.clientInformation
   */
  id: string;

  /**
   * The source, if the field is unedited this will be the source of the field in settings.clientInformation.
   * When edited this should be the source of the edited field that used to reference clientInformation.
   */
  source: Field['source'];

  /**
   * The id of the referenced field in settings.clientInformation if applicable.
   */
  clientInformationFieldId?: string;

  /**
   * Source information of the field in the current ClientInformation for the client.
   */
  clientInformationSource: ClientInformationSource;

  /**
   * The current value of the field in ClientInformation
   */
  clientInformationValue: T;

  /**
   * The current value of the field in Annual Report.
   */
  value: T;
};

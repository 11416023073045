import { INIT_STATE } from '_shared/redux/action-types';
import { GlobalActions } from '_shared/redux/actions';
import { OrganisationType, Checklists } from '_organization/types';
import {
  INSERT_INVITE_TO_ORG,
  ADD_ORGANISATION_INFO,
  REMOVE_MEMBER,
  SET_ORGANISATION,
  SET_CHECKLISTS,
  ADD_CHECKLIST,
  EDIT_CHECKLIST,
  SET_SETTINGS,
  REMOVE_CHECKLIST,
} from './action-types';
import { OrganizationAction } from './actions';

type OrganisationStateType = OrganisationType & Checklists;

const initialOrganisationState: OrganisationStateType = {
  invited: [],
  users: [],
  name: null,
  organisationId: null,
  nextPayment: 0,
  checklists: [],
};

export default (
  state: OrganisationStateType = initialOrganisationState,
  action: OrganizationAction | GlobalActions
): OrganisationStateType => {
  switch (action.type) {
    case INIT_STATE:
      return action.state.organisation || state;
    case SET_ORGANISATION:
      return {
        ...state,
        ...action.organisation,
      };
    case INSERT_INVITE_TO_ORG:
      return {
        ...state,
        invited: [...state.invited, action.invite],
      };
    case ADD_ORGANISATION_INFO:
      return {
        ...state,
        ...action.organisationInfo,
      };
    case REMOVE_MEMBER:
      return {
        ...state,
        users: state.users.filter(
          (user: Member.MemberType) => user.email !== action.email
        ),
      };
    case SET_CHECKLISTS:
      return {
        ...state,
        checklists: action.checklists,
      };
    case ADD_CHECKLIST:
      return {
        ...state,
        checklists: [...state.checklists, action.checklistTemplate],
      };
    case EDIT_CHECKLIST:
      return {
        ...state,
        checklists: state.checklists.map((checklist) =>
          checklist.id === action.checklistTemplate.id
            ? action.checklistTemplate
            : checklist
        ),
      };
    case REMOVE_CHECKLIST:
      return {
        ...state,
        checklists: state.checklists.filter(
          (checklist) => checklist.id !== action.checklistTemplate.id
        ),
      };
    case SET_SETTINGS:
      return {
        ...state,
        settings: action.settings,
      };
    default:
      return state;
  }
};

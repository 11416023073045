import React from 'react';
import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';

const MainWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 60px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${(props) => props.theme.spacing(3)}px;
`;

const InformationSectionLabelWrapper = styled.div`
  margin-right: 32px;
  font-size: ${(props) => props.theme.typography.fontSize};
`;

interface ClientCardProps {
  label: string;
  children: React.ReactNode;
}

const ClientCard = ({ label, children }: ClientCardProps) => {
  return (
    <div>
      <MainWrapper>
        <InformationSectionLabelWrapper>
          <Typography variant="h2">{label}</Typography>
        </InformationSectionLabelWrapper>
      </MainWrapper>
      {children}
    </div>
  );
};

export default ClientCard;

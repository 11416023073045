import React, { useMemo } from 'react';
import { AgoyTable, AgoyTableColumn } from '@agoy/document';
import { useIntl } from 'react-intl';
import { getClasses } from '@agoy/common';
import { BaseRow, Table, TableBody, TableHeadCell } from './TableComponents';
import ReportTableRow from './ReportTableRow';

const PRINT_WIDTH = 450;
const REGULAR_WIDTH = 250;

export interface ReportTableProps {
  tableId: string;
  table: AgoyTable;
  columnLabelFormatter?: (columnCell: AgoyTableColumn) => React.ReactNode;
  skipColumns: string[];
  print?: boolean;
}

const ReportTable = ({
  tableId,
  table,
  columnLabelFormatter,
  skipColumns,
  print,
}: ReportTableProps) => {
  const { formatMessage } = useIntl();

  const { rows, columns } = table;

  const filteredColumns = useMemo(
    () => columns.filter((col) => !skipColumns.includes(col.id)),
    [columns, skipColumns]
  );

  const getTableColumnLabel = (cell: AgoyTableColumn): React.ReactNode => {
    const { id, label } = cell;
    const formattedLabel = columnLabelFormatter
      ? columnLabelFormatter(cell)
      : label;

    return (
      formattedLabel ??
      formatMessage({
        id: `annualReport.${tableId}.${id}`,
        defaultMessage: id,
      })
    );
  };

  const cellWidth = print ? PRINT_WIDTH : REGULAR_WIDTH;

  const classNames = getClasses({
    'is-print': !!print,
  });

  return (
    <Table className={classNames}>
      <thead>
        <BaseRow>
          {filteredColumns.map((col, index) => (
            <TableHeadCell
              key={col.id}
              cellWidth={index === 0 ? cellWidth : undefined}
            >
              {index !== 0 ? getTableColumnLabel(col) : null}
            </TableHeadCell>
          ))}
        </BaseRow>
      </thead>
      <TableBody>
        {rows.map((row) => (
          <ReportTableRow
            key={row.id}
            baseId={tableId}
            row={row}
            columns={filteredColumns}
            level={1}
          />
        ))}
      </TableBody>
    </Table>
  );
};

export default ReportTable;

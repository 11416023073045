/**
 * Definition of settings section for the confirmationCertificate document.
 */
export const settings = {
  type: 'part' as const,
  children: {
    annualReportSigning: { type: 'field' as const },
    annualGeneralMeetingSigning: { type: 'field' as const },
  },
};

import React, { useState, useRef } from 'react';
import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';
import { Document, Page } from 'react-pdf/dist/esm/entry.vite';
import {
  isImageDocument,
  isPdfDocument,
} from '_reconciliation/components/ReconciliationView/HiddenRow/HiddenGroupRow/utils/documentTypes';

const ORIGINAL_PDF_HEIGHT = 792;
const PRINT_PDF_WIDTH = 784;

const Image = styled.img`
  break-inside: avoid;
  display: block;
  max-width: 100%;
  @media print {
    margin-top: ${(props) => props.theme.spacing(2)}px;
  }
`;

const ImageHeading = styled(Typography)`
  margin-top: ${(props) => props.theme.spacing(2)}px;
  @media print {
    display: none;
  }
`;

const PdfPreview = styled(Document)`
  width: 100%;
  @media print {
    padding-bottom: ${(props) => props.theme.spacing(2)}px;
  }
`;

const PdfPage = styled(Page)`
  margin-top: ${(props) => props.theme.spacing(2)}px;
  .react-pdf__Page__textContent {
    overflow: hidden;
  }
  @media print {
    margin-top: 0;
    page-break-inside: avoid;
  }
`;

type DocumentComponentProps = {
  document: {
    name: string;
    url: string;
  };
  pdfPageSize?: {
    height?: number;
    width?: number;
  };
  onLoad?: () => void;
  className?: string;
  hideTitle?: boolean;
};

const getFileExtension = (file) => {
  const dotIndex = file.name.lastIndexOf('.');
  let extension = '';

  if (dotIndex !== -1) {
    extension = file.name.substr(dotIndex);
  }

  return extension;
};

const DocumentComponent = ({
  document,
  pdfPageSize = {
    width: 784,
  },
  onLoad = () => {},
  className,
  hideTitle,
}: DocumentComponentProps): JSX.Element | null => {
  const pagesToLoad = useRef(0);

  const [documentsPages, setDocumentsPages] = useState(0);
  const [pageHeightDifference, setPageHeightDifference] = useState(0);

  const handlePageRender = () => {
    pagesToLoad.current -= 1;
    if (pagesToLoad.current === 0) {
      onLoad();
    }
  };

  const handlePDFLoading = async (pdfInfo) => {
    const page = await pdfInfo.getPage(1);
    const pageHeight = page.view[3]; // pdf page height accessible only from view instance;
    const pageWidth = page.view[2];
    /**
     * * Two times multiply because of the bug, that appears if pageHeight two times more than original pdf size;
     * TODO: Need to check with other PDF files (other size-types);
     * TODO: Create a validation for incorrect file size documents, while uploading PDF into app;
     */
    if (pageHeight > ORIGINAL_PDF_HEIGHT * 2) {
      const aspectRatio = pageWidth / (pdfPageSize.width || PRINT_PDF_WIDTH);
      const calculatedPdfPageHeight = pageHeight / aspectRatio;
      setPageHeightDifference(calculatedPdfPageHeight);
    }
    pagesToLoad.current = pdfInfo.numPages;
    setDocumentsPages(pdfInfo.numPages);
  };

  const extension = getFileExtension(document);

  if (isImageDocument(extension)) {
    return (
      <>
        {!hideTitle && <ImageHeading> {document.name}</ImageHeading>}
        <Image
          src={document.url}
          alt={document.name}
          onLoad={onLoad}
          onError={onLoad}
        />
      </>
    );
  }

  if (isPdfDocument(extension)) {
    return (
      <>
        {!hideTitle && <ImageHeading> {document.name}</ImageHeading>}
        <PdfPreview
          file={document.url}
          paddingBottom={pageHeightDifference}
          onLoadSuccess={(pdfInfo) => handlePDFLoading(pdfInfo)}
          onLoadError={onLoad}
          className={className}
        >
          {documentsPages &&
            Array.from(new Array(documentsPages), (el, index) => (
              <PdfPage
                width={pdfPageSize.width}
                height={pdfPageSize.height}
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                onRenderSuccess={handlePageRender}
                onRenderError={handlePageRender}
                renderAnnotationLayer={false}
                renderForms={false}
                renderTextLayer={false}
              />
            ))}
        </PdfPreview>
      </>
    );
  }

  return null;
};

export default DocumentComponent;

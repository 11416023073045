import React from 'react';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import regression, { DataPoint } from 'regression';

import { ccyFormat } from '@agoy/common';

// Generate linear regression with trending variables
const prepareRegressionData = (data: number[]): ReadonlyArray<DataPoint> => {
  const result = regression.linear(data.map((el, i) => [i, el]));
  return result.points;
};

interface CashFlowDiagramProps {
  periods: string[];
  data: number[];
  print?: boolean;
  height?: number;
}

// A two-series diagram displaying a summary of the company's inflows and outflows per month and it's trend
const CashFlowDiagram = ({
  periods,
  data,
  print = false,
  height,
}: CashFlowDiagramProps): JSX.Element => {
  const maxValueOfY = Math.round(Math.max.apply(null, data));
  let tickeketY = 100000;
  if (maxValueOfY < 100000) {
    tickeketY = 10000;
  }
  if (maxValueOfY < 10000) {
    tickeketY = 1000;
  }
  if (maxValueOfY < 1000) {
    tickeketY = 100;
  }
  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={{
        chart: {
          height,
          style: { 'font-family': "'Exo 2'" },
        },
        title: {
          text: null,
          margin: 0,
        },
        plotOptions: {
          series: {
            animation: false,
            grouping: false,
            borderWidth: 0.2,
            borderColor: '#f8af61',
            padding: 10,
          },
        },
        xAxis: {
          categories: periods,
          labels: {
            enabled: false,
          },
        },
        yAxis: {
          title: {
            text: null,
          },
          labels: {
            style: {
              fontSize: print ? '1rem' : '0.5rem',
            },
            formatter: ({ value }) => {
              return value < 0
                ? `<span style="color:rgb(192,0,0)">${ccyFormat(
                    value,
                    0
                  )}</span>`
                : ccyFormat(value, 0);
            },
          },
          tickInterval: tickeketY,
          plotLines: [
            {
              color: 'black',
              width: 0.3,
              value: 0,
              zIndex: 3,
            },
          ],
        },
        series: [
          {
            name: 'Kassaflöde',
            type: 'column',
            color: '#f8af61',
            data,
          },
          {
            name: 'Linjär (Kassaflöde)',
            color: 'black',
            type: 'line',
            dashStyle: 'Dash',
            lineWidth: 1,
            marker: {
              enabled: false,
            },
            data: prepareRegressionData(data),
          },
        ],
        legend: {
          layout: 'horizontal',
          align: 'center',
          verticalAlign: 'top',
          margin: 0,
        },
        lang: {
          thousandsSep: '',
        },
        credits: {
          enabled: false,
        },
        tooltip: {
          valueDecimals: 0,
        },
      }}
    />
  );
};

export default CashFlowDiagram;

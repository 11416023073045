import React from 'react';
import styled from '@emotion/styled';
// @ts-ignore
import { ReactComponent as MaintenanceImage } from 'assets/maintenance_fortnox.svg';
import Typography from '_shared/components/Typography/Typography';
import Button from '_shared/components/Buttons/Button';
import { OpenInNew } from '@material-ui/icons';

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 500px;
  button {
    margin-top: 8px;
    margin-bottom: 8px;
  }
`;

const Image = styled(MaintenanceImage)`
  width: 250px;
  height: auto;
`;

const MaintenancePage = (): JSX.Element => {
  return (
    <Wrapper>
      <Content>
        <Image title="" />
        <Typography variant="h1" margin="bottom">
          Ursäkta, här blev det lite fel.
        </Typography>
        <Typography variant="body1">
          Vi vet om felet och felsöker vad som kan ha gått snett. Prova gärna
          att uppdatera sidan igen om några minuter. Om det fortfarande inte
          fungerar kan du kika på vår driftinformation nedan.
        </Typography>
        <Button
          onClick={() =>
            window.open(
              'https://status.fortnox.se/',
              '_blank',
              'noopener,noreferrer'
            )
          }
          label="Se driftstatus på status.fortnox.se"
          endIcon={<OpenInNew />}
        />
        <Button
          label="Försök igen"
          onClick={() => window.location.reload()}
          variant="outlined"
        />
      </Content>
    </Wrapper>
  );
};

export default MaintenancePage;

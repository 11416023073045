import updateDocument from './helpers/updateDocument';
import { AgoyDocumentStructure } from '../document';
import { OperationResult, State } from './types';

const addAnnualReportSection = <T extends AgoyDocumentStructure>(
  structure: T,
  state: State<T>,
  id: string
): OperationResult<T> => {
  return updateDocument(structure, state, id, {
    array: (k, id, props) => {
      return {
        ...props,
        node: {
          ...props.node,
          sections: [
            ...props.node.sections,
            typeof props.node.newSectionTemplate === 'function'
              ? props.node.newSectionTemplate(
                  `${id}-${props.node.sections.length}`
                )
              : props.node.newSectionTemplate,
          ],
        },
        changes: { ...props.changes, [props.node.sections.length]: {} },
      };
    },
  });
};

export default addAnnualReportSection;

import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { batch } from 'react-redux';

import { addGlobalErrorMessage, ClientAction } from 'redux/actions';
import { RootState } from 'redux/reducers';
import { getContext } from 'utils/AgoyAppClient/contextHolder';
import { SieData } from '_reconciliation/types';
import Optional from 'utils/Optional';
import { Program } from '_shared/types';
import { getApiSdk, isApiErrorType } from 'api-sdk';
import {
  INIT_STATE,
  RESET_UNSAFE_CLIENT_DATA,
  SET_CURRENT_PERIOD,
  SET_PROGRAM_PERIODS_STATUS,
  SET_NO_SIE_DATA,
  SET_PROGRAM_FINANCIAL_YEAR_STATUS,
  ProgramFinancialYearStatuses,
  ProgramPeriodStatuses,
} from './action-types';

// Global actions, reacted to by multiple reducers

interface InitStateAction {
  type: typeof INIT_STATE;
  state: Partial<RootState>;
}

export const initState = (state: Partial<RootState>): InitStateAction => ({
  type: INIT_STATE,
  state,
});

export type SetSieDataArg = Optional<SieData, 'accounts'>;

export interface SetCurrentPeriodAction {
  type: typeof SET_CURRENT_PERIOD;
  clientId: string;
  financialYear: string;
  period: string;
}

export interface SetNoSieDataAction {
  type: typeof SET_NO_SIE_DATA;
  clientId: string;
  year: string;
}

export const setNoSieData = (
  clientId: string,
  year: string
): SetNoSieDataAction => ({
  type: SET_NO_SIE_DATA,
  clientId,
  year,
});

export const setCurrentPeriodAction = (
  clientId: string,
  financialYear: string,
  period: string
): SetCurrentPeriodAction => ({
  type: SET_CURRENT_PERIOD,
  clientId,
  financialYear,
  period,
});

interface ResetUnsafeClientData {
  type: typeof RESET_UNSAFE_CLIENT_DATA;
}

/**
 * Used for resetting the customer view
 */
export const resetUnsafeClientData = (): ResetUnsafeClientData => ({
  type: RESET_UNSAFE_CLIENT_DATA,
});

const setProgramPeriodsStatusAction = (
  clientId: string,
  periods: ProgramPeriodStatuses,
  program: Program
): SetProgramPeriodsStatus => ({
  clientId,
  type: SET_PROGRAM_PERIODS_STATUS,
  periods,
  program,
});

const setProgramFinancialYearStatusesAction = (
  clientId: string,
  status: ProgramFinancialYearStatuses,
  program: Program
): SetProgramFinancialYearStatus => ({
  clientId,
  type: SET_PROGRAM_FINANCIAL_YEAR_STATUS,
  status,
  program,
});

/**
 *  Fetches the program status for a specific program.
 *
 * @param clientId
 * @param program the AGOY program that uses statuses; typed in ProgramPeriodStatus
 * @returns
 */
export const fetchProgramStatus =
  (
    clientId: string,
    program: Program,
    isPerson?: boolean
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    const sdk = getApiSdk(getContext());

    try {
      if (!isPerson) {
        const statuses = await sdk.getProgramStatuses({
          clientIds: [clientId],
          program,
        });
        const periodStatuses = statuses[clientId]?.filter(
          (status): status is ProgramPeriodStatuses[number] =>
            typeof status.periodId === 'number'
        );

        dispatch(
          setProgramPeriodsStatusAction(clientId, periodStatuses ?? [], program)
        );

        const yearsStatuses = statuses[clientId]?.filter(
          (status): status is ProgramFinancialYearStatuses[number] =>
            typeof status.financialYearId === 'number'
        );
        dispatch(
          setProgramFinancialYearStatusesAction(
            clientId,
            yearsStatuses ?? [],
            program
          )
        );
      }
    } catch (err) {
      if (!isApiErrorType(err) || !err.handled) {
        // eslint-disable-next-line no-console
        console.warn(err);
        dispatch(addGlobalErrorMessage('error'));
      }
    }
  };

export interface SetProgramPeriodsStatus extends ClientAction {
  type: typeof SET_PROGRAM_PERIODS_STATUS;
  periods: ProgramPeriodStatuses;
  program: Program;
}

export interface SetProgramFinancialYearStatus extends ClientAction {
  type: typeof SET_PROGRAM_FINANCIAL_YEAR_STATUS;
  status: ProgramFinancialYearStatuses;
  program: Program;
}

export type GlobalActions =
  | ResetUnsafeClientData
  | InitStateAction
  | SetCurrentPeriodAction
  | SetNoSieDataAction
  | SetProgramPeriodsStatus
  | SetProgramFinancialYearStatus;

import { ApiReturnType } from 'api-sdk';
import {
  FormStatus,
  TaxDeclarationForm,
} from 'redux/reducers/TaxDeclarationView/types';
import {
  TaxDeclarationFormData,
  isTaxDeclarationFormPartData,
} from '@agoy/tax-declaration-document';
import { Cell, stringValue } from '@agoy/document';
import Optional from 'utils/Optional';
import { isDefined } from 'utils/filter';
import { SRUForm } from './generateSRU/types';

/**
 * Previous (during 2021) the forms had statuses that was not aligned with program statuses.
 * If any old form should exist with old statuses we map that to NOT_STARTED so the user can
 * use the correct status.
 *
 * @param status form status
 * @returns
 */
const toFormStatus = (status: string | null): FormStatus => {
  switch (status) {
    case 'DONE':
    case 'NOT_STARTED':
    case 'STARTED':
    case 'AW_COMPL':
    case 'AW_REVIEW':
      return status;
    case 'AWAITING_RECORDS':
    case 'RECORDS_RECEIVED':
    default:
      return 'NOT_STARTED';
  }
};

const toFormDescription = (description: string | null | undefined) => {
  if (!description) {
    return '';
  }

  return description;
};

export const mapStoredForm = (
  storedForm: Optional<
    ApiReturnType<'getTaxDeclarationDocuments'>[number],
    'relatedClients'
  >
): TaxDeclarationForm => ({
  id: storedForm.id,
  status: toFormStatus(storedForm.status),
  description: toFormDescription(storedForm.description),
  type: storedForm.type,
  name: storedForm.name || storedForm.type,
  title: storedForm.title,
  subtitle: storedForm.subtitle,
  checked: storedForm.checked || false,
  relatedClient: storedForm.relatedClients,
});

export const mapCellToSRUValue = (cell: Cell): string | undefined => {
  switch (cell.type) {
    case 'number':
    case 'ref':
      switch (typeof cell.value) {
        case 'number':
          return Math.round(cell.value)
            ? Math.round(cell.value).toString()
            : undefined;
        case 'boolean':
          return cell.value ? 'X' : undefined;
        case 'string':
          if (cell.value === '') return undefined;
          return cell.value;
        default:
          return undefined;
      }
    case 'string':
      if (cell.value === '') return undefined;
      return cell.value;
    case 'boolean':
      return cell.value ? 'X' : undefined;
    default:
      return undefined;
  }
};

const mapToSRU = (partId, clientName, orgNr, created, sruFields): SRUForm => {
  return {
    id: partId,
    companyName: clientName,
    organisationNumber: orgNr,
    created,
    records: Object.keys(sruFields)
      .sort()
      .map((key) => {
        const value = mapCellToSRUValue(sruFields[key]);
        if (value === undefined) {
          return undefined;
        }
        return {
          sruKey: key,
          value,
        };
      })
      .filter(isDefined),
  };
};

const mapINK4DUToSRU = (
  partId,
  clientName,
  orgNr,
  created,
  formFields,
  sruFields,
  partnerRowIndex
): SRUForm => {
  const sruRecords = Object.keys(sruFields)
    .sort()
    .map((key) => {
      const value = mapCellToSRUValue(sruFields[key]);
      if (value === undefined) {
        return undefined;
      }
      return {
        sruKey: key,
        value,
      };
    })
    .filter(isDefined);

  const partnerSruFields = ['8100', '8101', '8102', '8103', '8104', '8105'];
  const partnerRecords = partnerSruFields
    .map((sruKey, index) => {
      const formFieldKey = `partnerSlot${partnerRowIndex}_col${index + 1}`;
      const value = mapCellToSRUValue(formFields[formFieldKey]);
      if (value === undefined) {
        return undefined;
      }
      return {
        sruKey,
        value,
      };
    })
    .filter(isDefined);

  return {
    id: partId,
    companyName: clientName,
    organisationNumber: orgNr,
    created,
    records: [...sruRecords, ...partnerRecords],
  };
};

/**
 * Generate SRU forms given formdata (fields, sru fields)
 * This function also covers the edge case of form-part repeption
 * during SRU generation, seen in DU part of INK4
 *
 * @param clientName name of client
 * @param orgNr organisation Number
 * @param formData data including all parts of given form
 * @param created created date
 * @returns array of SRU forms
 */
export const mapToSRUForms = (
  clientName: string,
  orgNr: string,
  formData: TaxDeclarationFormData,
  created: Date
): SRUForm[] => {
  const formDataKeys = Object.keys(formData);
  const formIndexINK4DU = Object.keys(formData).indexOf('INK4DU');
  const formIndexINK4 = Object.keys(formData).indexOf('INK4');

  const hasINK4DUPart = formIndexINK4DU > -1;
  const hasINK4Part = formIndexINK4 > -1;

  if (hasINK4DUPart) {
    formDataKeys.splice(formIndexINK4DU, 1);
  }

  if (hasINK4Part) {
    formDataKeys.splice(formIndexINK4, 1);
  }

  const sruForms = formDataKeys
    .map((part) => {
      const partData = formData[part];
      if (!isTaxDeclarationFormPartData(partData)) {
        return undefined;
      }
      return mapToSRU(partData.id, clientName, orgNr, created, partData.sru);
    })
    .filter(isDefined);

  let additionalForms: SRUForm[] = [];
  if (hasINK4DUPart) {
    const partINK4DU = formData.INK4DU;
    if (isTaxDeclarationFormPartData(partINK4DU)) {
      const partnerRows = Object.keys(partINK4DU.fields).filter((key) => {
        const fieldValue = stringValue(partINK4DU.fields[key]);
        return key.match('partnerSlot\\d+_col1') && fieldValue;
      });

      additionalForms = partnerRows.map((partnerRowId) => {
        const partnerRowIndex = partnerRowId.split('_')[0].substring(11);
        return mapINK4DUToSRU(
          partINK4DU.id,
          clientName,
          orgNr,
          created,
          partINK4DU.fields,
          partINK4DU.sru,
          partnerRowIndex
        );
      });
    }
  }

  return [...sruForms, ...additionalForms];
};

export const mapAndGroupStoredForms = (
  forms: Optional<
    ApiReturnType<'getTaxDeclarationDocuments'>[number],
    'relatedClients'
  >[]
): TaxDeclarationForm[] => {
  const taxForms = forms.map(
    (storedForm): [string | null, TaxDeclarationForm] => [
      storedForm.parentId,
      mapStoredForm(storedForm),
    ]
  );

  taxForms
    .filter(([parentId]) => parentId)
    .forEach(([parentId, form]) => {
      const item = taxForms.find(([, form]) => form.id === parentId);
      if (item) {
        const [, parent] = item;
        if (!parent.subForms) {
          parent.subForms = [];
        }
        parent.subForms.push(form);
      }
    });

  return taxForms.filter(([parentId]) => !parentId).map(([, form]) => form);
};

/* eslint-disable react/prop-types */
import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import styled from '@emotion/styled';
import { IconButton, Paper, Tooltip, Typography } from '@material-ui/core';
import { MoreHoriz } from '@material-ui/icons';

import { colors as ThemeColors } from '@agoy/theme';
import { translations } from '@agoy/program-status';
import { PrivatePersonWithManager, ProgramStatus } from '_person/_types/person';
import { createPeriods } from 'utils';
import { Period } from 'utils/createPeriods';

import { isSameOrAfter, parseFinancialYear, parseFormat } from '@agoy/dates';
import PersonAvatar from './PersonAvatar';

const Container = styled.div`
  margin-top: 30px;
  margin-right: 30px;
`;

const Card = styled(Paper)`
  min-height: 100px;
  cursor: pointer;
`;

const ButtonWrapper = styled.div`
  color: ${({ theme }) => theme.palette.text.primary};
  margin-top: ${(props) => props.theme.spacing(1)}px;
  align-self: stretch;
`;

const Row = styled.div`
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const RowMonths = styled(Row)`
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 5px;
`;

const Column = styled.div`
  display: flex;
`;

const PersonDetails = styled.div`
  margin-top: ${(props) => props.theme.spacing(2)}px;
  padding: 0 ${(props) => props.theme.spacing(1)}px;
`;

const Manager = styled(Typography)`
  text-align: center;
`;

const PersonName = styled(Typography)`
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.6rem;
  margin-bottom: 0.25rem;
`;

const MonthContainer = styled.div`
  flex: 1;
  text-align: center;
  color: ${(props) => props.theme.palette.grey[600]};
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 5px;
`;

const CurrentMonthContainer = styled(MonthContainer)`
  font-weight: 500;
  color: ${(props) => props.theme.palette.primary.dark};

  background-color: ${(props) =>
    props.theme.palette.overviewView.currentMonthBackground};
`;

const DeclarationMonthContainer = styled(MonthContainer)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) =>
    props.theme.palette.overviewView.closingMonthBackground};
`;

const DeclarationMonthStatusContainer = styled(MonthContainer)<{
  backgroundColor?: string;
}>`
  margin-bottom: ${(props) => props.theme.spacing(1 / 2)}px;
  min-width: 103px;
  height: 20px;
  background-color: ${(props) => props.backgroundColor};
`;

const isDeclarationMonth = (date: string) => {
  const month = parseFormat(date, 'MMM').toLocaleUpperCase();
  return month === 'DEC';
};

const checkFinancialYear = (period, financialYear) => {
  const { end } = parseFinancialYear(financialYear);
  return isSameOrAfter(end, period);
};

type TaxesStatusProps = {
  periods: Period[];
  status: ProgramStatus | undefined | null;
};
const TaxesStatus = ({ periods, status }: TaxesStatusProps): JSX.Element => {
  const { formatMessage } = useIntl();

  const Month = ({ date }): JSX.Element => {
    if (
      status &&
      isDeclarationMonth(date) &&
      checkFinancialYear(date, status.financialYear)
    ) {
      return (
        <Tooltip
          title={`${formatMessage({ id: status.program })} (${formatMessage(
            translations[status.status]
          )})`}
        >
          <DeclarationMonthStatusContainer
            backgroundColor={ThemeColors[status.status]}
          />
        </Tooltip>
      );
    }

    return <MonthContainer />;
  };

  return (
    <RowMonths>
      {periods.map((period) => (
        <Month key={period.date} date={period.date} />
      ))}
    </RowMonths>
  );
};

const Periods = ({ periods }): JSX.Element => {
  const formattedDate = (date: string) =>
    parseFormat(date, 'MMM-yy').toLocaleUpperCase();

  const isCurrentMonth = (date: string) => {
    const currentMonth = parseFormat(new Date(), 'MMM-yy').toLocaleUpperCase();
    return formattedDate(date) === currentMonth;
  };

  const Month = ({ date }): JSX.Element => {
    if (isDeclarationMonth(date)) {
      return <DeclarationMonthContainer>DEKLARATION</DeclarationMonthContainer>;
    }

    if (isCurrentMonth(date)) {
      return (
        <CurrentMonthContainer>{formattedDate(date)}</CurrentMonthContainer>
      );
    }

    return <MonthContainer>{formattedDate(date)}</MonthContainer>;
  };

  return (
    <RowMonths>
      {periods.map((period) => (
        <Month key={period.date} date={period.date} />
      ))}
    </RowMonths>
  );
};

const ActionButton = ({ children, onClick }): JSX.Element => (
  <ButtonWrapper>
    <IconButton onClick={onClick} color="inherit">
      {children}
    </IconButton>
  </ButtonWrapper>
);

type Props = {
  person: PrivatePersonWithManager;
};

const PersonCard = (props: Props): JSX.Element => {
  const periods = createPeriods();
  const history = useHistory();

  const { person } = props;
  const { id, personNumber, firstName, lastName, manager, programStatus } =
    person;

  const handleOnViewMore = (ev) => {
    history.push(`/persons/${id}`);
    ev.stopPropagation();
  };

  const handleCardClick = () => {
    history.push(`/persons/${id}/taxdeclaration`);
  };

  return (
    <Container>
      <Card onClick={handleCardClick}>
        <Row>
          <Column style={{ marginTop: 10 }}>
            <PersonAvatar personNumber={personNumber} />
            <PersonDetails>
              <PersonName>
                {firstName} {lastName}
              </PersonName>
              <Manager>{manager}</Manager>
            </PersonDetails>
          </Column>
          <Column>
            <ActionButton onClick={handleOnViewMore}>
              <MoreHoriz />
            </ActionButton>
          </Column>
        </Row>
        {programStatus && (
          <TaxesStatus periods={periods} status={programStatus[0]} />
        )}
      </Card>
      <Periods periods={periods} />
    </Container>
  );
};

export default PersonCard;

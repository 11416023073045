import {
  collectReferences,
  collectValues,
  expandIds,
  resolveReference,
  ResolveReferenceContext,
  ResolveReferenceInput,
  ResolvedReference,
} from '@agoy/document';
import updateReportValues from './updateReportValues';
import updateNotesGroupAndNumbering from './updateNotesGroupAndNumbering';

import {
  AnnualReport,
  AnnualReportType,
  getContentDefinition,
} from '../config/document';
import { getDocumentation } from '../config';
import { Documentation } from '../config/shares-company/documentation/documentation';

export const resolveById =
  (
    calculatedReferences: Record<string, ResolvedReference>,
    references: Record<string, string>,
    values: Record<string, ResolvedReference>
  ) =>
  (id: string, context: ResolveReferenceContext): ResolvedReference => {
    if (id in values) {
      return values[id];
    }
    const ref = references[id];

    if (ref) {
      if (calculatedReferences[ref] !== undefined) {
        return calculatedReferences[ref];
      }
      const refValue = resolveReference(ref, context);
      calculatedReferences[ref] = refValue;
      return refValue;
    }
    return { error: 'missingId' };
  };

// This one should not be used for the BR and RR tests
export const calculateReferences = (
  report: AnnualReport,
  baseReferences: Record<string, string>,
  input: ResolveReferenceInput,
  reportType: AnnualReportType,
  referencesToCalculate?: Record<string, string>
): Record<string, ResolvedReference> => {
  const content = getContentDefinition(report, reportType);

  const collectedValues = collectValues(content);
  const values: Record<string, ResolvedReference> = collectedValues(report);
  const collectedReferences = collectReferences(content);
  const references: Record<string, string> = collectedReferences(
    report,
    baseReferences
  );

  const allIds = [
    ...new Set([...Object.keys(values), ...Object.keys(references)]),
  ];

  const calculatedReferences: Record<string, ResolvedReference> = {};

  const context: ResolveReferenceContext = {
    resolveById: resolveById(calculatedReferences, references, values),
    resolveConfig: () => undefined,
    expandIds: expandIds(allIds),
    input,
  };

  Object.entries(referencesToCalculate || references).forEach(
    ([id, reference]) => {
      if (calculatedReferences[reference] === undefined) {
        values[id] = calculatedReferences[reference] = resolveReference(
          reference,
          context
        );
      }
    }
  );

  return calculatedReferences;
};

/**
 * Extracts the ids of referenced notes, the references must be "id(notes.[the note].number)"
 */

export const extractReferencedNotes = (
  report: AnnualReport,
  baseReferences: Record<string, string>,
  reportType: AnnualReportType
): {
  id: string;
  note: string;
}[] => {
  const content = getContentDefinition(report, reportType);

  const collectedReferences = collectReferences(content);
  const references: Record<string, string> = collectedReferences(
    report,
    baseReferences
  );

  // find and put notes that was referenced from section inputs
  const referencedNotes = Object.entries(references)
    .filter(
      ([id, reference]) =>
        (id.includes('notes-') || id.includes('note-')) &&
        reference.startsWith('id(notes.') &&
        reference.endsWith('.number)')
    )
    .map(([id, reference]) => ({
      id: id.split('.')[0],
      note: reference.substring(9, reference.length - 8),
    }));

  return referencedNotes;
};

/**
 * resolveAnnualReport calculates all references and updates the report.
 *
 * It also updates the notes numbering.
 */
export const resolveAnnualReport = (
  report: AnnualReport,
  baseReferences: Record<string, string>,
  input: ResolveReferenceInput,
  reportType: AnnualReportType
): AnnualReport => {
  const references = calculateReferences(
    report,
    baseReferences,
    input,
    reportType,
    undefined
  );

  const updatedReport = updateReportValues(report, references, reportType);
  const referencedNotes = extractReferencedNotes(
    updatedReport,
    baseReferences,
    reportType
  );
  const updatedWithNotes = updateNotesGroupAndNumbering(
    updatedReport,
    referencedNotes,
    reportType
  );

  if (updatedReport !== updatedWithNotes) {
    const refs = calculateReferences(
      updatedWithNotes,
      baseReferences,
      input,
      reportType,
      undefined
    );

    return updateReportValues(updatedWithNotes, refs, reportType);
  }

  return updatedReport;
};

export const returnDocumentation = (
  companyType,
  reportType: AnnualReportType
): Documentation => {
  const documentation = getDocumentation(companyType, reportType);
  return documentation || {};
};

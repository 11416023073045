import { Err, Ok, Result } from 'ts-results';
import { NotificationService } from '_shared/services/Notifications/types';
import { SieImportError, SieImportWarnings } from '../../types';

/**
 * waitForSieImport
 *
 * Returns a promise that resolves when the sie-import identified
 * by `jobId` is completed or failed according to the websocket
 * notifications.
 *
 * @param notificationService
 * @param clientId
 * @param jobId
 * @returns warnings from a completed sie-import or the error from a failed
 */
const waitForSieImport = (
  notificationService: NotificationService,
  clientId: string,
  jobId: string
) => {
  return new Promise<Result<SieImportWarnings, SieImportError>>(
    (resolve, reject) => {
      const subscription = notificationService.subscribe(
        { clientId, topic: 'sie-import' },
        (result) => {
          try {
            if (result.ok) {
              const msg = result.val;
              if (msg.topic === 'sie-import' && msg.jobId === jobId) {
                switch (msg.status) {
                  case 'completed':
                    notificationService.unsubscribe(subscription);
                    resolve(Ok(msg.warnings));
                    break;
                  case 'failed':
                    notificationService.unsubscribe(subscription);
                    resolve(Err(msg));
                    break;
                  case 'started':
                    // Continue to wait
                    break;
                  case 'ignored':
                    notificationService.unsubscribe(subscription);
                    resolve(Ok([]));
                    break;
                  default:
                    break;
                }
              }
            }
          } catch (err) {
            reject(err);
          }
        }
      );
      (subscription as any)._debug = 'waitForSieImport';
    }
  );
};

export default waitForSieImport;

import React from 'react';
import { Typography, Button } from '@material-ui/core';
import { useIntl } from 'react-intl';
import styled from '@emotion/styled';

import { appHeaderHeight } from 'theme/measurements';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: calc(100vh - ${appHeaderHeight * 2}px);
`;

const Title = styled(Typography)`
  text-align: center;
  margin-bottom: ${(props) => props.theme.spacing(4)}px;
`;

type Props = {
  isAdmin: boolean;
  handleTriggerPay: () => Promise<void>;
};

const Unpaid = ({ isAdmin, handleTriggerPay }: Props): JSX.Element => {
  const { formatMessage } = useIntl();

  if (isAdmin) {
    return (
      <Content>
        <Title>{formatMessage({ id: 'unpaid.admin.title' })}</Title>
        <Title>{formatMessage({ id: 'unpaid.admin.subtitle' })}</Title>
        <Title>{formatMessage({ id: 'unpaid.admin.subtitle2' })}</Title>
        <Title>{formatMessage({ id: 'unpaid.admin.subtitle3' })}</Title>
        <Button
          onClick={() => handleTriggerPay()}
          variant="contained"
          color="secondary"
          disableElevation
        >
          {formatMessage({
            id: `unpaid.admin.button`,
          })}
        </Button>
      </Content>
    );
  }

  return (
    <Content>
      <Title>{formatMessage({ id: 'unpaid.nonadmin.title' })}</Title>
      <Title>{formatMessage({ id: 'unpaid.nonadmin.subtitle' })}</Title>
    </Content>
  );
};

export default Unpaid;

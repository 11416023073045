import { PersonsOverviewActions } from './actions';
import { PersonOverviewState } from './types';
import PersonOverviewActions from './action-types';

const initialCustomersOverviewState: PersonOverviewState = {
  sortType: 'BY_CLIENT_NAME',
  search: '',
};

export default (
  state: PersonOverviewState = initialCustomersOverviewState,
  action: PersonsOverviewActions
): PersonOverviewState => {
  switch (action.type) {
    case PersonOverviewActions.SET_NAME_FILTER:
      return {
        ...state,
        search: action.search,
      };
    case PersonOverviewActions.SET_SORT_TYPE: {
      return {
        ...state,
        sortType: action.sortType,
      };
    }
    default:
      return state;
  }
};

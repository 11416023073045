import React from 'react';
import { useIntl } from 'react-intl';
import { Typography } from '@material-ui/core';
import styled from '@emotion/styled';
import { format } from 'date-fns';
import { GroupActualBalanceHistoryItem } from '../../types';
import { formatValue } from '../../utils';

const HistoryContainer = styled('div')`
  margin-bottom: ${(props) => props.theme.spacing(2)}px;
  border: 1px solid ${({ theme }) => theme.palette.grey[400]};
  border-radius: 5px;
  padding: ${(props) => props.theme.spacing(1.5)}px;
  max-width: 400px;
  overflow-y: auto;
  margin-top: ${(props) => props.theme.spacing(3)}px;
`;

const HistoryItem = styled('div')`
  flex-direction: column;
  margin-bottom: ${(props) => props.theme.spacing(2)}px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const HistoryTextContainer = styled('div')`
  flex-direction: row;

  p {
    :not(:last-of-type) {
      margin-right: 5px;
    }
  }
`;

const HistoryText = styled(Typography)`
  color: ${({ theme }) => theme.palette.grey[800]};
`;

const HistoryBlueText = styled(Typography)`
  color: ${({ theme }) => theme.palette.accountingView.hiddenRow.infoText};
`;

const HistoryDate = styled(Typography)`
  color: ${({ theme }) => theme.palette.grey[500]};
`;

interface GroupHistoryProps {
  history: GroupActualBalanceHistoryItem;
}

const GroupActualBalanceHistory = ({
  history,
}: GroupHistoryProps): JSX.Element => {
  const { formatMessage } = useIntl();

  return (
    <HistoryContainer>
      <HistoryItem>
        <HistoryDate>
          {history.date && format(new Date(history.date), 'yyyy-MM-dd HH:mm')}
        </HistoryDate>
        <HistoryTextContainer>
          <HistoryText display="inline">{history.authorName}</HistoryText>
          <HistoryText display="inline">
            {formatMessage({ id: 'hidden.event.history.part1' })}
          </HistoryText>
          <HistoryBlueText display="inline">
            {formatMessage({ id: 'hidden.event.history.part2' })}
          </HistoryBlueText>
          <Typography display="inline">
            {formatValue(history.balance)}
          </Typography>
        </HistoryTextContainer>
      </HistoryItem>
    </HistoryContainer>
  );
};

export default GroupActualBalanceHistory;

import { OpenAPI, DefaultService } from '@agoy/api-sdk-core';
import { addHofToClassMethods, methodHof } from './utils';
import { ApiErrorType } from './api-errors';

/**
 * Initialize the Sdk globals (exposed by the autogenerated code) and enhance the service methods
 * by adding a HOF with onError callback.
 *
 * Returns the SDK service.
 */
const SdkServiceFactory = (
  baseUrl: string,
  headers: () => Promise<Record<string, string>>,
  service: typeof DefaultService,
  globals: typeof OpenAPI,
  onError: (error: ApiErrorType) => ApiErrorType
): typeof DefaultService => {
  // The context baseUrl is not aware of the OpenAPI routes, so v1 needs to be added
  globals.BASE = `${baseUrl}/v1`;
  globals.HEADERS = headers;

  return addHofToClassMethods(service, methodHof(onError));
};

export default SdkServiceFactory;

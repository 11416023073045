import React from 'react';
import { NotificationContextProvider } from '../services/Notifications/NotificationsContext';

const withNotifications = (Component) => (props) => {
  return (
    <NotificationContextProvider>
      <Component {...props} />
    </NotificationContextProvider>
  );
};

export default withNotifications;

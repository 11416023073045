import { reformat } from '@agoy/dates';
import { SupplierInvoice, InvoicesFilter } from '_reconciliation/types';

const createFilterByPeriods =
  (periodList: string[]) => (invoice: SupplierInvoice) => {
    const dateString = reformat(invoice.date, 'yyyy-MM-dd', 'yyyyMM');

    return periodList.some((period) => period.startsWith(dateString));
  };

const filterInvoices = ({
  data,
  filter,
}: {
  data: SupplierInvoice[];
  filter: InvoicesFilter;
}): SupplierInvoice[] => {
  const activeStatusList = filter.statusList
    .filter((item) => item.active)
    .map((item) => item.title);

  const statusBasedFilter =
    activeStatusList.length === 0
      ? data
      : data.filter((invoiceItem) =>
          activeStatusList.includes(invoiceItem.status)
        );

  const periodsFilter = createFilterByPeriods(Object.keys(filter.periods));
  return statusBasedFilter.filter(periodsFilter);
};

export default filterInvoices;

import {
  account,
  or,
  ref,
  value,
  ReferenceAccountInformation,
  multiply,
  sum,
  id,
} from '@agoy/document';
import { startOfMonth, sub } from 'date-fns';

import { FinancialReportStructure } from '../../types';

import { field, table } from '@agoy/annual-report-document';
import { getRandomInt } from './util';
import { parse, format, parseFormat } from '@agoy/dates';

const TX1 = {
  1: `Tabellen nedan visar en förenklad resultat- och balansräkning för de senaste 12 månaderna. Kontona är sammanfattade i kontogrupper. Observera att balansräkningen visar utgående balans / ackumulerat värde och att resultaträkningen visar förändringen / periodsaldot.`,
  2: `På denna sida ser ni en förenklad resultaträkning och balansräkning för de senaste 12 månaderna, indelat i kontogrupper. Observera att balansräkningen visar utgående balans och att resultaträkningen visar förändringen.`,
  3: `Nedanför finner ni resultaträkning och balansräkning för de senaste 12 månaderna. Kontona är sammanfattade i kontogrupper. Observera att resultaträkningen visar förändringen / periodsaldot och balansräkningen visar utgående balans / ackumulerat värde.`,
};

const TX2 = {
  1: `Ni ser exempelvis att det högsta värdet för nettoomsättningen var {maxSales} SEK och att det lägsta värdet för personalkostnaderna var {minPersonalExpenses} SEK under den senaste 12-månadersperioden.`,
  2: `Ni ser exempelvis att det lägsta värdet för nettoomsättningen var {minSales} SEK och att det högsta rörelseresultatet var {maxIncome} SEK under den senaste 12-månadersperioden.`,
  3: `Ni ser exempelvis att det högsta värdet för nettoomsättningen var {maxSales} SEK och att det lägsta rörelseresultatet var {minIncome} SEK under den senaste 12-månadersperioden.`,
};

export const tableRRBRConfig = (
  accounts: Record<string, ReferenceAccountInformation>,
  period: string
): FinancialReportStructure['tableRRBR'] => {
  const lastperiod = startOfMonth(parse(period, 'yyyyMMdd'));
  const textNumber = getRandomInt(3);
  const calcMonth = Array.from(Array(12).keys()).map((item) =>
    sub(lastperiod, { months: item })
  );

  const months = calcMonth.reverse().map((item) => {
    return {
      period: format(item, 'MMMyy'),
      label: parseFormat(item, 'MMM-yy'),
    };
  });
  return {
    section: {
      active: false,
      title: field('Resultat- & Balansräkning Tabell'),
      generalInfo: field(`${TX1[textNumber]} ${TX2[textNumber]}`),
      generalInfoStatic: field(`${TX1[1]} ${TX2[1]}`),
      comments: field(''),
      result: table(
        'tableRRBR.section.table.result',
        { id: 'nameLabel', label: 'Resultaträkning' },
        ...months.map((month) => {
          return {
            id: month.period,
            label: month.label,
          };
        })
      )
        .addRows((rows) => {
          const nettoCells = months.map((month) => {
            return ref(
              multiply(or(account('3000:3799', 'change', month.period), 0), -1)
            );
          });
          rows.addRow('nettos', value('Nettoomsättning'), ...nettoCells);
          const revenueCells = months.map((month) => {
            return ref(
              multiply(or(account('3800:3999', 'change', month.period), 0), -1)
            );
          });
          rows.addRow(
            'nettoaccounts',
            value('Övriga rörelseintäkter'),
            ...revenueCells
          );
          const bruttokostnaderCells = months.map((month) => {
            return ref(or(account('4000:4999', 'change', month.period), 0));
          });
          rows.addRow(
            'bruttokostnader',
            value('Bruttokostnader'),
            ...bruttokostnaderCells
          );
          const ovrigaCells = months.map((month) => {
            return ref(or(account('5000:6999', 'change', month.period), 0));
          });
          rows.addRow(
            'ovrigaKostnader',
            value('Övriga kostnader'),
            ...ovrigaCells
          );
          const personalCells = months.map((month) => {
            return ref(or(account('7000:7699', 'change', month.period), 0));
          });
          rows.addRow(
            'personalkostnader',
            value('Personalkostnader'),
            ...personalCells
          );
          const avskrivningarCells = months.map((month) => {
            return ref(or(account('7700:7899', 'change', month.period), 0));
          });
          rows.addRow(
            'avskrivningar',
            value('Avskrivningar etc'),
            ...avskrivningarCells
          );
          const reaForlustCells = months.map((month) => {
            return ref(or(account('7900:7999', 'change', month.period), 0));
          });
          rows.addRow(
            'reaForlust',
            value('REA-förlust etc'),
            ...reaForlustCells
          );
          const operationsResultCells = months.map((month) => {
            return ref(
              or(
                sum(
                  multiply(account('3000:3999', 'change', month.period), -1),
                  multiply(account('4000:7999', 'change', month.period), -1)
                ),
                0
              )
            );
          });
          rows.addRow(
            'operationsResult',
            value('Rörelseresultat'),
            ...operationsResultCells
          );
          return rows.build();
        })
        .build(),
      balance: table(
        'tableRRBR.section.balance',
        { id: 'nameLabel', label: 'Balansräkning' },
        ...months.map((month) => {
          return {
            id: month.period,
            label: month.label,
          };
        })
      )
        .addRows((rows) => {
          const intangibleAssetsCells = months.map((month) => {
            return ref(or(account('1000:1099', 'ub', month.period), 0));
          });
          rows.addRow(
            'intangibleAssets',
            value('Immateriella anläggningstillgångar'),
            ...intangibleAssetsCells
          );
          const materialAssetsCells = months.map((month) => {
            return ref(or(account('1100:1299', 'ub', month.period), 0));
          });
          rows.addRow(
            'materialAssets',
            value('Materiella anläggningstillgångar'),
            ...materialAssetsCells
          );
          const financialAssetsCells = months.map((month) => {
            return ref(or(account('1300:1399', 'ub', month.period), 0));
          });
          rows.addRow(
            'financialAssets',
            value('Finansiella anläggningstillgångar'),
            ...financialAssetsCells
          );
          const stockCells = months.map((month) => {
            return ref(or(account('1400:1499', 'ub', month.period), 0));
          });
          rows.addRow('stock', value('Varulager'), ...stockCells);
          const shortTermClaimsCells = months.map((month) => {
            return ref(or(account('1500:1799', 'ub', month.period), 0));
          });
          rows.addRow(
            'shortTermClaims',
            value('Kortfristiga fordringar'),
            ...shortTermClaimsCells
          );
          const shortTermDepositCells = months.map((month) => {
            return ref(or(account('1800:1899', 'ub', month.period), 0));
          });
          rows.addRow(
            'shortTermDeposit',
            value('Kortfristiga placeringar'),
            ...shortTermDepositCells
          );
          const kassaCells = months.map((month) => {
            return ref(or(account('1900:1999', 'ub', month.period), 0));
          });
          rows.addRow('kassa', value('Kassa och bank'), ...kassaCells);
          rows.addRow('capitalliabilities', value('Eget kapital och skulder'));
          const fundsCells = months.map((month) => {
            return ref(or(account('1000:1999', 'ub', month.period), 0));
          });
          rows.addRow('funds', value('Summa tillgångar'), ...fundsCells);
          const equityCells = months.map((month) => {
            return ref(
              multiply(or(account('2000:2099', 'ub', month.period), 0), -1)
            );
          });
          rows.addRow('equity', value('Eget kapital'), ...equityCells);
          const taxFreeReservesCells = months.map((month) => {
            return ref(
              multiply(or(account('2100:2199', 'ub', month.period), 0), -1)
            );
          });
          rows.addRow(
            'taxFreeReservers',
            value('Obeskattade reserver'),
            ...taxFreeReservesCells
          );
          const reservesCells = months.map((month) => {
            return ref(
              multiply(or(account('2200:2299', 'ub', month.period), 0), -1)
            );
          });
          rows.addRow('reserves', value('Avsättningar'), ...reservesCells);
          const longTermDebtCells = months.map((month) => {
            return ref(
              multiply(or(account('2300:2399', 'ub', month.period), 0), -1)
            );
          });
          rows.addRow(
            'longTermDebt',
            value('Långfristiga skulder'),
            ...longTermDebtCells
          );
          const shortTermDebtCells = months.map((month) => {
            return ref(
              multiply(or(account('2400:2999', 'ub', month.period), 0), -1)
            );
          });
          rows.addRow(
            'shortTermDebt',
            value('Kortfristiga skulder'),
            ...shortTermDebtCells
          );
          const summaryCells = months.map((month) => {
            return ref(
              sum(
                id(`tableRRBR.section.balance.shortTermDebt.${month.period}`),
                id(`tableRRBR.section.balance.longTermDebt.${month.period}`),
                id(`tableRRBR.section.balance.equity.${month.period}`),
                id(`tableRRBR.section.balance.taxFreeReservers.${month.period}`)
              )
            );
          });
          rows.addRow(
            'summary',
            value('Summa eget kapital och skulder'),
            ...summaryCells
          );
          return rows.build();
        })
        .build(),
      cashFlow: table(
        'tableRRBR.section.table.balans',
        { id: 'nameLabel', label: 'Balansräkning' },
        ...months.map((month) => {
          return {
            id: month.period,
            label: month.label,
          };
        })
      )
        .addRows((rows) => {
          const cashFlowCells = months.map((month) => {
            return ref(or(account('1900:1999', 'change', month.period), 0));
          });
          rows.addRow(
            'cashFlow',
            value('Kassaflöde per månad'),
            ...cashFlowCells
          );
          return rows.build();
        })
        .build(),
    },
  };
};

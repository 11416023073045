import React, { useContext } from 'react';

type ContextType = {
  messageChannel: string;
};
const Context = React.createContext<ContextType>({
  messageChannel: 'global',
});

export const useMessagesContext = () => {
  return useContext(Context);
};

type Props = {
  messageChannel: string;
  children: React.ReactNode;
};

export const MessagesProvider = ({ messageChannel, children }: Props) => (
  <Context.Provider value={{ messageChannel }}>{children}</Context.Provider>
);

import React from 'react';
import { useIntl } from 'react-intl';
import { Typography } from '@material-ui/core';
import {
  SharesCompanyManagementReport,
  EconomicAssociationManagementReport,
  ResultsDisposition as ResultsDispositionType,
} from '@agoy/annual-report-document';
import { stringValue } from '@agoy/document';
import MultilineField from '../../UI/MultilineField';
import { Heading, Section } from '../../../../UI';
import { FormattingContext } from '@agoy/formatting';

import FirstTableSection from './DisposalSection';
import ExtraordinaryMeetingSection from './ExtraordinaryMeeting';
import SecondTableSection from './ProposalSection';
interface ResultsDispositionProps {
  managementReport:
    | SharesCompanyManagementReport
    | EconomicAssociationManagementReport;
  reportPart: ResultsDispositionType | undefined;
  isDigitalSubmission: boolean | null | undefined;
}

const ResultsDisposition = ({
  managementReport,
  reportPart,
  isDigitalSubmission,
}: ResultsDispositionProps): JSX.Element | null => {
  const { formatMessage } = useIntl();

  const formattingContext = React.useContext(FormattingContext);
  const displayLabel = formattingContext.shouldPrintLabel();

  if (!reportPart) return null;

  const title =
    stringValue(managementReport.resultsDisposition.title) ||
    formatMessage({
      id: 'annualReport.menu.managementReport.resultsDisposition',
    });

  const proposeHeader =
    stringValue(managementReport.resultsDisposition.proposeHeader) ||
    formatMessage({
      id: 'annualReport.menu.managementReport.resultsDisposition.comment',
    });

  const isCommentEmpty = stringValue(reportPart.propose) === '';

  return (
    <Section>
      <Heading title={title} />
      {displayLabel && (
        <Typography variant="h4">
          {formatMessage(
            {
              id: 'annualReport.menu.managementReport.currencyPart',
            },
            {
              type: 'Resultatsdisposition',
            }
          )}
        </Typography>
      )}
      <FirstTableSection
        managementReport={managementReport}
        reportPart={reportPart}
        isDigitalSubmission={isDigitalSubmission}
      />
      <ExtraordinaryMeetingSection
        managementReport={managementReport}
        reportPart={reportPart}
        isDigitalSubmission={isDigitalSubmission}
      />
      <SecondTableSection
        managementReport={managementReport}
        reportPart={reportPart}
        isDigitalSubmission={isDigitalSubmission}
      />

      {!isCommentEmpty && (
        <MultilineField
          header={<Typography variant="h4">{proposeHeader}</Typography>}
          field={reportPart.propose}
        />
      )}
    </Section>
  );
};

export default ResultsDisposition;

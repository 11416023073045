export type OptionalKeys<T> = Exclude<
  {
    [K in keyof T]: T extends Record<K, T[K]> ? never : K;
  }[keyof T],
  undefined
>;

/**
 * Deletes the given keys from a copy of the object if they are undefined.
 * Will return the original object if no keys needs to be removed.
 *
 * @param obj
 * @param keys Optional properties to delete
 * @returns
 */
export const removeOptional = <T extends object>(
  obj: T,
  ...keys: OptionalKeys<T>[]
): T => {
  let result = obj;
  for (const key of keys) {
    if (key.toString() in obj && obj[key] === undefined) {
      if (result === obj) {
        // First delete, copy the object and delete.
        result = { ...obj };
      }
      delete result[key];
    }
  }
  return result;
};

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  IconButton,
  Typography,
  Button,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import styled from '@emotion/styled';
import AddIcon from '@material-ui/icons/Add';
import isPropValid from '@emotion/is-prop-valid';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { TableServiceType } from '_shared/components/CommonTable';
import { AgoyTable, AgoyTableRow, StringCell } from '@agoy/document';
import { gray } from '@agoy/theme/src/colors';
import { generateId } from '@agoy/document/src/AgoyDocument/createNewRow';
import { UpdateRowsPayload } from '@agoy/document/src/AgoyDocument/operations/updateTableRows';
import When from '_shared/components/When/When';
import EditTableModalRow from './EditTableModalRow';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 50vh;
  overflow: auto;
`;

const DragContainer = styled('div', {
  shouldForwardProp: (prop) => isPropValid(prop),
})<{ dragging: boolean }>`
  padding: 0;
  ${({ dragging }) => (dragging ? `background-color: #b7deb7;` : '')}
`;

const Label = styled(Typography)`
  font-weight: bold;
  font-size: 20px;
`;

const StyledDialogTitle = styled(DialogTitle)`
  padding: 0 0 0 24px;
`;

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const FormButton = styled(Button)`
  border-radius: 20px;
  margin-top: ${({ theme }) => theme.spacing(2)}px;
`;

const AddRowButtonContainer = styled.div`
  border-bottom: 1px solid ${gray.MEDIUM};
  padding-bottom: ${({ theme }) => theme.spacing(2)}px;
`;

const PlusIcon = styled(AddIcon)`
  font-size: 24px;
  margin-right: 8px;
`;

const Title = styled(Typography)`
  border-bottom: solid 1px #000;
  font-weight: 700;
  padding-bottom: ${({ theme }) => theme.spacing(1)}px;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
`;

interface Props {
  isOpen?: boolean;
  onClose?: () => void;
  table: AgoyTable;
  service: TableServiceType;
  baseId: string;
}

type QueueFunc = (row?: AgoyTableRow) => void;

const EditTableModal = ({
  isOpen = false,
  onClose = () => {},
  table,
  service,
  baseId,
}: Props) => {
  const { formatMessage } = useIntl();
  const [queue, setQueue] = useState<QueueFunc[]>([]);
  const [rows, setRows] = useState<AgoyTableRow[]>([]);

  const generateRows = useCallback(() => {
    const currentRows = table.rows.find((row) => row.id === 'main')?.rows ?? [];

    const newRows: AgoyTableRow[] = [];
    const newRowsWithSortKey: AgoyTableRow[] = [];

    currentRows.forEach((item) => {
      if (typeof item.sortKey === 'number') {
        newRowsWithSortKey.push({
          ...item,
        });
      } else {
        newRows.push({
          ...item,
        });
      }
    });

    newRowsWithSortKey.sort((a, b) => (a.sortKey || 0) - (b.sortKey || 0));
    newRowsWithSortKey.forEach((item) => {
      newRows.splice(item.sortKey || 0, 0, item);
    });

    setRows(newRows);
  }, [table.rows]);

  const addToQueue = useCallback(
    (updateFunc: QueueFunc) => {
      setQueue([...queue, updateFunc]);
    },
    [queue, setQueue]
  );

  const executeQueue = useCallback(() => {
    queue.forEach((q) => q());
  }, [queue]);

  useEffect(() => {
    setQueue([]);

    if (isOpen) {
      generateRows();
    }
  }, [isOpen, generateRows]);

  const onDragEnd = useCallback(
    ({ destination, draggableId }) => {
      const rowIndex = rows.findIndex((item) => item.id === draggableId);
      const newRows = [...rows];

      if (rowIndex !== -1) {
        newRows[rowIndex].sortKey = destination.index;
      }

      const rowsWithSortKey: UpdateRowsPayload[] = [];
      const [deletedElement] = newRows.splice(rowIndex, 1);

      newRows.splice(destination.index, 0, deletedElement);

      newRows.forEach((row, index) => {
        if (typeof row.sortKey === 'number') {
          rowsWithSortKey.push({
            id: `${baseId}.main.${row.id}`,
            sortKey: index,
          });
        }
      });
      setRows(newRows);

      addToQueue(() => {
        service?.updateRows(rowsWithSortKey);
      });
    },
    [baseId, rows, service, addToQueue, setRows]
  );

  const onChecked = useCallback(
    (row) => {
      addToQueue(() => {
        service.toggleTableRowActive(`${baseId}.main.${row.id}`);
      });

      const index = rows.findIndex((r) => r.id === row.id);
      const r = rows[index];

      if (r) {
        r.active = !r.active;
      }
    },
    [baseId, service, addToQueue, rows]
  );

  const onNameChanged = useCallback(
    (row, value = '') => {
      addToQueue(() => {
        service.updateCellValue(`${baseId}.main.${row.id}.name`, value);
      });

      const index = rows.findIndex((r) => r.id === row.id);
      const r = rows[index];

      if (r?.cells?.name) {
        (r.cells.name as StringCell).value = value;
      }
    },
    [baseId, service, addToQueue, rows]
  );

  const onDeleteRow = useCallback(
    (row) => {
      addToQueue(() => {
        service.deleteRow(`${baseId}.main.${row.id}`);
      });

      const index = rows.findIndex((r) => r.id === row.id);
      rows.splice(index, 1);
    },
    [baseId, service, rows, addToQueue]
  );

  const onAddRow = useCallback(() => {
    const newId = generateId();
    addToQueue(() => {
      service.addRow(`${baseId}`, newId);
    });

    rows.push({
      ...rows[0].newRowTemplate,
      id: newId,
      active: true,
      cells: {
        default: { type: 'boolean', value: false },
        name: { type: 'string', value: '' },
        value: { type: 'number', value: 0 },
      },
    });
  }, [service, baseId, rows, addToQueue]);

  return (
    <Dialog
      open={isOpen}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          onClose();
        }
      }}
      maxWidth="lg"
      disableEscapeKeyDown
    >
      <StyledDialogTitle>
        <HeaderRow>
          <Label>
            {formatMessage({ id: 'finalTaxCalculations.modal.title' })}
          </Label>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </HeaderRow>
      </StyledDialogTitle>
      <DialogContent>
        <Title>
          {formatMessage({ id: 'finalTaxCalculations.modal.table.title' })}
        </Title>
        <DragDropContext onDragEnd={onDragEnd}>
          <Container>
            <Droppable droppableId="droppable" type="type-columns">
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  <When isTrue={Boolean(rows)}>
                    {rows.map((row, rowIndex) => (
                      <Draggable
                        key={row.id}
                        draggableId={`${row.id}`}
                        index={rowIndex}
                      >
                        {(providedGroup, snapshotGroup) => (
                          <DragContainer
                            {...providedGroup.draggableProps}
                            {...providedGroup.dragHandleProps}
                            ref={providedGroup.innerRef}
                            dragging={snapshotGroup.isDragging}
                            style={{ ...providedGroup.draggableProps.style }}
                          >
                            <div {...providedGroup.dragHandleProps} />
                            <EditTableModalRow
                              row={row}
                              onChecked={() => onChecked(row)}
                              onNameChanged={(e) => onNameChanged(row, e)}
                              onDelete={() => onDeleteRow(row)}
                            />
                          </DragContainer>
                        )}
                      </Draggable>
                    ))}
                  </When>

                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </Container>
        </DragDropContext>

        <AddRowButtonContainer>
          <FormButton
            onClick={() => onAddRow()}
            variant="outlined"
            color="primary"
            size="small"
          >
            <PlusIcon />
            {formatMessage({ id: 'add.row' })}
          </FormButton>
        </AddRowButtonContainer>
      </DialogContent>

      <DialogActions>
        <FormButton onClick={() => onClose()} color="primary">
          {formatMessage({ id: 'finalTaxCalculations.modal.cancel' })}
        </FormButton>
        <FormButton
          onClick={async () => {
            executeQueue();
            onClose();
          }}
          color="primary"
          variant="contained"
        >
          {formatMessage({ id: 'finalTaxCalculations.modal.ok' })}
        </FormButton>
      </DialogActions>
    </Dialog>
  );
};

export default EditTableModal;

import { value } from '@agoy/document';
import { NoteInvertedTwoTableSection, IxbrlCell } from '../../../../common';
import { sumNotesRowsById } from '../../../../common/utils/accountSumsUtil';
import { year, previousYear } from '../../../../common/utils/date-util';
import {
  field,
  ixbrlCell,
  ixbrlField,
  range,
  table,
  tupleRef,
} from '../../../../common/utils/util';
import { AnnualReportType } from '../../../../document';

const MONETARY_ITEM_TYPE = 'monetaryItemType';

export const getNote18 = (
  reportType: AnnualReportType,
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null,
  documentTypeVersion = '1'
): NoteInvertedTwoTableSection => {
  return {
    active: false,
    number: value(undefined),
    name: value('Andelar i intresseföretag och gemensamt styrda företag'),
    // second table comes first in the view now
    // KEEP IT as secondTable to preserve older input values
    secondTable: table<IxbrlCell>(
      'notes.note18.secondTable',
      { id: 'label', label: '' },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      .addRows((rows) =>
        rows
          // 'ingående' = previous period outgoing balance
          .addRow(
            '1',
            noteLabel('Ingående anskaffningsvärden'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagAnskaffningsvarden',
              contextRef: 'balans1',
              saldo: 'debit',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagAnskaffningsvarden',
              contextRef: 'balans2',
              saldo: 'debit',
            })
          )
          .addRow(
            '2',
            noteLabel('Inköp'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagAnskaffningsvardenInkop',
              contextRef: 'period0',
              standardRubrik:
                'Ökning av anskaffningsvärden i samband med inköp av andelar i intresseföretag och gemensamt styrda företag',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagAnskaffningsvardenInkop',
              contextRef: 'period1',
              standardRubrik:
                'Ökning av anskaffningsvärden i samband med inköp av andelar i intresseföretag och gemensamt styrda företag',
            })
          )
          .addRow(
            'anskaffningsvardenFusion',
            noteLabel('Fusion'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagAnskaffningsvardenFusion',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden för andelar i intresseföretag och gemensamt styrda företag genom fusion',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagAnskaffningsvardenFusion',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden för andelar i intresseföretag och gemensamt styrda företag genom fusion',
            })
          )
          .addRow(
            '3',
            noteLabel('Försäljningar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagAnskaffningsvardenForsaljningar',
              contextRef: 'period0',
              standardRubrik:
                'Minskning av anskaffningsvärden i samband med försäljningar av andelar i intresseföretag och gemensamt styrda företag',
              negateValue: true,
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagAnskaffningsvardenForsaljningar',
              contextRef: 'period1',
              standardRubrik:
                'Minskning av anskaffningsvärden i samband med försäljningar av andelar i intresseföretag och gemensamt styrda företag',
              negateValue: true,
            })
          )
          .addRow(
            'shareholderContributions',
            noteLabel('Lämnade aktieägartillskott'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagAnskaffningsvardenLamnadeAktieagartillskott',
              contextRef: 'period0',
              standardRubrik:
                'Ökning av anskaffningsvärden i samband lämnande aktieägartillskott till intresseföretag och gemensamt styrda företag',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagAnskaffningsvardenLamnadeAktieagartillskott',
              contextRef: 'period1',
              standardRubrik:
                'Ökning av anskaffningsvärden i samband lämnande aktieägartillskott till intresseföretag och gemensamt styrda företag',
            })
          )
          .addRow(
            'repaymentOfShareholderContributions',
            noteLabel('Återbetalning av lämnat aktieägartillskott'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagAnskaffningsvardenAterbetalningLamnatAktieagartillskott',
              contextRef: 'period0',
              standardRubrik:
                'Minskning av anskaffningsvärden i samband återbetalning av lämnat aktieägartillskott till intresseföretag och gemensamt styrda företag',
              negateValue: true,
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagAnskaffningsvardenAterbetalningLamnatAktieagartillskott',
              contextRef: 'period1',
              standardRubrik:
                'Minskning av anskaffningsvärden i samband återbetalning av lämnat aktieägartillskott till intresseföretag och gemensamt styrda företag',
              negateValue: true,
            })
          )
          .addRow(
            'yearProfitShare',
            noteLabel('Årets resultatandel'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagAnskaffningsvardenAretsResultatAndel',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden i samband periodens resultatandel i handelsbolag som är intresseföretag eller gemensamt styrda företag',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagAnskaffningsvardenAretsResultatAndel',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden i samband periodens resultatandel i handelsbolag som är intresseföretag eller gemensamt styrda företag',
            })
          )
          .addRow(
            '4',
            noteLabel('Omklassificeringar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagAnskaffningsvardenOmklassificeringar',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden i samband med omklassificeringar av andelar i intresseföretag och gemensamt styrda företag',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagAnskaffningsvardenOmklassificeringar',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden i samband med omklassificeringar av andelar i intresseföretag och gemensamt styrda företag',
            })
          )
          .addRow(
            'anskaffningsvardenConversionDifferences',
            noteLabel('Omräkningsdifferenser'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagAnskaffningsvardenOmrakningsdifferenser',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden i samband med omräkningsdifferenser av andelar i intresseföretag och gemensamt styrda företag',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagAnskaffningsvardenOmrakningsdifferenser',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden i samband med omräkningsdifferenser av andelar i intresseföretag och gemensamt styrda företag',
            })
          )
          .addRow(
            '5',
            noteLabel('Utgående anskaffningsvärden'),
            ixbrlCell(
              sumNotesRowsById(
                18,
                [
                  ...range(1, 5),
                  'anskaffningsvardenFusion',
                  'shareholderContributions',
                  'repaymentOfShareholderContributions',
                  'yearProfitShare',
                  'anskaffningsvardenConversionDifferences',
                ],
                'year',
                'secondTable'
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagAnskaffningsvarden',
                contextRef: 'balans0',
                saldo: 'debit',
              }
            ),
            ixbrlCell(
              sumNotesRowsById(
                18,
                [
                  ...range(1, 5),
                  'anskaffningsvardenFusion',
                  'shareholderContributions',
                  'repaymentOfShareholderContributions',
                  'yearProfitShare',
                  'anskaffningsvardenConversionDifferences',
                ],
                'previousYear',
                'secondTable'
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagAnskaffningsvarden',
                contextRef: 'balans1',
                saldo: 'debit',
              }
            )
          )
          .addRow(
            '6',
            noteLabel('Ingående nedskrivningar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagNedskrivningar',
              contextRef: 'balans1',
              saldo: 'credit',
              negateValue: true,
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagNedskrivningar',
              contextRef: 'balans2',
              saldo: 'credit',
              negateValue: true,
            })
          )
          .addRow(
            'nedskrivningarFusion',
            noteLabel('Fusion'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagForandringNedskrivningarFusion',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av nedskrivningar för andelar i intresseföretag och gemensamt styrda företag genom fusion',
              negateValue: true,
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagForandringNedskrivningarFusion',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av nedskrivningar för andelar i intresseföretag och gemensamt styrda företag genom fusion',
              negateValue: true,
            })
          )
          .addRow(
            '7',
            noteLabel('Försäljningar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagForandringNedskrivningarAterfordaNedskrivningarForsaljningar',
              contextRef: 'period0',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagForandringNedskrivningarAterfordaNedskrivningarForsaljningar',
              contextRef: 'period1',
            })
          )
          .addRow(
            '8',
            noteLabel('Återförda nedskrivningar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagForandringNedskrivningarAterfordaNedskrivningar',
              contextRef: 'period0',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagForandringNedskrivningarAterfordaNedskrivningar',
              contextRef: 'period1',
            })
          )
          .addRow(
            '9',
            noteLabel('Omklassificeringar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagForandringNedskrivningarOmklassificeringar',
              contextRef: 'period0',
              standardRubrik:
                'Minskning (ökning) av nedskrivningar i samband med omklassificeringar av andelar i intresseföretag och gemensamt styrda företag',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagForandringNedskrivningarOmklassificeringar',
              contextRef: 'period1',
              standardRubrik:
                'Minskning (ökning) av nedskrivningar i samband med omklassificeringar av andelar i intresseföretag och gemensamt styrda företag',
            })
          )
          .addRow(
            '10',
            noteLabel('Årets nedskrivningar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagForandringNedskrivningarAretsNedskrivningar',
              contextRef: 'period0',
              negateValue: true,
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagForandringNedskrivningarAretsNedskrivningar',
              contextRef: 'period1',
              negateValue: true,
            })
          )
          .addRow(
            'nedskrivningarConversionDifferences',
            noteLabel('Omräkningsdifferenser'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagForandringNedskrivningarOmrakningsdifferenser',
              contextRef: 'period0',
              standardRubrik:
                'Minskning (ökning) av nedskrivningar i samband med omräkningsdifferenser av andelar i intresseföretag och gemensamt styrda företag',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagForandringNedskrivningarOmrakningsdifferenser',
              contextRef: 'period1',
              standardRubrik:
                'Minskning (ökning) av nedskrivningar i samband med omräkningsdifferenser av andelar i intresseföretag och gemensamt styrda företag',
            })
          )
          .addRow(
            '11',
            noteLabel('Utgående nedskrivningar'),
            ixbrlCell(
              sumNotesRowsById(
                18,
                [
                  ...range(6, 11),
                  'nedskrivningarFusion',
                  'nedskrivningarConversionDifferences',
                ],
                'year',
                'secondTable'
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagNedskrivningar',
                contextRef: 'balans0',
                saldo: 'credit',
                negateValue: true,
              }
            ),
            ixbrlCell(
              sumNotesRowsById(
                18,
                [
                  ...range(6, 11),
                  'nedskrivningarFusion',
                  'nedskrivningarConversionDifferences',
                ],
                'previousYear',
                'secondTable'
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagNedskrivningar',
                contextRef: 'balans1',
                saldo: 'credit',
                negateValue: true,
              }
            )
          )
          .addRow(
            '12',
            noteLabel('Redovisat värde'),
            ixbrlCell(sumNotesRowsById(18, [5, 11], 'year', 'secondTable'), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetag',
              contextRef: 'balans0',
              saldo: 'debit',
            }),
            ixbrlCell(
              sumNotesRowsById(18, [5, 11], 'previousYear', 'secondTable'),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetag',
                contextRef: 'balans1',
                saldo: 'debit',
              }
            )
          )
          .build()
      )
      .newRowTemplate(value(''), value(undefined), value(undefined))
      .build(),
    table: table<IxbrlCell>(
      'notes.note18.table',
      { id: 'companyName', label: 'Företagets namn' },
      {
        id: 'organisationNumber',
        label: 'Organisationsnummer',
      },
      {
        id: 'place',
        label: 'Säte',
      },
      {
        id: 'capitalShare',
        label: 'Kapitalandel (%)',
      }
    )
      .addRows((rows) =>
        rows
          .addRow(
            '1',
            ixbrlCell(value(''), {
              type: 'stringItemType',
              name: 'se-cd-base:ForetagetsNamn',
              contextRef: 'period0',
              tuple: {
                name:
                  reportType === 'k2'
                    ? 'se-gaap-ext:InnehavAndelarIntresseforetagGemensamtStyrdaForetagInklusiveUppgiftForetagK2Tuple'
                    : 'se-gaap-ext:InnehavAndelarIntresseforetagGemensamtStyrdaForetagInklusiveUppgiftForetagTuple',
                id: `${rows.getBaseId()}.1.tuple`,
                tupleID: `${rows.getBaseId()}.1.tuple`,
              },
              tupleRef: tupleRef(1, `${rows.getBaseId()}.1.tuple`),
            }),
            ixbrlCell(value(''), {
              type: 'stringItemType',
              name: 'se-cd-base:Organisationsnummer',
              contextRef: 'period0',
              tupleRef: tupleRef(3, `${rows.getBaseId()}.1.tuple`),
            }),
            ixbrlCell(value(''), {
              type: 'stringItemType',
              name: 'se-cd-base:ForetagetsSate',
              contextRef: 'period0',
              tupleRef: tupleRef(4, `${rows.getBaseId()}.1.tuple`),
            }),
            ixbrlCell(value(undefined), {
              type: 'pureItemType',
              name: 'se-gen-base:InnehavAndelarIntresseforetagGemensamtStyrdaForetagKapitalandel',
              contextRef: 'balans0',
              tupleRef: tupleRef(8, `${rows.getBaseId()}.1.tuple`),
            })
          )
          .build()
      )
      .newRowTemplateGenerator((id, baseId) => ({
        id,
        active: true,
        cells: {
          companyName: ixbrlCell(value(''), {
            type: 'stringItemType',
            name: 'se-cd-base:ForetagetsNamn',
            contextRef: 'period0',
            tuple: {
              name:
                reportType === 'k2'
                  ? 'se-gaap-ext:InnehavAndelarIntresseforetagGemensamtStyrdaForetagInklusiveUppgiftForetagK2Tuple'
                  : 'se-gaap-ext:InnehavAndelarIntresseforetagGemensamtStyrdaForetagInklusiveUppgiftForetagTuple',
              id: `${baseId}.${id}.tuple`,
              tupleID: `${baseId}.${id}.tuple`,
            },
            tupleRef: tupleRef(1, `${baseId}.${id}.tuple`),
          }),
          organisationNumber: ixbrlCell(value(''), {
            type: 'stringItemType',
            name: 'se-cd-base:Organisationsnummer',
            contextRef: 'period0',
            tupleRef: tupleRef(3, `${baseId}.${id}.tuple`),
          }),
          place: ixbrlCell(value(''), {
            type: 'stringItemType',
            name: 'se-cd-base:ForetagetsSate',
            contextRef: 'period0',
            tupleRef: tupleRef(4, `${baseId}.${id}.tuple`),
          }),
          capitalShare: ixbrlCell(value(undefined), {
            type: 'pureItemType',
            name: 'se-gen-base:InnehavAndelarIntresseforetagGemensamtStyrdaForetagKapitalandel',
            contextRef: 'balans0',
            tupleRef: tupleRef(8, `${baseId}.${id}.tuple`),
          }),
        },
      }))
      .build(),
    commentLabel: field('Kommentar'),
    comment: ixbrlField(field(''), {
      type: 'stringItemType',
      name: 'se-gen-base:NotAndelarIntresseforetagGemensamtStyrdaForetagKommentar',
      contextRef: 'period0',
    }),
    group: value(undefined),
    section: value(undefined),
    validForReportType: {
      k2: true,
      k3: documentTypeVersion === '1',
    },
  };
};

import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';
import { Typography } from '@material-ui/core';

const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const LoginReplacement = () => {
  const intl = useIntl();

  useEffect(() => {
    const timeout = setTimeout(() => {
      window.location.href = 'https://www.fortnox.se/loggat-ut';
    }, 0);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <Wrapper>
      <Typography>
        {intl.formatMessage({
          id: 'bye',
        })}
      </Typography>
    </Wrapper>
  );
};

export default LoginReplacement;

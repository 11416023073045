import { FinancialReportStructure } from '../../types';

export const balanceSheetAssetsConfig =
  (): FinancialReportStructure['balanceSheetAssets'] => {
    return {
      section: {
        active: true,
        activeSecondTable: true,
      },
    };
  };

import {
  formatCurrentTime,
  formatEndOfMonth,
  formatStartOfMonth,
  parseFormat,
} from '@agoy/dates';

const getOldestSelectedDate = (periods: { [key: string]: boolean }): number => {
  let oldestPeriod = 0;
  Object.keys(periods).forEach((p) => {
    if (Number(p) > oldestPeriod) {
      oldestPeriod = Number(p);
    }
  });

  if (oldestPeriod === 0) {
    return 0;
  }
  const lastDaySelected = formatEndOfMonth(`${oldestPeriod}`, 'yyyyMMdd');

  return Number(lastDaySelected);
};

const getIngoingDate = (date: string) => {
  if (date == null || date.length === 0) {
    return '';
  }

  return formatStartOfMonth(date, 'yyyy-MM-dd');
};

const getOutgoingDate = (date: string, periods: { [key: string]: boolean }) => {
  const lastDateSelected = getOldestSelectedDate(periods);
  if (lastDateSelected === 0) {
    return '';
  }

  const todaysDate = Number(formatCurrentTime('yyyyMMdd'));
  const selectionIsInThePast = todaysDate >= lastDateSelected;
  if (selectionIsInThePast) {
    return formatEndOfMonth(date, 'yyyy-MM-dd');
  }

  const lastTransactionDate = Number(date);
  const hasTransactionsInTheFuture = lastTransactionDate > todaysDate;
  if (hasTransactionsInTheFuture) {
    return parseFormat(date, 'yyyy-MM-dd');
  }

  // todays date
  return formatCurrentTime('yyyy-MM-dd');
};

export { getIngoingDate, getOutgoingDate };

import React, { useContext } from 'react';
import XML from './XML';
import { FormattingContext } from '@agoy/formatting';

type WithChildren = {
  children: React.ReactNode | React.ReactNodeArray;
};

const IX = ({
  tagName,
  children,
  ...props
}: Record<string, string | React.ReactNode>): React.ReactElement => (
  <XML namespace="ix" tagName={tagName} {...props}>
    {children}
  </XML>
);

export const Header = ({ children }: WithChildren): React.ReactElement => (
  <IX tagName="header">{children}</IX>
);

export const Hidden = ({ children }: WithChildren): React.ReactElement => (
  <IX tagName="hidden">{children}</IX>
);

/**
 * Continuation is used in the confirmation certificate (Fastställelseintyg)
 * 1. NonNumericContinuation with id of the continuation
 * 2. Continuation tag with reference to the continuation
 */
export const NonNumericContinuation = ({
  children,
  name,
  contextRef,
  continuedAt,
}: NonNumericProps & { continuedAt: string }): React.ReactElement => (
  <IX
    tagName="nonNumeric"
    name={name}
    contextRef={contextRef}
    continuedAt={continuedAt}
  >
    {children}
  </IX>
);

export const Continuation = ({
  id,
  children,
}: WithChildren & { id: string }): React.ReactElement => (
  <IX tagName="continuation" id={id}>
    {children}
  </IX>
);

export const FSIDate = ({
  children,
  name,
  contextRef,
}: NonNumericProps): React.ReactElement => (
  <IX
    tagName="nonNumeric"
    name={name}
    contextRef={contextRef}
    id="ID_DATUM_UNDERTECKNANDE_FASTSTALLELSEINTYG"
  >
    {children}
  </IX>
);

const removeAtSign = (value: string): string => {
  return value.replace('@', '');
};

type NonNumericProps = {
  name: string;
  contextRef: string;
  tupleRef?: string;
  order?: string;
  previousYearTuple?: boolean;
  hasPreviousYear?: boolean;
} & WithChildren;

/**
 * Typically for string values
 *
 * @param children Children to be wrapped
 * @param name IX name reference, ex se-gen-base:Nettoomsattning
 * @param contextRef IX period
 * @returns Wrapped children in IX element
 */
export const NonNumeric = ({
  children,
  name,
  contextRef,
  tupleRef,
  order,
  previousYearTuple = false,
  hasPreviousYear = false,
}: NonNumericProps): React.ReactElement => {
  const additionalProps: Record<string, unknown> = {};
  if (tupleRef) additionalProps.tupleRef = removeAtSign(tupleRef);
  if (order) additionalProps.order = order;

  const contextRefWithoutYear = contextRef.slice(0, -1);

  if (previousYearTuple && tupleRef) {
    const tupleContextRef = `${removeAtSign(
      tupleRef
    )}_${contextRefWithoutYear}`;

    return (
      <IX
        tagName="nonNumeric"
        name={name}
        contextRef={`${contextRefWithoutYear}0`}
        {...additionalProps}
        tupleRef={`${tupleContextRef}0`}
      >
        {hasPreviousYear ? (
          <IX
            tagName="nonNumeric"
            name={name}
            contextRef={`${contextRefWithoutYear}1`}
            {...additionalProps}
            tupleRef={`${tupleContextRef}1`}
          >
            {children}
          </IX>
        ) : (
          children
        )}
      </IX>
    );
  }

  return (
    <IX
      tagName="nonNumeric"
      name={name}
      contextRef={contextRef}
      {...additionalProps}
    >
      {children}
    </IX>
  );
};

const negate = (value: number): number => {
  return value * -1;
};

const appendSignAttribute = (
  standardRubrik: string,
  cellValue: number
): boolean => {
  if (
    (standardRubrik.startsWith('Ökning') && cellValue < 0) || // also covers the case Ökning (minskning)
    (standardRubrik.startsWith('Minskning') && cellValue < 0) || // also covers the case Minskning (ökning)
    (standardRubrik.startsWith('Positiv') && cellValue < 0)
  ) {
    return true;
  }

  return false;
};

type NonFractionProps = {
  name: string;
  contextRef: string;
  unitRef?: string;
  decimals?: string;
  scale?: string;
  format?: string;
  cellValue?: number;
  saldo?: 'debit' | 'credit';
  tupleRef?: string;
  order?: string;
  sign?: '-';
  standardRubrik?: string; // Standardrubrik according to the excel
  negateValue?: boolean;
} & WithChildren;

/**
 * Typically number values with currency
 *
 * @param children Children to be wrapped
 * @param name IX name reference, ex se-gen-base:Nettoomsattning
 * @param contextRef IX period
 * @returns Wrapped children in IX element
 */
export const NonFraction = ({
  children,
  name,
  contextRef,
  unitRef = 'SEK',
  decimals = 'INF',
  scale = '0',
  format = 'ixt:numspacecomma',
  cellValue,
  saldo,
  tupleRef,
  order,
  sign,
  standardRubrik,
  negateValue,
}: NonFractionProps): React.ReactElement => {
  const formatting = useContext(FormattingContext);
  if (formatting.displayInThousands && unitRef === 'SEK') {
    decimals = '-3';
    scale = '3';
  }

  const additionalProps: Record<string, unknown> = {};
  if (sign) additionalProps.sign = sign;
  if (tupleRef) additionalProps.tupleRef = removeAtSign(tupleRef);

  if (cellValue) {
    // we might need to invert the value since Agoy uses the opposite sign for values in certain cases
    const val = negateValue ? negate(cellValue) : cellValue;
    if ((val < 0 && saldo === 'debit') || (val < 0 && saldo === 'credit')) {
      additionalProps.sign = '-';
    }

    if (standardRubrik && appendSignAttribute(standardRubrik, val)) {
      additionalProps.sign = '-';
    }
  }

  if (order) additionalProps.order = order;

  return (
    <IX
      tagName="nonFraction"
      name={name}
      contextRef={contextRef}
      unitRef={unitRef}
      decimals={decimals}
      scale={scale}
      format={format}
      {...additionalProps}
    >
      {children}
    </IX>
  );
};

/**
 * Percentage values
 *
 * @param props See NonFraction props
 * @returns Wrapped children in IX element
 */
export const NonFractionPercent = (
  props: NonFractionProps
): React.ReactElement => {
  const { cellValue, ...rest } = props;

  if (cellValue && cellValue < 0) {
    return (
      <NonFraction
        {...rest}
        unitRef="procent"
        format="ixt:numspacecomma"
        scale="-2"
        sign="-"
      />
    );
  }
  return (
    <NonFraction
      {...props}
      unitRef="procent"
      format="ixt:numspacecomma"
      scale="-2"
    />
  );
};

/**
 * Used by multi year overview in management report and note2.
 * AGOY-3528 unitRef had to be added as additionalProps to pass validation.
 * @param props
 * @returns
 */
export const NonFractionDecimal = ({
  children,
  name,
  contextRef,
  unitRef,
  format,
}: Omit<NonFractionProps, 'decimals' | 'scale'>): React.ReactElement => {
  const additionalProps: Record<string, unknown> = {};
  if (unitRef) additionalProps.unitRef = unitRef;
  if (format) additionalProps.format = format;

  return (
    <IX
      tagName="nonFraction"
      name={name}
      contextRef={contextRef}
      decimals="INF"
      scale="0"
      {...additionalProps}
    >
      {children}
    </IX>
  );
};

type ReferencesProps = WithChildren;

export const References = ({
  children,
}: ReferencesProps): React.ReactElement => (
  <IX tagName="references">{children}</IX>
);

type ResourcesProps = WithChildren;

export const Resources = ({ children }: ResourcesProps): React.ReactElement => (
  <IX tagName="resources">{children}</IX>
);

type TupleProps = {
  name: string;
  id?: string;
  tupleID: string;
};

export const Tuple = ({
  name,
  id,
  tupleID,
}: TupleProps): React.ReactElement => (
  <IX
    tagName="tuple"
    name={name}
    tupleID={removeAtSign(tupleID)}
    {...(id ? { id: removeAtSign(id) } : {})}
  />
);

import React from 'react';
import { useIntl } from 'react-intl';
import { Typography } from '@material-ui/core';
import styled from '@emotion/styled';

const Wrapper = styled.div`
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Container = () => {
  const { formatMessage } = useIntl();

  return (
    <Wrapper>
      <Typography variant="h1">
        {formatMessage({ id: 'integration.fortnox.failure' })}
      </Typography>
    </Wrapper>
  );
};

export default Container;

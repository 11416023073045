import React from 'react';
import Typography from '@material-ui/core/Typography';
import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';
import { useIntl } from 'react-intl';

export type LabelPlacement = 'top' | 'left';

type GenericSimplefiedProps = {
  label?: string | null | React.ReactNode;
  required?: boolean;
  children?: React.ReactNode | React.ReactNode[];
  editing?: boolean;
  value?: any;
  labelPlacement?: LabelPlacement;
  width?: number;
  inputRef?: React.Ref<any>;
  name?: string;
  className?: string;
};

interface WrapperProps {
  labelPlacement: LabelPlacement;
  label?: string | null | React.ReactNode;
  width?: number;
  editing?: boolean;
}

const Wrapper = styled('div', {
  shouldForwardProp: isPropValid,
})<WrapperProps>`
  display: flex;
  ${({ width }) => width && `width: ${width}px;`}
  ${({ labelPlacement, label }) =>
    label && labelPlacement === 'left' && 'align-items: center;'}
  ${({ label, labelPlacement }) =>
    label && labelPlacement === 'top' && 'flex-direction: column;'}
`;

const RequiredStar = styled.span`
  color: ${(props) => props.theme.palette.error.light};
`;

export const Label = styled(Typography)`
  min-width: 180px;
`;

interface NotEditingValueProps {
  isMissing?: boolean;
}

const NotEditingValue = styled(({ isMissing, ...props }) => (
  <Typography {...props} />
))<NotEditingValueProps>`
  ${(props) => props.width && `width: ${props.width}px;`}
  ${(props) => !props.value && 'height: 20px;'}
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${(props) =>
    props.isMissing
      ? props.theme.palette.text.secondary
      : props.theme.palette.text.primary};
`;

const GenericSimplefied = ({
  label,
  required,
  editing,
  children,
  value,
  labelPlacement = 'left',
  width,
  className,
}: Partial<GenericSimplefiedProps>): JSX.Element => {
  const intl = useIntl();

  // If the value is an empty string, show "missing"
  const displayValue =
    value || (value !== undefined && intl.formatMessage({ id: 'missing' }));
  const isMissing = !value;

  return (
    <Wrapper
      labelPlacement={labelPlacement}
      width={width}
      className={`generic-input-wrapper ${className || ''}`}
      label={label}
    >
      {label && (
        <Label variant="subtitle1" color="textSecondary">
          {label} {required && editing && <RequiredStar>*</RequiredStar>}
        </Label>
      )}
      {editing ? (
        children
      ) : (
        <NotEditingValue
          isMissing={isMissing}
          width={width}
          value={displayValue}
        >
          {displayValue}
        </NotEditingValue>
      )}
    </Wrapper>
  );
};

export default GenericSimplefied;

import React from 'react';
import { Tab } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { CardTabs } from '_organization/components/organisms/CardTabs';
import { ConfigTab } from './config';

interface TabRowProps {
  currentTabId: string;
  tabs: ConfigTab[];
  onTabChange: (event: any, value: any) => void;
}

const TabRow = ({ currentTabId, tabs, onTabChange }: TabRowProps) => {
  const { formatMessage } = useIntl();

  return (
    <CardTabs
      value={currentTabId}
      onChange={onTabChange}
      indicatorColor="secondary"
      textColor="secondary"
      aria-label={formatMessage({ id: 'company.create.or.join' })}
    >
      {tabs.map((tab) => (
        <Tab
          value={tab.id}
          key={tab.id}
          label={formatMessage({ id: tab.titleId })}
        />
      ))}
    </CardTabs>
  );
};

export default TabRow;

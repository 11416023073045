/* eslint-disable import/prefer-default-export */
import { isNotNull } from '@agoy/common';
import {
  ReconciliationBalanceAccountGroup,
  ReconciliationBalanceAccountRow,
  ReconciliationBalanceKeyFigure,
} from '@agoy/api-sdk-core';

export const findAccountRow = (
  rows: (
    | ReconciliationBalanceAccountRow
    | ReconciliationBalanceKeyFigure
    | ReconciliationBalanceAccountGroup
  )[],
  accountNumber: number
): ReconciliationBalanceAccountRow | null => {
  const result = rows
    .filter(
      (row): row is ReconciliationBalanceAccountRow => row.type === 'account'
    )
    .find((row) => row.number === accountNumber);
  if (result) {
    return result;
  }
  const grouped = rows
    .filter(
      (row): row is ReconciliationBalanceAccountGroup => row.type === 'group'
    )
    .flatMap((row) => findAccountRow(row.rows, accountNumber))
    .filter(isNotNull);
  return grouped[0] ?? null;
};

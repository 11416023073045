import * as t from 'io-ts';
import { isRight } from 'fp-ts/Either';
import { IntlShape } from 'react-intl';
import groupBy from 'lodash-es/groupBy';
import sortBy from 'lodash-es/sortBy';
import { TimePeriod } from '@agoy/document';
import { translations } from '@agoy/program-status';
import { addDays, isSameDay, parse } from 'date-fns';

const TransitionType = t.type({
  program: t.string,
  interval: t.string,
  intervalType: t.string,
  ruleId: t.string,
  from: t.string,
  to: t.string,
});

const ErrorResponseCodec = t.type({
  code: t.union([t.undefined, t.string]),
  message: t.union([t.undefined, t.string]),
  transitions: t.union([t.undefined, t.array(TransitionType)]),
});

type StatusGeneratorError = t.TypeOf<typeof ErrorResponseCodec>;
type Transition = t.TypeOf<typeof TransitionType>;
type Message = {
  id: string;
  values: Record<string, string>;
};

export const isStatusGeneratorError = (
  err: unknown
): err is StatusGeneratorError => {
  const result = ErrorResponseCodec.decode(err);
  if (isRight(result)) {
    return true;
  }
  console.warn(result.left);
  return false;
};

const ruleIdToErrorId: Record<string, string> = {
  'AN_REPORT.from.done.next.locked':
    'program.status.error.annualReport.next.locked',
  'AN_REPORT.from.not.done.to.locked':
    'program.status.error.annualReport.mustBeDoneToLock',
  'AN_REPORT.requires.financialStatement.done':
    'program.status.error.annualReport.financialStatement.done',
  'FIN_STATEMENT.requires.reconciliation.done':
    'program.status.error.financialStatement.reconciliation.done',
};

export const describeInvalidTransitionError = (
  err: StatusGeneratorError,
  requestedStatus: {
    program: string;
    financialYear?: string;
    period?: string;
    status: string;
  },
  formatMessage: IntlShape['formatMessage'],
  defaultErrorId
): string => {
  const result = ErrorResponseCodec.decode(err);
  if (isRight(result)) {
    const error = result.right;
    if (error.code === 'INVALID_TRANSITIONS' && error.transitions) {
      const byRule = groupBy(error.transitions, 'ruleId');

      // Get the rules we have error messages for
      const rules = Object.keys(byRule).filter(
        (ruleId) => ruleIdToErrorId[ruleId]
      );

      // Show the first error
      const rule = rules[0];

      if (rule) {
        const { interval, from, to, program } = byRule[rule][0];
        const period = TimePeriod.fromFinancialYear(interval);
        const formattedPeriod = `${period.startDateISO} - ${period.endDateISO}`;
        const id = ruleIdToErrorId[rule] || defaultErrorId;

        return formatMessage(
          {
            id,
          },
          {
            period: formattedPeriod,
            to: formatMessage(translations[to]),
            from: formatMessage(translations[from]),
            fromType: from,
            program: formatMessage({ id: program }),
          }
        );
      }
    }

    return formatMessage({ id: defaultErrorId });
  }
  console.log(result.left);
  return formatMessage({ id: 'error' });
};

const describeRequiredTransition = (
  {
    program,
    from,
    to,
    interval,
    intervalType,
    multiple,
  }: Transition & { multiple?: true },
  formatMessage: IntlShape['formatMessage']
): Message => {
  const period = TimePeriod.fromFinancialYear(interval);
  const formattedPeriod = `${period.startDateISO} - ${period.endDateISO}`;

  return {
    id: `program.status.required.${intervalType}${multiple ? '.multiple' : ''}`,
    values: {
      program: formatMessage({ id: program }),
      from: formatMessage(translations[from]),
      fromType: from,
      to: formatMessage(translations[to]),
      toType: to,
      interval: formattedPeriod,
    },
  };
};

const groupAndDescribeTransitions = (
  transitions: Transition[],
  formatMessage: IntlShape['formatMessage']
): Message[] => {
  const grouped = groupBy(transitions, (t) => `${t.from}-${t.to}`);
  return Object.values(grouped).flatMap((group): Message[] => {
    return sortBy(group, (t) => t.interval)
      .reduce((all: Transition[], t) => {
        if (all.length === 0) {
          return [t];
        }
        const last = all[all.length - 1];
        if (
          isSameDay(
            addDays(
              parse(last.interval.substring(9), 'yyyyMMdd', Date.now()),
              1
            ),
            parse(t.interval.substring(0, 8), 'yyyyMMdd', Date.now())
          )
        ) {
          const joined = {
            ...last,
            interval: `${last.interval.substring(0, 8)}-${t.interval.substring(
              9
            )}`,
            multiple: true,
          };
          return [...all.slice(0, all.length - 1), joined];
        }
        all.push(t);
        return all;
      }, [])
      .map((t) => describeRequiredTransition(t, formatMessage));
  });
};

export const describeRequiredChanges = (
  err: StatusGeneratorError,
  requestedStatus: {
    program: string;
    financialYear?: string;
    period?: string;
    status: string;
  },
  formatMessage: IntlShape['formatMessage']
): Message[] => {
  const result = ErrorResponseCodec.decode(err);
  if (isRight(result)) {
    const error = result.right;

    const byProgram = groupBy(error.transitions, 'program');
    return Object.values(byProgram).flatMap((transitions): Message[] => {
      const { financialYear = [], period = [] } = groupBy(
        transitions,
        'intervalType'
      );

      // Remove periods that exist in the financialYears
      const filteredPeriods = period.filter((p) => {
        const periodStart = p.interval.substring(0, 8);
        return !financialYear.some((y) => {
          const [start, end] = y.interval.split('-');
          return periodStart >= start && periodStart < end;
        });
      });

      return sortBy(
        [
          ...groupAndDescribeTransitions(financialYear, formatMessage),
          ...groupAndDescribeTransitions(filteredPeriods, formatMessage),
        ],
        (msg) => msg.values.interval
      );
    });
  }
  return [];
};

import React from 'react';
import { format } from 'date-fns';
import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';
import FortnoxLogo from 'assets/fortnox-logo.svg';
import { useSelector } from 'react-redux';
import { currentCustomer } from '_clients/redux/customer-view/selectors';
import { useIntl } from 'react-intl';
import { AgoyTable } from '@agoy/document';
import { CompanyType } from '_clients/types/types';

const HeaderTitleAndPage = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;

const HeaderTitle = styled(Typography)`
  font-weight: 700;
  font-size: 1.4rem;
`;

const HeaderSubTitle = styled(Typography)`
  font-weight: 700;
  font-size: 1.2rem;
`;

const FinalTaxCalculationHeader = styled.div`
  display: flex;
  gap: 80px;
  flex-direction: row;

  > div {
    flex: 1;
  }
`;

const Logo = styled.img`
  width: 150px;
  margin-bottom: 1rem;
`;

const BorderText = styled.div`
  border: 1px solid #002517;
  border-radius: 8px;
  padding: 8px 28px 8px 8px;
`;

const OrgAndPeriod = styled.div`
  > :first-of-type {
    border-bottom: 1px solid #002517;
  }
`;

const OrgNumber = styled.div`
  display: flex;
  flex-direction: column;
`;

const PeriodRange = styled.div`
  display: flex;
  gap: 20px;

  > :first-of-type {
    display: flex;
    flex-direction: column-reverse;
  }
`;

type FinalTaxCalculationPageHeaderProps = {
  orgNumber?: string | null;
  startDate?: string;
  endDate?: string;
  currentPage: number;
  totalPages: number;
  companyType: CompanyType;
};

const FinalTaxCalculationPageHeader = ({
  orgNumber,
  startDate,
  endDate,
  currentPage,
  totalPages,
  companyType,
}: FinalTaxCalculationPageHeaderProps) => {
  const { formatMessage } = useIntl();

  return (
    <FinalTaxCalculationHeader>
      <div>
        <Logo src={FortnoxLogo} />
        <BorderText>
          Den här sidan är skapad av Fortnox och är inte en del av blankettet
          från Skatteverket. Den ska därför inte inkluderas vid inlämning till
          Skatteverket.
        </BorderText>
      </div>
      <div>
        <HeaderTitleAndPage>
          <HeaderTitle>Slutlig sammanställning skatt</HeaderTitle>
          <span>
            {currentPage}({totalPages})
          </span>
        </HeaderTitleAndPage>
        <HeaderSubTitle>
          {formatMessage({
            id: `company.${companyType.replaceAll('_', '.')}`,
          })}
        </HeaderSubTitle>
        <OrgAndPeriod>
          <OrgNumber>
            <span>Organisationsnummer</span>
            <span>{orgNumber}</span>
          </OrgNumber>
          <PeriodRange>
            <div>Räkenskapsår</div>
            {startDate && (
              <OrgNumber>
                <span>Från och med</span>
                <span>{startDate}</span>
              </OrgNumber>
            )}
            {endDate && (
              <OrgNumber>
                <span>Till och med</span>
                <span>{endDate}</span>
              </OrgNumber>
            )}
          </PeriodRange>
        </OrgAndPeriod>
      </div>
    </FinalTaxCalculationHeader>
  );
};

export default FinalTaxCalculationPageHeader;

import React, { useMemo } from 'react';
import { Field } from '@agoy/document';
import { isActive } from '@agoy/annual-report-document';
import Typography from '../../../UI/Typography/Typography';
import { IXElement } from '../../../ARKeyToIX';

interface MultilineFieldProps {
  field: Field | undefined;
  header?: React.ReactElement;
}

const splitText = (value: string): string[] => value.split(/\r\n|\r|\n/g);

const MultilineField = ({ field, header }: MultilineFieldProps) => {
  if (!field) {
    return null;
  }

  const splittedForPrint = useMemo(() => {
    switch (field.type) {
      case 'string':
      case 'msg':
        return splitText(typeof field.value === 'string' ? field.value : '');
      default:
        return [];
    }
  }, [field]);

  return isActive(field.active) === false || !splittedForPrint.length ? null : (
    <>
      {header}
      <IXElement arPart={field}>
        <div>
          {splittedForPrint
            .filter((item) => item !== '')
            .map((part) => {
              return <Typography key={part}>{part?.trim()}</Typography>;
            })}
        </div>
      </IXElement>
    </>
  );
};

export default MultilineField;

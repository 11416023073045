import {
  AnnualReport,
  AnnualReportType,
  getContentDefinition,
} from '@agoy/annual-report-document';
import { isSinglePeriodSelected } from '@agoy/annual-report-view';
import { booleanValue } from '@agoy/document';

const excludedParts = [
  'settings',
  'annualGeneralMeeting',
  'confirmationCertificate',
  'digitalSubmission',
];

const excludedPartsWhenSinglePeriod = [
  'managementReport',
  'notes',
  'manualSubmission',
  'cashFlowStatement',
  'signatures',
];

const extractPartsToPrint = (
  report: AnnualReport | undefined,
  reportType?: AnnualReportType,
  hasSignatures?: boolean
): string[] => {
  if (!report || !reportType) {
    return [];
  }

  const singlePeriodSelected = isSinglePeriodSelected(report.settings);
  const content = getContentDefinition(report, reportType);

  const parts = Object.keys(content.children)
    .filter((part) => !excludedParts.includes(part))
    .filter((part) =>
      singlePeriodSelected
        ? !excludedPartsWhenSinglePeriod.includes(part)
        : part
    )
    .filter((part) => {
      return Object.keys(content.children[part].children)
        .filter((section) => report[part][section] !== undefined)
        .some((section) => booleanValue(report[part][section].active, true));
    });

  if (hasSignatures) {
    return [...parts, 'signatures'];
  }

  return [...parts];
};

export default extractPartsToPrint;

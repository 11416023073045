import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import merge from 'lodash/merge';
import { ClientAction, ClientYearAction } from 'redux/actions';
import { RootState } from 'redux/reducers';
import get from 'lodash/get';
import { addDays, format, parse } from 'date-fns';

import { datesToString, reformatISODate } from '@agoy/common';
import { FinancialYear, Period, StockInventoryTable } from '@agoy/api-sdk-core';

import {
  CustomerInvoicesResponse,
  SupplierInvoicesResponse,
  UserInput,
  CreateLegacySpecification,
  InputData,
  UserAccountInput,
  Routines,
  VacationDebt,
} from '_reconciliation/types';
import {
  addGlobalErrorMessage,
  addGlobalMessage,
} from '_messages/redux/actions';
import { AccountingBalances, AccountingAccount } from 'types/Accounting';
import { asResultClass, getApiSdk } from 'api-sdk';
import { getContext } from 'utils/AgoyAppClient/contextHolder';
import hasOwnProperty from 'utils/hasOwnProperty';
import { createInputData } from 'utils/UserInputUtils';
import OverviewTable from 'types/OverviewTable/types';
import { PeriodStatus } from '_shared/types';
import {
  getFormattedPeriod,
  legacySpecificationSum,
} from '_reconciliation/components/ReconciliationView/HiddenRow/HiddenGroupRow/utils';
import { createPeriods } from '_reconciliation/components/ReconciliationView/HiddenRow/HiddenGroupRow/utils/groupAccounts';

import { parseFormat } from '@agoy/dates';
import {
  PATCH_USER_INPUT_DATA,
  PATCH_USER_INPUT_ROUTINE_DATA,
  SAVING_USER_DATA_FAILED,
  SAVING_USER_DATA_SUCCESS,
  SET_CUSTOMER_INVOICES,
  SET_NUM_DOCUMENTS,
  SET_ROUTINES_DATA,
  SET_USER_INPUT_DATA,
  STARTED_SAVING_USER_DATA,
  SET_PERIOD_CHANGE_VISIBILITY,
  SET_PERIOD_UB_VISIBILITY,
  TOGGLE_PERIOD_DONE_VISIBILITY,
  TOGGLE_PERIOD_LOCKED_VISIBILITY,
  SET_SINGLE_PERIOD,
  SET_USER_INPUT_DOCUMENTS,
  SET_ACCOUNTING_BALANCES,
  SET_SUPPLIER_INVOICES,
  SET_VACATION_DEBT,
  ADD_PROGRAM_PERIODS_STATUS,
  SET_STOCK_INVENTORY_TABLE,
  SET_MOVING_ACCOUNTS_MODE,
} from './action-types';
import { currentClientYear } from './selectors';
import { getAccountsWithTransactionsInPeriod } from './utils';

interface StartedSavingUserDataAction {
  type: typeof STARTED_SAVING_USER_DATA;
}

export const startedSavingUserData = (): StartedSavingUserDataAction => ({
  type: STARTED_SAVING_USER_DATA,
});

interface SavingUserDataFailedAction {
  type: typeof SAVING_USER_DATA_FAILED;
}

export const savingUserDataFailed = (): SavingUserDataFailedAction => ({
  type: SAVING_USER_DATA_FAILED,
});

interface SavingUserDataSuccessAction {
  type: typeof SAVING_USER_DATA_SUCCESS;
}

export const savingUserDataSuccess = (): SavingUserDataSuccessAction => ({
  type: SAVING_USER_DATA_SUCCESS,
});

interface SetUserInputDataAction extends ClientYearAction {
  type: typeof SET_USER_INPUT_DATA;
  userInput: UserInput;
}

export const setUserInputData = (
  clientId: string,
  financialYear: FinancialYear,
  userInput: UserInput
): SetUserInputDataAction => {
  return {
    type: SET_USER_INPUT_DATA,
    clientId,
    financialYear: datesToString(financialYear),
    userInput,
  };
};

interface SetRoutinesDataAction extends ClientAction {
  type: typeof SET_ROUTINES_DATA;
  routines: Routines;
}

export const setRoutinesData = (
  clientId: string,
  routines: Routines
): SetRoutinesDataAction => {
  return {
    type: SET_ROUTINES_DATA,
    clientId,
    routines,
  };
};

export const insertSpecification =
  (
    clientId: string,
    financialYear: FinancialYear,
    accountNumber: string,
    specification: CreateLegacySpecification,
    period: Period
  ) =>
  (dispatch, getState) => {
    const state: RootState = getState();
    const userInput =
      state.accountingView.clients[clientId]?.years[
        datesToString(financialYear)
      ]?.userInput;
    if (!userInput) {
      console.error('No userInput');
      return;
    }
    const formattedPeriod = reformatISODate(period.start, 'yyyy-MM');
    const specifications =
      userInput?.[`account${accountNumber}`]?.[formattedPeriod]
        ?.legacySpecifications || [];

    const nextId =
      specifications.length === 0
        ? 1
        : [...specifications].sort((a, b) => b.id - a.id)[0].id + 1;

    const newSpecifications = [
      ...specifications,
      { ...specification, id: nextId },
    ];
    dispatch(
      patchUserInputData(clientId, financialYear, period, {
        newUserInput: {
          legacySpecifications: newSpecifications,
          saldo: `${legacySpecificationSum(newSpecifications)}`,
        },
        accountNumber,
      })
    );
  };

interface PatchUserInputDataAction extends ClientYearAction {
  type: typeof PATCH_USER_INPUT_DATA;
  newUserInput: Partial<InputData>;
  accountNumber: string;
  formattedPeriod: string;
  periodId: number;
}

export const patchUserInputData = (
  clientId: string,
  financialYear: FinancialYear,
  period: Period,
  {
    newUserInput,
    accountNumber,
  }: {
    newUserInput: Partial<InputData>;
    accountNumber: string;
  }
): PatchUserInputDataAction => ({
  type: PATCH_USER_INPUT_DATA,
  clientId,
  financialYear: datesToString(financialYear),
  newUserInput,
  accountNumber,
  formattedPeriod: reformatISODate(period.start, 'yyyy-MM'),
  periodId: period.id,
});

interface PatchUserInputRoutineDataAction extends ClientAction {
  type: typeof PATCH_USER_INPUT_ROUTINE_DATA;
  newUserInput: any;
  accountNumber: string;
  formattedPeriod: string;
}

export const patchUserInputRoutineData = (
  clientId: string,
  {
    newUserInput,
    accountNumber,
    formattedPeriod,
  }: {
    newUserInput: any;
    accountNumber: string;
    formattedPeriod: string;
  }
): PatchUserInputRoutineDataAction => ({
  type: PATCH_USER_INPUT_ROUTINE_DATA,
  clientId,
  newUserInput,
  accountNumber,
  formattedPeriod,
});

export interface SetNumDocumentsAction extends ClientYearAction {
  type: typeof SET_NUM_DOCUMENTS;
  accountNumber: string;
  formattedPeriod: string;
  numImages: number;
  periodId: number;
}

export const setNumDocuments = (
  clientId: string,
  financialYear: FinancialYear,
  accountNumber: string,
  formattedPeriod: string,
  periodId: number,
  numImages: number
): SetNumDocumentsAction => ({
  type: SET_NUM_DOCUMENTS,
  clientId,
  financialYear: datesToString(financialYear),
  accountNumber,
  formattedPeriod,
  periodId,
  numImages,
});

export const increaseDocuments =
  (
    clientId: string,
    financialYear: FinancialYear,
    accountNumber: string,
    formattedPeriod: string,
    periodId: number,
    count = 1
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    const userInputDocuments =
      getState().accountingView.clients[clientId].years[
        datesToString(financialYear)
      ];

    const numDocumentsBeforeIncrease = get(
      userInputDocuments,
      `userInputDocuments[account${accountNumber}documents][${formattedPeriod}]`,
      0
    );
    const numDocumentsIncreased = numDocumentsBeforeIncrease + count;

    dispatch(
      setNumDocuments(
        clientId,
        financialYear,
        accountNumber,
        formattedPeriod,
        periodId,
        numDocumentsIncreased
      )
    );
  };

export const decreaseDocuments =
  (
    clientId: string,
    financialYear: FinancialYear,
    accountNumber: string,
    formattedPeriod: string,
    periodId: number,
    count = 1
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    const userInputDocuments =
      getState().accountingView.clients[clientId].years[
        datesToString(financialYear)
      ];

    const numDocumentsBeforeDecrease = get(
      userInputDocuments,
      `userInputDocuments[account${accountNumber}documents][${formattedPeriod}]`,
      0
    );
    const numDocumentsDecreased = numDocumentsBeforeDecrease - count;

    dispatch(
      setNumDocuments(
        clientId,
        financialYear,
        accountNumber,
        formattedPeriod,
        periodId,
        numDocumentsDecreased
      )
    );
  };

interface SetSinglePeriodAction {
  type: typeof SET_SINGLE_PERIOD;
  period?: string;
  periodType?: string;
}

export const setSinglePeriod = (
  period?: string,
  periodType?: string
): SetSinglePeriodAction => ({
  type: SET_SINGLE_PERIOD,
  period,
  periodType,
});

interface SetPeriodChangeVisibilityAction {
  type: typeof SET_PERIOD_CHANGE_VISIBILITY;
  value: boolean;
}

export const setPeriodChangeVisibility = (
  value: boolean
): SetPeriodChangeVisibilityAction => ({
  type: SET_PERIOD_CHANGE_VISIBILITY,
  value,
});

interface SetPeriodUBVisibilityAction {
  type: typeof SET_PERIOD_UB_VISIBILITY;
  value: boolean;
}

export const setPeriodUBVisibility = (
  value: boolean
): SetPeriodUBVisibilityAction => ({
  type: SET_PERIOD_UB_VISIBILITY,
  value,
});

interface SetMovingAccountsModeAction {
  type: typeof SET_MOVING_ACCOUNTS_MODE;
  value: boolean;
}

export const setMovingAccountsMode = (
  value: boolean
): SetMovingAccountsModeAction => ({
  type: SET_MOVING_ACCOUNTS_MODE,
  value,
});

interface TogglePeriodDoneVisibilityAction {
  type: typeof TOGGLE_PERIOD_DONE_VISIBILITY;
}

export const togglePeriodDoneVisibility =
  (): TogglePeriodDoneVisibilityAction => ({
    type: TOGGLE_PERIOD_DONE_VISIBILITY,
  });

interface TogglePeriodLockedVisibilityAction {
  type: typeof TOGGLE_PERIOD_LOCKED_VISIBILITY;
}

export const togglePeriodLockedVisibilityAction =
  (): TogglePeriodLockedVisibilityAction => ({
    type: TOGGLE_PERIOD_LOCKED_VISIBILITY,
  });

export const togglePeriodLockedVisibility =
  (): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    const { periodLockedVisible } = getState().accountingView;
    localStorage.setItem(
      'periodLockedVisible',
      periodLockedVisible ? '' : 'active'
    );
    dispatch(togglePeriodLockedVisibilityAction());
  };

interface SetCustomerInvoicesAction extends ClientYearAction {
  type: typeof SET_CUSTOMER_INVOICES;
  payload: CustomerInvoicesResponse;
}
interface SetSupplierInvoicesAction extends ClientYearAction {
  type: typeof SET_SUPPLIER_INVOICES;
  payload: SupplierInvoicesResponse;
}

export const setCustomerInvoices = (
  clientId: string,
  financialYear: string,
  data: CustomerInvoicesResponse
): SetCustomerInvoicesAction => ({
  type: SET_CUSTOMER_INVOICES,
  clientId,
  financialYear,
  payload: data,
});

export const setSupplierInvoices = (
  clientId: string,
  financialYear: string,
  data: SupplierInvoicesResponse
): SetSupplierInvoicesAction => ({
  type: SET_SUPPLIER_INVOICES,
  clientId,
  financialYear,
  payload: data,
});

const updateUserInput =
  ({
    clientId,
    year,
    month,
    financialYear,
    periodId,
    userInput,
    type,
  }: {
    clientId: string;
    year: string;
    month: string;
    financialYear: FinancialYear;
    periodId: number;
    userInput: UserAccountInput;
    notChangedPeriods?: number[];
    type: 'all' | 'not_changed';
  }): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    const currentUserInput = currentClientYear((state) => state.userInput)(
      getState()
    );

    const sdk = getApiSdk(getContext());

    await asResultClass(
      sdk.fillActualBalance({
        clientid: clientId,
        periodId,
        type,
        notChangedPeriods: [periodId],
      })
    );

    const userInputUpdate: UserInput = {};

    Object.keys(userInput).forEach((item) => {
      userInputUpdate[item] = {
        [`${year}-${month}`]: {
          ...userInput[item],
        },
      };
    });

    dispatch(
      setUserInputData(
        clientId,
        financialYear,
        merge({}, currentUserInput, userInputUpdate)
      )
    );
  };

export const patchMultipleUserInputData =
  (
    period: Period,
    financialYear: FinancialYear
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    try {
      const formattedPeriod = getFormattedPeriod(period);

      dispatch(startedSavingUserData());

      const { currentCustomer, currentFinancialYear } = getState().customerView;
      const accountingBalances = currentClientYear(
        (state) => state.accountingBalances
      )(getState());
      if (!accountingBalances || !currentCustomer || !currentFinancialYear) {
        dispatch(savingUserDataFailed());
        return;
      }

      const userInput = currentClientYear((state) => state.userInput)(
        getState()
      );

      if (!userInput) {
        dispatch(savingUserDataFailed());
        return;
      }
      const [year, month] = formattedPeriod.split('-');

      let filteredAccountsData: {
        account: string;
        saldo: string;
      }[] = [];

      // update only accounts with number from 1000 to 3000 and with zero saldo
      const filterAccounts = (accounts: OverviewTable.AccountInformation[]) => {
        accounts.forEach((accountItem) => {
          const accountNumber = Number.parseInt(accountItem.account, 10);
          if (accountNumber >= 1000 && accountNumber < 3000) {
            accountItem.periods.forEach((item) => {
              const saldo = item.ib.toString();
              const userInputItem =
                userInput[`account${accountItem.account}`]?.[formattedPeriod];

              if (
                item.period.startsWith(formattedPeriod) &&
                item.change === 0 &&
                (!userInputItem?.visited || userInputItem?.saldo !== saldo)
              ) {
                filteredAccountsData.push({
                  account: accountItem.account,
                  saldo,
                });
              }
            });
          }
        });
      };

      const previousFinancialYearEnd = format(
        addDays(parse(financialYear.start, 'yyyy-MM-dd', Date.now()), -1),
        'yyyy-MM-dd'
      );
      const previousFinancialYear = accountingBalances.financialYears.find(
        (y) => y.end === previousFinancialYearEnd
      );

      const accountsData = accountingBalances.accounts.map((account) =>
        createPeriods(
          financialYear,
          previousFinancialYear ?? null,
          accountingBalances.periods,
          accountingBalances.periods,
          account
        )
      );

      filterAccounts(accountsData);

      if (accountsData.length === 0) {
        // No accounts needs update
        dispatch(savingUserDataSuccess());
        dispatch(addGlobalMessage('success', 'overview.saldo.successMessage'));
        return;
      }

      // update only accounts without transactions
      const transactionPerPeriodAndAccount =
        getAccountsWithTransactionsInPeriod(accountingBalances, period.id);

      filteredAccountsData = filteredAccountsData.filter(
        (item) => !transactionPerPeriodAndAccount?.has(item.account)
      );

      if (filteredAccountsData.length === 0) {
        // No accounts to update
        dispatch(savingUserDataSuccess());
        dispatch(addGlobalMessage('success', 'overview.saldo.successMessage'));
        return;
      }

      const updates: {
        [accountNumber: string]: InputData;
      } = {};

      filteredAccountsData.forEach((item) => {
        const accountUserInput = userInput[`account${item.account}`];
        const periodUserInput = accountUserInput
          ? accountUserInput[formattedPeriod]
          : {};

        const inputData = createInputData({
          ...periodUserInput,
          saldo: item.saldo,
          visited: true,
        });

        updates[`account${item.account}`] = { ...inputData };
      });

      try {
        await dispatch(
          updateUserInput({
            clientId: currentCustomer,
            year,
            month,
            periodId: period.id,
            financialYear,
            userInput: updates,
            type: 'not_changed',
            notChangedPeriods: [period.id],
          })
        );

        dispatch(savingUserDataSuccess());
        dispatch(addGlobalMessage('success', 'overview.saldo.successMessage'));
      } catch (err) {
        if (typeof err === 'object' && hasOwnProperty(err, 'code')) {
          if (err.code === 'RECONCILIATION.LOCKED') {
            dispatch(addGlobalErrorMessage('error.RECONCILIATION.LOCKED'));
          }
        } else {
          console.error('Unexpected error', err);
          dispatch(addGlobalErrorMessage('error'));
        }
        dispatch(savingUserDataFailed());
      }
    } catch (e) {
      dispatch(savingUserDataFailed());
      dispatch(addGlobalErrorMessage('error', 'overview.saldo.errorMessage'));
    }
  };

const findOutgoingBalance = (
  accountingBalances: AccountingBalances,
  account: AccountingAccount,
  period: Period,
  financialYearId: number
): number | undefined => {
  const balance = account.periods[period.id];
  if (balance && (balance.out !== undefined || balance.in !== undefined)) {
    return balance.out ?? balance.in;
  }
  const pIndex = accountingBalances.periods.findIndex(
    (p) => p.id === period.id
  );
  if (pIndex > 0) {
    const prevPeriod = accountingBalances.periods
      .slice(0, pIndex)
      .reverse()
      .find((p) => account.periods[p.id]?.out !== undefined);
    if (prevPeriod) {
      return account.periods[prevPeriod.id].out;
    }
  }
  return account.balances[financialYearId].in;
};

export const makeGreen =
  (
    period: Period,
    financialYearId: number
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    try {
      const { currentCustomer, currentFinancialYearId } =
        getState().customerView;
      const { accountingBalances, userInput } =
        currentClientYear((state) => state)(getState()) || {};

      const formattedPeriod = parseFormat(period.start, 'yyyy-MM');

      if (accountingBalances && userInput && currentCustomer) {
        const currentFinancialYear = getState().customers[
          currentCustomer
        ].rawFinancialYears.find((fy) => fy.id === currentFinancialYearId);
        if (!currentFinancialYear) {
          return;
        }

        // Create or update the existing userInputData
        const updated = accountingBalances.accounts
          .filter((a) => a.number < '3000')
          .reduce((monthData: UserAccountInput, account) => {
            const accountKey = `account${account.number}`;

            const out = findOutgoingBalance(
              accountingBalances,
              account,
              period,
              financialYearId
            );
            if (out === undefined) {
              return monthData;
            }

            if (userInput[accountKey]?.[formattedPeriod]) {
              monthData[accountKey] = createInputData({
                ...userInput[accountKey][formattedPeriod],
                saldo: Math.round(out).toFixed(0),
                visited: true,
              });
            } else {
              monthData[accountKey] = createInputData({
                saldo: Math.round(out).toFixed(0),
                visited: true,
              });
            }
            return monthData;
          }, {});

        await dispatch(
          updateUserInput({
            clientId: currentCustomer,
            year: parseFormat(period.start, 'yyyy'),
            month: parseFormat(period.start, 'MM'),
            financialYear: currentFinancialYear,
            periodId: period.id,
            userInput: updated,
            type: 'all',
          })
        );
        dispatch(
          addGlobalMessage('success', 'overview.saldo.makeAllSuccessMessage')
        );
      }
    } catch (err) {
      if (typeof err === 'object' && hasOwnProperty(err, 'code')) {
        if (err.code === 'RECONCILIATION.LOCKED') {
          dispatch(addGlobalErrorMessage('error.RECONCILIATION.LOCKED'));
        }
      } else {
        console.error('Unexpected error', err);
        dispatch(addGlobalErrorMessage('error'));
      }
      dispatch(savingUserDataFailed());
    }
  };

type UserInputDocuments = {
  [id: string]: {
    [period: string]: number;
  };
};

interface SetUserInputDocumentsAction extends ClientYearAction {
  type: typeof SET_USER_INPUT_DOCUMENTS;
  userInputDocuments: UserInputDocuments;
}

export const setUserInputDocuments = (
  clientId: string,
  financialYear: FinancialYear,
  userInputDocuments: UserInputDocuments
): SetUserInputDocumentsAction => ({
  type: SET_USER_INPUT_DOCUMENTS,
  clientId,
  financialYear: datesToString(financialYear),
  userInputDocuments,
});

interface SetAccountingBalancesActions extends ClientYearAction {
  type: typeof SET_ACCOUNTING_BALANCES;
  accountingBalances: AccountingBalances | null;
  updatedAt: number;
}

export const setAccountingBalances = (
  clientId: string,
  financialYear: string,
  accountingBalances: AccountingBalances | null,
  updatedAt: number
): SetAccountingBalancesActions => ({
  type: SET_ACCOUNTING_BALANCES,
  clientId,
  financialYear,
  accountingBalances,
  updatedAt,
});

interface SetVacationDebtAction {
  type: typeof SET_VACATION_DEBT;
  payload?: VacationDebt;
}

export const setVacationDebt = (
  vacationDebt?: VacationDebt
): SetVacationDebtAction => ({
  type: SET_VACATION_DEBT,
  payload: vacationDebt,
});

interface SetStockInventoryTable {
  type: typeof SET_STOCK_INVENTORY_TABLE;
  payload?: StockInventoryTable;
}

export const setStockInventoryTable = (
  stockInventoryTable?: StockInventoryTable
): SetStockInventoryTable => ({
  type: SET_STOCK_INVENTORY_TABLE,
  payload: stockInventoryTable,
});

interface AddProgramPeriodStatusAction extends ClientAction {
  type: typeof ADD_PROGRAM_PERIODS_STATUS;
  status: PeriodStatus;
}

export const addProgramPeriodStatus = (
  clientId: string,
  status: PeriodStatus
): AddProgramPeriodStatusAction => {
  return {
    type: ADD_PROGRAM_PERIODS_STATUS,
    clientId,
    status,
  };
};

export type AccountingViewAction =
  | StartedSavingUserDataAction
  | SavingUserDataFailedAction
  | SavingUserDataSuccessAction
  | SetUserInputDataAction
  | SetRoutinesDataAction
  | PatchUserInputDataAction
  | PatchUserInputRoutineDataAction
  | SetNumDocumentsAction
  | SetSinglePeriodAction
  | SetPeriodChangeVisibilityAction
  | SetPeriodUBVisibilityAction
  | TogglePeriodDoneVisibilityAction
  | TogglePeriodLockedVisibilityAction
  | SetCustomerInvoicesAction
  | SetSupplierInvoicesAction
  | SetUserInputDocumentsAction
  | SetAccountingBalancesActions
  | SetVacationDebtAction
  | AddProgramPeriodStatusAction
  | SetStockInventoryTable
  | SetMovingAccountsModeAction;

import { isEqual } from 'lodash';
import { resolveReference } from '@agoy/document';
import { TaxCalculationRow, TaxTable, TaxCalculationContext } from './types';
import { updateContext } from './context';

export const calculateRow = <RowType extends TaxCalculationRow>(
  rowBefore: RowType,
  context: TaxCalculationContext,
  hidden: boolean | undefined = undefined
): RowType => {
  const row =
    hidden === undefined || rowBefore?.hidden === hidden
      ? rowBefore
      : { ...rowBefore, hidden };

  if (row.reference) {
    const refValue = resolveReference(row.reference, context);
    const value = typeof refValue === 'number' ? refValue : undefined;
    const error = typeof refValue === 'object' ? refValue.error : undefined;

    if (value === row.value && error === row.error) {
      return updateContext(context, row);
    }
    return updateContext(context, { ...row, value, error });
  }
  return updateContext(context, row);
};

const sumRows = <T extends TaxCalculationRow>(rows: T[]): number => {
  const sum = rows
    .filter((row) => !row.deleted)
    // NOTE: to avaoid floating precision problem we convert 'row.value' into cents/öre
    .reduce((sum, row) => (row.value ? sum + row.value * 100 : sum), 0);
  // sum is in cents/öre thats why we divide back with 100
  return sum / 100;
};

export const calculateTaxTable = <RowType extends TaxCalculationRow>(
  taxTable: TaxTable<RowType>,
  context: TaxCalculationContext,
  calcRow: (
    row: RowType,
    context: TaxCalculationContext,
    hidden?: boolean | undefined
  ) => RowType = calculateRow
): TaxTable<RowType> => {
  const rows = taxTable.rows.map((row) => calcRow(row, context));
  const sum: RowType = {
    ...taxTable.sum,
    value: sumRows(rows),
  };

  const nextTaxTable: TaxTable<RowType> = {
    rows,
    sum,
  };

  updateContext(context, sum);

  if (isEqual(rows, taxTable.rows) && isEqual(sum, taxTable.sum)) {
    return taxTable;
  }

  return nextTaxTable;
};

/**
 * according to the rules if the second to last digit is even
 * the person is female, otherwise it is a male
 */
export const isFemale = (personNumber: string) => {
  if (personNumber.length === 0) {
    return true;
  }

  const personNumberDigits = personNumber.split('');
  const previousLastDigit = personNumberDigits[personNumberDigits.length - 2];
  return Number(previousLastDigit) % 2 === 0;
};

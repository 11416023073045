import { RootState } from 'redux/reducers';
import { TaxViewClientYearState } from './reducer';

export const currentTaxView = (
  state: RootState
): TaxViewClientYearState | undefined => {
  const { currentCustomer, currentFinancialYear } = state.customerView;
  if (currentCustomer && currentFinancialYear) {
    return state.taxView.clients[currentCustomer]?.years[currentFinancialYear];
  }
  return undefined;
};

export const selectFromCurrentTaxView =
  <T>(
    selector: (state: TaxViewClientYearState) => T
  ): ((state: RootState) => T | undefined) =>
  (state) => {
    const view = currentTaxView(state);
    if (view) {
      return selector(view);
    }
    return undefined;
  };

export const overdepreciationDocument = (state) => {
  const view = currentTaxView(state);

  if (view) {
    return view.state?.document.overdepreciation;
  }

  return undefined;
};

export const currentParticularSalaryTaxDocument = (state) => {
  const view = currentTaxView(state);

  if (view) {
    return view.state?.document.particularSalaryTax;
  }

  return undefined;
};

export const currentParticularSalaryFORATaxDocument = (state) => {
  const view = currentTaxView(state);

  if (view) {
    return view.state?.document.particularSalaryTax.fora;
  }

  return undefined;
};

export const currentParticularSalaryITPDocument = (state) => {
  const view = currentTaxView(state);

  if (view) {
    return view.state?.document.particularSalaryTax.itp;
  }

  return undefined;
};

export const currentParticularSalaryOtherPensionCommitments = (state) => {
  const view = currentTaxView(state);

  if (view) {
    return view.state?.document.particularSalaryTax.otherPensionCommitments;
  }

  return undefined;
};

import { ref, table, value } from '@agoy/document';
import { NoteOneTableSection } from '../../../../common';
import { previousYear, year } from '../../../../common/utils/date-util';
import {
  sumAccountsIBs,
  sumNotesRowsById,
} from '../../../../common/utils/accountSumsUtil';
import { field, range } from '../../../../common/utils/util';
import { NoteAccountRanges } from '../types';

export const note40Accounts: NoteAccountRanges[] = [
  {
    rowId: '1',
    ranges: [[2096]],
    cellLabel: 'Belopp vid årets ingång',
    action: 'sumAccountsIBs',
  },
];

export const getNote40 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null,
  documentTypeVersion = '1'
): NoteOneTableSection => {
  return {
    active: false,
    number: value(undefined),
    name: value('Fond för verkligt värde'),
    table: table(
      'notes.note40.table',
      { id: 'label', label: '' },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      .addRows((rows) =>
        rows
          .addRow(
            '1',
            noteLabel('Belopp vid årets ingång'),
            ref(sumAccountsIBs('2096', true)),
            ref(sumAccountsIBs('2096', true, 'previousYear'))
          )
          .addRow(
            '2',
            noteLabel('Ökning av verkligt värde avseende'),
            value(undefined), // no account ranges for all rows, except 1st above
            value(undefined)
          )
          .addRow(
            '3',
            noteLabel('Finansiella tillgångar som kan säljas'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '4',
            noteLabel('Kassaflödessäkring'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '5',
            noteLabel('Säkring av nettoinvestering i utländska dotterbolag'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '6',
            noteLabel('Minskning av verkligt värde avseende'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '7',
            noteLabel('Finansiella tillgångar som kan säljas'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '8',
            noteLabel('Kassaflödessäkring'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '9',
            noteLabel('Säkring av nettoinvestering i utländska dotterbolag'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '10',
            noteLabel('Överfört till resultaträkningen till följd av'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '11',
            noteLabel('Försäljning av finansiella tillgångar som kan säljas'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '12',
            noteLabel('Avräkning av kassaflödessäkring'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '13',
            noteLabel('Belopp vid årets utgång'),
            sumNotesRowsById(40, range(1, 13), 'year'),
            sumNotesRowsById(40, range(1, 13), 'previousYear')
          )
          .build()
      )
      .newRowTemplate(value(''), value(''), value(undefined))
      .build(),
    commentLabel: field('Kommentar'),
    comment: field(''),
    group: value(undefined),
    section: value(undefined),
    validForReportType: {
      k2: true,
      k3: documentTypeVersion === '1',
    },
  };
};

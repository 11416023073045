import React from 'react';
import { CommonNotes, Note1Section } from '@agoy/annual-report-document';
import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';

const PrintSection = styled.div`
  padding: ${(props) => props.theme.spacing(1)}px;
  margin: ${(props) => props.theme.spacing(1, 0)};
`;

type IncomeTaxSectionProps = {
  note1: CommonNotes['note1'];
  id: string;
  title?: string;
};

const Paragraph = ({
  note1,
  id,
}: IncomeTaxSectionProps): JSX.Element | null => {
  const { value, active } = note1[id];

  // Don't render fields that aren't selected
  if (!active) {
    return null;
  }

  return (
    <PrintSection>
      <Typography>{value}</Typography>
    </PrintSection>
  );
};

export default Paragraph;

import React from 'react';
import Container from './Container';

interface TabPanelProps {
  children?: React.ReactNode;
  name: string;
  index: any;
  value: any;
  withoutPadding?: boolean;
}

function CardTabPanel(props: TabPanelProps) {
  const {
    children,
    name,
    value,
    index,
    withoutPadding = false,
    ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`${name}-tabpanel-${index}`}
      aria-labelledby={`${name}-tab-${index}`}
      {...other}
    >
      {value === index &&
        (!withoutPadding ? <Container>{children}</Container> : children)}
    </div>
  );
}

export default CardTabPanel;

import React from 'react';
import { Box } from '@material-ui/core';
import { NoteOneTableSection } from '@agoy/annual-report-document';
import { Cell, numberValue, stringValue } from '@agoy/document';
import Comment from '../Comment';
import Header from '../Header';
import { Note } from '../Note';
import NoteTable from '../NoteTable';

type ThreeColumnNoteProps = {
  noteId: string;
  rowsInGroupsOf?: number;
  commentLabel: Cell | undefined;
  title: string;
  displayOnlyStringRow?: boolean;
  hasPreviousYear?: boolean;
  displayUnfiltered?: boolean;
  isXBRLRender?: boolean;
} & NoteOneTableSection;

const ThreeColumnNote = ({
  active,
  noteId,
  number,
  table,
  rowsInGroupsOf,
  commentLabel,
  comment,
  title,
  displayOnlyStringRow,
  hasPreviousYear = false,
  displayUnfiltered,
  isXBRLRender = false,
}: ThreeColumnNoteProps) => {
  if (!active) return null;

  return (
    <Note id={noteId}>
      <Header noteId={noteId} noteNumber={numberValue(number)} title={title} />

      <Box mt={1} mb={1}>
        <NoteTable
          hasPreviousYear={hasPreviousYear}
          table={table}
          tableId={`notes.${noteId}.table`}
          groupRowsInterval={rowsInGroupsOf}
          displayOnlyStringRow={displayOnlyStringRow}
          displayUnfiltered={displayUnfiltered}
          isXBRLRender={isXBRLRender}
        />
      </Box>

      {/* Comment */}
      <Comment
        field={comment}
        value={stringValue(comment) || ''}
        labelValue={stringValue(commentLabel) || ''}
      />
    </Note>
  );
};

export default ThreeColumnNote;

import React, { useContext } from 'react';
import styled from '@emotion/styled';

import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

import FinancialReportViewServiceContext from '_financial-report/service/FinancialReportViewServiceContext';

import EditField from '../../../../_shared/components/Inputs/EditField';

interface EditableTextBlockProps {
  fieldId: string;
  defaultText: string;
  isEditing: boolean;
  styles?: any;
  print?: boolean;
}

type StyledBoxProps = {
  styles?: any;
  print?: boolean;
};

const StyledTextBox = styled.div<StyledBoxProps>`
  width: 100%;
  min-height: ${(props) => props.styles.height};
  height: ${(props) => props.styles.height};
  margin-top: 5px;
  margin-bottom: 5px;
  padding: ${(props) => props.styles.padding};
  color: ${(props) =>
    props.styles?.color || `${props.theme.palette.text.primary}`};
  overflow: hidden;
  display: flex;
  align-items: flex-start;
`;

const EditableTextField = styled(
  ({ disableUnderline, ...rest }) => (
    <TextField multiline fullWidth size="small" variant="outlined" {...rest} />
  ),
  {
    shouldForwardProp: (prop) => prop !== 'print',
  }
)<StyledBoxProps>`
  .MuiInputBase-root {
    width: 100%;
    height: ${(props) => props.styles.height};
    align-items: flex-start;
    font-size: ${({ print }) => (print ? '1rem' : '0.6rem')};
    font-weight: ${(props) => (props.styles.fontBold ? 700 : 500)};
    margin-bottom: ${({ print }) => (print ? '10px' : '5px')};
    overflow: hidden;
    padding: ${(props) =>
      props.styles.padding || `${props.theme.spacing(1)}px`};
  }
`;

export const StyledText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'print',
})<StyledBoxProps>`
  font-size: ${({ print }) => (print ? '1rem' : '0.6rem')};
  font-weight: ${(props) => (props.styles.fontBold ? 700 : 500)};
  line-height: ${({ print }) => (print ? '1.2rem' : '0.7rem')};
`;

const EditableTextBlock = ({
  fieldId,
  defaultText,
  isEditing,
  styles,
  print = false,
}: EditableTextBlockProps): JSX.Element => {
  const service = useContext(FinancialReportViewServiceContext);

  const updateField = (newTitle) => {
    service.updateField(fieldId, newTitle);
  };

  return isEditing ? (
    <EditField
      value={defaultText}
      onChange={updateField}
      component={EditableTextField}
      styles={styles}
    />
  ) : (
    <StyledTextBox print={print} styles={styles}>
      <StyledText print={print} styles={styles}>
        {defaultText}
      </StyledText>
    </StyledTextBox>
  );
};

export default EditableTextBlock;

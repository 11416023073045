import { value, ref, or, id, sum } from '@agoy/document';
import { IxbrlCell, NoteOneTableWithSum } from '../../../../common';
import { year, previousYear } from '../../../../common/utils/date-util';
import {
  active,
  field,
  ixbrlCell,
  ixbrlField,
  table,
  tupleRef,
} from '../../../../common/utils/util';
import { NoteAccountRanges } from '../types';

const MONETARY_ITEM_TYPE = 'monetaryItemType';

export const note86Accounts: NoteAccountRanges[] = [];

export const getNote86 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null
): NoteOneTableWithSum => {
  return {
    active: false,
    number: value(undefined),
    name: value('Justering för poster som inte ingår i kassaflödet m.m.'),
    table: table<IxbrlCell>(
      'notes.note86.table',
      {
        id: 'label',
        label: 'Justering för poster som inte ingår i kassaflödet m.m.',
      },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      .addRows((rows) =>
        rows
          .addRow(
            'depreciationAndWriteOffs',
            noteLabel('Avskrivningar och nedskrivningar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:JusteringPosterInteIngarKassaflodeAvskrivningarNedskrivningar',
              contextRef: 'period0',
              standardRubrik:
                'Avskrivningar och nedskrivningar (inklusive återföringar) vid justering för poster som inte ingår i kassaflödet m.m.',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:JusteringPosterInteIngarKassaflodeAvskrivningarNedskrivningar',
              contextRef: 'period1',
              standardRubrik:
                'Avskrivningar och nedskrivningar (inklusive återföringar) vid justering för poster som inte ingår i kassaflödet m.m.',
            })
          )
          .addRow(
            'realisationResults',
            noteLabel('Realisationsresultat'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:RealisationsVinstForlustRorelseresultat',
              contextRef: 'period0',
              standardRubrik:
                'Realisationsförlust (realisationsvinst) som ingår i rörelseresultatet',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:RealisationsVinstForlustRorelseresultat',
              contextRef: 'period1',
              standardRubrik:
                'Realisationsförlust (realisationsvinst) som ingår i rörelseresultatet',
            })
          )
          .addRow(
            'exchangeRateDifference',
            noteLabel('Valutakursdifferenser'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:JusteringPosterInteIngarKassaflodeValutakursdifferenser',
              contextRef: 'period0',
              standardRubrik:
                'Valutakursförlust (valutakursvinst) i justering för poster som inte ingår i kassaflödet m.m.',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:JusteringPosterInteIngarKassaflodeValutakursdifferenser',
              contextRef: 'period1',
              standardRubrik:
                'Valutakursförlust (valutakursvinst) i justering för poster som inte ingår i kassaflödet m.m.',
            })
          )
          .addRow(
            'provisionForPensions',
            noteLabel('Avsättning för pensioner'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:JusteringPosterInteIngarKassaflodeAvsattningPensioner',
              contextRef: 'period0',
              saldo: 'credit',
              standardRubrik:
                'Avsättning (återföring av avsättning) för pensioner i justering för poster som inte ingår i kassaflödet m.m.',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:JusteringPosterInteIngarKassaflodeAvsattningPensioner',
              contextRef: 'period1',
              saldo: 'credit',
              standardRubrik:
                'Avsättning (återföring av avsättning) för pensioner i justering för poster som inte ingår i kassaflödet m.m.',
            })
          )
          .addRow(
            'provisionForGuarantees',
            noteLabel('Avsättning för garantier'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:JusteringPosterInteIngarKassaflodeAvsattningGarantier',
              contextRef: 'period0',
              saldo: 'credit',
              standardRubrik:
                'Avsättning (återföring av avsättning) för garantier i justering för poster som inte ingår i kassaflödet m.m.',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:JusteringPosterInteIngarKassaflodeAvsattningGarantier',
              contextRef: 'period1',
              saldo: 'credit',
              standardRubrik:
                'Avsättning (återföring av avsättning) för garantier i justering för poster som inte ingår i kassaflödet m.m.',
            })
          )
          .addRow(
            'provisionForRestructuring',
            noteLabel('Avsättning för omstrukturering'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:JusteringPosterInteIngarKassaflodeAvsattningOmstrukturering',
              contextRef: 'period0',
              saldo: 'credit',
              standardRubrik:
                'Avsättning (återföring av avsättning) för omstrukturering i justering för poster som inte ingår i kassaflödet m.m.',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:JusteringPosterInteIngarKassaflodeAvsattningOmstrukturering',
              contextRef: 'period1',
              saldo: 'credit',
              standardRubrik:
                'Avsättning (återföring av avsättning) för omstrukturering i justering för poster som inte ingår i kassaflödet m.m.',
            })
          )
          .addRow(
            'others',
            noteLabel('Övriga poster som inte ingår i kassaflödet m.m.'),
            undefined,
            undefined
          )
          .addRow(
            'otherTypes',
            ixbrlCell(value('Typ av övrig post'), {
              type: 'stringItemType',
              name: 'se-gen-base:OvrigaPosterInteIngarKassaflodetTyp',
              contextRef: 'period0',
              standardRubrik: 'Typ av övrig post som inte ingår i kassaflödet',
              tuple: {
                name: 'se-gaap-ext:OvrigaPosterInteIngarKassaflodetTuple',
                id: `${rows.getBaseId()}.rowOther.tuple`,
                tupleID: `${rows.getBaseId()}.rowOther.tuple`,
              },
              previousYearTuple: true,
              tupleRef: tupleRef(1, `${rows.getBaseId()}.rowOther.tuple`),
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OvrigaPosterInteIngarKassaflodet',
              contextRef: 'period0',
              tupleRef: tupleRef(
                2,
                `${rows.getBaseId()}.rowOther.tuple_period0`
              ),
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OvrigaPosterInteIngarKassaflodet',
              contextRef: 'period1',
              tupleRef: tupleRef(
                3,
                `${rows.getBaseId()}.rowOther.tuple_period1`
              ),
            })
          )
          .build()
      )
      .newRowTemplateGenerator((id, baseId) => ({
        id,
        active: true,
        cells: {
          label: ixbrlCell(value(''), {
            type: 'stringItemType',
            name: 'se-gen-base:OvrigaPosterInteIngarKassaflodetTyp',
            contextRef: 'period0',
            standardRubrik:
              'Specifikation av övriga poster som inte ingår i kassaflödet m.m. (Tabell)',
            tuple: {
              name: 'se-gaap-ext:OvrigaPosterInteIngarKassaflodetTuple',
              id: `${baseId}.${id}.tuple`,
              tupleID: `${baseId}.${id}.tuple`,
            },
            previousYearTuple: true,
            tupleRef: tupleRef(1, `${baseId}.${id}.tuple`),
          }),
          year: ixbrlCell(value(undefined), {
            type: MONETARY_ITEM_TYPE,
            name: 'se-gen-base:OvrigaPosterInteIngarKassaflodet',
            contextRef: 'period0',
            tupleRef: tupleRef(2, `${baseId}.${id}.tuple_period0`),
          }),
          previousYear: ixbrlCell(value(undefined), {
            type: MONETARY_ITEM_TYPE,
            name: 'se-gen-base:OvrigaPosterInteIngarKassaflodet',
            contextRef: 'period0',
            tupleRef: tupleRef(2, `${baseId}.${id}.tuple_period1`),
          }),
        },
      }))
      .build(),
    sumTable: table(
      'notes.note86.sumTable',
      { id: 'label', label: '' },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      .addRows((rows) =>
        rows
          .addRow(
            'sum',
            noteLabel('Summa'),
            ref(or(sum(id('notes.note86.table.*.year')), 0)),
            ref(or(sum(id('notes.note86.table.*.previousYear')), 0))
          )
          .build()
      )
      .build(),
    commentLabel: field('Kommentar till not'),
    comment: ixbrlField(active(field('')), {
      type: 'stringItemType',
      name: 'se-gen-base:DispositionerVinstForlustKommentar',
      contextRef: 'balans0',
    }),
    group: value(undefined),
    section: value(undefined),
    validForReportType: {
      k2: false,
      k3: true,
    },
  };
};

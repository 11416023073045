import React, { useState } from 'react';
import styled from '@emotion/styled';

import { SignaturePerson } from '_reconciliation/types';
import { Period } from '@agoy/api-sdk-core';
import CommonPreviewModal from '_shared/components/CommonPreviewModal';
import InventoryViewPreview from './InventoryViewPreview';

const Container = styled.section`
  @media print {
    page-break-inside: avoid;
  }
`;

type InventoryViewPreviewModalProps = {
  period: Period;
  accountNumber: string;
  signatures: SignaturePerson[];
  richText: string;
  location: string;
  open: boolean;
  onClose: () => void;
  onPrint: () => Promise<void>;
};

const InventoryViewPreviewModal = ({
  period,
  accountNumber,
  signatures,
  richText,
  location,
  open,
  onClose,
  onPrint,
}: InventoryViewPreviewModalProps): JSX.Element | null => {
  const [printLoading, setPrintLoading] = useState(false);

  const handlePrint = async () => {
    setPrintLoading(true);
    await onPrint();
    setPrintLoading(false);
  };

  return (
    <CommonPreviewModal
      loading={printLoading}
      isOpen={open}
      handleShow={onClose}
      onPrint={handlePrint}
    >
      <Container>
        <InventoryViewPreview
          period={period}
          accountNumber={accountNumber}
          signatures={signatures}
          richText={richText}
          location={location}
        />
      </Container>
    </CommonPreviewModal>
  );
};

export default InventoryViewPreviewModal;

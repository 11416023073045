import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { useIntl } from 'react-intl';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { Select, MenuItem, Typography, Breadcrumbs } from '@material-ui/core';
import PublishIcon from '@material-ui/icons/Publish';
import styled from '@emotion/styled';
import { formatFinancialYear } from '@agoy/common';

import { useSelector } from 'redux/reducers';
import FileDropArea from '_shared/components/FileDropArea';
import ConfirmationDialog from '_shared/components/Dialogs/ConfirmationDialog';
import { asResultClass, getApiSdk } from 'api-sdk';
import { getContext } from 'utils/AgoyAppClient/contextHolder';
import Downloadlink from 'utils/DownloadLink';
import LoadingLogo from '_shared/components/LoadingLogo';
import { GetComponentProps } from '_shared/services/type-helpers';
import { formatYearVariable } from 'utils/document-util';
import Button from '_shared/components/Buttons/Button';
import Document from './Document';

type Sdk = Awaited<ReturnType<typeof getApiSdk>>;
type ClientFiles = Awaited<ReturnType<Sdk['getClientDocuments']>>;

const Container = styled.div`
  padding: ${(props) => props.theme.spacing(2)}px
    ${(props) => props.theme.spacing(4)}px;
  width: 100%;
`;

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const UploadArea = styled(FileDropArea)`
  background: ${(props) => props.theme.palette.accountingView.cell.ok};
  border: ${(props) => `1px dashed ${props.theme.palette.secondary.main}`};
  padding: ${(props) => props.theme.spacing(4)}px;
  border-radius: ${(props) => props.theme.shape.borderRadius}px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing(2)}px;
  margin: ${(props) => props.theme.spacing(2)}px 0;
`;

const HiddenUploadInput = styled.input`
  display: none;
`;

const Table = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr min-content min-content;
`;

const TableHeader = styled.div`
  white-space: nowrap;
  padding: ${(props) => props.theme.spacing(1)}px;
`;

const LoadingIndicator = styled.div`
  margin: 0 ${(props) => props.theme.spacing(4)}px;
  display: flex;
  justify-content: center;
`;

type DocumentPageProps = {
  clientId: string;
  open: boolean;
};

const DocumentPage = ({ clientId, open }: DocumentPageProps): JSX.Element => {
  const { formatMessage } = useIntl();

  const sdk = getApiSdk(getContext());

  const clientName = useSelector((state) => state.customers[clientId]?.name);
  const currentFinancialYear = useSelector(
    (state) => state.customerView.currentFinancialYear
  );
  const financialYears = useSelector(
    (state) => state.customers[clientId]?.financialYears
  );

  const [loadingFiles, setLoadingFiles] = useState(false);
  const [files, setFiles] = useState<ClientFiles>({ listDocuments: [] });

  const [uploading, setUploading] = useState(false);
  const [postDocumentError, setPostDocumentError] = useState(false);
  const [selectedYear, setSelectedYear] = useState<string>('');
  const [autoDownload, setAutoDownload] = useState<
    { url: string; name: string } | undefined
  >();
  const [verifyDeleteFile, setVerifyDeleteFile] = useState<GetComponentProps<
    typeof ConfirmationDialog
  > | null>(null);

  const inputField = useRef<HTMLInputElement>(null);

  const fetchClientDocuments = async ({
    clientId,
    selectedYear,
  }: {
    clientId: string;
    selectedYear: string;
  }) => {
    setLoadingFiles(true);
    const clientDocumentsResult = await asResultClass(
      sdk.getClientDocuments({
        clientid: clientId,
        year: formatYearVariable(selectedYear),
      })
    );
    setLoadingFiles(false);

    if (clientDocumentsResult.ok) {
      setFiles(clientDocumentsResult.val);
      return clientDocumentsResult.val;
    }

    return { listDocuments: [] };
  };

  useEffect(() => {
    if (selectedYear) {
      fetchClientDocuments({ clientId, selectedYear });
    }
  }, [selectedYear, clientId]);

  useEffect(() => {
    const lastFinancialYear =
      financialYears && financialYears[financialYears.length - 1];

    if (open && currentFinancialYear) {
      setSelectedYear(
        financialYears?.find((year) => year === currentFinancialYear) ||
          lastFinancialYear
      );
    } else {
      setSelectedYear(lastFinancialYear);
    }
  }, [open, financialYears, currentFinancialYear]);

  const onSelectYear = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedYear(event.target.value as string);
  };

  const uploadFile = useCallback(
    async (file: File) => {
      if (!selectedYear) {
        // eslint-disable-next-line no-console
        console.warn('No selected year to upload file for');
        return;
      }

      setUploading(true);
      try {
        const result = await asResultClass(
          sdk.putClientDocument({
            clientid: clientId,
            year: formatYearVariable(selectedYear),
            category: 'other',
            name: file.name,
            requestBody: file,
            program: 'SYSTEM',
            section: 'DOCUMENTS',
          })
        );

        if (result.ok) {
          fetchClientDocuments({ clientId, selectedYear });
          setPostDocumentError(false);
        } else {
          setPostDocumentError(true);
        }
      } catch {
        setPostDocumentError(true);
      } finally {
        setUploading(false);
      }
    },
    [selectedYear, clientId]
  );

  const handleFileButton = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.currentTarget.files) {
        const file = event.currentTarget.files[0];
        if (file) {
          uploadFile(file);
        }
      }
    },
    [uploadFile]
  );

  const verifyDelete = useCallback(
    (name: string, doDelete: () => void) => {
      setVerifyDeleteFile({
        text: formatMessage({ id: 'documents.confirm.delete' }, { name }),
        onAccept: doDelete,
        onClose: () => {
          setVerifyDeleteFile(null);
        },
      });
    },
    [formatMessage]
  );

  const handleDelete = () => fetchClientDocuments({ clientId, selectedYear });

  const sortedFiles = useMemo(() => {
    return [...(files?.listDocuments || [])].sort((a, b) =>
      a.lastModified.localeCompare(b.lastModified)
    );
  }, [files]);

  const handleInvalidDocument = async (error, file) => {
    const data = await fetchClientDocuments({ clientId, selectedYear });
    const updatedFile = data.listDocuments?.filter(
      ({ name, category }) => name === file.name && category === file.category
    )[0];
    if (updatedFile) {
      setAutoDownload(updatedFile);
    }
  };

  return (
    <Container>
      <Breadcrumbs>
        <Typography variant="subtitle1">{clientName}</Typography>
        <Typography variant="subtitle1">
          {formatMessage({ id: 'documents' })}
        </Typography>
      </Breadcrumbs>
      <HeaderRow>
        <Typography variant="h2">
          {formatMessage({ id: 'documents.clientdocuments' })}
        </Typography>
        <SelectWrapper>
          <Typography variant="body1" color="textSecondary">
            {formatMessage({ id: 'documents.year.label' })}
          </Typography>
          <Select value={selectedYear} disableUnderline onChange={onSelectYear}>
            {financialYears?.map((year) => (
              <MenuItem key={year} value={year}>
                {formatFinancialYear(year)}
              </MenuItem>
            ))}
          </Select>
        </SelectWrapper>
      </HeaderRow>
      {postDocumentError && (
        <Alert severity="error">
          <AlertTitle>
            {formatMessage({ id: 'documents.upload.error.title' })}
          </AlertTitle>
          {formatMessage({ id: 'documents.upload.error.text' })}
        </Alert>
      )}
      <UploadArea
        onDrop={uploadFile}
        isValidFile={() => true}
        onInvalidFileType={() => {}}
      >
        {uploading ? (
          <LoadingLogo size="medium" />
        ) : (
          <>
            <Button
              label={formatMessage({ id: 'documents.upload.button' })}
              size="medium"
              variant="outlined"
              startIcon={<PublishIcon />}
              onClick={() => inputField?.current?.click?.()}
            />
            <HiddenUploadInput
              type="file"
              onChange={handleFileButton}
              ref={inputField}
            />
            <Typography variant="body1" color="textSecondary">
              {formatMessage({ id: 'documents.upload.drop' })}
            </Typography>
          </>
        )}
      </UploadArea>

      {verifyDeleteFile && <ConfirmationDialog {...verifyDeleteFile} />}
      {!loadingFiles && files && (
        <Table>
          <TableHeader>
            <Typography variant="body1" color="textSecondary">
              {formatMessage({ id: 'documents.column.name' })}
            </Typography>
          </TableHeader>
          <TableHeader>
            <Typography variant="body1" color="textSecondary">
              {formatMessage({ id: 'documents.column.date' })}
            </Typography>
          </TableHeader>
          <TableHeader>
            <Typography variant="body1" color="textSecondary">
              {formatMessage({ id: 'documents.column.download' })}
            </Typography>
          </TableHeader>
          <TableHeader>
            <Typography variant="body1" color="textSecondary">
              {formatMessage({ id: 'documents.column.delete' })}
            </Typography>
          </TableHeader>
          {sortedFiles.map((file) => (
            <Document
              key={file.name}
              {...file}
              customerId={clientId}
              year={selectedYear}
              verifyDelete={verifyDelete}
              onDelete={handleDelete}
              onInvalidDocument={(e) => handleInvalidDocument(e, file)}
            />
          ))}
        </Table>
      )}
      {loadingFiles && (
        <LoadingIndicator>
          <LoadingLogo size="medium" />
        </LoadingIndicator>
      )}
      {autoDownload && (
        <Downloadlink
          autoDownload
          href={autoDownload.url}
          title={autoDownload.name}
        />
      )}
    </Container>
  );
};

export default DocumentPage;

import React, { useState, useEffect, useContext, useMemo } from 'react';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';

import { useApiSdk } from 'api-sdk';
import PeriodSummary from '_shared/components/PeriodSummary';
import { formatPeriodToMonthAndYear } from 'utils';
import { InputData } from '_reconciliation/types';
import LoadingPlaceholder from '_shared/components/LoadingPlaceholder';
import { VoucherLastNumberContextProvider } from '_shared/components/VoucherView/VoucherNumberContext';

import LagerjusteringTableView from './LagerjusteringTableView';
import LagerjusteringVoucherView from './LagerjusteringVoucherView';
import { INVENTORY_OBSOLESCENCE_DEFAULT_DESCRIPTION } from './lagerjusteringUtil';
import { InventoryObsolescenceDataType } from './types';
import PeriodDataContext from '../PeriodDataContext';

type LagerjusteringViewProps = {
  userData: InputData;
  accountNumber: string;
};

const Wrapper = styled.div`
  margin-top: ${(props) => props.theme.spacing(2)}px;
  display: flex;
  gap: ${(props) => props.theme.spacing(3)}px;
`;

const LagerjusteringView = ({
  userData,
  accountNumber,
}: LagerjusteringViewProps) => {
  const dispatch = useDispatch();
  const sdk = useApiSdk();

  const { clientId, period, financialYear } = useContext(PeriodDataContext);

  const defaultInventoryObsolescenceData = useMemo(
    () => ({
      account: +accountNumber,
      rate: 0.03,
      periodId: period.id,
      reference: '',
      description: INVENTORY_OBSOLESCENCE_DEFAULT_DESCRIPTION,
    }),
    [period.id, accountNumber]
  );

  const {
    ub = 0,
    ib,
    psaldo: userDataPsaldo,
    saldo: userDataSaldo,
  } = userData || {};
  const saldo = userDataSaldo ? parseInt(userDataSaldo, 10) : 0;
  const psaldo = userDataPsaldo ? parseInt(userDataPsaldo.toString(), 10) : 0;

  const [isLoading, setIsLoading] = useState(true);
  const [stockValue, setStockValue] = useState(ub);
  const [inventoryObsolescenceData, setInventoryObsolescenceData] =
    useState<InventoryObsolescenceDataType>(defaultInventoryObsolescenceData);

  useEffect(() => {
    const fetchInventoryObsolescenceData = async () => {
      setIsLoading(true);
      try {
        const { account, periodId, rate, description, reference } =
          await sdk.getInventoryObsolescence({
            clientId,
            periodId: period.id,
            account: +accountNumber,
          });

        const updatedInventoryObsolescenceData = {
          account,
          periodId,
          rate,
          description: description ?? '',
          reference: reference ?? '',
        };
        setInventoryObsolescenceData(updatedInventoryObsolescenceData);
      } catch (error) {
        await sdk.addInventoryObsolescence({
          clientId,
          requestBody: defaultInventoryObsolescenceData,
        });
        setInventoryObsolescenceData(defaultInventoryObsolescenceData);
      } finally {
        setIsLoading(false);
      }
    };
    fetchInventoryObsolescenceData();
  }, [
    period,
    clientId,
    sdk,
    accountNumber,
    defaultInventoryObsolescenceData,
    ub,
    dispatch,
  ]);

  return (
    <Wrapper>
      <PeriodSummary
        date={formatPeriodToMonthAndYear(period)}
        periodIb={ib}
        periodSaldo={psaldo}
        periodUb={ub}
        saldo={saldo}
      />
      {isLoading ? (
        <LoadingPlaceholder />
      ) : (
        <>
          <LagerjusteringTableView
            defaultData={defaultInventoryObsolescenceData}
            data={inventoryObsolescenceData}
            onChangeData={setInventoryObsolescenceData}
            ub={ub}
            period={period}
            handleStockValueChange={setStockValue}
          />
          <VoucherLastNumberContextProvider
            clientId={clientId}
            financialYearId={financialYear.id}
          >
            <LagerjusteringVoucherView
              inventoryObsolescenceData={inventoryObsolescenceData}
              stock={stockValue}
              accountNumber={accountNumber}
              period={period}
              ub={ub}
            />
          </VoucherLastNumberContextProvider>
        </>
      )}
    </Wrapper>
  );
};

export default LagerjusteringView;

import { getMonth, format } from 'date-fns';
import { Period } from '@agoy/api-sdk-core';
import { AccountingAccount } from 'types/Accounting';
import { PERIODICITY } from './constants';
import {
  Checklist,
  ChecklistTemplate,
  ChecklistGroup,
  ChecklistQuestion,
  Periodicity,
} from './types';

type PeriodsByPeriodicity = Record<Periodicity, Period[]>;

export const checkAccountRange = (
  accounts: AccountingAccount[],
  fromAccount?: string,
  toAccount?: string
): boolean => {
  if (fromAccount && toAccount) {
    return !!accounts.find(
      (account) => account.number >= fromAccount && account.number <= toAccount
    );
  }
  return true;
};

const getEveryNthPeriods = (periods: Period[], n: number) => {
  return periods.filter((period) => {
    const month = getMonth(new Date(period.start)) + 1;
    return month % n === 0;
  });
};

const getOddPeriods = (periods: Period[]) => {
  return periods.filter((period) => {
    const month = getMonth(new Date(period.start)) + 1;
    return month % 2 !== 0;
  });
};

export const getPeriodsByPeriodicity = (
  allPeriods: Period[]
): PeriodsByPeriodicity => {
  const result: PeriodsByPeriodicity = {
    every_period: [],
    even_month: [],
    odd_month: [],
    every_quarter: [],
    half_year: [],
    last_period: [],
    first_period: [],
  };
  const periods = allPeriods.filter((period) => period.type === 'month');

  PERIODICITY.forEach((item) => {
    switch (item) {
      case 'every_period':
        result[item] = periods;
        break;
      case 'even_month':
        result[item] = getEveryNthPeriods(periods, 2);
        break;
      case 'odd_month':
        result[item] = getOddPeriods(periods);
        break;
      case 'every_quarter':
        result[item] = getEveryNthPeriods(periods, 3);
        break;
      case 'half_year':
        result[item] = getEveryNthPeriods(periods, 6);
        break;
      case 'last_period':
        result[item] = [periods[periods.length - 1]];
        break;
      case 'first_period':
        result[item] = periods.slice(0, 1);
        break;
      default:
        break;
    }
  });

  return result;
};

export const checkPeriod = (
  periodicity: Periodicity,
  periodsPeriodicity: PeriodsByPeriodicity,
  currentPeriod: Period
): boolean => {
  return !!periodsPeriodicity[periodicity].find(
    (period) => period.id === currentPeriod.id
  );
};

export const getQuestions = (
  questions: ChecklistQuestion[],
  currentPeriod: Period,
  periodsPeriodicity: PeriodsByPeriodicity,
  accounts: AccountingAccount[]
): ChecklistQuestion[] => {
  const filteredQuestions: ChecklistQuestion[] = [];

  questions.forEach((item) => {
    const { fromAccount, toAccount, periodicity } = item;

    if (checkAccountRange(accounts, fromAccount, toAccount)) {
      if (checkPeriod(periodicity, periodsPeriodicity, currentPeriod)) {
        filteredQuestions.push(item);
      }
    }
  });

  return filteredQuestions;
};

export const getGroups = (
  checklistTemplateGroups: ChecklistGroup[],
  currentPeriod: Period,
  periodsPeriodicity: PeriodsByPeriodicity,
  accounts: AccountingAccount[]
): ChecklistGroup[] => {
  const checklistGroups: ChecklistGroup[] = [];

  checklistTemplateGroups.forEach((group) => {
    const { fromAccount, toAccount, questions } = group;

    if (checkAccountRange(accounts, fromAccount, toAccount)) {
      const checklistQuestions = getQuestions(
        questions,
        currentPeriod,
        periodsPeriodicity,
        accounts
      );

      if (Object.keys(checklistQuestions).length) {
        checklistGroups.push({
          ...group,
          questions: checklistQuestions,
        });
      }
    }
  });

  return checklistGroups;
};

export const createChecklistPeriodsFromTemplate = (
  checklistTemplate: ChecklistTemplate,
  periods: Period[],
  accounts: AccountingAccount[] = [],
  includeInPrint = true
): Checklist => {
  const { groups, ...rest } = checklistTemplate;

  const checklist: Checklist = {
    ...rest,
    periods: {},
    includeInPrint,
  };

  const periodsByPeriodicity = getPeriodsByPeriodicity(periods);

  periods.forEach((period) => {
    checklist.periods[period.id] = {
      groups: getGroups(groups, period, periodsByPeriodicity, accounts),
    };
  });

  return checklist;
};

export const createChecklistFromTemplate = (
  checklistTemplate: ChecklistTemplate
): Checklist => {
  const { groups, ...rest } = checklistTemplate;

  const checklist: Checklist = {
    ...rest,
    periods: {},
    includeInPrint: true,
  };

  checklist.periods.year = {
    groups,
  };

  return checklist;
};

export const getFinancialYear = (
  financialYears:
    | {
        id: number;
        start: string;
        end: string;
        periods?: Array<Period>;
      }[]
    | undefined,
  financialYear: string
):
  | {
      id?: number;
      start?: string;
      end?: string;
      periods?: Array<Period>;
    }
  | undefined => {
  if (financialYears) {
    return financialYears.find((item) =>
      item.start
        ? format(new Date(item.start), 'yyyyMMdd') ===
          financialYear.substring(0, 8)
        : false
    );
  }
  return undefined;
};

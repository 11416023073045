import React, { useRef } from 'react';
import styled from '@emotion/styled';
import { TableCell, TableRow } from '@material-ui/core';

import {
  AgoyTableColumn,
  AgoyTableRow,
  Cell as CellType,
} from '@agoy/document';
import { isActive } from '@agoy/annual-report-document';
import { NumberFormatType, StandardNumberFormatType } from '@agoy/common';
import { Cell } from './Cell';

interface TableRowProps {
  baseId: string;
  row: AgoyTableRow;
  columns: AgoyTableColumn[];
  skipColumns?: number[];
  numberFormat?: NumberFormatType;
  isXBRLRender?: boolean;
  numberFormatCell?: (colId: string) => NumberFormatType;
  renderCell?: (values: {
    cell: CellType;
    column: AgoyTableColumn;
    row: AgoyTableRow;
    baseId: string;
  }) => React.ReactElement;
}

const StyledTableRow = styled(TableRow)`
  background-color: inherit;
  cursor: default;
  &.active {
    display: table-row;
  }
  &.not-active {
    display: none;
  }
`;

const StyledTableCell = styled(TableCell)`
  background-color: inherit;
  &.right {
    text-align: right;
  }
  &.left {
    text-align: left;
  }

  &.ingoing {
    background-color: #f4f7fa;
    font-weight: 400;
  }

  &.ingoingDark {
    background-color: #ecf0f4;
    font-weight: 400;
  }

  &.outgoing {
    background-color: #faf9f4;
    font-weight: 400;
  }

  &.outgoingDark {
    background-color: #f4f2ec;
    font-weight: 400;
  }

  &.reported {
    background-color: #f3faf8;
    font-weight: 700;
  }

  &.reportedDark {
    background-color: #eef8f4;
    font-weight: 700;
  }

  &.debts {
    background-color: #fafafa;
    font-weight: 400;
  }

  &.debtsDark {
    background-color: #f5f5f5;
    font-weight: 400;
  }

  &.otherDark {
    background-color: #f5f5f5;
  }

  &.titleRow {
    font-weight: 700;
  }

  &.printMode {
    @media print {
      background-color: inherit;
    }
  }
`;

const GeneralTableRow = ({
  baseId,
  row,
  columns,
  skipColumns = [],
  numberFormat = StandardNumberFormatType,
  isXBRLRender = false,
  numberFormatCell,
  renderCell,
}: TableRowProps): JSX.Element | null => {
  const containerRef = useRef(null);

  const getRowType = (cellId: string): string => {
    const label = row?.cells?.label as { type: string; value: string };
    const rowType = label?.value;
    let cellClass = ``;

    const rowIs = (type: string) => rowType?.includes(type);

    if (rowIs('Ingående')) {
      cellClass = 'ingoing';
    } else if (rowIs('Utgående')) {
      cellClass = 'outgoing';
    } else if (rowIs('Redovisat') || rowIs('Summa')) {
      cellClass = 'reported';
    } else if (rowIs('Övriga skulder')) {
      cellClass = 'debts';
    } else if (cellId === 'previousYear') {
      cellClass = 'other';
    } else if (rowIs('Förändringar')) {
      return 'titleRow';
    }

    return cellId === 'previousYear'
      ? `printMode ${cellClass}Dark`
      : `printMode ${cellClass}`;
  };

  if (isActive(row.active) === false) {
    return null;
  }

  return (
    <>
      {row.cells && (
        <StyledTableRow
          key={row.id}
          ref={containerRef}
          className={isActive(row.active) ? ' active' : ' not-active'}
        >
          {columns.map((col, index) => {
            const cell = row.cells?.[col.id];
            const key = `${row.id}.${col.id}`;
            const output: React.ReactNode[] = [];

            if (skipColumns.indexOf(index) !== -1) {
              output.push(<TableCell key={`${key}.1`} />);
            }

            if (!cell) {
              output.push(<TableCell key={key} />);
            } else if (renderCell) {
              output.push(
                <React.Fragment key={key}>
                  {renderCell({
                    cell,
                    column: col,
                    row,
                    baseId,
                  })}
                </React.Fragment>
              );
            } else {
              const format =
                numberFormatCell != null
                  ? numberFormatCell(col.id)
                  : numberFormat;

              output.push(
                <StyledTableCell
                  className={`${index === 0 ? 'left' : 'right'} ${getRowType(
                    col.id
                  )}`}
                  key={key}
                >
                  <Cell
                    id={`${baseId}.${row.id}`}
                    cell={cell}
                    numberFormatType={format}
                    active={
                      col.id === 'label' || isActive(row.active) !== false
                    }
                    isXBRLRender={isXBRLRender}
                  />
                </StyledTableCell>
              );
            }
            return output;
          })}
        </StyledTableRow>
      )}
    </>
  );
};

export default GeneralTableRow;

/**
 * Generates a string of space-separated CSS class names based on a given object
 * where keys represent class names and values indicate whether the class should be included.
 *
 * @param classes - An object where keys are class names, and values are booleans
 * indicating whether the class should be included (true) or excluded (false).
 *
 * @returns A string of space-separated CSS class names from the provided object.
 *
 * @example
 *
 * const ExampleComponent = styled.div`
 * 	.class1 {
 * 	  color: red;
 *  }
 *
 *  .class2 {
 *    background-color: blue;
 *  }
 * `
 *
 * const StyledExample = ({ someCondition }) => {
 *   const [foo, setFoo] = useState(false);
 *   const classNames = getClasses({
 *     class1: foo,
 *     class2: someCondition,
 *     class3: true,
 *   });
 *   // Output: "class2 class3"
 *
 *   return <ExampleComponent className={classNames}>...</ExampleComponent>;
 * }
 */
export const getClasses = (classes: Record<string, boolean>) =>
  Object.keys(classes)
    .filter((cl) => classes[cl])
    .join(' ');

import { format, parse } from '@agoy/dates';
import { capitalize } from '@agoy/common';
import { ACCOUNT_PERIOD_DATE_FORMAT } from 'types/Accounting';
import { Period } from '@agoy/api-sdk-core';

/**
 * Formats the start of a period to month and year in long format, ` Januari 2019`,
 * with a leading space.
 *
 * @param period
 * @returns
 */
export const formatPeriodToMonthAndYear = (period: Period): string => {
  return ` ${capitalize(
    format(parse(period.start, ACCOUNT_PERIOD_DATE_FORMAT), 'MMMM yyyy')
  )}`;
};

/**
 * Parses a date from string and then formats in another format.
 *
 * @param date date as string in `currentFormat`
 * @param currentFormat format as specified by `date-fns` and unicode standard
 * @param newFormat format as specified by `date-fns` and unicode standard
 * @returns
 */
export const reformatDate = (
  date: string,
  currentFormat: string,
  newFormat: string
): string => {
  return format(parse(date, currentFormat), newFormat);
};

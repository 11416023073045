import { arrayToRecord, getAnnualConfig } from '@agoy/common';
import {
  account,
  floor,
  id,
  label,
  max,
  min,
  multiply,
  or,
  ref,
  round,
  sum,
  value,
  accountName,
  applyChanges,
  sumAllowEmpty,
  tooltip,
  msg,
  ifOrElse,
  not,
} from '@agoy/document';
import { field, table } from '@agoy/annual-report-document';
import { range } from 'lodash';
import { createYears } from './calculateAccrualFunds';
import {
  contentDefinition,
  StoredTaxConfig,
  TaxCalculationConfig,
  TaxCalculationRow,
  TaxDocument,
  TaxViewDocument,
  TransactionRow,
} from './types';

const accountReference = (account: string): TaxCalculationRow => ({
  id: `account${account}`,
  label: account,
  reference: `account(${account})`,
});

type NumberProperties<T> = {
  [K in keyof T]: T[K] extends number ? K : never;
}[keyof T];
type ConfigName =
  | NumberProperties<TaxCalculationConfig>
  | 'yearPercentage'
  | `accrualInterestPercentage.${number}`
  | `accrualFund.date.${number}`
  | 'total.reversal.ub'
  | 'total.deposit.ub'
  | 'financialYear.start'
  | 'financialYear.end'
  | 'calendarYear.start'
  | 'calendarYear.end'
  | 'brokenFinancialYear';

// helpers for config
const config = (name: ConfigName) => `config(${name})`;

// helper for adjustment rows
const adjustmentRow = (
  id: string,
  from: string,
  to: string,
  reverse?: boolean
): TransactionRow[] => [
  {
    id: `${id}.from`,
    labelId: id,
    account: from,
    reference: reverse ? `multiply(-1,id(${id}))` : `id(${id})`,
  },
  {
    id: `${id}.to`,
    labelId: id,
    account: to,
    reference: reverse ? `id(${id})` : `multiply(-1,id(${id}))`,
  },
];

const getTaxDocuments = (): TaxDocument[] => {
  const labels = [
    'Engagemangsbesked',
    'Huvudbok',
    'Verifikationslista',
    'Årsredovisning föregående år',
    'Årsstämmoprotokoll föregående år',
    'Försäkringsbrev',
    'Försäkringsbrev',
    'Övrigt',
    'Övrigt',
    'Övrigt',
    'Övrigt',
    'Övrigt',
  ];
  const result: TaxDocument[] = labels.map((item, index) => ({
    label: item,
    value: `taxDocument-${index}`,
  }));
  return result;
};

export const companyTaxPerYear = {
  '2013': 0.22,
  '2014': 0.22,
  '2015': 0.22,
  '2016': 0.22,
  '2017': 0.22,
  '2018': 0.22,
  '2019': 0.214,
  '2020': 0.214,
  '2021': 0.206,
  '2022': 0.206,
  '2023': 0.206,
  '2024': 0.206,
};

const companyTypeAccounts = {
  individual: '2019',
  economic_association: '2099',
  limited: '2019',
  limited_partnership: '2019',
  non_profit_association: '2069',
  foundation: '2069',
  shares: '2099',
  other: '2099',
};

export type CompanyTypeAccounts =
  | 'shares'
  | 'limited'
  | 'limited_partnership'
  | 'individual'
  | 'economic_association'
  | 'non_profit_association'
  | 'foundation'
  | 'other';

export const getTemplateTax = (year: string): number => {
  const numberYear = Number.parseInt(year, 10);

  const config = getAnnualConfig(numberYear);

  if (config?.templateTax) {
    return config.templateTax;
  } else {
    throw new Error(
      `No ${
        config && !config.templateTax ? 'templateTax' : 'config'
      } for year '${numberYear}' found!`
    );
  }
};

export const getConfigBase = (companyType?: string): TaxCalculationConfig => ({
  // Ännu ej bokförd särskild löneskatt enligt beräkning nedan
  notBookedParticualSalaryTax: {
    id: 'notYetBookedParticualSalaryTax',
    reference: id('particularSalaryTax.notYetBookedParticualSalaryTax'),
  },
  // Årets resultat för konto 3000-8799
  resultBeforeFrom3000to8799: {
    id: 'resultBeforeFrom3000to8799',
    reference: multiply(
      sum(account('3000:8799'), id('notYetBookedParticualSalaryTax')),
      -1
    ),
  },
  // Bokslutsplanering
  yearEndPlanning: {
    rows: [
      // Bokförda lämnade koncernbidrag
      {
        id: 'bookedSentGroupContributions',
        reference: multiply(
          -1,
          or(account('8830:8839'), max(account('8800:8899:koncernbidrag'), 0))
        ),
      },
      // Bokförda mottagna koncernbidrag
      {
        id: 'bookedReceivedGroupContributions',
        reference: multiply(
          -1,
          or(account('8820:8829'), min(account('8800:8899:koncernbidrag'), 0))
        ),
      },
      // Bokförda övriga bokslutsdispositioner 1
      {
        id: 'firstBookedOtherDispositions',
        reference: multiply(
          -1,
          or(account('8870:8889'), min(account('8800:8899:koncernbidrag'), 0))
        ),
      },

      // Bokförda övriga bokslutsdispositioner 2
      {
        id: 'secondBookedOtherDispositions',
        reference: multiply(
          -1,
          or(account('8890:8899'), min(account('8800:8899:koncernbidrag'), 0))
        ),
      },
      // Valda lämnade koncernbidrag
      {
        id: 'chosenSentGroupContributions',
        value: 0,
      },
      // Valda mottagna koncernbidrag
      {
        id: 'chosenReceivedGroupContributions',
        value: 0,
      },
      // Lämnade gottgörelser
      {
        id: 'sentCompensation',
        reference: multiply(-1, account('8840:8849')),
      },

      /**
       * !: Here is a bag with referencing to value (document.overdepreciation.totalAmount). If we'll reference to it
       * !: from "chosenIncreaseOfOverdepreciation" and "chosenDecreaseOfOverdepreciation" with max/min/or/ifOrElse functions (for both of that id's)
       * !: it will case zero value result. Looking like it can't find correct reference in that case.
       */

      // Ökning av överavskrivning
      {
        id: 'chosenIncreaseOfOverdepreciation',
        reference: multiply(max(account('8850:8853'), 0), -1),
      },
      // Minskning av överavskrivning
      {
        id: 'chosenDecreaseOfOverdepreciation',
        reference: multiply(min(account('8850:8853'), 0), -1),
      },
      // Förändring av ersättningsfond
      {
        id: 'changeOfReplacementFund',
        reference: multiply(-1, account('8860:8869')),
      },
      // Vald övrig bokslutsdisposition 1
      {
        id: 'chosenOtherAdjustment1',
        value: 0,
      },
      // Vald övrig bokslutsdisposition 2
      {
        id: 'chosenOtherAdjustment2',
        value: 0,
      },
      // Redan bokförda periodiseringsfonder
      {
        id: 'bookedAccrualFund',
        reference: multiply(-1, account('8810:8818')),
      },
      // Tvingande återföring av p-fond
      {
        id: 'forcedReversalOfAccrualFund', // Agoy-excel D23
        reference: multiply(-1, id('accrualFund-6')),
      },
      // Max möjlig avsättning till p-fond, Max möjlig ytterligare avsättning till p-fond
      {
        // This is just a placeholder for the very special line which will
        // format the label with a value calculated after both Skatteberäkning and Periodiseringsfonder
        id: 'maxPossibleDepositionToAccrualFundLabel', // Agoy-excel D24
        value: 0,
      },
      // Vald återföring av periodiseringsfond
      {
        id: 'chosenReversalOfAccrualFund', // Agoy-excel D25
        value: 0,
      },
      // Vald avsättning till periodiseringsfond
      {
        id: 'chosenDepositionToAccrualFund', // Agoy-excel D26
        value: 0,
      },
    ],
    sum: {
      id: 'yearEndPlanningSum',
      hidden: true,
    },
  },
  yearEndPlanningResultBeforeTaxes: {
    // Årets resultat EFTER bokslutsplanering men INNAN årets skatt
    id: 'resultAfterYearEndPlanningBeforeTaxes',
    reference: sum(id('yearEndPlanningSum'), id('resultBeforeFrom3000to8799')),
  },
  // Resultat INNAN skatt
  resultBeforeTaxes: {
    id: 'resultBeforeTaxes',
    reference: multiply(-1, account('3000:8799')),
    hidden: true,
  },
  // Skatteberäkning
  taxCalculation: {
    rows: [
      {
        // Icke skattepliktiga intäkter
        id: 'nonTaxableIncomes',
        reference: id('nonTaxableIncomesSum'),
      },
      {
        // Ej avdragsgilla kostander
        id: 'nonDeductibleExpenses',
        reference: id('nonDeductibleExpensesSum'),
      },
      {
        // Schablonintäkt på periodiseringsfonder (0,51%)
        id: 'templateIncomeAccrualFund',
        reference: or(
          multiply(
            -1,
            id('accrualFundsSum'),
            config('templateTax'),
            config('yearPercentage')
          ),
          0
        ),
      },
      {
        // Underskottsavdrag
        id: 'deficitDeduction',
        label: 'Underskottsavdrag',
        reference: min(0, id('previousYear.yearsTaxableResult')),
      },

      {
        // Uppräkning periodiseringsfond
        id: 'calculationAccrual',
        label: 'Uppräkning periodiseringsfond',
        reference: id(
          'accrualFunds.specificationReversalOfAccrualFund.totalAccrualInterestAmount.interestAmount'
        ),
      },
    ],
    sum: {
      id: 'taxCalculationSum',
      hidden: true,
    },
  },
  taxCalculationMoreRows: [
    // Årets skattemässiga resultat
    {
      id: 'yearsTaxableResult',
      reference: sum(
        id('taxCalculationSum'), // Sum of Uppräkning av bolagsskatt för återföring to Uppräkning periodiseringsfond
        or(
          id('resultAfterYearEndPlanningBeforeTaxes'), // Årets resultat EFTER bokslutsplanering men INNAN årets skat
          id('resultBeforeTaxes') // Årets resultat för konto 3000-8799
        )
        // or(id('increaseOfCompanyTaxFromAccrualFunds'), 0) // Uppräkning av bolagsskatt för återföring av p-fond (again?)
      ),
    },
    // Redan bokförd bolagskatt
    {
      id: 'bookedCompanyTax',
      reference: or(account('8901:8910'), 0),
    },
    // Bolagsskatt,
    // Rules:
    //  * if the taxable result is less than 200, then no tax.
    //  * The taxable result is rounded down to nearest 10
    //  * The decimals are remove from the calculated tax
    //
    // Check out the tests in resolveReference for more.
    {
      id: 'companyTax',
      reference: max(
        0,
        multiply(
          // Trick to get 0 if yearsTaxableResult is less than 200 and 1 otherwise, to multiply the tax with, like an if-statement.
          min(max(0, round(sum(id('yearsTaxableResult'), -199.5))), 1),
          floor(
            multiply(
              // multiplying with 0.1, flooring and multiply by 10 is
              // rounding down to nearest factor of 10.
              multiply(
                10,
                floor(multiply(0.1, round(id('yearsTaxableResult'))))
              ),
              'config(companyTax)'
            )
          )
        )
      ),
    },
    {
      id: 'taxChanged',
      reference: account('8920'),
      hiddenWhenEmpty: true,
    },
    // Justering av bokförd , skatt som ska bokföras
    // Skatt som ska bokföras
    {
      id: 'taxToBook',
      labelId: 'taxToBook',
      reference: sum(id('companyTax'), multiply(-1, id('bookedCompanyTax'))),
    },
    // Redan bokfört resutat
    {
      id: 'alreadyBookedResult',
      reference: or(account('8990:8999'), 0),
    },
    // Resultat
    {
      id: 'result',
      reference: sum(
        or(
          id('resultAfterYearEndPlanningBeforeTaxes'),
          id('resultBeforeTaxes')
        ),
        multiply(-1, id('companyTax')),
        multiply(-1, or(id('taxChanged'), 0))
      ),
    },
    // Resultat att bokföra
    {
      id: 'resultToBook',
      labelId: 'resultToBook',
      reference: sum(id('result'), multiply(-1, id('alreadyBookedResult'))),
    },
    {
      id: 'forcedReversalOfAccrualFundMinusBooked',
      reference: max(
        sum(
          id('forcedReversalOfAccrualFund'),
          or(account('8819', 'change'), 0)
        ),
        0
      ),
      hidden: true,
    },
  ],
  nonTaxableIncomes: {
    rows: [
      accountReference('8010'),
      accountReference('8012'),
      accountReference('8020'),
      accountReference('8022'),
      accountReference('8110'),
      accountReference('8254'),
      accountReference('8314'),
    ],
    sum: { id: 'nonTaxableIncomesSum' },
  },
  nonDeductibleExpenses: {
    rows: [
      accountReference('5099'),
      accountReference('5199'),
      accountReference('6072'),
      accountReference('6342'),
      accountReference('6352'),
      accountReference('6982'),
      accountReference('6992'),
      accountReference('7622'),
      accountReference('7632'),
      accountReference('8423'),
    ],
    sum: { id: 'nonDeductibleExpensesSum' },
  },
  particularSalaryTax: {
    rows: [
      accountReference('7410'),
      accountReference('7411'),
      accountReference('7412'),
    ],
    sum: { id: 'particularSalaryTaxSum' },
  },
  alreadyBookedParticularSalaryTax: {
    id: 'alreadyBookedParticularSalaryTax',
    reference: or(account('7530:7549'), 0),
  },
  particularSalaryTaxToBook: {
    id: 'particularSalaryTaxToBook',
  },
  accrualFunds: { rows: [], sum: { id: 'accrualFundsSum' } },
  accrualFundsAccounts: {
    rows: [],
    sum: { id: 'accrualFundsAccountNumberSum' }, // Ignore this sum
  },
  accrualFundsRows: [
    // Tvingande återföring av p-fond
    {
      id: 'accrualFundsForcedReversalOfAccrualFund', // (Agoy-excel 'p-fond'!P14)
      reference: or(multiply(-1, id('accrualFund-6')), 0),
    },
    // Total avsättning till p-fond vid årets början
    {
      id: 'totalAccrualFunds', // (Agoy-excel 'p-fond'!P15)
      reference: round(or(id('accrualFundsSum'), 0)),
    },
    {
      id: 'templateIncome', // (Agoy-excel 'p-fond'!P16)
      reference: multiply(id('accrualFundsSum'), config('templateTax')),
    },
    {
      id: 'accrualFundsCompanyTax', // (Agoy-excel 'p-fond'!P17)
      reference: multiply(id('templateIncome'), config('companyTax')),
    },
    // Max möjlig avsättning till p-fond, Max möjlig ytterligare avsättning till p-fond
    {
      // This is just a placeholder for the very special line which will
      // format the label with a value calculated after both Skatteberäkning and Periodiseringsfonder
      id: 'maxPossibleDepositionToAccrualFundLabel',
      value: 0,
    },
    {
      // (AgoyExcel U12)
      id: 'maxPossibleDepositionToAccrualFundU12', // if bookedAccrualFund > 0
      reference: multiply(
        round(
          sum(
            id('yearsTaxableResult'),
            multiply(-1, or(id('bookedAccrualFund'), 0)),
            multiply(-1, id('chosenDepositionToAccrualFund'))
          )
        ),
        0.25
      ),
      hidden: true,
    },
    {
      // (AgoyExcel U14)
      id: 'maxPossibleDepositionToAccrualFund', // if bookedAccrualFund > 0
      reference: round(
        multiply(-1, id('maxPossibleDepositionToAccrualFundU12'))
      ),

      hidden: true,
    },
    {
      // (AgoyExcel U16)
      id: 'U16',
      reference: round(or(id('bookedAccrualFund'), 0)),

      hidden: true,
    },
    {
      // (Excel U22)
      id: 'maxPossibleFurtherDepositionToAccrualFund',
      reference: sum(
        multiply(
          -1,
          sum(
            multiply(
              sum(
                or(id('yearsTaxableResult'), 0),
                multiply(-1, or(id('bookedAccrualFund'), 0)),
                multiply(-1, or(id('chosenDepositionToAccrualFund'), 0))
              ),
              0.25
            ),
            or(id('bookedAccrualFund'), 0)
          )
        ),
        multiply(-1, id('chosenDepositionToAccrualFund'))
      ),
      hidden: true,
    },
    {
      // (Excel U20) Ytterligare möjlig avsättning till p-fonde efter bokfört
      id: 'furtherPossibleDepositionToAccrualFund',
      reference: or(
        round(
          min(
            0,
            multiply(
              sum(id('maxPossibleDepositionToAccrualFundU12'), id('U16')),
              -1
            )
          )
        ),
        0
      ),
      hidden: true,
    },
    {
      // (Excel U22) Max möjlig ytterligare avsättning till p-fond efter bokfört o eget val
      id: 'furtherPossibleDepositionToAccrualFundWithChosen',
      reference: or(
        round(
          sum(
            id('furtherPossibleDepositionToAccrualFund'),
            multiply(-1, id('chosenDepositionToAccrualFund'))
          )
        ),
        0
      ),
      hidden: true,
    },
    {
      id: 'chosenAndBookedDepositToAccrualFund',
      reference: sum(
        or(id('bookedAccrualFund'), 0),
        id('chosenDepositionToAccrualFund')
      ),
    },
    // Max möjlig återföring av p-fond exlusive tvingande återföring
    {
      id: 'maxPossibleReversalOfAccrualFundNotForced',
      reference: sum(
        id('accrualFundsForcedReversalOfAccrualFund'),
        id('totalAccrualFunds')
      ),
    },
    {
      id: 'chosenAndReversalOfAccrualFundIncludingForced',
      reference: sum(
        id('accrualFundsForcedReversalOfAccrualFund'),
        id('chosenReversalOfAccrualFund')
      ),
    },
  ],
  adjustments: {
    rows: [
      ...adjustmentRow('taxToBook', '8910', '2510'),
      ...adjustmentRow('particularSalaryTaxToBook', '7530', '2514'),
      ...adjustmentRow('chosenSentGroupContributions', '8830', '2360', true),
      ...adjustmentRow(
        'chosenReceivedGroupContributions',
        '8820',
        '1310',
        true
      ),
      ...adjustmentRow(
        'chosenIncreaseOfOverdepreciation',
        '8850',
        '2150',
        true
      ),
      ...adjustmentRow(
        'chosenDecreaseOfOverdepreciation',
        '8850',
        '2150',
        true
      ),
      ...adjustmentRow('chosenOtherAdjustment1', '', ''),
      ...adjustmentRow('chosenOtherAdjustment2', '', ''),
    ],
    sum: {
      id: 'adjustmentsSum',
      hidden: true,
      account: '',
    },
  },
  taxDocuments: getTaxDocuments(),
  salaryTax: 0.2426,
  companyTax: 0.22,
  templateTax: 0.0051,
  documentChanges: {},
  document: {
    previousYear: undefined,
    overdepreciation: {
      totalAmount: ref(
        sum(
          id(
            'overdepreciation.intangibleAssets.excessDepreciation.depositionOrDissolution'
          ),
          id(
            'overdepreciation.buildingsAndGroundFacilities.excessDepreciation.depositionOrDissolution'
          ),
          id(
            'overdepreciation.machinesAndInventory.excessDepreciation.depositionOrDissolution'
          )
        )
      ),
      intangibleAssets: {
        useMainRule: value(false),
        active: value(false),
        overDepreciationVoucher: table(
          'overDepreciationVoucher',
          { id: 'label' },
          { id: 'account' },
          { id: 'value' }
        )
          .addRows((rows) => {
            rows.addRow(
              'overDepreciation_from',
              value('Immateriella anläggningstillgångar'),
              value(8851),
              ref(
                id(
                  'overdepreciation.intangibleAssets.excessDepreciation.depositionOrDissolution'
                )
              )
            );
            rows.addRow(
              'overDepreciation_to',
              value('Immateriella anläggningstillgångar'),
              value(2151),
              ref(
                multiply(
                  -1,
                  id(
                    'overdepreciation.intangibleAssets.excessDepreciation.depositionOrDissolution'
                  )
                )
              )
            );

            return rows.build();
          })
          .newRowTemplate(value(''), value(undefined), value(undefined))
          .build(),
        excessDepreciation: {
          totalSelectedRuleForExcessDepreciation: ref(
            ifOrElse(
              id('overdepreciation.intangibleAssets.useMainRule'),
              id(
                'overdepreciation.intangibleAssets.mainRule.minimumAllowedTaxValueAccordingToTheMainRule'
              ),
              id(
                'overdepreciation.intangibleAssets.additionalRule.depreciationBasis'
              )
            )
          ),
          closingPlannedBookValue: field(0),
          openingAccumulatedExcessDepreciation: field(0),
          depositionOrDissolution: ref(
            sum(
              multiply(
                -1,
                id(
                  'overdepreciation.intangibleAssets.excessDepreciation.totalSelectedRuleForExcessDepreciation'
                )
              ),
              id(
                'overdepreciation.intangibleAssets.excessDepreciation.closingPlannedBookValue'
              ),
              multiply(
                -1,
                id(
                  'overdepreciation.intangibleAssets.excessDepreciation.openingAccumulatedExcessDepreciation'
                )
              )
            )
          ),
        },
        mainRule: {
          closingTaxValueOfThePreviousTaxYear: field(0),
          yearAcquisitionsThatRemainAtTheEndOfTheYear: field(0),
          deduction: ref(
            sum(
              id(
                'overdepreciation.intangibleAssets.mainRule.closingTaxValueOfThePreviousTaxYear'
              ),
              id(
                'overdepreciation.intangibleAssets.mainRule.yearAcquisitionsThatRemainAtTheEndOfTheYear'
              )
            )
          ),
          disposal: field(0),
          insuranceCompensation: field(0),
          particularlyHighExpenditure: field(0),
          depreciationBasis: ref(
            sum(
              id('overdepreciation.intangibleAssets.mainRule.deduction'),
              multiply(
                -1,
                id('overdepreciation.intangibleAssets.mainRule.disposal')
              ),
              multiply(
                -1,
                id(
                  'overdepreciation.intangibleAssets.mainRule.insuranceCompensation'
                )
              ),
              multiply(
                -1,
                id(
                  'overdepreciation.intangibleAssets.mainRule.particularlyHighExpenditure'
                )
              )
            )
          ),
          minimumAllowedTaxValueAccordingToTheMainRule: ref(
            multiply(
              id(
                'overdepreciation.intangibleAssets.mainRule.depreciationBasis'
              ),
              0.7
            )
          ),
        },
        additionalRule: {
          completionRuleTable: table(
            'overdepreciation.intangibleAssets.additionalRule.completionRuleTable',
            { id: 'date', label: 'Räkenskapsår', align: 'left' },
            {
              id: 'acquisitionValueAtTheEndOfTheTaxYear',
              label: 'Anskaffningsvärde  vid beskattningsårets slut',
              align: 'left',
            },
            { id: 'deduction', label: 'Avdrag', align: 'left' },
            { id: 'basis', label: 'Underlag', align: 'right' },
            {
              id: 'lowestShareOfTheAcquisitionValue',
              label: 'Lägsta andelen av anskaffningsvärdet',
              align: 'right',
            },
            {
              id: 'depreciationBasis',
              label: 'Avskrivningsunderlag',
              align: 'right',
            }
          )
            .addRows((rows) => {
              range(0, 5, 1).forEach((i) => {
                rows.addRow(
                  i.toString(),
                  ref(config(`accrualFund.date.${i}`)),
                  value(0),
                  value(0),
                  ref(
                    sum(
                      id(
                        `${rows.getBaseId()}.${i}.acquisitionValueAtTheEndOfTheTaxYear`
                      ),
                      multiply(-1, id(`${rows.getBaseId()}.${i}.deduction`))
                    )
                  ),
                  value((100 - (100 / 5) * (i + 1)) / 100),
                  ref(
                    multiply(
                      id(`${rows.getBaseId()}.${i}.basis`),
                      id(
                        `${rows.getBaseId()}.${i}.lowestShareOfTheAcquisitionValue`
                      )
                    )
                  )
                );
              });

              rows.addRow(
                'totalDepositionOfAccrualFund',
                {
                  type: 'label',
                  value:
                    'Lägsta tillåtna skattemässiga värde enligt kompletteringsregeln',
                },
                undefined,
                undefined,
                undefined,
                {
                  type: 'label',
                  value: 'Totalt (=)',
                },
                ref(
                  sum(
                    ...range(0, 5, 1).map((i) =>
                      or(id(`${rows.getBaseId()}.${i}.depreciationBasis`), 0)
                    )
                  )
                )
              );

              return rows.build();
            })
            .build(),
          depreciationBasis: ref(
            sum(
              ...range(0, 5, 1).map((i) =>
                or(
                  id(
                    `overdepreciation.intangibleAssets.additionalRule.completionRuleTable.${i}.depreciationBasis`
                  ),
                  0
                )
              )
            )
          ),
        },
      },
      buildingsAndGroundFacilities: {
        useMainRule: value(false),
        active: value(false),
        overDepreciationVoucher: table(
          'overDepreciationVoucher',
          { id: 'label' },
          { id: 'account' },
          { id: 'value' }
        )
          .addRows((rows) => {
            rows.addRow(
              'overDepreciation_from',
              value('Byggnader & markanläggningar'),
              value(8852),
              ref(
                id(
                  'overdepreciation.buildingsAndGroundFacilities.excessDepreciation.depositionOrDissolution'
                )
              )
            );
            rows.addRow(
              'overDepreciation_to',
              value('Byggnader & markanläggningar'),
              value(2152),
              ref(
                multiply(
                  -1,
                  id(
                    'overdepreciation.buildingsAndGroundFacilities.excessDepreciation.depositionOrDissolution'
                  )
                )
              )
            );

            return rows.build();
          })
          .newRowTemplate(value(''), value(undefined), value(undefined))
          .build(),
        mainRule: {
          closingTaxValueOfThePreviousTaxYear: field(0),
          yearAcquisitionsThatRemainAtTheEndOfTheYear: field(0),
          deduction: ref(
            sum(
              id(
                'overdepreciation.buildingsAndGroundFacilities.mainRule.closingTaxValueOfThePreviousTaxYear'
              ),
              id(
                'overdepreciation.buildingsAndGroundFacilities.mainRule.yearAcquisitionsThatRemainAtTheEndOfTheYear'
              )
            )
          ),
          disposal: field(0),
          insuranceCompensation: field(0),
          particularlyHighExpenditure: field(0),
          depreciationBasis: ref(
            sum(
              id(
                'overdepreciation.buildingsAndGroundFacilities.mainRule.deduction'
              ),
              multiply(
                -1,
                id(
                  'overdepreciation.buildingsAndGroundFacilities.mainRule.disposal'
                )
              ),
              multiply(
                -1,
                id(
                  'overdepreciation.buildingsAndGroundFacilities.mainRule.insuranceCompensation'
                )
              ),
              multiply(
                -1,
                id(
                  'overdepreciation.buildingsAndGroundFacilities.mainRule.particularlyHighExpenditure'
                )
              )
            )
          ),
          minimumAllowedTaxValueAccordingToTheMainRule: ref(
            multiply(
              id(
                'overdepreciation.buildingsAndGroundFacilities.mainRule.depreciationBasis'
              ),
              0.7
            )
          ),
        },
        excessDepreciation: {
          totalSelectedRuleForExcessDepreciation: ref(
            ifOrElse(
              id('overdepreciation.buildingsAndGroundFacilities.useMainRule'),
              id(
                'overdepreciation.buildingsAndGroundFacilities.mainRule.minimumAllowedTaxValueAccordingToTheMainRule'
              ),
              id(
                'overdepreciation.buildingsAndGroundFacilities.additionalRule.depreciationBasis'
              )
            )
          ),
          closingPlannedBookValue: field(0),
          openingAccumulatedExcessDepreciation: field(0),
          depositionOrDissolution: ref(
            sum(
              multiply(
                -1,
                id(
                  'overdepreciation.buildingsAndGroundFacilities.excessDepreciation.totalSelectedRuleForExcessDepreciation'
                )
              ),
              id(
                'overdepreciation.buildingsAndGroundFacilities.excessDepreciation.closingPlannedBookValue'
              ),
              multiply(
                -1,
                id(
                  'overdepreciation.buildingsAndGroundFacilities.excessDepreciation.openingAccumulatedExcessDepreciation'
                )
              )
            )
          ),
        },
        additionalRule: {
          completionRuleTable: table(
            'overdepreciation.buildingsAndGroundFacilities.additionalRule.completionRuleTable',
            { id: 'date', label: 'Räkenskapsår', align: 'left' },
            {
              id: 'acquisitionValueAtTheEndOfTheTaxYear',
              label: 'Anskaffningsvärde  vid beskattningsårets slut',
              align: 'left',
            },
            { id: 'deduction', label: 'Avdrag', align: 'left' },
            { id: 'basis', label: 'Underlag', align: 'right' },
            {
              id: 'lowestShareOfTheAcquisitionValue',
              label: 'Lägsta andelen av anskaffningsvärdet',
              align: 'right',
            },
            {
              id: 'depreciationBasis',
              label: 'Avskrivningsunderlag',
              align: 'right',
            }
          )
            .addRows((rows) => {
              range(0, 5, 1).forEach((i) => {
                rows.addRow(
                  i.toString(),
                  ref(config(`accrualFund.date.${i}`)),
                  value(0),
                  value(0),
                  ref(
                    sum(
                      id(
                        `${rows.getBaseId()}.${i}.acquisitionValueAtTheEndOfTheTaxYear`
                      ),
                      multiply(-1, id(`${rows.getBaseId()}.${i}.deduction`))
                    )
                  ),
                  value((100 - (100 / 5) * (i + 1)) / 100),
                  ref(
                    multiply(
                      id(`${rows.getBaseId()}.${i}.basis`),
                      id(
                        `${rows.getBaseId()}.${i}.lowestShareOfTheAcquisitionValue`
                      )
                    )
                  )
                );
              });

              rows.addRow(
                'totalDepositionOfAccrualFund',
                {
                  type: 'label',
                  value:
                    'Lägsta tillåtna skattemässiga värde enligt kompletteringsregeln',
                },
                undefined,
                undefined,
                undefined,
                {
                  type: 'label',
                  value: 'Totalt (=)',
                },
                ref(
                  sum(
                    ...range(0, 5, 1).map((i) =>
                      or(id(`${rows.getBaseId()}.${i}.depreciationBasis`), 0)
                    )
                  )
                )
              );

              return rows.build();
            })
            .build(),
          depreciationBasis: ref(
            sum(
              ...range(0, 5, 1).map((i) =>
                or(
                  id(
                    `overdepreciation.buildingsAndGroundFacilities.additionalRule.completionRuleTable.${i}.depreciationBasis`
                  ),
                  0
                )
              )
            )
          ),
        },
      },
      machinesAndInventory: {
        useMainRule: value(false),
        active: value(true),
        overDepreciationVoucher: table(
          'overDepreciationVoucher',
          { id: 'label' },
          { id: 'account' },
          { id: 'value' }
        )
          .addRows((rows) => {
            rows.addRow(
              'overDepreciation_from',
              value('Maskiner & inventarier'),
              value(8853),
              ref(
                id(
                  'overdepreciation.machinesAndInventory.excessDepreciation.depositionOrDissolution'
                )
              )
            );
            rows.addRow(
              'overDepreciation_to',
              value('Maskiner & inventarier'),
              value(2153),
              ref(
                multiply(
                  -1,
                  id(
                    'overdepreciation.machinesAndInventory.excessDepreciation.depositionOrDissolution'
                  )
                )
              )
            );

            return rows.build();
          })
          .newRowTemplate(value(''), value(undefined), value(undefined))
          .build(),
        excessDepreciation: {
          totalSelectedRuleForExcessDepreciation: ref(
            ifOrElse(
              id('overdepreciation.machinesAndInventory.useMainRule'),
              id(
                'overdepreciation.machinesAndInventory.mainRule.minimumAllowedTaxValueAccordingToTheMainRule'
              ),
              id(
                'overdepreciation.machinesAndInventory.additionalRule.depreciationBasis'
              )
            )
          ),
          closingPlannedBookValue: field(0),
          openingAccumulatedExcessDepreciation: field(0),
          depositionOrDissolution: ref(
            sum(
              multiply(
                -1,
                id(
                  'overdepreciation.machinesAndInventory.excessDepreciation.totalSelectedRuleForExcessDepreciation'
                )
              ),
              id(
                'overdepreciation.machinesAndInventory.excessDepreciation.closingPlannedBookValue'
              ),
              multiply(
                -1,
                id(
                  'overdepreciation.machinesAndInventory.excessDepreciation.openingAccumulatedExcessDepreciation'
                )
              )
            )
          ),
        },
        mainRule: {
          closingTaxValueOfThePreviousTaxYear: field(0),
          yearAcquisitionsThatRemainAtTheEndOfTheYear: field(0),
          deduction: ref(
            sum(
              id(
                'overdepreciation.machinesAndInventory.mainRule.closingTaxValueOfThePreviousTaxYear'
              ),
              id(
                'overdepreciation.machinesAndInventory.mainRule.yearAcquisitionsThatRemainAtTheEndOfTheYear'
              )
            )
          ),
          disposal: field(0),
          insuranceCompensation: field(0),
          particularlyHighExpenditure: field(0),
          depreciationBasis: ref(
            sum(
              id('overdepreciation.machinesAndInventory.mainRule.deduction'),
              multiply(
                -1,
                id('overdepreciation.machinesAndInventory.mainRule.disposal')
              ),
              multiply(
                -1,
                id(
                  'overdepreciation.machinesAndInventory.mainRule.insuranceCompensation'
                )
              ),
              multiply(
                -1,
                id(
                  'overdepreciation.machinesAndInventory.mainRule.particularlyHighExpenditure'
                )
              )
            )
          ),
          minimumAllowedTaxValueAccordingToTheMainRule: ref(
            multiply(
              id(
                'overdepreciation.machinesAndInventory.mainRule.depreciationBasis'
              ),
              0.7
            )
          ),
        },
        additionalRule: {
          completionRuleTable: table(
            'overdepreciation.machinesAndInventory.additionalRule.completionRuleTable',
            { id: 'date', label: 'Räkenskapsår', align: 'left' },
            {
              id: 'acquisitionValueAtTheEndOfTheTaxYear',
              label: 'Anskaffningsvärde  vid beskattningsårets slut',
              align: 'left',
            },
            { id: 'deduction', label: 'Avdrag', align: 'left' },
            { id: 'basis', label: 'Underlag', align: 'right' },
            {
              id: 'lowestShareOfTheAcquisitionValue',
              label: 'Lägsta andelen av anskaffningsvärdet',
              align: 'right',
            },
            {
              id: 'depreciationBasis',
              label: 'Avskrivningsunderlag',
              align: 'right',
            }
          )
            .addRows((rows) => {
              range(0, 5, 1).forEach((i) => {
                rows.addRow(
                  i.toString(),
                  ref(config(`accrualFund.date.${i}`)),
                  value(0),
                  value(0),
                  ref(
                    sum(
                      id(
                        `${rows.getBaseId()}.${i}.acquisitionValueAtTheEndOfTheTaxYear`
                      ),
                      multiply(-1, id(`${rows.getBaseId()}.${i}.deduction`))
                    )
                  ),
                  value((100 - (100 / 5) * (i + 1)) / 100),
                  ref(
                    multiply(
                      id(`${rows.getBaseId()}.${i}.basis`),
                      id(
                        `${rows.getBaseId()}.${i}.lowestShareOfTheAcquisitionValue`
                      )
                    )
                  )
                );
              });

              rows.addRow(
                'totalDepositionOfAccrualFund',
                {
                  type: 'label',
                  value:
                    'Lägsta tillåtna skattemässiga värde enligt kompletteringsregeln',
                },
                undefined,
                undefined,
                undefined,
                {
                  type: 'label',
                  value: 'Totalt (=)',
                },
                ref(
                  sum(
                    ...range(0, 5, 1).map((i) =>
                      or(id(`${rows.getBaseId()}.${i}.depreciationBasis`), 0)
                    )
                  )
                )
              );

              return rows.build();
            })
            .build(),
          depreciationBasis: ref(
            sum(
              ...range(0, 5, 1).map((i) =>
                or(
                  id(
                    `overdepreciation.machinesAndInventory.additionalRule.completionRuleTable.${i}.depreciationBasis`
                  ),
                  0
                )
              )
            )
          ),
        },
      },
    },
    particularSalaryTax: {
      fora: {
        salaryBase: ref(
          id('particularSalaryTax.fora.helper.salaryBase.totalSum.value')
        ),
        accounts: table(
          'particularSalaryTax.fora.accounts',
          'accountNumber',
          'label',
          'value'
        )
          .addRows((builder) =>
            builder
              .addRow(
                '1',
                value(7410),
                ref(accountName(id(`${builder.getBaseId()}.1.accountNumber`))),
                ref(
                  or(account(id(`${builder.getBaseId()}.1.accountNumber`)), 0)
                )
              )
              .build()
          )
          .newRowTemplate(
            value(undefined),
            ref(accountName(id('$id.accountNumber'))),
            ref(account(id('$id.accountNumber')))
          )
          .build(),
        accountsSum: ref(
          sumAllowEmpty(id('particularSalaryTax.fora.accounts.*.value'))
        ),
        unpaidFORA: field(0),
        accruedDebtToFORA: field(0),
        accruedDebtNote: field(''),
        paidPremiumsPreviousYear: field(0),
        helper: {
          salaryBase: table(
            'particularSalaryTax.fora.helper.salaryBase',
            'title',
            'label',
            'value',
            'percentage',
            'sum',
            'note',
            'accountNumber'
          )
            .addRows((builder) =>
              builder
                .addRow(
                  'previousYearContractualPensionBelow75',
                  label(
                    'Löneunderlag enligt slutfaktura FORA senaste kalenderåret'
                  ),
                  label('Avtalspension SAF-LO lönedelar upp till 7,5 ibb'),
                  value(undefined),
                  value(0.043), // TODO config('')
                  ref(
                    multiply(
                      id(
                        `${builder.getBaseId()}.previousYearContractualPensionBelow75.value`
                      ),
                      id(
                        `${builder.getBaseId()}.previousYearContractualPensionBelow75.percentage`
                      )
                    )
                  )
                )
                .addRow(
                  'previousYearContractualPensionOver75',
                  undefined,
                  label('Avtalspension SAF-LO lönedelar över 7,5 ibb'),
                  value(undefined),
                  value(0.3), // TODO config('')
                  ref(
                    multiply(
                      id(
                        `${builder.getBaseId()}.previousYearContractualPensionOver75.value`
                      ),
                      id(
                        `${builder.getBaseId()}.previousYearContractualPensionOver75.percentage`
                      )
                    )
                  )
                )
                .addRow(
                  'previousYearPremiumWaiverInsurance',
                  undefined,
                  label('Premiebefrielseförsäkring'),
                  value(undefined),
                  value(0), // TODO config('')
                  ref(
                    multiply(
                      id(
                        `${builder.getBaseId()}.previousYearPremiumWaiverInsurance.value`
                      ),
                      id(
                        `${builder.getBaseId()}.previousYearPremiumWaiverInsurance.percentage`
                      )
                    )
                  )
                )
                .addRow(
                  'previousYearSeveranceSupplementaryPension',
                  undefined,
                  label('Kollektivavtalade avg. extrapension'),
                  value(undefined),
                  value(0), // TODO config('')
                  ref(
                    multiply(
                      id(
                        `${builder.getBaseId()}.previousYearSeveranceSupplementaryPension.value`
                      ),
                      id(
                        `${builder.getBaseId()}.previousYearSeveranceSupplementaryPension.percentage`
                      )
                    )
                  )
                )
                .addRow(
                  'previousYearSum',
                  label('Summa'),
                  label(
                    'Löneunderlag enligt slutfaktura FORA senaste kalenderåret'
                  ),
                  ref(
                    sumAllowEmpty(
                      id(`${builder.getBaseId()}.previousYearC*.sum`),
                      id(
                        `${builder.getBaseId()}.previousYearSeveranceSupplementaryPension.sum`
                      ),
                      id(
                        `${builder.getBaseId()}.previousYearPremiumWaiverInsurance.sum`
                      )
                    )
                  ),
                  undefined,
                  undefined
                )
                // ----   PREVIOUS YEAR DATA!  --- \\
                .addRow(
                  'previousYearDiff',
                  tooltip(
                    label('Avräkning löner enligt föregående års bokslut'),
                    'Anges med minustecken framför'
                  ),
                  undefined,
                  ref(
                    or(
                      id(
                        'previousYear.particularSalaryTax.fora.helper.salaryBase.sum.sum'
                      ),
                      0
                    )
                  ),
                  undefined,
                  undefined
                )
                .addRow(
                  'contractualPensionBelow75',
                  msg('Löneunderlag {start} - {end}', {
                    start: config('calendarYear.start'),
                    end: config('calendarYear.end'),
                  }),
                  label('Avtalspension SAF-LO lönedelar upp till 7,5 ibb'),
                  ref(
                    sum(
                      id(
                        `${builder.getBaseId()}.contractualPensionBelow75.*.value`
                      )
                    )
                  ),
                  value(0.043), // TODO config('')
                  ref(
                    multiply(
                      id(
                        `${builder.getBaseId()}.contractualPensionBelow75.value`
                      ),
                      id(
                        `${builder.getBaseId()}.contractualPensionBelow75.percentage`
                      )
                    )
                  )
                )
                .addSubRows((rows) =>
                  rows
                    .addRow(
                      '1',
                      undefined,
                      ref(
                        accountName(id(`${rows.getBaseId()}.1.accountNumber`))
                      ),
                      ref(
                        account(
                          id(`${rows.getBaseId()}.1.accountNumber`),
                          'change',
                          'calendarYear'
                        )
                      ),
                      undefined,
                      undefined,
                      undefined,
                      value(7010)
                    )

                    .build()
                )
                .newRowTemplate(
                  undefined,
                  ref(accountName(id('$id.accountNumber'))),
                  ref(
                    sum(
                      account(id('$id.accountNumber'), 'change', 'calendarYear')
                    )
                  ),
                  undefined,
                  undefined,
                  undefined,
                  value(undefined)
                )
                .addRow(
                  'contractualPensionOver75',
                  undefined,
                  label('Avtalspension SAF-LO lönedelar över 7,5 ibb'),
                  ref(
                    sum(
                      id(
                        `${builder.getBaseId()}.contractualPensionOver75.*.value`
                      )
                    )
                  ),
                  value(0.3), // TODO config('')
                  ref(
                    multiply(
                      id(
                        `${builder.getBaseId()}.contractualPensionOver75.value`
                      ),
                      id(
                        `${builder.getBaseId()}.contractualPensionOver75.percentage`
                      )
                    )
                  )
                )
                .addSubRows((rows) =>
                  rows
                    .addRow(
                      '1',
                      undefined,
                      ref(
                        accountName(id(`${rows.getBaseId()}.1.accountNumber`))
                      ),
                      ref(
                        account(
                          id(`${rows.getBaseId()}.1.accountNumber`),
                          'change',
                          'calendarYear'
                        )
                      ),
                      undefined,
                      undefined,
                      undefined,
                      value(7011)
                    )
                    .build()
                )
                .newRowTemplate(
                  undefined,
                  ref(accountName(id('$id.accountNumber'))),
                  ref(
                    account(id('$id.accountNumber'), 'change', 'calendarYear')
                  ),
                  undefined,
                  undefined,
                  undefined,
                  value(undefined)
                )
                .addRow(
                  'premiumWaiverInsurance',
                  undefined,
                  label('Premiebefrielseförsäkring'),
                  value(undefined),
                  value(0), // TODO config('')
                  ref(
                    multiply(
                      id(`${builder.getBaseId()}.premiumWaiverInsurance.value`),
                      id(
                        `${builder.getBaseId()}.premiumWaiverInsurance.percentage`
                      )
                    )
                  )
                )
                .addRow(
                  'severanceSupplementaryPension',
                  undefined,
                  label('Kollektivavtalade avg. extrapension'),
                  value(undefined),
                  value(0), // TODO config('')
                  ref(
                    multiply(
                      id(
                        `${builder.getBaseId()}.severanceSupplementaryPension.value`
                      ),
                      id(
                        `${builder.getBaseId()}.severanceSupplementaryPension.percentage`
                      )
                    )
                  )
                )
                .addRow(
                  'adjustmentOfSalaryBase1',
                  undefined,
                  tooltip(
                    label(
                      'Justering av löneunderlag, ökning (+) minskning (-)'
                    ),
                    'Denna ruta kan användas till att räkna bort löner för de som inte ingår i FORA, ex. anställda under 23 år.'
                  ),
                  value(0),
                  value(0), // TODO config('')
                  ref(
                    or(
                      multiply(
                        id(
                          `${builder.getBaseId()}.adjustmentOfSalaryBase1.value`
                        ),
                        id(
                          `${builder.getBaseId()}.adjustmentOfSalaryBase1.percentage`
                        )
                      ),
                      0
                    )
                  ),
                  value('')
                )
                .addRow(
                  'adjustmentOfSalaryBase2',
                  undefined,
                  tooltip(
                    label(
                      'Justering av löneunderlag, ökning (+) minskning (-)'
                    ),
                    'Denna ruta kan användas till att räkna bort löner för de som inte ingår i FORA, ex. anställda under 23 år.'
                  ),
                  value(0),
                  value(0), // TODO config('')
                  ref(
                    or(
                      multiply(
                        id(
                          `${builder.getBaseId()}.adjustmentOfSalaryBase2.value`
                        ),
                        id(
                          `${builder.getBaseId()}.adjustmentOfSalaryBase2.percentage`
                        )
                      ),
                      0
                    )
                  ),
                  value('')
                )
                .addRow(
                  'sum',
                  label('Summa'),
                  msg(`Löneunderlag {start} - {end}`, {
                    start: config('calendarYear.start'),
                    end: config('calendarYear.end'),
                  }),
                  ref(
                    sumAllowEmpty(
                      id(`${builder.getBaseId()}.c*.sum`),
                      id(
                        `${builder.getBaseId()}.severanceSupplementaryPension.sum`
                      ),
                      id(`${builder.getBaseId()}.premiumWaiverInsurance.sum`),
                      id(`${builder.getBaseId()}.adjustmentOfSalaryBase*.sum`)
                    )
                  ),
                  undefined,
                  undefined
                )
                .addRow(
                  'other',
                  undefined,
                  tooltip(
                    value('Övrigt'),
                    'Övrigt fält, exempelvis för att fylla i tredje årets belopp om det aktuella räkenskapsåret innehåller tre kalenderår.'
                  ),
                  value(0),
                  value(0),
                  ref(
                    multiply(
                      id(
                        'particularSalaryTax.fora.helper.salaryBase.other.value'
                      ),
                      id(
                        'particularSalaryTax.fora.helper.salaryBase.other.percentage'
                      )
                    )
                  ),
                  value(''),
                  undefined
                )
                .addRow(
                  'totalSum',
                  label('Summa'),
                  label('Löneunderlag FORA'),
                  ref(
                    ifOrElse(
                      sumAllowEmpty(
                        id(`${builder.getBaseId()}.previousYearSum.value`),
                        id(`${builder.getBaseId()}.sum.value`),
                        id(`${builder.getBaseId()}.previousYearDiff.value`),
                        id(`${builder.getBaseId()}.other.sum`)
                      ),
                      sumAllowEmpty(
                        id(`${builder.getBaseId()}.previousYearSum.value`),
                        id(`${builder.getBaseId()}.sum.value`),
                        id(`${builder.getBaseId()}.previousYearDiff.value`),
                        id(`${builder.getBaseId()}.other.sum`)
                      ),
                      '0'
                    )
                  ),
                  undefined,
                  undefined
                )
                .build()
            )
            .build(),
        },
      },
      itp: {
        itpPremiums: ref(
          id('particularSalaryTax.itp.helper.premiums.sum.value')
        ),
        unpaidITP: ref(
          or(
            id(
              'particularSalaryTax.itp.helper.premiums.invoicesForPensionPremiums.value'
            ),
            0
          )
        ),
        ITPKPremiums: table(
          'particularSalaryTax.itp.IPTKPremiums',
          'accountNumber',
          'label',
          'value'
        )
          .newRowTemplate(
            value(undefined),
            ref(accountName(id('$id.accountNumber'))),
            ref(account(id('$id.accountNumber')))
          )
          .addRows((builder) =>
            builder
              .addRow(
                '1',
                value(7411),
                ref(accountName(id(`${builder.getBaseId()}.1.accountNumber`))),
                ref(account(id(`${builder.getBaseId()}.1.accountNumber`)))
              )
              .build()
          )
          .build(),
        ITPKPremiumsSum: ref(
          sumAllowEmpty(id('particularSalaryTax.itp.ITPKPremiums.*.value'))
        ),
        unpaidITPK: field(0),
        unpaidITPKNote: field(''),
        helper: {
          premiums: table(
            'particularSalaryTax.itp.helper.premiums',
            'title',
            'label',
            'value',
            'percentage',
            'sum',
            'value2',
            'label2'
          )
            .addRows((builder) =>
              builder
                .addRow(
                  'bookedPremiums',
                  undefined,
                  label('Bokförda premier ITP 1 & ITP 2'),
                  value(undefined),
                  undefined, // TODO config('')
                  undefined
                )
                .addRow(
                  'tglPremiums',
                  label('Avgår för TGL-premier'),
                  label('Antal anställda'),
                  value(0), //antal anställda
                  value(26, undefined, 'kr'),
                  ref(
                    multiply(
                      id(`${builder.getBaseId()}.tglPremiums.value`),
                      id(`${builder.getBaseId()}.tglPremiums.value2`),
                      id(`${builder.getBaseId()}.tglPremiums.percentage`)
                    )
                  ), // Calculate antal anställda x månader x percentage
                  value(0), // antal månader
                  label('Antal månader')
                )
                .addRow(
                  'annualSalaryUpTo75',
                  undefined,
                  label('Årslönesumma upp till 7,5 ibb per anställd'),
                  value(undefined), // Input for label
                  value(0.0008), // Multiplier
                  ref(
                    multiply(
                      id(`${builder.getBaseId()}.annualSalaryUpTo75.value`),
                      id(`${builder.getBaseId()}.annualSalaryUpTo75.percentage`)
                    )
                  )
                )
                .addRow(
                  'annualSalaryAbove75',
                  undefined,
                  label('Årslönesumma över 7,5 ibb per anställd'),
                  value(undefined), // Input for label
                  value(0.004), // Multiplier
                  ref(
                    multiply(
                      id(`${builder.getBaseId()}.annualSalaryAbove75.value`),
                      id(
                        `${builder.getBaseId()}.annualSalaryAbove75.percentage`
                      )
                    )
                  )
                )
                .addRow(
                  'invoicesForPensionPremiums',
                  undefined,
                  label(
                    'Skuld (-)/fordran (+) ej betalda fakturor för pensionspremie'
                  ),
                  value(undefined), // Input for label
                  undefined,
                  undefined
                )
                .addRow(
                  'sum',
                  label('Summa'),
                  label('Underlag ITP'),
                  ref(
                    sumAllowEmpty(
                      id(`${builder.getBaseId()}.bookedPremiums.value`),
                      id(`${builder.getBaseId()}.tglPremiums.sum`),
                      id(`${builder.getBaseId()}.annualSalaryUpTo75.sum`),
                      id(`${builder.getBaseId()}.annualSalaryAbove75.sum`),
                      id(
                        `${builder.getBaseId()}.invoicesForPensionPremiums.value`
                      )
                    )
                  ),
                  undefined,
                  undefined
                )
                .build()
            )
            .build(),
        },
      },
      otherPensionCommitments: {
        otherPensionInsurances: table(
          'particularSalaryTax.otherPensionCommitments.otherPensionInsurances',
          'accountNumber',
          'label',
          'value'
        )
          .newRowTemplate(
            value(undefined),
            ref(accountName(id('$id.accountNumber'))),
            ref(account(id('$id.accountNumber')))
          )
          .addRows((builder) =>
            builder
              .addRow(
                '1',
                value(7412),
                ref(accountName(id(`${builder.getBaseId()}.1.accountNumber`))),
                ref(account(id(`${builder.getBaseId()}.1.accountNumber`)))
              )
              .build()
          )
          .build(),
        otherPensionInsurancesSum: ref(
          sumAllowEmpty(
            id(
              'particularSalaryTax.otherPensionCommitments.otherPensionInsurances.*.value'
            )
          )
        ),
        foreignPensionInsurances: table(
          'particularSalaryTax.otherPensionCommitments.foreignPensionInsurances',
          'accountNumber',
          'label',
          'value'
        )
          .newRowTemplate(
            value(undefined),
            ref(accountName(id('$id.accountNumber'))),
            ref(account(id('$id.accountNumber')))
          )
          .addRows((builder) =>
            builder
              .addRow(
                '1',
                value(7413),
                ref(accountName(id(`${builder.getBaseId()}.1.accountNumber`))),
                ref(account(id(`${builder.getBaseId()}.1.accountNumber`)))
              )
              .build()
          )
          .build(),
        foreignPensionInsurancesSum: ref(
          sumAllowEmpty(
            id(
              'particularSalaryTax.otherPensionCommitments.foreignPensionInsurances.*.value'
            )
          )
        ),
        paymentPensionFoundation: value(0),
        paymentPensionFoundationNote: value(''),
        compensationPensionCommitments: value(0),
        compensationPensionCommitmentsNote: value(''),
        pensionProvisions: value(0),
        pensionProvisionsNote: value(''),
      },
      summarize: {
        taxationBasis: ref(
          sumAllowEmpty(
            ifOrElse(
              or(
                config('brokenFinancialYear'),
                // yearPercetange is 1 for the last period and less for others
                // so floor will give 1 for the last period and 0 for the others
                // So this equals "not last period"
                not(floor(config('yearPercentage')))
              ),
              id('particularSalaryTax.fora.salaryBase'),
              '0'
            ),
            ifOrElse(
              or(
                config('brokenFinancialYear'),
                not(floor(config('yearPercentage')))
              ),
              '0',
              sumAllowEmpty(
                id('particularSalaryTax.fora.accountsSum'),
                id('particularSalaryTax.fora.unpaidFORA'),
                id('particularSalaryTax.fora.accruedDebtToFORA'),
                id('particularSalaryTax.fora.paidPremiumsPreviousYear')
              )
            ),
            id('particularSalaryTax.itp.itpPremiums'),
            id('particularSalaryTax.itp.ITPKPremiumsSum'),
            id('particularSalaryTax.itp.unpaidITPK'),
            id(
              'particularSalaryTax.otherPensionCommitments.otherPensionInsurancesSum'
            ),
            id(
              'particularSalaryTax.otherPensionCommitments.foreignPensionInsurancesSum'
            ),
            id(
              'particularSalaryTax.otherPensionCommitments.paymentPensionFoundation'
            ),
            id(
              'particularSalaryTax.otherPensionCommitments.compensationPensionCommitments'
            ),
            id('particularSalaryTax.otherPensionCommitments.pensionProvisions')
          )
        ),
        particularSalaryTax: ref(
          multiply(id('particularSalaryTax.summarize.taxationBasis'), 0.2426)
        ),
        recordedPayrollTax: table(
          'particularSalaryTax.summarize.recordedPayrollTax',
          'accountNumber',
          'label',
          'value'
        )
          .newRowTemplate(
            value(undefined),
            ref(accountName(id('$id.accountNumber'))),
            ref(account(id('$id.accountNumber')))
          )
          .addRows((builder) =>
            builder
              .addRow(
                '1',
                value(7530),
                ref(accountName(id(`${builder.getBaseId()}.1.accountNumber`))),
                ref(
                  or(account(id(`${builder.getBaseId()}.1.accountNumber`)), 0)
                )
              )
              .addRow(
                '2',
                value(7531),
                ref(accountName(id(`${builder.getBaseId()}.2.accountNumber`))),
                ref(
                  or(account(id(`${builder.getBaseId()}.2.accountNumber`)), 0)
                )
              )
              .addRow(
                '3',
                value(7532),
                ref(accountName(id(`${builder.getBaseId()}.3.accountNumber`))),
                ref(
                  or(account(id(`${builder.getBaseId()}.3.accountNumber`)), 0)
                )
              )
              .addRow(
                '4',
                value(7533),
                ref(accountName(id(`${builder.getBaseId()}.4.accountNumber`))),
                ref(
                  or(account(id(`${builder.getBaseId()}.4.accountNumber`)), 0)
                )
              )
              .build()
          )
          .build(),
        recordedPayRollTaxSum: ref(
          sum(id('particularSalaryTax.summarize.recordedPayrollTax.*.value'))
        ),
        taxToBeBooked: ref(
          sumAllowEmpty(
            id('particularSalaryTax.summarize.particularSalaryTax'),
            multiply(
              -1,
              id('particularSalaryTax.summarize.recordedPayRollTaxSum')
            )
          )
        ),
      },
      notYetBookedParticualSalaryTax: ref(
        ifOrElse(
          id('particularSalaryTax.isDetailedSpecification'),
          id('particularSalaryTax.summarize.taxToBeBooked'),
          id('particularSalaryTaxToBook')
        )
      ),
      isDetailedSpecification: value(true),
      finacialStatementVerification: {
        transactions: table(
          'particularSalaryTax.finacialStatementVerification.transactions',
          'accountNumber',
          'credit',
          'debit'
        )
          .addRows((builder) =>
            builder
              .addRow(
                '1',
                value(7530),
                ref(
                  sumAllowEmpty(
                    id('particularSalaryTax.summarize.particularSalaryTax'),
                    multiply(
                      -1,
                      id('particularSalaryTax.summarize.recordedPayRollTaxSum')
                    )
                  )
                ),
                value(0)
              )
              .addRow(
                '2',
                value(2514),
                value(0),
                ref(
                  sumAllowEmpty(
                    id('particularSalaryTax.summarize.particularSalaryTax'),
                    multiply(
                      -1,
                      id('particularSalaryTax.summarize.recordedPayRollTaxSum')
                    )
                  )
                )
              )
              .build()
          )
          .build(),
      },
    },
    accrualFunds: {
      specificationReversalOfAccrualFund: table(
        'accrualFunds.specificationReversalOfAccrualFund',
        { id: 'accountNumber', label: 'Konto' },
        { id: 'reversalAmount', label: 'Återför belopp' },
        { id: 'percentage', label: 'Procentsats' },
        { id: 'interestAmount', label: 'Uppräknat belopp' },
        { id: 'ub', label: 'UB Kontosaldo' }
      )
        .addRows((rows) => {
          range(6, 0, -1).forEach((i) => {
            rows.addRow(
              i.toString(),
              ref(id(`accrualFund-${i}.account`)),
              ref(id(`accrualFunds.depositionOfAccrualFund.${i}.change`)),
              ref(config(`accrualInterestPercentage.${i}`)),
              ref(
                multiply(
                  id(`${rows.getBaseId()}.${i}.reversalAmount`),
                  id(`${rows.getBaseId()}.${i}.percentage`)
                )
              ),
              ref(id(`accrualFunds.depositionOfAccrualFund.${i}.ub`))
            );
          });

          rows.addRow(
            'totalAccrualInterestAmount',
            { type: 'label', value: 'Totalt' },
            ref(
              sum(
                ...range(6, 0, -1).map((i) =>
                  id(`${rows.getBaseId()}.${i}.reversalAmount`)
                )
              )
            ),
            undefined,
            ref(
              sum(
                ...range(6, 0, -1).map((i) =>
                  id(`${rows.getBaseId()}.${i}.interestAmount`)
                )
              )
            ),
            undefined
          );

          return rows.build();
        })
        .build(),
      depositionOfAccrualFund: table(
        'accrualFunds.depositionOfAccrualFund',
        { id: 'date', label: 'Räkenskapsår' },
        { id: 'accountNumber', label: 'Konto' },
        { id: 'ib', label: 'Ingående balans' },
        { id: 'change', label: 'Förändring' },
        { id: 'ub', label: 'Utgående balans' }
      )
        .addRows((rows) => {
          range(6, 0, -1).forEach((i) => {
            rows.addRow(
              i.toString(),
              ref(config(`accrualFund.date.${i}`)),
              ref(id(`accrualFund-${i}.account`)),
              ref(sum(account(id(`accrualFund-${i}.account`), 'ib'))),
              ref(
                max(
                  min(
                    or(
                      multiply(
                        -1,
                        account(id(`accrualFund-${i}.account`), 'ib')
                      ),
                      0
                    ),
                    sum(
                      id('chosenAndReversalOfAccrualFundIncludingForced'),
                      ...Array.from(new Array(6 - i), (el, index) =>
                        or(
                          multiply(
                            -1,
                            id(`${rows.getBaseId()}.${-(index - 6)}.change`)
                          ),
                          0
                        )
                      )
                    )
                  ),
                  0
                )
              ),
              ref(
                sum(
                  account(id(`accrualFund-${i}.account`), 'ib'),
                  id(`${rows.getBaseId()}.${i}.change`)
                )
              )
            );
          });

          rows.addRow(
            'totalDepositionOfAccrualFund',
            { type: 'label', value: 'Totalt' },
            undefined,
            ref(
              sum(
                ...range(6, 0, -1).map((i) =>
                  or(id(`${rows.getBaseId()}.${i}.ib`), 0)
                )
              )
            ),
            ref(
              sum(
                ...range(6, 0, -1).map((i) =>
                  or(id(`${rows.getBaseId()}.${i}.change`), 0)
                )
              )
            ),
            ref(
              sum(
                ...range(6, 0, -1).map((i) =>
                  or(id(`${rows.getBaseId()}.${i}.ub`), 0)
                )
              )
            )
          );

          return rows.build();
        })
        .build(),
      adjustments: table(
        'accrualFunds.adjustments',
        { id: 'label' },
        { id: 'account' },
        { id: 'value' }
      )
        .addRows((rows) => {
          rows.addRow(
            'chosenReversalOfAccrualFund_from',
            value('Vald återföring av periodiseringsfond'),
            value(8819),
            ref(
              multiply(
                -1,
                max(
                  sum(
                    id('chosenReversalOfAccrualFund'),
                    or(account('8819', 'ub'), 0)
                  ),
                  0
                )
              )
            )
          );

          for (let i = 5; i > 0; i -= 1) {
            rows.addRow(
              `chosenReversalOfAccrualFund_to_${i}`,
              value('Vald återföring av periodiseringsfond'),
              ref(id(`accrualFund-${i}.account`)),
              ref(
                max(
                  sum(
                    min(
                      or(
                        multiply(-1, account(id(`accrualFund-${i}.account`))),
                        0
                      ),
                      sum(
                        id('chosenReversalOfAccrualFund'),
                        or(id('forcedReversalOfAccrualFund'), 0),
                        ...Array.from(new Array(6 - i), (el, index) =>
                          or(
                            account(
                              id(`accrualFund-${-(index - 6)}.account`),
                              'ib'
                            ),
                            0
                          )
                        )
                      )
                    ),
                    multiply(
                      -1,
                      or(account(id(`accrualFund-${i}.account`), 'change'), 0)
                    )
                  ),
                  0
                )
              )
            );
          }

          rows.addRow(
            'forcedReversalOfAccrualFundMinusBooked_from',
            value('Tvingande återföring av periodiseringsfond'),
            value(8819),
            ref(multiply(-1, id('forcedReversalOfAccrualFundMinusBooked')))
          );

          rows.addRow(
            'forcedReversalOfAccrualFundMinusBooked_to',
            value('Tvingande återföring av periodiseringsfond'),
            ref(id('accrualFund-6.account')),
            ref(id('forcedReversalOfAccrualFundMinusBooked'))
          );

          rows.addRow(
            'chosenDepositionToAccrualFund_from',
            value('Vald avsättning till periodiseringsfond'),
            value(8811),
            ref(multiply(-1, id('chosenDepositionToAccrualFund')))
          );

          rows.addRow(
            'chosenDepositionToAccrualFund_to',
            value('Vald avsättning till periodiseringsfond'),
            value(2129),
            ref(id('chosenDepositionToAccrualFund'))
          );

          return rows.build();
        })
        .newRowTemplate(value(''), value(undefined), value(undefined))
        .build(),
    },
    yearTaxVoucher: table(
      'yearTaxVoucher',
      { id: 'label' },
      { id: 'account' },
      { id: 'value' }
    )
      .addRows((rows) => {
        rows.addRow(
          'taxToBook_from',
          value('Årets skatt'),
          value(8910),
          ref(id('taxToBook'))
        );
        rows.addRow(
          'taxToBook_to',
          value('Årets skatt'),
          value(2510),
          ref(multiply(-1, id('taxToBook')))
        );

        return rows.build();
      })
      .newRowTemplate(value(''), value(undefined), value(undefined))
      .build(),
    yearResultVoucher: table(
      'yearResultVoucher',
      { id: 'label' },
      { id: 'account' },
      { id: 'value' }
    )
      .addRows((rows) => {
        rows.addRow(
          'resultToBook_from',
          value('Skatt som ska bokföras'),
          value(8999),
          ref(
            companyType === 'individual'
              ? id('resultBeforeFrom3000to8799')
              : id('resultToBook')
          )
        );
        rows.addRow(
          'resultToBook_to',
          value('Skatt som ska bokföras'),
          value(companyType ? +companyTypeAccounts[companyType] : 2099),
          ref(
            companyType === 'individual'
              ? multiply(-1, id('resultBeforeFrom3000to8799'))
              : multiply(-1, id('resultToBook'))
          )
        );

        return rows.build();
      })
      .newRowTemplate(value(''), value(undefined), value(undefined))
      .build(),
    otherAdjustmentsVoucher: table(
      'otherAdjustmentsVoucher',
      { id: 'label' },
      { id: 'account' },
      { id: 'value' }
    )
      .addRows((rows) => {
        rows.addRow(
          'chosenOtherAdjustment1_from',
          value('Övrig bokslutsjustering 1'),
          value(undefined),
          ref(id('chosenOtherAdjustment1'))
        );
        rows.addRow(
          'chosenOtherAdjustment1_to',
          value('Övrig bokslutsjustering 1'),
          value(undefined),
          ref(multiply(-1, id('chosenOtherAdjustment1')))
        );

        rows.addRow(
          'chosenOtherAdjustment2_from',
          value('Övrig bokslutsjustering 2'),
          value(undefined),
          ref(id('chosenOtherAdjustment2'))
        );
        rows.addRow(
          'chosenOtherAdjustment2_to',
          value('Övrig bokslutsjustering 2'),
          value(undefined),
          ref(multiply(-1, id('chosenOtherAdjustment2')))
        );

        return rows.build();
      })
      .newRowTemplate(value(''), value(undefined), value(undefined))
      .build(),

    groupContributionVoucher: table(
      'groupContributionVoucher',
      { id: 'label' },
      { id: 'account' },
      { id: 'value' }
    )
      .addRows((rows) => {
        rows.addRow(
          'chosenSentGroupContributions_from',
          value('Valda lämnade koncernbidrag'),
          value(8830),
          ref(multiply(-1, id('chosenSentGroupContributions')))
        );
        rows.addRow(
          'chosenSentGroupContributions_to',
          value('Valda lämnade koncernbidrag'),
          value(2360),
          ref(id('chosenSentGroupContributions'))
        );

        rows.addRow(
          'chosenReceivedGroupContributions_from',
          value('Valda mottagna koncernbidrag'),
          value(8820),
          ref(multiply(-1, id('chosenReceivedGroupContributions')))
        );
        rows.addRow(
          'chosenReceivedGroupContributions_to',
          value('Valda mottagna koncernbidrag'),
          value(1310),
          ref(id('chosenReceivedGroupContributions'))
        );

        return rows.build();
      })
      .newRowTemplate(value(''), value(undefined), value(undefined))
      .build(),
    expandedVouchers: {
      particularSalaryTax: value(false),
      accrualFunds: value(false),
      yearTax: value(false),
      yearResult: value(false),
      propertyTax: value(false),
      otherAdjustmentsVoucher: value(false),
      groupContributionVoucher: value(false),
      intangibleAssets: value(false),
      buildingsAndGroundFacilities: value(false),
      machinesAndInventory: value(false),
    },
    propertyTaxTotal: value(0),
  },
});
export const configBase = getConfigBase();

export const yearEndPlanningConfig = configBase.yearEndPlanning;
export const taxCalculationTableConfig = configBase.taxCalculation;
export const nonTaxableIncomeCalculationTableConfig =
  configBase.nonTaxableIncomes;
export const nonDeductibleExpensesConfig = configBase.nonDeductibleExpenses;
export const particularSalaryTaxConfig = configBase.particularSalaryTax;

/**
 * * Can't use this adjustmentsConfig because of receiving company type from the props;
 * TODO: re-write baseConfig;
 */
export const adjustmentsConfig = configBase.adjustments;
export const taxDocumentsConfig = configBase.taxDocuments;

const mergeRow = (
  configRow: TaxCalculationRow,
  storedRow: TaxCalculationRow | undefined
): TaxCalculationRow => {
  if (!storedRow) {
    return configRow;
  }

  // The part that can be changed by a user is the label, value and deleted
  if (
    configRow.label !== storedRow.label ||
    configRow.value !== storedRow.label ||
    configRow.reference !== storedRow.reference ||
    storedRow.deleted
  ) {
    return {
      ...configRow,
      label: storedRow.label,
      value: storedRow.value,
      deleted: storedRow.deleted,
      reference:
        storedRow.reference && configRow.reference
          ? configRow.reference
          : storedRow.reference,
    };
  }
  return configRow;
};

export const mergeRows = (
  config: TaxCalculationRow[],
  stored: TaxCalculationRow[]
): TaxCalculationRow[] => {
  // Refresh the stored config with possible updates
  const storedById = arrayToRecord(stored, (row) => row.id);
  return [
    ...config.map((configRow) => mergeRow(configRow, storedById[configRow.id])),
    ...stored.filter((row) => row.id.startsWith('@')),
  ];
};

/**
 * Creates the base configuration, the default setup for a financial year
 *
 * @param financialYear
 * @param periods
 * @returns
 */
export const createBaseTaxConfig = (
  financialYear: string,
  periods: string[],
  companyType: CompanyTypeAccounts
): TaxCalculationConfig => {
  const configBase = getConfigBase(companyType);
  const endYear = financialYear.split('-')[1];
  const year = endYear
    ? endYear.substring(0, 4)
    : financialYear.substring(0, 4);

  return {
    ...configBase,
    accrualFunds: {
      ...configBase.accrualFunds,
      rows: createYears(
        periods,
        (i) => `accrualFund-${i}`,
        (i) => `or(account(id(accrualFund-${i}.account),ib))`
      ),
    },
    accrualFundsAccounts: {
      ...configBase.accrualFundsAccounts,
      rows: createYears(periods, (i) => `accrualFund-${i}.account`),
    },
    adjustments: {
      ...configBase.adjustments,
      rows: [
        ...configBase.adjustments.rows,
        ...adjustmentRow(
          'resultToBook',
          '8999',
          companyType ? companyTypeAccounts[companyType] : '2099'
        ),
      ],
    },
    companyTax: companyTaxPerYear[year],
    templateTax: getTemplateTax(year),
  };
};

/**
 * createTaxConfig merges the base configuration with stored changes.
 *
 * @param baseConfig
 * @param storedConfig
 * @returns
 */
export const createTaxConfig = (
  baseConfig: TaxCalculationConfig,
  storedConfig: StoredTaxConfig,
  companyType: CompanyTypeAccounts
): TaxCalculationConfig => {
  let config: TaxCalculationConfig = {
    ...baseConfig,
    ...storedConfig,
  };
  const configBase = getConfigBase(companyType);

  config = {
    ...config,
    yearEndPlanning: {
      ...config.yearEndPlanning,
      rows: mergeRows(
        configBase.yearEndPlanning.rows,
        storedConfig.yearEndPlanning.rows
      ),
    },
    taxCalculation: {
      ...config.taxCalculation,
      rows: mergeRows(
        configBase.taxCalculation.rows,
        storedConfig.taxCalculation.rows
      ),
    },
    accrualFunds: {
      ...baseConfig.accrualFunds,
      rows: mergeRows(
        baseConfig.accrualFunds.rows,
        storedConfig.accrualFunds.rows
      ),
    },
  };
  if (config.accrualFunds.rows.some((row) => !row.label)) {
    // When agoy-app creates a config, the label can be missing
    config = {
      ...config,
      accrualFunds: {
        ...config.accrualFunds,
        rows: config.accrualFunds.rows.map((row, index) =>
          row.label
            ? row
            : { ...row, label: baseConfig.accrualFunds.rows[index].label }
        ),
      },
    };
  }

  const apply = applyChanges(contentDefinition);
  config.document = apply(
    config.document as TaxViewDocument,
    config.documentChanges
  );

  return config;
};

/**
 * getTaxConfig, loads the stored config from backend and merges with the current config
 * allow for updates.
 *
 * @param clientId
 * @param financialYear
 * @param periods The monthly periods of the year, format YYYYMMDD
 * @returns
 */
export const getTaxConfig = async (
  getStoredTaxConfig: (
    clientId: string,
    financialYear: string
  ) => Promise<StoredTaxConfig | null>,
  clientId: string,
  financialYear: string,
  periods: string[],
  companyType: CompanyTypeAccounts
): Promise<TaxCalculationConfig> => {
  const newTaxConfig = createBaseTaxConfig(financialYear, periods, companyType);

  try {
    const storedTaxConfig = await getStoredTaxConfig(clientId, financialYear);

    if (storedTaxConfig) {
      return createTaxConfig(newTaxConfig, storedTaxConfig, companyType);
    }
    return newTaxConfig;
  } catch (e) {
    return newTaxConfig;
  }
};

const cell = {
  type: 'cell',
  active: 'boolean',
};

export const menuDefinition = {
  salarytax: {
    section: {
      title: cell,
    },
  },
  propertytax: {
    section: {
      title: cell,
    },
  },
  calculation: {
    section: {
      title: cell,
    },
  },
  accruals: {
    section: {
      title: cell,
    },
  },
  specifications: {
    section: {
      title: cell,
    },
  },
  internspecifications: {
    section: {
      title: cell,
    },
  },
  externspecifications: {
    section: {
      title: cell,
    },
  },
  comments: {
    section: {
      title: cell,
    },
  },
  overdepreciation: {
    section: {
      title: cell,
    },
  },
  verifications: {
    section: {
      title: cell,
    },
  },
  documents: {
    section: {
      title: cell,
    },
  },
};

/**
 * Get flatten keys of object as string[]
 */
export const flattenObjKeys = (obj: Record<string, any>): string[] => {
  return Object.keys(flattenObj(obj));
};

/**
 * Get flatten keys of object with values
 * res: `{a: 1, a.b: 2, a.b.c: 3}`
 */
const flattenObj = (
  obj: Record<string, any>,
  parent?: string,
  res = {}
): Record<string, any> => {
  for (let key in obj) {
    let propName = parent ? parent + '.' + key : key;
    if (typeof obj[key] == 'object') {
      flattenObj(obj[key], propName, res);
    } else {
      res[propName] = obj[key];
    }
  }
  return res;
};

/**
 * Get all the unique keys from an object
 */
export const getUniqueKeys = (obj: Record<string, any>): string[] => {
  return Object.keys(flattenObjSingleKey(obj));
};

/**
 * Get flatten keys (1 level nested) of object with values
 * res: `{a: 1, b: 2, c: 3}`
 */
const flattenObjSingleKey = (
  obj: Record<string, any>,
  res = {}
): Record<string, any> => {
  for (let key in obj) {
    let propName = key;
    if (typeof obj[key] == 'object') {
      res[propName] = obj[key];
      flattenObjSingleKey(obj[key], res);
    } else {
      res[propName] = obj[key];
    }
  }
  return res;
};

import React, { useContext } from 'react';

import Typography from '@material-ui/core/Typography';
import { TextField } from '@material-ui/core';
import styled from '@emotion/styled';

import EditField from '_shared/components/Inputs/EditField';
import FinancialReportViewServiceContext from '_financial-report/service/FinancialReportViewServiceContext';

interface PageTitleProps {
  isEditing: boolean;
  fieldId: string;
  title: string;
  className?: string;
  customColor: string | undefined;
}

const TitleNameField = styled(({ disableUnderline, ...rest }) => (
  <TextField multiline size="medium" variant="outlined" {...rest} />
))`
  .MuiInputBase-root {
    font-size: 28px;
    width: 100%;
    height: px;
  }
`;

type StyledPageTitleProps = {
  customColor: string | undefined;
};

const StyledPageTitle = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'customColor',
})<StyledPageTitleProps>`
  color: ${(props) => props.customColor || props.theme.palette.text.primary};
`;

const PageTitle = ({
  isEditing,
  fieldId,
  title,
  className,
  customColor,
}: PageTitleProps): JSX.Element => {
  const service = useContext(FinancialReportViewServiceContext);

  const updateTitle = (newTitle) => {
    service.updateField(fieldId, newTitle);
  };

  return isEditing ? (
    <EditField
      value={title}
      onChange={(newValue) => updateTitle(newValue)}
      component={TitleNameField}
      className={className}
    />
  ) : (
    <StyledPageTitle
      className={className}
      variant="h2"
      customColor={customColor}
    >
      {title}
    </StyledPageTitle>
  );
};

export default PageTitle;

import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { Typography, Switch, FormControlLabel, Box } from '@material-ui/core';
import styled from '@emotion/styled';

import { useSelector } from 'redux/reducers';
import { Period } from '@agoy/api-sdk-core';
import { InputData } from '_reconciliation/types';
import { currentClientForNewReconciliation } from '_reconciliation/redux/accounting-view/selectors';
import { parse } from '@agoy/dates';
import { getRoutine } from './routines';
import TransferComment from './TransferComment';
import MultilineTextField from './Input';

import InternalComments from './InternalComments';
import PeriodDataContext from '../PeriodDataContext';
import {
  onUserInputCallbackFunction,
  onUserInputRoutineCallbackFunction,
} from '../types';

const CommentAndRoutineColumn = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  width: 30%;
`;

interface CommentsProps {
  account: string;
  userData: InputData;
  period: Period;
  onUserInputCallback: onUserInputCallbackFunction;
  onUserInputRoutineCallback: onUserInputRoutineCallbackFunction;
  onChangeExternalComment: onUserInputCallbackFunction;
  isLocked: boolean;
}

const Comments = ({
  account,
  userData,
  period,
  onUserInputCallback,
  onUserInputRoutineCallback,
  onChangeExternalComment,
  isLocked,
}: CommentsProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const { clientId, periodType } = useContext(PeriodDataContext);

  const { comment, routine, printComment = true } = userData || {};

  const previousRoutine = useSelector(
    currentClientForNewReconciliation(clientId, (state) => {
      const routinesOnAccount = state.routines?.[`account${account}`];
      if (!routinesOnAccount) {
        return undefined;
      }
      return getRoutine(parse(period.start), routinesOnAccount);
    })
  );

  const onExternalCommentUpdate = (event) => {
    const newComment = event.target.value;
    onChangeExternalComment(
      {
        comment: newComment,
      },
      account,
      period
    );
  };

  const onPrintExternalCommentUpdate = (event) => {
    const value = event.target.checked;
    onChangeExternalComment(
      {
        printComment: value,
      },
      account,
      period
    );
  };

  const onRoutineUpdate = (event) => {
    const newRoutineText = event.target.value;
    onUserInputRoutineCallback(
      { routine: newRoutineText.trim() ? newRoutineText : '' },
      account,
      period
    );
  };

  // TODO: activate for year view when it's finished
  return (
    <CommentAndRoutineColumn>
      <InternalComments
        account={account}
        period={period}
        onUserInputCallback={onUserInputCallback}
      />

      <Box display="flex" justifyContent="space-between" marginTop={2}>
        <Typography variant="body1" color="textSecondary">
          {formatMessage({ id: 'externalComment' })}
        </Typography>
        <FormControlLabel
          control={
            <Switch
              size="small"
              checked={printComment}
              onChange={onPrintExternalCommentUpdate}
              disabled={isLocked}
            />
          }
          label={
            <Typography variant="body1" color="textSecondary">
              {formatMessage({ id: 'includeToPrint' })}
            </Typography>
          }
        />
      </Box>
      <MultilineTextField
        placeholder={formatMessage({ id: 'externalComment' })}
        defaultValue={comment || ''}
        onBlur={onExternalCommentUpdate}
        isLocked={isLocked}
      />
      <TransferComment
        comment={comment}
        period={period}
        accountNumber={account}
        userInputCallback={onUserInputCallback}
      />
      <Box marginTop={2}>
        <Typography variant="body1" color="textSecondary">
          {formatMessage({ id: 'routine' })}
        </Typography>

        <MultilineTextField
          placeholder={formatMessage({ id: 'routine' })}
          defaultValue={routine || previousRoutine || ''}
          onBlur={onRoutineUpdate}
          isLocked={isLocked}
        />
      </Box>
    </CommentAndRoutineColumn>
  );
};

export default Comments;

const helpStructureINK3 = {
  type: 'part' as const,
  _type: 'INK3' as const,
  children: {
    preliminaryTaxPerMonth: { type: 'field' as const },
    months: { type: 'field' as const },
  },
};

export default helpStructureINK3;

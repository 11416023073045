import {
  TaxDeclarationFormDefinition,
  TaxDeclarationFormDataConfig,
  PersonMetaData,
} from '../../types';
import {
  div,
  id,
  ifOrElse,
  multiply,
  multiplyPercentage,
  ref,
  sumAllowEmpty,
  value,
  table,
  label,
  or,
} from '@agoy/document';
import {
  absolute,
  mapFormId,
  negative,
  onlyNegative,
  onlyPositive,
  toInitial,
  toStructure,
} from '../forms-util';
import helpStructureK6 from '../../types/help/K6';

const configK6 = (
  form: TaxDeclarationFormDefinition,
  person: PersonMetaData,
  financialYearEnd: string
): TaxDeclarationFormDataConfig => {
  const is2022 = financialYearEnd === '20221231';

  const name = `${person?.firstName || ''} ${person?.lastName || ''}`;
  const K6 = mapFormId(form, 'K6');
  const initial = {
    K6: {
      partType: 'form' as const,
      id: K6,
      fields: {
        datum: value(''),
        numreringFleraK6: value(undefined),
        namn: value(name),
        personNummer: value(person.personNumber || ''),
        A_lagenhetsbeteckning_hos_bostadsrattsforeningen: value(''),
        A_bostadsrattsforeningens_organisationsnummer: value(''),
        A_bostadsrattsforeningens_namn: value(''),
        A_forsaljningsdatum_enligt_kopekontrakt: value(''),
        A_inkopsdatum_enligt_kopekontrakt: value(''),
        A_hela_innehavstiden: value(false),
        A_del_av_innehavstiden: value(false),
        A_fr_o_m: value(''),
        A_t_o_m: value(''),
        A_andel_i_procent: value(undefined),
        B_gemensam_for_flera_delagare: value(false),
        B_individuell_for_flera_delagare: value(false),
        B_andel_av_bostadsratten: value(undefined),
        B_150_procent_av_bostadsrattens_andel: value(false),
        B_1_forsaljningspris_enligt_kopekontrakt: value(undefined),
        B_2_forsaljningsutgifter_maklararvode: value(undefined),
        B_3_inkopspris: value(undefined),
        B_4_forbattringsutgifter_grundforbattringar: ref(
          sumAllowEmpty(id('K6.help.improvements.*.amount'))
        ),
        B_5_forbattringsutgifter_forbattrande_reparationer: ref(
          sumAllowEmpty(id('K6.help.renovation.*.amount'))
        ),
        B_6_kapitaltillskott: value(undefined),
        B_7_bostadsrattens_andel_reparationsfond_vid_forsaljningen:
          value(undefined),
        B_8_bostadsrattens_andel_reparationsfond_vid_kopet: value(undefined),
        B_9_vinst_eller_forlust: ref(
          sumAllowEmpty(
            id('K6.fields.B_1_forsaljningspris_enligt_kopekontrakt'),
            negative(id('K6.fields.B_2_forsaljningsutgifter_maklararvode')),
            negative(id('K6.fields.B_3_inkopspris')),
            negative(
              id('K6.fields.B_4_forbattringsutgifter_grundforbattringar')
            ),
            negative(
              id('K6.fields.B_5_forbattringsutgifter_forbattrande_reparationer')
            ),
            negative(id('K6.fields.B_6_kapitaltillskott')),
            negative(
              id(
                'K6.fields.B_7_bostadsrattens_andel_reparationsfond_vid_forsaljningen'
              )
            ),

            id('K6.fields.B_8_bostadsrattens_andel_reparationsfond_vid_kopet')
          )
        ),
        B_10_vinst_eller_forlust_x_andel_salt:
          // If B_gemensam is checked then B10 = B9 * B_andel otherwise B10 = B9;
          ref(
            ifOrElse(
              id('K6.fields.B_gemensam_for_flera_delagare'),
              multiplyPercentage(
                id('K6.fields.B_9_vinst_eller_forlust'),
                id('K6.fields.B_andel_av_bostadsratten')
              ),
              id('K6.fields.B_9_vinst_eller_forlust')
            )
          ),
        B_11_aterforing_av_uppskovsbelopp: value(undefined),
        B_12_vinst_for_over_beloppet:
          // If B.10 + B.11 is positive then B.12 = B.10 + B.11
          ref(
            ifOrElse(
              onlyPositive(
                sumAllowEmpty(
                  id('K6.fields.B_10_vinst_eller_forlust_x_andel_salt'),
                  id('K6.fields.B_11_aterforing_av_uppskovsbelopp')
                )
              ),
              sumAllowEmpty(
                id('K6.fields.B_10_vinst_eller_forlust_x_andel_salt'),
                id('K6.fields.B_11_aterforing_av_uppskovsbelopp')
              ),
              '0'
            )
          ),
        B_13_forlust_for_over_beloppet:
          // If B.10 + B.11 is negative then B.13 = B.10 + B.11
          ref(
            ifOrElse(
              onlyNegative(
                sumAllowEmpty(
                  id('K6.fields.B_10_vinst_eller_forlust_x_andel_salt'),
                  id('K6.fields.B_11_aterforing_av_uppskovsbelopp')
                )
              ),
              absolute(
                sumAllowEmpty(
                  id('K6.fields.B_10_vinst_eller_forlust_x_andel_salt'),
                  id('K6.fields.B_11_aterforing_av_uppskovsbelopp')
                )
              ),
              '0'
            )
          ),
        B_14_avdrag_for_preliminart_uppskovsbelopp: ref(
          ifOrElse(
            id('K6.help.postponementAmount.2.value'),
            id('K6.help.postponementAmount.4.value'),
            undefined
          )
        ),
        B_15_avdrag_for_slutligt_uppskovsbelopp: ref(
          ifOrElse(
            id('K6.help.postponementAmount.3.value'),
            id('K6.help.postponementAmount.4.value'),
            undefined
          )
        ),
        B_16_aterstaende_vinst_efter_avdrag_for_uppskovsbelopp:
          // If value in B.12 and in B.14 then B.16 = B.12 - B.14
          // If value in B.12 and in B.15 then B.16 = B.12 - B.15
          // If no value in B.12 then B.16 = undefined
          ref(
            ifOrElse(
              id('K6.fields.B_12_vinst_for_over_beloppet'),
              ifOrElse(
                id('K6.fields.B_14_avdrag_for_preliminart_uppskovsbelopp'),
                sumAllowEmpty(
                  id('K6.fields.B_12_vinst_for_over_beloppet'),
                  negative(
                    id('K6.fields.B_14_avdrag_for_preliminart_uppskovsbelopp')
                  )
                ),
                sumAllowEmpty(
                  id('K6.fields.B_12_vinst_for_over_beloppet'),
                  negative(
                    id('K6.fields.B_15_avdrag_for_slutligt_uppskovsbelopp')
                  )
                )
              ),
              undefined
            )
          ),
        B_begar_preliminart_uppskov: value(false),
        C_bosatt_i_bostadsratten_fr_o_m: value(''),
        C_bosatt_i_bostadsratten_t_o_m: value(''),
        C_bosatt_under_minst_ett_ar: value(false),
        C_bosatt_sammanlagt_minst_tre_ar: value(false),
        C_forvarvat_nya_bostad_fore_forsaljningen: value(false),
        C_bostadsratten_sald_tvangsforsaljning: value(false),
        D_agd_procent_x_3000000: ref(
          multiplyPercentage(3000000, id('K6.fields.D_agd_andel_procent'))
        ),
        D_agd_andel_procent: ref(id('K6.fields.B_andel_av_bostadsratten')),
        E_fastighetsbeteckning: value(''),
        E_inkopsdatum_enligt_kopekontrakt: value(''),
        E_datum_for_bosattning: value(''),
        E_1_inkopspris_lagfartskostnad: value(undefined),
        E_1_andel_i_procent: ref(id('K6.fields.B_andel_av_bostadsratten')),
        E_2_utgift_for_ny_eller_ombyggnad: value(undefined),
        E_3_totalt_inkopspris: is2022
          ? ref(
              sumAllowEmpty(
                id('K6.fields.E_1_inkopspris_lagfartskostnad'),
                id('K6.fields.E_2_utgift_for_ny_eller_ombyggnad')
              )
            )
          : value(undefined),
        E_4_andel_i_procent: ref(id('K6.fields.B_andel_av_bostadsratten')),
        E_4_andel_i_kronor: is2022
          ? value(undefined)
          : ref(
              multiplyPercentage(
                id('K6.fields.E_3_totalt_inkopspris'),
                id('K6.fields.E_4_andel_i_procent')
              )
            ),
        F_bostadsrattsforeningens_namn: value(''),
        F_bostadsrattsforeningens_organisationsnummer: value(''),
        F_lagenhetsbeteckning: value(''),
        F_inkopsdatum_enligt_kopekontrakt: value(''),
        F_datum_for_bosattning: value(''),
        F_1_inkopspris_minus_inre_reparationsfond: value(undefined),
        F_1_andel_i_procent: ref(id('K6.fields.B_andel_av_bostadsratten')),
        F_2_andel_i_procent: ref(id('K6.fields.B_andel_av_bostadsratten')),
        F_2_andel_i_kronor: is2022
          ? value(undefined)
          : ref(
              multiplyPercentage(
                id('K6.fields.F_1_inkopspris_minus_inre_reparationsfond'),
                id('K6.fields.F_2_andel_i_procent')
              )
            ),
        G_vad_utlandska_boendeformen_heter: value(''),
        G_land: value(''),
        G_bostadens_adress: value(''),
        G_bostadens_postadress: value(''),
        H_vinst: ref(
          ifOrElse(
            id('K6.fields.B_12_vinst_for_over_beloppet'),
            id('K6.fields.B_12_vinst_for_over_beloppet'),
            undefined
          )
        ),
        H_inkopspris:
          // For 2022: Value from E.3 (if there is) or F.1 pris * (F.1 % / 100) (otherwise)
          is2022
            ? ref(
                ifOrElse(
                  id('K6.fields.E_3_totalt_inkopspris'),
                  multiplyPercentage(
                    id('K6.fields.E_3_totalt_inkopspris'),
                    id('K6.fields.E_1_andel_i_procent')
                  ),
                  multiplyPercentage(
                    id('K6.fields.F_1_inkopspris_minus_inre_reparationsfond'),
                    id('K6.fields.F_1_andel_i_procent')
                  )
                )
              )
            : // For 2021: Value from E.4 (if there is) or F.2 (otherwise)
              ref(
                ifOrElse(
                  id('K6.fields.E_4_andel_i_kronor'),
                  id('K6.fields.E_4_andel_i_kronor'),
                  id('K6.fields.F_2_andel_i_kronor')
                )
              ),
        H_forsaljningspris: value(undefined),
        H_vinst_x_inkopspris_forsaljningspris: ref(
          div(
            multiply(id('K6.fields.H_vinst'), id('K6.fields.H_inkopspris')),
            id('K6.fields.H_forsaljningspris')
          )
        ),
      },
      derivedFields: {
        B_vinst: ref(
          ifOrElse(
            id(
              'K6.fields.B_16_aterstaende_vinst_efter_avdrag_for_uppskovsbelopp'
            ),
            id(
              'K6.fields.B_16_aterstaende_vinst_efter_avdrag_for_uppskovsbelopp'
            ),
            id('K6.fields.B_12_vinst_for_over_beloppet')
          )
        ),
        B_forlust: ref(id('K6.fields.B_13_forlust_for_over_beloppet')),
      },
      sru: {
        '7014': ref(id('K6.fields.numreringFleraK6')),
        '3700': ref(id('K6.fields.A_bostadsrattsforeningens_namn')),
        '3736': ref(
          id('K6.fields.A_bostadsrattsforeningens_organisationsnummer')
        ),
        '3713': ref(
          id('K6.fields.A_lagenhetsbeteckning_hos_bostadsrattsforeningen')
        ),
        '3702': ref(id('K6.fields.A_forsaljningsdatum_enligt_kopekontrakt')),
        '3701': ref(id('K6.fields.A_inkopsdatum_enligt_kopekontrakt')),
        '3707': ref(id('K6.fields.A_hela_innehavstiden')),
        '3619': ref(id('K6.fields.A_del_av_innehavstiden')),
        '3709': ref(id('K6.fields.A_fr_o_m')),
        '3710': ref(id('K6.fields.A_t_o_m')),
        '3718': ref(id('K6.fields.A_andel_i_procent')),
        '3715': ref(id('K6.fields.B_gemensam_for_flera_delagare')),
        '3714': ref(id('K6.fields.B_individuell_for_flera_delagare')),
        '3729': ref(id('K6.fields.B_andel_av_bostadsratten')),
        '3711': ref(id('K6.fields.B_150_procent_av_bostadsrattens_andel')),
        '3720': ref(id('K6.fields.B_1_forsaljningspris_enligt_kopekontrakt')),
        '3721': ref(id('K6.fields.B_2_forsaljningsutgifter_maklararvode')),
        '3722': ref(id('K6.fields.B_3_inkopspris')),
        '3723': ref(
          id('K6.fields.B_4_forbattringsutgifter_grundforbattringar')
        ),
        '3724': ref(
          id('K6.fields.B_5_forbattringsutgifter_forbattrande_reparationer')
        ),
        '3725': ref(id('K6.fields.B_6_kapitaltillskott')),
        '3726': ref(
          id(
            'K6.fields.B_7_bostadsrattens_andel_reparationsfond_vid_forsaljningen'
          )
        ),
        '3727': ref(
          id('K6.fields.B_8_bostadsrattens_andel_reparationsfond_vid_kopet')
        ),
        '3728': ref(id('K6.fields.B_9_vinst_eller_forlust')),
        '3730': ref(id('K6.fields.B_10_vinst_eller_forlust_x_andel_salt')),
        '3731': ref(id('K6.fields.B_11_aterforing_av_uppskovsbelopp')),
        '3732': ref(id('K6.fields.B_12_vinst_for_over_beloppet')),
        '3733': ref(id('K6.fields.B_13_forlust_for_over_beloppet')),
        '3637': ref(id('K6.fields.B_14_avdrag_for_preliminart_uppskovsbelopp')),
        '3681': ref(id('K6.fields.B_15_avdrag_for_slutligt_uppskovsbelopp')),
        '3740': ref(
          id('K6.fields.B_16_aterstaende_vinst_efter_avdrag_for_uppskovsbelopp')
        ),
        '3801': ref(id('K6.fields.B_begar_preliminart_uppskov')),
        '3703': ref(id('K6.fields.C_bosatt_i_bostadsratten_fr_o_m')),
        '3704': ref(id('K6.fields.C_bosatt_i_bostadsratten_t_o_m')),
        '3716': ref(id('K6.fields.C_bosatt_under_minst_ett_ar')),
        '3717': ref(id('K6.fields.C_bosatt_sammanlagt_minst_tre_ar')),
        '3719': ref(id('K6.fields.C_forvarvat_nya_bostad_fore_forsaljningen')),
        '3737': ref(id('K6.fields.C_bostadsratten_sald_tvangsforsaljning')),
        '3633': ref(id('K6.fields.D_agd_andel_procent')),
        '3639': ref(id('K6.fields.D_agd_procent_x_3000000')),
        '3640': ref(id('K6.fields.E_fastighetsbeteckning')),
        '3641': ref(id('K6.fields.E_inkopsdatum_enligt_kopekontrakt')),
        '3642': ref(id('K6.fields.E_datum_for_bosattning')),
        '3643': ref(id('K6.fields.E_1_inkopspris_lagfartskostnad')),
        '3644': ref(id('K6.fields.E_2_utgift_for_ny_eller_ombyggnad')),

        ...(is2022
          ? undefined
          : {
              '3645': ref(id('K6.fields.E_3_totalt_inkopspris')),
            }),

        ...(is2022
          ? { '3646': ref(id('K6.fields.E_1_andel_i_procent')) }
          : { '3646': ref(id('K6.fields.E_4_andel_i_procent')) }),

        ...(is2022
          ? { '3647': ref(id('K6.fields.E_3_totalt_inkopspris')) }
          : { '3647': ref(id('K6.fields.E_4_andel_i_kronor')) }),

        '3650': ref(id('K6.fields.F_bostadsrattsforeningens_namn')),
        '3651': ref(
          id('K6.fields.F_bostadsrattsforeningens_organisationsnummer')
        ),
        '3652': ref(id('K6.fields.F_lagenhetsbeteckning')),
        '3653': ref(id('K6.fields.F_inkopsdatum_enligt_kopekontrakt')),
        '3654': ref(id('K6.fields.F_datum_for_bosattning')),

        ...(is2022
          ? undefined
          : {
              '3655': ref(
                id('K6.fields.F_1_inkopspris_minus_inre_reparationsfond')
              ),
            }),

        ...(is2022
          ? { '3656': ref(id('K6.fields.F_1_andel_i_procent')) }
          : { '3656': ref(id('K6.fields.F_2_andel_i_procent')) }),

        ...(is2022
          ? {
              '3657': ref(
                id('K6.fields.F_1_inkopspris_minus_inre_reparationsfond')
              ),
            }
          : { '3657': ref(id('K6.fields.F_2_andel_i_kronor')) }),

        '3662': ref(id('K6.fields.G_vad_utlandska_boendeformen_heter')),
        '3663': ref(id('K6.fields.G_land')),
        '3664': ref(id('K6.fields.G_bostadens_adress')),
        '3658': ref(id('K6.fields.G_bostadens_postadress')),
        '3696': ref(id('K6.fields.H_vinst_x_inkopspris_forsaljningspris')),
      },
      help: {
        _type: 'K6' as const,
        postponementHelper: table(
          'k6.help.postponementHelper',
          { id: 'label', label: '' },
          { id: 'value', label: '' }
        )
          .addRows((rows) =>
            rows
              .addRow(
                '1',
                label(
                  'Kommer en ny bostad köpas och önskar ni därmed preliminärt uppskov?'
                ),
                value(
                  is2022
                    ? !!ref(
                        or(
                          id(
                            'K6.fields.F_1_inkopspris_minus_inre_reparationsfond'
                          ),
                          id('K6.fields.E_3_totalt_inkopspris')
                        )
                      ).value
                    : !!ref(
                        or(
                          id('K6.fields.E_4_andel_i_kronor'),
                          id('K6.fields.F_2_andel_i_kronor')
                        )
                      ).value
                )
              )

              .addRow(
                '2',
                label(
                  'Vill du utnyttja möjligheten till slutligt uppskov maximalt? Ange “Nej” ifall du vill yrka på ett annat uppskovsbelopp.'
                ),
                value(true)
              )
              .build()
          )
          .build(),
        improvements: table(
          'K6.help.improvements',
          'description',
          'year',
          'amount',
          'sum'
        )
          .addRows((rows) =>
            rows.addRow('1', value(''), value(''), value(0), value(0)).build()
          )
          .newRowTemplate(value(''), value(''), value(0), value(0))
          .build(),

        renovation: table(
          'K6.help.renovation',
          'description',
          'year',
          'amount',
          'sum'
        )
          .addRows((rows) =>
            rows.addRow('1', value(''), value(''), value(0), value(0)).build()
          )
          .newRowTemplate(value(''), value(''), value(0), value(0))
          .build(),
        postponementAmount: table(
          'K6.help.postponementAmount',
          { id: 'label', label: '' },
          { id: 'value', label: '' }
        )
          .addRows((rows) =>
            rows
              .addRow('1', label('Inget uppskov'), {
                type: 'boolean',
                value: true,
              })
              .addRow('2', label('Preliminärt uppskov'), {
                type: 'boolean',
                value: false,
              })
              .addRow('3', label('Slutligt uppskov'), {
                type: 'boolean',
                value: false,
              })
              .addRow('4', label('Yrkat uppskovsbelopp om ej max'), value(0))
              .build()
          )
          .build(),
      },
    },
  };
  return {
    initial: toInitial(initial, form),
    definition: toStructure(initial, form, { K6: helpStructureK6 }),
  };
};

export default configK6;

import React, { useEffect, useState } from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  TextField as MuiTextField,
  TextFieldProps,
} from '@material-ui/core';
import styled from '@emotion/styled';

import { ACCOUNTANT_LABELS } from '@agoy/annual-report-view/src/types/enums';
import EditField from '_shared/components/Inputs/EditField';
import AddOptionMember from './AddOptionMember';

const TextField = styled(
  ({
    disableUnderline,
    ...rest
  }: TextFieldProps & { disableUnderline: boolean }) => (
    <MuiTextField variant="outlined" size="small" {...rest} />
  )
)`
  margin-left: 2rem;
`;

const NewOptionWrapper = styled.div`
  .MuiFormControl-root {
    display: flex;
    margin-left: 2rem;
  }
  .MuiOutlinedInput-adornedEnd {
    padding-right: 4px;
  }
`;

type AccountantTitleOption = {
  id: string;
  checked: boolean;
  label: string;
  freeText?: string;
};

type AccountantTitleSelectionProps = {
  titles: string;
  onChange: (changes: string) => void;
};

const VerticalGrid = styled.div`
  display: grid;
  grid-template-rows: auto auto;
`;

const AccountantTitleSelection = ({
  titles,
  onChange,
}: AccountantTitleSelectionProps) => {
  const [options, setOptions] = useState<AccountantTitleOption[]>([
    { id: 'option1', checked: false, label: ACCOUNTANT_LABELS.ACCOUNTANT },
    {
      id: 'option2',
      checked: false,
      label: ACCOUNTANT_LABELS.ACCOUNTANT_ALTERNATE,
    },
    { id: 'option3', checked: false, label: ACCOUNTANT_LABELS.AUTHORIZED },
    { id: 'option4', checked: false, label: ACCOUNTANT_LABELS.APPROVED },
    {
      id: 'option5',
      checked: false,
      label: ACCOUNTANT_LABELS.MAIN_ACCOUNTANT,
    },
    {
      id: 'option6',
      checked: false,
      label: ACCOUNTANT_LABELS.MAIN_ACCOUNTANT_IS_ACCOUNTANT_FIRM,
      freeText: '',
    },
    {
      id: 'option7',
      checked: false,
      label: ACCOUNTANT_LABELS.ACCREDITED_ACCOUNTANT,
    },
  ]);

  useEffect(() => {
    if (titles === '') return;

    let freeTextMainAccountantIsAccountingFirm;

    const roleLabels = titles.split('|');
    const option6 = roleLabels.find((value) =>
      value.includes(ACCOUNTANT_LABELS.MAIN_ACCOUNTANT_IS_ACCOUNTANT_FIRM)
    );

    if (option6 && option6 !== '') {
      freeTextMainAccountantIsAccountingFirm = option6
        .split(ACCOUNTANT_LABELS.MAIN_ACCOUNTANT_IS_ACCOUNTANT_FIRM)[1]
        .trim();
    }

    setOptions((prevOptions) => {
      const newOptions: AccountantTitleOption[] = [];
      roleLabels.forEach((newOptionLabel, index) => {
        const existingOption = prevOptions.find(
          (option) => option.label === newOptionLabel
        );

        if (!existingOption) {
          newOptions.push({
            id: `option${prevOptions.length + index}`,
            checked: true,
            label: newOptionLabel,
          });
        }
      });

      const allOptions = [...prevOptions, ...newOptions];
      return allOptions.map((option) => {
        if (
          option.label ===
            ACCOUNTANT_LABELS.MAIN_ACCOUNTANT_IS_ACCOUNTANT_FIRM &&
          freeTextMainAccountantIsAccountingFirm
        ) {
          return {
            ...option,
            checked: true,
            freeText: freeTextMainAccountantIsAccountingFirm,
          };
        }
        return roleLabels.includes(option.label)
          ? { ...option, checked: true }
          : option;
      });
    });
  }, [titles]);

  const collectChanges = (updatedOptions = options) => {
    return updatedOptions
      .filter((item) => item.checked)
      .map((item) => {
        if (
          item.label === ACCOUNTANT_LABELS.MAIN_ACCOUNTANT_IS_ACCOUNTANT_FIRM &&
          item.freeText
        ) {
          return `${item.label} ${item.freeText}`;
        }
        return item.label;
      })
      .join('|');
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const option = options.find((option) => option.id === event.target.name);
    if (!option) return;

    const updatedOptions = options.map((existingOption) =>
      existingOption.id === option.id
        ? { ...existingOption, checked: event.target.checked }
        : existingOption
    );
    setOptions(updatedOptions);

    const changes = collectChanges(updatedOptions);
    onChange(changes);
  };

  const onTextfieldChange = (value) => {
    const option = options.find(
      (option) =>
        option.label === ACCOUNTANT_LABELS.MAIN_ACCOUNTANT_IS_ACCOUNTANT_FIRM
    );
    if (option) {
      const updatedOptions = options.map((existingOption) =>
        existingOption.id === option.id
          ? { ...existingOption, freeText: value || '' }
          : existingOption
      );
      setOptions(updatedOptions);

      const changes = collectChanges(updatedOptions);
      onChange(changes);
    }
  };

  const handleAddOption = (optionLabel) => {
    if (optionLabel === '') {
      optionLabel = ' ';
    }

    setOptions([
      ...options,
      {
        id: `option${options.length + 1}`,
        checked: false,
        label: optionLabel,
      },
    ]);
  };

  return (
    <>
      <FormControl component="fieldset">
        <FormGroup>
          {options.map((option) => (
            <VerticalGrid key={option.id}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={option.checked}
                    onChange={handleCheckboxChange}
                    name={option.id}
                  />
                }
                label={option.label}
              />
              {option.label ===
                ACCOUNTANT_LABELS.MAIN_ACCOUNTANT_IS_ACCOUNTANT_FIRM && (
                <EditField
                  value={option.freeText as string}
                  component={TextField}
                  onChange={onTextfieldChange}
                />
              )}
            </VerticalGrid>
          ))}
        </FormGroup>
      </FormControl>
      <NewOptionWrapper>
        <AddOptionMember onAddOption={handleAddOption} />
      </NewOptionWrapper>
    </>
  );
};

export default AccountantTitleSelection;

import * as t from 'io-ts';
import { parseMessage } from './common';

const GenerateDocumentValuesMessageCodec = t.intersection([
  t.type({
    messageId: t.string,
    userId: t.string,
    clientId: t.string,
    documentId: t.string,
  }),
  t.partial({
    depth: t.number,
    providedValues: t.record(
      t.string,
      t.union([t.string, t.number, t.boolean, t.null])
    ),
  }),
]);

/**
 * Message format for starting the generation of document values.
 * Used by the generateDocument? lamdba.
 */
export type GenerateDocumentValuesMessage = t.TypeOf<
  typeof GenerateDocumentValuesMessageCodec
>;

/**
 * parseGenerateDocumentValuesMessage
 *
 * @param json message in string
 * @returns A result object with a valid message.
 */
export const parseGenerateDocumentValuesMessage = (json: string) =>
  parseMessage(GenerateDocumentValuesMessageCodec, json);

import cashFlowK3V2 from '../cash-flow-statement/v2/k3/cashflowstatementk3v2.json';
import cashFlowK2V2 from '../cash-flow-statement/v2/k2/cashflowstatementk2v2.json';
import functionalIncomeStatementK3v2 from '../income-statement/v2/k3/functionalincomestatementk3v2.json';
import costDividedIncomeStatementK2v2 from '../income-statement/v2/k2/costdividedincomestatementk2v2.json';
import costDividedIncomeStatementK3v2 from '../income-statement/v2/k3/costdividedincomestatementk3v2.json';
import balanceStatementK2v2 from '../balance-sheet/v2/k2/balancestatementk2v2.json';
import balanceStatementK3v2 from '../balance-sheet/v2/k3/balancestatementk3v2.json';
import { AnnualReportType, IncomeStatementType } from '../../document';

export type Documentation = {
  [key: string]: {
    tooltip?: string;
    standardrubrik?: string;
    accounts?: string;
  };
};

export const sharesCompanyDocumentation = (
  reportType: AnnualReportType,
  incomeStatementType?: IncomeStatementType
): Documentation => {
  switch (reportType) {
    case 'k2':
      return {
        ...cashFlowK2V2,
        ...balanceStatementK2v2,
        ...costDividedIncomeStatementK2v2,
      };
    case 'k3':
      return {
        ...cashFlowK3V2,
        ...(incomeStatementType === 'functional'
          ? functionalIncomeStatementK3v2
          : costDividedIncomeStatementK3v2),
        ...balanceStatementK3v2,
      };
    default:
      return {};
  }
};

import { label, value } from '@agoy/document';
import {
  CustomNotes,
  Note84Section,
  NoteInputFieldSection,
  NoteOneTableSection,
  NoteOneTableWithSum,
  NoteTwoTableSection,
} from '../../../../common';
import { AnnualReportType } from '../../../../document';

import { getNote35 } from '../Notes/note35';
import { getNote36 } from '../Notes/note36';
import { getNote37 } from '../Notes/note37';
import { getNote38 } from '../Notes/note38';
import { getNote39 } from '../Notes/note39';
import { getNote67 } from '../Notes/note67';
import { getNote68 } from '../Notes/note68';
import { getNote80 } from '../k3-notes/note80';
import { getNote84 } from '../k3-notes/note84';
import { getSharesCompanyCustomNote } from '../Notes/customNote';
import { getNote86 } from '../k3-notes/note86';
import { getNote88 } from '../k3-notes/note88';

export interface OtherNotes {
  note35: NoteInputFieldSection;
  note36: NoteInputFieldSection;
  note37: NoteOneTableSection;
  note38: NoteOneTableSection;
  note39: NoteOneTableSection;
  note67: NoteInputFieldSection;
  note68: NoteOneTableSection;
  note80: NoteTwoTableSection;
  note84: Note84Section;
  note86: NoteOneTableWithSum;
  note88: NoteOneTableSection;
  custom: CustomNotes;
}

export const getSharesCompanyOtherNotes = (
  reportType: AnnualReportType,
  financialYears: string[],
  financialYear: string,
  period: string | null,
  isDigitalSubmission: boolean,
  documentTypeVersion = '1'
): OtherNotes => {
  const noteLabel = (name: string) =>
    isDigitalSubmission ? label(name) : value(name);

  return {
    note35: getNote35(),
    note36: getNote36(),
    note37: getNote37(noteLabel, documentTypeVersion),
    note38: getNote38(noteLabel, documentTypeVersion),
    note39: getNote39(noteLabel, documentTypeVersion),
    note67: getNote67(),
    note68: getNote68(documentTypeVersion),
    note80: getNote80(financialYear, financialYears, period),
    note84: getNote84(noteLabel, financialYear, financialYears, period),
    note86: getNote86(noteLabel, financialYear, financialYears, period),
    note88: getNote88(documentTypeVersion),
    custom: getSharesCompanyCustomNote(financialYear, financialYears, period),
  };
};

import React, { memo, useCallback, useState } from 'react';
import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';
import { Checkbox, Tooltip } from '@material-ui/core';
import Check from '@material-ui/icons/Check';
import Report from '@material-ui/icons/Report';
import NotInterested from '@material-ui/icons/NotInterested';
import { useIntl } from 'react-intl';
import { ReconciliationState } from '@agoy/api-sdk-core';

const CheckboxWrapper = styled('div', { shouldForwardProp: isPropValid })<{
  alwaysVisible: boolean;
}>`
  display: ${(props) => (props.alwaysVisible ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  z-index: 2;
  margin-left: 2px;
  font-size: 19px;
  width: 24px;
`;

const Wrapper = styled.div`
  display: flex;
`;

const CorrectCheckbox = styled(Checkbox)`
  padding: 2px;

  color: ${({ theme, checked }) =>
    checked ? '#007031' : theme.palette.grey[500]};

  &:hover {
    color: ${({ theme, checked }) =>
      checked ? '#003621' : theme.palette.grey[900]};
  }
`;

const ReportIcon = styled(Report)`
  color: #930e00;
`;

const NotInterestedIcon = styled(NotInterested)`
  color: #930e00;
`;

const CheckIcon = styled(Check)`
  color: #007031;
`;

type SaldoCheckboxProps = {
  state: ReconciliationState;
  hasSpecifications: boolean;
  isGroup?: boolean;
  visible?: boolean;
  disabled?: boolean;
  dataCy?: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
};

const SaldoCheckbox = ({
  state,
  hasSpecifications,
  isGroup = false,
  visible = false,
  disabled,
  dataCy,
  onClick,
}: SaldoCheckboxProps) => {
  const { formatMessage } = useIntl();

  const [isCheckboxHover, setCheckboxHover] = useState(false);

  const handleHoverOn = useCallback(() => {
    setCheckboxHover(true);
  }, []);

  const handleHoverOut = useCallback(() => {
    setCheckboxHover(false);
  }, []);

  let tooltipTitleId = '';
  let alwaysVisible = visible;
  let iconComponent = <></>;

  if (hasSpecifications) {
    if (state === 'error') {
      tooltipTitleId = 'reconciliation.account.checkbox.disabledWithError';
      iconComponent = <NotInterestedIcon fontSize="inherit" />;
    } else if (state === 'checked') {
      tooltipTitleId = 'reconciliation.account.checkbox.activeVerified';
      iconComponent = <CheckIcon fontSize="inherit" />;
      alwaysVisible = true;
    } else {
      tooltipTitleId = 'reconciliation.account.checkbox.disabled';
      iconComponent = (
        <CorrectCheckbox
          size="small"
          color="default"
          checked={!isGroup || state === 'done'}
          disabled={!isGroup || disabled}
          onClick={onClick}
        />
      );
    }
  } else if (state === 'error' && !isCheckboxHover) {
    tooltipTitleId = 'reconciliation.account.checkbox.notActiveWithError';
    iconComponent = <ReportIcon fontSize="inherit" />;
  } else if (state === 'checked') {
    tooltipTitleId = 'reconciliation.account.checkbox.activeVerified';
    iconComponent = <CheckIcon fontSize="inherit" />;
    alwaysVisible = true;
  } else {
    if (state === 'done') {
      tooltipTitleId = 'reconciliation.account.checkbox.active';
    } else if (state === 'error') {
      tooltipTitleId = 'reconciliation.account.checkbox.notActiveWithError';
    } else {
      tooltipTitleId = 'reconciliation.account.checkbox.notActive';
    }

    iconComponent = (
      <CorrectCheckbox
        checked={state === 'done'}
        size="small"
        onClick={onClick}
        color="default"
        disabled={disabled}
      />
    );
  }

  return (
    <Tooltip
      title={formatMessage({
        id: tooltipTitleId,
      })}
    >
      <Wrapper>
        <CheckboxWrapper
          alwaysVisible={alwaysVisible}
          className="correctCheckbox"
          data-cy={dataCy}
          onMouseEnter={handleHoverOn}
          onMouseLeave={handleHoverOut}
        >
          {iconComponent}
        </CheckboxWrapper>
      </Wrapper>
    </Tooltip>
  );
};

export default memo(SaldoCheckbox);

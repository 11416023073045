import React, { useState, useEffect } from 'react';
import {
  Tooltip,
  FormControlLabel,
  FormGroup,
  Checkbox,
} from '@material-ui/core';
import { useIntl } from 'react-intl';

type SharpPDFTogglerProps = {
  checked: boolean;
  onChange: () => void;
};

const SharpPDFToggler = ({
  checked,
  onChange,
}: SharpPDFTogglerProps): JSX.Element | null => {
  const { formatMessage } = useIntl();

  const handleChange = () => {
    onChange();
  };

  return (
    <>
      <Tooltip
        title={formatMessage({
          id: 'print.periodSpecification.sharpenPDF.tooltip',
        })}
      >
        <FormGroup>
          <FormControlLabel
            control={<Checkbox checked={checked} onChange={handleChange} />}
            label={formatMessage({
              id: 'print.periodSpecification.sharpenPDF',
            })}
          />
        </FormGroup>
      </Tooltip>
    </>
  );
};

export default SharpPDFToggler;

export const TOGGLE_LAMPS_EXPANDED = 'customers-overview/TOGGLE_LAMPS_EXPANDED';
export const TOGGLE_MINIMIZED_CARDS =
  'customers-overview/TOGGLE_MINIMIZED_CARDS';
export const SET_SORT_TYPE = 'customers-overview/SET_SORT_TYPE';
export const SET_SORT_SEARCH = 'customers-overview/SET_SORT_SEARCH';
export const TOGGLE_HIDE_CLEAN_CLIENTS =
  'customers-overview/TOGGLE_HIDE_CLEAN_CLIENTS';
export const SET_FILTERS = 'customers-overview/SET_FILTERS';
export const SET_CLOSING_PERIOD_FILTER =
  'customers-overview/SET_CLOSING_PERIOD_FILTER';
export const SET_ACTIVE_TAGS = 'customers-overview/SET_ACTIVE_TAGS';
export const SET_ACTIVE_ASSIGNEES = 'customers-overview/SET_ACTIVE_ASSIGNEES';
export const SET_COMPANY_TYPE_FILTER =
  'customers-overview/SET_COMPANY_TYPE_FILTER';

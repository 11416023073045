import React, { Suspense, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  Paper,
  Tab,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Avatar,
  FormControl,
  InputAdornment,
  TextField,
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import Skeleton from '@material-ui/lab/Skeleton';
import styled from '@emotion/styled';
import get from 'lodash-es/get';

import config from '_shared/services/config';
import { CardTabs } from '_organization/components/organisms/CardTabs';
import CardTabPanel from '_organization/components/organisms/CardTabPanel';

const isFortnoxWhiteLabel = config.whiteLabelUI === 'fortnox';

const SearchField = styled(FormControl)`
  width: 100%;
`;

const MemberListType = styled.div`
  text-align: right;
  color: ${(props) => props.theme.palette.text.secondary};
`;

export const filterMembers = (
  members: Member.MemberType[],
  name: string
): Member.MemberType[] => {
  const lowerCaseName = name.toLocaleLowerCase();
  return members.filter((member) => {
    // Using get() to avoid 'undefined undefined' if member is empty
    const fullName = `${get(member, 'givenName', '')} ${get(
      member,
      'familyName',
      ''
    )}`;
    const includeSupportRole = member.roles?.includes('Support');
    return (
      fullName.toLowerCase().includes(lowerCaseName) && !includeSupportRole
    );
  });
};

const getRoleText = (member: Member.MemberType) => {
  const adminText =
    config.whiteLabelUI === 'fortnox'
      ? 'dashboard.members.types.admin.fortnox'
      : 'dashboard.members.types.admin';
  return member.roles?.includes('OrganisationAdmin')
    ? adminText
    : 'dashboard.members.types.accountingContractor';
};

type Props = {
  isAdmin: boolean;
  isLoading: boolean;
  orgMembers: Member.MemberType[];
  handleSelectMember: (userId: string) => void;
  MemberInvitations: React.ReactNode;
};

const MembersList = ({
  isAdmin,
  isLoading,
  orgMembers,
  handleSelectMember,
  MemberInvitations,
}: Props) => {
  const intl = useIntl();

  const [selectedTabuserId, setSelectedTabuserId] = useState(0);
  const [searchString, setSearchString] = useState<string>('');
  const members = filterMembers(orgMembers, searchString);
  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTabuserId(newValue);
  };

  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const search: string = event.target.value.trim();
    setSearchString(search);
  };

  const renderMemberList = () => {
    if (members.length === 0) {
      if (searchString) {
        return (
          <ListItem>
            <ListItemText>
              {intl.formatMessage({ id: 'dashboard.members.noSearchResults' })}
            </ListItemText>
          </ListItem>
        );
      }

      return (
        <Skeleton
          variant="rect"
          animation="wave"
          width="100%"
          height="40px"
          component="li"
        />
      );
    }
    return isLoading ? (
      <Skeleton
        variant="rect"
        animation="wave"
        width="100%"
        height="40px"
        component="li"
      />
    ) : (
      members.map((member) => (
        <React.Fragment key={member.email}>
          <ListItem
            button
            onClick={() => {
              handleSelectMember(member.userId);
            }}
          >
            <ListItemIcon>
              <Avatar src={member.picture || undefined} />
            </ListItemIcon>
            <ListItemText>
              {`${member.givenName} ${member.familyName}`}
            </ListItemText>
            <ListItemText
              primary={
                <MemberListType>
                  {intl.formatMessage({
                    id: getRoleText(member),
                  })}
                </MemberListType>
              }
            />
          </ListItem>
        </React.Fragment>
      ))
    );
  };

  return (
    <>
      <Paper elevation={2}>
        <CardTabs
          value={selectedTabuserId}
          onChange={handleTabChange}
          indicatorColor="secondary"
          textColor="secondary"
        >
          <Tab
            label={intl.formatMessage({ id: 'dashboard.members.members' })}
          />
          {isAdmin && !isFortnoxWhiteLabel && (
            <Tab
              label={intl.formatMessage({ id: 'dashboard.members.invite' })}
            />
          )}
        </CardTabs>
        <CardTabPanel name="members" value={selectedTabuserId} index={0}>
          <SearchField>
            <TextField
              id="search-members"
              variant="outlined"
              size="small"
              placeholder={intl.formatMessage({
                id: 'dashboard.members.searchPlaceholder',
              })}
              fullWidth
              onChange={(ev) => {
                handleSearchInputChange(ev);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </SearchField>
          <List>{renderMemberList()}</List>
        </CardTabPanel>
        <CardTabPanel name="members" value={selectedTabuserId} index={1}>
          {isAdmin && !isFortnoxWhiteLabel && (
            <Suspense
              fallback={
                <Skeleton
                  variant="rect"
                  animation="wave"
                  width="100%"
                  height="40px"
                  component="div"
                />
              }
            >
              {MemberInvitations}
            </Suspense>
          )}
        </CardTabPanel>
      </Paper>
    </>
  );
};

export default MembersList;

import React from 'react';
import styled from '@emotion/styled';
import { formatRolesByComma } from '../../../../utils/formatRoles';
import { IXElement } from '../../../../ARKeyToIX';
import { IxbrlCell } from '@agoy/annual-report-document';
import { Tuple } from '../../../../xml/IX';
import { Accountant } from './AccountantTitlesPreview';
import { stringValue } from '@agoy/document';
import Typography from '../../../../UI/Typography/Typography';
import Spacer from '../../../../UI/helpers/Spacer';
import { useIntl } from 'react-intl';

const GridItem = styled.div`
  min-width: 300px;
  display: inline-block;
  padding-left: 0px;
  margin: 0 ${({ theme }) => theme.spacing(0.5)}px;
  p {
    margin-bottom: ${({ theme }) => theme.spacing(1)}px;
  }
`;

const HiddenDiv = styled.div`
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
`;

const BlockContent = styled.div`
  display: block;
`;

type AccountantRowPreviewProps = {
  accountant: Accountant;
  isDigitalSubmission: boolean;
  isDigitalSigningWithFortnox: boolean;
  index: number;
  isDateSelected: boolean;
};

const returnTuple = (arPart, element: string, id: number): IxbrlCell => {
  let item;
  switch (element) {
    case 'givenName':
      item = '1.0';
      break;
    case 'surName':
      item = '2.0';
      break;
    case 'role':
      item = '3.0';
      return {
        ...arPart,
        ixbrl: {
          ...arPart.ixbrl,
          name: 'se-gen-base:UnderskriftHandlingTitel',
          tupleRef: {
            tupleRef: `UnderskriftRevisorspateckningTuple${id + 1}`,
            order: item,
          },
        },
      };
    default:
      console.error('Error in element for returnTuple(): ', element);
  }

  return {
    ...arPart,
    ixbrl: {
      ...arPart.ixbrl,
      tupleRef: {
        tupleRef: `UnderskriftRevisorspateckningTuple${id + 1}`,
        order: item,
      },
    },
  };
};

const returnMainAccountant = (arPart, id: number): IxbrlCell => {
  return {
    ...arPart,
    ixbrl: {
      ...arPart.ixbrl,
      type: 'booleanItemType',
      name: 'se-gen-base:UnderskriftRevisorspateckningRevisorHuvudansvarig',
      tupleRef: {
        tupleRef: `UnderskriftRevisorspateckningTuple${id + 1}`,
        order: '4.0',
      },
    },
  };
};

const AccountantTitlesRowPreview = ({
  accountant,
  isDigitalSubmission,
  isDigitalSigningWithFortnox,
  index,
  isDateSelected,
}: AccountantRowPreviewProps) => {
  const { formatMessage } = useIntl();
  const getDate = () => {
    return isDateSelected && !!accountant.date ? accountant.date : '';
  };
  const renderDigitalSubmission = () => {
    return (
      <GridItem key={index}>
        {!isDigitalSigningWithFortnox && (
          <BlockContent>
            <Typography textStyle="italic" margin="none">
              {`${stringValue(accountant.givenName)} ${stringValue(
                accountant.surName
              )}`}
            </Typography>
          </BlockContent>
        )}
        <Tuple
          name="se-gaap-ext:UnderskriftRevisorspateckningTuple"
          tupleID={`UnderskriftRevisorspateckningTuple${index + 1}`}
        />
        <Typography margin="none">
          <IXElement
            arPart={returnTuple(accountant.givenName, 'givenName', index)}
          >
            {stringValue(accountant.givenName)}
          </IXElement>{' '}
          <IXElement arPart={returnTuple(accountant.surName, 'surName', index)}>
            {stringValue(accountant.surName)}
          </IXElement>
        </Typography>
        {/* <Typography variant="body1" margin="none">
          {isDigitalSigningWithFortnox &&
            !accountant.date &&
            formatMessage({
              id: 'annualReport.signatures.section.dateFromSignature.label',
            })}
          {getDate()}
        </Typography> */}
        {accountant.roles && (
          <div>
            <Typography variant="body2" margin="none" textStyle="bold">
              <IXElement arPart={returnTuple(accountant.roles, 'role', index)}>
                {formatRolesByComma(stringValue(accountant.roles)) ?? ''}
              </IXElement>
            </Typography>
          </div>
        )}
        {accountant.mainAccountant ? (
          <IXElement
            arPart={returnMainAccountant(accountant.mainAccountant, index)}
          >
            {/* The HiddenDiv tag was added to hide this true value that is used for validation */}
            <HiddenDiv>true</HiddenDiv>
          </IXElement>
        ) : (
          <></>
        )}
      </GridItem>
    );
  };
  const renderManualSubmission = () => {
    return (
      <GridItem key={`${accountant.givenName} ${accountant.surName}`}>
        <Spacer size={5} />
        <Typography variant="body1">
          {stringValue(accountant.givenName)} {stringValue(accountant.surName)}
        </Typography>
        {/* <Typography variant="body1">
          {isDigitalSigningWithFortnox &&
            !accountant.date &&
            formatMessage({
              id: 'annualReport.signatures.section.dateFromSignature.label',
            })}
          {getDate()}
        </Typography> */}
        <Typography variant="body2" textStyle="bold">
          {formatRolesByComma(stringValue(accountant.roles))}
        </Typography>
      </GridItem>
    );
  };

  return (
    <>
      {isDigitalSubmission
        ? renderDigitalSubmission()
        : renderManualSubmission()}
    </>
  );
};

export default AccountantTitlesRowPreview;

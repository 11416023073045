import { createNewRow } from './createNewRow';
import { Cell } from '../Cell/types';
import {
  AgoyDocument,
  AgoyDocumentPart,
  AgoyDocumentStructure,
} from './document';
import {
  AgoyTable,
  AgoyTableColumn,
  AgoyTableRow,
  AgoyTableRowGenerator,
} from './table';
import {
  AgoyDocumentChanges,
  CellUpdate,
  ColumnChange,
  RowChange,
  TableChange,
} from './changes';
import { value } from '../Cell/config';
import { AgoyDocumentStructurePart } from '.';
import { traverseDocument } from './traverse';
import { isDefined, removeOptional } from '@agoy/common';
import { Field } from '../types/field';

/**
 * Inserts the new row at the correct place according to sortKey.
 * Assumes rows is already sorted with ascending with undefined last.
 *
 * Edits the array in-place
 */
export const insertRow = (rows: AgoyTableRow[], newRow: AgoyTableRow): void => {
  const newRowSortKey = newRow.sortKey;
  if (newRowSortKey !== undefined) {
    const index = rows.findIndex(
      (row) => row.sortKey === undefined || row.sortKey > newRowSortKey
    );
    if (index !== -1) {
      rows.splice(index, 0, newRow);
    } else {
      rows.push(newRow);
    }
  } else {
    rows.push(newRow);
  }
};

/**
 * Helper that gives you the rest properties of a cell,
 * pending the cell type
 *
 * @param cell
 * @returns Rest of cell properties
 */
export function restOfCell<T extends Cell>(cell: Cell): object {
  switch (cell.type) {
    case 'boolean':
    case 'label':
    case 'string':
    case 'number': {
      const { value, type, ...rest } = cell;
      return rest;
    }
    case 'msg': {
      const {
        value,
        message,
        type,
        parameterReferences,
        parameterValues,
        ...rest
      } = cell;
      return rest;
    }
    case 'ref': {
      const { reference, type, value, ...rest } = cell;
      return rest;
    }
    case 'refs': {
      const { references, type, values, ...rest } = cell;
      return rest;
    }
  }
}

export const mergeCells = (
  cells: Record<string, Cell> | undefined,
  update: Record<string, CellUpdate>
): Record<string, Cell> => {
  return Object.keys(update).reduce(
    (newCells: Record<string, Cell>, column: string): Record<string, Cell> => {
      const cellUpdate = update[column];

      if (cellUpdate) {
        const cell = newCells[column];
        let newCell: Cell;

        switch (cellUpdate.type) {
          case 'number':
            newCell = {
              original: cellUpdate.original,
              ...(cell && restOfCell(cell)),
              value: cellUpdate.value,
              type: cellUpdate.type,
              source: cellUpdate.source,
            };
            break;
          case 'string':
            newCell = {
              original: cellUpdate.original,
              ...(cell && restOfCell(cell)),
              value: cellUpdate.value,
              type: cellUpdate.type,
              ...(cellUpdate.source ? { source: cellUpdate.source } : {}),
            };
            break;
          case 'boolean':
            newCell = {
              original: cellUpdate.original,
              ...(cell && restOfCell(cell)),
              value: cellUpdate.value,
              type: cellUpdate.type,
              ...(cellUpdate.source ? { source: cellUpdate.source } : {}),
            };
            break;
          case 'ref':
            newCell = {
              original: cellUpdate.original,
              ...(cell && restOfCell(cell)),
              type: 'ref',
              reference: cellUpdate.reference,
              value: { error: 'notResolved' },
              ...(cellUpdate.source ? { source: cellUpdate.source } : {}),
            };
            break;
          case 'refs':
            newCell = {
              original: cellUpdate.original,
              ...(cell && restOfCell(cell)),
              type: 'refs',
              references: cellUpdate.references,
              values: cellUpdate.references.map((_) => ({
                error: 'notResolved',
              })),
              ...(cellUpdate.source ? { source: cellUpdate.source } : {}),
            };
            break;
          default:
            return newCells;
        }

        return {
          ...newCells,
          [column]: removeOptional(newCell, 'source', 'original'),
        };
      }
      return newCells;
    },
    cells || {}
  );
};

const applyRowsChanges = (
  baseId: string,
  newRowTemplate: AgoyTableRow | AgoyTableRowGenerator | undefined,
  rows: AgoyTableRow[],
  changes: RowChange[]
): AgoyTableRow[] => {
  return changes.reduce(
    (rows, change) => {
      if (change.type === 'delete') {
        return rows.filter((row) => row.id !== change.id);
      }
      if (change.type === 'update') {
        const index = rows.findIndex((row) => row.id === change.id);
        if (index !== -1) {
          const row = rows[index];
          const newRow = {
            ...row,
            active:
              change.row?.active !== undefined ? change.row.active : row.active,
          };
          if (change.rows || row.rows) {
            newRow.rows =
              change.rows && row.rows
                ? applyRowsChanges(
                    `${baseId}.${change.id}`,
                    row.newRowTemplate,
                    row.rows,
                    change.rows
                  ) // TODO Check
                : row.rows;
          }
          if (change.row?.cells || row.cells) {
            newRow.cells = change.row?.cells
              ? mergeCells(row.cells, change.row.cells)
              : row.cells;
          }

          if (typeof change.row?.sortKey === 'number') {
            newRow.sortKey = change.row.sortKey;
          }

          rows[index] = newRow;
        }
      }
      if (change.type === 'add') {
        if (newRowTemplate) {
          const newRow = createNewRow(
            newRowTemplate,
            baseId,
            change.id,
            change.params
          );

          if (change.rows) {
            const row: AgoyTableRow = {
              ...newRow,
              ...change.row,
              rows: applyRowsChanges(
                `${baseId}.${change.id}`,
                newRow?.newRowTemplate,
                (change.row || newRow).rows || [],
                change.rows
              ),
            };
            if (newRow?.newRowTemplate) {
              row.newRowTemplate = newRow.newRowTemplate;
            }
            insertRow(rows, row);
          } else {
            insertRow(rows, change.row || newRow);
          }
        }
      }
      return rows;
    },
    [...rows]
  );
};

const applyColumnChanges = (
  config: AgoyTableColumn[],
  changes: ColumnChange[]
): AgoyTableColumn[] => {
  return changes.reduce((result, change) => {
    if (change.type === 'add') {
      const newColumn: AgoyTableColumn = { id: change.id };
      if (change.label !== undefined) {
        newColumn.label = change.label;
      }
      if (change.active !== undefined) {
        newColumn.active = change.active;
      }
      if (typeof change.sortKey === 'number') {
        newColumn.sortKey = change.sortKey;
      }
      return [
        ...result.slice(0, change.index),
        newColumn,
        ...result.slice(change.index),
      ];
    }
    if (change.type === 'delete') {
      return result.filter((col) => col.id !== change.id);
    }
    if (change.type === 'update') {
      return result.map((col) => {
        if (col.id === change.id) {
          const updatedColumn = { ...col };
          if (change.label) {
            updatedColumn.label = change.label;
          }
          if (change.active !== undefined) {
            updatedColumn.active = change.active;
          }
          if (typeof change.sortKey === 'number') {
            updatedColumn.sortKey = change.sortKey;
          }
          return updatedColumn;
        }
        return col;
      });
    }
    return result;
  }, config);
};

const applyColumnChangesToRow = (
  id: string,
  table: AgoyTable,
  row: AgoyTableRow,
  changes: ColumnChange[]
): AgoyTableRow => {
  return changes.reduce((result, change) => {
    let newRow = result;
    if (newRow.cells) {
      if (change.type === 'add') {
        if (table.newColumnTemplate?.generator) {
          const newCell = table.newColumnTemplate.generator(
            change.id,
            row.id,
            id,
            {}
          );
          if (newCell) {
            newRow = {
              ...newRow,
              cells: {
                ...newRow.cells,
                [change.id]: { ...newCell, ...newRow.cells[change.id] },
              },
            };
          }
        } else if (!(change.id in newRow.cells)) {
          newRow = {
            ...newRow,
            cells: {
              ...newRow.cells,
              [change.id]: change.cellType === 'string' ? value('') : value(0),
            },
          };
        }
      } else if (change.type === 'delete') {
        const newCells = { ...newRow.cells };
        delete newCells[change.id];
        newRow = { ...newRow, cells: newCells };
      }
    }
    if (newRow.rows) {
      newRow = {
        ...newRow,
        rows: applyColumnChangesToRows(id, table, newRow.rows, changes),
      };
    }
    if (typeof newRow.newRowTemplate === 'object') {
      newRow = {
        ...newRow,
        newRowTemplate: applyColumnChangesToRow(
          id,
          table,
          newRow.newRowTemplate,
          changes
        ),
      };
    }
    return newRow;
  }, row);
};

const applyColumnChangesToRows = (
  id: string,
  table: AgoyTable,
  rows: AgoyTableRow[],
  changes: ColumnChange[]
): AgoyTableRow[] => {
  return rows.map((row) =>
    applyColumnChangesToRow(`${id}.${row.id}`, table, row, changes)
  );
};

const applyTableChanges = (
  tableId: string,
  config: AgoyTable,
  change: TableChange
): AgoyTable => {
  let result = config;
  if (change.columns) {
    result = {
      ...result,
      columns: applyColumnChanges(result.columns, change.columns),
    };
  }
  if (change.rows && result.rows) {
    result = {
      ...result,
      rows: applyRowsChanges(
        tableId,
        config.newRowTemplate,
        result.rows,
        change.rows
      ),
    };
  }
  if (change.columns) {
    result = {
      ...result,
      rows: applyColumnChangesToRows(
        tableId,
        config,
        result.rows,
        change.columns
      ),
    };
    if (typeof result.newRowTemplate === 'object') {
      result = {
        ...result,
        newRowTemplate: applyColumnChangesToRow(
          tableId,
          config,
          result.newRowTemplate,
          change.columns
        ),
      };
    }
  }
  if ('active' in change) {
    result = {
      ...result,
      active: !!change.active,
    };
  }
  if ('source' in change) {
    result = {
      ...result,
      source: change.source,
    };
  }
  return result;
};

/**
 * Returns the first argument that is not undefined.
 * If all are undefined then it returns undefined.
 *
 * @param args
 * @returns
 */
const firstDefined = <T>(...args: (T | undefined)[]): T | undefined =>
  args.find(isDefined);

export type ApplyChanges<
  T extends AgoyDocumentStructure,
  D extends AgoyDocument<T> = AgoyDocument<T>
> = (config: D, changes: AgoyDocumentChanges<T>) => D;

const applyChanges = <
  T extends AgoyDocumentStructure,
  U extends AgoyDocument<T>
>(
  structure: T
): ApplyChanges<T, U> => {
  return <D extends AgoyDocument<T>>(
    document: D,
    changes: AgoyDocumentChanges<T>
  ) => {
    const result = traverseDocument(null, structure, document, changes, {
      // we don't have any changes in the part for now
      // ex: settings and balance sheet of AR
      part: (key, id, props) => {
        return props;
      },
      field: (key, id, props) => {
        const { changes } = props;

        if (changes) {
          // disregard active property of node in the case of storing the original node before change
          const { active, ...nodeWithoutActive } = props.node;
          const originalNode = props.node.original || nodeWithoutActive;

          switch (changes.type) {
            case 'number': {
              const newNode: Field = {
                ...restOfCell(props.node),
                original: originalNode,
                type: changes.type,
                value: changes.value,
                source: changes.source,
                active: firstDefined(changes.active, active),
              };
              return {
                ...props,
                node: removeOptional(newNode, 'source', 'active'),
              };
            }
            case 'string': {
              const newNode: Field = {
                ...restOfCell(props.node),
                original: originalNode,
                type: changes.type,
                value: changes.value,
                source: changes.source,
                active: firstDefined(changes.active, active),
              };
              return {
                ...props,
                node: removeOptional(newNode, 'source', 'active'),
              };
            }
            case 'boolean': {
              const newNode: Field = {
                ...restOfCell(props.node),
                original: originalNode,
                type: changes.type,
                value: changes.value,
                source: changes.source,
                active: firstDefined(changes.active, active),
              };
              return {
                ...props,
                node: removeOptional(newNode, 'source', 'active'),
              };
            }
            case 'ref': {
              const newNode: Field = {
                ...restOfCell(props.node),
                original: originalNode,
                value: { error: 'notResolved' },
                reference: changes.reference,
                type: changes.type,
                source: changes.source,
                active: firstDefined(changes.active, active),
              };

              return {
                ...props,
                node: removeOptional(newNode, 'source', 'active'),
              };
            }

            case 'msg': {
              const newNode: Field = {
                ...restOfCell(props.node),
                original: originalNode,
                value: { error: 'notResolved' },
                parameterReferences:
                  props.node.type === 'msg'
                    ? props.node.parameterReferences
                    : undefined,
                parameterValues:
                  props.node.type === 'msg'
                    ? props.node.parameterValues
                    : undefined,
                message: changes.message,
                type: changes.type,
                source: changes.source,
                active: firstDefined(changes.active, active),
              };

              return {
                ...props,
                node: removeOptional(newNode, 'source', 'active'),
              };
            }
            default:
              return props;
          }
        }
        return props;
      },
      boolean: (key, id, props) => {
        const { changes } = props;
        if (typeof changes === 'boolean') {
          return { ...props, node: changes };
        }
        return props;
      },
      table: (key, id, props) => {
        const { changes } = props;
        if (changes) {
          return {
            ...props,
            node: applyTableChanges(id, props.node, changes),
          };
        }
        return props;
      },
      array: (key, id, props) => {
        const { changes } = props;
        if (changes) {
          const newSections = Object.keys(changes)
            .map((key) => parseInt(key))
            .reduce(
              (
                result: (AgoyDocumentPart<AgoyDocumentStructurePart> | null)[],
                key: number
              ) => {
                if (typeof props.node.newSectionTemplate === 'function') {
                  result[key] =
                    changes[key] === null
                      ? null
                      : props.node.newSectionTemplate(`${id}-${key}`);
                } else {
                  result[key] =
                    changes[key] === null
                      ? null
                      : props.node.newSectionTemplate;
                }
                return result;
              },
              [...props.node.sections]
            );
          return {
            ...props,
            node: { ...props.node, sections: newSections },
          };
        }

        return props;
      },
    });

    if (document === result.document) {
      return document;
    }
    return {
      ...document,
      ...result.document,
    };
  };
};

export default applyChanges;

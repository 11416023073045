import React, { useState, useEffect, useMemo } from 'react';
import styled from '@emotion/styled';
import { omit } from 'lodash';
import { useIntl } from 'react-intl';

import { useSelector } from 'redux/reducers';
import { generateAllPeriods, getPeriodYear } from 'utils/periodManipulation';
import CommonPeriodPicker from '_shared/components/PeriodPicker';
import { Period } from '@agoy/api-sdk-core';
import { format, parseFinancialYear, parseFormat } from '@agoy/dates';
import Button from '_shared/components/Buttons/Button';

const StyledButton = styled.div`
  margin: ${(props) => props.theme.spacing(1)}px auto;
`;

type PeriodPickerProps = {
  clientId: string;
  periods: Period[];
  onPeriodChange: (periods: { [key: string]: boolean }) => void;
};

type ButtonMode = 'selectCurrentYear' | 'selectAll' | 'selectCurrentMonth';

const titles = {
  selectCurrentYear: 'hidden.transactions.selectYear',
  selectAll: 'hidden.transactions.selectAll',
  selectCurrentMonth: 'hidden.transactions.selectMonth',
};

const transformPeriodsToObject = (periods: (Period | string)[]) => {
  const periodsAsObject = {};
  periods.forEach((p) => {
    // we need only YYYYMM format
    periodsAsObject[
      (typeof p === 'string' ? p : p.start).replace('-', '').substring(0, 6)
    ] = true;
  });
  return periodsAsObject;
};

const PeriodPicker = ({
  clientId,
  periods,
  onPeriodChange,
}: PeriodPickerProps): JSX.Element | null => {
  const { formatMessage } = useIntl();

  const [selectedPeriods, setSelectedPeriods] = useState(() =>
    transformPeriodsToObject(periods)
  );

  const [buttonMode, setButtonMode] = useState<ButtonMode>('selectCurrentYear');

  const { financialYears = [], rawFinancialYears } =
    useSelector((state) => state.customers[clientId]) || {};

  const periodAsDate = periods[periods.length - 1].start;

  const currentFinancialYear = rawFinancialYears.find(
    (y) => y.start <= periodAsDate && y.end >= periodAsDate
  );

  const range = useMemo(() => {
    const firstFinancialYear = financialYears[0];
    const lastFinancialYear = financialYears[financialYears.length - 1];

    const { start } = parseFinancialYear(firstFinancialYear);
    const { end } = parseFinancialYear(lastFinancialYear);
    return {
      start,
      end,
    };
  }, [financialYears]);

  useEffect(() => {
    setSelectedPeriods(transformPeriodsToObject(periods));
  }, [periods]);

  useEffect(() => {
    onPeriodChange(selectedPeriods);
  }, [onPeriodChange, selectedPeriods]);

  const handlePeriodSelect = (periodString) => {
    const formattedPeriod = parseFormat(periodString, 'yyyyMM');

    if (
      Object.keys(selectedPeriods).length > 1 &&
      selectedPeriods[formattedPeriod]
    ) {
      const omitToggledPeriod = omit(selectedPeriods, formattedPeriod);
      setSelectedPeriods(omitToggledPeriod);
    } else {
      setSelectedPeriods((f) => ({
        ...f,
        [formattedPeriod]: true,
      }));
    }
  };

  const handlePeriodSelectYear = () => {
    if (currentFinancialYear) {
      setSelectedPeriods(
        transformPeriodsToObject(currentFinancialYear.periods ?? [])
      );
    }
  };

  const handlePeriodSelectAll = () => {
    const start = format(range.start, 'yyyyMMdd');
    const end = format(range.end, 'yyyyMMdd');
    const allPeriods = generateAllPeriods(start, end);
    setSelectedPeriods(transformPeriodsToObject(allPeriods));
  };

  const handlePeriodSelectMonth = () => {
    setSelectedPeriods(transformPeriodsToObject(periods));
  };

  const handleSelectButton = () => {
    if (buttonMode === 'selectCurrentYear') {
      handlePeriodSelectYear();
      setButtonMode(
        financialYears.length > 1 ? 'selectAll' : 'selectCurrentMonth'
      );
    } else if (buttonMode === 'selectAll') {
      handlePeriodSelectAll();
      setButtonMode('selectCurrentMonth');
    } else if (buttonMode === 'selectCurrentMonth') {
      handlePeriodSelectMonth();
      setButtonMode('selectCurrentYear');
    }
  };

  const renderButton = (disabled) => (
    <StyledButton>
      <Button
        label={formatMessage({ id: titles[buttonMode] })}
        disabled={disabled}
        onClick={handleSelectButton}
        variant="text"
        size="medium"
      />
    </StyledButton>
  );

  return (
    <CommonPeriodPicker
      currentPeriodYear={getPeriodYear(periodAsDate)}
      rangeStartDate={range.start}
      rangeEndDate={range.end}
      selectedPeriods={selectedPeriods}
      handlePeriodSelect={handlePeriodSelect}
      renderButton={renderButton}
    />
  );
};

export default PeriodPicker;

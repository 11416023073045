import { AnnualReportActionTypes } from '_annual-report/redux/annual-report/action-type-declarations';

import { isClientAction, isClientYearAction } from 'redux/actions';
import { GlobalActions } from '_shared/redux/actions';
import {
  INIT_STATE,
  SET_PROGRAM_PERIODS_STATUS,
} from '_shared/redux/action-types';
import { updateProgramStatusPeriodState } from '_shared/redux/program-status/reducer';
import AnnualReportState, {
  AnnualReportClientState,
  AnnualReportClientYearState,
} from './types';
import {
  SET_ANNUAL_REPORT_CHANGES,
  DELETE_ANNUAL_REPORT_DOCUMENT,
} from '../action-types';

export const initialClientYearState: AnnualReportClientYearState = {};

export const initialClientState: AnnualReportClientState = {
  years: {},
};

export const initialState: AnnualReportState = {
  clients: {},
};

export const clientYearReducer = (
  state: AnnualReportClientYearState = initialClientYearState,
  action: AnnualReportActionTypes | GlobalActions
): AnnualReportClientYearState => {
  switch (action.type) {
    case SET_ANNUAL_REPORT_CHANGES:
      return {
        ...state,
        changes: action.changes,
        documentConfiguration: action.documentConfiguration,
        documentId: action.documentId ?? state.documentId,
        locked: action.locked ?? state.locked,
      };

    case DELETE_ANNUAL_REPORT_DOCUMENT: {
      const newState = {
        ...state,
        documentId: undefined,
        changes: undefined,
        documentConfiguration: undefined,
      };

      return newState;
    }

    default:
      return state;
  }
};

const clientReducer = (
  state: AnnualReportClientState = initialClientState,
  action: GlobalActions | AnnualReportActionTypes
): AnnualReportClientState => {
  switch (action.type) {
    case SET_PROGRAM_PERIODS_STATUS:
      return updateProgramStatusPeriodState('AN_REPORT', state, action);

    default:
      return state;
  }
};

const reducer = (
  state: AnnualReportState = initialState,
  action: GlobalActions | AnnualReportActionTypes
): AnnualReportState => {
  switch (action.type) {
    case INIT_STATE:
      return action.state.annualReport || state;

    default:
      if (isClientYearAction(action)) {
        const { clientId, financialYear } = action;
        if (financialYear.length !== 17) {
          throw new Error(`Invalid financialYear ${financialYear}`);
        }
        const clientState = state.clients[clientId]?.years[financialYear];
        const nextClientState = clientYearReducer(clientState, action);
        if (nextClientState !== clientState) {
          return {
            ...state,
            clients: {
              ...state.clients,
              [action.clientId]: {
                ...state.clients[action.clientId],
                years: {
                  ...state.clients[action.clientId]?.years,
                  [financialYear]: nextClientState,
                },
              },
            },
          };
        }
      }
      if (isClientAction(action)) {
        const { clientId } = action;
        return {
          ...state,
          clients: {
            ...state.clients,
            [clientId]: clientReducer(state.clients[clientId], action),
          },
        };
      }

      return state;
  }
};

export default reducer;

import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Avatar, Button, Grid, Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { PrivatePerson } from '_person/_types/person';
import { GenericModal } from '_shared/components/Modal';
import PersonAvatar from './PersonAvatar';
import SearchField from './SearchField';
import DialogDeletePerson from './DialogDeletePerson';
import AccessControl from './AccessControl';

const Container = styled.div`
  width: 85vw;
  padding: ${(props) => props.theme.spacing(2)}px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeaderLeftContainer = styled.div`
  display: flex;
  flexdirection: row;
`;

const HeaderRightContainer = styled.div``;

const MembersSection = styled(Grid)`
  padding: ${(props) => props.theme.spacing(1)}px;
  margin-top: ${(props) => props.theme.spacing(1)}px;
`;

const MemberAvatar = styled(Avatar)`
  height: 80px;
  width: 80px;
  margin-right: ${(props) => props.theme.spacing(1)}px;
`;

const FullName = ({ children }) => (
  <Typography variant="h2">{children}</Typography>
);

const DeleteButton = styled(Button)`
  background-color: ${({ theme }) => theme.palette.error.main};
  color: ${({ theme }) => theme.palette.error.contrastText};
  width: 100%;
`;

const getMembersAsArray = (
  members: Member.MemberType[]
): Member.MemberType[] => {
  const memberKeys = Object.keys(members);
  const membersArray: Member.MemberType[] = [];
  memberKeys.forEach((k: string) => membersArray.push(members[k]));

  return membersArray;
};

type Props = {
  isOpen: boolean;
  person: PrivatePerson | null;
  members: Member.MemberType[] | null;
  isAdmin: boolean;
  onToggle: (member: Member.MemberType) => void;
  onClose: () => void;
  onDelete: () => void;
};

const ModalEditPerson = (props: Props): JSX.Element => {
  const { person, members, isOpen, onToggle, onClose, onDelete, isAdmin } =
    props;
  const [personMembers, setPersonMembers] = useState<Member.MemberType[]>([]);
  const [filteredMembers, setFilteredMembers] = useState<Member.MemberType[]>(
    []
  );
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [search, setSearch] = useState('');
  const { formatMessage } = useIntl();
  const searchPlaceholder = formatMessage({
    id: 'person.search.placeholder',
  });

  const isAuthorised = (member: Member.MemberType) => {
    const authorisedPerson = member.authorisedPersons?.find(
      (authorized) => authorized.id === person?.id
    );

    return authorisedPerson != null;
  };

  const handleShowDeleteModal = () => {
    setShowDeleteDialog(true);
  };

  const handleDeletePerson = async () => {
    setIsDeleting(true);
    try {
      await onDelete();
    } finally {
      setIsDeleting(false);
      setShowDeleteDialog(false);
    }
  };

  useEffect(() => {
    if (members == null) return;

    setPersonMembers(getMembersAsArray(members));
  }, [members]);

  useEffect(() => {
    const includes = (s?: string) => s?.toLowerCase().includes(search);
    const filtered = personMembers?.filter(
      (m) => includes(m.familyName) || includes(m.givenName)
    );

    setFilteredMembers(filtered);
  }, [personMembers, search]);

  return (
    <GenericModal open={isOpen} handleClose={onClose}>
      <DialogDeletePerson
        isOpen={showDeleteDialog}
        isLoading={isDeleting}
        personName={`${person?.firstName} ${person?.lastName}`}
        onConfirm={handleDeletePerson}
        onClose={() => setShowDeleteDialog(false)}
      />
      <Container>
        <Header>
          <HeaderLeftContainer>
            <PersonAvatar personNumber={person?.personNumber || ''} />
            <FullName>{`${person?.firstName} ${person?.lastName}`}</FullName>
          </HeaderLeftContainer>
          <HeaderRightContainer>
            {isAdmin && (
              <DeleteButton onClick={handleShowDeleteModal}>
                {formatMessage({ id: 'client.delete.dialog.button' })}
              </DeleteButton>
            )}
          </HeaderRightContainer>
        </Header>
        <Body>
          <SearchField
            placeholder={searchPlaceholder}
            onChange={(newValue: string) => setSearch(newValue)}
          />
          <MembersSection container spacing={2}>
            {filteredMembers.map((member) => (
              <Grid key={member.email} item xs={12} sm={6} lg={4}>
                <AccessControl
                  name={`${member.givenName} ${member.familyName}`}
                  email={member.email}
                  Avatar={<MemberAvatar />}
                  isAuthorised={isAuthorised(member)}
                  onAuthorisationToggle={() => onToggle(member)}
                />
              </Grid>
            ))}
          </MembersSection>
        </Body>
      </Container>
    </GenericModal>
  );
};

export default ModalEditPerson;

import React from 'react';
import {
  Checkbox,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  Typography as MuiTypography,
} from '@material-ui/core';
import { useIntl } from 'react-intl';
import styled from '@emotion/styled';

import { GenericDocumentViewService } from '_shared/services/document/GenericDocumentViewService';
import CurrencyField from '_shared/components/Inputs/CurrencyField';
import EditField from '_shared/components/Inputs/EditField';
import { AgoyTableRow, numberValue, stringValue } from '@agoy/document';
import { isActive } from '@agoy/annual-report-document';
import StringInput from '../../UI/StringInput';

const Typography = styled(MuiTypography)`
  font-size: 1rem;
  color: inherit;
  font-weight: 400;
`;
interface VotingListRowProps {
  tableId: string;
  row: AgoyTableRow;
  service: GenericDocumentViewService;
  print?: boolean;
  editable?: boolean;
}
const VotingListRow = ({
  tableId,
  row,
  print,
  service,
  editable = true,
}: VotingListRowProps): JSX.Element | null => {
  const { formatMessage } = useIntl();

  const rowId = `${tableId}.${row.id}`;

  const handleNameChange = (name) => {
    service.updateCellValue(`${rowId}.name`, name);
  };

  const handleSharesChange = (votes) => {
    service.updateCellValue(`${rowId}.shares`, votes);
  };

  const handleVotesChange = (votes) => {
    service.updateCellValue(`${rowId}.votes`, votes);
  };

  const handleRoleChange = (event) => {
    service.updateCellValue(`${rowId}.role`, event.target.value);
  };

  const handleActiveChange = () => {
    service.toggleTableRowActive(rowId);
  };

  if (print && !isActive(row.active)) return null;

  return (
    <TableRow>
      {!print && (
        <TableCell>
          <Checkbox
            checked={isActive(row.active) ?? true}
            onChange={handleActiveChange}
            disabled={!editable}
          />
        </TableCell>
      )}
      <TableCell>
        {print || rowId.endsWith('-signature') ? (
          stringValue(row.cells?.name)
        ) : (
          <EditField
            value={stringValue(row.cells?.name) || ''}
            component={StringInput}
            onChange={handleNameChange}
            disabled={!editable}
          />
        )}
      </TableCell>
      <TableCell>
        {print ? (
          numberValue(row.cells?.shares) || '-'
        ) : (
          <CurrencyField
            value={numberValue(row.cells?.shares)}
            onValueChange={handleSharesChange}
            Input={StringInput}
            disabled={!editable}
          />
        )}
      </TableCell>
      <TableCell>
        {print ? (
          numberValue(row.cells?.votes) || '-'
        ) : (
          <CurrencyField
            value={numberValue(row.cells?.votes)}
            onValueChange={handleVotesChange}
            Input={StringInput}
            disabled={!editable}
          />
        )}
      </TableCell>
      <TableCell>
        {print || !editable ? (
          <Typography>
            {formatMessage({
              id: `annualReport.annualGeneralMeeting.role.${stringValue(
                row.cells?.role
              )}`,
              defaultMessage: ' ',
            })}
          </Typography>
        ) : (
          <Select
            value={stringValue(row.cells?.role) || ''}
            onChange={handleRoleChange}
            fullWidth
            disableUnderline
            disabled={!editable}
          >
            <MenuItem value="chairman_protocol">
              {formatMessage({
                id: 'annualReport.annualGeneralMeeting.role.chairman_protocol',
              })}
            </MenuItem>
            <MenuItem value="recordKeeper">
              {formatMessage({
                id: 'annualReport.annualGeneralMeeting.role.recordKeeper',
              })}
            </MenuItem>
            <MenuItem value="adjuster">
              {formatMessage({
                id: 'annualReport.annualGeneralMeeting.role.adjuster',
              })}
            </MenuItem>
            <MenuItem value="chairmanAndRecordKeeper">
              {formatMessage({
                id: 'annualReport.annualGeneralMeeting.role.chairmanAndRecordKeeper',
              })}
            </MenuItem>
            <MenuItem value="">(Ingen)</MenuItem>
          </Select>
        )}
      </TableCell>
    </TableRow>
  );
};

export default VotingListRow;

/**
 * Wildcard ids are use to match and often sum multiple references
 * by id. A wildcard is a '*' in the id.
 *
 * Important: The * will not match across a dot.
 *
 * Example: "a.*.c"
 * will match
 * - "a.b.c"
 * - "a.something.c" etc.
 *
 * but not
 * - "a.b.1.c"
 *
 *
 * @param allIds All existing ids to match with
 * @returns Function that matches wildcard ids.
 */
const expandIds =
  (allIds: string[]) =>
  (ids: string): string[] => {
    const parts = ids.split('*');
    const result = allIds.filter((id) => {
      if (id.startsWith(parts[0])) {
        let position = parts[0].length;
        for (let i = 1; i < parts.length; i++) {
          const start = position;
          if (parts[i].length === 0 && i === parts.length - 1) {
            return true;
          }
          position = id.indexOf(parts[i], position);
          if (position === -1) {
            return false;
          }
          const dot = id.indexOf('.', start);
          if (dot !== -1 && dot < position) {
            return false;
          }
          position += parts[i].length;
        }
        // Last position should be at the end.
        return position === id.length;
      }
      return false;
    });
    return result;
  };

export default expandIds;

import React from 'react';
import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';

const SpacerComponent = styled('div', {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== 'size',
})<{
  size: number;
}>`
  width: 100%;
  height: ${({ theme, size }) => theme.spacing(size)}px;
`;

/**
 * Spacer. Used for creating vertical space between fields.
 * Spacer div will stretch horizontally to take up container width and apply top padding of given size.
 *
 * @param size number - multiplier of 8px, e.g. size 2 = 2 * 8px = 16px;
 * @returns spacer component
 */

const Spacer = ({ size }: { size: number }) => {
  return <SpacerComponent size={size} />;
};

export default Spacer;

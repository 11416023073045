import React, { useContext, useState, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import styled from '@emotion/styled';
import AdapterDateFns from '@date-io/date-fns';
import { useDispatch } from 'react-redux';
import shortid from 'shortid';

import { useSelector } from 'redux/reducers';
import { InputData, SignaturePerson } from '_reconciliation/types';
import RichEditor from '_shared/components/RichEditor';
import EditingIconButton from '_shared/components/Buttons/EditingIconButton';
import ResetContentButton from '_shared/components/ResetContent/ResetContentButton';
import SignaturePeopleTable from '_shared/components/SignaturePeopleTable';
import { RowChanges } from '_shared/components/SignaturePeopleTable/EditRowView';
import PrintButton from '_shared/components/Buttons/PrintButton';
import { AgoyTableRow } from '@agoy/document';
import { ccyFormat } from '@agoy/common';
import { isInputCorrect } from 'utils';
import { parseFormat } from '@agoy/dates';
import { printDocument } from '_shared/components/PrintedDocument/utils';
import { addGlobalErrorMessage } from 'redux/actions';

import LocationField from '../Components/LocationField';
import AccountInfo from '../Components/AccountInfo';
import ErrorText from '../Components/ErrorText';
import InventoryViewPreviewModal from './InventoryViewPreviewModal';
import { SaldoHistoryContext } from '../../HiddenRowHistoryProvider';
import PeriodDataContext from '../PeriodDataContext';
import { usePrintState } from '../../utils';

const Container = styled.div`
  display: flex;
  padding-top: ${(props) => props.theme.spacing(2)}px;
  flex-direction: column;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  right: ${(props) => props.theme.spacing(2)}px;
  top: 60px;
`;

const TableWrapper = styled.div`
  margin: ${(props) => props.theme.spacing(2)}px 0;
  max-width: 1000px;
`;

interface InventoryViewProps {
  accountNumber: string;
  userData: InputData;
}

const getRows = (persons: SignaturePerson[]): AgoyTableRow[] => {
  return persons.map((person) => {
    const row = { id: shortid(), active: true, cells: {} };

    Object.keys(person).forEach((key) => {
      row.cells[key] = {
        type: 'string',
        value: person[key],
      };
    });

    return row;
  });
};

const InventoryView = ({
  accountNumber,
  userData,
}: InventoryViewProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const printState = usePrintState();
  const dispatch = useDispatch();

  const { onUserInputChange } = useContext(SaldoHistoryContext);
  const { clientId, period, financialYear } = useContext(PeriodDataContext);

  const [isEditing, setEditing] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);

  const { name, orgNumber } = useSelector((state) => state.customers[clientId]);

  const { inventoryView, saldo, ub } = userData;

  const getDefaultRichText = () => {
    // first paragraph
    let result = `<p>${formatMessage(
      {
        id: 'hidden.inventory.defaultRichText.part1',
      },
      { saldo: ccyFormat(saldo) }
    )}</p>`;

    // second paragraph
    result = result.concat(
      `<p>${formatMessage({
        id: 'hidden.inventory.defaultRichText.part2',
      })}</p>`
    );

    // list part
    let list = '';
    new Array(4).fill(0).forEach((item, index) => {
      list = list.concat(
        `<li>${formatMessage({
          id: `hidden.inventory.defaultRichText.list.${index + 1}`,
        })}</li>`
      );
    });

    result = result.concat(`<ul>${list}</ul>`);

    // last paragraph
    result = result.concat(
      `<p>${formatMessage({
        id: 'hidden.inventory.defaultRichText.part3',
      })}</p>`
    );

    return result;
  };

  const {
    richText = getDefaultRichText(),
    signatures = [],
    location = '',
  } = inventoryView;

  const isCorrect =
    ub !== undefined && saldo !== undefined
      ? isInputCorrect(`${saldo}`, ub)
      : false;

  const signaturesRows = useMemo(() => {
    return getRows(signatures);
  }, [signatures]);

  const handleReset = useCallback(() => {
    onUserInputChange(
      { inventoryView: { ...inventoryView, richText: undefined } },
      accountNumber,
      period
    );
  }, [accountNumber, inventoryView, onUserInputChange, period]);

  const handleChangeEditor = useCallback(
    (value) => {
      onUserInputChange(
        { inventoryView: { ...inventoryView, richText: value } },
        accountNumber,
        period
      );
    },
    [accountNumber, inventoryView, onUserInputChange, period]
  );

  const handleChangeLocation = useCallback(
    (updatedLocation) => {
      onUserInputChange(
        { inventoryView: { ...inventoryView, location: updatedLocation } },
        accountNumber,
        period
      );
    },
    [accountNumber, inventoryView, onUserInputChange, period]
  );

  const handleAddRow = useCallback(() => {
    onUserInputChange(
      {
        inventoryView: {
          ...inventoryView,
          signatures: [
            ...signatures,
            {
              date: '',
              givenName: '',
              role: '',
              surName: '',
            },
          ],
        },
      },
      accountNumber,
      period
    );
  }, [accountNumber, signatures, inventoryView, onUserInputChange, period]);

  const handleEditRow = useCallback(
    (rowId, changes: Partial<RowChanges>) => {
      const rowIndex = signaturesRows.findIndex((item) => item.id === rowId);

      const updatedSignatures = [...signatures];
      updatedSignatures[rowIndex] = {
        ...updatedSignatures[rowIndex],
        ...changes,
      };

      onUserInputChange(
        { inventoryView: { ...inventoryView, signatures: updatedSignatures } },
        accountNumber,
        period
      );
    },
    [
      accountNumber,
      signatures,
      signaturesRows,
      inventoryView,
      onUserInputChange,
      period,
    ]
  );

  const handleDeleteRow = useCallback(
    (rowId) => {
      const rowIndex = signaturesRows.findIndex((item) => item.id === rowId);
      const updatedSignatures = [...signatures];

      updatedSignatures.splice(rowIndex, 1);

      onUserInputChange(
        { inventoryView: { ...inventoryView, signatures: updatedSignatures } },
        accountNumber,
        period
      );
    },
    [
      accountNumber,
      signatures,
      signaturesRows,
      inventoryView,
      onUserInputChange,
      period,
    ]
  );

  const handlePrint = useCallback(async () => {
    try {
      const finYearInterval = `${parseFormat(
        financialYear.start,
        'yyyyMM'
      )}-${parseFormat(financialYear.end, 'yyyyMM')}`;

      const docName = `${formatMessage({
        id: 'hidden.inventory.title',
      })} ${name} ${period.start}.pdf`;

      await printDocument(
        clientId,
        finYearInterval,
        'accounting',
        docName,
        ['inventoryView'],
        {
          ...printState,
          inventoryViewData: {
            accountNumber,
            period,
            signatures,
            richText,
            location,
          },
        }
      );
    } catch (e) {
      dispatch(addGlobalErrorMessage('error'));
    }
  }, [
    accountNumber,
    clientId,
    dispatch,
    financialYear.end,
    financialYear.start,
    formatMessage,
    location,
    name,
    period,
    printState,
    richText,
    signatures,
  ]);

  return (
    <MuiPickersUtilsProvider utils={AdapterDateFns}>
      <Container>
        <InventoryViewPreviewModal
          accountNumber={accountNumber}
          period={period}
          signatures={signatures}
          richText={richText}
          location={location}
          open={isModalOpen}
          onClose={() => setModalOpen(!isModalOpen)}
          onPrint={handlePrint}
        />

        <AccountInfo
          title={formatMessage({ id: 'hidden.inventory.title' })}
          tooltip={formatMessage({ id: 'hidden.inventory.tooltip' })}
          accountNumber={accountNumber}
          clientName={name || ''}
          orgNumber={orgNumber || ''}
          financialYear={financialYear}
          period={period}
        />

        <RichEditor
          value={richText}
          onChange={handleChangeEditor}
          isEditing={isEditing}
          height={200}
        />

        {!isCorrect && (
          <ErrorText
            errorText={formatMessage({ id: 'hidden.inventory.saldoError' })}
          />
        )}

        <LocationField onChange={handleChangeLocation} value={location} />

        <TableWrapper>
          <SignaturePeopleTable
            rows={signaturesRows}
            onAddRow={handleAddRow}
            onEditRow={handleEditRow}
            onDeleteRow={handleDeleteRow}
            showAccountantRole={false}
          />
        </TableWrapper>

        <Buttons>
          <PrintButton
            onClick={() => setModalOpen(true)}
            tooltip={formatMessage({ id: 'hidden.inventory.print' })}
          />
          <EditingIconButton
            value={isEditing}
            onClick={() => setEditing(!isEditing)}
          />
          <ResetContentButton what="test" onReset={handleReset} />
        </Buttons>
      </Container>
    </MuiPickersUtilsProvider>
  );
};

export default InventoryView;

import React, { useState } from 'react';
import ResetIcon from '@material-ui/icons/Restore';
import { IconButton, Tooltip } from '@material-ui/core';
import { useIntl } from 'react-intl';
import styled from '@emotion/styled';
import ResetContentDialog from './ResetContentDialog';

const StyledIcon = styled(IconButton)`
  ${(props) =>
    props.color === 'primary'
      ? `color: ${props.theme.palette.success.main}`
      : ''};
`;
interface ResetContentButtonProps {
  className?: string;
  what: string;
  size?: 'medium' | 'small';
  disabled?: boolean;
  onReset: () => void;
}

const ResetContentButton = ({
  className,
  what,
  size = 'medium',
  onReset,
  disabled,
}: ResetContentButtonProps) => {
  const { formatMessage } = useIntl();
  const [open, setOpen] = useState(false);

  const onConfirm = async () => {
    onReset();

    setOpen(false);
  };

  return (
    <>
      <Tooltip title={formatMessage({ id: 'annualReport.reset.title' })}>
        <StyledIcon
          className={className}
          onClick={() => setOpen(true)}
          color={open ? 'primary' : undefined}
          size={size}
          disabled={disabled}
        >
          <ResetIcon />
        </StyledIcon>
      </Tooltip>
      <ResetContentDialog
        what={what}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        onConfirm={onConfirm}
      />
    </>
  );
};

export default ResetContentButton;

import React from 'react';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';
import { Typography, Button, Checkbox, Paper } from '@material-ui/core';

const Wrapper = styled.div`
  margin: 0 auto ${(props) => props.theme.spacing(4)}px auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  overflow-y: true;

  width: 700px;
`;

const Table = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: ${(props) => props.theme.spacing(1)}px;
`;

const CenteredDiv = styled.div`
  margin: 0 auto;
`;

type LicenseType = 'none' | 'small' | 'big';
type LicensesType = {
  small: {
    available: number;
    total: number;
  };
  big: {
    available: number;
    total: number;
  };
  members: { id: string; name: string; license: LicenseType }[];
};

type Props = {
  licenses: LicensesType;
  updatingLicenses: boolean;
  handleGoToLicenseManagement: () => void;
  handleLicenseAssignment: (
    userId: string,
    newLicenseType: LicenseType
  ) => Promise<void>;
};

const Page = ({
  licenses,
  updatingLicenses,
  handleGoToLicenseManagement,
  handleLicenseAssignment,
}: Props) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <Paper elevation={2}>
        <Wrapper>
          <Table>
            <div />
            <Typography variant="h4" align="center">
              {formatMessage({ id: 'checkout.smallSubscription.title' })}
            </Typography>
            <Typography variant="h4" align="center">
              {formatMessage({ id: 'checkout.bigSubcription.title' })}
            </Typography>
            <Typography>
              {formatMessage({ id: 'dashboard.licenses.available' })}
            </Typography>
            <Typography align="center">x{licenses.small.available}</Typography>
            <Typography align="center">x{licenses.big.available}</Typography>
            <Typography>
              {formatMessage({ id: 'dashboard.licenses.total' })}
            </Typography>
            <Typography align="center">x{licenses.small.total}</Typography>
            <Typography align="center">x{licenses.big.total}</Typography>
          </Table>
          <br />
          <Button
            onClick={handleGoToLicenseManagement}
            variant="contained"
            color="secondary"
            disableElevation
          >
            {formatMessage({ id: 'dashboard.licenses.manageButton' })}
          </Button>
        </Wrapper>
      </Paper>
      <Paper elevation={2}>
        <Wrapper>
          <Table>
            <Typography variant="h4">
              {formatMessage({ id: 'info.activity.user' })}
            </Typography>
            <Typography variant="h4" align="center">
              {formatMessage({ id: 'checkout.smallSubscription.title' })}
            </Typography>
            <Typography variant="h4" align="center">
              {formatMessage({ id: 'checkout.bigSubcription.title' })}
            </Typography>
            {licenses.members.map((licenseUser) => {
              const userLicense = licenseUser.license;
              return (
                <React.Fragment key={licenseUser.id}>
                  <Typography component="div">{licenseUser.name}</Typography>
                  <CenteredDiv>
                    <Checkbox
                      checked={licenseUser.license === 'small'}
                      style={{ padding: 0 }}
                      disabled={updatingLicenses}
                      onClick={() =>
                        handleLicenseAssignment(
                          licenseUser.id,
                          userLicense === 'small' ? 'none' : 'small'
                        )
                      }
                    />
                  </CenteredDiv>
                  <CenteredDiv>
                    <Checkbox
                      checked={licenseUser.license === 'big'}
                      style={{ padding: 0 }}
                      disabled={updatingLicenses}
                      onClick={() =>
                        handleLicenseAssignment(
                          licenseUser.id,
                          userLicense === 'big' ? 'none' : 'big'
                        )
                      }
                    />
                  </CenteredDiv>
                </React.Fragment>
              );
            })}
          </Table>
        </Wrapper>
      </Paper>
    </>
  );
};

export default Page;

import React, { useEffect, Suspense, useCallback, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';
import * as Sentry from '@sentry/react';
import { ClientsRouteParams } from 'routes/types';
import { setCurrentCustomer } from '_clients/redux/customer-view/actions';
import FallbackComponent from '_shared/components/ErrorBoundary';
import LoadingLogo from '_shared/components/LoadingLogo';
import { addGlobalErrorMessage } from '_messages/redux/actions';
import { isUserError } from 'types/Error/types';
import ScrollTo from 'ScrollTo';
import {
  ClientLoaderContextProvider,
  ClientLoaderCallback,
  ClientLoaderErrorCallback,
} from '_shared/components/ClientLoaderContext';
import {
  setAccountListDrawerExpanded,
  setAccountListDrawerMode,
} from '_shared/redux/account-list-drawer/actions';
import { setChecklistDrawerExpanded } from '_shared/redux/checklist/actions';
import useHeaderHeight from '_shared/hooks/useHeaderHeight';
import { updateFinancialReportReferencesCurrentPeriod } from '_financial-report/redux/actions';
import { Provider as ClientSourceContextProvider } from '_annual-report/service/ClientSource';
import { Provider as AccountingBalancesSourceProvider } from '_annual-report/service/AccountingBalancesSource';
import { useSelector } from 'redux/reducers';

import { IconMenuProvider } from '_shared/components/IconMenu';
import Loaders from './Loaders';
import LoadClient from './LoadClient';
import LoadPreviousYearAccountingData from './LoadPreviousYearAccountingData';
import LoadProductStatuses from './LoadProductStatuses';
import UserInputLoader from './Loaders/UserInputLoader';
import UpdateSieFromFortnox from './UpdateSieFromFortnox';

const AnnualReportView = React.lazy(
  () => import('_annual-report/components/AnnualReportView')
);
const Taxes = React.lazy(() => import('_tax/components/Taxes'));
const FinancialReportView = React.lazy(
  () => import('_financial-report/components/FinancialReportView')
);
const TaxDeclarationView = React.lazy(
  () => import('_tax/components/TaxDeclarationView')
);

const LoadingFallback = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const ClientProduct = (): JSX.Element => {
  const dispatch = useDispatch();
  const {
    params: { program },
  } = useRouteMatch<ClientsRouteParams>();

  const [error, setError] = useState<string | null>(null);

  const { currentCustomer, currentFinancialYearId, currentFinancialYear } =
    useSelector((state) => state.customerView);

  const onLoaded = useCallback<ClientLoaderCallback>(
    async (clientId, year) => {
      await dispatch(setCurrentCustomer(clientId, year));
    },
    [dispatch]
  );

  const onError = useCallback<ClientLoaderErrorCallback>(
    (err) => {
      // eslint-disable-next-line no-console
      console.error(err);
      Sentry.captureException(err);
      const messageId = isUserError(err)
        ? err.messageId
        : 'client.loading.error';
      dispatch(addGlobalErrorMessage(messageId));
      setError(messageId);
    },
    [dispatch]
  );

  const onLoadedFinancialReport = useCallback(
    (clientId) => {
      dispatch(updateFinancialReportReferencesCurrentPeriod(clientId));
    },
    [dispatch]
  );

  useEffect(() => {
    // Clear current customer when leaving
    return () => {
      dispatch(setCurrentCustomer(undefined, undefined));
    };
  }, [dispatch]);

  // Close the accountList when changing programs
  useEffect(() => {
    if (!program) return;

    dispatch(setAccountListDrawerExpanded(false));
    dispatch(setChecklistDrawerExpanded(false));
    dispatch(setAccountListDrawerMode('viewing'));
  }, [program, dispatch]);

  return (
    <ClientLoaderContextProvider onLoaded={onLoaded} onError={onError}>
      <Loaders />
      <LoadClient error={error}>
        <ScrollTo>
          <Sentry.ErrorBoundary fallback={FallbackComponent}>
            <Suspense
              fallback={
                <LoadingFallback>
                  <LoadingLogo size="medium" />
                </LoadingFallback>
              }
            >
              {currentCustomer &&
                currentFinancialYearId &&
                currentFinancialYear && (
                  <>
                    <UserInputLoader
                      clientId={currentCustomer}
                      financialYearId={currentFinancialYearId}
                    />
                    <UpdateSieFromFortnox
                      clientId={currentCustomer}
                      financialYear={currentFinancialYear}
                    />
                  </>
                )}
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                  minWidth: 0,
                  overflow: 'auto',
                }}
              >
                {program === 'year' && (
                  <>
                    <LoadProductStatuses program="AN_REPORT" />
                    <AnnualReportView />
                  </>
                )}
                {program === 'tax' && (
                  <>
                    <LoadProductStatuses program="FIN_STATEMENT" />
                    <LoadProductStatuses program="RECONCILIATION" />
                    <Taxes />
                  </>
                )}
                {program === 'taxdeclaration' && <TaxDeclarationView />}
                {program === 'financialreport' && (
                  <LoadPreviousYearAccountingData
                    onLoaded={onLoadedFinancialReport}
                  >
                    <FinancialReportView />
                  </LoadPreviousYearAccountingData>
                )}
              </div>
            </Suspense>
          </Sentry.ErrorBoundary>
        </ScrollTo>
      </LoadClient>
    </ClientLoaderContextProvider>
  );
};

const Layout = styled.div<{ headerHeight: number }>`
  display: flex;
  height: calc(
    100vh - ${({ headerHeight }) => headerHeight}px
  ); // deduct header height
  width: 100vw;
`;

export default (): JSX.Element => {
  const headerHeight = useHeaderHeight();

  return (
    <Layout headerHeight={headerHeight}>
      <IconMenuProvider />
      <ClientSourceContextProvider>
        <AccountingBalancesSourceProvider>
          <ClientProduct />
        </AccountingBalancesSourceProvider>
      </ClientSourceContextProvider>
    </Layout>
  );
};

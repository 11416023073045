import {
  TaxDeclarationFormDefinition,
  TaxDeclarationFormDataConfig,
  PersonMetaData,
} from '../../types';
import {
  ref,
  value,
  id,
  sum,
  or,
  ifOrElse,
  multiply,
  max,
  min,
  abs,
} from '@agoy/document';
import { range } from 'lodash/fp';
import { mapFormId, toInitial, toStructure } from '../forms-util';

/**
 * Calculates the profit in a row, if no profit is made it returns undefined
 * @param section
 * @param rowNumber
 * @returns
 */
const vinstCalculationRef = (section, rowNumber) => {
  return ref(
    ifOrElse(
      max(
        sum(
          or(id(`K4.fields.${section}_${rowNumber}_forsaljningspris`), 0),
          multiply(
            or(id(`K4.fields.${section}_${rowNumber}_omkostnadsbelopp`), 0),
            -1
          )
        ),
        0
      ),
      sum(
        or(id(`K4.fields.${section}_${rowNumber}_forsaljningspris`), 0),
        multiply(
          or(id(`K4.fields.${section}_${rowNumber}_omkostnadsbelopp`), 0),
          -1
        )
      ),
      undefined
    )
  );
};

/**
 * Calculates the loss in a row, if no loss is made it returns undefined
 * @param section
 * @param rowNumber
 * @returns
 */
const forlustCalculationRef = (section, rowNumber) => {
  return ref(
    ifOrElse(
      min(
        sum(
          or(id(`K4.fields.${section}_${rowNumber}_forsaljningspris`), 0),
          multiply(
            or(id(`K4.fields.${section}_${rowNumber}_omkostnadsbelopp`), 0),
            -1
          )
        ),
        0
      ),
      abs(
        sum(
          or(id(`K4.fields.${section}_${rowNumber}_forsaljningspris`), 0),
          multiply(
            or(id(`K4.fields.${section}_${rowNumber}_omkostnadsbelopp`), 0),
            -1
          )
        )
      ),
      undefined
    )
  );
};

/**
 * Calculates the sum of a 'table' in the form
 * @param idRef the id of the property to sum over
 * @param section section, ex A, B...
 * @param rowNumbers Array of row numbers to sum over [1, 2, 3, ...]
 * @returns
 */
const sumRows = (idRef, section, rowNumbers) => {
  if (!rowNumbers || rowNumbers.length === 0) {
    return undefined;
  }

  return ref(
    ifOrElse(
      sum(
        ...rowNumbers.map((rowNumber) => {
          return or(id(`K4.fields.${section}_${rowNumber}_${idRef}`), 0);
        })
      ),
      sum(
        ...rowNumbers.map((rowNumber) => {
          return or(id(`K4.fields.${section}_${rowNumber}_${idRef}`), 0);
        })
      ),
      undefined
    )
  );
};

const configK4 = (
  form: TaxDeclarationFormDefinition,
  person: PersonMetaData
): TaxDeclarationFormDataConfig => {
  const name = `${person?.firstName || ''} ${person?.lastName || ''}`;
  const K4 = mapFormId(form, 'K4');
  const initial = {
    K4: {
      partType: 'form' as const,
      id: K4,
      fields: {
        datum: value(''),
        numreringFleraK4: value(undefined),
        namn: value(name),
        personNummer: value(person.personNumber || ''),
        A_1_antal: value(undefined),
        A_1_beteckning: value(''),
        A_1_forsaljningspris: value(undefined),
        A_1_omkostnadsbelopp: value(undefined),
        A_1_vinst: vinstCalculationRef('A', '1'),
        A_1_forlust: forlustCalculationRef('A', '1'),
        A_2_antal: value(undefined),
        A_2_beteckning: value(''),
        A_2_forsaljningspris: value(undefined),
        A_2_omkostnadsbelopp: value(undefined),
        A_2_vinst: vinstCalculationRef('A', '2'),
        A_2_forlust: forlustCalculationRef('A', '2'),
        A_3_antal: value(undefined),
        A_3_beteckning: value(''),
        A_3_forsaljningspris: value(undefined),
        A_3_omkostnadsbelopp: value(undefined),
        A_3_vinst: vinstCalculationRef('A', '3'),
        A_3_forlust: forlustCalculationRef('A', '3'),
        A_4_antal: value(undefined),
        A_4_beteckning: value(''),
        A_4_forsaljningspris: value(undefined),
        A_4_omkostnadsbelopp: value(undefined),
        A_4_vinst: vinstCalculationRef('A', '4'),
        A_4_forlust: forlustCalculationRef('A', '4'),
        A_5_antal: value(undefined),
        A_5_beteckning: value(''),
        A_5_forsaljningspris: value(undefined),
        A_5_omkostnadsbelopp: value(undefined),
        A_5_vinst: vinstCalculationRef('A', '5'),
        A_5_forlust: forlustCalculationRef('A', '5'),
        A_6_antal: value(undefined),
        A_6_beteckning: value(''),
        A_6_forsaljningspris: value(undefined),
        A_6_omkostnadsbelopp: value(undefined),
        A_6_vinst: vinstCalculationRef('A', '6'),
        A_6_forlust: forlustCalculationRef('A', '6'),
        A_7_antal: value(undefined),
        A_7_beteckning: value(''),
        A_7_forsaljningspris: value(undefined),
        A_7_omkostnadsbelopp: value(undefined),
        A_7_vinst: vinstCalculationRef('A', '7'),
        A_7_forlust: forlustCalculationRef('A', '7'),
        A_8_antal: value(undefined),
        A_8_beteckning: value(''),
        A_8_forsaljningspris: value(undefined),
        A_8_omkostnadsbelopp: value(undefined),
        A_8_vinst: vinstCalculationRef('A', '8'),
        A_8_forlust: forlustCalculationRef('A', '8'),
        A_9_antal: value(undefined),
        A_9_beteckning: value(''),
        A_9_forsaljningspris: value(undefined),
        A_9_omkostnadsbelopp: value(undefined),
        A_9_vinst: vinstCalculationRef('A', '9'),
        A_9_forlust: forlustCalculationRef('A', '9'),
        A_summa_forsaljningspris:
          sumRows('forsaljningspris', 'A', range(1, 10)) || value(undefined),
        A_summa_omkostnadsbelopp:
          sumRows('omkostnadsbelopp', 'A', range(1, 10)) || value(undefined),
        A_summa_vinst: sumRows('vinst', 'A', range(1, 10)) || value(undefined),
        A_summa_forlust:
          sumRows('forlust', 'A', range(1, 10)) || value(undefined),
        B_beteckning_pa_uppskovsaktie: value(''),
        B_uppskovsbelopp_som_aterfors: value(undefined),
        C_1_antal: value(undefined),
        C_1_beteckning: value(''),
        C_1_forsaljningspris: value(undefined),
        C_1_omkostnadsbelopp: value(undefined),
        C_1_vinst: vinstCalculationRef('C', '1'),
        C_1_forlust: forlustCalculationRef('C', '1'),
        C_2_antal: value(undefined),
        C_2_beteckning: value(''),
        C_2_forsaljningspris: value(undefined),
        C_2_omkostnadsbelopp: value(undefined),
        C_2_vinst: vinstCalculationRef('C', '2'),
        C_2_forlust: forlustCalculationRef('C', '2'),
        C_3_antal: value(undefined),
        C_3_beteckning: value(''),
        C_3_forsaljningspris: value(undefined),
        C_3_omkostnadsbelopp: value(undefined),
        C_3_vinst: vinstCalculationRef('C', '3'),
        C_3_forlust: forlustCalculationRef('C', '3'),
        C_4_antal: value(undefined),
        C_4_beteckning: value(''),
        C_4_forsaljningspris: value(undefined),
        C_4_omkostnadsbelopp: value(undefined),
        C_4_vinst: vinstCalculationRef('C', '4'),
        C_4_forlust: forlustCalculationRef('C', '4'),
        C_5_antal: value(undefined),
        C_5_beteckning: value(''),
        C_5_forsaljningspris: value(undefined),
        C_5_omkostnadsbelopp: value(undefined),
        C_5_vinst: vinstCalculationRef('C', '5'),
        C_5_forlust: forlustCalculationRef('C', '5'),
        C_6_antal: value(undefined),
        C_6_beteckning: value(''),
        C_6_forsaljningspris: value(undefined),
        C_6_omkostnadsbelopp: value(undefined),
        C_6_vinst: vinstCalculationRef('C', '6'),
        C_6_forlust: forlustCalculationRef('C', '6'),
        C_7_antal: value(undefined),
        C_7_beteckning: value(''),
        C_7_forsaljningspris: value(undefined),
        C_7_omkostnadsbelopp: value(undefined),
        C_7_vinst: vinstCalculationRef('C', '7'),
        C_7_forlust: forlustCalculationRef('C', '7'),
        C_summa_forsaljningspris:
          sumRows('forsaljningspris', 'C', range(1, 8)) || value(undefined),
        C_summa_omkostnadsbelopp:
          sumRows('omkostnadsbelopp', 'C', range(1, 8)) || value(undefined),
        C_summa_vinst: sumRows('vinst', 'C', range(1, 8)) || value(undefined),
        C_summa_forlust:
          sumRows('forlust', 'C', range(1, 8)) || value(undefined),
        D_1_antal: value(undefined),
        D_1_beteckning: value(''),
        D_1_forsaljningspris: value(undefined),
        D_1_omkostnadsbelopp: value(undefined),
        D_1_vinst: vinstCalculationRef('D', '1'),
        D_1_forlust: forlustCalculationRef('D', '1'),
        D_2_antal: value(undefined),
        D_2_beteckning: value(''),
        D_2_forsaljningspris: value(undefined),
        D_2_omkostnadsbelopp: value(undefined),
        D_2_vinst: vinstCalculationRef('D', '2'),
        D_2_forlust: forlustCalculationRef('D', '2'),
        D_3_antal: value(undefined),
        D_3_beteckning: value(''),
        D_3_forsaljningspris: value(undefined),
        D_3_omkostnadsbelopp: value(undefined),
        D_3_vinst: vinstCalculationRef('D', '3'),
        D_3_forlust: forlustCalculationRef('D', '3'),
        D_4_antal: value(undefined),
        D_4_beteckning: value(''),
        D_4_forsaljningspris: value(undefined),
        D_4_omkostnadsbelopp: value(undefined),
        D_4_vinst: vinstCalculationRef('D', '4'),
        D_4_forlust: forlustCalculationRef('D', '4'),
        D_5_antal: value(undefined),
        D_5_beteckning: value(''),
        D_5_forsaljningspris: value(undefined),
        D_5_omkostnadsbelopp: value(undefined),
        D_5_vinst: vinstCalculationRef('D', '5'),
        D_5_forlust: forlustCalculationRef('D', '5'),
        D_6_antal: value(undefined),
        D_6_beteckning: value(''),
        D_6_forsaljningspris: value(undefined),
        D_6_omkostnadsbelopp: value(undefined),
        D_6_vinst: vinstCalculationRef('D', '6'),
        D_6_forlust: forlustCalculationRef('D', '6'),
        D_7_antal: value(undefined),
        D_7_beteckning: value(''),
        D_7_forsaljningspris: value(undefined),
        D_7_omkostnadsbelopp: value(undefined),
        D_7_vinst: vinstCalculationRef('D', '7'),
        D_7_forlust: forlustCalculationRef('D', '7'),
        D_summa_forsaljningspris:
          sumRows('forsaljningspris', 'D', range(1, 8)) || value(undefined),
        D_summa_omkostnadsbelopp:
          sumRows('omkostnadsbelopp', 'D', range(1, 8)) || value(undefined),
        D_summa_vinst: sumRows('vinst', 'D', range(1, 8)) || value(undefined),
        D_summa_forlust:
          sumRows('forlust', 'D', range(1, 8)) || value(undefined),
      },
      sru: {
        '7014': ref(id('K4.fields.numreringFleraK4')),
        '3100': ref(id('K4.fields.A_1_antal')),
        '3101': ref(id('K4.fields.A_1_beteckning')),
        '3102': ref(id('K4.fields.A_1_forsaljningspris')),
        '3103': ref(id('K4.fields.A_1_omkostnadsbelopp')),
        '3104': ref(id('K4.fields.A_1_vinst')),
        '3105': ref(id('K4.fields.A_1_forlust')),
        '3110': ref(id('K4.fields.A_2_antal')),
        '3111': ref(id('K4.fields.A_2_beteckning')),
        '3112': ref(id('K4.fields.A_2_forsaljningspris')),
        '3113': ref(id('K4.fields.A_2_omkostnadsbelopp')),
        '3114': ref(id('K4.fields.A_2_vinst')),
        '3115': ref(id('K4.fields.A_2_forlust')),
        '3120': ref(id('K4.fields.A_3_antal')),
        '3121': ref(id('K4.fields.A_3_beteckning')),
        '3122': ref(id('K4.fields.A_3_forsaljningspris')),
        '3123': ref(id('K4.fields.A_3_omkostnadsbelopp')),
        '3124': ref(id('K4.fields.A_3_vinst')),
        '3125': ref(id('K4.fields.A_3_forlust')),
        '3130': ref(id('K4.fields.A_4_antal')),
        '3131': ref(id('K4.fields.A_4_beteckning')),
        '3132': ref(id('K4.fields.A_4_forsaljningspris')),
        '3133': ref(id('K4.fields.A_4_omkostnadsbelopp')),
        '3134': ref(id('K4.fields.A_4_vinst')),
        '3135': ref(id('K4.fields.A_4_forlust')),
        '3140': ref(id('K4.fields.A_5_antal')),
        '3141': ref(id('K4.fields.A_5_beteckning')),
        '3142': ref(id('K4.fields.A_5_forsaljningspris')),
        '3143': ref(id('K4.fields.A_5_omkostnadsbelopp')),
        '3144': ref(id('K4.fields.A_5_vinst')),
        '3145': ref(id('K4.fields.A_5_forlust')),
        '3150': ref(id('K4.fields.A_6_antal')),
        '3151': ref(id('K4.fields.A_6_beteckning')),
        '3152': ref(id('K4.fields.A_6_forsaljningspris')),
        '3153': ref(id('K4.fields.A_6_omkostnadsbelopp')),
        '3154': ref(id('K4.fields.A_6_vinst')),
        '3155': ref(id('K4.fields.A_6_forlust')),
        '3160': ref(id('K4.fields.A_7_antal')),
        '3161': ref(id('K4.fields.A_7_beteckning')),
        '3162': ref(id('K4.fields.A_7_forsaljningspris')),
        '3163': ref(id('K4.fields.A_7_omkostnadsbelopp')),
        '3164': ref(id('K4.fields.A_7_vinst')),
        '3165': ref(id('K4.fields.A_7_forlust')),
        '3170': ref(id('K4.fields.A_8_antal')),
        '3171': ref(id('K4.fields.A_8_beteckning')),
        '3172': ref(id('K4.fields.A_8_forsaljningspris')),
        '3173': ref(id('K4.fields.A_8_omkostnadsbelopp')),
        '3174': ref(id('K4.fields.A_8_vinst')),
        '3175': ref(id('K4.fields.A_8_forlust')),
        '3180': ref(id('K4.fields.A_9_antal')),
        '3181': ref(id('K4.fields.A_9_beteckning')),
        '3182': ref(id('K4.fields.A_9_forsaljningspris')),
        '3183': ref(id('K4.fields.A_9_omkostnadsbelopp')),
        '3184': ref(id('K4.fields.A_9_vinst')),
        '3185': ref(id('K4.fields.A_9_forlust')),
        '3300': ref(id('K4.fields.A_summa_forsaljningspris')),
        '3301': ref(id('K4.fields.A_summa_omkostnadsbelopp')),
        '3304': ref(id('K4.fields.A_summa_vinst')),
        '3305': ref(id('K4.fields.A_summa_forlust')),
        '3302': ref(id('K4.fields.B_beteckning_pa_uppskovsaktie')),
        '3303': ref(id('K4.fields.B_uppskovsbelopp_som_aterfors')),
        '3310': ref(id('K4.fields.C_1_antal')),
        '3311': ref(id('K4.fields.C_1_beteckning')),
        '3312': ref(id('K4.fields.C_1_forsaljningspris')),
        '3313': ref(id('K4.fields.C_1_omkostnadsbelopp')),
        '3314': ref(id('K4.fields.C_1_vinst')),
        '3315': ref(id('K4.fields.C_1_forlust')),
        '3320': ref(id('K4.fields.C_2_antal')),
        '3321': ref(id('K4.fields.C_2_beteckning')),
        '3322': ref(id('K4.fields.C_2_forsaljningspris')),
        '3323': ref(id('K4.fields.C_2_omkostnadsbelopp')),
        '3324': ref(id('K4.fields.C_2_vinst')),
        '3325': ref(id('K4.fields.C_2_forlust')),
        '3330': ref(id('K4.fields.C_3_antal')),
        '3331': ref(id('K4.fields.C_3_beteckning')),
        '3332': ref(id('K4.fields.C_3_forsaljningspris')),
        '3333': ref(id('K4.fields.C_3_omkostnadsbelopp')),
        '3334': ref(id('K4.fields.C_3_vinst')),
        '3335': ref(id('K4.fields.C_3_forlust')),
        '3340': ref(id('K4.fields.C_4_antal')),
        '3341': ref(id('K4.fields.C_4_beteckning')),
        '3342': ref(id('K4.fields.C_4_forsaljningspris')),
        '3343': ref(id('K4.fields.C_4_omkostnadsbelopp')),
        '3344': ref(id('K4.fields.C_4_vinst')),
        '3345': ref(id('K4.fields.C_4_forlust')),
        '3350': ref(id('K4.fields.C_5_antal')),
        '3351': ref(id('K4.fields.C_5_beteckning')),
        '3352': ref(id('K4.fields.C_5_forsaljningspris')),
        '3353': ref(id('K4.fields.C_5_omkostnadsbelopp')),
        '3354': ref(id('K4.fields.C_5_vinst')),
        '3355': ref(id('K4.fields.C_5_forlust')),
        '3360': ref(id('K4.fields.C_6_antal')),
        '3361': ref(id('K4.fields.C_6_beteckning')),
        '3362': ref(id('K4.fields.C_6_forsaljningspris')),
        '3363': ref(id('K4.fields.C_6_omkostnadsbelopp')),
        '3364': ref(id('K4.fields.C_6_vinst')),
        '3365': ref(id('K4.fields.C_6_forlust')),
        '3370': ref(id('K4.fields.C_7_antal')),
        '3371': ref(id('K4.fields.C_7_beteckning')),
        '3372': ref(id('K4.fields.C_7_forsaljningspris')),
        '3373': ref(id('K4.fields.C_7_omkostnadsbelopp')),
        '3374': ref(id('K4.fields.C_7_vinst')),
        '3375': ref(id('K4.fields.C_7_forlust')),
        '3400': ref(id('K4.fields.C_summa_forsaljningspris')),
        '3401': ref(id('K4.fields.C_summa_omkostnadsbelopp')),
        '3403': ref(id('K4.fields.C_summa_vinst')),
        '3404': ref(id('K4.fields.C_summa_forlust')),
        '3410': ref(id('K4.fields.D_1_antal')),
        '3411': ref(id('K4.fields.D_1_beteckning')),
        '3412': ref(id('K4.fields.D_1_forsaljningspris')),
        '3413': ref(id('K4.fields.D_1_omkostnadsbelopp')),
        '3414': ref(id('K4.fields.D_1_vinst')),
        '3415': ref(id('K4.fields.D_1_forlust')),
        '3420': ref(id('K4.fields.D_2_antal')),
        '3421': ref(id('K4.fields.D_2_beteckning')),
        '3422': ref(id('K4.fields.D_2_forsaljningspris')),
        '3423': ref(id('K4.fields.D_2_omkostnadsbelopp')),
        '3424': ref(id('K4.fields.D_2_vinst')),
        '3425': ref(id('K4.fields.D_2_forlust')),
        '3430': ref(id('K4.fields.D_3_antal')),
        '3431': ref(id('K4.fields.D_3_beteckning')),
        '3432': ref(id('K4.fields.D_3_forsaljningspris')),
        '3433': ref(id('K4.fields.D_3_omkostnadsbelopp')),
        '3434': ref(id('K4.fields.D_3_vinst')),
        '3435': ref(id('K4.fields.D_3_forlust')),
        '3440': ref(id('K4.fields.D_4_antal')),
        '3441': ref(id('K4.fields.D_4_beteckning')),
        '3442': ref(id('K4.fields.D_4_forsaljningspris')),
        '3443': ref(id('K4.fields.D_4_omkostnadsbelopp')),
        '3444': ref(id('K4.fields.D_4_vinst')),
        '3445': ref(id('K4.fields.D_4_forlust')),
        '3450': ref(id('K4.fields.D_5_antal')),
        '3451': ref(id('K4.fields.D_5_beteckning')),
        '3452': ref(id('K4.fields.D_5_forsaljningspris')),
        '3453': ref(id('K4.fields.D_5_omkostnadsbelopp')),
        '3454': ref(id('K4.fields.D_5_vinst')),
        '3455': ref(id('K4.fields.D_5_forlust')),
        '3460': ref(id('K4.fields.D_6_antal')),
        '3461': ref(id('K4.fields.D_6_beteckning')),
        '3462': ref(id('K4.fields.D_6_forsaljningspris')),
        '3463': ref(id('K4.fields.D_6_omkostnadsbelopp')),
        '3464': ref(id('K4.fields.D_6_vinst')),
        '3465': ref(id('K4.fields.D_6_forlust')),
        '3470': ref(id('K4.fields.D_7_antal')),
        '3471': ref(id('K4.fields.D_7_beteckning')),
        '3472': ref(id('K4.fields.D_7_forsaljningspris')),
        '3473': ref(id('K4.fields.D_7_omkostnadsbelopp')),
        '3474': ref(id('K4.fields.D_7_vinst')),
        '3475': ref(id('K4.fields.D_7_forlust')),
        '3500': ref(id('K4.fields.D_summa_forsaljningspris')),
        '3501': ref(id('K4.fields.D_summa_omkostnadsbelopp')),
        '3503': ref(id('K4.fields.D_summa_vinst')),
        '3504': ref(id('K4.fields.D_summa_forlust')),
      },
    },
  };
  return {
    initial: toInitial(initial, form),
    definition: toStructure(initial, form),
  };
};

export default configK4;

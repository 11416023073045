import { MessagesActions } from './actions';
import { ADD_CHANNEL_MESSAGE, REMOVE_CHANNEL_MESSAGE } from './action-types';
import MessagesState, { Message } from '../types';

const initialState: MessagesState = {};

const reducer = (
  state: MessagesState = initialState,
  action: MessagesActions
): MessagesState => {
  switch (action.type) {
    case ADD_CHANNEL_MESSAGE: {
      const { id, channel, severity, textId, text } = action;
      const channelMessages = state[channel];

      const message: Message = {
        id,
        severity,
        textId,
        text,
      };
      return {
        ...state,
        [channel]: channelMessages
          ? [...channelMessages.filter((msg) => msg.id !== message.id), message] // Replace if existing id
          : [message],
      };
    }

    case REMOVE_CHANNEL_MESSAGE:
      const channelMessages = state[action.channel];
      if (!channelMessages) {
        return state;
      }
      const filterMessages = channelMessages.filter(
        (msg) => msg.id !== action.id
      );
      if (filterMessages.length === channelMessages.length) {
        return state;
      }
      if (filterMessages.length === 0) {
        const newState = { ...state };
        delete newState[action.channel];
        return newState;
      }
      return {
        ...state,
        [action.channel]: filterMessages,
      };

    default:
      return state;
  }
};

export default reducer;

import * as t from 'io-ts';

const LampValue = t.union([
  t.literal('done'),
  t.literal('started'),
  t.literal('doesNotExist'),
  t.literal('wrong'),
  t.literal('unvisited'),
]);

export const ClientLampsChangedMessageCodec = t.type({
  userId: t.string,
  clientId: t.string,
  topic: t.literal('client-lamps-changed'),
  periods: t.array(
    t.type({
      periodId: t.number,
      lamps: t.type({
        a15xx: LampValue,
        a19xx: LampValue,
        a24xx: LampValue,
        a1630: LampValue,
        rest: LampValue,
        moms: LampValue,
      }),
    })
  ),
});

/**
 * Notification of change to client lamps
 *
 * generate-document-values lambda will report when values are updated.
 */
export type ClientLampsChangedMessage = t.TypeOf<
  typeof ClientLampsChangedMessageCodec
>;

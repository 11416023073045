import { value } from '@agoy/document';
import { field, active } from '../../common/utils/util';
import { FoundationNotes } from './types';
import { AnnualReportType } from '../../document';
import { getFoundationIncomeStatementNotes } from './income-statement-notes/incomeStatementNotes';
import { getFoundationBalanceSheetNotes } from './balance-sheet-notes/balanceSheetNotes';
import { getFoundationOtherNotes } from './other-notes/otherNotes';

export const notesConfig = (
  financialYears: string[],
  financialYear: string,
  period: string | null,
  reportType: AnnualReportType,
  isDigitalSubmission: boolean
): FoundationNotes => {
  const incomeStatementNotes = getFoundationIncomeStatementNotes(
    financialYears,
    financialYear,
    period,
    reportType,
    false
  );

  const balanceSheetNotes = getFoundationBalanceSheetNotes(
    financialYears,
    financialYear,
    period,
    false
  );

  const otherNotes = getFoundationOtherNotes(
    financialYears,
    financialYear,
    period,
    false
  );

  return {
    active: undefined,
    headings: {
      active: undefined,
      number: value(undefined),
      name: value(undefined),
      notesIncomeStatementTitle: active(field('Noter till resultaträkning')),
      notesBalanceSheetTitle: active(field('Noter till balansräkning')),
      notesOtherTitle: active(field('Övriga noter')),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    ...incomeStatementNotes,
    ...balanceSheetNotes,
    ...otherNotes,
  };
};

export const notesReferences = (): Record<string, string> => ({});

import { AgoyTable, AgoyTableColumn, Cell } from '@agoy/document';

const splitTableByColumns = (
  table: AgoyTable<Cell>,
  findLabelColumns = (c: AgoyTableColumn, index: number) => c.id === 'label'
): AgoyTable[] => {
  // This function is needed to divide a table into several depending of the count of columns.

  // Table division criteria:
  // Less than 6 - one table.
  // More than 6 and less than 11 columns, two tables.
  // More than 11 three tables.

  const labelColumn = table.columns.find(findLabelColumns);

  if (!labelColumn) return [table];

  const activeTableColumnsWithoutLabel = table.columns.filter(
    (c) =>
      c.id !== labelColumn.id &&
      (!('active' in c) || c.active || c.id.startsWith('@'))
  );

  const indexCount = (countOfColumns: number) => {
    if (countOfColumns < 9) return 4;
    if (countOfColumns >= 11) return 6;
    return 5;
  };

  const countOfColumns = activeTableColumnsWithoutLabel.length;

  const spliceCount = indexCount(countOfColumns);

  if (countOfColumns <= 6) {
    return [
      {
        ...table,
        columns: table.columns.filter(
          (c) => !('active' in c) || c.active || c.id.startsWith('@')
        ),
      },
    ];
  }

  if (countOfColumns >= 7 && countOfColumns <= 12) {
    return [
      {
        ...table,
        columns: [
          labelColumn,
          ...activeTableColumnsWithoutLabel.slice(0, spliceCount),
        ],
      },
      {
        ...table,
        columns: [
          labelColumn,
          ...activeTableColumnsWithoutLabel.slice(spliceCount),
        ],
      },
    ];
  }

  if (countOfColumns > 12) {
    return [
      {
        ...table,
        columns: [labelColumn, ...activeTableColumnsWithoutLabel.slice(0, 5)],
      },
      {
        ...table,
        columns: [labelColumn, ...activeTableColumnsWithoutLabel.slice(5, 9)],
      },
      {
        ...table,
        columns: [labelColumn, ...activeTableColumnsWithoutLabel.slice(9)],
      },
    ];
  }

  return [table];
};

export default splitTableByColumns;

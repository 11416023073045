/* eslint-disable no-nested-ternary */
import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';
import {
  Tooltip,
  Typography,
  TypographyProps,
  TextField as MuiTextField,
} from '@material-ui/core';
import { AnnualReportType } from '@agoy/annual-report-document';
import { default as EditFieldBase } from '_shared/components/Inputs/EditField';
import isPropValid from '@emotion/is-prop-valid';
import AnnualReportViewServiceContext from '_annual-report/service/AnnualReportViewServiceContext';

interface HeadingProps {
  id?: string;
  title: string | React.ReactElement;
  variant?: TypographyProps['variant'];
  controls?: React.ReactElement | undefined;
  tooltip?: string;
  reportType?: AnnualReportType;
  noteId?: string;
  editing?: boolean;
  noteNumber?: number | undefined;
  isDigitalSubmission?: boolean;
  part?: string;
}

const HeadingWithControls = styled(Typography)`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  min-width: 800px;
  margin-bottom: ${(props) => props.theme.spacing(3)}px;
`;

const Controls = styled.div`
  flex-shrink: 0;
`;

const TextFieldBase = (props) => {
  return <MuiTextField variant="outlined" size="small" {...props} />;
};

const SingleLineTextField = styled(TextFieldBase, {
  shouldForwardProp: (prop) => isPropValid(prop),
})`
  width: 100%;
`;

const HeadingForPrint = ({
  title,
  variant = 'h2',
  controls,
  id,
  tooltip,
  reportType,
  noteId,
  editing,
  noteNumber,
  isDigitalSubmission = false,
  part,
}: HeadingProps) => {
  const intl = useIntl();
  const service = useContext(AnnualReportViewServiceContext);

  const onFieldChange = (value) => {
    const fullId = noteId ? `notes.${noteId}.${id}` : `${part}.${id}`;
    service.updateField(fullId, value);
  };

  if (controls) {
    return (
      <HeadingWithControls variant={variant} id={id}>
        {tooltip && reportType === 'k3' ? (
          <Tooltip
            title={intl.formatMessage({ id: tooltip })}
            placement="right"
          >
            {editing && !isDigitalSubmission ? (
              <EditFieldBase
                component={SingleLineTextField}
                value={title}
                onChange={onFieldChange}
              />
            ) : (
              <span>{noteNumber ? `${noteNumber} ${title}` : title}</span>
            )}
          </Tooltip>
        ) : editing && !isDigitalSubmission ? (
          <EditFieldBase
            component={SingleLineTextField}
            value={title}
            onChange={onFieldChange}
          />
        ) : (
          <span>{noteNumber ? `${noteNumber} ${title}` : title}</span>
        )}
        <Controls>{controls}</Controls>
      </HeadingWithControls>
    );
  }

  return (
    <Typography variant={variant} id={id}>
      {noteNumber ? `${noteNumber} ${title}` : title}
    </Typography>
  );
};

export default HeadingForPrint;

import React, { useRef } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import {
  Grow,
  Popper,
  Paper as MuiPaper,
  MenuItem,
  MenuList,
  ClickAwayListener,
  Box,
  Divider,
  Typography,
  Tooltip,
} from '@material-ui/core';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';
import { StatusIcon } from './StatusIcon';
import Button from '../Buttons/Button';

const ButtonStatusDot = styled.div`
  display: flex;
  border-radius: 50%;
  background: white;
`;

const Label = styled.div`
  padding: 0 ${({ theme }) => theme.spacing(1)}px;
  max-width: 168px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  align-self: center;
`;

const Arrow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Paper = styled(MuiPaper)`
  width: 312px;
`;

const WhoChanged = styled.div`
  white-space: normal;
  padding: ${({ theme }) => theme.spacing(1)}px;

  .MuiTypography-root {
    font-size: 0.875rem;
  }
`;

const DateOfChange = styled(Typography)`
  color: ${({ theme }) => theme.palette.grey[500]};
`;

const ColoredStatus = styled.span`
  color: ${(props: { statusColor: string }) => props.statusColor};
`;

type PeriodStatusOption = {
  id: string;
  label: string;
  statusColor: string;
  selected: boolean;
  disabled: boolean;
  tooltip?: string;
};

export type StatusHistory = {
  id: string;
  label: string;
  statusColor: string;
  createdAt?: string;
  createdBy?: string;
  reason?: string;
};

type PeriodStatusButtonProps = {
  options: PeriodStatusOption[];
  onOptionSelected: (optionId: string) => void;
  statusHistory?: StatusHistory[];
  icon?: JSX.Element;
};

export const PeriodStatusButton = ({
  options,
  onOptionSelected,
  statusHistory,
  icon,
}: PeriodStatusButtonProps): JSX.Element => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = useRef(null);
  const { formatMessage } = useIntl();

  const selectedOption = options.find(({ selected }) => selected) || options[0];

  const handleMenuItemClick = (optionId: string) => {
    if (onOptionSelected) onOptionSelected(optionId);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        label={selectedOption.label}
        variant="outlined"
        onClick={handleToggle}
        ref={anchorRef}
        startIcon={
          <ButtonStatusDot>
            <StatusIcon
              statusColor={selectedOption?.statusColor || 'inherit'}
              icon={icon}
              small
            />
          </ButtonStatusDot>
        }
        endIcon={
          <Arrow
            aria-controls={open ? 'period-status-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label="Välj periodstatus"
            aria-haspopup="menu"
          >
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </Arrow>
        }
      />

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{ zIndex: 9999 }}
        placement="bottom-end"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <>
                  <MenuList id="period-status--button-menu">
                    {options.map(
                      ({
                        label,
                        disabled,
                        selected,
                        statusColor,
                        id,
                        tooltip,
                      }) => (
                        <Tooltip key={label} title={tooltip || ''}>
                          <div>
                            <MenuItem
                              disabled={disabled}
                              selected={selected}
                              onClick={() => handleMenuItemClick(id)}
                            >
                              <StatusIcon
                                statusColor={statusColor || 'inherit'}
                                icon={icon}
                              />
                              <Box paddingLeft={1}>{label}</Box>
                            </MenuItem>
                          </div>
                        </Tooltip>
                      )
                    )}
                  </MenuList>

                  {statusHistory && (
                    <>
                      <Divider />
                      {statusHistory
                        // only render at most 3 last changes
                        .slice(0, 3)
                        .map((historyItem) => {
                          return (
                            <WhoChanged
                              key={`${historyItem.createdAt}-${
                                historyItem.createdBy
                              }-${Math.random() * 100}`}
                            >
                              <DateOfChange>
                                {historyItem.createdAt}
                              </DateOfChange>
                              <Typography>
                                {historyItem.createdBy}{' '}
                                {formatMessage({
                                  id: historyItem.reason
                                    ? 'program.status.history.log.entry.reason'
                                    : 'program.status.history.log.entry.change',
                                })}
                                {': '}
                                <ColoredStatus
                                  statusColor={historyItem.statusColor}
                                >
                                  {historyItem.reason
                                    ? `"${historyItem.reason}"`
                                    : historyItem.label}
                                </ColoredStatus>
                              </Typography>
                            </WhoChanged>
                          );
                        })}
                    </>
                  )}
                </>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default PeriodStatusButton;

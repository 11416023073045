import React, { useCallback, memo, useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import styled from '@emotion/styled';
import RestoreIcon from '@material-ui/icons/Restore';
import { IconButton, Switch } from '@material-ui/core';

import { setChecklistDrawerExpanded } from '_shared/redux/checklist/actions';
import {
  ChecklistItemStatus,
  ChecklistStatuses,
  Checklist,
} from '_shared/redux/checklist/types';
import LoadingPlaceholder from '_shared/components/LoadingPlaceholder';
import Drawer from '_shared/components/Drawer';
import { Period } from '@agoy/api-sdk-core';
import { formatCurrentTime, parseFormat } from '@agoy/dates';

import SettingsModal from './SettingsModal';
import ChecklistComponent from './Checklist';
import PeriodsRow from './PeriodsRow';

const DRAWER_EXPANDED_WIDTH = 1000;

const Button = styled(IconButton)`
  color: white;
  margin-right: ${({ theme }) => theme.spacing(1)}px;
`;

const StyledRestoreIcon = styled(RestoreIcon)`
  font-size: 30px;
`;

interface ChecklistDrawerProps {
  periods: Period[];
  loading: boolean;
  showResetButton: boolean;
  expanded: boolean;
  statuses?: ChecklistStatuses;
  checklist?: Checklist;
  defaultPeriod?: Period;
  financialYear?: string;
  onChangeStatus: (
    questionId: number,
    status: ChecklistItemStatus,
    selectedPeriod?: Period
  ) => void;
  onChangeChecklist: () => void;
  onToggleInclude?: () => void;
}

const ChecklistDrawer = ({
  periods,
  loading,
  showResetButton,
  expanded,
  statuses,
  checklist,
  defaultPeriod,
  financialYear,
  onChangeStatus,
  onChangeChecklist,
  onToggleInclude,
}: ChecklistDrawerProps) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const [selectedPeriod, setSelectedPeriod] = useState<Period | undefined>();
  const [isSettingsVisible, setIsSettingsVisible] = useState<boolean>(false);
  const [withColumns, setWithColumns] = useState(false);

  const filteredPeriods = useMemo(
    () => periods.filter((period) => period.type === 'month'),
    [periods]
  );

  useEffect(() => {
    const currentDate = formatCurrentTime('yyyyMM');
    const currentPeriod = filteredPeriods.find(
      (item) => parseFormat(item.start, 'yyyyMM') === currentDate
    );
    setSelectedPeriod(
      defaultPeriod ||
        currentPeriod ||
        filteredPeriods[filteredPeriods.length - 1]
    );
  }, [filteredPeriods, defaultPeriod]);

  const toggleExpanded = useCallback(() => {
    dispatch(setChecklistDrawerExpanded(!expanded));
  }, [dispatch, expanded]);

  const toggleSettingsVisible = () => {
    setIsSettingsVisible(!isSettingsVisible);
  };

  const handleSubmitChange = () => {
    onChangeChecklist();
    setIsSettingsVisible(!isSettingsVisible);
  };

  const handleClickPeriod = (period) => {
    setSelectedPeriod(period);
  };

  return (
    <>
      <SettingsModal
        isOpen={isSettingsVisible}
        onClose={toggleSettingsVisible}
        onSubmit={handleSubmitChange}
      />

      <Drawer
        open={expanded}
        onClose={toggleExpanded}
        width={`${DRAWER_EXPANDED_WIDTH}px`}
        headerTitle={formatMessage({
          id: 'checklist.label',
        })}
        headerContent={
          <>
            <Switch
              checked={withColumns}
              onChange={() => setWithColumns(!withColumns)}
              size="small"
            />
            {showResetButton && (
              <Button onClick={toggleSettingsVisible} size="small">
                <StyledRestoreIcon />
              </Button>
            )}
          </>
        }
        stickyHeader
      >
        {loading ? (
          <LoadingPlaceholder />
        ) : (
          statuses &&
          checklist && (
            <>
              <PeriodsRow
                periods={filteredPeriods}
                dataByPeriods={checklist.periods}
                selectedPeriod={selectedPeriod}
                statuses={statuses}
                onClickPeriod={handleClickPeriod}
              />
              <ChecklistComponent
                period={selectedPeriod}
                withColumns={withColumns}
                statuses={statuses}
                checklist={checklist}
                financialYear={financialYear}
                onChangeStatus={onChangeStatus}
                onToggleInclude={onToggleInclude}
              />
            </>
          )
        )}
      </Drawer>
    </>
  );
};

export default memo(ChecklistDrawer);

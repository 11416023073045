import { ref, table, value } from '@agoy/document';
import { NoteOneTableSection } from '../../../../common';
import {
  field,
  ixbrlCell,
  ixbrlField,
  range,
} from '../../../../common/utils/util';
import {
  sumAccountsIBs,
  sumNotesRowsById,
} from '../../../../common/utils/accountSumsUtil';
import { year, previousYear } from '../../../../common/utils/date-util';
import { NoteAccountRanges } from '../types';

const MONETARY_ITEM_TYPE = 'monetaryItemType';

export const note87Accounts: NoteAccountRanges[] = [
  {
    rowId: '1',
    ranges: [[1020], [1030], [1040], [1050]],
    cellLabel: 'Ingående anskaffningsvärden',
    action: 'sumAccountsIBs',
  },
  {
    rowId: '9',
    ranges: [[1029], [1039], [1049], [1059]],
    cellLabel: 'Ingående avskrivningar',
    action: 'sumAccountsIBs',
  },
  {
    rowId: '25',
    ranges: [[1028], [1038], [1048], [1058]],
    cellLabel: 'Ingående nedskrivningar',
    action: 'sumAccountsIBs',
  },
];

export const getNote87 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null,
  documentTypeVersion = '1'
): NoteOneTableSection => {
  return {
    active: false,
    number: value(undefined),
    name: value(
      'Koncessioner, patent, licenser, varumärken samt liknande rättigheter'
    ),
    table: table(
      `notes.note87.table`,
      { id: 'label', label: '' },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      // 'ingående' = previous period outgoing balance
      .addRows((rows) =>
        rows
          .addRow(
            '1',
            noteLabel('Ingående anskaffningsvärden'),
            ixbrlCell(ref(sumAccountsIBs('1020+1030+1040+1050', false)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterAnskaffningsvarden',
              contextRef: 'balans1',
              saldo: 'debit',
              standardRubrik:
                'Anskaffningsvärden koncessioner, patent, licenser, varumärken samt liknande rättigheter',
            }),
            ixbrlCell(
              ref(sumAccountsIBs('1020+1030+1040+1050', false, 'previousYear')),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterAnskaffningsvarden',
                contextRef: 'balans2',
                saldo: 'debit',
                standardRubrik:
                  'Anskaffningsvärden koncessioner, patent, licenser, varumärken samt liknande rättigheter',
              }
            )
          )
          .addRow(
            'ChangesInAcquisitionValuesTitle',
            noteLabel('Förändringar av anskaffningsvärden')
          )
          .addRow(
            '2',
            noteLabel('Inköp'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringAnskaffningsvardenInkop',
              contextRef: 'period0',
              standardRubrik:
                'Ökning av anskaffningsvärden i samband med inköp av koncessioner, patent, licenser, varumärken samt liknande rättigheter',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringAnskaffningsvardenInkop',
              contextRef: 'period1',
              standardRubrik:
                'Ökning av anskaffningsvärden i samband med inköp av koncessioner, patent, licenser, varumärken samt liknande rättigheter',
            })
          )
          .addRow(
            '3',
            noteLabel('Rörelseförvärv'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringAnskaffningsvardenRorelseforvarv',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden för koncessioner, patent, licenser, varumärken samt liknande rättigheter genom rörelseförvärv',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringAnskaffningsvardenRorelseforvarv',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden för koncessioner, patent, licenser, varumärken samt liknande rättigheter genom rörelseförvärv',
            })
          )
          .addRow(
            '4',
            noteLabel('Fusion'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringAnskaffningsvardenFusion',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden för koncessioner, patent, licenser, varumärken samt liknande rättigheter genom fusion',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringAnskaffningsvardenFusion',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden för koncessioner, patent, licenser, varumärken samt liknande rättigheter genom fusion',
            })
          )
          .addRow(
            '5',
            noteLabel('Försäljningar/utrangeringar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringAnskaffningsvardenForsaljningarUtrangeringar',
              contextRef: 'period0',
              standardRubrik:
                'Minskning av anskaffningsvärden i samband med försäljningar eller utrangeringar av koncessioner, patent, licenser, varumärken samt liknande rättigheter',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringAnskaffningsvardenForsaljningarUtrangeringar',
              contextRef: 'period1',
              standardRubrik:
                'Minskning av anskaffningsvärden i samband med försäljningar eller utrangeringar av koncessioner, patent, licenser, varumärken samt liknande rättigheter',
            })
          )
          .addRow(
            '6',
            noteLabel('Omklassificeringar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringAnskaffningsvardenOmklassificeringar',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden i samband med omklassificeringar av koncessioner, patent, licenser, varumärken samt liknande rättigheter',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringAnskaffningsvardenOmklassificeringar',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden i samband med omklassificeringar av koncessioner, patent, licenser, varumärken samt liknande rättigheter',
            })
          )
          .addRow(
            '7',
            noteLabel('Omräkningsdifferenser'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringAnskaffningsvardenOmrakningsdifferenser',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden i samband med omräkningsdifferenser av koncessioner, patent, licenser, varumärken samt liknande rättigheter',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringAnskaffningsvardenOmrakningsdifferenser',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden i samband med omräkningsdifferenser av koncessioner, patent, licenser, varumärken samt liknande rättigheter',
            })
          )
          .addRow(
            '8',
            noteLabel('Utgående anskaffningsvärden'),
            ixbrlCell(sumNotesRowsById(87, [...range(1, 8)], 'year'), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterAnskaffningsvarden',
              contextRef: 'balans0',
              saldo: 'debit',
              standardRubrik:
                'Anskaffningsvärden koncessioner, patent, licenser, varumärken samt liknande rättigheter',
            }),
            ixbrlCell(sumNotesRowsById(87, [...range(1, 8)], 'previousYear'), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterAnskaffningsvarden',
              contextRef: 'balans1',
              saldo: 'debit',
              standardRubrik:
                'Anskaffningsvärden koncessioner, patent, licenser, varumärken samt liknande rättigheter',
            })
          )
          .addRow(
            '9',
            noteLabel('Ingående avskrivningar'),
            ixbrlCell(ref(sumAccountsIBs('1029+1039+1049+1059', false)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterAvskrivningar',
              contextRef: 'balans1',
              saldo: 'credit',
              standardRubrik:
                'Ackumulerade avskrivningar koncessioner, patent, licenser, varumärken samt liknande rättigheter',
            }),
            ixbrlCell(
              ref(sumAccountsIBs('1029+1039+1049+1059', false, 'previousYear')),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterAvskrivningar',
                contextRef: 'balans2',
                saldo: 'credit',
                standardRubrik:
                  'Ackumulerade avskrivningar koncessioner, patent, licenser, varumärken samt liknande rättigheter',
              }
            )
          )
          .addRow(
            'ChangesInDeprecationTitle',
            noteLabel('Förändringar av avskrivningar')
          )
          .addRow(
            '10',
            noteLabel('Fusion'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringAvskrivningarFusion',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av avskrivningar för koncessioner, patent, licenser, varumärken samt liknande rättigheter genom fusion',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringAvskrivningarFusion',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av avskrivningar för koncessioner, patent, licenser, varumärken samt liknande rättigheter genom fusion',
            })
          )
          .addRow(
            '11',
            noteLabel('Försäljningar/utrangeringar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringAvskrivningarAterfordaAvskrivningarForsaljningarRespUtrangeringar',
              contextRef: 'period0',
              standardRubrik:
                'Återförda avskrivningar i samband med försäljningar eller utrangeringar av koncessioner, patent, licenser, varumärken samt liknande rättigheter',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringAvskrivningarAterfordaAvskrivningarForsaljningarRespUtrangeringar',
              contextRef: 'period1',
              standardRubrik:
                'Återförda avskrivningar i samband med försäljningar eller utrangeringar av koncessioner, patent, licenser, varumärken samt liknande rättigheter',
            })
          )
          .addRow(
            '12',
            noteLabel('Omklassificeringar'), // no accounts
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringAvskrivningarOmklassificeringar',
              contextRef: 'period0',
              standardRubrik:
                'Minskning (ökning) av avskrivningar i samband med omklassificeringar av koncessioner, patent, licenser, varumärken samt liknande rättigheter',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringAvskrivningarOmklassificeringar',
              contextRef: 'period1',
              standardRubrik:
                'Minskning (ökning) av avskrivningar i samband med omklassificeringar av koncessioner, patent, licenser, varumärken samt liknande rättigheter',
            })
          )
          .addRow(
            '13',
            noteLabel('Årets avskrivningar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringAvskrivningarAretsAvskrivningar',
              contextRef: 'period0',
              standardRubrik:
                'Periodens avskrivningar koncessioner, patent, licenser, varumärken samt liknande rättigheter',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringAvskrivningarAretsAvskrivningar',
              contextRef: 'period1',
              standardRubrik:
                'Periodens avskrivningar koncessioner, patent, licenser, varumärken samt liknande rättigheter',
            })
          )
          .addRow(
            '14',
            noteLabel('Omräkningsdifferenser'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringAvskrivningarOmrakningsdifferenser',
              contextRef: 'period0',
              standardRubrik:
                'Minskning (ökning) av avskrivningar i samband med omräkningsdifferenser av koncessioner, patent, licenser, varumärken samt liknande rättigheter',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringAvskrivningarOmrakningsdifferenser',
              contextRef: 'period1',
              standardRubrik:
                'Minskning (ökning) av avskrivningar i samband med omräkningsdifferenser av koncessioner, patent, licenser, varumärken samt liknande rättigheter',
            })
          )
          .addRow(
            '15',
            noteLabel('Utgående avskrivningar'),
            ixbrlCell(sumNotesRowsById(87, [...range(9, 14)], 'year'), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterAvskrivningar',
              contextRef: 'balans0',
              saldo: 'credit',
              standardRubrik:
                'Ackumulerade avskrivningar koncessioner, patent, licenser, varumärken samt liknande rättigheter',
            }),
            ixbrlCell(sumNotesRowsById(87, [...range(9, 14)], 'previousYear'), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterAvskrivningar',
              contextRef: 'balans1',
              saldo: 'credit',
              standardRubrik:
                'Ackumulerade avskrivningar koncessioner, patent, licenser, varumärken samt liknande rättigheter',
            })
          )
          .addRow(
            '16',
            noteLabel('Ingående uppskrivningar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterUppskrivningar',
              contextRef: 'balans1',
              saldo: 'debit',
              standardRubrik:
                'Ackumulerade uppskrivningar koncessioner, patent, licenser, varumärken samt liknande rättigheter',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterUppskrivningar',
              contextRef: 'balans2',
              saldo: 'debit',
              standardRubrik:
                'Ackumulerade uppskrivningar koncessioner, patent, licenser, varumärken samt liknande rättigheter',
            })
          )
          .addRow(
            'ChangesToRevaluationsTitle',
            noteLabel('Förändringar av uppskrivningar')
          )
          .addRow(
            '17',
            noteLabel('Fusion'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringUppskrivningarFusion',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av uppskrivningar för koncessioner, patent, licenser, varumärken samt liknande rättigheter genom fusion',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringUppskrivningarFusion',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av uppskrivningar för koncessioner, patent, licenser, varumärken samt liknande rättigheter genom fusion',
            })
          )
          .addRow(
            '18',
            noteLabel('Försäljningar/utrangeringar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringUppskrivningarForsaljningarUtrangeringar',
              contextRef: 'period0',
              standardRubrik:
                'Återförda uppskrivningar i samband med försäljningar eller utrangeringar av koncessioner, patent, licenser, varumärken samt liknande rättigheter',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringUppskrivningarForsaljningarUtrangeringar',
              contextRef: 'period1',
              standardRubrik:
                'Återförda uppskrivningar i samband med försäljningar eller utrangeringar av koncessioner, patent, licenser, varumärken samt liknande rättigheter',
            })
          )
          .addRow(
            '19',
            noteLabel('Omklassificeringar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringUppskrivningarOmklassificeringar',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av uppskrivningar i samband med omklassificeringar av koncessioner, patent, licenser, varumärken samt liknande rättigheter',
            }), // no accounts
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringUppskrivningarOmklassificeringar',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av uppskrivningar i samband med omklassificeringar av koncessioner, patent, licenser, varumärken samt liknande rättigheter',
            })
          )
          .addRow(
            '20',
            noteLabel('Årets uppskrivningar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringUppskrivningarAretsUppskrivningar',
              contextRef: 'period0',
              standardRubrik:
                'Periodens uppskrivningar av koncessioner, patent, licenser, varumärken samt liknande rättigheter',
            }), // no accounts
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringUppskrivningarAretsUppskrivningar',
              contextRef: 'period1',
              standardRubrik:
                'Periodens uppskrivningar av koncessioner, patent, licenser, varumärken samt liknande rättigheter',
            })
          )

          .addRow(
            '21',
            noteLabel('Årets nedskrivningar på uppskrivet belopp'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringUppskrivningarAretsNedskrivningarUppskrivetBelopp',
              contextRef: 'period0',
              standardRubrik:
                'Nedskrivningar av uppskrivet belopp på koncessioner, patent, licenser, varumärken samt liknande rättigheter',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringUppskrivningarAretsNedskrivningarUppskrivetBelopp',
              contextRef: 'period1',
              standardRubrik:
                'Nedskrivningar av uppskrivet belopp på koncessioner, patent, licenser, varumärken samt liknande rättigheter',
            })
          )
          .addRow(
            '22',
            noteLabel('Årets avskrivningar på uppskrivet belopp'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringUppskrivningarAretsAvskrivningarUppskrivetBelopp',
              contextRef: 'period0',
              standardRubrik:
                'Avskrivningar av uppskrivet belopp på koncessioner, patent, licenser, varumärken samt liknande rättigheter',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringUppskrivningarAretsAvskrivningarUppskrivetBelopp',
              contextRef: 'period1',
              standardRubrik:
                'Avskrivningar av uppskrivet belopp på koncessioner, patent, licenser, varumärken samt liknande rättigheter',
            })
          )
          .addRow(
            '23',
            noteLabel('Omräkningsdifferenser'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringUppskrivningarOmrakningsdifferenser',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av uppskrivningar i samband med omräkningsdifferenser av koncessioner, patent, licenser, varumärken samt liknande rättigheter',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringUppskrivningarOmrakningsdifferenser',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av uppskrivningar i samband med omräkningsdifferenser av koncessioner, patent, licenser, varumärken samt liknande rättigheter',
            })
          )
          .addRow(
            '24',
            noteLabel('Utgående uppskrivningar'),
            ixbrlCell(sumNotesRowsById(87, [...range(16, 23)], 'year'), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterUppskrivningar',
              contextRef: 'balans0',
              saldo: 'debit',
              standardRubrik:
                'Ackumulerade uppskrivningar koncessioner, patent, licenser, varumärken samt liknande rättigheter',
            }),
            ixbrlCell(
              sumNotesRowsById(87, [...range(16, 23)], 'previousYear'),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterUppskrivningar',
                contextRef: 'balans1',
                saldo: 'debit',
                standardRubrik:
                  'Ackumulerade uppskrivningar koncessioner, patent, licenser, varumärken samt liknande rättigheter',
              }
            )
          )
          .addRow(
            '25',
            noteLabel('Ingående nedskrivningar'),
            ixbrlCell(ref(sumAccountsIBs('1028+1038+1048+1058', false)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterNedskrivningar',
              contextRef: 'balans1',
              saldo: 'credit',
              standardRubrik:
                'Ackumulerade nedskrivningar koncessioner, patent, licenser, varumärken samt liknande rättigheter',
            }),
            ixbrlCell(
              ref(sumAccountsIBs('1028+1038+1048+1058', false, 'previousYear')),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterNedskrivningar',
                contextRef: 'balans2',
                saldo: 'credit',
                standardRubrik:
                  'Ackumulerade nedskrivningar koncessioner, patent, licenser, varumärken samt liknande rättigheter',
              }
            )
          )
          .addRow(
            'ChangesInImpairmentChargesTitle',
            noteLabel('Förändringar av nedskrivningar')
          )
          .addRow(
            '26',
            noteLabel('Fusion'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringNedskrivningarFusion',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av nedskrivningar för koncessioner, patent, licenser, varumärken samt liknande rättigheter genom fusion',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringNedskrivningarFusion',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av nedskrivningar för koncessioner, patent, licenser, varumärken samt liknande rättigheter genom fusion',
            })
          )
          .addRow(
            '27',
            noteLabel('Försäljningar/utrangeringar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringNedskrivningarAterfordaNedskrivningarForsaljningarRespUtrangeringar',
              contextRef: 'period0',
              standardRubrik:
                'Återförda nedskrivningar i samband med försäljningar eller utrangeringar av koncessioner, patent, licenser, varumärken samt liknande rättigheter',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringNedskrivningarAterfordaNedskrivningarForsaljningarRespUtrangeringar',
              contextRef: 'period1',
              standardRubrik:
                'Återförda nedskrivningar i samband med försäljningar eller utrangeringar av koncessioner, patent, licenser, varumärken samt liknande rättigheter',
            })
          )
          .addRow(
            '28',
            noteLabel('Återförda nedskrivningar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringNedskrivningarAterfordaNedskrivningar',
              contextRef: 'period0',
              standardRubrik:
                'Periodens återförda nedskrivningar av koncessioner, patent, licenser, varumärken samt liknande rättigheter',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringNedskrivningarAterfordaNedskrivningar',
              contextRef: 'period1',
              standardRubrik:
                'Periodens återförda nedskrivningar av koncessioner, patent, licenser, varumärken samt liknande rättigheter',
            })
          )
          .addRow(
            '29',
            noteLabel('Omklassificeringar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringNedskrivningarOmklassificeringar',
              contextRef: 'period0',
              standardRubrik:
                'Minskning (ökning) av nedskrivningar i samband med omklassificeringar av koncessioner, patent, licenser, varumärken samt liknande rättigheter',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringNedskrivningarOmklassificeringar',
              contextRef: 'period1',
              standardRubrik:
                'Minskning (ökning) av nedskrivningar i samband med omklassificeringar av koncessioner, patent, licenser, varumärken samt liknande rättigheter',
            })
          )
          .addRow(
            '30',
            noteLabel('Årets nedskrivningar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringNedskrivningarAretsNedskrivningar',
              contextRef: 'period0',
              standardRubrik:
                'Periodens nedskrivningar av koncessioner, patent, licenser, varumärken samt liknande rättigheter',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringNedskrivningarAretsNedskrivningar',
              contextRef: 'period1',
              standardRubrik:
                'Periodens nedskrivningar av koncessioner, patent, licenser, varumärken samt liknande rättigheter',
            })
          )
          .addRow(
            '31',
            noteLabel('Omräkningsdifferenser'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringNedskrivningarOmrakningsdifferenser',
              contextRef: 'period0',
              saldo: 'credit',
              standardRubrik:
                'Minskning (ökning) av nedskrivningar i samband med omräkningsdifferenser av koncessioner, patent, licenser, varumärken samt liknande rättigheter',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterForandringNedskrivningarOmrakningsdifferenser',
              contextRef: 'period1',
              saldo: 'credit',
              standardRubrik:
                'Minskning (ökning) av nedskrivningar i samband med omräkningsdifferenser av koncessioner, patent, licenser, varumärken samt liknande rättigheter',
            })
          )
          .addRow(
            '32',
            noteLabel('Utgående nedskrivningar'),
            ixbrlCell(sumNotesRowsById(87, [...range(25, 31)], 'year'), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterNedskrivningar',
              contextRef: 'balans0',
              saldo: 'credit',
              standardRubrik:
                'Ackumulerade nedskrivningar koncessioner, patent, licenser, varumärken samt liknande rättigheter',
            }),
            ixbrlCell(
              sumNotesRowsById(87, [...range(25, 31)], 'previousYear'),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterNedskrivningar',
                contextRef: 'balans1',
                saldo: 'credit',
                standardRubrik:
                  'Ackumulerade nedskrivningar koncessioner, patent, licenser, varumärken samt liknande rättigheter',
              }
            )
          )
          .addRow(
            '33',
            noteLabel('Redovisat värde'),
            ixbrlCell(sumNotesRowsById(87, ['8', '15', '24', '32'], 'year'), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheter',
              contextRef: 'balans0',
              saldo: 'debit',
              standardRubrik:
                'Koncessioner, patent, licenser, varumärken samt liknande rättigheter',
            }),
            ixbrlCell(
              sumNotesRowsById(87, ['8', '15', '24', '32'], 'previousYear'),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheter',
                contextRef: 'balans1',
                saldo: 'debit',
                standardRubrik:
                  'Koncessioner, patent, licenser, varumärken samt liknande rättigheter',
              }
            )
          )
          .addRow(
            '34',
            noteLabel('Offentliga bidrag som reducerat anskaffningsvärdet'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterOffentligaBidrag',
              contextRef: 'balans0',
              saldo: 'credit',
              standardRubrik:
                'Offentliga bidrag som reducerat anskaffningsvärdet på koncessioner, patent, licenser, varumärken samt liknande rättigheter',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheterOffentligaBidrag',
              contextRef: 'balans1',
              saldo: 'credit',
              standardRubrik:
                'Offentliga bidrag som reducerat anskaffningsvärdet på koncessioner, patent, licenser, varumärken samt liknande rättigheter',
            })
          )
          .build()
      )
      .newRowTemplate(value(''), value(undefined), value(undefined))
      .build(),
    commentLabel: field('Kommentar'),
    comment: ixbrlField(field(''), {
      type: 'stringItemType',
      name: 'se-gen-base:NotKoncessionerPatentLicenserVarumarkenLiknandeRattigheterKommentar',
      contextRef: 'period0',
      standardRubrik:
        'Kommentar till specifikation av koncessioner, patent, licenser, varumärken samt liknande rättigheter',
    }),
    group: value(undefined),
    section: value(undefined),
    validForReportType: {
      k2: false,
      k3: documentTypeVersion != '1' ? true : false,
    },
  };
};

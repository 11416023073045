import React, { useContext } from 'react';

import Typography from '@material-ui/core/Typography';
import { TextField } from '@material-ui/core';
import styled from '@emotion/styled';

import EditField from '_shared/components/Inputs/EditField';
import FinancialReportViewServiceContext from '_financial-report/service/FinancialReportViewServiceContext';

interface ReportTitleProps {
  isEditing: boolean;
  reportTitle: string;
  className?: string;
  customColor: string | undefined;
}

const TitleNameField = styled(({ disableUnderline, ...rest }) => (
  <TextField multiline size="medium" variant="outlined" {...rest} />
))`
  .MuiInputBase-root {
    font-size: 28px;
    width: 100%;
    height: 150px;
  }
`;

type StyledReportTitleProps = {
  customColor: string | undefined;
};

const StyledReportTitle = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'customColor',
})<StyledReportTitleProps>`
  color: ${(props) => props.customColor || props.theme.palette.text.primary};
`;

const ReportTitle = ({
  isEditing,
  reportTitle,
  className,
  customColor,
}: ReportTitleProps): JSX.Element => {
  const service = useContext(FinancialReportViewServiceContext);

  const updateTitle = (newTitle) => {
    service.updateField('frontPage.section.title', newTitle);
  };

  return isEditing ? (
    <EditField
      value={reportTitle}
      onChange={(newValue) => updateTitle(newValue)}
      component={TitleNameField}
      className={className}
    />
  ) : (
    <StyledReportTitle
      className={className}
      variant="h1"
      customColor={customColor}
    >
      {reportTitle}
    </StyledReportTitle>
  );
};

export default ReportTitle;

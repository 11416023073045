import React from 'react';
import AccountingBalancesLoader from './AccountingBalancesLoader';
import ClientLoader from './ClientLoader';
import RoutinesLoader from './RoutinesLoader';
import TaxCalculationLoader from './TaxCalculationLoader';

/**
 * Defines all loaders for a client that
 * are executed for every client/year
 */
const Loaders = (): JSX.Element => (
  <>
    <ClientLoader />
    <RoutinesLoader />
    <TaxCalculationLoader />
    <AccountingBalancesLoader />
  </>
);

export default Loaders;

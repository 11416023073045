import React, { useCallback, useState, useEffect } from 'react';

interface DownloadLinkProps {
  href: string;
  title: string;
  children?: React.ReactElement;
  autoDownload?: boolean;
  fileType?: string;
  onDownloading?: (downloading: boolean, error: string | null) => void;
}

/**
 * Workaround for downloading content from another origin.
 *
 * @param Component an a element
 */
const Downloadlink: React.FC<DownloadLinkProps> = ({
  href,
  title,
  children = undefined,
  autoDownload = false,
  fileType = '',
  onDownloading = () => {},
  ...rest
}: DownloadLinkProps) => {
  const [onDownload, setOnDownload] = useState(false);

  const download = useCallback(async () => {
    try {
      onDownloading(true, null);
      const link = document.createElement('a');
      const response = await fetch(href);
      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(new Blob([blob]));

        link.href = url;
        link.setAttribute(
          'download',
          fileType ? `${title}.${fileType}` : title
        );

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode?.removeChild(link);
        onDownloading(false, null);
      } else {
        onDownloading(false, `${response.status}`);
      }
    } catch (error) {
      onDownloading(false, error.message);
    }
  }, [title, fileType, href, onDownloading]);

  useEffect(() => {
    if (autoDownload || onDownload) {
      download();
      setOnDownload(false);
    }
  }, [autoDownload, onDownload]);

  return (
    <a
      role="presentation"
      onClick={() => setOnDownload(true)}
      onKeyDown={() => setOnDownload(true)}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      {children}
    </a>
  );
};

export default Downloadlink;

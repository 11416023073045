import { value, ref } from '@agoy/document';
import { NoteOneTableSection } from '../../../../common';
import {
  sumAccountsUBs,
  sumNotesRowsById,
} from '../../../../common/utils/accountSumsUtil';
import { year, previousYear } from '../../../../common/utils/date-util';
import {
  field,
  ixbrlCell,
  ixbrlField,
  table,
} from '../../../../common/utils/util';
import { NoteAccountRanges } from '../types';

const MONETARY_ITEM_TYPE = 'monetaryItemType';

export const note25Accounts: NoteAccountRanges[] = [
  {
    rowId: '1',
    ranges: [[1470, 1477], [1479], [2430], [2432, 2439]],
    cellLabel: 'Aktiverade nedlagda utgifter (Fordran)',
    action: 'sumAccountsUBs',
  },
  {
    rowId: '2',
    ranges: [[1478, 2431]],
    cellLabel: 'Fakturerat belopp (Fordran)',
    action: 'sumAccountsUBs',
  },
];

export const getNote25 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null,
  documentTypeVersion = '1'
): NoteOneTableSection => {
  return {
    active: false,
    number: value(undefined),
    name: value('Pågående arbete för annans räkning'),
    table: table(
      'notes.note25.table',
      { id: 'label', label: '' },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      .addRows((rows) =>
        rows
          .addRow(
            '1',
            noteLabel('Aktiverade nedlagda utgifter (Fordran)'),
            ixbrlCell(
              ref(sumAccountsUBs('1470:1477+1479+2430+2432:2439', true)),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:PagaendeArbetenAnnansRakningOmsattningstillgangarAktiveradeUtgifter',
                contextRef: 'balans0',
                saldo: 'debit',
              }
            ),
            ixbrlCell(
              ref(
                sumAccountsUBs(
                  '1470:1477+1479+2430+2432:2439',
                  true,
                  'previousYear'
                )
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:PagaendeArbetenAnnansRakningOmsattningstillgangarAktiveradeUtgifter',
                contextRef: 'balans1',
                saldo: 'debit',
              }
            )
          )
          .addRow(
            '2',
            noteLabel('Fakturerat belopp (Fordran)'),
            ixbrlCell(ref(sumAccountsUBs('1478+2431', true)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeArbetenAnnansRakningOmsattningstillgangarFaktureradeDelbelopp',
              contextRef: 'balans0',
              saldo: 'credit',
              negateValue: true,
            }),
            ixbrlCell(ref(sumAccountsUBs('1478+2431', true, 'previousYear')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeArbetenAnnansRakningOmsattningstillgangarFaktureradeDelbelopp',
              contextRef: 'balans1',
              saldo: 'credit',
              negateValue: true,
            })
          )
          .addRow(
            'claimSum',
            noteLabel('Redovisat värde (Fordran)'),
            ixbrlCell(sumNotesRowsById(25, [1, 2], 'year'), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeArbetenAnnansRakningOmsattningstillgangar',
              contextRef: 'balans0',
              saldo: 'debit',
            }),
            ixbrlCell(sumNotesRowsById(25, [1, 2], 'previousYear'), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeArbetenAnnansRakningOmsattningstillgangar',
              contextRef: 'balans1',
              saldo: 'debit',
            })
          )
          .addRow(
            '3',
            noteLabel('Fakturerat belopp (Skuld)'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeArbetenAnnansRakningKortfristigaSkulderFaktureradeDelbelopp',
              contextRef: 'balans0',
              saldo: 'credit',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeArbetenAnnansRakningKortfristigaSkulderFaktureradeDelbelopp',
              contextRef: 'balans1',
              saldo: 'credit',
            })
          )
          .addRow(
            '4',
            noteLabel('Aktiverade nedlagda utgifter (Skuld)'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeArbetenAnnansRakningKortfristigaSkulderAktiveradeUtgifter',
              contextRef: 'balans0',
              saldo: 'debit',
              negateValue: true,
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeArbetenAnnansRakningKortfristigaSkulderAktiveradeUtgifter',
              contextRef: 'balans1',
              saldo: 'debit',
              negateValue: true,
            })
          )
          .addRow(
            'debtSum',
            noteLabel('Redovisat värde (Skuld)'),
            ixbrlCell(sumNotesRowsById(25, [3, 4], 'year'), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeArbetenAnnansRakningKortfristigaSkulder',
              contextRef: 'balans0',
              saldo: 'credit',
            }),
            ixbrlCell(sumNotesRowsById(25, [3, 4], 'previousYear'), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeArbetenAnnansRakningKortfristigaSkulder',
              contextRef: 'balans1',
              saldo: 'credit',
            })
          )
          .build()
      )
      .newRowTemplate(value(''), value(undefined), value(undefined))
      .build(),
    commentLabel: field('Kommentar'),
    comment: ixbrlField(field(''), {
      type: 'stringItemType',
      name: 'se-gen-base:NotPagaendeArbetenAnnansRakningKommentar',
      contextRef: 'period0',
    }),
    group: value(undefined),
    section: value(undefined),
    validForReportType: {
      k2: true,
      k3: documentTypeVersion === '1',
    },
  };
};

const helpStructureK10 = {
  type: 'part' as const,
  _type: 'K10' as const,
  children: {
    main: {
      type: 'part' as const,
      children: {
        rule: { type: 'field' as const },
        dividends: { type: 'field' as const },
        savedDividends: { type: 'field' as const },
        dividendTaxed: { type: 'field' as const },
        dividendCapital: { type: 'field' as const },
      },
    },
  },
};

export default helpStructureK10;

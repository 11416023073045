import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
  memo,
} from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Button, Typography, Box } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { VariableSizeList, ListChildComponentProps } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { useHistory } from 'react-router-dom';
import styled from '@emotion/styled';

import { useSelector } from 'redux/reducers';
import LoadingPlaceholder from '_shared/components/LoadingPlaceholder';
import WelcomeBackground from 'assets/background-welcome-screen.jpg';
import { getClients } from '_clients/redux/customers/actions';
import { ClientCompanyType } from '_clients/types/types';
import { getOrganisationMembers } from '_organization/redux/actions';
import config from '_shared/services/config';
import { useLicenseStatus } from '_payment/licenses/useLicense';
import { createPeriods } from 'utils';
import { Period } from 'utils/createPeriods';

import { addGlobalErrorMessage } from 'redux/actions';
import {
  getProgramStatusesForPeriods,
  PeriodStatusesType,
} from './ClientCard/LampRow/utils';
import { sortClients as sortClientsFactory, sortBySearch } from './sorting';
import ClientCard from './ClientCard';

const Wrapper = styled.div`
  height: 100%;
  overflow-y: hidden;
`;

const ClientListWrapper = styled(Box)`
  display: block;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  padding-top: 1rem;
  text-align: center;
`;

const StyledImage = styled.img`
  margin: 6rem 0 2rem 0;
  width: 40%;
`;

const StyledButton = styled(Button)`
  margin: 2rem 0;
`;

const NoClientFound = styled.div`
  display: flex;
  margin: 6rem 0 2rem 0;
  text-align: center;
  justify-content: center;
  align-items: center;
`;

interface CustomersViewProps {
  clientList: ClientCompanyType[];
  searchedClients: ClientCompanyType[];
}

const ClientListItem = memo(
  ({ data, index, style }: ListChildComponentProps) => {
    const {
      clientList,
      clientsPeriods,
      clientsProgramStatuses,
      clientsExpanded,
      onToggleExpanded,
    } = data;
    const client = clientList[index];

    const handleToggle = useCallback(() => {
      onToggleExpanded(client.id);
    }, [client, onToggleExpanded]);

    return (
      <div style={style}>
        <ClientCard
          client={client}
          periods={clientsPeriods[client.id]}
          programStatuses={clientsProgramStatuses[client.id]}
          expanded={clientsExpanded[client.id]}
          onToggleExpanded={handleToggle}
        />
      </div>
    );
  }
);

const ClientsView = ({
  clientList = [],
  searchedClients = [],
}: CustomersViewProps) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const { license } = useLicenseStatus();

  const clientsListRef = useRef<VariableSizeList>(null);

  const notFilteredClients = useSelector((state) => state.customers);
  const { organisationId, firstLogin } = useSelector((state) => state.user);
  const { search, lampsExpanded } = useSelector(
    (state) => state.customersOverview
  );

  const isFortnoxWhiteLabel = config.whiteLabelUI === 'fortnox';
  const isSearching = searchedClients.length !== 0;

  const [isLoadingCustomers, setIsLoadingCustomers] = useState(false);
  const [clientsExpanded, setClientsExpanded] = useState<{
    [clientId: string]: boolean;
  }>({});

  const [clientsPeriods, clientsProgramStatuses] = useMemo<
    [
      { [clientId: string]: Period[] },
      { [clientId: string]: { [period: string]: PeriodStatusesType } }
    ]
  >(() => {
    const periods = {};
    const programStatuses = {};

    clientList.forEach((client) => {
      const { id, financialYears, programStatus, closingMonth } = client;
      const clientPeriods = createPeriods(financialYears);
      periods[id] = clientPeriods;
      programStatuses[id] = getProgramStatusesForPeriods(
        programStatus,
        clientPeriods,
        closingMonth,
        license
      );
    });

    return [periods, programStatuses];
  }, [clientList, license]);

  useEffect(() => {
    (async () => {
      if (organisationId) {
        try {
          setIsLoadingCustomers(true);
          await dispatch(getClients());
        } catch (error) {
          dispatch(addGlobalErrorMessage('error'));
        } finally {
          setIsLoadingCustomers(false);
        }
      }
    })();
  }, [organisationId, dispatch]);

  useEffect(() => {
    (async () => {
      if (organisationId) {
        try {
          await dispatch(getOrganisationMembers());
        } catch (error) {
          dispatch(addGlobalErrorMessage('error'));
        }
      }
    })();
  }, [dispatch, organisationId]);

  useEffect(() => {
    setClientsExpanded((currentValue) => {
      const clients = isSearching ? searchedClients : clientList;
      const newClientsExpanded = { ...currentValue };

      clients.forEach((client) => {
        newClientsExpanded[client.id] = lampsExpanded;
      });

      return newClientsExpanded;
    });

    clientsListRef.current?.resetAfterIndex(0);
  }, [clientList, isSearching, lampsExpanded, searchedClients]);

  const createCustomer = useCallback(() => {
    history.push(`/clients/create`);
  }, [history]);

  const handleToggleExpanded = useCallback((clientId: string) => {
    setClientsExpanded((currentValue) => {
      const newClientsExpanded = { ...currentValue };

      newClientsExpanded[clientId] = !newClientsExpanded[clientId];
      return newClientsExpanded;
    });

    clientsListRef.current?.resetAfterIndex(0);
  }, []);

  const getItemSize = (index: number) => {
    const clients = isSearching ? searchedClients : clientList;
    const client = clients[index];

    const defaultSize = clientsExpanded[client.id] ? 200 : 160;

    return index === clients.length - 1 ? defaultSize + 40 : defaultSize;
  };

  if (isLoadingCustomers) {
    return (
      <Wrapper>
        <LoadingPlaceholder />
      </Wrapper>
    );
  }

  if (!notFilteredClients || Object.keys(notFilteredClients).length === 0) {
    return (
      <Wrapper>
        <ClientListWrapper>
          <StyledImage src={WelcomeBackground} alt="Background-image" />
          <Typography component="div">
            <Box fontWeight="fontWeightBold">
              {formatMessage({
                id: isFortnoxWhiteLabel
                  ? 'welcome.page.fortnox'
                  : 'welcome.page',
              })}
            </Box>
          </Typography>

          {!isFortnoxWhiteLabel && (
            <>
              <Box>
                <Typography>
                  {formatMessage({ id: 'welcome.trial' })}
                </Typography>
              </Box>
              <StyledButton
                startIcon={<AddCircleOutlineIcon />}
                onClick={createCustomer}
                color="secondary"
                variant="contained"
                id="onboarding-add-customer"
              >
                {formatMessage({ id: 'add.first.customer' })}
              </StyledButton>
            </>
          )}
          {/* Inject a div in order to display the onboarding tour#1 when div is found by Usetiful */}
          {firstLogin && <div id="EMPTY_CUSTOMER_TOUR_ID" />}
        </ClientListWrapper>
      </Wrapper>
    );
  }

  if (
    (search !== '' && searchedClients.length === 0) ||
    clientList.length === 0
  ) {
    return (
      <Wrapper>
        <NoClientFound>
          <Typography variant="h3">
            {formatMessage({
              id: 'dashboard.customers.search.noResults',
            })}
          </Typography>
        </NoClientFound>
      </Wrapper>
    );
  }

  return (
    <Wrapper id="customer-card-container" data-cy="customer-card-container">
      <AutoSizer>
        {({ height, width }) => (
          <VariableSizeList
            itemCount={isSearching ? searchedClients.length : clientList.length}
            itemData={{
              clientList: isSearching ? searchedClients : clientList,
              clientsPeriods,
              clientsProgramStatuses,
              clientsExpanded,
              onToggleExpanded: handleToggleExpanded,
            }}
            itemSize={getItemSize}
            width={width}
            height={height}
            ref={clientsListRef}
          >
            {ClientListItem}
          </VariableSizeList>
        )}
      </AutoSizer>
      {/* Inject a div in order to display the onboarding tour#2 when div is found by Usetiful */}
      {firstLogin && <div id="EXISTING_CUSTOMERS_TOUR_ID" />}
    </Wrapper>
  );
};

// Since refetch's error handling is flawed, retrying is done by unmounting/mounting the view
const CustomersViewWrapper = () => {
  const userEmail = useSelector((state) => state.user.email);
  const clients = useSelector((state) => state.customers);
  const users = useSelector((state) => state.organisation.users);
  const {
    search,
    hideCleanClients,
    sortType,
    filters,
    activeClientTags,
    activeClientAssignees,
  } = useSelector((state) => state.customersOverview);

  const [clientsList, setClientsList] = useState<ClientCompanyType[]>([]);
  const [searchedCustomers, setSearchedCustomers] = useState<
    ClientCompanyType[]
  >([]);
  const sortClients = useMemo(() => sortClientsFactory(users), [users]);

  useEffect(() => {
    setClientsList(
      sortClients({
        clients,
        sortedByType: sortType,
        userEmail,
        hideClean: hideCleanClients,
        activeClientTags,
        activeClientAssignees,
        filters,
      })
    );
  }, [
    clients,
    sortType,
    userEmail,
    hideCleanClients,
    filters,
    sortClients,
    activeClientAssignees,
    activeClientTags,
  ]);

  useEffect(() => {
    setSearchedCustomers(
      sortBySearch({
        customers: clients,
        search,
        hideClean: hideCleanClients,
        filters,
        activeClientTags,
        activeClientAssignees,
      })
    );
  }, [
    clients,
    search,
    hideCleanClients,
    filters,
    activeClientTags,
    activeClientAssignees,
  ]);

  return (
    <ClientsView clientList={clientsList} searchedClients={searchedCustomers} />
  );
};

export default CustomersViewWrapper;

import React from 'react';
import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';

const Container = styled.div`
  display: block;
  width: 100%;
`;

const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const HeaderInfo = styled.div`
  margin: 0;
`;

const PrintInfo = styled(Typography)`
  margin: 0;
`;

const Title = styled(Typography)`
  margin: 0;
  font-weight: bold;
`;

type HeaderProps = {
  title: string;
  headerData: {
    customerName: string;
    customerNumber: string;
  };
  printInfo: {
    title: string;
    period: string;
  };
};

const Header = ({ title, headerData, printInfo }: HeaderProps): JSX.Element => {
  return (
    <Container>
      <HeaderContent>
        <HeaderInfo>
          <Typography variant="body2">{headerData.customerName}</Typography>
          <Typography variant="body2">{headerData.customerNumber}</Typography>
        </HeaderInfo>
        <Title variant="h3">{title}</Title>
        <PrintInfo>
          <Typography variant="body2">{printInfo.title}</Typography>
          <Typography variant="body2">{printInfo.period}</Typography>
        </PrintInfo>
      </HeaderContent>
    </Container>
  );
};

export default Header;

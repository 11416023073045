import {
  FinancialReportStructure,
  FinancialReportCustomerType,
} from '../../types';

import { field } from '@agoy/annual-report-document';
import { parseFormat } from '@agoy/dates';

export const frontPageConfig = (
  customer: FinancialReportCustomerType,
  period: string,
  isSinglePeriod: boolean
): FinancialReportStructure['frontPage'] => {
  const periodTitle = parseFormat(period, 'MMM-yy').toLowerCase();
  return {
    section: {
      active: true,
      title: field(
        `${
          isSinglePeriod ? 'Periodrapport' : 'Årsrapport'
        } ${periodTitle} för ${customer.name}`
      ),
      clientName: field(customer.name),
    },
  };
};

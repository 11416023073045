import React from 'react';
import { Grid } from '@material-ui/core';
import {
  Controller,
  Control,
  FieldError,
  FieldValues,
  UnpackNestedValue,
} from 'react-hook-form';
import { SimplifiedSelect } from '_shared/components/Inputs';
import FormError from '_shared/components/Inputs/FormError';
import { useIntl } from 'react-intl';
import { PrivatePersonWithManager } from '_person/_types/person';

const FIELD_NAME = 'manager';

type Props = {
  control: Control<PrivatePersonWithManager>;
  fieldError?: FieldError;
  isEditing?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  labelPlacement?: 'top' | 'left';
  getValues: () => UnpackNestedValue<FieldValues>;
  list: { value: string; label: string }[];
};

const ContactPersonPicker = (props: Props) => {
  const { formatMessage } = useIntl();

  const {
    control,
    fieldError,
    isEditing,
    labelPlacement = 'top',
    fullWidth,
    disabled,
    getValues,
  } = props;

  return (
    <Grid item sm={fullWidth ? 10 : 6} xs={12}>
      <Controller
        control={control}
        name={FIELD_NAME}
        render={({ field: { onChange } }) => (
          <SimplifiedSelect
            width={fullWidth ? 400 : undefined}
            editing={isEditing}
            labelPlacement={labelPlacement}
            label={formatMessage({
              id: `person.form.${FIELD_NAME}`,
            })}
            listItems={props.list}
            value={getValues()?.manager || ''}
            onChange={onChange}
            onSelect={onChange}
            disabled={disabled}
          />
        )}
      />
      <FormError>
        {!!fieldError && formatMessage({ id: 'form.fieldRequired' })}
      </FormError>
    </Grid>
  );
};

export default ContactPersonPicker;

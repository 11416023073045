import React from 'react';
import EditIcon from '@material-ui/icons/Edit';
import { useIntl } from 'react-intl';
import styled from '@emotion/styled';
import { IconButton, Tooltip } from '@material-ui/core';

const StyledIcon = styled(IconButton)`
  ${(props) =>
    props.color === 'primary'
      ? `color: ${props.theme.palette.success.main}`
      : ''};
`;

interface EditingIconButtonProps {
  className?: string;
  value: boolean;
  onClick: () => void;
}

const EditingIconButton = ({
  className,
  value,
  onClick,
}: EditingIconButtonProps): JSX.Element => {
  const { formatMessage } = useIntl();

  return (
    <Tooltip
      title={
        !value
          ? formatMessage({ id: 'edit' })
          : formatMessage({ id: 'stop.edit' })
      }
    >
      <StyledIcon
        className={className}
        onClick={onClick}
        color={value ? 'primary' : undefined}
      >
        <EditIcon />
      </StyledIcon>
    </Tooltip>
  );
};

export default EditingIconButton;

import { AnnualReportPart, AnnualReportSection } from '../../document';
import { value, AgoyTable, AgoyTableRow, ref, or, id } from '@agoy/document';
import { mapRecord } from '@agoy/common';
import { IxbrlCell } from '../../common';
import { TableBuilder, ixbrlCell, table } from '../../common/utils/util';

const ixbrlElementName = {
  givenName: 'se-gen-base:UnderskriftHandlingTilltalsnamn',
  surName: 'se-gen-base:UnderskriftHandlingEfternamn',
  role: 'se-gen-base:UnderskriftHandlingRoll',
  date: 'se-gen-base:UndertecknandeDatum',
};

const boolean = { type: 'boolean' as const };

export interface AnnualGeneralMeetingSignaturesSection
  extends AnnualReportSection {
  people: AgoyTable;
}

export interface AnnualGeneralMeetingSignatures extends AnnualReportPart {
  section: AnnualGeneralMeetingSignaturesSection;
}

export const annualGeneralMeetingSignatures = {
  type: 'part' as const,
  children: {
    active: boolean,
    section: {
      type: 'part' as const,
      children: {
        active: boolean,
        people: { type: 'table' as const },
      },
    },
  },
};

const addNewRowSignaturesTemplate = (
  rowBuilder: TableBuilder<IxbrlCell>
): TableBuilder<IxbrlCell> => {
  return rowBuilder.newRowTemplateGenerator(
    (rowId, parentId): AgoyTableRow<IxbrlCell> => ({
      active: true,
      id: rowId,
      cells: ['givenName', 'surName', 'role', 'phoneNumber', 'email'].reduce(
        (cells, key) => {
          const cell = ref(
            or(
              ...['edit', 'signing'].map((subRowId) =>
                id(`${parentId}.${rowId}.${subRowId}.${key}`)
              )
            )
          );
          return {
            ...cells,
            [key]: ixbrlElementName[key]
              ? ixbrlCell(cell, {
                  type: 'stringItemType',
                  name: ixbrlElementName[key],
                  contextRef: 'period0',
                })
              : cell,
          };
        },
        {}
      ),
      rows: [],
      newRowTemplate: (
        subRowId,
        parentRowId,
        params
      ): AgoyTableRow<IxbrlCell> => ({
        id: subRowId,
        active: true,
        cells: mapRecord(params, (cell) => value(cell)),
      }),
    })
  );
};

export const annualGeneralMeetingSignaturesConfig =
  (): AnnualGeneralMeetingSignatures => ({
    active: undefined,
    section: {
      active: undefined,
      people: addNewRowSignaturesTemplate(
        table<IxbrlCell>(
          'section.people',
          'givenName',
          'surName',
          'role',
          'phoneNumber',
          'email'
        )
      ).build(),
    },
  });

export const signaturesReferences = (): Record<string, string> => ({});

import React, { createContext, useCallback, useState } from 'react';
import { asResultClass, useApiSdk } from 'api-sdk';

type SeriesNextNumbers = { [series: string]: number };

interface VoucherNumber {
  seriesNextNumbers: SeriesNextNumbers;
  onChangeSeriesNextNumber: (series: string, number: number) => void;
  getSeriesNextNumber: (series: string) => Promise<number>;
}

const VoucherLastNumberContext = createContext<VoucherNumber>({
  seriesNextNumbers: {},
  onChangeSeriesNextNumber: () => {},
  getSeriesNextNumber: async () => 1,
});

type ProviderProps = {
  clientId: string;
  financialYearId: number;
  children: JSX.Element;
};

export const VoucherLastNumberContextProvider = ({
  clientId,
  financialYearId,
  children,
}: ProviderProps): JSX.Element | null => {
  const sdk = useApiSdk();

  const [seriesNextNumbers, setSeriesNextNumbers] = useState<SeriesNextNumbers>(
    {}
  );

  const onChangeSeriesNextNumber = useCallback(
    (series: string, number: number) => {
      setSeriesNextNumbers((currentValue) => ({
        ...currentValue,
        [series]: number,
      }));
    },
    []
  );

  const getSeriesNextNumber = useCallback(
    async (series: string) => {
      if (!seriesNextNumbers[series]) {
        const result = await asResultClass(
          sdk.getVoucherNextNumber({
            clientid: clientId,
            financialYearId,
            series,
          })
        );

        let next = 1;
        if (result.ok) {
          next = result.val.nextNumber || 1;
        }

        setSeriesNextNumbers((currentValue) => ({
          ...currentValue,
          [series]: next,
        }));

        return next;
      }

      return seriesNextNumbers[series];
    },
    [clientId, financialYearId, sdk, seriesNextNumbers]
  );

  return (
    <VoucherLastNumberContext.Provider
      value={{
        seriesNextNumbers,
        onChangeSeriesNextNumber,
        getSeriesNextNumber,
      }}
    >
      {children}
    </VoucherLastNumberContext.Provider>
  );
};

export default VoucherLastNumberContext;

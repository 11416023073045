import { ToggleFinancialReportSettingsParameter } from '../../action-type-declarations';
import { FinancialReportClientPeriodState } from '../types';

const toggleFinancialReportSettingsParameter = (
  state: FinancialReportClientPeriodState,
  action: ToggleFinancialReportSettingsParameter
): FinancialReportClientPeriodState => {
  const { id } = action;

  if (!state.report) {
    return state;
  }

  return {
    ...state,
    report: {
      ...state.report,
      settings: {
        ...state.report.settings,
        section: {
          ...state.report.settings.section,
          [id]: !state.report.settings.section[id],
        },
      },
    },

    changes: {
      ...state.changes,
      settings: {
        ...state.changes.settings,
        section: {
          ...state.changes.settings?.section,
          [id]: !state.report.settings.section[id],
        },
      },
    },
  };
};

export default toggleFinancialReportSettingsParameter;

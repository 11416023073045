const helpStructureK5 = {
  type: 'part' as const,
  _type: 'K5' as const,
  children: {
    improvements: { type: 'table' as const },
    renovation: { type: 'table' as const },
    postponementAmount: { type: 'table' as const },
    postponementHelper: { type: 'table' as const },
  },
};

export default helpStructureK5;

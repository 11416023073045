import { value } from '@agoy/document';
import { NoteOneTableSection, IxbrlCell } from '../../../../common';
import { year, previousYear } from '../../../../common/utils/date-util';
import {
  field,
  ixbrlCell,
  ixbrlField,
  table,
} from '../../../../common/utils/util';

const MONETARY_ITEM_TYPE = 'monetaryItemType';

export const getNote27 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null,
  documentTypeVersion = '1'
): NoteOneTableSection => {
  return {
    active: false,
    number: value(undefined),
    name: value('Långfristiga skulder'),
    table: table<IxbrlCell>(
      'notes.note27.table',
      { id: 'label', label: '' },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      },
      'hidden'
    )
      // no account prefilling in note27
      .addRows((rows) =>
        rows
          .addRow(
            '1',
            noteLabel(
              'Belopp på skuldpost som förfaller till betalning senare än fem år efter balansdagen'
            ),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:LangfristigaSkulderForfallerSenare5Ar',
              contextRef: 'balans0',
              saldo: 'credit',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:LangfristigaSkulderForfallerSenare5Ar',
              contextRef: 'balans1',
              saldo: 'credit',
            })
          )
          .build()
      )
      .newRowTemplate(value(''), value(undefined), value(undefined))
      .build(),
    commentLabel: field('Kommentar'),
    comment: ixbrlField(field(''), {
      type: 'stringItemType',
      name: 'se-gen-base:NotLangfristigaSkulderKommentar',
      contextRef: 'balans0',
    }),
    group: value(undefined),
    section: value(undefined),
    validForReportType: {
      k2: true,
      k3: documentTypeVersion === '1',
    },
  };
};

import React from 'react';
import { useIntl } from 'react-intl';
import { Typography as MuiTypography } from '@material-ui/core';
import styled from '@emotion/styled';
import { CommonNotes } from '@agoy/annual-report-document';
import Paragraph from './Paragraph';

const Section = styled.div`
  margin: 0.25rem 0;
  font-size: ${({ theme }) => theme.typography.body1.fontSize};
`;

const SectionTitle = styled.div`
  display: flex;
  justify-content: space-between;
`;

type IncomeTaxSectionProps = {
  note1: CommonNotes['note1'];
  id: string;
  title?: string;
};

const IncomeTaxSection = (props: IncomeTaxSectionProps): JSX.Element => {
  const { note1, id, title } = props;
  const { formatMessage } = useIntl();

  const active =
    [
      'incomeTaxParagraph1',
      'incomeTaxParagraph2',
      'incomeTaxParagraph3',
      'incomeTaxParagraph4',
      'incomeTaxParagraph5',
    ].find((paragraphId) => note1[paragraphId].active) !== undefined;

  return (
    <Section>
      <SectionTitle>
        {!active ? null : (
          <MuiTypography variant="h4">
            {title ||
              formatMessage({
                id: `annualReport.notes.note1.${id}`,
              })}
          </MuiTypography>
        )}
      </SectionTitle>
      <Paragraph note1={note1} id="incomeTaxParagraph1" />
      <Paragraph note1={note1} id="incomeTaxParagraph2" />
      <Paragraph note1={note1} id="incomeTaxParagraph3" />
      <Paragraph note1={note1} id="incomeTaxParagraph4" />
      <Paragraph note1={note1} id="incomeTaxParagraph5" />
    </Section>
  );
};

export default IncomeTaxSection;

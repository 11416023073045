import { ResolvedReference, updateValues } from '@agoy/document';
import {
  AnnualReport,
  AnnualReportType,
  getContentDefinition,
} from '../config/document';

const updateReportValues = (
  config: AnnualReport,
  references: Record<string, ResolvedReference>,
  reportType: AnnualReportType
): AnnualReport => {
  const definition = getContentDefinition(config, reportType);
  const apply = updateValues(definition);

  return apply(config, references);
};

export default updateReportValues;

import React, { useCallback, useMemo } from 'react';

/**
 * Formatting Context, manages the formatting of numbers.
 *
 */
export type FormattingContextType = {
  /**
   * If true, numbers are displayed in thousands
   *
   */
  displayInThousands: boolean;
  /**
   * If set, labels get a suffix with the value of this property surrounded by parenthesis
   *
   */
  labelDisplayInThousands: boolean | undefined;
  /**
   * Returns the formatted number if displayInThousands is true, otherwise returns the value
   * type is needed to determine the number format
   *
   * @param value
   * @param type
   */
  formatNumber: (value: number | undefined, type: string) => number | undefined;
  /**
   * Returns the label if labelDisplayInThousands is set, otherwise returns null
   * type is needed to determine the number format
   *
   * @param type
   */
  shouldPrintLabel: () => boolean;
  /**
   * This is used only for debugging purposes.
   */
  fallbackContext: boolean;
};

const FormattingContext = React.createContext<FormattingContextType>({
  displayInThousands: false,
  labelDisplayInThousands: false,
  formatNumber: (
    value: number | undefined,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    type: string
  ): number | undefined => value,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  shouldPrintLabel: (): boolean => false,
  fallbackContext: true,
});

interface FormattingContextProviderProps {
  displayInThousands: boolean;
  labelDisplayInThousands?: boolean;
  children: JSX.Element | JSX.Element[];
}

export const FormattingContextProvider = ({
  displayInThousands = false,
  labelDisplayInThousands = false,
  children,
}: FormattingContextProviderProps): JSX.Element => {
  const formatNumber = useCallback(
    (value: number | undefined, type: string): number | undefined => {
      if (
        type === 'percentage' ||
        type === 'plain-integer' ||
        type === 'plain-integer-formatted'
      ) {
        return value;
      }

      if (typeof value !== 'number') {
        return undefined;
      }

      if (!displayInThousands) {
        return value;
      }

      return value / 1000;
    },
    [displayInThousands]
  );

  const shouldPrintLabel = useCallback(() => {
    return labelDisplayInThousands;
  }, [labelDisplayInThousands, displayInThousands]);

  const contextValue = useMemo(() => {
    return {
      displayInThousands,
      labelDisplayInThousands,
      formatNumber,
      shouldPrintLabel,
      fallbackContext: false,
    };
  }, [
    displayInThousands,
    labelDisplayInThousands,
    formatNumber,
    shouldPrintLabel,
  ]);

  return (
    <FormattingContext.Provider value={contextValue}>
      {children}
    </FormattingContext.Provider>
  );
};

export default FormattingContext;

import React from 'react';
import { Cell as CellType } from '@agoy/document';
import NoteSelector from './Field/NoteSelector';
import { NumberFormatType, StandardNumberFormatType } from '@agoy/common';
import NonReferenceCell from './NonReferenceCell';
import ReferenceCell from './ReferenceCell';

interface CellProps {
  className?: string;
  cell: CellType;
  numberFormatType?: NumberFormatType;
  id?: string;
  active?: boolean;
  isXBRLRender?: boolean;
}

const Cell = ({
  className,
  cell,
  numberFormatType = StandardNumberFormatType,
  id,
  active = true,
  isXBRLRender = false,
}: CellProps): JSX.Element | null => {
  if (!cell) {
    return null;
  }

  if (cell.type === 'refs') {
    return <NoteSelector className={className} cell={cell} />;
  }

  if (cell.type === 'ref') {
    const { value } = cell;
    return (
      <ReferenceCell
        id={id}
        value={value}
        className={className}
        numberFormatType={numberFormatType}
        active={active}
        cell={cell}
      />
    );
  }

  return (
    <NonReferenceCell
      id={id}
      className={className}
      cell={cell}
      numberFormatType={numberFormatType}
      active={active}
      isXBRLRender={isXBRLRender}
    />
  );
};

export default Cell;

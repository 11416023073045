import { RefObject, useEffect, useRef } from 'react';

const useAutosizeTextArea = (value: any): RefObject<HTMLTextAreaElement> => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    const textArea = textAreaRef?.current;
    if (textArea) {
      textArea.style.height = '0px';
      const { scrollHeight } = textArea;
      textArea.style.height = `${scrollHeight - (scrollHeight % 21)}px`;
    }
  }, [textAreaRef, value]);

  return textAreaRef;
};

export default useAutosizeTextArea;

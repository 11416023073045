import React from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, useRouteMatch } from 'react-router-dom';
import { addGlobalErrorMessage } from 'redux/actions';

import { ClientsRouteParams } from 'routes/types';
import { AgoyPrograms } from 'types';
import LicenseErrorPage from '_payment/components/pages/LicenseError';
import LoadingPlaceholder from '_shared/components/LoadingPlaceholder';
import { useLicenseStatus } from './useLicense';

const PROGRAMS_AVSTAMNING: AgoyPrograms[] = [
  'reconciliation',
  'financialreport',
];

/**
 * Checks what license a user has and redirect to home if the user
 * tries accessing the url of a program not included in their plan.
 * */
const withLicenseRouteGuard =
  (Component: React.FC) =>
  (props: Record<string, unknown>): JSX.Element => {
    const {
      params: { program },
    } = useRouteMatch<ClientsRouteParams>();
    const { license } = useLicenseStatus();
    const dispatch = useDispatch();

    if (license === null) {
      return <LoadingPlaceholder />;
    }

    if (license === 'none') {
      return <LicenseErrorPage />;
    }

    if (license === 'small' && !PROGRAMS_AVSTAMNING.includes(program)) {
      dispatch(addGlobalErrorMessage('license.missing.redirect'));

      return <Redirect to="/" />;
    }

    return <Component {...props} />;
  };

export default withLicenseRouteGuard;

import React, { useContext } from 'react';
import { Cell as CellType } from '@agoy/document';
import { NumberFormatType, StandardNumberFormatType } from '@agoy/common';
import NoteSelector from './Field/NoteSelector';
import NonReferenceCell from './NonReferenceCell';
import ReferenceCell from './ReferenceCell';
import MessageCell from './MessageCell';
import OptionSelector from './Field/OptionSelector';
import CheckboxSelector from './Field/CheckboxSelector';
import NotesContext from '../Context/NotesContext';

interface CellProps {
  className?: string;
  id: string;
  cell: CellType;
  editing?: boolean;
  numberFormatType?: NumberFormatType;
  active?: boolean;
  isLocked?: boolean;
  placeholder?: string;
  onBlur?: (newValue: string) => void;
  warning?: string;
  /**
   * The underlying components assume that if the id has more than 2 parts, it is a table cell
   * and uses updateCellValue otherwise updateField.
   * Setting this to true forces it to use updateField and false to updateCellValue without checking the id.
   */
  isField?: boolean;
  accountRangeTooltip?: string;
  onValueChange?: (key: string, newValue: string) => void;
}

const Cell = ({
  className,
  id,
  cell,
  editing,
  numberFormatType = StandardNumberFormatType,
  active = true,
  isLocked = false,
  placeholder,
  isField,
  accountRangeTooltip,
  onBlur,
  warning,
  onValueChange,
}: CellProps): JSX.Element | null => {
  if (!cell) {
    return null;
  }

  if (cell.type === 'string' && 'format' in cell) {
    return (
      <OptionSelector
        className={className}
        cell={cell}
        editing={editing}
        cellId={id}
        active={active}
        onValueChange={onValueChange}
      />
    );
  }

  if (cell.type === 'refs') {
    return (
      <NoteSelector
        className={className}
        cell={cell}
        editing={editing}
        cellId={id}
        active={active}
      />
    );
  }

  if (cell.type === 'ref') {
    const { value } = cell;
    return (
      <ReferenceCell
        value={value}
        className={className}
        id={id}
        editing={editing}
        numberFormatType={numberFormatType}
        active={active}
        warning={warning}
        isField={isField}
      />
    );
  }

  if (cell.type === 'msg') {
    return (
      <MessageCell message={cell.message} parameters={cell.parameterValues} />
    );
  }

  if (cell.type === 'boolean') {
    return (
      <CheckboxSelector
        className={className}
        cell={cell}
        editing={editing}
        cellId={id}
        active={active}
      />
    );
  }

  return (
    <NonReferenceCell
      className={className}
      id={id}
      cell={cell}
      editing={editing}
      numberFormatType={numberFormatType}
      active={active}
      isLocked={isLocked}
      onBlur={onBlur}
      placeholder={placeholder}
      warning={warning}
      isField={isField}
      accountRangeTooltip={accountRangeTooltip}
    />
  );
};

export default Cell;

import React from 'react';
import { Typography, TextField } from '@material-ui/core';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';
import EditField from '_shared/components/Inputs/EditField';

const Location = styled.div`
  margin: ${(props) => props.theme.spacing(2)}px 0;
`;

const TextFieldBase = (props) => {
  return <TextField variant="outlined" size="small" {...props} />;
};

interface LocationFieldProps {
  value: string;
  onChange: (value: string) => void;
}

const LocationField = ({
  value,
  onChange,
}: LocationFieldProps): JSX.Element => {
  const { formatMessage } = useIntl();

  return (
    <Location>
      <Typography>
        {formatMessage({ id: 'annualReport.signatures.section.location' })}
      </Typography>
      <EditField component={TextFieldBase} onChange={onChange} value={value} />
    </Location>
  );
};

export default LocationField;

import { Typography } from '@material-ui/core';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { format } from '@agoy/dates';
import { groupBy } from 'lodash';
import PeriodDataContext from '_reconciliation/components/ReconciliationView/HiddenRow/Rows/PeriodDataContext';
import CommentsSection from '_shared/components/Comments/CommentsSection';
import styled from '@emotion/styled';
import useComments from '_shared/hooks/useComments';
import { Program, Comment } from '_shared/types';
import { useDispatch } from 'react-redux';
import { useSelector } from 'redux/reducers';
import { addGlobalErrorMessage } from 'redux/actions';
import Button from '_shared/components/Buttons/Button';
import { asResultClass, useApiSdk } from 'api-sdk';

import { HiddenGroupRow as HiddenGroupRowType } from '../RowContext/types';
import { getNotCopiedComments } from './Rows/EventView/InternalComments';

const InternalCommentsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Container = styled.div`
  width: 650px;
`;

const ControlsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing(0.5)}px;
`;

type GropedAccountsCommentsProps = {
  row: HiddenGroupRowType;
  clientId: string;
};

const GroupedInternalComments = ({
  clientId,
  row,
}: GropedAccountsCommentsProps) => {
  const { formatMessage } = useIntl();
  const { addComments } = useApiSdk();
  const dispatch = useDispatch();

  const {
    nextPeriod,
    previousPeriod,
    previousPeriodFinancialYear,
    financialYear,
    periodLocked,
    nextPeriodLocked,
    nextPeriodFinancialYear,
    periodType,
    period,
  } = useContext(PeriodDataContext);

  const periodId = period.id;
  const userId = useSelector((state) => state.user['custom:userId']);

  const groupId = row.balance.id.split('.')[0];
  const key = `group.${groupId}.internal`;
  const financialYearId = financialYear.id;
  const program: Program = 'RECONCILIATION';

  const {
    comments: internalComments,
    createComment,
    loading: loadingComments,
    reloadComments: reloadInternalComments,
    savingNewComment,
  } = useComments(clientId, program, financialYearId, periodId, key);

  const {
    comments: previousPeriodComments,
    loading: loadingPreview,
    reloadComments: reloadPreviousPeriodComments,
  } = useComments(
    clientId,
    program,
    financialYearId,
    previousPeriod ? previousPeriod.id : undefined,
    key
  );

  const {
    comments: nextPeriodComments,
    loading: loadingNextPeriodComments,
    reloadComments: reloadNextPeriodComments,
  } = useComments(
    clientId,
    program,
    financialYearId,
    nextPeriod ? nextPeriod.id : undefined,
    key
  );

  const previousPeriodFormat = periodType === 'quarter' ? 'QQQ' : 'MMM-yy';

  const notCopiedFromPrevious = useMemo(
    () => getNotCopiedComments(previousPeriodComments, internalComments),
    [internalComments, previousPeriodComments]
  );

  const notCopiedToNext = useMemo(
    () => getNotCopiedComments(internalComments, nextPeriodComments),
    [internalComments, nextPeriodComments]
  );

  useEffect(() => {
    if (internalComments.length === 0 && notCopiedFromPrevious.length > 0) {
      setShowPreview(true);
    }
  }, [internalComments.length, notCopiedFromPrevious.length]);

  const notCopiedComments = useMemo(() => {
    // Get a comment that hasn't been copied yet
    const copiedCommentsOriginals = internalComments
      .filter((comment) => !!comment.copiedFrom?.id)
      .map((comment) => comment.copiedFrom?.id);
    return previousPeriodComments.filter(
      (comment) => !copiedCommentsOriginals.includes(comment.id)
    );
  }, [internalComments, previousPeriodComments]);

  const [showPreview, setShowPreview] = useState(
    internalComments.length === 0 && notCopiedComments.length > 0
  );

  useEffect(() => {
    if (internalComments.length === 0 && notCopiedComments.length) {
      setShowPreview(true);
    }
  }, [internalComments.length, notCopiedComments.length]);

  const internalCommentsPreview =
    previousPeriod && notCopiedComments.length
      ? {
          comments: notCopiedComments,
          title: formatMessage(
            { id: 'comments.reconciliation.preview' },
            {
              period: format(
                new Date(previousPeriod.start),
                previousPeriodFormat
              ),
            }
          ),
        }
      : undefined;

  const commentsGroups = groupBy(internalComments, (comment) =>
    comment.copiedFrom ? 'copiedFromPrevious' : 'native'
  );

  const copiedComments = useMemo(() => {
    if (!commentsGroups.copiedFromPrevious) {
      return undefined;
    }

    const commentWithInfo = commentsGroups.copiedFromPrevious[0];

    return {
      title: formatMessage(
        { id: 'comments.reconciliation.copied' },
        {
          period: previousPeriod
            ? format(new Date(previousPeriod.start), previousPeriodFormat)
            : '',
        }
      ),
      comments: commentsGroups.copiedFromPrevious,
      additionalInfo: formatMessage(
        { id: 'comments.reconciliation.copiedBy' },
        {
          userName: commentWithInfo?.userDisplayName || '',
          timestamp: format(
            new Date(commentWithInfo.createdAt),
            'yyyy-MM-dd HH:mm'
          ),
        }
      ),
    };
  }, [commentsGroups.copiedFromPrevious, formatMessage, previousPeriod]);

  const copyComments = async (
    commentsToCopy: Comment[],
    newPeriodId: number,
    afterCopy: () => Promise<void>
  ) => {
    if (!commentsToCopy.length) {
      return;
    }
    const newComments = commentsToCopy.map((oldComment) => ({
      userId,
      program,
      financialYearId,
      periodId: newPeriodId,
      key,
      comment: oldComment.comment,
      copiedFromId: oldComment.id,
    }));

    const addResult = await asResultClass(
      addComments({ clientId, requestBody: newComments })
    );

    if (addResult.err) {
      dispatch(addGlobalErrorMessage('comments.failed.sending'));
      return;
    }

    await afterCopy();
  };

  const copyFromPrevious = () =>
    copyComments(notCopiedFromPrevious, periodId, async () => {
      reloadInternalComments();
      reloadPreviousPeriodComments();
      setShowPreview(false);
    });

  const copyToNext = () =>
    nextPeriod &&
    copyComments(notCopiedToNext, nextPeriod.id, async () => {
      reloadNextPeriodComments();
    });

  const getButtonName = (id: string) =>
    formatMessage({ id: `comments.copy.${id}` });

  return (
    <Container>
      <Typography variant="body1" color="textSecondary">
        {formatMessage({ id: 'comments.internalComments.title' })}
      </Typography>
      <InternalCommentsContainer>
        <CommentsSection
          disabled={periodLocked}
          multiline
          comments={commentsGroups.native ?? []}
          addComment={createComment}
          loading={
            loadingComments || loadingPreview || loadingNextPeriodComments
          }
          savingNewComment={savingNewComment}
          showPreview={showPreview}
          preview={internalCommentsPreview}
          subsections={copiedComments ? [copiedComments] : undefined}
        />
      </InternalCommentsContainer>
      {(!!internalComments.length || !!notCopiedFromPrevious.length) && (
        <ControlsContainer>
          {previousPeriod &&
            financialYear.id === previousPeriodFinancialYear?.id &&
            period.type !== 'year_end' &&
            !loadingComments &&
            !loadingPreview && (
              <Button
                label={getButtonName('fetchFromPrevious')}
                variant="text"
                size="small"
                disabled={notCopiedComments.length === 0 || periodLocked}
                onClick={copyFromPrevious}
                onMouseEnter={() =>
                  internalCommentsPreview && setShowPreview(true)
                }
                onMouseLeave={() =>
                  internalComments.length > 0 && setShowPreview(false)
                }
              />
            )}
          {nextPeriod &&
            nextPeriod.type !== 'year_end' &&
            financialYear.id === nextPeriodFinancialYear?.id &&
            !loadingComments && (
              <Button
                label={getButtonName('copyToNextPeriod')}
                variant="text"
                size="small"
                onClick={copyToNext}
                disabled={notCopiedToNext.length === 0 || !!nextPeriodLocked}
              />
            )}
        </ControlsContainer>
      )}
    </Container>
  );
};

export default GroupedInternalComments;

import arrayBufferToBuffer from 'utils/ArrayBuffer';

/**
 * Validate results from reading file
 * @param reader
 * @throws
 */
const validateResult = (result: ArrayBuffer | string | null) => {
  if (result === null) {
    throw new Error('Read not complete or unsuccessful');
  }
  if (typeof result === 'string') {
    throw new Error('No ArrayBuffer');
  }
};

/**
 * Get the buffer from the reader after validating
 * @param reader
 */
export const getBuffer = (reader: FileReader): Buffer => {
  validateResult(reader.result);
  return arrayBufferToBuffer(reader.result);
};

import {
  TaxDeclarationFormDefinition,
  TaxDeclarationFormDataConfig,
  CompanyMetaData,
  helpStructureINK3,
} from '../../types';
import {
  id,
  sum,
  or,
  ref,
  value,
  sumAllowNull,
  table,
  ifOrElse,
  multiply,
  div,
  abs,
  max,
} from '@agoy/document';
import { differenceInMonths } from 'date-fns';
import { parse } from '@agoy/dates';
import {
  mapFormId,
  balanceSheetRef as balanceSheetUtilRef,
  incomeStatementRef as incomeStatementUtilRef,
  negative,
  onlyNegative,
  onlyPositive,
  toConfig,
  toInitial,
  toStructure,
} from '../forms-util';
import { financialYearToSKVPeriod } from '../../skvPeriod';
import { finalTaxCalculations, getFinalCalculationsMetadata } from '../shared';

const balanceSheetRef = (reference: string) =>
  balanceSheetUtilRef(reference, true);
const incomeStatementRef = (reference: string) =>
  incomeStatementUtilRef(reference, true);

export const configINK3 = (
  form: TaxDeclarationFormDefinition,
  customer: CompanyMetaData,
  financialYearStart: string,
  financialYearEnd: string
): TaxDeclarationFormDataConfig => {
  // The SRU config for SKV-2003-to-2023P3 is slightly different from the previous ones
  // For years before 2022P4 we return a different config
  const isFrom2022P4 =
    financialYearToSKVPeriod(`${financialYearStart}-${financialYearEnd}`) >=
    '2022P4';

  const months =
    differenceInMonths(parse(financialYearEnd), parse(financialYearStart)) + 1;

  const initial = {
    INK3: {
      partType: 'form' as const,
      id: mapFormId(form, 'INK3'),
      fields: {
        orgNr: value(customer.orgNumber),
        date: value(''),
        name: value(customer.name),
        address_1: value(customer.address),
        address_2: value(''),
        address_3: value(''),
        '1_1': ref(
          sumAllowNull(id('INK3K.fields.2_16'), id('INK3S.fields.7_9'))
        ),
        '1_2': ref(
          sumAllowNull(id('INK3K.fields.2_17'), id('INK3S.fields.7_10'))
        ),
        '1_3': value(undefined),
        '1_4': value(undefined),
        '1_5_a': value(undefined),
        '1_5_b': value(undefined),
        '1_6_a': value(undefined),
        '1_6_b': value(undefined),
        '1_7': value(undefined),
        '1_7_whole': value(undefined),
        '1_7_half': value(undefined),
        '1_8': value(undefined),
        '1_8_whole': value(undefined),
        '1_8_half': value(undefined),
        '1_9': value(undefined),
        '1_10': value(undefined),
        '1_11': value(undefined),
        '1_12': value(undefined),
        '1_13': value(undefined),
        '1_14': value(undefined),
        '1_15': value(undefined),
        '1_16': value(undefined),
        signatureClarification: value(''),
        phoneNr: value(''),
        otherInfoSubmitted: value(false),
      },
      sru: {},
      help: {
        _type: 'INK3' as const,
        months: value(months),
        preliminaryTaxPerMonth: ref(
          multiply(
            div(
              id('finalTaxCalculations.taxCalculations.main.2.value'),
              id('INK3.help.months')
            ),
            -1
          )
        ),
      },
    },
    INK3SUF: {
      partType: 'form' as const,
      id: mapFormId(form, 'INK3SUF'),
      fields: {
        orgNr: ref(id('INK3.fields.orgNr')),
        date: ref(id('INK3.fields.date')),
        idrott: value(false),
        kultur: value(false),
        miljovard: value(false),
        omsorgOmBarnOchUngdom: value(false),
        politiskVerksamhet: value(false),
        religiosVerksamhet: value(false),
        sjukvard: value(false),
        socHjalpverksamhet: value(false),
        sverigesForsvarOchKrisberedskap: value(false),
        utbildning: value(false),
        vetenskapligForskning: value(false),
        annanLikvardigVerksamhet: isFrom2022P4 ? value(false) : value(''),
        ...(isFrom2022P4
          ? { annanLikvardigVerksamhetText: value('') }
          : undefined),
        realEstatePercent: value(undefined),
        realEstateArea: value(undefined),
        '4_1': value(undefined),
        '4_2': ref(incomeStatementRef('Nettoomsattning')),
        '4_3': ref(
          sumAllowNull(
            id('N9-*.N9.fields.C_5_overfort'),
            id('N9-*.N9.fields.E_4_skattemassig_aterforing'),
            id('N9-*.N9.fields.E_6_ytterligare')
          )
        ),
        '4_4': value(undefined),
        '4_5': value(undefined),
        '4_6': value(undefined),
        '4_7': value(undefined),
        '4_8': value(undefined),
        '4_9': value(undefined),
        '4_10': value(undefined),
        '4_11': value(undefined),
        '4_12': value(undefined),
      },
      sru: {
        '7011': value(financialYearStart),
        '7012': value(financialYearEnd),
        '8822': ref(id('INK3SUF.fields.idrott')),
        '8828': ref(id('INK3SUF.fields.kultur')),
        '8832': ref(id('INK3SUF.fields.miljovard')),
        '8823': ref(id('INK3SUF.fields.omsorgOmBarnOchUngdom')),
        '8827': ref(id('INK3SUF.fields.politiskVerksamhet')),
        '8820': ref(id('INK3SUF.fields.religiosVerksamhet')),
        '8833': ref(id('INK3SUF.fields.sjukvard')),
        '8821': ref(id('INK3SUF.fields.socHjalpverksamhet')),
        '8831': ref(id('INK3SUF.fields.sverigesForsvarOchKrisberedskap')),
        '8829': ref(id('INK3SUF.fields.utbildning')),
        '8830': ref(id('INK3SUF.fields.vetenskapligForskning')),
        ...(isFrom2022P4
          ? { '8619': ref(id('INK3SUF.fields.annanLikvardigVerksamhet')) }
          : undefined),
        '8620': ref(
          id(
            isFrom2022P4
              ? 'INK3SUF.fields.annanLikvardigVerksamhetText'
              : 'INK3SUF.fields.annanLikvardigVerksamhet'
          )
        ),
        '8614': ref(id('INK3SUF.fields.realEstatePercent')),
        '8615': ref(id('INK3SUF.fields.realEstateArea')),
        '8640': ref(id('INK3SUF.fields.4_1')),
        '8641': ref(id('INK3SUF.fields.4_2')),
        '8642': ref(id('INK3SUF.fields.4_3')),
        '8643': ref(id('INK3SUF.fields.4_4')),
        '8629': ref(id('INK3SUF.fields.4_5')),
        '8644': ref(id('INK3SUF.fields.4_6')),
        '8713': ref(id('INK3SUF.fields.4_7')),
        '8714': ref(id('INK3SUF.fields.4_8')),
        '8715': ref(id('INK3SUF.fields.4_9')),
        '8716': ref(id('INK3SUF.fields.4_10')),
        '8717': ref(id('INK3SUF.fields.4_11')),
        '8718': ref(id('INK3SUF.fields.4_12')),
      },
    },
    INK3SUS: {
      partType: 'form' as const,
      id: mapFormId(form, 'INK3SUS'),
      fields: {
        orgNr: ref(id('INK3.fields.orgNr')),
        date: ref(id('INK3.fields.date')),
        idrott: value(false),
        kultur: value(false),
        miljovard: value(false),
        omsorgOmBarnOchUngdom: value(false),
        politiskVerksamhet: value(false),
        religiosVerksamhet: value(false),
        sjukvard: value(false),
        socHjalpverksamhet: value(false),
        sverigesForsvarOchKrisberedskap: value(false),
        utbildning: value(false),
        vetenskapligForskning: value(false),
        annanLikvardigVerksamhet: isFrom2022P4 ? value(false) : value(''),
        ...(isFrom2022P4
          ? { annanLikvardigVerksamhetText: value('') }
          : undefined),
        '3_1': value(undefined),
        '3_2': value(undefined),
        '3_3': value(undefined),
        '3_4': value(undefined),
        '3_5': value(undefined),
        '3_6': value(undefined),
        '3_7': value(false),
        '3_8': value(undefined),
        '3_9': value(undefined),
      },
      sru: {
        '7011': value(financialYearStart),
        '7012': value(financialYearEnd),
        '8806': ref(id('INK3SUS.fields.idrott')),
        '8807': ref(id('INK3SUS.fields.kultur')),
        '8808': ref(id('INK3SUS.fields.miljovard')),
        '8804': ref(id('INK3SUS.fields.omsorgOmBarnOchUngdom')),
        '8809': ref(id('INK3SUS.fields.politiskVerksamhet')),
        '8810': ref(id('INK3SUS.fields.religiosVerksamhet')),
        '8811': ref(id('INK3SUS.fields.sjukvard')),
        '8801': ref(id('INK3SUS.fields.socHjalpverksamhet')),
        '8805': ref(id('INK3SUS.fields.sverigesForsvarOchKrisberedskap')),
        '8800': ref(id('INK3SUS.fields.utbildning')),
        '8803': ref(id('INK3SUS.fields.vetenskapligForskning')),
        ...(isFrom2022P4
          ? { '8839': ref(id('INK3SUS.fields.annanLikvardigVerksamhet')) }
          : undefined),
        '8840': ref(
          id(
            isFrom2022P4
              ? 'INK3SUS.fields.annanLikvardigVerksamhetText'
              : 'INK3SUS.fields.annanLikvardigVerksamhet'
          )
        ),
        '8850': ref(id('INK3SUS.fields.3_1')),
        '8857': ref(id('INK3SUS.fields.3_2')),
        '8858': ref(id('INK3SUS.fields.3_3')),
        '8851': ref(id('INK3SUS.fields.3_4')),
        '8852': ref(id('INK3SUS.fields.3_5')),
        '8853': ref(id('INK3SUS.fields.3_6')),
        '8854': ref(id('INK3SUS.fields.3_7')),
        '8855': ref(id('INK3SUS.fields.3_8')),
        '8856': ref(id('INK3SUS.fields.3_9')),
      },
    },
    INK3R: {
      partType: 'form' as const,
      id: mapFormId(form, 'INK3R'),
      fields: {
        orgNr: ref(id('INK3.fields.orgNr')),
        date: ref(id('INK3.fields.date')),
        '5_1': ref(
          sumAllowNull(
            balanceSheetRef(
              'KoncessionerPatentLicenserVarumarkenLiknandeRattigheter'
            ),
            balanceSheetRef('HyresratterLiknandeRattigheter'),
            balanceSheetRef('Goodwill'),
            balanceSheetRef(
              'BalanseradeUtgifterUtvecklingsarbetenLiknandeArbeten'
            ),
            balanceSheetRef('ForskottImmateriellaAnlaggningstillgangar'),
            balanceSheetRef(
              'PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangar'
            )
          )
        ),
        '5_2': ref(balanceSheetRef('ByggnaderMark')),
        '5_3': ref(
          balanceSheetRef(
            'PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangar'
          )
        ),
        '5_4': ref(
          sumAllowNull(
            balanceSheetRef('MaskinerAndraTekniskaAnlaggningar'),
            balanceSheetRef('InventarierVerktygInstallationer'),
            balanceSheetRef('OvrigaMateriellaAnlaggningstillgangar'),
            balanceSheetRef('ForbattringsutgifterAnnansFastighet')
          )
        ),
        '5_5': ref(
          sumAllowNull(
            balanceSheetRef('AndelarKoncernforetag'),
            balanceSheetRef('AndelarIntresseforetagGemensamtStyrdaForetag'),
            balanceSheetRef('AgarintressenOvrigaForetag'),
            balanceSheetRef('AndraLangfristigaVardepappersinnehav'),
            balanceSheetRef('FordringarKoncernforetagLangfristiga'),
            balanceSheetRef(
              'FordringarIntresseforetagGemensamtStyrdaForetagLangfristiga'
            ),
            balanceSheetRef('FordringarOvrigaForetagAgarintresseLangfristiga'),
            balanceSheetRef('LanDelagareNarstaende')
          )
        ),
        '5_6': ref(
          sumAllowNull(
            balanceSheetRef('AndraLangfristigaFordringar'),
            balanceSheetRef('UppskjutenSkattefordran')
          )
        ),
        '5_7': ref(
          sumAllowNull(
            balanceSheetRef('LagerRavarorFornodenheter'),
            balanceSheetRef('LagerVarorUnderTillverkning'),
            balanceSheetRef('LagerFardigaVarorHandelsvaror'),
            balanceSheetRef('OvrigaLagertillgangar'),
            balanceSheetRef('PagaendeArbetenAnnansRakningOmsattningstillgangar')
          )
        ),
        '5_8': ref(balanceSheetRef('Kundfordringar')),
        '5_9': ref(
          sumAllowNull(
            or(balanceSheetRef('ForskottTillLeverantorer'), 0),
            or(balanceSheetRef('FordringarKoncernforetagKortfristiga'), 0),
            or(
              balanceSheetRef(
                'FordringarIntresseforetagGemensamtStyrdaForetagKortfristiga'
              ),
              0
            ),
            or(
              balanceSheetRef(
                'FordringarOvrigaforetagAgarintresseKortfristiga'
              ),
              0
            ),
            or(balanceSheetRef('OvrigaFordringarKortfristiga'), 0),
            or(balanceSheetRef('AktuellSkattefodran'), 0),
            or(balanceSheetRef('AndelarKoncernforetagKortfristiga'), 0),
            or(balanceSheetRef('OvrigaKortfristigaPlaceringar'), 0)
          )
        ),
        '5_10': ref(
          sumAllowNull(
            balanceSheetRef('ForutbetaldaKostnaderUpplupnaIntakter'),
            balanceSheetRef('UpparbetadEjFaktureradIntakt')
          )
        ),
        '5_11': ref(
          sumAllowNull(
            balanceSheetRef('KassaBankExklRedovisningsmedel'),
            balanceSheetRef('Redovisningsmedel')
          )
        ),
        '5_12': ref(
          sumAllowNull(
            // The first ref here is only relevant for manual submission
            balanceSheetRef('equityManualK2'),
            balanceSheetRef('Aktiekapital'),
            balanceSheetRef('EjRegistreratAktiekapital'),
            balanceSheetRef('OverkursfondBunden'),
            balanceSheetRef('Uppskrivningsfond'),
            balanceSheetRef('Reservfond'),
            balanceSheetRef('Kapitalandelsfond'),
            balanceSheetRef('FondUtvecklingsutgifter')
          )
        ),
        '5_13': ref(balanceSheetRef('Periodiseringsfonder')),
        '5_14': ref(
          sumAllowNull(
            balanceSheetRef('AckumuleradeOveravskrivningar'),
            balanceSheetRef('OvrigaObeskattadeReserver'),
            balanceSheetRef('Ersattningsfonder')
          )
        ),
        '5_15': ref(
          balanceSheetRef('AvsattningarPensionerLiknandeForpliktelserEnligtLag')
        ),
        '5_16': ref(
          sumAllowNull(
            balanceSheetRef('OvrigaAvsattningar'),
            balanceSheetRef('Obligationslan')
          )
        ),
        '5_17': ref(
          sumAllowNull(
            balanceSheetRef('OvrigaLangfristigaSkulderKreditinstitut'),
            balanceSheetRef('SkulderKoncernforetagLangfristiga'),
            balanceSheetRef(
              'SkulderIntresseforetagGemensamtStyrdaForetagLangfristiga'
            ),
            balanceSheetRef('SkulderOvrigaForetagAgarintresseLangfristiga'),
            balanceSheetRef('OvrigaLangfristigaSkulder'),
            balanceSheetRef('CheckrakningskreditKortfristig'),
            balanceSheetRef('OvrigaKortfristigaSkulderKreditinstitut')
          )
        ),
        '5_18': ref(balanceSheetRef('Skatteskulder')),
        '5_19': ref(balanceSheetRef('Leverantorsskulder')),
        '5_20': ref(
          sumAllowNull(
            balanceSheetRef('ForskottFranKunder'),
            balanceSheetRef('PagaendeArbetenAnnansRakningKortfristigaSkulder'),
            balanceSheetRef('Vaxelskulder'),
            balanceSheetRef('SkulderKoncernforetagKortfristiga'),
            balanceSheetRef(
              'SkulderIntresseforetagGemensamtStyrdaForetagKortfristiga'
            ),
            balanceSheetRef('SkulderOvrigaForetagAgarintresseKortfristiga'),
            balanceSheetRef('OvrigaKortfristigaSkulder')
          )
        ),
        // UpplupnaKostnaderForutbetaldaIntakter from v1
        '5_21': ref(
          sumAllowNull(
            balanceSheetRef('UpplupnaKostnaderForutbetaldaIntakter'),
            balanceSheetRef('FaktureradEjUpparbetadIntakt')
          )
        ),
        '6_1_pos': value(undefined),
        '6_2_pos': value(undefined),
        '6_3_pos': value(undefined),
        '6_4_pos': ref(incomeStatementRef('Nettoomsattning')),
        '6_5_pos': ref(
          sumAllowNull(
            incomeStatementRef(
              'ForandringLagerProdukterIArbeteFardigaVarorPagaendeArbetenAnnansRakning'
            ),
            incomeStatementRef('AktiveratArbeteEgenRakning'),
            incomeStatementRef('OvrigaRorelseintakter')
          )
        ),
        '6_6_neg': ref(
          sumAllowNull(
            negative(incomeStatementRef('RavarorFornodenheterKostnader')),
            negative(incomeStatementRef('HandelsvarorKostnader'))
          )
        ),
        '6_7_neg': ref(negative(incomeStatementRef('OvrigaExternaKostnader'))),
        '6_8_neg': ref(negative(incomeStatementRef('Personalkostnader'))),
        '6_9_neg': ref(
          sumAllowNull(
            negative(
              incomeStatementRef(
                'AvskrivningarNedskrivningarMateriellaImmateriellaAnlaggningstillgangar'
              )
            ),
            negative(
              incomeStatementRef(
                'NedskrivningarOmsattningstillgangarUtoverNormalaNedskrivningar'
              )
            )
          )
        ),
        '6_10_neg': ref(negative(incomeStatementRef('OvrigaRorelsekostnader'))),
        '6_11_pos': ref(
          onlyPositive(
            sumAllowNull(
              incomeStatementRef('ResultatAndelarKoncernforetag'),
              incomeStatementRef(
                'ResultatAndelarIntresseforetagGemensamtStyrda'
              ),
              incomeStatementRef('ResultatOvrigaforetagAgarintresse'),
              incomeStatementRef(
                'ResultatOvrigaFinansiellaAnlaggningstillgangar'
              ),
              incomeStatementRef(
                'ResultatOvrigaFinansiellaAnlaggningstillgangar'
              )
            )
          )
        ),
        '6_11_neg': ref(
          onlyNegative(
            sumAllowNull(
              incomeStatementRef('ResultatAndelarKoncernforetag'),
              incomeStatementRef(
                'ResultatAndelarIntresseforetagGemensamtStyrda'
              ),
              incomeStatementRef('ResultatOvrigaforetagAgarintresse'),
              incomeStatementRef(
                'ResultatOvrigaFinansiellaAnlaggningstillgangar'
              ),
              incomeStatementRef(
                'ResultatOvrigaFinansiellaAnlaggningstillgangar'
              )
            )
          )
        ),
        '6_12_pos': value(undefined),
        '6_12_neg': value(undefined),
        '6_13_pos': ref(
          incomeStatementRef('OvrigaRanteintakterLiknandeResultatposter')
        ),
        '6_14_neg': ref(
          negative(
            incomeStatementRef(
              'NedskrivningarFinansiellaAnlaggningstillgangarKortfristigaPlaceringar'
            )
          )
        ),
        '6_15_neg': ref(
          negative(incomeStatementRef('RantekostnaderLiknandeResultatposter'))
        ),
        '6_16_pos': ref(
          onlyPositive(incomeStatementRef('ForandringPeriodiseringsfond'))
        ),
        '6_17_neg': ref(
          onlyNegative(incomeStatementRef('ForandringPeriodiseringsfond'))
        ),
        '6_18_pos': ref(
          onlyPositive(
            sumAllowNull(
              incomeStatementRef('LamnadeKoncernbidrag'),
              incomeStatementRef('ErhallnaKoncernbidrag'),
              incomeStatementRef('ForandringOveravskrivningar'),
              incomeStatementRef('OvrigaBokslutsdispositioner'),
              incomeStatementRef('ForandringErsattningsfond'),
              incomeStatementRef('AndraBokslutsdispositioner')
            )
          )
        ),
        '6_18_neg': ref(
          onlyNegative(
            sumAllowNull(
              incomeStatementRef('LamnadeKoncernbidrag'),
              incomeStatementRef('ErhallnaKoncernbidrag'),
              incomeStatementRef('ForandringOveravskrivningar'),
              incomeStatementRef('OvrigaBokslutsdispositioner'),
              incomeStatementRef('ForandringErsattningsfond'),
              incomeStatementRef('AndraBokslutsdispositioner')
            )
          )
        ),
        '6_19_neg': ref(
          sumAllowNull(
            negative(incomeStatementRef('SkattAretsResultat')),
            negative(incomeStatementRef('OvrigaSkatter'))
          )
        ),
        '6_20_profit': ref(onlyPositive(incomeStatementRef('AretsResultat'))),
        '6_20_loss': ref(onlyNegative(incomeStatementRef('AretsResultat'))),
        skattepliktigVerksamhet_1_pos: value(undefined),
        skattepliktigVerksamhet_2_pos: ref(
          incomeStatementRef('Nettoomsattning')
        ),
        skattepliktigVerksamhet_3_pos: value(undefined),
        skattepliktigVerksamhet_4_pos: ref(
          incomeStatementRef('OvrigaRorelseintakter')
        ),
        skattepliktigVerksamhet_5_neg: value(undefined),
        skattepliktigVerksamhet_6_neg: value(undefined), // 6.7 here too
        skattepliktigVerksamhet_7_neg: value(undefined), // 6.8 here too
        skattepliktigVerksamhet_8_neg: value(undefined),
        skattepliktigVerksamhet_9_neg: value(undefined),
        skattepliktigVerksamhet_10_pos: value(undefined),
        skattepliktigVerksamhet_11_neg: value(undefined),
        skattepliktigVerksamhet_12_pos: value(undefined),
        skattepliktigVerksamhet_13_neg: value(undefined),
        skattepliktigVerksamhet_14_pos: value(undefined),
        skattepliktigVerksamhet_15_neg: value(undefined),
        skattepliktigVerksamhet_16_neg: value(undefined),
        skattepliktigVerksamhet_17_pos: value(undefined),
        skattepliktigVerksamhet_18_neg: value(undefined),
        skattepliktigVerksamhet_19_pos: value(undefined),
        skattepliktigVerksamhet_20_neg: value(undefined),
        '6_21_profit': ref(onlyPositive(incomeStatementRef('AretsResultat'))),
        '6_21_loss': ref(onlyNegative(incomeStatementRef('AretsResultat'))),
      },
      sru: {
        '7011': value(financialYearStart),
        '7012': value(financialYearEnd),
        '8720': ref(id('INK3R.fields.5_1')),
        '8721': ref(id('INK3R.fields.5_2')),
        '8722': ref(id('INK3R.fields.5_3')),
        '8723': ref(id('INK3R.fields.5_4')),
        '8724': ref(id('INK3R.fields.5_5')),
        '8725': ref(id('INK3R.fields.5_6')),

        '8726': ref(id('INK3R.fields.5_7')),
        '8727': ref(id('INK3R.fields.5_8')),
        '8728': ref(id('INK3R.fields.5_9')),
        '8729': ref(id('INK3R.fields.5_10')),
        '8730': ref(id('INK3R.fields.5_11')),

        '8735': ref(id('INK3R.fields.5_12')),

        '8736': ref(id('INK3R.fields.5_13')),
        '8737': ref(id('INK3R.fields.5_14')),

        '8738': ref(id('INK3R.fields.5_15')),
        '8739': ref(id('INK3R.fields.5_16')),

        '8740': ref(id('INK3R.fields.5_17')),
        '8741': ref(id('INK3R.fields.5_18')),
        '8742': ref(id('INK3R.fields.5_19')),
        '8743': ref(id('INK3R.fields.5_20')),
        '8744': ref(id('INK3R.fields.5_21')),

        '8650': ref(id('INK3R.fields.6_1_pos')),
        '8651': ref(id('INK3R.fields.6_2_pos')),
        '8671': ref(id('INK3R.fields.skattepliktigVerksamhet_1_pos')),
        '8652': ref(id('INK3R.fields.6_3_pos')),
        '8672': ref(id('INK3R.fields.skattepliktigVerksamhet_2_pos')),
        '8653': ref(id('INK3R.fields.6_4_pos')),
        '8673': ref(id('INK3R.fields.skattepliktigVerksamhet_3_pos')),
        '8654': ref(id('INK3R.fields.6_5_pos')),
        '8674': ref(id('INK3R.fields.skattepliktigVerksamhet_4_pos')),
        '8750': ref(id('INK3R.fields.6_6_neg')),
        '8770': ref(id('INK3R.fields.skattepliktigVerksamhet_5_neg')),
        '8751': ref(id('INK3R.fields.6_7_neg')),
        '8771': ref(id('INK3R.fields.skattepliktigVerksamhet_6_neg')),
        '8752': ref(id('INK3R.fields.6_8_neg')),
        '8772': ref(id('INK3R.fields.skattepliktigVerksamhet_7_neg')),
        '8753': ref(id('INK3R.fields.6_9_neg')),
        '8773': ref(id('INK3R.fields.skattepliktigVerksamhet_8_neg')),
        '8754': ref(id('INK3R.fields.6_10_neg')),
        '8774': ref(id('INK3R.fields.skattepliktigVerksamhet_9_neg')),
        '8655': ref(id('INK3R.fields.6_11_pos')),
        '8675': ref(id('INK3R.fields.skattepliktigVerksamhet_10_pos')),
        '8755': ref(id('INK3R.fields.6_11_neg')),
        '8775': ref(id('INK3R.fields.skattepliktigVerksamhet_11_neg')),

        '8656': ref(id('INK3R.fields.6_12_pos')),
        '8676': ref(id('INK3R.fields.skattepliktigVerksamhet_12_pos')),
        '8756': ref(id('INK3R.fields.6_12_neg')),
        '8776': ref(id('INK3R.fields.skattepliktigVerksamhet_13_neg')),

        '8657': ref(id('INK3R.fields.6_13_pos')),
        '8677': ref(id('INK3R.fields.skattepliktigVerksamhet_14_pos')),
        '8757': ref(id('INK3R.fields.6_14_neg')),
        '8777': ref(id('INK3R.fields.skattepliktigVerksamhet_15_neg')),
        '8758': ref(id('INK3R.fields.6_15_neg')),
        '8778': ref(id('INK3R.fields.skattepliktigVerksamhet_16_neg')),
        '8659': ref(id('INK3R.fields.6_16_pos')),
        '8679': ref(id('INK3R.fields.skattepliktigVerksamhet_17_pos')),
        '8760': ref(id('INK3R.fields.6_17_neg')),
        '8780': ref(id('INK3R.fields.skattepliktigVerksamhet_18_neg')),
        '8660': ref(id('INK3R.fields.6_18_pos')),
        '8680': ref(id('INK3R.fields.skattepliktigVerksamhet_19_pos')),
        '8761': ref(id('INK3R.fields.6_18_neg')),
        '8781': ref(id('INK3R.fields.skattepliktigVerksamhet_20_neg')),

        '8762': ref(id('INK3R.fields.6_19_neg')),
        '8663': ref(id('INK3R.fields.6_20_profit')),
        '8763': ref(id('INK3R.fields.6_20_loss')),
        '8685': ref(id('INK3R.fields.6_21_profit')),
        '8785': ref(id('INK3R.fields.6_21_loss')),
      },
    },
    INK3S: {
      partType: 'form' as const,
      id: mapFormId(form, 'INK3S'),
      fields: {
        orgNr: ref(id('INK3.fields.orgNr')),
        date: ref(id('INK3.fields.date')),
        '7_1_pos': ref(id('INK3R.fields.6_20_profit')),
        '7_2_neg': ref(id('INK3R.fields.6_20_loss')),
        '7_3_a_neg': value(undefined),
        '7_3_b_pos': value(undefined),
        '7_4_neg': ref(
          sumAllowNull(
            id('N9-*.N9.fields.C_2_overtaget'),
            id('N9-*.N9.fields.E_5_skattemassig_avdrag')
          )
        ),
        '7_5_a_pos': value(undefined),
        '7_5_b_pos': value(undefined),
        '7_5_c_pos': value(undefined),
        '7_5_d_pos': value(undefined),
        '7_6_pos': value(undefined),
        '7_6_neg': value(undefined),
        '7_7_neg': ref(
          sumAllowNull(
            id('N8-*.N8.fields.A_1_summa'),
            id('N8-*.N8.fields.B_1_belopp')
          )
        ),
        '7_8_pos': ref(
          sumAllowNull(
            id('N8-*.N8.fields.A_2_summa'),
            id('N8-*.N8.fields.B_2_belopp')
          )
        ),
        '7_9': value(undefined),
        '7_10': value(undefined),
        '7_11': value(undefined),
        '7_12': value(undefined),
        '7_13': value(undefined),
        '7_14': value(undefined),
        contractorParticipation_yes: value(false),
        contractorParticipation_no: value(false),
        annualreport_audit_yes: value(false),
        annualreport_audit_no: value(false),
      },
      sru: {
        '7011': value(financialYearStart),
        '7012': value(financialYearEnd),
        '8686': ref(id('INK3S.fields.7_1_pos')),
        '8786': ref(id('INK3S.fields.7_2_neg')),
        '8787': ref(id('INK3S.fields.7_3_a_neg')),
        '8687': ref(id('INK3S.fields.7_3_b_pos')),
        '8788': ref(id('INK3S.fields.7_4_neg')),
        '8688': ref(id('INK3S.fields.7_5_a_pos')),
        '8693': ref(id('INK3S.fields.7_5_b_pos')),
        '8696': ref(id('INK3S.fields.7_5_c_pos')),
        '8689': ref(id('INK3S.fields.7_5_d_pos')),
        '8690': ref(id('INK3S.fields.7_6_pos')),
        '8789': ref(id('INK3S.fields.7_6_neg')),
        '8790': ref(id('INK3S.fields.7_7_neg')),
        '8691': ref(id('INK3S.fields.7_8_pos')),
        '8695': ref(id('INK3S.fields.7_9')), // (flyttas till huvudblanketten sid 1, p. 1.1)
        '8795': ref(id('INK3S.fields.7_10')), // (flyttas till huvudblanketten sid 1, p. 1.2)
        '8030': ref(id('INK3S.fields.7_11')),
        '8031': ref(id('INK3S.fields.7_12')),
        '8033': ref(id('INK3S.fields.7_13')),
        '8034': ref(id('INK3S.fields.7_14')),
        '8040': ref(id('INK3S.fields.contractorParticipation_yes')),
        '8041': ref(id('INK3S.fields.contractorParticipation_no')),
        '8044': ref(id('INK3S.fields.annualreport_audit_yes')),
        '8045': ref(id('INK3S.fields.annualreport_audit_no')),
      },
    },
    INK3K: {
      partType: 'form' as const,
      id: mapFormId(form, 'INK3K'),
      fields: {
        orgNr: ref(id('INK3.fields.orgNr')),
        date: ref(id('INK3.fields.date')),
        '2_1_pos': value(undefined),
        '2_1_neg': value(undefined),
        '2_2_pos': value(undefined),
        '2_3_pos': value(undefined),
        '2_4_neg': value(undefined),
        '2_5_neg': value(undefined),
        '2_6_neg': value(undefined),
        '2_7_neg': ref(sumAllowNull(id('N4-*.N4.fields.A_2_vinst'))),
        '2_8_pos': ref(
          sumAllowNull(
            id('N4-*.N4.fields.A_3_tidigare_uppskov'),
            id('N4-*.N4.fields.A_4_uppskov_som_ska_aterforas'),
            id('N4-*.N4.fields.B_1_uppskov_som_ska_aterforas')
          )
        ),
        '2_9_neg': value(undefined),
        '2_10_pos': value(undefined),
        '2_11_pos': value(undefined),
        '2_12_pos': value(undefined),
        '2_13_pos': value(undefined),
        '2_14_neg': value(undefined),
        '2_15_neg': value(undefined),
        '2_16': value(undefined),
        '2_17': value(undefined),
      },
      sru: {
        '7011': value(financialYearStart),
        '7012': value(financialYearEnd),
        '8602': ref(id('INK3K.fields.2_1_pos')),
        '8703': ref(id('INK3K.fields.2_1_neg')),
        '8600': ref(id('INK3K.fields.2_2_pos')),
        '8601': ref(id('INK3K.fields.2_3_pos')),
        '8700': ref(id('INK3K.fields.2_4_neg')),
        '8701': ref(id('INK3K.fields.2_5_neg')),
        '8702': ref(id('INK3K.fields.2_6_neg')),
        '8704': ref(id('INK3K.fields.2_7_neg')),
        '8603': ref(id('INK3K.fields.2_8_pos')),
        '8705': ref(id('INK3K.fields.2_9_neg')),
        '8607': ref(id('INK3K.fields.2_10_pos')),
        ...(isFrom2022P4
          ? { '8611': ref(id('INK3K.fields.2_11_pos')) }
          : { '8606': ref(id('INK3K.fields.2_11_pos')) }),
        '8604': ref(id('INK3K.fields.2_12_pos')),
        '8605': ref(id('INK3K.fields.2_13_pos')),
        '8706': ref(id('INK3K.fields.2_14_neg')),
        '8707': ref(id('INK3K.fields.2_15_neg')),
        '8610': ref(id('INK3K.fields.2_16')),
        '8710': ref(id('INK3K.fields.2_17')),
      },
    },
  };

  const additionalPages = {
    finalTaxCalculations: {
      ...getFinalCalculationsMetadata({
        customer,
        financialYearStart,
        financialYearEnd,
      }),
      taxReturnsPositive: value('Beräknat belopp att betala (exklusive ränta)'),
      taxReturnsNegative: value('Beräknat belopp att få tillbaka'),
      finalTax: table(
        'finalTaxCalculations.finalTax',
        'name',
        'value',
        'default'
      )
        .addRows((rows) =>
          rows
            .addRow('main')
            .addSubRows((subRows) =>
              subRows
                .addRow(
                  '1',
                  value('Statlig inkomstskatt/Bolagsskatt'),
                  ref(id('financialStatement.companyTax')),
                  value(true)
                )
                .addRow(
                  '2',
                  value('Fastighetsskatt'),
                  ref(id('financialStatement.propertyTaxTotal')),
                  value(true)
                )
                .addRow(
                  '3',
                  value('Särskild löneskatt'),
                  ref(
                    ifOrElse(
                      id(
                        'financialStatement.particularSalaryTax.isDetailedSpecification'
                      ),
                      id(
                        'financialStatement.particularSalaryTax.summarize.particularSalaryTax'
                      ),
                      id('financialStatement.particularSalaryTaxFromSum')
                    )
                  ),
                  value(true)
                )
                .addRow('4', value('Avkastningsskatt'), value(0), value(true))
                .addRow(
                  '5',
                  value('Skattereduktion för förnybar el'),
                  value(0),
                  value(true)
                )
                .build()
            )
            .newRowTemplate(value(''), value(0), value(false))
            .addRow(
              'sum',
              value('Slutlig skatt'),
              ref(sum(id(`${rows.getBaseId()}.main.*.value`)))
            )
            .build()
        )
        .build(),
      taxCalculations: table(
        'finalTaxCalculations.taxCalculations',
        'name',
        'value',
        'default'
      )
        .addRows((rows) =>
          rows
            .addRow('main')
            .addSubRows((subRows) =>
              subRows
                .addRow(
                  '1',
                  value('Slutlig skatt'),
                  ref(sum(id('finalTaxCalculations.finalTax.main.*.value'))),
                  value(true)
                )
                .addRow('2', value('Preliminär skatt'), value(), value(true))
                .build()
            )
            .newRowTemplate(value(''), value(0), value(false))
            .addRow(
              'sum',
              ref(
                ifOrElse(
                  max(sum(id(`${rows.getBaseId()}.main.*.value`)), 0),
                  id('finalTaxCalculations.taxReturnsPositive'),
                  id('finalTaxCalculations.taxReturnsNegative')
                )
              ),
              ref(abs(sum(id(`${rows.getBaseId()}.main.*.value`))))
            )
            .build()
        )
        .build(),
    },
  };

  return customer.type === 'foundation' ||
    customer.type === 'non_profit_association'
    ? toConfig(
        initial,
        form,
        { INK3: helpStructureINK3 },
        { finalTaxCalculations },
        additionalPages
      )
    : {
        initial: toInitial(initial, form),
        definition: toStructure(initial, form),
      };
};

export default configINK3;

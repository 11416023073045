import {
  TaxDeclarationFormDefinition,
  TaxDeclarationFormDataConfig,
  CompanyMetaData,
  helpStructureINK4,
} from '../../types';
import {
  id,
  account,
  sum,
  or,
  ref,
  value,
  sumAllowNull,
  table,
  ifOrElse,
  multiply,
  div,
  max,
  abs,
} from '@agoy/document';
import { differenceInMonths } from 'date-fns';
import { parse } from '@agoy/dates';
import {
  mapFormId,
  balanceSheetRef as balanceSheetUtilRef,
  incomeStatementRef as incomeStatementUtilRef,
  negative,
  onlyNegative,
  onlyPositive,
  toConfig,
  toInitial,
  toStructure,
} from '../forms-util';
import { financialYearToSKVPeriod } from '../../skvPeriod';
import { finalTaxCalculations, getFinalCalculationsMetadata } from '../shared';

const balanceSheetRef = (reference: string) =>
  balanceSheetUtilRef(reference, true);
const incomeStatementRef = (reference: string) =>
  incomeStatementUtilRef(reference, true);

export const configINK4 = (
  form: TaxDeclarationFormDefinition,
  customer: CompanyMetaData,
  financialYearStart: string,
  financialYearEnd: string
): TaxDeclarationFormDataConfig => {
  // The SRU config for SKV-2004-to-2023P3 is slightly different from the previous ones
  // For years before 2022P4 we return a different config
  const isFrom2022P4 =
    financialYearToSKVPeriod(`${financialYearStart}-${financialYearEnd}`) >=
    '2022P4';

  const months =
    differenceInMonths(parse(financialYearEnd), parse(financialYearStart)) + 1;

  const initial = {
    INK4: {
      partType: 'form' as const,
      id: mapFormId(form, 'INK4'),
      fields: {
        orgNr: value(customer.orgNumber || ''),
        date: value(''),
        companyName: value(''),
        companyAddress1: value(''),
        companyAddress2: value(''),
        companyAddress3: value(''),
        '1_1': value(undefined),
        '1_2': value(undefined),
        '1_3_a': value(undefined),
        '1_3_b': value(undefined),
        '1_4_a': value(undefined),
        '1_4_b': value(undefined),
        ...(isFrom2022P4
          ? { '1_5': value(undefined) }
          : { '1_5_whole': value(undefined), '1_5_half': value(undefined) }),
        ...(isFrom2022P4
          ? { '1_6': value(undefined) }
          : { '1_6_whole': value(undefined), '1_6_half': value(undefined) }),
        '1_7': value(undefined),
        '1_8': value(undefined),
        '1_9': value(undefined),
        '1_10': value(undefined),
        '1_11': value(undefined),
        '1_12': value(undefined),
        '1_13': value(undefined),
        ...(isFrom2022P4 ? { signatureName: value('') } : undefined),
        signatureClarification: value(''),
        phoneNr: value(''),
      },
      sru: {},
      help: {
        _type: 'INK4' as const,
        months: value(months),
        preliminaryTaxPerMonth: ref(
          multiply(
            div(
              id('finalTaxCalculations.taxCalculations.main.2.value'),
              id('INK4.help.months')
            ),
            -1
          )
        ),
      },
    },

    INK4R: {
      partType: 'form' as const,
      id: mapFormId(form, 'INK4R'),
      fields: {
        orgNr: ref(id('INK4.fields.orgNr')),
        date: ref(id('INK4.fields.date')),
        '2_1': ref(
          sumAllowNull(
            balanceSheetRef(
              'KoncessionerPatentLicenserVarumarkenLiknandeRattigheter'
            ),
            balanceSheetRef('HyresratterLiknandeRattigheter'),
            balanceSheetRef('Goodwill'),
            balanceSheetRef(
              'BalanseradeUtgifterUtvecklingsarbetenLiknandeArbeten'
            )
          )
        ),
        '2_2': ref(
          sumAllowNull(
            balanceSheetRef('ForskottImmateriellaAnlaggningstillgangar'),
            balanceSheetRef(
              'PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangar'
            )
          )
        ),
        '2_3': ref(balanceSheetRef('ByggnaderMark')),
        '2_4': ref(
          sumAllowNull(
            or(balanceSheetRef('MaskinerAndraTekniskaAnlaggningar'), 0),
            or(balanceSheetRef('InventarierVerktygInstallationer'), 0),
            or(balanceSheetRef('OvrigaMateriellaAnlaggningstillgangar'), 0)
          )
        ),
        '2_5': ref(balanceSheetRef('ForbattringsutgifterAnnansFastighet')),
        '2_6': ref(
          balanceSheetRef(
            'PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangar'
          )
        ),
        '2_7': ref(
          sumAllowNull(
            or(balanceSheetRef('AndelarKoncernforetag'), 0),
            or(
              balanceSheetRef('AndelarIntresseforetagGemensamtStyrdaForetag'),
              0
            ),
            or(balanceSheetRef('AgarintressenOvrigaForetag'), 0),
            or(balanceSheetRef('AndraLangfristigaVardepappersinnehav'), 0)
          )
        ),
        '2_8': ref(
          sumAllowNull(
            or(balanceSheetRef('FordringarKoncernforetagLangfristiga'), 0),
            or(
              balanceSheetRef(
                'FordringarIntresseforetagGemensamtStyrdaForetagLangfristiga'
              ),
              0
            ),
            or(
              balanceSheetRef(
                'FordringarOvrigaForetagAgarintresseLangfristiga'
              ),
              0
            )
          )
        ),
        '2_9': ref(balanceSheetRef('LanDelagareNarstaende')),
        '2_10': ref(
          sumAllowNull(
            balanceSheetRef('AndraLangfristigaFordringar'),
            balanceSheetRef('UppskjutenSkattefordran')
          )
        ),
        '2_11': ref(
          sumAllowNull(
            balanceSheetRef('LagerRavarorFornodenheter'),
            balanceSheetRef('LagerVarorUnderTillverkning'),
            balanceSheetRef('LagerFardigaVarorHandelsvaror'),
            balanceSheetRef('OvrigaLagertillgangar')
          )
        ),
        '2_12': ref(
          balanceSheetRef('PagaendeArbetenAnnansRakningOmsattningstillgangar')
        ),
        '2_13': ref(balanceSheetRef('ForskottTillLeverantorer')),
        '2_14': ref(balanceSheetRef('Kundfordringar')),
        '2_15': ref(
          sumAllowNull(
            balanceSheetRef('FordringarKoncernforetagKortfristiga'),
            balanceSheetRef(
              'FordringarIntresseforetagGemensamtStyrdaForetagKortfristiga'
            ),
            balanceSheetRef('FordringarOvrigaforetagAgarintresseKortfristiga'),
            balanceSheetRef('OvrigaFordringarKortfristiga'),
            balanceSheetRef('AktuellSkattefodran')
          )
        ),
        '2_16': ref(
          sumAllowNull(
            balanceSheetRef('ForutbetaldaKostnaderUpplupnaIntakter'),
            balanceSheetRef('UpparbetadEjFaktureradIntakt')
          )
        ),
        '2_17': ref(
          sumAllowNull(
            balanceSheetRef('AndelarKoncernforetagKortfristiga'),
            balanceSheetRef('OvrigaKortfristigaPlaceringar')
          )
        ),
        '2_18': ref(
          sumAllowNull(
            balanceSheetRef('KassaBankExklRedovisningsmedel'),
            balanceSheetRef('Redovisningsmedel')
          )
        ),
        '2_19': ref(account('2010+2020+2030+2040+2050:2059+2060+2070:2072')),
        '2_20': ref(
          account('2011:2018+2021:2028+2031:2038+2041:2048+2061:2066')
        ),
        '2_21': ref(onlyPositive(account('2019+2029+2039+2049+2068:2069'))),
        '2_22': ref(onlyNegative(account('2019+2029+2039+2049+2068:2069'))),
        '2_23': ref(
          sumAllowNull(
            id('INK4R.fields.2_19'),
            id('INK4R.fields.2_20'),
            id('INK4R.fields.2_21'),
            id('INK4R.fields.2_22')
          )
        ),
        '2_24': ref(balanceSheetRef('AckumuleradeOveravskrivningar')),
        '2_25': ref(
          sumAllowNull(
            balanceSheetRef('Periodiseringsfonder'),
            balanceSheetRef('OvrigaObeskattadeReserver'),
            balanceSheetRef('Ersattningsfonder')
          )
        ),
        '2_26': ref(
          balanceSheetRef('AvsattningarPensionerLiknandeForpliktelserEnligtLag')
        ),
        '2_27': ref(
          balanceSheetRef('OvrigaAvsattningarPensionerLiknandeForpliktelser')
        ),
        '2_28': ref(balanceSheetRef('OvrigaAvsattningar')),
        '2_29': ref(
          sumAllowNull(
            balanceSheetRef('SkulderKoncernforetagLangfristiga'),
            balanceSheetRef(
              'SkulderIntresseforetagGemensamtStyrdaForetagLangfristiga'
            )
          )
        ),
        '2_30': ref(
          sumAllowNull(
            balanceSheetRef('SkulderOvrigaForetagAgarintresseLangfristiga'),
            balanceSheetRef('CheckrakningskreditLangfristig'),
            balanceSheetRef('OvrigaLangfristigaSkulderKreditinstitut'),
            balanceSheetRef('OvrigaLangfristigaSkulder'),
            balanceSheetRef('Obligationslan')
          )
        ),
        '2_31': ref(balanceSheetRef('ForskottFranKunder')),
        '2_32': ref(
          balanceSheetRef('PagaendeArbetenAnnansRakningKortfristigaSkulder')
        ),
        '2_33': ref(balanceSheetRef('Leverantorsskulder')),
        '2_34': ref(balanceSheetRef('Skatteskulder')),
        '2_35': ref(
          sumAllowNull(
            balanceSheetRef('OvrigaKortfristigaSkulderKreditinstitut'),
            balanceSheetRef('SkulderKoncernforetagKortfristiga'),
            balanceSheetRef(
              'SkulderIntresseforetagGemensamtStyrdaForetagKortfristiga'
            ),
            balanceSheetRef('SkulderOvrigaForetagAgarintresseKortfristiga'),
            balanceSheetRef('CheckrakningskreditKortfristig'),
            balanceSheetRef('Vaxelskulder'),
            balanceSheetRef('OvrigaKortfristigaSkulder')
          )
        ),
        '2_36': ref(
          sumAllowNull(
            balanceSheetRef('FaktureradEjUpparbetadIntakt'),
            balanceSheetRef('UpplupnaKostnaderForutbetaldaIntakter')
          )
        ),
        '3_1_pos': ref(incomeStatementRef('Nettoomsattning')),
        '3_2_pos': ref(
          onlyPositive(
            incomeStatementRef(
              'ForandringLagerProdukterIArbeteFardigaVarorPagaendeArbetenAnnansRakning'
            )
          )
        ),
        '3_2_neg': ref(
          onlyNegative(
            incomeStatementRef(
              'ForandringLagerProdukterIArbeteFardigaVarorPagaendeArbetenAnnansRakning'
            )
          )
        ),
        '3_3_pos': ref(incomeStatementRef('AktiveratArbeteEgenRakning')),
        '3_4_pos': ref(incomeStatementRef('OvrigaRorelseintakter')),
        '3_5_neg': ref(
          negative(incomeStatementRef('RavarorFornodenheterKostnader'))
        ),
        '3_6_neg': ref(negative(incomeStatementRef('HandelsvarorKostnader'))),
        '3_7_neg': ref(negative(incomeStatementRef('OvrigaExternaKostnader'))),
        '3_8_neg': ref(negative(incomeStatementRef('Personalkostnader'))),
        '3_9_neg': ref(
          negative(
            incomeStatementRef(
              'AvskrivningarNedskrivningarMateriellaImmateriellaAnlaggningstillgangar'
            )
          )
        ),
        '3_10_neg': ref(incomeStatementRef('OvrigaRorelsekostnader')),
        '3_11_pos': ref(
          onlyPositive(incomeStatementRef('ResultatAndelarKoncernforetag'))
        ),
        '3_11_neg': ref(
          onlyNegative(incomeStatementRef('ResultatAndelarKoncernforetag'))
        ),
        '3_12_pos': ref(
          onlyPositive(
            sumAllowNull(
              balanceSheetRef('ResultatAndelarIntresseforetagGemensamtStyrda'),
              balanceSheetRef('ResultatOvrigaforetagAgarintresse')
            )
          )
        ),
        '3_12_neg': ref(
          onlyNegative(
            sumAllowNull(
              balanceSheetRef('ResultatAndelarIntresseforetagGemensamtStyrda'),
              balanceSheetRef('ResultatOvrigaforetagAgarintresse')
            )
          )
        ),
        '3_13_pos': ref(
          onlyPositive(
            incomeStatementRef('ResultatOvrigaFinansiellaAnlaggningstillgangar')
          )
        ),
        '3_13_neg': ref(
          onlyNegative(
            incomeStatementRef('ResultatOvrigaFinansiellaAnlaggningstillgangar')
          )
        ),
        '3_14_pos': ref(
          incomeStatementRef('OvrigaRanteintakterLiknandeResultatposter')
        ),
        '3_15_neg': ref(
          incomeStatementRef(
            'NedskrivningarFinansiellaAnlaggningstillgangarKortfristigaPlaceringar'
          )
        ),
        '3_16_neg': ref(
          negative(incomeStatementRef('RantekostnaderLiknandeResultatposter'))
        ),
        '3_17_pos': ref(
          onlyPositive(
            sumAllowNull(
              incomeStatementRef('ErhallnaKoncernbidrag'),
              incomeStatementRef('LamnadeKoncernbidrag'),
              incomeStatementRef('ForandringPeriodiseringsfond'),
              incomeStatementRef('ForandringOveravskrivningar'),
              incomeStatementRef('OvrigaBokslutsdispositioner'),
              incomeStatementRef('ForandringErsattningsfond'),
              incomeStatementRef('SkattAretsResultat'),
              incomeStatementRef('OvrigaSkatter'),
              incomeStatementRef('AndraBokslutsdispositioner')
            )
          )
        ),
        '3_17_neg': ref(
          onlyNegative(
            sumAllowNull(
              incomeStatementRef('ErhallnaKoncernbidrag'),
              incomeStatementRef('LamnadeKoncernbidrag'),
              incomeStatementRef('ForandringPeriodiseringsfond'),
              incomeStatementRef('ForandringOveravskrivningar'),
              incomeStatementRef('OvrigaBokslutsdispositioner'),
              incomeStatementRef('ForandringErsattningsfond')
            )
          )
        ),
        '3_18': ref(onlyPositive(incomeStatementRef('ResultatForeSkatt'))),
        '3_19': ref(onlyNegative(incomeStatementRef('ResultatForeSkatt'))),
      },
      sru: {
        '7011': value(financialYearStart),
        '7012': value(financialYearEnd),
        '7201': ref(id('INK4R.fields.2_1')),
        '7202': ref(id('INK4R.fields.2_2')),
        '7214': ref(id('INK4R.fields.2_3')),
        '7215': ref(id('INK4R.fields.2_4')),
        '7216': ref(id('INK4R.fields.2_5')),
        '7217': ref(id('INK4R.fields.2_6')),
        '7236': ref(id('INK4R.fields.2_7')),
        '7232': ref(id('INK4R.fields.2_8')),
        '7234': ref(id('INK4R.fields.2_9')),
        '7237': ref(id('INK4R.fields.2_10')),
        '7247': ref(id('INK4R.fields.2_11')),
        '7245': ref(id('INK4R.fields.2_12')),
        '7246': ref(id('INK4R.fields.2_13')),
        '7253': ref(id('INK4R.fields.2_14')),
        '7264': ref(id('INK4R.fields.2_15')),
        '7263': ref(id('INK4R.fields.2_16')),
        '7272': ref(id('INK4R.fields.2_17')),
        '7281': ref(id('INK4R.fields.2_18')),
        '7310': ref(id('INK4R.fields.2_19')),
        '7311': ref(id('INK4R.fields.2_20')),
        '7312': ref(id('INK4R.fields.2_21')),
        '7313': ref(id('INK4R.fields.2_22')),
        '7314': ref(id('INK4R.fields.2_23')),
        '7322': ref(id('INK4R.fields.2_24')),
        '7323': ref(id('INK4R.fields.2_25')),
        '7331': ref(id('INK4R.fields.2_26')),
        '7332': ref(id('INK4R.fields.2_27')),
        '7333': ref(id('INK4R.fields.2_28')),
        '7353': ref(id('INK4R.fields.2_29')),
        '7355': ref(id('INK4R.fields.2_30')),
        '7362': ref(id('INK4R.fields.2_31')),
        '7363': ref(id('INK4R.fields.2_32')),
        '7365': ref(id('INK4R.fields.2_33')),
        '7368': ref(id('INK4R.fields.2_34')),
        '7371': ref(id('INK4R.fields.2_35')),
        '7370': ref(id('INK4R.fields.2_36')),
        '7410': ref(id('INK4R.fields.3_1_pos')),
        '7411': ref(id('INK4R.fields.3_2_pos')),
        '7510': ref(id('INK4R.fields.3_2_neg')),
        '7412': ref(id('INK4R.fields.3_3_pos')),
        '7413': ref(id('INK4R.fields.3_4_pos')),
        '7511': ref(id('INK4R.fields.3_5_neg')),
        '7512': ref(id('INK4R.fields.3_6_neg')),
        '7513': ref(id('INK4R.fields.3_7_neg')),
        '7514': ref(id('INK4R.fields.3_8_neg')),
        '7515': ref(id('INK4R.fields.3_9_neg')),
        '7535': ref(id('INK4R.fields.3_10_neg')),
        '7414': ref(id('INK4R.fields.3_11_pos')),
        '7518': ref(id('INK4R.fields.3_11_neg')),
        '7415': ref(id('INK4R.fields.3_12_pos')),
        '7519': ref(id('INK4R.fields.3_12_neg')),
        '7416': ref(id('INK4R.fields.3_13_pos')),
        '7520': ref(id('INK4R.fields.3_13_neg')),
        '7417': ref(id('INK4R.fields.3_14_pos')),
        '7521': ref(id('INK4R.fields.3_15_neg')),
        '7522': ref(id('INK4R.fields.3_16_neg')),
        '7427': ref(id('INK4R.fields.3_17_pos')),
        '7537': ref(id('INK4R.fields.3_17_neg')),
        '7450': ref(id('INK4R.fields.3_18')),
        '7550': ref(id('INK4R.fields.3_19')),
      },
    },
    INK4S: {
      partType: 'form' as const,
      id: mapFormId(form, 'INK4S'),
      fields: {
        orgNr: ref(id('INK4.fields.orgNr')),
        date: ref(id('INK4.fields.date')),
        '4_1_pos': ref(id('INK4R.fields.3_18')),
        '4_2_neg': ref(id('INK4R.fields.3_19')),
        '4_3_a_pos': value(undefined),
        '4_3_b_pos': ref(
          sumAllowNull(id('N9-*.N9.fields.E_4_skattemassig_aterforing'))
        ),
        '4_4_a_neg': ref(
          sumAllowNull(
            id('N8-*.N8.fields.A_1_summa'),
            id('N8-*.N8.fields.B_1_belopp')
          )
        ),
        '4_4_b_neg': ref(
          sumAllowNull(id('N9-*.N9.fields.E_5_skattemassig_avdrag'))
        ),
        '4_5_a_neg': value(undefined),
        '4_5_b_neg': value(undefined),
        '4_6_a_pos': ref(
          sumAllowNull(
            id('N8-*.N8.fields.A_2_summa'),
            id('N8-*.N8.fields.B_2_belopp')
          )
        ),
        '4_6_b_pos': value(undefined),
        '4_6_c_pos': value(undefined),
        '4_7_neg': value(undefined),
        '4_8_a_neg': value(undefined),
        '4_8_b_pos': value(undefined),
        '4_8_c_pos': value(undefined),
        '4_8_d_neg': value(undefined),
        '4_9_pos': value(undefined),
        '4_9_neg': value(undefined),
        '4_10_a_neg': value(undefined),
        '4_10_b_pos': value(undefined),
        '4_11_a_neg': value(undefined),
        '4_11_b_pos': value(undefined),
        '4_12': value(undefined),
        '4_13': value(undefined),
        '4_14': value(undefined),
        '4_15': value(undefined),
        ingetUnderlagFaststalls: value(false), // not present in form defintion for 2022P1 - 2022P3 but we need to keep it here for older forms
        ovrigaUpplysningarSeparatSkrivelse: value(false),
        uppdragsTagareHarBitratt_yes: value(false),
        uppdragsTagareHarBitratt_no: value(false),
        arsredovisningenForemalForRevision_yes: value(false),
        arsredovisningenForemalForRevision_no: value(false),
      },
      sru: {
        '7011': value(financialYearStart),
        '7012': value(financialYearEnd),
        '7650': ref(id('INK4S.fields.4_1_pos')),
        '7750': ref(id('INK4S.fields.4_2_neg')),
        '7652': ref(id('INK4S.fields.4_3_a_pos')),
        '7653': ref(id('INK4S.fields.4_3_b_pos')),
        '7761': ref(id('INK4S.fields.4_4_a_neg')),
        '7764': ref(id('INK4S.fields.4_4_b_neg')),
        '7752': ref(id('INK4S.fields.4_5_a_neg')),
        '7754': ref(id('INK4S.fields.4_5_b_neg')),
        '7662': ref(id('INK4S.fields.4_6_a_pos')),
        '7668': ref(id('INK4S.fields.4_6_b_pos')),
        '7665': ref(id('INK4S.fields.4_6_c_pos')),
        '7766': ref(id('INK4S.fields.4_7_neg')),
        '7741': ref(id('INK4S.fields.4_8_a_neg')),
        '7642': ref(id('INK4S.fields.4_8_b_pos')),
        '7641': ref(id('INK4S.fields.4_8_c_pos')),
        '7742': ref(id('INK4S.fields.4_8_d_neg')),
        '7666': ref(id('INK4S.fields.4_9_pos')),
        '7765': ref(id('INK4S.fields.4_9_neg')),
        '7767': ref(id('INK4S.fields.4_10_a_neg')),
        '7669': ref(id('INK4S.fields.4_10_b_pos')),
        '7768': ref(id('INK4S.fields.4_11_a_neg')),
        '7643': ref(id('INK4S.fields.4_11_b_pos')),
        '7670': ref(id('INK4S.fields.4_12')),
        '7770': ref(id('INK4S.fields.4_13')),
        '8020': ref(id('INK4S.fields.4_14')),
        '8021': ref(id('INK4S.fields.4_15')),
        '74': ref(id('INK4S.fields.ingetUnderlagFaststalls')),
        '90': ref(id('INK4S.fields.ovrigaUpplysningarSeparatSkrivelse')),
        '8040': ref(id('INK4S.fields.uppdragsTagareHarBitratt_yes')),
        '8041': ref(id('INK4S.fields.uppdragsTagareHarBitratt_no')),
        '8044': ref(id('INK4S.fields.arsredovisningenForemalForRevision_yes')),
        '8045': ref(id('INK4S.fields.arsredovisningenForemalForRevision_no')),
      },
    },
    INK4DU: {
      partType: 'form' as const,
      id: mapFormId(form, 'INK4DU'),
      fields: {
        orgNr: ref(id('INK4.fields.orgNr')),
        date: ref(id('INK4.fields.date')),
        numreringFleraDelagare: value(undefined),
        // danger will robinson, if you change the structure of the partner slot ids
        // you have to update SRU generation mapping
        partnerSlot1_col1: value(''),
        partnerSlot1_col2: value(undefined),
        partnerSlot1_col3: value(undefined),
        partnerSlot1_col4: value(undefined),
        partnerSlot1_col5: value(undefined),
        partnerSlot1_col6: value(''),
        partnerSlot2_col1: value(''),
        partnerSlot2_col2: value(undefined),
        partnerSlot2_col3: value(undefined),
        partnerSlot2_col4: value(undefined),
        partnerSlot2_col5: value(undefined),
        partnerSlot2_col6: value(''),
        partnerSlot3_col1: value(''),
        partnerSlot3_col2: value(undefined),
        partnerSlot3_col3: value(undefined),
        partnerSlot3_col4: value(undefined),
        partnerSlot3_col5: value(undefined),
        partnerSlot3_col6: value(''),
        partnerSlot4_col1: value(''),
        partnerSlot4_col2: value(undefined),
        partnerSlot4_col3: value(undefined),
        partnerSlot4_col4: value(undefined),
        partnerSlot4_col5: value(undefined),
        partnerSlot4_col6: value(''),
        partnerSlot5_col1: value(''),
        partnerSlot5_col2: value(undefined),
        partnerSlot5_col3: value(undefined),
        partnerSlot5_col4: value(undefined),
        partnerSlot5_col5: value(undefined),
        partnerSlot5_col6: value(''),
        partnerSlot6_col1: value(''),
        partnerSlot6_col2: value(undefined),
        partnerSlot6_col3: value(undefined),
        partnerSlot6_col4: value(undefined),
        partnerSlot6_col5: value(undefined),
        partnerSlot6_col6: value(''),
        partnerSlot7_col1: value(''),
        partnerSlot7_col2: value(undefined),
        partnerSlot7_col3: value(undefined),
        partnerSlot7_col4: value(undefined),
        partnerSlot7_col5: value(undefined),
        partnerSlot7_col6: value(''),
        partnerSlot8_col1: value(''),
        partnerSlot8_col2: value(undefined),
        partnerSlot8_col3: value(undefined),
        partnerSlot8_col4: value(undefined),
        partnerSlot8_col5: value(undefined),
        partnerSlot8_col6: value(''),
        partnerSlot9_col1: value(''),
        partnerSlot9_col2: value(undefined),
        partnerSlot9_col3: value(undefined),
        partnerSlot9_col4: value(undefined),
        partnerSlot9_col5: value(undefined),
        partnerSlot9_col6: value(''),
        partnerSlot10_col1: value(''),
        partnerSlot10_col2: value(undefined),
        partnerSlot10_col3: value(undefined),
        partnerSlot10_col4: value(undefined),
        partnerSlot10_col5: value(undefined),
        partnerSlot10_col6: value(''),
        partnerSlot11_col1: value(''),
        partnerSlot11_col2: value(undefined),
        partnerSlot11_col3: value(undefined),
        partnerSlot11_col4: value(undefined),
        partnerSlot11_col5: value(undefined),
        partnerSlot11_col6: value(''),
        partnerSlot12_col1: value(''),
        partnerSlot12_col2: value(undefined),
        partnerSlot12_col3: value(undefined),
        partnerSlot12_col4: value(undefined),
        partnerSlot12_col5: value(undefined),
        partnerSlot12_col6: value(''),
        partnerSlot13_col1: value(''),
        partnerSlot13_col2: value(undefined),
        partnerSlot13_col3: value(undefined),
        partnerSlot13_col4: value(undefined),
        partnerSlot13_col5: value(undefined),
        partnerSlot13_col6: value(''),
        partnerSlot14_col1: value(''),
        partnerSlot14_col2: value(undefined),
        partnerSlot14_col3: value(undefined),
        partnerSlot14_col4: value(undefined),
        partnerSlot14_col5: value(undefined),
        partnerSlot14_col6: value(''),
        partnerSlot15_col1: value(''),
        partnerSlot15_col2: value(undefined),
        partnerSlot15_col3: value(undefined),
        partnerSlot15_col4: value(undefined),
        partnerSlot15_col5: value(undefined),
        partnerSlot15_col6: value(''),
        partnerSlot16_col1: value(''),
        partnerSlot16_col2: value(undefined),
        partnerSlot16_col3: value(undefined),
        partnerSlot16_col4: value(undefined),
        partnerSlot16_col5: value(undefined),
        partnerSlot16_col6: value(''),
        partnerSlot17_col1: value(''),
        partnerSlot17_col2: value(undefined),
        partnerSlot17_col3: value(undefined),
        partnerSlot17_col4: value(undefined),
        partnerSlot17_col5: value(undefined),
        partnerSlot17_col6: value(''),
        partnerSlot18_col1: value(''),
        partnerSlot18_col2: value(undefined),
        partnerSlot18_col3: value(undefined),
        partnerSlot18_col4: value(undefined),
        partnerSlot18_col5: value(undefined),
        partnerSlot18_col6: value(''),
        partnerSlot19_col1: value(''),
        partnerSlot19_col2: value(undefined),
        partnerSlot19_col3: value(undefined),
        partnerSlot19_col4: value(undefined),
        partnerSlot19_col5: value(undefined),
        partnerSlot19_col6: value(''),
        partnerSlot20_col1: value(''),
        partnerSlot20_col2: value(undefined),
        partnerSlot20_col3: value(undefined),
        partnerSlot20_col4: value(undefined),
        partnerSlot20_col5: value(undefined),
        partnerSlot20_col6: value(''),
        partnerSlot21_col1: value(''),
        partnerSlot21_col2: value(undefined),
        partnerSlot21_col3: value(undefined),
        partnerSlot21_col4: value(undefined),
        partnerSlot21_col5: value(undefined),
        partnerSlot21_col6: value(''),
        partnerSlot22_col1: value(''),
        partnerSlot22_col2: value(undefined),
        partnerSlot22_col3: value(undefined),
        partnerSlot22_col4: value(undefined),
        partnerSlot22_col5: value(undefined),
        partnerSlot22_col6: value(''),
        partnerSlot23_col1: value(''),
        partnerSlot23_col2: value(undefined),
        partnerSlot23_col3: value(undefined),
        partnerSlot23_col4: value(undefined),
        partnerSlot23_col5: value(undefined),
        partnerSlot23_col6: value(''),
        partnerSlot24_col1: value(''),
        partnerSlot24_col2: value(undefined),
        partnerSlot24_col3: value(undefined),
        partnerSlot24_col4: value(undefined),
        partnerSlot24_col5: value(undefined),
        partnerSlot24_col6: value(''),
      },
      sru: {
        '7050': value(false),
        '7011': value(financialYearStart),
        '7012': value(financialYearEnd),
        /*
          DO NOT ADD FIELDS HERE FOR PARTNER SLOTS.
          The format of INK4 DU SRU generation is very different see mapping.ts file in web module
          -> /src/utils/TaxDeclaration/mapping.ts
        */
      },
    },
  };
  const additionalPages = {
    finalTaxCalculations: {
      ...getFinalCalculationsMetadata({
        customer,
        financialYearStart,
        financialYearEnd,
      }),
      taxReturnsPositive: value('Beräknat belopp att betala (exklusive ränta)'),
      taxReturnsNegative: value('Beräknat belopp att få tillbaka'),
      finalTax: table(
        'finalTaxCalculations.finalTax',
        'name',
        'value',
        'default'
      )
        .addRows((rows) =>
          rows
            .addRow('main')
            .addSubRows((subRows) =>
              subRows
                .addRow(
                  '1',
                  value('Fastighetsskatt'),
                  ref(id('financialStatement.propertyTaxTotal')),
                  value(true)
                )
                .addRow(
                  '2',
                  value('Särskild löneskatt'),
                  ref(
                    ifOrElse(
                      id(
                        'financialStatement.particularSalaryTax.isDetailedSpecification'
                      ),
                      id(
                        'financialStatement.particularSalaryTax.summarize.particularSalaryTax'
                      ),
                      id('financialStatement.particularSalaryTaxFromSum')
                    )
                  ),
                  value(true)
                )
                .addRow('3', value('Avkastningsskatt'), value(0), value(true))
                .addRow(
                  '4',
                  value('Skattereduktion för förnybar el'),
                  value(0),
                  value(true)
                )
                .build()
            )
            .newRowTemplate(value(''), value(0), value(false))
            .addRow(
              'sum',
              value('Slutlig skatt'),
              ref(sum(id(`${rows.getBaseId()}.main.*.value`)))
            )
            .build()
        )

        .build(),

      taxCalculations: table(
        'finalTaxCalculations.taxCalculations',
        'name',
        'value',
        'default'
      )
        .addRows((rows) =>
          rows
            .addRow('main')
            .addSubRows((subRows) =>
              subRows
                .addRow(
                  '1',
                  value('Slutlig skatt'),
                  ref(sum(id('finalTaxCalculations.finalTax.main.*.value'))),
                  value(true)
                )
                .addRow('2', value('Preliminär skatt'), value(0), value(true))
                .build()
            )
            .newRowTemplate(value(''), value(0), value(false))
            .addRow(
              'sum',
              ref(
                ifOrElse(
                  max(sum(id(`${rows.getBaseId()}.main.*.value`)), 0),
                  id('finalTaxCalculations.taxReturnsPositive'),
                  id('finalTaxCalculations.taxReturnsNegative')
                )
              ),
              ref(abs(sum(id(`${rows.getBaseId()}.main.*.value`))))
            )
            .build()
        )
        .build(),
    },
  };

  return customer.type?.startsWith('limited')
    ? toConfig(
        initial,
        form,
        { INK4: helpStructureINK4 },
        { finalTaxCalculations },
        additionalPages
      )
    : {
        initial: toInitial(initial, form),
        definition: toStructure(initial, form),
      };
};

export default configINK4;

// eslint-disable @typescript-eslint/no-use-before-define
import range from 'lodash-es/range';
import { addDays, format, parse } from 'date-fns';
import {
  AccountBalance,
  AccountingAccount,
  AccountingBalances,
  AccountingPeriod,
  AccountingPeriodBalance,
  FinancialYear,
} from 'types/Accounting';
import { AccountInformation } from 'utils/SieParser';
import OverviewTable from 'types/OverviewTable/types';
import { companyTaxPerYear } from '@agoy/tax-document';
import groupSpec, {
  GroupSpec,
  SummarySpec,
  SummaryType,
  SummaryFunction,
} from './groupSpec';

/**
 * all periods does not have to have data in the sie fil.
 * this fills those periods with the previosPeriods Ib, Ub,totalc
 */
export const createRowData = (
  allPeriods: AccountingPeriod[],
  yearBalance: AccountBalance | undefined,
  periodBalances: Record<string, AccountingPeriodBalance>
): OverviewTable.PeriodInformation[] => {
  const yearIb = yearBalance?.in ?? 0;

  const rows: OverviewTable.PeriodInformation[] = [];
  allPeriods
    .filter((period) => period.type === 'month' || period.type === 'year_end')
    .forEach((period: AccountingPeriod) => {
      const periodFromRow: AccountingPeriodBalance | undefined =
        periodBalances[period.id];

      if (periodFromRow !== undefined) {
        rows.push({
          ib: periodFromRow.in,
          periodId: period.id,
          period: period.start,
          ub: periodFromRow.out,
          change: periodFromRow.out - periodFromRow.in,
        });
      } else {
        const defaultPrevRow = { period: period.start, change: 0 };
        const previousRow =
          rows.length > 0
            ? {
                ...defaultPrevRow,
                ib: rows[rows.length - 1].ub,
                ub: rows[rows.length - 1].ub,
                periodId: rows[rows.length - 1].periodId,
              }
            : {
                ...defaultPrevRow,
                ib: yearIb,
                ub: yearIb,
                periodId: period.id,
              };
        rows.push(previousRow);
      }
    });
  return rows;
};

const between = <T>(begin: T, middle: T, end: T): boolean =>
  middle >= begin && middle <= end;

const summarizeGroup = (
  groupAccounts: OverviewTable.AccountInformation[],
  group: GroupSpec,
  currentYear: number,
  previousYear: number
): OverviewTable.AccountGroupSummary | undefined => {
  const { summary } = group;
  if (!summary) {
    return undefined;
  }
  if (summary.accountRanges) {
    const accounts = summary.accountRanges.map(([firstAccount, lastAccount]) =>
      groupAccounts.filter((account) =>
        between(firstAccount, account.account, lastAccount)
      )
    );

    return accounts.length === 0
      ? undefined
      : getSummaryFunction(
          summary.type,
          currentYear,
          previousYear
        )(accounts, summary);
  }
  const accounts = group.subGroups
    .flatMap((subGroup) => subGroup.subTabs)
    .flatMap((subTab) => {
      return groupAccounts.filter((account) =>
        between(subTab.firstAccount, account.account, subTab.lastAccount)
      );
    });

  return accounts.length === 0
    ? undefined
    : getSummaryFunction(
        summary.type,
        currentYear,
        previousYear
      )([accounts], summary);
};

const summarizeSubGroup = (
  filteredAccounts: OverviewTable.AccountInformation[][],
  groupAccounts: OverviewTable.AccountInformation[],
  summary: SummarySpec | undefined,
  currentYear: number,
  previousYear: number
): OverviewTable.AccountGroupSummary | undefined => {
  if (!summary) {
    return undefined;
  }

  const accounts = summary.accountRanges
    ? summary.accountRanges.map(([firstAccount, lastAccount]) =>
        groupAccounts.filter((account) =>
          between(firstAccount, account.account, lastAccount)
        )
      )
    : filteredAccounts;
  if (accounts.length === 0) {
    return undefined;
  }

  return getSummaryFunction(
    summary.type,
    currentYear,
    previousYear
  )(accounts, summary);
};

type SummaryTypeFunction = (
  accounts: OverviewTable.AccountInformation[][],
  summary: SummarySpec
) => OverviewTable.AccountGroupSummary | undefined;

export const getYearIb = (account: AccountInformation): number =>
  (
    account.res.find((y) => y.yearNo === '-1') ||
    account.yearIbs.find((y) => y.yearNo === '0')
  )?.saldo || 0;

export const getYearUb = (account: AccountInformation): number =>
  (
    account.res.find((y) => y.yearNo === '0') ||
    account.yearUbs.find((y) => y.yearNo === '0')
  )?.saldo || 0;

const accountToRow = (
  account: OverviewTable.AccountInformation
): OverviewTable.SummaryRow => ({
  previousYearUb: account.previousYearUb,
  yearIb: account.yearIb,
  yearUb: account.yearUb,
  yearChange: account.yearChange,
  periods: account.periods.map((period) => ({
    ub: period.ub,
    change: period.change,
  })),
});

function notNull<T>(obj: T | null) {
  return obj !== null;
}

const calculateByColumn = (
  rows: (OverviewTable.SummaryRow | undefined)[],
  operator: SummaryFunction
): OverviewTable.SummaryRow | undefined => {
  if (rows.length === 0) {
    return undefined;
  }

  if (rows.every((item) => item === undefined)) {
    return undefined;
  }

  const nPeriods = rows.reduce<number>(
    (longestPeriods, row) =>
      row?.periods.length || longestPeriods < 0
        ? row?.periods.length || 0
        : longestPeriods,
    0
  );
  const previousYearUb = operator(
    rows.filter(notNull).map((row) => row?.previousYearUb || row?.yearIb || 0),
    'yearIb'
  );
  const yearIb = operator(
    rows.filter(notNull).map((row) => row?.yearIb || 0),
    'yearIb'
  );
  const yearUb = operator(
    rows.map((row) => row?.yearUb || 0),
    'yearUb'
  );
  return {
    previousYearUb,
    yearIb,
    yearUb,
    yearChange:
      previousYearUb !== undefined && yearUb !== undefined
        ? yearUb - previousYearUb
        : undefined,
    periods:
      range(nPeriods).map((_, index) => ({
        ub: operator(
          rows.map((row) => row?.periods[index].ub || 0),
          'period.ub'
        ),
        change: operator(
          rows.map((row) => row?.periods[index].change || 0),
          'period.change'
        ),
      })) || [],
  };
};

const sumArray = (n) => n.reduce((a, b) => a + b, 0);

const summarizeAccountsAsSum: SummaryTypeFunction = (
  accounts,
  summary
): OverviewTable.AccountGroupSummary | undefined => {
  const sum = calculateByColumn(
    accounts.map((a) => calculateByColumn(a.map(accountToRow), sumArray)),
    sumArray
  );

  const { type, ...summaryRest } = summary;

  return (
    sum && {
      ...summaryRest,
      ...sum,
    }
  );
};

const summarizeAccountsAsResult =
  (calcSum): SummaryTypeFunction =>
  (accounts, summary) => {
    const sums = accounts.map((group) =>
      calculateByColumn(group.map(accountToRow), sumArray)
    );
    const diff = calculateByColumn(sums, (n, field) => {
      return field === 'yearUb' ? n[0] : undefined;
    });
    if (!diff) {
      return undefined;
    }
    const noResult = diff.yearUb === undefined || Math.abs(diff.yearUb) < 0.1;

    const sum = calculateByColumn(sums, calcSum);

    const { type, ...summaryRest } = summary;

    return (
      sum && {
        ...summaryRest,
        title: noResult ? 'Beräknat resultat' : 'Resultat',
        ...sum,
      }
    );
  };

const summarizeAccountsWithFunction: SummaryTypeFunction = (
  accounts,
  summary
) => {
  const sums = accounts.map((group) =>
    calculateByColumn(group.map(accountToRow), sumArray)
  );

  const result = calculateByColumn(sums, summary.fn!);

  return (
    result && {
      ...summary,
      title: summary.title,
      type: summary.valueType,
      ...result,
    }
  );
};

//
// Expects accounts to contain [[accounts 2000-2099],[accounts 2100-2199],[accounts 1000-1999]]
// from groupSpec
//
const summarizeSolidity =
  (currentYear: number, previousYear: number): SummaryTypeFunction =>
  (accounts, summary) => {
    const sums = accounts.map((group) =>
      calculateByColumn(group.map(accountToRow), sumArray)
    );

    const result = calculateByColumn(sums, (n, field) => {
      if (field === 'yearIb' || field === 'yearUb' || field === 'period.ub') {
        const [account20xx, account21xx, account1xxx] = n;
        if (
          account20xx === undefined ||
          account21xx === undefined ||
          account1xxx === undefined
        ) {
          return undefined;
        }
        if (account1xxx === 0) {
          return undefined;
        }
        return (
          -(
            account20xx +
            (1 -
              (field === 'yearIb'
                ? companyTaxPerYear[`${previousYear}`]
                : companyTaxPerYear[`${currentYear}`])) *
              account21xx
          ) / account1xxx
        );
      }
      return undefined;
    });

    if (!result) {
      return undefined;
    }

    // Fix period changes
    const periods = calculatePeriodChanges(result.yearIb, result.periods);

    return (
      result && {
        ...summary,
        title: summary.title,
        type: summary.valueType,
        ...result,
        periods,
      }
    );
  };

const safeSubtract = (
  a: number | undefined,
  b: number | undefined
): number | undefined =>
  a !== undefined && b !== undefined ? a - b : undefined;

const calculatePeriodChanges = (
  ib: number | undefined,
  periods: OverviewTable.SummaryRowPeriod[]
): OverviewTable.SummaryRowPeriod[] => {
  return periods.map((p, index) => ({
    ...p,
    change:
      index === 0
        ? safeSubtract(p.ub, ib)
        : safeSubtract(p.ub, periods[index - 1].ub),
  }));
};

const getSummaryFunction = (
  type: SummaryType,
  currentYear: number,
  previousYear: number
): SummaryTypeFunction => {
  switch (type) {
    case 'sum':
      return summarizeAccountsAsSum;
    case 'result':
      return summarizeAccountsAsResult((n, field) => {
        const [, s3 = 0, s4 = 0] = n;
        return field === 'yearChange' ? undefined : s3 + s4;
      });
    case 'function':
      return summarizeAccountsWithFunction;
    case 'solidity':
      return summarizeSolidity(currentYear, previousYear);
    default:
      return () => undefined;
  }
};

export const createPeriods = (
  financialYear: FinancialYear,
  previousFinancialYear: FinancialYear | null,
  periods: AccountingPeriod[],
  allPeriods: AccountingPeriod[],
  account: AccountingAccount
): OverviewTable.AccountInformation => {
  const newPeriods = createRowData(
    allPeriods,
    account.balances[financialYear.id],
    account.periods
  );

  const yearIb = account.balances[financialYear.id]?.in ?? 0;

  const previousYearUb = previousFinancialYear
    ? account.balances[previousFinancialYear.id]?.out ?? yearIb
    : yearIb;

  const yearUb =
    account.balances[financialYear.id]?.out ??
    account.balances[financialYear.id]?.in ??
    yearIb;

  return {
    account: account.number,
    accountName: account.name,
    yearIb,
    yearUb,
    previousYearUb,
    yearChange: yearUb - previousYearUb,
    periods:
      allPeriods === periods
        ? newPeriods
        : newPeriods.filter((p) =>
            periods.find((item) => item.start === p.period)
          ),
  };
};

const groupAccounts = (
  accountingBalances: AccountingBalances,
  periods: AccountingPeriod[]
): OverviewTable.AccountGroup[] => {
  const financialYearStart = accountingBalances.periods[0].start;
  const financialYear = accountingBalances.financialYears.find(
    (y) => y.start === financialYearStart
  );
  if (!financialYear) {
    console.warn('financial year not found');
    return [];
  }
  const previousFinancialYearEnd = format(
    addDays(parse(financialYear.start, 'yyyy-MM-dd', Date.now()), -1),
    'yyyy-MM-dd'
  );
  const previousFinancialYear =
    accountingBalances.financialYears.find(
      (y) => y.end === previousFinancialYearEnd
    ) ?? null;

  const endingYear = accountingBalances.periods[
    accountingBalances.periods.length - 1
  ].end.substring(0, 4);
  const previousYear = previousFinancialYearEnd.substring(0, 4);

  const accounts = accountingBalances.accounts.map((account) =>
    createPeriods(
      financialYear,
      previousFinancialYear,
      periods,
      accountingBalances.periods.filter((p) => p.type !== 'year_end'),
      account
    )
  );

  return groupSpec.map((group): OverviewTable.AccountGroup => {
    const accountsInGroup = accounts.filter((account) =>
      group.numbers.includes(account.account[0])
    );

    return {
      ...group,
      subGroups: group.subGroups.map(
        (subGroup): OverviewTable.AccountSubGroup => {
          const filteredAccounts: OverviewTable.AccountInformation[][] = [];
          const subTabs = subGroup.subTabs.map(
            (subTab): OverviewTable.AccountSubTab => {
              const accountsInRange = accountsInGroup.filter((account) =>
                between(
                  subTab.firstAccount,
                  account.account,
                  subTab.lastAccount
                )
              );
              if (accountsInRange.length > 0) {
                filteredAccounts.push(accountsInRange);
              }
              const summary = summarizeSubGroup(
                [accountsInRange],
                accountsInGroup,
                subTab.summary,
                parseInt(endingYear, 10),
                parseInt(previousYear, 10)
              );
              return {
                title: subTab.title,
                accounts: accountsInRange,
                summary,
              };
            }
          );
          return {
            title: subGroup.title,
            subTabs,
            summary: summarizeSubGroup(
              filteredAccounts,
              accountsInGroup,
              subGroup.summary,
              parseInt(endingYear, 10),
              2018
            ),
          };
        }
      ),
      summary: summarizeGroup(
        accountsInGroup,
        group,
        parseInt(endingYear, 10),
        parseInt(previousYear, 10)
      ),
    };
  });
};

export default groupAccounts;

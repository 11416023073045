import React, { useRef, useState } from 'react';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import {
  Grow,
  Popper,
  Paper as MuiPaper,
  MenuItem,
  MenuList,
  ClickAwayListener,
  Box,
} from '@material-ui/core';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';
import { colors as ThemeColors } from '@agoy/theme';
import { ChecklistItemStatus } from '_shared/redux/checklist/types';
import { STATUSES } from '_shared/redux/checklist/constants';
import Button from '../Buttons/Button';

const ButtonStatusDot = styled.div<{ statusColor?: string }>`
  display: flex;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  background-color: ${({ theme, statusColor }) =>
    statusColor || theme.palette.grey[400]};
`;

const Arrow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Paper = styled(MuiPaper)`
  width: 200px;
`;

const StatusItem = styled(MenuItem)`
  justify-content: space-between;
`;

type StatusSelectProps = {
  value: ChecklistItemStatus;
  onOptionSelected: (optionId: ChecklistItemStatus) => void;
};

const StatusSelect = ({
  value,
  onOptionSelected,
}: StatusSelectProps): JSX.Element => {
  const [open, setOpen] = useState(false);

  const anchorRef = useRef(null);
  const { formatMessage } = useIntl();

  const handleMenuItemClick = (optionId: ChecklistItemStatus) => {
    if (onOptionSelected) onOptionSelected(optionId);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        label={value ? formatMessage({ id: `checklist.status.${value}` }) : ''}
        variant="text"
        onClick={handleToggle}
        ref={anchorRef}
        startIcon={
          <ButtonStatusDot
            statusColor={ThemeColors[`checklist_${value}`]?.main}
          />
        }
        endIcon={
          <Arrow
            aria-controls={open ? 'period-status-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label="Välj periodstatus"
            aria-haspopup="menu"
          >
            {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </Arrow>
        }
      />

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{ zIndex: 9999 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="period-status--button-menu">
                  {STATUSES.map((status) => (
                    <StatusItem
                      key={status}
                      onClick={() => handleMenuItemClick(status)}
                    >
                      <Box paddingLeft={1}>
                        {formatMessage({ id: `checklist.status.${status}` })}
                      </Box>
                      <ButtonStatusDot
                        statusColor={ThemeColors[`checklist_${status}`]?.main}
                      />
                    </StatusItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default StatusSelect;

import React from 'react';
import { MultiReferenceCell } from '@agoy/document';

interface NoteSelectorProps {
  className?: string;
  cell: MultiReferenceCell;
}

const NoteSelector = ({ className, cell }: NoteSelectorProps) => {
  return <span className={className}>{cell.values.join(', ')}</span>;
};

export default NoteSelector;

export const IMAGE_EXTENSIONS = [
  '.jpeg',
  '.jpg',
  '.png',
  '.gif',
  '.tiff',
  '.svg',
];
export const PDF_EXTENSIONS = ['.pdf'];

/**
 * Checks if a given extension is from an IMAGE document.
 */
export const isImageDocument = (extension: string): boolean => {
  return IMAGE_EXTENSIONS.includes(extension.toLowerCase());
};

/**
 * Checks if a given extension is from a PDF document.
 */
export const isPdfDocument = (extension: string): boolean => {
  return PDF_EXTENSIONS.includes(extension.toLowerCase());
};

export const getDocumentExtension = (documentName: string) => {
  const dotIndex = documentName.lastIndexOf('.');

  if (dotIndex === -1) {
    return '';
  }
  const extension = documentName.substring(dotIndex);
  return extension;
};

export const getDocumentNameWithoutExtension = (documentName: string) => {
  const dotIndex = documentName.lastIndexOf('.');

  if (dotIndex === -1) {
    return '';
  }
  const extension = documentName.substring(0, dotIndex);
  return extension;
};

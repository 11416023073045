import { id, or, ref, sum, table, value } from '@agoy/document';
import { NoteOneTableWithSum } from '../../../../common';
import { previousYear, year } from '../../../../common/utils/date-util';
import { field } from '../../../../common/utils/util';

export const getNote32 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null,
  documentTypeVersion = '1'
): NoteOneTableWithSum => {
  return {
    active: false,
    number: value(undefined),
    name: value('Kortfristiga konvertibla lån'),
    table: table(
      'notes.note32.table',
      { id: 'morgages', label: 'Lån' },
      { id: 'terms', label: 'Tid och villkor' },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      .addRows((rows) =>
        rows
          .addRow('1', value(''), value(''), value(undefined), value(undefined))
          .addRow('2', value(''), value(''), value(undefined), value(undefined))
          .build()
      )
      .newRowTemplate(value(''), value(''), value(undefined), value(undefined))
      .build(),
    sumTable: table(
      'notes.note32.sumTable',
      { id: 'morgages', label: '' },
      { id: 'terms', label: '' },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      .addRows((rows) =>
        rows
          .addRow(
            'sum',
            noteLabel('Summa'),
            value(''),
            ref(or(sum(id('notes.note32.table.*.year')), 0)),
            ref(or(sum(id('notes.note32.table.*.previousYear')), 0))
          )
          .build()
      )
      .build(),
    commentLabel: field('Kommentar'),
    comment: field(''),
    group: value(undefined),
    section: value(undefined),
    validForReportType: {
      k2: true,
      k3: documentTypeVersion === '1',
    },
  };
};

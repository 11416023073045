import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useApiSdk, asResultClass } from 'api-sdk';
import { NotificationContext } from '_shared/services/Notifications/NotificationsContext';

type AccountNames = Record<
  number, // Financial year id
  Record<
    string, // accountNumber
    { name: string; active: boolean }
  >
>;

type AccountNamesContextType = AccountNames;

export const AccountNamesContext = React.createContext<AccountNamesContextType>(
  {}
);

type AccountNamesContextProviderProps = {
  clientId: string;
  financialYearId: number;
  children: React.ReactNode | React.ReactNode[];
};

export const AccountNamesContextProvider = ({
  clientId,
  financialYearId,
  children,
}: AccountNamesContextProviderProps): JSX.Element => {
  const sdk = useApiSdk();
  const notificationService = useContext(NotificationContext);

  const [accountNames, setAccountNames] = useState<AccountNames>({});

  const fetchAccountNames = useCallback(async () => {
    const result = await asResultClass(
      sdk.getReconciliationAccounts({ clientid: clientId, financialYearId })
    );

    if (result.ok && result.val.length > 0) {
      setAccountNames((value) => ({
        ...value,
        [financialYearId]: result.val.reduce(
          (accounts, account) => ({
            ...accounts,
            [account.number]: { name: account.name, active: account.active },
          }),
          {}
        ),
      }));
    }
  }, [clientId, financialYearId, sdk]);

  useEffect(() => {
    if (accountNames[financialYearId]) {
      return;
    }

    fetchAccountNames();
  }, [financialYearId, accountNames, fetchAccountNames]);

  useEffect(() => {
    const sub = notificationService?.subscribe(
      {
        topic: 'accounting-balances-changed',
        clientId,
      },
      (result) => {
        if (result.ok && result.val.topic === 'accounting-balances-changed') {
          fetchAccountNames();
        }
      }
    );

    return () => {
      sub?.unsubscribe();
    };
  }, [clientId, notificationService, fetchAccountNames]);

  return (
    <AccountNamesContext.Provider value={accountNames}>
      {children}
    </AccountNamesContext.Provider>
  );
};

import { colors } from '@agoy/theme';

const {
  modalIcon,
  orange,
  white,
  primaryMain,
  primaryLight,
  secondaryMain,
  info,
  error,
  warning,
  success,
  textPrimary,
  textSecondary,
  cellIncomingBalance,
  cellTotalChange,
  cellOutgoingBalance,
  cellOk,
  cellWarning,
  accountStatusWrong,
  currentMonthBackground,
  closingMonthBackground,
  gridBorderColor,
  dimensionBackgroundColor,
  popupHeader,
  grey,
  darkGrey,
  creditSafeColor,
  sidebarDividerBackground,
} = colors;

export {
  modalIcon,
  orange,
  white,
  primaryMain,
  primaryLight,
  secondaryMain,
  info,
  error,
  warning,
  success,
  textPrimary,
  textSecondary,
  cellIncomingBalance,
  cellTotalChange,
  cellOutgoingBalance,
  cellOk,
  cellWarning,
  accountStatusWrong,
  currentMonthBackground,
  closingMonthBackground,
  gridBorderColor,
  dimensionBackgroundColor,
  popupHeader,
  grey,
  darkGrey,
  creditSafeColor,
  sidebarDividerBackground,
};

import {
  TaxDeclarationFormDefinition,
  TaxDeclarationFormDataConfig,
  PersonMetaData,
} from '../../types';
import {
  ref,
  value,
  id,
  sumAllowEmpty,
  ifOrElse,
  multiply,
  config,
  min,
  div,
  or,
  not,
} from '@agoy/document';
import {
  mapFormId,
  negative,
  toInitial,
  toStructure,
  onlyNegative,
  onlyPositive,
} from '../forms-util';
import helpStructureK10 from '../../types/help/K10';

const configK10 = (
  form: TaxDeclarationFormDefinition,
  person?: PersonMetaData
): TaxDeclarationFormDataConfig => {
  const K10 = mapFormId(form, 'K10');
  const name = person
    ? `${person?.firstName || ''} ${person?.lastName || ''}`
    : '';

  // Functions to ignore an id if its rule is not active
  const returnMainRuleConfig = (fieldConfig: string) => {
    return ref(ifOrElse(id('K10.help.main.rule'), fieldConfig, undefined));
  };
  const returnSimplifiedRuleConfig = (fieldConfig: string) => {
    return ref(ifOrElse(not(id('K10.help.main.rule')), fieldConfig, undefined));
  };

  const initial = {
    K10: {
      partType: 'form' as const,
      id: K10,
      fields: {
        date: value(''),
        shareholderName: value(name || ''),
        shareholderSocSecNumber: value(person ? person.personNumber : ''),
        shareholderNumSharesStartOfYear: ref(
          id('company.ownership.ownedShares')
        ),

        companyName: ref(id('company.clientInformation.name')),
        companyOrgNum: ref(id('company.clientInformation.orgNumber')),
        companyTotalSharesYear: ref(id('company.ownership.shareTotal')),
        isForeignCompany: value(false),
        '1_1_pos': returnSimplifiedRuleConfig(
          multiply(
            div(
              id('K10.fields.shareholderNumSharesStartOfYear'),
              id('K10.fields.companyTotalSharesYear')
            ),
            config('simplificationRule')
          )
        ),
        '1_2_pos': returnSimplifiedRuleConfig(
          sumAllowEmpty(
            id('K10.help.main.savedDividends'),
            multiply(
              id('K10.help.main.savedDividends'),
              multiply(1, config('adjustmentSavedSpace'))
            )
          )
        ),
        '1_3': returnSimplifiedRuleConfig(
          sumAllowEmpty(id('K10.fields.1_1_pos'), id('K10.fields.1_2_pos'))
        ),
        '1_4_neg': value(undefined),
        '1_5': returnSimplifiedRuleConfig(
          sumAllowEmpty(
            id('K10.fields.1_3'),
            negative(id('K10.fields.1_4_neg'))
          )
        ),
        '1_6_pos': returnSimplifiedRuleConfig(id('K10.help.main.dividends')),
        '1_7_neg': returnSimplifiedRuleConfig(id('K10.fields.1_5')),
        '1_8': returnSimplifiedRuleConfig(
          onlyPositive(
            min(
              sumAllowEmpty(
                id('K10.fields.1_6_pos'),
                negative(id('K10.fields.1_7_neg'))
              ),
              config('maxTaxationOfDividendOfService90IBB')
            )
          )
        ),
        '1_9': returnSimplifiedRuleConfig(
          onlyNegative(
            sumAllowEmpty(
              id('K10.fields.1_6_pos'),
              negative(id('K10.fields.1_7_neg'))
            )
          )
        ),
        '1_10_neg': returnSimplifiedRuleConfig(id('K10.fields.3_7_a_neg')),
        '1_11': returnSimplifiedRuleConfig(
          sumAllowEmpty(
            id('K10.fields.1_9'),
            negative(id('K10.fields.1_10_neg'))
          )
        ),
        '1_12_pos': returnSimplifiedRuleConfig(id('K10.help.main.dividends')),
        '1_13_neg': returnSimplifiedRuleConfig(id('K10.fields.1_8')),
        '1_14': returnSimplifiedRuleConfig(
          sumAllowEmpty(
            id('K10.fields.1_12_pos'),
            negative(id('K10.fields.1_13_neg'))
          )
        ),
        '1_15_pos': returnSimplifiedRuleConfig(
          multiply(
            min(id('K10.fields.1_5'), id('K10.fields.1_14')),
            config('taxablePart')
          )
        ),

        '1_16_pos': returnSimplifiedRuleConfig(
          onlyPositive(
            sumAllowEmpty(id('K10.fields.1_14'), negative(id('K10.fields.1_5')))
          )
        ),
        '1_17': returnSimplifiedRuleConfig(
          sumAllowEmpty(id('K10.fields.1_15_pos'), id('K10.fields.1_16_pos'))
        ),
        '2_1_pos': value(undefined),
        '2_2_pos': returnMainRuleConfig(id('K10.fields.6_11')),
        '2_3_pos': returnMainRuleConfig(
          multiply(
            id('K10.help.main.savedDividends'),
            sumAllowEmpty(config('adjustmentSavedSpace'), 1)
          )
        ),
        '2_4': returnMainRuleConfig(
          sumAllowEmpty(
            id('K10.fields.2_1_pos'),
            id('K10.fields.2_2_pos'),
            id('K10.fields.2_3_pos')
          )
        ),
        '2_5_neg': value(undefined),
        '2_6': returnMainRuleConfig(
          sumAllowEmpty(
            id('K10.fields.2_4'),
            negative(id('K10.fields.2_5_neg'))
          )
        ),
        '2_7_pos': returnMainRuleConfig(id('K10.help.main.dividends')),
        '2_8_neg': returnMainRuleConfig(id('K10.fields.2_6')),
        '2_9': returnMainRuleConfig(
          onlyPositive(
            min(
              sumAllowEmpty(
                id('K10.fields.2_7_pos'),
                negative(id('K10.fields.2_8_neg'))
              ),
              config('maxTaxationOfDividendOfService90IBB')
            )
          )
        ),
        '2_10': returnMainRuleConfig(
          onlyNegative(
            sumAllowEmpty(
              id('K10.fields.2_7_pos'),
              negative(id('K10.fields.2_8_neg'))
            )
          )
        ),
        '2_11_neg': returnMainRuleConfig(id('K10.fields.3_7_a_neg')),
        '2_12': returnMainRuleConfig(
          sumAllowEmpty(
            id('K10.fields.2_10'),
            negative(id('K10.fields.2_11_neg'))
          )
        ),
        '2_13_pos': returnMainRuleConfig(id('K10.help.main.dividends')),
        '2_14_neg': returnMainRuleConfig(id('K10.fields.2_9')),
        '2_15': returnMainRuleConfig(
          sumAllowEmpty(
            id('K10.fields.2_13_pos'),
            negative(id('K10.fields.2_14_neg'))
          )
        ),
        '2_16_pos': returnMainRuleConfig(
          multiply(
            min(id('K10.fields.2_6'), id('K10.fields.2_15')),
            config('taxablePart')
          )
        ),
        '2_17_pos': returnMainRuleConfig(
          onlyPositive(
            sumAllowEmpty(id('K10.fields.2_15'), negative(id('K10.fields.2_6')))
          )
        ),
        '2_18': returnMainRuleConfig(
          sumAllowEmpty(id('K10.fields.2_16_pos'), id('K10.fields.2_17_pos'))
        ),
        numSoldShares: value(''),
        dateOfSharesSold: value(''),
        '3_1_pos': value(undefined),
        '3_2_neg': value(undefined),
        '3_3': ref(
          onlyPositive(
            sumAllowEmpty(
              id('K10.fields.3_1_pos'),
              negative(id('K10.fields.3_2_neg'))
            )
          )
        ),
        '3_4_a': ref(
          onlyNegative(
            sumAllowEmpty(
              id('K10.fields.3_1_pos'),
              negative(id('K10.fields.3_2_neg'))
            )
          )
        ),
        '3_4_b': ref(multiply(id('K10.fields.3_4_a'), config('taxablePart'))),
        '3_5_pos': ref(id('K10.fields.3_1_pos')),
        '3_6_neg': value(undefined),
        '3_7_a_neg': value(undefined),
        '3_7_b_neg': value(undefined),
        '3_8': ref(
          onlyPositive(
            sumAllowEmpty(
              id('K10.fields.3_5_pos'),
              negative(id('K10.fields.3_6_neg')),
              negative(id('K10.fields.3_7_a_neg')),
              negative(id('K10.fields.3_7_b_neg'))
            )
          )
        ),
        '3_9_pos': ref(id('K10.fields.3_3')),
        '3_10_neg': ref(
          min(
            id('K10.fields.3_8'),
            config('maxTaxationOfDividendOfService100IBB')
          )
        ),
        '3_11': ref(
          sumAllowEmpty(
            id('K10.fields.3_9_pos'),
            negative(id('K10.fields.3_10_neg'))
          )
        ),
        '3_12_pos': ref(
          multiply(
            min(
              or(id('K10.fields.3_7_a_neg'), id('K10.fields.3_7_b_neg')),
              id('K10.fields.3_11')
            ),
            config('taxablePart')
          )
        ),
        '3_13_pos': ref(
          sumAllowEmpty(
            id('K10.fields.3_11'),
            negative(or(id('K10.fields.3_7_a_neg'), id('K10.fields.3_7_b_neg')))
          )
        ),
        '3_14': ref(
          sumAllowEmpty(id('K10.fields.3_12_pos'), id('K10.fields.3_13_pos'))
        ),
        saleOfReceivedShares: value(false),
        '4_1': value(false),
        '4_2': value(false),
        relatedPartySocSecNumber: value(''),
        '5_1': value(undefined),
        '5_2': value(undefined),
        '5_3': ref(
          sumAllowEmpty(
            multiply(id('K10.fields.5_2'), config('salaryDemandPercentage')),
            config('variableSalaryDemandFixedPart')
          )
        ),
        subsidiary1OrgNr: value(''),
        subsidiary2OrgNr: value(''),
        subsidiary3OrgNr: value(''),
        '6_1_pos': value(undefined),
        '6_2_pos': value(undefined),
        '6_3': ref(
          sumAllowEmpty(id('K10.fields.6_1_pos'), id('K10.fields.6_2_pos'))
        ),
        '6_4': ref(
          multiply(id('K10.fields.6_3'), config('percentageSalaryBasis'))
        ),
        '6_5': value(undefined),
        '6_6_pos': value(undefined),
        '6_7_pos': value(undefined),
        '6_8': ref(
          sumAllowEmpty(id('K10.fields.6_6_pos'), id('K10.fields.6_7_pos'))
        ),
        '6_9': ref(
          multiply(id('K10.fields.6_8'), config('percentageSalaryBasis'))
        ),
        '6_10': value(undefined),
        '6_11': ref(sumAllowEmpty(id('K10.fields.6_5'), id('K10.fields.6_10'))),
      },
      derivedFields: {
        simplifiedRule_1_8_toInk1: ref(
          ifOrElse(
            not(id('K10.help.main.rule')),
            onlyPositive(id('K10.fields.1_8')),
            undefined
          )
        ),
        simplifiedRule_1_17_toInk1: ref(
          ifOrElse(
            not(id('K10.help.main.rule')),
            onlyPositive(id('K10.fields.1_17')),
            undefined
          )
        ),
        mainRule_2_9_toInk1: ref(
          ifOrElse(
            id('K10.help.main.rule'),
            onlyPositive(id('K10.fields.2_9')),
            undefined
          )
        ),
        mainRule_2_18_toInk1: ref(
          ifOrElse(
            id('K10.help.main.rule'),
            onlyPositive(id('K10.fields.2_18')),
            undefined
          )
        ),
      },
      sru: {
        '7050': ref(id('K10.fields.isForeignCompany')), // utländskt dotterbolag
        '4530': ref(id('K10.fields.companyOrgNum')),
        '4531': ref(id('K10.fields.shareholderNumSharesStartOfYear')),
        '4532': ref(id('K10.fields.companyTotalSharesYear')),
        '4511': returnSimplifiedRuleConfig(id('K10.fields.1_1_pos')),
        '4501': returnSimplifiedRuleConfig(id('K10.fields.1_2_pos')),
        '4502': returnSimplifiedRuleConfig(id('K10.fields.1_3')),
        '4720': returnSimplifiedRuleConfig(id('K10.fields.1_4_neg')),
        '4503': returnSimplifiedRuleConfig(id('K10.fields.1_5')),
        '4504': returnSimplifiedRuleConfig(id('K10.fields.1_6_pos')),
        '4721': returnSimplifiedRuleConfig(id('K10.fields.1_7_neg')),
        '4505': returnSimplifiedRuleConfig(id('K10.fields.1_8')),
        '4722': returnSimplifiedRuleConfig(id('K10.fields.1_9')),
        '4723': returnSimplifiedRuleConfig(id('K10.fields.1_10_neg')),
        '4724': returnSimplifiedRuleConfig(id('K10.fields.1_11')),
        '4506': returnSimplifiedRuleConfig(id('K10.fields.1_12_pos')),
        '4725': returnSimplifiedRuleConfig(id('K10.fields.1_13_neg')),
        '4507': returnSimplifiedRuleConfig(id('K10.fields.1_14')),
        '4508': returnSimplifiedRuleConfig(id('K10.fields.1_15_pos')),
        '4509': returnSimplifiedRuleConfig(id('K10.fields.1_16_pos')),
        '4515': returnSimplifiedRuleConfig(id('K10.fields.1_17')),
        '4520': returnMainRuleConfig(id('K10.fields.2_1_pos')),
        '4521': returnMainRuleConfig(id('K10.fields.2_2_pos')),
        '4522': returnMainRuleConfig(id('K10.fields.2_3_pos')),
        '4523': returnMainRuleConfig(id('K10.fields.2_4')),
        '4730': returnMainRuleConfig(id('K10.fields.2_5_neg')),
        '4524': returnMainRuleConfig(id('K10.fields.2_6')),
        '4525': returnMainRuleConfig(id('K10.fields.2_7_pos')),
        '4731': returnMainRuleConfig(id('K10.fields.2_8_neg')),
        '4526': returnMainRuleConfig(id('K10.fields.2_9')),
        '4732': returnMainRuleConfig(id('K10.fields.2_10')),
        '4733': returnMainRuleConfig(id('K10.fields.2_11_neg')),
        '4734': returnMainRuleConfig(id('K10.fields.2_12')),
        '4527': returnMainRuleConfig(id('K10.fields.2_13_pos')),
        '4735': returnMainRuleConfig(id('K10.fields.2_14_neg')),
        '4528': returnMainRuleConfig(id('K10.fields.2_15')),
        '4529': returnMainRuleConfig(id('K10.fields.2_16_pos')),
        '4540': returnMainRuleConfig(id('K10.fields.2_17_pos')),
        '4541': returnMainRuleConfig(id('K10.fields.2_18')),
        '4543': ref(id('K10.fields.numSoldShares')),
        '4544': ref(id('K10.fields.dateOfSharesSold')),
        '4545': ref(id('K10.fields.3_1_pos')),
        '4740': ref(id('K10.fields.3_2_neg')),
        '4546': ref(id('K10.fields.3_3')),
        '4741': ref(id('K10.fields.3_4_a')),
        '4742': ref(id('K10.fields.3_4_b')),
        '4547': ref(id('K10.fields.3_5_pos')),
        '4743': ref(id('K10.fields.3_6_neg')),
        '4744': ref(id('K10.fields.3_7_a_neg')),
        '4745': ref(id('K10.fields.3_7_b_neg')),
        '4548': ref(id('K10.fields.3_8')),
        '4549': ref(id('K10.fields.3_9_pos')),
        '4746': ref(id('K10.fields.3_10_neg')),
        '4550': ref(id('K10.fields.3_11')),
        '4551': ref(id('K10.fields.3_12_pos')),
        '4552': ref(id('K10.fields.3_13_pos')),
        '4553': ref(id('K10.fields.3_14')),
        '4555': ref(id('K10.fields.saleOfReceivedShares')),
        '4556': ref(id('K10.fields.4_1')),
        '4557': ref(id('K10.fields.4_2')),
        '4560': ref(id('K10.fields.relatedPartySocSecNumber')),
        '4561': ref(id('K10.fields.5_1')),
        '4562': ref(id('K10.fields.5_2')),
        '4563': ref(id('K10.fields.5_3')),
        '4564': ref(id('K10.fields.subsidiary1OrgNr')),
        '4565': ref(id('K10.fields.subsidiary2OrgNr')),
        '4566': ref(id('K10.fields.subsidiary3OrgNr')),
        '4570': ref(id('K10.fields.6_1_pos')),
        '4571': ref(id('K10.fields.6_2_pos')),
        '4572': ref(id('K10.fields.6_3')),
        '4573': ref(id('K10.fields.6_4')),
        '4576': ref(id('K10.fields.6_5')),
        '4577': ref(id('K10.fields.6_6_pos')),
        '4578': ref(id('K10.fields.6_7_pos')),
        '4579': ref(id('K10.fields.6_8')),
        '4580': ref(id('K10.fields.6_9')),
        '4583': ref(id('K10.fields.6_10')),
        '4584': ref(id('K10.fields.6_11')),
      },
      help: {
        _type: 'K10' as const,
        main: {
          rule: value(true),
          dividends: value(undefined),
          savedDividends: value(undefined),
          dividendCapital: ref(
            ifOrElse(
              id('K10.help.main.rule'),
              id('K10.fields.2_18'),
              id('K10.fields.1_17')
            )
          ),
          dividendTaxed: ref(
            ifOrElse(
              id('K10.help.main.rule'),
              id('K10.fields.2_9'),
              id('K10.fields.1_8')
            )
          ),
        },
      },
    },
  };
  return {
    initial: toInitial(initial, form),
    definition: toStructure(initial, form, { K10: helpStructureK10 }),
  };
};

export default configK10;

import { AgoyTable, Field } from '@agoy/document';
import { AnnualReportPart, AnnualReportSection } from '../../document';

export interface DigitalSubmissionSection extends AnnualReportSection {
  signature: AgoyTable;
  needsAuditReport: Field;
}

export interface DigitalSubmission extends AnnualReportPart {
  main: DigitalSubmissionSection;
}

export interface DigitalSubmissionSection extends AnnualReportSection {
  signature: AgoyTable;
  needsAuditReport: Field;
}

export interface DigitalSubmission extends AnnualReportPart {
  main: DigitalSubmissionSection;
}

const cell = { type: 'field' as const };
const boolean = { type: 'boolean' as const };

export const digitalSubmission = {
  type: 'part' as const,
  children: {
    active: boolean,
    main: {
      type: 'part' as const,
      children: {
        active: boolean,
        signature: { type: 'table' as const },
        needsAuditReport: cell,
      },
    },
  },
};

import { ApiReturnType } from 'api-sdk';
import { Voucher, ReferencesTypes } from 'types/Voucher';

// eslint-disable-next-line import/prefer-default-export
export const transformVouchers = (
  data: ApiReturnType<'getVoucherList'>
): Voucher[] => {
  return data.map((item) => ({
    ...item,
    reference: (item.reference as ReferencesTypes) || ReferencesTypes.GLIDER,
    transactions:
      item.transactions?.map((transaction) => ({
        id: transaction.id,
        sourceInfo: transaction.sourceInfo || '',
        kind: 'KS',
        typeAmount: transaction.typeAmount,
        amount: transaction.amount,
        account: transaction.account,
        debit: transaction.typeAmount === 'debit' ? transaction.amount : 0,
        credit: transaction.typeAmount === 'credit' ? transaction.amount : 0,
      })) || [],
  }));
};

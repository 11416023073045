import { areIntervalsOverlapping, format, Interval, parseISO } from 'date-fns';
import { ReconciliationPeriod } from './types';
import { ClientCompany } from '@agoy/api-sdk-core';

type FinancialYears = ClientCompany['financialYears'];

export const getIntervals = (
  type: Exclude<ReconciliationPeriod['type'], 'dead'>,
  clientId: string,
  financialYears: FinancialYears,
  current: FinancialYears[number],
  eachOfInterval: (interval: Interval) => Date[],
  endOf: (date: Date) => Date
): ReconciliationPeriod[] => {
  if (financialYears.length > 0) {
    const lastFinancialYear = financialYears[financialYears.length - 1];
    const allPeriods = financialYears.flatMap(
      (year) => year.periods?.map((p) => ({ ...p, financialYear: year })) ?? []
    );
    const currentInterval = {
      start: parseISO(current.start),
      end: parseISO(current.end),
    };

    const minStartDate = financialYears[0].start;
    const maxEndDate = lastFinancialYear.end;

    return eachOfInterval({
      start: parseISO(financialYears[0].start),
      end: parseISO(lastFinancialYear.end),
    })
      .map((start) => ({ start, end: endOf(start) }))
      .filter((interval) =>
        areIntervalsOverlapping(interval, currentInterval, { inclusive: true })
      )
      .map(({ start: startDate, end: endDate }) => {
        const start = format(startDate, 'yyyy-MM-dd');
        const end = format(endDate, 'yyyy-MM-dd');
        return {
          type,
          clientId,
          start: start < minStartDate ? minStartDate : start,
          end: end > maxEndDate ? maxEndDate : end,
          periods: allPeriods.filter(
            (p) => p.start >= start && p.end <= end && p.type === 'month'
          ),
          financialYears: financialYears.filter(
            (y) => y.end >= start && y.start <= end
          ),
        };
      });
  }
  return [];
};

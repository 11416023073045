import React from 'react';
import styled from '@emotion/styled';
import {
  AnnualReportType,
  Notes,
  CommonNotes,
} from '@agoy/annual-report-document';
import { numberValue, stringValue } from '@agoy/document';
import PrincipleTable from './Note1Table';
import IncomeTaxSection from './IncomeTaxSection';
import Comment from '../../UI/Comment';
import ToggleSection from '../../UI/ToggleSection';
import ToggleMultiSection from '../../UI/ToggleMultiSection';
import Header from '../../UI/Header';
import FieldSection from '../../UI/FieldSection';
import ServiceAndContractingAssignments from './ServiceAndContractingSection';
import { Typography } from '@material-ui/core';

const Note1Wrapper = styled.div`
  margin: 2rem 0 0 0;
  max-width: 960px;
`;

type Note1Props = {
  notes: CommonNotes;
  reportType: AnnualReportType;
  isDigitalSubmission: boolean;
};

const Note1 = ({
  notes,
  reportType,
  isDigitalSubmission,
}: Note1Props): JSX.Element => {
  const { note1 } = notes;
  return (
    <Note1Wrapper id="note1">
      <Header
        noteId="note1"
        noteNumber={numberValue(note1.number)}
        title={stringValue(note1.name) || ''}
        field={note1.name}
      />
      {/* disclaimer section, has no section title */}
      {'disclaimerK1' in note1 && reportType === 'k1' && (
        <FieldSection
          field={note1.disclaimerK1}
          value={stringValue(note1.disclaimerK1) || ''}
        />
      )}
      {'disclaimerK2' in note1 && reportType === 'k2' && (
        <FieldSection
          field={note1.disclaimerK2}
          value={stringValue(note1.disclaimerK2) || ''}
        />
      )}
      {'disclaimerK3' in note1 && reportType === 'k3' && (
        <FieldSection value={stringValue(note1.disclaimerK3) || ''} />
      )}
      {'changeOfAccountingRegulationsLabel' in note1 && (
        <ToggleSection
          id="changeOfAccountingRegulationsLabel"
          section="note1"
          target={note1.changeOfAccountingRegulationsLabel}
          title={stringValue(note1.changeOfAccountingRegulationsLabel) || ''}
        >
          <FieldSection
            value={stringValue(note1.changeOfAccountingRegulations) || ''}
          />
        </ToggleSection>
      )}
      {'deviationFromBasicAccountingPrinciplesLabel' in note1 && (
        <ToggleSection
          id="deviationFromBasicAccountingPrinciplesLabel"
          section="note1"
          target={note1.deviationFromBasicAccountingPrinciplesLabel}
          title={
            stringValue(note1.deviationFromBasicAccountingPrinciplesLabel) || ''
          }
        >
          <FieldSection
            value={
              stringValue(note1.deviationFromBasicAccountingPrinciples) || ''
            }
          />
        </ToggleSection>
      )}
      {/* Immateriella anläggningstillgångar for Enskild Firma */}
      {'intangibleAssetsGeneralLabel' in note1 && reportType === 'k1' && (
        <ToggleSection
          id="intangibleAssetsGeneralLabel"
          section="note1"
          target={note1.intangibleAssetsGeneralLabel}
          title={stringValue(note1.intangibleAssetsGeneralLabel) || ''}
        >
          <FieldSection
            value={stringValue(note1.intangibleAssetsGeneral) || ''}
          />
        </ToggleSection>
      )}
      {'tangibleFixedAssetsLabel' in note1 && reportType === 'k1' && (
        <ToggleSection
          id="tangibleFixedAssetsLabel"
          section="note1"
          target={note1.tangibleFixedAssetsLabel}
          title={stringValue(note1.tangibleFixedAssetsLabel) || ''}
        >
          <FieldSection
            value={stringValue(note1.tangibleFixedAssetsGeneral) || ''}
          />
        </ToggleSection>
      )}
      {/* Tangible assets section */}
      {'tangibleFixedAssetsLabel' in note1 && reportType !== 'k1' && (
        <ToggleMultiSection
          headingTranslationKey="note1.tangibleFixedAssetsLabel"
          title={stringValue(note1.tangibleFixedAssetsLabel || '')}
          toggleSections={[
            { type: 'table', section: 'note1', id: 'tangibleFixedAssets' },
          ]}
          notes={notes}
        >
          <FieldSection
            field={note1.tangibleFixedAssetsGeneral}
            value={stringValue(note1.tangibleFixedAssetsGeneral) || ''}
          />
          {'tangibleFixedAssets' in note1 && (
            <PrincipleTable
              table={note1.tangibleFixedAssets}
              tableId="notes.note1.tangibleFixedAssets"
            />
          )}
        </ToggleMultiSection>
      )}
      {'receivablesLabel' in note1 && !isDigitalSubmission && (
        <ToggleSection
          id="receivablesLabel"
          section="note1"
          target={note1.receivablesLabel}
          title={stringValue(note1.receivablesLabel) || ''}
        >
          <FieldSection value={stringValue(note1.receivables) || ''} />
        </ToggleSection>
      )}
      {/* otherAssetsProvisionsAndLiabilities section */}
      {'otherAssetsProvisionsAndLiabilitiesLabel' in note1 &&
        !isDigitalSubmission && (
          <ToggleSection
            id="otherAssetsProvisionsAndLiabilitiesLabel"
            section="note1"
            target={note1.otherAssetsProvisionsAndLiabilitiesLabel}
            title={
              stringValue(note1.otherAssetsProvisionsAndLiabilitiesLabel) || ''
            }
          >
            <FieldSection
              value={
                stringValue(note1.otherAssetsProvisionsAndLiabilities) || ''
              }
            />
          </ToggleSection>
        )}
      {/* revenueRecognition section */}
      {'revenueRecognitionLabel' in note1 && !isDigitalSubmission && (
        <ToggleSection
          id="revenueRecognitionLabel"
          section="note1"
          target={note1.revenueRecognitionLabel}
          title={stringValue(note1.revenueRecognitionLabel) || ''}
        >
          <FieldSection value={stringValue(note1.revenueRecognition) || ''} />
        </ToggleSection>
      )}
      {/* saleOfGoods section */}
      {'saleOfGoodsLabel' in note1 && !isDigitalSubmission && (
        <ToggleSection
          id="saleOfGoodsLabel"
          section="note1"
          target={note1.saleOfGoodsLabel}
          title={stringValue(note1.saleOfGoodsLabel) || ''}
        >
          <FieldSection value={stringValue(note1.saleOfGoods) || ''} />
        </ToggleSection>
      )}
      {/* services section */}
      {'servicesLabel' in note1 && (
        <ToggleSection
          id="servicesLabel"
          section="note1"
          target={note1.servicesLabel}
          title={stringValue(note1.servicesLabel) || ''}
        >
          <FieldSection
            field={note1.services}
            value={stringValue(note1.services) || ''}
          />
        </ToggleSection>
      )}
      {/* acquisitionGoods section */}
      {'acquisitionGoodsLabel' in note1 && (
        <ToggleSection
          id="acquisitionGoodsLabel"
          section="note1"
          target={note1.acquisitionGoodsLabel}
          title={stringValue(note1.acquisitionGoodsLabel) || ''}
        >
          <FieldSection
            field={note1.acquisitionGoods}
            value={stringValue(note1.acquisitionGoods) || ''}
          />
        </ToggleSection>
      )}
      {/* changedPrinciples section */}
      {'changedPrinciplesLabel' in note1 && (
        <ToggleSection
          id="changedPrinciplesLabel"
          section="note1"
          target={note1.changedPrinciplesLabel}
          title={stringValue(note1.changedPrinciplesLabel) || ''}
        >
          {'changedPrinciplesHint' in note1 && (
            <Typography style={{ whiteSpace: 'pre-line' }} variant="body1">
              {stringValue(note1.changedPrinciplesHint)}
            </Typography>
          )}
          <FieldSection
            field={note1.changedPrinciples}
            value={stringValue(note1.changedPrinciples) || ''}
          />
        </ToggleSection>
      )}
      {/* rents section */}
      {'rentsLabel' in note1 && !isDigitalSubmission && (
        <ToggleSection
          id="rentsLabel"
          section="note1"
          target={note1.rentsLabel}
          title={stringValue(note1.rentsLabel) || ''}
        >
          <FieldSection value={stringValue(note1.rents) || ''} />
        </ToggleSection>
      )}
      {/* saleOfGoods section */}
      {'interestRoyaltyAndDividensLabel' in note1 && !isDigitalSubmission && (
        <ToggleSection
          // Keep the typo here so we dont lose stored user data
          id="interestRoyaltyAndDividensLabel"
          section="note1"
          target={note1.interestRoyaltyAndDividensLabel}
          title={stringValue(note1.interestRoyaltyAndDividensLabel) || ''}
        >
          <FieldSection
            value={stringValue(note1.interestRoyaltyAndDividens) || ''}
          />
        </ToggleSection>
      )}
      {'deviationsFromBasicAccountingPrinciplesLabel' in note1 && (
        <ToggleSection
          id="deviationsFromBasicAccountingPrinciplesLabel"
          section="note1"
          target={note1.deviationsFromBasicAccountingPrinciplesLabel}
          title={
            stringValue(note1.deviationsFromBasicAccountingPrinciplesLabel) ||
            ''
          }
        >
          <FieldSection
            value={
              stringValue(note1.deviationsFromBasicAccountingPrinciples) || ''
            }
          />
        </ToggleSection>
      )}
      {'serviceAndContractingAssignmentsLabel' in note1 && (
        <ServiceAndContractingAssignments note1={note1} />
      )}
      {'tangibleAssetsComment' in note1 && (
        <Comment
          field={note1.tangibleAssetsComment}
          value={stringValue(note1.tangibleAssetsComment) || ''}
          labelValue={stringValue(note1.tangibleAssetsCommentLabel)}
        />
      )}
      {/* intangibleAssets section  general */}
      {'intangibleAssetsGeneralLabel' in note1 && reportType !== 'k1' && (
        <ToggleMultiSection
          headingTranslationKey="note1.intangibleAssetsGeneralLabel"
          toggleSections={[
            { type: 'field', section: 'note1', id: 'intangibleAssetsGeneral' },
            { type: 'table', section: 'note1', id: 'intangibleAssetsTable' },
            {
              type: 'field',
              section: 'note1',
              id: 'intangibleAssetsProjectsClosed',
            },
          ]}
          title={stringValue(note1.intangibleAssetsGeneralLabel) || ''}
          // only present in shares-company for now
          notes={notes as Notes}
        >
          <FieldSection
            field={note1.intangibleAssetsGeneral}
            value={stringValue(note1.intangibleAssetsGeneral) || ''}
          />

          {/* intangibleAssets section table */}
          {'intangibleAssetsTable' in note1 && (
            <PrincipleTable
              table={note1.intangibleAssetsTable}
              tableId="notes.note1.intangibleAssetsTable"
            />
          )}
          {/* intangibleAssets section closed projects */}
          {'intangibleAssetsProjectsClosed' in note1 &&
            !isDigitalSubmission && (
              <FieldSection
                value={stringValue(note1.intangibleAssetsProjectsClosed) || ''}
              />
            )}
        </ToggleMultiSection>
      )}
      {/* income tax section */}
      {'incomeTaxLabel' in note1 && !isDigitalSubmission && (
        <IncomeTaxSection
          id="incomeTaxLabel"
          note1={note1}
          title={stringValue(note1.incomeTaxLabel) || ''}
        />
      )}

      {/* Comment */}
      {'comment' in note1 && (
        <Comment
          field={note1.comment}
          value={stringValue(note1.comment) || ''}
          labelValue={stringValue(note1.commentLabel) || ''}
        />
      )}
    </Note1Wrapper>
  );
};

export default Note1;

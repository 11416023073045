import React, { useRef } from 'react';
import { Typography, TextField, IconButton } from '@material-ui/core';
import { DragIndicator, Delete } from '@material-ui/icons';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';
import { ChecklistQuestion, Program } from '_shared/redux/checklist/types';
import { darkBlue } from '@agoy/theme/src/colors';
import PeriodSelect from './PeriodSelect';

const ItemContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 200px 32px;
  grid-gap: ${({ theme }) => theme.spacing(1)}px;
`;

const EditItemContainer = styled.div<{ dragging: boolean }>`
  border-radius: 8px;
  display: grid;
  grid-template-columns: 24px 1fr 200px 45px;
  grid-template-rows: 1fr;
  grid-column-gap: ${({ theme }) => theme.spacing(1)}px;
  padding: ${({ theme }) => theme.spacing(0.5)}px 0px;
  ${({ dragging }) => (dragging ? `background-color: #b7deb7;` : '')}
`;

const IconContainer = styled.div`
  align-items: center;
  background-color: #0000000d;
  border-radius: 8px;
  display: flex;
  justify-content: center;
`;

const StyledIconButton = styled(IconButton)`
  color: ${darkBlue};
  height: 100%;
`;

const ItemLabel = styled(Typography)`
  font-size: 14px;
  padding: ${({ theme }) => theme.spacing(1)}px
    ${({ theme }) => theme.spacing(1)}px;
  overflow-wrap: break-word;
  white-space: normal;
`;

const ItemPeriodLabel = styled(Typography)`
  font-size: 14px;
  padding: ${({ theme }) => theme.spacing(1)}px
    ${({ theme }) => theme.spacing(1)}px;
  text-align: start;
  white-space: normal;
`;

const StyledTextField = styled(TextField)`
  .MuiOutlinedInput-input {
    padding: ${({ theme }) => theme.spacing(1.5)}px;
  }
`;

interface ChecklistItemProps {
  checklistItem: ChecklistQuestion;
  editing: boolean;
  isDragging: boolean;
  program: Program;
  groupIndex: number;
  onDeleteItem: (item: ChecklistQuestion, groupIndex: number) => void;
  onChangeItem: (item: ChecklistQuestion, groupIndex: number) => void;
}

const ChecklistItem = ({
  checklistItem,
  editing,
  isDragging,
  program,
  groupIndex,
  onChangeItem,
  onDeleteItem,
}: ChecklistItemProps) => {
  const { formatMessage } = useIntl();
  const itemRef = useRef(null);

  const handleLabelBlur = (event) => {
    onChangeItem(
      {
        ...checklistItem,
        label: event.target.value,
      },
      groupIndex
    );
  };

  const handleChangePeriod = (value) => {
    onChangeItem(
      {
        ...checklistItem,
        periodicity: value,
      },
      groupIndex
    );
  };

  return editing ? (
    <EditItemContainer ref={itemRef} dragging={isDragging}>
      <IconContainer>
        <DragIndicator />
      </IconContainer>
      <StyledTextField
        variant="outlined"
        defaultValue={checklistItem.label}
        placeholder={formatMessage({ id: 'checklist.create.item' })}
        onBlur={handleLabelBlur}
      />
      <PeriodSelect
        program={program}
        period={checklistItem.periodicity}
        onChange={handleChangePeriod}
      />
      <StyledIconButton
        size="small"
        onClick={() => onDeleteItem(checklistItem, groupIndex)}
      >
        <Delete />
      </StyledIconButton>
    </EditItemContainer>
  ) : (
    <ItemContainer>
      <ItemLabel>{checklistItem.label}</ItemLabel>
      <ItemPeriodLabel>
        {formatMessage({
          id: `checklist.create.periodicity.${checklistItem.periodicity}`,
        })}
      </ItemPeriodLabel>
    </ItemContainer>
  );
};

export default ChecklistItem;

import { value, ref, table } from '@agoy/document';
import { IxbrlCell, NoteOneTableSection } from '../../../../common';
import {
  sumAccountsIBs,
  sumNotesRowsById,
} from '../../../../common/utils/accountSumsUtil';
import { field, ixbrlCell, ixbrlField } from '../../../../common/utils/util';
import { year, previousYear } from '../../../../common/utils/date-util';
import { NoteAccountRanges } from '../types';

const MONETARY_ITEM_TYPE = 'monetaryItemType';

export const note73Accounts: NoteAccountRanges[] = [
  {
    rowId: '1',
    ranges: [[1180, 1181], [1188], [1280, 1281], [1288]],
    cellLabel: 'Ingående anskaffningsvärden',
    action: 'sumAccountsIBs',
  },
];

export const getNote73 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null,
  documentTypeVersion = '1'
): NoteOneTableSection => {
  return {
    active: false,
    number: value(undefined),
    name: value(
      'Pågående nyanläggningar och förskott avseende materiella anläggningstillgångar'
    ),
    table: table<IxbrlCell>(
      'notes.note73.table',
      { id: 'label', label: '' },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      .addRows((rows) =>
        rows
          .addRow(
            'initialAcquisitionValues',
            noteLabel('Ingående anskaffningsvärden'),
            ixbrlCell(
              ref(sumAccountsIBs('1180:1181+1188+1280:1281+1288', false)),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarAnskaffningsvarden',
                contextRef: 'balans0',
                saldo: 'debit',
                standardRubrik:
                  'Anskaffningsvärden pågående nyanläggningar och förskott avseende materiella anläggningstillgångar',
              }
            ),
            ixbrlCell(
              ref(
                sumAccountsIBs(
                  '1180:1181+1188+1280:1281+1288',
                  false,
                  'previousYear'
                )
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarAnskaffningsvarden',
                contextRef: 'balans1',
                saldo: 'debit',
                standardRubrik:
                  'Anskaffningsvärden pågående nyanläggningar och förskott avseende materiella anläggningstillgångar',
              }
            )
          )
          .addRow(
            'changesInAcquisitionValues',
            noteLabel('Förändringar av anskaffningsvärden'),
            undefined,
            undefined
          )
          .addRow(
            'expensesIncurred',
            noteLabel('Nedlagda utgifter'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringAnskaffningsvardenNedlagdaUtgifter',
              contextRef: 'period0',
              standardRubrik:
                'Ökning av anskaffningsvärden i samband med produktion av pågående nyanläggningar avseende materiella anläggningstillgångar',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringAnskaffningsvardenNedlagdaUtgifter',
              contextRef: 'period1',
              standardRubrik:
                'Ökning av anskaffningsvärden i samband med produktion av pågående nyanläggningar avseende materiella anläggningstillgångar',
            })
          )
          .addRow(
            'advancesPaid',
            noteLabel('Utbetalda förskott'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringAnskaffningsvardenUtbetaladeForskott',
              contextRef: 'period0',
              standardRubrik:
                'Ökning av anskaffningsvärden i samband med periodens utbetalda förskott avseende materiella anläggningstillgångar',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringAnskaffningsvardenUtbetaladeForskott',
              contextRef: 'period1',
              standardRubrik:
                'Ökning av anskaffningsvärden i samband med periodens utbetalda förskott avseende materiella anläggningstillgångar',
            })
          )
          .addRow(
            'businessAcquisitions',
            noteLabel('Rörelseförvärv'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringAnskaffningsvardenRorelseforvarv',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden för pågående nyanläggningar och förskott avseende materiella anläggningstillgångar genom rörelseförvärv',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringAnskaffningsvardenRorelseforvarv',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden för pågående nyanläggningar och förskott avseende materiella anläggningstillgångar genom rörelseförvärv',
            })
          )
          .addRow(
            'anskaffningsvardenFusion',
            noteLabel('Fusion'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringAnskaffningsvardenFusion',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden för pågående nyanläggningar och förskott avseende materiella anläggningstillgångar genom fusion',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringAnskaffningsvardenFusion',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden för pågående nyanläggningar och förskott avseende materiella anläggningstillgångar genom fusion',
            })
          )
          .addRow(
            'salesAndDisposals',
            noteLabel('Försäljningar/utrangeringar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringAnskaffningsvardenForsaljningarUtrangeringar',
              contextRef: 'period0',
              standardRubrik:
                'Minskning av anskaffningsvärden i samband med försäljningar eller utrangeringar av pågående nyanläggningar avseende materiella anläggningstillgångar',
              negateValue: true,
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringAnskaffningsvardenForsaljningarUtrangeringar',
              contextRef: 'period1',
              standardRubrik:
                'Minskning av anskaffningsvärden i samband med försäljningar eller utrangeringar av pågående nyanläggningar avseende materiella anläggningstillgångar',
              negateValue: true,
            })
          )
          .addRow(
            'refundedAdvances',
            noteLabel('Återbetalda förskott'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringAnskaffningsvardenAterbetaladeForskott',
              contextRef: 'period0',
              standardRubrik:
                'Minskning av anskaffningsvärden i samband med periodens återbetalda förskott avseende materiella anläggningstillgångar',
              negateValue: true,
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringAnskaffningsvardenAterbetaladeForskott',
              contextRef: 'period1',
              standardRubrik:
                'Minskning av anskaffningsvärden i samband med periodens återbetalda förskott avseende materiella anläggningstillgångar',
              negateValue: true,
            })
          )
          .addRow(
            'anskaffningsvardenWrittenAdvances',
            noteLabel('Bortskrivna förskott'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringAnskaffningsvardenBortskrivnaForskott',
              contextRef: 'period0',
              standardRubrik:
                'Minskning av anskaffningsvärden i samband med bortskrivning av förskott avseende materiella anläggningstillgångar',
              negateValue: true,
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringAnskaffningsvardenBortskrivnaForskott',
              contextRef: 'period1',
              standardRubrik:
                'Minskning av anskaffningsvärden i samband med bortskrivning av förskott avseende materiella anläggningstillgångar',
              negateValue: true,
            })
          )
          .addRow(
            'reclassifications',
            noteLabel('Omklassificeringar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringAnskaffningsvardenOmklassificeringar',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden i samband med omklassificeringar av pågående nyanläggningar och förskott avseende materiella anläggningstillgångar',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringAnskaffningsvardenOmklassificeringar',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden i samband med omklassificeringar av pågående nyanläggningar och förskott avseende materiella anläggningstillgångar',
            })
          )
          .addRow(
            'anskaffningsvardenConversionDifferences',
            noteLabel('Omräkningsdifferenser'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringAnskaffningsvardenOmrakningsdifferenser',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden i samband med omräkningsdifferenser av pågående nyanläggningar och förskott avseende materiella anläggningstillgångar',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringAnskaffningsvardenOmrakningsdifferenser',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden i samband med omräkningsdifferenser av pågående nyanläggningar och förskott avseende materiella anläggningstillgångar',
            })
          )
          .addRow(
            'clossingAcquisitionValues',
            noteLabel('Utgående anskaffningsvärden'),
            ixbrlCell(
              sumNotesRowsById(
                73,
                [
                  'initialAcquisitionValues',
                  'expensesIncurred',
                  'advancesPaid',
                  'businessAcquisitions',
                  'anskaffningsvardenFusion',
                  'salesAndDisposals',
                  'refundedAdvances',
                  'anskaffningsvardenWrittenAdvances',
                  'reclassifications',
                  'anskaffningsvardenConversionDifferences',
                ],
                'year'
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarAnskaffningsvarden',
                contextRef: 'balans0',
                saldo: 'debit',
                standardRubrik:
                  'Anskaffningsvärden pågående nyanläggningar och förskott avseende materiella anläggningstillgångar',
              }
            ),
            ixbrlCell(
              sumNotesRowsById(
                73,
                [
                  'initialAcquisitionValues',
                  'expensesIncurred',
                  'advancesPaid',
                  'businessAcquisitions',
                  'anskaffningsvardenFusion',
                  'salesAndDisposals',
                  'refundedAdvances',
                  'anskaffningsvardenWrittenAdvances',
                  'reclassifications',
                  'anskaffningsvardenConversionDifferences',
                ],
                'previousYear'
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarAnskaffningsvarden',
                contextRef: 'balans1',
                saldo: 'debit',
                standardRubrik:
                  'Anskaffningsvärden pågående nyanläggningar och förskott avseende materiella anläggningstillgångar',
              }
            )
          )
          .addRow(
            'writeDownsIngoing',
            noteLabel('Ingående nedskrivningar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarNedskrivningar',
              contextRef: 'balans1',
              saldo: 'credit',
              negateValue: true,
              standardRubrik:
                'Ackumulerade nedskrivningar pågående nyanläggningar och förskott avseende materiella anläggningstillgångar',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarNedskrivningar',
              contextRef: 'balans2',
              saldo: 'credit',
              negateValue: true,
              standardRubrik:
                'Ackumulerade nedskrivningar pågående nyanläggningar och förskott avseende materiella anläggningstillgångar',
            })
          )
          .addRow(
            'changesInImpairments',
            noteLabel('Förändringar av nedskrivningar'),
            undefined,
            undefined
          )
          .addRow(
            'writeDownsFusion',
            noteLabel('Fusion'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringNedskrivningarFusion',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av nedskrivningar för pågående nyanläggningar och förskott avseende materiella anläggningstillgångar genom fusion',
              negateValue: true,
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringNedskrivningarFusion',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av nedskrivningar för pågående nyanläggningar och förskott avseende materiella anläggningstillgångar genom fusion',
              negateValue: true,
            })
          )
          .addRow(
            'writeDownsSalesAndDisposals',
            noteLabel('Försäljningar/utrangeringar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringNedskrivningarAterfordaNedskrivningarForsaljningarRespUtrangeringar',
              contextRef: 'period0',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringNedskrivningarAterfordaNedskrivningarForsaljningarRespUtrangeringar',
              contextRef: 'period1',
            })
          )
          .addRow(
            'writeDownsWrittenAdvances',
            noteLabel('Bortskrivna förskott'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringNedskrivningarBortskrivnaForskott',
              contextRef: 'period0',
              standardRubrik:
                'Minskning av nedskrivningar i samband med bortskrivning av förskott avseende materiella anläggningstillgångar',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringNedskrivningarBortskrivnaForskott',
              contextRef: 'period1',
              standardRubrik:
                'Minskning av nedskrivningar i samband med bortskrivning av förskott avseende materiella anläggningstillgångar',
            })
          )
          .addRow(
            'writeDownsReclassifications',
            noteLabel('Omklassificeringar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringNedskrivningarOmklassificeringar',
              contextRef: 'period0',
              standardRubrik:
                'Minskning (ökning) av nedskrivningar i samband med omklassificeringar av pågående nyanläggningar och förskott avseende materiella anläggningstillgångar',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringNedskrivningarOmklassificeringar',
              contextRef: 'period1',
              standardRubrik:
                'Minskning (ökning) av nedskrivningar i samband med omklassificeringar av pågående nyanläggningar och förskott avseende materiella anläggningstillgångar',
            })
          )
          .addRow(
            'writeDownsReversals',
            noteLabel('Återförda nedskrivningar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringNedskrivningarAterfordaNedskrivningar',
              contextRef: 'period0',
              standardRubrik:
                'Periodens återförda nedskrivningar av pågående nyanläggningar och förskott avseende materiella anläggningstillgångar',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringNedskrivningarAterfordaNedskrivningar',
              contextRef: 'period1',
              standardRubrik:
                'Periodens återförda nedskrivningar av pågående nyanläggningar och förskott avseende materiella anläggningstillgångar',
            })
          )
          .addRow(
            'writeDownsOfTheYear',
            noteLabel('Årets nedskrivningar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringNedskrivningarAretsNedskrivningar',
              contextRef: 'period0',
              negateValue: true,
              standardRubrik:
                'Periodens nedskrivningar av pågående nyanläggningar och förskott avseende materiella anläggningstillgångar',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringNedskrivningarAretsNedskrivningar',
              contextRef: 'period1',
              negateValue: true,
              standardRubrik:
                'Periodens nedskrivningar av pågående nyanläggningar och förskott avseende materiella anläggningstillgångar',
            })
          )
          .addRow(
            'writeDownsConversionDifferences',
            noteLabel('Omräkningsdifferenser'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringNedskrivningarOmrakningsdifferenser',
              contextRef: 'period0',
              standardRubrik:
                'Minskning (ökning) av nedskrivningar i samband med omräkningsdifferenser av pågående nyanläggningar och förskott avseende materiella anläggningstillgångar',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarForandringNedskrivningarOmrakningsdifferenser',
              contextRef: 'period1',
              standardRubrik:
                'Minskning (ökning) av nedskrivningar i samband med omräkningsdifferenser av pågående nyanläggningar och förskott avseende materiella anläggningstillgångar',
            })
          )
          .addRow(
            'writeDownsClosing',
            noteLabel('Utgående nedskrivningar'),
            ixbrlCell(
              sumNotesRowsById(
                73,
                [
                  'writeDownsIngoing',
                  'writeDownsFusion',
                  'writeDownsSalesAndDisposals',
                  'writeDownsWrittenAdvances',
                  'writeDownsReclassifications',
                  'writeDownsReversals',
                  'writeDownsOfTheYear',
                  'writeDownsConversionDifferences',
                ],
                'year'
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarNedskrivningar',
                contextRef: 'balans0',
                saldo: 'credit',
                negateValue: true,
              }
            ),
            ixbrlCell(
              sumNotesRowsById(
                73,
                [
                  'writeDownsIngoing',
                  'writeDownsFusion',
                  'writeDownsSalesAndDisposals',
                  'writeDownsWrittenAdvances',
                  'writeDownsReclassifications',
                  'writeDownsReversals',
                  'writeDownsOfTheYear',
                  'writeDownsConversionDifferences',
                ],
                'previousYear'
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarNedskrivningar',
                contextRef: 'balans1',
                saldo: 'credit',
                negateValue: true,
              }
            )
          )
          .addRow(
            'recordedValue',
            noteLabel('Redovisat värde'),
            ixbrlCell(
              sumNotesRowsById(
                73,
                ['clossingAcquisitionValues', 'writeDownsClosing'],
                'year'
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangar',
                contextRef: 'balans0',
                saldo: 'debit',
              }
            ),
            ixbrlCell(
              sumNotesRowsById(
                73,
                ['clossingAcquisitionValues', 'writeDownsClosing'],
                'previousYear'
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangar',
                contextRef: 'balans1',
                saldo: 'debit',
              }
            )
          )
          .addRow(
            'publicContributionsThatReducedAcquisitionValue',
            noteLabel('Offentliga bidrag som reducerat anskaffningsvärdet'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarOffentligaBidrag',
              contextRef: 'balans0',
              saldo: 'credit',
              standardRubrik:
                'Storlek på offentligt bidrag som reducerar anskaffningsvärdet på pågående nyanläggningar och förskott så länge tillgången nyttjas',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarOffentligaBidrag',
              contextRef: 'balans1',
              saldo: 'credit',
              standardRubrik:
                'Storlek på offentligt bidrag som reducerar anskaffningsvärdet på pågående nyanläggningar och förskott så länge tillgången nyttjas',
            })
          )
          .build()
      )
      .newRowTemplate(value(''), value(undefined), value(undefined))
      .build(),
    commentLabel: field('Kommentar'),
    comment: ixbrlField(field(''), {
      type: 'stringItemType',
      name: 'se-gen-base:NotPagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangarKommentar',
      contextRef: 'period0',
    }),
    group: value(undefined),
    section: value(undefined),
    validForReportType: {
      k2: false,
      k3: documentTypeVersion !== '1',
    },
  };
};

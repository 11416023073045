import React, { useEffect } from 'react';
import { Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import GenericSimplefied from '_shared/components/Inputs/GenericSimplefied';
import LoadingPlaceholder from '_shared/components/LoadingPlaceholder';
import { useDispatch } from 'react-redux';
import { useSelector } from 'redux/reducers';
import { getClientConnectionsToCompanies } from '_client-connections/redux/persons/actions';
import useSharesInCompany from '_client-connections/hooks/useSharesInCompany';
import ConnectionsTabWrapper, {
  ConnectionTabProps,
} from './ConnectionsTabWrapper';
import { getCompanyTypeOptions } from './ConnectionsToCompanyView';
import ShareTransactionsTable from './ShareTransactionsTable';

const NavigateBack = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)}px;
  cursor: pointer;
  p {
    font-weight: 700;
  }
`;

type ConnectionDetailsProps = ConnectionTabProps & {
  connectionId: number;
};

const ConnectionDetailsView = ({
  client,
  path,
  connectionId,
}: ConnectionDetailsProps): JSX.Element => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const history = useHistory();
  const text = (
    id: string,
    values?: Record<string, string | number | undefined>
  ) => intl.formatMessage({ id: `connections.${id}` }, values);

  const isLoading = useSelector((state) => state.ui.updatingConnections);

  const companyTypesLabels = getCompanyTypeOptions(intl);

  const { connections = [] } = client;
  const company = connections.find(
    (connection) => connection.relationId === connectionId
  );

  const sharesInCurrentCompany = useSharesInCompany(
    company?.orgId || '',
    client.id || ''
  );

  useEffect(() => {
    (async () => {
      if (!company) {
        await dispatch(
          getClientConnectionsToCompanies(
            client.id || '',
            path === 'clients' ? 'C' : 'P'
          )
        );
      }
    })();
  }, [company]);

  const onStockUpdate = async () => {
    await dispatch(
      getClientConnectionsToCompanies(
        client.id || '',
        path === 'clients' ? 'C' : 'P'
      )
    );
  };

  return (
    <ConnectionsTabWrapper
      client={client}
      title={text('detail.title')}
      description={
        <>
          <Typography>
            {text('detail.description', { firstName: client.name })}
          </Typography>
          <GenericSimplefied
            label={text('detail.orgNumber')}
            value={company?.orgNumber}
          />
          <GenericSimplefied
            label={text('detail.companyName')}
            value={company?.orgName}
          />
          <GenericSimplefied
            label={text('table.companyType')}
            value={companyTypesLabels[company?.orgType || '']}
          />
          <GenericSimplefied
            label={text('table.totalShares')}
            value={company?.stockTotals?.[0].shareTotal}
          />
        </>
      }
      navigateBack={
        <NavigateBack
          onClick={() => history.push(`/${path}/${client.id}/connections`)}
        >
          <ArrowBackIcon fontSize="small" color="primary" />
          <Typography color="primary" variant="body1">
            {text('detail.goBackToConenctionsList')}
          </Typography>
        </NavigateBack>
      }
    >
      {isLoading ? (
        <LoadingPlaceholder />
      ) : (
        <ShareTransactionsTable
          companyConnection={company}
          onStockUpdate={onStockUpdate}
          sharesInCurrentCompany={sharesInCurrentCompany}
        />
      )}
    </ConnectionsTabWrapper>
  );
};

export default ConnectionDetailsView;

import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { useIntl } from 'react-intl';
import DocumentViewServiceContext from '_shared/services/document/DocumentViewServiceContext';

const Content = styled.div`
  margin: ${({ theme }) => theme.spacing(2)}px 0;
`;

type MeetingDateProps = {
  value: string;
  maxDate: Date;
  minDate: Date;
  disabled?: boolean;
};

const fullId = 'annualGeneralMeeting.main.date';

const MeetingDate = ({
  value,
  minDate,
  maxDate,
  disabled = false,
}: MeetingDateProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const service = useContext(DocumentViewServiceContext);

  const handleDateChange = (_date, newValue: string | null | undefined) => {
    service.updateField(fullId, newValue ?? '');
  };

  return (
    <Content>
      <Typography>
        {formatMessage({
          id: `annualReport.${fullId}`,
        })}
      </Typography>
      <KeyboardDatePicker
        disableToolbar
        disabled={disabled}
        maxDate={maxDate}
        variant="inline"
        margin="dense"
        format="yyyy-MM-dd"
        autoOk
        inputVariant="outlined"
        value={null}
        inputValue={value}
        minDate={minDate}
        required
        minDateMessage={formatMessage({
          id: 'annualReport.signatures.section.auditReportDate.beforeMinDateLabel',
        })}
        maxDateMessage={formatMessage({
          id: 'annualReport.signatures.section.auditReportDate.afterMaxDateLabel.forBV',
        })}
        invalidDateMessage={formatMessage({
          id: 'annualReport.signatures.section.auditReportDate.errorLabel',
        })}
        onChange={handleDateChange}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
      />
    </Content>
  );
};

export default MeetingDate;

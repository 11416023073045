import React, { useContext, useState, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import styled from '@emotion/styled';
import AdapterDateFns from '@date-io/date-fns';
import { useDispatch } from 'react-redux';
import shortid from 'shortid';

import { useSelector } from 'redux/reducers';
import { InputData, SignaturePerson } from '_reconciliation/types';
import RichEditor from '_shared/components/RichEditor';
import EditingIconButton from '_shared/components/Buttons/EditingIconButton';
import PrintButton from '_shared/components/Buttons/PrintButton';
import ResetContentButton from '_shared/components/ResetContent/ResetContentButton';
import SignaturePeopleTable from '_shared/components/SignaturePeopleTable';
import { RowChanges } from '_shared/components/SignaturePeopleTable/EditRowView';
import { printDocument } from '_shared/components/PrintedDocument/utils';
import { addGlobalErrorMessage } from 'redux/actions';
import { AgoyTableRow } from '@agoy/document';
import { ccyFormat } from '@agoy/common';
import { parseFormat } from '@agoy/dates';
import { isInputCorrect } from 'utils';

import LocationField from '../Components/LocationField';
import AccountInfo from '../Components/AccountInfo';
import ErrorText from '../Components/ErrorText';
import CashViewPreviewModal from './CashViewPreviewModal';
import PeriodDataContext from '../PeriodDataContext';
import { SaldoHistoryContext } from '../../HiddenRowHistoryProvider';
import { usePrintState } from '../../utils';

const Container = styled.div`
  display: flex;
  padding-top: ${(props) => props.theme.spacing(2)}px;
  flex-direction: column;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  right: ${(props) => props.theme.spacing(2)}px;
  top: 60px;
`;

const TableWrapper = styled.div`
  margin: ${(props) => props.theme.spacing(2)}px 0;
  max-width: 1000px;
`;

interface CashViewProps {
  accountNumber: string;
  userData: InputData;
}

const getRows = (persons: SignaturePerson[]): AgoyTableRow[] => {
  return persons.map((person) => {
    const row = { id: shortid(), active: true, cells: {} };

    Object.keys(person).forEach((key) => {
      row.cells[key] = {
        type: 'string',
        value: person[key],
      };
    });

    return row;
  });
};

const CashView = ({ accountNumber, userData }: CashViewProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const printState = usePrintState();
  const dispatch = useDispatch();

  const { onUserInputChange } = useContext(SaldoHistoryContext);
  const { clientId, period, financialYear } = useContext(PeriodDataContext);

  const [isEditing, setEditing] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);

  const { name, orgNumber } = useSelector(
    (state) => state.customers[clientId] || {}
  );

  const { cashView, saldo, ub } = userData;

  const {
    richText = `<p style="font-size: 1rem;">${formatMessage({
      id: 'hidden.cash.defaultRichText.part1',
    })}</p><p style="font-size: 1rem;">${formatMessage(
      { id: 'hidden.cash.defaultRichText.part2' },
      { saldo: ccyFormat(saldo) }
    )}</p>`,
    signatures = [],
    location = '',
  } = cashView;

  const isCorrect =
    ub !== undefined && saldo !== undefined
      ? isInputCorrect(`${saldo}`, ub)
      : false;

  const signaturesRows = useMemo(() => {
    return getRows(signatures);
  }, [signatures]);

  const handleReset = useCallback(() => {
    onUserInputChange(
      { cashView: { ...cashView, richText: undefined } },
      accountNumber,
      period
    );
  }, [accountNumber, cashView, onUserInputChange, period]);

  const handleChangeEditor = useCallback(
    (updatedCashText) => {
      onUserInputChange(
        { cashView: { ...cashView, richText: updatedCashText } },
        accountNumber,
        period
      );
    },
    [accountNumber, cashView, onUserInputChange, period]
  );

  const handleChangeLocation = useCallback(
    (updatedLocation) => {
      onUserInputChange(
        { cashView: { ...cashView, location: updatedLocation } },
        accountNumber,
        period
      );
    },
    [accountNumber, cashView, onUserInputChange, period]
  );

  const handleAddRow = useCallback(() => {
    onUserInputChange(
      {
        cashView: {
          ...cashView,
          signatures: [
            ...signatures,
            {
              date: '',
              givenName: '',
              role: '',
              surName: '',
            },
          ],
        },
      },
      accountNumber,
      period
    );
  }, [accountNumber, signatures, cashView, onUserInputChange, period]);

  const handleEditRow = useCallback(
    (rowId, changes: Partial<RowChanges>) => {
      const rowIndex = signaturesRows.findIndex((item) => item.id === rowId);

      const updatedSignatures = [...signatures];
      updatedSignatures[rowIndex] = {
        ...updatedSignatures[rowIndex],
        ...changes,
      };

      onUserInputChange(
        { cashView: { ...cashView, signatures: updatedSignatures } },
        accountNumber,
        period
      );
    },
    [
      accountNumber,
      signatures,
      signaturesRows,
      cashView,
      onUserInputChange,
      period,
    ]
  );

  const handleDeleteRow = useCallback(
    (rowId) => {
      const rowIndex = signaturesRows.findIndex((item) => item.id === rowId);
      const updatedSignatures = [...signatures];

      updatedSignatures.splice(rowIndex, 1);

      onUserInputChange(
        { cashView: { ...cashView, signatures: updatedSignatures } },
        accountNumber,
        period
      );
    },
    [
      accountNumber,
      signatures,
      signaturesRows,
      cashView,
      onUserInputChange,
      period,
    ]
  );

  const handlePrint = async () => {
    const finYearInterval = `${parseFormat(
      financialYear.start,
      'yyyyMM'
    )}-${parseFormat(financialYear.end, 'yyyyMM')}`;

    try {
      await printDocument(
        clientId,
        finYearInterval,
        'accounting',
        formatMessage({ id: 'hidden.cash.title' }),
        ['cashView'],
        {
          ...printState,
          cashViewData: { accountNumber, signatures, richText, location },
        }
      );
    } catch {
      dispatch(addGlobalErrorMessage('error'));
    }
  };

  return (
    <MuiPickersUtilsProvider utils={AdapterDateFns}>
      <Container>
        <CashViewPreviewModal
          accountNumber={accountNumber}
          signatures={signatures}
          richText={richText}
          location={location}
          open={isModalOpen}
          onClose={() => setModalOpen(!isModalOpen)}
          onPrint={handlePrint}
        />

        <AccountInfo
          title={formatMessage({ id: 'hidden.cash.title' })}
          accountNumber={accountNumber}
          clientName={name}
          orgNumber={orgNumber || ''}
          financialYear={financialYear}
          period={period}
        />

        <RichEditor
          value={richText}
          onChange={handleChangeEditor}
          isEditing={isEditing}
          height={200}
        />

        {!isCorrect && (
          <ErrorText
            errorText={formatMessage({ id: 'hidden.cash.saldoError' })}
          />
        )}

        <LocationField onChange={handleChangeLocation} value={location} />

        <TableWrapper>
          <SignaturePeopleTable
            rows={signaturesRows}
            onAddRow={handleAddRow}
            onEditRow={handleEditRow}
            onDeleteRow={handleDeleteRow}
            showAccountantRole={false}
          />
        </TableWrapper>

        <Buttons>
          <PrintButton onClick={() => setModalOpen(true)} />
          <EditingIconButton
            value={isEditing}
            onClick={() => setEditing(!isEditing)}
          />
          <ResetContentButton what="test" onReset={handleReset} />
        </Buttons>
      </Container>
    </MuiPickersUtilsProvider>
  );
};

export default CashView;

import React from 'react';
// @ts-ignore
import { ReactComponent as ReportIcon } from 'assets/program/FinancialReportIcon.svg';
import getDisabledStatuses from '_shared/components/StatusSelector/PeriodHelpers';
import StatusSelectorComponent, {
  Tooltips,
} from '_shared/components/StatusSelector/StatusSelector';
import { currentPeriod } from '_clients/redux/customer-view/selectors';
import { TimePeriod } from '@agoy/document';
import { currentFinancialClientState } from '_financial-report/redux/selectors';
import { useSelector } from '../../../redux/reducers';

const StatusSelector = (): JSX.Element | null => {
  const period = useSelector(currentPeriod);
  const financialYear = useSelector(
    (state) => state.customerView.currentFinancialYear
  );
  const periodStatus = useSelector((state) =>
    period
      ? currentFinancialClientState(state)?.periodStatus?.[period.id]
      : undefined
  );
  const tooltips: Tooltips = {};

  const disabledStatuses = getDisabledStatuses(
    periodStatus ? periodStatus.status : null
  );

  if (!period) {
    return null;
  }

  return (
    <StatusSelectorComponent
      program="FIN_STATEMENT_REPORT"
      period={
        period.type === 'month'
          ? TimePeriod.fromISODates(period).value
          : undefined
      }
      financialYear={period.type === 'year_end' ? financialYear : undefined}
      selectedStatus={periodStatus?.history[0] ?? null}
      disabledStatuses={disabledStatuses}
      tooltips={tooltips}
      statusHistory={periodStatus?.history}
      icon={<ReportIcon />}
    />
  );
};

export default StatusSelector;

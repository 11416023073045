import React from 'react';
import { Button } from '@material-ui/core';
import styled from '@emotion/styled';
import Add from '@material-ui/icons/Add';
import { useIntl } from 'react-intl';
import Row from './Row';

const AddRow = styled(Row)`
  &:hover > * {
    border: 1px dashed ${(props) => props.theme.palette.primary.main};
    background-color: ${(props) => props.theme.palette.grey['200']};
  }
  margin-top: ${(props) => props.theme.spacing(1)}px;
  margin-bottom: ${(props) => props.theme.spacing(4)}px;
`;

const AddButton = styled(Button)`
  width: 50%;
  justify-content: flex-start;
  padding: 0.75rem;
  line-height: 1;
`;

const ValueButton = styled(Button)`
  width: 25%;
  padding: 10px 8px;
`;

interface AddTableRowProps {
  onAddRow: () => void;
}

const AddTableRow = ({ onAddRow }: AddTableRowProps) => {
  const { formatMessage } = useIntl();

  return (
    <AddRow>
      <AddButton startIcon={<Add />} onClick={onAddRow}>
        {formatMessage({ id: 'tax.addrow.label' })}
      </AddButton>
      <ValueButton onClick={onAddRow}>&nbsp;</ValueButton>
    </AddRow>
  );
};

export default AddTableRow;

import React, { useState, useMemo } from 'react';
import { VariableSizeList, ListChildComponentProps } from 'react-window';
import { Typography, Popper, TextField, Tooltip } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import styled from '@emotion/styled';
import accountPlan from '_shared/services/Intl/kontoplan.json';

const LISTBOX_PADDING = 8;
const OPTIONS = Object.keys(accountPlan);

const StyledTextField = styled(TextField)`
  .MuiAutocomplete-inputRoot {
    padding: 2px;
    padding-right: 35px !important;
  }
`;

const ItemLabel = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
`;

const StyledPopper = styled(Popper)`
  min-width: 350px;
`;

const renderRow = (props: ListChildComponentProps) => {
  const { data, index, style } = props;
  const dataSet = data[index];

  const inlineStyle = {
    ...style,
    top: (style.top as number) + LISTBOX_PADDING,
  };

  const [label, childrenProps] = dataSet.props.children;

  const fullLabel = `${label} - ${accountPlan[label]}`;

  return (
    <Tooltip enterDelay={500} enterNextDelay={500} title={fullLabel}>
      <Typography
        {...childrenProps}
        component="li"
        onClick={() => childrenProps.onClick(label)}
        style={inlineStyle}
        noWrap
      >
        <ItemLabel>{fullLabel}</ItemLabel>
      </Typography>
    </Tooltip>
  );
};

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);

  return <div ref={ref} {...props} {...outerProps} />;
});

const useResetCache = (data: any) => {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
};

const ListboxComponent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLElement>
>((props, ref) => {
  const { children, ...other } = props;

  const itemData: React.ReactChild[] = children as React.ReactChild[];

  const itemCount = itemData.length;
  const itemSize = 48;

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.reduce<number>((a) => a + itemSize, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={() => itemSize}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

type AccountSelectProps = {
  defaultValue?: string | null;
  placeholder?: string;
  fromAccountNumber?: string | null;
  toAccountNumber?: string | null;
  onChange: (value: string | null) => void;
};

const AccountSelect = ({
  defaultValue,
  placeholder,
  fromAccountNumber,
  toAccountNumber,
  onChange,
}: AccountSelectProps) => {
  const [value, setValue] = useState<string | null>(defaultValue || null);
  const [open, setOpen] = useState(false);

  const options = useMemo(
    () =>
      OPTIONS.filter((option) => {
        let result = true;
        if (fromAccountNumber) {
          result = option > fromAccountNumber;
        }
        if (toAccountNumber) {
          result = option < toAccountNumber;
        }
        return result;
      }),
    [fromAccountNumber, toAccountNumber]
  );

  const handleSelect = (text) => {
    setValue(text);
    setOpen(false);
    onChange(text);
  };

  return (
    <Autocomplete
      options={options}
      value={value}
      PopperComponent={StyledPopper}
      ListboxComponent={ListboxComponent}
      renderInput={(params) => (
        <StyledTextField
          {...params}
          variant="outlined"
          placeholder={placeholder}
          onBlur={(e) => handleSelect(e.target.value)}
        />
      )}
      renderOption={(option, props) => {
        const updatedProps = {
          ...props,
          className: 'MuiAutocomplete-option',
          onClick: handleSelect,
        };
        return [option, updatedProps];
      }}
      disableListWrap
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      freeSolo
      open={open}
    />
  );
};

export default AccountSelect;

import { value, table } from '@agoy/document';
import { NoteOneTableSection, IxbrlCell } from '../../../../common';
import { year, previousYear } from '../../../../common/utils/date-util';
import {
  active,
  field,
  ixbrlCell,
  ixbrlField,
} from '../../../../common/utils/util';

const MONETARY_ITEM_TYPE = 'monetaryItemType';

export const getNote34 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null,
  documentTypeVersion = '1'
): NoteOneTableSection => {
  return {
    active: false,
    number: value(undefined),
    name: value('Eventualförpliktelser'),
    table: table<IxbrlCell>(
      'notes.note34.table',
      { id: 'label', label: '' },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      .addRows((rows) =>
        rows
          .addRow(
            '1',
            noteLabel('Eventualförpliktelser'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:EventualForpliktelser',
              contextRef: 'balans0',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:EventualForpliktelser',
              contextRef: 'balans1',
            })
          )
          .build()
      )
      .newRowTemplate(value(''), value(undefined), value(undefined))
      .build(),
    commentLabel: field('Kommentar'),
    comment: ixbrlField(active(field('')), {
      type: 'stringItemType',
      name: 'se-gen-base:NotEventualForpliktelserKommentar',
      contextRef: 'balans0',
    }),
    group: value(undefined),
    section: value(undefined),
    validForReportType: {
      k2: true,
      k3: documentTypeVersion === '1',
    },
  };
};

import { CustomerInvoice, InvoicesFilter } from '_reconciliation/types';

const createFilterByPeriods =
  (periodList: string[]) => (invoice: CustomerInvoice) => {
    const dateString = invoice.date.split('-').join('');

    return periodList.some((period) => dateString.startsWith(period));
  };

const filterInvoices = ({
  data,
  filter,
}: {
  data: CustomerInvoice[];
  filter: InvoicesFilter;
}): CustomerInvoice[] => {
  const activeStatusList = filter.statusList
    .filter((item) => item.active)
    .map((item) => item.title);

  const statusBasedFilter =
    activeStatusList.length === 0
      ? data
      : data.filter((invoiceItem) =>
          activeStatusList.includes(invoiceItem.status)
        );

  const periodsFilter = createFilterByPeriods(Object.keys(filter.periods));
  return statusBasedFilter.filter(periodsFilter);
};

export default filterInvoices;

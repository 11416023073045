import {
  HeadingsSection,
  noteHeadingsStructure,
  boolean,
} from '../../common/types/noteSections';
import { AnnualReportPart } from '../../document';
import {
  BalanceSheetNotes,
  balanceSheetNotesStructure,
} from './balance-sheet-notes/balanceSheetNotes';
import {
  IncomeStatementNotes,
  incomeStatementNotesStructure,
} from './income-statement-notes/incomeStatementNotes';
import { OtherNotes, otherNotesStructure } from './other-notes/otherNotes';

export interface FoundationNotes
  extends AnnualReportPart,
    IncomeStatementNotes,
    BalanceSheetNotes,
    OtherNotes {
  headings: HeadingsSection;
}

export const foundationNotes = {
  type: 'part' as const,
  children: {
    active: boolean,
    headings: noteHeadingsStructure,
    ...incomeStatementNotesStructure,
    ...balanceSheetNotesStructure,
    ...otherNotesStructure,
  },
};

import React, { useCallback, useContext } from 'react';
import EditField from '_shared/components/Inputs/EditField';
import DocumentViewServiceContext from '_shared/services/document/DocumentViewServiceContext';
import StringInput from './StringInput';

interface StringFieldProps {
  className?: string;
  id: string;
  value: string;
  disabled?: boolean;
}

const StringField = ({ className, id, value, disabled }: StringFieldProps) => {
  const service = useContext(DocumentViewServiceContext);
  const onChange = useCallback(
    (newValue: string) => {
      if (id.split('.').length > 3) {
        service.updateCellValue(id, newValue);
      } else {
        service.updateField(id, newValue);
      }
    },
    [service, id]
  );

  return (
    <EditField
      className={className}
      value={value}
      onChange={onChange}
      component={StringInput}
      disabled={disabled}
    />
  );
};

export default StringField;

import React from 'react';
import { useIntl } from 'react-intl';
import { Typography, Tooltip } from '@material-ui/core';
import styled from '@emotion/styled';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { FinancialYear, Period } from '@agoy/api-sdk-core';
import { parseFormat } from '@agoy/dates';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${(props) => props.theme.spacing(1)}px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: ${(props) => props.theme.spacing(1)}px;
`;

const Title = styled(Typography)`
  font-size: 1.4rem;
  font-weight: 700;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ClientName = styled(Typography)`
  font-size: 1.4rem;
`;

const Label = styled(Typography)`
  color: ${(props) => props.theme.palette.text.secondary};
  font-size: 1rem;
`;

const Value = styled(Typography)`
  font-size: 1rem;
`;

const InfoIcon = styled(InfoOutlinedIcon)`
  height: 20px;
  margin-left: 5px;
  color: ${(props) => props.theme.palette.grey[600]};
`;

interface AccountInfoProps {
  title: string;
  tooltip?: string;
  accountNumber: string;
  orgNumber: string;
  clientName: string;
  financialYear: FinancialYear;
  period: Period;
}

const AccountInfo = ({
  title,
  tooltip,
  accountNumber,
  orgNumber,
  clientName,
  financialYear,
  period,
}: AccountInfoProps): JSX.Element => {
  const { formatMessage } = useIntl();

  const { start, end } = financialYear;

  return (
    <Container>
      <TitleWrapper>
        <Title>{title}</Title>
        {tooltip && (
          <Tooltip title={tooltip}>
            <InfoIcon />
          </Tooltip>
        )}
      </TitleWrapper>
      <ClientName>{clientName}</ClientName>
      <Row>
        <Label>{formatMessage({ id: 'hidden.cash.orgNumber' })}:</Label>
        <Value>{orgNumber}</Value>
      </Row>
      <Row>
        <Label>{formatMessage({ id: 'hidden.cash.period' })}:</Label>
        <Value>{parseFormat(period.end, 'yyyy-MM-dd')}</Value>
      </Row>
      <Row>
        <Label>{formatMessage({ id: 'hidden.cash.financialYear' })}:</Label>
        <Value>
          {start} — {end}
        </Value>
      </Row>
      <Row>
        <Label>{formatMessage({ id: 'hidden.cash.account' })}:</Label>
        <Value>{accountNumber}</Value>
      </Row>
    </Container>
  );
};

export default AccountInfo;

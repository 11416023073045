import React, { useState, useEffect } from 'react';
import { Box, TextField as MuiTextField, Typography } from '@material-ui/core';
import { KeyboardDatePicker as MuiKeyboardDatePicker } from '@material-ui/pickers';
import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';
import { useIntl } from 'react-intl';
import { format, parse, isAfter, isEqual } from 'date-fns';

import { AgoyTableRow, Cell, StringCell, stringValue } from '@agoy/document';
import EditField from '_shared/components/Inputs/EditField';
import BoardMemberTypeSelection from './BoardMemberTypeSelection';
import AccountantTitleSelection from './AccountantTitleSelection';
import Button from '../Buttons/Button';

const ExpandedContentGrid = styled.div`
  display: grid;
  grid-template-rows: repeat(4, fit-content(100%));
  height: fit-content;
`;

const ExpandedContentContainer = styled.div`
  padding: ${({ theme }) => theme.spacing(1)}px;
  display: grid;
  grid-template-columns: fit-content(100%) fit-content(100%);
  height: fit-content;
`;

const TextFieldBase = (props) => {
  return <MuiTextField variant="outlined" size="small" {...props} />;
};

const TextField = styled(TextFieldBase, {
  shouldForwardProp: (prop) => isPropValid(prop),
})``;

const KeyboardDatePicker = styled(MuiKeyboardDatePicker)`
  max-width: 200px;
  margin-top: 0;
`;

// Check if values for 'changes' are not empty or undefined
const validateField = (field: string | undefined): boolean => {
  return !!field;
};

// Check if current date is valid
const checkIsValidDate = (currentDate, comparableDate) => {
  return (
    isAfter(currentDate, comparableDate) || isEqual(currentDate, comparableDate)
  );
};

export interface RowChanges {
  givenName: string;
  surName: string;
  socialSecurityNumber: string;
  role: string;
  accountantRole: string;
  date: string;
}

interface SignaturesPeopleEditVueProps {
  baseId: string;
  row: AgoyTableRow<Cell>;
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  onEditRow: (changes: Partial<RowChanges>) => void;
  showDate: boolean;
  showAccountantRole: boolean;
  minDate?: Date;
}

const SignaturesPeopleEditView = ({
  baseId,
  row,
  setExpanded,
  onEditRow,
  showDate,
  showAccountantRole,
  minDate,
}: SignaturesPeopleEditVueProps): JSX.Element => {
  const { formatMessage } = useIntl();

  const [disabled, setDisabled] = useState(true);
  const [changes, setChanges] = useState<Partial<RowChanges>>({});

  useEffect(() => {
    if (
      !stringValue(row.cells?.date) &&
      (!minDate || checkIsValidDate(new Date(), minDate))
    ) {
      setChanges({
        ...changes,
        date: format(new Date(), 'yyyy-MM-dd'),
      });
      return;
    }
    setChanges({});
  }, [row, minDate]);

  // If the required fields for the signature are filled in 'changes', activate the save button
  useEffect(() => {
    const givenNameValid = validateField(
      changes.givenName ?? stringValue(row.cells?.givenName)
    );
    const surNameValid = validateField(
      changes.surName ?? stringValue(row.cells?.surName)
    );
    const roleValid = validateField(
      changes.role ?? stringValue(row.cells?.role)
    );
    const accountantRoleValid = validateField(
      changes.accountantRole ?? stringValue(row.cells?.accountantRole)
    );

    const cellDateValue = row.cells?.date as StringCell;
    let dateValid;

    if (!showDate) {
      dateValid = true;
    } else {
      dateValid =
        changes.date &&
        (minDate
          ? checkIsValidDate(
              parse(changes.date, 'yyyy-MM-dd', new Date()),
              minDate
            )
          : true);

      if (dateValid == null && cellDateValue.value.length > 0) {
        dateValid = true;
      }
    }

    if (
      givenNameValid &&
      surNameValid &&
      (roleValid || accountantRoleValid) &&
      dateValid &&
      Object.keys(changes).length > 0 // At least one field changed to save
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [changes, minDate, row.cells, showDate]);

  const handleDateChange = (_date, newValue: string | null | undefined) => {
    setChanges({
      ...changes,
      date: newValue ?? '',
    });
  };

  const firstNameSurNameRender = (): JSX.Element => {
    return (
      <ExpandedContentContainer>
        <Box p={1} width={265}>
          <Typography>
            {formatMessage({
              id: `${baseId}.expanded.placeholder.givenName`,
            })}
          </Typography>
          <EditField
            component={TextField}
            onChange={(value) => {
              setChanges({
                ...changes,
                givenName: value,
              });
            }}
            value={changes.givenName ?? stringValue(row.cells?.givenName) ?? ''}
          />
        </Box>
        <Box p={1}>
          <Typography>
            {formatMessage({
              id: `${baseId}.expanded.placeholder.surName`,
            })}
          </Typography>
          <EditField
            component={TextField}
            onChange={(value) => {
              setChanges({ ...changes, surName: value });
            }}
            value={changes.surName ?? stringValue(row.cells?.surName) ?? ''}
          />
        </Box>
      </ExpandedContentContainer>
    );
  };

  const personalNumberDateRender = (): JSX.Element => {
    return (
      <ExpandedContentContainer>
        <Box p={1}>
          {showDate && (
            <>
              <Typography>
                {formatMessage({
                  id: `annualReport.signatures.section.date`,
                })}
              </Typography>

              <KeyboardDatePicker
                id="date-picker-inline-signatures-date"
                format="yyyy-MM-dd"
                inputVariant="outlined"
                variant="inline"
                margin="dense"
                inputValue={changes.date ?? stringValue(row.cells?.date) ?? ''}
                onChange={handleDateChange}
                value={null}
                disableToolbar
                minDate={minDate}
                minDateMessage={formatMessage({
                  id: 'annualReport.signatures.section.date.alert',
                })}
                required
                autoOk
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                invalidDateMessage={formatMessage({
                  id: 'annualReport.signatures.section.auditReportDate.errorLabel',
                })}
              />
            </>
          )}
        </Box>
      </ExpandedContentContainer>
    );
  };

  const boardMemberAccountantRoleRender = () => {
    return (
      <ExpandedContentContainer>
        <Box p={1}>
          <Typography>
            {formatMessage({
              id: `${baseId}.expanded.placeholder.boardMemberRoles`,
            })}
          </Typography>
          <BoardMemberTypeSelection
            roles={changes.role ?? stringValue(row?.cells?.role) ?? ''}
            onChange={(roles) => {
              setChanges({ ...changes, role: roles });
            }}
          />
        </Box>
        {showAccountantRole && (
          <Box p={1}>
            <Typography>
              {formatMessage({
                id: `${baseId}.expanded.placeholder.accountantTitleRoles`,
              })}
            </Typography>
            <AccountantTitleSelection
              titles={
                changes.accountantRole ??
                stringValue(row?.cells?.accountantRole) ??
                ''
              }
              onChange={(roles) => {
                setChanges({
                  ...changes,
                  accountantRole: roles,
                });
              }}
            />
          </Box>
        )}
      </ExpandedContentContainer>
    );
  };

  const saveAndCancelButton = () => {
    return (
      <Box display="flex" p={1}>
        <Button
          label={formatMessage({
            id: `cancel`,
          })}
          onClick={() => setExpanded(false)}
          variant="text"
          size="medium"
        />
        <Box ml={1}>
          <Button
            label={formatMessage({
              id: `save`,
            })}
            disabled={disabled}
            onClick={() => onEditRow(changes)}
            size="medium"
          />
        </Box>
      </Box>
    );
  };

  return (
    <ExpandedContentGrid>
      {firstNameSurNameRender()}
      {personalNumberDateRender()}
      {boardMemberAccountantRoleRender()}
      {saveAndCancelButton()}
    </ExpandedContentGrid>
  );
};

export default SignaturesPeopleEditView;

import {
  AgoyDocument,
  ReferenceErrorType,
  ResolvedReference,
  ResolveReferenceContext,
  ResolveReferenceInput,
  PropsOfType,
  AgoyDocumentChanges,
} from '@agoy/document';

type configNames = PropsOfType<
  TaxCalculationConfig,
  TaxTableConfig<TaxCalculationRow>
>;
type viewNames = PropsOfType<TaxView, TaxTable | null>;
export type TaxCalculationPart = configNames & viewNames;

export interface YearEndPlanning extends TaxTable {
  outdated: boolean;
  checksum: string;
  resultAfterYearEndPlanningBeforeTaxes: TaxCalculationRow;
}

export interface YearEndPlanningConfig extends TaxTableConfig {
  checksum?: string;
}

export interface TaxCalculationRow {
  id: string; // Id for references and label
  label?: string; // Actual text for label
  labelId?: string; // Order of label lookup, is 'label', otherwise 'labelId' and otherwise 'id'
  value?: number;
  reference?: string;
  hidden?: boolean;
  hiddenWhenEmpty?: boolean;
  error?: ReferenceErrorType | undefined;
  deleted?: boolean;
  note?: string;
}

export interface TaxSubSection {
  active?: boolean;
  title: string;
  section: string;
}

export interface TaxCalculation extends TaxTable {
  rows: TaxCalculationRow[];
  moreRows: TaxCalculationRow[];
  warningLowAmount: boolean;
}

export interface TaxTable<
  RowType extends TaxCalculationRow = TaxCalculationRow
> {
  rows: RowType[];
  sum: RowType;
}

export interface ParticularSalaryTax extends TaxTable {
  particularSalaryTax?: TaxCalculationRow;
  particularSalaryTaxFromSum?: TaxCalculationRow;
  alreadyBookedParticularSalaryTax?: TaxCalculationRow;
  particularSalaryTaxToBook?: TaxCalculationRow;

  warningLowAmount: boolean;
  warningHighAmount: boolean;
}

export interface PossibleDepositionToAccrualFund {
  value: number;
  furtherDeposition: boolean;
}

// holder for the attachments in Bokslutsdokument
export interface TaxDocument {
  value: string;
  label: string;
}

export interface TaxDocumentAttachment {
  name: string;
  category: string;
  url: string;
  lastModified: string;
  __typename?: string;
}

export const mainRule = {
  type: 'part' as const,
  children: {
    closingTaxValueOfThePreviousTaxYear: {
      type: 'field' as const,
    },
    yearAcquisitionsThatRemainAtTheEndOfTheYear: {
      type: 'field' as const,
    },
    deduction: {
      type: 'field' as const,
    },
    disposal: {
      type: 'field' as const,
    },
    insuranceCompensation: {
      type: 'field' as const,
    },
    particularlyHighExpenditure: {
      type: 'field' as const,
    },
    depreciationBasis: {
      type: 'field' as const,
    },
    minimumAllowedTaxValueAccordingToTheMainRule: {
      type: 'field' as const,
    },
  },
};

export const excessDepreciation = {
  type: 'part' as const,
  children: {
    totalSelectedRuleForExcessDepreciation: {
      type: 'field' as const,
    },
    closingPlannedBookValue: {
      type: 'field' as const,
    },
    openingAccumulatedExcessDepreciation: {
      type: 'field' as const,
    },
    depositionOrDissolution: {
      type: 'field' as const,
    },
  },
};

export const overdepreciation = {
  type: 'part' as const,
  children: {
    totalAmount: { type: 'field' as const },
    intangibleAssets: {
      type: 'part' as const,
      children: {
        useMainRule: {
          type: 'field' as const,
        },
        active: {
          type: 'field' as const,
        },
        mainRule,
        overDepreciationVoucher: { type: 'table' as const },
        excessDepreciation,
        additionalRule: {
          type: 'part' as const,
          children: {
            completionRuleTable: {
              type: 'table' as const,
            },
            depreciationBasis: {
              type: 'field' as const,
            },
          },
        },
      },
    },
    buildingsAndGroundFacilities: {
      type: 'part' as const,
      children: {
        useMainRule: {
          type: 'field' as const,
        },
        active: {
          type: 'field' as const,
        },
        mainRule,
        overDepreciationVoucher: { type: 'table' as const },
        excessDepreciation,
        additionalRule: {
          type: 'part' as const,
          children: {
            completionRuleTable: {
              type: 'table' as const,
            },
            depreciationBasis: {
              type: 'field' as const,
            },
          },
        },
      },
    },
    machinesAndInventory: {
      type: 'part' as const,
      children: {
        useMainRule: {
          type: 'field' as const,
        },
        active: {
          type: 'field' as const,
        },
        mainRule,
        overDepreciationVoucher: { type: 'table' as const },
        excessDepreciation,
        additionalRule: {
          type: 'part' as const,
          children: {
            completionRuleTable: {
              type: 'table' as const,
            },
            depreciationBasis: {
              type: 'field' as const,
            },
          },
        },
      },
    },
  },
};

export const contentDefinition = {
  type: 'document' as const,
  children: {
    previousYear: {
      type: 'externalDocument' as const,
      documentType: 'financialStatement',
      financialYear: -1,
    },
    overdepreciation,
    particularSalaryTax: {
      type: 'part' as const,
      children: {
        fora: {
          type: 'part' as const,
          children: {
            salaryBase: {
              type: 'field' as const,
            },
            accounts: {
              type: 'table' as const,
            },
            accountsSum: {
              type: 'field' as const,
            },
            unpaidFORA: {
              type: 'field' as const,
            },
            accruedDebtToFORA: {
              type: 'field' as const,
            },
            accruedDebtNote: {
              type: 'field' as const,
            },
            paidPremiumsPreviousYear: {
              type: 'field' as const,
            },
            helper: {
              type: 'part' as const,
              children: {
                salaryBase: {
                  type: 'table' as const,
                },
              },
            },
          },
        },
        itp: {
          type: 'part' as const,
          children: {
            itpPremiums: {
              type: 'field' as const,
            },
            unpaidITP: {
              type: 'field' as const,
            },
            ITPKPremiums: {
              type: 'table' as const,
            },
            ITPKPremiumsSum: {
              type: 'field' as const,
            },
            unpaidITPK: {
              type: 'field' as const,
            },
            unpaidITPKNote: {
              type: 'field' as const,
            },
            helper: {
              type: 'part' as const,
              children: {
                premiums: {
                  type: 'table' as const,
                },
              },
            },
          },
        },
        otherPensionCommitments: {
          type: 'part' as const,
          children: {
            otherPensionInsurances: {
              type: 'table' as const,
            },
            otherPensionInsurancesSum: {
              type: 'field' as const,
            },
            foreignPensionInsurances: {
              type: 'table' as const,
            },
            foreignPensionInsurancesSum: {
              type: 'field' as const,
            },
            paymentPensionFoundation: {
              type: 'field' as const,
            },
            paymentPensionFoundationNote: {
              type: 'field' as const,
            },
            compensationPensionCommitments: {
              type: 'field' as const,
            },
            compensationPensionCommitmentsNote: {
              type: 'field' as const,
            },
            pensionProvisions: {
              type: 'field' as const,
            },
            pensionProvisionsNote: {
              type: 'field' as const,
            },
          },
        },
        summarize: {
          type: 'part' as const,
          children: {
            taxationBasis: {
              type: 'field' as const,
            },
            particularSalaryTax: {
              type: 'field' as const,
            },
            recordedPayrollTax: {
              type: 'table' as const,
            },
            recordedPayRollTaxSum: {
              type: 'field' as const,
            },
            taxToBeBooked: {
              type: 'field' as const,
            },
          },
        },
        isDetailedSpecification: { type: 'field' as const },
        finacialStatementVerification: {
          type: 'part' as const,
          children: {
            transactions: {
              type: 'table' as const,
            },
          },
        },
        notYetBookedParticualSalaryTax: { type: 'field' as const },
      },
    },
    accrualFunds: {
      type: 'part' as const,
      children: {
        specificationReversalOfAccrualFund: { type: 'table' as const },
        depositionOfAccrualFund: { type: 'table' as const },
        adjustments: { type: 'table' as const },
      },
    },
    yearTaxVoucher: { type: 'table' as const },
    yearResultVoucher: { type: 'table' as const },
    otherAdjustmentsVoucher: { type: 'table' as const },
    groupContributionVoucher: { type: 'table' as const },
    expandedVouchers: {
      type: 'part' as const,
      children: {
        particularSalaryTax: { type: 'field' as const },
        accrualFunds: { type: 'field' as const },
        yearTax: { type: 'field' as const },
        yearResult: { type: 'field' as const },
        propertyTax: { type: 'field' as const },
        otherAdjustmentsVoucher: { type: 'field' as const },
        groupContributionVoucher: { type: 'field' as const },
        intangibleAssets: { type: 'field' as const },
        buildingsAndGroundFacilities: { type: 'field' as const },
        machinesAndInventory: { type: 'field' as const },
      },
    },
    propertyTaxTotal: { type: 'field' as const },
  },
};

export type TaxViewDocument = AgoyDocument<typeof contentDefinition>;
export type TaxViewDocumentChanges = AgoyDocumentChanges<
  typeof contentDefinition
>;

export interface TaxView {
  notBookedParticualSalaryTax: TaxCalculationRow;
  resultBeforeFrom3000to8799: TaxCalculationRow;
  yearEndPlanning: YearEndPlanning | null;
  resultBeforeTaxes: TaxCalculationRow;
  taxCalculation: TaxCalculation;
  nonTaxableIncomes: TaxTable | null;
  nonDeductibleExpenses: TaxTable | null;
  particularSalaryTax: ParticularSalaryTax | null;
  accrualFunds: AccrualFunds | null;
  accrualFundsAccounts: TaxTable | null;
  maxPossibleDepositionToAccrualFund: PossibleDepositionToAccrualFund | null;
  adjustments: TaxTable<TransactionRow>;
  taxDocuments: TaxDocument[];
  rowsById: Record<string, TaxCalculationRow>;
  document: TaxViewDocument;
  internspecifications: Record<string, TaxSubSection>;
  externspecifications: Record<string, TaxSubSection>;
  overdepreciation: Record<string, TaxSubSection>;
}

export interface TaxCalculationContext extends ResolveReferenceContext {
  config: TaxCalculationConfig;
  rowsById: Record<string, TaxCalculationRow>;
  input: TaxCalculationInput;
  refs: Record<string, ResolvedReference>;
}

export interface TaxTableConfig<
  RowType extends TaxCalculationRow = TaxCalculationRow
> {
  rows: RowType[];
  sum: RowType;
  lastModified?: string;
}

export interface TaxCalculationConfig {
  // Ännu ej bokförd särskild löneskatt enligt beräkning nedan
  notBookedParticualSalaryTax: TaxCalculationRow;

  // Årets resultat för konto 3000-8799
  resultBeforeFrom3000to8799: TaxCalculationRow;

  // Bokslutsplanering
  yearEndPlanning: YearEndPlanningConfig;
  yearEndPlanningResultBeforeTaxes: TaxCalculationRow;

  // Resultat INNAN skatt
  resultBeforeTaxes: TaxCalculationRow;

  // Skatteberäkning
  taxCalculation: TaxTableConfig;
  taxCalculationMoreRows: TaxCalculationRow[];

  // Icke skattepliktiga intäkter
  nonTaxableIncomes: TaxTableConfig;

  // Ej avdragsgilla kostnader
  nonDeductibleExpenses: TaxTableConfig;

  // Särskild löneskatt
  particularSalaryTax: TaxTableConfig;
  alreadyBookedParticularSalaryTax: TaxCalculationRow;
  particularSalaryTaxToBook: TaxCalculationRow;

  // Periodiseringsfonder
  accrualFunds: TaxTableConfig;
  accrualFundsRows: TaxCalculationRow[];
  accrualFundsAccounts: TaxTableConfig;

  // Bokslutsjustering
  adjustments: TaxTableConfig<TransactionRow>;

  taxDocuments: TaxDocument[];

  // Taxes
  salaryTax: number;
  companyTax: number;
  templateTax: number;

  // New document !!
  document: TaxViewDocument;
  documentChanges: TaxViewDocumentChanges;
}

export interface TaxCalculationInput extends ResolveReferenceInput {
  sieUpdated: string;
  lastPeriod: boolean;
  yearPercentage: number;
}

export interface AccrualFunds extends TaxTable {
  moreRows: TaxCalculationRow[];
  rows: TaxCalculationRow[];
}

export interface TransactionRow extends TaxCalculationRow {
  account?: string;
  accountValue?: number;
}

export interface Voucher {
  transactionDate: string;
  description: string;
  series?: string; // TODO how to select serie?
  rows: VoucherRow[];
}

export interface VoucherRow {
  transactionInformation: string;
  account: string;
  credit: number;
  debit: number;
}

export type StoredTaxConfig = Pick<TaxCalculationConfig, TaxCalculationPart>;

import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Typography, Button } from '@material-ui/core';

import { CTAButton } from '_shared/components/Buttons';
import FormError from '_shared/components/Inputs/FormError';
import { SimplifiedTextfield } from '_shared/components/Inputs';
import useAuth from '_authentication/hooks/useAuth';
import useQuery from '_authentication/hooks/useQuery';
import { useHistory } from 'react-router-dom';
import { Account } from '../../types';
import {
  LoginBox,
  LinkWrapper,
  TextField,
  UnderlineLink,
  ActionsWrapper,
} from './SharedComponents';

type Props = {
  backToLogin: (email?: string) => void;
  accountData: Account;
  openConfirm: (email?: string) => void;
};

const CreateContent: React.FC<Props> = ({
  backToLogin,
  openConfirm,
  accountData,
}: Props) => {
  const { formatMessage } = useIntl();
  const msg = (id: string) => formatMessage({ id });
  const auth = useAuth();
  const history = useHistory();

  const queryEmail = useQuery().get('email');
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm();
  const [submitError, setSubmitError] = useState<string | JSX.Element>('');

  const isRequired = {
    required: msg('form.fieldRequired'),
  };

  const passwordRef = useRef(null);
  passwordRef.current = watch('password', '');

  const ToPasswordReset = (email: string) => {
    return (
      <Typography
        component="span"
        display="inline"
        style={{ whiteSpace: 'pre-wrap' }}
      >
        {formatMessage({
          id: 'register.exists',
        })}
        <UnderlineLink
          display="inline"
          onClick={() => history.push('login', { reset: true, email })}
        >
          {formatMessage({ id: 'register.exists.link' })}
        </UnderlineLink>
        .
      </Typography>
    );
  };

  const handleFormSubmit = async (data) => {
    const { username, password, givenName, familyName } = data;
    try {
      await auth.signUpAWS({ username, password, givenName, familyName });
      openConfirm(data);
    } catch (error: any) {
      let message: string | JSX.Element;
      message = msg('error');

      if (error.name === 'UsernameExistsException') {
        message = ToPasswordReset(data.username);
      } else if (
        error.name === 'UserLambdaValidationException' &&
        error.message.endsWith('Domain not approved.')
      ) {
        message = msg('register.illegalDomain');
      } else if (
        error.name === 'InvalidParameterException' &&
        error.message.endsWith('must have length greater than or equal to 6')
      ) {
        message = msg('register.passwordTooShort');
      }

      setSubmitError(message);
    }
  };

  return (
    <LoginBox onSubmit={handleSubmit(handleFormSubmit)}>
      <Typography variant="h2">{msg('create.account')}</Typography>
      <Typography>{msg('create.account.text')}</Typography>

      <SimplifiedTextfield
        {...register('givenName', isRequired)}
        labelPlacement="top"
        placeholder={msg('editprofile.firstName')}
        variant="outlined"
        fullWidth
        editing
        defaultValue={accountData.givenName || ''}
      />

      <FormError>{errors.givenName?.message}</FormError>

      <SimplifiedTextfield
        {...register('familyName', isRequired)}
        labelPlacement="top"
        placeholder={msg('editprofile.lastName')}
        variant="outlined"
        fullWidth
        editing
        defaultValue={accountData.familyName || ''}
      />

      <FormError>{errors.familyName?.message}</FormError>

      <TextField
        {...register('username', isRequired)}
        variant="outlined"
        size="small"
        defaultValue={queryEmail || accountData.username || ''}
        placeholder={msg('email.placeholder')}
        type="email"
      />

      <FormError>{errors.username?.message}</FormError>

      <TextField
        {...register('password', isRequired)}
        variant="outlined"
        size="small"
        defaultValue={accountData.password || ''}
        placeholder={msg('password')}
        type="password"
      />

      <TextField
        {...register('extraPassword', {
          validate: (value) =>
            value === passwordRef.current ||
            formatMessage({ id: 'form.passwordNoMatch' }),
        })}
        variant="outlined"
        size="small"
        placeholder={msg('password.repeat')}
        type="password"
      />

      <FormError>{errors.extraPassword?.message}</FormError>

      {submitError && <FormError>{submitError}</FormError>}

      <LinkWrapper>
        <UnderlineLink onClick={() => openConfirm()}>
          {msg('login.writeConformation')}
        </UnderlineLink>
      </LinkWrapper>

      <ActionsWrapper>
        <Button onClick={() => backToLogin()}>{msg('forgot.back')}</Button>
        <CTAButton type="submit" loading={auth.isLoading}>
          {formatMessage({ id: 'register.register' })}
        </CTAButton>
      </ActionsWrapper>
    </LoginBox>
  );
};

export default CreateContent;

import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Box } from '@material-ui/core';

import { useSelector } from 'redux/reducers';
import {
  setAccountListDrawerExpanded,
  setAccountListDrawerMode,
  setSelectedAccounts,
  setCurrentAccount as setCurrentAccountAction,
} from '_shared/redux/account-list-drawer/actions';
import { AccountingAccount, FinancialYear } from '@agoy/api-sdk-core';
import useClientDataLayer from 'data/client/useClientDataLayer';
import Button from './Buttons/Button';
import { ButtonProps } from './Buttons/Button.types';

type AccountSelectorProps = {
  rowId: string;
  clientId: string;
  financialYear: FinancialYear;
  account?: string;
  value?: any;
  onClick?: (ev) => void;
  onAccountSelected?: (
    selectedAccount: string | undefined,
    selectedAccountName: string | undefined,
    accountId: string,
    close?: () => void
  ) => void;
  tableSelectedAccounts?: string[] | [];
} & Partial<Pick<ButtonProps, 'variant' | 'startIcon'>>;

const AccountSelector = React.forwardRef<
  HTMLButtonElement,
  AccountSelectorProps
>(
  (
    {
      rowId,
      account,
      value,
      clientId,
      financialYear,
      onClick = () => {},
      onAccountSelected = () => {},
      tableSelectedAccounts = [],
      variant = 'outlined',
      startIcon,
    }: AccountSelectorProps,
    ref
  ) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const clientDataLayer = useClientDataLayer(clientId);

    const {
      selectionOwner,
      currentAccount: currentSelectedAccount,
      selectedAccounts,
    } = useSelector((state) => state.accountListDrawer);

    const [accounts, setAccounts] = useState<AccountingAccount[] | undefined>();
    const [currentAccount, setCurrentAccount] = useState<string | undefined>();

    const handleClose = useCallback(() => {
      dispatch(setAccountListDrawerExpanded(false));
      dispatch(setAccountListDrawerMode('viewing'));
    }, [dispatch]);

    const handleClick = useCallback(
      (ev) => {
        dispatch(setSelectedAccounts(tableSelectedAccounts));
        dispatch(setCurrentAccountAction(currentAccount));
        dispatch(setAccountListDrawerExpanded(true));
        dispatch(
          setAccountListDrawerMode('selecting-accounts', financialYear, rowId)
        );
        onClick(ev);
      },
      [
        dispatch,
        currentAccount,
        financialYear,
        rowId,
        onClick,
        tableSelectedAccounts,
      ]
    );

    useEffect(() => {
      setCurrentAccount(account);
    }, [account]);

    useEffect(() => {
      const subscription = clientDataLayer.accountingBalances
        .getAccountingBalances(financialYear.id)
        .subscribe((result) => {
          if (result.ok) {
            setAccounts(result.val?.accountingBalances.accounts);
          }
        });
      return () => {
        subscription.unsubscribe();
      };
    }, [clientDataLayer.accountingBalances, financialYear.id]);

    useEffect(() => {
      if (
        currentAccount !== currentSelectedAccount &&
        rowId === selectionOwner
      ) {
        const accountName =
          currentSelectedAccount &&
          accounts?.find((acc) => acc.number === currentSelectedAccount)?.name;
        onAccountSelected(
          currentSelectedAccount,
          accountName,
          rowId,
          handleClose
        );
        setCurrentAccount(currentSelectedAccount);
      }
    }, [
      onAccountSelected,
      rowId,
      selectionOwner,
      currentAccount,
      handleClose,
      accounts,
      selectedAccounts,
      currentSelectedAccount,
    ]);

    const getButtonLabel = () => {
      if (value) {
        return value.toString();
      }

      return account
        ? intl.formatMessage({ id: 'accountSelector.changeAccount' })
        : intl.formatMessage({ id: 'accountSelector.selectAccount' });
    };

    return (
      <Box>
        <Button
          label={getButtonLabel()}
          onClick={handleClick}
          ref={ref}
          variant={variant}
          startIcon={startIcon}
        />
      </Box>
    );
  }
);

export default AccountSelector;

import React from 'react';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';
import { AgoyTable, numberValue } from '@agoy/document';
import { CompanyType } from '_clients/types/types';
import CalculationsTable from '../../components/CalculationsTable';
import FinalTaxCalculationPageHeader from '../../components/FinalTaxCalculationPageHeader';

const Header = styled.div`
  margin-bottom: 1rem;
`;

const CalculationsTableWrapper = styled.div`
  margin-bottom: 32px;
`;

type AdditionalPagesProps = {
  isPrint?: boolean;
  showSumRow?: (table: TableWithId) => boolean;
  page: TableWithId[];
  currentPageNumber: number;
  totalPageNumber: number;
  rawTable?: (table: TableWithId) => AgoyTable | undefined;
  orgNr?: string | null;
  companyType: CompanyType;
  startDate?: string;
  endDate?: string;
};

type TableWithId = { table: AgoyTable; id: string };

const AdditionalPage = ({
  currentPageNumber,
  totalPageNumber,
  page,
  isPrint = false,
  showSumRow = () => true,
  rawTable = () => undefined,
  orgNr,
  companyType,
  startDate,
  endDate,
}: AdditionalPagesProps) => {
  const { formatMessage } = useIntl();

  const getSum = (table: TableWithId) => {
    return numberValue(rawTable(table)?.rows[1]?.cells?.value) || 0;
  };

  return (
    <>
      <Header>
        <FinalTaxCalculationPageHeader
          orgNumber={orgNr}
          startDate={startDate}
          endDate={endDate}
          currentPage={currentPageNumber}
          totalPages={totalPageNumber}
          companyType={companyType}
        />
      </Header>

      {page.map((table) => (
        <CalculationsTableWrapper key={table.id}>
          <CalculationsTable
            id={table.id}
            table={table.table}
            isPrint={isPrint}
            title={formatMessage(
              { id: `${table.id}` },
              {
                parameter: formatMessage({
                  id: `finalTaxCalculations.deficitCalculations.${
                    getSum(table) >= 0 ? 'plus' : 'minus'
                  }`,
                }),
              }
            )}
            showSumRow={showSumRow(table)}
            rawTable={rawTable(table)}
          />
        </CalculationsTableWrapper>
      ))}
    </>
  );
};

export default AdditionalPage;

import { TaxView } from '@agoy/tax-document';

export const findAccrualFundValue = (
  state: TaxView | null,
  id: string
): number | undefined =>
  state?.accrualFunds?.moreRows?.find((row) => row.id === id)?.value ||
  state?.accrualFunds?.rows?.find((row) => row.id === id)?.value;

export const findYearEndValue = (
  state: TaxView | null,
  id: string
): number | undefined =>
  state?.yearEndPlanning?.rows?.find((row) => row.id === id)?.value;

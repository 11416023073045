import React, { useCallback, useContext } from 'react';
import { useIntl } from 'react-intl';
import styled from '@emotion/styled';
import { Paper as MuiPaper } from '@material-ui/core';
import { useDispatch } from 'react-redux';

import { isTaxDeclarationModule } from 'utils/TaxDeclaration/tax-declaration-util';
import useModule from '_tax/hooks/useModule';
import { RESET_TAX_CALCULATION_TABLE } from '_tax/redux/tax-view/action-types';
import { printDocument } from '_shared/components/PrintedDocument/utils';
import usePrintState from '_tax/hooks/usePrintState';
import { addGlobalErrorMessage } from 'redux/actions';

import TaxTable from './TaxTable';
import TaxesDataContext from '../../service/TaxesDataContext';

const Wrapper = styled.div`
  flex: 1;
`;

const Paper = styled(MuiPaper)`
  height: 100%;
`;

type TaxCalculationProps = {
  isPrint?: boolean;
};

const TaxCalculation = ({
  isPrint = false,
}: TaxCalculationProps): JSX.Element | null => {
  const { formatMessage } = useIntl();
  const moduleContext = useModule();
  const printState = usePrintState();
  const dispatch = useDispatch();

  const { taxYearData, service, clientId, financialYear } =
    useContext(TaxesDataContext);

  const taxTable = taxYearData.state?.taxCalculation;

  const reset = useCallback(() => {
    service.resetGenericTax(RESET_TAX_CALCULATION_TABLE);
  }, [service]);

  const handlePrint = useCallback(async () => {
    try {
      await printDocument(
        clientId,
        financialYear,
        'taxes',
        formatMessage({ id: `tax.taxCalculation.title` }),
        ['taxCalculation'],
        printState
      );
    } catch (e) {
      dispatch(addGlobalErrorMessage('error'));
    }
  }, [clientId, dispatch, financialYear, formatMessage, printState]);

  if (!taxTable) {
    return null;
  }

  const warning = taxTable.warningLowAmount
    ? formatMessage({ id: 'tax.warning.taxCalculation.lowAmount' })
    : undefined;

  const taxDeclarationModule = isTaxDeclarationModule(moduleContext);

  return (
    <Wrapper>
      <Paper>
        <TaxTable
          part="taxCalculation"
          additionalRows={taxTable.moreRows}
          mainTable
          warning={warning}
          onReset={reset}
          disableEditing={taxDeclarationModule}
          onPrint={handlePrint}
          print={isPrint}
          printable
        />
      </Paper>
    </Wrapper>
  );
};

export default TaxCalculation;

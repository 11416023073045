import {
  TaxDeclarationFormDefinition,
  TaxDeclarationFormDataConfig,
} from '../../types';
import { value } from '@agoy/document';
import { mapFormId, toInitial, toStructure } from '../forms-util';

const configN11 = (
  form: TaxDeclarationFormDefinition,
  financialYearStart: string,
  financialYearEnd: string
): TaxDeclarationFormDataConfig => {
  const N11 = mapFormId(form, 'N11');
  const initial = {
    N11: {
      partType: 'form' as const,
      id: N11,
      fields: {
        datum: value(''),
        company_name: value(''),
        org_number: value(''),
        A_1: value(undefined),
        B_1: value(undefined),
        B_2: value(undefined),
        B_3: value(undefined),
        B_4: value(undefined),
        B_5: value(undefined),
        C_1: value(undefined),
        C_2: value(undefined),
        D_1: value(undefined),
        D_2: value(undefined),
        D_3: value(undefined),
        D_4: value(undefined),
        D_5: value(undefined),
        D_6: value(undefined),
        D_7: value(undefined),
        E_2_1: value(undefined),
        E_2_2: value(undefined),
        E_2_3: value(undefined),
        E_2_4: value(undefined),
        E_2_5: value(undefined),
        E_2_6: value(undefined),
        E_2_7: value(undefined),
        E_2_8: value(undefined),
        E_2_9: value(undefined),
        E_2_10: value(undefined),
        E_2_11: value(undefined),
        E_2_12: value(undefined),
        E_2_13: value(undefined),
        E_2_14: value(undefined),
        E_2_15: value(undefined),
        E_2_16: value(undefined),
        E_2_17: value(undefined),
        E_2_18: value(undefined),
        E_2_19: value(undefined),
        E_2_20: value(undefined),
        E_2_21: value(undefined),
        E_2_22: value(undefined),
        E_2_23: value(undefined),
        E_2_24: value(undefined),
        E_2_25: value(undefined),
        E_2_26: value(undefined),
        E_2_27: value(undefined),
        E_2_28: value(undefined),
        E_2_31: value(undefined),
        E_2_32: value(undefined),
        E_2_33: value(undefined),
        E_2_34: value(undefined),
        E_2_35: value(undefined),
        E_2_36: value(undefined),
        E_2_37: value(undefined),
        E_2_38: value(undefined),
        E_2_39: value(undefined),
        E_2_40: value(undefined),
        E_2_41: value(undefined),
        E_2_42: value(undefined),
        E_2_43: value(undefined),
        E_2_44: value(undefined),
        E_2_45: value(undefined),
        E_2_46: value(undefined),
        E_2_47: value(undefined),
        E_2_48: value(undefined),
        E_2_49: value(undefined),
        E_2_50: value(undefined),
        E_3_1: value(undefined),
        E_3_3: value(undefined),
        E_3_2_pos: value(undefined),
        E_3_2_neg: value(undefined),
        E_3_4: value(undefined),
        E_3_5: value(undefined),
        E_3_6: value(undefined),
        E_3_7: value(undefined),
        E_3_8: value(undefined),
        E_3_9: value(undefined),
        E_3_10: value(undefined),
        E_3_11: value(undefined),
        E_3_12_pos: value(undefined),
        E_3_12_neg: value(undefined),
        E_3_13_pos: value(undefined),
        E_3_13_neg: value(undefined),
        E_3_14_pos: value(undefined),
        E_3_14_neg: value(undefined),
        E_3_15_pos: value(undefined),
        E_3_15_neg: value(undefined),
        E_3_16: value(undefined),
        E_3_17: value(undefined),
        E_3_18: value(undefined),
        E_3_24_pos: value(undefined),
        E_3_24_neg: value(undefined),
        E_3_25: value(undefined),
        E_3_26: value(undefined),
        E_3_27: value(undefined),
        F_pos: value(undefined),
        F_neg: value(undefined),
      },
      sru: {},
    },
  };
  return {
    initial: toInitial(initial, form),
    definition: toStructure(initial, form),
  };
};

export default configN11;

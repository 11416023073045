import { UpdateFinancialReportField } from '_financial-report/redux/action-type-declarations';
import { contentDefinition } from '@agoy/financial-report-document';
import { isError, operations } from '@agoy/document';

import { FinancialReportClientPeriodState } from '../types';

const updateFinancialReportField = (
  state: FinancialReportClientPeriodState,
  action: UpdateFinancialReportField
): FinancialReportClientPeriodState => {
  if (!state.report) {
    return state;
  }
  const { id, value } = action;

  // 4701 TODO: we may need to adapt the contentDefinition below according to the companyType
  const result = operations.updateField(
    contentDefinition,
    {
      changes: state.changes,
      document: state.report,
    },
    id,
    value
  );

  if (isError(result)) {
    console.log('Error in updateFinancialReportField', result);
    return state;
  }
  if (!result) {
    return state;
  }
  return {
    ...state,
    report: result.document,
    changes: result.changes,
  };
};
export default updateFinancialReportField;

export const modalIcon = '#48a6ff';

export const orange = '#F8AF61';
export const white = '#FFFFFF';

export const primaryMain = '#007533';
export const primaryLight = '#58B856';
export const secondaryMain = '#FFC200';

export const info = '#4C95C3';
export const error = '#D35B4F';
export const warning = '#FFD86C';
export const success = '#77B28F';

export const textPrimary = '#000';
export const textSecondary = '#000000a6';

// new colors to be used for text
export const textOnDark = '#ffffff';
export const textOnLight = '#002517';
export const textHeadingOnLight = '#003824';
export const textReadOnlyOnLight = '#0000004D';
export const textPlaceholder = '#656D6A';

// Specific variations of colors used in account overview cells
export const cellIncomingBalance = '#E1ECF1';
export const cellTotalChange = '#EDF3F6';
export const cellOutgoingBalance = '#F4F8F9';
export const cellOk = '#DCF9E7';
export const cellWarning = '#F9CACF';
export const cellStarted = '#F9EBDC';
export const cellChecked = '#087046';

// Specific variations of colors used in overview
export const accountStatusWrong = '#f4a4ac';
export const currentMonthBackground = '#D1C6D6';
export const closingMonthBackground = '#D4E3E3';

// Transaction analaysis colors
export const gridBorderColor = '#e7e6e6';
export const dimensionBackgroundColor = '#e6e6e6';

// Popup with setting actual saldo
export const popupHeader = '#ccd5d8';

// Table colors
export const headerBackgroundColor = '#f5f5f5';

// color palette
export const green = {
  HIGH: '#007533',
  MEDIUM: '#A1CBAD',
  LOW: '#E5F9E5',
  DARK: '#003824',
};

export const yellow = {
  HIGH: '#FFC200',
  MEDIUM: '#FFE699',
  LOW: '#FFF7DB',
};

export const red = {
  HIGH: '#930E00',
  MEDIUM: '#EB978E',
  LOW: '#F9ECEC',
  DARK: '#580800',
};

export const gray = {
  HIGH: '#8E9491',
  MEDIUM: '#BFC6C4',
  LOW: '#E6ECEA',
  HOVER: '#F2F4F3',
  LIGHT_BORDER: '#dde1e0',
};

export const blue = {
  HIGH: '#095E8E',
  MEDIUM: '#85B9D6',
  LOW: '#D7EDF4',
};

const purple = {
  HIGH: '#400F54',
  MEDIUM: '#D1C4D6',
  LOW: '#ECE7EE',
};

export const beige = {
  HIGH: '#DEBF9E',
  MEDIUM: '#ECDAC6',
  LOW: '#F8F1E8',
};

// Statuses for financial statement
export const NOT_STARTED_POPUP = '#fff';

// Statuses for Skatt
export const NOT_STARTED = gray.MEDIUM;
export const STARTED = yellow.MEDIUM;
export const AW_COMPL = purple.MEDIUM;
export const AW_REVIEW = blue.MEDIUM;
export const DONE = green.MEDIUM;
export const LOCKED = green.MEDIUM;

// Credit Safe icon and buttons color
export const creditSafeColor = '#2e75b8';
export const boardCard = '#f4fdff';
export const contactInfoCard = '#F5FCF4';
export const organisationCard = '#FEF7EF';

// Specification info colors
export const grey = '#ededf2';
export const darkGrey = '#e1e4eb';

// Hidden row comments/saldo history
export const infoText = '#08046a';

// Statuses for checklist
export const checklist_in_progress = { main: warning, darker: '#de9d58' };
export const checklist_yes = { main: success, darker: '#02b06a' };
export const checklist_no = { main: error, darker: '#570257' };
export const checklist_not_started = { main: '#bdbdbd', darker: '#bdbdbd' };
export const checklist_not_applicable = { main: '#E1ECF1', darker: '#E1ECF1' };

// Sidebar (main navigation) colors
export const sidebarDividerBackground = '#4ac000';

// New reconciliation view period header colors
export const periodHeaderYear = '#445277';
export const periodHeaderQuarter = '#6C5675';
export const periodHeaderMonth = '#755656';
export const periodHeaderYearEnd = '#11303B';

// client view colors
export const companyTag = beige.HIGH;
export const personTag = blue.MEDIUM;

export const darkBlue = '#002517';

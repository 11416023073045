import React, { useContext } from 'react';

import { SystemMessagesContext } from '_shared/HOC/withSystemMessages';
import { Message } from './Message';

/**
 * Component that fetches and displays any system messages (API contains logic for what
 * messages to return). This component also stored viewed messages in LS so that they
 * are not presented to the user again if she has dismissed them
 *
 * @returns One or more system messages
 */
const SystemMessages = (): JSX.Element => {
  const { messages, onCloseMessage } = useContext(SystemMessagesContext);

  return (
    <>
      {messages.map(({ id, type, title, body }) => (
        <Message
          key={`system-message-${id}`}
          id={id}
          type={type}
          title={title}
          body={body}
          onClose={onCloseMessage}
        />
      ))}
    </>
  );
};

export default SystemMessages;

import React from 'react';
import { useIntl } from 'react-intl';
import { Cell } from '@agoy/document';
import {
  Row,
  Label,
  NoteField,
  InputField,
} from './ParticularSalaryPanels/Layout';

export interface TaxDocumentNoteRowProps {
  inputCellId: string;
  inputCell: Cell;
  noteCellId: string;
  noteCell: Cell;
  editing: boolean;
  labelId: string;
}

const TaxDocumentNoteRow = ({
  inputCellId,
  inputCell: input,
  noteCellId,
  noteCell: note,
  editing,
  labelId,
}: TaxDocumentNoteRowProps): JSX.Element => {
  const { formatMessage } = useIntl();
  return (
    <Row>
      <Label>{formatMessage({ id: `${labelId}` })}</Label>
      <NoteField
        id={noteCellId}
        cell={note}
        isField
        editing={editing}
        placeholder="Notering..."
      />
      <InputField id={inputCellId} cell={input} isField editing={editing} />
    </Row>
  );
};

export default TaxDocumentNoteRow;

import styled from '@emotion/styled';
import { TableRow } from '@material-ui/core';

const AddTableRow = styled(TableRow)<{ level: number }>`
  .MuiTableCell-body {
    padding: ${({ theme }) => theme.spacing(1 / 2)}px;
  }
  .MuiTableCell-body:first-of-type {
    padding-left: ${({ level, theme }) => theme.spacing(2 * level)}px;
  }
  .add {
    content: ' ';
    border: 1px dashed transparent;
    height: 36px;
    width: 100%;
    border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  }
  &:hover .add {
    border: 1px dashed ${({ theme }) => theme.palette.primary.main};
  }
`;

export default AddTableRow;

import React from 'react';
import { useIntl } from 'react-intl';
import { Box, Table, Tooltip, Typography } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import styled from '@emotion/styled';
import { AgoyTableRow, Cell, numberValue, stringValue } from '@agoy/document';
import { ccyFormat, PercentageNumberFormatType } from '@agoy/common';
import { TableBody } from '_shared/components/CommonTable';
import Row from '../../Row';
import Field from '../Field';
import TaxDocumentTableAccountRow from '../../TaxDocumentTableAccountRow';

const Header = styled(Box)`
  background: ${({ theme }) => theme.palette.grey[200]};
  margin: 10px -20px 0;
  padding: 3px 30px;

  h6 {
    font-weight: 700;
    color: ${({ theme }) => theme.palette.text.primary};
    font-size: 0.9rem;
  }
`;

const CenteredRow = styled(Row)`
  align-items: center;
  margin-top: 10px;
  justify-content: align-self;
  gap: 16px;

  p,
  h6 {
    align-items: center;
    display: flex;
    white-space: normal;
  }

  h6 {
    font-weight: 700;
    color: #00313c;
  }
`;

const InlineSum = styled(Box)`
  margin: 10px -20px 0;
  padding: 3px 30px;

  h6 {
    font-weight: 700;
    color: #00313c;
    font-size: 0.9rem;
  }
`;

const Container = styled.div`
  &:not(.no-padding) {
    padding: 0px 10px;
  }

  &.no-padding {
    padding: 4px 0;
  }
`;

const InputField = styled(Field)`
  color: #00313c;
  font-weight: 500;
  padding-right: 6px;
  background: ${({ theme }) => theme.palette.grey[200]};
  text-align: right;
  width: 100px;
  min-width: 100px;
  border: 1px solid ${({ theme }) => theme.palette.grey[400]};
  padding-top: 5.5px;
  padding-bottom: 4.5px;
  border-radius: 3px;
  margin-right: 3px;
  word-break: break-all;
`;

const PercentageField = styled(InputField)`
  margin-right: ${(props) => props.theme.spacing(1.5)}px;
`;

const PercentContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .percent-label {
    margin-right: -6px;
    padding-left: 3px;
  }
`;

const ChildrenFooterContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const ChildrenBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SubtitleContainer = styled.div`
  display: flex;
  gap: 3px;
  align-items: center;

  h6 {
    color: #00313c;
  }

  span {
    color: #000000a6;
  }
`;
const RowBox = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
  flex: 1;
  gap: 60px;

  h6 {
    font-weight: 700;
    color: #00313c;
  }
`;

const RowTitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  overflow: hidden;

  p {
    white-space: normal;
  }
`;

const InfoIcon = styled(InfoOutlinedIcon)`
  height: 15px;
  margin-right: 10px;
  color: #00313c;
`;

const NoteField = styled(Field)`
  color: #00313c;
  font-weight: 500;
  padding-right: 6px;
  background: ${({ theme }) => theme.palette.grey[200]};
  border: 1px solid ${({ theme }) => theme.palette.grey[400]};
  padding-top: 5.5px;
  padding-bottom: 4.5px;
  border-radius: 3px;
  padding-left: 10px;
  max-height: 30px;
  width: 170px;
  ::placeholder {
    font-weight: 100;
  }
`;

const ValueContainer = styled.div`
  display: flex;
  gap: ${(props) => props.theme.spacing(1)}px;
  p {
    white-space: nowrap;
  }
`;

const TitlesContainer = styled.div`
  margin-right: auto;
  display: flex;
  gap: ${(props) => props.theme.spacing(1)}px;
`;

const Bold = styled(Typography)`
  font-weight: 700;
`;

const Line = styled.div`
  background: ${({ theme }) => theme.palette.grey[200]};
  width: 100%;
  height: 2px;
`;

const TaxTable = styled(Table)`
  box-shadow: none;
  border: none;
  padding: 10px;

  tbody {
    display: block;
    padding: 0 10px;

    tr.active {
      display: flex;
      gap: 8px;
      width: 100%;
      align-items: center;
      flex: 1;

      td {
        padding-right: 0;
      }
    }

    .label-cell {
      flex: 1;
    }
  }

  .delete-cell {
    border: none;
  }

  td {
    border: none;
  }
`;

export type SingleCalculationProps = {
  rowId?: string;
  sum?: Cell;
  title?: Cell;
  label?: Cell;
  label2?: Cell;
  titleBold?: boolean;
  value?: Cell;
  value2?: Cell;
  percentage?: Cell;
  isEditMode?: boolean;
  isPercentageEditable?: boolean;
  isNoteEditable?: boolean;
  isInline?: boolean;
  children?: JSX.Element[] | JSX.Element;
  note?: Cell;
  rows?: AgoyTableRow[];
};

type RowTitleProps = {
  rowTitle?: string;
  rowDescription?: string;
  rowAmount?: number;
  tooltip?: string;
  isBold?: boolean;
};

const accountColumns = [
  { id: 'accountNumber' },
  { id: 'label' },
  { id: 'value' },
];

export const SingleCalculationComponent = ({
  rowId = '',
  sum,
  title,
  label2,
  value,
  label,
  value2,
  percentage,
  isEditMode,
  isPercentageEditable,
  isInline,
  children,
  note,
  rows,
}: SingleCalculationProps) => {
  const isChildrenArray = !!children && 'length' in children;
  const Body = isChildrenArray ? children?.[0] : children;
  const Footer = isChildrenArray ? children?.[1] : children;
  const { formatMessage } = useIntl();
  const titleOnOwnLine = label && (stringValue(title)?.length ?? 0) > 10;

  const isNumber = (item: number | undefined): item is number => {
    return !!item;
  };

  const selectedAccounts: string[] = (rows ?? [])
    .map((row) => row?.cells?.accountNumber)
    .map((accountNumberCell) => numberValue(accountNumberCell))
    .filter(isNumber)
    .map((accountNumber) => accountNumber.toString());

  return (
    <div>
      <Container>
        {Body?.type.name === 'Body' && Body}

        {(title || label) && value && (
          <>
            {title && titleOnOwnLine && (
              <CenteredRow>
                <Typography variant="h6">
                  {stringValue(title)}
                  {title.tooltip && (
                    <Tooltip title={title.tooltip}>
                      <InfoIcon />
                    </Tooltip>
                  )}
                </Typography>
              </CenteredRow>
            )}
            <CenteredRow>
              <TitlesContainer>
                {title && !titleOnOwnLine && (
                  <Typography variant="h6">
                    {stringValue(title)}
                    {title.tooltip && (
                      <Tooltip title={title.tooltip}>
                        <InfoIcon />
                      </Tooltip>
                    )}
                  </Typography>
                )}
                {label && (
                  <SubtitleContainer>
                    <Typography variant="body1">
                      {stringValue(label)}
                    </Typography>
                    {label.tooltip && (
                      <Tooltip title={label.tooltip}>
                        <InfoIcon />
                      </Tooltip>
                    )}
                  </SubtitleContainer>
                )}
              </TitlesContainer>

              {!rows && (
                <>
                  {isEditMode ? (
                    <>
                      {note && (
                        <NoteField
                          cell={note}
                          id={`${rowId}.note`}
                          editing
                          placeholder="Notering..."
                        />
                      )}

                      {value && (
                        <InputField
                          cell={value}
                          id={`${rowId}.value`}
                          editing
                        />
                      )}
                    </>
                  ) : (
                    <ValueContainer>
                      {note && <Typography>{stringValue(note)}</Typography>}
                      <Typography
                        style={{ color: '#000000a6' }}
                        variant="body1"
                      >
                        {ccyFormat(numberValue(value))}
                      </Typography>
                    </ValueContainer>
                  )}
                </>
              )}
            </CenteredRow>
          </>
        )}

        {label2 !== undefined && value2 !== undefined && (
          <Row>
            <SubtitleContainer>
              <Typography variant="body1">{stringValue(label2)}</Typography>
            </SubtitleContainer>

            {isEditMode ? (
              <InputField cell={value2} id={`${rowId}.value2`} editing />
            ) : (
              <Typography style={{ color: '#000000a6' }} variant="body1">
                {ccyFormat(numberValue(value2))}
              </Typography>
            )}
          </Row>
        )}
      </Container>

      {percentage !== undefined && (
        <PercentContainer>
          {isEditMode && isPercentageEditable ? (
            <>
              <Typography style={{ marginRight: '5px' }} variant="body1">
                х
              </Typography>
              <PercentageField
                cell={percentage}
                id={`${rowId}.percentage`}
                editing
                numberFormatType={PercentageNumberFormatType}
              />
            </>
          ) : (
            <Container>
              <Typography style={{ color: '#000000a6' }} variant="body1">
                {percentage.type === 'number' && percentage.unit
                  ? `х${numberValue(percentage)}${percentage.unit}`
                  : `х${ccyFormat((numberValue(percentage) ?? 0) * 100, 2)}%`}
              </Typography>
            </Container>
          )}
        </PercentContainer>
      )}

      {rows && (
        <TaxTable>
          <TableBody
            baseId="taxViewDocument"
            rows={rows}
            columns={accountColumns}
            canAddRows
            canDeleteRows
            editing={isEditMode ?? false}
            tableId={rowId}
            renderRow={(subRow) => (
              <TaxDocumentTableAccountRow
                columns={accountColumns}
                editing={isEditMode ?? false}
                selectedAccounts={selectedAccounts}
                subRow={subRow}
              />
            )}
          />
        </TaxTable>
      )}
      {Footer?.type.name === 'Footer' && Footer}

      {sum !== undefined ? (
        <>
          {isInline ? (
            <InlineSum>
              <Row>
                <Bold variant="body1">
                  {`${formatMessage({
                    id: 'sum',
                  })}  ${stringValue(title)}`}
                </Bold>
                <Bold variant="body1">{ccyFormat(numberValue(sum))}</Bold>
              </Row>
            </InlineSum>
          ) : (
            <Header>
              <Row>
                <Bold variant="body1">
                  {formatMessage({
                    id: 'sum',
                  })}
                </Bold>
                <Bold variant="body1">{ccyFormat(numberValue(sum))}</Bold>
              </Row>
            </Header>
          )}
        </>
      ) : (
        <Line />
      )}
    </div>
  );
};

const Footer = ({ children }) => {
  return <ChildrenFooterContainer>{children}</ChildrenFooterContainer>;
};

const Body = ({ children }) => {
  return <ChildrenBodyContainer>{children}</ChildrenBodyContainer>;
};

SingleCalculationComponent.Footer = Footer;
SingleCalculationComponent.Body = Body;

export const RowTitle = ({
  rowTitle,
  rowDescription,
  rowAmount,
  tooltip,
  isBold,
}: RowTitleProps) => {
  return (
    <RowBox>
      <RowTitleContainer>
        {rowTitle && <Typography variant="h6">{rowTitle}</Typography>}
        {rowDescription &&
          (isBold ? (
            <Bold variant="body1">{rowDescription}</Bold>
          ) : (
            <Typography variant="body1">{rowDescription}</Typography>
          ))}

        {tooltip && (
          <Tooltip title={tooltip}>
            <InfoIcon />
          </Tooltip>
        )}
      </RowTitleContainer>
      {rowAmount && <Typography variant="body1">{rowAmount}</Typography>}
    </RowBox>
  );
};

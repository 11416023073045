import React from 'react';
import { Variant } from '@material-ui/core/styles/createTypography';
import { Field } from '@agoy/document';
import ToggleSection from './ToggleSection';

type ToggleTitleProps = {
  print?: boolean;
  id: string;
  section: string; // note1 ex
  target: Field;
  titleVariant?: Variant;
  title?: string;
  children: React.ReactNode[];
};

const ToggleTitle = ({
  id,
  section,
  target,
  titleVariant = 'h1',
  children,
  title,
}: ToggleTitleProps): JSX.Element | null => {
  let childrenActive = false;

  React.Children.forEach(children, (element) => {
    if (!React.isValidElement(element)) {
      return;
    }
    if (element.props?.noteId?.includes('custom')) {
      childrenActive = true;
    }
    if (element.props.active === true) {
      childrenActive = true;
    }
  });
  if (!childrenActive) return null;

  return (
    <>
      <ToggleSection
        id={id}
        section={section}
        target={target}
        titleVariant={titleVariant}
        title={title}
      />
      {children}
    </>
  );
};

export default ToggleTitle;

import React, { memo } from 'react';
import { Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import {
  NumberFormatType,
  StandardNumberFormatType,
  ccyFormat,
  getNumericValueFormat,
  getDisplayValue,
} from '@agoy/common';
import { FormattingContext } from '@agoy/formatting';
import { ResolvedReference } from '@agoy/document';
import { formatPercentage } from './formatting';
import StringField from './Field/StringField';
import NumberCell from './NumberCell';
import { IXElement } from '../../../ARKeyToIX';
import { IxbrlCell } from '@agoy/annual-report-document';

interface ReferenceCellProps {
  className?: string;
  numberFormatType?: NumberFormatType;
  value: ResolvedReference;
  active: boolean;
  id?: string;
  cell: IxbrlCell;
}

const ReferenceCell = memo(
  ({
    value,
    className,
    numberFormatType = StandardNumberFormatType,
    active,
    id,
    cell,
  }: ReferenceCellProps) => {
    const { formatMessage } = useIntl();
    const formattingContext = React.useContext(FormattingContext);

    const { formatNumber } = formattingContext;

    // We rely on ixbrl tagging data here to determine the percentage and number of people format
    const unitRef = cell?.ixbrl?.unitRef;

    const ixbrlType = cell?.ixbrl?.type;

    let { type: formatType } = getNumericValueFormat(unitRef);

    // We only need the default precision in preview, without showing decimals
    const { type, precision } = numberFormatType;

    if (type !== 'standard') {
      formatType = type;
    }

    const isPercentage = type === 'percentage' || formatType === 'percentage';

    const numberFormat = isPercentage ? formatPercentage : ccyFormat;

    if (value === undefined || typeof value === 'boolean') {
      return null;
    }
    if (typeof value === 'string') {
      return <StringField className={className} value={value} />;
    }
    if (typeof value === 'object') {
      if (value.error === 'missingPeriod') return null;
      return (
        <Typography
          color={value.error === 'missingAccount' ? 'textPrimary' : 'error'}
          className={className}
          style={{
            textAlign: 'right',
            fontStyle: value.error === 'missingAccount' ? 'italic' : '',
          }}
        >
          {formatMessage({
            id: `ref.error.${value.error}`,
          })}
        </Typography>
      );
    }

    const displayValue = getDisplayValue(ixbrlType, value, type, formatNumber);

    const cellValueAsString = numberFormat(displayValue, precision);
    // turn cell value into a number again
    const cellValue: number = isPercentage
      ? value
      : parseFloat(cellValueAsString.replace(/\s+/g, '').replace(',', '.'));

    return (
      <NumberCell className={className}>
        {active && (
          <>
            {/* The IXElement handles the sign of the cell value */}
            {cellValue < 0 && '-'}
            <span>
              <IXElement arPart={cell}>
                {numberFormat(
                  displayValue ? Math.abs(displayValue) : displayValue,
                  precision
                )}
              </IXElement>
            </span>
          </>
        )}
      </NumberCell>
    );
  }
);

export default ReferenceCell;

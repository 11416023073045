import { mapRecord } from '@agoy/common';
import { AgoyDocument, AgoyDocumentStructure } from './document';
import { AgoyTable, AgoyTableRow } from './table';
import { Cell, FormatMessageCell, isCellWithWarning, isField } from '../Cell';
import { isResolveReferenceError, ResolvedReference } from '../References';
import { traverseDocument } from './traverse';
import IntlMessageFormat from 'intl-messageformat';

type Refs = Record<string, ResolvedReference>;

/**
 * Converts a resolved reference to a boolean
 * @param value a resolved reference
 * @returns
 */
const convertValue = (value: ResolvedReference): boolean | undefined => {
  if (isResolveReferenceError(value) || value === undefined) {
    return undefined;
  }

  return !!value;
};

const formatMessage = (
  message: string,
  parameters: Record<string, ResolvedReference>
): string => {
  const values = new IntlMessageFormat(message).format(
    mapRecord(parameters || {}, (v) =>
      v === undefined || typeof v === 'object' ? '' : v?.toString()
    )
  );
  if (Array.isArray(values)) {
    return values.join();
  }
  return values;
};

export const updateCell = (oldCell: Cell, references: Refs): Cell => {
  let field = oldCell;
  if (isField(field)) {
    const cellActive = field.active;

    if (typeof cellActive === 'object') {
      const value = convertValue(references[cellActive.reference]);
      if (cellActive.value !== value) {
        const newField = { ...field, active: { ...cellActive, value: value } };
        field = newField;
      }
    }
  }

  const warning =
    isCellWithWarning(field) && field.warning.reference in references
      ? { ...field.warning, value: references[field.warning.reference] }
      : undefined;

  const cell = warning
    ? {
        ...field,
        warning,
      }
    : field;

  if (cell.type === 'ref') {
    const value =
      cell.reference in references
        ? references[cell.reference]
        : { error: 'notResolved' as const };
    if (cell.value !== value) {
      return { ...cell, value };
    }
  } else if (cell.type === 'refs') {
    let updated = false;
    const newValues = cell.references.map((ref, index) => {
      const value =
        ref in references ? references[ref] : { error: 'notResolved' as const };
      if (cell.values[index] !== value) {
        updated = true;
      }
      return value;
    });
    if (newValues.length !== cell.values.length || updated) {
      return { ...cell, values: newValues };
    }
  } else if (cell.type === 'msg') {
    let updated = false;
    const newParameterValues = Object.entries(
      cell.parameterReferences || {}
    ).reduce((result, [param, ref]) => {
      const newRef =
        ref in references ? references[ref] : { error: 'notResolved' as const };
      if (param in result) {
        if (result[param] !== newRef) {
          updated = true;
          return {
            ...result,
            [param]: newRef,
          };
        }
        return result;
      }
      updated = true;
      return {
        ...result,
        [param]: newRef,
      };
    }, cell.parameterValues || {});
    const newValue = formatMessage(cell.message, newParameterValues);
    if (newValue !== cell.value) {
      updated = true;
    }
    if (updated) {
      return {
        ...cell,
        value: newValue,
        parameterValues: newParameterValues,
      };
    }
  }

  return cell;
};

const updateRows = (rows: AgoyTableRow[], references: Refs): AgoyTableRow[] => {
  let updated = false;
  const newRows = rows.map((row) => {
    const newRow = updateRow(row, references);
    if (newRow !== row) {
      updated = true;
    }
    return newRow;
  });

  return updated ? newRows : rows;
};

const updateRow = (row: AgoyTableRow, references: Refs): AgoyTableRow => {
  let newRow = row;

  const rowActive = row.active;

  if (typeof rowActive === 'object') {
    const value = convertValue(references[rowActive.reference]);

    if (rowActive.value !== value) {
      newRow = { ...newRow, active: { ...rowActive, value } };
    }
  }

  if (newRow.rows) {
    const newRows = updateRows(newRow.rows, references);
    if (newRows !== newRow.rows) {
      newRow = { ...newRow, rows: newRows };
    }
  }
  if (newRow.cells) {
    const newCells = Object.keys(newRow.cells).reduce((cells, column) => {
      const cell = cells[column];
      const newCell = updateCell(cell, references);
      if (newCell !== cell) {
        return { ...cells, [column]: newCell };
      }
      return cells;
    }, newRow.cells);

    if (newCells !== newRow.cells) {
      newRow = { ...newRow, cells: newCells };
    }
  }

  return newRow;
};

const updateTable = (oldTable: AgoyTable, references: Refs): AgoyTable => {
  let table = oldTable;
  if (!table.rows) {
    console.error('Missing rows', table);
  }

  const tableActive = table.active;

  // update the active property (if reference) bool value
  if (typeof tableActive === 'object') {
    const value = convertValue(references[tableActive.reference]);
    if (tableActive.value !== value) {
      const newTable = { ...table, active: { ...tableActive, value: value } };
      table = newTable;
    }
  }

  const newRows = updateRows(table.rows, references);
  if (newRows !== table.rows) {
    return { ...table, rows: newRows };
  }
  return table;
};

const updateValues =
  <T extends AgoyDocumentStructure>(structure: T) =>
  <D extends AgoyDocument<T>>(
    report: D,
    references: Record<string, ResolvedReference>
  ): D => {
    const result = traverseDocument(null, structure, report, undefined, {
      field: (key, id, props) => {
        const updated = updateCell(props.node, references);
        if (props.node !== updated && props.node.type === updated.type) {
          return { ...props, node: updated };
        }
        return props;
      },

      table: (key, id, props) => {
        const updated = updateTable(props.node, references);
        if (props.node !== updated) {
          return { ...props, node: updated };
        }
        return props;
      },
    });
    if (result.document === report) {
      return report;
    }
    return {
      ...report,
      ...result.document,
    };
  };
export default updateValues;

import {
  TaxDeclarationFormDefinition,
  TaxDeclarationFormDataConfig,
  PersonMetaData,
} from '../../types';
import { ref, value, id } from '@agoy/document';
import { mapFormId, toInitial, toStructure } from '../forms-util';

const configK15A = (
  form: TaxDeclarationFormDefinition,
  person: PersonMetaData
): TaxDeclarationFormDataConfig => {
  const name = `${person?.firstName || ''} ${person?.lastName || ''}`;
  const K15A = mapFormId(form, 'K15A');
  const initial = {
    K15A: {
      partType: 'form' as const,
      id: K15A,
      fields: {
        datum: value(''),
        numrering_vid_flera_k15a: value(undefined),
        namn: value(name),
        personnummer: value(person.personNumber || ''),
        A_namn: value(''),
        A_organisationsnummer: value(''),
        A_forsaljningsdatum: value(''),
        A_ersattning: value(undefined),
        A_justerad_anskaffningsutgift: value(undefined),
        A_vinst: value(undefined),
        A_forlust: value(undefined),
        B_namn: value(''),
        B_organisationsnummer: value(''),
        B_forsaljningsdatum: value(''),
        B_ersattning: value(undefined),
        B_justerad_anskaffningsutgift: value(undefined),
        B_vinst: value(undefined),
        B_forlust: value(undefined),
        C_namn: value(''),
        C_organisationsnummer: value(''),
        C_forsaljningsdatum: value(''),
        C_ersattning: value(undefined),
        C_justerad_anskaffningsutgift: value(undefined),
        C_vinst: value(undefined),
        C_forlust: value(undefined),
        D_namn: value(''),
        D_organisationsnummer: value(''),
        D_forsaljningsdatum: value(''),
        D_ersattning: value(undefined),
        D_justerad_anskaffningsutgift: value(undefined),
        D_vinst: value(undefined),
        D_forlust: value(undefined),
        E_namn: value(''),
        E_organisationsnummer: value(''),
        E_forsaljningsdatum: value(''),
        E_ersattning: value(undefined),
        E_justerad_anskaffningsutgift: value(undefined),
        E_vinst: value(undefined),
        E_forlust: value(undefined),
        F_namn: value(''),
        F_organisationsnummer: value(''),
        F_forsaljningsdatum: value(''),
        F_ersattning: value(undefined),
        F_justerad_anskaffningsutgift: value(undefined),
        F_vinst: value(undefined),
        F_forlust: value(undefined),
        summa_vinst: value(undefined),
        summa_forlust: value(undefined),
      },
      sru: {
        '7014': ref(id('K15A.fields.numrering_vid_flera_k15a')),
        '5702': ref(id('K15A.fields.A_organisationsnummer')),
        '5703': ref(id('K15A.fields.A_forsaljningsdatum')),
        '5704': ref(id('K15A.fields.A_ersattning')),
        '5705': ref(id('K15A.fields.A_justerad_anskaffningsutgift')),
        '5706': ref(id('K15A.fields.A_vinst')),
        '5707': ref(id('K15A.fields.A_forlust')),
        '5712': ref(id('K15A.fields.B_organisationsnummer')),
        '5713': ref(id('K15A.fields.B_forsaljningsdatum')),
        '5714': ref(id('K15A.fields.B_ersattning')),
        '5715': ref(id('K15A.fields.B_justerad_anskaffningsutgift')),
        '5716': ref(id('K15A.fields.B_vinst')),
        '5717': ref(id('K15A.fields.B_forlust')),
        '5722': ref(id('K15A.fields.C_organisationsnummer')),
        '5723': ref(id('K15A.fields.C_forsaljningsdatum')),
        '5724': ref(id('K15A.fields.C_ersattning')),
        '5725': ref(id('K15A.fields.C_justerad_anskaffningsutgift')),
        '5726': ref(id('K15A.fields.C_vinst')),
        '5727': ref(id('K15A.fields.C_forlust')),
        '5732': ref(id('K15A.fields.D_organisationsnummer')),
        '5733': ref(id('K15A.fields.D_forsaljningsdatum')),
        '5734': ref(id('K15A.fields.D_ersattning')),
        '5735': ref(id('K15A.fields.D_justerad_anskaffningsutgift')),
        '5736': ref(id('K15A.fields.D_vinst')),
        '5737': ref(id('K15A.fields.D_forlust')),
        '5742': ref(id('K15A.fields.E_organisationsnummer')),
        '5743': ref(id('K15A.fields.E_forsaljningsdatum')),
        '5744': ref(id('K15A.fields.E_ersattning')),
        '5745': ref(id('K15A.fields.E_justerad_anskaffningsutgift')),
        '5746': ref(id('K15A.fields.E_vinst')),
        '5747': ref(id('K15A.fields.E_forlust')),
        '5752': ref(id('K15A.fields.F_organisationsnummer')),
        '5753': ref(id('K15A.fields.F_forsaljningsdatum')),
        '5754': ref(id('K15A.fields.F_ersattning')),
        '5755': ref(id('K15A.fields.F_justerad_anskaffningsutgift')),
        '5756': ref(id('K15A.fields.F_vinst')),
        '5757': ref(id('K15A.fields.F_forlust')),
        '5760': ref(id('K15A.fields.summa_vinst')),
        '5770': ref(id('K15A.fields.summa_forlust')),
      },
    },
  };
  return {
    initial: toInitial(initial, form),
    definition: toStructure(initial, form),
  };
};

export default configK15A;

import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';
import { compact } from 'lodash';
import { stringValue } from '@agoy/document';

import Typography from '@material-ui/core/Typography';
import { CompanyType } from '_clients/types/types';
import { Period } from '@agoy/api-sdk-core';
import { FinancialReportClientPeriodState } from '_financial-report/redux/reducer/types';
// @ts-ignore
import { ReactComponent as BarsImage } from 'assets/bars.svg';
import { getFullSections } from '../../MinimapMenuDrawer';

import OrganisationLogo from '../../UI/OrganisationLogo';

import { mapPartToModel, ReportColors } from '../../../../utils';

type ReportColorsProps = {
  customColor: string | undefined;
};

const A4Page = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  height: 960px;
  width: 1400px;
  padding: 40px 0 80px;
`;

const Subheader = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'customColor',
})<ReportColorsProps>`
  font-size: 45px;
  margin: ${({ theme }) => theme.spacing(2)}px 0;
  color: ${(props) => props.customColor || props.theme.palette.text.primary};
`;

const DataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

const PictureWrapper = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  width: 50%;
`;

const EntryRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledBarsImage = styled(BarsImage, {
  shouldForwardProp: (prop) => prop !== 'customColor',
})<ReportColorsProps>`
  width: 100%;
  margin: 0 0 50px 40px;
  fill: ${(props) =>
    props.customColor ||
    props.theme.palette.financialReportView.accentBackground};
`;

const RowText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'customColor',
})<ReportColorsProps>`
  font-size: 27px;
  color: ${(props) => props.customColor || props.theme.palette.text.primary};
`;

type FrontPageProps = {
  financialState: FinancialReportClientPeriodState;
  reportColors: ReportColors;
  splitIncomeStatementPage: boolean;
  splitIncomeStatementPeriodPage: boolean;
  splitBalanceSheetPage: boolean;
  companyType: CompanyType;
  period: Period;
};

const TableOfContents = ({
  financialState,
  reportColors,
  splitIncomeStatementPage,
  splitIncomeStatementPeriodPage,
  splitBalanceSheetPage,
  companyType,
  period,
}: FrontPageProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const [contents, updateContents] = useState<string[]>([]);
  const { report, documents } = financialState;

  const pageTitle = formatMessage({ id: 'financialreport.table-of-contents' });

  const firstIncomeStatementTitle = splitIncomeStatementPage
    ? `financialreport.income-statement-part1`
    : `financialreport.income-statement`;

  const firstIncomeStatementPeriodTitle = splitIncomeStatementPeriodPage
    ? `financialreport.income-statement-period-part1`
    : `financialreport.income-statement-period`;

  // Check for active pages and update the state
  useEffect(() => {
    const menuList = getFullSections(
      splitIncomeStatementPage,
      splitIncomeStatementPeriodPage,
      splitBalanceSheetPage,
      companyType,
      period.type
    );

    const newContents = menuList.map((part) => {
      // We don't need to show first two pages in the table
      if (part === 'table-of-contents' || part === 'frontpage') {
        return null;
      }

      const incomeStatement = report?.incomeStatementTables?.section;

      if (part === 'income-statement-period-part1') {
        if (incomeStatement?.periodTable.active) {
          return formatMessage({ id: firstIncomeStatementPeriodTitle });
        }
        return null;
      }

      if (part === 'income-statement-period-part2') {
        if (
          splitIncomeStatementPeriodPage &&
          incomeStatement?.secondPeriodTable.active
        ) {
          return formatMessage({
            id: 'financialreport.income-statement-period-part2',
          });
        }
        return null;
      }

      // If income statement is too long it is split into two parts with different structure
      if (part === 'income-statement-part1') {
        if (incomeStatement?.table.active) {
          return formatMessage({ id: firstIncomeStatementTitle });
        }
        return null;
      }

      if (part === 'income-statement-part2') {
        if (splitIncomeStatementPage && incomeStatement?.secondTable.active) {
          return formatMessage({
            id: 'financialreport.income-statement-part2',
          });
        }
        return null;
      }

      if (part === 'balance-sheet') {
        if (report?.balanceSheet.section.equityAndLiabilities.active) {
          return formatMessage({
            id: 'financialreport.balance-sheet',
          });
        }
        return null;
      }

      if (part === 'additionalTextOne') {
        if (report?.additionalText.section.text1Active) {
          return (
            stringValue(report.additionalText.section.text1Title) ||
            formatMessage({
              id: 'financialreport.additionalTextOne',
            })
          );
        }
        return null;
      }
      if (part === 'additionalTextTwo') {
        if (report?.additionalText.section.text2Active) {
          return (
            stringValue(report.additionalText.section.text2Title) ||
            formatMessage({
              id: 'financialreport.additionalTextTwo',
            })
          );
        }
        return null;
      }

      if (report?.[`${mapPartToModel(part)}`]?.section.active) {
        if (part === 'documents') {
          if (report.documents.section.active && documents.length) {
            return stringValue(report.documents.section.title);
          }
        } else {
          return formatMessage({
            id: `financialreport.${part}`,
          });
        }
      }
      return null;
    });

    updateContents(compact(newContents));
  }, [
    documents,
    firstIncomeStatementPeriodTitle,
    firstIncomeStatementTitle,
    formatMessage,
    report,
    splitBalanceSheetPage,
    splitIncomeStatementPage,
    splitIncomeStatementPeriodPage,
  ]);

  return (
    <A4Page>
      <DataWrapper>
        <Subheader variant="h2" customColor={reportColors.textMain}>
          {pageTitle}
        </Subheader>
        {contents.map((entry, i) => {
          return (
            <EntryRow key={`${entry}-${i}`}>
              <RowText variant="h4" customColor={reportColors.textMain}>
                {entry}
              </RowText>
              <RowText
                variant="h4"
                customColor={reportColors.textMain}
              >{`${formatMessage({ id: 'PAGE' })} ${i + 3}`}</RowText>
            </EntryRow>
          );
        })}
      </DataWrapper>
      <PictureWrapper>
        <StyledBarsImage customColor={reportColors.background} />
      </PictureWrapper>
      <OrganisationLogo print />
    </A4Page>
  );
};

export default TableOfContents;

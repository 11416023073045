import {
  AgoyTable,
  AgoyTableColumn,
  AgoyTableRow,
  NumberCell,
  Cell,
} from '@agoy/document';
import { ccyFormat } from '@agoy/common';
import { differenceInMonths, isValid } from 'date-fns';
import { parse } from '@agoy/dates';

export const prepareGeneralInfo = (
  text: string,
  rows: AgoyTableRow[],
  showIn1000: boolean
): string => {
  let result = text;
  const nettoRow = rows.find((item) => {
    return item.id === 'nettos';
  });
  const personalRow = rows.find((item) => {
    return item.id === 'personalkostnader';
  });
  const incomeRow = rows.find((item) => {
    return item.id === 'operationsResult';
  });

  if (result.lastIndexOf('{maxSales}') > 0) {
    const cells = nettoRow?.cells;
    let value = '0';
    if (cells) {
      const values = Object.values(cells).filter((item) => {
        return item.type === 'ref' && typeof item.value === 'number';
      });
      const data = values.map((item) => {
        const value = (item as NumberCell).value || 0;
        return value;
      });
      let maxSales = data.reduce((prev, next) => {
        const maxSum = Math.max(prev, next);
        return maxSum;
      }, data[0]);
      if (showIn1000) {
        maxSales /= 1000;
      }
      value = ccyFormat(maxSales, 0);
    }
    result = result.replace(/{maxSales}/g, value);
  }
  if (result.lastIndexOf('{minSales}') > 0) {
    const cells = nettoRow?.cells;
    let value = '0';
    if (cells) {
      const values = Object.values(cells).filter((item) => {
        return item.type === 'ref' && typeof item.value === 'number';
      });
      const data = values.map((item) => {
        return (item as NumberCell).value || 0;
      });
      let minSales = data.reduce((prev, next) => {
        const minSum = Math.min(prev, next);
        return minSum;
      }, data[0]);
      if (showIn1000) {
        minSales /= 1000;
      }
      value = ccyFormat(minSales, 0);
    }
    result = result.replace(/{minSales}/g, value);
  }
  if (result.lastIndexOf('{maxIncome}') > 0) {
    const cells = incomeRow?.cells;
    let value = '0';
    if (cells) {
      const values = Object.values(cells).filter((item) => {
        return item.type === 'ref' && typeof item.value === 'number';
      });
      const data = values.map((item) => {
        return (item as NumberCell).value || 0;
      });
      let maxIncome = data.reduce((prev, next) => {
        const maxSum = Math.max(prev, next);
        return maxSum;
      }, data[0]);
      if (showIn1000) {
        maxIncome /= 1000;
      }
      value = ccyFormat(maxIncome, 0);
    }
    result = result.replace(/{maxIncome}/g, value);
  }
  if (result.lastIndexOf('{minIncome}') > 0) {
    const cells = incomeRow?.cells;
    let value = '0';
    if (cells) {
      const values = Object.values(cells).filter((item) => {
        return item.type === 'ref' && typeof item.value === 'number';
      });
      const data = values.map((item) => {
        return (item as NumberCell).value || 0;
      });
      let minIncome = data.reduce((prev, next) => {
        const minSum = Math.min(prev, next);
        return minSum;
      }, data[0]);
      if (showIn1000) {
        minIncome /= 1000;
      }
      value = ccyFormat(minIncome, 0);
    }
    result = result.replace(/{minIncome}/g, value);
  }
  if (result.lastIndexOf('{minPersonalExpenses}') > 0) {
    const cells = personalRow?.cells;
    let value = '0';
    if (cells) {
      const values = Object.values(cells).filter((item) => {
        return item.type === 'ref' && typeof item.value === 'number';
      });
      const data = values.map((item) => {
        return (item as NumberCell).value || 0;
      });
      let minIncome = data.reduce((prev, next) => {
        const minSum = Math.min(prev, next);
        return minSum;
      }, data[0]);
      if (showIn1000) {
        minIncome /= 1000;
      }
      value = ccyFormat(minIncome, 0);
    }
    result = result.replace(/{minPersonalExpenses}/g, value);
  }
  return result;
};

export const filterBlankRow = (
  table: AgoyTable,
  showBlank: boolean
): {
  columns: AgoyTableColumn[];
  rows: AgoyTableRow[];
} => {
  if (showBlank) {
    return { columns: table.columns, rows: table.rows };
  }
  const filteredRows = table.rows.filter((row) => {
    let showRow = false;
    if (row.cells) {
      Object.values(row.cells).forEach((item) => {
        if (
          item.type === 'ref' &&
          item.value &&
          typeof item.value === 'number' &&
          item.value !== 0
        ) {
          showRow = true;
        }
      });
    }
    return showRow;
  });
  const result = { columns: table.columns, rows: filteredRows };
  return result;
};

export const filterBlankColumns = (
  table: AgoyTable,
  showBlank: boolean
): AgoyTableColumn[] => {
  if (showBlank) {
    return table.columns;
  }
  const columnsValues: { id: string; values: Cell[] }[] = [];

  table.rows.forEach((row) => {
    if (row.cells) {
      Object.entries(row.cells).forEach((item) => {
        const [key, value] = item;
        const columnIndex = columnsValues.findIndex(
          (column) => column.id === key
        );
        if (columnIndex !== -1) {
          columnsValues[columnIndex].values.push(value);
        } else {
          columnsValues.push({ id: key, values: [value] });
        }
      });
    }
  });

  const filteredColumns = table.columns.filter((column) => {
    let showColumn = false;

    const values =
      columnsValues.find((item) => item.id === column.id)?.values || [];

    values.forEach((item) => {
      if (
        item.type === 'ref' &&
        item.value &&
        typeof item.value === 'number' &&
        item.value !== 0
      ) {
        showColumn = true;
      }

      if (item.type === 'string') {
        showColumn = true;
      }
    });

    return showColumn;
  });

  return filteredColumns;
};

/**
 * Comapre rows by sort by date. Use in sort function
 * @param a first date
 * @param b second date
 * @returns 0 same, -1 less, 1 more
 */
export const sortColumns = (a: AgoyTableColumn, b: AgoyTableColumn): number => {
  const firstDate = parse(a.id, 'MMMyy');
  const secondDate = parse(b.id, 'MMMyy');
  if (isValid(firstDate) && isValid(secondDate)) {
    return differenceInMonths(firstDate, secondDate);
  }
  // if one parameters not a date
  return 0;
};

/**
 * Add toatal row column
 * @param columns list of cilumns
 * @param rows  Rows data
 * @returns
 */
export const addTotalColumn = (
  columns: AgoyTableColumn[],
  rows: AgoyTableRow<Cell>[],
  rowSum: boolean
): {
  columns: AgoyTableColumn[];
  rows: AgoyTableRow<Cell>[];
} => {
  const newColumns = [...columns];
  const newRows = [...rows];
  if (rowSum) {
    newColumns.push({
      id: 'total',
      label: 'Summa',
    });
    let rowTotal = 0;
    newRows.forEach((row) => {
      if (row.cells) {
        const cellsData = row.cells;
        const ids = Object.keys(cellsData);
        const cells = ids.map((id) => {
          const cell = cellsData[id];
          if (
            cell &&
            cell.type &&
            cell.type === 'ref' &&
            typeof cell.value === 'number'
          ) {
            return cell.value;
          }
          return 0;
        });
        rowTotal = cells.reduce((prev, next) => {
          return (prev || 0) + (next || 0);
        }, 0);
        row.cells.total = {
          type: 'number',
          value: rowTotal,
        };
      }
    });
  } else {
    newColumns.push({
      id: 'total',
      label: '',
    });
  }
  return { columns: newColumns, rows: newRows };
};

import React from 'react';
import { Button, ButtonProps } from '@material-ui/core';

export default React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, ...rest }, ref) => (
    <Button {...rest} variant="contained" color="primary" ref={ref}>
      {children}
    </Button>
  )
);

import React, { useState, useCallback, memo, useMemo } from 'react';
import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';

import { FinancialYear } from '@agoy/api-sdk-core';
import {
  AccountPlanList,
  ReferencesTypes,
  Voucher,
  VoucherSeries,
} from 'types/Voucher';
import VoucherListFilter from './VoucherListFilter';
import VoucherListItem from './VoucherListItem';

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  min-width: 360px;
  border: 1px solid ${({ theme }) => theme.palette.grey[500]};
  border-radius: 12px;
`;

const FiltersSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 16px 24px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey[500]};
`;

const ListSection = styled.div`
  width: 100%;
  padding-right: 1rem;
  flex-direction: column;
`;

const Title = styled(Typography)`
  font-weight: 700;
  font-size: 19px;
`;

interface VoucherListProperties {
  clientId: string;
  financialYear: FinancialYear;
  voucherList: Voucher[];
  seriesList: VoucherSeries[];
  accounts: AccountPlanList[];
  voucherSeriesNextNumber: { [series: string]: number };
  integrationActive: boolean;
  onDeleteVoucher?: (voucher: Voucher) => Promise<void>;
  onUpdateVoucher?: (voucher) => Promise<void>;
}

const sortByType = (a: Voucher, b: Voucher) => {
  if (a.series === 'PREL' && b.series !== 'PREL') {
    return -1;
  }
  if (a.series !== 'PREL' && b.series === 'PREL') {
    return 1;
  }
  return 0;
};

const VoucherList = ({
  clientId,
  financialYear,
  voucherList,
  seriesList,
  accounts,
  voucherSeriesNextNumber,
  integrationActive,
  onDeleteVoucher,
  onUpdateVoucher,
}: VoucherListProperties) => {
  const [sortValue, setSortValue] = useState<'type' | null>(null);
  const [expanded, setExpanded] = useState<number | null>(null);

  const sortedVoucherList = useMemo(() => {
    if (sortValue === 'type') {
      const updatedList = [...voucherList];
      updatedList.sort(sortByType);
      return updatedList;
    }

    return voucherList;
  }, [sortValue, voucherList]);

  const handleFilterChange = () => {
    setSortValue((currentValue) => (!currentValue ? 'type' : null));
  };

  const handleExpandChange = useCallback((voucherId: number) => {
    setExpanded((currentValue) =>
      currentValue === voucherId ? null : voucherId
    );
  }, []);

  return (
    <Container>
      <FiltersSection>
        <Title>Verifikationslista</Title>
        <VoucherListFilter onFilterClick={handleFilterChange} />
      </FiltersSection>

      <ListSection>
        {sortedVoucherList
          .filter((item) => item.reference === ReferencesTypes.GLIDER)
          .map((voucher) => (
            <VoucherListItem
              key={voucher.id}
              clientId={clientId}
              financialYear={financialYear}
              voucher={voucher}
              seriesList={seriesList}
              accounts={accounts}
              isExpanded={expanded === voucher.id}
              integrationActive={integrationActive}
              nextSeriesNumber={voucherSeriesNextNumber[voucher.series]}
              onExpandChange={handleExpandChange}
              onDeleteVoucher={onDeleteVoucher}
              onUpdateVoucher={onUpdateVoucher}
            />
          ))}
      </ListSection>
    </Container>
  );
};

export default memo(VoucherList);

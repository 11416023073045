import {
  TaxDeclarationFormDefinition,
  TaxDeclarationFormDataConfig,
  PersonMetaData,
} from '../../types';
import { ref, value, id, ifOrElse } from '@agoy/document';
import { mapFormId, toInitial, toStructure } from '../forms-util';

const configK9 = (
  form: TaxDeclarationFormDefinition,
  person: PersonMetaData,
  financialYearEnd: string
): TaxDeclarationFormDataConfig => {
  const is2022 = financialYearEnd === '2022-12-31';

  const name = `${person?.firstName || ''} ${person?.lastName || ''}`;
  const K9 = mapFormId(form, 'K9');
  const initial = {
    K9: {
      partType: 'form' as const,
      id: K9,
      fields: {
        datum: value(''),
        numrering_vid_flera_K9: value(undefined),
        namn: value(name),
        personnummer: value(person.personNumber || ''),
        A_oakta_bostadsforetagets_namn: value(''),
        A_organisationsnummer: value(''),
        A_forsaljningsdatum_enligt_kopekontrakt: value(''),
        A_inkopsdatum_enligt_kopekontrakt: value(''),
        A_lagenhetsbeteckning: value(''),
        A_bostadsrattforening: value(false),
        A_aktiebolag: value(false),
        B_gemensamma: value(false),
        B_individuella: value(false),
        B_andel_av_bostadsratten_salt: value(undefined),
        B_1_forsaljningspris_enligt_kopekontrakt: value(undefined),
        B_2_forsaljningsutgifter: value(undefined),
        B_3_inkopspris: value(undefined),
        B_4_forbattringsutgifter: value(undefined),
        B_5_kapitaltillskott: value(undefined),
        B_6_bostadsrattens_andel_vid_forsaljningen: value(undefined),
        B_7_bostadsrattens_andel_vid_kopet: value(undefined),
        B_8_vinst_eller_forlust: value(undefined),
        B_9_beloppet_x_andel_bostadsratten: value(undefined),
        B_10_ateforing_av_uppskovsbelopp: value(undefined),
        B_11_vinst: value(undefined),
        B_12_forlust: value(undefined),
        B_13_vinst_beloppet: value(undefined),
        B_14_forlust_beloppet: value(undefined),
        B_15_avdrag_preliminart_uppskovsbelopp: value(undefined),
        B_16_avdrag_slutligt_uppskovsbelopp: value(undefined),
        B_17_aterstaende_vinst_efter_avdrag: value(undefined),
        B_begar_preliminart_uppskov: value(false),
        C_bosatt_from: value(''),
        C_bosatt_tom: value(''),
        C_bosatt_minst_ett_ar: value(false),
        C_bosatt_minst_tre_ar: value(false),
        C_forvarvat_min_nya_bostad: value(false),
        D_andel_bostadsratten_procent: value(undefined),
        D_andel_bostadsratten_kronor: value(undefined),
        E_fastighetsbeteckning: value(''),
        E_inkopsdatum_enligt_kopekontrakt: value(''),
        E_datum_for_bosattning: value(''),
        E_1_inkopspris: value(undefined),
        E_1_agarandel_procent: value(undefined),
        E_2_utgift: value(undefined),
        E_3_totalt_inkopspris: value(undefined),
        E_4_agarandel_procent: value(undefined),
        E_4_agarandel_kronor: value(undefined),
        F_bostadsrattsforeningens_namn: value(''),
        F_organisationsnummer: value(''),
        F_lagenhetsbeteckning: value(''),
        F_inkopsdatum_enligt_kopekontrakt: value(''),
        F_datum_bosattning: value(''),
        F_1_inkopspris_minus_ev_reparationsfond: value(undefined),
        F_1_agarandel_procent: value(undefined),
        F_2_agarandel_procent: value(undefined),
        F_2_agarandel_kronor: value(undefined),
        G_utlandska_boendeformen_namn: value(''),
        G_landskod: value(''),
        G_bostadens_gatuadress: value(''),
        G_bostadens_postadress: value(''),
        H_vinst: value(undefined),
        H_inkopspris: value(undefined),
        H_forsaljningspris_minus_utgifter: value(undefined),
        H_vinst_x_inkopspris_forsaljningspris: value(undefined),
        I_1_bostadsforman: value(undefined),
        I_2_beloppet: value(undefined),
        I_bostadsforetagets_organisationsnummer: value(''),
      },
      derivedFields: {
        B_summa_vinst: ref(
          ifOrElse(
            id('K9.fields.B_17_aterstaende_vinst_efter_avdrag'),
            id('K9.fields.B_17_aterstaende_vinst_efter_avdrag'),
            id('K9.fields.B_13_vinst_beloppet')
          )
        ),
        B_vinst_7_4: ref(
          ifOrElse(
            id('K9.fields.A_aktiebolag'),
            id('K9.derivedFields.B_summa_vinst'),
            undefined
          )
        ),
        B_vinst_7_5: ref(
          ifOrElse(
            id('K9.fields.A_bostadsrattforening'),
            id('K9.derivedFields.B_summa_vinst'),
            undefined
          )
        ),
        B_forlust_8_3: ref(
          ifOrElse(
            id('K9.fields.A_aktiebolag'),
            id('K9.fields.B_14_forlust_beloppet'),
            undefined
          )
        ),
        B_forlust_8_4: ref(
          ifOrElse(
            id('K9.fields.A_bostadsrattforening'),
            id('K9.fields.B_14_forlust_beloppet'),
            undefined
          )
        ),
      },
      sru: {
        '7014': ref(id('K9.fields.numrering_vid_flera_K9')),
        '4205': ref(id('K9.fields.A_oakta_bostadsforetagets_namn')),
        '4206': ref(id('K9.fields.A_organisationsnummer')),
        '3702': ref(id('K9.fields.A_forsaljningsdatum_enligt_kopekontrakt')),
        '3701': ref(id('K9.fields.A_inkopsdatum_enligt_kopekontrakt')),
        '3713': ref(id('K9.fields.A_lagenhetsbeteckning')),
        '4207': ref(id('K9.fields.A_bostadsrattforening')),
        '4208': ref(id('K9.fields.A_aktiebolag')),
        '3715': ref(id('K9.fields.B_gemensamma')),
        '3714': ref(id('K9.fields.B_individuella')),
        '3729': ref(id('K9.fields.B_andel_av_bostadsratten_salt')),
        '3720': ref(id('K9.fields.B_1_forsaljningspris_enligt_kopekontrakt')),
        '3721': ref(id('K9.fields.B_2_forsaljningsutgifter')),
        '3722': ref(id('K9.fields.B_3_inkopspris')),
        '4209': ref(id('K9.fields.B_4_forbattringsutgifter')),
        '3725': ref(id('K9.fields.B_5_kapitaltillskott')),
        '3726': ref(id('K9.fields.B_6_bostadsrattens_andel_vid_forsaljningen')),
        '3727': ref(id('K9.fields.B_7_bostadsrattens_andel_vid_kopet')),
        '3728': ref(id('K9.fields.B_8_vinst_eller_forlust')),
        '3730': ref(id('K9.fields.B_9_beloppet_x_andel_bostadsratten')),
        '3731': ref(id('K9.fields.B_10_ateforing_av_uppskovsbelopp')),
        '4215': ref(id('K9.fields.B_11_vinst')),
        '4216': ref(id('K9.fields.B_12_forlust')),
        '4217': ref(id('K9.fields.B_13_vinst_beloppet')),
        '4218': ref(id('K9.fields.B_14_forlust_beloppet')),
        '4225': ref(id('K9.fields.B_15_avdrag_preliminart_uppskovsbelopp')),
        '4226': ref(id('K9.fields.B_16_avdrag_slutligt_uppskovsbelopp')),
        '4227': ref(id('K9.fields.B_17_aterstaende_vinst_efter_avdrag')),
        '3801': ref(id('K9.fields.B_begar_preliminart_uppskov')),
        '3703': ref(id('K9.fields.C_bosatt_from')),
        '3704': ref(id('K9.fields.C_bosatt_tom')),
        '3716': ref(id('K9.fields.C_bosatt_minst_ett_ar')),
        '3717': ref(id('K9.fields.C_bosatt_minst_tre_ar')),
        '3719': ref(id('K9.fields.C_forvarvat_min_nya_bostad')),
        '3633': ref(id('K9.fields.D_andel_bostadsratten_procent')),
        '3639': ref(id('K9.fields.D_andel_bostadsratten_kronor')),
        '3640': ref(id('K9.fields.E_fastighetsbeteckning')),
        '3641': ref(id('K9.fields.E_inkopsdatum_enligt_kopekontrakt')),
        '3642': ref(id('K9.fields.E_datum_for_bosattning')),
        '3643': ref(id('K9.fields.E_1_inkopspris')),
        '3644': ref(id('K9.fields.E_2_utgift')),

        ...(is2022
          ? undefined
          : {
              '3645': ref(id('K9.fields.E_3_totalt_inkopspris')),
            }),

        ...(is2022
          ? { '3646': ref(id('K9.fields.E_1_agarandel_procent')) }
          : {
              '3646': ref(id('K9.fields.E_4_agarandel_procent')),
            }),

        ...(is2022
          ? { '3647': ref(id('K9.fields.E_3_totalt_inkopspris')) }
          : {
              '3647': ref(id('K9.fields.E_4_agarandel_kronor')),
            }),

        '3650': ref(id('K9.fields.F_bostadsrattsforeningens_namn')),
        '3651': ref(id('K9.fields.F_organisationsnummer')),
        '3652': ref(id('K9.fields.F_lagenhetsbeteckning')),
        '3653': ref(id('K9.fields.F_inkopsdatum_enligt_kopekontrakt')),
        '3654': ref(id('K9.fields.F_datum_bosattning')),

        ...(is2022
          ? undefined
          : {
              '3655': ref(
                id('K9.fields.F_1_inkopspris_minus_ev_reparationsfond')
              ),
            }),

        ...(is2022
          ? { '3656': ref(id('K9.fields.F_1_agarandel_procent')) }
          : {
              '3656': ref(id('K9.fields.F_2_agarandel_procent')),
            }),

        ...(is2022
          ? {
              '3657': ref(
                id('K9.fields.F_1_inkopspris_minus_ev_reparationsfond')
              ),
            }
          : {
              '3657': ref(id('K9.fields.F_2_agarandel_kronor')),
            }),

        '3662': ref(id('K9.fields.G_utlandska_boendeformen_namn')),
        '3663': ref(id('K9.fields.G_landskod')),
        '3664': ref(id('K9.fields.G_bostadens_gatuadress')),
        '3658': ref(id('K9.fields.G_bostadens_postadress')),
        '3696': ref(id('K9.fields.H_vinst_x_inkopspris_forsaljningspris')),
        '4235': ref(id('K9.fields.I_1_bostadsforman')),
        '4236': ref(id('K9.fields.I_2_beloppet')),
        '4237': ref(id('K9.fields.I_bostadsforetagets_organisationsnummer')),
      },
    },
  };
  return {
    initial: toInitial(initial, form),
    definition: toStructure(initial, form),
  };
};

export default configK9;

export const SET_FINANCIAL_REPORT_CONFIG =
  'bokslutl-report/SET_FINANCIAL_REPORT_CONFIG';
export const UPDATE_FINANCIAL_REPORT_CELL_REFERENCES =
  'bokslutl-report/UPDATE_FINANCIAL_REPORT_CELL_REFERENCES';
export const UPDATE_FINANCIAL_REPORT_CELL_VALUE =
  'bokslutl-report/UPDATE_FINANCIAL_REPORT_CELL_VALUE';
export const UPDATE_FINANCIAL_REPORT_FIELD =
  'bokslutl-report/UPDATE_FINANCIAL_REPORT_FIELD';
export const TOGGLE_FINANCIAL_REPORT_SECTION_ACTIVE =
  'bokslutl-report/TOGGLE_FINANCIAL_REPORT_SECTION_ACTIVE';
export const TOGGLE_FINANCIAL_REPORT_INCOME_STATEMENT_SECTION_ACTIVE =
  'bokslutl-report/TOGGLE_FINANCIAL_REPORT_INCOME_STATEMENT_SECTION_ACTIVE';
export const TOGGLE_FINANCIAL_REPORT_BALANCE_SHEET_SECTION_ACTIVE =
  'bokslutl-report/TOGGLE_FINANCIAL_REPORT_BALANCE_SHEET_SECTION_ACTIVE';
export const TOGGLE_FINANCIAL_REPORT_BALANCE_SHEET_ASSETS_SECTION_ACTIVE =
  'bokslutl-report/TOGGLE_FINANCIAL_REPORT_BALANCE_SHEET_ASSETS_SECTION_ACTIVE';
export const TOGGLE_FINANCIAL_REPORT_FIELD =
  'bokslutl-report/TOGGLE_FINANCIAL_REPORT_FIELD';
export const SET_FINANCIAL_REPORT_FIELD_ACTIVE =
  'bokslutl-report/SET_FINANCIAL_REPORT_FIELD_ACTIVE';
export const SET_FINANCIAL_REPORT_SECTION_ACTIVE =
  'bokslutl-report/SET_FINANCIAL_REPORT_SECTION_ACTIVE';
export const SET_BOOKSLUTS_REPORT_CHANGES =
  'bokslutl-report/SET_BOOKSLUTS_REPORT_CHANGES';
export const TOGGLE_FINANCIAL_REPORT_TABLE_ACTIVE =
  'bokslutl-report/TOGGLE_FINANCIAL_REPORT_TABLE_ACTIVE';
export const UPDATE_FINANCIAL_REPORT_CHANGES =
  'bokslutl-report/UPDATE_FINANCIAL_REPORT_CHANGES';
export const SET_FINANCIAL_REPORT_CHANGES =
  'bokslut-report/SET_FINANCIAL_REPORT_CHANGES';
export const SET_FINANCIAL_REPORT = 'bokslutl-report/SET_FINANCIAL_REPORT';
export const SET_FIRST_FINANCIAL_YEAR =
  'bokslutl-report/SET_FIRST_FINANCIAL_YEAR';
export const UPDATE_FINANCIAL_REPORT_ROWS =
  'bokslutl-report/UPDATE_FINANCIAL_REPORT_ROWS';
export const TOGGLE_FINANCIAL_REPORT_SETTINGS_PARAMETER =
  'financial-report/TOGGLE_FINANCIAL_REPORT_SETTINGS_PARAMETER';
export const SET_FINANCIAL_REPORT_DOCUMENTS =
  'financial-report/SET_FINANCIAL_REPORT_DOCUMENTS';
export const TOGGLE_FINANCIAL_REPORT_ADDITIONAL_TEXT_ACTIVE =
  'bokslutl-report/TOGGLE_FINANCIAL_REPORT_ADDITIONAL_TEXT_ACTIVE';

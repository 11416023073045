import React, { memo, useRef, useContext } from 'react';
import { createPortal } from 'react-dom';
import styled from '@emotion/styled';
import { Drawer as MuiDrawer } from '@material-ui/core';

import useHeaderHeight from '_shared/hooks/useHeaderHeight';
import { appHeaderHeight } from 'theme/measurements';
import { PrintContext } from '_shared/HOC/withPrintContext';

import DrawerHeader from '../DrawerHeader';

const DRAWER_EXPANDED_WIDTH = 1000;
const drawersElementId = 'drawers';

/*
      Instead of changing width (squeeze content effect) we keep the full width
      of the drawer off-screen and just move it on showing.
  */
const StyledDrawer = styled(
  ({ open, headerHeight, width, collapsedWidth, ...props }) => (
    <MuiDrawer {...props} />
  )
)<{
  open: boolean;
  headerHeight: number;
  width: string;
  collapsedWidth: string;
}>`
  position: fixed;
  white-space: nowrap;
  flex-shrink: 0;
  width: ${({ open, width, collapsedWidth }) =>
    open ? width : collapsedWidth};
  height: calc(100vh - ${({ headerHeight }) => headerHeight}px);
  top: calc(${({ headerHeight }) => headerHeight}px);
  right: 0;
  transition: width 0.25s ease-in-out;

  .MuiDrawer-paper {
    position: static;
    width: ${({ width }) => width};
    ${({ open }) => (open ? '' : 'overflow-x: hidden')};
  }
`;

interface DrawerProps {
  children: React.ReactNode;
  headerTitle: string;
  headerContent?: React.ReactElement;
  open: boolean;
  width?: string;
  collapsedWidth?: string;
  stickyHeader?: boolean;
  className?: string;
  onClose: () => void;
}

const Drawer = ({
  children,
  headerTitle,
  headerContent,
  open,
  width,
  collapsedWidth,
  stickyHeader,
  className,
  onClose,
}: DrawerProps) => {
  const topRef = useRef(null);
  const headerHeight = useHeaderHeight();

  const { hideMainContent } = useContext(PrintContext);

  if (hideMainContent) {
    return null;
  }

  const drawersElement = document.getElementById(drawersElementId);

  return (
    <>
      {createPortal(
        <StyledDrawer
          variant="permanent"
          anchor="right"
          open={open}
          headerHeight={headerHeight + appHeaderHeight}
          width={width ?? `${DRAWER_EXPANDED_WIDTH}px`}
          collapsedWidth={collapsedWidth ?? '0'}
          className={className}
        >
          <DrawerHeader
            ref={topRef}
            title={headerTitle}
            expanded={open}
            toggleExpanded={onClose}
            fixed={stickyHeader}
          >
            {headerContent}
          </DrawerHeader>

          {children}
        </StyledDrawer>,
        drawersElement as Element
      )}
    </>
  );
};

export default memo(Drawer);

import { value, table, ref, or, sum, id } from '@agoy/document';
import { sumAccountsUBs } from '../../../../common/utils/accountSumsUtil';
import { year, previousYear } from '../../../../common/utils/date-util';
import { Note28Section } from '../../../../common';
import { field } from '../../../../common/utils/util';
import { NoteAccountRanges } from '../types';

// TODO: It has two tables
export const note28Accounts: NoteAccountRanges[] = [
  {
    rowId: '1',
    ranges: [[2300, 2319]],
    cellLabel: 'Obligationslån',
    action: 'sumAccountsUBs',
  },
  {
    rowId: '2',
    ranges: [[2350, 2359]],
    cellLabel: 'Skulder till kreditinstitut',
    action: 'sumAccountsUBs',
  },
  {
    rowId: '3',
    ranges: [[2360, 2369]],
    cellLabel: 'Skulder till koncernföretag',
    action: 'sumAccountsUBs',
  },
  {
    rowId: '4',
    ranges: [[2370, 2372]],
    cellLabel: 'Skulder till intresseföretag och gemensamt styrda företag',
    action: 'sumAccountsUBs',
  },
  {
    rowId: '5',
    ranges: [[2373, 2379]],
    cellLabel: 'Skulder till övriga företag som det finns ett ägarintresse i',
    action: 'sumAccountsUBs',
  },
  {
    rowId: '6',
    ranges: [[2380, 2399]],
    cellLabel: 'Övriga skulder',
    action: 'sumAccountsUBs',
  },
  {
    rowId: '1',
    ranges: [[2400, 2419]],
    cellLabel: 'Skulder till kreditinstitut',
    action: 'sumAccountsUBs',
  },
  {
    rowId: '2',
    // This one has a different acc range for previousYear
    ranges: [[2460, 2469]],
    cellLabel: 'Skulder till koncernföretag',
    action: 'sumAccountsUBs',
  },
  {
    rowId: '3',
    ranges: [
      [2470, 2472],
      [2870, 2872],
    ],
    cellLabel: 'Skulder till intresseföretag och gemensamt styrda företag',
    action: 'sumAccountsUBs',
  },
  {
    rowId: '4',
    ranges: [
      [2473, 2479],
      [2873, 2879],
    ],
    cellLabel: 'Skulder till övriga företag som det finns ett ägarintresse i',
    action: 'sumAccountsUBs',
  },
];

export const getNote28 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null,
  documentTypeVersion = '1'
): Note28Section => {
  return {
    active: false,
    number: value(undefined),
    name: value('Skulder som avser flera poster'),
    general: field(
      'Företagets banklån/skuld om yyy kronor redovisas under följande poster i balansräkningen.'
    ),
    table: table(
      'notes.note28.table',
      { id: 'label', label: 'Långfristiga skulder' },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      .addRows((rows) =>
        rows
          .addRow(
            '1',
            noteLabel('Obligationslån'),
            ref(sumAccountsUBs('2300:2319', true)),
            ref(sumAccountsUBs('2300:2319', true, 'previousYear'))
          )
          .addRow(
            '2',
            noteLabel('Skulder till kreditinstitut'),
            ref(sumAccountsUBs('2350:2359', true)),
            ref(sumAccountsUBs('2350:2359', true, 'previousYear'))
          )
          .addRow(
            '3',
            noteLabel('Skulder till koncernföretag'),
            ref(sumAccountsUBs('2360:2369', true)),
            ref(sumAccountsUBs('2360:2369', true, 'previousYear'))
          )
          .addRow(
            '4',
            noteLabel(
              'Skulder till intresseföretag och gemensamt styrda företag'
            ),
            ref(sumAccountsUBs('2370:2372', true)),
            ref(sumAccountsUBs('2370:2372', true, 'previousYear'))
          )
          .addRow(
            '5',
            noteLabel(
              'Skulder till övriga företag som det finns ett ägarintresse i'
            ),
            ref(sumAccountsUBs('2373:2379', true)),
            ref(sumAccountsUBs('2373:2379', true, 'previousYear'))
          )
          .addRow(
            '6',
            noteLabel('Övriga skulder'),
            ref(sumAccountsUBs('2380:2399', true)),
            ref(sumAccountsUBs('2380:2399', true, 'previousYear'))
          )
          .build()
      )
      .newRowTemplate(value(''), value(undefined), value(undefined))
      .build(),
    secondTable: table(
      'notes.note28.secondTable',
      { id: 'label', label: 'Kortfristiga skulder' },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      .addRows((rows) =>
        rows
          .addRow(
            '1',
            noteLabel('Skulder till kreditinstitut'),
            ref(sumAccountsUBs('2400:2419', true)),
            ref(sumAccountsUBs('2400:2419', true, 'previousYear'))
          )
          .addRow(
            '2',
            noteLabel('Skulder till koncernföretag'),
            ref(sumAccountsUBs('2460:2469', true)),
            ref(sumAccountsUBs('2460:2469+2860:2869', true, 'previousYear'))
          )
          .addRow(
            '3',
            noteLabel(
              'Skulder till intresseföretag och gemensamt styrda företag'
            ),
            ref(sumAccountsUBs('2470:2472+2870:2872', true)),
            ref(sumAccountsUBs('2470:2472+2870:2872', true, 'previousYear'))
          )
          .addRow(
            '4',
            noteLabel(
              'Skulder till övriga företag som det finns ett ägarintresse i'
            ),
            ref(sumAccountsUBs('2473:2479+2873:2879', true)),
            ref(sumAccountsUBs('2473:2479+2873:2879', true, 'previousYear'))
          )
          .addRow(
            '5',
            noteLabel('Övriga skulder'),
            value(undefined),
            value(undefined)
          )
          .build()
      )
      .newRowTemplate(value(''), value(undefined), value(undefined))
      .build(),
    sumTable: table(
      'notes.note28.sumTable',
      { id: 'label', label: '' },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      .addRows((rows) =>
        rows
          .addRow(
            'sum',
            noteLabel('Summa'),
            ref(
              or(
                sum(
                  sum(
                    id('notes.note28.table.*.year'),
                    id('notes.note28.secondTable.*.year')
                  )
                ),
                0
              )
            ),
            ref(
              or(
                sum(
                  sum(
                    id('notes.note28.table.*.previousYear'),
                    id('notes.note28.secondTable.*.previousYear')
                  )
                ),
                0
              )
            )
          )
          .build()
      )
      .build(),
    commentLabel: field('Kommentar'),
    comment: field(''),
    group: value(undefined),
    section: value(undefined),
    validForReportType: {
      k2: true,
      k3: documentTypeVersion === '1',
    },
  };
};

import React from 'react';
import styled from '@emotion/styled';
import { AnnualReportType, CommonNotes } from '@agoy/annual-report-document';
import { numberValue, stringValue } from '@agoy/document';
import PrincipleTable from './Note1Table';
import Comment from '../../UI/Comment';
import ToggleSection from '../../UI/ToggleSection';
import ToggleMultiSection from '../../UI/ToggleMultiSection';
import Header from '../../UI/Header';
import FieldSection from '../../UI/FieldSection';

const Note1Wrapper = styled.div`
  margin: 2rem 0 0 0;
  max-width: 960px;
`;

type Note1Props = {
  notes: CommonNotes;
  reportType: AnnualReportType;
  isDigitalSubmission: boolean;
};

const Note1 = ({
  notes,
  reportType,
  isDigitalSubmission,
}: Note1Props): JSX.Element => {
  const { note1 } = notes;
  return (
    <Note1Wrapper id="note1">
      <Header
        noteId="note1"
        noteNumber={numberValue(note1.number)}
        title={stringValue(note1.name) || ''}
        field={note1.name}
      />
      {/* This version of Note1 is just for k3 */}
      {'disclaimerK3' in note1 && reportType === 'k3' && (
        <FieldSection value={stringValue(note1.disclaimerK3) || ''} />
      )}
      {[
        'basicPrinciplesDeviations',
        'accountingPrincipleChange',
        'deviationReasons',
        'notComparablePrincipleChange',
        'generalAdviceDeviation',
        'incomeStatementChange',
        'firstTimeK3',
      ].map(
        (section) =>
          section in note1 && (
            <ToggleSection
              key={section}
              id={`${section}Label`}
              section="note1"
              target={note1[`${section}Label`]}
              title={stringValue(note1[`${section}Label`]) || ''}
            >
              <FieldSection value={stringValue(note1[section]) || ''} />
            </ToggleSection>
          )
      )}
      {'foreignCurrencyLabel' in note1 && (
        <ToggleMultiSection
          headingTranslationKey="note1.foreignCurrencyLabel"
          title={stringValue(note1.foreignCurrencyLabel || '')}
          toggleSections={[
            {
              type: 'field',
              section: 'note1',
              id: 'foreignCurrencyGeneral',
            },
            {
              type: 'field',
              section: 'note1',
              id: 'foreignCurrencyConversion',
            },
            {
              type: 'field',
              section: 'note1',
              id: 'foreignCurrencyRecalculation',
            },
          ]}
          notes={notes}
        >
          <FieldSection
            field={note1.foreignCurrencyGeneral}
            value={stringValue(note1.foreignCurrencyGeneral) || ''}
          />

          <FieldSection
            field={note1.foreignCurrencyConversionLabel}
            value={stringValue(note1.foreignCurrencyConversionLabel) || ''}
          />
          <FieldSection
            field={note1.foreignCurrencyConversion}
            value={stringValue(note1.foreignCurrencyConversion) || ''}
          />

          <FieldSection
            field={note1.foreignCurrencyRecalculationLabel}
            value={stringValue(note1.foreignCurrencyRecalculationLabel) || ''}
          />
          <FieldSection
            field={note1.foreignCurrencyRecalculation}
            value={stringValue(note1.foreignCurrencyRecalculation) || ''}
          />
        </ToggleMultiSection>
      )}

      {'k3IncomeLabel' in note1 && (
        <ToggleMultiSection
          headingTranslationKey="note1.k3IncomeLabel"
          title={stringValue(note1.k3IncomeLabel || '')}
          toggleSections={[
            {
              type: 'field',
              section: 'note1',
              id: 'k3IncomeGeneral',
            },
            {
              type: 'field',
              section: 'note1',
              id: 'k3SaleOfGoods',
            },
            {
              type: 'field',
              section: 'note1',
              id: 'k3Services',
            },
            {
              type: 'field',
              section: 'note1',
              id: 'k3CustomerLoyalty',
            },
            {
              type: 'field',
              section: 'note1',
              id: 'k3InterestAndDividend',
            },
            {
              type: 'field',
              section: 'note1',
              id: 'k3WarrantyObligations',
            },
          ]}
          notes={notes}
        >
          <FieldSection
            field={note1.k3IncomeGeneral}
            value={stringValue(note1.k3IncomeGeneral) || ''}
          />

          {[
            'k3SaleOfGoods',
            'k3Services',
            'k3CustomerLoyalty',
            'k3InterestAndDividend',
            'k3WarrantyObligations',
          ].map(
            (section) =>
              section in note1 && (
                <>
                  <FieldSection
                    key={section}
                    field={note1[`${section}Label`]}
                    value={stringValue(note1[`${section}Label`]) || ''}
                  />
                  <FieldSection
                    key={section}
                    field={note1[section]}
                    value={stringValue(note1[section]) || ''}
                  />
                </>
              )
          )}
        </ToggleMultiSection>
      )}

      {[
        'publicContributions',
        'employeeCompensation',
        'loanExpenses',
        'tax',
        'leasing',
      ].map(
        (section) =>
          section in note1 && (
            <ToggleSection
              key={section}
              id={`${section}Label`}
              section="note1"
              target={note1[`${section}Label`]}
              title={stringValue(note1[`${section}Label`]) || ''}
            >
              <FieldSection value={stringValue(note1[section]) || ''} />
            </ToggleSection>
          )
      )}

      {'intangibleAssetsTable' in note1 && (
        <ToggleMultiSection
          headingTranslationKey="note1.intangibleAssetsGeneralLabel"
          title={stringValue(note1.intangibleAssetsGeneralLabel || '')}
          toggleSections={[
            {
              type: 'field',
              section: 'note1',
              id: 'intangibleAssetsGeneral',
            },
            { type: 'table', section: 'note1', id: 'intangibleAssetsTable' },
            {
              type: 'field',
              section: 'note1',
              id: 'intangibleAssetsProjectsClosed',
            },
          ]}
          notes={notes}
        >
          <FieldSection
            value={stringValue(note1.intangibleAssetsGeneral) || ''}
          />

          {'intangibleAssetsTableCaption' in note1 && (
            <FieldSection
              value={stringValue(note1.intangibleAssetsTableCaption) || ''}
            />
          )}
          <PrincipleTable
            table={note1.intangibleAssetsTable}
            tableId="notes.note1.intangibleAssetsTable"
          />
          <FieldSection
            value={stringValue(note1.intangibleAssetsProjectsClosed) || ''}
          />
        </ToggleMultiSection>
      )}
      {/* Tangible assets section */}
      {'tangibleFixedAssetsLabel' in note1 && (
        <ToggleMultiSection
          headingTranslationKey="note1.tangibleFixedAssetsLabel"
          title={stringValue(note1.tangibleFixedAssetsLabel || '')}
          toggleSections={[
            { type: 'table', section: 'note1', id: 'tangibleFixedAssets' },
          ]}
          notes={notes}
        >
          <FieldSection
            field={note1.tangibleFixedAssetsGeneral}
            value={stringValue(note1.tangibleFixedAssetsGeneral) || ''}
          />

          {'tangibleFixedAssetsCaption' in note1 && (
            <FieldSection
              value={stringValue(note1.tangibleFixedAssetsCaption) || ''}
            />
          )}
          {'tangibleFixedAssets' in note1 && (
            <PrincipleTable
              table={note1.tangibleFixedAssets}
              tableId="notes.note1.tangibleFixedAssets"
            />
          )}
          {'tangibleAssetsComment' in note1 && (
            <Comment
              field={note1.tangibleAssetsComment}
              value={stringValue(note1.tangibleAssetsComment) || ''}
              labelValue={stringValue(note1.tangibleAssetsCommentLabel)}
            />
          )}
        </ToggleMultiSection>
      )}
      {[
        'fixedAssetsWriteDowns',
        'companiesShares',
        'groupContributions',
        'jointVenture',
        'financialInstruments',
        'collateralAccounting',
        'inventory',
        'provisions',
        'contingentAssetsAndLiabilities',
        'debtAcquisition',
        'cashflowAnalysis',
      ].map(
        (section) =>
          section in note1 && (
            <ToggleSection
              key={section}
              id={`${section}Label`}
              section="note1"
              target={note1[`${section}Label`]}
              title={stringValue(note1[`${section}Label`]) || ''}
            >
              <FieldSection value={stringValue(note1[section]) || ''} />
            </ToggleSection>
          )
      )}
    </Note1Wrapper>
  );
};

export default Note1;

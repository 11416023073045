import { Status } from '_shared/types';
import { intl } from '_shared/HOC/withIntl';
import { translations } from '@agoy/program-status';

type DocumentValue = string | number | boolean | undefined;

export interface DocumentValues {
  [key: string]: DocumentValue;
}

export interface FinancialYearDocuments {
  [documentName: string]: DocumentValues;
}

export interface ClientDocumentState {
  [financialYear: string]: FinancialYearDocuments;
}
interface DocumentState {
  [clientId: string]: ClientDocumentState;
}

export const PeriodStatuses: Record<Status, string> = {
  NOT_STARTED: intl.formatMessage(translations.NOT_STARTED),
  STARTED: intl.formatMessage(translations.STARTED),
  AW_COMPL: intl.formatMessage(translations.AW_COMPL),
  AW_REVIEW: intl.formatMessage(translations.AW_REVIEW),
  DONE: intl.formatMessage(translations.DONE),
  LOCKED: intl.formatMessage(translations.LOCKED),
};

export interface BasisAccount {
  number: string;
  name: string;
}

export default DocumentState;

import { formatFinancialYear } from '@agoy/common';
import { AnnualReportCustomerType } from '../../common';
import { field, ixbrlField, table } from '../../common/utils/util';
import {
  abs,
  id,
  multiply,
  sum,
  ref,
  ifOrElse,
  round,
  value,
  or,
  TimePeriod,
  ReferenceCell,
  not,
  max,
  account,
  sumAllowNull,
  sumAllowEmpty,
} from '@agoy/document';
import { Settings } from '../../common/types/settings';

interface Warning {
  id: string; // warning id
  label: string; // warning text
  type?: 'calculation' | 'id'; // type to define whether warning needs to calculate "A" (parent value), default = 'id'. NOTE: only "A" should be calculatable for type 'calculation'
  a: string; // A value (parent, will never have warning cell)
  b: string; // B value (child, will always have warning cell)
  isDigitalSubmission?: boolean;
  hasNoPreviousYear?: boolean;
}

const incomeStatementAretsResultatYear = 'incomeStatement.AretsResultat.year';
const balanceSheetAretsResultatYear = 'balanceSheet.AretsResultatEgetKapital.year';
const incomeStatementAretsResultatPreviousYear =
  'incomeStatement.AretsResultat.previousYear';
const balanceSheetFrittEgetKapitalYear = 'balanceSheet.FrittEgetKapital.year';
const balanceSheetBalanseratResultatYear =
  'balanceSheet.BalanseratResultat.year';
const balanceSheetBalanseratResultatPreviousYear =
  'balanceSheet.BalanseratResultat.previousYear';

// AGOY-3964: for each new warning, add a new object here with the information below. a and b are compared
// "a" is ALWAYS parent and will not have warning displayed.
// For better results consider which value you will treat as source of truth and put it in "a".
const warnings = (
  isDigitalSubmission = false,
  hasNoPreviousYear = false,
  isK3 = false,
): Warning[] => {
  const getChangesInEquitySum = (column: string) : string => {
    return `managementReport.changesInEquity.table.main.sumByYearEnd.${column}`;
  };

  return [
    {
      id: 'changesInEquityTotalUnrestrictedEquityWarning',
      label:
        'Värdena i balansräkningen (A) och i förvaltningsberättelsens förändringar i eget kapital belopp vid årets utgång (B) stämmer inte överens.',
      a: balanceSheetFrittEgetKapitalYear,
      b: 'managementReport.changesInEquity.table.main.sumByYearEnd.totalUnrestrictedEquity',
    },
    {
      id: 'sumAssetsWarning',
      label:
        'Värdena i balansräkningens summa tillgångar (A) och i balansräkningens summa eget kapital och skulder  (B) stämmer inte överens.',
      a: 'balanceSheet.Tillgangar.year',
      b: 'balanceSheet.EgetKapitalSkulder.year',
    },
    {
      id: 'sumAssetsPreviousYearWarning',
      label:
        'Värdena i balansräkningens summa tillgångar för föregående (A) och  i balansräkningens summa eget kapital och skulder för föregående (B) stämmer inte överens.',
      a: 'balanceSheet.Tillgangar.previousYear',
      b: 'balanceSheet.EgetKapitalSkulder.previousYear',
    },
    {
      id: 'FrittEgetKapital',
      label:
        'Värdena för summan som står till årsstämmans förfogande i resultatdispositionen (B) och för summa fritt eget kapital i balansräkningen (A) stämmer inte överens.',
      a: balanceSheetFrittEgetKapitalYear,
      b: 'managementReport.resultsDisposition.total',
    },
    {
      id: 'FrittEgetKapital-1',
      label:
        'Värdena för summan till disponering som styrelsen föreslår (B) och för summa fritt eget kapital i balansräkningen (A) stämmer inte överens.',
      a: balanceSheetFrittEgetKapitalYear,
      b: 'managementReport.resultsDisposition.inTotal',
    },
    {
      id: 'Nettoomsattning-1',
      label:
        'Värdena i Flerårsöversikt för nettoomsattning (A) och för nettoomsättning i resultaträknings rörelsens intäkter m.m. för det sista räkenskapsår (B) stämmer inte överens.',
      a: 'managementReport.multiYearOverview.table.netto.period0',
      b: 'incomeStatement.Nettoomsattning.year',
    },
    {
      id: 'Nettoomsattning-2',
      label:
        'Värdena i Flerårsöversikt för nettoomsattning (A) och för nettoomsättning i resultaträknings rörelsens intäkter m.m. för det föregående räkenskapsår (B) stämmer inte överens.',
      a: 'managementReport.multiYearOverview.table.netto.period1',
      b: 'incomeStatement.Nettoomsattning.previousYear',
    },
    {
      id: 'IncomeStatementYearResultWarning-1',
      label:
        'Värdena i balansräkningens eget kapital och skulder årets resultat (B) och i resultaträkningens årets resultat (A) stämmer inte överens.',
      a: incomeStatementAretsResultatYear,
      b: balanceSheetAretsResultatYear,
    },
    {
      id: 'IncomeStatementYearResultWarning-2',
      label:
        'Värdena i förvaltningsberättelsens resultatdisposition årets resultat (B) och i resultaträkningens årets resultat (A) stämmer inte överens.',
      a: incomeStatementAretsResultatYear,
      b: 'managementReport.resultsDisposition.toDispose.2.value',
    },
    {
      id: 'IncomeStatementYearResultWarning-3',
      label:
        'Värdena i förvaltningsberättelsens förändringar i eget kapital årets resultat (B) och i resultaträkningens årets resultat (A) stämmer inte överens.',
      a: incomeStatementAretsResultatYear,
      b: 'managementReport.changesInEquity.table.main.content.yearResult.yearResult',
    },
    {
      id: 'IncomeStatementPreviousYearResultWarning-1',
      label:
        'Värdena i balansräkningens fritt eget kapital för årets resultat (B) och i resultaträkningens årets resultat för föregående (A) stämmer inte överens.',
      a: incomeStatementAretsResultatPreviousYear,
      b: 'balanceSheet.AretsResultatEgetKapital.previousYear',
    },
    {
      id: 'IncomeStatementPreviousYearResultWarning-2',
      label:
        'Värdena i förändringar i eget kapital för belopp vid årets ingång (B) och i resultaträkningens årets resultat för föregående (A) stämmer inte överens.',
      a: incomeStatementAretsResultatPreviousYear,
      b: 'managementReport.changesInEquity.table.main.content.incomingAmount.yearResult',
    },
    isDigitalSubmission
      ? // If digital submission and no previous year, we compare b to itself to avoid warning
        {
          id: 'BalanseratResultatWarning',
          label:
            'Värdena i förändringar i eget kapital för balanserat resultat i belopp vid årets ingång (B) och i balansräkningens fritt eget kapital för balanserat resultat (A) stämmer inte överens.',
          a: hasNoPreviousYear
            ? 'managementReport.changesInEquity.table.main.content.incomingAmount.balanseratResultat'
            : balanceSheetBalanseratResultatPreviousYear,
          b: 'managementReport.changesInEquity.table.main.content.incomingAmount.balanseratResultat',
        }
      : {
          id: 'BalanseratResultatWarning',
          label:
            'Värdena i förändringar i eget kapital för balanserat resultat i belopp vid årets ingång (B) och i balansräkningens fritt eget kapital för balanserat resultat (A) stämmer inte överens.',
          a: balanceSheetBalanseratResultatPreviousYear,
          b: 'managementReport.changesInEquity.table.main.content.incomingAmount.balanseratResultat',
        },
    isDigitalSubmission
      ? {
          id: 'BalanseratResultatWarning-2',
          label:
            'Värdena för balanserat resultat som står till årsstämmans förfogande i resultatdispositionen (B) och i balansräkningens fritt eget kapital för balanserat resultat (A) stämmer inte överens.',
          a: balanceSheetBalanseratResultatYear,
          b: 'managementReport.resultsDisposition.toDispose.1.value',
        }
      : {
          id: 'BalanseratResultatWarning-2',
          label:
            'Värdena för balanserat resultat som står till årsstämmans förfogande i resultatdispositionen (B) och i balansräkningens fritt eget kapital för balanserat resultat (A) stämmer inte överens.',
          type: 'calculation',
          // Sum of balanserat resultat (year) and erhållna aktieägartillskott (year)
          a: sumAllowEmpty(
            id(balanceSheetBalanseratResultatYear),
            id(
              'balanceSheet.section.equityAndLiabilities.equityAndLiabilities.equity.unrestrictedEquity.3.year'
            )
          ),
          b: 'managementReport.resultsDisposition.toDispose.1.value',
        },
    {
      id: 'BalanseratResultatWarning-3',
      label:
        'Värdena balanserat resultat, förändringar i eget kapital vid årets utgång (A) stämmer inte överens med balansräkningens balanserat resultat (B).',
      a: isK3 ? getChangesInEquitySum('balanceResult') : getChangesInEquitySum('balanseratResultat'),
      b: balanceSheetBalanseratResultatYear,
    },
    {
      id: 'AretsResultatWarning-1',
      label:
        'Värdena årets resultat, förändringar i eget kapital vid årets utgång (A) stämmer inte överens med balansräkningens eget kapital och skulder årets resultat (B).',
      a: getChangesInEquitySum('yearResult'),
      b: balanceSheetAretsResultatYear,
    },
    {
      id: 'ResultatEfterFinansiellaPosterWarning-1',
      label:
        'Värdena för resultat efter finansiella poster i flerårsöversikt (B) och i resultaträkningen (A) stämmer inte överens.',
      a: 'incomeStatement.ResultatEfterFinansiellaPoster.year',
      b: 'managementReport.multiYearOverview.table.result.period0',
    },
    {
      id: 'ResultatEfterFinansiellaPosterWarning-2',
      label:
        'Värdena för resultat efter finansiella poster i flerårsöversikt (B) och i resultaträkningen för föregående år (A) stämmer inte överens.',
      a: 'incomeStatement.ResultatEfterFinansiellaPoster.previousYear',
      b: 'managementReport.multiYearOverview.table.result.period1',
    },

    {
      id: 'IncomeStatementPreviousYearResultBeforeSkattSumWarning',
      label:
        'Värdena i resultaträkningens resultat före skatt (eller summa av resultat före skatt och övriga skattekostnader) för föregående räkenskapsår (A) och i resultaträkningens årets resultat för föregående räkenskapsår (B) stämmer inte överens.',
      type: 'calculation',
      // Sum Resultat före skatt and
      //  - if Summa skatter is present, use it
      //  - if not, use the sum of rows from Skatter
      a: sum(
        or(id('incomeStatement.ResultatForeSkatt.previousYear'), 0),
        or(
          or(
            id(
              'incomeStatement.section.table.income.main.taxesSum.previousYear'
            ),
            sumAllowNull(
              id('incomeStatement.SkattAretsResultat.previousYear'),
              id('incomeStatement.OvrigaSkatter.previousYear')
            )
          ),
          0
        )
      ),
      b: incomeStatementAretsResultatPreviousYear,
    },
    {
      id: 'IncomeStatementYearResultBeforeSkattSumWarning',
      label:
        'Värdena i resultaträkningens resultat före skatt (eller summa av resultat före skatt och övriga skattekostnader) (A) och i resultaträkningens årets resultat (B) stämmer inte överens.',
      type: 'calculation',
      // Sum Resultat före skatt and
      //  - if Summa skatter is present, use it
      //  - if not, use the sum of rows from Skatter
      a: sum(
        or(id('incomeStatement.ResultatForeSkatt.year'), 0),
        or(
          id('incomeStatement.section.table.income.main.taxesSum.year'),
          sumAllowNull(
            id('incomeStatement.SkattAretsResultat.year'),
            id('incomeStatement.OvrigaSkatter.year')
          ),
          0
        )
      ),
      b: incomeStatementAretsResultatYear,
    },
  ];
};

export const getReferenceIdByValue = (
  references: Record<string, string>,
  cellId: string
) => {
  return Object.keys(references).find((key) => {
    return id(cellId) === references[key];
  });
};

export const getWarningCell = (cellId: string): ReferenceCell | undefined => {
  /**
   * Find all appearances of cellId in B
   * If there is no appearance
   *    return undefined
   *
   * Find first difference that is not 0
   * if there is one
   *    return warning
   *  else return 0
   */
  const possibleWarnings = warnings().filter((warning) => warning.b === cellId);
  if (!possibleWarnings?.length) {
    return undefined;
  }

  const getConditionForIndex = (currentIndex) => {
    return ifOrElse(
      round(
        id(
          `settings.warnings.table.${possibleWarnings[currentIndex].id}.difference`
        )
      ),
      id(`settings.warnings.table.${possibleWarnings[currentIndex].id}.label`),
      possibleWarnings[currentIndex + 1]
        ? getConditionForIndex(currentIndex + 1)
        : '0'
    );
  };
  return ref(getConditionForIndex(0));
};

const getSubmissionWarnings = (
  isFirstFinancialYear: boolean,
  reportTransitionK3toK2: boolean
) => [
  {
    id: 'unrestrictedEquitySumTooLow',
    label:
      'Eget kapital är förbrukat. En kontrollbalansräkning måste upprättas. Du har 8 månader på dig att återställa eget kapital från det datum då kontrollbalansräkningen upprättades.',
    type: 'warning',
    result: ref(
      not(
        not(
          round(
            max(
              sum(
                multiply(0.5, id('balanceSheet.Aktiekapital.year')),
                multiply(id('balanceSheet.EgetKapital.year'), -1)
              ),
              0
            )
          )
        )
      )
    ),
  },
  {
    id: 'incomeShareCapital',
    label:
      'Värden för aktiekapital (föregående år) stämmer inte överens mellan balansräkning och Förvaltningsberättelsen.',
    type: 'warning',
    result: !isFirstFinancialYear
      ? ref(
          not(
            not(
              round(
                sum(
                  id('balanceSheet.Aktiekapital.previousYear'),
                  multiply(
                    id(
                      !reportTransitionK3toK2
                        ? 'managementReport.changesInEquity.table.main.content.incomingAmount.shareCapital'
                        : 'managementReport.changesInEquity.table.transitionToK2.amountPrevYear.shareCapital'
                    ),
                    -1
                  )
                )
              )
            )
          )
        )
      : value(false),
  },
  {
    id: 'outcomeShareCapital',
    label:
      'Värden för aktiekapital (innevarande år) stämmer inte överens mellan balansräkningen och Förändringar i eget kapital (EK) i Förvaltningsberättelsen.',
    type: 'warning',
    result: ref(
      not(
        not(
          round(
            sum(
              id('balanceSheet.Aktiekapital.year'),
              multiply(
                id(
                  'managementReport.changesInEquity.table.main.sumByYearEnd.shareCapital'
                ),
                -1
              )
            )
          )
        )
      )
    ),
  },
  {
    id: 'shareCapital',
    label:
      'Aktiekaptial hittades inte på konto 2081 för nuvarande och förgående räkenskapsår. Kontrollera att du har bokat aktiekapital på konto 2081 i balansräkningen för nuvarande och förgående räkenskapsår.',
    type: 'warning',
    result: !isFirstFinancialYear
      ? ref(
          not(
            sum(
              id('balanceSheet.Aktiekapital.year'),
              id('balanceSheet.Aktiekapital.previousYear')
            )
          )
        )
      : value(false),
  },
  {
    id: 'shareCapitalCurrentYear',
    label:
      'Aktiekaptial hittades inte på konto 2081 för nuvarande räkenskapsår. Kontrollera att du har bokat aktiekapital på konto 2081 i balansräkningen för nuvarande och förgående räkenskapsår. Är detta företagets första räkenskapsår så måste aktiekapitalet vid årets ingång hanteras manuellt.',
    type: 'warning',
    result: !isFirstFinancialYear
      ? ref(
          ifOrElse(
            not(id('settings.warnings.submissionWarnings.shareCapital.result')),
            not(id('balanceSheet.Aktiekapital.year')),
            not(id('settings.warnings.submissionWarnings.shareCapital.result'))
          )
        )
      : ref(not(id('balanceSheet.Aktiekapital.year'))),
  },
  {
    id: 'shareCapitalPreviousYear',
    label:
      'Aktiekaptial hittades inte på konto 2081 för förgående räkenskapsår. Kontrollera att du har bokat aktiekapital på konto 2081 i balansräkningen för nuvarande och förgående räkenskapsår.',
    type: 'warning',
    result: !isFirstFinancialYear
      ? ref(
          ifOrElse(
            not(id('settings.warnings.submissionWarnings.shareCapital.result')),
            not(or(id('balanceSheet.Aktiekapital.previousYear'), 1)),
            not(id('settings.warnings.submissionWarnings.shareCapital.result'))
          )
        )
      : value(false),
  },
];

export const settingsConfig = (
  customer: AnnualReportCustomerType,
  financialYear: string,
  period: TimePeriod,
  previousPeriod: TimePeriod | null,
  isFirstFinancialYear: boolean,
  isDigitalSubmission: boolean,
  hasNoPreviousYear: boolean,
  isSinglePeriod: boolean,
  isK3: boolean,
  reportTransitionK3toK2: boolean
): Settings => {
  return {
    section: {
      title: field(''),
      secondaryText: field(''),
      currencyText: field(''),
      clientName: field(customer.name),
      isSinglePeriod,
      period: field(formatFinancialYear(financialYear)),
      financialYear: field(financialYear),
      periodStart: ixbrlField(field(period.startDateISO), {
        type: 'dateItemType',
        name: 'se-cd-base:RakenskapsarForstaDag',
        contextRef: 'period0',
      }),
      periodEnd: ixbrlField(field(period.endDateISO), {
        type: 'dateItemType',
        name: 'se-cd-base:RakenskapsarSistaDag',
        contextRef: 'period0',
      }),
      previousPeriodStart: field(previousPeriod?.startDateISO || ''),
      previousPeriodEnd: field(previousPeriod?.endDateISO || ''),
      organisationNumber: field(customer.orgNumber ?? ''),
      isFirstFinancialYear,
      logoLargeUrl: field(customer.logoOriginal ?? ''),
      logoUrl: field(customer.logo ?? ''),
      isAuditReportRequired: ref(
        not(
          not(
            multiply(
              round(max(sum(account('3000:3999'), -3000000), 0)),
              round(max(sum(account('1000:1999'), -1500000), 0))
            )
          )
        )
      ),
      auditReportRequiredUpdated: value(''),
      typeSigningAnnualReport: {
        isDigitalSigningWithFortnox: value(false),
        isManualSigning: value(true),
        isOtherDigitalSigning: value(false),
      },
      typeSigningProtocol: {
        isDigitalSigningWithFortnox: value(true),
        isManualSigning: value(false),
        isOtherDigitalSigning: value(false),
      },
      cashFlowStatementActive: value(false),
      annualGeneralMeetingActive: value(true),
      formatting: {
        displayInThousands: value(false),
        displayMultiYearOverviewInThousands: value(false),
        displayChangesInEquityInThousands: value(false),
        displayResultsDispositionInThousands: value(false),
        labelDisplayInThousands: value('tkr'),
        numberOfDecimalsForPercentagesInMultiYearOverview: value(2),
      },
    },
    warnings: {
      table: table(
        'settings.warnings.table',
        { id: 'label', label: '' },
        {
          id: 'difference',
          label: 'Skillnad',
        },
        { id: 'a', label: 'A' },
        { id: 'b', label: 'B' }
      )
        .addRows((rows) => {
          warnings(isDigitalSubmission, hasNoPreviousYear, isK3).forEach(
            (warning) => {
              rows.addRow(
                warning.id,
                value(warning.label),
                ref(
                  abs(
                    sum(
                      id(`${rows.getBaseId()}.${warning.id}.a`),
                      multiply(-1, id(`${rows.getBaseId()}.${warning.id}.b`))
                    )
                  )
                ),
                warning.type === 'calculation'
                  ? ref(warning.a)
                  : ref(id(warning.a)),
                ref(id(warning.b))
              );
            }
          );
          return rows.build();
        })
        .build(),
      submissionWarnings: table(
        'settings.warnings.submissionWarnings',
        'label',
        'result',
        'type'
      )
        .addRows((rows) => {
          getSubmissionWarnings(
            isFirstFinancialYear,
            reportTransitionK3toK2
          ).forEach((warning) =>
            rows.addRow(
              warning.id,
              value(warning.label),
              warning.result,
              value(warning.type)
            )
          );
          return rows.build();
        })
        .build(),
    },
    clientInformation: {
      activityText: field('[verksamhet]'),
      city: field('[ort]'),
    },
    isNedskrivningEgenPost: value(false),
  };
};

export const settingsReferences = (): Record<string, string> => ({});

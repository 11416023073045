import {
  endOfMonth,
  format as fnsFormat,
  formatISO,
  isAfter,
  isBefore,
  isEqual,
  parseISO as fnsParseISO,
  parse as fnsParse,
  startOfDay,
  startOfMonth,
} from 'date-fns';
import sv from 'date-fns/locale/sv';

const hasTimezone = /(GMT|[+-][0-9]{4})$/;
function isDateWithTimeZones(date: string) {
  return hasTimezone.test(date);
}

function isFormatMonthYear(format: string) {
  return (
    format === 'MMMyy' ||
    format === 'MMM-yy' ||
    format === 'MMMyyyy' ||
    format === 'MMM-yyyy'
  );
}

/**
 * Parses a date according to the specified format and returns a Date object.
 *
 * The behavior of the function is similar to moment.js in terms of how it handles time units not included in the format.
 *
 * @param date - The date to parse. Can be a string, a Date object, null, or undefined.
 *    If a string, it should be in ISO date format.
 *    If a Date object, a copy of the date object is returned
 *    If null, the function throws an error.
 *    If undefined or not provided, the function returns the current date and time.
 * @param format - The format of the date string. Defaults to 'yyyy-MM-dd hh:mm:ss'.
 * @returns The parsed date as a Date object.
 */
export const parse = (
  date: string | Date | null | undefined = new Date(),
  format = 'yyyy-MM-dd hh:mm:ss'
): Date => {
  if (typeof date === 'string') {
    let parsedDate: string | Date;
    if (isDateWithTimeZones(date)) {
      parsedDate = new Date(date);
    } else if (isFormatMonthYear(format)) {
      parsedDate = fnsParse(date, format, new Date(), { locale: sv });
    } else {
      parsedDate = fnsParseISO(date);
    }

    // Mimics same behavior as moment, if passed timeunit dd or hh is not passed all times after dd or hh should be set to 0
    if (!format.includes('dd')) {
      parsedDate = startOfMonth(parsedDate);
    } else if (!format.includes('hh')) {
      parsedDate = startOfDay(parsedDate);
    }

    return parsedDate;
  } else if (date instanceof Date) {
    return new Date(date);
  } else if (date === null) {
    return new Date('Invalid date');
  } else if (date === undefined) {
    // Mimics same behavior as moment
    return new Date();
  } else {
    throw new Error('Unexpected input type');
  }
};

const regexExactMatchOnMMM = /(^|[^M])MMM(?!M)/;

/**
 * Formats a date according to the specified format and returns a string. The formatting is locale-specific (Swedish)
 *
 * If the format includes 'MMM' but not 'MMMM', additional processing is applied to the formatted date
 * to make it match the formatting behavior of moment.js.
 *
 * @param date - The date to format.
 *    If a string, it should be in ISO date format.
 * @param dateFormat - The format to apply to the date. This should be a string following date-fns's formatting tokens.
 * @returns - The formatted date as a string.
 */
export const parseFormat = (
  date: string | Date | null | undefined | number,
  dateFormat: string
): string => {
  let formatInput;
  if (typeof date === 'number') {
    formatInput = date;
  } else {
    formatInput = parse(date);
  }

  return format(formatInput, dateFormat);
};

/**
 * Format MMM as moment as date-fns handles its differnt.
 * @param date that have been formated with MMM
 * */
const formatMMMLikeMoment = (date: string) =>
  date.replace(
    /(jan\.|feb\.|mars|apr\.|juni|juli|okt\.|aug\.|sep\.|nov\.|dec\.)/g,
    (matchMonth: string) => {
      switch (matchMonth) {
        case 'jan.':
          return 'jan';
        case 'feb.':
          return 'feb';
        case 'mars':
          return 'mar';
        case 'apr.':
          return 'apr';
        case 'juni':
          return 'jun';
        case 'juli':
          return 'jul';
        case 'okt.':
          return 'okt';
        case 'aug.':
          return 'aug';
        case 'sep.':
          return 'sep';
        case 'nov.':
          return 'nov';
        case 'dec.':
          return 'dec';
        default:
          return matchMonth;
      }
    }
  );

/**
 * Reformats a date from one format to another.
 *
 * The function first parses the input date using the provided `startFormat`, and then formats the resulting Date object to the `endFormat` using date-fns's `format` function. The formatting is locale-specific (Swedish).
 *
 * @param date - The date to reformat.
 * @param startFormat - The format of the input date. This should be a string following date-fns's formatting tokens.
 * @param endFormat - The desired format for the output date. This should also be a string following date-fns's formatting tokens.
 * @returns - The reformatted date as a string.
 */
export const reformat = (
  date: string | Date | null | undefined,
  startFormat: string,
  endFormat: string
): string => {
  return format(parse(date, startFormat), endFormat);
};

/**
 * Formats a date using the provided dateForamt. The formatting is locale-specific (Swedish).
 *
 * @param date - The date to format. It should be either a Date object or a number
 *    representing a timestamp (milliseconds since the UNIX epoch).
 * @param dateFormat - The desired format for the output date. It should be a string
 *    following date-fns's formatting tokens.
 * @returns - The formatted date as a string.
 */
export const format = (date: Date | number, dateFormat: string): string => {
  if (date.toString() === 'Invalid Date') {
    return 'Invalid date';
  }

  let formatedDate = fnsFormat(date, dateFormat, { locale: sv });

  // Only match on MMM and not MMMM
  if (regexExactMatchOnMMM.test(dateFormat)) {
    formatedDate = formatMMMLikeMoment(formatedDate);
  }
  return formatedDate;
};

export const isSameOrBefore = (date0: Date, date1: Date): boolean => {
  return isBefore(date0, date1) || isEqual(date0, date1);
};

export const isSameOrAfter = (date0: Date, date1: Date): boolean => {
  return isAfter(date0, date1) || isEqual(date0, date1);
};

export const formatCurrentTime = (
  dateFormat = 'yyyy-MM-dd hh:mm:ss'
): string => {
  return format(new Date(), dateFormat);
};

export const formatEndOfMonth = (
  date: string | Date,
  dateFormat = 'yyyy-MM-dd'
): string => {
  return format(endOfMonth(parse(date)), dateFormat);
};

export const formatStartOfMonth = (
  date: string | Date,
  dateFormat = 'yyyy-MM-dd'
): string => {
  return format(startOfMonth(parse(date)), dateFormat);
};

export const parseFinancialYear = (
  financialYear: string
): { start: Date; end: Date } => {
  const [start, end] = financialYear
    .split('-')
    .map((date) => parse(date, 'yyyyMMdd'));
  return { start, end };
};

/**
 * Returns the year end from a financialYear string in the specified format.
 * @param date needs to be a string in the format YYYYMMDD-YYYYMMDD
 * Ex: 20240301-20250331
 * */
export const getFinancialYearEnd = (date: string): string => {
  const parts = date.split('-');
  if (parts.length !== 2) {
    throw new Error('Invalid date format');
  }
  return parts[1].substring(0, 4);
};

export const formatISODate = (date: Date | number) =>
  formatISO(date, { representation: 'date' });

/**
 * Checks if the current date is before the end of the financial year.
 *
 * @param financialYear - The financial year in the format 'yyyymmdd-yyyymmdd'.
 * @returns True if the current date is before the end of the financial year, false otherwise.
 */
export const isNowBeforeEndOfFinancialYear = (
  financialYear: string
): boolean => {
  const { end } = parseFinancialYear(financialYear);
  const now = new Date();
  return isSameOrBefore(now, end);
};

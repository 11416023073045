import React from 'react';
import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';
import { Comment } from '_shared/types';

const CommentsListContent = styled.div`
  padding: ${({ theme }) => theme.spacing(1.5)}px;
  padding-top: 0;
`;

const CommentContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px dashed ${(props) => props.theme.palette.grey[300]};
  padding-top: ${({ theme }) => theme.spacing(1)}px;
  padding-bottom: ${({ theme }) => theme.spacing(1)}px;
  white-space: pre-wrap;

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
`;

const Title = styled(Typography)`
  background-color: ${({ theme }) => theme.palette.grey[300]};
  color: ${({ theme }) => theme.palette.grey[600]};
  padding: ${({ theme }) => `${theme.spacing(0.5)}px ${theme.spacing(1.5)}px`};
  text-transform: uppercase;
  margin-top: ${({ theme }) => theme.spacing(0.5)}px;
`;

const CommentBody = styled(Typography)`
  &.preview {
    color: ${({ theme }) => theme.palette.grey[500]};
    font-style: italic;
  }
`;

const AdditionalInfo = styled(Typography)`
  padding: ${({ theme }) => theme.spacing(1.5)}px;
  padding-top: 0;
  color: ${({ theme }) => theme.palette.grey[500]};
`;

export type CommentsSubsection = {
  title: string;
  comments: Comment[];
  additionalInfo?: string;
};

interface Props {
  subsection: CommentsSubsection;
  preview?: boolean;
}

/**
 * Renders list of comments with subsection title and additional info
 *
 * @param subsection subsection of comments, includes title, comments and additional info
 * @param preview flag to render subsection as preview, styling only, default = false (optional)
 * @returns A list of comments or message about absent comments
 */
const CommentsSubsectionList = React.forwardRef(
  (
    { subsection: { title, comments, additionalInfo }, preview = false }: Props,
    ref: React.ForwardedRef<HTMLDivElement>
  ) => (
    <div ref={ref}>
      <Title variant="subtitle2">{title}</Title>
      <CommentsListContent>
        {comments.map((comment) => (
          <CommentContainer key={comment.id}>
            <CommentBody variant="body2" className={preview ? 'preview' : ''}>
              {comment.comment}
            </CommentBody>
          </CommentContainer>
        ))}
      </CommentsListContent>
      {additionalInfo && (
        <AdditionalInfo variant="body2">{additionalInfo}</AdditionalInfo>
      )}
    </div>
  )
);

export default CommentsSubsectionList;

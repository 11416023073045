import React, { useCallback, useContext } from 'react';
import { List } from '@material-ui/core';
import styled from '@emotion/styled';
import MenuItem from './UI/MenuItem';
import FinancialReportViewServiceContext from '../../service/FinancialReportViewServiceContext';
import { mapPartToModel } from '../../utils';

interface Section {
  part: string;
  title: string;
  isActivePart: boolean;
}
interface MinimapMenuProps {
  sections: Section[];
  currentPart: string;
  handleMenuClick: (part) => void;
}

const Wrapper = styled(List)`
  padding: 0;

  & > *:not(:last-child):before {
    content: '';
    border-right: 2px solid ${(props) => props.theme.palette.grey[300]};
    height: 100%;
    position: absolute;
    left: 27px;
    top: 26px;
    z-index: -1;
  }
`;

const MinimapMenu = ({
  sections,
  currentPart,
  handleMenuClick,
}: MinimapMenuProps): JSX.Element => {
  const service = useContext(FinancialReportViewServiceContext);

  const handleCheckbox = useCallback(
    (part) => {
      switch (part) {
        case 'income-statement': {
          service.toggleIncomeStatementSectionActive('table');
          break;
        }
        case 'income-statement-part1': {
          service.toggleIncomeStatementSectionActive('table');
          break;
        }

        case 'income-statement-part2': {
          service.toggleIncomeStatementSectionActive('secondTable');
          break;
        }

        case 'income-statement-period-part1': {
          service.toggleIncomeStatementSectionActive('periodTable');
          break;
        }

        case 'income-statement-period-part2': {
          service.toggleIncomeStatementSectionActive('secondPeriodTable');
          break;
        }

        case 'balance-sheet': {
          service.toggleBalanceSheetSectionActive('equityAndLiabilities');
          break;
        }
        case 'balance-sheet-assets-part1': {
          service.toggleBalanceSheetAssetsSectionActive('active');
          break;
        }
        case 'balance-sheet-assets-part2': {
          service.toggleBalanceSheetAssetsSectionActive('activeSecondTable');
          break;
        }

        case 'additionalTextOne': {
          service.toggleFieldActive('additionalText.section.text1Active');
          break;
        }
        case 'additionalTextTwo': {
          service.toggleFieldActive('additionalText.section.text2Active');
          break;
        }

        default: {
          service.toggleSectionActive(mapPartToModel(part));
        }
      }
    },
    [service]
  );

  return (
    <>
      <Wrapper>
        {sections &&
          sections.map(({ part, title: sectionTitle, isActivePart }) => (
            <MenuItem
              key={part}
              part={part}
              title={sectionTitle}
              active={part === currentPart}
              isFrontPageRow={part === 'frontpage'}
              isSettingsRow={part === 'create' || part === 'settings'}
              isActivePart={isActivePart}
              onClick={() => {
                handleMenuClick(part);
              }}
              onCheckboxChange={handleCheckbox}
            />
          ))}
      </Wrapper>
    </>
  );
};

export default MinimapMenu;

import { Dispatch } from 'redux';
import { decode } from 'jsonwebtoken';

import { ActivityLog } from '@agoy/activity-log';

import { snakeToCamel } from 'utils/camelSnake';
import {
  insertUserAttributes,
  setUserLoggedIn,
  setUserLoggedOut,
  userIsAuthenticating,
  userNotAuthenticating,
} from '_users/redux/actions';
import sendActivityEvent from 'Api/Activity/authorizationActivityEventHandler';

import { ContextFunctionsType } from './types';

/**
 * Factory for managing the authentication using our own JWT token (generated on the BE).
 * The Fortnox users use the JWT token (provided during redirect handshake).
 */
const jwtAuth = (
  dispatch: Dispatch<any>,
  setStateUser: (user: any) => void,
  setStateIsAuthenticated: (value: boolean) => void,
  setStateIsLoading: (value: boolean) => void
): ContextFunctionsType => {
  // SETTERs for both state and redux actions
  const setUser = (user) => {
    setStateUser(user);
    dispatch(insertUserAttributes(user));
  };

  const setIsAuthenticated = (value: boolean) => {
    setStateIsAuthenticated(value);
    if (value) {
      dispatch(setUserLoggedIn());
    } else {
      dispatch(setUserLoggedOut());
    }
  };

  const setIsLoading = (value: boolean) => {
    setStateIsLoading(value);
    if (value) {
      dispatch(userIsAuthenticating());
    } else {
      dispatch(userNotAuthenticating());
    }
  };

  const withLoading = (fn) => async (args?) => {
    try {
      setIsLoading(true);
      await fn(args);
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Handle user formatting, setting authentication and user values.
   */
  const handleUser = (user: Record<string, any> | null) => {
    if (user) {
      // Convert all cognito snake case user attributes to camel case
      const userCamelCaseAttributes: any = Object.keys(user).reduce(
        (acc, curr) => ({
          ...acc,
          [snakeToCamel(curr)]: user[curr],
        }),
        {}
      );

      userCamelCaseAttributes.personNr =
        userCamelCaseAttributes['custom:personNr'];
      delete userCamelCaseAttributes['custom:personNr '];

      // requited by `web/src/_user/redux/reducer.ts`, please dont remove
      userCamelCaseAttributes.organisationId =
        userCamelCaseAttributes['custom:organisationId'];
      delete userCamelCaseAttributes['custom:organisationId'];

      setUser(userCamelCaseAttributes);
      setIsAuthenticated(true);
    } else {
      setUser(null);
      setIsAuthenticated(false);
    }
  };

  const getCurrentSessionAWS = withLoading(async () => {
    try {
      const token = await window.localStorage.getItem('agoyJwt');
      if (token) {
        const payload = decode(token);
        if (payload && typeof payload === 'object') {
          const roles = payload.data['custom:roles'].split(' ');
          delete payload.data['custom:roles'];

          handleUser({ ...payload.data, roles });
        }
      } else {
        handleUser(null);
      }
    } catch (err) {
      console.error(err);
    }
  });

  const logInAWS = withLoading(async () => {
    throw new Error('Not supported');
  });

  const logOutAWS = withLoading(async () => {
    await sendActivityEvent(
      ActivityLog.createActivityLogEvent({
        program: 'SYSTEM',
        section: 'GENERAL',
        resource: 'USER',
        operation: 'LOGOUT',
        arguments: [],
      })
    );
    window.sessionStorage.removeItem('token');
    handleUser(null);
  });

  const signUpAWS = withLoading(async () => {
    throw new Error('Not supported');
  });

  const confirmAccountAWS = withLoading(async () => {
    throw new Error('Not supported');
  });

  const resendConfirmationAWS = withLoading(async () => {
    throw new Error('Not supported');
  });

  const forgotPasswordAWS = withLoading(async () => {
    throw new Error('Not supported');
  });

  const changePasswordAWS = withLoading(async () => {
    throw new Error('Not supported');
  });

  const confirmNewPasswordAWS = withLoading(async () => {
    throw new Error('Not supported');
  });

  return {
    getCurrentSessionAWS,
    logInAWS,
    logOutAWS,
    signUpAWS,
    confirmAccountAWS,
    resendConfirmationAWS,
    forgotPasswordAWS,
    changePasswordAWS,
    confirmNewPasswordAWS,
    refreshSessionAWS: getCurrentSessionAWS,
  };
};

export default jwtAuth;

export const SET_TAX_VIEW_CONFIG = 'tax-view/SET_TAX_VIEW_CONFIG';
export const SET_TAX_VIEW_STATE = 'tax-view/SET_TAX_VIEW_STATE';
export const ADD_EMPTY_TAX_TABLE_ROW = 'tax-view/ADD_EMPTY_TAX_TABLE_ROW';
export const ADD_CONTRACTUAL_PENSION_TABLE_ROW =
  'tax-view/ADD_CONTRACTUAL_PENSION_TABLE_ROW';
export const ADD_EMPTY_TAX_SUMMARY_TABLE_ROW =
  'tax-view/ADD_EMPTY_TAX_SUMMARY_TABLE_ROW';
export const DELETE_TAX_TABLE_ROW = 'tax-view/DELETE_TAX_TABLE_ROW';
export const DELETE_CONTRACTUAL_PENSION_TABLE_ROW =
  'tax-view/DELETE_CONTRACTUAL_PENSION_TABLE_ROW';
export const UPDATE_TAX_TABLE_ROW_LABEL = 'tax-view/UPDATE_TAX_TABLE_ROW_LABEL';
export const UPDATE_CONTRACTUAL_PENSION_TABLE_ROW_LABEL =
  'tax-view/UPDATE_CONTRACTUAL_PENSION_TABLE_ROW_LABEL';
export const UPDATE_CONTRACTUAL_PENSION_TABLE_ROW_VALUE =
  'tax-view/UPDATE_CONTRACTUAL_PENSION_TABLE_ROW_VALUE';
export const UPDATE_TAX_TABLE_ROW_VALUE = 'tax-view/UPDATE_TAX_TABLE_ROW_VALUE';
export const UPDATE_TAX_SUMMARY_TABLE_ROW_VALUE =
  'tax-view/UPDATE_TAX_SUMMARY_TABLE_ROW_VALUE';
export const UPDATE_TAX_TABLE_ROW_REFERENCE =
  'tax-view/UPDATE_TAX_TABLE_ROW_REFERENCE';
export const UPDATE_CONTRACTUAL_PENSION_TABLE_ROW_REFERENCE =
  'tax-view/UPDATE_CONTRACTUAL_PENSION_TABLE_ROW_REFERENCE';
export const UPDATE_TAX_VIEW_TABLE_ROW_REFERENCE =
  'tax-view/UPDATE_TAX_VIEW_TABLE_ROW_REFERENCE';
export const UPDATE_TRANSACTION_ROW_ACCOUNT =
  'tax-view/UPDATE_TRANSACTION_ROW_ACCOUNT';
export const REMOVE_USER_TRANSACTIONS = 'tax-view/REMOVE_USER_TRANSACTIONS';
export const RESET_YEAR_END_PLANNING = 'tax-view/RESET_YEAR_END_PLANNING';
export const RESET_NONE_TAXABLE_INCOME = 'tax-view/RESET_NONE_TAXABLE_INCOME';
export const RESET_NONE_DEDUCTIBLE_EXPENSES =
  'tax-view/RESET_NONE_DEDUCTIBLE_EXPENSES';
export const RESET_TAX_CALCULATION_TABLE =
  'tax-view/RESET_TAX_CALCULATION_TABLE';
export const RESET_TAX_CALCULATION_ADJUSTMENTS =
  'tax-view/RESET_TAX_CALCULATION_ADJUSTMENTS';
export const RESET_TAX_PARTICULAR_SALARY =
  'tax-view/RESET_TAX_PARTICULAR_SALARY';
export const RESET_TAX_FORA = 'tax-view/RESET_TAX_FORA';
export const RESET_DOCUMENT_PART = 'tax-view/RESET_DOCUMENT_PART';
export const UPDATE_YEAR_END_PLANNING_CHECKSUM =
  'tax-view/UPDATE_YEAR_END_PLANNING_CHECKSUM';
export const SET_TAX_DOCUMENTS = 'tax-view/SET_TAX_DOCUMENTS';
export const RESET_TAX_DOCUMENTS = 'tax-view/RESET_TAX_DOCUMENTS';
export const SET_TAX_EXTERNAL_DOCUMENT_VALUES =
  'tax-view/SET_TAX_EXTERNAL_DOCUMENT_VALUES';
export const UPDATE_FORA_FIELD = 'tax-view/UPDATE_FORA_FIELD';
export const UPDATE_TAX_VIEW_FIELD = 'tax-view/UPDATE_TAX_VIEW_FIELD';
export const UPDATE_DOCUMENT_CELL = 'tax-view/UPDATE_DOCUMENT_CELL';
export const ADD_DOCUMENT_ROW = 'tax-view/ADD_DOCUMENT_ROW';
export const DELETE_DOCUMENT_ROW = 'tax-view/DELETE_DOCUMENT_ROW';
export const TOGGLE_TAX_SECTION_ACTIVE = 'tax-view/TOGGLE_TAX_SECTION_ACTIVE';
export const ADD_TAX_SECTIONS = 'tax-view/ADD_TAX_SECTIONS';

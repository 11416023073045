import React from 'react';
import { Button } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';
import styled from '@emotion/styled';
import LoadingLogo from '_shared/components/LoadingLogo';

const ButtonWrapper = styled.div`
  position: relative;
  width: max-content;
`;

const ProgressInButtonWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

interface CTAButtonProps extends ButtonProps {
  loading?: boolean;
  lockButton?: boolean;
}

const CTAButton = ({
  children,
  loading,
  color = 'primary',
  lockButton = false,
  ...rest
}: CTAButtonProps) => (
  <ButtonWrapper>
    <Button
      style={{ borderRadius: lockButton ? '0px 4px 4px 0px' : '4px' }}
      variant="contained"
      {...rest}
      disableElevation
      color={color}
      disabled={loading || rest.disabled}
    >
      {children}
    </Button>
    {loading && (
      <ProgressInButtonWrapper>
        <LoadingLogo size="small" />
      </ProgressInButtonWrapper>
    )}
  </ButtonWrapper>
);

export default CTAButton;

import { SkatteverketTransaction } from '_clients/redux/customer-view/types';

const filterTransactions = (
  transactions: SkatteverketTransaction[],
  accountNumber: number
) => {
  const regexp = {
    1650: /((^Moms.*)|(^Beslut.*moms.*))/,
    2510: /((^Debiterad preliminärskatt.*)|(^Tillgodoförd debiterad preliminärskatt.*)|(^Slutlig skatt.*)|(^Beslut.*debiterad preliminärskatt.*))/,
    2650: /((^Moms.*)|(^Beslut.*moms.*))/,
    2710: /((^Avdragen skatt.*)|(^Beslut.*avdragen skatt.*))/,
    2730: /((^Arbetsgivaravgift.*)|(^Beslut.*arbetsgivaravgift.*))/,
  };

  switch (true) {
    case accountNumber === 1650:
      return transactions.filter(
        (t) => regexp[1650].test(t.specification) && t.amount > 0
      );
    case accountNumber >= 2510 && accountNumber <= 2520:
      return transactions.filter((t) => regexp[2510].test(t.specification));
    case accountNumber === 2650:
      return transactions.filter(
        (t) => regexp[1650].test(t.specification) && t.amount < 0
      );
    case accountNumber >= 2710 && accountNumber <= 2720:
      return transactions.filter((t) => regexp[2710].test(t.specification));
    case accountNumber >= 2730 && accountNumber <= 2740:
      return transactions.filter((t) => regexp[2730].test(t.specification));
    default:
      return transactions;
  }
};

export default filterTransactions;

import { ReferenceAccountInformation, label, value } from '@agoy/document';
import { active, field } from '../../../../common/utils/util';
import { GeneratedSharesV2K3Notes } from './types';
import { getSharesCompanyCustomNote } from '../../shared/customNotes';
import { getNote2001K3v2 } from './notes/note2001k3v2';
import { getNote2002K3v2 } from './notes/note2002k3v2';
import { getNote2003K3v2 } from './notes/note2003k3v2';
import { getNote2004K3v2 } from './notes/note2004k3v2';
import { getNote2005K3v2 } from './notes/note2005k3v2';
import { getNote2006K3v2 } from './notes/note2006k3v2';
import { getNote2007K3v2 } from './notes/note2007k3v2';
import { getNote2008K3v2 } from './notes/note2008k3v2';
import { getNote2009K3v2 } from './notes/note2009k3v2';
import { getNote2010K3v2 } from './notes/note2010k3v2';
import { getNote2011K3v2 } from './notes/note2011k3v2';
import { getNote2012K3v2 } from './notes/note2012k3v2';
import { getNote2013K3v2 } from './notes/note2013k3v2';
import { getNote2014K3v2 } from './notes/note2014k3v2';
import { getNote2015K3v2 } from './notes/note2015k3v2';
import { getNote2016K3v2 } from './notes/note2016k3v2';
import { getNote2017K3v2 } from './notes/note2017k3v2';
import { getNote2018K3v2 } from './notes/note2018k3v2';
import { getNote2019K3v2 } from './notes/note2019k3v2';
import { getNote2020K3v2 } from './notes/note2020k3v2';
import { getNote2021K3v2 } from './notes/note2021k3v2';
import { getNote2022K3v2 } from './notes/note2022k3v2';
import { getNote2023K3v2 } from './notes/note2023k3v2';
import { getNote2024K3v2 } from './notes/note2024k3v2';
import { getNote2025K3v2 } from './notes/note2025k3v2';
import { getNote2026K3v2 } from './notes/note2026k3v2';
import { getNote2027K3v2 } from './notes/note2027k3v2';
import { getNote2028K3v2 } from './notes/note2028k3v2';
import { getNote2029K3v2 } from './notes/note2029k3v2';
import { getNote2030K3v2 } from './notes/note2030k3v2';
import { getNote2031K3v2 } from './notes/note2031k3v2';
import { getNote2032K3v2 } from './notes/note2032k3v2';
import { getNote2033K3v2 } from './notes/note2033k3v2';
import { getNote2034K3v2 } from './notes/note2034k3v2';
import { getNote2035K3v2 } from './notes/note2035k3v2';
import { getNote2036K3v2 } from './notes/note2036k3v2';
import { getNote2037K3v2 } from './notes/note2037k3v2';
import { getNote2038K3v2 } from './notes/note2038k3v2';
import { getNote2039K3v2 } from './notes/note2039k3v2';
import { getNote2040K3v2 } from './notes/note2040k3v2';
import { getNote2041K3v2 } from './notes/note2041k3v2';
import { getNote2042K3v2 } from './notes/note2042k3v2';
import { getNote2043K3v2 } from './notes/note2043k3v2';
import { getNote2044K3v2 } from './notes/note2044k3v2';
import { getNote2045K3v2 } from './notes/note2045k3v2';
import { getNote2046K3v2 } from './notes/note2046k3v2';
import { getNote2047K3v2 } from './notes/note2047k3v2';
import { getNote2048K3v2 } from './notes/note2048k3v2';
import { getNote2049K3v2 } from './notes/note2049k3v2';
import { getNote2050K3v2 } from './notes/note2050k3v2';
import { getNote2051K3v2 } from './notes/note2051k3v2';
import { getNote2052K3v2 } from './notes/note2052k3v2';
import { getNote2053K3v2 } from './notes/note2053k3v2';
import { getNote2054K3v2 } from './notes/note2054k3v2';
import { getNote2055K3v2 } from './notes/note2055k3v2';
import { getNote2056K3v2 } from './notes/note2056k3v2';
import { getNote2057K3v2 } from './notes/note2057k3v2';
import { getNote2058K3v2 } from './notes/note2058k3v2';
import { getNote2059K3v2 } from './notes/note2059k3v2';
import { getNote2060K3v2 } from './notes/note2060k3v2';
import { getNote2061K3v2 } from './notes/note2061k3v2';
import { getNote2062K3v2 } from './notes/note2062k3v2';
import { getNote2063K3v2 } from './notes/note2063k3v2';
import { getNote2064K3v2 } from './notes/note2064k3v2';
import { getNote2065K3v2 } from './notes/note2065k3v2';
import { getNote2066K3v2 } from './notes/note2066k3v2';
import { getNote2067K3v2 } from './notes/note2067k3v2';
import { getNote2068K3v2 } from './notes/note2068k3v2';
import { getNote2069K3v2 } from './notes/note2069k3v2';
import { getNote2070K3v2 } from './notes/note2070k3v2';
import { getNote2071K3v2 } from './notes/note2071k3v2';
import { getNote2072K3v2 } from './notes/note2072k3v2';
import { getNote2073K3v2 } from './notes/note2073k3v2';
import { getNote2074K3v2 } from './notes/note2074k3v2';
import { getNote2075K3v2 } from './notes/note2075k3v2';
import { getNote2076K3v2 } from './notes/note2076k3v2';
import { getNote2077K3v2 } from './notes/note2077k3v2';
import { getNote2078K3v2 } from './notes/note2078k3v2';
import { getNote2079K3v2 } from './notes/note2079k3v2';
import { getNote2080K3v2 } from './notes/note2080k3v2';
import { getNote2081K3v2 } from './notes/note2081k3v2';
import { getNote2082K3v2 } from './notes/note2082k3v2';
import { getNote2083K3v2 } from './notes/note2083k3v2';
import { getNote2084K3v2 } from './notes/note2084k3v2';
import { getNote2085K3v2 } from './notes/note2085k3v2';
import { getNote2086K3v2 } from './notes/note2086k3v2';
import { getNote2087K3v2 } from './notes/note2087k3v2';
import { getNote2088K3v2 } from './notes/note2088k3v2';
import { getNote2089K3v2 } from './notes/note2089k3v2';
import { getNote2090K3v2 } from './notes/note2090k3v2';
import { getNote2091K3v2 } from './notes/note2091k3v2';
import { getNote2092K3v2 } from './notes/note2092k3v2';
import { getNote2093K3v2 } from './notes/note2093k3v2';
import { getNote2094K3v2 } from './notes/note2094k3v2';
import { getNote2095K3v2 } from './notes/note2095k3v2';
import { getNote2096K3v2 } from './notes/note2096k3v2';
import { getNote2097K3v2 } from './notes/note2097k3v2';
import { getNote2098K3v2 } from './notes/note2098k3v2';
import { getNote2099K3v2 } from './notes/note2099k3v2';
import { getNote2100K3v2 } from './notes/note2100k3v2';
import { getNote2101K3v2 } from './notes/note2101k3v2';

export const notesConfigV2K3Config = (
  accounts: Record<string, ReferenceAccountInformation>,
  financialYears: string[],
  financialYear: string,
  period: string | null,
  isDigitalSubmission: boolean
): GeneratedSharesV2K3Notes => {
  const noteLabel = (name: string) =>
    isDigitalSubmission ? label(name) : value(name);

  return {
    active: undefined,
    headings: {
      active: undefined,
      number: value(),
      name: value(),
      notesIncomeStatementTitle: active(field('Noter till resultaträkning')),
      notesBalanceSheetTitle: active(field('Noter till balansräkning')),
      notesOtherTitle: active(field('Övriga noter')),
      group: value(),
      section: value(),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note1: getNote2001K3v2(noteLabel, financialYear, financialYears, period),
    note2: getNote2002K3v2(noteLabel, financialYear, financialYears, period),
    note3: getNote2003K3v2(noteLabel, financialYear, financialYears, period),
    note4: getNote2004K3v2(noteLabel, financialYear, financialYears, period),
    note5: getNote2005K3v2(noteLabel, financialYear, financialYears, period),
    note6: getNote2006K3v2(noteLabel, financialYear, financialYears, period),
    note7: getNote2007K3v2(noteLabel, financialYear, financialYears, period),
    note8: getNote2008K3v2(noteLabel, financialYear, financialYears, period),
    note9: getNote2009K3v2(noteLabel, financialYear, financialYears, period),
    note10: getNote2010K3v2(noteLabel, financialYear, financialYears, period),
    note11: getNote2011K3v2(noteLabel, financialYear, financialYears, period),
    note12: getNote2012K3v2(noteLabel, financialYear, financialYears, period),
    note13: getNote2013K3v2(noteLabel, financialYear, financialYears, period),
    note14: getNote2014K3v2(noteLabel, financialYear, financialYears, period),
    note15: getNote2015K3v2(noteLabel, financialYear, financialYears, period),
    note16: getNote2016K3v2(noteLabel, financialYear, financialYears, period),
    note17: getNote2017K3v2(noteLabel, financialYear, financialYears, period),
    note18: getNote2018K3v2(noteLabel, financialYear, financialYears, period),
    note19: getNote2019K3v2(noteLabel, financialYear, financialYears, period),
    note20: getNote2020K3v2(noteLabel, financialYear, financialYears, period),
    note21: getNote2021K3v2(noteLabel, financialYear, financialYears, period),
    note22: getNote2022K3v2(noteLabel, financialYear, financialYears, period),
    note23: getNote2023K3v2(noteLabel, financialYear, financialYears, period),
    note24: getNote2024K3v2(noteLabel, financialYear, financialYears, period),
    note25: getNote2025K3v2(noteLabel, financialYear, financialYears, period),
    note26: getNote2026K3v2(noteLabel, financialYear, financialYears, period),
    note27: getNote2027K3v2(noteLabel, financialYear, financialYears, period),
    note28: getNote2028K3v2(noteLabel, financialYear, financialYears, period),
    note29: getNote2029K3v2(noteLabel, financialYear, financialYears, period),
    note30: getNote2030K3v2(noteLabel, financialYear, financialYears, period),
    note31: getNote2031K3v2(noteLabel, financialYear, financialYears, period),
    note32: getNote2032K3v2(noteLabel, financialYear, financialYears, period),
    note33: getNote2033K3v2(noteLabel, financialYear, financialYears, period),
    note34: getNote2034K3v2(noteLabel, financialYear, financialYears, period),
    note35: getNote2035K3v2(noteLabel, financialYear, financialYears, period),
    note36: getNote2036K3v2(noteLabel, financialYear, financialYears, period),
    note37: getNote2037K3v2(noteLabel, financialYear, financialYears, period),
    note38: getNote2038K3v2(noteLabel, financialYear, financialYears, period),
    note39: getNote2039K3v2(noteLabel, financialYear, financialYears, period),
    note40: getNote2040K3v2(noteLabel, financialYear, financialYears, period),
    note41: getNote2041K3v2(noteLabel, financialYear, financialYears, period),
    note42: getNote2042K3v2(noteLabel, financialYear, financialYears, period),
    note43: getNote2043K3v2(noteLabel, financialYear, financialYears, period),
    note44: getNote2044K3v2(noteLabel, financialYear, financialYears, period),
    note45: getNote2045K3v2(noteLabel, financialYear, financialYears, period),
    note46: getNote2046K3v2(noteLabel, financialYear, financialYears, period),
    note47: getNote2047K3v2(noteLabel, financialYear, financialYears, period),
    note48: getNote2048K3v2(noteLabel, financialYear, financialYears, period),
    note49: getNote2049K3v2(noteLabel, financialYear, financialYears, period),
    note50: getNote2050K3v2(noteLabel, financialYear, financialYears, period),
    note51: getNote2051K3v2(noteLabel, financialYear, financialYears, period),
    note52: getNote2052K3v2(noteLabel, financialYear, financialYears, period),
    note53: getNote2053K3v2(noteLabel, financialYear, financialYears, period),
    note54: getNote2054K3v2(noteLabel, financialYear, financialYears, period),
    note55: getNote2055K3v2(noteLabel, financialYear, financialYears, period),
    note56: getNote2056K3v2(noteLabel, financialYear, financialYears, period),
    note57: getNote2057K3v2(noteLabel, financialYear, financialYears, period),
    note58: getNote2058K3v2(noteLabel, financialYear, financialYears, period),
    note59: getNote2059K3v2(noteLabel, financialYear, financialYears, period),
    note60: getNote2060K3v2(noteLabel, financialYear, financialYears, period),
    note61: getNote2061K3v2(noteLabel, financialYear, financialYears, period),
    note62: getNote2062K3v2(noteLabel, financialYear, financialYears, period),
    note63: getNote2063K3v2(noteLabel, financialYear, financialYears, period),
    note64: getNote2064K3v2(noteLabel, financialYear, financialYears, period),
    note65: getNote2065K3v2(noteLabel, financialYear, financialYears, period),
    note66: getNote2066K3v2(noteLabel, financialYear, financialYears, period),
    note67: getNote2067K3v2(noteLabel, financialYear, financialYears, period),
    note68: getNote2068K3v2(noteLabel, financialYear, financialYears, period),
    note69: getNote2069K3v2(noteLabel, financialYear, financialYears, period),
    note70: getNote2070K3v2(noteLabel, financialYear, financialYears, period),
    note71: getNote2071K3v2(noteLabel, financialYear, financialYears, period),
    note72: getNote2072K3v2(noteLabel, financialYear, financialYears, period),
    note73: getNote2073K3v2(noteLabel, financialYear, financialYears, period),
    note74: getNote2074K3v2(noteLabel, financialYear, financialYears, period),
    note75: getNote2075K3v2(noteLabel, financialYear, financialYears, period),
    note76: getNote2076K3v2(noteLabel, financialYear, financialYears, period),
    note77: getNote2077K3v2(noteLabel, financialYear, financialYears, period),
    note78: getNote2078K3v2(noteLabel, financialYear, financialYears, period),
    note79: getNote2079K3v2(noteLabel, financialYear, financialYears, period),
    note80: getNote2080K3v2(noteLabel, financialYear, financialYears, period),
    note81: getNote2081K3v2(noteLabel, financialYear, financialYears, period),
    note82: getNote2082K3v2(noteLabel, financialYear, financialYears, period),
    note83: getNote2083K3v2(noteLabel, financialYear, financialYears, period),
    note84: getNote2084K3v2(noteLabel, financialYear, financialYears, period),
    note85: getNote2085K3v2(noteLabel, financialYear, financialYears, period),
    note86: getNote2086K3v2(noteLabel, financialYear, financialYears, period),
    note87: getNote2087K3v2(noteLabel, financialYear, financialYears, period),
    note88: getNote2088K3v2(noteLabel, financialYear, financialYears, period),
    note89: getNote2089K3v2(noteLabel, financialYear, financialYears, period),
    note90: getNote2090K3v2(noteLabel, financialYear, financialYears, period),
    note91: getNote2091K3v2(noteLabel, financialYear, financialYears, period),
    note92: getNote2092K3v2(noteLabel, financialYear, financialYears, period),
    note93: getNote2093K3v2(noteLabel, financialYear, financialYears, period),
    note94: getNote2094K3v2(noteLabel, financialYear, financialYears, period),
    note95: getNote2095K3v2(noteLabel, financialYear, financialYears, period),
    note96: getNote2096K3v2(noteLabel, financialYear, financialYears, period),
    note97: getNote2097K3v2(noteLabel, financialYear, financialYears, period),
    note98: getNote2098K3v2(noteLabel, financialYear, financialYears, period),
    note99: getNote2099K3v2(noteLabel, financialYear, financialYears, period),
    note100: getNote2100K3v2(noteLabel, financialYear, financialYears, period),
    note101: getNote2101K3v2(noteLabel, financialYear, financialYears, period),
    custom: getSharesCompanyCustomNote(financialYear, financialYears, period),
  };
};

import { AgoyTableRow, Cell, CellUpdate, UpdateRowChange } from '../../..';
import updateRow, { TableUpdater } from './updateRow';

const createCellUpdate = (cell: Cell): CellUpdate | undefined => {
  switch (cell.type) {
    case 'ref':
      return { type: 'ref', reference: cell.reference };
    case 'refs':
      return { type: 'refs', references: cell.references };
    case 'string':
      return { type: 'string', value: cell.value };
    case 'number':
      return { type: 'number', value: cell.value };
    case 'boolean':
      return { type: 'boolean', value: cell.value };
    default:
      return undefined;
  }
};

const addOriginal = (
  cell: CellUpdate | undefined,
  original: Cell | undefined
): CellUpdate | undefined => {
  if (!cell || !original) {
    return cell;
  }
  return {
    ...cell,
    original,
  };
};

const updateCell = (
  rowIds: string[],
  columnId: string,
  updater: (cell: Cell | undefined) => Cell,
  keepOriginal = false
): TableUpdater => {
  return updateRow(
    rowIds,
    (row: AgoyTableRow, change): [AgoyTableRow, UpdateRowChange] => {
      const cell = row.cells?.[columnId];

      const original = cell?.original ?? cell;
      const newCell =
        keepOriginal && original
          ? { ...updater(cell), original }
          : updater(cell);
      if (newCell !== cell) {
        const cellUpdate = addOriginal(
          createCellUpdate(newCell),
          keepOriginal ? original : undefined
        );
        if (cellUpdate) {
          const newRow = {
            ...row,
            cells: {
              ...row.cells,
              [columnId]: newCell,
            },
          };

          return [
            newRow,
            {
              ...change,
              row: {
                ...change.row,
                cells: {
                  ...change.row?.cells,
                  [columnId]: cellUpdate,
                },
              },
            },
          ];
        }
      }
      return [row, { type: 'update', id: row.id }];
    }
  );
};

export default updateCell;

/* eslint-disable react-hooks/exhaustive-deps */
import { ApiSdk, useApiSdk } from 'api-sdk';
import { ApiErrorType, isApiErrorType } from 'api-sdk/api-errors';
import React, { useCallback, useEffect, useState } from 'react';
import { AsyncHookReturn, useAsync } from 'utils/hooks';

/**
 * Return type of the useAPICall hook.
 */
type UseAPICallType<T, U extends any[]> = Omit<
  AsyncHookReturn<T | undefined>,
  'run'
> & { run: (...args: U) => Promise<T | undefined> };

/**
 * Hook to wrap API calls with.
 *
 * It provides an instance of the ApiSdk to the callback. The callback can
 * have any number of arguments after the sdk parameter. These other parameters
 * are then exposed in the returned run-function.
 *
 * Any error of the ApiErrorType will be passed to the onError function.
 * If the onError function throws, the error will be passed to React for
 * further handling in an ErrorBoundary.
 *
 * If the error is some other kind of error, it will be thrown into the
 * React chain for further handling in an ErrorBoundary.
 *
 * @param call Function that executes the API call
 * @param onError Function to handle the error or throw error to the ErrorBoundary
 * @param deps Dependencies of the `call` and `onError` functions
 * @returns Object with status properties, data and run-function.
 *      The object is not memoized, but the properties are so the run-function
 *      can be used as a dependency to other hooks.
 */
const useAPICall = <T, U extends any[]>(
  call: (sdk: ApiSdk, ...params: U) => Promise<T>,
  onError: (err: ApiErrorType) => Promise<void>,
  deps: React.DependencyList
): UseAPICallType<T, U> => {
  const sdk = useApiSdk();
  const obj = useAsync<T | undefined>(undefined);

  // Use callbacks for the functions with the given dependency list
  const apiCall = useCallback(call, deps);
  const errorHandler = useCallback(onError, deps);

  // Throwing an error in a React state setter will
  // propagate the error throw the React chain.
  const [, setError] = useState();

  // Reset the async object if the dependencies change.
  useEffect(() => {
    return () => {
      obj.reset();
    };
  }, deps);

  const asyncRun = obj.run;

  // The execution logic with error handling
  const run: (...args: U) => Promise<T | undefined> = useCallback(
    async (...args: U): Promise<T | undefined> => {
      return asyncRun(apiCall(sdk, ...args)).catch(
        async (err): Promise<undefined> => {
          try {
            if (isApiErrorType(err)) {
              if (!err.handled) {
                await errorHandler(err);
              }
            } else {
              setError(() => {
                // Unknown error, throw it into React.
                throw err;
              });
            }
          } catch (error) {
            setError(() => {
              // Throw the error into React
              throw error;
            });
          }
          return undefined;
        }
      );
    },
    [asyncRun, sdk, apiCall, errorHandler]
  );

  return { ...obj, run };
};

export default useAPICall;

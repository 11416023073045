import { value, ref } from '@agoy/document';
import { NoteOneTableSection, IxbrlCell } from '../../../../common';
import {
  sumAccountsIBs,
  sumPositiveTransactions,
  sumNegativeTransactions,
  sumNotesRowsById,
} from '../../../../common/utils/accountSumsUtil';
import { year, previousYear } from '../../../../common/utils/date-util';
import {
  field,
  ixbrlCell,
  ixbrlField,
  range,
  table,
} from '../../../../common/utils/util';
import { NoteAccountRanges } from '../types';

const MONETARY_ITEM_TYPE = 'monetaryItemType';

export const note23Accounts: NoteAccountRanges[] = [
  {
    rowId: '1',
    ranges: [[1360, 1368]],
    cellLabel: 'Ingående anskaffningsvärden',
    action: 'sumAccountsIBs',
  },
  {
    rowId: '2',
    ranges: [[1360, 1368]],
    cellLabel: 'Årets lämnade lån',
    action: 'sumPositiveTransactions',
  },
  {
    rowId: '3',
    ranges: [[1360, 1368]],
    cellLabel: 'Årets amorteringar',
    action: 'sumNegativeTransactions',
  },
  {
    rowId: '6',
    ranges: [[1369]],
    cellLabel: 'Ingående nedskrivningar',
    action: 'sumAccountsIBs',
  },
  {
    rowId: '7',
    ranges: [[1369]],
    cellLabel: 'Årets amorteringar',
    action: 'sumPositiveTransactions',
  },
  {
    rowId: '10',
    ranges: [[1369]],
    cellLabel: 'Årets nedskrivningar',
    action: 'sumNegativeTransactions',
  },
];

export const getNote23 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null
): NoteOneTableSection => {
  return {
    active: false,
    number: value(undefined),
    name: value('Lån till delägare eller närstående'),
    table: table<IxbrlCell>(
      'notes.note23.table',
      { id: 'label', label: '' },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      .addRows((rows) =>
        rows
          // 'ingående' = previous period outgoing balance
          .addRow(
            '1',
            noteLabel('Ingående anskaffningsvärden'),
            ixbrlCell(ref(sumAccountsIBs('1360:1368', false)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:LanDelagareNarstaendeAnskaffningsvarden',
              contextRef: 'balans1',
              saldo: 'debit',
            }),
            ixbrlCell(ref(sumAccountsIBs('1360:1368', false, 'previousYear')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:LanDelagareNarstaendeAnskaffningsvarden',
              contextRef: 'balans2',
              saldo: 'debit',
            })
          )
          .addRow(
            '2',
            noteLabel('Årets lämnade lån'),
            ixbrlCell(ref(sumPositiveTransactions('1360:1368')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:LanDelagareNarstaendeForandringAnskaffningsvardenAretsLamnadeLan',
              contextRef: 'period0',
              standardRubrik:
                'Ökning av anskaffningsvärden i samband med periodens lämnade lån till delägare eller närstående',
            }),
            ixbrlCell(
              ref(sumPositiveTransactions('1360:1368', 'previousYear')),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:LanDelagareNarstaendeForandringAnskaffningsvardenAretsLamnadeLan',
                contextRef: 'period1',
                standardRubrik:
                  'Ökning av anskaffningsvärden i samband med periodens lämnade lån till delägare eller närstående',
              }
            )
          )
          .addRow(
            'anskaffningsvardenFusion',
            noteLabel('Fusion'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:LanDelagareNarstaendeForandringAnskaffningsvardenFusion',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden för lån till delägare eller närstående genom fusion',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:LanDelagareNarstaendeForandringAnskaffningsvardenFusion',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden för lån till delägare eller närstående genom fusion',
            })
          )
          .addRow(
            '3',
            noteLabel('Årets amorteringar'),
            ixbrlCell(ref(sumNegativeTransactions('1360:1368')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:LanDelagareNarstaendeForandringAnskaffningsvardenAretsAmorteringar',
              contextRef: 'period0',
              standardRubrik:
                'Minskning av anskaffningsvärden i samband med periodens amorteringar av lån till delägare eller närstående',
              negateValue: true,
            }),
            ixbrlCell(
              ref(sumNegativeTransactions('1360:1368', 'previousYear')),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:LanDelagareNarstaendeForandringAnskaffningsvardenAretsAmorteringar',
                contextRef: 'period1',
                standardRubrik:
                  'Minskning av anskaffningsvärden i samband med periodens amorteringar av lån till delägare eller närstående',
                negateValue: true,
              }
            )
          )
          .addRow(
            'anskaffningsvardenWrittenReceivables',
            noteLabel('Bortskrivna fordringar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:LanDelagareNarstaendeForandringAnskaffningsvardenBortskrivnafordringar',
              contextRef: 'period0',
              standardRubrik:
                'Minskning av anskaffningsvärden i samband med periodens bortskrivning av lån till delägare eller närstående',
              negateValue: true,
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:LanDelagareNarstaendeForandringAnskaffningsvardenBortskrivnafordringar',
              contextRef: 'period1',
              standardRubrik:
                'Minskning av anskaffningsvärden i samband med periodens bortskrivning av lån till delägare eller närstående',
              negateValue: true,
            })
          )
          .addRow(
            '4',
            noteLabel('Omklassificeringar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:LanDelagareNarstaendeForandringAnskaffningsvardenOmklassificeringar',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden i samband med omklassificeringar av lån till delägare eller närstående',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:LanDelagareNarstaendeForandringAnskaffningsvardenOmklassificeringar',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden i samband med omklassificeringar av lån till delägare eller närstående',
            })
          )
          .addRow(
            'anskaffningsvardenConversionDifferences',
            noteLabel('Omräkningsdifferenser'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:LanDelagareNarstaendeForandringAnskaffningsvardenOmrakningsdifferenser',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden i samband med omräkningsdifferenser av lån till delägare eller närstående',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:LanDelagareNarstaendeForandringAnskaffningsvardenOmrakningsdifferenser',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden i samband med omräkningsdifferenser av lån till delägare eller närstående',
            })
          )
          .addRow(
            '5',
            noteLabel('Utgående  anskaffningsvärden'),
            ixbrlCell(
              sumNotesRowsById(
                23,
                [
                  ...range(1, 5),
                  'anskaffningsvardenFusion',
                  'anskaffningsvardenWrittenReceivables',
                  'anskaffningsvardenConversionDifferences',
                ],
                'year'
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:LanDelagareNarstaendeAnskaffningsvarden',
                contextRef: 'balans0',
                saldo: 'debit',
              }
            ),
            ixbrlCell(
              sumNotesRowsById(
                23,
                [
                  ...range(1, 5),
                  'anskaffningsvardenFusion',
                  'anskaffningsvardenWrittenReceivables',
                  'anskaffningsvardenConversionDifferences',
                ],
                'previousYear'
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:LanDelagareNarstaendeAnskaffningsvarden',
                contextRef: 'balans1',
                saldo: 'debit',
              }
            )
          )
          .addRow(
            '6',
            noteLabel('Ingående nedskrivningar'),
            ixbrlCell(ref(sumAccountsIBs('1369', false)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:LanDelagareNarstaendeNedskrivningar',
              contextRef: 'balans1',
              saldo: 'credit',
              negateValue: true,
            }),
            ixbrlCell(ref(sumAccountsIBs('1369', false, 'previousYear')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:LanDelagareNarstaendeNedskrivningar',
              contextRef: 'balans2',
              saldo: 'credit',
              negateValue: true,
            })
          )
          .addRow(
            '7',
            noteLabel('Årets amorteringar'),
            ixbrlCell(ref(sumPositiveTransactions('1369')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:LanDelagareNarstaendeForandringNedskrivningarAretsAmorteringar',
              contextRef: 'period0',
              negateValue: true,
            }),
            ixbrlCell(ref(sumPositiveTransactions('1369', 'previousYear')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:LanDelagareNarstaendeForandringNedskrivningarAretsAmorteringar',
              contextRef: 'period1',
              negateValue: true,
            })
          )
          .addRow(
            'nedskrivningarFusion',
            noteLabel('Fusion'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:LanDelagareNarstaendeForandringNedskrivningarFusion',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av nedskrivningar av lån till delägare eller närstående genom fusion',
              negateValue: true,
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:LanDelagareNarstaendeForandringNedskrivningarFusion',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av nedskrivningar av lån till delägare eller närstående genom fusion',
              negateValue: true,
            })
          )
          .addRow(
            '8',
            noteLabel('Återförda nedskrivningar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:LanDelagareNarstaendeForandringNedskrivningarAterfordaNedskrivningar',
              contextRef: 'period0',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:LanDelagareNarstaendeForandringNedskrivningarAterfordaNedskrivningar',
              contextRef: 'period1',
            })
          )
          .addRow(
            'nedskrivningarWrittenReceivables',
            noteLabel('Bortskrivna fordringar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:LanDelagareNarstaendeForandringNedskrivningarBortskrivnafordringar',
              contextRef: 'period0',
              standardRubrik:
                'Minskning av nedskrivningar i samband med bortskrivningar av lån till delägare eller närstående',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:LanDelagareNarstaendeForandringNedskrivningarBortskrivnafordringar',
              contextRef: 'period1',
              standardRubrik:
                'Minskning av nedskrivningar i samband med bortskrivningar av lån till delägare eller närstående',
            })
          )
          .addRow(
            '9',
            noteLabel('Omklassificeringar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:LanDelagareNarstaendeForandringNedskrivningarOmklassificeringar',
              contextRef: 'period0',
              standardRubrik:
                'Minskning (ökning) av nedskrivningar i samband med omklassificeringar av lån till delägare eller närstående',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:LanDelagareNarstaendeForandringNedskrivningarOmklassificeringar',
              contextRef: 'period1',
              standardRubrik:
                'Minskning (ökning) av nedskrivningar i samband med omklassificeringar av lån till delägare eller närstående',
            })
          )
          .addRow(
            '10',
            noteLabel('Årets nedskrivningar'),
            ixbrlCell(ref(sumNegativeTransactions('1369')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:LanDelagareNarstaendeForandringNedskrivningarAretsNedskrivningar',
              contextRef: 'period0',
              negateValue: true,
            }),
            ixbrlCell(ref(sumNegativeTransactions('1369', 'previousYear')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:LanDelagareNarstaendeForandringNedskrivningarAretsNedskrivningar',
              contextRef: 'period1',
              negateValue: true,
            })
          )
          .addRow(
            'nedskrivningarConversionDifferences',
            noteLabel('Omräkningsdifferenser'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:LanDelagareNarstaendeForandringNedskrivningarOmrakningsdifferenser',
              contextRef: 'period0',
              standardRubrik:
                'Minskning (ökning) av nedskrivningar i samband med omräkningsdifferenser av lån till delägare eller närstående',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:LanDelagareNarstaendeForandringNedskrivningarOmrakningsdifferenser',
              contextRef: 'period1',
              standardRubrik:
                'Minskning (ökning) av nedskrivningar i samband med omräkningsdifferenser av lån till delägare eller närstående',
            })
          )
          .addRow(
            '11',
            noteLabel('Utgående nedskrivningar'),
            ixbrlCell(
              sumNotesRowsById(
                23,
                [
                  ...range(6, 11),
                  'nedskrivningarFusion',
                  'nedskrivningarWrittenReceivables',
                  'nedskrivningarConversionDifferences',
                ],
                'year'
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:LanDelagareNarstaendeNedskrivningar',
                contextRef: 'balans0',
                saldo: 'credit',
                negateValue: true,
              }
            ),
            ixbrlCell(
              sumNotesRowsById(
                23,
                [
                  ...range(6, 11),
                  'nedskrivningarFusion',
                  'nedskrivningarWrittenReceivables',
                  'nedskrivningarConversionDifferences',
                ],
                'previousYear'
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:LanDelagareNarstaendeNedskrivningar',
                contextRef: 'balans1',
                saldo: 'credit',
                negateValue: true,
              }
            )
          )
          .addRow(
            '12',
            noteLabel('Redovisat värde'),
            ixbrlCell(sumNotesRowsById(23, [5, 11], 'year'), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:LanDelagareNarstaende',
              contextRef: 'balans0',
              saldo: 'debit',
            }),
            ixbrlCell(sumNotesRowsById(23, [5, 11], 'previousYear'), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:LanDelagareNarstaende',
              contextRef: 'balans1',
              saldo: 'debit',
            })
          )
          .build()
      )
      .newRowTemplate(value(''), value(undefined), value(undefined))
      .build(),
    commentLabel: field('Kommentar'),
    comment: ixbrlField(field(''), {
      type: 'stringItemType',
      name: 'se-gen-base:NotLanDelagareNarstaendeKommentar',
      contextRef: 'period0',
    }),
    group: value(undefined),
    section: value(undefined),
    validForReportType: {
      k2: true,
      k3: true,
    },
  };
};

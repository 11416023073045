import React, { useCallback, useContext } from 'react';
import { Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import TaxesDataContext from '_tax/service/TaxesDataContext';
import { currentParticularSalaryITPDocument } from '_tax/redux/tax-view/selectors';
import usePrintState from '_tax/hooks/usePrintState';
import { printDocument } from '_shared/components/PrintedDocument/utils';
import { addGlobalErrorMessage } from 'redux/actions';
import { useSelector } from 'redux/reducers';

import ExpandableTwoColPanel from '../UI/ExpandableTwoColPanel/ExpandableTwoColPanel';
import { SingleCalculationComponent } from '../UI/SingleCalculationComponent/SingleCalculationComponent';
import { CalculationsList, InputField, Label, Row } from './Layout';
import TaxDocumentTable from '../TaxDocumentTable';
import TaxDocumentNoteRow from '../TaxDocumentNoteRow';

type ITPCalculationsLeftPanelProps = {
  isEditing?: boolean;
  isPrint?: boolean;
};

export const ITPCalculationsLeftPanel = ({
  isEditing = false,
  isPrint = false,
}: ITPCalculationsLeftPanelProps): JSX.Element | null => {
  const { formatMessage } = useIntl();
  const state = useSelector(currentParticularSalaryITPDocument);

  if (!state) {
    return null;
  }

  const { itpPremiums, unpaidITP, unpaidITPK, unpaidITPKNote, ITPKPremiums } =
    state;

  return (
    <div>
      <Row>
        <Label>
          {formatMessage({
            id: 'taxCalculations.itp.itp-premiums',
          })}
        </Label>
        <InputField
          id="particularSalaryTax.itp.itpPremiums"
          cell={itpPremiums}
          isField
        />
      </Row>
      <Row>
        <Label>
          {formatMessage({
            id: 'taxCalculations.itp.unpaid-itp',
          })}
        </Label>
        <InputField
          id="particularSalaryTax.itp.unpaidITP"
          cell={unpaidITP}
          isField
        />
      </Row>

      <Typography variant="h6" style={{ padding: '8px' }}>
        {formatMessage({
          id: 'taxCalculations.itp.subtitle',
        })}
      </Typography>

      <Row noMargin>
        <TaxDocumentTable
          baseId="taxViewDocument"
          tableId="particularSalaryTax.itp.ITPKPremiums"
          editing={isEditing}
          rows={ITPKPremiums.rows}
          columns={ITPKPremiums.columns}
          isPrint={isPrint}
        />
      </Row>

      <TaxDocumentNoteRow
        inputCellId="particularSalaryTax.itp.unpaidITPK"
        inputCell={unpaidITPK}
        noteCellId="particularSalaryTax.itp.unpaidITPKNote"
        noteCell={unpaidITPKNote}
        editing={isEditing}
        labelId="taxCalculations.itp.total"
      />
    </div>
  );
};

type RightPanelProps = {
  isEditing?: boolean;
};

const RightPanel = ({
  isEditing = false,
}: RightPanelProps): JSX.Element | null => {
  const state = useSelector(currentParticularSalaryITPDocument);

  if (!state) {
    return null;
  }

  const { rows } = state.helper.premiums;

  return (
    <CalculationsList>
      {rows.map((row) => (
        <SingleCalculationComponent
          key={row.id}
          rowId={`particularSalaryTax.itp.helper.premiums.${row.id}`}
          isEditMode={isEditing && row.id !== 'sum'}
          rows={row.rows}
          title={row.cells?.title}
          label={row.cells?.label}
          sum={row.cells?.sum}
          value={row.cells?.value}
          percentage={row.cells?.percentage}
          note={row.cells?.note}
          label2={row.cells?.label2}
          value2={row.cells?.value2}
        />
      ))}
    </CalculationsList>
  );
};

export const ITPCalculationsPrint = (): JSX.Element => {
  const { formatMessage } = useIntl();

  return (
    <div>
      <Typography variant="h2">
        {formatMessage({
          id: 'taxCalculations.itp.left-col.title',
        })}
      </Typography>
      <ITPCalculationsLeftPanel isPrint />
    </div>
  );
};

const ITPCalculationsPanel = (): JSX.Element => {
  const { formatMessage } = useIntl();
  const printState = usePrintState();
  const dispatch = useDispatch();

  const { service, financialYear, clientId } = useContext(TaxesDataContext);

  const handleReset = useCallback(() => {
    service.resetDocumentPart('particularSalaryTax.itp');
  }, [service]);

  const handlePrint = useCallback(async () => {
    try {
      await printDocument(
        clientId,
        financialYear,
        'taxes',
        formatMessage({
          id: 'taxCalculations.itp.left-col.title',
        }),
        ['itpCalculations'],
        printState
      );
    } catch (e) {
      dispatch(addGlobalErrorMessage('error'));
    }
  }, [clientId, dispatch, financialYear, formatMessage, printState]);

  return (
    <ExpandableTwoColPanel
      leftColTitle={formatMessage({
        id: 'taxCalculations.itp.left-col.title',
      })}
      rightColTitle={formatMessage({
        id: 'taxCalculations.itp.right-col.title',
      })}
      leftCol={(isEditing) => {
        return <ITPCalculationsLeftPanel isEditing={isEditing} />;
      }}
      rightCol={(isEditing) => {
        return <RightPanel isEditing={isEditing} />;
      }}
      onResetClick={handleReset}
      onPrintClick={handlePrint}
    />
  );
};

export default ITPCalculationsPanel;

import { value, table } from '@agoy/document';
import { NoteTwoTableSection, IxbrlCell } from '../../../../common';
import {
  active,
  field,
  ixbrlCell,
  ixbrlField,
  tupleRef,
} from '../../../../common/utils/util';
import { year } from '../../../../common/utils/date-util';

const MONETARY_ITEM_TYPE = 'monetaryItemType';

export const getNote80 = (
  financialYear: string,
  financialYears: string[],
  period: string | null
): NoteTwoTableSection => {
  return {
    active: false,
    number: value(undefined),
    name: value('Fusion'),
    table: table<IxbrlCell>(
      'notes.note80.table',
      {
        id: 'companyName',
        label: 'Företags namn',
      },
      {
        id: 'organizationNumber',
        label: 'Organisationsnummer',
      },
      {
        id: 'noteDate',
        label: 'Fusionsdag',
      },
      {
        id: 'netSales',
        label: 'Nettoomsättning som ingår',
      },
      {
        id: 'operatingProfit',
        label: 'Rörelseresultat som ingår',
      },
      {
        id: 'notNetSales',
        label: 'Nettoomsättning som inte ingår',
      },
      {
        id: 'notOperatingProfit',
        label: 'Rörelseresultat som inte ingår',
      }
    )
      .addRows((rows) =>
        rows
          .addRow(
            '1',
            ixbrlCell(value(''), {
              type: 'stringItemType',
              name: 'se-cd-base:ForetagetsNamn',
              contextRef: 'period0',
              tuple: {
                name: 'se-gaap-ext:FusionBolagTuple',
                id: `${rows.getBaseId()}.1.tuple`,
                tupleID: `${rows.getBaseId()}.1.tuple`,
              },
              tupleRef: tupleRef(1, `${rows.getBaseId()}.1.tuple`),
            }),
            ixbrlCell(value(''), {
              type: 'stringItemType',
              name: 'se-cd-base:Organisationsnummer',
              contextRef: 'period0',
              tupleRef: tupleRef(2, `${rows.getBaseId()}.1.tuple`),
            }),
            ixbrlCell(value(''), {
              type: 'dateItemType',
              name: 'se-gen-base:FusionBolagFusionsdag',
              contextRef: 'period0',
              tupleRef: tupleRef(3, `${rows.getBaseId()}.1.tuple`),
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:FusionBolagOvertagetForetagNettoomsattningForeFusionenRegistrering',
              contextRef: 'period0',
              saldo: 'credit',
              tupleRef: tupleRef(4, `${rows.getBaseId()}.1.tuple`),
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:FusionBolagOvertagetForetagRorelseresultatForeFusionenRegistrering',
              contextRef: 'period0',
              saldo: 'credit',
              tupleRef: tupleRef(5, `${rows.getBaseId()}.1.tuple`),
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:FusionBolagOvertagetForetagNettoomsattningInteRedovisatOvertagandeForetage',
              contextRef: 'period0',
              saldo: 'credit',
              tupleRef: tupleRef(6, `${rows.getBaseId()}.1.tuple`),
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:FusionBolagOvertagetForetagRorelseresultatInteRedovisatOvertagandeForetage',
              contextRef: 'period0',
              saldo: 'credit',
              tupleRef: tupleRef(7, `${rows.getBaseId()}.1.tuple`),
            })
          )
          .build()
      )
      .newRowTemplateGenerator((id, baseId) => ({
        id,
        active: true,
        cells: {
          companyName: ixbrlCell(value(''), {
            type: 'stringItemType',
            name: 'se-cd-base:ForetagetsNamn',
            contextRef: 'period0',
            tuple: {
              name: 'se-gaap-ext:FusionBolagTuple',
              id: `${baseId}.${id}.tuple`,
              tupleID: `${baseId}.${id}.tuple`,
            },
            tupleRef: tupleRef(1, `${baseId}.${id}.tuple`),
          }),
          organizationNumber: ixbrlCell(value(''), {
            type: 'stringItemType',
            name: 'se-cd-base:Organisationsnummer',
            contextRef: 'period0',
            tupleRef: tupleRef(2, `${baseId}.${id}.tuple`),
          }),
          noteDate: ixbrlCell(value(undefined), {
            type: 'dateItemType',
            name: 'se-gen-base:FusionBolagFusionsdag',
            contextRef: 'period0',
            saldo: 'credit',
            tupleRef: tupleRef(3, `${baseId}.${id}.tuple`),
          }),
          netSales: ixbrlCell(value(undefined), {
            type: MONETARY_ITEM_TYPE,
            name: 'se-gen-base:FusionBolagOvertagetForetagNettoomsattningForeFusionenRegistrering',
            contextRef: 'period0',
            saldo: 'credit',
            tupleRef: tupleRef(4, `${baseId}.${id}.tuple`),
          }),
          operatingProfit: ixbrlCell(value(undefined), {
            type: MONETARY_ITEM_TYPE,
            name: 'se-gen-base:FusionBolagOvertagetForetagRorelseresultatForeFusionenRegistrering',
            contextRef: 'period0',
            saldo: 'credit',
            tupleRef: tupleRef(5, `${baseId}.${id}.tuple`),
          }),
          notNetSales: ixbrlCell(value(undefined), {
            type: MONETARY_ITEM_TYPE,
            name: 'se-gen-base:FusionBolagOvertagetForetagNettoomsattningInteRedovisatOvertagandeForetage',
            contextRef: 'period0',
            saldo: 'credit',
            tupleRef: tupleRef(6, `${baseId}.${id}.tuple`),
          }),
          notOperatingProfit: ixbrlCell(value(undefined), {
            type: MONETARY_ITEM_TYPE,
            name: 'se-gen-base:FusionBolagOvertagetForetagRorelseresultatInteRedovisatOvertagandeForetage',
            contextRef: 'period0',
            saldo: 'credit',
            tupleRef: tupleRef(7, `${baseId}.${id}.tuple`),
          }),
        },
      }))
      .build(),
    // Title here: Uppgift om övertaget företags balansräkning
    secondTable: table<IxbrlCell>(
      'notes.note80.secondTable',
      {
        id: 'companyName',
        label: 'Företagets namn',
      },
      {
        id: 'assetOrLiability',
        label: 'Typ av tillgång eller skuld',
      },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      }
    )
      .addRows((rows) =>
        rows
          .addRow(
            '1',
            ixbrlCell(value(''), {
              type: 'stringItemType',
              name: 'se-cd-base:ForetagetsNamn',
              contextRef: 'period0',
              tuple: {
                name: 'se-gaap-ext:FusionOverlatandeForetagBalansrakningTuple',
                id: `${rows.getBaseId()}.1.tuple`,
                tupleID: `${rows.getBaseId()}.1.tuple`,
              },
              tupleRef: tupleRef(1, `${rows.getBaseId()}.1.tuple`),
            }),
            ixbrlCell(value(''), {
              type: 'stringItemType',
              name: 'se-gen-base:FusionOverlatandeForetagBalansrakningTypTillgangSkuld',
              contextRef: 'balans0',
              tupleRef: tupleRef(2, `${rows.getBaseId()}.1.tuple`),
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:FusionOverlatandeForetagBalansrakningBelopp',
              contextRef: 'balans0',
              tupleRef: tupleRef(3, `${rows.getBaseId()}.1.tuple`),
            })
          )
          .build()
      )
      .newRowTemplateGenerator((id, baseId) => ({
        id,
        active: true,
        cells: {
          companyName: ixbrlCell(value(''), {
            type: 'stringItemType',
            name: 'se-cd-base:ForetagetsNamn',
            contextRef: 'period0',
            tuple: {
              name: 'se-gaap-ext:FusionOverlatandeForetagBalansrakningTuple',
              id: `${baseId}.${id}.tuple`,
              tupleID: `${baseId}.${id}.tuple`,
            },
            tupleRef: tupleRef(1, `${baseId}.${id}.tuple`),
          }),
          assetOrLiability: ixbrlCell(value(''), {
            type: 'stringItemType',
            name: 'se-gen-base:FusionOverlatandeForetagBalansrakningTypTillgangSkuld',
            contextRef: 'balans0',
            tupleRef: tupleRef(2, `${baseId}.${id}.tuple`),
          }),
          year: ixbrlCell(value(undefined), {
            type: MONETARY_ITEM_TYPE,
            name: 'se-gen-base:FusionOverlatandeForetagBalansrakningBelopp',
            contextRef: 'balans0',
            tupleRef: tupleRef(3, `${baseId}.${id}.tuple`),
          }),
        },
      }))
      .build(),
    commentLabel: field('Kommentar'),
    comment: ixbrlField(active(field('')), {
      type: 'stringItemType',
      name: 'se-gen-base:NotExceptionellaIntakterKostnaderKommentar',
      contextRef: 'period0',
    }),
    group: value(undefined),
    section: value(undefined),
    validForReportType: {
      k2: false,
      k3: true,
    },
  };
};

import React from 'react';
import { useIntl } from 'react-intl';
import { TextSection as TextSectionType } from '@agoy/annual-report-document';
import { stringValue } from '@agoy/document';
import { Heading, TextBlock } from '../../../UI';
import MultilineField from '../UI/MultilineField';

type TextSectionProps = {
  fullId: string;
  id: string;
  reportPart: TextSectionType;
  hideTitle?: boolean;
};

const TextSection = ({
  fullId,
  id,
  reportPart,
  hideTitle = false,
}: TextSectionProps) => {
  const intl = useIntl();

  const title = hideTitle
    ? ''
    : stringValue(reportPart.title) ||
      intl.formatMessage({
        id: `annualReport.menu.${fullId}`,
      });

  const value = stringValue(reportPart.text);
  const show = value;
  if (!show) {
    return null;
  }

  return (
    <TextBlock>
      <Heading id={id} title={title} />
      <MultilineField field={reportPart.text} />
    </TextBlock>
  );
};

export default TextSection;

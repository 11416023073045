import { boolean } from '@agoy/annual-report-document';
import { AgoyDocument, AgoyDocumentChanges } from '@agoy/document';

export type FinancialReport = AgoyDocument<typeof contentDefinition>;

export type FinancialReportPartKey = keyof typeof partDefintions;

export function isBokslutReportPartKey(
  part: string
): part is FinancialReportPartKey {
  return contentDefinition.children[part] !== undefined;
}

export type FinancialReportChanges = AgoyDocumentChanges<
  typeof contentDefinition
>;

const cell = {
  type: 'field' as const,
};

export type FinancialReportStructure = AgoyDocument<typeof contentDefinition>;

export const contentDefinition = {
  type: 'document' as const,
  children: {
    settings: {
      type: 'part' as const,
      children: {
        warnings: {
          type: 'part' as const,
          children: {
            table: { type: 'table' as const },
          },
        },
        section: {
          type: 'part' as const,
          children: {
            title: cell,
            reportPerson: cell,
            secondaryText: cell,
            active: { type: 'boolean' as const },
            isSinglePeriod: { type: 'boolean' as const },
            isFirstFinancialYear: { type: 'boolean' as const },
            clientName: cell,
            financialYear: cell,
            organisationNumber: cell,
            period: cell,
            periodStart: cell,
            periodEnd: cell,
            previousPeriodStart: cell,
            previousPeriodEnd: cell,
            logoLargeUrl: cell,
            logoUrl: cell,
            colorTextMain: cell,
            colorTextSecondary: cell,
            colorBackground: cell,
            keyValuesPercent: cell,
            noRiskPercent: cell,
            formatTextValuesInThousands: { type: 'boolean' as const },
            formatDiagramValuesInThousands: { type: 'boolean' as const },
            formatTableValuesInThousands: { type: 'boolean' as const },
            isTextRandomizationEnabled: { type: 'boolean' as const },
            useClientLogo: { type: 'boolean' as const },
            keyValuesType: cell,
            comparePeriod: cell,
          },
        },
      },
    },
    frontPage: {
      type: 'part' as const,
      children: {
        section: {
          type: 'part' as const,
          children: {
            active: { type: 'boolean' as const },
            title: cell,
            clientName: cell,
          },
        },
      },
    },

    tableOfContents: {
      type: 'part' as const,
      children: {
        section: {
          type: 'part' as const,
          children: { active: { type: 'boolean' as const } },
        },
      },
    },

    balanceSheetAssets: {
      type: 'part' as const,
      children: {
        section: {
          type: 'part' as const,
          children: {
            active: { type: 'boolean' as const },
            activeSecondTable: { type: 'boolean' as const },
          },
        },
      },
    },

    diagramRRBR: {
      type: 'part' as const,
      children: {
        section: {
          type: 'part' as const,
          children: {
            active: { type: 'boolean' as const },
            userName: cell,
            title: cell,
            secondaryText: cell,
            note1Static: cell,
            note2Static: cell,
            charter1Static: cell,
            charter2Static: cell,
            note1: cell,
            note2: cell,
            note3: cell,
            query1: cell,
            query2: cell,
            query3: cell,
            query4: cell,
            query5: cell,
            query6: cell,
            charter1: cell,
            resultPrevious: { type: 'table' as const },
            resultCurrent: { type: 'table' as const },
            charter2: cell,
            compareMonth: { type: 'table' as const },
          },
        },
      },
    },

    tableRRBR: {
      type: 'part' as const,
      children: {
        section: {
          type: 'part' as const,
          children: {
            active: { type: 'boolean' as const },
            title: cell,
            generalInfo: cell,
            generalInfoStatic: cell,
            result: { type: 'table' as const },
            balance: { type: 'table' as const },
            cashFlow: { type: 'table' as const },
            comments: cell,
          },
        },
      },
    },

    cashFlowDiagram: {
      type: 'part' as const,
      children: {
        section: {
          type: 'part' as const,
          children: {
            active: { type: 'boolean' as const },
            title: cell,
            secondaryText: cell,
            secondaryTextStatic: cell,
            charter: cell,
            result: { type: 'table' as const },
          },
        },
      },
    },
    incomeStatement: {
      type: 'part' as const,
      children: {
        active: { type: 'boolean' as const },
        section: {
          type: 'part' as const,
          children: {
            active: { type: 'boolean' as const },
            table: { type: 'table' as const },
          },
        },
      },
    },
    incomeStatementTables: {
      type: 'part' as const,
      children: {
        active: { type: 'boolean' as const },
        section: {
          type: 'part' as const,
          children: {
            active: { type: 'boolean' as const },
            table: { type: 'table' as const },
            secondTable: { type: 'table' as const },
            periodTable: { type: 'table' as const },
            secondPeriodTable: { type: 'table' as const },
          },
        },
      },
    },
    balanceSheet: {
      type: 'part' as const,
      children: {
        active: { type: 'boolean' as const },
        section: {
          type: 'part' as const,
          children: {
            active: { type: 'boolean' as const },
            assets: { type: 'table' as const },
            equityAndLiabilities: { type: 'table' as const },
          },
        },
      },
    },
    documents: {
      type: 'part' as const,
      children: {
        section: {
          type: 'part' as const,
          children: {
            active: { type: 'boolean' as const },
            title: cell,
          },
        },
      },
    },
    additionalText: {
      type: 'part' as const,
      children: {
        section: {
          type: 'part' as const,
          children: {
            active: { type: 'boolean' as const },
            text1Active: { type: 'boolean' as const },
            text1Title: cell,
            text1: cell,
            text2Active: { type: 'boolean' as const },
            text2Title: cell,
            text2: cell,
          },
        },
      },
    },
  },
};

const partDefintions = contentDefinition.children;

export type ReportType = 'k1' | 'k2' | 'k3';

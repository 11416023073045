import { AgoyTable, AgoyTableRow } from '@agoy/document';

const spacer: AgoyTableRow = {
  active: true,
  type: 'spacer',
  id: 'spacer',
};

const addSpacers = (table: AgoyTable) => {
  const resultRows = table.rows
    .map((row) => {
      const subRows = row.rows ?? [];

      // level 2
      const subRowsWithSpacers = subRows
        .map((subRow, index) => {
          const nextSubRow = subRows[index + 1];

          // spacers after level 2 row if
          // - next row is a header
          // - row is a sum and next row is row
          if (
            nextSubRow &&
            (nextSubRow.type === 'header' ||
              (subRow.type === 'sum' && nextSubRow.type === 'row'))
          ) {
            return [subRow, { ...spacer, id: `${subRow.id}-spacer` }];
          }

          return [subRow];
        })
        .flat();

      // - spacers after each level 1 except for accountsNotAssigned
      // - for level 1 headers if there is more than 1 child
      if (
        row.type !== 'accountsNotAssigned' &&
        (row.type !== 'header' || subRows.length > 1)
      ) {
        // add as 0 child spacer
        subRowsWithSpacers.unshift(spacer);
      }

      const newRow = { ...row, rows: subRowsWithSpacers };

      // spacer after level 1 children if there were some
      if (subRows.length) {
        return [newRow, { ...spacer, id: `${row.id}-spacer` }];
      }

      return [newRow];
    })
    .flat();

  return {
    ...table,
    rows: resultRows,
  };
};

const addPrintSpacers = (table: AgoyTable, majorTitles = false) => {
  const traverseSubRows = (rows: AgoyTableRow[], level = 1): AgoyTableRow[] => {
    const resultRows = rows.map((row, index) => {
      const traversedChildren = row.rows
        ? traverseSubRows(row.rows, level + 1)
        : [];

      if (majorTitles && row.type === 'header' && level === 1) {
        traversedChildren.unshift({ ...spacer, id: `${row.id}-header-spacer` });
      }

      const nextRow = rows[index + 1];

      const rowWithTraversedChildren = {
        ...row,
        rows: traversedChildren,
      };

      // add spacers after each sum (except for the very last summary on level 1) or group without sum
      return (row.type === 'sum' && (level > 1 || !!nextRow)) ||
        (nextRow && nextRow.type === 'header')
        ? [rowWithTraversedChildren, { ...spacer, id: `${row.id}-spacer` }]
        : [rowWithTraversedChildren];
    });

    return resultRows.flat();
  };

  const traversedRows = traverseSubRows(table.rows);
  const preSpacer = traversedRows[0]?.type?.toLocaleLowerCase().includes('sum')
    ? []
    : [spacer];

  return {
    ...table,
    rows: [...preSpacer, ...traversedRows],
  };
};

export { addSpacers, addPrintSpacers };

export const companyTaxPerYear = {
  '2013': 0.22,
  '2014': 0.22,
  '2015': 0.22,
  '2016': 0.22,
  '2017': 0.22,
  '2018': 0.22,
  '2019': 0.214,
  '2020': 0.214,
  '2021': 0.206,
  '2022': 0.206,
  '2023': 0.206,
  '2024': 0.206,
};

import saveUserInputData from './saveUserInputData';
import saveTaxDeclarationFormMiddleware from './saveTaxDeclarationFormMiddleware';
import saveFinancialReportMiddleware from './saveFinacialReportMiddleware';

const middleWares = [
  saveUserInputData,
  saveTaxDeclarationFormMiddleware,
  saveFinancialReportMiddleware,
];
export default middleWares;

import { ref, table, value } from '@agoy/document';
import { NoteOneTableSection } from '../../../../common';
import { previousYear, year } from '../../../../common/utils/date-util';
import { field, range } from '../../../../common/utils/util';
import {
  sumAccountsIBs,
  sumNegativeTransactions,
  sumNotesRowsById,
  sumPositiveTransactions,
} from '../../../../common/utils/accountSumsUtil';
import { NoteAccountRanges } from '../types';

export const note43Accounts: NoteAccountRanges[] = [
  {
    rowId: '1',
    ranges: [[1000, 1017]],
    cellLabel: 'Ingående utgifter för utvecklingsarbete',
    action: 'sumAccountsIBs',
  },
  {
    rowId: '2',
    ranges: [[1000, 1017]],
    cellLabel: 'Upparbetat under året',
    action: 'sumPositiveTransactions',
  },
  {
    rowId: '3',
    ranges: [[1000, 1017]],
    cellLabel: 'Försäljningar/utrangeringar',
    action: 'sumNegativeTransactions',
  },
  {
    rowId: '6',
    ranges: [[1019]],
    cellLabel: 'Ingående avskrivningar',
    action: 'sumAccountsIBs',
  },
  {
    rowId: '7',
    ranges: [[1019]],
    cellLabel: 'Försäljningar/utrangeringar',
    action: 'sumPositiveTransactions',
  },
  {
    rowId: '9',
    ranges: [[1019]],
    cellLabel: 'Årets avskrivningar',
    action: 'sumNegativeTransactions',
  },
  {
    rowId: '11',
    ranges: [[1018]],
    cellLabel: 'Ingående nedskrivningar',
    action: 'sumAccountsIBs',
  },
  {
    rowId: '12',
    ranges: [[1018]],
    cellLabel: 'Försäljningar/utrangeringar',
    action: 'sumPositiveTransactions',
  },
  {
    rowId: '15',
    ranges: [[1018]],
    cellLabel: 'Årets nedskrivningar',
    action: 'sumNegativeTransactions',
  },
];

export const getNote43 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null,
  documentTypeVersion = '1'
): NoteOneTableSection => {
  return {
    active: false,
    number: value(undefined),
    name: value(
      'Balanserade utgifter för utvecklingsarbeten och liknande arbeten'
    ),
    table: table(
      `notes.note43.table`,
      { id: 'label', label: '' },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      .addRows((rows) =>
        rows
          .addRow(
            '1',
            noteLabel('Ingående utgifter för utvecklingsarbete'),
            ref(sumAccountsIBs('1000:1017', false)),
            ref(sumAccountsIBs('1000:1017', false, 'previousYear'))
          )
          .addRow(
            '2',
            noteLabel('Upparbetat under året'),
            ref(sumPositiveTransactions('1000:1017')),
            ref(sumPositiveTransactions('1000:1017', 'previousYear'))
          )
          .addRow(
            '3',
            noteLabel('Försäljningar/utrangeringar'),
            ref(sumNegativeTransactions('1000:1017')),
            ref(sumNegativeTransactions('1000:1017', 'previousYear'))
          )
          .addRow(
            '4',
            noteLabel('Omklassificeringar'), // no accounts
            value(undefined),
            value(undefined)
          )
          .addRow(
            '5',
            noteLabel('Utgående ackumulerade utgifter för utvecklingsarbete'),
            sumNotesRowsById(43, range(1, 5), 'year'),
            sumNotesRowsById(43, range(1, 5), 'previousYear')
          )
          .addRow(
            '6',
            noteLabel('Ingående avskrivningar'),
            ref(sumAccountsIBs('1019', false)),
            ref(sumAccountsIBs('1019', false, 'previousYear'))
          )
          .addRow(
            '7',
            noteLabel('Försäljningar/utrangeringar'),
            ref(sumPositiveTransactions('1019')),
            ref(sumPositiveTransactions('1019', 'previousYear'))
          )
          .addRow(
            '8',
            noteLabel('Omklassificeringar'), // no accounts
            value(undefined),
            value(undefined)
          )
          .addRow(
            '9',
            noteLabel('Årets avskrivningar'),
            ref(sumNegativeTransactions('1019')),
            ref(sumNegativeTransactions('1019', 'previousYear'))
          )
          .addRow(
            '10',
            noteLabel('Utgående ackumulerade avskrivningar'),
            sumNotesRowsById(43, range(6, 10), 'year'),
            sumNotesRowsById(43, range(6, 10), 'previousYear')
          )
          .addRow(
            '11',
            noteLabel('Ingående nedskrivningar'),
            ref(sumAccountsIBs('1018', false)),
            ref(sumAccountsIBs('1018', false, 'previousYear'))
          )
          .addRow(
            '12',
            noteLabel('Försäljningar/utrangeringar'),
            ref(sumPositiveTransactions('1018')),
            ref(sumPositiveTransactions('1018', 'previousYear'))
          )
          .addRow(
            '13',
            noteLabel('Återförd nedskrivning'),
            value(undefined), // no accounts
            value(undefined)
          )
          .addRow(
            '14',
            noteLabel('Omklassificeringar'),
            value(undefined), // no accounts
            value(undefined)
          )
          .addRow(
            '15',
            noteLabel('Årets nedskrivningar'),
            ref(sumNegativeTransactions('1018')),
            ref(sumNegativeTransactions('1018', 'previousYear'))
          )
          .addRow(
            '16',
            noteLabel('Utgående ackumulerade nedskrivningar'),
            sumNotesRowsById(43, range(11, 16), 'year'),
            sumNotesRowsById(43, range(11, 16), 'previousYear')
          )
          .addRow(
            '17',
            noteLabel('Utgående redovisat värde'),
            sumNotesRowsById(43, [5, 10, 16], 'year'),
            sumNotesRowsById(43, [5, 10, 16], 'previousYear')
          )
          .addRow(
            '18',
            noteLabel(
              'Tillgångar anskaffade med offentligt bidrag ingår med redovisat anskaffningsvärde'
            ),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '19',
            noteLabel(
              'I ovanstående redovisat värde ingår leasade tillgångar med'
            ),
            value(undefined),
            value(undefined)
          )
          .build()
      )
      .newRowTemplate(value(''), value(undefined), value(undefined))
      .build(),
    commentLabel: field('Kommentar'),
    comment: field(''),
    group: value(undefined),
    section: value(undefined),
    validForReportType: {
      k2: true,
      k3: documentTypeVersion === '1',
    },
  };
};

import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';

import { getApiSdk, asResultClass } from 'api-sdk';
import { getContext } from 'utils/AgoyAppClient/contextHolder';
import { RootState } from 'redux/reducers';
import { setClientChecklists } from '_clients/redux/customers/actions';
import { ClientYearAction } from 'redux/actions';
import { AccountingAccount } from '@agoy/api-sdk-core';

import {
  SET_CHECKLIST_QUESTION_STATUS,
  SET_CHECKLIST_STATUSES,
  SET_CHECKLIST_PROGRAM,
  TOGGLE_INCLUDE_CHECKLIST_TO_REPORT,
} from '../action-types';
import {
  ChecklistStatuses,
  Program,
  Checklist,
  ChecklistItemStatus,
  ChecklistTemplate,
} from '../types';
import { createChecklistPeriodsFromTemplate, getFinancialYear } from '../utils';

interface SetChecklistProgramAction extends ClientYearAction {
  type: typeof SET_CHECKLIST_PROGRAM;
  program: Program;
  checklist: Checklist;
}

export const setChecklistProgram = (
  clientId: string,
  financialYear: string,
  program: Program,
  checklist: Checklist
): SetChecklistProgramAction => ({
  type: SET_CHECKLIST_PROGRAM,
  clientId,
  financialYear,
  program,
  checklist,
});

export const assignChecklistToClient =
  (
    clientId: string,
    financialYear: string,
    accounts: AccountingAccount[],
    checklist: ChecklistTemplate
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    const sdk = getApiSdk(getContext());
    const { rawFinancialYears } = getState().customers[clientId];
    const rawFinancialYear = getFinancialYear(rawFinancialYears, financialYear);

    if (!rawFinancialYear?.periods) {
      return;
    }

    const checklists = getState().customers[clientId]?.checklists || [];

    if (checklist.id) {
      const result = await asResultClass(
        sdk.assignChecklistToClient({
          clientid: clientId,
          requestBody: {
            templateId: checklist.id,
            // @ts-ignore
            financialYearId: rawFinancialYear.id,
          },
        })
      );

      if (result.ok) {
        dispatch(
          setClientChecklists(clientId, [
            // @ts-ignore
            ...checklists.filter(
              (checklistTemplate) =>
                !(
                  checklistTemplate.program === checklist.program &&
                  checklistTemplate.financialYearId === rawFinancialYear.id
                )
            ),
            {
              // @ts-ignore
              financialYearId: rawFinancialYear.id,
              templateId: checklist.id,
              program: checklist.program,
            },
          ])
        );

        dispatch(
          setChecklistProgram(
            clientId,
            financialYear,
            checklist.program,
            createChecklistPeriodsFromTemplate(
              checklist,
              rawFinancialYear.periods,
              accounts
            )
          )
        );
      }
    }
  };

interface SetChecklistStatusesAction extends ClientYearAction {
  type: typeof SET_CHECKLIST_STATUSES;
  statuses: ChecklistStatuses;
}

export const setChecklistStatuses = (
  clientId: string,
  financialYear: string,
  statuses: ChecklistStatuses
): SetChecklistStatusesAction => ({
  type: SET_CHECKLIST_STATUSES,
  clientId,
  financialYear,
  statuses,
});

export const fetchChecklistStatuses =
  (
    clientId: string,
    financialYear: string
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    const sdk = getApiSdk(getContext());

    const { rawFinancialYears } = getState().customers[clientId];
    const rawFinancialYear = getFinancialYear(rawFinancialYears, financialYear);

    if (rawFinancialYear) {
      const result = await asResultClass(
        sdk.getCheckListItemStatus({
          financialYearId: `${rawFinancialYear.id}`,
          clientid: clientId,
        })
      );

      if (result.ok) {
        dispatch(setChecklistStatuses(clientId, financialYear, result.val));
      }
    }
  };

export const getChecklistForProgram =
  (
    clientId: string,
    financialYear: string,
    program: Program,
    accounts: AccountingAccount[]
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    const { rawFinancialYears } = getState().customers[clientId];
    const templates = getState().organisation.checklists;
    const rawFinancialYear = getFinancialYear(rawFinancialYears, financialYear);

    if (!rawFinancialYear?.periods) {
      return;
    }

    const checklists = getState().customers[clientId]?.checklists || [];

    let assignedChecklist: ChecklistTemplate | undefined;
    let includeInPrint = true;

    for (let index = 0; index < checklists.length; index += 1) {
      const clientChecklist = checklists[index];
      const result = templates.find(
        (item) =>
          item.id === clientChecklist.templateId && item.program === program
      );

      if (result && clientChecklist.financialYearId === rawFinancialYear.id) {
        assignedChecklist = result;
        includeInPrint = clientChecklist.includeInPrint;
        break;
      }
    }

    if (assignedChecklist) {
      dispatch(
        setChecklistProgram(
          clientId,
          financialYear,
          program,
          createChecklistPeriodsFromTemplate(
            assignedChecklist,
            rawFinancialYear.periods,
            accounts,
            includeInPrint
          )
        )
      );
    } else {
      const activeChecklist = getState().organisation.checklists.find(
        (item) => item.program === program && item.clientIds.includes(clientId)
      );

      if (activeChecklist) {
        dispatch(
          assignChecklistToClient(
            clientId,
            financialYear,
            accounts,
            activeChecklist
          )
        );
      }
    }
  };

interface SetChecklistQuestionStatusAction extends ClientYearAction {
  type: typeof SET_CHECKLIST_QUESTION_STATUS;
  periodId: number;
  questionId: number;
  status: ChecklistItemStatus;
}

export const setChecklistQuestionStatus = (
  clientId: string,
  financialYear: string,
  periodId: number,
  questionId: number,
  status: ChecklistItemStatus
): SetChecklistQuestionStatusAction => ({
  type: SET_CHECKLIST_QUESTION_STATUS,
  clientId,
  financialYear,
  periodId,
  questionId,
  status,
});

export const updateChecklistQuestionStatus =
  (
    clientId: string,
    financialYear: string,
    periodId: number,
    questionId: number,
    status: ChecklistItemStatus
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    const sdk = getApiSdk(getContext());

    const { rawFinancialYears } = getState().customers[clientId];
    const rawFinancialYear = getFinancialYear(rawFinancialYears, financialYear);

    if (rawFinancialYear) {
      const result = await asResultClass(
        sdk.setCheckListItemStatus({
          clientid: clientId,
          requestBody: [
            {
              // @ts-ignore
              financialYearId: rawFinancialYear.id,
              status,
              questionId,
              periodId,
              clientId,
            },
          ],
        })
      );

      if (result.ok) {
        dispatch(
          setChecklistQuestionStatus(
            clientId,
            financialYear,
            periodId,
            questionId,
            status
          )
        );
      }
    }
  };

interface ToggleIncludeChecklistToReportAction extends ClientYearAction {
  type: typeof TOGGLE_INCLUDE_CHECKLIST_TO_REPORT;
  program: Program;
}

export const toggleIncludeChecklistToReport = (
  clientId: string,
  financialYear: string,
  program: Program
): ToggleIncludeChecklistToReportAction => ({
  type: TOGGLE_INCLUDE_CHECKLIST_TO_REPORT,
  clientId,
  financialYear,
  program,
});

export type ClientChecklistActions =
  | SetChecklistProgramAction
  | SetChecklistStatusesAction
  | SetChecklistQuestionStatusAction
  | ToggleIncludeChecklistToReportAction;

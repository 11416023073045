import { AgoyDocument, AgoyDocumentChanges } from '@agoy/document';
import { balanceSheet } from '../common/types/balanceSheet';
import { confirmationCertificate } from '../common/types/confirmationCertificate';
import { incomeStatement } from '../common/types/incomeStatement';
import { manualSubmission } from '../common/types/manualSubmission';
import { settings } from '../common/types/settings';
import { individualCompanyNotes } from './notes/types';
import { individualCompanySignatures } from './signatures/individualCompanySignatures';

export const individualCompanyContentDefinition = {
  type: 'document' as const,
  children: {
    settings,
    confirmationCertificate,
    incomeStatement,
    balanceSheet,
    notes: individualCompanyNotes,
    manualSubmission,
    signatures: individualCompanySignatures,
  },
  documentType: 'individual' as const,
  version: '1' as const,
};

export const individualCompanyUnsortedNotes = [
  'note1',
  // NOTE 56 is like note1 it does not belong to balance sheet or income statement
  'note56',
];

export const individualCompanyBalanceSheetNotes = [
  'note6',
  'note7',
  'note8',
  'note9',
  'note10',
  'note11',
  'note12',
  'note13',
  'note14',
  'note15',
];

export const individualCompanyOtherNotes = ['custom'];

export type IndividualCompanyAnnualReport = AgoyDocument<
  typeof individualCompanyContentDefinition
>;

export type IndividualCompanyAnnualReportStructure =
  typeof individualCompanyContentDefinition;

export type IndividualCompanyAnnualReportChanges = AgoyDocumentChanges<
  typeof individualCompanyContentDefinition
>;

import React from 'react';
import useAutosizeTextArea from '_shared/components/Inputs/v2/hooks/useAutosizeTextArea';

export type TextAreaType = React.DetailedHTMLProps<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
>;
interface AutosizeTextAreaProps extends TextAreaType {
  as: React.ComponentType<TextAreaType>;
  selectTextOnFocus?: boolean;
}

const AutosizeTextArea = (props: AutosizeTextAreaProps) => {
  const { value, as: TextArea, selectTextOnFocus, ...rest } = props;
  const textAreaRef = useAutosizeTextArea(value);

  const selectText = () => {
    if (selectTextOnFocus) {
      textAreaRef.current?.select();
    }
  };

  return (
    <TextArea
      {...rest}
      value={value}
      rows={1}
      wrap="soft"
      ref={textAreaRef}
      onFocus={selectText}
    />
  );
};

export default AutosizeTextArea;

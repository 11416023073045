import { intl } from '_shared/HOC/withIntl';

export const checkForZeros = (num: string | number) => {
  if (num === '0' || num === 0) {
    return '0,00';
  }
  return num;
};

export const checkForNumbers = (str: string | number) => {
  if (typeof str === 'number') {
    return true;
  }
  return /[0-9]/.test(str);
};

export const calculateDebtCategorySum = (data) => {
  return data?.reduce((prev, current) => prev + current, 0);
};

export const calculateTotalDebtSum = (data) => {
  const total = calculateDebtCategorySum(data);
  const newTotal = (31.42 / 100) * total;
  const doubleTotal = total + newTotal;
  return [doubleTotal, newTotal, total];
};

export const debtHeaders = [
  {
    id: intl.formatMessage({
      id: 'reconciliation.hidden_row.semesterskuld.preview.employeeId',
    }),
    align: 'left',
  },
  {
    id: intl.formatMessage({
      id: 'reconciliation.hidden_row.semesterskuld.preview.employeeName',
    }),
    align: 'left',
  },
  { id: '', align: 'left' },
  {
    id: intl.formatMessage({
      id: 'reconciliation.hidden_row.semesterskuld.preview.days',
    }),
    align: 'right',
  },
  {
    id: intl.formatMessage({
      id: 'reconciliation.hidden_row.semesterskuld.preview.moving',
    }),
    align: 'right',
  },
  {
    id: intl.formatMessage({
      id: 'reconciliation.hidden_row.semesterskuld.preview.holiday',
    }),
    align: 'right',
  },
  {
    id: intl.formatMessage({
      id: 'reconciliation.hidden_row.semesterskuld.preview.holidayDebt',
    }),
    align: 'right',
  },
];

export const summary = [
  {
    title: intl.formatMessage({
      id: 'reconciliation.hidden_row.semesterskuld.preview.totalDebtAdvance',
    }),
    value: 0,
  },
  {
    title: intl.formatMessage({
      id: 'reconciliation.hidden_row.semesterskuld.preview.totalWageSaved',
    }),
    value: 0,
  },
  {
    title: intl.formatMessage({
      id: 'reconciliation.hidden_row.semesterskuld.preview.totalVariableUnused',
    }),
    value: 0,
  },
  {
    title: intl.formatMessage({
      id: 'reconciliation.hidden_row.semesterskuld.preview.totalWageUnused',
    }),
    value: 0,
  },
  {
    title: intl.formatMessage({
      id: 'reconciliation.hidden_row.semesterskuld.preview.totalVariableEarned',
    }),
    value: 0,
  },
  {
    title: intl.formatMessage({
      id: 'reconciliation.hidden_row.semesterskuld.preview.totalWageEarned',
    }),
    value: 0,
  },
];

export const totalSummary = [
  {
    title: intl.formatMessage({
      id: 'reconciliation.hidden_row.semesterskuld.preview.totalSummWithPercent',
    }),
    value: 0,
  },
  {
    title: intl.formatMessage({
      id: 'reconciliation.hidden_row.semesterskuld.preview.totalWithPercent',
    }),
    value: 0,
  },
  {
    title: intl.formatMessage({
      id: 'reconciliation.hidden_row.semesterskuld.preview.totalSemesterskuld',
    }),
    value: 0,
  },
];

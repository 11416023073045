import {
  TaxDeclarationFormDefinition,
  TaxDeclarationFormDataConfig,
  PersonMetaData,
} from '../../types';
import { id, ifOrElse, max, min, multiply, ref, value } from '@agoy/document';
import { mapFormId, toInitial, toStructure } from '../forms-util';

const configK13 = (
  form: TaxDeclarationFormDefinition,
  person: PersonMetaData
): TaxDeclarationFormDataConfig => {
  const name = `${person?.firstName || ''} ${person?.lastName || ''}`;
  const K13 = mapFormId(form, 'K13');
  const initial = {
    K13: {
      partType: 'form' as const,
      id: K13,
      fields: {
        datum: value(''),
        numrering_vid_flera_k13: value(undefined),
        namn: value(name),
        person_or_orgnumber: value(person.personNumber || ''),
        A_1_antal: value(undefined),
        A_1_beteckning: value(''),
        A_1a_1_1_ersattning: value(undefined),
        A_1a_1_2_vinst: value(undefined),
        A_1a_1_3_ersattning: value(undefined),
        A_1a_1_4_vinst: value(undefined),
        A_1a_1_5_positivt: value(undefined),
        A_1a_1_6_framskjuten: value(undefined),
        A_1b_1_7_ersattning: value(undefined),
        A_1b_1_8_sparat: value(undefined),
        A_1b_1_9_kapitalvinst: value(undefined),
        A_1b_1_10_beloppet: value(undefined),
        A_1b_1_11_beloppet: value(undefined),
        A_1b_1_12_ersattning: value(undefined),
        A_1c_tjanstebelopp: value(undefined),
        A_2_antal: value(undefined),
        A_2_beteckning: value(''),
        A_2_organisationsnummer: value(''),
        A_3_antal: value(undefined),
        A_3_beteckning: value(''),
        A_3_uppskov_kapital: value(undefined),
        A_3_uppskov_tjanst: value(undefined),
        B_1_namn: value(''),
        B_1_organisationsnummer: value(''),
        B_2_antal: value(undefined),
        B_2_beteckning: value(''),
        B_2_organisationsnummer: value(''),
        B_2_tjanstebelopp_kapitalvinst: value(undefined),
        B_2_tjanstebelopp_utdelning: value(undefined),
        C_1_antal: value(undefined),
        C_1_beteckning: value(''),
        C_1_ersattning: value(undefined),
        C_1_anskaffningsutgift: value(undefined),
        C_1_vinst_or_forlust_kapital: value(undefined),
        C_1_vinst_or_forlust_tjanst: value(undefined),
        C_1_tidigare_uppskov_kapital: value(undefined),
        C_1_tidigare_uppskov_tjanst: value(undefined),
        C_1_vinst_forlust_fors_ink1: value(undefined),
        C_1_inkomst_av_tjanst: value(undefined),
        C_2_antal: value(undefined),
        C_2_beteckning: value(''),
        C_2_uppskovbelopp_kapital: value(undefined),
        C_2_uppskovbelopp_tjanst: value(undefined),
        C_2_totalt_kapital: value(undefined),
        C_2_totalt_tjanst: value(undefined),
        C_2_vinst_kapital: value(undefined),
        C_2_inkomst_tjanst: value(undefined),
      },
      derivedFields: {
        A_1a_ersattning: ref(max(0, id('K13.fields.A_1a_1_3_ersattning'))),
        C_1_vinst_to_7_4: ref(
          ifOrElse(
            max(0, id('K13.fields.C_1_vinst_forlust_fors_ink1')),
            id('K13.fields.C_1_vinst_or_forlust_kapital'),
            undefined
          )
        ),
        C_1_forlust_to_8_3: ref(
          ifOrElse(
            min(0, id('K13.fields.C_1_vinst_forlust_fors_ink1')),
            multiply(-1, id('K13.fields.C_1_vinst_or_forlust_kapital')),
            undefined
          )
        ),
      },
      sru: {
        '7014': ref(id('K13.fields.numrering_vid_flera_k13')),
        '5400': ref(id('K13.fields.A_1_antal')),
        '5401': ref(id('K13.fields.A_1_beteckning')),
        '5610': ref(id('K13.fields.A_1a_1_1_ersattning')),
        '5402': ref(id('K13.fields.A_1a_1_2_vinst')),
        '5611': ref(id('K13.fields.A_1a_1_3_ersattning')),
        '5403': ref(id('K13.fields.A_1a_1_4_vinst')),
        '5612': ref(id('K13.fields.A_1a_1_5_positivt')),
        '5406': ref(id('K13.fields.A_1a_1_6_framskjuten')),
        '5613': ref(id('K13.fields.A_1b_1_7_ersattning')),
        '5614': ref(id('K13.fields.A_1b_1_8_sparat')),
        '5615': ref(id('K13.fields.A_1b_1_9_kapitalvinst')),
        '5616': ref(id('K13.fields.A_1b_1_10_beloppet')),
        '5617': ref(id('K13.fields.A_1b_1_11_beloppet')),
        '5618': ref(id('K13.fields.A_1b_1_12_ersattning')),
        '5407': ref(id('K13.fields.A_1c_tjanstebelopp')),
        '5410': ref(id('K13.fields.A_2_antal')),
        '5411': ref(id('K13.fields.A_2_beteckning')),
        '5412': ref(id('K13.fields.A_2_organisationsnummer')),
        '5420': ref(id('K13.fields.A_3_antal')),
        '5421': ref(id('K13.fields.A_3_beteckning')),
        '5422': ref(id('K13.fields.A_3_uppskov_kapital')),
        '5423': ref(id('K13.fields.A_3_uppskov_tjanst')),
        '5430': ref(id('K13.fields.B_1_namn')),
        '5431': ref(id('K13.fields.B_1_organisationsnummer')),
        '5440': ref(id('K13.fields.B_2_antal')),
        '5441': ref(id('K13.fields.B_2_beteckning')),
        '5442': ref(id('K13.fields.B_2_organisationsnummer')),
        '5443': ref(id('K13.fields.B_2_tjanstebelopp_kapitalvinst')),
        '5444': ref(id('K13.fields.B_2_tjanstebelopp_utdelning')),
        '5500': ref(id('K13.fields.C_1_antal')),
        '5501': ref(id('K13.fields.C_1_beteckning')),
        '5502': ref(id('K13.fields.C_1_ersattning')),
        '5503': ref(id('K13.fields.C_1_anskaffningsutgift')),
        '5504': ref(id('K13.fields.C_1_vinst_or_forlust_kapital')),
        '5505': ref(id('K13.fields.C_1_vinst_or_forlust_tjanst')),
        '5506': ref(id('K13.fields.C_1_tidigare_uppskov_kapital')),
        '5507': ref(id('K13.fields.C_1_tidigare_uppskov_tjanst')),
        '5508': ref(id('K13.fields.C_1_vinst_forlust_fors_ink1')),
        '5509': ref(id('K13.fields.C_1_inkomst_av_tjanst')),
        '5600': ref(id('K13.fields.C_2_antal')),
        '5601': ref(id('K13.fields.C_2_beteckning')),
        '5602': ref(id('K13.fields.C_2_uppskovbelopp_kapital')),
        '5603': ref(id('K13.fields.C_2_uppskovbelopp_tjanst')),
        '5604': ref(id('K13.fields.C_2_totalt_kapital')),
        '5605': ref(id('K13.fields.C_2_totalt_tjanst')),
        '5606': ref(id('K13.fields.C_2_vinst_kapital')),
        '5608': ref(id('K13.fields.C_2_inkomst_tjanst')),
      },
    },
  };
  return {
    initial: toInitial(initial, form),
    definition: toStructure(initial, form),
  };
};

export default configK13;

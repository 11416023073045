import { parse } from '@agoy/dates';
import { isAfter } from 'date-fns';

export const getCenturyFromYYMMDD = (yymmdd: string): string => {
  const month = parseInt(yymmdd.substring(2, 4), 10);
  if (month > 12) {
    // Companies has "month" > 12 and use 16 as century
    return '16';
  }
  if (isAfter(parse(`20${yymmdd}`, 'yyyyMMdd'), Date.now())) {
    // Must be 19 or unborn
    return '19';
  }
  return '20';
};

/**
 * Normalizes an organisation number to standard YYYYMMDDNNNN or YYYYMMDD-NNNN
 *
 * @param orgNr format 10 or 12-digits, with or without a dash
 * @param withDash optional, include dash. Defaults to false.
 */

export const normalizeOrganisationNumber = (
  orgNr: string,
  withDash = false
): string => {
  const match = /^(16|18|19|20|)?(\d{6})[-–]?(\d{4})$/g.exec(orgNr.trim());
  /**
   * !: That's the place where empty organisation or personal number will always fail;
   * TODO: Need to find a solution for allow empty field (like optional on frontend part);
   */
  if (!match) {
    throw new Error(`Invalid organisation number ${orgNr}`);
  }
  const century = match[1] || getCenturyFromYYMMDD(match[2]);
  return `${century}${match[2]}${withDash ? '-' : ''}${match[3]}`;
};

export const formatOrganisationNumber = (
  orgNr: string,
  withDash = false,
  century: 'always' | 'ifNeeded' | 'never' = 'always'
): string => {
  const match = /^(16|18|19|20|)?(\d{6})[-–]?(\d{4})$/g.exec(orgNr.trim());
  if (!match) {
    return orgNr;
  }
  if (century === 'never') {
    return `${match[2]}${withDash ? '-' : ''}${match[3]}`;
  }
  if (century === 'always') {
    const centuryValue = match[1] || getCenturyFromYYMMDD(match[2]);
    return `${centuryValue}${match[2]}${withDash ? '-' : ''}${match[3]}`;
  }

  const inferedCentury = getCenturyFromYYMMDD(match[2]);
  const centuryValue = !match[1] || match[1] === inferedCentury ? '' : match[1];

  return `${centuryValue}${match[2]}${withDash ? '-' : ''}${match[3]}`;
};

/**
 * Check if a given number is valid according to Luhn algorithm
 * @param {string} orgNumber can contain `-` chars
 */
export const isValidOrgNumber = (orgNumber: string): boolean => {
  if (typeof orgNumber !== 'string') return false;

  let normalisedOrgNumber: string;
  try {
    normalisedOrgNumber = normalizeOrganisationNumber(orgNumber);
  } catch {
    return false;
  }

  let sum = 0;
  let doubleUpDigit = false;

  if (normalisedOrgNumber.length === 12) {
    const century = normalisedOrgNumber.substring(0, 2);
    if (century !== '16' && century !== '19' && century !== '20') {
      return false;
    }
  }
  const last10digits = normalisedOrgNumber.substring(
    normalisedOrgNumber.length - 10
  );

  /* from the right to left, double every other digit starting with the second to last digit. */
  for (let i = last10digits.length - 1; i >= 0; i--) {
    const currentDigit = parseInt(last10digits.charAt(i), 10);

    /* double every other digit starting with the second to last digit */
    if (doubleUpDigit) {
      /* doubled number is greater than 9 than subtracted 9 */
      if (currentDigit * 2 > 9) {
        sum += currentDigit * 2 - 9;
      } else {
        sum += currentDigit * 2;
      }
    } else {
      sum += currentDigit;
    }
    doubleUpDigit = !doubleUpDigit;
  }

  /* sum and divide it by 10. If the remainder equals zero, the number is valid.  */
  return sum % 10 === 0;
};

export const isValidSharesCompanyOrgNumber = (orgNr: string): boolean => {
  try {
    const normalised = normalizeOrganisationNumber(orgNr);
    const orgNumber = formatOrganisationNumber(normalised).substring(2);
    const firstDigit = parseInt(orgNumber.charAt(0), 10);
    const month = parseInt(orgNumber.substring(2, 4));

    if (firstDigit !== 5) {
      return false;
    }

    return month >= 20;
  } catch {
    return false;
  }
};

import React, { useCallback, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { addGlobalErrorMessage } from 'redux/actions';
import { LoaderFunction } from '_shared/components/ClientLoaderContext';
import { UserError } from 'types/Error/types';
import { ClientDataLayerContext } from 'data/client/ClientDataLayerContext';
import { setAccountingBalances } from '_reconciliation/redux/accounting-view/actions';
import { isApiErrorType } from 'api-sdk';
import BaseLoader from './BaseLoader';

/**
 * AccountingBalancesLoader
 *
 * Loads the sie data for a specific client and year.
 */
const AccountingBalancesLoader = (): JSX.Element => {
  const dispatch = useDispatch();
  const clientDataLayerContext = useContext(ClientDataLayerContext);

  const loader: LoaderFunction = useCallback(
    async (clientId, companyType, financialYear) => {
      if (!financialYear) {
        throw new Error('Expected a financial year');
      }
      const clientDataLayer = clientDataLayerContext.get(clientId);
      try {
        const subscription = clientDataLayer.accountingBalances
          .getAccountingBalances(financialYear)
          .subscribe((result) => {
            if (result.ok) {
              dispatch(
                setAccountingBalances(
                  clientId,
                  financialYear,
                  result.val ? result.val.accountingBalances : null,
                  result.val ? result.val.updatedAt : Date.now()
                )
              );
            } else {
              // eslint-disable-next-line no-console
              console.error(result.val);
              dispatch(addGlobalErrorMessage('error'));
            }
            subscription.unsubscribe();
          });
      } catch (error) {
        if (isApiErrorType(error)) {
          if (error.status === 403) {
            throw new UserError('sie.error.unauthorized');
          }
        }
      } finally {
        clientDataLayerContext.release(clientDataLayer);
      }
    },
    [dispatch, clientDataLayerContext]
  );

  return <BaseLoader id="accountingBalances" scope="year" loader={loader} />;
};

export default AccountingBalancesLoader;

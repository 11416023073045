export const SET_ORGANISATION = 'organisation/SET_ORGANISATION';
export const INSERT_INVITE_TO_ORG = 'organisation/INSERT_INVITE_TO_ORG';
export const ADD_ORGANISATION_INFO = 'organisation/ADD_ORGANISATION_INFO';
export const REMOVE_INVITE = 'organisation/REMOVE_INVITE';
export const REMOVE_MEMBER = 'organisation/REMOVE_MEMBER';
export const SET_CHECKLISTS = 'organisation/SET_CHECKLISTS';
export const ADD_CHECKLIST = 'organisation/ADD_CHECKLIST';
export const REMOVE_CHECKLIST = 'organisation/REMOVE_CHECKLIST';
export const EDIT_CHECKLIST = 'organisation/EDIT_CHECKLIST';
export const SET_SETTINGS = 'organisation/SET_SETTINGS';

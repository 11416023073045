import React from 'react';
import { IconButton, Tooltip, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from '@material-ui/icons/Search';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';

import { SimplifiedTextfield } from '_shared/components/Inputs';
import Button from '_shared/components/Buttons/Button';

const ErrorText = styled(Typography)`
  color: ${(props) => props.theme.palette.error.main};
  background-color: ${(props) => props.theme.palette.common.white};
`;

const Overlay = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: ${(props) => props.theme.palette.background.default}95;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Buttons = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledTextField = styled(SimplifiedTextfield)`
  input {
    background-color: ${(props) => props.theme.palette.common.white};
    border-radius: 4px;
  }
`;

const ControlButtons = styled.div<{ column?: boolean }>`
  display: flex;
  flex-direction: ${({ column }) => (column ? 'column' : 'row')};
  justify-content: ${({ column }) => (column ? 'end' : 'center')};
  align-items: center;
  height: 100%;
`;

interface DocumentOverlayProps {
  currentDocumentName: string;
  documentName: string;
  errorMessage: string;
  isLoading: boolean;
  onDocumentNameChange: (newDocumentName: string) => void;
  onOpenDocumentModal: () => void;
  onDeleteDocument: () => Promise<void>;
  onDocumentNameSave: (newDocumentName: string) => Promise<void>;
  extraContent?: JSX.Element;
  columnButtons?: boolean;
  deleteDisabled?: boolean;
  editNameDisabled?: boolean;
}

const DocumentOverlay = ({
  currentDocumentName,
  documentName,
  errorMessage,
  isLoading,
  onDocumentNameChange,
  onOpenDocumentModal,
  onDeleteDocument,
  onDocumentNameSave,
  extraContent,
  columnButtons = false,
  deleteDisabled = false,
  editNameDisabled = false,
}: DocumentOverlayProps): JSX.Element => {
  const { formatMessage } = useIntl();

  return (
    <Overlay>
      <Buttons>
        <ControlButtons column={columnButtons}>
          {!isLoading && (
            <Tooltip title={formatMessage({ id: 'remove' })}>
              <div>
                <IconButton
                  color="primary"
                  onClick={onDeleteDocument}
                  disabled={deleteDisabled}
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            </Tooltip>
          )}
          <Tooltip title={formatMessage({ id: 'moreDetails' })}>
            <IconButton color="primary" onClick={onOpenDocumentModal}>
              <SearchIcon />
            </IconButton>
          </Tooltip>
        </ControlButtons>
        {extraContent}
      </Buttons>

      <Tooltip
        title={
          isLoading
            ? formatMessage({ id: 'hidden.uploadingImage' })
            : formatMessage({ id: 'hidden.nameImage' })
        }
      >
        <div>
          {errorMessage && <ErrorText>{errorMessage}</ErrorText>}

          <StyledTextField
            variant="outlined"
            value={documentName}
            onChange={(e) => {
              onDocumentNameChange(e.target.value);
            }}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                onDocumentNameSave(documentName);
              }
            }}
            editing
            disabled={editNameDisabled || isLoading}
            error={errorMessage.length > 0}
          />
        </div>
      </Tooltip>

      {!editNameDisabled && (
        <Button
          label={formatMessage({ id: 'save' })}
          loading={isLoading}
          onClick={() => {
            onDocumentNameSave(documentName);
          }}
          disabled={currentDocumentName === documentName}
        />
      )}
    </Overlay>
  );
};

export default DocumentOverlay;

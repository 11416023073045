import React from 'react';
import styled from '@emotion/styled';
import { Status } from '_shared/types';
import { colors as ThemeColors } from '@agoy/theme';
import { LampValue } from '_clients/types/types';
import { getStatusColor } from 'utils';

interface ShapeElementProps {
  background: string;
}

const ShapeElement = styled.div`
  background: ${(props: ShapeElementProps) => props.background};
  height: 8px;
  width: 100%;
  border-radius: 2px;
`;

const getColor = (periodStatus: Status, groupStatus: LampValue) => {
  if (periodStatus === 'STARTED' && groupStatus === 'wrong') {
    return getStatusColor(groupStatus);
  }

  return ThemeColors[periodStatus];
};

interface PeriodShapeProps {
  periodStatus: Status;
  groupStatus: LampValue;
}

const PeriodShape = ({ periodStatus, groupStatus }: PeriodShapeProps) => {
  return <ShapeElement background={getColor(periodStatus, groupStatus)} />;
};

export default PeriodShape;

import React from 'react';
import { Typography } from '@material-ui/core';
import { AgoyTable, stringValue } from '@agoy/document';
import { useIntl } from 'react-intl';
import Paragraph from './Paragraph';
import NameTable from './NameTable';

interface BoardMembersParagraphProps {
  no: number;
  table: AgoyTable;
  heading: string | undefined;
  text: string | undefined;
  print?: boolean;
  isPreview?: boolean;
  editable?: boolean;
}

const BoardMembersParagraph = ({
  no,
  table,
  print,
  heading,
  text,
  isPreview,
  editable,
}: BoardMembersParagraphProps): JSX.Element => {
  const { formatMessage } = useIntl();

  return (
    <Paragraph
      no={no}
      id="11"
      headingId="annualGeneralMeeting.main.boardMembersHeading"
      heading={
        heading ||
        formatMessage(
          {
            id: `annualReport.annualGeneralMeeting.main.11`,
          },
          {
            ceo: table.rows.some(
              (row) =>
                stringValue(row.cells?.role) === 'ceo' ||
                stringValue(row.cells?.role) === 'external_ceo'
            ),
          }
        )
      }
      optionalComment
      defaultText={formatMessage({
        id: `comment`,
      })}
      textId="annualGeneralMeeting.main.boardMembersHeadingText"
      text={text}
      editable={!print && editable}
      isPreview={isPreview}
    >
      {(editing) => (
        <Typography component="div">
          <NameTable
            editing={editing}
            print={print}
            table={table}
            tableId="annualGeneralMeeting.main.boardMembers"
            addLabelId="annualReport.annualGeneralMeeting.add.member"
          />
        </Typography>
      )}
    </Paragraph>
  );
};

export default BoardMembersParagraph;

import React, { useState, useEffect, useRef } from 'react';
import { injectIntl } from 'react-intl';
import { Tooltip, Typography } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import { useSelector } from 'redux/reducers';
import { parseFormat } from '@agoy/dates';
import Button from '../Buttons/Button';

interface FileUploadProps {
  intl: any;
  handleChange: (files: FileList | null) => void;
  showToolTip: boolean;
  sieUpdated: number | null | undefined;
  disabled: boolean;
}

const FileUpload = ({
  intl,
  handleChange,
  showToolTip,
  sieUpdated,
  disabled,
}: FileUploadProps) => {
  const [toolTipOpen, setToolTipOpen] = useState(false);
  const inputField = useRef<HTMLInputElement>(null);

  const sieFileJustUpdated = useSelector(
    (state) => state.customerView.sieFileJustUpdated
  );

  useEffect(() => {
    if (showToolTip) {
      const timer = setTimeout(() => setToolTipOpen(true), 400);
      return () => clearTimeout(timer);
    }
    setToolTipOpen(false);
  }, [showToolTip]);

  useEffect(() => {
    setToolTipOpen(sieFileJustUpdated);
  }, [sieFileJustUpdated]);

  const mouseEnter = () => {
    setToolTipOpen(true);
  };
  const mouseLeave = () => {
    setToolTipOpen(false);
  };

  const tooltipTitle = sieFileJustUpdated ? (
    // Multiline tooltip hack
    <>
      <Typography>
        {intl.formatMessage({ id: 'upload.new.sie.tooltip.title' })}
      </Typography>
      {intl.formatMessage({ id: 'upload.new.sie.tooltip.body' })}
    </>
  ) : sieUpdated ? (
    intl.formatMessage(
      { id: 'upload.file.tooltip.updated' },
      { time: parseFormat(sieUpdated, 'yyyy-MM-dd HH:mm') }
    )
  ) : (
    intl.formatMessage({ id: 'upload.file.tooltip.default' })
  );

  const handledDocumentUpload = () => {
    inputField.current?.click?.();
  };

  return (
    <Tooltip open={toolTipOpen} arrow title={tooltipTitle}>
      <>
        <Button
          label={intl.formatMessage({ id: 'upload.file' })}
          startIcon={<DescriptionIcon />}
          disabled={disabled}
          onMouseEnter={mouseEnter}
          onMouseLeave={mouseLeave}
          onClick={() => {
            handledDocumentUpload();
          }}
        />
        <input
          data-cy="upload-file"
          type="file"
          ref={inputField}
          onChange={(e) => handleChange(e.target.files)}
          style={{ display: 'none' }}
        />
      </>
    </Tooltip>
  );
};

export default injectIntl(FileUpload);

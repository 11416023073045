import React, { useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useSelector } from 'redux/reducers';
import { getOrganisationMembers } from '_organization/redux/actions';
import PersonCard from '_person/components/_organisms/PersonCard';
import {
  getPersons,
  getPersonsProgramStatuses,
} from '_person/redux/persons/actions';
import { PrivatePerson, PrivatePersonWithManager } from '_person/_types/person';
import LoadingPlaceholder from '_shared/components/LoadingPlaceholder';
import CreateFirstPersonView from '_person/components/CreateFirstPersonView';
import { useHistory } from 'react-router-dom';
import { sortBy } from 'lodash';

const Container = styled.div`
  padding-left: ${(props) => props.theme.spacing(3)}px;
  height: 100%;
  margin-bottom: 20px;
  overflow-y: auto;
`;

const NoClientFound = styled.div`
  display: flex;
  margin: 6rem 0 2rem 0;
  text-align: center;
  justify-content: center;
  align-items: center;
`;

const Loading = (): JSX.Element => (
  <Container>
    <LoadingPlaceholder />
  </Container>
);

const ListClientPersons = (): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const intl = useIntl();
  const { search, sortType } = useSelector((state) => state.personsOverview);
  const persons = useSelector((state) => state.persons || {});
  const isFetching = useSelector((state) => state.ui.fetchingPersons);
  const [filteredPersons, setFilteredPersons] = useState<PrivatePerson[]>([]);
  const orgMembers = useSelector((state) => state.organisation.users);
  const hasNoPersons = Object.keys(persons).length === 0;

  const FilterNoResultsFound = (): JSX.Element => (
    <Container>
      <NoClientFound>
        <Typography variant="h3">
          {intl.formatMessage({
            id: 'dashboard.person.search.noResults',
          })}
        </Typography>
      </NoClientFound>
    </Container>
  );

  const handleOnCreatePerson = () => {
    history.push(`/persons/create`);
  };

  useEffect(() => {
    dispatch(getPersons());
    dispatch(getOrganisationMembers(true));
  }, [dispatch]);

  useEffect(() => {
    const personIds = Object.keys(persons);
    if (personIds.length > 0) {
      dispatch(getPersonsProgramStatuses(personIds));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, Object.keys(persons).length]);

  useEffect(() => {
    if (Object.keys(persons).length === 0) {
      return;
    }

    const personsArray = Object.keys(persons)
      .filter(
        (id) =>
          persons[id].firstName.toLowerCase().includes(search.toLowerCase()) ||
          persons[id].lastName.toLowerCase().includes(search.toLowerCase())
      )
      .map((key) => persons[key]);

    const personsWithManager = personsArray.map(
      (p: PrivatePersonWithManager) => ({
        ...p,
        manager: orgMembers.find((m) => m.userId === p.managerId)?.email || '',
      })
    );

    const sortByField = sortType === 'BY_CLIENT_NAME' ? 'firstName' : 'manager';
    const sortedFliteredList = sortBy(personsWithManager, [sortByField], 'asc');

    setFilteredPersons(sortedFliteredList);
  }, [orgMembers, persons, search, sortType]);

  if (isFetching) {
    return <Loading />;
  }

  if (hasNoPersons) {
    return <CreateFirstPersonView onCreate={handleOnCreatePerson} />;
  }

  if (filteredPersons.length === 0) {
    return <FilterNoResultsFound />;
  }

  return (
    <Container>
      {filteredPersons.map((person) => (
        <PersonCard key={person.id} person={person} />
      ))}
    </Container>
  );
};

export default ListClientPersons;

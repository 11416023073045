import React from 'react';
import { AgoyTable, AgoyTableRow } from '@agoy/document';

import { Table } from '../../../../UI/CommonPrintTable';
import {
  flattenRows,
  includeRowWithStringValues,
} from '../../../../utils/utils';

interface Note1TableProps {
  table: AgoyTable | undefined;
  tableId: string;
}

const Note1Table = ({
  table,
  tableId,
}: Note1TableProps): JSX.Element | null => {
  const renderRows = (rows: AgoyTableRow[]) => {
    return flattenRows(rows).filter((row) => includeRowWithStringValues(row));
  };

  if (table == null) {
    return null;
  }

  return (
    <Table
      baseId="annualReport"
      tableId={tableId}
      rows={renderRows(table.rows)}
      columns={table.columns}
    />
  );
};

export default Note1Table;

import React from 'react';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';
import Typography from '_shared/components/Typography/Typography';

const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const UserChanged = () => {
  const intl = useIntl();

  return (
    <Wrapper>
      <Typography variant="h3">
        {intl.formatMessage({ id: 'fortnox.whitelabel.userChanged' })}
      </Typography>
      <Typography variant="body1">
        {intl.formatMessage({ id: 'fortnox.whitelabel.userChanged.text' })}
      </Typography>
    </Wrapper>
  );
};

export default UserChanged;

import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { Box, Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { Table } from '_shared/components/CommonTable';
import { StyledTableCell } from '_shared/components/CommonTable/TableRow';
import { Tag as ClientTag } from '_shared/components/ClientView/ClientTag';
import { AgoyTableRow } from '@agoy/document';
import { ccyFormat } from '@agoy/common';
import { ClientCompanyType } from '_clients/types/types';
import { getFullOwnersList } from '_client-connections/utils';
import { ShareOwners } from '_client-connections/types';
import { getCompanyTypeOptions } from './ConnectionsToCompanyView';

const StyledTable = styled(Table)`
  margin-top: ${({ theme }) => theme.spacing(4)}px;
`;

const CompanySharesOwnersTable = ({
  client,
}: {
  client: ClientCompanyType;
}) => {
  const intl = useIntl();
  const text = (
    id: string,
    values?: Record<string, string | number | undefined>
  ) => intl.formatMessage({ id: `connections.${id}` }, values);

  const columns = [
    { id: 'ownerName', label: text('table.companyOrPersonName') },
    {
      id: 'ownerNumber',
      label: text('detail.orgNumber'),
    },
    {
      id: 'companyType',
      label: text('table.companyType'),
    },
    { id: 'ownedShares', label: text('table.shares') },
    { id: 'sharePercentage', label: text('table.sharesPercent') },
  ];

  const owners = client.shareOwners || [];
  const companyTypesLabels = getCompanyTypeOptions(intl);

  const getCellValue = (owner: ShareOwners, column: string): string => {
    if (column === 'companyType') {
      return owner.companyType ? companyTypesLabels[owner.companyType] : '';
    }

    if (column === 'sharePercentage') {
      return `${ccyFormat(owner.sharePercentage || 0, 2)}%`;
    }

    return owner[column] || '';
  };

  const rows: AgoyTableRow[] = useMemo(() => {
    const ownersList = getFullOwnersList(
      owners,
      client.stockTotals?.[0]?.shareTotal,
      text('shareOwners.otherOwners')
    );

    const tableRows = ownersList.map((item, index) => {
      const cells = {};

      columns.forEach((col) => {
        cells[col.id] = {
          type: 'string',
          value: getCellValue(item, col.id),
        };
      });

      return {
        id: item.ownerNumber,
        active: true,
        cells,
      };
    });

    return tableRows;
  }, [owners, client]);

  return (
    <>
      <Typography variant="h3">
        {text('shareOwners.title', {
          companyName: client.name,
        })}
      </Typography>
      <StyledTable
        baseId="companyConnections"
        tableId="shareOwners"
        columns={columns}
        rows={rows}
        renderCell={({ row, column }) => {
          if (
            column.id === 'companyType' &&
            owners.find((owner) => owner.ownerNumber === row.id)?.ownerType ===
              'P'
          ) {
            return (
              <StyledTableCell
                data-is-column-dragging={false}
                key={`${row.id}.${column.id}`}
              >
                <Box display="flex" justifyContent="flex-end">
                  <ClientTag clientType="person">
                    {intl.formatMessage({
                      id: 'client.view.header.person',
                    })}
                  </ClientTag>
                </Box>
              </StyledTableCell>
            );
          }
          return null;
        }}
      />
    </>
  );
};

export default CompanySharesOwnersTable;

import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';
import { Typography } from '@material-ui/core';
import PrintStateContext from '_shared/components/PrintedDocument/PrintStateContext';
import LagerjusteringTable from './LagerjusteringTable';

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
`;

const TableTitle = styled(Typography)`
  font-size: 1.5rem;
  font-weight: 500;
  color: ${(props) => props.theme.palette.accountingView.headers.main};
`;

const TableHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
`;

const LagerjusteringPreviewPrint = () => {
  const { formatMessage } = useIntl();
  const {
    state: { inventoryObsolescenceData },
  } = useContext(PrintStateContext);

  if (!inventoryObsolescenceData) {
    return null;
  }

  return (
    <TableWrapper>
      <TableHeaderWrapper>
        <TableTitle>
          {formatMessage({
            id: `hidden.lagerjustering.view`,
          })}
        </TableTitle>
      </TableHeaderWrapper>
      <LagerjusteringTable
        {...inventoryObsolescenceData}
        isEditing={false}
        isPrint
      />
    </TableWrapper>
  );
};

export default LagerjusteringPreviewPrint;

import { value } from '@agoy/document';
import {
  CompanyMetaData,
  PersonMetaData,
  TaxDeclarationFormDefinition,
  isCompanyType,
} from '../../types';
import { mapFormId, toConfig } from '../forms-util';
import helpStructureCL from '../../types/help/CL';

const configCoverLetter = (
  form: TaxDeclarationFormDefinition,
  customer: CompanyMetaData | PersonMetaData,
  accountingFirm: string | null | undefined,
  finanicalYearStart: string
) => {
  const isCompany = isCompanyType(customer);
  const year = finanicalYearStart.slice(0, 4);

  const CoverLetter = mapFormId(form, 'CL');

  const initial = {
    CL: {
      partType: 'coverLetter' as const,
      id: CoverLetter,
      fields: {
        name: value(
          isCompany
            ? customer.name
            : `${customer.firstName} ${customer.lastName}`
        ),
        personalNumber: value(
          isCompany ? customer.orgNumber : customer.personNumber
        ),
        taxYear: value(year),

        missionTitle: value('Uppdrag'),
        missionText: value(
          `${accountingFirm} skickar härmed det upprättade förslaget till inkomstdeklaration.`
        ),

        responsibilityTitle: value('Ansvar'),
        responsibilityText: value(
          'Vi vill påminna om att deklarationen är ert ansvar och att du bör granska alla uppgifter i deklarationen noga. Om det finns några frågor eller oklarheter, tveka inte att kontakta oss för att diskutera eventuella ändringar eller korrigeringar.'
        ),

        signatureTitle: value('Underskrift av inkomstdeklaration'),
        signatureOfIncomeDeclaration: value('1'),
        signatureText: value(
          'Byrån signerar efter inkommen deklarationsblankett.'
        ),

        toSkatteverketTitle: value('Inlämning till Skatteverket'),
        toSkatteverketCheckbox1: value(false),
        toSkatteverketCheckbox2: value(false),
        toSkatteverketCheckbox3: value(false),
        toSkatteverketCheckbox4: value(false),
        toSkatteverketText: value(''),

        taxCalculationTitle: value('Skatteberäkning'),
        taxCalculationText: value(
          'Skatteberäkningen som bifogas baseras på uppgifterna i inkomstdeklarationen.'
        ),
        estimatedFinalTax: value(0),
        estimatedTaxRefund: value(0),
        fTax: value(0),

        paymentToSkatteverketTitle: value('Inbetalning till Skatteverket'),
        paymentToSkatteverketText: value(
          'För att undvika räntekostnader rekommenderar vi att du gör en fyllnadsinbetalning till Skatteverket på följande belopp, senast slutdatum.'
        ),

        paymentToSkatteverketAmount: value(0),
        paymentToSkatteverketDate: value(''),

        commentTitle: value('Tack för ert förtroende!'),
        commentText: value(
          `Om du har några frågor eller behöver mer information, tveka inte att kontakta oss.\nDetaljer om uppdraget finns i vårt uppdragsavtal med bilagda allmänna villkor.\n\nVänliga hälsningar,\n${accountingFirm}`
        ),
      },
      help: {
        _type: 'CL' as const,
        mission: value(true),
        missionTitle: value(true),

        responsibility: value(true),
        responsibilityTitle: value(true),

        signature: value(true),
        signatureTitle: value(true),
        signatureRadio: value(true),
        signatureText: value(false),

        toSkatteverket: value(true),
        toSkatteverketTitle: value(true),
        toSkatteverketCheckbox: value(true),
        toSkatteverketText: value(false),

        taxCalculation: value(true),
        taxCalculationTitle: value(true),
        taxCalculationText: value(true),
        taxCalculationTable: value(true),

        paymentToSkatteverket: value(true),
        paymentToSkatteverketTitle: value(true),
        paymentToSkatteverketText: value(true),
        paymentToSkatteverketTable: value(true),

        comment: value(true),
        commentTitle: value(true),
      },
      sru: {},
    },
  };

  return toConfig(initial, form, { CL: helpStructureCL });
};

export default configCoverLetter;

import { RowChange } from '../changes';
import { AgoyDocumentStructure } from '../document';
import { AgoyTable, AgoyTableRow } from '../table';
import { addItem } from './helpers';
import updateTable from './helpers/updateTable';
import updateDocument, { withError } from './helpers/updateDocument';
import { OperationResult, State } from './types';

const deleteColumnInRowChanges = (
  rows: RowChange[],
  columnId: string
): RowChange[] => {
  return rows.map((row) => {
    if (row.type === 'add') {
      let newRow = row;
      if (newRow.row?.cells) {
        const newCells = { ...newRow.row.cells };
        delete newCells[columnId];
        newRow = { ...newRow, row: { ...newRow.row, cells: newCells } };
      }
      return newRow;
    }
    if (row.type === 'update') {
      let newRow = row;
      if (newRow.rows) {
        newRow = {
          ...newRow,
          rows: deleteColumnInRowChanges(newRow.rows, columnId),
        };
      }
      return newRow;
    }
    return row;
  });
};

const deleteTableColumn = <T extends AgoyDocumentStructure>(
  structure: T,
  state: State<T>,
  id: string
): OperationResult<T> => {
  return updateDocument(structure, state, id, {
    table: (key, tableId, props) => {
      const columnId = key?.[0];
      if (!columnId) {
        return withError(props, 'INVALID_ID');
      }
      const updateRow = (row: AgoyTableRow): AgoyTableRow => {
        let newRow = row;
        if (newRow.rows) {
          newRow = { ...newRow, rows: newRow.rows.map(updateRow) };
        }
        if (newRow.cells) {
          const newCells = { ...newRow.cells };
          delete newCells[columnId];
          newRow = {
            ...newRow,
            cells: newCells,
          };
        }
        if (typeof newRow.newRowTemplate === 'object') {
          newRow = {
            ...newRow,
            newRowTemplate: updateRow(newRow.newRowTemplate),
          };
        }
        return newRow;
      };

      const result = updateTable(props.node, props.changes, (table, change) => {
        const existingAddColumnChange = change.columns?.find(
          (c) => c.type === 'add' && c.id === columnId
        );
        let newTable: AgoyTable = {
          ...table,
          columns: table.columns.filter((col) => col.id !== columnId),
          rows: table.rows.map(updateRow),
        };
        if (typeof newTable.newRowTemplate === 'object') {
          newTable = {
            ...newTable,
            newRowTemplate: updateRow(newTable.newRowTemplate),
          };
        }
        if (existingAddColumnChange && existingAddColumnChange.type === 'add') {
          const newColumns = change.columns
            ?.filter((col) => col.id !== columnId)
            .map((col) => {
              // Other add column changes with indexes higher than the one being delete must shift index
              if (
                col.type === 'add' &&
                col.index > existingAddColumnChange.index
              ) {
                return { ...col, index: col.index - 1 };
              }
              return col;
            });
          if (!newColumns || newColumns.length === 0) {
            let newChange = { ...change };
            delete newChange.columns;
            if (newChange.rows) {
              newChange = {
                ...newChange,
                rows: deleteColumnInRowChanges(newChange.rows, columnId),
              };
            }
            return [newTable, newChange];
          }
          const newRows = change.rows
            ? deleteColumnInRowChanges(change.rows, columnId)
            : undefined;

          let newChange = { ...change, columns: newColumns };
          if (newRows) {
            newChange = { ...newChange, rows: newRows };
          }
          return [newTable, newChange];
        }
        return [
          newTable,
          {
            ...change,
            columns: addItem(
              change.columns?.filter((col) => col.id !== columnId),
              { type: 'delete', id: columnId }
            ),
          },
        ];
      });

      if (Array.isArray(result)) {
        return {
          ...props,
          node: result[0],
          changes: result[1],
        };
      }
      if (result) {
        return withError(props, result);
      }
      return props;
    },
  });
};

export default deleteTableColumn;

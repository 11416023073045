import React, { useCallback, useContext, useState } from 'react';
import styled from '@emotion/styled';
import CurrencyField from '_shared/components/Inputs/CurrencyField';
import { GetComponentProps } from '_shared/services/type-helpers';
import { NumberFormatType } from '@agoy/common';
import ServiceContext from '../../Context/TableServiceContext';
import StringInput from './StringInput';

interface NumberFieldProps {
  className?: string;
  id: string;
  value: number | undefined;
  format?: NumberFormatType;
  isField?: boolean;
}

const NumberInput = styled(StringInput)`
  text-align: right;
`;

const NumberInputWithError = styled(NumberInput)`
  color: ${({ theme }) => theme.palette.error.main};
`;

const NumberField = ({
  id,
  value,
  className,
  format,
  isField,
  ...props
}: NumberFieldProps & GetComponentProps<typeof CurrencyField>): JSX.Element => {
  const [hasError, setHasError] = useState(false);
  const service = useContext(ServiceContext);
  const onChange = useCallback(
    (newValue: number | undefined) => {
      const isValidPercentage = () => {
        if (newValue == null) {
          return true;
        }

        if (format?.minValue != null && newValue < format.minValue) {
          return false;
        }

        if (format?.maxValue != null && newValue > format.maxValue) {
          return false;
        }

        return true;
      };

      if (newValue !== value) {
        if (
          (isField === undefined && id.split('.').length > 3) ||
          isField === false
        ) {
          if (format?.type === 'percentage' && !isValidPercentage()) {
            setHasError(true);
            return;
          }

          setHasError(false);
          service?.updateCellValue(id, newValue);
        } else {
          service?.updateField(id, newValue);
        }
      }
    },
    [value, id, format, service, isField]
  );

  return (
    <CurrencyField
      className={className}
      {...props}
      value={value}
      onValueChange={onChange}
      Input={hasError ? NumberInputWithError : NumberInput}
    />
  );
};

export default NumberField;

import { AgoyDocument, AgoyDocumentStructure } from './document';
import expandIds from '../References/expandIds';
import resolveReference from '../References/resolveReference';
import {
  ResolvedReference,
  ResolveReferenceContext,
  ResolveReferenceInput,
  TimePeriod,
} from '../References';
import { collectReferences, collectValues } from './collect';

export const resolveById =
  (
    calculatedReferences: Record<string, ResolvedReference>,
    references: Record<string, string>,
    values: Record<string, ResolvedReference>[]
  ) =>
  (id: string, context: ResolveReferenceContext): ResolvedReference => {
    const source = values.find((v) => id in v);
    if (source) {
      return source[id];
    }
    const ref = references[id];

    if (ref) {
      if (ref in calculatedReferences) {
        return calculatedReferences[ref];
      }
      const refValue = resolveReference(ref, context);
      // eslint-disable-next-line no-param-reassign
      calculatedReferences[ref] = refValue;
      return refValue;
    }
    if (context.parent) {
      return context.parent.resolveById(id, context.parent);
    }
    return { error: 'missingId' };
  };

const EMPTY = {};
export const NO_ACCOUNT_INPUT: ResolveReferenceInput = {
  accountResolver: {
    get: () => ({ error: 'missingPeriod' }),
    sum: () => ({ error: 'missingPeriod' }),
    getName: () => undefined,
  },
  defaultPeriod: new TimePeriod('19700101', '19700101'),
  periods: {},
};

// 4701 TODO rename either this one or the one in annual-report-document
// THE ONE THAT HELPED FIX THE TEST
/**
 * Function to calculate the values of all references in a document.
 *
 * @param definition The structure of the document, @see AgoyDocumentStructure
 * @param data The document to calculate references in
 * @param baseValues Optional, Input values, a map of id to numbers.
 * @param baseReferences Optional, References defined outside of the document.
 * @param input Optional, Input for references to accounts.
 */
export const calculateReferences = <T extends AgoyDocumentStructure>(
  definition: T,
  data: AgoyDocument<T>,
  baseValues?: Record<string, string | number | boolean | undefined>[],
  context?: ResolveReferenceContext,
  baseReferences?: Record<string, string>,
  input?: ResolveReferenceInput
): Record<string, ResolvedReference> => {
  const values: Record<string, ResolvedReference> =
    collectValues(definition)(data);
  const allValues = baseValues ? [...baseValues, values] : [values];

  const references = collectReferences(definition)(
    data,
    baseReferences || EMPTY
  );
  const allIds = [
    ...new Set([...allValues.flatMap(Object.keys), ...Object.keys(references)]),
  ];

  const calculatedReferences: Record<string, ResolvedReference> = {};

  const theContext: ResolveReferenceContext = {
    resolveById: resolveById(calculatedReferences, references, allValues),
    resolveConfig: (name) => context?.resolveConfig(name, context),
    expandIds: expandIds(allIds),
    input: input || NO_ACCOUNT_INPUT,
    parent: context,
  };

  Object.entries(references).forEach(([id, reference]) => {
    if (calculatedReferences[reference] === undefined) {
      const value = resolveReference(reference, theContext);
      values[id] = value;
      calculatedReferences[reference] = value;
    }
  });

  return calculatedReferences;
};

import { ReferenceAccountInformation, TimePeriod } from '@agoy/document';
import {
  AnnualReportCustomerType,
  manualSubmissionConfig,
  confirmationCertificateConfig,
} from '../common';
import { DocumentConfiguration } from '../document';
import {
  IndividualCompanyAnnualReport,
  individualCompanyBalanceSheetNotes,
  individualCompanyOtherNotes,
} from './document';
import {
  balanceSheetConfig,
  balanceSheetReferences,
  incomeStatementConfig,
  incomeStatementReferences,
  settingsConfig,
  notesConfig,
  signaturesConfig,
} from './types';

/**
 * Creates a new configuration of a annual report
 * @param customer
 * @param period  The time period for the financial year's start to
 *                the end of the current month
 * @param previousPeriod  The time period for the previous year's start
 *                        to the end of the current month minus one year
 * @param documentConfiguration Settings that affect the content of the report.
 */

export const individualCompanyGroupedNotes = {
  incomeStatementNotes: [],
  balanceSheetNotes: individualCompanyBalanceSheetNotes,
  otherNotes: individualCompanyOtherNotes,
};

export const individualCompanyConfig = (
  customer: AnnualReportCustomerType,
  period: TimePeriod,
  previousPeriod: TimePeriod | null,
  accounts: Record<string, ReferenceAccountInformation>,
  documentConfiguration: DocumentConfiguration,
  isSinglePeriod: boolean
): IndividualCompanyAnnualReport => {
  const isFirstFinancialYear =
    documentConfiguration.financialYears?.length === 1;

  const currentYear = documentConfiguration.financialYears[0];
  const financialYear = TimePeriod.fromISODates(
    currentYear.start,
    currentYear.end
  ).value;

  return {
    documentType: 'individual',
    version: '1',
    settings: settingsConfig(
      customer,
      financialYear,
      period,
      previousPeriod,
      isFirstFinancialYear,
      isSinglePeriod
    ),
    confirmationCertificate: confirmationCertificateConfig(),
    incomeStatement: incomeStatementConfig(
      accounts,
      period,
      previousPeriod,
      documentConfiguration.reportType
    ),
    balanceSheet: balanceSheetConfig(
      accounts,
      period,
      previousPeriod,
      'previousYear'
    ),
    notes: notesConfig(
      customer.financialYears || [],
      financialYear,
      period.end,
      documentConfiguration.isDigitalSubmission || false
    ),
    manualSubmission: manualSubmissionConfig(),
    signatures: signaturesConfig(),
  };
};

/**
 * Creates all references that the annual report refers to
 */
export const references = (_: DocumentConfiguration) => {
  const sharedReferences = {};

  const partReferences = [
    sharedReferences,
    balanceSheetReferences(),
    incomeStatementReferences(),
  ];

  // Verify that there is no name conflict
  let references = {};
  partReferences.forEach((refs) => {
    const keysSoFar = Object.keys(references);
    const conflict = Object.keys(refs).find((ref) => keysSoFar.includes(ref));
    if (conflict) {
      throw new Error(`Conflicting keys in references (${conflict})`);
    }
    references = { ...references, ...refs };
  });

  return references;
};

import React from 'react';
import styled from '@emotion/styled';
import { Tooltip, Typography as MuiTypography } from '@material-ui/core';
import CloseIconButton from './CloseIconButton';

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px 8px 0px 0px;
  padding: ${(props) => props.theme.spacing(1, 2)};
  background-color: ${(props) =>
    props.theme.palette.accountingView.headers.main};
`;

const Typography = styled(MuiTypography)`
  color: ${(props) => props.theme.palette.secondary.contrastText};
  margin-left: 0.5rem;
`;

const TitleIconContainer = styled.div`
  display: flex;
  gap: 0.5rem;
`;

type Props = {
  onClose: () => void;
  title?: string;
  icon?: any;
  tooltipText?: any;
};

const CloseTopBar = ({ onClose, title, icon, tooltipText }: Props) => (
  <Container>
    <TitleIconContainer>
      {title && <Typography>{title}</Typography>}
      {icon && tooltipText && <Tooltip title={tooltipText}>{icon}</Tooltip>}
    </TitleIconContainer>
    <CloseIconButton stopPropagation onClose={onClose} />
  </Container>
);

export default CloseTopBar;

import React from 'react';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';

import { AgoyTable, ResolvedReference } from '@agoy/document';
import { useSelector } from 'redux/reducers';
import Typography from '_shared/components/Typography/Typography';

import Table from './Table';
import PrintHeader from './PrintHeader';

const PrintTable = styled(Table)`
  .MuiTableCell-root {
    border: 1px ${({ theme }) => theme.palette.grey[400]} solid;
  }
`;

type ImprovementsAccountPrintProps = {
  table: AgoyTable;
  propertyCode: ResolvedReference;
};

const ImprovementsTablePrint = ({
  table,
  propertyCode,
}: ImprovementsAccountPrintProps) => {
  const { formatMessage } = useIntl();

  const { currentCustomer = '', currentFinancialYear = '' } = useSelector(
    (state) => state.customerView
  );

  const person = useSelector((state) => state.persons[currentCustomer]);

  return (
    <>
      <PrintHeader
        financialYear={currentFinancialYear}
        name={`${person.firstName} ${person.lastName}`}
        number={person.personNumber}
        propertyCode={propertyCode}
        isPerson
      />
      <Typography variant="h2">
        {formatMessage({
          id: `tax.declaration.form.helper.improvements.title`,
        })}
      </Typography>
      <PrintTable
        baseId="taxDeclaration"
        tableId="improvements"
        rows={table.rows}
        columns={table.columns}
        print
      />
    </>
  );
};

export default ImprovementsTablePrint;

import React from 'react';
import { useIntl } from 'react-intl';
import { Typography } from '@material-ui/core';
import styled from '@emotion/styled';
import { format } from 'date-fns';
import { GroupCheckedHistoryItemType } from '../../types';

const HistoryContainer = styled('div')`
  margin-bottom: ${(props) => props.theme.spacing(2)}px;
  border: 1px solid ${({ theme }) => theme.palette.grey[400]};
  border-radius: 5px;
  padding: ${(props) => props.theme.spacing(1.5)}px;
  max-width: 400px;
  overflow-y: auto;
`;

const HistoryItem = styled('div')`
  flex-direction: column;
  margin-bottom: ${(props) => props.theme.spacing(2)}px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const HistoryTextContainer = styled('div')`
  flex-direction: row;

  p {
    :not(:last-of-type) {
      margin-right: 5px;
    }
  }
`;

const HistoryText = styled(Typography)`
  color: ${({ theme }) => theme.palette.grey[800]};
`;

const HistoryBlueText = styled(Typography)`
  color: ${({ theme }) => theme.palette.accountingView.hiddenRow.infoText};
`;

const HistoryDate = styled(Typography)`
  color: ${({ theme }) => theme.palette.grey[500]};
`;

interface GroupHistoryProps {
  history: GroupCheckedHistoryItemType[];
}

const GroupCheckedHistory = ({ history }: GroupHistoryProps): JSX.Element => {
  const { formatMessage } = useIntl();

  const latestHistory = history[0];

  return (
    <HistoryContainer>
      <HistoryItem>
        <HistoryDate>
          {format(new Date(latestHistory.date), 'yyyy-MM-dd HH:mm')}
        </HistoryDate>
        <HistoryTextContainer>
          <HistoryText display="inline">{latestHistory.authorName}</HistoryText>
          <HistoryText display="inline">
            {formatMessage({ id: 'hidden.event.history.part1' })}
          </HistoryText>
          <HistoryBlueText display="inline">
            {formatMessage({ id: 'hidden.event.checkedHistory' })}
          </HistoryBlueText>
          <Typography display="inline">
            {formatMessage(
              { id: 'hidden.group.event.history.account' },
              { account: latestHistory.account }
            )}
          </Typography>
        </HistoryTextContainer>
      </HistoryItem>
    </HistoryContainer>
  );
};

export default GroupCheckedHistory;

import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import Drawer from '_shared/components/Drawer';

interface PreviewDrawerProps {
  previewPart: string;
  expanded: boolean;
  toggleExpanded: () => void;
  children?: React.ReactElement;
  appendNode?: HTMLElement | null;
}

const PreviewDrawer = ({
  previewPart,
  expanded,
  toggleExpanded,
  children,
  appendNode = document.body,
}: PreviewDrawerProps) => {
  const { formatMessage } = useIntl();

  const [container, setContainer] = useState<Element | null>(null);

  useEffect(() => {
    const el = document.createElement('div');
    const node = appendNode || document.body;
    node.appendChild(el);
    setContainer(el);
    return () => {
      node.removeChild(el);
    };
  }, [appendNode]);

  if (!container) {
    return null;
  }

  return (
    <Drawer
      open={expanded}
      onClose={toggleExpanded}
      width="70vh"
      headerTitle={formatMessage({ id: `${previewPart}.preview.title` })}
    >
      {children}
    </Drawer>
  );
};

export default PreviewDrawer;

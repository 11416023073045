import React, { memo, useCallback, useContext, useState } from 'react';
import styled from '@emotion/styled';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

import { getClasses } from '@agoy/common';
import Typography from '_shared/components/Typography/Typography';
import AnnualReportViewServiceContext from '_annual-report/service/AnnualReportViewServiceContext';
import { GroupedNotes } from '_shared/utils/notesUtils/types';
import SelectPopover from './SelectPopover';

const SelectInput = styled.div`
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
  background-color: white;
  border-radius: ${({ theme }) => theme.spacing(0.5)}px;
  border: 1px solid #e0e0e0;
  column-gap: ${({ theme }) => theme.spacing(0.5)}px;
  height: 35px;
  width: 100%;
  padding: 0 ${({ theme }) => theme.spacing(1)}px;

  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0;
  }

  &:not(.disabled):hover,
  &.open {
    cursor: pointer;
    border-color: ${({ theme }) => theme.palette.border.darkGreen};
  }

  &.disabled {
    pointer-events: none;
    svg {
      color: #dde1e0;
    }
  }
`;

interface SelectProps {
  textValue: string;
  cellId: string;
  selectedNotes: string[];
  noteGroups: GroupedNotes;
  disabled?: boolean;
  className?: string;
}

const Select = memo(
  ({
    textValue,
    cellId,
    selectedNotes,
    disabled = false,
    noteGroups,
    className,
  }: SelectProps) => {
    const service = useContext(AnnualReportViewServiceContext);
    const [open, setOpen] = useState(false);

    const handleClick = useCallback(() => {
      if (disabled) {
        return;
      }

      setOpen((currentValue) => !currentValue);
    }, [disabled]);

    return (
      <>
        <SelectInput
          className={`${className} ${getClasses({ disabled, open })}`}
          onClick={handleClick}
        >
          <Typography margin="none" color={disabled ? 'disabled' : 'onLight'}>
            {textValue}
          </Typography>
          {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </SelectInput>

        <SelectPopover
          open={open}
          noteGroups={noteGroups}
          selectedNotes={selectedNotes}
          onClose={handleClick}
          onChange={(updatedNotes) => {
            service.updateCellReferences(cellId, updatedNotes);
          }}
        />
      </>
    );
  }
);

export default Select;

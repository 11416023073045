import React from 'react';
import styled from '@emotion/styled';
import { MoreHoriz, Add } from '@material-ui/icons';
import {
  ClickAwayListener,
  IconButton,
  Paper,
  Popper,
  Tooltip,
} from '@material-ui/core';

import { useSelector } from 'redux/reducers';
// @ts-ignore
import { ReactComponent as Cog } from './assets/fa_gear.svg';
// @ts-ignore
import { ReactComponent as FilesIcon } from './assets/files.svg';
import SideBarPopup from './SideBarPopup';

const Menu = styled.div`
  width: 64px;
  height: 100%;
  background-color: #fff;
  border-right: 1px solid #dde1e0;
  display: flex;
  flex-direction: column;
  color: #002517;
`;

const Divider = styled.div`
  height: 24px;
  background: #fff;
  border-bottom: 1px solid #dde1e0;
`;

const IconWrapper = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 64px;
  cursor: pointer;
  fill: #002517;
  border-bottom: 1px solid #dde1e0;

  &[data-selected] {
    fill: #002517;
    box-shadow: inset 4px 0 ${({ theme }) => theme.palette.secondary.main};
  }

  &:hover {
    fill: #007533;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
  width: 64px;
  height: 100%;
  background-color: #fff;
  fill: #002517;
`;

type IconProps = {
  id: string;
  tooltip: string;
  selected?: boolean;
  onClick?: () => void;
  icon: React.ReactNode;
};

const PopOver = styled(Paper)`
  width: 64px;
  background-color: #fff;
  color: #000;
`;

const Icon = ({ selected = false, onClick, icon, tooltip }: IconProps) => {
  const attrs = {};
  if (selected) attrs['data-selected'] = true;
  return (
    <Tooltip title={tooltip} placement="right">
      <IconWrapper {...attrs} onClick={onClick}>
        {icon}
      </IconWrapper>
    </Tooltip>
  );
};

type IconMenuProps = {
  onSettingsClick?: () => void;
  programs?: IconProps[];
  years?: IconProps[];
  currentClient: string;
  currentFinancialYear?: string;
  currentProgram?: string;
  fortnoxIntegration?: React.ReactNode;
  reconciliationPopup?: boolean;
  addYear?: () => void;
};

/**
 * Icon menu, used as main navigation for programs and financial years
 *
 * @param programs Array of programs with icons and links
 * @param years Array of financial years with icons and links
 * @returns
 */
const IconMenu = ({
  onSettingsClick = () => {},
  programs = [],
  years = [],
  currentClient,
  currentFinancialYear,
  currentProgram,
  fortnoxIntegration,
  reconciliationPopup,
  addYear,
}: IconMenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isPopupShown, setPopupShown] = React.useState(false);

  const customer = useSelector((state) => state.customers[currentClient]);

  const annualReportConfig = useSelector((state) => {
    if (!currentFinancialYear || !currentClient) {
      return null;
    }

    const annualReportClient = state.annualReport.clients[currentClient];

    return annualReportClient
      ? annualReportClient.years[currentFinancialYear]?.documentConfiguration
      : null;
  });
  const annualReportCompanyType = annualReportConfig?.documentType || 'shares';

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'years-popper' : undefined;

  const hasMoreThanThreeYears = years.length > 3;
  return (
    <Container>
      <Menu>
        <Icon
          id="settings-cog"
          icon={<Cog />}
          tooltip="Klientinställningar"
          onClick={onSettingsClick}
          selected={!currentProgram}
        />
        {programs.map((program) =>
          annualReportCompanyType === 'individual' && program.id === 'year' ? (
            <Icon key={program.id} {...program} tooltip="Årsbokslut" />
          ) : (
            <Icon key={program.id} {...program} />
          )
        )}
        <Divider />
        {years.map((year, index) =>
          index < 3 ? <Icon key={year.id} {...year} /> : null
        )}
        {hasMoreThanThreeYears && (
          <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <IconButton
                aria-describedby={id}
                style={{ color: '#000', flex: 1 }}
                size="medium"
                onClick={handleClick}
                disableRipple
              >
                <MoreHoriz />
              </IconButton>
              <Popper
                id={id}
                open={open}
                anchorEl={anchorEl}
                placement="right"
                style={{ zIndex: 50 }}
              >
                <PopOver>
                  {years.map((year, index) =>
                    index > 2 ? (
                      <Icon
                        key={year.id}
                        {...year}
                        onClick={() => {
                          if (year.onClick) year.onClick();
                          setAnchorEl(null);
                        }}
                      />
                    ) : null
                  )}
                </PopOver>
              </Popper>
            </div>
          </ClickAwayListener>
        )}
        {addYear && (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <IconButton
              style={{ color: '#000', flex: 1 }}
              size="medium"
              onClick={addYear}
              disableRipple
            >
              <Add />
            </IconButton>
          </div>
        )}
        {fortnoxIntegration}
      </Menu>
      {reconciliationPopup && (
        <>
          {isPopupShown && (
            <SideBarPopup
              client={customer}
              financialYear={currentFinancialYear}
              onClickAway={() => setPopupShown(false)}
            />
          )}
          <Icon
            id="export-stuff"
            icon={
              <div style={{ width: '25px' }}>
                <FilesIcon />
              </div>
            }
            tooltip="Import/Export"
            onClick={() => setPopupShown(!isPopupShown)}
            selected={isPopupShown}
          />
        </>
      )}
    </Container>
  );
};

export default IconMenu;

import { AgoyDocumentStructure } from '../document';
import updateDocument from './helpers/updateDocument';
import { OperationResult, State } from './types';

const toggleFieldActive = <T extends AgoyDocumentStructure>(
  structure: T,
  state: State<T>,
  id: string
): OperationResult<T> => {
  return updateDocument(structure, state, id, {
    field: (key, fieldId, props) => {
      const currentActiveState =
        typeof props.node.active === 'object'
          ? props.node.active.value
          : props.node.active;

      return {
        ...props,
        node: {
          ...props.node,
          active: !currentActiveState,
          original: props.node.original || { ...props.node },
        },
        changes: { ...props.node, active: !currentActiveState },
      };
    },
  });
};

export default toggleFieldActive;

import { getMonth } from 'date-fns';
import { parse } from '@agoy/dates';
import { createLinkedNodes, LinkedNode, mapOf } from '@agoy/common';
import { AccountResolver } from '.';
import { AccountValueType, TimePeriod, ResolvedReference } from './types';

type AccountingPeriod = {
  id: number;
  financialYearId: number;
  /**
   * Periods start date, yyyy-MM-dd
   */
  start: string;
  /**
   * Periods end date, yyyy-MM-dd
   */
  end: string;
  /**
   * Type, 'month' or 'year_end'
   */
  type: 'month' | 'year_end';
};

type AccountBalance = {
  /**
   * Incoming balance, 0 for result accounts
   */
  in?: number;

  /**
   * Outgoing balance/result
   */
  out?: number;

  /**
   * The sum of all debit transactions, with cancellation
   */
  debits?: number;

  /**
   * The sum of all credit transactions, with cancellation
   */
  credits?: number;
};

type AccountingPeriodBalance = {
  in: number;
  out: number;
};

export type AccountingAccount = {
  number: string;
  name: string;
  balances: Record<string, AccountBalance>;
  periods: Record<string, AccountingPeriodBalance>;
};

type Range = { first: string; last: string; nameFilter?: string | undefined };

class AccountingBalancesAccountResolver implements AccountResolver {
  years: Map<number, Map<string, AccountingAccount>> = new Map();
  yearIds: Map<string, number> = new Map();

  /**
   * maps a financial year tp periods
   */
  availablePeriods: Map<string, LinkedNode<AccountingPeriod>> = new Map();

  constructor(
    financialYearId: number,
    periods: AccountingPeriod[],
    accounts: AccountingAccount[]
  ) {
    this.addFinancialYear(financialYearId, periods, accounts);
  }

  addFinancialYear(
    financialYearId: number,
    periods: AccountingPeriod[],
    accounts: AccountingAccount[]
  ) {
    this.years.set(
      financialYearId,
      mapOf(accounts, (account) => account.number)
    );
    for (const period of createLinkedNodes(periods)) {
      this.availablePeriods.set(
        TimePeriod.fromDates(period.value.start, period.value.end, 'yyyy-MM-dd')
          .value,
        period
      );
    }
    this.yearIds.set(
      TimePeriod.fromDates(
        periods[0].start,
        periods[periods.length - 1].end,
        'yyyy-MM-dd'
      ).value,
      financialYearId
    );
  }

  getFinancialYear(period: TimePeriod) {
    const financialYearId = this.yearIds.get(period.value);
    return (
      (financialYearId !== undefined && this.years.get(financialYearId)) ||
      undefined
    );
  }

  protected getValue(
    field: AccountValueType,
    balance?: AccountBalance | AccountingPeriodBalance
  ): number | undefined {
    if (!balance) {
      return undefined;
    }
    switch (field) {
      case 'ub':
        return balance.out ?? balance.in;
      case 'ib':
      case 'yearIb':
        return balance.in;
      case 'change':
        return balance.in !== undefined && balance.out !== undefined
          ? balance.out - balance.in
          : undefined;
      case 'creditWithoutInverse':
        return 'credits' in balance ? balance?.credits : undefined;
      case 'debitWithoutInverse':
        return 'debits' in balance ? balance.debits : undefined;
      default:
        return undefined;
    }
  }

  protected getPeriodValue(
    field: AccountValueType,
    period: LinkedNode<AccountingPeriod>,
    account: AccountingAccount
  ): number | undefined {
    if (field === 'yearIb') {
      return account.balances[period.value.financialYearId].in;
    }
    if (account.periods[period.value.id]) {
      return this.getValue(field, account.periods[period.value.id]);
    }
    if (field === 'change') {
      return 0;
    }
    if (field === 'ib' || field === 'ub') {
      // No data for the current period, look back in time.
      let previousPeriod = period.prev;
      while (previousPeriod) {
        if (account.periods[previousPeriod.value.id]) {
          return this.getValue('ub', account.periods[previousPeriod.value.id]);
        }
        previousPeriod = previousPeriod.prev;
      }
      // No period with data so lets check the years incoming balance
      const balance = account.balances[period.value.financialYearId];
      return balance?.in;
    }
    return undefined;
  }

  get(
    field: AccountValueType,
    period: TimePeriod,
    accountNumber: string
  ): ResolvedReference {
    // process.stdout.write(`get ${period.value} ${period}\n`);

    const financialYear = this.getFinancialYear(period);

    if (financialYear) {
      const financialYearId = this.yearIds.get(period.value);
      if (financialYearId !== undefined) {
        const balance =
          financialYear.get(accountNumber)?.balances[financialYearId];
        return this.getValue(field, balance);
      }
    }

    const { start: periodStart, end: periodEnd } = period.toISODates();

    if (getMonth(parse(periodEnd)) !== getMonth(parse(periodStart))) {
      let startPeriod: LinkedNode<AccountingPeriod> | null = null;

      for (const item of this.availablePeriods.values()) {
        if (item.value.start === periodStart) {
          startPeriod = item;
        }
      }

      let nextPeriod = startPeriod;
      let resultValue = 0;

      if (startPeriod) {
        while (nextPeriod && nextPeriod.value.end <= periodEnd) {
          const account = this.years
            .get(nextPeriod.value.financialYearId)
            ?.get(accountNumber);

          if (account) {
            const accountValue = this.getPeriodValue(
              field,
              nextPeriod,
              account
            );
            if (accountValue) {
              resultValue += accountValue;
            }
          }
          nextPeriod = nextPeriod.next;
        }

        return resultValue;
      } else {
        return { error: 'missingPeriod' };
      }
    }

    const existingPeriod = this.availablePeriods.get(period.value);
    if (existingPeriod) {
      const account = this.years
        .get(existingPeriod.value.financialYearId)
        ?.get(accountNumber);

      if (account) {
        return this.getPeriodValue(field, existingPeriod, account);
      }

      return { error: 'missingAccount' };
    }
    return { error: 'missingPeriod' };
  }

  isInRange(ranges: Range[], account: AccountingAccount): boolean {
    return ranges.some((range) => {
      const isInRange =
        account.number >= range.first && account.number <= range.last;
      if (isInRange && range.nameFilter) {
        return account.name
          .toLowerCase()
          .includes(range.nameFilter.toLowerCase());
      }
      return isInRange;
    });
  }

  sum(
    field: AccountValueType,
    period: TimePeriod,
    ranges: Range[]
  ): ResolvedReference {
    const sumAccounts = (
      financialYearId: number,
      accounts: Map<string, AccountingAccount>,
      accountingPeriod: LinkedNode<AccountingPeriod> | null
    ) => {
      let value = 0;
      for (const account of accounts.values()) {
        if (this.isInRange(ranges, account)) {
          const accountValue = accountingPeriod
            ? this.getPeriodValue(field, accountingPeriod, account)
            : this.getValue(field, account.balances[financialYearId]);
          if (accountValue !== undefined) {
            value = value + accountValue;
          }
        }
      }
      return value;
    };

    const financialYear = this.getFinancialYear(period);
    if (financialYear) {
      const financialYearId = this.yearIds.get(period.value);
      if (financialYearId !== undefined) {
        return sumAccounts(financialYearId, financialYear, null);
      }
    }

    const { start: periodStart, end: periodEnd } = period.toISODates();

    if (getMonth(parse(periodEnd)) !== getMonth(parse(periodStart))) {
      let startPeriod: LinkedNode<AccountingPeriod> | null = null;

      for (const item of this.availablePeriods.values()) {
        if (item.value.start === periodStart) {
          startPeriod = item;
        }
      }

      let nextPeriod = startPeriod;
      let resultValue = 0;

      if (startPeriod) {
        while (nextPeriod && nextPeriod.value.end <= periodEnd) {
          const accounts = this.years.get(nextPeriod.value.financialYearId);

          if (accounts) {
            const accountValue = sumAccounts(
              nextPeriod.value.financialYearId,
              accounts,
              nextPeriod
            );
            if (accountValue) {
              resultValue += accountValue;
            }
          }
          nextPeriod = nextPeriod.next;
        }

        return resultValue;
      } else {
        return { error: 'missingPeriod' };
      }
    }

    const existingPeriod = this.availablePeriods.get(period.value);
    if (existingPeriod) {
      const accounts = this.years.get(existingPeriod.value.financialYearId);
      if (accounts) {
        return sumAccounts(
          existingPeriod.value.financialYearId,
          accounts,
          existingPeriod
        );
      }
    }
    return { error: 'missingPeriod' };
  }

  getName(accountNumber: number): string | undefined {
    for (const yearId of [...this.years.keys()]) {
      if (this.years.get(yearId)?.has(accountNumber.toString())) {
        return this.years.get(yearId)?.get(accountNumber.toString())?.name;
      }
    }
    return undefined;
  }
}

export default AccountingBalancesAccountResolver;

import { AgoyTableRow, Cell } from '@agoy/document';

// eslint-disable-next-line import/prefer-default-export
export const checkDuplicatedAccrualFundAccount = (
  selectedAccount: string,
  rows: AgoyTableRow<Cell>[]
): boolean => {
  return (
    rows.find((row) => {
      if (
        row.cells &&
        row.cells.accountNumber &&
        row.cells.accountNumber.type === 'ref'
      ) {
        return row.cells.accountNumber.value === Number(selectedAccount);
      }
    }) !== undefined
  );
};

import React from 'react';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';
import Button from '_shared/components/Buttons/Button';
import Expandable from './Expandable';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing(3)}px;
  width: 100%;
  padding: ${(props) => props.theme.spacing(3)}px;
`;

const ActionButtons = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  padding: ${(props) => props.theme.spacing(3)}px;
  gap: ${(props) => props.theme.spacing(2)}px;
  box-shadow: ${({ theme }) => theme.shadows[4]};
  width: 100%;
`;

interface ColumnSelectorProps {
  onSave: () => void;
  onCancel: () => void;
}

const ColumnSelector = ({
  onSave,
  onCancel,
}: ColumnSelectorProps): JSX.Element => {
  const { formatMessage } = useIntl();

  return (
    <>
      <Container>
        <Button
          label={formatMessage({ id: 'hidden.specification.createColumn' })}
          size="small"
          variant="outlined"
        />
        <Expandable
          title={formatMessage({ id: 'hidden.specification.selectedColumns' })}
          defaultExpanded
        >
          <div>Test 1</div>
        </Expandable>
        <Expandable
          title={formatMessage({ id: 'hidden.specification.assets' })}
        >
          <div>Test 2</div>
        </Expandable>
        <Expandable
          title={formatMessage({ id: 'hidden.specification.accruals' })}
        >
          <div>Test 3</div>
        </Expandable>
      </Container>
      <ActionButtons>
        <Button
          label={formatMessage({ id: 'hidden.specification.save' })}
          size="medium"
          onClick={onSave}
        />
        <Button
          label={formatMessage({ id: 'hidden.button.cancel' })}
          size="medium"
          variant="outlined"
          onClick={onCancel}
        />
      </ActionButtons>
    </>
  );
};

export default ColumnSelector;

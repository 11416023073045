import React from 'react';
import styled from '@emotion/styled';
import { Tooltip } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { format, getMonth, parse } from 'date-fns';

import { AccountGroupAttribute, getGroupStatus } from 'utils/accountGrouper';
import { Lamps } from '_clients/types/types';
import config from '_shared/services/config';
import AccountStatus from '../AccountStatus';
import GroupStatus from '../GroupStatus';
import PeriodStatus from './PeriodStatus';
import { PeriodStatusesType } from './utils';

const isFortnoxMode = config.whiteLabelUI === 'fortnox';

const Container = styled.div<{ isClosingPeriod: boolean }>`
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.spacing(1 / 4)}px;
  width: ${({ isClosingPeriod }) => (isClosingPeriod ? 6 : 4.5)}vw;
`;

const AccountStatusWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  column-gap: ${({ theme }) => theme.spacing(1 / 4)}px;
  row-gap: ${({ theme }) => theme.spacing(1 / 4)}px;
  flex-flow: row wrap;
  justify-content: center;
`;

const SpecialPeriod = styled.div`
  text-align: center;
  text-transform: uppercase;
  border-radius: 2px;
`;

// fix colors after removing of feature flag
const ClosingMonthLabel = styled(SpecialPeriod)`
  color: ${({ theme }) => theme.palette.secondary.contrastText};
  background: ${({ theme }) =>
    isFortnoxMode
      ? '#003824'
      : theme.palette.overviewView.closingMonthBackground};
  font-weight: 700;
`;

const CurrentMonthLabel = styled(SpecialPeriod)`
  background: ${({ theme }) =>
    isFortnoxMode
      ? '#FFB992'
      : theme.palette.overviewView.currentMonthBackground};
`;

const StyledMonth = styled.div`
  text-align: center;
  text-transform: uppercase;
  color: ${({ theme }) => theme.palette.text.primary};
`;

// Order matters!
const availableLamps: Array<AccountGroupAttribute | string> = [
  'a15xx',
  'a1630',
  'a19xx',
  'a24xx',
  // VAT,
  'moms',
  'rest',
];

type PeriodObject = {
  date: string;
  hasData: boolean;
};

type LampItemProps = {
  period: PeriodObject;
  periodLamp?: Lamps;
  programStatus: PeriodStatusesType;
  isClosingPeriod: boolean;
  expanded: boolean;
};

const LampItem = ({
  periodLamp,
  period,
  programStatus,
  isClosingPeriod,
  expanded,
}: LampItemProps) => {
  const { formatMessage } = useIntl();

  const parsedPeriodStart = parse(period.date, 'yyyyMMdd', Date.now());
  const periodMonth = getMonth(parsedPeriodStart) + 1;

  const today = format(new Date(), 'yyyyMM');
  const isCurrentPeriod = period.date.startsWith(today);

  return (
    <Container isClosingPeriod={isClosingPeriod}>
      {expanded ? (
        <AccountStatusWrapper>
          {availableLamps.map((accountGroup) => (
            <AccountStatus
              status={periodLamp ? periodLamp[accountGroup] : 'unvisited'}
              key={accountGroup}
            />
          ))}
        </AccountStatusWrapper>
      ) : (
        <GroupStatus
          periodStatus={programStatus.RECONCILIATION || 'NOT_STARTED'}
          groupStatus={periodLamp ? getGroupStatus(periodLamp) : 'unvisited'}
        />
      )}

      {expanded && <PeriodStatus periodStatuses={programStatus} />}

      {isClosingPeriod && (
        <Tooltip title={formatMessage({ id: 'month.tax' })} placement="bottom">
          <ClosingMonthLabel>
            {format(parsedPeriodStart, 'MMM yy')}
          </ClosingMonthLabel>
        </Tooltip>
      )}

      {!isClosingPeriod &&
        (isCurrentPeriod ? (
          <Tooltip
            title={formatMessage({ id: 'month.current' })}
            placement="bottom"
          >
            <CurrentMonthLabel>
              {`${formatMessage({ id: `month.${periodMonth}` })} ${format(
                parsedPeriodStart,
                'yy'
              )}`}
            </CurrentMonthLabel>
          </Tooltip>
        ) : (
          <StyledMonth>
            {`${formatMessage({ id: `month.${periodMonth}` })} ${format(
              parsedPeriodStart,
              'yy'
            )}`}
          </StyledMonth>
        ))}
    </Container>
  );
};

export default LampItem;

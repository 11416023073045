import { ref, table, value } from '@agoy/document';
import {
  field,
  ixbrlCell,
  ixbrlField,
  range,
} from '../../../../common/utils/util';
import { NoteOneTableSection, IxbrlCell } from '../../../../common';
import {
  sumAccountsIBs,
  sumPositiveTransactions,
  sumNegativeTransactions,
  sumNotesRowsById,
} from '../../../../common/utils/accountSumsUtil';
import { year, previousYear } from '../../../../common/utils/date-util';
import { NoteAccountRanges } from '../types';

const MONETARY_ITEM_TYPE = 'monetaryItemType';

export const note8Accounts: NoteAccountRanges[] = [
  {
    rowId: '1',
    ranges: [[1070, 1077]],
    cellLabel: 'Ingående anskaffningsvärden',
    action: 'sumAccountsIBs',
  },
  {
    rowId: '2',
    ranges: [[1070, 1077]],
    cellLabel: 'Rörelseförvärv',
    action: 'sumPositiveTransactions',
  },
  {
    rowId: '3',
    ranges: [[1070, 1077]],
    cellLabel: 'Försäljningar/utrangeringar',
    action: 'sumNegativeTransactions',
  },
  {
    rowId: '6',
    ranges: [[1079]],
    cellLabel: 'Ingående avskrivningar',
    action: 'sumAccountsIBs',
  },
  {
    rowId: '7',
    ranges: [[1079]],
    cellLabel: 'Försäljningar/utrangeringar',
    action: 'sumPositiveTransactions',
  },
  {
    rowId: '9',
    ranges: [[1079]],
    cellLabel: 'Årets avskrivningar',
    action: 'sumNegativeTransactions',
  },
  {
    rowId: '11',
    ranges: [[1078]],
    cellLabel: 'Ingående nedskrivningar',
    action: 'sumAccountsIBs',
  },
  {
    rowId: '12',
    ranges: [[1078]],
    cellLabel: 'Försäljningar/utrangeringar',
    action: 'sumPositiveTransactions',
  },
  {
    rowId: '15',
    ranges: [[1078]],
    cellLabel: 'Årets nedskrivningar',
    action: 'sumNegativeTransactions',
  },
];

export const getNote8 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null
): NoteOneTableSection => {
  return {
    active: false,
    number: value(undefined),
    name: value('Goodwill'),
    table: table<IxbrlCell>(
      `notes.note8.table`,
      { id: 'label', label: '' },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      .addRows((rows) =>
        rows
          // 'ingående' = previous period outgoing balance
          .addRow(
            '1',
            noteLabel('Ingående anskaffningsvärden'),
            ixbrlCell(ref(sumAccountsIBs('1070:1077', false)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:GoodwillAnskaffningsvarden',
              contextRef: 'balans1',
              saldo: 'debit',
            }),
            ixbrlCell(ref(sumAccountsIBs('1070:1077', false, 'previousYear')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:GoodwillAnskaffningsvarden',
              contextRef: 'balans2',
              saldo: 'debit',
            })
          )
          // In this note, Rörelseförvärv now contains what inköp used to contain
          .addRow(
            '2',
            noteLabel('Rörelseförvärv'),
            ixbrlCell(ref(sumPositiveTransactions('1070:1077')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:GoodwillForandringAnskaffningsvardenRorelseforvarv',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden för goodwill vid rörelseförvärv',
            }),
            ixbrlCell(
              ref(sumPositiveTransactions('1070:1077', 'previousYear')),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:GoodwillForandringAnskaffningsvardenRorelseforvarv',
                contextRef: 'period1',
                standardRubrik:
                  'Ökning (minskning) av anskaffningsvärden för goodwill vid rörelseförvärv',
              }
            )
          )
          .addRow(
            'anskaffningsvardenFusion',
            noteLabel('Fusion'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:GoodwillForandringAnskaffningsvardenFusion',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden för goodwill genom fusion',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:GoodwillForandringAnskaffningsvardenFusion',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden för goodwill genom fusion',
            })
          )
          .addRow(
            '3',
            noteLabel('Försäljningar/utrangeringar'),
            ixbrlCell(ref(sumNegativeTransactions('1070:1077')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:GoodwillForandringAnskaffningsvardenForsaljningarUtrangeringar',
              contextRef: 'period0',
              standardRubrik:
                'Minskning av anskaffningsvärden i samband med försäljningar eller utrangeringar av goodwill',
              negateValue: true,
            }),
            ixbrlCell(
              ref(sumNegativeTransactions('1070:1077', 'previousYear')),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:GoodwillForandringAnskaffningsvardenForsaljningarUtrangeringar',
                contextRef: 'period1',
                standardRubrik:
                  'Minskning av anskaffningsvärden i samband med försäljningar eller utrangeringar av goodwill',
                negateValue: true,
              }
            )
          )
          .addRow(
            '4',
            noteLabel('Omklassificeringar'), // no accounts
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:GoodwillForandringAnskaffningsvardenOmklassificeringar',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden i samband med omklassificeringar av goodwill',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:GoodwillForandringAnskaffningsvardenOmklassificeringar',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden i samband med omklassificeringar av goodwill',
            })
          )
          .addRow(
            'anskaffningsvardenConversionDifferences',
            noteLabel('Omräkningsdifferenser'), // no accounts
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:GoodwillForandringAnskaffningsvardenOmrakningsdifferenser',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden i samband med omräkningsdifferenser av goodwill',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:GoodwillForandringAnskaffningsvardenOmrakningsdifferenser',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden i samband med omräkningsdifferenser av goodwill',
            })
          )
          .addRow(
            '5',
            noteLabel('Utgående anskaffningsvärden'),
            ixbrlCell(
              sumNotesRowsById(
                8,
                [
                  ...range(1, 5),
                  'anskaffningsvardenFusion',
                  'anskaffningsvardenConversionDifferences',
                ],
                'year'
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:GoodwillAnskaffningsvarden',
                contextRef: 'balans0',
                saldo: 'debit',
              }
            ),
            ixbrlCell(
              sumNotesRowsById(
                8,
                [
                  ...range(1, 5),
                  'anskaffningsvardenFusion',
                  'anskaffningsvardenConversionDifferences',
                ],
                'previousYear'
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:GoodwillAnskaffningsvarden',
                contextRef: 'balans1',
                saldo: 'debit',
              }
            )
          )
          .addRow(
            '6',
            noteLabel('Ingående avskrivningar'),
            ixbrlCell(ref(sumAccountsIBs('1079', false)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:GoodwillAvskrivningar',
              contextRef: 'balans1',
              saldo: 'credit',
              negateValue: true,
            }),
            ixbrlCell(ref(sumAccountsIBs('1079', false, 'previousYear')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:GoodwillAvskrivningar',
              contextRef: 'balans2',
              saldo: 'credit',
              negateValue: true,
            })
          )
          .addRow(
            'avskrivningarFusion',
            noteLabel('Fusion'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:GoodwillForandringAvskrivningarAterfordaAvskrivningarFusion',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av avskrivningar av goodwill genom fusion',
              negateValue: true,
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:GoodwillForandringAvskrivningarAterfordaAvskrivningarFusion',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av avskrivningar av goodwill genom fusion',
              negateValue: true,
            })
          )
          .addRow(
            '7',
            noteLabel('Försäljningar/utrangeringar'),
            ixbrlCell(ref(sumPositiveTransactions('1079')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:GoodwillForandringAvskrivningarAterfordaAvskrivningarForsaljningarRespUtrangeringar',
              contextRef: 'period0',
            }),
            ixbrlCell(ref(sumPositiveTransactions('1079', 'previousYear')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:GoodwillForandringAvskrivningarAterfordaAvskrivningarForsaljningarRespUtrangeringar',
              contextRef: 'period1',
            })
          )
          .addRow(
            '8',
            noteLabel('Omklassificeringar'), // no accounts
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:GoodwillForandringAvskrivningarOmklassificeringar',
              contextRef: 'period0',
              standardRubrik:
                'Minskning (ökning) av avskrivningar i samband med omklassificeringar av goodwill',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:GoodwillForandringAvskrivningarOmklassificeringar',
              contextRef: 'period1',
              standardRubrik:
                'Minskning (ökning) av avskrivningar i samband med omklassificeringar av goodwill',
            })
          )
          .addRow(
            '9',
            noteLabel('Årets avskrivningar'),
            ixbrlCell(ref(sumNegativeTransactions('1079')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:GoodwillForandringAvskrivningarAretsAvskrivningar',
              contextRef: 'period0',
              saldo: 'debit',
            }),
            ixbrlCell(ref(sumNegativeTransactions('1079', 'previousYear')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:GoodwillForandringAvskrivningarAretsAvskrivningar',
              contextRef: 'period1',
              saldo: 'debit',
            })
          )
          .addRow(
            'avskrivningarConversionDifferences',
            noteLabel('Omräkningsdifferenser'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:GoodwillForandringAvskrivningarOmrakningsdifferenser',
              contextRef: 'period0',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:GoodwillForandringAvskrivningarOmrakningsdifferenser',
              contextRef: 'period1',
            })
          )
          .addRow(
            '10',
            noteLabel('Utgående avskrivningar'),
            ixbrlCell(
              sumNotesRowsById(
                8,
                [
                  ...range(6, 10),
                  'avskrivningarFusion',
                  'avskrivningarConversionDifferences',
                ],
                'year'
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:GoodwillAvskrivningar',
                contextRef: 'balans0',
                saldo: 'credit',
                negateValue: true,
              }
            ),
            ixbrlCell(
              sumNotesRowsById(
                8,
                [
                  ...range(6, 10),
                  'avskrivningarFusion',
                  'avskrivningarConversionDifferences',
                ],
                'previousYear'
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:GoodwillAvskrivningar',
                contextRef: 'balans1',
                saldo: 'credit',
                negateValue: true,
              }
            )
          )
          .addRow(
            '11',
            noteLabel('Ingående nedskrivningar'),
            ixbrlCell(ref(sumAccountsIBs('1078', false)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:GoodwillNedskrivningar',
              contextRef: 'balans1',
              saldo: 'credit',
              negateValue: true,
            }),
            ixbrlCell(ref(sumAccountsIBs('1078', false, 'previousYear')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:GoodwillNedskrivningar',
              contextRef: 'balans2',
              saldo: 'credit',
              negateValue: true,
            })
          )
          .addRow(
            'nedskrivningarFusion',
            noteLabel('Fusion'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:GoodwillForandringNedskrivningarFusion',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av nedskrivningar av goodwill genom fusion',
              negateValue: true,
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:GoodwillForandringNedskrivningarFusion',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av nedskrivningar av goodwill genom fusion',
              negateValue: true,
            })
          )
          .addRow(
            '12',
            noteLabel('Försäljningar/utrangeringar'),
            ixbrlCell(ref(sumPositiveTransactions('1078')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:GoodwillForandringNedskrivningarAterfordaNedskrivningarForsaljningarRespUtrangeringar',
              contextRef: 'period0',
            }),
            ixbrlCell(ref(sumPositiveTransactions('1078', 'previousYear')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:GoodwillForandringNedskrivningarAterfordaNedskrivningarForsaljningarRespUtrangeringar',
              contextRef: 'period1',
            })
          )
          .addRow(
            '13',
            noteLabel('Återförda nedskrivningar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:GoodwillForandringNedskrivningarAterfordaNedskrivningar',
              contextRef: 'period0',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:GoodwillForandringNedskrivningarAterfordaNedskrivningar',
              contextRef: 'period1',
            })
          )
          .addRow(
            '14',
            noteLabel('Omklassificeringar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:GoodwillForandringNedskrivningarOmklassificeringar',
              contextRef: 'period0',
              standardRubrik:
                'Minskning (ökning) av nedskrivningar i samband med omklassificeringar av goodwill',
            }), // no accounts
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:GoodwillForandringNedskrivningarOmklassificeringar',
              contextRef: 'period0',
              standardRubrik:
                'Minskning (ökning) av nedskrivningar i samband med omklassificeringar av goodwill',
            })
          )
          .addRow(
            '15',
            noteLabel('Årets nedskrivningar'),
            ixbrlCell(ref(sumNegativeTransactions('1078')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:GoodwillForandringNedskrivningarAretsNedskrivningar',
              contextRef: 'period0',
              saldo: 'debit',
            }),
            ixbrlCell(ref(sumNegativeTransactions('1078', 'previousYear')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:GoodwillForandringNedskrivningarAretsNedskrivningar',
              contextRef: 'period1',
              saldo: 'debit',
            })
          )
          .addRow(
            'nedskrivningarConversionDifferences',
            noteLabel('Omräkningsdifferenser'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:GoodwillForandringNedskrivningarOmrakningsdifferenser',
              contextRef: 'period0',
              standardRubrik:
                'Minskning (ökning) av nedskrivningar i samband med omräkningsdifferenser av goodwill',
              negateValue: true,
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:GoodwillForandringNedskrivningarOmrakningsdifferenser',
              contextRef: 'period1',
              standardRubrik:
                'Minskning (ökning) av nedskrivningar i samband med omräkningsdifferenser av goodwill',
              negateValue: true,
            })
          )
          .addRow(
            '16',
            noteLabel('Utgående nedskrivningar'),
            ixbrlCell(
              sumNotesRowsById(
                8,
                [
                  ...range(11, 16),
                  'nedskrivningarFusion',
                  'nedskrivningarConversionDifferences',
                ],
                'year'
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:GoodwillNedskrivningar',
                contextRef: 'balans0',
                saldo: 'credit',
                negateValue: true,
              }
            ),
            ixbrlCell(
              sumNotesRowsById(
                8,
                [
                  ...range(11, 16),
                  'nedskrivningarFusion',
                  'nedskrivningarConversionDifferences',
                ],
                'previousYear'
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:GoodwillNedskrivningar',
                contextRef: 'balans1',
                saldo: 'credit',
                negateValue: true,
              }
            )
          )
          .addRow(
            '17',
            noteLabel('Redovisat värde'),
            ixbrlCell(sumNotesRowsById(8, [5, 10, 16], 'year'), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:Goodwill',
              contextRef: 'balans0',
              saldo: 'debit',
            }),
            ixbrlCell(sumNotesRowsById(8, [5, 10, 16], 'previousYear'), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:Goodwill',
              contextRef: 'balans1',
              saldo: 'debit',
            })
          )
          .build()
      )
      .newRowTemplate(value(''), value(undefined), value(undefined))
      .build(),
    commentLabel: field('Kommentar'),
    comment: ixbrlField(field(''), {
      type: 'stringItemType',
      name: 'se-gen-base:NotGoodwillKommentar',
      contextRef: 'period0',
    }),
    group: value(undefined),
    section: value(undefined),
    validForReportType: {
      k2: true,
      k3: true,
    },
  };
};

import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Typography, Paper } from '@material-ui/core';
import { useIntl } from 'react-intl';

import config from '_shared/services/config';
import Button from '_shared/components/Buttons/Button';
import { asResultClass, useApiSdk } from 'api-sdk';
import { addGlobalErrorMessage } from 'redux/actions';
import { useDispatch } from 'react-redux';

const Wrapper = styled(Paper)`
  max-width: 960px;
  margin: ${({ theme }) => theme.spacing(2)}px auto;
  padding: ${({ theme }) => theme.spacing(2)}px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)}px;
`;

const Title = styled(Typography)`
  margin: 0 ${({ theme }) => theme.spacing(1)}px;
`;

type IntegrationType = {
  isShown: boolean;
  isActive: boolean;
  text?: string;
  callback: () => void;
};

const StatusButton = ({ isActive, text = '', callback }: IntegrationType) => {
  const { formatMessage } = useIntl();

  const activateText =
    text.length > 0
      ? text
      : formatMessage({ id: 'customerinfo.integrations.add' });

  const removeText =
    text.length > 0
      ? text
      : formatMessage({ id: 'customerinfo.integrations.remove' });

  return (
    <Button
      label={isActive ? removeText : activateText}
      onClick={callback}
      color={isActive ? 'danger' : 'primary'}
      variant="outlined"
      size="medium"
    />
  );
};

type IntegrationsropTypesPropTypes = {
  fortnox: IntegrationType;
};

export const Integrations = ({
  fortnox,
}: IntegrationsropTypesPropTypes): React.ReactElement => {
  return (
    <>
      {fortnox.isShown && (
        <Wrapper>
          <Title variant="h3">Fortnox</Title>
          <StatusButton {...fortnox} />
        </Wrapper>
      )}
    </>
  );
};

type PropTypes = {
  clientId: string;
};

/**
 * Manages the fortnox integraion for user.
 *
 * May manage more integrations in the future.
 */
const Container = ({ clientId }: PropTypes) => {
  const sdk = useApiSdk();
  const dispatch = useDispatch();

  const isFortnoxWhiteLabel = config.whiteLabelUI === 'fortnox';

  // STATE
  const [isIntegrationActive, setIsIntegrationActive] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const fetchIntegrationStatus = async () => {
    const result = await asResultClass(
      sdk.getFortnoxIntegrationStatus({ clientid: clientId })
    );

    if (result.err) {
      return dispatch(addGlobalErrorMessage('error'));
    }

    setIsIntegrationActive(result.val.isActive);
  };

  const deleteIntegration = async () => {
    const deleteResult = await asResultClass(
      sdk.deleteFortnoxIntegration({ clientid: clientId })
    );
    if (deleteResult.err) dispatch(addGlobalErrorMessage('error'));
  };

  const startIntegration = async () => {
    const startIntegrationResult = await asResultClass(
      sdk.startFortnoxIntegration({ clientid: clientId })
    );

    if (startIntegrationResult.err) {
      dispatch(addGlobalErrorMessage('error'));
      return;
    }

    window.open(startIntegrationResult.val.integrationUrl, '_self');
  };

  // EFFECTS
  React.useEffect(() => {
    fetchIntegrationStatus();
  }, []);

  // CALLBACKS
  const fortnoxCallback = async (): Promise<void> => {
    if (isIntegrationActive) {
      await deleteIntegration();
      await fetchIntegrationStatus();
    } else {
      await startIntegration();
    }
  };

  return (
    <>
      <Integrations
        fortnox={{
          isShown: !isFortnoxWhiteLabel,
          isActive: isIntegrationActive,
          callback: fortnoxCallback,
        }}
      />
    </>
  );
};
export default Container;

import React from 'react';
import { Box } from '@material-ui/core';
import { NoteOneTableSection } from '@agoy/annual-report-document';
import {
  AgoyTable,
  Cell,
  Field,
  numberValue,
  stringValue,
} from '@agoy/document';
import Comment from '../../UI/Comment';
import Header from '../../UI/Header';
import { Note } from '../../UI/Note';
import NoteTable from '../../UI/NoteTable';

type Note75Props = {
  noteId: string;
  rowsInGroupsOf?: number;
  commentLabel: Cell | undefined;
  title: string;
  displayOnlyStringRow?: boolean;
  hasPreviousYear?: boolean;
  tableComment: Field;
  tableCommentLabel: Field;
  secondTable: AgoyTable;
  secondTableComment: Field;
  secondTableCommentLabel: Field;
  thirdTable: AgoyTable;
  thirdTableComment: Field;
  thirdTableCommentLabel: Field;
  fourthTable: AgoyTable;
  fourthTableComment: Field;
  fourthTableCommentLabel: Field;
  fifthTable: AgoyTable;
  fifthTableComment: Field;
  fifthTableCommentLabel: Field;
  sixthTable: AgoyTable;
  sixthTableComment: Field;
  sixthTableCommentLabel: Field;
} & NoteOneTableSection;

const Note75 = ({
  active,
  noteId,
  number,
  table,
  rowsInGroupsOf,
  tableComment,
  tableCommentLabel,
  title,
  displayOnlyStringRow,
  hasPreviousYear,
  secondTable,
  secondTableComment,
  secondTableCommentLabel,
  thirdTable,
  thirdTableComment,
  thirdTableCommentLabel,
  fourthTable,
  fourthTableComment,
  fourthTableCommentLabel,
  fifthTable,
  fifthTableComment,
  fifthTableCommentLabel,
  sixthTable,
  sixthTableComment,
  sixthTableCommentLabel,
  commentLabel,
  comment,
}: Note75Props) => {
  if (!active) return null;

  return (
    <Note id={noteId}>
      <Header noteId={noteId} noteNumber={numberValue(number)} title={title} />

      {/* First Table */}
      <Box mt={1} mb={1}>
        <NoteTable
          hasPreviousYear={hasPreviousYear}
          table={table}
          tableId={`notes.${noteId}.table`}
          groupRowsInterval={rowsInGroupsOf}
          displayOnlyStringRow={displayOnlyStringRow}
        />
      </Box>
      <Comment
        field={tableComment}
        value={stringValue(tableComment) || ''}
        labelValue={stringValue(tableCommentLabel) || ''}
      />

      {/* Second Table */}
      <Box mt={1} mb={1}>
        <NoteTable
          hasPreviousYear={hasPreviousYear}
          table={secondTable}
          tableId={`notes.${noteId}.secondTable`}
          groupRowsInterval={rowsInGroupsOf}
          displayOnlyStringRow={displayOnlyStringRow}
        />
      </Box>
      <Comment
        field={secondTableComment}
        value={stringValue(secondTableComment) || ''}
        labelValue={stringValue(secondTableCommentLabel) || ''}
      />

      {/* Third Table */}
      <Box mt={1} mb={1}>
        <NoteTable
          hasPreviousYear={hasPreviousYear}
          table={thirdTable}
          tableId={`notes.${noteId}.thirdTable`}
          groupRowsInterval={rowsInGroupsOf}
          displayOnlyStringRow={displayOnlyStringRow}
        />
      </Box>
      <Comment
        field={thirdTableComment}
        value={stringValue(thirdTableComment) || ''}
        labelValue={stringValue(thirdTableCommentLabel) || ''}
      />

      {/* Fourth Table */}
      <Box mt={1} mb={1}>
        <NoteTable
          hasPreviousYear={hasPreviousYear}
          table={fourthTable}
          tableId={`notes.${noteId}.fourthTable`}
          groupRowsInterval={rowsInGroupsOf}
          displayOnlyStringRow={displayOnlyStringRow}
        />
      </Box>
      <Comment
        field={fourthTableComment}
        value={stringValue(fourthTableComment) || ''}
        labelValue={stringValue(fourthTableCommentLabel) || ''}
      />

      {/* Fifth Table */}
      <Box mt={1} mb={1}>
        <NoteTable
          hasPreviousYear={hasPreviousYear}
          table={fifthTable}
          tableId={`notes.${noteId}.fifthTable`}
          groupRowsInterval={rowsInGroupsOf}
          displayOnlyStringRow={displayOnlyStringRow}
        />
      </Box>
      <Comment
        field={fifthTableComment}
        value={stringValue(fifthTableComment) || ''}
        labelValue={stringValue(fifthTableCommentLabel) || ''}
      />

      {/* Sixth Table */}
      <Box mt={1} mb={1}>
        <NoteTable
          hasPreviousYear={hasPreviousYear}
          table={sixthTable}
          tableId={`notes.${noteId}.sixthTable`}
          groupRowsInterval={rowsInGroupsOf}
          displayOnlyStringRow={displayOnlyStringRow}
        />
      </Box>
      <Comment
        field={sixthTableComment}
        value={stringValue(sixthTableComment) || ''}
        labelValue={stringValue(sixthTableCommentLabel) || ''}
      />

      {/* Comment */}
      <Comment
        field={comment}
        value={stringValue(comment) || ''}
        labelValue={stringValue(commentLabel) || ''}
      />
    </Note>
  );
};

export default Note75;

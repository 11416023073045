import { applyChanges } from '@agoy/document';
import {
  AnnualReport,
  AnnualReportChanges,
  AnnualReportType,
  getContentDefinition,
} from '../config/document';

const applyReportChanges = (
  config: AnnualReport,
  changes: AnnualReportChanges,
  reportType: AnnualReportType
): AnnualReport => {
  const apply = applyChanges(getContentDefinition(config, reportType));
  return apply(config, changes) as AnnualReport;
};

export default applyReportChanges;

import { id, ref } from '@agoy/document';
import { ConfirmationCertificate } from '../../common/types/confirmationCertificate';
import { field, ixbrlField } from '../../common/utils/util';

export const confirmationCertificateConfig = (
  standalone: boolean
): ConfirmationCertificate => {
  const annualReportId = (id) => (standalone ? `annualReport.${id}` : id);

  return {
    active: undefined,
    section: {
      active: undefined,
      heading: field(''),
      clientNameText: field(''),
      organisationNumberText: field(''),
      date: ixbrlField(field(''), {
        type: 'stringItemType',
        name: 'se-bol-base:UnderskriftFastallelseintygDatum',
        contextRef: 'balans0',
      }),
      location: ref(id(annualReportId('settings.clientInformation.city'))),
      statement: field(''),
      statementOptionId: field(''),
      signatureClarification: ixbrlField(field(''), {
        // in digital submission this refers to given name
        type: 'stringItemType',
        name: 'se-bol-base:UnderskriftFaststallelseintygForetradareTilltalsnamn',
        contextRef: 'period0',
      }),
      dsSignatureClarificationSurname: ixbrlField(field(''), {
        type: 'stringItemType',
        name: 'se-bol-base:UnderskriftFaststallelseintygForetradareEfternamn',
        contextRef: 'period0',
      }),
      dsIncomeStatementBalanceSheet: ixbrlField(field(''), {
        type: 'stringItemType',
        name: 'se-bol-base:FaststallelseResultatBalansrakning',
        contextRef: 'balans0',
      }),
      dsBoardApproval: ixbrlField(field(''), {
        type: 'stringItemType',
        name: 'se-bol-base:ArsstammaResultatDispositionGodkannaStyrelsensForslag',
        contextRef: 'balans0',
      }),
      dsBoardDisapproval: ixbrlField(field(''), {
        type: 'stringItemType',
        name: 'se-bol-base:ArsstammaResultatDispositionInteGodkannaStyrelsensForslag',
        contextRef: 'balans0',
      }),
      dsDispositionDecision: ixbrlField(field(''), {
        type: 'stringItemType',
        name: 'se-bol-base:ArsstammaResultatDispositionBeslutstext',
        contextRef: 'balans0',
      }),
      dsOriginalsMatchVerificaton: ixbrlField(field(''), {
        type: 'stringItemType',
        name: 'se-bol-base:IntygandeOriginalInnehall',
        contextRef: 'balans0',
      }),
      dsRole: ixbrlField(field(''), {
        type: 'stringItemType',
        name: 'se-bol-base:UnderskriftFaststallelseintygForetradareForetradarroll',
        contextRef: 'period0',
      }),
    },
  };
};

export const confirmationCertificateReferences = (): Record<
  string,
  string
> => ({});

import { label, value } from '@agoy/document';
import {
  Note2Section,
  Note74Section,
  NoteInputFieldSectionNoComment,
  NoteOneTableSection,
  NoteOneTableWithSum,
  NoteTwoTableSection,
  NoteInvertedTwoTableSection,
  NoteThreeCommentThreeTableSection,
} from '../../../../common';

import { AnnualReportType } from '../../../../document';
import getNote3 from '../Notes/note3';
import getNote4 from '../Notes/note4';
import getNote5 from '../Notes/note5';
import getNote59 from '../Notes/note59';
import getNote60 from '../Notes/note60';
import getNote61 from '../Notes/note61';
import getNote62 from '../Notes/note62';
import getNote63 from '../Notes/note63';
import getNote64 from '../Notes/note64';
import getNote2 from '../Notes/note2';
import getNote74 from '../k3-notes/note74';
import getNote79 from '../k3-notes/note79';
import getNote81 from '../k3-notes/note81';
import { getNote85 } from '../k3-notes/note85';
import { getNote89 } from '../k3-notes/note89';
import { getNote90 } from '../k3-notes/note90';
import { getNote91 } from '../k3-notes/note91';
import { getNote93 } from '../k3-notes/note93';
import { getNote101 } from '../k3-notes/note101';

export interface IncomeStatementNotes {
  note2: Note2Section;
  note3: NoteOneTableSection;
  note4: NoteOneTableSection;
  note5: NoteOneTableSection;
  note59: NoteOneTableSection;
  note60: NoteInputFieldSectionNoComment;
  note61: NoteOneTableWithSum;
  note62: NoteOneTableWithSum;
  note63: NoteOneTableWithSum;
  note64: NoteTwoTableSection;
  note74: Note74Section;
  note79: NoteInputFieldSectionNoComment;
  note81: NoteInvertedTwoTableSection;
  note85: NoteOneTableSection;
  note89: NoteOneTableSection;
  note90: NoteOneTableSection;
  note91: NoteInvertedTwoTableSection;
  note93: NoteThreeCommentThreeTableSection;
  note101: NoteOneTableSection;
}

export const getSharesCompanyIncomeStatementNotes = (
  reportType: AnnualReportType,
  financialYears: string[],
  financialYear: string,
  period: string | null,
  isDigitalSubmission: boolean,
  documentTypeVersion = '1'
): IncomeStatementNotes => {
  const noteLabel = (name: string) =>
    isDigitalSubmission ? label(name) : value(name);

  return {
    note2: getNote2(
      noteLabel,
      financialYear,
      financialYears,
      period,
      isDigitalSubmission,
      documentTypeVersion
    ),
    note3: getNote3(
      noteLabel,
      financialYear,
      financialYears,
      period,
      documentTypeVersion
    ),
    note4: getNote4(
      noteLabel,
      financialYear,
      financialYears,
      period,
      documentTypeVersion
    ),
    note5: getNote5(
      noteLabel,
      financialYear,
      financialYears,
      period,
      documentTypeVersion
    ),
    note59: getNote59(noteLabel, financialYear, financialYears, period),
    note60: getNote60(),
    note61: getNote61(noteLabel, financialYear, financialYears, period),
    note62: getNote62(noteLabel, financialYear, financialYears, period),
    note63: getNote63(
      noteLabel,
      financialYear,
      financialYears,
      period,
      documentTypeVersion
    ),
    note64: getNote64(),
    // Here starts the notes added for K3 digital submission
    note74: getNote74(noteLabel, financialYear, financialYears, period),
    note79: getNote79(),
    note81: getNote81(noteLabel, financialYear, financialYears, period),
    note85: getNote85(noteLabel, financialYear, financialYears, period),
    note89: getNote89(noteLabel, financialYear, financialYears, period),
    note90: getNote90(noteLabel, financialYear, financialYears, period),
    note91: getNote91(noteLabel, financialYear, financialYears, period),
    note93: getNote93(noteLabel, financialYear, financialYears, period),
    note101: getNote101(
      noteLabel,
      financialYear,
      financialYears,
      period,
      documentTypeVersion
    ),
  };
};

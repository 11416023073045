import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { CTAButton } from '_shared/components/Buttons';
import ConfirmationDialog from '_shared/components/Dialogs/ConfirmationDialog';

type Props = { handleDisconnect: () => Promise<void> };

const DisconnectFortnox = ({ handleDisconnect }: Props): JSX.Element => {
  const { formatMessage } = useIntl();

  const [confirming, setConfirming] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const onDisconnectConfirm = async () => {
    setConfirming(false);
    setIsDisabled(true);
    await handleDisconnect();
    setIsDisabled(false);
  };

  return (
    <>
      <CTAButton
        disabled={isDisabled}
        onClick={() => {
          setConfirming(true);
        }}
      >
        {formatMessage({ id: 'organisation.fortnox.disconnect' })}
      </CTAButton>
      {confirming && (
        <ConfirmationDialog
          onAccept={onDisconnectConfirm}
          onClose={async () => {
            setConfirming(false);
          }}
          text={formatMessage({
            id: 'organisation.fortnox.disconnect.confirm',
          })}
        />
      )}
    </>
  );
};

export default DisconnectFortnox;

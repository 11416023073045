import React from 'react';
import {
  Notes,
  Note2Section as Note2SectionType,
} from '@agoy/annual-report-document';
import { FormattingContextProvider } from '@agoy/formatting';
import { numberValue, stringValue } from '@agoy/document';
import Comment from '../../UI/Comment';
import EmployeesToggle from './EmployeesToggle';
import Header from '../../UI/Header';
import FieldSection from '../../UI/FieldSection';
import { Note } from '../../UI/Note';
import NoteTable from '../../UI/NoteTable';

type Note2Props = {
  notes: Notes;
  hasPreviousYear?: boolean;
} & Note2SectionType;

const Note2 = ({
  active,
  number,
  comment,
  notes,
  hasPreviousYear = false,
}: Note2Props) => {
  const {
    name,
    avgNumberOfEmployees,
    avgNumberOfEmployeesTable,
    commentLabel,
  } = notes.note2;

  if (!active) return null;

  return (
    <Note id="note2">
      <Header
        noteId="note2"
        noteNumber={numberValue(number)}
        title={stringValue(name) || ''}
      />

      <FormattingContextProvider displayInThousands={false}>
        <EmployeesToggle
          toggleSections={[
            {
              type: 'field',
              section: 'note2',
              id: 'avgNumberOfEmployees',
              active: avgNumberOfEmployees.active,
            },
            {
              type: 'table',
              section: 'note2',
              id: 'avgNumberOfEmployeesTable',
              active: avgNumberOfEmployeesTable.active,
            },
          ]}
          notes={notes}
        >
          <FieldSection value={stringValue(avgNumberOfEmployees) || ''} />

          <NoteTable
            hasPreviousYear={hasPreviousYear}
            table={avgNumberOfEmployeesTable}
            tableId="notes.note2.avgNumberOfEmployeesTable"
          />
        </EmployeesToggle>
      </FormattingContextProvider>

      {/* Comment */}
      <Comment
        field={comment}
        value={stringValue(comment) || ''}
        labelValue={stringValue(commentLabel) || ''}
      />
    </Note>
  );
};

export default Note2;

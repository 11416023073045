import React from 'react';
import styled from '@emotion/styled';

import TabRow from './TabRow';
import { ConfigTab, TabContentProps } from './config';

const Content = styled.div`
  padding-bottom: ${(props) => props.theme.spacing(2)}px;
  padding-left: ${(props) => props.theme.spacing(2)}px;
  padding-right: ${(props) => props.theme.spacing(2)}px;
`;

interface Props {
  tabs: ConfigTab[];
  currentTabId: string;
  onTabChange: (_: any, newTabIndex: number) => void;
  tabContentProps: TabContentProps;
}

const Tabs = ({ currentTabId, tabs, onTabChange, tabContentProps }: Props) => {
  return (
    <>
      <TabRow
        currentTabId={currentTabId}
        onTabChange={onTabChange}
        tabs={tabs}
      />
      <Content>
        {tabs
          .find((tab) => tab.id === currentTabId)
          ?.component(tabContentProps)}
      </Content>
    </>
  );
};

export default Tabs;

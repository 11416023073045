import React from 'react';
import styled from '@emotion/styled';
import { getStatusColor } from 'utils';
import { LampValue } from '_clients/types/types';

interface ShapeElementProps {
  status: LampValue;
  type?: string;
}

// fix colors after removing of feature flag
const ShapeElement = styled.div<ShapeElementProps>`
  background: ${({ status }) =>
    status === 'doesNotExist' ? 'white' : getStatusColor(status)};
  height: 8px;
  border-radius: ${({ theme }) => theme.spacing(1 / 4)}px;
  width: ${({ type }) => (type === 'legend' ? '1rem' : '100%')};

  ${({ status, theme }) =>
    status === 'doesNotExist'
      ? `border: 1px solid ${theme.palette.grey[400]};`
      : ''};
`;

interface AccountStatusProps {
  status: LampValue;
  type?: string;
}

const AccountStatus = ({ status, type }: AccountStatusProps) => (
  <ShapeElement status={status} type={type} />
);

export default AccountStatus;

import { twoDigitsRound } from '@agoy/common';
import { AccountRow, KeyFigure, SummarizationContext } from '../types';
import solidity from './solidity';

type Range = [number, number];

const standardKeyFigure = (
  id: string,
  ranges: Range[],
  fn: (...valuesFromRanges: number[]) => number
): ((
  context: SummarizationContext,
  accounts: (start: number, end: number) => AccountRow[]
) => KeyFigure) => {
  return (context, accounts) => {
    const accountRanges = ranges.map((range) =>
      accounts(...range).reduce(
        (sum, account) => ({
          ib: sum.ib + account.ib,
          ub: sum.ub + account.ub,
          change: sum.change + account.change,
        }),
        { ib: 0, ub: 0, change: 0 }
      )
    );
    return {
      type: 'key',
      id,
      ib: fn(...accountRanges.map((account) => account.ib)),
      ub: fn(...accountRanges.map((account) => account.ub)),
      change:
        id === 'refiPercent' || id === 'grossProfitMargin'
          ? fn(...accountRanges.map((account) => account.ub)) -
            fn(...accountRanges.map((account) => account.ib))
          : fn(...accountRanges.map((account) => account.change)),
    };
  };
};

/**
 * Title: "Resultat efter finansiella poster (REFI)"
 *
 * id: refi / refiAgain
 *
 * sum(3000:8799)
 */
export const refi = (id: 'refi' | 'refiAgain') =>
  standardKeyFigure(id, [[3000, 8799]], (a) => twoDigitsRound(a));

/**
 * Title: "REFI i %"
 *
 * id: refiPercent / refiPercentAgain
 *
 * sum(3000:8799) / sum(3000:3999)
 */
export const refiPercent = (id: 'refiPercent' | 'refiPercentAgain') =>
  standardKeyFigure(
    id,
    [
      [3000, 8799],
      [3000, 3999],
    ],
    (a, b) => Math.round((1000 * a) / b) / 1000
  );

/**
 * Title: "Bruttovinst"
 *
 * id: grossProfit
 *
 * sum(3000:3799) + sum(4000:4999)
 */
export const grossProfit = standardKeyFigure(
  'grossProfit',
  [
    [3000, 3799],
    [4000, 4999],
  ],
  (a, b) => twoDigitsRound(a + b)
);

/**
 * Title "Bruttovinstmarginal"
 *
 * id: grossProfitMargin
 *
 * (sum(3000:3799) + sum(4000:4999)) / sum(3000:3799)
 */
export const grossProfitMargin = standardKeyFigure(
  'grossProfitMargin',
  [
    [3000, 3799],
    [4000, 4999],
    [3000, 3799],
  ],
  (a, b, c) => Math.round((1000 * (a + b)) / c) / 1000
);

/**
 * Title "Beräknat resultat"
 *
 * id: estimatedResult
 *
 * sum(3000:8989) if sum(8990:8999) = 0
 */
export const estimatedResult = standardKeyFigure(
  'estimatedResult',
  [
    [3000, 8989],
    [8990, 8999],
  ],
  (a, b) => (Math.round(b) === 0 ? twoDigitsRound(a) : Number.NaN)
);

/**
 * Title "Resultat"
 *
 * id: result
 *
 * sum(8990:8999) if != 0
 */

export const result = standardKeyFigure('result', [[8990, 8999]], (a) =>
  Math.round(a) === 0 ? Number.NaN : twoDigitsRound(a)
);

export const getKeyFigureById = (
  id: string
): ((
  context: SummarizationContext,
  accounts: (start: number, end: number) => AccountRow[]
) => KeyFigure) => {
  const keyFigures = {
    refi: refi('refi'),
    refiAgain: refi('refiAgain'),
    refiPercent: refiPercent('refiPercent'),
    refiPercentAgain: refiPercent('refiPercentAgain'),
    grossProfit,
    grossProfitMargin,
    estimatedResult,
    result,
    solidity,
  };

  return keyFigures[id];
};

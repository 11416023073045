import React from 'react';
import styled from '@emotion/styled';
import { useSelector } from 'redux/reducers';
import PropertyTaxPrintTable from './PropertyTaxPrintTable';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 30px;
`;

const PropertyTaxPreviewPrint = () => {
  const { propertyTaxTableData } = useSelector((state) => state.accountingView);

  return (
    <Wrapper>
      <PropertyTaxPrintTable data={propertyTaxTableData} />
    </Wrapper>
  );
};

export default PropertyTaxPreviewPrint;

import { Status } from '_shared/types';

/**
 * Gives you a list of disabled options based on the current selected option
 * DONE status is available if the current status is AW_REVIEW or DONE
 *
 * @param currentStatus
 * @returns
 */
const getDisabledStatuses = (currentStatus: Status | null): Status[] => {
  return currentStatus === null ||
    !['AW_REVIEW', 'DONE', 'LOCKED'].includes(currentStatus)
    ? ['DONE']
    : [];
};

export default getDisabledStatuses;

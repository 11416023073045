import { ReferenceAccountInformation, TimePeriod } from '@agoy/document';
import { AnnualReportCustomerType, AccountsStructure } from './common';
import {
  individualCompanyConfig,
  individualCompanyGroupedNotes,
} from './individual-company';
import {
  sharesCompanyConfig,
  sharesCompanyConfigV2,
  getSharesCompanyNotes,
} from './shares-company';
import { individualCompanyReferences } from './individual-company/references/individualCompanyReferences';
import { sharesCompanyReferences } from './shares-company/references/references';
import {
  IndividualCompanyBalanceSheetStructure,
  individualCompanyBalanceSheetAccounts,
} from './individual-company/balance-sheet/individualCompanyBalanceSheet';
import {
  IndividualCompanyIncomeStatementStructure,
  individualCompanyIncomeStatementAccounts,
} from './individual-company/income-statement/individualCompanyIncomeStatement';
import {
  EconomicAssociationBalanceSheetStructure,
  economicAssociationBalanceSheetAccounts,
} from './economic-association/balance-sheet/economicAssociationBalanceSheet';
import {
  EconomicAssociationIncomeStatementStructure,
  economicAssociationIncomeStatementAccounts,
} from './economic-association/income-statement/economicAssociationIncomeStatement';
import {
  economicAssociationConfig,
  economicAssociationNotes,
} from './economic-association';
import { economicAssociationReferences } from './economic-association/reference/economicAssociationReference';
import {
  limitedCompaniesConfig,
  limitedCompaniesNotes,
} from './limited-companies';
import { limitedCompaniesReferences } from './limited-companies/references/limitedCompaniesReference';
import {
  limitedCompaniesBalanceSheetAccounts,
  LimitedCompaniesBalanceSheetStructure,
} from './limited-companies/balance-sheet/limitedCompaniesBalanceSheet';
import {
  limitedCompaniesIncomeStatementAccounts,
  LimitedCompaniesIncomeStatementStructure,
} from './limited-companies/income-statement/limitedCompaniesIncomeStatement';
import {
  AnnualReport,
  AnnualReportType,
  CompaniesAnnualReports,
  ContentDefinitionVariants,
  DocumentConfiguration,
} from './document';
import { nonProfitOrgConfig, nonProfitOrgNotes } from './non-profit-org';
import {
  nonProfitOrgIncomeStatementAccounts,
  NonProfitOrgIncomeStatementStructure,
} from './non-profit-org/income-statement/nonProfitOrgIncomeStatement';
import {
  nonProfitOrgBalanceSheetAccounts,
  NonProfitOrgBalanceSheetStructure,
} from './non-profit-org/balance-sheet/nonProfitOrgBalanceSheet';
import { nonProfitOrgReferences } from './non-profit-org/reference/nonProfitOrgReference';
import { foundationCompanyNotes, foundationConfig } from './foundation-company';
import { foundationReferences } from './foundation-company/reference/foundationReference';
import {
  foundationIncomeStatementAccounts,
  FoundationIncomeStatementStructure,
} from './foundation-company/income-statement/foundationIncomeStatement';
import {
  foundationBalanceSheetAccounts,
  FoundationBalanceSheetStructure,
} from './foundation-company/balance-sheet/foundationBalanceSheet';
import {
  SharesCompanyIncomeStatementStructure,
  sharesCompanyIncomeStatementAccounts,
} from './shares-company/income-statement/v1/incomeStatement';
import {
  sharesCompanyBalanceSheetAccounts,
  SharesCompanyBalanceSheetStructure,
} from './shares-company/balance-sheet/v1/balanceSheet';
import { sharesCompanyNotesAccountStructure } from './shares-company/notes/v1/notes';
import {
  SharesCompanyManagementReportStructure,
  sharesCompanyManagementReportAccountRange,
} from './shares-company/management-report/managementReport';
import {
  Documentation,
  sharesCompanyDocumentation,
} from './shares-company/documentation/documentation';
import { ClientInformationTables } from './shares-company/types';

type BalanceSheetStructure =
  | SharesCompanyBalanceSheetStructure
  | IndividualCompanyBalanceSheetStructure
  | EconomicAssociationBalanceSheetStructure
  | LimitedCompaniesBalanceSheetStructure
  | NonProfitOrgBalanceSheetStructure
  | FoundationBalanceSheetStructure;

const balanceSheetAccountStructure = (
  companyType: keyof CompaniesAnnualReports,
  reportType: AnnualReportType,
  isDigitalSubmission: boolean
): BalanceSheetStructure | null => {
  switch (companyType) {
    case 'individual':
      return individualCompanyBalanceSheetAccounts;
    case 'economic_association':
      return economicAssociationBalanceSheetAccounts;
    case 'limited_companies':
      return limitedCompaniesBalanceSheetAccounts;
    case 'non_profit_association':
      return nonProfitOrgBalanceSheetAccounts;
    case 'foundation':
      return foundationBalanceSheetAccounts;
    default:
      return sharesCompanyBalanceSheetAccounts(reportType, isDigitalSubmission);
  }
};

type IncomeStatementStructure =
  | SharesCompanyIncomeStatementStructure
  | IndividualCompanyIncomeStatementStructure
  | EconomicAssociationIncomeStatementStructure
  | LimitedCompaniesIncomeStatementStructure
  | NonProfitOrgIncomeStatementStructure
  | FoundationIncomeStatementStructure;

const incomeStatementAccountStructure = (
  companyType: keyof CompaniesAnnualReports,
  reportType: AnnualReportType
): IncomeStatementStructure | null => {
  switch (companyType) {
    case 'individual':
      return individualCompanyIncomeStatementAccounts;
    case 'economic_association':
      return economicAssociationIncomeStatementAccounts;
    case 'limited_companies':
      return limitedCompaniesIncomeStatementAccounts;
    case 'non_profit_association':
      return nonProfitOrgIncomeStatementAccounts;
    case 'foundation':
      return foundationIncomeStatementAccounts;
    default:
      return sharesCompanyIncomeStatementAccounts(reportType);
  }
};

type ManagementReportAccountStructure = SharesCompanyManagementReportStructure;

const managementReportAccountStructure = (
  companyType: keyof CompaniesAnnualReports,
  reportType: AnnualReportType,
  isDigitalSubmission: boolean,
  documentTypeVersion = '1'
): ManagementReportAccountStructure | null => {
  switch (companyType) {
    case 'individual':
    case 'economic_association':
    case 'limited_companies':
    case 'non_profit_association':
    case 'foundation':
      return null;
    default:
      return sharesCompanyManagementReportAccountRange(
        reportType,
        documentTypeVersion,
        isDigitalSubmission
      );
  }
};

export const getAccountsStructure = (
  section: 'balanceSheet' | 'incomeStatement' | 'managementReport',
  companyType: keyof CompaniesAnnualReports,
  reportType: AnnualReportType,
  isDigitalSubmission: boolean,
  documentTypeVersion = '1'
): AccountsStructure | null => {
  if (section === 'balanceSheet') {
    return balanceSheetAccountStructure(
      companyType,
      reportType,
      isDigitalSubmission
    );
  }

  if (section === 'managementReport') {
    return managementReportAccountStructure(
      companyType,
      reportType,
      isDigitalSubmission,
      documentTypeVersion
    );
  }

  // income section
  return incomeStatementAccountStructure(companyType, reportType);
};

export const getNotesAccountStructure = (
  companyType: keyof CompaniesAnnualReports
) => {
  switch (companyType) {
    case 'individual':
      return [];
    case 'economic_association':
      return [];
    case 'limited_companies':
      return [];
    case 'non_profit_association':
      return [];
    case 'foundation':
      return [];
    default:
      return sharesCompanyNotesAccountStructure;
  }
};

export type NotesStructure = {
  incomeStatementNotes: string[];
  balanceSheetNotes: string[];
  otherNotes: string[];
};

export const getAnnualReportNotesStructure = (
  companyType: keyof CompaniesAnnualReports,
  reportType: AnnualReportType,
  documentTypeVersion = '1'
): NotesStructure => {
  switch (companyType) {
    case 'individual':
      return individualCompanyGroupedNotes;
    case 'economic_association':
      return economicAssociationNotes;
    case 'limited_companies':
      return limitedCompaniesNotes;
    case 'non_profit_association':
      return nonProfitOrgNotes;
    case 'foundation':
      return foundationCompanyNotes;
    default:
      return getSharesCompanyNotes(reportType, documentTypeVersion);
  }
};

const getAnnualReportConfig = (variant: ContentDefinitionVariants) => {
  switch (variant.documentType) {
    case 'shares':
      switch (variant.version) {
        case '2':
          return sharesCompanyConfigV2;
        case '1':
        default:
          return sharesCompanyConfig;
      }
    case 'individual':
      return individualCompanyConfig;
    case 'economic_association':
      return economicAssociationConfig;
    case 'limited_companies':
      return limitedCompaniesConfig;
    case 'non_profit_association':
      return nonProfitOrgConfig;
    case 'foundation':
      return foundationConfig;
    default:
      return sharesCompanyConfig;
  }
};

const getAnnualReportReferences = (
  companyType: keyof CompaniesAnnualReports
) => {
  switch (companyType) {
    case 'individual':
      return individualCompanyReferences;
    case 'economic_association':
      return economicAssociationReferences;
    case 'limited_companies':
      return limitedCompaniesReferences;
    case 'non_profit_association':
      return nonProfitOrgReferences;
    case 'foundation':
      return foundationReferences;
    default:
      return sharesCompanyReferences;
  }
};

/**
 * Creates a new configuration of a annual report
 * @param customer
 * @param period  The time period for the financial year's start to
 *                the end of the current month
 * @param previousPeriod  The time period for the previous year's start
 *                        to the end of the current month minus one year
 * @param documentConfiguration Settings that affect the content of the report.
 */
export const config = (
  customer: AnnualReportCustomerType,
  period: TimePeriod,
  previousPeriod: TimePeriod | null,
  accounts: Record<string, ReferenceAccountInformation>,
  documentConfiguration: DocumentConfiguration,
  isSinglePeriod: boolean,
  clientInformationTables?: ClientInformationTables
): AnnualReport => {
  const annualReportConfig = getAnnualReportConfig(documentConfiguration);

  return annualReportConfig(
    customer,
    period,
    previousPeriod,
    accounts,
    documentConfiguration,
    isSinglePeriod,
    clientInformationTables
  );
};

type References = {
  [key: string]: string;
};

/**
 * Creates all references that the annual report refers to
 */
export const references = (
  documentConfiguration: DocumentConfiguration
): References => {
  const getReferences = getAnnualReportReferences(
    documentConfiguration.documentType
  );
  const partReferences = getReferences(documentConfiguration);

  // Verify that there is no name conflict
  let references = {};
  partReferences.forEach((refs) => {
    const keysSoFar = Object.keys(references);
    const conflict = Object.keys(refs).find((ref) => keysSoFar.includes(ref));
    if (conflict) {
      throw new Error(`Conflicting keys in references (${conflict})`);
    }
    references = { ...references, ...refs };
  });

  return references;
};

export const getDocumentation = (
  companyType: keyof CompaniesAnnualReports,
  reportType: AnnualReportType
): Documentation | undefined => {
  switch (companyType) {
    case 'shares':
      return sharesCompanyDocumentation(reportType);
    default:
      return undefined;
  }
};

const isInputCorrect = (
  inputSaldo: number | undefined | null | string,
  saldo: number
) => {
  if (typeof inputSaldo === 'string') {
    return Math.abs(parseFloat(inputSaldo) - saldo) <= 1;
  }

  if (typeof inputSaldo === 'number') {
    return Math.abs(inputSaldo - saldo) <= 1;
  }

  return false;
};

export default isInputCorrect;

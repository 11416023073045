import React from 'react';
import styled from '@emotion/styled';
import { getClasses } from '@agoy/common';
import { TypographyProps, Variant } from './Typography.types';

const variantMapping: { [key in Variant]: string } = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  body1: 'p',
  body2: 'p',
  caption: 'p',
  label: 'label',
};

const TypographyRoot = styled('span')`
  font-family: 'Exo 2';

  font-variant-numeric: tabular-nums;
  font-feature-settings: 'tnum';

  white-space: pre-wrap;

  margin: 0;

  &[class*='variant-h'] {
    line-height: 130%;
    font-weight: 700;
  }

  &.variant-h1 {
    font-size: 33.16px;

    &.margin-top {
      margin-top: ${({ theme }) => theme.spacing(4)}px;
    }

    &.margin-bottom {
      margin-bottom: ${({ theme }) => theme.spacing(2)}px;
    }
  }

  &.variant-h2 {
    font-size: 24.88px;

    &.margin-top {
      margin-top: ${({ theme }) => theme.spacing(3)}px;
    }

    &.margin-bottom {
      margin-bottom: ${({ theme }) => theme.spacing(2)}px;
    }
  }

  &.variant-h3 {
    font-size: 18.66px;

    &.margin-top {
      margin-top: ${({ theme }) => theme.spacing(3)}px;
    }

    &.margin-bottom {
      margin-bottom: ${({ theme }) => theme.spacing(1)}px;
    }
  }

  &.variant-h4 {
    font-size: 14px;

    &.margin-top {
      margin-top: ${({ theme }) => theme.spacing(2)}px;
    }

    &.margin-bottom {
      margin-bottom: ${({ theme }) => theme.spacing(1)}px;
    }
  }

  &.variant-h5 {
    line-height: 125%;
    font-size: 10.5px;

    &.margin-top {
      margin-top: ${({ theme }) => theme.spacing(1.5)}px;
    }

    &.margin-bottom {
      margin-bottom: ${({ theme }) => theme.spacing(0.5)}px;
    }
  }

  &[class*='variant-body'],
  &.variant-caption {
    line-height: 150%;
    font-weight: 400;

    &.style-bold {
      font-weight: 700;
    }

    &.style-italic {
      font-style: italic;
    }
  }

  &.variant-body1 {
    font-size: 14px;

    &.margin-top {
      margin-top: 0;
    }

    &.margin-bottom {
      margin-bottom: ${({ theme }) => theme.spacing(2)}px;
    }
  }

  &.variant-body2 {
    font-size: 12px;

    &.margin-top {
      margin-top: 0;
    }

    &.margin-bottom {
      margin-bottom: ${({ theme }) => theme.spacing(1.5)}px;
    }
  }

  &.variant-caption {
    font-size: 10.5px;

    &.margin-top {
      margin-top: 0;
    }

    &.margin-bottom {
      margin-bottom: ${({ theme }) => theme.spacing(1)}px;
    }
  }

  &.color-onDark {
    color: ${({ theme }) => theme.palette.fortnoxText.onDark};
  }

  &.color-onLight {
    color: ${({ theme }) => theme.palette.fortnoxText.onLight};
    &[class*='variant-h'] {
      color: ${({ theme }) => theme.palette.fortnoxText.onLightHeading};
    }
  }

  &.color-readOnlyOnLight {
    color: ${({ theme }) => theme.palette.fortnoxText.readOnlyOnLight};
  }

  &.color-placeholder {
    color: ${({ theme }) => theme.palette.fortnoxText.placeholder};
  }

  &.color-disabled {
    color: ${({ theme }) => theme.palette.fortnoxText.disabled};
  }
`;
const Typography = ({
  children,
  as,
  variant = 'body1',
  textStyle = 'regular',
  color = 'onLight',
  margin = 'both',
  htmlFor,
}: TypographyProps) => {
  const Component: any = as ?? variantMapping[variant];
  const marginClasses = getClasses({
    'margin-top': margin === 'top' || margin === 'both',
    'margin-bottom': margin === 'bottom' || margin === 'both',
  });

  const additionalProps = Component === 'label' ? { htmlFor } : {};

  return (
    <TypographyRoot
      as={Component}
      className={`variant-${variant} style-${textStyle} color-${color} ${marginClasses}`}
      {...additionalProps}
    >
      {children}
    </TypographyRoot>
  );
};

export default Typography;

import React from 'react';
import { IxbrlCell, IxbrlField } from '@agoy/annual-report-document';
import {
  NonNumeric,
  NonFraction,
  NonFractionPercent,
  NonFractionDecimal,
  Tuple,
} from './xml/IX';
import { isMultiReferenceCell } from '@agoy/document';

/**
 * The map of Ixbrl item type - IX element
 */
export const IxbrlTypeToIXElement = {
  stringItemType: NonNumeric,
  monetaryItemType: NonFraction,
  pureItemType: NonFractionPercent,
  decimalItemType: NonFractionDecimal,
  dateItemType: NonNumeric,
  booleanItemType: NonNumeric,
  enumerationItemType: NonNumeric,
  sharesItemType: NonFraction,
};

type IXElementProps = {
  arPart: IxbrlField | IxbrlCell;
  children: React.ReactNode;
  contextRef?: string;
  [x: string]: unknown;
};

/**
 * Function that generates the IX element to be used for a given ixbrl field / cell in the annual report
 * The element has default attributes applied but can be overridden
 *
 * @param
 * @returns IX element wrapping children or children if no mapping is applied for id
 */
export const IXElement = ({
  arPart,
  children,
  hasPreviousYear,
  ...rest
}: IXElementProps): React.ReactElement => {
  if (isMultiReferenceCell(arPart)) return <>{children}</>;

  const { value, ixbrl: ixProps } = arPart;

  // do not add tag if value is not present (0 is still a valid value), unless first tuple column
  if (ixProps?.tupleRef?.order !== "1.0" && (value === '' || value === undefined)) return <>{children}</>;

  if (ixProps) {
    const { type, tuple, previousYearTuple, tupleRef, ...defaultProps } =
      ixProps;

    const Element = IxbrlTypeToIXElement[type];

    if (Element) {
      const mergedProps = Object.keys(defaultProps).reduce(
        (acc, key) => ({ ...acc, [key]: rest[key] || defaultProps[key] }),
        {}
      ) as typeof defaultProps;

      // remove the last character from the string below
      const contextRef = mergedProps.contextRef.slice(0, -1);

      const tupleElement = tuple ? (
        <Tuple name={tuple.name} id={tuple.id} tupleID={tuple.tupleID} />
      ) : null;

      const tupleElementWithPreviousYear =
        tuple && previousYearTuple ? (
          <>
            <Tuple
              name={tuple.name}
              id={`${tuple.id}_${contextRef}0`}
              tupleID={`${tuple.tupleID}_${contextRef}0`}
            />
            {hasPreviousYear && (
              <Tuple
                name={tuple.name}
                id={`${tuple.id}_${contextRef}1`}
                tupleID={`${tuple.tupleID}_${contextRef}1`}
              />
            )}
          </>
        ) : null;

      // Left for now, untoggle to see what IXBL elements are tagged
      // console.log('Using iXBRL element', mergedProps);

      if (type === 'monetaryItemType' || type === 'pureItemType') {
        const { sign } = rest;
        return (
          <>
            {tupleElement}
            {Element({
              children,
              cellValue: value as number,
              sign,
              ...mergedProps,
              ...tupleRef,
            })}
          </>
        );
      }
      if (type === 'stringItemType' || type === 'enumerationItemType') {
        return (
          <>
            {/* Keep rendering the way it was if the tuple has no previousYear */}
            {previousYearTuple ? tupleElementWithPreviousYear : tupleElement}
            {Element({
              children,
              ...mergedProps,
              ...tupleRef,
              previousYearTuple: previousYearTuple,
              hasPreviousYear,
            })}
          </>
        );
      }

      return (
        <>
          {/* Keep rendering the way it was if the tuple has no previousYear and it's not a title */}
          {tupleElement}
          {Element({ children, ...mergedProps, ...tupleRef })}
        </>
      );
    }
    console.warn('Unknown element for ixbrl type: ', type);
  }

  return <>{children}</>;
};

import { useCallback } from 'react';
import { UserError } from 'types/Error/types';
import { ApiSdk, asResultClass, useApiSdk } from 'api-sdk';

type GenerateSieFile = (
  clientId: string,
  financialYear: string
) => Promise<Blob>;

export const generateSieFile =
  (sdk: ApiSdk): GenerateSieFile =>
  async (clientId, financialYear) => {
    const response = await asResultClass(
      sdk.getSieFile({ clientid: clientId, financialYear })
    );

    if (response.ok) {
      return response.val;
    }

    const error = response.val;
    console.error(error);
    if (error.name === 'FINANCIAL_YEAR_NOT_FOUND') {
      throw new UserError('sie.generate.error.FINANCIAL_YEAR_NOT_FOUND');
    }
    throw new UserError('sie.generate.failed');
  };

export const useGenerateSieFile = (): GenerateSieFile => {
  const sdk = useApiSdk();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(generateSieFile(sdk), [sdk]);
};

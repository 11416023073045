import {
  TaxDeclarationFormDefinition,
  TaxDeclarationFormDataConfig,
  PersonMetaData,
} from '../../types';
import { id, ref, value } from '@agoy/document';
import { mapFormId, toInitial, toStructure } from '../forms-util';

const configK12 = (
  form: TaxDeclarationFormDefinition,
  person: PersonMetaData
): TaxDeclarationFormDataConfig => {
  const name = `${person?.firstName || ''} ${person?.lastName || ''}`;
  const K12 = mapFormId(form, 'K12');
  const initial = {
    K12: {
      partType: 'form' as const,
      id: K12,
      fields: {
        datum: value(''),
        numrering_vid_flera_k12: value(undefined),
        namn: value(name),
        personnummer: value(person.personNumber || ''),
        foretagets_namn: value(''),
        foretagets_organisationsnummer: value(''),
        utlandsk_bolag: value(false),
        A_1_utdelning: value(undefined),
        A_2_utdelning_kapital: value(undefined),
        B_1_antal: value(undefined),
        B_1_forsaljningspris: value(undefined),
        B_1_omkostnadsbelopp: value(undefined),
        B_1_vinst: value(undefined),
        B_1_forlust: value(undefined),
        B_2_antal: value(undefined),
        B_2_forsaljningspris: value(undefined),
        B_2_omkostnadsbelopp: value(undefined),
        B_2_vinst: value(undefined),
        B_2_forlust: value(undefined),
        B_3_antal: value(undefined),
        B_3_forsaljningspris: value(undefined),
        B_3_omkostnadsbelopp: value(undefined),
        B_3_vinst: value(undefined),
        B_3_forlust: value(undefined),
        B_summerad_vinst: value(undefined),
        B_summerad_forlust: value(undefined),
        B_vinst_aktier_x_5_6: value(undefined),
        B_forlust_aktier_x_5_6: value(undefined),
        C_1_antal: value(undefined),
        C_1_forsaljningspris: value(undefined),
        C_1_omkostnadsbelopp: value(undefined),
        C_1_vinst: value(undefined),
        C_1_forlust: value(undefined),
        C_2_antal: value(undefined),
        C_2_forsaljningspris: value(undefined),
        C_2_omkostnadsbelopp: value(undefined),
        C_2_vinst: value(undefined),
        C_2_forlust: value(undefined),
        C_3_antal: value(undefined),
        C_3_forsaljningspris: value(undefined),
        C_3_omkostnadsbelopp: value(undefined),
        C_3_vinst: value(undefined),
        C_3_forlust: value(undefined),
        C_summerad_vinst: value(undefined),
        C_forlust_andelar: value(undefined),
        C_vinst_andelar_x_5_6: value(undefined),
      },
      derivedFields: {
        B_forlust: ref(id('K12.fields.B_forlust_aktier_x_5_6')),
        C_forlust: ref(id('K12.fields.C_forlust_andelar')),
      },
      sru: {
        '7014': ref(id('K12.fields.numrering_vid_flera_k12')),
        '4530': ref(id('K12.fields.foretagets_organisationsnummer')),
        '7050': ref(id('K12.fields.utlandsk_bolag')),
        '5105': ref(id('K12.fields.A_1_utdelning')),
        '5103': ref(id('K12.fields.A_2_utdelning_kapital')),
        '5125': ref(id('K12.fields.B_1_antal')),
        '5126': ref(id('K12.fields.B_1_forsaljningspris')),
        '5127': ref(id('K12.fields.B_1_omkostnadsbelopp')),
        '5128': ref(id('K12.fields.B_1_vinst')),
        '5129': ref(id('K12.fields.B_1_forlust')),
        '5135': ref(id('K12.fields.B_2_antal')),
        '5136': ref(id('K12.fields.B_2_forsaljningspris')),
        '5137': ref(id('K12.fields.B_2_omkostnadsbelopp')),
        '5138': ref(id('K12.fields.B_2_vinst')),
        '5139': ref(id('K12.fields.B_2_forlust')),
        '5145': ref(id('K12.fields.B_3_antal')),
        '5146': ref(id('K12.fields.B_3_forsaljningspris')),
        '5147': ref(id('K12.fields.B_3_omkostnadsbelopp')),
        '5148': ref(id('K12.fields.B_3_vinst')),
        '5149': ref(id('K12.fields.B_3_forlust')),
        '5150': ref(id('K12.fields.B_summerad_vinst')),
        '5152': ref(id('K12.fields.B_summerad_forlust')),
        '5121': ref(id('K12.fields.B_vinst_aktier_x_5_6')),
        '5123': ref(id('K12.fields.B_forlust_aktier_x_5_6')),
        '5165': ref(id('K12.fields.C_1_antal')),
        '5166': ref(id('K12.fields.C_1_forsaljningspris')),
        '5167': ref(id('K12.fields.C_1_omkostnadsbelopp')),
        '5168': ref(id('K12.fields.C_1_vinst')),
        '5169': ref(id('K12.fields.C_1_forlust')),
        '5175': ref(id('K12.fields.C_2_antal')),
        '5176': ref(id('K12.fields.C_2_forsaljningspris')),
        '5177': ref(id('K12.fields.C_2_omkostnadsbelopp')),
        '5178': ref(id('K12.fields.C_2_vinst')),
        '5179': ref(id('K12.fields.C_2_forlust')),
        '5185': ref(id('K12.fields.C_3_antal')),
        '5186': ref(id('K12.fields.C_3_forsaljningspris')),
        '5187': ref(id('K12.fields.C_3_omkostnadsbelopp')),
        '5188': ref(id('K12.fields.C_3_vinst')),
        '5189': ref(id('K12.fields.C_3_forlust')),
        '5190': ref(id('K12.fields.C_summerad_vinst')),
        '5124': ref(id('K12.fields.C_forlust_andelar')),
        '5122': ref(id('K12.fields.C_vinst_andelar_x_5_6')),
      },
    },
  };
  return {
    initial: toInitial(initial, form),
    definition: toStructure(initial, form),
  };
};

export default configK12;

import { Period } from '@agoy/api-sdk-core';
import { ACCOUNT_PERIOD_DATE_FORMAT } from 'types/Accounting';
import OverviewTable from 'types/OverviewTable/types';
import { reformatDate } from 'utils';
import { InputData, UserAccountInput } from '_reconciliation/types';

export { createRowData } from './groupAccounts';

/**
 * @param period
 * @param withoutDash if true uses the format yyyyMM otherwise the default yyyy-MM
 * @returns The periods start date formatted as yyyy-MM (or yyyyMM)
 */
export const getFormattedPeriod = (
  period: Period,
  withoutDash = false
): string => {
  return reformatDate(
    period.start,
    ACCOUNT_PERIOD_DATE_FORMAT,
    withoutDash ? 'yyyyMM' : 'yyyy-MM'
  );
};

/**
 * Create the UserAccountInput object with information from
 * the actual balances and the stored user input if one exists.
 *
 * @param rowData Account balances for all periods in year
 * @param account Account number
 * @param backendData UserInputData for the specific account number
 * @returns
 */
export const createUserInputData = (
  rowData: OverviewTable.PeriodInformation[],
  backendData: UserAccountInput
): UserAccountInput => {
  return rowData
    .map((cell): InputData => {
      const period = cell.period.substring(0, 7);
      const periodData = backendData[cell.periodId];

      return {
        comment: periodData ? periodData.comment : '',
        saldo: periodData && periodData.saldo,
        visited: periodData ? periodData.visited : false,
        period,
        legacySpecifications: periodData?.legacySpecifications,
        ib: cell.ib,
        periodId: cell.periodId,
        ub: cell.ub,
        psaldo: cell.ub - cell.ib,
        numImages: periodData ? periodData.numImages : 0,
        printComment: periodData?.printComment ?? true,
        internalComments: periodData ? periodData.internalComments : false,
        checked: periodData?.checked ?? false,
        cashView: periodData?.cashView || {},
        inventoryView: periodData?.inventoryView || {},
      };
    })
    .reduce((obj: UserAccountInput, currVal): UserAccountInput => {
      if (currVal.periodId) {
        return { ...obj, [currVal.periodId]: currVal };
      }
      return obj;
    }, {});
};

export const getNextLegacySpecificationId = (
  specification: Client.LegacySpecification[]
): number => Math.max(...specification.map((o) => o.id), 0) + 1;

export const legacySpecificationSum = (
  specification: Client.LegacySpecification[]
): number => {
  return specification.reduce((acc, curr) => {
    const amount = Math.round(parseFloat(curr.amount));
    return Number.isNaN(amount) ? acc : acc + amount;
  }, 0);
};

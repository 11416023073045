import React from 'react';
import { TableRow, TableHead } from '@material-ui/core';
import { AgoyTableColumn } from '@agoy/document';
import TableHeadCell from './TableHeadCell';

interface TableHeadComponentProps {
  baseId: string;
  tableId: string;
  columns: AgoyTableColumn[];
  renderCell?: (values: {
    column: AgoyTableColumn;
  }) => React.ReactElement | string | null;
}

const TableHeadComponent = ({
  baseId,
  tableId,
  columns,
  renderCell,
}: TableHeadComponentProps): JSX.Element => {
  return (
    <TableHead>
      <TableRow>
        {columns.map((col, index) => (
          <React.Fragment key={col.id}>
            <TableHeadCell
              column={col}
              baseId={baseId}
              tableId={tableId}
              textAlign={index === 0 ? ' left' : ' right'}
              renderCell={renderCell}
            />
          </React.Fragment>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default TableHeadComponent;

import React from 'react';
import styled from '@emotion/styled';
import { isArray } from 'lodash';

import { DebtItemProps, DebtFieldsProps } from './types';

const Container = styled.div`
  display: block;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  align-items: start;
  padding: 5px 0;
  @media print {
    display: block;
    position: relative;
    break-before: always;
    break-inside: avoid;
    -webkit-column-page-break-inside: avoid;
    page-break-inside: avoid;
  }
`;

const InfoRow = styled.div`
  position: relative;
  display: flex;
  align-items: start;
  width: 100%;
  justify-content: space-between;
`;

const DebtInfoColumn = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
`;

const InfoCell = styled.p`
  width: ${(p: DebtFieldsProps) => p.customWidth}px;
  margin: 0px 0px 5px 0px;
  text-align: ${(p: DebtFieldsProps) => p.customAlign || 'left'};
`;

const SummaryInfoCell = styled(InfoCell)`
  border-top: solid 2px black;
`;

const TransactionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 5px;
`;

const DebtItem = ({ colsAmount, fields }: DebtItemProps): JSX.Element => {
  return (
    <Container>
      <TransactionContainer>
        {fields.map((field, index) => {
          return (
            <InfoRow key={`SemestersInfoRow${index}`}>
              {isArray(field) ? (
                <DebtInfoColumn>
                  {field.map((item) => {
                    if (item.title === 'total') {
                      return (
                        <SummaryInfoCell
                          key={item.id}
                          customAlign={item.align}
                          customWidth={colsAmount}
                        >
                          {item.value}
                        </SummaryInfoCell>
                      );
                    }
                    if (item.title !== 'totalContribution') {
                      return (
                        <InfoCell
                          key={item.id}
                          customAlign={item.align}
                          customWidth={colsAmount}
                        >
                          {item.value}
                        </InfoCell>
                      );
                    }
                  })}
                </DebtInfoColumn>
              ) : (
                <InfoCell
                  key={field.id}
                  customAlign={field.align}
                  customWidth={colsAmount}
                >
                  {field.value}
                </InfoCell>
              )}
            </InfoRow>
          );
        })}
      </TransactionContainer>
    </Container>
  );
};

export default DebtItem;

import React from 'react';
import { FieldError, UseFormRegisterReturn } from 'react-hook-form';
import { Typography } from '@material-ui/core';

import {
  ClientInformationDirector,
  ClientInformationField,
} from '@agoy/api-sdk-core';
import LabelField from '_shared/components/Inputs/LabelField';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';
import FormError from '_shared/components/Inputs/FormError';
import When from '_shared/components/When/When';

interface NotEditingValueProps {
  isMissing?: boolean;
}

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${(props) => props.theme.spacing(1)}px
    ${(props) => props.theme.spacing(2)}px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const NotEditingLabel = styled(Typography)`
  font-size: 10px;
  color: ${({ theme }) => theme.palette.grey[700]};
`;

const NotEditingValue = styled(({ isMissing, ...props }) => (
  <Typography {...props} />
))<NotEditingValueProps>`
  ${(props) => !props.value && 'height: 43px;'}
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${(props) =>
    props.isMissing
      ? props.theme.palette.text.secondary
      : props.theme.palette.text.primary};
`;

export interface ClientCardInformationBlockProps extends UseFormRegisterReturn {
  children?: React.ReactNode;
  source?: ClientInformationField['source'];
  timestamp?: ClientInformationField['timestamp'] | number;
  field: string;
  value?: string | number | boolean | ClientInformationDirector[];
  labelField?: string;
  error?: FieldError;
  placeholder?: string;
  isEditing?: boolean;
  autoFocus?: boolean;
  disabled?: boolean;
  required?: boolean;
  inputRef?: React.ForwardedRef<unknown>;
  renderNonEditingValue?: (
    value?: string | number | boolean | ClientInformationDirector[]
  ) => React.ReactNode;
}

const ClientCardDirectorInformationBlock = React.forwardRef(
  (props: ClientCardInformationBlockProps, ref) => {
    const { formatMessage } = useIntl();
    const {
      children,
      source,
      value,
      timestamp,
      field,
      labelField,
      error,
      isEditing,
      autoFocus = false,
      renderNonEditingValue,
      ...inputProps
    } = props;

    const displayValue =
      value || (value !== undefined && formatMessage({ id: 'missing' }));
    const isMissing = !value;

    const getErrorMessage = () => {
      const validationMessages = {
        validate: formatMessage({
          id: `clientInformation.${field}.wrongFormat`,
        }),
        minLength: formatMessage({
          id: `clientInformation.${field}.wrongFormat`,
        }),
        pattern: formatMessage({
          id: `clientInformation.${field}.wrongFormat`,
        }),
        required: formatMessage({ id: `clientInformation.required` }),
      };

      return error ? validationMessages[error.type] : '';
    };

    return (
      <Wrapper>
        {isEditing ? (
          <When
            isTrue={!!children}
            fallback={
              <LabelField
                label={labelField}
                inputProps={{ ...inputProps, autoFocus, inputRef: ref }}
              />
            }
          >
            {children}
          </When>
        ) : (
          <MainWrapper>
            <NotEditingLabel>{labelField}</NotEditingLabel>
            <When
              isTrue={!!renderNonEditingValue}
              fallback={
                <NotEditingValue isMissing={isMissing} value={displayValue}>
                  {displayValue}
                </NotEditingValue>
              }
            >
              {renderNonEditingValue?.(value)}
            </When>
          </MainWrapper>
        )}

        {isEditing && <FormError>{getErrorMessage()}</FormError>}
      </Wrapper>
    );
  }
);

export default ClientCardDirectorInformationBlock;

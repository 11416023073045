import { cloneDeep } from 'lodash';
import { startOfMonth, endOfMonth, sub, isBefore } from 'date-fns';

import { FinancialReport } from '@agoy/financial-report-document';
import {
  AgoyTable,
  AgoyTableColumn,
  AgoyTableRow,
  Cell,
  filterRows,
  stringValue,
} from '@agoy/document';
import {
  AnnualReport,
  AnnualReportChanges,
  AnnualReportVersion,
} from '@agoy/annual-report-document';
import { format, parse } from '@agoy/dates';
import { AccountingBalances, AccountingPeriodBalance } from 'types/Accounting';

export type ReportColors = {
  textMain: string | undefined;
  textSecondary: string | undefined;
  background: string | undefined;
};

const MAX_ROWS_PER_PAGE = 25;

const partsMapping = {
  frontpage: 'frontPage',
  'table-of-contents': 'tableOfContents',
  RRBRDiagram: 'diagramRRBR',
  RRBRTable: 'tableRRBR',
  cashFlowStatement: 'cashFlowDiagram',
  'income-statement-period-part1': 'incomeStatement',
  'income-statement-period-part2': 'incomeStatement',
  'income-statement-part1': 'incomeStatementTables',
  'income-statement-part2': 'incomeStatementTables',
  'balance-sheet-assets-part1': 'balanceSheetAssets',
  'balance-sheet-assets-part2': 'balanceSheetAssets',
  'balance-sheet': 'balanceSheet',
  create: 'create',
  settings: 'settings',
  documents: 'documents',
};

export const getIncomeStatementTableFromReport = (
  report: AnnualReport | FinancialReport | undefined
) => {
  if (report) {
    return report.incomeStatement.section.table;
  }
};

export const getIncomeStatementTableByVersion = (
  table: AgoyTable<Cell> | undefined,
  version: AnnualReportVersion | undefined
) => {
  if (table) {
    if (version === '1' && table.rows[0]?.rows?.[0]) {
      return {
        ...table,
        rows: table.rows[0].rows[0].rows ?? [],
      };
    }
    return table;
  }
};

const splittingTableByRowsNumber = (
  splitRows: AgoyTableRow<Cell>[],
  firstTableRowsNumber: number
) => {
  let counter = 0;
  const splitRowsFunction = (rows, rowsNumber) => {
    const matchedRows: AgoyTableRow<Cell>[] = [];
    const remainingRows: AgoyTableRow<Cell>[] = [];
    rows.forEach((row) => {
      if (counter < rowsNumber) {
        if (!row.rows || (row.rows && row.rows.length === 0)) {
          counter++;
          matchedRows.push(row);
        }
        if (row.rows && row.rows.length > 0) {
          if (counter + 1 === rowsNumber) {
            remainingRows.push(row);
            counter++;
          } else {
            counter++;
            const { matched, remaining } = splitRowsFunction(
              row.rows,
              rowsNumber
            );
            matchedRows.push({
              ...row,
              rows: matched,
            });
            if (remaining.length > 0) {
              remainingRows.push({
                ...row,
                rows: remaining,
              });
            }
          }
        }
      } else {
        remainingRows.push(row);
      }
    });
    return { matched: matchedRows, remaining: remainingRows };
  };

  const partTables = splitRowsFunction(splitRows, firstTableRowsNumber);

  return {
    firstTable: partTables.matched,
    secondTable: partTables.remaining,
  };
};

/**
 * *: These functions should be used for not showing and not calculating rows with zero values in the year
 * *: and previousYear cells and rows without subRows and year/previousYear cells (case most related to v1 tables);
 */
export const isSumRowWithZeroValue = (row: AgoyTableRow<Cell>) => {
  if (row.cells && row.type === 'sum') {
    return Object.entries(row.cells)
      .filter(([id, _]) => ['year', 'previousYear'].includes(id))
      .every(([_, cell]) => 'value' in cell && cell.value === 0);
  }
};

export const isRowWithoutLabel = (row: AgoyTableRow<Cell>) => {
  if (
    row.cells &&
    row.cells.label &&
    'value' in row.cells.label &&
    !row.cells.label.value
  ) {
    return true;
  }
  return false;
};

export const isRowWithoutYearValueAndSubRows = (row: AgoyTableRow<Cell>) => {
  return (
    'rows' in row &&
    row.rows?.length === 0 &&
    row.cells &&
    !('year' in row.cells)
  );
};

export const getTotalRowsNumber = (tableRows: AgoyTableRow<Cell>[]) => {
  const totalRows: AgoyTableRow<Cell>[] = [];

  const getTotalRows = (rows) => {
    rows.forEach((row) => {
      const sumRowWithZeroValue = isSumRowWithZeroValue(row);
      const rowWithoutYearValueAndSubRows =
        isRowWithoutYearValueAndSubRows(row);

      if (
        !row.id.toLowerCase().includes('visuallyHidden'.toLowerCase()) &&
        !sumRowWithZeroValue &&
        !rowWithoutYearValueAndSubRows
      ) {
        totalRows.push(row);
      }

      if (row.rows && Array.isArray(row.rows)) {
        getTotalRows(row.rows);
      }
    });
  };

  getTotalRows(tableRows);

  return totalRows.length;
};

export const numberOfTableRows = (
  table: AgoyTable,
  shouldShowZeros: boolean
) => {
  const tableRows = filterRows(table, ['account'], false, shouldShowZeros);

  const totalRowsNumber = getTotalRowsNumber(tableRows.rows);

  return totalRowsNumber - MAX_ROWS_PER_PAGE;
};

const splitTableInTwoParts = (
  table: AgoyTable<Cell>,
  shouldShowZeros: boolean,
  part?: string
) => {
  const partTwoRowsNumber = numberOfTableRows(table, shouldShowZeros);
  const partOneRowsNumber = getTotalRowsNumber(table.rows) - partTwoRowsNumber;

  const splittedTables = splittingTableByRowsNumber(
    [...table.rows],
    partOneRowsNumber
  );

  if (part === 'part1') {
    return {
      ...table,
      rows: splittedTables.firstTable,
    };
  }

  if (part === 'part2') {
    return {
      ...table,
      rows: splittedTables.secondTable,
    };
  }

  return table;
};

export const mapPartToModel = (part: string): string => {
  return partsMapping[part];
};

type TypeList = 'assets' | 'totalEquity' | 'totalRevenues';

/**
 * @param diagramRRBR - data on diagram page.
 * @param type Type of amount
 * @returns returned amount
 */

export const getKeyValueAmount = (
  diagramRRBR: FinancialReport['diagramRRBR'],
  type: number
): number => {
  let typeName: TypeList;
  let result = 0;
  switch (type) {
    case 1: {
      typeName = 'assets';
      break;
    }
    case 2: {
      typeName = 'totalEquity';
      break;
    }
    case 3: {
      typeName = 'totalRevenues';
      break;
    }
    default: {
      console.error('Incorrect key value type');
      return result;
    }
  }

  if (typeName)
    diagramRRBR.section.compareMonth.rows?.find((row) => {
      if (
        row.id === typeName &&
        row.cells &&
        row.cells.currentPeriod.type === 'ref' &&
        typeof row.cells.currentPeriod.value === 'number'
      ) {
        result = Math.abs(row.cells.currentPeriod.value);
      }
    });
  return result;
};

export const getReportColors = (
  settings: FinancialReport['settings'] | undefined
): ReportColors => ({
  textMain: stringValue(settings?.section.colorTextMain),
  textSecondary: stringValue(settings?.section.colorTextSecondary),
  background: stringValue(settings?.section.colorBackground),
});

export const formatTextInThousands = (note: string): string => {
  const result = note.replace(/SEK/g, 'TSEK');
  return result;
};

export const formatDataInThousands = (data: number[]): number[] => {
  const result = data.map((item) => item / 1000);
  return result;
};

export const formatChartTitleInThousands = (title: string): string => {
  const result = `${title} (belopp i TSEK)`;
  return result;
};

export const getPeriodStart = (
  periodStart: string,
  financialYear: string,
  comparePeriod: number
): Date => {
  const [financialYearStart] = financialYear.split('-');
  const financialYearStartDate = parse(financialYearStart, 'yyyyMMdd');
  const periodStartDate = sub(parse(periodStart, 'yyyyMMdd'), {
    months: comparePeriod - 1,
  });

  if (isBefore(periodStartDate, financialYearStartDate)) {
    return financialYearStartDate;
  }

  return periodStartDate;
};

/**
 * Split annual report IncomeStatement
 * @param table - annualReport incomeStatement table
 * @param part - parts of report inComeStatement
 * @returns table
 */

export const prepareIncomeStatementPartData = (
  table: AgoyTable,
  part: string,
  isPeriod: boolean,
  comparePeriod: number,
  financialYear: string,
  tableShouldBeSplit: boolean,
  shouldShowZeros: boolean,
  previousFinancialYear?: string
) => {
  const { active, columns } = table;
  let updatedColumns = [...columns];

  if (isPeriod && comparePeriod > 1) {
    updatedColumns = columns.map((column) => {
      const [from, to] = (column.label || '').split(' ');

      if (column.id === 'year') {
        return {
          ...column,
          label: `${format(
            getPeriodStart(from, financialYear, comparePeriod),
            'yyyy-MM-dd'
          )} ${to}`,
        };
      }

      if (column.id === 'previousYear' && previousFinancialYear) {
        return {
          ...column,
          label: `${format(
            getPeriodStart(from, previousFinancialYear, comparePeriod),
            'yyyy-MM-dd'
          )} ${to}`,
        };
      }

      return { ...column };
    });
  }

  const filteredRows = filterRows(
    table,
    ['account'],
    false,
    shouldShowZeros
  ).rows;

  if (part == null || !tableShouldBeSplit) {
    // part is not specified or income has rows only for one page so we should return all rows
    return { active, columns: updatedColumns, rows: filteredRows };
  }
  const updatedTable = splitTableInTwoParts(
    { active, columns: updatedColumns, rows: filteredRows },
    shouldShowZeros,
    part
  );

  return updatedTable;
};

export const prepareBalanceSheet = (
  table: AgoyTable,
  tableShouldBeSplit: boolean,
  shouldShowZeros: boolean,
  part?: string
) => {
  const filteredRows = filterRows(
    table,
    ['account'],
    false,
    shouldShowZeros
  ).rows;

  if (part == null || !tableShouldBeSplit) {
    return { ...table, rows: filteredRows };
  }

  const updatedTable = splitTableInTwoParts(
    { ...table, rows: filteredRows },
    shouldShowZeros,
    part
  );

  return updatedTable;
};

export const annualReportIncomeChanged = (
  annualReportChanges: AnnualReportChanges
): boolean => {
  if (
    annualReportChanges &&
    annualReportChanges.incomeStatement &&
    annualReportChanges.incomeStatement.section &&
    annualReportChanges.incomeStatement.section.table
  ) {
    return true;
  }
  return false;
};

/**
 * create add accounts to expenses rows
 * @param data from annual report
 * @param addAccounts object with added account to rows
 */
const addExpensesRowsAccounts = (
  expensesRows: AgoyTableRow<Cell>,
  addAccounts: Record<string, number[]>
) => {
  expensesRows.rows?.forEach((row) => {
    let changesAccounts;
    switch (row.id) {
      case '1': {
        changesAccounts = row.rows?.filter(
          (item) => parseInt(item.id, 10) < 4000 || parseInt(item.id, 10) > 4999
        );
        addAccounts.brutto =
          changesAccounts?.map((item: { id: string }) =>
            parseInt(item.id, 10)
          ) || [];
        break;
      }
      case '3': {
        changesAccounts = row.rows?.filter(
          (item) => parseInt(item.id, 10) < 5000 || parseInt(item.id, 10) > 6999
        );
        addAccounts.ovrigaKostnader =
          changesAccounts?.map((item: { id: string }) =>
            parseInt(item.id, 10)
          ) || [];
        break;
      }
      case '4': {
        changesAccounts = row.rows?.filter(
          (item) => parseInt(item.id, 10) < 7000 || parseInt(item.id, 10) > 7699
        );
        addAccounts.personalkostnader =
          changesAccounts?.map((item: { id: string }) =>
            parseInt(item.id, 10)
          ) || [];
        break;
      }
      case '5':
      case '6': {
        changesAccounts = row.rows?.filter(
          (item) => parseInt(item.id, 10) < 7800 || parseInt(item.id, 10) > 7899
        );
        addAccounts.avskrivningar =
          changesAccounts?.map((item: { id: string }) =>
            parseInt(item.id, 10)
          ) || [];
        break;
      }
      case '9': {
        changesAccounts = row.rows?.filter(
          (item) => parseInt(item.id, 10) < 7900 || parseInt(item.id, 10) > 7999
        );
        addAccounts.reaForlust =
          changesAccounts?.map((item: { id: string }) =>
            parseInt(item.id, 10)
          ) || [];
        break;
      }
      default:
        break;
    }
  });
};
/**
 * create add accounts to income rows
 * @param data from annual report
 * @param addAccounts object with added account to rows
 */
const addIncomeRowsIncomeAccounts = (
  incomeRows: AgoyTableRow<Cell>,
  addAccounts: Record<string, number[]>
) => {
  incomeRows.rows?.forEach((row) => {
    let changesAccounts;
    switch (row.id) {
      case '1': {
        changesAccounts = row.rows?.filter(
          (item) => parseInt(item.id, 10) < 3000 || parseInt(item.id, 10) > 3779
        );
        addAccounts.nettos =
          changesAccounts?.map((item) => parseInt(item.id)) || [];
        break;
      }
      case '2': {
        changesAccounts = row.rows?.filter(
          (item) => parseInt(item.id, 10) < 4900 || parseInt(item.id, 10) > 4999
        );
        addAccounts.bruttokostnader =
          changesAccounts?.map((item) => parseInt(item.id)) || [];
        break;
      }
      case '3':
      case '4': {
        changesAccounts = row.rows?.filter(
          (item) => parseInt(item.id, 10) < 3800 || parseInt(item.id, 10) > 3999
        );
        addAccounts.nettoaccounts =
          changesAccounts?.map((item) => parseInt(item.id)) || [];
        break;
      }
      default:
        break;
    }
  });
};

const addRemovedAccount = (
  account: number,
  accounts: number[] | undefined
): number[] => {
  if (!accounts) {
    accounts = [];
  }
  accounts.push(account);
  return accounts;
};
/**
 * Craete object rows account remove
 */
const removeAccountFromRow = (
  account: number,
  removeAccounts: Record<string, number[]>
) => {
  if (account >= 3000 && account <= 3779) {
    removeAccounts.nettos = addRemovedAccount(account, removeAccounts.nettos);
  }
  if (account >= 3800 && account <= 3999) {
    removeAccounts.nettoaccounts = addRemovedAccount(
      account,
      removeAccounts.bruttokostnader
    );
  }

  if (account >= 4000 && account <= 4999) {
    removeAccounts.bruttokostnader = addRemovedAccount(
      account,
      removeAccounts.bruttokostnader
    );
  }

  if (account >= 5000 && account <= 5999) {
    removeAccounts.ovrigaKostnader = addRemovedAccount(
      account,
      removeAccounts.ovrigaKostnader
    );
  }

  if (account >= 7000 && account <= 7699) {
    removeAccounts.personalkostnader = addRemovedAccount(
      account,
      removeAccounts.personalkostnader
    );
  }

  if (account >= 7700 && account <= 7899) {
    removeAccounts.avskrivningar = addRemovedAccount(
      account,
      removeAccounts.avskrivningar
    );
  }

  if (account >= 7900 && account <= 7999) {
    removeAccounts.reaForlust = addRemovedAccount(
      account,
      removeAccounts.reaForlust
    );
  }
};

const getAccountBalance = (
  accountNumber: number,
  accountingBalances: AccountingBalances,
  period: Date
): AccountingPeriodBalance | undefined => {
  const start = format(startOfMonth(period), 'yyyy-MM-dd');
  const end = format(endOfMonth(period), 'yyyy-MM-dd');
  const periodId = accountingBalances.periods.find(
    (period) => period.start === start && period.end === end
  )?.id;
  if (periodId) {
    const balance = accountingBalances.accounts.find((account) => {
      if (
        account.number === accountNumber.toString() &&
        account.periods[periodId]
      ) {
        return true;
      }
      return false;
    });
    if (balance) {
      return balance.periods[periodId];
    }
  }
  return undefined;
};

export const calcMoveAccounts = (
  incomeStatement: AgoyTable
): {
  addAccounts: Record<string, number[]> | undefined;
  removeAccounts: Record<string, number[]> | undefined;
} => {
  if (incomeStatement) {
    const firstLevelRow = incomeStatement.rows[0];
    const secondLevelRow =
      firstLevelRow && firstLevelRow.rows ? firstLevelRow.rows[0] : undefined;
    const selectedRows = secondLevelRow?.rows?.filter((row) => {
      if (row.id === 'incomes' || row.id === 'expenses') {
        return true;
      }
      return false;
    });
    if (!selectedRows || selectedRows.length === 0) {
      return {
        addAccounts: undefined,
        removeAccounts: undefined,
      };
    }
    const incomeRows = selectedRows.find((row) => row.id === 'incomes');
    const addAccounts: Record<string, number[]> = {};
    const removeAccounts: Record<string, number[]> = {};
    const expensesRows = selectedRows.find((row) => row.id === 'expenses');
    if (incomeRows) {
      addIncomeRowsIncomeAccounts(incomeRows, addAccounts);
    }
    if (expensesRows) {
      addExpensesRowsAccounts(expensesRows, addAccounts);
    }
    Object.keys(addAccounts).forEach((key) => {
      const accounts = addAccounts[key];
      accounts.forEach((account) => {
        removeAccountFromRow(account, removeAccounts);
      });
    });
    return {
      addAccounts,
      removeAccounts,
    };
  }
  return {
    addAccounts: undefined,
    removeAccounts: undefined,
  };
};
/**
 *  Apply rows accounts changes, Add or remove account's amount to row
 */
export const applyRowsChanges = (
  columns: AgoyTableColumn[],
  rows: AgoyTableRow<Cell>[],
  accountingBalances: AccountingBalances,
  rowChanges: {
    addAccounts: Record<string, number[]> | undefined;
    removeAccounts: Record<string, number[]> | undefined;
  }
): {
  columns: AgoyTableColumn[];
  rows: AgoyTableRow<Cell>[];
} => {
  const newRows = cloneDeep(rows);
  columns.forEach((column) => {
    if (column.id === 'nameLabel') {
      return;
    }

    const period = parse(column.id, 'MMMyy');
    newRows.forEach((row) => {
      let changeAmmount = 0;
      if (rowChanges.addAccounts) {
        const addAccounts = rowChanges.addAccounts[row.id];
        if (addAccounts && addAccounts.length > 0) {
          addAccounts.forEach((account) => {
            const accountBalance = getAccountBalance(
              account,
              accountingBalances,
              period
            );
            if (accountBalance) {
              const temp = Math.round(accountBalance.in - accountBalance.out);
              changeAmmount += temp;
            }
          });
        }
      }
      if (rowChanges.removeAccounts) {
        const removeAccounts = rowChanges.removeAccounts[row.id];
        if (removeAccounts && removeAccounts.length > 0) {
          removeAccounts.forEach((account) => {
            const accountBalance = getAccountBalance(
              account,
              accountingBalances,
              period
            );
            if (accountBalance) {
              const temp = Math.round(accountBalance.in - accountBalance.out);
              changeAmmount -= temp;
            }
          });
        }
      }
      if (row.cells && changeAmmount !== 0) {
        const cell = row.cells[column.id];
        if (cell.type === 'ref' && typeof cell.value === 'number') {
          cell.value += changeAmmount;
        }
      }
    });
  });
  return { columns, rows: newRows };
};

import React, { useCallback, useContext, useMemo } from 'react';
import { get } from 'lodash';
import styled from '@emotion/styled';

import { isDefined } from '@agoy/common';
import { AgoyDocument, Cell, Field, stringValue } from '@agoy/document';
import {
  annualGeneralMeetingContentDefinition,
  AnnualReport,
  annualReportSignaturesContentDefinition,
  confirmationCertificateContentDefinition,
} from '@agoy/annual-report-document';
import { ClientInfoContext } from '_clients/context/ClientInformationContext';
import AnnualReportDataServiceContext from '_annual-report/service/AnnualReportDataServiceContext';
import DocumentViewServiceContext from '_shared/services/document/DocumentViewServiceContext';
import { ClientInformation } from '@agoy/api-sdk-core';
import useObservable from 'utils/useObservable';
import { ClientInformationSource, SourceInfo } from './types';
import Source from './Source';
import { referenceToIds } from './utils';

type DataServiceDocumentReportsType =
  | AnnualReport
  | AgoyDocument<typeof annualGeneralMeetingContentDefinition>
  | AgoyDocument<typeof annualReportSignaturesContentDefinition>
  | AgoyDocument<typeof confirmationCertificateContentDefinition>;

type DataServiceDocumentReports = {
  annualReport?: AnnualReport;
  annualGeneralMeeting?: AgoyDocument<
    typeof annualGeneralMeetingContentDefinition
  >;
  annualReportSignatures?: AgoyDocument<
    typeof annualReportSignaturesContentDefinition
  >;
  confirmationCertificate?: AgoyDocument<
    typeof confirmationCertificateContentDefinition
  >;
};

export const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

export const SourceContainer = styled.div`
  margin-left: ${(props) => props.theme.spacing(2)}px;
  max-width: 25%;
  & > div {
    margin-bottom: ${(props) => props.theme.spacing(3)}px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const getReferences = (field: Cell): string[] => {
  switch (field.type) {
    case 'msg':
      return Object.values(field.parameterReferences || {}).flatMap((ref) =>
        referenceToIds(ref)
      );
    case 'ref':
      return referenceToIds(field.reference);
    default:
      if (field.original) {
        return getReferences(field.original);
      }
      return [];
  }
};

const getDocumentReportById = (
  id: string,
  service: DataServiceDocumentReports
): DataServiceDocumentReportsType | undefined => {
  const firstKey = id.split('.')[0];

  // Annual report is default
  return service[firstKey] || service.annualReport;
};

const getSourceField = (
  id: string,
  dataServiceDocumentReports: DataServiceDocumentReports
): { source: Cell['source']; id: string } | undefined => {
  const documentReport = getDocumentReportById(id, dataServiceDocumentReports);

  if (!documentReport) return undefined;

  let field = get(documentReport, id);

  // if field is not found, probably this is ref to external document,
  // try to get field without prefix id
  if (!field) {
    const idWithoutPrefix = id.split('.').slice(1).join('.');
    field = get(documentReport, idWithoutPrefix);
  }

  if (field?.source) {
    return { source: field.source, id };
  }

  if (field?.type === 'ref') {
    const referencedId = referenceToIds(field.reference)[0];

    if (referencedId) {
      return getSourceField(referencedId, dataServiceDocumentReports);
    }
  }

  return undefined;
};

const mapToSourceInfo = (
  id: string,
  field: Field,
  clientInformation: ClientInformation,
  dataServiceDocumentReports: DataServiceDocumentReports
): SourceInfo<string>[] => {
  return getReferences(field)
    .map((refId): SourceInfo<string> | undefined => {
      let referencedField;
      let referencedId;

      if (field.type === 'msg') {
        referencedField = get(dataServiceDocumentReports.annualReport, refId);
        referencedId = refId;
      } else {
        referencedField = field;
        referencedId = id;
      }

      const sourceField = getSourceField(
        referencedId,
        dataServiceDocumentReports
      );

      if (!sourceField || !referencedField) {
        return undefined;
      }

      const { source, id: sourceId } = sourceField;
      if (!source) {
        return undefined;
      }

      const clientInformationField = clientInformation?.[source.id];

      const value = stringValue(referencedField);

      if (value === undefined || clientInformationField === undefined) {
        return undefined;
      }

      return {
        id: refId,
        source,
        clientInformationFieldId: sourceId,
        clientInformationSource: clientInformationField,
        clientInformationValue: clientInformationField.value,
        value,
      };
    })
    .filter(isDefined);
};

type RegistrySourceInformationProps = React.PropsWithChildren<{
  className?: string;
  id: string;
  field: Field;
  editing: boolean;
}>;

const RegistrySourceInformation = ({
  className,
  id,
  children,
  field,
  editing,
}: RegistrySourceInformationProps): React.ReactElement => {
  const clientInformation = useContext(ClientInfoContext);
  const dataService = useContext(AnnualReportDataServiceContext);
  const viewService = useContext(DocumentViewServiceContext);

  const { clientId } = dataService.annualReport;
  const annualReport = useObservable(dataService.annualReport?.report);
  const annualGeneralMeeting = useObservable(
    dataService.annualGeneralMeeting?.report
  );
  const annualReportSignatures = useObservable(
    dataService.annualReportSignatures?.report
  );
  const confirmationCertificate = useObservable(
    dataService.confirmationCertificate?.report
  );

  const sources = useMemo((): SourceInfo<string>[] => {
    if (!clientInformation || !annualReport) {
      return [];
    }

    const dataServiceDocumentReports: DataServiceDocumentReports = {
      annualReport,
      annualGeneralMeeting,
      annualReportSignatures,
      confirmationCertificate,
    };

    return mapToSourceInfo(
      id,
      field,
      clientInformation[clientId],
      dataServiceDocumentReports
    );
  }, [
    clientInformation,
    annualReport,
    annualGeneralMeeting,
    annualReportSignatures,
    confirmationCertificate,
    id,
    field,
    clientId,
  ]);

  const onReset = useCallback(
    (
      referencedId: string,
      clientInformationFieldId: string | undefined,
      clientInformationSource: ClientInformationSource,
      clientInformationValue: string
    ) => {
      if (clientInformationValue !== null) {
        switch (field.type) {
          case 'msg':
            if (clientInformationFieldId) {
              viewService.updateField(
                clientInformationFieldId,
                clientInformationValue,
                {
                  type: clientInformationSource.source,
                  updatedAt: new Date(
                    clientInformationSource.timestamp
                  ).getTime(),
                  userId: clientInformationSource.userId,
                }
              );
            }
            viewService.resetField(referencedId);
            break;
          default:
            viewService.updateField(referencedId, clientInformationValue, {
              type: clientInformationSource.source,
              updatedAt: new Date(clientInformationSource.timestamp).getTime(),
              userId: clientInformationSource.userId,
            });
            viewService.resetField(id);
        }
      }
    },
    [viewService, field.type, id]
  );

  return (
    <Container className={className}>
      {children}
      <SourceContainer>
        {sources.map((source) => (
          <Source<string>
            sourceInfo={source}
            key={source.id}
            onReset={onReset}
            editing={editing}
            isChanged={(sourceInfo) =>
              sourceInfo.value !== sourceInfo.clientInformationValue
            }
            renderDifference={() => <>{source.clientInformationValue}</>}
          />
        ))}
      </SourceContainer>
    </Container>
  );
};

export default RegistrySourceInformation;

import { value } from '@agoy/document';
import { CustomNotes } from '../../../../common';
import { previousYear, year } from '../../../../common/utils/date-util';
import { field, table } from '../../../../common/utils/util';

export const getSharesCompanyCustomNote = (
  financialYear: string,
  financialYears: string[],
  period: string | null
): CustomNotes => {
  return {
    type: 'sections',
    sections: [],
    newSectionTemplate: {
      active: false,
      number: { type: 'number', value: 0 },
      name: { type: 'string', value: 'Ny not' },
      group: { type: 'string', value: 'other' },
      section: { type: 'string', value: '' },
      validForReportType: {
        k2: true,
        k3: true,
      },
      commentTitle: { type: 'string', value: 'Kommentar' },
      typeOne: field('Egen fritext'),
      typeTwo: table(
        'notes.custom.typeTwoTable',
        { id: 'label', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        .newColumnTemplate((column) => column.setType('string').build())
        .newRowTemplate(value(''), value(undefined), value(undefined))
        .build(),
      typeThree: table(
        'notes.custom.typeThreeTable',
        { id: 'label', label: '' },
        { id: 'val1', label: '' },
        { id: 'val2', label: '' },
        { id: 'val3', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        .newColumnTemplate((column) => column.setType('string').build())
        .newRowTemplate(
          value(''),
          value(''),
          value(''),
          value(''),
          value(undefined),
          value(undefined)
        )
        .build(),
    },
  };
};

import { id, or, ref, sum, table, value } from '@agoy/document';
import { IxbrlCell, NoteOneTableWithSum } from '../../../../common';
import {
  active,
  field,
  ixbrlCell,
  ixbrlField,
  tupleRef,
} from '../../../../common/utils/util';
import { year } from '../../../../common/utils/date-util';
import { sumAccountsUBs } from '../../../../common/utils/accountSumsUtil';
import { NoteAccountRanges } from '../types';

const MONETARY_ITEM_TYPE = 'monetaryItemType';

export const note62Accounts: NoteAccountRanges[] = [
  {
    rowId: '1',
    ranges: [
      [7900, 7959],
      [7980, 7989],
      [7990, 7999],
    ],
    cellLabel: 'Övriga rörelsekostnader',
    action: 'sumAccountsUBs',
  },
  {
    rowId: '2',
    ranges: [[7960, 7969]],
    cellLabel:
      'Valutakursförluster på fordringar och skulder av rörelsekaraktär',
    action: 'sumAccountsUBs',
  },
  {
    rowId: '3',
    ranges: [[7970, 7979]],
    cellLabel:
      'Förlust vid avyttring av immateriella och materiella anläggningstillgångar',
    action: 'sumAccountsUBs',
  },
];

const getNote62 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null
): NoteOneTableWithSum => {
  return {
    active: false,
    number: value(undefined),
    name: value('Övriga rörelsekostnader'),
    table: table<IxbrlCell>(
      `notes.note62.table`,
      {
        id: 'costType',
        label: 'Kostnadsslag',
      },
      {
        id: 'amount',
        label: 'Belopp',
      }
    )
      .addRows((rows) =>
        rows
          .addRow(
            '1',
            ixbrlCell(value('Övriga rörelsekostnader'), {
              type: 'stringItemType',
              name: 'se-gen-base:OvrigaRorelsekostnaderKostnadsslagBenamning',
              contextRef: 'period0',
              tuple: {
                name: 'se-gaap-ext:OvrigaRorelsekostnaderTuple',
                id: `${rows.getBaseId()}.1.tuple`,
                tupleID: `${rows.getBaseId()}.1.tuple`,
              },
              tupleRef: tupleRef(1, `${rows.getBaseId()}.1.tuple`),
            }),
            ixbrlCell(
              ref(sumAccountsUBs('7900:7959+7980:7989+7990:7999', true)),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:OvrigaRorelsekostnaderKostnadsslag',
                contextRef: 'period0',
                saldo: 'debit',
                negateValue: true,
                tupleRef: tupleRef(2, `${rows.getBaseId()}.1.tuple`),
              }
            )
          )
          .addRow(
            '2',
            ixbrlCell(
              value(
                'Valutakursförluster på fordringar och skulder av rörelsekaraktär'
              ),
              {
                type: 'stringItemType',
                name: 'se-gen-base:OvrigaRorelsekostnaderKostnadsslagBenamning',
                contextRef: 'period0',
                tuple: {
                  name: 'se-gaap-ext:OvrigaRorelsekostnaderTuple',
                  id: `${rows.getBaseId()}.2.tuple`,
                  tupleID: `${rows.getBaseId()}.2.tuple`,
                },
                tupleRef: tupleRef(1, `${rows.getBaseId()}.2.tuple`),
              }
            ),
            ixbrlCell(ref(sumAccountsUBs('7960:7969', true)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OvrigaRorelsekostnaderKostnadsslag',
              contextRef: 'period0',
              saldo: 'debit',
              negateValue: true,
              tupleRef: tupleRef(2, `${rows.getBaseId()}.2.tuple`),
            })
          )
          .addRow(
            '3',
            ixbrlCell(
              value(
                'Förlust vid avyttring av immateriella och materiella anläggningstillgångar'
              ),
              {
                type: 'stringItemType',
                name: 'se-gen-base:OvrigaRorelsekostnaderKostnadsslagBenamning',
                contextRef: 'period0',
                tuple: {
                  name: 'se-gaap-ext:OvrigaRorelsekostnaderTuple',
                  id: `${rows.getBaseId()}.3.tuple`,
                  tupleID: `${rows.getBaseId()}.3.tuple`,
                },
                tupleRef: tupleRef(1, `${rows.getBaseId()}.3.tuple`),
              }
            ),
            ixbrlCell(ref(sumAccountsUBs('7970:7979', true)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OvrigaRorelsekostnaderKostnadsslag',
              contextRef: 'period0',
              saldo: 'debit',
              negateValue: true,
              tupleRef: tupleRef(2, `${rows.getBaseId()}.3.tuple`),
            })
          )
          .build()
      )
      .newRowTemplateGenerator((id, baseId) => ({
        id,
        active: true,
        cells: {
          costType: ixbrlCell(value(''), {
            type: 'stringItemType',
            name: 'se-gen-base:OvrigaRorelsekostnaderKostnadsslagBenamning',
            contextRef: 'period0',
            tuple: {
              name: 'se-gaap-ext:OvrigaRorelsekostnaderTuple',
              id: `${baseId}.${id}.tuple`,
              tupleID: `${baseId}.${id}.tuple`,
            },
            tupleRef: tupleRef(1, `${baseId}.${id}.tuple`),
          }),
          amount: ixbrlCell(value(undefined), {
            type: MONETARY_ITEM_TYPE,
            name: 'se-gen-base:OvrigaRorelsekostnaderKostnadsslag',
            contextRef: 'period0',
            saldo: 'debit',
            negateValue: true,
            tupleRef: tupleRef(2, `${baseId}.${id}.tuple`),
          }),
        },
      }))
      .build(),
    sumTable: table(
      'notes.note62.sumTable',
      { id: 'label', label: '' },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      }
    )
      .addRows((rows) =>
        rows
          .addRow(
            'sum',
            noteLabel('Summa'),
            ref(or(sum(id('notes.note62.table.*.amount')), 0))
          )
          .build()
      )
      .build(),
    commentLabel: field('Kommentar'),
    comment: ixbrlField(active(field('')), {
      type: 'stringItemType',
      name: 'se-gen-base:NotOvrigaRorelsekostnaderKommentar',
      contextRef: 'period0',
    }),
    group: value(undefined),
    section: value(undefined),
    validForReportType: {
      k2: true,
      k3: true,
    },
  };
};

export default getNote62;

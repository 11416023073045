import ProgramStatus from './ProgramStatus';
import { programStatusMapSchema } from './status-map-schema';
import {
  StatusGenerator,
  Errors,
  generateRequiredStatuses,
} from './StatusGenerator';
import {
  ProgramKey as ExportedProgramKey,
  ProgramStatusName as ExportedProgramStatusName,
  IntervalTypeKey as ExportedIntervalTypeKey,
} from './transitionRules';

export default new ProgramStatus(programStatusMapSchema);

export * from './status-map-schema';
export { StatusGenerator, generateRequiredStatuses };

export type ProgramKey = ExportedProgramKey;
export type ProgramStatusName = ExportedProgramStatusName;
export type IntervalTypeKey = ExportedIntervalTypeKey;
export type StatusGeneratorErrors = Errors;

/**
 * When we need to pass feature-flags to program-status, we add the feature name as a string in this type.
 */
export type ProgramStatusFeature = null;

import { AgoyDocument } from '@agoy/document';
import { annualGeneralMeetingSignaturesContentDefinition } from './document';
import { annualGeneralMeetingSignaturesConfig } from './types/signatures';

export * from './types/signatures';

export const annualGeneralMeetingSignaturesDocumentConfig = (): AgoyDocument<
  typeof annualGeneralMeetingSignaturesContentDefinition
> => {
  return {
    documentType: 'annualGeneralMeetingSignatures',
    annualGeneralMeetingSignatures: annualGeneralMeetingSignaturesConfig(),
  };
};

export { annualGeneralMeetingSignaturesContentDefinition };

import { table, value } from '@agoy/document';
import { NoteOneTableSection } from '../../../../common';
import { field } from '../../../../common/utils/util';

export const getNote38 = (
  noteLabel: any,
  documentTypeVersion = '1'
): NoteOneTableSection => {
  return {
    active: false,
    number: value(undefined),
    name: value('Nyckeltalsdefinitioner'),
    table: table('notes.note38.table', { id: 'label', label: '' })
      .addRows((rows) =>
        rows
          .addRowActive(false)
          .addRow(
            '1',
            noteLabel(
              'Soliditet: Justerat eget kapital i procent av balansomslutning'
            )
          )
          .addRowActive(false)
          .addRow(
            '2',
            noteLabel(
              'Avkastning på eget kapital: Resultat efter finansiella poster i procent av genomsnittligt justerat eget kapital'
            )
          )
          .addRowActive(false)
          .addRow(
            '3',
            noteLabel(
              'Avkastning på totalt kapital: Rörelseresultat med tillägg för finansiella intäkter, i procent av genomsnittlig balansomslutning'
            )
          )
          .addRowActive(false)
          .addRow(
            '4',
            noteLabel(
              'Kassalikviditet: Omsättningstillgångar exklusive varulager i procent av kortfristiga fordringar'
            )
          )
          .build()
      )
      .build(),
    commentLabel: field('Kommentar'),
    comment: field(''),
    group: value(undefined),
    section: value(undefined),
    validForReportType: {
      k2: true,
      k3: documentTypeVersion === '1',
    },
  };
};

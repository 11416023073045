import { AnnualReportType } from '../../../document';
import { Notes } from './types';
import { getSharesCompanyIncomeStatementNotes } from './income-sheet-notes/incomeStatementNotes';
import { ReferenceAccountInformation, value } from '@agoy/document';
import { active, field } from '../../../common/utils/util';
import getSharesCompanyNote1 from '../shared/note1';
import { getSharesCompanyBalanceSheetNotes } from './balance-sheet-notes/balanceSheetNotes';
import { getSharesCompanyOtherNotes } from './other-notes/otherNotes';
// TODO: put them where they belong in balanceSheetNotes, incomeStatementNotes, otherNotes
import { note61Accounts } from './Notes/note61';
import { note6Accounts } from './Notes/note6';
import { note7Accounts } from './Notes/note7';
import { note8Accounts } from './Notes/note8';
import { note9Accounts } from './Notes/note9';
import { note10Accounts } from './Notes/note10';
import { note11Accounts } from './Notes/note11';
import { note12Accounts } from './Notes/note12';
import { note13Accounts } from './Notes/note13';
import { note15Accounts } from './Notes/note15';
import { note17Accounts } from './Notes/note17';
import { note19Accounts } from './Notes/note19';
import { note21Accounts } from './Notes/note21';
import { note22Accounts } from './Notes/note22';
import { note23Accounts } from './Notes/note23';
import { note24Accounts } from './Notes/note24';
import { note25Accounts } from './Notes/note25';
import { note26Accounts } from './Notes/note26';
import { note28Accounts } from './Notes/note28';
import { note29Accounts } from './Notes/note29';
import { note30Accounts } from './Notes/note30';
import { note31Accounts } from './Notes/note31';
import { note40Accounts } from './Notes/note40';
import { note41Accounts } from './Notes/note41';
import { note43Accounts } from './Notes/note43';
import { note62Accounts } from './Notes/note62';
import { note63Accounts } from './Notes/note63';
import { note66Accounts } from './Notes/note66';
import { note70Accounts } from './k3-notes/note70';
import { note72Accounts } from './k3-notes/note72';
import { note73Accounts } from './k3-notes/note73';
import { note77Accounts } from './k3-notes/note77';
import { note82Accounts } from './k3-notes/note82';
import { note86Accounts } from './k3-notes/note86';
import { note87Accounts } from './k3-notes/note87';
import { note90Accounts } from './k3-notes/note90';
import { note92Accounts } from './k3-notes/note92';
import { note93Accounts } from './k3-notes/note93';
import { note94Accounts } from './k3-notes/note94';
import { note101Accounts } from './k3-notes/note101';
import { note401Accounts } from './automated-k3-notes/note401';
import { note402Accounts } from './automated-k3-notes/note402';

export const sharesCompanyNotesAccountStructure = {
  note6: note6Accounts,
  note7: note7Accounts,
  note8: note8Accounts,
  note9: note9Accounts,
  note10: note10Accounts,
  note11: note11Accounts,
  note12: note12Accounts,
  note13: note13Accounts,
  note15: note15Accounts,
  note17: note17Accounts,
  note19: note19Accounts,
  note21: note21Accounts,
  note22: note22Accounts,
  note23: note23Accounts,
  note24: note24Accounts,
  note25: note25Accounts,
  note26: note26Accounts,
  note28: note28Accounts,
  note29: note29Accounts,
  note30: note30Accounts,
  note31: note31Accounts,
  note40: note40Accounts,
  note41: note41Accounts,
  note43: note43Accounts,
  note61: note61Accounts,
  note62: note62Accounts,
  note63: note63Accounts,
  note66: note66Accounts,
  note70: note70Accounts,
  note72: note72Accounts,
  note73: note73Accounts,
  note77: note77Accounts,
  note82: note82Accounts,
  note86: note86Accounts,
  note87: note87Accounts,
  note90: note90Accounts,
  note92: note92Accounts,
  note93: note93Accounts,
  note94: note94Accounts,
  note101: note101Accounts,
  note401: note401Accounts,
  note402: note402Accounts,
};

export const notesConfig = (
  accounts: Record<string, ReferenceAccountInformation>,
  financialYears: string[],
  financialYear: string,
  period: string | null,
  reportType: AnnualReportType,
  isDigitalSubmission: boolean,
  documentTypeVersion = '1'
): Notes => {
  const incomeStatementNotes = getSharesCompanyIncomeStatementNotes(
    reportType,
    financialYears,
    financialYear,
    period,
    isDigitalSubmission,
    documentTypeVersion
  );

  const balanceSheetNotes = getSharesCompanyBalanceSheetNotes(
    accounts,
    reportType,
    financialYears,
    financialYear,
    period,
    isDigitalSubmission,
    documentTypeVersion
  );

  const otherNotes = getSharesCompanyOtherNotes(
    reportType,
    financialYears,
    financialYear,
    period,
    isDigitalSubmission,
    documentTypeVersion
  );

  return {
    active: undefined,
    headings: {
      active: undefined,
      number: value(undefined),
      name: value(undefined),
      notesIncomeStatementTitle: active(field('Noter till resultaträkning')),
      notesBalanceSheetTitle: active(field('Noter till balansräkning')),
      notesOtherTitle: active(field('Övriga noter')),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },

    note1: getSharesCompanyNote1(
      reportType,
      isDigitalSubmission,
      documentTypeVersion
    ),
    ...incomeStatementNotes,
    ...balanceSheetNotes,
    ...otherNotes,
  };
};

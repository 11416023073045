import React from 'react';
import { format, addDays, parseISO } from 'date-fns';
import { stringValue } from '@agoy/document';
import { DocumentConfiguration, Settings } from '@agoy/annual-report-document';
import * as IX from '../../xml/IX';
import * as XBRLI from '../../xml/XBRLI';

type ResourcesProps = {
  settings: Settings;
  documentConfig: DocumentConfiguration;
};

const Resources = ({
  settings,
  documentConfig,
}: ResourcesProps): React.ReactElement | null => {
  const organisationNumber =
    stringValue(settings.section.organisationNumber) || '';

  // The multi year overview contains references to previous years so we need to
  // include them here (both for period and balans)
  const periods: { yearRef: number; start: string; end: string }[] =
    documentConfig.financialYears.map((year, index) => ({
      yearRef: index,
      start: year.start,
      end: year.end,
    }));
  const lastPeriod = periods[periods.length - 1];

  const antalAnstallda =
    documentConfig.reportType === 'k3'
      ? 'se-k3-type:AntalAnstallda'
      : 'se-k2-type:AntalAnstallda';

  return (
    <IX.Resources>
      {periods.map(({ yearRef, start, end }) => (
        <XBRLI.Context
          id={`period${yearRef}`}
          key={`periods-yearRef${yearRef}`}
        >
          <XBRLI.Entity>
            <XBRLI.Identifier>{organisationNumber}</XBRLI.Identifier>
          </XBRLI.Entity>
          <XBRLI.Period>
            <XBRLI.StartDate>{start}</XBRLI.StartDate>
            <XBRLI.EndDate>{end}</XBRLI.EndDate>
          </XBRLI.Period>
        </XBRLI.Context>
      ))}

      {[
        ...periods,
        {
          yearRef: lastPeriod.yearRef + 1,
          end: format(addDays(parseISO(lastPeriod.start), -1), 'yyyy-MM-dd'),
        },
      ].map(({ yearRef, end }) => (
        <XBRLI.Context id={`balans${yearRef}`} key={`balans-yearRef${yearRef}`}>
          <XBRLI.Entity>
            <XBRLI.Identifier>{organisationNumber}</XBRLI.Identifier>
          </XBRLI.Entity>
          <XBRLI.Period>
            <XBRLI.Instant>{end}</XBRLI.Instant>
          </XBRLI.Period>
        </XBRLI.Context>
      ))}

      <XBRLI.Unit id="SEK">
        <XBRLI.Measure>iso4217:SEK</XBRLI.Measure>
      </XBRLI.Unit>
      <XBRLI.Unit id="procent">
        <XBRLI.Measure>xbrli:pure</XBRLI.Measure>
      </XBRLI.Unit>
      <XBRLI.Unit id="antal-anstallda">
        <XBRLI.Measure>{antalAnstallda}</XBRLI.Measure>
      </XBRLI.Unit>
      <XBRLI.Unit id="andelar">
        <XBRLI.Measure>xbrli:shares</XBRLI.Measure>
      </XBRLI.Unit>
    </IX.Resources>
  );
};

export default Resources;

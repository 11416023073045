/**
 * Helper to get the original logo url
 *
 * @param logoUrl Full url of logo
 * @returns The url of the original logo
 */
export const logoOriginalUrl = (logoUrl: string): string => {
  const index = logoUrl.lastIndexOf('.');
  const [imgUrl, imageFormat] = [
    logoUrl.substring(0, index),
    logoUrl.substring(index),
  ];
  return `${imgUrl}-original${imageFormat}`;
};

export default logoOriginalUrl;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { Box } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useIntl } from 'react-intl';
import { differenceInDays } from 'date-fns';

import { useSelector } from 'redux/reducers';
import { asResultClass, isApiErrorType, useApiSdk } from 'api-sdk';
import { ReferencesTypes, Voucher } from 'types/Voucher';
import CreateVoucherView from '_shared/components/VoucherView/CreateVoucherView';
import useVoucherData from '_shared/components/VoucherView/useVoucherData';
import useVoucherForm, {
  DEFAULT_TRANSACTIONS,
} from '_shared/components/VoucherView/useVoucherForm';
import { transformVouchers } from '_shared/components/VoucherView/utils';
import { format, isSameOrAfter, parse, isSameOrBefore } from '@agoy/dates';
import VoucherList from './VoucherList';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  background: ${({ theme }) => theme.palette.common.white};
  min-width: 360px;
  width: 100%;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  padding: ${({ theme }) => theme.spacing(1)}px
    ${({ theme }) => theme.spacing(3)}px;
`;

type VoucherViewProps = {
  clientId: string;
};

const getDefaultDate = (maxVoucherDate: Date) => {
  const nowDate = new Date();

  return isSameOrBefore(nowDate, maxVoucherDate)
    ? format(nowDate, 'yyyy-MM-dd')
    : format(maxVoucherDate, 'yyyy-MM-dd');
};

const VoucherView = ({ clientId }: VoucherViewProps) => {
  const { formatMessage } = useIntl();
  const sdk = useApiSdk();

  const { currentPeriod, currentFinancialYearId } = useSelector(
    (state) => state.customerView
  );
  const { startDate, integrations, rawFinancialYears } = useSelector(
    (state) => state.customers[clientId]
  );
  const periodStatus = useSelector(
    (state) => state.accountingView.clients[clientId].periodStatus
  );

  const integrationActive = !!integrations?.fortnox;

  const financialYear = useMemo(
    () => rawFinancialYears?.find((item) => item.id === currentFinancialYearId),
    [currentFinancialYearId, rawFinancialYears]
  );

  const {
    voucherNumber,
    nextVoucherNumber,
    description,
    selectedSeries,
    transactions,
    documents,
    date,
    onChangeSeries,
    onChangeDescription,
    onCreateVoucher,
    onChangeDate,
    onAddTransaction,
    onRemoveTransaction,
    onUpdateTransaction,
    onChangeDocuments,
    onReset,
    onAddDocument,
    onUpdateVoucher,
  } = useVoucherForm({
    clientId,
    financialYear,
    defaultTransactions: DEFAULT_TRANSACTIONS,
    reference: ReferencesTypes.GLIDER,
    period: currentPeriod || '',
  });

  const { accountsList, seriesList } = useVoucherData({
    clientId,
    period: currentPeriod || '',
  });

  const [voucherList, setVoucherList] = useState<Voucher[]>([]);
  const [voucherSeriesNextNumber, setVoucherSeriesNextNumber] = useState<{
    [series: string]: number;
  }>({});
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [editing, setEditing] = useState(false);

  const periods = financialYear?.periods;

  const firstNotLockedPeriodStart = useMemo(() => {
    if (!periodStatus || !periods) {
      return undefined;
    }
    return periods.find((item) => periodStatus[item.id]?.status !== 'LOCKED')
      ?.start;
  }, [periodStatus, periods]);

  const minVoucherDate = useMemo(() => {
    const parsedStartDate = parse(startDate);
    const parsedFirstNotLockedPeriod = parse(firstNotLockedPeriodStart);

    if (!startDate || !firstNotLockedPeriodStart) {
      return parsedStartDate || parsedFirstNotLockedPeriod || undefined;
    }

    return isSameOrAfter(parsedStartDate, parsedFirstNotLockedPeriod)
      ? parsedStartDate
      : parsedFirstNotLockedPeriod;
  }, [firstNotLockedPeriodStart, startDate]);

  const maxVoucherDate = useMemo(() => {
    return parse(financialYear?.end);
  }, [financialYear]);

  const getVouchers = useCallback(async () => {
    if (!currentPeriod) {
      return;
    }

    const result = await asResultClass(
      sdk.getVoucherList({
        clientid: clientId,
        period: currentPeriod,
      })
    );

    if (result.ok) {
      const vouchers = transformVouchers(result.val);

      setVoucherList(vouchers);
    }
  }, [currentPeriod, clientId, sdk]);

  const getSeriesNextNumbers = useCallback(async () => {
    if (!financialYear) {
      return;
    }

    const result = seriesList.map(async (item) => {
      const response = await asResultClass(
        sdk.getVoucherNextNumber({
          clientid: clientId,
          financialYearId: financialYear.id,
          series: item.series,
        })
      );

      const number = response.ok ? response.val.nextNumber || 1 : 1;

      return { series: item.series, number };
    });

    const seriesNumbers = {};

    (await Promise.all(result)).forEach((item) => {
      seriesNumbers[item.series] = item.number;
    });

    setVoucherSeriesNextNumber(seriesNumbers);
  }, [financialYear, seriesList, sdk, clientId]);

  useEffect(() => {
    getVouchers();
  }, [getVouchers]);

  useEffect(() => {
    onChangeSeries(integrationActive ? 'A' : 'Agoy');
  }, [integrationActive, onChangeSeries]);

  useEffect(() => {
    if (!date) {
      onChangeDate(getDefaultDate(maxVoucherDate));
    }
  }, [date, financialYear, maxVoucherDate, onChangeDate]);

  useEffect(() => {
    getSeriesNextNumbers();
  }, [getSeriesNextNumbers]);

  const onSave = useCallback(
    async (voucher: Voucher): Promise<void> => {
      setError('');
      setLoading(true);

      const result = await onCreateVoucher(voucher);

      if (result.ok) {
        setVoucherSeriesNextNumber((currentValue) => ({
          ...currentValue,
          [voucher.series]: currentValue[voucher.series] + 1,
        }));
        onReset();
        onChangeDate(getDefaultDate(maxVoucherDate));

        await getVouchers();

        setLoading(false);

        return;
      }

      if (isApiErrorType(result.val)) {
        const { message } = result.val.body;

        switch (message) {
          case 'COULD_NOT_STORE_VOUCHER_IN_DB':
          case 'VOUCHER_INCORRECT_ACCOUNT':
          case 'VOUCHER_INCORRECT_FINANCIAL_YEAR':
          case 'VOUCHER_PERIOD_NOT_FOUND':
          case 'INTEGRATION_NOT_FOUND':
          case 'FORTNOX_CREATE_VOUCHER_ERROR':
          case 'VOUCHER_ORGANIZATION_NOT_FOUND':
            setError('error');
            break;
          default:
            setError(message);
        }
      } else {
        setError('error');
      }
      setLoading(false);
    },
    [getVouchers, maxVoucherDate, onChangeDate, onCreateVoucher, onReset]
  );

  const handleChangeEditing = useCallback(() => {
    setEditing((currentValue) => !currentValue);
  }, []);

  const handleDeleteDocument = (id: number) => {
    const updatedDocuments = documents.filter((item) => item.id !== id);
    onChangeDocuments(updatedDocuments);
  };

  const handleDeleteVoucher = useCallback(
    async (voucher: Voucher) => {
      setLoading(true);

      if (!voucher.id) {
        return;
      }

      const result = await asResultClass(
        sdk.deleteVoucher({
          clientid: clientId,
          voucherid: voucher.id,
        })
      );

      if (result.ok) {
        setVoucherSeriesNextNumber((currentValue) => ({
          ...currentValue,
          [voucher.series]: currentValue[voucher.series] - 1,
        }));

        await getVouchers();
      }

      setLoading(false);
    },
    [clientId, getVouchers, sdk]
  );

  const handleUpdateVoucher = useCallback(
    async (voucher) => {
      const result = await onUpdateVoucher(voucher);

      if (result.ok) {
        setVoucherSeriesNextNumber((currentValue) => ({
          ...currentValue,
          [result.val.series]: currentValue[result.val.series] - 1,
        }));
        await getVouchers();
      }
    },
    [getVouchers, onUpdateVoucher]
  );
  if (!financialYear) {
    return null;
  }

  return (
    <Container>
      <CreateVoucherView
        clientId={clientId}
        financialYear={financialYear}
        titleText="Verifikation"
        series={selectedSeries}
        transactions={transactions}
        description={description}
        date={date}
        documents={documents}
        voucherNumber={voucherNumber}
        nextVoucherNumber={nextVoucherNumber}
        seriesList={seriesList}
        accountsList={accountsList}
        source={ReferencesTypes.GLIDER}
        fortnoxActive={integrationActive}
        minDate={minVoucherDate}
        maxDate={maxVoucherDate}
        disabled={loading}
        editing={editing}
        disabledDate={
          differenceInDays(minVoucherDate, maxVoucherDate) === 0 &&
          isSameOrBefore(minVoucherDate, parse(firstNotLockedPeriodStart))
        }
        onChangeEditing={handleChangeEditing}
        onChangeSeries={onChangeSeries}
        onAddTransaction={onAddTransaction}
        onRemoveTransaction={onRemoveTransaction}
        onUpdateTransaction={onUpdateTransaction}
        onChangeDate={onChangeDate}
        onChangeDescription={onChangeDescription}
        onAddDocument={onAddDocument}
        onDeleteDocument={handleDeleteDocument}
        onReset={onReset}
        onSave={onSave}
      />

      {error && (
        <Box marginLeft={4} marginRight={2} marginBottom={4}>
          <Alert severity="error" onClose={() => setError('')}>
            <AlertTitle>
              {error === 'error' ? formatMessage({ id: error }) : error}
            </AlertTitle>
          </Alert>
        </Box>
      )}

      <VoucherList
        clientId={clientId}
        financialYear={financialYear}
        voucherList={voucherList}
        seriesList={seriesList}
        accounts={accountsList}
        voucherSeriesNextNumber={voucherSeriesNextNumber}
        integrationActive={integrationActive}
        onDeleteVoucher={handleDeleteVoucher}
        onUpdateVoucher={handleUpdateVoucher}
      />
    </Container>
  );
};

export default VoucherView;

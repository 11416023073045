import React from 'react';
import Typography from '../../UI/Typography/Typography';
import { Typography as MuiTypography } from '@material-ui/core';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';
import {
  CashFlowStatementGeneral,
  Settings,
} from '@agoy/annual-report-document';
import CashFlowPrintTable from '../../UI/CashFlowPrintTable';
import HierarchicalPrintTable from '../../UI/HierarchicalPrintTable/v2/HierarchicalPrintTable';
import { filterRows } from '@agoy/document';
import { addPrintSpacers } from '../../utils/notes/addSpacers';

const Content = styled.div`
  @media print {
    page-break-before: always;
  }
`;

interface CashFlowStatementPageProps {
  cashFlowStatement: CashFlowStatementGeneral | undefined;
  settings: Settings;
}

const CashFlowStatementPage = ({
  cashFlowStatement,
  settings,
}: CashFlowStatementPageProps): JSX.Element | null => {
  const { formatMessage } = useIntl();
  const title = formatMessage({ id: 'annualReport.menu.cashFlowStatement' });

  if (cashFlowStatement == null) {
    return null;
  }

  return (
    <Content>
      {'main' in cashFlowStatement.section ? (
        <>
          <MuiTypography variant="h1">{title}</MuiTypography>
          <CashFlowPrintTable
            settings={settings}
            table={cashFlowStatement.section.main}
            tableId="cashFlowStatement.section.main"
          />
        </>
      ) : (
        <>
          <Typography variant="h1">{title}</Typography>
          <HierarchicalPrintTable
            tableId="cashFlowStatement.section.table"
            table={addPrintSpacers(
              filterRows(cashFlowStatement.section.table, ['account'], false)
            )}
            compact
          />
        </>
      )}
    </Content>
  );
};

export default CashFlowStatementPage;

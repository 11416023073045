import {
  TaxDeclarationFormDefinition,
  TaxDeclarationFormDataConfig,
  CompanyMetaData,
} from '../../types';
import { ref, value, sum, id, multiply } from '@agoy/document';
import {
  balanceSheetRef as balanceSheetUtilRef,
  incomeStatementRef as incomeStatementUtilRef,
  toInitial,
  mapFormId,
  toStructure,
} from '../forms-util';

const balanceSheetRef = (reference: string) =>
  balanceSheetUtilRef(reference, true);
const incomeStatementRef = (reference: string) =>
  incomeStatementUtilRef(reference, true);

const configForenklatArsbokslut = (
  form: TaxDeclarationFormDefinition,
  customer: CompanyMetaData,
  financialYearStart: string,
  financialYearEnd: string
): TaxDeclarationFormDataConfig => {
  const ForenklatArsbokslut = mapFormId(form, 'ForenklatArsbokslut');
  const initial = {
    ForenklatArsbokslut: {
      partType: 'form' as const,
      id: ForenklatArsbokslut,
      fields: {
        name: value(customer.name),
        persOrOrgNumber: value(customer.orgNumber),
        fromDate: value(financialYearStart),
        toDate: value(financialYearEnd),
        businessNature: value(''),
        B_1: ref(balanceSheetRef('ImmateriellaAnlaggningstillgangar')),
        B_2: ref(balanceSheetRef('ByggnaderMarkanlaggningar')),
        B_3: ref(balanceSheetRef('MarkSomInteSkrivas')),
        B_4: ref(balanceSheetRef('MaskinerInventarier')),
        B_5: ref(balanceSheetRef('OvrigaAnlaggningstillgangar')),
        B_6: ref(balanceSheetRef('Varulager')),
        B_7: ref(balanceSheetRef('Kundfordringar')),
        B_8: ref(balanceSheetRef('OvrigaFordringar')),
        B_9: ref(balanceSheetRef('KassaBank')),
        summa_tillgangar: ref(
          sum(
            id('ForenklatArsbokslut.fields.B_1'),
            id('ForenklatArsbokslut.fields.B_2'),
            id('ForenklatArsbokslut.fields.B_3'),
            id('ForenklatArsbokslut.fields.B_4'),
            id('ForenklatArsbokslut.fields.B_5'),
            id('ForenklatArsbokslut.fields.B_6'),
            id('ForenklatArsbokslut.fields.B_7'),
            id('ForenklatArsbokslut.fields.B_8'),
            id('ForenklatArsbokslut.fields.B_9')
          )
        ),
        B_10: ref(balanceSheetRef('EgetKapital')),
        B_13: ref(balanceSheetRef('Laneskulder')),
        B_14: ref(balanceSheetRef('Skatteskulder')),
        B_15: ref(balanceSheetRef('Leverantorsskulder')),
        B_16: ref(balanceSheetRef('OvrigaSkulder')),
        summa_skulder: ref(
          sum(
            id('ForenklatArsbokslut.fields.B_10'),
            id('ForenklatArsbokslut.fields.B_13'),
            id('ForenklatArsbokslut.fields.B_14'),
            id('ForenklatArsbokslut.fields.B_15'),
            id('ForenklatArsbokslut.fields.B_16')
          )
        ),
        R_1: ref(incomeStatementRef('netSales')),
        R_2: ref(incomeStatementRef('MomsfriaIntakter')),
        R_3: ref(incomeStatementRef('BilBostadsforman')),
        R_4: ref(incomeStatementRef('Ranteintakter')),
        R_5: ref(multiply(-1, incomeStatementRef('VarorMaterialTjanster'))),
        R_6: ref(multiply(-1, incomeStatementRef('OvrigaExternaKostnader'))),
        R_7: ref(multiply(-1, incomeStatementRef('AnstalldPersonal'))),
        R_8: ref(multiply(-1, incomeStatementRef('Rantekostnader'))),
        R_9: ref(multiply(-1, incomeStatementRef('NedskrivningarByggnader'))),
        R_10: ref(multiply(-1, incomeStatementRef('NedskrivningarMaskiner'))),
        R_11: ref(incomeStatementRef('AretsResultat')),
        U_1: value(undefined),
        U_2: value(undefined),
        U_3: value(undefined),
        U_4: value(undefined),
      },
      sru: {},
    },
  };
  return {
    initial: toInitial(initial, form),
    definition: toStructure(initial, form),
  };
};

export default configForenklatArsbokslut;

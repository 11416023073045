import {
  SET_CHECKLIST_DRAWER_EXPANDED,
  SET_CHECKLIST_QUESTION_STATUS,
  SET_CHECKLIST_STATUSES,
  SET_CHECKLIST_PROGRAM,
  SET_PERSON_CHECKLIST_PROGRAM,
  SET_PERSON_CHECKLIST_QUESTION_STATUS,
  SET_PERSON_CHECKLIST_STATUSES,
  TOGGLE_INCLUDE_CHECKLIST_TO_REPORT,
} from './action-types';
import {
  DrawerActions,
  ClientChecklistActions,
  PersonChecklistActions,
} from './actions';
import {
  ChecklistStatuses,
  ChecklistPeriodStatuses,
  ChecklistPrograms,
} from './types';

type ChecklistActions =
  | DrawerActions
  | ClientChecklistActions
  | PersonChecklistActions;

export interface ChecklistYearState {
  programs: ChecklistPrograms;
  statuses: ChecklistStatuses;
}
interface ChecklistClientState {
  years: Record<string, ChecklistYearState>;
}

interface ChecklistState {
  expanded: boolean;
  clients: Record<string, ChecklistClientState>;
  persons: Record<string, ChecklistClientState>;
}

const initialChecklistState: ChecklistState = {
  expanded: false,
  clients: {},
  persons: {},
};

const updateClient = (
  state: ChecklistState,
  clientId: string,
  updater: (clientState: ChecklistClientState) => ChecklistClientState
): ChecklistState => {
  const newClientState = updater(state.clients[clientId] || { years: {} });

  return {
    ...state,
    clients: {
      ...state.clients,
      [clientId]: newClientState,
    },
  };
};

const updateClientYear = (
  state: ChecklistState,
  clientId: string,
  financialYear: string,
  updater: (clientState: ChecklistYearState) => ChecklistYearState
): ChecklistState => {
  return updateClient(state, clientId, (clientState) => {
    const updatedClientYearState = updater(
      clientState.years[financialYear] || { programs: {}, statuses: {} }
    );

    const updatedState: ChecklistClientState = {
      ...clientState,
      years: {
        ...clientState.years,
        [financialYear]: updatedClientYearState,
      },
    };

    return updatedState;
  });
};

const updateClientQuestionStatus = (
  state: ChecklistState,
  clientId: string,
  financialYear: string,
  periodId: number,
  updater: (periodStatuses: ChecklistPeriodStatuses) => ChecklistPeriodStatuses
): ChecklistState => {
  return updateClientYear(state, clientId, financialYear, (clientYearState) => {
    const updatedPeriodStatuses = updater(
      clientYearState.statuses[periodId] || {}
    );

    const updatedClientYearState: ChecklistYearState = {
      ...clientYearState,
      statuses: {
        ...clientYearState.statuses,
        [periodId]: updatedPeriodStatuses,
      },
    };

    return updatedClientYearState;
  });
};

const updatePerson = (
  state: ChecklistState,
  personId: string,
  updater: (clientState: ChecklistClientState) => ChecklistClientState
): ChecklistState => {
  const newPersonState = updater(state.persons[personId] || { years: {} });

  return {
    ...state,
    persons: {
      ...state.clients,
      [personId]: newPersonState,
    },
  };
};

const updatePersonYear = (
  state: ChecklistState,
  personId: string,
  financialYear: number,
  updater: (personState: ChecklistYearState) => ChecklistYearState
): ChecklistState => {
  return updatePerson(state, personId, (personState) => {
    const updatedPersonYearState = updater(
      personState.years[financialYear] || { programs: {}, statuses: {} }
    );

    const updatedState: ChecklistClientState = {
      ...personState,
      years: {
        ...personState.years,
        [financialYear]: updatedPersonYearState,
      },
    };

    return updatedState;
  });
};

const updatePersonQuestionStatus = (
  state: ChecklistState,
  personId: string,
  financialYearId: number,
  periodId: number | string,
  updater: (periodStatuses: ChecklistPeriodStatuses) => ChecklistPeriodStatuses
): ChecklistState => {
  return updatePersonYear(
    state,
    personId,
    financialYearId,
    (personYearState) => {
      const updatedPeriodStatuses = updater(
        personYearState.statuses[periodId] || {}
      );

      const updatedPersonYearState: ChecklistYearState = {
        ...personYearState,
        statuses: {
          ...personYearState.statuses,
          [periodId]: updatedPeriodStatuses,
        },
      };

      return updatedPersonYearState;
    }
  );
};

export default (
  state: ChecklistState = initialChecklistState,
  action: ChecklistActions
): ChecklistState => {
  switch (action.type) {
    case SET_CHECKLIST_DRAWER_EXPANDED:
      return {
        ...state,
        expanded: action.expanded,
      };

    case SET_CHECKLIST_PROGRAM:
      return updateClientYear(
        state,
        action.clientId,
        action.financialYear,
        (clientYearState) => {
          const updatedClientYearState: ChecklistYearState = {
            ...clientYearState,
            programs: {
              ...clientYearState.programs,
              [action.program]: action.checklist,
            },
          };

          return updatedClientYearState;
        }
      );

    case SET_CHECKLIST_STATUSES:
      return updateClientYear(
        state,
        action.clientId,
        action.financialYear,
        (clientYearState) => {
          const updatedClientYearState: ChecklistYearState = {
            ...clientYearState,
            statuses: action.statuses,
          };

          return updatedClientYearState;
        }
      );

    case TOGGLE_INCLUDE_CHECKLIST_TO_REPORT:
      return updateClientYear(
        state,
        action.clientId,
        action.financialYear,
        (clientYearState) => {
          const programState = clientYearState.programs[action.program];

          const updatedClientYearState: ChecklistYearState = {
            ...clientYearState,
            programs: {
              ...clientYearState.programs,
              [action.program]: {
                ...programState,
                includeInPrint: !programState?.includeInPrint,
              },
            },
          };

          return updatedClientYearState;
        }
      );

    case SET_CHECKLIST_QUESTION_STATUS:
      return updateClientQuestionStatus(
        state,
        action.clientId,
        action.financialYear,
        action.periodId,
        (periodStatuses) => {
          const updatedPeriodStatuses: ChecklistPeriodStatuses = {
            ...periodStatuses,
            [action.questionId]: action.status,
          };

          return updatedPeriodStatuses;
        }
      );

    case SET_PERSON_CHECKLIST_PROGRAM:
      return updatePersonYear(
        state,
        action.personId,
        action.financialYearId,
        (personYearState) => {
          const updatedPersonYearState: ChecklistYearState = {
            ...personYearState,
            programs: {
              ...personYearState.programs,
              [action.program]: action.checklist,
            },
          };

          return updatedPersonYearState;
        }
      );

    case SET_PERSON_CHECKLIST_STATUSES:
      return updatePersonYear(
        state,
        action.personId,
        action.financialYearId,
        (personYearState) => {
          const updatedPersonYearState: ChecklistYearState = {
            ...personYearState,
            statuses: action.statuses,
          };

          return updatedPersonYearState;
        }
      );

    case SET_PERSON_CHECKLIST_QUESTION_STATUS:
      return updatePersonQuestionStatus(
        state,
        action.personId,
        action.financialYearId,
        action.periodId,
        (periodStatuses) => {
          const updatedPeriodStatuses: ChecklistPeriodStatuses = {
            ...periodStatuses,
            [action.questionId]: action.status,
          };

          return updatedPeriodStatuses;
        }
      );

    default:
      return state;
  }
};

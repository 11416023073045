import { ConfigType, getAnnualConfig, isAnnualConfigName } from '@agoy/common';
import {
  ResolvedReference,
  ResolveReferenceContext,
  ResolveReferenceInput,
  TimePeriod,
} from '../types';

/**
 * A context that resolves annual config values for the taxation year.
 */
export class AnnualConfigContext implements ResolveReferenceContext {
  parent?: ResolveReferenceContext | undefined;

  input: ResolveReferenceInput;

  config: ConfigType | undefined;

  constructor(
    parent: ResolveReferenceContext | undefined,
    input: ResolveReferenceInput,
    period: TimePeriod
  ) {
    this.parent = parent;
    this.input = input;
    this.config = getAnnualConfig(period.endDate.getFullYear());
  }

  resolveById(id: string, context: ResolveReferenceContext): ResolvedReference {
    return this.parent?.resolveById(id, context);
  }

  resolveConfig(
    name: string,
    context: ResolveReferenceContext
  ): ResolvedReference {
    if (!this.config || !isAnnualConfigName(name)) {
      return this.parent?.resolveConfig(name, context);
    }
    return this.config[name];
  }
}

export default AnnualConfigContext;

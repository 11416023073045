export const getStatementOptions = (pronoun = 'Jag (Vi)') => [
  {
    id: 'notApplicable',
    label: 'Ej tillämpat',
    title: 'Ej tillämpat',
    name: '',
  },
  {
    id: 'rejectedResultsAndBalance',
    label: 'Avstyrkt resultat- och balansräkning',
    title: `${pronoun} har i denna avstyrkt att resultaträkningen och balansräkningen fastställs.`,
    name: 'se-mem-base:RevisorspateckningMeningAvstyrktAttResultatrakningBalansrakningFaststallsMember',
  },
  {
    id: 'rejectedResults',
    label: 'Avstyrkt resultaträkning',
    title: `${pronoun} har i denna avstyrkt att resultaträkningen fastställs.`,
    name: 'se-mem-base:RevisorspateckningMeningAvstyrktAttResultatrakningFaststallsMember',
  },
  {
    id: 'rejectedBalance',
    label: 'Avstyrkt balansräkning',
    title: `${pronoun} har i denna avstyrkt att balansräkningen fastställs.`,
    name: 'se-mem-base:RevisorspateckningMeningAvstyrktAttBalansrakningFaststallsMember',
  },
  {
    id: 'rejectedResultatAndEitherBalance',
    label: 'Avstyrkt Resultatrakning varken till eller avstyrkt Balansrakning',
    title: `${pronoun} har i denna avstyrkt att resultaträkningen och varken till- eller avstyrkt att balansräkningen fastställs.`,
    name: 'se-mem-base:RevisorspateckningMeningAvstyrktAttResultatrakningVarkenTillEllerAvstyrktBalansrakningFaststallsMember',
  },
  {
    id: 'rejectedBalanceAndEitherResultat',
    label: 'Avstyrkt Balansrakning varken till eller avstyrkt Resultatrakning',
    title: `${pronoun} har i denna avstyrkt att balansräkningen och varken till- eller avstyrkt att resultaträkningen fastställs.`,
    name: 'se-mem-base:RevisorspateckningMeningAvstyrktAttBalanstrakningVarkenTillEllerAvstyrktResultatrakningFaststallsMember',
  },
  {
    id: 'neitherRejectedResultsAndBalance',
    label: 'Varken till- eller avstyrkt resultat- och balansräkning',
    title: `${pronoun} har i denna varken till- eller avstyrkt att resultaträkningen och balansräkningen fastställs.`,
    name: 'se-mem-base:RevisorspateckningMeningVarkenTillEllerAvstyrktAttResultatrakningBalansrakningFaststallsMember',
  },
  {
    id: 'neitherRejectedResults',
    label: 'Varken till- eller avstyrkt resultaträkning',
    title: `${pronoun} har i denna varken till- eller avstyrkt att resultaträkningen fastställs.`,
    name: 'se-mem-base:RevisorspateckningMeningVarkenTillEllerAvstyrktAttResultatrakningFaststallsMember',
  },
  {
    id: 'neitherRejectedBalance',
    label: 'Varken till- eller avstyrkt balansräkning',
    title: `${pronoun} har i denna varken till- eller avstyrkt att balansräkningen fastställs.`,
    name: 'se-mem-base:RevisorspateckningMeningVarkenTillEllerAvstyrktAttBalansrakningFaststallsMember',
  },
];

export const NewStatementOptions = [
  {
    id: 'notApplicable',
    label: 'Inget uttalande valt',
    title: 'Inget uttalande valt',
    name: '',
  },
  {
    id: 'rejectedResultsAndBalance',
    label: 'Avstyrkt resultat- och balansräkning',
    title: 'Avstyrkt fastställande av resultat- och balansräkningen',
    name: 'se-mem-base:RevisorspateckningMeningAvstyrktAttResultatrakningBalansrakningFaststallsMember',
  },
  {
    id: 'rejectedResults',
    label: 'Avstyrkt resultaträkning',
    title: 'Avstyrkt fastställande av resultaträkningen',
    name: 'se-mem-base:RevisorspateckningMeningAvstyrktAttResultatrakningFaststallsMember',
  },
  {
    id: 'rejectedBalance',
    label: 'Avstyrkt balansräkning',
    title: 'Avstyrkt fastställande av balansräkningen',
    name: 'se-mem-base:RevisorspateckningMeningAvstyrktAttBalansrakningFaststallsMember',
  },
  {
    id: 'rejectedResultatAndEitherBalance',
    label: 'Avstyrkt Resultatrakning varken till eller avstyrkt Balansrakning',
    title:
      'Avstyrkt fastställande av resultaträkningen, ingen åsikt om balansräkningen',
    name: 'se-mem-base:RevisorspateckningMeningAvstyrktAttResultatrakningVarkenTillEllerAvstyrktBalansrakningFaststallsMember',
  },
  {
    id: 'rejectedBalanceAndEitherResultat',
    label: 'Avstyrkt Balansrakning varken till eller avstyrkt Resultatrakning',
    title:
      'Avstyrkt fastställande av balansräkningen, ingen åsikt om resultaträkningen',
    name: 'se-mem-base:RevisorspateckningMeningAvstyrktAttBalanstrakningVarkenTillEllerAvstyrktResultatrakningFaststallsMember',
  },
  {
    id: 'neitherRejectedResultsAndBalance',
    label: 'Varken till- eller avstyrkt resultat- och balansräkning',
    title:
      'Ingen åsikt om fastställande av varken resultat- eller balansräkningen',
    name: 'se-mem-base:RevisorspateckningMeningVarkenTillEllerAvstyrktAttResultatrakningBalansrakningFaststallsMember',
  },
  {
    id: 'neitherRejectedResults',
    label: 'Varken till- eller avstyrkt resultaträkning',
    title: 'Ingen åsikt om fastställande av resultaträkningen',
    name: 'se-mem-base:RevisorspateckningMeningVarkenTillEllerAvstyrktAttResultatrakningFaststallsMember',
  },
  {
    id: 'neitherRejectedBalance',
    label: 'Varken till- eller avstyrkt balansräkning',
    title: 'Ingen åsikt om fastställande av balansräkningen',
    name: 'se-mem-base:RevisorspateckningMeningVarkenTillEllerAvstyrktAttBalansrakningFaststallsMember',
  },
];

import React from 'react';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Box, Typography } from '@material-ui/core';

import { useSelector } from 'redux/reducers';
import { ClientsRouteParams } from 'routes/types';
import { formatEndOfMonth, reformat } from '@agoy/dates';

const Header = ({ period, actualFinancialYear }) => {
  const intl = useIntl();
  const { clientId } = useParams<ClientsRouteParams>();
  const { orgNumber, name } = useSelector(
    (state) => state.customers[clientId!]
  );

  const formattedFinancialYear = actualFinancialYear
    ?.split('-')
    .map((item) => reformat(item, 'yyyyMMdd', 'yyyy-MM-dd'))
    .join(' - ');

  return (
    <div>
      <Typography variant="h2">{name}</Typography>
      <Typography component="div">
        {intl.formatMessage({
          id: 'print.periodSpecification.organisationNumber',
        })}
        <Box component="span" paddingLeft={1} fontWeight="fontWeightBold">
          {orgNumber}
        </Box>
      </Typography>
      <Typography component="div">
        {intl.formatMessage({ id: 'print.periodSpecification.closingDate' })}
        <Box component="span" paddingLeft={1} fontWeight="fontWeightBold">
          {formatEndOfMonth(period.start, 'yyyy-MM-dd')}
        </Box>
      </Typography>
      <Typography>
        {intl.formatMessage({ id: 'financialyear' })}
        <Box component="span" paddingLeft={1} fontWeight="fontWeightBold">
          {formattedFinancialYear}
        </Box>
      </Typography>
    </div>
  );
};

export default Header;

import { RowChange } from '../changes';
import { AgoyDocumentStructure } from '../document';
import updateDocument from './helpers/updateDocument';
import { OperationResult, State } from './types';

const resetWithoutActive = (rows: RowChange[]): RowChange[] =>
  rows
    .map((changes): RowChange | undefined => {
      if (changes.type !== 'update') {
        return undefined;
      }
      const subRows = changes.rows ? resetWithoutActive(changes.rows) : [];
      const subRowsValue = subRows.length > 0 ? subRows : undefined;
      const hasActiveValue = changes.row && 'active' in changes.row;

      return subRowsValue || hasActiveValue
        ? {
            ...changes,
            rows: subRowsValue,
            row: hasActiveValue ? { active: changes.row?.active } : undefined,
          }
        : undefined;
    })
    .filter((subRow): subRow is RowChange => !!subRow);

/**
 * Operation to remove changes by id.
 *
 * Note that only the changes are affected, the document is untouched and
 * have to be recreated from the updated changes.
 *
 * Currently supporting  "part" and "table" types.
 *
 * @param structure
 * @param state
 * @param id
 * @returns
 */
const removeChanges = <T extends AgoyDocumentStructure>(
  structure: T,
  state: State<T>,
  id: string,
  resetActive = true
): OperationResult<T> => {
  return updateDocument(structure, state, id, {
    table: (key, id, props) => {
      if (props.changes) {
        /**
         * Reset previous changes by provided rowId
         */
        if (key?.length) {
          const revertedChanges = props.changes.rows?.filter(
            (row) => !key.find((keyId) => keyId === row.id)
          );

          return {
            ...props,
            changes: {
              ...props.changes,
              rows: revertedChanges,
            },
          };
        }
        if (!resetActive) {
          const newChangesRows =
            props.changes.rows && resetWithoutActive(props.changes.rows);
          return {
            ...props,
            changes: newChangesRows?.length
              ? {
                  type: 'update',
                  active: props.changes.active,
                  rows: newChangesRows,
                }
              : undefined,
          };
        }

        return {
          ...props,
          changes: undefined,
        };
      }

      return props;
    },
    part: (key, id, props) => {
      if (props.changes) {
        return {
          ...props,
          changes: undefined,
        };
      }
      return props;
    },
    field: (key, id, props) => {
      if (props.changes) {
        return {
          ...props,
          changes: undefined,
        };
      }
      return props;
    },
  });
};

export default removeChanges;

import { Middleware } from 'redux';
import { RootState } from 'redux/reducers';
import {
  RESET_TAX_DECLARATION_FORM_FIELD,
  UPDATE_TAX_DECLARATION_FORM_FIELD,
  UPDATE_TAX_DECLARATION_FORM_EXTERNAL_FIELD,
  UPDATE_TAX_DECLARATION_TABLE_ROW_CELL,
  DELETE_TAX_DECLARATION_TABLE_ROW,
  ADD_TAX_DECLARATION_TABLE_ROW,
  RESET_TAX_DECLARATION_TABLE,
  TOGGLE_TAX_DECLARATION_TABLE_ROW_ACTIVE,
  UPDATE_TAX_DECLARATION_TABLE_ROWS,
} from 'redux/actionsTypes';
import debounceByKey from 'utils/debounceByKey';

import { AgoyDocumentChanges } from '@agoy/document';
import {
  savingUserDataFailed,
  savingUserDataSuccess,
  startedSavingUserData,
} from '_reconciliation/redux/accounting-view/actions';
import { TaxDeclarationFormStructure } from '@agoy/tax-declaration-document';
import { asResultClass, getApiSdk } from 'api-sdk';
import { getContext } from 'utils/AgoyAppClient/contextHolder';

const triggers = [
  UPDATE_TAX_DECLARATION_FORM_FIELD,
  RESET_TAX_DECLARATION_FORM_FIELD,
  UPDATE_TAX_DECLARATION_FORM_EXTERNAL_FIELD,
  UPDATE_TAX_DECLARATION_TABLE_ROW_CELL,
  DELETE_TAX_DECLARATION_TABLE_ROW,
  ADD_TAX_DECLARATION_TABLE_ROW,
  RESET_TAX_DECLARATION_TABLE,
  TOGGLE_TAX_DECLARATION_TABLE_ROW_ACTIVE,
  UPDATE_TAX_DECLARATION_TABLE_ROWS,
];

const saveTaxDeclarationForm = async (
  dispatch,
  customerId: string,
  formId: string,
  financialYear: string,
  changes: AgoyDocumentChanges<TaxDeclarationFormStructure>
) => {
  dispatch(startedSavingUserData());
  try {
    const updateResult = await asResultClass(
      getApiSdk(getContext()).addAgoyDocumentChanges({
        documentId: formId,
        clientId: customerId,
        requestBody: changes,
      })
    );
    if (updateResult.err) {
      throw new Error('Failed to update changes in form data');
    }
    dispatch(savingUserDataSuccess());
  } catch (e) {
    dispatch(savingUserDataFailed());
  }
};

const key = (
  dispatch,
  customerId: string,
  formId: string,
  financialYear: string,
  changes: AgoyDocumentChanges<TaxDeclarationFormStructure>
): string => `${customerId}-${formId}-${financialYear}`;

const save = debounceByKey(key, saveTaxDeclarationForm, 2000);

const saveTaxDeclarationFormMiddleware: Middleware<{}, RootState> =
  (store) => (next) => (action) => {
    const { taxDeclarationView: prevState } = store.getState();
    const result = next(action);
    const { taxDeclarationView, customerView } = store.getState();
    const { viewFormId: selectedFormId } = taxDeclarationView;
    const selectedDeclarationYear = customerView.currentFinancialYear;

    if (!selectedFormId) return result;

    const prevFormData = prevState.formData[selectedFormId];
    const formData = taxDeclarationView.formData[selectedFormId];
    const changes = formData?.changes;

    if (prevFormData?.changes !== changes && triggers.includes(action.type)) {
      const { currentCustomer } = store.getState().customerView;
      if (currentCustomer && selectedDeclarationYear && changes) {
        save(
          store.dispatch,
          currentCustomer,
          selectedFormId,
          selectedDeclarationYear,
          changes
        );
      }
    }

    return result;
  };

export default saveTaxDeclarationFormMiddleware;

import React from 'react';
import styled from '@emotion/styled';

const Wrapper = styled.div`
  display: flex;
  margin: 0 8px;
  width: 100%;
  justify-content: space-between;
`;

const Title = styled.div`
  margin-right: 8px;
  text-transform: none;
  background-color: ${(props) =>
    props.theme.palette.accountingView.headers.main}
  color: ${(props) => props.theme.palette.secondary.contrastText};
`;

const SpeedDialItem = ({
  title = '',
  icon,
}: {
  title: string;
  icon: React.ReactNode;
}): JSX.Element => (
  <Wrapper>
    <Title>{title}</Title>
    {icon}
  </Wrapper>
);

export default SpeedDialItem;

import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';
import { useApiSdk } from 'api-sdk';
import { useDispatch } from 'react-redux';
import { addGlobalErrorMessage, addGlobalMessage } from 'redux/actions';

import Licenses from '_payment/components/pages/Licenses';
import LoadingPlaceholder from '_shared/components/LoadingPlaceholder';
import { useIntl } from 'react-intl';

const Wrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing(2)}px;
`;

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;

  height: 70vh;
`;

type Sdk = Awaited<ReturnType<typeof useApiSdk>>;
type LicensesType = Parameters<typeof Licenses>[0]['licenses'];

/**
 * Extract the license type of a memberId, from the big / small license member lists
 */
const findLicenseType = ({
  memberId,
  bigLicenseMembers: bigMembers,
  smallLicenseMembers: smallMembers,
}: {
  memberId: string;
  bigLicenseMembers: Awaited<
    ReturnType<Sdk['listOrganisationPackages']>
  >['big']['users'];
  smallLicenseMembers: Awaited<
    ReturnType<Sdk['listOrganisationPackages']>
  >['small']['users'];
}): LicensesType['members'][number]['license'] => {
  const isBig = bigMembers.find(
    (licenseMember) => licenseMember.id === memberId
  );
  const isSmall = smallMembers.find(
    (licenseMember) => licenseMember.id === memberId
  );

  if (isBig) return 'big';
  if (isSmall) return 'small';

  return 'none';
};

/**
 * Build the license structure that the FE expects,
 * from the output of the `members` and `packages` API requests
 */
export const buildLicenseStructure = (
  members: Awaited<ReturnType<Sdk['getOrganisationMembers']>>,
  packages: Awaited<ReturnType<Sdk['listOrganisationPackages']>>
): LicensesType => {
  const licenses: LicensesType = {
    small: {
      available: packages.small.quantity - packages.small.users.length,
      total: packages.small.quantity,
    },
    big: {
      available: packages.big.quantity - packages.big.users.length,
      total: packages.big.quantity,
    },
    members: members.map((member) => ({
      id: member.userId,
      name: `${member.givenName} ${member.familyName}`,
      license: findLicenseType({
        memberId: member.userId,
        bigLicenseMembers: packages.big.users,
        smallLicenseMembers: packages.small.users,
      }),
    })),
  };

  return licenses;
};

const PaymentDetailsContainer = () => {
  const history = useHistory();
  const sdk = useApiSdk();
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const [isLoading, setIsLoading] = useState(false);
  const [updatingLicenses, setUpdatingLicenses] = useState(false);
  const [licenses, setLicenses] = useState<LicensesType | undefined>();

  const fetchMembersAndLicenses = async () => {
    try {
      const [members, packages] = await Promise.all([
        sdk.getOrganisationMembers(),
        sdk.listOrganisationPackages(),
      ]);

      setLicenses(buildLicenseStructure(members, packages));
    } catch (error) {
      dispatch(addGlobalErrorMessage('error'));
    }
  };

  React.useEffect(() => {
    setIsLoading(true);
    fetchMembersAndLicenses();
    setIsLoading(false);
  }, []);

  const handleGoToLicenseManagement = () => {
    history.push(`/organisation/payment-details`);
  };

  const handleLicenseAssignment = async (
    userId: string,
    newLicenseType: LicensesType['members'][number]['license']
  ) => {
    setUpdatingLicenses(true);

    try {
      if (newLicenseType === 'none') {
        await sdk.unAssignPackageFromUser({
          userId,
        });
      } else {
        await sdk.assignPackageToUser({
          requestBody: {
            userId,
            package: newLicenseType,
          },
        });
      }
    } catch (error) {
      dispatch(
        addGlobalMessage(
          'error',
          formatMessage({ id: 'license.assignment.error' })
        )
      );
    }
    await fetchMembersAndLicenses();

    setUpdatingLicenses(false);
  };

  if (isLoading || !licenses) {
    return (
      <LoadingWrapper>
        <LoadingPlaceholder />
      </LoadingWrapper>
    );
  }

  return (
    <Wrapper>
      <Licenses
        licenses={licenses}
        updatingLicenses={updatingLicenses}
        handleGoToLicenseManagement={handleGoToLicenseManagement}
        handleLicenseAssignment={handleLicenseAssignment}
      />
    </Wrapper>
  );
};

export default PaymentDetailsContainer;

import { account, not, ref, round, table, value } from '@agoy/document';
import { Note2Section } from '../../../../common';
import {
  activeReference,
  field,
  ixbrlCell,
  ixbrlField,
} from '../../../../common/utils/util';
import { previousYear, year } from '../../../../common/utils/date-util';

const getNote2 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null,
  isDigitalSubmission: boolean,
  documentTypeVersion = '1'
): Note2Section => {
  return {
    active: false,
    number: value(undefined),
    name: field('Medelantal anställda'),
    noEmployees: activeReference(
      field('Bolaget har ej haft några anställda under verksamhetsåret.'),
      ref(not(round(account('7000:7399'))))
    ),
    avgNumberOfEmployees: activeReference(
      field(
        'Medelantalet anställda bygger på av bolaget betalda närvarotimmar relaterade till en normal arbetstid.'
      ),
      ref(round(account('7000:7399')))
    ),
    avgNumberOfEmployeesTable: table(
      'notes.note2.avgNumberOfEmployeesTable',
      { id: 'label', label: '' },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      .setActive(ref(round(account('7000:7399'))))
      .addRows((rows) => {
        rows.addRow(
          '1',
          noteLabel('Medelantal anställda'),
          ixbrlCell(value(undefined), {
            type: 'decimalItemType',
            name: 'se-gen-base:MedelantaletAnstallda',
            contextRef: 'period0',
            unitRef: 'antal-anstallda',
          }),
          ixbrlCell(value(undefined), {
            type: 'decimalItemType',
            name: 'se-gen-base:MedelantaletAnstallda',
            contextRef: 'period1',
            unitRef: 'antal-anstallda',
          })
        );
        if (!isDigitalSubmission) {
          rows
            .addRow(
              '2',
              noteLabel('Varav kvinnor'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '3',
              noteLabel('Varav män'),
              value(undefined),
              value(undefined)
            );
        }
        return rows.build();
      })
      .newRowTemplate(value(''), value(undefined), value(undefined))
      .build(),
    commentLabel: field('Kommentar'),
    comment: ixbrlField(field(''), {
      type: 'stringItemType',
      name: 'se-gen-base:NotMedelantaletAnstalldaKommentar',
      contextRef: 'period0',
    }),
    group: value(undefined),
    section: value(undefined),
    validForReportType: {
      k2: true,
      k3: documentTypeVersion === '1',
    },
  };
};

export default getNote2;

import { useContext, useMemo, useRef } from 'react';
import { MultiReferenceCell } from '@agoy/document';
import {
  getNotesByReportType,
  groupNotesByStructure,
  mapNotes,
  referencedNotes,
  sortNotes,
} from '_shared/utils/notesUtils/utils';
import { GroupedNotes } from '_shared/utils/notesUtils/types';
import { getAnnualReportNotesStructure } from '@agoy/annual-report-document';
import useDocumentConfiguration from '_annual-report/components/AnnualReportView/useDocumentConfiguration';
import NotesContext from '_annual-report/components/AnnualReportView/Context/NotesContext';

const useNotesSelectionList = (
  cell: MultiReferenceCell,
  notesSectionName: string
) => {
  const notes = useContext(NotesContext);
  const { reportType, isDigitalSubmission, version, documentType } =
    useDocumentConfiguration() || {};

  const selectedNotes = referencedNotes(cell.references);
  const initialSelection = useRef(selectedNotes);

  const groupedNotes = useMemo<GroupedNotes>(() => {
    if (!documentType || !reportType) {
      return {
        incomeStatementNotes: [],
        balanceSheetNotes: [],
        otherNotes: [],
      };
    }

    const noteGroups = groupNotesByStructure(
      notesSectionName,
      getAnnualReportNotesStructure(documentType, reportType, version || '1'),
      version === '2'
        ? mapNotes(notes)
        : getNotesByReportType(
            mapNotes(notes),
            reportType,
            !!isDigitalSubmission
          )
    );

    return {
      incomeStatementNotes: sortNotes(
        noteGroups.incomeStatementNotes,
        initialSelection.current
      ),
      balanceSheetNotes: sortNotes(
        noteGroups.balanceSheetNotes,
        initialSelection.current
      ),
      otherNotes: sortNotes(noteGroups.otherNotes, initialSelection.current),
    };
  }, [
    documentType,
    isDigitalSubmission,
    notes,
    notesSectionName,
    reportType,
    version,
  ]);

  return {
    selectedNotes,
    groupedNotes,
  };
};

export default useNotesSelectionList;

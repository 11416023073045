import React, { createContext, useMemo } from 'react';
import { NotificationService } from './types';
import NotificationServiceImpl from './NotificationServiceImpl';

export const NotificationContext = createContext<NotificationService | null>(
  null
);

export const NotificationContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const service = useMemo(() => new NotificationServiceImpl(), []);

  return (
    <NotificationContext.Provider value={service}>
      {children}
    </NotificationContext.Provider>
  );
};

import {
  TaxDeclarationFormDefinition,
  TaxDeclarationFormDataConfig,
  PersonMetaData,
} from '../../types';
import { id, ref, value } from '@agoy/document';
import { mapFormId, toInitial, toStructure } from '../forms-util';

const configK11 = (
  form: TaxDeclarationFormDefinition,
  person: PersonMetaData
): TaxDeclarationFormDataConfig => {
  const name = `${person?.firstName || ''} ${person?.lastName || ''}`;
  const K11 = mapFormId(form, 'K11');
  const initial = {
    K11: {
      partType: 'form' as const,
      id: K11,
      fields: {
        datum: value(''),
        numrering_vid_flera_k11: value(undefined),
        delagarens_namn: value(name),
        personnummer: value(person.personNumber || ''),
        foretagets_namn: value(''),
        organisationsnummer: value(''),
        A_1_underlag_investeraravdrag: value(undefined),
        A_2_maximalt_investeraravdrag: value(undefined),
        A_3_begart_investeraravdrag: value(undefined),
        A_4_antal_andelar: value(undefined),
        A_5_betalningen_andelarna: value(false),
        B_1_investeraravdrag: value(undefined),
        B_2_antal_andelar_aktier: value(undefined),
        C_1_investeraravdrag: value(undefined),
        D_1_investeraravdrag: value(undefined),
        E_1_investeraravdrag: value(undefined),
      },
      externalFields: {
        k11_utlandsk_bolag: value(false),
      },

      sru: {
        '7014': ref(id('K11.fields.numrering_vid_flera_k11')),
        '7050': ref(id('K11.externalFields.k11_utlandsk_bolag')),
        '4530': ref(id('K11.fields.organisationsnummer')),
        '5910': ref(id('K11.fields.A_1_underlag_investeraravdrag')),
        '5911': ref(id('K11.fields.A_2_maximalt_investeraravdrag')),
        '5912': ref(id('K11.fields.A_3_begart_investeraravdrag')),
        '5913': ref(id('K11.fields.A_4_antal_andelar')),
        '5915': ref(id('K11.fields.A_5_betalningen_andelarna')),
        '5920': ref(id('K11.fields.B_1_investeraravdrag')),
        '5921': ref(id('K11.fields.B_2_antal_andelar_aktier')),
        '5930': ref(id('K11.fields.C_1_investeraravdrag')),
        '5940': ref(id('K11.fields.D_1_investeraravdrag')),
        '5950': ref(id('K11.fields.E_1_investeraravdrag')),
      },
    },
  };
  return {
    initial: toInitial(initial, form),
    definition: toStructure(initial, form),
  };
};

export default configK11;

import {
  TaxDeclarationFormDefinition,
  TaxDeclarationFormDataConfig,
} from '../../types';
import {
  ref,
  value,
  id,
  or,
  sum,
  multiply,
  sumAllowNull,
} from '@agoy/document';
import {
  mapFormId,
  toStructure,
  onlyNegative,
  onlyPositive,
  toInitial,
} from '../forms-util';

const configN3B = (
  form: TaxDeclarationFormDefinition,
  financialYearStart: string,
  financialYearEnd: string
): TaxDeclarationFormDataConfig => {
  const N3B = mapFormId(form, 'N3B');
  const initial = {
    N3B: {
      partType: 'form' as const,
      id: N3B,
      fields: {
        date: value(''),
        partnerName: value(''),
        partnerOrgNr: value(''),
        firmName: value(''),
        firmOrgNr: value(''),
        accountingYearFrom: value(''),
        accountingYearTo: value(''),
        '1_pos': value(undefined),
        '2_neg': value(undefined),
        '3_a_pos': value(undefined),
        '3_b_neg': value(undefined),
        '4_a_pos': value(undefined),
        '4_b_neg': value(undefined),
        '5_a_pos': value(undefined),
        '5_b_neg': value(undefined),
        '6_neg': ref(
          sumAllowNull(
            id('N4-*.N4.fields.A_2_andel'),
            id('N4-*.N4.fields.A_3_tidigare_uppskov')
          )
        ),
        '7_pos': ref(
          sumAllowNull(
            id('N4-*.N4.fields.A_4_uppskov_som_ska_aterforas'),
            id('N4-*.N4.fields.B_1_uppskov_som_ska_aterforas')
          )
        ),
        '8_neg': value(undefined),
        '9_pos': value(undefined),
        '10_surplus': ref(
          onlyPositive(
            sum(
              or(id('N3B.fields.1_pos'), 0),
              multiply(-1, or(id('N3B.fields.2_neg'), 0)),
              or(id('N3B.fields.3_a_pos'), 0),
              multiply(-1, or(id('N3B.fields.3_b_neg'), 0)),
              or(id('N3B.fields.4_a_pos'), 0),
              multiply(-1, or(id('N3B.fields.4_b_neg'), 0)),
              or(id('N3B.fields.5_a_pos'), 0),
              multiply(-1, or(id('N3B.fields.5_b_neg'), 0)),
              multiply(-1, or(id('N3B.fields.6_neg'), 0)),
              or(id('N3B.fields.7_pos'), 0),
              multiply(-1, or(id('N3B.fields.8_neg'), 0)),
              or(id('N3B.fields.9_pos'), 0)
            )
          )
        ),
        '10_deficit': ref(
          onlyNegative(
            sum(
              or(id('N3B.fields.1_pos'), 0),
              multiply(-1, or(id('N3B.fields.2_neg'), 0)),
              or(id('N3B.fields.3_a_pos'), 0),
              multiply(-1, or(id('N3B.fields.3_b_neg'), 0)),
              or(id('N3B.fields.4_a_pos'), 0),
              multiply(-1, or(id('N3B.fields.4_b_neg'), 0)),
              or(id('N3B.fields.5_a_pos'), 0),
              multiply(-1, or(id('N3B.fields.5_b_neg'), 0)),
              multiply(-1, or(id('N3B.fields.6_neg'), 0)),
              or(id('N3B.fields.7_pos'), 0),
              multiply(-1, or(id('N3B.fields.8_neg'), 0)),
              or(id('N3B.fields.9_pos'), 0)
            )
          )
        ),
        '11': value(undefined),
        '12_pos': value(undefined),
        '13_pos': value(undefined),
        '14_neg': value(undefined),
        '15_neg': value(undefined),
        '16': value(undefined),
        '17': value(undefined),
        '18': value(undefined),
      },
      sru: {
        '7011': value(financialYearStart),
        '7012': value(financialYearEnd),
        '7050': value(false), // bolaget är utländskt bolag
        '7030': ref(id('N3B.fields.firmOrgNr')),
        '7015': ref(id('N3B.fields.accountingYearFrom')),
        '7016': ref(id('N3B.fields.accountingYearTo')),
        '7800': ref(id('N3B.fields.1_pos')),
        '7900': ref(id('N3B.fields.2_neg')),
        '7802': ref(id('N3B.fields.3_a_pos')),
        '7902': ref(id('N3B.fields.3_b_neg')),
        '7803': ref(id('N3B.fields.4_a_pos')),
        '7903': ref(id('N3B.fields.4_b_neg')),
        '7810': ref(id('N3B.fields.5_a_pos')),
        '7911': ref(id('N3B.fields.5_b_neg')),
        '7904': ref(id('N3B.fields.6_neg')),
        '7804': ref(id('N3B.fields.7_pos')),
        '7905': ref(id('N3B.fields.8_neg')),
        '7805': ref(id('N3B.fields.9_pos')),
        '7830': ref(id('N3B.fields.10_surplus')),
        '7930': ref(id('N3B.fields.10_deficit')),
        '7806': ref(id('N3B.fields.11')),
        '7807': ref(id('N3B.fields.12_pos')),
        '7808': ref(id('N3B.fields.13_pos')),
        '7907': ref(id('N3B.fields.14_neg')),
        '7908': ref(id('N3B.fields.15_neg')),
        '7809': ref(id('N3B.fields.16')),
        '7909': ref(id('N3B.fields.17')),
        '8080': ref(id('N3B.fields.18')),
      },
    },
  };
  return {
    initial: toInitial(initial, form),
    definition: toStructure(initial, form),
  };
};

export default configN3B;

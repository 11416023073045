import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import styled from '@emotion/styled';
import { Tooltip, Button } from '@material-ui/core';
import UpdateIcon from '@material-ui/icons/Update';
import {
  addGlobalMessage,
  addGlobalErrorMessage,
  getClient,
  setIntegrationStatus,
} from 'redux/actions';
import LoadingLogo from '_shared/components/LoadingLogo';
import { useApiSdk } from 'api-sdk';
import { useSelector } from 'redux/reducers';
import config from '_shared/services/config';
import { getPeriodStatusReconciliation } from '_reconciliation/redux/accounting-view/selectors';
import useClientDataLayer from 'data/client/useClientDataLayer';

const IconWrapper = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 64px;
  cursor: pointer;
  fill: #002517;

  &[data-selected] {
    color: white;
    fill: white;
    background-color: ${({ theme }) => theme.palette.primary.light};
  }

  &:hover {
    fill: white;
  }

  & > svg {
    width: 35px;
  }
`;

const UpdateSIEButton = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  // STATE
  const [loading, setLoading] = useState(false);

  const isLocked = useSelector(
    getPeriodStatusReconciliation(null, (state) => state.status === 'LOCKED')
  );

  const { currentCustomer, currentFinancialYear, currentFinancialYearId } =
    useSelector((state) => {
      return state.customerView;
    });

  const clientDataLayer = useClientDataLayer(currentCustomer);

  const isFortnoxMode = config.whiteLabelUI === 'fortnox';

  // API
  const sdk = useApiSdk();

  // CALLBACKS

  const handleUpdateWithFortnoxAction = async () => {
    if (
      !currentCustomer ||
      !currentFinancialYear ||
      !currentFinancialYearId ||
      !clientDataLayer
    ) {
      return;
    }

    setLoading(true);

    try {
      const { isActive } = await sdk.getFortnoxIntegrationStatus({
        clientid: currentCustomer,
      });
      if (!isActive) throw Error('Fortnox integration not found');

      dispatch(
        setIntegrationStatus(currentCustomer, 'fortnox', {
          lastModified: new Date().toISOString(),
          lastAccessed: new Date().toISOString(),
        })
      );

      const result =
        await clientDataLayer.accountingBalances.updateSieFromFortnox(
          currentFinancialYear,
          currentFinancialYearId
        );

      if (result.err) {
        if (
          'errorDetails' in result.val &&
          'message' in result.val.errorDetails &&
          (result.val.errorDetails.message === 'Error from Fortnox' ||
            result.val.errorDetails.message === 'Not authorized') &&
          'detailsMessage' in result.val.errorDetails &&
          typeof result.val.errorDetails.detailsMessage === 'string'
        ) {
          const { detailsMessage } = result.val.errorDetails;
          dispatch(addGlobalErrorMessage(detailsMessage));
          return;
        }

        console.error(result.val);
        dispatch(
          addGlobalErrorMessage('customersubmenu.updatewithfortnox.error')
        );
        return;
      }

      dispatch(
        addGlobalMessage('success', 'customersubmenu.updatewithfortnox.success')
      );
      dispatch(getClient(currentCustomer));
    } catch (error) {
      dispatch(
        addGlobalMessage('error', 'customersubmenu.updatewithfortnox.error')
      );

      if (error.body?.code === 'integration.missing') {
        dispatch(setIntegrationStatus(currentCustomer, 'fortnox', null));
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Tooltip
      title={formatMessage({ id: 'customersubmenu.updatewithfortnox' })}
      placement="right"
    >
      <IconWrapper
        id="fortnox"
        disabled={isLocked || loading}
        onClick={handleUpdateWithFortnoxAction}
      >
        <div>{loading ? <LoadingLogo size="small" /> : <UpdateIcon />}</div>
      </IconWrapper>
    </Tooltip>
  );
};

export default UpdateSIEButton;

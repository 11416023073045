import React from 'react';
import styled from '@emotion/styled';

import GenericModal from '_shared/components/Modal/GenericModal';

const Wrapper = styled.div`
  display: flex;
  flex-flow: row;
`;

const getPdfFileString = (content: string) => {
  const dataURIString = `data:application/pdf;base64,`;
  return `${dataURIString}${content}`;
};

interface Props {
  isVisible: boolean;
  fileContent: string | Array<string>;
  onClose: () => void;
}
const PdfStringModal = ({ isVisible, fileContent, onClose }: Props) => {
  const handleHideModal = () => onClose();
  if (typeof fileContent === 'string') {
    console.log(fileContent);
    return (
      <Wrapper>
        {isVisible && fileContent && (
          <GenericModal
            fullHeight
            handleClose={handleHideModal}
            open={isVisible}
          >
            <iframe
              title="preview"
              src={getPdfFileString(fileContent)}
              style={{ height: '80vh', width: '100%', border: 'none' }}
            />
          </GenericModal>
        )}
      </Wrapper>
    );
  }
  return <> </>;
};

export default PdfStringModal;

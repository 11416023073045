import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Document, Page } from 'react-pdf/dist/esm/entry.vite';

const PdfPreview = styled(Document)`
  width: 100%;
`;

interface TestPdfProps {
  onLoaded: () => void;
}

const ORIGINAL_PDF_HEIGHT = 792;
const PRINT_PDF_WIDTH = 784;

const TestPdf = ({ onLoaded }: TestPdfProps) => {
  const [numPages, setNumPages] = useState<number | undefined>();
  const [pageHeightDifference, setPageHeightDifference] = useState(0);

  const onPDFLoaded = async (pdf) => {
    const page = await pdf.getPage(1);
    const pageHeight = page.view[3];
    const pageWidth = page.view[2];

    if (pageHeight > ORIGINAL_PDF_HEIGHT * 2) {
      const aspectRatio = pageWidth / PRINT_PDF_WIDTH;
      const calculatedPdfPageHeight = pageHeight / aspectRatio;
      setPageHeightDifference(calculatedPdfPageHeight);
    }
    setNumPages(pdf.numPages);
  };

  const urlParams = new URLSearchParams(window.location.search);
  const fileUrl =
    urlParams.get('fileUrl') ??
    'https://agoy-assets20210223002211654800000002.s3.eu-west-1.amazonaws.com/test-pdfs/Jabra+Evolve2+75_User+Manual_EN_English_RevC.pdf';
  return (
    <PdfPreview
      paddingBottom={pageHeightDifference}
      file={fileUrl}
      onLoadSuccess={onPDFLoaded}
      onLoadError={onPDFLoaded}
    >
      {Array.from(new Array(numPages || 0), (el, index) => {
        return (
          <Page
            width={784}
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            onRenderSuccess={onLoaded}
            onRenderError={onLoaded}
            renderAnnotationLayer={false}
            renderForms={false}
            renderTextLayer={false}
          />
        );
      })}
    </PdfPreview>
  );
};

export default TestPdf;

import React from 'react';
import Typography from '../../../../UI/Typography/Typography';
import { stringValue, Field } from '@agoy/document';

interface AccountantProps {
  accountant: Field;
}

const Accountant = ({ accountant }: AccountantProps): JSX.Element | null => {
  const value = stringValue(accountant) || '';

  return value && accountant.active ? <Typography>{value}</Typography> : null;
};

export default Accountant;

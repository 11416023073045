import React from 'react';
import styled from '@emotion/styled';
import { Dialog, IconButton, Typography } from '@material-ui/core';
import { AreYouSure } from '_shared/components/Icons';
import CloseIcon from '@material-ui/icons/Close';
import { AnnualReportShouldBeUpgraded } from '_annual-report/types';
import { useIntl } from 'react-intl';
import Button from '../Buttons/Button';

const DangerButton = styled.div`
  margin-top: ${({ theme }) => theme.spacing(4)}px;
`;

const StyledDialog = styled(Dialog)`
  text-align: center;
  .MuiDialog-paper {
    padding: ${({ theme }) => theme.spacing(4)}px;
    width: 390px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }
`;

const CloseButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
`;

type ResetContentDialogProps = {
  what: string;
  annualReportShouldBeUpgraded?: AnnualReportShouldBeUpgraded;
  onClose: () => void;
  onConfirm: () => void;
  open: boolean;
};

const ResetContentDialog = ({
  what,
  annualReportShouldBeUpgraded = 'reset',
  open,
  onClose,
  onConfirm,
}: ResetContentDialogProps) => {
  const { formatMessage } = useIntl();
  return (
    <StyledDialog open={open} onClose={onClose}>
      <CloseButton onClick={onClose}>
        <CloseIcon fontSize="large" />
      </CloseButton>
      <AreYouSure />
      <Typography variant="h4">
        {formatMessage({
          id: `annualReport.reset.dialog.title.${annualReportShouldBeUpgraded}`,
        })}
      </Typography>
      <Typography color="textSecondary">
        {formatMessage(
          {
            id: `annualReport.reset.dialog.text.${annualReportShouldBeUpgraded}`,
          },
          { what }
        )}
      </Typography>
      <DangerButton>
        <Button
          label={formatMessage({
            id: `annualReport.reset.button.${annualReportShouldBeUpgraded}`,
          })}
          onClick={onConfirm}
          color="danger"
        />
      </DangerButton>
    </StyledDialog>
  );
};

export default ResetContentDialog;

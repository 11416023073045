import {
  ReferenceCell,
  ref,
  value,
  refs,
  or,
  id,
  sum,
  account,
  multiply,
} from '@agoy/document';

import { AnnualReportType } from '../../../document';
import { table } from '../../../common/utils/util';
import { getWarningCell } from '../../settings/settings';

const addManagementReportSumCell = (baseId: string): ReferenceCell =>
  ref(
    or(
      sum(
        or(id(`${baseId}.yearResult`), 0),
        or(id(`${baseId}.otherUnrestrictedCapital`), 0),
        or(id(`${baseId}.@*`), 0)
      ),
      0
    )
  );

/**
 * The definition of the table columns change depending on the report type
 * The mapping of the columns in each row is affected by this
 */
export const v1ChangesInEquityManualSubmission = (
  reportType: AnnualReportType,
  transitionK3toK2: boolean
) => {
  return table(
    'managementReport.changesInEquity.table',
    { id: 'label', label: '' },
    'note',
    'shareCapital',
    'otherRestrictedCapital',
    'otherUnrestrictedCapital',
    'yearResult',
    'totalUnrestrictedEquity',
    'yearResultWarning'
  )
    .newColumnTemplate((column) =>
      column
        .setType('number')
        .addCell('managementReport.changesInEquity.table.main.sumByYearEnd', {
          type: 'ref',
          reference: or(
            sum(
              id(
                `managementReport.changesInEquity.table.main.content.*.$columnId`
              ),
              transitionK3toK2
                ? id(
                    `managementReport.changesInEquity.table.transitionToK2.*.$columnId`
                  )
                : 0
            ),
            0
          ),
          value: 0,
        })
        .build()
    )
    .addRows((rows) =>
      rows
        // Rows for transition to K2
        .addRow('transitionToK2')
        .addSubRows((rows) =>
          rows
            .addRowActive(false)
            .addRow(
              'amountPrevYear',
              value(
                'Belopp vid jämförelseårets ingång enligt fastställd balansräkning'
              ),
              refs(),
              value(0),
              value(0),
              value(0),
              value(0),
              addManagementReportSumCell(
                'managementReport.changesInEquity.table.transitionToK2.amountPrevYear'
              )
            )
            .addRow(
              'adjustmentsBFNAR',
              value('Justeringar vid övergång till BFNAR 2012:1 (K2)'),
              refs(),
              value(0),
              value(0),
              value(0),
              value(0),
              addManagementReportSumCell(
                'managementReport.changesInEquity.table.transitionToK2.adjustmentsBFNAR'
              )
            )
            .addRow(
              '3',
              value('Egenupparbetad immateriell anläggningstillgång'),
              refs(),
              value(0),
              value(0),
              value(0),
              value(0),
              addManagementReportSumCell(
                'managementReport.changesInEquity.table.transitionToK2.3'
              )
            )
            .addRow(
              '4',
              value('Eget kapital föregående år'),
              refs(),
              value(0),
              value(0),
              value(0),
              value(0),
              addManagementReportSumCell(
                'managementReport.changesInEquity.table.transitionToK2.4'
              )
            )
            .addRow(
              '5',
              value(
                'Finansiell anläggningstillgång värderad till verkligt värde'
              ),
              refs(),
              value(0),
              value(0),
              value(0),
              value(0),
              addManagementReportSumCell(
                'managementReport.changesInEquity.table.transitionToK2.5'
              )
            )
            .addRow(
              '6',
              value('Justeringar vid övergång till BFNAR 2016:10 (K2)'),
              refs(),
              value(0),
              value(0),
              value(0),
              value(0),
              addManagementReportSumCell(
                'managementReport.changesInEquity.table.transitionToK2.6'
              )
            )
            .addRow(
              '7',
              value('Leasingavtal'),
              refs(),
              value(0),
              value(0),
              value(0),
              value(0),
              addManagementReportSumCell(
                'managementReport.changesInEquity.table.transitionToK2.7'
              )
            )
            .addRow(
              '8',
              value('Pensionsavsättningar'),
              refs(),
              value(0),
              value(0),
              value(0),
              value(0),
              addManagementReportSumCell(
                'managementReport.changesInEquity.table.transitionToK2.8'
              )
            )
            .addRow(
              '9',
              value('Uppskjuten skattefordran'),
              refs(),
              value(0),
              value(0),
              value(0),
              value(0),
              addManagementReportSumCell(
                'managementReport.changesInEquity.table.transitionToK2.9'
              )
            )
            .addRow(
              '10',
              value('Uppskjuten skatteskuld'),
              refs(),
              value(0),
              value(0),
              value(0),
              value(0),
              addManagementReportSumCell(
                'managementReport.changesInEquity.table.transitionToK2.10'
              )
            )
            .addRow(
              '11',
              value('Uppskriven anläggningstillgång'),
              refs(),
              value(0),
              value(0),
              value(0),
              value(0),
              addManagementReportSumCell(
                'managementReport.changesInEquity.table.transitionToK2.11'
              )
            )
            .addRow(
              '12',
              value(
                'Finansiella instrument värderade till verkligt värde enligt 4 kap. 14 a § årsredovisningslagen (1995:1554)'
              ),
              refs(),
              value(0),
              value(0),
              value(0),
              value(0),
              addManagementReportSumCell(
                'managementReport.changesInEquity.table.transitionToK2.12'
              )
            )
            .build()
        )
        .newRowTemplate(
          value(''),
          refs(),
          value(0),
          value(0),
          value(0),
          value(0),
          addManagementReportSumCell('$id')
        )

        // These rows are displayed for both K2 and K3 report types
        .addRow('main')
        .addSubRows((rows) =>
          rows
            .addRow('content')
            .addSubRows((rows) =>
              rows
                .addRowManagementReport({
                  id: 'incomingAmount',
                  cells: [
                    value('Belopp vid årets ingång'),
                    refs(),
                    ref(multiply(-1, account('2081', 'ib'))),
                    ref(or(multiply(-1, account('2082:2089', 'ib')), 0)),
                    ref(or(multiply(-1, account('2090:2098', 'ib')), 0)),
                    ref(or(multiply(-1, account('2099', 'ib')), 0)),
                    addManagementReportSumCell(
                      'managementReport.changesInEquity.table.main.content.incomingAmount'
                    ),
                    getWarningCell(
                      `${rows.getBaseId()}.incomingAmount.yearResult`
                    ),
                  ],
                  isRowActive: true,
                })
                .addRowManagementReport({
                  id: 'fundEmission',
                  cells: [
                    value('Fondemission'),
                    refs(),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    addManagementReportSumCell(
                      'managementReport.changesInEquity.table.main.content.fundEmission'
                    ),
                  ],
                  isRowActive: false,
                })
                .addRowManagementReport({
                  id: 'newSplit',
                  cells: [
                    value('Nyemission'),
                    refs(),
                    ref(or(multiply(-1, account('2087', 'change')), 0)),
                    value(0),
                    value(0),
                    value(0),
                    addManagementReportSumCell(
                      'managementReport.changesInEquity.table.main.content.newSplit'
                    ),
                  ],
                  isRowActive: false,
                })
                .addRowManagementReport({
                  id: 'shareholderContributions',
                  cells: [
                    value('Erhållna aktieägartillskott'),
                    refs(),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    addManagementReportSumCell(
                      'managementReport.changesInEquity.table.main.content.shareholderContributions'
                    ),
                  ],
                  isRowActive: false,
                })
                .addRowManagementReport({
                  id: 'transferAdjusterResult',
                  cells: [
                    value('Omföring av ovan justerat resultat'),
                    refs(),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    addManagementReportSumCell(
                      'managementReport.changesInEquity.table.main.content.transferAdjusterResult'
                    ),
                  ],
                  isRowActive: false,
                })
                .addRowManagementReport({
                  id: 'despositionOfResults',
                  cells: [
                    value('Resultatdisposition enl. beslut av årsstämma'),
                    refs(),
                    value(0),
                    value(0),
                    ref(
                      or(
                        id(
                          'managementReport.changesInEquity.table.main.content.incomingAmount.yearResult'
                        ),
                        0
                      )
                    ),
                    ref(
                      or(
                        multiply(
                          -1,
                          id(
                            'managementReport.changesInEquity.table.main.content.incomingAmount.yearResult'
                          )
                        ),
                        0
                      )
                    ),
                    addManagementReportSumCell(
                      'managementReport.changesInEquity.table.main.content.despositionOfResults'
                    ),
                  ],
                  isRowActive: true,
                })
                .addRowManagementReport({
                  id: 'depositionReservFund',
                  cells: [
                    value('Avsättning till reservfond'),
                    refs(),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    addManagementReportSumCell(
                      'managementReport.changesInEquity.table.main.content.depositionReservFund'
                    ),
                  ],
                  isRowActive: false,
                })
                .addRowManagementReport({
                  id: 'dividendToShareHolders',
                  cells: [
                    value('Utdelning till aktieägare'),
                    refs(),
                    value(0),
                    value(0),
                    ref(
                      or(
                        // id(
                        //   'managementReport.resultsDisposition.selectedDividend' // Should get this from last year somehow
                        // ),
                        id('payedDividend')
                      )
                    ),
                    value(0),
                    addManagementReportSumCell(
                      'managementReport.changesInEquity.table.main.content.dividendToShareHolders'
                    ),
                  ],
                  isRowActive: true,
                })
                .addRowManagementReport({
                  id: 'refundShareholders',
                  cells: [
                    value('Återbetalning aktieägartillskott'),
                    refs(),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    addManagementReportSumCell(
                      'managementReport.changesInEquity.table.main.content.refundShareholders'
                    ),
                  ],
                  isRowActive: false,
                })

                .addRowManagementReport({
                  id: 'yearResult',
                  cells: [
                    value('Årets resultat'),
                    refs(),
                    value(0),
                    value(0),
                    value(0),
                    ref(multiply(-1, account('2099'))),
                    addManagementReportSumCell(
                      'managementReport.changesInEquity.table.main.content.yearResult'
                    ),
                    getWarningCell(
                      `${rows.getBaseId()}.yearResult.balanseratResultat`
                    ),
                    getWarningCell(`${rows.getBaseId()}.yearResult.yearResult`),
                  ],
                  isRowActive: true,
                })
                .addRowManagementReport({
                  id: 'shareFund',
                  cells: [
                    value('Överkursfond'),
                    refs(),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    addManagementReportSumCell(
                      'managementReport.changesInEquity.table.main.k3.shareFund'
                    ),
                  ],
                  isRowActive: false,
                })
                .addRowManagementReport(
                  reportType === 'k3'
                    ? {
                        id: 'developmentFund',
                        cells: [
                          value('Fond för utveckling'),
                          refs(),
                          value(0),
                          value(0),
                          value(0),
                          value(0),
                          addManagementReportSumCell(
                            'managementReport.changesInEquity.table.main.k3.developmentFund'
                          ),
                        ],
                        isRowActive: false,
                      }
                    : undefined
                )
                .build()
            )
            .newRowTemplate(
              value(''),
              refs(),
              value(0),
              value(0),
              value(0),
              value(0),
              addManagementReportSumCell('$id')
            )
            .addRow(
              'sumByYearEnd',
              value('Belopp vid årets utgång'),
              refs(),
              ref(
                sum(
                  id(
                    `managementReport.changesInEquity.table.main.content.*.shareCapital`
                  ),
                  transitionK3toK2
                    ? id(
                        `managementReport.changesInEquity.table.transitionToK2.*.shareCapital`
                      )
                    : 0
                )
              ),
              ref(
                sum(
                  id(
                    `managementReport.changesInEquity.table.main.content.*.otherRestrictedCapital`
                  ),
                  transitionK3toK2
                    ? id(
                        `managementReport.changesInEquity.table.transitionToK2.*.otherRestrictedCapital`
                      )
                    : 0
                )
              ),
              ref(
                sum(
                  id(
                    `managementReport.changesInEquity.table.main.content.*.otherUnrestrictedCapital`
                  ),
                  transitionK3toK2
                    ? id(
                        `managementReport.changesInEquity.table.transitionToK2.*.otherUnrestrictedCapital`
                      )
                    : 0
                )
              ),
              ref(
                sum(
                  id(
                    `managementReport.changesInEquity.table.main.content.*.yearResult`
                  ),
                  transitionK3toK2
                    ? id(
                        `managementReport.changesInEquity.table.transitionToK2.*.yearResult`
                      )
                    : 0
                )
              ),
              addManagementReportSumCell(`${rows.getBaseId()}.sumByYearEnd`)
            )
            .newRowTemplate(
              value(''),
              refs(),
              value(0),
              value(0),
              value(0),
              value(0),
              addManagementReportSumCell('$id')
            )
            .build()
        )
        .newRowTemplate(
          value(''),
          refs(),
          value(0),
          value(0),
          value(0),
          value(0),
          value(0)
        )
        .build()
    )
    .newRowTemplate(
      value(''),
      refs(),
      value(0),
      value(0),
      value(0),
      value(0),
      value(0)
    )
    .build();
};

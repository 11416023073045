/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import ChosenDepositionToAccrualFundRow from './ChosenDepositionToAccrualFundRow';
import ChosenReversalOfAccrualFundRow from './ChosenReversalOfAccrualFundRow';
import MaxPossibleDepositionToAccrualFundLabelRow from './MaxPossibleDepositionToAccrualFundLabelRow';
import IncreaseOfCompanyTaxFromAccrualFundsRow from './IncreaseOfCompanyTaxFromAccrualFundsRow';
import TaxTableRow, { TaxTableRowProps } from './TaxTableRow';

const SelectRowType = (props: TaxTableRowProps): JSX.Element => {
  const { row } = props;
  if (row.id === 'increaseOfCompanyTaxFromAccrualFunds') {
    return <IncreaseOfCompanyTaxFromAccrualFundsRow {...props} />;
  }
  if (row.id === 'maxPossibleDepositionToAccrualFundLabel') {
    return <MaxPossibleDepositionToAccrualFundLabelRow {...props} />;
  }
  if (row.id === 'chosenReversalOfAccrualFund') {
    return <ChosenReversalOfAccrualFundRow {...props} />;
  }
  if (row.id === 'chosenDepositionToAccrualFund') {
    return <ChosenDepositionToAccrualFundRow {...props} />;
  }

  return <TaxTableRow {...props} />;
};

export default SelectRowType;

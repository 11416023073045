import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { sub, format, addDays, isAfter, isBefore } from 'date-fns';
import styled from '@emotion/styled';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import AdapterDateFns from '@date-io/date-fns';
import MuiAutocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import { ActivityLog } from '@agoy/activity-log';
import { GetComponentProps } from '_shared/services/type-helpers';
import { useDispatch } from 'react-redux';
import { addGlobalErrorMessage } from 'redux/actions';
import { parse } from '@agoy/dates';
import Button from '_shared/components/Buttons/Button';
import { FilterElementType } from '.';

const Element = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Autocomplete = styled(
  (props: GetComponentProps<typeof MuiAutocomplete>) => (
    <MuiAutocomplete {...props} />
  )
)`
  .MuiInputBase-root {
    height: 44px;
  }
  .MuiOutlinedInput-root {
    padding: ${({ theme }) => theme.spacing(1 / 2)}px;
  }
`;

type SelectedFilterTypes = {
  fromDate?: string | null;
  toDate?: string | null;
  user: FilterElementType | null;
  program: FilterElementType | null;
  section: FilterElementType | null;
};

type Props = {
  users: FilterElementType[];
  onSearchClick: (filters) => void;
};

const Filters = ({ users, onSearchClick }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const [selectedFilters, setSelectedFilters] = useState<SelectedFilterTypes>({
    fromDate: format(sub(new Date(), { months: 1 }), 'yyyy-MM-dd'),
    toDate: format(addDays(new Date(), 1), 'yyyy-MM-dd'),
    user: null,
    program: null,
    section: null,
  });

  // clean up the filter handlers
  const handleSearchClick = (filters: SelectedFilterTypes) => {
    const { user, ...filtersWithoutUser } = filters;
    onSearchClick({
      ...filtersWithoutUser,
      userId: user?.id || null,
      program: filters?.program?.id || null,
      section: filters?.section?.id || null,
    });
  };

  return (
    <>
      {/* FROM DATE */}
      <Element>
        <div>{formatMessage({ id: 'info.activity.from' })}</div>
        <MuiPickersUtilsProvider utils={AdapterDateFns}>
          <KeyboardDatePicker
            autoOk
            disableToolbar
            emptyLabel=""
            variant="inline"
            inputVariant="outlined"
            format="yyyy-MM-dd"
            margin="none"
            id="date-picker-inline-from"
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            value={selectedFilters.fromDate}
            invalidDateMessage={formatMessage({
              id: 'info.activity.error.date',
            })}
            onChange={(_, newValue) => {
              if (isAfter(parse(newValue), parse(selectedFilters.toDate))) {
                const message = formatMessage({
                  id: 'info.activity.error.date',
                });
                dispatch(addGlobalErrorMessage(message));

                return;
              }

              setSelectedFilters({
                ...selectedFilters,
                fromDate: newValue,
              });
            }}
            InputProps={{
              style: {
                padding: 9,
                height: 44,
              },
            }}
            style={{
              maxHeight: 44,
              minWidth: 150,
            }}
          />
        </MuiPickersUtilsProvider>
      </Element>
      {/* TO DATE */}
      <Element>
        <div>{formatMessage({ id: 'info.activity.to' })}</div>
        <MuiPickersUtilsProvider utils={AdapterDateFns}>
          <KeyboardDatePicker
            autoOk
            disableToolbar
            emptyLabel=""
            variant="inline"
            inputVariant="outlined"
            format="yyyy-MM-dd"
            margin="none"
            id="date-picker-inline-from"
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            value={selectedFilters.toDate}
            invalidDateMessage={formatMessage({
              id: 'info.activity.error.date',
            })}
            onChange={(_, value) => {
              if (isBefore(parse(value), parse(selectedFilters.fromDate))) {
                const message = formatMessage({
                  id: 'info.activity.error.date',
                });
                dispatch(addGlobalErrorMessage(message));

                return;
              }

              setSelectedFilters({
                ...selectedFilters,
                toDate: value,
              });
            }}
            InputProps={{
              style: {
                padding: 9,
                height: 44,
              },
            }}
            style={{
              maxHeight: 44,
              minWidth: 150,
            }}
          />
        </MuiPickersUtilsProvider>
      </Element>
      {/* USER */}
      <Element>
        <div>{formatMessage({ id: 'info.activity.user' })}</div>
        <Autocomplete
          blurOnSelect
          onChange={(e, option) => {
            setSelectedFilters({
              ...selectedFilters,
              user: option as FilterElementType,
            });
          }}
          // @ts-ignore @ts-nocheck
          getOptionSelected={(
            option: FilterElementType,
            value: FilterElementType
          ) => option.id === value.id}
          options={users}
          getOptionLabel={(option) => (option as FilterElementType).label}
          style={{ minWidth: 200 }}
          renderInput={(params) => <TextField {...params} variant="outlined" />}
        />
      </Element>
      {/* PROGRAM */}
      <Element>
        <div>{formatMessage({ id: 'info.activity.program' })}</div>
        <Autocomplete
          blurOnSelect
          value={selectedFilters.program}
          onChange={(e, option) => {
            setSelectedFilters({
              ...selectedFilters,
              program: option as FilterElementType,
              section: null, // reset section on program change
            });
          }}
          // @ts-ignore @ts-nocheck
          getOptionSelected={(
            option: FilterElementType,
            value: FilterElementType
          ) => option.id === value.id}
          options={ActivityLog.getAvailablePrograms()}
          getOptionLabel={(option) => (option as FilterElementType).label}
          style={{ minWidth: 200 }}
          renderInput={(params) => <TextField {...params} variant="outlined" />}
        />
      </Element>
      {/* SECTION */}
      <Element>
        <div>{formatMessage({ id: 'info.activity.section' })}</div>
        <Autocomplete
          blurOnSelect
          value={selectedFilters.section}
          onChange={(e, option) => {
            setSelectedFilters({
              ...selectedFilters,
              section: option as FilterElementType,
            });
          }}
          // @ts-ignore @ts-nocheck
          getOptionSelected={(
            option: FilterElementType,
            value: FilterElementType
          ) => option.id === value.id}
          options={ActivityLog.getAvailableSections(
            selectedFilters.program?.id as any
          )}
          getOptionLabel={(option) => (option as FilterElementType).label}
          style={{ minWidth: 200 }}
          renderInput={(params) => <TextField {...params} variant="outlined" />}
        />
      </Element>
      {/* SEARCH */}
      <Button
        label={formatMessage({ id: 'info.activity.table.load' })}
        onClick={() => handleSearchClick(selectedFilters)}
      />
    </>
  );
};

export default Filters;

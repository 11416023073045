import React, { useEffect } from 'react';
import useClientDataLayer from 'data/client/useClientDataLayer';
import { useDispatch } from 'react-redux';
import { addGlobalErrorMessage, setAccountingBalances } from 'redux/actions';
import { useSelector } from 'redux/reducers';

/**
 * HOC to listen for updates in accounting balances and pushing them to redux
 *
 * This component handles updating the accounting balances upon a successful import.
 *
 * @param Component Wrapped component
 * @returns
 */
const withAccountingBalancesListener = (Component) => (props) => {
  const dispatch = useDispatch();

  const {
    currentFinancialYearId,
    currentFinancialYear,
    currentYear,
    currentCustomer: currentClient,
  } = useSelector((state) => state.customerView);

  const clientDataLayer = useClientDataLayer(currentClient);

  useEffect(() => {
    if (
      currentClient &&
      currentFinancialYear &&
      currentFinancialYearId &&
      currentYear
    ) {
      const sub = clientDataLayer?.accountingBalances
        .getAccountingBalances(currentFinancialYearId)
        .subscribe(async (result) => {
          if (result.ok) {
            try {
              dispatch(
                setAccountingBalances(
                  currentClient,
                  currentFinancialYear,
                  result.val ? result.val.accountingBalances : null,
                  result.val ? result.val.updatedAt : Date.now()
                )
              );
            } catch (err) {
              dispatch(addGlobalErrorMessage('error'));
            }
          } else {
            dispatch(addGlobalErrorMessage(`error.${result.val}`));
          }
        });

      return () => {
        sub?.unsubscribe();
      };
    }
  }, [
    dispatch,
    currentClient,
    currentFinancialYearId,
    currentFinancialYear,
    currentYear,
    clientDataLayer,
  ]);

  return <Component {...props} />;
};
export default withAccountingBalancesListener;

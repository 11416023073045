import React, { useState, useEffect, useCallback, memo } from 'react';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import {
  ChecklistQuestion,
  ChecklistGroup as ChecklistGroupType,
  Program,
} from '_shared/redux/checklist/types';
import { DraggableItemTypes } from 'contants';
import ChecklistGroup from './ChecklistGroup';
import AddNewItem from './AddNewItem';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 966px;
`;

const AddGroupWrapper = styled.div`
  background-color: #f2f4f3;
  border-radius: 8px;
`;

interface ChecklistTreeProps {
  groups: ChecklistGroupType[];
  editing?: boolean;
  showAccountFilters?: boolean;
  program: Program;
  onChange?: (groups: ChecklistGroupType[]) => void;
}

interface ChecklistTreeContentProps {
  groups: ChecklistGroupType[];
  editing?: boolean;
  showAccountFilters?: boolean;
  program: Program;
  onChange: (
    updateGroup: (currentGroups: ChecklistGroupType[]) => ChecklistGroupType[]
  ) => void;
}

const ChecklistTreeContent = memo(
  ({
    groups,
    editing = false,
    showAccountFilters = false,
    program,
    onChange,
  }: ChecklistTreeContentProps) => {
    const { formatMessage } = useIntl();

    const onAddItem = useCallback(
      (groupIndex: number) => {
        onChange((currentGroups) =>
          currentGroups.map((group, index) => {
            if (index === groupIndex) {
              const newItem: ChecklistQuestion = {
                id: new Date().valueOf(),
                label: '',
                periodicity: group.periodicity,
              };

              if (group.fromAccount) {
                newItem.fromAccount = group.fromAccount;
              }

              if (group.toAccount) {
                newItem.toAccount = group.toAccount;
              }

              return {
                ...group,
                questions: [...group.questions, newItem],
              };
            }

            return group;
          })
        );
      },
      [onChange]
    );

    const onAddGroup = useCallback(() => {
      onChange((currentGroups) => {
        const updatedGroups = [...currentGroups];

        updatedGroups.push({
          id: new Date().valueOf(),
          label: '',
          periodicity:
            program === 'tax_declaration_person'
              ? 'last_period'
              : 'every_period',
          questions: [],
        });

        return updatedGroups;
      });
    }, [onChange, program]);

    const onDeleteItem = useCallback(
      (deletedItem: ChecklistQuestion, groupIndex: number) => {
        onChange((currentGroups) => {
          const updatedGroups = [...currentGroups];
          const group = updatedGroups[groupIndex];

          updatedGroups[groupIndex] = {
            ...group,
            questions: group.questions.filter(
              (item) => item.id !== deletedItem.id
            ),
          };

          return updatedGroups;
        });
      },
      [onChange]
    );

    const onDeleteGroup = useCallback(
      (groupIndex: number) => {
        onChange((currentGroups) => {
          const updatedGroups = [...currentGroups];

          updatedGroups.splice(groupIndex, 1);

          return updatedGroups;
        });
      },
      [onChange]
    );

    const onChangeItem = useCallback(
      (changedItem: ChecklistQuestion, groupIndex: number) => {
        onChange((currentGroups) =>
          currentGroups.map((group, index) => {
            if (index === groupIndex) {
              return {
                ...group,
                questions: group.questions.map((item) => {
                  if (item.id === changedItem.id) {
                    return changedItem;
                  }
                  return item;
                }),
              };
            }

            return group;
          })
        );
      },
      [onChange]
    );

    const onChangeGroup = useCallback(
      (changedGroup: ChecklistGroupType, groupIndex: number) => {
        onChange((currentGroups) => {
          const updatedGroups = [...currentGroups];

          updatedGroups.splice(groupIndex, 1, changedGroup);

          return updatedGroups;
        });
      },
      [onChange]
    );

    const onMoveGroup = useCallback(
      (result) => {
        if (!result.destination) {
          return;
        }

        const currentGroupIndex = result.source.index;
        const newGroupIndex = result.destination.index;

        if (currentGroupIndex !== newGroupIndex) {
          onChange((currentGroups) => {
            const updatedGroups = [...currentGroups];

            const group = updatedGroups.splice(currentGroupIndex, 1)[0];
            updatedGroups.splice(newGroupIndex, 0, group);

            return updatedGroups;
          });
        }
      },
      [onChange]
    );

    const onMoveQuestion = useCallback(
      (result) => {
        const currentGroupId = +result.source.droppableId;
        const currentItemIndex = result.source.index;

        if (!result.destination) {
          return;
        }

        const newGroupId = +result.destination.droppableId;
        const newItemIndex = result.destination.index;

        let currentGroupIndex = 0;
        let newGroupIndex = 0;

        onChange((currentGroups) => {
          const updatedGroups = [...currentGroups];

          updatedGroups.forEach((group, index) => {
            if (group.id === currentGroupId) {
              currentGroupIndex = index;
            }
            if (group.id === newGroupId) {
              newGroupIndex = index;
            }
          });

          if (
            currentGroupIndex === newGroupIndex &&
            currentItemIndex === newItemIndex
          ) {
            return currentGroups;
          }

          if (newGroupIndex !== currentGroupIndex) {
            const currentGroupItems = [
              ...updatedGroups[currentGroupIndex].questions,
            ];
            const newGroupItems = [...updatedGroups[newGroupIndex].questions];

            const draggedItem = currentGroupItems.splice(
              currentItemIndex,
              1
            )[0];

            newGroupItems.splice(newItemIndex, 0, draggedItem);

            updatedGroups[currentGroupIndex].questions = currentGroupItems;
            updatedGroups[newGroupIndex].questions = newGroupItems;
          } else {
            const newGroupItems = [...updatedGroups[newGroupIndex].questions];

            const draggedItem = newGroupItems.splice(currentItemIndex, 1)[0];
            newGroupItems.splice(newItemIndex, 0, draggedItem);

            updatedGroups[newGroupIndex].questions = newGroupItems;
          }

          return updatedGroups;
        });
      },
      [onChange]
    );

    const onDragEnd = useCallback(
      (result) => {
        if (result.type === DraggableItemTypes.CHECKLIST_GROUP) {
          onMoveGroup(result);
        }

        if (result.type === DraggableItemTypes.CHECKLIST_ITEM) {
          onMoveQuestion(result);
        }
      },
      [onMoveGroup, onMoveQuestion]
    );

    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Container>
          <Droppable
            droppableId="droppable"
            type={DraggableItemTypes.CHECKLIST_GROUP}
          >
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {groups.map((group, groupIndex) => (
                  <Draggable
                    key={group.id}
                    draggableId={`${group.id}`}
                    index={groupIndex}
                    isDragDisabled={!editing}
                  >
                    {(providedGroup, snapshotGroup) => (
                      <ChecklistGroup
                        group={groups[groupIndex]}
                        groupIndex={groupIndex}
                        editing={editing}
                        showAccountFilters={showAccountFilters}
                        program={program}
                        provided={providedGroup}
                        isDragging={snapshotGroup.isDragging}
                        onAddItem={onAddItem}
                        onDeleteItem={onDeleteItem}
                        onChangeItem={onChangeItem}
                        onDeleteGroup={onDeleteGroup}
                        onChangeGroup={onChangeGroup}
                      />
                    )}
                  </Draggable>
                ))}

                {provided.placeholder}

                {editing && (
                  <AddGroupWrapper>
                    <AddNewItem
                      title={formatMessage({ id: 'checklist.create.newGroup' })}
                      onClick={onAddGroup}
                    />
                  </AddGroupWrapper>
                )}
              </div>
            )}
          </Droppable>
        </Container>
      </DragDropContext>
    );
  }
);

const ChecklistTree = ({
  groups,
  editing = false,
  showAccountFilters = false,
  program,
  onChange = () => {},
}: ChecklistTreeProps) => {
  const [currentGroups, setCurrentGroups] = useState(groups);

  useEffect(() => {
    onChange(currentGroups);
  }, [currentGroups, onChange]);

  useEffect(() => {
    setCurrentGroups(groups);
  }, [groups, onChange]);

  const handleChange = useCallback(
    (updateState: (groups: ChecklistGroupType[]) => ChecklistGroupType[]) => {
      setCurrentGroups(updateState);
    },
    []
  );

  return (
    <ChecklistTreeContent
      groups={currentGroups}
      editing={editing}
      showAccountFilters={showAccountFilters}
      program={program}
      onChange={handleChange}
    />
  );
};

export default memo(ChecklistTree);

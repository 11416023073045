import React, { useCallback, memo, useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { useApiSdk, asResultClass } from 'api-sdk';
import { useSelector } from 'redux/reducers';
import { TimePeriod } from '@agoy/document';
import { ChecklistItemStatus, Program } from '_shared/redux/checklist/types';
import ChecklistDrawer from '_shared/components/Checklist';
import {
  assignChecklistToClient,
  fetchChecklistStatuses,
  getChecklistForProgram,
  updateChecklistQuestionStatus,
  toggleIncludeChecklistToReport,
} from '_shared/redux/checklist/actions';
import { currentClientYearChecklist } from '_shared/redux/checklist/selectors';
import { AccountingAccount, ClientCompany, Period } from '@agoy/api-sdk-core';
import useClientDataLayer from 'data/client/useClientDataLayer';
import { firstValueFrom } from 'rxjs';

interface ChecklistDrawerProps {
  clientId: string;
  financialYear: ClientCompany['financialYears'][0];
  program: Program;
  defaultPeriod?: Period;
}

const ClientChecklist = ({
  clientId,
  financialYear,
  program,
  defaultPeriod,
}: ChecklistDrawerProps) => {
  const dispatch = useDispatch();
  const sdk = useApiSdk();
  const clientDataLayer = useClientDataLayer(clientId);

  const [loading, setLoading] = useState(false);
  const [accounts, setAccounts] = useState<AccountingAccount[]>([]);

  const financialYearPeriod = TimePeriod.fromISODates(financialYear).value;

  const { programs, statuses } =
    useSelector(currentClientYearChecklist(clientId, financialYearPeriod)) ||
    {};

  const checklistTemplates = useSelector(
    (state) => state.organisation.checklists
  );

  const expanded = useSelector((state) => state.checklist.expanded);

  const currentChecklist = programs?.[program];

  const fetchChecklistData = useCallback(async () => {
    if (expanded) {
      setLoading(true);
      const result = await firstValueFrom(
        clientDataLayer.accountingBalances.getAccountingBalances(
          financialYearPeriod
        )
      );

      if (result.ok) {
        const accountsList = result.val?.accountingBalances?.accounts || [];
        await dispatch(fetchChecklistStatuses(clientId, financialYearPeriod));
        await dispatch(
          getChecklistForProgram(
            clientId,
            financialYearPeriod,
            program,
            accountsList
          )
        );
        setAccounts(accountsList || []);
      }

      setLoading(false);
    }
  }, [
    expanded,
    clientDataLayer.accountingBalances,
    financialYearPeriod,
    dispatch,
    clientId,
    program,
  ]);

  useEffect(() => {
    fetchChecklistData();
  }, [fetchChecklistData]);

  const newChecklistTemplate = useMemo(
    () =>
      currentChecklist
        ? checklistTemplates.find(
            (template) =>
              template.id !== currentChecklist.id &&
              template.program === currentChecklist.program &&
              template.clientIds.includes(clientId) &&
              !template.hidden
          )
        : undefined,
    [checklistTemplates, currentChecklist]
  );

  const handleChangeChecklist = async () => {
    if (newChecklistTemplate) {
      setLoading(true);
      dispatch(
        await assignChecklistToClient(
          clientId,
          financialYearPeriod,
          accounts,
          newChecklistTemplate
        )
      );
      setLoading(false);
    }
  };

  const handleChangeStatus = (
    questionId: number,
    status: ChecklistItemStatus,
    selectedPeriod?: Period
  ) => {
    if (financialYearPeriod && clientId && selectedPeriod) {
      dispatch(
        updateChecklistQuestionStatus(
          clientId,
          financialYearPeriod,
          selectedPeriod.id,
          questionId,
          status
        )
      );
    }
  };

  const handleToggleInclude = async () => {
    if (financialYearPeriod && clientId && currentChecklist) {
      await asResultClass(
        sdk.setClientChecklistPrintState({
          clientid: clientId,
          templateId: currentChecklist.id!,
          financialYearId: financialYear.id,
          requestBody: {
            includeInPrint: !currentChecklist.includeInPrint,
          },
        })
      );
      dispatch(
        toggleIncludeChecklistToReport(clientId, financialYearPeriod, program)
      );
    }
  };

  return (
    <ChecklistDrawer
      expanded={expanded}
      statuses={statuses}
      checklist={currentChecklist}
      periods={financialYear.periods || []}
      loading={loading}
      defaultPeriod={defaultPeriod}
      showResetButton={!!newChecklistTemplate}
      onChangeStatus={handleChangeStatus}
      onChangeChecklist={handleChangeChecklist}
      onToggleInclude={handleToggleInclude}
    />
  );
};

export default memo(ClientChecklist);

import { Dispatch } from 'redux';

import { Cell } from '@agoy/document';
import { isBokslutReportPartKey } from '@agoy/financial-report-document';
import {
  resetFinancialReportContent,
  updateFinancialReportCellValue,
  updateFinancialReportField,
  toggleFinancialReportFieldActive,
  toggleFinancialReportSectionActive,
  toggleFinancialReportIncomeStatementSectionActive,
  toggleFinancialReportBalanceSheetSectionActive,
  toggleFinancialReportBalanceSheetAssetsSectionActive,
  toggleFinancialReportSettingsParameter,
  setFinancialReportDocuments,
  toggleAdditionalTextActive,
} from '_financial-report/redux/actions';
import { Document } from '_financial-report/redux/reducer/types';
import FinancialReportViewService from './FinancialReportViewService';

class FinancialReportReduxViewService implements FinancialReportViewService {
  private clientId: string;

  private period: string;

  private dispatch: Dispatch<any>;

  constructor(clientId: string, period: string, dispatch: Dispatch<any>) {
    this.clientId = clientId;
    this.period = period;
    this.dispatch = dispatch;
  }

  async updateCellValue(
    cellId: string,
    value: string | number | Cell | undefined
  ): Promise<void> {
    await this.dispatch(
      updateFinancialReportCellValue(this.clientId, this.period, cellId, value)
    );
  }

  async updateField(
    fieldId: string,
    value: string | number | undefined
  ): Promise<void> {
    await this.dispatch(
      updateFinancialReportField(this.clientId, this.period, fieldId, value)
    );
  }

  async resetContent(id: string): Promise<void> {
    await this.dispatch(
      resetFinancialReportContent(this.clientId, this.period, id)
    );
  }

  async toggleFieldActive(fieldId: string): Promise<void> {
    const [part, section, fieldName] = fieldId.split('.');
    if (!isBokslutReportPartKey(part)) {
      throw new Error(`Unknown part ${part}`);
    }
    await this.dispatch(
      toggleFinancialReportFieldActive(
        this.clientId,
        this.period,
        part,
        section,
        fieldName
      )
    );
  }

  async toggleSettingsParameter(id: string): Promise<void> {
    await this.dispatch(
      toggleFinancialReportSettingsParameter(this.clientId, this.period, id)
    );
  }

  async toggleSectionActive(part: string): Promise<void> {
    if (!isBokslutReportPartKey(part)) {
      throw new Error(`Unknown part ${part}`);
    }
    await this.dispatch(
      toggleFinancialReportSectionActive(this.clientId, this.period, part)
    );
  }

  async toggleIncomeStatementSectionActive(section: string): Promise<void> {
    await this.dispatch(
      toggleFinancialReportIncomeStatementSectionActive(
        this.clientId,
        this.period,
        section
      )
    );
  }

  async toggleBalanceSheetSectionActive(section: string): Promise<void> {
    await this.dispatch(
      toggleFinancialReportBalanceSheetSectionActive(
        this.clientId,
        this.period,
        section
      )
    );
  }

  async toggleBalanceSheetAssetsSectionActive(section: string): Promise<void> {
    await this.dispatch(
      toggleFinancialReportBalanceSheetAssetsSectionActive(
        this.clientId,
        this.period,
        section
      )
    );
  }

  async addDocument(documents: Document[], document: Document): Promise<void> {
    const updatedDocuments = [document, ...documents];
    await this.dispatch(
      setFinancialReportDocuments(this.clientId, this.period, updatedDocuments)
    );
  }

  async deleteDocument(
    documents: Document[],
    documentName: string
  ): Promise<void> {
    const updatedDocuments = documents.filter(
      (item) => item.name !== documentName
    );
    await this.dispatch(
      setFinancialReportDocuments(this.clientId, this.period, updatedDocuments)
    );
  }

  async editDocumentName(
    documents: Document[],
    oldName: string,
    newName: string
  ): Promise<void> {
    const updatedDocuments = documents.map((item) => {
      if (item.name === oldName) {
        return {
          ...item,
          name: newName,
        };
      }
      return item;
    });
    await this.dispatch(
      setFinancialReportDocuments(this.clientId, this.period, updatedDocuments)
    );
  }

  async toggleAdditionalTextActive(section: string): Promise<void> {
    await this.dispatch(
      toggleAdditionalTextActive(this.clientId, this.period, section)
    );
  }
}

export default FinancialReportReduxViewService;

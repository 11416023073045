import { FinancialReportStructure } from '../../types';

import { account, or, ref, value } from '@agoy/document';
import { startOfMonth, sub } from 'date-fns';
import { format, parse } from '@agoy/dates';

import { field, table } from '@agoy/annual-report-document';
import { getRandomInt } from './util';

const TX1 = {
  1: 'Diagrammet nedan visar en sammanställning av bolagets in- och utbetalningar per månad.',
  2: 'Nedan visar diagrammet månadsvis bolagets flöde av pengar på banken.',
  3: 'De orange staplarna nedan ger en bild av bolagets in- och utbetalningar på banken, månadsvis.',
};

const TX2 = {
  1: 'När det kommer in pengar på banken ökar kassaflödet och när det försvinner pengar på banken minskar kassaflödet.',
  2: 'Kassaflödet minskar när bolaget gör en utbetalning och ökar när det sker en insättning på banken.',
  3: 'Kassaflödet i bolaget ökar när det kommer in pengar på banken och minskar när det försvinner pengar från banken.',
};

const TX3 = {
  1: 'Staplarna i diagrammet visar antingen positivt eller negativt. Positiv stapel betyder att det under månaden kommit in mer pengar på banken än ut från den.',
  2: 'Visar värdet på stapeln positivt säger det att inflödet av pengar har varit större jämfört med flödet ut.',
  3: 'I diagrammet kan staplarna både vara positiva och negativa. Negativ stapel betyder att flödet av pengar ut har varit större än flödet av pengar in.',
};

const TX4 = {
  1: 'Den linjära linjen visar trenden de senaste 12 månaderna.',
  2: 'Trenden de senaste 12 månaderna visas i den linjära linjen.',
  3: 'Den linjära linjen ger en bild av hur trenden varit senaste månaderna.',
};

export const cashFlowDiagramConfig = (
  period: string
): FinancialReportStructure['cashFlowDiagram'] => {
  const textNumber = getRandomInt(3);
  const lastperiod = startOfMonth(parse(period, 'yyyyMMdd'));

  const calcMonth = Array.from(Array(12).keys()).map((item) =>
    sub(lastperiod, { months: item })
  );
  const months = calcMonth.reverse().map((item) => {
    return { period: format(item, 'MMMyy'), label: format(item, 'MMM-yy') };
  });
  return {
    section: {
      active: true,
      title: field('Kassaflödesanalys'),
      secondaryText: field(
        `${TX1[textNumber]} ${TX2[textNumber]} ${TX3[textNumber]} ${TX4[textNumber]}`
      ),
      secondaryTextStatic: field(`${TX1[1]} ${TX2[1]} ${TX3[1]} ${TX4[1]}`),
      charter: field('Kassaflöde'),
      result: table(
        'CashFlowDiagram.section.table.result',
        { id: 'nameLabel', label: 'Kassaflöde' },
        ...months.map((month) => {
          return {
            id: month.period,
            label: month.label,
          };
        })
      )
        .addRows((rows) => {
          const cashFlow = months.map((month) => {
            return ref(or(account('1900:1999', 'change', month.period), 0));
          });
          rows.addRow('cashflow', value('Kassaflöde'), ...cashFlow);
          return rows.build();
        })
        .build(),
    },
  };
};

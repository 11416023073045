import { parse } from 'date-fns';

const skvPeriodsByMonth = [
  'P1',
  'P1',
  'P1',
  'P1',
  'P2',
  'P2',
  'P3',
  'P3',
  'P4',
  'P4',
  'P4',
  'P4',
];

export const financialYearToSKVPeriod = (financialYear: string): string => {
  const [_, end] = financialYear.split('-');
  const endDate = parse(end, 'yyyyMMdd', new Date());
  const month = endDate.getMonth();
  const period = skvPeriodsByMonth[month];

  return `${endDate.getFullYear()}${period}`;
};

import {
  value,
  table,
  SelectFormat,
  ref,
  or,
  sum,
  id,
  account,
} from '@agoy/document';
import { IxbrlCell, NoteOneTableSection } from '../../../../common';
import {
  field,
  ixbrlCell,
  ixbrlField,
  tupleRef,
} from '../../../../common/utils/util';
import { year, previousYear } from '../../../../common/utils/date-util';
import { NoteAccountRanges } from '../types';
import { sumAccountsUBs } from '../../../../common/utils/accountSumsUtil';

const typeOfAssignmentInputFormat: SelectFormat = {
  type: 'select',
  options: [
    {
      value:
        'se-mem-base:RevisorerRevisionsforetagUppdragRevisionsuppdragMember',
      label: 'Revisionsuppdrag',
    },
    {
      value:
        'se-mem-base:RevisorerRevisionsforetagUppdragRevisionsverksamhetUtoverRevisionsuppdragetMember',
      label: 'Revisionsverksamhet utöver revisionsuppdraget',
    },
    {
      value:
        'se-mem-base:RevisorerRevisionsforetagUppdragSkatteradgivningMember',
      label: 'Skatterådgivning',
    },
    {
      value: 'se-mem-base:RevisorerRevisionsforetagOvrigaTjansterMember',
      label: 'Övriga tjänster',
    },
  ],
};

/**
 * TODO: These account ranges are not useful to the frontend, and we
 * need to rethink how to use them in the tooltips
 */
export const note101Accounts: NoteAccountRanges[] = [
  {
    rowId: 'se-mem-base:RevisorerRevisionsforetagUppdragRevisionsuppdragMember',
    ranges: [[6420]],
    cellLabel: 'Revisionsuppdrag',
    action: 'sumAccountsUBs',
  },
  {
    rowId:
      'se-mem-base:RevisorerRevisionsforetagUppdragRevisionsverksamhetUtoverRevisionsuppdragetMember',
    ranges: [[6422]],
    cellLabel: 'Revisionsverksamhet utöver revisionsuppdraget',
    action: 'sumAccountsUBs',
  },
  {
    rowId: 'se-mem-base:RevisorerRevisionsforetagUppdragSkatteradgivningMember',
    ranges: [[6423]],
    cellLabel: 'Skatterådgivning',
    action: 'sumAccountsUBs',
  },
  {
    rowId: 'se-mem-base:RevisorerRevisionsforetagOvrigaTjansterMember',
    ranges: [[6424]],
    cellLabel: 'Övriga tjänster',
    action: 'sumAccountsUBs',
  },
];

const getAccountRange = (selectedIndex: number) => {
  const rowId = typeOfAssignmentInputFormat.options[selectedIndex].value;
  const accountsObj = note101Accounts.find(
    (account) => account.rowId === rowId
  );

  return accountsObj?.ranges[0].toString() || '';
};

export const getNote101 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null,
  documentTypeVersion = '1'
): NoteOneTableSection => {
  return {
    active: false,
    number: value(undefined),
    name: value('Ersättningar till revisorer'),
    table: table<IxbrlCell>(
      'notes.note101.table',
      { id: 'auditorsAndAuditFirms', label: 'Revisor och revisionsföretag' },
      { id: 'typeOfAssignment', label: 'Typ av uppdrag' },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      .addRows((rows) =>
        rows
          .addRow(
            'sum',
            noteLabel('Summa'),
            undefined,
            ixbrlCell(
              ref(
                or(
                  sum(
                    id('notes.note101.table.@*.year'),
                    or(id('notes.note101.table.1.year'), 0)
                  ),
                  0
                )
              ),
              {
                type: 'monetaryItemType',
                name: 'se-gen-base:RevisorerRevisionsforetagErsattning',
                standardRubrik: 'Ersättning till revisor och revisionsföretag',
                saldo: 'debit',
                contextRef: 'period0',
                negateValue: true,
              }
            ),
            ixbrlCell(
              ref(
                or(
                  sum(
                    id('notes.note101.table.@*.previousYear'),
                    or(id('notes.note101.table.1.previousYear'), 0)
                  ),
                  0
                )
              ),
              {
                type: 'monetaryItemType',
                name: 'se-gen-base:RevisorerRevisionsforetagErsattning',
                standardRubrik: 'Ersättning till revisor och revisionsföretag',
                saldo: 'debit',
                contextRef: 'period1',
                negateValue: true,
              }
            )
          )
          .addRow(
            '1',
            ixbrlCell(value(''), {
              type: 'stringItemType',
              name: 'se-gen-base:RevisorerRevisionsforetagNamn',
              contextRef: 'period0',
              standardRubrik:
                'Namn på revisor och revisionsföretag som erhållit ersättning',
              previousYearTuple: true,
              tuple: {
                name: 'se-gaap-ext:ErsattningRevisorerTuple',
                id: `${rows.getBaseId()}.1.tuple`,
                tupleID: `${rows.getBaseId()}.1.tuple`,
              },
              tupleRef: tupleRef(1, `${rows.getBaseId()}.1.tuple`),
            }),
            ixbrlCell(
              {
                type: 'string',
                value: typeOfAssignmentInputFormat.options[0].label,
                format: typeOfAssignmentInputFormat,
              },
              {
                type: 'stringItemType',
                name: 'se-gen-base:RevisorerRevisionsforetagUppdragList',
                contextRef: 'period0',
                standardRubrik:
                  'Typ av uppdrag för revisorer och revisionsföretag (Vallista)',
                tupleRef: tupleRef(2, `${rows.getBaseId()}.1.tuple`),
                previousYearTuple: true,
              }
            ),
            ixbrlCell(ref(sumAccountsUBs(getAccountRange(0), true)), {
              type: 'monetaryItemType',
              name: 'se-gen-base:RevisorerRevisionsforetagErsattningUppdrag',
              standardRubrik:
                'Ersättning till revisor och revisionsföretag per uppdrag',
              saldo: 'debit',
              contextRef: 'period0',
              negateValue: true,
              tupleRef: tupleRef(3, `${rows.getBaseId()}.1.tuple_period0`),
            }),
            ixbrlCell(
              ref(sumAccountsUBs(getAccountRange(0), true, 'previousYear')),
              {
                type: 'monetaryItemType',
                name: 'se-gen-base:RevisorerRevisionsforetagErsattningUppdrag',
                standardRubrik:
                  'Ersättning till revisor och revisionsföretag per uppdrag',
                saldo: 'debit',
                contextRef: 'period1',
                negateValue: true,
                tupleRef: tupleRef(4, `${rows.getBaseId()}.1.tuple_period1`),
              }
            )
          )
          .build()
      )
      .newRowTemplateGenerator((id, baseId) => ({
        id,
        active: true,
        cells: {
          auditorsAndAuditFirms: ixbrlCell(value(''), {
            type: 'stringItemType',
            name: 'se-gen-base:RevisorerRevisionsforetagNamn',
            contextRef: 'period0',
            standardRubrik:
              'Namn på revisor och revisionsföretag som erhållit ersättning',
            previousYearTuple: true,
            tuple: {
              name: 'se-gaap-ext:ErsattningRevisorerTuple',
              id: `${baseId}.${id}.1.tuple`,
              tupleID: `${baseId}.${id}.1.tuple`,
            },
            tupleRef: tupleRef(1, `${baseId}.${id}.1.tuple`),
          }),
          typeOfAssignment: ixbrlCell(
            {
              type: 'string',
              value: typeOfAssignmentInputFormat.options[0].label,
              format: typeOfAssignmentInputFormat,
            },
            {
              type: 'stringItemType',
              name: 'se-gen-base:RevisorerRevisionsforetagUppdragList',
              contextRef: 'period0',
              standardRubrik:
                'Typ av uppdrag för revisorer och revisionsföretag (Vallista)',
              tupleRef: tupleRef(2, `${baseId}.${id}.1.tuple_period0`),
            }
          ),
          year: ixbrlCell(ref(sumAccountsUBs(getAccountRange(0), true)), {
            type: 'monetaryItemType',
            name: 'se-gen-base:RevisorerRevisionsforetagErsattningUppdrag',
            standardRubrik:
              'Ersättning till revisor och revisionsföretag per uppdrag',
            saldo: 'debit',
            contextRef: 'period0',
            negateValue: true,
            tupleRef: tupleRef(3, `${baseId}.${id}.1.tuple_period0`),
          }),
          previousYear: ixbrlCell(
            ref(sumAccountsUBs(getAccountRange(0), true, 'previousYear')),
            {
              type: 'monetaryItemType',
              name: 'se-gen-base:RevisorerRevisionsforetagErsattningUppdrag',
              standardRubrik:
                'Ersättning till revisor och revisionsföretag per uppdrag',
              saldo: 'debit',
              contextRef: 'period1',
              negateValue: true,
              tupleRef: tupleRef(4, `${baseId}.${id}.1.tuple_period1`),
            }
          ),
        },
      }))
      .build(),
    commentLabel: field('Kommentar till not'),
    comment: ixbrlField(field(''), {
      type: 'stringItemType',
      name: 'se-gen-base:NotErsattningRevisorerKommentar',
      contextRef: 'period0',
    }),
    group: value(undefined),
    section: value(undefined),
    validForReportType: {
      k2: false,
      k3: documentTypeVersion !== '1',
    },
  };
};

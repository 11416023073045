import React from 'react';
import { FlaggAdmin } from 'flagg/dist/react';
import { Alert } from '@material-ui/lab';

const Container = () => (
  <>
    <Alert severity="error">Touching this may DELETE your data!</Alert>
    <Alert severity="error">
      Om du trycker på detta kan det leda till att dina data RADERAS!
    </Alert>
    <FlaggAdmin />
  </>
);

export default Container;

import {
  abs,
  account,
  AccountValueType,
  Cell,
  id,
  label,
  multiply,
  or,
  ref,
  ReferenceAccountInformation,
  refs,
  sum,
  value,
} from '@agoy/document';
import { range, RowsBuilder } from './util';

/**
 * How accountRangeRows works:
 * 1. It takes a range of account numbers (first, last) and iterates over them.
 * 2. If the switchSign is true it means we multiply by -1
 * 3. in the account function we get accountNumber as parameter, we can pass also a modifier and a periodName
 *  the modifier can be 'ib', 'ub' etc if NOT specified is 'ub' -> outgoing balance
 * 4. the periodName is the name of the period, if NOT specified is the current financial year
 */
export const getRowsFromAccountRanges = (
  accounts: Record<string, ReferenceAccountInformation>,
  first: number,
  last: number,
  rows: RowsBuilder,
  switchSign: boolean,
  previousPeriod: string,
  modifier?: AccountValueType | undefined,
  isIncomeStatement?: boolean
): RowsBuilder => {
  range(first, last + 1).forEach((n) => {
    const accountNumber = n.toString();
    const accountInformation = accounts[accountNumber];
    const currentYearSum = isIncomeStatement
      ? account(accountNumber, modifier, 'year')
      : account(accountNumber, modifier);

    if (accountInformation) {
      rows
        .addRowWithType(
          accountNumber,
          'account',
          label(`${accountNumber} ${accountInformation.accountName}`),
          undefined,
          ref(
            switchSign
              ? multiply(-1, or(currentYearSum, 0))
              : or(account(accountNumber, modifier), 0)
          ),
          ref(
            switchSign
              ? multiply(
                  -1,
                  or(account(accountNumber, modifier, previousPeriod), 0)
                )
              : or(account(accountNumber, modifier, previousPeriod), 0)
          )
        )
        .setSortKey(n);
    }
  });
  return rows;
};

export const addPost =
  (
    accounts: Record<string, ReferenceAccountInformation>,
    switchSign: boolean,
    rows: RowsBuilder,
    previousPeriod: string
  ) =>
  (
    rowId: string,
    name: string,
    accountNumbers: [number, number][],
    noteRef?: string,
    modifier?: AccountValueType | undefined
  ): RowsBuilder => {
    rows.addRow(
      rowId,
      value(name), // user-editable name for a row
      noteRef ? refs(noteRef) : refs(), // this is where the notes are added
      ref(or(sum(id(`${rows.getBaseId()}.${rowId}.*.year`)), 0)),
      ref(or(sum(id(`${rows.getBaseId()}.${rowId}.*.previousYear`)), 0))
    );

    rows.newRowTemplateGenerator(
      (id, baseId, { label: labelValue, ib, ub }) => ({
        id,
        type: 'account',
        active: true,
        cells: {
          label: label(labelValue),
          year: ub
            ? ref(switchSign ? multiply(-1, account(ub)) : account(ub))
            : value(0),
          previousYear: ib
            ? ref(
                switchSign
                  ? multiply(-1, or(account(ib, undefined, previousPeriod), 0))
                  : or(account(ib, undefined, previousPeriod), 0)
              )
            : value(0),
        },
        sortKey: ib ? parseInt(ib) : parseInt(ub),
      })
    );
    rows.addSubRows((rows) => {
      accountNumbers.forEach(([first, last]) =>
        getRowsFromAccountRanges(
          accounts,
          first,
          last,
          rows,
          switchSign,
          previousPeriod,
          modifier
        )
      );
      return rows.build();
    });
    return rows;
  };

export const addBalanceSheetGroup = (
  rows: RowsBuilder,
  rowId: string,
  name: string,
  subRowBuilder: (rows: RowsBuilder) => void,
  getWarningCell: (text: string) => Cell | undefined,
  sumName?: string,
  noteRef?: string,
  hasHiddenPreviewAndPrint?: boolean,
  useAddRowSum = true
): void => {
  rows
    .addRow(
      rowId,
      name === undefined ? undefined : value(name),
      noteRef ? refs(noteRef) : refs(),
      hasHiddenPreviewAndPrint
        ? ref(
            or(
              sum(
                id(`${rows.getBaseId()}.${rowId}.1_HideInPreviewAndPrint.year`)
              ),
              0
            )
          )
        : undefined,
      hasHiddenPreviewAndPrint
        ? ref(
            or(
              sum(
                id(
                  `${rows.getBaseId()}.${rowId}.1_HideInPreviewAndPrint.previousYear`
                )
              ),
              0
            )
          )
        : undefined,
      ref(id(`${rows.getBaseId()}.${rowId}Sum.hidden`))
    )
    .addSubRows((rows) => {
      subRowBuilder(rows);
      return rows.build();
    });

  rows.newRowTemplateBuilder((template) =>
    template
      .addRow(
        '',
        value(''),
        refs(),
        ref(or(sum(id('$id.*.year')), 0)),
        ref(or(sum(id('$id.*.previousYear')), 0))
      )
      .newRowTemplate(label('$label'), label(''), ref('$ub'), ref('$ib'))
  );
  {
    addAccountSumRow(rows, rowId, sumName, getWarningCell, useAddRowSum);
  }
};

export const addIncomeStatementGroup = (
  rows: RowsBuilder,
  rowId: string,
  name: string,
  subRowsBuilder: (rows: RowsBuilder) => void,
  sumName?: string,
  noteRef?: string,
  useAddRowSum = true
): void => {
  rows
    .addRow(
      rowId,
      value(name),
      noteRef ? refs(noteRef) : refs(),
      undefined,
      undefined,
      ref(id(`${rows.getBaseId()}.${rowId}Sum.hidden`))
    )
    .addSubRows((rows) => {
      subRowsBuilder(rows);
      return rows.build();
    });

  {
    addAccountSumRow(rows, rowId, sumName, undefined, useAddRowSum);
  }
};

const addAccountSumRow = (
  rows: RowsBuilder,
  rowId: string,
  sumName?: string,
  getWarningCell?: (text: string) => Cell | undefined,
  useAddRowSum = true
) => {
  // A group with empty sumName should have a hidden row with the sum
  const sumRowId = sumName === '' ? `${rowId}VisuallyHiddenSum` : `${rowId}Sum`;
  if (useAddRowSum) {
    rows.addRow(
      sumRowId,
      value(sumName),
      refs(),
      ref(or(sum(id(`${rows.getBaseId()}.${rowId}.*.year`)), 0)),
      ref(or(sum(id(`${rows.getBaseId()}.${rowId}.*.previousYear`)), 0)),
      ref(
        sum(
          or(
            id(`${rows.getBaseId()}.${rowId}.*.notes-0`),
            id(`${rows.getBaseId()}.${rowId}Sum.notes-0`),
            0
          ),
          abs(id(`${rows.getBaseId()}.${rowId}Sum.year`)),
          abs(id(`${rows.getBaseId()}.${rowId}Sum.previousYear`))
        )
      ),
      getWarningCell
        ? getWarningCell(`${rows.getBaseId()}.${rowId}Sum.year`)
        : undefined
    );
    return;
  }
  rows.addRowWithType(
    sumRowId,
    'sum',
    value(sumName),
    refs(),
    ref(or(sum(id(`${rows.getBaseId()}.${rowId}.*.year`)), 0)),
    ref(or(sum(id(`${rows.getBaseId()}.${rowId}.*.previousYear`)), 0)),
    ref(
      sum(
        or(
          id(`${rows.getBaseId()}.${rowId}.*.notes-0`),
          id(`${rows.getBaseId()}.${rowId}Sum.notes-0`),
          0
        ),
        abs(id(`${rows.getBaseId()}.${rowId}Sum.year`)),
        abs(id(`${rows.getBaseId()}.${rowId}Sum.previousYear`))
      )
    )
  );
};

import { balanceSheetReferences } from '../balance-sheet/v1/balanceSheet';
import { balanceStatementK2v2References } from '../balance-sheet/v2/k2/balancestatementk2v2';
import { balanceStatementK3v2References } from '../balance-sheet/v2/k3/balancestatementk3v2';
import { costDividedIncomeStatementK2v2References } from '../income-statement/v2/k2/costdividedincomestatementk2v2';
import { costDividedIncomeStatementK3v2References } from '../income-statement/v2/k3/costdividedincomestatementk3v2';
import { incomeStatementReferences } from '../income-statement/v1/incomeStatement';
import { functionalIncomeStatementK3v2References } from '../income-statement/v2/k3/functionalincomestatementk3v2';
import {
  AnnualReportType,
  AnnualReportVersion,
  IncomeStatementType,
} from '../../document';

export type References = {
  [key: string]: string;
};

const incomeStatementRefsObj: {
  [key in IncomeStatementType]: {
    [key in AnnualReportType]?: () => References;
  };
} = {
  cost: {
    k2: costDividedIncomeStatementK2v2References,
    k3: costDividedIncomeStatementK3v2References,
  },
  functional: {
    k3: functionalIncomeStatementK3v2References,
  },
};

const balanceSheetRefsObj: {
  [key in AnnualReportType]?: () => References;
} = {
  k2: balanceStatementK2v2References,
  k3: balanceStatementK3v2References,
};

export const getIncomeStatementReferences = (
  version: AnnualReportVersion,
  reportType: AnnualReportType,
  incomeSatetementType: IncomeStatementType
): References =>
  (
    (version === '2' &&
      incomeStatementRefsObj[incomeSatetementType][reportType]) ||
    incomeStatementReferences
  )();

export const getBalanceStatementReferences = (
  version: AnnualReportVersion,
  reportType: AnnualReportType
): References =>
  (
    (version === '2' && balanceSheetRefsObj[reportType]) ||
    balanceSheetReferences
  )();

import React from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import styled from '@emotion/styled';
import { ButtonBase, ButtonBaseProps } from '@material-ui/core';

const StyledButton = styled(ButtonBase)`
  margin-left: ${(props) => props.theme.spacing(2)}px;
  color: ${(props) => props.theme.palette.grey[700]};
  &:hover {
    color: ${(props) => props.theme.palette.error.main};
  }
`;

type DeleteRowButtonProps = Pick<
  ButtonBaseProps,
  'onClick' | 'className' | 'disabled'
>;

export default ({ onClick, className, disabled }: DeleteRowButtonProps) => (
  <StyledButton onClick={onClick} className={className} disabled={disabled}>
    <DeleteIcon />
  </StyledButton>
);

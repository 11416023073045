import { Source, Cell, traverseDocument } from '@agoy/document';
import GenericDocumentReactiveViewService from '_shared/services/document/GenericDocumentReactiveViewService';
import { GenericDocumentViewService } from '_shared/services/document/GenericDocumentViewService';
import {
  annualGeneralMeetingContentDefinition,
  annualGeneralMeetingSignaturesContentDefinition,
  annualReportSignaturesContentDefinition,
  confirmationCertificateContentDefinition,
} from '@agoy/annual-report-document';

type ViewService = GenericDocumentReactiveViewService<
  | typeof annualGeneralMeetingContentDefinition
  | typeof annualReportSignaturesContentDefinition
  | typeof annualGeneralMeetingSignaturesContentDefinition
  | typeof confirmationCertificateContentDefinition
>;

/**
 * This document view service dispatches the
 * operations to other view services.
 *
 * The routing is based on the prefix of the ids, with a default service
 * for those that doesn't match.
 *
 * If a view service is defined for the prefix 'signatures', then
 * the operations for the id 'signatures.section.people' are directed
 * to that view service.
 */
class CompoundViewService implements GenericDocumentViewService {
  defaultService: GenericDocumentViewService;

  serviceByPrefix: Record<string, ViewService>;

  /**
   *
   * @param defaultService The service used for all operation that doesn't match a prefix.
   * @param serviceByPrefix The mapping of a prefix to a view service.
   */
  constructor(defaultService, serviceByPrefix: Record<string, ViewService>) {
    this.defaultService = defaultService;
    this.serviceByPrefix = serviceByPrefix;
  }

  private documentIncludesId = (id: string, service: ViewService) => {
    if (service.document) {
      const result = traverseDocument(
        id.split('.'),
        service.structure,
        service.document,
        undefined,
        {}
      );

      return !result.error;
    }

    return false;
  };

  private getServiceAndId(id: string): {
    service: GenericDocumentViewService;
    id: string;
  } {
    const [prefix, ...rest] = id.split('.');
    const idWithoutPrefix = rest.join('.');

    let documentId = id;
    let documentKey = Object.keys(this.serviceByPrefix).find((key) =>
      this.documentIncludesId(id, this.serviceByPrefix[key])
    );

    if (
      documentKey &&
      this.serviceByPrefix[documentKey].structure.children[prefix]?.type ===
        'externalDocument'
    ) {
      // if reference to external document, need to find document using id without prefix
      documentKey = Object.keys(this.serviceByPrefix).find((key) =>
        this.documentIncludesId(idWithoutPrefix, this.serviceByPrefix[key])
      );
      documentId = idWithoutPrefix;
    }

    return {
      service: documentKey
        ? this.serviceByPrefix[documentKey]
        : this.defaultService,
      id: documentId,
    };
  }

  updateField(
    fieldId: string,
    value: string | number | boolean | undefined,
    source?: Partial<Source | undefined>
  ): Promise<void> {
    const { service, id } = this.getServiceAndId(fieldId);
    return service.updateField(id, value, source);
  }

  updateCellValue(
    cellId: string,
    value: string | number | Cell | undefined,
    options: { keepOriginal?: boolean } = {}
  ): Promise<void> {
    const { service, id } = this.getServiceAndId(cellId);
    return service.updateCellValue(id, value, options);
  }

  updateRowValues(
    rowId: string,
    values: { [key: string]: Cell | string | number | undefined },
    options: { keepOriginal?: boolean } = {}
  ): Promise<void> {
    const { service, id } = this.getServiceAndId(rowId);
    return service.updateRowValues(id, values, options);
  }

  deleteRow(rowId: string): Promise<void> {
    const { service, id } = this.getServiceAndId(rowId);
    return service.deleteRow(id);
  }

  addRow(
    rowId: string,
    newRowId?: string | undefined,
    cellParameters?: Record<string, string> | undefined,
    copyId?: string | undefined
  ): Promise<void> {
    const { service, id } = this.getServiceAndId(rowId);
    return service.addRow(id, newRowId, cellParameters, copyId);
  }

  resetContent(contentId: string): Promise<void> {
    const { service, id } = this.getServiceAndId(contentId);
    return service.resetContent(id);
  }

  toggleTableRowActive(rowId: string): Promise<void> {
    const { service, id } = this.getServiceAndId(rowId);
    return service.toggleTableRowActive(id);
  }

  updateTableSource(tableId: string, source: Partial<Source>) {
    const { service, id } = this.getServiceAndId(tableId);
    return service.updateTableSource(id, source);
  }

  addTableColumn(
    tableId: string,
    index: number,
    label: string,
    sortKey?: number | undefined
  ): void | Promise<void> {
    const { service, id } = this.getServiceAndId(tableId);
    return service.addTableColumn(id, index, label, sortKey);
  }

  deleteColumn(columnId: string): void | Promise<void> {
    const { service, id } = this.getServiceAndId(columnId);
    return service.deleteColumn(id);
  }

  toggleFieldActive(fieldId: string): void | Promise<void> {
    const { service, id } = this.getServiceAndId(fieldId);
    return service.toggleFieldActive(id);
  }

  toggleSectionActive(columnId: string): void | Promise<void> {
    const { service, id } = this.getServiceAndId(columnId);
    return service.toggleSectionActive(id);
  }

  toggleTableActive(tableId: string): void | Promise<void> {
    const { service, id } = this.getServiceAndId(tableId);
    return service.toggleTableActive(id);
  }

  updateCellReferences(
    cellId: string,
    references: string[]
  ): void | Promise<void> {
    const { service, id } = this.getServiceAndId(cellId);
    return service.updateCellReferences(id, references);
  }

  updateColumnLabel(columnId: string, label: string): void | Promise<void> {
    const { service, id } = this.getServiceAndId(columnId);
    return service.updateColumnLabel(id, label);
  }

  updateColumnSortKey(
    columnsWithSortKey: { id: string; sortKey: number }[]
  ): void | Promise<void> {
    if (columnsWithSortKey.length) {
      const { service } = this.getServiceAndId(columnsWithSortKey[0].id);
      return service.updateColumnSortKey(columnsWithSortKey);
    }
  }

  updateRows(
    rows: {
      id: string;
      sortKey?: number | undefined;
      active?: boolean | undefined;
    }[]
  ): void | Promise<void> {
    if (rows.length) {
      const { service } = this.getServiceAndId(rows[0].id);
      return service.updateRows(rows);
    }
  }

  resetTableRow(rowId: string): Promise<void> {
    const { service, id } = this.getServiceAndId(rowId);
    return service.resetTableRow(id);
  }

  async resetField(fieldId: string): Promise<void> {
    const { service, id } = this.getServiceAndId(fieldId);
    return service.resetField(id);
  }
}

export default CompoundViewService;

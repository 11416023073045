import { mapRecord } from '@agoy/common';
import { DocumentValuesResolver, TimePeriod, Values } from '@agoy/document';
import { asResultClass, getApiSdk, isApiErrorType } from 'api-sdk';
import {
  BehaviorSubject,
  firstValueFrom,
  map,
  Observable,
  ReplaySubject,
  Subject,
  Subscription,
  withLatestFrom,
} from 'rxjs';

export const mapValues = (
  values: Record<string, string | number | boolean | null>
): Values => {
  return mapRecord(values, (v) => (v === null ? undefined : v));
};

type ApiSdk = Pick<
  ReturnType<typeof getApiSdk>,
  | 'getAgoyDocumentValues'
  | 'findAgoyDocument'
  | 'getAgoyDocumentConnections'
  | 'addAgoyDocumentConnection'
>;

export type Connections = Awaited<
  ReturnType<ApiSdk['getAgoyDocumentConnections']>
>['connections'];

class ExternalDocumentValuesResolver implements DocumentValuesResolver {
  sdk: ApiSdk;

  clientId: string;

  documentId: string;

  financialYear: TimePeriod;

  existingConnections: Subject<Connections>;

  readonly = false;

  /**
   * Record by documentId to an observable of values.
   *
   * TODO: When notications of changes values are implemented, pick the
   * subject for the documentId here and call next with the new values.
   */
  values: Record<string, Subject<Values>> = {};

  /**
   * Subscription that needs to be closed when done
   */
  subscriptions: Subscription[] = [];

  /**
   * Subject that are created in this class, completed when we dispose it
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  subjects: Subject<any>[] = [];

  constructor(
    sdk: ApiSdk,
    clientId: string,
    documentId: string,
    financialYear: TimePeriod,
    readonly: boolean
  ) {
    this.sdk = sdk;
    this.clientId = clientId;
    this.documentId = documentId;
    this.financialYear = financialYear;
    this.readonly = readonly;
    this.existingConnections = new ReplaySubject(1);
    this.sdk
      .getAgoyDocumentConnections({ documentId, clientId, type: 'uses' })
      .then((result) => this.existingConnections.next(result.connections))
      .catch((err) => this.existingConnections.error(err));
  }

  dispose() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
    this.subjects.forEach((sub) => sub.complete());
  }

  /**
   * Initializes a subject for the values of an external document
   * by creating a subject and calling the API for the values.
   *
   * @param documentId
   * @returns
   */
  initConnection(documentId: string): Subject<Values> {
    if (!(documentId in this.values)) {
      const subject = new ReplaySubject<Values>(1);
      this.subjects.push(subject);

      this.values[documentId] = subject;
      asResultClass(
        this.sdk.getAgoyDocumentValues({
          documentId,
          clientId: this.clientId,
        })
      ).then((valuesResult) => {
        if (valuesResult.ok) {
          subject.next(mapValues(valuesResult.val.values));
          return;
        }
        if (
          isApiErrorType(valuesResult.val) &&
          valuesResult.val.status === 404
        ) {
          // No values generated yet.
          subject.next({});
          return;
        }
        if (!isApiErrorType(valuesResult.val) || !valuesResult.val.handled) {
          // eslint-disable-next-line no-console
          console.error(valuesResult.val);
          subject.error(
            new Error(`Failed to get values for document ${documentId}`)
          );
        }
      });
      return subject;
    }
    return this.values[documentId];
  }

  resolveValues(
    name: string,
    type: string,
    financialYearStep: number | undefined,
    relationType?: 'ownership' | 'sibling' | 'ownedByOwner'
  ): Observable<Values> {
    const subject = new ReplaySubject<Values>(1);
    firstValueFrom(this.existingConnections).then(async (connected) => {
      // Now that we have the connection that are already registered
      // we can check if there are other documents to connect.

      const connection = connected.find((conn) => conn.name === name);

      if (connection) {
        // This connection already exists, just connect the values.
        this.subscriptions.push(
          this.initConnection(connection.toId).subscribe((values) =>
            subject.next(values)
          )
        );
        return;
      }

      if (this.readonly) {
        // Support users should not try to create connections
        subject.next({});
        return;
      }

      // Check which document that exist
      const existingDocuments = await asResultClass(
        this.sdk.findAgoyDocument({
          clientId: this.clientId,
          financialYear: this.financialYear.value,
          relativeFinancialYear: financialYearStep,
          type,
          relationType,
        })
      );

      if (existingDocuments.err) {
        // Forward the error to the subject.
        if (
          isApiErrorType(existingDocuments.val) &&
          existingDocuments.val.body.code === 'FINANCIAL_YEAR_NOT_FOUND'
        ) {
          subject.next({});
        } else if (
          !isApiErrorType(existingDocuments.val) ||
          !existingDocuments.val.handled
        ) {
          subject.error(existingDocuments.val);
        }
      } else if (existingDocuments.val.length === 0) {
        // No document, empty values.
        subject.next({});
      } else {
        const unconnectedId = existingDocuments.val[0].id;

        // Add connection
        this.sdk
          .addAgoyDocumentConnection({
            documentId: this.documentId,
            clientId: this.clientId,
            toDocumentId: unconnectedId,
            requestBody: {
              name,
              order: null,
            },
          })
          .catch((err) => {
            if (!isApiErrorType(err) || !err.handled) {
              subject.error(err);
            }
          });

        // Forward the values
        this.subscriptions.push(
          this.initConnection(unconnectedId).subscribe((values) =>
            subject.next(values)
          )
        );
      }
    });

    return subject;
  }

  resolveMultipleValues(
    name: string,
    type: string,
    financialYearStep: number | undefined,
    relationType?: 'ownership' | 'sibling'
  ): Observable<Record<string, Values>> {
    const subject = new BehaviorSubject<Record<string, Values>>({});
    this.subjects.push(subject);

    firstValueFrom(this.existingConnections).then(async (connected) => {
      // Now that we have the connection that are already registered
      // we can check if there are other documents to connect.

      // Check which document that exist
      const existingDocuments = await asResultClass(
        this.sdk.findAgoyDocument({
          clientId: this.clientId,
          financialYear: this.financialYear.value,
          relativeFinancialYear: financialYearStep,
          type,
          relationType,
        })
      );

      if (existingDocuments.err) {
        subject.error(existingDocuments.val);
        return;
      }

      // The order starts at 0, and the next added connection
      // will have +1. So the first connection has order 1.
      // If a connection is deleted, there may be gaps in the orders
      let maxOrder =
        connected.length > 0
          ? Math.max(...connected.map((c) => c.order || 0))
          : 0;

      const allConnected = [...connected];

      // Add connection for any document that is not connected yet
      existingDocuments.val
        .filter((doc) => !connected.find((c) => c.toId === doc.id))
        .forEach((unconnected) => {
          // eslint-disable-next-line no-plusplus
          const order = ++maxOrder;
          allConnected.push({
            name,
            order,
            toId: unconnected.id,
            clientId: unconnected.clientId,
          });
          this.sdk.addAgoyDocumentConnection({
            documentId: this.documentId,
            clientId: this.clientId,
            toDocumentId: unconnected.id,
            requestBody: {
              name,
              order,
            },
          });
        });

      allConnected.forEach((conn) => this.initConnection(conn.toId));

      allConnected.forEach((conn) => {
        if (conn.name) {
          const obs = this.values[conn.toId];
          const key =
            conn.order === null ? conn.name : `${conn.name}-${conn.order}`;

          // Create a subscription that combine the values for a particular document
          // with the other documents for this subject.
          this.subscriptions.push(
            obs
              .pipe(
                withLatestFrom(subject),
                map(([values, allValues]) => ({ ...allValues, [key]: values }))
              )
              .subscribe((allValues) => subject.next(allValues))
          );
        } else {
          console.warn('connection is missing a name', conn);
        }
      });
    });

    return subject;
  }

  /**
   * Refetch values for a document
   * @param documentId The document id to refresh / refetch values for
   */
  refreshValues(documentId: string): void {
    if (documentId in this.values) {
      const subject = this.values[documentId];
      asResultClass(
        this.sdk.getAgoyDocumentValues({
          documentId,
          clientId: this.clientId,
        })
      ).then((valuesResult) => {
        if (valuesResult.ok) {
          subject.next(mapValues(valuesResult.val.values));
          return;
        }
        if (
          isApiErrorType(valuesResult.val) &&
          valuesResult.val.status === 404
        ) {
          // No values generated yet.
          subject.next({});
          return;
        }
        if (!isApiErrorType(valuesResult.val) || !valuesResult.val.handled) {
          // eslint-disable-next-line no-console
          console.error(valuesResult.val);
          subject.error(
            new Error(`Failed to get values for document ${documentId}`)
          );
        }
      });
    }
  }
}

export default ExternalDocumentValuesResolver;

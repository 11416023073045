import {
  TaxDeclarationFormDefinition,
  TaxDeclarationFormDataConfig,
  PersonMetaData,
} from '../../types';
import {
  ref,
  value,
  id,
  ifOrElse,
  table,
  sumAllowEmpty,
  multiply,
  label,
  multiplyPercentage,
  div,
  or,
} from '@agoy/document';
import {
  mapFormId,
  negative,
  onlyNegative,
  onlyPositive,
  toStructure,
  toInitial,
  absolute,
} from '../forms-util';
import helpStructureK5 from '../../types/help/K5';

// TODO For financial year 2024 and later if the fields change a lot
// its probably better to just create a new config instead of creating even more exceptions
const configK5 = (
  form: TaxDeclarationFormDefinition,
  person: PersonMetaData,
  financialYearEnd: string
): TaxDeclarationFormDataConfig => {
  const is2022 = financialYearEnd === '20221231';

  const isYear2022OrHigher = (financialYearEnd: string): boolean => {
    const year = parseInt(financialYearEnd.substring(0, 4), 10);
    return year >= 2022;
  };

  const is2022OrHigher = isYear2022OrHigher(financialYearEnd);

  const name = `${person?.firstName || ''} ${person?.lastName || ''}`;
  const K5 = mapFormId(form, 'K5');
  const initial = {
    K5: {
      partType: 'form' as const,
      id: K5,
      fields: {
        datum: value(''),
        numreringFleraK5: value(undefined),
        namn: value(name),
        personnummer: value(person.personNumber || ''),
        A_fastighetsbeteckning: value(''),
        A_forsaljningsdatumKopekontrakt: value(''),
        A_inkopsdatumKopekontrakt: value(''),
        A_helaInnehavstiden: value(false),
        A_delAvInnehavstiden: value(false),
        A_fromDatum: value(''),
        A_tomDatum: value(''),
        A_andelProcentFritidsboende: value(undefined),
        B_andelSaltProcent: value(undefined),
        B_gemensamma: value(false),
        B_individuella: value(false),
        B_fastighetensInkopspris: value(false),
        B_bottomRightCheckbox: value(false),
        B_1_forsaljningsprisKopekontrakt: value(undefined),
        B_2_forsaljningsutgifterMaklararvode: value(undefined),
        B_3_inkopspris: value(undefined),
        B_4_forbattringsutgifter: ref(
          sumAllowEmpty(id('K5.help.improvements.*.amount'))
        ),
        B_5_forbattrande: ref(sumAllowEmpty(id('K5.help.renovation.*.amount'))),
        B_6_vinstEllerForlust: ref(
          sumAllowEmpty(
            id('K5.fields.B_1_forsaljningsprisKopekontrakt'),
            negative(id('K5.fields.B_2_forsaljningsutgifterMaklararvode')),
            negative(id('K5.fields.B_3_inkopspris')),
            negative(id('K5.fields.B_4_forbattringsutgifter')),
            negative(id('K5.fields.B_5_forbattrande'))
          )
        ),
        B_7_beloppet:
          // If checkbox B.gemensamma is checked then B.7 = B.6 * (B.andelSaltProcent / 100)
          // If checkbox B.gemensamma is not checked then B.7 = B.6
          ref(
            ifOrElse(
              id('K5.fields.B_gemensamma'),
              multiplyPercentage(
                id('K5.fields.B_6_vinstEllerForlust'),
                id('K5.fields.B_andelSaltProcent')
              ),
              id('K5.fields.B_6_vinstEllerForlust')
            )
          ),
        B_8_aterforing: value(undefined),
        B_9_vinst:
          // If B.7 + B.8 is positive then B.9 = B.7 + B.8
          ref(
            ifOrElse(
              onlyPositive(
                sumAllowEmpty(
                  id('K5.fields.B_7_beloppet'),
                  id('K5.fields.B_8_aterforing')
                )
              ),
              sumAllowEmpty(
                id('K5.fields.B_7_beloppet'),
                id('K5.fields.B_8_aterforing')
              ),
              '0'
            )
          ),
        B_10_forlust:
          // If B.7 + B.8 is negative then B.10 = B.7 + B.8
          ref(
            ifOrElse(
              onlyNegative(
                sumAllowEmpty(
                  id('K5.fields.B_7_beloppet'),
                  id('K5.fields.B_8_aterforing')
                )
              ),
              absolute(
                sumAllowEmpty(
                  id('K5.fields.B_7_beloppet'),
                  id('K5.fields.B_8_aterforing')
                )
              ),
              '0'
            )
          ),
        B_11_avdragForPreliminart: ref(
          ifOrElse(
            id('K5.help.postponementAmount.2.value'),
            id('K5.help.postponementAmount.4.value'),
            undefined
          )
        ),

        B_12_avdragForSlutligt: ref(
          ifOrElse(
            id('K5.help.postponementAmount.3.value'),
            id('K5.help.postponementAmount.4.value'),
            undefined
          )
        ),

        B_13_aterstaende:
          // If value in B.9 and in B.11 then B.13 = B.9 - B.11
          // If value in B.9 and in B.12 then B.13 = B.9 - B.12
          // If no value in B.9 then B.13 = undefined
          ref(
            ifOrElse(
              id('K5.fields.B_9_vinst'),
              ifOrElse(
                id('K5.fields.B_11_avdragForPreliminart'),
                sumAllowEmpty(
                  id('K5.fields.B_9_vinst'),
                  negative(id('K5.fields.B_11_avdragForPreliminart'))
                ),
                sumAllowEmpty(
                  id('K5.fields.B_9_vinst'),
                  negative(id('K5.fields.B_12_avdragForSlutligt'))
                )
              ),
              undefined
            )
          ),
        C_fromDatum: value(''),
        C_toDatum: value(''),
        C_varitUnder: value(false),
        C_varitSammanlagt: value(false),
        C_forvarvat: value(false),
        C_fastigheten: value(false),
        D_procent: ref(id('K5.fields.B_andelSaltProcent')),
        D_denAndel: ref(multiplyPercentage(3000000, id('K5.fields.D_procent'))),
        E_fastighetsbeteckning: value(''),
        E_inkopsdatum: value(''),
        E_datumForBosattning: value(''),
        E_1_inkopspris: value(undefined),
        E_1_andelProcent: is2022
          ? ref(id('K5.fields.B_andelSaltProcent'))
          : value(undefined),
        E_2_utgift: value(undefined),
        E_3_totaltInkopspris: is2022
          ? ref(
              sumAllowEmpty(
                id('K5.fields.E_1_inkopspris'),
                id('K5.fields.E_2_utgift')
              )
            )
          : value(undefined),
        E_4_andelProcent: !is2022
          ? ref(id('K5.fields.B_andelSaltProcent'))
          : value(undefined),
        E_4_andelKronor: is2022
          ? value(undefined)
          : ref(
              multiplyPercentage(
                id('K5.fields.E_3_totaltInkopspris'),
                id('K5.fields.E_1_andelProcent')
              )
            ),
        F_bostadsrattsforeningens: value(''),
        F_organisationsnummer: value(''),
        F_lagenhetsbeteckning: value(''),
        F_inkopsdatum: value(''),
        F_datumForBosattning: value(''),
        F_1_inkopspris: value(undefined),
        F_1_andelProcent: is2022
          ? ref(id('K5.fields.B_andelSaltProcent'))
          : value(undefined),
        F_2_andelProcent: !is2022
          ? ref(id('K5.fields.B_andelSaltProcent'))
          : value(undefined),
        F_2_andelKronor: is2022
          ? value(undefined)
          : ref(
              multiplyPercentage(
                id('K5.fields.F_1_inkopspris'),
                id('K5.fields.F_2_andelProcent')
              )
            ),
        G_vadDenUtlandska: value(''),
        G_eesLand: value(''),
        G_bostadensAdress: value(''),
        G_bostadensPostadress: value(''),
        H_vinst: ref(
          ifOrElse(
            id('K5.fields.B_9_vinst'),
            id('K5.fields.B_9_vinst'),
            undefined
          )
        ),
        H_inkopspris:
          // For 2022: Value from E.3 (if there is) or F.1 pris * (F.1 % / 100) (otherwise)
          is2022
            ? ref(
                ifOrElse(
                  id('K5.fields.E_3_totaltInkopspris'),
                  multiplyPercentage(
                    id('K5.fields.E_3_totaltInkopspris'),
                    id('K5.fields.E_1_andelProcent')
                  ),
                  multiplyPercentage(
                    id('K5.fields.F_1_inkopspris'),
                    id('K5.fields.F_1_andelProcent')
                  )
                )
              )
            : // For 2021: Value from E.4 (if there is) or F.2 (otherwise)
              ref(
                ifOrElse(
                  id('K5.fields.E_4_andelKronor'),
                  id('K5.fields.E_4_andelKronor'),
                  id('K5.fields.F_2_andelKronor')
                )
              ),
        H_forsaljningspris: value(undefined),
        H_total: ref(
          div(
            multiply(id('K5.fields.H_vinst'), id('K5.fields.H_inkopspris')),
            id('K5.fields.H_forsaljningspris')
          )
        ),
      },
      derivedFields: {
        B_vinst: ref(
          ifOrElse(
            id('K5.fields.B_13_aterstaende'),
            id('K5.fields.B_13_aterstaende'),
            id('K5.fields.B_9_vinst')
          )
        ),
        B_forlust: ref(id('K5.fields.B_10_forlust')),
      },
      sru: {
        '7014': ref(id('K5.fields.numreringFleraK5')),
        '3600': ref(id('K5.fields.A_fastighetsbeteckning')),
        '3602': ref(id('K5.fields.A_forsaljningsdatumKopekontrakt')),
        '3601': ref(id('K5.fields.A_inkopsdatumKopekontrakt')),
        '3607': ref(id('K5.fields.A_helaInnehavstiden')),
        '3619': ref(id('K5.fields.A_delAvInnehavstiden')),
        '3609': ref(id('K5.fields.A_fromDatum')),
        '3610': ref(id('K5.fields.A_tomDatum')),
        '3618': ref(id('K5.fields.A_andelProcentFritidsboende')),
        '3626': ref(id('K5.fields.B_andelSaltProcent')),
        '3613': ref(id('K5.fields.B_gemensamma')),
        '3614': ref(id('K5.fields.B_individuella')),
        '3611': ref(id('K5.fields.B_fastighetensInkopspris')),
        '3801': ref(id('K5.fields.B_bottomRightCheckbox')),
        '3620': ref(id('K5.fields.B_1_forsaljningsprisKopekontrakt')),
        '3621': ref(id('K5.fields.B_2_forsaljningsutgifterMaklararvode')),
        '3622': ref(id('K5.fields.B_3_inkopspris')),
        '3623': ref(id('K5.fields.B_4_forbattringsutgifter')),
        '3624': ref(id('K5.fields.B_5_forbattrande')),
        '3625': ref(id('K5.fields.B_6_vinstEllerForlust')),
        '3627': ref(id('K5.fields.B_7_beloppet')),
        '3628': ref(id('K5.fields.B_8_aterforing')),
        '3629': ref(id('K5.fields.B_9_vinst')),
        '3630': ref(id('K5.fields.B_10_forlust')),
        '3637': ref(id('K5.fields.B_11_avdragForPreliminart')),
        '3681': ref(id('K5.fields.B_12_avdragForSlutligt')),
        '3695': ref(id('K5.fields.B_13_aterstaende')),
        '3603': ref(id('K5.fields.C_fromDatum')),
        '3604': ref(id('K5.fields.C_toDatum')),
        '3615': ref(id('K5.fields.C_varitUnder')),
        '3616': ref(id('K5.fields.C_varitSammanlagt')),
        '3617': ref(id('K5.fields.C_forvarvat')),
        '3648': ref(id('K5.fields.C_fastigheten')),
        '3633': ref(id('K5.fields.D_procent')),
        '3639': ref(id('K5.fields.D_denAndel')),
        '3640': ref(id('K5.fields.E_fastighetsbeteckning')),
        '3641': ref(id('K5.fields.E_inkopsdatum')),
        '3642': ref(id('K5.fields.E_datumForBosattning')),
        '3643': ref(id('K5.fields.E_1_inkopspris')),
        '3644': ref(id('K5.fields.E_2_utgift')),

        ...(is2022OrHigher
          ? undefined
          : {
              '3645': ref(id('K5.fields.E_3_totaltInkopspris')),
            }),

        ...(is2022OrHigher
          ? { '3646': ref(id('K5.fields.E_1_andelProcent')) }
          : { '3646': ref(id('K5.fields.E_4_andelProcent')) }),

        ...(is2022OrHigher
          ? { '3647': ref(id('K5.fields.E_3_totaltInkopspris')) }
          : { '3647': ref(id('K5.fields.E_4_andelKronor')) }),

        '3650': ref(id('K5.fields.F_bostadsrattsforeningens')),
        '3651': ref(id('K5.fields.F_organisationsnummer')),
        '3652': ref(id('K5.fields.F_lagenhetsbeteckning')),
        '3653': ref(id('K5.fields.F_inkopsdatum')),
        '3654': ref(id('K5.fields.F_datumForBosattning')),

        ...(is2022OrHigher
          ? undefined
          : {
              '3655': ref(id('K5.fields.F_1_inkopspris')),
            }),

        ...(is2022OrHigher
          ? { '3656': ref(id('K5.fields.F_1_andelProcent')) }
          : { '3656': ref(id('K5.fields.F_2_andelProcent')) }),

        ...(is2022OrHigher
          ? { '3657': ref(id('K5.fields.F_1_inkopspris')) }
          : { '3657': ref(id('K5.fields.F_2_andelKronor')) }),

        '3662': ref(id('K5.fields.G_vadDenUtlandska')),
        '3663': ref(id('K5.fields.G_eesLand')),
        '3664': ref(id('K5.fields.G_bostadensAdress')),
        '3658': ref(id('K5.fields.G_bostadensPostadress')),
        // definition of 3696 is: Vinst x Inköpspris/Försäljningspris
        '3696': ref(id('K5.fields.H_total')),
      },
      help: {
        _type: 'K5' as const,
        postponementHelper: table(
          'k5.help.postponementHelper',
          { id: 'label', label: '' },
          { id: 'value', label: '' }
        )
          .addRows((rows) =>
            rows
              .addRow(
                '1',
                label(
                  'Kommer en ny bostad köpas och önskar ni därmed preliminärt uppskov?'
                ),
                value(
                  is2022OrHigher
                    ? !!ref(
                        or(
                          id('K5.fields.F_1_inkopspris'),
                          id('K5.fields.E_3_totaltInkopspris')
                        )
                      ).value
                    : !!ref(
                        or(
                          id('K5.fields.E_4_andelKronor'),
                          id('K5.fields.F_2_andelKronor')
                        )
                      ).value
                )
              )

              .addRow(
                '2',
                label(
                  'Vill du utnyttja möjligheten till slutligt uppskov maximalt? Ange “Nej” ifall du vill yrka på ett annat uppskovsbelopp.'
                ),
                value(true)
              )
              .build()
          )
          .build(),
        improvements: table(
          'K5.help.improvements',
          'description',
          'year',
          'amount',
          'sum'
        )
          .addRows((rows) =>
            rows.addRow('1', value(''), value(''), value(0), value(0)).build()
          )
          .newRowTemplate(value(''), value(''), value(0), value(0))
          .build(),

        renovation: table(
          'K5.help.renovation',
          'description',
          'year',
          'amount',
          'sum'
        )
          .addRows((rows) =>
            rows.addRow('1', value(''), value(''), value(0), value(0)).build()
          )
          .newRowTemplate(value(''), value(''), value(0), value(0))
          .build(),

        postponementAmount: table(
          'K5.help.postponementAmount',
          { id: 'label', label: '' },
          { id: 'value', label: '' }
        )
          .addRows((rows) =>
            rows
              .addRow('1', label('Inget uppskov'), {
                type: 'boolean',
                value: true,
              })
              .addRow('2', label('Preliminärt uppskov'), {
                type: 'boolean',
                value: false,
              })
              .addRow('3', label('Slutligt uppskov'), {
                type: 'boolean',
                value: false,
              })
              .addRow('4', label('Yrkat uppskovsbelopp om ej max'), value(0))
              .build()
          )
          .build(),
      },
    },
  };
  return {
    initial: toInitial(initial, form),
    definition: toStructure(initial, form, { K5: helpStructureK5 }),
  };
};

export default configK5;

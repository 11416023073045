import { ToggleFinancialReportIncomeStatementSectionActive } from '../../action-type-declarations';
import { FinancialReportClientPeriodState } from '../types';

const toggleFinancialReportIncomeStatementSectionActive = (
  state: FinancialReportClientPeriodState,
  action: ToggleFinancialReportIncomeStatementSectionActive
) => {
  const { section } = action;

  if (!state.report) {
    return state;
  }

  return {
    ...state,
    report: {
      ...state.report,
      incomeStatementTables: {
        ...state.report.incomeStatementTables,
        section: {
          ...state.report.incomeStatementTables.section,
          [section]: {
            ...state.report.incomeStatementTables.section[section],
            active: !state.report.incomeStatementTables.section[section].active,
          },
        },
      },
    },

    changes: {
      ...state.changes,
      incomeStatementTables: {
        ...state.changes.incomeStatementTables,
        section: {
          ...state.changes.incomeStatementTables?.section,
          [section]: {
            // ...state.changes.incomeStatement?.section[section],
            active: !state.report.incomeStatementTables.section[section].active,
          },
        },
      },
    },
  };
};

export default toggleFinancialReportIncomeStatementSectionActive;

import React from 'react';
import { Box } from '@material-ui/core';
import { NoteFourTablesWithCommentsAndSum } from '@agoy/annual-report-document';
import { numberValue, stringValue } from '@agoy/document';
import { Note } from '../../UI/Note';
import Comment from '../../UI/Comment';
import Header from '../../UI/Header';
import NoteTable from '../../UI/NoteTable';

type Note93Props = {
  noteId: string;
  active: boolean;
  title: string;
  hasPreviousYear?: boolean;
} & NoteFourTablesWithCommentsAndSum;

const Note93 = ({
  noteId,
  active,
  number,
  title,
  table,
  firstTableCommentLabel,
  firstTableComment,
  secondTable,
  thirdTable,
  thirdTableCommentLabel,
  thirdTableComment,
  commentLabel,
  comment,
  hasPreviousYear,
}: Note93Props): JSX.Element | null => {
  if (!active) return null;
  return (
    <Note id={noteId}>
      <Header noteId={noteId} noteNumber={numberValue(number)} title={title} />

      <Box mt={1} mb={1}>
        <NoteTable
          table={table}
          hasPreviousYear={hasPreviousYear}
          tableId={`notes.${noteId}.table`}
        />
      </Box>

      <Comment
        field={firstTableComment}
        labelValue={stringValue(firstTableCommentLabel) || ''}
        value={stringValue(firstTableComment) || ''}
      />

      <Box mt={1} mb={1}>
        <NoteTable
          table={secondTable}
          hasPreviousYear={hasPreviousYear}
          tableId={`notes.${noteId}.secondTable`}
        />
      </Box>

      <Box mt={1} mb={1}>
        <NoteTable
          table={{
            ...thirdTable,
            rows: [...thirdTable.rows.slice(1), thirdTable.rows[0]],
          }}
          hasPreviousYear={hasPreviousYear}
          tableId={`notes.${noteId}.thirdTable`}
        />
      </Box>

      <Comment
        field={thirdTableComment}
        labelValue={stringValue(thirdTableCommentLabel) || ''}
        value={stringValue(thirdTableComment) || ''}
      />

      <Comment
        field={comment}
        labelValue={stringValue(commentLabel) || ''}
        value={stringValue(comment) || ''}
      />
    </Note>
  );
};

export default Note93;

import { formatFinancialYear, mapOf } from '@agoy/common';
import { AnnualReportCustomerType } from '../../common';
import { field, table } from '../../common/utils/util';
import {
  abs,
  id,
  multiply,
  sum,
  ref,
  value,
  TimePeriod,
  ReferenceCell,
  ifOrElse,
  round,
  not,
  account,
  max,
} from '@agoy/document';
import { Settings, Warning } from '../../common/types/settings';

// AGOY-3964: for each new warning, add a new object here with the information below. a and b are compared
const warnings = [
  {
    id: 'totalUnrestrictedEquityWarning',
    label:
      'Värdena i balansräkningen (A) och i förvaltningsberättelsens resultatdisposition summa (B) stämmer inte överens.',
    // a and b are AR reference strings
    // these references don't exist for individualCompany
    a: 'balanceSheet.section.equityAndLiabilities.equityAndLiabilities.equity.unrestrictedEquitySum.year',
    b: 'managementReport.resultsDisposition.total',
  },
  {
    id: 'sumAssetsWarning',
    label:
      'Värdena i balansräkningens summa tillgångar (A) och i balansräkningens summa eget kapital och skulder (B) stämmer inte överens.',
    a: 'balanceSheet.section.assets.sumAssets.year',
    b: 'balanceSheet.section.equityAndLiabilities.sumEquityAndLiabilities.year',
  },
  {
    id: 'sumAssetsPreviousYearWarning',
    label:
      'Värdena i balansräkningens summa tillgångar för föregående (A) och  i balansräkningens summa eget kapital och skulder för föregående (B) stämmer inte överens.',
    a: 'balanceSheet.section.assets.sumAssets.previousYear',
    b: 'balanceSheet.section.equityAndLiabilities.sumEquityAndLiabilities.previousYear',
  },
];

const submissionWarnings: Warning[] = [];

const warningByA = mapOf(
  warnings,
  (warning) => warning.a,
  (warning) => warning
);

const warningByB = mapOf(
  warnings,
  (warning) => warning.b,
  (warning) => warning
);

export const getWarningCell = (cellId: string): ReferenceCell | undefined => {
  const warning = warningByA.get(cellId) || warningByB.get(cellId);
  if (!warning) return undefined;
  return ref(
    ifOrElse(
      round(id(`settings.warnings.table.${warning.id}.difference`)),
      id(`settings.warnings.table.${warning.id}.label`),
      '0'
    )
  );
};

export const settingsConfig = (
  customer: AnnualReportCustomerType,
  financialYear: string,
  period: TimePeriod,
  previousPeriod: TimePeriod | null,
  isFirstFinancialYear: boolean,
  isSinglePeriod: boolean
): Settings => {
  return {
    section: {
      title: field('Årsbokslut'),
      secondaryText: field(''),
      currencyText: field('Årsbokslutet är upprättat i svenska kronor (SEK).'),
      clientName: field(customer.name),
      isSinglePeriod,
      period: field(formatFinancialYear(financialYear)),
      financialYear: field(financialYear),
      periodStart: field(period.startDateISO),
      periodEnd: field(period.endDateISO),
      previousPeriodStart: field(previousPeriod?.startDateISO || ''),
      previousPeriodEnd: field(previousPeriod?.endDateISO || ''),
      organisationNumber: field(customer.orgNumber ?? ''),
      isFirstFinancialYear,
      logoLargeUrl: field(customer.logoOriginal ?? ''),
      logoUrl: field(customer.logo ?? ''),
      isAuditReportRequired: ref(
        not(
          not(
            multiply(
              round(max(sum(account('3000:3999'), -3000000), 0)),
              round(max(sum(account('1000:1999'), -1500000), 0))
            )
          )
        )
      ),
      auditReportRequiredUpdated: value(''),
      typeSigningAnnualReport: {
        isDigitalSigningWithFortnox: value(false),
        isManualSigning: value(true),
        isOtherDigitalSigning: value(false),
      },
      typeSigningProtocol: {
        isDigitalSigningWithFortnox: value(true),
        isManualSigning: value(false),
        isOtherDigitalSigning: value(false),
      },
      cashFlowStatementActive: value(false),
      annualGeneralMeetingActive: value(true),
      formatting: {
        displayInThousands: value(false),
        displayMultiYearOverviewInThousands: value(false),
        displayChangesInEquityInThousands: value(false),
        displayResultsDispositionInThousands: value(false),
        labelDisplayInThousands: value('tkr'),
        numberOfDecimalsForPercentagesInMultiYearOverview: value(2),
      },
    },
    warnings: {
      table: table('settings.warnings.table', 'label', 'difference', 'a', 'b')
        .addRows((rows) => {
          warnings.forEach((warning) =>
            rows.addRow(
              warning.id,
              value(warning.label),
              ref(
                abs(
                  sum(
                    id(`${rows.getBaseId()}.${warning.id}.a`),
                    multiply(-1, id(`${rows.getBaseId()}.${warning.id}.b`))
                  )
                )
              ),
              ref(id(warning.a)),
              ref(id(warning.b))
            )
          );
          return rows.build();
        })
        .build(),
      submissionWarnings: table(
        'settings.warnings.submissionWarnings',
        'label',
        'result',
        'type'
      )
        .addRows((rows) => {
          submissionWarnings.forEach((warning) =>
            rows.addRow(
              warning.id,
              value(warning.label),
              ref(warning.result),
              value(warning.type)
            )
          );
          return rows.build();
        })
        .build(),
    },
    clientInformation: {
      activityText: field(''),
      city: field('[ort]'),
    },
    isNedskrivningEgenPost: value(false),
  };
};

export const settingsReferences = (): Record<string, string> => ({});

import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { Settings } from '@agoy/annual-report-document';
import {
  AgoyTable,
  AgoyTableRow,
  AgoyTableColumn,
  Cell,
  stringValue,
  referenceValue,
} from '@agoy/document';
import { isSinglePeriodSelected } from '../../utils/isSinglePeriodSelected';
import { rowHaveValues } from '../../utils/rowHaveValues';
import {
  HierarchicalPrintTableRowProps,
  RowLevel1,
  RowLevel2,
  RowLevel3 as HRowLevel3,
} from '../HierarchicalPrintTable/HierarchicalPrintTableRow';
import HierarchicalPrintTable from '../HierarchicalPrintTable';

type BRRRPrintTableProps = {
  className?: string;
  table: AgoyTable;
  tableId: string;
  settings: Settings;
  skipColumns?: string[];
  stickyHeader?: boolean;
  renderHeader?: (
    columns: AgoyTableColumn[]
  ) => React.ReactElement | React.ReactElement[];
  zoom?: number;
};

const StyledHierarchicalTable = styled(HierarchicalPrintTable)`
  // Noter
  .MuiTableHead-root .MuiTableCell-root.MuiTableCell-head:nth-of-type(2) {
    text-align: right;
    min-width: 120px;
  }

  // accounting year
  .MuiTableHead-root .MuiTableCell-root.MuiTableCell-head:nth-of-type(3) {
    text-align: right;
    min-width: 120px;
  }

  // previous accounting year
  .MuiTableHead-root .MuiTableCell-root.MuiTableCell-head:nth-of-type(4) {
    text-align: right;
    min-width: 120px;
  }
`;

// Ex. Tillgångar, never editable
const BalanceSheetRowLevel1 = (props: HierarchicalPrintTableRowProps) => {
  const { row } = props;

  if (isRowLabelEmpty(row)) return null;
  const isSumRow = row.id.startsWith('sum') || row.id.endsWith('Sum');
  return <RowLevel1 sumRow={isSumRow} {...props} />;
};

const isRowHidden = (row: AgoyTableRow) => {
  return referenceValue(row.cells?.hidden) === 0;
};

/** Returns true if the label, which is the title for the row, is empty.
 */
const isRowLabelEmpty = (row: AgoyTableRow) => {
  return stringValue(row.cells?.label) === '';
};

const hasSubRowValue = (subRows: AgoyTableRow<Cell>[]): boolean => {
  const haveSubRowsWithValues = subRows
    ?.map((r) => {
      // result = årets resultat, always show it in incomeStatement
      if (r.id === 'result') {
        return true;
      }

      // If the row is hidden, it should not be visible
      if (isRowHidden(r)) {
        return false;
      }

      // headers should not be visible by default. If any subRows have values the header is visible
      if (r.type === 'header') {
        return false;
      }

      return rowHaveValues(r);
    })
    .some((r) => r);

  return haveSubRowsWithValues;
};

// Ex. Anläggningstillgångar
const BalanceSheetRowLevel2 = (props: HierarchicalPrintTableRowProps) => {
  const { row } = props;

  if (isRowLabelEmpty(row)) return null;
  const isHeading = row.cells && Object.keys(row.cells).length === 1;
  const isSumRow = row.id.startsWith('sum') || row.id.endsWith('Sum');

  // AGOY-3942: 'subscribedUnpaidCapital' is a special row added in this story
  // its structure is different from the other sum rows
  const subRows =
    row?.rows != null && row.id !== 'subscribedUnpaidCapital'
      ? row.rows
      : [row];

  if (!hasSubRowValue(subRows)) {
    return null;
  }

  return <RowLevel2 sumRow={isSumRow} {...props} heading={isHeading} />;
};

// Ex. Materiella anläggninstillgångar
const BalanceSheetRowLevel3 = (props: HierarchicalPrintTableRowProps) => {
  const { row } = props;

  if (isRowLabelEmpty(row)) return null;
  if (isRowHidden(row)) return null;
  const isSumRow = row.id.startsWith('sum') || row.id.endsWith('Sum');

  // AGOY-3845 to make manual edits in these row.id appear in preview / print
  const subRows =
    row?.rows != null &&
    row.id !== 'jointControlledA' &&
    row.id !== 'jointControlledB'
      ? row.rows
      : [row];

  if (
    !hasSubRowValue(subRows) &&
    !hasNoteReferences(row, !!props.isSinglePeriodSelected)
  ) {
    return null;
  }

  return <RowLevel3 sumRow={isSumRow} {...props} />;
};

const RowLevel3 = styled(RowLevel2)`
  .MuiTableCell-body:first-of-type {
    padding-left: ${({ theme }) => theme.spacing(0)}px;
  }
`;

const valueNearZero = (col: string, row: AgoyTableRow): boolean => {
  const value = row.cells?.[col];
  if (value === undefined) return true;
  if (value && value.type === 'ref') {
    const ref = value.value;
    if (ref !== undefined && typeof ref === 'number' && Math.abs(ref) < 1.0) {
      return true;
    }
  }
  if (value && value.type === 'number') {
    const val = value.value;
    return val ? val === 0 : true;
  }
  return false;
};

const hasNoteReferences = (
  row: AgoyTableRow,
  isSinglePeriodSelected: boolean
) => {
  const value = row.cells?.notes;
  if (value && value.type === 'refs') {
    const refs = value.references;
    if (!isSinglePeriodSelected) return refs.length > 0;
    return refs.length > 0 && !valueNearZero('year', row);
  }
  return false;
};

const BalanceSheetRowNonZeroLevel4 = (
  props: HierarchicalPrintTableRowProps
) => {
  const rowId = `${props.baseId}.${props.row.id}`;
  const hideRow =
    rowId.includes('VisuallyHidden') ||
    rowId.includes('HideInPreviewAndPrint') ||
    props.row.id === 'result';

  if (
    valueNearZero('year', props.row) &&
    valueNearZero('previousYear', props.row) &&
    !props.row.id.includes('subtitle') &&
    !hasNoteReferences(props.row, !!props.isSinglePeriodSelected)
  ) {
    return null;
  }
  return <Row3 hideRow={hideRow} {...props} />;
};

const Row3 = styled(HRowLevel3)`
  .MuiTableCell-body:first-of-type {
    padding-left: ${({ theme }) => theme.spacing(2)}px;
  }
`;

const rowComponentsPrint = [
  BalanceSheetRowLevel1,
  BalanceSheetRowLevel2,
  BalanceSheetRowLevel3,
  BalanceSheetRowNonZeroLevel4,
];

const BRRRPrintTable = ({
  className,
  table,
  tableId,
  settings,
  skipColumns = [],
  renderHeader,
  zoom = 1,
}: BRRRPrintTableProps): JSX.Element => {
  const columns = useMemo(
    () =>
      table.columns.filter((col) => {
        if (col.id === 'notes' && isSinglePeriodSelected(settings)) {
          return false;
        }
        if (skipColumns.includes(col.id)) {
          return false;
        }
        // Columns that should not be shown
        return !col.id?.includes('hidden') && !col.id.includes('Warning');
      }),
    [table.columns, settings, skipColumns]
  );

  return (
    <StyledHierarchicalTable
      className={className}
      table={table}
      tableId={tableId}
      rowComponents={rowComponentsPrint}
      columns={columns}
      hideTableHeadLabel={true}
      renderHeader={renderHeader}
      zoom={zoom}
      isSinglePeriodSelected={isSinglePeriodSelected(settings)}
    />
  );
};

export default styled(BRRRPrintTable)`
  min-width: 100%;
  overflow: hidden;

  thead tr th:last-of-type,
  tbody tr td:last-of-type {
    padding-right: ${({ theme }) =>
      theme.spacing(1 / 3)}px; // remember spacing default is 6 in print
  }

  .MuiTableCell-root:nth-of-type(2) {
    text-align: right;
    padding-left: ${({ theme }) => theme.spacing(6)}px;
  }
`;

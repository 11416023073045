import React from 'react';
import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import Button from '_shared/components/Buttons/Button';

const TableHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const ControlButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)}px;
`;

interface Props {
  title: string;
  editButtonText: string;
  editing: boolean;
  onEdit: () => void;
  onCancel: () => void;
  onSave: () => void;
  saveButtonDisabled?: boolean;
}

const ConnectionsTableHeader = ({
  title,
  editButtonText,
  editing,
  onEdit,
  onCancel,
  onSave,
  saveButtonDisabled,
}: Props) => {
  const { formatMessage } = useIntl();

  return (
    <TableHeader>
      <Typography variant="h3">{title}</Typography>
      <ControlButtons>
        {!editing && (
          <Button label={editButtonText} onClick={onEdit} variant="outlined" />
        )}
        {editing && [
          <Button
            label={formatMessage({ id: 'cancel' })}
            onClick={onCancel}
            variant="text"
          />,
          <Button
            label={formatMessage({ id: 'save.changes' })}
            disabled={saveButtonDisabled}
            onClick={onSave}
          />,
        ]}
      </ControlButtons>
    </TableHeader>
  );
};

export default ConnectionsTableHeader;

import React from 'react';
import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';

const Container = styled.div<{ groupId: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;

  .hover_group_${({ groupId }) => groupId}.hover_account_${({ groupId }) =>
      groupId}
    .groupSum_${({ groupId }) => groupId}
    & {
    > p {
      color: #007031;
    }
    background-color: #f2f4f3;
  }
`;

const Name = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.primary};
`;

export type GroupSumCellProps = {
  groupSumName: string;
  groupId: string;
};

const GroupSumCell = ({ groupSumName, groupId }: GroupSumCellProps) => {
  return (
    <Container groupId={groupId}>
      <Name>{groupSumName}</Name>
    </Container>
  );
};

export default GroupSumCell;

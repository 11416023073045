import {
  account,
  id,
  label,
  or,
  multiply,
  sum,
  ref,
  value,
  round,
  not,
  ifOrElse,
  abs,
  ReferenceCell,
} from '@agoy/document';
import { previousYear, year } from '../../common/utils/date-util';
import {
  table,
  field,
  active,
  range,
  inactiveOnReportTypeK2,
  activeReference,
} from '../../common/utils/util';
import { LimitedCompaniesNotes } from './types';
import { AnnualReportType } from '../../document';

// TODO: Move strings to translation file (sv.json)?
// With the 2021 specs from BV, new ids are being added as strings now

/**
 * Returns the sum for a specified NoteNumber for the rowIds specified
 * in the current year or previous year.
 * @param NoteNumber: Ex: 1 = Redovisningsprinciper
 * @param rowIds: array of row Ids in a note.
 * @param endId: string that represents a column's id.
 * @param table = 'table' is default. This is the usually the case when there's only one table.
 */
const sumNotesRowsById = (
  NoteNumber: number,
  rowIds: (string | number)[],
  endId:
    | 'year'
    | 'previousYear'
    | 'reportedValue'
    | 'taxValue'
    | 'temporaryDifference',
  table = 'table'
): ReferenceCell =>
  ref(
    ifOrElse(
      sum(
        ...[...rowIds].map((idNumber) => {
          return abs(
            or(id(`notes.note${NoteNumber}.${table}.${idNumber}.${endId}`), 0)
          );
        })
      ),
      sum(
        ...[...rowIds].map((idNumber) => {
          return or(
            id(`notes.note${NoteNumber}.${table}.${idNumber}.${endId}`),
            0
          );
        })
      ),
      undefined
    )
  );

/**
 * Returns the sum of all negative (Credit) transactions for the specified
 * accounts or range(s) of accounts for the client's currently selected accounting year.
 * Should not include inverse transactions in the same date for an account.
 * Example: ref(sumNegativeTransactions('1220:1227+1230:1237'))
 * @param accounts: string with account(s).
 * @optionalParam periodName: period name to be used when resolving references.
 * The default is the current financial year.
 */
const sumNegativeTransactions = (accounts: string, periodName?: string) =>
  or(sum(account(accounts, 'creditWithoutInverse', periodName), 0), 0);

/**
 * Returns the sum of all positive (Debit) transactions for the specified
 * accounts or range(s) of accounts for the client's currently selected accounting year.
 * Should not include inverse transactions in the same date for an account.
 *  Example: ref(sumPositiveTransactions('1220:1227+1230:1237'))
 * @param accounts: string with account(s).
 * @optionalParam periodName: period name to be used when resolving references.
 * The default is the current financial year.
 */
const sumPositiveTransactions = (accounts: string, periodName?: string) =>
  or(sum(account(accounts, 'debitWithoutInverse', periodName), 0), 0);

/**
 * Returns the sum of all IBs (incoming balances) for the specified
 * accounts or range(s) of accounts for the client accounting period(s).
 * Supports getting IBs from any period
 *
 * Example: ref(sumPositiveTransactions('1220:1227+1230:1237'))
 * @param accounts: string with account(s).
 * @param areAccountsOver2000: false if the accounts used go until 1999.
 * @optionalParam periodName: period name to be used when resolving references.
 * The default is the current financial year.
 */
const sumAccountsIBs = (
  accounts: string,
  areAccountsOver2000: boolean,
  periodName?: string
) =>
  or(
    sum(
      areAccountsOver2000
        ? multiply(-1, account(accounts, 'ib', periodName))
        : account(accounts, 'ib', periodName),
      0
    ),
    0
  );

/**
 * Returns the sum of all UBs (outgoing balances) for the specified
 * accounts or range(s) of accounts for the client's currently selected accounting year.
 * @param accounts: string with account(s)
 * @param areAccountsOver2000: false if the accounts used go until 1999.
 * @optionalParam periodName: period name to be used when resolving references.
 * The default is the current financial year.
 */
const sumAccountsUBs = (
  accounts: string,
  areAccountsOver2000: boolean,
  periodName?: string
) =>
  or(
    sum(
      areAccountsOver2000
        ? multiply(-1, account(accounts, 'ub', periodName))
        : account(accounts, 'ub', periodName),
      0
    ),
    0
  );

export const notesConfig = (
  financialYears: string[],
  financialYear: string,
  period: string | null,
  reportType: AnnualReportType,
  isDigitalSubmission: boolean
): LimitedCompaniesNotes => {
  const noteLabel = (name: string) =>
    isDigitalSubmission ? label(name) : value(name);

  return {
    active: undefined,
    headings: {
      active: undefined,
      number: value(undefined),
      name: value(undefined),
      notesIncomeStatementTitle: active(field('Noter till resultaträkning')),
      notesBalanceSheetTitle: active(field('Noter till balansräkning')),
      notesOtherTitle: active(field('Övriga noter')),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note1: {
      active: true,
      number: value(1),
      name: field('Redovisningsprinciper'),
      disclaimerK2: field(
        'Årsredovisningen är upprättad i enlighet med årsredovisningslagen och bokföringsnämndens allmänna råd (BFNAR2016:10) om årsredovisning i mindre företag.'
      ),
      disclaimerK3: field(
        'Årsredovisningen har upprättats i enlighet med årsredovisningslagen och Bokföringsnämndens allmänna råd BFNAR 2012:1 Årsredovisning och koncernredovisning (K3).'
      ),
      changeOfAccountingRegulationsLabel: field(
        'Byte av redovisningsregelverk'
      ),
      changeOfAccountingRegulations: field(
        'Årsredovisningen är upprättad i enlighet med årsredovisningslagen och Bokföringsnämndens allmänna råd (BFNAR 2016:10) om årsredovisning i mindre företag. Årsredovisningen upprättas för första gången i enlighet med Bokföringsnämndens allmänna råd (BFNAR 2016:10) om årsredovisning i mindre företag, vilket kan innebära en bristande jämförbarhet mellan räkenskapsåret och det närmast föregående räkenskapsåret. Jämförelsetalen har inte räknats om. Årsredovisningen har för det närmast föregående räkenskapsåret lämnats som bilaga.'
      ),
      tangibleFixedAssetsLabel: field('Materiella anläggningstillgångar'),
      tangibleFixedAssetsGeneral: field(
        'Materiella anläggningstillgångar redovisas till anskaffningsvärde med avdrag för ackumulerade avskrivningar och eventuella nedskrivningar. Tillgångarna skrivs av linjärt över tillgångarnas bedömda nyttjandeperiod förutom mark som inte skrivs av. Nyttjandeperioden omprövas per varje balansdag. Följande nyttjandeperioder tillämpas:'
      ),
      tangibleFixedAssets: table(
        'notes.note1.tangibleFixedAssets',
        'appliedDeprecationPeriods',
        'numberOfYears'
      )
        .addRows((rows) => {
          return [
            {
              id: '1',
              label: 'Byggnader: [år]',
            },
            {
              id: '2',
              label: 'Maskiner och andra tekniska anläggningar: [år]',
            },
            {
              id: '3',
              label: 'Inventarier, verktyg och installationer: [antal år]',
            },
            {
              id: '4',
              label: 'Förbättringsutgifter på annans fastighet: [år]',
            },
            {
              id: '5',
              label: 'Övriga materiella anläggningstillgångar: [år]',
            },
          ]
            .reduce(
              (rows, { id, label }) =>
                rows.addRow(id, noteLabel(label), value('')),
              rows
            )
            .build();
        })
        .newRowTemplate(value(''), value(''))
        .build(),
      receivablesLabel: inactiveOnReportTypeK2(field('Fordringar'), reportType),
      receivables: inactiveOnReportTypeK2(
        field(
          'Fordringar har upptagits till de belopp var med de beräknas inflyta.'
        ),
        reportType
      ),
      otherAssetsProvisionsAndLiabilitiesLabel: inactiveOnReportTypeK2(
        field('Övriga tillgångar, avsättningar och skulder'),
        reportType
      ),
      otherAssetsProvisionsAndLiabilities: inactiveOnReportTypeK2(
        field(
          'Övriga tillgångar, avsättningar och skulder har värderats till anskaffningsvärden om inget annat anges nedan.'
        ),
        reportType
      ),
      revenueRecognitionLabel: inactiveOnReportTypeK2(
        field('Intäktsredovisning'),
        reportType
      ),
      revenueRecognition: inactiveOnReportTypeK2(
        field(
          'Inkomsten redovisas till det verkliga värdet av vad som erhållits eller kommer att erhållas. Företaget redovisar därför inkomsten till nominellt värde (fakturabelopp) om ersättningen erhålls i likvida medel direkt vid leverans. Avdrag görs för lämnade rabatter.'
        ),
        reportType
      ),
      saleOfGoodsLabel: inactiveOnReportTypeK2(
        field('Varuförsäljning'),
        reportType
      ),
      saleOfGoods: inactiveOnReportTypeK2(
        field(
          'Försäljning av varor redovisas när företaget till köparen har överfört de väsentliga risker och förmåner som är förknippade med ägandet, normalt när kunden har varorna i sin besittning. Intäkter från försäljning av varor som inte har några betydande serviceförpliktelser redovisas vid leverans.'
        ),
        reportType
      ),
      servicesLabel: inactiveOnReportTypeK2(field('Tjänster'), reportType),
      services: inactiveOnReportTypeK2(
        field(
          'Intäkter från konsulttjänster intäktsredovisas när tjänsterna tillhandahålls.'
        ),
        reportType
      ),
      acquisitionGoodsLabel: field(
        'Anskaffningsvärde för egentillverkade varor'
      ),
      acquisitionGoods: field(
        'Företaget räknar in indirekta tillverkningskostnader i anskaffningsvärdet för egentillverkade varor. '
      ),
      changedPrinciplesLabel: field(
        'Bristande jämförbarhet vid ändrade principer'
      ),
      changedPrinciplesHint: field(
        'Beskriv posten som påverkas av ändrad redovisningsprincip och beskriv om posten redovisas till ett högre eller lägre värde. Redovisa också hur ändrad redovisningsprincip påverkar resultatet, ökat eller minskat resultat.'
      ),
      changedPrinciples: field(
        'Från och med detta räkenskapsår [...] då det ger en bättre redovisning. Den ändrade principen innebär att [ ... ]  och ger följande resultateffekt innevarande år: [ ... ].  Jämförelsetalen har inte räknats om.'
      ),
      deviationFromBasicAccountingPrinciplesLabel: field(
        'Avvikelser från de grundläggande redovisningsprinciperna'
      ),
      deviationFromBasicAccountingPrinciples: field(
        '[Följande avvikelser har gjorts från följande grundläggande redovisningsprinciper]'
      ),
      serviceAndContractingAssignmentsLabel: field(
        'Tjänste- och entreprenaduppdrag'
      ),
      serviceAndContractingAssignments: field(
        'Tjänste - och entreprenaduppdrag till fast pris redovisas enligt'
      ),
      serviceAndContractingAssignmentsParagraph1: field(
        'Företagets intäkter från uppdrag till fast pris redovisas enligt huvudregeln.'
      ),
      serviceAndContractingAssignmentsParagraph2: field(
        'Företagets intäkter från uppdrag till fast pris redovisa enligt alternativregeln. Företaget räknar in indirekta utgifter i värdet för pågående arbete för annans räkning.'
      ),
      rentsLabel: inactiveOnReportTypeK2(field('Hyror'), reportType),
      rents: inactiveOnReportTypeK2(
        field(
          'Hyresintäkter från uthyrning av företagets lokaler redovisas linjärt över leasingperioden.'
        ),
        reportType
      ),
      interestRoyaltyAndDividensLabel: inactiveOnReportTypeK2(
        field('Ränta, royalty och utdelning'),
        reportType
      ),
      interestRoyaltyAndDividens: inactiveOnReportTypeK2(
        field(
          'Ränta redovisas med tillämpning av effektiv räntemetoden. Royalty periodiseras i enlighet med den aktuella överenskommelsens ekonomiska innebörd. Utdelning redovisas när aktieägarens rätt att få utdelning bedöms som säker.'
        ),
        reportType
      ),
      tangibleAssetsCommentLabel: field('Kommentar'),
      tangibleAssetsComment: field(''),
      intangibleAssetsGeneralLabel: field('Immateriella anläggningstillgångar'),
      intangibleAssetsGeneral: field(
        'Immateriella anläggningstillgångar redovisas till anskaffningsvärde med avdrag för ackumulerade avskrivningar och eventuella nedskrivningar. Tillgångarna skrivs av linjärt över tillgångarnas bedömda nyttjande period. Nyttjandeperioden omprövas per varje balansdag. Pågående projekt skrivs inte av utan nedskrivningsprövas årligen. Följande nyttjandeperioder tillämpas:'
      ),
      intangibleAssetsTable: inactiveOnReportTypeK2(
        table('notes.note1.intangibleAssetsTable', 'empty', 'numberOfYears')
          .addRows((rows) => {
            return [
              {
                id: '1',
                label:
                  'Koncessioner, patent, licenser, varumärken samt liknande rättigheter',
              },
              {
                id: '2',
                label: 'Hyresrätter och liknande rättigheter',
              },
              {
                id: '3',
                label: 'Goodwill',
              },
              {
                id: '4',
                label:
                  'Bedömd avskrivningstid för goodwill tillämpas av följande skäl:',
              },
            ]
              .reduce(
                (rows, { id, label }) =>
                  rows.addRow(id, noteLabel(label), value('')),
                rows
              )
              .build();
          })
          .newRowTemplate(value(''), value(''))
          .build(),
        reportType
      ),
      intangibleAssetsProjectsClosed: field(
        'Inga projekt var avslutade per bokslutsdatum.'
      ),
      incomeTaxLabel: inactiveOnReportTypeK2(field('Inkomstskatt'), reportType),
      incomeTaxParagraph1: inactiveOnReportTypeK2(
        field(
          'Det förelåg inget skattepliktigt resultat efter att ej avdragsgilla kostnader och ej skattepliktiga intäkter lagts till bolagets negativa redovisningsmässiga resultat.'
        ),
        reportType
      ),
      incomeTaxParagraph2: inactiveOnReportTypeK2(
        field(
          'Uppskjuten skatt är inkomstskatt för skattepliktigt resultat avseende framtida räkenskapsår till följd av tidigare transaktioner eller händelser. Uppskjuten skatt beräknas på temporära skillnader. En temporär skillnad finns när det redovisade värdet på en tillgång eller skuld skiljer sig från det skattemässiga värdet. Temporära skillnader beaktas ej i skillnader hänförliga till investeringar i dotterföretag, filialer, intresseföretag eller joint venture om företaget kan styra tidpunkten för återföring av de temporära skillnaderna och det inte är uppenbart att den temporära skillnaden kommer att återföras inom en överskådlig framtid. Skillnader som härrör från den första redovisningen av goodwill eller vid den första redovisningen skuld eller tillgång såvida inte den hänförliga transaktionen är ett rörelseförvärv eller påverkar skatt eller redovisat resultat utgör inte heller temporära. Uppskjuten skattefordran avseende underskottsavdrag eller andra framtida skattemässiga avdrag redovisas i den omfattning det är sannolikt att avdragen kan avräknas mot framtida skattemässiga överskott.'
        ),
        reportType
      ),
      incomeTaxParagraph3: inactiveOnReportTypeK2(
        field(
          'Företaget har inga temporära skillnader för utom de som återspeglas i obeskattade reserver.'
        ),
        reportType
      ),
      incomeTaxParagraph4: inactiveOnReportTypeK2(
        field(
          'Uppskjutna skatteskulder som är hänförlig till obeskattade reserver särredovisas inte, obeskattade reserver redovisas med bruttobeloppet i balansräkningen.'
        ),
        reportType
      ),
      incomeTaxParagraph5: inactiveOnReportTypeK2(
        field(
          'För poster som redovisas i resultaträkningen redovisas där med sammanhängande skatt i resultaträkningen. För poster som redovisas direkt mot eget kapital redovisas även skatten direkt mot eget kapital.'
        ),
        reportType
      ),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note2: {
      active: false,
      number: value(undefined),
      name: field('Medelantal anställda'),
      noEmployees: activeReference(
        field('Bolaget har ej haft några anställda under verksamhetsåret.'),
        ref(not(round(account('7000:7399'))))
      ),
      avgNumberOfEmployees: activeReference(
        field(
          'Medelantalet anställda bygger på av bolaget betalda närvarotimmar relaterade till en normal arbetstid.'
        ),
        ref(round(account('7000:7399')))
      ),
      avgNumberOfEmployeesTable: table(
        'notes.note2.avgNumberOfEmployeesTable',
        { id: 'label', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        .setActive(ref(round(account('7000:7399'))))
        .addRows((rows) => {
          rows.addRow(
            '1',
            noteLabel('Medelantal anställda'),
            value(undefined),
            value(undefined)
          );
          if (!isDigitalSubmission) {
            rows
              .addRow(
                '2',
                noteLabel('Varav kvinnor'),
                value(undefined),
                value(undefined)
              )
              .addRow(
                '3',
                noteLabel('Varav män'),
                value(undefined),
                value(undefined)
              );
          }
          return rows.build();
        })
        .newRowTemplate(value(''), value(undefined), value(undefined))
        .build(),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note3: {
      active: false,
      number: value(undefined),
      name: value('Resultat från övriga finansiella anläggningstillgångar'),
      table: table(
        'notes.note3.table',
        { id: 'label', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        .addRows((rows) =>
          rows
            .addRow(
              '1',
              noteLabel('Ränteintäkter och liknande resultatposter som avser'),
              value(undefined),
              value(undefined)
            )
            .build()
        )
        .newRowTemplate(value(''), value(undefined), value(undefined))
        .build(),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note4: {
      active: false,
      number: value(undefined),
      name: value('Övriga ränteintäkter och liknande resultatposter'),
      table: table(
        'notes.note4.table',
        { id: 'label', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        .addRows((rows) =>
          rows
            .addRow(
              '1',
              noteLabel('Ränteintäkter och liknande resultatposter som avser'),
              value(undefined),
              value(undefined)
            )
            .build()
        )
        .newRowTemplate(value(''), value(undefined), value(undefined))
        .build(),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note5: {
      active: false,
      number: value(undefined),
      name: value('Räntekostnader och liknande resultatposter'),
      table: table(
        'notes.note5.table',
        { id: 'label', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        .addRows((rows) =>
          rows
            .addRow(
              '1',
              noteLabel('Räntekostnader och liknande resultatposter som avser'),
              value(undefined),
              value(undefined)
            )
            .build()
        )
        .newRowTemplate(value(''), value(undefined), value(undefined))
        .build(),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note6: {
      active: false,
      number: value(undefined),
      name: value(
        'Koncessioner, patent, licenser, varumärken samt liknande rättigheter'
      ),
      table: table(
        `notes.note6.table`,
        { id: 'label', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        // 'ingående' = previous period outgoing balance
        .addRows((rows) =>
          rows
            .addRow(
              '1',
              noteLabel('Ingående anskaffningsvärden'),
              ref(sumAccountsIBs('1020+1030+1040+1050', false)),
              ref(sumAccountsIBs('1020+1030+1040+1050', false, 'previousYear'))
            )
            .addRow(
              '2',
              noteLabel('Inköp'),
              ref(
                sumPositiveTransactions(
                  '1020:1027+1030:1037+1040:1047+1050:1057'
                )
              ),
              ref(
                sumPositiveTransactions(
                  '1020:1027+1030:1037+1040:1047+1050:1057',
                  'previousYear'
                )
              )
            )
            .addRow(
              'businessAcquisitions',
              noteLabel('Rörelseförvärv'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'anskaffningsvardenFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '3',
              noteLabel('Försäljningar/utrangeringar'),
              ref(
                sumNegativeTransactions(
                  '1020:1027+1030:1037+1040:1047+1050:1057'
                )
              ),
              ref(
                sumNegativeTransactions(
                  '1020:1027+1030:1037+1040:1047+1050:1057',
                  'previousYear'
                )
              )
            )
            .addRow(
              '4',
              noteLabel('Omklassificeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'anskaffningsvardenConversionDifferences',
              noteLabel('Omräkningsdifferenser'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '5',
              noteLabel('Utgående anskaffningsvärden'),
              sumNotesRowsById(
                6,
                [
                  ...range(1, 5),
                  'anskaffningsvardenConversionDifferences',
                  'businessAcquisitions',
                  'anskaffningsvardenFusion',
                ],
                'year'
              ),
              sumNotesRowsById(
                6,
                [
                  ...range(1, 5),
                  'anskaffningsvardenConversionDifferences',
                  'businessAcquisitions',
                  'anskaffningsvardenFusion',
                ],
                'previousYear'
              )
            )
            .addRow(
              '6',
              noteLabel('Ingående avskrivningar'),
              ref(sumAccountsIBs('1029+1039+1049+1059', false)),
              ref(sumAccountsIBs('1029+1039+1049+1059', false, 'previousYear'))
            )
            .addRow(
              'avskrivningarFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '7',
              noteLabel('Försäljningar/utrangeringar'),
              ref(sumPositiveTransactions('1029+1039+1049+1059')),
              ref(
                sumPositiveTransactions('1029+1039+1049+1059', 'previousYear')
              )
            )
            .addRow(
              '8',
              noteLabel('Omklassificeringar'), // no accounts
              value(undefined),
              value(undefined)
            )
            .addRow(
              '9',
              noteLabel('Årets avskrivningar'),
              ref(sumNegativeTransactions('1029+1039+1049+1059')),
              ref(
                sumNegativeTransactions('1029+1039+1049+1059', 'previousYear')
              )
            )
            .addRow(
              'avskrivningarConversionDifferences',
              noteLabel('Omräkningsdifferenser'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '10',
              noteLabel('Utgående avskrivningar'),
              sumNotesRowsById(
                6,
                [
                  ...range(6, 10),
                  'avskrivningarFusion',
                  'avskrivningarConversionDifferences',
                ],
                'year'
              ),
              sumNotesRowsById(
                6,
                [
                  ...range(6, 10),
                  'avskrivningarFusion',
                  'avskrivningarConversionDifferences',
                ],
                'previousYear'
              )
            )
            .addRow(
              '11',
              noteLabel('Ingående nedskrivningar'),
              ref(sumAccountsIBs('1028+1038+1048+1058', false)),
              ref(sumAccountsIBs('1028+1038+1048+1058', false, 'previousYear'))
            )
            .addRow(
              'nedskrivningarFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '12',
              noteLabel('Försäljningar/utrangeringar'),
              ref(sumPositiveTransactions('1028+1038+1048+1058')),
              ref(
                sumPositiveTransactions('1028+1038+1048+1058', 'previousYear')
              )
            )
            .addRow(
              '13',
              noteLabel('Återförda nedskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '14',
              noteLabel('Omklassificeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '15',
              noteLabel('Årets nedskrivningar'),
              ref(sumNegativeTransactions('1028+1038+1048+1058')),
              ref(
                sumNegativeTransactions('1028+1038+1048+1058', 'previousYear')
              )
            )
            .addRow(
              'nedskrivningarConversionDifferences',
              noteLabel('Omräkningsdifferenser'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '16',
              noteLabel('Utgående nedskrivningar'),
              sumNotesRowsById(
                6,
                [
                  ...range(11, 16),
                  'nedskrivningarFusion',
                  'nedskrivningarConversionDifferences',
                ],
                'year'
              ),
              sumNotesRowsById(
                6,
                [
                  ...range(11, 16),
                  'nedskrivningarFusion',
                  'nedskrivningarConversionDifferences',
                ],
                'previousYear'
              )
            )
            .addRow(
              '17',
              noteLabel('Utgående Redovisat värde'),
              sumNotesRowsById(6, [5, 10, 16], 'year'),
              sumNotesRowsById(6, [5, 10, 16], 'previousYear')
            )
            // TODO: how should we represent changes in acquisition values / Förändringar av anskaffningsvärden
            .addRow(
              '18',
              noteLabel('Förändringar av anskaffningsvärden'),
              ref(sumAccountsIBs('1020+1030+1040+1050', false)),
              ref(sumAccountsIBs('1020+1030+1040+1050', false, 'previousYear'))
            )
            // TODO: how should we represent Förändringar av avskrivningar
            .addRow(
              '19',
              noteLabel('Förändringar av avskrivningar'),
              ref(sumAccountsIBs('1029+1039+1049+1059', false)),
              ref(sumAccountsIBs('1029+1039+1049+1059', false, 'previousYear'))
            )
            // TODO: how should we represent Förändringar av nedskrivningar
            .addRow(
              '20',
              noteLabel('Förändringar av nedskrivningar'),
              ref(sumAccountsIBs('1028+1038+1048+1058', false)),
              ref(sumAccountsIBs('1028+1038+1048+1058', false, 'previousYear'))
            )
            .build()
        )
        .newRowTemplate(value(''), value(undefined), value(undefined))
        .build(),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note7: {
      active: false,
      number: value(undefined),
      name: value('Hyresrätter och liknande rättigheter'),
      table: table(
        `notes.note7.table`,
        { id: 'label', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        // 'ingående' = previous period outgoing balance
        .addRows((rows) =>
          rows
            .addRow(
              '1',
              noteLabel('Ingående anskaffningsvärden'),
              ref(sumAccountsIBs('1060', false)),
              ref(sumAccountsIBs('1060', false, 'previousYear'))
            )
            .addRow(
              '2',
              noteLabel('Inköp'),
              ref(sumPositiveTransactions('1060:1067')),
              ref(sumPositiveTransactions('1060:1067', 'previousYear'))
            )
            .addRow(
              'businessAcquisitions',
              noteLabel('Rörelseförvärv'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'anskaffningsvardenFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '3',
              noteLabel('Försäljningar/utrangeringar'),
              ref(sumNegativeTransactions('1060:1067')),
              ref(sumNegativeTransactions('1060:1067', 'previousYear'))
            )
            .addRow(
              '4',
              noteLabel('Omklassificeringar'), // no accounts
              value(undefined),
              value(undefined)
            )
            .addRow(
              'anskaffningsvardenConversionDifferences',
              noteLabel('Omräkningsdifferenser'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '5',
              noteLabel('Utgående anskaffningsvärden'),
              sumNotesRowsById(
                7,
                [
                  ...range(1, 5),
                  'businessAcquisitions',
                  'anskaffningsvardenFusion',
                  'anskaffningsvardenConversionDifferences',
                ],
                'year'
              ),
              sumNotesRowsById(
                7,
                [
                  ...range(1, 5),
                  'businessAcquisitions',
                  'anskaffningsvardenFusion',
                  'anskaffningsvardenConversionDifferences',
                ],
                'previousYear'
              )
            )
            .addRow(
              '6',
              noteLabel('Ingående avskrivningar'),
              ref(sumAccountsIBs('1069', false)),
              ref(sumAccountsIBs('1069', false, 'previousYear'))
            )
            .addRow(
              'avskrivningarFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '7',
              noteLabel('Försäljningar/utrangeringar'),
              ref(sumPositiveTransactions('1069')),
              ref(sumPositiveTransactions('1069', 'previousYear'))
            )
            .addRow(
              '8',
              noteLabel('Omklassificeringar'), // no accounts
              value(undefined),
              value(undefined)
            )
            .addRow(
              '9',
              noteLabel('Årets avskrivningar'),
              ref(sumNegativeTransactions('1069')),
              ref(sumNegativeTransactions('1069', 'previousYear'))
            )
            .addRow(
              'avskrivningarConversionDifferences',
              noteLabel('Omräkningsdifferenser'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '10',
              noteLabel('Utgående avskrivningar'),
              sumNotesRowsById(
                7,
                [
                  ...range(6, 10),
                  'avskrivningarFusion',
                  'avskrivningarConversionDifferences',
                ],
                'year'
              ),
              sumNotesRowsById(
                7,
                [
                  ...range(6, 10),
                  'avskrivningarFusion',
                  'avskrivningarConversionDifferences',
                ],
                'previousYear'
              )
            )
            .addRow(
              '11',
              noteLabel('Ingående nedskrivningar'),
              ref(sumAccountsIBs('1068', false)),
              ref(sumAccountsIBs('1068', false, 'previousYear'))
            )
            .addRow(
              'nedskrivningarFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '12',
              noteLabel('Försäljningar/utrangeringar'),
              ref(sumPositiveTransactions('1068')),
              ref(sumPositiveTransactions('1068', 'previousYear')),
              value(undefined)
            )
            .addRow(
              '13',
              noteLabel('Återförda nedskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '14',
              noteLabel('Omklassificeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '15',
              noteLabel('Årets nedskrivningar'),
              ref(sumNegativeTransactions('1068')),
              ref(sumNegativeTransactions('1068', 'previousYear'))
            )
            .addRow(
              'nedskrivningarConversionDifferences',
              noteLabel('Omräkningsdifferenser'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '16',
              noteLabel('Utgående nedskrivningar'),
              sumNotesRowsById(
                7,
                [
                  ...range(11, 16),
                  'nedskrivningarFusion',
                  'nedskrivningarConversionDifferences',
                ],
                'year'
              ),
              sumNotesRowsById(
                7,
                [
                  ...range(11, 16),
                  'nedskrivningarFusion',
                  'nedskrivningarConversionDifferences',
                ],
                'previousYear'
              )
            )
            .addRow(
              '17',
              noteLabel('Utgående Redovisat värde'),
              sumNotesRowsById(7, [5, 10, 16], 'year'),
              sumNotesRowsById(7, [5, 10, 16], 'previousYear')
            )
            // TODO: how should we represent changes in acquisition values / Förändringar av anskaffningsvärden
            .addRow(
              '18',
              noteLabel('Förändringar av anskaffningsvärden'),
              ref(sumAccountsIBs('1060', false)),
              ref(sumAccountsIBs('1060', false, 'previousYear'))
            )
            // TODO: how should we represent Förändringar av avskrivningar
            .addRow(
              '19',
              noteLabel('Förändringar av avskrivningar'),
              ref(sumAccountsIBs('1069', false)),
              ref(sumAccountsIBs('1069', false, 'previousYear'))
            )
            // TODO: how should we represent Förändringar av nedskrivningar
            .addRow(
              '20',
              noteLabel('Förändringar av nedskrivningar'),
              ref(sumAccountsIBs('1068', false)),
              ref(sumAccountsIBs('1068', false, 'previousYear'))
            )
            .build()
        )
        .newRowTemplate(value(''), value(undefined), value(undefined))
        .build(),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note8: {
      active: false,
      number: value(undefined),
      name: value('Goodwill'),
      table: table(
        `notes.note8.table`,
        { id: 'label', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        .addRows((rows) =>
          rows
            // 'ingående' = previous period outgoing balance
            .addRow(
              '1',
              noteLabel('Ingående anskaffningsvärden'),
              ref(sumAccountsIBs('1070', false)),
              ref(sumAccountsIBs('1070', false, 'previousYear'))
            )
            // In this note, Rörelseförvärv now contains what inköp used to contain
            .addRow(
              '2',
              noteLabel('Rörelseförvärv'),
              ref(sumPositiveTransactions('1070:1077')),
              ref(sumPositiveTransactions('1070:1077', 'previousYear'))
            )
            .addRow(
              'anskaffningsvardenFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '3',
              noteLabel('Försäljningar/utrangeringar'),
              ref(sumNegativeTransactions('1070:1077')),
              ref(sumNegativeTransactions('1070:1077', 'previousYear'))
            )
            .addRow(
              '4',
              noteLabel('Omklassificeringar'), // no accounts
              value(undefined),
              value(undefined)
            )
            .addRow(
              'anskaffningsvardenConversionDifferences',
              noteLabel('Omräkningsdifferenser'), // no accounts
              value(undefined),
              value(undefined)
            )
            .addRow(
              '5',
              noteLabel('Utgående anskaffningsvärden'),
              sumNotesRowsById(
                8,
                [
                  ...range(1, 5),
                  'anskaffningsvardenFusion',
                  'anskaffningsvardenConversionDifferences',
                ],
                'year'
              ),
              sumNotesRowsById(
                8,
                [
                  ...range(1, 5),
                  'anskaffningsvardenFusion',
                  'anskaffningsvardenConversionDifferences',
                ],
                'previousYear'
              )
            )
            .addRow(
              '6',
              noteLabel('Ingående avskrivningar'),
              ref(sumAccountsIBs('1079', false)),
              ref(sumAccountsIBs('1079', false, 'previousYear'))
            )
            .addRow(
              'avskrivningarFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '7',
              noteLabel('Försäljningar/utrangeringar'),
              ref(sumPositiveTransactions('1079')),
              ref(sumPositiveTransactions('1079', 'previousYear'))
            )
            .addRow(
              '8',
              noteLabel('Omklassificeringar'), // no accounts
              value(undefined),
              value(undefined)
            )
            .addRow(
              '9',
              noteLabel('Årets avskrivningar'),
              ref(sumNegativeTransactions('1079')),
              ref(sumNegativeTransactions('1079', 'previousYear'))
            )
            .addRow(
              'avskrivningarConversionDifferences',
              noteLabel('Omräkningsdifferenser'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '10',
              noteLabel('Utgående avskrivningar'),
              sumNotesRowsById(
                8,
                [
                  ...range(6, 10),
                  'avskrivningarFusion',
                  'avskrivningarConversionDifferences',
                ],
                'year'
              ),
              sumNotesRowsById(
                8,
                [
                  ...range(6, 10),
                  'avskrivningarFusion',
                  'avskrivningarConversionDifferences',
                ],
                'previousYear'
              )
            )
            .addRow(
              '11',
              noteLabel('Ingående nedskrivningar'),
              ref(sumAccountsIBs('1078', false)),
              ref(sumAccountsIBs('1078', false, 'previousYear'))
            )
            .addRow(
              'nedskrivningarFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '12',
              noteLabel('Försäljningar/utrangeringar'),
              ref(sumPositiveTransactions('1078')),
              ref(sumPositiveTransactions('1078', 'previousYear'))
            )
            .addRow(
              '13',
              noteLabel('Återförda nedskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '14',
              noteLabel('Omklassificeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '15',
              noteLabel('Årets nedskrivningar'),
              ref(sumNegativeTransactions('1078')),
              ref(sumNegativeTransactions('1078', 'previousYear'))
            )
            .addRow(
              'nedskrivningarConversionDifferences',
              noteLabel('Omräkningsdifferenser'),
              value(undefined),
              value(undefined)
            )

            .addRow(
              '16',
              noteLabel('Utgående nedskrivningar'),
              sumNotesRowsById(
                8,
                [
                  ...range(11, 16),
                  'nedskrivningarFusion',
                  'nedskrivningarConversionDifferences',
                ],
                'year'
              ),
              sumNotesRowsById(
                8,
                [
                  ...range(11, 16),
                  'nedskrivningarFusion',
                  'nedskrivningarConversionDifferences',
                ],
                'previousYear'
              )
            )
            .addRow(
              '17',
              noteLabel('Redovisat värde'),
              sumNotesRowsById(8, [5, 10, 16], 'year'),
              sumNotesRowsById(8, [5, 10, 16], 'previousYear')
            )
            // TODO: how should we represent changes in acquisition values / Förändringar av anskaffningsvärden
            .addRow(
              '18',
              noteLabel('Förändringar av anskaffningsvärden'),
              ref(sumAccountsIBs('1070', false)),
              ref(sumAccountsIBs('1070', false, 'previousYear'))
            )
            // TODO: how should we represent Förändringar av avskrivningar
            .addRow(
              '19',
              noteLabel('Förändringar av avskrivningar'),
              ref(sumAccountsIBs('1079', false)),
              ref(sumAccountsIBs('1079', false, 'previousYear'))
            )
            // TODO: how should we represent Förändringar av nedskrivningar
            .addRow(
              '20',
              noteLabel('Förändringar av nedskrivningar'),
              ref(sumAccountsIBs('1078', false)),
              ref(sumAccountsIBs('1078', false, 'previousYear'))
            )
            .build()
        )
        .newRowTemplate(value(''), value(undefined), value(undefined))
        .build(),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note9: {
      active: false,
      number: value(undefined),
      name: value('Förskott avseende immateriella anläggningstillgångar'),
      table: table(
        'notes.note9.table',
        { id: 'label', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        // 'ingående' = previous period outgoing balance
        .addRows((rows) =>
          rows
            .addRow(
              '1',
              noteLabel('Ingående anskaffningsvärden'),
              ref(sumAccountsIBs('1080:1088', false)),
              ref(sumAccountsIBs('1080:1088', false, 'previousYear'))
            )
            .addRow(
              'advancesPaid',
              noteLabel('Utbetalda förskott'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'businessAcquisitions',
              noteLabel('Rörelseförvärv'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'anskaffningsvardenFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            // the order changed, but the old id must remain
            .addRow(
              '3',
              noteLabel('Återbetalda förskott'),
              ref(sumNegativeTransactions('1080:1089')),
              ref(sumNegativeTransactions('1080:1089', 'previousYear'))
            )
            .addRow(
              'anskaffningsvardenWrittenAdvances',
              noteLabel('Bortskrivna förskott'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '2',
              noteLabel('Omklassificeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'anskaffningsvardenConversionDifferences',
              noteLabel('Omräkningsdifferenser'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'anskaffningsvardenSum',
              noteLabel('Utgående anskaffningsvärden'),
              sumNotesRowsById(
                9,
                [
                  ...range(1, 4),
                  'advancesPaid',
                  'businessAcquisitions',
                  'anskaffningsvardenFusion',
                  'anskaffningsvardenConversionDifferences',
                ],
                'year'
              ),
              sumNotesRowsById(
                9,
                [
                  ...range(1, 4),
                  'advancesPaid',
                  'businessAcquisitions',
                  'anskaffningsvardenFusion',
                  'anskaffningsvardenConversionDifferences',
                ],
                'previousYear'
              )
            )
            .addRow(
              'incomingWriteDowns',
              noteLabel('Ingående nedskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'nedskrivningarFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'nedskrivningarReversal',
              noteLabel('Återförda nedskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'nedskrivningarWrittenAdvances',
              noteLabel('Bortskrivna förskott'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'nedskrivningarReclassifications',
              noteLabel('Omklassificeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'nedskrivningarYearWriteDowns',
              noteLabel('Årets nedskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'nedskrivningarConversionDifferences',
              noteLabel('Omräkningsdifferenser'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'nedskrivningarSum',
              noteLabel('Utgående nedskrivningar'),
              sumNotesRowsById(
                9,
                [
                  'incomingWriteDowns',
                  'nedskrivningarFusion',
                  'nedskrivningarReversal',
                  'nedskrivningarWrittenAdvances',
                  'nedskrivningarReclassifications',
                  'nedskrivningarYearWriteDowns',
                  'nedskrivningarConversionDifferences',
                ],
                'year'
              ),
              sumNotesRowsById(
                9,
                [
                  'incomingWriteDowns',
                  'nedskrivningarFusion',
                  'nedskrivningarReversal',
                  'nedskrivningarWrittenAdvances',
                  'nedskrivningarReclassifications',
                  'nedskrivningarYearWriteDowns',
                  'nedskrivningarConversionDifferences',
                ],
                'previousYear'
              )
            )
            .addRow(
              'totalSum',
              noteLabel('Redovisat värde'),
              sumNotesRowsById(
                9,
                ['anskaffningsvardenSum', 'nedskrivningarSum'],
                'year'
              ),
              sumNotesRowsById(
                9,
                ['anskaffningsvardenSum', 'nedskrivningarSum'],
                'previousYear'
              )
            )
            // TODO: how should we represent changes in acquisition values / Förändringar av anskaffningsvärden
            .addRow(
              '18',
              noteLabel('Förändringar av anskaffningsvärden'),
              ref(sumAccountsIBs('1080:1088', false)),
              ref(sumAccountsIBs('1080:1088', false, 'previousYear'))
            )
            .build()
        )
        .newRowTemplate(value(''), value(undefined), value(undefined))
        .build(),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note10: {
      active: false,
      number: value(undefined),
      name: value('Byggnader och mark'),
      table: table(
        `notes.note10.table`,
        { id: 'label', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        // 'ingående' = previous period outgoing balance
        .addRows((rows) =>
          rows
            .addRow(
              '1',
              noteLabel('Ingående anskaffningsvärden'),
              ref(sumAccountsIBs('1100:1117+1150:1157', false)),
              ref(sumAccountsIBs('1100:1117+1150:1157', false, 'previousYear'))
            )
            .addRow(
              '2',
              noteLabel('Inköp'),
              ref(
                sumPositiveTransactions(
                  '1100:1117+1130:1139+1140:1149+1150:1157'
                )
              ),
              ref(
                sumPositiveTransactions(
                  '1100:1117+1130:1139+1140:1149+1150:1157',
                  'previousYear'
                )
              )
            )
            .addRow(
              'businessAcquisitions',
              noteLabel('Rörelseförvärv'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'anskaffningsvardenFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '3',
              noteLabel('Försäljningar/utrangeringar'),
              ref(
                sumNegativeTransactions(
                  '1100:1117+1130:1139+1140:1149+1150:1157'
                )
              ),
              ref(
                sumNegativeTransactions(
                  '1100:1117+1130:1139+1140:1149+1150:1157',
                  'previousYear'
                )
              )
            )
            .addRow(
              '4',
              noteLabel('Omklassificeringar'), // no accounts
              value(undefined),
              value(undefined)
            )
            .addRow(
              'anskaffningsvardenConversionDifferences',
              noteLabel('Omräkningsdifferenser'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '5',
              noteLabel('Utgående anskaffningsvärden'),
              sumNotesRowsById(
                10,
                [
                  ...range(1, 5),
                  'businessAcquisitions',
                  'anskaffningsvardenFusion',
                  'anskaffningsvardenConversionDifferences',
                ],
                'year'
              ),
              sumNotesRowsById(
                10,
                [
                  ...range(1, 5),
                  'businessAcquisitions',
                  'anskaffningsvardenFusion',
                  'anskaffningsvardenConversionDifferences',
                ],
                'previousYear'
              )
            )
            .addRow(
              '6',
              noteLabel('Ingående avskrivningar'),
              ref(sumAccountsIBs('1119+1129+1159', false)),
              ref(sumAccountsIBs('1119+1129+1159', false, 'previousYear'))
            )
            .addRow(
              'avskrivningarFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '7',
              noteLabel('Försäljningar/utrangeringar'),
              ref(sumPositiveTransactions('1119+1159')),
              ref(sumPositiveTransactions('1119+1159', 'previousYear'))
            )
            .addRow(
              '8',
              noteLabel('Omklassificeringar'), // no accounts
              value(undefined),
              value(undefined)
            )
            .addRow(
              '9',
              noteLabel('Årets avskrivningar'),
              ref(sumNegativeTransactions('1119+1159')),
              ref(sumNegativeTransactions('1119+1159', 'previousYear'))
            )
            .addRow(
              'avskrivningarConversionDifferences',
              noteLabel('Omräkningsdifferenser'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '10',
              noteLabel('Utgående avskrivningar'),
              sumNotesRowsById(
                10,
                [
                  ...range(6, 10),
                  'avskrivningarFusion',
                  'avskrivningarConversionDifferences',
                ],
                'year'
              ),
              sumNotesRowsById(
                10,
                [
                  ...range(6, 10),
                  'avskrivningarFusion',
                  'avskrivningarConversionDifferences',
                ],
                'previousYear'
              )
            )
            .addRow(
              'incomingRevaluations',
              noteLabel('Ingående uppskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'uppskrivningarFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'uppskrivningarSales',
              noteLabel('Försäljningar/utrangeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'uppskrivningarReclassifications',
              noteLabel('Omklassificeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'uppskrivningarYearRevaluations',
              noteLabel('Årets uppskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'uppskrivningarDepreciation',
              noteLabel('Årets avskrivningar på uppskrivet belopp'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'uppskrivningarConversionDifferences',
              noteLabel('Omräkningsdifferenser'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'uppskrivningarSum',
              noteLabel('Utgående uppskrivningar'),
              sumNotesRowsById(
                10,
                [
                  'incomingRevaluations',
                  'uppskrivningarFusion',
                  'uppskrivningarSales',
                  'uppskrivningarReclassifications',
                  'uppskrivningarYearRevaluations',
                  'uppskrivningarDepreciation',
                  'uppskrivningarConversionDifferences',
                ],
                'year'
              ),
              sumNotesRowsById(
                10,
                [
                  'incomingRevaluations',
                  'uppskrivningarFusion',
                  'uppskrivningarSales',
                  'uppskrivningarReclassifications',
                  'uppskrivningarYearRevaluations',
                  'uppskrivningarDepreciation',
                  'uppskrivningarConversionDifferences',
                ],
                'previousYear'
              )
            )
            .addRow(
              '11',
              noteLabel('Ingående nedskrivningar'),
              ref(sumAccountsIBs('1118+1158', false)),
              ref(sumAccountsIBs('1118+1158', false, 'previousYear'))
            )
            .addRow(
              'nedskrivningarFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '12',
              noteLabel('Försäljningar/utrangeringar'),
              ref(sumPositiveTransactions('1118+1158')),
              ref(sumPositiveTransactions('1118+1158', 'previousYear'))
            )
            .addRow(
              '13',
              noteLabel('Återförda nedskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '14',
              noteLabel('Omklassificeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '15',
              noteLabel('Årets nedskrivningar'),
              ref(sumNegativeTransactions('1118+1158')),
              ref(sumNegativeTransactions('1118+1158', 'previousYear'))
            )
            .addRow(
              'nedskrivningarConversionDifferences',
              noteLabel('Omräkningsdifferenser'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '16',
              noteLabel('Utgående nedskrivningar'),
              sumNotesRowsById(
                10,
                [
                  ...range(11, 16),
                  'nedskrivningarFusion',
                  'nedskrivningarConversionDifferences',
                ],
                'year'
              ),
              sumNotesRowsById(
                10,
                [
                  ...range(11, 16),
                  'nedskrivningarFusion',
                  'nedskrivningarConversionDifferences',
                ],
                'previousYear'
              )
            )
            .addRow(
              '17',
              noteLabel('Redovisat värde'),
              sumNotesRowsById(10, [5, 10, 16, 'uppskrivningarSum'], 'year'),
              sumNotesRowsById(
                10,
                [5, 10, 16, 'uppskrivningarSum'],
                'previousYear'
              )
            )
            .addRow(
              '18',
              noteLabel('Mark'),
              ref(sumAccountsIBs('1130:1139+1140:1149', false)),
              ref(sumAccountsIBs('1130:1139+1140:1149', false, 'previousYear'))
            )
            .addRow(
              '19',
              noteLabel('Mark är inte föremål för avskrivningar.'),
              value(undefined),
              value(undefined)
            )
            // TODO: how should we represent changes in acquisition values / Förändringar av anskaffningsvärden
            .addRow(
              '20',
              noteLabel('Förändringar av anskaffningsvärden'),
              ref(sumAccountsIBs('1100:1117+1150:1157', false)),
              ref(sumAccountsIBs('1100:1117+1150:1157', false, 'previousYear'))
            )
            // TODO: how should we represent Förändringar av avskrivningar
            .addRow(
              '21',
              noteLabel('Förändringar av avskrivningar'),
              ref(sumAccountsIBs('1119+1159', false)),
              ref(sumAccountsIBs('1119+1159', false, 'previousYear'))
            )
            // TODO: how should we represent Förändringar av nedskrivningar
            .addRow(
              '22',
              noteLabel('Förändringar av nedskrivningar'),
              ref(sumAccountsIBs('1118+1158', false)),
              ref(sumAccountsIBs('1118+1158', false, 'previousYear'))
            )
            // TODO" which accounts should we sum up ?
            .addRow(
              '23',
              noteLabel('Förändringar av uppskrivningar'),
              undefined,
              undefined
            )
            // AGOY-3022 DO NOT USE IDs of 18
            // or higher in this note, there used to be data stored until id 25
            .build()
        )
        .newRowTemplate(value(''), value(undefined), value(undefined))
        .build(),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note11: {
      active: false,
      number: value(undefined),
      name: value('Maskiner och andra tekniska anläggningar'),
      table: table(
        `notes.note11.table`,
        { id: 'label', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        .addRows((rows) =>
          rows
            // 'ingående' = previous period outgoing balance
            .addRow(
              '1',
              noteLabel('Ingående anskaffningsvärden'),
              ref(
                sumAccountsIBs(
                  '1210:1211+1213:1220+1221:1222+1223:1225+1230:1231+1232:1240+1241:1242+1243:1244+1245:1246+1247:1250+1251:1257',
                  false
                )
              ),
              ref(
                sumAccountsIBs(
                  '1210:1211+1213:1220+1221:1222+1223:1225+1230:1231+1232:1240+1241:1242+1243:1244+1245:1246+1247:1250+1251:1257',
                  false,
                  'previousYear'
                )
              )
            )
            .addRow(
              '2',
              noteLabel('Inköp'),
              ref(sumPositiveTransactions('1200:1217')),
              ref(sumPositiveTransactions('1200:1217', 'previousYear'))
            )
            .addRow(
              'businessAcquisitions',
              noteLabel('Rörelseförvärv'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'anskaffningsvardenFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '3',
              noteLabel('Försäljningar/utrangeringar'),
              ref(sumNegativeTransactions('1200:1217')),
              ref(sumNegativeTransactions('1200:1217', 'previousYear'))
            )
            .addRow(
              '4',
              noteLabel('Omklassificeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'anskaffningsvardenConversionDifferences',
              noteLabel('Omräkningsdifferenser'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '5',
              noteLabel('Utgående anskaffningsvärden'),
              sumNotesRowsById(
                11, // TODO: is this correct?
                [
                  ...range(1, 5),
                  'businessAcquisitions',
                  'anskaffningsvardenFusion',
                  'anskaffningsvardenConversionDifferences',
                ],
                'year'
              ),
              sumNotesRowsById(
                11,
                [
                  ...range(1, 5),
                  'businessAcquisitions',
                  'anskaffningsvardenFusion',
                  'anskaffningsvardenConversionDifferences',
                ],
                'previousYear'
              )
            )
            .addRow(
              '6',
              noteLabel('Ingående avskrivningar'),
              ref(sumAccountsIBs('1219:1249+1259:1269', false)),
              ref(sumAccountsIBs('1219:1249+1259:1269', false, 'previousYear'))
            )
            .addRow(
              'avskrivningarFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '7',
              noteLabel('Försäljningar/utrangeringar'),
              ref(sumPositiveTransactions('1219')),
              ref(sumPositiveTransactions('1219', 'previousYear'))
            )
            .addRow(
              '8',
              noteLabel('Omklassificeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '9',
              noteLabel('Årets avskrivningar'),
              ref(sumNegativeTransactions('1219')),
              ref(sumNegativeTransactions('1219', 'previousYear'))
            )
            .addRow(
              'avskrivningarConversionDifferences',
              noteLabel('Omräkningsdifferenser'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '10',
              noteLabel('Utgående avskrivningar'),
              sumNotesRowsById(
                12,
                [
                  ...range(6, 10),
                  'avskrivningarFusion',
                  'avskrivningarConversionDifferences',
                ],
                'year'
              ),
              sumNotesRowsById(
                12,
                [
                  ...range(6, 10),
                  'avskrivningarFusion',
                  'avskrivningarConversionDifferences',
                ],
                'previousYear'
              )
            )
            .addRow(
              '11',
              noteLabel('Ingående nedskrivningar'),
              ref(sumAccountsIBs('1218:1248+1258:1269', false)),
              ref(sumAccountsIBs('1218:1248+1258:1269', false, 'previousYear'))
            )
            .addRow(
              'nedskrivningarFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '12',
              noteLabel('Försäljningar/utrangeringar'),
              ref(sumPositiveTransactions('1218')),
              ref(sumPositiveTransactions('1218', 'previousYear'))
            )
            .addRow(
              '13',
              noteLabel('Återförda nedskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '14',
              noteLabel('Omklassificeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '15',
              noteLabel('Årets nedskrivningar'),
              ref(sumNegativeTransactions('1218')),
              ref(sumNegativeTransactions('1218', 'previousYear'))
            )
            .addRow(
              'nedskrivningarConversionDifferences',
              noteLabel('Omklassificeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '16',
              noteLabel('Utgående nedskrivningar'),
              sumNotesRowsById(
                11,
                [
                  ...range(11, 16),
                  'nedskrivningarFusion',
                  'nedskrivningarConversionDifferences',
                ],
                'year'
              ),
              sumNotesRowsById(
                11,
                [
                  ...range(11, 16),
                  'nedskrivningarFusion',
                  'nedskrivningarConversionDifferences',
                ],
                'previousYear'
              )
            )
            .addRow(
              '17',
              noteLabel('Redovisat värde'),
              sumNotesRowsById(11, [5, 10, 16], 'year'),
              sumNotesRowsById(11, [5, 10, 16], 'previousYear')
            )
            // TODO: how should we represent changes in acquisition values / Förändringar av anskaffningsvärden
            .addRow(
              '18',
              noteLabel('Förändringar av anskaffningsvärden'),
              ref(
                sumAccountsIBs(
                  '1210:1211+1213:1220+1221:1222+1223:1225+1230:1231+1232:1240+1241:1242+1243:1244+1245:1246+1247:1250+1251:1257',
                  false
                )
              ),
              ref(
                sumAccountsIBs(
                  '1210:1211+1213:1220+1221:1222+1223:1225+1230:1231+1232:1240+1241:1242+1243:1244+1245:1246+1247:1250+1251:1257',
                  false,
                  'previousYear'
                )
              )
            )
            // TODO: how should we represent Förändringar av avskrivningar
            .addRow(
              '19',
              noteLabel('Förändringar av avskrivningar'),
              ref(sumAccountsIBs('1219', false)),
              ref(sumAccountsIBs('1219', false, 'previousYear'))
            )
            // TODO: how should we represent Förändringar av nedskrivningar
            .addRow(
              '20',
              noteLabel('Förändringar av nedskrivningar'),
              ref(sumAccountsIBs('1218', false)),
              ref(sumAccountsIBs('1218', false, 'previousYear'))
            )
            .build()
        )
        .newRowTemplate(value(''), value(undefined), value(undefined))
        .build(),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note12: {
      active: false,
      number: value(undefined),
      name: value('Inventarier, verktyg och installationer'),
      table: table(
        `notes.note12.table`,
        { id: 'label', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        .addRows((rows) =>
          rows
            // 'ingående' = previous period outgoing balance
            .addRow(
              '1',
              noteLabel('Ingående anskaffningsvärden'),
              ref(sumAccountsIBs('1220:1227+1230:1237+1240:1247', false)),
              ref(
                sumAccountsIBs(
                  '1220:1227+1230:1237+1240:1247',
                  false,
                  'previousYear'
                )
              )
            )
            .addRow(
              '2',
              noteLabel('Inköp'),
              ref(
                sumPositiveTransactions(
                  '1220:1227+1230:1237+1240:1247+1250:1257'
                )
              ),
              ref(
                sumPositiveTransactions(
                  '1220:1227+1230:1237+1240:1247+1250:1257',
                  'previousYear'
                )
              )
            )
            .addRow(
              'businessAcquisitions',
              noteLabel('Rörelseförvärv'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'anskaffningsvardenFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '3',
              noteLabel('Försäljningar/utrangeringar'),
              ref(
                sumNegativeTransactions(
                  '1220:1227+1230:1237+1240:1247+1250:1257'
                )
              ),
              ref(
                sumNegativeTransactions(
                  '1220:1227+1230:1237+1240:1247+1250:1257',
                  'previousYear'
                )
              )
            )
            .addRow(
              '4',
              noteLabel('Omklassificeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'anskaffningsvardenConversionDifferences',
              noteLabel('Omräkningsdifferenser'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '5',
              noteLabel('Utgående anskaffningsvärden'),
              sumNotesRowsById(
                12,
                [
                  ...range(1, 5),
                  'businessAcquisitions',
                  'anskaffningsvardenFusion',
                  'anskaffningsvardenConversionDifferences',
                ],
                'year'
              ),
              sumNotesRowsById(
                12,
                [
                  ...range(1, 5),
                  'businessAcquisitions',
                  'anskaffningsvardenFusion',
                  'anskaffningsvardenConversionDifferences',
                ],
                'previousYear'
              )
            )
            .addRow(
              '6',
              noteLabel('Ingående avskrivningar'),
              ref(sumAccountsIBs('1229+1239+1249', false)),
              ref(sumAccountsIBs('1229+1239+1249', false, 'previousYear'))
            )
            .addRow(
              'avskrivningarFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '7',
              noteLabel('Försäljningar/utrangeringar'),
              ref(sumPositiveTransactions('1229+1239+1249+1259')),
              ref(
                sumPositiveTransactions('1229+1239+1249+1259', 'previousYear')
              )
            )
            .addRow(
              '8',
              noteLabel('Omklassificeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '9',
              noteLabel('Årets avskrivningar'),
              ref(sumNegativeTransactions('1229+1239+1249+1259')),
              ref(
                sumNegativeTransactions('1229+1239+1249+1259', 'previousYear')
              )
            )
            .addRow(
              'avskrivningarConversionDifferences',
              noteLabel('Omräkningsdifferenser'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '10',
              noteLabel('Utgående avskrivningar'),
              sumNotesRowsById(
                12,
                [
                  ...range(6, 10),
                  'avskrivningarFusion',
                  'avskrivningarConversionDifferences',
                ],
                'year'
              ),
              sumNotesRowsById(
                12,
                [
                  ...range(6, 10),
                  'avskrivningarFusion',
                  'avskrivningarConversionDifferences',
                ],
                'previousYear'
              )
            )
            .addRow(
              '11',
              noteLabel('Ingående nedskrivningar'),
              ref(sumAccountsIBs('1228+1238+1248+1258', false)),
              ref(sumAccountsIBs('1228+1238+1248+1258', false, 'previousYear'))
            )
            .addRow(
              'nedskrivningarFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '12',
              noteLabel('Försäljningar/utrangeringar'),
              ref(sumPositiveTransactions('1228+1238+1248+1258')),
              ref(
                sumPositiveTransactions('1228+1238+1248+1258', 'previousYear')
              )
            )
            .addRow(
              '13',
              noteLabel('Återförda nedskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '14',
              noteLabel('Omklassificeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '15',
              noteLabel('Årets nedskrivningar'),
              ref(sumNegativeTransactions('1228+1238+1248+1258')),
              ref(
                sumNegativeTransactions('1228+1238+1248+1258', 'previousYear')
              )
            )
            .addRow(
              'nedskrivningarConversionDifferences',
              noteLabel('Omräkningsdifferenser'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '16',
              noteLabel('Utgående nedskrivningar'),
              sumNotesRowsById(
                12,
                [
                  ...range(11, 16),
                  'nedskrivningarFusion',
                  'nedskrivningarConversionDifferences',
                ],
                'year'
              ),
              sumNotesRowsById(
                12,
                [
                  ...range(11, 16),
                  'nedskrivningarFusion',
                  'nedskrivningarConversionDifferences',
                ],
                'previousYear'
              )
            )
            .addRow(
              '17',
              noteLabel('Redovisat värde'),
              sumNotesRowsById(12, [5, 10, 16], 'year'),
              sumNotesRowsById(12, [5, 10, 16], 'previousYear')
            )
            // TODO: how should we represent changes in acquisition values / Förändringar av anskaffningsvärden
            .addRow(
              '18',
              noteLabel('Förändringar av anskaffningsvärden'),
              ref(sumAccountsIBs('1220:1227+1230:1237+1240:1247', false)),
              ref(
                sumAccountsIBs(
                  '1220:1227+1230:1237+1240:1247',
                  false,
                  'previousYear'
                )
              )
            )
            // TODO: how should we represent Förändringar av avskrivningar
            .addRow(
              '19',
              noteLabel('Förändringar av avskrivningar'),
              ref(sumAccountsIBs('1229+1239+1249+1259', false)),
              ref(sumAccountsIBs('1229+1239+1249+1259', false, 'previousYear'))
            )
            // TODO: how should we represent Förändringar av nedskrivningar
            .addRow(
              '20',
              noteLabel('Förändringar av nedskrivningar'),
              ref(sumAccountsIBs('1228+1238+1248+1258', false)),
              ref(sumAccountsIBs('1228+1238+1248+1258', false, 'previousYear'))
            )
            .build()
        )
        .newRowTemplate(value(''), value(undefined), value(undefined))
        .build(),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note13: {
      active: false,
      number: value(undefined),
      name: value('Förbättringsutgifter på annans fastighet'),
      table: table(
        `notes.note13.table`,
        { id: 'label', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        .addRows((rows) =>
          rows
            // 'ingående' = previous period outgoing balance
            .addRow(
              '1',
              noteLabel('Ingående anskaffningsvärden'),
              ref(sumAccountsIBs('1120', false)),
              ref(sumAccountsIBs('1120', false, 'previousYear'))
            )
            .addRow(
              '2',
              noteLabel('Inköp'),
              ref(sumPositiveTransactions('1120:1128')),
              ref(sumPositiveTransactions('1120:1128', 'previousYear'))
            )
            .addRow(
              'businessAcquisitions',
              noteLabel('Rörelseförvärv'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'anskaffningsvardenFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '3',
              noteLabel('Försäljningar/utrangeringar'),
              ref(sumNegativeTransactions('1120:1128')),
              ref(sumNegativeTransactions('1120:1128', 'previousYear'))
            )
            .addRow(
              '4',
              noteLabel('Omklassificeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'anskaffningsvardenConversionDifferences',
              noteLabel('Omräkningsdifferenser'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '5',
              noteLabel('Utgående anskaffningsvärden'),
              sumNotesRowsById(
                13,
                [
                  ...range(1, 5),
                  'businessAcquisitions',
                  'anskaffningsvardenFusion',
                  'anskaffningsvardenConversionDifferences',
                ],
                'year'
              ),
              sumNotesRowsById(
                13,
                [
                  ...range(1, 5),
                  'businessAcquisitions',
                  'anskaffningsvardenFusion',
                  'anskaffningsvardenConversionDifferences',
                ],
                'previousYear'
              )
            )
            .addRow(
              '6',
              noteLabel('Ingående avskrivningar'),
              ref(sumAccountsIBs('1129', false)),
              ref(sumAccountsIBs('1129', false, 'previousYear'))
            )
            .addRow(
              'avskrivningarFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '7',
              noteLabel('Försäljningar/utrangeringar'),
              ref(sumPositiveTransactions('1129')),
              ref(sumPositiveTransactions('1129', 'previousYear'))
            )
            .addRow(
              '8',
              noteLabel('Omklassificeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '9',
              noteLabel('Årets avskrivningar'),
              ref(sumNegativeTransactions('1129')),
              ref(sumNegativeTransactions('1129', 'previousYear'))
            )
            .addRow(
              'avskrivningarConversionDifferences',
              noteLabel('Omräkningsdifferenser'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '10',
              noteLabel('Utgående avskrivningar'),
              sumNotesRowsById(
                13,
                [
                  ...range(6, 10),
                  'avskrivningarFusion',
                  'avskrivningarConversionDifferences',
                ],
                'year'
              ),
              sumNotesRowsById(
                13,
                [
                  ...range(6, 10),
                  'avskrivningarFusion',
                  'avskrivningarConversionDifferences',
                ],
                'previousYear'
              )
            )
            // ID 11 was renamed to totalSum, because it contains the addition
            // of all other sums of note 13
            .addRow(
              '12',
              noteLabel('Ingående nedskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'nedskrivningarFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '13',
              noteLabel('Försäljningar/utrangeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '14',
              noteLabel('Återförda nedskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '15',
              noteLabel('Omklassificeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '16',
              noteLabel('Årets nedskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'nedskrivningarConversionDifferences',
              noteLabel('Omräkningsdifferenser'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '17',
              noteLabel('Utgående nedskrivningar'),
              sumNotesRowsById(
                13,
                [
                  ...range(12, 17),
                  'nedskrivningarFusion',
                  'nedskrivningarConversionDifferences',
                ],
                'year'
              ),
              sumNotesRowsById(
                13,
                [
                  ...range(12, 17),
                  'nedskrivningarFusion',
                  'nedskrivningarConversionDifferences',
                ],
                'previousYear'
              )
            )
            .addRow(
              'totalSum',
              noteLabel('Redovisat värde'),
              sumNotesRowsById(13, [5, 10, 17], 'year'),
              sumNotesRowsById(13, [5, 10, 17], 'previousYear')
            )
            // TODO: how should we represent changes in acquisition values / Förändringar av anskaffningsvärden
            .addRow(
              '18',
              noteLabel('Förändringar av anskaffningsvärden'),
              ref(sumAccountsIBs('1120', false)),
              ref(sumAccountsIBs('1120', false, 'previousYear'))
            )
            // TODO: how should we represent Förändringar av avskrivningar
            .addRow(
              '19',
              noteLabel('Förändringar av avskrivningar'),
              ref(sumAccountsIBs('1129', false)),
              ref(sumAccountsIBs('1129', false, 'previousYear'))
            )
            .addRow(
              '20',
              noteLabel('Förändringar av nedskrivningar'),
              undefined,
              undefined
            )
            .build()
        )
        .newRowTemplate(value(''), value(undefined), value(undefined))
        .build(),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note14: {
      active: false,
      number: value(undefined),
      name: value('Övriga materiella anläggningstillgångar'),
      table: table(
        `notes.note14.table`,
        { id: 'label', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        .addRows((rows) =>
          rows
            // 'ingående' = previous period outgoing balance
            .addRow(
              '1',
              noteLabel('Ingående anskaffningsvärden'),
              undefined,
              undefined
            )
            .addRow(
              '1',
              noteLabel('Ingående avskrivningar'),
              ref(sumAccountsIBs('1259+1269+1299', false)),
              ref(sumAccountsIBs('1259+1269+1299', false, 'previousYear'))
            )
            .addRow(
              'expensesIncurred',
              noteLabel('Nedlagda utgifter'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'advancesPaid',
              noteLabel('Utbetalda förskott'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '2',
              noteLabel('Rörelseförvärv'),
              ref(sumPositiveTransactions('1180:1189+1280:1289')),
              ref(
                sumPositiveTransactions('1180:1189+1280:1289', 'previousYear')
              )
            )
            .addRow(
              'avskrivningarFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '3',
              noteLabel('Försäljningar/utrangeringar'),
              ref(sumNegativeTransactions('1180:1189+1280:1289')),
              ref(
                sumNegativeTransactions('1180:1189+1280:1289', 'previousYear')
              )
            )
            .addRow(
              'refundedAdvances',
              noteLabel('Återbetalda förskott'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'anskaffningsvardenWrittenAdvances',
              noteLabel('Bortskrivna förskott'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '4',
              noteLabel('Omklassificeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'anskaffningsvardenConversionDifferences',
              noteLabel('Omräkningsdifferenser'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '5',
              noteLabel('Utgående avskrivningar'),
              sumNotesRowsById(
                15,
                [
                  ...range(1, 5),
                  'avskrivningarFusion',
                  'avskrivningarConversionDifferences',
                ],
                'year'
              ),
              sumNotesRowsById(
                15,
                [
                  ...range(1, 5),
                  'avskrivningarFusion',
                  'avskrivningarConversionDifferences',
                ],
                'previousYear'
              )
            )
            .addRow(
              '6',
              noteLabel('Ingående nedskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'nedskrivningarFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '7',
              noteLabel('Försäljningar/utrangeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '8',
              noteLabel('Återförda nedskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'nedskrivningarWrittenAdvances',
              noteLabel('Bortskrivna förskott'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '9',
              noteLabel('Omklassificeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '10',
              noteLabel('Årets nedskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'nedskrivningarConversionDifferences',
              noteLabel('Omräkningsdifferenser'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '11',
              noteLabel('Utgående nedskrivningar'),
              sumNotesRowsById(
                15,
                [
                  ...range(6, 11),
                  'nedskrivningarFusion',
                  'nedskrivningarWrittenAdvances',
                  'nedskrivningarConversionDifferences',
                ],
                'year'
              ),
              sumNotesRowsById(
                15,
                [
                  ...range(6, 11),
                  'nedskrivningarFusion',
                  'nedskrivningarWrittenAdvances',
                  'nedskrivningarConversionDifferences',
                ],
                'previousYear'
              )
            )
            .addRow(
              '12',
              noteLabel('Redovisat värde'),
              sumNotesRowsById(15, [5, 11], 'year'),
              sumNotesRowsById(15, [5, 11], 'previousYear')
            )
            // TODO: how should we represent changes in acquisition values / Förändringar av anskaffningsvärden
            .addRow(
              '13',
              noteLabel('Förändringar av anskaffningsvärden'),
              undefined,
              undefined
            )
            .addRow(
              '14',
              noteLabel('Förändringar av avskrivningar'),
              ref(sumAccountsIBs('1259+1269+1299', false)),
              ref(sumAccountsIBs('1259+1269+1299', false, 'previousYear'))
            )
            .addRow(
              '15',
              noteLabel('Förändringar av nedskrivningar'),
              undefined,
              undefined
            )
            .build()
        )
        .newRowTemplate(value(''), value(undefined), value(undefined))
        .build(),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note15: {
      active: false,
      number: value(undefined),
      name: value(
        'Pågående nyanläggningar och förskott avseende materiella anläggningstillgångar'
      ),
      table: table(
        'notes.note15.table',
        { id: 'label', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        .addRows((rows) =>
          rows
            // 'ingående' = previous period outgoing balance
            .addRow(
              '1',
              noteLabel('Ingående anskaffningsvärden'),
              ref(sumAccountsIBs('1280', false)),
              ref(sumAccountsIBs('1280', false, 'previousYear'))
            )
            .addRow(
              'expensesIncurred',
              noteLabel('Nedlagda utgifter'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'advancesPaid',
              noteLabel('Utbetalda förskott'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '2',
              noteLabel('Rörelseförvärv'),
              ref(sumPositiveTransactions('1180:1189+1280:1289')),
              ref(
                sumPositiveTransactions('1180:1189+1280:1289', 'previousYear')
              )
            )
            .addRow(
              'anskaffningsvardenFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '3',
              noteLabel('Försäljningar/utrangeringar'),
              ref(sumNegativeTransactions('1180:1189+1280:1289')),
              ref(
                sumNegativeTransactions('1180:1189+1280:1289', 'previousYear')
              )
            )
            .addRow(
              'refundedAdvances',
              noteLabel('Återbetalda förskott'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'anskaffningsvardenWrittenAdvances',
              noteLabel('Bortskrivna förskott'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '4',
              noteLabel('Omklassificeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'anskaffningsvardenConversionDifferences',
              noteLabel('Omräkningsdifferenser'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '5',
              noteLabel('Utgående anskaffningsvärden'),
              sumNotesRowsById(
                15,
                [
                  ...range(1, 5),
                  'expensesIncurred',
                  'advancesPaid',
                  'anskaffningsvardenFusion',
                  'refundedAdvances',
                  'anskaffningsvardenWrittenAdvances',
                  'anskaffningsvardenConversionDifferences',
                ],
                'year'
              ),
              sumNotesRowsById(
                15,
                [
                  ...range(1, 5),
                  'expensesIncurred',
                  'advancesPaid',
                  'anskaffningsvardenFusion',
                  'refundedAdvances',
                  'anskaffningsvardenWrittenAdvances',
                  'anskaffningsvardenConversionDifferences',
                ],
                'previousYear'
              )
            )
            .addRow(
              '6',
              noteLabel('Ingående nedskrivningar'),
              ref(sumAccountsIBs('1288', false)),
              ref(sumAccountsIBs('1288', false, 'previousYear'))
            )
            .addRow(
              'nedskrivningarFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '7',
              noteLabel('Försäljningar/utrangeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '8',
              noteLabel('Återförda nedskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'nedskrivningarWrittenAdvances',
              noteLabel('Bortskrivna förskott'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '9',
              noteLabel('Omklassificeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '10',
              noteLabel('Årets nedskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'nedskrivningarConversionDifferences',
              noteLabel('Omräkningsdifferenser'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '11',
              noteLabel('Utgående nedskrivningar'),
              sumNotesRowsById(
                15,
                [
                  ...range(6, 11),
                  'nedskrivningarFusion',
                  'nedskrivningarWrittenAdvances',
                  'nedskrivningarConversionDifferences',
                ],
                'year'
              ),
              sumNotesRowsById(
                15,
                [
                  ...range(6, 11),
                  'nedskrivningarFusion',
                  'nedskrivningarWrittenAdvances',
                  'nedskrivningarConversionDifferences',
                ],
                'previousYear'
              )
            )
            .addRow(
              '12',
              noteLabel('Redovisat värde'),
              sumNotesRowsById(15, [5, 11], 'year'),
              sumNotesRowsById(15, [5, 11], 'previousYear')
            )
            .addRow(
              '13',
              noteLabel('Förändringar av anskaffningsvärden'),
              ref(sumAccountsIBs('1280', false)),
              ref(sumAccountsIBs('1280', false, 'previousYear'))
            )
            .addRow(
              '14',
              noteLabel('Förändringar av nedskrivningar'),
              ref(sumAccountsIBs('1288', false)),
              ref(sumAccountsIBs('1288', false, 'previousYear'))
            )
            .build()
        )
        .newRowTemplate(value(''), value(undefined), value(undefined))
        .build(),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note16: {
      active: false,
      number: value(undefined),
      name: value('Andelar i koncernföretag'),
      secondTable: table(
        'notes.note16.secondTable',
        { id: 'companyName', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        .addRows((rows) =>
          rows
            // 'ingående' = previous period outgoing balance
            .addRow(
              '1',
              noteLabel('Ingående anskaffningsvärden'),
              value(undefined),
              value(undefined)
            )
            .addRow('2', noteLabel('Inköp'), value(undefined), value(undefined))
            .addRow(
              'anskaffningsvardenFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '3',
              noteLabel('Försäljningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'shareholderContributions',
              noteLabel('Lämnade aktieägartillskott'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'repaymentShareholderContributions',
              noteLabel('Återbetalning av lämnat aktieägartillskott'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'yearProfit',
              noteLabel('Årets resultatandel'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '4',
              noteLabel('Omklassificeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'anskaffningsvardenConversionDifferences',
              noteLabel('Omräkningsdifferenser'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '5',
              noteLabel('Utgående anskaffningsvärden'),
              sumNotesRowsById(
                16,
                [
                  ...range(1, 5),
                  'anskaffningsvardenFusion',
                  'shareholderContributions',
                  'repaymentShareholderContributions',
                  'yearProfit',
                  'anskaffningsvardenConversionDifferences',
                ],
                'year',
                'secondTable'
              ),
              sumNotesRowsById(
                16,
                [
                  ...range(1, 5),
                  'anskaffningsvardenFusion',
                  'shareholderContributions',
                  'repaymentShareholderContributions',
                  'yearProfit',
                  'anskaffningsvardenConversionDifferences',
                ],
                'previousYear',
                'secondTable'
              )
            )
            .addRow(
              '6',
              noteLabel('Ingående nedskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'nedskrivningarFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '7',
              noteLabel('Försäljningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '8',
              noteLabel('Återförda nedskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '9',
              noteLabel('Omklassificeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '10',
              noteLabel('Årets nedskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'nedskrivningarConversionDifferences',
              noteLabel('Omräkningsdifferenser'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '11',
              noteLabel('Utgående nedskrivningar'),
              sumNotesRowsById(
                16,
                [
                  ...range(6, 11),
                  'nedskrivningarFusion',
                  'nedskrivningarConversionDifferences',
                ],
                'year',
                'secondTable'
              ),
              sumNotesRowsById(
                16,
                [
                  ...range(6, 11),
                  'nedskrivningarFusion',
                  'nedskrivningarConversionDifferences',
                ],
                'previousYear',
                'secondTable'
              )
            )
            .addRow(
              '12',
              noteLabel('Redovisat värde'),
              sumNotesRowsById(16, [5, 11], 'year', 'secondTable'),
              sumNotesRowsById(16, [5, 11], 'previousYear', 'secondTable')
            )
            .build()
        )
        .newRowTemplate(value(''), value(undefined), value(undefined))
        .build(),
      table: table(
        'notes.note16.table',
        { id: 'companyName', label: 'Företagets namn' },
        {
          id: 'organisationNumber',
          label: 'Organisationsnummer',
        },
        {
          id: 'place',
          label: 'Säte',
        },
        {
          id: 'capitalShare',
          label: 'Kapitalandel (%)',
        }
      )
        .addRows((rows) =>
          rows
            .addRow('1', value(''), value(''), value(''), value(undefined))
            .build()
        )
        .newRowTemplateGenerator((id, baseId) => ({
          id,
          active: true,
          cells: {
            companyName: value(''),
            organisationNumber: value(''),
            place: value(''),
            capitalShare: value(undefined),
          },
        }))
        .build(),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note17: {
      active: false,
      number: value(undefined),
      name: value('Fordringar hos koncernföretag'),
      table: table(
        'notes.note17.table',
        { id: 'label', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        .addRows((rows) =>
          rows
            // 'ingående' = previous period outgoing balance
            .addRow(
              '1',
              noteLabel('Ingående anskaffningsvärden'),
              ref(sumAccountsIBs('1320:1327+1329+1660:1669', false)),
              ref(
                sumAccountsIBs(
                  '1320:1327+1329+1660:1669',
                  false,
                  'previousYear'
                )
              )
            )
            .addRow(
              '2',
              noteLabel('Tillkommande fordringar'),
              ref(sumPositiveTransactions('1320:1327+1329+1660:1669')),
              ref(
                sumPositiveTransactions(
                  '1320:1327+1329+1660:1669',
                  'previousYear'
                )
              )
            )
            .addRow(
              'businessAcquisitions',
              noteLabel('Rörelseförvärv'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'anskaffningsvardenFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '3',
              noteLabel('Reglerade fordringar'),
              ref(sumNegativeTransactions('1320:1327+1329+1660:1669')),
              ref(
                sumNegativeTransactions(
                  '1320:1327+1329+1660:1669',
                  'previousYear'
                )
              )
            )
            .addRow(
              'anskaffningsvardenWrittenReceivables',
              noteLabel('Bortskrivna fordringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '4',
              noteLabel('Omklassificeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'anskaffningsvardenConversionDifferences',
              noteLabel('Omräkningsdifferenser'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '5',
              noteLabel('Utgående anskaffningsvärden'),
              sumNotesRowsById(
                17,
                [
                  ...range(1, 5),
                  'businessAcquisitions',
                  'anskaffningsvardenFusion',
                  'anskaffningsvardenWrittenReceivables',
                  'anskaffningsvardenConversionDifferences',
                ],
                'year'
              ),
              sumNotesRowsById(
                17,
                [
                  ...range(1, 5),
                  'businessAcquisitions',
                  'anskaffningsvardenFusion',
                  'anskaffningsvardenWrittenReceivables',
                  'anskaffningsvardenConversionDifferences',
                ],
                'previousYear'
              )
            )
            .addRow(
              '6',
              noteLabel('Ingående nedskrivningar'),
              ref(sumAccountsIBs('1328', false)),
              ref(sumAccountsIBs('1328', false, 'previousYear'))
            )
            .addRow(
              '7',
              noteLabel('Reglerade fordringar'), // it was Amortering, avgående fordringar
              ref(sumPositiveTransactions('1328')),
              ref(sumPositiveTransactions('1328', 'previousYear'))
            )
            .addRow(
              'nedskrivningarFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '8',
              noteLabel('Återförda nedskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'nedskrivningarWrittenReceivables',
              noteLabel('Bortskrivna fordringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '9',
              noteLabel('Omklassificeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '10',
              noteLabel('Årets nedskrivningar'),
              ref(sumNegativeTransactions('1328')),
              ref(sumNegativeTransactions('1328', 'previousYear'))
            )
            .addRow(
              'nedskrivningarConversionDifferences',
              noteLabel('Omräkningsdifferenser'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '11',
              noteLabel('Utgående nedskrivningar'),
              sumNotesRowsById(
                17,
                [
                  ...range(6, 11),
                  'nedskrivningarFusion',
                  'nedskrivningarWrittenReceivables',
                  'nedskrivningarConversionDifferences',
                ],
                'year'
              ),
              sumNotesRowsById(
                17,
                [
                  ...range(6, 11),
                  'nedskrivningarFusion',
                  'nedskrivningarWrittenReceivables',
                  'nedskrivningarConversionDifferences',
                ],
                'previousYear'
              )
            )
            .addRow(
              '12',
              noteLabel('Redovisat värde'),
              sumNotesRowsById(17, [5, 11], 'year'),
              sumNotesRowsById(17, [5, 11], 'previousYear')
            )
            .build()
        )
        .newRowTemplate(value(''), value(undefined), value(undefined))
        .build(),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note18: {
      active: false,
      number: value(undefined),
      name: value('Andelar i intresseföretag och gemensamt styrda företag'),
      // second table comes first in the view now
      // KEEP IT as secondTable to preserve older input values
      secondTable: table(
        'notes.note18.secondTable',
        { id: 'companyName', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        .addRows((rows) =>
          rows
            // 'ingående' = previous period outgoing balance
            .addRow(
              '1',
              noteLabel('Ingående anskaffningsvärden'),
              value(undefined),
              value(undefined)
            )
            .addRow('2', noteLabel('Inköp'), value(undefined), value(undefined))
            .addRow(
              'anskaffningsvardenFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '3',
              noteLabel('Försäljningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'shareholderContributions',
              noteLabel('Lämnade aktieägartillskott'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'repaymentOfShareholderContributions',
              noteLabel('Återbetalning av lämnat aktieägartillskott'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'yearProfitShare',
              noteLabel('Årets resultatandel'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '4',
              noteLabel('Omklassificeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'anskaffningsvardenConversionDifferences',
              noteLabel('Omräkningsdifferenser'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '5',
              noteLabel('Utgående anskaffningsvärden'),
              sumNotesRowsById(
                18,
                [
                  ...range(1, 5),
                  'anskaffningsvardenFusion',
                  'shareholderContributions',
                  'repaymentOfShareholderContributions',
                  'yearProfitShare',
                  'anskaffningsvardenConversionDifferences',
                ],
                'year',
                'secondTable'
              ),
              sumNotesRowsById(
                18,
                [
                  ...range(1, 5),
                  'anskaffningsvardenFusion',
                  'shareholderContributions',
                  'repaymentOfShareholderContributions',
                  'yearProfitShare',
                  'anskaffningsvardenConversionDifferences',
                ],
                'previousYear',
                'secondTable'
              )
            )
            .addRow(
              '6',
              noteLabel('Ingående nedskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'nedskrivningarFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '7',
              noteLabel('Försäljningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '8',
              noteLabel('Återförda nedskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '9',
              noteLabel('Omklassificeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '10',
              noteLabel('Årets nedskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'nedskrivningarConversionDifferences',
              noteLabel('Omräkningsdifferenser'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '11',
              noteLabel('Utgående nedskrivningar'),
              sumNotesRowsById(
                18,
                [
                  ...range(6, 11),
                  'nedskrivningarFusion',
                  'nedskrivningarConversionDifferences',
                ],
                'year',
                'secondTable'
              ),
              sumNotesRowsById(
                18,
                [
                  ...range(6, 11),
                  'nedskrivningarFusion',
                  'nedskrivningarConversionDifferences',
                ],
                'previousYear',
                'secondTable'
              )
            )
            .addRow(
              '12',
              noteLabel('Redovisat värde'),
              sumNotesRowsById(18, [5, 11], 'year', 'secondTable'),
              sumNotesRowsById(18, [5, 11], 'previousYear', 'secondTable')
            )
            .build()
        )
        .newRowTemplate(value(''), value(undefined), value(undefined))
        .build(),
      table: table(
        'notes.note18.table',
        { id: 'companyName', label: 'Företagets namn' },
        {
          id: 'organisationNumber',
          label: 'Organisationsnummer',
        },
        {
          id: 'place',
          label: 'Säte',
        },
        {
          id: 'capitalShare',
          label: 'Kapitalandel (%)',
        }
      )
        .addRows((rows) =>
          rows
            .addRow('1', value(''), value(''), value(''), value(undefined))
            .build()
        )
        .newRowTemplateGenerator((id, baseId) => ({
          id,
          active: true,
          cells: {
            companyName: value(''),
            organisationNumber: value(''),
            place: value(''),
            capitalShare: value(undefined),
          },
        }))
        .build(),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note19: {
      active: false,
      number: value(undefined),
      name: value(
        'Fordringar hos intresseföretag och gemensamt styrda företag'
      ),
      table: table(
        'notes.note19.table',
        { id: 'label', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        .addRows((rows) =>
          rows
            // 'ingående' = previous period outgoing balance
            .addRow(
              '1',
              noteLabel('Ingående anskaffningsvärden'),
              ref(sumAccountsIBs('1340:1341+1343+1345+1349+1670:1672', false)),
              ref(
                sumAccountsIBs(
                  '1340:1341+1343+1345+1349+1670:1672',
                  false,
                  'previousYear'
                )
              )
            )
            .addRow(
              '2',
              noteLabel('Tillkommande fordringar'),
              ref(
                sumPositiveTransactions('1340:1341+1343+1345+1349+1670:1672')
              ),
              ref(
                sumPositiveTransactions(
                  '1340:1341+1343+1345+1349+1670:1672',
                  'previousYear'
                )
              )
            )
            .addRow(
              'anskaffningsvardenFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '3',
              noteLabel('Reglerade fordringar'),
              ref(
                sumNegativeTransactions('1340:1341+1343+1345+1349+1670:1672')
              ),
              ref(
                sumNegativeTransactions(
                  '1340:1341+1343+1345+1349+1670:1672',
                  'previousYear'
                )
              )
            )
            .addRow(
              'anskaffningsvardenWrittenReceivables',
              noteLabel('Bortskrivna fordringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '4',
              noteLabel('Omklassificeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '5',
              noteLabel('Omräkningsdifferenser'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '6',
              noteLabel('Utgående anskaffningsvärden'),
              sumNotesRowsById(
                19,
                [
                  ...range(1, 6),
                  'anskaffningsvardenFusion',
                  'anskaffningsvardenWrittenReceivables',
                ],
                'year'
              ),
              sumNotesRowsById(
                19,
                [
                  ...range(1, 6),
                  'anskaffningsvardenFusion',
                  'anskaffningsvardenWrittenReceivables',
                ],
                'previousYear'
              )
            )
            .addRow(
              '7',
              noteLabel('Ingående nedskrivningar'),
              ref(sumAccountsIBs('1342+1344+1348', false)),
              ref(sumAccountsIBs('1342+1344+1348', false, 'previousYear'))
            )
            .addRow(
              '8',
              noteLabel('Reglerade fordringar'),
              ref(sumPositiveTransactions('1342+1344+1348')),
              ref(sumPositiveTransactions('1342+1344+1348', 'previousYear'))
            )
            .addRow(
              'nedskrivningarFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '9',
              noteLabel('Återförda nedskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'nedskrivningarWrittenReceivables',
              noteLabel('Bortskrivna fordringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '10',
              noteLabel('Omklassificeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '11',
              noteLabel('Årets nedskrivningar'),
              ref(sumNegativeTransactions('1342+1344+1348')),
              ref(sumNegativeTransactions('1342+1344+1348', 'previousYear'))
            )
            .addRow(
              '12',
              noteLabel('Omräkningsdifferenser'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '13',
              noteLabel('Utgående nedskrivningar'),
              sumNotesRowsById(
                19,
                [
                  ...range(7, 13),
                  'nedskrivningarFusion',
                  'nedskrivningarWrittenReceivables',
                ],
                'year'
              ),
              sumNotesRowsById(
                19,
                [
                  ...range(7, 13),
                  'nedskrivningarFusion',
                  'nedskrivningarWrittenReceivables',
                ],
                'previousYear'
              )
            )
            .addRow(
              '14',
              noteLabel('Redovisat värde'),
              sumNotesRowsById(19, [6, 13], 'year'),
              sumNotesRowsById(19, [6, 13], 'previousYear')
            )
            .build()
        )
        .newRowTemplate(value(''), value(undefined), value(undefined))
        .build(),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note20: {
      active: false,
      number: value(undefined),
      name: value('Ägarintressen i övriga företag'),
      // secondTable really comes first here
      secondTable: table(
        'notes.note20.secondTable',
        { id: 'companyName', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        .addRows((rows) =>
          rows
            // 'ingående' = previous period outgoing balance
            .addRow(
              '1',
              noteLabel('Ingående anskaffningsvärden'),
              value(undefined),
              value(undefined)
            )
            .addRow('2', noteLabel('Inköp'), value(undefined), value(undefined))
            .addRow(
              'anskaffningsvardenFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '3',
              noteLabel('Försäljningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'shareholderContributions',
              noteLabel('Lämnade aktieägartillskott'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'repaymentShareholderContributions',
              noteLabel('Återbetalning av lämnat aktieägartillskott'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'yearProfit',
              noteLabel('Årets resultatandel'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '4',
              noteLabel('Omklassificeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'anskaffningsvardenConversionDifferences',
              noteLabel('Omräkningsdifferenser'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '5',
              noteLabel('Utgående anskaffningsvärden'),
              sumNotesRowsById(
                20,
                [
                  ...range(1, 5),
                  'anskaffningsvardenFusion',
                  'shareholderContributions',
                  'repaymentShareholderContributions',
                  'yearProfit',
                  'anskaffningsvardenConversionDifferences',
                ],
                'year',
                'secondTable'
              ),
              sumNotesRowsById(
                20,
                [
                  ...range(1, 5),
                  'anskaffningsvardenFusion',
                  'shareholderContributions',
                  'repaymentShareholderContributions',
                  'yearProfit',
                  'anskaffningsvardenConversionDifferences',
                ],
                'previousYear',
                'secondTable'
              )
            )
            .addRow(
              '6',
              noteLabel('Ingående nedskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'nedskrivningarFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '7',
              noteLabel('Försäljningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '8',
              noteLabel('Återförda nedskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '9',
              noteLabel('Omklassificeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '10',
              noteLabel('Årets nedskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'nedskrivningarConversionDifferences',
              noteLabel('Omräkningsdifferenser'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '11',
              noteLabel('Utgående nedskrivningar'),
              sumNotesRowsById(
                20,
                [
                  ...range(6, 11),
                  'nedskrivningarFusion',
                  'nedskrivningarConversionDifferences',
                ],
                'year',
                'secondTable'
              ),
              sumNotesRowsById(
                20,
                [
                  ...range(6, 11),
                  'nedskrivningarFusion',
                  'nedskrivningarConversionDifferences',
                ],
                'previousYear',
                'secondTable'
              )
            )
            .addRow(
              '12',
              noteLabel('Redovisat värde'),
              sumNotesRowsById(20, [5, 11], 'year', 'secondTable')
            )
            .build()
        )
        .newRowTemplate(value(''), value(undefined), value(undefined))
        .build(),
      table: table(
        'notes.note20.table',
        { id: 'companyName', label: 'Företagets namn' },
        {
          id: 'organisationNumber',
          label: 'Organisationsnummer',
        },
        {
          id: 'place',
          label: 'Säte',
        },
        {
          id: 'capitalShare',
          label: 'Kapitalandel',
        }
      )
        .addRows((rows) =>
          rows
            .addRow('1', value(''), value(''), value(''), value(undefined))
            .build()
        )
        .newRowTemplateGenerator((id, baseId) => ({
          id,
          active: true,
          cells: {
            companyName: value(''),
            organisationNumber: value(''),
            place: value(''),
            capitalShare: value(undefined),
          },
        }))
        .build(),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note21: {
      active: false,
      number: value(undefined),
      name: value(
        'Fordringar hos övriga företag som det finns ett ägarintresse i'
      ),
      table: table(
        'notes.note21.table',
        { id: 'label', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        .addRows((rows) =>
          rows
            // 'ingående' = previous period outgoing balance
            .addRow(
              '1',
              noteLabel('Ingående anskaffningsvärden'),
              ref(sumAccountsIBs('1346+1673:1679', false)),
              ref(sumAccountsIBs('1346+1673:1679', false, 'previousYear'))
            )
            .addRow(
              '2',
              noteLabel('Tillkommande fordringar'),
              ref(sumPositiveTransactions('1346+1673:1679')),
              ref(sumPositiveTransactions('1346+1673:1679', 'previousYear'))
            )
            .addRow(
              'anskaffningsvardenFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '3',
              noteLabel('Reglerade fordringar'),
              ref(sumNegativeTransactions('1346+1673:1679')),
              ref(sumNegativeTransactions('1346+1673:1679', 'previousYear'))
            )
            .addRow(
              'anskaffningsvardenWrittenReceivables',
              noteLabel('Bortskrivna fordringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '4',
              noteLabel('Omklassificeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'anskaffningsvardenConversionDifferences',
              noteLabel('Omräkningsdifferenser'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '5',
              noteLabel('Utgående  anskaffningsvärden'),
              sumNotesRowsById(
                21,
                [
                  ...range(1, 5),
                  'anskaffningsvardenFusion',
                  'anskaffningsvardenWrittenReceivables',
                  'anskaffningsvardenConversionDifferences',
                ],
                'year'
              ),
              sumNotesRowsById(
                21,
                [
                  ...range(1, 5),
                  'anskaffningsvardenFusion',
                  'anskaffningsvardenWrittenReceivables',
                  'anskaffningsvardenConversionDifferences',
                ],
                'previousYear'
              )
            )
            .addRow(
              '7',
              noteLabel('Ingående nedskrivningar'),
              ref(sumAccountsIBs('1347', false)),
              ref(sumAccountsIBs('1347', false, 'previousYear'))
            )
            .addRow(
              '8',
              noteLabel('Reglerade fordringar'),
              ref(sumPositiveTransactions('1347')),
              ref(sumPositiveTransactions('1347', 'previousYear'))
            )
            .addRow(
              'nedskrivningarFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '9',
              noteLabel('Återförda nedskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'nedskrivningarWrittenReceivables',
              noteLabel('Bortskrivna fordringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '10',
              noteLabel('Omklassificeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '11',
              noteLabel('Årets nedskrivningar'),
              ref(sumNegativeTransactions('1347')),
              ref(sumNegativeTransactions('1347', 'previousYear'))
            )
            .addRow(
              'nedskrivningarConversionDifferences',
              noteLabel('Omräkningsdifferenser'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '12',
              noteLabel('Utgående  nedskrivningar'),
              sumNotesRowsById(
                21,
                [
                  ...range(7, 12),
                  'nedskrivningarFusion',
                  'nedskrivningarWrittenReceivables',
                  'nedskrivningarConversionDifferences',
                ],
                'year'
              ),
              sumNotesRowsById(
                21,
                [
                  ...range(7, 12),
                  'nedskrivningarFusion',
                  'nedskrivningarWrittenReceivables',
                  'nedskrivningarConversionDifferences',
                ],
                'previousYear'
              )
            )
            .addRow(
              '13',
              noteLabel('Redovisat värde'),
              sumNotesRowsById(21, [5, 12], 'year'),
              sumNotesRowsById(21, [5, 12], 'previousYear')
            )
            .build()
        )
        .newRowTemplate(value(''), value(undefined), value(undefined))
        .build(),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note22: {
      active: false,
      number: value(undefined),
      name: value('Andra långfristiga värdepappersinnehav'),
      table: table(
        'notes.note22.table',
        { id: 'label', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        .addRows((rows) =>
          rows
            // 'ingående' = previous period outgoing balance
            .addRow(
              '1',
              noteLabel('Ingående anskaffningsvärden'),
              ref(sumAccountsIBs('1350:1357', false))
            )
            .addRow(
              '2',
              noteLabel('Inköp'),
              ref(sumPositiveTransactions('1350:1357')),
              ref(sumPositiveTransactions('1350:1357', 'previousYear'))
            )
            .addRow(
              'anskaffningsvardenFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '3',
              noteLabel('Försäljningar'),
              ref(sumNegativeTransactions('1350:1357')),
              ref(sumNegativeTransactions('1350:1357', 'previousYear'))
            )
            .addRow(
              'yearResults',
              noteLabel('Årets resultatandel'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '4',
              noteLabel('Omklassificeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'anskaffningsvardenConversionDifferences',
              noteLabel('Omräkningsdifferenser'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '5',
              noteLabel('Utgående  anskaffningsvärden'),
              sumNotesRowsById(
                22,
                [
                  ...range(1, 5),
                  'anskaffningsvardenFusion',
                  'yearResults',
                  'anskaffningsvardenConversionDifferences',
                ],
                'year'
              ),
              sumNotesRowsById(
                22,
                [
                  ...range(1, 5),
                  'anskaffningsvardenFusion',
                  'yearResults',
                  'anskaffningsvardenConversionDifferences',
                ],
                'previousYear'
              )
            )
            .addRow(
              '6',
              noteLabel('Ingående nedskrivningar'),
              ref(sumAccountsIBs('1358+1359', false)),
              ref(sumAccountsIBs('1358+1359', false, 'previousYear'))
            )
            .addRow(
              'nedskrivningarFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '7',
              noteLabel('Försäljningar'),
              ref(sumPositiveTransactions('1358+1359')),
              ref(sumPositiveTransactions('1358+1359', 'previousYear'))
            )
            .addRow(
              '8',
              noteLabel('Återförda nedskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '9',
              noteLabel('Omklassificeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '10',
              noteLabel('Årets nedskrivningar'),
              ref(sumNegativeTransactions('1358+1359')),
              ref(sumNegativeTransactions('1358+1359', 'previousYear'))
            )
            .addRow(
              'nedskrivningarConversionDifferences',
              noteLabel('Omräkningsdifferenser'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '11',
              noteLabel('Utgående  nedskrivningar'),
              sumNotesRowsById(
                22,
                [
                  ...range(6, 11),
                  'nedskrivningarFusion',
                  'nedskrivningarConversionDifferences',
                ],
                'year'
              ),
              sumNotesRowsById(
                22,
                [
                  ...range(6, 11),
                  'nedskrivningarFusion',
                  'nedskrivningarConversionDifferences',
                ],
                'previousYear'
              )
            )
            .addRow(
              '12',
              noteLabel('Redovisat värde'),
              sumNotesRowsById(22, [5, 11], 'year'),
              sumNotesRowsById(22, [5, 11], 'previousYear')
            )
            .build()
        )
        .newRowTemplate(value(''), value(undefined), value(undefined))
        .build(),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note23: {
      active: false,
      number: value(undefined),
      name: value('Lån till delägare eller närstående'),
      table: table(
        'notes.note23.table',
        { id: 'label', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        .addRows((rows) =>
          rows
            // 'ingående' = previous period outgoing balance
            .addRow(
              '1',
              noteLabel('Ingående anskaffningsvärden'),
              ref(sumAccountsIBs('1360', false)),
              ref(sumAccountsIBs('1360', false, 'previousYear'))
            )
            .addRow(
              '2',
              noteLabel('Årets lämnade lån'),
              ref(sumPositiveTransactions('1360:1368')),
              ref(sumPositiveTransactions('1360:1368', 'previousYear'))
            )
            .addRow(
              'anskaffningsvardenFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '3',
              noteLabel('Årets amorteringar'),
              ref(sumNegativeTransactions('1360:1368')),
              ref(sumNegativeTransactions('1360:1368', 'previousYear'))
            )
            .addRow(
              'anskaffningsvardenWrittenReceivables',
              noteLabel('Bortskrivna fordringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '4',
              noteLabel('Omklassificeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'anskaffningsvardenConversionDifferences',
              noteLabel('Omräkningsdifferenser'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '5',
              noteLabel('Utgående anskaffningsvärden'),
              sumNotesRowsById(
                23,
                [
                  ...range(1, 5),
                  'anskaffningsvardenFusion',
                  'anskaffningsvardenWrittenReceivables',
                  'anskaffningsvardenConversionDifferences',
                ],
                'year'
              ),
              sumNotesRowsById(
                23,
                [
                  ...range(1, 5),
                  'anskaffningsvardenFusion',
                  'anskaffningsvardenWrittenReceivables',
                  'anskaffningsvardenConversionDifferences',
                ],
                'previousYear'
              )
            )
            .addRow(
              '6',
              noteLabel('Ingående nedskrivningar'),
              ref(sumAccountsIBs('1369', false)),
              ref(sumAccountsIBs('1369', false, 'previousYear'))
            )
            .addRow(
              '7',
              noteLabel('Årets amorteringar'),
              ref(sumPositiveTransactions('1369')),
              ref(sumPositiveTransactions('1369', 'previousYear'))
            )
            .addRow(
              'nedskrivningarFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '8',
              noteLabel('Återförda nedskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'nedskrivningarWrittenReceivables',
              noteLabel('Bortskrivna fordringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '9',
              noteLabel('Omklassificeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '10',
              noteLabel('Årets nedskrivningar'),
              ref(sumNegativeTransactions('1369')),
              ref(sumNegativeTransactions('1369', 'previousYear'))
            )
            .addRow(
              '11',
              noteLabel('Utgående nedskrivningar'),
              sumNotesRowsById(
                23,
                [
                  ...range(6, 11),
                  'nedskrivningarFusion',
                  'nedskrivningarWrittenReceivables',
                  'nedskrivningarConversionDifferences',
                ],
                'year'
              ),
              sumNotesRowsById(
                23,
                [
                  ...range(6, 11),
                  'nedskrivningarFusion',
                  'nedskrivningarWrittenReceivables',
                  'nedskrivningarConversionDifferences',
                ],
                'previousYear'
              )
            )
            .addRow(
              '12',
              noteLabel('Redovisat värde'),
              sumNotesRowsById(23, [5, 11], 'year'),
              sumNotesRowsById(23, [5, 11], 'previousYear')
            )
            // TODO: how should we represent changes in acquisition values / Förändringar av anskaffningsvärden
            .addRow(
              '13',
              noteLabel('Förändringar av anskaffningsvärden'),
              ref(sumAccountsIBs('1360', false)),
              ref(sumAccountsIBs('1360', false, 'previousYear'))
            )
            .addRow(
              '14',
              noteLabel('Förändringar av nedskrivningar'),
              ref(sumAccountsIBs('1369', false)),
              ref(sumAccountsIBs('1369', false, 'previousYear'))
            )
            .build()
        )
        .newRowTemplate(value(''), value(undefined), value(undefined))
        .build(),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note24: {
      active: false,
      number: value(undefined),
      name: value('Andra långfristiga fordringar'),
      table: table(
        'notes.note24.table',
        { id: 'label', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        .addRows((rows) =>
          rows
            // 'ingående' = previous period outgoing balance
            .addRow(
              '1',
              noteLabel('Ingående anskaffningsvärden'),
              ref(sumAccountsIBs('1380:1388+1390:1399', false)),
              ref(sumAccountsIBs('1380:1388+1390:1399', false, 'previousYear'))
            )
            .addRow(
              '2',
              noteLabel('Tillkommande fordringar'),
              ref(sumPositiveTransactions('1380:1388+1390:1399')),
              ref(
                sumPositiveTransactions('1380:1388+1390:1399', 'previousYear')
              )
            )
            .addRow(
              'anskaffningsvardenFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '3',
              noteLabel('Reglerade fordringar'),
              ref(sumNegativeTransactions('1380:1388+1390:1399')),
              ref(
                sumNegativeTransactions('1380:1388+1390:1399', 'previousYear')
              )
            )
            .addRow(
              'anskaffningsvardenWrittenReceivables',
              noteLabel('Bortskrivna fordringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '4',
              noteLabel('Omklassificeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'anskaffningsvardenConversionDifferences',
              noteLabel('Omräkningsdifferenser'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '5',
              noteLabel('Utgående anskaffningsvärden'),
              sumNotesRowsById(
                24,
                [
                  ...range(1, 5),
                  'anskaffningsvardenFusion',
                  'anskaffningsvardenWrittenReceivables',
                  'anskaffningsvardenConversionDifferences',
                ],
                'year'
              ),
              sumNotesRowsById(
                24,
                [
                  ...range(1, 5),
                  'anskaffningsvardenFusion',
                  'anskaffningsvardenWrittenReceivables',
                  'anskaffningsvardenConversionDifferences',
                ],
                'previousYear'
              )
            )
            .addRow(
              '6',
              noteLabel('Ingående nedskrivningar'),
              ref(sumAccountsIBs('1389', false)),
              ref(sumAccountsIBs('1389', false, 'previousYear'))
            )
            .addRow(
              'nedskrivningarFusion',
              noteLabel('Fusion'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '7',
              noteLabel('Reglerade fordringar'),
              ref(sumPositiveTransactions('1389')),
              ref(sumPositiveTransactions('1389', 'previousYear'))
            )
            .addRow(
              '8',
              noteLabel('Återförda nedskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'nedskrivningarWrittenReceivables',
              noteLabel('Bortskrivna fordringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '9',
              noteLabel('Omklassificeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '10',
              noteLabel('Årets nedskrivningar'),
              ref(sumNegativeTransactions('1389')),
              ref(sumNegativeTransactions('1389', 'previousYear'))
            )
            .addRow(
              'nedskrivningarConversionDifferences',
              noteLabel('Omräkningsdifferenser'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '11',
              noteLabel('Utgående nedskrivningar'),
              sumNotesRowsById(
                24,
                [
                  ...range(6, 11),
                  'nedskrivningarFusion',
                  'nedskrivningarWrittenReceivables',
                  'nedskrivningarConversionDifferences',
                ],
                'year'
              ),
              sumNotesRowsById(
                24,
                [
                  ...range(6, 11),
                  'nedskrivningarFusion',
                  'nedskrivningarWrittenReceivables',
                  'nedskrivningarConversionDifferences',
                ],
                'previousYear'
              )
            )
            .addRow(
              '12',
              noteLabel('Redovisat värde'),
              sumNotesRowsById(24, [5, 11], 'year'),
              sumNotesRowsById(24, [5, 11], 'previousYear')
            )
            .addRow(
              '13',
              noteLabel('Förändringar av anskaffningsvärden'),
              ref(sumAccountsIBs('1380:1388+1390:1399', false)),
              ref(sumAccountsIBs('1380:1388+1390:1399', false, 'previousYear'))
            )
            // TODO: how should we represent Förändringar av nedskrivningar
            .addRow(
              '14',
              noteLabel('Förändringar av nedskrivningar'),
              ref(sumAccountsIBs('1389', false)),
              ref(sumAccountsIBs('1389', false, 'previousYear'))
            )
            .build()
        )
        .newRowTemplate(value(''), value(undefined), value(undefined))
        .build(),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note25: {
      active: false,
      number: value(undefined),
      name: value('Pågående arbete för annans räkning'),
      table: table(
        'notes.note25.table',
        { id: 'label', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        .addRows((rows) =>
          rows
            .addRow(
              '1',
              noteLabel('Aktiverade nedlagda utgifter'),
              ref(sumAccountsUBs('1470:1471+1479+1478', true)),
              ref(sumAccountsUBs('1470:1471+1479+1478', true, 'previousYear'))
            )
            .addRow(
              '2',
              noteLabel('Fakturerat belopp'),
              ref(sumAccountsUBs('1478', true)),
              ref(sumAccountsUBs('1478', true, 'previousYear'))
            )
            .addRow(
              'claimSum',
              noteLabel('Redovisat värde'),
              sumNotesRowsById(25, [1, 2], 'year'),
              sumNotesRowsById(25, [1, 2], 'previousYear')
            )
            .addRow(
              '3',
              noteLabel('Fakturerat belopp'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '4',
              noteLabel('Aktiverade nedlagda utgifter'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              'debtSum',
              noteLabel('Redovisat värde (Skuld)'),
              sumNotesRowsById(25, [3, 4], 'year'),
              sumNotesRowsById(25, [3, 4], 'previousYear')
            )
            .build()
        )
        .newRowTemplate(value(''), value(undefined), value(undefined))
        .build(),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note26: {
      active: false,
      number: value(undefined),
      name: value('Uppskrivningsfond'),
      table: table(
        'notes.note26.table',
        { id: 'label', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        },
        'hidden'
      )
        .addRows((rows) =>
          rows
            // 'ingång' = previous period outgoing balance
            .addRow(
              '1',
              noteLabel('Belopp vid årets ingång'),
              ref(sumAccountsIBs('2080', true)),
              ref(sumAccountsIBs('2080', true, 'previousYear'))
            )
            .addRow(
              '2',
              noteLabel('Avsättning till fonden under räkenskapsåret'),
              ref(sumNegativeTransactions('2085')),
              ref(sumNegativeTransactions('2085', 'previousYear'))
            )
            .addRow(
              '3',
              noteLabel('Avskrivning av uppskrivna tillgångar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '4',
              noteLabel('Nedskrivning av uppskrivna tillgångar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '5',
              noteLabel('Försäljning/utrangering av uppskrivna tillgångar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '6',
              noteLabel('Ianspråktaget för fondemission'),
              value(undefined),
              value(undefined)
            )
            // row 'Nyemission' with ID 7 was removed, since it does not fit in current BV specs
            .addRow(
              '8',
              noteLabel('Ianspråktaget för täckande av förlust'),
              value(undefined),
              value(undefined)
            )
            .build()
        )
        .newRowTemplate(value(''), value(undefined), value(undefined))
        .build(),
      sumTable: table(
        'notes.note26.sumTable',
        { id: 'label', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        .addRows((rows) =>
          rows
            .addRow(
              'sum',
              label('Belopp vid årets utgång'),
              ref(or(sum(id('notes.note26.table.*.year')), 0)),
              ref(or(sum(id('notes.note26.table.*.previousYear')), 0))
            )
            .addRow(
              'revaluedAssetsExcludingRevaluation',
              label('Uppskrivna tillgångarnas värde exkl. uppskrivning'),
              value(undefined),
              value(undefined)
            )
            .build()
        )
        .build(),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note27: {
      active: false,
      number: value(undefined),
      name: value('Långfristiga skulder'),
      table: table(
        'notes.note27.table',
        { id: 'label', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        },
        'hidden'
      )
        // no account prefilling in note27
        .addRows((rows) =>
          rows
            .addRow(
              '1',
              noteLabel(
                'Belopp på skuldpost som förfaller till betalning senare än fem år efter balansdagen'
              ),
              ref(sumAccountsIBs('2300:2329', true)),
              ref(sumAccountsIBs('2300:2329', true, 'previousYear'))
            )
            .build()
        )
        .newRowTemplate(value(''), value(undefined), value(undefined))
        .build(),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note28: {
      active: false,
      number: value(undefined),
      name: value('Skulder som avser flera poster'),
      general: field(
        'Företagets banklån/skuld om yyy kronor redovisas under följande poster i balansräkningen.'
      ),
      table: table(
        'notes.note28.table',
        { id: 'label', label: 'Långfristiga skulder' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        .addRows((rows) =>
          rows
            .addRow(
              '1',
              noteLabel('Obligationslån'),
              ref(sumAccountsUBs('2300:2319', true)),
              ref(sumAccountsUBs('2300:2319', true, 'previousYear'))
            )
            .addRow(
              '2',
              noteLabel('Skulder till kreditinstitut'),
              ref(sumAccountsUBs('2350:2359', true)),
              ref(sumAccountsUBs('2350:2359', true, 'previousYear'))
            )
            .addRow(
              '3',
              noteLabel('Skulder till koncernföretag'),
              ref(sumAccountsUBs('2360:2369', true)),
              ref(sumAccountsUBs('2360:2369', true, 'previousYear'))
            )
            .addRow(
              '4',
              noteLabel(
                'Skulder till intresseföretag och gemensamt styrda företag'
              ),
              ref(sumAccountsUBs('2370:2372', true)),
              ref(sumAccountsUBs('2370:2372', true, 'previousYear'))
            )
            .addRow(
              '5',
              noteLabel(
                'Skulder till övriga företag som det finns ett ägarintresse i'
              ),
              ref(sumAccountsUBs('2373:2379', true)),
              ref(sumAccountsUBs('2373:2379', true, 'previousYear'))
            )
            .addRow(
              '6',
              noteLabel('Övriga skulder'),
              ref(sumAccountsUBs('2380:2399', true)),
              ref(sumAccountsUBs('2380:2399', true, 'previousYear'))
            )
            .build()
        )
        .newRowTemplate(value(''), value(undefined), value(undefined))
        .build(),
      secondTable: table(
        'notes.note28.secondTable',
        { id: 'label', label: 'Kortfristiga skulder' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        .addRows((rows) =>
          rows
            .addRow(
              '1',
              noteLabel('Skulder till kreditinstitut'),
              ref(sumAccountsUBs('2400:2419', true)),
              ref(sumAccountsUBs('2400:2419', true, 'previousYear'))
            )
            .addRow(
              '2',
              noteLabel('Skulder till koncernföretag'),
              ref(sumAccountsUBs('2460:2469', true)),
              ref(sumAccountsUBs('2460:2469+2860:2869', true, 'previousYear'))
            )
            .addRow(
              '3',
              noteLabel(
                'Skulder till intresseföretag och gemensamt styrda företag'
              ),
              ref(sumAccountsUBs('2470:2472+2870:2872', true)),
              ref(sumAccountsUBs('2470:2472+2870:2872', true, 'previousYear'))
            )
            .addRow(
              '4',
              noteLabel(
                'Skulder till övriga företag som det finns ett ägarintresse i'
              ),
              ref(sumAccountsUBs('2473:2479+2873:2879', true)),
              ref(sumAccountsUBs('2473:2479+2873:2879', true, 'previousYear'))
            )
            .addRow(
              '5',
              noteLabel('Övriga skulder'),
              value(undefined),
              value(undefined)
            )
            .build()
        )
        .newRowTemplate(value(''), value(undefined), value(undefined))
        .build(),
      sumTable: table(
        'notes.note28.sumTable',
        { id: 'label', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        .addRows((rows) =>
          rows
            .addRow(
              'sum',
              noteLabel('Summa'),
              ref(
                or(
                  sum(
                    sum(
                      id('notes.note28.table.*.year'),
                      id('notes.note28.secondTable.*.year')
                    )
                  ),
                  0
                )
              ),
              ref(
                or(
                  sum(
                    sum(
                      id('notes.note28.table.*.previousYear'),
                      id('notes.note28.secondTable.*.previousYear')
                    )
                  ),
                  0
                )
              )
            )
            .build()
        )
        .build(),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note29: {
      active: false,
      number: value(undefined),
      name: value('Långfristiga konvertibla lån'),
      table: table(
        'notes.note29.table',
        { id: 'morgages', label: 'Lån' },
        { id: 'terms', label: 'Tid och villkor' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        .addRows((rows) =>
          rows
            .addRow(
              '1',
              value(''),
              value(''),
              ref(sumAccountsUBs('2320', true)),
              ref(sumAccountsUBs('2320', true, 'previousYear'))
            )
            .addRow(
              '2',
              value(''),
              value(''),
              ref(sumAccountsUBs('2321', true)),
              ref(sumAccountsUBs('2321', true, 'previousYear'))
            )
            .addRow(
              '3',
              value(''),
              value(''),
              ref(sumAccountsUBs('2322:2329', true)),
              ref(sumAccountsUBs('2322:2329', true, 'previousYear'))
            )
            .build()
        )
        .newRowTemplate(
          value(''),
          value(''),
          value(undefined),
          value(undefined)
        )
        .build(),
      sumTable: table(
        'notes.note29.sumTable',
        { id: 'morgages', label: '' },
        { id: 'terms', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        .addRows((rows) =>
          rows
            .addRow(
              'sum',
              noteLabel('Summa'),
              value(''),
              ref(or(sum(id('notes.note29.table.*.year')), 0)),
              ref(or(sum(id('notes.note29.table.*.previousYear')), 0))
            )
            .build()
        )
        .build(),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note30: {
      active: false,
      number: value(undefined),
      name: value('Checkräkningskredit'),
      table: table(
        'notes.note30.table',
        { id: 'label', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        .addRows((rows) =>
          rows
            .addRow(
              '1',
              noteLabel('Beviljad checkräkningskredit uppgår till'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '2',
              noteLabel('Outnyttjad del'),
              ref(sumAccountsUBs('2330:2339', true)),
              ref(sumAccountsUBs('2330:2339', true, 'previousYear'))
            )
            .build()
        )
        .newRowTemplate(value(''), value(undefined), value(undefined))
        .build(),
      sumTable: table(
        'notes.note30.sumTable',
        { id: 'utilizedCreditAmount', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        .addRows((rows) =>
          rows
            .addRow(
              'sum',
              noteLabel('Utnyttjat kreditbelopp'),
              ref(or(sum(id('notes.note30.table.*.year')), 0)),
              ref(or(sum(id('notes.note30.table.*.previousYear')), 0))
            )
            .build()
        )
        .build(),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note31: {
      active: false,
      number: value(undefined),
      name: value('Byggnadskreditiv'),
      table: table(
        'notes.note31.table',
        { id: 'label', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        .addRows((rows) =>
          rows
            .addRow(
              '1',
              noteLabel('Beviljat byggnadskreditiv uppgår till'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '2',
              noteLabel('Outnyttjad del'),
              ref(sumAccountsUBs('2340:2349', true)),
              ref(sumAccountsUBs('2340:2349', true, 'previousYear'))
            )
            .build()
        )
        .newRowTemplate(value(''), value(undefined), value(undefined))
        .build(),
      sumTable: table(
        'notes.note31.sumTable',
        { id: 'utilizedCreditAmount', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        .addRows((rows) =>
          rows
            .addRow(
              'sum',
              noteLabel('Utnyttjat kreditbelopp'),
              ref(or(sum(id('notes.note31.table.*.year')), 0)),
              ref(or(sum(id('notes.note31.table.*.previousYear')), 0))
            )
            .build()
        )
        .build(),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note32: {
      active: false,
      number: value(undefined),
      name: value('Kortfristiga konvertibla lån'),
      table: table(
        'notes.note32.table',
        { id: 'morgages', label: 'Lån' },
        { id: 'terms', label: 'Tid och villkor' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        .addRows((rows) =>
          rows
            .addRow(
              '1',
              value(''),
              value(''),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '2',
              value(''),
              value(''),
              value(undefined),
              value(undefined)
            )
            .build()
        )
        .newRowTemplate(
          value(''),
          value(''),
          value(undefined),
          value(undefined)
        )
        .build(),
      sumTable: table(
        'notes.note32.sumTable',
        { id: 'morgages', label: '' },
        { id: 'terms', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        .addRows((rows) =>
          rows
            .addRow(
              'sum',
              noteLabel('Summa'),
              value(''),
              ref(or(sum(id('notes.note32.table.*.year')), 0)),
              ref(or(sum(id('notes.note32.table.*.previousYear')), 0))
            )
            .build()
        )
        .build(),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note33: {
      active: false,
      number: value(undefined),
      name: value('Ställda säkerheter'),
      table: table(
        'notes.note33.table',
        { id: 'label', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        .addRows((rows) =>
          rows
            // The IDs aren't ordered because the structure was changed
            // but old IDs were kept to persist old data where present
            .addRow(
              '5',
              noteLabel('Företagsinteckningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '6',
              noteLabel(
                'Varav till koncernföretag, intresseföretag eller gemensamt styrt företag'
              ),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '1',
              noteLabel('Fastighetsinteckningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '2',
              noteLabel(
                'Varav till koncernföretag, intresseföretag eller gemensamt styrt företag'
              ),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '9',
              noteLabel('Tillgångar med äganderättsförbehåll'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '10',
              noteLabel(
                'Varav till koncernföretag, intresseföretag eller gemensamt styrt företag'
              ),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '13',
              noteLabel('Belånade fordringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '14',
              noteLabel(
                'Varav till koncernföretag, intresseföretag eller gemensamt styrt företag'
              ),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '17',
              noteLabel('Andra ställda säkerheter'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '18',
              noteLabel(
                'Varav till koncernföretag, intresseföretag eller gemensamt styrt företag'
              ),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '19',
              noteLabel('Suma ställda säkerheter'),
              value(undefined),
              value(undefined)
            )
            .build()
        )
        .newRowTemplate(value(''), value(undefined), value(undefined))
        .build(),
      sumTable: table(
        'notes.note33.sumTable',
        { id: 'label', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        .addRows((rows) =>
          rows
            .addRow(
              'sum',
              noteLabel('Summa ställda säkerheter'),
              ref(or(sum(id('notes.note33.table.*.year')), 0)),
              ref(or(sum(id('notes.note33.table.*.previousYear')), 0))
            )
            .build()
        )
        .build(),
      commentLabel: field('Kommentar'),
      comment: active(field('')),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note34: {
      active: false,
      number: value(undefined),
      name: value('Eventualförpliktelser'),
      table: table(
        'notes.note34.table',
        { id: 'label', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        .addRows((rows) =>
          rows
            .addRow(
              '1',
              noteLabel('Eventualförpliktelser'),
              value(undefined),
              value(undefined)
            )
            .build()
        )
        .newRowTemplate(value(''), value(undefined), value(undefined))
        .build(),
      commentLabel: field('Kommentar'),
      comment: active(field('')),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note35: {
      active: false,
      number: value(undefined),
      name: value('Väsentliga händelser efter räkenskapsårets slut'),
      inputField: active(field('')),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note36: {
      active: false,
      number: value(undefined),
      name: value(
        'Ekonomiska arrangemang som inte redovisas i balansräkningen'
      ),
      inputField: active(field('')),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note37: {
      active: false,
      number: value(undefined),
      name: value('Koncernförhållanden'),
      table: table('notes.note37.table', { id: 'label', label: '' })
        .addRows((rows) =>
          rows
            .addRowActive(false)
            .addRow(
              '1',
              noteLabel(
                'Bolaget är helägt dotterbolag till XXX AB, Org.nr XXXXXXXXX-XXXX, säte XXX'
              )
            )
            .addRowActive(false)
            .addRow(
              '2',
              noteLabel(
                'Bolaget är moderbolag, men med stöd av ÅRL 7 kap 2§ upprättas inte någon koncernredovisning.'
              )
            )
            .addRowActive(false)
            .addRow(
              '3',
              noteLabel(
                'Bolaget är moderbolag, men med stöd av ÅRL 7 kap 3§ upprättas inte någon koncernredovisning.'
              )
            )
            .addRowActive(false)
            .addRow(
              '4',
              noteLabel(
                'Övergripande koncernredovisning upprättas av: Namn, Org.nr, säte'
              )
            )
            .addRowActive(false)
            .addRow(
              '5',
              noteLabel(
                'Minsta koncernredovisning upprättas av: Namn, Org.nr, säte'
              )
            )
            .build()
        )
        .build(),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note38: {
      active: false,
      number: value(undefined),
      name: value('Definition av nyckeltal'),
      table: table('notes.note38.table', { id: 'label', label: '' })
        .addRows((rows) =>
          rows
            .addRowActive(false)
            .addRow(
              '1',
              noteLabel(
                'Soliditet: Justerat eget kapital i procent av balansomslutning'
              )
            )
            .addRowActive(false)
            .addRow(
              '2',
              noteLabel(
                'Avkastning på eget kapital: Resultat efter finansiella poster i procent av genomsnittligt justerat eget kapital'
              )
            )
            .addRowActive(false)
            .addRow(
              '3',
              noteLabel(
                'Avkastning på totalt kapital: Rörelseresultat med tillägg för finansiella intäkter, i procent av genomsnittlig balansomslutning'
              )
            )
            .addRowActive(false)
            .addRow(
              '4',
              noteLabel(
                'Kassalikviditet: Omsättningstillgångar exklusive varulager i procent av kortfristiga fordringar'
              )
            )
            .build()
        )
        .build(),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note39: {
      active: false,
      number: value(undefined),
      name: value('Bokslutsrapport'),
      table: table('notes.note39.table', { id: 'label', label: '' })
        .addRows((rows) =>
          rows
            .addRowActive(false)
            .addRow(
              '1',
              noteLabel(
                'För denna årsredovisning har en bokslutsrapport upprättats av (Förnamn Efternamn, Redovisningsbyrån AB), som är auktoriserad redovisningskonsult genom medlemskap i (FAR/SRF)'
              )
            )
            .build()
        )
        .build(),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note40: {
      active: false,
      number: value(undefined),
      name: value('Fond för verkligt värde'),
      table: table(
        'notes.note40.table',
        { id: 'label', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        .addRows((rows) =>
          rows
            .addRow(
              '1',
              noteLabel('Belopp vid årets ingång'),
              ref(sumAccountsIBs('2096', true)),
              ref(sumAccountsIBs('2096', true, 'previousYear'))
            )
            .addRow(
              '2',
              noteLabel('Ökning av verkligt värde avseende'),
              value(undefined), // no account ranges for all rows, except 1st above
              value(undefined)
            )
            .addRow(
              '3',
              noteLabel('Finansiella tillgångar som kan säljas'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '4',
              noteLabel('Kassaflödessäkring'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '5',
              noteLabel('Säkring av nettoinvestering i utländska dotterbolag'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '6',
              noteLabel('Minskning av verkligt värde avseende'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '7',
              noteLabel('Finansiella tillgångar som kan säljas'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '8',
              noteLabel('Kassaflödessäkring'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '9',
              noteLabel('Säkring av nettoinvestering i utländska dotterbolag'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '10',
              noteLabel('Överfört till resultaträkningen till följd av'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '11',
              noteLabel('Försäljning av finansiella tillgångar som kan säljas'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '12',
              noteLabel('Avräkning av kassaflödessäkring'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '13',
              noteLabel('Belopp vid årets utgång'),
              sumNotesRowsById(40, range(1, 13), 'year'),
              sumNotesRowsById(40, range(1, 13), 'previousYear')
            )
            .build()
        )
        .newRowTemplate(value(''), value(''), value(undefined))
        .build(),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note41: {
      active: false,
      number: value(undefined),
      name: value('Uppskjuten skattefordran'),
      table: table(
        'notes.note41.table',
        { id: 'label', label: '' },
        { id: 'reportedValue', label: 'Redovisat värde' },
        { id: 'taxValue', label: 'Skattemässigt värde' },
        { id: 'temporaryDifference', label: 'Temporär skillnad' }
      )
        .addRows((rows) =>
          rows
            .addRow(
              '1',
              noteLabel('Avdragsgilla temporära skillnader'),
              value(undefined), // no acc ranges until id 7
              value(undefined),
              value(undefined)
            )
            .addRow(
              '2',
              value(''),
              value(undefined),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '3',
              value(''),
              value(undefined),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '4',
              value(''),
              value(undefined),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '5',
              value(''),
              value(undefined),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '6',
              noteLabel('Skattemässiga underskottsavdrag'),
              value(undefined),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '7',
              noteLabel('Balanserad vinst i eget kapital'),
              ref(sumAccountsUBs('2091+2098:2099', true)),
              value(undefined), //TODO in AGOY-2337 Formula that checks whether the input is bigger than value above * bolagskatt for year of the report
              value(undefined) // if above is true, warn the user. Similar to bokslut's field Vald återföring av periodiseringsfond från tidigare år
            )
            .addRow(
              '8',
              noteLabel('Andra outnyttjade skatteavdrag'),
              value(undefined), // no more acc ranges until end of note
              value(undefined),
              value(undefined)
            )
            .addRow(
              '9',
              value(''),
              value(undefined),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '10',
              value(''),
              value(undefined),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '11',
              value(''),
              value(undefined),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '12',
              value(''),
              value(undefined),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '13',
              noteLabel('Summering'),
              sumNotesRowsById(41, range(1, 13), 'reportedValue'),
              sumNotesRowsById(41, range(1, 13), 'taxValue'),
              sumNotesRowsById(41, range(1, 13), 'temporaryDifference')
            )
            .addRow(
              '14',
              noteLabel('Utgående redovisat värde uppskjuten skattefordran'),
              value(undefined),
              value(undefined),
              value(undefined)
            )
            .build()
        )
        .newRowTemplate(
          value(''),
          value(undefined),
          value(undefined),
          value(undefined)
        )
        .build(),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note42: {
      active: false,
      number: value(undefined),
      name: value('Övriga immateriella anläggningstillgångar'),
      table: table(
        'notes.note42.table',
        { id: 'label', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        .addRows((rows) =>
          rows
            .addRow(
              '1',
              noteLabel('Ingående anskaffningsvärden'),
              ref(sumAccountsIBs('1090:1099', false)),
              ref(sumAccountsIBs('1090:1099', false, 'previousYear'))
            )
            .addRow('2', noteLabel('Inköp'), value(undefined), value(undefined))
            .addRow(
              '3',
              noteLabel('Försäljningar/utrangeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '4',
              noteLabel('Omklassificeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '5',
              noteLabel('Utgående anskaffningsvärden'),
              sumNotesRowsById(42, range(1, 5), 'year'),
              sumNotesRowsById(42, range(1, 5), 'previousYear')
            )
            .addRow(
              '6',
              noteLabel('Ingående avskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '7',
              noteLabel('Försäljningar/utrangeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '8',
              noteLabel('Omklassificeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '9',
              noteLabel('Årets avskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '10',
              noteLabel('Utgående ackumulerade avskrivningar'),
              sumNotesRowsById(42, range(6, 10), 'year'),
              sumNotesRowsById(42, range(6, 10), 'previousYear')
            )
            .addRow(
              '11',
              noteLabel('Ingående uppskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '12',
              noteLabel('Försäljningar/utrangeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '13',
              noteLabel('Omklassificeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '14',
              noteLabel('Årets uppskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '15',
              noteLabel('Årets nedskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '16',
              noteLabel('Årets avskrivningar på uppskrivet belopp'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '17',
              noteLabel('Utgående ackumulerade uppskrivningar netto'),
              sumNotesRowsById(42, range(11, 17), 'year'),
              sumNotesRowsById(42, range(11, 17), 'previousYear')
            )
            .addRow(
              '18',
              noteLabel('Ingående nedskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '19',
              noteLabel('Försäljningar/utrangeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '20',
              noteLabel('Återförd nedskrivning'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '21',
              noteLabel('Omklassificeringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '22',
              noteLabel('Årets nedskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '23',
              noteLabel('Utgående ackumulerade nedskrivningar'),
              sumNotesRowsById(42, range(18, 23), 'year'),
              sumNotesRowsById(42, range(18, 23), 'previousYear')
            )
            .addRow(
              '24',
              noteLabel('Utgående redovisat värde'),
              sumNotesRowsById(42, [5, 10, 17, 23], 'year'),
              sumNotesRowsById(42, [5, 10, 17, 23], 'previousYear')
            )
            .addRow(
              '25',
              noteLabel(
                'Tillgångar anskaffade med offentligt bidrag ingår med redovisat anskaffningsvärde'
              ),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '26',
              noteLabel(
                'I ovanstående redovisat värde ingår leasade tillgångar med'
              ),
              value(undefined),
              value(undefined)
            )
            .build()
        )
        .newRowTemplate(value(''), value(''), value(undefined))
        .build(),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note43: {
      active: false,
      number: value(undefined),
      name: value(
        'Balanserade utgifter för utvecklingsarbeten och liknande arbeten'
      ),
      table: table(
        `notes.note43.table`,
        { id: 'label', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        .addRows((rows) =>
          rows
            .addRow(
              '1',
              noteLabel('Ingående utgifter för utvecklingsarbete'),
              ref(sumAccountsIBs('1000:1017', false)),
              ref(sumAccountsIBs('1000:1017', false, 'previousYear'))
            )
            .addRow(
              '2',
              noteLabel('Upparbetat under året'),
              ref(sumPositiveTransactions('1000:1017')),
              ref(sumPositiveTransactions('1000:1017', 'previousYear'))
            )
            .addRow(
              '3',
              noteLabel('Försäljningar/utrangeringar'),
              ref(sumNegativeTransactions('1000:1017')),
              ref(sumNegativeTransactions('1000:1017', 'previousYear'))
            )
            .addRow(
              '4',
              noteLabel('Omklassificeringar'), // no accounts
              value(undefined),
              value(undefined)
            )
            .addRow(
              '5',
              noteLabel('Utgående ackumulerade utgifter för utvecklingsarbete'),
              sumNotesRowsById(43, range(1, 5), 'year'),
              sumNotesRowsById(43, range(1, 5), 'previousYear')
            )
            .addRow(
              '6',
              noteLabel('Ingående avskrivningar'),
              ref(sumAccountsIBs('1019', false)),
              ref(sumAccountsIBs('1019', false, 'previousYear'))
            )
            .addRow(
              '7',
              noteLabel('Försäljningar/utrangeringar'),
              ref(sumPositiveTransactions('1019')),
              ref(sumPositiveTransactions('1019', 'previousYear'))
            )
            .addRow(
              '8',
              noteLabel('Omklassificeringar'), // no accounts
              value(undefined),
              value(undefined)
            )
            .addRow(
              '9',
              noteLabel('Årets avskrivningar'),
              ref(sumNegativeTransactions('1019')),
              ref(sumNegativeTransactions('1019', 'previousYear'))
            )
            .addRow(
              '10',
              noteLabel('Utgående ackumulerade avskrivningar'),
              sumNotesRowsById(43, range(6, 10), 'year'),
              sumNotesRowsById(43, range(6, 10), 'previousYear')
            )
            .addRow(
              '11',
              noteLabel('Ingående nedskrivningar'),
              ref(sumAccountsIBs('1018', false)),
              ref(sumAccountsIBs('1018', false, 'previousYear'))
            )
            .addRow(
              '12',
              noteLabel('Försäljningar/utrangeringar'),
              ref(sumPositiveTransactions('1018')),
              ref(sumPositiveTransactions('1018', 'previousYear'))
            )
            .addRow(
              '13',
              noteLabel('Återförd nedskrivning'),
              value(undefined), // no accounts
              value(undefined)
            )
            .addRow(
              '14',
              noteLabel('Omklassificeringar'),
              value(undefined), // no accounts
              value(undefined)
            )
            .addRow(
              '15',
              noteLabel('Årets nedskrivningar'),
              ref(sumNegativeTransactions('1018')),
              ref(sumNegativeTransactions('1018', 'previousYear'))
            )
            .addRow(
              '16',
              noteLabel('Utgående ackumulerade nedskrivningar'),
              sumNotesRowsById(43, range(11, 16), 'year'),
              sumNotesRowsById(43, range(11, 16), 'previousYear')
            )
            .addRow(
              '17',
              noteLabel('Utgående redovisat värde'),
              sumNotesRowsById(43, [5, 10, 16], 'year'),
              sumNotesRowsById(43, [5, 10, 16], 'previousYear')
            )
            .addRow(
              '18',
              noteLabel(
                'Tillgångar anskaffade med offentligt bidrag ingår med redovisat anskaffningsvärde'
              ),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '19',
              noteLabel(
                'I ovanstående redovisat värde ingår leasade tillgångar med'
              ),
              value(undefined),
              value(undefined)
            )
            .build()
        )
        .newRowTemplate(value(''), value(undefined), value(undefined))
        .build(),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note44: {
      active: false,
      number: value(undefined),
      name: value(
        'Tillgångar, avsättningar och skulder som avser flera poster'
      ),
      table: table(
        `notes.note44.table`,
        {
          id: 'typeOfAssets',
          label:
            'Typ av tillgångar, avsättningar och skulder som avser flera poster',
        },
        {
          id: 'balanceSheetItem',
          label: 'Typ av balanspost',
        },
        {
          id: 'amount',
          label: 'Belopp',
        }
      )
        .addRows((rows) =>
          rows.addRow('1', value(''), value(''), value(undefined)).build()
        )
        .newRowTemplateGenerator((id, baseId) => ({
          id,
          active: true,
          cells: {
            typeOfAssets: value(''),
            balanceSheetItem: value(''),
            amount: value(undefined),
          },
        }))
        .build(),
      commentLabel: field('Kommentar'),
      comment: active(field('')),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note45: {
      active: false,
      number: value(undefined),
      name: field('Exceptionella intäkter'),
      inputField: active(field('Benämning på exceptionell intäkt samt belopp')),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note47: {
      active: false,
      number: value(undefined),
      name: value('Väsentliga händelser efter räkenskapsårets utgång'),
      inputField: active(field('')),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note48: {
      active: false,
      number: value(undefined),
      name: field('Exceptionella kostnader'),
      inputField: active(
        field('Benämning på exceptionell kostnad samt belopp')
      ),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    custom: {
      type: 'sections',
      sections: [],
      newSectionTemplate: {
        active: false,
        number: { type: 'number', value: 0 },
        name: { type: 'string', value: 'Ny not' },
        group: { type: 'string', value: 'other' },
        section: { type: 'string', value: '' },
        validForReportType: {
          k2: true,
          k3: true,
        },
        commentTitle: { type: 'string', value: 'Kommentar' },
        typeOne: field('Egen fritext'),
        typeTwo: table(
          'notes.custom.typeTwoTable',
          { id: 'label', label: '' },
          {
            id: 'year',
            label: year(financialYear, financialYears, period) || '',
          },
          {
            id: 'previousYear',
            label: previousYear(financialYear, financialYears) || '',
          }
        )
          .newColumnTemplate((column) => column.setType('string').build())
          .newRowTemplate(value(''), value(undefined), value(undefined))
          .build(),
        typeThree: table(
          'notes.custom.typeThreeTable',
          { id: 'label', label: '' },
          { id: 'val1', label: '' },
          { id: 'val2', label: '' },
          { id: 'val3', label: '' },
          {
            id: 'year',
            label: year(financialYear, financialYears, period) || '',
          },
          {
            id: 'previousYear',
            label: previousYear(financialYear, financialYears) || '',
          }
        )
          .newColumnTemplate((column) => column.setType('string').build())
          .newRowTemplate(
            value(''),
            value(''),
            value(''),
            value(''),
            value(undefined),
            value(undefined)
          )
          .build(),
      },
    },
  };
};

export const notesReferences = (): Record<string, string> => ({});

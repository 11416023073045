import { useContext } from 'react';
import { DocumentConfiguration } from '@agoy/annual-report-document';
import AnnualReportDataServiceContext from '_annual-report/service/AnnualReportDataServiceContext';
import useObservable from 'utils/useObservable';

const useDocumentConfiguration = (): DocumentConfiguration | undefined => {
  const dataService = useContext(AnnualReportDataServiceContext);

  return useObservable(dataService.annualReport.documentConfiguration);
};

export default useDocumentConfiguration;

import {
  FormPartContentDefinition,
  TaxDeclarationFormDefinition,
} from '@agoy/tax-declaration-document';
import { RootState } from '..';
import { FinancialYearDocuments } from '../../../_tax/types';
import { TaxDeclarationForm, TaxDeclationFormState } from './types';

export const findForm = (
  forms: TaxDeclarationForm[],
  id: string
): TaxDeclarationForm | undefined => {
  return forms.reduce((found: TaxDeclarationForm | undefined, form) => {
    if (found) {
      return found;
    }
    if (form.id === id) {
      return form;
    }

    return form.subForms ? findForm(form.subForms, id) : undefined;
  }, undefined);
};

export const definitionOfViewedForm = (
  state: RootState
): TaxDeclarationFormDefinition | undefined => {
  const { taxDeclarationView } = state;
  const { viewFormId } = taxDeclarationView;
  return viewFormId ? definitionOfForm(state, viewFormId) : undefined;
};

export const definitionOfForm = (
  state: RootState,
  formId: string,
  formClientId?: string,
  shouldGetForChildCompany?: boolean
): TaxDeclarationFormDefinition | undefined => {
  const { taxDeclarationView } = state;
  const { years } = taxDeclarationView;

  return Object.keys(years)
    .map((year) => {
      const yearData = years[year];
      if (!yearData) {
        return undefined;
      }
      const { forms, definitions, connectionsChart } = yearData;
      let formType;
      if (shouldGetForChildCompany && connectionsChart) {
        const connectionForms = connectionsChart.find(
          (conn) => conn.clientId === formClientId
        )?.forms;
        formType = connectionForms?.find((form) => form.id === formId)?.type;
      } else {
        formType = findForm(forms, formId)?.type;
      }

      if (formType && definitions) {
        const definition = definitions[formType];
        return definition;
      }
      return undefined;
    })
    .find((item) => item);
};

export const partsForViewedForm = (
  state: RootState
): FormPartContentDefinition[] | undefined => {
  return definitionOfViewedForm(state)?.parts;
};

export const viewedFormState = (
  state: RootState
): TaxDeclationFormState | undefined => {
  const { viewFormId, formData } = state.taxDeclarationView;
  return viewFormId ? formData[viewFormId] : undefined;
};

export const selectFormById = (
  state: RootState,
  formId: string
): TaxDeclarationForm | undefined => {
  const {
    taxDeclarationView: { years },
  } = state;

  return Object.keys(years)
    .map((year) => {
      const yearData = years[year];

      return yearData ? findForm(yearData.forms, formId) : undefined;
    })
    .find((item) => item);
};

/**
 * viewingForm, get the current form (not form data) which is selected
 * @param state
 */
export const viewingForm = (
  state: RootState
): TaxDeclarationForm | undefined => {
  const {
    taxDeclarationView: { viewFormId },
  } = state;
  if (!viewFormId) return undefined;

  return selectFormById(state, viewFormId);
};

/**
 * currentDocuments, selects the documents that exists for the current customer (from customerView) and the
 * financialYear given by taxDeclaration.selectedDeclarationYear
 *
 * @param state
 */
export const currentDocuments = (
  state: RootState
): FinancialYearDocuments | undefined => {
  const { currentCustomer, currentFinancialYear: selectedDeclarationYear } =
    state.customerView;
  if (selectedDeclarationYear && currentCustomer) {
    return state.document[currentCustomer]?.[selectedDeclarationYear];
  }
  return undefined;
};

import React, { useContext, useCallback } from 'react';
import TaxTableRow, { TaxTableRowProps } from './TaxTableRow';
import TaxesDataContext from '../../../service/TaxesDataContext';

const IncreaseOfCompanyTaxFromAccrualFundsRow = (props: TaxTableRowProps) => {
  const { service } = useContext(TaxesDataContext);

  const updateValue = useCallback(
    (rowId: string, value: number) => {
      if (props.part !== 'header') {
        service.updateTaxTableRowValue(props.part, rowId, value);
      }
    },
    [props.part, service]
  );

  return (
    <TaxTableRow
      {...props}
      onChangeLabel={undefined}
      onChangeValue={updateValue}
    />
  );
};

export default IncreaseOfCompanyTaxFromAccrualFundsRow;

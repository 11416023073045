import React from 'react';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  border-bottom: solid 2px black;
  padding: 5px 0;
`;

const HeaderInfo = styled.div`
  display: flex;
`;

const InfoRow = styled.div`
  display: flex;
  align-items: start;
  margin: 0;
`;

const SummeryRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  margin: 0;
`;

interface GroupFieldsProps {
  customWidth: number;
  customAlign?: string;
}

const Info = styled.p`
  width: ${(p: GroupFieldsProps) => p.customWidth}px;
  margin: 0;
  text-align: ${(p: GroupFieldsProps) => p.customAlign || 'left'};
`;

const HeadInfo = styled(Info)`
  font-weight: bold;
  white-space: nowrap;
`;

const TransactionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

type ItemProp = {
  id: string;
  align: string;
  value: string;
};

type SummariesProps = {
  id: string;
  title: string;
  amount: string;
};

type GroupItemProps = {
  id: string;
  colsAmount: number;
  headlines: string[];
  fields: ItemProp[][];
  summaries: SummariesProps[] | null;
};

const GroupItem = ({
  id,
  headlines,
  colsAmount,
  fields,
  summaries,
}: GroupItemProps): JSX.Element => {
  const { formatMessage } = useIntl();
  return (
    <Container>
      <HeaderInfo>
        {headlines.map((item) => (
          <HeadInfo key={item + id} customWidth={colsAmount}>
            {item}
          </HeadInfo>
        ))}
      </HeaderInfo>
      <TransactionContainer>
        {fields.map((items, index) => (
          <InfoRow key={`KundInfoRow${index}`}>
            {items.map((item) => (
              <Info
                key={item.id}
                customAlign={item.align}
                customWidth={colsAmount}
              >
                {item.value}
              </Info>
            ))}
          </InfoRow>
        ))}
      </TransactionContainer>
      {summaries && (
        <SummeryRow>
          {summaries.map((item) => (
            <InfoRow key={item.id}>
              <HeadInfo
                key={`${item.title}1${item.id}`}
                customWidth={colsAmount}
              >
                {item.title}
              </HeadInfo>
              <HeadInfo
                key={`${item.title}2${item.id}`}
                customWidth={colsAmount}
              >
                {item.amount}
              </HeadInfo>
            </InfoRow>
          ))}
        </SummeryRow>
      )}
    </Container>
  );
};

export default GroupItem;

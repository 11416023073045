import { AgoyTable, TableChange } from '..';
import { AgoyDocumentStructure } from '../document';
import updateRow from './helpers/updateRow';
import updateDocument from './helpers/updateDocument';
import { Errors, OperationResult, State } from './types';

export interface UpdateRowsPayload {
  id: string;
  sortKey?: number;
  active?: boolean;
}

const updateTableRows = <T extends AgoyDocumentStructure>(
  structure: T,
  state: State<T>,
  rows: UpdateRowsPayload[]
): OperationResult<T> => {
  // Assuming that all rows belong to the same table.
  if (rows.length === 0) {
    return false;
  }

  return updateDocument(structure, state, rows[0].id, {
    table: (keys, tableId, props) => {
      const result = rows.reduce(
        (
          currentState: [AgoyTable, TableChange | undefined] | Errors,
          { id, sortKey, active }
        ): [AgoyTable, TableChange | undefined] | Errors => {
          if (typeof currentState === 'string') {
            return currentState;
          }
          if (!id.startsWith(tableId)) {
            return currentState;
          }
          const subId = id.substring(tableId.length + 1).split('.');

          const updater = updateRow(subId, (row, change) => {
            const newRow = { ...row };
            const newRowChange = { ...change.row };

            if (sortKey !== undefined) {
              newRow.sortKey = sortKey;
              newRowChange.sortKey = sortKey;
            }
            if (active !== undefined) {
              newRow.active = active;
              newRowChange.active = active;
            }

            return [newRow, { ...change, row: newRowChange }];
          });
          const updatedState = updater(
            currentState[0],
            currentState[1] || { type: 'update' }
          );
          if (!updatedState) {
            return currentState;
          }
          return updatedState;
        },
        [props.node, props.changes]
      );
      if (Array.isArray(result)) {
        return {
          ...props,
          node: result[0],
          changes: result[1],
        };
      }
      return props;
    },
  });
};

export default updateTableRows;

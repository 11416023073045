import { value, ref, table } from '@agoy/document';
import { NoteThreeCommentTwoTableSection, IxbrlCell } from '../../../../common';
import {
  sumAccountsUBs,
  sumNotesRowsById,
} from '../../../../common/utils/accountSumsUtil';
import {
  active,
  field,
  range,
  ixbrlCell,
  ixbrlField,
} from '../../../../common/utils/util';
import { year, previousYear } from '../../../../common/utils/date-util';
import { NoteAccountRanges } from '../types';

const MONETARY_ITEM_TYPE = 'monetaryItemType';

export const note70Accounts: NoteAccountRanges[] = [
  {
    rowId: '1',
    ranges: [[1400, 1419]],
    cellLabel: 'Råvaror och förnödenheter',
    action: 'sumAccountsUBs',
  },
  {
    rowId: '2',
    ranges: [[1440, 1449]],
    cellLabel: 'Varor under tillverkning',
    action: 'sumAccountsUBs',
  },
  {
    rowId: '3',
    ranges: [[1450, 1469]],
    cellLabel: 'Färdiga varor och handelsvaror',
    action: 'sumAccountsUBs',
  },
  {
    rowId: '4',
    ranges: [[1490, 1499]],
    cellLabel: 'Övriga lagertillgångar',
    action: 'sumAccountsUBs',
  },
  {
    rowId: '5',
    ranges: [[1470, 1479]],
    cellLabel: 'Pågående arbete för annans räkning',
    action: 'sumAccountsUBs',
  },
];

export const getNote70 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null
): NoteThreeCommentTwoTableSection => {
  return {
    active: false,
    number: value(undefined),
    name: value('Varulager m.m.'),
    table: table<IxbrlCell>(
      'notes.note70.table',
      {
        id: 'label',
        label: 'Fördelning av varulager m.m.',
      },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      .addRows((rows) =>
        rows
          .addRow(
            '1',
            noteLabel('Råvaror och förnödenheter'),
            ixbrlCell(ref(sumAccountsUBs('1400:1419', false)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:LagerRavarorFornodenheter',
              contextRef: 'balans0',
              saldo: 'debit',
            }),
            ixbrlCell(ref(sumAccountsUBs('1400:1419', false, 'previousYear')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:LagerRavarorFornodenheter',
              contextRef: 'balans1',
              saldo: 'debit',
            })
          )
          .addRow(
            '2',
            noteLabel('Varor under tillverkning'),
            ixbrlCell(ref(sumAccountsUBs('1440:1449', false)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:LagerVarorUnderTillverkning',
              contextRef: 'balans0',
              saldo: 'debit',
            }),
            ixbrlCell(ref(sumAccountsUBs('1440:1449', false, 'previousYear')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:LagerVarorUnderTillverkning',
              contextRef: 'balans1',
              saldo: 'debit',
            })
          )
          .addRow(
            '3',
            noteLabel('Färdiga varor och handelsvaror'),
            ixbrlCell(ref(sumAccountsUBs('1450:1469', false)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:LagerFardigaVarorHandelsvaror',
              contextRef: 'balans0',
              saldo: 'debit',
            }),
            ixbrlCell(ref(sumAccountsUBs('1450:1469', false, 'previousYear')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:LagerFardigaVarorHandelsvaror',
              contextRef: 'balans1',
              saldo: 'debit',
            })
          )
          .addRow(
            '4',
            noteLabel('Övriga lagertillgångar'),
            ixbrlCell(ref(sumAccountsUBs('1490:1499', false)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OvrigaLagertillgangar',
              contextRef: 'balans0',
              saldo: 'debit',
            }),
            ixbrlCell(ref(sumAccountsUBs('1490:1499', false, 'previousYear')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OvrigaLagertillgangar',
              contextRef: 'balans1',
              saldo: 'debit',
            })
          )
          .addRow(
            '5',
            noteLabel('Pågående arbete för annans räkning'),
            ixbrlCell(ref(sumAccountsUBs('1470:1479', false)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeArbetenAnnansRakningOmsattningstillgangar',
              contextRef: 'balans0',
              saldo: 'debit',
            }),
            ixbrlCell(ref(sumAccountsUBs('1470:1479', false, 'previousYear')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeArbetenAnnansRakningOmsattningstillgangar',
              contextRef: 'balans1',
              saldo: 'debit',
            })
          )
          .addRow(
            '6',
            noteLabel('Förskott till leverantörer'),
            ixbrlCell(ref(sumAccountsUBs('1480:1489', false)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForskottTillLeverantorer',
              contextRef: 'balans0',
              saldo: 'debit',
            }),
            ixbrlCell(ref(sumAccountsUBs('1480:1489', false, 'previousYear')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForskottTillLeverantorer',
              contextRef: 'balans1',
              saldo: 'debit',
            })
          )
          .addRow(
            '7',
            noteLabel('Summa varulager m.m.'),
            ixbrlCell(sumNotesRowsById(70, range(1, 7), 'year'), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:VarulagerMm',
              contextRef: 'balans0',
              saldo: 'debit',
            }),
            ixbrlCell(sumNotesRowsById(70, range(1, 7), 'previousYear'), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:VarulagerMm',
              contextRef: 'balans1',
              saldo: 'debit',
            })
          )
          .addRow(
            '8',
            noteLabel(
              'Ränta som räknats in i anskaffningsvärdet för varulager'
            ),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:RantaRaknatInAnskaffningsvarde',
              contextRef: 'balans0',
              saldo: 'debit',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:RantaRaknatInAnskaffningsvarde',
              contextRef: 'balans1',
              saldo: 'debit',
            })
          )
          .build()
      )
      .newRowTemplate(value(''), value(undefined), value(undefined))
      .build(),
    firstTableCommentLabel: field('Fördelning av varulager kommentar'),
    firstTableComment: ixbrlField(active(field('')), {
      type: 'stringItemType',
      name: 'se-gen-base:FordelningVarulagerKommentar',
      contextRef: 'period0',
    }),
    secondTableTitle: field(''),
    secondTable: table<IxbrlCell>(
      'notes.note70.secondTable',
      {
        id: 'label',
        label: 'Skillnadsbelopp varulager',
      },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      .addRows((rows) =>
        rows
          .addRow(
            '1',
            noteLabel('Varulager redovisat till nettoförsäljningsvärde'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:VarulagerRedovisatNettoforsaljningsvarde',
              contextRef: 'balans0',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:VarulagerRedovisatNettoforsaljningsvarde',
              contextRef: 'balans1',
            })
          )
          .addRow(
            '2',
            noteLabel('Varulager redovisat till anskaffningsvärde'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:VarulagerRedovisatAnskaffningsvarde',
              contextRef: 'balans0',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:VarulagerRedovisatAnskaffningsvarde',
              contextRef: 'balans1',
            })
          )
          .addRow(
            '3',
            noteLabel('Skillnadsbelopp'),
            ixbrlCell(
              sumNotesRowsById(70, range(1, 3), 'year', 'secondTable'),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:VarulagerSkillnadsbelopp',
                contextRef: 'balans0',
              }
            ),
            ixbrlCell(
              sumNotesRowsById(70, range(1, 3), 'previousYear', 'secondTable'),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:VarulagerSkillnadsbelopp',
                contextRef: 'balans1',
              }
            )
          )
          .addRow(
            '4',
            noteLabel('Fördelning av skillnadsbelopp'),
            noteLabel(''),
            noteLabel('')
          )
          .addRow(
            '5',
            noteLabel('Råvaror och förnödenheter'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:VarulagerSkillnadAnskaffningNettoforsaljningVardeRavarorFornodenheter',
              contextRef: 'balans0',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:VarulagerSkillnadAnskaffningNettoforsaljningVardeRavarorFornodenheter',
              contextRef: 'balans1',
            })
          )
          .addRow(
            '6',
            noteLabel('Varor under tillverkning'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:VarulagerSkillnadAnskaffningNettoforsaljningVardeVarorUnderTillverkning',
              contextRef: 'balans0',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:VarulagerSkillnadAnskaffningNettoforsaljningVardeVarorUnderTillverkning',
              contextRef: 'balans1',
            })
          )
          .addRow(
            '7',
            noteLabel('Färdiga varor och handelsvaror'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:VarulagerSkillnadAnskaffningNettoforsaljningVardeFardigaVarorHandelsvaror',
              contextRef: 'balans0',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:VarulagerSkillnadAnskaffningNettoforsaljningVardeFardigaVarorHandelsvaror',
              contextRef: 'balans1',
            })
          )
          .addRow(
            '8',
            noteLabel('Övriga lagertillgångar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:VarulagerSkillnadAnskaffningNettoforsaljningVardeOvrigaLagertillgangar',
              contextRef: 'balans0',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:VarulagerSkillnadAnskaffningNettoforsaljningVardeOvrigaLagertillgangar',
              contextRef: 'balans1',
            })
          )
          .addRow(
            '9',
            noteLabel('Pågående arbete för annans räkning'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:VarulagerSkillnadAnskaffningNettoforsaljningVardePagaendeArbeteAnnansRakning',
              contextRef: 'balans0',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:VarulagerSkillnadAnskaffningNettoforsaljningVardePagaendeArbeteAnnansRakning',
              contextRef: 'balans1',
            })
          )
          .addRow(
            '10',
            noteLabel('Förskott till leverantör'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:VarulagerSkillnadAnskaffningNettoforsaljningVardeForskottLeverantor',
              contextRef: 'balans0',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:VarulagerSkillnadAnskaffningNettoforsaljningVardeForskottLeverantor',
              contextRef: 'balans1',
            })
          )
          .build()
      )
      .newRowTemplate(value(''), value(undefined), value(undefined))
      .build(),
    secondTableCommentLabel: field('Skillnadsbelopp Varulager Kommentar'),
    secondTableComment: ixbrlField(active(field('')), {
      type: 'stringItemType',
      name: 'se-gen-base:SkillnadsbeloppVarulagerKommentar',
      contextRef: 'period0',
    }),
    commentLabel: field('Kommentar'),
    comment: ixbrlField(active(field('')), {
      type: 'stringItemType',
      name: 'se-gen-base:NotVarulagerKommentar',
      contextRef: 'period0',
    }),
    group: value(undefined),
    section: value(undefined),
    validForReportType: {
      k2: false,
      k3: true,
    },
  };
};

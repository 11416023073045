import { parse, format, parseFormat } from '@agoy/dates';
import {
  add,
  differenceInCalendarYears,
  differenceInMonths,
  sub,
} from 'date-fns';
import { AgoyTableRow } from '@agoy/document';
import { ccyFormat } from '@agoy/common';

import { FinancialReport } from '@agoy/financial-report-document';
import { getKeyValueAmount } from '_financial-report/utils';

const series = [
  'nettos',
  'nettoaccounts',
  'bruttokostnader',
  'ovrigaKostnader',
  'personalkostnader',
  'avskrivningar',
  'reaForlust',
];

const getStartPeriodInsideFinancialYear = (
  endPeriod: Date,
  comparePeriod: number,
  currentFinancialYear: string
): Date => {
  let startPeriod = sub(endPeriod, { months: comparePeriod - 1 });
  const [start] = currentFinancialYear.split('-');
  const startYear = parse(start, 'yyyyMMdd');
  if (startYear > startPeriod) {
    startPeriod = startYear;
  }
  return startPeriod;
};

export const getYearData = (
  year: Date,
  count: number,
  rows: AgoyTableRow[] | undefined,
  currentYear: boolean
): number[] => {
  const periods = Array.from(Array(count).keys()).map((item) => {
    const month = add(year, { months: item });
    return format(month, currentYear ? 'MMMyy' : 'MMMyy');
  });
  const data = series?.map((seria) => {
    const rowsData = rows?.find((row) => {
      return row.id === seria;
    });
    if (rowsData && rowsData.cells) {
      const cellsData = rowsData.cells;
      const periodData = periods.map((item) => {
        const cell = cellsData[item];
        if (
          cell &&
          cell.type &&
          cell.type === 'ref' &&
          typeof cell.value === 'number'
        ) {
          return cell.value;
        }
        return 0;
      });
      return Math.round(
        periodData.reduce((prev, next) => {
          return (prev || 0) + (next || 0);
        }, 0)
      );
    }
  });
  return data as number[];
};

export const getPeriodData = (
  rows: AgoyTableRow[] | undefined,
  isPrev: boolean
): number[] => {
  const data = series?.map((seria) => {
    const rowsData = rows?.find((row) => {
      return row.id === seria;
    });
    if (rowsData && rowsData.id !== 'assets' && rowsData.cells) {
      let cell = rowsData.cells.previousPeriod;
      if (!isPrev) {
        cell = rowsData.cells.currentPeriod;
      }
      return cell.type === 'ref' && typeof cell.value === 'number'
        ? cell.value
        : 0;
    }
    return 0;
  });
  return data as number[];
};

export const prepareNote2Period = (
  text: string,
  year: Date,
  currentData: number[],
  previousData: number[],
  comparePeriod: number,
  currentFinancialYear: string
): string => {
  let result = text;
  if (result.length === 0) {
    return result;
  }
  if (result.lastIndexOf('{currentDate}') > 0) {
    const endPeriod = year;
    const startPeriod = getStartPeriodInsideFinancialYear(
      endPeriod,
      comparePeriod,
      currentFinancialYear
    );
    let periodTitle = `${parseFormat(startPeriod, 'MMM')}-${parseFormat(
      endPeriod,
      'MMM yyyy'
    )}`;
    if (differenceInCalendarYears(startPeriod, endPeriod) !== 0) {
      periodTitle = `${parseFormat(startPeriod, 'MMM-yy')}-${parseFormat(
        endPeriod,
        'MMM-yy'
      )}`;
    }
    if (differenceInMonths(startPeriod, endPeriod) === 0) {
      periodTitle = `${parseFormat(startPeriod, 'MMM-yy')}`;
    }
    result = result.replace(/{currentDate}/g, periodTitle);
  }
  if (result.lastIndexOf('{currentSales}') > 0) {
    const currentSales = currentData[0];
    const value = ccyFormat(currentSales, 0);
    result = result.replace(/{currentSales}/g, value);
  }
  if (result.lastIndexOf('{totalSales}') > 0) {
    const totalSales = currentData[0] - previousData[0];
    const sign = totalSales < 0 ? 'minskat' : 'ökat';
    const value = ccyFormat(totalSales, 0);
    result = result.replace(/{totalSalesSign}/g, sign);
    result = result.replace(/{totalSales}/g, value);
  }
  if (result.lastIndexOf('personalExpenses}') > 0) {
    const personalkostnader = currentData[4];
    const value = ccyFormat(personalkostnader, 0);
    result = result.replace(/{personalExpenses}/g, value);
  }
  if (result.lastIndexOf('{currentExpenses}') > 0) {
    const currentExpenses =
      currentData[2] +
      currentData[3] +
      currentData[4] +
      currentData[5] +
      currentData[6];
    const value = ccyFormat(currentExpenses, 0);
    result = result.replace(/{currentExpenses}/g, value);
  }
  return result;
};

export const prepareNote2Year = (
  text: string,
  year: Date,
  currentYear: number[],
  previousYear: number[]
): string => {
  let result = text;
  if (result.lastIndexOf('{periodSales}') > 0) {
    const sumCurrent = ccyFormat(currentYear[0], 0);
    result = result.replace(/{periodSales}/g, sumCurrent);
  }
  if (result.lastIndexOf('{changingPeriodSales}') > 0) {
    const changing = currentYear[0] - previousYear[0];
    const sign = changing < 0 ? 'minskat' : 'ökat';
    const rowResult = ccyFormat(Math.abs(changing), 0);
    result = result.replace(/{changingPeriodSales}/g, rowResult);
    result = result.replace(/{changingPeriodSalesSign}/g, sign);
  }
  if (result.lastIndexOf('{changingPeriodExpenses}') > 0) {
    const currentExpenses =
      currentYear[2] +
      currentYear[3] +
      currentYear[4] +
      currentYear[5] +
      currentYear[6];
    const previousExpenses =
      previousYear[2] +
      previousYear[3] +
      previousYear[4] +
      previousYear[5] +
      previousYear[6];
    const changingPeriodExpenses = currentExpenses - previousExpenses;
    const sign = changingPeriodExpenses < 0 ? 'minskat' : 'ökat';
    const value = ccyFormat(Math.abs(changingPeriodExpenses), 0);
    result = result.replace(/{changingPeriodExpenses}/g, value);
    result = result.replace(/{changingPeriodExpensesSign}/g, sign);
  }

  return result;
};

export const prepareNote1 = (
  note: string,
  user: string,
  customer: string,
  year: Date,
  comparePeriod: number,
  currentFinancialYear: string
): string => {
  let result = note;
  if (result.lastIndexOf('{currentDate}') > 0) {
    const endPeriod = year;
    const startPeriod = getStartPeriodInsideFinancialYear(
      endPeriod,
      comparePeriod,
      currentFinancialYear
    );
    let periodTitle = `${parseFormat(startPeriod, 'MMM')}-${parseFormat(
      endPeriod,
      'MMM yyyy'
    )}`;
    if (differenceInCalendarYears(startPeriod, endPeriod) !== 0) {
      periodTitle = `${parseFormat(startPeriod, 'MMM-yy')}-${parseFormat(
        endPeriod,
        'MMM-yy'
      )}`;
    }
    if (differenceInMonths(startPeriod, endPeriod) === 0) {
      periodTitle = `${parseFormat(startPeriod, 'MMM-yy')}`;
    }
    result = result.replace(/{currentDate}/g, periodTitle);
  }
  if (result.lastIndexOf('{UserName}') > 0) {
    result = result.replace(/{UserName}/g, user);
  }
  if (result.lastIndexOf('{ClientName}') > 0) {
    result = result.replace(/{ClientName}/g, customer);
  }
  return result;
};

export const prepareKeyValue = (
  diagramRRBR: FinancialReport['diagramRRBR'],
  percent: number,
  type: number
): number => {
  const result = getKeyValueAmount(diagramRRBR, type);
  return result * percent;
};

export const prepareSalesExpensesChange = (
  keyValue: number,
  currentYear: number[],
  previousYear: number[],
  lessText: string,
  moreText: string
): string => {
  let result = '';
  const currentExpenses =
    currentYear[2] +
    currentYear[3] +
    currentYear[4] +
    currentYear[5] +
    currentYear[6];
  const previousExpenses =
    previousYear[2] +
    previousYear[3] +
    previousYear[4] +
    previousYear[5] +
    previousYear[6];
  const changingExpenses = currentExpenses - previousExpenses;

  const changingSale = currentYear[0] - previousYear[0];

  const difference = changingSale - changingExpenses;
  if (difference < -keyValue) {
    result = lessText;
  }
  if (difference > keyValue) {
    result = moreText;
  }
  if (result.length > 0) {
    let sign = changingSale < 0 ? 'minskat' : 'ökat';
    result = result.replaceAll('{SignChangingSales}', sign);
    result = result.replaceAll(
      '{ChangingSales}',
      ccyFormat(Math.abs(changingSale), 0)
    );
    sign = changingExpenses < 0 ? 'minskat' : 'ökat';
    result = result.replaceAll('{SignChangingExpenses}', sign);
    result = result.replaceAll(
      '{ChangingExpenses}',
      ccyFormat(Math.abs(changingExpenses), 0)
    );
    result = result.replaceAll(
      '{DiffSalesExpenses}',
      ccyFormat(Math.abs(difference), 0)
    );
  }
  return result;
};

export const prepareSalesGrossExpensesChange = (
  keyValue: number,
  currentYear: number[],
  previousYear: number[],
  lessText: string,
  moreText: string
): string => {
  let result = '';
  const changingGrossExpenses = currentYear[2] - previousYear[2];
  const changingPersonalExpenses = currentYear[4] - previousYear[4];
  const changingSale = currentYear[0] - previousYear[0];
  const differenceGross = changingSale - changingGrossExpenses;
  const differencePersonal = changingSale - changingPersonalExpenses;
  let boolResult =
    differenceGross < -keyValue && differencePersonal < -keyValue;
  boolResult = !boolResult ? differenceGross < -keyValue : false;
  if (boolResult) {
    result = lessText;
  }
  boolResult = differenceGross > keyValue && differencePersonal > keyValue;
  boolResult = !boolResult ? differenceGross > keyValue : false;
  if (boolResult) {
    result = moreText;
  }
  if (result.length > 0) {
    let sign = changingSale < 0 ? 'minskat' : 'ökat';
    result = result.replaceAll('{SignChangingSales}', sign);
    result = result.replaceAll(
      '{ChangingSales}',
      ccyFormat(Math.abs(changingSale), 0)
    );
    sign = changingGrossExpenses < 0 ? 'minskat' : 'ökat';
    result = result.replaceAll('{SignChangingGrossExpenses}', sign);
    result = result.replaceAll(
      '{ChangingGrossExpenses}',
      ccyFormat(Math.abs(changingGrossExpenses), 0)
    );
    result = result.replaceAll(
      '{DiffSaleGrosExpenses}',
      ccyFormat(Math.abs(differenceGross), 0)
    );
  }
  return result;
};

export const prepareSalesPersonalExpensesChange = (
  keyValue: number,
  currentYear: number[],
  previousYear: number[],
  lessText: string,
  moreText: string
): string => {
  let result = '';
  const changingGrossExpenses = currentYear[2] - previousYear[2];
  const changingPersonalExpenses = currentYear[4] - previousYear[4];
  const changingSale = currentYear[0] - previousYear[0];
  const differenceGross = changingSale - changingGrossExpenses;
  const differencePersonal = changingSale - changingPersonalExpenses;
  let boolResult =
    differenceGross < -keyValue && differencePersonal < -keyValue;
  if (boolResult) {
    boolResult = differencePersonal > differenceGross;
  } else {
    boolResult = differencePersonal < -keyValue;
  }
  if (boolResult) {
    result = lessText;
  }
  boolResult = differenceGross > keyValue && differencePersonal > keyValue;
  if (boolResult) {
    boolResult = differencePersonal < differenceGross;
  } else {
    boolResult = differencePersonal > keyValue;
  }
  if (boolResult) {
    result = moreText;
  }
  if (result.length > 0) {
    let sign = changingSale < 0 ? 'minskat' : 'ökat';
    result = result.replaceAll('{SignChangingSales}', sign);
    result = result.replaceAll(
      '{ChangingSales}',
      ccyFormat(Math.abs(changingSale), 0)
    );
    sign = changingSale > 0 ? 'minskat' : 'ökat';
    result = result.replaceAll('{SignChangingPersonalExpenses}', sign);
    result = result.replaceAll(
      '{ChangingPersonalExpenses}',
      ccyFormat(Math.abs(changingPersonalExpenses), 0)
    );
    result = result.replaceAll(
      '{DiffSalePersonalExpenses}',
      ccyFormat(Math.abs(differencePersonal), 0)
    );
  }
  return result;
};

export const prepareChart2Title = (
  title: string,
  currentFinancialYear: string | undefined,
  currentPeriod: string | null,
  comparePeriod: number
): string => {
  if (!currentFinancialYear || !currentPeriod) {
    return title;
  }
  const endPeriod = parse(currentPeriod, 'yyyyMMdd');
  const startPeriod = getStartPeriodInsideFinancialYear(
    endPeriod,
    comparePeriod,
    currentFinancialYear
  );
  let periodTitle = `${parseFormat(startPeriod, 'MMM')}-${parseFormat(
    endPeriod,
    'MMM yyyy'
  )}`;
  if (differenceInCalendarYears(startPeriod, endPeriod) !== 0) {
    periodTitle = `${parseFormat(startPeriod, 'MMM-yy')}-${parseFormat(
      endPeriod,
      'MMM-yy'
    )}`;
  }
  if (differenceInMonths(startPeriod, endPeriod) === 0) {
    periodTitle = `${parseFormat(startPeriod, 'MMM-yy')}`;
  }
  const result = title.replace(/{periodCompare}/g, periodTitle);
  return result;
};

export const getComparePeriodData = (
  year: Date,
  endPeriod: Date,
  comparePeriod: number,
  rows: AgoyTableRow[] | undefined
): number[] => {
  let startDate = endPeriod;
  // calculate start month of period to compare
  startDate = sub(endPeriod, { months: comparePeriod - 1 });

  let count = differenceInMonths(endPeriod, startDate);
  // calculate count of month to compare
  count = count === 0 ? 1 : comparePeriod;
  // prepare list of period to compare
  const periods = Array.from(Array(count).keys()).map((item) => {
    const month = add(startDate, { months: item });
    return format(month, 'MMMyy');
  });
  const data = series?.map((seria) => {
    const rowsData = rows?.find((row) => {
      return row.id === seria;
    });
    if (rowsData && rowsData.cells) {
      const cellsData = rowsData.cells;
      const periodData = periods.map((item) => {
        const cell = cellsData[item];
        if (
          cell &&
          cell.type &&
          cell.type === 'ref' &&
          typeof cell.value === 'number'
        ) {
          return cell.value;
        }
        return 0;
      });
      return Math.round(
        periodData.reduce((prev, next) => {
          return (prev || 0) + (next || 0);
        }, 0)
      );
    }
  });
  return data as number[];
};

import { group } from '@agoy/common';
import {
  AnnualReport,
  AnnualReportType,
  CommonNotes,
} from '../config/document';
import {
  ACTIVE_NOTES_ECONOMIC_ASSOCIATION,
  ACTIVE_NOTES_FOUNDATION_COMPANY,
  ACTIVE_NOTES_INDIVIDUAL_COMPANY,
  ACTIVE_NOTES_LIMITED_COMPANIES,
  ACTIVE_NOTES_NON_PROFIT_ORG,
  getActiveNotesForSharesCompany,
} from '..';

const activeNotes = {
  economic_association: ACTIVE_NOTES_ECONOMIC_ASSOCIATION,
  foundation: ACTIVE_NOTES_FOUNDATION_COMPANY,
  individual: ACTIVE_NOTES_INDIVIDUAL_COMPANY,
  limited_companies: ACTIVE_NOTES_LIMITED_COMPANIES,
  non_profit_association: ACTIVE_NOTES_NON_PROFIT_ORG,
};

type ReferencedNote = {
  id: string;
  note: string;
};

export const getCompanyActiveNotes = (
  report: AnnualReport,
  reportType: AnnualReportType
): string[] => {
  const companyType = report.documentType;
  if (companyType === 'shares') {
    return getActiveNotesForSharesCompany(reportType, report.version);
  }

  return activeNotes[companyType] || [];
};

const getGroupFromReference = (reference?: ReferencedNote) =>
  reference && ['balanceSheet', 'incomeStatement'].includes(reference.id)
    ? reference.id
    : 'other';

const getGroup = (
  noteId: string,
  noteIndex: number,
  reference?: ReferencedNote,
  notesWithoutGroup?: string[]
) => {
  if (noteIndex === -1 && !noteId.includes('custom')) return undefined;

  if (notesWithoutGroup?.includes(noteId)) return '';

  return getGroupFromReference(reference);
};

/**
 * Notes numbers are calculated from 1 and onwards
 * in the order of their appearence (reference) in Annual Report:
 *
 * 1. Resultaträkning
 * 2. Balansräkning
 * 3. Övriga
 *    - Kassaflödesanalys
 *    - checked but not referenced notes
 *    - custom not referenced notes
 */
const updateNotesGroupAndNumbering = (
  report: AnnualReport,
  referencedNotes: ReferencedNote[],
  reportType: AnnualReportType
): AnnualReport => {
  const companyActiveNotes = getCompanyActiveNotes(report, reportType);
  const oldNotes: CommonNotes = report.notes;
  const referencedNoteIds = referencedNotes.map((note) => note.note);

  const notesWithSpecialHandling = ['note1'];
  if (report.version === '2' && reportType === 'k3') {
    notesWithSpecialHandling.push('note2');
  }
  if (report.version === '1' && report.documentType === 'individual') {
    notesWithSpecialHandling.push('note56');
  }

  // group references by the section they're referenced in
  const groupedReferences = group<ReferencedNote, string[], string>(
    referencedNotes.filter(
      (note) => !notesWithSpecialHandling.includes(note.note)
    ),
    getGroupFromReference,
    (notes) => [...new Set(notes.map((note) => note.note))]
  );

  // add group 'other' if doesn't exist
  if (!groupedReferences.has('other')) {
    groupedReferences.set('other', []);
  }

  // append all checked but not referenced notes
  groupedReferences
    .get('other')
    ?.push(
      ...companyActiveNotes.filter(
        (note) =>
          !notesWithSpecialHandling.includes(note) &&
          !referencedNoteIds.includes(note) &&
          oldNotes[note].active
      )
    );

  // put the notes in array in order they should be numbered
  const numberedNotes = [
    ...notesWithSpecialHandling.filter(
      (note) =>
        note === 'note1' ||
        referencedNoteIds.includes(note) ||
        oldNotes[note].active
    ),
    ...(groupedReferences.get('incomeStatement') || []),
    ...(groupedReferences.get('balanceSheet') || []),
    ...(groupedReferences.get('other') || []),
  ];

  // note number assigned is a position in numberedNotes + 1
  // not referenced/checked notes get undefined
  const updateNote = (noteId, note) => {
    if (!note) {
      // custom notes can be null
      return note;
    }

    const noteIndex = numberedNotes.findIndex(
      (activeNote) => activeNote === noteId
    );
    const active = noteIndex > -1;
    const reference = referencedNotes.find((note) => note.note === noteId);

    return {
      ...note,
      active,
      number: {
        ...note.number,
        value: active ? noteIndex + 1 : undefined,
      },
      group: {
        type: 'string',
        value: getGroup(noteId, noteIndex, reference, notesWithSpecialHandling),
      },
      section: {
        type: 'string',
        value: reference?.id,
      },
    };
  };

  const updatedNotes = Object.entries(oldNotes).reduce(
    (notes, [noteId, note]) => {
      if (!noteId.includes('note') && noteId !== 'custom') {
        return notes;
      }

      // update each note in custom or current note
      const updatedNote =
        noteId === 'custom'
          ? {
              ...note,
              sections: note.sections.map((customNote, index) =>
                updateNote(`custom-${index}`, customNote)
              ),
            }
          : updateNote(noteId, note);

      return {
        ...notes,
        [noteId]: updatedNote,
      };
    },
    oldNotes
  );

  return {
    ...report,
    notes: updatedNotes,
  } as AnnualReport;
};

export default updateNotesGroupAndNumbering;
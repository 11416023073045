/*
 * This is a GENERATED file, do not change manually!
 * Generated:
 * - from file k3-arsredovisning-2021-10-31-rev20230131 - Noter.csv
 * - Comments: AGOY-6804
 */

import {
  value,
  ref,
  sumAllowNull,
  id,
  AgoyDocumentPart,
} from '@agoy/document';
import { IxbrlCell } from '../../../../../common';
import {
  field,
  ixbrlCell,
  ixbrlField,
  table,
} from '../../../../../common/utils/util';
import { previousYear, year } from '../../../../../common/utils/date-util';
import {
  sumAccountsIBs,
} from '../../../../../common/utils/accountSumsUtil';

const cell = { type: 'field' as const };
const boolean = { type: 'boolean' as const };
const type = { type: 'type' as const };
const number = { type: 'number' as const };

export const getNote2042K3v2 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null
): AgoyDocumentPart<typeof note2042K3v2ContentDefinition> => ({
  active: false,
  number: value(undefined),
  name: noteLabel('Förbättringsutgifter på annans fastighet'),
  data: {
    active: true,
    ForbattringsutgifterAnnansFastighetAbstract: {
      type: 'group',
      active: true,
      level: 0,
      title: field('Förbättringsutgifter på annans fastighet'),
      table: table<IxbrlCell>(
        'notes.note42.data.ForbattringsutgifterAnnansFastighetAbstract.table',
        {
          id: 'ForbattringsutgifterAnnansFastighetAbstract',
          label: '',
          dataType: 'text',
          active: true,
        },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) ?? '',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) ?? '',
          dataType: 'numeric',
          active: true,
        }
      )
        .addRows((rows) => {
          rows
            .addRowWithType(
              'ForbattringsutgifterAnnansFastighetAbstract',
              'hidden',
              undefined,
              undefined,
              undefined
            )
            .addSubRows((rows) => {
              rows.addRowWithType(
                'ForbattringsutgifterAnnansFastighetAnskaffningsvardenIngaende',
                'sum_accounts',
                noteLabel('Ingående anskaffningsvärden'),
                ixbrlCell(ref(sumAccountsIBs('1120:1128', false)), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:ForbattringsutgifterAnnansFastighetAnskaffningsvarden',
                  standardRubrik:
                    'Anskaffningsvärden förbättringsutgifter på annans fastighet',
                  saldo: 'debit',
                  negateValue: false,
                }),
                ixbrlCell(
                  ref(sumAccountsIBs('1120:1128', false, 'previousYear')),
                  {
                    contextRef: 'balans2',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:ForbattringsutgifterAnnansFastighetAnskaffningsvarden',
                    standardRubrik:
                      'Anskaffningsvärden förbättringsutgifter på annans fastighet',
                    saldo: 'debit',
                    negateValue: false,
                  }
                )
              );
              rows
                .addRowWithType(
                  'ForbattringsutgifterAnnansFastighetForandringAnskaffningsvardenAbstract',
                  'header',
                  noteLabel('Förändringar av anskaffningsvärden'),
                  undefined,
                  undefined
                )
                .addSubRows((rows) => {
                  rows.addRowWithType(
                    'ForbattringsutgifterAnnansFastighetForandringAnskaffningsvardenInkop',
                    'row',
                    noteLabel('Inköp'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForbattringsutgifterAnnansFastighetForandringAnskaffningsvardenInkop',
                      standardRubrik:
                        'Ökning av anskaffningsvärden i samband med periodens nedlagda förbättringsutgifter på annans fastighet',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForbattringsutgifterAnnansFastighetForandringAnskaffningsvardenInkop',
                      standardRubrik:
                        'Ökning av anskaffningsvärden i samband med periodens nedlagda förbättringsutgifter på annans fastighet',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'ForbattringsutgifterAnnansFastighetForandringAnskaffningsvardenRorelseforvarv',
                    'row',
                    noteLabel('Rörelseförvärv'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForbattringsutgifterAnnansFastighetForandringAnskaffningsvardenRorelseforvarv',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden för förbättringsutgifter på annans fastighet genom rörelseförvärv',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForbattringsutgifterAnnansFastighetForandringAnskaffningsvardenRorelseforvarv',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden för förbättringsutgifter på annans fastighet genom rörelseförvärv',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'ForbattringsutgifterAnnansFastighetForandringAnskaffningsvardenFusion',
                    'row',
                    noteLabel('Fusion'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForbattringsutgifterAnnansFastighetForandringAnskaffningsvardenFusion',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden för förbättringsutgifter på annans fastighet genom fusion',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForbattringsutgifterAnnansFastighetForandringAnskaffningsvardenFusion',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden för förbättringsutgifter på annans fastighet genom fusion',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'ForbattringsutgifterAnnansFastighetForandringAnskaffningsvardenForsaljningarUtrangeringar',
                    'row',
                    noteLabel('Försäljningar/utrangeringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForbattringsutgifterAnnansFastighetForandringAnskaffningsvardenForsaljningarUtrangeringar',
                      standardRubrik:
                        'Minskning av anskaffningsvärden i samband med försäljningar eller utrangeringar av förbättringsutgifter på annans fastighet',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForbattringsutgifterAnnansFastighetForandringAnskaffningsvardenForsaljningarUtrangeringar',
                      standardRubrik:
                        'Minskning av anskaffningsvärden i samband med försäljningar eller utrangeringar av förbättringsutgifter på annans fastighet',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'ForbattringsutgifterAnnansFastighetForandringAnskaffningsvardenOmklassificeringar',
                    'row',
                    noteLabel('Omklassificeringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForbattringsutgifterAnnansFastighetForandringAnskaffningsvardenOmklassificeringar',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden i samband med omklassificeringar av förbättringsutgifter på annans fastighet',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForbattringsutgifterAnnansFastighetForandringAnskaffningsvardenOmklassificeringar',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden i samband med omklassificeringar av förbättringsutgifter på annans fastighet',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'ForbattringsutgifterAnnansFastighetForandringAnskaffningsvardenOmrakningsdifferenser',
                    'row',
                    noteLabel('Omräkningsdifferenser'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForbattringsutgifterAnnansFastighetForandringAnskaffningsvardenOmrakningsdifferenser',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden i samband med omräkningsdifferenser av förbättringsutgifter på annans fastighet',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForbattringsutgifterAnnansFastighetForandringAnskaffningsvardenOmrakningsdifferenser',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden i samband med omräkningsdifferenser av förbättringsutgifter på annans fastighet',
                      negateValue: false,
                    })
                  );
                  return rows.build();
                });
              rows.addRowWithType(
                'ForbattringsutgifterAnnansFastighetAnskaffningsvardenUtgaende',
                'sum',
                noteLabel('Utgående anskaffningsvärden'),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note42.data.ForbattringsutgifterAnnansFastighetAbstract.table.ForbattringsutgifterAnnansFastighetAbstract.ForbattringsutgifterAnnansFastighetAnskaffningsvardenIngaende.year'
                      ),
                      id(
                        'notes.note42.data.ForbattringsutgifterAnnansFastighetAbstract.table.ForbattringsutgifterAnnansFastighetAbstract.ForbattringsutgifterAnnansFastighetForandringAnskaffningsvardenAbstract.*.year'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans0',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:ForbattringsutgifterAnnansFastighetAnskaffningsvarden',
                    standardRubrik:
                      'Anskaffningsvärden förbättringsutgifter på annans fastighet',
                    saldo: 'debit',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note42.data.ForbattringsutgifterAnnansFastighetAbstract.table.ForbattringsutgifterAnnansFastighetAbstract.ForbattringsutgifterAnnansFastighetAnskaffningsvardenIngaende.previousYear'
                      ),
                      id(
                        'notes.note42.data.ForbattringsutgifterAnnansFastighetAbstract.table.ForbattringsutgifterAnnansFastighetAbstract.ForbattringsutgifterAnnansFastighetForandringAnskaffningsvardenAbstract.*.previousYear'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans1',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:ForbattringsutgifterAnnansFastighetAnskaffningsvarden',
                    standardRubrik:
                      'Anskaffningsvärden förbättringsutgifter på annans fastighet',
                    saldo: 'debit',
                    negateValue: false,
                  }
                )
              );
              rows.addRowWithType(
                'ForbattringsutgifterAnnansFastighetAvskrivningarIngaende',
                'sum_accounts',
                noteLabel('Ingående avskrivningar'),
                ixbrlCell(ref(sumAccountsIBs('1129', false)), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:ForbattringsutgifterAnnansFastighetAvskrivningar',
                  standardRubrik:
                    'Ackumulerade avskrivningar förbättringsutgifter på annans fastighet',
                  saldo: 'credit',
                  negateValue: true,
                }),
                ixbrlCell(ref(sumAccountsIBs('1129', false, 'previousYear')), {
                  contextRef: 'balans2',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:ForbattringsutgifterAnnansFastighetAvskrivningar',
                  standardRubrik:
                    'Ackumulerade avskrivningar förbättringsutgifter på annans fastighet',
                  saldo: 'credit',
                  negateValue: true,
                })
              );
              rows
                .addRowWithType(
                  'ForbattringsutgifterAnnansFastighetForandringAvskrivningarAbstract',
                  'header',
                  noteLabel('Förändringar av avskrivningar'),
                  undefined,
                  undefined
                )
                .addSubRows((rows) => {
                  rows.addRowWithType(
                    'ForbattringsutgifterAnnansFastighetForandringAvskrivningarFusion',
                    'row',
                    noteLabel('Fusion'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForbattringsutgifterAnnansFastighetForandringAvskrivningarFusion',
                      standardRubrik:
                        'Ökning (minskning) av avskrivningar av förbättringsutgifter på annans fastighet genom fusion',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForbattringsutgifterAnnansFastighetForandringAvskrivningarFusion',
                      standardRubrik:
                        'Ökning (minskning) av avskrivningar av förbättringsutgifter på annans fastighet genom fusion',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'ForbattringsutgifterAnnansFastighetForandringAvskrivningarAterfordaAvskrivningarForsaljningarRespUtrangeringar',
                    'row',
                    noteLabel('Försäljningar/utrangeringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForbattringsutgifterAnnansFastighetForandringAvskrivningarAterfordaAvskrivningarForsaljningarRespUtrangeringar',
                      standardRubrik:
                        'Återförda avskrivningar i samband med försäljningar eller utrangeringar av förbättringsutgifter på annans fastighet',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForbattringsutgifterAnnansFastighetForandringAvskrivningarAterfordaAvskrivningarForsaljningarRespUtrangeringar',
                      standardRubrik:
                        'Återförda avskrivningar i samband med försäljningar eller utrangeringar av förbättringsutgifter på annans fastighet',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'ForbattringsutgifterAnnansFastighetForandringAvskrivningarOmklassificeringar',
                    'row',
                    noteLabel('Omklassificeringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForbattringsutgifterAnnansFastighetForandringAvskrivningarOmklassificeringar',
                      standardRubrik:
                        'Minskning (ökning) av avskrivningar i samband med omklassificeringar av förbättringsutgifter på annans fastighet',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForbattringsutgifterAnnansFastighetForandringAvskrivningarOmklassificeringar',
                      standardRubrik:
                        'Minskning (ökning) av avskrivningar i samband med omklassificeringar av förbättringsutgifter på annans fastighet',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'ForbattringsutgifterAnnansFastighetForandringAvskrivningarAretsAvskrivningar',
                    'row',
                    noteLabel('Årets avskrivningar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForbattringsutgifterAnnansFastighetForandringAvskrivningarAretsAvskrivningar',
                      standardRubrik:
                        'Periodens avskrivningar förbättringsutgifter på annans fastighet',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForbattringsutgifterAnnansFastighetForandringAvskrivningarAretsAvskrivningar',
                      standardRubrik:
                        'Periodens avskrivningar förbättringsutgifter på annans fastighet',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'ForbattringsutgifterAnnansFastighetForandringAvskrivningarOmrakningsdifferenser',
                    'row',
                    noteLabel('Omräkningsdifferenser'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForbattringsutgifterAnnansFastighetForandringAvskrivningarOmrakningsdifferenser',
                      standardRubrik:
                        'Minskning (ökning) av avskrivningar i samband med omräkningsdifferenser av förbättringsutgifter på annans fastighet',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForbattringsutgifterAnnansFastighetForandringAvskrivningarOmrakningsdifferenser',
                      standardRubrik:
                        'Minskning (ökning) av avskrivningar i samband med omräkningsdifferenser av förbättringsutgifter på annans fastighet',
                      negateValue: false,
                    })
                  );
                  return rows.build();
                });
              rows.addRowWithType(
                'ForbattringsutgifterAnnansFastighetAvskrivningarUtgaende',
                'sum',
                noteLabel('Utgående avskrivningar'),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note42.data.ForbattringsutgifterAnnansFastighetAbstract.table.ForbattringsutgifterAnnansFastighetAbstract.ForbattringsutgifterAnnansFastighetAvskrivningarIngaende.year'
                      ),
                      id(
                        'notes.note42.data.ForbattringsutgifterAnnansFastighetAbstract.table.ForbattringsutgifterAnnansFastighetAbstract.ForbattringsutgifterAnnansFastighetForandringAvskrivningarAbstract.*.year'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans0',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:ForbattringsutgifterAnnansFastighetAvskrivningar',
                    standardRubrik:
                      'Ackumulerade avskrivningar förbättringsutgifter på annans fastighet',
                    saldo: 'credit',
                    negateValue: true,
                  }
                ),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note42.data.ForbattringsutgifterAnnansFastighetAbstract.table.ForbattringsutgifterAnnansFastighetAbstract.ForbattringsutgifterAnnansFastighetAvskrivningarIngaende.previousYear'
                      ),
                      id(
                        'notes.note42.data.ForbattringsutgifterAnnansFastighetAbstract.table.ForbattringsutgifterAnnansFastighetAbstract.ForbattringsutgifterAnnansFastighetForandringAvskrivningarAbstract.*.previousYear'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans1',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:ForbattringsutgifterAnnansFastighetAvskrivningar',
                    standardRubrik:
                      'Ackumulerade avskrivningar förbättringsutgifter på annans fastighet',
                    saldo: 'credit',
                    negateValue: true,
                  }
                )
              );
              rows.addRowWithType(
                'ForbattringsutgifterAnnansFastighetUppskrivningarIngaende',
                'row',
                noteLabel('Ingående uppskrivningar'),
                ixbrlCell(value(undefined), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:ForbattringsutgifterAnnansFastighetUppskrivningar',
                  standardRubrik:
                    'Ackumulerade uppskrivningar förbättringsutgifter på annans fastighet',
                  saldo: 'debit',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'balans2',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:ForbattringsutgifterAnnansFastighetUppskrivningar',
                  standardRubrik:
                    'Ackumulerade uppskrivningar förbättringsutgifter på annans fastighet',
                  saldo: 'debit',
                  negateValue: false,
                })
              );
              rows
                .addRowWithType(
                  'ForbattringsutgifterAnnansFastighetUppskrivningarAbstract',
                  'header',
                  noteLabel('Förändringar av uppskrivningar'),
                  undefined,
                  undefined
                )
                .addSubRows((rows) => {
                  rows.addRowWithType(
                    'ForbattringsutgifterAnnansFastighetUppskrivningarFusion',
                    'row',
                    noteLabel('Fusion'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForbattringsutgifterAnnansFastighetUppskrivningarFusion',
                      standardRubrik:
                        'Ökning (minskning) av uppskrivningar för förbättringsutgifter på annans fastighet genom fusion',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForbattringsutgifterAnnansFastighetUppskrivningarFusion',
                      standardRubrik:
                        'Ökning (minskning) av uppskrivningar för förbättringsutgifter på annans fastighet genom fusion',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'ForbattringsutgifterAnnansFastighetUppskrivningarForsaljningarUtrangeringar',
                    'row',
                    noteLabel('Försäljningar/utrangeringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForbattringsutgifterAnnansFastighetUppskrivningarForsaljningarUtrangeringar',
                      standardRubrik:
                        'Återförda uppskrivningar i samband med försäljningar eller utrangeringar av förbättringsutgifter på annans fastighet',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForbattringsutgifterAnnansFastighetUppskrivningarForsaljningarUtrangeringar',
                      standardRubrik:
                        'Återförda uppskrivningar i samband med försäljningar eller utrangeringar av förbättringsutgifter på annans fastighet',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'ForbattringsutgifterAnnansFastighetUppskrivningarOmklassificeringar',
                    'row',
                    noteLabel('Omklassificeringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForbattringsutgifterAnnansFastighetUppskrivningarOmklassificeringar',
                      standardRubrik:
                        'Ökning (minskning) av uppskrivningar i samband med omklassificeringar av förbättringsutgifter på annans fastighet',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForbattringsutgifterAnnansFastighetUppskrivningarOmklassificeringar',
                      standardRubrik:
                        'Ökning (minskning) av uppskrivningar i samband med omklassificeringar av förbättringsutgifter på annans fastighet',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'ForbattringsutgifterAnnansFastighetUppskrivningarAretsNedskrivningar',
                    'row',
                    noteLabel('Årets uppskrivningar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForbattringsutgifterAnnansFastighetUppskrivningarAretsNedskrivningar',
                      standardRubrik:
                        'Periodens uppskrivningar av förbättringsutgifter på annans fastighet',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForbattringsutgifterAnnansFastighetUppskrivningarAretsNedskrivningar',
                      standardRubrik:
                        'Periodens uppskrivningar av förbättringsutgifter på annans fastighet',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'ForbattringsutgifterAnnansFastighetUppskrivningarAretsNedskrivningarUppskrivetBelopp',
                    'row',
                    noteLabel('Årets nedskrivningar på uppskrivet belopp'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForbattringsutgifterAnnansFastighetUppskrivningarAretsNedskrivningarUppskrivetBelopp',
                      standardRubrik:
                        'Nedskrivningar av uppskrivet belopp på förbättringsutgifter på annans fastighet',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForbattringsutgifterAnnansFastighetUppskrivningarAretsNedskrivningarUppskrivetBelopp',
                      standardRubrik:
                        'Nedskrivningar av uppskrivet belopp på förbättringsutgifter på annans fastighet',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'ForbattringsutgifterAnnansFastighetUppskrivningarAretsAvskrivningarUppskrivetBelopp',
                    'row',
                    noteLabel('Årets avskrivningar på uppskrivet belopp'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForbattringsutgifterAnnansFastighetUppskrivningarAretsAvskrivningarUppskrivetBelopp',
                      standardRubrik:
                        'Avskrivningar av uppskrivet belopp på förbättringsutgifter på annans fastighet',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForbattringsutgifterAnnansFastighetUppskrivningarAretsAvskrivningarUppskrivetBelopp',
                      standardRubrik:
                        'Avskrivningar av uppskrivet belopp på förbättringsutgifter på annans fastighet',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'ForbattringsutgifterAnnansFastighetUppskrivningarOmrakningsdifferenser',
                    'row',
                    noteLabel('Omräkningsdifferenser'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForbattringsutgifterAnnansFastighetUppskrivningarOmrakningsdifferenser',
                      standardRubrik:
                        'Ökning (minskning) av uppskrivningar i samband med omräkningsdifferenser av förbättringsutgifter på annans fastighet',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForbattringsutgifterAnnansFastighetUppskrivningarOmrakningsdifferenser',
                      standardRubrik:
                        'Ökning (minskning) av uppskrivningar i samband med omräkningsdifferenser av förbättringsutgifter på annans fastighet',
                      negateValue: false,
                    })
                  );
                  return rows.build();
                });
              rows.addRowWithType(
                'ForbattringsutgifterAnnansFastighetUppskrivningarUtgaende',
                'sum',
                noteLabel('Utgående uppskrivningar'),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note42.data.ForbattringsutgifterAnnansFastighetAbstract.table.ForbattringsutgifterAnnansFastighetAbstract.ForbattringsutgifterAnnansFastighetUppskrivningarIngaende.year'
                      ),
                      id(
                        'notes.note42.data.ForbattringsutgifterAnnansFastighetAbstract.table.ForbattringsutgifterAnnansFastighetAbstract.ForbattringsutgifterAnnansFastighetUppskrivningarAbstract.*.year'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans0',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:ForbattringsutgifterAnnansFastighetUppskrivningar',
                    standardRubrik:
                      'Ackumulerade uppskrivningar förbättringsutgifter på annans fastighet',
                    saldo: 'debit',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note42.data.ForbattringsutgifterAnnansFastighetAbstract.table.ForbattringsutgifterAnnansFastighetAbstract.ForbattringsutgifterAnnansFastighetUppskrivningarIngaende.previousYear'
                      ),
                      id(
                        'notes.note42.data.ForbattringsutgifterAnnansFastighetAbstract.table.ForbattringsutgifterAnnansFastighetAbstract.ForbattringsutgifterAnnansFastighetUppskrivningarAbstract.*.previousYear'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans1',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:ForbattringsutgifterAnnansFastighetUppskrivningar',
                    standardRubrik:
                      'Ackumulerade uppskrivningar förbättringsutgifter på annans fastighet',
                    saldo: 'debit',
                    negateValue: false,
                  }
                )
              );
              rows.addRowWithType(
                'ForbattringsutgifterAnnansFastighetNedskrivningarIngaende',
                'row',
                noteLabel('Ingående nedskrivningar'),
                ixbrlCell(value(undefined), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:ForbattringsutgifterAnnansFastighetNedskrivningar',
                  standardRubrik:
                    'Ackumulerade nedskrivningar förbättringsutgifter på annans fastighet',
                  saldo: 'credit',
                  negateValue: true,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'balans2',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:ForbattringsutgifterAnnansFastighetNedskrivningar',
                  standardRubrik:
                    'Ackumulerade nedskrivningar förbättringsutgifter på annans fastighet',
                  saldo: 'credit',
                  negateValue: true,
                })
              );
              rows
                .addRowWithType(
                  'ForbattringsutgifterAnnansFastighetForandringNedskrivningarAbstract',
                  'header',
                  noteLabel('Förändringar av nedskrivningar'),
                  undefined,
                  undefined
                )
                .addSubRows((rows) => {
                  rows.addRowWithType(
                    'ForbattringsutgifterAnnansFastighetForandringNedskrivningarFusion',
                    'row',
                    noteLabel('Fusion'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForbattringsutgifterAnnansFastighetForandringNedskrivningarFusion',
                      standardRubrik:
                        'Ökning (minskning) av nedskrivningar för förbättringsutgifter på annans fastighet genom fusion',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForbattringsutgifterAnnansFastighetForandringNedskrivningarFusion',
                      standardRubrik:
                        'Ökning (minskning) av nedskrivningar för förbättringsutgifter på annans fastighet genom fusion',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'ForbattringsutgifterAnnansFastighetForandringNedskrivningarAterfordaNedskrivningarPaForsaljningarRespUtrangeringar',
                    'row',
                    noteLabel('Försäljningar/utrangeringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForbattringsutgifterAnnansFastighetForandringNedskrivningarAterfordaNedskrivningarPaForsaljningarRespUtrangeringar',
                      standardRubrik:
                        'Återförda nedskrivningar i samband med försäljningar eller utrangeringar av förbättringsutgifter på annans fastighet',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForbattringsutgifterAnnansFastighetForandringNedskrivningarAterfordaNedskrivningarPaForsaljningarRespUtrangeringar',
                      standardRubrik:
                        'Återförda nedskrivningar i samband med försäljningar eller utrangeringar av förbättringsutgifter på annans fastighet',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'ForbattringsutgifterAnnansFastighetForandringNedskrivningarOmklassificeringar',
                    'row',
                    noteLabel('Omklassificeringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForbattringsutgifterAnnansFastighetForandringNedskrivningarOmklassificeringar',
                      standardRubrik:
                        'Minskning (ökning) av nedskrivningar i samband med omklassificeringar av förbättringsutgifter på annans fastighet',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForbattringsutgifterAnnansFastighetForandringNedskrivningarOmklassificeringar',
                      standardRubrik:
                        'Minskning (ökning) av nedskrivningar i samband med omklassificeringar av förbättringsutgifter på annans fastighet',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'ForbattringsutgifterAnnansFastighetForandringNedskrivningarAterfordaNedskrivningar',
                    'row',
                    noteLabel('Återförda nedskrivningar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForbattringsutgifterAnnansFastighetForandringNedskrivningarAterfordaNedskrivningar',
                      standardRubrik:
                        'Periodens återförda nedskrivningar av förbättringsutgifter på annans fastighet',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForbattringsutgifterAnnansFastighetForandringNedskrivningarAterfordaNedskrivningar',
                      standardRubrik:
                        'Periodens återförda nedskrivningar av förbättringsutgifter på annans fastighet',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'ForbattringsutgifterAnnansFastighetForandringNedskrivningarAretsNedskrivningar',
                    'row',
                    noteLabel('Årets nedskrivningar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForbattringsutgifterAnnansFastighetForandringNedskrivningarAretsNedskrivningar',
                      standardRubrik:
                        'Periodens nedskrivningar av förbättringsutgifter på annans fastighet',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForbattringsutgifterAnnansFastighetForandringNedskrivningarAretsNedskrivningar',
                      standardRubrik:
                        'Periodens nedskrivningar av förbättringsutgifter på annans fastighet',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'ForbattringsutgifterAnnansFastighetForandringNedskrivningarOmrakningsdifferenser',
                    'row',
                    noteLabel('Omräkningsdifferenser'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForbattringsutgifterAnnansFastighetForandringNedskrivningarOmrakningsdifferenser',
                      standardRubrik:
                        'Minskning (ökning) av nedskrivningar i samband med omräkningsdifferenser av förbättringsutgifter på annans fastighet',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForbattringsutgifterAnnansFastighetForandringNedskrivningarOmrakningsdifferenser',
                      standardRubrik:
                        'Minskning (ökning) av nedskrivningar i samband med omräkningsdifferenser av förbättringsutgifter på annans fastighet',
                      negateValue: false,
                    })
                  );
                  return rows.build();
                });
              rows.addRowWithType(
                'ForbattringsutgifterAnnansFastighetNedskrivningarUtgaende',
                'sum',
                noteLabel('Utgående nedskrivningar'),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note42.data.ForbattringsutgifterAnnansFastighetAbstract.table.ForbattringsutgifterAnnansFastighetAbstract.ForbattringsutgifterAnnansFastighetNedskrivningarIngaende.year'
                      ),
                      id(
                        'notes.note42.data.ForbattringsutgifterAnnansFastighetAbstract.table.ForbattringsutgifterAnnansFastighetAbstract.ForbattringsutgifterAnnansFastighetForandringNedskrivningarAbstract.*.year'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans0',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:ForbattringsutgifterAnnansFastighetNedskrivningar',
                    standardRubrik:
                      'Ackumulerade nedskrivningar förbättringsutgifter på annans fastighet',
                    saldo: 'credit',
                    negateValue: true,
                  }
                ),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note42.data.ForbattringsutgifterAnnansFastighetAbstract.table.ForbattringsutgifterAnnansFastighetAbstract.ForbattringsutgifterAnnansFastighetNedskrivningarIngaende.previousYear'
                      ),
                      id(
                        'notes.note42.data.ForbattringsutgifterAnnansFastighetAbstract.table.ForbattringsutgifterAnnansFastighetAbstract.ForbattringsutgifterAnnansFastighetForandringNedskrivningarAbstract.*.previousYear'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans1',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:ForbattringsutgifterAnnansFastighetNedskrivningar',
                    standardRubrik:
                      'Ackumulerade nedskrivningar förbättringsutgifter på annans fastighet',
                    saldo: 'credit',
                    negateValue: true,
                  }
                )
              );
              return rows.build();
            });
          rows.addRowWithType(
            'ForbattringsutgifterAnnansFastighet',
            'sum',
            noteLabel('Redovisat värde'),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note42.data.ForbattringsutgifterAnnansFastighetAbstract.table.ForbattringsutgifterAnnansFastighetAbstract.ForbattringsutgifterAnnansFastighetAnskaffningsvardenUtgaende.year'
                  ),
                  id(
                    'notes.note42.data.ForbattringsutgifterAnnansFastighetAbstract.table.ForbattringsutgifterAnnansFastighetAbstract.ForbattringsutgifterAnnansFastighetAvskrivningarUtgaende.year'
                  ),
                  id(
                    'notes.note42.data.ForbattringsutgifterAnnansFastighetAbstract.table.ForbattringsutgifterAnnansFastighetAbstract.ForbattringsutgifterAnnansFastighetUppskrivningarUtgaende.year'
                  ),
                  id(
                    'notes.note42.data.ForbattringsutgifterAnnansFastighetAbstract.table.ForbattringsutgifterAnnansFastighetAbstract.ForbattringsutgifterAnnansFastighetNedskrivningarUtgaende.year'
                  )
                )
              ),
              {
                contextRef: 'balans0',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:ForbattringsutgifterAnnansFastighet',
                standardRubrik: 'Förbättringsutgifter på annans fastighet',
                saldo: 'debit',
                negateValue: false,
              }
            ),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note42.data.ForbattringsutgifterAnnansFastighetAbstract.table.ForbattringsutgifterAnnansFastighetAbstract.ForbattringsutgifterAnnansFastighetAnskaffningsvardenUtgaende.previousYear'
                  ),
                  id(
                    'notes.note42.data.ForbattringsutgifterAnnansFastighetAbstract.table.ForbattringsutgifterAnnansFastighetAbstract.ForbattringsutgifterAnnansFastighetAvskrivningarUtgaende.previousYear'
                  ),
                  id(
                    'notes.note42.data.ForbattringsutgifterAnnansFastighetAbstract.table.ForbattringsutgifterAnnansFastighetAbstract.ForbattringsutgifterAnnansFastighetUppskrivningarUtgaende.previousYear'
                  ),
                  id(
                    'notes.note42.data.ForbattringsutgifterAnnansFastighetAbstract.table.ForbattringsutgifterAnnansFastighetAbstract.ForbattringsutgifterAnnansFastighetNedskrivningarUtgaende.previousYear'
                  )
                )
              ),
              {
                contextRef: 'balans1',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:ForbattringsutgifterAnnansFastighet',
                standardRubrik: 'Förbättringsutgifter på annans fastighet',
                saldo: 'debit',
                negateValue: false,
              }
            )
          );
          rows.addRowWithType(
            'ForbattringsutgifterAnnansFastighetOffentligaBidrag',
            'row',
            noteLabel('Offentliga bidrag som reducerat anskaffningsvärdet'),
            ixbrlCell(value(undefined), {
              contextRef: 'balans0',
              type: 'monetaryItemType',
              unitRef: 'SEK',
              name: 'se-gen-base:ForbattringsutgifterAnnansFastighetOffentligaBidrag',
              standardRubrik:
                'Offentliga bidrag som reducerat anskaffningsvärdet på förbättringsutgifter på annans fastighet',
              saldo: 'credit',
              negateValue: true,
            }),
            ixbrlCell(value(undefined), {
              contextRef: 'balans1',
              type: 'monetaryItemType',
              unitRef: 'SEK',
              name: 'se-gen-base:ForbattringsutgifterAnnansFastighetOffentligaBidrag',
              standardRubrik:
                'Offentliga bidrag som reducerat anskaffningsvärdet på förbättringsutgifter på annans fastighet',
              saldo: 'credit',
              negateValue: true,
            })
          );
          return rows.build();
        })
        .build(),
      NotForbattringsutgifterAnnansFastighetKommentar: {
        type: 'group',
        active: true,
        level: 0,
        title: field(
          'Kommentar till specifikation av förbättringsutgifter på annans fastighet'
        ),
        value: ixbrlField(field(''), {
          contextRef: 'period0',
          type: 'stringItemType',
          name: 'se-gen-base:NotForbattringsutgifterAnnansFastighetKommentar',
          standardRubrik:
            'Kommentar till specifikation av förbättringsutgifter på annans fastighet',
        }),
      },
    },
  },
  group: value(undefined),
  section: value(undefined),
  validForReportType: {
    k2: true,
    k3: true,
  },
});
export const note2042K3v2ContentDefinition = {
  type: 'part' as const,
  children: {
    active: boolean,
    number: cell,
    name: cell,
    data: {
      type: 'part' as const,
      children: {
        active: boolean,

        ForbattringsutgifterAnnansFastighetAbstract: {
          type: 'part' as const,
          children: {
            type: type,
            level: number,
            active: boolean,

            title: cell,

            table: { type: 'table' as const },

            NotForbattringsutgifterAnnansFastighetKommentar: {
              type: 'part' as const,
              children: {
                type: type,
                level: number,
                active: boolean,

                title: cell,

                value: cell,
              },
            },
          },
        },
      },
    },
    group: cell,
    section: cell,
    validForReportType: {
      type: 'part' as const,
      children: {
        k2: boolean,
        k3: boolean,
      },
    },
  },
};

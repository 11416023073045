import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import DateRangeIcon from '@material-ui/icons/DateRange';
import styled from '@emotion/styled';

import ChipFilter from './ChipFilter';
import PeriodCalendar from './PeriodCalendar';
import Button from '../Buttons/Button';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 260px;
  min-width: 260px;
`;

const Toggle = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
`;

type PeriodPickerProps = {
  currentPeriodYear: number;
  rangeStartDate: Date;
  rangeEndDate: Date;
  selectedPeriods: { [period: string]: boolean };
  handlePeriodSelect: (period: string) => void;
  handlePeriodSelectAll?: (year: number) => void;
  handlePeriodDeselectAll?: (year: number) => any;
  renderButton: (disabled: boolean) => JSX.Element;
};

const PeriodPicker = ({
  currentPeriodYear,
  rangeStartDate,
  rangeEndDate,
  selectedPeriods,
  handlePeriodSelect,
  handlePeriodSelectAll = () => {},
  handlePeriodDeselectAll = () => {},
  renderButton,
}: PeriodPickerProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const [isShown, setIsShown] = useState(true);

  return (
    <Wrapper>
      <Toggle>
        <Button
          label={formatMessage({ id: 'hidden.transactions.toggleCalendar' })}
          onClick={() => setIsShown(!isShown)}
          startIcon={<DateRangeIcon />}
          variant="text"
          size="medium"
        />
      </Toggle>

      <ChipFilter
        periods={selectedPeriods}
        handlePeriodSelect={handlePeriodSelect}
      />

      <PeriodCalendar
        isShown={isShown}
        enabledRange={{
          start: rangeStartDate,
          end: rangeEndDate,
        }}
        activeYear={currentPeriodYear}
        selectedPeriods={selectedPeriods}
        handlePeriodSelect={handlePeriodSelect}
        handleSelectAll={handlePeriodSelectAll}
        handleDeselectAll={handlePeriodDeselectAll}
        renderButton={renderButton}
      />
    </Wrapper>
  );
};

export default PeriodPicker;

import React from 'react';
import { useIntl } from 'react-intl';
import { Typography } from '@material-ui/core';
import styled from '@emotion/styled';

import { ResolvedReference } from '@agoy/document';

const Header = styled.div`
  display: flex;
  flex-direction: row;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;

const Field = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled(Typography)`
  font-weight: 600;
`;

const Value = styled(Typography)`
  font-size: 16px;
`;

interface PrintHeaderProps {
  financialYear: string;
  propertyCode: ResolvedReference;
  name: string;
  number: string;
  isPerson?: boolean;
}

const PrintHeader = ({
  financialYear,
  propertyCode,
  name,
  number,
  isPerson,
}: PrintHeaderProps) => {
  const { formatMessage } = useIntl();

  return (
    <Header>
      <Column>
        <Field>
          <Label>
            {formatMessage({ id: 'tax.declaration.form.helper.name' })}
          </Label>
          <Value>{name}</Value>
        </Field>
        <Field>
          <Label>
            {formatMessage({
              id: `tax.declaration.form.helper.${
                isPerson ? 'number' : 'companyNumber'
              }`,
            })}
          </Label>
          <Value>{number}</Value>
        </Field>
        <Field>
          <Label>
            {formatMessage({
              id: `tax.declaration.form.helper.${
                isPerson ? 'code' : 'companyCode'
              }`,
            })}
          </Label>
          <Value>{propertyCode}</Value>
        </Field>
      </Column>
      <Column>
        <Field>
          <Label>
            {formatMessage({ id: 'tax.declaration.form.helper.year' })}
          </Label>
          <Value>{financialYear.slice(0, 4)}</Value>
        </Field>
      </Column>
    </Header>
  );
};

export default PrintHeader;

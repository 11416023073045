import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import Button from '_shared/components/Buttons/Button';
import AddIcon from '@material-ui/icons/Add';
import { AgoyTableColumn, AgoyTableRow } from '@agoy/document';
import styled from '@emotion/styled';
import DocumentViewServiceContext from '_shared/services/document/DocumentViewServiceContext';
import DeleteButton from '_shared/components/Buttons/DeleteButton';
import Cell from '../../UI/Cell';

const AddRow = styled(({ columns, ...props }) => <div {...props} />)`
  grid-column: 1 / span ${(props) => props.columns};
`;

const Content = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;
interface GridRowProps {
  baseId: string;
  row: AgoyTableRow;
  columns: AgoyTableColumn[];
  editing: boolean;
  print: boolean;
}

const GridRow = ({
  baseId,
  row,
  columns,
  editing,
  print,
}: GridRowProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const service = useContext(DocumentViewServiceContext);

  const rowId = `${baseId}.${row.id}`;
  const handleAddRow = () => {
    service.addRow(rowId);
  };
  const handleDelete = () => {
    service.deleteRow(rowId);
  };
  return (
    <>
      {columns.map((col, index) => {
        const cell = row.cells?.[col.id];
        if (!cell) {
          return <span key={col.id} />;
        }
        return (
          <Content key={col.id}>
            <Cell cell={cell} id={`${rowId}.${col.id}`} editing={editing} />
            {editing && index === columns.length - 1 && (
              <DeleteButton onClick={handleDelete} />
            )}
          </Content>
        );
      })}
      {row.rows?.map((subRow) => (
        <GridRow
          key={subRow.id}
          baseId={rowId}
          row={subRow}
          columns={columns}
          editing={editing}
          print={print}
        />
      ))}
      {editing && row.newRowTemplate && (
        <AddRow columns={columns.length}>
          <Button
            startIcon={<AddIcon />}
            onClick={handleAddRow}
            label={formatMessage({ id: 'add.row' })}
          />
        </AddRow>
      )}
    </>
  );
};

export default GridRow;

import { Row } from './types';

const sortOrder = [
  'refi',
  'refiPercent',
  '1000_1399',
  '1000_1099',
  '1100_1199',
  '1100_1299',
  '1200_1299',
  '1300_1399',
  '1400_1999',
  '1400_1499',
  '1500_1799',
  '1500_1599',
  '1600_1699',
  '1700_1799',
  '1800_1899',
  '1900_1999',
  '1000_1999',
  '2000_2999',
  '2000_2099',
  '2100_2999',
  '2100_2199',
  '2200_2299',
  '2300_2399',
  '2400_2499',
  '2500_2599',
  '2600_2699',
  '2700_2799',
  '2800_2899',
  '2800_2899',
  '2900_2999',
  'solidity',
  '3000_8999',
  '3000_3899',
  '3000_3999',
  '3900_3999',
  '4000_7999',
  '4000_4999',
  'grossProfit',
  'grossProfitMargin',
  '5000_6999',
  '7000_7699',
  '7700_7899',
  '7900_7999',
  '7000_7999',
  '8000_8799',
  'refiAgain',
  'refiPercentAgain',
  '8800_8999',
  '8800_8899',
  '8900_8999',
  'estimatedResult',
  'result',
];

const sortOrderLookup: Record<string, number> = sortOrder.reduce(
  (lookup, key, index) => ({ ...lookup, [key]: index }),
  {}
);

const typeOrder: Record<Row['type'], number> = {
  header: 0,
  groupHeader: 1,
  group: 2,
  groupSum: 3,
  account: 4,
  hiddenGroup: 5,
  hidden: 6,
  keyFigure: 7,
};

/**
 * Ordering function for rows.
 * A "header" is ordered before "account" and "account"s are ordered
 * by their `accountNumber`.
 *
 * @param left
 * @param right
 * @returns a negative value if `left` is order before `right`,
 *          a positive value if `left` is order after `right`,
 *          or zero otherwise.
 */
const compareRows = (a: Row, b: Row): number => {
  if (a.type === 'header') {
    // Headers are always first
    return b.type === 'header' ? 0 : -1;
  }
  if (b.type === 'header') {
    // Headers are always first
    return 1;
  }
  if (a.type === 'groupSum') {
    // Sums are always last
    return b.type === 'groupSum' ? 0 : 1;
  }
  if (b.type === 'groupSum') {
    // Sums are always last
    return -1;
  }
  if (a.type === 'account' && b.type === 'account') {
    // Accounts are ordered by account number
    const order = parseInt(a.accountNumber, 10) - parseInt(b.accountNumber, 10);
    if (order !== 0) {
      return order;
    }
  }

  // Check ordering based on ids
  const order =
    (sortOrderLookup[a.id] ?? 1000) - (sortOrderLookup[b.id] ?? 1000);

  if (order !== 0) {
    return order;
  }

  if (a.id === b.id) {
    // Same ids, order by type of row.
    return typeOrder[a.type] - typeOrder[b.type];
  }

  return a.id < b.id ? -1 : 1;
};

export default compareRows;

import { value, table, ref, or, id, sum } from '@agoy/document';
import { IxbrlCell, NoteOneTableWithSum } from '../../../../common';
import { year, previousYear } from '../../../../common/utils/date-util';
import {
  active,
  field,
  ixbrlCell,
  ixbrlField,
  tupleRef,
} from '../../../../common/utils/util';

const MONETARY_ITEM_TYPE = 'monetaryItemType';
const STRING_ITEM_TYPE = 'stringItemType';

export const getNote83 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null,
  documentTypeVersion = '1'
): NoteOneTableWithSum => {
  return {
    active: false,
    number: value(undefined),
    name: value('Eventualförpliktelser'),
    table: table<IxbrlCell>(
      'notes.note83.table',
      { id: 'label', label: '' },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      .addRows((rows) =>
        rows
          .addRow(
            '1',
            ixbrlCell(value('Eventualförpliktelser'), {
              type: STRING_ITEM_TYPE,
              name: 'se-gen-base:EventualForpliktelserTyp',
              contextRef: 'balans0',
              tuple: {
                name: 'se-gaap-ext:EventualForpliktelserTuple',
                id: `${rows.getBaseId()}.1.tuple`,
                tupleID: `${rows.getBaseId()}.1.tuple`,
              },
              previousYearTuple: true,
              tupleRef: tupleRef(1, `${rows.getBaseId()}.1.tuple`),
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:EventualForpliktelserPerTyp',
              contextRef: 'balans0',
              tupleRef: tupleRef(2, `${rows.getBaseId()}.1.tuple_balans0`),
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:EventualForpliktelserPerTyp',
              contextRef: 'balans1',
              tupleRef: tupleRef(3, `${rows.getBaseId()}.1.tuple_balans1`),
            }),
            value(undefined)
          )
          .build()
      )
      .newRowTemplateGenerator((id, baseId) => ({
        id,
        active: true,
        cells: {
          label: ixbrlCell(value(''), {
            type: 'stringItemType',
            name: 'se-gen-base:EventualForpliktelserTyp',
            contextRef: 'balans0',
            tuple: {
              name: 'se-gaap-ext:EventualForpliktelserTuple',
              id: `${baseId}.${id}.tuple`,
              tupleID: `${baseId}.${id}.tuple`,
            },
            previousYearTuple: true,
            tupleRef: tupleRef(1, `${baseId}.${id}.tuple`),
          }),
          year: ixbrlCell(value(undefined), {
            type: MONETARY_ITEM_TYPE,
            name: 'se-gen-base:EventualForpliktelserPerTyp',
            contextRef: 'balans0',
            tupleRef: tupleRef(2, `${baseId}.${id}.tuple_balans0`),
          }),
          previousYear: ixbrlCell(value(undefined), {
            type: MONETARY_ITEM_TYPE,
            name: 'se-gen-base:EventualForpliktelserPerTyp',
            contextRef: 'balans1',
            tupleRef: tupleRef(3, `${baseId}.${id}.tuple_balans1`),
          }),
        },
      }))
      .build(),
    sumTable: table(
      'notes.note83.sumTable',
      { id: 'label', label: '' },
      {
        id: 'sum',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousSum',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      .addRows((rows) =>
        rows
          .addRow(
            'sum',
            noteLabel('Summa eventualförpliktelser'),
            ixbrlCell(ref(or(sum(id('notes.note83.table.*.year')), 0)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:EventualForpliktelser',
              contextRef: 'balans0',
            }),
            ixbrlCell(
              ref(or(sum(id('notes.note83.table.*.previousYear')), 0)),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:EventualForpliktelser',
                contextRef: 'balans1',
              }
            )
          )
          .build()
      )
      .build(),
    commentLabel: field('Kommentar'),
    comment: ixbrlField(active(field('')), {
      type: 'stringItemType',
      name: 'se-gen-base:NotEventualForpliktelserKommentar',
      contextRef: 'balans0',
    }),
    group: value(undefined),
    section: value(undefined),
    validForReportType: {
      k2: false,
      k3: documentTypeVersion != '1' ? true : false,
    },
  };
};

import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'redux/reducers';
import FinancialReportReduxViewService from './FinancialReportReduxViewService';
import FinancialReportViewService from './FinancialReportViewService';

const defaultService: FinancialReportViewService =
  {} as FinancialReportViewService;

const FinancialReportViewServiceContext =
  React.createContext<FinancialReportViewService>(defaultService);

export const Provider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}): JSX.Element => {
  const dispatch = useDispatch();
  const { currentCustomer, currentPeriod } = useSelector(
    (state) => state.customerView
  );

  const service = useMemo(
    () =>
      currentCustomer && currentPeriod
        ? new FinancialReportReduxViewService(
            currentCustomer,
            currentPeriod,
            dispatch
          )
        : defaultService,
    [currentCustomer, currentPeriod, dispatch]
  );

  return (
    <FinancialReportViewServiceContext.Provider value={service}>
      {children}
    </FinancialReportViewServiceContext.Provider>
  );
};

export default FinancialReportViewServiceContext;

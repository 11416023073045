import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { findAccrualFundValue, findYearEndValue } from './helpers';
import TaxTableRow, { TaxTableRowProps } from './TaxTableRow';
import TaxesDataContext from '../../../service/TaxesDataContext';

const ChosenDepositionToAccrualFundRow = (
  props: TaxTableRowProps
): JSX.Element => {
  const { formatMessage } = useIntl();
  const {
    taxYearData: { state },
  } = useContext(TaxesDataContext);

  const U20 =
    findAccrualFundValue(state, 'furtherPossibleDepositionToAccrualFund') || 0;

  const D26 = findYearEndValue(state, 'chosenDepositionToAccrualFund') || 0;

  let error;

  if (D26 < U20 - 1) {
    error = formatMessage({ id: 'tax.chosenDepositionToAccrualFund.error' });
  }

  return (
    <TaxTableRow {...props} errorLabel={error} onChangeLabel={undefined} />
  );
};

export default ChosenDepositionToAccrualFundRow;

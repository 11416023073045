import React from 'react';
import { CommonNotes } from '@agoy/annual-report-document';

const NotesContext = React.createContext<CommonNotes | undefined>(undefined);

interface NotesContextProviderProps {
  notes: CommonNotes;
  children: JSX.Element | JSX.Element[];
}

export const NotesContextProvider = ({
  notes,
  children,
}: NotesContextProviderProps): JSX.Element => (
  <NotesContext.Provider value={notes}>{children}</NotesContext.Provider>
);

export default NotesContext;

import {
  format,
  isSameOrAfter,
  isSameOrBefore,
  parse,
  parseFinancialYear,
} from '@agoy/dates';
import {
  endOfMonth,
  getMonth,
  startOfMonth,
  subDays,
  subMonths,
  subYears,
} from 'date-fns';

export const previousPeriod = (period: string): string => {
  const periodObj = parse(period, 'yyyyMMdd');
  return format(endOfMonth(subMonths(periodObj, 1)), 'yyyy-MM-dd');
};

export const isFirstPeriod = (
  financialYear: string,
  period: string | null
): boolean => {
  if (!period) return false;
  return financialYear.startsWith(period);
};

const periodEnd = (periodEnd: string, financialYear: string): string | null => {
  const [, financialYearEnd] = financialYear.split('-');
  return getMonth(parse(periodEnd)) !== getMonth(parse(financialYearEnd))
    ? format(endOfMonth(parse(periodEnd)), 'yyyy-MM-dd')
    : format(parse(financialYearEnd), 'yyyy-MM-dd');
};

/**
 * Formats the passed in financial year in the format YYYY-MM-DD
 * @param financialYear
 * @param financialYears
 * @returns
 */
export const year = (
  financialYear: string,
  financialYears: string[] = [],
  endPeriod?: string | null
): string | null => {
  if (!financialYears.includes(financialYear)) return null;
  if (endPeriod) return periodEnd(endPeriod, financialYear);

  return format(parseFinancialYear(financialYear).end, 'yyyy-MM-dd');
};

/**
 * Formats the previous financial year to the passed in financial year in the format YYYY-MM-DD
 * @param financialYear '202001-202012'
 * @param financialYears [..., '202001-202012, 202101-202112]
 * @returns
 */
export const previousYear = (
  financialYear: string,
  financialYears: string[] = []
): string | null => {
  const { start } = parseFinancialYear(financialYear);
  const financialYearIndex = financialYears.indexOf(financialYear);
  if (financialYearIndex === -1) {
    return null;
  }

  const assumedPreviousYearEnd = format(
    endOfMonth(subDays(start, 1)),
    'yyyy-MM-dd'
  );

  if (financialYearIndex === 0 && financialYears.length === 1) {
    // only one (current) financial year exists
    return assumedPreviousYearEnd;
  }

  const prevFinancialYear = financialYears[financialYearIndex - 1];
  if (prevFinancialYear) {
    // check there is no gap in financial years
    const { end } = parseFinancialYear(prevFinancialYear);
    if (format(endOfMonth(end), 'yyyy-MM-dd') !== assumedPreviousYearEnd) {
      // eslint-disable-next-line no-console
      console.log(
        'No previous year found, assuming previous year to be ',
        assumedPreviousYearEnd
      );
    }
  }

  return assumedPreviousYearEnd;
};

export const previousYearStart = (
  financialYear: string,
  financialYears: string[]
): string | null => {
  const financialYearIndex = financialYears.indexOf(financialYear);
  if (financialYearIndex === -1) {
    return null;
  }
  const prevFinancialYear = financialYears[financialYearIndex - 1];
  if (!prevFinancialYear) {
    return null;
  }
  const { start } = parseFinancialYear(prevFinancialYear);
  return format(startOfMonth(start), 'yyyy-MM-dd');
};

/**
 * @param financialYear current financial year '202001-202012'
 * @param financialYears a customers multiple financial years '[202001-202012]'
 * @returns
 */
export const getPreviousYear = (
  financialYear: string,
  financialYears: string[] = []
): string | null => {
  const financialYearIndex = financialYears.indexOf(financialYear);
  if (financialYearIndex === -1) {
    return null;
  }
  return financialYears[financialYearIndex - 1];
};

/**
 * Get period (YYYYMM format) form previous year
 *
 * @param period '202011'
 * @param prevFinancialYear the year before the current financial year
 * @returns
 */
export const getPreviousYearPeriod = (
  period: string | null,
  prevFinancialYear: string
): string | null => {
  if (period === null) {
    return null;
  }
  if (prevFinancialYear) {
    const { start, end } = parseFinancialYear(prevFinancialYear);
    const periodMoment = subYears(parse(period, 'yyyyMM'), 1);
    if (
      isSameOrBefore(start, periodMoment) &&
      isSameOrAfter(end, periodMoment)
    ) {
      return format(periodMoment, 'yyyyMM');
    }
  }

  return null;
};

import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';
import Field from '../UI/Field';

export const Row = styled.div<{ noMargin?: boolean }>`
  display: flex;
  gap: ${(props) => props.theme.spacing(2)}px;
  padding: ${(props) => props.theme.spacing(1)}px;
  margin-top: ${(props) => (props.noMargin ? 0 : props.theme.spacing(2))}px;

  align-items: flex-start;

  & input {
    color: #00313c;
    border: 1px solid ${({ theme }) => theme.palette.grey[400]};
    border-radius: 3px;
    margin-right: 3px;
  }
`;

export const Label = styled(Typography)``;

export const CalculationsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing(1)}px;
`;

export const InputField = styled(Field)`
  width: 100px;
  min-width: 100px;
  text-align: right;
  font-weight: 500;
  padding-right: 6px;
  display: block;
  margin-left: auto;
  font-size: 1rem;
  word-break: break-all;
`;

export const NoteField = styled(Field)`
  width: 100px;
  min-width: 100px;
  text-align: right;
  display: block;
  margin-left: auto;
`;

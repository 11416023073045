import { AgoyDocument } from '@agoy/document';
import { annualGeneralMeetingConfig } from '../shares-company/annual-general-meeting/annualGeneralMeeting';
import { annualGeneralMeetingContentDefinition } from './document';
import { ClientInformationTables } from '../shares-company/types';

export const annualGeneralMeetingDocumentConfig = (
  clientInformationTables?: ClientInformationTables
): AgoyDocument<typeof annualGeneralMeetingContentDefinition> => {
  return {
    documentType: 'annualGeneralMeeting',
    annualGeneralMeeting: annualGeneralMeetingConfig(
      true,
      clientInformationTables
    ),
    annualReport: undefined,
  };
};

export { annualGeneralMeetingContentDefinition };

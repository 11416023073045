import React, { useCallback, useContext, useMemo } from 'react';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';
import {
  MenuItem,
  Select,
  Typography as MuiTypography,
} from '@material-ui/core';
import { Table } from '_shared/components/CommonTable';
import { TableProps } from '_shared/components/CommonTable/Table';
import { TableCell } from '_shared/components/Table/BorderTable';
import { AgoyTable, stringValue } from '@agoy/document';
import { isActive } from '@agoy/annual-report-document';
import EditField from '_shared/components/Inputs/EditField';
import { ClientInformation } from '@agoy/api-sdk-core';
import DocumentViewServiceContext from '_shared/services/document/DocumentViewServiceContext';
import PersonalNumber from '_shared/components/PersonalNumber';
import { mapDirectors } from 'utils/mapDirectors';
import { GenericDocumentViewService } from '_shared/services/document/GenericDocumentViewService';
import { getClasses } from '@agoy/common';
import StringInput from '../../UI/StringInput';
import TableSourceInformation from '../../UI/RegistrySourceInformation/TableSourceInformation';
import { SourceInfo } from '../../UI/RegistrySourceInformation/types';
import NameTableCIDiff from './NameTableCIDiff';

const Names = styled.div`
  margin: ${({ theme }) => theme.spacing(1)}px 0;
`;

const Typography = styled(MuiTypography)`
  font-size: 1rem;
  font-weight: 400;
`;

const Placeholder = styled(MuiTypography)`
  color: #757575;
  line-height: 1;
`;

const StyledTable = styled(Table)`
  max-width: 800px;
  .MuiTableCell-root {
    font-size: 1rem;
    &:nth-of-type(2) {
      width: 200px;
    }
    &:nth-of-type(3) {
      width: 200px;
    }
  }
  &.has-additional-row {
    .MuiTableRow-root:first-of-type {
      border-bottom: 3px solid ${({ theme }) => theme.palette.grey[300]};
    }
  }
`;

const ROLES = {
  ceo: 'Verkställande direktör',
  chairman: 'Styrelseordförande',
  board_member: 'Styrelseledamot',
  external_ceo: 'Extern verkställande direktör',
  deputy_ceo: 'Vice verkställande direktör',
  external_deputy_ceo: 'Extern vice verkställande direktör',
  board_deputy: 'Styrelsesuppleant',
};

const AUDITOR_ROLES = {
  auditor: 'Revisor',
  chief_auditor: 'Huvudansvarig revisor',
  deputy_auditor: 'Revisorssuppleant',
  lay_auditor: 'Lekmannarevisor',
  deputy_lay_auditor: 'Lekmannarevisorssuppleant',
  chartered_accountant: 'Auktoriserad revisor',
  approved_auditor: 'Godkänd revisor',
};

interface NameTableProps {
  editing: boolean;
  table: AgoyTable;
  tableId: string;
  addLabelId: string;
  print?: boolean;
  titleLabel?: string;
  editTitle?: boolean;
  titleId?: string;
  additionalRowId?: string;
}

const mapSourceInfo = (
  tableId: string,
  table: AgoyTable,
  clientInformation: ClientInformation,
  service: GenericDocumentViewService
): SourceInfo<Record<string, string>[]> | null => {
  const value = table.rows.map((row) => {
    const typeOfNumber: string =
      row?.cells?.personNr &&
      'tooltip' in row.cells.personNr &&
      row.cells.personNr.tooltip
        ? row.cells?.personNr.tooltip
        : '';

    const supplement: string =
      row?.cells?.name && 'tooltip' in row.cells.name && row.cells.name.tooltip
        ? row.cells?.name.tooltip
        : '';

    return {
      name: stringValue(row.cells?.name) ?? '',
      personNr: stringValue(row.cells?.personNr) ?? '',
      role: stringValue(row.cells?.role) ?? '',
      typeOfNumber,
      supplement,
    };
  });
  switch (tableId) {
    case 'annualGeneralMeeting.main.boardMembers': {
      const ciDirectors = clientInformation.directors;
      if (!ciDirectors) {
        return null;
      }
      const clientCeoInformationValue = mapDirectors(
        clientInformation.ceo?.value,
        'boardMembers'
      );
      const clientDirectorsInformationValue = mapDirectors(
        clientInformation.directors?.value,
        'boardMembers'
      );

      service.updateTableSource(tableId, {
        id: 'directors',
        type: ciDirectors.source,
        updatedAt: new Date(ciDirectors.timestamp).getTime(),
      });
      return {
        id: 'directors',
        source: table.source,
        clientInformationSource: ciDirectors,
        clientInformationValue: [
          ...clientCeoInformationValue,
          ...clientDirectorsInformationValue,
        ],
        value,
      };
    }
    case 'annualGeneralMeeting.main.auditors': {
      const ciAuditors = clientInformation.auditors;
      if (!ciAuditors) {
        return null;
      }
      const clientAuditorsInformationValue = mapDirectors(
        clientInformation.auditors?.value,
        'auditors'
      );
      const clientAuditFirmInformationValue = mapDirectors(
        clientInformation.auditFirm?.value,
        undefined,
        true
      );

      service.updateTableSource(tableId, {
        id: 'directors',
        type: ciAuditors.source,
        updatedAt: new Date(ciAuditors.timestamp).getTime(),
      });

      return {
        id: 'auditors',
        source: table.source,
        clientInformationSource: ciAuditors,
        clientInformationValue: [
          ...clientAuditFirmInformationValue,
          ...clientAuditorsInformationValue,
        ],
        value,
      };
    }
    default:
      return null;
  }
};

const NameTable = ({
  editing,
  print,
  table,
  tableId,
  addLabelId,
  titleLabel,
  editTitle,
  titleId,
  additionalRowId,
}: NameTableProps): JSX.Element => {
  const { formatMessage } = useIntl();

  const roles =
    tableId === 'annualGeneralMeeting.main.auditors' ? AUDITOR_ROLES : ROLES;
  const columns = print
    ? table.columns.filter((col) => col.id !== 'personNr')
    : table.columns;

  const service = useContext(DocumentViewServiceContext);

  const handleTitleLabelChange = (newValue) => {
    service.updateField(`annualGeneralMeeting.main.${titleId}`, newValue);
  };

  const hasAdditionalRow = useMemo(
    () => table?.rows.find((row) => row.id === additionalRowId),
    [table, additionalRowId]
  );

  const activeNameTable = table.rows.find((row) => isActive(row.active));

  const renderCell = useCallback<Required<TableProps>['renderCell']>(
    (values) => {
      const cellStringValue = stringValue(values.cell);

      if (values.column.id === 'personNr' && !editing && !!cellStringValue) {
        if ('tooltip' in values.cell && values.cell.tooltip === 'orgNumber') {
          return <TableCell align="center">{cellStringValue}</TableCell>;
        }
        return (
          <TableCell>
            <PersonalNumber value={cellStringValue} />
          </TableCell>
        );
      }

      if (values.column.id === 'name' && !!cellStringValue) {
        if (
          !editing &&
          !print &&
          'tooltip' in values.cell &&
          values.cell.tooltip
        ) {
          return (
            <TableCell align="left">{`${cellStringValue} ${values.cell.tooltip}`}</TableCell>
          );
        }
        return <TableCell align="left">{cellStringValue}</TableCell>;
      }

      if (values.column.id === 'role') {
        if (values.row.id === additionalRowId) {
          return (
            <TableCell align="left">
              {formatMessage({
                id: `clientInformation.${cellStringValue}`,
              })}
            </TableCell>
          );
        }
        if (editing) {
          return (
            <TableCell align="left">
              <Select
                value={stringValue(values.cell)}
                variant="outlined"
                fullWidth
                displayEmpty
                onChange={(event) =>
                  service.updateCellValue(
                    `${values.baseId}.${values.row.id}.role`,
                    `${event.target.value}`
                  )
                }
                renderValue={
                  !stringValue(values.cell)
                    ? () => (
                        <Placeholder>
                          {formatMessage({
                            id: `annualReport.${values.baseId}.role`,
                          })}
                        </Placeholder>
                      )
                    : undefined
                }
              >
                {Object.entries(roles).map(([role, label]) => (
                  <MenuItem key={role} value={role}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </TableCell>
          );
        }
        return (
          <TableCell align="left">
            {roles[stringValue(values.cell) ?? ''] ?? ''}
          </TableCell>
        );
      }
      return null;
    },
    [editing, formatMessage, service, roles, additionalRowId]
  );

  const onDeleteRow = useCallback<Required<TableProps>['onDeleteRow']>(
    (row, id) => {
      service.deleteRow(id);
    },
    [service]
  );

  const onAddRow = useCallback<Required<TableProps>['onAddRow']>(
    (baseId) => {
      service.addRow(baseId, undefined, { name: '', personNr: '', role: '' });
    },
    [service]
  );

  const onReset = useCallback(() => {
    service.resetContent(tableId);
  }, [service, tableId]);

  const onResetBackRow = useCallback(() => {
    service.resetContent(`${tableId}.${additionalRowId}`);
  }, [service, tableId, additionalRowId]);

  const additionalButton = useMemo(() => {
    if (additionalRowId) {
      return !hasAdditionalRow
        ? [
            {
              label: formatMessage({
                id: 'annualReport.annualGeneralMeeting.main.auditors.additionalButton.getAuditFirm',
              }),
              onClick: onResetBackRow,
            },
          ]
        : [];
    }
    return [];
  }, [additionalRowId, hasAdditionalRow, formatMessage, onResetBackRow]);

  const classNames = getClasses({
    'has-additional-row': !!hasAdditionalRow && !print,
  });

  return (
    <Names>
      {titleLabel && activeNameTable && !editTitle && (
        <Typography>{titleLabel} </Typography>
      )}
      {titleLabel && activeNameTable && editTitle && (
        <EditField
          value={titleLabel || ''}
          onChange={handleTitleLabelChange}
          component={StringInput}
        />
      )}
      <TableSourceInformation
        editing={editing}
        table={table}
        tableId={tableId}
        renderDifference={(sourceInfo) => {
          return (
            <NameTableCIDiff
              rows={sourceInfo.clientInformationValue}
              roles={ROLES}
            />
          );
        }}
        mapSourceInfo={(clientInformation, theTable) =>
          mapSourceInfo(tableId, theTable, clientInformation, service)
        }
        onReset={onReset}
      >
        {table.rows.length > 0 || editing ? (
          <StyledTable
            baseId="annualReport"
            columns={columns}
            className={classNames}
            rows={table.rows}
            tableId={tableId}
            editing={editing}
            print={print}
            service={service}
            hideHeader
            withPlaceholder
            renderCell={renderCell}
            canDeleteRows
            onDeleteRow={onDeleteRow}
            canAddRows
            onAddRow={onAddRow}
            addNewRowLabelId={addLabelId}
            additionalButtons={additionalButton}
          />
        ) : null}
      </TableSourceInformation>
    </Names>
  );
};

export default NameTable;

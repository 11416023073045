import { setIntegrationStatus } from '_clients/redux/customers/actions';
import { addGlobalErrorMessage } from '_messages/redux/actions';
import { asResultClass, useApiSdk } from 'api-sdk';
import { ApiErrorType, isApiErrorType } from 'api-sdk/api-errors';
import useClientDataLayer from 'data/client/useClientDataLayer';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'redux/reducers';
import { Err } from 'ts-results';
import isFortnoxErrorInformation from 'utils/isFortnoxErrorInformation';

const handleErrorMessage = (
  dispatch: ReturnType<typeof useDispatch>,
  result: Err<ApiErrorType | Error>
) => {
  if (isApiErrorType(result.val) && result.val.handled) {
    return;
  }

  // eslint-disable-next-line no-console
  console.warn(result.val);
  if (
    isApiErrorType(result.val) &&
    isFortnoxErrorInformation(result.val.body.details)
  ) {
    dispatch(addGlobalErrorMessage(undefined, result.val.body.details.Message));
  } else if (isApiErrorType(result.val) && result.val.status === 403) {
    dispatch(
      addGlobalErrorMessage('customersubmenu.updatewithfortnox.access.error')
    );
  } else {
    dispatch(addGlobalErrorMessage('customersubmenu.updatewithfortnox.error'));
  }
};

type UpdateSieFromFortnoxProps = {
  clientId: string;
  financialYear: string;
  immediatelyUpdate?: boolean;
};

/**
 * Component with a hook to regularly import the latest SIE from fortnox every 30 min.
 *
 * @param immediatelyUpdate Starts an update immediately
 */
const UpdateSieFromFortnox = ({
  clientId,
  financialYear,
  immediatelyUpdate = false,
}: UpdateSieFromFortnoxProps): null => {
  const dispatch = useDispatch();
  const sdk = useApiSdk();

  const supportUser = useSelector((state) => state.user.supportUser);

  const clientDataLayer = useClientDataLayer(clientId);

  useEffect(() => {
    if (supportUser || !clientDataLayer) {
      return undefined;
    }
    const updateSie = async () => {
      if (clientId && financialYear) {
        try {
          const { isActive } = await sdk.getFortnoxIntegrationStatus({
            clientid: clientId,
          });

          if (isActive) {
            dispatch(
              setIntegrationStatus(clientId, 'fortnox', {
                lastModified: new Date().toISOString(),
                lastAccessed: new Date().toISOString(),
              })
            );

            const result = await asResultClass(
              sdk.updateSieFortnoxIntegration({
                clientid: clientId,
                financialYear,
              })
            );
            if (result.err) {
              handleErrorMessage(dispatch, result);
            }
          } else {
            dispatch(setIntegrationStatus(clientId, 'fortnox', null));
          }
        } catch (integrationCheckError) {
          handleErrorMessage(dispatch, integrationCheckError);
        }
      }
    };

    if (immediatelyUpdate) {
      updateSie();
    }

    const interval = setInterval(updateSie, 1800000);
    return () => {
      clearInterval(interval);
    };
  }, [
    clientId,
    financialYear,
    clientDataLayer,
    supportUser,
    dispatch,
    sdk,
    immediatelyUpdate,
  ]);

  return null;
};

export default UpdateSieFromFortnox;

import { AnnualReport } from '../../document';
export type Settings = AnnualReport['settings'];
export type SettingsSection = Settings['section'];
export type WarningsSection = Settings['warnings'];

const cell = { type: 'field' as const };
const boolean = { type: 'boolean' as const };

export const settings = {
  type: 'part' as const,
  children: {
    warnings: {
      type: 'part' as const,
      children: {
        table: { type: 'table' as const },
        submissionWarnings: { type: 'table' as const },
      },
    },
    section: {
      type: 'part' as const,
      children: {
        title: cell,
        secondaryText: cell,
        currencyText: cell,
        isFirstFinancialYear: boolean,
        clientName: cell,
        financialYear: cell,
        organisationNumber: cell,
        isSinglePeriod: boolean,
        period: cell,
        periodStart: cell,
        periodEnd: cell,
        previousPeriodStart: cell,
        previousPeriodEnd: cell,
        logoLargeUrl: cell,
        logoUrl: cell,
        isAuditReportRequired: cell,
        auditReportRequiredUpdated: cell,
        typeSigningAnnualReport: {
          type: 'part' as const,
          children: {
            isDigitalSigningWithFortnox: cell,
            isManualSigning: cell,
            isOtherDigitalSigning: cell,
          },
        },
        typeSigningProtocol: {
          type: 'part' as const,
          children: {
            isDigitalSigningWithFortnox: cell,
            isManualSigning: cell,
            isOtherDigitalSigning: cell,
          },
        },
        cashFlowStatementActive: cell,
        annualGeneralMeetingActive: cell,
        formatting: {
          type: 'part' as const,
          children: {
            displayInThousands: cell,
            displayMultiYearOverviewInThousands: cell,
            displayChangesInEquityInThousands: cell,
            displayResultsDispositionInThousands: cell,
            labelDisplayInThousands: cell,
            numberOfDecimalsForPercentagesInMultiYearOverview: cell,
          },
        },
      },
    },
    clientInformation: {
      type: 'part' as const,
      children: {
        city: cell,
        activityText: cell,
      },
    },
    isNedskrivningEgenPost: cell,
  },
};

export type Warning = {
  id: string;
  label: string;
  result: string;
  type: string;
};

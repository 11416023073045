export const ORG_NUMBER = 'orgNumber';
export const VAT_PERIOD = 'vatPeriod';
export const COMPANY_TYPE = 'type';
export const CLOSING_PERIOD = 'closingPeriod';
export const CLOSING_MONTH = 'closingMonth';
export const CLIENT_MANAGER = 'managerEmail';
export const COMPANY_NAME = 'name';
export const COMPANY_ADDRESS = 'address';
export const EMAIL = 'email';
export const phoneNumber = 'number';
export const CONTACT_PERSON = 'contact';
export const START_DATE = 'startDate';

export const clientInformationAttributes = [
  ORG_NUMBER,
  COMPANY_ADDRESS,
  CLOSING_PERIOD,
  VAT_PERIOD,
  COMPANY_TYPE,
  CLOSING_MONTH,
  CLIENT_MANAGER,
  START_DATE,
];
export const contactInformationAttributes = [
  CONTACT_PERSON,
  phoneNumber,
  EMAIL,
];

export const CUSTOMER_KEYS = [
  ...clientInformationAttributes,
  ...contactInformationAttributes,
];
export const additionalInformation = [
  COMPANY_ADDRESS,
  ORG_NUMBER,
  phoneNumber,
  CONTACT_PERSON,
  EMAIL,
];

export const fullCostomerInformation = CUSTOMER_KEYS.reduce((tot, val) => {
  return { ...tot, [val]: '' };
}, {});

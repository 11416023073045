import { addItem, insertAt } from './helpers';
import updateDocument from './helpers/updateDocument';
import updateTable from './helpers/updateTable';
import updateCell from './helpers/updateCell';
import { AgoyDocumentStructure } from '../document';
import { OperationResult, State } from './types';
import { AgoyTable, AgoyTableColumn, AgoyTableRow } from '../table';
import { Cell } from '../../Cell';
import { ColumnChange } from '../changes';
import { applyParameters } from '../createNewRow';

const addTableColumn = <T extends AgoyDocumentStructure>(
  structure: T,
  state: State<T>,
  tableId: string,
  column: AgoyTableColumn,
  index: number
): OperationResult<T> => {
  return updateDocument(structure, state, tableId, {
    table: (key, id, props) => {
      const newColumnTemplate = props.node.newColumnTemplate || {
        type: 'number',
      };

      // FIXME Tobias
      // if (partKey === 'notes' && sectionKey.includes('custom-')) {
      //   newColumnTemplate = { type: 'string' };
      // }
      let updatedState = updateTable(
        props.node,
        props.changes,
        (table, change) => {
          const updateRow = (
            row: AgoyTableRow,
            fullRowId: string
          ): AgoyTableRow => {
            let newRow = row;

            if (newRow.rows) {
              newRow = {
                ...newRow,
                rows: newRow.rows.map((item) =>
                  updateRow(item, `${fullRowId}.${row.id}`)
                ),
              };
            }

            if (newColumnTemplate?.generator) {
              const newCell = newColumnTemplate.generator(
                column.id,
                row.id,
                fullRowId,
                {}
              );
              if (newCell) {
                newRow = {
                  ...newRow,
                  cells: {
                    ...newRow.cells,
                    [column.id]: newCell,
                  },
                };
              }
            }
            if (newRow.cells) {
              const defaultCell: Cell =
                newColumnTemplate.type === 'string'
                  ? { type: 'string', value: '' }
                  : { type: 'number', value: 0 };

              newRow = {
                ...newRow,
                cells: {
                  ...newRow.cells,
                  [column.id]: defaultCell,
                },
              };
            }

            if (newRow.newRowTemplate) {
              if (typeof newRow.newRowTemplate === 'object') {
                newRow = {
                  ...newRow,
                  newRowTemplate: updateRow(newRow.newRowTemplate, '$fullId'),
                };
              } else if (typeof newRow.newRowTemplate === 'function') {
                console.error(
                  'Adding columns to rows with a newRowTemplate function is not supported',
                  newRow.id
                );
              }
            }

            return newRow;
          };

          let newTable: AgoyTable = {
            ...table,
            columns: insertAt(table.columns, column, index),
            rows: table.rows.map((row) =>
              updateRow(row, `${tableId}.${row.id}`)
            ),
          };

          if (newTable.newRowTemplate) {
            if (typeof newTable.newRowTemplate === 'object') {
              newTable = {
                ...newTable,
                newRowTemplate: updateRow(
                  newTable.newRowTemplate,
                  `${tableId}.$rowId`
                ),
              };
            } else if (typeof newTable.newRowTemplate === 'function') {
              console.error(
                'Adding columns to tables with a newRowTemplate function is not supported',
                tableId
              );
            }
          }

          const result: ColumnChange = {
            cellType: newColumnTemplate.type,
            label: column.label,
            id: column.id,
            type: 'add',
            index,
          };

          if (column.sortKey) {
            result.sortKey = column.sortKey;
          }

          return [
            newTable,
            {
              ...change,
              columns: addItem(change.columns, result),
            },
          ];
        }
      );

      if (typeof updatedState === 'object') {
        const stateWithNewColumn = updatedState;
        newColumnTemplate?.cells?.forEach((item) => {
          if (!item.id.startsWith(tableId)) {
            return;
          }

          const subRowIds = item.id.substring(tableId.length + 1).split('.');

          if (item.columnId) {
            if (item.columnId === column.id) {
              const updater = updateCell(subRowIds, column.id, () =>
                applyParameters(item.cell, {
                  columnId: column.id,
                })
              );
              const result = updater(...stateWithNewColumn);
              if (result) {
                updatedState = result;
              }
            }
          } else {
            const updater = updateCell(subRowIds, column.id, () =>
              applyParameters(item.cell, {
                columnId: column.id,
              })
            );
            const result = updater(...stateWithNewColumn);
            if (result) {
              updatedState = result;
            }
          }
        });

        if (!updatedState) {
          return props;
        }
        return {
          ...props,
          node: updatedState[0],
          changes: updatedState[1],
        };
      }
      return props;
    },
  });
};

export default addTableColumn;

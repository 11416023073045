import { AgoyTable, Cell, Field } from '@agoy/document';
import { AnnualReportPart, AnnualReportSection } from '../../document';

const cell = { type: 'field' as const };
const boolean = { type: 'boolean' as const };

type ClientInformationTableRow = {
  id: string;
  cells: {
    name: Cell;
    personNr: Cell;
    role: Cell;
  };
};

export type ClientInformationTables = {
  auditorsRows: ClientInformationTableRow[];
  auditFirmRows: ClientInformationTableRow[];
  boardMembersRows: ClientInformationTableRow[];
};

export interface AnnualGeneralMeetingSection extends AnnualReportSection {
  heading: Field;
  headingText: Field;
  location: Field;
  date: Field;
  votingListHeading: Field;
  votingListHeadingText: Field;
  votingListText: Field;
  chairmanHeading: Field;
  chairmanHeadingText: Field;
  recordKeeperHeading: Field;
  recordKeeperHeadingText: Field;
  adjusterHeading: Field;
  adjusterHeadingText: Field;
  noticeAndAgendaHeading: Field;
  noticeAndAgendaHeadingText: Field;
  auditorReportHeading: Field;
  auditorReportHeadingText: Field;
  resultAndBalanceHeading: Field;
  resultAndBalanceHeadingText: Field;
  dischargeHeading: Field;
  dischargeHeadingText: Field;
  feeHeading: Field;
  feeHeadingText: Field;
  boardMembersHeading: Field;
  boardMembersHeadingText: Field;
  auditorsParagraphHeading: Field;
  auditorsParagraphHeadingText: Field;
  auditorsParagraphText: Field;
  auditorTitle: Field;
  deputyAuditorTitle: Field;
  terminationHeading: Field;
  terminationHeadingText: Field;
  votingList: AgoyTable;
  fee: Field;
  result: Field;
  resultDispositionHeading: Field;
  resultDispositionHeadingText: Field;
  resultsDisposition: AgoyTable; // used in the case of an approved result
  resultsDispositionRejected: AgoyTable; // used in the case of a rejected result
  resultsDispositionText: Field;
  agmApprovedResultsDisposition: Field;
  boardMembers: AgoyTable;
  auditors: AgoyTable;
  auditFirm: AgoyTable;
  noAuditor: Field;
  deputyAuditors: AgoyTable;
  customParagraphs: AgoyTable;
}

export interface AnnualGeneralMeeting extends AnnualReportPart {
  main: AnnualGeneralMeetingSection;
}

export const annualGeneralMeeting = {
  type: 'part' as const,
  children: {
    active: boolean,
    main: {
      type: 'part' as const,
      children: {
        active: boolean,
        heading: cell,
        headingText: cell,
        location: cell,
        date: cell,
        votingListHeading: cell,
        votingListHeadingText: cell,
        votingListText: cell,
        chairmanHeading: cell,
        chairmanHeadingText: cell,
        recordKeeperHeading: cell,
        recordKeeperHeadingText: cell,
        adjusterHeading: cell,
        adjusterHeadingText: cell,
        noticeAndAgendaHeading: cell,
        noticeAndAgendaHeadingText: cell,
        auditorReportHeading: cell,
        auditorReportHeadingText: cell,
        resultAndBalanceHeading: cell,
        resultAndBalanceHeadingText: cell,
        resultDispositionHeading: cell,
        resultDispositionHeadingText: cell,
        dischargeHeading: cell,
        dischargeHeadingText: cell,
        feeHeading: cell,
        feeHeadingText: cell,
        boardMembersHeading: cell,
        boardMembersHeadingText: cell,
        auditorsParagraphHeading: cell,
        auditorsParagraphHeadingText: cell,
        auditorsParagraphText: cell,
        auditorTitle: cell,
        deputyAuditorTitle: cell,
        terminationHeading: cell,
        terminationHeadingText: cell,
        votingList: { type: 'table' as const },
        result: cell,
        agmApprovedResultsDisposition: cell,
        resultsDisposition: { type: 'table' as const },
        resultsDispositionRejected: { type: 'table' as const },
        resultsDispositionText: cell,
        fee: cell,
        boardMembers: { type: 'table' as const },
        auditors: { type: 'table' as const },
        auditFirm: { type: 'table' as const },
        noAuditor: cell,
        deputyAuditors: { type: 'table' as const },
        customParagraphs: { type: 'table' as const },
      },
    },
  },
};

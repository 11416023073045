import React from 'react';
import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';

export const Tag = styled.div<{ clientType: 'person' | 'company' }>`
  width: max-content;
  border-radius: 4px;
  padding: 2px 4px;
  background-color: ${({ theme, clientType }) =>
    theme.palette.clientView.tag[clientType]};
`;

const ClientTag = ({ clientType }: { clientType: 'person' | 'company' }) => {
  const { formatMessage } = useIntl();

  return (
    <Tag clientType={clientType}>
      <Typography variant="caption">
        {formatMessage({
          id: `client.view.header.${clientType}`,
        })}
      </Typography>
    </Tag>
  );
};

export default ClientTag;

import React from 'react';
import { mapRecord } from '@agoy/common';
import { ResolvedReference } from '@agoy/document';
import { IntlMessageFormat } from 'intl-messageformat';

interface MessageCellProps {
  className?: string;
  message: string;
  parameters: Record<string, ResolvedReference> | undefined;
}

const MessageCell = ({
  message,
  parameters,
  className,
}: MessageCellProps): JSX.Element => {
  const value = new IntlMessageFormat(message).format(
    mapRecord(parameters || {}, (v) =>
      typeof v === 'object' || v === undefined ? '' : v
    )
  );
  return <span className={className}>{value}</span>;
};

export default MessageCell;

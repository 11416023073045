import React, { useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { Paper, Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { Comment } from '_shared/types';
import { format } from 'date-fns';
import LoadingPlaceholder from '../LoadingPlaceholder';
import CommentsSubsectionList, {
  CommentsSubsection,
} from './CommentsSubsectionList';

const CommentsListWrapper = styled(Paper)`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  margin-top: -${({ theme }) => theme.spacing(0.5)}px;
`;

const CommentsListContent = styled.div`
  padding: ${({ theme }) => theme.spacing(1.5)}px;
`;

const CommentContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${(props) => props.theme.palette.grey[300]};
  padding-top: ${({ theme }) => theme.spacing(1)}px;
  padding-bottom: ${({ theme }) => theme.spacing(1)}px;
  white-space: pre-wrap;
`;

const CommentInfo = styled(Typography)`
  color: ${({ theme }) => theme.palette.grey[500]};
`;

interface CommentItemProps {
  comment: Comment;
  displayName: boolean;
  displayCreatedAt: boolean;
}

/**
 * Renders a comment item in the list
 *
 * @param comments Comment object
 * @param displayName Display or hide author name
 * @param displayCreatedAt Display or hide date of creation
 * @returns A single comment item
 */
const CommentItem = ({
  comment: { comment, userDisplayName, createdAt },
  displayName,
  displayCreatedAt,
}: CommentItemProps) => (
  <CommentContainer>
    <Typography variant="body1">{comment}</Typography>
    <CommentInfo variant="body2">{`${
      displayCreatedAt
        ? `${format(new Date(createdAt), 'yyyy-MM-dd HH:mm')} `
        : ''
    }${displayName ? userDisplayName : ''}`}</CommentInfo>
  </CommentContainer>
);

interface Props {
  comments: Comment[];
  displayName: boolean;
  displayCreatedAt: boolean;
  loading: boolean;
  preview?: CommentsSubsection;
  showPreview?: boolean;
  subsections?: CommentsSubsection[];
}

/**
 * Renders list of comments or message about absent comments or loader when list is loading
 *
 * @param comments List of comments
 * @param displayName Display or hide author name
 * @param displayCreatedAt Display or hide date of creation
 * @param loading Comment section performs a network request
 * @param preview comments to display as preview, includes title and comments (optional)
 * @param showPreview flag to toggle preview visibility, default = false (optional)
 * @param subsections subsections of comments, includes title and comments title, comments and additional info (optional)
 * @returns A list of comments or message about absent comments
 */
const CommentsList = ({
  comments,
  displayName,
  displayCreatedAt,
  loading,
  preview,
  showPreview = false,
  subsections,
}: Props) => {
  const { formatMessage } = useIntl();
  const commentsContainerRef = useRef<HTMLDivElement>(null);
  const previewRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (showPreview && previewRef.current) {
      previewRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      });
    }

    if (!showPreview && commentsContainerRef.current) {
      commentsContainerRef.current.scrollTo({ behavior: 'smooth', top: 0 });
    }
  }, [showPreview]);

  return (
    <CommentsListWrapper ref={commentsContainerRef}>
      {loading ? (
        <LoadingPlaceholder />
      ) : (
        <>
          {!comments.length && !preview && !subsections?.length && (
            <CommentsListContent>
              <Typography variant="body1">
                {formatMessage({ id: 'comments.noComments' })}
              </Typography>
            </CommentsListContent>
          )}
          {comments.length > 0 && (
            <CommentsListContent>
              {comments.map((comment) => (
                <CommentItem
                  key={comment.id}
                  comment={comment}
                  displayName={displayName}
                  displayCreatedAt={displayCreatedAt}
                />
              ))}
            </CommentsListContent>
          )}
          {subsections &&
            subsections.map((subSection) => (
              <CommentsSubsectionList
                key={subSection.title}
                subsection={subSection}
              />
            ))}
          {showPreview && preview && (
            <CommentsSubsectionList
              ref={previewRef}
              subsection={preview}
              preview
            />
          )}
        </>
      )}
    </CommentsListWrapper>
  );
};

export default CommentsList;

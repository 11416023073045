import React from 'react';
import styled from '@emotion/styled';
import { AgoyTableColumn, AgoyTableRow, stringValue } from '@agoy/document';

import { TableCell, TableRow } from '@material-ui/core';
import Cell from './Cell';

const StyledRow = styled(TableRow, {
  shouldForwardProp: (propName) => propName !== 'print',
})<PrintProps>`
  background-color: #f5f5f5;
  text-align: end;
  border: none;
  line-height: ${({ print }) => (print ? '1.1rem' : '0.6rem')};

  & > td:nth-of-type(2n) {
    background-color: #f4faf6;
  }

  & > td:nth-of-type(1) {
    text-align: start;
    border-right: 1px solid #bdeec4;
  }
`;

const StyledCell = styled(TableCell, {
  shouldForwardProp: (propName) => propName !== 'print',
})<PrintProps>`
  text-align: inherit;
  font-size: ${({ print }) => (print ? '0.7rem' : '0.4rem')};
  line-height: ${({ print }) => (print ? '1.3rem' : '0.6rem')};
  padding: 1px;
  border: none;

  &.last-row {
    border-top: 1px solid #bdeec4;
  }
`;

const TitleCell = styled(TableCell, {
  shouldForwardProp: (propName) => propName !== 'print',
})<PrintProps>`
  text-align: inherit;
  font-size: ${({ print }) => (print ? '0.7rem' : '0.4rem')};
  line-height: ${({ print }) => (print ? '1.1rem' : '0.6rem')};
  border: none;

  &.highlighted {
    font-weight: bold;
  }

  &.last-row {
    border-top: 1px solid #bdeec4;
  }
`;

type PrintProps = {
  print: boolean;
};

interface RRBRTableRowProps {
  columns: AgoyTableColumn[];
  row: AgoyTableRow;
  isLastRow: boolean;
  print?: boolean;
  className?: string;
  formatValueInThousands?: boolean;
}

const highlightedHeaders = [
  'Tillgängar',
  'Eget kapital och skulder',
  'Kassaflöde per månad',
];

const RRBRTableRow = ({
  columns,
  row,
  isLastRow,
  print = false,
  className,
  formatValueInThousands = false,
}: RRBRTableRowProps): JSX.Element => {
  const getCell = (tRow: AgoyTableRow, tColumn: AgoyTableColumn) => {
    return tRow.cells?.[tColumn.id];
  };

  const firstCell = getCell(row, columns[0]);

  const styleRowHeader = () => {
    let style = '';

    if (highlightedHeaders.includes(stringValue(firstCell) || '')) {
      style += 'highlighted';
    }

    if (isLastRow) {
      style += ' last-row';
    }

    return style;
  };

  return (
    <StyledRow className={className} print={print}>
      <TitleCell print={print} className={styleRowHeader()}>
        <Cell cell={firstCell} />
      </TitleCell>
      {columns.map((col, index) => {
        if (index === 0) return null;
        const cell = getCell(row, col);
        const key = `${row.id}.${col.id}.${index}`;

        if (!cell) {
          return <TableCell key={key} style={{ borderBottom: 0 }} />;
        }
        if (formatValueInThousands) {
          return (
            <StyledCell
              print={print}
              key={key}
              className={isLastRow ? 'last-row' : ''}
            >
              <Cell cell={cell} formatValueInThousands />
            </StyledCell>
          );
        }
        return (
          <StyledCell
            print={print}
            key={key}
            className={isLastRow ? 'last-row' : ''}
          >
            <Cell cell={cell} />
          </StyledCell>
        );
      })}
    </StyledRow>
  );
};

export default RRBRTableRow;

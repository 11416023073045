import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';
import {
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  InputAdornment,
  Grid,
  FormControl,
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import Logo from '_shared/components/Logo';
import { ClientCompanyType } from '_clients/types/types';

const CustomerLogo = styled(Logo)`
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
`;

const SearchField = styled(FormControl)`
  width: 100%;
`;

const ClientStatusInactive = styled(Typography)`
  color: ${(props) => props.theme.palette.error.main};
  text-transform: capitalize;
`;

const ClientStatusActive = styled(Typography)`
  color: ${(props) => props.theme.palette.secondary.main};
  text-transform: capitalize;
`;

interface ClientCompaniesListProps {
  clients: { [key: string]: ClientCompanyType };
  isAdmin: boolean;
  handleSelectClient: (clientId: string) => void;
  isLoading: boolean;
}

const ClientCompaniesList = ({
  clients,
  isAdmin,
  handleSelectClient,
  isLoading,
}: ClientCompaniesListProps) => {
  const { formatMessage } = useIntl();

  const [searchString, setSearchString] = useState<string>('');
  const [filteredClients, setFilteredClients] = useState<ClientCompanyType[]>(
    []
  );

  useEffect(() => {
    // keep local sorted and filtered list of customers displayed in list
    let sortedClientCompanies = Array.from(Object.values(clients)).sort(
      (a, b) => {
        if (a.name) {
          return a.name.localeCompare(b.name);
        }
        return ''.localeCompare(b.name);
      }
    );

    if (searchString) {
      sortedClientCompanies = sortedClientCompanies.filter((client) =>
        client.name.toLowerCase().includes(searchString)
      );
    }

    setFilteredClients(sortedClientCompanies);
  }, [clients, searchString]);

  const onSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const search: string = event.target.value.trim().toLowerCase();
    setSearchString(search);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <SearchField>
          <TextField
            id="search-company"
            variant="outlined"
            size="small"
            placeholder={formatMessage({
              id: 'dashboard.customers.search.placeHolder',
            })}
            fullWidth
            onChange={(ev) => {
              onSearchInputChange(ev);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </SearchField>
        <Grid item xs={12}>
          <List>
            {isLoading && (
              <Skeleton
                variant="rect"
                animation="wave"
                width="100%"
                height="40px"
                component="div"
              />
            )}
            {filteredClients.length !== 0
              ? filteredClients
                  .filter((client) => client.active || isAdmin)
                  .map((client) => (
                    <React.Fragment key={client.id}>
                      <ListItem
                        button
                        onClick={() => {
                          handleSelectClient(client.id);
                        }}
                      >
                        <ListItemIcon>
                          <CustomerLogo src={client.logo} />
                        </ListItemIcon>
                        <ListItemText>
                          <Typography>{client.name}</Typography>
                          {isAdmin &&
                            (client.active ? (
                              <ClientStatusActive>
                                {formatMessage({
                                  id: `dashboard.customers.status.active`,
                                })}
                              </ClientStatusActive>
                            ) : (
                              <ClientStatusInactive>
                                {formatMessage({
                                  id: `dashboard.customers.status.inactive`,
                                })}
                              </ClientStatusInactive>
                            ))}
                        </ListItemText>
                      </ListItem>
                    </React.Fragment>
                  ))
              : searchString && (
                  <ListItem>
                    <ListItemText>
                      {formatMessage({
                        id: 'dashboard.customers.search.noResults',
                      })}
                    </ListItemText>
                  </ListItem>
                )}
          </List>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ClientCompaniesList;

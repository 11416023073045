import React from 'react';
import styled from '@emotion/styled';
import Typography from '../../../../UI/Typography/Typography';
import { useIntl } from 'react-intl';
import { isValid } from 'date-fns';
import { parse } from '@agoy/dates';

import {
  AgoyTable,
  AgoyTableRow,
  Cell,
  Field,
  StringCell,
  stringValue,
} from '@agoy/document';
import { AnnualReportVersion } from '@agoy/annual-report-document';
import { ACCOUNTANT_LABELS } from '../../../../types/enums';
import { IXElement } from '../../../../ARKeyToIX';

const Content = styled.div`
  margin: ${(props) => props.theme.spacing(2)}px 0;
  p {
    white-space: nowrap;
  }
`;

const returnIxbrlField = (value, statement) => {
  const hasStatement = statement.value !== '';
  const ixbrlObject = {
    ...value,
    ixbrl: {
      ...value.ixbrl,
      name: hasStatement
        ? 'se-gen-base:RevisorspateckningRevisionsberattelseAvvikerStandardutformning'
        : 'se-gen-base:RevisorspateckningRevisionsberattelseEnligtStandardutformning',
    },
  };
  return ixbrlObject;
};

const countAuditors = (rows: AgoyTableRow<Cell>[]) => {
  let count = 0;

  rows.forEach((row) => {
    const accountantRoles = (row?.cells?.accountantRole as StringCell)?.value;
    accountantRoles !== '' && count++;
  });

  return count;
};

type SignaturesAuditReportDateProps = {
  people: AgoyTable;
  value: Field;
  statement: Field;
  statementPronoun: Field;
  version: AnnualReportVersion;
};

const SignaturesAuditReportDate = ({
  people,
  value,
  statement,
  statementPronoun,
  version,
}: SignaturesAuditReportDateProps): JSX.Element => {
  const { formatMessage } = useIntl();

  const peopleRows = people.rows;

  let isMainAccountantCompany = countAuditors(peopleRows) > 1;

  if (!isMainAccountantCompany) {
    peopleRows.forEach((row) => {
      const accountantRoles = (row?.cells?.accountantRole as StringCell)?.value;
      const roles = accountantRoles ? accountantRoles.split('|') : [];

      const isAccountantFirm = !!roles.find((value) =>
        value.includes(ACCOUNTANT_LABELS.MAIN_ACCOUNTANT_IS_ACCOUNTANT_FIRM)
      );

      if (isAccountantFirm) {
        isMainAccountantCompany = isAccountantFirm;
      }
    });
  }

  const parsedDate = parse(stringValue(value));

  const statementPronounValue = stringValue(statementPronoun);

  return (
    <Content>
      <Typography>
        {`${formatMessage({
          id:
            version === '1' || !statementPronounValue
              ? `annualReport.signatures.section.auditReportDate.${
                  isMainAccountantCompany ? 'ours' : 'mine'
                }`
              : `annualReport.signatures.section.audit.${statementPronounValue}`,
        })} ${formatMessage({
          id: 'annualReport.signatures.section.auditReportDate',
        })} ${formatMessage({
          id: `annualReport.signatures.section.auditReportDate.${
            !stringValue(value) || isValid(parsedDate)
              ? 'withDate'
              : 'withoutDate'
          }`,
        })}
      `}
        <IXElement arPart={returnIxbrlField(value, statement)}>
          {stringValue(value)}
        </IXElement>
      </Typography>
    </Content>
  );
};

export default SignaturesAuditReportDate;

import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Paper, Box, IconButton, Typography, Tooltip } from '@material-ui/core';
import ResetIcon from '@material-ui/icons/Restore';
import PrintIcon from '@material-ui/icons/Print';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import styled from '@emotion/styled';
import EditingIconButton from '_annual-report/components/AnnualReportView/UI/EditingIconButton';
import ResetContentDialog from '_shared/components/ResetContent/ResetContentDialog';

const PageContent = styled.div`
  min-width: 840px;
  max-width: 100%;
  display: flex;
  gap: ${({ theme }) => theme.spacing(2)}px;
`;

const BoxTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(2)}px;

  h2 {
    margin: 0;
  }
`;

const RightBox = styled(Box)`
  transition: max-height 0.5s, max-width 0.5s, min-width 0.5s;
  max-height: 0px;
  overflow: hidden;
  max-width: 0;
  min-width: 0;
  white-space: nowrap;

  &.shown {
    max-height: 5000px;
    max-width: 50%;
    min-width: 650px;
  }
`;

const BoxContent = styled.div`
  padding: ${({ theme }) => theme.spacing(2)}px;
`;

const BoxTitleActionButtons = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(1)}px;
`;

const InfoIcon = styled(InfoOutlinedIcon)`
  margin-left: ${({ theme }) => theme.spacing(1)}px;
  color: #00313c;
`;

interface ExpandableTwoColPanelProps {
  leftColTitle: string;
  leftTooltip?: string;
  rightColTitle: string;
  rightTooltip?: string;
  isExpandable?: boolean;
  leftCol: (isEditing?: boolean) => React.ReactElement;
  rightCol: (isEditing?: boolean) => React.ReactElement;
  onResetClick?: () => void;
  onPrintClick?: () => Promise<void>;
}

interface HeaderProps {
  title: string;
  tooltip?: string;
  buttons?: () => React.ReactElement;
}

interface LeftColumnButtonsProps {
  editing: boolean;
  expanded: boolean;
  isExpandable?: boolean;
  onEditClick: () => void;
  onExpandClick: () => void;
  onPrintClick?: () => Promise<void>;
  onResetClick?: () => void;
}

interface RightColumnButtonsProps {
  onCollapseClick: () => void;
}

const BoxHeader = ({ title, tooltip, buttons = () => <></> }: HeaderProps) => {
  return (
    <BoxTitle>
      <Typography
        variant="h2"
        style={{ display: 'flex', alignItems: 'center' }}
      >
        {title}
        {tooltip && (
          <Tooltip title={tooltip}>
            <InfoIcon />
          </Tooltip>
        )}
      </Typography>
      <BoxTitleActionButtons>{buttons()}</BoxTitleActionButtons>
    </BoxTitle>
  );
};

const LeftColumnButtons = ({
  editing,
  onEditClick,
  expanded,
  isExpandable,
  onExpandClick,
  onPrintClick = async () => {},
  onResetClick = () => {},
}: LeftColumnButtonsProps) => {
  const [printLoading, setPrintLoading] = useState(false);

  const handlePrintClick = async () => {
    setPrintLoading(true);
    await onPrintClick();
    setPrintLoading(false);
  };

  return (
    <>
      <EditingIconButton value={editing} onClick={onEditClick} />
      <IconButton onClick={onResetClick}>
        <ResetIcon />
      </IconButton>
      <IconButton onClick={handlePrintClick} disabled={printLoading}>
        <PrintIcon />
      </IconButton>
      {!expanded && isExpandable && (
        <IconButton onClick={onExpandClick}>
          <ChevronRight />
        </IconButton>
      )}
    </>
  );
};

const RightColumnButtons = ({ onCollapseClick }: RightColumnButtonsProps) => {
  return (
    <IconButton onClick={onCollapseClick}>
      <ChevronLeft />
    </IconButton>
  );
};

const ExpandableTwoColPanel = ({
  leftCol,
  rightCol,
  leftColTitle,
  rightColTitle,
  leftTooltip,
  rightTooltip,
  isExpandable = true,
  onResetClick = () => {},
  onPrintClick = async () => {},
}: ExpandableTwoColPanelProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [open, setOpen] = useState(false);

  const { formatMessage } = useIntl();

  return (
    <PageContent>
      <ResetContentDialog
        what={formatMessage({ id: `tax.particularSalaryTax.title` })}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        onConfirm={() => {
          setOpen(false);
          onResetClick();
        }}
      />

      <Box maxWidth={650} minWidth={650}>
        <Paper>
          <BoxHeader
            title={leftColTitle}
            tooltip={leftTooltip}
            buttons={() => (
              <LeftColumnButtons
                isExpandable={isExpandable}
                editing={isEditing}
                expanded={isExpanded}
                onEditClick={() => setIsEditing(!isEditing)}
                onExpandClick={() => setIsExpanded(true)}
                onPrintClick={onPrintClick}
                onResetClick={() => setOpen(true)}
              />
            )}
          />
          <BoxContent>{leftCol(isEditing)}</BoxContent>
        </Paper>
      </Box>

      <RightBox
        maxWidth={650}
        minWidth={650}
        className={isExpanded && isExpandable ? 'shown' : ''}
      >
        <Paper>
          <BoxHeader
            title={rightColTitle}
            tooltip={rightTooltip}
            buttons={() => (
              <RightColumnButtons
                onCollapseClick={() => setIsExpanded(false)}
              />
            )}
          />
          <BoxContent>{rightCol(isEditing)}</BoxContent>
        </Paper>
      </RightBox>
    </PageContent>
  );
};

export default ExpandableTwoColPanel;

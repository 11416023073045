import { AgoyDocumentStructure } from '../document';
import updateDocument from './helpers/updateDocument';
import { OperationResult, State } from './types';

const updateFieldActive = <T extends AgoyDocumentStructure>(
  structure: T,
  state: State<T>,
  id: string,
  value: boolean
): OperationResult<T> => {
  return updateDocument(structure, state, id, {
    field: (key, fieldId, props) => {
      return {
        ...props,
        node: {
          ...props.node,
          active: value,
          original: props.node.original || { ...props.node },
        },
        changes: { ...props.node, active: value },
      };
    },
  });
};

export default updateFieldActive;

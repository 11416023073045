import React, { useMemo, useContext } from 'react';
import { useIntl } from 'react-intl';
import { ccyFormat } from '@agoy/common';
import { findAccrualFundValue, findYearEndValue } from './helpers';
import TaxTableRow, { TaxTableRowProps } from './TaxTableRow';
import TaxesDataContext from '../../../service/TaxesDataContext';

const MaxPossibleDepositionToAccrualFundLabelRow = (
  props: TaxTableRowProps
): JSX.Element => {
  const { formatMessage } = useIntl();
  const {
    taxYearData: { state },
  } = useContext(TaxesDataContext);

  // The variable names represent cells in Agoy-excel
  const U22 =
    findAccrualFundValue(
      state,
      'furtherPossibleDepositionToAccrualFundWithChosen'
    ) || 0;
  const U20 =
    findAccrualFundValue(state, 'furtherPossibleDepositionToAccrualFund') || 0;
  const D26 = findYearEndValue(state, 'chosenDepositionToAccrualFund') || 0;
  const U14 = findAccrualFundValue(state, 'maxPossibleDepositionToAccrualFund');
  let label = '';
  let error;

  if (Math.abs(U22) <= 1 || Math.abs(U20) <= 1) {
    label = formatMessage({
      id: 'tax.maxPossibleDepositionToAccrualFundLabel.noFurther',
    });
  } else if (U20 > 0 || U22 > 0) {
    error = formatMessage(
      { id: 'tax.maxPossibleDepositionToAccrualFundLabel.error.tooMuch' },
      { value: ccyFormat(U20 > 0 ? U20 : U22) }
    );
  } else if (U22 < 0) {
    label = formatMessage(
      { id: 'tax.maxPossibleDepositionToAccrualFundLabel.further.label' },
      { value: ccyFormat(U20 - D26) }
    );
  } else {
    label = formatMessage(
      { id: 'tax.maxPossibleDepositionToAccrualFundLabel.label' },
      { value: ccyFormat(U14) }
    );
  }
  const modifiedRow = useMemo(() => {
    return {
      ...props.row,
      label,
    };
  }, [props.row, label]);

  return (
    <TaxTableRow
      {...props}
      row={modifiedRow}
      errorLabel={error}
      onChangeLabel={undefined}
    />
  );
};

export default MaxPossibleDepositionToAccrualFundLabelRow;

import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import styled from '@emotion/styled';
import { Card, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';

import { normalizeOrganisationNumber, Awaited } from '@agoy/common';
import {
  asResultClass,
  ApiReturnType,
  useApiSdk,
  getApiSdk,
  isApiErrorType,
} from 'api-sdk';
import {
  setOrganisation,
  setUserFirstLogin,
  addGlobalErrorMessage,
} from 'redux/actions';
import { useSelector } from 'redux/reducers';
import { authContext } from '_authentication/HOC/withAuth';
import { usePaymentStatus } from '_payment/payment-status/usePaymentStatus';
import CreateOrganisationForm from './CreateOrganisationForm';
import SelectOrganizationForm from './SelectOrganizationForm';

type Sdk = Awaited<ReturnType<typeof getApiSdk>>;
type CreateOrganisationParams = Awaited<
  Parameters<Sdk['createOrganisation']>
>[0]['requestBody'];

const Container = styled.div`
  padding: ${(props) => props.theme.spacing(2)}px
    ${(props) => props.theme.spacing(3)}px;
  width: 100%;
  height: fit-content;
  max-width: 600px;
`;

const Page = styled.div`
  display: flex;
  justify-content: center;
  overflow-y: auto;
`;

const CardContainer = styled(Card)`
  margin-top: ${(props) => props.theme.spacing(1)}px;
  padding: ${(props) => props.theme.spacing(2)}px
    ${(props) => props.theme.spacing(3)}px;
`;

const CreateOrganisationContainer = (): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const intl = useIntl();
  const sdk = useApiSdk();
  const paymentStatusHook = usePaymentStatus();

  const organization = useSelector((state) => state.user.organisationId);
  const { refreshSessionAWS } = useContext(authContext);

  const [currentTab, setCurrentTab] = useState<'join' | 'create'>('join');
  const [invites, setInvites] = useState<ApiReturnType<'getInvites'>>([]);
  const [creatingOrganisation, setCreatingOrganisation] = useState(false);
  const [fetchingInvites, setFetchingInvites] = useState(false);

  const handleTabChange = (event, value) => {
    setCurrentTab(value);
  };

  useEffect(() => {
    if (organization) {
      paymentStatusHook.reloadPaymentStatus().then(() => history.push('/'));
    }
  }, [history, organization]);

  useEffect(() => {
    (async () => {
      setFetchingInvites(true);
      const invitesResult = await asResultClass(sdk.getInvites());

      if (invitesResult.ok) {
        setInvites(invitesResult.val);
        if (invitesResult.val.length) {
          setCurrentTab('join');
        } else {
          setCurrentTab('create');
        }
      }
      setFetchingInvites(false);
    })();
  }, [sdk]);

  const acceptInvite = async (selectedInvite) => {
    if (!selectedInvite) return;

    const result = await asResultClass(
      sdk.acceptOrganisationInvite({ inviteId: selectedInvite })
    );

    if (result.ok) {
      dispatch(setOrganisation(result.val));
      await refreshSessionAWS();
      // Action to display the onboarding tour2 in CustomersView
      dispatch(setUserFirstLogin());
    }
  };

  const createOrganisation = async (values: CreateOrganisationParams) => {
    setCreatingOrganisation(true);

    const result = await asResultClass(
      sdk.createOrganisation({
        requestBody: {
          ...values,
          orgNumber: normalizeOrganisationNumber(values.orgNumber, true),
        },
      })
    );

    if (result.ok) {
      dispatch(setOrganisation(result.val));
      await refreshSessionAWS();
      // Action to display the onboarding tour1 in CustomersView
      dispatch(setUserFirstLogin());
    } else if (isApiErrorType(result.val) && result.val.status === 409) {
      dispatch(addGlobalErrorMessage('company.create.orgNumber.error'));
    } else if (!isApiErrorType(result.val) || !result.val.handled) {
      dispatch(addGlobalErrorMessage('error'));
    }

    setCreatingOrganisation(false);
  };

  return (
    <Page>
      <Container>
        <RadioGroup
          aria-label={intl.formatMessage({ id: 'company.create.or.join' })}
          value={currentTab}
          onChange={handleTabChange}
          row
        >
          <FormControlLabel
            value="join"
            control={<Radio />}
            label={intl.formatMessage({ id: 'company.join.org' })}
          />
          <FormControlLabel
            value="create"
            control={<Radio />}
            label={intl.formatMessage({ id: 'company.create.org' })}
          />
        </RadioGroup>

        <CardContainer>
          {currentTab === 'join' && (
            <SelectOrganizationForm
              invites={invites}
              onAcceptInvite={acceptInvite}
              loading={fetchingInvites}
            />
          )}

          {currentTab === 'create' && (
            <CreateOrganisationForm
              onCreateOrganization={createOrganisation}
              loading={creatingOrganisation}
            />
          )}
        </CardContainer>
      </Container>
    </Page>
  );
};

export default CreateOrganisationContainer;

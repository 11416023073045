import React, { useCallback, useContext } from 'react';
import { Tooltip } from '@material-ui/core';
import styled from '@emotion/styled';
import DocumentViewServiceContext from '_shared/services/document/DocumentViewServiceContext';
import CurrencyField from '_shared/components/Inputs/CurrencyField';
import { GetComponentProps } from '_shared/services/type-helpers';
import StringInput from './StringInput';

interface NumberFieldProps {
  className?: string;
  id: string;
  value: number | undefined;
  warning?: string;
}

const NumberInput = styled(StringInput)<{ error?: boolean }>`
  text-align: right;

  ${({ error, theme }) =>
    error
      ? `border-color: ${theme.palette.error.main};
    :hover {
    border-color: ${theme.palette.error.main};
  };`
      : ''}
`;

const NumberField = ({
  id,
  value,
  className,
  warning,
  ...props
}: NumberFieldProps & GetComponentProps<typeof CurrencyField>): JSX.Element => {
  const service = useContext(DocumentViewServiceContext);
  const onChange = useCallback(
    (newValue: number | undefined) => {
      if (newValue !== value)
        if (id.split('.').length > 3) {
          service.updateCellValue(id, newValue, { keepOriginal: true });
        } else {
          service.updateField(id, newValue);
        }
    },
    [service, value, id]
  );

  return warning ? (
    <Tooltip title={warning} placement="bottom">
      <div>
        <CurrencyField
          className={className}
          {...props}
          value={value}
          onValueChange={onChange}
          Input={NumberInput}
          warning={warning}
        />
      </div>
    </Tooltip>
  ) : (
    <CurrencyField
      className={className}
      {...props}
      value={value}
      onValueChange={onChange}
      Input={NumberInput}
    />
  );
};

export default NumberField;

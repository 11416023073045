import {
  ReferenceCell,
  abs,
  account,
  id,
  ifOrElse,
  multiply,
  or,
  ref,
  sum,
} from '@agoy/document';

/**
 * Returns the sum of all UBs (outgoing balances) for the specified
 * accounts or range(s) of accounts for the client's currently selected accounting year.
 * @param accounts: string with account(s)
 * @param areAccountsOver2000: false if the accounts used go until 1999.
 * @optionalParam periodName: period name to be used when resolving references.
 * The default is the current financial year.
 */
export const sumAccountsUBs = (
  accounts: string,
  areAccountsOver2000: boolean,
  periodName?: string
) =>
  or(
    sum(
      areAccountsOver2000
        ? multiply(-1, account(accounts, 'ub', periodName))
        : account(accounts, 'ub', periodName),
      0
    ),
    0
  );

/**
 * Returns the sum for a specified NoteNumber for the rowIds specified
 * in the current year or previous year.
 * @param NoteNumber: Ex: 1 = Redovisningsprinciper
 * @param rowIds: array of row Ids in a note.
 * @param endId: string that represents a column's id.
 * @param table = 'table' is default. This is the usually the case when there's only one table.
 */
export const sumNotesRowsById = (
  NoteNumber: number,
  rowIds: (string | number)[],
  endId:
    | 'year'
    | 'previousYear'
    | 'reportedValue'
    | 'taxValue'
    | 'temporaryDifference',
  table = 'table'
): ReferenceCell =>
  ref(
    ifOrElse(
      sum(
        ...[...rowIds].map((idNumber) => {
          return abs(
            or(id(`notes.note${NoteNumber}.${table}.${idNumber}.${endId}`), 0)
          );
        })
      ),
      sum(
        ...[...rowIds].map((idNumber) => {
          return or(
            id(`notes.note${NoteNumber}.${table}.${idNumber}.${endId}`),
            0
          );
        })
      ),
      undefined
    )
  );

/**
 * Returns the sum of all negative (Credit) transactions for the specified
 * accounts or range(s) of accounts for the client's currently selected accounting year.
 * Should not include inverse transactions in the same date for an account.
 * Example: ref(sumNegativeTransactions('1220:1227+1230:1237'))
 * @param accounts: string with account(s).
 * @optionalParam periodName: period name to be used when resolving references.
 * The default is the current financial year.
 */
export const sumNegativeTransactions = (
  accounts: string,
  periodName?: string
) => or(sum(account(accounts, 'creditWithoutInverse', periodName), 0), 0);

/**
 * Returns the sum of all positive (Debit) transactions for the specified
 * accounts or range(s) of accounts for the client's currently selected accounting year.
 * Should not include inverse transactions in the same date for an account.
 *  Example: ref(sumPositiveTransactions('1220:1227+1230:1237'))
 * @param accounts: string with account(s).
 * @optionalParam periodName: period name to be used when resolving references.
 * The default is the current financial year.
 */
export const sumPositiveTransactions = (
  accounts: string,
  periodName?: string
) => or(sum(account(accounts, 'debitWithoutInverse', periodName), 0), 0);

/**
 * Returns the sum of all IBs (incoming balances) for the specified
 * accounts or range(s) of accounts for the client accounting period(s).
 * Supports getting IBs from any period
 *
 * Example: ref(sumPositiveTransactions('1220:1227+1230:1237'))
 * @param accounts: string with account(s).
 * @param areAccountsOver2000: false if the accounts used go until 1999.
 * @optionalParam periodName: period name to be used when resolving references.
 * The default is the current financial year.
 */
export const sumAccountsIBs = (
  accounts: string,
  areAccountsOver2000: boolean,
  periodName?: string
) =>
  or(
    sum(
      areAccountsOver2000
        ? multiply(-1, account(accounts, 'ib', periodName))
        : account(accounts, 'ib', periodName),
      0
    ),
    0
  );

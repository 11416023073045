import React from 'react';
import { Box } from '@material-ui/core';
import {
  CommonNotes,
  NoteThreeCommentTwoTableSection,
} from '@agoy/annual-report-document';
import { Field, numberValue, stringValue } from '@agoy/document';
import Comment from '../Comment';
import Header from '../Header';
import { Note } from '../Note';
import NoteTable from '../NoteTable';
import FieldSection from '../FieldSection';

type DoubleThreeColumnNoteProps = {
  noteId: string;
  notes: CommonNotes;
  title: string;
  secondTableTitle?: Field;
  firstTableCommentLabel: Field;
  secondTableCommentLabel: Field;
  commentLabel: Field;
  hasPreviousYear?: boolean;
} & NoteThreeCommentTwoTableSection;

const ThreeCommentDoubleThreeColumnNote = ({
  noteId,
  notes,
  active,
  number,
  table,
  secondTable,
  secondTableTitle,
  firstTableComment,
  secondTableComment,
  comment,
  title,
  firstTableCommentLabel,
  secondTableCommentLabel,
  commentLabel,
  hasPreviousYear = false,
}: DoubleThreeColumnNoteProps): JSX.Element | null => {
  if (!active) return null;

  return (
    <Note id={noteId}>
      <Header noteId={noteId} noteNumber={numberValue(number)} title={title} />

      <Box mt={1} mb={1}>
        <NoteTable
          hasPreviousYear={hasPreviousYear}
          table={table}
          tableId={`notes.${noteId}.table`}
        />
      </Box>

      {/* Comment for first table */}
      <Box mt={4} mb={4}>
        <Comment
          labelValue={stringValue(firstTableCommentLabel) || ''}
          field={firstTableComment}
          value={stringValue(firstTableComment) || ''}
        />
      </Box>

      {'note401' in notes && (
        <FieldSection value={stringValue(secondTableTitle) || ''} />
      )}

      <Box mt={1} mb={1}>
        <NoteTable
          hasPreviousYear={hasPreviousYear}
          table={secondTable}
          tableId={`notes.${noteId}.secondTable`}
        />
      </Box>

      {/* Comment for second table */}
      <Comment
        labelValue={stringValue(secondTableCommentLabel) || ''}
        field={secondTableComment}
        value={stringValue(secondTableComment) || ''}
      />

      {/* Comment */}
      <Comment
        labelValue={stringValue(commentLabel) || ''}
        field={comment}
        value={stringValue(comment) || ''}
      />
    </Note>
  );
};

export default ThreeCommentDoubleThreeColumnNote;

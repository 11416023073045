import { Field } from '@agoy/document';
import { AnnualReportPart, AnnualReportSection } from '../../document';
import { field } from '../utils/util';
import { IxbrlField } from './ixbrl';

export interface ConfirmationCertificateSection extends AnnualReportSection {
  heading: Field;
  clientNameText: Field;
  organisationNumberText: Field;
  location: Field;
  date: Field;
  statement: Field;
  statementOptionId: Field;
  signatureClarification: IxbrlField;
  // ds = digital signage used, specific fields used when digital submission is used
  // covered by the statement when doing manual submission
  dsSignatureClarificationSurname: IxbrlField;
  dsIncomeStatementBalanceSheet: IxbrlField;
  dsBoardApproval: IxbrlField;
  dsBoardDisapproval: IxbrlField;
  dsDispositionDecision: IxbrlField; // used when board rejects the disposition
  dsOriginalsMatchVerificaton: IxbrlField;
  dsRole: IxbrlField;
}

// Noter
export interface ConfirmationCertificate extends AnnualReportPart {
  section: ConfirmationCertificateSection;
}

const cell = { type: 'field' as const };
const boolean = { type: 'boolean' as const };

export const confirmationCertificate = {
  type: 'part' as const,
  children: {
    active: boolean,
    section: {
      type: 'part' as const,
      children: {
        active: boolean,
        heading: cell,
        clientNameText: cell,
        organisationNumberText: cell,
        date: cell,
        location: cell,
        statement: cell,
        statementOptionId: cell,
        signatureClarification: cell,
        dsSignatureClarificationSurname: cell,
        dsIncomeStatementBalanceSheet: cell,
        dsBoardApproval: cell,
        dsBoardDisapproval: cell,
        dsDispositionDecision: cell,
        dsOriginalsMatchVerificaton: cell,
        dsRole: cell,
      },
    },
  },
};

export const confirmationCertificateConfig = (): ConfirmationCertificate => ({
  active: undefined,
  section: {
    active: undefined,
    heading: field(''),
    clientNameText: field(''),
    organisationNumberText: field(''),
    date: field(''),
    location: field(''),
    statement: field(''),
    statementOptionId: field(''),
    signatureClarification: field(''),
    dsSignatureClarificationSurname: field(''),
    dsIncomeStatementBalanceSheet: field(''),
    dsBoardApproval: field(''),
    dsBoardDisapproval: field(''),
    dsDispositionDecision: field(''),
    dsOriginalsMatchVerificaton: field(''),
    dsRole: field(''),
  },
});

export const confirmationCertificateReferences = (): Record<
  string,
  string
> => ({});

import React, { useMemo } from 'react';
import { TextField, Typography, TextFieldProps } from '@material-ui/core';
import styled from '@emotion/styled';
import EditField from '_shared/components/Inputs/EditField';

const Textarea = ({ disableUnderline, ...props }) => (
  <TextField fullWidth multiline variant="outlined" {...props} minRows={10} />
);

const Block = styled(Typography)`
  margin-bottom: 0.875rem;
  max-width: 800px;
`;

const StyledEditField = styled(EditField)`
  max-width: 800px;
`;

interface MultilineFieldProps {
  value: string;
  onChange: (value) => void;
  print: boolean;
  disabled?: boolean;
  active?: boolean;
  header?: React.ReactElement;
  placeholder?: string;
  inputProps?: TextFieldProps;
}

const MultilineField = ({
  value,
  onChange,
  print,
  disabled,
  active,
  header,
  placeholder,
  inputProps,
}: MultilineFieldProps): JSX.Element | null => {
  const splittedForPrint = useMemo(
    () => (print ? value.split(/\r?\n\s*\r?\n/g) : []),
    [print, value]
  );

  if (print) {
    return active === false || !value ? null : (
      <>
        {header}
        {splittedForPrint.map((part, index) => (
          <Block key={index}>{part}</Block>
        ))}
      </>
    );
  }

  return (
    <>
      {header}
      <StyledEditField
        value={value}
        onChange={onChange}
        component={Textarea}
        disabled={disabled}
        placeholder={placeholder}
        handleEnter={false}
        InputProps={inputProps}
      />
    </>
  );
};

export default MultilineField;

import React from 'react';
import { CommonNotes } from '@agoy/annual-report-document';

import PrintCustomNote from './Customised/PrintCustomNote';
import Note2 from './ByNumber/2nd/Note2';
import Note23 from './ByNumber/23rd/Note23';
import Note28 from './ByNumber/28th/Note28';
import Note75 from './ByNumber/75th/Note75';
import Note77 from './ByNumber/77th/Note77';
import OneTableThreeColumnNote from './UI/single-table/ThreeColumnNote';
import OneTableFourColumnNote from './UI/single-table/FourColumnNote';
import OneTableThreeColumnSumNote from './UI/single-table/ThreeColumnSumNote';
import OneTableFourColumnAddSumNote from './UI/single-table/FourColumnSumNote';
import ThreeColumnFourColumnNote from './UI/two-tables/ThreeColumnFourColumnNote';
import DoubleThreeColumnNote from './UI/two-tables/DoubleThreeColumnNote';
import InputFieldNote from './UI/InputFieldNote';
import CheckboxesNote from './UI/CheckboxesNote';
import ToggleTitle from './UI/ToggleTitle';
import { stringValue } from '@agoy/document';
import ThreeCommentDoubleThreeColumnNote from './UI/two-tables/ThreeCommentDoubleThreeColumnNote';
import Note74 from './ByNumber/74th/Note74';
import Note80 from './ByNumber/80th/Note80';
import ThreeColumnAndTwoColumnWithSumNote from './UI/two-tables/ThreeColumnAndTwoColumnWithSumNote';
import Note84 from './ByNumber/84th/Note84';
import Note93 from './ByNumber/93th/Note93';
import Note95 from './ByNumber/95th/Note95';
import Note96 from './ByNumber/96th/Note96';
import Note98 from './ByNumber/Note98/Note98';
import Note99 from './ByNumber/99th/Note99';
import Note100 from './ByNumber/100/Note100';
import Note101 from './ByNumber/Note101/Note101';

interface otherNotesProps {
  notes: CommonNotes;
  otherNotes: [string, string][];
  hasPreviousYear: boolean;
  isDigitalSubmission: boolean;
  isXBRLRender?: boolean;
}

const OtherNotes = ({
  notes,
  otherNotes,
  hasPreviousYear,
  isDigitalSubmission,
  isXBRLRender,
}: otherNotesProps) => {
  return (
    <ToggleTitle
      id="notesOtherTitle"
      section="headings"
      titleVariant="h2"
      target={notes.headings.notesOtherTitle}
      title={stringValue(notes.headings.notesOtherTitle) || ''}
    >
      {otherNotes.map(([note, _]) => {
        if (note in notes) {
          if (note === 'note2') {
            return (
              <Note2
                key={note}
                {...notes[note]}
                notes={notes}
                hasPreviousYear={hasPreviousYear}
              />
            );
          }
          if (note === 'note7' || note === 'note27') {
            return (
              <OneTableThreeColumnNote
                key={note}
                noteId={note}
                {...notes[note]}
                title={stringValue(notes[note].name) || ''}
                hasPreviousYear={hasPreviousYear}
                commentLabel={notes[note].commentLabel}
              />
            );
          }
          if (note === 'note16' || note === 'note18' || note === 'note20') {
            return (
              <ThreeColumnFourColumnNote
                key={note}
                noteId={note}
                {...notes[note]}
                title={stringValue(notes[note].name) || ''}
                hasPreviousYear={hasPreviousYear}
                commentLabel={notes[note].commentLabel}
              />
            );
          }
          if (
            note === 'note57' ||
            note === 'note64' ||
            note === 'note76' ||
            note === 'note81' ||
            note === 'note91'
          ) {
            return (
              <DoubleThreeColumnNote
                key={note}
                noteId={note}
                {...notes[note]}
                title={stringValue(notes[note].name) || ''}
                hasPreviousYear={hasPreviousYear}
                commentLabel={notes[note].commentLabel}
              />
            );
          }

          if (
            note === 'note35' ||
            note === 'note36' ||
            note === 'note45' ||
            note === 'note47' ||
            note === 'note48' ||
            note === 'note50' ||
            note === 'note51' ||
            note === 'note53' ||
            note === 'note54' ||
            note === 'note60' ||
            note === 'note65' ||
            note === 'note67' ||
            note === 'note69' ||
            note === 'note79'
          ) {
            return (
              <InputFieldNote
                key={note}
                noteId={note}
                {...notes[note]}
                title={stringValue(notes[note].name) || ''}
                commentLabel={notes[note].commentLabel}
                isDigitalSubmission={isDigitalSubmission}
              />
            );
          }
          if (note === 'note44') {
            return (
              <OneTableThreeColumnNote
                key={note}
                noteId={note}
                {...notes[note]}
                title={stringValue(notes[note].name) || ''}
                hasPreviousYear={hasPreviousYear}
                commentLabel={notes[note].commentLabel}
                displayOnlyStringRow
              />
            );
          }
          if (note === 'note68' || note === 'note88') {
            return (
              <OneTableThreeColumnNote
                key={note}
                noteId={note}
                {...notes[note]}
                title={stringValue(notes[note].name) || ''}
                commentLabel={notes[note].commentLabel}
                // all three columns should always be shown regardless of no previousYear
                hasPreviousYear
                displayOnlyStringRow
                isXBRLRender={isXBRLRender}
              />
            );
          }
          if (note === 'note46') {
            return (
              <OneTableThreeColumnNote
                key={note}
                noteId={note}
                {...notes[note]}
                title={stringValue(notes[note].name) || ''}
                hasPreviousYear={hasPreviousYear}
                commentLabel={notes[note].commentLabel}
              />
            );
          }
          if (note === 'note41') {
            return (
              <OneTableFourColumnNote
                key={note}
                noteId={note}
                {...notes[note]}
                title={stringValue(notes[note].name) || ''}
                hasPreviousYear={hasPreviousYear}
                commentLabel={notes[note].commentLabel}
              />
            );
          }
          if (note === 'note23') {
            return (
              <Note23
                key={note}
                {...notes[note]}
                title={stringValue(notes[note].name) || ''}
                hasPreviousYear={hasPreviousYear}
              />
            );
          }
          if (
            note === 'note26' ||
            (!isDigitalSubmission && note === 'note30') ||
            note === 'note31' ||
            note === 'note83' ||
            note === 'note86'
          ) {
            return (
              <OneTableThreeColumnSumNote
                key={note}
                noteId={note}
                {...notes[note]}
                title={stringValue(notes[note].name) || ''}
                hasPreviousYear={hasPreviousYear}
                commentLabel={notes[note].commentLabel}
              />
            );
          }

          if (isDigitalSubmission && note === 'note58') {
            return (
              <OneTableThreeColumnNote
                key={note}
                noteId={note}
                {...notes[note]}
                title={stringValue(notes[note].name) || ''}
                hasPreviousYear={hasPreviousYear}
                commentLabel={notes[note].commentLabel}
              />
            );
          }
          if (note === 'note28') {
            return (
              <Note28
                key={note}
                {...notes[note]}
                title={stringValue(notes[note].name) || ''}
                hasPreviousYear={hasPreviousYear}
                commentLabel={notes[note].commentLabel}
              />
            );
          }
          if (note === 'note29' || note === 'note32') {
            return (
              <OneTableFourColumnAddSumNote
                key={note}
                noteId={note}
                {...notes[note]}
                title={stringValue(notes[note].name) || ''}
                hasPreviousYear={hasPreviousYear}
                commentLabel={notes[note].commentLabel}
              />
            );
          }

          if (note === 'note33') {
            return (
              <OneTableThreeColumnSumNote
                key={note}
                noteId={note}
                {...notes[note]}
                title={stringValue(notes[note].name) || ''}
                commentLabel={notes[note].commentLabel}
                hasPreviousYear={hasPreviousYear}
                rowsInGroupsOf={4}
              />
            );
          }

          if (
            note === 'note61' ||
            note === 'note62' ||
            note === 'note63' ||
            note === 'note66' ||
            note === 'note86'
          ) {
            return (
              <OneTableThreeColumnSumNote
                key={note}
                noteId={note}
                {...notes[note]}
                title={stringValue(notes[note].name) || ''}
                commentLabel={notes[note].commentLabel}
              />
            );
          }
          if (
            note === 'note34' ||
            note === 'note59' ||
            note === 'note85' ||
            note === 'note89' ||
            note === 'note90' ||
            note === 'note92' ||
            note === 'note94'
          ) {
            return (
              <OneTableThreeColumnNote
                key={note}
                noteId={note}
                {...notes[note]}
                title={stringValue(notes[note].name) || ''}
                hasPreviousYear={hasPreviousYear}
                commentLabel={notes[note].commentLabel}
              />
            );
          }
          if (note === 'note39' || note === 'note38' || note === 'note37') {
            return (
              <CheckboxesNote
                key={note}
                noteId={note}
                {...notes[note]}
                title={stringValue(notes[note].name) || ''}
                commentLabel={notes[note].commentLabel}
              />
            );
          }
          if (note === 'note70' || note === 'note401') {
            return (
              <ThreeCommentDoubleThreeColumnNote
                key={note}
                noteId={note}
                {...notes[note]}
                title={stringValue(notes[note].name) || ''}
                hasPreviousYear={hasPreviousYear}
                firstTableCommentLabel={notes[note].firstTableCommentLabel}
                secondTableCommentLabel={notes[note].secondTableCommentLabel}
                commentLabel={notes[note].commentLabel}
              />
            );
          }
          if (note === 'note71' || note === 'note97') {
            return (
              <OneTableThreeColumnNote
                key={note}
                noteId={note}
                {...notes[note]}
                title={stringValue(notes[note].name) || ''}
                hasPreviousYear={hasPreviousYear}
                commentLabel={notes[note].commentLabel}
              />
            );
          }
          if (note === 'note72' || note === 'note82') {
            return (
              <ThreeColumnAndTwoColumnWithSumNote
                key={note}
                noteId={note}
                {...notes[note]}
                title={stringValue(notes[note].name) || ''}
                hasPreviousYear={hasPreviousYear}
                commentLabel={notes[note].commentLabel}
                isDigitalSubmission={isDigitalSubmission}
              />
            );
          }
          if (note === 'note73') {
            return (
              <OneTableThreeColumnNote
                key={note}
                noteId={note}
                {...notes[note]}
                // Aggregates rows in groups of up to 17 rows
                rowsInGroupsOf={17}
                hasPreviousYear={hasPreviousYear}
                title={stringValue(notes[note].name) || ''}
                commentLabel={notes[note].commentLabel}
                isDigitalSubmission={isDigitalSubmission}
                displayUnfiltered={true}
              />
            );
          }

          if (note === 'note74') {
            return (
              <Note74
                key={note}
                {...notes[note]}
                hasPreviousYear
                noteId={note}
                isDigitalSubmission={isDigitalSubmission}
                title={stringValue(notes[note].name) || ''}
              />
            );
          }

          if (note === 'note75') {
            return (
              <Note75
                key={note}
                noteId={note}
                {...notes[note]}
                title={stringValue(notes[note].name) || ''}
                hasPreviousYear={hasPreviousYear}
                commentLabel={notes[note].commentLabel}
              />
            );
          }

          if (note === 'note84') {
            return (
              <Note84
                key={note}
                noteId={note}
                {...notes[note]}
                hasPreviousYear={hasPreviousYear}
              />
            );
          }

          if (note === 'note93') {
            return (
              <Note93
                key={note}
                noteId={note}
                {...notes[note]}
                title={stringValue(notes[note].name) || ''}
                hasPreviousYear={hasPreviousYear}
              />
            );
          }

          if (note === 'note80') {
            return (
              <Note80
                {...notes[note]}
                title={stringValue(notes[note].name) || ''}
                hasPreviousYear={hasPreviousYear}
                commentLabel={notes[note].commentLabel}
                isDigitalSubmission={isDigitalSubmission}
              />
            );
          }

          if (note === 'note77') {
            return (
              <Note77
                key={note}
                noteId={note}
                {...notes[note]}
                title={stringValue(notes[note].name) || ''}
                hasPreviousYear={hasPreviousYear}
              />
            );
          }

          if (note === 'note78') {
            return (
              <OneTableThreeColumnNote
                key={note}
                noteId={note}
                {...notes[note]}
                title={stringValue(notes[note].name) || ''}
                hasPreviousYear={hasPreviousYear}
                commentLabel={notes[note].commentLabel}
                displayUnfiltered
              />
            );
          }

          if (note === 'note95') {
            return (
              <Note95
                key={note}
                noteId={note}
                {...notes[note]}
                title={stringValue(notes[note].name) || ''}
                hasPreviousYear={hasPreviousYear}
                commentLabel={notes[note].commentLabel}
              />
            );
          }
          if (note === 'note96') {
            return (
              <Note96
                key={note}
                noteId={note}
                {...notes[note]}
                title={stringValue(notes[note].name) || ''}
                hasPreviousYear={hasPreviousYear}
                commentLabel={notes[note].commentLabel}
              />
            );
          }
          if (note === 'note98') {
            return (
              <Note98
                key={note}
                noteId={note}
                {...notes[note]}
                title={stringValue(notes[note].name) || ''}
                hasPreviousYear={hasPreviousYear}
                commentLabel={notes[note].commentLabel}
              />
            );
          }
          if (note === 'note99') {
            return (
              <Note99
                key={note}
                noteId={note}
                {...notes[note]}
                title={stringValue(notes[note].name) || ''}
                hasPreviousYear={hasPreviousYear}
                commentLabel={notes[note].commentLabel}
                isXBRLRender={isXBRLRender}
              />
            );
          }
          if (note === 'note100') {
            return (
              <Note100
                key={note}
                noteId={note}
                {...notes[note]}
                title={stringValue(notes[note].name) || ''}
                hasPreviousYear={hasPreviousYear}
                commentLabel={notes[note].commentLabel}
              />
            );
          }

          if (note === 'note101') {
            return (
              <Note101
                key={note}
                noteId={note}
                {...notes[note]}
                title={stringValue(notes[note].name) || ''}
                hasPreviousYear={hasPreviousYear}
                commentLabel={notes[note].commentLabel}
                isXBRLRender={isXBRLRender}
              />
            );
          }

          // This note is rendered above so should not be rendered here
          if (note === 'note56') {
            return false;
          }

          return (
            <OneTableThreeColumnNote
              key={note}
              noteId={note}
              {...notes[note]}
              title={stringValue(notes[note].name) || ''}
              hasPreviousYear={hasPreviousYear}
              commentLabel={notes[note].commentLabel}
            />
          );
        }
        if (note?.includes('custom')) {
          const [_, customNoteNumber] = note.split('-');
          return (
            <PrintCustomNote
              key={customNoteNumber}
              noteId={note}
              note={notes.custom.sections[customNoteNumber]}
              hasPreviousYear={hasPreviousYear}
            />
          );
        }
      })}
    </ToggleTitle>
  );
};

export default OtherNotes;

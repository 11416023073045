import React from 'react';
import { CommonNotes } from '@agoy/annual-report-document';

import PrintCustomNote from './Customised/PrintCustomNote';
import Note2 from './ByNumber/2nd/Note2';
import OneTableThreeColumnNote from './UI/single-table/ThreeColumnNote';
import OneTableThreeColumnSumNote from './UI/single-table/ThreeColumnSumNote';
import DoubleThreeColumnNote from './UI/two-tables/DoubleThreeColumnNote';
import InputFieldNote from './UI/InputFieldNote';
import CheckboxesNote from './UI/CheckboxesNote';
import ToggleTitle from './UI/ToggleTitle';
import { stringValue } from '@agoy/document';
import Note74 from './ByNumber/74th/Note74';
import Note80 from './ByNumber/80th/Note80';
import Note84 from './ByNumber/84th/Note84';
import Note93 from './ByNumber/93th/Note93';
import Note101 from './ByNumber/Note101/Note101';

interface IncomeStatementNotesProps {
  notes: CommonNotes;
  incomeStatementNotes: [string, string][];
  hasPreviousYear: boolean;
  isDigitalSubmission: boolean;
  isXBRLRender?: boolean;
}

const IncomeStatementNotes = ({
  notes,
  incomeStatementNotes,
  hasPreviousYear,
  isDigitalSubmission,
  isXBRLRender = false,
}: IncomeStatementNotesProps) => {
  return (
    <ToggleTitle
      id="notesIncomeStatementTitle"
      section="headings"
      titleVariant="h2"
      target={notes.headings.notesIncomeStatementTitle}
      title={stringValue(notes.headings.notesIncomeStatementTitle) || ''}
    >
      {incomeStatementNotes.map(([note, _]) => {
        if (note in notes) {
          if (note === 'note2') {
            return (
              <Note2
                key={note}
                {...notes[note]}
                notes={notes}
                hasPreviousYear={hasPreviousYear}
              />
            );
          }
          if (
            note === 'note45' ||
            note === 'note48' ||
            note === 'note53' ||
            note === 'note54' ||
            note === 'note60' ||
            note === 'note79'
          ) {
            return (
              <InputFieldNote
                key={note}
                noteId={note}
                {...notes[note]}
                title={stringValue(notes[note].name) || ''}
                commentLabel={notes[note].commentLabel}
                isDigitalSubmission={isDigitalSubmission}
              />
            );
          }
          if (
            note === 'note35' ||
            note === 'note36' ||
            note === 'note50' ||
            note === 'note51' ||
            note === 'note67'
          ) {
            return (
              <InputFieldNote
                key={note}
                noteId={note}
                {...notes[note]}
                title={stringValue(notes[note].name) || ''}
                commentLabel={notes[note].commentLabel}
                isDigitalSubmission={isDigitalSubmission}
              />
            );
          }
          if (note === 'note33') {
            return (
              <OneTableThreeColumnSumNote
                key={note}
                noteId={note}
                {...notes[note]}
                title={stringValue(notes[note].name) || ''}
                commentLabel={notes[note].commentLabel}
                hasPreviousYear={hasPreviousYear}
                rowsInGroupsOf={4}
              />
            );
          }
          if (
            note === 'note61' ||
            note === 'note62' ||
            note === 'note63' ||
            note === 'note86'
          ) {
            return (
              <OneTableThreeColumnSumNote
                key={note}
                noteId={note}
                {...notes[note]}
                title={stringValue(notes[note].name) || ''}
                commentLabel={notes[note].commentLabel}
              />
            );
          }
          if (
            note === 'note34' ||
            note === 'note59' ||
            note === 'note85' ||
            note === 'note89' ||
            note === 'note90' ||
            note === 'note92' ||
            note === 'note94'
          ) {
            return (
              <OneTableThreeColumnNote
                key={note}
                noteId={note}
                {...notes[note]}
                title={stringValue(notes[note].name) || ''}
                hasPreviousYear={hasPreviousYear}
                commentLabel={notes[note].commentLabel}
              />
            );
          }
          if (note === 'note68' || note === 'note88') {
            return (
              <OneTableThreeColumnNote
                key={note}
                noteId={note}
                {...notes[note]}
                title={stringValue(notes[note].name) || ''}
                commentLabel={notes[note].commentLabel}
                // all three columns should always be shown regardless of no previousYear
                hasPreviousYear
                displayOnlyStringRow
                isXBRLRender={isXBRLRender}
              />
            );
          }
          if (note === 'note39' || note === 'note38' || note === 'note37') {
            return (
              <CheckboxesNote
                key={note}
                noteId={note}
                {...notes[note]}
                title={stringValue(notes[note].name) || ''}
                commentLabel={notes[note].commentLabel}
              />
            );
          }
          if (note === 'note64' || note === 'note81' || note === 'note91') {
            return (
              <DoubleThreeColumnNote
                key={note}
                noteId={note}
                {...notes[note]}
                title={stringValue(notes[note].name) || ''}
                hasPreviousYear={hasPreviousYear}
                commentLabel={notes[note].commentLabel}
              />
            );
          }
          if (note === 'note74') {
            return (
              <Note74
                key={note}
                {...notes[note]}
                hasPreviousYear
                noteId={note}
                isDigitalSubmission={isDigitalSubmission}
                title={stringValue(notes[note].name) || ''}
              />
            );
          }

          if (note === 'note80') {
            return (
              <Note80
                {...notes[note]}
                title={stringValue(notes[note].name) || ''}
                hasPreviousYear={hasPreviousYear}
                commentLabel={notes[note].commentLabel}
                isDigitalSubmission={isDigitalSubmission}
              />
            );
          }

          if (note === 'note84') {
            return (
              <Note84
                key={note}
                noteId={note}
                {...notes[note]}
                hasPreviousYear={hasPreviousYear}
              />
            );
          }

          if (note === 'note93') {
            return (
              <Note93
                key={note}
                noteId={note}
                {...notes[note]}
                title={stringValue(notes[note].name) || ''}
                hasPreviousYear={hasPreviousYear}
              />
            );
          }

          if (note === 'note101') {
            return (
              <Note101
                key={note}
                noteId={note}
                {...notes[note]}
                title={stringValue(notes[note].name) || ''}
                hasPreviousYear={hasPreviousYear}
                commentLabel={notes[note].commentLabel}
                isXBRLRender={isXBRLRender}
              />
            );
          }

          return (
            <OneTableThreeColumnNote
              key={note}
              noteId={note}
              {...notes[note]}
              title={stringValue(notes[note].name) || ''}
              hasPreviousYear={hasPreviousYear}
              commentLabel={notes[note].commentLabel}
            />
          );
        }
        if (note?.includes('custom')) {
          const [_, customNoteNumber] = note.split('-');
          return (
            <PrintCustomNote
              key={customNoteNumber}
              noteId={note}
              note={notes.custom.sections[customNoteNumber]}
            />
          );
        }
      })}
    </ToggleTitle>
  );
};

export default IncomeStatementNotes;

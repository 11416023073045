import { reformat } from '@agoy/dates';

const formatFinancialYear = (financialYear: string) => {
  if (!financialYear) return undefined;
  const [startDate, endDate] = financialYear.split('-');
  return `${reformat(startDate, 'yyyyMMdd', 'yyyy-MM-dd')} – ${reformat(
    endDate,
    'yyyyMMdd',
    'yyyy-MM-dd'
  )}`;
};

export default formatFinancialYear;

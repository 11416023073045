/* eslint-disable import/prefer-default-export */
import { isSameOrBefore, parse } from '@agoy/dates';
import { compareAsc } from 'date-fns';
import { Routines } from '_reconciliation/types';

export const getRoutine = (
  periodStart: Date,
  periodsWithRoutineObjs: Routines['accountXXXX']
): string => {
  let lastPeriod = '';

  const sortedKeys = Object.keys(periodsWithRoutineObjs).sort((a, b) =>
    compareAsc(parse(a), parse(b))
  );

  sortedKeys.forEach((period) => {
    if (isSameOrBefore(parse(period, 'yyyy-MM'), periodStart)) {
      lastPeriod = period;
    }
  });

  return periodsWithRoutineObjs[lastPeriod] || '';
};

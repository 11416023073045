import { value } from '@agoy/document';
import { CompanyMetaData } from '../../types';
import { parseFormat } from '@agoy/dates';

export const finalTaxCalculations = {
  type: 'part' as const,
  partType: 'additionalPages' as const,
  children: {
    // fields
    companyType: { type: 'field' as const },
    financialYearStart: { type: 'field' as const },
    financialYearEnd: { type: 'field' as const },
    orgNumber: { type: 'field' as const },
    taxReturnsPositive: { type: 'field' as const },
    taxReturnsNegative: { type: 'field' as const },

    // tables
    finalTax: { type: 'table' as const },
    taxCalculations: { type: 'table' as const },
  },
};

export const getFinalCalculationsMetadata = ({
  customer,
  financialYearStart,
  financialYearEnd,
}: {
  customer: CompanyMetaData;
  financialYearStart: string;
  financialYearEnd: string;
}) => {
  return {
    partType: 'additionalPages' as const,
    companyType: value(customer.type),
    financialYearStart: value(parseFormat(financialYearStart, 'yyyy-MM-dd')),
    financialYearEnd: value(parseFormat(financialYearEnd, 'yyyy-MM-dd')),
    orgNumber: value(customer.orgNumber),
  };
};

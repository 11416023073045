import { AgoyTable } from '../table';
import { TableChange } from '../changes';
import { AgoyDocumentStructure } from '../document';
import updateTable from './helpers/updateTable';
import updateDocument from './helpers/updateDocument';
import { OperationResult, State, Errors } from './types';

type UpdateColumnSortKey = { id: string; sortKey: number };

type TableState = { node: AgoyTable; changes: TableChange } | Errors;

const updateTableColumnSortKey = <T extends AgoyDocumentStructure>(
  structure: T,
  state: State<T>,
  columns: UpdateColumnSortKey[]
): OperationResult<T> => {
  // Assuming that all columns belong to the same table
  if (columns.length === 0) {
    return false;
  }

  return updateDocument(structure, state, columns[0].id, {
    table: (key, tableId, props) => {
      const updated = columns.reduce(
        (currentState: TableState, { id, sortKey }): TableState => {
          if (!id.startsWith(tableId)) {
            return currentState;
          }
          if (typeof currentState === 'string') {
            return currentState;
          }
          const columnId = id.substring(tableId.length + 1);
          const updatedState = updateTable(
            currentState.node,
            currentState.changes,
            (currentTable: AgoyTable, change: TableChange) => {
              const column = currentTable.columns.find(
                (col) => col.id === columnId
              );
              if (!column) {
                return [currentTable, change];
              }

              const newTable = {
                ...currentTable,
                columns: currentTable.columns.map((col) =>
                  col.id === columnId ? { ...col, sortKey } : col
                ),
              };

              if (change.columns) {
                const existingColumnChange = change.columns?.find(
                  (col) => col.id === columnId
                );
                if (existingColumnChange) {
                  return [
                    newTable,
                    {
                      ...change,
                      columns: change.columns.map((col) =>
                        col.id === columnId ? { ...col, sortKey } : col
                      ),
                    },
                  ];
                }
                return [
                  newTable,
                  {
                    ...change,
                    columns: [
                      ...change.columns,
                      { type: 'update', id: columnId, sortKey },
                    ],
                  },
                ];
              }
              return [
                newTable,
                {
                  ...change,
                  columns: [{ type: 'update', id: columnId, sortKey }],
                },
              ];
            }
          );
          if (!updatedState) {
            return currentState;
          }
          if (Array.isArray(updatedState)) {
            return {
              node: updatedState[0],
              changes: updatedState[1],
            };
          }
          return updatedState;
        },
        props as TableState
      );

      if (typeof updated === 'object') {
        return {
          ...props,
          node: updated.node,
          changes: updated.changes,
        };
      }
      return props;
    },
  });
};

export default updateTableColumnSortKey;

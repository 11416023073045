import { value, table } from '@agoy/document';
import { IxbrlCell, NoteInvertedTwoTableSection } from '../../../../common';
import { sumNotesRowsById } from '../../../../common/utils/accountSumsUtil';
import {
  active,
  field,
  ixbrlCell,
  ixbrlField,
} from '../../../../common/utils/util';
import { year, previousYear } from '../../../../common/utils/date-util';

const MONETARY_ITEM_TYPE = 'monetaryItemType';

const getNote81 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null
): NoteInvertedTwoTableSection => {
  return {
    active: false,
    number: value(undefined),
    name: value('Operationella leasingavtal - leasegivare'),
    table: table<IxbrlCell>(
      'notes.note81.table',
      {
        id: 'label',
        label: 'Framtida minimileaseavgifter',
      },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      .addRows((rows) =>
        rows
          .addRow(
            '1',
            noteLabel('Inom ett år'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OperationellaLeasingavtalLeasegivareFramtidaMinimileaseavgifterInomEttAr',
              contextRef: 'period0',
              standardRubrik:
                'Framtida minimileaseavgifter avseende operationella leasingavtal som förfaller inom ett år, för leasegivare',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OperationellaLeasingavtalLeasegivareFramtidaMinimileaseavgifterInomEttAr',
              contextRef: 'period1',
              standardRubrik:
                'Framtida minimileaseavgifter avseende operationella leasingavtal som förfaller inom ett år, för leasegivare',
            })
          )
          .addRow(
            '2',
            noteLabel('Senare än ett år men inom fem år'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OperationellaLeasingavtalLeasegivareFramtidaMinimileaseavgifterMellanEttOchFemAr',
              contextRef: 'period0',
              standardRubrik:
                'Framtida minimileaseavgifter avseende operationella leasingavtal som förfaller senare än ett men inom fem år, för leasegivare',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OperationellaLeasingavtalLeasegivareFramtidaMinimileaseavgifterMellanEttOchFemAr',
              contextRef: 'period1',
              standardRubrik:
                'Framtida minimileaseavgifter avseende operationella leasingavtal som förfaller senare än ett men inom fem år, för leasegivare',
            })
          )
          .addRow(
            '3',
            noteLabel('Senare än fem år'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OperationellaLeasingavtalLeasegivareFramtidaMinimileaseavgifterSenareFemAr',
              contextRef: 'period0',
              standardRubrik:
                'Framtida minimileaseavgifter avseende operationella leasingavtal som förfaller senare än fem år, för leasegivare',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OperationellaLeasingavtalLeasegivareFramtidaMinimileaseavgifterSenareFemAr',
              contextRef: 'period1',
              standardRubrik:
                'Framtida minimileaseavgifter avseende operationella leasingavtal som förfaller senare än fem år, för leasegivare',
            })
          )
          .addRow(
            '4',
            noteLabel('Summa'),
            ixbrlCell(sumNotesRowsById(81, [1, 2, 3], 'year'), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OperationellaLeasingavtalLeasegivareFramtidaMinimileaseavgifter',
              contextRef: 'period0',
              standardRubrik:
                'Framtida minimileaseavgifter avseende operationella leasingavtal, för leasegivare',
            }),
            ixbrlCell(sumNotesRowsById(81, [1, 2, 3], 'previousYear'), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OperationellaLeasingavtalLeasegivareFramtidaMinimileaseavgifter',
              contextRef: 'period1',
              standardRubrik:
                'Framtida minimileaseavgifter avseende operationella leasingavtal, för leasegivare',
            })
          )
          .build()
      )
      .newRowTemplate(value(''), value(undefined), value(undefined))
      .build(),
    secondTable: table<IxbrlCell>(
      'notes.note81.secondTable',
      {
        id: 'label',
        label: 'Variabla avgifter som ingår i räkenskapsårets resultat',
      },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      .addRows((rows) =>
        rows
          .addRow(
            '1',
            noteLabel(
              'Variabla avgifter som ingår i räkenskapsårets resultat för leasegivare'
            ),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OperationellaLeasingavtalLeasegivareVariablaAvgifterIngarResultat',
              contextRef: 'period0',
              saldo: 'credit',
              standardRubrik:
                'Variabla avgifter avseende operationella leasingavtal som ingår i periodens resultat, för leasegivare',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OperationellaLeasingavtalLeasegivareVariablaAvgifterIngarResultat',
              contextRef: 'period1',
              saldo: 'credit',
              standardRubrik:
                'Variabla avgifter avseende operationella leasingavtal som ingår i periodens resultat, för leasegivare',
            })
          )
          .build()
      )
      .newRowTemplate(value(''), value(undefined), value(undefined))
      .build(),
    commentLabel: field('Kommentar'),
    comment: ixbrlField(active(field('')), {
      type: 'stringItemType',
      name: 'se-gen-base:NotOperationellaLeasingavtalLeasegivareKommentar',
      contextRef: 'period0',
      standardRubrik:
        'Kommentar till upplysning om operationella leasingavtal, leasegivare',
    }),
    group: value(undefined),
    section: value(undefined),
    validForReportType: {
      k2: false,
      k3: true,
    },
  };
};

export default getNote81;

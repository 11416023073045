import React from 'react';
import styled from '@emotion/styled';
import Typography from '../../UI/Typography/Typography';
import { Typography as MuiTypography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import {
  AnnualReportVersion,
  BalanceSheet,
  Settings,
} from '@agoy/annual-report-document';

import { default as BalanceSheetPrintTable } from '../../UI/BRRRPrintTable';
import HierarchicalPrintTable from '../../UI/HierarchicalPrintTable/v2/HierarchicalPrintTable';
import { filterRows } from '@agoy/document';
import { addPrintSpacers } from '../../utils/notes/addSpacers';

const Content = styled.div`
  @media screen {
    min-width: 100%;
    width: min-content;
  }

  @media print {
    width: 100%;
    page-break-before: always;
  }
`;

const VisualPageDivider = styled.div`
  @media screen {
    border-bottom: 1px dashed ${(props) => props.theme.palette.grey['500']};
  }
`;

const OwnPage = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(4)}px;
  page-break-after: always;
`;

interface BalanceSheetPageProps {
  balanceSheet: BalanceSheet;
  settings: Settings;
  version: AnnualReportVersion;
}

const BalanceSheetPage = ({
  balanceSheet,
  settings,
  version,
}: BalanceSheetPageProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const title = formatMessage({ id: 'annualReport.menu.balanceSheet' });

  const { assets, equityAndLiabilities } = balanceSheet.section;

  if (version === '2') {
    return (
      <Content>
        <OwnPage>
          <Typography variant="h1">{title}</Typography>
          <HierarchicalPrintTable
            tableId="balanceSheet.section.assets"
            table={addPrintSpacers(
              filterRows(balanceSheet.section.assets, ['account'], false),
              true
            )}
            level1ThiccRows
            compact
          />
        </OwnPage>
        <VisualPageDivider />
        <Typography variant="h1">{title}</Typography>
        <HierarchicalPrintTable
          tableId="balanceSheet.section.equityAndLiabilities"
          table={addPrintSpacers(
            filterRows(
              balanceSheet.section.equityAndLiabilities,
              ['account'],
              false
            ),
            true
          )}
          level1ThiccRows
          compact
        />
      </Content>
    );
  }

  return (
    <Content>
      <OwnPage>
        <MuiTypography variant="h1">{title}</MuiTypography>
        <BalanceSheetPrintTable
          table={assets}
          tableId="balanceSheet.section.assets"
          settings={settings}
        />
      </OwnPage>
      <MuiTypography variant="h1">{title}</MuiTypography>
      <BalanceSheetPrintTable
        table={equityAndLiabilities}
        tableId="balanceSheet.section.equityAndLiabilities"
        settings={settings}
      />
    </Content>
  );
};

export default BalanceSheetPage;

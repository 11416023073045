import { EventMapSchema, OperationsArray } from './event-map-schema';
import { flattenObjKeys, getUniqueKeys } from './utils';

/**
 * Get value of nested object path.
 */
function getNested(obj, ...args) {
  return args.reduce((obj, level) => obj && obj[level], obj);
}

type EventMapSchemaType = typeof EventMapSchema;

/**
 * Program > section > resource > operation relation in enforced through the
 * `createActivityLogEvent` function
 */
export type ActivityLogEventType = {
  // who and when, normaly populated on server
  date: Date;
  userId: string | null; // user uuid
  userIp: string; // v4 or v6
  clientId: string | null; // client uuid
  organisationId: string; // org id, used for access controll
  // where
  program: string;
  section: string;
  resource: string;
  // what
  /**
   * The operation allowed over a given resource
   */
  operation: string;
  /**
   * The changed params [inBound, outBound]
   * */
  arguments: Array<string>;
};

type FilterElement = { id: string; label: string };

interface IActivityLog {
  /**
   * Return EventMapSchema for further instrocpection.
   */
  getEventMapSchema: () => typeof EventMapSchema;

  /**
   * List the available programs.
   *
   * In the return:
   * `id` is the program string identifier defined in `modules/activity-log`.
   * `label` is the translated name for that program.
   */
  getAvailablePrograms: () => Array<FilterElement>;

  /**
   * List the available sections. Depends on the selected program.
   * Will be empty if no program is selected.
   *
   * In the return:
   * `id` is the section string identifier defined in `modules/activity-log`.
   * `label` is the translated name for that section.
   */
  getAvailableSections<
    selectedProgramId extends keyof typeof EventMapSchema & string
  >(
    selectedProgramId?: selectedProgramId
  ): Array<FilterElement>;

  /**
   * List the available resources. Depends on the selected program / section.
   * Will be empty if no program / section is selected.
   *
   * In the return:
   * `id` is the resource string identifier defined in `modules/activity-log`.
   * `label` is the translated name for that resction.
   */
  getAvailableResources<
    selectedProgramId extends keyof typeof EventMapSchema & string,
    selectedSectionId extends keyof typeof EventMapSchema[selectedProgramId] &
      string
  >(
    selectedProgramId?: selectedProgramId,
    selectedSectionId?: selectedSectionId
  ): Array<FilterElement>;

  /**
   * Validate that a operation is defined.
   */
  isValidOperation: (
    operationKey: string
  ) => operationKey is typeof OperationsArray[number];

  /**
   * Construct an activity log event, type safely against the EventMapSchema.
   */
  createActivityLogEvent<
    programKey extends keyof typeof EventMapSchema & string,
    sectionKey extends keyof typeof EventMapSchema[programKey] & string,
    resourceKey extends keyof typeof EventMapSchema[programKey][sectionKey] &
      string,
    operationKey extends keyof typeof EventMapSchema[programKey][sectionKey][resourceKey] &
      string
  >(args: {
    // metadata
    date?: Date;
    userId?: string;
    userIp?: string;
    clientId?: string;
    organisationId?: string;
    // provided by caller module
    program: programKey;
    section: sectionKey;
    resource: resourceKey;
    operation: operationKey;
    arguments: Array<string>;
  }): ActivityLogEventType;

  /**
   * Validates event context against existing map.
   *
   * program > section > resource > operation
   */
  isValidActivityLogEvent: (
    program: string,
    section: string,
    resource: string,
    operation: string
  ) => boolean;

  /**
   * Get description for one event, taking into account context and the provided template in sv.json file.
   */
  getDescription<
    programKey extends keyof typeof EventMapSchema & string,
    sectionKey extends keyof typeof EventMapSchema[programKey] & string,
    resourceKey extends keyof typeof EventMapSchema[programKey][sectionKey] &
      string,
    operationKey extends keyof typeof EventMapSchema[programKey][sectionKey][resourceKey] &
      string
  >(
    program: programKey | string,
    section: sectionKey | string,
    resource: resourceKey | string,
    operation: operationKey | string
  ): string;

  /**
   * Get description id for one event, taking into account context and the provided template in sv.json file.
   */
  getDescriptionId<
    programKey extends keyof typeof EventMapSchema & string,
    sectionKey extends keyof typeof EventMapSchema[programKey] & string,
    resourceKey extends keyof typeof EventMapSchema[programKey][sectionKey] &
      string,
    operationKey extends keyof typeof EventMapSchema[programKey][sectionKey][resourceKey] &
      string
  >(
    program: programKey | string,
    section: sectionKey | string,
    resource: resourceKey | string,
    operation: operationKey | string
  ): string;
}

class ActivityLog implements IActivityLog {
  private eventMapSchema: EventMapSchemaType;
  private translatedMessages: Record<string, string>;

  constructor(
    eventMapSchema: EventMapSchemaType,
    translatedMessages: Record<string, string>
  ) {
    this.eventMapSchema = eventMapSchema;
    this.translatedMessages = translatedMessages;

    if (!this.isValidSchema(eventMapSchema)) {
      throw new Error(
        'The EventMapSchema is not valid, please read the docs and update it.'
      );
    }

    if (!this.hasAllTransactions(eventMapSchema)) {
      throw new Error('EventMapSchema translations are missing.');
    }
  }

  /**
   * Checks if the EventMap schema is valid.
   * The operations should be part of the enum and the general ontoly structure kept.
   */
  private isValidSchema(eventMap: typeof EventMapSchema): boolean {
    for (const programKey in eventMap) {
      for (const sectionKey in eventMap[programKey]) {
        for (const resourceKey in eventMap[programKey][sectionKey]) {
          for (const operationKey in eventMap[programKey][sectionKey][
            resourceKey
          ]) {
            const operationValue =
              eventMap[programKey][sectionKey][resourceKey][operationKey];

            // return false if the operation doesnt exist, or value is not `true`
            if (
              this.isValidOperation(operationKey) &&
              operationValue &&
              typeof operationValue == 'boolean'
            ) {
            } else {
              console.error(
                `Invalid schema at eventMap[${programKey}][${sectionKey}][${resourceKey}][${operationKey}]\
\nShould be a valid Operation, [${OperationsArray.toString()}] and :true`
              );
              return false;
            }
          }
        }
      }
    }

    return true;
  }

  /**
   * Checks that all the programs, sections, resources, operations, and templates are present.
   * Translations are located in `modules/common/src/sv.json`
   */
  private hasAllTransactions(eventMap: typeof EventMapSchema) {
    // check per resource operation template
    const flattenEventMapKeys = flattenObjKeys(eventMap);
    const hasTemplateTranslations = flattenEventMapKeys.every((key) => {
      const hasTranslation =
        this.translatedMessages[key] && this.translatedMessages[key].length > 0;

      if (!hasTranslation)
        console.error(`Missing translation for EventMap template: '${key}'`);

      return hasTranslation;
    });

    // check all keys have translation (programs, sections, resources and operations)
    const uniqueKeys = getUniqueKeys(eventMap);
    const hasKeyTranslations = uniqueKeys.every((key) => {
      const hasTranslation =
        this.translatedMessages[key] && this.translatedMessages[key].length > 0;

      if (!hasTranslation)
        console.error(
          `Missing translation, or empty string for EventMap key: '${key}'`
        );

      return hasTranslation;
    });

    return hasTemplateTranslations && hasKeyTranslations;
  }

  getEventMapSchema: IActivityLog['getEventMapSchema'] = () =>
    this.eventMapSchema;

  getAvailablePrograms: IActivityLog['getAvailablePrograms'] = () => {
    return Object.keys(this.eventMapSchema).map((programKey) => {
      return {
        id: programKey,
        label: this.translatedMessages[programKey],
      };
    });
  };

  getAvailableSections: IActivityLog['getAvailableSections'] = (
    selectedProgramId
  ) => {
    const sectionObject = getNested(EventMapSchema, selectedProgramId) || {};

    return Object.keys(sectionObject).map((sectionKey) => {
      return {
        id: sectionKey,
        label: this.translatedMessages[sectionKey],
      };
    });
  };

  getAvailableResources: IActivityLog['getAvailableResources'] = (
    selectedProgramId,
    selectedSectionId
  ) => {
    const resourceObject =
      getNested(EventMapSchema, selectedProgramId, selectedSectionId) || {};

    return Object.keys(resourceObject).map((sectionKey) => {
      return {
        id: sectionKey,
        label: this.translatedMessages[sectionKey],
      };
    });
  };

  isValidOperation: IActivityLog['isValidOperation'] = (
    operationKey
  ): operationKey is typeof OperationsArray[number] => {
    return (OperationsArray as readonly string[]).includes(operationKey);
  };

  createActivityLogEvent: IActivityLog['createActivityLogEvent'] = (args) => ({
    date: args.date || new Date(),
    userId: args.userId || null,
    userIp: args.userIp || '',
    clientId: args.clientId || null,
    organisationId: args.organisationId || (null as any),
    ...args,
  });

  isValidActivityLogEvent: IActivityLog['isValidActivityLogEvent'] = (
    ...context
  ) => getNested(EventMapSchema, ...context) === true;

  getDescription: IActivityLog['getDescription'] = (
    program,
    section,
    resource,
    operation
  ) =>
    this.translatedMessages[`${program}.${section}.${resource}.${operation}`] ||
    '';

  getDescriptionId: IActivityLog['getDescriptionId'] = (
    program,
    section,
    resource,
    operation
  ) => `${program}.${section}.${resource}.${operation}`;
}

export default ActivityLog;

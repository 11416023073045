import React from 'react';
import { Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import styled from '@emotion/styled';
import {
  MultiYearOverview as MultiYearOverviewType,
  Settings,
} from '@agoy/annual-report-document';
import { Heading, Section as BaseSection } from '../../../../UI';
import YearTable from '../../UI/YearTable';
import MultilineField from '../../UI/MultilineField';
import { numberValue, stringValue } from '@agoy/document';
import {
  NumberFormatType,
  PercentageNumberFormatTypeWithPrecision,
  PlainIntegerFormattedNumberFormatType,
  PlainIntegerNumberFormatType,
  StandardNumberFormatType,
} from '@agoy/common';
import { FormattingContext } from '@agoy/formatting';

const Section = styled(BaseSection)`
  width: 100%;
`;

const usePercentageForRow = (precision: number) => {
  return (rowId: string): NumberFormatType => {
    const percentageFields = [
      'solidity',
      'resultPercentage',
      'operativeMargin',
      'avkastningTotaltKapital',
      'avkastningSysselsattKapital',
      'avkastningEgetKapital',
      'kassalikviditet',
    ];

    if (rowId === 'avgNumberOfEmployees') {
      return PlainIntegerNumberFormatType;
    }

    if (rowId.includes('@')) {
      return PlainIntegerFormattedNumberFormatType;
    }

    return percentageFields.includes(rowId)
      ? PercentageNumberFormatTypeWithPrecision(precision)
      : StandardNumberFormatType;
  };
};
interface MultiYearOverviewProps {
  reportPart: MultiYearOverviewType;
  isDigitalSubmission: boolean | null | undefined;
  title: string | undefined;
  commentLabel: string | undefined;
  settings: Settings;
}

const MultiYearOverview = ({
  reportPart,
  isDigitalSubmission,
  title,
  settings,
  commentLabel,
}: MultiYearOverviewProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const intl = useIntl();

  const formattingContext = React.useContext(FormattingContext);
  const displayLabel = formattingContext.shouldPrintLabel();

  const precision =
    numberValue(
      settings.section.formatting
        .numberOfDecimalsForPercentagesInMultiYearOverview
    ) || 0;

  const { table } = reportPart;
  const titleValue =
    title ||
    intl.formatMessage({
      id: 'annualReport.menu.managementReport.multiYearOverview',
    });

  const isCommentEmpty = stringValue(reportPart.multiYearText) === '';
  return (
    <Section>
      <Heading title={titleValue} />
      {displayLabel && (
        <Typography variant="h5">
          {formatMessage(
            {
              id: 'annualReport.menu.managementReport.currencyPart',
            },
            {
              type: 'Flerårsöversikt',
            }
          )}
        </Typography>
      )}
      <YearTable
        tableId="managementReport.multiYearOverview.table"
        table={table}
        numberFormat={usePercentageForRow(precision)}
        isDigitalSubmission={isDigitalSubmission}
      />

      {!isCommentEmpty && (
        <MultilineField
          header={
            <Typography variant="h4">
              {commentLabel ||
                formatMessage({
                  id: `annualReport.managementReport.multiYearOverview.comments`,
                })}
            </Typography>
          }
          field={reportPart.multiYearText}
        />
      )}
    </Section>
  );
};

export default MultiYearOverview;

import React, { useMemo, useEffect } from 'react';
import { AgoyDocumentStructure, DocumentDataService } from '@agoy/document';
import { GenericDocumentViewService } from './GenericDocumentViewService';
import GenericDocumentReactiveViewService from './GenericDocumentReactiveViewService';

const DocumentViewServiceContext =
  React.createContext<GenericDocumentViewService>(
    {} as GenericDocumentViewService
  );

type ProviderType<T extends AgoyDocumentStructure> = React.PropsWithChildren<{
  structure: T;
  dataService: DocumentDataService<T>;
}>;

export const Provider = <T extends AgoyDocumentStructure>({
  children,
  structure,
  dataService,
}: ProviderType<T>) => {
  const service = useMemo(() => {
    return new GenericDocumentReactiveViewService(structure, dataService);
  }, [structure, dataService]);

  useEffect(() => {
    return () => {
      service.dispose();
    };
  }, [service]);

  return (
    <DocumentViewServiceContext.Provider value={service}>
      {children}
    </DocumentViewServiceContext.Provider>
  );
};

export default DocumentViewServiceContext;

import React from 'react';
import styled from '@emotion/styled';
import { Tooltip } from '@material-ui/core';
import { Lock } from '@material-ui/icons';

// @ts-ignore
import { ReactComponent as ReconciliationIcon } from '_shared/components/IconMenu/assets/reconciliation.svg';
import { getPeriodHeaderStatusColor } from 'utils';
import { LampValue } from '_clients/types/types';
import { Status } from '_shared/types';
import { colors } from '@agoy/theme';

const IconWrapper = styled.div<{ statusColor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ statusColor }) => statusColor};
  padding: 6px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  position: relative;
`;

const Icon = styled(ReconciliationIcon)`
  fill: white;
`;

const LockIcon = styled(Lock)`
  position: absolute;
  left: 40%;
  top: 10%;
  font-size: 20px;
  color: black;
`;

export type ReconciliationStatusProps = {
  status?: Status;
  groupStatus?: LampValue;
  tooltipMessage?: string;
  className?: string;
};

const ReconciliationStatus = ({
  status = 'NOT_STARTED',
  groupStatus = 'doesNotExist',
  tooltipMessage = '',
  className,
}: ReconciliationStatusProps) => {
  const statusColor =
    status === 'STARTED' && groupStatus === 'wrong'
      ? getPeriodHeaderStatusColor(groupStatus)
      : colors[status];

  return (
    <Tooltip title={tooltipMessage}>
      <IconWrapper className={className} statusColor={statusColor}>
        <Icon />
        {status === 'LOCKED' && <LockIcon />}
      </IconWrapper>
    </Tooltip>
  );
};

export default ReconciliationStatus;

import React from 'react';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import { useIntl } from 'react-intl';
import styled from '@emotion/styled';
import theme from 'theme';
import { FieldValues, UseFormSetValue } from 'react-hook-form';

import { GetComponentProps } from '_shared/services/type-helpers';
import { normalizeString } from '_clients/components/Information/utils';
import GenericSimplified from './GenericSimplefied';

const getColor = (error?: boolean, warning?: boolean) => {
  if (error) {
    return theme.palette.error.main;
  }
  if (warning) {
    return theme.palette.warning.main;
  }

  return null;
};

const StyledTextField = styled(TextField)<{
  helpertextcolor: string | null;
}>`
  .MuiOutlinedInput-notchedOutline {
    ${({ helpertextcolor }) =>
      helpertextcolor ? 'border-color:'.concat(helpertextcolor) : ''};
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    ${({ helpertextcolor }) =>
      helpertextcolor ? 'border-color:'.concat(helpertextcolor) : ''};
  }
  .MuiFormHelperText-root {
    ${({ helpertextcolor }) =>
      helpertextcolor ? 'color:'.concat(helpertextcolor) : ''};
  }
`;

type SimplifiedTextfieldProps = {
  setValue?: UseFormSetValue<FieldValues>;
  field?: string;
  dataCy?: string;
  warning?: boolean;
};

const SimplifiedTextfield = React.forwardRef(
  (
    props: GetComponentProps<typeof GenericSimplified> &
      TextFieldProps &
      SimplifiedTextfieldProps,
    ref
  ): JSX.Element => {
    const intl = useIntl();
    const inputProps: { 'data-cy'?: string } = {};
    const {
      value,
      required,
      width,
      warning,
      helperText,
      error,
      field,
      setValue,
      dataCy,
      ...rest
    } = props;

    if (props.label) delete rest.label;
    if (props.editing) delete rest.editing;
    if (props.labelPlacement) delete rest.labelPlacement;
    if (props.className) delete rest.className;
    if (props.onBlur) delete rest.onBlur;

    if (props.dataCy) {
      inputProps['data-cy'] = dataCy || undefined;
    }

    const size = props.size ? props.size : 'small';

    const normalizeInput = (input: string): string => {
      return normalizeString(input);
    };

    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
      const normalized = normalizeInput(event.target.value);
      if (setValue && field) {
        setValue(field, normalized);
      }
    };

    return (
      <GenericSimplified {...props}>
        <StyledTextField
          variant="outlined"
          value={value}
          size={size}
          inputProps={inputProps}
          fullWidth={!!width}
          inputRef={ref}
          helpertextcolor={getColor(error, warning)}
          onBlur={handleBlur}
          helperText={
            helperText ||
            (required &&
              !value &&
              intl.formatMessage({ id: 'form.fieldRequired' }))
          }
          {...rest}
        />
      </GenericSimplified>
    );
  }
);
export default SimplifiedTextfield;

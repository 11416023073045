import React from 'react';
import { Box } from '@material-ui/core';
import { Note28Section } from '@agoy/annual-report-document';
import { Field, numberValue, stringValue } from '@agoy/document';
import Comment from '../../UI/Comment';
import FieldSection from '../../UI/FieldSection';
import { FieldSectionTitle } from '../../UI/FieldSectionTitle';
import Header from '../../UI/Header';
import { Note } from '../../UI/Note';
import NoteTable from '../../UI/NoteTable';

type Note28Props = {
  title: string;
  commentLabel: Field;
  hasPreviousYear?: boolean;
} & Note28Section;

const Note28 = ({
  active,
  number,
  general,
  table,
  secondTable,
  sumTable,
  comment,
  title,
  commentLabel,
  hasPreviousYear = false,
}: Note28Props): JSX.Element | null => {
  if (!active) return null;

  return (
    <Note id="note28">
      <Header noteId="note28" noteNumber={numberValue(number)} title={title} />

      <FieldSectionTitle noteId="note28" id="general" />
      <FieldSection value={stringValue(general) || ''} />

      <Box mt={1} mb={1}>
        <NoteTable
          hasPreviousYear={hasPreviousYear}
          table={table}
          tableId="notes.note28.table"
        />
      </Box>

      <Box mt={1} mb={1}>
        <NoteTable
          hasPreviousYear={hasPreviousYear}
          table={secondTable}
          tableId="notes.note28.secondTable"
        />
      </Box>

      <Box mt={1} mb={1}>
        <NoteTable
          table={sumTable}
          tableId="notes.note28.sumTable"
          hasPreviousYear={hasPreviousYear}
        />
      </Box>

      {/* Comment */}
      <Comment
        field={comment}
        value={stringValue(comment) || ''}
        labelValue={stringValue(commentLabel) || ''}
      />
    </Note>
  );
};

export default Note28;

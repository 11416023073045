/*
 * This is a GENERATED file, do not change manually!
 * Generated:
 * - from file k3-arsredovisning-2021-10-31-rev20230131 - Kostnadsslagsindelad resultatr.csv
 * - Comments: nan
 */

import {
  id,
  account,
  sum,
  sumAllowNull,
  ifOrElse,
  or,
  multiply,
  label,
  ref,
  not,
  value,
  refs,
  ReferenceAccountInformation,
  TimePeriod,
  AccountValueType,
} from '@agoy/document';
import { IxbrlCell } from '../../../../common';
import {
  table,
  RowsBuilder,
  ixbrlCell,
} from '../../../../common/utils/util';
import { AnnualReportType, AnnualReportVersion } from '../../../../document';
import { range, StatementSection } from '@agoy/annual-report-document';

const accountRangeRows = (
  accounts: Record<string, ReferenceAccountInformation>,
  first: number,
  last: number,
  rows: RowsBuilder,
  switchSign: boolean,
  currentPeriod: string,
  previousPeriod: string,
  modifier?: AccountValueType | undefined
): RowsBuilder => {
  range(first, last + 1).forEach((n) => {
    const accountNumber = n.toString();
    const accountInformation = accounts[accountNumber];
    if (accountInformation) {
      rows
        .addRowWithType(
          accountNumber,
          'account',
          label(`${accountInformation.accountName}`),
          undefined,
          ref(
            switchSign
              ? multiply(
                  -1,
                  or(account(accountNumber, modifier, currentPeriod), 0)
                )
              : or(account(accountNumber, modifier, currentPeriod), 0)
          ),
          ref(
            switchSign
              ? multiply(
                  -1,
                  or(account(accountNumber, modifier, previousPeriod), 0)
                )
              : or(account(accountNumber, modifier, previousPeriod), 0)
          )
        )
        .setSortKey(n);
    }
  });
  return rows;
};

export const costDividedIncomeStatementK3v2Config = (
  accounts: Record<string, ReferenceAccountInformation>,
  period: TimePeriod,
  previousPeriod: TimePeriod | null,
  type: AnnualReportType,
  isDigitalSubmission: boolean | null,
  documentTypeVersion: AnnualReportVersion = '2',
  accountModifier: AccountValueType = 'change'
): StatementSection => ({
  active: undefined,
  section: {
    active: undefined,
    table: table<IxbrlCell>(
      'incomeStatement.section.table',
      { id: 'label', dataType: 'text' },
      { id: 'notes', dataType: 'numeric' },
      {
        id: 'year',
        label: `${period.startDateISO} ${period.endDateISO}`,
        dataType: 'numeric',
      },
      previousPeriod
        ? {
            id: 'previousYear',
            label: `${previousPeriod.startDateISO} ${previousPeriod.endDateISO}`,
            dataType: 'numeric',
          }
        : undefined
    )
      .addRows((rows) => {
        rows
          .addRowWithType(
            'RorelseresultatAbstract',
            'header',
            value('Rörelseresultat'),
            refs(),
            undefined,
            undefined
          )
          .addSubRows((rows) => {
            rows
              .addRowWithType(
                'RorelsensIntakterLagerforandringarMmAbstract',
                'header',
                value('Rörelsens intäkter, lagerförändringar m.m.'),
                refs(),
                undefined,
                undefined
              )
              .addSubRows((rows) => {
                rows
                  .addRowWithType(
                    'Nettoomsattning',
                    'alwaysDisplay',
                    value('Nettoomsättning'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.RorelseresultatAbstract.RorelsensIntakterLagerforandringarMmAbstract.Nettoomsattning.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:Nettoomsattning',
                        contextRef: 'period0',
                        standardRubrik: 'Nettoomsättning',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.RorelseresultatAbstract.RorelsensIntakterLagerforandringarMmAbstract.Nettoomsattning.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:Nettoomsattning',
                        contextRef: 'period1',
                        standardRubrik: 'Nettoomsättning',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    accountRangeRows(
                      accounts,
                      3000,
                      3799,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );

                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'ForandringLagerProdukterIArbeteFardigaVarorPagaendeArbetenAnnansRakning',
                    'row',
                    value(
                      'Förändring av lager av färdiga varor, av varor under tillverkning och pågående arbeten för annans räkning'
                    ),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.RorelseresultatAbstract.RorelsensIntakterLagerforandringarMmAbstract.ForandringLagerProdukterIArbeteFardigaVarorPagaendeArbetenAnnansRakning.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:ForandringLagerProdukterIArbeteFardigaVarorPagaendeArbetenAnnansRakning',
                        contextRef: 'period0',
                        standardRubrik:
                          'Förändring av lager av färdiga varor, av varor under tillverkning och pågående arbeten för annans räkning',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.RorelseresultatAbstract.RorelsensIntakterLagerforandringarMmAbstract.ForandringLagerProdukterIArbeteFardigaVarorPagaendeArbetenAnnansRakning.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:ForandringLagerProdukterIArbeteFardigaVarorPagaendeArbetenAnnansRakning',
                        contextRef: 'period1',
                        standardRubrik:
                          'Förändring av lager av färdiga varor, av varor under tillverkning och pågående arbeten för annans räkning',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    accountRangeRows(
                      accounts,
                      4900,
                      4999,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );

                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'AktiveratArbeteEgenRakning',
                    'row',
                    value('Aktiverat arbete för egen räkning'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.RorelseresultatAbstract.RorelsensIntakterLagerforandringarMmAbstract.AktiveratArbeteEgenRakning.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:AktiveratArbeteEgenRakning',
                        contextRef: 'period0',
                        standardRubrik: 'Aktiverat arbete för egen räkning',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.RorelseresultatAbstract.RorelsensIntakterLagerforandringarMmAbstract.AktiveratArbeteEgenRakning.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:AktiveratArbeteEgenRakning',
                        contextRef: 'period1',
                        standardRubrik: 'Aktiverat arbete för egen räkning',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    accountRangeRows(
                      accounts,
                      3800,
                      3899,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );

                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'OvrigaRorelseintakter',
                    'row',
                    value('Övriga rörelseintäkter'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.RorelseresultatAbstract.RorelsensIntakterLagerforandringarMmAbstract.OvrigaRorelseintakter.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:OvrigaRorelseintakter',
                        contextRef: 'period0',
                        standardRubrik: 'Övriga rörelseintäkter',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.RorelseresultatAbstract.RorelsensIntakterLagerforandringarMmAbstract.OvrigaRorelseintakter.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:OvrigaRorelseintakter',
                        contextRef: 'period1',
                        standardRubrik: 'Övriga rörelseintäkter',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    accountRangeRows(
                      accounts,
                      3900,
                      3999,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );

                    return rows.build();
                  });
                return rows.build();
              });
            rows
              .addRowWithType(
                'RorelseintakterLagerforandringarMm',
                'alwaysDisplaySum',
                value('Summa rörelsens intäkter, lagerförändringar m.m.'),
                undefined,
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'incomeStatement.section.table.RorelseresultatAbstract.RorelsensIntakterLagerforandringarMmAbstract.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    name: 'se-gen-base:RorelseintakterLagerforandringarMm',
                    contextRef: 'period0',
                    standardRubrik: 'Rörelseintäkter, lagerförändringar m.m.',
                    saldo: 'credit',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'incomeStatement.section.table.RorelseresultatAbstract.RorelsensIntakterLagerforandringarMmAbstract.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    name: 'se-gen-base:RorelseintakterLagerforandringarMm',
                    contextRef: 'period1',
                    standardRubrik: 'Rörelseintäkter, lagerförändringar m.m.',
                    saldo: 'credit',
                    negateValue: false,
                  }
                )
              )
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(or(account('$ub', accountModifier, 'year'), 0)),
                ref(or(account('$ib', accountModifier, 'previousYear'), 0))
              )
              .addSubRows((rows) => {
                return rows.build();
              });
            rows
              .addRowWithType(
                'RorelsekostnaderAbstract',
                'header',
                value('Rörelsens kostnader'),
                refs(),
                undefined,
                undefined
              )
              .addSubRows((rows) => {
                rows
                  .addRowWithType(
                    'RavarorFornodenheterKostnader',
                    'row',
                    value('Råvaror och förnödenheter'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.RorelseresultatAbstract.RorelsekostnaderAbstract.RavarorFornodenheterKostnader.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:RavarorFornodenheterKostnader',
                        contextRef: 'period0',
                        standardRubrik:
                          'Kostnad för förbrukning av råvaror och förnödenheter',
                        saldo: 'debit',
                        negateValue: true,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.RorelseresultatAbstract.RorelsekostnaderAbstract.RavarorFornodenheterKostnader.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:RavarorFornodenheterKostnader',
                        contextRef: 'period1',
                        standardRubrik:
                          'Kostnad för förbrukning av råvaror och förnödenheter',
                        saldo: 'debit',
                        negateValue: true,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    accountRangeRows(
                      accounts,
                      4000,
                      4899,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );

                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'HandelsvarorKostnader',
                    'row',
                    value('Handelsvaror'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.RorelseresultatAbstract.RorelsekostnaderAbstract.HandelsvarorKostnader.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:HandelsvarorKostnader',
                        contextRef: 'period0',
                        standardRubrik: 'Kostnad för sålda handelsvaror',
                        saldo: 'debit',
                        negateValue: true,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.RorelseresultatAbstract.RorelsekostnaderAbstract.HandelsvarorKostnader.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:HandelsvarorKostnader',
                        contextRef: 'period1',
                        standardRubrik: 'Kostnad för sålda handelsvaror',
                        saldo: 'debit',
                        negateValue: true,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'OvrigaExternaKostnader',
                    'row',
                    value('Övriga externa kostnader'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.RorelseresultatAbstract.RorelsekostnaderAbstract.OvrigaExternaKostnader.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:OvrigaExternaKostnader',
                        contextRef: 'period0',
                        standardRubrik: 'Övriga externa kostnader',
                        saldo: 'debit',
                        negateValue: true,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.RorelseresultatAbstract.RorelsekostnaderAbstract.OvrigaExternaKostnader.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:OvrigaExternaKostnader',
                        contextRef: 'period1',
                        standardRubrik: 'Övriga externa kostnader',
                        saldo: 'debit',
                        negateValue: true,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    accountRangeRows(
                      accounts,
                      5000,
                      6999,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );

                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'Personalkostnader',
                    'row',
                    value('Personalkostnader'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.RorelseresultatAbstract.RorelsekostnaderAbstract.Personalkostnader.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:Personalkostnader',
                        contextRef: 'period0',
                        standardRubrik: 'Personalkostnader',
                        saldo: 'debit',
                        negateValue: true,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.RorelseresultatAbstract.RorelsekostnaderAbstract.Personalkostnader.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:Personalkostnader',
                        contextRef: 'period1',
                        standardRubrik: 'Personalkostnader',
                        saldo: 'debit',
                        negateValue: true,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    accountRangeRows(
                      accounts,
                      7000,
                      7699,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );

                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'AvskrivningarNedskrivningarMateriellaImmateriellaAnlaggningstillgangar',
                    'row',
                    value(
                      'Av- och nedskrivningar av materiella och immateriella anläggningstillgångar'
                    ),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.RorelseresultatAbstract.RorelsekostnaderAbstract.AvskrivningarNedskrivningarMateriellaImmateriellaAnlaggningstillgangar.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:AvskrivningarNedskrivningarMateriellaImmateriellaAnlaggningstillgangar',
                        contextRef: 'period0',
                        standardRubrik:
                          'Avskrivningar och nedskrivningar av materiella och immateriella anläggningstillgångar inklusive återföringar av nedskrivningar',
                        saldo: 'debit',
                        negateValue: true,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.RorelseresultatAbstract.RorelsekostnaderAbstract.AvskrivningarNedskrivningarMateriellaImmateriellaAnlaggningstillgangar.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:AvskrivningarNedskrivningarMateriellaImmateriellaAnlaggningstillgangar',
                        contextRef: 'period1',
                        standardRubrik:
                          'Avskrivningar och nedskrivningar av materiella och immateriella anläggningstillgångar inklusive återföringar av nedskrivningar',
                        saldo: 'debit',
                        negateValue: true,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    accountRangeRows(
                      accounts,
                      7800,
                      7899,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );

                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'NedskrivningarOmsattningstillgangarUtoverNormalaNedskrivningar',
                    'row',
                    value(
                      'Nedskrivningar av omsättningstillgångar utöver normala nedskrivningar'
                    ),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.RorelseresultatAbstract.RorelsekostnaderAbstract.NedskrivningarOmsattningstillgangarUtoverNormalaNedskrivningar.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:NedskrivningarOmsattningstillgangarUtoverNormalaNedskrivningar',
                        contextRef: 'period0',
                        standardRubrik:
                          'Nedskrivningar av omsättningstillgångar utöver normala nedskrivningar',
                        saldo: 'debit',
                        negateValue: true,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.RorelseresultatAbstract.RorelsekostnaderAbstract.NedskrivningarOmsattningstillgangarUtoverNormalaNedskrivningar.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:NedskrivningarOmsattningstillgangarUtoverNormalaNedskrivningar',
                        contextRef: 'period1',
                        standardRubrik:
                          'Nedskrivningar av omsättningstillgångar utöver normala nedskrivningar',
                        saldo: 'debit',
                        negateValue: true,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    accountRangeRows(
                      accounts,
                      7700,
                      7799,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );

                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'OvrigaRorelsekostnader',
                    'row',
                    value('Övriga rörelsekostnader'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.RorelseresultatAbstract.RorelsekostnaderAbstract.OvrigaRorelsekostnader.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:OvrigaRorelsekostnader',
                        contextRef: 'period0',
                        standardRubrik:
                          'Övriga rörelsekostnader i kostnadsslagsindelad resultaträkning',
                        saldo: 'debit',
                        negateValue: true,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.RorelseresultatAbstract.RorelsekostnaderAbstract.OvrigaRorelsekostnader.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:OvrigaRorelsekostnader',
                        contextRef: 'period1',
                        standardRubrik:
                          'Övriga rörelsekostnader i kostnadsslagsindelad resultaträkning',
                        saldo: 'debit',
                        negateValue: true,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    accountRangeRows(
                      accounts,
                      7900,
                      7999,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );

                    return rows.build();
                  });
                return rows.build();
              });
            rows
              .addRowWithType(
                'Rorelsekostnader',
                'sum',
                value('Summa rörelsens kostnader'),
                undefined,
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'incomeStatement.section.table.RorelseresultatAbstract.RorelsekostnaderAbstract.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    name: 'se-gen-base:Rorelsekostnader',
                    contextRef: 'period0',
                    standardRubrik: 'Rörelsekostnader',
                    saldo: 'debit',
                    negateValue: true,
                  }
                ),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'incomeStatement.section.table.RorelseresultatAbstract.RorelsekostnaderAbstract.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    name: 'se-gen-base:Rorelsekostnader',
                    contextRef: 'period1',
                    standardRubrik: 'Rörelsekostnader',
                    saldo: 'debit',
                    negateValue: true,
                  }
                )
              )
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(or(account('$ub', accountModifier, 'year'), 0)),
                ref(or(account('$ib', accountModifier, 'previousYear'), 0))
              )
              .addSubRows((rows) => {
                return rows.build();
              });
            rows
              .addRowWithType(
                'AndelarIntresseforetagGemensamtStyrdaForetagResultat',
                'row',
                value(
                  'Andelar i intresseföretags och gemensamt styrda företags resultat'
                ),
                refs(),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'incomeStatement.section.table.RorelseresultatAbstract.AndelarIntresseforetagGemensamtStyrdaForetagResultat.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagResultat',
                    contextRef: 'period0',
                    standardRubrik:
                      'Andelar i intresseföretags och gemensamt styrda företags resultat',
                    saldo: 'credit',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'incomeStatement.section.table.RorelseresultatAbstract.AndelarIntresseforetagGemensamtStyrdaForetagResultat.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagResultat',
                    contextRef: 'period1',
                    standardRubrik:
                      'Andelar i intresseföretags och gemensamt styrda företags resultat',
                    saldo: 'credit',
                    negateValue: false,
                  }
                )
              ) // <<< end row_empty_value ---
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(
                  multiply(-1, or(account('$ub', accountModifier, 'year'), 0))
                ),
                ref(
                  multiply(
                    -1,
                    or(account('$ib', accountModifier, 'previousYear'), 0)
                  )
                )
              )
              .addSubRows((rows) => {
                return rows.build();
              });
            rows
              .addRowWithType(
                'RealisationsresultatForsaljningIntresseforetagGemensamtStyrdaForetag',
                'row',
                value(
                  'Realisationsresultat vid försäljning av intresseföretag och gemensamt styrda företag'
                ),
                refs(),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'incomeStatement.section.table.RorelseresultatAbstract.RealisationsresultatForsaljningIntresseforetagGemensamtStyrdaForetag.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:RealisationsresultatForsaljningIntresseforetagGemensamtStyrdaForetag',
                    contextRef: 'period0',
                    standardRubrik:
                      'Realisationsresultat vid försäljning av intresseföretag och gemensamt styrda företag',
                    saldo: 'credit',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'incomeStatement.section.table.RorelseresultatAbstract.RealisationsresultatForsaljningIntresseforetagGemensamtStyrdaForetag.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:RealisationsresultatForsaljningIntresseforetagGemensamtStyrdaForetag',
                    contextRef: 'period1',
                    standardRubrik:
                      'Realisationsresultat vid försäljning av intresseföretag och gemensamt styrda företag',
                    saldo: 'credit',
                    negateValue: false,
                  }
                )
              ) // <<< end row_empty_value ---
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(
                  multiply(-1, or(account('$ub', accountModifier, 'year'), 0))
                ),
                ref(
                  multiply(
                    -1,
                    or(account('$ib', accountModifier, 'previousYear'), 0)
                  )
                )
              )
              .addSubRows((rows) => {
                return rows.build();
              });
            return rows.build();
          });
        rows
          .addRowWithType(
            'Rorelseresultat',
            'alwaysDisplaySum',
            value('Rörelseresultat'),
            undefined,
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'incomeStatement.section.table.RorelseresultatAbstract.*.year'
                  )
                )
              ),
              {
                type: 'monetaryItemType',
                name: 'se-gen-base:Rorelseresultat',
                contextRef: 'period0',
                standardRubrik: 'Rörelseresultat',
                saldo: 'credit',
                negateValue: false,
              }
            ),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'incomeStatement.section.table.RorelseresultatAbstract.*.previousYear'
                  )
                )
              ),
              {
                type: 'monetaryItemType',
                name: 'se-gen-base:Rorelseresultat',
                contextRef: 'period1',
                standardRubrik: 'Rörelseresultat',
                saldo: 'credit',
                negateValue: false,
              }
            )
          )
          .newRowTemplateWithType(
            'account',
            label('$label'),
            undefined,
            ref(or(account('$ub', accountModifier, 'year'), 0)),
            ref(or(account('$ib', accountModifier, 'previousYear'), 0))
          )
          .addSubRows((rows) => {
            return rows.build();
          });
        rows
          .addRowWithType(
            'FinansiellaPosterAbstract',
            'header',
            value('Resultat från finansiella poster'),
            refs(),
            undefined,
            undefined
          )
          .addSubRows((rows) => {
            rows
              .addRowActive(ref(id('settings.isNedskrivningEgenPost')))
              .addRowWithType(
                'FinansiellaPosterNedskrivningEgenPostAbstract',
                'header',
                value(
                  'Resultat från finansiella poster - med nedskrivningar i egen post'
                ),
                refs(),
                undefined,
                undefined
              )
              .addSubRows((rows) => {
                rows
                  .addRowWithType(
                    'ResultatAndelarKoncernforetag',
                    'row',
                    value('Resultat från andelar i koncernföretag'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningEgenPostAbstract.ResultatAndelarKoncernforetag.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:ResultatAndelarKoncernforetag',
                        contextRef: 'period0',
                        standardRubrik:
                          'Resultat från andelar i koncernföretag exklusive nedskrivningar',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningEgenPostAbstract.ResultatAndelarKoncernforetag.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:ResultatAndelarKoncernforetag',
                        contextRef: 'period1',
                        standardRubrik:
                          'Resultat från andelar i koncernföretag exklusive nedskrivningar',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    accountRangeRows(
                      accounts,
                      8000,
                      8069,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );

                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'ResultatAndelarIntresseforetagGemensamtStyrda',
                    'row',
                    value(
                      'Resultat från andelar i intresseföretag och gemensamt styrda företag'
                    ),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningEgenPostAbstract.ResultatAndelarIntresseforetagGemensamtStyrda.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:ResultatAndelarIntresseforetagGemensamtStyrda',
                        contextRef: 'period0',
                        standardRubrik:
                          'Resultat från andelar i intresseföretag och gemensamt styrda företag exklusive nedskrivningar',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningEgenPostAbstract.ResultatAndelarIntresseforetagGemensamtStyrda.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:ResultatAndelarIntresseforetagGemensamtStyrda',
                        contextRef: 'period1',
                        standardRubrik:
                          'Resultat från andelar i intresseföretag och gemensamt styrda företag exklusive nedskrivningar',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    accountRangeRows(
                      accounts,
                      8100,
                      8112,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );
                    accountRangeRows(
                      accounts,
                      8114,
                      8117,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );
                    accountRangeRows(
                      accounts,
                      8119,
                      8122,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );
                    accountRangeRows(
                      accounts,
                      8124,
                      8132,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );
                    accountRangeRows(
                      accounts,
                      8134,
                      8169,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );

                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'ResultatOvrigaforetagAgarintresse',
                    'row',
                    value(
                      'Resultat från övriga företag som det finns ett ägarintresse i'
                    ),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningEgenPostAbstract.ResultatOvrigaforetagAgarintresse.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:ResultatOvrigaforetagAgarintresse',
                        contextRef: 'period0',
                        standardRubrik:
                          'Resultat från övriga företag som det finns ett ägarintresse i exklusive nedskrivningar',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningEgenPostAbstract.ResultatOvrigaforetagAgarintresse.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:ResultatOvrigaforetagAgarintresse',
                        contextRef: 'period1',
                        standardRubrik:
                          'Resultat från övriga företag som det finns ett ägarintresse i exklusive nedskrivningar',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    accountRangeRows(
                      accounts,
                      8113,
                      8113,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );
                    accountRangeRows(
                      accounts,
                      8118,
                      8118,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );
                    accountRangeRows(
                      accounts,
                      8123,
                      8123,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );
                    accountRangeRows(
                      accounts,
                      8133,
                      8133,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );

                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'ResultatOvrigaFinansiellaAnlaggningstillgangar',
                    'row',
                    value(
                      'Resultat från övriga värdepapper och fordringar som är anläggningstillgångar'
                    ),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningEgenPostAbstract.ResultatOvrigaFinansiellaAnlaggningstillgangar.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:ResultatOvrigaFinansiellaAnlaggningstillgangar',
                        contextRef: 'period0',
                        standardRubrik:
                          'Resultat från övriga värdepapper och fordringar som är anläggningstillgångar exklusive nedskrivningar',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningEgenPostAbstract.ResultatOvrigaFinansiellaAnlaggningstillgangar.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:ResultatOvrigaFinansiellaAnlaggningstillgangar',
                        contextRef: 'period1',
                        standardRubrik:
                          'Resultat från övriga värdepapper och fordringar som är anläggningstillgångar exklusive nedskrivningar',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    accountRangeRows(
                      accounts,
                      8200,
                      8269,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );

                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'OvrigaRanteintakterLiknandeResultatposter',
                    'row',
                    value('Övriga ränteintäkter och liknande resultatposter'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningEgenPostAbstract.OvrigaRanteintakterLiknandeResultatposter.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:OvrigaRanteintakterLiknandeResultatposter',
                        contextRef: 'period0',
                        standardRubrik:
                          'Övriga ränteintäkter och liknande resultatposter exklusive nedskrivningar',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningEgenPostAbstract.OvrigaRanteintakterLiknandeResultatposter.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:OvrigaRanteintakterLiknandeResultatposter',
                        contextRef: 'period1',
                        standardRubrik:
                          'Övriga ränteintäkter och liknande resultatposter exklusive nedskrivningar',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    accountRangeRows(
                      accounts,
                      8300,
                      8399,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );

                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'NedskrivningarFinansiellaAnlaggningstillgangarKortfristigaPlaceringar',
                    'row',
                    value(
                      'Nedskrivningar av finansiella anläggningstillgångar och kortfristiga placeringar'
                    ),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningEgenPostAbstract.NedskrivningarFinansiellaAnlaggningstillgangarKortfristigaPlaceringar.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:NedskrivningarFinansiellaAnlaggningstillgangarKortfristigaPlaceringar',
                        contextRef: 'period0',
                        standardRubrik:
                          'Nedskrivningar av finansiella anläggningstillgångar och kortfristiga placeringar',
                        saldo: 'debit',
                        negateValue: true,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningEgenPostAbstract.NedskrivningarFinansiellaAnlaggningstillgangarKortfristigaPlaceringar.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:NedskrivningarFinansiellaAnlaggningstillgangarKortfristigaPlaceringar',
                        contextRef: 'period1',
                        standardRubrik:
                          'Nedskrivningar av finansiella anläggningstillgångar och kortfristiga placeringar',
                        saldo: 'debit',
                        negateValue: true,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    accountRangeRows(
                      accounts,
                      8070,
                      8099,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );
                    accountRangeRows(
                      accounts,
                      8170,
                      8199,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );
                    accountRangeRows(
                      accounts,
                      8270,
                      8299,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );

                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'RantekostnaderLiknandeResultatposterEgenPost',
                    'row',
                    value('Räntekostnader och liknande resultatposter'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningEgenPostAbstract.RantekostnaderLiknandeResultatposterEgenPost.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:RantekostnaderLiknandeResultatposter',
                        contextRef: 'period0',
                        standardRubrik:
                          'Räntekostnader och liknande resultatposter',
                        saldo: 'debit',
                        negateValue: true,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningEgenPostAbstract.RantekostnaderLiknandeResultatposterEgenPost.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:RantekostnaderLiknandeResultatposter',
                        contextRef: 'period1',
                        standardRubrik:
                          'Räntekostnader och liknande resultatposter',
                        saldo: 'debit',
                        negateValue: true,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    accountRangeRows(
                      accounts,
                      8400,
                      8799,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );

                    return rows.build();
                  });
                return rows.build();
              });
            rows
              .addRowActive(ref(not(id('settings.isNedskrivningEgenPost'))))
              .addRowWithType(
                'FinansiellaPosterNedskrivningRespektivePostAbstract',
                'header',
                value(
                  'Resultat från finansiella poster - med nedskrivningar i respektive post'
                ),
                refs(),
                undefined,
                undefined
              )
              .addSubRows((rows) => {
                rows
                  .addRowWithType(
                    'ResultatAndelarKoncernforetagInklNedskrivning',
                    'row',
                    value('Resultat från andelar i koncernföretag'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningRespektivePostAbstract.ResultatAndelarKoncernforetagInklNedskrivning.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:ResultatAndelarKoncernforetagInklNedskrivning',
                        contextRef: 'period0',
                        standardRubrik:
                          'Resultat från andelar i koncernföretag inklusive nedskrivningar',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningRespektivePostAbstract.ResultatAndelarKoncernforetagInklNedskrivning.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:ResultatAndelarKoncernforetagInklNedskrivning',
                        contextRef: 'period1',
                        standardRubrik:
                          'Resultat från andelar i koncernföretag inklusive nedskrivningar',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    accountRangeRows(
                      accounts,
                      8000,
                      8069,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );
                    accountRangeRows(
                      accounts,
                      8070,
                      8099,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );

                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'ResultatAndelarIntresseforetagGemensamtStyrdaInklNedskrivning',
                    'row',
                    value(
                      'Resultat från andelar i intresseföretag och gemensamt styrda företag'
                    ),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningRespektivePostAbstract.ResultatAndelarIntresseforetagGemensamtStyrdaInklNedskrivning.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:ResultatAndelarIntresseforetagGemensamtStyrdaInklNedskrivning',
                        contextRef: 'period0',
                        standardRubrik:
                          'Resultat från andelar i intresseföretag och gemensamt styrda företag inklusive nedskrivningar',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningRespektivePostAbstract.ResultatAndelarIntresseforetagGemensamtStyrdaInklNedskrivning.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:ResultatAndelarIntresseforetagGemensamtStyrdaInklNedskrivning',
                        contextRef: 'period1',
                        standardRubrik:
                          'Resultat från andelar i intresseföretag och gemensamt styrda företag inklusive nedskrivningar',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    accountRangeRows(
                      accounts,
                      8100,
                      8112,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );
                    accountRangeRows(
                      accounts,
                      8114,
                      8117,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );
                    accountRangeRows(
                      accounts,
                      8119,
                      8122,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );
                    accountRangeRows(
                      accounts,
                      8124,
                      8169,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );
                    accountRangeRows(
                      accounts,
                      8170,
                      8199,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );

                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'ResultatOvrigaforetagAgarintresseInklNedskrivning',
                    'row',
                    value(
                      'Resultat från övriga företag som det finns ett ägarintresse i'
                    ),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningRespektivePostAbstract.ResultatOvrigaforetagAgarintresseInklNedskrivning.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:ResultatOvrigaforetagAgarintresseInklNedskrivning',
                        contextRef: 'period0',
                        standardRubrik:
                          'Resultat från övriga företag som det finns ett ägarintresse i inklusive nedskrivningar',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningRespektivePostAbstract.ResultatOvrigaforetagAgarintresseInklNedskrivning.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:ResultatOvrigaforetagAgarintresseInklNedskrivning',
                        contextRef: 'period1',
                        standardRubrik:
                          'Resultat från övriga företag som det finns ett ägarintresse i inklusive nedskrivningar',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    accountRangeRows(
                      accounts,
                      8113,
                      8113,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );
                    accountRangeRows(
                      accounts,
                      8118,
                      8118,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );
                    accountRangeRows(
                      accounts,
                      8123,
                      8123,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );
                    accountRangeRows(
                      accounts,
                      8270,
                      8299,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );

                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'ResultatOvrigaFinansiellaAnlaggningstillgangarInklNedskrivning',
                    'row',
                    value(
                      'Resultat från övriga värdepapper och fordringar som är anläggningstillgångar'
                    ),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningRespektivePostAbstract.ResultatOvrigaFinansiellaAnlaggningstillgangarInklNedskrivning.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:ResultatOvrigaFinansiellaAnlaggningstillgangarInklNedskrivning',
                        contextRef: 'period0',
                        standardRubrik:
                          'Resultat från övriga värdepapper och fordringar som är anläggningstillgångar inklusive nedskrivningar',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningRespektivePostAbstract.ResultatOvrigaFinansiellaAnlaggningstillgangarInklNedskrivning.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:ResultatOvrigaFinansiellaAnlaggningstillgangarInklNedskrivning',
                        contextRef: 'period1',
                        standardRubrik:
                          'Resultat från övriga värdepapper och fordringar som är anläggningstillgångar inklusive nedskrivningar',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    accountRangeRows(
                      accounts,
                      8200,
                      8269,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );

                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'OvrigaRanteintakterLiknandeResultatposterInklNedskrivning',
                    'row',
                    value('Övriga ränteintäkter och liknande resultatposter'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningRespektivePostAbstract.OvrigaRanteintakterLiknandeResultatposterInklNedskrivning.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:OvrigaRanteintakterLiknandeResultatposterInklNedskrivning',
                        contextRef: 'period0',
                        standardRubrik:
                          'Övriga ränteintäkter och liknande resultatposter inklusive nedskrivningar',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningRespektivePostAbstract.OvrigaRanteintakterLiknandeResultatposterInklNedskrivning.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:OvrigaRanteintakterLiknandeResultatposterInklNedskrivning',
                        contextRef: 'period1',
                        standardRubrik:
                          'Övriga ränteintäkter och liknande resultatposter inklusive nedskrivningar',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    accountRangeRows(
                      accounts,
                      8300,
                      8399,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );

                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'RantekostnaderLiknandeResultatposterRespektivePost',
                    'row',
                    value('Räntekostnader och liknande resultatposter'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningRespektivePostAbstract.RantekostnaderLiknandeResultatposterRespektivePost.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:RantekostnaderLiknandeResultatposter',
                        contextRef: 'period0',
                        standardRubrik:
                          'Räntekostnader och liknande resultatposter',
                        saldo: 'debit',
                        negateValue: true,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningRespektivePostAbstract.RantekostnaderLiknandeResultatposterRespektivePost.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:RantekostnaderLiknandeResultatposter',
                        contextRef: 'period1',
                        standardRubrik:
                          'Räntekostnader och liknande resultatposter',
                        saldo: 'debit',
                        negateValue: true,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    accountRangeRows(
                      accounts,
                      8400,
                      8799,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );

                    return rows.build();
                  });
                return rows.build();
              });
            return rows.build();
          });
        rows
          .addRowWithType(
            'FinansiellaPoster',
            'sum',
            value('Summa resultat från finansiella poster'),
            undefined,
            ixbrlCell(
              ref(
                ifOrElse(
                  id('settings.isNedskrivningEgenPost'),
                  sumAllowNull(
                    id(
                      'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningEgenPostAbstract.*.year'
                    )
                  ),
                  sumAllowNull(
                    id(
                      'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningRespektivePostAbstract.*.year'
                    )
                  )
                )
              ),
              {
                type: 'monetaryItemType',
                name: 'se-gen-base:FinansiellaPoster',
                contextRef: 'period0',
                standardRubrik: 'Resultat från finansiella poster',
                saldo: 'credit',
                negateValue: false,
              }
            ),
            ixbrlCell(
              ref(
                ifOrElse(
                  id('settings.isNedskrivningEgenPost'),
                  sumAllowNull(
                    id(
                      'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningEgenPostAbstract.*.previousYear'
                    )
                  ),
                  sumAllowNull(
                    id(
                      'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningRespektivePostAbstract.*.previousYear'
                    )
                  )
                )
              ),
              {
                type: 'monetaryItemType',
                name: 'se-gen-base:FinansiellaPoster',
                contextRef: 'period1',
                standardRubrik: 'Resultat från finansiella poster',
                saldo: 'credit',
                negateValue: false,
              }
            )
          )
          .newRowTemplateWithType(
            'account',
            label('$label'),
            undefined,
            ref(or(account('$ub', accountModifier, 'year'), 0)),
            ref(or(account('$ib', accountModifier, 'previousYear'), 0))
          )
          .addSubRows((rows) => {
            return rows.build();
          });
        rows
          .addRowWithType(
            'ResultatEfterFinansiellaPoster',
            'alwaysDisplaySum',
            value('Resultat efter finansiella poster'),
            undefined,
            ixbrlCell(
              ref(
                sumAllowNull(
                  id('incomeStatement.section.table.Rorelseresultat.year'),
                  id('incomeStatement.section.table.FinansiellaPoster.year')
                )
              ),
              {
                type: 'monetaryItemType',
                name: 'se-gen-base:ResultatEfterFinansiellaPoster',
                contextRef: 'period0',
                standardRubrik: 'Resultat efter finansiella poster',
                saldo: 'credit',
                negateValue: false,
              }
            ),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'incomeStatement.section.table.Rorelseresultat.previousYear'
                  ),
                  id(
                    'incomeStatement.section.table.FinansiellaPoster.previousYear'
                  )
                )
              ),
              {
                type: 'monetaryItemType',
                name: 'se-gen-base:ResultatEfterFinansiellaPoster',
                contextRef: 'period1',
                standardRubrik: 'Resultat efter finansiella poster',
                saldo: 'credit',
                negateValue: false,
              }
            )
          )
          .newRowTemplateWithType(
            'account',
            label('$label'),
            undefined,
            ref(or(account('$ub', accountModifier, 'year'), 0)),
            ref(or(account('$ib', accountModifier, 'previousYear'), 0))
          )
          .addSubRows((rows) => {
            return rows.build();
          });
        rows
          .addRowWithType(
            'BokslutsdispositionerAbstract',
            'header',
            value('Bokslutsdispositioner'),
            refs(),
            undefined,
            undefined
          )
          .addSubRows((rows) => {
            rows
              .addRowWithType(
                'ErhallnaKoncernbidrag',
                'row',
                value('Erhållna koncernbidrag'),
                refs(),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'incomeStatement.section.table.BokslutsdispositionerAbstract.ErhallnaKoncernbidrag.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:ErhallnaKoncernbidrag',
                    contextRef: 'period0',
                    standardRubrik: 'Erhållna koncernbidrag',
                    saldo: 'credit',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'incomeStatement.section.table.BokslutsdispositionerAbstract.ErhallnaKoncernbidrag.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:ErhallnaKoncernbidrag',
                    contextRef: 'period1',
                    standardRubrik: 'Erhållna koncernbidrag',
                    saldo: 'credit',
                    negateValue: false,
                  }
                )
              ) // <<< end row_empty_value ---
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(
                  multiply(-1, or(account('$ub', accountModifier, 'year'), 0))
                ),
                ref(
                  multiply(
                    -1,
                    or(account('$ib', accountModifier, 'previousYear'), 0)
                  )
                )
              )
              .addSubRows((rows) => {
                accountRangeRows(
                  accounts,
                  8820,
                  8829,
                  rows,
                  true,
                  'year',
                  'previousYear',
                  accountModifier
                );

                return rows.build();
              });
            rows
              .addRowWithType(
                'LamnadeKoncernbidrag',
                'row',
                value('Lämnade koncernbidrag'),
                refs(),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'incomeStatement.section.table.BokslutsdispositionerAbstract.LamnadeKoncernbidrag.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:LamnadeKoncernbidrag',
                    contextRef: 'period0',
                    standardRubrik: 'Lämnade koncernbidrag',
                    saldo: 'debit',
                    negateValue: true,
                  }
                ),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'incomeStatement.section.table.BokslutsdispositionerAbstract.LamnadeKoncernbidrag.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:LamnadeKoncernbidrag',
                    contextRef: 'period1',
                    standardRubrik: 'Lämnade koncernbidrag',
                    saldo: 'debit',
                    negateValue: true,
                  }
                )
              ) // <<< end row_empty_value ---
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(
                  multiply(-1, or(account('$ub', accountModifier, 'year'), 0))
                ),
                ref(
                  multiply(
                    -1,
                    or(account('$ib', accountModifier, 'previousYear'), 0)
                  )
                )
              )
              .addSubRows((rows) => {
                accountRangeRows(
                  accounts,
                  8830,
                  8839,
                  rows,
                  true,
                  'year',
                  'previousYear',
                  accountModifier
                );

                return rows.build();
              });
            rows
              .addRowWithType(
                'ForandringPeriodiseringsfond',
                'row',
                value('Förändring av periodiseringsfonder'),
                refs(),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'incomeStatement.section.table.BokslutsdispositionerAbstract.ForandringPeriodiseringsfond.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:ForandringPeriodiseringsfond',
                    contextRef: 'period0',
                    standardRubrik: 'Förändring av periodiseringsfonder',
                    saldo: 'credit',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'incomeStatement.section.table.BokslutsdispositionerAbstract.ForandringPeriodiseringsfond.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:ForandringPeriodiseringsfond',
                    contextRef: 'period1',
                    standardRubrik: 'Förändring av periodiseringsfonder',
                    saldo: 'credit',
                    negateValue: false,
                  }
                )
              ) // <<< end row_empty_value ---
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(
                  multiply(-1, or(account('$ub', accountModifier, 'year'), 0))
                ),
                ref(
                  multiply(
                    -1,
                    or(account('$ib', accountModifier, 'previousYear'), 0)
                  )
                )
              )
              .addSubRows((rows) => {
                accountRangeRows(
                  accounts,
                  8800,
                  8819,
                  rows,
                  true,
                  'year',
                  'previousYear',
                  accountModifier
                );

                return rows.build();
              });
            rows
              .addRowWithType(
                'ForandringOveravskrivningar',
                'row',
                value('Förändring av överavskrivningar'),
                refs(),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'incomeStatement.section.table.BokslutsdispositionerAbstract.ForandringOveravskrivningar.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:ForandringOveravskrivningar',
                    contextRef: 'period0',
                    standardRubrik: 'Förändring av överavskrivningar',
                    saldo: 'credit',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'incomeStatement.section.table.BokslutsdispositionerAbstract.ForandringOveravskrivningar.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:ForandringOveravskrivningar',
                    contextRef: 'period1',
                    standardRubrik: 'Förändring av överavskrivningar',
                    saldo: 'credit',
                    negateValue: false,
                  }
                )
              ) // <<< end row_empty_value ---
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(
                  multiply(-1, or(account('$ub', accountModifier, 'year'), 0))
                ),
                ref(
                  multiply(
                    -1,
                    or(account('$ib', accountModifier, 'previousYear'), 0)
                  )
                )
              )
              .addSubRows((rows) => {
                accountRangeRows(
                  accounts,
                  8850,
                  8859,
                  rows,
                  true,
                  'year',
                  'previousYear',
                  accountModifier
                );

                return rows.build();
              });
            rows
              .addRowWithType(
                'ForandringErsattningsfond',
                'row',
                value('Förändring av ersättningsfonder'),
                refs(),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'incomeStatement.section.table.BokslutsdispositionerAbstract.ForandringErsattningsfond.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:ForandringErsattningsfond',
                    contextRef: 'period0',
                    standardRubrik: 'Förändring av ersättningsfonder',
                    saldo: 'credit',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'incomeStatement.section.table.BokslutsdispositionerAbstract.ForandringErsattningsfond.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:ForandringErsattningsfond',
                    contextRef: 'period1',
                    standardRubrik: 'Förändring av ersättningsfonder',
                    saldo: 'credit',
                    negateValue: false,
                  }
                )
              ) // <<< end row_empty_value ---
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(
                  multiply(-1, or(account('$ub', accountModifier, 'year'), 0))
                ),
                ref(
                  multiply(
                    -1,
                    or(account('$ib', accountModifier, 'previousYear'), 0)
                  )
                )
              )
              .addSubRows((rows) => {
                accountRangeRows(
                  accounts,
                  8860,
                  8869,
                  rows,
                  true,
                  'year',
                  'previousYear',
                  accountModifier
                );

                return rows.build();
              });
            rows
              .addRowWithType(
                'AndraBokslutsdispositioner',
                'row',
                value('Andra bokslutsdispositioner'),
                refs(),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'incomeStatement.section.table.BokslutsdispositionerAbstract.AndraBokslutsdispositioner.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:AndraBokslutsdispositioner',
                    contextRef: 'period0',
                    standardRubrik:
                      'Andra bokslutsdispositioner än koncernbidrag, förändring av periodiseringsfonder, förändring av överavskrivningar och förändring av ersättningsfonder',
                    saldo: 'credit',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'incomeStatement.section.table.BokslutsdispositionerAbstract.AndraBokslutsdispositioner.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:AndraBokslutsdispositioner',
                    contextRef: 'period1',
                    standardRubrik:
                      'Andra bokslutsdispositioner än koncernbidrag, förändring av periodiseringsfonder, förändring av överavskrivningar och förändring av ersättningsfonder',
                    saldo: 'credit',
                    negateValue: false,
                  }
                )
              ) // <<< end row_empty_value ---
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(
                  multiply(-1, or(account('$ub', accountModifier, 'year'), 0))
                ),
                ref(
                  multiply(
                    -1,
                    or(account('$ib', accountModifier, 'previousYear'), 0)
                  )
                )
              )
              .addSubRows((rows) => {
                accountRangeRows(
                  accounts,
                  8840,
                  8849,
                  rows,
                  true,
                  'year',
                  'previousYear',
                  accountModifier
                );
                accountRangeRows(
                  accounts,
                  8870,
                  8899,
                  rows,
                  true,
                  'year',
                  'previousYear',
                  accountModifier
                );

                return rows.build();
              });
            return rows.build();
          });
        rows
          .addRowWithType(
            'Bokslutsdispositioner',
            'sum',
            value('Summa bokslutsdispositioner'),
            undefined,
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'incomeStatement.section.table.BokslutsdispositionerAbstract.*.year'
                  )
                )
              ),
              {
                type: 'monetaryItemType',
                name: 'se-gen-base:Bokslutsdispositioner',
                contextRef: 'period0',
                standardRubrik: 'Bokslutsdispositioner',
                saldo: 'credit',
                negateValue: false,
              }
            ),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'incomeStatement.section.table.BokslutsdispositionerAbstract.*.previousYear'
                  )
                )
              ),
              {
                type: 'monetaryItemType',
                name: 'se-gen-base:Bokslutsdispositioner',
                contextRef: 'period1',
                standardRubrik: 'Bokslutsdispositioner',
                saldo: 'credit',
                negateValue: false,
              }
            )
          )
          .newRowTemplateWithType(
            'account',
            label('$label'),
            undefined,
            ref(or(account('$ub', accountModifier, 'year'), 0)),
            ref(or(account('$ib', accountModifier, 'previousYear'), 0))
          )
          .addSubRows((rows) => {
            return rows.build();
          });
        rows
          .addRowWithType(
            'ResultatForeSkatt',
            'alwaysDisplaySum',
            value('Resultat före skatt'),
            undefined,
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'incomeStatement.section.table.ResultatEfterFinansiellaPoster.year'
                  ),
                  id('incomeStatement.section.table.Bokslutsdispositioner.year')
                )
              ),
              {
                type: 'monetaryItemType',
                name: 'se-gen-base:ResultatForeSkatt',
                contextRef: 'period0',
                standardRubrik: 'Resultat före skatt',
                saldo: 'credit',
                negateValue: false,
              }
            ),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'incomeStatement.section.table.ResultatEfterFinansiellaPoster.previousYear'
                  ),
                  id(
                    'incomeStatement.section.table.Bokslutsdispositioner.previousYear'
                  )
                )
              ),
              {
                type: 'monetaryItemType',
                name: 'se-gen-base:ResultatForeSkatt',
                contextRef: 'period1',
                standardRubrik: 'Resultat före skatt',
                saldo: 'credit',
                negateValue: false,
              }
            )
          )
          .newRowTemplateWithType(
            'account',
            label('$label'),
            undefined,
            ref(or(account('$ub', accountModifier, 'year'), 0)),
            ref(or(account('$ib', accountModifier, 'previousYear'), 0))
          )
          .addSubRows((rows) => {
            return rows.build();
          });
        rows
          .addRowWithType(
            'SkatterAbstract',
            'header',
            value('Skatter'),
            refs(),
            undefined,
            undefined
          )
          .addSubRows((rows) => {
            rows
              .addRowWithType(
                'SkattAretsResultat',
                'row',
                value('Skatt på årets resultat'),
                refs(),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'incomeStatement.section.table.SkatterAbstract.SkattAretsResultat.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:SkattAretsResultat',
                    contextRef: 'period0',
                    standardRubrik: 'Skatt på periodens resultat',
                    saldo: 'debit',
                    negateValue: true,
                  }
                ),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'incomeStatement.section.table.SkatterAbstract.SkattAretsResultat.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:SkattAretsResultat',
                    contextRef: 'period1',
                    standardRubrik: 'Skatt på periodens resultat',
                    saldo: 'debit',
                    negateValue: true,
                  }
                )
              ) // <<< end row_empty_value ---
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(
                  multiply(-1, or(account('$ub', accountModifier, 'year'), 0))
                ),
                ref(
                  multiply(
                    -1,
                    or(account('$ib', accountModifier, 'previousYear'), 0)
                  )
                )
              )
              .addSubRows((rows) => {
                accountRangeRows(
                  accounts,
                  8900,
                  8919,
                  rows,
                  true,
                  'year',
                  'previousYear',
                  accountModifier
                );

                return rows.build();
              });
            rows
              .addRowWithType(
                'OvrigaSkatter',
                'row',
                value('Övriga skatter'),
                refs(),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'incomeStatement.section.table.SkatterAbstract.OvrigaSkatter.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:OvrigaSkatter',
                    contextRef: 'period0',
                    standardRubrik: 'Övriga skattekostnader',
                    saldo: 'debit',
                    negateValue: true,
                  }
                ),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'incomeStatement.section.table.SkatterAbstract.OvrigaSkatter.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:OvrigaSkatter',
                    contextRef: 'period1',
                    standardRubrik: 'Övriga skattekostnader',
                    saldo: 'debit',
                    negateValue: true,
                  }
                )
              ) // <<< end row_empty_value ---
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(
                  multiply(-1, or(account('$ub', accountModifier, 'year'), 0))
                ),
                ref(
                  multiply(
                    -1,
                    or(account('$ib', accountModifier, 'previousYear'), 0)
                  )
                )
              )
              .addSubRows((rows) => {
                accountRangeRows(
                  accounts,
                  8920,
                  8989,
                  rows,
                  true,
                  'year',
                  'previousYear',
                  accountModifier
                );

                return rows.build();
              });
            return rows.build();
          });
        rows
          .addRowWithType(
            'AretsResultat',
            'sum',
            value('Årets resultat'),
            undefined,
            ixbrlCell(
              ref(
                sumAllowNull(
                  id('incomeStatement.section.table.ResultatForeSkatt.year'),
                  id(
                    'incomeStatement.section.table.SkatterAbstract.SkattAretsResultat.year'
                  ),
                  id(
                    'incomeStatement.section.table.SkatterAbstract.OvrigaSkatter.year'
                  )
                )
              ),
              {
                type: 'monetaryItemType',
                name: 'se-gen-base:AretsResultat',
                contextRef: 'period0',
                standardRubrik: 'Periodens resultat',
                saldo: 'credit',
                negateValue: false,
              }
            ),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'incomeStatement.section.table.ResultatForeSkatt.previousYear'
                  ),
                  id(
                    'incomeStatement.section.table.SkatterAbstract.SkattAretsResultat.previousYear'
                  ),
                  id(
                    'incomeStatement.section.table.SkatterAbstract.OvrigaSkatter.previousYear'
                  )
                )
              ),
              {
                type: 'monetaryItemType',
                name: 'se-gen-base:AretsResultat',
                contextRef: 'period1',
                standardRubrik: 'Periodens resultat',
                saldo: 'credit',
                negateValue: false,
              }
            )
          )
          .newRowTemplateWithType(
            'account',
            label('$label'),
            undefined,
            ref(or(account('$ub', accountModifier, 'year'), 0)),
            ref(or(account('$ib', accountModifier, 'previousYear'), 0))
          )
          .addSubRows((rows) => {
            accountRangeRows(
              accounts,
              8990,
              8999,
              rows,
              false,
              'year',
              'previousYear',
              accountModifier
            );

            return rows.build();
          });
        return rows.build();
      })
      .build(),
  },
});
export const costDividedIncomeStatementK3v2References = (): Record<
  string,
  string
> => ({
  'incomeStatement.Nettoomsattning.year': id(
    'incomeStatement.section.table.RorelseresultatAbstract.RorelsensIntakterLagerforandringarMmAbstract.Nettoomsattning.year'
  ),
  'incomeStatement.Nettoomsattning.previousYear': id(
    'incomeStatement.section.table.RorelseresultatAbstract.RorelsensIntakterLagerforandringarMmAbstract.Nettoomsattning.previousYear'
  ),
  'incomeStatement.ForandringLagerProdukterIArbeteFardigaVarorPagaendeArbetenAnnansRakning.year':
    id(
      'incomeStatement.section.table.RorelseresultatAbstract.RorelsensIntakterLagerforandringarMmAbstract.ForandringLagerProdukterIArbeteFardigaVarorPagaendeArbetenAnnansRakning.year'
    ),
  'incomeStatement.ForandringLagerProdukterIArbeteFardigaVarorPagaendeArbetenAnnansRakning.previousYear':
    id(
      'incomeStatement.section.table.RorelseresultatAbstract.RorelsensIntakterLagerforandringarMmAbstract.ForandringLagerProdukterIArbeteFardigaVarorPagaendeArbetenAnnansRakning.previousYear'
    ),
  'incomeStatement.AktiveratArbeteEgenRakning.year': id(
    'incomeStatement.section.table.RorelseresultatAbstract.RorelsensIntakterLagerforandringarMmAbstract.AktiveratArbeteEgenRakning.year'
  ),
  'incomeStatement.AktiveratArbeteEgenRakning.previousYear': id(
    'incomeStatement.section.table.RorelseresultatAbstract.RorelsensIntakterLagerforandringarMmAbstract.AktiveratArbeteEgenRakning.previousYear'
  ),
  'incomeStatement.OvrigaRorelseintakter.year': id(
    'incomeStatement.section.table.RorelseresultatAbstract.RorelsensIntakterLagerforandringarMmAbstract.OvrigaRorelseintakter.year'
  ),
  'incomeStatement.OvrigaRorelseintakter.previousYear': id(
    'incomeStatement.section.table.RorelseresultatAbstract.RorelsensIntakterLagerforandringarMmAbstract.OvrigaRorelseintakter.previousYear'
  ),
  'incomeStatement.RorelseintakterLagerforandringarMm.year': id(
    'incomeStatement.section.table.RorelseresultatAbstract.RorelseintakterLagerforandringarMm.year'
  ),
  'incomeStatement.RorelseintakterLagerforandringarMm.previousYear': id(
    'incomeStatement.section.table.RorelseresultatAbstract.RorelseintakterLagerforandringarMm.previousYear'
  ),
  'incomeStatement.RavarorFornodenheterKostnader.year': id(
    'incomeStatement.section.table.RorelseresultatAbstract.RorelsekostnaderAbstract.RavarorFornodenheterKostnader.year'
  ),
  'incomeStatement.RavarorFornodenheterKostnader.previousYear': id(
    'incomeStatement.section.table.RorelseresultatAbstract.RorelsekostnaderAbstract.RavarorFornodenheterKostnader.previousYear'
  ),
  'incomeStatement.HandelsvarorKostnader.year': id(
    'incomeStatement.section.table.RorelseresultatAbstract.RorelsekostnaderAbstract.HandelsvarorKostnader.year'
  ),
  'incomeStatement.HandelsvarorKostnader.previousYear': id(
    'incomeStatement.section.table.RorelseresultatAbstract.RorelsekostnaderAbstract.HandelsvarorKostnader.previousYear'
  ),
  'incomeStatement.OvrigaExternaKostnader.year': id(
    'incomeStatement.section.table.RorelseresultatAbstract.RorelsekostnaderAbstract.OvrigaExternaKostnader.year'
  ),
  'incomeStatement.OvrigaExternaKostnader.previousYear': id(
    'incomeStatement.section.table.RorelseresultatAbstract.RorelsekostnaderAbstract.OvrigaExternaKostnader.previousYear'
  ),
  'incomeStatement.Personalkostnader.year': id(
    'incomeStatement.section.table.RorelseresultatAbstract.RorelsekostnaderAbstract.Personalkostnader.year'
  ),
  'incomeStatement.Personalkostnader.previousYear': id(
    'incomeStatement.section.table.RorelseresultatAbstract.RorelsekostnaderAbstract.Personalkostnader.previousYear'
  ),
  'incomeStatement.AvskrivningarNedskrivningarMateriellaImmateriellaAnlaggningstillgangar.year':
    id(
      'incomeStatement.section.table.RorelseresultatAbstract.RorelsekostnaderAbstract.AvskrivningarNedskrivningarMateriellaImmateriellaAnlaggningstillgangar.year'
    ),
  'incomeStatement.AvskrivningarNedskrivningarMateriellaImmateriellaAnlaggningstillgangar.previousYear':
    id(
      'incomeStatement.section.table.RorelseresultatAbstract.RorelsekostnaderAbstract.AvskrivningarNedskrivningarMateriellaImmateriellaAnlaggningstillgangar.previousYear'
    ),
  'incomeStatement.NedskrivningarOmsattningstillgangarUtoverNormalaNedskrivningar.year':
    id(
      'incomeStatement.section.table.RorelseresultatAbstract.RorelsekostnaderAbstract.NedskrivningarOmsattningstillgangarUtoverNormalaNedskrivningar.year'
    ),
  'incomeStatement.NedskrivningarOmsattningstillgangarUtoverNormalaNedskrivningar.previousYear':
    id(
      'incomeStatement.section.table.RorelseresultatAbstract.RorelsekostnaderAbstract.NedskrivningarOmsattningstillgangarUtoverNormalaNedskrivningar.previousYear'
    ),
  'incomeStatement.OvrigaRorelsekostnader.year': id(
    'incomeStatement.section.table.RorelseresultatAbstract.RorelsekostnaderAbstract.OvrigaRorelsekostnader.year'
  ),
  'incomeStatement.OvrigaRorelsekostnader.previousYear': id(
    'incomeStatement.section.table.RorelseresultatAbstract.RorelsekostnaderAbstract.OvrigaRorelsekostnader.previousYear'
  ),
  'incomeStatement.Rorelsekostnader.year': id(
    'incomeStatement.section.table.RorelseresultatAbstract.Rorelsekostnader.year'
  ),
  'incomeStatement.Rorelsekostnader.previousYear': id(
    'incomeStatement.section.table.RorelseresultatAbstract.Rorelsekostnader.previousYear'
  ),
  'incomeStatement.AndelarIntresseforetagGemensamtStyrdaForetagResultat.year':
    id(
      'incomeStatement.section.table.RorelseresultatAbstract.AndelarIntresseforetagGemensamtStyrdaForetagResultat.year'
    ),
  'incomeStatement.AndelarIntresseforetagGemensamtStyrdaForetagResultat.previousYear':
    id(
      'incomeStatement.section.table.RorelseresultatAbstract.AndelarIntresseforetagGemensamtStyrdaForetagResultat.previousYear'
    ),
  'incomeStatement.RealisationsresultatForsaljningIntresseforetagGemensamtStyrdaForetag.year':
    id(
      'incomeStatement.section.table.RorelseresultatAbstract.RealisationsresultatForsaljningIntresseforetagGemensamtStyrdaForetag.year'
    ),
  'incomeStatement.RealisationsresultatForsaljningIntresseforetagGemensamtStyrdaForetag.previousYear':
    id(
      'incomeStatement.section.table.RorelseresultatAbstract.RealisationsresultatForsaljningIntresseforetagGemensamtStyrdaForetag.previousYear'
    ),
  'incomeStatement.Rorelseresultat.year': id(
    'incomeStatement.section.table.Rorelseresultat.year'
  ),
  'incomeStatement.Rorelseresultat.previousYear': id(
    'incomeStatement.section.table.Rorelseresultat.previousYear'
  ),
  'incomeStatement.ResultatAndelarKoncernforetag.year': id(
    'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningEgenPostAbstract.ResultatAndelarKoncernforetag.year'
  ),
  'incomeStatement.ResultatAndelarKoncernforetag.previousYear': id(
    'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningEgenPostAbstract.ResultatAndelarKoncernforetag.previousYear'
  ),
  'incomeStatement.ResultatAndelarIntresseforetagGemensamtStyrda.year': id(
    'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningEgenPostAbstract.ResultatAndelarIntresseforetagGemensamtStyrda.year'
  ),
  'incomeStatement.ResultatAndelarIntresseforetagGemensamtStyrda.previousYear':
    id(
      'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningEgenPostAbstract.ResultatAndelarIntresseforetagGemensamtStyrda.previousYear'
    ),
  'incomeStatement.ResultatOvrigaforetagAgarintresse.year': id(
    'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningEgenPostAbstract.ResultatOvrigaforetagAgarintresse.year'
  ),
  'incomeStatement.ResultatOvrigaforetagAgarintresse.previousYear': id(
    'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningEgenPostAbstract.ResultatOvrigaforetagAgarintresse.previousYear'
  ),
  'incomeStatement.ResultatOvrigaFinansiellaAnlaggningstillgangar.year': id(
    'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningEgenPostAbstract.ResultatOvrigaFinansiellaAnlaggningstillgangar.year'
  ),
  'incomeStatement.ResultatOvrigaFinansiellaAnlaggningstillgangar.previousYear':
    id(
      'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningEgenPostAbstract.ResultatOvrigaFinansiellaAnlaggningstillgangar.previousYear'
    ),
  'incomeStatement.OvrigaRanteintakterLiknandeResultatposter.year': id(
    'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningEgenPostAbstract.OvrigaRanteintakterLiknandeResultatposter.year'
  ),
  'incomeStatement.OvrigaRanteintakterLiknandeResultatposter.previousYear': id(
    'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningEgenPostAbstract.OvrigaRanteintakterLiknandeResultatposter.previousYear'
  ),
  'incomeStatement.NedskrivningarFinansiellaAnlaggningstillgangarKortfristigaPlaceringar.year':
    id(
      'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningEgenPostAbstract.NedskrivningarFinansiellaAnlaggningstillgangarKortfristigaPlaceringar.year'
    ),
  'incomeStatement.NedskrivningarFinansiellaAnlaggningstillgangarKortfristigaPlaceringar.previousYear':
    id(
      'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningEgenPostAbstract.NedskrivningarFinansiellaAnlaggningstillgangarKortfristigaPlaceringar.previousYear'
    ),
  'incomeStatement.RantekostnaderLiknandeResultatposterEgenPost.year': id(
    'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningEgenPostAbstract.RantekostnaderLiknandeResultatposterEgenPost.year'
  ),
  'incomeStatement.RantekostnaderLiknandeResultatposterEgenPost.previousYear':
    id(
      'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningEgenPostAbstract.RantekostnaderLiknandeResultatposterEgenPost.previousYear'
    ),
  'incomeStatement.ResultatAndelarKoncernforetagInklNedskrivning.year': id(
    'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningRespektivePostAbstract.ResultatAndelarKoncernforetagInklNedskrivning.year'
  ),
  'incomeStatement.ResultatAndelarKoncernforetagInklNedskrivning.previousYear':
    id(
      'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningRespektivePostAbstract.ResultatAndelarKoncernforetagInklNedskrivning.previousYear'
    ),
  'incomeStatement.ResultatAndelarIntresseforetagGemensamtStyrdaInklNedskrivning.year':
    id(
      'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningRespektivePostAbstract.ResultatAndelarIntresseforetagGemensamtStyrdaInklNedskrivning.year'
    ),
  'incomeStatement.ResultatAndelarIntresseforetagGemensamtStyrdaInklNedskrivning.previousYear':
    id(
      'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningRespektivePostAbstract.ResultatAndelarIntresseforetagGemensamtStyrdaInklNedskrivning.previousYear'
    ),
  'incomeStatement.ResultatOvrigaforetagAgarintresseInklNedskrivning.year': id(
    'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningRespektivePostAbstract.ResultatOvrigaforetagAgarintresseInklNedskrivning.year'
  ),
  'incomeStatement.ResultatOvrigaforetagAgarintresseInklNedskrivning.previousYear':
    id(
      'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningRespektivePostAbstract.ResultatOvrigaforetagAgarintresseInklNedskrivning.previousYear'
    ),
  'incomeStatement.ResultatOvrigaFinansiellaAnlaggningstillgangarInklNedskrivning.year':
    id(
      'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningRespektivePostAbstract.ResultatOvrigaFinansiellaAnlaggningstillgangarInklNedskrivning.year'
    ),
  'incomeStatement.ResultatOvrigaFinansiellaAnlaggningstillgangarInklNedskrivning.previousYear':
    id(
      'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningRespektivePostAbstract.ResultatOvrigaFinansiellaAnlaggningstillgangarInklNedskrivning.previousYear'
    ),
  'incomeStatement.OvrigaRanteintakterLiknandeResultatposterInklNedskrivning.year':
    id(
      'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningRespektivePostAbstract.OvrigaRanteintakterLiknandeResultatposterInklNedskrivning.year'
    ),
  'incomeStatement.OvrigaRanteintakterLiknandeResultatposterInklNedskrivning.previousYear':
    id(
      'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningRespektivePostAbstract.OvrigaRanteintakterLiknandeResultatposterInklNedskrivning.previousYear'
    ),
  'incomeStatement.RantekostnaderLiknandeResultatposterRespektivePost.year': id(
    'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningRespektivePostAbstract.RantekostnaderLiknandeResultatposterRespektivePost.year'
  ),
  'incomeStatement.RantekostnaderLiknandeResultatposterRespektivePost.previousYear':
    id(
      'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningRespektivePostAbstract.RantekostnaderLiknandeResultatposterRespektivePost.previousYear'
    ),
  'incomeStatement.FinansiellaPoster.year': id(
    'incomeStatement.section.table.FinansiellaPoster.year'
  ),
  'incomeStatement.FinansiellaPoster.previousYear': id(
    'incomeStatement.section.table.FinansiellaPoster.previousYear'
  ),
  'incomeStatement.ResultatEfterFinansiellaPoster.year': id(
    'incomeStatement.section.table.ResultatEfterFinansiellaPoster.year'
  ),
  'incomeStatement.ResultatEfterFinansiellaPoster.previousYear': id(
    'incomeStatement.section.table.ResultatEfterFinansiellaPoster.previousYear'
  ),
  'incomeStatement.ErhallnaKoncernbidrag.year': id(
    'incomeStatement.section.table.BokslutsdispositionerAbstract.ErhallnaKoncernbidrag.year'
  ),
  'incomeStatement.ErhallnaKoncernbidrag.previousYear': id(
    'incomeStatement.section.table.BokslutsdispositionerAbstract.ErhallnaKoncernbidrag.previousYear'
  ),
  'incomeStatement.LamnadeKoncernbidrag.year': id(
    'incomeStatement.section.table.BokslutsdispositionerAbstract.LamnadeKoncernbidrag.year'
  ),
  'incomeStatement.LamnadeKoncernbidrag.previousYear': id(
    'incomeStatement.section.table.BokslutsdispositionerAbstract.LamnadeKoncernbidrag.previousYear'
  ),
  'incomeStatement.ForandringPeriodiseringsfond.year': id(
    'incomeStatement.section.table.BokslutsdispositionerAbstract.ForandringPeriodiseringsfond.year'
  ),
  'incomeStatement.ForandringPeriodiseringsfond.previousYear': id(
    'incomeStatement.section.table.BokslutsdispositionerAbstract.ForandringPeriodiseringsfond.previousYear'
  ),
  'incomeStatement.ForandringOveravskrivningar.year': id(
    'incomeStatement.section.table.BokslutsdispositionerAbstract.ForandringOveravskrivningar.year'
  ),
  'incomeStatement.ForandringOveravskrivningar.previousYear': id(
    'incomeStatement.section.table.BokslutsdispositionerAbstract.ForandringOveravskrivningar.previousYear'
  ),
  'incomeStatement.ForandringErsattningsfond.year': id(
    'incomeStatement.section.table.BokslutsdispositionerAbstract.ForandringErsattningsfond.year'
  ),
  'incomeStatement.ForandringErsattningsfond.previousYear': id(
    'incomeStatement.section.table.BokslutsdispositionerAbstract.ForandringErsattningsfond.previousYear'
  ),
  'incomeStatement.AndraBokslutsdispositioner.year': id(
    'incomeStatement.section.table.BokslutsdispositionerAbstract.AndraBokslutsdispositioner.year'
  ),
  'incomeStatement.AndraBokslutsdispositioner.previousYear': id(
    'incomeStatement.section.table.BokslutsdispositionerAbstract.AndraBokslutsdispositioner.previousYear'
  ),
  'incomeStatement.Bokslutsdispositioner.year': id(
    'incomeStatement.section.table.Bokslutsdispositioner.year'
  ),
  'incomeStatement.Bokslutsdispositioner.previousYear': id(
    'incomeStatement.section.table.Bokslutsdispositioner.previousYear'
  ),
  'incomeStatement.ResultatForeSkatt.year': id(
    'incomeStatement.section.table.ResultatForeSkatt.year'
  ),
  'incomeStatement.ResultatForeSkatt.previousYear': id(
    'incomeStatement.section.table.ResultatForeSkatt.previousYear'
  ),
  'incomeStatement.SkattAretsResultat.year': id(
    'incomeStatement.section.table.SkatterAbstract.SkattAretsResultat.year'
  ),
  'incomeStatement.SkattAretsResultat.previousYear': id(
    'incomeStatement.section.table.SkatterAbstract.SkattAretsResultat.previousYear'
  ),
  'incomeStatement.OvrigaSkatter.year': id(
    'incomeStatement.section.table.SkatterAbstract.OvrigaSkatter.year'
  ),
  'incomeStatement.OvrigaSkatter.previousYear': id(
    'incomeStatement.section.table.SkatterAbstract.OvrigaSkatter.previousYear'
  ),
  'incomeStatement.AretsResultat.year': id(
    'incomeStatement.section.table.AretsResultat.year'
  ),
  'incomeStatement.AretsResultat.previousYear': id(
    'incomeStatement.section.table.AretsResultat.previousYear'
  ),
});

import { table, value } from '@agoy/document';
import { NoteOneTableSection } from '../../../../common';
import { field } from '../../../../common/utils/util';

export const getNote39 = (
  noteLabel: any,
  documentTypeVersion = '1'
): NoteOneTableSection => {
  return {
    active: false,
    number: value(undefined),
    name: value('Bokslutsrapport'),
    table: table('notes.note39.table', { id: 'label', label: '' })
      .addRows((rows) =>
        rows
          .addRowActive(false)
          .addRow(
            '1',
            noteLabel(
              'För denna årsredovisning har en bokslutsrapport upprättats av (Förnamn Efternamn, Redovisningsbyrån AB), som är auktoriserad redovisningskonsult genom medlemskap i (FAR/SRF)'
            )
          )
          .build()
      )
      .build(),
    commentLabel: field('Kommentar'),
    comment: field(''),
    group: value(undefined),
    section: value(undefined),
    validForReportType: {
      k2: true,
      k3: documentTypeVersion === '1',
    },
  };
};

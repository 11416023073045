import React from 'react';
import { Box } from '@material-ui/core';
import { NoteTwoTableSection } from '@agoy/annual-report-document';
import { Field, numberValue, stringValue } from '@agoy/document';
import Comment from '../../UI/Comment';
import { FieldSectionTitle } from '../../UI/FieldSectionTitle';
import Header from '../../UI/Header';
import { Note } from '../../UI/Note';
import NoteTable from '../../UI/NoteTable';

type Note80Props = {
  title: string;
  commentLabel: Field;
  hasPreviousYear?: boolean;
} & NoteTwoTableSection;

const Note80 = ({
  active,
  number,
  table,
  secondTable,
  comment,
  title,
  commentLabel,
  hasPreviousYear = false,
}: Note80Props): JSX.Element | null => {
  if (!active) return null;

  return (
    <Note id="note80">
      <Header noteId="note80" noteNumber={numberValue(number)} title={title} />

      <FieldSectionTitle noteId="note80" id="acquiredCompanyDetails" />

      <Box mt={1} mb={1}>
        {/* It's actually seven columns, but this one renders all columns */}
        <NoteTable
          hasPreviousYear={hasPreviousYear}
          table={table}
          tableId="notes.note80.table"
        />
      </Box>

      <FieldSectionTitle noteId="note80" id="acquiredCompanyBR" />

      <Box mt={1} mb={1}>
        <NoteTable
          hasPreviousYear={hasPreviousYear}
          table={secondTable}
          tableId="notes.note80.secondTable"
        />
      </Box>

      {/* Comment */}
      <Comment
        field={comment}
        value={stringValue(comment) || ''}
        labelValue={stringValue(commentLabel) || ''}
      />
    </Note>
  );
};

export default Note80;

import React, { createContext, useContext, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { useSelector } from 'redux/reducers';
import {
  distinctUntilChanged,
  filter,
  map,
  Observable,
  ReplaySubject,
  Subject,
} from 'rxjs';
import { CustomersState } from '_clients/redux/customers/reducer';
import { NotificationContext } from '_shared/services/Notifications/NotificationsContext';
import { NotificationService } from '_shared/services/Notifications/types';
import { ClientDataLayerImpl } from './ClientDataLayer';
import { ClientDataLayer } from './types';

type ClientDataLayerContextType = {
  get(clientId: string): ClientDataLayer;
  release(clientDataLayer: ClientDataLayer): void;
};

/**
 * Implementation of a ClientDataLayerContext
 *
 * It enables sharing and reusing a ClientDataLayer
 */
class ClientDataLayerContextImpl implements ClientDataLayerContextType {
  notificationService: NotificationService | null;

  clientData: Observable<CustomersState>;

  dispatch: Dispatch;

  clients: Map<string, { service: ClientDataLayerImpl; users: number }> =
    new Map();

  constructor(
    notificationService: NotificationService | null,
    dispatch: Dispatch,
    clientData: Observable<CustomersState>
  ) {
    this.notificationService = notificationService;
    this.dispatch = dispatch;
    this.clientData = clientData;
  }

  /**
   * Getter for a ClientDataLayer
   *
   * The prefered way of getting a ClientDataLayer is through the `withClientDataLayer`.
   * There are situations where that can't be used, like while creating a new client,
   * then it can be used directly.
   *
   * Note: The returned ClientDataLayer must be released when the caller
   * is done with it.
   *
   * @param clientId The clientId the ClientDataLayer is created for
   * @returns An existing or new ClientDataLayer
   */
  get(clientId: string): ClientDataLayer {
    const existing = this.clients.get(clientId);
    if (existing) {
      existing.users += 1;
      return existing.service;
    }

    const client = {
      service: new ClientDataLayerImpl(
        clientId,
        this.clientData.pipe(
          map((value) => value[clientId]),
          filter((value) => value !== undefined),
          distinctUntilChanged()
        ),
        this.notificationService,
        this.dispatch
      ),
      users: 1,
    };
    this.clients.set(clientId, client);
    return client.service;
  }

  /**
   * Release a ClientDataLayer
   *
   * Must be called once and only once for a ClientDataLayer returned by `get`.
   *
   * @param clientDataLayer
   */
  release(clientDataLayer: ClientDataLayer): void {
    const client = Array.from(this.clients.values()).find(
      (c) => c.service === clientDataLayer
    );
    if (!client) {
      // eslint-disable-next-line no-console
      console.error('Missing client');
    } else {
      client.users -= 1;
      if (client.users === 0) {
        setTimeout(() => {
          if (client.users === 0) {
            client.service.release();
            this.clients.delete(client.service.clientId);
          }
        }, 30000);
      }
    }
  }

  /**
   * Shutdown all.
   */
  releaseAll(): void {
    this.clients.forEach((value) => value.service.release());
    this.clients.clear();
  }
}

/**
 * The ClientDataLayer context
 *
 * Provides a dummy object as default.
 */
export const ClientDataLayerContext = createContext<ClientDataLayerContextType>(
  new ClientDataLayerContextImpl(
    null,
    () => {
      throw new Error('Not initialized');
    },
    new Subject()
  )
);

type ClientDataLayerProviderProps = {
  children: React.ReactNode;
};

export const ClientDataLayerProvider = ({
  children,
}: ClientDataLayerProviderProps) => {
  const dispatch = useDispatch();
  const notificationService = useContext(NotificationContext);
  const clients = useMemo(() => new ReplaySubject<CustomersState>(1), []);

  const customers = useSelector((state) => state.customers);

  useEffect(() => {
    clients.next(customers);
  }, [clients, customers]);

  useEffect(() => {
    return () => {
      clients.complete();
    };
  }, [clients]);

  const context = useMemo(
    () =>
      new ClientDataLayerContextImpl(notificationService, dispatch, clients),
    [notificationService, dispatch, clients]
  );

  useEffect(() => {
    return () => {
      // When we are done with a data layer, release its resources
      context.releaseAll();
    };
  }, [context]);

  return (
    <ClientDataLayerContext.Provider value={context}>
      {children}
    </ClientDataLayerContext.Provider>
  );
};

import { resolveReference } from '@agoy/document';
import { shallowCompare } from './utils/shallowCompare';
import {
  TaxCalculationContext,
  TaxView,
  TransactionRow,
  TaxTable,
} from './types';
import { calculateRow, calculateTaxTable } from './calculateTaxTable';
import { updateContext } from './context';

const createFromContext = (
  context: TaxCalculationContext
): TaxTable<TransactionRow> => {
  return context.config.adjustments;
};

const calculateTransactionRow = (
  row: TransactionRow,
  context: TaxCalculationContext
): TransactionRow => {
  let newRow = calculateRow(row, context);
  if (newRow.account) {
    const value = resolveReference(newRow.account, context);
    if (typeof value === 'number' && value !== newRow.accountValue) {
      newRow = updateContext(context, { ...newRow, accountValue: value });
    }
  }
  return shallowCompare(row, newRow) ? row : newRow;
};

const updateConfig = (
  taxTable: TaxTable<TransactionRow>,
  context: TaxCalculationContext
) => {
  return {
    ...taxTable,
    rows: taxTable.rows.map((row) => {
      if (!context.input.lastPeriod && row.id === 'resultToBook.from') {
        return {
          ...row,
          account: '8990',
          accountValue: 8990,
        };
      }
      return row;
    }),
  };
};

const calculateAdjustments = (
  context: TaxCalculationContext,
  taxView: TaxView | null
): TaxTable<TransactionRow> => {
  return calculateTaxTable(
    updateConfig(taxView?.adjustments || createFromContext(context), context),
    context,
    calculateTransactionRow
  );
};

export default calculateAdjustments;

import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { Redirect } from 'react-router-dom';

import useQuery from '_authentication/hooks/useQuery';
import NotFoundView from '_shared/components/NotFoundView';
import useAuth from '_authentication/hooks/useAuth';
import LoginBackground from 'assets/login-background.jpg';
import config from '_shared/services/config';
// @ts-ignore
import { ReactComponent as Logo } from 'assets/agoy_login.svg';
import { Account } from '../../types';
import CreateContent from './CreateContent';

const AgoyLogo = styled(Logo)`
  position: absolute;
  top: 32px;
  left: 80px;
  width: 160px;
  color: ${(props) => props.theme.palette.secondary.main};
`;

const Wrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  height: 100vh;
`;

const AuthorizationWrapper = styled.div`
  background-color: #fff;
  width: 50vw;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AuthorizationImageContainer = styled.div`
  display: flex;
  background: url(${LoginBackground});
  background-size: cover;
  width: 50vw;
`;

const AdContainer = styled.div`
  margin: auto;
  border-radius: 4px;
  background-color: rgba(0, 49, 60, 0.6);
  padding: 1.5rem;
  color: white;
  max-width: 685px;
  & > h2 {
    font-size: 2.5rem;
    margin: 0;
    color: #00ce7c;
  }
  & > p:last-of-type {
    margin-bottom: 0;
  }
  & a {
    color: #00ce7c;
  }
`;

const RegistrationView = (props) => {
  const auth = useAuth();
  const queryEmail = useQuery().get('email');

  const [email, setEmail] = useState('');
  const [accountData, setAccountData] = useState<Account>({
    username: '',
    givenName: '',
    familyName: '',
    password: '',
  });

  const redirectWithEmail = (route, query?) => {
    const completeRoute = query
      ? `/${route}?email=${encodeURIComponent(query)}`
      : `/${route}`;
    props.history.push(completeRoute);
  };

  const openConfirm = (data) => {
    if (data) {
      setEmail(data.username);
      if (data.password) setAccountData(data);
      redirectWithEmail('confirm', data.username);
    }
    redirectWithEmail('confirm', email);
  };

  const openLogin = (query) => redirectWithEmail('login', query);

  useEffect(() => {
    // fetch current session
    auth.getCurrentSessionAWS();
  }, []);

  if (!auth.isLoading && auth.isAuthenticated) {
    return <Redirect to="/" />;
  }

  if (config.runningEnvironment === 'production' && !queryEmail) {
    return <NotFoundView />;
  }

  return (
    <Wrapper>
      <AuthorizationWrapper>
        <AgoyLogo />
        <CreateContent
          openConfirm={openConfirm}
          backToLogin={openLogin}
          accountData={accountData}
        />
      </AuthorizationWrapper>
      <AuthorizationImageContainer>
        <AdContainer>
          <h2>Vill du vara med och revolutionera redovisningsvärlden?</h2>
          <p>Välkommen till Agoy!</p>
          <p>Registrera ditt konto nu.</p>
        </AdContainer>
      </AuthorizationImageContainer>
    </Wrapper>
  );
};

export default RegistrationView;

import React, { useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import {
  Typography,
  Tooltip,
  Popover,
  Divider,
  MenuList,
  MenuItem,
} from '@material-ui/core';
import styled from '@emotion/styled';
import { format } from 'date-fns';
import { InfoOutlined } from '@material-ui/icons';
import isPropValid from '@emotion/is-prop-valid';

import { useApiSdk, asResultClass } from 'api-sdk';
import { addGlobalErrorMessage, addGlobalMessage } from 'redux/actions';
import { useSelector } from 'redux/reducers';
import { colors as ThemeColors } from '@agoy/theme';
import { datesToString } from '@agoy/common';
import { parseFormat } from '@agoy/dates';
import AnnualReportContext from '_reconciliation/components/ReconciliationView/RowContext/AnnualReportContext';
import ApproveChangesDialog from '_shared/components/StatusSelector/ApproveChangesDialog';
import { describeRequiredChanges } from '_shared/services/ProgramStatus/errorHandling';
import { setProgramStatus } from '_shared/components/StatusSelector/StatusSelector';
import { transformVouchers } from '_shared/components/VoucherView/utils';
import { Status, ProgramStatusPeriodState } from '_shared/types';
import { fetchProgramStatus } from '_shared/redux/actions';
import { PeriodStatuses } from '_tax/types';
import { error } from 'theme/colors';
import { Voucher } from 'types/Voucher';

import { ReconciliationPeriod } from '@agoy/reconciliation';
import UnlockReasonModal from './UnlockReasonModal';
import ReconciliationStatus from './ReconciliationStatus';
import VoucherModal from './VoucherModal';
import AnnualReportErrorModal from './AnnualReportError';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  column-gap: 4px;
  padding: ${({ theme }) => theme.spacing(1)}px
    ${({ theme }) => theme.spacing(2)}px;

  :hover {
    background-color: #f5f5f5;
  }

  > div {
    flex-shrink: 0;
  }
`;

const InfoOutlinedIcon = styled(InfoOutlined)`
  font-size: 20px;
  color: ${({ theme }) => theme.palette.text.secondary};
  margin-left: 4px;
`;

const ReconciliationStatusIcon = styled(ReconciliationStatus)`
  width: 24px;
  height: 24px;
`;

const StyledMenuItem = styled(MenuItem)`
  display: flex;
  flex-direction: row;
  column-gap: 4px;
`;

const StyledDivider = styled(Divider)`
  margin: 0 8px;
`;

const HistoryItem = styled.div`
  padding: ${({ theme }) => theme.spacing(1)}px
    ${({ theme }) => theme.spacing(2)}px;
`;

const StatusDate = styled(Typography)`
  color: ${({ theme }) => theme.palette.grey[500]};
  font-size: 0.875rem;
`;

const StatusText = styled(Typography, { shouldForwardProp: isPropValid })<{
  statusColor?: string;
}>`
  display: inline;
  font-size: 0.875rem;
  ${({ statusColor }) => (statusColor ? `color: ${statusColor}` : '')}
`;

type PeriodStatusPopoverProps = {
  period: ReconciliationPeriod;
  periodStatus?: ProgramStatusPeriodState;
  clientId: string;
  anchorElement: HTMLDivElement | null;
  open: boolean;
  onClose: () => void;
};

const PeriodStatusPopover = ({
  period,
  periodStatus = {
    history: [],
    status: 'NOT_STARTED',
  },
  clientId,
  anchorElement,
  open,
  onClose,
}: PeriodStatusPopoverProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const sdk = useApiSdk();

  const annualReportData = useContext(AnnualReportContext);

  const [isReasonOpen, setIsReasonOpen] = useState(false);
  const [voucherApproved, setVoucherApproved] = useState(false);
  const [approved, setApproved] = useState(false);
  const [isARWarningOpen, setIsARWarningOpen] = useState(false);

  const [prelVouchers, setPrelVouchers] = useState<Voucher[]>([]);
  const [reasonText, setReasonText] = useState('');
  const [nextStatus, setNextStatus] = useState<Status | null>(null);
  const [approveMessage, setApproveMessage] = useState<ReturnType<
    typeof describeRequiredChanges
  > | null>(null);

  const periodDoneAccountActions = useSelector(
    (state) =>
      state.organisation.settings?.reconciliation?.periodDoneAccountActions
  );

  const { history, status } = periodStatus;

  const lastPeriod = period.periods[period.periods.length - 1];
  const financialYearPeriod = `${parseFormat(
    lastPeriod?.financialYear?.start,
    'yyyyMMdd'
  )}-${parseFormat(lastPeriod?.financialYear?.end, 'yyyyMMdd')}`;

  const cancelChangeStatus = () => {
    setIsReasonOpen(false);
    setPrelVouchers([]);
    setVoucherApproved(false);
    setApproved(false);
    setReasonText('');
    setNextStatus(null);
    setApproveMessage(null);
  };

  const getStatusParameters = () => {
    return {
      approved,
      voucherApproved,
      reason: reasonText,
    };
  };

  const getPrelVouchers = async () => {
    if (period.type !== 'yearEnd') {
      return [];
    }

    const result = await asResultClass(
      sdk.getVoucherList({
        clientid: clientId,
        period: parseFormat(period.start, 'yyyyMMdd'),
      })
    );

    if (result.ok) {
      const vouchers = result.val;
      const prelVouchersList = transformVouchers(
        vouchers.filter((item) => item.preliminary)
      );

      return prelVouchersList;
    }

    return [];
  };

  const handleSelectStatus = async ({
    updatedStatus,
    isVouchersApproved = false,
    isApproved = false,
    reason,
  }: {
    updatedStatus: Status;
    isVouchersApproved?: boolean;
    isApproved?: boolean;
    reason?: string;
  }) => {
    if (status === updatedStatus) {
      return;
    }

    setNextStatus(updatedStatus);

    // open modal if the annual report is locked
    if (
      status === 'LOCKED' &&
      annualReportData.version !== '1' &&
      annualReportData.isLocked
    ) {
      setIsARWarningOpen(true);
      return;
    }

    if (status === 'LOCKED' && !reason) {
      setIsReasonOpen(true);
      return;
    }

    if (
      updatedStatus === 'DONE' &&
      period.type === 'yearEnd' &&
      !isVouchersApproved
    ) {
      const vouchers = await getPrelVouchers();

      if (vouchers.length) {
        setPrelVouchers(vouchers);
        return;
      }
    }

    const result = await setProgramStatus(
      clientId,
      'RECONCILIATION',
      period.type === 'yearEnd' ? 'financialYear' : 'period',
      datesToString(period),
      updatedStatus,
      formatMessage,
      isApproved,
      reason
    );

    if (result.err) {
      if (typeof result.val === 'string') {
        dispatch(addGlobalErrorMessage(undefined, result.val));
      } else {
        setApproveMessage(result.val);
      }
    } else {
      await dispatch(fetchProgramStatus(clientId, 'RECONCILIATION'));

      if (
        periodDoneAccountActions === 'mark_all_accounts' &&
        updatedStatus === 'DONE'
      ) {
        const fillResult = await asResultClass(
          sdk.fillActualBalance({
            clientid: clientId,
            periodId: lastPeriod.id,
            type: 'all',
          })
        );

        if (fillResult.ok) {
          if (
            'hasSpecifications' in fillResult.val &&
            fillResult.val.hasSpecifications
          ) {
            dispatch(
              addGlobalMessage(
                'error',
                'overview.saldo.error.hasSpecifications'
              )
            );
          } else {
            dispatch(
              addGlobalMessage(
                'success',
                'overview.saldo.makeAllSuccessMessage'
              )
            );
          }
        } else {
          dispatch(addGlobalErrorMessage('error'));
        }
      }

      setReasonText('');
      setNextStatus(null);

      onClose();
    }
  };

  const handleClickMark = async () => {
    const result = await asResultClass(
      sdk.fillActualBalance({
        clientid: clientId,
        periodId: lastPeriod.id,
        type: 'not_changed',
        notChangedPeriods: period.periods.map((subPeriod) => subPeriod.id),
      })
    );

    if (result.ok) {
      if ('hasSpecifications' in result.val && result.val.hasSpecifications) {
        dispatch(
          addGlobalMessage('error', 'overview.saldo.error.hasSpecifications')
        );
      } else {
        dispatch(addGlobalMessage('success', 'overview.saldo.successMessage'));
        handleSelectStatus({
          ...getStatusParameters(),
          updatedStatus: 'STARTED',
        });
      }
      onClose();
    } else {
      dispatch(addGlobalErrorMessage('error', 'overview.saldo.errorMessage'));
    }

    onClose();
  };

  const onApproved = () => {
    setApproveMessage(null);
    setApproved(true);

    if (nextStatus) {
      handleSelectStatus({
        ...getStatusParameters(),
        updatedStatus: nextStatus,
        isApproved: true,
      });
    }
  };

  const onSubmitReason = (text: string) => {
    setIsReasonOpen(false);
    setReasonText(text);

    if (nextStatus) {
      handleSelectStatus({
        ...getStatusParameters(),
        updatedStatus: nextStatus,
        reason: text,
      });
    }
  };

  const handleSubmitVoucherModal = () => {
    setPrelVouchers([]);
    setVoucherApproved(true);

    if (nextStatus) {
      handleSelectStatus({
        ...getStatusParameters(),
        isVouchersApproved: true,
        updatedStatus: nextStatus,
      });
    }
  };

  return (
    <Popover
      open={open}
      anchorEl={anchorElement}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      PaperProps={{
        style: { width: anchorElement?.getBoundingClientRect().width },
      }}
    >
      {approveMessage && (
        <ApproveChangesDialog
          changes={approveMessage}
          onCancel={cancelChangeStatus}
          onApproved={onApproved}
        />
      )}

      <UnlockReasonModal
        open={isReasonOpen}
        period={period}
        onClose={cancelChangeStatus}
        onSubmit={onSubmitReason}
      />

      <VoucherModal
        open={!!prelVouchers.length}
        vouchers={prelVouchers}
        financialYear={period.financialYears[0]}
        onClose={cancelChangeStatus}
        onSubmit={handleSubmitVoucherModal}
      />

      <AnnualReportErrorModal
        open={isARWarningOpen}
        clientId={clientId}
        financialYear={financialYearPeriod}
        onClose={() => setIsARWarningOpen(false)}
      />

      <Wrapper>
        {periodDoneAccountActions === 'mark_all_accounts' &&
          (status === 'NOT_STARTED' || status === 'STARTED') && (
            <>
              <Row onClick={handleClickMark}>
                <ReconciliationStatusIcon status="STARTED" />
                <Typography>
                  {formatMessage({ id: 'overview.header.markAllAccounts' })}
                </Typography>
              </Row>
              <StyledDivider />
            </>
          )}

        <MenuList>
          <StyledMenuItem
            selected={status === 'STARTED'}
            disabled={status === 'LOCKED'}
            onClick={() =>
              handleSelectStatus({
                ...getStatusParameters(),
                updatedStatus: 'STARTED',
              })
            }
          >
            <ReconciliationStatusIcon status="STARTED" />
            <Typography>{PeriodStatuses.STARTED}</Typography>
          </StyledMenuItem>
          <StyledMenuItem
            selected={status === 'DONE'}
            onClick={() =>
              handleSelectStatus({
                ...getStatusParameters(),
                updatedStatus: 'DONE',
              })
            }
          >
            <ReconciliationStatusIcon status="DONE" />
            <Typography>{PeriodStatuses.DONE}</Typography>
            <Tooltip
              title={formatMessage({
                id: `overview.header.tooltip.${
                  periodDoneAccountActions === 'no_action'
                    ? 'no_action'
                    : 'mark_all_accounts'
                }`,
              })}
            >
              <InfoOutlinedIcon />
            </Tooltip>
          </StyledMenuItem>
          <StyledMenuItem
            disabled={!(status === 'DONE' || status === 'LOCKED')}
            selected={status === 'LOCKED'}
            onClick={() =>
              handleSelectStatus({
                ...getStatusParameters(),
                updatedStatus: 'LOCKED',
              })
            }
          >
            <ReconciliationStatusIcon status="LOCKED" />
            <Typography>{PeriodStatuses.LOCKED}</Typography>
          </StyledMenuItem>
        </MenuList>

        {!!history.length && (
          <>
            <StyledDivider />

            {history.slice(0, 3).map((item) => (
              <HistoryItem key={item.createdAt}>
                <StatusDate>
                  {format(new Date(item.createdAt), 'yyyy-MM-dd HH:mm')}
                </StatusDate>
                {item.reason ? (
                  <>
                    <StatusText>
                      {item.createdBy}{' '}
                      {formatMessage({
                        id: 'overview.header.status.reason',
                      })}
                      {': '}
                    </StatusText>
                    <StatusText
                      statusColor={error}
                    >{`"${item.reason}"`}</StatusText>
                  </>
                ) : (
                  <>
                    <StatusText>
                      {item.createdBy}{' '}
                      {formatMessage({
                        id: 'program.status.history.log.entry.change',
                      })}
                      {': '}
                    </StatusText>
                    <StatusText statusColor={ThemeColors[item.status]}>
                      {PeriodStatuses[item.status]}
                    </StatusText>
                  </>
                )}
              </HistoryItem>
            ))}
          </>
        )}
      </Wrapper>
    </Popover>
  );
};

export default PeriodStatusPopover;

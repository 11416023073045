import { value, ref, table, or, sum, id } from '@agoy/document';
import { IxbrlCell, NoteTwoTableSectionWithSum } from '../../../../common';
import { sumAccountsUBs } from '../../../../common/utils/accountSumsUtil';
import {
  active,
  field,
  ixbrlCell,
  ixbrlField,
  tupleRef,
} from '../../../../common/utils/util';
import { year, previousYear } from '../../../../common/utils/date-util';
import { NoteAccountRanges } from '../types';

const MONETARY_ITEM_TYPE = 'monetaryItemType';

export const note72Accounts: NoteAccountRanges[] = [
  {
    rowId: '1',
    ranges: [[1620]],
    cellLabel: 'Upparbetad men ej fakturerad intäkt',
    action: 'sumAccountsUBs',
  },
  {
    rowId: '2',
    ranges: [[1710, 1719]],
    cellLabel: 'Förutbetalda hyreskostnader',
    action: 'sumAccountsUBs',
  },
  {
    rowId: '3',
    ranges: [[1740, 1749]],
    cellLabel: 'Förutbetalda räntekostnader',
    action: 'sumAccountsUBs',
  },
  {
    rowId: '4',
    ranges: [[1730, 1739]],
    cellLabel: 'Förutbetalda försäkringspremier',
    action: 'sumAccountsUBs',
  },
  {
    rowId: '6',
    ranges: [[1760, 1769]],
    cellLabel: 'Upplupna ränteintäkter',
    action: 'sumAccountsUBs',
  },
];

export const getNote72 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null
): NoteTwoTableSectionWithSum => {
  return {
    active: false,
    number: value(undefined),
    name: value('Förutbetalda kostnader och upplupna intäkter'),
    table: table<IxbrlCell>(
      'notes.note72.table',
      {
        id: 'label',
        label: 'Förutbetalda kostnader och upplupna intäkter',
      },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      .addRows((rows) =>
        rows
          .addRow(
            '1',
            noteLabel('Upparbetad men ej fakturerad intäkt'),
            ixbrlCell(ref(sumAccountsUBs('1620', false)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:UpparbetadEjFaktureradIntakt',
              contextRef: 'balans0',
              saldo: 'debit',
              standardRubrik: 'Upparbetad men ej fakturerad intäkt',
            }),
            ixbrlCell(ref(sumAccountsUBs('1620', false, 'previousYear')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:UpparbetadEjFaktureradIntakt',
              contextRef: 'balans1',
              saldo: 'debit',
              standardRubrik: 'Upparbetad men ej fakturerad intäkt',
            })
          )
          .addRow(
            '2',
            noteLabel('Förutbetalda hyreskostnader'),
            ixbrlCell(ref(sumAccountsUBs('1710:1719', false)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForutbetaldaKostnaderUpplupnaIntakterForutbetaldHyreskostnad',
              contextRef: 'balans0',
              saldo: 'debit',
              standardRubrik:
                'Förutbetalda hyreskostnader i förutbetalda kostnader och upplupna intäkter',
            }),
            ixbrlCell(ref(sumAccountsUBs('1710:1719', false, 'previousYear')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForutbetaldaKostnaderUpplupnaIntakterForutbetaldHyreskostnad',
              contextRef: 'balans1',
              saldo: 'debit',
              standardRubrik:
                'Förutbetalda hyreskostnader i förutbetalda kostnader och upplupna intäkter',
            })
          )
          .addRow(
            '3',
            noteLabel('Förutbetalda räntekostnader'),
            ixbrlCell(ref(sumAccountsUBs('1740:1749', false)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForutbetaldaKostnaderUpplupnaIntakterForutbetaldRantekostnad',
              contextRef: 'balans0',
              saldo: 'debit',
              standardRubrik:
                'Förutbetalda räntekostnader i förutbetalda kostnader och upplupna intäkter',
            }),
            ixbrlCell(ref(sumAccountsUBs('1740:1749', false, 'previousYear')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForutbetaldaKostnaderUpplupnaIntakterForutbetaldRantekostnad',
              contextRef: 'balans1',
              saldo: 'debit',
              standardRubrik:
                'Förutbetalda räntekostnader i förutbetalda kostnader och upplupna intäkter',
            })
          )
          .addRow(
            '4',
            noteLabel('Förutbetalda försäkringspremier'),
            ixbrlCell(ref(sumAccountsUBs('1730:1739', false)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForutbetaldaKostnaderUpplupnaIntakterForutbetaldForsakringspremie',
              contextRef: 'balans0',
              saldo: 'debit',
              standardRubrik:
                'Förutbetalda försäkringspremier i förutbetalda kostnader och upplupna intäkter',
            }),
            ixbrlCell(ref(sumAccountsUBs('1730:1739', false, 'previousYear')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForutbetaldaKostnaderUpplupnaIntakterForutbetaldForsakringspremie',
              contextRef: 'balans1',
              saldo: 'debit',
              standardRubrik:
                'Förutbetalda försäkringspremier i förutbetalda kostnader och upplupna intäkter',
            })
          )
          .addRow(
            '5',
            noteLabel('Upplupna intäkter'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForutbetaldaKostnaderUpplupnaIntakterUpplupnaIntakter',
              contextRef: 'balans0',
              saldo: 'debit',
              standardRubrik:
                'Upplupna intäkter i förutbetalda kostnader och upplupna intäkter',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForutbetaldaKostnaderUpplupnaIntakterUpplupnaIntakter',
              contextRef: 'balans1',
              saldo: 'debit',
              standardRubrik:
                'Upplupna intäkter i förutbetalda kostnader och upplupna intäkter',
            })
          )
          .addRow(
            '6',
            noteLabel('Upplupna ränteintäkter'),
            ixbrlCell(ref(sumAccountsUBs('1760:1769', false)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForutbetaldaKostnaderUpplupnaIntakterUpplupnaRanteintakter',
              contextRef: 'balans0',
              saldo: 'debit',
              standardRubrik:
                'Upplupna ränteintäkter i förutbetalda kostnader och upplupna intäkter',
            }),
            ixbrlCell(ref(sumAccountsUBs('1760:1769', false, 'previousYear')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForutbetaldaKostnaderUpplupnaIntakterUpplupnaRanteintakter',
              contextRef: 'balans1',
              saldo: 'debit',
              standardRubrik:
                'Upplupna ränteintäkter i förutbetalda kostnader och upplupna intäkter',
            })
          )
          .build()
      )
      .build(),
    commentLabel: field('Kommentar'),
    comment: ixbrlField(active(field('')), {
      type: 'stringItemType',
      name: 'se-gen-base:NotForutbetaldaKostnaderUpplupnaIntakterKommentar',
      contextRef: 'period0',
      standardRubrik:
        'Kommentar till specifikation av förutbetalda kostnader och upplupna intäkter',
    }),
    secondTable: table<IxbrlCell>(
      'notes.note72.secondTable',
      {
        id: 'label',
        label: 'Typ av interimspost',
      },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      .addRows((rows) =>
        rows
          .addRow(
            'typeOfInterimPost',
            ixbrlCell(value(''), {
              type: 'stringItemType',
              name: 'se-gen-base:OvrigaForutbetaldaKostnaderUpplupnaIntakterInterimspost',
              contextRef: 'balans0',
              tuple: {
                name: 'se-gaap-ext:OvrigaForutbetaldaKostnaderUpplupnaIntakterTuple',
                id: `${rows.getBaseId()}.typeOfInterimPost.tuple`,
                tupleID: `${rows.getBaseId()}.typeOfInterimPost.tuple`,
              },
              previousYearTuple: true,
              tupleRef: tupleRef(
                1,
                `${rows.getBaseId()}.typeOfInterimPost.tuple`
              ),
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OvrigaForutbetaldaKostnaderUpplupnaIntakter',
              contextRef: 'balans0',
              saldo: 'debit',
              tupleRef: tupleRef(
                2,
                `${rows.getBaseId()}.typeOfInterimPost.tuple_balans0`
              ),
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OvrigaForutbetaldaKostnaderUpplupnaIntakter',
              contextRef: 'balans1',
              saldo: 'debit',
              tupleRef: tupleRef(
                3,
                `${rows.getBaseId()}.typeOfInterimPost.tuple_balans1`
              ),
            })
          )
          .build()
      )
      .newRowTemplateGenerator((id, baseId) => ({
        id,
        active: true,
        cells: {
          label: ixbrlCell(value(''), {
            type: 'stringItemType',
            name: 'se-gen-base:OvrigaForutbetaldaKostnaderUpplupnaIntakterInterimspost',
            contextRef: 'balans0',
            tuple: {
              name: 'se-gaap-ext:OvrigaForutbetaldaKostnaderUpplupnaIntakterTuple',
              id: `${baseId}.${id}.tuple`,
              tupleID: `${baseId}.${id}.tuple`,
            },
            previousYearTuple: true,
            tupleRef: tupleRef(1, `${baseId}.${id}.tuple`),
          }),
          year: ixbrlCell(value(undefined), {
            type: MONETARY_ITEM_TYPE,
            name: 'se-gen-base:OvrigaForutbetaldaKostnaderUpplupnaIntakter',
            contextRef: 'balans0',
            saldo: 'debit',
            tupleRef: tupleRef(2, `${baseId}.${id}.tuple_balans0`),
          }),
          previousYear: ixbrlCell(value(undefined), {
            type: MONETARY_ITEM_TYPE,
            name: 'se-gen-base:OvrigaForutbetaldaKostnaderUpplupnaIntakter',
            contextRef: 'balans1',
            saldo: 'debit',
            tupleRef: tupleRef(3, `${baseId}.${id}.tuple_balans1`),
          }),
        },
      }))
      .build(),
    sumTable: table(
      'notes.note72.sumTable',
      { id: 'label', label: '' },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      .addRows((rows) =>
        rows
          .addRow(
            'sum',
            noteLabel('Summa'),
            ixbrlCell(
              ref(
                or(
                  sum(
                    sum(
                      id('notes.note72.table.*.year'),
                      id('notes.note72.secondTable.*.year')
                    )
                  ),
                  0
                )
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:ForutbetaldaKostnaderUpplupnaIntakter',
                contextRef: 'balans0',
                saldo: 'debit',
                standardRubrik: 'Förutbetalda kostnader och upplupna intäkter',
              }
            ),
            ixbrlCell(
              ref(
                or(
                  sum(
                    sum(
                      id('notes.note72.table.*.previousYear'),
                      id('notes.note72.secondTable.*.previousYear')
                    )
                  ),
                  0
                )
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:ForutbetaldaKostnaderUpplupnaIntakter',
                contextRef: 'balans1',
                saldo: 'debit',
                standardRubrik: 'Förutbetalda kostnader och upplupna intäkter',
              }
            )
          )
          .build()
      )
      .build(),
    group: value(undefined),
    section: value(undefined),
    validForReportType: {
      k2: false,
      k3: true,
    },
  };
};

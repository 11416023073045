import React from 'react';
import Typography from './Typography/Typography';
import { Variant } from './Typography/Typography.types';
interface HeadingProps {
  id?: string;
  title: string | React.ReactElement;
  variant?: Variant;
}

/**
 * Heading used for printing
 * @param title
 * @param variant
 * @param title
 * @returns
 */
const Heading = ({ title, variant = 'h3' }: HeadingProps): JSX.Element => {
  return <Typography variant={variant}>{title}</Typography>;
};

export default Heading;

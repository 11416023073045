import { useContext, useMemo } from 'react';
import { TaxViewState } from '_tax/redux/tax-view/reducer';
import { useSelector } from 'redux/reducers';
import TaxesDataContext from '_tax/service/TaxesDataContext';
import { PrintState } from '_shared/components/PrintedDocument/PrintStateContext';
import { CustomerViewState } from '_clients/redux/customer-view/types';
import { AccountingViewState } from '_reconciliation/redux/accounting-view/reducer';
import { CustomersState } from '_clients/redux/customers/reducer';

export type TaxPrintState = {
  clientId: string;
  taxView: TaxViewState;
  taxData: PrintState['taxData'];
  customerView: CustomerViewState;
  accountingView: AccountingViewState;
  customers: CustomersState;
};

const usePrintState = (): TaxPrintState => {
  const taxViewState = useSelector((state) => state.taxView);
  const customerViewState = useSelector((state) => state.customerView);
  const accountingViewState = useSelector((state) => state.accountingView);
  const customersState = useSelector((state) => state.customers);

  const { onToggleDetailedSpecification, ...taxData } =
    useContext(TaxesDataContext);

  const state: TaxPrintState = useMemo(
    () => ({
      clientId: taxData.clientId,
      taxView: taxViewState,
      customerView: customerViewState,
      accountingView: accountingViewState,
      customers: customersState,
      taxData,
    }),
    [
      accountingViewState,
      customerViewState,
      customersState,
      taxData,
      taxViewState,
    ]
  );

  return state;
};

export default usePrintState;

import React from 'react';
import { useIntl } from 'react-intl';
import MaterialUiChip from '@material-ui/core/Chip';
import styled from '@emotion/styled';
import { InvoiceFilter } from '_reconciliation/types';

const Chip = styled(MaterialUiChip)`
  margin: 0 ${(props) => props.theme.spacing(0.5)}px 0;
`;

type FilterProps = {
  filters: InvoiceFilter[];
  filterAction: (value: string) => void;
  filterDeleteAction: (value: string) => void;
};

const InvoiceFilters: React.FC<FilterProps> = ({
  filters,
  filterAction,
  filterDeleteAction,
}: FilterProps) => {
  const intl = useIntl();

  return (
    <div>
      {filters.map((filter) => (
        <Chip
          key={filter.title}
          label={intl.formatMessage({
            id: `hidden.invoices.status.${filter.title}`,
          })}
          color="primary"
          size="small"
          variant={filter.active ? 'default' : 'outlined'}
          onClick={() => filterAction(filter.title)}
          onDelete={() => filterDeleteAction(filter.title)}
        />
      ))}
    </div>
  );
};

export default InvoiceFilters;

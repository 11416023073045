import React, { useRef } from 'react';
import { useIntl } from 'react-intl';
import styled from '@emotion/styled';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { Typography } from '@material-ui/core';

interface BaseProps {
  editing: boolean;
  square?: boolean;
  roundedSquare?: boolean;
}

interface LogoProps {
  imageUrl?: string;
  editing: boolean;
}

const Base = styled.div<BaseProps>`
  height: 4rem;
  width: 4rem;
  border-radius: ${(props) =>
    props.square ? 'unset' : props.roundedSquare ? '4px' : '50%'};
  margin: 2rem auto;
  background-color: ${(props) => `${props.theme.palette.grey[400]}`};
  position: relative;
  ${(props) =>
    props.editing && `border: 2px dashed ${props.theme.palette.primary.main}`};
  overflow: hidden;
  cursor: ${(props) => (props.editing ? 'pointer' : 'default')};

  input {
    display: none;
  }

  > * {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const Logo = styled.img<LogoProps>`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: ${(props) => (props.editing ? '0.4' : '1')}
    ${(props) =>
      props.imageUrl &&
      `&:after {
    content: '';
    display: block;
    background: url(${props.imageUrl}) ;
    background-size: contain;
    background-repeat:no-repeat; 
    background-position: center center; 
    opacity: ${props.editing ? '0.4' : '1'}
    }`};
`;

const StyledAvatar = styled(AccountCircleIcon)`
  font-size: 4rem;
`;

const HoverCaption = styled(Typography)`
  font-weight: bold;
  font-size: 15px;
`;

type Props = {
  imageUrl?: string | null;
  handleChange?: (file: File) => void;
  sendErrorMessage?: (message: string) => void;
  editing: boolean;
  square?: boolean;
  roundedSquare?: boolean;
  className?: string;
  hoverCaption?: boolean;
  handleEdit?: () => void;
};

export const UserClientImage = ({
  editing = false,
  imageUrl,
  handleChange,
  sendErrorMessage,
  square,
  roundedSquare,
  className,
  hoverCaption,
  handleEdit,
}: Props): JSX.Element => {
  const { formatMessage } = useIntl();
  const fileInput = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    if (editing) fileInput.current!.click();
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];

    if (editing !== undefined && file) {
      if (file.type.startsWith('image')) {
        handleChange && handleChange(file);
      } else {
        sendErrorMessage && sendErrorMessage(formatMessage({ id: 'error' }));
      }
    }
  };

  return (
    <Base
      onClick={handleClick}
      editing={editing}
      square={square}
      roundedSquare={roundedSquare}
      className={className}
    >
      {imageUrl && (
        <Logo
          className="logo"
          imageUrl={imageUrl || undefined}
          src={imageUrl}
          editing={editing}
          alt="client-logo"
        />
      )}
      <input
        ref={fileInput}
        type="file"
        accept="image/*"
        onChange={handleFileSelect}
      />
      {editing && <AddAPhotoIcon color="primary" />}
      {!imageUrl && !editing && <StyledAvatar className="logo" />}
      {hoverCaption && (
        <HoverCaption className="hoverCap" onClick={handleEdit}>
          {formatMessage({ id: 'edit' })}
        </HoverCaption>
      )}
    </Base>
  );
};

export default UserClientImage;

import React from 'react';
import { Box } from '@material-ui/core';
import { NoteOneTableSection } from '@agoy/annual-report-document';
import {
  AgoyTable,
  AgoyTableRow,
  Field,
  StringCell,
  numberValue,
  stringValue,
} from '@agoy/document';
import Comment from '../../UI/Comment';
import Header from '../../UI/Header';
import { Note } from '../../UI/Note';
import NoteTable from '../../UI/NoteTable';

type Note101Props = {
  noteId: string;
  rowsInGroupsOf?: number;
  print?: boolean;
  isDigitalSubmission?: boolean;
  title: string;
  commentLabel: Field;
  accountRanges?: any;
  hasPreviousYear: boolean;
  isXBRLRender?: boolean;
} & NoteOneTableSection;

const Note101 = ({
  active,
  noteId,
  number,
  table,
  rowsInGroupsOf,
  title,
  commentLabel,
  comment,
  hasPreviousYear,
  isXBRLRender = false,
}: Note101Props) => {
  if (!active) return null;

  const replaceEmptyStringWithSpace = (table: AgoyTable): AgoyTable => {
    const updatedTable = { ...table };

    updatedTable.rows.forEach((row: AgoyTableRow) => {
      if (row.cells == null) return;

      Object.keys(row?.cells).forEach((key) => {
        if (row?.cells == null || row?.cells[key] == null) return;

        if ((row.cells[key] as StringCell).value === '') {
          (row.cells[key] as StringCell).value = ' ';
        }
      });
    });

    return updatedTable;
  };

  const updatedTable = replaceEmptyStringWithSpace(table);

  return (
    <Note id={noteId}>
      <Header noteId={noteId} noteNumber={numberValue(number)} title={title} />

      {/* First Table */}
      <Box mt={1} mb={1}>
        <NoteTable
          table={{
            ...updatedTable,
            rows: [...updatedTable.rows.slice(1), updatedTable.rows[0]],
          }}
          tableId={`notes.${noteId}.table`}
          groupRowsInterval={rowsInGroupsOf}
          hasPreviousYear={hasPreviousYear}
          isXBRLRender={isXBRLRender}
        />
      </Box>

      {/* Comment */}
      <Comment
        field={comment}
        value={stringValue(comment) || ''}
        labelValue={stringValue(commentLabel) || ''}
      />
    </Note>
  );
};

export default Note101;

import React, { useState } from 'react';
import {
  Box,
  IconButton,
  TableCell,
  TableRow as MuiTableRow,
  Typography,
} from '@material-ui/core';
import { Delete, ExpandLess, ExpandMore } from '@material-ui/icons';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';

import { AgoyTableColumn, AgoyTableRow, stringValue } from '@agoy/document';
import SignaturesPeopleEditView, { RowChanges } from './EditRowView';

const TableRow = styled(MuiTableRow)`
  :hover {
    background: ${({ theme }) => theme.palette.accountingView.cell.ok};
  }

  // full name
  td:nth-of-type(1) {
    max-width: 260px;
  }

  // social sec number
  td:nth-of-type(2) {
    max-width: 160px;
  }

  // Role
  td:nth-of-type(3) {
    max-width: 260px;
  }

  // Expand icon
  td:nth-last-of-type(2) {
    width: 60px;
    padding-left: 6px;
    padding-right: 6px;
  }

  // Delete icon
  td:nth-last-of-type(1) {
    width: 60px;
    padding-left: 6px;
    padding-right: 6px;
  }
`;

const ExpandedContent = styled(Box)`
  background: ${({ theme }) => theme.palette.grey[100]};
`;

const getFullName = (cells) => {
  return `${cells?.givenName?.value} ${cells?.surName?.value}`;
};

interface SignaturesPeopleTableRowProps {
  baseId: string;
  row: AgoyTableRow;
  columns: AgoyTableColumn[];
  print?: boolean;
  showDate: boolean;
  showAccountantRole: boolean;
  minDate?: Date;
  onEditRow: (rowId: string, changes: Partial<RowChanges>) => void;
  onDeleteRow: (rowId: string) => void;
}

const SignaturesPeopleTableRow = ({
  baseId,
  row,
  columns,
  print,
  showDate,
  showAccountantRole,
  minDate,
  onEditRow,
  onDeleteRow,
}: SignaturesPeopleTableRowProps): JSX.Element => {
  const { formatMessage } = useIntl();

  const [expanded, setExpanded] = useState(false);

  return (
    <>
      {row.cells && (
        <TableRow key={row.id}>
          <TableCell>
            {getFullName(row.cells) === ' ' ? (
              <Typography>
                {formatMessage({
                  id: `${baseId}.placeholder.givenName`,
                })}{' '}
                {formatMessage({
                  id: `${baseId}.placeholder.surname`,
                })}
              </Typography>
            ) : (
              <Typography noWrap={!print}>{getFullName(row.cells)}</Typography>
            )}
          </TableCell>

          {columns.map((col) => {
            const cell = row.cells?.[col.id];
            const key = `${row.id}.${col.id}`;

            if (!cell) {
              return <TableCell key={key} />;
            }

            if (cell.type === 'string') {
              let cellValue = cell.value.replace(/\|/g, ', ');
              if (col.id === 'role') {
                if (stringValue(row?.cells?.accountantRole)) {
                  if (row?.cells?.accountantRole.type === 'string') {
                    cellValue = row?.cells?.accountantRole.value.replace(
                      /\|/g,
                      ', '
                    );
                  }
                }
              }

              return (
                <TableCell key={key}>
                  <Typography noWrap={!print}>{cellValue}</Typography>
                </TableCell>
              );
            }
            return null;
          })}
          {!print && (
            <>
              <TableCell>
                <IconButton
                  color="primary"
                  aria-label="expand person row"
                  component="span"
                  onClick={() => {
                    setExpanded(!expanded);
                  }}
                >
                  {expanded ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
              </TableCell>
              <TableCell>
                <IconButton
                  color="primary"
                  aria-label="delete person row"
                  component="span"
                  onClick={() => {
                    onDeleteRow(row.id);
                  }}
                >
                  <Delete />
                </IconButton>
              </TableCell>
            </>
          )}
        </TableRow>
      )}
      {expanded && (
        <MuiTableRow>
          <TableCell colSpan={5}>
            <ExpandedContent p={2}>
              <SignaturesPeopleEditView
                baseId={baseId}
                setExpanded={setExpanded}
                row={row}
                showDate={showDate}
                showAccountantRole={showAccountantRole}
                onEditRow={(changes) => onEditRow(row.id, changes)}
                minDate={minDate}
              />
            </ExpandedContent>
          </TableCell>
        </MuiTableRow>
      )}
    </>
  );
};

export default SignaturesPeopleTableRow;

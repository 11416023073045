import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router';

interface ScrollToProps {
  children: React.ReactElement;
}

const ScrollTo = ({ children }: ScrollToProps) => {
  const location = useLocation();
  const lastLocation = useRef(location);

  useEffect(() => {
    if (lastLocation.current.pathname !== location.pathname) {
      // Disable scroll to top when navigating to account in accounting view
      if (!location.pathname.includes('/details/account')) {
        window.scrollTo({ behavior: 'smooth', top: 0 });
      }
    } else if (location.hash) {
      const target = document.querySelector(location.hash);
      if (target) {
        target.scrollIntoView({ behavior: 'smooth' });
      }
    }
    lastLocation.current = location;
  }, [location]);

  return children;
};

export default ScrollTo;

import React from 'react';
import { isFemale } from '_person/utils';

type AvatarProps = {
  personNumber: string;
  small?: boolean;
};

const PersonAvatar = ({ personNumber, small }: AvatarProps): JSX.Element => (
  <img
    src={isFemale(personNumber) ? '../../../female.png' : '../../../male.png'}
    alt="Person avatar"
    width={small ? 50 : 80}
    height={small ? 50 : 80}
  />
);

export default PersonAvatar;

import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import { getMonth, isBefore, startOfMonth, subMonths } from 'date-fns';

import { RootState } from 'redux/reducers';
import { isFinancialYear } from '@agoy/common';
import { setCurrentPeriodAction } from '_shared/redux/actions';
import { updateTaxView } from '_tax/redux/tax-view/actions';

import {
  loadFinancialReport,
  updateFinancialReportConfig,
} from '_financial-report/redux/actions';
import { currentFinancialReportByPeriod } from '_financial-report/redux/selectors';
import { format, parse, parseFinancialYear, parseFormat } from '@agoy/dates';
import {
  SET_CURRENT_CUSTOMER,
  TOGGLE_NEW_SIE_FILE_UPDATED,
  LOAD_CUSTOMER,
  LOAD_YEAR,
} from './action-types';

export interface SetCurrentCustomerAction {
  type: typeof SET_CURRENT_CUSTOMER;
  currentCustomer: string | undefined;
  currentYear: string | undefined;
  currentFinancialYear: string | undefined;
  currentPeriod: string | null;
  currentFinancialYearId: number | undefined;
}

interface SetNextClientIdAction {
  type: typeof LOAD_CUSTOMER;
  clientId: string;
}

interface SetNextYearAction {
  type: typeof LOAD_YEAR;
  clientId: string;
  year: string;
}

export const setCurrentCustomerAction = (
  customerId: string | undefined,
  year: string | undefined,
  financialYear: string | undefined,
  period: string | null,
  financialYearId: number | undefined
): SetCurrentCustomerAction => {
  return {
    type: SET_CURRENT_CUSTOMER,
    currentCustomer: customerId,
    currentYear: year,
    currentFinancialYear: financialYear,
    currentPeriod: period,
    currentFinancialYearId: financialYearId,
  };
};

/**
 * setCurrentCustomer
 *
 * @param clientId
 * @param financialYear format YYYYMMDD-YYYYMMDD
 * @returns
 */
export const setCurrentCustomer =
  (
    clientId: string | undefined,
    financialYear: string | undefined,
    person?: boolean
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    if (financialYear !== undefined && !isFinancialYear(financialYear)) {
      throw new Error(`Expected a financial year, got ${financialYear}`);
    }
    const { customers } = getState();
    // found finacialYearId by  financialYear
    const financialYearIds = clientId
      ? customers[clientId].financialYearIds
      : undefined;
    const financialYearId =
      financialYear && financialYearIds
        ? financialYearIds[financialYear]
        : undefined;
    const client = clientId !== undefined ? customers[clientId] : undefined;
    if (clientId && !client) {
      throw new Error(`Client not loaded (${clientId})`);
    }

    const year = financialYear?.substring(0, 4);

    let period;
    if (financialYear) {
      // default period, if ongoing financial year use current calendar month
      // otherwise last month of financial year
      const { start, end } = parseFinancialYear(financialYear);
      const previousMonth = parse(
        startOfMonth(subMonths(new Date(), 1)),
        'yyyyMMdd'
      );

      if (isBefore(end, previousMonth)) {
        period = format(end, 'yyyyMMdd');
      } else if (getMonth(parse(previousMonth)) === getMonth(parse(start))) {
        period = parseFormat(start, 'yyyyMMdd');
      } else {
        period = format(previousMonth, 'yyyyMMdd');
      }
    }
    const {
      currentCustomer,
      currentYear,
      currentPeriod,
      currentFinancialYear,
    } = getState().customerView;
    if (
      currentCustomer !== clientId ||
      currentYear !== year ||
      currentFinancialYear !== financialYear ||
      currentPeriod !== period
    ) {
      await dispatch(
        setCurrentCustomerAction(
          clientId,
          year,
          financialYear,
          period,
          financialYearId
        )
      );
      if (clientId && financialYear && !person) {
        if (period) {
          updateTaxView(clientId, financialYear, dispatch, getState, false);
        }
      }
    }
  };

export const setCurrentPeriod =
  (period: string): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    const { currentCustomer, currentFinancialYear } = getState().customerView;
    if (currentCustomer && currentFinancialYear) {
      dispatch(
        setCurrentPeriodAction(currentCustomer, currentFinancialYear, period)
      );

      updateTaxView(
        currentCustomer,
        currentFinancialYear,
        dispatch,
        getState,
        false
      );

      const existReport = currentFinancialReportByPeriod(getState(), period);
      if (existReport) {
        updateFinancialReportConfig(
          currentCustomer,
          period,
          dispatch,
          getState
        );
      } else {
        await dispatch(
          loadFinancialReport(currentCustomer, currentFinancialYear, period)
        );
      }
    }
  };

interface ToggleNewSieFileUpdatedAction {
  type: typeof TOGGLE_NEW_SIE_FILE_UPDATED;
}

export const toggleNewSieFileUpdated = (): ToggleNewSieFileUpdatedAction => ({
  type: TOGGLE_NEW_SIE_FILE_UPDATED,
});

export const sieFileJustUpdated = () => {
  return (dispatch) => {
    dispatch({ type: TOGGLE_NEW_SIE_FILE_UPDATED });
    setTimeout(() => dispatch({ type: TOGGLE_NEW_SIE_FILE_UPDATED }), 5000);
  };
};

export const setNextClientId = (clientId: string): SetNextClientIdAction => ({
  type: LOAD_CUSTOMER,
  clientId,
});

export const setNextYear = (
  clientId: string,
  year: string
): SetNextYearAction => {
  if (!isFinancialYear(year)) {
    throw new Error('Must be a financial year');
  }
  return {
    type: LOAD_YEAR,
    clientId,
    year,
  };
};

export type CustomerViewAction =
  | SetCurrentCustomerAction
  | SetNextClientIdAction
  | SetNextYearAction
  | ToggleNewSieFileUpdatedAction;

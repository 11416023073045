import React, {
  useMemo,
  useCallback,
  useContext,
  useState,
  useEffect,
} from 'react';
import { useDispatch } from 'react-redux';
import { isEqual, last } from 'lodash';

import { AgoyTableRow } from '@agoy/document';
import { LegacySpecification } from '_clients/types/types';
import { addGlobalErrorMessage } from 'redux/actions';
import { useApiSdk } from 'api-sdk';
import { Table, TableServiceType } from '_shared/components/CommonTable';
import { mapToSpecification } from '_reconciliation/util/mapSpecification';
import useSequentialCall from 'utils/useSequentialCall';
import { ccyParse } from '@agoy/common';

import PeriodDataContext from '../PeriodDataContext';
import { getNextLegacySpecificationId } from '../../HiddenGroupRow/utils';

interface Props {
  specification: LegacySpecification[];
  accountNumber: string;
  print?: boolean;
}

const columns = [
  { id: 'description' },
  { id: 'reference' },
  { id: 'account' },
  { id: 'amount' },
];

const SpecificationTable = ({
  specification,
  accountNumber,
  print = false,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const sdk = useApiSdk();

  const [currentSpecification, setCurrentSpecification] =
    useState<LegacySpecification[]>(specification);

  const sortedSpecifications = useMemo(() => {
    return currentSpecification.sort((a, b) => a.id - b.id);
  }, [currentSpecification]);

  useEffect(() => {
    setCurrentSpecification((value) =>
      isEqual(value, specification) ? value : specification
    );
  }, [specification]);

  const {
    periodLocked: isLocked,
    clientId,
    groupedPeriods,
  } = useContext(PeriodDataContext);

  const storeSpecificationsRequest = useCallback(
    async (specifications: LegacySpecification[]) => {
      try {
        const periodToUpdate = last(groupedPeriods);
        if (periodToUpdate) {
          await sdk.updateLegacySpecifications({
            clientid: clientId,
            periodId: periodToUpdate.id,
            accountNumber: parseInt(accountNumber, 10),
            requestBody: specifications.map(mapToSpecification),
          });
        }
      } catch (error) {
        dispatch(addGlobalErrorMessage('error'));
      }
    },
    [accountNumber, clientId, dispatch, groupedPeriods, sdk]
  );

  const storeSpecifications = useSequentialCall(
    storeSpecificationsRequest,
    200
  );

  const handleUpdateSpecification = useCallback(
    (spec: LegacySpecification[]) => {
      setCurrentSpecification(spec);
      storeSpecifications(spec);
    },
    [storeSpecifications]
  );

  const handleAddSpecificationRow = useCallback(() => {
    handleUpdateSpecification(
      sortedSpecifications.concat({
        id: getNextLegacySpecificationId(currentSpecification),
        description: '',
        amount: '0',
        account: '',
        reference: '',
      })
    );
  }, [handleUpdateSpecification, sortedSpecifications, currentSpecification]);

  const handleUpdateCell = useCallback(
    (cellId: string, value: string | number | boolean | undefined) => {
      const [, rowId, cell] = cellId.split('.');

      const rowIndex = parseInt(rowId, 10);
      const row = sortedSpecifications[rowIndex];
      const updatedSpecification = [...sortedSpecifications];

      if (!row) return;

      if (
        cell === 'amount' &&
        (typeof value === 'undefined' ||
          typeof value === 'string' ||
          value === 0)
      ) {
        updatedSpecification.splice(rowIndex, 1, {
          ...row,
          [cell]: '0',
        });
        handleUpdateSpecification(updatedSpecification);
      } else {
        updatedSpecification.splice(rowIndex, 1, {
          ...row,
          [cell]: value,
        });
        handleUpdateSpecification(updatedSpecification);
      }
    },
    [sortedSpecifications, handleUpdateSpecification]
  );

  const handleDeleteRow = useCallback(
    (id: string) => {
      const [, rowId] = id.split('.');
      const withoutRow = [...currentSpecification];
      withoutRow.splice(parseInt(rowId, 10), 1);

      handleUpdateSpecification(withoutRow);
    },
    [handleUpdateSpecification, currentSpecification]
  );

  const service = useMemo(() => {
    const newService = {} as TableServiceType;
    newService.addRow = handleAddSpecificationRow;
    newService.deleteRow = handleDeleteRow;
    newService.updateField = handleUpdateCell;
    return newService;
  }, [handleAddSpecificationRow, handleDeleteRow, handleUpdateCell]);

  const rows: AgoyTableRow[] = useMemo(() => {
    return sortedSpecifications.map((item, index) => {
      const cells = {};

      columns.forEach((col) => {
        if (col.id === 'amount') {
          cells[col.id] = {
            type: 'number',
            value: item.amount ? ccyParse(parseFloat(item[col.id])) : undefined,
          };
        } else {
          cells[col.id] = {
            type: 'string',
            value: item[col.id],
          };
        }
      });
      return {
        id: `${index}`,
        active: true,
        cells,
      };
    });
  }, [sortedSpecifications]);

  useEffect(() => {
    setCurrentSpecification(specification);
  }, [specification]);

  return (
    <Table
      baseId="hidden"
      tableId="legacySpecification"
      rows={rows}
      columns={columns}
      service={service}
      canAddRows={!print}
      canDeleteRows={!print}
      editing={!print}
      isLocked={isLocked}
      print={print}
      onColumnBlur={handleUpdateCell}
    />
  );
};

export default SpecificationTable;

import React, { useState } from 'react';

type Period = {
  value: string | undefined;
  program: string | undefined;
};

type PeriodContextType = {
  period: Period;
  setPeriod: (period: Period) => void;
};

export const PeriodContext = React.createContext<PeriodContextType>({
  period: { value: undefined, program: undefined },
  setPeriod: () => {},
});

const withPeriod = (Component) => (props) => {
  const [period, setPeriod] = useState<Period>({ value: '', program: '' });

  return (
    <PeriodContext.Provider value={{ period, setPeriod }}>
      <Component {...props} />
    </PeriodContext.Provider>
  );
};

export default withPeriod;

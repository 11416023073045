import { AgoyDocumentPart } from '@agoy/document';
import { AnnualReportPart } from '../../../../document';
import {
  CustomNotes,
  HeadingsSection,
} from '../../../../common/types/noteSections';
import { customNoteContentDefinition } from '../../shared/customNotes';
import { note2001K3v2ContentDefinition } from './notes/note2001k3v2';
import { note2002K3v2ContentDefinition } from './notes/note2002k3v2';
import { note2003K3v2ContentDefinition } from './notes/note2003k3v2';
import { note2004K3v2ContentDefinition } from './notes/note2004k3v2';
import { note2005K3v2ContentDefinition } from './notes/note2005k3v2';
import { note2006K3v2ContentDefinition } from './notes/note2006k3v2';
import { note2007K3v2ContentDefinition } from './notes/note2007k3v2';
import { note2008K3v2ContentDefinition } from './notes/note2008k3v2';
import { note2009K3v2ContentDefinition } from './notes/note2009k3v2';
import { note2010K3v2ContentDefinition } from './notes/note2010k3v2';
import { note2011K3v2ContentDefinition } from './notes/note2011k3v2';
import { note2012K3v2ContentDefinition } from './notes/note2012k3v2';
import { note2013K3v2ContentDefinition } from './notes/note2013k3v2';
import { note2014K3v2ContentDefinition } from './notes/note2014k3v2';
import { note2015K3v2ContentDefinition } from './notes/note2015k3v2';
import { note2016K3v2ContentDefinition } from './notes/note2016k3v2';
import { note2017K3v2ContentDefinition } from './notes/note2017k3v2';
import { note2018K3v2ContentDefinition } from './notes/note2018k3v2';
import { note2019K3v2ContentDefinition } from './notes/note2019k3v2';
import { note2020K3v2ContentDefinition } from './notes/note2020k3v2';
import { note2021K3v2ContentDefinition } from './notes/note2021k3v2';
import { note2022K3v2ContentDefinition } from './notes/note2022k3v2';
import { note2023K3v2ContentDefinition } from './notes/note2023k3v2';
import { note2024K3v2ContentDefinition } from './notes/note2024k3v2';
import { note2025K3v2ContentDefinition } from './notes/note2025k3v2';
import { note2026K3v2ContentDefinition } from './notes/note2026k3v2';
import { note2027K3v2ContentDefinition } from './notes/note2027k3v2';
import { note2028K3v2ContentDefinition } from './notes/note2028k3v2';
import { note2029K3v2ContentDefinition } from './notes/note2029k3v2';
import { note2030K3v2ContentDefinition } from './notes/note2030k3v2';
import { note2031K3v2ContentDefinition } from './notes/note2031k3v2';
import { note2032K3v2ContentDefinition } from './notes/note2032k3v2';
import { note2033K3v2ContentDefinition } from './notes/note2033k3v2';
import { note2034K3v2ContentDefinition } from './notes/note2034k3v2';
import { note2035K3v2ContentDefinition } from './notes/note2035k3v2';
import { note2036K3v2ContentDefinition } from './notes/note2036k3v2';
import { note2037K3v2ContentDefinition } from './notes/note2037k3v2';
import { note2038K3v2ContentDefinition } from './notes/note2038k3v2';
import { note2039K3v2ContentDefinition } from './notes/note2039k3v2';
import { note2040K3v2ContentDefinition } from './notes/note2040k3v2';
import { note2041K3v2ContentDefinition } from './notes/note2041k3v2';
import { note2042K3v2ContentDefinition } from './notes/note2042k3v2';
import { note2043K3v2ContentDefinition } from './notes/note2043k3v2';
import { note2044K3v2ContentDefinition } from './notes/note2044k3v2';
import { note2045K3v2ContentDefinition } from './notes/note2045k3v2';
import { note2046K3v2ContentDefinition } from './notes/note2046k3v2';
import { note2047K3v2ContentDefinition } from './notes/note2047k3v2';
import { note2048K3v2ContentDefinition } from './notes/note2048k3v2';
import { note2049K3v2ContentDefinition } from './notes/note2049k3v2';
import { note2050K3v2ContentDefinition } from './notes/note2050k3v2';
import { note2051K3v2ContentDefinition } from './notes/note2051k3v2';
import { note2052K3v2ContentDefinition } from './notes/note2052k3v2';
import { note2053K3v2ContentDefinition } from './notes/note2053k3v2';
import { note2054K3v2ContentDefinition } from './notes/note2054k3v2';
import { note2055K3v2ContentDefinition } from './notes/note2055k3v2';
import { note2056K3v2ContentDefinition } from './notes/note2056k3v2';
import { note2057K3v2ContentDefinition } from './notes/note2057k3v2';
import { note2058K3v2ContentDefinition } from './notes/note2058k3v2';
import { note2059K3v2ContentDefinition } from './notes/note2059k3v2';
import { note2060K3v2ContentDefinition } from './notes/note2060k3v2';
import { note2061K3v2ContentDefinition } from './notes/note2061k3v2';
import { note2062K3v2ContentDefinition } from './notes/note2062k3v2';
import { note2063K3v2ContentDefinition } from './notes/note2063k3v2';
import { note2064K3v2ContentDefinition } from './notes/note2064k3v2';
import { note2065K3v2ContentDefinition } from './notes/note2065k3v2';
import { note2066K3v2ContentDefinition } from './notes/note2066k3v2';
import { note2067K3v2ContentDefinition } from './notes/note2067k3v2';
import { note2068K3v2ContentDefinition } from './notes/note2068k3v2';
import { note2069K3v2ContentDefinition } from './notes/note2069k3v2';
import { note2070K3v2ContentDefinition } from './notes/note2070k3v2';
import { note2071K3v2ContentDefinition } from './notes/note2071k3v2';
import { note2072K3v2ContentDefinition } from './notes/note2072k3v2';
import { note2073K3v2ContentDefinition } from './notes/note2073k3v2';
import { note2074K3v2ContentDefinition } from './notes/note2074k3v2';
import { note2075K3v2ContentDefinition } from './notes/note2075k3v2';
import { note2076K3v2ContentDefinition } from './notes/note2076k3v2';
import { note2077K3v2ContentDefinition } from './notes/note2077k3v2';
import { note2078K3v2ContentDefinition } from './notes/note2078k3v2';
import { note2079K3v2ContentDefinition } from './notes/note2079k3v2';
import { note2080K3v2ContentDefinition } from './notes/note2080k3v2';
import { note2081K3v2ContentDefinition } from './notes/note2081k3v2';
import { note2082K3v2ContentDefinition } from './notes/note2082k3v2';
import { note2083K3v2ContentDefinition } from './notes/note2083k3v2';
import { note2084K3v2ContentDefinition } from './notes/note2084k3v2';
import { note2085K3v2ContentDefinition } from './notes/note2085k3v2';
import { note2086K3v2ContentDefinition } from './notes/note2086k3v2';
import { note2087K3v2ContentDefinition } from './notes/note2087k3v2';
import { note2088K3v2ContentDefinition } from './notes/note2088k3v2';
import { note2089K3v2ContentDefinition } from './notes/note2089k3v2';
import { note2090K3v2ContentDefinition } from './notes/note2090k3v2';
import { note2091K3v2ContentDefinition } from './notes/note2091k3v2';
import { note2092K3v2ContentDefinition } from './notes/note2092k3v2';
import { note2093K3v2ContentDefinition } from './notes/note2093k3v2';
import { note2094K3v2ContentDefinition } from './notes/note2094k3v2';
import { note2095K3v2ContentDefinition } from './notes/note2095k3v2';
import { note2096K3v2ContentDefinition } from './notes/note2096k3v2';
import { note2097K3v2ContentDefinition } from './notes/note2097k3v2';
import { note2098K3v2ContentDefinition } from './notes/note2098k3v2';
import { note2099K3v2ContentDefinition } from './notes/note2099k3v2';
import { note2100K3v2ContentDefinition } from './notes/note2100k3v2';
import { note2101K3v2ContentDefinition } from './notes/note2101k3v2';

export type NoteAccountRangesAction =
  | 'sumAccountsIBs'
  | 'sumAccountsUBs'
  | 'sumAccounts'
  | 'sumPositiveTransactions'
  | 'sumNegativeTransactions';

export type NoteAccountRanges = {
  rowId: string;
  ranges: number[][];
  cellLabel: string;
  action: NoteAccountRangesAction;
};

export type GeneratedSharesV2K3Notes = {
  headings: HeadingsSection;
  note1: AgoyDocumentPart<typeof note2001K3v2ContentDefinition>;
  note2: AgoyDocumentPart<typeof note2002K3v2ContentDefinition>;
  note3: AgoyDocumentPart<typeof note2003K3v2ContentDefinition>;
  note4: AgoyDocumentPart<typeof note2004K3v2ContentDefinition>;
  note5: AgoyDocumentPart<typeof note2005K3v2ContentDefinition>;
  note6: AgoyDocumentPart<typeof note2006K3v2ContentDefinition>;
  note7: AgoyDocumentPart<typeof note2007K3v2ContentDefinition>;
  note8: AgoyDocumentPart<typeof note2008K3v2ContentDefinition>;
  note9: AgoyDocumentPart<typeof note2009K3v2ContentDefinition>;
  note10: AgoyDocumentPart<typeof note2010K3v2ContentDefinition>;
  note11: AgoyDocumentPart<typeof note2011K3v2ContentDefinition>;
  note12: AgoyDocumentPart<typeof note2012K3v2ContentDefinition>;
  note13: AgoyDocumentPart<typeof note2013K3v2ContentDefinition>;
  note14: AgoyDocumentPart<typeof note2014K3v2ContentDefinition>;
  note15: AgoyDocumentPart<typeof note2015K3v2ContentDefinition>;
  note16: AgoyDocumentPart<typeof note2016K3v2ContentDefinition>;
  note17: AgoyDocumentPart<typeof note2017K3v2ContentDefinition>;
  note18: AgoyDocumentPart<typeof note2018K3v2ContentDefinition>;
  note19: AgoyDocumentPart<typeof note2019K3v2ContentDefinition>;
  note20: AgoyDocumentPart<typeof note2020K3v2ContentDefinition>;
  note21: AgoyDocumentPart<typeof note2021K3v2ContentDefinition>;
  note22: AgoyDocumentPart<typeof note2022K3v2ContentDefinition>;
  note23: AgoyDocumentPart<typeof note2023K3v2ContentDefinition>;
  note24: AgoyDocumentPart<typeof note2024K3v2ContentDefinition>;
  note25: AgoyDocumentPart<typeof note2025K3v2ContentDefinition>;
  note26: AgoyDocumentPart<typeof note2026K3v2ContentDefinition>;
  note27: AgoyDocumentPart<typeof note2027K3v2ContentDefinition>;
  note28: AgoyDocumentPart<typeof note2028K3v2ContentDefinition>;
  note29: AgoyDocumentPart<typeof note2029K3v2ContentDefinition>;
  note30: AgoyDocumentPart<typeof note2030K3v2ContentDefinition>;
  note31: AgoyDocumentPart<typeof note2031K3v2ContentDefinition>;
  note32: AgoyDocumentPart<typeof note2032K3v2ContentDefinition>;
  note33: AgoyDocumentPart<typeof note2033K3v2ContentDefinition>;
  note34: AgoyDocumentPart<typeof note2034K3v2ContentDefinition>;
  note35: AgoyDocumentPart<typeof note2035K3v2ContentDefinition>;
  note36: AgoyDocumentPart<typeof note2036K3v2ContentDefinition>;
  note37: AgoyDocumentPart<typeof note2037K3v2ContentDefinition>;
  note38: AgoyDocumentPart<typeof note2038K3v2ContentDefinition>;
  note39: AgoyDocumentPart<typeof note2039K3v2ContentDefinition>;
  note40: AgoyDocumentPart<typeof note2040K3v2ContentDefinition>;
  note41: AgoyDocumentPart<typeof note2041K3v2ContentDefinition>;
  note42: AgoyDocumentPart<typeof note2042K3v2ContentDefinition>;
  note43: AgoyDocumentPart<typeof note2043K3v2ContentDefinition>;
  note44: AgoyDocumentPart<typeof note2044K3v2ContentDefinition>;
  note45: AgoyDocumentPart<typeof note2045K3v2ContentDefinition>;
  note46: AgoyDocumentPart<typeof note2046K3v2ContentDefinition>;
  note47: AgoyDocumentPart<typeof note2047K3v2ContentDefinition>;
  note48: AgoyDocumentPart<typeof note2048K3v2ContentDefinition>;
  note49: AgoyDocumentPart<typeof note2049K3v2ContentDefinition>;
  note50: AgoyDocumentPart<typeof note2050K3v2ContentDefinition>;
  note51: AgoyDocumentPart<typeof note2051K3v2ContentDefinition>;
  note52: AgoyDocumentPart<typeof note2052K3v2ContentDefinition>;
  note53: AgoyDocumentPart<typeof note2053K3v2ContentDefinition>;
  note54: AgoyDocumentPart<typeof note2054K3v2ContentDefinition>;
  note55: AgoyDocumentPart<typeof note2055K3v2ContentDefinition>;
  note56: AgoyDocumentPart<typeof note2056K3v2ContentDefinition>;
  note57: AgoyDocumentPart<typeof note2057K3v2ContentDefinition>;
  note58: AgoyDocumentPart<typeof note2058K3v2ContentDefinition>;
  note59: AgoyDocumentPart<typeof note2059K3v2ContentDefinition>;
  note60: AgoyDocumentPart<typeof note2060K3v2ContentDefinition>;
  note61: AgoyDocumentPart<typeof note2061K3v2ContentDefinition>;
  note62: AgoyDocumentPart<typeof note2062K3v2ContentDefinition>;
  note63: AgoyDocumentPart<typeof note2063K3v2ContentDefinition>;
  note64: AgoyDocumentPart<typeof note2064K3v2ContentDefinition>;
  note65: AgoyDocumentPart<typeof note2065K3v2ContentDefinition>;
  note66: AgoyDocumentPart<typeof note2066K3v2ContentDefinition>;
  note67: AgoyDocumentPart<typeof note2067K3v2ContentDefinition>;
  note68: AgoyDocumentPart<typeof note2068K3v2ContentDefinition>;
  note69: AgoyDocumentPart<typeof note2069K3v2ContentDefinition>;
  note70: AgoyDocumentPart<typeof note2070K3v2ContentDefinition>;
  note71: AgoyDocumentPart<typeof note2071K3v2ContentDefinition>;
  note72: AgoyDocumentPart<typeof note2072K3v2ContentDefinition>;
  note73: AgoyDocumentPart<typeof note2073K3v2ContentDefinition>;
  note74: AgoyDocumentPart<typeof note2074K3v2ContentDefinition>;
  note75: AgoyDocumentPart<typeof note2075K3v2ContentDefinition>;
  note76: AgoyDocumentPart<typeof note2076K3v2ContentDefinition>;
  note77: AgoyDocumentPart<typeof note2077K3v2ContentDefinition>;
  note78: AgoyDocumentPart<typeof note2078K3v2ContentDefinition>;
  note79: AgoyDocumentPart<typeof note2079K3v2ContentDefinition>;
  note80: AgoyDocumentPart<typeof note2080K3v2ContentDefinition>;
  note81: AgoyDocumentPart<typeof note2081K3v2ContentDefinition>;
  note82: AgoyDocumentPart<typeof note2082K3v2ContentDefinition>;
  note83: AgoyDocumentPart<typeof note2083K3v2ContentDefinition>;
  note84: AgoyDocumentPart<typeof note2084K3v2ContentDefinition>;
  note85: AgoyDocumentPart<typeof note2085K3v2ContentDefinition>;
  note86: AgoyDocumentPart<typeof note2086K3v2ContentDefinition>;
  note87: AgoyDocumentPart<typeof note2087K3v2ContentDefinition>;
  note88: AgoyDocumentPart<typeof note2088K3v2ContentDefinition>;
  note89: AgoyDocumentPart<typeof note2089K3v2ContentDefinition>;
  note90: AgoyDocumentPart<typeof note2090K3v2ContentDefinition>;
  note91: AgoyDocumentPart<typeof note2091K3v2ContentDefinition>;
  note92: AgoyDocumentPart<typeof note2092K3v2ContentDefinition>;
  note93: AgoyDocumentPart<typeof note2093K3v2ContentDefinition>;
  note94: AgoyDocumentPart<typeof note2094K3v2ContentDefinition>;
  note95: AgoyDocumentPart<typeof note2095K3v2ContentDefinition>;
  note96: AgoyDocumentPart<typeof note2096K3v2ContentDefinition>;
  note97: AgoyDocumentPart<typeof note2097K3v2ContentDefinition>;
  note98: AgoyDocumentPart<typeof note2098K3v2ContentDefinition>;
  note99: AgoyDocumentPart<typeof note2099K3v2ContentDefinition>;
  note100: AgoyDocumentPart<typeof note2100K3v2ContentDefinition>;
  note101: AgoyDocumentPart<typeof note2101K3v2ContentDefinition>;
  custom: CustomNotes;
} & AnnualReportPart;

const cell = { type: 'field' as const };
const boolean = { type: 'boolean' as const };

export const generatedV2K3NotesType = {
  type: 'part' as const,
  children: {
    active: boolean,
    headings: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        notesIncomeStatementTitle: cell,
        notesBalanceSheetTitle: cell,
        notesOtherTitle: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note1: note2001K3v2ContentDefinition,
    note2: note2002K3v2ContentDefinition,
    note3: note2003K3v2ContentDefinition,
    note4: note2004K3v2ContentDefinition,
    note5: note2005K3v2ContentDefinition,
    note6: note2006K3v2ContentDefinition,
    note7: note2007K3v2ContentDefinition,
    note8: note2008K3v2ContentDefinition,
    note9: note2009K3v2ContentDefinition,
    note10: note2010K3v2ContentDefinition,
    note11: note2011K3v2ContentDefinition,
    note12: note2012K3v2ContentDefinition,
    note13: note2013K3v2ContentDefinition,
    note14: note2014K3v2ContentDefinition,
    note15: note2015K3v2ContentDefinition,
    note16: note2016K3v2ContentDefinition,
    note17: note2017K3v2ContentDefinition,
    note18: note2018K3v2ContentDefinition,
    note19: note2019K3v2ContentDefinition,
    note20: note2020K3v2ContentDefinition,
    note21: note2021K3v2ContentDefinition,
    note22: note2022K3v2ContentDefinition,
    note23: note2023K3v2ContentDefinition,
    note24: note2024K3v2ContentDefinition,
    note25: note2025K3v2ContentDefinition,
    note26: note2026K3v2ContentDefinition,
    note27: note2027K3v2ContentDefinition,
    note28: note2028K3v2ContentDefinition,
    note29: note2029K3v2ContentDefinition,
    note30: note2030K3v2ContentDefinition,
    note31: note2031K3v2ContentDefinition,
    note32: note2032K3v2ContentDefinition,
    note33: note2033K3v2ContentDefinition,
    note34: note2034K3v2ContentDefinition,
    note35: note2035K3v2ContentDefinition,
    note36: note2036K3v2ContentDefinition,
    note37: note2037K3v2ContentDefinition,
    note38: note2038K3v2ContentDefinition,
    note39: note2039K3v2ContentDefinition,
    note40: note2040K3v2ContentDefinition,
    note41: note2041K3v2ContentDefinition,
    note42: note2042K3v2ContentDefinition,
    note43: note2043K3v2ContentDefinition,
    note44: note2044K3v2ContentDefinition,
    note45: note2045K3v2ContentDefinition,
    note46: note2046K3v2ContentDefinition,
    note47: note2047K3v2ContentDefinition,
    note48: note2048K3v2ContentDefinition,
    note49: note2049K3v2ContentDefinition,
    note50: note2050K3v2ContentDefinition,
    note51: note2051K3v2ContentDefinition,
    note52: note2052K3v2ContentDefinition,
    note53: note2053K3v2ContentDefinition,
    note54: note2054K3v2ContentDefinition,
    note55: note2055K3v2ContentDefinition,
    note56: note2056K3v2ContentDefinition,
    note57: note2057K3v2ContentDefinition,
    note58: note2058K3v2ContentDefinition,
    note59: note2059K3v2ContentDefinition,
    note60: note2060K3v2ContentDefinition,
    note61: note2061K3v2ContentDefinition,
    note62: note2062K3v2ContentDefinition,
    note63: note2063K3v2ContentDefinition,
    note64: note2064K3v2ContentDefinition,
    note65: note2065K3v2ContentDefinition,
    note66: note2066K3v2ContentDefinition,
    note67: note2067K3v2ContentDefinition,
    note68: note2068K3v2ContentDefinition,
    note69: note2069K3v2ContentDefinition,
    note70: note2070K3v2ContentDefinition,
    note71: note2071K3v2ContentDefinition,
    note72: note2072K3v2ContentDefinition,
    note73: note2073K3v2ContentDefinition,
    note74: note2074K3v2ContentDefinition,
    note75: note2075K3v2ContentDefinition,
    note76: note2076K3v2ContentDefinition,
    note77: note2077K3v2ContentDefinition,
    note78: note2078K3v2ContentDefinition,
    note79: note2079K3v2ContentDefinition,
    note80: note2080K3v2ContentDefinition,
    note81: note2081K3v2ContentDefinition,
    note82: note2082K3v2ContentDefinition,
    note83: note2083K3v2ContentDefinition,
    note84: note2084K3v2ContentDefinition,
    note85: note2085K3v2ContentDefinition,
    note86: note2086K3v2ContentDefinition,
    note87: note2087K3v2ContentDefinition,
    note88: note2088K3v2ContentDefinition,
    note89: note2089K3v2ContentDefinition,
    note90: note2090K3v2ContentDefinition,
    note91: note2091K3v2ContentDefinition,
    note92: note2092K3v2ContentDefinition,
    note93: note2093K3v2ContentDefinition,
    note94: note2094K3v2ContentDefinition,
    note95: note2095K3v2ContentDefinition,
    note96: note2096K3v2ContentDefinition,
    note97: note2097K3v2ContentDefinition,
    note98: note2098K3v2ContentDefinition,
    note99: note2099K3v2ContentDefinition,
    note100: note2100K3v2ContentDefinition,
    note101: note2101K3v2ContentDefinition,
    custom: customNoteContentDefinition,
  },
};

import { insertAt } from './helpers';
import findRow from './helpers/findRow';
import updateRow from './helpers/updateRow';
import updateTable from './helpers/updateTable';
import updateDocument from './helpers/updateDocument';
import { AgoyDocumentStructure } from '../document';
import { OperationResult, State } from './types';
import { AgoyTable, AgoyTableRow } from '../table';
import { AddRowChange, TableChange, UpdateRowChange } from '../changes';
import { createNewRow } from '../createNewRow';

export const insertBySortKey = (
  rows: AgoyTableRow[],
  newRow: AgoyTableRow
): AgoyTableRow[] => {
  const newRowSortKey = newRow.sortKey;
  if (newRowSortKey !== undefined) {
    const index = rows.findIndex(
      (row) => row.sortKey === undefined || row.sortKey > newRowSortKey
    );
    if (index !== -1) {
      return insertAt(rows, newRow, index);
    }
  }
  return [...rows, newRow];
};

const addTableRow = <T extends AgoyDocumentStructure>(
  structure: T,
  state: State<T>,
  id: string,
  rowId?: string,
  cellParameters?: Record<string, string>,
  copyId?: string,
  sortKey?: number
): OperationResult<T> => {
  return updateDocument(structure, state, id, {
    table: (subId, tableId, props) => {
      const copyRow =
        copyId && copyId.startsWith(tableId)
          ? findRow(copyId.substring(tableId.length + 1), props.node)
          : undefined;

      const changes = props.changes || { type: 'update' };

      if (subId && subId.length > 0) {
        const addSubRow = (
          row: AgoyTableRow,
          change: UpdateRowChange
        ): [AgoyTableRow, UpdateRowChange] => {
          if (row.newRowTemplate && row.rows) {
            const newRow =
              copyRow ||
              createNewRow(
                row.newRowTemplate,
                id,
                rowId,
                cellParameters,
                sortKey
              );

            const addChange: AddRowChange = cellParameters
              ? { type: 'add', id: newRow.id, params: cellParameters }
              : { type: 'add', id: newRow.id };

            return [
              { ...row, rows: insertBySortKey(row.rows, newRow) },
              {
                ...change,
                id: row.id,
                rows: [...(change.rows || []), addChange],
              },
            ];
          }
          return [row, change];
        };
        const updater = updateRow(subId, addSubRow);
        const result = updater(props.node, changes);
        if (Array.isArray(result)) {
          if (result[0] !== props.node || result[1] !== changes) {
            return {
              ...props,
              node: result[0],
              changes: result[1],
            };
          }
        }
        return props;
      }
      const addRow = (
        table: AgoyTable,
        change: TableChange
      ): [AgoyTable, TableChange] => {
        if (table.newRowTemplate && table.rows) {
          const newRow = createNewRow(
            table.newRowTemplate,
            id,
            rowId,
            cellParameters,
            sortKey
          );

          const addChange: AddRowChange = cellParameters
            ? { type: 'add', id: newRow.id, params: cellParameters }
            : { type: 'add', id: newRow.id };

          return [
            { ...table, rows: insertBySortKey(table.rows, newRow) },
            {
              ...change,
              rows: [
                ...((change?.type === 'update' && change.rows) || []),
                addChange,
              ],
            },
          ];
        }
        throw new Error('Row needs newRowTemplate and rows to add more');
      };
      const result = updateTable(props.node, changes, addRow);
      if (Array.isArray(result)) {
        if (result[0] !== props.node || result[1] !== changes)
          return {
            ...props,
            node: result[0],
            changes: result[1],
          };
      }
      return props;
    },
  });
};

export default addTableRow;

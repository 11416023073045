import React from 'react';
import styled from '@emotion/styled';
import { Tooltip } from '@material-ui/core';
import { getClasses } from '@agoy/common';

const NumberCell = styled.div<{
  warning?: string;
}>`
  position: relative;
  display: inline-block;
  text-align: right;
  width: 100%;

  ${({ warning, theme }) => {
    return warning ? `color: ${theme.palette.error.main}` : '';
  }};
`;

const CellWithDecimals = styled.span<{
  isSumValueWithDecimals?: boolean;
  decimals?: string;
}>`
  display: inline-block;
  position: relative;
  padding-right: 0;
  transition: padding-right 0.2s ease-in-out;
  font-weight: inherit;
  font-size: inherit;

  &::after {
    display: inline-block;
    position: absolute;
    content: '';
    transition: opacity 0.2s ease-in-out;
    opacity: 0;
    font-style: italic;
  }

  &.has-decimals {
    &.sum::before {
      content: '\u002A ';
      vertical-align: sup;
      position: absolute;
      font-weight: 400;
      left: -7px;
    }
  }

  &.show-decimals {
    padding-right: ${({ decimals }) => (decimals ? '20px' : '0')};
    transition: padding-right 0.2s ease-in-out;
    &::after {
      content: '${({ decimals }) => decimals}';
      opacity: 0.6;
      transition: opacity 0.2s ease-in-out 0.2s;
    }

    &.sum::after {
      opacity: 1;
      font-style: normal;
    }
  }
`;

interface NumberCellWithHoverProps {
  className?: string;
  active?: boolean;
  showHoveredValues?: boolean;
  displayValue?: number;
  numberFormat: (value: number | undefined, decimals?: number) => string;
  cellValue?: number;
  sumCell?: boolean;
  warning?: string;
}

const NumberCellWithHover = ({
  active,
  displayValue,
  className,
  numberFormat,
  cellValue,
  showHoveredValues = false,
  sumCell = false,
  warning = '',
}: NumberCellWithHoverProps) => {
  const hasDecimals = !!cellValue && cellValue % 1 !== 0;
  const actualValue =
    showHoveredValues && hasDecimals
      ? numberFormat(displayValue, 2).slice(0, -3)
      : numberFormat(displayValue);
  const decimals = hasDecimals
    ? numberFormat(displayValue, 2)?.toString().slice(-3)
    : '';

  const classNames = getClasses({
    'has-decimals': hasDecimals,
    'show-decimals': showHoveredValues && hasDecimals,
    sum: sumCell,
  });

  return (
    <Tooltip title={warning} placement="bottom-end">
      <NumberCell className={className} warning={warning}>
        <CellWithDecimals className={classNames} decimals={decimals}>
          {!active || cellValue === undefined ? '' : actualValue}
        </CellWithDecimals>
      </NumberCell>
    </Tooltip>
  );
};

export default NumberCellWithHover;

// This is a GENERATED file, do not change!
// Generated:
// - from file data/k3-arsredovisning-2021-10-31-rev20230131 - Noter.csv
// - on 2023-11-09 06:22:52.045412
// - Comments: AGOY-6798
// - Generator git hash: 2f0592403e9bb8e6eeeba0444391b4c8b9b48eaf

import {
  value,
  ref,
  table,
  or,
  sum,
  id,
  ReferenceAccountInformation,
  account,
  RowsBuilder,
  multiply,
} from '@agoy/document';
import { IxbrlCell, NoteThreeCommentTwoTableSection } from '../../../../common';
import {
  active,
  field,
  ixbrlCell,
  ixbrlField,
  range,
  tupleRef,
} from '../../../../common/utils/util';
import { previousYear, year } from '../../../../common/utils/date-util';
import {
  sumAccountsIBs,
  sumAccountsUBs,
  sumNegativeTransactions,
  sumPositiveTransactions,
} from '../../../../common/utils/accountSumsUtil';
import { NoteAccountRanges } from '../types';

export const note401Accounts: NoteAccountRanges[] = [
  //--- begin note_account_ranges_body (level 2) [notes.note401.table.AgarintressenOvrigaForetagAnskaffningsvardenIngaende] ---
  //--- [1055: rowSumAccounts(AgarintressenOvrigaForetagAnskaffningsvarden, 1336, sumAccountsIBs; period_base:=1, AgarintressenOvrigaForetagAnskaffningsvardenIngaende, nan)] ---
  {
    rowId:
      'notes.note401.table.AgarintressenOvrigaForetagAnskaffningsvardenIngaende',
    ranges: [[1336]],
    cellLabel: 'Ingående anskaffningsvärden',
    action: 'sumAccountsIBs',
  },
  //--- end note_account_ranges_body ---
  //--- begin note_account_ranges_body (level 2) [notes.note401.table.AgarintressenOvrigaForetagNedskrivningarIngaende] ---
  //--- [1076: rowSumAccounts(AgarintressenOvrigaForetagNedskrivningar, 1337, sumAccountsIBs; period_base:=1, AgarintressenOvrigaForetagNedskrivningarIngaende, nan)] ---
  {
    rowId:
      'notes.note401.table.AgarintressenOvrigaForetagNedskrivningarIngaende',
    ranges: [[1337]],
    cellLabel: 'Ingående nedskrivningar',
    action: 'sumAccountsIBs',
  },
  //--- end note_account_ranges_body ---
];

//--- begin note_header (level 0) [notes.note401] ---
//--- [1053: beginNote(NotAgarintressenOvrigaForetag, nan, noteTableSectionType:=NoteThreeCommentTwoTableSection; k2:=false; k3:= documentTypeVersion !== '1'; documentTypeVersion:='1', notes.note401, AGOY-6798)] ---
export const getNote401 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null,
  documentTypeVersion = '1'
): NoteThreeCommentTwoTableSection => {
  return {
    active: false,
    number: value(undefined),
    name: value('Ägarintressen i övriga företag'),
    //--- end note_header ---
    //--- begin default_table_header (level 1) [notes.note401.table] ---
    //--- [1054: tableHeader(AgarintressenOvrigaForetagAbstract, nan, nan, table, nan)] ---
    table: table<IxbrlCell>(
      'notes.note401.table',
      { id: 'label', label: '' },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      .addRows((rows) => {
        //--- end default_table_header ---
        //--- begin row_sum_accounts (level 2) [notes.note401.table.AgarintressenOvrigaForetagAnskaffningsvardenIngaende] ---
        //--- [1055: rowSumAccounts(AgarintressenOvrigaForetagAnskaffningsvarden, 1336, sumAccountsIBs; period_base:=1, AgarintressenOvrigaForetagAnskaffningsvardenIngaende, nan)] ---
        rows.addRow(
          'AgarintressenOvrigaForetagAnskaffningsvardenIngaende',
          noteLabel('Ingående anskaffningsvärden'),
          ixbrlCell(ref(sumAccountsIBs('1336', false)), {
            type: 'monetaryItemType',
            name: 'se-gen-base: AgarintressenOvrigaForetagAnskaffningsvarden',
            contextRef: 'balans1',
            standardRubrik: 'Anskaffningsvärden ägarintressen i övriga företag',
            saldo: 'debit',
          }),
          ixbrlCell(ref(sumAccountsIBs('1336', false, 'previousYear')), {
            type: 'monetaryItemType',
            name: 'se-gen-base: AgarintressenOvrigaForetagAnskaffningsvarden',
            contextRef: 'balans2',
            standardRubrik: 'Anskaffningsvärden ägarintressen i övriga företag',
            saldo: 'debit',
          })
        );
        //--- end row_sum_accounts ---
        //--- begin row_header (level 2) [notes.note401.table.AgarintressenOvrigaForetagAnskaffningsvardenAbstract] ---
        //--- [1056: rowHeader(AgarintressenOvrigaForetagAnskaffningsvardenAbstract, nan, nan, nan, nan)] ---
        rows.addRow(
          'AgarintressenOvrigaForetagAnskaffningsvardenAbstract',
          noteLabel('Förändringar av anskaffningsvärden'),
          undefined,
          undefined
        );
        //--- end row_header ---
        //--- begin row_sum_rows_in_table (level 2) [notes.note401.table.AgarintressenOvrigaForetagAnskaffningsvardenInkop] ---
        //--- [1057: rowEmptyValue(AgarintressenOvrigaForetagAnskaffningsvardenInkop, nan, nan, nan, nan)] ---
        rows.addRow(
          'AgarintressenOvrigaForetagAnskaffningsvardenInkop',
          noteLabel('Inköp'),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base: AgarintressenOvrigaForetagAnskaffningsvardenInkop',
            contextRef: 'period0',
            standardRubrik:
              'Ökning av anskaffningsvärden i samband med inköp av ägarintressen i övriga företag',
          }),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base: AgarintressenOvrigaForetagAnskaffningsvardenInkop',
            contextRef: 'period1',
            standardRubrik:
              'Ökning av anskaffningsvärden i samband med inköp av ägarintressen i övriga företag',
          })
        );
        //--- end row_sum_rows_in_table ---
        //--- begin row_sum_rows_in_table (level 2) [notes.note401.table.AgarintressenOvrigaForetagAnskaffningsvardenFusion] ---
        //--- [1058: rowEmptyValue(AgarintressenOvrigaForetagAnskaffningsvardenFusion, nan, nan, nan, nan)] ---
        rows.addRow(
          'AgarintressenOvrigaForetagAnskaffningsvardenFusion',
          noteLabel('Fusion'),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base: AgarintressenOvrigaForetagAnskaffningsvardenFusion',
            contextRef: 'period0',
            standardRubrik:
              'Ökning (minskning) av anskaffningsvärden för ägarintressen i övriga företag genom fusion',
          }),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base: AgarintressenOvrigaForetagAnskaffningsvardenFusion',
            contextRef: 'period1',
            standardRubrik:
              'Ökning (minskning) av anskaffningsvärden för ägarintressen i övriga företag genom fusion',
          })
        );
        //--- end row_sum_rows_in_table ---
        //--- begin row_sum_rows_in_table (level 2) [notes.note401.table.AgarintressenOvrigaForetagAnskaffningsvardenForsaljningar] ---
        //--- [1059: rowEmptyValue(AgarintressenOvrigaForetagAnskaffningsvardenForsaljningar, nan, nan, nan, nan)] ---
        rows.addRow(
          'AgarintressenOvrigaForetagAnskaffningsvardenForsaljningar',
          noteLabel('Försäljningar'),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base: AgarintressenOvrigaForetagAnskaffningsvardenForsaljningar',
            contextRef: 'period0',
            standardRubrik:
              'Minskning av anskaffningsvärden i samband med försäljningar av ägarintressen i övriga företag',
          }),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base: AgarintressenOvrigaForetagAnskaffningsvardenForsaljningar',
            contextRef: 'period1',
            standardRubrik:
              'Minskning av anskaffningsvärden i samband med försäljningar av ägarintressen i övriga företag',
          })
        );
        //--- end row_sum_rows_in_table ---
        //--- begin row_sum_rows_in_table (level 2) [notes.note401.table.AgarintressenOvrigaForetagAnskaffningsvardenLamnadeAktieagartillskott] ---
        //--- [1060: rowEmptyValue(AgarintressenOvrigaForetagAnskaffningsvardenLamnadeAktieagartillskott, nan, nan, nan, nan)] ---
        rows.addRow(
          'AgarintressenOvrigaForetagAnskaffningsvardenLamnadeAktieagartillskott',
          noteLabel('Lämnade aktieägartillskott'),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base: AgarintressenOvrigaForetagAnskaffningsvardenLamnadeAktieagartillskott',
            contextRef: 'period0',
            standardRubrik:
              'Ökning av anskaffningsvärden i samband med lämnande aktieägartillskott till ägarintressen i övriga företag',
          }),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base: AgarintressenOvrigaForetagAnskaffningsvardenLamnadeAktieagartillskott',
            contextRef: 'period1',
            standardRubrik:
              'Ökning av anskaffningsvärden i samband med lämnande aktieägartillskott till ägarintressen i övriga företag',
          })
        );
        //--- end row_sum_rows_in_table ---
        //--- begin row_sum_rows_in_table (level 2) [notes.note401.table.AgarintressenOvrigaForetagAnskaffningsvardenAterbetalningLamnatAktieagartillskott] ---
        //--- [1061: rowEmptyValue(AgarintressenOvrigaForetagAnskaffningsvardenAterbetalningLamnatAktieagartillskott, nan, nan, nan, nan)] ---
        rows.addRow(
          'AgarintressenOvrigaForetagAnskaffningsvardenAterbetalningLamnatAktieagartillskott',
          noteLabel('Återbetalning av lämnat aktieägartillskott'),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base: AgarintressenOvrigaForetagAnskaffningsvardenAterbetalningLamnatAktieagartillskott',
            contextRef: 'period0',
            standardRubrik:
              'Minskning av anskaffningsvärden i samband med återbetalning av lämnat aktieägartillskott till ägarintressen i övriga företag',
          }),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base: AgarintressenOvrigaForetagAnskaffningsvardenAterbetalningLamnatAktieagartillskott',
            contextRef: 'period1',
            standardRubrik:
              'Minskning av anskaffningsvärden i samband med återbetalning av lämnat aktieägartillskott till ägarintressen i övriga företag',
          })
        );
        //--- end row_sum_rows_in_table ---
        //--- begin row_sum_rows_in_table (level 2) [notes.note401.table.AgarintressenOvrigaForetagAnskaffningsvardenAretsResultatAndel] ---
        //--- [1062: rowEmptyValue(AgarintressenOvrigaForetagAnskaffningsvardenAretsResultatAndel, nan, nan, nan, nan)] ---
        rows.addRow(
          'AgarintressenOvrigaForetagAnskaffningsvardenAretsResultatAndel',
          noteLabel('Årets resultatandel'),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base: AgarintressenOvrigaForetagAnskaffningsvardenAretsResultatAndel',
            contextRef: 'period0',
            standardRubrik:
              'Ökning (minskning) av anskaffningsvärden i samband med periodens resultatandel i handelsbolag som är ägarintressen i övriga företag',
          }),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base: AgarintressenOvrigaForetagAnskaffningsvardenAretsResultatAndel',
            contextRef: 'period1',
            standardRubrik:
              'Ökning (minskning) av anskaffningsvärden i samband med periodens resultatandel i handelsbolag som är ägarintressen i övriga företag',
          })
        );
        //--- end row_sum_rows_in_table ---
        //--- begin row_sum_rows_in_table (level 2) [notes.note401.table.AgarintressenOvrigaForetagAnskaffningsvardenOmklassificeringar] ---
        //--- [1063: rowEmptyValue(AgarintressenOvrigaForetagAnskaffningsvardenOmklassificeringar, nan, nan, nan, nan)] ---
        rows.addRow(
          'AgarintressenOvrigaForetagAnskaffningsvardenOmklassificeringar',
          noteLabel('Omklassificeringar'),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base: AgarintressenOvrigaForetagAnskaffningsvardenOmklassificeringar',
            contextRef: 'period0',
            standardRubrik:
              'Ökning (minskning) av anskaffningsvärden i samband med omklassificeringar av ägarintressen i övriga företag',
          }),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base: AgarintressenOvrigaForetagAnskaffningsvardenOmklassificeringar',
            contextRef: 'period1',
            standardRubrik:
              'Ökning (minskning) av anskaffningsvärden i samband med omklassificeringar av ägarintressen i övriga företag',
          })
        );
        //--- end row_sum_rows_in_table ---
        //--- begin row_sum_rows_in_table (level 2) [notes.note401.table.AgarintressenOvrigaForetagAnskaffningsvardenUtdelning] ---
        //--- [1064: rowEmptyValue(AgarintressenOvrigaForetagAnskaffningsvardenUtdelning, nan, nan, nan, nan)] ---
        rows.addRow(
          'AgarintressenOvrigaForetagAnskaffningsvardenUtdelning',
          noteLabel('Utdelning'),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base: AgarintressenOvrigaForetagAnskaffningsvardenUtdelning',
            contextRef: 'period0',
            standardRubrik:
              'Minskning av anskaffningsvärden i samband med utdelning, ägarintressen i övriga företag',
          }),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base: AgarintressenOvrigaForetagAnskaffningsvardenUtdelning',
            contextRef: 'period1',
            standardRubrik:
              'Minskning av anskaffningsvärden i samband med utdelning, ägarintressen i övriga företag',
          })
        );
        //--- end row_sum_rows_in_table ---
        //--- begin row_sum_rows_in_table (level 2) [notes.note401.table.AgarintressenOvrigaForetagAnskaffningsvardenOmrakningsdifferenser] ---
        //--- [1065: rowEmptyValue(AgarintressenOvrigaForetagAnskaffningsvardenOmrakningsdifferenser, nan, nan, nan, nan)] ---
        rows.addRow(
          'AgarintressenOvrigaForetagAnskaffningsvardenOmrakningsdifferenser',
          noteLabel('Omräkningsdifferenser'),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base: AgarintressenOvrigaForetagAnskaffningsvardenOmrakningsdifferenser',
            contextRef: 'period0',
            standardRubrik:
              'Ökning (minskning) av anskaffningsvärden i samband med omräkningsdifferenser av ägarintressen i övriga företag',
          }),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base: AgarintressenOvrigaForetagAnskaffningsvardenOmrakningsdifferenser',
            contextRef: 'period1',
            standardRubrik:
              'Ökning (minskning) av anskaffningsvärden i samband med omräkningsdifferenser av ägarintressen i övriga företag',
          })
        );
        //--- end row_sum_rows_in_table ---
        //--- begin row_sum_rows_in_table (level 2) [notes.note401.table.AgarintressenOvrigaForetagAnskaffningsvardenUtgaende] ---
        //--- [1066: rowSumRowsInTable(AgarintressenOvrigaForetagAnskaffningsvarden, nan, sum:=AgarintressenOvrigaForetagAnskaffningsvarden, AgarintressenOvrigaForetagAnskaffningsvardenInkop, AgarintressenOvrigaForetagAnskaffningsvardenFusion, AgarintressenOvrigaForetagAnskaffningsvardenForsaljningar, AgarintressenOvrigaForetagAnskaffningsvardenLamnadeAktieagartillskott, AgarintressenOvrigaForetagAnskaffningsvardenAterbetalningLamnatAktieagartillskott, AgarintressenOvrigaForetagAnskaffningsvardenAretsResultatAndel, AgarintressenOvrigaForetagAnskaffningsvardenOmklassificeringar, AgarintressenOvrigaForetagAnskaffningsvardenUtdelning, AgarintressenOvrigaForetagAnskaffningsvardenOmrakningsdifferenser, AgarintressenOvrigaForetagAnskaffningsvardenUtgaende, nan)] ---
        rows.addRow(
          'AgarintressenOvrigaForetagAnskaffningsvardenUtgaende',
          noteLabel('Utgående anskaffningsvärden'),
          ixbrlCell(
            ref(
              sum(
                or(
                  id(
                    'notes.note401.table.AgarintressenOvrigaForetagAnskaffningsvardenAbstract.AgarintressenOvrigaForetagAnskaffningsvarden.year'
                  ),
                  0
                ),
                or(
                  id(
                    'notes.note401.table.AgarintressenOvrigaForetagAnskaffningsvardenInkop.year'
                  ),
                  0
                ),
                or(
                  id(
                    'notes.note401.table.AgarintressenOvrigaForetagAnskaffningsvardenFusion.year'
                  ),
                  0
                ),
                or(
                  id(
                    'notes.note401.table.AgarintressenOvrigaForetagAnskaffningsvardenForsaljningar.year'
                  ),
                  0
                ),
                or(
                  id(
                    'notes.note401.table.AgarintressenOvrigaForetagAnskaffningsvardenLamnadeAktieagartillskott.year'
                  ),
                  0
                ),
                or(
                  id(
                    'notes.note401.table.AgarintressenOvrigaForetagAnskaffningsvardenAterbetalningLamnatAktieagartillskott.year'
                  ),
                  0
                ),
                or(
                  id(
                    'notes.note401.table.AgarintressenOvrigaForetagAnskaffningsvardenAretsResultatAndel.year'
                  ),
                  0
                ),
                or(
                  id(
                    'notes.note401.table.AgarintressenOvrigaForetagAnskaffningsvardenOmklassificeringar.year'
                  ),
                  0
                ),
                or(
                  id(
                    'notes.note401.table.AgarintressenOvrigaForetagAnskaffningsvardenUtdelning.year'
                  ),
                  0
                ),
                or(
                  id(
                    'notes.note401.table.AgarintressenOvrigaForetagAnskaffningsvardenOmrakningsdifferenser.year'
                  ),
                  0
                )
              )
            ),
            {
              type: 'monetaryItemType',
              name: 'se-gen-base: AgarintressenOvrigaForetagAnskaffningsvarden',
              contextRef: 'balans0',
              standardRubrik:
                'Anskaffningsvärden ägarintressen i övriga företag',
              saldo: 'debit',
            }
          ),
          ixbrlCell(
            ref(
              sum(
                or(
                  id(
                    'notes.note401.table.AgarintressenOvrigaForetagAnskaffningsvardenAbstract.AgarintressenOvrigaForetagAnskaffningsvarden.previousYear'
                  ),
                  0
                ),
                or(
                  id(
                    'notes.note401.table.AgarintressenOvrigaForetagAnskaffningsvardenInkop.previousYear'
                  ),
                  0
                ),
                or(
                  id(
                    'notes.note401.table.AgarintressenOvrigaForetagAnskaffningsvardenFusion.previousYear'
                  ),
                  0
                ),
                or(
                  id(
                    'notes.note401.table.AgarintressenOvrigaForetagAnskaffningsvardenForsaljningar.previousYear'
                  ),
                  0
                ),
                or(
                  id(
                    'notes.note401.table.AgarintressenOvrigaForetagAnskaffningsvardenLamnadeAktieagartillskott.previousYear'
                  ),
                  0
                ),
                or(
                  id(
                    'notes.note401.table.AgarintressenOvrigaForetagAnskaffningsvardenAterbetalningLamnatAktieagartillskott.previousYear'
                  ),
                  0
                ),
                or(
                  id(
                    'notes.note401.table.AgarintressenOvrigaForetagAnskaffningsvardenAretsResultatAndel.previousYear'
                  ),
                  0
                ),
                or(
                  id(
                    'notes.note401.table.AgarintressenOvrigaForetagAnskaffningsvardenOmklassificeringar.previousYear'
                  ),
                  0
                ),
                or(
                  id(
                    'notes.note401.table.AgarintressenOvrigaForetagAnskaffningsvardenUtdelning.previousYear'
                  ),
                  0
                ),
                or(
                  id(
                    'notes.note401.table.AgarintressenOvrigaForetagAnskaffningsvardenOmrakningsdifferenser.previousYear'
                  ),
                  0
                )
              )
            ),
            {
              type: 'monetaryItemType',
              name: 'se-gen-base: AgarintressenOvrigaForetagAnskaffningsvarden',
              contextRef: 'balans1',
              standardRubrik:
                'Anskaffningsvärden ägarintressen i övriga företag',
              saldo: 'debit',
            }
          )
        );
        //--- end row_sum_rows_in_table ---
        //--- begin row_sum_rows_in_table (level 2) [notes.note401.table.AgarintressenOvrigaForetagUppskrivningarIngaende] ---
        //--- [1067: rowEmptyValue(AgarintressenOvrigaForetagUppskrivningar, nan, nan, AgarintressenOvrigaForetagUppskrivningarIngaende, nan)] ---
        rows.addRow(
          'AgarintressenOvrigaForetagUppskrivningarIngaende',
          noteLabel('Ingående uppskrivningar'),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base: AgarintressenOvrigaForetagUppskrivningar',
            contextRef: 'balans0',
            standardRubrik:
              'Ackumulerade uppskrivningar ägarintressen i övriga företag',
            saldo: 'debit',
          }),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base: AgarintressenOvrigaForetagUppskrivningar',
            contextRef: 'balans1',
            standardRubrik:
              'Ackumulerade uppskrivningar ägarintressen i övriga företag',
            saldo: 'debit',
          })
        );
        //--- end row_sum_rows_in_table ---
        //--- begin row_header (level 2) [notes.note401.table.AgarintressenOvrigaForetagUppskrivningarAbstract] ---
        //--- [1068: rowHeader(AgarintressenOvrigaForetagUppskrivningarAbstract, nan, nan, nan, nan)] ---
        rows.addRow(
          'AgarintressenOvrigaForetagUppskrivningarAbstract',
          noteLabel('Förändringar av uppskrivningar'),
          undefined,
          undefined
        );
        //--- end row_header ---
        //--- begin row_sum_rows_in_table (level 2) [notes.note401.table.AgarintressenOvrigaForetagUppskrivningarFusion] ---
        //--- [1069: rowEmptyValue(AgarintressenOvrigaForetagUppskrivningarFusion, nan, nan, nan, nan)] ---
        rows.addRow(
          'AgarintressenOvrigaForetagUppskrivningarFusion',
          noteLabel('Fusion'),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base: AgarintressenOvrigaForetagUppskrivningarFusion',
            contextRef: 'period0',
            standardRubrik:
              'Ökning (minskning) av uppskrivningar för ägarintressen i övriga företag genom fusion',
          }),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base: AgarintressenOvrigaForetagUppskrivningarFusion',
            contextRef: 'period1',
            standardRubrik:
              'Ökning (minskning) av uppskrivningar för ägarintressen i övriga företag genom fusion',
          })
        );
        //--- end row_sum_rows_in_table ---
        //--- begin row_sum_rows_in_table (level 2) [notes.note401.table.AgarintressenOvrigaForetagUppskrivningarAterfordaUppskrivningarForsaljningar] ---
        //--- [1070: rowEmptyValue(AgarintressenOvrigaForetagUppskrivningarAterfordaUppskrivningarForsaljningar, nan, nan, nan, nan)] ---
        rows.addRow(
          'AgarintressenOvrigaForetagUppskrivningarAterfordaUppskrivningarForsaljningar',
          noteLabel('Försäljningar'),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base: AgarintressenOvrigaForetagUppskrivningarAterfordaUppskrivningarForsaljningar',
            contextRef: 'period0',
            standardRubrik:
              'Återförda uppskrivningar i samband med försäljningar av ägarintressen i övriga företag',
          }),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base: AgarintressenOvrigaForetagUppskrivningarAterfordaUppskrivningarForsaljningar',
            contextRef: 'period1',
            standardRubrik:
              'Återförda uppskrivningar i samband med försäljningar av ägarintressen i övriga företag',
          })
        );
        //--- end row_sum_rows_in_table ---
        //--- begin row_sum_rows_in_table (level 2) [notes.note401.table.AgarintressenOvrigaForetagUppskrivningarOmklassificeringar] ---
        //--- [1071: rowEmptyValue(AgarintressenOvrigaForetagUppskrivningarOmklassificeringar, nan, nan, nan, nan)] ---
        rows.addRow(
          'AgarintressenOvrigaForetagUppskrivningarOmklassificeringar',
          noteLabel('Omklassificeringar'),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base: AgarintressenOvrigaForetagUppskrivningarOmklassificeringar',
            contextRef: 'period0',
            standardRubrik:
              'Ökning (minskning) av uppskrivningar i samband med omklassificeringar av ägarintressen i övriga företag',
          }),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base: AgarintressenOvrigaForetagUppskrivningarOmklassificeringar',
            contextRef: 'period1',
            standardRubrik:
              'Ökning (minskning) av uppskrivningar i samband med omklassificeringar av ägarintressen i övriga företag',
          })
        );
        //--- end row_sum_rows_in_table ---
        //--- begin row_sum_rows_in_table (level 2) [notes.note401.table.AgarintressenOvrigaForetagUppskrivningarAretsUppskrivningar] ---
        //--- [1072: rowEmptyValue(AgarintressenOvrigaForetagUppskrivningarAretsUppskrivningar, nan, nan, nan, nan)] ---
        rows.addRow(
          'AgarintressenOvrigaForetagUppskrivningarAretsUppskrivningar',
          noteLabel('Årets uppskrivningar'),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base: AgarintressenOvrigaForetagUppskrivningarAretsUppskrivningar',
            contextRef: 'period0',
            standardRubrik:
              'Periodens uppskrivningar av ägarintressen i övriga företag',
          }),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base: AgarintressenOvrigaForetagUppskrivningarAretsUppskrivningar',
            contextRef: 'period1',
            standardRubrik:
              'Periodens uppskrivningar av ägarintressen i övriga företag',
          })
        );
        //--- end row_sum_rows_in_table ---
        //--- begin row_sum_rows_in_table (level 2) [notes.note401.table.AgarintressenOvrigaForetagUppskrivningarAretsNedskrivningarUppskrivetBelopp] ---
        //--- [1073: rowEmptyValue(AgarintressenOvrigaForetagUppskrivningarAretsNedskrivningarUppskrivetBelopp, nan, nan, nan, nan)] ---
        rows.addRow(
          'AgarintressenOvrigaForetagUppskrivningarAretsNedskrivningarUppskrivetBelopp',
          noteLabel('Årets nedskrivningar på uppskrivet belopp'),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base: AgarintressenOvrigaForetagUppskrivningarAretsNedskrivningarUppskrivetBelopp',
            contextRef: 'period0',
            standardRubrik:
              'Nedskrivningar av uppskrivet belopp på ägarintressen i övriga företag',
          }),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base: AgarintressenOvrigaForetagUppskrivningarAretsNedskrivningarUppskrivetBelopp',
            contextRef: 'period1',
            standardRubrik:
              'Nedskrivningar av uppskrivet belopp på ägarintressen i övriga företag',
          })
        );
        //--- end row_sum_rows_in_table ---
        //--- begin row_sum_rows_in_table (level 2) [notes.note401.table.AgarintressenOvrigaForetagUppskrivningarOmrakningsdifferenser] ---
        //--- [1074: rowEmptyValue(AgarintressenOvrigaForetagUppskrivningarOmrakningsdifferenser, nan, nan, nan, nan)] ---
        rows.addRow(
          'AgarintressenOvrigaForetagUppskrivningarOmrakningsdifferenser',
          noteLabel('Omräkningsdifferenser'),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base: AgarintressenOvrigaForetagUppskrivningarOmrakningsdifferenser',
            contextRef: 'period0',
            standardRubrik:
              'Ökning (minskning) av uppskrivningar i samband med omräkningsdifferenser av ägarintressen i övriga företag',
          }),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base: AgarintressenOvrigaForetagUppskrivningarOmrakningsdifferenser',
            contextRef: 'period1',
            standardRubrik:
              'Ökning (minskning) av uppskrivningar i samband med omräkningsdifferenser av ägarintressen i övriga företag',
          })
        );
        //--- end row_sum_rows_in_table ---
        //--- begin row_sum_rows_in_table (level 2) [notes.note401.table.AgarintressenOvrigaForetagUppskrivningarUtgaende] ---
        //--- [1075: rowSumRowsInTable(AgarintressenOvrigaForetagUppskrivningar, nan, sum:=AgarintressenOvrigaForetagUppskrivningar, AgarintressenOvrigaForetagUppskrivningarFusion, AgarintressenOvrigaForetagUppskrivningarAterfordaUppskrivningarForsaljningar, AgarintressenOvrigaForetagUppskrivningarOmklassificeringar, AgarintressenOvrigaForetagUppskrivningarAretsUppskrivningar, AgarintressenOvrigaForetagUppskrivningarAretsNedskrivningarUppskrivetBelopp, AgarintressenOvrigaForetagUppskrivningarOmrakningsdifferenser, AgarintressenOvrigaForetagUppskrivningarUtgaende, nan)] ---
        rows.addRow(
          'AgarintressenOvrigaForetagUppskrivningarUtgaende',
          noteLabel('Utgående uppskrivningar'),
          ixbrlCell(
            ref(
              sum(
                or(
                  id(
                    'notes.note401.table.AgarintressenOvrigaForetagUppskrivningarAbstract.AgarintressenOvrigaForetagUppskrivningar.year'
                  ),
                  0
                ),
                or(
                  id(
                    'notes.note401.table.AgarintressenOvrigaForetagUppskrivningarFusion.year'
                  ),
                  0
                ),
                or(
                  id(
                    'notes.note401.table.AgarintressenOvrigaForetagUppskrivningarAterfordaUppskrivningarForsaljningar.year'
                  ),
                  0
                ),
                or(
                  id(
                    'notes.note401.table.AgarintressenOvrigaForetagUppskrivningarOmklassificeringar.year'
                  ),
                  0
                ),
                or(
                  id(
                    'notes.note401.table.AgarintressenOvrigaForetagUppskrivningarAretsUppskrivningar.year'
                  ),
                  0
                ),
                or(
                  id(
                    'notes.note401.table.AgarintressenOvrigaForetagUppskrivningarAretsNedskrivningarUppskrivetBelopp.year'
                  ),
                  0
                ),
                or(
                  id(
                    'notes.note401.table.AgarintressenOvrigaForetagUppskrivningarOmrakningsdifferenser.year'
                  ),
                  0
                )
              )
            ),
            {
              type: 'monetaryItemType',
              name: 'se-gen-base: AgarintressenOvrigaForetagUppskrivningar',
              contextRef: 'balans0',
              standardRubrik:
                'Ackumulerade uppskrivningar ägarintressen i övriga företag',
              saldo: 'debit',
            }
          ),
          ixbrlCell(
            ref(
              sum(
                or(
                  id(
                    'notes.note401.table.AgarintressenOvrigaForetagUppskrivningarAbstract.AgarintressenOvrigaForetagUppskrivningar.previousYear'
                  ),
                  0
                ),
                or(
                  id(
                    'notes.note401.table.AgarintressenOvrigaForetagUppskrivningarFusion.previousYear'
                  ),
                  0
                ),
                or(
                  id(
                    'notes.note401.table.AgarintressenOvrigaForetagUppskrivningarAterfordaUppskrivningarForsaljningar.previousYear'
                  ),
                  0
                ),
                or(
                  id(
                    'notes.note401.table.AgarintressenOvrigaForetagUppskrivningarOmklassificeringar.previousYear'
                  ),
                  0
                ),
                or(
                  id(
                    'notes.note401.table.AgarintressenOvrigaForetagUppskrivningarAretsUppskrivningar.previousYear'
                  ),
                  0
                ),
                or(
                  id(
                    'notes.note401.table.AgarintressenOvrigaForetagUppskrivningarAretsNedskrivningarUppskrivetBelopp.previousYear'
                  ),
                  0
                ),
                or(
                  id(
                    'notes.note401.table.AgarintressenOvrigaForetagUppskrivningarOmrakningsdifferenser.previousYear'
                  ),
                  0
                )
              )
            ),
            {
              type: 'monetaryItemType',
              name: 'se-gen-base: AgarintressenOvrigaForetagUppskrivningar',
              contextRef: 'balans1',
              standardRubrik:
                'Ackumulerade uppskrivningar ägarintressen i övriga företag',
              saldo: 'debit',
            }
          )
        );
        //--- end row_sum_rows_in_table ---
        //--- begin row_sum_accounts (level 2) [notes.note401.table.AgarintressenOvrigaForetagNedskrivningarIngaende] ---
        //--- [1076: rowSumAccounts(AgarintressenOvrigaForetagNedskrivningar, 1337, sumAccountsIBs; period_base:=1, AgarintressenOvrigaForetagNedskrivningarIngaende, nan)] ---
        rows.addRow(
          'AgarintressenOvrigaForetagNedskrivningarIngaende',
          noteLabel('Ingående nedskrivningar'),
          ixbrlCell(ref(sumAccountsIBs('1337', false)), {
            type: 'monetaryItemType',
            name: 'se-gen-base: AgarintressenOvrigaForetagNedskrivningar',
            contextRef: 'balans1',
            standardRubrik:
              'Ackumulerade nedskrivningar ägarintressen i övriga företag',
            saldo: 'credit',
          }),
          ixbrlCell(ref(sumAccountsIBs('1337', false, 'previousYear')), {
            type: 'monetaryItemType',
            name: 'se-gen-base: AgarintressenOvrigaForetagNedskrivningar',
            contextRef: 'balans2',
            standardRubrik:
              'Ackumulerade nedskrivningar ägarintressen i övriga företag',
            saldo: 'credit',
          })
        );
        //--- end row_sum_accounts ---
        //--- begin row_header (level 2) [notes.note401.table.AgarintressenOvrigaForetagNedskrivningarAbstract] ---
        //--- [1077: rowHeader(AgarintressenOvrigaForetagNedskrivningarAbstract, nan, nan, nan, nan)] ---
        rows.addRow(
          'AgarintressenOvrigaForetagNedskrivningarAbstract',
          noteLabel('Förändringar av nedskrivningar'),
          undefined,
          undefined
        );
        //--- end row_header ---
        //--- begin row_sum_rows_in_table (level 2) [notes.note401.table.AgarintressenOvrigaForetagForandringNedskrivningarFusion] ---
        //--- [1078: rowEmptyValue(AgarintressenOvrigaForetagForandringNedskrivningarFusion, nan, nan, nan, nan)] ---
        rows.addRow(
          'AgarintressenOvrigaForetagForandringNedskrivningarFusion',
          noteLabel('Fusion'),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base: AgarintressenOvrigaForetagForandringNedskrivningarFusion',
            contextRef: 'period0',
            standardRubrik:
              'Ökning (minskning) av nedskrivningar för ägarintressen i övriga företag genom fusion',
          }),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base: AgarintressenOvrigaForetagForandringNedskrivningarFusion',
            contextRef: 'period1',
            standardRubrik:
              'Ökning (minskning) av nedskrivningar för ägarintressen i övriga företag genom fusion',
          })
        );
        //--- end row_sum_rows_in_table ---
        //--- begin row_sum_rows_in_table (level 2) [notes.note401.table.AgarintressenOvrigaForetagForandringNedskrivningarAterfordaNedskrivningarForsaljningar] ---
        //--- [1079: rowEmptyValue(AgarintressenOvrigaForetagForandringNedskrivningarAterfordaNedskrivningarForsaljningar, nan, nan, nan, nan)] ---
        rows.addRow(
          'AgarintressenOvrigaForetagForandringNedskrivningarAterfordaNedskrivningarForsaljningar',
          noteLabel('Försäljningar'),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base: AgarintressenOvrigaForetagForandringNedskrivningarAterfordaNedskrivningarForsaljningar',
            contextRef: 'period0',
            standardRubrik:
              'Återförda nedskrivningar i samband med försäljningar av ägarintressen i övriga företag',
          }),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base: AgarintressenOvrigaForetagForandringNedskrivningarAterfordaNedskrivningarForsaljningar',
            contextRef: 'period1',
            standardRubrik:
              'Återförda nedskrivningar i samband med försäljningar av ägarintressen i övriga företag',
          })
        );
        //--- end row_sum_rows_in_table ---
        //--- begin row_sum_rows_in_table (level 2) [notes.note401.table.AgarintressenOvrigaForetagForandringNedskrivningarOmklassificeringar] ---
        //--- [1080: rowEmptyValue(AgarintressenOvrigaForetagForandringNedskrivningarOmklassificeringar, nan, nan, nan, nan)] ---
        rows.addRow(
          'AgarintressenOvrigaForetagForandringNedskrivningarOmklassificeringar',
          noteLabel('Omklassificeringar'),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base: AgarintressenOvrigaForetagForandringNedskrivningarOmklassificeringar',
            contextRef: 'period0',
            standardRubrik:
              'Minskning (ökning) av nedskrivningar i samband med omklassificeringar av ägarintressen i övriga företag',
          }),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base: AgarintressenOvrigaForetagForandringNedskrivningarOmklassificeringar',
            contextRef: 'period1',
            standardRubrik:
              'Minskning (ökning) av nedskrivningar i samband med omklassificeringar av ägarintressen i övriga företag',
          })
        );
        //--- end row_sum_rows_in_table ---
        //--- begin row_sum_rows_in_table (level 2) [notes.note401.table.AgarintressenOvrigaForetagForandringNedskrivningarAterfordaNedskrivningar] ---
        //--- [1081: rowEmptyValue(AgarintressenOvrigaForetagForandringNedskrivningarAterfordaNedskrivningar, nan, nan, nan, nan)] ---
        rows.addRow(
          'AgarintressenOvrigaForetagForandringNedskrivningarAterfordaNedskrivningar',
          noteLabel('Återförda nedskrivningar'),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base: AgarintressenOvrigaForetagForandringNedskrivningarAterfordaNedskrivningar',
            contextRef: 'period0',
            standardRubrik:
              'Periodens återförda nedskrivningar av ägarintressen i övriga företag',
          }),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base: AgarintressenOvrigaForetagForandringNedskrivningarAterfordaNedskrivningar',
            contextRef: 'period1',
            standardRubrik:
              'Periodens återförda nedskrivningar av ägarintressen i övriga företag',
          })
        );
        //--- end row_sum_rows_in_table ---
        //--- begin row_sum_rows_in_table (level 2) [notes.note401.table.AgarintressenOvrigaForetagForandringNedskrivningarAretsNedskrivningar] ---
        //--- [1082: rowEmptyValue(AgarintressenOvrigaForetagForandringNedskrivningarAretsNedskrivningar, nan, nan, nan, nan)] ---
        rows.addRow(
          'AgarintressenOvrigaForetagForandringNedskrivningarAretsNedskrivningar',
          noteLabel('Årets nedskrivningar'),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base: AgarintressenOvrigaForetagForandringNedskrivningarAretsNedskrivningar',
            contextRef: 'period0',
            standardRubrik:
              'Periodens nedskrivningar av ägarintressen i övriga företag',
          }),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base: AgarintressenOvrigaForetagForandringNedskrivningarAretsNedskrivningar',
            contextRef: 'period1',
            standardRubrik:
              'Periodens nedskrivningar av ägarintressen i övriga företag',
          })
        );
        //--- end row_sum_rows_in_table ---
        //--- begin row_sum_rows_in_table (level 2) [notes.note401.table.AgarintressenOvrigaForetagForandringNedskrivningarOmrakningsdifferenser] ---
        //--- [1083: rowEmptyValue(AgarintressenOvrigaForetagForandringNedskrivningarOmrakningsdifferenser, nan, nan, nan, nan)] ---
        rows.addRow(
          'AgarintressenOvrigaForetagForandringNedskrivningarOmrakningsdifferenser',
          noteLabel('Omräkningsdifferenser'),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base: AgarintressenOvrigaForetagForandringNedskrivningarOmrakningsdifferenser',
            contextRef: 'period0',
            standardRubrik:
              'Minskning (ökning) av nedskrivningar i samband med omräkningsdifferenser av ägarintressen i övriga företag',
          }),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base: AgarintressenOvrigaForetagForandringNedskrivningarOmrakningsdifferenser',
            contextRef: 'period1',
            standardRubrik:
              'Minskning (ökning) av nedskrivningar i samband med omräkningsdifferenser av ägarintressen i övriga företag',
          })
        );
        //--- end row_sum_rows_in_table ---
        //--- begin row_sum_rows_in_table (level 2) [notes.note401.table.AgarintressenOvrigaForetagNedskrivningarUtgaende] ---
        //--- [1084: rowSumRowsInTable(AgarintressenOvrigaForetagNedskrivningar, nan, sum:=AgarintressenOvrigaForetagNedskrivningar, AgarintressenOvrigaForetagForandringNedskrivningarFusion, AgarintressenOvrigaForetagForandringNedskrivningarAterfordaNedskrivningarForsaljningar, AgarintressenOvrigaForetagForandringNedskrivningarOmklassificeringar, AgarintressenOvrigaForetagForandringNedskrivningarAterfordaNedskrivningar, AgarintressenOvrigaForetagForandringNedskrivningarAretsNedskrivningar, AgarintressenOvrigaForetagForandringNedskrivningarOmrakningsdifferenser, AgarintressenOvrigaForetagNedskrivningarUtgaende, nan)] ---
        rows.addRow(
          'AgarintressenOvrigaForetagNedskrivningarUtgaende',
          noteLabel('Utgående nedskrivningar'),
          ixbrlCell(
            ref(
              sum(
                or(
                  id(
                    'notes.note401.table.AgarintressenOvrigaForetagNedskrivningarAbstract.AgarintressenOvrigaForetagNedskrivningar.year'
                  ),
                  0
                ),
                or(
                  id(
                    'notes.note401.table.AgarintressenOvrigaForetagForandringNedskrivningarFusion.year'
                  ),
                  0
                ),
                or(
                  id(
                    'notes.note401.table.AgarintressenOvrigaForetagForandringNedskrivningarAterfordaNedskrivningarForsaljningar.year'
                  ),
                  0
                ),
                or(
                  id(
                    'notes.note401.table.AgarintressenOvrigaForetagForandringNedskrivningarOmklassificeringar.year'
                  ),
                  0
                ),
                or(
                  id(
                    'notes.note401.table.AgarintressenOvrigaForetagForandringNedskrivningarAterfordaNedskrivningar.year'
                  ),
                  0
                ),
                or(
                  id(
                    'notes.note401.table.AgarintressenOvrigaForetagForandringNedskrivningarAretsNedskrivningar.year'
                  ),
                  0
                ),
                or(
                  id(
                    'notes.note401.table.AgarintressenOvrigaForetagForandringNedskrivningarOmrakningsdifferenser.year'
                  ),
                  0
                )
              )
            ),
            {
              type: 'monetaryItemType',
              name: 'se-gen-base: AgarintressenOvrigaForetagNedskrivningar',
              contextRef: 'balans0',
              standardRubrik:
                'Ackumulerade nedskrivningar ägarintressen i övriga företag',
              saldo: 'credit',
            }
          ),
          ixbrlCell(
            ref(
              sum(
                or(
                  id(
                    'notes.note401.table.AgarintressenOvrigaForetagNedskrivningarAbstract.AgarintressenOvrigaForetagNedskrivningar.previousYear'
                  ),
                  0
                ),
                or(
                  id(
                    'notes.note401.table.AgarintressenOvrigaForetagForandringNedskrivningarFusion.previousYear'
                  ),
                  0
                ),
                or(
                  id(
                    'notes.note401.table.AgarintressenOvrigaForetagForandringNedskrivningarAterfordaNedskrivningarForsaljningar.previousYear'
                  ),
                  0
                ),
                or(
                  id(
                    'notes.note401.table.AgarintressenOvrigaForetagForandringNedskrivningarOmklassificeringar.previousYear'
                  ),
                  0
                ),
                or(
                  id(
                    'notes.note401.table.AgarintressenOvrigaForetagForandringNedskrivningarAterfordaNedskrivningar.previousYear'
                  ),
                  0
                ),
                or(
                  id(
                    'notes.note401.table.AgarintressenOvrigaForetagForandringNedskrivningarAretsNedskrivningar.previousYear'
                  ),
                  0
                ),
                or(
                  id(
                    'notes.note401.table.AgarintressenOvrigaForetagForandringNedskrivningarOmrakningsdifferenser.previousYear'
                  ),
                  0
                )
              )
            ),
            {
              type: 'monetaryItemType',
              name: 'se-gen-base: AgarintressenOvrigaForetagNedskrivningar',
              contextRef: 'balans1',
              standardRubrik:
                'Ackumulerade nedskrivningar ägarintressen i övriga företag',
              saldo: 'credit',
            }
          )
        );
        //--- end row_sum_rows_in_table ---
        //--- begin row_sum_rows_in_table (level 2) [notes.note401.table.AgarintressenOvrigaForetag] ---
        //--- [1085: rowSumRowsInTable(AgarintressenOvrigaForetag, nan, sum:=AgarintressenOvrigaForetagAnskaffningsvardenUtgaende, AgarintressenOvrigaForetagUppskrivningarUtgaende, AgarintressenOvrigaForetagNedskrivningarUtgaende, nan, nan)] ---
        rows.addRow(
          'AgarintressenOvrigaForetag',
          noteLabel('Redovisat värde'),
          ixbrlCell(
            ref(
              sum(
                or(
                  id(
                    'notes.note401.table.AgarintressenOvrigaForetagAnskaffningsvardenUtgaende.year'
                  ),
                  0
                ),
                or(
                  id(
                    'notes.note401.table.AgarintressenOvrigaForetagUppskrivningarUtgaende.year'
                  ),
                  0
                ),
                or(
                  id(
                    'notes.note401.table.AgarintressenOvrigaForetagNedskrivningarUtgaende.year'
                  ),
                  0
                )
              )
            ),
            {
              type: 'monetaryItemType',
              name: 'se-gen-base: AgarintressenOvrigaForetag',
              contextRef: 'balans0',
              standardRubrik: 'Ägarintressen i övriga företag',
              saldo: 'debit',
            }
          ),
          ixbrlCell(
            ref(
              sum(
                or(
                  id(
                    'notes.note401.table.AgarintressenOvrigaForetagAnskaffningsvardenUtgaende.previousYear'
                  ),
                  0
                ),
                or(
                  id(
                    'notes.note401.table.AgarintressenOvrigaForetagUppskrivningarUtgaende.previousYear'
                  ),
                  0
                ),
                or(
                  id(
                    'notes.note401.table.AgarintressenOvrigaForetagNedskrivningarUtgaende.previousYear'
                  ),
                  0
                )
              )
            ),
            {
              type: 'monetaryItemType',
              name: 'se-gen-base: AgarintressenOvrigaForetag',
              contextRef: 'balans1',
              standardRubrik: 'Ägarintressen i övriga företag',
              saldo: 'debit',
            }
          )
        );
        //--- end row_sum_rows_in_table ---
        //--- begin end_table (level 1) [notes.note401.table] ---
        //--- [1054: tableHeader(AgarintressenOvrigaForetagAbstract, nan, nan, table, nan)] ---
        return rows.build();
      })
      .newRowTemplate(value(''), value(undefined), value(undefined))
      .build(),
    //--- end end_table ---
    //--- begin end_table_with_comment (level 1) [notes.note401.firstTableComment] ---
    //--- [1086: endTableWithComment(AgarintressenOvrigaForetagKommentar, nan, nan, firstTableComment, nan)] ---
    firstTableCommentLabel: field('Kommentar'),
    firstTableComment: ixbrlField(field(''), {
      type: 'stringItemType',
      name: 'se-gen-base: AgarintressenOvrigaForetagKommentar',
      contextRef: 'period0',
      standardRubrik: 'Kommentar till ägarintressen i övriga företag',
    }),
    //--- end end_table_with_comment ---
    //--- begin tuple_table_header (level 1) [notes.note401.secondTable] ---
    //--- [1087: tupleTableHeader(InnehavAgarintressenOvrigaForetag, nan, nan, secondTable, nan)] ---
    secondTableTitle: ixbrlField(
      field('Innehav av ägarintressen i övriga företag'),
      {
        type: 'stringItemType',
        name: 'se-gen-base: InnehavAgarintressenOvrigaForetag',
        contextRef: 'period0',
        standardRubrik:
          'Innehav av andelar övriga företag, som det finns ägarintresse i',
      }
    ),
    secondTable: table<IxbrlCell>(
      'notes.note401.secondTable',
      {
        id: 'ForetagetsNamn',
        label: 'Företagets namn',
      },
      {
        id: 'Organisationsnummer',
        label: 'Organisationsnummer',
      },
      {
        id: 'ForetagetsSate',
        label: 'Säte',
      },
      {
        id: 'InnehavAndelarAgarintressenOvrigaForetagAntalAndelar',
        label: 'Antal andelar',
      },
      {
        id: 'InnehavAgarintressenOvrigaForetagKapital',
        label: 'Eget kapital',
      },
      {
        id: 'InnehavAndelarAgarintressenOvrigaForetagAretsResultat',
        label: 'Årets resultat',
      },
      {
        id: 'InnehavAndelarAgarintressenOvrigaForetagKapitalandel',
        label: 'Kapitalandel',
      },
      {
        id: 'InnehavAndelarAgarintressenOvrigaForetagRostrattsandel',
        label: 'Rösträttsandel',
      },
      {
        id: 'InnehavAgarintressenOvrigaForetagRedovisatVarde',
        label: 'Redovisat värde',
      }
    )
      .addRows((rows) => {
        //--- end tuple_table_header ---
        //--- begin tuple_rows (level 2) [notes.note401.secondTable.InnehavAndelarAgarintressenOvrigaForetagInklusiveUppgiftForetagTuple] ---
        //--- [1088: tupleId(InnehavAndelarAgarintressenOvrigaForetagInklusiveUppgiftForetagTuple, nan, nan, InnehavAndelarAgarintressenOvrigaForetagInklusiveUppgiftForetagTuple, nan)] ---
        rows.addRow(
          'InnehavAndelarAgarintressenOvrigaForetagInklusiveUppgiftForetagTuple',
          //--- begin tuple_rows.tupleColumnEmptyValue (level 3) [notes.note401.secondTable.InnehavAndelarAgarintressenOvrigaForetagInklusiveUppgiftForetagTuple.ForetagetsNamn] ---
          //--- [1089: tupleColumnEmptyValue(ForetagetsNamn, nan, nan, ForetagetsNamn, nan)] ---
          ixbrlCell(value(''), {
            type: 'stringItemType',
            name: 'se-cd-base: ForetagetsNamn',
            contextRef: 'period0',
            standardRubrik: 'Företagets nuvarande namn',
            //--- begin first_tuple_cell (level 2) [notes.note401.secondTable.InnehavAndelarAgarintressenOvrigaForetagInklusiveUppgiftForetagTuple] ---
            //--- [1088: tupleId(InnehavAndelarAgarintressenOvrigaForetagInklusiveUppgiftForetagTuple, nan, nan, InnehavAndelarAgarintressenOvrigaForetagInklusiveUppgiftForetagTuple, nan)] ---
            tuple: {
              name: 'se-gaap-ext: InnehavAndelarAgarintressenOvrigaForetagInklusiveUppgiftForetagTuple',
              id: `${rows.getBaseId()}.InnehavAndelarAgarintressenOvrigaForetagInklusiveUppgiftForetagTuple.tuple`,
              tupleID: `${rows.getBaseId()}.InnehavAndelarAgarintressenOvrigaForetagInklusiveUppgiftForetagTuple.tuple`,
            },
            //--- end first_tuple_cell ---
            tupleRef: tupleRef(
              1,
              `${rows.getBaseId()}.InnehavAndelarAgarintressenOvrigaForetagInklusiveUppgiftForetagTuple.tuple`
            ),
          }), //--- end tuple_rows.tupleColumnEmptyValue ---
          //--- begin tuple_rows.tupleColumnEmptyValue (level 3) [notes.note401.secondTable.InnehavAndelarAgarintressenOvrigaForetagInklusiveUppgiftForetagTuple.Organisationsnummer] ---
          //--- [1090: tupleColumnEmptyValue(Organisationsnummer, nan, nan, Organisationsnummer, nan)] ---
          ixbrlCell(value(''), {
            type: 'stringItemType',
            name: 'se-cd-base: Organisationsnummer',
            contextRef: 'period0',
            standardRubrik: 'Organisationsnummer',
            tupleRef: tupleRef(
              2,
              `${rows.getBaseId()}.InnehavAndelarAgarintressenOvrigaForetagInklusiveUppgiftForetagTuple.tuple`
            ),
          }), //--- end tuple_rows.tupleColumnEmptyValue ---
          //--- begin tuple_rows.tupleColumnEmptyValue (level 3) [notes.note401.secondTable.InnehavAndelarAgarintressenOvrigaForetagInklusiveUppgiftForetagTuple.ForetagetsSate] ---
          //--- [1091: tupleColumnEmptyValue(ForetagetsSate, nan, nan, ForetagetsSate, nan)] ---
          ixbrlCell(value(''), {
            type: 'stringItemType',
            name: 'se-cd-base: ForetagetsSate',
            contextRef: 'period0',
            standardRubrik: 'Företagets säte',
            tupleRef: tupleRef(
              3,
              `${rows.getBaseId()}.InnehavAndelarAgarintressenOvrigaForetagInklusiveUppgiftForetagTuple.tuple`
            ),
          }), //--- end tuple_rows.tupleColumnEmptyValue ---
          //--- begin tuple_rows.tupleColumnEmptyValue (level 3) [notes.note401.secondTable.InnehavAndelarAgarintressenOvrigaForetagInklusiveUppgiftForetagTuple.InnehavAndelarAgarintressenOvrigaForetagAntalAndelar] ---
          //--- [1092: tupleColumnEmptyValue(InnehavAndelarAgarintressenOvrigaForetagAntalAndelar, nan, nan, InnehavAndelarAgarintressenOvrigaForetagAntalAndelar, nan)] ---
          ixbrlCell(value(undefined), {
            type: 'sharesItemType',
            unitRef: 'andelar',
            name: 'se-gen-base: InnehavAndelarAgarintressenOvrigaForetagAntalAndelar',
            contextRef: 'balans0',
            standardRubrik:
              'Antal andelar per övrigt företag som det finns ägarintresse i',
            tupleRef: tupleRef(
              4,
              `${rows.getBaseId()}.InnehavAndelarAgarintressenOvrigaForetagInklusiveUppgiftForetagTuple.tuple`
            ),
          }), //--- end tuple_rows.tupleColumnEmptyValue ---
          //--- begin tuple_rows.tupleColumnEmptyValue (level 3) [notes.note401.secondTable.InnehavAndelarAgarintressenOvrigaForetagInklusiveUppgiftForetagTuple.InnehavAgarintressenOvrigaForetagKapital] ---
          //--- [1093: tupleColumnEmptyValue(InnehavAgarintressenOvrigaForetagKapital, nan, nan, InnehavAgarintressenOvrigaForetagKapital, nan)] ---
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base: InnehavAgarintressenOvrigaForetagKapital',
            contextRef: 'balans0',
            standardRubrik:
              'Positivt (negativt) eget kapital per övrigt företag som det finns ägarintresse i',
            tupleRef: tupleRef(
              5,
              `${rows.getBaseId()}.InnehavAndelarAgarintressenOvrigaForetagInklusiveUppgiftForetagTuple.tuple`
            ),
          }), //--- end tuple_rows.tupleColumnEmptyValue ---
          //--- begin tuple_rows.tupleColumnEmptyValue (level 3) [notes.note401.secondTable.InnehavAndelarAgarintressenOvrigaForetagInklusiveUppgiftForetagTuple.InnehavAndelarAgarintressenOvrigaForetagAretsResultat] ---
          //--- [1094: tupleColumnEmptyValue(InnehavAndelarAgarintressenOvrigaForetagAretsResultat, nan, nan, InnehavAndelarAgarintressenOvrigaForetagAretsResultat, nan)] ---
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base: InnehavAndelarAgarintressenOvrigaForetagAretsResultat',
            contextRef: 'period0',
            standardRubrik:
              'Periodens vinst (förlust) per övrigt företag som det finns ägarintresse i',
            tupleRef: tupleRef(
              6,
              `${rows.getBaseId()}.InnehavAndelarAgarintressenOvrigaForetagInklusiveUppgiftForetagTuple.tuple`
            ),
          }), //--- end tuple_rows.tupleColumnEmptyValue ---
          //--- begin tuple_rows.tupleColumnEmptyValue (level 3) [notes.note401.secondTable.InnehavAndelarAgarintressenOvrigaForetagInklusiveUppgiftForetagTuple.InnehavAndelarAgarintressenOvrigaForetagKapitalandel] ---
          //--- [1095: tupleColumnEmptyValue(InnehavAndelarAgarintressenOvrigaForetagKapitalandel, nan, nan, InnehavAndelarAgarintressenOvrigaForetagKapitalandel, nan)] ---
          ixbrlCell(value(undefined), {
            type: 'pureItemType',
            name: 'se-gen-base: InnehavAndelarAgarintressenOvrigaForetagKapitalandel',
            contextRef: 'balans0',
            standardRubrik:
              'Kapitalandel per övrigt företag som det finns ägarintresse i',
            tupleRef: tupleRef(
              7,
              `${rows.getBaseId()}.InnehavAndelarAgarintressenOvrigaForetagInklusiveUppgiftForetagTuple.tuple`
            ),
          }), //--- end tuple_rows.tupleColumnEmptyValue ---
          //--- begin tuple_rows.tupleColumnEmptyValue (level 3) [notes.note401.secondTable.InnehavAndelarAgarintressenOvrigaForetagInklusiveUppgiftForetagTuple.InnehavAndelarAgarintressenOvrigaForetagRostrattsandel] ---
          //--- [1096: tupleColumnEmptyValue(InnehavAndelarAgarintressenOvrigaForetagRostrattsandel, nan, nan, InnehavAndelarAgarintressenOvrigaForetagRostrattsandel, nan)] ---
          ixbrlCell(value(undefined), {
            type: 'pureItemType',
            name: 'se-gen-base: InnehavAndelarAgarintressenOvrigaForetagRostrattsandel',
            contextRef: 'balans0',
            standardRubrik:
              'Rösträttsandel per övrigt företag som det finns ägarintresse i',
            tupleRef: tupleRef(
              8,
              `${rows.getBaseId()}.InnehavAndelarAgarintressenOvrigaForetagInklusiveUppgiftForetagTuple.tuple`
            ),
          }), //--- end tuple_rows.tupleColumnEmptyValue ---
          //--- begin tuple_rows.tupleColumnEmptyValue (level 3) [notes.note401.secondTable.InnehavAndelarAgarintressenOvrigaForetagInklusiveUppgiftForetagTuple.InnehavAgarintressenOvrigaForetagRedovisatVarde] ---
          //--- [1097: tupleColumnEmptyValue(InnehavAgarintressenOvrigaForetagRedovisatVarde, nan, nan, InnehavAgarintressenOvrigaForetagRedovisatVarde, nan)] ---
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base: InnehavAgarintressenOvrigaForetagRedovisatVarde',
            contextRef: 'balans0',
            standardRubrik:
              'Redovisat värde per övrigt företag som det finns ägarintresse i',
            saldo: 'debit',
            tupleRef: tupleRef(
              9,
              `${rows.getBaseId()}.InnehavAndelarAgarintressenOvrigaForetagInklusiveUppgiftForetagTuple.tuple`
            ),
          }) //--- end tuple_rows.tupleColumnEmptyValue ---
        );
        //--- end tuple_rows ---
        //--- begin end_table (level 1) [notes.note401.secondTable] ---
        //--- [1087: tupleTableHeader(InnehavAgarintressenOvrigaForetag, nan, nan, secondTable, nan)] ---
        return rows.build();
      }) //--- begin tuple_rows_generator (level 2) [notes.note401.secondTable.InnehavAndelarAgarintressenOvrigaForetagInklusiveUppgiftForetagTuple] ---
      //--- [1088: tupleId(InnehavAndelarAgarintressenOvrigaForetagInklusiveUppgiftForetagTuple, nan, nan, InnehavAndelarAgarintressenOvrigaForetagInklusiveUppgiftForetagTuple, nan)] ---
      .newRowTemplateGenerator((id, baseId) => ({
        id,
        active: true,
        cells: {
          ForetagetsNamn: ixbrlCell(value(''), {
            type: 'stringItemType',
            name: 'se-cd-base: ForetagetsNamn',
            contextRef: 'period0',
            standardRubrik: 'Företagets nuvarande namn',
            tuple: {
              name: 'se-gaap-ext: InnehavAndelarAgarintressenOvrigaForetagInklusiveUppgiftForetagTuple',
              id: `${baseId}.${id}.tuple`,
              tupleID: `${baseId}.${id}.tuple`,
            },
            tupleRef: tupleRef(1, `${baseId}.${id}.tuple`),
          }),
          Organisationsnummer: ixbrlCell(value(''), {
            type: 'stringItemType',
            name: 'se-cd-base: Organisationsnummer',
            contextRef: 'period0',
            standardRubrik: 'Organisationsnummer',
            tupleRef: tupleRef(2, `${baseId}.${id}.tuple`),
          }),
          ForetagetsSate: ixbrlCell(value(''), {
            type: 'stringItemType',
            name: 'se-cd-base: ForetagetsSate',
            contextRef: 'period0',
            standardRubrik: 'Företagets säte',
            tupleRef: tupleRef(3, `${baseId}.${id}.tuple`),
          }),
          InnehavAndelarAgarintressenOvrigaForetagAntalAndelar: ixbrlCell(
            value(undefined),
            {
              type: 'sharesItemType',
              unitRef: 'andelar',
              name: 'se-gen-base: InnehavAndelarAgarintressenOvrigaForetagAntalAndelar',
              contextRef: 'balans0',
              standardRubrik:
                'Antal andelar per övrigt företag som det finns ägarintresse i',
              tupleRef: tupleRef(4, `${baseId}.${id}.tuple`),
            }
          ),
          InnehavAgarintressenOvrigaForetagKapital: ixbrlCell(
            value(undefined),
            {
              type: 'monetaryItemType',
              name: 'se-gen-base: InnehavAgarintressenOvrigaForetagKapital',
              contextRef: 'balans0',
              standardRubrik:
                'Positivt (negativt) eget kapital per övrigt företag som det finns ägarintresse i',
              tupleRef: tupleRef(5, `${baseId}.${id}.tuple`),
            }
          ),
          InnehavAndelarAgarintressenOvrigaForetagAretsResultat: ixbrlCell(
            value(undefined),
            {
              type: 'monetaryItemType',
              name: 'se-gen-base: InnehavAndelarAgarintressenOvrigaForetagAretsResultat',
              contextRef: 'period0',
              standardRubrik:
                'Periodens vinst (förlust) per övrigt företag som det finns ägarintresse i',
              tupleRef: tupleRef(6, `${baseId}.${id}.tuple`),
            }
          ),
          InnehavAndelarAgarintressenOvrigaForetagKapitalandel: ixbrlCell(
            value(undefined),
            {
              type: 'pureItemType',
              name: 'se-gen-base: InnehavAndelarAgarintressenOvrigaForetagKapitalandel',
              contextRef: 'balans0',
              standardRubrik:
                'Kapitalandel per övrigt företag som det finns ägarintresse i',
              tupleRef: tupleRef(7, `${baseId}.${id}.tuple`),
            }
          ),
          InnehavAndelarAgarintressenOvrigaForetagRostrattsandel: ixbrlCell(
            value(undefined),
            {
              type: 'pureItemType',
              name: 'se-gen-base: InnehavAndelarAgarintressenOvrigaForetagRostrattsandel',
              contextRef: 'balans0',
              standardRubrik:
                'Rösträttsandel per övrigt företag som det finns ägarintresse i',
              tupleRef: tupleRef(8, `${baseId}.${id}.tuple`),
            }
          ),
          InnehavAgarintressenOvrigaForetagRedovisatVarde: ixbrlCell(
            value(undefined),
            {
              type: 'monetaryItemType',
              name: 'se-gen-base: InnehavAgarintressenOvrigaForetagRedovisatVarde',
              contextRef: 'balans0',
              standardRubrik:
                'Redovisat värde per övrigt företag som det finns ägarintresse i',
              saldo: 'debit',
              tupleRef: tupleRef(9, `${baseId}.${id}.tuple`),
            }
          ),
        },
      }))
      //--- end tuple_rows_generator ---
      .build(),
    //--- end end_table ---
    //--- begin end_table_with_comment (level 1) [notes.note401.secondTableComment] ---
    //--- [1098: endTableWithComment(InnehavAndelarIAgarintressenOvrigaForetagKommentar, nan, nan, secondTableComment, nan)] ---
    secondTableCommentLabel: field('Kommentar'),
    secondTableComment: ixbrlField(field(''), {
      type: 'stringItemType',
      name: 'se-gen-base: InnehavAndelarIAgarintressenOvrigaForetagKommentar',
      contextRef: 'period0',
      standardRubrik:
        'Kommentar till innehav i övriga företag som det finns ägarintresse i',
    }),
    //--- end end_table_with_comment ---
    //--- begin end_note_with_comment (level 1) [notes.note401.comment] ---
    //--- [1099: endNoteWithComment(NotAgarintressenOvrigaForetagKommentar, nan, nan, comment, nan)] ---
    commentLabel: field('Kommentar till not'),
    comment: ixbrlField(field(''), {
      type: 'stringItemType',
      name: 'se-gen-base: NotAgarintressenOvrigaForetagKommentar',
      contextRef: 'period0',
      standardRubrik:
        'Kommentar till specifikation av ägarintressen i övriga företag',
    }),
    //--- end end_note_with_comment ---
    //--- begin note_header (level 0) [notes.note401] ---
    //--- [1053: beginNote(NotAgarintressenOvrigaForetag, nan, noteTableSectionType:=NoteThreeCommentTwoTableSection; k2:=false; k3:= documentTypeVersion !== '1'; documentTypeVersion:='1', notes.note401, AGOY-6798)] ---
    group: value(undefined),
    section: value(undefined),
    validForReportType: {
      k2: false,
      k3: documentTypeVersion !== '1',
    },
  };
};
//--- begin note_header (level 0) ---
//--- [1053: beginNote(NotAgarintressenOvrigaForetag, nan, noteTableSectionType:=NoteThreeCommentTwoTableSection; k2:=false; k3:= documentTypeVersion !== '1'; documentTypeVersion:='1', notes.note401, AGOY-6798)] ---
export const Note401References = (): Record<string, string> => ({
  'notes.note401.AgarintressenOvrigaForetagAnskaffningsvardenIngaende.year': id(
    'notes.note401.table.AgarintressenOvrigaForetagAnskaffningsvardenIngaende.year'
  ),
  'notes.note401.AgarintressenOvrigaForetagAnskaffningsvardenIngaende.previousYear':
    id(
      'notes.note401.table.AgarintressenOvrigaForetagAnskaffningsvardenIngaende.previousYear'
    ),
  'notes.note401.AgarintressenOvrigaForetagAnskaffningsvardenInkop.year': id(
    'notes.note401.table.AgarintressenOvrigaForetagAnskaffningsvardenInkop.year'
  ),
  'notes.note401.AgarintressenOvrigaForetagAnskaffningsvardenInkop.previousYear':
    id(
      'notes.note401.table.AgarintressenOvrigaForetagAnskaffningsvardenInkop.previousYear'
    ),
  'notes.note401.AgarintressenOvrigaForetagAnskaffningsvardenFusion.year': id(
    'notes.note401.table.AgarintressenOvrigaForetagAnskaffningsvardenFusion.year'
  ),
  'notes.note401.AgarintressenOvrigaForetagAnskaffningsvardenFusion.previousYear':
    id(
      'notes.note401.table.AgarintressenOvrigaForetagAnskaffningsvardenFusion.previousYear'
    ),
  'notes.note401.AgarintressenOvrigaForetagAnskaffningsvardenForsaljningar.year':
    id(
      'notes.note401.table.AgarintressenOvrigaForetagAnskaffningsvardenForsaljningar.year'
    ),
  'notes.note401.AgarintressenOvrigaForetagAnskaffningsvardenForsaljningar.previousYear':
    id(
      'notes.note401.table.AgarintressenOvrigaForetagAnskaffningsvardenForsaljningar.previousYear'
    ),
  'notes.note401.AgarintressenOvrigaForetagAnskaffningsvardenLamnadeAktieagartillskott.year':
    id(
      'notes.note401.table.AgarintressenOvrigaForetagAnskaffningsvardenLamnadeAktieagartillskott.year'
    ),
  'notes.note401.AgarintressenOvrigaForetagAnskaffningsvardenLamnadeAktieagartillskott.previousYear':
    id(
      'notes.note401.table.AgarintressenOvrigaForetagAnskaffningsvardenLamnadeAktieagartillskott.previousYear'
    ),
  'notes.note401.AgarintressenOvrigaForetagAnskaffningsvardenAterbetalningLamnatAktieagartillskott.year':
    id(
      'notes.note401.table.AgarintressenOvrigaForetagAnskaffningsvardenAterbetalningLamnatAktieagartillskott.year'
    ),
  'notes.note401.AgarintressenOvrigaForetagAnskaffningsvardenAterbetalningLamnatAktieagartillskott.previousYear':
    id(
      'notes.note401.table.AgarintressenOvrigaForetagAnskaffningsvardenAterbetalningLamnatAktieagartillskott.previousYear'
    ),
  'notes.note401.AgarintressenOvrigaForetagAnskaffningsvardenAretsResultatAndel.year':
    id(
      'notes.note401.table.AgarintressenOvrigaForetagAnskaffningsvardenAretsResultatAndel.year'
    ),
  'notes.note401.AgarintressenOvrigaForetagAnskaffningsvardenAretsResultatAndel.previousYear':
    id(
      'notes.note401.table.AgarintressenOvrigaForetagAnskaffningsvardenAretsResultatAndel.previousYear'
    ),
  'notes.note401.AgarintressenOvrigaForetagAnskaffningsvardenOmklassificeringar.year':
    id(
      'notes.note401.table.AgarintressenOvrigaForetagAnskaffningsvardenOmklassificeringar.year'
    ),
  'notes.note401.AgarintressenOvrigaForetagAnskaffningsvardenOmklassificeringar.previousYear':
    id(
      'notes.note401.table.AgarintressenOvrigaForetagAnskaffningsvardenOmklassificeringar.previousYear'
    ),
  'notes.note401.AgarintressenOvrigaForetagAnskaffningsvardenUtdelning.year':
    id(
      'notes.note401.table.AgarintressenOvrigaForetagAnskaffningsvardenUtdelning.year'
    ),
  'notes.note401.AgarintressenOvrigaForetagAnskaffningsvardenUtdelning.previousYear':
    id(
      'notes.note401.table.AgarintressenOvrigaForetagAnskaffningsvardenUtdelning.previousYear'
    ),
  'notes.note401.AgarintressenOvrigaForetagAnskaffningsvardenOmrakningsdifferenser.year':
    id(
      'notes.note401.table.AgarintressenOvrigaForetagAnskaffningsvardenOmrakningsdifferenser.year'
    ),
  'notes.note401.AgarintressenOvrigaForetagAnskaffningsvardenOmrakningsdifferenser.previousYear':
    id(
      'notes.note401.table.AgarintressenOvrigaForetagAnskaffningsvardenOmrakningsdifferenser.previousYear'
    ),
  'notes.note401.AgarintressenOvrigaForetagAnskaffningsvardenUtgaende.year': id(
    'notes.note401.table.AgarintressenOvrigaForetagAnskaffningsvardenUtgaende.year'
  ),
  'notes.note401.AgarintressenOvrigaForetagAnskaffningsvardenUtgaende.previousYear':
    id(
      'notes.note401.table.AgarintressenOvrigaForetagAnskaffningsvardenUtgaende.previousYear'
    ),
  'notes.note401.AgarintressenOvrigaForetagUppskrivningarIngaende.year': id(
    'notes.note401.table.AgarintressenOvrigaForetagUppskrivningarIngaende.year'
  ),
  'notes.note401.AgarintressenOvrigaForetagUppskrivningarIngaende.previousYear':
    id(
      'notes.note401.table.AgarintressenOvrigaForetagUppskrivningarIngaende.previousYear'
    ),
  'notes.note401.AgarintressenOvrigaForetagUppskrivningarFusion.year': id(
    'notes.note401.table.AgarintressenOvrigaForetagUppskrivningarFusion.year'
  ),
  'notes.note401.AgarintressenOvrigaForetagUppskrivningarFusion.previousYear':
    id(
      'notes.note401.table.AgarintressenOvrigaForetagUppskrivningarFusion.previousYear'
    ),
  'notes.note401.AgarintressenOvrigaForetagUppskrivningarAterfordaUppskrivningarForsaljningar.year':
    id(
      'notes.note401.table.AgarintressenOvrigaForetagUppskrivningarAterfordaUppskrivningarForsaljningar.year'
    ),
  'notes.note401.AgarintressenOvrigaForetagUppskrivningarAterfordaUppskrivningarForsaljningar.previousYear':
    id(
      'notes.note401.table.AgarintressenOvrigaForetagUppskrivningarAterfordaUppskrivningarForsaljningar.previousYear'
    ),
  'notes.note401.AgarintressenOvrigaForetagUppskrivningarOmklassificeringar.year':
    id(
      'notes.note401.table.AgarintressenOvrigaForetagUppskrivningarOmklassificeringar.year'
    ),
  'notes.note401.AgarintressenOvrigaForetagUppskrivningarOmklassificeringar.previousYear':
    id(
      'notes.note401.table.AgarintressenOvrigaForetagUppskrivningarOmklassificeringar.previousYear'
    ),
  'notes.note401.AgarintressenOvrigaForetagUppskrivningarAretsUppskrivningar.year':
    id(
      'notes.note401.table.AgarintressenOvrigaForetagUppskrivningarAretsUppskrivningar.year'
    ),
  'notes.note401.AgarintressenOvrigaForetagUppskrivningarAretsUppskrivningar.previousYear':
    id(
      'notes.note401.table.AgarintressenOvrigaForetagUppskrivningarAretsUppskrivningar.previousYear'
    ),
  'notes.note401.AgarintressenOvrigaForetagUppskrivningarAretsNedskrivningarUppskrivetBelopp.year':
    id(
      'notes.note401.table.AgarintressenOvrigaForetagUppskrivningarAretsNedskrivningarUppskrivetBelopp.year'
    ),
  'notes.note401.AgarintressenOvrigaForetagUppskrivningarAretsNedskrivningarUppskrivetBelopp.previousYear':
    id(
      'notes.note401.table.AgarintressenOvrigaForetagUppskrivningarAretsNedskrivningarUppskrivetBelopp.previousYear'
    ),
  'notes.note401.AgarintressenOvrigaForetagUppskrivningarOmrakningsdifferenser.year':
    id(
      'notes.note401.table.AgarintressenOvrigaForetagUppskrivningarOmrakningsdifferenser.year'
    ),
  'notes.note401.AgarintressenOvrigaForetagUppskrivningarOmrakningsdifferenser.previousYear':
    id(
      'notes.note401.table.AgarintressenOvrigaForetagUppskrivningarOmrakningsdifferenser.previousYear'
    ),
  'notes.note401.AgarintressenOvrigaForetagUppskrivningarUtgaende.year': id(
    'notes.note401.table.AgarintressenOvrigaForetagUppskrivningarUtgaende.year'
  ),
  'notes.note401.AgarintressenOvrigaForetagUppskrivningarUtgaende.previousYear':
    id(
      'notes.note401.table.AgarintressenOvrigaForetagUppskrivningarUtgaende.previousYear'
    ),
  'notes.note401.AgarintressenOvrigaForetagNedskrivningarIngaende.year': id(
    'notes.note401.table.AgarintressenOvrigaForetagNedskrivningarIngaende.year'
  ),
  'notes.note401.AgarintressenOvrigaForetagNedskrivningarIngaende.previousYear':
    id(
      'notes.note401.table.AgarintressenOvrigaForetagNedskrivningarIngaende.previousYear'
    ),
  'notes.note401.AgarintressenOvrigaForetagForandringNedskrivningarFusion.year':
    id(
      'notes.note401.table.AgarintressenOvrigaForetagForandringNedskrivningarFusion.year'
    ),
  'notes.note401.AgarintressenOvrigaForetagForandringNedskrivningarFusion.previousYear':
    id(
      'notes.note401.table.AgarintressenOvrigaForetagForandringNedskrivningarFusion.previousYear'
    ),
  'notes.note401.AgarintressenOvrigaForetagForandringNedskrivningarAterfordaNedskrivningarForsaljningar.year':
    id(
      'notes.note401.table.AgarintressenOvrigaForetagForandringNedskrivningarAterfordaNedskrivningarForsaljningar.year'
    ),
  'notes.note401.AgarintressenOvrigaForetagForandringNedskrivningarAterfordaNedskrivningarForsaljningar.previousYear':
    id(
      'notes.note401.table.AgarintressenOvrigaForetagForandringNedskrivningarAterfordaNedskrivningarForsaljningar.previousYear'
    ),
  'notes.note401.AgarintressenOvrigaForetagForandringNedskrivningarOmklassificeringar.year':
    id(
      'notes.note401.table.AgarintressenOvrigaForetagForandringNedskrivningarOmklassificeringar.year'
    ),
  'notes.note401.AgarintressenOvrigaForetagForandringNedskrivningarOmklassificeringar.previousYear':
    id(
      'notes.note401.table.AgarintressenOvrigaForetagForandringNedskrivningarOmklassificeringar.previousYear'
    ),
  'notes.note401.AgarintressenOvrigaForetagForandringNedskrivningarAterfordaNedskrivningar.year':
    id(
      'notes.note401.table.AgarintressenOvrigaForetagForandringNedskrivningarAterfordaNedskrivningar.year'
    ),
  'notes.note401.AgarintressenOvrigaForetagForandringNedskrivningarAterfordaNedskrivningar.previousYear':
    id(
      'notes.note401.table.AgarintressenOvrigaForetagForandringNedskrivningarAterfordaNedskrivningar.previousYear'
    ),
  'notes.note401.AgarintressenOvrigaForetagForandringNedskrivningarAretsNedskrivningar.year':
    id(
      'notes.note401.table.AgarintressenOvrigaForetagForandringNedskrivningarAretsNedskrivningar.year'
    ),
  'notes.note401.AgarintressenOvrigaForetagForandringNedskrivningarAretsNedskrivningar.previousYear':
    id(
      'notes.note401.table.AgarintressenOvrigaForetagForandringNedskrivningarAretsNedskrivningar.previousYear'
    ),
  'notes.note401.AgarintressenOvrigaForetagForandringNedskrivningarOmrakningsdifferenser.year':
    id(
      'notes.note401.table.AgarintressenOvrigaForetagForandringNedskrivningarOmrakningsdifferenser.year'
    ),
  'notes.note401.AgarintressenOvrigaForetagForandringNedskrivningarOmrakningsdifferenser.previousYear':
    id(
      'notes.note401.table.AgarintressenOvrigaForetagForandringNedskrivningarOmrakningsdifferenser.previousYear'
    ),
  'notes.note401.AgarintressenOvrigaForetagNedskrivningarUtgaende.year': id(
    'notes.note401.table.AgarintressenOvrigaForetagNedskrivningarUtgaende.year'
  ),
  'notes.note401.AgarintressenOvrigaForetagNedskrivningarUtgaende.previousYear':
    id(
      'notes.note401.table.AgarintressenOvrigaForetagNedskrivningarUtgaende.previousYear'
    ),
  'notes.note401.AgarintressenOvrigaForetag.year': id(
    'notes.note401.table.AgarintressenOvrigaForetag.year'
  ),
  'notes.note401.AgarintressenOvrigaForetag.previousYear': id(
    'notes.note401.table.AgarintressenOvrigaForetag.previousYear'
  ),
  'notes.note401.firstTableComment.year': id(
    'notes.note401.firstTableComment.year'
  ),
  'notes.note401.firstTableComment.previousYear': id(
    'notes.note401.firstTableComment.previousYear'
  ),
  'notes.note401.secondTable.year': id('notes.note401.secondTable.year'),
  'notes.note401.secondTable.previousYear': id(
    'notes.note401.secondTable.previousYear'
  ),
  'notes.note401.InnehavAndelarAgarintressenOvrigaForetagInklusiveUppgiftForetagTuple.year':
    id(
      'notes.note401.secondTable.InnehavAndelarAgarintressenOvrigaForetagInklusiveUppgiftForetagTuple.year'
    ),
  'notes.note401.InnehavAndelarAgarintressenOvrigaForetagInklusiveUppgiftForetagTuple.previousYear':
    id(
      'notes.note401.secondTable.InnehavAndelarAgarintressenOvrigaForetagInklusiveUppgiftForetagTuple.previousYear'
    ),
  'notes.note401.ForetagetsNamn.year': id(
    'notes.note401.secondTable.InnehavAndelarAgarintressenOvrigaForetagInklusiveUppgiftForetagTuple.ForetagetsNamn.year'
  ),
  'notes.note401.ForetagetsNamn.previousYear': id(
    'notes.note401.secondTable.InnehavAndelarAgarintressenOvrigaForetagInklusiveUppgiftForetagTuple.ForetagetsNamn.previousYear'
  ),
  'notes.note401.Organisationsnummer.year': id(
    'notes.note401.secondTable.InnehavAndelarAgarintressenOvrigaForetagInklusiveUppgiftForetagTuple.Organisationsnummer.year'
  ),
  'notes.note401.Organisationsnummer.previousYear': id(
    'notes.note401.secondTable.InnehavAndelarAgarintressenOvrigaForetagInklusiveUppgiftForetagTuple.Organisationsnummer.previousYear'
  ),
  'notes.note401.ForetagetsSate.year': id(
    'notes.note401.secondTable.InnehavAndelarAgarintressenOvrigaForetagInklusiveUppgiftForetagTuple.ForetagetsSate.year'
  ),
  'notes.note401.ForetagetsSate.previousYear': id(
    'notes.note401.secondTable.InnehavAndelarAgarintressenOvrigaForetagInklusiveUppgiftForetagTuple.ForetagetsSate.previousYear'
  ),
  'notes.note401.InnehavAndelarAgarintressenOvrigaForetagAntalAndelar.year': id(
    'notes.note401.secondTable.InnehavAndelarAgarintressenOvrigaForetagInklusiveUppgiftForetagTuple.InnehavAndelarAgarintressenOvrigaForetagAntalAndelar.year'
  ),
  'notes.note401.InnehavAndelarAgarintressenOvrigaForetagAntalAndelar.previousYear':
    id(
      'notes.note401.secondTable.InnehavAndelarAgarintressenOvrigaForetagInklusiveUppgiftForetagTuple.InnehavAndelarAgarintressenOvrigaForetagAntalAndelar.previousYear'
    ),
  'notes.note401.InnehavAgarintressenOvrigaForetagKapital.year': id(
    'notes.note401.secondTable.InnehavAndelarAgarintressenOvrigaForetagInklusiveUppgiftForetagTuple.InnehavAgarintressenOvrigaForetagKapital.year'
  ),
  'notes.note401.InnehavAgarintressenOvrigaForetagKapital.previousYear': id(
    'notes.note401.secondTable.InnehavAndelarAgarintressenOvrigaForetagInklusiveUppgiftForetagTuple.InnehavAgarintressenOvrigaForetagKapital.previousYear'
  ),
  'notes.note401.InnehavAndelarAgarintressenOvrigaForetagAretsResultat.year':
    id(
      'notes.note401.secondTable.InnehavAndelarAgarintressenOvrigaForetagInklusiveUppgiftForetagTuple.InnehavAndelarAgarintressenOvrigaForetagAretsResultat.year'
    ),
  'notes.note401.InnehavAndelarAgarintressenOvrigaForetagAretsResultat.previousYear':
    id(
      'notes.note401.secondTable.InnehavAndelarAgarintressenOvrigaForetagInklusiveUppgiftForetagTuple.InnehavAndelarAgarintressenOvrigaForetagAretsResultat.previousYear'
    ),
  'notes.note401.InnehavAndelarAgarintressenOvrigaForetagKapitalandel.year': id(
    'notes.note401.secondTable.InnehavAndelarAgarintressenOvrigaForetagInklusiveUppgiftForetagTuple.InnehavAndelarAgarintressenOvrigaForetagKapitalandel.year'
  ),
  'notes.note401.InnehavAndelarAgarintressenOvrigaForetagKapitalandel.previousYear':
    id(
      'notes.note401.secondTable.InnehavAndelarAgarintressenOvrigaForetagInklusiveUppgiftForetagTuple.InnehavAndelarAgarintressenOvrigaForetagKapitalandel.previousYear'
    ),
  'notes.note401.InnehavAndelarAgarintressenOvrigaForetagRostrattsandel.year':
    id(
      'notes.note401.secondTable.InnehavAndelarAgarintressenOvrigaForetagInklusiveUppgiftForetagTuple.InnehavAndelarAgarintressenOvrigaForetagRostrattsandel.year'
    ),
  'notes.note401.InnehavAndelarAgarintressenOvrigaForetagRostrattsandel.previousYear':
    id(
      'notes.note401.secondTable.InnehavAndelarAgarintressenOvrigaForetagInklusiveUppgiftForetagTuple.InnehavAndelarAgarintressenOvrigaForetagRostrattsandel.previousYear'
    ),
  'notes.note401.InnehavAgarintressenOvrigaForetagRedovisatVarde.year': id(
    'notes.note401.secondTable.InnehavAndelarAgarintressenOvrigaForetagInklusiveUppgiftForetagTuple.InnehavAgarintressenOvrigaForetagRedovisatVarde.year'
  ),
  'notes.note401.InnehavAgarintressenOvrigaForetagRedovisatVarde.previousYear':
    id(
      'notes.note401.secondTable.InnehavAndelarAgarintressenOvrigaForetagInklusiveUppgiftForetagTuple.InnehavAgarintressenOvrigaForetagRedovisatVarde.previousYear'
    ),
  'notes.note401.secondTableComment.year': id(
    'notes.note401.secondTableComment.year'
  ),
  'notes.note401.secondTableComment.previousYear': id(
    'notes.note401.secondTableComment.previousYear'
  ),
  'notes.note401.comment.year': id('notes.note401.comment.year'),
  'notes.note401.comment.previousYear': id(
    'notes.note401.comment.previousYear'
  ),
});

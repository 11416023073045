import { DocumentValues } from '../../types';
import { SET_DOCUMENT_VALUES } from './action-types';

interface SetDocumentValuesAction {
  type: typeof SET_DOCUMENT_VALUES;
  clientId: string;
  financialYear: string;
  documentName: string;
  values: DocumentValues;
}

export type DocumentValuesActions = SetDocumentValuesAction;

export const setDocumentValues = (
  clientId: string,
  financialYear: string,
  documentName: string,
  values: DocumentValues
): SetDocumentValuesAction => ({
  type: SET_DOCUMENT_VALUES,
  clientId,
  financialYear,
  documentName,
  values,
});

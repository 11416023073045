import React, { useState, useEffect } from 'react';
import { Card } from '@material-ui/core';
import styled from '@emotion/styled';
import { useParams, useHistory } from 'react-router-dom';

import { useSelector } from 'redux/reducers';
import { ChecklistTemplate } from '_shared/redux/checklist/types';
import Typography from '_shared/components/Typography/Typography';
import ChecklistForm from './Components/ChecklistForm/ChecklistForm';

const StyledCard = styled(Card)`
  padding: 16px 16px;
  min-width: 1000px;
  min-height: 40vh;
  margin-bottom: ${({ theme }) => theme.spacing(9)}px;
`;

interface RouteParams {
  checklistId: string;
}

const ChecklistDetails = (): JSX.Element | null => {
  const { checklistId } = useParams<RouteParams>();
  const history = useHistory();
  const checklists = useSelector((state) => state.organisation.checklists);
  const [selectedChecklist, setSelectedChecklist] =
    useState<ChecklistTemplate>();

  useEffect(() => {
    if (checklistId) {
      const checklistById = checklists.find(
        (item) => `${item.id}` === checklistId
      );
      if (checklistById) {
        setSelectedChecklist(checklistById);
      }
    }
  }, [checklists, checklistId]);

  const handleCancel = async () => {
    history.push(`/organisation/checklists`);
  };

  if (!selectedChecklist) {
    return null;
  }

  return (
    <StyledCard>
      <Typography variant="h2">{selectedChecklist.name}</Typography>
      <ChecklistForm
        checklist={selectedChecklist}
        onChangeChecklist={() => {}}
        onCancel={handleCancel}
        readonly
      />
    </StyledCard>
  );
};

export default ChecklistDetails;

import React, { useCallback, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';
import { Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';

import {
  AgoyTable,
  AgoyTableRow,
  AgoyTableColumn,
  Cell as CellType,
  isMultiReferenceCell,
  isResolveReferenceError,
} from '@agoy/document';
import { ccyFormat } from '@agoy/common';
import {
  addTaxDeclarationFormTableRow,
  deleteTaxDeclarationFormTableRow,
  toggleTaxDeclarationFormTableRowActive,
  updateTaxDeclarationFormTableCell,
  updateTaxDeclarationFormTableRows,
} from 'redux/actions';
import { Table, TableServiceType } from '_shared/components/CommonTable';
import { TableCell } from '_shared/components/Table/BorderTable';
import { Cell } from '_shared/components/CommonTable/Cell';
import Button from '_shared/components/Buttons/Button';
import { fieldBackground } from '../EditForm/ui-helper';
import EditTableModal from './EditTableModal';

const StyledTable = styled(Table, {
  shouldForwardProp: (prop) => prop !== 'finalPart',
})<{ finalPart: boolean }>`
  border: none;
  box-shadow: none;

  tr td,
  tr th {
    border: none;
  }

  td {
    padding-top: 2px;
    padding-bottom: 2px;
    height: 38px;

    &:first-of-type {
      min-width: 450px;
      padding-left: 0;
    }

    &:last-of-type {
      padding-right: 150px;
    }

    input {
      &.ref {
        background: ${fieldBackground('calculated')};
      }

      &.ref-changed {
        background: ${fieldBackground('calculated-changed')};
      }
    }
  }

  tr {
    &:first-of-type td {
      border-top: 1px solid #002517;
      padding-top: 4px;
    }

    &:last-of-type td {
      ${({ finalPart }) => (finalPart ? `border-top: 1px solid #dde1e0;` : '')}
      border-bottom: 1px solid #8e9491;
      padding-bottom: 4px;
    }

    &:nth-last-of-type(2) td {
      padding-bottom: 4px;
    }
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    font-weight: 700;
    font-size: 16px;
  }
`;

const Text = styled(Typography)`
  padding-top: 12px;
`;

type CalculationsTableProps = {
  id: string;
  table: AgoyTable;
  rawTable?: AgoyTable;
  title?: string;
  showSumRow?: boolean;
  isPrint?: boolean;
};

const CalculationsTable = ({
  id,
  table,
  title,
  rawTable,
  showSumRow = false,
  isPrint = false,
}: CalculationsTableProps) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const columns = useMemo(() => {
    return table.columns.filter((col) => col.id !== 'default');
  }, [table.columns]);

  const subRows = useMemo(() => {
    return table.rows.find((row) => row.id === 'main')?.rows ?? [];
  }, [table.rows]);

  const renderCell = useCallback(
    (values: {
      cell: CellType;
      column: AgoyTableColumn;
      row: AgoyTableRow;
      baseId: string;
      isDragging: boolean;
    }) => {
      const { cell, column, row, baseId } = values;

      const isValue = column.id === 'value';
      const isName = column.id === 'name';
      const isSumRow = row.id === 'sum';

      const key = `${baseId}.${row.id}.${column.id}`;

      if (isMultiReferenceCell(cell)) {
        return null;
      }

      const { value } = cell;

      if (isResolveReferenceError(value)) {
        return null;
      }

      return isValue && !isSumRow ? (
        <TableCell align="left" key={key}>
          <Cell cell={cell} id={key} editing />
        </TableCell>
      ) : (
        <TableCell align={isName ? 'left' : 'right'} key={key}>
          {isSumRow ? (
            <b>{typeof value === 'number' ? ccyFormat(value) : value}</b>
          ) : (
            value
          )}
        </TableCell>
      );
    },
    []
  );

  const handleUpdateCell = useCallback(
    (cellId: string, value) => {
      const ids = cellId.split('.');
      const tableId = ids.slice(1).join('.');

      dispatch(updateTaxDeclarationFormTableCell(ids[0], tableId, value));
    },
    [dispatch]
  );

  const handleAddRow = useCallback(
    (partId: string, newRowId?: string) => {
      dispatch(
        addTaxDeclarationFormTableRow(
          partId,
          'main',
          newRowId ?? `${subRows.length + 1}`
        )
      );
    },
    [dispatch, subRows]
  );

  const handleToggleRowActive = useCallback(
    (partId: string) => {
      dispatch(toggleTaxDeclarationFormTableRowActive(partId));
    },
    [dispatch]
  );

  const handleRemoveRow = useCallback(
    (path: string) => {
      const ids = path.split('.');
      const rowId = ids.splice(-1)[0] ?? '';

      dispatch(deleteTaxDeclarationFormTableRow(ids.join('.'), rowId));
    },
    [dispatch]
  );

  const handleUpdateRows = useCallback(
    (
      rows: {
        id: string;
        sortKey?: number;
        active?: boolean;
      }[]
    ) => {
      dispatch(updateTaxDeclarationFormTableRows(rows));
    },
    [dispatch]
  );

  const service = useMemo(() => {
    const newService = {} as TableServiceType;
    newService.updateCellValue = handleUpdateCell;
    newService.addRow = handleAddRow;
    newService.toggleTableRowActive = handleToggleRowActive;
    newService.deleteRow = handleRemoveRow;
    newService.updateRows = handleUpdateRows;

    return newService;
  }, [
    handleUpdateCell,
    handleAddRow,
    handleToggleRowActive,
    handleRemoveRow,
    handleUpdateRows,
  ]);

  return (
    <div>
      {!isPrint && rawTable && (
        <EditTableModal
          isOpen={isEditModalOpen}
          onClose={() => setIsEditModalOpen(false)}
          table={rawTable}
          baseId={id}
          service={service}
        />
      )}

      <Header>
        <Typography>{formatMessage({ id: title })}</Typography>
        {!isPrint && (
          <Button
            variant="text"
            label={formatMessage({ id: 'edit.table' })}
            onClick={() => setIsEditModalOpen(true)}
          />
        )}
      </Header>

      <StyledTable
        baseId={id}
        tableId={id}
        columns={columns}
        rows={table.rows.filter((row) =>
          showSumRow ? true : row.id !== 'sum'
        )}
        renderCell={renderCell}
        service={service}
        hideHeader
        finalPart={showSumRow}
        print={isPrint}
        editing={!isPrint}
      />

      {!showSumRow && (
        <Text>
          {formatMessage({ id: 'tax.declaration.additionalPages.nextPage' })}
        </Text>
      )}
    </div>
  );
};

export default CalculationsTable;

import React from 'react';
import { useIntl } from 'react-intl';
import styled from '@emotion/styled';
import { Typography, Button } from '@material-ui/core';

import { appHeaderHeight } from 'theme/measurements';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: calc(100vh - ${appHeaderHeight * 2}px);
`;

type Props = {
  handleSelectRedirect: () => void;
};

const Page = ({ handleSelectRedirect }: Props) => {
  const { formatMessage } = useIntl();

  return (
    <Wrapper>
      <Typography variant="h1">
        {formatMessage({ id: 'payment.cancel' })}
      </Typography>
      <Button
        onClick={() => handleSelectRedirect()}
        variant="contained"
        disableElevation
      >
        {formatMessage({ id: 'forgot.back' })}
      </Button>
    </Wrapper>
  );
};

export default Page;

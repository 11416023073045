import { isLeft } from 'fp-ts/lib/Either';
import * as t from 'io-ts';
import reporter from 'io-ts-reporters';
import { Err, Ok, Result } from 'ts-results';
import { ValidationErrors, VALIDATION_ERRORS } from './types';

/**
 * Generic function to decode messages with.
 *
 * @param codec The codec to decode with
 * @param json JSON input
 * @returns A validated result
 */
export const parseMessage = <T>(
  codec: t.Type<T>,
  json: string
): Result<t.TypeOf<typeof codec>, ValidationErrors> => {
  const result = codec.decode(JSON.parse(json));
  if (isLeft(result)) {
    return Err({ type: VALIDATION_ERRORS, errors: reporter.report(result) });
  }
  return Ok(result.right);
};

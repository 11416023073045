import React, { useContext } from 'react';
import {
  DialogContent,
  DialogActions,
  DialogTitle,
  Dialog,
} from '@material-ui/core';
import { ErrorBoundaryContext } from 'react-error-boundary';
import Button from './Buttons/Button';

export default function FallbackComponent() {
  // if used as an react-error-boundary fallback, there
  // is a context to use for resetting the error state.
  const context = useContext(ErrorBoundaryContext);

  return (
    <Dialog open>
      <DialogTitle>Ett fel har uppstått</DialogTitle>
      <DialogContent>
        Vi har loggat felet och kommer att titta på det så snart som möjligt.
      </DialogContent>
      <DialogActions>
        <Button
          label="Ladda om sidan"
          onClick={() => {
            (context?.resetErrorBoundary ?? window.location.reload)();
          }}
        />
      </DialogActions>
    </Dialog>
  );
}

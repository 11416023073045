/**
 * Rounds number to two digits after floating point
 *
 * @param a
 * @returns a, rounded to two digits after floating point
 */
export function twoDigitsRound(a: number) {
  return Math.round((a + Number.EPSILON) * 100) / 100;
}

/**
 * Compare two numbers with two decimal precision, allows undefined values
 * Two undefined values are considered equal.
 *
 * @param a
 * @param b
 * @returns true if a and b rounded to two decimals are equal
 */
export function isDecimalEqual(
  a: number | undefined | null,
  b: number | undefined | null
): boolean {
  if (a == null) {
    return b == null;
  }
  if (b == null) {
    return false;
  }
  return Math.round(a * 100) - Math.round(b * 100) === 0;
}

import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import { useIntl } from 'react-intl';
import { Alert } from '@material-ui/lab';
import Button from '_shared/components/Buttons/Button';

type Props = {
  isOpen: boolean;
  onAccept: () => void;
  onCancel: () => void;
};

const ConfirmationDialog = ({ isOpen, onAccept, onCancel }: Props) => {
  const intl = useIntl();
  const text = (id: string) =>
    intl.formatMessage({
      id: `connections.addConnection.confirmationDialog.${id}`,
    });

  return (
    <Dialog
      open={isOpen}
      onClose={() => onCancel()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>{text('title')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{text('text')}</DialogContentText>
        <Alert severity="warning">{text('warningText')}</Alert>
      </DialogContent>
      <DialogActions>
        <Button label={text('cancel')} variant="text" onClick={onCancel} />
        <Button label={text('continue')} onClick={onAccept} />
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;

import { isEqual } from 'lodash';
import { Dispatch } from 'redux';
import {
  savingUserDataFailed,
  savingUserDataSuccess,
  startedSavingUserData,
} from '_reconciliation/redux/accounting-view/actions';
import {
  PATCH_USER_INPUT_DATA,
  PATCH_USER_INPUT_ROUTINE_DATA,
  SET_NUM_DOCUMENTS,
} from '_reconciliation/redux/accounting-view/action-types';
import { AccountingViewClientYear } from '_reconciliation/redux/accounting-view/reducer';
import { InputData } from '_reconciliation/types';
import { addGlobalErrorMessage } from '_messages/redux/actions';
import { createInputData, createInputRoutineData } from 'utils/UserInputUtils';
import debounceByKey from 'utils/debounceByKey';
import hasOwnProperty from 'utils/hasOwnProperty';
import { RootState } from 'redux/reducers';
import { asResultClass, getApiSdk } from 'api-sdk';
import { getContext } from 'utils/AgoyAppClient/contextHolder';

const saveInputKey = (
  dispatch,
  clientId,
  financialYear,
  account,
  period,
  periodId,
  userInput
) => {
  return `${clientId}_${financialYear}_${account}_${period}`;
};

const saveInputRoutineKey = (dispatch, clientId, account, routine) => {
  return `${clientId}__${account}`;
};

const saveInput = async (
  dispatch,
  clientId,
  financialYear,
  account,
  period,
  periodId,
  userInput
) => {
  const [year, month] = period.split('-');
  const accountingYear = financialYear.substring(0, 4);
  dispatch(startedSavingUserData());
  const sdk = getApiSdk(getContext());

  try {
    await sdk.putHiddenRowInput({
      accountingYear,
      year,
      month,
      clientid: clientId,
      periodId,
      requestBody: {
        [`account${account}`]: {
          ...userInput,
        },
      },
    });

    dispatch(savingUserDataSuccess());
  } catch (err) {
    if (typeof err === 'object' && hasOwnProperty(err, 'code')) {
      if (err.code === 'RECONCILIATION.LOCKED') {
        dispatch(addGlobalErrorMessage('error.RECONCILIATION.LOCKED'));
      }
    }
    dispatch(savingUserDataFailed());
  }
};

const saveInputRoutine = async (dispatch, clientId, account, routine) => {
  const sdk = getApiSdk(getContext());

  dispatch(startedSavingUserData());

  const result = await asResultClass(
    sdk.addRoutine({
      clientid: clientId,
      requestBody: {
        input: routine,
        account,
      },
    })
  );

  if (result.ok) {
    dispatch(savingUserDataSuccess());
  } else {
    dispatch(savingUserDataFailed());
  }
};

const save = debounceByKey(saveInputKey, saveInput, 1000);

const saveRoutine = debounceByKey(saveInputRoutineKey, saveInputRoutine, 1000);

const getInputData = (
  accountingView: AccountingViewClientYear | null | undefined,
  accountNumber,
  formattedPeriod
): InputData | undefined => {
  const accountUserInput =
    accountingView?.userInput?.[`account${accountNumber}`];
  return (
    accountUserInput &&
    accountUserInput[formattedPeriod] &&
    createInputData(accountUserInput[formattedPeriod])
  );
};

const saveUserInputData =
  ({ getState, dispatch }: { getState: () => RootState; dispatch: Dispatch }) =>
  (next) =>
  (action) => {
    const prevState = getState();
    const result = next(action);
    const newState = getState();

    if (
      action.type === PATCH_USER_INPUT_DATA ||
      action.type === SET_NUM_DOCUMENTS
    ) {
      const { accountNumber, formattedPeriod, periodId } = action;

      const { clientId, financialYear } = action;

      const { accountingView: prevAccountingView } = prevState;
      const { accountingView: newAccountingView } = newState;

      const previousInputData = getInputData(
        prevAccountingView.clients[clientId]?.years[financialYear],
        accountNumber,
        formattedPeriod
      );

      const inputData = getInputData(
        newAccountingView.clients[clientId]?.years[financialYear],
        accountNumber,
        formattedPeriod
      );

      if (!isEqual(previousInputData, inputData)) {
        save(
          dispatch,
          clientId,
          financialYear,
          accountNumber,
          formattedPeriod,
          periodId,
          inputData
        );
      }
    }

    if (action.type === PATCH_USER_INPUT_ROUTINE_DATA) {
      const { accountNumber, clientId } = action;
      const { accountingView } = getState();
      const clientState = accountingView.clients[clientId];

      if (!clientState) {
        console.error('No client');
        dispatch(addGlobalErrorMessage('error'));
      } else if (!clientState.routines) {
        console.error('No routines loaded');
        dispatch(addGlobalErrorMessage('error'));
      } else {
        saveRoutine(
          dispatch,
          clientId,
          accountNumber,
          createInputRoutineData(
            clientState.routines[`account${accountNumber}`]
          )
        );
      }
    }

    return result;
  };

export default saveUserInputData;

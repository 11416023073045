import {
  TaxDeclarationFormDefinition,
  TaxDeclarationFormDataConfig,
} from '../../types';
import { ref, value, id } from '@agoy/document';
import { mapFormId, toInitial, toStructure } from '../forms-util';

const configN4 = (
  form: TaxDeclarationFormDefinition,
  financialYearStart: string,
  financialYearEnd: string
): TaxDeclarationFormDataConfig => {
  const N4 = mapFormId(form, 'N4');
  const initial = {
    N4: {
      partType: 'form' as const,
      id: N4,
      fields: {
        datum: value(''),
        numrering_vid_flera_n4: value(undefined),
        namn: value(''),
        organisationsnummer: value(''),
        handelsbolagets_organisationsnummer: value(''),
        A_1_antal: value(undefined),
        A_1_beteckning_aktier: value(''),
        A_1_vinst: value(undefined),
        A_1_ersattning: value(undefined),
        A_2_antal: value(undefined),
        A_2_beteckning_aktier: value(''),
        A_2_organisationsnummer: value(''),
        A_2_vinst: value(undefined),
        A_2_andel: value(undefined),
        A_2_uppskovsbelopp: value(undefined),
        A_3_antal: value(undefined),
        A_3_beteckning_aktier: value(''),
        A_3_organisationsnummer: value(''),
        A_3_vinst: value(undefined),
        A_3_tidigare_uppskov: value(undefined),
        A_3_begart_uppskov: value(undefined),
        A_4_antal: value(undefined),
        A_4_beteckning: value(''),
        A_4_uppskov_som_ska_aterforas: value(undefined),
        A_4_aterstaende_uppskov: value(undefined),
        B_1_antal: value(undefined),
        B_1_beteckning: value(''),
        B_1_uppskov_som_ska_aterforas: value(undefined),
        B_1_aterstaende_uppskov: value(undefined),
        C_1_beteckning: value(''),
        C_1_antal_aktier: value(undefined),
        C_1_kvarvarande_uppskov: value(undefined),
      },
      sru: {
        '7011': value(financialYearStart),
        '7012': value(financialYearEnd),
        '7014': ref(id('N4.fields.numrering_vid_flera_n4')),
        '7040': ref(id('N4.fields.handelsbolagets_organisationsnummer')),
        '8500': ref(id('N4.fields.A_1_antal')),
        '8501': ref(id('N4.fields.A_1_beteckning_aktier')),
        '8502': ref(id('N4.fields.A_1_vinst')),
        '8503': ref(id('N4.fields.A_1_ersattning')),
        '8512': ref(id('N4.fields.A_2_antal')),
        '8513': ref(id('N4.fields.A_2_beteckning_aktier')),
        '8514': ref(id('N4.fields.A_2_organisationsnummer')),
        '8515': ref(id('N4.fields.A_2_vinst')),
        '8516': ref(id('N4.fields.A_2_andel')),
        '8517': ref(id('N4.fields.A_2_uppskovsbelopp')),
        '8532': ref(id('N4.fields.A_3_antal')),
        '8533': ref(id('N4.fields.A_3_beteckning_aktier')),
        '8534': ref(id('N4.fields.A_3_organisationsnummer')),
        '8535': ref(id('N4.fields.A_3_vinst')),
        '8536': ref(id('N4.fields.A_3_tidigare_uppskov')),
        '8537': ref(id('N4.fields.A_3_begart_uppskov')),
        '8544': ref(id('N4.fields.A_4_antal')),
        '8545': ref(id('N4.fields.A_4_beteckning')),
        '8546': ref(id('N4.fields.A_4_uppskov_som_ska_aterforas')),
        '8547': ref(id('N4.fields.A_4_aterstaende_uppskov')),
        '8557': ref(id('N4.fields.B_1_antal')),
        '8558': ref(id('N4.fields.B_1_beteckning')),
        '8559': ref(id('N4.fields.B_1_uppskov_som_ska_aterforas')),
        '8560': ref(id('N4.fields.B_1_aterstaende_uppskov')),
        '8570': ref(id('N4.fields.C_1_beteckning')),
        '8571': ref(id('N4.fields.C_1_antal_aktier')),
        '8572': ref(id('N4.fields.C_1_kvarvarande_uppskov')),
      },
    },
  };
  return {
    initial: toInitial(initial, form),
    definition: toStructure(initial, form),
  };
};

export default configN4;

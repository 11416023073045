import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { FormHelperText, TableCell } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { Table, TableServiceType } from '_shared/components/CommonTable';
import TableRow from '_shared/components/CommonTable/TableRow';
import { AgoyTableRow } from '@agoy/document';
import {
  SimplifiedDatePicker,
  SimplifiedSelect,
} from '_shared/components/Inputs';
import { useDispatch } from 'react-redux';
import { add, format, isAfter } from 'date-fns';
import { addGlobalErrorMessage } from 'redux/actions';
import {
  CompanyConnection,
  StockRegistrationType,
  StockTotal,
} from '_client-connections/types';
import { updateStockTotals } from '_client-connections/redux/persons/actions';
import { setUiStatus } from 'redux/actions/UI';
import { NonReferenceCell } from '_shared/components/CommonTable/Cell';
import { ccyFormat, ccyParse, StandardNumberFormatType } from '@agoy/common';
import CurrencyField from '_shared/components/Inputs/CurrencyField';
import StringInput from '_shared/components/CommonTable/Cell/Field/StringInput';
import { parseFormat, isSameOrBefore, parse } from '@agoy/dates';

import ConnectionsTableHeader from './ConnectionsTableHeader';

const StyledTable = styled(Table)`
  margin-top: ${({ theme }) => theme.spacing(4)}px;
`;

export const StyledDateField = styled(SimplifiedDatePicker)`
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
  flex: 1;

  .MuiInputBase-input {
    height: auto;
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .MuiInputBase-root {
    padding-right: 8px;
  }
  .MuiIconButton-root {
    padding: 8px;
  }
  fieldset {
    border: 1px solid #e0e0e0;
  }
`;

const StyledSelect = styled(SimplifiedSelect)`
  width: 100%;
  min-width: 150px;
  padding: 0;

  .MuiSelect-outlined {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .MuiInputBase-root {
    width: 100%;
  }

  fieldset {
    border: 1px solid #e0e0e0;
  }
`;

const StyledTableCell = styled(TableCell)`
  vertical-align: top;
  background-color: inherit;

  &.error-shareTotal input {
    border: 1px solid red;
  }
`;

const NumberInput = styled(StringInput)`
  text-align: right;
`;

const validateDate = (
  formatMessage: ({ id }) => string,
  dateValue: string | null | undefined,
  stockTotals: StockTotal[]
) => {
  const initialRegistration =
    stockTotals.length > 1 ? stockTotals[stockTotals.length - 1] : undefined;
  if (
    dateValue &&
    initialRegistration &&
    !isAfter(new Date(dateValue), new Date(initialRegistration.date))
  ) {
    return formatMessage({
      id: 'connections.validation.stockTotal.dateShouldBeAfterInitial',
    });
  }

  if (
    stockTotals
      .slice(0, stockTotals.length - 1)
      .some((totals) => totals.date === dateValue)
  ) {
    return formatMessage({
      id: 'connections.validation.stockTotal.datesShouldNotRepeat',
    });
  }
  return '';
};

const validateShares = (
  formatMessage: ({ id }) => string,
  newSharesChangeValue: number | undefined,
  currentRegistration: StockTotal,
  previousRegistration: StockTotal
) => {
  if (previousRegistration && newSharesChangeValue !== undefined) {
    const newTotal = newSharesChangeValue + previousRegistration.shareTotal;

    if (
      currentRegistration.type === 'new_emission' &&
      newTotal < previousRegistration.shareTotal
    ) {
      return formatMessage({
        id: 'connections.validation.stockTotal.newEmissionShouldBeHigher',
      });
    }

    if (newTotal < 0) {
      return formatMessage({
        id: 'connections.validation.stockTotal.stockCannotBeLessThanZero',
      });
    }
  }
  return '';
};

type Props = {
  onStockUpdate: () => void;
  companyConnection?: CompanyConnection;
};

const TotalTransactionsTable = ({
  companyConnection,
  onStockUpdate,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const text = useCallback(
    (id: string, values?: Record<string, string | number | undefined>) =>
      formatMessage({ id: `connections.detail.totalShares.${id}` }, values),
    [formatMessage]
  );

  const { orgId, orgName, orgNumber } = companyConnection || {};

  const [stockTotals, setStockTotals] = useState<StockTotal[]>(
    companyConnection?.stockTotals || []
  );

  const [errors, setErrors] = useState({
    shareTotal: '',
    date: '',
  });

  useEffect(() => {
    if (companyConnection) {
      setStockTotals(companyConnection.stockTotals || []);
    }
  }, [companyConnection]);

  /**
   * How editing works:
   *   - All the changes are stored on local state.
   *   - Only first row is editable.
   *   - Edited, deleted, created rows are applied on switching from editing mode to view mode.
   *   - Editing a row deletes old one and creates a new one.
   *   - New rows are defined by absence of id.
   *   - Deleted rows are the ones which are present in the model, but not present in local state.
   */

  const [editing, setEditing] = useState(false);

  const saveChanges = useCallback(async () => {
    dispatch(setUiStatus({ updatingConnections: true }));
    try {
      await dispatch(
        updateStockTotals(
          orgId || '',
          companyConnection?.stockTotals || [],
          stockTotals
        )
      );

      await onStockUpdate();
    } catch (e) {
      dispatch(addGlobalErrorMessage('error'));
    }
    setEditing(false);
    dispatch(setUiStatus({ updatingConnections: false }));
  }, [
    companyConnection?.stockTotals,
    dispatch,
    onStockUpdate,
    orgId,
    stockTotals,
  ]);

  const updateField = useCallback(
    (id, value) => {
      const [, , field] = id.split('.');

      setStockTotals((oldTotals) => [
        { ...oldTotals[0], id: undefined, [field]: value },
        ...oldTotals.slice(1),
      ]);
    },
    [setStockTotals]
  );

  const addNewStockTotal = useCallback(() => {
    setStockTotals((oldTotals) => [
      {
        shareTotal: oldTotals[0]?.shareTotal || 0,
        date: format(
          oldTotals[0]?.date
            ? add(new Date(oldTotals[0]?.date), {
                days: 1,
              })
            : new Date(),
          'yyyy-MM-dd'
        ),
        type: oldTotals.length === 0 ? 'new_registration' : 'new_emission',
      },
      ...oldTotals,
    ]);
  }, [setStockTotals]);

  const deleteStockTotal = useCallback(() => {
    setStockTotals((oldTotals) => oldTotals.slice(1));
    setErrors({ shareTotal: '', date: '' });
  }, [setStockTotals]);

  const discardChanges = () => {
    setStockTotals(companyConnection?.stockTotals || []);
    setEditing(false);
    setErrors({ shareTotal: '', date: '' });
  };

  const service = useMemo(() => {
    const newService = {} as TableServiceType;
    newService.addRow = addNewStockTotal;
    newService.deleteRow = deleteStockTotal;
    newService.updateField = updateField;
    return newService;
  }, [addNewStockTotal, deleteStockTotal, updateField]);

  const typeToLabel = useMemo(
    () => ({
      new_registration: text('table.type.new_registration'),
      purchase: text('table.type.purchase'),
      new_emission: text('table.type.new_emission'),
      other: text('table.type.other'),
    }),
    [text]
  );

  const columns = useMemo(
    () => [
      {
        id: 'type',
        label: text('table.type'),
      },
      {
        id: 'date',
        label: text('table.date'),
      },
      { id: 'stockChange', label: text('table.shareChange') },
      { id: 'shareTotal', label: text('table.shareTotal') },
      {
        id: 'comment',
        label: text('table.note'),
      },
    ],
    [text]
  );

  const getStockTotalValue = (totals, i, key) => {
    if (totals?.length) {
      if (key === 'stockChange') {
        return i === totals.length - 1
          ? totals[i].shareTotal
          : totals[i].shareTotal - totals[i + 1].shareTotal;
      }
      return totals[i][key];
    }
    return undefined;
  };

  const rows: AgoyTableRow[] = useMemo(
    () =>
      stockTotals.map((item, index) => {
        const cells = {};

        columns.forEach((col) => {
          cells[col.id] = {
            type: 'string',
            value: '',
          };

          if (col.id === 'stockChange') {
            cells[col.id].type = 'number';
            cells[col.id].value =
              index === stockTotals.length - 1
                ? item.shareTotal
                : item.shareTotal - stockTotals[index + 1].shareTotal;
          } else if (![null, undefined, ''].includes(item[col.id])) {
            cells[col.id].type = col.id === 'shareTotal' ? 'number' : 'string';
            cells[col.id].value =
              col.id === 'type' ? typeToLabel[item[col.id]] : item[col.id];
          }
        });

        return {
          id: `${index}`,
          active: true,
          cells,
        };
      }),
    [stockTotals, columns, typeToLabel]
  );

  return (
    <>
      <ConnectionsTableHeader
        title={text('title', {
          orgName,
          orgNumber,
        })}
        editButtonText={text('editShares')}
        editing={editing}
        onEdit={() => setEditing(true)}
        onCancel={discardChanges}
        onSave={saveChanges}
        saveButtonDisabled={!!errors.date || !!errors.shareTotal}
      />
      <StyledTable
        baseId="connectionToCompany"
        tableId="totalShares"
        canAddRows={!errors.date && !errors.shareTotal}
        canDeleteRows
        editing={editing}
        service={service}
        columns={columns}
        rows={rows}
        renderRow={(row, index) => (
          <TableRow
            baseId="connectionToCompany"
            tableId={row.baseId}
            row={row.row}
            columns={columns}
            editing={editing}
            isLocked={index !== 0}
            canAddRows={!errors.date && !errors.shareTotal}
            canDeleteRows
            renderCell={({ column, cell }) => {
              if (editing && index === 0) {
                const key = `${row.row.id}.${column.id}`;

                if (column.id === 'date') {
                  return (
                    <StyledTableCell key={key}>
                      <StyledDateField
                        editing
                        onChange={(dateValue) => {
                          updateField(`totalShares.${key}`, dateValue);
                          setErrors({
                            ...errors,
                            date: validateDate(
                              formatMessage,
                              dateValue,
                              stockTotals
                            ),
                          });
                        }}
                        value={getStockTotalValue(
                          stockTotals,
                          index,
                          column.id
                        )}
                        error={!!errors.date}
                        helperText={errors.date ? errors.date : ''}
                        shouldDisableDate={(day) => {
                          const initialRegistration =
                            stockTotals.length > 1
                              ? stockTotals[stockTotals.length - 1]
                              : undefined;
                          const dayString = parseFormat(day, 'yyyy-MM-dd');

                          return (
                            (!!initialRegistration &&
                              !!day &&
                              isSameOrBefore(
                                day,
                                parse(initialRegistration?.date)
                              )) ||
                            stockTotals.some(
                              (total) => total.date === dayString
                            )
                          );
                        }}
                      />
                    </StyledTableCell>
                  );
                }

                if (column.id === 'type') {
                  const hasMoreThanOneRow = rows.length > 1;
                  const listItems = hasMoreThanOneRow
                    ? [
                        {
                          label: typeToLabel.new_emission,
                          value: 'new_emission',
                        },
                        {
                          label: typeToLabel.other,
                          value: 'other',
                        },
                      ]
                    : [
                        {
                          label: typeToLabel.new_registration,
                          value: 'new_registration',
                        },
                        {
                          label: typeToLabel.purchase,
                          value: 'purchase',
                        },
                      ];

                  return (
                    <StyledTableCell key={key}>
                      <StyledSelect
                        value={getStockTotalValue(
                          stockTotals,
                          index,
                          column.id
                        )}
                        listItems={listItems}
                        onChange={({ target }) => {
                          updateField(`totalShares.${key}`, target.value);
                          setErrors({
                            ...errors,
                            shareTotal: validateShares(
                              formatMessage,
                              stockTotals[0].shareTotal -
                                (stockTotals[1]?.shareTotal || 0),
                              {
                                ...stockTotals[0],
                                type: target.value as StockRegistrationType,
                              },
                              stockTotals[1]
                            ),
                          });
                        }}
                        editing
                      />
                    </StyledTableCell>
                  );
                }

                if (column.id === 'stockChange') {
                  return (
                    <StyledTableCell
                      key={key}
                      className={errors.shareTotal ? ' error-shareTotal' : ''}
                    >
                      <CurrencyField
                        value={getStockTotalValue(
                          stockTotals,
                          index,
                          column.id
                        )}
                        onValueChange={(newValue) => {
                          const value =
                            newValue && stockTotals[1]
                              ? stockTotals[1].shareTotal + newValue
                              : newValue;
                          updateField(`totalShares.${index}.shareTotal`, value);
                          setErrors({
                            ...errors,
                            shareTotal: validateShares(
                              formatMessage,
                              newValue,
                              stockTotals[0],
                              stockTotals[1]
                            ),
                          });
                        }}
                        Input={NumberInput}
                        formatter={ccyFormat}
                        parser={ccyParse}
                        displayDecimals={0}
                        editingDecimals={0}
                      />
                      {errors.shareTotal && (
                        <FormHelperText error>
                          {errors.shareTotal}
                        </FormHelperText>
                      )}
                    </StyledTableCell>
                  );
                }

                return (
                  <StyledTableCell key={key}>
                    <NonReferenceCell
                      cell={cell}
                      id={`${row.baseId}.${key}`}
                      numberFormatType={StandardNumberFormatType}
                      editing
                      active
                      isLocked={column.id === 'shareTotal'}
                    />
                  </StyledTableCell>
                );
              }
              return null;
            }}
          />
        )}
      />
    </>
  );
};

export default TotalTransactionsTable;

import React, { useContext, useState } from 'react';
import styled from '@emotion/styled';
import { Box, TextField, Typography } from '@material-ui/core';
import DocumentViewServiceContext from '_shared/services/document/DocumentViewServiceContext';
import DeleteButton from '_shared/components/Buttons/DeleteButton';
import EditField from '_shared/components/Inputs/EditField';
import EditingIconButton from '../../UI/EditingIconButton';
import Heading from '../../UI/Heading';
import HeadingForPrint from '../../UI/HeadingForPrint';

interface CustomParagraphProps {
  id: string;
  no: number;
  heading: string;
  text: string;
  print: boolean;
  isPreview?: boolean;
}

const StyledDeleteButton = styled(DeleteButton)`
  margin-right: ${({ theme }) => theme.spacing(2)}px;
`;

const NoteNameField = styled(({ disableUnderline, ...rest }) => (
  <TextField size="small" variant="outlined" {...rest} />
))`
  .MuiInputBase-root {
    font-size: 1rem;
    font-weigth: 400;
    width: 100%;
  }
`;

const Text = styled(Typography)`
  font-size: 1rem;
  font-weigth: 400;
  margin-top: -1rem;
`;

const CustomParagraph = ({
  id,
  no,
  heading,
  text,
  print,
  isPreview,
}: CustomParagraphProps): JSX.Element => {
  const service = useContext(DocumentViewServiceContext);

  const handleHeadingChange = (value: string) => {
    service.updateCellValue(`${id}.heading`, value);
  };
  const handleTextChange = (value: string) => {
    service.updateCellValue(`${id}.text`, value);
  };
  const handleDelete = () => {
    service.deleteRow(id);
  };
  const [editing, setEditing] = useState(false);
  const title = `§${no} ${heading}`;

  const HeadingComponent = !isPreview ? (
    <Heading
      title={
        print || !editing ? (
          title
        ) : (
          <Box display="flex" width="75%">
            <Typography variant="h2" display="inline" color="textSecondary">
              {`§${no}`}
            </Typography>
            {/* This box adjusts the input field to match h2 styles above */}
            <Box ml=".5rem" mt="1.75rem" fontSize="1.75rem">
              <EditField
                value={heading}
                onChange={handleHeadingChange}
                component={NoteNameField}
              />
            </Box>
          </Box>
        )
      }
      controls={
        print ? undefined : (
          <>
            {editing && <StyledDeleteButton onClick={handleDelete} />}
            <EditingIconButton
              value={editing}
              onClick={() => setEditing((value) => !value)}
            />
          </>
        )
      }
    />
  ) : (
    <HeadingForPrint
      title={
        print || !editing ? (
          title
        ) : (
          <Box display="flex" width="75%">
            <Typography variant="h2" display="inline" color="textSecondary">
              {`§${no}`}
            </Typography>
            {/* This box adjusts the input field to match h2 styles above */}
            <Box ml=".5rem" mt="1.75rem" fontSize="1.75rem">
              <EditField
                value={heading}
                onChange={handleHeadingChange}
                component={NoteNameField}
              />
            </Box>
          </Box>
        )
      }
      controls={
        print ? undefined : (
          <>
            {editing && <StyledDeleteButton onClick={handleDelete} />}
            <EditingIconButton
              value={editing}
              onClick={() => setEditing((value) => !value)}
            />
          </>
        )
      }
    />
  );

  return (
    <>
      <Box maxWidth={800}>{HeadingComponent}</Box>
      {print || !editing ? (
        <Text>{text}</Text>
      ) : (
        <EditField
          value={text}
          onChange={handleTextChange}
          component={({ disableUnderline, ...props }) => (
            <TextField
              fullWidth
              multiline
              variant="outlined"
              {...props}
              maxRows={8}
              minRows={8}
            />
          )}
        />
      )}
    </>
  );
};

export default CustomParagraph;

import { ApiReturnType } from 'api-sdk';

export type LegacySpecification =
  ApiReturnType<'getLegacySpecifications'>['accounts'][string][number];

/**
 * Maps a specification from the old user input format to the new API format.
 *
 * @param spec
 * @returns
 */
export const mapToSpecification = (
  spec: Client.LegacySpecification
): LegacySpecification => ({
  id: spec.id,
  account: spec.account,
  reference: spec.reference,
  description: spec.description,
  amount: spec.amount != null ? parseFloat(String(spec.amount)) : null,
});

/**
 * Maps a specification from the new API format to the old user input format.
 *
 * @param spec
 * @returns
 */
export const mapFromSpecification = (
  spec: LegacySpecification
): Client.LegacySpecification => ({
  id: spec.id,
  account: spec.account,
  reference: spec.reference,
  description: spec.description,
  amount: spec.amount !== null ? spec.amount.toFixed(2) : '',
});

import { value } from '@agoy/document';
import { NoteInputFieldSectionNoComment } from '../../../../common';
import { active, field, ixbrlField } from '../../../../common/utils/util';

const getNote79 = (): NoteInputFieldSectionNoComment => {
  return {
    active: false,
    number: value(undefined),
    name: value('Poster och delposter som slagits samman'),
    inputField: ixbrlField(active(field('')), {
      type: 'stringItemType',
      name: 'se-gen-base:NotSammanslagnaPosterDelposterResultatrakning',
      contextRef: 'period0',
    }),
    group: value(undefined),
    section: value(undefined),
    validForReportType: {
      k2: false,
      k3: true,
    },
  };
};

export default getNote79;

import React from 'react';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';
import { Typography } from '@material-ui/core';
import {
  SharesCompanyManagementReport,
  ResultsDisposition as ResultsDispositionType,
  EconomicAssociationManagementReport,
  isActive,
} from '@agoy/annual-report-document';
import { stringValue } from '@agoy/document';
import Cell from '../../../../UI/CommonPrintTable/Cell/Cell';
import { renderResultsDispositionRows } from './util';

const GridTable = styled.div`
  width: 50%;
  display: block;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;

  > span {
    display: block;
    width: inherit;
  }

  > span:first-of-type {
    flex: 1;
  }
`;

interface DisposalSectionProps {
  managementReport:
    | SharesCompanyManagementReport
    | EconomicAssociationManagementReport;
  reportPart: ResultsDispositionType;
  isDigitalSubmission: boolean | null | undefined;
}

const DisposalSection = ({
  managementReport,
  reportPart,
  isDigitalSubmission,
}: DisposalSectionProps): JSX.Element | null => {
  const { formatMessage } = useIntl();

  const toDisposeHeader =
    stringValue(managementReport.resultsDisposition.toDisposeHeader) || '';

  const customTotal =
    stringValue(managementReport.resultsDisposition.customTotal) ||
    formatMessage({
      id: 'sum',
    });

  /**
   * If digital submission to BV, rows added by user are not displayed.
   */
  const rowsToDisplay = renderResultsDispositionRows(
    reportPart.toDispose.rows,
    isDigitalSubmission
  );

  return (
    <>
      {reportPart.toDisposeHeader.active ? (
        <Typography variant="h4">{toDisposeHeader}</Typography>
      ) : null}

      <GridTable>
        {rowsToDisplay.map(
          (row) =>
            isActive(row.active) &&
            row.cells?.label &&
            row.cells.value && (
              <Row key={row.id}>
                <Cell cell={row.cells?.label} />
                <Cell cell={row.cells?.value} />
              </Row>
            )
        )}

        {reportPart.total.active ? (
          <Row>
            <span>
              <Typography variant="inherit">{customTotal}</Typography>
            </span>
            <Cell cell={reportPart.total} />
          </Row>
        ) : null}
      </GridTable>
    </>
  );
};

export default DisposalSection;

import { AgoyTableColumn, AgoyTableRow } from '@agoy/document';
import { StockInventoryTable } from '@agoy/api-sdk-core';
import { ExpandableTableData } from '_shared/components/Table/ExpandableTable';

export type StockInventoryTableRow = {
  item: string;
  articleNumber: string;
  amount: number;
  unit: string;
  valuePerUnit: number;
  total: number;
  additionalColumns: Record<string, string>;
};

export const DEFAULT_DATA: StockInventoryTable = {
  headers: [
    {
      id: 'item',
      label: 'Vara/Produkt', // Item/Product
    },
    {
      id: 'articleNumber',
      label: 'Artiklenr.', // Articles
    },
    {
      id: 'amount',
      label: 'Antal', // Number
    },
    {
      id: 'unit',
      label: 'Enhet', // Unit
    },
    {
      id: 'valuePerUnit',
      label: 'Anskaffningsvärde',
    },
    {
      id: 'total',
      label: 'Totalt', // Total
    },
  ],
  rows: [],
};
export const defaultColumnKeys = DEFAULT_DATA.headers.map((h) => h.id);
export const defaultColumnTypes: Record<string, 'string' | 'number'> = {
  item: 'string',
  articleNumber: 'string',
  amount: 'number',
  unit: 'string',
  valuePerUnit: 'number',
  total: 'number',
};

export type TableService = {
  onTableDataUpdate: (data: ExpandableTableData) => void;
  onDefaultColumnLabelUpdate: (oldColumn: string, newColumn: string) => void;
};

export const emptyTableService: TableService = {
  onTableDataUpdate: () => {},
  onDefaultColumnLabelUpdate: () => {},
};

import { ref, table, value } from '@agoy/document';
import { NoteOneTableSection } from '../../../../common';
import { field, range } from '../../../../common/utils/util';
import {
  sumAccountsUBs,
  sumNotesRowsById,
} from '../../../../common/utils/accountSumsUtil';
import { NoteAccountRanges } from '../types';

export const note41Accounts: NoteAccountRanges[] = [
  {
    rowId: '7',
    ranges: [[2091], [2098, 2099]],
    cellLabel: 'Balanserad vinst i eget kapital',
    action: 'sumAccountsUBs',
  },
];

export const getNote41 = (
  noteLabel: any,
  documentTypeVersion = '1'
): NoteOneTableSection => {
  return {
    active: false,
    number: value(undefined),
    name: value('Uppskjuten skattefordran'),
    table: table(
      'notes.note41.table',
      { id: 'label', label: '' },
      { id: 'reportedValue', label: 'Redovisat värde' },
      { id: 'taxValue', label: 'Skattemässigt värde' },
      { id: 'temporaryDifference', label: 'Temporär skillnad' }
    )
      .addRows((rows) =>
        rows
          .addRow(
            '1',
            noteLabel('Avdragsgilla temporära skillnader'),
            value(undefined), // no acc ranges until id 7
            value(undefined),
            value(undefined)
          )
          .addRow(
            '2',
            value(''),
            value(undefined),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '3',
            value(''),
            value(undefined),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '4',
            value(''),
            value(undefined),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '5',
            value(''),
            value(undefined),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '6',
            noteLabel('Skattemässiga underskottsavdrag'),
            value(undefined),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '7',
            noteLabel('Balanserad vinst i eget kapital'),
            ref(sumAccountsUBs('2091+2098:2099', true)),
            value(undefined), //TODO in AGOY-2337 Formula that checks whether the input is bigger than value above * bolagskatt for year of the report
            value(undefined) // if above is true, warn the user. Similar to bokslut's field Vald återföring av periodiseringsfond från tidigare år
          )
          .addRow(
            '8',
            noteLabel('Andra outnyttjade skatteavdrag'),
            value(undefined), // no more acc ranges until end of note
            value(undefined),
            value(undefined)
          )
          .addRow(
            '9',
            value(''),
            value(undefined),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '10',
            value(''),
            value(undefined),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '11',
            value(''),
            value(undefined),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '12',
            value(''),
            value(undefined),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '13',
            noteLabel('Summering'),
            sumNotesRowsById(41, range(1, 13), 'reportedValue'),
            sumNotesRowsById(41, range(1, 13), 'taxValue'),
            sumNotesRowsById(41, range(1, 13), 'temporaryDifference')
          )
          .addRow(
            '14',
            noteLabel('Utgående redovisat värde uppskjuten skattefordran'),
            value(undefined),
            value(undefined),
            value(undefined)
          )
          .build()
      )
      .newRowTemplate(
        value(''),
        value(undefined),
        value(undefined),
        value(undefined)
      )
      .build(),
    commentLabel: field('Kommentar'),
    comment: field(''),
    group: value(undefined),
    section: value(undefined),
    validForReportType: {
      k2: true,
      k3: documentTypeVersion === '1',
    },
  };
};

import { Cell } from '../Cell';
import { AgoyTableRow, AgoyTableRowGenerator } from './table';

export const generateId = () => `@${Date.now()}`;

const replaceParameters = (
  value: string,
  parameters: Record<string, string>
): string => {
  return Object.entries(parameters).reduce((value, [name, replacement]) => {
    return value.replace(new RegExp(`\\$${name}\\b`, 'g'), replacement);
  }, value);
};

export const applyParameters = (
  cell: Cell,
  parameters: Record<string, string>
): Cell => {
  if (cell.type === 'string' || cell.type === 'label') {
    return { ...cell, value: replaceParameters(cell.value, parameters) };
  }
  if (cell.type === 'ref') {
    return {
      ...cell,
      reference: replaceParameters(cell.reference, parameters),
    };
  }

  return cell;
};

const emptyParameters = {};

export const createNewRow = (
  newRowTemplate: AgoyTableRow | AgoyTableRowGenerator,
  baseId: string,
  rowId?: string,
  cellParameters?: Record<string, string>,
  sortKey?: number
): AgoyTableRow => {
  const id = rowId || generateId();
  if (newRowTemplate) {
    if (typeof newRowTemplate === 'function') {
      return newRowTemplate(id, baseId, cellParameters || emptyParameters);
    }

    const parameters = {
      ...cellParameters,
      id: `${baseId}.${id}`,
    };
    return {
      ...newRowTemplate,
      cells:
        newRowTemplate?.cells &&
        Object.entries(newRowTemplate.cells)
          .map(([column, cell]): [string, Cell] => [
            column,
            applyParameters(cell, parameters),
          ])
          .reduce((obj: Record<string, Cell>, [key, value]: [string, Cell]) => {
            obj[key] = value;
            return obj;
          }, {}),
      id,
      sortKey,
    };
  }
  return {
    active: true,
    id,
  };
};

import { asResultClass, getApiSdk } from 'api-sdk';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { setUiStatus } from 'redux/actions/UI';
import { RootState } from 'redux/reducers';
import { getContext } from 'utils/AgoyAppClient/contextHolder';
import { normalizePersonalNumber } from '@agoy/common';
import { StockTotal, StockTransaction } from '_client-connections/types';
import {
  defaultPerson,
  PersonDetails,
  PrivatePerson,
  ProgramStatus,
  Checklist,
} from '_person/_types/person';
import { addYears, endOfYear, format, startOfYear } from 'date-fns';
import { getFinancialYear } from '_shared/redux/checklist/utils';
import { setCurrentCustomerAction } from 'redux/actions';
import PersonActions from './action-types';

type ThunkResponse = ThunkAction<void, RootState, unknown, Action<string>>;

export const createPerson =
  (personData: PrivatePerson, manager: Member.MemberType): ThunkResponse =>
  async (dispatch) => {
    dispatch(setUiStatus({ fetchingPersons: true }));
    const sdk = getApiSdk(getContext());
    const newPerson = {
      clientPerson: {
        ...personData,
        managerId: manager?.userId || '',
        organisationId: manager?.organisationId,
        logo: '',
      },
      taxAddress: personData.taxAddress,
      postAddress: null,
    };

    try {
      const result = await asResultClass(
        sdk.postClientPerson({ requestBody: newPerson })
      );

      if (result.err) {
        throw Error(result.val.message);
      }

      return result.val.id;
    } finally {
      dispatch(setUiStatus({ fetchingPersons: false }));
    }
  };

const actionPopulatePersons = (persons: PrivatePerson[]) => ({
  type: PersonActions.POPULATE_PERSONS,
  persons,
});

export const actionPopulatePersonsStatuses = (
  personsStatuses: Record<string, ProgramStatus[]>
) => ({
  type: PersonActions.UPDATE_PERSONS_PROGRAM_STATUSES,
  personsStatuses,
});

export const getPersons = (): ThunkResponse => async (dispatch) => {
  const sdk = getApiSdk(getContext());
  dispatch(setUiStatus({ fetchingPersons: true }));
  const result = await asResultClass(sdk.getClientPersons());

  if (result.ok) {
    const persons: PrivatePerson[] = result.val.map((p) => ({
      ...defaultPerson,
      id: p.id,
      firstName: p.firstName,
      lastName: p.lastName,
      contactPhoneNumber: '',
      personNumber: p.personNumber,
      managerId: p.managerId,
    }));

    dispatch(actionPopulatePersons(persons));

    dispatch(setUiStatus({ fetchingPersons: false }));
  } else {
    dispatch(setUiStatus({ fetchingPersons: false }));
    throw Error(result.val.message);
  }
};

export const getPersonsProgramStatuses =
  (clientIds: string[]): ThunkResponse =>
  async (dispatch) => {
    const sdk = getApiSdk(getContext());

    const result = await asResultClass(
      sdk.getProgramStatuses({ clientIds, program: 'TAX_DECLARATION' })
    );

    if (result.ok) {
      const personsStatuses: Record<string, ProgramStatus[]> = {};
      Object.entries(result.val).forEach(([clientId, statuses]) => {
        personsStatuses[clientId] = statuses.map((s) => ({
          createdAt: s.createdAt,
          period: s.period || '',
          program: s.program,
          status: s.status,
          financialYear: s.financialYear || '',
        }));
      });

      dispatch(actionPopulatePersonsStatuses(personsStatuses));
    } else {
      throw Error(result.val.message);
    }
  };

export const actionAddPersonDetails = (person: PersonDetails) => ({
  type: PersonActions.ADD_PERSON,
  person,
});

export const getPerson =
  (personId: string): ThunkResponse =>
  async (dispatch) => {
    try {
      const sdk = getApiSdk(getContext());
      dispatch(setUiStatus({ fetchingPersons: true }));
      const result = await asResultClass(
        sdk.getClientPersonById({ clientid: personId })
      );

      if (result.ok) {
        const person = { ...defaultPerson, ...result.val };
        dispatch(actionAddPersonDetails(person));
        dispatch(getPersonsProgramStatuses([person.id]));
        return person;
      }
      return undefined;
    } finally {
      dispatch(setUiStatus({ fetchingPersons: false }));
    }
  };

export const setCurrentPersonWithYear =
  (personId: string): ThunkResponse =>
  async (dispatch) => {
    const sdk = getApiSdk(getContext());
    const result = await asResultClass(
      sdk.getClientPersonById({ clientid: personId })
    );

    if (result.err) {
      return undefined;
    }

    const person = { ...defaultPerson, ...result.val };
    dispatch(actionAddPersonDetails(person));

    const financialYear = `${format(
      startOfYear(addYears(Date.now(), -1)),
      'yyyyMMdd'
    )}-${format(endOfYear(addYears(Date.now(), -1)), 'yyyyMMdd')}`;

    const financialYearId = getFinancialYear(
      person.rawFinancialYears,
      financialYear
    )?.id;

    // run action to populate redux with current customer since
    // actions in the tax declaration view uses customer (private person in this case) data
    dispatch(
      setCurrentCustomerAction(
        personId,
        financialYear.substring(0, 4),
        financialYear,
        null,
        financialYearId
      )
    );
  };

export const savePerson =
  (personId: string, updatedPerson: PrivatePerson): ThunkResponse =>
  async (dispatch) => {
    const sdk = getApiSdk(getContext());
    if (updatedPerson.taxAddress == null) {
      return;
    }

    const normalizedPersonNumber = normalizePersonalNumber(
      updatedPerson.personNumber
    );

    // prepare the requestBody as is expected by the backend
    const requestBody = {
      clientPerson: {
        firstName: updatedPerson.firstName,
        lastName: updatedPerson.lastName,
        personNumber: normalizedPersonNumber,
        contactEmail: updatedPerson.contactEmail,
        contactPhoneNumber: updatedPerson.contactPhoneNumber,
        managerId: updatedPerson.managerId,
        logo: updatedPerson.logo,
      },
      taxAddress: updatedPerson.taxAddress,
    };

    try {
      dispatch(setUiStatus({ fetchingPersons: true }));
      const request = sdk.updateClientPerson({
        clientid: personId,
        requestBody,
      });

      const result = await asResultClass(request);
      if (result.ok) {
        await dispatch(getPerson(personId));
      }
    } finally {
      dispatch(setUiStatus({ fetchingPersons: false }));
    }
  };

const actionDeletePerson = (personId: string) => ({
  type: PersonActions.DELETE_PERSON,
  personId,
});

export const deletePerson =
  (personId: string): ThunkResponse =>
  async (dispatch) => {
    dispatch(setUiStatus({ fetchingPersons: true }));
    const sdk = getApiSdk(getContext());
    try {
      const result = await asResultClass(
        sdk.deleteClientPersonById({ clientid: personId })
      );

      if (result.err) {
        throw Error(result.val.message);
      }

      dispatch(actionDeletePerson(personId));

      return result.val;
    } finally {
      dispatch(setUiStatus({ fetchingPersons: false }));
    }
  };

export const createConnection =
  (
    personId: string,
    companyId: string,
    stockTransaction?: StockTransaction,
    stockTotal?: StockTotal
  ): ThunkResponse =>
  async (dispatch) => {
    const sdk = getApiSdk(getContext());

    // prepare the requestBody as is expected by the backend
    const requestBody = {
      relation: {
        fromClientId: personId,
        toCompanyId: companyId,
      },
      stockTransaction: stockTransaction
        ? {
            ...stockTransaction,
            comment: stockTransaction.comment || undefined,
          }
        : undefined,
      stockTotal: stockTotal
        ? {
            ...stockTotal,
            clientCompanyId: companyId,
            comment: stockTotal.comment || undefined,
          }
        : undefined,
    };

    try {
      dispatch(setUiStatus({ fetchingPersons: true }));
      const result = await asResultClass(
        sdk.addClientRelation({
          clientid: personId,
          requestBody,
        })
      );

      if (result.ok) {
        await dispatch(getPerson(personId));
      }

      if (result.err) {
        throw Error(result.val.message);
      }
    } finally {
      dispatch(setUiStatus({ fetchingPersons: false }));
    }
  };

export const setPersonChecklists = (
  personId: string,
  checklists: Checklist[]
) => {
  return {
    type: PersonActions.SET_PERSON_CHECKLISTS,
    personId,
    checklists,
  };
};

import React from 'react';
import { useIntl } from 'react-intl';
import styled from '@emotion/styled';
import { Typography, Button } from '@material-ui/core';

import { appHeaderHeight } from 'theme/measurements';
import LoadingPlaceholder from '_shared/components/LoadingPlaceholder';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: calc(100vh - ${appHeaderHeight * 2}px);
`;

type Props = {
  isLoading: boolean;
  handleSelectRedirect: () => void;
};

const Page = ({ isLoading, handleSelectRedirect }: Props) => {
  const { formatMessage } = useIntl();

  if (isLoading) {
    return (
      <Wrapper>
        <LoadingPlaceholder />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Typography variant="h1">
        {formatMessage({ id: 'payment.success' })}
      </Typography>
      <Button
        onClick={() => handleSelectRedirect()}
        variant="contained"
        color="secondary"
        disableElevation
      >
        {formatMessage({ id: 'payment.success.button' })}
      </Button>
    </Wrapper>
  );
};

export default Page;

import { value, ref } from '@agoy/document';
import { NoteOneTableSection, IxbrlCell } from '../../../../common';
import {
  sumAccountsIBs,
  sumNegativeTransactions,
  sumNotesRowsById,
} from '../../../../common/utils/accountSumsUtil';
import { year, previousYear } from '../../../../common/utils/date-util';
import {
  field,
  ixbrlCell,
  ixbrlField,
  range,
  table,
} from '../../../../common/utils/util';
import { NoteAccountRanges } from '../types';

const MONETARY_ITEM_TYPE = 'monetaryItemType';

export const note9Accounts: NoteAccountRanges[] = [
  {
    rowId: '1',
    ranges: [[1080, 1089]],
    cellLabel: 'Ingående anskaffningsvärden',
    action: 'sumAccountsIBs',
  },
  {
    rowId: '3',
    ranges: [[1080, 1089]],
    cellLabel: 'Återbetalda förskott',
    action: 'sumNegativeTransactions',
  },
];

export const getNote9 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null,
  documentTypeVersion = '1'
): NoteOneTableSection => {
  return {
    active: false,
    number: value(undefined),
    name: value('Förskott avseende immateriella anläggningstillgångar'),
    table: table<IxbrlCell>(
      'notes.note9.table',
      { id: 'label', label: '' },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      // 'ingående' = previous period outgoing balance
      .addRows((rows) =>
        rows
          .addRow(
            '1',
            noteLabel('Ingående anskaffningsvärden'),
            ixbrlCell(ref(sumAccountsIBs('1080:1089', false)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForskottImmateriellaAnlaggningstillgangarBetaldaForskott',
              contextRef: 'balans1',
              saldo: 'debit',
            }),
            ixbrlCell(ref(sumAccountsIBs('1080:1089', false, 'previousYear')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForskottImmateriellaAnlaggningstillgangarBetaldaForskott',
              contextRef: 'balans2',
              saldo: 'debit',
            })
          )
          .addRow(
            'advancesPaid',
            noteLabel('Utbetalda förskott'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarForandringarAnskaffningsvardenUtbetaldaForskott',
              contextRef: 'period0',
              standardRubrik:
                'Ökning av anskaffningsvärden i samband med periodens utbetalda förskott avseende immateriella anläggningstillgångar',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarForandringarAnskaffningsvardenUtbetaldaForskott',
              contextRef: 'period1',
              standardRubrik:
                'Ökning av anskaffningsvärden i samband med periodens utbetalda förskott avseende immateriella anläggningstillgångar',
            })
          )
          .addRow(
            'businessAcquisitions',
            noteLabel('Rörelseförvärv'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForskottImmateriellaAnlaggningstillgangarRorelseforvarv',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden för förskott avseende immateriella anläggningstillgångar genom rörelseförvärv',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForskottImmateriellaAnlaggningstillgangarRorelseforvarv',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden för förskott avseende immateriella anläggningstillgångar genom rörelseförvärv',
            })
          )
          .addRow(
            'anskaffningsvardenFusion',
            noteLabel('Fusion'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForskottImmateriellaAnlaggningstillgangarFusion',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden för förskott avseende immateriella anläggningstillgångar genom fusion',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForskottImmateriellaAnlaggningstillgangarFusion',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden för förskott avseende immateriella anläggningstillgångar genom fusion',
            })
          )
          // the order changed, but the old id must remain
          .addRow(
            '3',
            noteLabel('Återbetalda förskott'),
            ixbrlCell(ref(sumNegativeTransactions('1080:1089')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarForandringarAnskaffningsvardenAterbetaldaForskott',
              contextRef: 'period0',
              standardRubrik:
                'Minskning av anskaffningsvärden i samband med periodens återbetalda förskott avseende immateriella anläggningstillgångar',
              negateValue: true,
            }),
            ixbrlCell(
              ref(sumNegativeTransactions('1080:1089', 'previousYear')),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarForandringarAnskaffningsvardenAterbetaldaForskott',
                contextRef: 'period1',
                standardRubrik:
                  'Minskning av anskaffningsvärden i samband med periodens återbetalda förskott avseende immateriella anläggningstillgångar',
                negateValue: true,
              }
            )
          )
          .addRow(
            'anskaffningsvardenWrittenAdvances',
            noteLabel('Bortskrivna förskott'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarForandringarAnskaffningsvardenBortskrivnaForskott',
              contextRef: 'period0',
              standardRubrik:
                'Minskning av anskaffningsvärden i samband med bortskrivning av förskott avseende immateriella anläggningstillgångar',
              negateValue: true,
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarForandringarAnskaffningsvardenBortskrivnaForskott',
              contextRef: 'period1',
              standardRubrik:
                'Minskning av anskaffningsvärden i samband med bortskrivning av förskott avseende immateriella anläggningstillgångar',
              negateValue: true,
            })
          )
          .addRow(
            '2',
            noteLabel('Omklassificeringar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForskottImmateriellaAnlaggningstillgangarOmklassificeringar',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden i samband med omklassificeringar av förskott avseende immateriella anläggningstillgångar',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForskottImmateriellaAnlaggningstillgangarOmklassificeringar',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden i samband med omklassificeringar av förskott avseende immateriella anläggningstillgångar',
            })
          )
          .addRow(
            'anskaffningsvardenConversionDifferences',
            noteLabel('Omräkningsdifferenser'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForskottImmateriellaAnlaggningstillgangarOmrakningsdifferenser',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden i samband med omräkningsdifferenser av förskott avseende immateriella anläggningstillgångar',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForskottImmateriellaAnlaggningstillgangarOmrakningsdifferenser',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden i samband med omräkningsdifferenser av förskott avseende immateriella anläggningstillgångar',
            })
          )
          .addRow(
            'anskaffningsvardenSum',
            noteLabel('Utgående anskaffningsvärden'),
            ixbrlCell(
              sumNotesRowsById(
                9,
                [
                  ...range(1, 4),
                  'advancesPaid',
                  'businessAcquisitions',
                  'anskaffningsvardenFusion',
                  'anskaffningsvardenConversionDifferences',
                ],
                'year'
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:ForskottImmateriellaAnlaggningstillgangarBetaldaForskott',
                contextRef: 'balans0',
                saldo: 'debit',
              }
            ),
            ixbrlCell(
              sumNotesRowsById(
                9,
                [
                  ...range(1, 4),
                  'advancesPaid',
                  'businessAcquisitions',
                  'anskaffningsvardenFusion',
                  'anskaffningsvardenConversionDifferences',
                ],
                'previousYear'
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:ForskottImmateriellaAnlaggningstillgangarBetaldaForskott',
                contextRef: 'balans1',
                saldo: 'debit',
              }
            )
          )
          .addRow(
            'incomingWriteDowns',
            noteLabel('Ingående nedskrivningar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForskottImmateriellaAnlaggningstillgangarNedskrivningar',
              contextRef: 'balans1',
              saldo: 'credit',
              negateValue: true,
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForskottImmateriellaAnlaggningstillgangarNedskrivningar',
              contextRef: 'balans2',
              saldo: 'credit',
              negateValue: true,
            })
          )
          .addRow(
            'nedskrivningarFusion',
            noteLabel('Fusion'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForskottImmateriellaAnlaggningstillgangarForandringarNedskrivningarFusion',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av nedskrivningar för förskott avseende immateriella anläggningstillgångar genom fusion',
              negateValue: true,
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForskottImmateriellaAnlaggningstillgangarForandringarNedskrivningarFusion',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av nedskrivningar för förskott avseende immateriella anläggningstillgångar genom fusion',
              negateValue: true,
            })
          )
          .addRow(
            'nedskrivningarReversal',
            noteLabel('Återförda nedskrivningar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForskottImmateriellaAnlaggningstillgangarForandringarNedskrivningarAterfordaNedskrivningar',
              contextRef: 'period0',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForskottImmateriellaAnlaggningstillgangarForandringarNedskrivningarAterfordaNedskrivningar',
              contextRef: 'period1',
            })
          )
          .addRow(
            'nedskrivningarWrittenAdvances',
            noteLabel('Bortskrivna förskott'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForskottImmateriellaAnlaggningstillgangarForandringarNedskrivningarBortskrivnaForskott',
              contextRef: 'period0',
              standardRubrik:
                'Minskning av nedskrivningar i samband med bortskrivning av förskott avseende immateriella anläggningstillgångar',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForskottImmateriellaAnlaggningstillgangarForandringarNedskrivningarBortskrivnaForskott',
              contextRef: 'period1',
              standardRubrik:
                'Minskning av nedskrivningar i samband med bortskrivning av förskott avseende immateriella anläggningstillgångar',
            })
          )
          .addRow(
            'nedskrivningarReclassifications',
            noteLabel('Omklassificeringar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForskottImmateriellaAnlaggningstillgangarForandringarNedskrivningarOmklassificeringar',
              contextRef: 'period0',
              standardRubrik:
                'Minskning (ökning) av nedskrivningar i samband med omklassificeringar av förskott avseende immateriella anläggningstillgångar',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForskottImmateriellaAnlaggningstillgangarForandringarNedskrivningarOmklassificeringar',
              contextRef: 'period1',
              standardRubrik:
                'Minskning (ökning) av nedskrivningar i samband med omklassificeringar av förskott avseende immateriella anläggningstillgångar',
            })
          )
          .addRow(
            'nedskrivningarYearWriteDowns',
            noteLabel('Årets nedskrivningar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForskottImmateriellaAnlaggningstillgangarForandringarNedskrivningarAretsNedskrivningar',
              contextRef: 'period0',
              negateValue: true,
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForskottImmateriellaAnlaggningstillgangarForandringarNedskrivningarAretsNedskrivningar',
              contextRef: 'period1',
              negateValue: true,
            })
          )
          .addRow(
            'nedskrivningarConversionDifferences',
            noteLabel('Omräkningsdifferenser'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForskottImmateriellaAnlaggningstillgangarForandringarNedskrivningarOmrakningsdifferenser',
              contextRef: 'period0',
              standardRubrik:
                'Minskning (ökning) av nedskrivningar i samband med omräkningsdifferenser av förskott avseende immateriella anläggningstillgångar',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForskottImmateriellaAnlaggningstillgangarForandringarNedskrivningarOmrakningsdifferenser',
              contextRef: 'period1',
              standardRubrik:
                'Minskning (ökning) av nedskrivningar i samband med omräkningsdifferenser av förskott avseende immateriella anläggningstillgångar',
            })
          )
          .addRow(
            'nedskrivningarSum',
            noteLabel('Utgående nedskrivningar'),
            ixbrlCell(
              sumNotesRowsById(
                9,
                [
                  'incomingWriteDowns',
                  'nedskrivningarFusion',
                  'nedskrivningarReversal',
                  'nedskrivningarWrittenAdvances',
                  'nedskrivningarReclassifications',
                  'nedskrivningarYearWriteDowns',
                  'nedskrivningarConversionDifferences',
                ],
                'year'
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:ForskottImmateriellaAnlaggningstillgangarNedskrivningar',
                contextRef: 'balans0',
                saldo: 'credit',
                negateValue: true,
              }
            ),
            ixbrlCell(
              sumNotesRowsById(
                9,
                [
                  'incomingWriteDowns',
                  'nedskrivningarFusion',
                  'nedskrivningarReversal',
                  'nedskrivningarWrittenAdvances',
                  'nedskrivningarReclassifications',
                  'nedskrivningarYearWriteDowns',
                  'nedskrivningarConversionDifferences',
                ],
                'previousYear'
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:ForskottImmateriellaAnlaggningstillgangarNedskrivningar',
                contextRef: 'balans1',
                saldo: 'credit',
                negateValue: true,
              }
            )
          )
          .addRow(
            'totalSum',
            noteLabel('Redovisat värde'),
            ixbrlCell(
              sumNotesRowsById(
                9,
                ['anskaffningsvardenSum', 'nedskrivningarSum'],
                'year'
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:ForskottImmateriellaAnlaggningstillgangar',
                contextRef: 'balans0',
                saldo: 'debit',
              }
            ),
            ixbrlCell(
              sumNotesRowsById(
                9,
                ['anskaffningsvardenSum', 'nedskrivningarSum'],
                'previousYear'
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:ForskottImmateriellaAnlaggningstillgangar',
                contextRef: 'balans1',
                saldo: 'debit',
              }
            )
          )
          .build()
      )
      .newRowTemplate(value(''), value(undefined), value(undefined))
      .build(),
    commentLabel: field('Kommentar'),
    comment: ixbrlField(field(''), {
      type: 'stringItemType',
      name: 'se-gen-base:NotForskottImmateriellaAnlaggningstillgangarKommentar',
      contextRef: 'period0',
    }),
    group: value(undefined),
    section: value(undefined),
    validForReportType: {
      k2: true,
      k3: documentTypeVersion === '1',
    },
  };
};

import React from 'react';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import styled from '@emotion/styled';
import { ButtonBase, Typography as MuiTypography } from '@material-ui/core';

const StyledButton = styled(ButtonBase)`
  margin-left: ${(props) => props.theme.spacing(1)}px;
  text-decoration: underline;
  color: ${(props) => props.theme.palette.secondary.main};

  &:hover {
    color: ${(props) => props.theme.palette.text.primary};
    .arrow {
      color: ${(props) => props.theme.palette.text.primary};
    }
  }
`;

const Typography = styled(MuiTypography)`
  &:hover {
    color: ${(props) => props.theme.palette.text.primary};
  }
`;

type BackButtonProps = {
  onClick: () => void;
  title?: string;
  className?: string;
};

const NavForwardButton = ({
  onClick,
  title,
  className,
}: BackButtonProps): JSX.Element => (
  <StyledButton onClick={onClick} className={className}>
    <Typography variant="subtitle2" color="secondary">
      {title}
    </Typography>
    <ArrowForwardIcon color="secondary" fontSize="small" className="arrow" />
  </StyledButton>
);

export default NavForwardButton;

import { RootState } from 'redux/reducers';
import { ChecklistYearState } from './reducer';

export const selectClientYear = (
  state: RootState,
  clientId: string,
  financialYear: string
): ChecklistYearState | null | undefined =>
  state.checklist.clients[clientId]?.years[financialYear];

export const currentClientYearChecklist =
  (clientId: string, financialYear: string) => (state: RootState) => {
    const clientState = selectClientYear(state, clientId, financialYear);
    return clientState;
  };

export const selectPersonYear = (
  state: RootState,
  personId: string,
  financialYearId: number
): ChecklistYearState | null | undefined =>
  state.checklist.persons[personId]?.years[financialYearId];

export const currentPersonYearChecklist =
  <T>(selector: (personYear: ChecklistYearState) => T, defaultValue?: T) =>
  (state: RootState): T | undefined => {
    const { currentCustomer, currentFinancialYearId } = state.customerView;

    if (currentCustomer && currentFinancialYearId) {
      const personState = selectPersonYear(
        state,
        currentCustomer,
        currentFinancialYearId
      );
      if (personState) {
        return selector(personState);
      }
    }
    return defaultValue;
  };

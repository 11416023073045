import React from 'react';
import { Tooltip, IconButton } from '@material-ui/core';
import { Print } from '@material-ui/icons';

interface PrintButtonProps {
  onClick: () => void;
  tooltip?: string;
  disabled?: boolean;
}

const PrintButton = ({
  onClick,
  tooltip = '',
  disabled = false,
}: PrintButtonProps): JSX.Element => {
  return (
    <Tooltip title={tooltip}>
      <IconButton onClick={onClick} disabled={disabled}>
        <Print />
      </IconButton>
    </Tooltip>
  );
};

export default PrintButton;

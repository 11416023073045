const helpStructureINK4 = {
  type: 'part' as const,
  _type: 'INK4' as const,
  children: {
    preliminaryTaxPerMonth: { type: 'field' as const },
    months: { type: 'field' as const },
  },
};

export default helpStructureINK4;

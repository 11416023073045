import styled from '@emotion/styled';
import shouldForwardProp from '@emotion/is-prop-valid';

const Table = styled.table`
  width: 100%;
  display: table;
  table-layout: fixed;
  border-spacing: 0;
  border-collapse: collapse;
  border: none;
  font-size: 8px;
  line-height: 12px;

  &.is-print {
    font-size: 1rem;
    line-height: 1.2rem;
  }

  thead {
    position: sticky;
    top: 0px;
    z-index: 1;

    display: table-header-group;

    font-weight: 700;
    white-space: nowrap;
    text-align: right;
  }
`;

const TableHeadCell = styled('th', {
  shouldForwardProp,
})<{ cellWidth?: number }>`
  display: table-cell;
  vertical-align: bottom;
  width: ${({ cellWidth }) => (cellWidth ? `${cellWidth}px` : 'auto')};
  font-size: inherit;
  line-height: inherit;

  &:first-of-type {
    text-align: left;
  }
`;

const TableBody = styled('tbody')`
  display: table-row-group;

  font-weight: 300;
`;

const BaseRow = styled('tr')`
  display: table-row;
  vertical-align: bottom;
  position: relative;
  font-size: inherit;
`;

const TableRow = styled(BaseRow)`
  box-sizing: border-box;
`;

const TableBodyCell = styled('td', {
  shouldForwardProp,
})<{ level?: number }>`
  display: table-cell;
  text-align: left;
  vertical-align: middle;
  width: 200px;
  padding-left: ${({ level, theme }) => theme.spacing(level ? level * 2 : 1)}px;
  font-size: inherit;
  font-weight: 700;
  line-height: inherit;

  &.regular-row {
    font-weight: 400;
  }

  &.header,
  &.sum,
  &.accountsNotAssigned {
    font-weight: 700;
  }

  &.italic {
    font-style: italic;
  }

  .notes,
  .error {
    font-size: inherit;
  }
`;

export { Table, TableHeadCell, TableBody, BaseRow, TableRow, TableBodyCell };

import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import cloneDeep from 'lodash-es/cloneDeep';
import {
  Grid,
  Switch,
  Avatar,
  Typography,
  TextField,
  InputAdornment,
  FormGroup,
  FormControlLabel,
  Button,
  FormControl,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip,
  Tab,
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import styled from '@emotion/styled';

import CustomersReducer from '_clients/redux/customers';
import { GenericModal } from '_shared/components/Modal';
import DeleteButtonWithDialog from '_shared/components/Buttons/DeleteButtonWithDialog';
import { ClientCompanyType } from '_clients/types/types';
import { DefaultContainer } from '_organization/components/organisms/Container';
import { ClientAccessListItem } from '_organization/components/organisms/UserAccessListItem';
import PersonAccessView from '_person/components/PersonAccessView';
import { PrivatePerson } from '_person/_types/person';
import { CardTabs } from './CardTabs';
import CardTabPanel from './CardTabPanel';

const Container = styled(DefaultContainer)`
  height: 100%;
  width: 85vw;
`;

const GridWrapper = styled(Grid)`
  padding: ${(props) => props.theme.spacing(2)}px;
  height: 100%;
`;

const MemberAvatar = styled(Avatar)`
  width: ${(props) => props.theme.spacing(20)}px;
  height: ${(props) => props.theme.spacing(20)}px;
`;

const SearchAndFilterSection = styled(Grid)`
  margin: ${(props) => props.theme.spacing(2)}px 0;
`;

const ClientsSection = styled(Grid)`
  padding: 0 ${(props) => props.theme.spacing(1)}px;
`;

const StyledRoleSelector = styled(FormControl)`
  margin-left: ${(props) => props.theme.spacing(2)}px;
  .MuiOutlinedInput-root {
    height: ${(props) => props.theme.spacing(4.5)}px;
  }
`;

// Client helpers

export interface Clients {
  [clientId: string]: ClientCompanyType & ClientAuthAttributes;
}

interface ClientAuthAttributes {
  isAuthorised?: boolean;
  isAuthorisedEditedValue: boolean;
  isVisible?: boolean;
}

/**
 * Construct the client list to be used in the popup state.
 * The authorisation for a given member over each client is also computed.
 * @param adminClients clients visible to the administrator
 * @param memberAuthorisedClients clients authorised to the selected member
 */
export const calculateAuthorisedClients = (
  adminClients: ReturnType<typeof CustomersReducer> = {},
  memberAuthorisedClients: any = []
): Clients => {
  const authorisedClients = {};

  if (
    !adminClients ||
    !memberAuthorisedClients ||
    memberAuthorisedClients.length === 0
  )
    return authorisedClients;

  const adminClientIds = Object.keys(adminClients);
  const memberAuthorisedClientIds = memberAuthorisedClients.map(
    (client) => client.id
  );

  if (adminClientIds.length) {
    adminClientIds.forEach((adminClientId) => {
      const adminClient = adminClients[adminClientId];

      const isMemberAuthorisedForClient =
        memberAuthorisedClientIds.includes(adminClientId);

      authorisedClients[adminClientId] = {
        ...adminClient,
        isAuthorised: isMemberAuthorisedForClient,
        isAuthorisedEditedValue: isMemberAuthorisedForClient,
        isVisible: true,
      };
    });
  }
  return authorisedClients;
};

export const resetClients = (clients: Clients): Clients => {
  const resetedClients = cloneDeep(clients);

  Object.keys(resetedClients).forEach((clientId) => {
    resetedClients[clientId].isAuthorisedEditedValue =
      resetedClients[clientId].isAuthorised ?? false;
    resetedClients[clientId].isVisible = true;
  });

  return resetedClients;
};

// Filter helpers

interface Filters {
  companyName: string;
  authorisedOnly: boolean;
}

const DEFAULT_FILTERS: Filters = { companyName: '', authorisedOnly: false };

export const filterClients = ({
  clients,
  filters,
}: {
  clients: Clients;
  filters: Filters;
}): Clients => {
  const filteredClients = cloneDeep(clients);

  Object.keys(clients).forEach((clientId) => {
    let isVisible = true;

    if (
      filters.companyName.length > 0 &&
      !filteredClients[clientId].name
        .toLowerCase()
        .includes(filters.companyName.toLowerCase())
    ) {
      isVisible = false;
    }
    if (
      isVisible &&
      filters.authorisedOnly &&
      !filteredClients[clientId].isAuthorisedEditedValue
    ) {
      isVisible = false;
    }

    filteredClients[clientId].isVisible = isVisible;
  });

  return filteredClients;
};

type Persons = {
  [key: string]: PrivatePerson;
};
interface EditMemberProps {
  isAdmin: boolean;
  member: Member.MemberType;
  clients: Clients;
  persons: Persons;
  loggedInUserEmail: string;
  handleClose: () => void;
  handleModifyRole: (newRole: string) => Promise<void>;
  handleDeleteMember: () => Promise<void>;
  handleToggleClientAuthorisation: (clientId: string) => Promise<void>;
  handleTogglePersonAuthorisation: (personId: string) => Promise<void>;
}

const EditMemberModal = ({
  isAdmin,
  member,
  clients,
  persons,
  loggedInUserEmail,
  handleClose,
  handleModifyRole,
  handleDeleteMember,
  handleToggleClientAuthorisation,
  handleTogglePersonAuthorisation,
}: EditMemberProps) => {
  const { formatMessage } = useIntl();

  // STATE
  const [filteredClients, setFilteredClients] = useState(clients);
  const [filters, setFilters] = useState<Filters>(DEFAULT_FILTERS);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [updatedRole, setUpdatedRole] = useState('');
  const [selectedTabId, setSelectedTabId] = useState(0);

  const onFilterChange = (filters: Filters) => {
    setFilters(filters);
    setFilteredClients(filterClients({ clients, filters }));
  };

  const handleConfirmRole = (event) => {
    const updatedRoleChoice = (event.target as HTMLInputElement).value;
    setUpdatedRole(updatedRoleChoice);
    setDialogOpen(true);
  };

  return (
    <GenericModal open handleClose={handleClose}>
      <Container>
        <GridWrapper
          container
          direction="column"
          justifyContent="space-between"
        >
          <Grid item>
            <Grid container item>
              <Grid container item xs={6}>
                <Grid container justifyContent="center" item xs={6}>
                  <MemberAvatar src={member.picture || undefined} />
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h2">
                    {`${member.givenName || ''} ${member.familyName || ''}`}
                  </Typography>
                  <Typography variant="body1">{member.email}</Typography>
                  {member.phoneNumber && (
                    <Typography variant="body1">
                      {member.phoneNumber}
                    </Typography>
                  )}
                </Grid>
              </Grid>
              {isAdmin && (
                <Grid container item xs={6} justifyContent="flex-end">
                  <Grid item>
                    {/* REMOVE MEMBER FROM ORG */}
                    <DeleteButtonWithDialog
                      variant="contained"
                      confirmMessage={formatMessage(
                        { id: 'dashboard.members.delete.confirm' },
                        { email: member.email }
                      )}
                      disabled={loggedInUserEmail === member.email}
                      onClick={handleDeleteMember}
                      color="primary"
                      label={formatMessage({
                        id: 'dashboard.members.removeFromOrg',
                      })}
                    />

                    {/* UPDATE MEMBER'S ROLE */}
                    <Tooltip
                      placement="left-start"
                      title={formatMessage({
                        id: 'dashboard.members.types.roles.tooltip',
                      })}
                    >
                      <StyledRoleSelector
                        variant="outlined"
                        disabled={loggedInUserEmail === member.email}
                      >
                        <Select
                          value={
                            member.roles?.includes('OrganisationAdmin')
                              ? 'OrganisationAdmin'
                              : 'AccountingConsultant'
                          }
                          onChange={handleConfirmRole}
                        >
                          <MenuItem value="OrganisationAdmin">
                            {formatMessage({
                              id: 'dashboard.members.types.administrator',
                            })}
                          </MenuItem>
                          <MenuItem value="AccountingConsultant">
                            {formatMessage({
                              id: 'dashboard.members.types.accountingContractor',
                            })}
                          </MenuItem>
                        </Select>
                      </StyledRoleSelector>
                    </Tooltip>
                    <Dialog
                      disableBackdropClick
                      open={dialogOpen}
                      onClose={handleClose}
                    >
                      <DialogTitle>
                        {formatMessage({
                          id: 'dashboard.members.changeRole.dialog',
                        }).toUpperCase()}
                      </DialogTitle>
                      <DialogContent>
                        {formatMessage(
                          { id: 'dashboard.members.changeRole.confirm' },
                          {
                            givenName: member.givenName,
                            familyName: member.familyName,
                            newRole: formatMessage({
                              id:
                                updatedRole === 'AccountingConsultant'
                                  ? 'dashboard.members.types.accountingContractor'
                                  : 'dashboard.members.types.administrator',
                            }),
                          }
                        )}
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={() => setDialogOpen(false)}
                          color="primary"
                        >
                          {formatMessage({ id: 'cancel' })}
                        </Button>
                        <Button
                          onClick={async () => {
                            setDialogOpen(false);
                            await handleModifyRole(updatedRole);
                          }}
                          color="primary"
                        >
                          Ok
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </Grid>
                </Grid>
              )}
            </Grid>
            <CardTabs
              value={selectedTabId}
              onChange={(_, newId) => setSelectedTabId(newId)}
              indicatorColor="secondary"
              textColor="secondary"
            >
              <Tab
                label={formatMessage({
                  id: 'dashboard.customers.heading',
                })}
              />
              <Tab label="Personer" />
            </CardTabs>
            <Grid item>
              <CardTabPanel name="companies" value={selectedTabId} index={0}>
                <SearchAndFilterSection
                  container
                  item
                  spacing={2}
                  justifyContent="space-between"
                >
                  <Grid item xs={6}>
                    <TextField
                      id="search-auth-members"
                      variant="outlined"
                      size="small"
                      fullWidth
                      onChange={(e) =>
                        onFilterChange({
                          ...filters,
                          companyName: e.target.value,
                        })
                      }
                      placeholder={formatMessage({
                        id: 'dashboard.customers.search.placeHolder',
                      })}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Search />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid container justifyContent="flex-end" item xs={6}>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Switch
                            color="primary"
                            checked={filters.authorisedOnly}
                            onChange={() =>
                              onFilterChange({
                                ...filters,
                                authorisedOnly: !filters.authorisedOnly,
                              })
                            }
                            name="viewAuthorizedClientsToggle"
                          />
                        }
                        label={formatMessage({
                          id: 'dashboard.members.edit.viewAuthorizedToggle',
                        })}
                      />
                    </FormGroup>
                  </Grid>
                </SearchAndFilterSection>
                <ClientsSection container item spacing={2}>
                  {Object.keys(filteredClients)
                    .filter((clientId) => filteredClients[clientId].isVisible)
                    .map((clientId) => (
                      <Grid key={clientId} item xs={4}>
                        <ClientAccessListItem
                          client={clients[clientId]}
                          authorized={clients[clientId].isAuthorisedEditedValue}
                          accessChangeHandler={() =>
                            handleToggleClientAuthorisation(clientId)
                          }
                        />
                      </Grid>
                    ))}
                </ClientsSection>
              </CardTabPanel>
              <CardTabPanel name="persons" value={selectedTabId} index={1}>
                <PersonAccessView
                  persons={persons}
                  member={member}
                  onAuthorisationToggle={handleTogglePersonAuthorisation}
                />
              </CardTabPanel>
            </Grid>
          </Grid>
        </GridWrapper>
      </Container>
    </GenericModal>
  );
};

export default EditMemberModal;

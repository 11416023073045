import { SetSortClientsActions } from './actions';
import {
  TOGGLE_LAMPS_EXPANDED,
  TOGGLE_HIDE_CLEAN_CLIENTS,
  SET_SORT_TYPE,
  SET_SORT_SEARCH,
  SET_ACTIVE_TAGS,
  SET_FILTERS,
  SET_ACTIVE_ASSIGNEES,
} from './action-types';
import { CustomersOverviewState, SortType } from './types';

export const initialCustomersOverviewState: CustomersOverviewState = {
  lampsExpanded: false,
  hideCleanClients: false,
  sortType: 'BY_CLIENT_NAME' as SortType,
  activeClientTags: [],
  activeClientAssignees: [],
  search: '',
  filters: {
    closingPeriodFilter: { quarter: false, year: false, month: false },
    companyTypeFilter: {
      shares: false,
      limited: false,
      limited_partnership: false,
      individual: false,
      economic_association: false,
      non_profit_association: false,
      foundation: false,
      other: false,
    },
    closingMonthFilter: Array.from(Array(12)).reduce(
      (filter, _, index) => ({ ...filter, [index + 1]: false }),
      {}
    ),
    declarationPeriodFilter: {
      1: false,
      2: false,
      3: false,
      4: false,
    },
  },
};

export default (
  state: CustomersOverviewState = initialCustomersOverviewState,
  action: SetSortClientsActions
): CustomersOverviewState => {
  switch (action.type) {
    case TOGGLE_LAMPS_EXPANDED:
      return {
        ...state,
        lampsExpanded: !state.lampsExpanded,
      };
    case TOGGLE_HIDE_CLEAN_CLIENTS:
      return {
        ...state,
        hideCleanClients: !state.hideCleanClients,
      };
    case SET_SORT_TYPE:
      return {
        ...state,
        sortType: action.sortType,
      };
    case SET_SORT_SEARCH:
      return {
        ...state,
        search: action.search,
      };
    case SET_FILTERS:
      return {
        ...state,
        filters: action.filters,
      };
    case SET_ACTIVE_ASSIGNEES:
      return {
        ...state,
        activeClientAssignees: action.activeClientAssignees,
      };
    case SET_ACTIVE_TAGS:
      return {
        ...state,
        activeClientTags: action.activeClientTags,
      };
    default:
      return state;
  }
};

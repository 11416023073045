import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useSelector } from 'redux/reducers';
import LoadingLogo from '_shared/components/LoadingLogo';
import TotalTransactionsTable from '_client-connections/components/TotalTransactionsTable';
import { addClient } from '_clients/redux/customers/actions';
import { ClientCompanyType } from '_clients/types/types';
import { asResultClass, useApiSdk } from 'api-sdk';
import { mapClient } from '_clients/services/mappings';
import { setUiStatus } from 'redux/actions/UI';
import LoadingPlaceholder from '_shared/components/LoadingPlaceholder';

const Container = styled.div`
  flex: 1;
  height: 100%;
  max-width: 1200px;
  width: 75%;
  min-width: 850px;
`;

const CompanySharesView = ({
  client,
}: {
  client: ClientCompanyType;
}): JSX.Element => {
  const dispatch = useDispatch();
  const { getClientById } = useApiSdk();
  const intl = useIntl();

  const isLoading = useSelector((state) => state.ui.updatingConnections);

  useEffect(() => {
    (async () => {
      // Fetch full client to get stock totals
      dispatch(setUiStatus({ updatingConnections: true }));

      const result = await asResultClass(
        getClientById({ clientid: client.id })
      );

      if (result.ok) {
        dispatch(addClient(mapClient(result.val)));
      }

      dispatch(setUiStatus({ updatingConnections: false }));
    })();
  }, []);

  return (
    <Container>
      {isLoading ? (
        <LoadingPlaceholder />
      ) : (
        <TotalTransactionsTable
          onStockUpdate={async () => {
            const result = await asResultClass(
              getClientById({ clientid: client.id })
            );

            if (result.ok) {
              dispatch(addClient(mapClient(result.val)));
            }
          }}
          companyConnection={{
            orgId: client.id,
            orgName: client.name,
            orgNumber: client.orgNumber || '',
            orgType: client.type,
            stockTotals: client.stockTotals,
          }}
        />
      )}
    </Container>
  );
};

export default CompanySharesView;

/* eslint-disable react/prop-types */
import React from 'react';
import styled from '@emotion/styled';
import { Button, Dialog, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import LoadingLogo from '_shared/components/LoadingLogo';
import { useIntl } from 'react-intl';

const DangerButton = styled(Button)<{ loading: boolean }>`
  background-color: ${(props) =>
    props.loading
      ? `${props.theme.palette.grey[100]}`
      : `${props.theme.palette.error.main}`};
  color: ${({ theme }) => theme.palette.error.contrastText};
  margin-top: ${({ theme }) => theme.spacing(4)}px;
`;

const StyledDialog = styled(Dialog)`
  text-align: center;
  .MuiDialog-paper {
    padding: ${({ theme }) => theme.spacing(4)}px;
    width: 390px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }
`;

const CloseButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
`;

const StyledDeleteIcon = styled(DeleteForeverIcon)`
  font-size: 80px;
`;

type Props = {
  personName: string;
  isOpen: boolean;
  isLoading: boolean;
  onConfirm: () => void;
  onClose: () => void;
};

const Title = ({ children }): JSX.Element => (
  <Typography variant="h4">{children}</Typography>
);

const BodyText = ({ children }): JSX.Element => (
  <Typography color="textSecondary">{children}</Typography>
);

const DialogDeletePerson = (props: Props): JSX.Element => {
  const { personName, isOpen, isLoading, onClose, onConfirm } = props;
  const { formatMessage } = useIntl();

  const getText = (key: string, value?: string) => {
    return formatMessage(
      { id: `client.delete.dialog.${key}` },
      { clientName: value }
    );
  };

  return (
    <StyledDialog open={isOpen} onClose={onClose}>
      <CloseButton onClick={onClose}>
        <CloseIcon fontSize="large" />
      </CloseButton>
      <StyledDeleteIcon />
      <Title>{getText('title')}</Title>
      <BodyText>{getText('text', personName)}</BodyText>
      <br />
      <BodyText>{getText('textConfirm', personName)}</BodyText>
      <DangerButton variant="contained" onClick={onConfirm} loading={isLoading}>
        {!isLoading ? getText('button') : <LoadingLogo size="small" />}
      </DangerButton>
    </StyledDialog>
  );
};

export default DialogDeletePerson;

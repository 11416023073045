import React from 'react';
import styled from '@emotion/styled';
import { Box, Button, Typography } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { useIntl } from 'react-intl';
import config from '_shared/services/config';

const Wrapper = styled.div`
  padding-left: ${(props) => props.theme.spacing(3)}px;
  height: 100%;
  overflow-y: auto;
  display: block;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  padding-top: ${(props) => props.theme.spacing(1)}px;
  text-align: center;
`;

const StyledButton = styled(Button)`
  margin: ${(props) => props.theme.spacing(2, 0)};
`;

type Props = {
  onCreate: () => void;
};

const isFortnoxMode = config.whiteLabelUI === 'fortnox';

const CreateFirstPersonView = (props: Props): JSX.Element => {
  const { formatMessage } = useIntl();
  const { onCreate } = props;

  return (
    <Wrapper>
      <Typography component="div">
        <Box fontWeight="fontWeightBold">
          {formatMessage({
            id: 'person.list.noItems',
          })}
        </Box>
      </Typography>
      {!isFortnoxMode && (
        <StyledButton
          startIcon={<AddCircleOutlineIcon />}
          onClick={onCreate}
          color="secondary"
          variant="contained"
        >
          {formatMessage({ id: 'add.first.person' })}
        </StyledButton>
      )}
    </Wrapper>
  );
};

export default CreateFirstPersonView;

import { AgoyTable, AgoyTableRow, RowType } from './table';

const hasNumericValue = (cell: any): cell is { value: number } =>
  'value' in cell && ![null, undefined].includes(cell.value);

const isZeroOrRoundedToZero = (value: number): boolean =>
  value === 0 || Math.round(value) === 0;

const shouldShowRow = (
  row: AgoyTableRow,
  filterTypes?: RowType[],
  showAll = true,
  showZeroValues = true,
  showHidden = false
) => {
  const { cells, rows: subRows, type } = row;

  // controlled by outer prop
  if (!!filterTypes?.length && type && filterTypes.includes(type)) {
    return false;
  }

  // we should not show rows that are not active
  if (
    // There is a case in income statement when depending on the value we show different rows. The others get inactive.
    // for this reason we should not use showAll to display hidden rows.
    !showHidden &&
    (row.active === false ||
      (typeof row.active === 'object' && row.active.value === false))
  )
    return false;

  // in edit mode all rows should be shown
  // in non-edit those that have type alwaysDisplay or alwaysDisplaySum should be shown
  if (
    showAll ||
    // In income statement, Nettoomsattning and its sum should always be displayed
    type?.includes('alwaysDisplay')
  )
    return true;

  if (cells) {
    const cellValues = Object.values(cells);
    // check if this row has notes or non-0 values
    // get all numeric and notes cells
    const numericAndNotesCells = cellValues.filter((cell) =>
      ['number', 'ref', 'refs'].includes(cell.type || '')
    );

    // some cells have notes or values that are not zero
    if (
      numericAndNotesCells.some(
        (cell) =>
          (cell.type === 'refs' && cell.values.length > 0) ||
          (hasNumericValue(cell) &&
            (showZeroValues || !isZeroOrRoundedToZero(cell.value)))
      )
    ) {
      return true;
    }

    // check if this row has all string values, some of which are non empty
    // NOTE: this is a TEMPORAL fix for notes until we figure out a better strategy
    // possible extending generator
    if (
      !numericAndNotesCells.length &&
      cellValues.length > 1 &&
      cellValues.every((cell) => cell.type === 'string')
    ) {
      return cellValues.some((cell) => 'value' in cell && !!cell.value);
    }
  }

  // checked only for group headers and hidden rows (hidden rows are handlked by render)
  // at this point subrows should be filtered, so we're interested in their length
  // account rows should not affect visibility of the row
  if (type && ['header', 'hidden'].includes(type) && subRows?.length) {
    return subRows.filter((subRow) => subRow.type !== 'account').length > 0;
  }

  // not assigned accounts should be visible in non-edit mode when there are non-empty accounts
  if (type === 'accountsNotAssigned' && subRows?.length) {
    return true;
  }

  return false;
};

/**
 * Method accepts Agoy Table and returns Table with rows filtered according to built in logic and additional params
 *
 * @param table Agoy Table with rows
 * @param filterTypes row types that should be excluded from final result (usually to exclude "account" rows)
 * @param showAll show all rows (usually used with edit), does NOT interfere with "filterTypes"
 * @returns
 */
const filterRows = (
  table: AgoyTable,
  filterTypes?: RowType[],
  showAll = true,
  showZeroValues = true,
  showHidden = false
): AgoyTable => {
  const { rows } = table;

  // filter method that recursively goes through all nested levels of rows
  const filter = (tableRows: AgoyTableRow[]): AgoyTableRow[] => {
    // recursively apply filter to children first
    const rowsWithFilteredChildren = tableRows.map((row) =>
      row.rows ? { ...row, rows: filter(row.rows) } : row
    );

    // filter rows on current level
    return rowsWithFilteredChildren.filter((row) =>
      shouldShowRow(row, filterTypes, showAll, showZeroValues, showHidden)
    );
  };

  return {
    ...table,
    rows: filter(rows),
  };
};

export default filterRows;

import {
  DeleteRowChange,
  RowChange,
  TableChange,
  UpdateRowChange,
} from '../changes';
import { AgoyDocumentStructure } from '../document';
import { AgoyTable, AgoyTableRow } from '../table';
import { addItem } from './helpers';
import updateRow from './helpers/updateRow';
import updateTable from './helpers/updateTable';
import updateDocument, { withError } from './helpers/updateDocument';
import { OperationResult, State } from './types';

const deleteRowChange = (
  rows: RowChange[] | undefined,
  id: string
): RowChange[] => {
  if (!rows) {
    return [{ type: 'delete', id }];
  }
  const deleteChange: DeleteRowChange = { type: 'delete', id };
  const changes = rows.filter((r) => r.id === id);
  if (changes.length > 0) {
    if (changes[0].type === 'add') {
      // If the first change is add, then user added the row and we can delete every trace of it.
      return rows.filter((r) => r.id !== id);
    }
    return addItem(
      rows.filter((r) => r.id !== id),
      deleteChange
    );
  }

  return addItem(rows, deleteChange);
};

const deleteTableRow = <T extends AgoyDocumentStructure>(
  structure: T,
  state: State<T>,
  id: string
): OperationResult<T> => {
  return updateDocument(structure, state, id, {
    table: (subId, tableId, props) => {
      if (!subId) {
        return withError(props, 'INVALID_ID');
      }

      const rowId = subId[subId.length - 1];

      const deleteSubRow = (
        row: AgoyTableRow,
        change: UpdateRowChange
      ): [AgoyTableRow, UpdateRowChange] => [
        {
          ...row,
          rows: row.rows?.filter((row) => row.id !== rowId),
        },
        {
          ...change,
          id: row.id,
          rows: deleteRowChange(
            change?.type === 'update' ? change?.rows : undefined,
            rowId
          ),
        },
      ];
      const deleteRow = (
        row: AgoyTable,
        change: TableChange
      ): [AgoyTable, TableChange] => [
        {
          ...row,
          rows: row.rows?.filter((row) => row.id !== rowId),
        },
        {
          ...change,
          rows: deleteRowChange(change?.rows, rowId),
        },
      ];

      if (subId.length > 1) {
        const updater = updateRow(
          subId.slice(0, subId.length - 1),
          deleteSubRow
        );
        const changes = props.changes || { type: 'update' };
        const result = updater(props.node, changes);
        if (Array.isArray(result)) {
          return {
            ...props,
            node: result[0],
            changes: result[1],
          };
        }
        if (result) {
          return withError(props, result);
        }
        return props;
      }
      const result = updateTable(props.node, props.changes, deleteRow);
      if (Array.isArray(result)) {
        return {
          ...props,
          node: result[0],
          changes: result[1],
        };
      }
      if (result) {
        return withError(props, result);
      }
      return props;
    },
  });
};

export default deleteTableRow;

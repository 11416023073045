import { FinancialReportChanges } from '@agoy/financial-report-document';
import { getContext } from 'utils/AgoyAppClient/contextHolder';
import { asResultClass, getApiSdk } from 'api-sdk';

export type FinancialReportStatusResponse = {
  period: string;
  program: string;
  status: string;
  userId: string;
  createdAt: string;
  givenName: string;
  familyName: string;
};

export const storeFinancialReport = async (
  clientId: string,
  periodId: number,
  changes: FinancialReportChanges
): Promise<void> => {
  const sdk = getApiSdk(getContext());
  const response = await asResultClass(
    sdk.updateFinancialReport({
      clientid: clientId,
      periodId,
      requestBody: changes,
    })
  );

  if (response.err) {
    return Promise.reject(response.val);
  }

  return Promise.resolve();
};

import React from 'react';
import styled from '@emotion/styled';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import { ccyFormat } from '@agoy/common';

const TableContainer = styled.div`
  width: 100%;
  background-color: #ffffff;
  padding-bottom: 3px;
`;

const StyledTable = styled(Table)`
  width: 99%;
`;

const StyledTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'print',
})<PrintProps>`
  font-size: ${({ print }) => (print ? '1rem' : '0.6rem')};
`;

const StyledCell = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== 'print',
})<PrintProps>`
  width: 45px;
  max-width: 45px;
  border: 1px solid #d7d7d7;
  text-align: center;
  padding: 0;
`;

type PrintProps = {
  print: boolean;
};

interface CashFlowTableProps {
  periods: string[];
  data: number[];
  print?: boolean;
}

const CashFlowTable = ({
  periods,
  data,
  print = false,
}: CashFlowTableProps): JSX.Element => {
  return (
    <TableContainer>
      <StyledTable size="small" padding="none">
        <TableBody>
          <TableRow>
            <StyledCell
              print={print}
              style={{ width: '53px', maxWidth: '53px', border: 'none' }}
            />
            {periods.map((period) => (
              <StyledCell key={period} print={print}>
                <StyledTypography print={print}>{period}</StyledTypography>
              </StyledCell>
            ))}
          </TableRow>
          <TableRow>
            <StyledCell print={print}>
              <StyledTypography print={print}>Kassaflöde</StyledTypography>
            </StyledCell>
            {data.map((el, i) => (
              <StyledCell key={`${i}.${el}`} print={print}>
                <StyledTypography print={print}>
                  {ccyFormat(el, 0)}
                </StyledTypography>
              </StyledCell>
            ))}
          </TableRow>
        </TableBody>
      </StyledTable>
    </TableContainer>
  );
};

export default CashFlowTable;

import React, { useState, useEffect, useCallback } from 'react';
import { useIntl } from 'react-intl';
import {
  Typography,
  Paper as MuiPaper,
  Grid,
  Button,
  TextField,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import Alert from '@material-ui/lab/Alert';
import styled from '@emotion/styled';

import { isValidPostalCode, formatOrganisationNumber } from '@agoy/common';
import Snackbar from '_shared/components/Snackbar';
import { CTAButton } from '_shared/components/Buttons';
import config from '_shared/services/config';
import Logo from '_shared/components/Logo';
import { UserClientImage } from 'utils/UserClientImage';
import {
  IMAGE_SIZE_LIMIT,
  IMAGE_MAX_WIDTH_LIMIT,
  IMAGE_MAX_HEIGHT_LIMIT,
  IMAGE_MIN_WIDTH_LIMIT,
} from 'contants';
import { useOrganisationService } from '_organization/hooks/userOrganisationService';
import getImageResolution from 'utils/getImageResolution';

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(2)}px;
`;

const Paper = styled(MuiPaper)`
  max-width: 1280px;
  margin: 0 auto;
`;

const ContainerSubTitle = styled(Typography)`
  color: ${(props) => props.theme.palette.text.secondary};
  font-weight: 400;
`;

const Title = styled(Typography)`
  font-weight: 500;
  font-size: 2.5rem;
  margin: 0;
`;

const OrganizationLogo = styled(Logo)`
  width: 8rem;
  height: 8rem;
  margin-right: 1rem;
  background: ${(props) => props.theme.palette.common.white};

  .MuiSvgIcon-root {
    font-size: 4rem;
  }
`;

const UploadOrganizationLogo = styled(UserClientImage)`
  width: 8rem;
  height: 8rem;
  margin-right: 1rem;
  margin: 0;

  .MuiSvgIcon-root {
    font-size: 4rem;
  }
`;

interface InformationFormValues {
  organisationName: string;
  orgNumber: string;
  address: string;
  city: string;
  postalCode: string;
  country?: string;
}

interface InformationFormErrors {
  organisationName?: string;
  orgNumber?: string;
  address?: string;
  city?: string;
  postalCode?: string;
  country?: string;
}

const fortnoxWhiteLabel = config.whiteLabelUI === 'fortnox';

const formErrorMessages = {
  organisationName: 'dashboard.information.validationErrors.name',
  orgNumber: 'orgNr.error',
  address: 'dashboard.information.validationErrors.address',
  city: 'dashboard.information.validationErrors.city',
  postalCode: 'dashboard.information.validationErrors.zipcode',
  country: 'dashboard.information.validationErrors.country',
};

type Props = {
  isAdmin: boolean;
  addLogoUploadErrorNotification: (errorMessage: string) => void;
  removeLogoUploadErrorNotification: () => void;
};

const EditOrganisation = ({
  isAdmin,
  addLogoUploadErrorNotification,
  removeLogoUploadErrorNotification,
}: Props): JSX.Element => {
  const intl = useIntl();

  const {
    isDataLoading,
    isPersisting,
    data: organisation,
    updateOrganisationLogo,
    updateOrganisationInfo,
  } = useOrganisationService();

  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const [submitErrorMessage, setSubmitErrorMessage] = useState('');
  const [changeOrgInfoOpen, setChangeOrgInfoOpen] = useState(false);
  const [newImage, setNewImage] = useState<File | null>(null);
  const [newImagePreview, setNewImagePreview] = useState<string | null>(null);
  const [formErrors, setFormErrors] = useState<InformationFormErrors>({});
  const [formValues, setFormValues] = useState<InformationFormValues>({
    organisationName: '',
    postalCode: '',
    orgNumber: '',
    address: '',
    city: '',
    country: '',
  });

  const {
    organisationName: newOrganisationName,
    postalCode: newPostalCode,
    address: newAddress,
    city: newCity,
    country: newCountry,
  } = formValues;

  const getDefaultFormValues = useCallback((): InformationFormValues => {
    return {
      organisationName: organisation?.name || '',
      postalCode: organisation?.postalCode || '',
      orgNumber: formatOrganisationNumber(
        organisation?.orgNumber ?? '',
        true,
        'ifNeeded'
      ),
      address: organisation?.address || '',
      city: organisation?.city || '',
      country: organisation?.country || '',
    };
  }, [organisation]);

  useEffect(() => {
    setFormValues(getDefaultFormValues());
  }, [getDefaultFormValues]);

  useEffect(() => {
    if (newImage) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setNewImagePreview(reader.result as string);
      };
      reader.readAsDataURL(newImage);
    } else {
      setNewImagePreview('');
    }
  }, [newImage]);

  if (!organisation) {
    return <></>;
  }

  const setFormValue = (field: string, value: string) => {
    setFormValues({ ...formValues, [field]: value });
  };

  const setFormError = (field: string, error: string) => {
    setFormErrors({ ...formErrors, [field]: error });
  };

  const validateField = (field: string) => {
    if (field === 'postalCode' && !isValidPostalCode(formValues[field] || '')) {
      return intl.formatMessage({ id: formErrorMessages[field] });
    }
    if (!formValues[field]) {
      return intl.formatMessage({ id: formErrorMessages[field] });
    }

    return null;
  };

  const validateForm = () => {
    const errors = {};

    Object.keys(formValues).forEach((item: string) => {
      const error = validateField(item);
      if (error) {
        errors[item] = error;
      }
    });

    setFormErrors(errors);

    return !Object.keys(errors).length;
  };

  const handleOnBlur = (field: string) => {
    const error = validateField(field);
    if (error) {
      setFormError(field, error);
    } else {
      setFormError(field, '');
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const payload = {
      name: newOrganisationName,
      address: newAddress,
      postalCode: newPostalCode,
      city: newCity,
      country: newCountry || '',
    };
    try {
      if (fortnoxWhiteLabel) {
        if (newImage) {
          await updateOrganisationLogo(newImage);
          setSubmitSuccess(true);
          setChangeOrgInfoOpen(false);
        }
      } else if (validateForm()) {
        await updateOrganisationInfo(payload);
        if (newImage) {
          await updateOrganisationLogo(newImage);
        }
        setSubmitSuccess(true);
        setChangeOrgInfoOpen(false);
      }
    } catch (error) {
      const errorMessage = intl.formatMessage({ id: 'error' });
      setSubmitErrorMessage(errorMessage); // Generic error message
      setSubmitError(true);
    }
  };

  const handleLogoSelect = async (file: File) => {
    removeLogoUploadErrorNotification();

    if (file) {
      if (file.size > IMAGE_SIZE_LIMIT) {
        addLogoUploadErrorNotification('error.imageSize');
        return;
      }

      try {
        const fileAsDataURL = URL.createObjectURL(file);
        const { width, height } = await getImageResolution(fileAsDataURL);

        if (width < IMAGE_MIN_WIDTH_LIMIT) {
          addLogoUploadErrorNotification('error.imageMinWidth');
          return;
        }

        if (width > IMAGE_MAX_WIDTH_LIMIT || height > IMAGE_MAX_HEIGHT_LIMIT) {
          addLogoUploadErrorNotification('error.imageMaxWidth');
          return;
        }
      } catch (e) {
        addLogoUploadErrorNotification('error.imageonly');
      }

      setNewImage(file);
    }
  };

  const handleCloseSnackbar = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setSubmitSuccess(false);
    setSubmitError(false);
    setSubmitErrorMessage('');
  };

  const handleCancel = () => {
    setChangeOrgInfoOpen(false);
    setFormValues(getDefaultFormValues());
    setFormErrors({});
    setNewImage(null);
  };

  return (
    <>
      <Snackbar open={submitError} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="error">
          {submitErrorMessage}
        </Alert>
      </Snackbar>
      <Snackbar open={submitSuccess} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="success">
          {intl.formatMessage({ id: 'orginfo.updated' })}
        </Alert>
      </Snackbar>

      <Paper elevation={2}>
        {!isDataLoading ? (
          <Wrapper>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid container item alignItems="flex-start" xs={6}>
                  <Grid item xs={12}>
                    <Typography variant="h5">
                      {intl.formatMessage({
                        id: 'dashboard.information.heading',
                      })}
                    </Typography>
                  </Grid>
                  <Grid container alignItems="center" item xs={3}>
                    {changeOrgInfoOpen ? (
                      <UploadOrganizationLogo
                        editing={changeOrgInfoOpen}
                        imageUrl={newImagePreview || organisation.logo}
                        handleChange={handleLogoSelect}
                        sendErrorMessage={(m) => console.error(m)}
                        square
                      />
                    ) : (
                      <OrganizationLogo src={organisation.logo || ''} />
                    )}
                  </Grid>
                  <Grid
                    container
                    direction="column"
                    item
                    xs={changeOrgInfoOpen ? 6 : 9}
                  >
                    {!changeOrgInfoOpen ? (
                      <Title variant="h1">{organisation.name || ''}</Title>
                    ) : (
                      <>
                        <ContainerSubTitle>
                          {intl.formatMessage({
                            id: 'dashboard.information.name',
                          })}
                        </ContainerSubTitle>
                        <TextField
                          id="orginfo-new-name"
                          variant="outlined"
                          size="small"
                          value={newOrganisationName}
                          onChange={(e) => {
                            setFormValue('organisationName', e.target.value);
                          }}
                          error={!!formErrors.organisationName}
                          helperText={formErrors.organisationName}
                          onBlur={() => handleOnBlur('organisationName')}
                          disabled={fortnoxWhiteLabel}
                        />
                      </>
                    )}
                  </Grid>
                </Grid>
                <Grid container direction="row" item xs={6}>
                  <Grid container item spacing={1} xs={8}>
                    <Grid
                      container
                      direction="column"
                      justifyContent="center"
                      item
                      xs={4}
                    >
                      <ContainerSubTitle>
                        {intl.formatMessage({
                          id: 'dashboard.information.orgNumber',
                        })}
                      </ContainerSubTitle>
                    </Grid>
                    <Grid container item alignItems="center" xs={8}>
                      {changeOrgInfoOpen && (
                        <TextField
                          disabled
                          id="orginfo-new-orgnr"
                          variant="outlined"
                          size="small"
                          fullWidth
                          value={formatOrganisationNumber(
                            organisation.orgNumber ?? '',
                            true,
                            'ifNeeded'
                          )}
                        />
                      )}
                      {!changeOrgInfoOpen && (
                        <Typography variant="body1">
                          {formatOrganisationNumber(
                            organisation.orgNumber ?? '',
                            true,
                            'ifNeeded'
                          )}
                        </Typography>
                      )}
                    </Grid>
                    <Grid
                      container
                      direction="column"
                      justifyContent="center"
                      item
                      xs={4}
                    >
                      <ContainerSubTitle>
                        {intl.formatMessage({
                          id: 'dashboard.information.adress',
                        })}
                      </ContainerSubTitle>
                    </Grid>
                    <Grid container item alignItems="center" xs={8}>
                      {changeOrgInfoOpen && (
                        <TextField
                          id="orginfo-new-adress"
                          variant="outlined"
                          size="small"
                          fullWidth
                          value={newAddress}
                          error={!!formErrors.address}
                          helperText={formErrors.address}
                          onChange={(e) => {
                            setFormValue('address', e.target.value);
                          }}
                          onBlur={() => handleOnBlur('address')}
                          disabled={fortnoxWhiteLabel}
                        />
                      )}
                      {!changeOrgInfoOpen && (
                        <Typography variant="body1">
                          {organisation.address || ''}
                        </Typography>
                      )}
                    </Grid>
                    <Grid
                      container
                      direction="column"
                      justifyContent="center"
                      item
                      xs={4}
                    >
                      <ContainerSubTitle>
                        {intl.formatMessage({
                          id: 'dashboard.information.city',
                        })}
                      </ContainerSubTitle>
                    </Grid>
                    <Grid container item alignItems="center" xs={8}>
                      {changeOrgInfoOpen && (
                        <TextField
                          id="orginfo-new-city"
                          variant="outlined"
                          size="small"
                          fullWidth
                          value={newCity}
                          error={!!formErrors.city}
                          helperText={formErrors.city}
                          onChange={(e) => {
                            setFormValue('city', e.target.value);
                          }}
                          onBlur={() => handleOnBlur('city')}
                          disabled={fortnoxWhiteLabel}
                        />
                      )}
                      {!changeOrgInfoOpen && (
                        <Typography variant="body1">
                          {organisation.city || ''}
                        </Typography>
                      )}
                    </Grid>
                    <Grid
                      container
                      direction="column"
                      justifyContent="center"
                      item
                      xs={4}
                    >
                      <ContainerSubTitle>
                        {intl.formatMessage({
                          id: 'dashboard.information.zipcode',
                        })}
                      </ContainerSubTitle>
                    </Grid>
                    <Grid container item alignItems="center" xs={8}>
                      {changeOrgInfoOpen && (
                        <TextField
                          id="orginfo-new-zipcode"
                          variant="outlined"
                          size="small"
                          fullWidth
                          value={newPostalCode}
                          error={!!formErrors.postalCode}
                          helperText={formErrors.postalCode}
                          onChange={(e) => {
                            setFormValue('postalCode', e.target.value);
                          }}
                          onBlur={() => handleOnBlur('postalCode')}
                          disabled={fortnoxWhiteLabel}
                        />
                      )}
                      {!changeOrgInfoOpen && (
                        <Typography variant="body1">
                          {organisation.postalCode || ''}
                        </Typography>
                      )}
                    </Grid>
                    <Grid
                      container
                      direction="column"
                      justifyContent="center"
                      item
                      xs={4}
                    >
                      <ContainerSubTitle>
                        {intl.formatMessage({
                          id: 'dashboard.information.country',
                        })}
                      </ContainerSubTitle>
                    </Grid>
                    <Grid container item alignItems="center" xs={8}>
                      {changeOrgInfoOpen && (
                        <TextField
                          id="orginfo-new-country"
                          variant="outlined"
                          size="small"
                          fullWidth
                          value={newCountry}
                          error={!!formErrors.country}
                          helperText={formErrors.country}
                          onChange={(e) => {
                            setFormValue('country', e.target.value);
                          }}
                          onBlur={() => handleOnBlur('country')}
                          disabled={fortnoxWhiteLabel}
                        />
                      )}
                      {!changeOrgInfoOpen && (
                        <Typography variant="body1">{newCountry}</Typography>
                      )}
                    </Grid>
                  </Grid>

                  {isAdmin && (
                    <Grid
                      container
                      item
                      direction="row"
                      alignItems="flex-end"
                      justifyContent="flex-end"
                      xs={4}
                    >
                      {changeOrgInfoOpen ? (
                        <>
                          <Grid item>
                            <Button color="primary" onClick={handleCancel}>
                              Avbryt
                            </Button>
                          </Grid>
                          <Grid item>
                            <CTAButton
                              disableElevation
                              type="submit"
                              loading={isPersisting}
                            >
                              {intl.formatMessage({ id: 'save' })}
                            </CTAButton>
                          </Grid>
                        </>
                      ) : (
                        <Grid item>
                          <Button
                            color="primary"
                            onClick={() => {
                              setChangeOrgInfoOpen(true);
                            }}
                          >
                            {intl.formatMessage({
                              id: 'dashboard.information.updateInfoButton',
                            })}
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </form>
          </Wrapper>
        ) : (
          <Skeleton
            variant="rect"
            animation="wave"
            width="100%"
            height="200px"
          />
        )}
      </Paper>
    </>
  );
};

export default EditOrganisation;

import {
  AgoyTable,
  Cell,
  id,
  ref,
  sumAllowEmpty,
  table,
  value,
} from '@agoy/document';
import {
  IxbrlCell,
  Note93Tables,
  NoteTable,
  NoteThreeCommentThreeTableSection,
} from '../../../../common';
import { previousYear, year } from '../../../../common/utils/date-util';
import {
  active,
  field,
  ixbrlCell,
  ixbrlField,
  tupleRef,
} from '../../../../common/utils/util';
import { sumAccountsUBs } from '../../../../common/utils/accountSumsUtil';
import { NoteAccountRanges } from '../types';

const MONETARY_ITEM_TYPE = 'monetaryItemType';

export const note93Accounts: NoteAccountRanges[] = [
  {
    rowId: 'rowCurrentTaxCurrentPeriod',
    ranges: [[8910, 8919]],
    cellLabel: 'Aktuell skatt',
    action: 'sumAccountsIBs',
  },
  {
    rowId: 'rowAdjustmentRegardingPreviousYears',
    ranges: [[8920, 8929]],
    cellLabel: 'Justering avseende tidigare år',
    action: 'sumAccountsIBs',
  },
];

const tableStructure: NoteTable<Note93Tables>[] = [
  {
    id: 'table',
    title: 'Skatt på årets resultat',
    rows: [
      {
        id: 'titleRowCurrentTaxAbstract',
        label: 'Aktuell skatt',
        elementName: 'se-gen-base:AktuellSkattAbstract',
        isTitleRow: true,
        contextRef: 'period0',
      },
      {
        id: 'rowCurrentTaxCurrentPeriod',
        label: 'Aktuell skatt',
        elementName: 'se-gen-base:AktuellSkattNuvarandePeriod',
        contextRef: 'period0',
        saldo: 'debit',
        refGenerator: (column) =>
          ref(sumAccountsUBs('8910:8919', false, column)),
      },
      {
        id: 'rowAdjustmentRegardingPreviousYears',
        label: 'Justering avseende tidigare år',
        elementName: 'se-gen-base:AktuellSkattJusteringTidigarePeriod',
        contextRef: 'period0',
        saldo: 'debit',
        refGenerator: (column) =>
          ref(sumAccountsUBs('8920:8929', false, column)),
      },
      {
        id: 'sumTotalCurrentTax',
        label: 'Summa aktuell skatt',
        elementName: 'se-gen-base:AktuellSkatt',
        contextRef: 'period0',
        saldo: 'debit',
        refGenerator: (column) =>
          ref(
            sumAllowEmpty(
              id(`notes.note93.table.rowCurrentTaxCurrentPeriod.${column}`),
              id(
                `notes.note93.table.rowAdjustmentRegardingPreviousYears.${column}`
              )
            )
          ),
      },
      {
        id: 'titleRowDeferredTax',
        label: 'Uppskjuten skatt',
        elementName: 'se-gen-base:UppskjutenSkattAbstract',
        contextRef: 'period0',
        isTitleRow: true,
      },
      {
        id: 'rowChangeInDeferredTaxTemporaryDifferences',
        label: 'Förändring av uppskjuten skatt avseende temporära skillnader',
        elementName: 'se-gen-base:ForandringUppskjutenSkattTemporaraSkillnader',
        contextRef: 'period0',
        saldo: 'debit',
      },
      {
        id: 'rowChangeInDeferredTaxAssetLossDeductions',
        label:
          'Förändring av uppskjuten skattefordran avseende underskottsavdrag',
        elementName:
          'se-gen-base:ForandringUppskjutenSkattefodranUnderskottsavdrag',
        contextRef: 'period0',
        saldo: 'debit',
      },
      {
        id: 'sumTotalDeferredTax',
        label: 'Summa uppskjuten skatt',
        elementName: 'se-gen-base:UppskjutenSkatt',
        contextRef: 'period0',
        saldo: 'debit',
        refGenerator: (column) =>
          ref(
            sumAllowEmpty(
              id(
                `notes.note93.table.rowChangeInDeferredTaxTemporaryDifferences.${column}`
              ),
              id(
                `notes.note93.table.rowChangeInDeferredTaxAssetLossDeductions.${column}`
              )
            )
          ),
      },
      {
        id: 'sum',
        label: 'Summa',
        elementName: 'se-gen-base:SkattAretsResultat',
        contextRef: 'period0',
        saldo: 'debit',
        refGenerator: (column) =>
          ref(
            sumAllowEmpty(
              id(`notes.note93.table.sumTotalCurrentTax.${column}`),
              id(`notes.note93.table.sumTotalDeferredTax.${column}`)
            )
          ),
      },
    ],
  },
  {
    id: 'secondTable',
    title: 'Avstämning av effektiv skatt',
    rows: [
      {
        id: 'rowProfitBeforeTax',
        label: 'Resultat före skatt',
        elementName: 'se-gen-base:ResultatForeSkatt',
        contextRef: 'period0',
        saldo: 'credit',
      },
      {
        id: 'rowApplicableTaxRate',
        label: 'Gällande skattesats (%)',
        elementName: 'se-gen-base:AvstamningEffktivSkattBeraknadSkattesats',
        contextRef: 'period0',
        type: 'pureItemType',
        unitRef: 'procent',
      },
      {
        id: 'rowTaxAccordingToTheApplicableTaxRate',
        label: 'Skatt enligt gällande skattesats',
        elementName:
          'se-gen-base:AvstamningEffktivSkattEnligtGallandeSkattesats',
        contextRef: 'period0',
        saldo: 'debit',
      },
    ],
  },
];

export const getNote93 = (
  noteLabel: (name: string) => Cell,
  financialYear: string,
  financialYears: string[],
  period: string | null
): NoteThreeCommentThreeTableSection => {
  const tables = tableStructure.reduce<
    {
      [key in Note93Tables]: AgoyTable;
    }
  >(
    (tables, currentTable) => ({
      ...tables,
      [currentTable.id]: table<IxbrlCell>(
        `notes.note93.${currentTable.id}`,
        {
          id: 'label',
          label: currentTable.title || '',
        },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        .addRows((rows) => {
          currentTable.rows.forEach((row) => {
            if (row.isTitleRow) {
              rows.addRow(row.id, noteLabel(row.label));
            } else {
              rows.addRow(
                row.id,
                noteLabel(row.label),
                ixbrlCell(
                  !row.refGenerator
                    ? value(undefined)
                    : row.refGenerator('year'),
                  {
                    unitRef: row.unitRef || undefined,
                    type: MONETARY_ITEM_TYPE || row.type,
                    name: row.elementName,
                    contextRef: row.contextRef,
                    saldo: row.saldo,
                  }
                ),
                ixbrlCell(
                  !row.refGenerator
                    ? value(undefined)
                    : row.refGenerator('previousYear'),
                  {
                    unitRef: row.unitRef || undefined,
                    type: MONETARY_ITEM_TYPE || row.type,
                    name: row.elementName,
                    contextRef: 'period1',
                    saldo: row.saldo,
                  }
                )
              );
            }
          });
          return rows.build();
        })
        .build(),
    }),
    {} as { [key in Note93Tables]: AgoyTable }
  );

  return {
    active: false,
    number: value(undefined),
    name: value('Skatt på årets resultat'),
    ...tables,
    firstTableCommentLabel: field('Kommentar'),
    firstTableComment: ixbrlField(active(field('')), {
      type: 'stringItemType',
      name: 'se-gen-base:SkattPeriodensResultatKommentar',
      contextRef: 'period0',
    }),
    thirdTable: table<IxbrlCell>(
      `notes.note93.thirdTable`,
      {
        id: 'label',
        label: 'Typ av väsentlig post',
      },
      {
        id: 'belopp',
        label: 'Belopp',
      },
      {
        id: 'procent',
        label: 'Procent',
      }
    )
      .newRowTemplateGenerator((id, baseId) => ({
        id,
        active: true,
        cells: {
          label: ixbrlCell(value(''), {
            type: 'stringItemType',
            name: 'se-gen-base:AvstamningEffktivSkattVasentligPostBenamning',
            contextRef: 'period0',
            tuple: {
              name: 'se-gaap-ext:AvstamningEffktivSkattVasentligPostTuple',
              id: `${baseId}.${id}.tuple`,
              tupleID: `${baseId}.${id}.tuple`,
            },
            tupleRef: tupleRef(1, `${baseId}.${id}.tuple`),
          }),
          belopp: ixbrlCell(value(undefined), {
            type: MONETARY_ITEM_TYPE,
            name: 'se-gen-base:AvstamningEffktivSkattVasentligPost',
            contextRef: 'period0',
            tupleRef: tupleRef(2, `${baseId}.${id}.tuple`),
          }),
          procent: ixbrlCell(value(undefined), {
            type: 'pureItemType',
            unitRef: 'procent',
            name: 'se-gen-base:AvstamningEffktivSkattVasentligPostSkatteeffekt',
            contextRef: 'period0',
            tupleRef: tupleRef(3, `${baseId}.${id}.tuple`),
          }),
        },
      }))
      .addRows((rows) =>
        rows
          .addRow(
            'ReportedEffectiveTax',
            noteLabel('Redovisad effektiv skatt'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AvstamningEffktivSkattBeraknadSkattesats',
              contextRef: 'period0',
              standardRubrik: 'Redovisad effektiv skatt',
              saldo: 'debit',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AvstamningEffktivSkattBeraknadSkattesats',
              contextRef: 'period1',
              standardRubrik: 'Redovisad effektiv skatt',
              saldo: 'debit',
            })
          )
          .build()
      )
      .build(),
    thirdTableCommentLabel: field('Kommentar'),
    thirdTableComment: ixbrlField(active(field('')), {
      type: 'stringItemType',
      name: 'se-gen-base:AvstamningEffktivSkattKommentar',
      contextRef: 'period0',
    }),
    commentLabel: field('Kommentar till not'),
    comment: ixbrlField(active(field('')), {
      type: 'stringItemType',
      name: 'se-gen-base:NotSkattPeriodensResultatKommentar',
      contextRef: 'period0',
    }),
    group: value(undefined),
    section: value(undefined),
    validForReportType: {
      k2: false,
      k3: true,
    },
  };
};

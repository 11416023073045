import { Cell } from '@agoy/document';
import {
  ClientInformationDirector,
  ClientInformationAuditFirm,
  ClientInformation,
} from '@agoy/api-sdk-core';

const ROLES = {
  auditors: [
    'auditor',
    'chief_auditor',
    'deputy_auditor',
    'lay_auditor',
    'deputy_lay_auditor',
    'chartered_accountant',
    'approved_auditor',
  ],
  boardMembers: [
    'ceo',
    'external_ceo',
    'external_deputy_ceo',
    'chairman',
    'vice_chairman',
    'board_member',
    'board_deputy',
    'deputy_ceo',
  ],
};

type Row = {
  name: string;
  personNr: string;
  typeOfNumber: string;
  role: string;
  supplement?: string;
};

type ClientInformationTableRow = {
  id: string;
  cells: {
    name: Cell;
    personNr: Cell;
    role: Cell;
  };
};

export type ClientInformationTables = {
  auditorsRows: ClientInformationTableRow[];
  auditFirmRows: ClientInformationTableRow[];
  boardMembersRows: ClientInformationTableRow[];
};

export const mapDirectors = (
  clientInformationDirectorsValue:
    | ClientInformationDirector[]
    | ClientInformationAuditFirm[]
    | undefined,
  roles = 'boardMembers',
  isAuditFirm?: boolean
): Row[] => {
  if (isAuditFirm) {
    return clientInformationDirectorsValue
      ? Object.values(clientInformationDirectorsValue).map((director) => ({
          name: `${director.companyName}`,
          personNr: director.orgNumber,
          role: 'auditFirm',
          typeOfNumber: 'orgNumber',
          supplement: director.responsibleAuditor || '',
        }))
      : [];
  }
  return clientInformationDirectorsValue
    ? Object.values(clientInformationDirectorsValue)
        .filter((director) => ROLES[roles].includes(director.role))
        .map((director) => ({
          name: `${director.firstName} ${director.lastName}`,
          personNr: director.personNr,
          role: director.role,
          typeOfNumber: 'personNr',
          supplement: director.responsibleAuditor || '',
        }))
    : [];
};

const createDirectorsRows = (directors: Row[]): ClientInformationTableRow[] => {
  return directors.map((director, index) => {
    const id = `ci${index}`;
    return {
      id,
      cells: {
        name: {
          type: 'string',
          value: director.name,
          tooltip: director.supplement,
        },
        personNr: {
          type: 'string',
          value: director.personNr,
          tooltip: director.typeOfNumber,
        },
        role: { type: 'string', value: director.role },
      },
    };
  });
};

const createClientInformationTables = (
  clientInformation: ClientInformation
): ClientInformationTables => {
  const mappedAuditFirm = mapDirectors(
    clientInformation.auditFirm?.value,
    undefined,
    true
  );
  const mappedAuditors = mapDirectors(
    clientInformation.auditors?.value,
    'auditors'
  );
  const mappedCeoMembers = mapDirectors(
    clientInformation.ceo?.value,
    'boardMembers'
  );
  const mappedDirectorsMembers = mapDirectors(
    clientInformation.directors?.value,
    'boardMembers'
  );

  const auditorsRows = createDirectorsRows([
    ...mappedAuditFirm,
    ...mappedAuditors,
  ]);

  const boardMembersRows = createDirectorsRows([
    ...mappedCeoMembers,
    ...mappedDirectorsMembers,
  ]);

  const auditFirmRows = createDirectorsRows(mappedAuditFirm);

  return {
    auditorsRows,
    auditFirmRows,
    boardMembersRows,
  };
};

export default createClientInformationTables;

import {
  TaxDeclarationFormDefinition,
  TaxDeclarationFormDataConfig,
  PersonMetaData,
} from '../../types';
import { ref, value, id } from '@agoy/document';
import { mapFormId, toInitial, toStructure } from '../forms-util';

const configT2 = (
  form: TaxDeclarationFormDefinition,
  person: PersonMetaData
): TaxDeclarationFormDataConfig => {
  const name = `${person?.firstName || ''} ${person?.lastName || ''}`;
  const T2 = mapFormId(form, 'T2');
  const initial = {
    T2: {
      partType: 'form' as const,
      id: T2,
      fields: {
        datum: value(''),
        numrering_vid_flera_T2: value(undefined),
        namn: value(name),
        personnummer: value(person.personNumber || ''),
        A_versamhet: value(''),
        B_1_inkomster: value(undefined),
        B_2_kontanta_utgifter: value(undefined),
        B_3_forslitningsavdrag: value(undefined),
        B_4_overskott: value(undefined),
        B_5_underskott: value(undefined),
        C_1_overskott: value(undefined),
        C_2_avdrag_underskott: value(undefined),
        C_3_overskott: value(undefined),
        C_year_2016: value(false),
        C_year_2017: value(false),
        C_year_2018: value(false),
        C_year_2019: value(false),
        C_year_2020: value(false),
        D_1_overskott: value(undefined),
        D_2_foregaende_ars_schablonavdrag: value(undefined),
        D_3_patforda_egenavgifter: value(undefined),
        D_4_overskott_underskott: value(undefined),
        D_5_arets_schablonavdrag: value(undefined),
        D_6_resultat_overskott: value(undefined),
        D_7_resultat_underskott: value(undefined),
        E_2016_1_inkomster: value(undefined),
        E_2016_2_utgifter: value(undefined),
        E_2016_3_forslitningsavdrag: value(undefined),
        E_2016_4_underskott: value(undefined),
        E_2017_1_inkomster: value(undefined),
        E_2017_2_utgifter: value(undefined),
        E_2017_3_forslitningsavdrag: value(undefined),
        E_2017_4_underskott: value(undefined),
        E_2018_1_inkomster: value(undefined),
        E_2018_2_utgifter: value(undefined),
        E_2018_3_forslitningsavdrag: value(undefined),
        E_2018_4_underskott: value(undefined),
        E_2019_1_inkomster: value(undefined),
        E_2019_2_utgifter: value(undefined),
        E_2019_3_forslitningsavdrag: value(undefined),
        E_2019_4_underskott: value(undefined),
        E_2020_1_inkomster: value(undefined),
        E_2020_2_utgifter: value(undefined),
        E_2020_3_forslitningsavdrag: value(undefined),
        E_2020_4_underskott: value(undefined),
      },
      derivedFields: {
        D_underskott: ref(id('T2.fields.D_7_resultat_underskott')),
      },
      sru: {
        '7014': ref(id('T2.fields.numrering_vid_flera_T2')),
        '7020': ref(id('T2.fields.A_versamhet')),
        '2201': ref(id('T2.fields.B_1_inkomster')),
        '2202': ref(id('T2.fields.B_2_kontanta_utgifter')),
        '2203': ref(id('T2.fields.B_3_forslitningsavdrag')),
        '2204': ref(id('T2.fields.B_4_overskott')),
        '2205': ref(id('T2.fields.B_5_underskott')),
        '2211': ref(id('T2.fields.C_1_overskott')),
        '2212': ref(id('T2.fields.C_2_avdrag_underskott')),
        '2213': ref(id('T2.fields.C_3_overskott')),
        '2225': ref(id('T2.fields.C_year_2016')),
        '2226': ref(id('T2.fields.C_year_2017')),
        '2227': ref(id('T2.fields.C_year_2018')),
        '2228': ref(id('T2.fields.C_year_2019')),
        '2229': ref(id('T2.fields.C_year_2020')),
        '2230': ref(id('T2.fields.D_1_overskott')),
        '2231': ref(id('T2.fields.D_2_foregaende_ars_schablonavdrag')),
        '2232': ref(id('T2.fields.D_3_patforda_egenavgifter')),
        '2233': ref(id('T2.fields.D_4_overskott_underskott')),
        '2234': ref(id('T2.fields.D_5_arets_schablonavdrag')),
        '2235': ref(id('T2.fields.D_6_resultat_overskott')),
        '2236': ref(id('T2.fields.D_7_resultat_underskott')),
        '2435': ref(id('T2.fields.E_2016_1_inkomster')),
        '2436': ref(id('T2.fields.E_2016_2_utgifter')),
        '2437': ref(id('T2.fields.E_2016_3_forslitningsavdrag')),
        '2438': ref(id('T2.fields.E_2016_4_underskott')),
        '2439': ref(id('T2.fields.E_2017_1_inkomster')),
        '2440': ref(id('T2.fields.E_2017_2_utgifter')),
        '2441': ref(id('T2.fields.E_2017_3_forslitningsavdrag')),
        '2442': ref(id('T2.fields.E_2017_4_underskott')),
        '2443': ref(id('T2.fields.E_2018_1_inkomster')),
        '2444': ref(id('T2.fields.E_2018_2_utgifter')),
        '2445': ref(id('T2.fields.E_2018_3_forslitningsavdrag')),
        '2446': ref(id('T2.fields.E_2018_4_underskott')),
        '2447': ref(id('T2.fields.E_2019_1_inkomster')),
        '2448': ref(id('T2.fields.E_2019_2_utgifter')),
        '2449': ref(id('T2.fields.E_2019_3_forslitningsavdrag')),
        '2450': ref(id('T2.fields.E_2019_4_underskott')),
        '2451': ref(id('T2.fields.E_2020_1_inkomster')),
        '2452': ref(id('T2.fields.E_2020_2_utgifter')),
        '2453': ref(id('T2.fields.E_2020_3_forslitningsavdrag')),
        '2454': ref(id('T2.fields.E_2020_4_underskott')),
      },
    },
  };
  return {
    initial: toInitial(initial, form),
    definition: toStructure(initial, form),
  };
};

export default configT2;

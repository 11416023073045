import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Paper, Tab } from '@material-ui/core';
import styled from '@emotion/styled';

import { ClientCompanyType } from '_clients/types/types';
import { CardTabs } from '_organization/components/organisms/CardTabs';
import CardTabPanel from '_organization/components/organisms/CardTabPanel';
import PersonsListView from '_person/components/PersonsListView';
import ClientCompaniesList from './ClientCompaniesList';

// TODO:
// New logic ClientCompany -> ARRAY(map to new type, filter, sort)
// move to arrayUtils.ts -> sort, filter functions
export const Container = styled.div`
  padding: ${(props) => props.theme.spacing(2)}px;
`;

type Props = {
  isAdmin: boolean;
  clients: { [key: string]: ClientCompanyType };
  handleSelectClient: (clientId: string) => void;
  isLoading: { [key: string]: boolean };
};

const ClientsList = ({
  isAdmin,
  clients,
  handleSelectClient,
  isLoading,
}: Props) => {
  const { formatMessage } = useIntl();

  const [selectedTabClientId, setSelectedTabClientId] = useState(0);
  const text = (key: string) =>
    formatMessage({ id: `dashboard.customers.subHeading.${key}` });

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTabClientId(newValue);
  };

  return (
    <Paper elevation={2}>
      <CardTabs
        value={selectedTabClientId}
        onChange={handleTabChange}
        indicatorColor="secondary"
        textColor="secondary"
      >
        <Tab label={text('company')} />
        <Tab label={text('person')} />
      </CardTabs>
      <CardTabPanel name="companies" value={selectedTabClientId} index={0}>
        <ClientCompaniesList
          isAdmin={isAdmin}
          clients={clients}
          handleSelectClient={handleSelectClient}
          isLoading={isLoading.clients}
        />
      </CardTabPanel>
      <CardTabPanel name="persons" value={selectedTabClientId} index={1}>
        <PersonsListView />
      </CardTabPanel>
    </Paper>
  );
};

export default ClientsList;

import { id, or, ref, sum, table, value } from '@agoy/document';
import { NoteOneTableWithSum } from '../../../../common';
import { sumAccountsUBs } from '../../../../common/utils/accountSumsUtil';
import { year, previousYear } from '../../../../common/utils/date-util';
import { field } from '../../../../common/utils/util';
import { NoteAccountRanges } from '../types';

export const note29Accounts: NoteAccountRanges[] = [
  {
    rowId: '1',
    ranges: [[2320]],
    cellLabel: 'Obligationslån',
    action: 'sumAccountsUBs',
  },
  {
    rowId: '2',
    ranges: [[2321]],
    cellLabel: 'Obligationslån',
    action: 'sumAccountsUBs',
  },
  {
    rowId: '3',
    ranges: [[2322, 2329]],
    cellLabel: 'Obligationslån',
    action: 'sumAccountsUBs',
  },
];

export const getNote29 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null,
  documentTypeVersion = '1'
): NoteOneTableWithSum => {
  return {
    active: false,
    number: value(undefined),
    name: value('Långfristiga konvertibla lån'),
    table: table(
      'notes.note29.table',
      { id: 'morgages', label: 'Lån' },
      { id: 'terms', label: 'Tid och villkor' },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      .addRows((rows) =>
        rows
          .addRow(
            '1',
            value(''),
            value(''),
            ref(sumAccountsUBs('2320', true)),
            ref(sumAccountsUBs('2320', true, 'previousYear'))
          )
          .addRow(
            '2',
            value(''),
            value(''),
            ref(sumAccountsUBs('2321', true)),
            ref(sumAccountsUBs('2321', true, 'previousYear'))
          )
          .addRow(
            '3',
            value(''),
            value(''),
            ref(sumAccountsUBs('2322:2329', true)),
            ref(sumAccountsUBs('2322:2329', true, 'previousYear'))
          )
          .build()
      )
      .newRowTemplate(value(''), value(''), value(undefined), value(undefined))
      .build(),
    sumTable: table(
      'notes.note29.sumTable',
      { id: 'morgages', label: '' },
      { id: 'terms', label: '' },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      .addRows((rows) =>
        rows
          .addRow(
            'sum',
            noteLabel('Summa'),
            value(''),
            ref(or(sum(id('notes.note29.table.*.year')), 0)),
            ref(or(sum(id('notes.note29.table.*.previousYear')), 0))
          )
          .build()
      )
      .build(),
    commentLabel: field('Kommentar'),
    comment: field(''),
    group: value(undefined),
    section: value(undefined),
    validForReportType: {
      k2: true,
      k3: documentTypeVersion === '1',
    },
  };
};

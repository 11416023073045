import React, { useState, useCallback, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { format } from 'date-fns';
import { sv } from 'date-fns/locale';
import { asResultClass, useApiSdk } from 'api-sdk';

import { useSelector } from 'redux/reducers';
import { addGlobalErrorMessage, addGlobalMessage } from 'redux/actions';
import {
  SkatteverketAuthResponse,
  SkatteverketTransaction,
  SkatteverketTransactionResponse,
} from '_clients/redux/customer-view/types';
import View from './View';
import { HiddenRowProps } from '../HiddenRow';
import PeriodDataContext from '../PeriodDataContext';

const EMPTY_ARRAY = [];

const Container = ({
  userData,
  accountNumber,
}: Pick<HiddenRowProps, 'userData' | 'accountNumber'>): JSX.Element => {
  const dispatch = useDispatch();
  const sdk = useApiSdk();

  // STATE
  const [transactions, setTransactions] = useState<SkatteverketTransaction[]>(
    []
  );
  const [lastFetchedDate, setLastFetchedDate] = useState<string>('');
  const [totalResults, setTotalResults] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // SELECTORS
  const { clientId } = useContext(PeriodDataContext);
  const orgNumber = useSelector(
    (state) => state.customers[clientId]?.orgNumber || ''
  );

  // CALLBACKS
  const handleFetchData = useCallback(
    async (startPeriods, endPeriods, offset, limit, contains, event?) => {
      if (isLoading) return;

      try {
        setIsLoading(true);
        const result = await asResultClass(
          sdk.getClientsSkatteverketTransactions({
            clientid: clientId,
            orgNumber,
            fromDate: startPeriods,
            toDate: endPeriods,
            type: event,
            offset,
            contains,
            limit,
          })
        );

        setIsLoading(false);

        if (result.ok) {
          if (result.val.hasOwnProperty('url')) {
            const authResponse = result.val as SkatteverketAuthResponse;
            !!event && window.open(authResponse.url);
          } else {
            const transactionResponse =
              result.val as SkatteverketTransactionResponse;
            if (
              transactionResponse.transactions &&
              transactionResponse.lastUpdateDate
            ) {
              setTotalResults(transactionResponse.totalResults);
              setTransactions(transactionResponse.transactions);
              setLastFetchedDate(
                format(new Date(transactionResponse.lastUpdateDate), 'PPp', {
                  locale: sv,
                })
              );

              if (event === 'hard') {
                // we got the latest data from skatteverket
                dispatch(
                  addGlobalMessage(
                    'success',
                    'hidden.skatteverket.fetch.success'
                  )
                );
              }
            }
          }
        }
      } catch (err) {
        dispatch(addGlobalErrorMessage('hidden.skatteverket.fetch.error'));
      }
    },
    [dispatch, orgNumber, clientId]
  );

  return (
    <View
      accountNumber={accountNumber}
      userData={userData}
      isLoading={isLoading}
      totalResults={totalResults}
      transactions={transactions || EMPTY_ARRAY}
      transactionsFetchDate={lastFetchedDate}
      handleFetchData={handleFetchData}
    />
  );
};

export default Container;

import React, { createContext, useState } from 'react';

interface PrintContext {
  hideMainContent: boolean;
  setHideMainContent: (hidden: boolean) => void;
}

export const PrintContext = createContext<PrintContext>({
  hideMainContent: false,
  setHideMainContent: (_) => {},
});

// Prefered style, not in use yet in this case
const withPrintContext = (Component) => (props) => {
  const [hideMainContent, setHideMainContent] = useState<boolean>(false);

  return (
    <PrintContext.Provider value={{ hideMainContent, setHideMainContent }}>
      <Component {...props} />
    </PrintContext.Provider>
  );
};

// Old provider style, will be removed later on
export const PrintContextProvider = ({ children }) => {
  const [hideMainContent, setHideMainContent] = useState<boolean>(false);

  return (
    <PrintContext.Provider value={{ hideMainContent, setHideMainContent }}>
      {children}
    </PrintContext.Provider>
  );
};

export default withPrintContext;

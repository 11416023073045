import React, { useState, useEffect } from 'react';

import {
  ListItem,
  Checkbox,
  ListItemText as MuiListItemText,
  ListItemSecondaryAction,
  withTheme,
  WithTheme,
} from '@material-ui/core';
import styled from '@emotion/styled';

const ListItemText = styled(MuiListItemText)`
  > .MuiTypography-root {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

interface MenuItemProps {
  part: string;
  title: string;
  active: boolean;
  isFrontPageRow: boolean | undefined;
  isSettingsRow: boolean;
  isActivePart: boolean;
  onClick?: () => void;
  onCheckboxChange: (part: string) => void;
}

interface BallIconProps {
  active: boolean;
}

const BallIcon = withTheme(({ theme, active }: BallIconProps & WithTheme) => (
  <div>
    <svg width="56px" height="36px">
      {!active && <circle r={10} cx="28px" cy="18" fill="#FFF" />}
      <circle
        r={active ? 12 : 8}
        cx="28px"
        cy="18"
        fill={theme?.palette.grey[300]}
      />
      {active && (
        <>
          <circle r={10} fill="#FFF" cx="28px" cy="18" />
          <circle
            r={8}
            fill={theme?.palette.secondary.main}
            cx="28px"
            cy="18"
          />
        </>
      )}
    </svg>
  </div>
));

const MenuItem = ({
  part,
  title,
  active,
  isFrontPageRow,
  isSettingsRow,
  isActivePart,
  onClick,
  onCheckboxChange,
}: MenuItemProps): JSX.Element => {
  const [checkboxState, setCheckboxState] = useState(isActivePart);

  useEffect(() => {
    setCheckboxState(isActivePart);
  }, [isActivePart]);

  const handleCheckbox = () => {
    setCheckboxState(!checkboxState);
    onCheckboxChange(part);
  };

  return (
    <ListItem alignItems="center" onClick={onClick} button disableGutters>
      <BallIcon active={active} />
      <ListItemText primary={title} />
      {!isSettingsRow && (
        <ListItemSecondaryAction>
          <Checkbox
            color="primary"
            size="small"
            disabled={isFrontPageRow}
            checked={checkboxState}
            onChange={handleCheckbox}
          />
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
};

export default MenuItem;

import React, { memo } from 'react';
import styled from '@emotion/styled';
import { stringValue } from '@agoy/document';
import {
  NumberFormatType,
  StandardNumberFormatType,
  ccyFormat,
  getNumericValueFormat,
  getDisplayValue,
} from '@agoy/common';
import { IxbrlCell, isIxbrlCell } from '@agoy/annual-report-document';
import { formatPercentage } from './formatting';
import NumberCell from './NumberCell';
import { IXElement } from '../../../ARKeyToIX';
import * as IX from '../../../xml/IX';
import { FormattingContext } from '@agoy/formatting';

interface NonReferenceCellProps {
  className?: string;
  cell: IxbrlCell;
  numberFormatType?: NumberFormatType;
  id?: string;
  active?: boolean;
  isXBRLRender?: boolean;
}

const StringCell = styled.span`
  word-break: break-word;
`;

const HiddenValue = styled.span`
  display: none;
`;

const getDisplayWithTaggedValue = (display: string | undefined, value: string | undefined, arPart: IxbrlCell) => {
  return (
    <>
      {display}
      <HiddenValue>
        <IX.Header>
          <IX.Hidden>
            <IXElement arPart={arPart}>
              {value}
            </IXElement>
          </IX.Hidden>
        </IX.Header>
      </HiddenValue>
    </>
  );
};

const NonReferenceCell = memo(
  ({
    className,
    cell,
    numberFormatType = StandardNumberFormatType,
    active,
    isXBRLRender = false,
  }: NonReferenceCellProps) => {
    const formattingContext = React.useContext(FormattingContext);

    const { formatNumber } = formattingContext;

    const hasOptionSelectedValue = () => {
      return 'format' in cell && cell.format?.options;
    };

    const getOptionSelectedValue = () => {
      if ('format' in cell && cell.format?.options) {
        const selectedOption = cell.format?.options?.find(
          (o) => o.label === cell.value
        );

        if (selectedOption) {
          return selectedOption.value;
        }
      }

      // no match found
      return stringValue(cell);
    };

    const getV2SelectedOption = () => {
      if (cell.type === 'string' && cell.format?.options) {
        return cell.format.options.find(
          (o) => o.value === cell.value
        );
      }

      return undefined;
    };

    if (cell.type === 'number') {
      // We rely on ixbrl tagging data here to determine the percentage and number of people format
      const unitRef = cell?.ixbrl?.unitRef;

      const ixbrlType = cell?.ixbrl?.type;

      let { type: formatType } = getNumericValueFormat(unitRef);

      // We only need the default precision in preview, without showing decimals
      const { type, precision } = numberFormatType;

      if (type !== 'standard') {
        formatType = type;
      }

      const isPercentage = type === 'percentage' || formatType === 'percentage';

      const numberFormat = isPercentage ? formatPercentage : ccyFormat;

      const displayValue = getDisplayValue(
        ixbrlType,
        cell.value,
        type,
        formatNumber
      );

      return (
        <NumberCell className={className}>
          {!active || cell.value === undefined ? (
            ''
          ) : (
            <>
              {cell.value < 0 && '-'}
              <span>
                <IXElement arPart={cell}>
                  {numberFormat(
                    displayValue ? Math.abs(displayValue) : displayValue,
                    precision
                  )}
                </IXElement>
              </span>
            </>
          )}
        </NumberCell>
      );
    }

    // Commonly used as row title
    if (
      cell.type === 'string' ||
      cell.type === 'label' ||
      cell.type === 'msg'
    ) {
      if (isIxbrlCell(cell)) {
        const v2SelectedOption = getV2SelectedOption();
        if (v2SelectedOption) {
          return (
            <StringCell className={className}>
              {getDisplayWithTaggedValue(v2SelectedOption.label, v2SelectedOption.value, cell)}
            </StringCell>
          );
        }

        return (
          <StringCell className={className}>
            {active ? (
              <>
                {isXBRLRender && hasOptionSelectedValue()
                ? (getDisplayWithTaggedValue(stringValue(cell), getOptionSelectedValue(), cell))
                : (<IXElement arPart={cell}>{stringValue(cell)}</IXElement>)}
              </>
            ) : (
              ''
            )}
          </StringCell>
        );
      }

      return (
        <StringCell className={className}>
          {active ? stringValue(cell) : ''}
        </StringCell>
      );
    }
    return null;
  }
);

export default NonReferenceCell;

import React, { useRef, useState } from 'react';
import styled from '@emotion/styled';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  Checkbox,
  ClickAwayListener,
  Paper,
  Popper,
  PopperPlacementType,
  TextField,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Close from '@material-ui/icons/Close';
import { useIntl } from 'react-intl';
import Button from '../Buttons/Button';
import Typography from '../Typography/Typography';

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  .p {
    padding: 4px 0;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  .Mui-checked {
    color: ${({ theme }) => theme.palette.primary.main};
  }

  .MuiCheckbox-root {
    padding: 0;
  }
`;

const Arrow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledAutocomplete = styled(Autocomplete)`
  width: 400px;
  position: relative;
  right: 100px;
  top: 5px;
  .hideInputSearch {
    top: -20px;
    .MuiAutocomplete-inputRoot {
      visibility: hidden;
    }

    .MuiAutocomplete-input {
      display: none;
    }
  }

  .MuiAutocomplete-popupIndicatorOpen {
    display: none;
  }

  .MuiAutocomplete-inputRoot {
    border-radius: 8px 8px 0px 0px;
    ${(props) =>
      props.open
        ? `border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;`
        : ''}
  }
` as typeof Autocomplete;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 58px;
  padding: 8px 16px;
  background-color: white;
  border-radius: 0px 0px 8px 8px;
  border-top: 1px solid lightgray;
  box-shadow: ${({ theme }) => theme.shadows[3]};
`;

type CustomerPaperPros = {
  internalValue: string[];
  handleAddPersons: (internalValue: string[]) => void;
  labelForApproveButton: string;
};

const CustomPaper = ({
  internalValue,
  handleAddPersons,
  labelForApproveButton,
  ...props
}: CustomerPaperPros) => {
  return (
    <>
      <Paper {...props} />
      <Wrapper>
        <Button
          label={labelForApproveButton}
          onClick={() => handleAddPersons(internalValue)}
          size="large"
        />
      </Wrapper>
    </>
  );
};

const StyledPaper = styled(CustomPaper)`
  margin: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  width: 400px;

  .MuiAutocomplete-listbox {
    padding: 0;

    .MuiAutocomplete-option {
      padding: 0px;
      border-bottom: 1px solid ${({ theme }) => theme.palette.grey[200]};
    }

    .MuiAutocomplete-option[aria-selected='true']:not([data-focus='true']) {
      background-color: transparent;
    }
  }
`;

type Props = {
  options: { value: string; label: string }[];
  placeholder?: string;
  label: string;
  disabled?: boolean;
  startIcon?: React.ReactNode;
  displayEndIcon?: boolean;
  value?: string[];
  className?: string;
  autoCompleteStyle?: {};
  popperPlacement?: PopperPlacementType;
  labelForApproveButton?: string;
  handleAddRow: (value: string[]) => void;
  onValueChange?: (value: string[]) => void;
};

type OptionType = {
  value: string;
  label: string;
};

const AutocompleteMultiSelect = ({
  options = [],
  value = [],
  placeholder = '',
  label,
  disabled = false,
  startIcon,
  displayEndIcon = true,
  className = '',
  labelForApproveButton = 'Fortsätt',
  autoCompleteStyle,
  popperPlacement = 'bottom',
  handleAddRow,
  onValueChange = () => {},
}: Props) => {
  const { formatMessage } = useIntl();
  const anchorRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const handleAddPersons = () => {
    handleAddRow(value);
    setIsOpen(false);
  };

  const renderOption = (v: OptionType) => {
    return (
      <Row>
        <StyledCheckbox color="primary" checked={!!value.includes(v.value)} />{' '}
        <Typography margin="top">{v.label}</Typography>
      </Row>
    );
  };

  const getOptionLabel = (v: OptionType) => {
    return v.label;
  };

  const onChange = (v: OptionType[]) => {
    const formattedValue = v.map((i) => i.value);
    onValueChange(formattedValue);
  };

  const activeOptions = options.filter((item) => value.includes(item.value));

  return (
    <>
      <Button
        label={label}
        variant="outlined"
        onClick={() => setIsOpen(!isOpen)}
        ref={anchorRef}
        endIcon={
          displayEndIcon ? (
            <Arrow>{isOpen ? <Close /> : <ExpandMoreIcon />}</Arrow>
          ) : null
        }
        disabled={disabled}
        startIcon={startIcon}
      />
      <ClickAwayListener onClickAway={() => setIsOpen(false)}>
        <Popper
          open={isOpen}
          anchorEl={anchorRef.current}
          placement={popperPlacement}
          transition
          disablePortal
          style={{ zIndex: 9999 }}
        >
          <StyledAutocomplete
            style={autoCompleteStyle}
            PaperComponent={(props) => (
              <StyledPaper
                {...props}
                internalValue={value}
                handleAddPersons={handleAddPersons}
                labelForApproveButton={labelForApproveButton}
              />
            )}
            value={activeOptions}
            disableClearable
            getOptionLabel={getOptionLabel}
            options={options}
            onChange={(_e, v) => {
              onChange(v);
            }}
            renderOption={renderOption}
            inputValue={inputValue}
            onInputChange={(e, v, reason) => {
              if (reason === 'reset') {
                return;
              }
              setInputValue(v);
            }}
            disableCloseOnSelect
            renderTags={() => null}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={placeholder}
                variant="outlined"
                className={className}
              />
            )}
            size="medium"
            fullWidth
            open={isOpen}
            noOptionsText={formatMessage({ id: 'autocomplete.noResults' })}
            multiple
          />
        </Popper>
      </ClickAwayListener>
    </>
  );
};

export default AutocompleteMultiSelect;

/*
 * This is a GENERATED file, do not change manually!
 * Generated:
 * - from file k3-arsredovisning-2021-10-31-rev20230131 - Noter.csv
 * - Comments: AGOY-6768 (former 70)
 */

import {
  value,
  ref,
  sumAllowNull,
  id,
  multiply,
  AgoyDocumentPart,
} from '@agoy/document';
import { IxbrlCell } from '../../../../../common';
import {
  field,
  ixbrlCell,
  ixbrlField,
  table,
} from '../../../../../common/utils/util';
import { previousYear, year } from '../../../../../common/utils/date-util';
import {
  sumAccountsUBs,
} from '../../../../../common/utils/accountSumsUtil';

const cell = { type: 'field' as const };
const boolean = { type: 'boolean' as const };
const type = { type: 'type' as const };
const number = { type: 'number' as const };

export const getNote2062K3v2 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null
): AgoyDocumentPart<typeof note2062K3v2ContentDefinition> => ({
  active: false,
  number: value(undefined),
  name: noteLabel('Varulager m.m.'),
  data: {
    active: true,
    FordelningVarulagerAbstract: {
      type: 'group',
      active: true,
      level: 0,
      title: field('Fördelning av varulager m.m.'),
      table: table<IxbrlCell>(
        'notes.note62.data.FordelningVarulagerAbstract.table',
        {
          id: 'FordelningVarulagerAbstract',
          label: '',
          dataType: 'text',
          active: true,
        },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) ?? '',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) ?? '',
          dataType: 'numeric',
          active: true,
        }
      )
        .addRows((rows) => {
          rows
            .addRowWithType(
              'FordelningVarulagerAbstract',
              'hidden',
              undefined,
              undefined,
              undefined
            )
            .addSubRows((rows) => {
              rows.addRowWithType(
                'LagerRavarorFornodenheter',
                'sum_accounts',
                noteLabel('Råvaror och förnödenheter'),
                ixbrlCell(ref(sumAccountsUBs('1400:1419', false)), {
                  contextRef: 'balans0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:LagerRavarorFornodenheter',
                  standardRubrik: 'Lager av råvaror och förnödenheter',
                  saldo: 'debit',
                  negateValue: false,
                }),
                ixbrlCell(
                  ref(sumAccountsUBs('1400:1419', false, 'previousYear')),
                  {
                    contextRef: 'balans1',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:LagerRavarorFornodenheter',
                    standardRubrik: 'Lager av råvaror och förnödenheter',
                    saldo: 'debit',
                    negateValue: false,
                  }
                )
              );
              rows.addRowWithType(
                'LagerVarorUnderTillverkning',
                'sum_accounts',
                noteLabel('Varor under tillverkning'),
                ixbrlCell(ref(sumAccountsUBs('1440:1449', false)), {
                  contextRef: 'balans0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:LagerVarorUnderTillverkning',
                  standardRubrik: 'Lager av varor under tillverkning',
                  saldo: 'debit',
                  negateValue: false,
                }),
                ixbrlCell(
                  ref(sumAccountsUBs('1440:1449', false, 'previousYear')),
                  {
                    contextRef: 'balans1',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:LagerVarorUnderTillverkning',
                    standardRubrik: 'Lager av varor under tillverkning',
                    saldo: 'debit',
                    negateValue: false,
                  }
                )
              );
              rows.addRowWithType(
                'LagerFardigaVarorHandelsvaror',
                'sum_accounts',
                noteLabel('Färdiga varor och handelsvaror'),
                ixbrlCell(ref(sumAccountsUBs('1450:1469', false)), {
                  contextRef: 'balans0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:LagerFardigaVarorHandelsvaror',
                  standardRubrik:
                    'Lager av färdiga egentillverkade varor samt varor som har köpts för vidareförsäljning',
                  saldo: 'debit',
                  negateValue: false,
                }),
                ixbrlCell(
                  ref(sumAccountsUBs('1450:1469', false, 'previousYear')),
                  {
                    contextRef: 'balans1',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:LagerFardigaVarorHandelsvaror',
                    standardRubrik:
                      'Lager av färdiga egentillverkade varor samt varor som har köpts för vidareförsäljning',
                    saldo: 'debit',
                    negateValue: false,
                  }
                )
              );
              rows.addRowWithType(
                'OvrigaLagertillgangar',
                'sum_accounts',
                noteLabel('Övriga lagertillgångar'),
                ixbrlCell(ref(sumAccountsUBs('1490:1499', false)), {
                  contextRef: 'balans0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:OvrigaLagertillgangar',
                  standardRubrik: 'Övriga lagertillgångar',
                  saldo: 'debit',
                  negateValue: false,
                }),
                ixbrlCell(
                  ref(sumAccountsUBs('1490:1499', false, 'previousYear')),
                  {
                    contextRef: 'balans1',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:OvrigaLagertillgangar',
                    standardRubrik: 'Övriga lagertillgångar',
                    saldo: 'debit',
                    negateValue: false,
                  }
                )
              );
              rows.addRowWithType(
                'PagaendeArbetenAnnansRakningOmsattningstillgangar',
                'sum_accounts',
                noteLabel('Pågående arbete för annans räkning'),
                ixbrlCell(ref(sumAccountsUBs('1470:1479', false)), {
                  contextRef: 'balans0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:PagaendeArbetenAnnansRakningOmsattningstillgangar',
                  standardRubrik:
                    'Pågående arbete för annans räkning, tillgång',
                  saldo: 'debit',
                  negateValue: false,
                }),
                ixbrlCell(
                  ref(sumAccountsUBs('1470:1479', false, 'previousYear')),
                  {
                    contextRef: 'balans1',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:PagaendeArbetenAnnansRakningOmsattningstillgangar',
                    standardRubrik:
                      'Pågående arbete för annans räkning, tillgång',
                    saldo: 'debit',
                    negateValue: false,
                  }
                )
              );
              rows.addRowWithType(
                'ForskottTillLeverantorer',
                'sum_accounts',
                noteLabel('Förskott till leverantörer'),
                ixbrlCell(ref(sumAccountsUBs('1480:1489', false)), {
                  contextRef: 'balans0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:ForskottTillLeverantorer',
                  standardRubrik: 'Förskott till leverantörer',
                  saldo: 'debit',
                  negateValue: false,
                }),
                ixbrlCell(
                  ref(sumAccountsUBs('1480:1489', false, 'previousYear')),
                  {
                    contextRef: 'balans1',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:ForskottTillLeverantorer',
                    standardRubrik: 'Förskott till leverantörer',
                    saldo: 'debit',
                    negateValue: false,
                  }
                )
              );
              return rows.build();
            });
          rows.addRowWithType(
            'VarulagerMm',
            'sum',
            noteLabel('Summa varulager m.m.'),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note62.data.FordelningVarulagerAbstract.table.FordelningVarulagerAbstract.*.year'
                  )
                )
              ),
              {
                contextRef: 'balans0',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:VarulagerMm',
                standardRubrik: 'Varulager m.m.',
                saldo: 'debit',
                negateValue: false,
              }
            ),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note62.data.FordelningVarulagerAbstract.table.FordelningVarulagerAbstract.*.previousYear'
                  )
                )
              ),
              {
                contextRef: 'balans1',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:VarulagerMm',
                standardRubrik: 'Varulager m.m.',
                saldo: 'debit',
                negateValue: false,
              }
            )
          );
          return rows.build();
        })
        .build(),
      FordelningVarulagerKommentar: {
        type: 'group',
        active: true,
        level: 0,
        visible: ref(
          id('notes.note62.data.FordelningVarulagerAbstract.active')
        ),
        title: field('Kommentar till fördelning av varulager m.m.'),
        value: ixbrlField(field(''), {
          contextRef: 'period0',
          type: 'stringItemType',
          name: 'se-gen-base:FordelningVarulagerKommentar',
          standardRubrik: 'Kommentar till fördelning av varulager m.m.',
        }),
      },
    },
    RantaRaknatInAnskaffningsvarde: {
      type: 'group',
      active: true,
      level: 0,
      title: field('Ränta som räknats in i anskaffningsvärdet för varulager'),
      table: table<IxbrlCell>(
        'notes.note62.data.RantaRaknatInAnskaffningsvarde.table',
        {
          id: 'RantaRaknatInAnskaffningsvarde',
          label: '',
          dataType: 'text',
          active: true,
        },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) ?? '',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) ?? '',
          dataType: 'numeric',
          active: true,
        }
      )
        .addRows((rows) => {
          rows.addRowWithType(
            'RantaRaknatInAnskaffningsvarde',
            'row',
            noteLabel(
              'Ränta som räknats in i anskaffningsvärdet för varulager'
            ),
            ixbrlCell(value(undefined), {
              contextRef: 'balans0',
              type: 'monetaryItemType',
              unitRef: 'SEK',
              name: 'se-gen-base:RantaRaknatInAnskaffningsvarde',
              standardRubrik:
                'Ränta som räknats in i anskaffningsvärdet för varulager',
              saldo: 'debit',
              negateValue: false,
            }),
            ixbrlCell(value(undefined), {
              contextRef: 'balans1',
              type: 'monetaryItemType',
              unitRef: 'SEK',
              name: 'se-gen-base:RantaRaknatInAnskaffningsvarde',
              standardRubrik:
                'Ränta som räknats in i anskaffningsvärdet för varulager',
              saldo: 'debit',
              negateValue: false,
            })
          );
          return rows.build();
        })
        .build(),
    },
    SkillnadsbeloppVarulagerAbstract: {
      type: 'group',
      active: true,
      level: 0,
      title: field('Skillnadsbelopp varulager'),
      table: table<IxbrlCell>(
        'notes.note62.data.SkillnadsbeloppVarulagerAbstract.table',
        {
          id: 'SkillnadsbeloppVarulagerAbstract',
          label: '',
          dataType: 'text',
          active: true,
        },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) ?? '',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) ?? '',
          dataType: 'numeric',
          active: true,
        }
      )
        .addRows((rows) => {
          rows
            .addRowWithType(
              'SkillnadsbeloppVarulagerAbstract',
              'hidden',
              undefined,
              undefined,
              undefined
            )
            .addSubRows((rows) => {
              rows.addRowWithType(
                'VarulagerRedovisatNettoforsaljningsvarde',
                'row',
                noteLabel('Varulager redovisat till nettoförsäljningsvärde'),
                ixbrlCell(value(undefined), {
                  contextRef: 'balans0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:VarulagerRedovisatNettoforsaljningsvarde',
                  standardRubrik:
                    'Varulager redovisat till nettoförsäljningsvärde',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:VarulagerRedovisatNettoforsaljningsvarde',
                  standardRubrik:
                    'Varulager redovisat till nettoförsäljningsvärde',
                  negateValue: false,
                })
              );
              rows.addRowWithType(
                'VarulagerRedovisatAnskaffningsvarde',
                'row',
                noteLabel('Varulager redovisat till anskaffningsvärde'),
                ixbrlCell(value(undefined), {
                  contextRef: 'balans0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:VarulagerRedovisatAnskaffningsvarde',
                  standardRubrik: 'Varulager redovisat till anskaffningsvärde',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:VarulagerRedovisatAnskaffningsvarde',
                  standardRubrik: 'Varulager redovisat till anskaffningsvärde',
                  negateValue: false,
                })
              );
              return rows.build();
            });
          rows.addRowWithType(
            'VarulagerSkillnadsbelopp',
            'sum',
            noteLabel('Skillnadsbelopp'),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note62.data.SkillnadsbeloppVarulagerAbstract.table.SkillnadsbeloppVarulagerAbstract.VarulagerRedovisatNettoforsaljningsvarde.year'
                  ),
                  multiply(
                    -1,
                    id(
                      'notes.note62.data.SkillnadsbeloppVarulagerAbstract.table.SkillnadsbeloppVarulagerAbstract.VarulagerRedovisatAnskaffningsvarde.year'
                    )
                  )
                )
              ),
              {
                contextRef: 'balans0',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:VarulagerSkillnadsbelopp',
                standardRubrik:
                  'Positiv (negativ) skillnad mellan nettoförsäljningsvärde och anskaffningsvärde för varulager',
                negateValue: false,
              }
            ),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note62.data.SkillnadsbeloppVarulagerAbstract.table.SkillnadsbeloppVarulagerAbstract.VarulagerRedovisatNettoforsaljningsvarde.previousYear'
                  ),
                  multiply(
                    -1,
                    id(
                      'notes.note62.data.SkillnadsbeloppVarulagerAbstract.table.SkillnadsbeloppVarulagerAbstract.VarulagerRedovisatAnskaffningsvarde.previousYear'
                    )
                  )
                )
              ),
              {
                contextRef: 'balans1',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:VarulagerSkillnadsbelopp',
                standardRubrik:
                  'Positiv (negativ) skillnad mellan nettoförsäljningsvärde och anskaffningsvärde för varulager',
                negateValue: false,
              }
            )
          );
          rows
            .addRowWithType(
              'VarulagerFordelningSkillnadsbeloppAbstract',
              'header',
              noteLabel('Fördelning av skillnadsbelopp'),
              undefined,
              undefined
            )
            .addSubRows((rows) => {
              rows.addRowWithType(
                'VarulagerSkillnadAnskaffningNettoforsaljningVardeRavarorFornodenheter',
                'row',
                noteLabel('Råvaror och förnödenheter'),
                ixbrlCell(value(undefined), {
                  contextRef: 'balans0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:VarulagerSkillnadAnskaffningNettoforsaljningVardeRavarorFornodenheter',
                  standardRubrik:
                    'Positiv (negativ) skillnad mellan nettoförsäljningsvärde och anskaffningsvärde för råvaror och förnödenheter',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:VarulagerSkillnadAnskaffningNettoforsaljningVardeRavarorFornodenheter',
                  standardRubrik:
                    'Positiv (negativ) skillnad mellan nettoförsäljningsvärde och anskaffningsvärde för råvaror och förnödenheter',
                  negateValue: false,
                })
              );
              rows.addRowWithType(
                'VarulagerSkillnadAnskaffningNettoforsaljningVardeVarorUnderTillverkning',
                'row',
                noteLabel('Varor under tillverkning'),
                ixbrlCell(value(undefined), {
                  contextRef: 'balans0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:VarulagerSkillnadAnskaffningNettoforsaljningVardeVarorUnderTillverkning',
                  standardRubrik:
                    'Positiv (negativ) skillnad mellan nettoförsäljningsvärde och anskaffningsvärde för varor under tillverkning',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:VarulagerSkillnadAnskaffningNettoforsaljningVardeVarorUnderTillverkning',
                  standardRubrik:
                    'Positiv (negativ) skillnad mellan nettoförsäljningsvärde och anskaffningsvärde för varor under tillverkning',
                  negateValue: false,
                })
              );
              rows.addRowWithType(
                'VarulagerSkillnadAnskaffningNettoforsaljningVardeFardigaVarorHandelsvaror',
                'row',
                noteLabel('Färdiga varor och handelsvaror'),
                ixbrlCell(value(undefined), {
                  contextRef: 'balans0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:VarulagerSkillnadAnskaffningNettoforsaljningVardeFardigaVarorHandelsvaror',
                  standardRubrik:
                    'Positiv (negativ) skillnad mellan nettoförsäljningsvärde och anskaffningsvärde för färdiga varor och handelsvaror',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:VarulagerSkillnadAnskaffningNettoforsaljningVardeFardigaVarorHandelsvaror',
                  standardRubrik:
                    'Positiv (negativ) skillnad mellan nettoförsäljningsvärde och anskaffningsvärde för färdiga varor och handelsvaror',
                  negateValue: false,
                })
              );
              rows.addRowWithType(
                'VarulagerSkillnadAnskaffningNettoforsaljningVardeOvrigaLagertillgangar',
                'row',
                noteLabel('Övriga lagertillgångar'),
                ixbrlCell(value(undefined), {
                  contextRef: 'balans0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:VarulagerSkillnadAnskaffningNettoforsaljningVardeOvrigaLagertillgangar',
                  standardRubrik:
                    'Positiv (negativ) skillnad mellan nettoförsäljningsvärde och anskaffningsvärde för övriga lagertillgångar',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:VarulagerSkillnadAnskaffningNettoforsaljningVardeOvrigaLagertillgangar',
                  standardRubrik:
                    'Positiv (negativ) skillnad mellan nettoförsäljningsvärde och anskaffningsvärde för övriga lagertillgångar',
                  negateValue: false,
                })
              );
              rows.addRowWithType(
                'VarulagerSkillnadAnskaffningNettoforsaljningVardePagaendeArbeteAnnansRakning',
                'row',
                noteLabel('Pågående arbete för annans räkning'),
                ixbrlCell(value(undefined), {
                  contextRef: 'balans0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:VarulagerSkillnadAnskaffningNettoforsaljningVardePagaendeArbeteAnnansRakning',
                  standardRubrik:
                    'Positiv (negativ) skillnad mellan nettoförsäljningsvärde och anskaffningsvärde för pågående arbete för annans räkning',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:VarulagerSkillnadAnskaffningNettoforsaljningVardePagaendeArbeteAnnansRakning',
                  standardRubrik:
                    'Positiv (negativ) skillnad mellan nettoförsäljningsvärde och anskaffningsvärde för pågående arbete för annans räkning',
                  negateValue: false,
                })
              );
              rows.addRowWithType(
                'VarulagerSkillnadAnskaffningNettoforsaljningVardeForskottLeverantor',
                'row',
                noteLabel('Förskott till leverantör'),
                ixbrlCell(value(undefined), {
                  contextRef: 'balans0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:VarulagerSkillnadAnskaffningNettoforsaljningVardeForskottLeverantor',
                  standardRubrik:
                    'Positiv (negativ) skillnad mellan nettoförsäljningsvärde och anskaffningsvärde för förskott till leverantör',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:VarulagerSkillnadAnskaffningNettoforsaljningVardeForskottLeverantor',
                  standardRubrik:
                    'Positiv (negativ) skillnad mellan nettoförsäljningsvärde och anskaffningsvärde för förskott till leverantör',
                  negateValue: false,
                })
              );
              return rows.build();
            });
          return rows.build();
        })
        .build(),
      SkillnadsbeloppVarulagerKommentar: {
        type: 'group',
        active: true,
        level: 0,
        visible: ref(
          id('notes.note62.data.SkillnadsbeloppVarulagerAbstract.active')
        ),
        title: field('Kommentar till skillnadsbelopp varulager'),
        value: ixbrlField(field(''), {
          contextRef: 'period0',
          type: 'stringItemType',
          name: 'se-gen-base:SkillnadsbeloppVarulagerKommentar',
          standardRubrik: 'Kommentar till skillnadsbelopp varulager',
        }),
      },
    },
    NotVarulagerKommentar: {
      type: 'group',
      active: true,
      level: 0,
      title: field('Kommentar till specifikation av varulager m.m'),
      value: ixbrlField(field(''), {
        contextRef: 'period0',
        type: 'stringItemType',
        name: 'se-gen-base:NotVarulagerKommentar',
        standardRubrik: 'Kommentar till specifikation av varulager m.m',
      }),
    },
  },
  group: value(undefined),
  section: value(undefined),
  validForReportType: {
    k2: true,
    k3: true,
  },
});
export const note2062K3v2ContentDefinition = {
  type: 'part' as const,
  children: {
    active: boolean,
    number: cell,
    name: cell,
    data: {
      type: 'part' as const,
      children: {
        active: boolean,

        FordelningVarulagerAbstract: {
          type: 'part' as const,
          children: {
            type: type,
            level: number,
            active: boolean,

            title: cell,

            table: { type: 'table' as const },

            FordelningVarulagerKommentar: {
              type: 'part' as const,
              children: {
                type: type,
                level: number,
                active: boolean,
                visible: cell,

                title: cell,

                value: cell,
              },
            },
          },
        },

        RantaRaknatInAnskaffningsvarde: {
          type: 'part' as const,
          children: {
            type: type,
            level: number,
            active: boolean,

            title: cell,

            table: { type: 'table' as const },
          },
        },

        SkillnadsbeloppVarulagerAbstract: {
          type: 'part' as const,
          children: {
            type: type,
            level: number,
            active: boolean,

            title: cell,

            table: { type: 'table' as const },

            SkillnadsbeloppVarulagerKommentar: {
              type: 'part' as const,
              children: {
                type: type,
                level: number,
                active: boolean,
                visible: cell,

                title: cell,

                value: cell,
              },
            },
          },
        },

        NotVarulagerKommentar: {
          type: 'part' as const,
          children: {
            type: type,
            level: number,
            active: boolean,

            title: cell,

            value: cell,
          },
        },
      },
    },
    group: cell,
    section: cell,
    validForReportType: {
      type: 'part' as const,
      children: {
        k2: boolean,
        k3: boolean,
      },
    },
  },
};

import { isDefined } from '@agoy/common';
import { RootState } from 'redux/reducers';
import {
  currentPeriod,
  currentPeriods,
} from '_clients/redux/customer-view/selectors';
import { PeriodStatus, Status } from '_shared/types';

export const getAnnualYearPeriodStatuses = (
  state: RootState
): PeriodStatus[] => {
  const { currentCustomer } = state.customerView;
  if (currentCustomer) {
    const programPeriodState =
      state.annualReport.clients[currentCustomer].periodStatus;

    if (programPeriodState) {
      return (
        currentPeriods(state)
          ?.map((period) => programPeriodState[period.id]?.history[0])
          .filter(isDefined) ?? []
      );
    }
  }
  return [];
};

export const getCurrentPeriodStatusAnnualReport = (
  state: RootState
): Status => {
  const period = currentPeriod(state);
  const { currentCustomer } = state.customerView;
  if (currentCustomer && period) {
    const { periodStatus } = state.annualReport.clients[currentCustomer];
    if (periodStatus?.[period.id]) {
      return periodStatus[period.id].status;
    }
  }

  return 'NOT_STARTED';
};

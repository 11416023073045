import { Observable } from 'rxjs';
import { Result } from 'ts-results';
import { AccountingBalancesResult } from 'types/Accounting';
import { SieImportStateMessage } from '@agoy/messages';

export type SieImportWarnings = (SieImportStateMessage & {
  status: 'completed';
})['warnings'];

export type SieImportErrorMessage = SieImportStateMessage & {
  status: 'failed';
};

/**
 * Helper type to single out the error details for SIE_IMPORT_CHANGE_IN_LOCKED_PERIOD
 */
export type LockedPeriodError = SieImportErrorMessage & {
  errorCode: 'SIE_IMPORT_CHANGE_IN_LOCKED_PERIOD';
};

export type SieImportError =
  | SieImportErrorMessage
  | {
      errorCode: typeof REQUIRE_IMMIDIATE_UPDATE;
      errorDetails: {
        financialYear: string | number;
      };
    }
  | {
      errorCode: Exclude<
        string,
        SieImportErrorMessage['errorCode'] | typeof REQUIRE_IMMIDIATE_UPDATE
      >;
      errorDetails: {
        detailsMessage?: string;
        message?: string;
      };
    };

/**
 * Internal error code in the service. Used when the notifications and sie-import in RDS is not used.
 */
export const REQUIRE_IMMIDIATE_UPDATE = 'REQUIRE_IMMIDIATE_UPDATE';

export type GetAccountingBalancesErrors = string;

export interface AccountingBalancesDataLayer {
  /**
   * getAccountingBalances
   *
   * Given a string on the format `yyyyMMdd-yyyyMMdd`, it will request the balances for that financial year.
   * The observable will be immidiately completed upon delivering a result. The result will then be available
   * by the financialYearId (number)
   *
   * Given a number it will request the accounting balances for that financial year by DB id. The observable
   * will continue to deliver updates.
   *
   * @param financialYear
   */
  getAccountingBalances(
    financialYear: number | string
  ): Observable<
    Result<AccountingBalancesResult | null, GetAccountingBalancesErrors>
  >;

  /**
   * Upload a SIE file for the client
   *
   * This method will wait for a notification from the WebSocket and
   * resolve once the sie-import is completed.
   *
   * @param file The file content
   */
  uploadSieFile(
    file: Buffer
  ): Promise<Result<SieImportWarnings, SieImportError>>;

  /**
   * Requests a new SIE file from Fortnox
   *
   * This method will wait for a notification from the WebSocket and
   * resolve once the sie-import is completed.
   *
   * @param financialYear The requested financial year as yyyyMMdd-yyyyMMdd
   * @param financialYearId The requested financial year's id.
   */
  updateSieFromFortnox(
    financialYear: string,
    financialYearId: number
  ): Promise<Result<SieImportWarnings, SieImportError>>;

  retryImport(
    financialYear: string,
    financialYearId: number
  ): Promise<Result<void, SieImportError>>;
}

import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { Typography } from '@material-ui/core';
import styled from '@emotion/styled';

import { useSelector } from 'redux/reducers';
import { SignaturePerson } from '_reconciliation/types';
import RichEditor from '_shared/components/RichEditor';
import AccountInfo from '../Components/AccountInfo';
import PeriodDataContext from '../PeriodDataContext';

const Container = styled.div`
  display: flex;
  padding-top: ${(props) => props.theme.spacing(2)}px;
  flex-direction: column;
`;

const Location = styled.div`
  padding-top: ${(props) => props.theme.spacing(1)}px;
  padding-right: ${(props) => props.theme.spacing(2)}px;
`;

const Persons = styled.div`
  display: flex;
  flex-direction: row;
`;

interface CashViewPreviewProps {
  accountNumber: string;
  signatures: SignaturePerson[];
  richText: string;
  location: string;
}

const CashViewPreview = ({
  accountNumber,
  signatures,
  richText,
  location,
}: CashViewPreviewProps): JSX.Element => {
  const { formatMessage } = useIntl();

  const { clientId, period, financialYear } = useContext(PeriodDataContext);
  const { name, orgNumber } = useSelector(
    (state) => state.customers[clientId] || {}
  );

  return (
    <Container>
      <AccountInfo
        title={formatMessage({ id: 'hidden.cash.title' })}
        accountNumber={accountNumber}
        clientName={name}
        orgNumber={orgNumber || ''}
        financialYear={financialYear}
        period={period}
      />

      <RichEditor value={richText} isEditing={false} />

      {location && (
        <Location>
          <Typography>
            {formatMessage({ id: 'annualReport.signatures.section.location' })}
          </Typography>
          <Typography>{location}</Typography>
        </Location>
      )}

      <Persons>
        {signatures.map((person) => (
          <Location
            key={`${person.givenName} ${person.surName} ${person.date}`}
          >
            <Typography>
              {person.givenName} {person.surName}
            </Typography>
            <Typography>{person.date}</Typography>
            <Typography>{person.role.split('|').join(', ')}</Typography>
          </Location>
        ))}
      </Persons>
    </Container>
  );
};

export default CashViewPreview;

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { UserError } from 'types/Error/types';
import { useSelector } from 'redux/reducers';
import { getAccountingBalances } from 'Api/Client/accounting';
import { AccountingBalancesResult } from 'types/Accounting';
import { setAccountingBalances } from '_reconciliation/redux/accounting-view/actions';
import { isApiErrorType } from 'api-sdk';

type LoadPreviousYearAccountingDataProps = {
  onLoaded?: (clientId: string, currentFinancialYear: string) => void;
  children: React.ReactNode;
};

/**
 * LoadPreviousYearSieData
 *
 * Loads the sie data for the previous year of current customer by looking
 * at the (index of current year) - 1 of the client finanacial years array.
 * This assumes there are no gaps in the financial years array.
 */
const LoadPreviousYearAccountingData = ({
  onLoaded,
  children,
}: LoadPreviousYearAccountingDataProps): JSX.Element => {
  const dispatch = useDispatch();

  const { currentCustomer: clientId, currentFinancialYear } = useSelector(
    (state) => state.customerView
  );

  const financialYears = useSelector((state) =>
    clientId ? state.customers[clientId].financialYears : undefined
  );

  useEffect(() => {
    if (clientId && currentFinancialYear && financialYears) {
      if (financialYears.length <= 1) return undefined;
      const currentYearIndex = financialYears
        .sort()
        .indexOf(currentFinancialYear);

      if (currentYearIndex < 1) return undefined;
      const previousFinancialYear = financialYears[currentYearIndex - 1];

      const loadSieData = async (financialYear: string) => {
        if (!financialYear) {
          throw new Error('Expected a financial year');
        }
        let result: AccountingBalancesResult | null = null;
        try {
          result = await getAccountingBalances(clientId, financialYear);
        } catch (error) {
          if (isApiErrorType(error)) {
            if (error.status === 403) {
              throw new UserError('sie.error.unauthorized');
            }
          }
          throw error;
        }
        if (result) {
          await dispatch(
            setAccountingBalances(
              clientId,
              financialYear,
              result.accountingBalances,
              result.updatedAt
            )
          );
        }
      };

      (async () => {
        try {
          await loadSieData(previousFinancialYear);
          if (onLoaded) {
            onLoaded(clientId, currentFinancialYear);
          }
        } catch (err) {
          console.error(err);
        }
      })();
    }
  }, [onLoaded, dispatch, clientId, currentFinancialYear, financialYears]);

  return <>{children}</>;
};

export default LoadPreviousYearAccountingData;

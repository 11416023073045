import React, { useCallback, useContext, useEffect, useState } from 'react';
import { batch, useDispatch } from 'react-redux';
import { generatePath, useHistory, useRouteMatch } from 'react-router-dom';
import { asResultClass, isApiErrorType, useApiSdk } from 'api-sdk';
import { useIntl } from 'react-intl';

import { addGlobalMessage } from 'redux/actions';
import { useSelector } from 'redux/reducers';
import { ClientsRouteParams } from 'routes/types';
import { addClient } from '_clients/redux/customers/actions';
import { mapClient } from '_clients/services/mappings';
import { ClientInfoContext } from '_clients/context/ClientInformationContext';
import ClientLoaderContext, {
  LoaderFunction,
  LoaderFunctionOnLoad,
} from '_shared/components/ClientLoaderContext';
import BaseLoader from './BaseLoader';

/**
 * ClientLoader is responsible for getting the client data from the API
 * When the state customerView.next is changed, it will trigger all
 * registered loaders
 */
const ClientLoader = (): JSX.Element => {
  const { formatMessage } = useIntl();
  const sdk = useApiSdk();

  const dispatch = useDispatch();
  const history = useHistory();
  const { path, params } = useRouteMatch<ClientsRouteParams>();
  const { loadClient } = useContext(ClientLoaderContext);
  const { fetchClientInformation } = useContext(ClientInfoContext);

  const {
    next: { clientId: nextClientId, year: nextYear },
  } = useSelector((state) => state.customerView);

  const companyType = useSelector((state) =>
    nextClientId ? state.customers[nextClientId]?.type : undefined
  );

  const [isClientLoaded, setClientLoaded] = useState(false);

  const clientLoader = useCallback(
    async (clientId, year): Promise<LoaderFunctionOnLoad | void> => {
      await fetchClientInformation(clientId);
      const clientResult = await asResultClass(
        sdk.getClientById({
          clientid: clientId,
        })
      );

      if (clientResult.err) {
        if (isApiErrorType(clientResult.val) && clientResult.val.handled) {
          // Ignore the handled error
          return undefined;
        }
        // eslint-disable-next-line no-console
        console.warn(clientResult.val.message);

        // Reload the client list, solves the issue where
        // credentials has been changed in another tab.
        window.location.href = '/';
        return undefined;
      }

      setClientLoaded(true);

      const mappedData = mapClient(clientResult.val);
      dispatch(addClient(mappedData));

      if ('messages' in clientResult.val) {
        const { messages } = clientResult.val;
        if (messages.length > 0) {
          batch(() => {
            messages.forEach((msg) =>
              dispatch(
                addGlobalMessage(
                  msg.level,
                  formatMessage(
                    { id: `${msg.level}.getClient.${msg.id}` },
                    msg.details
                  )
                )
              )
            );
          });
        }
      }

      if (
        mappedData?.financialYears &&
        (year === undefined || !mappedData.financialYears.includes(year))
      ) {
        // Get the latest year and switch to that
        const latestYear = mappedData.financialYears.reduce(
          (latest, current) => (latest > current ? latest : current),
          ''
        );

        // Return a handler that is executed when all loaders are finished
        return async () => {
          if (latestYear !== year) {
            history.push(
              generatePath(path, { ...params, financialYear: latestYear })
            );
          }
        };
      }
    },
    [
      fetchClientInformation,
      sdk,
      dispatch,
      formatMessage,
      history,
      path,
      params,
    ]
  );

  const loader: LoaderFunction = useCallback(
    async (clientId, type, year) => {
      if (!isClientLoaded) {
        return clientLoader(clientId, year);
      }
    },
    [clientLoader, isClientLoaded]
  );

  useEffect(() => {
    if (nextClientId) {
      // Client and/or year changes, start loading that data.
      if (!companyType) {
        clientLoader(nextClientId, nextYear);
      }
      if (companyType) {
        loadClient(nextClientId, companyType, nextYear);
      }
    }
  }, [nextYear, nextClientId, loadClient, clientLoader, companyType]);

  return <BaseLoader id="client" scope="client" loader={loader} />;
};

export default ClientLoader;

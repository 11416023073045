import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { Note84Section } from '@agoy/annual-report-document';
import { numberValue, stringValue } from '@agoy/document';
import { Note } from '../../UI/Note';
import Comment from '../../UI/Comment';
import Header from '../../UI/Header';
import NoteTable from '../../UI/NoteTable';
import { useIntl } from 'react-intl';

type Note84Props = {
  noteId: string;
  hasPreviousYear?: boolean;
} & Note84Section;

const Note84 = ({
  noteId,
  hasPreviousYear,
  ...note
}: Note84Props): JSX.Element | null => {
  const { formatMessage } = useIntl();

  const { active, number } = note;

  if (!active) return null;

  const title = stringValue(note.name) || '';

  return (
    <Note id={noteId}>
      <Header noteId={noteId} noteNumber={numberValue(number)} title={title} />

      <Box mt={1} mb={1}>
        <NoteTable
          table={note.table}
          hasPreviousYear={hasPreviousYear}
          tableId={`notes.${noteId}.table`}
        />
      </Box>
      <Box mt={1} mb={1}>
        <NoteTable
          table={note.secondTable}
          hasPreviousYear={hasPreviousYear}
          tableId={`notes.${noteId}.secondTable`}
        />
      </Box>
      <Box mt={1} mb={1}>
        <NoteTable
          table={note.thirdTable}
          hasPreviousYear={hasPreviousYear}
          tableId={`notes.${noteId}.thirdTable`}
        />
      </Box>
      <Typography variant="h4">
        {formatMessage({ id: 'annualReport.notes.note84.allocationProposal' })}
      </Typography>
      <Box mt={1} mb={1}>
        <NoteTable
          table={note.fourthTable}
          hasPreviousYear={hasPreviousYear}
          tableId={`notes.${noteId}.fourthTable`}
        />
      </Box>
      <Box mt={1} mb={1}>
        <NoteTable
          table={note.fifthTable}
          hasPreviousYear={hasPreviousYear}
          tableId={`notes.${noteId}.fifthTable`}
        />
      </Box>
      {!!note.sixthTable.rows?.length && (
        <Typography variant="h4">
          {formatMessage({
            id: 'annualReport.notes.note84.proposedDividendPerShare',
          })}
        </Typography>
      )}
      <Box mt={1} mb={1}>
        <NoteTable
          table={note.sixthTable}
          hasPreviousYear={hasPreviousYear}
          tableId={`notes.${noteId}.sixthTable`}
          displayUnfiltered
        />
      </Box>

      <Comment
        field={note.comment}
        labelValue={stringValue(note.commentLabel) || ''}
        value={stringValue(note.comment) || ''}
      />

      <Comment
        field={note.secondComment}
        labelValue={stringValue(note.secondCommentLabel) || ''}
        value={stringValue(note.secondComment) || ''}
      />
    </Note>
  );
};

export default Note84;

import React, { useRef } from 'react';
import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';

import { FinancialReport } from '@agoy/financial-report-document';
import { stringValue } from '@agoy/document';
import { TaxDocumentAttachment } from '@agoy/tax-document';
import Document from '_shared/components/Document';
import { Document as DocumentType } from '_financial-report/redux/reducer/types';

import { ReportColors } from '../../../../utils';

type ReportColorsProps = {
  customColor: string | undefined;
};

const A4Page = styled.div`
  position: relative;
  width: 1400px;
`;

const DocumentsContainer = styled.div`
  width: 100%;
`;

const StyledDocument = styled(Document)`
  .react-pdf__Page {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const SmallTitle = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'customColor',
})<ReportColorsProps>`
  font-size: 20px;
  line-height: 22px;
  margin-bottom: ${(props) => props.theme.spacing(1)}px;
  color: ${(props) => props.customColor || props.theme.palette.text.primary};
`;

export interface Attachment extends TaxDocumentAttachment {
  id: string;
}

interface DocumentsViewProps {
  documentsData: FinancialReport['documents'];
  documents: DocumentType[];
  reportColors: ReportColors;
  onDocumentsReady?: () => void;
}

const DocumentsViewPreview = ({
  documentsData,
  documents,
  reportColors,
  onDocumentsReady = () => {},
}: DocumentsViewProps): JSX.Element => {
  const loadedDocuments = useRef(0);

  const handleDocumentLoad = () => {
    loadedDocuments.current += 1;
    if (loadedDocuments.current === documents.length) {
      onDocumentsReady();
    }
  };

  if (documents.length === 0) {
    onDocumentsReady();
  }

  return (
    <A4Page>
      <SmallTitle customColor={reportColors.textMain}>
        {stringValue(documentsData.section.title)}
      </SmallTitle>
      <DocumentsContainer>
        {documents.map((attachment) => (
          <StyledDocument
            document={attachment}
            pdfPageSize={{
              height: 930,
            }}
            onLoad={handleDocumentLoad}
            key={attachment.id}
          />
        ))}
      </DocumentsContainer>
    </A4Page>
  );
};

export default DocumentsViewPreview;

import { ReferenceAccountInformation, TimePeriod } from '@agoy/document';
import { parse, format } from '@agoy/dates';

import {
  getPreviousYearPeriod,
  individualCompanyIncomeStatementConfig,
} from '@agoy/annual-report-document';

import { FinancialReport, FinancialReportCustomerType } from '../../types';
import { settingsConfig } from './settings';
import { diagramRRBRConfig } from './diagramRRBR';
import { frontPageConfig } from './frontPage';
import { tableRRBRConfig } from './tableRRBR';
import { cashFlowDiagramConfig } from './cashFlowDiagram';
import { tableOfContentsConfig } from './tableOfContents';
import { balanceSheetAssetsConfig } from './balanceSheetAssets';
import { incomeStatementConfig } from './incomeStatement';
import { documentsConfig } from './documents';
import { additionalTextsConfig } from './additionalTexts';
import {
  individualCompanyBalanceSheetConfig,
  balanceSheetReferences,
  incomeStatementReferences,
} from '@agoy/annual-report-document';
import {
  formatEndOfMonth,
  formatStartOfMonth,
  parseFinancialYear,
  parseFormat,
} from '@agoy/dates';
import { isFirstFinancialYear } from '../utils';

const REPORT_TYPE = 'k2';

/**
 * Creates a new configuration of a financial report
 * @param customer
 */
export const config = (
  userName: string,
  customer: FinancialReportCustomerType,
  period: string,
  financialYear: string,
  accounts: Record<string, ReferenceAccountInformation>,
  isSinglePeriod: boolean
): FinancialReport => {
  const currentPeriod = format(parse(period, 'yyyyMMdd'), 'yyyy-MM-dd');
  const { start, end } = parseFinancialYear(financialYear);
  const yearStart = format(start, 'yyyy-MM-dd');
  const yearEnd = format(end, 'yyyy-MM-dd');

  const financialYears = Array.from(customer.financialYears)
    .sort()
    .reverse()
    .filter((year) => year <= financialYear)
    .slice(0, 2)
    .map((y) => TimePeriod.fromFinancialYear(y))
    .map((p) => ({
      start: p.startDateISO,
      end: p.endDateISO,
      label: p.start.substring(0, 4),
    }));

  const previousYearPeriod =
    financialYears.length > 1
      ? getPreviousYearPeriod(
          period,
          TimePeriod.fromISODates(
            financialYears[1].start,
            financialYears[1].end
          ).value
        )
      : null;
  const formattedPreviousYearPeriod = previousYearPeriod
    ? format(parse(previousYearPeriod, 'yyyyMM'), 'MMMyy')
    : '';

  const timePeriod = new TimePeriod(
    parseFormat(period, 'yyyyMMdd'),
    formatEndOfMonth(period, 'yyyyMMdd')
  );
  const previousTimePeriod = previousYearPeriod
    ? new TimePeriod(
        formatStartOfMonth(previousYearPeriod, 'yyyyMMdd'),
        formatEndOfMonth(previousYearPeriod, 'yyyyMMdd')
      )
    : null;

  return {
    settings: settingsConfig(
      userName,
      customer,
      financialYear,
      period,
      isFirstFinancialYear(customer),
      isSinglePeriod
    ),
    frontPage: frontPageConfig(customer, period, isSinglePeriod),

    tableOfContents: tableOfContentsConfig(),

    balanceSheetAssets: balanceSheetAssetsConfig(),

    diagramRRBR: diagramRRBRConfig(userName, currentPeriod, yearStart, yearEnd),

    tableRRBR: tableRRBRConfig(accounts, period),

    cashFlowDiagram: cashFlowDiagramConfig(period),

    incomeStatementTables: incomeStatementConfig(
      accounts,
      financialYear,
      customer.financialYears,
      period,
      REPORT_TYPE
    ),
    // period table(s)
    incomeStatement: individualCompanyIncomeStatementConfig(
      accounts,
      timePeriod,
      previousTimePeriod,
      REPORT_TYPE,
      // false,
      // make sure it uses change values instead of ub in the rapport
      'change'
    ),
    balanceSheet: individualCompanyBalanceSheetConfig(
      accounts,
      TimePeriod.monthOf(period),
      previousYearPeriod ? TimePeriod.monthOf(previousYearPeriod) : null,
      formattedPreviousYearPeriod
    ),
    documents: documentsConfig(),
    additionalText: additionalTextsConfig(),
  };
};

/**
 * Creates all references that the annual report refers to
 */
export const references = () => {
  const sharedReferences = {};

  const partReferences = [
    sharedReferences,
    // TODO: these are still from shares-company
    balanceSheetReferences(),
    incomeStatementReferences(),
  ];

  // Verify that there is no name conflict
  let references = {};
  partReferences.forEach((refs) => {
    const keysSoFar = Object.keys(references);
    const conflict = Object.keys(refs).find((ref) => keysSoFar.includes(ref));
    if (conflict) {
      throw new Error(`Conflicting keys in references (${conflict})`);
    }
    references = { ...references, ...refs };
  });

  return references;
};

import { AgoyTable, Field } from '@agoy/document';
import { AnnualReportPart, AnnualReportSection } from '../../../document';
import { IxbrlField } from '../../../common/types/ixbrl';
import {
  CustomNotes,
  HeadingsSection,
  Note28Section,
  Note2Section,
  NoteInputFieldSection,
  NoteInputFieldSectionNoComment,
  NoteOneTableSection,
  NoteOneTableWithSum,
  NoteInvertedTwoTableSection,
  NoteTwoTableSection,
  NoteThreeCommentTwoTableSection,
  NotesSection,
  NoteSixTableSection,
  Note74Section,
  NoteTwoTableSectionWithSum,
  NoteFourTablesWithCommentsAndSum,
  Note84Section,
  NoteFiveTableTwoCommentSection,
  NoteThreeCommentThreeTableSection,
  NoteTwoTableSectionWithTwoSum,
  Note95,
  Note100,
  NoteFourTablesWithCaptionsCommentsAndSum,
} from '../../../common/types/noteSections';

export type NoteAccountRangesAction =
  | 'sumAccountsIBs'
  | 'sumAccountsUBs'
  | 'sumAccounts'
  | 'sumPositiveTransactions'
  | 'sumNegativeTransactions';

export type NoteAccountRanges = {
  rowId: string;
  ranges: number[][];
  cellLabel: string;
  action: NoteAccountRangesAction;
};

export interface Note1Section extends AnnualReportSection, NotesSection {
  disclaimerK2: Field;
  disclaimerK3: Field;
  tangibleFixedAssetsLabel: Field;
  tangibleFixedAssetsGeneral: Field;
  tangibleFixedAssetsCaption: Field;
  tangibleFixedAssets: AgoyTable;
  receivablesLabel: Field;
  receivables: Field;
  otherAssetsProvisionsAndLiabilitiesLabel: Field;
  otherAssetsProvisionsAndLiabilities: Field;
  revenueRecognitionLabel: Field;
  revenueRecognition: Field;
  saleOfGoodsLabel: Field;
  saleOfGoods: Field;
  servicesLabel: Field;
  services: Field;
  acquisitionGoodsLabel: Field;
  acquisitionGoods: Field;
  changedPrinciplesLabel: Field;
  changedPrinciples: Field;
  rentsLabel: Field;
  rents: Field;
  interestRoyaltyAndDividensLabel: Field;
  interestRoyaltyAndDividens: Field;
  intangibleAssetsGeneralLabel: Field;
  tangibleAssetsCommentLabel: Field;
  tangibleAssetsComment: IxbrlField;
  intangibleAssetsGeneral: Field;
  intangibleAssetsTableCaption: Field;
  intangibleAssetsTable: AgoyTable;
  intangibleAssetsProjectsClosed: Field;
  incomeTaxLabel: Field;
  incomeTaxParagraph1: Field;
  incomeTaxParagraph2: Field;
  incomeTaxParagraph3: Field;
  incomeTaxParagraph4: Field;
  incomeTaxParagraph5: Field;
  // Note 1 K3 fields
  accountingPrincipleChangeLabel: Field;
  accountingPrincipleChange: Field;
  taxLabel: Field;
  tax: Field;
  leasingLabel: Field;
  leasing: Field;
  deviationReasonsLabel: Field;
  deviationReasons: Field;
  notComparablePrincipleChangeLabel: Field;
  notComparablePrincipleChange: Field;
  generalAdviceDeviationLabel: Field;
  generalAdviceDeviation: Field;
  basicPrinciplesDeviationsLabel: Field;
  basicPrinciplesDeviations: Field;
  incomeStatementChangeLabel: Field;
  incomeStatementChange: Field;
  firstTimeK3Label: Field;
  firstTimeK3: Field;
  employeeCompensationLabel: Field;
  employeeCompensation: Field;
  publicContributionsLabel: Field;
  publicContributions: Field;
  loanExpensesLabel: Field;
  loanExpenses: Field;
  foreignCurrencyLabel: Field;
  foreignCurrencyGeneral: Field;
  foreignCurrencyConversionLabel: Field;
  foreignCurrencyConversion: Field;
  foreignCurrencyRecalculationLabel: Field;
  foreignCurrencyRecalculation: Field;
  k3IncomeLabel: Field;
  k3IncomeGeneral: Field;
  k3SaleOfGoodsLabel: Field;
  k3SaleOfGoods: Field;
  k3ServicesLabel: Field;
  k3Services: Field;
  k3CustomerLoyaltyLabel: Field;
  k3CustomerLoyalty: Field;
  k3InterestAndDividendLabel: Field;
  k3InterestAndDividend: Field;
  k3WarrantyObligationsLabel: Field;
  k3WarrantyObligations: Field;
  fixedAssetsWriteDownsLabel: Field;
  fixedAssetsWriteDowns: Field;
  companiesSharesLabel: Field;
  companiesShares: Field;
  groupContributionsLabel: Field;
  groupContributions: Field;
  jointVentureLabel: Field;
  jointVenture: Field;
  financialInstrumentsLabel: Field;
  financialInstruments: Field;
  collateralAccountingLabel: Field;
  collateralAccounting: Field;
  inventoryLabel: Field;
  inventory: Field;
  provisionsLabel: Field;
  provisions: Field;
  contingentAssetsAndLiabilitiesLabel: Field;
  contingentAssetsAndLiabilities: Field;
  debtAcquisitionLabel: Field;
  debtAcquisition: Field;
  cashflowAnalysisLabel: Field;
  cashflowAnalysis: Field;
  commentLabel: Field;
  comment: IxbrlField;
}

// Noter
export interface Notes extends AnnualReportPart {
  headings: HeadingsSection;
  note1: Note1Section;
  note2: Note2Section;
  note3: NoteOneTableSection;
  note4: NoteOneTableSection;
  note5: NoteOneTableSection;
  note6: NoteOneTableSection;
  note7: NoteOneTableSection;
  note8: NoteOneTableSection;
  note9: NoteOneTableSection;
  note10: NoteOneTableSection;
  note11: NoteOneTableSection;
  note12: NoteOneTableSection;
  note13: NoteOneTableSection;
  note14: NoteOneTableSection;
  note15: NoteOneTableSection;
  note16: NoteInvertedTwoTableSection;
  note17: NoteOneTableSection;
  note18: NoteInvertedTwoTableSection;
  note19: NoteOneTableSection;
  note20: NoteInvertedTwoTableSection;
  note21: NoteOneTableSection;
  note22: NoteOneTableSection;
  note23: NoteOneTableSection;
  note24: NoteOneTableSection;
  note25: NoteOneTableSection;
  note26: NoteOneTableWithSum;
  note27: NoteOneTableSection;
  note28: Note28Section;
  note29: NoteOneTableWithSum;
  note30: NoteOneTableWithSum;
  note31: NoteOneTableWithSum;
  note32: NoteOneTableWithSum;
  note33: NoteOneTableWithSum;
  note34: NoteOneTableSection;
  note35: NoteInputFieldSection;
  note36: NoteInputFieldSection;
  note37: NoteOneTableSection;
  note38: NoteOneTableSection;
  note39: NoteOneTableSection;
  note40: NoteOneTableSection;
  note41: NoteOneTableSection;
  note42: NoteOneTableSection;
  note43: NoteOneTableSection;
  note44: NoteOneTableSection;
  note58: NoteOneTableSection;
  note59: NoteOneTableSection;
  note60: NoteInputFieldSectionNoComment;
  note61: NoteOneTableWithSum;
  note62: NoteOneTableWithSum;
  note63: NoteOneTableWithSum;
  note64: NoteTwoTableSection;
  note65: NoteInputFieldSection;
  note66: NoteOneTableWithSum;
  note67: NoteInputFieldSection;
  note68: NoteOneTableSection;
  note69: NoteInputFieldSection;
  note70: NoteThreeCommentTwoTableSection;
  note71: NoteOneTableSection;
  note72: NoteTwoTableSectionWithSum;
  note73: NoteOneTableSection;
  note74: Note74Section;
  note75: NoteSixTableSection;
  note76: NoteInvertedTwoTableSection;
  note77: NoteFourTablesWithCommentsAndSum;
  note78: NoteOneTableSection;
  note79: NoteInputFieldSectionNoComment;
  note80: NoteTwoTableSection;
  note81: NoteInvertedTwoTableSection;
  note82: NoteTwoTableSectionWithSum;
  note83: NoteOneTableWithSum;
  note84: Note84Section;
  note85: NoteOneTableSection;
  note86: NoteOneTableWithSum;
  note87: NoteOneTableSection;
  note88: NoteOneTableSection;
  note89: NoteOneTableSection;
  note90: NoteOneTableSection;
  note91: NoteInvertedTwoTableSection;
  note92: NoteOneTableSection;
  note93: NoteThreeCommentThreeTableSection;
  note94: NoteOneTableSection;
  note95: Note95;
  note96: NoteFiveTableTwoCommentSection;
  note97: NoteOneTableSection;
  note98: NoteTwoTableSectionWithTwoSum;
  note99: NoteFourTablesWithCaptionsCommentsAndSum;
  note100: Note100;
  note101: NoteOneTableSection;
  note401: NoteThreeCommentTwoTableSection;
  note402: NoteOneTableSection;
  custom: CustomNotes;
}

const cell = { type: 'field' as const };
const boolean = { type: 'boolean' as const };

export const notes = {
  type: 'part' as const,
  children: {
    active: boolean,
    headings: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        notesIncomeStatementTitle: cell,
        notesBalanceSheetTitle: cell,
        notesOtherTitle: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note1: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        disclaimerK2: cell,
        disclaimerK3: cell,
        tangibleFixedAssetsLabel: cell,
        tangibleFixedAssetsGeneral: cell,
        tangibleFixedAssetsCaption: cell,
        tangibleFixedAssets: { type: 'table' as const },
        receivablesLabel: cell,
        receivables: cell,
        otherAssetsProvisionsAndLiabilitiesLabel: cell,
        otherAssetsProvisionsAndLiabilities: cell,
        revenueRecognitionLabel: cell,
        revenueRecognition: cell,
        saleOfGoodsLabel: cell,
        saleOfGoods: cell,
        servicesLabel: cell,
        services: cell,
        acquisitionGoodsLabel: cell,
        acquisitionGoods: cell,
        changedPrinciplesLabel: cell,
        changedPrinciples: cell,
        rentsLabel: cell,
        rents: cell,
        interestRoyaltyAndDividensLabel: cell,
        interestRoyaltyAndDividens: cell,
        intangibleAssetsGeneralLabel: cell,
        tangibleAssetsCommentLabel: cell,
        tangibleAssetsComment: cell,
        intangibleAssetsGeneral: cell,
        intangibleAssetsTableCaption: cell,
        intangibleAssetsTable: { type: 'table' as const },
        intangibleAssetsProjectsClosed: cell,
        incomeTaxLabel: cell,
        incomeTaxParagraph1: cell,
        incomeTaxParagraph2: cell,
        incomeTaxParagraph3: cell,
        incomeTaxParagraph4: cell,
        incomeTaxParagraph5: cell,
        accountingPrincipleChangeLabel: cell,
        accountingPrincipleChange: cell,
        taxLabel: cell,
        tax: cell,
        leasingLabel: cell,
        leasing: cell,
        deviationReasonsLabel: cell,
        deviationReasons: cell,
        notComparablePrincipleChangeLabel: cell,
        notComparablePrincipleChange: cell,
        generalAdviceDeviationLabel: cell,
        generalAdviceDeviation: cell,
        basicPrinciplesDeviationsLabel: cell,
        basicPrinciplesDeviations: cell,
        incomeStatementChangeLabel: cell,
        incomeStatementChange: cell,
        firstTimeK3Label: cell,
        firstTimeK3: cell,
        employeeCompensationLabel: cell,
        employeeCompensation: cell,
        publicContributionsLabel: cell,
        publicContributions: cell,
        loanExpensesLabel: cell,
        loanExpenses: cell,
        foreignCurrencyLabel: cell,
        foreignCurrencyGeneral: cell,
        foreignCurrencyConversionLabel: cell,
        foreignCurrencyConversion: cell,
        foreignCurrencyRecalculationLabel: cell,
        foreignCurrencyRecalculation: cell,
        k3IncomeLabel: cell,
        k3IncomeGeneral: cell,
        k3SaleOfGoodsLabel: cell,
        k3SaleOfGoods: cell,
        k3ServicesLabel: cell,
        k3Services: cell,
        k3CustomerLoyaltyLabel: cell,
        k3CustomerLoyalty: cell,
        k3InterestAndDividendLabel: cell,
        k3InterestAndDividend: cell,
        k3WarrantyObligationsLabel: cell,
        k3WarrantyObligations: cell,
        fixedAssetsWriteDownsLabel: cell,
        fixedAssetsWriteDowns: cell,
        companiesSharesLabel: cell,
        companiesShares: cell,
        groupContributionsLabel: cell,
        groupContributions: cell,
        jointVentureLabel: cell,
        jointVenture: cell,
        financialInstrumentsLabel: cell,
        financialInstruments: cell,
        collateralAccountingLabel: cell,
        collateralAccounting: cell,
        inventoryLabel: cell,
        inventory: cell,
        provisionsLabel: cell,
        provisions: cell,
        contingentAssetsAndLiabilitiesLabel: cell,
        contingentAssetsAndLiabilities: cell,
        debtAcquisitionLabel: cell,
        debtAcquisition: cell,
        cashflowAnalysisLabel: cell,
        cashflowAnalysis: cell,
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note2: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        noEmployees: cell,
        avgNumberOfEmployees: cell,
        avgNumberOfEmployeesTable: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note3: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note4: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note5: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note43: {
      type: 'part' as const,
      children: {
        // from an accounting perspective, it should come here
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note6: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note7: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note8: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note9: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note10: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note11: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note12: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note13: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note14: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note15: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note16: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        secondTable: { type: 'table' as const },
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note17: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    // only secondTable to inherit tableId
    note18: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        secondTable: { type: 'table' as const },
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note19: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    // only secondTable to inherit tableId
    note20: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        secondTable: { type: 'table' as const },
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note21: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note22: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note23: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        // table was deleted
        // keeping secondTable's IDs
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note24: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note25: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note26: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        sumTable: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note27: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note28: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        general: cell,
        table: { type: 'table' as const },
        secondTable: { type: 'table' as const },
        sumTable: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note29: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        sumTable: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note30: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        sumTable: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note31: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        sumTable: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note32: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        sumTable: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    // 40-42 and 44 must come after note 32 and before 33
    // they are all balance sheet notes
    note40: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note41: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note42: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note44: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note33: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        sumTable: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note34: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note35: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        inputField: cell,
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note36: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        inputField: cell,
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note37: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note38: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note39: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note58: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note59: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note60: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        inputField: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note61: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        sumTable: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note62: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        sumTable: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note63: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        sumTable: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note64: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        secondTable: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note65: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        inputField: cell,
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note66: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        sumTable: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note67: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        inputField: cell,
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note68: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note69: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        inputField: cell,
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    // K3 exclusive notes start here
    note70: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        firstTableCommentLabel: cell,
        firstTableComment: cell,
        secondTableTitle: cell,
        secondTable: { type: 'table' as const },
        secondTableCommentLabel: cell,
        secondTableComment: cell,
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note71: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note72: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        secondTable: { type: 'table' as const },
        sumTable: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note73: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note74: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        noEmployeesTable: { type: 'table' as const },
        noEmployeesTableCommentLabel: cell,
        noEmployeesTableComment: cell,
        noEmployeesPerCountryTable: { type: 'table' as const },
        noEmployeesPerCountryTableCommentLabel: cell,
        noEmployeesPerCountryTableComment: cell,
        noOfBoardMembersAndExecutivesTable: { type: 'table' as const },
        noOfCEOAndOtherExecutivesTable: { type: 'table' as const },
        noOfCEOAndOtherExecutivesTableCommentLabel: cell,
        noOfCEOAndOtherExecutivesTableComment: cell,
        salariesAndBenefitsTable: { type: 'table' as const },
        salariesAndBenefitsSumTable: { type: 'table' as const },
        salariesAndBenefitsTableCommentLabel: cell,
        salariesAndBenefitsTableComment: cell,
        socialCostsTable: { type: 'table' as const },
        socialCostsSumTable: { type: 'table' as const },
        socialCostsTableCommentLabel: cell,
        socialCostsTableComment: cell,
        pensionCostsTable: { type: 'table' as const },
        pensionCostsTableCommentLabel: cell,
        pensionCostsTableComment: cell,
        specificationOfSalariesPensionsAndOtherBenefitsTable: {
          type: 'table' as const,
        },
        specificationOfSalariesPensionsAndOtherBenefitsTableCommentLabel: cell,
        specificationOfSalariesPensionsAndOtherBenefitsTableComment: cell,
        severancePayAgreementLabel: cell,
        severancePayAgreement: cell,
        nrOfPeopleUnderTheCompanyManagementTable: {
          type: 'table' as const,
        },
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note75: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        tableCommentLabel: cell,
        tableComment: cell,
        secondTable: { type: 'table' as const },
        secondTableCommentLabel: cell,
        secondTableComment: cell,
        thirdTable: { type: 'table' as const },
        thirdTableCommentLabel: cell,
        thirdTableComment: cell,
        fourthTable: { type: 'table' as const },
        fourthTableCommentLabel: cell,
        fourthTableComment: cell,
        fifthTable: { type: 'table' as const },
        fifthTableCommentLabel: cell,
        fifthTableComment: cell,
        sixthTable: { type: 'table' as const },
        sixthTableCommentLabel: cell,
        sixthTableComment: cell,
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note76: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        secondTable: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note77: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        firstTableCommentLabel: cell,
        firstTableComment: cell,
        secondTable: { type: 'table' as const },
        secondTableCommentLabel: cell,
        secondTableComment: cell,
        thirdTable: { type: 'table' as const },
        thirdTableCommentLabel: cell,
        thirdTableComment: cell,
        fourthTable: { type: 'table' as const },
        sumTable: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note78: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note79: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        inputField: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note80: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        secondTable: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note81: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        secondTable: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note82: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        secondTable: { type: 'table' as const },
        sumTable: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note83: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        sumTable: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note84: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        secondTable: { type: 'table' as const },
        thirdTable: { type: 'table' as const },
        fourthTable: { type: 'table' as const },
        fifthTable: { type: 'table' as const },
        sixthTable: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        secondCommentLabel: cell,
        secondComment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note85: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note86: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        sumTable: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note87: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note88: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note89: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note90: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note91: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        secondTable: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note92: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note93: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        firstTableCommentLabel: cell,
        firstTableComment: cell,
        secondTable: { type: 'table' as const },
        thirdTable: { type: 'table' as const },
        thirdTableCommentLabel: cell,
        thirdTableComment: cell,
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note94: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note95: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        tables: {
          type: 'array' as const,
          children: {
            type: 'part' as const,
            children: {
              type: cell,
              year: cell,
              previousYear: cell,
              table: { type: 'table' as const },
              customYear: cell,
              customPreviousYear: cell,
              customTable: { type: 'table' as const },
            },
          },
        },
        sumTable: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note96: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        tableCommentLabel: cell,
        tableComment: cell,
        secondTable: { type: 'table' as const },
        secondTableCommentLabel: cell,
        secondTableComment: cell,
        thirdTable: { type: 'table' as const },
        thirdTableCommentLabel: cell,
        thirdTableComment: cell,
        fourthTable: { type: 'table' as const },
        fourthTableCommentLabel: cell,
        fourthTableComment: cell,
        fifthTable: { type: 'table' as const },
        fifthTableCommentLabel: cell,
        fifthTableComment: cell,
        sixthCommentLabel: cell,
        sixthComment: cell,
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note97: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note98: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        sumTable: { type: 'table' as const },
        secondTable: { type: 'table' as const },
        secondSumTable: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note99: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        subTitle: cell,
        tableCaption: cell,
        table: { type: 'table' as const },
        firstTableCommentLabel: cell,
        firstTableComment: cell,
        secondTableCaption: cell,
        secondTable: { type: 'table' as const },
        secondTableCommentLabel: cell,
        secondTableComment: cell,
        thirdTableCaption: cell,
        thirdTable: { type: 'table' as const },
        thirdTableCommentLabel: cell,
        thirdTableComment: cell,
        fourthTableCaption: cell,
        fourthTable: { type: 'table' as const },
        sumTable: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note100: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        tables: {
          type: 'array' as const,
          children: {
            type: 'part' as const,
            children: {
              type: cell,
              year: cell,
              previousYear: cell,
              table: { type: 'table' as const },
            },
          },
        },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note101: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note401: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        firstTableCommentLabel: cell,
        firstTableComment: cell,
        secondTableTitle: cell,
        secondTable: { type: 'table' as const },
        secondTableCommentLabel: cell,
        secondTableComment: cell,
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note402: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    custom: {
      type: 'array' as const,
      children: {
        type: 'part' as const,
        children: {
          active: boolean,
          number: cell,
          name: cell,
          group: cell,
          section: cell,
          validForReportType: {
            type: 'part' as const,
            children: {
              k2: boolean,
              k3: boolean,
            },
          },
          commentTitle: cell,
          typeOne: cell,
          typeTwo: { type: 'table' as const },
          typeThree: { type: 'table' as const },
        },
      },
    },
  },
};

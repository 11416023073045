/* eslint-disable import/prefer-default-export */
import {
  distinctUntilChanged,
  filter,
  firstValueFrom,
  map,
  Observable,
  Subscription,
} from 'rxjs';
import { Result } from 'ts-results';
import { batch, useDispatch } from 'react-redux';
import { reformatISODate } from '@agoy/common';
import { WSNotification } from '@agoy/messages';
import { addProgramPeriodStatus, setClientLampStatus } from 'redux/actions';
import { ClientCompanyType } from '_clients/types/types';
import {
  NotificationErrors,
  NotificationService,
} from '_shared/services/Notifications/types';
import AccountingBalancesDataLayerService from './accountingBalances/service';
import { AccountingBalancesDataLayer } from './accountingBalances/types';
import ReconciliationDataLayerService from './reconciliation/service';
import { ReconciliationDataLayer } from './reconciliation/types';
import { ClientDataLayer } from './types';

type Dispatch = ReturnType<typeof useDispatch>;

export class ClientDataLayerImpl implements ClientDataLayer {
  clientId: string;

  notificationService: NotificationService | null;

  clientData: Observable<ClientCompanyType>;

  private dispatch: Dispatch;

  private _accountingBalances: AccountingBalancesDataLayerService | undefined;

  private _reconciliation: ReconciliationDataLayerService | undefined;

  private subscriptions: Subscription[] = [];

  constructor(
    clientId: string,
    clientData: Observable<ClientCompanyType>,
    notificationService: NotificationService | null,
    dispatch: Dispatch
  ) {
    this.clientId = clientId;
    this.notificationService = notificationService;
    this.dispatch = dispatch;
    this.clientData = clientData;
    if (notificationService) {
      this.subscriptions.push(
        notificationService.subscribe(
          {
            topic: 'client-lamps-changed',
            clientId,
          },
          async (result) => {
            this.onClientLampsChanged(result);
          }
        )
      );
      this.subscriptions.push(
        notificationService.subscribe(
          {
            topic: 'program-status-changed',
            clientId,
          },
          async (result) => {
            this.onProgramStatusChanged(result);
          }
        )
      );
    }
  }

  public get accountingBalances(): AccountingBalancesDataLayer {
    if (!this._accountingBalances) {
      this._accountingBalances = new AccountingBalancesDataLayerService(
        this.clientId,
        this.notificationService
      );
    }
    return this._accountingBalances;
  }

  public get reconciliation(): ReconciliationDataLayer {
    if (!this._reconciliation) {
      this._reconciliation = new ReconciliationDataLayerService(
        this.dispatch,
        this.clientId,
        this.clientData.pipe(
          map((value) => value?.rawFinancialYears),
          filter((value) => value !== undefined),
          distinctUntilChanged()
        ),
        this.notificationService
      );
    }
    return this._reconciliation;
  }

  private async onClientLampsChanged(
    result: Result<WSNotification, NotificationErrors>
  ): Promise<void> {
    if (result.ok) {
      const financialYears = await firstValueFrom(
        this.clientData.pipe(
          map((value) => value?.rawFinancialYears),
          filter((value) => value !== undefined),
          distinctUntilChanged()
        )
      );
      const periods = financialYears.flatMap((fy) => fy.periods ?? []);
      if (result.val.topic === 'client-lamps-changed') {
        const message = result.val;
        batch(() => {
          message.periods.forEach((period) => {
            const existingPeriod = periods.find(
              (p) => p.id === period.periodId
            );
            if (existingPeriod) {
              this.dispatch(
                setClientLampStatus(
                  period.lamps,
                  reformatISODate(existingPeriod.start, 'yyyyMMdd'),
                  message.clientId
                )
              );
            }
          });
        });
      }
    }
  }

  private onProgramStatusChanged(
    result: Result<WSNotification, NotificationErrors>
  ): void {
    if (result.ok) {
      const message = result.val;
      if (message.topic === 'program-status-changed') {
        batch(() => {
          message.status.forEach((ps) => {
            if (ps.period && ps.periodId) {
              this.dispatch(
                addProgramPeriodStatus(message.clientId, {
                  status: ps.status,
                  previousStatus: ps.previousStatus,
                  reason: ps.reason,
                  createdAt: ps.createdAt,
                  createdBy: `${ps.givenName} ${ps.familyName}`,
                  period: ps.period,
                  periodId: ps.periodId,
                })
              );
            }
          });
        });
      }
    }
  }

  release(): void {
    if (this._accountingBalances) {
      this._accountingBalances.release();
    }
    if (this._reconciliation) {
      this._reconciliation.release();
    }
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}

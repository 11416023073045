import { id, or, ref, sum, table, value } from '@agoy/document';
import { IxbrlCell, NoteOneTableWithSum } from '../../../../common';
import { year } from '../../../../common/utils/date-util';
import {
  active,
  field,
  ixbrlCell,
  ixbrlField,
  tupleRef,
} from '../../../../common/utils/util';
import { sumAccountsUBs } from '../../../../common/utils/accountSumsUtil';
import { NoteAccountRanges } from '../types';

const MONETARY_ITEM_TYPE = 'monetaryItemType';

export const note66Accounts: NoteAccountRanges[] = [
  {
    rowId: '1',
    ranges: [[2910, 2919]],
    cellLabel: 'Upplupna löner',
    action: 'sumAccountsUBs',
  },
  {
    rowId: '2',
    ranges: [[2920, 2929]],
    cellLabel: 'Upplupna semesterlöner',
    action: 'sumAccountsUBs',
  },
  {
    rowId: '3',
    ranges: [[2940, 2949]],
    cellLabel: 'Upplupna sociala avgifter',
    action: 'sumAccountsUBs',
  },
  {
    rowId: '4',
    ranges: [[2960, 2969]],
    cellLabel: 'Upplupna räntekostnader',
    action: 'sumAccountsUBs',
  },
  {
    rowId: '5',
    ranges: [[2970, 2979]],
    cellLabel: 'Förutbetalda intäkter',
    action: 'sumAccountsUBs',
  },
  {
    rowId: '6',
    ranges: [[2971]],
    cellLabel: 'Förutbetalda hyresintäkter',
    action: 'sumAccountsUBs',
  },
  {
    rowId: '7',
    ranges: [[2990, 2999]],
    cellLabel: 'Övriga upplupna kostnader och förutbetalda intäkter',
    action: 'sumAccountsUBs',
  },
];

export const getNote66 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null,
  documentTypeVersion = '1'
): NoteOneTableWithSum => {
  return {
    active: false,
    number: value(undefined),
    name: value('Upplupna kostnader och förutbetalda intäkter'),
    table: table<IxbrlCell>(
      'notes.note66.table',
      {
        id: 'label',
        label: '',
      },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      }
    )
      .addRows((rows) =>
        rows
          .addRow(
            '1',
            noteLabel('Upplupna löner'),
            ixbrlCell(ref(sumAccountsUBs('2910:2919', true)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:UpplupnaKostnaderForutbetaldaIntakterUpplupnaLoner',
              contextRef: 'balans0',
              saldo: 'credit',
            })
          )
          .addRow(
            '2',
            noteLabel('Upplupna semesterlöner'),
            ixbrlCell(ref(sumAccountsUBs('2920:2929', true)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:UpplupnaKostnaderForutbetaldaIntakterUpplupnaSemesterloner',
              contextRef: 'balans0',
              saldo: 'credit',
            })
          )
          .addRow(
            '3',
            noteLabel('Upplupna sociala avgifter'),
            ixbrlCell(ref(sumAccountsUBs('2940:2949', true)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:UpplupnaKostnaderForutbetaldaIntakterUpplupnaSocialaAvgifter',
              contextRef: 'balans0',
              saldo: 'credit',
            })
          )
          .addRow(
            '4',
            noteLabel('Upplupna räntekostnader'),
            ixbrlCell(ref(sumAccountsUBs('2960:2969', true)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:UpplupnaKostnaderForutbetaldaIntakterUpplupnaRantekostnader',
              contextRef: 'balans0',
              saldo: 'credit',
            })
          )
          .addRow(
            '5',
            noteLabel('Förutbetalda intäkter'),
            ixbrlCell(ref(sumAccountsUBs('2970:2979', true)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:UpplupnaKostnaderForutbetaldaIntakterForutbetaldaIntakter',
              contextRef: 'balans0',
              saldo: 'credit',
            })
          )
          .addRow(
            '6',
            noteLabel('Förutbetalda hyresintäkter'),
            ixbrlCell(ref(sumAccountsUBs('2971', true)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:UpplupnaKostnaderForutbetaldaIntakterForutbetaldaHyresintakter',
              contextRef: 'balans0',
              saldo: 'credit',
            })
          )
          .addRow(
            '7',
            ixbrlCell(
              value('Övriga upplupna kostnader och förutbetalda intäkter'),
              {
                type: 'stringItemType',
                name: 'se-gen-base:OvrigaUpplupnaKostnaderForutbetaldaIntakterInterimspost',
                contextRef: 'balans0',
                tuple: {
                  name: 'se-gaap-ext:OvrigaUpplupnaKostnaderForutbetaldaIntakterTuple',
                  id: `${rows.getBaseId()}.7.tuple`,
                  tupleID: `${rows.getBaseId()}.7.tuple`,
                },
                tupleRef: tupleRef(1, `${rows.getBaseId()}.7.tuple`),
              }
            ),
            ixbrlCell(ref(sumAccountsUBs('2990:2999', true)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OvrigaUpplupnaKostnaderForutbetaldaIntakter',
              contextRef: 'balans0',
              saldo: 'credit',
              tupleRef: tupleRef(2, `${rows.getBaseId()}.7.tuple`),
            })
          )
          .build()
      )
      .newRowTemplateGenerator((id, baseId) => ({
        id,
        active: true,
        cells: {
          label: ixbrlCell(value(''), {
            type: 'stringItemType',
            name: 'se-gen-base:OvrigaUpplupnaKostnaderForutbetaldaIntakterInterimspost',
            contextRef: 'balans0',
            tuple: {
              name: 'se-gaap-ext:OvrigaUpplupnaKostnaderForutbetaldaIntakterTuple',
              id: `${baseId}.${id}.tuple`,
              tupleID: `${baseId}.${id}.tuple`,
            },
            tupleRef: tupleRef(1, `${baseId}.${id}.tuple`),
          }),
          year: ixbrlCell(value(undefined), {
            type: MONETARY_ITEM_TYPE,
            name: 'se-gen-base:OvrigaUpplupnaKostnaderForutbetaldaIntakter',
            contextRef: 'balans0',
            saldo: 'credit',
            tupleRef: tupleRef(2, `${baseId}.${id}.tuple`),
          }),
        },
      }))
      .build(),
    sumTable: table(
      'notes.note66.sumTable',
      { id: 'label', label: '' },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      }
    )
      .addRows((rows) =>
        rows
          .addRow(
            'sum',
            noteLabel('Summa'),
            ixbrlCell(ref(or(sum(id('notes.note66.table.*.year')), 0)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:UpplupnaKostnaderForutbetaldaIntakter',
              contextRef: 'balans0',
            })
          )
          .build()
      )
      .build(),
    commentLabel: field('Kommentar till not'),
    comment: ixbrlField(active(field('')), {
      type: 'stringItemType',
      name: 'se-gen-base:NotUpplupnaKostnaderForutbetaldaIntakterKommentar',
      contextRef: 'period0',
    }),
    group: value(undefined),
    section: value(undefined),
    validForReportType: {
      k2: true,
      k3: documentTypeVersion === '1' ? true : false,
    },
  };
};

import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import config from '_shared/services/config';

let userPool: AmazonCognitoIdentity.CognitoUserPool;

export const createUserPool = (): AmazonCognitoIdentity.CognitoUserPool => {
  if (!userPool) {
    userPool = new AmazonCognitoIdentity.CognitoUserPool({
      UserPoolId: config.userPoolId,
      ClientId: config.clientId,
    });
  }
  return userPool;
};

export const logOut = (): boolean => {
  const cognitoUser = createUserPool().getCurrentUser();
  if (cognitoUser != null) {
    cognitoUser.signOut();
    return true;
  }
  return false;
};

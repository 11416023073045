/*
 * This is a GENERATED file, do not change manually!
 * Generated:
 * - from file k2-arsredovisning-2021-10-31-rev20230131_sv - Noter.csv
 * - Comments: nan
 */

import {
  value,
  AgoyDocumentPart,
} from '@agoy/document';
import { IxbrlCell } from '../../../../../common';
import {
  field,
  ixbrlCell,
  ixbrlField,
  table,
} from '../../../../../common/utils/util';
import { previousYear, year } from '../../../../../common/utils/date-util';



const cell = { type: 'field' as const };
const boolean = { type: 'boolean' as const };
const type = { type: 'type' as const };
const number = { type: 'number' as const };

export const getNote2083K2v2 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null
): AgoyDocumentPart<typeof note2083K2v2ContentDefinition> => ({
  active: false,
  number: value(undefined),
  name: noteLabel('Eventualförpliktelser'),
  data: {
    active: true,
    EventualForpliktelser: {
      type: 'group',
      active: true,
      level: 0,
      title: field('Eventualförpliktelser'),
      table: table<IxbrlCell>(
        'notes.note83.data.EventualForpliktelser.table',
        {
          id: 'EventualForpliktelser',
          label: '',
          dataType: 'text',
          active: true,
        },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) ?? '',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) ?? '',
          dataType: 'numeric',
          active: true,
        }
      )
        .addRows((rows) => {
          rows.addRowWithType(
            'EventualForpliktelser',
            'row',
            noteLabel('Eventualförpliktelser'),
            ixbrlCell(value(undefined), {
              contextRef: 'balans0',
              type: 'monetaryItemType',
              unitRef: 'SEK',
              name: 'se-gen-base:EventualForpliktelser',
              standardRubrik: 'Eventualförpliktelser',
              negateValue: false,
            }),
            ixbrlCell(value(undefined), {
              contextRef: 'balans1',
              type: 'monetaryItemType',
              unitRef: 'SEK',
              name: 'se-gen-base:EventualForpliktelser',
              standardRubrik: 'Eventualförpliktelser',
              negateValue: false,
            })
          );
          return rows.build();
        })
        .build(),
    },
    NotEventualForpliktelserKommentar: {
      type: 'group',
      active: true,
      level: 0,
      title: field('Kommentar till specifikation av eventualförpliktelser'),
      value: ixbrlField(field(''), {
        contextRef: 'balans0',
        type: 'stringItemType',
        name: 'se-gen-base:NotEventualForpliktelserKommentar',
        standardRubrik: 'Kommentar till specifikation av eventualförpliktelser',
      }),
    },
  },
  group: value(undefined),
  section: value(undefined),
  validForReportType: {
    k2: true,
    k3: true,
  },
});
export const note2083K2v2ContentDefinition = {
  type: 'part' as const,
  children: {
    active: boolean,
    number: cell,
    name: cell,
    data: {
      type: 'part' as const,
      children: {
        active: boolean,

        EventualForpliktelser: {
          type: 'part' as const,
          children: {
            type: type,
            level: number,
            active: boolean,

            title: cell,

            table: { type: 'table' as const },
          },
        },

        NotEventualForpliktelserKommentar: {
          type: 'part' as const,
          children: {
            type: type,
            level: number,
            active: boolean,

            title: cell,

            value: cell,
          },
        },
      },
    },
    group: cell,
    section: cell,
    validForReportType: {
      type: 'part' as const,
      children: {
        k2: boolean,
        k3: boolean,
      },
    },
  },
};

import React, { useState, useCallback, useContext, useMemo } from 'react';
import styled from '@emotion/styled';
import {
  IconButton,
  List,
  ListItem,
  Divider,
  ClickAwayListener,
} from '@material-ui/core';
import Tooltip from '_shared/components/Tooltip';
import { MoreHoriz, Check } from '@material-ui/icons';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import {
  setPeriodChangeVisibility,
  setPeriodUBVisibility,
  setMovingAccountsMode,
} from 'redux/actions';
import { useSelector } from 'redux/reducers';
import { getClasses } from '@agoy/common';
import Typography from '_shared/components/Typography/Typography';
import HiddenRowsContext from '_reconciliation/components/ReconciliationView/RowContext/HiddenRowsContext';
import MoveAccountsContext from '_reconciliation/components/ReconciliationView/RowContext/MoveAccountsContext';
import { ClientCompany } from '@agoy/api-sdk-core';

const StyledIconButton = styled(IconButton)`
  &.active {
    border: 2px solid ${({ theme }) => theme.palette.warning.main};
    margin-right: -2px;
    border-radius: 50px;

    svg {
      color: ${({ theme }) => theme.palette.warning.main};
    }
  }
`;

const MoreHorizIcon = styled(MoreHoriz)`
  color: white;
`;

const Popup = styled(List)`
  position: absolute;
  width: max-content;
  border-radius: 8px;
  min-width: 250px;
  left: 400px;
  top: 35px;
  padding: 8px 0px;
  background-color: white;
  z-index: 15;
  box-shadow: ${(props) => props.theme.shadows[6]};
`;

const StyledListItem = styled(ListItem)`
  padding: 8px 16px;
  column-gap: 8px;
`;

const StyledDivider = styled(Divider)`
  margin: 8px 16px;
`;

const CheckIcon = styled(Check)`
  font-size: 20px;
  &.isHidden {
    visibility: hidden;
  }
`;

type Props = {
  clientId: string;
  financialYear: ClientCompany['financialYears'][0];
};

const HeaderPopupMenu = ({ clientId, financialYear }: Props) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const { removeAllHiddenRows } = useContext(HiddenRowsContext);
  const { localMovedAccounts, saveModalIsOpen, setSaveModalIsOpen } =
    useContext(MoveAccountsContext);

  const [isPopupShown, setPopupShown] = useState(false);

  const { periodChangeVisible, periodUBVisible, movingAccountsMode } =
    useSelector((state) => state.accountingView);

  const periodStatuses = useSelector(
    (state) => state.accountingView.clients[clientId]?.periodStatus
  );

  const periodsAreLocked = useMemo(() => {
    if (!periodStatuses) {
      return false;
    }
    const periods = financialYear.periods?.filter(
      (period) => period.type === 'month'
    );
    return periods?.every(
      (period) => periodStatuses[period.id]?.status === 'LOCKED'
    );
  }, [financialYear.periods, periodStatuses]);

  const allOptionsVisible = periodChangeVisible && periodUBVisible;

  const enableChangeVisibility = useCallback(() => {
    dispatch(setPeriodChangeVisibility(true));
    dispatch(setPeriodUBVisibility(false));
  }, [dispatch]);

  const enableUBVisibility = useCallback(() => {
    dispatch(setPeriodChangeVisibility(false));
    dispatch(setPeriodUBVisibility(true));
  }, [dispatch]);

  const enableAllVisibility = useCallback(() => {
    dispatch(setPeriodChangeVisibility(true));
    dispatch(setPeriodUBVisibility(true));
  }, [dispatch]);

  const toggleMovingMode = useCallback(() => {
    if (periodsAreLocked) return;
    if (!saveModalIsOpen && Object.keys(localMovedAccounts).length > 0) {
      setSaveModalIsOpen(true);
    } else {
      dispatch(setMovingAccountsMode(!movingAccountsMode));
      removeAllHiddenRows();
    }
  }, [
    dispatch,
    localMovedAccounts,
    movingAccountsMode,
    removeAllHiddenRows,
    saveModalIsOpen,
    setSaveModalIsOpen,
    periodsAreLocked,
  ]);

  return (
    <>
      <StyledIconButton
        size="small"
        className={getClasses({ active: isPopupShown })}
        onClick={() => setPopupShown(!isPopupShown)}
      >
        <MoreHorizIcon />
      </StyledIconButton>

      {isPopupShown && (
        <ClickAwayListener onClickAway={() => setPopupShown(false)}>
          <Popup>
            <StyledListItem button onClick={enableAllVisibility}>
              <CheckIcon
                className={getClasses({ isHidden: !allOptionsVisible })}
              />
              <Typography margin="none">
                {formatMessage({ id: 'reconciliation.header.both' })}
              </Typography>
            </StyledListItem>
            <StyledListItem button onClick={enableChangeVisibility}>
              <CheckIcon
                className={getClasses({
                  isHidden: allOptionsVisible || !periodChangeVisible,
                })}
              />
              <Typography margin="none">
                {formatMessage({ id: 'reconciliation.header.change' })}
              </Typography>
            </StyledListItem>
            <StyledListItem button onClick={enableUBVisibility}>
              <CheckIcon
                className={getClasses({
                  isHidden: allOptionsVisible || !periodUBVisible,
                })}
              />
              <Typography margin="none">
                {formatMessage({ id: 'reconciliation.header.ub' })}
              </Typography>
            </StyledListItem>

            <StyledDivider />
            <Tooltip
              placement="right"
              title={
                periodsAreLocked
                  ? formatMessage({
                      id: 'reconciliation.header.movingAccounts.tooltip',
                    })
                  : ''
              }
            >
              <StyledListItem button onClick={toggleMovingMode}>
                <CheckIcon
                  className={getClasses({
                    isHidden: !movingAccountsMode,
                  })}
                />

                <Typography
                  margin="none"
                  color={periodsAreLocked ? 'disabled' : 'onLight'}
                >
                  {formatMessage({
                    id: 'reconciliation.header.movingAccounts',
                  })}
                </Typography>
              </StyledListItem>
            </Tooltip>
          </Popup>
        </ClickAwayListener>
      )}
    </>
  );
};

export default HeaderPopupMenu;

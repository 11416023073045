import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { setCurrentPersonWithYear } from '_person/redux/persons/actions';
import { ClientPersonsRouteParams } from 'routes/types';
import { setUiStatus } from 'redux/actions/UI';

/**
 * Loading product view for individual person HOC
 *
 * Populates redux with data needed for products view
 */
const withReduxSeeding = (Component) => (props) => {
  const dispatch = useDispatch();
  const {
    params: { personId },
  } = useRouteMatch<ClientPersonsRouteParams>();

  useEffect(() => {
    const bootstrapData = async () => {
      try {
        dispatch(setUiStatus({ fetchingPersons: true }));
        await dispatch(setCurrentPersonWithYear(personId));
      } finally {
        dispatch(setUiStatus({ fetchingPersons: false }));
      }
    };

    bootstrapData();
  }, [dispatch, personId]);

  return <Component {...props} />;
};

export default withReduxSeeding;

/* eslint-disable class-methods-use-this */
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  asyncScheduler,
  BehaviorSubject,
  combineLatest,
  map,
  of,
  skip,
  throttleTime,
  distinctUntilChanged,
  withLatestFrom,
} from 'rxjs';
import { DocumentDataService, TimePeriod } from '@agoy/document';
import {
  AnnualReportDataService,
  ReactiveAnnualReportDataService,
  annualReportSignaturesContentDefinition,
  annualReportSignaturesDocumentConfig,
  annualGeneralMeetingContentDefinition,
  annualGeneralMeetingDocumentConfig,
  confirmationCertificateContentDefinition,
  confirmationCertificateDocumentConfig,
  annualGeneralMeetingSignaturesContentDefinition,
  annualGeneralMeetingSignaturesDocumentConfig,
} from '@agoy/annual-report-document';
import { reformat } from '@agoy/dates';
import { useDispatch } from 'react-redux';
import { addGlobalErrorMessage } from 'redux/actions';
import { asResultClass, useApiSdk } from 'api-sdk';
import { ClientFinancialYears } from '_clients/types/types';
import { useSelector } from 'redux/reducers';
import { createGenericDocumentDataService } from '_shared/services/document/createDocumentDataService';
import { NotificationContext } from '_shared/services/Notifications/NotificationsContext';
import createClientInformationTables from 'utils/mapDirectors';
import { setAnnualReportChanges } from '_annual-report/redux/annual-report/actions';
import { ClientInfoContext } from '_clients/context/ClientInformationContext';
import PrintStateContext from '_shared/components/PrintedDocument/PrintStateContext';

import { ChangesSourceContext } from './AnnualReportChangesDataSource';
import { AccountingBalancesSourceContext } from './AccountingBalancesSource';
import { ClientSourceContext } from './ClientSource';
import { isAnnualReportPrintState } from '../components/AnnualReportView/AnnualReportPrintState';

export type AnnualReportDataServiceContextType = {
  /**
   * Annual report data service
   */
  annualReport: AnnualReportDataService;

  /**
   *
   */
  annualReportSignatures?: DocumentDataService<
    typeof annualReportSignaturesContentDefinition
  >;

  /**
   * Data service for the Årsstämmoprotokoll document (unless that section is in annualReport)
   */
  annualGeneralMeeting?: DocumentDataService<
    typeof annualGeneralMeetingContentDefinition
  >;

  /**
   * Data service for the Årsstämmoprotokoll signatures document
   */
  annualGeneralMeetingSignatures?: DocumentDataService<
    typeof annualGeneralMeetingSignaturesContentDefinition
  >;

  /**
   * Data service for the Fastställelseintyg document, including submission (unless that section is in annualReport)
   */
  confirmationCertificate?: DocumentDataService<
    typeof confirmationCertificateContentDefinition
  >;
};

const AnnualReportDataServiceContext =
  createContext<AnnualReportDataServiceContextType>({
    annualReport: {} as AnnualReportDataService,
  });

type ProviderProps = {
  clientId: string;
  financialYear: ClientFinancialYears[number];
  children: JSX.Element;
  previousFinancialYear?: string;
};

export const Provider = ({
  clientId,
  financialYear,
  children,
  previousFinancialYear,
}: ProviderProps): JSX.Element | null => {
  const sdk = useApiSdk();
  const notificationService = useContext(NotificationContext);
  const dispatch = useDispatch();

  const balancesSource = useContext(AccountingBalancesSourceContext);
  const changesSource = useContext(ChangesSourceContext);
  const clientSource = useContext(ClientSourceContext);
  const { clientInformation } = useContext(ClientInfoContext);

  const { currentPeriod, currentFinancialYearId, currentFinancialYear } =
    useSelector((state) => state.customerView);
  const { type } = useSelector((state) => state.customers[clientId]);

  const [contextValue, setContextValue] =
    useState<AnnualReportDataServiceContextType | null>(null);

  const mappedClientInformationTables = useMemo(() => {
    const info = clientInformation[clientId];
    if (!info) {
      return undefined;
    }
    return createClientInformationTables(info);
  }, [clientInformation, clientId]);

  useEffect(() => {
    if (!currentFinancialYear) {
      return () => {};
    }
    const financialYearPeriod = TimePeriod.fromISODates(financialYear);

    const periodObj =
      currentPeriod &&
      financialYear.periods?.find(
        (p) => p.start === reformat(currentPeriod, 'yyyyMMdd', 'yyyy-MM-dd')
      );
    const period = periodObj
      ? TimePeriod.fromISODates(periodObj)
      : financialYearPeriod;
    const currentYearSieData = balancesSource.get(
      clientId,
      financialYearPeriod.value
    );
    const previousYearSieData = previousFinancialYear
      ? balancesSource.get(clientId, previousFinancialYear)
      : new BehaviorSubject(null);

    const changes = changesSource.get(clientId, financialYearPeriod.value);

    const documentIdSubscription = changes
      .pipe(
        map((value) => value.documentId),
        distinctUntilChanged()
      )
      .subscribe((documentId) => {
        const newService = new ReactiveAnnualReportDataService(
          clientId,
          documentId,
          financialYearPeriod,
          period.start === financialYearPeriod.end
            ? financialYearPeriod
            : period,
          changes.pipe(map((value) => value)),
          changes.pipe(map((value) => value.documentConfiguration)),
          clientSource.get(clientId, financialYearPeriod.value),
          currentYearSieData,
          previousYearSieData,
          undefined,
          mappedClientInformationTables
        );

        setContextValue((value) => ({ ...value, annualReport: newService }));

        //
        // Connect a subscription to save the changes
        //
        const saveSubscription = combineLatest({
          documentConfiguration: newService.documentConfiguration,
          changes: newService.changes,
        })
          .pipe(
            skip(1),
            throttleTime(2000, asyncScheduler, { trailing: true }),
            withLatestFrom(newService.locked)
          )
          .subscribe(async ([value, locked]) => {
            changesSource.set(clientId, financialYearPeriod.value, {
              changes: value.changes,
              documentConfiguration: value.documentConfiguration,
              locked,
              documentId,
            });
          });

        const lockedSubscription = combineLatest({
          locked: newService.locked,
        })
          .pipe(
            skip(1),
            throttleTime(2000, asyncScheduler, { trailing: true }),
            withLatestFrom(newService.documentConfiguration, newService.changes)
          )
          .subscribe(async ([value, documentConfiguration, latestChanges]) => {
            dispatch(
              setAnnualReportChanges(
                clientId,
                financialYearPeriod.value,
                documentConfiguration,
                latestChanges,
                undefined,
                value.locked
              )
            );
          });

        const documentVariantSubscription = changes
          .pipe(
            map((value) => value.documentConfiguration),
            distinctUntilChanged(
              (a, b) =>
                a.documentType === b.documentType && a.version === b.version
            )
          )
          .subscribe((documentConfiguration) => {
            // First load or documentType/version changed
            if (documentConfiguration.version === '2') {
              // Version 2, we need to load other documents too

              /**
               * Callback to find/create a document
               *
               * @param documentType Type of document, "annualGeneralMeeting" etc.
               * @param documentName Name of document stored in the database if new.
               * @returns The documentId
               */
              const findDocument =
                (documentType: string, documentName: string) =>
                async (): Promise<string> => {
                  let returnDoc;

                  // @ts-ignore https://developer.mozilla.org/en-US/docs/Web/API/Web_Locks_API
                  await navigator.locks.request(
                    `${clientId}.${currentFinancialYear}.${documentType}`,
                    async () => {
                      const docs = await asResultClass(
                        sdk.findAgoyDocument({
                          clientId,
                          financialYear: currentFinancialYear,
                          type: documentType,
                        })
                      );

                      if (docs.ok) {
                        if (docs.val.length === 1) {
                          // Found an existing document.
                          returnDoc = docs.val[0].id;
                          return;
                        }
                        if (docs.val.length === 0) {
                          // Create a new document
                          const doc = await asResultClass(
                            sdk.createAgoyDocument({
                              clientId,
                              requestBody: {
                                name: documentName,
                                type: documentType,
                                financialYear: financialYearPeriod.value,
                              },
                            })
                          );
                          if (doc.err) {
                            throw doc;
                          }

                          returnDoc = doc.val.id;
                          return;
                        }
                        // Sorry, we have create multiple documents
                        // by accident.
                        throw new Error(
                          `Multiple documents of type ${documentType}`
                        );
                      }

                      throw docs;
                    }
                  );
                  return returnDoc;
                };

              const annualReportSignaturesSubject =
                createGenericDocumentDataService(sdk, notificationService, {
                  clientId,
                  structure: annualReportSignaturesContentDefinition,
                  initialDocument: annualReportSignaturesDocumentConfig(
                    documentConfiguration.documentType,
                    documentConfiguration.reportType
                  ),
                  defaultPeriod: financialYearPeriod,
                  findDocument: findDocument(
                    'annualReportSignatures',
                    'Underskrifter Årsredovisning'
                  ),
                  readonly: false,
                });

              annualReportSignaturesSubject.subscribe({
                next: (annualReportSignatures) => {
                  // Dispose of the service at the same time as documentVariantSubscription
                  documentVariantSubscription.add({
                    unsubscribe: () => annualReportSignatures.dispose(),
                  });
                  setContextValue(
                    (value) =>
                      value && {
                        ...value,
                        annualReportSignatures,
                      }
                  );
                },
                error: (err) => {
                  // eslint-disable-next-line no-console
                  console.error(
                    'Error setting up annualGeneralMeeting service',
                    err
                  );
                  dispatch(addGlobalErrorMessage('error'));
                },
              });

              const annualGeneralMeetingServiceSubject =
                createGenericDocumentDataService(sdk, notificationService, {
                  clientId,
                  structure: annualGeneralMeetingContentDefinition,
                  initialDocument: annualGeneralMeetingDocumentConfig(
                    mappedClientInformationTables
                  ),
                  defaultPeriod: financialYearPeriod,
                  findDocument: findDocument(
                    'annualGeneralMeeting',
                    'Årsstämmoprotokoll'
                  ),
                  readonly: false,
                });

              annualGeneralMeetingServiceSubject.subscribe({
                next: (annualGeneralMeeting) => {
                  // Dispose of the service at the same time as documentVariantSubscription
                  documentVariantSubscription.add({
                    unsubscribe: () => annualGeneralMeeting.dispose(),
                  });
                  setContextValue(
                    (value) =>
                      value && {
                        ...value,
                        annualGeneralMeeting,
                      }
                  );
                },
                error: (err) => {
                  // eslint-disable-next-line no-console
                  console.error(
                    'Error setting up annualGeneralMeeting service',
                    err
                  );
                  dispatch(addGlobalErrorMessage('error'));
                },
              });

              const annualGeneralMeetingSignaturesSubject =
                createGenericDocumentDataService(sdk, notificationService, {
                  clientId,
                  structure: annualGeneralMeetingSignaturesContentDefinition,
                  initialDocument:
                    annualGeneralMeetingSignaturesDocumentConfig(),
                  defaultPeriod: financialYearPeriod,
                  findDocument: findDocument(
                    'annualGeneralMeetingSignatures',
                    'Underskrifter Årsstämmoprotokoll'
                  ),
                  readonly: false,
                });

              annualGeneralMeetingSignaturesSubject.subscribe({
                next: (annualGeneralMeetingSignatures) => {
                  // Dispose of the service at the same time as documentVariantSubscription
                  documentVariantSubscription.add({
                    unsubscribe: () => annualGeneralMeetingSignatures.dispose(),
                  });
                  setContextValue(
                    (value) =>
                      value && {
                        ...value,
                        annualGeneralMeetingSignatures,
                      }
                  );
                },
                error: (err) => {
                  // eslint-disable-next-line no-console
                  console.error(
                    'Error setting up annualGeneralMeeting service',
                    err
                  );
                  dispatch(addGlobalErrorMessage('error'));
                },
              });

              const confirmationCertificateServiceSubject =
                createGenericDocumentDataService(sdk, notificationService, {
                  clientId,
                  structure: confirmationCertificateContentDefinition,
                  initialDocument: confirmationCertificateDocumentConfig(
                    documentConfiguration.documentType
                  ),
                  defaultPeriod: financialYearPeriod,
                  findDocument: findDocument(
                    'confirmationCertificate',
                    'Fastställelseintyg'
                  ),
                  readonly: false,
                });

              confirmationCertificateServiceSubject.subscribe({
                next: (confirmationCertificate) => {
                  // Dispose of the service at the same time as documentVariantSubscription
                  documentVariantSubscription.add({
                    unsubscribe: () => confirmationCertificate.dispose(),
                  });
                  setContextValue(
                    (value) =>
                      value && {
                        ...value,
                        confirmationCertificate,
                      }
                  );
                },
                error: (err) => {
                  // eslint-disable-next-line no-console
                  console.error(
                    'Error setting up confirmationCertificate service',
                    err
                  );
                  dispatch(addGlobalErrorMessage('error'));
                },
              });
            } else {
              // Not version 2 (anymore?)
              setContextValue((value) =>
                value?.annualGeneralMeeting || value?.confirmationCertificate
                  ? { annualReport: value.annualReport }
                  : value
              );
            }
          });

        setTimeout(() => {
          documentIdSubscription.add(() => {
            newService.dispose();
            documentVariantSubscription.unsubscribe();

            // Delay the unsubscribe to saving due to the time throttle
            setTimeout(() => saveSubscription.unsubscribe(), 2100);
            setTimeout(() => lockedSubscription.unsubscribe(), 2100);
          });
        }, 0);
      });
    //
    // Clean up
    //
    return () => {
      documentIdSubscription.unsubscribe();
    };
  }, [
    sdk,
    dispatch,
    clientId,
    financialYear,
    previousFinancialYear,
    changesSource,
    balancesSource,
    clientSource,
    currentPeriod,
    currentFinancialYear,
    currentFinancialYearId,
    type,
    notificationService,
    mappedClientInformationTables,
  ]);

  useEffect(() => {}, []);

  if (contextValue === null) {
    return null;
  }
  return (
    <AnnualReportDataServiceContext.Provider value={contextValue}>
      {children}
    </AnnualReportDataServiceContext.Provider>
  );
};

type PrintDataProviderProps = {
  children: JSX.Element;
};

export const AnnualReportDataServicePrintProvider = ({
  children,
}: PrintDataProviderProps): JSX.Element | null => {
  const { state } = useContext(PrintStateContext);
  const contextValue = useMemo(() => {
    if (isAnnualReportPrintState(state)) {
      const newService: AnnualReportDataService = {
        clientId: state.clientId,
        documentId: state.annualReport.documentId,
        documentConfiguration: of(state.annualReport.documentConfiguration),
        updateDocumentConfiguration: () => {
          throw new Error('No updates allowed');
        },
        report: of(state.annualReport.report),
        changes: of({}),
        locked: of(false),
        latestLocked: false,
        latestReport: of(state.annualReport.report),
        update: () => {
          throw new Error('No updates allowed');
        },
        dispose: () => {},
        updateLocked: () => {},
      };

      const arSignatureService:
        | DocumentDataService<typeof annualReportSignaturesContentDefinition>
        | undefined = state.annualReportSignatures
        ? {
            clientId: state.clientId,
            documentId: state.annualReportSignatures.documentId,
            changes: of({}),
            locked: of(false),
            latestLocked: false,
            report: of(state.annualReportSignatures.document),
            dispose() {},
            latestReport: of(state.annualReportSignatures.document),
            update() {},
            updateLocked: () => {},
          }
        : undefined;

      const agmService:
        | DocumentDataService<typeof annualGeneralMeetingContentDefinition>
        | undefined = state.annualGeneralMeeting
        ? {
            clientId: state.clientId,
            documentId: state.annualGeneralMeeting.documentId,
            changes: of({}),
            locked: of(false),
            latestLocked: false,
            report: of(state.annualGeneralMeeting.document),
            dispose() {},
            latestReport: of(state.annualGeneralMeeting.document),
            update() {},
            updateLocked: () => {},
          }
        : undefined;

      const ccService:
        | DocumentDataService<typeof confirmationCertificateContentDefinition>
        | undefined = state.confirmationCertificate
        ? {
            clientId: state.clientId,
            documentId: state.confirmationCertificate.documentId,
            changes: of({}),
            locked: of(false),
            latestLocked: false,
            report: of(state.confirmationCertificate.document),
            dispose() {},
            latestReport: of(state.confirmationCertificate.document),
            update() {},
            updateLocked: () => {},
          }
        : undefined;

      return {
        annualReport: newService,
        annualReportSignatures: arSignatureService,
        annualGeneralMeeting: agmService,
        confirmationCertificate: ccService,
      };
    }
    return null;
  }, [state]);

  if (contextValue === null) {
    return null;
  }

  return (
    <AnnualReportDataServiceContext.Provider value={contextValue}>
      {children}
    </AnnualReportDataServiceContext.Provider>
  );
};
export default AnnualReportDataServiceContext;

import React from 'react';

import { Awaited } from '@agoy/common';
import { getApiSdk, useApiSdk } from 'api-sdk';
import { useAsync } from 'utils/hooks';
import LoadingPlaceholder from '_shared/components/LoadingPlaceholder';
import MaintenancePage from '_clients/components/MaintenancePage';

type Sdk = Awaited<ReturnType<typeof getApiSdk>>;

type ContextType = {
  data: {
    /**
     * Payment status, represents if a given user has paid-access to Agoy.
     *
     * In the future, it will be part of the auth token claims.
     */
    hasAccess: boolean;
  };
  /**
   * Re-fetches the payment status (context data) from the API
   */
  reloadPaymentStatus: () => Promise<void>;
};

const Context = React.createContext<ContextType | undefined>(undefined);

type DepsType = {
  fetchHasPaidAccess: Sdk['getOrganisationPaidAccess'];
};

/**
 * Injects the payment status context into React, and manages its livecycle.
 */
const PaymentStatusProvider = ({
  dependencies,
  children,
}: {
  dependencies: DepsType;
  children: React.ReactNode;
}) => {
  const { data, status, setData, run } = useAsync({
    hasAccess: false,
  } as ContextType['data']);

  React.useEffect(() => {
    run(dependencies.fetchHasPaidAccess()).catch(console.error);
  }, []);

  const reloadPaymentStatus = React.useCallback(async () => {
    setData(await dependencies.fetchHasPaidAccess());
  }, [dependencies, setData]);

  const value: ContextType = React.useMemo(
    (): ContextType => ({
      data,
      reloadPaymentStatus,
    }),
    [data, reloadPaymentStatus]
  );

  if (status === 'pending' || status === 'idle') {
    return <LoadingPlaceholder />;
  }

  if (status === 'rejected') {
    return <MaintenancePage />;
  }

  if (status === 'resolved') {
    return <Context.Provider value={value}>{children}</Context.Provider>;
  }

  throw new Error(`Unhandled status: ${status}`);
};

/**
 * Inject the payment status context
 */
const withPaymentStatusContext = (Component) => (props) => {
  const sdk = useApiSdk();

  return (
    <PaymentStatusProvider
      dependencies={{ fetchHasPaidAccess: sdk.getOrganisationPaidAccess }}
    >
      <Component {...props} />
    </PaymentStatusProvider>
  );
};

/**
 * Exposes the payment status server-state, relative to the current organisation.
 */
function usePaymentStatus() {
  const context = React.useContext(Context);
  if (context === undefined) {
    throw new Error(
      'usePaymentStatus must be used within the PaymentStatusProvider provider'
    );
  }
  return context;
}

export { withPaymentStatusContext, usePaymentStatus, PaymentStatusProvider };

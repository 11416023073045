import {
  id,
  account,
  sum,
  or,
  multiply,
  label,
  ref,
  value,
  refs,
  ReferenceAccountInformation,
  TimePeriod,
  AccountValueType,
} from '@agoy/document';
import { IncomeStatement } from '../../common/types/incomeStatement';
import {
  addIncomeStatementGroup,
  getRowsFromAccountRanges,
} from '../../common/utils/accountsUtil';
import {
  table,
  RowsBuilder,
  SectionRow,
  getLinkToNote,
} from '../../common/utils/util';

const addPost =
  (
    accounts: Record<string, ReferenceAccountInformation>,
    switchSign: boolean,
    rows: RowsBuilder,
    rowName: string,
    accountModifier: AccountValueType
  ) =>
  (
    rowId: string,
    name: string,
    accountNumbers: [number, number][],
    noteRef?: string
  ): RowsBuilder => {
    rows.addRow(
      rowId,
      value(name),
      noteRef ? refs(noteRef) : refs(), // notes can be linked here
      ref(or(sum(id(`${rows.getBaseId()}.${rowId}.*.year`)), 0)),
      ref(or(sum(id(`${rows.getBaseId()}.${rowId}.*.previousYear`)), 0))
    );
    rows.newRowTemplateGenerator(
      (id, baseId, { label: labelValue, ib, ub }) => ({
        id,
        active: true,
        type: 'account',
        cells: {
          label: label(labelValue),
          year: ub
            ? ref(
                switchSign
                  ? multiply(-1, account(ub, accountModifier, 'year'))
                  : account(ub, accountModifier, 'year')
              )
            : value(0),
          previousYear: ib
            ? ref(
                switchSign
                  ? multiply(-1, account(ib, accountModifier, 'previousYear'))
                  : account(ib, accountModifier, 'previousYear')
              )
            : value(0),
        },
        sortKey: ib ? parseInt(ib) : parseInt(ub),
      })
    );
    rows.addSubRows((rows) => {
      accountNumbers.forEach(([first, last]) =>
        getRowsFromAccountRanges(
          accounts,
          first,
          last,
          rows,
          switchSign,
          'previousYear',
          accountModifier,
          true
        )
      );
      return rows.build();
    });

    return rows;
  };

type IncomeStatementSectionReference =
  | 'incomes'
  | 'operatingCosts'
  | 'financialPosts'
  | 'closingDispositions'
  | 'resultBeforeTax'
  | 'result'
  | 'unassignedAccounts';

export type LimitedCompaniesIncomeStatementStructure = {
  [key in IncomeStatementSectionReference]: SectionRow[];
};

export const limitedCompaniesIncomeStatementAccounts: LimitedCompaniesIncomeStatementStructure =
  {
    incomes: [
      {
        row: '1',
        name: 'Nettoomsättning',
        accounts: [[3000, 3799]],
      },
      {
        row: '2',
        name: 'Förändring av lager av produkter i arbete, färdiga varor och pågående arbete för annans räkning',
        accounts: [
          [4900, 4909],
          [4932, 4959],
          [4970, 4979],
          [4990, 4999],
        ],
      },
      {
        row: '3',
        name: 'Aktiverat arbete för egen räkning',
        accounts: [[3800, 3899]],
      },
      {
        row: '4',
        name: 'Övriga rörelseintäkter',
        accounts: [[3900, 3999]],
      },
    ],
    operatingCosts: [
      {
        row: '1',
        name: 'Råvaror och förnödenheter',
        accounts: [
          [4000, 4899],
          [4910, 4931],
        ],
      },
      {
        row: '2',
        name: 'Handelsvaror',
        accounts: [
          [4960, 4969],
          [4980, 4989],
        ],
      },
      {
        row: '3',
        name: 'Övriga externa kostnader',
        accounts: [[5000, 6999]],
        noteNumber: 45,
      },
      {
        row: '4',
        name: 'Personalkostnader',
        accounts: [[7000, 7699]],
        noteNumber: 2,
      },
      {
        row: '5',
        name: 'Av- och nedskrivningar av materiella och immateriella anläggningstillgångar',
        accounts: [
          [7700, 7739],
          [7750, 7789],
        ],
        noteNumber: 48,
      },
      {
        row: '6',
        name: 'Nedskrivningar av omsättningstillgångar utöver normala nedskrivningar',
        accounts: [
          [7740, 7749],
          [7790, 7799],
        ],
      },
      {
        row: '7',
        name: 'Övriga rörelsekostnader',
        accounts: [[7900, 7999]],
      },
    ],
    financialPosts: [
      {
        row: '1',
        name: 'Resultat från andelar i koncernföretag',
        accounts: [[8000, 8099]],
      },
      {
        row: '2',
        name: 'Resultat från andelar i intresseföretag och gemensamt styrda företag',
        accounts: [
          [8100, 8112],
          [8114, 8117],
          [8119, 8122],
          [8124, 8132],
          [8134, 8169],
          [8190, 8199],
        ],
      },
      {
        row: '3',
        name: 'Resultat från övriga företag som det finns ett ägarintresse i',
        accounts: [
          [8113, 8113],
          [8118, 8118],
          [8123, 8123],
          [8133, 8133],
        ],
      },
      {
        row: '4',
        name: 'Resultat från övriga finansiella anläggningstillgångar',
        accounts: [
          [8200, 8269],
          [8290, 8299],
        ],
      },
      {
        row: '5',
        name: 'Övriga ränteintäkter och liknande resultatposter',
        accounts: [
          [8300, 8369],
          [8390, 8399],
        ],
      },
      {
        row: '6',
        name: 'Nedskrivningar av finansiella anläggningstillgångar och kortfristiga placeringar',
        accounts: [[8270, 8279]],
      },
      {
        row: '7',
        name: 'Räntekostnader och liknande resultatposter',
        accounts: [[8400, 8499]],
      },
    ],
    closingDispositions: [
      {
        row: '1',
        name: 'Erhållna koncernbidrag',
        accounts: [[8820, 8829]],
      },
      {
        row: '2',
        name: 'Lämnade koncernbidrag',
        accounts: [[8830, 8839]],
      },
      {
        row: '3',
        name: 'Förändring av periodiseringsfonder',
        accounts: [[8800, 8819]],
      },
      {
        row: '4',
        name: 'Förändring av överavskrivningar',
        accounts: [[8850, 8859]],
      },
      {
        row: '5',
        name: 'Övriga bokslutsdispositioner',
        accounts: [
          [8840, 8849],
          [8860, 8899],
        ],
      },
    ],
    resultBeforeTax: [
      {
        row: '1',
        name: 'Övriga skatter',
        accounts: [[8980, 8989]],
      },
    ],
    result: [
      {
        row: '1_HideInPreviewAndPrint',
        name: 'Årets resultat',
        accounts: [[8990, 8999]],
      },
    ],
    unassignedAccounts: [
      {
        row: '1',
        name: 'Ej tilldelade konton',
        accounts: [
          [7800, 7899],
          [8170, 8189],
          [8280, 8289],
          [8370, 8389],
          [8500, 8799],
          [8900, 8979],
        ],
      },
    ],
  };

const attachRows = (
  accounts: Record<string, ReferenceAccountInformation>,
  switchSign: boolean,
  rows: RowsBuilder,
  structureKey: keyof LimitedCompaniesIncomeStatementStructure,
  accountModifier: AccountValueType
) => {
  const add = addPost(
    accounts,
    switchSign,
    rows,
    structureKey,
    accountModifier
  );
  limitedCompaniesIncomeStatementAccounts[structureKey].map((section) => {
    const link = getLinkToNote(section, accounts);

    add(section.row, section.name, section.accounts, link);
  });
};

const addIncomes = (
  accounts: Record<string, ReferenceAccountInformation>,
  rows: RowsBuilder,
  accountModifier: AccountValueType
): void => {
  addIncomeStatementGroup(
    rows,
    'incomes',
    'Rörelseintäkter, lagerförändringar m.m.',
    (rows) => attachRows(accounts, true, rows, 'incomes', accountModifier),
    'Summa rörelseintäkter, lagerförändringar m.m.'
  );
};

const addOperatingCosts = (
  accounts: Record<string, ReferenceAccountInformation>,
  rows: RowsBuilder,
  accountModifier: AccountValueType
): void => {
  addIncomeStatementGroup(
    rows,
    'operatingCosts',
    'Rörelsekostnader',
    (rows) =>
      attachRows(accounts, true, rows, 'operatingCosts', accountModifier),
    'Summa rörelsekostnader'
  );
};

const addFinancialPosts = (
  accounts: Record<string, ReferenceAccountInformation>,
  rows: RowsBuilder,
  accountModifier: AccountValueType
): void => {
  addIncomeStatementGroup(
    rows,
    'financialPosts',
    'Finansiella poster',
    (rows) =>
      attachRows(accounts, true, rows, 'financialPosts', accountModifier),
    'Summa finansiella poster'
  );
};

const addClisingDisposition = (
  accounts: Record<string, ReferenceAccountInformation>,
  rows: RowsBuilder,
  accountModifier: AccountValueType
): void => {
  addIncomeStatementGroup(
    rows,
    'closingDispositions',
    'Bokslutsdispositioner',
    (rows) =>
      attachRows(accounts, true, rows, 'closingDispositions', accountModifier),
    'Summa bokslutsdispositioner'
  );
};

const addResultBeforeTax = (
  accounts: Record<string, ReferenceAccountInformation>,
  rows: RowsBuilder,
  accountModifier: AccountValueType
): void => {
  addIncomeStatementGroup(
    rows,
    'resultBeforeTax',
    'Resultat före skatt',
    (rows) =>
      attachRows(accounts, true, rows, 'resultBeforeTax', accountModifier),
    'Summa resultat före skatt'
  );
};

const addResults = (
  accounts: Record<string, ReferenceAccountInformation>,
  rows: RowsBuilder,
  accountModifier: AccountValueType
): void => {
  addIncomeStatementGroup(
    rows,
    'result',
    'Årets resultat',
    // In incomeStatement we usually invert the sign for the UBs from avstämning here
    // but we should not do this for Årets resultat of incomeStatement (accounts 8990 until 8999)
    (rows) => attachRows(accounts, false, rows, 'result', accountModifier),
    ''
  );
};

const addUnassignedAccounts = (
  accounts: Record<string, ReferenceAccountInformation>,
  rows: RowsBuilder,
  accountModifier: AccountValueType
): void => {
  addIncomeStatementGroup(
    rows,
    'unassignedAccounts',
    'Ej tilldelade',
    (rows) =>
      attachRows(accounts, true, rows, 'unassignedAccounts', accountModifier),
    'Ej tilldelade total'
  );
};

export const incomeStatementConfig = (
  accounts: Record<string, ReferenceAccountInformation>,
  period: TimePeriod,
  previousPeriod: TimePeriod | null,
  accountModifier: AccountValueType = 'ub'
): IncomeStatement => ({
  active: undefined,
  section: {
    active: undefined,
    table: table(
      'incomeStatement.section.table',
      'label',
      'notes',
      { id: 'year', label: `${period.startDateISO} ${period.endDateISO}` },
      previousPeriod
        ? {
            id: 'previousYear',
            label: `${previousPeriod.startDateISO} ${previousPeriod.endDateISO}`,
          }
        : undefined,
      'hidden'
    )
      .addRows((rows) =>
        rows
          .addRow('income')
          .addSubRows((rows) => {
            rows.addRow('main').addSubRows((rows) => {
              addIncomes(accounts, rows, accountModifier);
              addOperatingCosts(accounts, rows, accountModifier);

              rows.addRow(
                'operatingResults',
                value('Rörelseresultat'),
                refs(),
                ref(
                  sum(
                    id(`${rows.getBaseId()}.incomesSum.year`),
                    id(`${rows.getBaseId()}.operatingCostsSum.year`)
                  )
                ),
                ref(
                  sum(
                    id(`${rows.getBaseId()}.incomesSum.previousYear`),
                    id(`${rows.getBaseId()}.operatingCostsSum.previousYear`)
                  )
                )
              );

              addFinancialPosts(accounts, rows, accountModifier);

              rows.addRow(
                'resultAfterFinancial',
                value('Resultat efter finansiella poster'),
                refs(),
                ref(
                  sum(
                    id(`${rows.getBaseId()}.operatingResults.year`),
                    id(`${rows.getBaseId()}.financialPostsSum.year`)
                  )
                ),
                ref(
                  sum(
                    id(`${rows.getBaseId()}.operatingResults.previousYear`),
                    id(`${rows.getBaseId()}.financialPostsSum.previousYear`)
                  )
                )
              );
              addClisingDisposition(accounts, rows, accountModifier);
              // Ej tilldelade AKA unassigned accounts
              addUnassignedAccounts(accounts, rows, accountModifier);
              addResultBeforeTax(accounts, rows, accountModifier);
              addResults(accounts, rows, accountModifier);

              return rows.build();
            });

            return rows.build();
          })
          .build()
      )
      .build(),
  },
});

export const incomeStatementReferences = (): Record<string, string> => ({
  'incomeStatement.netSales.year': id(
    'incomeStatement.section.table.income.main.incomes.1.year'
  ),
  'incomeStatement.netSales.previousYear': id(
    'incomeStatement.section.table.income.main.incomes.1.previousYear'
  ),
  'incomeStatement.resultAfterFinancial.year': id(
    'incomeStatement.section.table.income.main.resultAfterFinancial.year'
  ),
  'incomeStatement.resultAfterFinancial.previousYear': id(
    'incomeStatement.section.table.income.main.resultAfterFinancial.previousYear'
  ),
});

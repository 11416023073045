import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import { getOriginalGroup } from '_reconciliation/components/ReconciliationView/RowContext/utils';
import { useAccountDrag } from '_reconciliation/components/ReconciliationView/RowContext/DndHooks';
import { useSelector } from 'redux/reducers';
import { getClasses } from '@agoy/common';
import Tooltip from '_shared/components/Tooltip';

const Container = styled.div<{ accountNumber: string; groupId: string }>`
  height: 100%;
  padding: 2px;

  .hover_group_${({ groupId }) => groupId}.hover_account_${({
      accountNumber,
    }) => accountNumber}
    .account_${({ accountNumber }) => accountNumber}
    & {
    background-color: #f2f4f3;
    .wrapper {
      color: #007031;
    }
  }

  &.draggable {
    cursor: pointer;

    &:hover {
      .wrapper {
        background-color: #0000000d;
        > svg {
          visibility: visible;
        }
      }
    }

    &.isDragging {
      .wrapper {
        color: ${({ theme }) => theme.palette.text.disabled};
        background-color: white;
        > svg {
          visibility: hidden;
        }
      }
    }
  }

  &.isMoved > .wrapper {
    font-style: italic;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: ${({ theme }) => theme.spacing(1)}px;
  height: 100%;
  color: ${({ theme }) => theme.palette.text.primary};
  padding-left: 4px;
  padding-right: 16px;
  border-radius: 4px;
`;

const DragIcon = styled(DragIndicatorIcon)`
  visibility: hidden;
  font-size: 20px;
`;

const AccountName = styled(Typography)`
  color: inherit;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const AccountNumber = styled(Typography)`
  font-weight: 600;
  color: inherit;
`;

export type AccountCellProps = {
  accountNumber: string;
  accountName: string;
  groupId: string;
  companyType: string;
  movingPeriods: string[];
  movedByPeriods?: Set<string>;
};

const AccountCell = ({
  accountNumber,
  accountName,
  groupId,
  companyType,
  movedByPeriods,
  movingPeriods,
}: AccountCellProps) => {
  const { formatMessage } = useIntl();

  const { movingAccountsMode } = useSelector((state) => state.accountingView);

  const isMovingEnabled = movingAccountsMode && !!movingPeriods.length;

  const originalGroup = useMemo(
    () => getOriginalGroup(accountNumber, companyType),
    [accountNumber, companyType]
  );

  const [{ isDragging }, drag] = useAccountDrag(
    accountNumber,
    groupId,
    isMovingEnabled,
    movingPeriods,
    true
  );

  const classes = getClasses({
    isDragging: !!isDragging,
    draggable: isMovingEnabled,
    isMoved: originalGroup !== groupId,
  });

  const getMessage = useCallback(
    (id: string, params?: Record<string, string>) =>
      formatMessage(
        {
          id: `reconciliation.${id}`,
        },
        params
      ),
    [formatMessage]
  );

  const tooltipTitle = useMemo(() => {
    if (movingAccountsMode) {
      return '';
    }

    if (originalGroup !== groupId) {
      return getMessage('movedFromAccount.tooltip', {
        group: getMessage(`row.${originalGroup}`),
      });
    }

    if (movedByPeriods?.size) {
      return getMessage('period.movedToAccount.tooltip', {
        group: [...movedByPeriods]
          .map((item) => getMessage(`row.${item}`))
          .join(', '),
      });
    }

    return '';
  }, [getMessage, groupId, movedByPeriods, movingAccountsMode, originalGroup]);

  return (
    <Tooltip title={tooltipTitle}>
      <Container
        accountNumber={accountNumber}
        groupId={groupId}
        className={`${classes} headerAccount`}
        ref={drag}
      >
        <Wrapper className="wrapper">
          <DragIcon color="inherit" />
          <AccountNumber>{accountNumber}</AccountNumber>
          <AccountName>{accountName}</AccountName>
        </Wrapper>
      </Container>
    </Tooltip>
  );
};

export default AccountCell;

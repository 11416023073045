import { value, ref } from '@agoy/document';
import { NoteOneTableSection, IxbrlCell } from '../../../../common';
import {
  sumAccountsIBs,
  sumPositiveTransactions,
  sumNegativeTransactions,
  sumNotesRowsById,
} from '../../../../common/utils/accountSumsUtil';
import { year, previousYear } from '../../../../common/utils/date-util';
import {
  field,
  ixbrlCell,
  ixbrlField,
  range,
  table,
} from '../../../../common/utils/util';
import { NoteAccountRanges } from '../types';

const MONETARY_ITEM_TYPE = 'monetaryItemType';

export const note13Accounts: NoteAccountRanges[] = [
  {
    rowId: '1',
    ranges: [[1120, 1128]],
    cellLabel: 'Ingående anskaffningsvärden',
    action: 'sumAccountsIBs',
  },
  {
    rowId: '2',
    ranges: [[1120, 1128]],
    cellLabel: 'Inköp',
    action: 'sumPositiveTransactions',
  },
  {
    rowId: '3',
    ranges: [[1120, 1128]],
    cellLabel: 'Försäljningar/utrangeringar',
    action: 'sumNegativeTransactions',
  },
  {
    rowId: '6',
    ranges: [[1129]],
    cellLabel: 'Ingående avskrivningar',
    action: 'sumAccountsIBs',
  },
  {
    rowId: '7',
    ranges: [[1129]],
    cellLabel: 'Försäljningar/utrangeringar',
    action: 'sumPositiveTransactions',
  },
  {
    rowId: '9',
    ranges: [[1129]],
    cellLabel: 'Årets avskrivningar',
    action: 'sumNegativeTransactions',
  },
];

export const getNote13 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null,
  documentTypeVersion = '1'
): NoteOneTableSection => {
  return {
    active: false,
    number: value(undefined),
    name: value('Förbättringsutgifter på annans fastighet'),
    table: table<IxbrlCell>(
      `notes.note13.table`,
      { id: 'label', label: '' },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      .addRows((rows) =>
        rows
          // 'ingående' = previous period outgoing balance
          .addRow(
            '1',
            noteLabel('Ingående anskaffningsvärden'),
            ixbrlCell(ref(sumAccountsIBs('1120:1128', false)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForbattringsutgifterAnnansFastighetAnskaffningsvarden',
              contextRef: 'balans1',
              saldo: 'debit',
            }),
            ixbrlCell(ref(sumAccountsIBs('1120:1128', false, 'previousYear')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForbattringsutgifterAnnansFastighetAnskaffningsvarden',
              contextRef: 'balans2',
              saldo: 'debit',
            })
          )
          .addRow(
            '2',
            noteLabel('Inköp'),
            ixbrlCell(ref(sumPositiveTransactions('1120:1128')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForbattringsutgifterAnnansFastighetForandringAnskaffningsvardenInkop',
              contextRef: 'period0',
              standardRubrik:
                'Ökning av anskaffningsvärden i samband med periodens nedlagda förbättringsutgifter på annans fastighet',
            }),
            ixbrlCell(
              ref(sumPositiveTransactions('1120:1128', 'previousYear')),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:ForbattringsutgifterAnnansFastighetForandringAnskaffningsvardenInkop',
                contextRef: 'period1',
                standardRubrik:
                  'Ökning av anskaffningsvärden i samband med periodens nedlagda förbättringsutgifter på annans fastighet',
              }
            )
          )
          .addRow(
            'businessAcquisitions',
            noteLabel('Rörelseförvärv'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForbattringsutgifterAnnansFastighetForandringAnskaffningsvardenRorelseforvarv',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden för förbättringsutgifter på annans fastighet genom rörelseförvärv',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForbattringsutgifterAnnansFastighetForandringAnskaffningsvardenRorelseforvarv',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden för förbättringsutgifter på annans fastighet genom rörelseförvärv',
            })
          )
          .addRow(
            'anskaffningsvardenFusion',
            noteLabel('Fusion'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForbattringsutgifterAnnansFastighetForandringAnskaffningsvardenFusion',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden för förbättringsutgifter på annans fastighet genom fusion',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForbattringsutgifterAnnansFastighetForandringAnskaffningsvardenFusion',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden för förbättringsutgifter på annans fastighet genom fusion',
            })
          )
          .addRow(
            '3',
            noteLabel('Försäljningar/utrangeringar'),
            ixbrlCell(ref(sumNegativeTransactions('1120:1128')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForbattringsutgifterAnnansFastighetForandringAnskaffningsvardenForsaljningarUtrangeringar',
              contextRef: 'period0',
              standardRubrik:
                'Minskning av anskaffningsvärden i samband med försäljningar eller utrangeringar av förbättringsutgifter på annans fastighet',
              negateValue: true,
            }),
            ixbrlCell(
              ref(sumNegativeTransactions('1120:1128', 'previousYear')),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:ForbattringsutgifterAnnansFastighetForandringAnskaffningsvardenForsaljningarUtrangeringar',
                contextRef: 'period1',
                standardRubrik:
                  'Minskning av anskaffningsvärden i samband med försäljningar eller utrangeringar av förbättringsutgifter på annans fastighet',
                negateValue: true,
              }
            )
          )
          .addRow(
            '4',
            noteLabel('Omklassificeringar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForbattringsutgifterAnnansFastighetForandringAnskaffningsvardenOmklassificeringar',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden i samband med omklassificeringar av förbättringsutgifter på annans fastighet',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForbattringsutgifterAnnansFastighetForandringAnskaffningsvardenOmklassificeringar',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden i samband med omklassificeringar av förbättringsutgifter på annans fastighet',
            })
          )
          .addRow(
            'anskaffningsvardenConversionDifferences',
            noteLabel('Omräkningsdifferenser'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForbattringsutgifterAnnansFastighetForandringAnskaffningsvardenOmrakningsdifferenser',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden i samband med omräkningsdifferenser av förbättringsutgifter på annans fastighet',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForbattringsutgifterAnnansFastighetForandringAnskaffningsvardenOmrakningsdifferenser',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden i samband med omräkningsdifferenser av förbättringsutgifter på annans fastighet',
            })
          )
          .addRow(
            '5',
            noteLabel('Utgående anskaffningsvärden'),
            ixbrlCell(
              sumNotesRowsById(
                13,
                [
                  ...range(1, 5),
                  'businessAcquisitions',
                  'anskaffningsvardenFusion',
                  'anskaffningsvardenConversionDifferences',
                ],
                'year'
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:ForbattringsutgifterAnnansFastighetAnskaffningsvarden',
                contextRef: 'balans0',
                saldo: 'debit',
              }
            ),
            ixbrlCell(
              sumNotesRowsById(
                13,
                [
                  ...range(1, 5),
                  'businessAcquisitions',
                  'anskaffningsvardenFusion',
                  'anskaffningsvardenConversionDifferences',
                ],
                'previousYear'
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:ForbattringsutgifterAnnansFastighetAnskaffningsvarden',
                contextRef: 'balans1',
                saldo: 'debit',
              }
            )
          )
          .addRow(
            '6',
            noteLabel('Ingående avskrivningar'),
            ixbrlCell(ref(sumAccountsIBs('1129', false)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForbattringsutgifterAnnansFastighetAvskrivningar',
              contextRef: 'balans1',
              saldo: 'credit',
              negateValue: true,
            }),
            ixbrlCell(ref(sumAccountsIBs('1129', false, 'previousYear')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForbattringsutgifterAnnansFastighetAvskrivningar',
              contextRef: 'balans2',
              saldo: 'credit',
              negateValue: true,
            })
          )
          .addRow(
            'avskrivningarFusion',
            noteLabel('Fusion'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForbattringsutgifterAnnansFastighetForandringAvskrivningarFusion',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av avskrivningar av förbättringsutgifter på annans fastighet genom fusion',
              negateValue: true,
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForbattringsutgifterAnnansFastighetForandringAvskrivningarFusion',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av avskrivningar av förbättringsutgifter på annans fastighet genom fusion',
              negateValue: true,
            })
          )
          .addRow(
            '7',
            noteLabel('Försäljningar/utrangeringar'),
            ixbrlCell(ref(sumPositiveTransactions('1129')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForbattringsutgifterAnnansFastighetForandringAvskrivningarAterfordaAvskrivningarForsaljningarRespUtrangeringar',
              contextRef: 'period0',
            }),
            ixbrlCell(ref(sumPositiveTransactions('1129', 'previousYear')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForbattringsutgifterAnnansFastighetForandringAvskrivningarAterfordaAvskrivningarForsaljningarRespUtrangeringar',
              contextRef: 'period1',
            })
          )
          .addRow(
            '8',
            noteLabel('Omklassificeringar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForbattringsutgifterAnnansFastighetForandringAvskrivningarOmklassificeringar',
              contextRef: 'period0',
              standardRubrik:
                'Minskning (ökning) av avskrivningar i samband med omklassificeringar av förbättringsutgifter på annans fastighet',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForbattringsutgifterAnnansFastighetForandringAvskrivningarOmklassificeringar',
              contextRef: 'period1',
              standardRubrik:
                'Minskning (ökning) av avskrivningar i samband med omklassificeringar av förbättringsutgifter på annans fastighet',
            })
          )
          .addRow(
            '9',
            noteLabel('Årets avskrivningar'),
            ixbrlCell(ref(sumNegativeTransactions('1129')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForbattringsutgifterAnnansFastighetForandringAvskrivningarAretsAvskrivningar',
              contextRef: 'period0',
              negateValue: true,
            }),
            ixbrlCell(ref(sumNegativeTransactions('1129', 'previousYear')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForbattringsutgifterAnnansFastighetForandringAvskrivningarAretsAvskrivningar',
              contextRef: 'period1',
              negateValue: true,
            })
          )
          .addRow(
            'avskrivningarConversionDifferences',
            noteLabel('Omräkningsdifferenser'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForbattringsutgifterAnnansFastighetForandringAvskrivningarOmrakningsdifferenser',
              contextRef: 'period0',
              standardRubrik:
                'Minskning (ökning) av avskrivningar i samband med omräkningsdifferenser av förbättringsutgifter på annans fastighet',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForbattringsutgifterAnnansFastighetForandringAvskrivningarOmrakningsdifferenser',
              contextRef: 'period1',
              standardRubrik:
                'Minskning (ökning) av avskrivningar i samband med omräkningsdifferenser av förbättringsutgifter på annans fastighet',
            })
          )
          .addRow(
            '10',
            noteLabel('Utgående avskrivningar'),
            ixbrlCell(
              sumNotesRowsById(
                13,
                [
                  ...range(6, 10),
                  'avskrivningarFusion',
                  'avskrivningarConversionDifferences',
                ],
                'year'
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:ForbattringsutgifterAnnansFastighetAvskrivningar',
                contextRef: 'balans0',
                saldo: 'credit',
                negateValue: true,
              }
            ),
            ixbrlCell(
              sumNotesRowsById(
                13,
                [
                  ...range(6, 10),
                  'avskrivningarFusion',
                  'avskrivningarConversionDifferences',
                ],
                'previousYear'
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:ForbattringsutgifterAnnansFastighetAvskrivningar',
                contextRef: 'balans1',
                saldo: 'credit',
                negateValue: true,
              }
            )
          )
          // ID 11 was renamed to totalSum, because it contains the addition
          // of all other sums of note 13
          .addRow(
            '12',
            noteLabel('Ingående nedskrivningar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForbattringsutgifterAnnansFastighetNedskrivningar',
              contextRef: 'balans1',
              saldo: 'credit',
              negateValue: true,
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForbattringsutgifterAnnansFastighetNedskrivningar',
              contextRef: 'balans2',
              saldo: 'credit',
              negateValue: true,
            })
          )
          .addRow(
            'nedskrivningarFusion',
            noteLabel('Fusion'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForbattringsutgifterAnnansFastighetForandringNedskrivningarFusion',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av nedskrivningar för förbättringsutgifter på annans fastighet genom fusion',
              negateValue: true,
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForbattringsutgifterAnnansFastighetForandringNedskrivningarFusion',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av nedskrivningar för förbättringsutgifter på annans fastighet genom fusion',
              negateValue: true,
            })
          )
          .addRow(
            '13',
            noteLabel('Försäljningar/utrangeringar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForbattringsutgifterAnnansFastighetForandringNedskrivningarAterfordaNedskrivningarPaForsaljningarRespUtrangeringar',
              contextRef: 'period0',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForbattringsutgifterAnnansFastighetForandringNedskrivningarAterfordaNedskrivningarPaForsaljningarRespUtrangeringar',
              contextRef: 'period1',
            })
          )
          .addRow(
            '14',
            noteLabel('Återförda nedskrivningar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForbattringsutgifterAnnansFastighetForandringNedskrivningarAterfordaNedskrivningar',
              contextRef: 'period0',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForbattringsutgifterAnnansFastighetForandringNedskrivningarAterfordaNedskrivningar',
              contextRef: 'period1',
            })
          )
          .addRow(
            '15',
            noteLabel('Omklassificeringar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForbattringsutgifterAnnansFastighetForandringNedskrivningarOmklassificeringar',
              contextRef: 'period0',
              standardRubrik:
                'Minskning (ökning) av nedskrivningar i samband med omklassificeringar av förbättringsutgifter på annans fastighet',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForbattringsutgifterAnnansFastighetForandringNedskrivningarOmklassificeringar',
              contextRef: 'period1',
              standardRubrik:
                'Minskning (ökning) av nedskrivningar i samband med omklassificeringar av förbättringsutgifter på annans fastighet',
            })
          )
          .addRow(
            '16',
            noteLabel('Årets nedskrivningar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForbattringsutgifterAnnansFastighetForandringNedskrivningarAretsNedskrivningar',
              contextRef: 'period0',
              negateValue: true,
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForbattringsutgifterAnnansFastighetForandringNedskrivningarAretsNedskrivningar',
              contextRef: 'period1',
              negateValue: true,
            })
          )
          .addRow(
            'nedskrivningarConversionDifferences',
            noteLabel('Omräkningsdifferenser'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForbattringsutgifterAnnansFastighetForandringNedskrivningarOmrakningsdifferenser',
              contextRef: 'period0',
              standardRubrik:
                'Minskning (ökning) av nedskrivningar i samband med omräkningsdifferenser av förbättringsutgifter på annans fastighet',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForbattringsutgifterAnnansFastighetForandringNedskrivningarOmrakningsdifferenser',
              contextRef: 'period1',
              standardRubrik:
                'Minskning (ökning) av nedskrivningar i samband med omräkningsdifferenser av förbättringsutgifter på annans fastighet',
            })
          )
          .addRow(
            '17',
            noteLabel('Utgående nedskrivningar'),
            ixbrlCell(
              sumNotesRowsById(
                13,
                [
                  ...range(12, 17),
                  'nedskrivningarFusion',
                  'nedskrivningarConversionDifferences',
                ],
                'year'
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:ForbattringsutgifterAnnansFastighetNedskrivningar',
                contextRef: 'balans0',
                saldo: 'credit',
                negateValue: true,
              }
            ),
            ixbrlCell(
              sumNotesRowsById(
                13,
                [
                  ...range(12, 17),
                  'nedskrivningarFusion',
                  'nedskrivningarConversionDifferences',
                ],
                'previousYear'
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:ForbattringsutgifterAnnansFastighetNedskrivningar',
                contextRef: 'balans1',
                saldo: 'credit',
                negateValue: true,
              }
            )
          )
          .addRow(
            'totalSum',
            noteLabel('Redovisat värde'),
            ixbrlCell(sumNotesRowsById(13, [5, 10, 17], 'year'), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForbattringsutgifterAnnansFastighet',
              contextRef: 'balans0',
              saldo: 'debit',
            }),
            ixbrlCell(sumNotesRowsById(13, [5, 10, 17], 'previousYear'), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForbattringsutgifterAnnansFastighet',
              contextRef: 'balans1',
              saldo: 'debit',
            })
          )
          .build()
      )
      .newRowTemplate(value(''), value(undefined), value(undefined))
      .build(),
    commentLabel: field('Kommentar'),
    comment: ixbrlField(field(''), {
      type: 'stringItemType',
      name: 'se-gen-base:NotForbattringsutgifterAnnansFastighetKommentar',
      contextRef: 'period0',
    }),
    group: value(undefined),
    section: value(undefined),
    validForReportType: {
      k2: true,
      k3: documentTypeVersion === '1',
    },
  };
};

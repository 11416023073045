/* eslint-disable no-console */
import { useContext, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';

import { reformatISODate } from '@agoy/common';
import {
  increaseDocuments,
  decreaseDocuments,
} from '_reconciliation/redux/accounting-view/actions';
import { clientYear } from '_reconciliation/redux/accounting-view/selectors';
import {
  getDocumentExtension,
  getDocumentNameWithoutExtension,
} from '_reconciliation/components/ReconciliationView/HiddenRow/HiddenGroupRow/utils/documentTypes';
import { PrintState } from '_shared/components/PrintedDocument/PrintStateContext';
import PeriodDataContext from '_reconciliation/components/ReconciliationView/HiddenRow/Rows/PeriodDataContext';
import { FinancialYear, Period } from '@agoy/api-sdk-core';
import { MAX_NUM_DOCUMENTS } from 'contants';
import { asResultClass, useApiSdk } from 'api-sdk';
import { useSelector } from 'redux/reducers';
import { formatCurrentTime } from '@agoy/dates';
import uniqueName from './uniqueName';
import { PeriodDocumentContext, PeriodDocument } from './Provider';

const usePeriodDocuments = () => {
  const sdk = useApiSdk();

  const [state, setState] = useContext(PeriodDocumentContext);

  const {
    clientId,
    period,
    financialYear,
    previousPeriod,
    previousGroupedPeriods,
    previousPeriodFinancialYear,
    lastPeriod,
  } = useContext(PeriodDataContext);

  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const userInputDocuments = useSelector(
    clientYear(
      clientId,
      financialYear,
      (currentState) => currentState.userInputDocuments
    )
  );

  const { documents, account } = state;

  const periodId = period.id;
  const year = reformatISODate(period.start, 'yyyy');
  const month = reformatISODate(period.start, 'MM');

  const addDocument = useCallback(
    async (fileName: string, file: File, id?: number) => {
      if (!clientId || !account) {
        return undefined;
      }
      // Create a unique name for the document. Should probably be done server side
      const currentDocuments = documents.map((document) => document.name);

      const name = getDocumentNameWithoutExtension(fileName);
      const extension = getDocumentExtension(fileName);
      const formattedName = `${name}${extension.toLocaleLowerCase()}`;

      const newDocumentName = uniqueName(
        currentDocuments,
        formattedName
      ).normalize('NFC');

      if (documents.length + 1 > MAX_NUM_DOCUMENTS) return undefined;

      const newDocument: PeriodDocument = {
        url: '',
        name: newDocumentName,
        lastModified: formatCurrentTime(),
        period,
        uploading: true,
        id: id || 0,
      };

      setState((value) => ({
        ...value,
        documents: [newDocument, ...value.documents],
        uploadingDocuments: true,
      }));

      const responseResult = await asResultClass(
        sdk.postPeriodDocument({
          clientid: clientId,
          account,
          periodId,
          name: newDocumentName,
          requestBody: file,
        })
      );

      if (responseResult.err) {
        setState((value) => ({
          ...value,
          documents: value.documents.filter((doc) => !doc.uploading),
          uploadingDocuments: false,
        }));
        return undefined;
      }

      setState((value) => ({
        ...value,
        documents: [
          responseResult.map((doc) => ({
            ...doc,
            period,
          })).val,
          ...value.documents.filter((doc) => !doc.uploading),
        ],
        uploadingDocuments: false,
      }));

      // The number of images for each cell is saved as part of the user input,
      // which in turn is saved in redux. Thus the action here
      dispatch(
        increaseDocuments(
          clientId,
          financialYear,
          account,
          `${year}${month}`,
          periodId
        )
      );

      return responseResult.val;
    },
    [
      clientId,
      account,
      documents,
      period,
      setState,
      year,
      month,
      periodId,
      sdk,
      financialYear,
      dispatch,
    ]
  );

  const deleteDocument = useCallback(
    async (document: PeriodDocument) => {
      if (!clientId || !financialYear || !account) {
        return;
      }
      if (
        // eslint-disable-next-line no-alert
        !window.confirm(
          formatMessage({ id: 'hidden.sureYouWantToRemoveDocument' })
        )
      ) {
        return;
      }

      try {
        await sdk.deletePeriodDocument({
          clientid: clientId,
          documentId: document.id,
          periodId: document.period.id,
        });

        setState((value) => ({
          ...value,
          documents: value.documents.filter((item) => item.id !== document.id),
        }));

        dispatch(
          decreaseDocuments(
            clientId,
            financialYear,
            account,
            `${year}${month}`,
            periodId
          )
        );
      } catch (e) {
        console.error(e);
      }
    },
    [
      clientId,
      financialYear,
      account,
      formatMessage,
      setState,
      year,
      month,
      periodId,
      sdk,
      dispatch,
    ]
  );

  const removeReference = useCallback(
    async (document: PeriodDocument, groupId: string) => {
      if (!financialYear || !account) {
        return;
      }

      try {
        await sdk.deletePeriodDocumentReference({
          clientid: clientId,
          periodId: document.period.id,
          documentId: document.id,
          accountNumber: account,
          accountGroup: groupId,
        });

        setState((value) => ({
          ...value,
          documents: value.documents.filter((item) => item.id !== document.id),
        }));

        dispatch(
          decreaseDocuments(
            clientId,
            financialYear,
            account,
            `${year}${month}`,
            periodId
          )
        );
      } catch (e) {
        console.error(e);
      }
    },
    [
      clientId,
      financialYear,
      account,
      setState,
      year,
      month,
      periodId,
      sdk,
      dispatch,
    ]
  );

  const copyDocument = useCallback(
    async (
      document: PeriodDocument,
      newFinancialYear: FinancialYear,
      newPeriod: Period
    ) => {
      if (!account) {
        return;
      }

      const newMonth = reformatISODate(newPeriod.start, 'MM');
      const newYear = reformatISODate(newPeriod.start, 'yyyy');

      try {
        await sdk.copyPeriodDocument({
          clientid: clientId,
          documentId: document.id,
          name: document.name,
          newAccount: account,
          newPeriodId: newPeriod.id,
        });

        dispatch(
          increaseDocuments(
            clientId,
            newFinancialYear,
            account,
            `${newYear}${newMonth}`,
            newPeriod.id
          )
        );
      } catch (e) {
        console.error(e);
      }
    },
    [account, clientId, dispatch, sdk]
  );

  const renameDocument = useCallback(
    async (document: PeriodDocument, newName: string) => {
      try {
        const newDocument = await sdk.renamePeriodDocument({
          clientid: clientId,
          periodId: document.period.id,
          documentId: document.id,
          newName,
        });

        setState((value) => ({
          ...value,
          documents: value.documents.map((item) => {
            if (item.id === newDocument.id) {
              return { ...newDocument, period: document.period };
            }
            return item;
          }),
        }));
      } catch (e) {
        console.error(e);
      }
    },
    [clientId, account, setState, sdk]
  );

  const copyDocumentsFromPreviousPeriod = useCallback(async () => {
    if (
      !account ||
      !previousPeriodFinancialYear ||
      !previousGroupedPeriods ||
      !lastPeriod ||
      !period
    ) {
      return;
    }

    const previousPeriods =
      period.type === 'year_end' ? [lastPeriod] : previousGroupedPeriods;

    try {
      const results = await sdk.getPeriodDocuments({
        clientid: clientId,
        account: [account],
        periodId: previousPeriods.map((p) => p.id),
      });

      const filteredDocuments = previousPeriods.flatMap(
        (p) =>
          results
            .filter((doc) =>
              doc.references.find(
                (ref) =>
                  ref.type === 'A' &&
                  ref.key === account &&
                  ref.periodIds.includes(p.id)
              )
            )
            .map((doc) => ({
              ...doc,
              period: p,
            })) ?? []
      );

      if (filteredDocuments.length) {
        const newDocuments = await Promise.all(
          filteredDocuments.map((item) => {
            return sdk.copyPeriodDocument({
              clientid: clientId,
              documentId: item.id,
              name: item.name,
              newAccount: account,
              newPeriodId: period.id,
            });
          })
        );

        setState((currentState) => ({
          ...currentState,
          documents: [...newDocuments.map((item) => ({ ...item, period }))],
        }));

        dispatch(
          increaseDocuments(
            clientId,
            financialYear,
            account,
            `${year}${month}`,
            period.id,
            filteredDocuments.length
          )
        );
      }
    } catch (e) {
      console.error(e);
    }
  }, [
    account,
    previousPeriodFinancialYear,
    previousGroupedPeriods,
    lastPeriod,
    period,
    sdk,
    clientId,
    setState,
    dispatch,
    financialYear,
    year,
    month,
  ]);

  const getPreviousPeriodDocumentsCount =
    useCallback(async (): Promise<number> => {
      if (!account || !previousPeriod || !previousPeriodFinancialYear) {
        return 0;
      }

      if (previousPeriodFinancialYear === financialYear && userInputDocuments) {
        const documentsByAccount =
          userInputDocuments[`account${account}documents`];

        return documentsByAccount
          ? documentsByAccount[`${previousPeriod.id}`]
          : 0;
      }

      try {
        const docs = await sdk.getPeriodDocuments({
          clientid: clientId,
          account: [account],
          periodId: [previousPeriod.id],
        });

        return docs.length;
      } catch (e) {
        console.error(e);
        return 0;
      }
    }, [
      account,
      previousPeriod,
      previousPeriodFinancialYear,
      financialYear,
      userInputDocuments,
      sdk,
      clientId,
    ]);

  const createAndAddDocument = useCallback(
    async ({
      name,
      docType,
      footer,
      header,
      parts,
      printState,
    }: {
      name: string;
      docType: string;
      footer: string;
      header: string;
      parts: string[];
      printState: PrintState;
    }) => {
      if (!account) {
        return;
      }

      const result = await asResultClass(
        sdk.printPeriodDocument({
          clientid: clientId,
          periodId,
          account,
          name,
          docType,
          requestBody: {
            footer,
            header,
            parts,
            printState,
          },
        })
      );

      if (result.ok) {
        dispatch(
          increaseDocuments(
            clientId,
            financialYear,
            account,
            `${year}${month}`,
            periodId
          )
        );
      } else {
        throw new Error('Error');
      }
    },
    [account, sdk, clientId, dispatch, financialYear, year, month, periodId]
  );

  return {
    ...state,
    addDocument,
    deleteDocument,
    copyDocument,
    renameDocument,
    copyDocumentsFromPreviousPeriod,
    createAndAddDocument,
    getPreviousPeriodDocumentsCount,
    removeReference,
  };
};

export default usePeriodDocuments;

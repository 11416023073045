import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';
import { FinancialReport } from '@agoy/financial-report-document';
import { booleanValue, stringValue } from '@agoy/document';
import { currentClientYear } from '_reconciliation/redux/accounting-view/selectors';
import { useSelector } from 'redux/reducers';
import Typography from '@material-ui/core/Typography';
import {
  formatTextInThousands,
  ReportColors,
  annualReportIncomeChanged,
  calcMoveAccounts,
  applyRowsChanges,
} from '_financial-report/utils';
import {
  AnnualReportChanges,
  IncomeStatement,
} from '@agoy/annual-report-document';

import EditableTextBlock from '../../UI/EditableTextBlock';
import RRBRTable from './Table';
import OrganisationLogo from '../../UI/OrganisationLogo';
import {
  filterBlankRow,
  filterBlankColumns,
  prepareGeneralInfo,
  sortColumns,
  addTotalColumn,
} from './utils';

type ReportColorsProps = {
  customColor: string | undefined;
};

type SubheaderProps = {
  customTextColor: string | undefined;
};

const A4Page = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 940px;
  width: 1400px;
`;

const Subheader = styled(Typography, {
  shouldForwardProp: (prop) =>
    prop !== 'customColor' && prop !== 'customTextColor',
})<ReportColorsProps & SubheaderProps>`
  margin: 0;
  padding: 15px 30px;
  font-size: 2rem;
  font-weight: bold;
  color: ${(props) =>
    props.customTextColor || props.theme.palette.text.primary};
  background-color: ${(props) =>
    props.customColor ||
    props.theme.palette.financialReportView.accentBackground};
`;

const DataSection = styled('div', {
  shouldForwardProp: (prop) => prop !== 'customColor',
})<ReportColorsProps>`
  display: flex;
  flex-direction: column;
  padding: 10px 30px;
  width: 95%;
  background-color: ${(props) =>
    props.customColor ||
    props.theme.palette.financialReportView.accentBackground};
`;

const CommentSection = styled('div', {
  shouldForwardProp: (prop) => prop !== 'customColor',
})<ReportColorsProps>`
  display: flex;
  padding: 0 30px;
  width: 65%;
  align-self: end;
  background-color: ${(props) =>
    props.customColor ||
    props.theme.palette.financialReportView.accentBackground};
`;

const CashFlowTable = styled(RRBRTable)`
  .MuiTableBody-root {
    tr {
      td {
        background-color: rgb(250, 250, 250);
        border-top: 1px solid #bdeec4;
        border-bottom: 1px solid #bdeec4;
      }
      td:nth-of-type(2n) {
        background-color: rgb(248, 255, 251);
      }
      td:first-of-type {
        background-color: rgb(248, 255, 251);
      }
    }
  }
`;

interface RRBRTablePageProps {
  tableRRBR: FinancialReport['tableRRBR'];
  reportColors: ReportColors;
  isTextRandomizationEnabled: boolean;
  settings: FinancialReport['settings'];
  incomeStatement: IncomeStatement;
  annualReportChanges: AnnualReportChanges | undefined;
  showBlankRow?: boolean;
}

const RRBRTablePreview = ({
  tableRRBR,
  reportColors,
  isTextRandomizationEnabled,
  settings,
  incomeStatement,
  annualReportChanges,
  showBlankRow = false,
}: RRBRTablePageProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const accountingBalances = useSelector(
    currentClientYear((state) => state.accountingBalances)
  );
  const title = formatMessage({ id: 'financialreport.RRBRTable' });
  const comment = stringValue(tableRRBR.section.comments);
  let generalInfo = isTextRandomizationEnabled
    ? stringValue(tableRRBR.section.generalInfo) || ''
    : stringValue(tableRRBR.section.generalInfoStatic) || '';

  const columns = useMemo(() => {
    const balanceTableColumns = filterBlankColumns(
      tableRRBR.section.balance,
      showBlankRow
    );
    const resultTableColumns = filterBlankColumns(
      tableRRBR.section.result,
      showBlankRow
    );

    const newBalanceTableColumns = [...balanceTableColumns];
    const newResultTableColumns = [...resultTableColumns];

    resultTableColumns.forEach((column) => {
      if (!newBalanceTableColumns.find((item) => item.id === column.id)) {
        newBalanceTableColumns.push(column);
      }
    });
    balanceTableColumns.forEach((column) => {
      if (!newResultTableColumns.find((item) => item.id === column.id)) {
        newResultTableColumns.push(column);
      }
    });

    return {
      balanceTableColumns: newBalanceTableColumns.sort(sortColumns),
      resultTableColumns: newResultTableColumns.sort(sortColumns),
      cashFlowTableColumns: newResultTableColumns.sort(sortColumns),
    };
  }, [showBlankRow, tableRRBR]);

  const resultTableData = useMemo(() => {
    let rowChanges;
    // check if   accounts order in annual report was changed. May be need recalculate row in table
    if (
      incomeStatement &&
      annualReportChanges &&
      annualReportIncomeChanged(annualReportChanges)
    ) {
      rowChanges = calcMoveAccounts(incomeStatement.section.table);
    }
    let result = tableRRBR
      ? filterBlankRow(tableRRBR.section.result, showBlankRow)
      : { columns: [], rows: [] };
    // recalculate row in table
    if (accountingBalances && rowChanges) {
      result = applyRowsChanges(
        columns.resultTableColumns,
        result.rows,
        accountingBalances,
        rowChanges
      );
    }
    result = addTotalColumn(columns.resultTableColumns, result.rows, true);
    return result;
  }, [columns, showBlankRow, tableRRBR]);

  const balanceTableData = useMemo(() => {
    let result = tableRRBR
      ? filterBlankRow(tableRRBR.section.balance, showBlankRow)
      : { columns: [], rows: [] };
    result = addTotalColumn(columns.balanceTableColumns, result.rows, false);
    return result;
  }, [columns, showBlankRow, tableRRBR]);

  const cashFlowTableData = useMemo(() => {
    let result = tableRRBR
      ? filterBlankRow(tableRRBR.section.cashFlow, showBlankRow)
      : { columns: [], rows: [] };
    result = addTotalColumn(columns.cashFlowTableColumns, result.rows, false);
    return result;
  }, [columns, showBlankRow, tableRRBR]);

  generalInfo = prepareGeneralInfo(
    generalInfo,
    tableRRBR.section.result.rows,
    booleanValue(settings.section.formatTextValuesInThousands)
  );
  if (settings.section.formatTextValuesInThousands) {
    generalInfo = formatTextInThousands(generalInfo);
  }

  return (
    <A4Page>
      <Subheader
        variant="h3"
        customColor={reportColors.background}
        customTextColor={reportColors.textSecondary}
      >
        {title}
      </Subheader>
      <DataSection customColor={reportColors.background}>
        <EditableTextBlock
          fieldId="tableRRBR.section.generalInfo"
          defaultText={generalInfo}
          isEditing={false}
          styles={{ height: '110px', color: reportColors.textSecondary }}
          print
        />
        <RRBRTable
          data={resultTableData}
          print
          lastRowBorder
          formatValueInThousands={booleanValue(
            settings.section.formatTableValuesInThousands
          )}
        />
        <RRBRTable
          data={balanceTableData}
          print
          formatValueInThousands={booleanValue(
            settings.section.formatTableValuesInThousands
          )}
        />
        <CashFlowTable
          data={cashFlowTableData}
          print
          hideHeader
          formatValueInThousands={booleanValue(
            settings.section.formatTableValuesInThousands
          )}
        />
      </DataSection>
      {comment && (
        <CommentSection customColor={reportColors.background}>
          <EditableTextBlock
            fieldId="tableRRBR.section.comments"
            defaultText={stringValue(tableRRBR.section.comments) || ''}
            isEditing={false}
            styles={{ height: '160px', color: reportColors.textSecondary }}
            print
          />
        </CommentSection>
      )}
      <OrganisationLogo print />
    </A4Page>
  );
};

export default RRBRTablePreview;

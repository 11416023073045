import React from 'react';
import {
  AnnualGeneralMeeting,
  ConfirmationCertificate,
  Settings,
} from '@agoy/annual-report-document';
import Typography from '../../UI/Typography/Typography';
import { useIntl } from 'react-intl';
import styled from '@emotion/styled';
import {
  Continuation,
  FSIDate,
  NonNumeric,
  NonNumericContinuation,
} from '../../xml/IX';
import { IXElement } from '../../ARKeyToIX';
import { stringValue, numberValue, booleanValue } from '@agoy/document';
import { ccyFormat } from '@agoy/common';

const Content = styled.div`
  padding: 0 2rem;
  text-align: left;

  p {
    margin-bottom: 1rem;
  }
`;

interface ConfirmationCertificateDigitalSubmissionProps {
  confirmationCertificate: ConfirmationCertificate;
  annualGeneralMeeting: AnnualGeneralMeeting;
  settings: Settings;
}

/**
 * The Fastställelseintyg (FSI) is only to be rendered and injected on the first
 * page when a user submits the annual report to the Bolagsverket. In the scenario
 * of manual submission, the FSI is rendered in /web and printed separately.
 *
 * @param confirmationCertificate The FSI, contains digital properties
 * @returns
 */
const ConfirmationCertificateDigitalSubmission = ({
  confirmationCertificate,
  annualGeneralMeeting,
  settings,
}: ConfirmationCertificateDigitalSubmissionProps): JSX.Element => {
  const { formatMessage } = useIntl();

  /* In the case of board rejection of the results disposition we render
     rejection rows which tags values based on the decision text */
  const renderRejectionRows = () => {
    const rowsToRender = booleanValue(
      settings.section.annualGeneralMeetingActive
    )
      ? annualGeneralMeeting.main.resultsDispositionRejected.rows
      : annualGeneralMeeting.main.resultsDispositionRejected.rows.filter(
          (r) => r.active
        );

    return rowsToRender.map((row, index, rows) => {
      if (!row.cells) return null;

      const label = stringValue(row.cells.label);
      const value = numberValue(row.cells.value);
      const isLastItem = index === rows.length - 1;

      if (!value) {
        return null;
      }

      return (
        <>
          {value < 0 && (
            <>
              -
              <IXElement
                key={`${label}-${value}`}
                arPart={row.cells.value}
                sign="-"
              >
                {ccyFormat(Math.abs(value))}
              </IXElement>
            </>
          )}
          {value >= 0 && (
            <IXElement key={`${label}-${value}`} arPart={row.cells.value}>
              {ccyFormat(value)}
            </IXElement>
          )}
          {' ' + label?.toLowerCase() + (isLastItem ? '.' : ', ')}
        </>
      );
    });
  };

  const { section } = confirmationCertificate;
  return (
    <Content>
      <Typography variant="h3">
        {formatMessage({
          id: 'annualReport.menu.confirmationCertificate',
        })}
      </Typography>
      {/* Start of continuation, income statement and balance sheet determined at AGM + board decision */}
      <Typography>
        <NonNumericContinuation
          name="se-bol-base:ArsstammaIntygande"
          contextRef="balans0"
          continuedAt="intygande_forts"
        >
          <IXElement arPart={section.dsIncomeStatementBalanceSheet}>
            {stringValue(section.dsIncomeStatementBalanceSheet)}
          </IXElement>{' '}
          <IXElement arPart={annualGeneralMeeting.main.date}>
            {stringValue(annualGeneralMeeting.main.date) || ''}
          </IXElement>
          {'. '}
          <br />
          {section.dsBoardApproval &&
            stringValue(section.dsBoardApproval) !== '' && (
              <IXElement arPart={section.dsBoardApproval}>
                {stringValue(section.dsBoardApproval)}
              </IXElement>
            )}
          {section.dsBoardDisapproval &&
            stringValue(section.dsBoardDisapproval) !== '' && (
              <IXElement arPart={section.dsBoardDisapproval}>
                {stringValue(section.dsBoardDisapproval)}
              </IXElement>
            )}
          {section.dsBoardDisapproval &&
            stringValue(section.dsBoardDisapproval) !== '' && (
              <>
                {' '}
                <IXElement arPart={section.dsDispositionDecision}>
                  {`${formatMessage({
                    id: 'annualReport.confirmationCertificate.dispositionDecisionPretext',
                  })} `}
                  {renderRejectionRows()}
                </IXElement>
              </>
            )}
        </NonNumericContinuation>
      </Typography>

      {/* Continuation, verification of authenticity and signatures */}
      <Typography>
        <Continuation id="intygande_forts">
          <IXElement arPart={section.dsOriginalsMatchVerificaton}>
            {stringValue(section.dsOriginalsMatchVerificaton)}
          </IXElement>
        </Continuation>
      </Typography>

      {/* The person signing the FSI section */}
      <Typography>
        <strong>
          <NonNumeric
            name="se-bol-base:UnderskriftFaststallelseintygElektroniskt"
            contextRef="balans0"
          >
            {formatMessage({
              id: 'annualReport.confirmationCertificate.section.signatureClarificationDigitalSubmission',
            })}
          </NonNumeric>
        </strong>
        <br />
        {section.signatureClarification && (
          <NonNumeric
            name="se-bol-base:UnderskriftFaststallelseintygForetradareTilltalsnamn"
            contextRef="period0"
          >
            <IXElement arPart={section.signatureClarification}>
              {stringValue(section.signatureClarification)}
            </IXElement>
          </NonNumeric>
        )}
        {section.dsSignatureClarificationSurname && (
          <NonNumeric
            name="se-bol-base:UnderskriftFaststallelseintygForetradareEfternamn"
            contextRef="period0"
          >
            <IXElement arPart={section.dsSignatureClarificationSurname}>
              {` ${stringValue(section.dsSignatureClarificationSurname)}`}
            </IXElement>
          </NonNumeric>
        )}
        {section.dsRole && stringValue(section.dsRole) !== '' && (
          <>
            {', '}
            <NonNumeric
              name="se-bol-base:UnderskriftFaststallelseintygForetradareForetradarroll"
              contextRef="period0"
            >
              <IXElement arPart={section.dsRole}>
                {stringValue(section.dsRole)}
              </IXElement>
            </NonNumeric>
          </>
        )}
        <br />
        {stringValue(section.date) !== '' && (
          <FSIDate
            name="se-bol-base:UnderskriftFastallelseintygDatum"
            contextRef="balans0"
          >
            {stringValue(section.date)}
          </FSIDate>
        )}
      </Typography>
    </Content>
  );
};

export default ConfirmationCertificateDigitalSubmission;

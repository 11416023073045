import React, { useState, createContext, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { asResultClass, useApiSdk, ApiReturnType } from 'api-sdk';
import { addGlobalErrorMessage } from 'redux/actions';
import { Period } from '@agoy/api-sdk-core';
import { FinancialYear } from 'types/Accounting';

export type GroupDocumentContextType = [
  PeriodDocumentState,
  React.Dispatch<React.SetStateAction<PeriodDocumentState>>
];

type ApiPeriodDocument = ApiReturnType<'getPeriodDocuments'>[number];

export type PeriodDocument = ApiPeriodDocument & {
  period: Period | undefined;
  uploading?: boolean;
};

interface PeriodDocumentsProviderProps {
  clientId: string;
  group: string;
  accounts: string[];
  groupedPeriods: (Period & {
    financialYear: FinancialYear;
  })[];
  children: React.ReactNode | React.ReactNode[];
}

interface PeriodDocumentState {
  documents: PeriodDocument[];
  accounts: string[];
  group: string;
  loadingDocuments: boolean;
  refetchDocuments: () => Promise<void>;
}

const defaultState: PeriodDocumentState = {
  documents: [],
  accounts: [],
  group: '',
  loadingDocuments: false,
  refetchDocuments: () => Promise.resolve(),
};

export const GroupDocumentContext = createContext<GroupDocumentContextType>([
  defaultState,
  () => {},
]);

const GroupDocumentsProvider = ({
  clientId,
  group,
  accounts,
  groupedPeriods,
  children,
}: PeriodDocumentsProviderProps): JSX.Element => {
  const { getPeriodDocuments } = useApiSdk();
  const dispatch = useDispatch();

  const [loadingDocuments, setLoadingDocuments] = useState(false);
  const [state, setState] = useState<PeriodDocumentState>({
    ...defaultState,
    accounts,
    group,
  });

  const refetchDocuments = useCallback(async () => {
    if (!accounts.length) return;
    setLoadingDocuments(true);

    const result = await asResultClass(
      getPeriodDocuments({
        clientid: clientId,
        account: accounts,
        accountGroups: [group],
        periodId: groupedPeriods.map((p) => p.id),
      })
    );

    if (result.err) {
      setLoadingDocuments(false);
      dispatch(addGlobalErrorMessage('fetch.error.getPeriodDocuments'));
    }

    if (result.ok) {
      setLoadingDocuments(false);

      const docs = result.val.map((doc) => ({
        ...doc,
        period: groupedPeriods.find((p) =>
          doc.references.find((ref) => ref.periodIds.includes(p.id))
        ),
      }));

      setState((currentState) => ({
        ...currentState,
        documents: docs,
        group,
        accounts,
      }));
    }
  }, [accounts, clientId, dispatch, getPeriodDocuments, group, groupedPeriods]);

  useEffect(() => {
    refetchDocuments();
  }, [groupedPeriods, refetchDocuments]);

  return (
    <GroupDocumentContext.Provider
      value={[{ ...state, loadingDocuments, refetchDocuments }, setState]}
    >
      {children}
    </GroupDocumentContext.Provider>
  );
};

export default GroupDocumentsProvider;

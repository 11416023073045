import { value, table } from '@agoy/document';
import { NoteTwoTableSection, IxbrlCell } from '../../../../common';
import {
  active,
  field,
  ixbrlCell,
  ixbrlField,
  tupleRef,
} from '../../../../common/utils/util';

const MONETARY_ITEM_TYPE = 'monetaryItemType';

const getNote64 = (): NoteTwoTableSection => {
  return {
    active: false,
    number: value(undefined),
    name: value('Exceptionella intäkter och kostnader'),
    table: table<IxbrlCell>(
      'notes.note64.firstTable',
      {
        id: 'label',
        label: '',
      },
      {
        id: 'sum',
        label: 'Belopp',
      }
    )
      .addRows((rows) =>
        rows
          .addRow(
            '1',
            ixbrlCell(value('Exceptionella intäkter'), {
              type: 'stringItemType',
              name: 'se-gen-base:ExceptionellaIntakterPost',
              contextRef: 'period0',
              tuple: {
                name: 'se-gaap-ext:ExceptionellaIntakterTuple',
                id: `${rows.getBaseId()}.1.tuple`,
                tupleID: `${rows.getBaseId()}.1.tuple`,
              },
              tupleRef: tupleRef(1, `${rows.getBaseId()}.1.tuple`),
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ExceptionellaIntakter',
              contextRef: 'period0',
              saldo: 'credit',
              tupleRef: tupleRef(2, `${rows.getBaseId()}.1.tuple`),
            })
          )
          .build()
      )
      .newRowTemplateGenerator((id, baseId) => ({
        id,
        active: true,
        cells: {
          label: ixbrlCell(value(''), {
            type: 'stringItemType',
            name: 'se-gen-base:ExceptionellaIntakterPost',
            contextRef: 'period0',
            tuple: {
              name: 'se-gaap-ext:ExceptionellaIntakterTuple',
              id: `${baseId}.${id}.tuple`,
              tupleID: `${baseId}.${id}.tuple`,
            },
            tupleRef: tupleRef(1, `${baseId}.${id}.tuple`),
          }),
          sum: ixbrlCell(value(undefined), {
            type: MONETARY_ITEM_TYPE,
            name: 'se-gen-base:ExceptionellaIntakter',
            contextRef: 'period0',
            saldo: 'debit',
            tupleRef: tupleRef(2, `${baseId}.${id}.tuple`),
          }),
        },
      }))
      .build(),
    secondTable: table<IxbrlCell>(
      'notes.note64.secondTable',
      {
        id: 'label',
        label: '',
      },
      {
        id: 'sum',
        label: 'Belopp',
      }
    )
      .addRows((rows) =>
        rows
          .addRow(
            '1',
            ixbrlCell(value('Exceptionella kostnader'), {
              type: 'stringItemType',
              name: 'se-gen-base:ExceptionellaKostnaderPost',
              contextRef: 'period0',
              tuple: {
                name: 'se-gaap-ext:ExceptionellaKostnaderTuple',
                id: `${rows.getBaseId()}.1.tuple`,
                tupleID: `${rows.getBaseId()}.1.tuple`,
              },
              tupleRef: tupleRef(1, `${rows.getBaseId()}.1.tuple`),
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ExceptionellaKostnader',
              contextRef: 'period0',
              saldo: 'debit',
              tupleRef: tupleRef(2, `${rows.getBaseId()}.1.tuple`),
            })
          )
          .build()
      )
      .newRowTemplateGenerator((id, baseId) => ({
        id,
        active: true,
        cells: {
          label: ixbrlCell(value(''), {
            type: 'stringItemType',
            name: 'se-gen-base:ExceptionellaKostnaderPost',
            contextRef: 'period0',
            tuple: {
              name: 'se-gaap-ext:ExceptionellaKostnaderTuple',
              id: `${baseId}.${id}.tuple`,
              tupleID: `${baseId}.${id}.tuple`,
            },
            tupleRef: tupleRef(1, `${baseId}.${id}.tuple`),
          }),
          sum: ixbrlCell(value(undefined), {
            type: MONETARY_ITEM_TYPE,
            name: 'se-gen-base:ExceptionellaKostnader',
            contextRef: 'period0',
            saldo: 'debit',
            tupleRef: tupleRef(2, `${baseId}.${id}.tuple`),
          }),
        },
      }))
      .build(),
    commentLabel: field('Kommentar'),
    comment: ixbrlField(active(field('')), {
      type: 'stringItemType',
      name: 'se-gen-base:NotExceptionellaIntakterKostnaderKommentar',
      contextRef: 'period0',
    }),
    group: value(undefined),
    section: value(undefined),
    validForReportType: {
      k2: true,
      k3: true,
    },
  };
};

export default getNote64;

import {
  ClientCompanyType,
  ClientCustomerAssigneeType,
  ClientCustomerTagType,
} from '_clients/types/types';
import { SortType, Filters } from '_clients/redux/customers-overview/types';
import { getGroupStatus } from 'utils/accountGrouper';
import { createPeriods } from 'utils';
import { add, endOfMonth, getMonth, isAfter, isBefore } from 'date-fns';
import {
  isSameOrAfter,
  isSameOrBefore,
  parse,
  parseFinancialYear,
} from '@agoy/dates';

export interface Clients {
  [key: string]: ClientCompanyType;
}

export const filterCleanClients = (clients: ClientCompanyType[]) => {
  return clients.filter((client) => {
    const { lamps, financialYears } = client;

    let isClean = true;

    const periods = createPeriods().filter((item) => {
      return isBefore(parse(item.date), endOfMonth(new Date()));
    });

    const withLatestYears = financialYears.some((financialYear) => {
      const { start, end } = parseFinancialYear(financialYear);

      return periods.some(
        (period) =>
          isSameOrBefore(start, parse(period.date)) &&
          isSameOrAfter(end, parse(period.date))
      );
    });

    if (!withLatestYears) {
      return false;
    }

    const { end } = parseFinancialYear(
      financialYears[financialYears.length - 1]
    );

    periods.forEach((period) => {
      if (
        isSameOrAfter(end, parse(period.date)) &&
        (!lamps[period.date] || getGroupStatus(lamps[period.date]) !== 'done')
      ) {
        isClean = false;
      }
    });

    return !isClean;
  });
};

const declarationPeriods = {
  1: { startMonth: 1, endMonth: 4 },
  2: { startMonth: 5, endMonth: 6 },
  3: { startMonth: 7, endMonth: 8 },
  4: { startMonth: 9, endMonth: 12 },
};

const filterClients = (clientsList: ClientCompanyType[], filters: Filters) => {
  const {
    closingPeriodFilter,
    companyTypeFilter,
    closingMonthFilter,
    declarationPeriodFilter,
  } = filters;

  const shouldFilterBy = (filterGroup: Record<string | number, boolean>) =>
    Object.values(filterGroup).some((filter) => filter);

  const shouldFilterByClosingPeriod = shouldFilterBy(closingPeriodFilter);
  const shouldFilterByCompanyType = shouldFilterBy(companyTypeFilter);
  const shouldFilterByClosingMonthPeriod = shouldFilterBy(closingMonthFilter);
  const shouldFilterByDeclarationPeriod = shouldFilterBy(
    declarationPeriodFilter
  );

  if (
    !(
      shouldFilterByClosingPeriod ||
      shouldFilterByCompanyType ||
      shouldFilterByClosingMonthPeriod ||
      shouldFilterByDeclarationPeriod
    )
  ) {
    return clientsList;
  }

  return clientsList.filter((item) => {
    // sort years in desc order and find first year that ends within year back from today
    const today = new Date();
    const yearBack = add(new Date(), { years: -1, days: -1 });

    const previousFinancialYear = item.rawFinancialYears.reverse().find((y) => {
      const endDate = new Date(y.end);
      return isBefore(endDate, today) && isAfter(endDate, yearBack);
    });

    const declarationMonth = previousFinancialYear
      ? getMonth(new Date(previousFinancialYear.end)) + 1
      : undefined;

    const declarationPeriod = !declarationMonth
      ? undefined
      : Object.keys(declarationPeriods).find(
          (period) =>
            declarationMonth <= declarationPeriods[period].endMonth &&
            declarationMonth >= declarationPeriods[period].startMonth
        );

    // if filter should not be applied, return true
    // else if field is present, check filter, if not present, return false
    return (
      (!shouldFilterByClosingPeriod ||
        (item.closingPeriod && closingPeriodFilter[item.closingPeriod])) &&
      (!shouldFilterByCompanyType || companyTypeFilter[item.type]) &&
      (!shouldFilterByClosingMonthPeriod ||
        (item.closingMonth && closingMonthFilter[item.closingMonth])) &&
      (!shouldFilterByDeclarationPeriod ||
        (declarationPeriod && declarationPeriodFilter[declarationPeriod]))
    );
  });
};

const filterClientsByTags = (
  clientsList: ClientCompanyType[],
  tags: Array<ClientCustomerTagType>
) => {
  // If nothing is selected filter should not be applied
  if (tags.length === 0) {
    return clientsList;
  }

  return clientsList.filter((item) =>
    item.tags
      ? item.tags.filter((tag) => tags.find((active) => active.id === tag.id))
          .length > 0
      : false
  );
};

const filterClientsByAssignees = (
  clientsList: ClientCompanyType[],
  assignees: Array<ClientCustomerTagType>
) => {
  // If nothing is selected filter should not be applied
  if (assignees.length === 0) {
    return clientsList;
  }

  return clientsList.filter((item) =>
    item.assignees
      ? item.assignees.filter((tag) =>
          assignees.find((active) => active.id === tag.id)
        ).length > 0
      : false
  );
};

export const sortClients = (members: Member.MemberType[]) => {
  const memberEmailById = members.reduce(
    (result, member) => ({ ...result, [member.userId]: member.email }),
    {}
  );
  const managerEmail = (client: ClientCompanyType): string =>
    (client.managerId && memberEmailById[client.managerId]) || '';

  return ({
    clients,
    sortedByType,
    userEmail,
    hideClean,
    filters,
    activeClientTags,
    activeClientAssignees,
  }: {
    clients: Clients;
    sortedByType: SortType;
    userEmail?: string;
    hideClean?: boolean;
    filters: Filters;
    activeClientTags: Array<ClientCustomerTagType>;
    activeClientAssignees: Array<ClientCustomerAssigneeType>;
  }): ClientCompanyType[] => {
    let clientsUnsorted = Object.values(clients).filter(
      (client) => client.active
    );
    if (hideClean) {
      clientsUnsorted = filterCleanClients(clientsUnsorted);
    }

    clientsUnsorted = filterClients(clientsUnsorted, filters);
    clientsUnsorted = filterClientsByTags(clientsUnsorted, activeClientTags);
    clientsUnsorted = filterClientsByAssignees(
      clientsUnsorted,
      activeClientAssignees
    );
    clientsUnsorted.sort((a, b) => a.name.localeCompare(b.name));

    switch (sortedByType) {
      case 'BY_CLIENT_NAME':
        return clientsUnsorted.sort((a, b) => a.name.localeCompare(b.name));
      case 'BY_CLIENT_MANAGER':
        return clientsUnsorted.sort((a, b) => {
          if (userEmail) {
            if (managerEmail(a) === userEmail && managerEmail(b) === userEmail)
              return 0;
            if (managerEmail(a) === userEmail) return -1;
            if (managerEmail(b) === userEmail) return 1;
          }
          return managerEmail(a).localeCompare(managerEmail(b));
        });
      default:
        return [];
    }
  };
};

export const sortBySearch = ({
  customers,
  search,
  hideClean,
  filters,
  activeClientTags,
  activeClientAssignees,
}: {
  customers: Clients;
  search: string;
  hideClean: boolean;
  filters: Filters;
  activeClientTags: Array<ClientCustomerTagType>;
  activeClientAssignees: Array<ClientCustomerAssigneeType>;
}): ClientCompanyType[] => {
  let searchedCustomers;
  if (search) {
    const sortedCustomers = Array.from(Object.values(customers)).sort((a, b) =>
      a.name.localeCompare(b.name)
    );
    const formattedSearch = search.trim().toLowerCase();

    /**
     * add search by tags
     */
    searchedCustomers = sortedCustomers.filter(
      (customer) =>
        customer.name.toLowerCase().includes(formattedSearch) ||
        customer.tags?.find((tag) =>
          tag.name.toLowerCase().includes(formattedSearch)
        )
    );

    if (hideClean) {
      searchedCustomers = filterCleanClients(searchedCustomers);
    }

    searchedCustomers = filterClients(searchedCustomers, filters);
    searchedCustomers = filterClientsByTags(
      searchedCustomers,
      activeClientTags
    );
    searchedCustomers = filterClientsByAssignees(
      searchedCustomers,
      activeClientAssignees
    );
    searchedCustomers = searchedCustomers.filter((item) => item.active);

    return searchedCustomers;
  }
  return [];
};

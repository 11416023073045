import React, {
  useCallback,
  useContext,
  useState,
  useEffect,
  useRef,
} from 'react';
import { useSelector } from 'redux/reducers';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';
import Alert from '_shared/components/AlertBanners/Alert';

import { FinancialReport } from '@agoy/financial-report-document';
import { stringValue } from '@agoy/document';
import FinancialReportViewServiceContext from '_financial-report/service/FinancialReportViewServiceContext';
import RichEditor from '_shared/components/RichEditor';

import OrganisationLogo from '../../UI/OrganisationLogo';
import { ReportColors } from '../../../../utils';
import Heading from '../../UI/Heading';
import EditingIconButton from '../../UI/EditingIconButton';
import ResetContentButton from '../../UI/ResetContent/ResetContentButton';
import PageTitle from '../../UI/PageTitle';

type ReportColorsProps = {
  customColor: string | undefined;
};

const Content = styled.div`
  min-width: 800px;
  width: min-content;
  margin-left: ${({ theme }) => theme.spacing(4)}px;
`;

const PageTitleContainer = styled.div`
  padding-left: ${({ theme }) => theme.spacing(2)}px;
`;

const ContainerStatic = styled('div', {
  shouldForwardProp: (prop) => prop !== 'customColor',
})<ReportColorsProps>`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;
  padding-top: ${({ theme }) => theme.spacing(2)}px;
  padding-left: ${({ theme }) => theme.spacing(2)}px;
`;

const ContainerOverflow = styled('div', {
  shouldForwardProp: (prop) => prop !== 'customColor',
})<ReportColorsProps>`
  height: 90%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: auto;
  padding-top: ${({ theme }) => theme.spacing(2)}px;
  padding-left: ${({ theme }) => theme.spacing(2)}px;
`;

const A4Page = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 1400px;
  height: 960px;
  font-size: 16px;
  padding: ${({ theme }) => theme.spacing(1)}px;
  border-radius: ${({ theme }) => `${theme.shape.borderRadius}px`};
  background: ${(props) => props.theme.palette.background.paper};
  box-shadow: ${({ theme }) => theme.shadows[4]};
  transform: scale(0.6);
  transform-origin: top left;
`;

const moreRich = {
  buttons: ['insertLink', 'insertTable'],
  align: 'left',
};

interface AdditionalTextProps {
  additionalText: FinancialReport['additionalText'];
  reportColors: ReportColors;
  pageId: string;
}

const AdditionalText = ({
  additionalText,
  reportColors,
  pageId,
}: AdditionalTextProps): JSX.Element | null => {
  const { formatMessage } = useIntl();
  const [editing, setEditing] = useState<boolean>(false);
  const overflowingText = useRef<HTMLDivElement | null>(null);
  const [overflowActive, setOverflowActive] = useState<boolean>(false);
  let title =
    stringValue(additionalText.section.text1Title) ||
    formatMessage({ id: 'financialreport.additionalTextOne' });
  let pageResetId = 'additionalPageOne';
  let titleFieldId = 'additionalText.section.text1Title';
  let defaultText = stringValue(additionalText.section.text1) || ' ';
  if (pageId === 'page2') {
    title =
      stringValue(additionalText.section.text2Title) ||
      formatMessage({ id: 'financialreport.additionalTextTwo' });
    pageResetId = 'additionalPageTwo';
    titleFieldId = 'additionalText.section.text2Title';
    defaultText = stringValue(additionalText.section.text2) || ' ';
  }
  const toggleEditing = useCallback(() => setEditing((state) => !state), []);
  const service = useContext(FinancialReportViewServiceContext);
  const checkOverflow = (textContainer: HTMLDivElement | null): boolean => {
    if (textContainer)
      return textContainer.offsetHeight < textContainer.scrollHeight;
    return false;
  };
  useEffect(() => {
    if (checkOverflow(overflowingText.current)) {
      setOverflowActive(true);
      return;
    }
    setOverflowActive(false);
  }, [overflowActive, editing]);
  const onChange = (newValue: string) => {
    switch (pageId) {
      case 'page1': {
        service.updateField('additionalText.section.text1', newValue);
        break;
      }
      case 'page2': {
        service.updateField('additionalText.section.text2', newValue);
        break;
      }
      default:
    }
  };
  const onReset = () => {
    switch (pageId) {
      case 'page1': {
        service.updateField('additionalText.section.text1', '');
        service.updateField('additionalText.section.text1Title', 'Textsida');
        break;
      }
      case 'page2': {
        service.updateField('additionalText.section.text2', '');
        service.updateField('additionalText.section.text2Title', 'Textsida');
        break;
      }
      default:
    }
  };

  const { currentPeriod } = useSelector((state) => state.customerView);
  if (!currentPeriod) {
    return <></>;
  }
  return (
    <Content>
      <Heading
        title={title}
        variant="h1"
        controls={
          <>
            <EditingIconButton value={editing} onClick={toggleEditing} />
            <ResetContentButton
              what={title}
              id={pageResetId}
              onReset={onReset}
            />
          </>
        }
      />
      <A4Page>
        <PageTitleContainer>
          <PageTitle
            title={title}
            fieldId={titleFieldId}
            isEditing={editing}
            customColor={reportColors.textMain}
          />
        </PageTitleContainer>
        {editing && (
          <ContainerStatic
            customColor={reportColors.background}
            ref={overflowingText}
          >
            <RichEditor
              value={defaultText}
              onChange={(html) => {
                onChange(html);
              }}
              isEditing={editing}
              height={795}
              width="100%"
              moreRich={moreRich}
            />
          </ContainerStatic>
        )}
        {!editing && (
          <ContainerOverflow
            customColor={reportColors.background}
            ref={overflowingText}
          >
            <RichEditor
              value={defaultText}
              onChange={(html) => {
                onChange(html);
              }}
              isEditing={editing}
              height={700}
              width="100%"
              moreRich={moreRich}
            />
          </ContainerOverflow>
        )}
        <OrganisationLogo />
        {overflowActive && (
          <Alert type="info">
            {formatMessage({ id: 'financialreport.additionalText.info' })}
          </Alert>
        )}
      </A4Page>
    </Content>
  );
};

export default AdditionalText;

import { AccountResolver } from './types';

/**
 * Dummy implementation of AccountResolver
 *
 * Logs errors if account are being referenced.
 */
const NoAccountResolver: AccountResolver = {
  get: () => {
    // eslint-disable-next-line no-console
    console.error("Didn't expect a call");
    return undefined;
  },
  sum: () => {
    // eslint-disable-next-line no-console
    console.error("Didn't expect a call");
    return undefined;
  },
  getName: () => {
    // eslint-disable-next-line no-console
    console.error("Didn't expect a call");
    return undefined;
  },
};

export default NoAccountResolver;

import { INIT_STATE } from '_shared/redux/action-types';
import { ClientCompanyType } from '_clients/types/types';
import {
  ADD_CUSTOMER,
  UPDATE_CUSTOMER,
  SET_CUSTOMER_LOGO,
  DELETE_CLIENT,
  POPULATE_CUSTOMERS,
  RESET_CUSTOMERS,
  SET_INTEGRATION_STATUS,
  SET_FINANCIAL_YEARS,
  SET_LAMP_STATUS,
  SET_CONNECTIONS_TO_COMPANIES,
  DELETE_CONNECTION,
  SET_CHECKLISTS,
  SET_COMPANY_SHARE_OWNERS,
} from './action-types';

export type CustomersState = {
  [key: string]: ClientCompanyType;
};

const initialCustomersState: CustomersState = {};

export default (
  state: CustomersState = initialCustomersState,
  action
): CustomersState => {
  switch (action.type) {
    // Global actions, shared by other reducers
    case INIT_STATE:
      return action.state.customers || state;

    // Specific actions
    case SET_LAMP_STATUS: {
      const news = {
        ...state,
        [action.customerId]: {
          ...state[action.customerId],
          lamps: {
            ...state[action.customerId].lamps,
            [action.period]: action.mapStatuses,
          },
        },
      };
      return news;
    }

    case ADD_CUSTOMER:
      return {
        ...state,
        [action.customer.id]: action.customer,
      };
    case UPDATE_CUSTOMER:
      return {
        ...state,
        [action.customer.id]: {
          ...state[action.customer.id],
          ...action.customer,
        },
      };
    case SET_CUSTOMER_LOGO:
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          logo: action.logo,
        },
      };
    case DELETE_CLIENT: {
      const newState = { ...state };
      delete newState[action.clientId];
      return newState;
    }
    case POPULATE_CUSTOMERS:
      return {
        ...state,
        ...action.customers.reduce((acc, current) => {
          acc[current.id] = current;
          return acc;
        }, {}),
      };
    case RESET_CUSTOMERS:
      return {};
    case SET_INTEGRATION_STATUS:
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          integrations: {
            ...state[action.id]?.integrations?.[action.integration],
            [action.integration]: action.status,
          },
        },
      };
    case SET_FINANCIAL_YEARS:
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          financialYears: action.financialYears,
        },
      };
    case SET_CONNECTIONS_TO_COMPANIES: {
      const company = state[action.clientId];
      return {
        ...state,
        [action.clientId]: {
          ...company,
          connections: action.connections || [],
        },
      };
    }
    case DELETE_CONNECTION: {
      const company = state[action.clientId];
      return {
        ...state,
        [action.clientId]: {
          ...company,
          connections: company.connections?.filter(
            (connection) => connection.relationId !== action.connectionId
          ),
        },
      };
    }
    case SET_COMPANY_SHARE_OWNERS: {
      const company = state[action.clientId];
      return {
        ...state,
        [action.clientId]: {
          ...company,
          shareOwners: action.owners || [],
        },
      };
    }
    case SET_CHECKLISTS: {
      const company = state[action.clientId];
      return {
        ...state,
        [action.clientId]: {
          ...company,
          checklists: action.checklists,
        },
      };
    }
    default:
      return state;
  }
};

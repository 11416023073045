/**
 * Fortnox ErrorInformation object.
 */
export type ErrorInformation = {
  Error: number;
  Message: string;
  Code: number;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isFortnoxErrorInformation = (obj: any): obj is ErrorInformation => {
  return (
    typeof obj === 'object' &&
    obj !== null &&
    'Error' in obj &&
    'Message' in obj &&
    'Code' in obj &&
    typeof obj.Error === 'number' &&
    typeof obj.Code === 'number' &&
    typeof obj.Message === 'string'
  );
};

export default isFortnoxErrorInformation;

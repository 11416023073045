import React from 'react';
import { AgoyTable } from '@agoy/document';
import { CommonNotes } from '@agoy/annual-report-document';
import { Table } from '../../../../UI/CommonPrintTable';

interface BigTableProps {
  table: AgoyTable;
  transitionK3ToK2: boolean;
  notes?: CommonNotes;
}

const tableId = 'managementReport.changesInEquity.table';

const BigTable = ({
  table,
  transitionK3ToK2,
  notes,
}: BigTableProps): JSX.Element => {
  const rowsToRender = table.rows.filter((row) => {
    if (transitionK3ToK2 && row.id === 'transitionToK2') return true;
    return row.id === 'main';
  });

  return (
    <Table
      baseId="annualReport"
      tableId={tableId}
      rows={rowsToRender}
      columns={table.columns.filter((column) => !column.id.includes('Warning'))}
      notes={notes}
    />
  );
};

export default BigTable;

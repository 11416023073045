import React from 'react';
import { useIntl } from 'react-intl';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import { ccyFormat } from '@agoy/common';

interface BarChartProps {
  currentYearData: number[];
  previousYearData: number[];
  height?: number;
  print?: boolean;
}

const colors = [
  'rgb(0,206,124)',
  'rgb(0,103,62)',
  'rgb(34,42,53)',
  'rgb(173,185,202)',
  'rgb(51,94,98)',
  'rgb(160,202,206)',
  'rgb(73,135,141)',
];

const BarChart = ({
  currentYearData,
  previousYearData,
  height = 255,
  print = false,
}: BarChartProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const maxValueOfY = Math.round(
    Math.max(
      Math.max.apply(null, currentYearData),
      Math.max.apply(null, previousYearData)
    )
  );
  let tickIntervalY = 100000;
  if (maxValueOfY < 100000) {
    tickIntervalY = 10000;
  }
  if (maxValueOfY < 10000) {
    tickIntervalY = 1000;
  }
  if (maxValueOfY < 1000) {
    tickIntervalY = 100;
  }
  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={{
        chart: {
          type: 'column',
          height,
          style: { 'font-family': "'Exo 2'" },
        },
        title: {
          text: null,
        },
        colors: ['#335e62', '#d9d9d9'],
        xAxis: {
          title: {
            text: null,
          },
          labels: {
            rotation: -35,
            style: {
              fontSize: print ? '0.6rem' : '0.3rem',
            },
          },
          categories: [
            'Nettoomsättning',
            'Övriga rörelseintäkter',
            'Bruttokostnader',
            'Övriga kostnader',
            'Personalkostnader',
            'Avskrivningar etc.',
            'REA-förlust etc.',
          ],
        },
        yAxis: {
          title: {
            text: null,
          },
          tickInterval: tickIntervalY,
          labels: {
            style: {
              fontSize: print ? '0.8rem' : '0.4rem',
            },
            formatter: ({ value }) => ccyFormat(value),
          },
        },
        plotOptions: {
          column: {
            animation: false,
            pointPadding: 0.05,
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              x: print ? 8 : 3,
              allowOverlap: true,
              // format labels as integer numbers
              format: '{point.y:,.0f}',
              style: {
                fontWeight: 'normal',
                fontSize: print ? '0.7rem' : '0.3rem',
                textOutline: 0,
              },
            },
          },
          series: {
            type: 'column',
            groupPadding: 0.1,
          },
        },
        series: [
          {
            name: formatMessage({
              id: 'financialreport.barchart.currentYear',
            }),
            data: currentYearData.map((item, index) => ({
              y: item,
              color: colors[index],
              dataLabels: {
                y: item < 0 ? 5 : -5,
                x: print ? -8 : -3,
              },
            })),
          },
          {
            name: formatMessage({
              id: 'financialreport.barchart.previousYear',
            }),
            data: previousYearData,
          },
        ],
        credits: {
          enabled: false,
        },
        lang: {
          thousandsSep: '',
        },
        legend: {
          enabled: false,
        },
        tooltip: {
          valueDecimals: 0,
        },
      }}
    />
  );
};

export default BarChart;

import { useDispatch } from 'react-redux';
import { useApiSdk } from 'api-sdk';
import { useContext } from 'react';
import {
  increaseDocuments,
  decreaseDocuments,
} from '_reconciliation/redux/accounting-view/actions';
import { PeriodDocument } from 'utils/usePeriodDocuments/Provider';
import { FinancialYear, Period } from '@agoy/api-sdk-core';
import { parseFormat } from '@agoy/dates';
import PeriodDataContext from '../PeriodDataContext';

const useDocumentsUtils = () => {
  const dispatch = useDispatch();
  const sdk = useApiSdk();

  const { clientId } = useContext(PeriodDataContext);

  type DeleteAllDocumentArgs = {
    documents: PeriodDocument[];
    account: string;
    financialYear: FinancialYear;
    year: string;
    month: string;
    periodId: number;
  };

  const deleteAllDocuments = async ({
    documents,
    account,
    financialYear,
    year,
    month,
    periodId,
  }: DeleteAllDocumentArgs) => {
    if (!clientId) {
      return;
    }

    await Promise.all(
      documents.map((document) => {
        return sdk.deletePeriodDocument({
          clientid: clientId,
          documentId: document.id,
          periodId: document.period.id,
        });
      })
    );
    dispatch(
      decreaseDocuments(
        clientId,
        financialYear,
        account,
        `${year}${month}`,
        periodId,
        documents.length
      )
    );
  };

  const copyAllDocuments = async ({
    fromAccount,
    fromPeriod,
    fromFinancialYear,
    toAccount,
    toPeriod,
    toFinancialYear,
    overwrite = false,
    move = false,
  }: {
    fromAccount: string;
    fromPeriod: Period;
    fromFinancialYear: FinancialYear;
    toAccount: string;
    toPeriod: Period;
    toFinancialYear: FinancialYear;
    overwrite?: boolean;
    move?: boolean;
  }) => {
    if (!clientId) {
      return;
    }

    const fromYear = parseFormat(fromPeriod.start, 'yyyy');
    const fromMonth = parseFormat(fromPeriod.start, 'MM');

    const toYear = parseFormat(toPeriod.start, 'yyyy');
    const toMonth = parseFormat(toPeriod.start, 'MM');

    try {
      const fromPeriodDocuments = await sdk.getPeriodDocuments({
        clientid: clientId,
        account: [fromAccount],
        periodId: [fromPeriod.id],
      });

      let toPeriodDocuments: typeof fromPeriodDocuments = [];

      if (overwrite) {
        // Only fetch the toPeriodDocuments when we need to.
        toPeriodDocuments = await sdk.getPeriodDocuments({
          clientid: clientId,
          account: [toAccount],
          periodId: [toPeriod.id],
        });

        await deleteAllDocuments({
          documents: toPeriodDocuments.map((doc) => ({
            ...doc,
            period: toPeriod,
          })),
          financialYear: toFinancialYear,
          account: toAccount,
          year: toYear,
          month: toMonth,
          periodId: toPeriod.id,
        });
      }

      if (fromPeriodDocuments.length) {
        await Promise.all(
          fromPeriodDocuments.map((document) => {
            return sdk.copyPeriodDocument({
              clientid: clientId,
              documentId: document.id,
              name: document.name,
              newAccount: toAccount,
              newPeriodId: toPeriod.id,
            });
          })
        );

        if (move) {
          await deleteAllDocuments({
            documents: fromPeriodDocuments.map((doc) => ({
              ...doc,
              period: fromPeriod,
            })),
            financialYear: fromFinancialYear,
            account: fromAccount,
            year: fromYear,
            month: fromMonth,
            periodId: fromPeriod.id,
          });
          dispatch(
            decreaseDocuments(
              clientId,
              fromFinancialYear,
              fromAccount,
              `${fromYear}${fromMonth}`,
              fromPeriod.id,
              fromPeriodDocuments.length
            )
          );
        }

        dispatch(
          increaseDocuments(
            clientId,
            toFinancialYear,
            toAccount,
            `${toYear}${toMonth}`,
            toPeriod.id,
            fromPeriodDocuments.length -
              (overwrite ? toPeriodDocuments.length : 0)
          )
        );
      }
    } catch (e) {
      console.error(e);
    }
  };

  return {
    copyAllDocuments,
    deleteAllDocuments,
  };
};

export default useDocumentsUtils;

import {
  FinancialReportStructure,
  FinancialReportCustomerType,
} from '../../types';

import { field } from '@agoy/annual-report-document';

export const frontPageConfig = (
  customer: FinancialReportCustomerType,
  period: string,
  isSinglePeriod: boolean
): FinancialReportStructure['frontPage'] => {
  return {
    section: {
      active: true,
      title: field(`Bokslutsrapport {periodTitle} för ${customer.name}`),
      clientName: field(customer.name),
    },
  };
};

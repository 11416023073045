import { id, ref, value, msg } from '@agoy/document';
import { IxbrlCell } from '../../common';
import { ixbrlField, ixbrlCell, table, field } from '../../common/utils/util';
import { AnnualGeneralMeeting, ClientInformationTables } from './types';

export const annualGeneralMeetingConfig = (
  standalone = false,
  clientInformationTables?: ClientInformationTables
): AnnualGeneralMeeting => {
  // The stand alone version of annualGeneralMeeting uses the annualReport
  // as a external document. In this case we need to prefix the referenced
  // ids.
  const annualReportId = (id) => (standalone ? `annualReport.${id}` : id);

  return {
    active: undefined,
    main: {
      active: undefined,
      heading: field(''),
      headingText: field(''),
      location: ref(id(annualReportId('settings.clientInformation.city'))),
      date: ixbrlField(field(''), {
        type: 'stringItemType',
        name: 'se-bol-base:Arsstamma',
        contextRef: 'balans0',
      }),
      votingListHeading: field(''),
      votingListHeadingText: field(''),
      votingListText: field(''),
      chairmanHeading: field(''),
      chairmanHeadingText: field(''),
      recordKeeperHeading: field(''),
      recordKeeperHeadingText: field(''),
      adjusterHeading: field(''),
      adjusterHeadingText: field(''),
      noticeAndAgendaHeading: field(''),
      noticeAndAgendaHeadingText: field(''),
      auditorReportHeading: field(''),
      auditorReportHeadingText: field(''),
      resultAndBalanceHeading: field(''),
      resultAndBalanceHeadingText: field(''),
      dischargeHeading: field(''),
      dischargeHeadingText: field(''),
      feeHeading: field(''),
      feeHeadingText: field(''),
      boardMembersHeading: field(''),
      boardMembersHeadingText: field(''),
      auditorsParagraphHeading: field(''),
      auditorsParagraphHeadingText: field(''),
      auditorsParagraphText: field(''),
      auditorTitle: field(''),
      deputyAuditorTitle: field(''),
      terminationHeading: field(''),
      terminationHeadingText: field(''),
      votingList: table(
        'annualGeneralMeeting.main.votingList',
        'name',
        'shares',
        'votes',
        'role'
      )
        .newRowTemplate(value('$name'), value(0), value(0), value(''))
        .build(),
      result: ref(
        id(annualReportId('managementReport.resultsDisposition.total'))
      ),
      resultDispositionHeading: field(''),
      resultDispositionHeadingText: field(''),
      agmApprovedResultsDisposition: field('yes'),
      resultsDisposition: table(
        // meeting approval of result
        'annualGeneralMeeting.main.resultsDisposition',
        'label',
        'value'
      )
        .addRows((rows) =>
          rows
            .addRow(
              'dividend',
              msg('Till aktieägare utdelas ({shareDividend} kr per aktie)', {
                shareDividend: id(
                  annualReportId(
                    'managementReport.profitProposal.dividendPerShare'
                  )
                ),
              }),
              ref(
                id(
                  annualReportId(
                    'managementReport.resultsDisposition.proposal.1.value'
                  )
                )
              )
            )
            .newRowTemplate(value(''), value(0))
            .addRow(
              'result',
              value('I ny räkning överföres'),
              ref(
                id(
                  annualReportId(
                    'managementReport.resultsDisposition.toTransfer'
                  )
                )
              )
            )
            .build()
        )
        .build(),
      resultsDispositionRejected: table<IxbrlCell>(
        // meeting rejection of result disposition, tagged for confirmation certificate in digital submission
        'annualGeneralMeeting.main.resultsDispositionRejected',
        'label',
        'value'
      )
        .addRows((rows) =>
          rows
            .addRow(
              'aktieutdelning',
              value('Aktieutdelning'),
              ixbrlCell(value(0), {
                type: 'monetaryItemType',
                name: 'se-bol-base:ArsstammaResultatDispositionAktieutdelning',
                contextRef: 'balans0',
              })
            )
            .addRow(
              'ianspraktagandeAvReservfond',
              value('Ianspråktagande av reservfond'),
              ixbrlCell(value(0), {
                type: 'monetaryItemType',
                name: 'se-bol-base:ArsstammaResultatDispositionIanspraktagandeReservfond',
                contextRef: 'balans0',
              })
            )
            .addRow(
              'ianspraktagandeAvBundenOverkursfond',
              value('Ianspråktagande av bunden överkursfond'),
              ixbrlCell(value(0), {
                type: 'monetaryItemType',
                name: 'se-bol-base:ArsstammaResultatDispositionIanspraktagandeBundenOverkursfond',
                contextRef: 'balans0',
              })
            )
            .addRow(
              'fondemissioner',
              value('Fondemissioner'),
              ixbrlCell(value(0), {
                type: 'monetaryItemType',
                name: 'se-bol-base:ArsstammaResultatDispositionFondemission',
                contextRef: 'balans0',
              })
            )
            .addRow(
              'ianspraktagandeAvUppskrivningsfond',
              value('Ianspråktagande av uppskrivningsfond'),
              ixbrlCell(value(0), {
                type: 'monetaryItemType',
                name: 'se-bol-base:ArsstammaResultatDispositionIanspraktagandeUppskrivningsfond',
                contextRef: 'balans0',
              })
            )
            .addRow(
              'ianspraktagandeAvFondForUtvUtg',
              value('Ianspråktagande av fond för utvecklingsutgifter'),
              ixbrlCell(value(0), {
                type: 'monetaryItemType',
                name: 'se-bol-base:ArsstammaResultatDispositionIanspraktagandeFondUtvecklingsutgifter',
                contextRef: 'balans0',
              })
            )
            .addRow(
              'avsattningTillReservfond',
              value('Avsättning till reservfond'),
              ixbrlCell(value(0), {
                type: 'monetaryItemType',
                name: 'se-bol-base:ArsstammaResultatDispositionAvsattningReservfond',
                contextRef: 'balans0',
              })
            )
            .addRow(
              'balanseringINyRakning',
              value('Balansering i ny räkning'),
              ixbrlCell(value(0), {
                type: 'monetaryItemType',
                name: 'se-bol-base:ArsstammaResultatDispositionBalanserasINyRakning',
                contextRef: 'balans0',
              })
            )
            .build()
        )
        .build(),
      resultsDispositionText: field(''),
      fee: field('no'),
      boardMembers: table(
        'annualGeneralMeeting.main.boardMembers',
        'name',
        'personNr',
        'role'
      )
        .addRows((rows) => {
          clientInformationTables?.boardMembersRows?.forEach((tableRow) =>
            rows.addRow(
              tableRow.id,
              tableRow.cells.name,
              tableRow.cells.personNr,
              tableRow.cells.role
            )
          );

          return rows.build();
        })
        .newRowTemplate(value('$name'), value('$personNr'), value('$role'))
        .build(),
      auditors: table(
        'annualGeneralMeeting.main.auditors',
        'name',
        'personNr',
        'role'
      )
        .addRows((rows) => {
          clientInformationTables?.auditorsRows?.forEach((tableRow) =>
            rows.addRow(
              tableRow.id,
              tableRow.cells.name,
              tableRow.cells.personNr,
              tableRow.cells.role
            )
          );
          return rows.build();
        })
        .newRowTemplate(value('$name'), value('$personNr'), value('$role'))
        .build(),
      auditFirm: table(
        'annualGeneralMeeting.main.auditFirm',
        'name',
        'personNr',
        'role'
      )
        .addRows((rows) => {
          clientInformationTables?.auditFirmRows?.forEach((tableRow) =>
            rows.addRow(
              tableRow.id,
              tableRow.cells.name,
              tableRow.cells.personNr,
              tableRow.cells.role
            )
          );

          return rows.build();
        })
        .newRowTemplate(value('$name'), value(''), value(''))
        .build(),
      noAuditor: field(''),
      deputyAuditors: table('annualGeneralMeeting.main.deputyAuditors', 'name')
        .newRowTemplate(value('$name'))
        .build(),
      customParagraphs: table(
        'annualGeneralMeeting.main.customParagraphs',
        'heading',
        'text'
      )
        .newRowTemplate(value('Rubrik'), value('Text...'))
        .build(),
    },
  };
};

import { VoucherTransaction } from '../../types/Voucher';

// Check if amount of Voucher Transactions Debit/Credit is equal
const isCreditAndDebitEqual = (transactions: VoucherTransaction[]): boolean => {
  const transactionSum = {
    debit: 0,
    credit: 0,
  };
  transactions.forEach((transaction) => {
    transactionSum.debit += +transaction.debit;
    transactionSum.credit += +transaction.credit;
  });
  return transactionSum.debit - transactionSum.credit === 0;
};

export default isCreditAndDebitEqual;

﻿// FIXME: Fix eslint errors
/* eslint-disable no-multi-assign */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
import {
  SieFile,
  Etikett,
  RecordType,
  Token,
  SieRecord,
  Records,
  ArrayTokenType,
  ElementTokenType,
  ElementToken,
  TokenType,
  ArrayToken,
} from 'types/SieFile';
import * as cp437 from './cp437';

type CallbackFunction<T> = (err: any, value?: T) => void;

interface SieModule {
  readText: (data: string) => SieFile;
  readBuffer: (data: Buffer) => SieFile;
}

interface ElementsObject {
  name: string;
  type: string[];
  many?: boolean;
}

interface SieParser {
  parse: (sieFileData: string) => SieFile;
  list: <T extends Etikett>(
    scan: RecordType<Etikett>[],
    etikett: T,
    includeInvalid: boolean
  ) => RecordType<T>[];

  _parseLine: (line: string) => SieRecord | undefined;
  _tokenizer: (line: string) => [ElementToken, ...Token[]];
  _Tokens: {
    ARRAY: ArrayTokenType;
    ELEMENT: ElementTokenType;
    [key: string]: TokenType;
  };
  _validateEtikett: (etikett: string) => Etikett | undefined;
  _parseAttrs: (row: SieRecord, tokens: Token[]) => SieRecord;
  _parseArray: (tokens: Token[], start, attrDef) => any;
  _Elements: {
    [key: string]: {
      fields: (ElementsObject | string)[];
      required: number;
    };
  };
  _addAttr: (
    obj: SieRecord,
    attr: string,
    token: Token[],
    i: number
  ) => boolean;
  _valuesOnly: (tokens: Token[]) => string[];
}

const sie: SieModule = {
  readText(original_data) {
    return parser.parse(original_data);
  },
  readBuffer(original_data) {
    return parser.parse(cp437.convert(original_data).toString());
  },
};

const parser: SieParser = {
  parse(sieFileData) {
    const root = new SieFileImpl();
    const lines = sieFileData.split(/\r?\n/).map((line) => line.trim());
    const stack: Records[] = [];
    let cur: Records = root;
    for (const i in lines) {
      cur.poster = cur.poster || [];
      if (lines[i] === '{') {
        stack[stack.length] = cur;
        cur = cur.poster[cur.poster.length - 1];
      } else if (lines[i] === '}') {
        cur = stack.pop() || cur;
      } else if (lines[i].startsWith('#')) {
        const post = parser._parseLine(lines[i]);
        if (post) {
          if (!post.valid) {
            post.source = lines[i];
          }
          cur.poster.push(post);
          cur.valid = cur.valid && post.valid;
        }
      }
    }
    return root;
  },
  _validateEtikett(etikett: string): Etikett | undefined {
    switch (etikett) {
      case 'ver':
      case 'trans':
      case 'ib':
      case 'ub':
      case 'res':
      case 'konto':
      case 'adress':
      case 'rar':
      case 'fnamn':
      case 'ftyp':
      case 'orgnr':
      case 'psaldo':
      case 'gen':
      case 'program':
      case 'objekt':
      case 'dim':
      case 'transactionobjekt':
        return etikett;
      default:
    }
  },
  _parseLine(line: string) {
    const tokens = parser._tokenizer(line);

    const etikett = this._validateEtikett(
      tokens[0].value!.replace(/^#/, '').toLowerCase()
    );
    if (etikett) {
      const row: SieRecord = {
        etikett,
        valid: true,
      };
      return parser._parseAttrs(row, tokens.slice(1));
    }
  },
  _Tokens: {
    ELEMENT: '#',
    BEGINARRAY: '{',
    ENDARRAY: '}',
    STRING: '"',
    ARRAY: '{}',
  },
  _tokenizer(line) {
    const tokens: [ElementToken, ...Token[]] = [
      { type: this._Tokens.ELEMENT, value: '' },
    ];
    let consume = false;
    let quoted = false;
    let doubleQuoted = false;
    for (let i = 0; i < line.length; i++) {
      if (consume) {
        if (quoted) {
          if (line[i] === '\\' && i + 1 < line.length && line[i + 1] === '"') {
            tokens[tokens.length - 1].value += line[++i];
          } else {
            quoted = consume = line[i] !== '"';
            if (doubleQuoted) {
              if (
                !quoted &&
                i + 2 < line.length &&
                line[i + 1] === '"' &&
                line[i + 2] === '"'
              ) {
                doubleQuoted = false;
                i += 2;
              } else {
                quoted = true;
              }
            }
            if (consume) {
              tokens[tokens.length - 1].value += line[i];
            }
            consume = consume || doubleQuoted;
          }
        } else {
          consume = line[i] !== ' ' && line[i] !== '\t' && line[i] !== '}';
          if (consume) {
            tokens[tokens.length - 1].value += line[i];
          } else if (line[i] === '}') {
            tokens[tokens.length] = { type: parser._Tokens.ENDARRAY };
          }
        }
      } else if (line[i] === '#') {
        consume = true;
      } else if (line[i] === '{') {
        tokens[tokens.length] = { type: parser._Tokens.BEGINARRAY };
      } else if (line[i] === '}') {
        tokens[tokens.length] = { type: parser._Tokens.ENDARRAY };
      } else if (line[i] === '"') {
        consume = quoted = true;
        if (i + 2 < line.length && line[i + 1] === '"' && line[i + 2] === '"') {
          doubleQuoted = true;
          i += 2;
        }
        tokens[tokens.length] = { type: parser._Tokens.STRING, value: '' };
      } else if (line[i] !== ' ' && line[i] !== '\t') {
        consume = true;
        tokens[tokens.length] = {
          type: parser._Tokens.STRING,
          value: line[i],
        };
      }
    }
    return tokens;
  },
  _parseAttrs(row, tokens) {
    const { fields, required } = parser._Elements[row.etikett];
    let addedFields = 0;
    if (parser._Elements[row.etikett]) {
      let i;
      for (i = 0; i < fields.length; i++) {
        const elem = fields[i];
        if (typeof elem === 'object') {
          parser._parseArray(tokens, i, elem);
          if (parser._addAttr(row, elem.name, tokens, i)) {
            addedFields++;
          }
        } else if (parser._addAttr(row, elem, tokens, i)) {
          addedFields++;
        }
      }
      if (addedFields < required) {
        row.valid = false;
      }
    }
    return row;
  },
  _parseArray(tokens, start, attrDef) {
    const startToken: ArrayToken = {
      type: parser._Tokens.ARRAY,
      value: [],
    };

    for (let i = start + 1; i < tokens.length; i++) {
      if (tokens[i].type === parser._Tokens.ENDARRAY) {
        startToken.value = parser._valuesOnly(tokens.slice(start + 1, i));
        tokens.splice(start, i - start + 1, startToken);

        const a: any[] = [];
        for (
          let j = 0;
          j < startToken.value.length - attrDef.type.length + 1;
          j += attrDef.type.length
        ) {
          const o = {};
          for (let k = 0; k < attrDef.type.length; k++) {
            o[attrDef.type[k]] = startToken.value[j + k];
          }
          a[a.length] = o;
        }
        startToken.value = attrDef.many ? a : a[0] || null;
      }
    }
  },
  _addAttr(obj, attr, tokens, pos) {
    if (pos < tokens.length) {
      obj[attr] = tokens[pos].value;
      if (obj[attr] === undefined) {
        obj.valid = false;
      } else {
        return true;
      }
    }
    return false;
  },
  _valuesOnly(tokens) {
    return tokens.flatMap((token) => token.value || '');
  },
  _Elements: {
    adress: {
      fields: ['kontakt', 'utdelningsadr', 'postadr', 'tel'],
      required: 0,
    },
    bkod: { fields: ['SNI-kod'], required: 0 },
    dim: { fields: ['dimensionsnr', 'namn'], required: 0 },
    enhet: { fields: ['kontonr', 'enhet'], required: 0 },
    flagga: { fields: ['x'], required: 0 },
    fnamn: { fields: ['företagsnamn'], required: 0 },
    fnr: { fields: ['företagsid'], required: 0 },
    format: { fields: ['PC8'], required: 0 },
    ftyp: { fields: ['företagstyp'], required: 0 },
    gen: { fields: ['datum', 'sign'], required: 1 },
    ib: { fields: ['årsnr', 'konto', 'saldo', 'kvantitet'], required: 3 },
    konto: { fields: ['kontonr', 'kontonamn'], required: 0 },
    kptyp: { fields: ['typ'], required: 0 },
    ktyp: { fields: ['kontonr', 'kontotyp'], required: 0 },
    objekt: {
      fields: ['dimensionsnr', 'objektnr', 'objektnamn'],
      required: 0,
    },
    oib: {
      fields: [
        'årsnr',
        'konto',
        { name: 'objekt', type: ['dimensionsnr', 'objektnr'] },
        'saldo',
        'kvantitet',
      ],
      required: 0,
    },
    omfattn: { fields: ['datum'], required: 0 },
    orgnr: { fields: ['orgnr', 'förvnr', 'verknr'], required: 0 },
    oub: {
      fields: [
        'årsnr',
        'konto',
        { name: 'objekt', type: ['dimensionsnr', 'objektnr'] },
        'saldo',
        'kvantitet',
      ],
      required: 0,
    },
    pbudget: {
      fields: [
        'årsnr',
        'period',
        'konto',
        { name: 'objekt', type: ['dimensionsnr', 'objektnr'] },
        'saldo',
        'kvantitet',
      ],
      required: 0,
    },
    program: { fields: ['programnamn', 'version'], required: 1 },
    prosa: { fields: ['text'], required: 0 },
    psaldo: {
      fields: [
        'årsnr',
        'period',
        'konto',
        { name: 'objekt', type: ['dimensionsnr', 'objektnr'] },
        'saldo',
        'kvantitet',
      ],
      required: 5,
    },
    rar: { fields: ['årsnr', 'start', 'slut'], required: 3 },
    res: { fields: ['års', 'konto', 'saldo', 'kvantitet'], required: 3 },
    sietype: { fields: ['typnr'], required: 0 },
    sru: { fields: ['konto', 'SRU-kod'], required: 2 },
    taxar: { fields: ['år'], required: 1 },
    trans: {
      fields: [
        'kontonr',
        { name: 'objektlista', type: ['dimensionsnr', 'objektnr'], many: true },
        'belopp',
        'transdat',
        'transtext',
        'kvantitet',
        'sign',
      ],
      required: 3,
    },
    rtrans: {
      fields: [
        'kontonr',
        { name: 'objektlista', type: ['dimensionsnr', 'objektnr'], many: true },
        'belopp',
        'transdat',
        'transtext',
        'kvantitet',
        'sign',
      ],
      required: 0,
    },
    btrans: {
      fields: [
        'kontonr',
        { name: 'objektlista', type: ['dimensionsnr', 'objektnr'], many: true },
        'belopp',
        'transdat',
        'transtext',
        'kvantitet',
        'sign',
      ],
      required: 0,
    },
    ub: { fields: ['årsnr', 'konto', 'saldo', 'kvantitet'], required: 3 },
    underdim: {
      fields: ['dimensionsnr', 'namn', 'superdimension'],
      required: 0,
    },
    valuta: { fields: ['valutakod'], required: 0 },
    ver: {
      fields: ['serie', 'vernr', 'verdatum', 'vertext', 'regdatum', 'sign'],
      required: 3,
    },
  },
  list<T extends Etikett>(
    scan: RecordType<Etikett>[],
    etikett: T,
    includeInvalid: boolean
  ): RecordType<T>[] {
    return scan.filter(
      (p) => (includeInvalid ? true : p.valid) && p.etikett === etikett
    ) as RecordType<T>[];
  },
};

class SieFileImpl implements SieFile {
  poster = [];

  valid = true;

  list<T extends Etikett>(etikett: T, includeInvalid = false): RecordType<T>[] {
    return parser.list(this.poster, etikett, includeInvalid);
  }
}

export default sie;
export const { readBuffer } = sie;
export const { readText } = sie;

// eslint-disable-next-line
import React, { useState, useEffect, useRef } from 'react';

/**
 * Use this function to limit how often a variable can change value
 * @param value The variable you want to debounce
 * @param delay the amount of delay you want
 * @param immediate (optional) if true the first change will happen direct and the following will wait for a delay.
 */
export default function useDebounce<T>(
  value: T,
  delay: number,
  immediate?: boolean
): T {
  const [debouncedValue, setDebouncedValue] = useState(value);
  const debouncing = useRef(false);

  useEffect(() => {
    let handler;
    if (immediate && !debouncing.current) {
      setDebouncedValue(value);
      handler = setTimeout(() => {
        debouncing.current = false;
      }, delay);
    } else {
      handler = setTimeout(() => {
        debouncing.current = false;
        setDebouncedValue(value);
      }, delay);
    }
    debouncing.current = true;
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay, debouncing, immediate]);

  return debouncedValue;
}

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { FormHelperText, TableCell, Typography } from '@material-ui/core';
import { format, isAfter, isBefore } from 'date-fns';
import { useIntl } from 'react-intl';
import {
  NonReferenceCell,
  Table,
  TableServiceType,
} from '_shared/components/CommonTable';
import TableRow from '_shared/components/CommonTable/TableRow';
import { AgoyTableRow } from '@agoy/document';
import { useDispatch } from 'react-redux';
import { addGlobalErrorMessage } from 'redux/actions';
import { ccyFormat, ccyParse } from '@agoy/common';
import {
  CompanyConnection,
  StockTotal,
  StockTransaction,
} from '_client-connections/types';
import { updateTransactions } from '_client-connections/redux/persons/actions';
import { setUiStatus } from 'redux/actions/UI';
import CurrencyField from '_shared/components/Inputs/CurrencyField';
import StringInput from '_shared/components/CommonTable/Cell/Field/StringInput';
import { StyledDateField } from './TotalTransactionsTable';
import ConnectionsTableHeader from './ConnectionsTableHeader';

const Container = styled.div`
  margin-top: ${({ theme }) => theme.spacing(4)}px;
`;

const TableHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const ControlButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)}px;
`;

const NumberInput = styled(StringInput)`
  text-align: right;
`;

const StyledTableCell = styled(TableCell)`
  vertical-align: top;
  background-color: inherit;

  &.error-shareChange input {
    border: 1px solid red;
  }
`;

const validateDate = (
  formatMessage: ({ id }) => string,
  dateValue: string | null | undefined,
  initialStockTotal?: StockTotal
) => {
  if (
    dateValue &&
    initialStockTotal &&
    isBefore(new Date(dateValue), new Date(initialStockTotal.date))
  ) {
    return formatMessage({
      id: 'connections.validation.shareTransaction.dateShouldNotBeBeforeInitial',
    });
  }
  return '';
};

const getSharesSum = (
  stockTransactions: StockTransaction[],
  currentTransactionDate?: string
): number => {
  let transactionsToSum = stockTransactions;
  if (currentTransactionDate) {
    const transactionDateObject = new Date(currentTransactionDate);
    transactionsToSum = stockTransactions.filter(
      (shares) =>
        currentTransactionDate === shares.transactionDate ||
        isBefore(new Date(shares.transactionDate), transactionDateObject)
    );
  }

  return transactionsToSum.reduce(
    (total, share) => total + share.shareChange,
    0
  );
};

export const validateShares = (
  formatMessage: ({ id }) => string,
  newValue: number | undefined,
  stockTransactions: StockTransaction[],
  stockTotals: StockTotal[],
  otherShares: StockTransaction[]
): string => {
  if (newValue) {
    const sharesInTotal =
      getSharesSum(stockTransactions.slice(1)) +
      getSharesSum(otherShares) +
      newValue;

    // New share change should not cause latest share sum to exceed latest stock
    if (
      stockTotals[0]?.shareTotal &&
      sharesInTotal > stockTotals[0]?.shareTotal
    ) {
      return formatMessage({
        id: 'connections.validation.shareTransaction.shareChangeShouldNotBeHigherThanTotal',
      });
    }

    const { transactionDate } = stockTransactions[0];

    if (transactionDate) {
      const transactionDateObject = new Date(transactionDate);

      const actualStockTotal = stockTotals.find(
        (total) =>
          transactionDate === total.date ||
          isAfter(transactionDateObject, new Date(total.date))
      );
      if (actualStockTotal) {
        const actualShares =
          getSharesSum(stockTransactions.slice(1), transactionDate) + newValue;

        const otherPreviousSharesSum = getSharesSum(
          otherShares,
          transactionDate
        );

        // Shares sum on the current date should not be higher than available stock
        if (
          actualShares >
          actualStockTotal.shareTotal - otherPreviousSharesSum
        ) {
          return formatMessage({
            id: 'connections.validation.shareTransaction.shareChangeShouldNotBeHigherThanTotal',
          });
        }
      }
    }
  }
  return '';
};

type Props = {
  onStockUpdate: () => void;
  companyConnection?: CompanyConnection;
  sharesInCurrentCompany: StockTransaction[];
};

const ShareTransactionsTable = ({
  onStockUpdate,
  companyConnection,
  sharesInCurrentCompany,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const text = (
    id: string,
    values?: Record<string, string | number | undefined>
  ) => formatMessage({ id: `connections.detail.ownedShares.${id}` }, values);

  const [stockTransactions, setStockTransactions] = useState<
    StockTransaction[]
  >(companyConnection?.stockTransactions || []);

  const { relationId = 0, stockTotals } = companyConnection || {};

  const [errors, setErrors] = useState({
    shareChange: '',
    transactionDate: '',
  });

  useEffect(() => {
    if (companyConnection) {
      setStockTransactions(companyConnection.stockTransactions || []);
    }
  }, [companyConnection]);

  /**
   * How editing works:
   *   - All the changes are stored on local state.
   *   - Only first row is editable.
   *   - Edited, deleted, created rows are applied on switching from editing mode to view mode.
   *   - Editing a row deletes old one and creates a new one.
   *   - New rows are defined by absence of id.
   *   - Deleted rows are the ones which are present in the model, but not present in local state.
   */
  const [editing, setEditing] = useState(false);

  const saveChanges = useCallback(async () => {
    dispatch(setUiStatus({ updatingConnections: true }));
    try {
      await dispatch(
        updateTransactions(
          relationId,
          companyConnection?.stockTransactions || [],
          stockTransactions
        )
      );

      await onStockUpdate();
    } catch (e) {
      dispatch(addGlobalErrorMessage('error'));
    }
    setEditing(false);
    dispatch(setUiStatus({ updatingConnections: false }));
  }, [stockTransactions]);

  const discardChanges = () => {
    setStockTransactions(companyConnection?.stockTransactions || []);
    setEditing(false);
    setErrors({ shareChange: '', transactionDate: '' });
  };

  const updateField = useCallback(
    (id, value) => {
      const [, , field] = id.split('.');

      setStockTransactions((oldTransactions) => [
        {
          ...oldTransactions[0],
          id: undefined,
          [field]: value,
        },
        ...oldTransactions.slice(1),
      ]);
    },
    [setStockTransactions]
  );

  const addNewTransaction = useCallback(() => {
    setStockTransactions((oldTransactions) => [
      {
        shareChange: 0,
        valuePerShare: 0,
        transactionDate:
          oldTransactions[0]?.transactionDate ||
          format(new Date(), 'yyyy-MM-dd'),
      },
      ...oldTransactions,
    ]);
  }, [setStockTransactions]);

  const deleteTransaction = useCallback(() => {
    setStockTransactions((oldTransactions) => oldTransactions.slice(1));
    setErrors({
      shareChange: '',
      transactionDate: '',
    });
  }, [setStockTransactions]);

  const service = useMemo(() => {
    const newService = {} as TableServiceType;
    newService.addRow = addNewTransaction;
    newService.deleteRow = deleteTransaction;
    newService.updateField = updateField;
    return newService;
  }, []);

  const columns = [
    { id: 'number', label: '#' },
    {
      id: 'transactionDate',
      label: text('table.acquisitionDate'),
    },
    { id: 'shareChange', label: text('table.shareChange') },
    {
      id: 'valuePerShare',
      label: text('table.costPerShare'),
    },
    { id: 'comment', label: text('table.comment') },
  ];

  const getStockTransactionsValue = (transactions, i, key) => {
    if (transactions?.length) {
      return transactions[i][key];
    }
  };

  const rows: AgoyTableRow[] = useMemo(
    () =>
      stockTransactions.map((item, index) => {
        const cells = {};

        columns.forEach((col) => {
          cells[col.id] = {
            type: 'string',
            value: '',
          };

          if (col.id === 'number') {
            cells[col.id].value = stockTransactions.length - index;
          } else if (![null, undefined].includes(item[col.id])) {
            cells[col.id].value = item[col.id];
            cells[col.id].type = ['shareChange', 'valuePerShare'].includes(
              col.id
            )
              ? 'number'
              : 'string';
          }
        });

        return {
          id: `${index}`,
          active: true,
          cells,
        };
      }),
    [stockTransactions]
  );

  return (
    <Container>
      <ConnectionsTableHeader
        title={text('title')}
        editButtonText={text('editTransactions')}
        editing={editing}
        onEdit={() => setEditing(true)}
        onCancel={discardChanges}
        onSave={saveChanges}
        saveButtonDisabled={!!errors.shareChange || !!errors.transactionDate}
      />
      <Table
        baseId="connectionToCompany"
        tableId="ownedShares"
        canAddRows={!errors.shareChange && !errors.transactionDate}
        canDeleteRows
        editing={editing}
        service={service}
        columns={columns}
        rows={rows}
        renderRow={(row, index) => (
          <TableRow
            baseId="connectionToCompany"
            tableId={row.baseId}
            row={row.row}
            columns={columns}
            editing={editing}
            isLocked={index !== 0}
            numberFormatColumn={(col) => ({
              type: 'standard',
              precision: col === 'valuePerShare' ? 2 : 0,
            })}
            canAddRows={!errors.shareChange && !errors.transactionDate}
            canDeleteRows
            renderCell={({ column, cell }) => {
              if (editing && index === 0) {
                const key = `${row.row.id}.${column.id}`;
                if (column.id === 'transactionDate') {
                  return (
                    <StyledTableCell key={key}>
                      <StyledDateField
                        editing
                        onChange={(dateValue) => {
                          const newValue = dateValue || '';
                          updateField(`ownedShares.${key}`, newValue);
                          setErrors({
                            ...errors,
                            transactionDate: validateDate(
                              formatMessage,
                              newValue,
                              stockTotals?.[stockTotals.length - 1]
                            ),
                            shareChange: validateShares(
                              formatMessage,
                              stockTransactions[0].shareChange,
                              [
                                {
                                  ...stockTransactions[0],
                                  transactionDate: newValue,
                                },
                                ...stockTransactions.slice(1),
                              ],
                              stockTotals || [],
                              sharesInCurrentCompany
                            ),
                          });
                        }}
                        value={getStockTransactionsValue(
                          stockTransactions,
                          index,
                          column.id
                        )}
                        error={!!errors.transactionDate}
                        helperText={errors.transactionDate}
                      />
                    </StyledTableCell>
                  );
                }
                if (column.id === 'shareChange') {
                  return (
                    <StyledTableCell
                      key={key}
                      className={errors.shareChange ? ' error-shareChange' : ''}
                    >
                      <CurrencyField
                        value={cell.type === 'number' ? cell.value : undefined}
                        onValueChange={(newValue) => {
                          updateField(`totalShares.${key}`, newValue);
                          setErrors({
                            ...errors,
                            shareChange: validateShares(
                              formatMessage,
                              newValue,
                              stockTransactions,
                              stockTotals || [],
                              sharesInCurrentCompany
                            ),
                          });
                        }}
                        Input={NumberInput}
                        formatter={ccyFormat}
                        parser={ccyParse}
                        displayDecimals={0}
                        editingDecimals={0}
                      />
                      {errors.shareChange && (
                        <FormHelperText error>
                          {errors.shareChange}
                        </FormHelperText>
                      )}
                    </StyledTableCell>
                  );
                }

                return (
                  <StyledTableCell key={key}>
                    <NonReferenceCell
                      cell={cell}
                      id={`${row.baseId}.${key}`}
                      numberFormatType={{
                        type: 'standard',
                        precision: column.id === 'valuePerShare' ? 2 : 0,
                      }}
                      editing
                      active
                      isLocked={column.id === 'number'}
                    />
                    <FormHelperText>
                      {column.id === 'valuePerShare' &&
                      getStockTransactionsValue(
                        stockTransactions,
                        index,
                        column.id
                      ) === 0
                        ? formatMessage({
                            id: 'connections.optionalSharesWarning',
                          })
                        : undefined}
                    </FormHelperText>
                  </StyledTableCell>
                );
              }

              return null;
            }}
          />
        )}
      />
    </Container>
  );
};

export default ShareTransactionsTable;

import { formatFinancialYear } from '@agoy/common';
import {
  FinancialReportCustomerType,
  FinancialReportStructure,
} from '../../types';

import {
  previousYear,
  year,
  previousYearStart,
  field,
  table,
} from '@agoy/annual-report-document';

export const settingsConfig = (
  userName: string,
  customer: FinancialReportCustomerType,
  financialYear: string,
  endPeriod: string | null,
  isFirstFinancialYear: boolean,
  isSinglePeriod: boolean
): FinancialReportStructure['settings'] => {
  return {
    section: {
      active: undefined,
      title: field(''),
      secondaryText: field(''),
      clientName: field(customer.name),
      reportPerson: field(userName),
      isSinglePeriod,
      period: field(formatFinancialYear(financialYear)),
      financialYear: field(financialYear),
      periodStart: field(
        formatFinancialYear(financialYear)?.substring(0, 10) ?? ''
      ),
      periodEnd: field(
        year(financialYear, customer.financialYears, endPeriod) ?? ''
      ),
      previousPeriodStart: field(
        previousYearStart(financialYear, customer.financialYears) ?? ''
      ),
      previousPeriodEnd: field(
        previousYear(financialYear, customer.financialYears) ?? ''
      ),
      organisationNumber: field(customer.orgNumber ?? ''),
      isFirstFinancialYear,
      logoLargeUrl: field(customer.logoOriginal ?? ''),
      logoUrl: field(customer.logo ?? ''),
      colorTextMain: field(''),
      colorTextSecondary: field(''),
      colorBackground: field(''),
      keyValuesPercent: field(0.1),
      noRiskPercent: field(0.03),
      formatTextValuesInThousands: false,
      formatDiagramValuesInThousands: false,
      formatTableValuesInThousands: false,
      isTextRandomizationEnabled: true,
      useClientLogo: false,
      keyValuesType: field(1),
      comparePeriod: field(1),
    },
    warnings: {
      table: table('settings.warnings.table').build(),
    },
  };
};

export const settingsReferences = (): Record<string, string> => ({});

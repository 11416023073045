import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import styled from '@emotion/styled';
import {
  TextField,
  Checkbox,
  FormControlLabel as MuiFormControlLabel,
  List,
  Typography,
  Tooltip,
} from '@material-ui/core';

const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${({ theme }) => theme.spacing(1)}px;
  gap: ${({ theme }) => theme.spacing(1)}px;
  background-color: #fff;
  box-shadow: ${({ theme }) => theme.shadows[2]};
`;

const StyledCheckbox = styled(Checkbox)`
  padding: 0px ${({ theme }) => theme.spacing(1)}px;
`;

const ResetFilters = styled.span`
  width: fit-content;
  align-self: flex-end;
  cursor: pointer;
  font-weight: 400;

  color: ${({ theme }) => theme.palette.grey[600]};

  :hover {
    color: ${({ theme }) => theme.palette.grey[800]};
  }
`;

const Label = styled(Typography)`
  text-align: left;
  display: block;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SearchField = styled(TextField)`
  min-width: 240px;
  .MuiInputBase-input {
    background-color: ${({ theme }) => theme.palette.grey[100]};
  }
  @media screen and (max-width: 1420px) {
    min-width: 160px;
  }
`;

const FormControlLabel = styled(MuiFormControlLabel)`
  justify-content: center;
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin-bottom: 5px;
`;

const CheckboxContainer = styled(List)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: auto;
  height: 180px;
`;

export type Item = {
  id: string;
  name: string;
  email?: string;
};

export interface FilterProps {
  defaultItemsList: Item[];
  activeItemsList: Item[];
  onSelect: (item: Item) => void;
  onEraseFilters: () => void;
}

const TagFilter = ({
  defaultItemsList,
  activeItemsList,
  onSelect,
  onEraseFilters,
}: FilterProps) => {
  const intl = useIntl();
  const [search, setSearch] = useState<string | null>(null);
  const [filteredItems, setFilteredItems] = useState<Item[]>([]);

  const handleSearch = (
    ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const unselectedItems = defaultItemsList.filter(
      (item) => !activeItemsList.find((active) => active.id === item.id)
    );
    const updatedItems = [...activeItemsList, ...unselectedItems].filter(
      (item) => item.name.toLowerCase().includes(ev.target.value.toLowerCase())
    );
    setFilteredItems(updatedItems);
    setSearch(ev.target.value);
  };

  const text = (id: string) => intl.formatMessage({ id });

  useEffect(() => {
    const unselectedItems = defaultItemsList.filter(
      (item) => !activeItemsList.find((active) => active.id === item.id)
    );
    setFilteredItems([...activeItemsList, ...unselectedItems]);
  }, [defaultItemsList, activeItemsList]);

  return (
    <FilterContainer>
      <SearchField
        id="search-customer"
        variant="outlined"
        size="small"
        placeholder={text('dashboard.customers.sorter.search.placeHolder')}
        onChange={(ev) => {
          handleSearch(ev);
        }}
        value={search || ''}
      />
      <CheckboxContainer>
        {filteredItems.map((item: Item) => {
          return (
            <Tooltip
              key={`${item.id} ${item.name}`}
              title={`${item.name}${item?.email ? ` (${item.email})` : ''}`}
            >
              <FormControlLabel
                control={
                  <StyledCheckbox
                    name="tag"
                    color="default"
                    checked={
                      !!activeItemsList.find(
                        (activeItem) => activeItem.id === item.id
                      )
                    }
                    onChange={() => {
                      setSearch('');
                      onSelect(item);
                    }}
                  />
                }
                label={<Label>{item.name}</Label>}
              />
            </Tooltip>
          );
        })}
      </CheckboxContainer>
      <ResetFilters onClick={onEraseFilters}>Rensa filter</ResetFilters>
    </FilterContainer>
  );
};

export default TagFilter;

import { setUserAttributes as doSetUserAttributes } from 'utils/AwsAsync';
import { setUser } from '@sentry/react';
import {
  SET_USER_LOGGED_IN,
  SET_USER_ATTRIBUTES,
  SET_USER_LOGGED_OUT,
  USER_IS_AUTHENTICATING,
  USER_NOT_AUTHENTICATING,
  SET_USER_FIRST_LOGIN,
  USER_LOGOUT,
} from './action-types';
import { UserType } from '../types';

export const setUserLoggedIn = () => ({
  type: SET_USER_LOGGED_IN,
});

export const insertUserAttributes = (userAttributes) => {
  setUser(userAttributes ? { email: userAttributes.email } : null);

  return {
    type: SET_USER_ATTRIBUTES,
    userAttributes: {
      ...(userAttributes || {}),
      fullName:
        userAttributes?.fullName ||
        `${userAttributes?.givenName} ${userAttributes?.familyName}`,
    },
  };
};

export const userIsAuthenticating = () => {
  return {
    type: USER_IS_AUTHENTICATING,
  };
};

export const userNotAuthenticating = () => ({
  type: USER_NOT_AUTHENTICATING,
});

export const setUserLoggedOut = () => ({
  type: SET_USER_LOGGED_OUT,
});

export const setUserAttributes = (userAttributes: Partial<UserType>) => {
  return async (dispatch) => {
    const apiCall = await doSetUserAttributes(userAttributes);
    dispatch(insertUserAttributes(userAttributes));
    return apiCall;
  };
};

export const setUserFirstLogin = () => ({
  type: SET_USER_FIRST_LOGIN,
});

export const userLogout = () => {
  setUser(null);
  return {
    type: USER_LOGOUT,
  };
};

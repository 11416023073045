import React, { useState, useCallback } from 'react';
import styled from '@emotion/styled';
import { IconButton } from '@material-ui/core';
import { CloudDownload, Delete } from '@material-ui/icons';
import { keyframes } from '@emotion/react';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { useIntl } from 'react-intl';
import DownloadLink from 'utils/DownloadLink';
import LoadingLogo from '_shared/components/LoadingLogo';
import { formatYearVariable } from 'utils/document-util';
import { parseFormat } from '@agoy/dates';
import { asResultClass, getApiSdk } from 'api-sdk';
import { getContext } from 'utils/AgoyAppClient/contextHolder';

const TableCell = styled.div<{
  align?: string;
}>`
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.align || 'left'};
  padding: ${({ theme }) => theme.spacing(1)}px;
`;

const rowHeight = 60;
const rowEnter = keyframes`
  from {
      height: 0;
  }
  to {
      height: ${rowHeight}px;
  }
`;

const Row = styled.div<{ visible: boolean }>`
  display: ${(props) => (props.visible ? 'contents' : 'none')};
  & > * {
    height: ${rowHeight}px;
    transition: height 0.25s ease-in-out;
    animation: ${rowEnter} 0.25s ease-in-out;
    overflow-y: hidden;
  }

  &:hover > * {
    background: ${(props) => props.theme.palette.accountingView.cell.ok};
  }
`;

const ErrorMessage = styled(Alert)`
  grid-column: span 4;
`;

type FileState = 'ready' | 'deleting' | 'deleted' | 'downloading';

const Document = ({
  name,
  category,
  lastModified,
  verifyDelete,
  customerId: clientId,
  url,
  year,
  onInvalidDocument,
  onDelete,
}): JSX.Element => {
  const [fileState, setFileState] = useState<FileState>('ready');
  const [downloadError, setDownloadError] = useState<string | null>(null);
  const [deleteError, setDeleteError] = useState(false);

  const onDownloading = async (downloading, error) => {
    setFileState(downloading ? 'downloading' : 'ready');
    if (error === '403') {
      try {
        await onInvalidDocument(error);
      } catch {
        setDownloadError(error);
      }
    } else {
      setDownloadError(error);
    }
  };

  const handleDelete = useCallback(() => {
    verifyDelete(name, async () => {
      setFileState('deleting');
      try {
        const sdk = getApiSdk(getContext());
        const formattedYear = formatYearVariable(year);
        const result = await asResultClass(
          sdk.deleteClientDocument({
            clientid: clientId,
            year: formattedYear,
            category,
            name,
            program: 'SYSTEM',
            section: 'DOCUMENTS',
          })
        );
        if (result.ok) {
          onDelete();
          setDeleteError(false);
          setFileState('deleted');
        } else {
          setDeleteError(true);
          setFileState('ready');
        }
      } catch (e) {
        setDeleteError(true);
        setFileState('ready');
      }
    });
  }, [verifyDelete, name, year, clientId, category, onDelete]);

  const { formatMessage } = useIntl();

  return (
    <Row visible={fileState !== 'deleted'}>
      {deleteError && (
        <ErrorMessage severity="error">
          <AlertTitle>
            {formatMessage({ id: 'documents.delete.failed.title' })}
          </AlertTitle>
          {formatMessage({ id: 'documents.delete.failed.text' })}
        </ErrorMessage>
      )}
      {downloadError && (
        <ErrorMessage severity="error">
          <AlertTitle>
            {formatMessage({ id: 'documents.download.failed.title' })}
          </AlertTitle>
          {formatMessage({ id: 'documents.download.failed.text' })}
        </ErrorMessage>
      )}
      <TableCell>{name}</TableCell>
      <TableCell>
        <span title={parseFormat(lastModified, 'yyyy-MM-dd HH:mm')}>
          {parseFormat(lastModified, 'yyyy-MM-dd')}
        </span>
      </TableCell>
      <TableCell align="center">
        <DownloadLink title={name} href={url} onDownloading={onDownloading}>
          <IconButton disabled={fileState !== 'ready'}>
            {fileState === 'downloading' ? (
              <LoadingLogo size="small" />
            ) : (
              <CloudDownload />
            )}
          </IconButton>
        </DownloadLink>
      </TableCell>
      <TableCell align="center">
        <IconButton onClick={handleDelete} disabled={fileState !== 'ready'}>
          {fileState === 'deleting' ? <LoadingLogo size="small" /> : <Delete />}
        </IconButton>
      </TableCell>
    </Row>
  );
};

export default Document;

import { getApiSdk } from 'api-sdk';

// Global action types, shared by the whole app
export const INIT_STATE = 'shared/INIT_STATE';
export const SET_NO_SIE_DATA = 'shared/SET_NO_SIE_DATA';
export const RESET_UNSAFE_CLIENT_DATA = 'shared/RESET_UNSAFE_CLIENT_DATA';
export const SET_CURRENT_PERIOD = 'shared/SET_CURRENT_PERIOD';
export const SET_PROGRAM_PERIODS_STATUS = 'shared/SET_PROGRAM_PERIODS_STATUS';
export const SET_PROGRAM_FINANCIAL_YEAR_STATUS =
  'shared/SET_PROGRAM_FINANCIAL_YEAR_STATUS';

type Sdk = Awaited<ReturnType<typeof getApiSdk>>;
export type ProgramStatuses = Awaited<
  ReturnType<Sdk['getProgramStatuses']>
>[string];

export type ProgramPeriodStatuses = (ProgramStatuses[number] & {
  periodId: number;
})[];

export type ProgramFinancialYearStatuses = (ProgramStatuses[number] & {
  financialYearId: number;
})[];

const getImageResolution = (
  dataURL: string
): Promise<{ height: number; width: number }> =>
  new Promise((resolve, reject) => {
    const img = new Image();

    img.onload = () => {
      resolve({
        height: img.height,
        width: img.width,
      });
    };

    img.onerror = () => {
      reject();
    };

    img.src = dataURL;
  });

export default getImageResolution;

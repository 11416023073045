/* eslint-disable no-console */
// eslint-disable-next-line import/no-cycle
import { ResetTaxDeclarationFormField } from 'redux/actions';
import { isError, operations } from '@agoy/document';
import {
  TaxDeclarationFormData,
  TaxDeclarationFormStructure,
} from '@agoy/tax-declaration-document';
import TaxDeclarationViewState from '../types';

const validateField = (
  formDefintion: TaxDeclarationFormStructure,
  partId: string,
  id: string
) => {
  const part = formDefintion.children[partId];
  if (part && 'children' in part && part.partType === 'form') {
    return part.children.fields.children[id]?.type === 'field';
  }
  return false;
};

const resetTaxDeclarationFormField = (
  state: TaxDeclarationViewState,
  action: ResetTaxDeclarationFormField
): TaxDeclarationViewState => {
  if (Object.keys(state.formData).length === 0) {
    return state;
  }

  const selectedFormId = state.viewFormId;
  if (!selectedFormId) return state;

  const formData = state.formData[selectedFormId];
  if (!formData) {
    return state;
  }
  const { partId, id, fieldConfig } = action;

  // validate that field is part of definition of form and original field config is provided
  if (!validateField(formData.definition, partId, id) || !fieldConfig) {
    return state;
  }

  const part = formData.document[partId];
  if (typeof part !== 'object' || Array.isArray(part)) {
    return state;
  }

  const result = operations.resetField(
    formData.definition,
    formData,
    `${partId}.fields.${id}`
  );

  if (!result) {
    return state;
  }
  if (isError(result)) {
    console.warn(result);
    return state;
  }

  return {
    ...state,
    formData: {
      ...state.formData,
      [selectedFormId]: {
        ...formData,
        document: result.document as TaxDeclarationFormData,
        changes: result.changes,
      },
    },
  };
};

export default resetTaxDeclarationFormField;

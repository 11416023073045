import React, { useState, useMemo } from 'react';
import { useIntl } from 'react-intl';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import styled from '@emotion/styled';
import { setMonth, getYear } from 'date-fns';

import { isSameOrAfter, isSameOrBefore, parse, format } from '@agoy/dates';
import PeriodCell from './PeriodCell';
import Button from '../Buttons/Button';

const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'Maj',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Okt',
  'Nov',
  'Dec',
];

const Wrapper = styled.div`
  border-color: ${(props) => props.theme.palette.primary.main};
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;

  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  height: ${(props) => props.theme.spacing(4)}px;

  color: white;
  background-color: ${(props) => props.theme.palette.primary.main};

  display: flex;
  align-items: center;
  justify-content: space-around;
`;

const Body = styled.div`
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-column-gap: ${(props) => props.theme.spacing(1)}px;
  grid-row-gap: ${(props) => props.theme.spacing(1)}px;
  margin: ${(props) => props.theme.spacing(1)}px;
`;

const SelectAllButton = styled.div`
  margin: ${(props) => props.theme.spacing(1)}px auto;
`;

type PeriodCalendarProps = {
  isShown: boolean;
  enabledRange: { start: Date; end: Date };
  activeYear: number;
  selectedPeriods: { [periodString: string]: boolean };
  handlePeriodSelect: (periodString: string) => void;
  handleSelectAll: (year: number) => void;
  handleDeselectAll: (year: number) => void;
  renderButton: (disabled: boolean) => JSX.Element;
};

const PeriodCalendar = ({
  isShown,
  enabledRange: { start, end },
  activeYear,
  selectedPeriods,
  handlePeriodSelect,
  handleSelectAll,
  handleDeselectAll,
  renderButton,
}: PeriodCalendarProps): JSX.Element => {
  const { formatMessage } = useIntl();

  const [year, setYear] = useState(activeYear);

  const handleSelectButton = () => {
    if (Object.keys(selectedPeriods).length > 1) {
      handleDeselectAll(year);
    } else {
      handleSelectAll(year);
    }
  };

  const isButtonActive = useMemo(() => {
    const rangeStartYear = getYear(start);
    const rangeEndYear = getYear(end);

    return (
      (year >= rangeStartYear && rangeEndYear >= year) ||
      Object.keys(selectedPeriods).length > 1
    );
  }, [end, selectedPeriods, start, year]);

  if (!isShown) return <></>;

  return (
    <Wrapper>
      <Title>
        <NavigateBeforeIcon onClick={() => setYear(year - 1)} />
        {year}
        <NavigateNextIcon onClick={() => setYear(year + 1)} />
      </Title>

      <Body>
        {months.map((month, index) => {
          const currentPeriod = setMonth(parse(year.toString(), 'yyyy'), index);

          const isActive =
            isSameOrBefore(start, currentPeriod) &&
            isSameOrAfter(end, currentPeriod);

          const periodKeys = Object.keys(selectedPeriods).map((period) =>
            period.substring(0, 6)
          );
          const isSelected = periodKeys.includes(
            format(currentPeriod, 'yyyyMM')
          );

          return (
            <PeriodCell
              key={format(currentPeriod, 'yyyyMMdd')}
              isActive={isActive}
              isSelected={isSelected}
              handleClick={() =>
                handlePeriodSelect(format(currentPeriod, 'yyyyMMdd'))
              }
              text={month}
            />
          );
        })}
      </Body>

      {renderButton ? (
        renderButton(!isButtonActive)
      ) : (
        <SelectAllButton>
          <Button
            label={
              Object.keys(selectedPeriods).length > 1
                ? formatMessage({ id: 'hidden.transactions.deselectAll' })
                : formatMessage({ id: 'hidden.transactions.selectAll' })
            }
            disabled={!isButtonActive}
            onClick={handleSelectButton}
            variant="text"
            size="medium"
          />
        </SelectAllButton>
      )}
    </Wrapper>
  );
};

export default PeriodCalendar;

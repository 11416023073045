/**
 * Helper to store and access system messages in local storage (LS)
 */
export const SYSTEM_MESSAGES_LS_KEY = 'viewedSystemMessages';

/**
 * Stores the id of a system message to LS
 * @param id system message id
 */
export const storeMessage = (id: number): void => {
  const lsMessages = window.localStorage.getItem(SYSTEM_MESSAGES_LS_KEY);
  const messages: number[] = lsMessages ? JSON.parse(lsMessages) : [];

  if (!messages.includes(id)) {
    messages.push(id);
  }

  window.localStorage.setItem(SYSTEM_MESSAGES_LS_KEY, JSON.stringify(messages));
};

/**
 * Removes the id of a system message from LS
 * @param id system message id
 */
export const removeMessage = (id: number): void => {
  const lsMessages = window.localStorage.getItem(SYSTEM_MESSAGES_LS_KEY);
  if (lsMessages) {
    const messages: number[] = JSON.parse(lsMessages);
    if (messages) {
      const updatedMessages = messages.filter((msgId) => msgId !== id);
      window.localStorage.setItem(
        SYSTEM_MESSAGES_LS_KEY,
        JSON.stringify(updatedMessages)
      );
    }
  }
};

/**
 * Get the viewed system messages from LS
 * @returns array of all viewed system message ids
 */
export const getStoredMessages = (): number[] => {
  const lsMessages = window.localStorage.getItem(SYSTEM_MESSAGES_LS_KEY);
  return lsMessages ? JSON.parse(lsMessages) : [];
};

export default { storeMessage, removeMessage, getStoredMessages };

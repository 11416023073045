import React, { useMemo } from 'react';
import {
  Table as MuiTable,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';

import { AgoyTableRow } from '@agoy/document';
import SignaturesPeopleTableRow from './SignaturePeopleTableRow';
import { RowChanges } from './EditRowView';
import Button from '../Buttons/Button';

interface SignaturesPeopleTableProps {
  className?: string;
  rows: AgoyTableRow[];
  tableId?: string;
  print?: boolean;
  showDate?: boolean;
  showAccountantRole?: boolean;
  minDate?: Date;
  onAddRow: () => void;
  onEditRow: (rowId: string, changes: Partial<RowChanges>) => void;
  onDeleteRow: (rowId: string) => void;
}

const Table = styled(MuiTable)`
  width: 80%;

  .MuiTableCell-root {
    border-bottom: none;
  }

  .MuiTableCell-head {
    color: ${(props) => props.theme.palette.grey[700]};
  }

  .MuiTableRow-head {
    // full name
    th:nth-of-type(1) {
      width: 260px;
    }

    // social sec number
    th:nth-of-type(2) {
      width: 160px;
    }

    // Expand icon
    th:nth-last-of-type(2) {
      width: 60px;
      padding-left: 6px;
      padding-right: 6px;
    }

    // Delete icon
    th:nth-last-of-type(1) {
      width: 60px;
      padding-left: 6px;
      padding-right: 6px;
    }
  }
`;

const columns = [{ id: 'role' }, { id: 'date' }];

const SignaturesPeopleTable = ({
  className,
  rows,
  print = false,
  showDate = true,
  showAccountantRole = true,
  tableId = 'annualReport.signatures.section.people',
  minDate,
  onAddRow,
  onEditRow,
  onDeleteRow,
}: SignaturesPeopleTableProps): JSX.Element => {
  const { formatMessage } = useIntl();

  const filteredColumns = useMemo(
    () => columns.filter((item) => (!showDate ? item.id !== 'date' : true)),
    [showDate]
  );

  return (
    <Table size="small" className={className}>
      <TableHead>
        <TableRow>
          <TableCell>
            {formatMessage({
              id: `${tableId}.fullName`,
            })}
          </TableCell>
          {filteredColumns.map((col) => (
            <TableCell key={col.id}>
              {formatMessage({
                id: `${tableId}.${col.id}`,
                defaultMessage: col.id,
              })}
            </TableCell>
          ))}
          {!print && (
            <>
              <TableCell />
              <TableCell />
            </>
          )}
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row) => (
          <SignaturesPeopleTableRow
            key={`${tableId}.${row.id}`}
            baseId={tableId}
            row={row}
            columns={filteredColumns}
            print={print}
            showDate={showDate}
            showAccountantRole={showAccountantRole}
            minDate={minDate}
            onEditRow={onEditRow}
            onDeleteRow={onDeleteRow}
          />
        ))}
        {!print && (
          <TableRow>
            <TableCell style={{ minWidth: '145px' }}>
              <Button
                label={formatMessage({
                  id: `add.row`,
                })}
                onClick={onAddRow}
                variant="text"
                startIcon={<AddIcon />}
              />
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default SignaturesPeopleTable;

import { IntlShape } from 'react-intl';
import { CompanyType } from '_clients/types/types';

export const getClosingMonthOptions = (intl: IntlShape) =>
  [
    { value: 1, label: intl.formatMessage({ id: 'month.jan' }) },
    { value: 2, label: intl.formatMessage({ id: 'month.feb' }) },
    { value: 3, label: intl.formatMessage({ id: 'month.mar' }) },
    { value: 4, label: intl.formatMessage({ id: 'month.apr' }) },
    { value: 5, label: intl.formatMessage({ id: 'month.may' }) },
    { value: 6, label: intl.formatMessage({ id: 'month.jun' }) },
    { value: 7, label: intl.formatMessage({ id: 'month.jul' }) },
    { value: 8, label: intl.formatMessage({ id: 'month.aug' }) },
    { value: 9, label: intl.formatMessage({ id: 'month.sep' }) },
    { value: 10, label: intl.formatMessage({ id: 'month.oct' }) },
    { value: 11, label: intl.formatMessage({ id: 'month.nov' }) },
    { value: 12, label: intl.formatMessage({ id: 'month.dec' }) },
  ] as const;

export const getClosingPeriodOptions = (intl: IntlShape) =>
  [
    {
      value: 'year',
      label: intl.formatMessage({ id: 'closingperiod.year' }),
    },
    {
      value: 'quarter',
      label: intl.formatMessage({
        id: 'closingperiod.quarter',
      }),
    },
    {
      value: 'month',
      label: intl.formatMessage({
        id: 'closingperiod.month',
      }),
    },
  ] as const;

export const getVatPeriodOptions = (intl: IntlShape) =>
  [
    { value: 'month', label: intl.formatMessage({ id: 'vatperiod.month' }) },
    {
      value: 'quarter',
      label: intl.formatMessage({ id: 'vatperiod.quarter' }),
    },
    {
      value: 'year',
      label: intl.formatMessage({ id: 'vatperiod.year' }),
    },
    {
      value: 'novat',
      label: intl.formatMessage({ id: 'vatperiod.novat' }),
    },
  ] as const;

type CompanyTypeOptions = Array<{ value: CompanyType; label: string }>;

export const getCompanyTypeOptions = (intl: IntlShape): CompanyTypeOptions => [
  { value: 'shares', label: intl.formatMessage({ id: 'company.shares' }) },
  { value: 'limited', label: intl.formatMessage({ id: 'company.limited' }) },
  {
    value: 'limited_partnership',
    label: intl.formatMessage({
      id: 'company.limited.partnership',
    }),
  },
  {
    value: 'individual',
    label: intl.formatMessage({
      id: 'company.individual',
    }),
  },
  {
    value: 'economic_association',
    label: intl.formatMessage({ id: 'company.economic.association' }),
  },
  {
    value: 'non_profit_association',
    label: intl.formatMessage({ id: 'company.non.profit.association' }),
  },
  {
    value: 'foundation',
    label: intl.formatMessage({ id: 'company.foundation' }),
  },
  {
    value: 'other',
    label: intl.formatMessage({ id: 'company.other' }),
  },
];

export const getVatRegistrationOptions = (intl: IntlShape) =>
  [
    {
      value: 1,
      label: intl.formatMessage({
        id: 'clientInformation.vatRegistration.true',
      }),
    },
    {
      value: 0,
      label: intl.formatMessage({
        id: 'clientInformation.vatRegistration.false',
      }),
    },
  ] as const;

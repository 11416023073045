import expandIds from '../expandIds';
import resolveReference from '../resolveReference';
import {
  ResolvedReference,
  ResolveReferenceContext,
  ResolveReferenceInput,
} from '../types';

/**
 * ResolveReferenceContext implementation that resolves references and
 * caches the values.
 *
 * It requires the full set of references that exists in a document.
 *
 */
export class CachingResolveReferenceContext implements ResolveReferenceContext {
  references: Record<string, string>;

  calculatedReferences: Record<string, ResolvedReference> = {};

  input: ResolveReferenceInput;

  allIds: string[];

  constructor(
    references: Record<string, string>,
    input: ResolveReferenceInput,
    parent?: ResolveReferenceContext
  ) {
    this.references = references;
    this.input = input;
    this.parent = parent;
    this.allIds = Object.keys(references);
  }

  resolveById(id: string, context: ResolveReferenceContext): ResolvedReference {
    const ref = this.references[id];

    if (ref) {
      if (ref in this.calculatedReferences) {
        // Cached value, reuse.
        return this.calculatedReferences[ref];
      }
      const refValue = resolveReference(ref, context);

      // Cache the value
      this.calculatedReferences[ref] = refValue;

      return refValue;
    }

    if (this.parent) {
      return this.parent.resolveById(id, context);
    }

    return { error: 'missingId' };
  }

  expandIds(ids: string): string[] {
    const matches = expandIds(this.allIds)(ids);
    if (this.parent?.expandIds) {
      return matches.concat(this.parent.expandIds(ids));
    }
    return matches;
  }

  resolveConfig(
    id: string,
    context: ResolveReferenceContext
  ): ResolvedReference {
    return this.parent?.resolveConfig(id, context);
  }

  parent?: ResolveReferenceContext | undefined;
}

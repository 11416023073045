import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { mapRoutinesData } from '_clients/services/mappings';
import { setRoutinesData } from '_reconciliation/redux/accounting-view/actions';
import { useApiSdk } from 'api-sdk';
import BaseLoader from './BaseLoader';

/**
 * RoutinesLoader
 *
 * The routines are independent of year so this
 * loader is registered under 'client' scope.
 */
const RoutinesLoader = (): JSX.Element => {
  const dispatch = useDispatch();
  const { getRoutines } = useApiSdk();

  const loader = useCallback(
    async (clientId) => {
      const routines = await getRoutines({ clientid: clientId });

      const mapped = mapRoutinesData(routines);
      await dispatch(setRoutinesData(clientId, mapped));
    },
    [getRoutines, dispatch]
  );

  return <BaseLoader id="routines" scope="client" loader={loader} />;
};

export default RoutinesLoader;

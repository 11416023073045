/*
 * This is a GENERATED file, do not change manually!
 * Generated:
 * - from file k3-arsredovisning-2021-10-31-rev20230131 - Noter.csv
 * - Comments: AGOY-6800
 */

import {
  value,
  ref,
  sumAllowNull,
  id,
  AgoyDocumentPart,
  SelectFormat,
} from '@agoy/document';
import { IxbrlCell } from '../../../../../common';
import {
  field,
  ixbrlCell,
  ixbrlField,
  table,
  tupleRef,
} from '../../../../../common/utils/util';
import { previousYear, year } from '../../../../../common/utils/date-util';
import {
  sumAccountsIBs,
} from '../../../../../common/utils/accountSumsUtil';

const cell = { type: 'field' as const };
const boolean = { type: 'boolean' as const };
const type = { type: 'type' as const };
const number = { type: 'number' as const };

const IntresseforetagGemensamtStyrdaForetagList: SelectFormat = {
  type: 'select',
  options: [
    {
      value: 'se-mem-base:ForetagTypIntresseforetagMember',
      label: 'Intresseföretag',
    },
    {
      value: 'se-mem-base:ForetagTypGemensamtStyrtForetagMember',
      label: 'Gemensamt styrt företag',
    },
  ],
};

const IntresseforetagGemensamtStyrdaForetagAgandeformList: SelectFormat = {
  type: 'select',
  options: [
    {
      value: 'se-mem-base:ForetagAgandeformDirektMember',
      label: 'Direkt ägandeform',
    },
    {
      value: 'se-mem-base:ForetagAgandeformIndirektMember',
      label: 'Indirekt ägandeform',
    },
  ],
};

export const getNote2048K3v2 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null
): AgoyDocumentPart<typeof note2048K3v2ContentDefinition> => ({
  active: false,
  number: value(undefined),
  name: noteLabel('Andelar i intresseföretag och gemensamt styrda företag'),
  data: {
    active: true,
    AndelarIntresseforetagGemensamtStyrdaForetagAbstract: {
      type: 'group',
      active: true,
      level: 0,
      title: field('Andelar i intresseföretag och gemensamt styrda företag'),
      table: table<IxbrlCell>(
        'notes.note48.data.AndelarIntresseforetagGemensamtStyrdaForetagAbstract.table',
        {
          id: 'AndelarIntresseforetagGemensamtStyrdaForetagAbstract',
          label: '',
          dataType: 'text',
          active: true,
        },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) ?? '',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) ?? '',
          dataType: 'numeric',
          active: true,
        }
      )
        .addRows((rows) => {
          rows
            .addRowWithType(
              'AndelarIntresseforetagGemensamtStyrdaForetagAbstract',
              'hidden',
              undefined,
              undefined,
              undefined
            )
            .addSubRows((rows) => {
              rows.addRowWithType(
                'AndelarIntresseforetagGemensamtStyrdaForetagAnskaffningsvardenIngaende',
                'sum_accounts',
                noteLabel('Ingående anskaffningsvärden'),
                ixbrlCell(ref(sumAccountsIBs('1331+1333', false)), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagAnskaffningsvarden',
                  standardRubrik:
                    'Anskaffningsvärden andelar i intresseföretag och gemensamt styrda företag',
                  saldo: 'debit',
                  negateValue: false,
                }),
                ixbrlCell(
                  ref(sumAccountsIBs('1331+1333', false, 'previousYear')),
                  {
                    contextRef: 'balans2',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagAnskaffningsvarden',
                    standardRubrik:
                      'Anskaffningsvärden andelar i intresseföretag och gemensamt styrda företag',
                    saldo: 'debit',
                    negateValue: false,
                  }
                )
              );
              rows
                .addRowWithType(
                  'AndelarIntresseforetagGemensamtStyrdaForetagAnskaffningsvardenAbstract',
                  'header',
                  noteLabel('Förändringar av anskaffningsvärden'),
                  undefined,
                  undefined
                )
                .addSubRows((rows) => {
                  rows.addRowWithType(
                    'AndelarIntresseforetagGemensamtStyrdaForetagAnskaffningsvardenInkop',
                    'row',
                    noteLabel('Inköp'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagAnskaffningsvardenInkop',
                      standardRubrik:
                        'Ökning av anskaffningsvärden i samband med inköp av andelar i intresseföretag och gemensamt styrda företag',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagAnskaffningsvardenInkop',
                      standardRubrik:
                        'Ökning av anskaffningsvärden i samband med inköp av andelar i intresseföretag och gemensamt styrda företag',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'AndelarIntresseforetagGemensamtStyrdaForetagAnskaffningsvardenFusion',
                    'row',
                    noteLabel('Fusion'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagAnskaffningsvardenFusion',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden för andelar i intresseföretag och gemensamt styrda företag genom fusion',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagAnskaffningsvardenFusion',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden för andelar i intresseföretag och gemensamt styrda företag genom fusion',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'AndelarIntresseforetagGemensamtStyrdaForetagAnskaffningsvardenForsaljningar',
                    'row',
                    noteLabel('Försäljningar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagAnskaffningsvardenForsaljningar',
                      standardRubrik:
                        'Minskning av anskaffningsvärden i samband med försäljningar av andelar i intresseföretag och gemensamt styrda företag',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagAnskaffningsvardenForsaljningar',
                      standardRubrik:
                        'Minskning av anskaffningsvärden i samband med försäljningar av andelar i intresseföretag och gemensamt styrda företag',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'AndelarIntresseforetagGemensamtStyrdaForetagAnskaffningsvardenLamnadeAktieagartillskott',
                    'row',
                    noteLabel('Lämnade aktieägartillskott'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagAnskaffningsvardenLamnadeAktieagartillskott',
                      standardRubrik:
                        'Ökning av anskaffningsvärden i samband lämnande aktieägartillskott till intresseföretag och gemensamt styrda företag',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagAnskaffningsvardenLamnadeAktieagartillskott',
                      standardRubrik:
                        'Ökning av anskaffningsvärden i samband lämnande aktieägartillskott till intresseföretag och gemensamt styrda företag',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'AndelarIntresseforetagGemensamtStyrdaForetagAnskaffningsvardenAterbetalningLamnatAktieagartillskott',
                    'row',
                    noteLabel('Återbetalning av lämnat aktieägartillskott'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagAnskaffningsvardenAterbetalningLamnatAktieagartillskott',
                      standardRubrik:
                        'Minskning av anskaffningsvärden i samband återbetalning av lämnat aktieägartillskott till intresseföretag och gemensamt styrda företag',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagAnskaffningsvardenAterbetalningLamnatAktieagartillskott',
                      standardRubrik:
                        'Minskning av anskaffningsvärden i samband återbetalning av lämnat aktieägartillskott till intresseföretag och gemensamt styrda företag',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'AndelarIntresseforetagGemensamtStyrdaForetagAnskaffningsvardenAretsResultatAndel',
                    'row',
                    noteLabel('Årets resultatandel'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagAnskaffningsvardenAretsResultatAndel',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden i samband periodens resultatandel i handelsbolag som är intresseföretag eller gemensamt styrda företag',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagAnskaffningsvardenAretsResultatAndel',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden i samband periodens resultatandel i handelsbolag som är intresseföretag eller gemensamt styrda företag',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'AndelarIntresseforetagGemensamtStyrdaForetagAnskaffningsvardenOmklassificeringar',
                    'row',
                    noteLabel('Omklassificeringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagAnskaffningsvardenOmklassificeringar',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden i samband med omklassificeringar av andelar i intresseföretag och gemensamt styrda företag',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagAnskaffningsvardenOmklassificeringar',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden i samband med omklassificeringar av andelar i intresseföretag och gemensamt styrda företag',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'AndelarIntresseforetagGemensamtStyrdaForetagAnskaffningsvardenUtdelning',
                    'row',
                    noteLabel('Utdelning'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagAnskaffningsvardenUtdelning',
                      standardRubrik:
                        'Minskning av anskaffningsvärden i samband med utdelning, andelar i intresseföretag och gemensamt styrda företag',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagAnskaffningsvardenUtdelning',
                      standardRubrik:
                        'Minskning av anskaffningsvärden i samband med utdelning, andelar i intresseföretag och gemensamt styrda företag',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'AndelarIntresseforetagGemensamtStyrdaForetagAnskaffningsvardenOmrakningsdifferenser',
                    'row',
                    noteLabel('Omräkningsdifferenser'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagAnskaffningsvardenOmrakningsdifferenser',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden i samband med omräkningsdifferenser av andelar i intresseföretag och gemensamt styrda företag',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagAnskaffningsvardenOmrakningsdifferenser',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden i samband med omräkningsdifferenser av andelar i intresseföretag och gemensamt styrda företag',
                      negateValue: false,
                    })
                  );
                  return rows.build();
                });
              rows.addRowWithType(
                'AndelarIntresseforetagGemensamtStyrdaForetagAnskaffningsvardenUtgaende',
                'sum',
                noteLabel('Utgående anskaffningsvärden'),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note48.data.AndelarIntresseforetagGemensamtStyrdaForetagAbstract.table.AndelarIntresseforetagGemensamtStyrdaForetagAbstract.AndelarIntresseforetagGemensamtStyrdaForetagAnskaffningsvardenIngaende.year'
                      ),
                      id(
                        'notes.note48.data.AndelarIntresseforetagGemensamtStyrdaForetagAbstract.table.AndelarIntresseforetagGemensamtStyrdaForetagAbstract.AndelarIntresseforetagGemensamtStyrdaForetagAnskaffningsvardenAbstract.*.year'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans0',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagAnskaffningsvarden',
                    standardRubrik:
                      'Anskaffningsvärden andelar i intresseföretag och gemensamt styrda företag',
                    saldo: 'debit',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note48.data.AndelarIntresseforetagGemensamtStyrdaForetagAbstract.table.AndelarIntresseforetagGemensamtStyrdaForetagAbstract.AndelarIntresseforetagGemensamtStyrdaForetagAnskaffningsvardenIngaende.previousYear'
                      ),
                      id(
                        'notes.note48.data.AndelarIntresseforetagGemensamtStyrdaForetagAbstract.table.AndelarIntresseforetagGemensamtStyrdaForetagAbstract.AndelarIntresseforetagGemensamtStyrdaForetagAnskaffningsvardenAbstract.*.previousYear'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans1',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagAnskaffningsvarden',
                    standardRubrik:
                      'Anskaffningsvärden andelar i intresseföretag och gemensamt styrda företag',
                    saldo: 'debit',
                    negateValue: false,
                  }
                )
              );
              rows.addRowWithType(
                'AndelarIntresseforetagGemensamtStyrdaForetagUppskrivningarIngaende',
                'row',
                noteLabel('Ingående uppskrivningar'),
                ixbrlCell(value(undefined), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagUppskrivningar',
                  standardRubrik:
                    'Ackumulerade uppskrivningar andelar i intresseföretag och gemensamt styrda företag',
                  saldo: 'debit',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'balans2',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagUppskrivningar',
                  standardRubrik:
                    'Ackumulerade uppskrivningar andelar i intresseföretag och gemensamt styrda företag',
                  saldo: 'debit',
                  negateValue: false,
                })
              );
              rows
                .addRowWithType(
                  'AndelarIntresseforetagGemensamtStyrdaForetagUppskrivningarAbstract',
                  'header',
                  noteLabel('Förändringar av uppskrivningar'),
                  undefined,
                  undefined
                )
                .addSubRows((rows) => {
                  rows.addRowWithType(
                    'AndelarIntresseforetagGemensamtStyrdaForetagUppskrivningarFusion',
                    'row',
                    noteLabel('Fusion'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagUppskrivningarFusion',
                      standardRubrik:
                        'Ökning (minskning) av uppskrivningar för andelar i intresseföretag och gemensamt styrda företag genom fusion',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagUppskrivningarFusion',
                      standardRubrik:
                        'Ökning (minskning) av uppskrivningar för andelar i intresseföretag och gemensamt styrda företag genom fusion',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'AndelarIntresseforetagGemensamtStyrdaForetagUppskrivningarAterfordaUppskrivningarForsaljningar',
                    'row',
                    noteLabel('Försäljningar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagUppskrivningarAterfordaUppskrivningarForsaljningar',
                      standardRubrik:
                        'Återförda uppskrivningar i samband med försäljningar av andelar i intresseföretag och gemensamt styrda företag',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagUppskrivningarAterfordaUppskrivningarForsaljningar',
                      standardRubrik:
                        'Återförda uppskrivningar i samband med försäljningar av andelar i intresseföretag och gemensamt styrda företag',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'AndelarIntresseforetagGemensamtStyrdaForetagUppskrivningarOmklassificeringar',
                    'row',
                    noteLabel('Omklassificeringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagUppskrivningarOmklassificeringar',
                      standardRubrik:
                        'Ökning (minskning) av uppskrivningar i samband med omklassificeringar av andelar i intresseföretag och gemensamt styrda företag',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagUppskrivningarOmklassificeringar',
                      standardRubrik:
                        'Ökning (minskning) av uppskrivningar i samband med omklassificeringar av andelar i intresseföretag och gemensamt styrda företag',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'AndelarIntresseforetagGemensamtStyrdaForetagUppskrivningarAretsUppskrivningar',
                    'row',
                    noteLabel('Årets uppskrivningar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagUppskrivningarAretsUppskrivningar',
                      standardRubrik:
                        'Periodens uppskrivningar av andelar i intresseföretag och gemensamt styrda företag',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagUppskrivningarAretsUppskrivningar',
                      standardRubrik:
                        'Periodens uppskrivningar av andelar i intresseföretag och gemensamt styrda företag',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'AndelarIntresseforetagGemensamtStyrdaForetagUppskrivningarAretsNedskrivningarUppskrivetBelopp',
                    'row',
                    noteLabel('Årets nedskrivningar på uppskrivet belopp'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagUppskrivningarAretsNedskrivningarUppskrivetBelopp',
                      standardRubrik:
                        'Nedskrivningar av uppskrivet belopp på andelar i intresseföretag och gemensamt styrda företag',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagUppskrivningarAretsNedskrivningarUppskrivetBelopp',
                      standardRubrik:
                        'Nedskrivningar av uppskrivet belopp på andelar i intresseföretag och gemensamt styrda företag',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'AndelarIntresseforetagGemensamtStyrdaForetagUppskrivningarOmrakningsdifferenser',
                    'row',
                    noteLabel('Omräkningsdifferenser'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagUppskrivningarOmrakningsdifferenser',
                      standardRubrik:
                        'Ökning (minskning) av uppskrivningar i samband med omräkningsdifferenser av andelar i intresseföretag och gemensamt styrda företag',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagUppskrivningarOmrakningsdifferenser',
                      standardRubrik:
                        'Ökning (minskning) av uppskrivningar i samband med omräkningsdifferenser av andelar i intresseföretag och gemensamt styrda företag',
                      negateValue: false,
                    })
                  );
                  return rows.build();
                });
              rows.addRowWithType(
                'AndelarIntresseforetagGemensamtStyrdaForetagUppskrivningarUtgaende',
                'sum',
                noteLabel('Utgående uppskrivningar'),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note48.data.AndelarIntresseforetagGemensamtStyrdaForetagAbstract.table.AndelarIntresseforetagGemensamtStyrdaForetagAbstract.AndelarIntresseforetagGemensamtStyrdaForetagUppskrivningarIngaende.year'
                      ),
                      id(
                        'notes.note48.data.AndelarIntresseforetagGemensamtStyrdaForetagAbstract.table.AndelarIntresseforetagGemensamtStyrdaForetagAbstract.AndelarIntresseforetagGemensamtStyrdaForetagUppskrivningarAbstract.*.year'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans0',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagUppskrivningar',
                    standardRubrik:
                      'Ackumulerade uppskrivningar andelar i intresseföretag och gemensamt styrda företag',
                    saldo: 'debit',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note48.data.AndelarIntresseforetagGemensamtStyrdaForetagAbstract.table.AndelarIntresseforetagGemensamtStyrdaForetagAbstract.AndelarIntresseforetagGemensamtStyrdaForetagUppskrivningarIngaende.previousYear'
                      ),
                      id(
                        'notes.note48.data.AndelarIntresseforetagGemensamtStyrdaForetagAbstract.table.AndelarIntresseforetagGemensamtStyrdaForetagAbstract.AndelarIntresseforetagGemensamtStyrdaForetagUppskrivningarAbstract.*.previousYear'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans1',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagUppskrivningar',
                    standardRubrik:
                      'Ackumulerade uppskrivningar andelar i intresseföretag och gemensamt styrda företag',
                    saldo: 'debit',
                    negateValue: false,
                  }
                )
              );
              rows.addRowWithType(
                'AndelarIntresseforetagGemensamtStyrdaForetagNedskrivningarIngaende',
                'sum_accounts',
                noteLabel('Ingående nedskrivningar'),
                ixbrlCell(ref(sumAccountsIBs('1332+1334+1338', false)), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagNedskrivningar',
                  standardRubrik:
                    'Ackumulerade nedskrivningar andelar i intresseföretag och gemensamt styrda företag',
                  saldo: 'credit',
                  negateValue: true,
                }),
                ixbrlCell(
                  ref(sumAccountsIBs('1332+1334+1338', false, 'previousYear')),
                  {
                    contextRef: 'balans2',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagNedskrivningar',
                    standardRubrik:
                      'Ackumulerade nedskrivningar andelar i intresseföretag och gemensamt styrda företag',
                    saldo: 'credit',
                    negateValue: true,
                  }
                )
              );
              rows
                .addRowWithType(
                  'AndelarIntresseforetagGemensamtStyrdaForetagNedskrivningarAbstract',
                  'header',
                  noteLabel('Förändringar av nedskrivningar'),
                  undefined,
                  undefined
                )
                .addSubRows((rows) => {
                  rows.addRowWithType(
                    'AndelarIntresseforetagGemensamtStyrdaForetagForandringNedskrivningarFusion',
                    'row',
                    noteLabel('Fusion'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagForandringNedskrivningarFusion',
                      standardRubrik:
                        'Ökning (minskning) av nedskrivningar för andelar i intresseföretag och gemensamt styrda företag genom fusion',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagForandringNedskrivningarFusion',
                      standardRubrik:
                        'Ökning (minskning) av nedskrivningar för andelar i intresseföretag och gemensamt styrda företag genom fusion',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'AndelarIntresseforetagGemensamtStyrdaForetagForandringNedskrivningarAterfordaNedskrivningarForsaljningar',
                    'row',
                    noteLabel('Försäljningar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagForandringNedskrivningarAterfordaNedskrivningarForsaljningar',
                      standardRubrik:
                        'Återförda nedskrivningar i samband med försäljningar av andelar i intresseföretag och gemensamt styrda företag',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagForandringNedskrivningarAterfordaNedskrivningarForsaljningar',
                      standardRubrik:
                        'Återförda nedskrivningar i samband med försäljningar av andelar i intresseföretag och gemensamt styrda företag',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'AndelarIntresseforetagGemensamtStyrdaForetagForandringNedskrivningarOmklassificeringar',
                    'row',
                    noteLabel('Omklassificeringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagForandringNedskrivningarOmklassificeringar',
                      standardRubrik:
                        'Minskning (ökning) av nedskrivningar i samband med omklassificeringar av andelar i intresseföretag och gemensamt styrda företag',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagForandringNedskrivningarOmklassificeringar',
                      standardRubrik:
                        'Minskning (ökning) av nedskrivningar i samband med omklassificeringar av andelar i intresseföretag och gemensamt styrda företag',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'AndelarIntresseforetagGemensamtStyrdaForetagForandringNedskrivningarAterfordaNedskrivningar',
                    'row',
                    noteLabel('Återförda nedskrivningar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagForandringNedskrivningarAterfordaNedskrivningar',
                      standardRubrik:
                        'Periodens återförda nedskrivningar av andelar i intresseföretag och gemensamt styrda företag',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagForandringNedskrivningarAterfordaNedskrivningar',
                      standardRubrik:
                        'Periodens återförda nedskrivningar av andelar i intresseföretag och gemensamt styrda företag',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'AndelarIntresseforetagGemensamtStyrdaForetagForandringNedskrivningarAretsNedskrivningar',
                    'row',
                    noteLabel('Årets nedskrivningar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagForandringNedskrivningarAretsNedskrivningar',
                      standardRubrik:
                        'Periodens nedskrivningar av andelar i intresseföretag och gemensamt styrda företag',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagForandringNedskrivningarAretsNedskrivningar',
                      standardRubrik:
                        'Periodens nedskrivningar av andelar i intresseföretag och gemensamt styrda företag',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'AndelarIntresseforetagGemensamtStyrdaForetagForandringNedskrivningarOmrakningsdifferenser',
                    'row',
                    noteLabel('Omräkningsdifferenser'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagForandringNedskrivningarOmrakningsdifferenser',
                      standardRubrik:
                        'Minskning (ökning) av nedskrivningar i samband med omräkningsdifferenser av andelar i intresseföretag och gemensamt styrda företag',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagForandringNedskrivningarOmrakningsdifferenser',
                      standardRubrik:
                        'Minskning (ökning) av nedskrivningar i samband med omräkningsdifferenser av andelar i intresseföretag och gemensamt styrda företag',
                      negateValue: false,
                    })
                  );
                  return rows.build();
                });
              rows.addRowWithType(
                'AndelarIntresseforetagGemensamtStyrdaForetagNedskrivningarUtgaende',
                'sum',
                noteLabel('Utgående nedskrivningar'),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note48.data.AndelarIntresseforetagGemensamtStyrdaForetagAbstract.table.AndelarIntresseforetagGemensamtStyrdaForetagAbstract.AndelarIntresseforetagGemensamtStyrdaForetagNedskrivningarIngaende.year'
                      ),
                      id(
                        'notes.note48.data.AndelarIntresseforetagGemensamtStyrdaForetagAbstract.table.AndelarIntresseforetagGemensamtStyrdaForetagAbstract.AndelarIntresseforetagGemensamtStyrdaForetagNedskrivningarAbstract.*.year'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans0',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagNedskrivningar',
                    standardRubrik:
                      'Ackumulerade nedskrivningar andelar i intresseföretag och gemensamt styrda företag',
                    saldo: 'credit',
                    negateValue: true,
                  }
                ),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note48.data.AndelarIntresseforetagGemensamtStyrdaForetagAbstract.table.AndelarIntresseforetagGemensamtStyrdaForetagAbstract.AndelarIntresseforetagGemensamtStyrdaForetagNedskrivningarIngaende.previousYear'
                      ),
                      id(
                        'notes.note48.data.AndelarIntresseforetagGemensamtStyrdaForetagAbstract.table.AndelarIntresseforetagGemensamtStyrdaForetagAbstract.AndelarIntresseforetagGemensamtStyrdaForetagNedskrivningarAbstract.*.previousYear'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans1',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagNedskrivningar',
                    standardRubrik:
                      'Ackumulerade nedskrivningar andelar i intresseföretag och gemensamt styrda företag',
                    saldo: 'credit',
                    negateValue: true,
                  }
                )
              );
              return rows.build();
            });
          rows.addRowWithType(
            'AndelarIntresseforetagGemensamtStyrdaForetag',
            'sum',
            noteLabel('Redovisat värde'),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note48.data.AndelarIntresseforetagGemensamtStyrdaForetagAbstract.table.AndelarIntresseforetagGemensamtStyrdaForetagAbstract.AndelarIntresseforetagGemensamtStyrdaForetagAnskaffningsvardenUtgaende.year'
                  ),
                  id(
                    'notes.note48.data.AndelarIntresseforetagGemensamtStyrdaForetagAbstract.table.AndelarIntresseforetagGemensamtStyrdaForetagAbstract.AndelarIntresseforetagGemensamtStyrdaForetagUppskrivningarUtgaende.year'
                  ),
                  id(
                    'notes.note48.data.AndelarIntresseforetagGemensamtStyrdaForetagAbstract.table.AndelarIntresseforetagGemensamtStyrdaForetagAbstract.AndelarIntresseforetagGemensamtStyrdaForetagNedskrivningarUtgaende.year'
                  )
                )
              ),
              {
                contextRef: 'balans0',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetag',
                standardRubrik:
                  'Andelar i intresseföretag och gemensamt styrda företag',
                saldo: 'debit',
                negateValue: false,
              }
            ),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note48.data.AndelarIntresseforetagGemensamtStyrdaForetagAbstract.table.AndelarIntresseforetagGemensamtStyrdaForetagAbstract.AndelarIntresseforetagGemensamtStyrdaForetagAnskaffningsvardenUtgaende.previousYear'
                  ),
                  id(
                    'notes.note48.data.AndelarIntresseforetagGemensamtStyrdaForetagAbstract.table.AndelarIntresseforetagGemensamtStyrdaForetagAbstract.AndelarIntresseforetagGemensamtStyrdaForetagUppskrivningarUtgaende.previousYear'
                  ),
                  id(
                    'notes.note48.data.AndelarIntresseforetagGemensamtStyrdaForetagAbstract.table.AndelarIntresseforetagGemensamtStyrdaForetagAbstract.AndelarIntresseforetagGemensamtStyrdaForetagNedskrivningarUtgaende.previousYear'
                  )
                )
              ),
              {
                contextRef: 'balans1',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetag',
                standardRubrik:
                  'Andelar i intresseföretag och gemensamt styrda företag',
                saldo: 'debit',
                negateValue: false,
              }
            )
          );
          return rows.build();
        })
        .build(),
      AndelarIntresseforetagGemensamtStyrdaForetagKommentar: {
        type: 'group',
        active: true,
        level: 0,
        visible: ref(
          id(
            'notes.note48.data.AndelarIntresseforetagGemensamtStyrdaForetagAbstract.active'
          )
        ),
        title: field(
          'Kommentar till andelar i intresseföretag och gemensamt styrda företag'
        ),
        value: ixbrlField(field(''), {
          contextRef: 'period0',
          type: 'stringItemType',
          name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagKommentar',
          standardRubrik:
            'Kommentar till andelar i intresseföretag och gemensamt styrda företag',
        }),
      },
    },
    InnehavAndelarIntresseforetagGemensamtStyrdaForetag: {
      type: 'group',
      active: true,
      level: 0,
      title: field(
        'Innehav av andelar i intresseföretag och gemensamt styrda företag'
      ),
      value: ixbrlField(field(''), {
        contextRef: 'period0',
        type: 'stringItemType',
        name: 'se-gen-base:InnehavAndelarIntresseforetagGemensamtStyrdaForetag',
        standardRubrik:
          'Innehav av andelar i intresseföretag och gemensamt styrda företag',
      }),
      InnehavAndelarIntresseforetagGemensamtStyrdaForetagInklusiveUppgiftForetagTuple:
        {
          type: 'group',
          active: true,
          level: 1,
          title: field(
            'Specifikation innehav av andelar i intresseföretag och gemensamt styrda företag inklusive uppgifter om företag'
          ),
          table: table<IxbrlCell>(
            'notes.note48.data.InnehavAndelarIntresseforetagGemensamtStyrdaForetag.InnehavAndelarIntresseforetagGemensamtStyrdaForetagInklusiveUppgiftForetagTuple.table',
            {
              id: 'ForetagetsNamn',
              label: 'Företagets namn',
              dataType: 'text',
              active: true,
            },
            {
              id: 'IntresseforetagGemensamtStyrdaForetagList',
              label: 'Typ av företag',
              dataType: 'text',
              active: true,
            },
            {
              id: 'IntresseforetagGemensamtStyrdaForetagAgandeformList',
              label: 'Direkt/indirekt ägande',
              dataType: 'text',
              active: true,
            },
            {
              id: 'Organisationsnummer',
              label: 'Organisations\u00ADnummer',
              dataType: 'text',
              active: true,
            },
            {
              id: 'ForetagetsSate',
              label: 'Säte',
              dataType: 'text',
              active: true,
            },
            {
              id: 'InnehavAndelarIntresseforetagGemensamtStyrdaForetagAntalAndelar',
              label: 'Antal andelar',
              dataType: 'numeric',
              active: true,
            },
            {
              id: 'InnehavAndelarIntresseforetagGemensamtStyrdaForetagEgetKapital',
              label: 'Eget kapital',
              dataType: 'numeric',
              active: true,
            },
            {
              id: 'InnehavAndelarIntresseforetagGemensamtStyrdaForetagAretsResultat',
              label: 'Årets resultat',
              dataType: 'numeric',
              active: true,
            },
            {
              id: 'InnehavAndelarIntresseforetagGemensamtStyrdaForetagKapitalandel',
              label: 'Kapitalandel',
              dataType: 'numeric',
              active: true,
            },
            {
              id: 'InnehavAndelarIntresseforetagGemensamtStyrdaForetagRostrattsandel',
              label: 'Rösträttsandel',
              dataType: 'numeric',
              active: true,
            },
            {
              id: 'InnehavAndelarIntresseforetagGemensamtStyrdaForetagRedovisatVarde',
              label: 'Redovisat värde',
              dataType: 'numeric',
              active: true,
            }
          )
            .addRows((rows) => {
              rows
                .addRowWithType(
                  'InnehavAndelarIntresseforetagGemensamtStyrdaForetagInklusiveUppgiftForetagTuple',
                  'hidden',
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined
                )
                .addSubRows((rows) => {
                  rows.addRowWithType(
                    '1',
                    'row',
                    ixbrlCell(value('Företagets namn'), {
                      type: 'stringItemType',
                      name: 'se-cd-base:ForetagetsNamn',
                      contextRef: 'period0',
                      standardRubrik: 'Företagets nuvarande namn',
                      negateValue: false,
                      tuple: {
                        name: 'se-gaap-ext:InnehavAndelarIntresseforetagGemensamtStyrdaForetagInklusiveUppgiftForetagTuple',
                        id: `${rows.getBaseId()}.1.tuple`,
                        tupleID: `${rows.getBaseId()}.1.tuple`,
                      },
                      tupleRef: tupleRef(1, `${rows.getBaseId()}.1.tuple`),
                      previousYearTuple: false,
                    }),
                    ixbrlCell(
                      {
                        type: 'string',
                        value:
                          IntresseforetagGemensamtStyrdaForetagList.options[0]
                            .value,
                        format: IntresseforetagGemensamtStyrdaForetagList,
                      },
                      {
                        type: 'enumerationItemType',
                        name: 'se-gen-base:IntresseforetagGemensamtStyrdaForetagList',
                        contextRef: 'period0',
                        standardRubrik:
                          'Intresseföretag eller gemensamt styrt företag',
                        negateValue: false,
                        tupleRef: tupleRef(2, `${rows.getBaseId()}.1.tuple`),
                        previousYearTuple: false,
                      }
                    ),
                    ixbrlCell(
                      {
                        type: 'string',
                        value:
                          IntresseforetagGemensamtStyrdaForetagAgandeformList
                            .options[0].value,
                        format:
                          IntresseforetagGemensamtStyrdaForetagAgandeformList,
                      },
                      {
                        type: 'enumerationItemType',
                        name: 'se-gen-base:IntresseforetagGemensamtStyrdaForetagAgandeformList',
                        contextRef: 'period0',
                        standardRubrik:
                          'Ägandeform av intresseföretag eller gemensamt styrt företag',
                        negateValue: false,
                        tupleRef: tupleRef(3, `${rows.getBaseId()}.1.tuple`),
                        previousYearTuple: false,
                      }
                    ),
                    ixbrlCell(value('Organisations\u00ADnummer'), {
                      type: 'stringItemType',
                      name: 'se-cd-base:Organisationsnummer',
                      contextRef: 'period0',
                      standardRubrik: 'Organisationsnummer',
                      negateValue: false,
                      tupleRef: tupleRef(4, `${rows.getBaseId()}.1.tuple`),
                      previousYearTuple: false,
                    }),
                    ixbrlCell(value('Säte'), {
                      type: 'stringItemType',
                      name: 'se-cd-base:ForetagetsSate',
                      contextRef: 'period0',
                      standardRubrik: 'Företagets säte',
                      negateValue: false,
                      tupleRef: tupleRef(5, `${rows.getBaseId()}.1.tuple`),
                      previousYearTuple: false,
                    }),
                    ixbrlCell(value(), {
                      type: 'sharesItemType',
                      unitRef: 'andelar',
                      name: 'se-gen-base:InnehavAndelarIntresseforetagGemensamtStyrdaForetagAntalAndelar',
                      contextRef: 'balans0',
                      standardRubrik:
                        'Antal andelar per intresseföretag eller gemensamt styrt företag',
                      negateValue: false,
                      tupleRef: tupleRef(6, `${rows.getBaseId()}.1.tuple`),
                      previousYearTuple: false,
                    }),
                    ixbrlCell(value(), {
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:InnehavAndelarIntresseforetagGemensamtStyrdaForetagEgetKapital',
                      contextRef: 'balans0',
                      standardRubrik:
                        'Positivt (negativt) eget kapital per intresseföretag och gemensamt styrda företag',
                      negateValue: false,
                      tupleRef: tupleRef(7, `${rows.getBaseId()}.1.tuple`),
                      previousYearTuple: false,
                    }),
                    ixbrlCell(value(), {
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:InnehavAndelarIntresseforetagGemensamtStyrdaForetagAretsResultat',
                      contextRef: 'period0',
                      standardRubrik:
                        'Periodens vinst (förlust) per intresseföretag och gemensamt styrda företag',
                      negateValue: false,
                      tupleRef: tupleRef(8, `${rows.getBaseId()}.1.tuple`),
                      previousYearTuple: false,
                    }),
                    ixbrlCell(value(), {
                      type: 'pureItemType',
                      unitRef: 'procent',
                      name: 'se-gen-base:InnehavAndelarIntresseforetagGemensamtStyrdaForetagKapitalandel',
                      contextRef: 'balans0',
                      standardRubrik:
                        'Kapitalandel per intresseföretag eller gemensamt styrt företag',
                      negateValue: false,
                      tupleRef: tupleRef(9, `${rows.getBaseId()}.1.tuple`),
                      previousYearTuple: false,
                    }),
                    ixbrlCell(value(), {
                      type: 'pureItemType',
                      unitRef: 'procent',
                      name: 'se-gen-base:InnehavAndelarIntresseforetagGemensamtStyrdaForetagRostrattsandel',
                      contextRef: 'balans0',
                      standardRubrik:
                        'Rösträttsandel per intresseföretag eller gemensamt styrt företag',
                      negateValue: false,
                      tupleRef: tupleRef(10, `${rows.getBaseId()}.1.tuple`),
                      previousYearTuple: false,
                    }),
                    ixbrlCell(value(), {
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:InnehavAndelarIntresseforetagGemensamtStyrdaForetagRedovisatVarde',
                      contextRef: 'balans0',
                      standardRubrik:
                        'Redovisat värde per intresseföretag eller gemensamt styrt företag',
                      saldo: 'debit',
                      negateValue: false,
                      tupleRef: tupleRef(11, `${rows.getBaseId()}.1.tuple`),
                      previousYearTuple: false,
                    })
                  );
                  return rows.build();
                });
              rows.newRowTemplateGenerator((_id, baseId) => ({
                id: _id,
                active: true,
                type: 'row',
                cells: {
                  ForetagetsNamn: ixbrlCell(value('Företagets namn'), {
                    type: 'stringItemType',
                    name: 'se-cd-base:ForetagetsNamn',
                    contextRef: 'period0',
                    standardRubrik: 'Företagets nuvarande namn',
                    negateValue: false,
                    tuple: {
                      name: 'se-gaap-ext:InnehavAndelarIntresseforetagGemensamtStyrdaForetagInklusiveUppgiftForetagTuple',
                      id: `${baseId}.${_id}.tuple`,
                      tupleID: `${baseId}.${_id}.tuple`,
                    },
                    tupleRef: tupleRef(1, `${baseId}.${_id}.tuple`),
                    previousYearTuple: false,
                  }),
                  IntresseforetagGemensamtStyrdaForetagList: ixbrlCell(
                    {
                      type: 'string',
                      value:
                        IntresseforetagGemensamtStyrdaForetagList.options[0]
                          .value,
                      format: IntresseforetagGemensamtStyrdaForetagList,
                    },
                    {
                      type: 'enumerationItemType',
                      name: 'se-gen-base:IntresseforetagGemensamtStyrdaForetagList',
                      contextRef: 'period0',
                      standardRubrik:
                        'Intresseföretag eller gemensamt styrt företag',
                      negateValue: false,
                      tupleRef: tupleRef(2, `${baseId}.${_id}.tuple`),
                      previousYearTuple: false,
                    }
                  ),
                  IntresseforetagGemensamtStyrdaForetagAgandeformList:
                    ixbrlCell(
                      {
                        type: 'string',
                        value:
                          IntresseforetagGemensamtStyrdaForetagAgandeformList
                            .options[0].value,
                        format:
                          IntresseforetagGemensamtStyrdaForetagAgandeformList,
                      },
                      {
                        type: 'enumerationItemType',
                        name: 'se-gen-base:IntresseforetagGemensamtStyrdaForetagAgandeformList',
                        contextRef: 'period0',
                        standardRubrik:
                          'Ägandeform av intresseföretag eller gemensamt styrt företag',
                        negateValue: false,
                        tupleRef: tupleRef(3, `${baseId}.${_id}.tuple`),
                        previousYearTuple: false,
                      }
                    ),
                  Organisationsnummer: ixbrlCell(
                    value('Organisations\u00ADnummer'),
                    {
                      type: 'stringItemType',
                      name: 'se-cd-base:Organisationsnummer',
                      contextRef: 'period0',
                      standardRubrik: 'Organisationsnummer',
                      negateValue: false,
                      tupleRef: tupleRef(4, `${baseId}.${_id}.tuple`),
                      previousYearTuple: false,
                    }
                  ),
                  ForetagetsSate: ixbrlCell(value('Säte'), {
                    type: 'stringItemType',
                    name: 'se-cd-base:ForetagetsSate',
                    contextRef: 'period0',
                    standardRubrik: 'Företagets säte',
                    negateValue: false,
                    tupleRef: tupleRef(5, `${baseId}.${_id}.tuple`),
                    previousYearTuple: false,
                  }),
                  InnehavAndelarIntresseforetagGemensamtStyrdaForetagAntalAndelar:
                    ixbrlCell(value(), {
                      type: 'sharesItemType',
                      unitRef: 'andelar',
                      name: 'se-gen-base:InnehavAndelarIntresseforetagGemensamtStyrdaForetagAntalAndelar',
                      contextRef: 'balans0',
                      standardRubrik:
                        'Antal andelar per intresseföretag eller gemensamt styrt företag',
                      negateValue: false,
                      tupleRef: tupleRef(6, `${baseId}.${_id}.tuple`),
                      previousYearTuple: false,
                    }),
                  InnehavAndelarIntresseforetagGemensamtStyrdaForetagEgetKapital:
                    ixbrlCell(value(), {
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:InnehavAndelarIntresseforetagGemensamtStyrdaForetagEgetKapital',
                      contextRef: 'balans0',
                      standardRubrik:
                        'Positivt (negativt) eget kapital per intresseföretag och gemensamt styrda företag',
                      negateValue: false,
                      tupleRef: tupleRef(7, `${baseId}.${_id}.tuple`),
                      previousYearTuple: false,
                    }),
                  InnehavAndelarIntresseforetagGemensamtStyrdaForetagAretsResultat:
                    ixbrlCell(value(), {
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:InnehavAndelarIntresseforetagGemensamtStyrdaForetagAretsResultat',
                      contextRef: 'period0',
                      standardRubrik:
                        'Periodens vinst (förlust) per intresseföretag och gemensamt styrda företag',
                      negateValue: false,
                      tupleRef: tupleRef(8, `${baseId}.${_id}.tuple`),
                      previousYearTuple: false,
                    }),
                  InnehavAndelarIntresseforetagGemensamtStyrdaForetagKapitalandel:
                    ixbrlCell(value(), {
                      type: 'pureItemType',
                      unitRef: 'procent',
                      name: 'se-gen-base:InnehavAndelarIntresseforetagGemensamtStyrdaForetagKapitalandel',
                      contextRef: 'balans0',
                      standardRubrik:
                        'Kapitalandel per intresseföretag eller gemensamt styrt företag',
                      negateValue: false,
                      tupleRef: tupleRef(9, `${baseId}.${_id}.tuple`),
                      previousYearTuple: false,
                    }),
                  InnehavAndelarIntresseforetagGemensamtStyrdaForetagRostrattsandel:
                    ixbrlCell(value(), {
                      type: 'pureItemType',
                      unitRef: 'procent',
                      name: 'se-gen-base:InnehavAndelarIntresseforetagGemensamtStyrdaForetagRostrattsandel',
                      contextRef: 'balans0',
                      standardRubrik:
                        'Rösträttsandel per intresseföretag eller gemensamt styrt företag',
                      negateValue: false,
                      tupleRef: tupleRef(10, `${baseId}.${_id}.tuple`),
                      previousYearTuple: false,
                    }),
                  InnehavAndelarIntresseforetagGemensamtStyrdaForetagRedovisatVarde:
                    ixbrlCell(value(), {
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:InnehavAndelarIntresseforetagGemensamtStyrdaForetagRedovisatVarde',
                      contextRef: 'balans0',
                      standardRubrik:
                        'Redovisat värde per intresseföretag eller gemensamt styrt företag',
                      saldo: 'debit',
                      negateValue: false,
                      tupleRef: tupleRef(11, `${baseId}.${_id}.tuple`),
                      previousYearTuple: false,
                    }),
                },
              }));
              return rows.build();
            })
            .build(),
        },
      InnehavAndelarIntresseforetagGemensamtStyrdaForetagKommentar: {
        type: 'group',
        active: true,
        level: 1,
        visible: ref(
          id(
            'notes.note48.data.InnehavAndelarIntresseforetagGemensamtStyrdaForetag.InnehavAndelarIntresseforetagGemensamtStyrdaForetagInklusiveUppgiftForetagTuple.active'
          )
        ),
        title: field(
          'Kommentar till innehav av andelar i intresseföretag och gemensamt styrda företag'
        ),
        value: ixbrlField(field(''), {
          contextRef: 'period0',
          type: 'stringItemType',
          name: 'se-gen-base:InnehavAndelarIntresseforetagGemensamtStyrdaForetagKommentar',
          standardRubrik:
            'Kommentar till innehav av andelar i intresseföretag och gemensamt styrda företag',
        }),
      },
    },
    NotAndelarIntresseforetagGemensamtStyrdaForetagKommentar: {
      type: 'group',
      active: true,
      level: 0,
      title: field(
        'Kommentar till specifikation av andelar i intresseföretag och gemensamt styrda företag'
      ),
      value: ixbrlField(field(''), {
        contextRef: 'period0',
        type: 'stringItemType',
        name: 'se-gen-base:NotAndelarIntresseforetagGemensamtStyrdaForetagKommentar',
        standardRubrik:
          'Kommentar till specifikation av andelar i intresseföretag och gemensamt styrda företag',
      }),
    },
  },
  group: value(undefined),
  section: value(undefined),
  validForReportType: {
    k2: true,
    k3: true,
  },
});
export const note2048K3v2ContentDefinition = {
  type: 'part' as const,
  children: {
    active: boolean,
    number: cell,
    name: cell,
    data: {
      type: 'part' as const,
      children: {
        active: boolean,

        AndelarIntresseforetagGemensamtStyrdaForetagAbstract: {
          type: 'part' as const,
          children: {
            type: type,
            level: number,
            active: boolean,

            title: cell,

            table: { type: 'table' as const },

            AndelarIntresseforetagGemensamtStyrdaForetagKommentar: {
              type: 'part' as const,
              children: {
                type: type,
                level: number,
                active: boolean,
                visible: cell,

                title: cell,

                value: cell,
              },
            },
          },
        },

        InnehavAndelarIntresseforetagGemensamtStyrdaForetag: {
          type: 'part' as const,
          children: {
            type: type,
            level: number,
            active: boolean,

            title: cell,

            value: cell,

            InnehavAndelarIntresseforetagGemensamtStyrdaForetagInklusiveUppgiftForetagTuple:
              {
                type: 'part' as const,
                children: {
                  type: type,
                  level: number,
                  active: boolean,

                  title: cell,

                  table: { type: 'table' as const },
                },
              },

            InnehavAndelarIntresseforetagGemensamtStyrdaForetagKommentar: {
              type: 'part' as const,
              children: {
                type: type,
                level: number,
                active: boolean,
                visible: cell,

                title: cell,

                value: cell,
              },
            },
          },
        },

        NotAndelarIntresseforetagGemensamtStyrdaForetagKommentar: {
          type: 'part' as const,
          children: {
            type: type,
            level: number,
            active: boolean,

            title: cell,

            value: cell,
          },
        },
      },
    },
    group: cell,
    section: cell,
    validForReportType: {
      type: 'part' as const,
      children: {
        k2: boolean,
        k3: boolean,
      },
    },
  },
};

import { ReferenceAccountInformation } from './types';
import { AccountingAccount } from './AccountingBalancesAccountResolver';

export const mapAccountsToReferenceAccountInformation = (
  accounts: AccountingAccount[]
): Record<string, ReferenceAccountInformation> => {
  return accounts.reduce(
    (
      refAccounts: Record<string, ReferenceAccountInformation>,
      account
    ): Record<string, ReferenceAccountInformation> => {
      // eslint-disable-next-line no-param-reassign
      refAccounts[account.number] = {
        account: account.number,
        accountName: account.name,
        periods: [],
        res: [],
        yearCreditsWithoutInverse: 0,
        yearDebitsWithoutInverse: 0,
        yearIbs: [],
        yearUbs: [],
      };
      return refAccounts;
    },
    {}
  );
};

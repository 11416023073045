import { value } from '@agoy/document';
import { NoteOneTableSection, IxbrlCell } from '../../../../common';
import {
  field,
  ixbrlCell,
  ixbrlField,
  table,
  tupleRef,
} from '../../../../common/utils/util';
import { previousYear, year } from '../../../../common/utils/date-util';

const MONETARY_ITEM_TYPE = 'monetaryItemType';

export const getNote78 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null,
  documentTypeVersion = '1'
): NoteOneTableSection => {
  return {
    active: false,
    number: value(undefined),
    name: value('Långfristiga skulder'),
    table: table<IxbrlCell>(
      'notes.note78.table',
      {
        id: 'liabilities',
        label: 'Skuldpost',
      },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      .addRows((rows) =>
        rows
          .addRow(
            'titleRow',
            noteLabel(
              'Långfristiga skulder som förfaller till betalning senare än 5 år efter balansdagen'
            ),
            undefined,
            undefined
          )
          .addRow(
            'debtItem',
            ixbrlCell(value(''), {
              type: 'stringItemType',
              name: 'se-gen-base:LangfristigaSkulderForfallerSkuldpost',
              contextRef: 'balans0',
              previousYearTuple: true,
              tuple: {
                name: 'se-gaap-ext:LangfristigaSkulderForfallerTuple',
                id: `${rows.getBaseId()}.debtItem.tuple`,
                tupleID: `${rows.getBaseId()}.debtItem.tuple`,
              },
              tupleRef: tupleRef(1, `${rows.getBaseId()}.debtItem.tuple`),
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:LangfristigaSkulderForfallerSenare5Ar',
              contextRef: 'balans0',
              saldo: 'credit',
              tupleRef: tupleRef(
                2,
                `${rows.getBaseId()}.debtItem.tuple_balans0`
              ),
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:LangfristigaSkulderForfallerSenare5Ar',
              contextRef: 'balans1',
              saldo: 'credit',
              tupleRef: tupleRef(
                3,
                `${rows.getBaseId()}.debtItem.tuple_balans1`
              ),
            })
          )
          .build()
      )
      .newRowTemplateGenerator((id, baseId) => ({
        id,
        active: true,
        cells: {
          liabilities: ixbrlCell(value(''), {
            type: 'stringItemType',
            name: 'se-gen-base:LangfristigaSkulderForfallerSkuldpost',
            contextRef: 'balans0',
            previousYearTuple: true,
            tuple: {
              name: 'se-gaap-ext:LangfristigaSkulderForfallerTuple',
              id: `${baseId}.${id}.tuple`,
              tupleID: `${baseId}.${id}.tuple`,
            },
            tupleRef: tupleRef(1, `${baseId}.${id}.tuple`),
          }),
          year: ixbrlCell(value(undefined), {
            type: MONETARY_ITEM_TYPE,
            name: 'se-gen-base:LangfristigaSkulderForfallerSenare5Ar',
            contextRef: 'balans0',
            saldo: 'credit',
            tupleRef: tupleRef(2, `${baseId}.${id}.tuple_balans0`),
          }),
          previousYear: ixbrlCell(value(undefined), {
            type: MONETARY_ITEM_TYPE,
            name: 'se-gen-base:LangfristigaSkulderForfallerSenare5Ar',
            contextRef: 'balans1',
            saldo: 'credit',
            tupleRef: tupleRef(3, `${baseId}.${id}.tuple_balans1`),
          }),
        },
      }))
      .build(),
    commentLabel: field('Kommentar'),
    comment: ixbrlField(field(''), {
      type: 'stringItemType',
      name: 'se-gen-base:NotLangfristigaSkulderKommentar',
      contextRef: 'balans0',
    }),
    group: value(undefined),
    section: value(undefined),
    validForReportType: {
      k2: false,
      k3: documentTypeVersion !== '1',
    },
  };
};

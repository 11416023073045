import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';
import React from 'react';

const AccountNumber = styled(Typography)`
  font-weight: 700;
`;

const Label = styled(Typography)`
  padding-left: ${(props) => props.theme.spacing(1)}px;
`;

interface AccountLabelProps {
  accountNumber: string;
  accountName: string | undefined;
}

const AccountLabel = ({ accountName, accountNumber }: AccountLabelProps) => (
  <>
    <AccountNumber>{accountNumber}</AccountNumber>
    <Label variant="body1" color="textSecondary">
      {accountName}
    </Label>
  </>
);

export default AccountLabel;

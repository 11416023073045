const uniqueName = (existing: string[], name: string, count = 0): string => {
  const extIndex = name.lastIndexOf('.');
  const baseName = extIndex !== -1 ? name.substring(0, extIndex) : name;
  const ext = extIndex !== -1 ? name.substring(extIndex) : '';
  const nextName = count === 0 ? name : `${baseName} (${count})${ext}`;

  if (existing.includes(nextName)) {
    return uniqueName(existing, name, count + 1);
  }
  return nextName;
};

export default uniqueName;

import { AgoyDocumentStructure } from '../document';
import { State } from './types';
import updateDocument from './helpers/updateDocument';

const setSectionActive = <T extends AgoyDocumentStructure>(
  structure: T,
  state: State<T>,
  id: string,
  active: boolean
) => {
  return updateDocument(structure, state, `${id}.active`, {
    boolean: (key, fieldId, props) => {
      // active property can be either boolean or reference cell
      const value =
        typeof props.node === 'object' ? props.node.value : props.node;

      if (value === active) {
        return props;
      }
      return {
        ...props,
        node: active,
        changes: active,
      };
    },
  });
};

export default setSectionActive;

import React, { useMemo } from 'react';
import { isFinancialYear } from '@agoy/common';
import { CompanyType } from '_clients/types/types';

export type LoaderFunctionOnLoad = () => Promise<void>;

/**
 * LoaderFunction
 *
 * @param   clientId
 * @param   financialYear format YYYYMMDD-YYYYMMDD, ex. 20200101-20201231
 * @returns optional function that is called after all loading is completed for the scope
 */
export type LoaderFunction = (
  clientId: string,
  companyType: CompanyType,
  financialYear?: string
) => Promise<LoaderFunctionOnLoad | void>;

/**
 * - 'client' scope is the first to load for a client, before any data for a specific financial year.
 * - 'year' scope is loaded after 'client' and is for a specific financial year.
 */
export type LoaderScope = 'client' | 'year';

/**
 * ClientLoaderCallback, async callback. Used for performing action before and after loading.
 *
 * @param clientId The loaded client id.
 * @param financialYear The financial year loaded for scope 'year'.
 */
export type ClientLoaderCallback = (
  clientId: string,
  financialYear?: string
) => Promise<void>;

export type ClientLoaderErrorCallback = (error: Error) => void;

interface ClientLoaderContextType {
  registerLoader: (
    id: string,
    scope: LoaderScope,
    loader: LoaderFunction
  ) => void;
  unregisterLoader: (id: string) => void;

  /**
   * loadClient
   *
   * @param clientId client to load
   * @param financialYear The financial year to load, format YYYYMMDD-YYYYMMDD.
   */
  loadClient: (
    clientId: string,
    companyType: CompanyType,
    financialYear?: string
  ) => Promise<void>;

  onLoaded: ClientLoaderCallback;

  onBeforeLoad: ClientLoaderCallback;

  onError: ClientLoaderErrorCallback;
}

const NO_CONTEXT = () => {
  throw new Error('No context');
};

const ClientLoaderContext = React.createContext<ClientLoaderContextType>({
  registerLoader: NO_CONTEXT,
  unregisterLoader: NO_CONTEXT,
  loadClient: NO_CONTEXT,
  onLoaded: NO_CONTEXT,
  onBeforeLoad: NO_CONTEXT,
  onError: NO_CONTEXT,
});

export const createLoaderContext = (): ClientLoaderContextType => {
  // Mutating state

  const loaders: Record<string, Record<LoaderScope, LoaderFunction>> = {};
  const loading: Record<string, string | null> = {};
  const loaded: Record<string, string | null> = {};

  // Methods
  const callLoaders = async (
    clientId: string,
    companyType: CompanyType,
    year: string | undefined,
    ...scopes: LoaderScope[]
  ) => {
    try {
      loading[clientId] = year ?? null;
      const afterLoaders = await Promise.all(
        Object.values(loaders)
          .flatMap((loader) => scopes.map((scope) => loader[scope]))
          .filter((loader) => loader)
          .map((loader) => loader(clientId, companyType, year))
      );
      delete loading[clientId];
      loaded[clientId] = year ?? null;
      await Promise.all(
        afterLoaders.map((onLoad) =>
          typeof onLoad === 'function' ? onLoad() : Promise.resolve()
        )
      );
    } finally {
      delete loading[clientId];
    }
  };

  const context: ClientLoaderContextType = {
    registerLoader: (id, scope, loader) => {
      if (loaders[id]) {
        throw new Error('Loader already exists');
      }
      loaders[id] = { [scope]: loader } as Record<LoaderScope, LoaderFunction>;
    },
    unregisterLoader: (id) => {
      delete loaders[id];
    },
    loadClient: async (clientId, companyType, financialYear) => {
      try {
        if (financialYear !== undefined && !isFinancialYear(financialYear)) {
          throw new Error(`Financial year has wrong format: ${financialYear}`);
        }
        if (loading[clientId] !== undefined) {
          return;
        }

        await context.onBeforeLoad(clientId, financialYear);
        if (loaded[clientId] === undefined) {
          if (financialYear) {
            await callLoaders(
              clientId,
              companyType,
              financialYear,
              'client',
              'year'
            );
          } else {
            await callLoaders(clientId, companyType, financialYear, 'client');
          }
        } else if (financialYear) {
          await callLoaders(clientId, companyType, financialYear, 'year');
        }
        await context.onLoaded(clientId, financialYear);
      } catch (error) {
        context.onError(error);
      }
    },
    onLoaded: async () => {},
    onBeforeLoad: async () => {},
    onError: () => {},
  };
  return context;
};

type ClientLoaderContextProviderType = {
  children: JSX.Element | JSX.Element[];
  onLoaded?: ClientLoaderCallback;
  onBeforeLoad?: ClientLoaderCallback;
  onError?: ClientLoaderErrorCallback;
};

export const ClientLoaderContextProvider = ({
  onLoaded,
  onBeforeLoad,
  onError,
  children,
}: ClientLoaderContextProviderType): JSX.Element => {
  const context = useMemo(createLoaderContext, []);
  if (onLoaded) {
    context.onLoaded = onLoaded;
  }
  if (onBeforeLoad) {
    context.onBeforeLoad = onBeforeLoad;
  }
  if (onError) {
    context.onError = onError;
  }

  return (
    <ClientLoaderContext.Provider value={context}>
      {children}
    </ClientLoaderContext.Provider>
  );
};

export default ClientLoaderContext;

import {
  TaxDeclarationFormDefinition,
  TaxDeclarationFormDataConfig,
} from '../../types';
import { ref, value, id } from '@agoy/document';
import { mapFormId, toInitial, toStructure } from '../forms-util';

const configN7 = (
  form: TaxDeclarationFormDefinition,
  financialYearStart: string,
  financialYearEnd: string
): TaxDeclarationFormDataConfig => {
  const N7 = mapFormId(form, 'N7');
  const initial = {
    N7: {
      partType: 'form' as const,
      id: N7,
      fields: {
        datum: value(''),
        A_den_namn: value(''),
        PersOrgnr: value(''),
        A_overlatare: value(''),
        A_overlatare_persorgnr: value(''),
        A_datum_overlatelsen: value(''),
        A_overtagande: value(false),
        B_1_a_avsattning_sjatte: value(undefined),
        B_1_b_avsattning_femte: value(undefined),
        B_1_c_avsattning_fjarde: value(undefined),
        B_1_d_avsattning_tredje: value(undefined),
        B_1_e_avsattning_andra: value(undefined),
        B_1_f_avsattning_aret: value(undefined),
        B_1_g_avsattning_under: value(undefined),
        B_2_expansionsfond: value(undefined),
        B_3_sparat: value(undefined),
        B_4_skogskonto: value(undefined),
      },
      sru: {
        PersOrgnr: ref(id('N7.fields.PersOrgnr')),
        '7011': value(financialYearStart),
        '7012': value(financialYearEnd),
        '8580': ref(id('N7.fields.A_overlatare_persorgnr')),
        '8585': ref(id('N7.fields.A_datum_overlatelsen')),
        '8581': ref(id('N7.fields.A_overtagande')),
        '8346': ref(id('N7.fields.B_1_a_avsattning_sjatte')),
        '8345': ref(id('N7.fields.B_1_b_avsattning_femte')),
        '8344': ref(id('N7.fields.B_1_c_avsattning_fjarde')),
        '8343': ref(id('N7.fields.B_1_d_avsattning_tredje')),
        '8342': ref(id('N7.fields.B_1_e_avsattning_andra')),
        '8341': ref(id('N7.fields.B_1_f_avsattning_aret')),
        '8340': ref(id('N7.fields.B_1_g_avsattning_under')),
        '8582': ref(id('N7.fields.B_2_expansionsfond')),
        '8583': ref(id('N7.fields.B_3_sparat')),
        '8584': ref(id('N7.fields.B_4_skogskonto')),
      },
    },
  };
  return {
    initial: toInitial(initial, form),
    definition: toStructure(initial, form),
  };
};

export default configN7;

import { Dispatch } from 'redux';
import { ActivityLog } from '@agoy/activity-log';

import { logOut } from 'utils/AuthenticatedClient';
import { snakeToCamel } from 'utils/camelSnake';
import {
  authenticateUser,
  changeUserPassword,
  confirmNewPassword,
  confirmUser,
  forgetPasswordRequest,
  getCurrentSession,
  getUserAttributes,
  resendConfirmation,
  userSignUp,
} from 'utils/AwsAsync';
import {
  insertUserAttributes,
  setUserLoggedIn,
  setUserLoggedOut,
  userIsAuthenticating,
  userNotAuthenticating,
} from '_users/redux/actions';
import sendActivityEvent from 'Api/Activity/authorizationActivityEventHandler';

import {
  ChangePasswordArgs,
  ConfirmAccountArgs,
  ConfirmNewPasswordArgs,
  ContextFunctionsType,
  SignUpArgs,
} from './types';

/**
 * Factory for managing the authentication agaist AWS Cognito.
 * The Agoy users (not form Fortnox) are managed through Cognito.
 */
const cognitoAuth = (
  dispatch: Dispatch<any>,
  setStateUser: (user: any) => void,
  setStateIsAuthenticated: (value: boolean) => void,
  setStateIsLoading: (value: boolean) => void
): ContextFunctionsType => {
  // SETTERs for both state and redux actions
  const setUser = (user) => {
    setStateUser(user);
    dispatch(insertUserAttributes(user));
  };

  const setIsAuthenticated = (value: boolean) => {
    setStateIsAuthenticated(value);
    if (value) {
      dispatch(setUserLoggedIn());
    } else {
      dispatch(setUserLoggedOut());
    }
  };

  const setIsLoading = (value: boolean) => {
    setStateIsLoading(value);
    if (value) {
      dispatch(userIsAuthenticating());
    } else {
      dispatch(userNotAuthenticating());
    }
  };

  const withLoading = (fn) => async (args?) => {
    try {
      setIsLoading(true);
      await fn(args);
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Handle user formatting, setting authentication and user values.
   */
  const handleUser = (user: Record<string, any> | null) => {
    if (user) {
      // Convert all cognito snake case user attributes to camel case
      const userCamelCaseAttributes = Object.keys(user).reduce(
        (acc, curr) => ({
          ...acc,
          [snakeToCamel(curr)]: user[curr],
        }),
        {}
      );
      setUser(userCamelCaseAttributes);
      setIsAuthenticated(true);
    } else {
      setUser(null);
      setIsAuthenticated(false);
    }
  };

  const getCurrentSessionAWS = withLoading(async () => {
    try {
      const { session, userAttributes } = await getCurrentSession({
        returnUserAttributes: true,
      });

      handleUser(session && userAttributes ? userAttributes : null);
    } catch (err) {
      console.error(err);
    }
  });

  const logInAWS = withLoading(async ({ username, password }) => {
    await authenticateUser({
      username,
      password,
    });
    const userAttributes = await getUserAttributes();

    handleUser(userAttributes || null);

    await sendActivityEvent(
      ActivityLog.createActivityLogEvent({
        program: 'SYSTEM',
        section: 'GENERAL',
        resource: 'USER',
        operation: 'LOGIN',
        arguments: [],
      })
    );
  });

  const logOutAWS = withLoading(async () => {
    await sendActivityEvent(
      ActivityLog.createActivityLogEvent({
        program: 'SYSTEM',
        section: 'GENERAL',
        resource: 'USER',
        operation: 'LOGOUT',
        arguments: [],
      })
    );
    logOut();
    handleUser(null);
  });

  const signUpAWS = withLoading(
    async ({ username, password, givenName, familyName }: SignUpArgs) => {
      await userSignUp({ username, password, givenName, familyName });
    }
  );

  const confirmAccountAWS = withLoading(
    async ({ username, code }: ConfirmAccountArgs) => {
      await confirmUser({ username, code });
    }
  );

  const resendConfirmationAWS = withLoading(async (username: string) => {
    await resendConfirmation(username);
  });

  const forgotPasswordAWS = withLoading(async (username: string) => {
    await forgetPasswordRequest({ username });
  });

  const changePasswordAWS = withLoading(
    async ({ oldPassword, newPassword }: ChangePasswordArgs) => {
      await changeUserPassword(oldPassword, newPassword);
    }
  );

  const confirmNewPasswordAWS = withLoading(
    async ({ username, code, newPassword }: ConfirmNewPasswordArgs) => {
      await confirmNewPassword({ username, code, newPassword });
    }
  );

  const refreshSessionAWS = async () => {
    const { session, userAttributes } = await getCurrentSession({
      returnUserAttributes: true,
      forceRefreshSession: true,
    });
    if (session !== null) {
      dispatch(insertUserAttributes(userAttributes));
    }
  };

  return {
    getCurrentSessionAWS,
    logInAWS,
    logOutAWS,
    signUpAWS,
    confirmAccountAWS,
    resendConfirmationAWS,
    forgotPasswordAWS,
    changePasswordAWS,
    confirmNewPasswordAWS,
    refreshSessionAWS,
  };
};

export default cognitoAuth;

import { shallowCompare } from './utils/shallowCompare';
import {
  TaxCalculationRow,
  TaxCalculationContext,
  AccrualFunds,
} from './types';
import { calculateRow, calculateTaxTable } from './calculateTaxTable';
import {
  parse,
  parseISO,
  startOfMonth,
  subDays,
  subMonths,
  subYears,
} from 'date-fns';
import { parseFormat, format } from '@agoy/dates';

const DEFAULT_INTEREST_RATE = 0.03;
const interests = [DEFAULT_INTEREST_RATE, 0.04, 0.06, 0];
const date18 = new Date(2018, 11, 31);
const date19 = new Date(2019, 0, 1);
const date20 = new Date(2020, 11, 31);
const date21 = new Date(2021, 0, 1);

/**
 * Calculates interest of an accrual fund, depending on it's deposition reversal dates
 * @param endDate deposition date
 * @param currentYear reversal date
 * @returns percentage as number
 */
export const accrualFundInterestRule = (
  startDate: Date,
  currentYear: Date
): number => {
  // Allocation before 2019-01-01 Reversal after 2018-12-31 and before  2021-01-01 (3%)
  if (
    startDate.getTime() < date19.getTime() &&
    currentYear.getTime() > date18.getTime() &&
    currentYear.getTime() < date21.getTime()
  ) {
    return interests[0];
  }
  // Allocation after 2018-12-31 and before 2021-01-01 Reversal after 2020-12-31 (4%)
  if (
    startDate.getTime() > date18.getTime() &&
    startDate.getTime() < date21.getTime() &&
    currentYear.getTime() > date20.getTime()
  ) {
    return interests[1];
  }

  // Allocation before 2019-01-01 Reversal after 2020-12-31 (6%)
  if (
    startDate.getTime() < date19.getTime() &&
    currentYear.getTime() > date20.getTime()
  ) {
    return interests[2];
  }

  // Allocation after 2020-12-31 Reversal after 2020-12-31 (0%)
  if (
    startDate.getTime() > date20.getTime() &&
    currentYear.getTime() > date20.getTime()
  ) {
    return interests[3];
  }

  return DEFAULT_INTEREST_RATE;
};

/**
 * Calculate the percentage interest of accrual a funds account
 */
export const accrualFundInterest = (
  afDate: string,
  currentYearString: string
): number => {
  const currentYear = parse(currentYearString, 'yyyyMMdd', Date.now());

  // replaceAll makes the jest tests crash
  const strings = afDate.split(/ - /);
  const startDate = parseISO(strings[0]);

  return accrualFundInterestRule(startDate, currentYear);
};

export const createYears = (
  periods: string[],
  id: (index: number) => string,
  reference?: (index: number) => string
): TaxCalculationRow[] => {
  const result: TaxCalculationRow[] = [];

  let endDate = subDays(
    parse(periods[0].substring(0, 8), 'yyyyMMdd', Date.now()),
    1
  );
  for (let i = 1; i <= 6; i++) {
    const label = `${format(
      startOfMonth(subMonths(endDate, 11)),
      'yyyy-MM-dd'
    )} – ${format(endDate, 'yyyy-MM-dd')}`;

    result.push({
      id: id(i),
      label,
      reference: reference ? reference(i) : undefined,
      value: 0,
    });
    endDate = subYears(endDate, 1);
  }
  return result.reverse();
};

const calculateRows = (
  context: TaxCalculationContext,
  previousAccrualFunds: AccrualFunds | null
): TaxCalculationRow[] => {
  const moreRows = (
    previousAccrualFunds?.moreRows || context.config.accrualFundsRows
  ).map((row) => calculateRow(row, context));

  if (!previousAccrualFunds?.moreRows) {
    return moreRows;
  }

  return shallowCompare(previousAccrualFunds.moreRows, moreRows)
    ? previousAccrualFunds.moreRows
    : moreRows;
};

export const parseYearFromAccrualFundLabel = (label: string): string => {
  return label.substring(13, 17);
};

export const calculateAccrualFunds = (
  context: TaxCalculationContext,
  previousAccrualFunds: AccrualFunds | null
): AccrualFunds => {
  const { rows, sum } = calculateTaxTable(
    previousAccrualFunds || context.config.accrualFunds,
    context
  );
  const newMoreRows = calculateRows(context, previousAccrualFunds).map(
    (row) => row
  );
  const moreRows =
    previousAccrualFunds &&
    shallowCompare(newMoreRows, previousAccrualFunds.moreRows)
      ? previousAccrualFunds.moreRows
      : newMoreRows;

  const accrualFunds: AccrualFunds = {
    rows,
    sum,
    moreRows,
  };

  if (!previousAccrualFunds) {
    return accrualFunds;
  }

  return shallowCompare(previousAccrualFunds, accrualFunds)
    ? previousAccrualFunds
    : accrualFunds;
};

/**
 * Takes a start and end date and make it to a string
 * @param startYear
 * @param endYear
 * @param index
 * @returns
 */
export const getAccrualDate = (
  startYear: Date,
  endYear: Date,
  index: number
): string => {
  startYear.setFullYear(startYear.getFullYear() - index);
  endYear.setFullYear(endYear.getFullYear() - index);
  return `${parseFormat(startYear, 'yyyy-MM-dd')} - ${parseFormat(
    endYear,
    'yyyy-MM-dd'
  )}`;
};

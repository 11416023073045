import { id, or, ref, sum, table, value } from '@agoy/document';
import { IxbrlCell, NoteOneTableWithSum } from '../../../../common';
import {
  active,
  field,
  ixbrlCell,
  ixbrlField,
  tupleRef,
} from '../../../../common/utils/util';
import { year } from '../../../../common/utils/date-util';
import { sumAccountsUBs } from '../../../../common/utils/accountSumsUtil';
import { NoteAccountRanges } from '../types';

const MONETARY_ITEM_TYPE = 'monetaryItemType';

export const note61Accounts: NoteAccountRanges[] = [
  {
    rowId: '1',
    ranges: [[3900, 3909]],
    cellLabel: 'Övriga rörelseintäkter',
    action: 'sumAccountsUBs',
  },
  {
    rowId: '2',
    ranges: [[3910, 3919]],
    cellLabel: 'Hyres- och arrandeintäkter',
    action: 'sumAccountsUBs',
  },
  {
    rowId: '3',
    ranges: [[3920, 3939]],
    cellLabel: 'Provisionsintäkter, licensintäkter och royalties',
    action: 'sumAccountsUBs',
  },
  {
    rowId: '4',
    ranges: [[3950, 3959]],
    cellLabel: 'Återvunna, tidigare avskrivna kundfordringar',
    action: 'sumAccountsUBs',
  },
  {
    rowId: '5',
    ranges: [[3960, 3969]],
    cellLabel: 'Valutakursvinster på fordringar och skulder av rörelsekaraktär',
    action: 'sumAccountsUBs',
  },
  {
    rowId: '6',
    ranges: [[3980, 3989]],
    cellLabel: 'Erhållna offentliga bidrag',
    action: 'sumAccountsUBs',
  },
  {
    rowId: '7',
    ranges: [[3990, 3999]],
    cellLabel: 'Övriga ersättningar, bidrag och intäkter',
    action: 'sumAccountsUBs',
  },
];

const getNote61 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null
): NoteOneTableWithSum => {
  return {
    active: false,
    number: value(undefined),
    name: value('Övriga rörelseintäkter'),
    table: table<IxbrlCell>(
      `notes.note61.table`,
      {
        id: 'incomeType',
        label: 'Intäktsslag',
      },
      {
        id: 'amount',
        label: 'Belopp',
      }
    )
      .addRows((rows) =>
        rows
          .addRow(
            '1',
            ixbrlCell(value('Övriga rörelseintäkter'), {
              type: 'stringItemType',
              name: 'se-gen-base:OvrigaRorelseintakterIntaktsslagBenamning',
              contextRef: 'period0',
              tuple: {
                name: 'se-gaap-ext:OvrigaRorelseintakterIntaktsslagTuple',
                id: `${rows.getBaseId()}.1.tuple`,
                tupleID: `${rows.getBaseId()}.1.tuple`,
              },
              tupleRef: tupleRef(1, `${rows.getBaseId()}.1.tuple`),
            }),
            ixbrlCell(ref(sumAccountsUBs('3900:3909', true)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OvrigaRorelseintakterIntaktsslag',
              contextRef: 'period0',
              saldo: 'credit',
              tupleRef: tupleRef(2, `${rows.getBaseId()}.1.tuple`),
            })
          )
          .addRow(
            '2',
            ixbrlCell(value('Hyres- och arrandeintäkter'), {
              type: 'stringItemType',
              name: 'se-gen-base:OvrigaRorelseintakterIntaktsslagBenamning',
              contextRef: 'period0',
              tuple: {
                name: 'se-gaap-ext:OvrigaRorelseintakterIntaktsslagTuple',
                id: `${rows.getBaseId()}.2.tuple`,
                tupleID: `${rows.getBaseId()}.2.tuple`,
              },
              tupleRef: tupleRef(1, `${rows.getBaseId()}.2.tuple`),
            }),
            ixbrlCell(ref(sumAccountsUBs('3910:3919', true)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OvrigaRorelseintakterIntaktsslag',
              contextRef: 'period0',
              saldo: 'credit',
              tupleRef: tupleRef(2, `${rows.getBaseId()}.2.tuple`),
            })
          )
          .addRow(
            '3',
            ixbrlCell(
              value('Provisionsintäkter, licensintäkter och royalties'),
              {
                type: 'stringItemType',
                name: 'se-gen-base:OvrigaRorelseintakterIntaktsslagBenamning',
                contextRef: 'period0',
                tuple: {
                  name: 'se-gaap-ext:OvrigaRorelseintakterIntaktsslagTuple',
                  id: `${rows.getBaseId()}.3.tuple`,
                  tupleID: `${rows.getBaseId()}.3.tuple`,
                },
                tupleRef: tupleRef(1, `${rows.getBaseId()}.3.tuple`),
              }
            ),
            ixbrlCell(ref(sumAccountsUBs('3920:3939', true)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OvrigaRorelseintakterIntaktsslag',
              contextRef: 'period0',
              saldo: 'credit',
              tupleRef: tupleRef(2, `${rows.getBaseId()}.3.tuple`),
            })
          )
          .addRow(
            '4',
            ixbrlCell(value('Återvunna, tidigare avskrivna kundfordringar'), {
              type: 'stringItemType',
              name: 'se-gen-base:OvrigaRorelseintakterIntaktsslagBenamning',
              contextRef: 'period0',
              tuple: {
                name: 'se-gaap-ext:OvrigaRorelseintakterIntaktsslagTuple',
                id: `${rows.getBaseId()}.4.tuple`,
                tupleID: `${rows.getBaseId()}.4.tuple`,
              },
              tupleRef: tupleRef(1, `${rows.getBaseId()}.4.tuple`),
            }),
            ixbrlCell(ref(sumAccountsUBs('3950:3959', true)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OvrigaRorelseintakterIntaktsslag',
              contextRef: 'period0',
              saldo: 'credit',
              tupleRef: tupleRef(2, `${rows.getBaseId()}.4.tuple`),
            })
          )
          .addRow(
            '5',
            ixbrlCell(
              value(
                'Valutakursvinster på fordringar och skulder av rörelsekaraktär'
              ),
              {
                type: 'stringItemType',
                name: 'se-gen-base:OvrigaRorelseintakterIntaktsslagBenamning',
                contextRef: 'period0',
                tuple: {
                  name: 'se-gaap-ext:OvrigaRorelseintakterIntaktsslagTuple',
                  id: `${rows.getBaseId()}.5.tuple`,
                  tupleID: `${rows.getBaseId()}.5.tuple`,
                },
                tupleRef: tupleRef(1, `${rows.getBaseId()}.5.tuple`),
              }
            ),
            ixbrlCell(ref(sumAccountsUBs('3960:3969', true)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OvrigaRorelseintakterIntaktsslag',
              contextRef: 'period0',
              saldo: 'credit',
              tupleRef: tupleRef(2, `${rows.getBaseId()}.5.tuple`),
            })
          )
          .addRow(
            '6',
            ixbrlCell(value('Erhållna offentliga bidrag'), {
              type: 'stringItemType',
              name: 'se-gen-base:OvrigaRorelseintakterIntaktsslagBenamning',
              contextRef: 'period0',
              tuple: {
                name: 'se-gaap-ext:OvrigaRorelseintakterIntaktsslagTuple',
                id: `${rows.getBaseId()}.6.tuple`,
                tupleID: `${rows.getBaseId()}.6.tuple`,
              },
              tupleRef: tupleRef(1, `${rows.getBaseId()}.6.tuple`),
            }),
            ixbrlCell(ref(sumAccountsUBs('3980:3989', true)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OvrigaRorelseintakterIntaktsslag',
              contextRef: 'period0',
              saldo: 'credit',
              tupleRef: tupleRef(2, `${rows.getBaseId()}.6.tuple`),
            })
          )
          .addRow(
            '7',
            ixbrlCell(value('Övriga ersättningar, bidrag och intäkter'), {
              type: 'stringItemType',
              name: 'se-gen-base:OvrigaRorelseintakterIntaktsslagBenamning',
              contextRef: 'period0',
              tuple: {
                name: 'se-gaap-ext:OvrigaRorelseintakterIntaktsslagTuple',
                id: `${rows.getBaseId()}.7.tuple`,
                tupleID: `${rows.getBaseId()}.7.tuple`,
              },
              tupleRef: tupleRef(1, `${rows.getBaseId()}.7.tuple`),
            }),
            ixbrlCell(ref(sumAccountsUBs('3990:3999', true)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OvrigaRorelseintakterIntaktsslag',
              contextRef: 'period0',
              saldo: 'credit',
              tupleRef: tupleRef(2, `${rows.getBaseId()}.7.tuple`),
            })
          )
          .build()
      )
      .newRowTemplateGenerator((id, baseId) => ({
        id,
        active: true,
        cells: {
          incomeType: ixbrlCell(value(''), {
            type: 'stringItemType',
            name: 'se-gen-base:OvrigaRorelseintakterIntaktsslagBenamning',
            contextRef: 'period0',
            tuple: {
              name: 'se-gaap-ext:OvrigaRorelseintakterIntaktsslagTuple',
              id: `${baseId}.${id}.tuple`,
              tupleID: `${baseId}.${id}.tuple`,
            },
            tupleRef: tupleRef(1, `${baseId}.${id}.tuple`),
          }),
          amount: ixbrlCell(value(undefined), {
            type: MONETARY_ITEM_TYPE,
            name: 'se-gen-base:OvrigaRorelseintakterIntaktsslag',
            contextRef: 'period0',
            saldo: 'credit',
            tupleRef: tupleRef(2, `${baseId}.${id}.tuple`),
          }),
        },
      }))
      .build(),
    sumTable: table(
      'notes.note61.sumTable',
      { id: 'label', label: '' },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      }
    )
      .addRows((rows) =>
        rows
          .addRow(
            'sum',
            noteLabel('Summa'),
            ref(or(sum(id('notes.note61.table.*.amount')), 0))
          )
          .build()
      )
      .build(),
    commentLabel: field('Kommentar'),
    comment: ixbrlField(active(field('')), {
      type: 'stringItemType',
      name: 'se-gen-base:NotOvrigaRorelseintakterKommentar',
      contextRef: 'period0',
    }),
    group: value(undefined),
    section: value(undefined),
    validForReportType: {
      k2: true,
      k3: true,
    },
  };
};

export default getNote61;

import { SortType } from './types';
import PersonOverviewActions from './action-types';

interface ToggleExpandLamps {
  type: typeof PersonOverviewActions.TOGGLE_LAMPS_EXPANDED;
}

export const toggleExpandLamps = (): ToggleExpandLamps => ({
  type: PersonOverviewActions.TOGGLE_LAMPS_EXPANDED,
});

interface SetSortByType {
  type: typeof PersonOverviewActions.SET_SORT_TYPE;
  sortType: SortType;
}

export const setSortByType = (sortType: SortType): SetSortByType => ({
  type: PersonOverviewActions.SET_SORT_TYPE,
  sortType,
});

interface SetNameFilter {
  type: typeof PersonOverviewActions.SET_NAME_FILTER;
  search: string;
}

export const setFilterName = (search: string): SetNameFilter => ({
  type: PersonOverviewActions.SET_NAME_FILTER,
  search,
});

export type PersonsOverviewActions =
  | ToggleExpandLamps
  | SetSortByType
  | SetNameFilter;

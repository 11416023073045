export const isDefined = <T>(item: T | undefined): item is T =>
  item !== undefined;

export const isUndefined = (item: unknown): item is undefined =>
  item === undefined;

export const isNotNull = <T>(item: T | null): item is T => item !== null;

export const filterResult = (arr: any, searchKey: string) => {
  if (!arr) return;
  return arr.filter((obj: { [x: string]: string }) => {
    return Object.keys(obj).some((key) => {
      return obj[key].includes(searchKey);
    });
  });
};

/*
 * This is a GENERATED file, do not change manually!
 * Generated:
 * - from file k3-arsredovisning-2021-10-31-rev20230131 - Noter.csv
 * - Comments: AGOY-6792 (former 407)
 */

import {
  value,
  ref,
  sumAllowNull,
  id,
  AgoyDocumentPart,
} from '@agoy/document';
import { IxbrlCell } from '../../../../../common';
import {
  field,
  ixbrlCell,
  ixbrlField,
  table,
} from '../../../../../common/utils/util';
import { previousYear, year } from '../../../../../common/utils/date-util';
import {
  sumAccountsIBs,
} from '../../../../../common/utils/accountSumsUtil';

const cell = { type: 'field' as const };
const boolean = { type: 'boolean' as const };
const type = { type: 'type' as const };
const number = { type: 'number' as const };

export const getNote2067K3v2 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null
): AgoyDocumentPart<typeof note2067K3v2ContentDefinition> => ({
  active: false,
  number: value(undefined),
  name: noteLabel('Uppskrivningsfond'),
  data: {
    active: true,
    UppskrivningsfondAbstract: {
      type: 'group',
      active: true,
      level: 0,
      title: field('Uppskrivningsfond'),
      table: table<IxbrlCell>(
        'notes.note67.data.UppskrivningsfondAbstract.table',
        {
          id: 'UppskrivningsfondAbstract',
          label: '',
          dataType: 'text',
          active: true,
        },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) ?? '',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) ?? '',
          dataType: 'numeric',
          active: true,
        }
      )
        .addRows((rows) => {
          rows
            .addRowWithType(
              'UppskrivningsfondAbstract',
              'hidden',
              undefined,
              undefined,
              undefined
            )
            .addSubRows((rows) => {
              rows.addRowWithType(
                'UppskrivningsfondIngaende',
                'sum_accounts',
                noteLabel('Ingående balans'),
                ixbrlCell(ref(sumAccountsIBs('2085', true)), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:Uppskrivningsfond',
                  standardRubrik: 'Uppskrivningsfond',
                  saldo: 'credit',
                  negateValue: false,
                }),
                ixbrlCell(ref(sumAccountsIBs('2085', true, 'previousYear')), {
                  contextRef: 'balans2',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:Uppskrivningsfond',
                  standardRubrik: 'Uppskrivningsfond',
                  saldo: 'credit',
                  negateValue: false,
                })
              );
              rows
                .addRowWithType(
                  'UppskrivningsfondForandringarAbstract',
                  'header',
                  noteLabel('Förändringar av uppskrivningsfond'),
                  undefined,
                  undefined
                )
                .addSubRows((rows) => {
                  rows.addRowWithType(
                    'ForandringAvsattningFondUnderRakenskapsaret',
                    'row',
                    noteLabel('Avsättning till fonden under räkenskapsåret'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForandringAvsattningFondUnderRakenskapsaret',
                      standardRubrik:
                        'Avsättning till uppskrivningsfond under perioden',
                      saldo: 'credit',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForandringAvsattningFondUnderRakenskapsaret',
                      standardRubrik:
                        'Avsättning till uppskrivningsfond under perioden',
                      saldo: 'credit',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'ForandringAvskrivningUppskrivnaTillgangar',
                    'row',
                    noteLabel('Avskrivning av uppskrivna tillgångar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForandringAvskrivningUppskrivnaTillgangar',
                      standardRubrik:
                        'Minskning av uppskrivningsfond i samband med avskrivning av uppskrivna tillgångar',
                      saldo: 'debit',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForandringAvskrivningUppskrivnaTillgangar',
                      standardRubrik:
                        'Minskning av uppskrivningsfond i samband med avskrivning av uppskrivna tillgångar',
                      saldo: 'debit',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'ForandringNedskrivningUppskrivnaTillgangar',
                    'row',
                    noteLabel('Nedskrivning av uppskrivna tillgångar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForandringNedskrivningUppskrivnaTillgangar',
                      standardRubrik:
                        'Minskning av uppskrivningsfond i samband med nedskrivning av uppskrivna tillgångar',
                      saldo: 'debit',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForandringNedskrivningUppskrivnaTillgangar',
                      standardRubrik:
                        'Minskning av uppskrivningsfond i samband med nedskrivning av uppskrivna tillgångar',
                      saldo: 'debit',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'ForandringForsaljningUtrangeringUppskrivnaTillgangar',
                    'row',
                    noteLabel(
                      'Försäljning/utrangering av uppskrivna tillgångar'
                    ),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForandringForsaljningUtrangeringUppskrivnaTillgangar',
                      standardRubrik:
                        'Minskning av uppskrivningsfond i samband med försäljning eller utrangering av uppskrivna tillgångar',
                      saldo: 'debit',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForandringForsaljningUtrangeringUppskrivnaTillgangar',
                      standardRubrik:
                        'Minskning av uppskrivningsfond i samband med försäljning eller utrangering av uppskrivna tillgångar',
                      saldo: 'debit',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'ForandringIanspraktagetFondemission',
                    'row',
                    noteLabel('Ianspråktaget för fondemission'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForandringIanspraktagetFondemission',
                      standardRubrik:
                        'Under perioden ianspråktagen uppskrivningsfond för fondemission',
                      saldo: 'debit',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForandringIanspraktagetFondemission',
                      standardRubrik:
                        'Under perioden ianspråktagen uppskrivningsfond för fondemission',
                      saldo: 'debit',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'ForandringIanspraktagetFondemissionTackForlust',
                    'row',
                    noteLabel('Ianspråktaget för täckande av förlust'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForandringIanspraktagetFondemissionTackForlust',
                      standardRubrik:
                        'Under perioden ianspråktagen uppskrivningsfond för täckning av förlust',
                      saldo: 'debit',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForandringIanspraktagetFondemissionTackForlust',
                      standardRubrik:
                        'Under perioden ianspråktagen uppskrivningsfond för täckning av förlust',
                      saldo: 'debit',
                      negateValue: true,
                    })
                  );
                  return rows.build();
                });
              rows.addRowWithType(
                'UppskrivningsfondUtgaende',
                'sum',
                noteLabel('Utgående balans'),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note67.data.UppskrivningsfondAbstract.table.UppskrivningsfondAbstract.UppskrivningsfondIngaende.year'
                      ),
                      id(
                        'notes.note67.data.UppskrivningsfondAbstract.table.UppskrivningsfondAbstract.UppskrivningsfondForandringarAbstract.*.year'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans0',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:Uppskrivningsfond',
                    standardRubrik: 'Uppskrivningsfond',
                    saldo: 'credit',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note67.data.UppskrivningsfondAbstract.table.UppskrivningsfondAbstract.UppskrivningsfondIngaende.previousYear'
                      ),
                      id(
                        'notes.note67.data.UppskrivningsfondAbstract.table.UppskrivningsfondAbstract.UppskrivningsfondForandringarAbstract.*.previousYear'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans1',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:Uppskrivningsfond',
                    standardRubrik: 'Uppskrivningsfond',
                    saldo: 'credit',
                    negateValue: false,
                  }
                )
              );
              rows.addRowWithType(
                'UppskrivnaTillgangarVardeExklUppskrivning',
                'row',
                noteLabel('Uppskrivna tillgångarnas värde exkl. uppskrivning'),
                ixbrlCell(value(undefined), {
                  contextRef: 'balans0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:UppskrivnaTillgangarVardeExklUppskrivning',
                  standardRubrik:
                    'Uppskrivna tillgångars värde exklusive uppskrivning',
                  saldo: 'debit',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:UppskrivnaTillgangarVardeExklUppskrivning',
                  standardRubrik:
                    'Uppskrivna tillgångars värde exklusive uppskrivning',
                  saldo: 'debit',
                  negateValue: false,
                })
              );
              return rows.build();
            });
          return rows.build();
        })
        .build(),
      NotUppskrivningsfondKommentar: {
        type: 'group',
        active: true,
        level: 0,
        title: field('Kommentar till specifikation av uppskrivningsfond'),
        value: ixbrlField(field(''), {
          contextRef: 'period0',
          type: 'stringItemType',
          name: 'se-gen-base:NotUppskrivningsfondKommentar',
          standardRubrik: 'Kommentar till specifikation av uppskrivningsfond',
        }),
      },
    },
  },
  group: value(undefined),
  section: value(undefined),
  validForReportType: {
    k2: true,
    k3: true,
  },
});
export const note2067K3v2ContentDefinition = {
  type: 'part' as const,
  children: {
    active: boolean,
    number: cell,
    name: cell,
    data: {
      type: 'part' as const,
      children: {
        active: boolean,

        UppskrivningsfondAbstract: {
          type: 'part' as const,
          children: {
            type: type,
            level: number,
            active: boolean,

            title: cell,

            table: { type: 'table' as const },

            NotUppskrivningsfondKommentar: {
              type: 'part' as const,
              children: {
                type: type,
                level: number,
                active: boolean,

                title: cell,

                value: cell,
              },
            },
          },
        },
      },
    },
    group: cell,
    section: cell,
    validForReportType: {
      type: 'part' as const,
      children: {
        k2: boolean,
        k3: boolean,
      },
    },
  },
};

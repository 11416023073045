import React from 'react';
import { Chip as MaterialChip } from '@material-ui/core';
import styled from '@emotion/styled';

const VerFilterWrapper = styled.div`
  justify-self: left;
  grid-column-start: 2;
  grid-column-end: 11;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
`;

const VerFilterChipWrapper = styled.div`
  margin-right: ${(props) => props.theme.spacing(1)}px;
`;

const VerFilterChip = styled(MaterialChip)`
  margin-right: ${(props) => props.theme.spacing(1)}px;
  background-color: ${(props) => props.theme.palette.success.main};
`;

const VerFilter = ({
  filterByVerificationNumber,
  handleVerFilterDeselect,
}: {
  filterByVerificationNumber: string | null;
  handleVerFilterDeselect: () => void;
}) => {
  if (!filterByVerificationNumber) return null;

  return (
    <VerFilterWrapper>
      <VerFilterChipWrapper>
        <VerFilterChip
          label={filterByVerificationNumber}
          onDelete={handleVerFilterDeselect}
        />
      </VerFilterChipWrapper>
    </VerFilterWrapper>
  );
};

export default VerFilter;

import * as t from 'io-ts';

export const ReconciliationMovedAccountsCodec = t.type({
  clientId: t.string,
  topic: t.literal('reconciliation-moved-accounts'),
  userId: t.string,
  financialYearId: t.number,
});

export type ReconciliationMovedAccounts = t.TypeOf<
  typeof ReconciliationMovedAccountsCodec
>;

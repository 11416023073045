import { isFinancialYear } from '@agoy/common';
import { differenceInCalendarYears, getYear } from 'date-fns';
import { generateAllPeriods } from 'utils/periodManipulation';

const toPeriods = (financialYear: string): string[] | undefined => {
  if (isFinancialYear(financialYear)) {
    const [from, to] = financialYear.split('-', 2);
    if (from && to) {
      return generateAllPeriods(from, to);
    }
  }
  return undefined;
};
/**
 * Get the date of when the financial year is ending
 * 20210123-20220223
 * @param financialYear
 * @returns
 */
export const financialYearEndDate = (financialYear: string) => {
  return new Date(
    Number(financialYear.substring(9, 13)),
    Number(financialYear.substring(13, 15)) - 1, // mont starts 0 index
    Number(financialYear.substring(15, 17))
  );
};

/**
 * Get the date of when the financial year is starting
 * Ex. 20210123
 *
 * @param financialYear
 * @returns
 */
export const financialYearStartDate = (financialYear: string) => {
  return new Date(
    Number(financialYear.substring(0, 4)),
    Number(financialYear.substring(4, 6)) - 1, // month starts 0 index
    Number(financialYear.substring(6, 8))
  );
};

/**
 * Takes a financialYear and returns all the years from start to end
 * @param financialYear year format 20210123-20220223
 * @returns an array of the years from start to end e.g. ['2020', '2021']
 */
export const calculateFinancialYears = (financialYear: string) => {
  let years: string[] | number[] = [];
  const startYear = getYear(financialYearStartDate(financialYear));
  const endYear = getYear(financialYearEndDate(financialYear));

  if (
    differenceInCalendarYears(
      financialYearEndDate(financialYear),
      financialYearStartDate(financialYear)
    ) === 0
  ) {
    years = [startYear];
  }

  if (
    differenceInCalendarYears(
      financialYearEndDate(financialYear),
      financialYearStartDate(financialYear)
    ) === 1
  ) {
    years = [startYear, endYear];
  }

  // Financial years with 18 months can include three different years
  if (
    differenceInCalendarYears(
      financialYearEndDate(financialYear),
      financialYearStartDate(financialYear)
    ) === 2
  ) {
    years = [startYear, startYear + 1, endYear];
  }

  return years.map((year) => year.toString());
};

export default toPeriods;

import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { format, sub, addDays } from 'date-fns';
import styled from '@emotion/styled';
import { ActivityLog } from '@agoy/activity-log';

import { useSelector } from 'redux/reducers';
import { getOrganisationMembers } from 'redux/actions';
import { useApiSdk } from 'api-sdk';
import Table from './Table';
import Header from './Header';
import Filters from './Filters';

const Wrapper = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)}px;
`;

const FiltersWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  gap: ${(props) => props.theme.spacing(2)}px;
`;

export type ActivityLogEventType = {
  id: number;
  date: string;
  userName: string;
  program: string;
  section: string;
  description: string;
};

export type FilterElementType = ReturnType<
  typeof ActivityLog.getAvailablePrograms
>[0];

type ActivityLogProps = {
  clientName: string;
  tableHeaders: string[];
  tableRows: ActivityLogEventType[];
  users: FilterElementType[];
  totalResults: number;
  currentPage: number;
  onSearchClick: (filters) => void;
  onSelectResultPage: (selectedPage: number) => void;
};

export const ActivityLogView = ({
  clientName,
  tableHeaders = [],
  tableRows = [],
  users = [],
  totalResults,
  currentPage,
  onSearchClick,
  onSelectResultPage,
}: ActivityLogProps): JSX.Element => {
  return (
    <Wrapper>
      <Header clientName={clientName} />
      <FiltersWrapper>
        <Filters users={users} onSearchClick={onSearchClick} />
      </FiltersWrapper>
      <Table
        headers={tableHeaders}
        rows={tableRows}
        totalResults={totalResults}
        currentPage={currentPage}
        onSelectResultPage={onSelectResultPage}
      />
    </Wrapper>
  );
};

type Sdk = Awaited<ReturnType<typeof useApiSdk>>;
type ActivityLogParams = Parameters<Sdk['getActivityLogEvents']>[0];

/**
 * Container component to:
 * interface with redux, keep local state and handle callbacks, so inner components dont have to.
 */
const Container = ({ clientId }: { clientId: string }): JSX.Element => {
  const dispatch = useDispatch();
  const sdk = useApiSdk();

  const clientName = useSelector((state) => state.customers[clientId]?.name);
  const usersFromState = useSelector((state) => state.organisation.users);
  const users = usersFromState.map((userFromState) => ({
    id: userFromState.userId,
    label: `${userFromState.givenName} ${userFromState.familyName} - ${userFromState.email}`,
  }));

  // STATE
  const [filters, setFilters] = useState<ActivityLogParams>({
    offset: 0,
    limit: 10,
    fromDate: format(sub(new Date(), { months: 1 }), 'yyyy-MM-dd'),
    toDate: format(addDays(new Date(), 1), 'yyyy-MM-dd'),
    userId: undefined,
    program: undefined,
    section: undefined,
    clientId,
  });

  const [paginationMetadata, setPaginationMetadata] = useState({
    totalResults: 0,
    totalPages: 0,
    currentPage: 0,
  });

  const [activityLogEvents, setActivityLogEvents] = useState<
    ActivityLogEventType[]
  >([]);

  const fetchActivityLogEvents = useCallback(
    async (args: ActivityLogParams) => {
      const result = await sdk.getActivityLogEvents(args);

      setActivityLogEvents(result.results);
      setPaginationMetadata({
        totalPages: result.totalPages,
        totalResults: result.totalResults,
        currentPage: result.currentPage,
      });
    },
    []
  );

  // EFFECTS

  // initial fetch
  useEffect(() => {
    fetchActivityLogEvents(filters);
    dispatch(getOrganisationMembers());
  }, []);

  // fetch on filter and pagination change
  useEffect(() => {
    fetchActivityLogEvents(filters);
  }, [filters, fetchActivityLogEvents, clientId]);

  // HANDLERS

  const handleSelectResultPage = (selectedPage: number) => {
    setPaginationMetadata({ ...paginationMetadata, currentPage: selectedPage });
    setFilters({
      ...filters,
      clientId,
      offset: (selectedPage - 1) * 10,
    });
  };

  const handleSearchClick = (filters) => {
    setFilters({ clientId, ...filters });
  };

  return (
    <ActivityLogView
      clientName={clientName}
      tableHeaders={['DATUM', 'ANVÄNDARE', 'PROGRAM', 'RUBRIK', 'HÄNDELSE']}
      tableRows={activityLogEvents}
      users={users}
      totalResults={paginationMetadata.totalResults}
      currentPage={paginationMetadata.currentPage}
      onSearchClick={handleSearchClick}
      onSelectResultPage={handleSelectResultPage}
    />
  );
};

export default Container;

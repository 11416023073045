import { cloneDeep } from 'lodash';

/**
 * Construct the member list to be used in the popup state.
 * The authorisation of the members over a client is also computed.
 * @param organisationMembers all the members in the organisation
 * @param clientAuthorisedMembers  authorised members for the selected client
 */
export const calculateAuthorisedMembers = (
  orgMembers: Member.MemberType[] | null = [],
  clientAuthorisedMembers: Member.MemberType[] | null
): Member.Members => {
  const authorisedMembers = {};
  if (orgMembers === null || !orgMembers.length) return authorisedMembers;

  const clientAuthorisedMemberEmails = clientAuthorisedMembers?.map(
    (c) => c.email
  );

  orgMembers.forEach((member) => {
    const isClientAuthorisedForMember = clientAuthorisedMemberEmails?.includes(
      member.email
    );

    authorisedMembers[member.email] = {
      ...member,
      isAuthorised: isClientAuthorisedForMember,
      isAuthorisedEditedValue: isClientAuthorisedForMember,
      isVisible: true,
    };
  });

  return authorisedMembers;
};

export const resetMembers = (members: Member.Members): Member.Members => {
  const resetedMembers = cloneDeep(members);

  Object.keys(resetedMembers).forEach((memberEmail) => {
    resetedMembers[memberEmail].isAuthorisedEditedValue =
      resetedMembers[memberEmail].isAuthorised;
    resetedMembers[memberEmail].isVisible = true;
  });

  return resetedMembers;
};

// Filter helpers

export const filterMembers = ({
  members,
  filters,
}: {
  members: Member.Members;
  filters: Member.Filters;
}) => {
  const filteredMembers = cloneDeep(members);

  Object.keys(members).forEach((memberEmail) => {
    let isVisible = true;

    if (
      filters.memberEmail.length > 0 &&
      !filteredMembers[memberEmail].email
        .toLowerCase()
        .includes(filters.memberEmail.toLowerCase())
    ) {
      isVisible = false;
    }

    if (
      isVisible &&
      filters.authorisedOnly &&
      !filteredMembers[memberEmail].isAuthorisedEditedValue
    ) {
      isVisible = false;
    }

    filteredMembers[memberEmail].isVisible = isVisible;
  });

  return filteredMembers;
};

import React, { memo } from 'react';
import { useIntl } from 'react-intl';
import { FormControlLabel, Switch, Typography } from '@material-ui/core';
import styled from '@emotion/styled';

import { STATUSES } from '_shared/redux/checklist/constants';
import {
  ChecklistItemStatus,
  ChecklistStatuses,
  Checklist,
} from '_shared/redux/checklist/types';
import { Period } from '@agoy/api-sdk-core';

import StatusSelect from './StatusSelect';
import StatusColumns from './StatusColumns';
import { formatPeriod } from './PeriodsRow';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing(0.5)}px;
`;

const Title = styled(Typography)`
  font-size: 24px;
  padding: 8px 8px;
`;

const GroupLabel = styled(Typography)`
  font-size: 18px;
  font-weight: 500;
  padding: ${({ theme }) => theme.spacing(1)}px
    ${({ theme }) => theme.spacing(1)}px;
  background-color: #f1f7fb;
`;

const Items = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;

  > div:nth-of-type(2n) {
    background-color: #f6f6f6;
  }
`;

const ItemContainer = styled.div<{ withColumns: boolean }>`
  display: grid;
  grid-template-columns: 1fr ${({ withColumns }) =>
      withColumns ? '235px' : 'max-content'};
  grid-gap: ${({ theme }) => theme.spacing(0.5)}px;
  align-items: center;
  padding-right: ${({ theme }) => theme.spacing(2)}px;
`;

const ItemLabel = styled(Typography)`
  font-size: ${({ theme }) => theme.spacing(2)}px;
  padding: ${({ theme }) => theme.spacing(1)}px
    ${({ theme }) => theme.spacing(1)}px;
  white-space: normal;
`;

const ColumnsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

const Columns = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
`;

const Column = styled(Typography)`
  font-size: 11px;
  text-align: center;
  white-space: normal;
`;

const StyledSwitch = styled(Switch)`
  margin-left: 8px;
`;

interface ChecklistProps {
  statuses: ChecklistStatuses;
  checklist: Checklist;
  period?: Period;
  withColumns: boolean;
  financialYear?: string;
  onChangeStatus: (
    questionId: number,
    status: ChecklistItemStatus,
    selectedPeriod?: Period
  ) => void;
  onToggleInclude?: () => void;
}

const ChecklistComponent = ({
  statuses,
  checklist,
  period,
  withColumns,
  financialYear,
  onChangeStatus,
  onToggleInclude,
}: ChecklistProps) => {
  const { formatMessage } = useIntl();

  const selectedPeriodId = period ? period.id : 'year';
  const periodTitle = period ? formatPeriod(period.start) : financialYear;

  const handleChangeStatus = (
    questionId: number,
    status: ChecklistItemStatus
  ) => {
    onChangeStatus(questionId, status, period);
  };

  if (!checklist.periods[selectedPeriodId]) {
    return null;
  }

  const currentPeriodStatuses = statuses[selectedPeriodId] || {};
  const { groups } = checklist.periods[selectedPeriodId];

  return (
    <Container>
      <ItemContainer withColumns={withColumns}>
        <Title>
          {formatMessage({ id: 'checklist.label' })}{' '}
          {formatMessage({ id: `checklist.title.${checklist.program}` })}{' '}
          {formatMessage(
            { id: `checklist.title.period` },
            {
              period: periodTitle,
            }
          )}
        </Title>

        {!!onToggleInclude && !withColumns && (
          <FormControlLabel
            control={
              <StyledSwitch
                checked={checklist.includeInPrint}
                onChange={onToggleInclude}
                size="small"
              />
            }
            label={formatMessage({ id: 'checklist.includeToReport' })}
            labelPlacement="start"
          />
        )}

        {withColumns && (
          <ColumnsWrapper>
            {!!onToggleInclude && (
              <FormControlLabel
                control={
                  <StyledSwitch
                    checked={checklist.includeInPrint}
                    onChange={onToggleInclude}
                    size="small"
                  />
                }
                label={formatMessage({ id: 'checklist.includeToReport' })}
                labelPlacement="start"
              />
            )}
            <Columns>
              {STATUSES.map((status) => (
                <Column key={status}>
                  {formatMessage({ id: `checklist.status.${status}` })}
                </Column>
              ))}
            </Columns>
          </ColumnsWrapper>
        )}
      </ItemContainer>

      {groups.map((group) => (
        <React.Fragment key={group.id}>
          <GroupLabel>{group.label}</GroupLabel>
          <Items>
            {group.questions.map((question) => (
              <ItemContainer key={question.id} withColumns={withColumns}>
                <ItemLabel>{question.label}</ItemLabel>
                {withColumns ? (
                  <StatusColumns
                    value={currentPeriodStatuses[question.id!] || 'not_started'}
                    onOptionSelected={(status) =>
                      handleChangeStatus(question.id!, status)
                    }
                  />
                ) : (
                  <StatusSelect
                    value={currentPeriodStatuses[question.id!] || 'not_started'}
                    onOptionSelected={(status) =>
                      handleChangeStatus(question.id!, status)
                    }
                  />
                )}
              </ItemContainer>
            ))}
          </Items>
        </React.Fragment>
      ))}
    </Container>
  );
};

export default memo(ChecklistComponent);

import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'redux/reducers';
import { AgoyTable, Cell, numberValue } from '@agoy/document';
import { Settings } from '@agoy/annual-report-document';
import { FinancialReport } from '@agoy/financial-report-document';
import { selectPreviousFinancialYear } from '_clients/redux/customer-view/selectors';

import {
  prepareIncomeStatementPartData,
  ReportColors,
  numberOfTableRows,
} from '../../../../utils';
import StatementTableView from '../../UI/StatementTable/StatementTable';

interface IncomeStatementViewProps {
  incomeStatement: AgoyTable<Cell>;
  reportColors: ReportColors;
  settings: FinancialReport['settings'];
  shouldSplitTable: boolean;
  shouldShowZeros: boolean;
  isPeriod?: boolean;
  print?: boolean;
  part?: 'part1' | 'part2';
}

const IncomeStatementView = ({
  incomeStatement,
  reportColors,
  settings,
  shouldSplitTable,
  shouldShowZeros,
  isPeriod = false,
  print = false,
  part = 'part1',
}: IncomeStatementViewProps): JSX.Element => {
  const { formatMessage } = useIntl();

  const previousFinancialYear = useSelector(selectPreviousFinancialYear);
  const financialYear = useSelector((state) => {
    return state.customerView.currentFinancialYear;
  });

  const baseTitleId = `financialreport.income-statement${
    isPeriod ? '-period' : ''
  }`;
  const fullTitleId =
    numberOfTableRows(incomeStatement, shouldShowZeros) > 0
      ? `${baseTitleId}-${part}`
      : baseTitleId;

  // Prepare first part of report IncomeStatement. Split annual report data
  const incomeStatementTable = prepareIncomeStatementPartData(
    incomeStatement,
    part,
    isPeriod,
    numberValue(settings.section.comparePeriod) ?? 1,
    financialYear ?? '',
    shouldSplitTable,
    shouldShowZeros,
    previousFinancialYear
  );

  return (
    <StatementTableView
      reportColors={reportColors}
      settings={settings as unknown as Settings}
      table={incomeStatementTable}
      tableId={
        part === 'part1'
          ? 'incomeStatement.section.table'
          : 'incomeStatement.section.secondTable'
      }
      pageTitle={formatMessage({ id: fullTitleId })}
      tableTitle={formatMessage({ id: baseTitleId })}
      print={print}
    />
  );
};

export default IncomeStatementView;

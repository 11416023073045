import styled from '@emotion/styled';

export const CenteredCell = styled.div`
  display: inline-grid;
  place-items: center;
  margin: 0;
`;

export const IncomingBalance = styled(CenteredCell)`
  background-color: ${(props) =>
    props.theme.palette.accountingView.cell.incomingBalance};
`;

export const Change = styled(CenteredCell)`
  background-color: ${(props) =>
    props.theme.palette.accountingView.cell.totalChange};
`;

export const OutgoingBalance = styled(CenteredCell)`
  background-color: ${(props) =>
    props.theme.palette.accountingView.cell.outgoingBalance};
`;

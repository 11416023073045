import React from 'react';
import styled from '@emotion/styled';
import Chip from '../Chip';

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing(0.5)}px;
  row-gap: ${({ theme }) => theme.spacing(1)}px;
`;

export type Option = { label: string; name: string; selected: boolean };

interface FilterProps {
  options: Option[];
  onSelect: (value: string) => void;
  onDeselect: (value: string) => void;
}

/**
 * Renders a group of filters.
 * Can be used on it's own or as part of {@link web/src/_shared/components/FilterGroup.ts | FilterGroup}
 *
 * @param options    available options (each option contains name, label, and selected flag)
 * @param onSelect   callback for selecting a filter
 * @param onDeselect callback for deselecting a filter
 *
 * @returns a resizable component with filters
 */
const Filter = ({ options, onSelect, onDeselect }: FilterProps) => {
  return (
    <OptionsContainer>
      {options.map((option) => (
        <Chip
          key={`filter-option-${option.name}`}
          label={option.label}
          variant={option.selected ? 'filled' : 'outlined'}
          onClick={option.selected ? undefined : () => onSelect(option.name)}
          onDelete={option.selected ? () => onDeselect(option.name) : undefined}
        />
      ))}
    </OptionsContainer>
  );
};

export default Filter;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Typography } from '@material-ui/core';
import styled from '@emotion/styled';
import {
  AgoyTableRow,
  AgoyTableColumn,
  Cell,
  StringCell,
  ResolvedReference,
} from '@agoy/document';
import ExpandableTable from '_shared/components/Table/ExpandableTable';
import {
  ccyFormat,
  NumberFormatType,
  PercentageNumberFormatType,
  StandardNumberFormatType,
} from '@agoy/common';
import {
  getPropertyTypeConfig,
  propertiesForSorting,
  getValidatedRows,
  getTableDefaultValues,
} from './propertyTaxUtil';

export type ExpandableTableData = {
  columns: AgoyTableColumn[];
  rows: AgoyTableRow[];
};

type PropertyTaxTableProps = {
  isPrint?: boolean;
  isEditing: boolean;
  rows: AgoyTableRow[];
  columns: AgoyTableColumn[];
  totalAmount: number;
  mainFhsAmount: number;
  alternativeFhsAmount: number;
  currentFinancialYear: string | undefined;
  onDataChange: (data: boolean) => void;
  onUploadRowsUpdate: (data: AgoyTableRow[]) => void;
  onColumnsUpdate: (data: { id: string; align: string }[]) => void;
  handleAmountCalculations: (
    total: number,
    main: number,
    alternative: number
  ) => void;
  setCurrentYearRows: (rows: AgoyTableRow[]) => void;
};

const TableWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.palette.grey[300]};
  border-radius: 5px;
  border-bottom: none;
`;

const isString = (
  value: ResolvedReference | ResolvedReference[]
): value is string => {
  return (value as string) !== undefined;
};

const usePercentageForColumn = (colId: string): NumberFormatType =>
  colId === 'owned' || colId === 'taxPercentage'
    ? PercentageNumberFormatType
    : StandardNumberFormatType;

const columnKeys = [
  'name',
  'type',
  'owned',
  'numberOfOwners',
  'area',
  'taxValue',
  'ownedTaxValue',
  'taxPercentage',
  'taxSum',
  'taxExempt',
  'ceilingAmount',
  'numberOfLGH',
  'fixedAmount',
];

const { defaultCells } = getTableDefaultValues(columnKeys);

const SumRow = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: ${({ theme }) => theme.spacing(2)}px
    ${({ theme }) => theme.spacing(4)}px;
  width: 100%;
  background-color: #fff;
  box-shadow: ${({ theme }) => theme.shadows[1]};
`;

const SumRowTitle = styled(Typography)`
  font-size: 20px;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.text.primary};
  text-transform: capitalize;
`;

const SumRowTotal = styled(Typography)`
  margin-left: 30px;
  font-size: 20px;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.text.primary};
  text-transform: capitalize;
`;

const PropertyTaxTable = ({
  isPrint,
  isEditing,
  rows,
  columns,
  totalAmount,
  mainFhsAmount,
  alternativeFhsAmount,
  currentFinancialYear,
  onDataChange,
  onColumnsUpdate,
  onUploadRowsUpdate,
  handleAmountCalculations,
  setCurrentYearRows,
}: PropertyTaxTableProps) => {
  const [isChanges, setIsChanges] = useState(false);

  const calculateActualValues = useCallback(
    (currentRows, updatedRows, typeBRF, typeLantbruk) => {
      let totalSum = 0;
      let brfTypeTaxSum = 0;
      let defaultTaxSum = 0;
      const newRows = updatedRows.map((row, index) => {
        const { value } = row.cells.type;
        const [_, option] = value.split('-');
        const propertyConfig = getPropertyTypeConfig(
          currentFinancialYear || ''
        );
        brfTypeTaxSum = 0;
        defaultTaxSum = 0;
        let comparedTaxSum;
        let newTaxPercentage;
        let newOwnedTaxValue;
        let newTaxExemptValue = row.cells.taxExempt?.value;
        let newCeilingAmount =
          row.cells.ceilingAmount?.value ||
          propertyConfig[option]?.amount ||
          '';
        const newFixedAmount =
          row.cells.fixedAmount?.value ||
          propertyConfig[option]?.fixedAmount ||
          '';
        const newNumberOfLGH = row.cells.numberOfLGH?.value || '';

        if (row.cells.type.value !== '') {
          newTaxPercentage = propertyConfig[option]?.tax;
          newCeilingAmount = propertyConfig[option]?.amount;

          if (currentRows[index]?.cells?.type?.value !== row.cells.type.value) {
            newTaxExemptValue = propertyConfig[option].fee;
          }
        }

        if (row.cells.owned.value && row.cells.taxValue.value) {
          newOwnedTaxValue = row.cells.owned.value * row.cells.taxValue.value;
        }

        if (newOwnedTaxValue && newTaxPercentage) {
          defaultTaxSum = newOwnedTaxValue * newTaxPercentage;
        }

        comparedTaxSum = defaultTaxSum;

        if (
          typeBRF &&
          newNumberOfLGH &&
          newFixedAmount &&
          row.cells.owned.value
        ) {
          brfTypeTaxSum =
            newNumberOfLGH * newFixedAmount * row.cells.owned.value;
          comparedTaxSum =
            defaultTaxSum < brfTypeTaxSum ? defaultTaxSum : brfTypeTaxSum;
        }

        if (newCeilingAmount) {
          comparedTaxSum =
            comparedTaxSum < Number(newCeilingAmount)
              ? comparedTaxSum
              : Number(newCeilingAmount);
        }

        if (newTaxExemptValue) {
          defaultTaxSum = 0;
        }

        if (defaultTaxSum) {
          totalSum += comparedTaxSum;
        }

        let newRow = {
          ...row,
          cells: {
            ...row.cells,
            taxPercentage: {
              ...row.cells.taxPercentage,
              value: newTaxPercentage,
            },
            ownedTaxValue: {
              ...row.cells.ownedTaxValue,
              value: newOwnedTaxValue,
            },
            taxSum: {
              ...row.cells.taxSum,
              value: comparedTaxSum,
            },
            taxExempt: {
              type: 'boolean',
              value: newTaxExemptValue,
            },
          },
        };

        if (typeBRF) {
          newRow = {
            ...newRow,
            cells: {
              ...newRow.cells,
              numberOfLGH: {
                type: 'string',
                value: `${newNumberOfLGH}`,
              },
              fixedAmount: {
                type: 'string',
                value: `${newFixedAmount}`,
              },
            },
          };
        }

        if (typeLantbruk) {
          newRow = {
            ...newRow,
            cells: {
              ...newRow.cells,
              ceilingAmount: {
                type: 'string',
                value: `${newCeilingAmount}`,
              },
            },
          };
        }

        return newRow;
      });
      handleAmountCalculations(totalSum, defaultTaxSum, brfTypeTaxSum);
      return newRows;
    },
    []
  );

  const handleDataUpdate = useCallback(
    async (data) => {
      setIsChanges(true);
      const updatedRows = data.rows;
      const updatedColumns = data.columns.map((col) => col.id);
      const typeBRF = data.rows.find((row) => {
        const { value } = row.cells.type;
        const [type, _] = value?.split('-');
        return type === 'BRF';
      });
      const typeLantbruk = data.rows.find((row) => {
        const { value } = row.cells.type;
        const [type, _] = value?.split('-');
        return type === 'Lantbruk';
      });

      if (typeBRF && !updatedColumns.includes('numberOfLGH', 'fixedAmount')) {
        updatedColumns.splice(2, 0, 'numberOfLGH', 'fixedAmount');
      } else if (
        !typeBRF &&
        updatedColumns.includes('numberOfLGH', 'fixedAmount')
      ) {
        updatedRows.forEach((row) => {
          delete row.cells.numberOfLGH;
          delete row.cells.fixedAmount;
        });
        const numberOfLGHIndex = updatedColumns.indexOf('numberOfLGH');
        updatedColumns.splice(numberOfLGHIndex, 1);
        const fixedAmountIndex = updatedColumns.indexOf('fixedAmount');
        updatedColumns.splice(fixedAmountIndex, 1);
      }

      if (typeLantbruk && !updatedColumns.includes('ceilingAmount')) {
        updatedColumns.splice(11, 0, 'ceilingAmount');
      } else if (!typeLantbruk && updatedColumns.includes('ceilingAmount')) {
        updatedRows.forEach((row) => {
          delete row.cells.ceilingAmount;
        });
        const ceilingAmountIndex = updatedColumns.indexOf('ceilingAmount');
        updatedColumns.splice(ceilingAmountIndex, 1);
      }
      const formattedColumns = updatedColumns.map((col) => ({
        id: col,
        align: 'left',
      }));
      onColumnsUpdate(formattedColumns);
      const calculatedRows = calculateActualValues(
        rows,
        updatedRows,
        typeBRF,
        typeLantbruk
      );
      setCurrentYearRows(calculatedRows);
    },
    [rows, calculateActualValues]
  );

  useEffect(() => {
    if (isChanges) {
      const { rows: validatedRows } = getValidatedRows(rows);
      onUploadRowsUpdate(validatedRows);
      onDataChange(true);
      setIsChanges(false);
    }
  }, [isChanges, onDataChange, onUploadRowsUpdate, rows]);

  const handleSortByType = (column: AgoyTableColumn | undefined) => {
    if (column && column.id === 'type') {
      const sortedTotalRows = rows
        .sort((firstRow, secondRow) => {
          const typeFirstRow = firstRow.cells?.type;
          const typeSecondRow = secondRow.cells?.type;

          let newFirstValue;
          let newSecondValue;

          if (typeFirstRow && 'value' in typeFirstRow) {
            const { value } = typeFirstRow;
            newFirstValue = propertiesForSorting.findIndex((property) => {
              if (!value) {
                return false;
              }

              const [type] = value.toString().split('-');
              return property === type;
            });
          }

          if (typeSecondRow && 'value' in typeSecondRow) {
            const { value } = typeSecondRow;
            newSecondValue = propertiesForSorting.findIndex((property) => {
              if (!value) {
                return false;
              }

              const [type] = value.toString().split('-');
              return property === type;
            });
          }

          if (newFirstValue < newSecondValue) {
            return -1;
          }
          if (newFirstValue > newSecondValue) {
            return 1;
          }
          return 0;
        })
        .map((row, index) => {
          return {
            ...row,
            id: `${index + 1}`,
          };
        });
      setCurrentYearRows(sortedTotalRows);
    }
  };

  const handleShowRowInfo = useCallback(
    (cells: Record<string, Cell> | undefined) => {
      if (cells && 'type' in cells && 'value' in cells.type) {
        const { value } = cells.type;
        if (isString(value)) {
          const [, option] = value.split('-');
          if (option === 'Småhus') {
            return 'Du behöver inte betala någon fastighetsavgift de första 15 åren för nybyggda småhus och ägarlägenheter som har värdeår 2012 och senare';
          }

          if (option === 'Lägenheter') {
            return `Fastighetsskatten är det lägsta värdet av antingen fast årligt belopp gånger antal lägenheter (${ccyFormat(
              alternativeFhsAmount,
              2
            )}) alt. 0,3 procent av taxeringsvärde för byggnad och mark (${ccyFormat(
              mainFhsAmount,
              2
            )}).`;
          }
        }
      }

      return undefined;
    },
    [alternativeFhsAmount, mainFhsAmount]
  );

  const rowsWithBRFFilter = useMemo(() => {
    const isStringCell = (cell: Cell): cell is StringCell => {
      return cell.type === 'string';
    };
    return rows.map((row: AgoyTableRow) => {
      if (
        row.cells?.type &&
        isStringCell(row.cells.type) &&
        !row.cells.type.value.includes('BRF')
      ) {
        delete row.cells.numberOfLGH;
      }
      return row;
    });
  }, [rows]);

  return (
    <TableWrapper>
      <SumRow>
        <SumRowTitle>Total fastighetsskatt</SumRowTitle>
        <SumRowTotal>{ccyFormat(totalAmount, 2)}</SumRowTotal>
      </SumRow>
      <ExpandableTable
        isPrint={isPrint}
        isEditing={isEditing}
        baseId="propertyTax"
        tableId="propertyTaxTable"
        defaultColumnKeys={columnKeys}
        initialData={rowsWithBRFFilter}
        initialColumns={columns}
        headerCellTitle="propertyTax.pressableHeaderCell.title"
        nonEditableRowColumns={[
          'ownedTaxValue',
          'taxPercentage',
          'taxSum',
          'ceilingAmount',
          'fixedAmount',
        ]}
        onDataUpdate={handleDataUpdate}
        widthFitContent={!!isEditing}
        numberFormatColumn={usePercentageForColumn}
        defaultCells={defaultCells}
        onHeaderColumnPress={handleSortByType}
        columnsWithClicking={['type']}
        handleShowRowInfo={handleShowRowInfo}
      />
    </TableWrapper>
  );
};

export default PropertyTaxTable;

import React, { useState } from 'react';
import styled from '@emotion/styled';

import CommonPreviewModal from '_shared/components/CommonPreviewModal';
import { SignaturePerson } from '_reconciliation/types';
import CashViewPreview from './CashViewPreview';

const Container = styled.section`
  @media print {
    page-break-inside: avoid;
  }
`;

type CashViewPreviewModalProps = {
  accountNumber: string;
  signatures: SignaturePerson[];
  richText: string;
  location: string;
  open: boolean;
  onPrint: () => Promise<void>;
  onClose: () => void;
};

const CashViewPreviewModal = ({
  accountNumber,
  signatures,
  richText,
  location,
  open,
  onPrint,
  onClose,
}: CashViewPreviewModalProps): JSX.Element | null => {
  const [printLoading, setPrintLoading] = useState(false);

  const handlePrint = async () => {
    setPrintLoading(true);
    await onPrint();
    setPrintLoading(false);
  };

  return (
    <CommonPreviewModal
      handleShow={onClose}
      onPrint={handlePrint}
      isOpen={open}
      loading={printLoading}
    >
      <Container>
        <CashViewPreview
          accountNumber={accountNumber}
          signatures={signatures}
          richText={richText}
          location={location}
        />
      </Container>
    </CommonPreviewModal>
  );
};

export default CashViewPreviewModal;

import React from 'react';
import { Field } from '@agoy/document';
import { Box, Typography as MuiTypography } from '@material-ui/core';
import styled from '@emotion/styled';

import { IXElement } from '../../../ARKeyToIX';

const Typography = styled(MuiTypography)`
  font-size: inherit;
`;

type FieldSectionProps = {
  field?: Field;
  value: string;
};

const FieldSection = ({ field, value }: FieldSectionProps) => {
  return field && value ? (
    <Box mb={2}>
      <IXElement arPart={field}>
        <Typography style={{ whiteSpace: 'pre-line' }} variant="body1">
          {value}
        </Typography>
      </IXElement>
    </Box>
  ) : (
    <Box mb={2}>
      <Typography style={{ whiteSpace: 'pre-line' }} variant="body1">
        {value}
      </Typography>
    </Box>
  );
};

export default FieldSection;

/*
 * This is a GENERATED file, do not change manually!
 * Generated:
 * - from file k2-arsredovisning-2021-10-31-rev20230131_sv - Noter.csv
 * - Comments: nan
 */

import {
  value,
  ref,
  sumAllowNull,
  id,
  AgoyDocumentPart,
} from '@agoy/document';
import { IxbrlCell } from '../../../../../common';
import {
  field,
  ixbrlCell,
  ixbrlField,
  table,
} from '../../../../../common/utils/util';
import { previousYear, year } from '../../../../../common/utils/date-util';
import {
  sumAccountsIBs,
} from '../../../../../common/utils/accountSumsUtil';

const cell = { type: 'field' as const };
const boolean = { type: 'boolean' as const };
const type = { type: 'type' as const };
const number = { type: 'number' as const };

export const getNote2059K2v2 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null
): AgoyDocumentPart<typeof note2059K2v2ContentDefinition> => ({
  active: false,
  number: value(undefined),
  name: noteLabel('Lån till delägare eller närstående'),
  data: {
    active: true,
    LanDelagareNarstaendeAbstract: {
      type: 'group',
      active: true,
      level: 0,
      title: field('Lån till delägare eller närstående'),
      table: table<IxbrlCell>(
        'notes.note59.data.LanDelagareNarstaendeAbstract.table',
        {
          id: 'LanDelagareNarstaendeAbstract',
          label: '',
          dataType: 'text',
          active: true,
        },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) ?? '',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) ?? '',
          dataType: 'numeric',
          active: true,
        }
      )
        .addRows((rows) => {
          rows
            .addRowWithType(
              'LanDelagareNarstaendeAbstract',
              'hidden',
              undefined,
              undefined,
              undefined
            )
            .addSubRows((rows) => {
              rows.addRowWithType(
                'LanDelagareNarstaendeAnskaffningsvardenIngaende',
                'sum_accounts',
                noteLabel('Ingående anskaffningsvärden'),
                ixbrlCell(ref(sumAccountsIBs('1360:1368', false)), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:LanDelagareNarstaendeAnskaffningsvarden',
                  standardRubrik:
                    'Anskaffningsvärden långfristiga lån till delägare eller närstående',
                  saldo: 'debit',
                  negateValue: false,
                }),
                ixbrlCell(
                  ref(sumAccountsIBs('1360:1368', false, 'previousYear')),
                  {
                    contextRef: 'balans2',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:LanDelagareNarstaendeAnskaffningsvarden',
                    standardRubrik:
                      'Anskaffningsvärden långfristiga lån till delägare eller närstående',
                    saldo: 'debit',
                    negateValue: false,
                  }
                )
              );
              rows
                .addRowWithType(
                  'LanDelagareNarstaendeForandringAnskaffningsvardenAbstract',
                  'header',
                  noteLabel('Förändringar av anskaffningsvärden'),
                  undefined,
                  undefined
                )
                .addSubRows((rows) => {
                  rows.addRowWithType(
                    'LanDelagareNarstaendeForandringAnskaffningsvardenAretsLamnadeLan',
                    'row',
                    noteLabel('Årets lämnade lån'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:LanDelagareNarstaendeForandringAnskaffningsvardenAretsLamnadeLan',
                      standardRubrik:
                        'Ökning av anskaffningsvärden i samband med periodens lämnade lån till delägare eller närstående',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:LanDelagareNarstaendeForandringAnskaffningsvardenAretsLamnadeLan',
                      standardRubrik:
                        'Ökning av anskaffningsvärden i samband med periodens lämnade lån till delägare eller närstående',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'LanDelagareNarstaendeForandringAnskaffningsvardenFusion',
                    'row',
                    noteLabel('Fusion'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:LanDelagareNarstaendeForandringAnskaffningsvardenFusion',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden för lån till delägare eller närstående genom fusion',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:LanDelagareNarstaendeForandringAnskaffningsvardenFusion',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden för lån till delägare eller närstående genom fusion',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'LanDelagareNarstaendeForandringAnskaffningsvardenAretsAmorteringar',
                    'row',
                    noteLabel('Årets amorteringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:LanDelagareNarstaendeForandringAnskaffningsvardenAretsAmorteringar',
                      standardRubrik:
                        'Minskning av anskaffningsvärden i samband med periodens amorteringar av lån till delägare eller närstående',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:LanDelagareNarstaendeForandringAnskaffningsvardenAretsAmorteringar',
                      standardRubrik:
                        'Minskning av anskaffningsvärden i samband med periodens amorteringar av lån till delägare eller närstående',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'LanDelagareNarstaendeForandringAnskaffningsvardenBortskrivnafordringar',
                    'row',
                    noteLabel('Bortskrivna fordringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:LanDelagareNarstaendeForandringAnskaffningsvardenBortskrivnafordringar',
                      standardRubrik:
                        'Minskning av anskaffningsvärden i samband med periodens bortskrivning av lån till delägare eller närstående',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:LanDelagareNarstaendeForandringAnskaffningsvardenBortskrivnafordringar',
                      standardRubrik:
                        'Minskning av anskaffningsvärden i samband med periodens bortskrivning av lån till delägare eller närstående',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'LanDelagareNarstaendeForandringAnskaffningsvardenOmklassificeringar',
                    'row',
                    noteLabel('Omklassificeringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:LanDelagareNarstaendeForandringAnskaffningsvardenOmklassificeringar',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden i samband med omklassificeringar av lån till delägare eller närstående',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:LanDelagareNarstaendeForandringAnskaffningsvardenOmklassificeringar',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden i samband med omklassificeringar av lån till delägare eller närstående',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'LanDelagareNarstaendeForandringAnskaffningsvardenOmrakningsdifferenser',
                    'row',
                    noteLabel('Omräkningsdifferenser'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:LanDelagareNarstaendeForandringAnskaffningsvardenOmrakningsdifferenser',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden i samband med omräkningsdifferenser av lån till delägare eller närstående',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:LanDelagareNarstaendeForandringAnskaffningsvardenOmrakningsdifferenser',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden i samband med omräkningsdifferenser av lån till delägare eller närstående',
                      negateValue: false,
                    })
                  );
                  return rows.build();
                });
              rows.addRowWithType(
                'LanDelagareNarstaendeAnskaffningsvardenUtgaende',
                'sum',
                noteLabel('Utgående anskaffningsvärden'),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note59.data.LanDelagareNarstaendeAbstract.table.LanDelagareNarstaendeAbstract.LanDelagareNarstaendeAnskaffningsvardenIngaende.year'
                      ),
                      id(
                        'notes.note59.data.LanDelagareNarstaendeAbstract.table.LanDelagareNarstaendeAbstract.LanDelagareNarstaendeForandringAnskaffningsvardenAbstract.*.year'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans0',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:LanDelagareNarstaendeAnskaffningsvarden',
                    standardRubrik:
                      'Anskaffningsvärden långfristiga lån till delägare eller närstående',
                    saldo: 'debit',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note59.data.LanDelagareNarstaendeAbstract.table.LanDelagareNarstaendeAbstract.LanDelagareNarstaendeAnskaffningsvardenIngaende.previousYear'
                      ),
                      id(
                        'notes.note59.data.LanDelagareNarstaendeAbstract.table.LanDelagareNarstaendeAbstract.LanDelagareNarstaendeForandringAnskaffningsvardenAbstract.*.previousYear'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans1',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:LanDelagareNarstaendeAnskaffningsvarden',
                    standardRubrik:
                      'Anskaffningsvärden långfristiga lån till delägare eller närstående',
                    saldo: 'debit',
                    negateValue: false,
                  }
                )
              );
              rows.addRowWithType(
                'LanDelagareNarstaendeNedskrivningarIngaende',
                'sum_accounts',
                noteLabel('Ingående nedskrivningar'),
                ixbrlCell(ref(sumAccountsIBs('1369', false)), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:LanDelagareNarstaendeNedskrivningar',
                  standardRubrik:
                    'Ackumulerade nedskrivningar långfristiga lån till delägare eller närstående',
                  saldo: 'credit',
                  negateValue: true,
                }),
                ixbrlCell(ref(sumAccountsIBs('1369', false, 'previousYear')), {
                  contextRef: 'balans2',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:LanDelagareNarstaendeNedskrivningar',
                  standardRubrik:
                    'Ackumulerade nedskrivningar långfristiga lån till delägare eller närstående',
                  saldo: 'credit',
                  negateValue: true,
                })
              );
              rows
                .addRowWithType(
                  'LanDelagareNarstaendeForandringNedskrivningarAbstract',
                  'header',
                  noteLabel('Förändring av nedskrivningar'),
                  undefined,
                  undefined
                )
                .addSubRows((rows) => {
                  rows.addRowWithType(
                    'LanDelagareNarstaendeForandringNedskrivningarAretsAmorteringar',
                    'row',
                    noteLabel('Årets amorteringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:LanDelagareNarstaendeForandringNedskrivningarAretsAmorteringar',
                      standardRubrik:
                        'Amorteringar av lån till delägare eller närstående',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:LanDelagareNarstaendeForandringNedskrivningarAretsAmorteringar',
                      standardRubrik:
                        'Amorteringar av lån till delägare eller närstående',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'LanDelagareNarstaendeForandringNedskrivningarFusion',
                    'row',
                    noteLabel('Fusion'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:LanDelagareNarstaendeForandringNedskrivningarFusion',
                      standardRubrik:
                        'Ökning (minskning) av nedskrivningar av lån till delägare eller närstående genom fusion',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:LanDelagareNarstaendeForandringNedskrivningarFusion',
                      standardRubrik:
                        'Ökning (minskning) av nedskrivningar av lån till delägare eller närstående genom fusion',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'LanDelagareNarstaendeForandringNedskrivningarAterfordaNedskrivningar',
                    'row',
                    noteLabel('Återförda nedskrivningar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:LanDelagareNarstaendeForandringNedskrivningarAterfordaNedskrivningar',
                      standardRubrik:
                        'Periodens återförda nedskrivningar av lån till delägare eller närstående',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:LanDelagareNarstaendeForandringNedskrivningarAterfordaNedskrivningar',
                      standardRubrik:
                        'Periodens återförda nedskrivningar av lån till delägare eller närstående',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'LanDelagareNarstaendeForandringNedskrivningarBortskrivnafordringar',
                    'row',
                    noteLabel('Bortskrivna fordringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:LanDelagareNarstaendeForandringNedskrivningarBortskrivnafordringar',
                      standardRubrik:
                        'Minskning av nedskrivningar i samband med bortskrivningar av lån till delägare eller närstående',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:LanDelagareNarstaendeForandringNedskrivningarBortskrivnafordringar',
                      standardRubrik:
                        'Minskning av nedskrivningar i samband med bortskrivningar av lån till delägare eller närstående',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'LanDelagareNarstaendeForandringNedskrivningarOmklassificeringar',
                    'row',
                    noteLabel('Omklassificeringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:LanDelagareNarstaendeForandringNedskrivningarOmklassificeringar',
                      standardRubrik:
                        'Minskning (ökning) av nedskrivningar i samband med omklassificeringar av lån till delägare eller närstående',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:LanDelagareNarstaendeForandringNedskrivningarOmklassificeringar',
                      standardRubrik:
                        'Minskning (ökning) av nedskrivningar i samband med omklassificeringar av lån till delägare eller närstående',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'LanDelagareNarstaendeForandringNedskrivningarAretsNedskrivningar',
                    'row',
                    noteLabel('Årets nedskrivningar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:LanDelagareNarstaendeForandringNedskrivningarAretsNedskrivningar',
                      standardRubrik:
                        'Periodens nedskrivningar av lån till delägare eller närstående',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:LanDelagareNarstaendeForandringNedskrivningarAretsNedskrivningar',
                      standardRubrik:
                        'Periodens nedskrivningar av lån till delägare eller närstående',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'LanDelagareNarstaendeForandringNedskrivningarOmrakningsdifferenser',
                    'row',
                    noteLabel('Omräkningsdifferenser'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:LanDelagareNarstaendeForandringNedskrivningarOmrakningsdifferenser',
                      standardRubrik:
                        'Minskning (ökning) av nedskrivningar i samband med omräkningsdifferenser av lån till delägare eller närstående',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:LanDelagareNarstaendeForandringNedskrivningarOmrakningsdifferenser',
                      standardRubrik:
                        'Minskning (ökning) av nedskrivningar i samband med omräkningsdifferenser av lån till delägare eller närstående',
                      negateValue: false,
                    })
                  );
                  return rows.build();
                });
              rows.addRowWithType(
                'LanDelagareNarstaendeNedskrivningarUtgaende',
                'sum',
                noteLabel('Utgående nedskrivningar'),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note59.data.LanDelagareNarstaendeAbstract.table.LanDelagareNarstaendeAbstract.LanDelagareNarstaendeNedskrivningarIngaende.year'
                      ),
                      id(
                        'notes.note59.data.LanDelagareNarstaendeAbstract.table.LanDelagareNarstaendeAbstract.LanDelagareNarstaendeForandringNedskrivningarAbstract.*.year'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans0',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:LanDelagareNarstaendeNedskrivningar',
                    standardRubrik:
                      'Ackumulerade nedskrivningar långfristiga lån till delägare eller närstående',
                    saldo: 'credit',
                    negateValue: true,
                  }
                ),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note59.data.LanDelagareNarstaendeAbstract.table.LanDelagareNarstaendeAbstract.LanDelagareNarstaendeNedskrivningarIngaende.previousYear'
                      ),
                      id(
                        'notes.note59.data.LanDelagareNarstaendeAbstract.table.LanDelagareNarstaendeAbstract.LanDelagareNarstaendeForandringNedskrivningarAbstract.*.previousYear'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans1',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:LanDelagareNarstaendeNedskrivningar',
                    standardRubrik:
                      'Ackumulerade nedskrivningar långfristiga lån till delägare eller närstående',
                    saldo: 'credit',
                    negateValue: true,
                  }
                )
              );
              return rows.build();
            });
          rows.addRowWithType(
            'LanDelagareNarstaende',
            'sum',
            noteLabel('Redovisat värde'),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note59.data.LanDelagareNarstaendeAbstract.table.LanDelagareNarstaendeAbstract.LanDelagareNarstaendeAnskaffningsvardenUtgaende.year'
                  ),
                  id(
                    'notes.note59.data.LanDelagareNarstaendeAbstract.table.LanDelagareNarstaendeAbstract.LanDelagareNarstaendeNedskrivningarUtgaende.year'
                  )
                )
              ),
              {
                contextRef: 'balans0',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:LanDelagareNarstaende',
                standardRubrik:
                  'Långfristiga lån till delägare eller närstående',
                saldo: 'debit',
                negateValue: false,
              }
            ),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note59.data.LanDelagareNarstaendeAbstract.table.LanDelagareNarstaendeAbstract.LanDelagareNarstaendeAnskaffningsvardenUtgaende.previousYear'
                  ),
                  id(
                    'notes.note59.data.LanDelagareNarstaendeAbstract.table.LanDelagareNarstaendeAbstract.LanDelagareNarstaendeNedskrivningarUtgaende.previousYear'
                  )
                )
              ),
              {
                contextRef: 'balans1',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:LanDelagareNarstaende',
                standardRubrik:
                  'Långfristiga lån till delägare eller närstående',
                saldo: 'debit',
                negateValue: false,
              }
            )
          );
          return rows.build();
        })
        .build(),
      NotLanDelagareNarstaendeKommentar: {
        type: 'group',
        active: true,
        level: 0,
        title: field(
          'Kommentar till specifikation av lån till delägare eller närstående'
        ),
        value: ixbrlField(field(''), {
          contextRef: 'period0',
          type: 'stringItemType',
          name: 'se-gen-base:NotLanDelagareNarstaendeKommentar',
          standardRubrik:
            'Kommentar till specifikation av lån till delägare eller närstående',
        }),
      },
    },
  },
  group: value(undefined),
  section: value(undefined),
  validForReportType: {
    k2: true,
    k3: true,
  },
});
export const note2059K2v2ContentDefinition = {
  type: 'part' as const,
  children: {
    active: boolean,
    number: cell,
    name: cell,
    data: {
      type: 'part' as const,
      children: {
        active: boolean,

        LanDelagareNarstaendeAbstract: {
          type: 'part' as const,
          children: {
            type: type,
            level: number,
            active: boolean,

            title: cell,

            table: { type: 'table' as const },

            NotLanDelagareNarstaendeKommentar: {
              type: 'part' as const,
              children: {
                type: type,
                level: number,
                active: boolean,

                title: cell,

                value: cell,
              },
            },
          },
        },
      },
    },
    group: cell,
    section: cell,
    validForReportType: {
      type: 'part' as const,
      children: {
        k2: boolean,
        k3: boolean,
      },
    },
  },
};

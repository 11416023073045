import React from 'react';
import {
  AnnualGeneralMeeting,
  SharesCompanyManagementReport,
  EconomicAssociationManagementReport,
  IndividualCompanyManagementReport,
  AnnualReportType,
  LimitedCompaniesManagementReport,
  CommonNotes,
  Settings,
} from '@agoy/annual-report-document';
import Page from '_shared/components/PrintedDocument/Page';
import { ManagementReportPage } from '@agoy/annual-report-view';
import PreviewSection from '../../UI/PreviewSection';

interface ManagementReportPreviewProps {
  managementReport:
    | SharesCompanyManagementReport
    | EconomicAssociationManagementReport
    | IndividualCompanyManagementReport
    | LimitedCompaniesManagementReport
    | undefined;
  notes: CommonNotes;
  transitionK3toK2: boolean;
  isDigitalSubmission: boolean | null | undefined;
  reportType: AnnualReportType;
  annualGeneralMeeting?: AnnualGeneralMeeting | undefined;
  settings: Settings;
  version: '1' | '2';
}

const ManagementReportPreview = ({
  annualGeneralMeeting,
  managementReport,
  notes,
  transitionK3toK2,
  isDigitalSubmission,
  reportType,
  settings,
  version,
}: ManagementReportPreviewProps): JSX.Element | null => {
  if (managementReport == null) {
    return null;
  }

  return (
    <Page>
      <PreviewSection id="managementReport">
        <ManagementReportPage
          annualGeneralMeeting={annualGeneralMeeting}
          managementReport={managementReport}
          notes={notes}
          transitionK3toK2={transitionK3toK2}
          isDigitalSubmission={isDigitalSubmission}
          reportType={reportType}
          settings={settings}
          version={version}
        />
      </PreviewSection>
    </Page>
  );
};

export default ManagementReportPreview;

import {
  id,
  sum,
  or,
  ref,
  value,
  refs,
  ReferenceAccountInformation,
  TimePeriod,
} from '@agoy/document';
import { BalanceSheet } from '../../common';
import { addBalanceSheetGroup, addPost } from '../../common/utils/accountsUtil';
import {
  table,
  RowsBuilder,
  getLinkToNote,
  SectionRow,
} from '../../common/utils/util';
import { getWarningCell } from '../settings/foundationSettings';

type BalanceSheetSectionReferences =
  | 'intangibleAssets'
  | 'tangibleFixedAssets'
  | 'financialAssets'
  | 'inventory'
  | 'shortTermReceivables'
  | 'cashAndBankBalances'
  | 'shortTermInvestments'
  | 'restrictedEquity'
  | 'allocations'
  | 'longTermLiabilities'
  | 'shortTermLiabilities'
  | 'unassignedAccounts';

export type FoundationBalanceSheetStructure = {
  [key in BalanceSheetSectionReferences]: SectionRow[];
};

export const foundationBalanceSheetAccounts: FoundationBalanceSheetStructure = {
  intangibleAssets: [
    {
      row: '1',
      name: 'Koncessioner, patent, licenser, varumärken samt liknande rättigheter',
      accounts: [[1000, 1059]],
      noteNumber: 6,
    },
    {
      row: '2',
      name: 'Hyresrätter och liknande rättigheter',
      accounts: [[1060, 1069]],
      noteNumber: 7,
    },
    {
      row: '3',
      name: 'Goodwill',
      accounts: [[1070, 1079]],
      noteNumber: 8,
    },
    {
      row: '4',
      name: 'Förskott avseende immateriella anläggningstillgångar',
      accounts: [[1080, 1099]],
      noteNumber: 9,
    },
  ],
  tangibleFixedAssets: [
    {
      row: '1',
      name: 'Byggnader och mark',
      accounts: [
        [1100, 1119],
        [1130, 1199],
      ],
      noteNumber: 10,
    },
    {
      row: '2',
      name: 'Maskiner och andra tekniska anläggningar',
      accounts: [[1200, 1219]],
      noteNumber: 11,
    },
    {
      row: '3',
      name: 'Inventarier, verktyg och installationer',
      accounts: [[1220, 1279]],
      noteNumber: 12,
    },
    {
      row: '4',
      name: 'Förbättringsutgifter på annans fastighet',
      accounts: [[1120, 1129]],
      noteNumber: 13,
    },
    {
      row: '5',
      name: 'Övriga materiella anläggningstillgångar',
      accounts: [[1290, 1299]],
      noteNumber: 14,
    },
    {
      row: '6',
      name: 'Pågående nyanläggningar och förskott avseende materiella anläggningstillgångar',
      accounts: [[1280, 1289]],
      noteNumber: 15,
    },
  ],
  financialAssets: [
    {
      row: '1',
      name: 'Andelar i koncernföretag',
      accounts: [[1300, 1319]],
      noteNumber: 16,
    },
    {
      row: '2',
      name: 'Fordringar hos koncernföretag',
      accounts: [[1320, 1329]],
      noteNumber: 17,
    },
    {
      row: '3',
      name: 'Andelar i intresseföretag och gemensamt styrda företag',
      accounts: [[1330, 1339]],
      noteNumber: 18,
    },
    {
      row: '4',
      name: 'Fordringar hos intresseföretag och gemensamt styrda företag',
      accounts: [
        [1340, 1345],
        [1347, 1349],
      ],
      noteNumber: 19,
    },
    {
      row: '5',
      name: 'Ägarintressen i övriga företag',
      accounts: [[1350, 1355]],
      noteNumber: 20,
    },
    {
      row: '6',
      name: 'Fordringar hos övriga företag som det finns ett ägarintresse i',
      accounts: [[1346, 1346]],
      noteNumber: 21,
    },
    {
      row: '7',
      name: 'Andra långfristiga värdepappersinnehav',
      accounts: [[1356, 1359]],
      noteNumber: 22,
    },
    {
      row: '8',
      name: 'Lån till delägare eller närstående',
      accounts: [[1360, 1379]],
      noteNumber: 23,
    },
    {
      row: '9',
      name: 'Andra långfristiga fordringar',
      accounts: [[1380, 1399]],
      noteNumber: 24,
    },
  ],
  inventory: [
    {
      row: '1',
      name: 'Råvaror och förnödenheter',
      accounts: [[1400, 1439]],
    },
    {
      row: '2',
      name: 'Varor under tillverkning',
      accounts: [[1440, 1449]],
    },
    {
      row: '3',
      name: 'Färdiga varor och handelsvaror',
      accounts: [[1450, 1469]],
    },
    {
      row: '4',
      name: 'Pågående arbete för annans räkning',
      accounts: [[1470, 1479]],
      noteNumber: 25,
    },
    {
      row: '5',
      name: 'Förskott till leverantörer',
      accounts: [[1480, 1489]],
    },
    {
      row: '6',
      name: 'Övriga lagertillgångar',
      accounts: [[1490, 1499]],
    },
  ],
  shortTermReceivables: [
    {
      row: '1',
      name: 'Kundfordringar',
      accounts: [[1500, 1599]],
    },
    {
      row: '2',
      name: 'Fordringar hos koncernföretag',
      accounts: [[1660, 1669]],
    },
    {
      row: '3',
      name: 'Fordringar hos intresseföretag och gemensamt styrda företag',
      accounts: [[1670, 1672]],
    },
    {
      row: '4',
      name: 'Fordringar hos övriga företag som det finns ett ägarintresse i',
      accounts: [[1673, 1679]],
    },
    {
      row: '5',
      name: 'Övriga fordringar',
      accounts: [
        [1600, 1619],
        [1630, 1639],
        [1640, 1659],
        [1680, 1689],
        [1690, 1699],
      ],
    },
    {
      row: '6',
      name: 'Upparbetad men ej fakturerad intäkt',
      accounts: [[1620, 1629]],
    },
    {
      row: '7',
      name: 'Förutbetalda kostnader och upplupna intäkter',
      accounts: [[1700, 1799]],
    },
  ],
  shortTermInvestments: [
    {
      row: '1',
      name: 'Andelar i koncernföretag',
      accounts: [[1860, 1860]],
    },
    {
      row: '2',
      name: 'Övriga kortfristiga placeringar',
      accounts: [
        [1800, 1859],
        [1861, 1899],
      ],
    },
  ],
  cashAndBankBalances: [
    {
      row: '1',
      name: 'Kassa och bank',
      accounts: [[1900, 1989]],
    },
    {
      row: '2',
      name: 'Redovisningsmedel',
      accounts: [[1990, 1999]],
    },
  ],
  restrictedEquity: [
    {
      row: '1_HideInPreviewAndPrint',
      name: 'Eget kapital vid räkenskapsårets början',
      accounts: [[2068, 2068]],
      modifier: 'ib',
    },
    {
      row: '2',
      name: 'Inbetalda medel',
      accounts: [[2070, 2075]],
      modifier: 'change',
    },
    {
      row: '3',
      name: 'Lämnade och återförda bidrag',
      accounts: [[2076, 2079]],
      modifier: 'change',
    },
    {
      row: '4',
      name: 'Årets resultat',
      accounts: [[2069, 2069]],
      modifier: 'change',
    },
  ],
  allocations: [
    {
      row: '1',
      name: 'Avsättningar för pensioner och liknande förpliktelser enligt lagen om tryggande av pensionsutfästelse m.m.',
      accounts: [[2200, 2219]],
    },
    {
      row: '2',
      name: 'Övriga avsättningar för pensioner och liknande förpliktelser',
      accounts: [[2230, 2239]],
    },
    {
      row: '3',
      name: 'Övriga avsättningar',
      accounts: [[2220, 2229]],
    },
  ],
  longTermLiabilities: [
    {
      row: '1',
      name: 'Mottagna ej använda bidrag',
      accounts: [],
      noteNumber: 27,
    },
    {
      row: '2',
      name: 'Beviljade ej utbetalade bidrag',
      accounts: [],
      noteNumber: 27,
    },
    {
      row: '3',
      name: 'Obligationslån',
      accounts: [[2310, 2319]],
      noteNumber: 27,
    },
    {
      row: '4',
      name: 'Checkräkningskredit',
      accounts: [[2330, 2339]],
      noteNumber: 30,
    },
    {
      row: '5',
      name: 'Övriga skulder till kreditinstitut',
      accounts: [[2350, 2359]],
      noteNumber: 27,
    },
    {
      row: '6',
      name: 'Skulder till koncernföretag',
      accounts: [[2360, 2369]],
      noteNumber: 27,
    },
    {
      row: '7',
      name: 'Skulder till intresseföretag och gemensamt styrda företag',
      accounts: [[2370, 2372]],
      noteNumber: 27,
    },
    {
      row: '8',
      name: 'Skulder till övriga företag som det finns ett ägarintresse i',
      accounts: [[2373, 2373]],
      noteNumber: 27,
    },
    {
      row: '9',
      name: 'Övriga skulder',
      accounts: [[2390, 2397]],
      noteNumber: 27,
    },
  ],
  shortTermLiabilities: [
    {
      row: '1',
      name: 'Mottagna ej använda bidrag',
      accounts: [[2398, 2398]],
    },
    {
      row: '2',
      name: 'Beviljade ej utbetalade bidrag',
      accounts: [[2399, 2399]],
    },
    {
      row: '3',
      name: 'Checkräkningskredit',
      accounts: [[2480, 2489]],
    },
    {
      row: '4',
      name: 'Övriga skulder till kreditinstitut',
      accounts: [[2400, 2419]],
    },
    {
      row: '5',
      name: 'Förskott från kunder',
      accounts: [[2420, 2429]],
    },
    {
      row: '6',
      name: 'Pågående arbete för annans räkning',
      accounts: [[2430, 2439]],
      noteNumber: 25,
    },
    {
      row: '7',
      name: 'Fakturerad men ej upparbetad intäkt',
      accounts: [[2450, 2459]],
    },
    {
      row: '8',
      name: 'Leverantörsskulder',
      accounts: [
        [2440, 2449],
        [2460, 2479],
      ],
    },
    {
      row: '9',
      name: 'Växelskulder',
      accounts: [[2490, 2499]],
    },
    {
      row: '10',
      name: 'Skulder till koncernföretag',
      accounts: [[2860, 2869]],
    },
    {
      row: '11',
      name: 'Skulder till intresseföretag och gemensamt styrda företag',
      accounts: [[2870, 2872]],
    },
    {
      row: '12',
      name: 'Skulder till övriga företag som det finns ett ägarintresse i',
      accounts: [[2873, 2879]],
    },
    {
      row: '13',
      name: 'Skatteskulder',
      accounts: [[2500, 2599]],
    },
    {
      row: '14',
      name: 'Övriga skulder',
      accounts: [
        [2600, 2799],
        [2890, 2899],
      ],
    },
    {
      row: '15',
      name: 'Upplupna kostnader och förutbetalda intäkter',
      accounts: [[2900, 2999]],
      noteNumber: 55,
    },
  ],
  unassignedAccounts: [
    {
      row: '1',
      name: 'Ej tilldelade konton',
      accounts: [
        [2000, 2067],
        [2080, 2199],
        [2240, 2309],
        [2320, 2329],
        [2340, 2349],
        [2374, 2389],
        [2800, 2859],
        [2880, 2889],
      ],
    },
  ],
};

const attachRows = (
  accounts: Record<string, ReferenceAccountInformation>,
  switchSign: boolean,
  rows: RowsBuilder,
  previousPeriod: string,
  structureKey: keyof FoundationBalanceSheetStructure
) => {
  const add = addPost(accounts, switchSign, rows, previousPeriod);
  foundationBalanceSheetAccounts[structureKey].map((section) => {
    const link = getLinkToNote(section, accounts);
    add(section.row, section.name, section.accounts, link, section.modifier);
  });
};

const addIntangibleAssets = (
  accounts: Record<string, ReferenceAccountInformation>,
  rows: RowsBuilder,
  previousPeriod: string
): void => {
  addBalanceSheetGroup(
    rows,
    'intangibleAssets',
    'Immateriella anläggningstillgångar',
    (rows) =>
      attachRows(accounts, false, rows, previousPeriod, 'intangibleAssets'),
    getWarningCell,
    'Summa Immateriella anläggningstillgångar'
  );
};

const addTangibleFixedAssets = (
  accounts: Record<string, ReferenceAccountInformation>,
  rows: RowsBuilder,
  previousPeriod: string
): void => {
  addBalanceSheetGroup(
    rows,
    'tangibleFixedAssets',
    'Materiella anläggningstillgångar',
    (rows) =>
      attachRows(accounts, false, rows, previousPeriod, 'tangibleFixedAssets'),
    getWarningCell,
    'Summa materiella anläggningstillgångar'
  );
};

const addFinancialAssets = (
  accounts: Record<string, ReferenceAccountInformation>,
  rows: RowsBuilder,
  previousPeriod: string
): void => {
  addBalanceSheetGroup(
    rows,
    'financialAssets',
    'Finansiella anläggningstillgångar',
    (rows) =>
      attachRows(accounts, false, rows, previousPeriod, 'financialAssets'),
    getWarningCell,
    'Summa finansiella anläggningstillgångar'
  );
};

const addInventory = (
  accounts: Record<string, ReferenceAccountInformation>,
  rows: RowsBuilder,
  previousPeriod: string
): void => {
  addBalanceSheetGroup(
    rows,
    'inventory',
    'Varulager m.m.',
    (rows) => attachRows(accounts, false, rows, previousPeriod, 'inventory'),
    getWarningCell,
    'Summa Varulager m.m.'
  );
};

const addShortTermReceivables = (
  accounts: Record<string, ReferenceAccountInformation>,
  rows: RowsBuilder,
  previousPeriod: string
): void => {
  addBalanceSheetGroup(
    rows,
    'shortTermReceivables',
    'Kortfristiga fordringar',
    (rows) =>
      attachRows(accounts, false, rows, previousPeriod, 'shortTermReceivables'),
    getWarningCell,
    'Summa kortfristiga fordringar'
  );
};

const addShortTermInvestments = (
  accounts: Record<string, ReferenceAccountInformation>,
  rows: RowsBuilder,
  previousPeriod: string
): void => {
  addBalanceSheetGroup(
    rows,
    'shortTermInvestments',
    'Kortfristiga placeringar',
    (rows) =>
      attachRows(accounts, false, rows, previousPeriod, 'shortTermInvestments'),
    getWarningCell,
    'Summa kortfristiga placeringar'
  );
};

const addCashAndBankBalances = (
  accounts: Record<string, ReferenceAccountInformation>,
  rows: RowsBuilder,
  previousPeriod: string
): void => {
  addBalanceSheetGroup(
    rows,
    'cashAndBankBalances',
    'Kassa och bank',
    (rows) =>
      attachRows(accounts, false, rows, previousPeriod, 'cashAndBankBalances'),
    getWarningCell,
    'Summa kassa och bank'
  );
};

const addRestrictedEquity = (
  accounts: Record<string, ReferenceAccountInformation>,
  rows: RowsBuilder,
  previousPeriod: string
) => {
  addBalanceSheetGroup(
    rows,
    'restrictedEquity',
    'Eget kapital vid räkenskapsårets början',
    (rows) =>
      attachRows(accounts, true, rows, previousPeriod, 'restrictedEquity'),
    getWarningCell,
    'Eget kapital vid räkenskapsårets slut',
    undefined,
    true
  );
};

const addAllocations = (
  accounts: Record<string, ReferenceAccountInformation>,
  rows: RowsBuilder,
  previousPeriod: string
) => {
  addBalanceSheetGroup(
    rows,
    'allocations',
    'Avsättningar',
    (rows) => attachRows(accounts, true, rows, previousPeriod, 'allocations'),
    getWarningCell,
    'Summa avsättningar'
  );
};

const addLongTermLiabilities = (
  accounts: Record<string, ReferenceAccountInformation>,
  rows: RowsBuilder,
  previousPeriod: string
) => {
  addBalanceSheetGroup(
    rows,
    'longTermLiabilities',
    'Långfristiga skulder',
    (rows) =>
      attachRows(accounts, true, rows, previousPeriod, 'longTermLiabilities'),
    getWarningCell,
    'Summa långfristiga skulder'
  );
};

const addShortTermLiabilities = (
  accounts: Record<string, ReferenceAccountInformation>,
  rows: RowsBuilder,
  previousPeriod: string
) => {
  addBalanceSheetGroup(
    rows,
    'shortTermLiabilities',
    'Kortfristiga skulder',
    (rows) =>
      attachRows(accounts, true, rows, previousPeriod, 'shortTermLiabilities'),
    getWarningCell,
    'Summa kortfristiga skulder'
  );
};

const addUnassignedAccounts = (
  accounts: Record<string, ReferenceAccountInformation>,
  rows: RowsBuilder,
  previousPeriod: string
) => {
  addBalanceSheetGroup(
    rows,
    'unassignedAccounts',
    'Ej tilldelade',
    (rows) =>
      attachRows(accounts, true, rows, previousPeriod, 'unassignedAccounts'),
    getWarningCell,
    'Ej tilldelade total'
  );
};

export const balanceSheetConfig = (
  accounts: Record<string, ReferenceAccountInformation>,
  period: TimePeriod,
  previousPeriod: TimePeriod | null,
  previousYearKey: string
): BalanceSheet => ({
  active: undefined,
  section: {
    active: undefined,
    assets: table(
      'balanceSheet.section.assets',
      'label',
      'notes',
      { id: 'year', label: period.endDateISO },
      previousPeriod
        ? {
            id: 'previousYear',
            label: previousPeriod.endDateISO,
          }
        : undefined,
      'hidden',
      'yearWarning'
    )
      .addRows((rows) =>
        rows
          .addRow('assets', value('Tillgångar'))
          .addSubRows((rows) => {
            rows
              .addRow('fixedAssets', value('Anläggningstillgångar'))
              .addSubRows((rows) => {
                // Immateriella anläggningstillgångar
                addIntangibleAssets(accounts, rows, previousYearKey);
                // Materiella anläggningstillgångar
                addTangibleFixedAssets(accounts, rows, previousYearKey);
                // Finansiella anläggningstillgångar
                addFinancialAssets(accounts, rows, previousYearKey);
                return rows.build();
              })
              .addRow(
                'sumFixedAssets',
                value('Summa anläggningstillgångar'),
                refs(),
                ref(
                  sum(
                    id(
                      `${rows.getBaseId()}.fixedAssets.intangibleAssetsSum.year`
                    ),
                    id(
                      `${rows.getBaseId()}.fixedAssets.tangibleFixedAssetsSum.year`
                    ),
                    id(
                      `${rows.getBaseId()}.fixedAssets.financialAssetsSum.year`
                    )
                  )
                ),
                ref(
                  sum(
                    id(
                      `${rows.getBaseId()}.fixedAssets.intangibleAssetsSum.previousYear`
                    ),
                    id(
                      `${rows.getBaseId()}.fixedAssets.tangibleFixedAssetsSum.previousYear`
                    ),
                    id(
                      `${rows.getBaseId()}.fixedAssets.financialAssetsSum.previousYear`
                    )
                  )
                )
              );

            rows
              .addRow('currentAssets', value('Omsättningstillgångar'))
              .addSubRows((rows) => {
                // Varulager m.m.
                addInventory(accounts, rows, previousYearKey);
                // Kortfristiga fordringar
                addShortTermReceivables(accounts, rows, previousYearKey);
                // Kortfristiga placeringar
                addShortTermInvestments(accounts, rows, previousYearKey);
                // Kassa och bank
                addCashAndBankBalances(accounts, rows, previousYearKey);

                return rows.build();
              })
              .addRow(
                'sumCurrentAssets',
                value('Summa omsättningstillgångar'),
                refs(),
                ref(
                  sum(
                    id(`${rows.getBaseId()}.currentAssets.inventorySum.year`),
                    id(
                      `${rows.getBaseId()}.currentAssets.shortTermReceivablesSum.year`
                    ),
                    id(
                      `${rows.getBaseId()}.currentAssets.shortTermInvestmentsSum.year`
                    ),
                    id(
                      `${rows.getBaseId()}.currentAssets.cashAndBankBalancesSum.year`
                    )
                  )
                ),
                ref(
                  sum(
                    id(
                      `${rows.getBaseId()}.currentAssets.inventorySum.previousYear`
                    ),
                    id(
                      `${rows.getBaseId()}.currentAssets.shortTermReceivablesSum.previousYear`
                    ),
                    id(
                      `${rows.getBaseId()}.currentAssets.shortTermInvestmentsSum.previousYear`
                    ),
                    id(
                      `${rows.getBaseId()}.currentAssets.cashAndBankBalancesSum.previousYear`
                    )
                  )
                )
              );

            return rows.build();
          })
          .addRow(
            'sumAssets',
            value('Summa tillgångar'),
            refs(),
            ref(
              or(
                sum(
                  id(`${rows.getBaseId()}.assets.fixedAssets.*Sum.year`),
                  id(`${rows.getBaseId()}.assets.currentAssets.*Sum.year`)
                ),
                0
              )
            ),
            ref(
              or(
                sum(
                  id(
                    `${rows.getBaseId()}.assets.fixedAssets.*Sum.previousYear`
                  ),
                  id(
                    `${rows.getBaseId()}.assets.currentAssets.*Sum.previousYear`
                  )
                ),
                0
              )
            )
          )
          .build()
      )
      .build(),
    equityAndLiabilities: table(
      'balanceSheet.section.equityAndLiabilities',
      'label',
      'notes',
      { id: 'year', label: period.endDateISO },
      previousPeriod
        ? {
            id: 'previousYear',
            label: previousPeriod.endDateISO,
          }
        : undefined,
      'hidden',
      'yearWarning'
    )
      .addRows((rows) =>
        rows
          .addRow('equityAndLiabilities', value('Eget kapital och skulder'))
          .addSubRows((rows) => {
            rows
              .addRow('equity', value('Eget kapital'))
              .addSubRows((rows) => {
                // Eget kapital vid räkenskapsårets början
                addRestrictedEquity(accounts, rows, previousYearKey);
                return rows.build();
              })

              .addRow(
                'sumEquity',
                value('Summa eget kapital'),
                refs(),
                ref(
                  sum(id(`${rows.getBaseId()}.equity.restrictedEquitySum.year`))
                ),
                ref(
                  sum(
                    id(
                      `${rows.getBaseId()}.equity.restrictedEquitySum.previousYear`
                    )
                  )
                )
              );

            // Avsättningar
            rows.addRow('allocations').addSubRows((rows) => {
              addAllocations(accounts, rows, previousYearKey);
              return rows.build();
            });

            // Långfristiga skulder
            rows.addRow('longTermLiabilities').addSubRows((rows) => {
              addLongTermLiabilities(accounts, rows, previousYearKey);
              return rows.build();
            });

            // Kortfristiga skulder
            rows.addRow('shortTermLiabilities').addSubRows((rows) => {
              addShortTermLiabilities(accounts, rows, previousYearKey);
              return rows.build();
            });

            // Ej tilldelade AKA unassigned accounts
            rows.addRow('unassignedAccounts').addSubRows((rows) => {
              addUnassignedAccounts(accounts, rows, previousYearKey);
              return rows.build();
            });

            return rows.build();
          })

          .addRow(
            'sumEquityAndLiabilities',
            value('Summa eget kapital och skulder'),
            refs(),
            ref(
              sum(
                id(`${rows.getBaseId()}.equityAndLiabilities.equity.*Sum.year`),
                id(
                  `${rows.getBaseId()}.equityAndLiabilities.allocations.*Sum.year`
                ),
                id(
                  `${rows.getBaseId()}.equityAndLiabilities.longTermLiabilities.*Sum.year`
                ),
                id(
                  `${rows.getBaseId()}.equityAndLiabilities.shortTermLiabilities.*Sum.year`
                )
              )
            ),
            ref(
              sum(
                id(
                  `${rows.getBaseId()}.equityAndLiabilities.equity.*Sum.previousYear`
                ),
                id(
                  `${rows.getBaseId()}.equityAndLiabilities.allocations.*Sum.previousYear`
                ),
                id(
                  `${rows.getBaseId()}.equityAndLiabilities.longTermLiabilities.*Sum.previousYear`
                ),
                id(
                  `${rows.getBaseId()}.equityAndLiabilities.shortTermLiabilities.*Sum.previousYear`
                )
              )
            )
          )
          .build()
      )
      .build(),
  },
});

export const balanceSheetReferences = (): Record<string, string> => ({
  'balanceSheet.sumEquity.year': id(
    'balanceSheet.section.equityAndLiabilities.equityAndLiabilities.sumEquity.year'
  ),
  'balanceSheet.sumEquity.previousYear': id(
    'balanceSheet.section.equityAndLiabilities.equityAndLiabilities.sumEquity.previousYear'
  ),
  'balanceSheet.sumEquityAndLiabilities.year': id(
    'balanceSheet.section.equityAndLiabilities.sumEquityAndLiabilities.year'
  ),
  'balanceSheet.sumEquityAndLiabilities.previousYear': id(
    'balanceSheet.section.equityAndLiabilities.sumEquityAndLiabilities.previousYear'
  ),
  'balanceSheet.sumAssets.year': id(
    'balanceSheet.section.assets.sumAssets.year'
  ),
  'balanceSheet.sumAssets.previousYear': id(
    'balanceSheet.section.assets.sumAssets.previousYear'
  ),
});

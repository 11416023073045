import {
  ReferenceCell,
  ref,
  value,
  refs,
  or,
  id,
  sum,
  account,
  multiply,
  div,
  label,
  msg,
} from '@agoy/document';
import { AnnualReportType, ConfiguredFinancialYear } from '../../document';
import { EconomicAssociationManagementReport } from './types';

import { companyTaxPerYear } from '../../common/taxPercentagePerYear';
import { active, field, table } from '../../common/utils/util';

const addManagementReportSumCell = (baseId: string): ReferenceCell =>
  ref(
    or(
      sum(
        or(id(`${baseId}.memberContributions`), 0),
        or(id(`${baseId}.publishingEfforts`), 0),
        or(id(`${baseId}.appreciationFund`), 0),
        or(id(`${baseId}.reserveFund`), 0),
        or(id(`${baseId}.yearResult`), 0),
        or(id(`${baseId}.balancedResult`), 0),
        or(id(`${baseId}.@*`), 0)
      ),
      0
    )
  );

const generateChangesInEquityTableManualSubmission = (
  reportType: AnnualReportType,
  transitionK3toK2: boolean
) => {
  return table(
    'managementReport.changesInEquity.table',
    { id: 'label', label: '' },
    'note',
    'memberContributions',
    'publishingEfforts',
    'appreciationFund',
    'reserveFund',
    'balancedResult',
    'yearResult',
    'totalChangesInEquity'
  )
    .newColumnTemplate((column) =>
      column
        .setType('number')
        .addCell('managementReport.changesInEquity.table.main.sumByYearEnd', {
          type: 'ref',
          reference: or(
            sum(
              id(
                `managementReport.changesInEquity.table.main.content.*.$columnId`
              ),
              transitionK3toK2
                ? id(
                    `managementReport.changesInEquity.table.transitionToK2.*.$columnId`
                  )
                : 0
            ),
            0
          ),
          value: 0,
        })
        .build()
    )
    .addRows((rows) =>
      rows
        // Rows for transition to K2
        .addRow('transitionToK2')
        .addSubRows((rows) =>
          rows
            .addRowActive(false)
            .addRow(
              'amountPrevYear',
              value(
                'Belopp vid jämförelseårets ingång enligt fastställd balansräkning'
              ),
              refs(),
              value(0),
              value(0),
              value(0),
              value(0),
              value(0),
              value(0),
              addManagementReportSumCell(
                'managementReport.changesInEquity.table.transitionToK2.amountPrevYear'
              )
            )
            .addRow(
              'adjustmentsBFNAR',
              value('Justeringar vid övergång till BFNAR 2012:1 (K2)'),
              refs(),
              value(0),
              value(0),
              value(0),
              value(0),
              value(0),
              value(0),
              addManagementReportSumCell(
                'managementReport.changesInEquity.table.transitionToK2.adjustmentsBFNAR'
              )
            )
            .addRow(
              '3',
              value('Egenupparbetad immateriell anläggningstillgång'),
              refs(),
              value(0),
              value(0),
              value(0),
              value(0),
              value(0),
              value(0),
              addManagementReportSumCell(
                'managementReport.changesInEquity.table.transitionToK2.3'
              )
            )
            .addRow(
              '4',
              value('Eget kapital föregående år'),
              refs(),
              value(0),
              value(0),
              value(0),
              value(0),
              value(0),
              value(0),
              addManagementReportSumCell(
                'managementReport.changesInEquity.table.transitionToK2.4'
              )
            )
            .addRow(
              '5',
              value(
                'Finansiell anläggningstillgång värderad till verkligt värde'
              ),
              refs(),
              value(0),
              value(0),
              value(0),
              value(0),
              value(0),
              value(0),
              addManagementReportSumCell(
                'managementReport.changesInEquity.table.transitionToK2.5'
              )
            )
            .addRow(
              '6',
              value('Justeringar vid övergång till BFNAR 2016:10 (K2)'),
              refs(),
              value(0),
              value(0),
              value(0),
              value(0),
              value(0),
              value(0),
              addManagementReportSumCell(
                'managementReport.changesInEquity.table.transitionToK2.6'
              )
            )
            .addRow(
              '7',
              value('Leasingavtal'),
              refs(),
              value(0),
              value(0),
              value(0),
              value(0),
              value(0),
              value(0),
              addManagementReportSumCell(
                'managementReport.changesInEquity.table.transitionToK2.7'
              )
            )
            .addRow(
              '8',
              value('Pensionsavsättningar'),
              refs(),
              value(0),
              value(0),
              value(0),
              value(0),
              value(0),
              value(0),
              addManagementReportSumCell(
                'managementReport.changesInEquity.table.transitionToK2.8'
              )
            )
            .addRow(
              '9',
              value('Uppskjuten skattefordran'),
              refs(),
              value(0),
              value(0),
              value(0),
              value(0),
              value(0),
              value(0),
              addManagementReportSumCell(
                'managementReport.changesInEquity.table.transitionToK2.9'
              )
            )
            .addRow(
              '10',
              value('Uppskjuten skatteskuld'),
              refs(),
              value(0),
              value(0),
              value(0),
              value(0),
              value(0),
              value(0),
              addManagementReportSumCell(
                'managementReport.changesInEquity.table.transitionToK2.10'
              )
            )
            .addRow(
              '11',
              value('Uppskriven anläggningstillgång'),
              refs(),
              value(0),
              value(0),
              value(0),
              value(0),
              value(0),
              value(0),
              addManagementReportSumCell(
                'managementReport.changesInEquity.table.transitionToK2.11'
              )
            )
            .addRow(
              '12',
              value(
                'Finansiella instrument värderade till verkligt värde enligt 4 kap. 14 a § årsredovisningslagen (1995:1554)'
              ),
              refs(),
              value(0),
              value(0),
              value(0),
              value(0),
              value(0),
              value(0),
              addManagementReportSumCell(
                'managementReport.changesInEquity.table.transitionToK2.12'
              )
            )
            .build()
        )
        .newRowTemplate(
          value(''),
          refs(),
          value(0),
          value(0),
          value(0),
          value(0),
          value(0),
          value(0),
          addManagementReportSumCell('$id')
        )

        // These rows are displayed for both K2 and K3 report types
        .addRow('main')
        .addSubRows((rows) =>
          rows
            .addRow('content')
            .addSubRows((rows) =>
              rows
                .addRowManagementReport({
                  id: 'incomingAmount',
                  cells: [
                    // 4701 TODO: these acc ranges need to be reviewed, we have 2 new columns now
                    value('Belopp vid årets ingång'),
                    refs(),
                    // Value from Medlemsinsatser in balanceSheet
                    ref(multiply(-1, account('2083', 'ib'))),
                    // Value from Medlemsinsatser in balanceSheet
                    ref(or(multiply(-1, account('2084', 'ib')), 0)),
                    // Value from Uppskrivningsfond in balanceSheet
                    ref(or(multiply(-1, account('2085', 'ib')), 0)),
                    // Value from Reservfond in balanceSheet
                    ref(or(multiply(-1, account('2086', 'ib')), 0)),
                    // Value from Balanserat Resultat in balanceSheet
                    ref(or(multiply(-1, account('2090:2098', 'ib')), 0)),
                    // Value from Årets resultat in balanceSheet
                    ref(or(multiply(-1, account('2099', 'ib')), 0)),
                    addManagementReportSumCell(
                      'managementReport.changesInEquity.table.main.content.incomingAmount'
                    ),
                  ],
                  isRowActive: true,
                })
                .addRowManagementReport({
                  id: 'despositionOfResults',
                  cells: [
                    value('Resultatdisposition enligt föreningsstämman'),
                    refs(),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    ref(
                      or(
                        id(
                          'managementReport.changesInEquity.table.main.content.incomingAmount.yearResult'
                        ),
                        0
                      )
                    ),
                    ref(
                      or(
                        multiply(
                          -1,
                          id(
                            'managementReport.changesInEquity.table.main.content.incomingAmount.yearResult'
                          )
                        ),
                        0
                      )
                    ),
                    addManagementReportSumCell(
                      'managementReport.changesInEquity.table.main.content.despositionOfResults'
                    ),
                  ],
                  isRowActive: true,
                })
                .addRowManagementReport({
                  id: 'profitDistribution',
                  cells: [
                    value('Vinstutdelning'),
                    refs(),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    addManagementReportSumCell(
                      'managementReport.changesInEquity.table.main.content.profitDistribution'
                    ),
                  ],
                  isRowActive: true,
                })
                .addRowManagementReport({
                  id: 'contributionIssue',
                  cells: [
                    value('Insatsemission'),
                    refs(),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    addManagementReportSumCell(
                      'managementReport.changesInEquity.table.main.content.contributionIssue'
                    ),
                  ],
                  isRowActive: true,
                })
                .addRowManagementReport({
                  id: 'publishingEffortsDividends',
                  cells: [
                    value('Utdelning på förlagsinsatser'),
                    refs(),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    addManagementReportSumCell(
                      'managementReport.changesInEquity.table.main.content.publishingEffortsDividends'
                    ),
                  ],
                  isRowActive: true,
                })
                .addRowManagementReport({
                  id: 'depositionReservFund',
                  cells: [
                    value('Avsättning till reservfonden'),
                    refs(),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    addManagementReportSumCell(
                      'managementReport.changesInEquity.table.main.content.depositionReservFund'
                    ),
                  ],
                  isRowActive: true,
                })
                .addRowManagementReport({
                  id: 'newAccountBalance',
                  cells: [
                    value('Balanseras i ny räkning'),
                    refs(),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    addManagementReportSumCell(
                      'managementReport.changesInEquity.table.main.content.newAccountBalance'
                    ),
                  ],
                  isRowActive: true,
                })
                .addRowManagementReport({
                  id: 'assetsSale',
                  cells: [
                    value('Avskrivn. resp. försäljn. av uppskriven tillgång'),
                    refs(),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    addManagementReportSumCell(
                      'managementReport.changesInEquity.table.main.content.assetsSale'
                    ),
                  ],
                  isRowActive: true,
                })
                .addRowManagementReport({
                  id: 'changeMemberContribution',
                  cells: [
                    value('Förändring medlemsinsatser'),
                    refs(),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    addManagementReportSumCell(
                      'managementReport.changesInEquity.table.main.content.changeMemberContribution'
                    ),
                  ],
                  isRowActive: true,
                })

                .addRowManagementReport({
                  id: 'yearResult',
                  cells: [
                    value('Årets resultat'),
                    refs(),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    value(0),
                    ref(multiply(-1, account('2099'))),
                    addManagementReportSumCell(
                      'managementReport.changesInEquity.table.main.content.yearResult'
                    ),
                  ],
                  isRowActive: true,
                })
                .build()
            )
            .newRowTemplate(
              value(''),
              refs(),
              value(0),
              value(0),
              value(0),
              value(0),
              value(0),
              value(0),
              addManagementReportSumCell('$id')
            )
            .addRow(
              'sumByYearEnd',
              value('Belopp vid årets utgång'),
              refs(),
              value(0),
              value(0),
              value(0),
              value(0),
              value(0),
              value(0),
              addManagementReportSumCell(`${rows.getBaseId()}.sumByYearEnd`)
            )
            .newRowTemplate(
              value(''),
              refs(),
              ref(
                sum(
                  id(
                    `managementReport.changesInEquity.table.main.content.*.shareCapital`
                  ),
                  transitionK3toK2
                    ? id(
                        `managementReport.changesInEquity.table.transitionToK2.*.shareCapital`
                      )
                    : 0
                )
              ),
              ref(
                sum(
                  id(
                    `managementReport.changesInEquity.table.main.content.*.otherRestrictedCapital`
                  ),
                  transitionK3toK2
                    ? id(
                        `managementReport.changesInEquity.table.transitionToK2.*.otherRestrictedCapital`
                      )
                    : 0
                )
              ),
              value(0),
              value(0),
              ref(
                sum(
                  id(
                    `managementReport.changesInEquity.table.main.content.*.otherUnrestrictedCapital`
                  ),
                  transitionK3toK2
                    ? id(
                        `managementReport.changesInEquity.table.transitionToK2.*.otherUnrestrictedCapital`
                      )
                    : 0
                )
              ),
              ref(
                sum(
                  id(
                    `managementReport.changesInEquity.table.main.content.*.yearResult`
                  ),
                  transitionK3toK2
                    ? id(
                        `managementReport.changesInEquity.table.transitionToK2.*.yearResult`
                      )
                    : 0
                )
              ),
              addManagementReportSumCell('$id')
            )
            .build()
        )
        .newRowTemplate(
          value(''),
          refs(),
          value(0),
          value(0),
          value(0),
          value(0),
          value(0),
          value(0),
          value(0)
        )
        .build()
    )
    .newRowTemplate(
      value(''),
      refs(),
      value(0),
      value(0),
      value(0),
      value(0),
      value(0),
      value(0),
      value(0)
    )
    .build();
};

export const managementReportConfig = (
  reportType: AnnualReportType,
  transitionK3toK2: boolean,
  financialYears: ConfiguredFinancialYear[]
): EconomicAssociationManagementReport => {
  return {
    // Verksamheten for K2
    active: undefined,
    activities: {
      boardText: field(''),
      boardTextWithCeo: field(''),
      boardWithoutCeo: field(''),
      liquidatorText: field(''),
      currencyText: reportType === 'k2' ? field('') : field(''),
      heading: field(''),
      active: reportType === 'k2',
      activityLabel: field('Verksamheten'),
      generallyLabel: field('Allmänt om verksamheten'),
      generally: active(
        msg(
          'Föreningen har sitt säte i {city}.\n\n{activityText}\n\nFöreningens verksamhet består av\n\nFöreningen har under räkenskapsåret bytt namn till [namn].\n\nSpeciella förhållanden som i särskild grad berör föreningen',
          {
            activityText: id('managementReport.hidden.activityText'),
            city: id('managementReport.hidden.city'),
          }
        )
      ),
      accountant: active(
        field(
          '[namn, byrå], som är auktoriserad redovisnings-/lönekonsult (FAR / SRF), har anlitats för biträde med följande tjänster: bokföring, lön, bokslut och årsredovisning.'
        )
      ),
      eventsLabel: field('Väsentliga händelser under räkenskapsåret'),
      events: active(
        field(
          'Beslutade viktiga förändringar i verksamheten är\n\nFör föreningen viktiga externa faktorer som påverkat dess ställning och resultat är\n\nFöreningen förutsätts inte längre fortsätta sin verksamhet'
        )
      ),
      memberInformationLabel: field('Medlemsinformation'),
      memberInformation: active(
        field(
          'Antalet medlemmar vid räkenskapsårets början [antalet medlemmar]\n\nAntalet tillkommande medlemmar under räkenskapsåret [antalet medlemmar]\n\nAntalet avgående medlemmar under räkenskapsåret [antalet medlemmar]\n\nAntalet medlemmar vid räkenskapsåret slut [antalet medlemmar]'
        )
      ),
      publishingEffortsLabel: field('Förlagsinsatser'),
      publishingEfforts: active(field('')),
      otherLabel: field(''),
      other: field(''),
    },
    // Verksamheten for K3
    business: {
      boardText: field(''),
      businessLabel: field('Verksamheten'),
      currencyText: reportType === 'k2' ? field('') : field(''),
      heading: field(''),
      active: reportType === 'k3',
      conditionsAndEventsLabel: field(
        'Viktiga förhållanden och väsentliga händelser'
      ),
      conditionsAndEvents: active(field('')),
      businessNatureLabel: field('Verksamhetens art och inriktning'),
      businessNature: active(field('')),
      businessChangesLabel: field('Viktiga förändringar i verksamheten'),
      businessChanges: active(field('')),
      externalFactorsLabel: field(
        'Viktiga externa faktorer som påverkat företagets ställning och resultat'
      ),
      externalFactors: active(field('')),
      specialCircumstancesLabel: field(
        'Speciella omständigheter som i särskild grad berör företaget'
      ),
      specialCircumstances: active(field('')),
      mainBusinessOwnersLabel: field(
        'Uppgift om ägare som har mer än tio procent av antalet andelar eller röster i företaget'
      ),
      mainBusinessOwners: active(field('')),
      limitedPartnershipsLabel: field('Komplementär i kommanditbolag'),
      limitedPartnerships: active(field('')),
      ownershipChangesLabel: field('Väsentliga förändringar i ägarstrukturen'),
      ownershipChanges: active(field('')),
      noSurvivalLabel: field(
        'Företaget tillämpar inte längre fortlevnadsprincipen'
      ),
      noSurvival: active(field('')),
      importantConditionsAndEventsLabel: field(
        'Övriga viktiga förhållanden och väsentliga händelser'
      ),
      importantConditionsAndEvents: active(field('')),
      controlBalanceSheetLabel: field('Kontrollbalansräkning'),
      controlBalanceSheet: active(field('')),
    },
    multiYearOverview: {
      active: true,
      multiYearLabel: field('Flerårsöversikt'),
      multiYearTextLabel: field('Kommentar flerårsöversikt'),
      multiYearText: active(field('')),
      table: table(
        'managementReport.multiYearOverview.table',
        { id: 'label', label: '' },
        ...financialYears.map((year, index) => ({
          id: `period${index}`,
          label: year.label,
        }))
      )
        .addRows((rows) =>
          rows
            .addRow(
              'netto',
              label('Nettoomsättning'),
              ref(id('incomeStatement.netSales.year')),
              ref(id('incomeStatement.netSales.previousYear')),
              ...financialYears.slice(2).map((year, index) => value(undefined))
            )
            .addRowActive(true)
            .addRow(
              'result',
              label('Resultat efter finansiella poster'),
              ref(id('incomeStatement.resultAfterFinancial.year')),
              ref(id('incomeStatement.resultAfterFinancial.previousYear')),
              ...financialYears.slice(2).map((year, index) => value(undefined))
            )
            .addRow(
              'solidity',
              label('Soliditet (%)'),
              // ("Summa eget kapital" + (1 - (aktuell bolagsskatt)) * "summa obeskattade reserver")  / "Summa tillgångar"
              ref(
                or(
                  div(
                    sum(
                      or(id('balanceSheet.sumEquity.year'), 0),
                      multiply(
                        sum(
                          1,
                          multiply(
                            -1,
                            companyTaxPerYear[
                              financialYears[0].end.substring(0, 4)
                            ]
                          )
                        ),
                        or(id('balanceSheet.sumUntaxedReserves.year'), 0)
                      )
                    ),
                    id('balanceSheet.sumAssets.year')
                  ),
                  0
                )
              ),
              ref(
                or(
                  div(
                    sum(
                      or(id('balanceSheet.sumEquity.previousYear'), 0),
                      multiply(
                        sum(
                          1,
                          multiply(
                            -1,
                            companyTaxPerYear[
                              financialYears[1]?.end.substring(0, 4)
                            ]
                          )
                        ),
                        or(
                          id('balanceSheet.sumUntaxedReserves.previousYear'),
                          0
                        )
                      )
                    ),
                    id('balanceSheet.sumAssets.previousYear')
                  ),
                  0
                )
              ),
              ...financialYears.slice(2).map((year, index) => value(undefined))
            )
            .build()
        )
        .newRowTemplate(value(''), ...financialYears.map(() => value(0)))
        .build(),
    },
    changesInEquity: {
      title: field(''),
      active: true,
      commentLabel: field('Kommentar'),
      comment: active(field('')),
      table: generateChangesInEquityTableManualSubmission(
        reportType,
        transitionK3toK2
      ),
    },

    resultsDisposition: {
      title: field(''),
      active: true,
      proposeHeader: field(''),
      propose: field(''),
      toDisposeHeader: active(
        field('Till föreningsstämmans förfogande står följande vinstmedel')
      ),
      // First table of resultsDisposition
      toDispose: table(
        'managementReport.resultsDisposition.toDispose',
        'label',
        'value'
      )
        .addRows((rows) => {
          rows
            .addRowActive(true)
            .addRow(
              '1',
              value('Balanserat resultat'),
              ref(or(multiply(-1, account('2090:2096+2098')), 0))
            )
            .addRow(
              '2',
              value('Årets resultat'),
              ref(or(multiply(-1, account('2099')), 0))
            );
          return rows.build();
        })
        .newRowTemplate(value(''), value(0))
        .build(),
      customTotal: active(field('')),
      total: active(
        ref(
          or(
            sum(id('managementReport.resultsDisposition.toDispose.*.value')),
            0
          )
        )
      ),
      totalWarning: value(''),
      extraordinaryMeetingDividendHeader: field(''),
      extraordinaryMeetingDividend: table(
        'managementReport.resultsDisposition.extraordinaryMeetingDividend',
        'label',
        'value'
      )
        .addRows((rows) =>
          rows
            .addRowActive(false)
            .addRow(
              'extraordinaryMeetingDividend',
              value('Efterutdelning'),
              value(undefined)
            )
            .build()
        )
        .newRowTemplate(value(''), value(0))
        .build(),
      extraordinaryMeetingDividendTotal: {
        ...active(
          ref(
            or(
              sum(
                id('managementReport.resultsDisposition.total'),
                multiply(
                  id(
                    'managementReport.resultsDisposition.extraordinaryMeetingDividend.*.value'
                  ),
                  -1
                )
              ),
              0
            )
          )
        ),
        active: false,
      },
      // Styrelsen föreslår att medlen disponeras enligt följande:
      boardProposalHeader: active(field('')),
      // second ResultsDisposition table
      proposal: table(
        'managementReport.resultsDisposition.proposal',
        'label',
        'value'
      )
        .addRows((rows) => {
          rows
            .addRowActive(true)
            .addRow(
              'profitDistribution',
              value('Vinstutdelning (2% på medlemsinsatser)'),
              value(undefined)
            )
            .addRow(
              'contributionIssue',
              value('Insatsemission (5% på medlemsinsatser)'),
              value(undefined)
            )
            .addRow(
              'publishingEffortsDividends',
              value('Utdelning på förlagsinsatser'),
              value(undefined)
            )
            .addRow(
              'reserveFundAllocated',
              value('Till reservfonden avsätts'),
              value(undefined)
            );
          return rows.build();
        })
        .newRowTemplate(value(''), value(0))
        .build(),
      customToTransfer: active(field('')),
      toTransfer: active(
        ref(
          sum(
            multiply(
              -1,
              or(
                sum(id('managementReport.resultsDisposition.proposal.*.value')),
                0
              )
            ),
            id('managementReport.resultsDisposition.total')
          )
        )
      ),
      customInTotal: active(field('')),
      // Sum the value from the first table of ResultsDisposition
      inTotal: active(
        ref(
          or(
            sum(id('managementReport.resultsDisposition.toDispose.*.value')),
            0
          )
        )
      ),
      inTotalWarning: value(''),
    },
    profitProposal: {
      title: field(''),
      active: false,
      dividendPerShare: ref(
        or(
          div(
            id('managementReport.profitProposal.dividend'),
            id('managementReport.profitProposal.shares')
          ),
          0
        )
      ),
      dividend: ref(
        or(id('managementReport.resultsDisposition.proposal.1.value'), 0)
      ),
      shares: field(1),
      toTransfer: ref(id('managementReport.resultsDisposition.toTransfer')),
      // the value (date) is added in ProfitProposal view component
      paymentDateType: field('generalMeeting'),
      paymentDate: field(''),
      statement: field(
        'Styrelsen har gjort bedömningen att vinstutdelningen är försvarlig ' +
          'med hänsyn till dom krav som verksamhetens art, omfattning och ' +
          'risker ställer på storleken av det egna kapitalet, och bolagets ' +
          'konsolideringsbehov, likviditet och ställning i övrigt. Den ' +
          'förslagna vinstutdelningen är därför förenlig med vad som anges i ' +
          'aktiebolagslagen 17 kap. 3§ andra och tredje styckena.'
      ),
    },
    hidden: {
      activityText: ref(id('settings.clientInformation.activityText')),
      city: ref(id('settings.clientInformation.city')),
    },
  };
};

export const managementReportReferences = (): Record<string, string> => ({
  solidity: sum(
    id('managementReport.multiYearOverview.table.nettoAndResult.*.value')
  ),
});

import { AgoyTable, Field } from '@agoy/document';
import { AnnualReportPart, AnnualReportSection } from '../../document';
import { IxbrlCell, IxbrlField } from '../../common/types/ixbrl';

export interface TextSection extends AnnualReportSection {
  title: Field;
  text: Field;
}

// Verksamheten
export interface Activities extends AnnualReportSection {
  // Allmänt om verksamheten
  boardText: Field;
  boardTextWithCeo: Field;
  boardWithoutCeo: Field;
  liquidatorText: Field;
  currencyText: IxbrlField;
  heading: Field;
  activityLabel: Field;
  generallyLabel: Field;
  generally: IxbrlField;
  accountant: Field;

  // Väsentliga händelser under räkenskapsåret
  eventsLabel: Field;
  events: IxbrlField;

  // Egna aktier
  sharesLabel: Field;
  shares: IxbrlField;

  // Other
  otherLabel: Field;
  other: IxbrlField;
}

// Verksamheten K3
export interface Business extends AnnualReportSection {
  boardText: Field;
  businessLabel: Field;
  currencyText: IxbrlField;
  heading: Field;

  // Viktiga förhållanden och väsentliga händelser
  conditionsAndEventsLabel: Field;
  conditionsAndEvents: IxbrlField;

  // Verksamhetens art och inriktning
  businessNatureLabel: Field;
  businessNature: IxbrlField;

  // Viktiga förändringar i verksamheten
  businessChangesLabel: Field;
  businessChanges: IxbrlField;

  // Viktiga externa faktorer som påverkat företagets ställning och resultat
  externalFactorsLabel: Field;
  externalFactors: IxbrlField;

  // Speciella omständigheter som i särskild grad berör företaget
  specialCircumstancesLabel: Field;
  specialCircumstances: IxbrlField;

  // Uppgift om ägare som har mer än tio procent av antalet andelar eller röster i företaget
  mainBusinessOwnersLabel: Field;
  mainBusinessOwners: IxbrlField;

  // Komplementär i kommanditbolag
  limitedPartnershipsLabel: Field;
  limitedPartnerships: IxbrlField;

  // Väsentliga förändringar i ägarstrukturen
  ownershipChangesLabel: Field;
  ownershipChanges: IxbrlField;

  // Företaget tillämpar inte längre fortlevnadsprincipen
  noSurvivalLabel: Field;
  noSurvival: IxbrlField;

  // Övriga viktiga förhållanden och väsentliga händelser
  importantConditionsAndEventsLabel: Field;
  importantConditionsAndEvents: IxbrlField;

  // Kontrollbalansräkning
  controlBalanceSheetLabel: Field;
  controlBalanceSheet: IxbrlField;
}

// Resultatdisposition
export interface ResultsDisposition extends AnnualReportSection {
  title: Field;
  proposeHeader: Field;
  propose: Field;
  toDisposeHeader: Field;
  toDispose: AgoyTable<IxbrlCell>;
  customTotal: Field;
  total: IxbrlField;
  totalWarning: Field;
  extraordinaryMeetingDividendHeader: Field;
  extraordinaryMeetingDividend: AgoyTable<IxbrlCell>;
  extraordinaryMeetingDividendTotal: IxbrlField;
  boardProposalHeader: Field;
  proposal: AgoyTable<IxbrlCell>;
  customToTransfer: Field;
  toTransfer: IxbrlField;
  customInTotal: Field;
  inTotal: Field;
  inTotalWarning: Field;
}

// Flerårsöversikt
export interface MultiYearOverview extends AnnualReportSection {
  table: AgoyTable<IxbrlCell>;
  multiYearLabel: Field;
  multiYearTextLabel: Field;
  multiYearText: IxbrlField;
}

// Nyckeltalsdefinitioner
export interface DefinitionOfKey extends AnnualReportSection {
  definitionOfKeyLabel: Field;
  definitionOfKeyTable: AgoyTable;
}

// Förändringar i eget kapital
export interface ChangesInEquity extends AnnualReportSection {
  title: Field;
  table: AgoyTable;
  conditionalShareholderContributions: AgoyTable;
  commentLabel: Field;
  comment: IxbrlField;
}

// Förslag till vinstdisposition
export interface ProfitProposal extends AnnualReportSection {
  title: Field;
  dividendPerShare: IxbrlField;
  dividend: Field;
  shares: Field;
  toTransfer: Field;

  paymentDateType: IxbrlField; // 'date' | 'generalMeeting'
  paymentDate: IxbrlField;
  statement: IxbrlField;
}

// Specifikation av utdelning per aktieslag
export interface ProfitProposalK3 extends AnnualReportSection {
  title: Field;
  table: AgoyTable<IxbrlCell>;
  comment: IxbrlField;
  statement: IxbrlField;
}

// Förvaltningsberättelse
export interface SharesCompanyManagementReport extends AnnualReportPart {
  activities: Activities;

  // Verksamheten K3
  business: Business;

  // Bedömning av företagets fortlevnadsförmåga
  viability: TextSection;

  // Förväntad framtida utveckling samt väsentliga risker och osäkerhetsfaktorer
  futureDevelopment: TextSection;

  // Verskamhet inom forskning och  utveckling
  rAndD: TextSection;

  // Filialer i utlandet
  subsidiariesAbroad: TextSection;

  // Hållbarhetsupplysningar
  sustainabilityInfo: TextSection;

  // Hållbarhetsrapport
  sustainabilityReport: TextSection;

  // Tillstånds- eller anmälningspliktig verksamhet enligt miljöbalken
  permitActivities: TextSection;

  // Egna aktier
  ownShares: TextSection;

  // Användning av finansiella instrument
  useOfFinancialInstruments: TextSection;

  multiYearOverview: MultiYearOverview;
  definitionOfKey: DefinitionOfKey;
  changesInEquity: ChangesInEquity;
  resultsDisposition: ResultsDisposition;
  profitProposal: ProfitProposal;
  profitProposalK3: ProfitProposalK3;

  hidden: {
    activityText: Field;
    city: Field;
  };
}

const cell = { type: 'field' as const };
const boolean = { type: 'boolean' as const };

export const managementReport = {
  type: 'part' as const,
  children: {
    active: boolean,
    activities: {
      type: 'part' as const,
      children: {
        boardText: cell,
        boardTextWithCeo: cell,
        boardWithoutCeo: cell,
        liquidatorText: cell,
        currencyText: cell,
        heading: cell,
        active: boolean,
        accountant: cell,
        eventsLabel: cell,
        events: cell,
        activityLabel: cell,
        generallyLabel: cell,
        generally: cell,
        sharesLabel: cell,
        shares: cell,
        otherLabel: cell,
        other: cell,
      },
    },
    business: {
      type: 'part' as const,
      children: {
        boardText: cell,
        businessLabel: cell,
        currencyText: cell,
        heading: cell,
        active: boolean,
        conditionsAndEventsLabel: cell,
        conditionsAndEvents: cell,
        businessNatureLabel: cell,
        businessNature: cell,
        businessChangesLabel: cell,
        businessChanges: cell,
        externalFactorsLabel: cell,
        externalFactors: cell,
        specialCircumstancesLabel: cell,
        specialCircumstances: cell,
        mainBusinessOwnersLabel: cell,
        mainBusinessOwners: cell,
        limitedPartnershipsLabel: cell,
        limitedPartnerships: cell,
        ownershipChangesLabel: cell,
        ownershipChanges: cell,
        noSurvivalLabel: cell,
        noSurvival: cell,
        importantConditionsAndEventsLabel: cell,
        importantConditionsAndEvents: cell,
        controlBalanceSheetLabel: cell,
        controlBalanceSheet: cell,
      },
    },
    viability: {
      type: 'part' as const,
      children: {
        active: boolean,
        text: cell,
        title: cell,
      },
    },
    futureDevelopment: {
      type: 'part' as const,
      children: {
        active: boolean,
        text: cell,
        title: cell,
      },
    },
    rAndD: {
      type: 'part' as const,
      children: {
        active: boolean,
        text: cell,
        title: cell,
      },
    },
    subsidiariesAbroad: {
      type: 'part' as const,
      children: {
        active: boolean,
        text: cell,
        title: cell,
      },
    },
    sustainabilityInfo: {
      type: 'part' as const,
      children: {
        active: boolean,
        text: cell,
        title: cell,
      },
    },
    sustainabilityReport: {
      type: 'part' as const,
      children: {
        active: boolean,
        text: cell,
        title: cell,
      },
    },
    permitActivities: {
      type: 'part' as const,
      children: {
        active: boolean,
        text: cell,
        title: cell,
      },
    },
    ownShares: {
      type: 'part' as const,
      children: {
        active: boolean,
        text: cell,
        title: cell,
      },
    },
    useOfFinancialInstruments: {
      type: 'part' as const,
      children: {
        active: boolean,
        text: cell,
        title: cell,
      },
    },
    multiYearOverview: {
      type: 'part' as const,
      children: {
        active: boolean,
        table: { type: 'table' as const },
        multiYearLabel: cell,
        multiYearTextLabel: cell,
        multiYearText: cell,
      },
    },
    definitionOfKey: {
      type: 'part' as const,
      children: {
        active: boolean,
        definitionOfKeyLabel: cell,
        definitionOfKeyTable: { type: 'table' as const },
      },
    },
    changesInEquity: {
      type: 'part' as const,
      children: {
        title: cell,
        active: boolean,
        table: { type: 'table' as const },
        conditionalShareholderContributions: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
      },
    },
    resultsDisposition: {
      type: 'part' as const,
      children: {
        title: cell,
        active: boolean,
        proposeHeader: cell,
        propose: cell,
        toDisposeHeader: cell,
        toDispose: { type: 'table' as const },
        customTotal: cell,
        total: cell,
        totalWarning: cell,
        extraordinaryMeetingDividendHeader: cell,
        extraordinaryMeetingDividend: { type: 'table' as const },
        extraordinaryMeetingDividendTotal: cell,
        boardProposalHeader: cell,
        proposal: { type: 'table' as const },
        customToTransfer: cell,
        toTransfer: cell,
        customInTotal: cell,
        inTotal: cell,
        inTotalWarning: cell,
      },
    },
    profitProposal: {
      type: 'part' as const,
      children: {
        title: cell,
        active: boolean,
        dividendPerShare: cell,
        dividend: cell,
        shares: cell,
        toTransfer: cell,
        paymentDateType: cell,
        paymentDate: cell,
        statement: cell,
      },
    },
    profitProposalK3: {
      type: 'part' as const,
      children: {
        title: cell,
        active: boolean,
        table: { type: 'table' as const },
        comment: cell,
        statement: cell,
      },
    },
    hidden: {
      type: 'part' as const,
      children: {
        activityText: cell,
        city: cell,
      },
    },
  },
};

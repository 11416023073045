import {
  TaxDeclarationFormData,
  TaxDeclarationFormPart,
  isTaxDeclarationCoverLetter,
  isTaxDeclarationFormPartData,
} from '@agoy/tax-declaration-document';

type FieldType = 'editable' | 'calculated' | 'calculated-changed';

export const fieldBackground = (fieldType: FieldType) => {
  switch (fieldType) {
    case 'editable':
      return '#DCF9E7';
    case 'calculated':
      return '#fff5bb';
    case 'calculated-changed':
      return 'repeating-linear-gradient(-45deg, #fff5bb 0px, #fff5bb 5px, #ffea70 5px, #ffea70 10px)';
    default:
      return '#FFF';
  }
};

export default fieldBackground;

/**
 * Helper function to get the TaxDeclarationFormPartData from TaxDeclarationFormData
 * which may contain other external documents related things.
 *
 * @param formData
 * @param key
 * @returns
 */
export const getFormPartData = (
  formData: TaxDeclarationFormData,
  key: string,
  type?: 'coverLetter'
): TaxDeclarationFormPart | undefined => {
  const part = formData[key];

  if (type === 'coverLetter') {
    if (isTaxDeclarationCoverLetter(part)) return part;
  }

  if (isTaxDeclarationFormPartData(part)) {
    return part;
  }
  return undefined;
};

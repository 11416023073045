import React, { memo } from 'react';
import { Typography, TextField, IconButton, Paper } from '@material-ui/core';
import styled from '@emotion/styled';
import { DragIndicator, Delete } from '@material-ui/icons';
import { useIntl } from 'react-intl';
import { Droppable, Draggable, DraggableProvided } from 'react-beautiful-dnd';
import {
  ChecklistQuestion,
  ChecklistGroup as ChecklistGroupType,
  Program,
} from '_shared/redux/checklist/types';
import { DraggableItemTypes } from 'contants';
import { darkBlue } from '@agoy/theme/src/colors';
import ChecklistItem from './ChecklistItem';
import AddNewItem from './AddNewItem';
import AccountSelect from './AccountSelect';
import PeriodSelect from './PeriodSelect';

const Container = styled.div<{ dragging: boolean }>`
  display: flex;
  gap: ${({ theme }) => theme.spacing(1)}px;
  padding: ${({ theme }) => theme.spacing(1)}px 0;
`;

const ContainerReadonly = styled(Paper)`
  padding: ${({ theme }) => theme.spacing(1)}px;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
  gap: ${({ theme }) => theme.spacing(1)}px;
  border: 1px solid ${({ theme }) => theme.palette.border.dark};
  border-radius: 8px;
`;

const EditGroupWrapper = styled.div<{ dragging: boolean }>`
  background-color: ${({ dragging }) => (dragging ? '#b7deb7' : '#f2f4f3')};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing(1)}px;
  width: 100%;
`;

const EditGroupContainer = styled.div<{ showAccountFilters: boolean }>`
  display: grid;
  grid-template-columns: ${({ showAccountFilters }) =>
    showAccountFilters ? '1fr 150px 10px 150px 200px 45px' : '1fr 200px 45px'};
  grid-column-gap: 8px;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
`;

const GroupLabel = styled(Typography)`
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  padding: ${({ theme }) => theme.spacing(1)}px
    ${({ theme }) => theme.spacing(1)}px;
  overflow-wrap: break-word;
`;

const GroupLabelPeriod = styled(Typography)`
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  padding: ${({ theme }) => theme.spacing(1)}px
    ${({ theme }) => theme.spacing(1)}px;
`;

const IconContainer = styled.div<{ dragging: boolean }>`
  align-items: center;
  background-color: ${({ dragging }) => (dragging ? '#b7deb7' : '#f2f4f3')};
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  justify-content: center;
`;

const Items = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: ${({ theme }) => theme.spacing(5)}px;
`;

const GroupLabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
`;

const GroupAccountLabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const GroupContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 200px 32px;
  grid-gap: ${({ theme }) => theme.spacing(1)}px;
  align-items: start;
`;

const StyledTextField = styled(TextField)`
  .MuiOutlinedInput-input {
    padding: ${({ theme }) => theme.spacing(1.5)}px;
  }
`;

const StyledIconButton = styled(IconButton)`
  color: ${darkBlue};
  height: 100%;
`;

interface ChecklistGroupProps {
  group: ChecklistGroupType;
  groupIndex: number;
  editing: boolean;
  showAccountFilters: boolean;
  program: Program;
  style?: React.CSSProperties;
  provided: DraggableProvided;
  isDragging: boolean;

  onAddItem: (groupIndex: number) => void;
  onDeleteItem: (item: ChecklistQuestion, groupIndex: number) => void;
  onChangeItem: (item: ChecklistQuestion, groupIndex: number) => void;

  onDeleteGroup: (groupIndex: number) => void;
  onChangeGroup: (groupItem: ChecklistGroupType, groupIndex: number) => void;
}

const ChecklistGroup = ({
  group,
  groupIndex,
  editing,
  showAccountFilters,
  program,
  provided,
  isDragging,
  onAddItem,
  onDeleteItem,
  onChangeItem,
  onDeleteGroup,
  onChangeGroup,
}: ChecklistGroupProps) => {
  const { formatMessage } = useIntl();

  const handleChangePeriod = (value) => {
    onChangeGroup(
      {
        ...group,
        periodicity: value,
      },
      groupIndex
    );
  };

  const handleChangeLabel = (event) => {
    onChangeGroup(
      {
        ...group,
        label: event.target.value,
      },
      groupIndex
    );
  };

  const handleChangeAccount = (field, value) => {
    onChangeGroup(
      {
        ...group,
        [field]: value,
      },
      groupIndex
    );
  };

  return (
    <>
      {editing ? (
        <Container
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          dragging={isDragging}
          style={{ ...provided.draggableProps.style }}
        >
          <>
            <IconContainer dragging={isDragging}>
              <DragIndicator />
            </IconContainer>
            <EditGroupWrapper dragging={isDragging}>
              <EditGroupContainer showAccountFilters={showAccountFilters}>
                <StyledTextField
                  placeholder={formatMessage({ id: 'checklist.create.group' })}
                  defaultValue={group.label}
                  variant="outlined"
                  onChange={handleChangeLabel}
                />
                {showAccountFilters && (
                  <>
                    <AccountSelect
                      defaultValue={group.fromAccount}
                      placeholder={formatMessage({
                        id: 'checklist.create.fromAccount',
                      })}
                      onChange={(value) =>
                        handleChangeAccount('fromAccount', value)
                      }
                      toAccountNumber={group.toAccount}
                    />
                    <Typography>—</Typography>
                    <AccountSelect
                      defaultValue={group.toAccount}
                      placeholder={formatMessage({
                        id: 'checklist.create.toAccount',
                      })}
                      onChange={(value) =>
                        handleChangeAccount('toAccount', value)
                      }
                      fromAccountNumber={group.fromAccount}
                    />
                  </>
                )}

                <PeriodSelect
                  program={program}
                  period={group.periodicity}
                  onChange={handleChangePeriod}
                />
                {!group.questions.length && (
                  <StyledIconButton
                    size="small"
                    onClick={() => onDeleteGroup(groupIndex)}
                  >
                    <Delete />
                  </StyledIconButton>
                )}
              </EditGroupContainer>
              <Droppable
                droppableId={`${group.id}`}
                type={DraggableItemTypes.CHECKLIST_ITEM}
              >
                {(dropItemProvided) => (
                  <Items
                    {...dropItemProvided.droppableProps}
                    ref={dropItemProvided.innerRef}
                  >
                    {group.questions.map((item, itemIndex) => (
                      <Draggable
                        key={item.id}
                        draggableId={`${item.id}`}
                        index={itemIndex}
                      >
                        {(itemProvided, itemSnapshot) => (
                          <div
                            {...itemProvided.draggableProps}
                            {...itemProvided.dragHandleProps}
                            ref={itemProvided.innerRef}
                          >
                            <ChecklistItem
                              key={item.id}
                              checklistItem={item}
                              editing={editing}
                              isDragging={itemSnapshot.isDragging}
                              program={program}
                              groupIndex={groupIndex}
                              onChangeItem={onChangeItem}
                              onDeleteItem={onDeleteItem}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}

                    {dropItemProvided.placeholder}

                    <AddNewItem
                      title={formatMessage({
                        id: 'checklist.create.newItem',
                      })}
                      onClick={() => onAddItem(groupIndex)}
                    />
                  </Items>
                )}
              </Droppable>
            </EditGroupWrapper>
          </>
        </Container>
      ) : (
        <ContainerReadonly ref={provided.innerRef}>
          <GroupContainer>
            <GroupLabel>{group.label}</GroupLabel>
            <GroupAccountLabelContainer>
              <GroupLabel>{group.fromAccount}</GroupLabel>
              {group.fromAccount && group.toAccount && '—'}
              <GroupLabel>{group.toAccount}</GroupLabel>
            </GroupAccountLabelContainer>
            <GroupLabelContainer>
              <GroupLabelPeriod>
                {formatMessage({
                  id: `checklist.create.periodicity.${group.periodicity}`,
                })}
              </GroupLabelPeriod>
            </GroupLabelContainer>
          </GroupContainer>
          {group.questions.map((item) => (
            <ChecklistItem
              key={item.id}
              checklistItem={item}
              editing={editing}
              isDragging={false}
              program={program}
              groupIndex={groupIndex}
              onChangeItem={onChangeItem}
              onDeleteItem={onDeleteItem}
            />
          ))}
        </ContainerReadonly>
      )}
    </>
  );
};

export default memo(ChecklistGroup);

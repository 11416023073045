import { getContext } from 'utils/AgoyAppClient/contextHolder';
import {
  FormDefinition,
  TaxDeclarationFormData,
  TaxDeclarationFormDefinition,
} from '@agoy/tax-declaration-document';
import { ClientType } from '_shared/types';
import { asResultClass, getApiSdk } from 'api-sdk';

const mapFormDefinition = (
  formDefinition: FormDefinition
): TaxDeclarationFormDefinition => ({ ...formDefinition, parts: undefined });

export const getForms = async (
  financialYear: string,
  clientType: ClientType
): Promise<TaxDeclarationFormDefinition[]> => {
  const sdk = getApiSdk(getContext());
  const result = await asResultClass(
    sdk.getFormsForFinancialYear({
      financialYear,
      clientType,
    })
  );

  if (result.ok) {
    return result.val.map(mapFormDefinition);
  }
  return Promise.reject(result.val);
};

export const printForm = async (
  clientId: string,
  documentName: string,
  financialYear: string,
  formType: string,
  taxFormValues:
    | Record<string, string | number | boolean | undefined>
    | TaxDeclarationFormData
    | null,
  printingParts: ('additionalPages' | 'taxForm' | 'coverLetter')[],
  documentId?: string | null
): Promise<string> => {
  const sdk = getApiSdk(getContext());

  const result = await sdk.printSkvForm({
    clientId,
    financialYear,
    formId: formType,
    requestBody: {
      documentName,
      taxFormValues,
      printingParts,
      documentId: documentId ?? undefined,
    },
  });

  if (!result) {
    return Promise.reject(result);
  }
  return result.url;
};

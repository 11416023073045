import React, {
  useCallback,
  memo,
  useRef,
  useState,
  useEffect,
  useContext,
  useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { Typography, Checkbox, Backdrop, Tooltip } from '@material-ui/core';
import styled from '@emotion/styled';
import { groupBy } from 'lodash';

import { useSelector } from 'redux/reducers';
import { ccyFormat, parseDateWithAbbreviatedMonth } from '@agoy/common';
import { PrintContext } from '_shared/HOC/withPrintContext';
import {
  setCurrentAccount,
  setAccountListDrawerExpanded,
  setAccountListDrawerMode,
  setDeselectedAccount,
} from '_shared/redux/account-list-drawer/actions';
import { currentClientYear } from '_reconciliation/redux/accounting-view/selectors';
import { isDefined } from 'utils/filter';
import Drawer from '_shared/components/Drawer';

import isPropValid from '@emotion/is-prop-valid';
import { AccountingAccount } from 'types/Accounting';
import { AccountNumber } from './AccountList/AccountNumber';
import { AccountRow } from './AccountList/AccountRow';
import { AccountLabel } from './AccountList/AccountLabel';
import {
  CenteredCell,
  IncomingBalance,
  Change,
  OutgoingBalance,
} from './AccountList/AccountCell';
import When from './When/When';
import Button from './Buttons/Button';

const DRAWER_COLLAPSED_WIDTH = 64;
const DRAWER_EXPANDED_WIDTH = 640;

const HeadLabel = styled(Typography)`
  font-size: 14px;
  text-align: center;
  padding: ${(props) => props.theme.spacing(1)}px;
  flex: 0 0 80px;
  overflow: hidden;
  white-space: normal;
`;

const Content = styled('div', { shouldForwardProp: isPropValid })<{
  expanded: boolean;
  selectAccounts: boolean;
}>`
  display: grid;
  grid-template-columns: ${(props) =>
    props.expanded
      ? `64px ${
          props.selectAccounts ? '42px' : ''
        } minmax(0, 1fr) 80px 80px 80px`
      : '64px'};

  grid-gap: 2px;
  padding: ${(props) => props.theme.spacing(1)}px 0;
`;

const Section = styled.div`
  display: grid;
  grid-column: 1 / span 6;
  grid-template-columns: subgrid;
  grid-gap: 2px;
  border-bottom: 2px solid ${({ theme }) => theme.palette.border.light};
`;

const Footer = styled.div`
  position: sticky;
  z-index: 1;
  bottom: 0;
  width: 100%;
  padding: ${({ theme }) => theme.spacing(2)}px;
  background: white;
`;

interface AccountListDrawerProps {
  hidden?: boolean;
  collapsedWidth?: number;
}

const AccountListDrawer = ({
  hidden,
  collapsedWidth = DRAWER_COLLAPSED_WIDTH,
}: AccountListDrawerProps) => {
  const { hideMainContent } = useContext(PrintContext);
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const topRef = useRef(null);

  const [rendered, setRendered] = useState(!hidden);

  // Selectors
  const currentPeriod = useSelector(
    (state) => state.customerView.currentPeriod
  );
  const { expanded, financialYear, selectedAccounts, currentAccount } =
    useSelector((state) => state.accountListDrawer);

  const [currentlyChecked, setCurrentlyChecked] = useState(selectedAccounts);

  useEffect(() => {
    setCurrentlyChecked(selectedAccounts);
  }, [selectedAccounts]);

  const accountDrawerMode = useSelector(
    (state) => state.accountListDrawer.mode
  );

  const isSelectAccountsMode =
    accountDrawerMode === 'selecting-accounts' ||
    accountDrawerMode === 'toggling-accounts';

  const accountingBalances = useSelector(
    currentClientYear((state) => state.accountingBalances)
  );
  const financialYearId = accountingBalances?.periods[0]?.financialYearId;

  const filteredPeriods =
    currentPeriod &&
    accountingBalances?.periods.filter(
      (p) => p.start.replace(/-/g, '') <= currentPeriod
    );

  const groupedAccounts = useMemo(
    () =>
      groupBy(accountingBalances?.accounts, (acc) =>
        selectedAccounts.includes(acc.number) ? 'selected' : 'list'
      ),
    [accountingBalances, selectedAccounts]
  );

  useEffect(() => {
    if (hidden) {
      if (expanded) {
        setRendered(true);
      } else {
        setTimeout(() => setRendered(false), 500);
      }
    }
  }, [hidden, expanded]);

  // Handlers
  const scrollTo = (ref) => {
    if (ref) {
      ref.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const toggleExpanded = useCallback(() => {
    dispatch(setAccountListDrawerExpanded(!expanded));
    dispatch(setAccountListDrawerMode('viewing', financialYear));
  }, [dispatch, expanded, financialYear]);

  const handleCheckboxChange = (
    ev: React.ChangeEvent<HTMLInputElement>,
    account: string
  ) => {
    const shouldSelect = ev.target.checked;

    if (shouldSelect) {
      dispatch(setCurrentAccount(account));
      scrollTo(topRef);
      setCurrentlyChecked((old) => [...old, account]);
    }

    if (!shouldSelect) {
      dispatch(setCurrentAccount());
      dispatch(setDeselectedAccount(account));
      setCurrentlyChecked((old) =>
        old.filter((oldAccount) => oldAccount !== account)
      );
    }
  };

  if (
    !rendered ||
    hideMainContent ||
    !accountingBalances ||
    financialYearId === undefined ||
    !currentPeriod ||
    !filteredPeriods
  ) {
    return null;
  }

  const renderList = (accounts: AccountingAccount[]) =>
    accounts?.map((row) => {
      const ib = row.balances[financialYearId]?.in ?? 0;

      if (!currentPeriod) return;

      // Find the last period that has outgoing balance or use the
      // incoming balance for the year.
      const ubs = filteredPeriods
        ?.map((period) => row.periods[period.id]?.out)
        .filter(isDefined);

      const ub = ubs.length > 0 ? ubs[ubs.length - 1] : ib;

      // eslint-disable-next-line consistent-return
      return (
        <AccountRow key={row.number}>
          <AccountNumber>{row.number}</AccountNumber>
          <When isTrue={expanded}>
            <When isTrue={isSelectAccountsMode}>
              <CenteredCell>
                <Checkbox
                  onChange={(ev) => handleCheckboxChange(ev, row.number)}
                  checked={
                    currentAccount === row.number ||
                    currentlyChecked.includes(row.number)
                  }
                  disabled={
                    currentAccount !== row.number &&
                    currentlyChecked.includes(row.number) &&
                    accountDrawerMode !== 'toggling-accounts'
                  }
                />
              </CenteredCell>
            </When>
            <AccountLabel>
              <Typography variant="body1">{row.name}</Typography>
            </AccountLabel>
            <IncomingBalance>{ccyFormat(ib)}</IncomingBalance>
            <Change>{ccyFormat(ub - ib)}</Change>
            <OutgoingBalance>{ccyFormat(ub)}</OutgoingBalance>
          </When>
        </AccountRow>
      );
    });

  //
  // By using translate to hide top bar, the drawer must now be outside
  // of that parent. Therefore we create a portal to an outside element
  // where the drawer will be rendered.
  //
  return (
    <>
      <Backdrop
        style={{ zIndex: 70 }}
        open={isSelectAccountsMode}
        onClick={() => {
          dispatch(setAccountListDrawerExpanded(false));
          dispatch(setAccountListDrawerMode('viewing'));
        }}
      />

      <Drawer
        open={expanded}
        onClose={toggleExpanded}
        width={`${DRAWER_EXPANDED_WIDTH}px`}
        collapsedWidth={`${collapsedWidth}px`}
        stickyHeader
        headerTitle={formatMessage({
          id: isSelectAccountsMode
            ? 'accountListDrawer.title.selectAccount'
            : 'accountListDrawer.title.accounts',
        })}
        headerContent={
          <>
            <Tooltip title={formatMessage({ id: 'ib.prev.year' })}>
              <HeadLabel variant="h3">{formatMessage({ id: 'ib' })}</HeadLabel>
            </Tooltip>
            <Tooltip
              title={formatMessage(
                {
                  id: 'tot.change.curr.period',
                },
                {
                  periodStart: financialYear
                    ? parseDateWithAbbreviatedMonth(financialYear.start)
                    : '',
                  currentPeriod: parseDateWithAbbreviatedMonth(currentPeriod),
                }
              )}
            >
              <HeadLabel variant="body1">
                {formatMessage({
                  id: 'print.periodSpecification.balanceChange',
                })}
              </HeadLabel>
            </Tooltip>
            <Tooltip
              title={formatMessage(
                { id: 'tot.ub.end.period' },
                {
                  currentPeriod: parseDateWithAbbreviatedMonth(currentPeriod),
                }
              )}
            >
              <HeadLabel variant="body1">
                {formatMessage({ id: 'ub.short' })}
              </HeadLabel>
            </Tooltip>
          </>
        }
      >
        <Content expanded={expanded} selectAccounts={isSelectAccountsMode}>
          <When isTrue={!!groupedAccounts.selected?.length}>
            <Section>{renderList(groupedAccounts.selected)}</Section>
          </When>
          {renderList(groupedAccounts.list)}
        </Content>
        <Footer>
          <Button
            label={formatMessage({ id: 'accountListDrawer.close' })}
            onClick={toggleExpanded}
            size="medium"
          />
        </Footer>
      </Drawer>
    </>
  );
};

export default memo(AccountListDrawer);

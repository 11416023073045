const DocumentPasteWrapper = (
  e: any,
  onLoad: (fileName: string, file: File) => void
): void => {
  const { items } = e.clipboardData || e.originalEvent.clipboardData;

  for (const item of items) {
    if (item.kind === 'file') {
      const blob = item.getAsFile();
      if (blob) {
        onLoad(blob.name || 'image.png', blob);
      }
    }
  }
};

export default DocumentPasteWrapper;

import { GlobalActions } from '_shared/redux/actions';
import DocumentState from '../../types';
import { SET_DOCUMENT_VALUES } from './action-types';
import { DocumentValuesActions } from './actions';

const initial: DocumentState = {};
export default (
  state: DocumentState = initial,
  action: GlobalActions | DocumentValuesActions
): DocumentState => {
  switch (action.type) {
    case SET_DOCUMENT_VALUES:
      const { clientId, financialYear, documentName, values } = action;
      return {
        ...state,
        [clientId]: {
          ...state[clientId],
          [financialYear]: {
            ...state[clientId]?.[financialYear],
            [documentName]: values,
          },
        },
      };

    default:
      return state;
  }
};

export const filterRecord = <T, U extends T>(
  record: Record<string, T>,
  filter: (value: T) => value is U
): Record<string, U> => {
  return Object.entries(record)
    .filter((entry): entry is [string, U] => filter(entry[1]))
    .reduce((result: Record<string, U>, [key, value]) => {
      result[key] = value;
      return result;
    }, {});
};

/**
 * Maps the values of a "Record". The return type of the mapping function
 * is used for the new type of record.
 *
 * The mapping function is given the value and key of the value and
 * is expected to return the new value.
 *
 * @param record
 * @param mapper
 * @returns
 */
export const mapRecord = <T, U>(
  record: Record<string, T>,
  mapper: (value: T, key: string) => U
): Record<string, U> => {
  return Object.entries(record).reduce((result: Record<string, U>, entry) => {
    const [key, value] = entry;
    result[key] = mapper(value, key);
    return result;
  }, {});
};

export const arrayToRecord = <T>(
  arr: T[] | undefined,
  key: (item: T) => string
): Record<string, T> => {
  if (!arr) {
    return {};
  }

  return arr.reduce((result: Record<string, T>, item: T) => {
    result[key(item)] = item;
    return result;
  }, {});
};

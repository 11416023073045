import React from 'react';

const PreviewSection = ({ children, id }) => {
  if (React.Children.count(children) === 0) {
    return null;
  }

  return <div id={id}>{children}</div>;
};

export default PreviewSection;

import { useContext, useMemo } from 'react';
import {
  FinancialYear,
  ReconciliationBalanceAccountRow,
  ReconciliationBalances,
} from '@agoy/api-sdk-core';
import { parseFormat } from '@agoy/dates';
import { useSelector } from 'redux/reducers';
import { AccountRow, Row } from '../RowContext/types';
import { AccountBalance } from '../types';
import PeriodDataContext from './Rows/PeriodDataContext';

// Gets several signatures to avoid having to specify the returned type when using the function
export function getAccounts(
  rows: ReconciliationBalanceAccountRow[],
  asString: true
): string[];
export function getAccounts(
  rows: ReconciliationBalanceAccountRow[],
  asString?: false
): number[];
export function getAccounts(
  rows: ReconciliationBalanceAccountRow[],
  asString?: boolean
): number[] | string[] {
  const accountNumbers: number[] = rows.map(({ number }) => number);

  if (asString) {
    return accountNumbers.map((item) => item.toString());
  }

  return accountNumbers;
}

export const getBalances = (rawBalances: ReconciliationBalances) => {
  const values: AccountBalance = {};

  const getValues = (subRows: typeof rawBalances.rows) => {
    subRows.forEach((subRow) => {
      if (subRow.type === 'account') {
        values[subRow.number] = subRow;
      } else if (subRow.type === 'key') {
        values[subRow.id] = subRow;
      } else {
        values[subRow.id] = subRow.sum;
        getValues(subRow.rows);
      }
    });
  };

  getValues(rawBalances.rows);

  return values;
};

export const getTotalSaldo = (data) => {
  return data.reduce((total: number, { saldo }) => total + saldo, 0);
};

export const getAccountRows = (rows: Row[]): AccountRow[] =>
  rows.filter((item) => 'accountNumber' in item) as AccountRow[];

export const usePrintState = () => {
  const periodData = useContext(PeriodDataContext);

  const customerViewState = useSelector((state) => state.customerView);
  const accountingViewState = useSelector((state) => state.accountingView);
  const customersState = useSelector((state) => state.customers);
  const organisation = useSelector((state) => state.organisation);

  const state = useMemo(
    () => ({
      customerView: customerViewState,
      accountingView: accountingViewState,
      customers: customersState,
      organisation,
      periodData,
    }),
    [
      accountingViewState,
      customerViewState,
      customersState,
      organisation,
      periodData,
    ]
  );

  return state;
};

export const getFinYearInterval = (financialYear: FinancialYear) =>
  `${parseFormat(financialYear.start, 'yyyyMM')}-${parseFormat(
    financialYear.end,
    'yyyyMM'
  )}`;

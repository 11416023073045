export const getSharesCompanyNotesGeneratedV2K3 = {
  generalNotes: ['note1'],
  incomeStatementNotes: [
    'note3',
    'note4',
    'note5',
    'note6',
    'note7',
    'note8',
    'note9',
    'note10',
    'note11',
    'note12',
    'note13',
    'note14',
    'note15',
    'note16',
    'note17',
    'note18',
    'note19',
    'note20',
    'note21',
    'note22',
    'note23',
    'note24',
    'note25',
    'note26',
    'note27',
    'note28',
    'note29',
    'note30',
    'note31',
    'note32',
  ],
  balanceSheetNotes: [
    'note33',
    'note34',
    'note35',
    'note36',
    'note37',
    'note38',
    'note39',
    'note40',
    'note41',
    'note42',
    'note43',
    'note44',
    'note45',
    'note46',
    'note47',
    'note48',
    'note49',
    'note50',
    'note51',
    'note52',
    'note53',
    'note54',
    'note55',
    'note56',
    'note57',
    'note58',
    'note59',
    'note60',
    'note61',
    'note62',
    'note63',
    'note64',
    'note65',
    'note66',
    'note67',
    'note68',
    'note69',
    'note70',
    'note71',
    'note72',
    'note73',
    'note74',
    'note75',
    'note76',
    'note77',
    'note78',
    'note79',
    'note80',
    'note81',
    'note84',
    'note85',
    'note86',
    'note87',
  ],
  otherNotes: [
    'note2',
    'note82',
    'note83',
    'note88',
    'note89',
    'note90',
    'note91',
    'note92',
    'note93',
    'note94',
    'note95',
    'note96',
    'note97',
    'note98',
    'note99',
    'note100',
    'note101',
  ],
};

import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import styled from '@emotion/styled';

import LoadingPlaceholder from '_shared/components/LoadingPlaceholder';
import { useApiSdk } from 'api-sdk';

const Wrapper = styled.div`
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const SkatteverketIntegration = () => {
  const { formatMessage } = useIntl();
  const { search } = useLocation();
  const authenticationCode = new URLSearchParams(search).get('code') || '';
  const sdk = useApiSdk();

  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        await sdk.getSkatteverketAuth({ authenticationCode });
      } catch (e) {
        setError(e);
        console.error(e);
      }
      setIsLoading(false);
    })();
  }, [authenticationCode, sdk]);

  if (isLoading) {
    return (
      <Wrapper>
        <LoadingPlaceholder />
      </Wrapper>
    );
  }

  if (error) {
    return (
      <Wrapper>
        <Typography variant="h1">
          {formatMessage({ id: 'integration.skatteverket.failure' })}
        </Typography>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Typography variant="h1">
        {formatMessage({ id: 'integration.skatteverket.success' })}
      </Typography>
    </Wrapper>
  );
};

export default SkatteverketIntegration;

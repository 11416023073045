import React from 'react';
import { useIntl } from 'react-intl';
import { Typography, IconButton, useTheme, Tooltip } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import styled from '@emotion/styled';

import { useSelector } from 'redux/reducers';
import Logo from '_shared/components/Logo';
import { ClientCompanyType } from '_clients/types/types';
import { ccyFormat } from '@agoy/common';

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const Container = styled(Row)`
  justify-content: space-between;
  cursor: pointer;
`;

const LogoAndNameContainer = styled(Row)`
  column-gap: 12px;
`;

const StyledLogo = styled(Logo)`
  width: 48px;
  height: 48px;
  border-radius: 4px;
  object-fit: contain;
`;

const ActionButtons = styled(Row)`
  column-gap: 8px;
`;

const InfoContainer = styled.div`
  display: flex;
  column-gap: 32px;
`;

const Label = styled(Typography)`
  font-size: 10.5px;
  color: ${(props) => props.theme.palette.text.primary};
`;

const Value = styled(Typography)`
  color: ${(props) => props.theme.palette.text.primary};
  font-weight: 700;
  font-size: 14px;
  white-space: nowrap;
`;

const Box = styled(Column)`
  align-items: flex-start;
`;

const NameText = styled(Typography)`
  font-size: 1.25rem;
  font-weight: 500;
`;

const StyledManager = styled(Typography)`
  line-height: 1rem;
  min-height: 1rem;
  margin-right: ${(props) => props.theme.spacing(1 / 2)}px;
  color: ${(props) => props.theme.palette.text.primary};
`;

const TaxInfoPart = styled.span`
  margin-right: ${(props) => props.theme.spacing(1)}px;
`;

interface InformationRowProps {
  customer: ClientCompanyType;
  expandedCallback: () => void;
  openMoreCallback: () => void;
  expanded: boolean;
}

const InformationRow = ({
  customer,
  expanded,
  expandedCallback,
  openMoreCallback,
}: InformationRowProps) => {
  const { formatMessage } = useIntl();

  const { name, managerId, closingPeriod, vatPeriod, type, tags } = customer;

  const managerEmail = useSelector(
    (state) =>
      state.organisation.users.find((m) => m.userId === managerId)?.email
  );

  const getText = (id: string) => formatMessage({ id });

  const closingPeriodTrans =
    closingPeriod && getText(`closingperiod.${closingPeriod}`);
  const vatPeriodTrans = vatPeriod && getText(`vatperiod.${vatPeriod}`);
  const closingPeriodLabel = getText('closingperiod');
  const vatPeriodLabel = getText('vatperiod');

  /**
   *  By default, company type writes in format like type_type, but for translations we need type.type
   */
  const formattedType = type.split('_').join('.');
  const typeTrans = type && getText(`company.${formattedType}`);
  const typeLabel = getText('company.type');

  const tagsLabel = getText('company.tags');
  const tagsNames = tags?.map((tag) => tag?.name).join(', ');

  const handleExpand = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    expandedCallback();
  };

  const handleShowMore = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    openMoreCallback();
  };

  const TaxInfo = () => {
    const { palette } = useTheme();
    const hasMinusBalance = Number(customer?.taxOutgoingBalanceAmount) < 0;
    const color = hasMinusBalance ? palette.error.main : palette.primary.main;

    const OutgoingLabelAndDate = () => (
      <TaxInfoPart>({customer?.taxOutgoingBalanceDate})</TaxInfoPart>
    );

    const Amount = () => (
      <TaxInfoPart>
        {ccyFormat(customer?.taxOutgoingBalanceAmount)} kr
      </TaxInfoPart>
    );

    if (!customer?.taxOutgoingBalanceDate) {
      return null;
    }

    return (
      <Box>
        <Label variant="subtitle2">{getText('customerinfo.tax')}</Label>
        <Value style={{ color }}>
          <Amount />
          <OutgoingLabelAndDate />
        </Value>
      </Box>
    );
  };

  return (
    <Container>
      <LogoAndNameContainer>
        <StyledLogo src={customer.logo} />
        <Column>
          <NameText>{name}</NameText>
          <StyledManager variant="subtitle2">{managerEmail}</StyledManager>
        </Column>
      </LogoAndNameContainer>

      <InfoContainer>
        {!!closingPeriodTrans && (
          <Tooltip title={getText('closingperiod.tooltip')}>
            <Box>
              <Value>{closingPeriodTrans}</Value>
              <Label>{closingPeriodLabel}</Label>
            </Box>
          </Tooltip>
        )}
        {!!typeTrans && (
          <Box>
            <Value>{typeTrans}</Value>
            <Label>{typeLabel}</Label>
          </Box>
        )}
        {!!vatPeriodTrans && (
          <Box>
            <Value>{vatPeriodTrans}</Value>
            <Label>{vatPeriodLabel}</Label>
          </Box>
        )}
        {!!tagsNames && (
          <Tooltip title={tagsNames}>
            <Box>
              <Value>{tagsNames}</Value>
              <Label>{tagsLabel}</Label>
            </Box>
          </Tooltip>
        )}

        <TaxInfo />

        <ActionButtons>
          <IconButton color="inherit" onClick={handleShowMore}>
            <MoreHorizIcon />
          </IconButton>
          <IconButton color="inherit" onClick={handleExpand}>
            {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </ActionButtons>
      </InfoContainer>
    </Container>
  );
};

export default InformationRow;

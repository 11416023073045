import React from 'react';
import styled from '@emotion/styled';
import useHeaderHeight from '_shared/hooks/useHeaderHeight';
import { appHeaderHeight } from 'theme/measurements';

const Container = styled.div<{ headerHeight: number }>`
  position: fixed;
  top: ${({ headerHeight }) => headerHeight + appHeaderHeight}px;
  right: 0;
  height: calc(
    100vh - ${({ headerHeight }) => headerHeight + appHeaderHeight}px
  );
  overflow-y: auto;

  z-index: ${(props) => props.theme.zIndex.drawer};
`;

/**
 * In order to make fixed work for drawers, they must be placed outside a parent with translate.
 */
const DrawerContainer = () => {
  const headerHeight = useHeaderHeight();

  return (
    <Container
      id="drawers"
      className="topBarShim"
      headerHeight={headerHeight}
    />
  );
};

export default DrawerContainer;

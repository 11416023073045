import styled from '@emotion/styled';
import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { useIntl } from 'react-intl';
import { AgoyTable, AgoyTableColumn } from '@agoy/document';
import { HierarchicalPrintTableRowProps } from './HierarchicalPrintTableRow';

const StyledTable = styled(Table)`
  margin-top: ${(props) => props.theme.spacing(2)}px;
  position: relative;
  border-collapse: separate;

  word-break: break-all;

  .MuiTableCell-root {
    padding: inherit;
  }

  .MuiTableCell-body {
    border: none;
  }

  .MuiTableRow-head {
    vertical-align: baseline;
    padding: ${(props) => props.theme.spacing(1)}px;
  }
`;

const HeaderCell = styled(TableCell)`
  background: ${({ theme }) => theme.palette.common.white};
  color: ${({ theme }) => theme.palette.text.primary};
  border-bottom: none;
  font-weight: 700;
`;

const TableHeadLabel = styled(Typography)`
  font-weight: 700;
`;

type RowComponent = React.ComponentType<HierarchicalPrintTableRowProps>;

type HierarchicalPrintTableProps = {
  className?: string;
  table?: AgoyTable;
  tableId?: string;

  /** Merge rows of multiple tables together, must have matching tableIds */
  tables?: AgoyTable[];
  tableIds?: string[];

  rowComponents: RowComponent[];
  columns?: AgoyTableColumn[];
  hideTableHeadLabel?: boolean;
  additionalHeaderRows?: React.ReactElement[];
  renderHeader?: (
    columns: AgoyTableColumn[]
  ) => React.ReactElement | React.ReactElement[];
  zoom?: number;
  isSinglePeriodSelected?: boolean;
};

interface RowsProps {
  table: AgoyTable;
  tableId: string;
  rowComponents: RowComponent[];
  columns: AgoyTableColumn[];
  zoom?: number;
  isSinglePeriodSelected?: boolean;
}

const Rows = ({
  table,
  tableId,
  rowComponents,
  columns,
  zoom,
  isSinglePeriodSelected,
}: RowsProps) => {
  const RowComponent = rowComponents[0];

  return (
    <>
      {table.rows.map((row) => (
        <RowComponent
          key={`${tableId}.${row.id}`}
          baseId={tableId}
          row={row}
          columns={columns}
          level={1}
          rowComponents={rowComponents}
          zoom={zoom}
          isSinglePeriodSelected={isSinglePeriodSelected}
        />
      ))}
    </>
  );
};

const getColumnHead = (colId, colLabel) => {
  // colId = value is used for CashFlowStatement, year and previousYear used in Balance sheet and income statement
  if (colId === 'year' || colId === 'previousYear' || colId === 'value') {
    const formattedYear = colLabel.split(' ');
    return (
      <>
        <TableHeadLabel>{formattedYear[0]}</TableHeadLabel>
        <TableHeadLabel>{formattedYear[1]}</TableHeadLabel>
      </>
    );
  }
  return <TableHeadLabel>{colLabel}</TableHeadLabel>;
};

const HierarchicalPrintTable = ({
  className,
  table,
  tableId,
  tables,
  tableIds,
  rowComponents,
  columns,
  hideTableHeadLabel = false,
  additionalHeaderRows,
  renderHeader,
  zoom = 1,
  isSinglePeriodSelected,
}: HierarchicalPrintTableProps): JSX.Element | null => {
  const { formatMessage } = useIntl();

  if (tables && !tables[0] && !table) {
    return null;
  }

  const renderColumns = columns || table?.columns || tables?.[0]?.columns;

  if (!renderColumns) {
    return null;
  }

  const firstTableId = tableId || tableIds?.[0];
  if (!firstTableId) {
    return null;
  }

  return (
    <StyledTable className={className}>
      <TableHead>
        <TableRow>
          {renderHeader
            ? renderHeader(renderColumns)
            : renderColumns.map((col, index) => {
                return hideTableHeadLabel && index === 0 ? (
                  <HeaderCell key={col.id} />
                ) : (
                  <HeaderCell key={col.id}>
                    {col.label !== undefined ? (
                      getColumnHead(col.id, col.label)
                    ) : (
                      <TableHeadLabel>
                        {formatMessage({
                          id: `annualReport.${firstTableId}.${col.id}`,
                          defaultMessage: col.id,
                        })}
                      </TableHeadLabel>
                    )}
                  </HeaderCell>
                );
              })}
        </TableRow>
        {additionalHeaderRows?.map((item) => item)}
      </TableHead>
      <TableBody>
        {table && tableId && (
          <Rows
            table={table}
            tableId={tableId}
            rowComponents={rowComponents}
            isSinglePeriodSelected={isSinglePeriodSelected}
            columns={renderColumns}
            zoom={zoom}
          />
        )}
        {tables?.map((aTable, index) => {
          const id = tableIds?.[index];
          if (!id) {
            return null;
          }
          return (
            <Rows
              key={id}
              table={aTable}
              tableId={id}
              rowComponents={rowComponents}
              isSinglePeriodSelected={isSinglePeriodSelected}
              columns={renderColumns}
              zoom={zoom}
            />
          );
        })}
      </TableBody>
    </StyledTable>
  );
};

export default HierarchicalPrintTable;

import { SeverityLevel } from '../types';
import { ADD_CHANNEL_MESSAGE, REMOVE_CHANNEL_MESSAGE } from './action-types';

interface AddChannelMessage {
  type: typeof ADD_CHANNEL_MESSAGE;
  id: string;
  channel: string;
  severity: SeverityLevel;
  textId?: string;
  text?: string;
}

interface RemoveChannelMessage {
  type: typeof REMOVE_CHANNEL_MESSAGE;
  channel: string;
  id: string;
}

let nextId = 0;
const getNextId = () => `${nextId++}`;

export const addGlobalErrorMessage = (
  textId: string | undefined,
  text?: string
): AddChannelMessage => ({
  type: ADD_CHANNEL_MESSAGE,
  id: getNextId(),
  channel: 'global',
  severity: 'error',
  text,
  textId,
});

export const addGlobalMessage = (
  severity: SeverityLevel,
  textId: string | undefined,
  text?: string
): AddChannelMessage =>
  addChannelMessage(severity, 'global', getNextId(), textId, text);

export const addChannelMessage = (
  severity: SeverityLevel,
  channel: string,
  messageId: string,
  textId: string | undefined,
  text?: string
): AddChannelMessage => ({
  type: ADD_CHANNEL_MESSAGE,
  id: messageId,
  channel,
  severity,
  text,
  textId,
});

export const removeGlobalMessage = (id: string): RemoveChannelMessage =>
  removeChannelMessage('global', id);

export const removeChannelMessage = (
  channel: string,
  id: string
): RemoveChannelMessage => ({
  type: REMOVE_CHANNEL_MESSAGE,
  channel,
  id,
});

export type MessagesActions = AddChannelMessage | RemoveChannelMessage;

import React from 'react';
import { Chip as MaterialChip } from '@material-ui/core';
import styled from '@emotion/styled';

import { parseDateStringToMonthAndYear } from '@agoy/common';

const Wrapper = styled.div`
  height: ${(props) => props.theme.spacing(5)}px;

  overflow: hidden;
  white-space: nowrap;

  position: relative;
`;

const OverFlowSection = styled.div`
  height: ${(props) => props.theme.spacing(5)}px;
  width: ${(props) => props.theme.spacing(3)}px;
  position: absolute;
  top: 0;
  right: 0;
  background: linear-gradient(
    to right,
    rgb(222 16 16 / 0%) 0%,
    ${(props) => props.theme.palette.background.default} 90%
  );
`;

const Chip = styled(MaterialChip)`
  margin-right: ${(props) => props.theme.spacing(1)}px;
  // below is the collor for text inside the chip
  color: ${(props) => props.theme.palette.secondary.contrastText};
`;

interface Props {
  periods: { [periodString: string]: boolean };
  handlePeriodSelect: (period: string) => any;
}

const ChipFilter = ({ periods, handlePeriodSelect }: Props) => (
  <Wrapper>
    {Object.keys(periods).map((period) => {
      return (
        <Chip
          key={`chip-${period}`}
          label={parseDateStringToMonthAndYear(period)}
          onDelete={() => handlePeriodSelect(period)}
          color="primary"
        />
      );
    })}
    <OverFlowSection />
  </Wrapper>
);

export default ChipFilter;

import { id, or, ref, sum, table, value } from '@agoy/document';
import { NoteOneTableWithSum } from '../../../../common';
import { previousYear, year } from '../../../../common/utils/date-util';
import { sumAccountsUBs } from '../../../../common/utils/accountSumsUtil';
import { field } from '../../../../common/utils/util';
import { NoteAccountRanges } from '../types';

export const note30Accounts: NoteAccountRanges[] = [
  {
    rowId: '2',
    ranges: [[2330, 2339]],
    cellLabel: 'Outnyttjad del',
    action: 'sumAccountsUBs',
  },
];

export const getNote30 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null,
  documentTypeVersion = '1'
): NoteOneTableWithSum => {
  return {
    active: false,
    number: value(undefined),
    // manual submission version of Checkräkningskredit
    name: value('Checkräkningskredit'),
    table: table(
      'notes.note30.table',
      { id: 'label', label: '' },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      .addRows((rows) => {
        rows
          .addRow(
            '1',
            noteLabel('Beviljad checkräkningskredit uppgår till'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '2',
            noteLabel('Outnyttjad del'),
            ref(sumAccountsUBs('2330:2339', true)),
            ref(sumAccountsUBs('2330:2339', true, 'previousYear'))
          );
        return rows.build();
      })
      .newRowTemplate(value(''), value(undefined), value(undefined))
      .build(),
    sumTable: table(
      'notes.note30.sumTable',
      { id: 'utilizedCreditAmount', label: '' },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      .addRows((rows) =>
        rows
          .addRow(
            'sum',
            noteLabel('Utnyttjat kreditbelopp'),
            ref(or(sum(id('notes.note30.table.*.year')), 0)),
            ref(or(sum(id('notes.note30.table.*.previousYear')), 0))
          )
          .build()
      )
      .build(),
    commentLabel: field('Kommentar'),
    comment: field(''),
    group: value(undefined),
    section: value(undefined),
    validForReportType: {
      k2: documentTypeVersion === '1',
      k3: documentTypeVersion === '1',
    },
  };
};

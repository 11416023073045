import styled from '@emotion/styled';
import React from 'react';
import { useDrag } from 'react-dnd';

const ItemType = 'TABLE_ITEM';

const Th = styled.th`
  text-align: start;
  padding: ${(props) => props.theme.spacing(2)}px
    ${(props) => props.theme.spacing(2)}px;
  border-bottom: 1px solid #ccc;
  border-left: 1px solid #ccc;
  white-space: nowrap;
  min-width: 300px;
  cursor: move;
`;

type DraggableHeaderProps = {
  children: React.ReactNode;
  columnIndex: number;
  moveColumn: (
    fromRowIndex: number,
    fromColumnIndex: number,
    toRowIndex: number,
    toColumnIndex: number
  ) => void;
};

const DraggableHeader = ({
  children,
  columnIndex,
  moveColumn,
}: DraggableHeaderProps) => {
  const [, drag] = useDrag({
    type: ItemType,
    item: { rowIndex: -1, columnIndex, isRow: true },
  });

  return <Th ref={drag}>{children}</Th>;
};

export default DraggableHeader;

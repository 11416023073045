// This is a GENERATED file, do not change!
// Generated:
// - from file data/k3-arsredovisning-2021-10-31-rev20230131 - Noter.csv
// - on 2023-12-19 12:14:40.443689
// - Comments: AGOY-6805
// - Generator git hash: 86681ea3f233ed64b51fbd4e1628e056d91725f8

import {
  value,
  ref,
  table,
  or,
  sum,
  sumAllowEmpty,
  id,
  ReferenceAccountInformation,
  account,
  RowsBuilder,
  multiply,
} from '@agoy/document';
import { IxbrlCell, NoteOneTableSection } from '../../../../common';
import {
  active,
  field,
  ixbrlCell,
  ixbrlField,
  range,
  tupleRef,
} from '../../../../common/utils/util';
import { previousYear, year } from '../../../../common/utils/date-util';
import {
  sumAccountsIBs,
  sumAccountsUBs,
  sumNegativeTransactions,
  sumPositiveTransactions,
} from '../../../../common/utils/accountSumsUtil';
import { NoteAccountRanges } from '../types';

export const note402Accounts: NoteAccountRanges[] = [
  // --- begin note_account_ranges_body (level 2) [notes.note402.table.InventarierVerktygInstallationerAnskaffningsvardenIngaende] ---
  // --- [752: rowSumAccounts(InventarierVerktygInstallationerAnskaffningsvarden, 1220:1227+1230:1232+1240:1247+1250:1257+1260, {"period_base":1}, InventarierVerktygInstallationerAnskaffningsvardenIngaende, nan)] ---
  {
    rowId:
      'notes.note402.table.InventarierVerktygInstallationerAnskaffningsvardenIngaende',
    ranges: [[1220, 1227], [1230, 1232], [1240, 1247], [1250, 1257], [1260]],
    cellLabel: 'Ingående anskaffningsvärden',
    action: 'sumAccountsIBs',
  },
  // --- end note_account_ranges_body ---
  // --- begin note_account_ranges_body (level 2) [notes.note402.table.InventarierVerktygInstallationerAvskrivningarIngaende] ---
  // --- [761: rowSumAccounts(InventarierVerktygInstallationerAvskrivningar, 1229+1239+1249+1259+1269, {"period_base":1}, InventarierVerktygInstallationerAvskrivningarIngaende, nan)] ---
  {
    rowId:
      'notes.note402.table.InventarierVerktygInstallationerAvskrivningarIngaende',
    ranges: [[1229], [1239], [1249], [1259], [1269]],
    cellLabel: 'Ingående avskrivningar',
    action: 'sumAccountsIBs',
  },
  // --- end note_account_ranges_body ---
  // --- begin note_account_ranges_body (level 2) [notes.note402.table.InventarierVerktygInstallationerNedskrivningarIngaende] ---
  // --- [779: rowSumAccounts(InventarierVerktygInstallationerNedskrivningar, 1228+1238+1248+1258, {"period_base":1}, InventarierVerktygInstallationerNedskrivningarIngaende, nan)] ---
  {
    rowId:
      'notes.note402.table.InventarierVerktygInstallationerNedskrivningarIngaende',
    ranges: [[1228], [1238], [1248], [1258]],
    cellLabel: 'Ingående nedskrivningar',
    action: 'sumAccountsIBs',
  },
  // --- end note_account_ranges_body ---
];

// --- begin note_header (level 0) [notes.note402] ---
// --- [750: beginNote(NotInventarierVerktygInstallationer, nan, {"noteTableSectionType":"NoteOneTableSection", "k2":"false", "k3":"documentTypeVersion !== '1'", "documentTypeVersion":1}, notes.note402, AGOY-6805)] ---
export const getNote402 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null,
  documentTypeVersion = '1'
): NoteOneTableSection => {
  return {
    active: false,
    number: value(undefined),
    name: value('Inventarier, verktyg och installationer'),
    // --- end note_header ---
    // --- begin default_table_header (level 1) [notes.note402.table] ---
    // --- [751: tableHeader(InventarierVerktygInstallationerAbstract, nan, nan, table, nan)] ---
    table: table<IxbrlCell>(
      'notes.note402.table',
      { id: 'label', label: '' },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      .addRows((rows) => {
        // --- end default_table_header ---
        // --- begin row_sum_accounts (level 2) [notes.note402.table.InventarierVerktygInstallationerAnskaffningsvardenIngaende] ---
        // --- [752: rowSumAccounts(InventarierVerktygInstallationerAnskaffningsvarden, 1220:1227+1230:1232+1240:1247+1250:1257+1260, {"period_base":1}, InventarierVerktygInstallationerAnskaffningsvardenIngaende, nan)] ---
        rows.addRow(
          'InventarierVerktygInstallationerAnskaffningsvardenIngaende',
          noteLabel('Ingående anskaffningsvärden'),
          ixbrlCell(
            ref(
              sumAccountsIBs(
                '1220:1227+1230:1232+1240:1247+1250:1257+1260',
                false
              )
            ),
            {
              type: 'monetaryItemType',
              name: 'se-gen-base:InventarierVerktygInstallationerAnskaffningsvarden',
              contextRef: 'balans1',
              standardRubrik:
                'Anskaffningsvärden inventarier, verktyg och installationer',
              saldo: 'debit',
              negateValue: false,
            }
          ),
          ixbrlCell(
            ref(
              sumAccountsIBs(
                '1220:1227+1230:1232+1240:1247+1250:1257+1260',
                false,
                'previousYear'
              )
            ),
            {
              type: 'monetaryItemType',
              name: 'se-gen-base:InventarierVerktygInstallationerAnskaffningsvarden',
              contextRef: 'balans2',
              standardRubrik:
                'Anskaffningsvärden inventarier, verktyg och installationer',
              saldo: 'debit',
              negateValue: false,
            }
          )
        );
        // --- end row_sum_accounts ---
        // --- begin row_header (level 2) [notes.note402.table.InventarierVerktygInstallationerForandringAnskaffningsvardenAbstract] ---
        // --- [753: rowHeader(InventarierVerktygInstallationerForandringAnskaffningsvardenAbstract, nan, nan, nan, nan)] ---
        rows.addRow(
          'InventarierVerktygInstallationerForandringAnskaffningsvardenAbstract',
          noteLabel('Förändringar av anskaffningsvärden'),
          undefined,
          undefined
        );
        // --- end row_header ---
        // --- begin row_sum_rows_in_table (level 2) [notes.note402.table.InventarierVerktygInstallationerForandringAnskaffningsvardenInkop] ---
        // --- [754: rowEmptyValue(InventarierVerktygInstallationerForandringAnskaffningsvardenInkop, nan, nan, nan, nan)] ---
        rows.addRow(
          'InventarierVerktygInstallationerForandringAnskaffningsvardenInkop',
          noteLabel('Inköp'),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base:InventarierVerktygInstallationerForandringAnskaffningsvardenInkop',
            contextRef: 'period0',
            standardRubrik:
              'Ökning av anskaffningsvärden i samband med inköp av inventarier, verktyg och installationer',
            negateValue: false,
          }),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base:InventarierVerktygInstallationerForandringAnskaffningsvardenInkop',
            contextRef: 'period1',
            standardRubrik:
              'Ökning av anskaffningsvärden i samband med inköp av inventarier, verktyg och installationer',
            negateValue: false,
          })
        );
        // --- end row_sum_rows_in_table ---
        // --- begin row_sum_rows_in_table (level 2) [notes.note402.table.InventarierVerktygInstallationerForandringAnskaffningsvardenRorelseforvarv] ---
        // --- [755: rowEmptyValue(InventarierVerktygInstallationerForandringAnskaffningsvardenRorelseforvarv, nan, nan, nan, nan)] ---
        rows.addRow(
          'InventarierVerktygInstallationerForandringAnskaffningsvardenRorelseforvarv',
          noteLabel('Rörelseförvärv'),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base:InventarierVerktygInstallationerForandringAnskaffningsvardenRorelseforvarv',
            contextRef: 'period0',
            standardRubrik:
              'Ökning (minskning) av anskaffningsvärden för inventarier, verktyg och installationer genom rörelseförvärv',
            negateValue: false,
          }),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base:InventarierVerktygInstallationerForandringAnskaffningsvardenRorelseforvarv',
            contextRef: 'period1',
            standardRubrik:
              'Ökning (minskning) av anskaffningsvärden för inventarier, verktyg och installationer genom rörelseförvärv',
            negateValue: false,
          })
        );
        // --- end row_sum_rows_in_table ---
        // --- begin row_sum_rows_in_table (level 2) [notes.note402.table.InventarierVerktygInstallationerForandringAnskaffningsvardenFusion] ---
        // --- [756: rowEmptyValue(InventarierVerktygInstallationerForandringAnskaffningsvardenFusion, nan, nan, nan, nan)] ---
        rows.addRow(
          'InventarierVerktygInstallationerForandringAnskaffningsvardenFusion',
          noteLabel('Fusion'),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base:InventarierVerktygInstallationerForandringAnskaffningsvardenFusion',
            contextRef: 'period0',
            standardRubrik:
              'Ökning (minskning) av anskaffningsvärden för inventarier, verktyg och installationer genom fusion',
            negateValue: false,
          }),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base:InventarierVerktygInstallationerForandringAnskaffningsvardenFusion',
            contextRef: 'period1',
            standardRubrik:
              'Ökning (minskning) av anskaffningsvärden för inventarier, verktyg och installationer genom fusion',
            negateValue: false,
          })
        );
        // --- end row_sum_rows_in_table ---
        // --- begin row_sum_rows_in_table (level 2) [notes.note402.table.InventarierVerktygInstallationerForandringAnskaffningsvardenForsaljningarUtrangeringar] ---
        // --- [757: rowEmptyValue(InventarierVerktygInstallationerForandringAnskaffningsvardenForsaljningarUtrangeringar, nan, nan, nan, nan)] ---
        rows.addRow(
          'InventarierVerktygInstallationerForandringAnskaffningsvardenForsaljningarUtrangeringar',
          noteLabel('Försäljningar/utrangeringar'),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base:InventarierVerktygInstallationerForandringAnskaffningsvardenForsaljningarUtrangeringar',
            contextRef: 'period0',
            standardRubrik:
              'Minskning av anskaffningsvärden i samband med försäljningar eller utrangeringar av inventarier, verktyg och installationer',
            negateValue: true,
          }),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base:InventarierVerktygInstallationerForandringAnskaffningsvardenForsaljningarUtrangeringar',
            contextRef: 'period1',
            standardRubrik:
              'Minskning av anskaffningsvärden i samband med försäljningar eller utrangeringar av inventarier, verktyg och installationer',
            negateValue: true,
          })
        );
        // --- end row_sum_rows_in_table ---
        // --- begin row_sum_rows_in_table (level 2) [notes.note402.table.InventarierVerktygInstallationerForandringAnskaffningsvardenOmklassificeringar] ---
        // --- [758: rowEmptyValue(InventarierVerktygInstallationerForandringAnskaffningsvardenOmklassificeringar, nan, nan, nan, nan)] ---
        rows.addRow(
          'InventarierVerktygInstallationerForandringAnskaffningsvardenOmklassificeringar',
          noteLabel('Omklassificeringar'),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base:InventarierVerktygInstallationerForandringAnskaffningsvardenOmklassificeringar',
            contextRef: 'period0',
            standardRubrik:
              'Ökning (minskning) av anskaffningsvärden i samband med omklassificeringar av inventarier, verktyg och installationer',
            negateValue: false,
          }),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base:InventarierVerktygInstallationerForandringAnskaffningsvardenOmklassificeringar',
            contextRef: 'period1',
            standardRubrik:
              'Ökning (minskning) av anskaffningsvärden i samband med omklassificeringar av inventarier, verktyg och installationer',
            negateValue: false,
          })
        );
        // --- end row_sum_rows_in_table ---
        // --- begin row_sum_rows_in_table (level 2) [notes.note402.table.InventarierVerktygInstallationerForandringAnskaffningsvardenOmrakningsdifferenser] ---
        // --- [759: rowEmptyValue(InventarierVerktygInstallationerForandringAnskaffningsvardenOmrakningsdifferenser, nan, nan, nan, nan)] ---
        rows.addRow(
          'InventarierVerktygInstallationerForandringAnskaffningsvardenOmrakningsdifferenser',
          noteLabel('Omräkningsdifferenser'),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base:InventarierVerktygInstallationerForandringAnskaffningsvardenOmrakningsdifferenser',
            contextRef: 'period0',
            standardRubrik:
              'Ökning (minskning) av anskaffningsvärden i samband med omräkningsdifferenser av inventarier, verktyg och installationer',
            negateValue: false,
          }),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base:InventarierVerktygInstallationerForandringAnskaffningsvardenOmrakningsdifferenser',
            contextRef: 'period1',
            standardRubrik:
              'Ökning (minskning) av anskaffningsvärden i samband med omräkningsdifferenser av inventarier, verktyg och installationer',
            negateValue: false,
          })
        );
        // --- end row_sum_rows_in_table ---
        // --- begin row_sum_rows_in_table (level 2) [notes.note402.table.InventarierVerktygInstallationerAnskaffningsvardenUtgaende] ---
        // --- [760: rowSumRowsInTable(InventarierVerktygInstallationerAnskaffningsvarden, nan, {"sum":["InventarierVerktygInstallationerAnskaffningsvardenIngaende", "InventarierVerktygInstallationerForandringAnskaffningsvardenInkop", "InventarierVerktygInstallationerForandringAnskaffningsvardenRorelseforvarv", "InventarierVerktygInstallationerForandringAnskaffningsvardenFusion", "InventarierVerktygInstallationerForandringAnskaffningsvardenForsaljningarUtrangeringar", "InventarierVerktygInstallationerForandringAnskaffningsvardenOmklassificeringar", "InventarierVerktygInstallationerForandringAnskaffningsvardenOmrakningsdifferenser"]}, InventarierVerktygInstallationerAnskaffningsvardenUtgaende, nan)] ---
        rows.addRow(
          'InventarierVerktygInstallationerAnskaffningsvardenUtgaende',
          noteLabel('Utgående anskaffningsvärden'),
          ixbrlCell(
            ref(
              or(
                sumAllowEmpty(
                  id(
                    'notes.note402.table.InventarierVerktygInstallationerAnskaffningsvardenIngaende.year'
                  ),
                  id(
                    'notes.note402.table.InventarierVerktygInstallationerForandringAnskaffningsvardenInkop.year'
                  ),
                  id(
                    'notes.note402.table.InventarierVerktygInstallationerForandringAnskaffningsvardenRorelseforvarv.year'
                  ),
                  id(
                    'notes.note402.table.InventarierVerktygInstallationerForandringAnskaffningsvardenFusion.year'
                  ),
                  id(
                    'notes.note402.table.InventarierVerktygInstallationerForandringAnskaffningsvardenForsaljningarUtrangeringar.year'
                  ),
                  id(
                    'notes.note402.table.InventarierVerktygInstallationerForandringAnskaffningsvardenOmklassificeringar.year'
                  ),
                  id(
                    'notes.note402.table.InventarierVerktygInstallationerForandringAnskaffningsvardenOmrakningsdifferenser.year'
                  )
                ),
                0
              )
            ),
            {
              type: 'monetaryItemType',
              name: 'se-gen-base:InventarierVerktygInstallationerAnskaffningsvarden',
              contextRef: 'balans0',
              standardRubrik:
                'Anskaffningsvärden inventarier, verktyg och installationer',
              saldo: 'debit',
              negateValue: false,
            }
          ),
          ixbrlCell(
            ref(
              or(
                sumAllowEmpty(
                  id(
                    'notes.note402.table.InventarierVerktygInstallationerAnskaffningsvardenIngaende.previousYear'
                  ),
                  id(
                    'notes.note402.table.InventarierVerktygInstallationerForandringAnskaffningsvardenInkop.previousYear'
                  ),
                  id(
                    'notes.note402.table.InventarierVerktygInstallationerForandringAnskaffningsvardenRorelseforvarv.previousYear'
                  ),
                  id(
                    'notes.note402.table.InventarierVerktygInstallationerForandringAnskaffningsvardenFusion.previousYear'
                  ),
                  id(
                    'notes.note402.table.InventarierVerktygInstallationerForandringAnskaffningsvardenForsaljningarUtrangeringar.previousYear'
                  ),
                  id(
                    'notes.note402.table.InventarierVerktygInstallationerForandringAnskaffningsvardenOmklassificeringar.previousYear'
                  ),
                  id(
                    'notes.note402.table.InventarierVerktygInstallationerForandringAnskaffningsvardenOmrakningsdifferenser.previousYear'
                  )
                ),
                0
              )
            ),
            {
              type: 'monetaryItemType',
              name: 'se-gen-base:InventarierVerktygInstallationerAnskaffningsvarden',
              contextRef: 'balans1',
              standardRubrik:
                'Anskaffningsvärden inventarier, verktyg och installationer',
              saldo: 'debit',
              negateValue: false,
            }
          )
        );
        // --- end row_sum_rows_in_table ---
        // --- begin row_sum_accounts (level 2) [notes.note402.table.InventarierVerktygInstallationerAvskrivningarIngaende] ---
        // --- [761: rowSumAccounts(InventarierVerktygInstallationerAvskrivningar, 1229+1239+1249+1259+1269, {"period_base":1}, InventarierVerktygInstallationerAvskrivningarIngaende, nan)] ---
        rows.addRow(
          'InventarierVerktygInstallationerAvskrivningarIngaende',
          noteLabel('Ingående avskrivningar'),
          ixbrlCell(ref(sumAccountsIBs('1229+1239+1249+1259+1269', false)), {
            type: 'monetaryItemType',
            name: 'se-gen-base:InventarierVerktygInstallationerAvskrivningar',
            contextRef: 'balans1',
            standardRubrik:
              'Ackumulerade avskrivningar inventarier, verktyg och installationer',
            saldo: 'credit',
            negateValue: true,
          }),
          ixbrlCell(
            ref(
              sumAccountsIBs('1229+1239+1249+1259+1269', false, 'previousYear')
            ),
            {
              type: 'monetaryItemType',
              name: 'se-gen-base:InventarierVerktygInstallationerAvskrivningar',
              contextRef: 'balans2',
              standardRubrik:
                'Ackumulerade avskrivningar inventarier, verktyg och installationer',
              saldo: 'credit',
              negateValue: true,
            }
          )
        );
        // --- end row_sum_accounts ---
        // --- begin row_header (level 2) [notes.note402.table.InventarierVerktygInstallationerForandringAvskrivningarAbstract] ---
        // --- [762: rowHeader(InventarierVerktygInstallationerForandringAvskrivningarAbstract, nan, nan, nan, nan)] ---
        rows.addRow(
          'InventarierVerktygInstallationerForandringAvskrivningarAbstract',
          noteLabel('Förändringar av avskrivningar'),
          undefined,
          undefined
        );
        // --- end row_header ---
        // --- begin row_sum_rows_in_table (level 2) [notes.note402.table.InventarierVerktygInstallationerForandringAvskrivningarFusion] ---
        // --- [763: rowEmptyValue(InventarierVerktygInstallationerForandringAvskrivningarFusion, nan, nan, nan, nan)] ---
        rows.addRow(
          'InventarierVerktygInstallationerForandringAvskrivningarFusion',
          noteLabel('Fusion'),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base:InventarierVerktygInstallationerForandringAvskrivningarFusion',
            contextRef: 'period0',
            standardRubrik:
              'Ökning (minskning) av avskrivningar för inventarier, verktyg och installationer genom fusion',
            negateValue: true,
          }),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base:InventarierVerktygInstallationerForandringAvskrivningarFusion',
            contextRef: 'period1',
            standardRubrik:
              'Ökning (minskning) av avskrivningar för inventarier, verktyg och installationer genom fusion',
            negateValue: true,
          })
        );
        // --- end row_sum_rows_in_table ---
        // --- begin row_sum_rows_in_table (level 2) [notes.note402.table.InventarierVerktygInstallationerForandringAvskrivningarAterfordaAvskrivningarForsaljningarRespUtrangeringar] ---
        // --- [764: rowEmptyValue(InventarierVerktygInstallationerForandringAvskrivningarAterfordaAvskrivningarForsaljningarRespUtrangeringar, nan, nan, nan, nan)] ---
        rows.addRow(
          'InventarierVerktygInstallationerForandringAvskrivningarAterfordaAvskrivningarForsaljningarRespUtrangeringar',
          noteLabel('Försäljningar/utrangeringar'),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base:InventarierVerktygInstallationerForandringAvskrivningarAterfordaAvskrivningarForsaljningarRespUtrangeringar',
            contextRef: 'period0',
            standardRubrik:
              'Återförda avskrivningar i samband med försäljningar eller utrangeringar av inventarier, verktyg och installationer',
            negateValue: false,
          }),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base:InventarierVerktygInstallationerForandringAvskrivningarAterfordaAvskrivningarForsaljningarRespUtrangeringar',
            contextRef: 'period1',
            standardRubrik:
              'Återförda avskrivningar i samband med försäljningar eller utrangeringar av inventarier, verktyg och installationer',
            negateValue: false,
          })
        );
        // --- end row_sum_rows_in_table ---
        // --- begin row_sum_rows_in_table (level 2) [notes.note402.table.InventarierVerktygInstallationerForandringAvskrivningarOmklassificeringar] ---
        // --- [765: rowEmptyValue(InventarierVerktygInstallationerForandringAvskrivningarOmklassificeringar, nan, nan, nan, nan)] ---
        rows.addRow(
          'InventarierVerktygInstallationerForandringAvskrivningarOmklassificeringar',
          noteLabel('Omklassificeringar'),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base:InventarierVerktygInstallationerForandringAvskrivningarOmklassificeringar',
            contextRef: 'period0',
            standardRubrik:
              'Minskning (ökning) av avskrivningar i samband med omklassificeringar av inventarier, verktyg och installationer',
            negateValue: false,
          }),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base:InventarierVerktygInstallationerForandringAvskrivningarOmklassificeringar',
            contextRef: 'period1',
            standardRubrik:
              'Minskning (ökning) av avskrivningar i samband med omklassificeringar av inventarier, verktyg och installationer',
            negateValue: false,
          })
        );
        // --- end row_sum_rows_in_table ---
        // --- begin row_sum_rows_in_table (level 2) [notes.note402.table.InventarierVerktygInstallationerForandringAvskrivningarAretsAvskrivningar] ---
        // --- [766: rowEmptyValue(InventarierVerktygInstallationerForandringAvskrivningarAretsAvskrivningar, nan, nan, nan, nan)] ---
        rows.addRow(
          'InventarierVerktygInstallationerForandringAvskrivningarAretsAvskrivningar',
          noteLabel('Årets avskrivningar'),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base:InventarierVerktygInstallationerForandringAvskrivningarAretsAvskrivningar',
            contextRef: 'period0',
            standardRubrik:
              'Periodens avskrivningar inventarier, verktyg och installationer',
            negateValue: false,
          }),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base:InventarierVerktygInstallationerForandringAvskrivningarAretsAvskrivningar',
            contextRef: 'period1',
            standardRubrik:
              'Periodens avskrivningar inventarier, verktyg och installationer',
            negateValue: false,
          })
        );
        // --- end row_sum_rows_in_table ---
        // --- begin row_sum_rows_in_table (level 2) [notes.note402.table.InventarierVerktygInstallationerForandringAvskrivningarOmrakningsdifferenser] ---
        // --- [767: rowEmptyValue(InventarierVerktygInstallationerForandringAvskrivningarOmrakningsdifferenser, nan, nan, nan, nan)] ---
        rows.addRow(
          'InventarierVerktygInstallationerForandringAvskrivningarOmrakningsdifferenser',
          noteLabel('Omräkningsdifferenser'),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base:InventarierVerktygInstallationerForandringAvskrivningarOmrakningsdifferenser',
            contextRef: 'period0',
            standardRubrik:
              'Minskning (ökning) av avskrivningar i samband med omräkningsdifferenser av inventarier, verktyg och installationer',
            negateValue: false,
          }),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base:InventarierVerktygInstallationerForandringAvskrivningarOmrakningsdifferenser',
            contextRef: 'period1',
            standardRubrik:
              'Minskning (ökning) av avskrivningar i samband med omräkningsdifferenser av inventarier, verktyg och installationer',
            negateValue: false,
          })
        );
        // --- end row_sum_rows_in_table ---
        // --- begin row_sum_rows_in_table (level 2) [notes.note402.table.InventarierVerktygInstallationerAvskrivningarUtgaende] ---
        // --- [768: rowSumRowsInTable(InventarierVerktygInstallationerAvskrivningar, nan, {"sum":["InventarierVerktygInstallationerAvskrivningarIngaende", "InventarierVerktygInstallationerForandringAvskrivningarFusion", "InventarierVerktygInstallationerForandringAvskrivningarAterfordaAvskrivningarForsaljningarRespUtrangeringar", "InventarierVerktygInstallationerForandringAvskrivningarOmklassificeringar", "InventarierVerktygInstallationerForandringAvskrivningarAretsAvskrivningar", "InventarierVerktygInstallationerForandringAvskrivningarOmrakningsdifferenser"]}, InventarierVerktygInstallationerAvskrivningarUtgaende, nan)] ---
        rows.addRow(
          'InventarierVerktygInstallationerAvskrivningarUtgaende',
          noteLabel('Utgående avskrivningar'),
          ixbrlCell(
            ref(
              or(
                sumAllowEmpty(
                  id(
                    'notes.note402.table.InventarierVerktygInstallationerAvskrivningarIngaende.year'
                  ),
                  id(
                    'notes.note402.table.InventarierVerktygInstallationerForandringAvskrivningarFusion.year'
                  ),
                  id(
                    'notes.note402.table.InventarierVerktygInstallationerForandringAvskrivningarAterfordaAvskrivningarForsaljningarRespUtrangeringar.year'
                  ),
                  id(
                    'notes.note402.table.InventarierVerktygInstallationerForandringAvskrivningarOmklassificeringar.year'
                  ),
                  id(
                    'notes.note402.table.InventarierVerktygInstallationerForandringAvskrivningarAretsAvskrivningar.year'
                  ),
                  id(
                    'notes.note402.table.InventarierVerktygInstallationerForandringAvskrivningarOmrakningsdifferenser.year'
                  )
                ),
                0
              )
            ),
            {
              type: 'monetaryItemType',
              name: 'se-gen-base:InventarierVerktygInstallationerAvskrivningar',
              contextRef: 'balans0',
              standardRubrik:
                'Ackumulerade avskrivningar inventarier, verktyg och installationer',
              saldo: 'credit',
              negateValue: true,
            }
          ),
          ixbrlCell(
            ref(
              or(
                sumAllowEmpty(
                  id(
                    'notes.note402.table.InventarierVerktygInstallationerAvskrivningarIngaende.previousYear'
                  ),
                  id(
                    'notes.note402.table.InventarierVerktygInstallationerForandringAvskrivningarFusion.previousYear'
                  ),
                  id(
                    'notes.note402.table.InventarierVerktygInstallationerForandringAvskrivningarAterfordaAvskrivningarForsaljningarRespUtrangeringar.previousYear'
                  ),
                  id(
                    'notes.note402.table.InventarierVerktygInstallationerForandringAvskrivningarOmklassificeringar.previousYear'
                  ),
                  id(
                    'notes.note402.table.InventarierVerktygInstallationerForandringAvskrivningarAretsAvskrivningar.previousYear'
                  ),
                  id(
                    'notes.note402.table.InventarierVerktygInstallationerForandringAvskrivningarOmrakningsdifferenser.previousYear'
                  )
                ),
                0
              )
            ),
            {
              type: 'monetaryItemType',
              name: 'se-gen-base:InventarierVerktygInstallationerAvskrivningar',
              contextRef: 'balans1',
              standardRubrik:
                'Ackumulerade avskrivningar inventarier, verktyg och installationer',
              saldo: 'credit',
              negateValue: true,
            }
          )
        );
        // --- end row_sum_rows_in_table ---
        // --- begin row_sum_rows_in_table (level 2) [notes.note402.table.InventarierVerktygInstallationerUppskrivningarIngaende] ---
        // --- [769: rowEmptyValue(InventarierVerktygInstallationerUppskrivningar, nan, nan, InventarierVerktygInstallationerUppskrivningarIngaende, nan)] ---
        rows.addRow(
          'InventarierVerktygInstallationerUppskrivningarIngaende',
          noteLabel('Ingående uppskrivningar'),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base:InventarierVerktygInstallationerUppskrivningar',
            contextRef: 'balans0',
            standardRubrik:
              'Ackumulerade uppskrivningar inventarier, verktyg och installationer',
            saldo: 'debit',
            negateValue: false,
          }),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base:InventarierVerktygInstallationerUppskrivningar',
            contextRef: 'balans1',
            standardRubrik:
              'Ackumulerade uppskrivningar inventarier, verktyg och installationer',
            saldo: 'debit',
            negateValue: false,
          })
        );
        // --- end row_sum_rows_in_table ---
        // --- begin row_header (level 2) [notes.note402.table.InventarierVerktygInstallationerForandringUppskrivningarAbstract] ---
        // --- [770: rowHeader(InventarierVerktygInstallationerForandringUppskrivningarAbstract, nan, nan, nan, nan)] ---
        rows.addRow(
          'InventarierVerktygInstallationerForandringUppskrivningarAbstract',
          noteLabel('Förändringar av uppskrivningar'),
          undefined,
          undefined
        );
        // --- end row_header ---
        // --- begin row_sum_rows_in_table (level 2) [notes.note402.table.InventarierVerktygInstallationerForandringUppskrivningarFusion] ---
        // --- [771: rowEmptyValue(InventarierVerktygInstallationerForandringUppskrivningarFusion, nan, nan, nan, nan)] ---
        rows.addRow(
          'InventarierVerktygInstallationerForandringUppskrivningarFusion',
          noteLabel('Fusion'),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base:InventarierVerktygInstallationerForandringUppskrivningarFusion',
            contextRef: 'period0',
            standardRubrik:
              'Ökning (minskning) av uppskrivningar för inventarier, verktyg och installationer genom fusion',
            negateValue: false,
          }),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base:InventarierVerktygInstallationerForandringUppskrivningarFusion',
            contextRef: 'period1',
            standardRubrik:
              'Ökning (minskning) av uppskrivningar för inventarier, verktyg och installationer genom fusion',
            negateValue: false,
          })
        );
        // --- end row_sum_rows_in_table ---
        // --- begin row_sum_rows_in_table (level 2) [notes.note402.table.InventarierVerktygInstallationerForandringUppskrivningarForsaljningarUtrangeringar] ---
        // --- [772: rowEmptyValue(InventarierVerktygInstallationerForandringUppskrivningarForsaljningarUtrangeringar, nan, nan, nan, nan)] ---
        rows.addRow(
          'InventarierVerktygInstallationerForandringUppskrivningarForsaljningarUtrangeringar',
          noteLabel('Försäljningar/utrangeringar'),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base:InventarierVerktygInstallationerForandringUppskrivningarForsaljningarUtrangeringar',
            contextRef: 'period0',
            standardRubrik:
              'Återförda uppskrivningar i samband med försäljningar eller utrangeringar av inventarier, verktyg och installationer',
            negateValue: true,
          }),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base:InventarierVerktygInstallationerForandringUppskrivningarForsaljningarUtrangeringar',
            contextRef: 'period1',
            standardRubrik:
              'Återförda uppskrivningar i samband med försäljningar eller utrangeringar av inventarier, verktyg och installationer',
            negateValue: true,
          })
        );
        // --- end row_sum_rows_in_table ---
        // --- begin row_sum_rows_in_table (level 2) [notes.note402.table.InventarierVerktygInstallationerForandringUppskrivningarOmklassificeringar] ---
        // --- [773: rowEmptyValue(InventarierVerktygInstallationerForandringUppskrivningarOmklassificeringar, nan, nan, nan, nan)] ---
        rows.addRow(
          'InventarierVerktygInstallationerForandringUppskrivningarOmklassificeringar',
          noteLabel('Omklassificeringar'),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base:InventarierVerktygInstallationerForandringUppskrivningarOmklassificeringar',
            contextRef: 'period0',
            standardRubrik:
              'Ökning (minskning) av uppskrivningar i samband med omklassificeringar av inventarier, verktyg och installationer',
            negateValue: false,
          }),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base:InventarierVerktygInstallationerForandringUppskrivningarOmklassificeringar',
            contextRef: 'period1',
            standardRubrik:
              'Ökning (minskning) av uppskrivningar i samband med omklassificeringar av inventarier, verktyg och installationer',
            negateValue: false,
          })
        );
        // --- end row_sum_rows_in_table ---
        // --- begin row_sum_rows_in_table (level 2) [notes.note402.table.InventarierVerktygInstallationerForandringUppskrivningarAretsUppskrivningar] ---
        // --- [774: rowEmptyValue(InventarierVerktygInstallationerForandringUppskrivningarAretsUppskrivningar, nan, nan, nan, Ökning av uppskrivningar (+ in Agoy))] ---
        rows.addRow(
          'InventarierVerktygInstallationerForandringUppskrivningarAretsUppskrivningar',
          noteLabel('Årets uppskrivningar'),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base:InventarierVerktygInstallationerForandringUppskrivningarAretsUppskrivningar',
            contextRef: 'period0',
            standardRubrik:
              'Periodens uppskrivningar av inventarier, verktyg och installationer',
            negateValue: false,
          }),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base:InventarierVerktygInstallationerForandringUppskrivningarAretsUppskrivningar',
            contextRef: 'period1',
            standardRubrik:
              'Periodens uppskrivningar av inventarier, verktyg och installationer',
            negateValue: false,
          })
        );
        // --- end row_sum_rows_in_table ---
        // --- begin row_sum_rows_in_table (level 2) [notes.note402.table.InventarierVerktygInstallationerForandringUppskrivningarAretsNedskrivningarUppskrivetBelopp] ---
        // --- [775: rowEmptyValue(InventarierVerktygInstallationerForandringUppskrivningarAretsNedskrivningarUppskrivetBelopp, nan, nan, nan, Minskning av uppskrivning (- in Agoy))] ---
        rows.addRow(
          'InventarierVerktygInstallationerForandringUppskrivningarAretsNedskrivningarUppskrivetBelopp',
          noteLabel('Årets nedskrivningar på uppskrivet belopp'),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base:InventarierVerktygInstallationerForandringUppskrivningarAretsNedskrivningarUppskrivetBelopp',
            contextRef: 'period0',
            standardRubrik:
              'Nedskrivningar av uppskrivet belopp på inventarier, verktyg och installationer',
            negateValue: true,
          }),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base:InventarierVerktygInstallationerForandringUppskrivningarAretsNedskrivningarUppskrivetBelopp',
            contextRef: 'period1',
            standardRubrik:
              'Nedskrivningar av uppskrivet belopp på inventarier, verktyg och installationer',
            negateValue: true,
          })
        );
        // --- end row_sum_rows_in_table ---
        // --- begin row_sum_rows_in_table (level 2) [notes.note402.table.InventarierVerktygInstallationerForandringUppskrivningarAretsAvskrivningarUppskrivetBelopp] ---
        // --- [776: rowEmptyValue(InventarierVerktygInstallationerForandringUppskrivningarAretsAvskrivningarUppskrivetBelopp, nan, nan, nan, Minskning av uppskrivning (- in Agoy))] ---
        rows.addRow(
          'InventarierVerktygInstallationerForandringUppskrivningarAretsAvskrivningarUppskrivetBelopp',
          noteLabel('Årets avskrivningar på uppskrivet belopp'),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base:InventarierVerktygInstallationerForandringUppskrivningarAretsAvskrivningarUppskrivetBelopp',
            contextRef: 'period0',
            standardRubrik:
              'Avskrivningar av uppskrivet belopp på inventarier, verktyg och installationer',
            negateValue: true,
          }),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base:InventarierVerktygInstallationerForandringUppskrivningarAretsAvskrivningarUppskrivetBelopp',
            contextRef: 'period1',
            standardRubrik:
              'Avskrivningar av uppskrivet belopp på inventarier, verktyg och installationer',
            negateValue: true,
          })
        );
        // --- end row_sum_rows_in_table ---
        // --- begin row_sum_rows_in_table (level 2) [notes.note402.table.InventarierVerktygInstallationerForandringUppskrivningarOmrakningsdifferenser] ---
        // --- [777: rowEmptyValue(InventarierVerktygInstallationerForandringUppskrivningarOmrakningsdifferenser, nan, nan, nan, nan)] ---
        rows.addRow(
          'InventarierVerktygInstallationerForandringUppskrivningarOmrakningsdifferenser',
          noteLabel('Omräkningsdifferenser'),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base:InventarierVerktygInstallationerForandringUppskrivningarOmrakningsdifferenser',
            contextRef: 'period0',
            standardRubrik:
              'Ökning (minskning) av uppskrivningar i samband med omräkningsdifferenser av inventarier, verktyg och installationer',
            negateValue: false,
          }),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base:InventarierVerktygInstallationerForandringUppskrivningarOmrakningsdifferenser',
            contextRef: 'period1',
            standardRubrik:
              'Ökning (minskning) av uppskrivningar i samband med omräkningsdifferenser av inventarier, verktyg och installationer',
            negateValue: false,
          })
        );
        // --- end row_sum_rows_in_table ---
        // --- begin row_sum_rows_in_table (level 2) [notes.note402.table.InventarierVerktygInstallationerUppskrivningarUtgaende] ---
        // --- [778: rowSumRowsInTable(InventarierVerktygInstallationerUppskrivningar, nan, {"sum":["InventarierVerktygInstallationerUppskrivningarIngaende", "InventarierVerktygInstallationerForandringUppskrivningarFusion", "InventarierVerktygInstallationerForandringUppskrivningarForsaljningarUtrangeringar", "InventarierVerktygInstallationerForandringUppskrivningarOmklassificeringar", "InventarierVerktygInstallationerForandringUppskrivningarAretsUppskrivningar", "InventarierVerktygInstallationerForandringUppskrivningarAretsNedskrivningarUppskrivetBelopp", "InventarierVerktygInstallationerForandringUppskrivningarAretsAvskrivningarUppskrivetBelopp", "InventarierVerktygInstallationerForandringUppskrivningarOmrakningsdifferenser"]}, InventarierVerktygInstallationerUppskrivningarUtgaende, nan)] ---
        rows.addRow(
          'InventarierVerktygInstallationerUppskrivningarUtgaende',
          noteLabel('Utgående uppskrivningar'),
          ixbrlCell(
            ref(
              or(
                sumAllowEmpty(
                  id(
                    'notes.note402.table.InventarierVerktygInstallationerUppskrivningarIngaende.year'
                  ),
                  id(
                    'notes.note402.table.InventarierVerktygInstallationerForandringUppskrivningarFusion.year'
                  ),
                  id(
                    'notes.note402.table.InventarierVerktygInstallationerForandringUppskrivningarForsaljningarUtrangeringar.year'
                  ),
                  id(
                    'notes.note402.table.InventarierVerktygInstallationerForandringUppskrivningarOmklassificeringar.year'
                  ),
                  id(
                    'notes.note402.table.InventarierVerktygInstallationerForandringUppskrivningarAretsUppskrivningar.year'
                  ),
                  id(
                    'notes.note402.table.InventarierVerktygInstallationerForandringUppskrivningarAretsNedskrivningarUppskrivetBelopp.year'
                  ),
                  id(
                    'notes.note402.table.InventarierVerktygInstallationerForandringUppskrivningarAretsAvskrivningarUppskrivetBelopp.year'
                  ),
                  id(
                    'notes.note402.table.InventarierVerktygInstallationerForandringUppskrivningarOmrakningsdifferenser.year'
                  )
                ),
                0
              )
            ),
            {
              type: 'monetaryItemType',
              name: 'se-gen-base:InventarierVerktygInstallationerUppskrivningar',
              contextRef: 'balans0',
              standardRubrik:
                'Ackumulerade uppskrivningar inventarier, verktyg och installationer',
              saldo: 'debit',
              negateValue: false,
            }
          ),
          ixbrlCell(
            ref(
              or(
                sumAllowEmpty(
                  id(
                    'notes.note402.table.InventarierVerktygInstallationerUppskrivningarIngaende.previousYear'
                  ),
                  id(
                    'notes.note402.table.InventarierVerktygInstallationerForandringUppskrivningarFusion.previousYear'
                  ),
                  id(
                    'notes.note402.table.InventarierVerktygInstallationerForandringUppskrivningarForsaljningarUtrangeringar.previousYear'
                  ),
                  id(
                    'notes.note402.table.InventarierVerktygInstallationerForandringUppskrivningarOmklassificeringar.previousYear'
                  ),
                  id(
                    'notes.note402.table.InventarierVerktygInstallationerForandringUppskrivningarAretsUppskrivningar.previousYear'
                  ),
                  id(
                    'notes.note402.table.InventarierVerktygInstallationerForandringUppskrivningarAretsNedskrivningarUppskrivetBelopp.previousYear'
                  ),
                  id(
                    'notes.note402.table.InventarierVerktygInstallationerForandringUppskrivningarAretsAvskrivningarUppskrivetBelopp.previousYear'
                  ),
                  id(
                    'notes.note402.table.InventarierVerktygInstallationerForandringUppskrivningarOmrakningsdifferenser.previousYear'
                  )
                ),
                0
              )
            ),
            {
              type: 'monetaryItemType',
              name: 'se-gen-base:InventarierVerktygInstallationerUppskrivningar',
              contextRef: 'balans1',
              standardRubrik:
                'Ackumulerade uppskrivningar inventarier, verktyg och installationer',
              saldo: 'debit',
              negateValue: false,
            }
          )
        );
        // --- end row_sum_rows_in_table ---
        // --- begin row_sum_accounts (level 2) [notes.note402.table.InventarierVerktygInstallationerNedskrivningarIngaende] ---
        // --- [779: rowSumAccounts(InventarierVerktygInstallationerNedskrivningar, 1228+1238+1248+1258, {"period_base":1}, InventarierVerktygInstallationerNedskrivningarIngaende, nan)] ---
        rows.addRow(
          'InventarierVerktygInstallationerNedskrivningarIngaende',
          noteLabel('Ingående nedskrivningar'),
          ixbrlCell(ref(sumAccountsIBs('1228+1238+1248+1258', false)), {
            type: 'monetaryItemType',
            name: 'se-gen-base:InventarierVerktygInstallationerNedskrivningar',
            contextRef: 'balans1',
            standardRubrik:
              'Ackumulerade nedskrivningar inventarier, verktyg och installationer',
            saldo: 'credit',
            negateValue: true,
          }),
          ixbrlCell(
            ref(sumAccountsIBs('1228+1238+1248+1258', false, 'previousYear')),
            {
              type: 'monetaryItemType',
              name: 'se-gen-base:InventarierVerktygInstallationerNedskrivningar',
              contextRef: 'balans2',
              standardRubrik:
                'Ackumulerade nedskrivningar inventarier, verktyg och installationer',
              saldo: 'credit',
              negateValue: true,
            }
          )
        );
        // --- end row_sum_accounts ---
        // --- begin row_header (level 2) [notes.note402.table.InventarierVerktygInstallationerForandringNedskrivningarAbstract] ---
        // --- [780: rowHeader(InventarierVerktygInstallationerForandringNedskrivningarAbstract, nan, nan, nan, nan)] ---
        rows.addRow(
          'InventarierVerktygInstallationerForandringNedskrivningarAbstract',
          noteLabel('Förändringar av nedskrivningar'),
          undefined,
          undefined
        );
        // --- end row_header ---
        // --- begin row_sum_rows_in_table (level 2) [notes.note402.table.InventarierVerktygInstallationerForandringNedskrivningarFusion] ---
        // --- [781: rowEmptyValue(InventarierVerktygInstallationerForandringNedskrivningarFusion, nan, nan, nan, nan)] ---
        rows.addRow(
          'InventarierVerktygInstallationerForandringNedskrivningarFusion',
          noteLabel('Fusion'),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base:InventarierVerktygInstallationerForandringNedskrivningarFusion',
            contextRef: 'period0',
            standardRubrik:
              'Ökning (minskning) av nedskrivningar för inventarier, verktyg och installationer genom fusion',
            negateValue: true,
          }),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base:InventarierVerktygInstallationerForandringNedskrivningarFusion',
            contextRef: 'period1',
            standardRubrik:
              'Ökning (minskning) av nedskrivningar för inventarier, verktyg och installationer genom fusion',
            negateValue: true,
          })
        );
        // --- end row_sum_rows_in_table ---
        // --- begin row_sum_rows_in_table (level 2) [notes.note402.table.InventarierVerktygInstallationerForandringNedskrivningarAterfordaNedskrivningarForsaljningarRespUtrangeringar] ---
        // --- [782: rowEmptyValue(InventarierVerktygInstallationerForandringNedskrivningarAterfordaNedskrivningarForsaljningarRespUtrangeringar, nan, nan, nan, nan)] ---
        rows.addRow(
          'InventarierVerktygInstallationerForandringNedskrivningarAterfordaNedskrivningarForsaljningarRespUtrangeringar',
          noteLabel('Försäljningar/utrangeringar'),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base:InventarierVerktygInstallationerForandringNedskrivningarAterfordaNedskrivningarForsaljningarRespUtrangeringar',
            contextRef: 'period0',
            standardRubrik:
              'Återförda nedskrivningar i samband med försäljningar eller utrangeringar av inventarier, verktyg och installationer',
            negateValue: false,
          }),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base:InventarierVerktygInstallationerForandringNedskrivningarAterfordaNedskrivningarForsaljningarRespUtrangeringar',
            contextRef: 'period1',
            standardRubrik:
              'Återförda nedskrivningar i samband med försäljningar eller utrangeringar av inventarier, verktyg och installationer',
            negateValue: false,
          })
        );
        // --- end row_sum_rows_in_table ---
        // --- begin row_sum_rows_in_table (level 2) [notes.note402.table.InventarierVerktygInstallationerForandringNedskrivningarOmklassificeringar] ---
        // --- [783: rowEmptyValue(InventarierVerktygInstallationerForandringNedskrivningarOmklassificeringar, nan, nan, nan, nan)] ---
        rows.addRow(
          'InventarierVerktygInstallationerForandringNedskrivningarOmklassificeringar',
          noteLabel('Omklassificeringar'),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base:InventarierVerktygInstallationerForandringNedskrivningarOmklassificeringar',
            contextRef: 'period0',
            standardRubrik:
              'Minskning (ökning) av nedskrivningar i samband med omklassificeringar av inventarier, verktyg och installationer',
            negateValue: false,
          }),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base:InventarierVerktygInstallationerForandringNedskrivningarOmklassificeringar',
            contextRef: 'period1',
            standardRubrik:
              'Minskning (ökning) av nedskrivningar i samband med omklassificeringar av inventarier, verktyg och installationer',
            negateValue: false,
          })
        );
        // --- end row_sum_rows_in_table ---
        // --- begin row_sum_rows_in_table (level 2) [notes.note402.table.InventarierVerktygInstallationerForandringNedskrivningarAterfordaNedskrivningar] ---
        // --- [784: rowEmptyValue(InventarierVerktygInstallationerForandringNedskrivningarAterfordaNedskrivningar, nan, nan, nan, Minskning av nedskrivningar (+ in Agoy))] ---
        rows.addRow(
          'InventarierVerktygInstallationerForandringNedskrivningarAterfordaNedskrivningar',
          noteLabel('Återförda nedskrivningar'),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base:InventarierVerktygInstallationerForandringNedskrivningarAterfordaNedskrivningar',
            contextRef: 'period0',
            standardRubrik:
              'Periodens återförda nedskrivningar av inventarier, verktyg och installationer',
            negateValue: false,
          }),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base:InventarierVerktygInstallationerForandringNedskrivningarAterfordaNedskrivningar',
            contextRef: 'period1',
            standardRubrik:
              'Periodens återförda nedskrivningar av inventarier, verktyg och installationer',
            negateValue: false,
          })
        );
        // --- end row_sum_rows_in_table ---
        // --- begin row_sum_rows_in_table (level 2) [notes.note402.table.InventarierVerktygInstallationerForandringNedskrivningarAretsNedskrivningar] ---
        // --- [785: rowEmptyValue(InventarierVerktygInstallationerForandringNedskrivningarAretsNedskrivningar, nan, nan, nan, Ökning av nedskrivningar (- in Agoy))] ---
        rows.addRow(
          'InventarierVerktygInstallationerForandringNedskrivningarAretsNedskrivningar',
          noteLabel('Årets nedskrivningar'),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base:InventarierVerktygInstallationerForandringNedskrivningarAretsNedskrivningar',
            contextRef: 'period0',
            standardRubrik:
              'Periodens nedskrivningar av inventarier, verktyg och installationer',
            negateValue: true,
          }),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base:InventarierVerktygInstallationerForandringNedskrivningarAretsNedskrivningar',
            contextRef: 'period1',
            standardRubrik:
              'Periodens nedskrivningar av inventarier, verktyg och installationer',
            negateValue: true,
          })
        );
        // --- end row_sum_rows_in_table ---
        // --- begin row_sum_rows_in_table (level 2) [notes.note402.table.InventarierVerktygInstallationerForandringNedskrivningarOmrakningsdifferenser] ---
        // --- [786: rowEmptyValue(InventarierVerktygInstallationerForandringNedskrivningarOmrakningsdifferenser, nan, nan, nan, nan)] ---
        rows.addRow(
          'InventarierVerktygInstallationerForandringNedskrivningarOmrakningsdifferenser',
          noteLabel('Omräkningsdifferenser'),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base:InventarierVerktygInstallationerForandringNedskrivningarOmrakningsdifferenser',
            contextRef: 'period0',
            standardRubrik:
              'Minskning (ökning) av nedskrivningar i samband med omräkningsdifferenser av inventarier, verktyg och installationer',
            negateValue: false,
          }),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base:InventarierVerktygInstallationerForandringNedskrivningarOmrakningsdifferenser',
            contextRef: 'period1',
            standardRubrik:
              'Minskning (ökning) av nedskrivningar i samband med omräkningsdifferenser av inventarier, verktyg och installationer',
            negateValue: false,
          })
        );
        // --- end row_sum_rows_in_table ---
        // --- begin row_sum_rows_in_table (level 2) [notes.note402.table.InventarierVerktygInstallationerNedskrivningarUtgaende] ---
        // --- [787: rowSumRowsInTable(InventarierVerktygInstallationerNedskrivningar, nan, {"sum":["InventarierVerktygInstallationerNedskrivningarIngaende", "InventarierVerktygInstallationerForandringNedskrivningarFusion", "InventarierVerktygInstallationerForandringNedskrivningarAterfordaNedskrivningarForsaljningarRespUtrangeringar", "InventarierVerktygInstallationerForandringNedskrivningarOmklassificeringar", "InventarierVerktygInstallationerForandringNedskrivningarAterfordaNedskrivningar", "InventarierVerktygInstallationerForandringNedskrivningarAretsNedskrivningar", "InventarierVerktygInstallationerForandringNedskrivningarOmrakningsdifferenser"]}, InventarierVerktygInstallationerNedskrivningarUtgaende, nan)] ---
        rows.addRow(
          'InventarierVerktygInstallationerNedskrivningarUtgaende',
          noteLabel('Utgående nedskrivningar'),
          ixbrlCell(
            ref(
              or(
                sumAllowEmpty(
                  id(
                    'notes.note402.table.InventarierVerktygInstallationerNedskrivningarIngaende.year'
                  ),
                  id(
                    'notes.note402.table.InventarierVerktygInstallationerForandringNedskrivningarFusion.year'
                  ),
                  id(
                    'notes.note402.table.InventarierVerktygInstallationerForandringNedskrivningarAterfordaNedskrivningarForsaljningarRespUtrangeringar.year'
                  ),
                  id(
                    'notes.note402.table.InventarierVerktygInstallationerForandringNedskrivningarOmklassificeringar.year'
                  ),
                  id(
                    'notes.note402.table.InventarierVerktygInstallationerForandringNedskrivningarAterfordaNedskrivningar.year'
                  ),
                  id(
                    'notes.note402.table.InventarierVerktygInstallationerForandringNedskrivningarAretsNedskrivningar.year'
                  ),
                  id(
                    'notes.note402.table.InventarierVerktygInstallationerForandringNedskrivningarOmrakningsdifferenser.year'
                  )
                ),
                0
              )
            ),
            {
              type: 'monetaryItemType',
              name: 'se-gen-base:InventarierVerktygInstallationerNedskrivningar',
              contextRef: 'balans0',
              standardRubrik:
                'Ackumulerade nedskrivningar inventarier, verktyg och installationer',
              saldo: 'credit',
              negateValue: true,
            }
          ),
          ixbrlCell(
            ref(
              or(
                sumAllowEmpty(
                  id(
                    'notes.note402.table.InventarierVerktygInstallationerNedskrivningarIngaende.previousYear'
                  ),
                  id(
                    'notes.note402.table.InventarierVerktygInstallationerForandringNedskrivningarFusion.previousYear'
                  ),
                  id(
                    'notes.note402.table.InventarierVerktygInstallationerForandringNedskrivningarAterfordaNedskrivningarForsaljningarRespUtrangeringar.previousYear'
                  ),
                  id(
                    'notes.note402.table.InventarierVerktygInstallationerForandringNedskrivningarOmklassificeringar.previousYear'
                  ),
                  id(
                    'notes.note402.table.InventarierVerktygInstallationerForandringNedskrivningarAterfordaNedskrivningar.previousYear'
                  ),
                  id(
                    'notes.note402.table.InventarierVerktygInstallationerForandringNedskrivningarAretsNedskrivningar.previousYear'
                  ),
                  id(
                    'notes.note402.table.InventarierVerktygInstallationerForandringNedskrivningarOmrakningsdifferenser.previousYear'
                  )
                ),
                0
              )
            ),
            {
              type: 'monetaryItemType',
              name: 'se-gen-base:InventarierVerktygInstallationerNedskrivningar',
              contextRef: 'balans1',
              standardRubrik:
                'Ackumulerade nedskrivningar inventarier, verktyg och installationer',
              saldo: 'credit',
              negateValue: true,
            }
          )
        );
        // --- end row_sum_rows_in_table ---
        // --- begin row_sum_rows_in_table (level 2) [notes.note402.table.InventarierVerktygInstallationer] ---
        // --- [788: rowSumRowsInTable(InventarierVerktygInstallationer, nan, {"sum":["InventarierVerktygInstallationerAnskaffningsvardenUtgaende", "InventarierVerktygInstallationerAvskrivningarUtgaende", "InventarierVerktygInstallationerUppskrivningarUtgaende", "InventarierVerktygInstallationerNedskrivningarUtgaende"]}, nan, Ökning av inventar (+ in Agoy))] ---
        rows.addRow(
          'InventarierVerktygInstallationer',
          noteLabel('Redovisat värde'),
          ixbrlCell(
            ref(
              or(
                sumAllowEmpty(
                  id(
                    'notes.note402.table.InventarierVerktygInstallationerAnskaffningsvardenUtgaende.year'
                  ),
                  id(
                    'notes.note402.table.InventarierVerktygInstallationerAvskrivningarUtgaende.year'
                  ),
                  id(
                    'notes.note402.table.InventarierVerktygInstallationerUppskrivningarUtgaende.year'
                  ),
                  id(
                    'notes.note402.table.InventarierVerktygInstallationerNedskrivningarUtgaende.year'
                  )
                ),
                0
              )
            ),
            {
              type: 'monetaryItemType',
              name: 'se-gen-base:InventarierVerktygInstallationer',
              contextRef: 'balans0',
              standardRubrik: 'Inventarier, verktyg och installationer',
              saldo: 'debit',
              negateValue: false,
            }
          ),
          ixbrlCell(
            ref(
              or(
                sumAllowEmpty(
                  id(
                    'notes.note402.table.InventarierVerktygInstallationerAnskaffningsvardenUtgaende.previousYear'
                  ),
                  id(
                    'notes.note402.table.InventarierVerktygInstallationerAvskrivningarUtgaende.previousYear'
                  ),
                  id(
                    'notes.note402.table.InventarierVerktygInstallationerUppskrivningarUtgaende.previousYear'
                  ),
                  id(
                    'notes.note402.table.InventarierVerktygInstallationerNedskrivningarUtgaende.previousYear'
                  )
                ),
                0
              )
            ),
            {
              type: 'monetaryItemType',
              name: 'se-gen-base:InventarierVerktygInstallationer',
              contextRef: 'balans1',
              standardRubrik: 'Inventarier, verktyg och installationer',
              saldo: 'debit',
              negateValue: false,
            }
          )
        );
        // --- end row_sum_rows_in_table ---
        // --- begin row_sum_rows_in_table (level 2) [notes.note402.table.InventarierVerktygInstallationerOffentligaBidrag] ---
        // --- [789: rowEmptyValue(InventarierVerktygInstallationerOffentligaBidrag, nan, nan, nan, Minskning av inventarier (anskaffningsvärde) (- in Agoy))] ---
        rows.addRow(
          'InventarierVerktygInstallationerOffentligaBidrag',
          noteLabel('Offentliga bidrag som reducerat anskaffningsvärdet'),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base:InventarierVerktygInstallationerOffentligaBidrag',
            contextRef: 'balans0',
            standardRubrik:
              'Offentliga bidrag som reducerat anskaffningsvärdet på inventarier, verktyg och installationer',
            saldo: 'credit',
            negateValue: true,
          }),
          ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base:InventarierVerktygInstallationerOffentligaBidrag',
            contextRef: 'balans1',
            standardRubrik:
              'Offentliga bidrag som reducerat anskaffningsvärdet på inventarier, verktyg och installationer',
            saldo: 'credit',
            negateValue: true,
          })
        );
        // --- end row_sum_rows_in_table ---
        // --- begin end_table (level 1) [notes.note402.table] ---
        // --- [751: tableHeader(InventarierVerktygInstallationerAbstract, nan, nan, table, nan)] ---
        return rows.build();
      })
      .newRowTemplate(value(''), value(undefined), value(undefined))
      .build(),
    // --- end end_table ---
    // --- begin end_note_with_comment (level 1) [notes.note402.tableComment] ---
    // --- [790: endNoteWithComment(NotInventarierVerktygInstallationerKommentar, nan, nan, tableComment, nan)] ---
    commentLabel: field('Kommentar till not'),
    comment: ixbrlField(field(''), {
      type: 'stringItemType',
      name: 'se-gen-base:NotInventarierVerktygInstallationerKommentar',
      contextRef: 'period0',
      standardRubrik:
        'Kommentar till specifikation av inventarier, verktyg och installationer',
    }),
    // --- end end_note_with_comment ---
    // --- begin note_header (level 0) [notes.note402] ---
    // --- [750: beginNote(NotInventarierVerktygInstallationer, nan, {"noteTableSectionType":"NoteOneTableSection", "k2":"false", "k3":"documentTypeVersion !== '1'", "documentTypeVersion":1}, notes.note402, AGOY-6805)] ---
    group: value(undefined),
    section: value(undefined),
    validForReportType: {
      k2: false,
      k3: documentTypeVersion !== '1',
    },
  };
};
// --- begin note_header (level 0) ---
// --- [750: beginNote(NotInventarierVerktygInstallationer, nan, {"noteTableSectionType":"NoteOneTableSection", "k2":"false", "k3":"documentTypeVersion !== '1'", "documentTypeVersion":1}, notes.note402, AGOY-6805)] ---
export const Note402References = (): Record<string, string> => ({
  'InventarierVerktygInstallationerAnskaffningsvardenIngaende.year': id(
    'notes.note402.table.InventarierVerktygInstallationerAnskaffningsvardenIngaende.year'
  ),
  'InventarierVerktygInstallationerAnskaffningsvardenIngaende.previousYear': id(
    'notes.note402.table.InventarierVerktygInstallationerAnskaffningsvardenIngaende.previousYear'
  ),
  'InventarierVerktygInstallationerForandringAnskaffningsvardenInkop.year': id(
    'notes.note402.table.InventarierVerktygInstallationerForandringAnskaffningsvardenInkop.year'
  ),
  'InventarierVerktygInstallationerForandringAnskaffningsvardenInkop.previousYear':
    id(
      'notes.note402.table.InventarierVerktygInstallationerForandringAnskaffningsvardenInkop.previousYear'
    ),
  'InventarierVerktygInstallationerForandringAnskaffningsvardenRorelseforvarv.year':
    id(
      'notes.note402.table.InventarierVerktygInstallationerForandringAnskaffningsvardenRorelseforvarv.year'
    ),
  'InventarierVerktygInstallationerForandringAnskaffningsvardenRorelseforvarv.previousYear':
    id(
      'notes.note402.table.InventarierVerktygInstallationerForandringAnskaffningsvardenRorelseforvarv.previousYear'
    ),
  'InventarierVerktygInstallationerForandringAnskaffningsvardenFusion.year': id(
    'notes.note402.table.InventarierVerktygInstallationerForandringAnskaffningsvardenFusion.year'
  ),
  'InventarierVerktygInstallationerForandringAnskaffningsvardenFusion.previousYear':
    id(
      'notes.note402.table.InventarierVerktygInstallationerForandringAnskaffningsvardenFusion.previousYear'
    ),
  'InventarierVerktygInstallationerForandringAnskaffningsvardenForsaljningarUtrangeringar.year':
    id(
      'notes.note402.table.InventarierVerktygInstallationerForandringAnskaffningsvardenForsaljningarUtrangeringar.year'
    ),
  'InventarierVerktygInstallationerForandringAnskaffningsvardenForsaljningarUtrangeringar.previousYear':
    id(
      'notes.note402.table.InventarierVerktygInstallationerForandringAnskaffningsvardenForsaljningarUtrangeringar.previousYear'
    ),
  'InventarierVerktygInstallationerForandringAnskaffningsvardenOmklassificeringar.year':
    id(
      'notes.note402.table.InventarierVerktygInstallationerForandringAnskaffningsvardenOmklassificeringar.year'
    ),
  'InventarierVerktygInstallationerForandringAnskaffningsvardenOmklassificeringar.previousYear':
    id(
      'notes.note402.table.InventarierVerktygInstallationerForandringAnskaffningsvardenOmklassificeringar.previousYear'
    ),
  'InventarierVerktygInstallationerForandringAnskaffningsvardenOmrakningsdifferenser.year':
    id(
      'notes.note402.table.InventarierVerktygInstallationerForandringAnskaffningsvardenOmrakningsdifferenser.year'
    ),
  'InventarierVerktygInstallationerForandringAnskaffningsvardenOmrakningsdifferenser.previousYear':
    id(
      'notes.note402.table.InventarierVerktygInstallationerForandringAnskaffningsvardenOmrakningsdifferenser.previousYear'
    ),
  'InventarierVerktygInstallationerAnskaffningsvardenUtgaende.year': id(
    'notes.note402.table.InventarierVerktygInstallationerAnskaffningsvardenUtgaende.year'
  ),
  'InventarierVerktygInstallationerAnskaffningsvardenUtgaende.previousYear': id(
    'notes.note402.table.InventarierVerktygInstallationerAnskaffningsvardenUtgaende.previousYear'
  ),
  'InventarierVerktygInstallationerAvskrivningarIngaende.year': id(
    'notes.note402.table.InventarierVerktygInstallationerAvskrivningarIngaende.year'
  ),
  'InventarierVerktygInstallationerAvskrivningarIngaende.previousYear': id(
    'notes.note402.table.InventarierVerktygInstallationerAvskrivningarIngaende.previousYear'
  ),
  'InventarierVerktygInstallationerForandringAvskrivningarFusion.year': id(
    'notes.note402.table.InventarierVerktygInstallationerForandringAvskrivningarFusion.year'
  ),
  'InventarierVerktygInstallationerForandringAvskrivningarFusion.previousYear':
    id(
      'notes.note402.table.InventarierVerktygInstallationerForandringAvskrivningarFusion.previousYear'
    ),
  'InventarierVerktygInstallationerForandringAvskrivningarAterfordaAvskrivningarForsaljningarRespUtrangeringar.year':
    id(
      'notes.note402.table.InventarierVerktygInstallationerForandringAvskrivningarAterfordaAvskrivningarForsaljningarRespUtrangeringar.year'
    ),
  'InventarierVerktygInstallationerForandringAvskrivningarAterfordaAvskrivningarForsaljningarRespUtrangeringar.previousYear':
    id(
      'notes.note402.table.InventarierVerktygInstallationerForandringAvskrivningarAterfordaAvskrivningarForsaljningarRespUtrangeringar.previousYear'
    ),
  'InventarierVerktygInstallationerForandringAvskrivningarOmklassificeringar.year':
    id(
      'notes.note402.table.InventarierVerktygInstallationerForandringAvskrivningarOmklassificeringar.year'
    ),
  'InventarierVerktygInstallationerForandringAvskrivningarOmklassificeringar.previousYear':
    id(
      'notes.note402.table.InventarierVerktygInstallationerForandringAvskrivningarOmklassificeringar.previousYear'
    ),
  'InventarierVerktygInstallationerForandringAvskrivningarAretsAvskrivningar.year':
    id(
      'notes.note402.table.InventarierVerktygInstallationerForandringAvskrivningarAretsAvskrivningar.year'
    ),
  'InventarierVerktygInstallationerForandringAvskrivningarAretsAvskrivningar.previousYear':
    id(
      'notes.note402.table.InventarierVerktygInstallationerForandringAvskrivningarAretsAvskrivningar.previousYear'
    ),
  'InventarierVerktygInstallationerForandringAvskrivningarOmrakningsdifferenser.year':
    id(
      'notes.note402.table.InventarierVerktygInstallationerForandringAvskrivningarOmrakningsdifferenser.year'
    ),
  'InventarierVerktygInstallationerForandringAvskrivningarOmrakningsdifferenser.previousYear':
    id(
      'notes.note402.table.InventarierVerktygInstallationerForandringAvskrivningarOmrakningsdifferenser.previousYear'
    ),
  'InventarierVerktygInstallationerAvskrivningarUtgaende.year': id(
    'notes.note402.table.InventarierVerktygInstallationerAvskrivningarUtgaende.year'
  ),
  'InventarierVerktygInstallationerAvskrivningarUtgaende.previousYear': id(
    'notes.note402.table.InventarierVerktygInstallationerAvskrivningarUtgaende.previousYear'
  ),
  'InventarierVerktygInstallationerUppskrivningarIngaende.year': id(
    'notes.note402.table.InventarierVerktygInstallationerUppskrivningarIngaende.year'
  ),
  'InventarierVerktygInstallationerUppskrivningarIngaende.previousYear': id(
    'notes.note402.table.InventarierVerktygInstallationerUppskrivningarIngaende.previousYear'
  ),
  'InventarierVerktygInstallationerForandringUppskrivningarFusion.year': id(
    'notes.note402.table.InventarierVerktygInstallationerForandringUppskrivningarFusion.year'
  ),
  'InventarierVerktygInstallationerForandringUppskrivningarFusion.previousYear':
    id(
      'notes.note402.table.InventarierVerktygInstallationerForandringUppskrivningarFusion.previousYear'
    ),
  'InventarierVerktygInstallationerForandringUppskrivningarForsaljningarUtrangeringar.year':
    id(
      'notes.note402.table.InventarierVerktygInstallationerForandringUppskrivningarForsaljningarUtrangeringar.year'
    ),
  'InventarierVerktygInstallationerForandringUppskrivningarForsaljningarUtrangeringar.previousYear':
    id(
      'notes.note402.table.InventarierVerktygInstallationerForandringUppskrivningarForsaljningarUtrangeringar.previousYear'
    ),
  'InventarierVerktygInstallationerForandringUppskrivningarOmklassificeringar.year':
    id(
      'notes.note402.table.InventarierVerktygInstallationerForandringUppskrivningarOmklassificeringar.year'
    ),
  'InventarierVerktygInstallationerForandringUppskrivningarOmklassificeringar.previousYear':
    id(
      'notes.note402.table.InventarierVerktygInstallationerForandringUppskrivningarOmklassificeringar.previousYear'
    ),
  'InventarierVerktygInstallationerForandringUppskrivningarAretsUppskrivningar.year':
    id(
      'notes.note402.table.InventarierVerktygInstallationerForandringUppskrivningarAretsUppskrivningar.year'
    ),
  'InventarierVerktygInstallationerForandringUppskrivningarAretsUppskrivningar.previousYear':
    id(
      'notes.note402.table.InventarierVerktygInstallationerForandringUppskrivningarAretsUppskrivningar.previousYear'
    ),
  'InventarierVerktygInstallationerForandringUppskrivningarAretsNedskrivningarUppskrivetBelopp.year':
    id(
      'notes.note402.table.InventarierVerktygInstallationerForandringUppskrivningarAretsNedskrivningarUppskrivetBelopp.year'
    ),
  'InventarierVerktygInstallationerForandringUppskrivningarAretsNedskrivningarUppskrivetBelopp.previousYear':
    id(
      'notes.note402.table.InventarierVerktygInstallationerForandringUppskrivningarAretsNedskrivningarUppskrivetBelopp.previousYear'
    ),
  'InventarierVerktygInstallationerForandringUppskrivningarAretsAvskrivningarUppskrivetBelopp.year':
    id(
      'notes.note402.table.InventarierVerktygInstallationerForandringUppskrivningarAretsAvskrivningarUppskrivetBelopp.year'
    ),
  'InventarierVerktygInstallationerForandringUppskrivningarAretsAvskrivningarUppskrivetBelopp.previousYear':
    id(
      'notes.note402.table.InventarierVerktygInstallationerForandringUppskrivningarAretsAvskrivningarUppskrivetBelopp.previousYear'
    ),
  'InventarierVerktygInstallationerForandringUppskrivningarOmrakningsdifferenser.year':
    id(
      'notes.note402.table.InventarierVerktygInstallationerForandringUppskrivningarOmrakningsdifferenser.year'
    ),
  'InventarierVerktygInstallationerForandringUppskrivningarOmrakningsdifferenser.previousYear':
    id(
      'notes.note402.table.InventarierVerktygInstallationerForandringUppskrivningarOmrakningsdifferenser.previousYear'
    ),
  'InventarierVerktygInstallationerUppskrivningarUtgaende.year': id(
    'notes.note402.table.InventarierVerktygInstallationerUppskrivningarUtgaende.year'
  ),
  'InventarierVerktygInstallationerUppskrivningarUtgaende.previousYear': id(
    'notes.note402.table.InventarierVerktygInstallationerUppskrivningarUtgaende.previousYear'
  ),
  'InventarierVerktygInstallationerNedskrivningarIngaende.year': id(
    'notes.note402.table.InventarierVerktygInstallationerNedskrivningarIngaende.year'
  ),
  'InventarierVerktygInstallationerNedskrivningarIngaende.previousYear': id(
    'notes.note402.table.InventarierVerktygInstallationerNedskrivningarIngaende.previousYear'
  ),
  'InventarierVerktygInstallationerForandringNedskrivningarFusion.year': id(
    'notes.note402.table.InventarierVerktygInstallationerForandringNedskrivningarFusion.year'
  ),
  'InventarierVerktygInstallationerForandringNedskrivningarFusion.previousYear':
    id(
      'notes.note402.table.InventarierVerktygInstallationerForandringNedskrivningarFusion.previousYear'
    ),
  'InventarierVerktygInstallationerForandringNedskrivningarAterfordaNedskrivningarForsaljningarRespUtrangeringar.year':
    id(
      'notes.note402.table.InventarierVerktygInstallationerForandringNedskrivningarAterfordaNedskrivningarForsaljningarRespUtrangeringar.year'
    ),
  'InventarierVerktygInstallationerForandringNedskrivningarAterfordaNedskrivningarForsaljningarRespUtrangeringar.previousYear':
    id(
      'notes.note402.table.InventarierVerktygInstallationerForandringNedskrivningarAterfordaNedskrivningarForsaljningarRespUtrangeringar.previousYear'
    ),
  'InventarierVerktygInstallationerForandringNedskrivningarOmklassificeringar.year':
    id(
      'notes.note402.table.InventarierVerktygInstallationerForandringNedskrivningarOmklassificeringar.year'
    ),
  'InventarierVerktygInstallationerForandringNedskrivningarOmklassificeringar.previousYear':
    id(
      'notes.note402.table.InventarierVerktygInstallationerForandringNedskrivningarOmklassificeringar.previousYear'
    ),
  'InventarierVerktygInstallationerForandringNedskrivningarAterfordaNedskrivningar.year':
    id(
      'notes.note402.table.InventarierVerktygInstallationerForandringNedskrivningarAterfordaNedskrivningar.year'
    ),
  'InventarierVerktygInstallationerForandringNedskrivningarAterfordaNedskrivningar.previousYear':
    id(
      'notes.note402.table.InventarierVerktygInstallationerForandringNedskrivningarAterfordaNedskrivningar.previousYear'
    ),
  'InventarierVerktygInstallationerForandringNedskrivningarAretsNedskrivningar.year':
    id(
      'notes.note402.table.InventarierVerktygInstallationerForandringNedskrivningarAretsNedskrivningar.year'
    ),
  'InventarierVerktygInstallationerForandringNedskrivningarAretsNedskrivningar.previousYear':
    id(
      'notes.note402.table.InventarierVerktygInstallationerForandringNedskrivningarAretsNedskrivningar.previousYear'
    ),
  'InventarierVerktygInstallationerForandringNedskrivningarOmrakningsdifferenser.year':
    id(
      'notes.note402.table.InventarierVerktygInstallationerForandringNedskrivningarOmrakningsdifferenser.year'
    ),
  'InventarierVerktygInstallationerForandringNedskrivningarOmrakningsdifferenser.previousYear':
    id(
      'notes.note402.table.InventarierVerktygInstallationerForandringNedskrivningarOmrakningsdifferenser.previousYear'
    ),
  'InventarierVerktygInstallationerNedskrivningarUtgaende.year': id(
    'notes.note402.table.InventarierVerktygInstallationerNedskrivningarUtgaende.year'
  ),
  'InventarierVerktygInstallationerNedskrivningarUtgaende.previousYear': id(
    'notes.note402.table.InventarierVerktygInstallationerNedskrivningarUtgaende.previousYear'
  ),
  'InventarierVerktygInstallationer.year': id(
    'notes.note402.table.InventarierVerktygInstallationer.year'
  ),
  'InventarierVerktygInstallationer.previousYear': id(
    'notes.note402.table.InventarierVerktygInstallationer.previousYear'
  ),
  'InventarierVerktygInstallationerOffentligaBidrag.year': id(
    'notes.note402.table.InventarierVerktygInstallationerOffentligaBidrag.year'
  ),
  'InventarierVerktygInstallationerOffentligaBidrag.previousYear': id(
    'notes.note402.table.InventarierVerktygInstallationerOffentligaBidrag.previousYear'
  ),
  'tableComment.year': id('notes.note402.tableComment.year'),
  'tableComment.previousYear': id('notes.note402.tableComment.previousYear'),
});

import { last } from 'lodash';
import theme from 'theme';

import { RootState } from 'redux/reducers';
import { TimePeriod } from '@agoy/document';
import { reformatDate, ccyFormat } from '@agoy/common';
import { ReconciliationState } from '@agoy/api-sdk-core';
import { ReconciliationPeriod } from '@agoy/reconciliation';

/**
 * Returns the corresponding time period for a period type.
 */
export const periodTypeToRequestPeriod = (
  periodType: ReconciliationPeriod['type']
): 'month' | 'quarter' | 'financialYear' => {
  switch (periodType) {
    case 'month':
    case 'dead':
      return 'month';
    case 'quarter':
      return 'quarter';
    case 'financialYear':
    case 'yearEnd':
      return 'financialYear';

    default:
      return 'month';
  }
};

/**
 * Redux selector to pick the user input for a period and account
 *
 * @param period
 * @param accountNumber
 * @returns
 */
export const selectUserInputByPeriodAndAccount =
  (period: ReconciliationPeriod, accountNumber?: string) =>
  (state: RootState) => {
    const lastFinancialYear = last(period.financialYears);
    if (!lastFinancialYear) {
      return undefined;
    }
    const financialYear = TimePeriod.fromISODates(
      lastFinancialYear.start,
      lastFinancialYear.end
    ).value;
    const lastPeriod = last(period.periods);
    if (!lastPeriod) {
      return undefined;
    }

    return state.accountingView.clients[period.clientId]?.years[financialYear]
      ?.userInput?.[`account${accountNumber}`]?.[
      reformatDate(lastPeriod.start, 'yyyy-MM-dd', 'yyyy-MM')
    ];
  };

export const formatDate = (dateString: string): string => {
  return `${dateString.substring(0, 4)}-${dateString.substring(
    4,
    6
  )}-${dateString.substring(6, 8)}`;
};

export const isPercentageRow = (id: string) =>
  id === 'solidity' ||
  id === 'grossProfitMargin' ||
  id === 'refiPercent' ||
  id === 'refiPercentAgain';

export const formatValue = (value: number, isPercentage = false) =>
  isPercentage ? `${ccyFormat(100 * value, 1)}%` : ccyFormat(value);

export const getAccountStateColor = (state: ReconciliationState) => {
  switch (state) {
    case 'not_started':
      return theme.palette.grey[100];
    case 'started':
      return theme.palette.accountingView.cell.started;
    case 'error':
      return theme.palette.accountingView.cell.warning;
    case 'checked':
    case 'done':
      return theme.palette.accountingView.cell.ok;
    default:
      return theme.palette.grey[200];
  }
};

export const getAccountColor = (
  visited: boolean,
  correct: boolean | null
): string => {
  if (correct === false) {
    return theme.palette.accountingView.cell.warning;
  }

  if (visited) {
    if (correct === null) {
      return theme.palette.accountingView.cell.started;
    }
  }

  if (correct) {
    return theme.palette.accountingView.cell.ok;
  }

  return theme.palette.grey[100];
};

export const DocumentReferenceTypes = {
  account: 'A',
  group: 'G',
};

import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { TextField as MuiTextField, Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import EditField from '_shared/components/Inputs/EditField';
import isPropValid from '@emotion/is-prop-valid';
import DocumentViewServiceContext from '_shared/services/document/DocumentViewServiceContext';
import { Field, stringValue } from '@agoy/document';
import RegistrySourceInformation from '../../UI/RegistrySourceInformation';

const Content = styled.div`
  margin: ${({ theme }) => theme.spacing(2)}px 0;
`;

const TextFieldBase = (props) => {
  return <MuiTextField variant="outlined" size="small" {...props} />;
};

const TextField = styled(TextFieldBase, {
  shouldForwardProp: (prop) => isPropValid(prop),
})`
  width: 25%;
`;

interface LabeledFieldProps {
  field: Field;
  editing: boolean;
  disabled?: boolean;
}

const fullId = 'annualGeneralMeeting.main.location';

const LocationField = ({
  field,
  editing,
  disabled = false,
}: LabeledFieldProps): JSX.Element => {
  const service = useContext(DocumentViewServiceContext);
  const { formatMessage } = useIntl();

  const onFieldChange = (newValue) => {
    service.updateField(fullId, newValue, {
      id: 'city',
      type: 'user',
    });
  };
  const value = stringValue(field);

  return (
    <Content>
      <Typography>
        {formatMessage({
          id: `annualReport.annualGeneralMeeting.main.location`,
        })}
      </Typography>
      {typeof value === 'string' && (
        <RegistrySourceInformation editing={editing} field={field} id={fullId}>
          <EditField
            component={TextField}
            onChange={onFieldChange}
            value={value}
            disabled={disabled}
          />
        </RegistrySourceInformation>
      )}
    </Content>
  );
};

export default LocationField;

import React from 'react';
import styled from '@emotion/styled';

type Size = 'tiny' | 'small' | 'medium' | 'large';

const SIZES: Record<Size, number> = {
  tiny: 20,
  small: 30,
  medium: 60,
  large: 100,
};

export interface LoadingLogoProps {
  size?: Size;
  onDark?: boolean;
}

interface CircleAnimationProps {
  className?: string;
  size: Size;
  // eslint-disable-next-line react/no-unused-prop-types
  onDark?: boolean;
}

const SVGCircleAnimation = ({ className, size }: CircleAnimationProps) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width={`${SIZES[size]}px`}
    height={`${SIZES[size]}px`}
    viewBox="0 0 48 48"
  >
    <circle cx="24" cy="24" r="20" fill="none" strokeWidth="3" />
  </svg>
);

const StyledCircleAnimation = styled(SVGCircleAnimation)`
  animation: rotate 0.9s linear infinite;

  circle {
    stroke: ${(props) => (props.onDark ? 'white' : '#007533')};
    stroke-linecap: round;
    animation: dash 1s ease-in-out infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 30, 110;
      stroke-dashoffset: 0;
    }
    100% {
      stroke-dasharray: 30, 110;
      stroke-dashoffset: 0;
    }
  }
`;

const LoadingLogo = ({ size = 'small', onDark }: LoadingLogoProps) => {
  return <StyledCircleAnimation size={size} onDark={onDark} />;
};

export default LoadingLogo;

import {
  id,
  account,
  sum,
  or,
  multiply,
  label,
  ref,
  value,
  refs,
  ReferenceAccountInformation,
  TimePeriod,
  abs,
  AccountValueType,
} from '@agoy/document';
import { IxbrlCell, IxbrlDataDefinition } from '../../../common';
import { IncomeStatement } from '../../../common/types/incomeStatement';
import { getRowsFromAccountRanges } from '../../../common/utils/accountsUtil';
import {
  table,
  RowsBuilder,
  ixbrlCell,
  SectionRow,
  getLinkToNote,
} from '../../../common/utils/util';
import { AnnualReportType, AnnualReportVersion } from '../../../document';
import { getReferenceIdByValue, getWarningCell } from '../../settings/settings';

// Lookup for rowId 'incomes'
const addPostIncomesIxbrl = (
  rowId: string,
  contextRef: string
): IxbrlDataDefinition => {
  switch (rowId) {
    case '1':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:Nettoomsattning',
        contextRef,
        saldo: 'credit',
      };
    case '2':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:ForandringLagerProdukterIArbeteFardigaVarorPagaendeArbetenAnnansRakning',
        contextRef,
        saldo: 'credit',
      };
    case '3':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:AktiveratArbeteEgenRakning',
        contextRef,
        saldo: 'credit',
      };
    case '4':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:OvrigaRorelseintakter',
        contextRef,
        saldo: 'credit',
      };
    default:
      throw new Error(`Error in addPostIncomesIxbrl() for rowId: ${rowId}`);
  }
};

// Lookup for rowId 'expenses'
const addPostExpensesIxbrl = (
  rowId: string,
  contextRef: string
): IxbrlDataDefinition => {
  switch (rowId) {
    case '1':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:RavarorFornodenheterKostnader',
        contextRef,
        saldo: 'debit',
        negateValue: true,
      };
    case '2':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:HandelsvarorKostnader',
        contextRef,
        saldo: 'debit',
        negateValue: true,
      };
    case '3':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:OvrigaExternaKostnader',
        contextRef,
        saldo: 'debit',
        negateValue: true,
      };
    case '4':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:Personalkostnader',
        contextRef,
        saldo: 'debit',
        negateValue: true,
      };
    case '5':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:AvskrivningarNedskrivningarMateriellaImmateriellaAnlaggningstillgangar',
        contextRef,
        saldo: 'debit',
        negateValue: true,
      };
    case '6':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:NedskrivningarOmsattningstillgangarUtoverNormalaNedskrivningar',
        contextRef,
        saldo: 'debit',
        negateValue: true,
      };
    case '9':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:OvrigaRorelsekostnader',
        contextRef,
        saldo: 'debit',
        negateValue: true,
      };
    default:
      throw new Error(`Error in addPostExpensesIxbrl() for rowId: ${rowId}`);
  }
};

// Lookup for rowId 'resultsFromFinancial'
const addPostResultsFromFinancialIxbrl = (
  rowId: string,
  contextRef: string
): IxbrlDataDefinition => {
  switch (rowId) {
    case '1':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:ResultatAndelarKoncernforetag',
        contextRef,
        saldo: 'credit',
      };
    case '2':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:ResultatAndelarIntresseforetagGemensamtStyrda',
        contextRef,
        saldo: 'credit',
      };
    case '3':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:ResultatOvrigaforetagAgarintresse',
        contextRef,
        saldo: 'credit',
      };
    case '4':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:ResultatOvrigaFinansiellaAnlaggningstillgangar',
        contextRef,
        saldo: 'credit',
      };
    case '5':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:OvrigaRanteintakterLiknandeResultatposter',
        contextRef,
        saldo: 'credit',
      };
    case '6':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:NedskrivningarFinansiellaAnlaggningstillgangarKortfristigaPlaceringar',
        contextRef,
        saldo: 'debit',
        negateValue: true,
      };
    case '7':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:RantekostnaderLiknandeResultatposter',
        contextRef,
        saldo: 'debit',
        negateValue: true,
      };
    case '8':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:ResultatAndelarKoncernforetagInklNedskrivning',
        contextRef,
        saldo: 'credit',
      };
    case '9':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:ResultatAndelarIntresseforetagGemensamtStyrdaInklNedskrivning',
        contextRef,
        saldo: 'credit',
      };
    case '10':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:ResultatOvrigaforetagAgarintresseInklNedskrivning',
        contextRef,
        saldo: 'credit',
      };
    case '11':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:ResultatOvrigaFinansiellaAnlaggningstillgangarInklNedskrivning',
        contextRef,
        saldo: 'credit',
      };
    case '12':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:OvrigaRanteintakterLiknandeResultatposterInklNedskrivning',
        contextRef,
        saldo: 'credit',
      };
    case '13':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:RantekostnaderLiknandeResultatposter',
        contextRef,
        saldo: 'debit',
      };
    default:
      throw new Error(
        `Error in addPostResultsFromFinancialIxbrl() for rowId: ${rowId}`
      );
  }
};

// Lookup for rowId 'appropriations'
const addPostAppropriationsIxbrl = (
  rowId: string,
  contextRef: string
): IxbrlDataDefinition => {
  switch (rowId) {
    case '1':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:ErhallnaKoncernbidrag',
        contextRef,
        saldo: 'credit',
      };
    case '2':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:LamnadeKoncernbidrag',
        contextRef,
        saldo: 'debit',
        negateValue: true,
      };
    case '3':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:ForandringPeriodiseringsfond',
        contextRef,
        saldo: 'credit',
      };
    case '4':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:ForandringOveravskrivningar',
        contextRef,
        saldo: 'credit',
      };
    case '5':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:OvrigaBokslutsdispositioner',
        contextRef,
        saldo: 'credit',
      };
    case '6':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:ForandringErsattningsfond',
        contextRef,
        saldo: 'credit',
      };
    default:
      throw new Error(
        `Error in addPostAppropriationsIxbrl() for rowId: ${rowId}`
      );
  }
};

// Lookup for rowId 'taxes'
const addPostTaxesIxbrl = (
  rowId: string,
  contextRef: string
): IxbrlDataDefinition => {
  switch (rowId) {
    case '1':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:SkattAretsResultat',
        contextRef,
        saldo: 'debit',
        negateValue: true,
      };
    case '2':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:OvrigaSkatter',
        contextRef,
        saldo: 'debit',
        negateValue: true,
      };
    default:
      throw new Error(`Error in addPostTaxesIxbrl() for rowId: ${rowId}`);
  }
};

// Main lookup to find the rowName's lookup
const addPostIxbrl = (
  rowName: string,
  rowId: string,
  contextRef: string
): IxbrlDataDefinition | null => {
  switch (rowName) {
    case 'incomes':
      return addPostIncomesIxbrl(rowId, contextRef);
      break;
    case 'expenses':
      return addPostExpensesIxbrl(rowId, contextRef);
      break;
    case 'resultsFromFinancial':
      return addPostResultsFromFinancialIxbrl(rowId, contextRef);
      break;
    case 'appropriations':
      return addPostAppropriationsIxbrl(rowId, contextRef);
      break;
    case 'taxes':
      return addPostTaxesIxbrl(rowId, contextRef);
      break;
    case 'jointControlledA':
      return null;
      break;
    case 'jointControlledB':
      return null;
      break;
    case 'result':
      return null;
      break;
    default:
      throw new Error(`Error in addPostIxbrl() for rowName: ${rowName}`);
  }
};

// Lookup for sums, called in addGroup()
const addGroupIxbrl = (
  sumRowId: string,
  contextRef: string
): IxbrlDataDefinition => {
  switch (sumRowId) {
    case 'incomesSum':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:RorelseintakterLagerforandringarMm',
        contextRef,
        saldo: 'credit',
      };
    case 'expensesSum':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:Rorelsekostnader',
        contextRef,
        saldo: 'debit',
        negateValue: true,
      };
    case 'resultsFromFinancialSum':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:FinansiellaPoster',
        contextRef,
        saldo: 'credit',
      };
    case 'appropriationsSum':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:Bokslutsdispositioner',
        contextRef,
        saldo: 'credit',
      };
    case 'resultSum':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:AretsResultat',
        contextRef,
        saldo: 'credit',
        negateValue: true,
      };
    default:
      throw new Error(`Error in addGroupIxbrl() for sumName: ${sumRowId}`);
  }
};

const addPost =
  (
    accounts: Record<string, ReferenceAccountInformation>,
    rows: RowsBuilder,
    rowName: string,
    isDigitalSubmission: boolean,
    accountModifier: AccountValueType
  ) =>
  (
    rowId: string,
    name: string,
    accountNumbers: [number, number][],
    noteRef?: string
  ): RowsBuilder => {
    rows.addRowWithType(
      rowId,
      'row',
      value(name),
      noteRef ? refs(noteRef) : refs(), // notes can be linked here
      ixbrlCell(
        ref(or(sum(id(`${rows.getBaseId()}.${rowId}.*.year`)), 0)),

        addPostIxbrl(rowName, rowId, 'period0')
      ),
      ixbrlCell(
        ref(or(sum(id(`${rows.getBaseId()}.${rowId}.*.previousYear`)), 0)),

        addPostIxbrl(rowName, rowId, 'period1')
      )
    );
    rows.newRowTemplateGenerator(
      (id, baseId, { label: labelValue, ib, ub }) => ({
        id,
        type: 'account',
        active: true,
        cells: {
          label: label(labelValue),
          year: ub
            ? ref(multiply(-1, or(account(ub, accountModifier, 'year'), 0)))
            : value(0),
          previousYear: ib
            ? ref(
                multiply(
                  -1,
                  or(account(ib, accountModifier, 'previousYear'), 0)
                )
              )
            : value(0),
        },
        sortKey: ib ? parseInt(ib) : parseInt(ub),
      })
    );
    rows.addSubRows((rows) => {
      accountNumbers.forEach(([first, last]) =>
        getRowsFromAccountRanges(
          accounts,
          first,
          last,
          rows,
          true,
          'previousYear',
          accountModifier,
          true
        )
      );
      return rows.build();
    });

    return rows;
  };

// similar to addGroup below, but does not use addGroupIxbrl
// and the first row has refs for values and has ixbrlCell
const addJointlyControlledCompaniesSubrows = (
  rows: RowsBuilder,
  rowId: string,
  name: string,
  ixbrlName: string,
  subRowsBuilder: (rows: RowsBuilder) => void,
  sumName?: string
): void => {
  rows
    .addRowWithType(
      rowId,
      'row',
      value(name),
      refs(),
      ixbrlCell(ref(or(sum(id(`${rows.getBaseId()}.${rowId}.*.year`)), 0)), {
        type: 'monetaryItemType',
        name: `se-gen-base:${ixbrlName}`,
        contextRef: 'period0',
        saldo: 'credit',
      }),
      ixbrlCell(
        ref(or(sum(id(`${rows.getBaseId()}.${rowId}.*.previousYear`)), 0)),
        {
          type: 'monetaryItemType',
          name: `se-gen-base:${ixbrlName}`,
          contextRef: 'period1',
          saldo: 'credit',
        }
      ),
      ref(id(`${rows.getBaseId()}.${rowId}VisuallyHiddenSum.hidden`))
    )
    .addSubRows((rows) => {
      subRowsBuilder(rows);
      return rows.build();
    });

  {
    sumName === '' &&
      rows.addRowWithType(
        // the id below is a sign to hide this row visually
        `${rowId}VisuallyHiddenSum`,
        'hidden',
        value(sumName),
        refs(),
        // We dont need a ixbrlCell for these hidden rows, because they are not submitted to BV
        ref(
          or(
            sum(
              id(`${rows.getBaseId()}.${rowId}.*.year`),
              // id below is to account for manual inputs
              id(`${rows.getBaseId()}.${rowId}.year`)
            ),
            0
          )
        ),
        ref(
          or(
            sum(
              id(`${rows.getBaseId()}.${rowId}.*.previousYear`),
              // id below is to account for manual inputs
              id(`${rows.getBaseId()}.${rowId}.previousYear`)
            ),
            0
          )
        ),
        ref(
          sum(
            // The accounts with notes linked to them should not be hidden
            or(
              id(`${rows.getBaseId()}.${rowId}.*.notes-0`),
              id(`${rows.getBaseId()}.${rowId}VisuallyHiddenSum.notes-0`),
              0
            ),
            abs(id(`${rows.getBaseId()}.${rowId}VisuallyHiddenSum.year`)),
            abs(
              id(`${rows.getBaseId()}.${rowId}VisuallyHiddenSum.previousYear`)
            )
          )
        )
      );
  }
};

const addGroup = (
  rows: RowsBuilder,
  rowId: string,
  name: string,
  subRowsBuilder: (rows: RowsBuilder) => void,
  sumName?: string
): void => {
  rows
    .addRowWithType(
      rowId,
      'header',
      value(name),
      refs(),
      undefined,
      undefined,
      ref(id(`${rows.getBaseId()}.${rowId}Sum.hidden`))
    )
    .addSubRows((rows) => {
      subRowsBuilder(rows);
      return rows.build();
    });

  {
    sumName &&
      rows.addRowWithType(
        `${rowId}Sum`,
        'sum',
        value(sumName),
        refs(),
        // Sum of taxes is not available in digital submission
        sumName !== 'Summa skatter'
          ? ixbrlCell(
              ref(or(sum(id(`${rows.getBaseId()}.${rowId}.*.year`)), 0)),
              addGroupIxbrl(`${rowId}Sum`, 'period0')
            )
          : ref(or(sum(id(`${rows.getBaseId()}.${rowId}.*.year`)), 0)),
        sumName !== 'Summa skatter'
          ? ixbrlCell(
              ref(
                or(sum(id(`${rows.getBaseId()}.${rowId}.*.previousYear`)), 0)
              ),
              addGroupIxbrl(`${rowId}Sum`, 'period1')
            )
          : ref(or(sum(id(`${rows.getBaseId()}.${rowId}.*.previousYear`)), 0)),
        ref(
          sum(
            or(
              id(`${rows.getBaseId()}.${rowId}.*.notes-0`),
              id(`${rows.getBaseId()}.${rowId}Sum.notes-0`),
              0
            ),
            abs(id(`${rows.getBaseId()}.${rowId}Sum.year`)),
            abs(id(`${rows.getBaseId()}.${rowId}Sum.previousYear`))
          )
        )
      );
  }
};

type IncomeStatementSectionReference =
  | 'incomes'
  | 'expenses'
  | 'jointControlledA'
  | 'jointControlledB'
  | 'resultsFromFinancial'
  | 'appropriations'
  | 'taxes'
  | 'result'
  | 'unassignedAccounts';

export type SharesCompanyIncomeStatementStructure = {
  [key in IncomeStatementSectionReference]: SectionRow[];
};

export const sharesCompanyIncomeStatementAccounts = (
  reportType?: AnnualReportType
): SharesCompanyIncomeStatementStructure => {
  const getAppropriations = (): SectionRow[] => {
    const appropriations: SectionRow[] = [
      {
        row: '1',
        name: 'Erhållna koncernbidrag',
        accounts: [[8820, 8829]],
      },
      {
        row: '2',
        name: 'Lämnade koncernbidrag',
        accounts: [[8830, 8839]],
      },
      {
        row: '3',
        name: 'Förändring av periodiseringsfonder',
        accounts: [[8800, 8819]],
      },
      {
        row: '4',
        name: 'Förändring av överavskrivningar',
        accounts: [[8850, 8859]],
      },
      {
        row: '5',
        name: 'Övriga bokslutsdispositioner',
        accounts: [
          [8840, 8849],
          reportType === 'k2' ? [8860, 8899] : [8870, 8899],
        ],
      },
    ];

    if (reportType === 'k3') {
      appropriations.push({
        row: '6',
        name: 'Förändring av ersättningsfonder',
        accounts: [[8860, 8869]],
      });
    }

    return appropriations;
  };

  return {
    incomes: [
      {
        row: '1',
        name: 'Nettoomsättning',
        accounts: [[3000, 3799]],
      },
      {
        row: '2',
        name: 'Förändringar av lager av produkter i arbete, färdiga varor och pågående arbeten för annans räkning',
        accounts: [[4900, 4999]],
      },
      {
        row: '3',
        name: 'Aktiverat arbete för egen räkning',
        accounts: [[3800, 3899]],
      },
      {
        row: '4',
        name: 'Övriga rörelseintäkter',
        accounts: [[3900, 3999]],
        noteNumber: 61,
      },
    ],
    expenses: [
      {
        row: '1',
        name: 'Råvaror och förnödenheter',
        accounts: [[4000, 4899]],
      },
      {
        row: '2',
        name: 'Handelsvaror',
        accounts: [],
      },
      {
        row: '3',
        name: 'Övriga externa kostnader',
        accounts: [[5000, 6999]],
        noteNumber: 63,
        noteAccountsOverride: [[6420, 6429]],
      },
      {
        row: '4',
        name: 'Personalkostnader',
        accounts: [[7000, 7699]],
        noteNumber: 2,
      },
      {
        row: '5',
        name: 'Avskrivningar och nedskrivningar av materiella och immateriella anläggningstillgångar',
        accounts: [[7800, 7899]],
      },
      {
        row: '6',
        name: 'Nedskrivningar av omsättningstillgångar utöver normala nedskrivningar',
        accounts: [[7700, 7799]],
      },
      {
        row: '9',
        name: 'Övriga rörelsekostnader',
        accounts: [[7900, 7999]],
        noteNumber: 62,
      },
    ],
    jointControlledA: [
      {
        row: 'VisuallyHidden',
        name: '',
        accounts: [[8111, 8112]],
      },
    ],
    jointControlledB: [
      {
        row: 'VisuallyHidden',
        name: '',
        accounts: [[8121, 8122]],
      },
    ],
    resultsFromFinancial: [
      {
        row: '1',
        name: 'Resultat från andelar i koncernföretag',
        accounts: [[8000, 8069]],
      },
      {
        row: '2',
        name: 'Resultat från andelar i intresseföretag och gemensamt styrda företag',
        accounts: [
          [8100, reportType === 'k2' ? 8112 : 8110],
          [8114, 8117],
          [8119, reportType === 'k2' ? 8122 : 8120],
          [8124, 8169],
        ],
      },
      {
        row: '3',
        name: 'Resultat från övriga företag som det finns ett ägarintresse i',
        accounts: [
          [8113, 8113],
          [8118, 8118],
          [8123, 8123],
        ],
      },
      {
        row: '4',
        name:
          reportType === 'k3'
            ? 'Resultat från övriga värdepapper och fordringar som är anläggningstillgångar'
            : 'Resultat från övriga finansiella anläggningstillgångar',
        accounts: [[8200, 8269]],
      },
      {
        row: '5',
        name: 'Övriga ränteintäkter och liknande resultatposter',
        accounts: [[8300, 8399]],
      },
      {
        row: '6',
        name: 'Nedskrivningar av finansiella anläggningstillgångar och kortfristiga placeringar',
        accounts: [
          [8070, 8099],
          [8170, 8199],
          [8270, 8299],
        ],
      },
      {
        row: '7',
        name: 'Räntekostnader och liknande resultatposter',
        accounts: [[8400, 8799]],
      },
      {
        row: '8',
        name: 'Resultat från andelar i koncernföretag',
        accounts: [],
      },
      {
        row: '9',
        name: 'Resultat från andelar i intresseföretag och gemensamt styrda företag',
        accounts: [],
      },
      {
        row: '10',
        name: 'Resultat från övriga företag som det finns ett ägarintresse i',
        accounts: [],
      },
      {
        row: '11',
        name: 'Resultat från övriga värdepapper och fordringar som är anläggningstillgångar',
        accounts: [],
      },
      {
        row: '12',
        name: 'Övriga ränteintäkter och liknande resultatposter',
        accounts: [],
      },
      {
        row: '13',
        name: 'Räntekostnader och liknande resultatposter',
        accounts: [],
      },
    ],
    appropriations: getAppropriations(),
    taxes: [
      {
        row: '1',
        name: 'Skatt på årets resultat',
        accounts: [[8900, 8919]],
      },
      {
        row: '2',
        name: 'Övriga skatter',
        accounts: [[8920, 8989]],
      },
    ],
    result: [
      {
        row: '1',
        name: 'Resultat',
        accounts: [[8990, 8999]],
      },
    ],
    unassignedAccounts: [],
  };
};

const attachRows = (
  accounts: Record<string, ReferenceAccountInformation>,
  rows: RowsBuilder,
  isDigitalSubmission: boolean,
  structureKey: keyof SharesCompanyIncomeStatementStructure,
  accountModifier: AccountValueType,
  reportType?: AnnualReportType,
  documentTypeVersion: AnnualReportVersion = '1'
) => {
  const add = addPost(
    accounts,
    rows,
    structureKey,
    isDigitalSubmission,
    accountModifier
  );
  sharesCompanyIncomeStatementAccounts(reportType)[structureKey].map(
    (section) => {
      const link = getLinkToNote(
        section,
        accounts,
        isDigitalSubmission,
        reportType,
        documentTypeVersion
      );
      add(section.row, section.name, section.accounts, link);
    }
  );
};

const addIncomes = (
  accounts: Record<string, ReferenceAccountInformation>,
  rows: RowsBuilder,
  isDigitalSubmission: boolean,
  type: AnnualReportType,
  accountModifier: AccountValueType,
  documentTypeVersion: AnnualReportVersion = '1'
): void => {
  addGroup(
    rows,
    'incomes',
    type === 'k3'
      ? 'Rörelsens intäkter, lagerförändringar m.m.'
      : 'Rörelseintäkter, lagerförändringar m.m.',
    (rows) =>
      attachRows(
        accounts,
        rows,
        isDigitalSubmission,
        'incomes',
        accountModifier,
        type,
        documentTypeVersion
      ),
    type === 'k3'
      ? 'Summa rörelsens intäkter, lagerförändringar m.m.'
      : 'Summa rörelseintäkter, lagerförändringar m.m.'
  );
};

const addExpenses = (
  accounts: Record<string, ReferenceAccountInformation>,
  rows: RowsBuilder,
  isDigitalSubmission: boolean,
  type: AnnualReportType,
  accountModifier: AccountValueType,
  documentTypeVersion: AnnualReportVersion = '1'
): void => {
  addGroup(
    rows,
    'expenses',
    type === 'k3' ? 'Rörelsens kostnader' : 'Rörelsekostnader',
    (rows) =>
      attachRows(
        accounts,
        rows,
        isDigitalSubmission,
        'expenses',
        accountModifier,
        type,
        documentTypeVersion
      ),
    type === 'k3' ? 'Summa rörelsens kostnader' : 'Summa rörelsekostnader'
  );
};

const addJointControlledCompaniesA = (
  accounts: Record<string, ReferenceAccountInformation>,
  rows: RowsBuilder,
  isDigitalSubmission: boolean,
  accountModifier: AccountValueType
): void => {
  addJointlyControlledCompaniesSubrows(
    rows,
    'jointControlledA',
    'Andelar i intresseföretags och gemensamt styrda företags resultat',
    'AndelarIntresseforetagGemensamtStyrdaForetagResultat',
    (rows) =>
      attachRows(
        accounts,
        rows,
        isDigitalSubmission,
        'jointControlledA',
        accountModifier
      ),
    ''
  );
};

const addJointControlledCompaniesB = (
  accounts: Record<string, ReferenceAccountInformation>,
  rows: RowsBuilder,
  isDigitalSubmission: boolean,
  accountModifier: AccountValueType
): void => {
  addJointlyControlledCompaniesSubrows(
    rows,
    'jointControlledB',
    'Realisationsresultat vid försäljning av intresseföretag och gemensamt styrda företag',
    'RealisationsresultatForsaljningIntresseforetagGemensamtStyrdaForetag',
    (rows) =>
      attachRows(
        accounts,
        rows,
        isDigitalSubmission,
        'jointControlledB',
        accountModifier
      ),
    ''
  );
};

const addResultsFromFinancial = (
  accounts: Record<string, ReferenceAccountInformation>,
  rows: RowsBuilder,
  isDigitalSubmission: boolean,
  type: AnnualReportType,
  accountModifier: AccountValueType
): void => {
  addGroup(
    rows,
    'resultsFromFinancial',
    type === 'k3' ? 'Resultat från finansiella poster' : 'Finansiella poster',
    (rows) => {
      const add = addPost(
        accounts,
        rows,
        'resultsFromFinancial',
        isDigitalSubmission,
        accountModifier
      );
      if (type === 'k3') {
        rows.addRowWithType(
          'subtitleFinansiellaPoster1',
          'header',
          value(
            'Resultat från finansiella poster - med nedskrivningar i egen post'
          )
        );
      }
      const structure =
        sharesCompanyIncomeStatementAccounts(type)['resultsFromFinancial'];
      // we split the structure into two sections because we want to insert
      // a row in between them in case type === 'k3'
      const firstGroup = structure.filter((s) => Number(s.row) < 8);
      const secondGroup = structure.filter((s) => Number(s.row) >= 8);

      firstGroup.map((range) => add(range.row, range.name, range.accounts));
      if (type === 'k3') {
        const key = 'subtitleFinansiellaPoster2';
        const label =
          'Resultat från finansiella poster - med nedskrivningar i respektive post';
        rows.addRowWithType(key, 'header', value(label));
        secondGroup.map((range) => add(range.row, range.name, range.accounts));
      }
    },
    type === 'k3'
      ? 'Summa resultat från finansiella poster'
      : 'Summa finansiella poster'
  );
};

const addAppropriations = (
  accounts: Record<string, ReferenceAccountInformation>,
  rows: RowsBuilder,
  isDigitalSubmission: boolean,
  type: AnnualReportType,
  accountModifier: AccountValueType,
  documentTypeVersion: AnnualReportVersion = '1'
): void => {
  addGroup(
    rows,
    'appropriations',
    'Bokslutsdispositioner',
    (rows) =>
      attachRows(
        accounts,
        rows,
        isDigitalSubmission,
        'appropriations',
        accountModifier,
        type,
        documentTypeVersion
      ),
    'Summa bokslutsdispositioner'
  );
};

const addTaxes = (
  accounts: Record<string, ReferenceAccountInformation>,
  rows: RowsBuilder,
  isDigitalSubmission: boolean,
  accountModifier: AccountValueType,
  type: AnnualReportType,
  documentTypeVersion: AnnualReportVersion = '1'
): void => {
  addGroup(
    rows,
    'taxes',
    'Skatter',
    (rows) =>
      attachRows(
        accounts,
        rows,
        isDigitalSubmission,
        'taxes',
        accountModifier,
        type,
        documentTypeVersion
      ),
    isDigitalSubmission ? undefined : 'Summa skatter'
  );
};

const addResults = (
  accounts: Record<string, ReferenceAccountInformation>,
  rows: RowsBuilder,
  isDigitalSubmission: boolean,
  accountModifier: AccountValueType
): void => {
  const references = incomeStatementReferences();

  // 5564 TODO: refactor this so that the account range is shown
  // digital submission tagging below should still be done
  addGroup(rows, 'result', 'Årets resultat', (rows) => {
    const warningCellIdYear = getReferenceIdByValue(
      references,
      `${rows.getBaseId()}.result.year`
    );

    const warningCellIdPreviousYear = getReferenceIdByValue(
      references,
      `${rows.getBaseId()}.result.previousYear`
    );

    rows.addRowWithType(
      'result',
      'row',
      value('Resultat'),
      refs(),
      ixbrlCell(ref(or(sum(account('8990:8999', accountModifier)), 0)), {
        type: 'monetaryItemType',
        name: 'se-gen-base:AretsResultat',
        contextRef: 'period0',
        saldo: 'credit',
      }),
      ixbrlCell(
        ref(or(sum(account('8990:8999', accountModifier, 'previousYear')), 0)),
        {
          type: 'monetaryItemType',
          name: 'se-gen-base:AretsResultat',
          contextRef: 'period1',
          saldo: 'credit',
        }
      ),
      undefined,
      getWarningCell(warningCellIdYear || `${rows.getBaseId()}.result.year`),
      getWarningCell(
        warningCellIdPreviousYear || `${rows.getBaseId()}.result.previousYear`
      )
    );

    rows.addSubRows((rows) => {
      getRowsFromAccountRanges(
        accounts,
        8990,
        8999,
        rows,
        true,
        'previousYear',
        accountModifier,
        true
      );
      return rows.build();
    });
  });
};

/**
 *
 *********** CONFIG ***********
 *
 */

export const incomeStatementConfig = (
  accounts: Record<string, ReferenceAccountInformation>,
  period: TimePeriod,
  previousPeriod: TimePeriod | null,
  type: AnnualReportType,
  isDigitalSubmission: boolean | null,
  documentTypeVersion: AnnualReportVersion = '1',
  accountModifier: AccountValueType = 'ub'
): IncomeStatement => ({
  active: undefined,
  section: {
    active: undefined,
    table: table<IxbrlCell>(
      'incomeStatement.section.table',
      'label',
      'notes',
      { id: 'year', label: `${period.startDateISO} ${period.endDateISO}` },
      previousPeriod
        ? {
            id: 'previousYear',
            label: `${previousPeriod.startDateISO} ${previousPeriod.endDateISO}`,
          }
        : undefined,
      'hidden',
      'yearWarning',
      'previousYearWarning'
    )
      .addRows((rows) =>
        rows
          .addRowWithType('income', 'hidden')
          .addSubRows((rows) => {
            rows.addRowWithType('main', 'hidden').addSubRows((rows) => {
              addIncomes(
                accounts,
                rows,
                !!isDigitalSubmission,
                type,
                accountModifier,
                documentTypeVersion
              );
              addExpenses(
                accounts,
                rows,
                !!isDigitalSubmission,
                type,
                accountModifier,
                documentTypeVersion
              );
              if (type === 'k3') {
                addJointControlledCompaniesA(
                  accounts,
                  rows,
                  !!isDigitalSubmission,
                  accountModifier
                );
                addJointControlledCompaniesB(
                  accounts,
                  rows,
                  !!isDigitalSubmission,
                  accountModifier
                );
              }
              rows.addRowWithType(
                'sumOperatingProfit',
                'sum',
                value('Rörelseresultat'),
                refs(),
                ixbrlCell(
                  ref(
                    sum(
                      or(id(`${rows.getBaseId()}.incomesSum.year`), 0),
                      or(id(`${rows.getBaseId()}.expensesSum.year`), 0),
                      // to account for values from accounds added here
                      or(
                        id(`${rows.getBaseId()}.jointControlledA.*Sum.year`),
                        0
                      ),
                      or(
                        id(`${rows.getBaseId()}.jointControlledB.*Sum.year`),
                        0
                      ),
                      // to account for manually added values
                      or(id(`${rows.getBaseId()}.jointControlledA.year`), 0),
                      or(id(`${rows.getBaseId()}.jointControlledB.year`), 0)
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    name: 'se-gen-base:Rorelseresultat',
                    contextRef: 'period0',
                    saldo: 'credit',
                  }
                ),
                ixbrlCell(
                  ref(
                    sum(
                      or(id(`${rows.getBaseId()}.incomesSum.previousYear`), 0),
                      or(id(`${rows.getBaseId()}.expensesSum.previousYear`), 0),
                      // to account for values from accounds added here
                      or(
                        id(
                          `${rows.getBaseId()}.jointControlledA.*Sum.previousYear`
                        ),
                        0
                      ),
                      or(
                        id(
                          `${rows.getBaseId()}.jointControlledB.*Sum.previousYear`
                        ),
                        0
                      ),
                      // to account for manually added values
                      or(
                        id(`${rows.getBaseId()}.jointControlledA.previousYear`),
                        0
                      ),
                      or(
                        id(`${rows.getBaseId()}.jointControlledB.previousYear`),
                        0
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    name: 'se-gen-base:Rorelseresultat',
                    contextRef: 'period1',
                    saldo: 'credit',
                  }
                )
              );

              addResultsFromFinancial(
                accounts,
                rows,
                !!isDigitalSubmission,
                type,
                accountModifier
              );

              rows.addRowWithType(
                'resultAfterFinancial',
                'sum',
                value('Resultat efter finansiella poster'),
                refs(),
                ixbrlCell(
                  ref(
                    sum(
                      id(`${rows.getBaseId()}.sumOperatingProfit.year`),
                      id(`${rows.getBaseId()}.resultsFromFinancialSum.year`)
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    name: 'se-gen-base:ResultatEfterFinansiellaPoster',
                    contextRef: 'period0',
                    saldo: 'credit',
                  }
                ),
                ixbrlCell(
                  ref(
                    sum(
                      id(`${rows.getBaseId()}.sumOperatingProfit.previousYear`),
                      id(
                        `${rows.getBaseId()}.resultsFromFinancialSum.previousYear`
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    name: 'se-gen-base:ResultatEfterFinansiellaPoster',
                    contextRef: 'period1',
                    saldo: 'credit',
                  }
                )
              );

              addAppropriations(
                accounts,
                rows,
                !!isDigitalSubmission,
                type,
                accountModifier,
                documentTypeVersion
              );

              rows.addRowWithType(
                'resultBeforeTaxes',
                'sum',
                value('Resultat före skatt'),
                refs(),
                ixbrlCell(
                  ref(
                    sum(
                      id(`${rows.getBaseId()}.resultAfterFinancial.year`),
                      id(`${rows.getBaseId()}.appropriationsSum.year`)
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    name: 'se-gen-base:ResultatForeSkatt',
                    contextRef: 'period0',
                    saldo: 'credit',
                  }
                ),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        `${rows.getBaseId()}.resultAfterFinancial.previousYear`
                      ),
                      id(`${rows.getBaseId()}.appropriationsSum.previousYear`)
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    name: 'se-gen-base:ResultatForeSkatt',
                    contextRef: 'period1',
                    saldo: 'credit',
                  }
                )
              );

              addTaxes(
                accounts,
                rows,
                !!isDigitalSubmission,
                accountModifier,
                type,
                documentTypeVersion
              );

              addResults(
                accounts,
                rows,
                !!isDigitalSubmission,
                accountModifier
              );

              return rows.build();
            });
            return rows.build();
          })
          .build()
      )
      .build(),
  },
});

export const incomeStatementReferences = (): Record<string, string> => ({
  'incomeStatement.Nettoomsattning.year': id(
    'incomeStatement.section.table.income.main.incomes.1.year'
  ),
  'incomeStatement.Nettoomsattning.previousYear': id(
    'incomeStatement.section.table.income.main.incomes.1.previousYear'
  ),
  'incomeStatement.ResultatEfterFinansiellaPoster.year': id(
    'incomeStatement.section.table.income.main.resultAfterFinancial.year'
  ),
  'incomeStatement.ResultatEfterFinansiellaPoster.previousYear': id(
    'incomeStatement.section.table.income.main.resultAfterFinancial.previousYear'
  ),
  'incomeStatement.ResultatForeSkatt.year': id(
    'incomeStatement.section.table.income.main.resultBeforeTaxes.year'
  ),
  'incomeStatement.ResultatForeSkatt.previousYear': id(
    'incomeStatement.section.table.income.main.resultBeforeTaxes.previousYear'
  ),
  'incomeStatement.SkattAretsResultat.year': id(
    'incomeStatement.section.table.income.main.taxes.1.year'
  ),
  'incomeStatement.SkattAretsResultat.previousYear': id(
    'incomeStatement.section.table.income.main.taxes.1.previousYear'
  ),
  'incomeStatement.OvrigaSkatter.year': id(
    'incomeStatement.section.table.income.main.taxes.2.year'
  ),
  'incomeStatement.OvrigaSkatter.previousYear': id(
    'incomeStatement.section.table.income.main.taxes.2.previousYear'
  ),
  'incomeStatement.AretsResultat.year': id(
    'incomeStatement.section.table.income.main.result.result.year'
  ),
  'incomeStatement.AretsResultat.previousYear': id(
    'incomeStatement.section.table.income.main.result.result.previousYear'
  ),
  'incomeStatement.ForandringLagerProdukterIArbeteFardigaVarorPagaendeArbetenAnnansRakning.year':
    id('incomeStatement.section.table.income.main.incomes.2.year'),
  'incomeStatement.AktiveratArbeteEgenRakning.year': id(
    'incomeStatement.section.table.income.main.incomes.3.year'
  ),
  'incomeStatement.OvrigaRorelseintakter.year': id(
    'incomeStatement.section.table.income.main.incomes.4.year'
  ),
  'incomeStatement.RavarorFornodenheterKostnader.year': id(
    'incomeStatement.section.table.income.main.expenses.1.year'
  ),
  'incomeStatement.HandelsvarorKostnader.year': id(
    'incomeStatement.section.table.income.main.expenses.2.year'
  ),
  'incomeStatement.OvrigaExternaKostnader.year': id(
    'incomeStatement.section.table.income.main.expenses.3.year'
  ),
  'incomeStatement.Personalkostnader.year': id(
    'incomeStatement.section.table.income.main.expenses.4.year'
  ),
  'incomeStatement.AvskrivningarNedskrivningarMateriellaImmateriellaAnlaggningstillgangar.year':
    id('incomeStatement.section.table.income.main.expenses.5.year'),
  'incomeStatement.NedskrivningarOmsattningstillgangarUtoverNormalaNedskrivningar.year':
    id('incomeStatement.section.table.income.main.expenses.6.year'),
  'incomeStatement.OvrigaRorelsekostnader.year': id(
    'incomeStatement.section.table.income.main.expenses.9.year'
  ),
  'incomeStatement.ResultatAndelarKoncernforetag.year': id(
    'incomeStatement.section.table.income.main.resultsFromFinancial.1.year'
  ),
  'incomeStatement.ResultatAndelarIntresseforetagGemensamtStyrda.year': id(
    'incomeStatement.section.table.income.main.resultsFromFinancial.2.year'
  ),
  'incomeStatement.ResultatOvrigaforetagAgarintresse.year': id(
    'incomeStatement.section.table.income.main.resultsFromFinancial.3.year'
  ),
  'incomeStatement.ResultatOvrigaFinansiellaAnlaggningstillgangar.year': id(
    'incomeStatement.section.table.income.main.resultsFromFinancial.4.year'
  ),
  'incomeStatement.OvrigaRanteintakterLiknandeResultatposter.year': id(
    'incomeStatement.section.table.income.main.resultsFromFinancial.5.year'
  ),
  'incomeStatement.NedskrivningarFinansiellaAnlaggningstillgangarKortfristigaPlaceringar.year':
    id('incomeStatement.section.table.income.main.resultsFromFinancial.6.year'),
  'incomeStatement.RantekostnaderLiknandeResultatposter.year': id(
    'incomeStatement.section.table.income.main.resultsFromFinancial.7.year'
  ),
  'incomeStatement.LamnadeKoncernbidrag.year': id(
    'incomeStatement.section.table.income.main.appropriations.2.year'
  ),
  'incomeStatement.ErhallnaKoncernbidrag.year': id(
    'incomeStatement.section.table.income.main.appropriations.1.year'
  ),
  'incomeStatement.ForandringPeriodiseringsfond.year': id(
    'incomeStatement.section.table.income.main.appropriations.3.year'
  ),
  'incomeStatement.ForandringOveravskrivningar.year': id(
    'incomeStatement.section.table.income.main.appropriations.4.year'
  ),
  'incomeStatement.OvrigaBokslutsdispositioner.year': id(
    'incomeStatement.section.table.income.main.appropriations.5.year'
  ),
  'incomeStatement.ForandringErsattningsfond.year': id(
    'incomeStatement.section.table.income.main.appropriations.6.year'
  ),
});

import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { asResultClass, getApiSdk } from 'api-sdk';
import { getContext } from 'utils/AgoyAppClient/contextHolder';
import { Action } from 'redux';
import shortid from 'shortid';

import {
  FinancialReport,
  FinancialReportChanges,
  FinancialReportPartKey,
  config,
  removeChangesById,
  resolveFinancialReport,
} from '@agoy/financial-report-document';
import {
  IncomeStatement,
  getPreviousYearPeriod,
} from '@agoy/annual-report-document';
import {
  AccountingBalancesAccountResolver,
  Cell,
  mapAccountsToReferenceAccountInformation,
  numberValue,
  TimePeriod,
} from '@agoy/document';
import { RootState } from 'redux/reducers';

import { addGlobalErrorMessage } from '_messages/redux/actions';
import {
  currentClientYear,
  previousClientYear,
  selectClientYear as selectAccountingViewClientYear,
} from '_reconciliation/redux/accounting-view/selectors';
import { formatYearVariable } from 'utils/document-util';
import { AccountingPeriod, AccountingAccount } from 'types/Accounting';
import { reformatDate } from 'utils';
import getCompanyType from '_annual-report/components/AnnualReportView/Parts/Settings/utils/getCompanyType';
import { parse, format, formatEndOfMonth } from '@agoy/dates';
import { currentPeriod as currentPeriodSelector } from '_clients/redux/customer-view/selectors';
import {
  SetFinancialReportConfigAction,
  UpdateFinancialReportChangesAction,
  UpdateFinancialReportCellValueAction,
  UpdateFinancialReportField,
  SetFinancialReportChangesAction,
  SetFinancialReportAction,
  ToggleFinancialReportField,
  ToggleFinancialReportSectionActive,
  ToggleFinancialReportIncomeStatementSectionActive,
  ToggleFinancialReportBalanceSheetSectionActive,
  ToggleFinancialReportBalanceSheetAssetsSectionActive,
  ToggleFinancialReportSettingsParameter,
  SetFinancialReportDocumentsAction,
  ToggleFinancialReportAdditionalTextActive,
} from './action-type-declarations';

import {
  SET_FINANCIAL_REPORT_CONFIG,
  UPDATE_FINANCIAL_REPORT_CELL_VALUE,
  UPDATE_FINANCIAL_REPORT_FIELD,
  UPDATE_FINANCIAL_REPORT_CHANGES,
  SET_FINANCIAL_REPORT_CHANGES,
  SET_FINANCIAL_REPORT,
  TOGGLE_FINANCIAL_REPORT_FIELD,
  TOGGLE_FINANCIAL_REPORT_SECTION_ACTIVE,
  TOGGLE_FINANCIAL_REPORT_INCOME_STATEMENT_SECTION_ACTIVE,
  TOGGLE_FINANCIAL_REPORT_BALANCE_SHEET_SECTION_ACTIVE,
  TOGGLE_FINANCIAL_REPORT_BALANCE_SHEET_ASSETS_SECTION_ACTIVE,
  TOGGLE_FINANCIAL_REPORT_SETTINGS_PARAMETER,
  SET_FINANCIAL_REPORT_DOCUMENTS,
  TOGGLE_FINANCIAL_REPORT_ADDITIONAL_TEXT_ACTIVE,
} from './action-types';

import { selectClientPeriod } from './selectors';
import { Document } from './reducer/types';
import { getPeriodStart } from '../utils';

const setFinancialReport = (
  clientId: string,
  period: string,
  report: FinancialReport
): SetFinancialReportAction => ({
  type: SET_FINANCIAL_REPORT,
  clientId,
  period,
  report,
});

const setFinancialReportConfigAction = (
  clientId: string,
  period: string,
  financialReport: FinancialReport
): SetFinancialReportConfigAction => ({
  type: SET_FINANCIAL_REPORT_CONFIG,
  clientId,
  period,
  config: financialReport,
});

const setFinancialReportChanges = (
  clientId: string,
  period: string,
  changes: FinancialReportChanges
): SetFinancialReportChangesAction => ({
  type: SET_FINANCIAL_REPORT_CHANGES,
  clientId,
  period,
  changes,
});

const updateFinancialReportChanges = (
  clientId: string,
  period: string,
  changes: FinancialReportChanges
): UpdateFinancialReportChangesAction => ({
  type: UPDATE_FINANCIAL_REPORT_CHANGES,
  clientId,
  period,
  changes,
});

const toggleFinancialReportFieldAction = (
  clientId: string,
  period: string,
  part: FinancialReportPartKey,
  section: string,
  field: string
): ToggleFinancialReportField => ({
  type: TOGGLE_FINANCIAL_REPORT_FIELD,
  clientId,
  period,
  part,
  section,
  field,
});

const toggleFinancialReportSettingsParameterAction = (
  clientId: string,
  period: string,
  id: string
): ToggleFinancialReportSettingsParameter => ({
  type: TOGGLE_FINANCIAL_REPORT_SETTINGS_PARAMETER,
  clientId,
  period,
  id,
});

const toggleFinancialReportSectionActiveAction = (
  clientId: string,
  period: string,
  part: FinancialReportPartKey
): ToggleFinancialReportSectionActive => ({
  type: TOGGLE_FINANCIAL_REPORT_SECTION_ACTIVE,
  clientId,
  period,
  part,
});

const toggleFinancialReportIncomeStatementSectionActiveAction = (
  clientId: string,
  period: string,
  section: string
): ToggleFinancialReportIncomeStatementSectionActive => ({
  type: TOGGLE_FINANCIAL_REPORT_INCOME_STATEMENT_SECTION_ACTIVE,
  clientId,
  period,
  section,
});

const toggleFinancialReportBalanceSheetSectionActiveAction = (
  clientId: string,
  period: string,
  section: string
): ToggleFinancialReportBalanceSheetSectionActive => ({
  type: TOGGLE_FINANCIAL_REPORT_BALANCE_SHEET_SECTION_ACTIVE,
  clientId,
  period,
  section,
});

const toggleFinancialReportBalanceSheetAssetsSectionActiveAction = (
  clientId: string,
  period: string,
  section: string
): ToggleFinancialReportBalanceSheetAssetsSectionActive => ({
  type: TOGGLE_FINANCIAL_REPORT_BALANCE_SHEET_ASSETS_SECTION_ACTIVE,
  clientId,
  period,
  section,
});

const toggleAdditionalTextActiveAction = (
  clientId: string,
  period: string,
  id: string
): ToggleFinancialReportAdditionalTextActive => ({
  type: TOGGLE_FINANCIAL_REPORT_ADDITIONAL_TEXT_ACTIVE,
  clientId,
  period,
  id,
});

export const setFinancialReportDocuments = (
  clientId: string,
  period: string,
  documents: Document[]
): SetFinancialReportDocumentsAction => ({
  type: SET_FINANCIAL_REPORT_DOCUMENTS,
  clientId,
  period,
  documents,
});

const prepareResolveData = (
  state,
  clientId,
  financialYear
):
  | {
      accounts: AccountingAccount[] | undefined;
      periods: AccountingPeriod[] | undefined;
      availablePeriods: (string | TimePeriod)[][] | undefined;
    }
  | undefined => {
  const accounts = selectAccountingViewClientYear(
    state,
    clientId,
    financialYear
  )?.accountingBalances?.accounts;
  const periods = selectAccountingViewClientYear(state, clientId, financialYear)
    ?.accountingBalances?.periods;
  // prepare periods to resolve accounts
  const availablePeriods = periods
    ?.filter((period) => period.type === 'month')
    .map((period) => {
      const periodStart = parse(period.start, 'yyyy-MM-dd');
      const periodName = format(periodStart, 'MMMyy');
      const periodTime = TimePeriod.fromDates(
        period.start,
        period.end,
        'yyyy-MM-dd'
      );
      return [periodName, periodTime];
    });
  return {
    accounts,
    periods,
    availablePeriods,
  };
};

export const updateFinancialReportReferences = async (
  clientId: string,
  period: string,
  dispatch: ThunkDispatch<RootState, unknown, Action<string>>,
  getState: () => RootState
): Promise<void> => {
  const state = getState();
  const currentPeriod = state.customerView?.currentPeriod;
  const currentFinancialYear = state.customerView?.currentFinancialYear;

  const financialYears = state.customers[clientId]?.financialYears;

  if (!financialYears || !currentFinancialYear) {
    console.error('Incorrect financialYears');
    return;
  }

  const financialReport = selectClientPeriod(state, clientId, period);
  if (!financialReport) {
    console.error('No financial report found');
    return;
  }

  let previousFinancialYear;

  if (financialYears.length > 1) {
    financialYears.forEach((year, index) => {
      if (year === currentFinancialYear) {
        previousFinancialYear = financialYears[index - 1];
      }
    });
  }
  const currentData = prepareResolveData(state, clientId, currentFinancialYear);

  if (
    !currentData ||
    !currentData.accounts ||
    !currentData.periods ||
    !currentData.availablePeriods
  ) {
    console.error('Incorrect current sieData');
    return;
  }

  const accountResolver = new AccountingBalancesAccountResolver(
    currentData.periods[0].financialYearId,
    currentData.periods,
    currentData.accounts
  );

  let { availablePeriods } = currentData;
  // get previous financial year accounts and periods
  if (previousFinancialYear) {
    const previousData = prepareResolveData(
      state,
      clientId,
      previousFinancialYear
    );
    if (
      previousData !== undefined &&
      previousData.accounts !== undefined &&
      previousData.periods !== undefined &&
      previousData.availablePeriods !== undefined
    ) {
      availablePeriods = availablePeriods.concat(previousData.availablePeriods);
      // Add additional previous financialYear account
      accountResolver.addFinancialYear(
        previousData.periods[0].financialYearId,
        previousData.periods,
        previousData.accounts
      );
    }
  }

  const { report, references } = financialReport;

  if (report && currentData.accounts && currentData.periods && period) {
    const currentPeriodStart = currentPeriod
      ? reformatDate(currentPeriod, 'yyyyMMdd', 'yyyy-MM-dd')
      : null;
    const selectedPeriod = currentData.periods.find(
      (p) => p.start === currentPeriodStart
    );

    const comparePeriod =
      numberValue(report.settings.section.comparePeriod) || 1;
    const fullPeriods = Object.fromEntries(availablePeriods);

    const previousYearPeriod = previousFinancialYear
      ? getPreviousYearPeriod(currentPeriod, previousFinancialYear)
      : null;

    if (previousYearPeriod) {
      const previousYearPeriodStart = getPeriodStart(
        previousYearPeriod,
        previousFinancialYear,
        comparePeriod
      );

      fullPeriods.previousYear = new TimePeriod(
        previousYearPeriodStart,
        formatEndOfMonth(previousYearPeriod, 'yyyyMMdd')
      );
    }

    if (currentPeriodStart) {
      const currentYearPeriodStart = getPeriodStart(
        currentPeriodStart,
        currentFinancialYear,
        comparePeriod
      );

      fullPeriods.year = new TimePeriod(
        currentYearPeriodStart,
        formatEndOfMonth(currentPeriodStart, 'yyyyMMdd')
      );
    }

    if (!selectedPeriod) {
      console.warn('Failed to find period');
      return;
    }

    const [newReport] = resolveFinancialReport(report, references, {
      accountResolver,
      defaultPeriod:
        currentData.periods[currentData.periods.length - 1].start ===
        currentPeriodStart
          ? TimePeriod.fromFinancialYear(currentFinancialYear)
          : TimePeriod.fromDates(
              selectedPeriod.start,
              selectedPeriod.end,
              'yyyy-MM-dd'
            ),
      periods: fullPeriods,
    });

    if (newReport !== report) {
      await dispatch(setFinancialReport(clientId, period, newReport));
    }
  }
};

export const loadFinancialReport =
  (
    clientId: string,
    financialYear: string,
    period: string
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    try {
      const periodId = currentPeriodSelector(getState())?.id;
      if (!periodId) {
        console.warn('Period id not found');
        return;
      }
      const sdk = getApiSdk(getContext());

      const financialReportResult = await asResultClass(
        sdk.getFinancialReport({ clientid: clientId, periodId })
      );

      const annualReportResult = await asResultClass(
        sdk.getAnnualReportChanges({ clientid: clientId, financialYear })
      );

      const storedFinancialReport = financialReportResult.ok
        ? financialReportResult.val
        : {};

      if (annualReportResult.ok) {
        const storedAnnualReport = annualReportResult.val;
        const { incomeStatement: iSChanges, balanceSheet: bshChanges } =
          storedAnnualReport.changes;

        if ('changes' in storedFinancialReport) {
          storedFinancialReport.changes.incomeStatement = iSChanges;
          storedFinancialReport.changes.balanceSheet = bshChanges;
        } else {
          storedFinancialReport.incomeStatement = iSChanges;
          storedFinancialReport.balanceSheet = bshChanges;
        }
      }

      const clientDocumentsResult = await asResultClass(
        sdk.getClientDocuments({
          clientid: clientId,
          year: formatYearVariable(financialYear),
        })
      );
      const data = clientDocumentsResult.ok
        ? clientDocumentsResult.val
        : { listDocuments: [] };

      const documents: Document[] = [];

      data?.listDocuments?.forEach((item) => {
        if (item.category.startsWith('financialReportDocuments')) {
          const itemPeriod = item.category.substring(item.category.length - 8);
          if (itemPeriod === period) {
            documents.push({
              id: shortid(),
              name: item.name,
              url: item.url,
            });
          }
        }
      });

      if (documents.length) {
        dispatch(setFinancialReportDocuments(clientId, period, documents));
      }

      if (storedFinancialReport) {
        if ('changes' in storedFinancialReport) {
          // Preparation for future API changes.
          dispatch(
            setFinancialReportChanges(
              clientId,
              period,
              storedFinancialReport.changes
            )
          );
        } else {
          const { ...changes } = storedFinancialReport;
          dispatch(setFinancialReportChanges(clientId, period, changes));
        }
      }

      updateFinancialReportConfig(clientId, period, dispatch, getState);
    } catch (e) {
      console.error(e);
      dispatch(addGlobalErrorMessage('finacialStatementReport.failedToLoad'));
    }
  };
export const setFinancialReportConfig =
  (
    clientId: string,
    period: string,
    reportConfig: FinancialReport
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    await dispatch(
      setFinancialReportConfigAction(clientId, period, reportConfig)
    );
    updateFinancialReportReferences(clientId, period, dispatch, getState);
  };

export const updateFinancialReportConfig = async (
  clientId: string,
  period: string,
  dispatch: ThunkDispatch<RootState, unknown, Action<string>>,
  getState: () => RootState
): Promise<void> => {
  const {
    currentCustomer: customerId,
    currentPeriod,
    currentFinancialYear,
  } = getState().customerView;
  if (customerId) {
    const customer = getState().customers[customerId];
    const accountingBalances = currentClientYear(
      (state) => state.accountingBalances
    )(getState());
    const previousAccounts = previousClientYear(
      (state) => state.accountingBalances?.accounts
    )(getState());
    const periodType = currentPeriodSelector(getState())?.type;

    if (accountingBalances && customer) {
      const { accounts } = accountingBalances;
      if (customer && accounts && currentPeriod && currentFinancialYear) {
        const { documentConfiguration } =
          getState().annualReport.clients[customerId].years[
            currentFinancialYear
          ];

        if (documentConfiguration) {
          try {
            const { givenName, familyName } = getState().user;
            const userName =
              givenName && familyName ? `${givenName} ${familyName}` : '';

            await dispatch(
              setFinancialReportConfig(
                clientId,
                currentPeriod,
                config(
                  userName,
                  customer,
                  currentPeriod,
                  currentFinancialYear,
                  mapAccountsToReferenceAccountInformation(
                    (previousAccounts || []).concat(accounts)
                  ),
                  periodType === 'month',
                  documentConfiguration,
                  getCompanyType(customer?.type)
                )
              )
            );
            await updateFinancialReportReferences(
              clientId,
              period,
              dispatch,
              getState
            );
          } catch (e) {
            console.error(e);
            dispatch(addGlobalErrorMessage('error'));
          }
        }
      }
    }
  }
};

const updateFinancialReportCellValueAction = (
  clientId: string,
  period: string,
  id: string,
  cell: Cell
): UpdateFinancialReportCellValueAction => ({
  type: UPDATE_FINANCIAL_REPORT_CELL_VALUE,
  clientId,
  period,
  id,
  cell,
});

export const updateFinancialReportCellValue =
  (
    clientId: string,
    period: string,
    id: string,
    cell: Cell | string | number | undefined
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    switch (typeof cell) {
      case 'string':
        await dispatch(
          updateFinancialReportCellValueAction(clientId, period, id, {
            type: 'string',
            value: cell,
          })
        );
        break;
      case 'number':
      case 'undefined':
        await dispatch(
          updateFinancialReportCellValueAction(clientId, period, id, {
            type: 'number',
            value: cell,
          })
        );
        break;
      default:
        await dispatch(
          updateFinancialReportCellValueAction(clientId, period, id, cell)
        );
    }
  };

export const updateFinancialReportField =
  (
    clientId: string,
    period: string,
    id: string,
    value: string | number | undefined
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    const action: UpdateFinancialReportField = {
      type: UPDATE_FINANCIAL_REPORT_FIELD,
      clientId,
      period,
      id,
      value,
    };
    await dispatch(action);

    await updateFinancialReportReferences(clientId, period, dispatch, getState);
  };

export const resetFinancialReportContent =
  (
    clientId: string,
    period: string,
    id: string
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    const state = selectClientPeriod(getState(), clientId, period);
    if (!state) {
      console.error('No financial report');
      return;
    }
    await dispatch(
      updateFinancialReportChanges(
        clientId,
        period,
        removeChangesById(state.changes, id)
      )
    );

    await updateFinancialReportConfig(clientId, period, dispatch, getState);
  };

export const toggleFinancialReportFieldActive =
  (
    clientId: string,
    year: string,
    part: FinancialReportPartKey,
    section: string,
    field: string
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    await dispatch(
      toggleFinancialReportFieldAction(clientId, year, part, section, field)
    );
  };

export const toggleFinancialReportSettingsParameter =
  (
    clientId: string,
    year: string,
    id: string
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    await dispatch(
      toggleFinancialReportSettingsParameterAction(clientId, year, id)
    );
  };

export const toggleFinancialReportSectionActive =
  (
    clientId: string,
    year: string,
    part: FinancialReportPartKey
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    await dispatch(
      toggleFinancialReportSectionActiveAction(clientId, year, part)
    );
  };

export const toggleFinancialReportIncomeStatementSectionActive =
  (
    clientId: string,
    year: string,
    section: string
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    await dispatch(
      toggleFinancialReportIncomeStatementSectionActiveAction(
        clientId,
        year,
        section
      )
    );
  };

export const toggleFinancialReportBalanceSheetSectionActive =
  (
    clientId: string,
    year: string,
    section: string
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    await dispatch(
      toggleFinancialReportBalanceSheetSectionActiveAction(
        clientId,
        year,
        section
      )
    );
  };

export const toggleFinancialReportBalanceSheetAssetsSectionActive =
  (
    clientId: string,
    year: string,
    section: string
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    await dispatch(
      toggleFinancialReportBalanceSheetAssetsSectionActiveAction(
        clientId,
        year,
        section
      )
    );
  };

export const updateFinancialReportReferencesCurrentPeriod =
  (clientId: string): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    const currentPeriod = getState().customerView?.currentPeriod;
    if (currentPeriod) {
      await updateFinancialReportReferences(
        clientId,
        currentPeriod,
        dispatch,
        getState
      );
    }
  };

export const toggleAdditionalTextActive =
  (
    clientId: string,
    year: string,
    section: string
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    await dispatch(toggleAdditionalTextActiveAction(clientId, year, section));
  };

export const PERIODICITY = [
  'every_period',
  'even_month',
  'odd_month',
  'every_quarter',
  'half_year',
  'last_period',
  'first_period',
] as const;

export const PROGRAMS = [
  'reconciliation_fin_statement_company',
  'annual_report_company',
  'tax_declaration_company',
] as const;

export const ALL_PROGRAMS = [
  'reconciliation_fin_statement_company',
  'annual_report_company',
  'tax_declaration_company',
  'tax_declaration_person',
] as const;

export const TYPES = [
  'empty',
  'tax',
  'annual-report',
  'tax-declaration',
] as const;

export const STATUSES = [
  'in_progress',
  'yes',
  'no',
  'not_started',
  'not_applicable',
] as const;

import { id, or, ref, sum, table, value } from '@agoy/document';
import { IxbrlCell, NoteOneTableWithSum } from '../../../../common';
import {
  active,
  field,
  ixbrlCell,
  ixbrlField,
} from '../../../../common/utils/util';
import { previousYear, year } from '../../../../common/utils/date-util';

const MONETARY_ITEM_TYPE = 'monetaryItemType';

export const getNote33 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null,
  documentTypeVersion = '1'
): NoteOneTableWithSum => {
  return {
    active: false,
    number: value(undefined),
    name: value('Ställda säkerheter'),
    table: table<IxbrlCell>(
      'notes.note33.table',
      { id: 'label', label: '' },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      .addRows((rows) =>
        rows
          // The IDs aren't ordered because the structure was changed
          // but old IDs were kept to persist old data where present
          .addRow(
            '5',
            noteLabel('Företagsinteckningar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:StalldaSakerheterForetagsinteckningar',
              contextRef: 'balans0',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:StalldaSakerheterForetagsinteckningar',
              contextRef: 'balans1',
            })
          )
          .addRow(
            '6',
            noteLabel(
              'Varav till koncernföretag, intresseföretag eller gemensamt styrt företag'
            ),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:StalldaSakerheterForetagsinteckningarKoncernforetag',
              contextRef: 'balans0',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:StalldaSakerheterForetagsinteckningarKoncernforetag',
              contextRef: 'balans1',
            })
          )
          .addRow(
            '1',
            noteLabel('Fastighetsinteckningar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:StalldaSakerheterFastighetsinteckningar',
              contextRef: 'balans0',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:StalldaSakerheterFastighetsinteckningar',
              contextRef: 'balans1',
            })
          )
          .addRow(
            '2',
            noteLabel(
              'Varav till koncernföretag, intresseföretag eller gemensamt styrt företag'
            ),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:StalldaSakerheterFastighetsinteckningarKoncernforetag',
              contextRef: 'balans0',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:StalldaSakerheterFastighetsinteckningarKoncernforetag',
              contextRef: 'balans1',
            })
          )
          .addRow(
            '9',
            noteLabel('Tillgångar med äganderättsförbehåll'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:StalldaSakerheterTillgangarAganderattsforbehall',
              contextRef: 'balans0',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:StalldaSakerheterTillgangarAganderattsforbehall',
              contextRef: 'balans1',
            })
          )
          .addRow(
            '10',
            noteLabel(
              'Varav till koncernföretag, intresseföretag eller gemensamt styrt företag'
            ),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:StalldaSakerheterTillgangaraganderattsforbehallKoncernforetag',
              contextRef: 'balans0',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:StalldaSakerheterTillgangaraganderattsforbehallKoncernforetag',
              contextRef: 'balans1',
            })
          )
          .addRow(
            '13',
            noteLabel('Belånade fordringar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:StalldaSakerheterBelanadeFordringar',
              contextRef: 'balans0',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:StalldaSakerheterBelanadeFordringar',
              contextRef: 'balans1',
            })
          )
          .addRow(
            '14',
            noteLabel(
              'Varav till koncernföretag, intresseföretag eller gemensamt styrt företag'
            ),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:StalldaSakerheterBelanadeFordringarKoncernforetag',
              contextRef: 'balans0',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:StalldaSakerheterBelanadeFordringarKoncernforetag',
              contextRef: 'balans1',
            })
          )
          .addRow(
            '17',
            noteLabel('Andra ställda säkerheter'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:StalldaSakerheterAndraStalldaSakerheter',
              contextRef: 'balans0',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:StalldaSakerheterAndraStalldaSakerheter',
              contextRef: 'balans1',
            })
          )
          .addRow(
            '18',
            noteLabel(
              'Varav till koncernföretag, intresseföretag eller gemensamt styrt företag'
            ),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:StalldaSakerheterAndraStalldaSakerheterKoncernforetag',
              contextRef: 'balans0',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:StalldaSakerheterAndraStalldaSakerheterKoncernforetag',
              contextRef: 'balans1',
            })
          )
          .build()
      )
      .newRowTemplate(value(''), value(undefined), value(undefined))
      .build(),
    sumTable: table(
      'notes.note33.sumTable',
      { id: 'label', label: '' },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      .addRows((rows) =>
        rows
          .addRow(
            'sum',
            noteLabel('Summa ställda säkerheter'),
            ixbrlCell(ref(or(sum(id('notes.note33.table.*.year')), 0)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:StalldaSakerheter',
              contextRef: 'balans0',
            }),
            ixbrlCell(
              ref(or(sum(id('notes.note33.table.*.previousYear')), 0)),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:StalldaSakerheter',
                contextRef: 'balans1',
              }
            )
          )
          .build()
      )
      .build(),
    commentLabel: field('Kommentar'),
    comment: ixbrlField(active(field('')), {
      type: 'stringItemType',
      name: 'se-gen-base:NotStalldaSakerheterKommentarer',
      contextRef: 'balans0',
    }),
    group: value(undefined),
    section: value(undefined),
    validForReportType: {
      k2: true,
      k3: documentTypeVersion === '1',
    },
  };
};

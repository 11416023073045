import React, { memo } from 'react';
import styled from '@emotion/styled';

import { ReconciliationBalanceKeyFigure } from '@agoy/api-sdk-core';
import { useSelector } from 'redux/reducers';

import { isPercentageRow, formatValue } from '../../../utils';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  height: 100%;
  background-color: #fafafa;
  border-bottom: 2px solid #eeeeee;
  border-left: 2px solid #eeeeee;
  border-right: 2px solid #eeeeee;
`;

const BalanceCell = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  height: 100%;
  padding: 5px;
`;

const IngoingBalance = styled(BalanceCell)`
  border-right: 2px solid #eeeeee;
  min-width: 95px;
  width: 100%;
`;

const OutgoingBalance = styled(BalanceCell)`
  min-width: 161px;
  width: 100%;
  text-align: right;
`;

type KeyFigureCellProps = {
  row: ReconciliationBalanceKeyFigure | undefined;
};

const KeyFigureCell = ({ row }: KeyFigureCellProps) => {
  const periodChangeVisible = useSelector(
    (state) => state.accountingView.periodChangeVisible
  );
  const periodUBVisible = useSelector(
    (state) => state.accountingView.periodUBVisible
  );

  const isPercentage = row && isPercentageRow(row.id);

  return (
    <Container>
      {periodChangeVisible && (
        <IngoingBalance>
          {row && row.ub !== null && row.ib !== null
            ? formatValue(row.ub - row.ib, isPercentage)
            : ''}
        </IngoingBalance>
      )}
      {periodUBVisible && (
        <OutgoingBalance>
          {row && row.ub !== null ? formatValue(row.ub, isPercentage) : ''}
        </OutgoingBalance>
      )}
    </Container>
  );
};

export default memo(KeyFigureCell);

import React from 'react';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';
import Typography from '../../../UI/Typography/Typography';
import {
  AnnualGeneralMeeting,
  SharesCompanyManagementReport,
  ProfitProposal as ProfitProposalType,
  EconomicAssociationManagementReport,
} from '@agoy/annual-report-document';
import { stringValue } from '@agoy/document';
import MultilineField from '../UI/MultilineField';
import { Heading, Cell, Section } from '../../../UI';

const InlineCell = styled(Cell)`
  display: inline;
`;

interface ProfitProposalProps {
  annualGeneralMeeting: AnnualGeneralMeeting | undefined;
  managementReport:
    | SharesCompanyManagementReport
    | EconomicAssociationManagementReport;
  reportPart: ProfitProposalType;
}

const ProfitProposal = ({
  annualGeneralMeeting,
  managementReport,
  reportPart,
}: ProfitProposalProps) => {
  const { formatMessage } = useIntl();

  const title =
    stringValue(managementReport.profitProposal.title) ||
    formatMessage({
      id: 'annualReport.menu.managementReport.profitProposal',
    });

  /* Needed for K3 in later story
  const annualGeneralMeetingDateField: IxbrlField = {
    ...annualGeneralMeeting.main.date,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    ixbrl: { ...reportPart.paymentDateType.ixbrl! },
  };
  */

  return (
    <Section>
      <Heading title={title} />
      {/* should be used in K3, once BLV replies on how it shoould be done
        <Tuple
          name="se-gaap-ext:SpecifikationForslagUtdelningAktieslagTuple"
          tupleID="SpecifikationForslagUtdelningAktieslagTuple1"
        />
         */}
      <Typography>
        {formatMessage({
          id: 'annualReport.managementReport.profitProposal.dividend.1',
        })}
        <InlineCell
          cell={reportPart.dividend}
          numberFormatType={{ type: 'plain-integer', precision: 0 }}
        />
        {formatMessage({
          id: 'annualReport.managementReport.profitProposal.dividend.2',
        })}
        <InlineCell
          cell={reportPart.dividendPerShare}
          numberFormatType={{ type: 'plain-integer', precision: 2 }}
        />
        {formatMessage({
          id: 'annualReport.managementReport.profitProposal.dividend.3',
        })}
      </Typography>

      {/* Dealing with dates to tag, user can select either first one 'Utbetalas vid årsstämma' or select a date herself */}
      {/* The user selected 'Utbetalas vid årsstämma' */}
      {stringValue(reportPart.paymentDateType) === 'generalMeeting' && (
        <Typography>
          {formatMessage({
            id: 'annualReport.managementReport.profitProposal.paymentDate.generalMeeting',
          })}
        </Typography>
      )}
      {/* User selected date herself */}
      {stringValue(reportPart.paymentDateType) === 'date' && (
        <Typography>
          {formatMessage({
            id: 'annualReport.managementReport.profitProposal.paymentDate.date',
          })}
          {stringValue(reportPart.paymentDate)}.
        </Typography>
      )}

      <MultilineField field={reportPart.statement} />
    </Section>
  );
};

export default ProfitProposal;

import React from 'react';
import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';
import ClientTag from './ClientTag';

const StyledInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)}px;

  h1 {
    font-weight: 700;
    margin: 0;
  }
`;

const ClientHeaderTitle = ({
  clientName,
  clientType,
}: {
  clientName: string;
  clientType: 'person' | 'company';
}) => (
  <StyledInfo>
    <Typography variant="h1" color="primary">
      {clientName}
    </Typography>
    <ClientTag clientType={clientType} />
  </StyledInfo>
);

export default ClientHeaderTitle;

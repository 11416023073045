import { removeOptional } from '@agoy/common';
import { AgoyDocumentStructure } from '../document';
import { OperationResult, State } from './types';
import updateDocument, { withError } from './helpers/updateDocument';
import { BooleanCell, NumberCell, StringCell } from '../../Cell';
import { Source } from '../../types/source';
import { FieldUpdate } from '../changes';
import { restOfCell } from '../applyChanges';
import { mergeSource } from './helpers/mergeSource';
import { isEqual } from 'lodash';

/**
 * updateField
 *
 * @param structure       AgoyDocumentStructure (contentDefinition)
 * @param state           The document and changes to update
 * @param id              Field id
 * @param value           The value, string, number or undefined
 * @returns If nothing is updated it returns false, if there is an error it returns a string
 * otherwise the updated document and changes are returned.
 */
const updateField = <T extends AgoyDocumentStructure>(
  structure: T,
  state: State<T>,
  id: string,
  value: string | number | boolean | undefined,
  sourceChange?: Partial<Source>
): OperationResult<T> => {
  return updateDocument(structure, state, id, {
    field: (key, id, props) => {
      const node = props.node;
      const { type, source: currentSource } = node;
      const { active, ...nodeOriginal } = node;

      const source = mergeSource(currentSource, sourceChange);
      // Must handle each type of field separatly due to typing
      switch (type) {
        case 'ref': {
          if (
            typeof value !== 'string' &&
            typeof value !== 'number' &&
            typeof value !== 'boolean' &&
            typeof value !== 'undefined'
          ) {
            return withError(props, 'INVALID_FIELD_TYPE');
          }

          if (typeof value === 'number' || typeof value === 'undefined') {
            const newNode: NumberCell = {
              ...node,
              type: 'number',
              value,
              original: node.original || nodeOriginal,
              source,
            };
            const newChange: FieldUpdate = {
              ...props.changes,
              type: 'number',
              value,
              source,
            };
            return {
              ...props,
              node: removeOptional(newNode, 'source'),
              changes: removeOptional(newChange, 'source'),
            };
          }
          if (typeof value === 'boolean') {
            const newNode: BooleanCell = {
              ...node,
              type: 'boolean',
              value,
              original: node.original || nodeOriginal,
              source,
            };
            const newChange: FieldUpdate = {
              ...props.changes,
              type: 'boolean',
              value,
              source,
            };
            return {
              ...props,
              node: removeOptional(newNode, 'source'),
              changes: removeOptional(newChange, 'source'),
            };
          }

          const newNode: StringCell = {
            ...node,
            type: 'string',
            value,
            original: node.original || nodeOriginal,
            source,
          };
          const newChange: FieldUpdate = {
            ...props.changes,
            type: 'string',
            value,
            source,
          };
          return {
            ...props,
            node: removeOptional(newNode, 'source'),
            changes: removeOptional(newChange, 'source'),
          };
        }
        case 'string': {
          if (typeof value !== 'string') {
            return withError(props, 'INVALID_FIELD_TYPE');
          }

          const newNode: StringCell = removeOptional<StringCell>(
            {
              ...node,
              value,
              source,
            },
            'source'
          );
          if (isEqual(newNode, node)) {
            // No change
            return props;
          }
          const newChange: FieldUpdate = {
            ...props.changes,
            type,
            value,
            source,
          };

          return {
            ...props,
            node: { ...newNode, original: node.original || nodeOriginal },
            changes: removeOptional(newChange, 'source'),
          };
        }
        case 'number': {
          if (typeof value !== 'number' && typeof value !== 'undefined') {
            return withError(props, 'INVALID_FIELD_TYPE');
          }

          const newNode = removeOptional<NumberCell>(
            {
              ...node,
              value,
              source,
            },
            'source'
          );
          if (isEqual(newNode, node)) {
            // No change
            return props;
          }
          const newChange: FieldUpdate = {
            ...props.changes,
            type,
            value,
            source,
          };
          return {
            ...props,
            node: { ...newNode, original: node.original || nodeOriginal },
            changes: removeOptional(newChange, 'source'),
          };
        }
        case 'boolean': {
          if (typeof value !== 'boolean') {
            return withError(props, 'INVALID_FIELD_TYPE');
          }
          const newNode: BooleanCell = removeOptional<BooleanCell>(
            {
              ...node,
              value,
              source,
            },
            'source'
          );
          if (isEqual(newNode, node)) {
            // No change
            return props;
          }
          const newChange: FieldUpdate = {
            ...props.changes,
            type,
            value,
            source,
          };
          return {
            ...props,
            node: { ...newNode, original: node.original || nodeOriginal },
            changes: removeOptional(newChange, 'source'),
          };
        }
        case 'msg': {
          // Replace a FormatMessageCell with StringCell or NumberCell
          if (
            typeof value !== 'string' &&
            typeof value !== 'number' &&
            typeof value !== 'undefined'
          ) {
            return withError(props, 'INVALID_FIELD_TYPE');
          }
          switch (typeof value) {
            case 'string': {
              const newNode: StringCell = {
                ...restOfCell(props.node),
                type: 'string',
                value,
                source,
              };
              const newChange: FieldUpdate = {
                ...props.changes,
                type: 'string',
                value,
                source,
              };
              return {
                ...props,
                node: removeOptional(newNode, 'source'),
                changes: removeOptional(newChange, 'source'),
              };
            }
            case 'number': {
              const newNode: NumberCell = {
                ...restOfCell(props.node),
                type: 'number',
                value,
                source,
              };
              const newChange: FieldUpdate = {
                ...props.changes,
                type: 'number',
                value,
                source,
              };
              return {
                ...props,
                node: removeOptional(newNode, 'source'),
                changes: removeOptional(newChange, 'source'),
              };
            }
            default:
              return withError(props, 'INVALID_FIELD_TYPE');
          }
        }
        default:
          return withError(props, 'INVALID_FIELD_TYPE');
      }
    },
  });
};

export default updateField;

import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import {
  Grid,
  Button,
  Typography,
  TextField,
  InputAdornment,
  FormControlLabel,
  Switch,
  FormGroup,
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import styled from '@emotion/styled';

import config from '_shared/services/config';
import { GenericModal } from '_shared/components/Modal';
import Logo from '_shared/components/Logo';

import { UserAccessListItem } from '_organization/components/organisms/UserAccessListItem';
import { filterMembers } from '_organization/components/organisms/helpers';
import { ClientCompanyType } from '_clients/types/types';
import DeleteClientButton from './DeleteClientButton';

export const DefaultContainer = styled.div`
  padding: ${(props) => props.theme.spacing(2)}px;
`;

const Container = styled(DefaultContainer)`
  height: 100%;
  min-width: 80vw;
`;
const GridWrapper = styled(Grid)`
  padding: ${(props) => props.theme.spacing(2)}px;
  height: 100%;
`;

const ClientLogo = styled(Logo)`
  width: 150px;
  height: 150px;
`;

const ClientInfo = styled(Grid)`
  padding: ${(props) => props.theme.spacing(2)}px;
`;

const SearchAndFilterSection = styled(Grid)`
  margin: ${(props) => props.theme.spacing(2)}px 0;
`;

const MembersSection = styled(Grid)`
  padding: 0 ${(props) => props.theme.spacing(1)}px;
`;

const isFortnoxWhiteLabel = config.whiteLabelUI === 'fortnox';

const DEFAULT_FILTERS: Member.Filters = {
  memberEmail: '',
  authorisedOnly: false,
};

type ModalEditClientProps = {
  isAdmin: boolean;
  isUpdatingAccess: boolean;
  members: Member.Members;
  client: ClientCompanyType;
  handleClose: () => void;
  handleToggleMemberAuthorisation: (member: Member.MemberType) => Promise<void>;
  handleUpdateClient: ({
    clientId,
    active,
  }: {
    clientId: string;
    active: boolean;
  }) => Promise<void>;
  handleShowFortnoxIntegration: () => void;
  handleDeleteClient: () => Promise<void>;
};

const ModalEditClient = ({
  isAdmin,
  isUpdatingAccess,
  members,
  client,
  handleClose,
  handleToggleMemberAuthorisation,
  handleUpdateClient,
  handleShowFortnoxIntegration,
  handleDeleteClient,
}: ModalEditClientProps) => {
  const { formatMessage } = useIntl();

  // STATE
  const [filteredMembers, setFilteredMembers] =
    useState<Member.Members>(members);
  const [filters, setFilters] = useState<Member.Filters>(DEFAULT_FILTERS);

  const {
    name: clientName = '',
    active: clientActive,
    logo: clientLogo = '',
  } = client;

  React.useEffect(() => {
    setFilteredMembers(filterMembers({ members, filters }));
  }, [members, filters]);

  const onFilterChange = (newFilters: Member.Filters) => {
    setFilters(newFilters);
  };

  return (
    <GenericModal open handleClose={handleClose}>
      <Container>
        <GridWrapper
          container
          direction="column"
          justifyContent="space-between"
        >
          <Grid container item spacing={2}>
            <Grid container>
              <Grid container item xs>
                <Grid item>
                  <ClientLogo src={clientLogo} />
                </Grid>
                <ClientInfo item>
                  <Typography variant="h2">{clientName}</Typography>
                </ClientInfo>
              </Grid>
              <Grid container item xs justifyContent="flex-end" spacing={2}>
                {!isFortnoxWhiteLabel && (
                  <Grid item>
                    <Button
                      color="primary"
                      variant="contained"
                      disableElevation
                      onClick={() => handleShowFortnoxIntegration()}
                    >
                      {formatMessage({
                        id: 'dashboard.customers.edit.fortnoxButton',
                      })}
                    </Button>
                  </Grid>
                )}
                {isAdmin && (
                  <>
                    <Grid item>
                      <Button
                        variant="contained"
                        onClick={() =>
                          handleUpdateClient({
                            clientId: client.id,
                            active: !client.active,
                          })
                        }
                        disableElevation
                        color="primary"
                      >
                        {formatMessage({
                          id: clientActive
                            ? `dashboard.customers.edit.clientStatus.active`
                            : `dashboard.customers.edit.clientStatus.inactive`,
                        })}
                      </Button>
                      {/* DELETE CLIENT */}
                      <DeleteClientButton
                        clientName={clientName}
                        handleConfirm={handleDeleteClient}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
            <Grid container item>
              <SearchAndFilterSection
                container
                item
                spacing={2}
                justifyContent="space-between"
              >
                <Grid item xs={6}>
                  <TextField
                    id="search-auth-clients"
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={(e) =>
                      onFilterChange({
                        ...filters,
                        memberEmail: e.target.value,
                      })
                    }
                    placeholder={formatMessage({
                      id: 'dashboard.members.searchPlaceholder',
                    })}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid container justifyContent="flex-end" item xs={6}>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Switch
                          color="primary"
                          checked={filters.authorisedOnly}
                          onChange={() =>
                            onFilterChange({
                              ...filters,
                              authorisedOnly: !filters.authorisedOnly,
                            })
                          }
                          name="viewAuthorizedMembersToggle"
                        />
                      }
                      label={formatMessage({
                        id: 'dashboard.customers.edit.viewAuthorizedToggle',
                      })}
                    />
                  </FormGroup>
                </Grid>
              </SearchAndFilterSection>
              <MembersSection container item spacing={2}>
                {Object.keys(filteredMembers)
                  .filter(
                    (memberEmail) => filteredMembers[memberEmail].isVisible
                  )
                  .map((memberEmail) => (
                    <Grid key={memberEmail} zeroMinWidth item>
                      <UserAccessListItem
                        member={filteredMembers[memberEmail]}
                        updatingAccess={isUpdatingAccess}
                        accessChangeHandler={() =>
                          handleToggleMemberAuthorisation(
                            filteredMembers[memberEmail]
                          )
                        }
                      />
                    </Grid>
                  ))}
              </MembersSection>
            </Grid>
          </Grid>
        </GridWrapper>
      </Container>
    </GenericModal>
  );
};

export default ModalEditClient;

import React, { useContext, useMemo } from 'react';
import PrintStateContext from '_shared/components/PrintedDocument/PrintStateContext';
import TaxesDataContext, { TaxData } from './TaxesDataContext';

type TaxesPrintProviderProps = React.PropsWithChildren<{}>;

const TaxesPrintProvider = ({ children }: TaxesPrintProviderProps) => {
  const { state } = useContext(PrintStateContext);

  const taxData: TaxData | null = useMemo(() => {
    if (!state.taxData) {
      return null;
    }
    return { ...state.taxData, onToggleDetailedSpecification: () => {} };
  }, [state.taxData]);

  if (!taxData) {
    console.log('State is missing taxData');
    return null;
  }

  return (
    <TaxesDataContext.Provider value={taxData}>
      {children}
    </TaxesDataContext.Provider>
  );
};

export default TaxesPrintProvider;

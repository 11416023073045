import React from 'react';
// TODO: BUTTONS view design revision needed
import { Button, Tooltip, useTheme } from '@material-ui/core';
import { Link } from 'react-router-dom';

type Props = {
  to: string;
  description?: string;
  isSelected?: boolean;
  children: React.ReactNode;
};

const LinkButton = (props: Props): JSX.Element => {
  const { to, isSelected, description, children } = props;
  const { palette } = useTheme();

  return (
    <Link to={to}>
      <Tooltip title={description || ''}>
        <Button
          style={{
            backgroundColor: isSelected ? palette.primary.light : undefined,
            marginRight: 20,
            marginTop: 5,
          }}
        >
          {children}
        </Button>
      </Tooltip>
    </Link>
  );
};
export default LinkButton;

/* eslint-disable import/no-cycle */
import { FinancialReportActionTypes } from '_financial-report/redux/action-type-declarations';
import { references, applyChanges } from '@agoy/financial-report-document';

import { isClientAction, isClientPeriodAction } from 'redux/actions';
import { GlobalActions } from '_shared/redux/actions';
import {
  INIT_STATE,
  SET_PROGRAM_PERIODS_STATUS,
} from '_shared/redux/action-types';
import updateFinancialReportField from '_financial-report/redux/reducer/reducers/updateFinancialReportField';
import toggleFinancialReportSectionActive from '_financial-report/redux/reducer/reducers/toggleFinancialReportSectionActive';
import { updateProgramStatusPeriodState } from '_shared/redux/program-status/reducer';
import toggleFinancialReportIncomeStatementSectionActive from './reducers/toggleFinancialReportIncomeStateSectionActive';
import toggleFinancialReportBalanceSheetSectionActive from './reducers/toggleFinancialReportBalanceSheetSectionActive';
import toggleFinancialReportSettingsParameter from './reducers/toggleFinancialReportSettingsParameter';

import FinancialReportState, {
  FinancialReportClientState,
  FinancialReportClientPeriodState,
} from './types';

import {
  SET_FINANCIAL_REPORT,
  SET_FINANCIAL_REPORT_CHANGES,
  SET_FINANCIAL_REPORT_CONFIG,
  UPDATE_FINANCIAL_REPORT_CHANGES,
  UPDATE_FINANCIAL_REPORT_FIELD,
  TOGGLE_FINANCIAL_REPORT_FIELD,
  TOGGLE_FINANCIAL_REPORT_SECTION_ACTIVE,
  TOGGLE_FINANCIAL_REPORT_INCOME_STATEMENT_SECTION_ACTIVE,
  TOGGLE_FINANCIAL_REPORT_BALANCE_SHEET_SECTION_ACTIVE,
  TOGGLE_FINANCIAL_REPORT_BALANCE_SHEET_ASSETS_SECTION_ACTIVE,
  TOGGLE_FINANCIAL_REPORT_SETTINGS_PARAMETER,
  SET_FINANCIAL_REPORT_DOCUMENTS,
} from '../action-types';
import toggleFinancialReportBalanceSheetAssetsSectionActive from './reducers/toggleFinancialReportBalanceSheetAssetsSectionActive';
import toggleFinancialReportField from './reducers/toggleFinancialReportField';

export const initialClientYearState: FinancialReportClientPeriodState = {
  references: references(),
  changes: {},
  documents: [],
};

export const initialClientState: FinancialReportClientState = {
  periods: {},
};

export const initialState: FinancialReportState = {
  clients: {},
};

export const clientReducer = (
  state: FinancialReportClientPeriodState = initialClientYearState,
  action: FinancialReportActionTypes | GlobalActions
): FinancialReportClientPeriodState => {
  switch (action.type) {
    case SET_FINANCIAL_REPORT_CONFIG:
      return {
        ...state,
        report: applyChanges(action.config, state.changes),
      };

    case SET_FINANCIAL_REPORT:
      if (action.report === state.report) {
        return state;
      }
      return { ...state, report: action.report };

    case SET_FINANCIAL_REPORT_CHANGES:
      return {
        ...state,
        changes: action.changes,
      };
    case UPDATE_FINANCIAL_REPORT_CHANGES:
      return {
        ...state,
        changes: action.changes,
      };
    case UPDATE_FINANCIAL_REPORT_FIELD:
      return updateFinancialReportField(state, action);

    case TOGGLE_FINANCIAL_REPORT_FIELD:
      return toggleFinancialReportField(state, action);

    case TOGGLE_FINANCIAL_REPORT_SECTION_ACTIVE:
      return toggleFinancialReportSectionActive(state, action);

    case TOGGLE_FINANCIAL_REPORT_INCOME_STATEMENT_SECTION_ACTIVE:
      return toggleFinancialReportIncomeStatementSectionActive(state, action);

    case TOGGLE_FINANCIAL_REPORT_BALANCE_SHEET_SECTION_ACTIVE:
      return toggleFinancialReportBalanceSheetSectionActive(state, action);

    case TOGGLE_FINANCIAL_REPORT_BALANCE_SHEET_ASSETS_SECTION_ACTIVE:
      return toggleFinancialReportBalanceSheetAssetsSectionActive(
        state,
        action
      );

    case TOGGLE_FINANCIAL_REPORT_SETTINGS_PARAMETER:
      return toggleFinancialReportSettingsParameter(state, action);

    case SET_FINANCIAL_REPORT_DOCUMENTS: {
      const { documents } = action;

      return {
        ...state,
        documents,
      };
    }

    default:
      return state;
  }
};

const reducer = (
  state: FinancialReportState = initialState,
  action: GlobalActions | FinancialReportActionTypes
): FinancialReportState => {
  switch (action.type) {
    case INIT_STATE:
      return action.state.financialReport || state;

    default:
      if (isClientPeriodAction(action)) {
        const { period } = action;
        const clientState = state.clients[action.clientId]?.periods[period];
        const nextClientState = clientReducer(clientState, action);

        if (nextClientState !== clientState) {
          return {
            ...state,
            clients: {
              ...state.clients,
              [action.clientId]: {
                ...state.clients[action.clientId],
                periods: {
                  ...state.clients[action.clientId]?.periods,
                  [period]: nextClientState,
                },
              },
            },
          };
        }
      }

      if (isClientAction(action)) {
        const clientState =
          state.clients[action.clientId] || initialClientState;
        switch (action.type) {
          case SET_PROGRAM_PERIODS_STATUS:
            return {
              ...state,
              clients: {
                ...state.clients,
                [action.clientId]: updateProgramStatusPeriodState(
                  'FIN_STATEMENT_REPORT',
                  clientState,
                  action
                ),
              },
            };
          default:
            return state;
        }
      }
      return state;
  }
};

export default reducer;

import React from 'react';
import styled from '@emotion/styled';
import { Select, MenuItem, Typography, ListItemIcon } from '@material-ui/core';
import Check from '@material-ui/icons/Check';
import { SelectProps } from '@material-ui/core/Select';
import GenericSimplefied from './GenericSimplefied';
import When from '../When/When';

const FieldWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const BaseField = styled(GenericSimplefied)`
  &.selectorLabel .MuiTypography-subtitle1 {
    font-size: 10px;
  }
  .MuiOutlinedInput-root {
    padding-top: 16px;
    padding-left: 2px;
    border-radius: 8px;
    width: 100%;
  }
  .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${({ theme }) => theme.palette.grey[500]};
  }
`;

const Title = styled(Typography)`
  position: absolute;
  top: 8px;
  left: 14px;
  font-size: 10px;
  color: #656d6a;
  background-color: #fff;
  padding: 0 4px;
`;

const StyledSelect = styled(Select)`
  .MuiOutlinedInput-root {
    padding: 12px 4px 12px 20px;
    position: relative;
  }

  .MuiSelect-select:focus {
    background-color: #fff;
  }
`;

interface SimplifiedSelectProps {
  title: string;
  listItems: Readonly<
    Array<{
      value: string | number;
      label: string;
      disabled?: boolean;
    }>
  >;
  editing?: boolean;
  width?: number;
}

const LabelSelector = React.forwardRef(
  (props: SelectProps & SimplifiedSelectProps, ref) => {
    const { label, className, editing, listItems, ...strippedProps } = props;
    const { width, title, value } = strippedProps;

    const displayValue = listItems?.length
      ? listItems.filter((item) => item.value === props.value)[0]?.label
      : '';

    return (
      <BaseField
        label={editing ? undefined : title}
        value={displayValue}
        editing={editing}
        className={className}
        labelPlacement="top"
      >
        <FieldWrapper>
          <StyledSelect
            variant="outlined"
            fullWidth={!!width}
            width={width}
            ref={ref}
            renderValue={() => displayValue}
            {...strippedProps}
          >
            {listItems.map((item) => (
              <MenuItem
                key={item.value}
                value={item.value}
                disabled={!!item.disabled}
              >
                <When isTrue={item.value === value}>
                  <ListItemIcon>
                    <Check />
                  </ListItemIcon>
                </When>
                {item.label}
              </MenuItem>
            ))}
          </StyledSelect>
          <Title>{title}</Title>
        </FieldWrapper>
      </BaseField>
    );
  }
);

export default LabelSelector;

import React, { useState } from 'react';
import { IconButton, TextField as MuiTextField } from '@material-ui/core';
import { Add } from '@material-ui/icons';

type AddOptionProps = {
  onAddOption: (newOption: string) => void;
};

const AddOptionMember = ({ onAddOption }: AddOptionProps) => {
  const [optionLabel, setOptionLabel] = useState('');

  const onFieldChange = (event) => {
    setOptionLabel(event.target.value);
  };

  const onButtonClick = () => {
    onAddOption(optionLabel);
  };

  return (
    <MuiTextField
      variant="outlined"
      size="small"
      onChange={onFieldChange}
      InputProps={{
        endAdornment: (
          <IconButton size="small" onClick={onButtonClick}>
            <Add />
          </IconButton>
        ),
      }}
    />
  );
};

export default AddOptionMember;

/*
 * This is a GENERATED file, do not change manually!
 * Generated:
 * - from file k3-arsredovisning-2021-10-31-rev20230131 - Noter.csv
 * - Comments: AGOY-6886
 */

import {
  value,
  AgoyDocumentPart,
} from '@agoy/document';
import {
  field,
  ixbrlField,
} from '../../../../../common/utils/util';



const cell = { type: 'field' as const };
const boolean = { type: 'boolean' as const };
const type = { type: 'type' as const };
const number = { type: 'number' as const };

export const getNote2086K3v2 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null
): AgoyDocumentPart<typeof note2086K3v2ContentDefinition> => ({
  active: false,
  number: value(undefined),
  name: noteLabel('Poster och delposter som slagits samman för balansräkning'),
  data: {
    active: true,
    NotSammanslagnaPosterDelposterBalansrakning: {
      type: 'group',
      active: true,
      level: 0,
      title: field(
        'Upplysning om poster och delposter som slagits samman för balansräkning'
      ),
      NotSammanslagnaPosterDelposterBalansrakning: ixbrlField(field(''), {
        contextRef: 'period0',
        type: 'stringItemType',
        name: 'se-gen-base:NotSammanslagnaPosterDelposterBalansrakning',
        standardRubrik:
          'Upplysning om poster och delposter som slagits samman för balansräkning',
      }),
    },
  },
  group: value(undefined),
  section: value(undefined),
  validForReportType: {
    k2: true,
    k3: true,
  },
});
export const note2086K3v2ContentDefinition = {
  type: 'part' as const,
  children: {
    active: boolean,
    number: cell,
    name: cell,
    data: {
      type: 'part' as const,
      children: {
        active: boolean,

        NotSammanslagnaPosterDelposterBalansrakning: {
          type: 'part' as const,
          children: {
            type: type,
            level: number,
            active: boolean,

            title: cell,

            NotSammanslagnaPosterDelposterBalansrakning: cell,
          },
        },
      },
    },
    group: cell,
    section: cell,
    validForReportType: {
      type: 'part' as const,
      children: {
        k2: boolean,
        k3: boolean,
      },
    },
  },
};

import { Period } from '@agoy/api-sdk-core';
import { MovedRows } from './types';

/**
 * Generates modified account groups for each changed period. The output is the
 * final state of the custom account groups and should replace everything in the
 * database. If a period is not in the output, it means there are no longer any
 * custom accounts for that period.
 *
 * @returns Modified custom accoung groups for each period
 * @param localMovedAccounts Local changes, taking precedence over saved changes
 * @param storedMovedAccounts Moved accounts saved in the database
 * @param periodsByStart Periods grouped by start date for quick access
 */
const generateCustomAccountGroups = (
  localMovedAccounts: MovedRows,
  storedMovedAccounts: MovedRows,
  periodsByStart: Record<string, Period>
): CustomAccountGroupsType => {
  const customAccountGroups: CustomAccountGroupsType = {};
  const processedAccountsByPeriod: { [periodId: number]: Set<number> } = {};

  const accountsMovedFromOriginalLocation = new Set<number>();

  // The order of the local and stored moved accounts matter. Once an
  // account is moved locally in a certain period, its previous location is
  // ignored. This is tracked and checked using ´processedAccountsByPeriod´.
  [localMovedAccounts, storedMovedAccounts].forEach((movedAccounts) => {
    const accountKeys = Object.keys(movedAccounts).map((item) =>
      parseInt(item, 10)
    );

    accountKeys.forEach((accountKey) => {
      const movedAccountsByPeriodStart = movedAccounts[accountKey];

      Object.keys(movedAccountsByPeriodStart).forEach((periodStart) => {
        const periodId = periodsByStart[periodStart].id;
        const { toGroupId } = movedAccountsByPeriodStart[periodStart];

        // Mark the account as processed for the period, even if it is back to
        // its original location. Further changes to this account are
        // ignored since we process changes from latest to oldest, and further
        // changes are older.
        if (processedAccountsByPeriod[periodId]) {
          if (processedAccountsByPeriod[periodId].has(accountKey)) return;
        } else {
          processedAccountsByPeriod[periodId] = new Set();
        }
        processedAccountsByPeriod[periodId].add(accountKey);

        // If the account is not between the group limits, it means the account
        // is moved from its original location.
        const [groupStart, groupEnd] = toGroupId
          .split('_')
          .map((item) => parseInt(item, 10));
        if (!(accountKey >= groupStart && accountKey <= groupEnd)) {
          accountsMovedFromOriginalLocation.add(accountKey);
        }

        if (!customAccountGroups[periodId]) {
          customAccountGroups[periodId] = [];
        }

        // Find or create the modified group
        const groupId = customAccountGroups[periodId].find(
          (item) => item.groupId === toGroupId
        );
        if (groupId) {
          groupId.accountIds.push(accountKey);
        } else {
          customAccountGroups[periodId].push({
            groupId: toGroupId,
            accountIds: [accountKey],
          });
        }
      });
    });
  });

  // Account is considered moved even if it is moved back to its original
  // location from another group. If given account is not moved from its
  // original location in any periods, we need to remove this account from
  // custom account groups.
  const cleanedCustomAccountGroups = {};
  Object.keys(customAccountGroups).forEach((periodId) => {
    const modifiedGroups = customAccountGroups[periodId];
    modifiedGroups.forEach((modifiedGroup) => {
      const hasAccountsMovedFromOriginalLocation =
        modifiedGroup.accountIds.some((accountKey) =>
          accountsMovedFromOriginalLocation.has(accountKey)
        );
      if (hasAccountsMovedFromOriginalLocation) {
        if (cleanedCustomAccountGroups[periodId]) {
          cleanedCustomAccountGroups[periodId].push(modifiedGroup);
        } else {
          cleanedCustomAccountGroups[periodId] = [modifiedGroup];
        }
      }
    });
  });
  return cleanedCustomAccountGroups;
};

export type ModifiedGroupType = {
  groupId: string;
  accountIds: number[];
};

export type CustomAccountGroupsType = {
  [periodId: number]: ModifiedGroupType[];
};

export default generateCustomAccountGroups;

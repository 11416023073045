import React, { useCallback } from 'react';
import {
  AppBar as MuiAppBar,
  Divider as MuiDivider,
  Toolbar as MuiToolbar,
  Typography,
} from '@material-ui/core';
import { useSelector } from 'redux/reducers';
import { Route, useHistory, useRouteMatch } from 'react-router-dom';
import styled from '@emotion/styled';
import { ClientPersonsRouteParams } from 'routes/types';
import { isFemale } from '_person/utils';
import { formatFinancialYear } from '@agoy/common';
import CustomerButton from '../CustomerSubMenu/CustomerButton';
import SavingStatusButton from '../SavingStatusButton';

const AppBar = styled(MuiAppBar)`
  background: ${({ theme }) => theme.palette.grey[200]};
`;

const Toolbar = styled(MuiToolbar)`
  display: flex;
  justify-content: space-between;
  padding-top: ${({ theme }) => theme.spacing(1)}px;
  padding-bottom: ${({ theme }) => theme.spacing(1)}px;
`;

const Grouper = styled.div`
  display: flex;
  align-items: center;
`;

const Divider = styled(MuiDivider)`
  margin: ${({ theme }) => theme.spacing(1 / 2, 2, 1 / 2, 2)};
`;

export const TaxDeclarationPersonsSubMenu = () => {
  const history = useHistory();
  const {
    params: { personId },
  } = useRouteMatch<ClientPersonsRouteParams>();
  const person = useSelector((state) => state.persons?.[personId]);
  const { currentFinancialYear } = useSelector((state) => state.customerView);

  const handleClick = useCallback(() => {
    history.push(`/persons/${personId}/`);
  }, [history, personId]);

  if (!person) return null;

  return (
    <AppBar position="sticky" elevation={2}>
      <Toolbar>
        {person && (
          <Route path="/persons/:personId/:program">
            <Grouper>
              <CustomerButton
                logoUrl={
                  person.logo || isFemale(person.personNumber)
                    ? '../../../../../female.png'
                    : '../../../../../male.png'
                }
                name={`${person.firstName} ${person.lastName}`}
                handleClick={handleClick}
              />
            </Grouper>
            <div style={{ flex: 1 }} />
            <>
              <Typography
                color="textPrimary"
                style={{ fontWeight: 500, fontSize: '1.25rem' }}
              >
                {currentFinancialYear &&
                  formatFinancialYear(currentFinancialYear)}
              </Typography>
              <Divider orientation="vertical" flexItem />
            </>
            <SavingStatusButton />
          </Route>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default TaxDeclarationPersonsSubMenu;

import { cloneDeep } from 'lodash';
import { ApiErrorType, isApiErrorType } from './api-errors';

type AnyFunction = (...args: any[]) => any;

/**
 * Get the public static method names from a class
 */
export const getStaticMethodNames = (Class: Record<string, any>) => {
  const lengthPrototypeAndName = ['length', 'prototype', 'name'];
  return Object.getOwnPropertyNames(Class).filter(
    (k) => !lengthPrototypeAndName.includes(k)
  );
};

/**
 * Enrich the methods of a given class, by adding a higer-order-function to each method.
 */
export function addHofToClassMethods<T extends Record<string, any>>(
  CustomClass: T,
  hof: AnyFunction
): T {
  const newClass = cloneDeep(CustomClass) as Record<string, any>;
  const methodNames = getStaticMethodNames(CustomClass);
  methodNames.forEach((methodName) => {
    newClass[methodName] = hof(CustomClass[methodName]);
  });

  return newClass as T;
}

/**
 * Extend the functionality of the vanilla SDK service functions
 */
export const methodHof =
  (onError: (error: ApiErrorType) => ApiErrorType) =>
  <Func extends AnyFunction>(
    func: Func
  ): ((...args: Parameters<Func>) => Promise<ReturnType<Func>>) => {
    const wrappedFn = async (
      ...args: Parameters<Func>
    ): Promise<ReturnType<Func>> => {
      try {
        const response = await func(...args);
        return response;
      } catch (error) {
        if (isApiErrorType(error)) {
          return Promise.reject(onError(error));
        }
        return Promise.reject(error);
      }
    };

    return wrappedFn;
  };

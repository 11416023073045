import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';

import { Awaited } from '@agoy/common';
import { asResultClass, getApiSdk } from 'api-sdk';
import { OrganisationSettings } from '@agoy/api-sdk-core';
import { RootState } from 'redux/reducers';
import { setUiStatus } from 'redux/actions/UI';
import { getContext } from 'utils/AgoyAppClient/contextHolder';
import { addGlobalMessage } from '_messages/redux/actions';
import { OrganisationType } from '_organization/types';
import { ChecklistTemplate } from '_shared/redux/checklist/types';
import { activeFeatureFlags } from '_shared/HOC/withFeatureFlags';

import {
  INSERT_INVITE_TO_ORG,
  ADD_ORGANISATION_INFO,
  REMOVE_MEMBER,
  SET_ORGANISATION,
  SET_CHECKLISTS,
  ADD_CHECKLIST,
  EDIT_CHECKLIST,
  REMOVE_CHECKLIST,
  SET_SETTINGS,
} from './action-types';

type Sdk = Awaited<ReturnType<typeof getApiSdk>>;
type UpdateOrganisationParams = Awaited<
  Parameters<Sdk['updateOrganisation']>
>[0]['requestBody'];

interface SetOrganisationAction {
  type: typeof SET_ORGANISATION;
  organisation: Partial<OrganisationType>;
}

export const setOrganisation = (
  organisation: Partial<OrganisationType>
): SetOrganisationAction => ({
  type: SET_ORGANISATION,
  organisation,
});

interface SetChecklistsAction {
  type: typeof SET_CHECKLISTS;
  checklists: ChecklistTemplate[];
}

export const setChecklists = (
  checklists: ChecklistTemplate[]
): SetChecklistsAction => ({
  type: SET_CHECKLISTS,
  checklists,
});

export const loadChecklists =
  (): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    const sdk = getApiSdk(getContext());

    const result = await asResultClass(
      sdk.getChecklistsForOrg({ showinactive: true })
    );

    if (result.ok) {
      dispatch(setChecklists(result.val || []));
    }
  };

// Refactor in favor of service-contexts
export const getUserOrganisation =
  (): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    const sdk = getApiSdk(getContext());

    dispatch(setUiStatus({ fetchingOrganisation: true }));
    const result = await asResultClass(sdk.getOrganisation());
    dispatch(setUiStatus({ fetchingOrganisation: false }));

    if (result.ok) {
      dispatch(loadChecklists());
      dispatch(setOrganisation(result.val));

      // temporary solution, will add endpoint for this in future
      if (result.val.id === 'cus_JbojPCnVHzIXBR') {
        activeFeatureFlags.set('feature_avstamningChecklistTemplate', true);
      }
    }
  };

interface AddOrganisationInfoAction {
  type: typeof ADD_ORGANISATION_INFO;
  organisationInfo: Partial<OrganisationType>;
}

export const addOrganisationInfo = (
  organisationInfo: Partial<OrganisationType>
): AddOrganisationInfoAction => ({
  type: ADD_ORGANISATION_INFO,
  organisationInfo,
});

export const updateOrganisationInfo =
  (
    organisationInfo: UpdateOrganisationParams
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    const sdk = getApiSdk(getContext());

    dispatch(setUiStatus({ fetchingOrganisation: true }));
    const result = await asResultClass(
      sdk.updateOrganisation({
        requestBody: {
          ...organisationInfo,
        },
      })
    );
    dispatch(setUiStatus({ fetchingOrganisation: false }));

    if (result.ok) {
      dispatch(addOrganisationInfo(organisationInfo));
    } else {
      throw Error(result.val.message);
    }
  };

export const attachAuthorisedPersons = async (members: Member.MemberType[]) => {
  const context = getContext();
  const sdk = getApiSdk(context);

  const updatedMembers: Member.MemberType[] = await Promise.all(
    members.map(async (member): Promise<Member.MemberType> => {
      const response = await asResultClass(
        sdk.getAuthorizedClientPersonsForUser({ userId: member.userId })
      );

      if (response.ok) {
        return {
          ...member,
          authorisedPersons: response.val,
        };
      }

      return member;
    })
  );

  return updatedMembers;
};

export const getOrganisationMembers =
  (
    withAuthorizedPersons = false
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    const context = getContext();
    const sdk = getApiSdk(context);

    dispatch(setUiStatus({ fetchingOrgisationMembers: true }));
    const response = await asResultClass(sdk.getOrganisationMembers());
    dispatch(setUiStatus({ fetchingOrgisationMembers: false }));

    if (response.ok) {
      const users = response.val as Member.MemberType[];
      if (withAuthorizedPersons) {
        const usersWithAuthorizedPersons = await attachAuthorisedPersons(
          response.val as Member.MemberType[]
        );
        dispatch(addOrganisationInfo({ users: usersWithAuthorizedPersons }));
      } else {
        dispatch(addOrganisationInfo({ users }));
      }
    } else {
      throw Error(response.val.message);
    }
  };

interface InsertInviteToOrgAction {
  type: typeof INSERT_INVITE_TO_ORG;
  invite;
}

export const insertInviteToOrg = (invite): InsertInviteToOrgAction => ({
  type: INSERT_INVITE_TO_ORG,
  invite,
});

interface RemoveMemberAction {
  type: typeof REMOVE_MEMBER;
  email;
}

export const removeMember = (email): RemoveMemberAction => ({
  type: REMOVE_MEMBER,
  email,
});

export const deleteMember =
  (
    user: Member.MemberType
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    const sdk = getApiSdk(getContext());

    const response = await asResultClass(
      sdk.deleteOrganisationMember({ memberId: user.userId })
    );

    if (response.ok) {
      dispatch(removeMember(user.email));
    } else {
      throw Error(response.val.message);
    }
  };

export const makeMemberAdmin =
  (
    user: Member.MemberType
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    const sdk = getApiSdk(getContext());

    const result = await asResultClass(
      sdk.updateOrgMemberRoles({
        memberId: user.userId,
        requestBody: {
          role: 'OrganisationAdmin',
        },
      })
    );

    if (result.ok) {
      dispatch(getOrganisationMembers());
      dispatch(
        addGlobalMessage('success', 'dashboard.members.changeRole.success')
      );
    }
  };

export const removeMemberAdmin =
  (
    user: Member.MemberType
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    const sdk = getApiSdk(getContext());

    const result = await asResultClass(
      sdk.updateOrgMemberRoles({
        memberId: user.userId,
        requestBody: {
          role: 'AccountingConsultant',
        },
      })
    );

    if (result.ok) {
      dispatch(getOrganisationMembers());
      dispatch(
        addGlobalMessage('success', 'dashboard.members.changeRole.success')
      );
    }
  };

export const toggleMemberAuthorisationForPerson =
  (
    member: Member.MemberType,
    personId: string,
    isAuthorised: boolean
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    const sdk = getApiSdk(getContext());
    const params = {
      userId: member.userId,
      clientId: personId,
    };

    const request = isAuthorised
      ? sdk.deleteAuthorizedClientPersonForUser(params)
      : sdk.authorizeClientPersonForUser(params);

    const result = await asResultClass(request);

    if (result.ok) {
      return dispatch(getOrganisationMembers());
    }

    const messageId = isAuthorised
      ? 'dashboard.members.edit.removeAccessFailed'
      : 'dashboard.members.edit.grantAccessFailed';
    return dispatch(addGlobalMessage('error', messageId));
  };

interface AddChecklistAction {
  type: typeof ADD_CHECKLIST;
  checklistTemplate: ChecklistTemplate;
}

export const addChecklist = (
  checklistTemplate: ChecklistTemplate
): AddChecklistAction => ({
  type: ADD_CHECKLIST,
  checklistTemplate,
});

export const createChecklistTemplate =
  (
    checklistTemplate: ChecklistTemplate
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    const sdk = getApiSdk(getContext());

    const result = await asResultClass(
      sdk.createCheckListTemplate({ requestBody: checklistTemplate })
    );

    if (result.ok) {
      dispatch(loadChecklists());
      return dispatch(
        addChecklist({ ...checklistTemplate, id: result.val.id })
      );
    }

    throw new Error('error');
  };

interface EditChecklistAction {
  type: typeof EDIT_CHECKLIST;
  checklistTemplate: ChecklistTemplate;
}

export const editChecklist = (
  editedChecklistTemplate: ChecklistTemplate
): EditChecklistAction => ({
  type: EDIT_CHECKLIST,
  checklistTemplate: editedChecklistTemplate,
});

interface RemoveChecklistAction {
  type: typeof REMOVE_CHECKLIST;
  checklistTemplate: ChecklistTemplate;
}

export const removeChecklist = (
  deleteChecklistTemplate: ChecklistTemplate
): RemoveChecklistAction => ({
  type: REMOVE_CHECKLIST,
  checklistTemplate: deleteChecklistTemplate,
});

export const deleteChecklistTemplate =
  (
    checklistTemplateId: number
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    const sdk = getApiSdk(getContext());

    const result = await asResultClass(
      sdk.hideCheckListTemplate({
        requestBody: { templateId: checklistTemplateId },
      })
    );

    const checklist = getState().organisation.checklists.find(
      (item) => item.id === checklistTemplateId
    );

    if (result.ok && checklist) {
      dispatch(editChecklist({ ...checklist, hidden: true }));
    } else {
      throw new Error('error');
    }
  };

export const updateChecklistTemplate =
  (
    checklistTemplate: ChecklistTemplate
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    try {
      const sdk = getApiSdk(getContext());
      if (checklistTemplate.id) {
        const checklist = await asResultClass(
          sdk.updateChecklistTemplate({
            checklistId: checklistTemplate.id,
            requestBody: { ...checklistTemplate, id: checklistTemplate.id },
          })
        );
        if (checklist.ok && checklist.val) {
          dispatch(editChecklist({ ...checklist.val }));
          dispatch(loadChecklists());
          return;
        }
      }
      throw new Error('error');
    } catch (e) {
      throw new Error('error');
    }
  };

export const setChecklistTemplateState =
  (
    checklistTemplateId: number,
    state: 'active' | 'inactive'
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    try {
      const checklistTemplate = getState().organisation.checklists.find(
        (item) => item.id === checklistTemplateId
      );

      if (checklistTemplate && checklistTemplate.id) {
        const sdk = getApiSdk(getContext());

        const result = await asResultClass(
          sdk.setCheckListTemplateState({
            requestBody: { templateId: checklistTemplate.id, state },
          })
        );

        if (result.ok) {
          dispatch(editChecklist({ ...checklistTemplate, state }));
        }
      }
    } catch (e) {
      throw new Error('error');
    }
  };

interface SetSettingsAction {
  type: typeof SET_SETTINGS;
  settings: OrganisationSettings;
}

export const setSettings = (
  settings: OrganisationSettings
): SetSettingsAction => ({
  type: SET_SETTINGS,
  settings,
});

export const changeOrganizationsSettings =
  (
    part: keyof OrganisationSettings,
    key: keyof OrganisationSettings[typeof part],
    value: OrganisationSettings[typeof part][typeof key]
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    const { settings } = getState().organisation;
    try {
      const sdk = getApiSdk(getContext());

      const updatedSettings = {
        ...(settings || {}),
        [part]: {
          ...(settings?.[part] || {}),
          [key]: value,
        },
      };

      const result = await asResultClass(
        sdk.updateOrganisationSettings({
          requestBody: {
            [part]: {
              [key]: value,
            },
          },
        })
      );

      if (result.ok) {
        dispatch(setSettings(updatedSettings));
      }
    } catch (e) {
      throw new Error('error');
    }
  };

export type OrganizationAction =
  | SetOrganisationAction
  | AddOrganisationInfoAction
  | InsertInviteToOrgAction
  | RemoveMemberAction
  | SetChecklistsAction
  | EditChecklistAction
  | AddChecklistAction
  | RemoveChecklistAction
  | SetSettingsAction;

import React from 'react';
import NonSuppBrowserSplash from '_shared/components/NonSuppBrowserView';
import MobileSplash from '_shared/components/NonSuppMobileView';
import { isMobile, isNotSupportedBrowser } from '_shared/services/deviceDetect';

/**
 * Render fallback screen for unsupported devices
 */
const withSupportFallback = (Component) => (props) => {
  if (isMobile(navigator.userAgent)) {
    return <MobileSplash />;
  }

  if (isNotSupportedBrowser(navigator.userAgent)) {
    return <NonSuppBrowserSplash />;
  }

  return <Component {...props} />;
};

export default withSupportFallback;

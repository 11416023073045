import React from 'react';
import { Box } from '@material-ui/core';
import { NoteOneTableWithSum } from '@agoy/annual-report-document';
import { Field, numberValue, stringValue } from '@agoy/document';
import Comment from '../Comment';
import Header from '../Header';
import { Note } from '../Note';
import NoteTable from '../NoteTable';

type ThreeColumnSumNoteProps = {
  noteId: string;
  rowsInGroupsOf?: number;
  title: string;
  commentLabel: Field;
  hasPreviousYear?: boolean;
} & NoteOneTableWithSum;

const ThreeColumnSumNote = ({
  noteId,
  active,
  number,
  table,
  rowsInGroupsOf,
  sumTable,
  comment,
  title,
  commentLabel,
  hasPreviousYear = false,
}: ThreeColumnSumNoteProps) => {
  if (!active) return null;

  return (
    <Note id={noteId}>
      <Header noteId={noteId} noteNumber={numberValue(number)} title={title} />

      <Box mt={1} mb={1}>
        <NoteTable
          hasPreviousYear={hasPreviousYear}
          table={table}
          tableId={`notes.${noteId}.table`}
          groupRowsInterval={rowsInGroupsOf}
        />
      </Box>
      <Box mt={1} mb={1}>
        <NoteTable
          hasPreviousYear={hasPreviousYear}
          table={sumTable}
          tableId={`notes.${noteId}.sumTable`}
        />
      </Box>

      {/* Comment */}
      <Comment
        field={comment}
        value={stringValue(comment) || ''}
        labelValue={stringValue(commentLabel) || ''}
      />
    </Note>
  );
};

export default ThreeColumnSumNote;

import React from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import styled from '@emotion/styled';
import { ButtonBase, ButtonBaseProps } from '@material-ui/core';
import { getClasses } from '@agoy/common';

const StyledButton = styled(ButtonBase)`
  margin-left: ${(props) => props.theme.spacing(2)}px;
  color: ${(props) => props.theme.palette.grey[700]};
  &:hover {
    color: ${(props) => props.theme.palette.error.main};
  }
  &.disabled {
    color: ${(props) => props.theme.palette.button.disabled};
  }
`;

type DeleteButtonProps = Pick<
  ButtonBaseProps,
  'onClick' | 'className' | 'disabled'
>;

const DeleteButton = ({
  onClick,
  className,
  disabled = false,
}: DeleteButtonProps) => (
  <StyledButton
    onClick={onClick}
    className={`${className ?? ''} ${getClasses({ disabled })}`}
    disabled={disabled}
  >
    <DeleteIcon />
  </StyledButton>
);

export default DeleteButton;

import styled from '@emotion/styled';

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  margin: ${({ theme }) => theme.spacing(1)}px 0;
`;

export default Row;

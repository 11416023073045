import { value, ref } from '@agoy/document';
import { NoteOneTableSection, IxbrlCell } from '../../../../common';
import {
  sumAccountsIBs,
  sumPositiveTransactions,
  sumNegativeTransactions,
  sumNotesRowsById,
} from '../../../../common/utils/accountSumsUtil';
import { year, previousYear } from '../../../../common/utils/date-util';
import {
  field,
  ixbrlCell,
  ixbrlField,
  range,
  table,
} from '../../../../common/utils/util';
import { NoteAccountRanges } from '../types';

const MONETARY_ITEM_TYPE = 'monetaryItemType';

export const note22Accounts: NoteAccountRanges[] = [
  {
    rowId: '1',
    ranges: [[1350, 1357]],
    cellLabel: 'Ingående anskaffningsvärden',
    action: 'sumAccountsIBs',
  },
  {
    rowId: '2',
    ranges: [[1350, 1357]],
    cellLabel: 'Inköp',
    action: 'sumPositiveTransactions',
  },
  {
    rowId: '3',
    ranges: [[1350, 1357]],
    cellLabel: 'Försäljningar',
    action: 'sumNegativeTransactions',
  },
  {
    rowId: '6',
    ranges: [[1358, 1359]],
    cellLabel: 'Ingående nedskrivningar',
    action: 'sumAccountsIBs',
  },
  {
    rowId: '7',
    ranges: [[1358, 1359]],
    cellLabel: 'Försäljningar',
    action: 'sumPositiveTransactions',
  },
  {
    rowId: '10',
    ranges: [[1358, 1359]],
    cellLabel: 'Årets nedskrivningar',
    action: 'sumNegativeTransactions',
  },
];

export const getNote22 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null,
  documentTypeVersion = '1'
): NoteOneTableSection => {
  return {
    active: false,
    number: value(undefined),
    name: value('Andra långfristiga värdepappersinnehav'),
    table: table<IxbrlCell>(
      'notes.note22.table',
      { id: 'label', label: '' },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      .addRows((rows) =>
        rows
          // 'ingående' = previous period outgoing balance
          .addRow(
            '1',
            noteLabel('Ingående anskaffningsvärden'),
            ixbrlCell(ref(sumAccountsIBs('1350:1357', false)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndraLangfristigaVardepappersinnehavAnskaffningsvarden',
              contextRef: 'balans1',
              saldo: 'debit',
            }),
            ixbrlCell(ref(sumAccountsIBs('1350:1357', false, 'previousYear')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndraLangfristigaVardepappersinnehavAnskaffningsvarden',
              contextRef: 'balans2',
              saldo: 'debit',
            })
          )
          .addRow(
            '2',
            noteLabel('Inköp'),
            ixbrlCell(ref(sumPositiveTransactions('1350:1357')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndraLangfristigaVardepappersinnehavForandringAnskaffningsvardenInkop',
              contextRef: 'period0',
              standardRubrik:
                'Ökning av anskaffningsvärden i samband med inköp av andra långfristiga värdepappersinnehav',
            }),
            ixbrlCell(
              ref(sumPositiveTransactions('1350:1357', 'previousYear')),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:AndraLangfristigaVardepappersinnehavForandringAnskaffningsvardenInkop',
                contextRef: 'period1',
                standardRubrik:
                  'Ökning av anskaffningsvärden i samband med inköp av andra långfristiga värdepappersinnehav',
              }
            )
          )
          .addRow(
            'anskaffningsvardenFusion',
            noteLabel('Fusion'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndraLangfristigaVardepappersinnehavForandringAnskaffningsvardenFusion',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden för andra långfristiga värdepappersinnehav genom fusion',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndraLangfristigaVardepappersinnehavForandringAnskaffningsvardenFusion',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden för andra långfristiga värdepappersinnehav genom fusion',
            })
          )
          .addRow(
            '3',
            noteLabel('Försäljningar'),
            ixbrlCell(ref(sumNegativeTransactions('1350:1357')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndraLangfristigaVardepappersinnehavForandringAnskaffningsvardenForsaljningar',
              contextRef: 'period0',
              standardRubrik:
                'Minskning av anskaffningsvärden i samband med försäljningar av andra långfristiga värdepappersinnehav',
              negateValue: true,
            }),
            ixbrlCell(
              ref(sumNegativeTransactions('1350:1357', 'previousYear')),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:AndraLangfristigaVardepappersinnehavForandringAnskaffningsvardenForsaljningar',
                contextRef: 'period1',
                standardRubrik:
                  'Minskning av anskaffningsvärden i samband med försäljningar av andra långfristiga värdepappersinnehav',
                negateValue: true,
              }
            )
          )
          .addRow(
            'yearResults',
            noteLabel('Årets resultatandel'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndraLangfristigaVardepappersinnehavForandringAnskaffningsvardenAretsResultatAndel',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden i samband periodens resultatandel i handelsbolag som är andra långfristiga värdepappersinnehav',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndraLangfristigaVardepappersinnehavForandringAnskaffningsvardenAretsResultatAndel',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden i samband periodens resultatandel i handelsbolag som är andra långfristiga värdepappersinnehav',
            })
          )
          .addRow(
            '4',
            noteLabel('Omklassificeringar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndraLangfristigaVardepappersinnehavForandringAnskaffningsvardenAretsOmklassificeringar',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden i samband med omklassificeringar av andra långfristiga värdepappersinnehav',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndraLangfristigaVardepappersinnehavForandringAnskaffningsvardenAretsOmklassificeringar',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden i samband med omklassificeringar av andra långfristiga värdepappersinnehav',
            })
          )
          .addRow(
            'anskaffningsvardenConversionDifferences',
            noteLabel('Omräkningsdifferenser'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndraLangfristigaVardepappersinnehavForandringAnskaffningsvardenAretsOmrakningsdifferenser',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden i samband med omräkningsdifferenser av andra långfristiga värdepappersinnehav',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndraLangfristigaVardepappersinnehavForandringAnskaffningsvardenAretsOmrakningsdifferenser',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden i samband med omräkningsdifferenser av andra långfristiga värdepappersinnehav',
            })
          )
          .addRow(
            '5',
            noteLabel('Utgående  anskaffningsvärden'),
            ixbrlCell(
              sumNotesRowsById(
                22,
                [
                  ...range(1, 5),
                  'anskaffningsvardenFusion',
                  'yearResults',
                  'anskaffningsvardenConversionDifferences',
                ],
                'year'
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:AndraLangfristigaVardepappersinnehavAnskaffningsvarden',
                contextRef: 'balans0',
                saldo: 'debit',
              }
            ),
            ixbrlCell(
              sumNotesRowsById(
                22,
                [
                  ...range(1, 5),
                  'anskaffningsvardenFusion',
                  'yearResults',
                  'anskaffningsvardenConversionDifferences',
                ],
                'previousYear'
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:AndraLangfristigaVardepappersinnehavAnskaffningsvarden',
                contextRef: 'balans1',
                saldo: 'debit',
              }
            )
          )
          .addRow(
            '6',
            noteLabel('Ingående nedskrivningar'),
            ixbrlCell(ref(sumAccountsIBs('1358+1359', false)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndraLangfristigaVardepappersinnehavNedskrivningar',
              contextRef: 'balans1',
              saldo: 'credit',
              negateValue: true,
            }),
            ixbrlCell(ref(sumAccountsIBs('1358+1359', false, 'previousYear')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndraLangfristigaVardepappersinnehavNedskrivningar',
              contextRef: 'balans2',
              saldo: 'credit',
              negateValue: true,
            })
          )
          .addRow(
            'nedskrivningarFusion',
            noteLabel('Fusion'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndraLangfristigaVardepappersinnehavForandringNedskrivningarFusion',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av nedskrivningar för andra långfristiga värdepappersinnehav genom fusion',
              negateValue: true,
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndraLangfristigaVardepappersinnehavForandringNedskrivningarFusion',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av nedskrivningar för andra långfristiga värdepappersinnehav genom fusion',
              negateValue: true,
            })
          )
          .addRow(
            '7',
            noteLabel('Försäljningar'),
            ixbrlCell(ref(sumPositiveTransactions('1358+1359')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndraLangfristigaVardepappersinnehavForandringNedskrivningarAterfordaNedskrivningarForsaljningar',
              contextRef: 'period0',
            }),
            ixbrlCell(
              ref(sumPositiveTransactions('1358+1359', 'previousYear')),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:AndraLangfristigaVardepappersinnehavForandringNedskrivningarAterfordaNedskrivningarForsaljningar',
                contextRef: 'period1',
              }
            )
          )
          .addRow(
            '8',
            noteLabel('Återförda nedskrivningar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndraLangfristigaVardepappersinnehavForandringNedskrivningarAterfordaNedskrivningar',
              contextRef: 'period0',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndraLangfristigaVardepappersinnehavForandringNedskrivningarAterfordaNedskrivningar',
              contextRef: 'period1',
            })
          )
          .addRow(
            '9',
            noteLabel('Omklassificeringar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndraLangfristigaVardepappersinnehavForandringNedskrivningarOmklassificeringar',
              contextRef: 'period0',
              standardRubrik:
                'Minskning (ökning) av nedskrivningar i samband med omklassificeringar av andra långfristiga värdepappersinnehav',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndraLangfristigaVardepappersinnehavForandringNedskrivningarOmklassificeringar',
              contextRef: 'period1',
              standardRubrik:
                'Minskning (ökning) av nedskrivningar i samband med omklassificeringar av andra långfristiga värdepappersinnehav',
            })
          )
          .addRow(
            '10',
            noteLabel('Årets nedskrivningar'),
            ixbrlCell(ref(sumNegativeTransactions('1358+1359')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndraLangfristigaVardepappersinnehavForandringNedskrivningarAretsNedskrivningar',
              contextRef: 'period0',
              negateValue: true,
            }),
            ixbrlCell(
              ref(sumNegativeTransactions('1358+1359', 'previousYear')),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:AndraLangfristigaVardepappersinnehavForandringNedskrivningarAretsNedskrivningar',
                contextRef: 'period1',
                negateValue: true,
              }
            )
          )
          .addRow(
            'nedskrivningarConversionDifferences',
            noteLabel('Omräkningsdifferenser'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndraLangfristigaVardepappersinnehavForandringNedskrivningarOmrakningsdifferenser',
              contextRef: 'period0',
              standardRubrik:
                'Minskning (ökning) av nedskrivningar i samband med omräkningsdifferenser av andra långfristiga värdepappersinnehav',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndraLangfristigaVardepappersinnehavForandringNedskrivningarOmrakningsdifferenser',
              contextRef: 'period1',
              standardRubrik:
                'Minskning (ökning) av nedskrivningar i samband med omräkningsdifferenser av andra långfristiga värdepappersinnehav',
            })
          )
          .addRow(
            '11',
            noteLabel('Utgående  nedskrivningar'),
            ixbrlCell(
              sumNotesRowsById(
                22,
                [
                  ...range(6, 11),
                  'nedskrivningarFusion',
                  'nedskrivningarConversionDifferences',
                ],
                'year'
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:AndraLangfristigaVardepappersinnehavNedskrivningar',
                contextRef: 'balans0',
                saldo: 'credit',
                negateValue: true,
              }
            ),
            ixbrlCell(
              sumNotesRowsById(
                22,
                [
                  ...range(6, 11),
                  'nedskrivningarFusion',
                  'nedskrivningarConversionDifferences',
                ],
                'previousYear'
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:AndraLangfristigaVardepappersinnehavNedskrivningar',
                contextRef: 'balans1',
                saldo: 'credit',
                negateValue: true,
              }
            )
          )
          .addRow(
            '12',
            noteLabel('Redovisat värde'),
            ixbrlCell(sumNotesRowsById(22, [5, 11], 'year'), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndraLangfristigaVardepappersinnehav',
              contextRef: 'balans0',
              saldo: 'debit',
            }),
            ixbrlCell(sumNotesRowsById(22, [5, 11], 'previousYear'), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndraLangfristigaVardepappersinnehav',
              contextRef: 'balans1',
              saldo: 'debit',
            })
          )
          .build()
      )
      .newRowTemplate(value(''), value(undefined), value(undefined))
      .build(),
    commentLabel: field('Kommentar'),
    comment: ixbrlField(field(''), {
      type: 'stringItemType',
      name: 'se-gen-base:NotAndraLangfristigaVardepappersinnehavKommentar',
      contextRef: 'period0',
    }),
    group: value(undefined),
    section: value(undefined),
    validForReportType: {
      k2: true,
      k3: documentTypeVersion === '1',
    },
  };
};

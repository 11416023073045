import { Awaited } from '@agoy/common';
import { getApiSdk } from 'api-sdk';
import { CompanyConnection } from '_client-connections/types';

type Sdk = Awaited<ReturnType<typeof getApiSdk>>;

type PersonManager = { manager?: string };

export type PersonDetails = Awaited<ReturnType<Sdk['getClientPersonById']>>;

export type Checklist = {
  financialYearId: number;
  templateId: number;
  program: string;
};

type TaxAddress = {
  zipCode: string;
  street: string;
  city: string;
  additionalAddressField1: string;
  additionalAddressField2: string;
  country: string;
};

export type ProgramStatus = {
  createdAt: string;
  period: string;
  program: string;
  status: string;
  financialYear?: string;
};

export type PrivatePerson = {
  id?: string;
  firstName: string;
  lastName: string;
  contactPhoneNumber: string;
  personNumber: string;
  organisationId: string;
  contactEmail: string;
  logo: string;
  managerId: string | null;
  taxAddress: TaxAddress;
  programStatus: ProgramStatus[] | null | undefined;
  orgMembersAuthorised: string[] | null;
  financialYears?: string[];
  connections?: CompanyConnection[];
  rawFinancialYears: {
    id: number;
    start: string;
    end: string;
  }[];
  checkLists?: Checklist[];
};

export type PrivatePersonWithManager = PrivatePerson & PersonManager;

export const defaultPerson: PrivatePersonWithManager = {
  manager: '',
  firstName: '',
  lastName: '',
  contactPhoneNumber: '',
  personNumber: '',
  organisationId: '',
  contactEmail: '',
  logo: '',
  managerId: '',
  taxAddress: {
    zipCode: '',
    street: '',
    city: '',
    additionalAddressField1: '',
    additionalAddressField2: '',
    country: 'Sverige',
  },
  programStatus: null,
  orgMembersAuthorised: [],
  financialYears: undefined,
  connections: undefined,
  rawFinancialYears: [],
};

import React from 'react';
import styled from '@emotion/styled';

import Typography from '@material-ui/core/Typography';
import { ccyFormat } from '@agoy/common';

const StyledTypography = styled(Typography)`
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
`;

const Cell = ({ cell, formatValueInThousands = false }): JSX.Element | null => {
  const { value } = cell;

  if (cell.value === undefined || typeof cell?.value === 'boolean') {
    return null;
  }
  let resultValue = `${value}`;
  if (typeof value === 'object') {
    resultValue = '';
  }
  if (typeof value === 'number') {
    resultValue = formatValueInThousands
      ? ccyFormat(value / 1000, 0)
      : ccyFormat(value, 0);
  }

  return <StyledTypography>{resultValue}</StyledTypography>;
};

export default Cell;

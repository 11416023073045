import {
  TaxDeclarationFormDefinition,
  TaxDeclarationFormDataConfig,
} from '../../types';
import { id, ref, value } from '@agoy/document';
import { mapFormId, toInitial, toStructure } from '../forms-util';

// This one is only for company client
const configN8 = (
  form: TaxDeclarationFormDefinition,
  financialYearStart: string,
  financialYearEnd: string
): TaxDeclarationFormDataConfig => {
  const N8 = mapFormId(form, 'N8');
  const initial = {
    N8: {
      partType: 'form' as const,
      id: N8,
      fields: {
        datum: value(''),
        numrering_flera_N8: value(undefined),
        namn: value(''),
        person_or_organisationsnummer: value(''),
        A_1_summa: value(undefined),
        A_2_a_fastighetens_beteckning: value(''),
        A_2_a_agd_andel: value(undefined),
        A_2_a_belopp: value(undefined),
        A_2_b_fastighetens_beteckning: value(''),
        A_2_b_agd_andel: value(undefined),
        A_2_b_belopp: value(undefined),
        A_2_summa: value(undefined),
        A_3_a_fastighetens_beteckning: value(''),
        A_3_a_agd_andel: value(undefined),
        A_3_a_okat: value(undefined),
        A_3_a_minskat: value(undefined),
        A_3_b_fastighetens_beteckning: value(''),
        A_3_b_agd_andel: value(undefined),
        A_3_b_okat: value(undefined),
        A_3_b_minskat: value(undefined),
        A_3_c_fastighetens_beteckning: value(''),
        A_3_c_agd_andel: value(undefined),
        A_3_c_okat: value(undefined),
        A_3_c_minskat: value(undefined),
        B_1_fastighetens_beteckning: value(''),
        B_1_agd_andel: value(undefined),
        B_1_belopp: value(undefined),
        B_2_fastighetens_beteckning: value(''),
        B_2_agd_andel: value(undefined),
        B_2_belopp: value(undefined),
        B_3_fastighetens_beteckning: value(''),
        B_3_agd_andel: value(undefined),
        B_3_okat: value(undefined),
        B_3_minskat: value(undefined),
      },
      sru: {
        // below is just an example to prevent errors in the file
        '7011': value(financialYearStart),
        '7012': value(financialYearEnd),
        '7014': ref(id('N8.fields.numrering_flera_N8')),
        '8440': ref(id('N8.fields.A_1_summa')),
        '8450': ref(id('N8.fields.A_2_a_fastighetens_beteckning')),
        '8451': ref(id('N8.fields.A_2_a_agd_andel')),
        '8452': ref(id('N8.fields.A_2_a_belopp')),
        '8453': ref(id('N8.fields.A_2_b_fastighetens_beteckning')),
        '8454': ref(id('N8.fields.A_2_b_agd_andel')),
        '8455': ref(id('N8.fields.A_2_b_belopp')),
        '8456': ref(id('N8.fields.A_2_summa')),
        '8400': ref(id('N8.fields.A_3_a_fastighetens_beteckning')),
        '8401': ref(id('N8.fields.A_3_a_agd_andel')),
        '8403': ref(id('N8.fields.A_3_a_okat')),
        '8404': ref(id('N8.fields.A_3_a_minskat')),
        '8405': ref(id('N8.fields.A_3_b_fastighetens_beteckning')),
        '8406': ref(id('N8.fields.A_3_b_agd_andel')),
        '8408': ref(id('N8.fields.A_3_b_okat')),
        '8409': ref(id('N8.fields.A_3_b_minskat')),
        '8410': ref(id('N8.fields.A_3_c_fastighetens_beteckning')),
        '8411': ref(id('N8.fields.A_3_c_agd_andel')),
        '8413': ref(id('N8.fields.A_3_c_okat')),
        '8414': ref(id('N8.fields.A_3_c_minskat')),
        '8460': ref(id('N8.fields.B_1_fastighetens_beteckning')),
        '8461': ref(id('N8.fields.B_1_agd_andel')),
        '8462': ref(id('N8.fields.B_1_belopp')),
        '8480': ref(id('N8.fields.B_2_fastighetens_beteckning')),
        '8481': ref(id('N8.fields.B_2_agd_andel')),
        '8482': ref(id('N8.fields.B_2_belopp')),
        '8465': ref(id('N8.fields.B_3_fastighetens_beteckning')),
        '8466': ref(id('N8.fields.B_3_agd_andel')),
        '8463': ref(id('N8.fields.B_3_okat')),
        '8464': ref(id('N8.fields.B_3_minskat')),
      },
    },
  };
  return {
    initial: toInitial(initial, form),
    definition: toStructure(initial, form),
  };
};

export default configN8;

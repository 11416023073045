import { AgoyDocument } from '@agoy/document';
import { annualReportSignaturesContentDefinition } from './document';
import { signaturesConfig as sharesCompanySignaturesConfig } from '../shares-company/signatures/signatures';
import { signaturesConfig as individualCompanySignaturesConfig } from '../individual-company/signatures/individualCompanySignatures';
import { signaturesConfig } from '../common';
import { AnnualReportType } from '../..';

const getAnnualReportSignaturesConfig = (documentType: string) => {
  switch (documentType) {
    case 'shares':
      return sharesCompanySignaturesConfig;
    case 'individual':
      return individualCompanySignaturesConfig;
    default:
      return signaturesConfig;
  }
};

export const annualReportSignaturesDocumentConfig = (
  documentType = 'shares',
  reportType?: AnnualReportType
): AgoyDocument<typeof annualReportSignaturesContentDefinition> => {
  return {
    documentType: 'annualReportSignatures',
    signatures: getAnnualReportSignaturesConfig(documentType)('2', reportType),
  };
};

export { annualReportSignaturesContentDefinition };

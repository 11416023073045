import { Cell, ReferenceCell } from '../Cell';
import { Source } from '../types/source';

export interface AgoyTableColumn {
  id: string;
  label?: string;
  sortKey?: number;
  /**
   * @deprecated use dataType instead and apply needed alignment on front-end
   */
  align?: string;
  dataType?: 'text' | 'numeric';
  active?: boolean;
  canToggle?: boolean;
}

export type AgoyTableRowGenerator<T extends Cell = Cell> = (
  id: string,
  parentId: string,
  params: Record<string, string>
) => AgoyTableRow<T>;

export type AgoyTableCellGenerator<T extends Cell = Cell> = (
  columnId: string,
  /**
   * The "local" id of the row, not the full path
   */
  rowId: string,
  /**
   * The full id of the row, ex. managementReport.multiYearOverview.table.solidity
   */
  fullRowId: string,
  params: Record<string, string>
) => T | null;

export type AgoyColumnTemplate<T extends Cell = Cell> = {
  type: 'string' | 'number';
  cells?: {
    id: string;
    cell: T;
    columnId?: string;
  }[];
  generator?: AgoyTableCellGenerator;
};

export type RowType =
  | 'header'
  | 'row'
  | 'sum'
  | 'account'
  | 'accountsNotAssigned'
  | 'sum_accounts'
  | 'hidden'
  | 'spacer'
  | 'alwaysDisplay'
  | 'alwaysDisplaySum';

/**
 * Object representing a row in your table. A row can have sub rows located in the rows attribute
 */
export interface AgoyTableRow<T extends Cell = Cell> {
  active: boolean | ReferenceCell;
  tableId?: string;
  id: string;
  cells?: Record<string, T>;
  rows?: AgoyTableRow<T>[];
  newRowTemplate?: AgoyTableRow<T> | AgoyTableRowGenerator<T>;
  sortKey?: number;
  type?: RowType;
}

export interface AgoyTable<T extends Cell = Cell> {
  active: boolean | ReferenceCell;
  columns: AgoyTableColumn[];
  rows: AgoyTableRow<T>[];
  newRowTemplate?: AgoyTableRow<T> | AgoyTableRowGenerator<T>;
  newColumnTemplate?: AgoyColumnTemplate<T>;
  source?: Source;
  visible?: boolean | ReferenceCell;
  level?: number;
}

/**
 * Table object has columns and rows
 *
 * @param table any  object
 */
export const isTableType = (table: any): table is AgoyTable => {
  return 'columns' in table && 'rows' in table;
};

import { ref, table, value } from '@agoy/document';
import { IxbrlCell, NoteOneTableSection } from '../../../../common';
import { previousYear, year } from '../../../../common/utils/date-util';
import {
  active,
  field,
  ixbrlCell,
  ixbrlField,
} from '../../../../common/utils/util';
import {
  sumAccountsUBs,
  sumNotesRowsById,
} from '../../../../common/utils/accountSumsUtil';
import { NoteAccountRanges } from '../types';

const MONETARY_ITEM_TYPE = 'monetaryItemType';

export const note90Accounts: NoteAccountRanges[] = [
  {
    rowId: '2',
    ranges: [[8820, 8829]],
    cellLabel: 'Erhållna koncernbidrag',
    action: 'sumAccountsUBs',
  },
  {
    rowId: '3',
    ranges: [[8830, 8839]],
    cellLabel: 'Lämnade koncernbidrag',
    action: 'sumAccountsUBs',
  },
  {
    rowId: '6',
    ranges: [[8811, 8818]],
    cellLabel: 'Avsättning till periodiseringsfonder',
    action: 'sumAccountsUBs',
  },
  {
    rowId: '7',
    ranges: [[8819]],
    cellLabel: 'Återföring av periodiseringsfonder',
    action: 'sumAccountsUBs',
  },
  {
    rowId: '9',
    ranges: [[8850, 8859]],
    cellLabel: 'Förändring av överavskrivningar',
    action: 'sumAccountsUBs',
  },
  {
    rowId: '12',
    ranges: [[8861, 8864]],
    cellLabel: 'Avsättning till ersättningsfond',
    action: 'sumAccountsUBs',
  },
  {
    rowId: '13',
    ranges: [[8865]],
    cellLabel: 'Ianspråktaget belopp från ersättningsfond för avskrivningar',
    action: 'sumAccountsUBs',
  },
  {
    rowId: '14',
    ranges: [[8866, 8868]],
    cellLabel:
      'Ianspråktaget belopp från ersättningsfond för annat än avskrivningar',
    action: 'sumAccountsUBs',
  },
  {
    rowId: '15',
    ranges: [[8869]],
    cellLabel: 'Återföring från ersättningsfond',
    action: 'sumAccountsUBs',
  },
  {
    rowId: '17',
    ranges: [[8890, 8899]],
    cellLabel: 'Andra bokslutsdispositioner',
    action: 'sumAccountsUBs',
  },
];

export const getNote90 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null
): NoteOneTableSection => {
  return {
    active: false,
    number: value(undefined),
    name: value('Bokslutsdispositioner'),
    table: table<IxbrlCell>(
      'notes.note90.table',
      {
        id: 'label',
        label: 'Bokslutsdispositioner',
      },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      .addRows((rows) =>
        rows
          .addRow(
            '1_title',
            noteLabel('Specifikation av koncernbidrag'),
            undefined,
            undefined
          )
          .addRow(
            '2',
            noteLabel('Erhållna koncernbidrag'),
            ixbrlCell(ref(sumAccountsUBs('8820:8829', true)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ErhallnaKoncernbidrag',
              contextRef: 'period0',
              saldo: 'credit',
            }),
            ixbrlCell(ref(sumAccountsUBs('8820:8829', true)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ErhallnaKoncernbidrag',
              contextRef: 'period1',
              saldo: 'credit',
            })
          )
          .addRow(
            '3',
            noteLabel('Lämnade koncernbidrag'),
            ixbrlCell(ref(sumAccountsUBs('8830:8839', true)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:LamnadeKoncernbidrag',
              contextRef: 'period0',
              saldo: 'debit',
            }),
            ixbrlCell(ref(sumAccountsUBs('8830:8839', true)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:LamnadeKoncernbidrag',
              contextRef: 'period1',
              saldo: 'debit',
            })
          )
          .addRow(
            '4',
            noteLabel('Summa specifikation av koncernbidrag'),
            ixbrlCell(sumNotesRowsById(90, [2, 3], 'year'), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:NettoErhallnaLamnadeKoncernbidrag',
              contextRef: 'period0',
              saldo: 'credit',
            }),
            ixbrlCell(sumNotesRowsById(90, [2, 3], 'previousYear'), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:NettoErhallnaLamnadeKoncernbidrag',
              contextRef: 'period1',
              saldo: 'credit',
            })
          )
          .addRow(
            '5_title',
            noteLabel('Förändring av periodiseringsfonder'),
            undefined,
            undefined
          )
          .addRow(
            '6',
            noteLabel('Avsättning till periodiseringsfonder'),
            ixbrlCell(ref(sumAccountsUBs('8811:8818', true)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForandringPeriodiseringsfondAvsattning',
              contextRef: 'period0',
              saldo: 'debit',
            }),
            ixbrlCell(ref(sumAccountsUBs('8811:8818', true)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForandringPeriodiseringsfondAvsattning',
              contextRef: 'period1',
              saldo: 'debit',
            })
          )
          .addRow(
            '7',
            noteLabel('Återföring av periodiseringsfonder'),
            ixbrlCell(ref(sumAccountsUBs('8819', true)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForandringPeriodiseringsfondAterforing',
              contextRef: 'period0',
              saldo: 'credit',
            }),
            ixbrlCell(ref(sumAccountsUBs('8819', true)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForandringPeriodiseringsfondAterforing',
              contextRef: 'period1',
              saldo: 'credit',
            })
          )
          .addRow(
            '8',
            noteLabel('Summa förändring av periodiseringsfonder'),
            ixbrlCell(sumNotesRowsById(90, [6, 7], 'year'), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForandringPeriodiseringsfond',
              contextRef: 'period0',
              saldo: 'credit',
            }),
            ixbrlCell(sumNotesRowsById(90, [6, 7], 'previousYear'), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForandringPeriodiseringsfond',
              contextRef: 'period1',
              saldo: 'credit',
            })
          )
          .addRow(
            '9',
            noteLabel('Förändring av överavskrivningar'),
            ixbrlCell(ref(sumAccountsUBs('8850:8859', true)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForandringOveravskrivningar',
              contextRef: 'period0',
              saldo: 'credit',
            }),
            ixbrlCell(ref(sumAccountsUBs('8850:8859', true)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForandringOveravskrivningar',
              contextRef: 'period1',
              saldo: 'credit',
            })
          )
          .addRow(
            '10_title',
            noteLabel('Övriga bokslutsdispositioner'),
            undefined,
            undefined
          )
          .addRow(
            '11_title',
            noteLabel('Förändring av ersättningsfond'),
            undefined,
            undefined
          )
          .addRow(
            '12',
            noteLabel('Avsättning till ersättningsfond'),
            ixbrlCell(ref(sumAccountsUBs('8861:8864', true)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AvsattningErsattningsfond',
              contextRef: 'period0',
              saldo: 'debit',
            }),
            ixbrlCell(ref(sumAccountsUBs('8861:8864', true)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AvsattningErsattningsfond',
              contextRef: 'period1',
              saldo: 'debit',
            })
          )
          .addRow(
            '13',
            noteLabel(
              'Ianspråktaget belopp från ersättningsfond för avskrivningar'
            ),
            ixbrlCell(ref(sumAccountsUBs('8865', true)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:IanspraktagetBeloppErsattningsfondAvskrivningar',
              contextRef: 'period0',
              saldo: 'credit',
            }),
            ixbrlCell(ref(sumAccountsUBs('8865', true)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:IanspraktagetBeloppErsattningsfondAvskrivningar',
              contextRef: 'period1',
              saldo: 'credit',
            })
          )
          .addRow(
            '14',
            noteLabel(
              'Ianspråktaget belopp från ersättningsfond för annat än avskrivningar'
            ),
            ixbrlCell(ref(sumAccountsUBs('8866:8868', true)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:IanspraktagetBeloppErsattningsfondAnnatAvskrivningar',
              contextRef: 'period0',
              saldo: 'credit',
            }),
            ixbrlCell(ref(sumAccountsUBs('8866:8868', true)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:IanspraktagetBeloppErsattningsfondAnnatAvskrivningar',
              contextRef: 'period1',
              saldo: 'credit',
            })
          )
          .addRow(
            '15',
            noteLabel('Återföring från ersättningsfond'),
            ixbrlCell(ref(sumAccountsUBs('8869', true)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AterforingErsattningsfond',
              contextRef: 'period0',
              saldo: 'credit',
            }),
            ixbrlCell(ref(sumAccountsUBs('8869', true)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AterforingErsattningsfond',
              contextRef: 'period1',
              saldo: 'credit',
            })
          )
          .addRow(
            '16',
            noteLabel('Summa förändring av ersättningsfond'),
            ixbrlCell(sumNotesRowsById(90, [12, 13, 14, 15], 'year'), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForandringErsattningsfond',
              contextRef: 'period0',
              saldo: 'credit',
            }),
            ixbrlCell(sumNotesRowsById(90, [12, 13, 14, 15], 'previousYear'), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForandringErsattningsfond',
              contextRef: 'period1',
              saldo: 'credit',
            })
          )
          .addRow(
            '17',
            noteLabel('Andra bokslutsdispositioner'),
            ixbrlCell(ref(sumAccountsUBs('8890:8899', true)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndraBokslutsdispositioner',
              contextRef: 'period0',
              saldo: 'credit',
            }),
            ixbrlCell(ref(sumAccountsUBs('8890:8899', true)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndraBokslutsdispositioner',
              contextRef: 'period1',
              saldo: 'credit',
            })
          )
          .addRow(
            '18',
            noteLabel('Summa övriga bokslutsdispositioner'),
            ixbrlCell(sumNotesRowsById(90, [16, 17], 'year'), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OvrigaBokslutsdispositioner',
              contextRef: 'period0',
              saldo: 'credit',
            }),
            ixbrlCell(sumNotesRowsById(90, [16, 17], 'previousYear'), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OvrigaBokslutsdispositioner',
              contextRef: 'period1',
              saldo: 'credit',
            })
          )
          .addRow(
            '19',
            noteLabel('Summa bokslutsdispositioner'),
            ixbrlCell(sumNotesRowsById(90, [4, 8, 9, 18], 'year'), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:Bokslutsdispositioner',
              contextRef: 'period0',
              saldo: 'credit',
            }),
            ixbrlCell(sumNotesRowsById(90, [4, 8, 9, 18], 'previousYear'), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:Bokslutsdispositioner',
              contextRef: 'period1',
              saldo: 'credit',
            })
          )
          .build()
      )
      .build(),
    commentLabel: field('Kommentar till not'),
    comment: ixbrlField(active(field('')), {
      type: 'stringItemType',
      name: 'se-gen-base:NotBokslutsdispositionerKommentar',
      contextRef: 'period0',
    }),
    group: value(undefined),
    section: value(undefined),
    validForReportType: {
      k2: false,
      k3: true,
    },
  };
};

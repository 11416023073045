import React from 'react';
import { Box } from '@material-ui/core';
import { NoteOneTableSection } from '@agoy/annual-report-document';
import { Field, numberValue, stringValue } from '@agoy/document';
import Comment from '../Comment';
import Header from '../Header';
import { Note } from '../Note';
import FourColumnTable from '../FourColumnTable';

type FourColumnNoteProps = {
  noteId: string;
  title: string;
  commentLabel: Field;
  hasPreviousYear?: boolean;
} & NoteOneTableSection;

const FourColumnNote = ({
  active,
  noteId,
  number,
  table,
  comment,
  title,
  commentLabel,
  hasPreviousYear = false,
}: FourColumnNoteProps) => {
  if (!active) return null;

  return (
    <Note id={noteId}>
      <Header noteId={noteId} noteNumber={numberValue(number)} title={title} />

      <Box mt={1} mb={1}>
        <FourColumnTable
          hasPreviousYear={hasPreviousYear}
          table={table}
          tableId={`notes.${noteId}.table`}
        />
      </Box>

      {/* Comment */}
      <Comment
        field={comment}
        value={stringValue(comment) || ''}
        labelValue={stringValue(commentLabel) || ''}
      />
    </Note>
  );
};

export default FourColumnNote;

import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useState,
  MouseEvent,
  useMemo,
} from 'react';
import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';
import { last } from 'lodash';
import { useDispatch } from 'react-redux';
import CommentIcon from '@material-ui/icons/Comment';
import AttachmentIcon from '@material-ui/icons/AttachFile';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import SpecificationIcon from '@material-ui/icons/Assignment';
import { useDragDropManager } from 'react-dnd';
import { useIntl } from 'react-intl';

import { ccyFormat, getClasses } from '@agoy/common';
import { useSelector } from 'redux/reducers';
import { ReconciliationState } from '@agoy/api-sdk-core';
import SaldoCheckbox from '_shared/components/SaldoCheckbox';
import useAPICall from '_shared/hooks/useAPICall';
import { addGlobalErrorMessage } from 'redux/actions';
import Tooltip from '_shared/components/Tooltip';
import When from '_shared/components/When/When';

import { ReconciliationPeriod } from '@agoy/reconciliation';
import HiddenRowsContext from '../../../RowContext/HiddenRowsContext';
import { useAccountDrag } from '../../../RowContext/DndHooks';
import { getAccountStateColor } from '../../../utils';
import { getOriginalGroup } from '../../../RowContext/utils';
import RowHoverContext from '../../../RowContext/RowHoverContext';
import { ReconciliationBalanceAccountRowWithMoving } from '../../../types';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  height: 100%;
  border-bottom: 2px solid #eeeeee;
  border-left: 2px solid #eeeeee;
  border-right: 2px solid #eeeeee;

  &.isHoverEnabled {
    cursor: pointer;

    :hover {
      :not(:has(.correctCheckbox:hover)):not(.isDragging):not(.isPlaceholder) {
        text-decoration: underline;
      }

      .hoverWrapper {
        display: flex;
      }

      .correctCheckbox {
        display: flex;
      }
    }
  }

  &.hiddenRowOpen {
    z-index: ${({ theme }) => theme.zIndex.accountingView.periodRow};
    border-top-left-radius: ${({ theme }) => theme.shape.borderRadius}px;
    border-top-right-radius: ${({ theme }) => theme.shape.borderRadius}px;
    border: 2px solid ${({ theme }) => theme.palette.primary.main};
    border-bottom: 2px solid ${({ theme }) => theme.palette.background.paper};
  }

  &.draggable {
    cursor: grab;

    &.isHoverEnabled {
      :hover {
        > svg {
          visibility: visible;
        }
      }
    }

    &.isDragging {
      cursor: grabbing;

      .correctCheckbox,
      .hoverWrapper {
        display: none;
      }
      > svg {
        visibility: hidden;
      }
      > div {
        color: ${({ theme }) => theme.palette.text.disabled};
      }

      .outgoingBalance {
        background-color: white;
      }
    }
  }

  &.isPlaceholder {
    cursor: initial;

    .outgoingBalance,
    .change {
      color: #bfc6c4;
      font-style: italic;
    }
  }
`;

const HoverWrapper = styled.div`
  position: absolute;
  left: 3px;
  top: 3px;
  width: calc(100% - 6px);
  height: calc(100% - 6px);
  z-index: 1;
  border-radius: 4px;
  background-color: #f2f4f3;
  mix-blend-mode: multiply;
  display: none;
`;

const BalanceCell = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  height: 100%;
  padding: 5px;
`;

const Icons = styled.div`
  & svg {
    font-size: 1rem;
  }
`;

const Change = styled(BalanceCell)`
  border-right: 2px solid #eeeeee;
  background-color: ${(props) => props.theme.palette.background.paper};
  min-width: 95px;
  width: 100%;
`;

const OutgoingBalance = styled(BalanceCell, {
  shouldForwardProp: isPropValid,
})<{ state: ReconciliationState | undefined }>`
  min-width: 161px;
  width: 100%;
  justify-content: space-between;
  background-color: ${({ state }) =>
    getAccountStateColor(state ?? 'not_started')};

  & > div {
    display: flex;
    align-items: center;
  }
`;

const DragIcon = styled(DragIndicatorIcon)`
  position: absolute;
  visibility: hidden;
  font-size: 20px;
  align-self: center;
  left: 4px;
`;

export type AccountCellProps = {
  period: ReconciliationPeriod;
  accountBalance?: ReconciliationBalanceAccountRowWithMoving;
  rowId: string;
  groupId: string;
  companyType: string;
  inHiddenGroupRow: boolean;
  isPeriodLocked: boolean;
};

const AccountCell = ({
  period,
  accountBalance,
  rowId,
  groupId,
  inHiddenGroupRow,
  companyType,
  isPeriodLocked,
}: AccountCellProps) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const [showHidden, setShowHidden] = useState(false);
  const [isDragging, setIsDragging] = useState(false);

  const dragDropManager = useDragDropManager();
  const monitor = dragDropManager.getMonitor();

  const { addHiddenRow, removeHiddenRow } = useContext(HiddenRowsContext);
  const { setHoverRow } = useContext(RowHoverContext);

  const { periodChangeVisible, periodUBVisible, movingAccountsMode } =
    useSelector((state) => state.accountingView);

  const lastPeriod = last(period.periods);

  const state =
    period.type === 'dead' || !accountBalance
      ? 'not_started'
      : accountBalance.state;

  const { isPlaceholder = false, movedTo } = accountBalance || {};

  useEffect(() => {
    const unsubscribe = monitor.subscribeToStateChange(() => {
      setIsDragging(monitor.isDragging());
    });

    return () => {
      unsubscribe();
    };
  }, [monitor]);

  const canDrag =
    movingAccountsMode &&
    period.type !== 'yearEnd' &&
    period.type !== 'dead' &&
    !isPeriodLocked &&
    !isPlaceholder;

  const [{ isDragging: isAccountDragging }, drag] = useAccountDrag(
    rowId,
    groupId,
    canDrag,
    [period.start]
  );

  const { run: updateVisited } = useAPICall(
    async (sdk) => {
      if (!lastPeriod || !accountBalance || state !== 'not_started') {
        return undefined;
      }

      return sdk.putUserInput({
        clientid: period.clientId,
        periodId: lastPeriod.id,
        accountNumber: parseInt(rowId, 10),
        requestBody: {
          visited: true,
        },
      });
    },
    async () => {
      dispatch(addGlobalErrorMessage('error'));
    },
    [lastPeriod, accountBalance, state]
  );

  const { run: onPutActualBalance, status: onPutActualBalanceStatus } =
    useAPICall(
      async (sdk, balance) => {
        if (!lastPeriod) {
          return undefined;
        }

        return Promise.all([
          sdk.putActualBalance({
            clientid: period.clientId,
            periodId: lastPeriod.id,
            account: [parseInt(rowId, 10)],
            preliminary: lastPeriod.preliminary ?? false,
            requestBody: {
              balance,
            },
          }),
          updateVisited(),
        ]);
      },
      async (error) => {
        if (error.status === 409) {
          dispatch(addGlobalErrorMessage('overview.locked'));
        } else {
          dispatch(addGlobalErrorMessage('error'));
        }
      },
      [dispatch, updateVisited, lastPeriod?.id, lastPeriod?.preliminary]
    );

  const clickHandler = useCallback(() => {
    if (accountBalance && !isPlaceholder && !movingAccountsMode) {
      setShowHidden((value) => !value);
    }
  }, [accountBalance, isPlaceholder, movingAccountsMode]);

  const handleClickCheckbox = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();

      if (period.type === 'dead' || isPlaceholder) {
        return;
      }

      const financialYear = lastPeriod?.financialYear;

      if (accountBalance && financialYear) {
        const { ub } = accountBalance;

        const value = state === 'done' || state === 'checked' ? null : ub;

        onPutActualBalance(value);
      }
    },
    [
      period.type,
      isPlaceholder,
      lastPeriod?.financialYear,
      accountBalance,
      state,
      onPutActualBalance,
    ]
  );

  const handleRowHoverOn = useCallback(() => {
    if (!isDragging) {
      setHoverRow({ accountId: rowId, groupId });
    }
  }, [groupId, isDragging, rowId, setHoverRow]);

  const handleRowHoverOut = useCallback(() => {
    setHoverRow(null);
  }, [setHoverRow]);

  useEffect(() => {
    if (accountBalance && showHidden) {
      addHiddenRow(rowId, groupId, period, accountBalance, () => {
        setShowHidden(false);
      });
    }
  }, [
    accountBalance,
    addHiddenRow,
    groupId,
    period,
    removeHiddenRow,
    showHidden,
    rowId,
  ]);

  useEffect(() => {
    if (showHidden) {
      return () => {
        // Closes the hidden row when toggled or component unmounted.
        removeHiddenRow(rowId, period);
      };
    }
    return undefined;
  }, [showHidden, removeHiddenRow, rowId, period]);

  const getMessage = useCallback(
    (id: string, params?: Record<string, string>) =>
      formatMessage(
        {
          id: `reconciliation.${id}`,
        },
        params
      ),
    [formatMessage]
  );

  const getTooltipMessage = useCallback(
    (id: string, params: Record<string, string>) =>
      getMessage(`period.${id}.tooltip`, params),
    [getMessage]
  );

  const getGroupTitle = useCallback(
    (group: string | undefined) => (group ? getMessage(`row.${group}`) : ''),
    [getMessage]
  );

  const tooltipTitle = useMemo(() => {
    if (movingAccountsMode || !movedTo) {
      return '';
    }
    const originalGroup = getOriginalGroup(rowId, companyType);

    if (movedTo === groupId) {
      return getTooltipMessage('movedFromAccount', {
        group: getGroupTitle(originalGroup),
      });
    }

    return getTooltipMessage('movedToAccount', {
      group: getGroupTitle(movedTo),
    });
  }, [
    companyType,
    getGroupTitle,
    getTooltipMessage,
    groupId,
    movedTo,
    movingAccountsMode,
    rowId,
  ]);

  const classes = getClasses({
    isDragging: !!isAccountDragging,
    isHoverEnabled: movingAccountsMode ? canDrag && !isDragging : true,
    draggable: canDrag,
    hiddenRowOpen: showHidden,
    isPlaceholder,
  });

  const dataCy = `${rowId}_${period.type}_${period.start}`;

  return (
    <Tooltip title={tooltipTitle}>
      <Container
        className={classes}
        onClick={clickHandler}
        onMouseEnter={handleRowHoverOn}
        onMouseLeave={handleRowHoverOut}
        data-cy={dataCy}
        ref={drag}
      >
        <DragIcon color="inherit" />

        {periodChangeVisible && (
          <Change className="change" data-cy={`${dataCy}_change`}>
            {accountBalance ? ccyFormat(accountBalance.change) : ''}
          </Change>
        )}

        {periodUBVisible && (
          <OutgoingBalance state={state} className="outgoingBalance">
            {period.type !== 'dead' && accountBalance && (
              <Icons>
                <When
                  isTrue={
                    accountBalance.hasInternalComments ||
                    accountBalance.hasComment
                  }
                >
                  <CommentIcon />
                </When>
                <When isTrue={accountBalance.hasDocuments}>
                  <AttachmentIcon />
                </When>
                <When isTrue={accountBalance.hasSpecifications}>
                  <SpecificationIcon />
                </When>
              </Icons>
            )}
            <div data-cy={`${dataCy}_outgoingBalance`}>
              {accountBalance ? ccyFormat(accountBalance.ub) : ''}
              {period.type !== 'dead' &&
                !isPlaceholder &&
                !movingAccountsMode && (
                  <SaldoCheckbox
                    state={state}
                    hasSpecifications={
                      accountBalance?.hasSpecifications ?? false
                    }
                    visible={inHiddenGroupRow}
                    disabled={onPutActualBalanceStatus === 'pending'}
                    dataCy={`${dataCy}_saldoCheckbox`}
                    onClick={handleClickCheckbox}
                  />
                )}
            </div>
          </OutgoingBalance>
        )}

        <HoverWrapper className="hoverWrapper" />
      </Container>
    </Tooltip>
  );
};

export default memo(AccountCell);

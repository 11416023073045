import {
  PropsOfType,
  BooleanCell,
  AgoyTable,
  ReferenceCell,
} from '@agoy/document';
import {
  EconomicAssociationAnnualReport,
  EconomicAssociationAnnualReportChanges,
  economicAssociationContentDefinition,
} from './economic-association/document';
import {
  IndividualCompanyAnnualReport,
  IndividualCompanyAnnualReportChanges,
  individualCompanyContentDefinition,
} from './individual-company/document';
import {
  SharesCompanyAnnualReport,
  SharesCompanyAnnualReportChanges,
  SharesCompanyAnnualReportK2V2,
  SharesCompanyAnnualReportK3V2,
  SharesCompanyAnnualReportK2V2Changes,
  SharesCompanyAnnualReportK3V2Changes,
  sharesCompanyContentDefinition,
  sharesCompanyContentDefinitionK2V2,
  sharesCompanyContentDefinitionK3V2,
} from './shares-company/document';
import {
  LimitedCompaniesAnnualReport,
  LimitedCompaniesAnnualReportChanges,
  limitedCompaniesContentDefinition,
} from './limited-companies/document';
import {
  NonProfitOrgAnnualReport,
  NonProfitOrgAnnualReportChanges,
  nonProfitOrgContentDefinition,
} from './non-profit-org/document';
import {
  FoundationAnnualReport,
  FoundationAnnualReportChanges,
  foundationContentDefinition,
} from './foundation-company/document';
import { Notes } from './shares-company/types';
import { GeneratedSharesV2K2Notes } from './shares-company/notes/v2/k2/types';
import { GeneratedSharesV2K3Notes } from './shares-company/notes/v2/k3/types';
import { IndividualCompanyNotes } from './individual-company/types';
import { EconomicAssociationNotes } from './economic-association/types';
import { FoundationNotes } from './foundation-company/types';
import { LimitedCompaniesNotes } from './limited-companies/types';
import { NonProfitOrgNotes } from './non-profit-org/types';
import { annualGeneralMeetingContentDefinition } from './annualGeneralMeeting/document';
import { annualReportSignaturesContentDefinition } from './annualReportSignatures/document';
import { annualGeneralMeetingSignaturesContentDefinition } from './annualGeneralMeetingSignatures/document';

export type AnnualReportType = 'k1' | 'k2' | 'k3';

export type IncomeStatementType = 'cost' | 'functional';

export type AnnualReportVersion = '1' | '2';

export type ConfiguredFinancialYear = {
  label: string;
  start: string;
  end: string;
};

export type DocumentConfiguration = {
  reportType: AnnualReportType;
  reportTransitionK3toK2: boolean;
  isDigitalSubmission: boolean | null;
  incomeStatement: IncomeStatementType;
  financialYears: ConfiguredFinancialYear[];
} & ContentDefinitionVariants;

export interface AnnualReportSection {
  active: boolean | BooleanCell | undefined; // FIXME Tobias ??
  level?: number;
  visible?: boolean | ReferenceCell;
}

export type AnnualReportPart = { active: boolean | BooleanCell | undefined }; // FIXME Tobias ??

export type StatementSection = AnnualReportPart & {
  section: AnnualReportSection & {
    table: AgoyTable;
  };
};

export type AnnualReport =
  | SharesCompanyAnnualReport
  | SharesCompanyAnnualReportK2V2
  | SharesCompanyAnnualReportK3V2
  | IndividualCompanyAnnualReport
  | EconomicAssociationAnnualReport
  | LimitedCompaniesAnnualReport
  | NonProfitOrgAnnualReport
  | FoundationAnnualReport;

export type CommonNotes =
  | Notes
  | GeneratedSharesV2K3Notes
  | GeneratedSharesV2K2Notes
  | IndividualCompanyNotes
  | EconomicAssociationNotes
  | FoundationNotes
  | LimitedCompaniesNotes
  | NonProfitOrgNotes;

export type CompaniesAnnualReports = {
  [R in AnnualReport['documentType']]: AnnualReport & { documentType: R };
};

export type AnnualReportPageNumbers = Record<
  Exclude<keyof SharesCompanyAnnualReport, 'documentType' | 'version'>,
  number
>;

export type AnnualReportPartKey = PropsOfType<AnnualReport, AnnualReportPart>;

export function isAnnualReportPartKey(
  part: string
): part is AnnualReportPartKey {
  return sharesCompanyContentDefinition.children[part] !== undefined;
}

export type AnnualReportChanges =
  | SharesCompanyAnnualReportChanges
  | SharesCompanyAnnualReportK2V2Changes
  | SharesCompanyAnnualReportK3V2Changes
  | IndividualCompanyAnnualReportChanges
  | EconomicAssociationAnnualReportChanges
  | LimitedCompaniesAnnualReportChanges
  | NonProfitOrgAnnualReportChanges
  | FoundationAnnualReportChanges;

export type AnnualReportStructure =
  | typeof sharesCompanyContentDefinition
  | typeof sharesCompanyContentDefinitionK2V2
  | typeof sharesCompanyContentDefinitionK3V2
  | typeof individualCompanyContentDefinition
  | typeof economicAssociationContentDefinition
  | typeof limitedCompaniesContentDefinition
  | typeof nonProfitOrgContentDefinition
  | typeof foundationContentDefinition;

export type AnnualReportsStructureTypes = {
  [R in AnnualReportStructure['documentType']]: {
    [V in (AnnualReportStructure & {
      documentType: R;
    })['version']]: AnnualReportStructure & {
      documentType: R;
      version: V;
    };
  };
};

export type AnnualReportTypes = {
  [R in AnnualReportStructure['documentType']]: {
    [V in (AnnualReportStructure & {
      documentType: R;
    })['version']]: AnnualReportStructure & {
      documentType: R;
      version: V;
    };
  };
};

const contentDefinitions = {
  shares: sharesCompanyContentDefinition,
  individual: individualCompanyContentDefinition,
  economic_association: economicAssociationContentDefinition,
  limited_companies: limitedCompaniesContentDefinition,
  non_profit_association: nonProfitOrgContentDefinition,
  foundation: foundationContentDefinition,
};

export type ContentDefinitionVariants =
  | Pick<typeof sharesCompanyContentDefinition, 'documentType' | 'version'>
  | Pick<typeof sharesCompanyContentDefinitionK2V2, 'documentType' | 'version'>
  | Pick<typeof sharesCompanyContentDefinitionK3V2, 'documentType' | 'version'>
  | Pick<typeof individualCompanyContentDefinition, 'documentType' | 'version'>
  | Pick<
      typeof economicAssociationContentDefinition,
      'documentType' | 'version'
    >
  | Pick<typeof limitedCompaniesContentDefinition, 'documentType' | 'version'>
  | Pick<typeof nonProfitOrgContentDefinition, 'documentType' | 'version'>
  | Pick<typeof foundationContentDefinition, 'documentType' | 'version'>;

/**
 * It gives the correct content definition for the specified company type
 * Example: 'shares' companyType will return sharesCompanyContentDefinition
 * @param companyType
 * @returns
 */
export const getContentDefinition = (
  { documentType, version },
  reportType: AnnualReportType
): AnnualReportStructure => {
  if (version === '2' && documentType === 'shares') {
    if (reportType === 'k2') {
      return sharesCompanyContentDefinitionK2V2;
    }
    if (reportType === 'k3') {
      return sharesCompanyContentDefinitionK3V2;
    }
  }

  const definition = contentDefinitions[documentType];
  if (!definition) {
    throw new Error(
      `Invalid content definition requested (${documentType}, version ${version})`
    );
  }
  return definition;
};

export {
  annualGeneralMeetingContentDefinition,
  annualReportSignaturesContentDefinition,
  annualGeneralMeetingSignaturesContentDefinition,
};

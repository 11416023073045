import { value, table, ref } from '@agoy/document';
import { range } from 'lodash';
import { NoteOneTableSection, IxbrlCell } from '../../../../common';
import {
  sumAccountsIBs,
  sumPositiveTransactions,
  sumNegativeTransactions,
  sumNotesRowsById,
} from '../../../../common/utils/accountSumsUtil';
import { year, previousYear } from '../../../../common/utils/date-util';
import { field, ixbrlCell, ixbrlField } from '../../../../common/utils/util';
import { NoteAccountRanges } from '../types';

const MONETARY_ITEM_TYPE = 'monetaryItemType';

export const note17Accounts: NoteAccountRanges[] = [
  {
    rowId: '1',
    ranges: [[1320, 1327], [1329], [1660, 1669]],
    cellLabel: 'Ingående anskaffningsvärden',
    action: 'sumAccountsIBs',
  },
  {
    rowId: '2',
    ranges: [[1320, 1327], [1329], [1660, 1669]],
    cellLabel: 'Tillkommande fordringar',
    action: 'sumPositiveTransactions',
  },
  {
    rowId: '3',
    ranges: [[1320, 1327], [1329], [1660, 1669]],
    cellLabel: 'Reglerade fordringar',
    action: 'sumNegativeTransactions',
  },
  {
    rowId: '6',
    ranges: [[1328]],
    cellLabel: 'Ingående nedskrivningar',
    action: 'sumAccountsIBs',
  },
  {
    rowId: '7',
    ranges: [[1328]],
    cellLabel: 'Reglerade fordringar',
    action: 'sumPositiveTransactions',
  },
  {
    rowId: '10',
    ranges: [[1328]],
    cellLabel: 'Årets nedskrivningar',
    action: 'sumNegativeTransactions',
  },
];

export const getNote17 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null
): NoteOneTableSection => {
  return {
    active: false,
    number: value(undefined),
    name: value('Fordringar hos koncernföretag'),
    table: table<IxbrlCell>(
      'notes.note17.table',
      { id: 'label', label: '' },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      .addRows((rows) =>
        rows
          // 'ingående' = previous period outgoing balance
          .addRow(
            '1',
            noteLabel('Ingående anskaffningsvärden'),
            ixbrlCell(ref(sumAccountsIBs('1320:1327+1329+1660:1669', false)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:FordringarKoncernforetagLangfristigaAnskaffningsvarden',
              contextRef: 'balans1',
              saldo: 'debit',
            }),
            ixbrlCell(
              ref(
                sumAccountsIBs(
                  '1320:1327+1329+1660:1669',
                  false,
                  'previousYear'
                )
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:FordringarKoncernforetagLangfristigaAnskaffningsvarden',
                contextRef: 'balans2',
                saldo: 'debit',
              }
            )
          )
          .addRow(
            '2',
            noteLabel('Tillkommande fordringar'),
            ixbrlCell(
              ref(sumPositiveTransactions('1320:1327+1329+1660:1669')),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:FordringarKoncernforetagLangfristigaForandringAnskaffningsvardenTillkommandeFordringar',
                contextRef: 'period0',
                standardRubrik:
                  'Ökning av anskaffningsvärden i samband med periodens tillkommande långfristiga fordringar på koncernföretag',
              }
            ),
            ixbrlCell(
              ref(
                sumPositiveTransactions(
                  '1320:1327+1329+1660:1669',
                  'previousYear'
                )
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:FordringarKoncernforetagLangfristigaForandringAnskaffningsvardenTillkommandeFordringar',
                contextRef: 'period1',
                standardRubrik:
                  'Ökning av anskaffningsvärden i samband med periodens tillkommande långfristiga fordringar på koncernföretag',
              }
            )
          )
          .addRow(
            'businessAcquisitions',
            noteLabel('Rörelseförvärv'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:FordringarKoncernforetagLangfristigaForandringAnskaffningsvardenRorelseforvarv',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden för långfristiga fordringar hos koncernföretag genom rörelseförvärv',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:FordringarKoncernforetagLangfristigaForandringAnskaffningsvardenRorelseforvarv',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden för långfristiga fordringar hos koncernföretag genom rörelseförvärv',
            })
          )
          .addRow(
            'anskaffningsvardenFusion',
            noteLabel('Fusion'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:FordringarKoncernforetagLangfristigaForandringAnskaffningsvardenFusion',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden för långfristiga fordringar hos koncernföretag genom fusion',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:FordringarKoncernforetagLangfristigaForandringAnskaffningsvardenFusion',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden för långfristiga fordringar hos koncernföretag genom fusion',
            })
          )
          .addRow(
            '3',
            noteLabel('Reglerade fordringar'),
            ixbrlCell(
              ref(sumNegativeTransactions('1320:1327+1329+1660:1669')),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:FordringarKoncernforetagLangfristigaForandringAnskaffningsvardenRegleradeFordringar',
                contextRef: 'period0',
                standardRubrik:
                  'Minskning av anskaffningsvärden i samband med periodens reglerade långfristiga fordringar hos koncernföretag',
                negateValue: true,
              }
            ),
            ixbrlCell(
              ref(
                sumNegativeTransactions(
                  '1320:1327+1329+1660:1669',
                  'previousYear'
                )
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:FordringarKoncernforetagLangfristigaForandringAnskaffningsvardenRegleradeFordringar',
                contextRef: 'period1',
                standardRubrik:
                  'Minskning av anskaffningsvärden i samband med periodens reglerade långfristiga fordringar hos koncernföretag',
                negateValue: true,
              }
            )
          )
          .addRow(
            'anskaffningsvardenWrittenReceivables',
            noteLabel('Bortskrivna fordringar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:FordringarKoncernforetagLangfristigaForandringAnskaffningsvardenBortskrivnafordringar',
              contextRef: 'period0',
              standardRubrik:
                'Minskning av anskaffningsvärden i samband med bortskrivning av långfristiga fordringar hos koncernföretag',
              negateValue: true,
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:FordringarKoncernforetagLangfristigaForandringAnskaffningsvardenBortskrivnafordringar',
              contextRef: 'period1',
              standardRubrik:
                'Minskning av anskaffningsvärden i samband med bortskrivning av långfristiga fordringar hos koncernföretag',
              negateValue: true,
            })
          )
          .addRow(
            '4',
            noteLabel('Omklassificeringar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:FordringarKoncernforetagLangfristigaForandringAnskaffningsvardenAretsOmklassificeringar',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden i samband med omklassificeringar av långfristiga fordringar hos koncernföretag',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:FordringarKoncernforetagLangfristigaForandringAnskaffningsvardenAretsOmklassificeringar',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden i samband med omklassificeringar av långfristiga fordringar hos koncernföretag',
            })
          )
          .addRow(
            'anskaffningsvardenConversionDifferences',
            noteLabel('Omräkningsdifferenser'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:FordringarKoncernforetagLangfristigaForandringAnskaffningsvardenOmrakningsdifferenser',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden i samband med omräkningsdifferenser av långfristiga fordringar hos koncernföretag',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:FordringarKoncernforetagLangfristigaForandringAnskaffningsvardenOmrakningsdifferenser',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden i samband med omräkningsdifferenser av långfristiga fordringar hos koncernföretag',
            })
          )
          .addRow(
            '5',
            noteLabel('Utgående anskaffningsvärden'),
            ixbrlCell(
              sumNotesRowsById(
                17,
                [
                  ...range(1, 5),
                  'businessAcquisitions',
                  'anskaffningsvardenFusion',
                  'anskaffningsvardenWrittenReceivables',
                  'anskaffningsvardenConversionDifferences',
                ],
                'year'
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:FordringarKoncernforetagLangfristigaAnskaffningsvarden',
                contextRef: 'balans0',
                saldo: 'debit',
              }
            ),
            ixbrlCell(
              sumNotesRowsById(
                17,
                [
                  ...range(1, 5),
                  'businessAcquisitions',
                  'anskaffningsvardenFusion',
                  'anskaffningsvardenWrittenReceivables',
                  'anskaffningsvardenConversionDifferences',
                ],
                'previousYear'
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:FordringarKoncernforetagLangfristigaAnskaffningsvarden',
                contextRef: 'balans1',
                saldo: 'debit',
              }
            )
          )
          .addRow(
            '6',
            noteLabel('Ingående nedskrivningar'),
            ixbrlCell(ref(sumAccountsIBs('1328', false)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:FordringarKoncernforetagLangfristigaNedskrivningar',
              contextRef: 'balans1',
              saldo: 'credit',
              negateValue: true,
            }),
            ixbrlCell(ref(sumAccountsIBs('1328', false, 'previousYear')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:FordringarKoncernforetagLangfristigaNedskrivningar',
              contextRef: 'balans2',
              saldo: 'credit',
              negateValue: true,
            })
          )
          .addRow(
            '7',
            noteLabel('Reglerade fordringar'), // it was Amortering, avgående fordringar
            ixbrlCell(ref(sumPositiveTransactions('1328')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:FordringarKoncernforetagLangfristigaForandringNedskrivningarRegleradeFordringar',
              contextRef: 'period0',
            }),
            ixbrlCell(ref(sumPositiveTransactions('1328', 'previousYear')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:FordringarKoncernforetagLangfristigaForandringNedskrivningarRegleradeFordringar',
              contextRef: 'period1',
            })
          )
          .addRow(
            'nedskrivningarFusion',
            noteLabel('Fusion'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:FordringarKoncernforetagLangfristigaForandringNedskrivningarFusion',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av nedskrivningar för långfristiga fordringar hos koncernföretag genom fusion',
              negateValue: true,
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:FordringarKoncernforetagLangfristigaForandringNedskrivningarFusion',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av nedskrivningar för långfristiga fordringar hos koncernföretag genom fusion',
              negateValue: true,
            })
          )
          .addRow(
            '8',
            noteLabel('Återförda nedskrivningar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:FordringarKoncernforetagLangfristigaForandringNedskrivningarAterfordaNedskrivningar',
              contextRef: 'period0',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:FordringarKoncernforetagLangfristigaForandringNedskrivningarAterfordaNedskrivningar',
              contextRef: 'period1',
            })
          )
          .addRow(
            'nedskrivningarWrittenReceivables',
            noteLabel('Bortskrivna fordringar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:FordringarKoncernforetagLangfristigaForandringNedskrivningarBortskrivnafordringar',
              contextRef: 'period0',
              standardRubrik:
                'Minskning av nedskrivningar i samband med bortskrivning av långfristiga fordringar hos koncernföretag',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:FordringarKoncernforetagLangfristigaForandringNedskrivningarBortskrivnafordringar',
              contextRef: 'period1',
              standardRubrik:
                'Minskning av nedskrivningar i samband med bortskrivning av långfristiga fordringar hos koncernföretag',
            })
          )
          .addRow(
            '9',
            noteLabel('Omklassificeringar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:FordringarKoncernforetagLangfristigaForandringNedskrivningarAretsOmklassificeringar',
              contextRef: 'period0',
              standardRubrik:
                'Minskning (ökning) av nedskrivningar i samband med omklassificeringar av långfristiga fordringar hos koncernföretag',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:FordringarKoncernforetagLangfristigaForandringNedskrivningarAretsOmklassificeringar',
              contextRef: 'period1',
              standardRubrik:
                'Minskning (ökning) av nedskrivningar i samband med omklassificeringar av långfristiga fordringar hos koncernföretag',
            })
          )
          .addRow(
            '10',
            noteLabel('Årets nedskrivningar'),
            ixbrlCell(ref(sumNegativeTransactions('1328')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:FordringarKoncernforetagLangfristigaForandringNedskrivningarAretsNedskrivningar',
              contextRef: 'period0',
              negateValue: true,
            }),
            ixbrlCell(ref(sumNegativeTransactions('1328', 'previousYear')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:FordringarKoncernforetagLangfristigaForandringNedskrivningarAretsNedskrivningar',
              contextRef: 'period1',
              negateValue: true,
            })
          )
          .addRow(
            'nedskrivningarConversionDifferences',
            noteLabel('Omräkningsdifferenser'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:FordringarKoncernforetagLangfristigaForandringNedskrivningarOmrakningsdifferenser',
              contextRef: 'period0',
              standardRubrik:
                'Minskning (ökning) av nedskrivningar i samband med omräkningsdifferenser av långfristiga fordringar hos koncernföretag',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:FordringarKoncernforetagLangfristigaForandringNedskrivningarOmrakningsdifferenser',
              contextRef: 'period1',
              standardRubrik:
                'Minskning (ökning) av nedskrivningar i samband med omräkningsdifferenser av långfristiga fordringar hos koncernföretag',
            })
          )
          .addRow(
            '11',
            noteLabel('Utgående nedskrivningar'),
            ixbrlCell(
              sumNotesRowsById(
                17,
                [
                  ...range(6, 11),
                  'nedskrivningarFusion',
                  'nedskrivningarWrittenReceivables',
                  'nedskrivningarConversionDifferences',
                ],
                'year'
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:FordringarKoncernforetagLangfristigaNedskrivningar',
                contextRef: 'balans0',
                saldo: 'credit',
                negateValue: true,
              }
            ),
            ixbrlCell(
              sumNotesRowsById(
                17,
                [
                  ...range(6, 11),
                  'nedskrivningarFusion',
                  'nedskrivningarWrittenReceivables',
                  'nedskrivningarConversionDifferences',
                ],
                'previousYear'
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:FordringarKoncernforetagLangfristigaNedskrivningar',
                contextRef: 'balans1',
                saldo: 'credit',
                negateValue: true,
              }
            )
          )
          .addRow(
            '12',
            noteLabel('Redovisat värde'),
            ixbrlCell(sumNotesRowsById(17, [5, 11], 'year'), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:FordringarKoncernforetagLangfristiga',
              contextRef: 'balans0',
              saldo: 'debit',
            }),
            ixbrlCell(sumNotesRowsById(17, [5, 11], 'previousYear'), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:FordringarKoncernforetagLangfristiga',
              contextRef: 'balans1',
              saldo: 'debit',
            })
          )
          .build()
      )
      .newRowTemplate(value(''), value(undefined), value(undefined))
      .build(),
    commentLabel: field('Kommentar'),
    comment: ixbrlField(field(''), {
      type: 'stringItemType',
      name: 'se-gen-base:NotFordringarKoncernforetagLangfristigaKommentar',
      contextRef: 'period0',
    }),
    group: value(undefined),
    section: value(undefined),
    validForReportType: {
      k2: true,
      k3: true,
    },
  };
};

import * as t from 'io-ts';
import { parseMessage } from './common';

const SkateverketSyncCodec = t.type({
  messageId: t.string,
  clientId: t.string,
  userId: t.string,
});

/**
 * Message format for starting synchronize skatteverket transaction.
 * Used by the skatteverket-update, skattevertket-sync lamdbas.
 */
export type SkatteverketSyncMessage = t.TypeOf<typeof SkateverketSyncCodec>;

/**
 * parseSkatteverketSync
 *
 * @param json message in string
 * @returns A result object with a valid message.
 */
export const parseSkatteverketSync = (json: string) =>
  parseMessage(SkateverketSyncCodec, json);


import { useContext } from 'react';
import {
  DragSourceMonitor,
  DropTargetMonitor,
  useDrag,
  useDrop,
} from 'react-dnd';
import { DraggableItemTypes } from 'contants';
import DndContext from './DndContext';
import MoveAccountsContext from './MoveAccountsContext';

type AccountCollectedProps = {
  isOver: boolean;
  canDrop: boolean;
  isDragging?: boolean;
  item?: {
    accountNumber: string;
    groupId: string;
    periods: string[];
  };
};

type AccountDragObject = {
  accountNumber: string;
  groupId: string;
  periods: string[];
  isHeader?: boolean;
};

const collect = (monitor: DropTargetMonitor): AccountCollectedProps => {
  return {
    isOver: !!monitor.isOver(),
    canDrop: !!monitor.canDrop(),
    item: monitor.getItem(),
  };
};

export const useAccountDrop = (targetGroupId: string) => {
  const { moveRow } = useContext(MoveAccountsContext);
  const { setDragOverGroup } = useContext(DndContext);

  return useDrop<AccountDragObject, void, AccountCollectedProps>(
    {
      accept: DraggableItemTypes.RECONCILIATION_ACCOUNT_ROW,
      canDrop: (item) => {
        return item.groupId !== targetGroupId;
      },
      drop: (item) => {
        moveRow(item.accountNumber, item.periods, item.groupId, targetGroupId);
      },
      hover: (item) => {
        setDragOverGroup(
          item.groupId !== targetGroupId
            ? {
                groupId: targetGroupId,
                periodStart: item.periods[0],
                isHeader: item.isHeader,
              }
            : null
        );
      },
      collect,
    },
    [targetGroupId, moveRow]
  );
};

export const useAccountDrag = (
  accountNumber: string,
  groupId: string,
  canDrag: boolean,
  periods: string[],
  isHeader?: boolean
) => {
  const { setDragOverGroup } = useContext(DndContext);

  return useDrag<AccountDragObject, void, AccountCollectedProps>(
    {
      type: DraggableItemTypes.RECONCILIATION_ACCOUNT_ROW,
      item: {
        accountNumber,
        groupId,
        periods,
        isHeader,
      },
      canDrag: () => {
        return canDrag;
      },
      collect: (monitor: DragSourceMonitor) => ({
        canDrop: false,
        isOver: false,
        isDragging: monitor.isDragging(),
      }),
      end: () => {
        setDragOverGroup(null);
      },
    },
    [accountNumber, groupId, canDrag, periods]
  );
};

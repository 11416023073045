import { FinancialReportStructure } from '../../types';
import { field } from '@agoy/annual-report-document';

export const documentsConfig = (): FinancialReportStructure['documents'] => {
  return {
    section: {
      active: true,
      title: field('Bifogade dokument'),
    },
  };
};

import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Dialog } from '@material-ui/core';
import { useIntl } from 'react-intl';
import Button from '_shared/components/Buttons/Button';
import Typography from '_shared/components/Typography/Typography';

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    padding: ${({ theme }) => theme.spacing(4)}px;
    max-width: 550px;
    display: flex;
    gap: 16px;
    border-radius: 8px;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  align-self: flex-end;
`;

interface Props {
  isOpen: boolean;
  onReset: () => Promise<void>;
  onClose: () => void;
}

const ResetDialog = ({ isOpen, onReset, onClose }: Props) => {
  const { formatMessage } = useIntl();

  const [loading, setLoading] = useState(false);

  const handleReset = async () => {
    try {
      setLoading(true);
      await onReset();
    } catch {
      onClose();
    } finally {
      setLoading(false);
    }
  };

  return (
    <StyledDialog open={isOpen} onClose={onClose}>
      <Header>
        <Typography variant="h2">
          {formatMessage({
            id: 'reconciliation.movedAccount.resetDialog.header',
          })}
        </Typography>
      </Header>

      <Typography variant="body1">
        {formatMessage({
          id: 'reconciliation.movedAccount.resetDialog.body',
        })}
      </Typography>

      <ButtonContainer>
        <Button
          label={formatMessage({ id: 'cancel' })}
          variant="text"
          size="large"
          onClick={onClose}
        />
        <Button
          label={formatMessage({
            id: 'reconciliation.movedAccount.resetDialog.reset',
          })}
          variant="contained"
          color="danger"
          size="large"
          loading={loading}
          onClick={handleReset}
        />
      </ButtonContainer>
    </StyledDialog>
  );
};

export default ResetDialog;

import { AgoyDocumentStructure } from '..';
import { AgoyDocumentChanges } from '../changes';
import { AgoyDocument } from '../document';

export type State<T extends AgoyDocumentStructure> = {
  document: AgoyDocument<T>;
  changes: AgoyDocumentChanges<T>;
};

export type Errors = 'INVALID_ID' | 'INVALID_FIELD_TYPE';

export function isError<T extends AgoyDocumentStructure>(
  result: OperationResult<T>
): result is Errors {
  return typeof result === 'string';
}

export type OperationResult<T extends AgoyDocumentStructure, Err = Errors> =
  | State<T>
  | false
  | Err;

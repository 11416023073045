import React, { useState, useEffect, useMemo, useCallback } from 'react';
import {
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';
import CommonTable from '_shared/components/CommonTable/Table';
import { useHistory } from 'react-router-dom';
import { Search } from '@material-ui/icons';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { AgoyTableRow } from '@agoy/document';
import { ClientCompanyType } from '_clients/types/types';
import { asResultClass, useApiSdk } from 'api-sdk';
import { mapClient } from '_clients/services/mappings';
import { useDebounce } from 'utils';
import { getCompanyTypeOptions } from '_client-connections/components/ConnectionsToCompanyView';
import usePersonHaveNEFormsInAlreadyConnectedCompanies from '_client-connections/hooks/usePersonHaveNEFormsInAlreadyConnectedCompanies';
import ConnectionsTabWrapper, {
  ConnectionTabProps,
} from '_client-connections/components/ConnectionsTabWrapper';
import LoadingLogo from '_shared/components/LoadingLogo';
import { StyledTableCell } from '_shared/components/CommonTable/TableRow';
import { Alert } from '@material-ui/lab';
import { getLastFinancialYear } from '_client-connections/utils';
import Button from '_shared/components/Buttons/Button';
import ConfirmationDialog from './ConfirmationDialog';

const Table = styled(CommonTable)`
  width: 60%;
  min-width: 600px;
`;

const MainButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 60%;
  min-width: 600px;
  margin-top: ${({ theme }) => theme.spacing(4)}px;
  gap: ${({ theme }) => theme.spacing(1)}px;
`;

const SearchFieldWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)}px;
  margin-top: ${({ theme }) => theme.spacing(4)}px;
  margin-bottom: ${({ theme }) => theme.spacing(4)}px;
`;

const Info = styled(Alert)`
  margin-top: ${({ theme }) => theme.spacing(2)}px;
`;

const Warning = styled(Alert)`
  margin-top: ${({ theme }) => theme.spacing(1)}px;
  min-width: 600px;
  width: 60%;
`;

const CompanyLink = styled(IconButton)`
  margin-left: ${({ theme }) => theme.spacing(1)}px;
`;

const SearchIcon = {
  startAdornment: (
    <InputAdornment position="start">
      <Search />
    </InputAdornment>
  ),
};

const SearchNote = styled(Typography)`
  margin-top: ${({ theme }) => theme.spacing(2)}px;
`;

type Props = ConnectionTabProps & {
  connectCompany: (company: ClientCompanyType) => void;
};

const SearchForCompanyToConnect = ({
  client,
  path,
  connectCompany,
}: Props): JSX.Element => {
  const { getClients, getTaxDeclarationDocuments } = useApiSdk();
  const history = useHistory();
  const intl = useIntl();
  const text = (id: string) =>
    intl.formatMessage({ id: `connections.addConnection.${id}` });

  const [search, setSearch] = useState('');
  const [searchNote, setSearchNote] = useState(text('startSearch'));
  const [filteredClients, setFilteredClients] = useState<ClientCompanyType[]>(
    []
  );
  const [isSearching, setSearching] = useState(false);

  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [searchForNeConnection, setSearchForNeConnection] = useState(false);
  const [shouldNotMakeConnection, setShouldNotMakeConnection] = useState(false);
  const [selectedCompanyId, setSelectedCompanyId] = useState<
    string | undefined
  >();

  const debouncedClientSearch = useDebounce(search, 1000);

  const clientId = client.id || '';

  const searchCompanies = useCallback(
    async (query: string) => {
      setSearching(true);
      const filteredClientsListResult = await asResultClass(
        getClients({
          clientId,
          operation: 'getNonConnectedClients',
          nameOrgnumberFilter: query,
        })
      );

      if (filteredClientsListResult.ok) {
        setFilteredClients(filteredClientsListResult.val.map(mapClient));
        setSelectedCompanyId(undefined);
        setSearchNote(
          filteredClientsListResult.val.length === 0 ? text('noResult') : ''
        );
      }
      setSearching(false);
    },
    [getClients, clientId]
  );

  const fireConnectCompany = () => {
    if (selectedCompanyId) {
      const company = filteredClients.find((c) => c.id === selectedCompanyId);
      if (!company) {
        return;
      }
      connectCompany(company);
    }
  };

  useEffect(() => {
    if (debouncedClientSearch === '') {
      setFilteredClients([]);
      setSelectedCompanyId(undefined);
      setSearchNote(text('startSearch'));
    } else {
      searchCompanies(debouncedClientSearch);
    }
  }, [debouncedClientSearch]);

  const personHaveNeFormsInAlreadyConnectedCompanies =
    usePersonHaveNEFormsInAlreadyConnectedCompanies(clientId);

  useEffect(() => {
    if (!personHaveNeFormsInAlreadyConnectedCompanies) {
      return;
    }

    setShouldNotMakeConnection(false);

    if (selectedCompanyId === undefined) {
      return;
    }

    const selectedClient = filteredClients.find(
      (c) => c.id === selectedCompanyId
    );

    if (selectedClient?.type !== 'individual') {
      return;
    }

    const fetchTaxOrganisationChart = async () => {
      setSearchForNeConnection(true);
      const taxOrganisationChart = await asResultClass(
        getTaxDeclarationDocuments({
          clientId: selectedClient.id,
          financialYear: getLastFinancialYear(),
        })
      );
      setSearchForNeConnection(false);

      if (taxOrganisationChart.ok) {
        const foundNeForms = taxOrganisationChart.val.some(
          (form) => form.name === 'NE'
        );

        setShouldNotMakeConnection(foundNeForms);
      }
    };

    fetchTaxOrganisationChart();
  }, [
    clientId,
    selectedCompanyId,
    filteredClients,
    personHaveNeFormsInAlreadyConnectedCompanies,
    getTaxDeclarationDocuments,
  ]);

  const columns = [
    {
      id: 'name',
      label: intl.formatMessage({ id: 'connections.detail.companyName' }),
    },
    {
      id: 'stockTotal',
      label: text('specifyShares.fields.totalShares'),
    },
    {
      id: 'type',
      label: intl.formatMessage({ id: 'connections.table.companyType' }),
    },
  ];

  const companyTypesLabels = getCompanyTypeOptions(intl);
  const rows: AgoyTableRow[] = useMemo(
    () =>
      filteredClients.map((client) => {
        const cells = {};

        columns.forEach((col) => {
          if (col.id === 'stockTotal') {
            const { stockTotals } = client;
            cells[col.id] = {
              type: 'string',
              value:
                stockTotals && stockTotals.length > 0
                  ? stockTotals[0].shareTotal
                  : '-',
            };
          } else {
            cells[col.id] = {
              type: 'string',
              value:
                col.id === 'type'
                  ? companyTypesLabels[client[col.id]]
                  : client[col.id],
            };
          }
        });

        return {
          id: `${client.id}`,
          active: true,
          cells,
        };
      }),
    [filteredClients]
  );

  return (
    <ConnectionsTabWrapper
      client={client}
      title={text('title')}
      description={
        <>
          <Typography>{text('description')}</Typography>
          <Info severity="info">{text('info')}</Info>
        </>
      }
    >
      <SearchFieldWrapper>
        <TextField
          variant="outlined"
          size="small"
          placeholder={text('search')}
          onChange={(event) => setSearch(event?.target.value)}
          value={search}
          InputProps={SearchIcon}
        />
      </SearchFieldWrapper>

      {isSearching ? (
        <LoadingLogo size="medium" />
      ) : (
        <>
          <Table
            baseId="clientsConnections"
            tableId="connectionsToCompanies"
            columns={columns}
            rows={rows}
            selectedRowId={selectedCompanyId}
            isRowSelectable={(rowId) => {
              const company = filteredClients.find((c) => c.id === rowId);
              return !!company?.stockTotals && company.stockTotals.length > 0;
            }}
            onRowSelect={setSelectedCompanyId}
            renderCell={({ cell, column, row }) => {
              if (column.id === 'name') {
                return (
                  <StyledTableCell key={`${row.id}.${column.id}`}>
                    {cell.type === 'string' ? cell.value : ''}
                    <CompanyLink
                      size="small"
                      onClick={() => history.push(`/clients/${row.id}/shares`)}
                    >
                      <ArrowForwardIcon fontSize="small" color="secondary" />
                    </CompanyLink>
                  </StyledTableCell>
                );
              }
              return null;
            }}
          />

          {searchNote && <SearchNote variant="body2">{searchNote}</SearchNote>}
        </>
      )}
      {shouldNotMakeConnection && (
        <Warning severity="warning">{text('warning.previousNEForm')}</Warning>
      )}
      {searchForNeConnection && (
        <div style={{ marginLeft: '8px', marginTop: '8px' }}>
          <LoadingLogo size="small" />
        </div>
      )}
      <ConfirmationDialog
        isOpen={showConfirmationDialog}
        onCancel={() => setShowConfirmationDialog(false)}
        onAccept={() => fireConnectCompany()}
      />
      <MainButtonsContainer>
        <Button
          label={intl.formatMessage({ id: 'cancel' })}
          variant="text"
          onClick={() => {
            history.push(`/${path}/${clientId}/connections`);
          }}
        />
        <Button
          label={text('connect')}
          disabled={!selectedCompanyId}
          onClick={() => {
            if (shouldNotMakeConnection) {
              setShowConfirmationDialog(true);
            } else {
              fireConnectCompany();
            }
          }}
        />
      </MainButtonsContainer>
    </ConnectionsTabWrapper>
  );
};

export default SearchForCompanyToConnect;

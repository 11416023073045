import * as t from 'io-ts';

const Status = t.union([
  t.literal('NOT_STARTED'),
  t.literal('STARTED'),
  t.literal('AW_COMPL'),
  t.literal('AW_REVIEW'),
  t.literal('DONE'),
  t.literal('LOCKED'),
]);

export const ProgramStatusChangedCodec = t.type({
  clientId: t.string,
  topic: t.literal('program-status-changed'),
  status: t.array(
    t.type({
      program: t.union([
        t.literal('RECONCILIATION'),
        t.literal('FIN_STATEMENT'),
        t.literal('AN_REPORT'),
        t.literal('TAX_DECLARATION'),
        t.literal('FIN_STATEMENT_REPORT'),
      ]),
      status: Status,
      previousStatus: t.union([Status, t.null]),
      period: t.union([t.string, t.null]),
      periodId: t.union([t.number, t.null]),
      financialYear: t.union([t.string, t.null]),
      financialYearId: t.union([t.number, t.null]),
      createdAt: t.string,
      givenName: t.string,
      familyName: t.string,
      reason: t.union([t.string, t.null]),
    })
  ),
  userId: t.string,
});

export type ProgramStatusChanged = t.TypeOf<typeof ProgramStatusChangedCodec>;

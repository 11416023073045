import { confirmationCertificate } from '../common/types/confirmationCertificate';
import { manualSubmission } from '../common/types/manualSubmission';
import { digitalSubmission } from '../shares-company/digital-submission/types';
import { settings } from './settings/types';

/**
 * Content definition for the stand alone Fastställelseintyg document.
 */
export const confirmationCertificateContentDefinition = {
  type: 'document' as const,
  children: {
    confirmationCertificate,
    manualSubmission,
    digitalSubmission,
    settings,
    annualReport: {
      type: 'externalDocument' as const,
      documentType: 'annualReport',
    },
  },
  documentType: 'confirmationCertificate' as const,
};

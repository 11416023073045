import React, { useContext, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { SpecificationColumnType } from '_clients/types/types';
import { useApiSdk } from 'api-sdk';
import { addGlobalErrorMessage } from 'redux/actions';
import PeriodDataContext from '../PeriodDataContext';
import DraggableTable from './DraggableTable';

interface Props {
  accountNumber: string;
}

const SpecificationTable = ({ accountNumber }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const sdk = useApiSdk();
  const [columns, setColumns] = useState([] as SpecificationColumnType[]);
  const [rows, setRows] = useState([
    ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11'],
    ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11'],
    ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11'],
    ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11'],
    ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11'],
  ]);

  const { clientId, period } = useContext(PeriodDataContext);

  useEffect(() => {
    const getSpecifications = async () => {
      try {
        const data = await sdk.getSpecifications({
          clientid: clientId,
          periodId: period.id,
          accountNumbers: [Number(accountNumber)],
        });
        setColumns(data.accounts[accountNumber].columns);
      } catch (error) {
        dispatch(addGlobalErrorMessage('error'));
      }
    };
    getSpecifications();
  }, [clientId, period, accountNumber]);

  return (
    <DraggableTable
      columns={columns}
      rows={rows}
      onRowChange={(x) => console.log(x)}
      onColumnChange={(x) => console.log(x)}
      onNewRow={(x) => console.log(x)}
    />
  );
};

export default SpecificationTable;

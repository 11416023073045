import React from 'react';
import styled from '@emotion/styled';
import { Typography, Dialog } from '@material-ui/core';
import { useIntl } from 'react-intl';
import Button from '../Buttons/Button';

const Container = styled.div`
  min-width: 400px;
  padding: ${(props) => props.theme.spacing(4)}px;
`;

const Title = styled(Typography)`
  font-size: 20px;
`;

const Warning = styled.span`
  color: ${({ theme }) => theme.palette.warning.main};
  font-size: 20px;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  column-gap: ${(props) => props.theme.spacing(1)}px;
  margin-top: ${(props) => props.theme.spacing(2)}px;
`;

type SettingsModalProps = {
  isOpen: boolean;
  onSubmit: () => void;
  onClose: () => void;
};

const SettingsModal = ({
  isOpen,
  onSubmit,
  onClose,
}: SettingsModalProps): JSX.Element => {
  const { formatMessage } = useIntl();

  const handleChangeChecklist = () => {
    onSubmit();
  };

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth={false}>
      <Container>
        <Title>
          {formatMessage({ id: 'checklist.settings.change-checklist' })}
        </Title>
        <Title>
          <Warning>
            {formatMessage({ id: 'checklist.settings.warning' })}
          </Warning>{' '}
          {formatMessage({ id: 'checklist.settings.warning-text' })}
        </Title>
        <Buttons>
          <Button
            label={formatMessage({ id: 'checklist.settings.cancel-button' })}
            variant="outlined"
            onClick={onClose}
          />
          <Button
            label={formatMessage({
              id: 'checklist.settings.change-checklist-button',
            })}
            onClick={handleChangeChecklist}
          />
        </Buttons>
      </Container>
    </Dialog>
  );
};

export default SettingsModal;

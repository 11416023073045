/*
 * This is a GENERATED file, do not change manually!
 * Generated:
 * - from file k3-arsredovisning-2021-10-31-rev20230131 - Noter.csv
 * - Comments: AGOY-6809
 */

import {
  value,
  ref,
  sumAllowNull,
  id,
  AgoyDocumentPart,
} from '@agoy/document';
import { IxbrlCell } from '../../../../../common';
import {
  field,
  ixbrlCell,
  ixbrlField,
  table,
} from '../../../../../common/utils/util';
import { previousYear, year } from '../../../../../common/utils/date-util';
import {
  sumAccountsIBs,
} from '../../../../../common/utils/accountSumsUtil';

const cell = { type: 'field' as const };
const boolean = { type: 'boolean' as const };
const type = { type: 'type' as const };
const number = { type: 'number' as const };

export const getNote2037K3v2 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null
): AgoyDocumentPart<typeof note2037K3v2ContentDefinition> => ({
  active: false,
  number: value(undefined),
  name: noteLabel(
    'Pågående utvecklingsarbeten och förskott avseende immateriella anläggningstillgångar'
  ),
  data: {
    active: true,
    PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarAbstract:
      {
        type: 'group',
        active: true,
        level: 0,
        title: field(
          'Pågående utvecklingsarbeten och förskott avseende immateriella anläggningstillgångar'
        ),
        table: table<IxbrlCell>(
          'notes.note37.data.PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarAbstract.table',
          {
            id: 'PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarAbstract',
            label: '',
            dataType: 'text',
            active: true,
          },
          {
            id: 'year',
            label: year(financialYear, financialYears, period) ?? '',
            dataType: 'numeric',
            active: true,
          },
          {
            id: 'previousYear',
            label: previousYear(financialYear, financialYears) ?? '',
            dataType: 'numeric',
            active: true,
          }
        )
          .addRows((rows) => {
            rows
              .addRowWithType(
                'PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarAbstract',
                'hidden',
                undefined,
                undefined,
                undefined
              )
              .addSubRows((rows) => {
                rows.addRowWithType(
                  'PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarAnskaffningsvardenIngaende',
                  'sum_accounts',
                  noteLabel('Ingående anskaffningsvärden'),
                  ixbrlCell(ref(sumAccountsIBs('1080:1089', false)), {
                    contextRef: 'balans1',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarAnskaffningsvarden',
                    standardRubrik:
                      'Anskaffningsvärden pågående utvecklingsarbeten och förskott avseende immateriella anläggningstillgångar',
                    saldo: 'debit',
                    negateValue: false,
                  }),
                  ixbrlCell(
                    ref(sumAccountsIBs('1080:1089', false, 'previousYear')),
                    {
                      contextRef: 'balans2',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarAnskaffningsvarden',
                      standardRubrik:
                        'Anskaffningsvärden pågående utvecklingsarbeten och förskott avseende immateriella anläggningstillgångar',
                      saldo: 'debit',
                      negateValue: false,
                    }
                  )
                );
                rows
                  .addRowWithType(
                    'PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarForandringarAnskaffningsvardenAbstract',
                    'header',
                    noteLabel('Förändringar av anskaffningsvärden'),
                    undefined,
                    undefined
                  )
                  .addSubRows((rows) => {
                    rows.addRowWithType(
                      'PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarForandringarAnskaffningsvardenNedlagdaUtgifter',
                      'row',
                      noteLabel('Nedlagda utgifter'),
                      ixbrlCell(value(undefined), {
                        contextRef: 'period0',
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarForandringarAnskaffningsvardenNedlagdaUtgifter',
                        standardRubrik:
                          'Ökning av anskaffningsvärden för pågående utvecklingsarbeten avseende immateriella anläggningstillgångar till följd av nedlagda utgifter',
                        negateValue: false,
                      }),
                      ixbrlCell(value(undefined), {
                        contextRef: 'period1',
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarForandringarAnskaffningsvardenNedlagdaUtgifter',
                        standardRubrik:
                          'Ökning av anskaffningsvärden för pågående utvecklingsarbeten avseende immateriella anläggningstillgångar till följd av nedlagda utgifter',
                        negateValue: false,
                      })
                    );
                    rows.addRowWithType(
                      'PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarForandringarAnskaffningsvardenUtbetaldaForskott',
                      'row',
                      noteLabel('Utbetalda förskott'),
                      ixbrlCell(value(undefined), {
                        contextRef: 'period0',
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarForandringarAnskaffningsvardenUtbetaldaForskott',
                        standardRubrik:
                          'Ökning av anskaffningsvärden i samband med periodens utbetalda förskott avseende immateriella anläggningstillgångar',
                        negateValue: false,
                      }),
                      ixbrlCell(value(undefined), {
                        contextRef: 'period1',
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarForandringarAnskaffningsvardenUtbetaldaForskott',
                        standardRubrik:
                          'Ökning av anskaffningsvärden i samband med periodens utbetalda förskott avseende immateriella anläggningstillgångar',
                        negateValue: false,
                      })
                    );
                    rows.addRowWithType(
                      'PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarForandringarAnskaffningsvardenRorelseforvarv',
                      'row',
                      noteLabel('Rörelseförvärv'),
                      ixbrlCell(value(undefined), {
                        contextRef: 'period0',
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarForandringarAnskaffningsvardenRorelseforvarv',
                        standardRubrik:
                          'Ökning (minskning) av anskaffningsvärden för pågående utvecklingsarbeten och förskott avseende immateriella anläggningstillgångar genom rörelseförvärv',
                        negateValue: false,
                      }),
                      ixbrlCell(value(undefined), {
                        contextRef: 'period1',
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarForandringarAnskaffningsvardenRorelseforvarv',
                        standardRubrik:
                          'Ökning (minskning) av anskaffningsvärden för pågående utvecklingsarbeten och förskott avseende immateriella anläggningstillgångar genom rörelseförvärv',
                        negateValue: false,
                      })
                    );
                    rows.addRowWithType(
                      'PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarForandringarAnskaffningsvardenFusion',
                      'row',
                      noteLabel('Fusion'),
                      ixbrlCell(value(undefined), {
                        contextRef: 'period0',
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarForandringarAnskaffningsvardenFusion',
                        standardRubrik:
                          'Ökning (minskning) av anskaffningsvärden för pågående utvecklingsarbeten och förskott avseende immateriella anläggningstillgångar genom fusion',
                        negateValue: false,
                      }),
                      ixbrlCell(value(undefined), {
                        contextRef: 'period1',
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarForandringarAnskaffningsvardenFusion',
                        standardRubrik:
                          'Ökning (minskning) av anskaffningsvärden för pågående utvecklingsarbeten och förskott avseende immateriella anläggningstillgångar genom fusion',
                        negateValue: false,
                      })
                    );
                    rows.addRowWithType(
                      'PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarForandringarAnskaffningsvardenForsaljningarUtrangeringar',
                      'row',
                      noteLabel('Försäljningar/utrangeringar'),
                      ixbrlCell(value(undefined), {
                        contextRef: 'period0',
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarForandringarAnskaffningsvardenForsaljningarUtrangeringar',
                        standardRubrik:
                          'Minskning av anskaffningsvärden i samband med försäljningar eller utrangeringar av pågående utvecklingsarbeten och förskott avseende immateriella anläggningstillgångar',
                        negateValue: true,
                      }),
                      ixbrlCell(value(undefined), {
                        contextRef: 'period1',
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarForandringarAnskaffningsvardenForsaljningarUtrangeringar',
                        standardRubrik:
                          'Minskning av anskaffningsvärden i samband med försäljningar eller utrangeringar av pågående utvecklingsarbeten och förskott avseende immateriella anläggningstillgångar',
                        negateValue: true,
                      })
                    );
                    rows.addRowWithType(
                      'PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarForandringarAnskaffningsvardenAterbetaldaForskott',
                      'row',
                      noteLabel('Återbetalda förskott'),
                      ixbrlCell(value(undefined), {
                        contextRef: 'period0',
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarForandringarAnskaffningsvardenAterbetaldaForskott',
                        standardRubrik:
                          'Minskning av anskaffningsvärden i samband med periodens återbetalda förskott avseende immateriella anläggningstillgångar',
                        negateValue: true,
                      }),
                      ixbrlCell(value(undefined), {
                        contextRef: 'period1',
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarForandringarAnskaffningsvardenAterbetaldaForskott',
                        standardRubrik:
                          'Minskning av anskaffningsvärden i samband med periodens återbetalda förskott avseende immateriella anläggningstillgångar',
                        negateValue: true,
                      })
                    );
                    rows.addRowWithType(
                      'PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarForandringarAnskaffningsvardenBortskrivnaForskott',
                      'row',
                      noteLabel('Bortskrivna förskott'),
                      ixbrlCell(value(undefined), {
                        contextRef: 'period0',
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarForandringarAnskaffningsvardenBortskrivnaForskott',
                        standardRubrik:
                          'Minskning av anskaffningsvärden i samband med bortskrivning av förskott avseende immateriella anläggningstillgångar',
                        negateValue: true,
                      }),
                      ixbrlCell(value(undefined), {
                        contextRef: 'period1',
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarForandringarAnskaffningsvardenBortskrivnaForskott',
                        standardRubrik:
                          'Minskning av anskaffningsvärden i samband med bortskrivning av förskott avseende immateriella anläggningstillgångar',
                        negateValue: true,
                      })
                    );
                    rows.addRowWithType(
                      'PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarForandringarAnskaffningsvardenOmklassificeringar',
                      'row',
                      noteLabel('Omklassificeringar'),
                      ixbrlCell(value(undefined), {
                        contextRef: 'period0',
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarForandringarAnskaffningsvardenOmklassificeringar',
                        standardRubrik:
                          'Ökning (minskning) av anskaffningsvärden i samband med omklassificeringar av pågående utvecklingsarbeten och förskott avseende immateriella anläggningstillgångar',
                        negateValue: false,
                      }),
                      ixbrlCell(value(undefined), {
                        contextRef: 'period1',
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarForandringarAnskaffningsvardenOmklassificeringar',
                        standardRubrik:
                          'Ökning (minskning) av anskaffningsvärden i samband med omklassificeringar av pågående utvecklingsarbeten och förskott avseende immateriella anläggningstillgångar',
                        negateValue: false,
                      })
                    );
                    rows.addRowWithType(
                      'PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarForandringarAnskaffningsvardenOmrakningsdifferenser',
                      'row',
                      noteLabel('Omräkningsdifferenser'),
                      ixbrlCell(value(undefined), {
                        contextRef: 'period0',
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarForandringarAnskaffningsvardenOmrakningsdifferenser',
                        standardRubrik:
                          'Ökning (minskning) av anskaffningsvärden i samband med omräkningsdifferenser avseende pågående utvecklingsarbeten och förskott avseende immateriella anläggningstillgångar',
                        negateValue: false,
                      }),
                      ixbrlCell(value(undefined), {
                        contextRef: 'period1',
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarForandringarAnskaffningsvardenOmrakningsdifferenser',
                        standardRubrik:
                          'Ökning (minskning) av anskaffningsvärden i samband med omräkningsdifferenser avseende pågående utvecklingsarbeten och förskott avseende immateriella anläggningstillgångar',
                        negateValue: false,
                      })
                    );
                    return rows.build();
                  });
                rows.addRowWithType(
                  'PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarAnskaffningsvardenUtgaende',
                  'sum',
                  noteLabel('Utgående anskaffningsvärden'),
                  ixbrlCell(
                    ref(
                      sumAllowNull(
                        id(
                          'notes.note37.data.PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarAbstract.table.PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarAbstract.PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarAnskaffningsvardenIngaende.year'
                        ),
                        id(
                          'notes.note37.data.PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarAbstract.table.PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarAbstract.PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarForandringarAnskaffningsvardenAbstract.*.year'
                        )
                      )
                    ),
                    {
                      contextRef: 'balans0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarAnskaffningsvarden',
                      standardRubrik:
                        'Anskaffningsvärden pågående utvecklingsarbeten och förskott avseende immateriella anläggningstillgångar',
                      saldo: 'debit',
                      negateValue: false,
                    }
                  ),
                  ixbrlCell(
                    ref(
                      sumAllowNull(
                        id(
                          'notes.note37.data.PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarAbstract.table.PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarAbstract.PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarAnskaffningsvardenIngaende.previousYear'
                        ),
                        id(
                          'notes.note37.data.PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarAbstract.table.PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarAbstract.PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarForandringarAnskaffningsvardenAbstract.*.previousYear'
                        )
                      )
                    ),
                    {
                      contextRef: 'balans1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarAnskaffningsvarden',
                      standardRubrik:
                        'Anskaffningsvärden pågående utvecklingsarbeten och förskott avseende immateriella anläggningstillgångar',
                      saldo: 'debit',
                      negateValue: false,
                    }
                  )
                );
                rows.addRowWithType(
                  'PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarNedskrivningarIngaende',
                  'row',
                  noteLabel('Ingående nedskrivningar'),
                  ixbrlCell(value(undefined), {
                    contextRef: 'balans1',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarNedskrivningar',
                    standardRubrik:
                      'Ackumulerade nedskrivningar pågående utvecklingsarbeten och förskott avseende immateriella anläggningstillgångar',
                    saldo: 'credit',
                    negateValue: true,
                  }),
                  ixbrlCell(value(undefined), {
                    contextRef: 'balans2',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarNedskrivningar',
                    standardRubrik:
                      'Ackumulerade nedskrivningar pågående utvecklingsarbeten och förskott avseende immateriella anläggningstillgångar',
                    saldo: 'credit',
                    negateValue: true,
                  })
                );
                rows
                  .addRowWithType(
                    'PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarForandringarNedskrivningarAbstract',
                    'header',
                    noteLabel('Förändringar av nedskrivningar'),
                    undefined,
                    undefined
                  )
                  .addSubRows((rows) => {
                    rows.addRowWithType(
                      'PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarForandringarNedskrivningarAterfordaNedskrivningar',
                      'row',
                      noteLabel('Återförda nedskrivningar'),
                      ixbrlCell(value(undefined), {
                        contextRef: 'period0',
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarForandringarNedskrivningarAterfordaNedskrivningar',
                        standardRubrik:
                          'Periodens återförda nedskrivningar av pågående utvecklingsarbeten och förskott avseende immateriella anläggningstillgångar',
                        negateValue: false,
                      }),
                      ixbrlCell(value(undefined), {
                        contextRef: 'period1',
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarForandringarNedskrivningarAterfordaNedskrivningar',
                        standardRubrik:
                          'Periodens återförda nedskrivningar av pågående utvecklingsarbeten och förskott avseende immateriella anläggningstillgångar',
                        negateValue: false,
                      })
                    );
                    rows.addRowWithType(
                      'PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarForandringarNedskrivningarFusion',
                      'row',
                      noteLabel('Fusion'),
                      ixbrlCell(value(undefined), {
                        contextRef: 'period0',
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarForandringarNedskrivningarFusion',
                        standardRubrik:
                          'Ökning (minskning) av nedskrivningar av pågående utvecklingsarbeten och förskott avseende immateriella anläggningstillgångar genom fusion',
                        negateValue: true,
                      }),
                      ixbrlCell(value(undefined), {
                        contextRef: 'period1',
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarForandringarNedskrivningarFusion',
                        standardRubrik:
                          'Ökning (minskning) av nedskrivningar av pågående utvecklingsarbeten och förskott avseende immateriella anläggningstillgångar genom fusion',
                        negateValue: true,
                      })
                    );
                    rows.addRowWithType(
                      'PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarForandringarNedskrivningarForsaljningarUtrangeringar',
                      'row',
                      noteLabel('Försäljningar/utrangeringar'),
                      ixbrlCell(value(undefined), {
                        contextRef: 'period0',
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarForandringarNedskrivningarForsaljningarUtrangeringar',
                        standardRubrik:
                          'Minskning av nedskrivningar i samband med försäljningar eller utrangeringar av pågående utvecklingsarbeten och förskott avseende immateriella anläggningstillgångar',
                        negateValue: false,
                      }),
                      ixbrlCell(value(undefined), {
                        contextRef: 'period1',
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarForandringarNedskrivningarForsaljningarUtrangeringar',
                        standardRubrik:
                          'Minskning av nedskrivningar i samband med försäljningar eller utrangeringar av pågående utvecklingsarbeten och förskott avseende immateriella anläggningstillgångar',
                        negateValue: false,
                      })
                    );
                    rows.addRowWithType(
                      'PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarForandringarNedskrivningarBortskrivnaForskott',
                      'row',
                      noteLabel('Bortskrivna förskott'),
                      ixbrlCell(value(undefined), {
                        contextRef: 'period0',
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarForandringarNedskrivningarBortskrivnaForskott',
                        standardRubrik:
                          'Minskning av nedskrivningar av förskott avseende immateriella anläggningstillgångar i samband med bortskrivning av förskott',
                        negateValue: false,
                      }),
                      ixbrlCell(value(undefined), {
                        contextRef: 'period1',
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarForandringarNedskrivningarBortskrivnaForskott',
                        standardRubrik:
                          'Minskning av nedskrivningar av förskott avseende immateriella anläggningstillgångar i samband med bortskrivning av förskott',
                        negateValue: false,
                      })
                    );
                    rows.addRowWithType(
                      'PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarForandringarNedskrivningarOmklassificeringar',
                      'row',
                      noteLabel('Omklassificeringar'),
                      ixbrlCell(value(undefined), {
                        contextRef: 'period0',
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarForandringarNedskrivningarOmklassificeringar',
                        standardRubrik:
                          'Minskning (ökning) av nedskrivningar av pågående utvecklingsarbeten och förskott avseende immateriella anläggningstillgångar i samband med omklassificeringar',
                        negateValue: false,
                      }),
                      ixbrlCell(value(undefined), {
                        contextRef: 'period1',
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarForandringarNedskrivningarOmklassificeringar',
                        standardRubrik:
                          'Minskning (ökning) av nedskrivningar av pågående utvecklingsarbeten och förskott avseende immateriella anläggningstillgångar i samband med omklassificeringar',
                        negateValue: false,
                      })
                    );
                    rows.addRowWithType(
                      'PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarForandringarNedskrivningarAretsNedskrivningar',
                      'row',
                      noteLabel('Årets nedskrivningar'),
                      ixbrlCell(value(undefined), {
                        contextRef: 'period0',
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarForandringarNedskrivningarAretsNedskrivningar',
                        standardRubrik:
                          'Periodens nedskrivningar av pågående utvecklingsarbeten och förskott avseende immateriella anläggningstillgångar',
                        negateValue: true,
                      }),
                      ixbrlCell(value(undefined), {
                        contextRef: 'period1',
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarForandringarNedskrivningarAretsNedskrivningar',
                        standardRubrik:
                          'Periodens nedskrivningar av pågående utvecklingsarbeten och förskott avseende immateriella anläggningstillgångar',
                        negateValue: true,
                      })
                    );
                    rows.addRowWithType(
                      'PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarForandringarNedskrivningarOmrakningsdifferenser',
                      'row',
                      noteLabel('Omräkningsdifferenser'),
                      ixbrlCell(value(undefined), {
                        contextRef: 'period0',
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarForandringarNedskrivningarOmrakningsdifferenser',
                        standardRubrik:
                          'Minskning (ökning) av nedskrivningar av pågående utvecklingsarbeten och förskott avseende immateriella anläggningstillgångar i samband med omräkningsdifferenser',
                        negateValue: false,
                      }),
                      ixbrlCell(value(undefined), {
                        contextRef: 'period1',
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarForandringarNedskrivningarOmrakningsdifferenser',
                        standardRubrik:
                          'Minskning (ökning) av nedskrivningar av pågående utvecklingsarbeten och förskott avseende immateriella anläggningstillgångar i samband med omräkningsdifferenser',
                        negateValue: false,
                      })
                    );
                    return rows.build();
                  });
                rows.addRowWithType(
                  'PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarUtgaende',
                  'sum',
                  noteLabel('Utgående nedskrivningar'),
                  ixbrlCell(
                    ref(
                      sumAllowNull(
                        id(
                          'notes.note37.data.PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarAbstract.table.PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarAbstract.PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarNedskrivningarIngaende.year'
                        ),
                        id(
                          'notes.note37.data.PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarAbstract.table.PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarAbstract.PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarForandringarNedskrivningarAbstract.*.year'
                        )
                      )
                    ),
                    {
                      contextRef: 'balans0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarNedskrivningar',
                      standardRubrik:
                        'Ackumulerade nedskrivningar pågående utvecklingsarbeten och förskott avseende immateriella anläggningstillgångar',
                      saldo: 'credit',
                      negateValue: true,
                    }
                  ),
                  ixbrlCell(
                    ref(
                      sumAllowNull(
                        id(
                          'notes.note37.data.PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarAbstract.table.PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarAbstract.PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarNedskrivningarIngaende.previousYear'
                        ),
                        id(
                          'notes.note37.data.PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarAbstract.table.PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarAbstract.PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarForandringarNedskrivningarAbstract.*.previousYear'
                        )
                      )
                    ),
                    {
                      contextRef: 'balans1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarNedskrivningar',
                      standardRubrik:
                        'Ackumulerade nedskrivningar pågående utvecklingsarbeten och förskott avseende immateriella anläggningstillgångar',
                      saldo: 'credit',
                      negateValue: true,
                    }
                  )
                );
                return rows.build();
              });
            rows.addRowWithType(
              'PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangar',
              'sum',
              noteLabel('Redovisat värde'),
              ixbrlCell(
                ref(
                  sumAllowNull(
                    id(
                      'notes.note37.data.PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarAbstract.table.PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarAbstract.PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarAnskaffningsvardenUtgaende.year'
                    ),
                    id(
                      'notes.note37.data.PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarAbstract.table.PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarAbstract.PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarUtgaende.year'
                    )
                  )
                ),
                {
                  contextRef: 'balans0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangar',
                  standardRubrik:
                    'Pågående utvecklingsarbeten och förskott avseende immateriella anläggningstillgångar',
                  saldo: 'debit',
                  negateValue: false,
                }
              ),
              ixbrlCell(
                ref(
                  sumAllowNull(
                    id(
                      'notes.note37.data.PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarAbstract.table.PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarAbstract.PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarAnskaffningsvardenUtgaende.previousYear'
                    ),
                    id(
                      'notes.note37.data.PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarAbstract.table.PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarAbstract.PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarUtgaende.previousYear'
                    )
                  )
                ),
                {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangar',
                  standardRubrik:
                    'Pågående utvecklingsarbeten och förskott avseende immateriella anläggningstillgångar',
                  saldo: 'debit',
                  negateValue: false,
                }
              )
            );
            return rows.build();
          })
          .build(),
        NotPagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarKommentar:
          {
            type: 'group',
            active: true,
            level: 0,
            title: field(
              'Kommentar till specifikation av pågående utvecklingsarbeten och förskott avseende immateriella anläggningstillgångar'
            ),
            value: ixbrlField(field(''), {
              contextRef: 'period0',
              type: 'stringItemType',
              name: 'se-gen-base:NotPagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarKommentar',
              standardRubrik:
                'Kommentar till specifikation av pågående utvecklingsarbeten och förskott avseende immateriella anläggningstillgångar',
            }),
          },
      },
  },
  group: value(undefined),
  section: value(undefined),
  validForReportType: {
    k2: true,
    k3: true,
  },
});
export const note2037K3v2ContentDefinition = {
  type: 'part' as const,
  children: {
    active: boolean,
    number: cell,
    name: cell,
    data: {
      type: 'part' as const,
      children: {
        active: boolean,

        PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarAbstract:
          {
            type: 'part' as const,
            children: {
              type: type,
              level: number,
              active: boolean,

              title: cell,

              table: { type: 'table' as const },

              NotPagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangarKommentar:
                {
                  type: 'part' as const,
                  children: {
                    type: type,
                    level: number,
                    active: boolean,

                    title: cell,

                    value: cell,
                  },
                },
            },
          },
      },
    },
    group: cell,
    section: cell,
    validForReportType: {
      type: 'part' as const,
      children: {
        k2: boolean,
        k3: boolean,
      },
    },
  },
};

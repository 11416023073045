import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';

import {
  ClientCompanyType,
  IntegrationType,
  ClientChecklist,
} from '_clients/types/types';
import { FortnoxIntegrationStatus } from '_integration/types';
import { Awaited } from '@agoy/common';
import { asResultClass, getApiSdk } from 'api-sdk';
import { RootState } from 'redux/reducers';
import { getContext } from 'utils/AgoyAppClient/contextHolder';
import { setUiStatus } from 'redux/actions/UI';
import { mapClient, mapClosingPeriod } from '_clients/services/mappings';
import {
  ADD_CUSTOMER,
  UPDATE_CUSTOMER,
  SET_CUSTOMER_LOGO,
  DELETE_CLIENT,
  POPULATE_CUSTOMERS,
  SET_INTEGRATION_STATUS,
  SET_FINANCIAL_YEARS,
  SET_LAMP_STATUS,
  RESET_CUSTOMERS,
  SET_CHECKLISTS,
} from './action-types';

type Sdk = Awaited<ReturnType<typeof getApiSdk>>;

export const addClient = (customer: ClientCompanyType) => ({
  type: ADD_CUSTOMER,
  customer,
});

export const createClient =
  (
    clientAttributes: Parameters<Sdk['postClients']>['0']['requestBody']
  ): ThunkAction<
    Promise<Awaited<ReturnType<Sdk['postClients']>> | undefined>,
    RootState,
    unknown,
    Action<string>
  > =>
  async (dispatch) => {
    const sdk = getApiSdk(getContext());

    const result = await asResultClass(
      sdk.postClients({ requestBody: clientAttributes })
    );

    if (result.ok) {
      dispatch(addClient(mapClient(result.val)));
      return result.val;
    }

    throw new Error(result.val.message);
  };

export const getClient =
  (clientId: string): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    const alreadyLoaded = !!getState().customers[clientId];
    if (!alreadyLoaded) {
      dispatch(setUiStatus({ fetchingClients: true }));
    }
    const sdk = getApiSdk(getContext());

    const result = await asResultClass(
      sdk.getClientById({ clientid: clientId })
    );

    if (result.ok) {
      dispatch(addClient(mapClient(result.val)));
    }
    if (!alreadyLoaded) {
      dispatch(setUiStatus({ fetchingClients: false }));
    }
  };

export const populateClients = ({
  clients,
}: {
  clients: ClientCompanyType[];
}) => ({
  type: POPULATE_CUSTOMERS,
  customers: clients,
});

export const getClients =
  (): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    const sdk = getApiSdk(getContext());

    const result = await asResultClass(sdk.getClients({}));

    if (result.ok) {
      dispatch(populateClients({ clients: result.val.map(mapClient) }));
      dispatch(setUiStatus({ clientsPopulated: true }));
    } else {
      throw new Error('Failed to get clients');
    }
  };

export const updateClient = (client: Partial<ClientCompanyType>) => ({
  type: UPDATE_CUSTOMER,
  customer: client,
});

export const saveClient =
  (
    clientId: string,
    client: Awaited<Parameters<Sdk['updateClientById']>>[0]['requestBody']
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    const sdk = getApiSdk(getContext());

    const clientWithId = { id: clientId, ...client };

    const result = await asResultClass(
      sdk.updateClientById({ clientid: clientId, requestBody: client })
    );

    if (result.ok) {
      dispatch(
        updateClient({
          ...clientWithId,
          name: clientWithId.name ?? '',
          type: clientWithId.type ?? undefined,
          active: clientWithId.active ?? false,
          financialYears: clientWithId.financialYears ?? [],
          closingPeriod: mapClosingPeriod(clientWithId.closingPeriod ?? null),
        })
      );
    } else {
      throw Error(result.val.message);
    }
  };

export const setClientLogo = (id: string, logo: string) => ({
  type: SET_CUSTOMER_LOGO,
  id,
  logo,
});

export const saveClientLogo =
  (
    clientId: string,
    file: File
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    const sdk = getApiSdk(getContext());

    const result = await asResultClass(
      sdk.putClientPicture({ clientid: clientId, requestBody: file })
    );

    if (result.ok) {
      dispatch(setClientLogo(clientId, result.val.logo));
    } else {
      throw Error(result.val.message);
    }
  };

export const deleteClient = (clientId: string) => ({
  type: DELETE_CLIENT,
  clientId,
});

export const setDeleteClient =
  (clientId: string): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    const sdk = getApiSdk(getContext());

    const result = await asResultClass(
      sdk.deleteClientById({ clientid: clientId })
    );

    if (result.ok) {
      dispatch(deleteClient(clientId));
    } else {
      throw Error(result.val.message);
    }
  };

export const resetClients = () => ({
  type: RESET_CUSTOMERS,
});

export const setIntegrationStatus = (
  id: string,
  integration: IntegrationType,
  status: FortnoxIntegrationStatus | null
) => ({
  type: SET_INTEGRATION_STATUS,
  id,
  integration,
  status,
});

export const setFinancialYears = (id: string, financialYears: string[]) => ({
  type: SET_FINANCIAL_YEARS,
  id,
  financialYears,
});

export const setClientLampStatus = (mapStatuses, period, clientId) => {
  return {
    type: SET_LAMP_STATUS,
    mapStatuses,
    customerId: clientId,
    period,
  };
};

export const setClientChecklists = (
  clientId: string,
  checklists: ClientChecklist[]
) => {
  return {
    type: SET_CHECKLISTS,
    clientId,
    checklists,
  };
};

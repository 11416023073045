import React from 'react';
import styled from '@emotion/styled';
import { Box, Typography } from '@material-ui/core';
import isPropValid from '@emotion/is-prop-valid';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import {
  green,
  blue,
  red,
  yellow,
  secondaryMain,
} from '@agoy/theme/src/colors';
import CloseIcon from '@material-ui/icons/Close';

export type AlertType =
  | 'error'
  | 'warning'
  | 'info'
  | 'success'
  | 'locked'
  | 'clientBanner'
  | 'annualReportBanner';

export type AlertSizeType = 'large' | 'medium';

const getAlertColor = (type: AlertType) => {
  if (type === 'locked' || type === 'success' || type === 'clientBanner')
    return green.MEDIUM;
  if (type === 'info') return blue.MEDIUM;
  if (type === 'error') return red.MEDIUM;
  if (type === 'annualReportBanner') return secondaryMain;
  return yellow.MEDIUM;
};

const MessageWrapper = styled(Box, { shouldForwardProp: isPropValid })<{
  type: AlertType;
  size?: AlertSizeType;
}>`
  background-color: ${({ type }) => getAlertColor(type)};
  border-radius: ${({ type }) =>
    type === 'annualReportBanner' ? '0px' : '8px'};
  padding: ${({ theme, size }) => theme.spacing(size === 'medium' ? 1 : 2)}px
    ${({ theme, size }) => theme.spacing(size === 'medium' ? 1 : 2)}px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  max-width: 100%;
  width: 100%;
  column-gap: 16px;
`;

const Message = styled(Typography)`
  font-size: ${({ theme }) => theme.typography.button.fontSize};
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
`;

const StyledWarningIcon = styled(ReportProblemOutlinedIcon)`
  color: ${({ theme }) => theme.palette.text.primary};
`;
const StyledInfoIcon = styled(InfoOutlinedIcon)`
  color: ${({ theme }) => theme.palette.text.primary};
`;
const StyledSuccessIcon = styled(CheckCircleOutlineIcon)`
  color: ${({ theme }) => theme.palette.text.primary};
`;
const StyledLockedIcon = styled(LockOutlinedIcon)`
  color: ${({ theme }) => theme.palette.text.primary};
`;

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

const StyledIcon = styled(CloseIcon)`
  cursor: pointer;
`;

type AlertPropsType = {
  type: AlertType;
  size?: AlertSizeType;
  children?: React.ReactNode;
  title?: string;
  onClose?: () => void;
};

const Alert = ({
  type,
  size = 'large',
  children,
  title,
  onClose,
}: AlertPropsType) => (
  <MessageWrapper
    type={type}
    size={size}
    display="flex"
    flexDirection="row"
    alignItems="center"
  >
    {(type === 'warning' || type === 'error') && (
      <StyledWarningIcon fontSize={size} />
    )}
    {type === 'info' && <StyledInfoIcon fontSize={size} />}
    {type === 'success' && <StyledSuccessIcon fontSize={size} />}
    {type === 'locked' && <StyledLockedIcon fontSize={size} />}
    {type === 'clientBanner' && <StyledInfoIcon fontSize={size} />}
    <MessageContainer>
      {title && <Message variant="inherit">{title}</Message>}
      {children}
    </MessageContainer>
    {onClose && <StyledIcon onClick={onClose} />}
  </MessageWrapper>
);

export default Alert;

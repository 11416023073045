import { value, ref, table } from '@agoy/document';
import { NoteInvertedTwoTableSection, IxbrlCell } from '../../../../common';
import {
  sumAccountsUBs,
  sumNotesRowsById,
} from '../../../../common/utils/accountSumsUtil';
import {
  active,
  field,
  ixbrlCell,
  ixbrlField,
} from '../../../../common/utils/util';
import { year, previousYear } from '../../../../common/utils/date-util';

const MONETARY_ITEM_TYPE = 'monetaryItemType';

export const getNote76 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null
): NoteInvertedTwoTableSection => {
  return {
    active: false,
    number: value(undefined),
    name: value('Pågående arbete för annans räkning'),
    table: table<IxbrlCell>(
      'notes.note76.table',
      {
        id: 'label',
        label: 'Pågående arbete för annans räkning (Fordran)',
      },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      .addRows((rows) =>
        rows
          .addRow(
            '1',
            noteLabel('Aktiverade nedlagda utgifter'),
            ixbrlCell(ref(sumAccountsUBs('1471', false)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeArbetenAnnansRakningOmsattningstillgangarAktiveradeUtgifter',
              contextRef: 'balans0',
              saldo: 'debit',
            }),
            ixbrlCell(ref(sumAccountsUBs('1471', false, 'previousYear')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeArbetenAnnansRakningOmsattningstillgangarAktiveradeUtgifter',
              contextRef: 'balans1',
              saldo: 'debit',
            })
          )
          .addRow(
            '2',
            noteLabel('Fakturerat belopp'),
            ixbrlCell(ref(sumAccountsUBs('1478', false)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeArbetenAnnansRakningOmsattningstillgangarFaktureradeDelbelopp',
              contextRef: 'balans0',
              saldo: 'credit',
            }),
            ixbrlCell(ref(sumAccountsUBs('1478', false, 'previousYear')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeArbetenAnnansRakningOmsattningstillgangarFaktureradeDelbelopp',
              contextRef: 'balans1',
              saldo: 'credit',
            })
          )
          .addRow(
            '3',
            noteLabel('Redovisat värde'),
            ixbrlCell(sumNotesRowsById(76, [1, 2], 'year'), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeArbetenAnnansRakningOmsattningstillgangar',
              contextRef: 'balans0',
              saldo: 'debit',
            }),
            ixbrlCell(sumNotesRowsById(76, [1, 2], 'previousYear'), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeArbetenAnnansRakningOmsattningstillgangar',
              contextRef: 'balans1',
              saldo: 'debit',
            })
          )
          .addRow(
            '4',
            noteLabel('Av beställaren innehållet belopp'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeArbetenAnnansRakningOmsattningstillgangarBestallarenInnehalletBelopp',
              contextRef: 'balans0',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeArbetenAnnansRakningOmsattningstillgangarBestallarenInnehalletBelopp',
              contextRef: 'balans1',
            })
          )
          .build()
      )
      .newRowTemplate(value(''), value(undefined), value(undefined))
      .build(),
    secondTable: table<IxbrlCell>(
      'notes.note76.secondTable',
      {
        id: 'label',
        label: 'Pågående arbete för annans räkning (Skuld)',
      },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      .addRows((rows) =>
        rows
          .addRow(
            '1',
            noteLabel('Fakturerat belopp'),
            ixbrlCell(ref(sumAccountsUBs('2431', false)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeArbetenAnnansRakningKortfristigaSkulderFaktureradeDelbelopp',
              contextRef: 'balans0',
              saldo: 'credit',
            }),
            ixbrlCell(ref(sumAccountsUBs('2431', false, 'previousYear')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeArbetenAnnansRakningKortfristigaSkulderFaktureradeDelbelopp',
              contextRef: 'balans1',
              saldo: 'credit',
            })
          )
          .addRow(
            '2',
            noteLabel('Aktiverade nedlagda utgifter'),
            ixbrlCell(ref(sumAccountsUBs('2438', false)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeArbetenAnnansRakningKortfristigaSkulderAktiveradeUtgifter',
              contextRef: 'balans0',
              saldo: 'debit',
            }),
            ixbrlCell(ref(sumAccountsUBs('2438', false, 'previousYear')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeArbetenAnnansRakningKortfristigaSkulderAktiveradeUtgifter',
              contextRef: 'balans1',
              saldo: 'debit',
            })
          )
          .addRow(
            '3',
            noteLabel('Redovisat värde'),
            ixbrlCell(sumNotesRowsById(76, [1, 2], 'year', 'secondTable'), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeArbetenAnnansRakningKortfristigaSkulder',
              contextRef: 'balans0',
              saldo: 'credit',
            }),
            ixbrlCell(
              sumNotesRowsById(76, [1, 2], 'previousYear', 'secondTable'),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:PagaendeArbetenAnnansRakningKortfristigaSkulder',
                contextRef: 'balans1',
                saldo: 'credit',
              }
            )
          )
          .addRow(
            '4',
            noteLabel('Av beställaren innehållet belopp'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeArbetenAnnansRakningKortfristigaSkulderBestallarenInnehalletBelopp',
              contextRef: 'balans0',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:PagaendeArbetenAnnansRakningKortfristigaSkulderBestallarenInnehalletBelopp',
              contextRef: 'balans1',
            })
          )
          .build()
      )
      .newRowTemplate(value(''), value(undefined), value(undefined))
      .build(),
    commentLabel: field('Kommentar'),
    comment: ixbrlField(active(field('')), {
      type: 'stringItemType',
      name: 'se-gen-base:NotPagaendeArbetenAnnansRakningKommentar',
      contextRef: 'period0',
    }),
    group: value(undefined),
    section: value(undefined),
    validForReportType: {
      k2: false,
      k3: true,
    },
  };
};

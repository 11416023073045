import React from 'react';
import { useIntl } from 'react-intl';
import styled from '@emotion/styled';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Typography,
} from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { VacationDebtEmployeeData } from '_reconciliation/types';
import { defaultNumberFormatter } from 'utils';

interface Props {
  employeesData?: Array<VacationDebtEmployeeData>;
  updatedAt?: string;
}

const Wrapper = styled.div`
  width: 100%;
`;

const TableCellBold = styled(TableCell)`
  color: ${({ theme }) => theme.palette.text.primary};
  font-weight: 600;
  font-size: 1rem;
`;

const TableTitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.primary};
  font-weight: 500;
`;

const UpdatedText = styled(Typography)`
  font-style: italic;
  color: ${({ theme }) => theme.palette.text.secondary};
  padding: ${({ theme }) => theme.spacing(1)}px;
  font-size: 0.9rem;
  font-weight: 500;
`;

const EmptyMessage = styled.td`
  padding: ${(props) => props.theme.spacing(1)}px
    ${(props) => props.theme.spacing(2)}px;
  font-style: italic;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)}px;
`;

const SummaryTable = ({ employeesData = [], updatedAt }: Props) => {
  const { formatMessage } = useIntl();
  const translate = (id) => formatMessage({ id });
  const aggregatedData: Partial<VacationDebtEmployeeData> =
    employeesData.reduce((result, employeeData) => {
      Object.keys(employeeData).forEach((field) => {
        if (typeof employeeData[field] === 'number') {
          result[field]
            ? (result[field] += employeeData[field])
            : (result[field] = employeeData[field]);
        }
      });

      return result;
    }, {});

  const holidayDebtWithContribution = defaultNumberFormatter.format(
    aggregatedData.TotalDebtEmployee! +
      aggregatedData.TotalDebtEmployerContribution!
  );

  Object.keys(aggregatedData).forEach((field) => {
    aggregatedData[field] = defaultNumberFormatter.format(
      aggregatedData[field]
    );
  });

  return (
    <Wrapper>
      <TableTitle variant="h3">
        {translate('vacationDebt.table.title')}
      </TableTitle>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                {translate('vacationDebt.table.header.title')}
              </TableCell>
              <TableCell align="right">
                {translate('vacationDebt.table.header.description')}
              </TableCell>
            </TableRow>
          </TableHead>
          {employeesData.length > 0 ? (
            <TableBody>
              <TableRow>
                <TableCellBold component="th" scope="row">
                  {translate('vacationDebt.table.row.debtWithContribution')}
                </TableCellBold>
                <TableCellBold align="right">
                  {holidayDebtWithContribution}
                </TableCellBold>
              </TableRow>
              <TableRow>
                <TableCellBold component="th" scope="row">
                  {translate('vacationDebt.table.row.contribution')}
                </TableCellBold>
                <TableCellBold align="right">
                  {aggregatedData.TotalDebtEmployerContribution}
                </TableCellBold>
              </TableRow>
              <TableRow>
                <TableCellBold component="th" scope="row">
                  {translate('vacationDebt.table.row.totalDebt')}
                </TableCellBold>
                <TableCellBold align="right">
                  {aggregatedData.TotalDebtEmployee}
                </TableCellBold>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  {translate('vacationDebt.table.row.debtAdvance')}
                </TableCell>
                <TableCell align="right">
                  {aggregatedData.DebtAdvance}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  {translate('vacationDebt.table.row.debtSaved')}
                </TableCell>
                <TableCell align="right">{aggregatedData.DebtSaved}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  {translate('vacationDebt.table.row.variableUnused')}
                </TableCell>
                <TableCell align="right">
                  {aggregatedData.VariableUnused}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  {translate('vacationDebt.table.row.debtUnused')}
                </TableCell>
                <TableCell align="right">{aggregatedData.DebtUnused}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  {translate('vacationDebt.table.row.variableEarned')}
                </TableCell>
                <TableCell align="right">
                  {aggregatedData.VariableEarned}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  {translate('vacationDebt.table.row.debtEarned')}
                </TableCell>
                <TableCell align="right">{aggregatedData.DebtEarned}</TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <EmptyMessage>
                  <InfoOutlinedIcon />
                  {translate('vacationDebt.table.noData')}
                </EmptyMessage>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>

      {updatedAt && (
        <UpdatedText>
          {translate('vacationDebt.table.footer.takenFromFortnox')}{' '}
          {new Date(updatedAt).toLocaleDateString('en-CA')}
        </UpdatedText>
      )}
    </Wrapper>
  );
};

export default SummaryTable;

const readBuffer = (file, start = 0, end = 2): Promise<ArrayBufferLike> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result as ArrayBufferLike);
    };
    reader.onerror = reject;
    reader.readAsArrayBuffer(file.slice(start, end));
  });
};

const check = (headers) => {
  return (buffers, options = { offset: 0 }): boolean =>
    headers.every(
      (header, index) => header === buffers[options.offset + index]
    );
};

const stringToBytes = (string) => {
  return [...string].map((character) => character.charCodeAt(0));
};

const isJPEG = check([0xff, 0xd8, 0xff]);
const isGIF = check([0x47, 0x49, 0x46, 0x38]);
const isTIFF = check([0x49, 0x20, 0x49]);
const isPDF = check(stringToBytes('%PDF'));

export const isBlobPDF = async (blob: Blob) => {
  const buffers: string | ArrayBuffer | null = await readBuffer(blob, 0, 4);
  const uint8Array = new Uint8Array(buffers);
  return isPDF(uint8Array);
};

export const isBlobImage = async (blob: Blob) => {
  const buffers = await readBuffer(blob, 0, 4);
  const uint8Array = new Uint8Array(buffers);
  return isJPEG(uint8Array) || isGIF(uint8Array) || isTIFF(uint8Array);
};

export const isBlobValid = async (blob: Blob) => {
  const buffers = await readBuffer(blob, 0, 4);
  const uint8Array = new Uint8Array(buffers);
  return (
    isJPEG(uint8Array) ||
    isGIF(uint8Array) ||
    isTIFF(uint8Array) ||
    isPDF(uint8Array)
  );
};

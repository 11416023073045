import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import {
  FormControl,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  IconButton,
  Avatar,
  Button,
} from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import Skeleton from '@material-ui/lab/Skeleton';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import styled from '@emotion/styled';

import { Awaited } from '@agoy/common';
import { getApiSdk } from 'api-sdk';
import { InviteOfOrganisation } from '_organization/types';

const SearchField = styled(FormControl)`
  width: 100%;
`;

const InviteMemberButton = styled(Button)`
  min-width: 130px;
`;

const getErrorMessageId = (error) => {
  switch (error.message) {
    case 'User already member of an organisation':
      return 'dashboard.members.invite.error.user.exists';
    case 'User already invited':
      return 'dashboard.members.invite.error.invite.exists';
  }
  return 'dashboard.members.invite.error.text';
};

type Sdk = Awaited<ReturnType<typeof getApiSdk>>;

type Props = {
  isLoading: boolean;
  error: string;
  invites: Awaited<ReturnType<Sdk['getOrganisationInvites']>> | undefined;
  handleSendInvite: (email: string) => Promise<void>;
  handleDeleteInvite: (invite: InviteOfOrganisation) => Promise<void>;
};

const MemberInvites = ({
  isLoading,
  error,
  invites,
  handleSendInvite,
  handleDeleteInvite,
}: Props) => {
  const intl = useIntl();

  const [email, setEmail] = useState<string>('');

  return (
    <>
      <SearchField>
        <TextField
          id="add-members"
          variant="outlined"
          size="small"
          placeholder={intl.formatMessage({ id: 'email.placeholder' })}
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          InputProps={{
            endAdornment: (
              <InviteMemberButton
                color="primary"
                size="small"
                onClick={() => handleSendInvite(email)}
              >
                {intl.formatMessage({ id: 'dashboard.members.invite' })}
              </InviteMemberButton>
            ),
          }}
        />
      </SearchField>
      <List>
        {(isLoading || error) && (
          <>
            {isLoading && (
              <ListItem>
                <Skeleton variant="text" animation="wave" width="100%" />
              </ListItem>
            )}
            {error && (
              <Alert severity="error">
                <AlertTitle>
                  {intl.formatMessage({
                    id: 'dashboard.members.invite.error.title',
                  })}
                </AlertTitle>
                {intl.formatMessage({
                  id: getErrorMessageId(error),
                })}
              </Alert>
            )}
          </>
        )}

        {invites &&
          invites.map((invite) => (
            <React.Fragment key={invite.id}>
              <ListItem>
                <ListItemIcon>
                  <Avatar />
                </ListItemIcon>
                <ListItemText>{invite.email}</ListItemText>
                <IconButton onClick={() => handleDeleteInvite(invite)}>
                  <Delete />
                </IconButton>
              </ListItem>
            </React.Fragment>
          ))}
      </List>
    </>
  );
};

export default MemberInvites;

import { Typography } from '@material-ui/core';
import styled from '@emotion/styled';

export const AccountNumber = styled(Typography)`
  font-weight: 600;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  padding: ${(props) => props.theme.spacing(1)}px
    ${(props) => props.theme.spacing(1)}px
    ${(props) => props.theme.spacing(1)}px
    ${(props) => props.theme.spacing(1) / 2}px;
`;

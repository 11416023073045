import { formatStartOfMonth } from '@agoy/dates';
import { addMonths, subYears } from 'date-fns';

export interface Period {
  date: string;
  hasData: boolean;
}

type FinancialYearsFunction = (financialYears?: string[]) => Period[];

/**
 * Will create period strings 12 months back and 6 months ahead
 */

const createPeriods: FinancialYearsFunction = (financialYears) => {
  let date = new Date();
  date = subYears(date, 1);

  const periods = Array.from(Array(18).keys()).map(() => {
    date = addMonths(date, 1);
    return formatStartOfMonth(date, 'yyyyMMdd');
  });

  return periods.map((p) => {
    const output = {
      date: p,
      hasData: false,
    };

    if (financialYears && financialYears.length !== 0) {
      const periodAsInt = parseInt(p, 10);

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < financialYears.length; i++) {
        const [start, end] = financialYears[i].split('-');
        output.hasData = !!(
          periodAsInt >= parseInt(start, 10) && periodAsInt <= parseInt(end, 10)
        );
      }
    }

    return output;
  });
};

export default createPeriods;

import {
  TaxDeclarationFormDefinition,
  TaxDeclarationFormDataConfig,
} from '../../types';
import { ref, value, id } from '@agoy/document';
import { mapFormId, toInitial, toStructure } from '../forms-util';

const configN9 = (
  form: TaxDeclarationFormDefinition,
  financialYearStart: string,
  financialYearEnd: string
): TaxDeclarationFormDataConfig => {
  const N9 = mapFormId(form, 'N9');
  const initial = {
    N9: {
      partType: 'form' as const,
      id: N9,
      fields: {
        datum: value(''),
        foretagets_namn: value(''),
        organisationsnummer: value(''),
        A_1_ranteutgifter_och_liknande: value(undefined),
        A_2_ranta_till_foretag: value(undefined),
        A_3_ranteugifter_till_foretag: value(undefined),
        A_4_ranteugifter_vid_vissa: value(undefined),
        A_5_ranteugifter_pa_vissa: value(undefined),
        A_6_valutakursforluster: value(undefined),
        A_7_ovrigt_som_ingar: value(undefined),
        A_8_ranteutgifter_vid_avtal: value(undefined),
        A_9_ovriga_utgifter: value(undefined),
        A_10_summa: value(undefined),
        B_1_redovisade_och_liknande: value(undefined),
        B_2_redovisade_som_inte: value(undefined),
        B_3_ranteinkomster: value(undefined),
        B_4_ranteutgifter: value(undefined),
        B_5_positivt: value(undefined),
        B_6_negativt: value(undefined),
        C_1_positivt: value(undefined),
        C_2_overtaget: value(undefined),
        C_3_positivt_efter_koncern: value(undefined),
        C_4_negativt: value(undefined),
        C_5_overfort: value(undefined),
        C_6_negativt: value(undefined),
        C_1_overtaget_fran: value(''),
        C_1_overtaget_belopp: value(undefined),
        C_2_overtaget_fran: value(''),
        C_2_overtaget_belopp: value(undefined),
        C_3_overtaget_fran: value(''),
        C_3_overtaget_belopp: value(undefined),
        C_4_overtaget_fran: value(''),
        C_4_overtaget_belopp: value(undefined),
        C_5_overtaget_fran: value(''),
        C_5_overtaget_belopp: value(undefined),
        C_6_overtaget_fran: value(''),
        C_6_overtaget_belopp: value(undefined),
        C_7_overtaget_fran: value(''),
        C_7_overtaget_belopp: value(undefined),
        C_8_overtaget_fran: value(''),
        C_8_overtaget_belopp: value(undefined),
        C_9_overtaget_fran: value(''),
        C_9_overtaget_belopp: value(undefined),
        C_10_overtaget_fran: value(''),
        C_10_overtaget_belopp: value(undefined),
        C_11_overtaget_fran: value(''),
        C_11_overtaget_belopp: value(undefined),
        C_12_overtaget_fran: value(''),
        C_12_overtaget_belopp: value(undefined),
        C_13_overtaget_fran: value(''),
        C_13_overtaget_belopp: value(undefined),
        C_14_overtaget_fran: value(''),
        C_14_overtaget_belopp: value(undefined),
        D_1_skattemassigt: value(undefined),
        D_2_om_punkt: value(undefined),
        D_3_arliga: value(undefined),
        D_4_avdrag: value(undefined),
        D_5_negativt: value(undefined),
        D_6_positivt: value(undefined),
        D_7_overskott: value(undefined),
        D_8_aterforing: value(undefined),
        D_9_mottagna: value(undefined),
        D_10_avdragsunderlag: value(undefined),
        D_11_avdragsutrymme: value(undefined),
        E_forenklingregeln: value(false),
        E_ebitda: value(false),
        E_1_negativt: value(undefined),
        E_2_avdrag_for_aret: value(undefined),
        E_3_avdrag_for_kvarstaende: value(undefined),
        E_4_skattemassig_aterforing: value(undefined),
        E_5_skattemassig_avdrag: value(undefined),
        E_6_ytterligare: value(undefined),
        F_1_1_organisationsnummer: value(''),
        F_1_1_begart: value(undefined),
        F_1_2_organisationsnummer: value(''),
        F_1_2_begart: value(undefined),
        F_1_3_organisationsnummer: value(''),
        F_1_3_begart: value(undefined),
        F_1_4_organisationsnummer: value(''),
        F_1_4_begart: value(undefined),
        F_1_5_organisationsnummer: value(''),
        F_1_5_begart: value(undefined),
        F_1_6_organisationsnummer: value(''),
        F_1_6_begart: value(undefined),
        F_1_7_organisationsnummer: value(''),
        F_1_7_begart: value(undefined),
        F_1_8_organisationsnummer: value(''),
        F_1_8_begart: value(undefined),
        F_1_9_organisationsnummer: value(''),
        F_1_9_begart: value(undefined),
        F_1_10_organisationsnummer: value(''),
        F_1_10_begart: value(undefined),
        F_1_11_organisationsnummer: value(''),
        F_1_11_begart: value(undefined),
        F_2_1_organisationsnummer: value(''),
        F_2_1_begart: value(undefined),
        F_2_2_organisationsnummer: value(''),
        F_2_2_begart: value(undefined),
        F_2_3_organisationsnummer: value(''),
        F_2_3_begart: value(undefined),
        F_2_4_organisationsnummer: value(''),
        F_2_4_begart: value(undefined),
        F_2_5_organisationsnummer: value(''),
        F_2_5_begart: value(undefined),
        F_2_6_organisationsnummer: value(''),
        F_2_6_begart: value(undefined),
        F_2_7_organisationsnummer: value(''),
        F_2_7_begart: value(undefined),
        F_2_8_organisationsnummer: value(''),
        F_2_8_begart: value(undefined),
        F_2_9_organisationsnummer: value(''),
        F_2_9_begart: value(undefined),
        F_2_10_organisationsnummer: value(''),
        F_2_10_begart: value(undefined),
        F_2_11_organisationsnummer: value(''),
        F_2_11_begart: value(undefined),
        G_1_arets_negativa: value(undefined),
        G_2_avdragsutrymmet: value(undefined),
        G_3_ytterligare: value(undefined),
        G_4_beskattningsarets: value(undefined),
        G_aret_innan: value(undefined),
        G_andra_aret: value(undefined),
        G_tredje_aret: value(undefined),
        G_fjarde_aret: value(undefined),
        G_femte_aret: value(undefined),
      },
      sru: {
        '7011': value(financialYearStart),
        '7012': value(financialYearEnd),
        '8200': ref(id('N9.fields.A_1_ranteutgifter_och_liknande')),
        '8201': ref(id('N9.fields.A_2_ranta_till_foretag')),
        '8325': ref(id('N9.fields.A_3_ranteugifter_till_foretag')),
        '8202': ref(id('N9.fields.A_4_ranteugifter_vid_vissa')),
        '8203': ref(id('N9.fields.A_5_ranteugifter_pa_vissa')),
        '8204': ref(id('N9.fields.A_6_valutakursforluster')),
        '8205': ref(id('N9.fields.A_7_ovrigt_som_ingar')),
        '8206': ref(id('N9.fields.A_8_ranteutgifter_vid_avtal')),
        '8207': ref(id('N9.fields.A_9_ovriga_utgifter')),
        '8208': ref(id('N9.fields.A_10_summa')),
        '8209': ref(id('N9.fields.B_1_redovisade_och_liknande')),
        '8210': ref(id('N9.fields.B_2_redovisade_som_inte')),
        '8211': ref(id('N9.fields.B_3_ranteinkomster')),
        '8212': ref(id('N9.fields.B_4_ranteutgifter')),
        '8213': ref(id('N9.fields.B_5_positivt')),
        '8214': ref(id('N9.fields.B_6_negativt')),
        '8215': ref(id('N9.fields.C_1_positivt')),
        '8216': ref(id('N9.fields.C_2_overtaget')),
        '8217': ref(id('N9.fields.C_3_positivt_efter_koncern')),
        '8218': ref(id('N9.fields.C_4_negativt')),
        '8219': ref(id('N9.fields.C_5_overfort')),
        '8220': ref(id('N9.fields.C_6_negativt')),
        '8221': ref(id('N9.fields.C_1_overtaget_fran')),
        '8222': ref(id('N9.fields.C_1_overtaget_belopp')),
        '8223': ref(id('N9.fields.C_2_overtaget_fran')),
        '8224': ref(id('N9.fields.C_2_overtaget_belopp')),
        '8225': ref(id('N9.fields.C_3_overtaget_fran')),
        '8226': ref(id('N9.fields.C_3_overtaget_belopp')),
        '8227': ref(id('N9.fields.C_4_overtaget_fran')),
        '8228': ref(id('N9.fields.C_4_overtaget_belopp')),
        '8229': ref(id('N9.fields.C_5_overtaget_fran')),
        '8230': ref(id('N9.fields.C_5_overtaget_belopp')),
        '8287': ref(id('N9.fields.C_6_overtaget_fran')),
        '8288': ref(id('N9.fields.C_6_overtaget_belopp')),
        '8289': ref(id('N9.fields.C_7_overtaget_fran')),
        '8290': ref(id('N9.fields.C_7_overtaget_belopp')),
        '8291': ref(id('N9.fields.C_8_overtaget_fran')),
        '8292': ref(id('N9.fields.C_8_overtaget_belopp')),
        '8293': ref(id('N9.fields.C_9_overtaget_fran')),
        '8294': ref(id('N9.fields.C_9_overtaget_belopp')),
        '8295': ref(id('N9.fields.C_10_overtaget_fran')),
        '8296': ref(id('N9.fields.C_10_overtaget_belopp')),
        '8297': ref(id('N9.fields.C_11_overtaget_fran')),
        '8298': ref(id('N9.fields.C_11_overtaget_belopp')),
        '8303': ref(id('N9.fields.C_12_overtaget_fran')),
        '8304': ref(id('N9.fields.C_12_overtaget_belopp')),
        '8305': ref(id('N9.fields.C_13_overtaget_fran')),
        '8306': ref(id('N9.fields.C_13_overtaget_belopp')),
        '8307': ref(id('N9.fields.C_14_overtaget_fran')),
        '8308': ref(id('N9.fields.C_14_overtaget_belopp')),
        '8231': ref(id('N9.fields.D_1_skattemassigt')),
        '8232': ref(id('N9.fields.D_2_om_punkt')),
        '8233': ref(id('N9.fields.D_3_arliga')),
        '8234': ref(id('N9.fields.D_4_avdrag')),
        '8235': ref(id('N9.fields.D_5_negativt')),
        '8236': ref(id('N9.fields.D_6_positivt')),
        '8237': ref(id('N9.fields.D_7_overskott')),
        '8238': ref(id('N9.fields.D_8_aterforing')),
        '8239': ref(id('N9.fields.D_9_mottagna')),
        '8240': ref(id('N9.fields.D_10_avdragsunderlag')),
        '8241': ref(id('N9.fields.D_11_avdragsutrymme')),
        '8326': ref(id('N9.fields.E_forenklingregeln')),
        '8327': ref(id('N9.fields.E_ebitda')),
        '8242': ref(id('N9.fields.E_1_negativt')),
        '8243': ref(id('N9.fields.E_2_avdrag_for_aret')),
        '8244': ref(id('N9.fields.E_3_avdrag_for_kvarstaende')),
        '8245': ref(id('N9.fields.E_4_skattemassig_aterforing')),
        '8246': ref(id('N9.fields.E_5_skattemassig_avdrag')),
        '8328': ref(id('N9.fields.E_6_ytterligare')),
        '8247': ref(id('N9.fields.F_1_1_organisationsnummer')),
        '8248': ref(id('N9.fields.F_1_1_begart')),
        '8249': ref(id('N9.fields.F_1_2_organisationsnummer')),
        '8250': ref(id('N9.fields.F_1_2_begart')),
        '8251': ref(id('N9.fields.F_1_3_organisationsnummer')),
        '8252': ref(id('N9.fields.F_1_3_begart')),
        '8253': ref(id('N9.fields.F_1_4_organisationsnummer')),
        '8254': ref(id('N9.fields.F_1_4_begart')),
        '8255': ref(id('N9.fields.F_1_5_organisationsnummer')),
        '8256': ref(id('N9.fields.F_1_5_begart')),
        '8257': ref(id('N9.fields.F_1_6_organisationsnummer')),
        '8258': ref(id('N9.fields.F_1_6_begart')),
        '8259': ref(id('N9.fields.F_1_7_organisationsnummer')),
        '8260': ref(id('N9.fields.F_1_7_begart')),
        '8261': ref(id('N9.fields.F_1_8_organisationsnummer')),
        '8262': ref(id('N9.fields.F_1_8_begart')),
        '8313': ref(id('N9.fields.F_1_9_organisationsnummer')),
        '8314': ref(id('N9.fields.F_1_9_begart')),
        '8315': ref(id('N9.fields.F_1_10_organisationsnummer')),
        '8316': ref(id('N9.fields.F_1_10_begart')),
        '8317': ref(id('N9.fields.F_1_11_organisationsnummer')),
        '8318': ref(id('N9.fields.F_1_11_begart')),
        '8263': ref(id('N9.fields.F_2_1_organisationsnummer')),
        '8264': ref(id('N9.fields.F_2_1_begart')),
        '8265': ref(id('N9.fields.F_2_2_organisationsnummer')),
        '8266': ref(id('N9.fields.F_2_2_begart')),
        '8267': ref(id('N9.fields.F_2_3_organisationsnummer')),
        '8268': ref(id('N9.fields.F_2_3_begart')),
        '8269': ref(id('N9.fields.F_2_4_organisationsnummer')),
        '8270': ref(id('N9.fields.F_2_4_begart')),
        '8271': ref(id('N9.fields.F_2_5_organisationsnummer')),
        '8272': ref(id('N9.fields.F_2_5_begart')),
        '8273': ref(id('N9.fields.F_2_6_organisationsnummer')),
        '8274': ref(id('N9.fields.F_2_6_begart')),
        '8275': ref(id('N9.fields.F_2_7_organisationsnummer')),
        '8276': ref(id('N9.fields.F_2_7_begart')),
        '8277': ref(id('N9.fields.F_2_8_organisationsnummer')),
        '8278': ref(id('N9.fields.F_2_8_begart')),
        '8319': ref(id('N9.fields.F_2_9_organisationsnummer')),
        '8320': ref(id('N9.fields.F_2_9_begart')),
        '8321': ref(id('N9.fields.F_2_10_organisationsnummer')),
        '8322': ref(id('N9.fields.F_2_10_begart')),
        '8323': ref(id('N9.fields.F_2_11_organisationsnummer')),
        '8324': ref(id('N9.fields.F_2_11_begart')),
        '8279': ref(id('N9.fields.G_1_arets_negativa')),
        '8280': ref(id('N9.fields.G_2_avdragsutrymmet')),
        '8329': ref(id('N9.fields.G_3_ytterligare')),
        '8281': ref(id('N9.fields.G_4_beskattningsarets')),
        '8282': ref(id('N9.fields.G_aret_innan')),
        '8283': ref(id('N9.fields.G_andra_aret')),
        '8284': ref(id('N9.fields.G_tredje_aret')),
        '8285': ref(id('N9.fields.G_fjarde_aret')),
        '8286': ref(id('N9.fields.G_femte_aret')),
      },
    },
  };
  return {
    initial: toInitial(initial, form),
    definition: toStructure(initial, form),
  };
};

export default configN9;

const helpStructureCL = {
  type: 'part' as const,
  _type: 'CL' as const,
  children: {
    mission: { type: 'field' as const },
    missionTitle: { type: 'field' as const },

    responsibility: { type: 'field' as const },
    responsibilityTitle: { type: 'field' as const },

    signature: { type: 'field' as const },
    signatureTitle: { type: 'field' as const },
    signatureRadio: { type: 'field' as const },
    signatureText: { type: 'field' as const },

    toSkatteverket: { type: 'field' as const },
    toSkatteverketTitle: { type: 'field' as const },
    toSkatteverketCheckbox: { type: 'field' as const },
    toSkatteverketText: { type: 'field' as const },

    taxCalculation: { type: 'field' as const },
    taxCalculationTitle: { type: 'field' as const },
    taxCalculationText: { type: 'field' as const },
    taxCalculationTable: { type: 'field' as const },

    paymentToSkatteverket: { type: 'field' as const },
    paymentToSkatteverketTitle: { type: 'field' as const },
    paymentToSkatteverketText: { type: 'field' as const },
    paymentToSkatteverketTable: { type: 'field' as const },

    comment: { type: 'field' as const },
    commentTitle: { type: 'field' as const },
  },
};

export default helpStructureCL;

import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography as MuiTypography,
} from '@material-ui/core';
import Button from '_shared/components/Buttons/Button';
import AddIcon from '@material-ui/icons/Add';
import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';
import { AgoyTable } from '@agoy/document';
import DocumentViewServiceContext from '_shared/services/document/DocumentViewServiceContext';
import VotingListRow from './VotingListRow';
import AddTableRow from '../../UI/HierarchicalTable/AddTableRow';

const StyledTable = styled(Table, {
  shouldForwardProp: (prop) => isPropValid(prop),
})<{ print: boolean }>`
  max-width: max-content;
  min-width: 600px;
  margin: ${({ theme }) => theme.spacing(2)}px 0;

  .MuiTableCell-root {
    padding: ${({ theme }) => theme.spacing(2)}px;
    font-size: 1rem;
    font-weight: 400;
  }
  .MuiTableCell-root :nth-of-type(3),
  .MuiTableCell-root :nth-of-type(4) {
    text-align: right;
  }
  .MuiTableCell-body {
    border: none;
    padding: ${({ theme }) => theme.spacing(1)}px;
  }
  .MuiTableCell-head {
    color: ${({ print, theme }) =>
      !print ? theme.palette.background.default : theme.palette.text.primary};
    background-color: ${({ print, theme }) =>
      !print
        ? theme.palette.accountingView.headers?.main
        : theme.palette.common.white};
  }
`;

const Typography = styled(MuiTypography)`
  font-size: 1rem;
  font-weight: 400;
`;

type VotingListProps = {
  votingList: AgoyTable;
  fullId: string;
  print?: boolean;
  editable?: boolean;
};

const VotingList = ({
  votingList,
  fullId,
  print = false,
  editable = true,
}: VotingListProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const service = useContext(DocumentViewServiceContext);

  const handleAddRow = () => {
    const newId = `@${Date.now()}`;
    service.addRow(fullId, newId, { name: '' });
  };

  return (
    <StyledTable size="small" print={print}>
      <TableHead>
        <TableRow>
          {!print && (
            <TableCell>
              {formatMessage({ id: `annualReport.${fullId}.active` })}
            </TableCell>
          )}
          <TableCell>
            <Typography>
              {formatMessage({ id: `annualReport.${fullId}.name` })}
            </Typography>
          </TableCell>

          <TableCell>
            <Typography>
              {formatMessage({ id: `annualReport.${fullId}.shares` })}
            </Typography>
          </TableCell>

          <TableCell>
            <Typography>
              {formatMessage({ id: `annualReport.${fullId}.votes` })}
            </Typography>
          </TableCell>

          <TableCell>
            <Typography>
              {formatMessage({ id: `annualReport.${fullId}.role` })}
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {votingList?.rows.map((p) => (
          <VotingListRow
            tableId={fullId}
            key={p.id}
            row={p}
            print={print}
            service={service}
            editable={editable}
          />
        ))}
        {!print && (
          <AddTableRow level={0}>
            <TableCell colSpan={5}>
              <Button
                variant="outlined"
                size="medium"
                startIcon={<AddIcon />}
                onClick={handleAddRow}
                label="Lägg till rad"
                disabled={!editable}
              />
            </TableCell>
            <TableCell />
          </AddTableRow>
        )}
      </TableBody>
    </StyledTable>
  );
};
export default VotingList;

import React, { useState } from 'react';
import { CTAButton } from '_shared/components/Buttons';
import { useIntl } from 'react-intl';
import { Typography, Button } from '@material-ui/core';
import { useForm } from 'react-hook-form';

import useAuth from '_authentication/hooks/useAuth';
import FormError from '_shared/components/Inputs/FormError';
import { LoginBox, TextField, ActionsWrapper } from './SharedComponents';

interface Props {
  backToLogin: () => void;
}

const ForgotContent: React.FC<Props> = ({ backToLogin }: Props) => {
  const auth = useAuth();
  const { formatMessage } = useIntl();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const [submitError, setSubmitError] = useState('');

  const msg = (id: string) => formatMessage({ id });

  const isRequired = {
    required: msg('form.fieldRequired'),
  };

  const requestNewPassword = async (data) => {
    const { username, newPassword, code } = data;

    try {
      await auth.confirmNewPasswordAWS({ username, newPassword, code });
      await auth.logInAWS({ username, password: newPassword });
    } catch (error) {
      setSubmitError(msg('error'));
    }
  };

  return (
    <LoginBox onSubmit={handleSubmit(requestNewPassword)}>
      <Typography variant="h5">{msg('resetpassword.title')}</Typography>
      <Typography>{msg('resetpassword.text')}</Typography>

      <TextField
        {...register('username', isRequired)}
        variant="outlined"
        size="small"
        placeholder={msg('email.placeholder')}
      />
      <FormError>{errors.username?.message}</FormError>

      <TextField
        {...register('newPassword', isRequired)}
        variant="outlined"
        size="small"
        type="password"
        placeholder={msg('resetpassword.newPassword')}
      />

      <FormError>{errors.newPassword?.message}</FormError>

      <TextField
        {...register('code', isRequired)}
        variant="outlined"
        size="small"
        placeholder={msg('code')}
      />

      <FormError>{errors.code?.message}</FormError>

      {submitError && <FormError>{submitError}</FormError>}

      <ActionsWrapper>
        <Button onClick={backToLogin}>{msg('forgot.back')}</Button>
        <CTAButton type="submit" loading={auth.isLoading}>
          {msg('login.retrieve')}
        </CTAButton>
      </ActionsWrapper>
    </LoginBox>
  );
};

export default ForgotContent;

import React from 'react';
import {
  AgoyTableRow,
  booleanValue,
  Cell,
  StringCell,
  stringValue,
} from '@agoy/document';
import styled from '@emotion/styled';
import AccountantTitlesRowPreview from './AccountantTitlesRowPreview';
import { useIntl } from 'react-intl';
import {
  AnnualReportVersion,
  defaultRolesForSignatureTable,
} from '@agoy/annual-report-document';
import { hasCustomRole } from '../../../../utils/utils';

const Grid = styled.div`
  margin: ${({ theme }) => theme.spacing(1)}px 0;
  display: inline-block;
`;

type AccountantTitlesPreviewProps = {
  rows: AgoyTableRow[];
  isDigitalSubmission: boolean;
  isDigitalSigningWithFortnox: boolean;
  version: AnnualReportVersion;
  isDateSelected: boolean;
};

export interface Accountant {
  givenName: Cell;
  surName: Cell;
  roles: Cell;
  mainAccountant: Cell | undefined;
  date: string;
}

const isAccountant = (item: Accountant | undefined): item is Accountant => {
  return !!item;
};

const AccountantTitlesPreview = ({
  rows = [],
  isDigitalSubmission,
  isDigitalSigningWithFortnox,
  version,
  isDateSelected,
}: AccountantTitlesPreviewProps) => {
  const { formatMessage } = useIntl();

  const allRoles = Object.values(defaultRolesForSignatureTable).flat();

  const returnAuditorRole = (roleCell: Cell | undefined): Cell => {
    const roleValue = stringValue(roleCell)?.split('|');

    const updatedRoleValue = roleValue && {
      ...roleCell,
      value: roleValue
        .map((role) =>
          allRoles.includes(role)
            ? formatMessage({ id: `clientInformation.${role}` })
            : role
        )
        .join('|'),
    };
    return updatedRoleValue as Cell;
  };

  const hasAuditorRole = (item: Accountant | undefined): boolean => {
    const roleValue = stringValue(item?.roles);

    if (!roleValue) return false;

    const roleValues = roleValue?.split('|');

    const translatedAuditorRoles = defaultRolesForSignatureTable.auditors.map(
      (role) => formatMessage({ id: `clientInformation.${role}` }).toLowerCase()
    );

    return (
      roleValues.some((role) =>
        defaultRolesForSignatureTable.auditors.includes(role)
      ) ||
      roleValues.some(
        (role) =>
          translatedAuditorRoles.includes(role.toLowerCase()) ||
          (hasCustomRole(roleValues) && version === '1')
      )
    );
  };

  const filteredAccountants = rows
    .map((row) => {
      if (
        row?.cells &&
        stringValue(row?.cells?.givenName) !== '' &&
        stringValue(row?.cells?.surName) !== '' &&
        stringValue(row?.cells?.accountantRole) !== ''
      ) {
        const mainAccountantV1 =
          version === '1'
            ? stringValue(row?.cells?.accountantRole)?.includes(
                'Huvudansvarig revisor'
              )
            : undefined;

        const mainAcccountantV2 =
          version === '2'
            ? booleanValue(row?.cells?.responsibleAuditor)
            : undefined;

        return {
          givenName: row.cells.givenName,
          surName: row.cells.surName,
          date: (row?.cells?.date as StringCell).value || '',
          roles: returnAuditorRole(
            version === '2' ? row.cells.role : row.cells.accountantRole
          ),

          mainAccountant:
            mainAccountantV1 || mainAcccountantV2
              ? {
                  ...(version === '1'
                    ? row.cells.accountantRole
                    : row.cells.responsibleAuditor),
                  value: 'Huvudansvarig revisor',
                  ixbrl: {
                    type: 'booleanItemType',
                    name: 'se-gen-base:UnderskriftRevisorspateckningRevisorHuvudansvarig',
                    contextRef: 'period0',
                  },
                }
              : undefined,
        } as Accountant;
      }
      return undefined;
    })
    .filter(isAccountant)
    .filter(hasAuditorRole);

  const responsibleAuditorIndex = filteredAccountants.findIndex(
    (person) => person.mainAccountant
  );

  return (
    <Grid>
      <div>
        {responsibleAuditorIndex > -1 && (
          <AccountantTitlesRowPreview
            index={responsibleAuditorIndex}
            accountant={filteredAccountants[responsibleAuditorIndex]}
            isDigitalSubmission={isDigitalSubmission}
            isDigitalSigningWithFortnox={isDigitalSigningWithFortnox}
            isDateSelected={
              isDateSelected ||
              !!filteredAccountants[responsibleAuditorIndex].date
            }
          />
        )}
      </div>
      {filteredAccountants?.map(
        (accountant, index) =>
          index !== responsibleAuditorIndex && (
            <AccountantTitlesRowPreview
              key={index}
              index={index}
              accountant={accountant}
              isDigitalSubmission={isDigitalSubmission}
              isDigitalSigningWithFortnox={isDigitalSigningWithFortnox}
              isDateSelected={isDateSelected || !!accountant.date}
            />
          )
      )}
    </Grid>
  );
};

export default AccountantTitlesPreview;

import { Subscription } from 'rxjs';
import {
  AgoyDocument,
  AgoyDocumentChanges,
  AgoyDocumentStructure,
  Cell,
  DocumentDataService,
  OperationResult,
  Source,
  operations,
} from '@agoy/document';
import { GenericDocumentViewService } from './GenericDocumentViewService';

class GenericDocumentReactiveViewService<T extends AgoyDocumentStructure>
  implements GenericDocumentViewService
{
  dataService: DocumentDataService<T>;

  structure: T;

  document?: AgoyDocument<T>;

  changes?: AgoyDocumentChanges<T>;

  userId?: string;

  subscriptions: Subscription[] = [];

  constructor(
    structure: T,
    dataService: DocumentDataService<T>,
    userId?: string
  ) {
    this.dataService = dataService;
    this.structure = structure;
    this.userId = userId;

    this.subscriptions.push(
      dataService.changes.subscribe((value) => {
        this.changes = value;
      })
    );

    this.subscriptions.push(
      dataService.latestReport.subscribe((value) => {
        this.document = value;
      })
    );
  }

  /**
   * Updates a field value.
   *
   * Optionally setting the source of the value.
   * The current userId will be added to the source.
   * The current time will be set by this function in updatedAt.
   *
   * @param fieldId
   * @param value
   * @param source
   */
  async updateField(
    fieldId: string,
    value: string | number | undefined | boolean,
    source?: Cell['source']
  ): Promise<void> {
    let patchedSource = source;
    if (patchedSource) {
      if (!patchedSource.userId) {
        // Default to the current userId
        patchedSource = { ...patchedSource, userId: this.userId };
      }
      if (!patchedSource?.updatedAt) {
        // Default to now
        patchedSource = { ...patchedSource, updatedAt: Date.now() };
      }
    }
    const documentAndChanges = operations.updateField(
      this.structure,
      this.getCurrentState(),
      fieldId,
      value,
      patchedSource
    );

    this.update(documentAndChanges);
  }

  async updateCellValue(
    cellId: string,
    value: string | number | boolean | Cell | undefined,
    options: { keepOriginal?: boolean } = {}
  ): Promise<void> {
    switch (typeof value) {
      case 'string':
        this.update(
          operations.updateCellValue(
            this.structure,
            this.getCurrentState(),
            cellId,
            {
              type: 'string',
              value,
            },
            options.keepOriginal ?? false
          )
        );
        break;
      case 'number':
      case 'undefined':
        this.update(
          operations.updateCellValue(
            this.structure,
            this.getCurrentState(),
            cellId,
            {
              type: 'number',
              value,
            },
            options.keepOriginal ?? false
          )
        );
        break;
      case 'boolean':
        this.update(
          operations.updateCellValue(
            this.structure,
            this.getCurrentState(),
            cellId,
            {
              type: 'boolean',
              value,
            },
            options.keepOriginal ?? false
          )
        );
        break;
      default:
        this.update(
          operations.updateCellValue(
            this.structure,
            this.getCurrentState(),
            cellId,
            value,
            options.keepOriginal ?? false
          )
        );
    }
  }

  async updateRowValues(
    rowId: string,
    values: { [key: string]: Cell | string | number | boolean | undefined },
    options: { keepOriginal?: boolean } = {}
  ): Promise<void> {
    let result: OperationResult<T> = this.getCurrentState();

    Object.keys(values).forEach((key) => {
      const value = values[key];
      const cellId = `${rowId}.${key}`;

      if (typeof result !== 'object') {
        this.update(result);
        return;
      }

      switch (typeof value) {
        case 'string':
          result = operations.updateCellValue(
            this.structure,
            result,
            cellId,
            {
              type: 'string',
              value,
            },
            options.keepOriginal ?? false
          );
          break;
        case 'number':
        case 'undefined':
          result = operations.updateCellValue(
            this.structure,
            result,
            cellId,
            {
              type: 'number',
              value,
            },
            options.keepOriginal ?? false
          );
          break;
        case 'boolean':
          result = operations.updateCellValue(
            this.structure,
            result,
            cellId,
            {
              type: 'boolean',
              value,
            },
            options.keepOriginal ?? false
          );
          break;
        default:
          result = operations.updateCellValue(
            this.structure,
            result,
            cellId,
            value,
            options.keepOriginal ?? false
          );
      }
    });

    this.update(result);
  }

  async addRow(
    rowId: string,
    newRowId?: string,
    cellParameters?: Record<string, string>,
    copyId?: string
  ): Promise<void> {
    this.update(
      operations.addTableRow(
        this.structure,
        this.getCurrentState(),
        rowId,
        newRowId,
        cellParameters,
        copyId
      )
    );
  }

  async deleteRow(rowId: string): Promise<void> {
    this.update(
      operations.deleteTableRow(this.structure, this.getCurrentState(), rowId)
    );
  }

  async resetContent(id: string): Promise<void> {
    const currentState = this.getCurrentState();

    const result = operations.removeChanges(
      this.structure,
      this.getCurrentState(),
      id
    );
    if (typeof result === 'object') {
      if (result.changes !== currentState.changes) {
        this.dataService.update(undefined, result.changes);
      }
    }
  }

  async toggleTableRowActive(rowId: string): Promise<void> {
    this.update(
      operations.toggleTableRowActive(
        this.structure,
        this.getCurrentState(),
        rowId
      )
    );
  }

  async updateTableSource(tableId: string, source: Source): Promise<void> {
    this.update(
      operations.updateTableSource(
        this.structure,
        this.getCurrentState(),
        tableId,
        source
      )
    );
  }

  async addTableColumn(
    tableId: string,
    index: number,
    label: string,
    sortKey?: number | undefined
  ): Promise<void> {
    throw new Error('Not implemented');
  }

  async deleteColumn(columnId: string): Promise<void> {
    throw new Error('Not implemented');
  }

  async toggleFieldActive(fieldId: string): Promise<void> {
    await this.update(
      operations.toggleFieldActive(
        this.structure,
        this.getCurrentState(),
        fieldId
      )
    );
  }

  async toggleSectionActive(id: string): Promise<void> {
    throw new Error('Not implemented');
  }

  async toggleTableActive(tableid: string): Promise<void> {
    throw new Error('Not implemented');
  }

  async updateCellReferences(
    cellId: string,
    references: string[]
  ): Promise<void> {
    throw new Error('Not implemented');
  }

  async updateColumnLabel(columnId: string, label: string): Promise<void> {
    throw new Error('Not implemented');
  }

  async updateColumnSortKey(
    columnsWithSortKey: { id: string; sortKey: number }[]
  ): Promise<void> {
    throw new Error('Not implemented');
  }

  async updateRows(
    rows: {
      id: string;
      sortKey?: number | undefined;
      active?: boolean | undefined;
    }[]
  ): Promise<void> {
    throw new Error('Not implemented');
  }

  async resetTableRow(rowId: string): Promise<void> {
    this.update(
      operations.resetTableRow(this.structure, this.getCurrentState(), rowId)
    );
  }

  async resetField(id: string): Promise<void> {
    const documentAndChanges = operations.resetField(
      this.structure,
      this.getCurrentState(),
      id
    );
    this.update(documentAndChanges);
  }

  dispose(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  private update(result: OperationResult<T>): void {
    if (result) {
      if (typeof result === 'string') {
        // eslint-disable-next-line no-console
        console.error(result);
      } else {
        this.dataService.update(result.document, result.changes);
      }
    }
  }

  private getCurrentState() {
    if (this.document && this.changes) {
      return {
        document: this.document,
        changes: this.changes,
      };
    }

    throw new Error('Report or changes not initialized yet');
  }
}
export default GenericDocumentReactiveViewService;

import { TimePeriod } from '@agoy/document';
import { Period } from '@agoy/api-sdk-core';
import { addDays, isSameDay } from 'date-fns';
import { RootState } from 'redux/reducers';
import { AccountingPeriod } from 'types/Accounting';
import { ProgramStatusPeriodState } from '_shared/types';
import { AccountingViewClientYear, AccountingViewClient } from './reducer';

/**
 * Gets the status in reconciliation for a specific period. If no
 * period is supplied, then the last period is used.
 *
 * @param period
 * @returns
 */
export const getPeriodStatusReconciliation =
  <T>(
    period: Period | null,
    selector: (periodStatus: ProgramStatusPeriodState) => T,
    defaultValue?: T
  ) =>
  (state: RootState): T | undefined => {
    const { currentCustomer, currentFinancialYear } = state.customerView;

    if (currentCustomer && currentFinancialYear) {
      const programPeriodState =
        state.accountingView.clients[currentCustomer]?.periodStatus;
      if (programPeriodState) {
        const thePeriod =
          period ||
          // Find the last month
          state.accountingView.clients[currentCustomer].years[
            currentFinancialYear
          ]?.accountingBalances?.periods.reduce(
            (last: AccountingPeriod | null, curr) =>
              // Return the last of type month
              curr.type === 'month' ? curr : last,
            null
          );

        if (thePeriod) {
          // Find the first with periodId
          const ps = programPeriodState[thePeriod.id];
          if (ps) {
            return selector(ps);
          }
        }
      }
    }
    return defaultValue;
  };

export const currentClientYear =
  <T>(
    selector: (clientYear: AccountingViewClientYear) => T,
    defaultValue?: T
  ) =>
  (state: RootState): T | undefined => {
    const { currentCustomer, currentFinancialYear } = state.customerView;

    if (currentCustomer && currentFinancialYear) {
      const clientState = selectClientYear(
        state,
        currentCustomer,
        currentFinancialYear
      );
      if (clientState) {
        return selector(clientState);
      }
    }
    return defaultValue;
  };

export const previousClientYear =
  <T>(
    selector: (clientYear: AccountingViewClientYear) => T,
    defaultValue?: T
  ) =>
  (state: RootState): T | undefined => {
    const { currentCustomer, currentFinancialYear } = state.customerView;
    if (currentCustomer && currentFinancialYear) {
      const year = TimePeriod.fromFinancialYear(currentFinancialYear);
      const previousEnd = addDays(year.startDate, -1);
      const previousYear = state.customers[currentCustomer].financialYears.find(
        (y) => isSameDay(TimePeriod.fromFinancialYear(y).endDate, previousEnd)
      );
      if (previousYear) {
        const clientState = selectClientYear(
          state,
          currentCustomer,
          previousYear
        );
        if (clientState) {
          return selector(clientState);
        }
      }
    }
    return defaultValue;
  };

export const currentClient =
  <T>(selector: (client: AccountingViewClient) => T, defaultValue?: T) =>
  (state: RootState): T | undefined => {
    const { currentCustomer } = state.customerView;
    if (currentCustomer) {
      const clientState = state.accountingView.clients[currentCustomer];
      if (clientState) {
        return selector(clientState);
      }
    }
    return defaultValue;
  };

export const selectClientYear = (
  state: RootState,
  clientId: string,
  financialYear: string
): AccountingViewClientYear | null | undefined =>
  state.accountingView.clients[clientId]?.years[financialYear];

/**
 * Creates a redux selector for a specific clientId and financial year in the accountingView.
 *
 * The argument selector function is used to pick information inside that state if it exists.
 *
 * @param clientId
 * @param financialYear
 * @param selector
 * @returns
 */
export const clientYear =
  <T>(
    clientId: string,
    financialYear: string | { start: string; end: string } | undefined,
    selector: (client: AccountingViewClientYear) => T
  ) =>
  (state: RootState): T | null => {
    if (!financialYear) {
      return null;
    }
    const data =
      state.accountingView.clients[clientId]?.years[
        typeof financialYear === 'string'
          ? financialYear
          : TimePeriod.fromISODates(financialYear).value
      ];

    return data && selector(data);
  };
export const currentClientForNewReconciliation =
  <T>(clientId: string, selector: (client: AccountingViewClient) => T) =>
  (state: RootState): T | null => {
    if (!clientId) {
      return null;
    }
    const data = state.accountingView.clients[clientId];
    return data && selector(data);
  };

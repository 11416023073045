import React from 'react';
import { Route, useLocation } from 'react-router-dom';
import styled from '@emotion/styled';
import PersonSubMenu from '_person/components/_organisms/PersonSubMenu';
import { CustomersOverviewSubMenu } from './parts';
import TopHeader from './TopHeader';
import EmptyClientSubMenu from './parts/EmptyClientSubMenu';
import SystemMessages from '../SystemMessage';

const Root = styled.div`
  position: sticky;
  top: 0;
  width: 100%;
  z-index: ${(props) => props.theme.zIndex.appBar};
  @media print {
    position: inherit;
    display: none;
  }

  .MuiToolbar-root {
    max-width: 100vw;
    position: sticky;
    left: 0;
  }
`;

const Header = () => {
  const location = useLocation();

  if (location.pathname === '/login') {
    return null;
  }

  return (
    <Root id="app-header">
      <TopHeader />
      <Route path="/clients/:clientId/empty" component={EmptyClientSubMenu} />
      <Route path="/" exact component={CustomersOverviewSubMenu} />
      <Route path="/persons" exact component={PersonSubMenu} />
      <SystemMessages />
    </Root>
  );
};

export default Header;

/*
 * This is a GENERATED file, do not change manually!
 * Generated:
 * - from file k3-arsredovisning-2021-10-31-rev20230131 - Noter.csv
 * - Comments: AGOY-6790
 */

import {
  value,
  ref,
  sumAllowNull,
  id,
  AgoyDocumentPart,
} from '@agoy/document';
import { IxbrlCell } from '../../../../../common';
import {
  field,
  ixbrlCell,
  ixbrlField,
  table,
  tupleRef,
} from '../../../../../common/utils/util';
import { previousYear, year } from '../../../../../common/utils/date-util';
import {
  sumAccountsUBs,
} from '../../../../../common/utils/accountSumsUtil';

const cell = { type: 'field' as const };
const boolean = { type: 'boolean' as const };
const type = { type: 'type' as const };
const number = { type: 'number' as const };

export const getNote2065K3v2 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null
): AgoyDocumentPart<typeof note2065K3v2ContentDefinition> => ({
  active: false,
  number: value(undefined),
  name: noteLabel('Förutbetalda kostnader och upplupna intäkter'),
  data: {
    active: true,
    ForutbetaldaKostnaderUpplupnaIntakterAbstract: {
      type: 'group',
      active: true,
      level: 0,
      title: field('Förutbetalda kostnader och upplupna intäkter'),
      table: table<IxbrlCell>(
        'notes.note65.data.ForutbetaldaKostnaderUpplupnaIntakterAbstract.table',
        {
          id: 'OvrigaForutbetaldaKostnaderUpplupnaIntakterInterimspost',
          label: 'Typ av interimspost',
          dataType: 'text',
          active: true,
        },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) ?? '',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) ?? '',
          dataType: 'numeric',
          active: true,
        }
      )
        .addRows((rows) => {
          rows
            .addRowWithType(
              'ForutbetaldaKostnaderUpplupnaIntakterAbstract',
              'hidden',
              undefined,
              undefined,
              undefined
            )
            .addSubRows((rows) => {
              rows.addRowWithType(
                'UpparbetadEjFaktureradIntakt',
                'sum_accounts',
                noteLabel('Upparbetad men ej fakturerad intäkt'),
                ixbrlCell(ref(sumAccountsUBs('1620', false)), {
                  contextRef: 'balans0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:UpparbetadEjFaktureradIntakt',
                  standardRubrik: 'Upparbetad men ej fakturerad intäkt',
                  saldo: 'debit',
                  negateValue: false,
                }),
                ixbrlCell(ref(sumAccountsUBs('1620', false, 'previousYear')), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:UpparbetadEjFaktureradIntakt',
                  standardRubrik: 'Upparbetad men ej fakturerad intäkt',
                  saldo: 'debit',
                  negateValue: false,
                })
              );
              rows.addRowWithType(
                'ForutbetaldaKostnaderUpplupnaIntakterForutbetaldHyreskostnad',
                'sum_accounts',
                noteLabel('Förutbetalda hyreskostnader'),
                ixbrlCell(ref(sumAccountsUBs('1710:1719', false)), {
                  contextRef: 'balans0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:ForutbetaldaKostnaderUpplupnaIntakterForutbetaldHyreskostnad',
                  standardRubrik:
                    'Förutbetalda hyreskostnader i förutbetalda kostnader och upplupna intäkter',
                  saldo: 'debit',
                  negateValue: false,
                }),
                ixbrlCell(
                  ref(sumAccountsUBs('1710:1719', false, 'previousYear')),
                  {
                    contextRef: 'balans1',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:ForutbetaldaKostnaderUpplupnaIntakterForutbetaldHyreskostnad',
                    standardRubrik:
                      'Förutbetalda hyreskostnader i förutbetalda kostnader och upplupna intäkter',
                    saldo: 'debit',
                    negateValue: false,
                  }
                )
              );
              rows.addRowWithType(
                'ForutbetaldaKostnaderUpplupnaIntakterForutbetaldRantekostnad',
                'sum_accounts',
                noteLabel('Förutbetalda räntekostnader'),
                ixbrlCell(ref(sumAccountsUBs('1740:1749', false)), {
                  contextRef: 'balans0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:ForutbetaldaKostnaderUpplupnaIntakterForutbetaldRantekostnad',
                  standardRubrik:
                    'Förutbetalda räntekostnader i förutbetalda kostnader och upplupna intäkter',
                  saldo: 'debit',
                  negateValue: false,
                }),
                ixbrlCell(
                  ref(sumAccountsUBs('1740:1749', false, 'previousYear')),
                  {
                    contextRef: 'balans1',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:ForutbetaldaKostnaderUpplupnaIntakterForutbetaldRantekostnad',
                    standardRubrik:
                      'Förutbetalda räntekostnader i förutbetalda kostnader och upplupna intäkter',
                    saldo: 'debit',
                    negateValue: false,
                  }
                )
              );
              rows.addRowWithType(
                'ForutbetaldaKostnaderUpplupnaIntakterForutbetaldForsakringspremie',
                'sum_accounts',
                noteLabel('Förutbetalda försäkringspremier'),
                ixbrlCell(ref(sumAccountsUBs('1730:1739', false)), {
                  contextRef: 'balans0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:ForutbetaldaKostnaderUpplupnaIntakterForutbetaldForsakringspremie',
                  standardRubrik:
                    'Förutbetalda försäkringspremier i förutbetalda kostnader och upplupna intäkter',
                  saldo: 'debit',
                  negateValue: false,
                }),
                ixbrlCell(
                  ref(sumAccountsUBs('1730:1739', false, 'previousYear')),
                  {
                    contextRef: 'balans1',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:ForutbetaldaKostnaderUpplupnaIntakterForutbetaldForsakringspremie',
                    standardRubrik:
                      'Förutbetalda försäkringspremier i förutbetalda kostnader och upplupna intäkter',
                    saldo: 'debit',
                    negateValue: false,
                  }
                )
              );
              rows.addRowWithType(
                'ForutbetaldaKostnaderUpplupnaIntakterUpplupnaIntakter',
                'row',
                noteLabel('Upplupna intäkter'),
                ixbrlCell(value(undefined), {
                  contextRef: 'balans0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:ForutbetaldaKostnaderUpplupnaIntakterUpplupnaIntakter',
                  standardRubrik:
                    'Upplupna intäkter i förutbetalda kostnader och upplupna intäkter',
                  saldo: 'debit',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:ForutbetaldaKostnaderUpplupnaIntakterUpplupnaIntakter',
                  standardRubrik:
                    'Upplupna intäkter i förutbetalda kostnader och upplupna intäkter',
                  saldo: 'debit',
                  negateValue: false,
                })
              );
              rows.addRowWithType(
                'ForutbetaldaKostnaderUpplupnaIntakterUpplupnaRanteintakter',
                'sum_accounts',
                noteLabel('Upplupna ränteintäkter'),
                ixbrlCell(ref(sumAccountsUBs('1760:1769', false)), {
                  contextRef: 'balans0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:ForutbetaldaKostnaderUpplupnaIntakterUpplupnaRanteintakter',
                  standardRubrik:
                    'Upplupna ränteintäkter i förutbetalda kostnader och upplupna intäkter',
                  saldo: 'debit',
                  negateValue: false,
                }),
                ixbrlCell(
                  ref(sumAccountsUBs('1760:1769', false, 'previousYear')),
                  {
                    contextRef: 'balans1',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:ForutbetaldaKostnaderUpplupnaIntakterUpplupnaRanteintakter',
                    standardRubrik:
                      'Upplupna ränteintäkter i förutbetalda kostnader och upplupna intäkter',
                    saldo: 'debit',
                    negateValue: false,
                  }
                )
              );
              rows.addRowWithType(
                '1',
                'row',
                ixbrlCell(value('Typ av interimspost'), {
                  type: 'stringItemType',
                  name: 'se-gen-base:OvrigaForutbetaldaKostnaderUpplupnaIntakterInterimspost',
                  contextRef: 'balans0',
                  standardRubrik:
                    'Typ av övrig förutbetald kostnad eller upplupen intäkt',
                  negateValue: false,
                  tuple: {
                    name: 'se-gaap-ext:OvrigaForutbetaldaKostnaderUpplupnaIntakterTuple',
                    id: `${rows.getBaseId()}.1.tuple`,
                    tupleID: `${rows.getBaseId()}.1.tuple`,
                  },
                  tupleRef: tupleRef(1, `${rows.getBaseId()}.1.tuple`),
                  previousYearTuple: true,
                }),
                ixbrlCell(value(undefined), {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:OvrigaForutbetaldaKostnaderUpplupnaIntakter',
                  contextRef: 'balans0',
                  standardRubrik:
                    'Övrig förutbetald kostnad eller upplupen intäkt per typ av interimspost',
                  saldo: 'debit',
                  negateValue: false,
                  tupleRef: tupleRef(2, `${rows.getBaseId()}.1.tuple_balans0`),
                  previousYearTuple: false,
                }),
                ixbrlCell(value(undefined), {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:OvrigaForutbetaldaKostnaderUpplupnaIntakter',
                  contextRef: 'balans1',
                  standardRubrik:
                    'Övrig förutbetald kostnad eller upplupen intäkt per typ av interimspost',
                  saldo: 'debit',
                  negateValue: false,
                  tupleRef: tupleRef(3, `${rows.getBaseId()}.1.tuple_balans1`),
                  previousYearTuple: false,
                })
              );
              return rows.build();
            });
          rows.newRowTemplateGenerator((_id, baseId) => ({
            id: _id,
            active: true,
            type: 'row',
            cells: {
              OvrigaForutbetaldaKostnaderUpplupnaIntakterInterimspost:
                ixbrlCell(value('Typ av interimspost'), {
                  type: 'stringItemType',
                  name: 'se-gen-base:OvrigaForutbetaldaKostnaderUpplupnaIntakterInterimspost',
                  contextRef: 'balans0',
                  standardRubrik:
                    'Typ av övrig förutbetald kostnad eller upplupen intäkt',
                  negateValue: false,
                  tuple: {
                    name: 'se-gaap-ext:OvrigaForutbetaldaKostnaderUpplupnaIntakterTuple',
                    id: `${baseId}.${_id}.tuple`,
                    tupleID: `${baseId}.${_id}.tuple`,
                  },
                  tupleRef: tupleRef(1, `${baseId}.${_id}.tuple`),
                  previousYearTuple: true,
                }),
              year: ixbrlCell(value(undefined), {
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:OvrigaForutbetaldaKostnaderUpplupnaIntakter',
                contextRef: 'balans0',
                standardRubrik:
                  'Övrig förutbetald kostnad eller upplupen intäkt per typ av interimspost',
                saldo: 'debit',
                negateValue: false,
                tupleRef: tupleRef(2, `${baseId}.${_id}.tuple_balans0`),
                previousYearTuple: false,
              }),
              previousYear: ixbrlCell(value(undefined), {
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:OvrigaForutbetaldaKostnaderUpplupnaIntakter',
                contextRef: 'balans1',
                standardRubrik:
                  'Övrig förutbetald kostnad eller upplupen intäkt per typ av interimspost',
                saldo: 'debit',
                negateValue: false,
                tupleRef: tupleRef(3, `${baseId}.${_id}.tuple_balans1`),
                previousYearTuple: false,
              }),
            },
          }));
          rows.addRowWithType(
            'ForutbetaldaKostnaderUpplupnaIntakter',
            'sum',
            noteLabel('Summa'),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note65.data.ForutbetaldaKostnaderUpplupnaIntakterAbstract.table.ForutbetaldaKostnaderUpplupnaIntakterAbstract.*.year'
                  )
                )
              ),
              {
                contextRef: 'balans0',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:ForutbetaldaKostnaderUpplupnaIntakter',
                standardRubrik: 'Förutbetalda kostnader och upplupna intäkter',
                saldo: 'debit',
                negateValue: false,
              }
            ),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note65.data.ForutbetaldaKostnaderUpplupnaIntakterAbstract.table.ForutbetaldaKostnaderUpplupnaIntakterAbstract.*.previousYear'
                  )
                )
              ),
              {
                contextRef: 'balans1',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:ForutbetaldaKostnaderUpplupnaIntakter',
                standardRubrik: 'Förutbetalda kostnader och upplupna intäkter',
                saldo: 'debit',
                negateValue: false,
              }
            )
          );
          return rows.build();
        })
        .build(),
      NotForutbetaldaKostnaderUpplupnaIntakterKommentar: {
        type: 'group',
        active: true,
        level: 0,
        title: field(
          'Kommentar till specifikation av förutbetalda kostnader och upplupna intäkter'
        ),
        value: ixbrlField(field(''), {
          contextRef: 'period0',
          type: 'stringItemType',
          name: 'se-gen-base:NotForutbetaldaKostnaderUpplupnaIntakterKommentar',
          standardRubrik:
            'Kommentar till specifikation av förutbetalda kostnader och upplupna intäkter',
        }),
      },
    },
  },
  group: value(undefined),
  section: value(undefined),
  validForReportType: {
    k2: true,
    k3: true,
  },
});
export const note2065K3v2ContentDefinition = {
  type: 'part' as const,
  children: {
    active: boolean,
    number: cell,
    name: cell,
    data: {
      type: 'part' as const,
      children: {
        active: boolean,

        ForutbetaldaKostnaderUpplupnaIntakterAbstract: {
          type: 'part' as const,
          children: {
            type: type,
            level: number,
            active: boolean,

            title: cell,

            table: { type: 'table' as const },

            NotForutbetaldaKostnaderUpplupnaIntakterKommentar: {
              type: 'part' as const,
              children: {
                type: type,
                level: number,
                active: boolean,

                title: cell,

                value: cell,
              },
            },
          },
        },
      },
    },
    group: cell,
    section: cell,
    validForReportType: {
      type: 'part' as const,
      children: {
        k2: boolean,
        k3: boolean,
      },
    },
  },
};

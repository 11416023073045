import {
  AgoyDocumentPartChanges,
  AgoyDocumentStructurePart,
} from '@agoy/document';
import { FinancialReportChanges } from './types';

const removeSection = <T extends AgoyDocumentStructurePart>(
  changes: AgoyDocumentPartChanges<T>,
  ids: string[]
): AgoyDocumentPartChanges<T> => {
  if (ids.length === 1) {
    const newChanges = { ...changes };
    delete newChanges[ids[0]];
    return newChanges;
  }
  // Deeper removal not implemented
  console.error('Trying to delete content to deep in changes');
  return changes;
};

const removePart = (
  changes: FinancialReportChanges,
  ids: string[]
): FinancialReportChanges => {
  const [part] = ids;
  if (ids.length === 1) {
    const newChanges = { ...changes };
    delete newChanges[part];
    return newChanges;
  }
  return {
    ...changes,
    [part]: removeSection(changes[part], ids.slice(1)),
  };
};

const removeChangesById = (
  changes: FinancialReportChanges,
  id: string
): FinancialReportChanges => {
  const ids = id.split('.');
  return removePart(changes, ids);
};

export default removeChangesById;

import { Buffer } from 'buffer';

window.Buffer = Buffer;

const isArrayBufferSupported = Buffer.from(new Uint8Array([1]).buffer)[0] === 1;

function arrayBufferToBufferAsArgument(ab) {
  return Buffer.from(ab);
}

function arrayBufferToBufferCycle(ab) {
  const buffer = Buffer.from(ab.byteLength);
  const view = new Uint8Array(ab);
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < buffer.length; ++i) {
    buffer[i] = view[i];
  }
  return buffer;
}

const arrayBufferToBuffer = isArrayBufferSupported
  ? arrayBufferToBufferAsArgument
  : arrayBufferToBufferCycle;

export default arrayBufferToBuffer;

import React from 'react';
import styled from '@emotion/styled';
import { Box, Typography } from '@material-ui/core';
import { AgoyTable, AgoyTableRow } from '@agoy/document';
import { Table } from '../../../UI/CommonPrintTable';
import {
  flattenRows,
  hasPreviousYearColumn,
  includeRowInPrint,
  includeRowWithStringInSecondColumn,
} from '../../../utils/utils';

interface NoteTableProps {
  table: AgoyTable;
  caption?: string;
  tableId: string;
  groupRowsInterval?: number;
  hasPreviousYear?: boolean;
  hideHeader?: boolean;
  className?: string;
  displayOnlyStringRow?: boolean;
  displayUnfiltered?: boolean;
  isXBRLRender?: boolean;
}

const StyledTable = styled(Table)`
  .MuiTableCell-root {
    width: 200px;

    &:first-of-type {
      width: auto;
    }

    &:last-child {
      width: 200px;
    }
  }
`;

/**
 * Used to be called ThreeColumnTable, but the number of columns is not controlled by this component,
 * that is done for each note in their config in modules/annual-report-document.
 * @groupRowsInterval is used to group rows in the table
 */
const NoteTable = ({
  className,
  table,
  caption = '',
  tableId,
  groupRowsInterval,
  hasPreviousYear = false,
  hideHeader = false,
  displayOnlyStringRow,
  displayUnfiltered,
  isXBRLRender = false,
}: NoteTableProps): JSX.Element => {
  const filterCondition = displayOnlyStringRow
    ? includeRowWithStringInSecondColumn
    : includeRowInPrint;

  const rows = flattenRows(table.rows).filter((row) =>
    displayUnfiltered ? row : filterCondition(row)
  );

  const newGroupedRows: AgoyTableRow[][] = [];

  rows.forEach((item, index) => {
    if (groupRowsInterval) {
      const firstItemInGroup = index % groupRowsInterval === 0;

      if (firstItemInGroup) {
        newGroupedRows[newGroupedRows.length] = [item];
      } else {
        newGroupedRows[newGroupedRows.length - 1].push(item);
      }
    }
  });
  const groupedRows = newGroupedRows;

  const columns = table.columns.filter(
    (col) =>
      col.id !== 'hidden' && (hasPreviousYear || !hasPreviousYearColumn(col))
  );

  return (
    <>
      {rows.length > 0 && caption.length > 0 && (
        <Typography variant="h4">{caption}</Typography>
      )}
      {groupedRows.length ? (
        groupedRows.map((group, groupIndex) => (
          <Box mb={2} key={`${tableId}.${group[0]?.id}`}>
            <StyledTable
              baseId="annualReport"
              tableId={tableId}
              rows={group}
              columns={columns}
              hideHeader={groupIndex !== 0 || hideHeader}
              className={className}
              isXBRLRender={isXBRLRender}
            />
          </Box>
        ))
      ) : (
        <StyledTable
          baseId="annualReport"
          tableId={tableId}
          rows={rows}
          columns={columns}
          className={className}
          hideHeader={hideHeader}
          isXBRLRender={isXBRLRender}
        />
      )}
    </>
  );
};

export default NoteTable;

import { ToggleFinancialReportBalanceSheetAssetsSectionActive } from '../../action-type-declarations';
import { FinancialReportClientPeriodState } from '../types';

const toggleFinancialReportBalanceSheetAssetsSectionActive = (
  state: FinancialReportClientPeriodState,
  action: ToggleFinancialReportBalanceSheetAssetsSectionActive
) => {
  const { section } = action;

  if (!state.report) {
    return state;
  }

  return {
    ...state,
    report: {
      ...state.report,
      balanceSheetAssets: {
        ...state.report.balanceSheetAssets,
        section: {
          ...state.report.balanceSheetAssets.section,
          [section]: !state.report.balanceSheetAssets.section[section],
        },
      },
    },

    changes: {
      ...state.changes,
      balanceSheetAssets: {
        ...state.changes.balanceSheetAssets,
        section: {
          ...state.changes.balanceSheetAssets?.section,
          [section]: !state.report.balanceSheetAssets.section[section],
        },
      },
    },
  };
};

export default toggleFinancialReportBalanceSheetAssetsSectionActive;

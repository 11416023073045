import { AgoyTableRow } from '@agoy/document';

const isUserAddedRow = (row: AgoyTableRow) => row.id.startsWith('@');

/**
 * It filters out rows added by the user if the submission to BV
 * is digital. Otherwise returns all rows.
 * @param rows: rows from the config table
 * @param isDigitalSubmission: Is it a digital submission to BV?
 */
export const renderResultsDispositionRows = (
  rows: AgoyTableRow[],
  isDigitalSubmission: boolean | null | undefined
): AgoyTableRow[] => {
  const rowsToDisplay = isDigitalSubmission
    ? rows.filter((row) => !isUserAddedRow(row))
    : rows.map((row) => row);
  return rowsToDisplay;
};

export default renderResultsDispositionRows;

import React from 'react';
import { useIntl } from 'react-intl';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

const getErrorBaseId = (error: string) => {
  switch (error) {
    case 'SIE_IMPORT_WRONG_ORG_NUMBER':
      return 'sie.orgNr';
    case 'OVERLAPPING_FINANCIAL_YEAR':
      return 'sie.overlapping';
    default:
      return 'sie.unknown';
  }
};

type UploadErrorDialogProps = {
  error: string;
  onClose: () => void;
};

const UploadErrorDialog = ({
  error,
  onClose,
}: UploadErrorDialogProps): JSX.Element => {
  const { formatMessage } = useIntl();

  const errorBaseId = getErrorBaseId(error);

  return (
    <Dialog
      open={!!error && error !== 'SIE_IMPORT_CHANGE_IN_LOCKED_PERIOD'}
      onClose={onClose}
    >
      <DialogTitle>
        {formatMessage({ id: `${errorBaseId}.error.title` })}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {formatMessage({ id: `${errorBaseId}.error.body` })}
          {errorBaseId === 'sie.orgNr' && (
            <>
              <br />
              <strong>
                {formatMessage({ id: `${errorBaseId}.error.strong` })}
              </strong>
            </>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" autoFocus>
          {formatMessage({ id: 'forgot.back' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UploadErrorDialog;

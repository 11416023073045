import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { useIntl } from 'react-intl';
import { useRouteMatch, generatePath, useHistory } from 'react-router-dom';
import { ClientsRouteParams } from 'routes/types';
import Button from './Buttons/Button';

type MissingClientStartDateDialogProps = {
  onClose: () => void;
};

const MissingClientStartDateDialog = ({
  onClose,
}: MissingClientStartDateDialogProps) => {
  const { params } = useRouteMatch<ClientsRouteParams>();
  const { push } = useHistory();
  const { formatMessage } = useIntl();
  const openUrl = generatePath('/clients/:clientId/', params);

  const onOpen = () => {
    onClose();
    push(openUrl);
  };

  return (
    <Dialog open onClose={onClose}>
      <DialogTitle>
        {formatMessage({ id: 'program.status.error.startDate.title' })}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {formatMessage({ id: 'program.status.error.startDate.text' })}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          label={formatMessage({ id: 'program.status.error.startDate.open' })}
          onClick={onOpen}
        />
        <Button
          label={formatMessage({ id: 'close' })}
          variant="text"
          onClick={onClose}
        />
      </DialogActions>
    </Dialog>
  );
};

export default MissingClientStartDateDialog;

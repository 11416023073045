import { Action } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { startOfYear, endOfMonth, isSameYear } from 'date-fns';

import {
  AccountingBalancesAccountResolver,
  Cell,
  TimePeriod,
} from '@agoy/document';
import {
  TaxCalculationConfig,
  TaxView,
  TaxCalculationInput,
  TaxCalculationPart,
  TaxDocument,
  taxCalculation,
  getYearPercentage,
} from '@agoy/tax-document';
import debounceByKey from 'utils/debounceByKey';
import storeTaxConfig from 'Api/Client/tax';
import { ClientAdjustmentsAction, ClientYearAction } from 'redux/actions';
import { RootState } from 'redux/reducers';
import { reformatDate } from 'utils/format';
import toPeriods from 'utils/financialYear/toPeriods';
import {
  SET_TAX_VIEW_CONFIG,
  SET_TAX_VIEW_STATE,
  DELETE_TAX_TABLE_ROW,
  UPDATE_TAX_TABLE_ROW_LABEL,
  UPDATE_TAX_TABLE_ROW_VALUE,
  UPDATE_TAX_TABLE_ROW_REFERENCE,
  ADD_EMPTY_TAX_TABLE_ROW,
  UPDATE_TRANSACTION_ROW_ACCOUNT,
  REMOVE_USER_TRANSACTIONS,
  RESET_YEAR_END_PLANNING,
  RESET_TAX_CALCULATION_TABLE,
  RESET_TAX_CALCULATION_ADJUSTMENTS,
  UPDATE_YEAR_END_PLANNING_CHECKSUM,
  RESET_NONE_TAXABLE_INCOME,
  RESET_NONE_DEDUCTIBLE_EXPENSES,
  RESET_TAX_PARTICULAR_SALARY,
  SET_TAX_DOCUMENTS,
  RESET_TAX_DOCUMENTS,
  SET_TAX_EXTERNAL_DOCUMENT_VALUES,
  ADD_EMPTY_TAX_SUMMARY_TABLE_ROW,
  UPDATE_TAX_SUMMARY_TABLE_ROW_VALUE,
  ADD_CONTRACTUAL_PENSION_TABLE_ROW,
  DELETE_CONTRACTUAL_PENSION_TABLE_ROW,
  UPDATE_CONTRACTUAL_PENSION_TABLE_ROW_LABEL,
  UPDATE_CONTRACTUAL_PENSION_TABLE_ROW_VALUE,
  UPDATE_CONTRACTUAL_PENSION_TABLE_ROW_REFERENCE,
  UPDATE_FORA_FIELD,
  RESET_TAX_FORA,
  UPDATE_TAX_VIEW_FIELD,
  UPDATE_DOCUMENT_CELL,
  ADD_DOCUMENT_ROW,
  DELETE_DOCUMENT_ROW,
  RESET_DOCUMENT_PART,
  TOGGLE_TAX_SECTION_ACTIVE,
  ADD_TAX_SECTIONS,
} from './action-types';

export const updateTaxView = (
  clientId: string,
  financialYear: string,
  dispatch: ThunkDispatch<RootState, unknown, Action<string>>,
  getState: () => RootState,
  changedByUser = true,
  taxPeriod?: string
): void => {
  const { accountingBalances, accountingBalancesUpdatedAt } =
    getState().accountingView.clients[clientId]?.years[financialYear] || {};

  const view = getState().taxView.clients[clientId]?.years[financialYear];
  if (!accountingBalances) {
    dispatch(setTaxViewState(clientId, financialYear, null));
  } else if (view) {
    const { currentPeriod } = getState().customerView;
    const { config, state, externalDocumentValues } = view;
    const { periods, accounts, financialYears } = accountingBalances;
    const financialYearObj = financialYears.find(
      (y) => y.id === periods[0].financialYearId
    );
    const thePeriod = taxPeriod || currentPeriod;

    if (
      config &&
      thePeriod &&
      financialYearObj &&
      accountingBalancesUpdatedAt
    ) {
      const financialYearId = financialYearObj.id;
      const financialYearPeriod = TimePeriod.fromDates(
        financialYearObj.start,
        financialYearObj.end,
        'yyyy-MM-dd'
      );
      const periodStart = reformatDate(thePeriod, 'yyyyMMdd', 'yyyy-MM-dd');
      const existingPeriod = periods.find((p) => p.start === periodStart);
      if (!existingPeriod) {
        console.warn(`Missing period ${periodStart}`);
      }

      const period = existingPeriod
        ? TimePeriod.fromISODates(existingPeriod.start, existingPeriod.end)
        : TimePeriod.monthOf(thePeriod);

      const input: TaxCalculationInput = {
        accountResolver: new AccountingBalancesAccountResolver(
          financialYearId,
          periods,
          accounts
        ),
        defaultPeriod:
          existingPeriod?.type === 'year_end' ? financialYearPeriod : period,
        periods: {
          january: new TimePeriod(
            startOfYear(financialYearPeriod.endDate),
            endOfMonth(startOfYear(financialYearPeriod.endDate))
          ),
          financialYear: financialYearPeriod,
          calendarYear: isSameYear(period.endDate, financialYearPeriod.endDate)
            ? new TimePeriod(startOfYear(period.endDate), period.endDate)
            : new TimePeriod(financialYearPeriod.startDate, period.endDate),
        },
        sieUpdated: new Date(accountingBalancesUpdatedAt).toUTCString(),
        lastPeriod: period.end === financialYearPeriod.end,
        yearPercentage: getYearPercentage(period, periods),
      };
      const taxViewState = taxCalculation(
        config,
        input,
        state,
        externalDocumentValues
      );
      if (
        changedByUser &&
        taxViewState.yearEndPlanning !== null &&
        taxViewState.yearEndPlanning.checksum !==
          config.yearEndPlanning.checksum
      ) {
        dispatch(
          updateYearEndPlanningChecksum(
            financialYear,
            thePeriod,
            taxViewState.yearEndPlanning.checksum
          )
        );
      } else {
        dispatch(setTaxViewState(clientId, financialYear, taxViewState));
      }
    } else {
      dispatch(setTaxViewState(clientId, financialYear, null));
    }
  }
};

export const setTaxViewConfig =
  (
    clientId: string,
    financialYear: string,
    config: TaxCalculationConfig,
    period?: string
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    dispatch(setTaxViewConfigAction(clientId, financialYear, config));

    updateTaxView(clientId, financialYear, dispatch, getState, false, period);
  };

interface SetTaxViewConfig extends ClientYearAction {
  type: typeof SET_TAX_VIEW_CONFIG;
  config: TaxCalculationConfig;
}

const setTaxViewConfigAction = (
  clientId: string,
  financialYear: string,
  config: TaxCalculationConfig
): SetTaxViewConfig => ({
  clientId,
  financialYear,
  type: SET_TAX_VIEW_CONFIG,
  config,
});

interface SetTaxViewState extends ClientYearAction {
  type: typeof SET_TAX_VIEW_STATE;
  state: TaxView | null;
}

export const setTaxViewState = (
  clientId: string,
  financialYear: string,
  state: TaxView | null
): SetTaxViewState => ({
  clientId,
  financialYear,
  type: SET_TAX_VIEW_STATE,
  state,
});

const debouncedStore = debounceByKey(
  (
    clientId: string,
    financialYear: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    config: Parameters<typeof storeTaxConfig>[2]
  ) => `${clientId}-${financialYear}`,
  storeTaxConfig,
  2000
);

// TODO remove once switched all over to saveTaxConfigFromState
const storeTaxConfigFromState = (
  getState: () => RootState,
  currentFinancialYear: string
) => {
  const { currentCustomer } = getState().customerView;
  const isFortnoxSupport = getState().user.email === 'support@fortnox.se';
  if (currentCustomer) {
    const view =
      getState().taxView.clients[currentCustomer]?.years[currentFinancialYear];
    if (view?.config && !isFortnoxSupport) {
      debouncedStore(currentCustomer, currentFinancialYear, view.config);
    }
  }
};

const saveTaxConfigFromState = (
  getState: () => RootState,
  currentFinancialYear: string,
  clientId: string
) => {
  const isFortnoxSupport = getState().user.email === 'support@fortnox.se';
  const view =
    getState().taxView.clients[clientId]?.years[currentFinancialYear];
  if (view?.config && !isFortnoxSupport) {
    debouncedStore(clientId, currentFinancialYear, view.config);
  }
};

export const addEmptyTaxTableRow =
  (
    currentFinancialYear: string,
    period: string,
    part: TaxCalculationPart,
    rowId: string
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    const { currentCustomer } = getState().customerView;
    if (currentCustomer && currentFinancialYear) {
      dispatch(
        addEmptyTaxTableRowAction(
          currentCustomer,
          currentFinancialYear,
          part,
          rowId
        )
      );
      updateTaxView(
        currentCustomer,
        currentFinancialYear,
        dispatch,
        getState,
        undefined,
        period
      );

      storeTaxConfigFromState(getState, currentFinancialYear);
    }
  };

export const addEmptyContractualPensionTableRow =
  (
    currentFinancialYear: string,
    period: string,
    part: string,
    rowId: string
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    const { currentCustomer } = getState().customerView;

    if (currentCustomer && currentFinancialYear) {
      dispatch(
        addEmptyContractualPensionTableRowAction(
          currentCustomer,
          currentFinancialYear,
          part,
          rowId
        )
      );

      updateTaxView(
        currentCustomer,
        currentFinancialYear,
        dispatch,
        getState,
        undefined,
        period
      );

      storeTaxConfigFromState(getState, currentFinancialYear);
    }
  };

export const deleteContractualPensionTableRow =
  (
    currentFinancialYear: string,
    period: string,
    part: string,
    rowId: string
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    const { currentCustomer } = getState().customerView;

    if (currentCustomer && currentFinancialYear) {
      dispatch(
        deleteContractualPensionTableRowAction(
          currentCustomer,
          currentFinancialYear,
          part,
          rowId
        )
      );
    }
  };

const addEmptyContractualPensionTableRowAction = (
  clientId: string,
  financialYear: string,
  part: string,
  rowId: string
): AddEmptyContractualPensionTableRow => ({
  clientId,
  financialYear,
  type: ADD_CONTRACTUAL_PENSION_TABLE_ROW,
  part,
  rowId,
  lastModified: new Date().toISOString(),
});

interface AddEmptyTaxTableRow extends ClientYearAction {
  type: typeof ADD_EMPTY_TAX_TABLE_ROW;
  part: TaxCalculationPart;
  rowId: string;
  lastModified: string;
}

interface AddEmptyContractualPensionTableRow extends ClientYearAction {
  type: typeof ADD_CONTRACTUAL_PENSION_TABLE_ROW;
  part: string;
  rowId: string;
  lastModified: string;
}

const addEmptyTaxTableRowAction = (
  clientId: string,
  financialYear: string,
  part: TaxCalculationPart,
  rowId: string
): AddEmptyTaxTableRow => ({
  clientId,
  financialYear,
  type: ADD_EMPTY_TAX_TABLE_ROW,
  part,
  rowId,
  lastModified: new Date().toISOString(),
});

interface AddEmptyTaxSummaryTableRow extends ClientYearAction {
  type: typeof ADD_EMPTY_TAX_SUMMARY_TABLE_ROW;
  part: string;
  rowId: string;
  lastModified: string;
}

export const deleteTaxTableRow =
  (
    currentFinancialYear: string,
    period: string,
    part: TaxCalculationPart,
    rowId: string
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    const { currentCustomer } = getState().customerView;
    if (currentCustomer && currentFinancialYear) {
      dispatch(
        deleteTaxTableRowAction(
          currentCustomer,
          currentFinancialYear,
          part,
          rowId
        )
      );

      updateTaxView(
        currentCustomer,
        currentFinancialYear,
        dispatch,
        getState,
        undefined,
        period
      );

      storeTaxConfigFromState(getState, currentFinancialYear);
    }
  };

interface DeleteTaxTableRow extends ClientYearAction {
  type: typeof DELETE_TAX_TABLE_ROW;
  part: TaxCalculationPart;
  rowId: string;
  lastModified: string;
}

const deleteTaxTableRowAction = (
  clientId: string,
  financialYear: string,
  part: TaxCalculationPart,
  rowId: string
): DeleteTaxTableRow => ({
  clientId,
  financialYear,
  type: DELETE_TAX_TABLE_ROW,
  part,
  rowId,
  lastModified: new Date().toISOString(),
});

interface DeleteContractualPensionTableRow extends ClientYearAction {
  type: typeof DELETE_CONTRACTUAL_PENSION_TABLE_ROW;
  part: string;
  rowId: string;
  lastModified: string;
}

const deleteContractualPensionTableRowAction = (
  clientId: string,
  financialYear: string,
  part: string,
  rowId: string
): DeleteContractualPensionTableRow => ({
  clientId,
  financialYear,
  type: DELETE_CONTRACTUAL_PENSION_TABLE_ROW,
  part,
  rowId,
  lastModified: new Date().toISOString(),
});

export const updateTaxTableRowLabel =
  (
    currentFinancialYear: string,
    period: string,
    part: TaxCalculationPart,
    rowId: string,
    label: string
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    const { currentCustomer } = getState().customerView;
    if (currentCustomer && currentFinancialYear) {
      dispatch(
        updateTaxTableRowLabelAction(
          currentCustomer,
          currentFinancialYear,
          part,
          rowId,
          label
        )
      );

      updateTaxView(
        currentCustomer,
        currentFinancialYear,
        dispatch,
        getState,
        undefined,
        period
      );

      storeTaxConfigFromState(getState, currentFinancialYear);
    }
  };

interface UpdateTaxTableRowLabel extends ClientYearAction {
  type: typeof UPDATE_TAX_TABLE_ROW_LABEL;
  part: TaxCalculationPart;
  rowId: string;
  label: string;
  lastModified: string;
}

interface UpdateContractualPensionTableRowLabel extends ClientYearAction {
  type: typeof UPDATE_CONTRACTUAL_PENSION_TABLE_ROW_LABEL;
  part: string;
  rowId: string;
  label: string;
  lastModified: string;
}
interface UpdateContractualPensionTableRowValue extends ClientYearAction {
  type: typeof UPDATE_CONTRACTUAL_PENSION_TABLE_ROW_VALUE;
  part: string;
  rowId: string;
  value: number;
  lastModified: string;
}

const updateTaxTableRowLabelAction = (
  clientId: string,
  financialYear: string,
  part: TaxCalculationPart,
  rowId: string,
  label: string
): UpdateTaxTableRowLabel => ({
  clientId,
  financialYear,
  type: UPDATE_TAX_TABLE_ROW_LABEL,
  part,
  rowId,
  label,
  lastModified: new Date().toISOString(),
});

export const updateContractualPensionLabel =
  (
    currentFinancialYear: string,
    period: string,
    part: string,
    rowId: string,
    label: string
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    const { currentCustomer } = getState().customerView;

    if (currentCustomer && currentFinancialYear) {
      dispatch(
        updateContractualPensionLabelAction(
          currentCustomer,
          currentFinancialYear,
          part,
          rowId,
          label
        )
      );
    }
  };

export const updateContractualPensionValue =
  (
    currentFinancialYear: string,
    period: string,
    part: string,
    rowId: string,
    value: number
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    const { currentCustomer } = getState().customerView;

    if (currentCustomer && currentFinancialYear) {
      dispatch(
        updateContractualPensionValueAction(
          currentCustomer,
          currentFinancialYear,
          part,
          rowId,
          value
        )
      );
    }
  };

const updateContractualPensionLabelAction = (
  clientId: string,
  financialYear: string,
  part: string,
  rowId: string,
  label: string
): UpdateContractualPensionTableRowLabel => ({
  clientId,
  financialYear,
  type: UPDATE_CONTRACTUAL_PENSION_TABLE_ROW_LABEL,
  part,
  rowId,
  label,
  lastModified: new Date().toISOString(),
});

const updateContractualPensionValueAction = (
  clientId: string,
  financialYear: string,
  part: string,
  rowId: string,
  value: number
): UpdateTaxSummaryTableRowValue => ({
  clientId,
  financialYear,
  type: UPDATE_TAX_SUMMARY_TABLE_ROW_VALUE,
  part,
  rowId,
  value,
  lastModified: new Date().toISOString(),
});

export const updateTaxTableRowValue =
  (
    currentFinancialYear: string,
    period: string,
    part: TaxCalculationPart | string,
    rowId: string,
    value: number
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    const { currentCustomer } = getState().customerView;
    if (currentCustomer && currentFinancialYear) {
      dispatch(
        updateTaxTableRowValueAction(
          currentCustomer,
          currentFinancialYear,
          part,
          rowId,
          value
        )
      );

      updateTaxView(
        currentCustomer,
        currentFinancialYear,
        dispatch,
        getState,
        undefined,
        period
      );

      storeTaxConfigFromState(getState, currentFinancialYear);
    }
  };

interface UpdateTaxSummaryTableRowValue extends ClientYearAction {
  type: typeof UPDATE_TAX_SUMMARY_TABLE_ROW_VALUE;
  part: string;
  rowId: string;
  value: number;
  lastModified: string;
}

interface UpdateTaxTableRowValue extends ClientYearAction {
  type: typeof UPDATE_TAX_TABLE_ROW_VALUE;
  part: TaxCalculationPart | string;
  rowId: string;
  value: number;
  lastModified: string;
}

const updateTaxTableRowValueAction = (
  clientId: string,
  financialYear: string,
  part: TaxCalculationPart | string,
  rowId: string,
  value: number
): UpdateTaxTableRowValue => ({
  clientId,
  financialYear,
  type: UPDATE_TAX_TABLE_ROW_VALUE,
  part,
  rowId,
  value,
  lastModified: new Date().toISOString(),
});

export const updateTaxTableRowReference =
  (
    currentFinancialYear: string,
    period: string,
    part: TaxCalculationPart | string,
    rowId: string,
    label: string,
    reference?: string
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    const { currentCustomer } = getState().customerView;
    if (currentCustomer) {
      dispatch(
        updateTaxTableRowReferenceAction(
          currentCustomer,
          currentFinancialYear,
          part,
          rowId,
          label,
          reference
        )
      );

      updateTaxView(
        currentCustomer,
        currentFinancialYear,
        dispatch,
        getState,
        undefined,
        period
      );

      storeTaxConfigFromState(getState, currentFinancialYear);
    }
  };

export const updateTransactionRowAccount =
  (
    currentFinancialYear: string,
    period: string,
    rowId: string,
    account: string
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    const { currentCustomer } = getState().customerView;
    if (currentCustomer && currentFinancialYear) {
      dispatch(
        updateTransactionRowAccountAction(
          currentCustomer,
          currentFinancialYear,
          rowId,
          account
        )
      );

      updateTaxView(
        currentCustomer,
        currentFinancialYear,
        dispatch,
        getState,
        undefined,
        period
      );

      storeTaxConfigFromState(getState, currentFinancialYear);
    }
  };

export const updateContractualPensionTableRowReference =
  (
    currentFinancialYear: string,
    period: string,
    part: string,
    rowId: string,
    label: string,
    reference: string
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    const { currentCustomer } = getState().customerView;

    if (currentCustomer) {
      dispatch(
        updateContractualPensionTableRowReferenceAction(
          currentCustomer,
          currentFinancialYear,
          part,
          rowId,
          label,
          reference
        )
      );
    }
  };

export const updateForaField =
  (
    currentFinancialYear: string,
    period: string,
    part: string,
    field: string,
    value: string | number
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    const { currentCustomer } = getState().customerView;

    if (currentCustomer) {
      dispatch(
        updateForaFieldAction(
          currentCustomer,
          currentFinancialYear,
          part,
          field,
          value
        )
      );
    }
  };

interface UpdateTaxTableRowReference extends ClientYearAction {
  type: typeof UPDATE_TAX_TABLE_ROW_REFERENCE;
  part: TaxCalculationPart | string;
  rowId: string;
  label: string;
  reference?: string;
}

interface UpdateContractualPensionTableRowReference extends ClientYearAction {
  type: typeof UPDATE_CONTRACTUAL_PENSION_TABLE_ROW_REFERENCE;
  part: string;
  rowId: string;
  label: string;
  reference: string;
}
interface UpdateForaField extends ClientYearAction {
  type: typeof UPDATE_FORA_FIELD;
  part: string;
  field: string;
  value: string | number;
}

const updateTaxTableRowReferenceAction = (
  clientId: string,
  financialYear: string,
  part: TaxCalculationPart | string,
  rowId: string,
  label: string,
  reference?: string
): UpdateTaxTableRowReference => ({
  clientId,
  financialYear,
  type: UPDATE_TAX_TABLE_ROW_REFERENCE,
  part,
  rowId,
  label,
  reference,
});

const updateContractualPensionTableRowReferenceAction = (
  clientId: string,
  financialYear: string,
  part: string,
  rowId: string,
  label: string,
  reference: string
): UpdateContractualPensionTableRowReference => ({
  clientId,
  financialYear,
  type: UPDATE_CONTRACTUAL_PENSION_TABLE_ROW_REFERENCE,
  part,
  rowId,
  label,
  reference,
});

const updateForaFieldAction = (
  clientId: string,
  financialYear: string,
  part: string,
  field: string,
  value: string | number
): UpdateForaField => ({
  clientId,
  financialYear,
  type: UPDATE_FORA_FIELD,
  part,
  field,
  value,
});

interface UpdateTransactionRowAccount extends ClientYearAction {
  type: typeof UPDATE_TRANSACTION_ROW_ACCOUNT;
  rowId: string;
  account: string;
  lastModified: string;
}

const updateTransactionRowAccountAction = (
  clientId: string,
  financialYear: string,
  rowId: string,
  account: string
): UpdateTransactionRowAccount => ({
  clientId,
  financialYear,
  type: UPDATE_TRANSACTION_ROW_ACCOUNT,
  rowId,
  account,
  lastModified: new Date().toISOString(),
});

interface RemoveUserTransaction extends ClientYearAction {
  type: typeof REMOVE_USER_TRANSACTIONS;
  lastModified: string;
}

const removeUserTransactionsAction = (
  clientId: string,
  financialYear: string
): RemoveUserTransaction => ({
  clientId,
  financialYear,
  type: REMOVE_USER_TRANSACTIONS,
  lastModified: new Date().toISOString(),
});

export const removeUserTransactions =
  (
    currentFinancialYear: string
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  (dispatch, getState) => {
    const { currentCustomer } = getState().customerView;

    if (currentCustomer && currentFinancialYear) {
      dispatch(
        removeUserTransactionsAction(currentCustomer, currentFinancialYear)
      );
    }
  };

/**
 *
 * @param currentFinancialYear
 * @param period
 * @param actionType
 * @param keep Optional list of ids to keep, only for RESET_YEAR_END_PLANNING
 * @returns
 */
export const resetGenericTax =
  (
    currentFinancialYear: string,
    period: string,
    actionType: string,
    keep?: string[]
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  (dispatch, getState) => {
    const { currentCustomer } = getState().customerView;

    if (currentCustomer) {
      const companyType = getState().customers[currentCustomer].type;
      const periods = toPeriods(currentFinancialYear);

      dispatch({
        type: actionType,
        clientId: currentCustomer,
        financialYear: currentFinancialYear,
        periods,
        companyType,
        keep,
      });

      updateTaxView(
        currentCustomer,
        currentFinancialYear,
        dispatch,
        getState,
        undefined,
        period
      );

      storeTaxConfigFromState(getState, currentFinancialYear);
    }
  };

interface ResetDocumentPartAction extends ClientYearAction {
  type: typeof RESET_DOCUMENT_PART;
  id: string;
}

/**
 *
 * @param currentFinancialYear
 * @param period
 * @param id the id in the document to reset
 */
export const resetDocumentPart =
  (
    clientId: string,
    currentFinancialYear: string,
    period: string,
    id: string
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  (dispatch, getState) => {
    dispatch({
      type: RESET_DOCUMENT_PART,
      clientId,
      financialYear: currentFinancialYear,
      id,
    });

    updateTaxView(
      clientId,
      currentFinancialYear,
      dispatch,
      getState,
      undefined,
      period
    );

    saveTaxConfigFromState(getState, currentFinancialYear, clientId);
  };

interface ToggleTaxSectionActive extends ClientYearAction {
  type: typeof TOGGLE_TAX_SECTION_ACTIVE;
  id: string;
  part: string;
}

export const toggleTaxSectionActive =
  (
    clientId: string,
    currentFinancialYear: string,
    period: string,
    part: string,
    id: string
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  (dispatch, getState) => {
    dispatch({
      type: TOGGLE_TAX_SECTION_ACTIVE,
      clientId,
      financialYear: currentFinancialYear,
      part,
      id,
    });

    updateTaxView(
      clientId,
      currentFinancialYear,
      dispatch,
      getState,
      undefined,
      period
    );

    saveTaxConfigFromState(getState, currentFinancialYear, clientId);
  };

interface AddTaxSections extends ClientYearAction {
  type: typeof ADD_TAX_SECTIONS;
  sections: {
    [key: string]: {
      active: boolean;
      title: string;
      section: string;
    };
  };
}

export const addTaxSections =
  (
    clientId: string,
    currentFinancialYear: string,
    period: string,
    sections: {
      [key: string]: {
        [key: string]: {
          active?: boolean;
          title: string;
          section: string;
        };
      };
    }
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: ADD_TAX_SECTIONS,
      clientId,
      financialYear: currentFinancialYear,
      sections,
    });
  };

export const updateYearEndPlanningChecksum =
  (
    currentFinancialYear: string,
    period: string,
    checksum?: string
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  (dispatch, getState) => {
    const { currentCustomer } = getState().customerView;

    if (currentCustomer) {
      const view =
        getState().taxView.clients[currentCustomer].years[currentFinancialYear];

      const checksumVariable =
        checksum || view?.state?.yearEndPlanning?.checksum;

      if (view && currentCustomer && currentFinancialYear && checksumVariable) {
        const action: UpdateYearEndPlanningChecksum = {
          type: UPDATE_YEAR_END_PLANNING_CHECKSUM,
          checksum: checksumVariable,
          clientId: currentCustomer,
          financialYear: currentFinancialYear,
        };
        dispatch(action);

        updateTaxView(
          currentCustomer,
          currentFinancialYear,
          dispatch,
          getState,
          false,
          period
        );

        storeTaxConfigFromState(getState, currentFinancialYear);
      }
    }
  };

interface SetTaxDocuments extends ClientYearAction {
  type: typeof SET_TAX_DOCUMENTS;
  documents: TaxDocument[];
}

const setTaxDocumentsAction = (
  clientId: string,
  financialYear: string,
  documents: TaxDocument[]
): SetTaxDocuments => ({
  clientId,
  financialYear,
  type: SET_TAX_DOCUMENTS,
  documents,
});

const updateField = (
  clientId: string,
  financialYear: string,
  id: string,
  value: string | number | boolean | undefined
): UpdateTaxViewField => ({
  clientId,
  financialYear,
  type: UPDATE_TAX_VIEW_FIELD,
  id,
  value,
});

export const updateTaxViewDocumentField =
  (
    clientId: string,
    financialYear: string,
    id: string,
    value: string | number | boolean | undefined
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  (dispatch, getState) => {
    dispatch(updateField(clientId, financialYear, id, value));
    updateTaxView(clientId, financialYear, dispatch, getState);
    saveTaxConfigFromState(getState, financialYear, clientId);
  };

export const updateTaxExternalDocumentValues =
  (
    clientId: string,
    financialYear: string,
    name: string,
    values: Record<string, string | number | boolean | undefined>
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  (dispatch, getState) => {
    dispatch(
      setTaxExternalDocumentValues(clientId, financialYear, name, values)
    );
    updateTaxView(clientId, financialYear, dispatch, getState);
    saveTaxConfigFromState(getState, financialYear, clientId);
  };

const updateCell = (
  clientId: string,
  financialYear: string,
  id: string,
  value: string | number | boolean | undefined | Cell
): UpdateDocumentCell => ({
  clientId,
  financialYear,
  type: UPDATE_DOCUMENT_CELL,
  id,
  value,
});

interface UpdateDocumentCell extends ClientYearAction {
  type: typeof UPDATE_DOCUMENT_CELL;
  id: string;
  value: string | number | boolean | undefined | Cell;
}

export const updateDocumentCell =
  (
    clientId: string,
    financialYear: string,
    id: string,
    value: string | number | boolean | undefined | Cell
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  (dispatch, getState) => {
    dispatch(updateCell(clientId, financialYear, id, value));
    updateTaxView(clientId, financialYear, dispatch, getState);
    saveTaxConfigFromState(getState, financialYear, clientId);
  };

// AddDocumentRow
interface AddDocumentRow extends ClientYearAction {
  type: typeof ADD_DOCUMENT_ROW;
  rowId: string;
  lastModified: string;
}

const addDocumentRowAction = (
  clientId: string,
  financialYear: string,
  rowId: string
): AddDocumentRow => ({
  clientId,
  financialYear,
  type: ADD_DOCUMENT_ROW,
  rowId,
  lastModified: new Date().toISOString(),
});

export const addDocumentRow =
  (
    clientId: string,
    financialYear: string,
    rowId: string
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  (dispatch, getState) => {
    dispatch(addDocumentRowAction(clientId, financialYear, rowId));
    updateTaxView(clientId, financialYear, dispatch, getState);
    saveTaxConfigFromState(getState, financialYear, clientId);
  };

// DeleteDocumentRow
interface DeleteDocumentRow extends ClientYearAction {
  type: typeof DELETE_DOCUMENT_ROW;
  rowId: string;
  lastModified: string;
}

const deleteDocumentRowAction = (
  clientId: string,
  financialYear: string,
  rowId: string
): DeleteDocumentRow => ({
  clientId,
  financialYear,
  type: DELETE_DOCUMENT_ROW,
  rowId,
  lastModified: new Date().toISOString(),
});

export const deleteDocumentRow =
  (
    clientId: string,
    financialYear: string,
    rowId: string
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  (dispatch, getState) => {
    dispatch(deleteDocumentRowAction(clientId, financialYear, rowId));
    updateTaxView(clientId, financialYear, dispatch, getState);
    saveTaxConfigFromState(getState, financialYear, clientId);
  };
export const updateTaxDocumentLabel =
  (
    currentFinancialYear: string,
    period: string,
    value: string,
    label: string
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  (dispatch, getState) => {
    const { currentCustomer } = getState().customerView;

    if (currentCustomer) {
      const taxDocuments =
        getState().taxView.clients[currentCustomer].years[currentFinancialYear]
          .state?.taxDocuments || [];
      const documentIndex = taxDocuments.findIndex(
        (item) => item.value === value
      );

      if (taxDocuments && documentIndex !== -1) {
        const newTaxDocuments = [...taxDocuments];
        newTaxDocuments[documentIndex] = {
          ...newTaxDocuments[documentIndex],
          label,
        };

        dispatch(
          setTaxDocumentsAction(
            currentCustomer,
            currentFinancialYear,
            newTaxDocuments
          )
        );

        updateTaxView(
          currentCustomer,
          currentFinancialYear,
          dispatch,
          getState,
          false,
          period
        );

        storeTaxConfigFromState(getState, currentFinancialYear);
      }
    }
  };
export interface ResetNoneTaxableIncome extends ClientYearAction {
  type: typeof RESET_NONE_TAXABLE_INCOME;
}

interface ResetNoneDeductibleExpenses extends ClientYearAction {
  type: typeof RESET_NONE_DEDUCTIBLE_EXPENSES;
}

interface ResetParticularSalary extends ClientYearAction {
  type: typeof RESET_TAX_PARTICULAR_SALARY;
}

interface ResetViewParticularFORA extends ClientYearAction {
  type: typeof RESET_TAX_FORA;
}

interface ResetYearEndPlanning extends ClientYearAction {
  type: typeof RESET_YEAR_END_PLANNING;
  keep?: string[];
}

interface ResetTaxCalculationTable extends ClientYearAction {
  type: typeof RESET_TAX_CALCULATION_TABLE;
}

interface UpdateYearEndPlanningChecksum extends ClientYearAction {
  type: typeof UPDATE_YEAR_END_PLANNING_CHECKSUM;
  checksum: string;
}

interface ResetAdjustments extends ClientAdjustmentsAction {
  type: typeof RESET_TAX_CALCULATION_ADJUSTMENTS;
}

interface ResetTaxDocuments extends ClientYearAction {
  type: typeof RESET_TAX_DOCUMENTS;
}

interface SetTaxExternalDocumentValues extends ClientYearAction {
  type: typeof SET_TAX_EXTERNAL_DOCUMENT_VALUES;
  name: string;
  values: Record<string, string | boolean | number | undefined>;
}

interface UpdateTaxViewField extends ClientYearAction {
  type: typeof UPDATE_TAX_VIEW_FIELD;
  id: string;
  value: string | number | boolean | undefined;
}

export const setTaxExternalDocumentValues = (
  clientId: string,
  financialYear: string,
  name: string,
  values: Record<string, string | number | boolean | undefined>
): SetTaxExternalDocumentValues => ({
  type: SET_TAX_EXTERNAL_DOCUMENT_VALUES,
  clientId,
  financialYear,
  name,
  values,
});

export type TaxViewActionTypes =
  | SetTaxViewConfig
  | SetTaxViewState
  | AddEmptyTaxTableRow
  | AddEmptyTaxSummaryTableRow
  | DeleteTaxTableRow
  | DeleteTaxTableRow
  | UpdateTaxTableRowLabel
  | UpdateTaxTableRowValue
  | UpdateTaxSummaryTableRowValue
  | UpdateTaxTableRowReference
  | UpdateTransactionRowAccount
  | UpdateTaxTableRowReference
  | UpdateTransactionRowAccount
  | RemoveUserTransaction
  | ResetYearEndPlanning
  | ResetTaxCalculationTable
  | ResetNoneTaxableIncome
  | ResetNoneDeductibleExpenses
  | ResetParticularSalary
  | ResetAdjustments
  | ResetDocumentPartAction
  | UpdateYearEndPlanningChecksum
  | SetTaxDocuments
  | ResetTaxDocuments
  | SetTaxExternalDocumentValues
  | AddEmptyContractualPensionTableRow
  | DeleteContractualPensionTableRow
  | UpdateContractualPensionTableRowLabel
  | UpdateContractualPensionTableRowValue
  | UpdateContractualPensionTableRowReference
  | UpdateForaField
  | ResetViewParticularFORA
  | UpdateTaxViewField
  | UpdateDocumentCell
  | AddDocumentRow
  | DeleteDocumentRow
  | ToggleTaxSectionActive
  | AddTaxSections;

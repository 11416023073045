import React from 'react';
import { useIntl } from 'react-intl';
import {
  SharesCompanyManagementReport,
  ProfitProposalK3 as ProfitProposalK3Type,
} from '@agoy/annual-report-document';
import { stringValue } from '@agoy/document';
import Typography from '../../../UI/Typography/Typography';
import MultilineField from '../UI/MultilineField';
import { Heading, Section } from '../../../UI';
import { Table } from '../../../UI/CommonPrintTable';

interface ProfitProposalProps {
  managementReport: SharesCompanyManagementReport;
  reportPart: ProfitProposalK3Type;
}

const ProfitProposalK3 = ({
  managementReport,
  reportPart,
}: ProfitProposalProps) => {
  const { formatMessage } = useIntl();

  const title =
    stringValue(managementReport.profitProposalK3.title) ||
    formatMessage({
      id: 'annualReport.menu.managementReport.profitProposalK3',
    });

  /* Needed for K3 in later story
  const annualGeneralMeetingDateField: IxbrlField = {
    ...annualGeneralMeeting.main.date,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    ixbrl: { ...reportPart.paymentDateType.ixbrl! },
  };
  */

  const { table, comment, statement } = reportPart;
  return (
    <Section>
      <Heading title={title} />
      <Table
        baseId="annualReport"
        tableId="managementReport.profitProposalK3.table"
        columns={table.columns}
        rows={table.rows}
      />
      {comment.value && (
        <MultilineField
          header={
            <Typography variant="h4">
              {formatMessage({
                id: 'annualReport.menu.managementReport.profitProposalK3.commentLabel',
              })}
            </Typography>
          }
          field={comment}
        />
      )}
      <MultilineField
        header={
          <Typography variant="h4">
            {formatMessage({
              id: 'annualReport.managementReport.profitProposal.boardStatement',
            })}
          </Typography>
        }
        field={statement}
      />
    </Section>
  );
};

export default ProfitProposalK3;

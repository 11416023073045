import { useContext, useEffect, useMemo } from 'react';
import { ClientDataLayerContext } from './ClientDataLayerContext';
import { ClientDataLayer } from './types';

// Used to avoid a typing issue
const DUMMY = {} as ClientDataLayer;

function useClientDataLayer(clientId: undefined): undefined;
function useClientDataLayer(clientId: string): ClientDataLayer;
function useClientDataLayer(
  clientId: string | undefined
): ClientDataLayer | undefined;
function useClientDataLayer(
  clientId: string | undefined
): ClientDataLayer | undefined {
  const context = useContext(ClientDataLayerContext);

  const clientDataLayer = useMemo(
    () => (typeof clientId === 'string' ? context.get(clientId) : DUMMY),
    [clientId, context]
  );

  useEffect(() => {
    return () => {
      if (clientDataLayer !== DUMMY) {
        context.release(clientDataLayer);
      }
    };
  }, [clientDataLayer, context]);

  return typeof clientId === 'string' ? clientDataLayer : undefined;
}

export default useClientDataLayer;

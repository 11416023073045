import React from 'react';
import styled from '@emotion/styled';
import Filter, { Option } from './Filter';

const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: ${({ theme }) => theme.spacing(1)}px;
  gap: ${({ theme }) => theme.spacing(1)}px;
  background-color: #fff;
  box-shadow: ${({ theme }) => theme.shadows[2]};
`;

const Groups = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  width: 100%;
  gap: ${({ theme }) => theme.spacing(1)}px;
`;

const FilterGroup = styled.div`
  border-radius: ${({ theme }) => theme.spacing(0.5)}px;
  padding: ${({ theme }) => theme.spacing(1)}px;
  background-color: ${({ theme }) => theme.palette.grey[100]};
`;

const Label = styled.div`
  font-weight: 400;
  text-transform: uppercase;
  color: ${({ theme }) => theme.palette.grey[400]};
  margin-bottom: ${({ theme }) => theme.spacing(1.5)}px;
`;

const ResetFilters = styled.span`
  width: fit-content;
  align-self: flex-end;
  cursor: pointer;
  font-weight: 400;

  color: ${({ theme }) => theme.palette.grey[600]};

  :hover {
    color: ${({ theme }) => theme.palette.grey[800]};
  }
`;

type OptionGroup = {
  id: string;
  name: string;
  options: Option[];
};

interface FilterProps {
  optionGroups: OptionGroup[];
  onSelect: (groupdId: string, value: string) => void;
  onDeselect: (groupdId: string, value: string) => void;
  onEraseFilters: () => void;
}

/**
 * Renders a filter window with filters divided in groups.
 *
 * @param optionGroups   available group options (each group contains groupId, label, and options)
 * @param onSelect       callback for selecting a filter
 * @param onDeselect     callback for deselecting a filter
 * @param onEraseFilters callback to deselect all filters
 *
 * @returns a component with grouped filters
 */
const FilterGroups = ({
  optionGroups,
  onSelect,
  onDeselect,
  onEraseFilters,
}: FilterProps) => {
  return (
    <FilterContainer>
      <Groups>
        {optionGroups.map((group) => (
          <FilterGroup key={`filter-group-${group.id}`}>
            <Label>{group.name}</Label>
            <Filter
              options={group.options}
              onSelect={(value) => onSelect(group.id, value)}
              onDeselect={(value) => onDeselect(group.id, value)}
            />
          </FilterGroup>
        ))}
      </Groups>
      <ResetFilters onClick={onEraseFilters}>Rensa filter</ResetFilters>
    </FilterContainer>
  );
};

export default FilterGroups;

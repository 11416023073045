/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState, useContext } from 'react';
import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { SaldoHistoryContext } from '_reconciliation/components/ReconciliationView/HiddenRow/HiddenRowHistoryProvider';
import {
  getFormattedPeriod,
  getNextLegacySpecificationId,
} from '_reconciliation/components/ReconciliationView/HiddenRow/HiddenGroupRow/utils';

import { Period } from '@agoy/api-sdk-core';
import { SkatteverketTransaction } from '_clients/redux/customer-view/types';
import { PAGE_SIZE } from 'utils/SieParser/transactionUtils';
import Paginator from '_shared/components/Paginator';
import LoadingPlaceholder from '_shared/components/LoadingPlaceholder';

import { currentClientYear } from '_reconciliation/redux/accounting-view/selectors';
import { useSelector } from 'redux/reducers';

import TableHeader from './TableHeader';
import TableRow from './TableRow';
import { getIngoingDate, getOutgoingDate } from '../utils/utils';
import filterTransactions from '../utils/filters';

const PaginatorWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: ${(props) => props.theme.spacing(2)}px auto 0;
`;

export type TableFilter = {
  pageNumber: 1;
  periods: { [key: string]: boolean };
};

type Props = {
  transactions: SkatteverketTransaction[];
  filterBy: TableFilter;
  totalResults: number;
  accountNumber: string;
  currentPeriod: Period;
  isLoading: boolean;
  isPrintPreviewMode?: boolean;
};

const Table = ({
  transactions,
  filterBy,
  totalResults,
  accountNumber,
  currentPeriod,
  isLoading,
  isPrintPreviewMode,
}: Props): JSX.Element => {
  const { formatMessage } = useIntl();

  const { onUserInputChange } = useContext(SaldoHistoryContext);

  const [transactionsPerAccount, setTransactionsPerAccount] = useState<
    SkatteverketTransaction[]
  >([]);
  const [paginatedTransactions, setPaginatedTransactions] = useState<
    SkatteverketTransaction[]
  >([]);
  const [pageNumber, setPageNumber] = useState<number>(1);

  const formattedPeriod = getFormattedPeriod(currentPeriod);
  const existingSpecification =
    useSelector(
      currentClientYear(
        (state) =>
          state.userInput?.[`account${accountNumber}`]?.[formattedPeriod]
            ?.legacySpecifications
      )
    ) || ([] as Client.LegacySpecification[]);

  const { currentCustomer, currentFinancialYear } = useSelector(
    (state) => state.customerView
  );

  const handleOnTransactionTransfer = (pageIndex: number) => {
    if (!currentCustomer || !currentFinancialYear) {
      return;
    }

    const index = pageIndex + (pageNumber - 1) * PAGE_SIZE;
    const { amount, specification } = transactionsPerAccount[index];

    const updatedSpecification = [
      ...existingSpecification,
      {
        account: accountNumber,
        amount: amount.toFixed(2),
        description: specification,
        id: getNextLegacySpecificationId(existingSpecification),
        reference: `Skatteverket`,
      },
    ];

    const newUserInput = {
      legacySpecifications: updatedSpecification,
    };

    onUserInputChange(newUserInput, accountNumber, currentPeriod);
  };

  const IngoingSaldoRow = React.memo(() => {
    const isFirstPage = pageNumber === 1;
    const ingoingSaldoDate = getIngoingDate(transactions[0]?.transactionDate);
    const ingoingSaldo = transactions[0]?.saldo - transactions[0]?.amount;

    const ingoingTransaction: SkatteverketTransaction = {
      transactionDate: '',
      specification: `Ingående saldo ${ingoingSaldoDate}`,
      amount: 0,
      saldo: ingoingSaldo || 0,
    };

    if (!isFirstPage || transactionsPerAccount.length === 0) {
      return null;
    }

    return (
      <TableRow
        showActionButton={!isPrintPreviewMode}
        transaction={ingoingTransaction}
        isSaldoInformation
      />
    );
  });

  const OutgoingSaldoRow = React.memo(() => {
    const isLastPage = pageNumber === Math.ceil(totalResults / PAGE_SIZE);
    const lastTransaction = transactions[transactions.length - 1];
    const outgoingSaldoDate = getOutgoingDate(
      lastTransaction?.transactionDate,
      filterBy.periods
    );

    const outgoingTransaction: SkatteverketTransaction = {
      transactionDate: '',
      specification: `Utgående saldo ${outgoingSaldoDate}`,
      amount: 0,
      saldo: lastTransaction?.saldo || 0,
    };

    if (!isLastPage && !isPrintPreviewMode) {
      return null;
    }

    return (
      <TableRow
        showActionButton={!isPrintPreviewMode}
        transaction={outgoingTransaction}
        isSaldoInformation
      />
    );
  });

  const handlePaginationChange = (newPageNumber: number) => {
    const offset = (newPageNumber - 1) * PAGE_SIZE;
    const limit = offset + PAGE_SIZE;
    const rowsOnPage = transactionsPerAccount.slice(offset, limit);
    setPaginatedTransactions(rowsOnPage);
    setPageNumber(newPageNumber);
  };

  useEffect(() => {
    const filteredRows = filterTransactions(
      transactions,
      Number(accountNumber)
    );

    if (isPrintPreviewMode) {
      // if it is in print preview mode we should
      // print all of the transactions at once
      setPaginatedTransactions(transactions);
      setTransactionsPerAccount(filteredRows);
      return;
    }

    let offset = (pageNumber - 1) * PAGE_SIZE;
    let limit = offset + PAGE_SIZE;
    let transactionsOnPage = filteredRows.slice(offset, limit);

    if (transactionsOnPage.length === 0 && pageNumber > 1) {
      offset = (pageNumber - 2) * PAGE_SIZE;
      limit = offset + PAGE_SIZE;
      transactionsOnPage = filteredRows.slice(offset, limit);
      setPageNumber(pageNumber - 1);
    }

    setPaginatedTransactions(transactionsOnPage);
    setTransactionsPerAccount(filteredRows);
  }, [accountNumber, isPrintPreviewMode, pageNumber, transactions]);

  if (isLoading) {
    return (
      <>
        <TableHeader showActionButton={!isPrintPreviewMode} />
        <LoadingPlaceholder />
      </>
    );
  }

  if (!totalResults || totalResults === 0) {
    return (
      <Typography variant="body1" color="textSecondary">
        {formatMessage({ id: 'hidden.skatteverket.emptyTransactions' })}
      </Typography>
    );
  }

  return (
    <div>
      <TableHeader showActionButton={!isPrintPreviewMode} />
      <IngoingSaldoRow />
      {paginatedTransactions.map((t, index) => (
        <TableRow
          key={index}
          showActionButton={!isPrintPreviewMode}
          transaction={t}
          onActionClicked={() => handleOnTransactionTransfer(index)}
        />
      ))}
      <OutgoingSaldoRow />
      {!isPrintPreviewMode && transactionsPerAccount.length > 0 && (
        <PaginatorWrapper>
          <Paginator
            pageNumber={pageNumber}
            totalElementCount={transactionsPerAccount.length}
            handlePaginationChange={handlePaginationChange}
            elementsPerPage={PAGE_SIZE}
          />
        </PaginatorWrapper>
      )}
    </div>
  );
};

export default Table;

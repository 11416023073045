import { ToggleFinancialReportSectionActive } from '../../action-type-declarations';
import { FinancialReportClientPeriodState } from '../types';

const toggleFinancialReportSectionActive = (
  state: FinancialReportClientPeriodState,
  action: ToggleFinancialReportSectionActive
) => {
  const { part } = action;

  if (!state.report) {
    return state;
  }

  return {
    ...state,
    report: {
      ...state.report,
      [part]: {
        ...state.report[part],
        section: {
          ...state.report[part].section,
          active: !state.report[part].section.active,
        },
      },
    },

    changes: {
      ...state.changes,
      [part]: {
        ...state.changes[part],
        section: {
          ...state.changes[part]?.section,
          active: !state.report[part].section.active,
        },
      },
    },
  };
};

export default toggleFinancialReportSectionActive;

import { value, table, ref, or, sum, sumAllowEmpty, id } from '@agoy/document';
import { IxbrlCell, Note74Section } from '../../../../common';
import {
  field,
  ixbrlCell,
  ixbrlField,
  tupleRef,
} from '../../../../common/utils/util';
import { previousYear, year } from '../../../../common/utils/date-util';
import { sumNotesRowsById } from '../../../../common/utils/accountSumsUtil';

const MONETARY_ITEM_TYPE = 'monetaryItemType';

const getNote74 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null
): Note74Section => {
  return {
    active: false,
    number: value(undefined),
    name: value('Personal'),
    noEmployeesTable: table<IxbrlCell>(
      'notes.note74.noEmployeesTable',
      { id: 'label', label: 'Personal' },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      .addRows((rows) =>
        rows
          .addRow(
            'female',
            noteLabel('Kvinnor'),
            ixbrlCell(value(undefined), {
              type: 'decimalItemType',
              unitRef: 'antal-anstallda',
              format: 'ixt:numspacecomma',
              name: 'se-gen-base:MedelantaletAnstalldaKvinnor',
              contextRef: 'period0',
              standardRubrik: 'Medelantalet anställda kvinnor',
            }),
            ixbrlCell(value(undefined), {
              type: 'decimalItemType',
              unitRef: 'antal-anstallda',
              format: 'ixt:numspacecomma',
              name: 'se-gen-base:MedelantaletAnstalldaKvinnor',
              contextRef: 'period1',
              standardRubrik: 'Medelantalet anställda kvinnor',
            })
          )
          .addRow(
            'femalePercent',
            noteLabel('Kvinnor (%)'),
            ixbrlCell(value(undefined), {
              type: 'pureItemType',
              unitRef: 'procent',
              name: 'se-gen-base:MedelantaletAnstalldaAndelKvinnor',
              contextRef: 'period0',
              standardRubrik: 'Andel anställda kvinnor',
            }),
            ixbrlCell(value(undefined), {
              type: 'pureItemType',
              unitRef: 'procent',
              name: 'se-gen-base:MedelantaletAnstalldaAndelKvinnor',
              contextRef: 'period1',
              standardRubrik: 'Andel anställda kvinnor',
            })
          )
          .addRow(
            'male',
            noteLabel('Män'),
            ixbrlCell(value(undefined), {
              type: 'decimalItemType',
              unitRef: 'antal-anstallda',
              format: 'ixt:numspacecomma',
              name: 'se-gen-base:MedelantaletAnstalldaMan',
              contextRef: 'period0',
              standardRubrik: 'Medelantalet anställda män',
            }),
            ixbrlCell(value(undefined), {
              type: 'decimalItemType',
              unitRef: 'antal-anstallda',
              format: 'ixt:numspacecomma',
              name: 'se-gen-base:MedelantaletAnstalldaMan',
              contextRef: 'period1',
              standardRubrik: 'Medelantalet anställda män',
            })
          )
          .addRow(
            'malePercent',
            noteLabel('Män (%)'),
            ixbrlCell(value(undefined), {
              type: 'pureItemType',
              unitRef: 'procent',
              name: 'se-gen-base:MedelantaletAnstalldaAndelMan',
              contextRef: 'period0',
              standardRubrik: 'Andel anställda män',
            }),
            ixbrlCell(value(undefined), {
              type: 'pureItemType',
              unitRef: 'procent',
              name: 'se-gen-base:MedelantaletAnstalldaAndelMan',
              contextRef: 'period1',
              standardRubrik: 'Andel anställda män',
            })
          )
          .addRow(
            'averageNoOfEmployees',
            noteLabel('Medelantal anställda'),
            ixbrlCell(value(undefined), {
              type: 'decimalItemType',
              unitRef: 'antal-anstallda',
              format: 'ixt:numspacecomma',
              name: 'se-gen-base:MedelantaletAnstallda',
              contextRef: 'period0',
              standardRubrik: 'Medelantalet anställda',
            }),
            ixbrlCell(value(undefined), {
              type: 'decimalItemType',
              unitRef: 'antal-anstallda',
              format: 'ixt:numspacecomma',
              name: 'se-gen-base:MedelantaletAnstallda',
              contextRef: 'period1',
              standardRubrik: 'Medelantalet anställda',
            })
          )
          .build()
      )
      .newRowTemplate(value(''), value(undefined), value(undefined))
      .build(),
    noEmployeesTableCommentLabel: field('Kommentar'),
    noEmployeesTableComment: ixbrlField(field(''), {
      type: 'stringItemType',
      name: 'se-gen-base:MedelantaletAnstalldaKommentar',
      contextRef: 'period0',
      standardRubrik: 'Kommentar till medelantalet anställda',
    }),
    // TABLE 2
    noEmployeesPerCountryTable: table<IxbrlCell>(
      'notes.note74.noEmployeesPerCountryTable',
      { id: 'land', label: 'Land' },
      {
        id: 'female',
        label: 'Kvinnor',
      },
      {
        id: 'femalePercent',
        label: 'Kvinnor (%)',
      },
      {
        id: 'man',
        label: 'Män',
      },
      {
        id: 'manPercent',
        label: 'Män (%)',
      },
      {
        id: 'averageNoOfEmployees',
        label: 'Medelantalet anställda',
      }
    )
      .addRows((rows) =>
        rows
          .addRow(
            'noOfEmployees',
            ixbrlCell(value(''), {
              type: 'stringItemType',
              name: 'se-gen-base:LanderfordelningMedelantaletAnstalldaLand',
              contextRef: 'period0',
              standardRubrik:
                'Land där anställda utför huvudsakliga arbetsuppgifter',
              tuple: {
                name: 'se-gaap-ext:LanderfordelningMedelantaletAnstalldaTuple',
                id: `${rows.getBaseId()}.noOfEmployees.tuple`,
                tupleID: `${rows.getBaseId()}.noOfEmployees.tuple`,
              },
              tupleRef: tupleRef(1, `${rows.getBaseId()}.noOfEmployees.tuple`),
            }),
            ixbrlCell(value(undefined), {
              type: 'decimalItemType',
              unitRef: 'antal-anstallda',
              format: 'ixt:numspacecomma',
              name: 'se-gen-base:LanderfordelningMedelantaletAnstalldaKvinnor',
              contextRef: 'period0',
              tupleRef: tupleRef(2, `${rows.getBaseId()}.noOfEmployees.tuple`),
            }),
            ixbrlCell(value(undefined), {
              type: 'pureItemType',
              name: 'se-gen-base:LanderfordelningMedelantaletAnstalldaAndelKvinnor',
              contextRef: 'period0',
              tupleRef: tupleRef(3, `${rows.getBaseId()}.noOfEmployees.tuple`),
            }),
            ixbrlCell(value(undefined), {
              type: 'decimalItemType',
              unitRef: 'antal-anstallda',
              format: 'ixt:numspacecomma',
              name: 'se-gen-base:LanderfordelningMedelantaletAnstalldaMan',
              contextRef: 'period0',
              tupleRef: tupleRef(4, `${rows.getBaseId()}.noOfEmployees.tuple`),
            }),
            ixbrlCell(value(undefined), {
              type: 'pureItemType',
              name: 'se-gen-base:LanderfordelningMedelantaletAnstalldaAndelMan',
              contextRef: 'period0',
              tupleRef: tupleRef(5, `${rows.getBaseId()}.noOfEmployees.tuple`),
            }),
            ixbrlCell(value(undefined), {
              type: 'decimalItemType',
              unitRef: 'antal-anstallda',
              format: 'ixt:numspacecomma',
              name: 'se-gen-base:LanderfordelningMedelantaletAnstallda',
              contextRef: 'period0',
              tupleRef: tupleRef(6, `${rows.getBaseId()}.noOfEmployees.tuple`),
            })
          )
          .build()
      )
      .newRowTemplateGenerator((id, baseId) => ({
        id,
        active: true,
        cells: {
          land: ixbrlCell(value(''), {
            type: 'stringItemType',
            name: 'se-gen-base:LanderfordelningMedelantaletAnstalldaLand',
            contextRef: 'period0',
            standardRubrik:
              'Land där anställda utför huvudsakliga arbetsuppgifter',
            tuple: {
              name: 'se-gaap-ext:LanderfordelningMedelantaletAnstalldaTuple',
              id: `${baseId}.${id}.tuple`,
              tupleID: `${baseId}.${id}.tuple`,
            },
            tupleRef: tupleRef(1, `${baseId}.${id}.tuple`),
          }),
          female: ixbrlCell(value(undefined), {
            type: 'decimalItemType',
            unitRef: 'antal-anstallda',
            format: 'ixt:numspacecomma',
            name: 'se-gen-base:LanderfordelningMedelantaletAnstalldaKvinnor',
            contextRef: 'period0',
            tupleRef: tupleRef(2, `${baseId}.${id}.tuple`),
          }),
          femalePercent: ixbrlCell(value(undefined), {
            type: 'pureItemType',
            name: 'se-gen-base:LanderfordelningMedelantaletAnstalldaAndelKvinnor',
            contextRef: 'period0',
            tupleRef: tupleRef(3, `${baseId}.${id}.tuple`),
          }),
          man: ixbrlCell(value(undefined), {
            type: 'decimalItemType',
            unitRef: 'antal-anstallda',
            format: 'ixt:numspacecomma',
            name: 'se-gen-base:LanderfordelningMedelantaletAnstalldaMan',
            contextRef: 'period0',
            tupleRef: tupleRef(4, `${baseId}.${id}.tuple`),
          }),
          manPercent: ixbrlCell(value(undefined), {
            type: 'pureItemType',
            name: 'se-gen-base:LanderfordelningMedelantaletAnstalldaAndelMan',
            contextRef: 'period0',
            tupleRef: tupleRef(5, `${baseId}.${id}.tuple`),
          }),
          averageNoOfEmployees: ixbrlCell(value(undefined), {
            type: 'decimalItemType',
            unitRef: 'antal-anstallda',
            format: 'ixt:numspacecomma',
            name: 'se-gen-base:LanderfordelningMedelantaletAnstallda',
            contextRef: 'period0',
            tupleRef: tupleRef(6, `${baseId}.${id}.tuple`),
          }),
        },
      }))
      .build(),
    noEmployeesPerCountryTableCommentLabel: field('Kommentar'),
    noEmployeesPerCountryTableComment: ixbrlField(field(''), {
      type: 'stringItemType',
      name: 'se-gen-base:LanderfordelningAnstalldaKommentar',
      contextRef: 'period0',
      standardRubrik: 'Kommentar till medelantalet anställda per land',
    }),
    // TABLE 3
    noOfBoardMembersAndExecutivesTable: table<IxbrlCell>(
      'notes.note74.noOfBoardMembersAndExecutivesTable',
      { id: 'label', label: 'Styrelseledamöter och ledande befattningshavare' },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      .addRows((rows) =>
        rows
          .addRow(
            'female',
            noteLabel('Kvinnor'),
            ixbrlCell(value(undefined), {
              type: 'decimalItemType',
              unitRef: 'antal-anstallda',
              format: 'ixt:numspacecomma',
              name: 'se-gen-base:FordelningStyrelseledamoterKvinnor',
              contextRef: 'balans0',
              standardRubrik: 'Antal kvinnor som är styrelseledamöter',
            }),
            ixbrlCell(value(undefined), {
              type: 'decimalItemType',
              unitRef: 'antal-anstallda',
              format: 'ixt:numspacecomma',
              name: 'se-gen-base:FordelningStyrelseledamoterKvinnor',
              contextRef: 'balans1',
              standardRubrik: 'Antal kvinnor som är styrelseledamöter',
            })
          )
          .addRow(
            'femalePercent',
            noteLabel('Kvinnor (%)'),
            ixbrlCell(value(undefined), {
              type: 'pureItemType',
              unitRef: 'percent',
              name: 'se-gen-base:FordelningStyrelseledamoterAndelKvinnor',
              contextRef: 'balans0',
              standardRubrik: 'Andel kvinnor som är i styrlesen',
            }),
            ixbrlCell(value(undefined), {
              type: 'pureItemType',
              unitRef: 'percent',
              name: 'se-gen-base:FordelningStyrelseledamoterAndelKvinnor',
              contextRef: 'balans1',
              standardRubrik: 'Andel kvinnor som är i styrlesen',
            })
          )
          .addRow(
            'male',
            noteLabel('Män'),
            ixbrlCell(value(undefined), {
              type: 'decimalItemType',
              unitRef: 'antal-anstallda',
              format: 'ixt:numspacecomma',
              name: 'se-gen-base:FordelningStyrelseledamoterMan',
              contextRef: 'balans0',
              standardRubrik: 'Antal män som är styrelseledamöter',
            }),
            ixbrlCell(value(undefined), {
              type: 'decimalItemType',
              unitRef: 'antal-anstallda',
              format: 'ixt:numspacecomma',
              name: 'se-gen-base:FordelningStyrelseledamoterMan',
              contextRef: 'balans1',
              standardRubrik: 'Antal män som är styrelseledamöter',
            })
          )
          .addRow(
            'malePercent',
            noteLabel('Män (%)'),
            ixbrlCell(value(undefined), {
              type: 'pureItemType',
              unitRef: 'percent',
              name: 'se-gen-base:FordelningStyrelseledamoterAndelMan',
              contextRef: 'balans0',
              standardRubrik: 'Andel män som är i styrelsen',
            }),
            ixbrlCell(value(undefined), {
              type: 'pureItemType',
              unitRef: 'percent',
              name: 'se-gen-base:FordelningStyrelseledamoterAndelMan',
              contextRef: 'balans1',
              standardRubrik: 'Andel män som är i styrelsen',
            })
          )
          .addRow(
            'noOfBoardMembers',
            noteLabel('Antal styrelseledamöter'),
            ixbrlCell(sumNotesRowsById(74, ['female', 'male'], 'year'), {
              type: 'decimalItemType',
              unitRef: 'antal-anstallda',
              format: 'ixt:numspacecomma',
              name: 'se-gen-base:AntalStyrelseledamoter',
              contextRef: 'balans0',
              standardRubrik: 'Antal styrelseledamöter',
            }),
            ixbrlCell(
              sumNotesRowsById(74, ['female', 'male'], 'previousYear'),
              {
                type: 'decimalItemType',
                unitRef: 'antal-anstallda',
                format: 'ixt:numspacecomma',
                name: 'se-gen-base:AntalStyrelseledamoter',
                contextRef: 'balans1',
                standardRubrik: 'Antal styrelseledamöter',
              }
            )
          )
          .build()
      )
      .build(),
    // TABLE 4
    noOfCEOAndOtherExecutivesTable: table<IxbrlCell>(
      'notes.note74.noOfCEOAndOtherExecutivesTable',
      { id: 'label', label: 'VD och övriga ledande befattningshavare' },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      .addRows((rows) =>
        rows
          .addRow(
            'female',
            noteLabel('Kvinnor'),
            ixbrlCell(value(undefined), {
              type: 'decimalItemType',
              unitRef: 'antal-anstallda',
              format: 'ixt:numspacecomma',
              name: 'se-gen-base:FordelningVerkstallandeDirektorOvrigaLedandeBefattningshavareKvinnor',
              contextRef: 'balans0',
              standardRubrik: 'Antal kvinnor bland ledande befattningshavare',
            }),
            ixbrlCell(value(undefined), {
              type: 'decimalItemType',
              unitRef: 'antal-anstallda',
              format: 'ixt:numspacecomma',
              name: 'se-gen-base:FordelningVerkstallandeDirektorOvrigaLedandeBefattningshavareKvinnor',
              contextRef: 'balans1',
              standardRubrik: 'Antal kvinnor bland ledande befattningshavare',
            })
          )
          .addRow(
            'femalePercent',
            noteLabel('Kvinnor (%)'),
            ixbrlCell(value(undefined), {
              type: 'pureItemType',
              unitRef: 'percent',
              name: 'se-gen-base:FordelningVerkstallandeDirektorOvrigaLedandeBefattningshavareAndelKvinnor',
              contextRef: 'balans0',
              standardRubrik: 'Andel kvinnor bland ledande befattningshavare',
            }),
            ixbrlCell(value(undefined), {
              type: 'pureItemType',
              unitRef: 'percent',
              name: 'se-gen-base:FordelningVerkstallandeDirektorOvrigaLedandeBefattningshavareAndelKvinnor',
              contextRef: 'balans1',
              standardRubrik: 'Andel kvinnor bland ledande befattningshavare',
            })
          )
          .addRow(
            'male',
            noteLabel('Män'),
            ixbrlCell(value(undefined), {
              type: 'decimalItemType',
              unitRef: 'antal-anstallda',
              format: 'ixt:numspacecomma',
              name: 'se-gen-base:FordelningVerkstallandeDirektorOvrigaLedandeBefattningshavareMan',
              contextRef: 'balans0',
              standardRubrik: 'Antal män bland ledande befattningshavare',
            }),
            ixbrlCell(value(undefined), {
              type: 'decimalItemType',
              unitRef: 'antal-anstallda',
              format: 'ixt:numspacecomma',
              name: 'se-gen-base:FordelningVerkstallandeDirektorOvrigaLedandeBefattningshavareMan',
              contextRef: 'balans1',
              standardRubrik: 'Antal män bland ledande befattningshavare',
            })
          )
          .addRow(
            'malePercent',
            noteLabel('Män (%)'),
            ixbrlCell(value(undefined), {
              type: 'pureItemType',
              unitRef: 'percent',
              name: 'se-gen-base:FordelningVerkstallandeDirektorOvrigaLedandeBefattningshavareAndelMan',
              contextRef: 'balans0',
              standardRubrik: 'Andel män bland ledande befattningshavare',
            }),
            ixbrlCell(value(undefined), {
              type: 'pureItemType',
              unitRef: 'percent',
              name: 'se-gen-base:FordelningVerkstallandeDirektorOvrigaLedandeBefattningshavareAndelMan',
              contextRef: 'balans1',
              standardRubrik: 'Andel män bland ledande befattningshavare',
            })
          )
          .addRow(
            'noOfSeniorExecutives',
            noteLabel('Antal ledande befattningshavare'),
            ixbrlCell(sumNotesRowsById(74, ['female', 'male'], 'year'), {
              type: 'decimalItemType',
              unitRef: 'antal-anstallda',
              format: 'ixt:numspacecomma',
              name: 'se-gen-base:AntalLedandeBefattningshavare',
              contextRef: 'balans0',
              standardRubrik: 'Antal ledande befattningshavare',
            }),
            ixbrlCell(
              sumNotesRowsById(74, ['female', 'male'], 'previousYear'),
              {
                type: 'decimalItemType',
                unitRef: 'antal-anstallda',
                format: 'ixt:numspacecomma',
                name: 'se-gen-base:AntalLedandeBefattningshavare',
                contextRef: 'balans1',
                standardRubrik: 'Antal ledande befattningshavare',
              }
            )
          )
          .build()
      )
      .build(),
    noOfCEOAndOtherExecutivesTableCommentLabel: field('Kommentar'),
    noOfCEOAndOtherExecutivesTableComment: ixbrlField(field(''), {
      type: 'stringItemType',
      name: 'se-gen-base:FordelningStyrelseLedandeBefattningshavareKommentar',
      contextRef: 'period0',
      standardRubrik: 'Kommentar till könsfördelning i styrelse och ledning',
    }),
    // TABLE 5
    salariesAndBenefitsTable: table<IxbrlCell>(
      'notes.note74.salariesAndBenefitsTable',
      { id: 'label', label: 'Löner och andra ersättningar' },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      .addRows((rows) =>
        rows
          .addRow(
            'boardAndManagingDirector',
            noteLabel('Styrelsen och verkställande direktören'),
            ixbrlCell(value(undefined), {
              type: 'monetaryItemType',
              name: 'se-gen-base:LonerAndraErsattningarStyrelseledamoterVerkstallandeDirektorMotsvarandeBefattningshavare',
              contextRef: 'period0',
              saldo: 'debit',
              negateValue: true,
              standardRubrik:
                'Skattepliktiga ersättningar till nuvarande samt tidigare styrelse och verkställande direktör, inkl. styrelsesuppleanter och vice verkställande direktör och som har redovisats som kostnad under perioden samt årets förändring av semesterlöneskuld och andra lönerelaterade reserveringar, inkl. ev. aktiverade utgifter för löner och andra ersättningar. I publika aktiebolag ingår löner och ersättningar till samtliga personer i bolagets ledning',
            }),
            ixbrlCell(value(undefined), {
              type: 'monetaryItemType',
              name: 'se-gen-base:LonerAndraErsattningarStyrelseledamoterVerkstallandeDirektorMotsvarandeBefattningshavare',
              contextRef: 'period1',
              saldo: 'debit',
              negateValue: true,
              standardRubrik:
                'Skattepliktiga ersättningar till nuvarande samt tidigare styrelse och verkställande direktör, inkl. styrelsesuppleanter och vice verkställande direktör och som har redovisats som kostnad under perioden samt årets förändring av semesterlöneskuld och andra lönerelaterade reserveringar, inkl. ev. aktiverade utgifter för löner och andra ersättningar. I publika aktiebolag ingår löner och ersättningar till samtliga personer i bolagets ledning',
            })
          )
          .addRow(
            'royaltiesAndOtherRemunerations',
            noteLabel('Varav tantiem och liknande'),
            ixbrlCell(value(undefined), {
              type: 'monetaryItemType',
              name: 'se-gen-base:LonerAndraErsattningarStyrelseledamoterVerkstallandeDirektorMotsvarandeBefattningshavareVaravTantiemLiknande',
              contextRef: 'period0',
              saldo: 'debit',
              negateValue: true,
              standardRubrik:
                'Tantiem och liknande ersättningar, del av löner och andra ersättningar till styrelseledamöter, verkställande direktör och motsvarande befattningshavare',
            }),
            ixbrlCell(value(undefined), {
              type: 'monetaryItemType',
              name: 'se-gen-base:LonerAndraErsattningarStyrelseledamoterVerkstallandeDirektorMotsvarandeBefattningshavareVaravTantiemLiknande',
              contextRef: 'period1',
              saldo: 'debit',
              negateValue: true,
              standardRubrik:
                'Tantiem och liknande ersättningar, del av löner och andra ersättningar till styrelseledamöter, verkställande direktör och motsvarande befattningshavare',
            })
          )
          .addRow(
            'otherEmployees',
            noteLabel('Övriga anställda'),
            ixbrlCell(value(undefined), {
              type: 'monetaryItemType',
              name: 'se-gen-base:LonerAndraErsattningarOvrigaAnstallda',
              contextRef: 'period0',
              saldo: 'debit',
              negateValue: true,
              standardRubrik:
                'Löner och andra ersättningar till övriga anställda',
            }),
            ixbrlCell(value(undefined), {
              type: 'monetaryItemType',
              name: 'se-gen-base:LonerAndraErsattningarOvrigaAnstallda',
              contextRef: 'period1',
              saldo: 'debit',
              negateValue: true,
              standardRubrik:
                'Löner och andra ersättningar till övriga anställda',
            })
          )
          .build()
      )
      .build(),
    salariesAndBenefitsSumTable: table(
      'notes.note74.salariesAndBenefitsSumTable',
      { id: 'label', label: '' },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      .addRows((rows) =>
        rows
          .addRow(
            'sum',
            noteLabel('Summa'),
            ixbrlCell(
              ref(
                or(sum(id('notes.note74.salariesAndBenefitsTable.*.year')), 0)
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:LonerAndraErsattningar',
                contextRef: 'period0',
                saldo: 'debit',
                negateValue: true,
              }
            ),
            ixbrlCell(
              ref(
                or(
                  sum(
                    id('notes.note74.salariesAndBenefitsTable.*.previousYear')
                  ),
                  0
                )
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:LonerAndraErsattningar',
                contextRef: 'period1',
                saldo: 'debit',
                negateValue: true,
              }
            )
          )
          .build()
      )
      .build(),
    salariesAndBenefitsTableCommentLabel: field('Kommentar'),
    salariesAndBenefitsTableComment: ixbrlField(field(''), {
      type: 'stringItemType',
      name: 'se-gen-base:LonerAndraErsattningarKommentar',
      contextRef: 'period0',
      standardRubrik: 'Kommentar till löner och andra ersättningar',
    }),
    // TABLE 6
    socialCostsTable: table<IxbrlCell>(
      'notes.note74.socialCostsTable',
      { id: 'label', label: 'Sociala kostnader inklusive pensionskostnader' },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      .addRows((rows) =>
        rows
          .addRow(
            'boardAndManagingDirector',
            noteLabel('Styrelsen och verkställande direktören'),
            ixbrlCell(value(undefined), {
              type: 'monetaryItemType',
              name: 'se-gen-base:PensionskostnaderStyrelsenVerkstallandeDirektorenMotsvarandeBefattningshavare',
              contextRef: 'period0',
              saldo: 'debit',
              negateValue: true,
              standardRubrik:
                'Pensionskostnader för styrelseledamöter, verkställande direktör och motsvarande befattningshavare',
            }),
            ixbrlCell(value(undefined), {
              type: 'monetaryItemType',
              name: 'se-gen-base:PensionskostnaderStyrelsenVerkstallandeDirektorenMotsvarandeBefattningshavare',
              contextRef: 'period1',
              saldo: 'debit',
              negateValue: true,
              standardRubrik:
                'Pensionskostnader för styrelseledamöter, verkställande direktör och motsvarande befattningshavare',
            })
          )
          .addRow(
            'otherEmployees',
            noteLabel('Övriga anställda'),
            ixbrlCell(value(undefined), {
              type: 'monetaryItemType',
              name: 'se-gen-base:PensionskostnaderOvrigaAnstallda',
              contextRef: 'period0',
              saldo: 'debit',
              negateValue: true,
              standardRubrik: 'Pensionskostnader för övriga anställda',
            }),
            ixbrlCell(value(undefined), {
              type: 'monetaryItemType',
              name: 'se-gen-base:PensionskostnaderOvrigaAnstallda',
              contextRef: 'period1',
              saldo: 'debit',
              negateValue: true,
              standardRubrik: 'Pensionskostnader för övriga anställda',
            })
          )
          .addRow(
            'totalPensionCosts',
            noteLabel('Summa pensionskostnader'),
            ixbrlCell(
              ref(
                or(
                  sumAllowEmpty(
                    id(
                      'notes.note74.socialCostsTable.boardAndManagingDirector.year'
                    ),
                    id('notes.note74.socialCostsTable.otherEmployees.year')
                  ),
                  0
                )
              ),
              {
                type: 'decimalItemType',
                format: 'ixt:numspacecomma',
                unitRef: 'SEK',
                name: 'se-gen-base:Pensionskostnader',
                contextRef: 'period0',
                saldo: 'debit',
                negateValue: true,
              }
            ),
            ixbrlCell(
              ref(
                or(
                  sumAllowEmpty(
                    id(
                      'notes.note74.socialCostsTable.boardAndManagingDirector.previousYear'
                    ),
                    id(
                      'notes.note74.socialCostsTable.otherEmployees.previousYear'
                    )
                  ),
                  0
                )
              ),
              {
                type: 'decimalItemType',
                format: 'ixt:numspacecomma',
                unitRef: 'SEK',
                name: 'se-gen-base:Pensionskostnader',
                contextRef: 'period1',
                saldo: 'debit',
                negateValue: true,
              }
            )
          )
          .addRow(
            'otherSocialContributions',
            noteLabel('Övriga sociala avgifter enligt lag och avtal'),
            ixbrlCell(value(undefined), {
              type: 'decimalItemType',
              format: 'ixt:numspacecomma',
              unitRef: 'SEK',
              name: 'se-gen-base:OvrigaSocialaAvgifterEnligtLagAvtal',
              contextRef: 'period0',
              saldo: 'debit',
              negateValue: true,
              standardRubrik: 'Övriga sociala avgifter enligt lag och avtal',
            }),
            ixbrlCell(value(undefined), {
              type: 'decimalItemType',
              format: 'ixt:numspacecomma',
              unitRef: 'SEK',
              name: 'se-gen-base:OvrigaSocialaAvgifterEnligtLagAvtal',
              contextRef: 'period1',
              saldo: 'debit',
              negateValue: true,
              standardRubrik: 'Övriga sociala avgifter enligt lag och avtal',
            })
          )
          .build()
      )
      .build(),
    socialCostsSumTable: table(
      'notes.note74.socialCostsTable',
      { id: 'label', label: '' },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      .addRows((rows) =>
        rows
          .addRow(
            'sum',
            noteLabel('Summa'),
            ixbrlCell(
              ref(
                or(
                  sumAllowEmpty(
                    id('notes.note74.socialCostsTable.totalPensionCosts.year'),
                    id(
                      'notes.note74.socialCostsTable.otherSocialContributions.year'
                    )
                  ),
                  0
                )
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:SocialaKostnaderInklPensionskostnader',
                contextRef: 'period0',
                saldo: 'debit',
                negateValue: true,
              }
            ),
            ixbrlCell(
              ref(
                or(
                  sumAllowEmpty(
                    id(
                      'notes.note74.socialCostsTable.totalPensionCosts.previousYear'
                    ),
                    id(
                      'notes.note74.socialCostsTable.otherSocialContributions.previousYear'
                    )
                  ),
                  0
                )
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:SocialaKostnaderInklPensionskostnader',
                contextRef: 'period1',
                saldo: 'debit',
                negateValue: true,
              }
            )
          )
          .build()
      )
      .build(),
    socialCostsTableCommentLabel: field('Kommentar'),
    socialCostsTableComment: ixbrlField(field(''), {
      type: 'stringItemType',
      name: 'se-gen-base:SocialaKostnaderKommentar',
      contextRef: 'period0',
      standardRubrik:
        'Kommentar till sociala kostnader inklusive pensionskostnader',
    }),
    // table 7
    pensionCostsTable: table<IxbrlCell>(
      'notes.note74.pensionCostsTable',
      { id: 'label', label: 'Pensionsförpliktelser' },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      .addRows((rows) =>
        rows
          .addRow(
            'boardAndCEO',
            noteLabel('Styrelse och verkställande direktör'),
            ixbrlCell(value(undefined), {
              type: 'monetaryItemType',
              name: 'se-gen-base:PensionsforpliktelserNuvarandeTidigareStyrelseVerkstallandeDirektor',
              contextRef: 'balans0',
              standardRubrik:
                'Pensionsförpliktelser för nuvarande samt tidigare styrelse och verkställande direktör',
            }),
            ixbrlCell(value(undefined), {
              type: 'monetaryItemType',
              name: 'se-gen-base:PensionsforpliktelserNuvarandeTidigareStyrelseVerkstallandeDirektor',
              contextRef: 'balans1',
              standardRubrik:
                'Pensionsförpliktelser för nuvarande samt tidigare styrelse och verkställande direktör',
            })
          )
          .build()
      )
      .build(),
    pensionCostsTableCommentLabel: field('Kommentar'),
    pensionCostsTableComment: ixbrlField(field(''), {
      type: 'stringItemType',
      name: 'se-gen-base:PensionsforpliktelserNuvarandeTidigareStyrelseVerkstallandeDirektorKommentar',
      contextRef: 'period0',
      standardRubrik:
        'Kommentar till pensionsförpliktelser för nuvarande samt tidigare styrelse och verkställande direktör',
    }),
    // Table 8
    specificationOfSalariesPensionsAndOtherBenefitsTable: table<IxbrlCell>(
      'notes.note74.specificationOfSalariesPensionsAndOtherBenefitsTable',
      {
        id: 'executive',
        label: 'Befattningshavare',
      },
      {
        id: 'position',
        label: 'Befattning',
      },
      {
        id: 'salaryAndOtherBenefits',
        label: 'Lön och andra ersättningar',
      },
      {
        id: 'pensionCosts',
        label: 'Pensionskostnad',
      },
      {
        id: 'pensionObligations',
        label: 'Pensionsförpliktelse',
      }
    )
      .addRows((rows) =>
        rows
          .addRow(
            'specificationOfSalariesPensionsAndOtherBenefits',
            ixbrlCell(value(''), {
              type: 'stringItemType',
              name: 'se-gen-base:LonerAndraErsattningarPubliktAktiebolagBefattningshavareNamn',
              standardRubrik: 'Namn på befattningshavare i publikt aktiebolag',
              contextRef: 'period0',
              tuple: {
                name: 'se-gaap-ext:LonerAndraErsattningarPubliktAktiebolagTuple',
                id: `${rows.getBaseId()}.specificationOfSalariesPensionsAndOtherBenefits.tuple`,
                tupleID: `${rows.getBaseId()}.specificationOfSalariesPensionsAndOtherBenefits.tuple`,
              },
              tupleRef: tupleRef(
                1,
                `${rows.getBaseId()}.specificationOfSalariesPensionsAndOtherBenefits.tuple`
              ),
            }),
            ixbrlCell(value(''), {
              type: 'stringItemType',
              name: 'se-gen-base:LonerAndraErsattningarPubliktAktiebolagBefattningshavareBefattning',
              contextRef: 'period0',
              standardRubrik:
                'Lön och annan ersättning till befattningshavare i publikt aktiebolag',
              tupleRef: tupleRef(
                2,
                `${rows.getBaseId()}.specificationOfSalariesPensionsAndOtherBenefits.tuple`
              ),
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:LonerAndraErsattningarPubliktAktiebolagBefattningshavareLonAnnanErsattning',
              contextRef: 'period0',
              standardRubrik:
                'Lön och annan ersättning till befattningshavare i publikt aktiebolag',
              saldo: 'debit',
              negateValue: true,
              tupleRef: tupleRef(
                3,
                `${rows.getBaseId()}.specificationOfSalariesPensionsAndOtherBenefits.tuple`
              ),
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:LonerAndraErsattningarPubliktAktiebolagPensionskostnad',
              contextRef: 'period0',
              standardRubrik:
                'Pensionskostnad för befattningshavare i publikt aktiebolag',
              saldo: 'debit',
              negateValue: true,
              tupleRef: tupleRef(
                4,
                `${rows.getBaseId()}.specificationOfSalariesPensionsAndOtherBenefits.tuple`
              ),
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:LonerAndraErsattningarPubliktAktiebolagPensionsforpliktelse',
              contextRef: 'period0',
              standardRubrik:
                'Pensionskostnad för befattningshavare i publikt aktiebolag',
              tupleRef: tupleRef(
                5,
                `${rows.getBaseId()}.specificationOfSalariesPensionsAndOtherBenefits.tuple`
              ),
            })
          )
          .build()
      )
      .newRowTemplateGenerator((id, baseId) => ({
        id,
        active: true,
        cells: {
          executive: ixbrlCell(value(''), {
            type: 'stringItemType',
            name: 'se-gen-base:LonerAndraErsattningarPubliktAktiebolagBefattningshavareNamn',
            standardRubrik: 'Namn på befattningshavare i publikt aktiebolag',
            contextRef: 'period0',
            tuple: {
              name: 'se-gaap-ext:LonerAndraErsattningarPubliktAktiebolagTuple',
              id: `${baseId}.${id}.tuple`,
              tupleID: `${baseId}.${id}.tuple`,
            },
            tupleRef: tupleRef(1, `${baseId}.${id}.tuple`),
          }),
          position: ixbrlCell(value(''), {
            type: 'stringItemType',
            name: 'se-gen-base:LonerAndraErsattningarPubliktAktiebolagBefattningshavareBefattning',
            contextRef: 'period0',
            standardRubrik:
              'Lön och annan ersättning till befattningshavare i publikt aktiebolag',
            tupleRef: tupleRef(2, `${baseId}.${id}.tuple`),
          }),
          salaryAndOtherBenefits: ixbrlCell(value(undefined), {
            type: MONETARY_ITEM_TYPE,
            name: 'se-gen-base:LonerAndraErsattningarPubliktAktiebolagBefattningshavareLonAnnanErsattning',
            contextRef: 'period0',
            standardRubrik:
              'Lön och annan ersättning till befattningshavare i publikt aktiebolag',
            saldo: 'debit',
            negateValue: true,
            tupleRef: tupleRef(3, `${baseId}.${id}.tuple`),
          }),
          pensionCosts: ixbrlCell(value(undefined), {
            type: MONETARY_ITEM_TYPE,
            name: 'se-gen-base:LonerAndraErsattningarPubliktAktiebolagPensionskostnad',
            contextRef: 'period0',
            standardRubrik:
              'Pensionskostnad för befattningshavare i publikt aktiebolag',
            saldo: 'debit',
            negateValue: true,
            tupleRef: tupleRef(4, `${baseId}.${id}.tuple`),
          }),
          pensionObligations: ixbrlCell(value(undefined), {
            type: MONETARY_ITEM_TYPE,
            name: 'se-gen-base:LonerAndraErsattningarPubliktAktiebolagPensionsforpliktelse',
            contextRef: 'period0',
            standardRubrik:
              'Pensionskostnad för befattningshavare i publikt aktiebolag',
            tupleRef: tupleRef(5, `${baseId}.${id}.tuple`),
          }),
        },
      }))
      .build(),
    specificationOfSalariesPensionsAndOtherBenefitsTableCommentLabel:
      field('Kommentar'),
    specificationOfSalariesPensionsAndOtherBenefitsTableComment: ixbrlField(
      field(''),
      {
        type: 'stringItemType',
        name: 'se-gen-base:LonerAndraErsattningarPubliktAktiebolagKommentar',
        contextRef: 'period0',
      }
    ),
    // Table 9
    nrOfPeopleUnderTheCompanyManagementTable: table<IxbrlCell>(
      'notes.note74.nrOfPeopleUnderTheCompanyManagement',
      { id: 'label', label: '' },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      .addRows((rows) =>
        rows
          .addRow(
            'nrOfPeople',
            noteLabel(
              'Antalet personer i bolagets ledning för publikt aktiebolag'
            ),
            ixbrlCell(value(undefined), {
              type: 'decimalItemType',
              unitRef: 'antal-anstallda',
              format: 'ixt:numspacecomma',
              name: 'se-gen-base:LonerAndraErsattningarPubliktAktiebolagPersonerBolagetsLedning',
              contextRef: 'period0',
              standardRubrik:
                'Antalet personer i bolagets ledning för publikt aktiebolag',
            }),
            ixbrlCell(value(undefined), {
              type: 'decimalItemType',
              unitRef: 'antal-anstallda',
              format: 'ixt:numspacecomma',
              name: 'se-gen-base:LonerAndraErsattningarPubliktAktiebolagPersonerBolagetsLedning',
              contextRef: 'period1',
              standardRubrik:
                'Antalet personer i bolagets ledning för publikt aktiebolag',
            })
          )
          .addRow(
            'nrOfPeopleCoveredByObligationAndCosts',
            noteLabel(
              'Antalet personer som omfattas av förpliktelser och kostnader avseende pensioner i publikt aktiebolag'
            ),
            ixbrlCell(value(undefined), {
              type: 'monetaryItemType',
              name: 'se-gen-base:AntalPersonerOmfattasForpliktelserKostnaderAvseendePensionerPubliktBolag',
              contextRef: 'balans0',
              standardRubrik:
                'Antalet personer som omfattas av förpliktelser och kostnader avseende pensioner i publikt aktiebolag',
            }),
            ixbrlCell(value(undefined), {
              type: 'monetaryItemType',
              name: 'se-gen-base:AntalPersonerOmfattasForpliktelserKostnaderAvseendePensionerPubliktBolag',
              contextRef: 'balans1',
              standardRubrik:
                'Antalet personer som omfattas av förpliktelser och kostnader avseende pensioner i publikt aktiebolag',
            })
          )
          .build()
      )
      .build(),
    severancePayAgreementLabel: field('Avtal om avgångsvederlag'),
    severancePayAgreement: ixbrlField(field(''), {
      type: 'stringItemType',
      name: 'se-gen-base:AvtalVasentligasteVillkorAvgangsvederlagPersonerForetagetsLedningsgrupp',
      contextRef: 'period0',
    }),
    group: value(undefined),
    section: value(undefined),
    validForReportType: {
      k2: false,
      k3: true,
    },
  };
};

export default getNote74;

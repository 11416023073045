import React from 'react';
import { format } from 'date-fns';
import { useIntl } from 'react-intl';
import styled from '@emotion/styled';

import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from '_shared/components/Table/BorderTable';
import Paginator from '_shared/components/Paginator';
import { ActivityLogEventType } from '.';

const PAGE_SIZE = 10;

const PaginatorWrapper = styled.div`
  margin: ${({ theme }) => theme.spacing(2)}px auto;
`;

const TextWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing(2)}px;
`;

type Props = {
  headers: string[];
  rows: ActivityLogEventType[];
  totalResults: number;
  currentPage: number;
  onSelectResultPage: (selectedPage) => void;
};

const ActivitiesTable = ({
  headers = [],
  rows = [],
  totalResults,
  currentPage,
  onSelectResultPage,
}: Props): JSX.Element => {
  const { formatMessage } = useIntl();

  if (rows.length === 0) {
    return (
      <TableContainer>
        <TextWrapper>
          {formatMessage({ id: 'info.activity.table.empty.result' })}
        </TextWrapper>
      </TableContainer>
    );
  }

  const handlePaginationChange = (selectedPageNumber) => {
    onSelectResultPage(selectedPageNumber);
  };
  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {headers.map((header, index) => (
                <TableCell key={index.toString()}>{header}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.id}>
                <TableCell>
                  {format(new Date(row.date), 'yyyy-MM-dd HH:mm:ss')}
                </TableCell>
                <TableCell>{row.userName}</TableCell>
                <TableCell>{formatMessage({ id: row.program })}</TableCell>
                <TableCell>{formatMessage({ id: row.section })}</TableCell>
                <TableCell>{row.description}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {rows.length > 0 && (
        <PaginatorWrapper>
          <Paginator
            pageNumber={currentPage}
            totalElementCount={totalResults}
            elementsPerPage={PAGE_SIZE}
            handlePaginationChange={handlePaginationChange}
          />
        </PaginatorWrapper>
      )}
    </>
  );
};

export default ActivitiesTable;

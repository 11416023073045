import styled from '@emotion/styled';
import React, { memo, useCallback } from 'react';
import { useSelector } from 'redux/reducers';

import { getClasses } from '@agoy/common';
import { Row } from '../RowContext/types';
import { useAccountDrop } from '../RowContext/DndHooks';
import { getHiddenRowGroups } from '../RowContext/utils';

type RenderContentProps = {
  clientId: string;
  periodStart: string;
  row: Row;
  header?: (row: Row & { type: 'header' }) => React.ReactElement;
  account?: (
    row: Row & { type: 'account' },
    groupId: string,
    inHiddenGroupRow: boolean
  ) => React.ReactElement;
  groupHeader?: (row: Row & { type: 'groupHeader' }) => React.ReactElement;
  groupSum?: (row: Row & { type: 'groupSum' }) => React.ReactElement;
  keyFigure?: (row: Row & { type: 'keyFigure' }) => React.ReactElement;
  hiddenRow?: (
    row: Row & { type: 'hidden' },
    groupId?: string
  ) => React.ReactElement;
  hiddenGroupRow?: (row: Row & { type: 'hiddenGroup' }) => React.ReactElement;
  checkActiveGroup?: (groupId: string) => boolean;
  inHiddenGroupRow?: boolean;
  parentGroupId?: string;
};

/**
 * HiddenSpacer is rendered in the period columns where the hidden row should be.
 *
 * Its purpose is to make room for the content, pushing the rows underneath
 * down.
 *
 * To make the hidden row dynamic, the spacer gets the height needed from a
 * CSS variable defined by the hidden row.
 */
const HiddenSpacer = styled('div', { shouldForwardProp: () => false })<{
  rowId: string;
}>`
  height: var(--${({ rowId }) => rowId.replace('.', '-')}-height);
`;

const Container = styled.div<{ groupId?: string }>`
  &.hiddenGroupActive {
    .row.account {
      margin-top: -2px;
      border-top-left-radius: ${({ theme }) => theme.shape.borderRadius}px;
      border-top-right-radius: ${({ theme }) => theme.shape.borderRadius}px;
      border-top: 2px solid ${({ theme }) => theme.palette.primary.main};
      border-left: 2px solid ${({ theme }) => theme.palette.primary.main};
      border-right: 2px solid ${({ theme }) => theme.palette.primary.main};
    }

    > .row.account ~ .row.account {
      margin-top: 0;
      border-radius: 0;
      border-top: none;
    }

    .row.account > div {
      border-left: none;
      border-right: none;
    }
  }

  .dragOver_${({ groupId }) => groupId} &.group_${({ groupId }) => groupId} {
    .row.groupHeader {
      border-bottom-color: #ffc200;
    }
    .row.groupHeader,
    .row.groupSum,
    .change,
    .headerAccount {
      background-color: #0000000d;
    }
  }
`;

const RenderColumnContent = memo(
  ({
    clientId,
    periodStart,
    row,
    account,
    header,
    groupHeader,
    groupSum,
    keyFigure,
    hiddenRow,
    hiddenGroupRow,
    checkActiveGroup = () => false,
    inHiddenGroupRow = false,
    parentGroupId,
  }: RenderContentProps) => {
    const [, drop] = useAccountDrop(row.id);

    const type = useSelector((state) => state.customers[clientId].type);

    const getDropRef = useCallback(
      (el) => {
        if (row.type === 'group' && getHiddenRowGroups(type).includes(row.id)) {
          drop(el);
        }
      },
      [drop, row.id, row.type, type]
    );

    const hiddenGroupActive = row.type === 'group' && checkActiveGroup(row.id);

    const content = () => {
      switch (row.type) {
        case 'account':
          return account
            ? account(row, parentGroupId || '', inHiddenGroupRow)
            : null;

        case 'header':
          return header ? header(row) : null;

        case 'group':
          return (
            <>
              {row.rows.map((subRow) => (
                <RenderColumnContent
                  key={`${subRow.type}_${subRow.id}`}
                  clientId={clientId}
                  periodStart={periodStart}
                  row={subRow}
                  header={header}
                  account={account}
                  groupHeader={groupHeader}
                  groupSum={groupSum}
                  keyFigure={keyFigure}
                  hiddenRow={hiddenRow}
                  hiddenGroupRow={hiddenGroupRow}
                  checkActiveGroup={checkActiveGroup}
                  inHiddenGroupRow={hiddenGroupActive}
                  parentGroupId={row.id}
                />
              ))}
            </>
          );

        case 'groupHeader':
          return groupHeader ? groupHeader(row) : null;

        case 'groupSum':
          return groupSum ? groupSum(row) : null;

        case 'keyFigure':
          return keyFigure ? keyFigure(row) : null;

        case 'hidden':
          return hiddenRow ? (
            hiddenRow(row, parentGroupId)
          ) : (
            <HiddenSpacer rowId={row.id} />
          );

        case 'hiddenGroup':
          return hiddenGroupRow ? (
            hiddenGroupRow(row)
          ) : (
            <HiddenSpacer rowId={row.id} />
          );

        default:
          return null;
      }
    };

    const classes = getClasses({
      hiddenGroupActive,
    });

    return (
      <Container
        className={`${row.type}_${row.id} ${row.className} ${classes}`}
        key={`${row.type}_${row.id}`}
        ref={getDropRef}
        groupId={row.type === 'group' ? row.id : undefined}
      >
        {content()}
      </Container>
    );
  }
);

export default RenderColumnContent;

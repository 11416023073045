/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Grid } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { PrivatePerson } from '_person/_types/person';
import AccessControl from './_organisms/AccessControl';
import SearchField from './_organisms/SearchField';
import PersonAvatar from './_organisms/PersonAvatar';

const PersonsSection = styled(Grid)`
  padding: ${(props) => props.theme.spacing(1)}px;
  margin-top: ${(props) => props.theme.spacing(1)}px;
`;

const Container = styled.div`
  margin-top: ${(props) => props.theme.spacing(1)}px;
`;

type Persons = {
  [key: string]: PrivatePerson;
};

type Props = {
  persons: Persons;
  member: Member.MemberType;
  onAuthorisationToggle: (personId: string) => void;
};

const PersonAccessView = (props: Props): JSX.Element => {
  const { persons, member, onAuthorisationToggle } = props;
  const { formatMessage } = useIntl();
  const searchPlaceholder = formatMessage({
    id: 'person.search.placeholder',
  });
  const [filteredPersons, setFilteredPersons] = useState<PrivatePerson[]>([]);
  const [search, setSearch] = useState('');

  const isAuthorised = (personId: string | undefined) => {
    const authorisedPerson = member.authorisedPersons?.find(
      (authorized) => authorized.id === personId
    );

    return authorisedPerson != null;
  };

  useEffect(() => {
    const filteredList = Object.keys(persons).filter(
      (id) =>
        persons[id].firstName.toLowerCase().includes(search.toLowerCase()) ||
        persons[id].lastName.toLowerCase().includes(search.toLowerCase())
    );

    setFilteredPersons(filteredList.map((key) => persons[key]));
  }, [persons, search]);

  return (
    <Container>
      <SearchField
        placeholder={searchPlaceholder}
        onChange={(newValue: string) => setSearch(newValue)}
      />
      <PersonsSection container spacing={2}>
        {filteredPersons.map((person: PrivatePerson) => (
          <Grid key={person.id} item xs={12} sm={6} lg={4}>
            <AccessControl
              name={`${person.firstName} ${person.lastName}`}
              Avatar={<PersonAvatar personNumber={person.personNumber} small />}
              isAuthorised={isAuthorised(person.id)}
              onAuthorisationToggle={() => onAuthorisationToggle(person.id!)}
            />
          </Grid>
        ))}
      </PersonsSection>
    </Container>
  );
};

export default PersonAccessView;

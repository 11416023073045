import React, { useEffect, useContext } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { ClientsRouteParams } from 'routes/types';
import { useIntl } from 'react-intl';
import { Grid, Box, Paper as MuiPaper, Typography } from '@material-ui/core';
import styled from '@emotion/styled';
import { useSelector } from 'redux/reducers';
import TaxTableRow from '_tax/components/Taxes/TaxTableRow';
import { currentClient } from '_reconciliation/redux/accounting-view/selectors';
import TaxesDataContext from '../../../service/TaxesDataContext';

const Paper = styled(MuiPaper)`
  padding: ${({ theme }) => theme.spacing(2)}px;
`;

const SecondaryHeader = styled(Box)`
  padding-left: ${({ theme }) => theme.spacing(2)}px;
  background: ${({ theme }) => theme.palette.info.light};
  border-top-left-radius: ${({ theme }) => theme.shape.borderRadius}px;
  border-top-right-radius: ${({ theme }) => theme.shape.borderRadius}px;
  color: ${({ theme }) =>
    theme.palette.getContrastText(theme.palette.info.light)};
`;

const formatConfig = {};

const AccrualFunds = () => {
  const history = useHistory();
  const {
    params: { clientId },
  } = useRouteMatch<ClientsRouteParams>();
  const { financialYear, taxYearData, isLastPeriod } =
    useContext(TaxesDataContext);

  const accrualFunds = taxYearData.state?.accrualFunds;

  const periods = useSelector(
    currentClient(
      (state) => state.years[financialYear]?.accountingBalances?.periods
    )
  );

  useEffect(() => {
    if (periods) {
      if (!isLastPeriod) {
        history.push(`/clients/${clientId}/${financialYear}/tax/salarytax`);
      }
    }
  }, [periods, history, clientId, financialYear, isLastPeriod]);

  const { formatMessage } = useIntl();

  return (
    <Box padding={2} id="information">
      <Paper>
        <Grid container spacing={2}>
          <Grid item>
            <MuiPaper elevation={0}>
              <Box>
                <SecondaryHeader display="flex" alignItems="center">
                  <Typography variant="h3">
                    {formatMessage({ id: 'hidden.information.accrualfund' })}
                  </Typography>
                </SecondaryHeader>
              </Box>
              {accrualFunds?.moreRows?.map((row) => (
                <TaxTableRow
                  key={row.id}
                  part="accrualFunds"
                  config={formatConfig}
                  row={row}
                  editing={false}
                  hideEmpty={false}
                />
              ))}
            </MuiPaper>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default AccrualFunds;

import {
  AnnualReportChanges,
  DocumentConfiguration,
} from '@agoy/annual-report-document';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { RootState } from 'redux/reducers';
import { asResultClass, getApiSdk } from 'api-sdk';
import { getContext } from 'utils/AgoyAppClient/contextHolder';
import {
  addGlobalMessage,
  addGlobalErrorMessage,
} from '_messages/redux/actions';

import {
  SetAnnualReportChangesAction,
  DeleteAnnualReportDocument,
} from './action-type-declarations';
import {
  SET_ANNUAL_REPORT_CHANGES,
  DELETE_ANNUAL_REPORT_DOCUMENT,
} from './action-types';

// eslint-disable-next-line import/prefer-default-export
export const setAnnualReportChanges = (
  clientId: string,
  financialYear: string,
  documentConfiguration: DocumentConfiguration,
  changes: AnnualReportChanges,
  documentId?: string,
  locked?: boolean
): SetAnnualReportChangesAction => ({
  type: SET_ANNUAL_REPORT_CHANGES,
  clientId,
  financialYear,
  documentConfiguration,
  changes,
  documentId,
  locked,
});

export const deleteAnnualReportDocumentAction = (
  financialYear: string,
  clientId: string
): DeleteAnnualReportDocument => ({
  type: DELETE_ANNUAL_REPORT_DOCUMENT,
  financialYear,
  clientId,
});

export const deleteAnnualReportDocument =
  (
    clientId: string,
    financialYear: string
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    try {
      const { documentId } =
        getState().annualReport.clients[clientId].years[financialYear];
      if (documentId) {
        const deleteResult = await asResultClass(
          getApiSdk(getContext()).deleteAgoyDocument({
            documentId,
            clientId,
          })
        );
        if (deleteResult.err) {
          throw new Error('Failed to delete annual report document');
        }
        dispatch(deleteAnnualReportDocumentAction(financialYear, clientId));
        if (deleteResult.ok) {
          const messageKey =
            'annualReport.settings.fetchFromPreviousYear.button.success';
          dispatch(addGlobalMessage('success', messageKey));
        }
      }
    } catch (e) {
      dispatch(addGlobalErrorMessage('Error'));
    }
  };

import React from 'react';
import { Modal, Fade, Backdrop } from '@material-ui/core';
import styled from '@emotion/styled';
import CloseTopBar from '../CloseTopBar';

const Root = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Container = styled.div`
  outline: none;
`;

interface ModalContentProps {
  fullHeight: boolean;
}

const ModalContent = styled.div<ModalContentProps>`
  border-radius: 0 0 8px 8px;
  margin: auto auto;
  width: min-content;
  ${(props) => (props.fullHeight ? 'max-height: 85vh;' : '')}
  overflow: auto;
  min-width: 1000px;
  background: white;

  display: flex;
`;

interface GenericModalProps {
  id?: string;
  open: boolean;
  handleClose: () => void;
  children: React.ReactNode;
  fullHeight?: boolean;
  title?: string;
  icon?: any;
  tooltipText?: any;
}
const GenericModal = ({
  id,
  open,
  handleClose,
  children,
  fullHeight = true,
  title,
  icon,
  tooltipText,
}: GenericModalProps): JSX.Element => {
  return (
    <Root
      id={id}
      aria-labelledby="image-modal"
      aria-describedby="image-modal"
      open={open}
      onClose={(event, reason) => {
        if (reason === 'backdropClick') {
          handleClose();
        }
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Container>
          <CloseTopBar
            title={title}
            onClose={handleClose}
            icon={icon}
            tooltipText={tooltipText}
          />
          <ModalContent
            onClick={(event) => event.stopPropagation()}
            fullHeight={fullHeight}
          >
            {children}
          </ModalContent>
        </Container>
      </Fade>
    </Root>
  );
};

export default GenericModal;

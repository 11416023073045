import React from 'react';
import styled from '@emotion/styled';
import { IconButton, Typography } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const Header = styled.div<{ fixed: boolean }>`
  display: flex;
  align-items: center;
  padding: ${(props) => props.theme.spacing(0)}px 0
    ${(props) => props.theme.spacing(0)}px
    ${(props) => props.theme.spacing(0)}px;
  color: ${(props) => props.theme.palette.secondary.contrastText};
  background-color: ${(props) =>
    props.theme.palette.accountingView.headers.main};
  position: ${(props) => (props.fixed ? 'sticky' : 'initial')};
  top: 0;
  width: 100%;
  height: 48px;
  z-index: 1;
`;

const HeadIconAndTitle = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  > p {
    padding-left: ${(props) => props.theme.spacing(1)}px;
  }
`;

interface DrawerHeaderProps {
  toggleExpanded: () => void;
  expanded: boolean;
  title: string;
  fixed?: boolean;
  children?: React.ReactElement | React.ReactElement[];
}

const DrawerHeader = React.forwardRef<HTMLDivElement, DrawerHeaderProps>(
  (
    {
      toggleExpanded,
      expanded,
      title,
      fixed = false,
      children,
    }: DrawerHeaderProps,
    ref
  ) => {
    return (
      <Header fixed={fixed} ref={ref}>
        <HeadIconAndTitle>
          <IconButton onClick={toggleExpanded} color="inherit">
            {expanded ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
          <Typography variant="body1" color="inherit">
            {title}
          </Typography>
        </HeadIconAndTitle>
        {children}
      </Header>
    );
  }
);

export default DrawerHeader;

import React from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import styled from '@emotion/styled';
import { AgoyTable } from '@agoy/document';
import { isActive } from '@agoy/annual-report-document';
import { Table } from '../../../UI/CommonPrintTable';
import { Cell } from '../../../UI/CommonPrintTable/Cell';

const TableComponent = styled(Table)<{ print?: boolean }>`
  .MuiTableCell-root {
    font-size: inherit;
    padding-left: 0;
  }
`;

interface CheckboxTableProps {
  table: AgoyTable;
  tableId: string;
}

const CheckboxTable = ({ table, tableId }: CheckboxTableProps): JSX.Element => {
  const renderRow = ({ row }) => {
    if (!isActive(row.active)) {
      return null;
    }

    return (
      <TableRow>
        {table.columns.map((col) => {
          const cell = row.cells?.[col.id];
          const key = `${row.id}.${col.id}`;
          const output: React.ReactNode[] = [];

          if (!cell) {
            output.push(<TableCell key={key} />);
          } else {
            output.push(
              <TableCell key={key}>
                <Cell
                  className="MuiTypography-root"
                  cell={cell}
                  active={col.id === 'label' || isActive(row.active) !== false}
                />
              </TableCell>
            );
          }
          return output;
        })}
      </TableRow>
    );
  };

  return (
    <TableComponent
      baseId="annualReport"
      tableId={tableId}
      rows={table.rows}
      columns={table.columns}
      hideHeader
      renderRow={renderRow}
    />
  );
};

export default CheckboxTable;

import React, { useContext, useMemo } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import styled from '@emotion/styled';

import { ForaCalculationsPrint } from '_tax/components/Taxes/ParticularSalaryPanels/ForaCalculationPanel';
import { ITPCalculationsPrint } from '_tax/components/Taxes/ParticularSalaryPanels/ITPPremiumsPanel';
import ViewPensionCommitments from '_tax/components/Taxes/UI/ViewPensionCommitmentsComponent/ViewPensionCommitmentsComponent';
import ViewSummaryComponent from '_tax/components/Taxes/UI/ViewSummaryComponent/ViewSummaryComponent';
import PropertyTaxTablePrint from '_tax/components/Taxes/PropertyTax/PropertyTaxTablePrint';
import YearEndPlanning from '_tax/components/Taxes/YearEndPlanning';
import TaxCalculation from '_tax/components/Taxes/TaxCalculation';
import TaxTable from '_tax/components/Taxes/TaxTable';

import TaxesDataContext from './TaxesDataContext';

const Page = styled(({ children, ...props }) =>
  React.Children.count(children) > 0 ? <div {...props}>{children}</div> : null
)`
  page-break-before: always;
  width: 842px;

  @media screen {
    border-bottom: 1px dashed ${(props) => props.theme.palette.grey['500']};
    padding: 32px 32px;
    width: 864px;
  }
`;

type TaxesPrintProps = {
  pageNumbers: Record<string, number>;
};

const TaxesPrint = ({ pageNumbers }: TaxesPrintProps): JSX.Element | null => {
  const { path } = useRouteMatch();

  const { periodObj, financialYearObj } = useContext(TaxesDataContext);

  const accountingPeriod = useMemo(
    () => ({
      ...periodObj,
      financialYearId: financialYearObj.id,
    }),
    [financialYearObj.id, periodObj]
  );

  return (
    <Switch>
      <Route path={`${path}/foraCalculations`}>
        <Page>
          <ForaCalculationsPrint />
        </Page>
      </Route>
      <Route path={`${path}/itpCalculations`}>
        <Page>
          <ITPCalculationsPrint />
        </Page>
      </Route>
      <Route path={`${path}/pensionCommitments`}>
        <Page>
          <ViewPensionCommitments isPrint />
        </Page>
      </Route>
      <Route path={`${path}/summary`}>
        <Page>
          <ViewSummaryComponent isPrint />
        </Page>
      </Route>
      <Route path={`${path}/propertyTax`}>
        <Page>
          <PropertyTaxTablePrint currentPeriod={accountingPeriod} />
        </Page>
      </Route>
      <Route path={`${path}/yearEndPlanning`}>
        <Page>
          <YearEndPlanning isPrint />
        </Page>
      </Route>
      <Route path={`${path}/nonTaxableIncomes`}>
        <Page>
          <TaxTable part="nonTaxableIncomes" printable print />
        </Page>
      </Route>
      <Route path={`${path}/nonDeductibleExpenses`}>
        <Page>
          <TaxTable part="nonDeductibleExpenses" printable print />
        </Page>
      </Route>
      <Route path={`${path}/taxCalculation`}>
        <Page>
          <TaxCalculation isPrint />
        </Page>
      </Route>

      <Route path={path} exact>
        {'foraCalculations' in pageNumbers && (
          <Page>
            <ForaCalculationsPrint />
          </Page>
        )}
        {'itpCalculations' in pageNumbers && (
          <Page>
            <ITPCalculationsPrint />
          </Page>
        )}
        {'pensionCommitments' in pageNumbers && (
          <Page>
            <ViewPensionCommitments isPrint />
          </Page>
        )}
        {'summary' in pageNumbers && (
          <Page>
            <ViewSummaryComponent isPrint />
          </Page>
        )}
        {'propertyTax' in pageNumbers && (
          <Page>
            <PropertyTaxTablePrint currentPeriod={accountingPeriod} />
          </Page>
        )}
        {'yearEndPlanning' in pageNumbers && (
          <Page>
            <YearEndPlanning isPrint />
          </Page>
        )}
        {'nonTaxableIncomes' in pageNumbers && (
          <Page>
            <TaxTable part="nonTaxableIncomes" printable print />
          </Page>
        )}
        {'nonDeductibleExpenses' in pageNumbers && (
          <Page>
            <TaxTable part="nonDeductibleExpenses" printable print />
          </Page>
        )}
        {'taxCalculation' in pageNumbers && (
          <Page>
            <TaxCalculation isPrint />
          </Page>
        )}
      </Route>
    </Switch>
  );
};

export default TaxesPrint;

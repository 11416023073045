import React from 'react';
import { Cell } from '@agoy/document';

export interface TableServiceType {
  addRow: (
    rowId: string,
    newRowId?: string,
    cellParameters?: Record<string, string>,
    copyId?: string
  ) => Promise<void> | void;

  addTableColumn: (
    tableId: string,
    index: number,
    label: string,
    sortKey?: number
  ) => Promise<void> | void;

  deleteColumn: (columnId: string) => Promise<void> | void;

  deleteRow: (rowId: string) => Promise<void> | void;

  toggleFieldActive: (fieldId: string) => Promise<void> | void;
  toggleSectionActive: (id: string) => Promise<void> | void;
  toggleTableActive: (tableid: string) => Promise<void> | void;
  toggleTableRowActive: (rowId: string) => Promise<void> | void;

  updateCellValue: (
    cellId: string,
    value: Cell | string | number | undefined,
    options?: { keepOriginal?: boolean }
  ) => Promise<void> | void;

  updateCellReferences: (
    cellId: string,
    references: string[]
  ) => Promise<void> | void;

  updateColumnLabel: (columnId: string, label: string) => Promise<void> | void;

  updateField: (
    fieldId: string,
    value: string | number | undefined | boolean
  ) => Promise<void> | void;

  updateColumnSortKey: (
    columnsWithSortKey: {
      id: string;
      sortKey: number;
    }[]
  ) => Promise<void> | void;

  updateRows: (
    rows: {
      id: string;
      sortKey?: number;
      active?: boolean;
    }[]
  ) => Promise<void> | void;
}

const defaultService: TableServiceType = {} as TableServiceType;

const TableServiceContext =
  React.createContext<TableServiceType>(defaultService);

export default TableServiceContext;

import React, { useRef, useEffect } from 'react';

type Props = {
  onDrop: (file: File, id: number) => void;
  className?: string;
  onEnter?: (event: React.DragEvent) => void;
  onLeave?: (event: React.DragEvent) => void;
  isValidFile?: (file: File) => boolean;
  onInvalidFileType?: () => void;
  children: React.ReactNode;
};

/**
 * Can be used with {@link web/src/_shared/hooks/useFileDrop.ts | useFileDrop} hook
 */
const FileDropArea = ({
  onDrop,
  className,
  onEnter,
  onLeave,
  isValidFile = () => true,
  onInvalidFileType = () => {},
  children,
}: Props) => {
  const inside = useRef(0);

  const handleDragOver = (event: React.DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
    event.dataTransfer.dropEffect = 'copy';
  };

  const handleDrop = (event: React.DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
    inside.current = 0;
    const files = [...event.dataTransfer.files];

    files.forEach((file, i) => {
      if (file && isValidFile(file)) {
        onDrop(file, i);
      } else {
        onInvalidFileType();
      }
    });
  };

  const handleLeave = (event: React.DragEvent) => {
    inside.current -= 1;
    if (inside.current === 0 && onLeave) {
      onLeave(event);
    }
  };

  const handleEnter = (event: React.DragEvent) => {
    inside.current += 1;
    if (inside.current === 1 && onEnter) {
      onEnter(event);
    }
  };

  useEffect(() => {
    const handleWindowEvents = (event: Event) => {
      event.stopPropagation();
      event.preventDefault();
    };

    document.addEventListener('drop', handleWindowEvents, false);
    document.addEventListener('dragover', handleWindowEvents, false);
    return () => {
      document.removeEventListener('dragover', handleWindowEvents, false);
      document.removeEventListener('drop', handleWindowEvents, false);
    };
  }, []);

  return (
    <div
      className={className}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      onDragEnter={handleEnter}
      onDragLeave={handleLeave}
    >
      {children}
    </div>
  );
};

export default FileDropArea;

import React, { useState, ReactElement, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import styled from '@emotion/styled';
import { PreviewFortnoxFileType } from 'types/PreviewFortnoxFile/types';
import { asResultClass, useApiSdk } from 'api-sdk';
import Button from '_shared/components/Buttons/Button';
import { useAccountDocuments } from 'utils/usePeriodDocuments';
import { addGlobalMessage } from 'redux/actions';

import PreviewFileModal from './PreviewFileModal';

const Wrapper = styled.div`
  display: flex;
  flex-flow: row;
`;
type PreviewFileProps = {
  clientId: string;
  fileList: PreviewFortnoxFileType[];
  additionalInfo?: ReactElement;
  showModal: boolean;
  uploadFile: boolean;
};

const PreviewFortnoxFile = ({
  clientId,
  fileList,
  additionalInfo,
  showModal = false,
  uploadFile = true,
}: PreviewFileProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const { addDocument } = useAccountDocuments();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fileId, setFileId] = useState<number>(0);
  const [currentFiles, setFiles] = useState<PreviewFortnoxFileType[]>([]);
  const sdk = useApiSdk();

  useEffect(() => {
    const initFileContent = async (reset: boolean) => {
      await updateFileContent(fileId, reset);
    };
    initFileContent(true);
    setIsModalOpen(showModal);
  }, [fileList, showModal]);

  const handleClosePreviewModal = () => {
    setIsModalOpen(false);
    setFiles([]);
    setFileId(0);
  };

  const updateFileContent = async (fileIndex: number, reset = false) => {
    let dataFiles: PreviewFortnoxFileType[];
    if (!reset) {
      dataFiles = currentFiles;
    } else {
      dataFiles = fileList;
    }
    if (
      dataFiles &&
      dataFiles[fileIndex] &&
      !dataFiles[fileIndex].fileContent
    ) {
      const id = dataFiles[fileIndex].fileId;
      const file = await asResultClass(
        sdk.getFortnoxFile({ clientid: clientId, fileId: id })
      );
      if (file.ok) {
        const fortnoxFile = {
          fileId: id,
          fileContent: file.val.fileContent || '',
          fileName: file.val.fileName || '',
        };
        dataFiles[fileIndex] = fortnoxFile;
        setFiles(dataFiles);
      }
    }
  };

  const onStepForward = async () => {
    const fileIndex =
      fileId + 1 < currentFiles.length ? fileId + 1 : currentFiles.length - 1;
    await updateFileContent(fileIndex);
    setFileId(fileIndex);
  };

  const onStepBackward = async () => {
    const fileIndex = fileId - 1 >= 0 ? fileId - 1 : 0;
    await updateFileContent(fileIndex);
    setFileId(fileIndex);
  };

  const handleUploadAttachment = async () => {
    const fileName = currentFiles[fileId].fileName || '';
    const data = currentFiles[fileId].fileContent.split(';');
    if (data.length === 2) {
      const mime = data[0].split(':')[1];
      const content = data[1].split(',')[1];
      const buff = Buffer.from(content, 'base64');
      const file = new File([buff], fileName, { type: mime });
      const url = await addDocument(fileName, file);
      if (url && url.url && url.url.length !== 0) {
        dispatch(
          addGlobalMessage('success', 'hidden.transactions.uploadCompleted')
        );
      }
    }
  };

  return (
    <Wrapper>
      {currentFiles.length > 0 && (
        <PreviewFileModal
          fileContent={currentFiles[fileId].fileContent || ''}
          fileName={currentFiles[fileId].fileName || ''}
          information={
            uploadFile ? (
              <>
                <Button
                  label={formatMessage({
                    id: 'hidden.transactions.uploadAttachment',
                  })}
                  onClick={handleUploadAttachment}
                />
                {additionalInfo}
              </>
            ) : (
              { additionalInfo }
            )
          }
          isOpen={isModalOpen && !!currentFiles[fileId]}
          onStepBackwards={
            currentFiles.length === 1 || fileId <= 0
              ? undefined
              : onStepBackward
          }
          onStepForward={
            currentFiles.length === 1 || fileId === currentFiles.length - 1
              ? undefined
              : onStepForward
          }
          onClose={handleClosePreviewModal}
        />
      )}
    </Wrapper>
  );
};

export default PreviewFortnoxFile;

import React from 'react';
import { useIntl } from 'react-intl';
import { BalanceSheet, Settings } from '@agoy/annual-report-document';

import {
  ReportColors,
  prepareBalanceSheet,
  numberOfTableRows,
} from '../../../../utils';
import StatementTableView from '../../UI/StatementTable/StatementTable';

interface BalanceSheetViewProps {
  balanceSheet: BalanceSheet;
  reportColors: ReportColors;
  settings: Settings;
  shouldSplitTable: boolean;
  shouldShowZeros: boolean;
  part?: 'part1' | 'part2';
  print?: boolean;
}

const BalanceSheetView = ({
  balanceSheet,
  reportColors,
  settings,
  shouldSplitTable,
  shouldShowZeros,
  part,
  print = false,
}: BalanceSheetViewProps): JSX.Element | null => {
  const { formatMessage } = useIntl();

  const { equityAndLiabilities, assets } = balanceSheet.section;

  const assetsTitle = formatMessage({
    id:
      numberOfTableRows(assets, shouldShowZeros) > 0 && part
        ? `financialreport.balance-sheet-assets-${part}`
        : 'financialreport.balance-sheet-assets',
  });
  const commonTitle = formatMessage({ id: 'financialreport.balance-sheet' });
  const title = part ? assetsTitle : commonTitle;

  const balanceSheetTable = part
    ? prepareBalanceSheet(assets, shouldSplitTable, shouldShowZeros, part)
    : prepareBalanceSheet(
        equityAndLiabilities,
        shouldSplitTable,
        shouldShowZeros
      );

  const balanceSheetTableTitle = part ? 'assets' : 'equityAndLiabilities';

  return (
    <StatementTableView
      reportColors={reportColors}
      settings={settings as unknown as Settings}
      table={balanceSheetTable}
      tableId={`balanceSheet.section.${balanceSheetTableTitle}`}
      pageTitle={title}
      tableTitle={formatMessage({ id: 'financialreport.balance-sheet.title' })}
      print={print}
    />
  );
};

export default BalanceSheetView;

import { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import ClientLoaderContext, {
  LoaderFunction,
  LoaderScope,
} from '_shared/components/ClientLoaderContext';

interface BaseLoaderProps {
  /**
   * Unique id for the loader
   */
  id: string;

  /**
   * The scope for the loader, @see LoaderScope.
   */
  scope: LoaderScope;

  /**
   * The loader, @see LoaderFunction.
   */
  loader: LoaderFunction;
}

/**
 * Base implementation for a loader as a React component.
 * Takes care of the register/unregister part.
 */
const BaseLoader = ({ id, scope, loader }: BaseLoaderProps): null => {
  const { registerLoader, unregisterLoader } = useContext(ClientLoaderContext);
  const dispatch = useDispatch();
  useEffect(() => {
    registerLoader(id, scope, loader);

    return () => {
      unregisterLoader(id);
    };
  }, [id, loader, scope, dispatch, registerLoader, unregisterLoader]);

  return null;
};

export default BaseLoader;

import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import ROUTES, { RenderRoutes } from 'routes/config';
import { withSharedProviders } from './AppProviders';

const App = withSharedProviders(() => {
  return (
    <BrowserRouter>
      <RenderRoutes routes={ROUTES} />
    </BrowserRouter>
  );
});

export default App;

import React, { useEffect, useContext, useMemo } from 'react';
import {
  annualGeneralMeetingContentDefinition,
  annualGeneralMeetingSignaturesContentDefinition,
  annualReportSignaturesContentDefinition,
  confirmationCertificateContentDefinition,
} from '@agoy/annual-report-document';
import { useSelector } from 'redux/reducers';
import DocumentViewServiceContext from '_shared/services/document/DocumentViewServiceContext';
import GenericDocumentReactiveViewService from '_shared/services/document/GenericDocumentReactiveViewService';
import useObservable from 'utils/useObservable';
import AnnualReportDataServiceContext from './AnnualReportDataServiceContext';
import AnnualReportReactiveViewService from './AnnualReportReactiveViewService';
import AnnualReportViewService from './AnnualReportViewService';
import CompoundViewService from './CompoundViewService';

const defaultService: AnnualReportViewService = {} as AnnualReportViewService;

const AnnualReportViewServiceContext =
  React.createContext<AnnualReportViewService>(defaultService);

export const Provider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}): JSX.Element | null => {
  const userId = useSelector((state) => state.user['custom:userId']);
  const reportService = useContext(AnnualReportDataServiceContext);
  const annualReportConfig = useObservable(
    reportService.annualReport.documentConfiguration
  );

  const service = useMemo(
    () =>
      annualReportConfig
        ? new AnnualReportReactiveViewService(
            reportService.annualReport,
            annualReportConfig,
            userId
          )
        : undefined,
    [
      reportService.annualReport,
      annualReportConfig?.documentType,
      annualReportConfig?.version,
      userId,
    ]
  );

  const compoundService = useMemo(() => {
    if (!annualReportConfig) {
      return undefined;
    }
    if (
      annualReportConfig.documentType !== 'shares' ||
      annualReportConfig.version !== '2'
    ) {
      return service;
    }

    if (
      !reportService.annualGeneralMeeting ||
      !reportService.annualReportSignatures ||
      !reportService.annualGeneralMeetingSignatures ||
      !reportService.confirmationCertificate
    ) {
      return service;
    }
    const annualGeneralMeeting = new GenericDocumentReactiveViewService(
      annualGeneralMeetingContentDefinition,
      reportService.annualGeneralMeeting
    );
    const signatures = new GenericDocumentReactiveViewService(
      annualReportSignaturesContentDefinition,
      reportService.annualReportSignatures
    );
    const annualGeneralMeetingSignatures =
      new GenericDocumentReactiveViewService(
        annualGeneralMeetingSignaturesContentDefinition,
        reportService.annualGeneralMeetingSignatures
      );
    const confirmationCertificate = new GenericDocumentReactiveViewService(
      confirmationCertificateContentDefinition,
      reportService.confirmationCertificate
    );

    return new CompoundViewService(service, {
      annualGeneralMeeting,
      signatures,
      annualGeneralMeetingSignatures,
      confirmationCertificate,
    });
  }, [
    annualReportConfig,
    reportService.annualGeneralMeeting,
    reportService.annualGeneralMeetingSignatures,
    reportService.annualReportSignatures,
    reportService.confirmationCertificate,
    service,
  ]);

  useEffect(() => {
    if (service) {
      service.initialize();

      return () => {
        service.dispose();
      };
    }
  }, [service]);

  if (!service || !compoundService) {
    return null;
  }

  return (
    <AnnualReportViewServiceContext.Provider value={service}>
      <DocumentViewServiceContext.Provider value={compoundService}>
        {children}
      </DocumentViewServiceContext.Provider>
    </AnnualReportViewServiceContext.Provider>
  );
};

export default AnnualReportViewServiceContext;

import React, { useContext } from 'react';
import useObservable from 'utils/useObservable';
import AnnualReportDataServiceContext from '_annual-report/service/AnnualReportDataServiceContext';
import Page from '_shared/components/PrintedDocument/Page';
import AnnualGeneralMeetingPreview from './Parts/AnnualGeneralMeeting/AnnualGeneralMeetingPreview';
import useAnnualReport from './useAnnualReport';

const AnnualGeneralMeetingPrint = (): JSX.Element | null => {
  const annualReport = useAnnualReport();
  const service = useContext(AnnualReportDataServiceContext);

  const annualGeneralMeetingDocument = useObservable(
    service.annualGeneralMeeting?.report
  );
  const annualReportSignaturesDocument = useObservable(
    service.annualReportSignatures?.report
  );

  if (!annualReport) {
    return null;
  }

  const signatures =
    annualReport.version === '1'
      ? annualReport.signatures
      : annualReportSignaturesDocument?.signatures;

  let annualGeneralMeeting = annualGeneralMeetingDocument?.annualGeneralMeeting;

  if (!annualGeneralMeeting && 'annualGeneralMeeting' in annualReport) {
    annualGeneralMeeting = annualReport.annualGeneralMeeting;
  }

  if (annualGeneralMeeting && signatures) {
    return (
      <Page>
        <AnnualGeneralMeetingPreview
          annualGeneralMeeting={annualGeneralMeeting}
          settings={annualReport.settings}
          signatures={signatures}
        />
      </Page>
    );
  }

  return null;
};

export default AnnualGeneralMeetingPrint;

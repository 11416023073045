import React, { useMemo, useCallback, useContext } from 'react';
import { useIntl } from 'react-intl';
import { Button, Paper as MuiPaper } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import AlertTitle from '@material-ui/lab/AlertTitle/AlertTitle';
import styled from '@emotion/styled';

import { printDocument } from '_shared/components/PrintedDocument/utils';
import usePrintState from '_tax/hooks/usePrintState';
import { addGlobalErrorMessage } from 'redux/actions';
import Alert from '_shared/components/AlertBanners/Alert';
import { RESET_YEAR_END_PLANNING } from '_tax/redux/tax-view/action-types';
import useModule from '_tax/hooks/useModule';
import { isTaxDeclarationModule } from 'utils/TaxDeclaration/tax-declaration-util';

import TaxTable from './TaxTable';
import TaxesDataContext from '../../service/TaxesDataContext';

const Wrapper = styled.div`
  min-width: 600px;
  max-width: 600px;
`;

const Paper = styled(MuiPaper)`
  height: 100%;
`;

const ButtonRow = styled.div`
  display: grid;
  justify-content: flex-end;
  gap: 16px;
  grid-template-columns: auto auto;
`;

const Warning = styled(Alert)`
  .MuiAlert-message {
    width: 100%;
  }
`;

type YearEndPlanningProps = {
  isPrint?: boolean;
};

const YearEndPlanning = ({
  isPrint = false,
}: YearEndPlanningProps): JSX.Element | null => {
  const { formatMessage } = useIntl();
  const moduleContext = useModule();
  const printState = usePrintState();
  const dispatch = useDispatch();

  const { taxYearData, service, clientId, financialYear } =
    useContext(TaxesDataContext);

  const taxTable = taxYearData.state?.yearEndPlanning;

  const additionalRows = useMemo(
    () =>
      taxTable?.resultAfterYearEndPlanningBeforeTaxes
        ? [taxTable.resultAfterYearEndPlanningBeforeTaxes]
        : [],
    [taxTable]
  );

  const reset = useCallback(() => {
    service.resetGenericTax(RESET_YEAR_END_PLANNING);
  }, [service]);

  const accept = useCallback(() => {
    service.updateYearEndPlanningChecksum();
  }, [service]);

  const handlePrint = useCallback(async () => {
    try {
      await printDocument(
        clientId,
        financialYear,
        'taxes',
        formatMessage({ id: `tax.yearEndPlanning.title` }),
        ['yearEndPlanning'],
        printState
      );
    } catch (e) {
      dispatch(addGlobalErrorMessage('error'));
    }
  }, [clientId, dispatch, financialYear, formatMessage, printState]);

  if (!taxTable) {
    return null;
  }

  const taxDeclarationModule = isTaxDeclarationModule(moduleContext);

  return (
    <Wrapper>
      <Paper>
        <TaxTable
          part="yearEndPlanning"
          showAllOption
          mainTable
          additionalRows={additionalRows}
          onReset={reset}
          disableEditing={taxDeclarationModule && isPrint}
          startContent={
            taxTable.outdated ? (
              <Warning type="warning">
                <AlertTitle>
                  {formatMessage({ id: 'tax.yearEndPlanning.alert.update' })}
                </AlertTitle>
                <ButtonRow>
                  <Button onClick={reset} variant="outlined">
                    {formatMessage({ id: 'tax.yearEndPlanning.alert.reset' })}
                  </Button>
                  <Button onClick={accept} variant="contained" color="primary">
                    OK
                  </Button>
                </ButtonRow>
              </Warning>
            ) : undefined
          }
          onPrint={handlePrint}
          print={isPrint}
          printable
        />
      </Paper>
    </Wrapper>
  );
};

export default YearEndPlanning;

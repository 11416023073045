import { Err, Ok, Result } from 'ts-results';
import { isBlobValid } from './fileValidation';

export const downloadFile = (url: string, fileName: string) => {
  const a = window.document.createElement('a');
  a.download = fileName;
  a.href = url;
  window.document.body.appendChild(a);
  a.click();
  window.document.body.removeChild(a);
};

export const fetchBlobAndValidate = async (
  url: string
): Promise<
  Result<{ blob: Blob; isValid: boolean }, { blob: null; isValid: null }>
> => {
  try {
    const response = await fetch(url, {
      method: 'GET',
    });
    if (response.ok) {
      const blob = await response.blob();
      const isValid = await isBlobValid(blob);
      return Ok({ blob, isValid });
    }
  } catch (error) {
    console.log(error);
  }
  return Err({ blob: null, isValid: null });
};

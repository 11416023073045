import React from 'react';
import { useSelector } from 'redux/reducers';

import OrganisationPage from '_organization/components/pages/Organisation';
import { OrganisationServiceProvider } from '_organization/hooks/userOrganisationService';
import config from '_shared/services/config';
import Checklist from '_organization/components/organisms/Checklist';
import Settings from '_organization/components/organisms/Settings';
import GeneralContainer from './general';
import MembersContainer from './members';
import ClientsContainer from './clients';
import PaymentDetailsContainer from './payment-details';
import LicensesContainer from './licenses';

/**
 * Uses component props for composing the layout.
 */
const Container = () => {
  const isAdmin =
    useSelector((state) => state.user.roles)?.includes('OrganisationAdmin') ||
    false;
  const isFortnoxMode = config.whiteLabelUI === 'fortnox';

  return (
    <OrganisationPage
      isAdmin={isAdmin}
      isFortnoxMode={isFortnoxMode}
      GeneralComponent={
        <OrganisationServiceProvider>
          <GeneralContainer />
        </OrganisationServiceProvider>
      }
      SettingsComponent={<Settings />}
      MembersComponent={<MembersContainer />}
      ClientsComponent={<ClientsContainer />}
      PaymentDetailsComponent={<PaymentDetailsContainer />}
      LicensesComponent={<LicensesContainer />}
      ChecklistComponent={<Checklist />}
    />
  );
};

export default Container;

import { AgoyDocumentStructure } from '../document';
import updateDocument from './helpers/updateDocument';
import updateRow from './helpers/updateRow';
import { State } from './types';
import { isReferenceCell, ReferenceCell } from '../../Cell';

export const isActive = (
  value: boolean | ReferenceCell | undefined
): boolean | undefined => {
  if (typeof value === 'object' && isReferenceCell(value)) {
    if (typeof value.value !== 'boolean') return true;
    return value.value;
  }

  return value;
};

const toggleTableRowActive = <T extends AgoyDocumentStructure>(
  structure: T,
  state: State<T>,
  id: string
) => {
  return updateDocument(structure, state, id, {
    table: (key, tableId, props) => {
      if (key) {
        const updater = updateRow(key, (row, change) => {
          const active =
            isActive(row.active) === undefined ? false : !isActive(row.active);

          return [
            { ...row, active },
            { ...change, row: { ...change.row, active } },
          ];
        });
        const result = updater(props.node, props.changes || { type: 'update' });

        if (Array.isArray(result)) {
          return {
            ...props,
            node: result[0],
            changes: result[1],
          };
        }
      }
      return props;
    },
  });
};

export default toggleTableRowActive;

import { Err } from 'ts-results';
import { asResultClass, getApiSdk, isApiErrorType } from 'api-sdk';
import { readBuffer } from 'utils/sie-reader/sie-reader';
import { NotificationService } from '_shared/services/Notifications/types';
import {
  AccountingBalancesDataLayer,
  REQUIRE_IMMIDIATE_UPDATE,
} from '../types';
import waitForSieImport from './utils/waitForSieImport';

const uploadSieFile = async (
  sdk: ReturnType<typeof getApiSdk>,
  notificationService: NotificationService | null,
  clientId: string,
  file: Buffer
): ReturnType<AccountingBalancesDataLayer['uploadSieFile']> => {
  const sieFile = readBuffer(file.slice(0, 2_048));
  const year = sieFile.list('rar').find((y) => y.årsnr === '0');
  const orgNumber = sieFile.list('orgnr')[0]?.orgnr;

  if (!year || !orgNumber) {
    return Err({
      errorCode: 'INVALID_SIE_FILE',
      errorDetails: {},
    });
  }

  const uploadResult = await asResultClass(
    sdk.putSieFile({
      clientid: clientId,
      financialYear: `${year.start}-${year.slut}`,
      orgNumber,
      requestBody: new Blob([file]),
    })
  );

  if (uploadResult.err) {
    if (isApiErrorType(uploadResult.val)) {
      return Err({
        errorCode: uploadResult.val.body.code || 'SIE_IMPORT_FAILED',
        errorDetails: {
          message: uploadResult.val.body.message,
        },
      });
    }
    return Err({
      errorCode: 'SIE_IMPORT_FAILED',
      errorDetails: {},
    });
  }

  if (uploadResult.val.jobId && notificationService !== null) {
    // Sie import is running, wait for the ok or error to return that.
    // The refresh of accounting balances will be done elsewhere.
    return waitForSieImport(
      notificationService,
      clientId,
      uploadResult.val.jobId
    );
  }
  // Update the accounting balances the old way.
  return Err({
    errorCode: REQUIRE_IMMIDIATE_UPDATE,
    errorDetails: { financialYear: `${year.start}-${year.slut}` },
  });
};

export default uploadSieFile;

const helpStructureK6 = {
  type: 'part' as const,
  _type: 'K6' as const,
  children: {
    improvements: { type: 'table' as const },
    renovation: { type: 'table' as const },
    postponementAmount: { type: 'table' as const },
    postponementHelper: { type: 'table' as const },
  },
};

export default helpStructureK6;

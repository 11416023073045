import React from 'react';
import ReactGA4 from 'react-ga4';
import config from '_shared/services/config';
import { GA4 } from 'react-ga4/types/ga4';

interface GAContextProps {
  ga4: GA4;
}

export const GAContext = React.createContext({} as GAContextProps);

export const GAProductionEnvironment = 'production';

const withGA = (Component) => (props) => {
  // Google analytics is only used in production environment
  if (config.runningEnvironment === 'production') {
    ReactGA4.initialize('G-XZ3VK2F80N');
  }
  const value = { ga4: ReactGA4 };

  return (
    <GAContext.Provider value={value}>
      <Component {...props} />
    </GAContext.Provider>
  );
};

export default withGA;

import { table, value } from '@agoy/document';
import { IxbrlCell, NoteOneTableSection } from '../../../../common';
import {
  active,
  field,
  ixbrlCell,
  ixbrlField,
  tupleRef,
} from '../../../../common/utils/util';
import { previousYear, year } from '../../../../common/utils/date-util';

export const getNote97 = (
  financialYear: string,
  financialYears: string[],
  period: string | null,
  documentTypeVersion = '1'
): NoteOneTableSection => {
  return {
    active: false,
    number: value(undefined),
    name: value('Tillgångar, avsättningar och skulder som avser flera poster'),
    table: table<IxbrlCell>(
      `notes.note97.table`,
      {
        id: 'typeOfAssetProvisionOrLiability',
        label: 'Typ av tillgång, avsättning eller skuld',
      },
      {
        id: 'balanceSheetItem',
        label: 'Typ av balanspost',
      },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      .addRows((rows) =>
        rows
          .addRow(
            '1',
            ixbrlCell(value(''), {
              type: 'stringItemType',
              name: 'se-gen-base:TillgangarAvsattningarSkulderPost',
              contextRef: 'balans0',
              previousYearTuple: true,
              standardRubrik:
                'Typ av tillgång, avsättning eller skuld som avser flera poster',
              tuple: {
                name: 'se-gaap-ext:TillgangarAvsattningarSkulderTuple',
                id: `${rows.getBaseId()}.1.tuple`,
                tupleID: `${rows.getBaseId()}.1.tuple`,
              },
              tupleRef: tupleRef(1, `${rows.getBaseId()}.1.tuple`),
            }),
            ixbrlCell(value(''), {
              type: 'stringItemType',
              name: 'se-gen-base:TillgangarAvsattningarSkulderBalanspost',
              contextRef: 'balans0',
              standardRubrik:
                'Balanspost där tillgång, avsättning eller skuld som avser flera poster redovisas',
              tupleRef: tupleRef(2, `${rows.getBaseId()}.1.tuple_balans0`),
            }),
            ixbrlCell(value(undefined), {
              type: 'monetaryItemType',
              name: 'se-gen-base:TillgangarAvsattningarSkulderPerTyp',
              contextRef: 'balans0',
              standardRubrik:
                'Belopp per balanspost där tillgång, (avsättning/skuld) som avser flera poster redovisas',
              tupleRef: tupleRef(3, `${rows.getBaseId()}.1.tuple_balans0`),
            }),
            ixbrlCell(value(undefined), {
              type: 'monetaryItemType',
              name: 'se-gen-base:TillgangarAvsattningarSkulderPerTyp',
              contextRef: 'balans1',
              standardRubrik:
                'Belopp per balanspost där tillgång, (avsättning/skuld) som avser flera poster redovisas',
              tupleRef: tupleRef(4, `${rows.getBaseId()}.1.tuple_balans1`),
            })
          )
          .build()
      )
      .newRowTemplateGenerator((id, baseId) => ({
        id,
        active: true,
        cells: {
          typeOfAssetProvisionOrLiability: ixbrlCell(value(''), {
            type: 'stringItemType',
            name: 'se-gen-base:TillgangarAvsattningarSkulderPost',
            contextRef: 'balans0',
            previousYearTuple: true,
            standardRubrik:
              'Typ av tillgång, avsättning eller skuld som avser flera poster',
            tuple: {
              name: 'se-gaap-ext:TillgangarAvsattningarSkulderTuple',
              id: `${baseId}.${id}.tuple`,
              tupleID: `${baseId}.${id}.tuple`,
            },
            tupleRef: tupleRef(1, `${baseId}.${id}.tuple`),
          }),
          balanceSheetItem: ixbrlCell(value(''), {
            type: 'stringItemType',
            contextRef: 'balans0',
            name: 'se-gen-base:TillgangarAvsattningarSkulderBalanspost',
            standardRubrik:
              'Balanspost där tillgång, avsättning eller skuld som avser flera poster redovisas',
            tupleRef: tupleRef(2, `${baseId}.${id}.tuple_balans0`),
          }),
          year: ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base:TillgangarAvsattningarSkulderPerTyp',
            contextRef: 'balans0',
            standardRubrik:
              'Belopp per balanspost där tillgång, (avsättning/skuld) som avser flera poster redovisas',
            tupleRef: tupleRef(3, `${baseId}.${id}.tuple_balans0`),
          }),
          previousYear: ixbrlCell(value(undefined), {
            type: 'monetaryItemType',
            name: 'se-gen-base:TillgangarAvsattningarSkulderPerTyp',
            contextRef: 'balans1',
            standardRubrik:
              'Belopp per balanspost där tillgång, (avsättning/skuld) som avser flera poster redovisas',
            tupleRef: tupleRef(4, `${baseId}.${id}.tuple_balans1`),
          }),
        },
      }))
      .build(),
    commentLabel: field('Kommentar till not'),
    comment: ixbrlField(active(field('')), {
      type: 'stringItemType',
      name: 'se-gen-base:NotTillgangarAvsattningarSkuldeKommentar',
      contextRef: 'balans0',
      standardRubrik:
        'Kommentar till specifikation av tillgångar, avsättningar och skulder som avser flera poster',
    }),
    group: value(undefined),
    section: value(undefined),
    validForReportType: {
      k2: false,
      k3: documentTypeVersion !== '1',
    },
  };
};

import React from 'react';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';
import { Typography } from '@material-ui/core';

import { FinancialReport } from '@agoy/financial-report-document';
import { stringValue } from '@agoy/document';
import RichEditor from '_shared/components/RichEditor';

import OrganisationLogo from '../../UI/OrganisationLogo';
import { ReportColors } from '../../../../utils';

type ReportColorsProps = {
  customColor: string | undefined;
};

const Container = styled('div', {
  shouldForwardProp: (prop) => prop !== 'customColor',
})<ReportColorsProps>`
  height: 90%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: auto;
  padding-top: ${({ theme }) => theme.spacing(2)}px;
  padding-left: ${({ theme }) => theme.spacing(2)}px;
`;

const A4Page = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 960px;
  width: 1400px;
`;

const SmallTitle = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'customColor',
})<ReportColorsProps>`
  font-size: 35px;
  line-height: 38px;
  margin-bottom: ${(props) => props.theme.spacing(1)}px;
  color: ${(props) => props.customColor || props.theme.palette.text.primary};
`;
interface AdditionalTextProps {
  additionalText: FinancialReport['additionalText'];
  reportColors: ReportColors;
  pageId: string;
}

const AdditionalTextPreview = ({
  additionalText,
  reportColors,
  pageId,
}: AdditionalTextProps): JSX.Element | null => {
  const { formatMessage } = useIntl();

  let title =
    stringValue(additionalText.section.text1Title) ||
    formatMessage({ id: 'financialreport.additionalTextOne' });
  let defaultText = stringValue(additionalText.section.text1) || ' ';
  if (pageId === 'page2') {
    title =
      stringValue(additionalText.section.text2Title) ||
      formatMessage({ id: 'financialreport.additionalTextTwo' });
    defaultText = stringValue(additionalText.section.text2) || ' ';
  }

  return (
    <A4Page>
      <SmallTitle customColor={reportColors.textMain}>{title}</SmallTitle>
      <Container customColor={reportColors.background}>
        <RichEditor
          value={defaultText}
          isEditing={false}
          height="100%"
          width="100%"
        />
      </Container>
      <OrganisationLogo print />
    </A4Page>
  );
};

export default AdditionalTextPreview;

import React, { useContext, useEffect, useState } from 'react';
import { Checkbox, Typography as MuiTypography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import DocumentViewServiceContext from '_shared/services/document/DocumentViewServiceContext';
import styled from '@emotion/styled';
import Paragraph from './Paragraph';

const Typography = styled(MuiTypography)<{ print?: boolean }>`
  font-size: 1rem;
  font-weight: 400;
  font-family: inherit;
`;

const FeeCheckBoxContainer = styled.div`
  display: flex;
  align-items: center;
`;

const feeOptions = ['no', 'accountant'];
interface FeeProps {
  no: number;
  fee: string;
  heading: string | undefined;
  text: string | undefined;
  print?: boolean;
  isPreview?: boolean;
  editable?: boolean;
}

const Fee = ({
  no,
  fee,
  heading,
  text,
  print,
  isPreview,
  editable,
}: FeeProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const service = useContext(DocumentViewServiceContext);

  const noText = formatMessage({
    id: `annualReport.annualGeneralMeeting.fee.option.no`,
  });
  const accountantText = formatMessage({
    id: `annualReport.annualGeneralMeeting.fee.option.accountant`,
  });

  const [noState, setNoState] = useState<boolean>(fee.includes(noText));
  const [accountantState, setAccountantState] = useState<boolean>(
    fee.includes(accountantText)
  );

  useEffect(() => {
    setNoState(fee.includes(noText));
    setAccountantState(fee.toLocaleLowerCase().includes(accountantText));
  }, [fee]);

  const handleChange = (noStateArg: boolean, accountantArg: boolean) => {
    const value = `${noStateArg ? noText : ''} ${
      accountantArg
        ? noStateArg
          ? `${accountantText[0].toLocaleUpperCase()}${accountantText.slice(1)}`
          : accountantText
        : ''
    }`;
    service.updateField('annualGeneralMeeting.main.fee', value);
  };

  const handleNoState = (event) => {
    setNoState(event.target.checked);
    handleChange(event.target.checked, accountantState);
  };

  const handleAccountantState = (event) => {
    setAccountantState(event.target.checked);
    handleChange(noState, event.target.checked);
  };

  return (
    <>
      {print && (
        <>
          <Paragraph
            no={no}
            id="10"
            headingId="annualGeneralMeeting.main.feeHeading"
            heading={
              heading ||
              formatMessage({
                id: `annualReport.annualGeneralMeeting.main.10`,
              })
            }
            optionalComment
            defaultText={formatMessage({
              id: `comment`,
            })}
            textId="annualGeneralMeeting.main.feeHeadingText"
            text={text}
            isPreview={isPreview}
          />

          {(noState || accountantState) && (
            <Typography>
              {formatMessage({
                id: 'annualReport.annualGeneralMeeting.main.10.text',
              })}
              {fee}
            </Typography>
          )}
        </>
      )}

      {!print && (
        <>
          <Paragraph
            no={no}
            id="10"
            headingId="annualGeneralMeeting.main.feeHeading"
            heading={
              heading ||
              formatMessage({
                id: `annualReport.annualGeneralMeeting.main.10`,
              })
            }
            optionalComment
            defaultText={formatMessage({
              id: `comment`,
            })}
            textId="annualGeneralMeeting.main.feeHeadingText"
            text={text}
            editable={!print && editable}
            isPreview={isPreview}
          />

          <Typography>
            {formatMessage({
              id: 'annualReport.annualGeneralMeeting.main.10.text',
            })}
          </Typography>
          <FeeCheckBoxContainer>
            <Checkbox
              checked={noState}
              onChange={handleNoState}
              disabled={!editable}
            />
            <Typography>
              {formatMessage({
                id: `annualReport.annualGeneralMeeting.fee.option.${feeOptions[0]}`,
              })}
            </Typography>
          </FeeCheckBoxContainer>
          <FeeCheckBoxContainer>
            <Checkbox
              checked={accountantState}
              onChange={handleAccountantState}
              disabled={!editable}
            />
            <Typography>
              {formatMessage({
                id: `annualReport.annualGeneralMeeting.fee.option.${feeOptions[1]}`,
              })}
            </Typography>
          </FeeCheckBoxContainer>
        </>
      )}
    </>
  );
};

export default Fee;

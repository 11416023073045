const sortByProp = (list: any[], value: string, property: string) => {
  if (
    !list.length ||
    list.length < 2 ||
    list.some((item) => !item.hasOwnProperty(property))
  ) {
    return list;
  }
  switch (value.toLowerCase()) {
    case 'date': {
      return list.sort(
        (a, b) =>
          new Date(b[property]).getTime() - new Date(a[property]).getTime()
      );
    }
    case 'name': {
      return list.sort((a, b) => a[property].localeCompare(b[property]));
    }
    case 'number': {
      return list.sort((a, b) => a[property] - b[property]);
    }
    default: {
      return list;
    }
  }
};

export default sortByProp;

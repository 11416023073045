import { ReferenceAccountInformation, label, value } from '@agoy/document';
import {
  Note28Section,
  NoteInputFieldSection,
  NoteInvertedTwoTableSection,
  NoteOneTableSection,
  NoteOneTableWithSum,
  NoteSixTableSection,
  NoteThreeCommentTwoTableSection,
  NoteTwoTableSectionWithSum,
  NoteFourTablesWithCommentsAndSum,
  NoteFiveTableTwoCommentSection,
  NoteTwoTableSectionWithTwoSum,
  Note95,
  Note100,
  NoteFourTablesWithCaptionsCommentsAndSum,
} from '../../../../common';
import { AnnualReportType } from '../../../../document';

import { getNote10 } from '../Notes/note10';
import { getNote11 } from '../Notes/note11';
import { getNote12 } from '../Notes/note12';
import { getNote13 } from '../Notes/note13';
import { getNote14 } from '../Notes/note14';
import { getNote15 } from '../Notes/note15';
import { getNote16 } from '../Notes/note16';
import { getNote17 } from '../Notes/note17';
import { getNote18 } from '../Notes/note18';
import { getNote19 } from '../Notes/note19';
import { getNote20 } from '../Notes/note20';
import { getNote21 } from '../Notes/note21';
import { getNote22 } from '../Notes/note22';
import { getNote23 } from '../Notes/note23';
import { getNote24 } from '../Notes/note24';
import { getNote25 } from '../Notes/note25';
import { getNote26 } from '../Notes/note26';
import { getNote27 } from '../Notes/note27';
import { getNote28 } from '../Notes/note28';
import { getNote29 } from '../Notes/note29';
import { getNote30 } from '../Notes/note30';
import { getNote31 } from '../Notes/note31';
import { getNote32 } from '../Notes/note32';
import { getNote33 } from '../Notes/note33';
import { getNote34 } from '../Notes/note34';
import { getNote40 } from '../Notes/note40';
import { getNote41 } from '../Notes/note41';
import { getNote42 } from '../Notes/note42';
import { getNote43 } from '../Notes/note43';
import { getNote44 } from '../Notes/note44';
import { getNote58 } from '../Notes/note58';
import { getNote6 } from '../Notes/note6';
import { getNote65 } from '../Notes/note65';
import { getNote66 } from '../Notes/note66';
import { getNote69 } from '../Notes/note69';
import { getNote7 } from '../Notes/note7';
import { getNote8 } from '../Notes/note8';
import { getNote9 } from '../Notes/note9';
import { getNote70 } from '../k3-notes/note70';
import { getNote71 } from '../k3-notes/note71';
import { getNote72 } from '../k3-notes/note72';
import { getNote73 } from '../k3-notes/note73';
import { getNote75 } from '../k3-notes/note75';
import { getNote76 } from '../k3-notes/note76';
import { getNote77 } from '../k3-notes/note77';
import { getNote78 } from '../k3-notes/note78';
import { getNote82 } from '../k3-notes/note82';
import { getNote83 } from '../k3-notes/note83';
import { getNote87 } from '../k3-notes/note87';
import { getNote92 } from '../k3-notes/note92';
import { getNote94 } from '../k3-notes/note94';
import { getNote95 } from '../k3-notes/note95';
import { getNote96 } from '../k3-notes/note96';
import { getNote97 } from '../k3-notes/note97';
import { getNote98 } from '../k3-notes/note98';
import { getNote99 } from '../k3-notes/note99';
import { getNote100 } from '../k3-notes/note100';
import { getNote401 } from '../automated-k3-notes/note401';
import { getNote402 } from '../automated-k3-notes/note402';

export interface BalanceSheetNotes {
  note43: NoteOneTableSection;
  note6: NoteOneTableSection;
  note7: NoteOneTableSection;
  note8: NoteOneTableSection;
  note9: NoteOneTableSection;
  note10: NoteOneTableSection;
  note11: NoteOneTableSection;
  note12: NoteOneTableSection;
  note13: NoteOneTableSection;
  note14: NoteOneTableSection;
  note15: NoteOneTableSection;
  note16: NoteInvertedTwoTableSection;
  note17: NoteOneTableSection;
  note18: NoteInvertedTwoTableSection;
  note19: NoteOneTableSection;
  note20: NoteInvertedTwoTableSection;
  note21: NoteOneTableSection;
  note22: NoteOneTableSection;
  note23: NoteOneTableSection;
  note24: NoteOneTableSection;
  note25: NoteOneTableSection;
  note26: NoteOneTableWithSum;
  note27: NoteOneTableSection;
  note28: Note28Section;
  note29: NoteOneTableWithSum;
  note30: NoteOneTableWithSum;
  note31: NoteOneTableWithSum;
  note32: NoteOneTableWithSum;
  note33: NoteOneTableWithSum;
  note34: NoteOneTableSection;
  note40: NoteOneTableSection;
  note41: NoteOneTableSection;
  note42: NoteOneTableSection;
  note44: NoteOneTableSection;
  note58: NoteOneTableSection;
  note65: NoteInputFieldSection;
  note66: NoteOneTableWithSum;
  note69: NoteInputFieldSection;
  note70: NoteThreeCommentTwoTableSection;
  note71: NoteOneTableSection;
  note72: NoteTwoTableSectionWithSum;
  note73: NoteOneTableSection;
  note76: NoteInvertedTwoTableSection;
  note75: NoteSixTableSection;
  note77: NoteFourTablesWithCommentsAndSum;
  note78: NoteOneTableSection;
  note82: NoteTwoTableSectionWithSum;
  note83: NoteOneTableWithSum;
  note87: NoteOneTableSection;
  note92: NoteOneTableSection;
  note94: NoteOneTableSection;
  note95: Note95;
  note96: NoteFiveTableTwoCommentSection;
  note97: NoteOneTableSection;
  note98: NoteTwoTableSectionWithTwoSum;
  note99: NoteFourTablesWithCaptionsCommentsAndSum;
  note100: Note100;
  note401: NoteThreeCommentTwoTableSection;
  note402: NoteOneTableSection;
}

export const getSharesCompanyBalanceSheetNotes = (
  accounts: Record<string, ReferenceAccountInformation>,
  reportType: AnnualReportType,
  financialYears: string[],
  financialYear: string,
  period: string | null,
  isDigitalSubmission: boolean,
  documentTypeVersion = '1'
): BalanceSheetNotes => {
  const noteLabel = (name: string) =>
    isDigitalSubmission ? label(name) : value(name);

  return {
    note43: getNote43(
      noteLabel,
      financialYear,
      financialYears,
      period,
      documentTypeVersion
    ),
    note6: getNote6(
      noteLabel,
      financialYear,
      financialYears,
      period,
      documentTypeVersion
    ),
    note7: getNote7(noteLabel, financialYear, financialYears, period),
    note8: getNote8(noteLabel, financialYear, financialYears, period),
    note9: getNote9(
      noteLabel,
      financialYear,
      financialYears,
      period,
      documentTypeVersion
    ),
    note10: getNote10(noteLabel, financialYear, financialYears, period),
    note11: getNote11(
      noteLabel,
      financialYear,
      financialYears,
      period,
      documentTypeVersion
    ),
    note12: getNote12(
      noteLabel,
      financialYear,
      financialYears,
      period,
      documentTypeVersion
    ),
    note13: getNote13(
      noteLabel,
      financialYear,
      financialYears,
      period,
      documentTypeVersion
    ),
    note14: getNote14(
      noteLabel,
      financialYear,
      financialYears,
      period,
      documentTypeVersion
    ),
    note15: getNote15(
      noteLabel,
      financialYear,
      financialYears,
      period,
      documentTypeVersion
    ),
    note16: getNote16(
      noteLabel,
      financialYear,
      financialYears,
      period,
      documentTypeVersion
    ),
    note17: getNote17(noteLabel, financialYear, financialYears, period),
    note18: getNote18(
      reportType,
      noteLabel,
      financialYear,
      financialYears,
      period,
      documentTypeVersion
    ),
    note19: getNote19(noteLabel, financialYear, financialYears, period),
    note20: getNote20(
      noteLabel,
      financialYear,
      financialYears,
      period,
      documentTypeVersion
    ),
    note21: getNote21(noteLabel, financialYear, financialYears, period),
    note22: getNote22(
      noteLabel,
      financialYear,
      financialYears,
      period,
      documentTypeVersion
    ),
    note23: getNote23(noteLabel, financialYear, financialYears, period),
    note24: getNote24(noteLabel, financialYear, financialYears, period),
    note25: getNote25(
      noteLabel,
      financialYear,
      financialYears,
      period,
      documentTypeVersion
    ),
    note26: getNote26(noteLabel, financialYear, financialYears, period),
    note27: getNote27(
      noteLabel,
      financialYear,
      financialYears,
      period,
      documentTypeVersion
    ),
    note28: getNote28(
      noteLabel,
      financialYear,
      financialYears,
      period,
      documentTypeVersion
    ),
    note29: getNote29(
      noteLabel,
      financialYear,
      financialYears,
      period,
      documentTypeVersion
    ),
    note30: getNote30(
      noteLabel,
      financialYear,
      financialYears,
      period,
      documentTypeVersion
    ),
    note31: getNote31(
      noteLabel,
      financialYear,
      financialYears,
      period,
      documentTypeVersion
    ),
    note32: getNote32(
      noteLabel,
      financialYear,
      financialYears,
      period,
      documentTypeVersion
    ),
    note33: getNote33(
      noteLabel,
      financialYear,
      financialYears,
      period,
      documentTypeVersion
    ),
    note34: getNote34(
      noteLabel,
      financialYear,
      financialYears,
      period,
      documentTypeVersion
    ),
    note40: getNote40(
      noteLabel,
      financialYear,
      financialYears,
      period,
      documentTypeVersion
    ),
    note41: getNote41(noteLabel, documentTypeVersion),
    note42: getNote42(
      noteLabel,
      financialYear,
      financialYears,
      period,
      documentTypeVersion
    ),
    note44: getNote44(documentTypeVersion),
    note58: getNote58(
      noteLabel,
      financialYear,
      financialYears,
      period,
      documentTypeVersion
    ),
    note65: getNote65(),
    note66: getNote66(
      noteLabel,
      financialYear,
      financialYears,
      period,
      documentTypeVersion
    ),
    note69: getNote69(),
    // Here starts the notes added for K3 digital submission
    note70: getNote70(noteLabel, financialYear, financialYears, period),
    note71: getNote71(noteLabel, financialYear, financialYears, period),
    note72: getNote72(noteLabel, financialYear, financialYears, period),
    note73: getNote73(
      noteLabel,
      financialYear,
      financialYears,
      period,
      documentTypeVersion
    ),
    note75: getNote75(noteLabel, financialYear, financialYears, period),
    note76: getNote76(noteLabel, financialYear, financialYears, period),
    note77: getNote77(
      noteLabel,
      financialYear,
      financialYears,
      period,
      accounts
    ),
    note78: getNote78(
      noteLabel,
      financialYear,
      financialYears,
      period,
      documentTypeVersion
    ),
    note82: getNote82(noteLabel, financialYear, financialYears, period),
    note83: getNote83(
      noteLabel,
      financialYear,
      financialYears,
      period,
      documentTypeVersion
    ),
    note87: getNote87(
      noteLabel,
      financialYear,
      financialYears,
      period,
      documentTypeVersion
    ),
    note92: getNote92(
      noteLabel,
      financialYear,
      financialYears,
      period,
      documentTypeVersion
    ),
    note94: getNote94(
      noteLabel,
      financialYear,
      financialYears,
      period,
      documentTypeVersion
    ),
    note95: getNote95(noteLabel, financialYear, financialYears, period),
    note96: getNote96(noteLabel, financialYear, financialYears, period),
    note97: getNote97(
      financialYear,
      financialYears,
      period,
      documentTypeVersion
    ),
    note98: getNote98(noteLabel, documentTypeVersion),
    note99: getNote99(
      noteLabel,
      financialYear,
      financialYears,
      period,
      documentTypeVersion
    ),
    note100: getNote100(noteLabel, financialYear, financialYears, period),
    note401: getNote401(
      noteLabel,
      financialYear,
      financialYears,
      period,
      documentTypeVersion
    ),
    note402: getNote402(
      noteLabel,
      financialYear,
      financialYears,
      period,
      documentTypeVersion
    ),
  };
};

import React, { createContext, useState, useEffect, useCallback } from 'react';
import { ApiReturnType, asResultClass, useApiSdk } from 'api-sdk';
import {
  getStoredMessages,
  removeMessage,
  storeMessage,
} from '_shared/components/SystemMessage/messagesLocalStorage';
import { filterMessages } from '_shared/components/SystemMessage/utils';

import config from '_shared/services/config';

type MessagesType = ApiReturnType<'getSystemMessages'>;

type SystemMessagesContextType = {
  messages: MessagesType;
  onCloseMessage: (messageId: number) => void;
};

export const SystemMessagesContext = createContext<SystemMessagesContextType>(
  {} as SystemMessagesContextType
);

export const SystemMessagesContextProvider = ({ children }) => {
  const sdk = useApiSdk();

  const [messages, setMessages] = useState<MessagesType>([]);

  /**
   * Loads system messages from server and checks in LS if the user has dismissed
   * any of those messages, if so we shouldn't display them again
   */
  const loadMessages = useCallback(async () => {
    const result = await asResultClass(sdk.getSystemMessages());

    if (result.ok) {
      const serverMessages = result.val;
      const serverMessageIds = serverMessages.map((sysMsg) => sysMsg.id);

      // get viewed messages stored in LS
      const lsMessageIds = getStoredMessages();

      // if any of the messages sent from server is not in
      // LS, remove them from LS
      lsMessageIds.forEach((id) => {
        if (!serverMessageIds.includes(id)) {
          removeMessage(id);
        }
      });

      setMessages(
        serverMessages.filter(filterMessages(config.whiteLabel, lsMessageIds))
      );
    }
  }, [sdk]);

  useEffect(() => {
    loadMessages();
  }, [loadMessages]);

  const onCloseMessage = (messageId: number) => {
    storeMessage(messageId);
    setMessages(messages.filter((msg) => msg.id !== messageId));
  };

  return (
    <SystemMessagesContext.Provider value={{ messages, onCloseMessage }}>
      {children}
    </SystemMessagesContext.Provider>
  );
};

import {
  endOfMonth,
  endOfQuarter,
  isSameMonth,
  parseISO,
  startOfMonth,
  startOfQuarter,
} from 'date-fns';
import { ISODateInterval } from './createPeriods';
import { formatISODate } from '@agoy/dates';

/**
 * Finds a quarter, month or financial year that contains a period.
 *
 * If the first financial year starts inside the quart or month, only a
 * partial time interval inside the financial years is returned. The same goes
 * for the last financial year. This means that the time interval may be
 * partial is always within the financial years range.
 *
 * @param closingPeriod Closing period of a client
 * @param period The period to expand, must be a month or shorter.
 * @param financialYears The financial years to match with.
 * @returns A matching full or partial time interval if found, otherwise null
 */
export const getMatchingTimePeriod = (
  closingPeriod: 'year' | 'quarter' | 'month',
  period: ISODateInterval,
  financialYears: ISODateInterval[]
): ISODateInterval | null => {
  if (financialYears.length === 0) {
    return null;
  }

  const periodStart = parseISO(period.start);
  const periodEnd = parseISO(period.end);

  if (!isSameMonth(periodStart, periodEnd)) {
    throw new Error('Period start and end must be within same month');
  }

  if (closingPeriod === 'year') {
    return (
      financialYears.find(
        (y) => y.start <= period.start && y.end >= period.end
      ) ?? null
    );
  }

  const sortedFinancialYears = [...financialYears].sort((a, b) =>
    a.start < b.start ? -1 : 1
  );

  const fullTimePeriod = {
    start: formatISODate(
      (closingPeriod === 'quarter' ? startOfQuarter : startOfMonth)(periodStart)
    ),
    end: formatISODate(
      (closingPeriod === 'quarter' ? endOfQuarter : endOfMonth)(periodEnd)
    ),
  };

  const firstYear = sortedFinancialYears[0];
  const lastYear = sortedFinancialYears[sortedFinancialYears.length - 1];

  if (fullTimePeriod.end < firstYear.start) {
    return null;
  }

  if (fullTimePeriod.start > lastYear.end) {
    return null;
  }

  return {
    start:
      fullTimePeriod.start < firstYear.start
        ? firstYear.start
        : fullTimePeriod.start,
    end: fullTimePeriod.end > lastYear.end ? lastYear.end : fullTimePeriod.end,
  };
};

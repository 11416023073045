import React from 'react';
import { useIntl } from 'react-intl';
import styled from '@emotion/styled';
import { DefinitionOfKey as DefinitionOfKeyType } from '@agoy/annual-report-document';
import { Heading, Section as BaseSection } from '../../../../UI';
import CheckboxTable from '../../../NotesPage/UI/CheckboxTable';

const Section = styled(BaseSection)`
  width: 100%;
`;

interface DefinitionOfKeyProps {
  reportPart: DefinitionOfKeyType;
  title: string | undefined;
}

const DefinitionOfKey = ({
  reportPart,
  title,
}: DefinitionOfKeyProps): JSX.Element => {
  const { formatMessage } = useIntl();

  const { definitionOfKeyTable } = reportPart;
  const titleValue =
    title ||
    formatMessage({
      id: 'annualReport.menu.managementReport.definitionOfKey',
    });

  return (
    <Section>
      <Heading title={titleValue} />

      <CheckboxTable
        tableId="managementReport.definitionOfKey.definitionOfKeyTable"
        table={definitionOfKeyTable}
      />
    </Section>
  );
};

export default DefinitionOfKey;

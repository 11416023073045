import { AgoyDocument, AgoyDocumentChanges } from '@agoy/document';
import { balanceSheet } from '../common/types/balanceSheet';
import { confirmationCertificate } from '../common/types/confirmationCertificate';
import { incomeStatement } from '../common/types/incomeStatement';
import { manualSubmission } from '../common/types/manualSubmission';
import { settings } from '../common/types/settings';
import { signatures } from '../common/types/signatures';
import { economicAssociationNotes } from './notes/types';
import { economicAssociationManagementReport } from './management-report/types';

export const economicAssociationContentDefinition = {
  type: 'document' as const,
  children: {
    settings,
    confirmationCertificate,
    managementReport: economicAssociationManagementReport,
    incomeStatement,
    balanceSheet,
    notes: economicAssociationNotes,
    manualSubmission,
    signatures,
  },
  documentType: 'economic_association' as const,
  version: '1' as const,
};

export const economicAssociationIncomeStatementNotes = [
  'note1',
  'note2',
  'note3',
  'note4',
  'note5',
  'note45',
  'note48',
];

export const economicAssociationBalanceSheetNotes = [
  'note43',
  'note6',
  'note7',
  'note8',
  'note9',
  'note10',
  'note11',
  'note12',
  'note13',
  'note14',
  'note15',
  'note16',
  'note17',
  'note18',
  'note19',
  'note20',
  'note21',
  'note22',
  'note23',
  'note24',
  'note25',
  'note26',
  'note27',
  'note28',
  'note29',
  'note30',
  'note31',
  'note32',
  // 40-42 and 44 come after 32 and before 33
  'note40',
  'note41',
  'note42',
  'note44',
  'note46',
  'note47',
];

export const economicAssociationOtherNotes = [
  'note33',
  'note34',
  'note35',
  'note36',
  'note37',
  'note38',
  'note39',
  'custom',
];

export type EconomicAssociationAnnualReport = AgoyDocument<
  typeof economicAssociationContentDefinition
>;

export type EconomicAssociationAnnualReportStructure =
  typeof economicAssociationContentDefinition;

export type EconomicAssociationAnnualReportChanges = AgoyDocumentChanges<
  typeof economicAssociationContentDefinition
>;

import React from 'react';
import { stringValue } from '@agoy/document';
import { DocumentConfiguration, Settings } from '@agoy/annual-report-document';
import * as IX from '../../xml/IX';
import { LinkSchemaRef } from '../../xml/Link';
import Resources from './Resources';
import styled from '@emotion/styled';

type HeaderProps = {
  settings: Settings;
  documentConfig: DocumentConfiguration;
  boardText: string | undefined;
  isXBRLRender?: boolean;
  isIndividual?: boolean;
};

const HiddenHeader = styled.div`
  display: none;
`;

const Header = ({
  settings,
  documentConfig,
  boardText,
  isXBRLRender,
  isIndividual,
}: HeaderProps): React.ReactElement | null => {
  // default option when no selection is done is just the board decided on the AR
  const boardTextSelection = (boardText: string | undefined) => {
    switch (boardText) {
      case 'boardAndCeo':
        return 'se-mem-base:FinansiellRapportStyrelsenVerkstallandeDirektorenAvgerArsredovisningMember';
      case 'liquidator':
        return 'se-mem-base:FinansiellRapportLikvidatornAvgerArsredovisningMember';
      default:
        return 'se-mem-base:FinansiellRapportStyrelsenAvgerArsredovisningMember';
    }
  };

  // Don't render this in preview mode only when creating an XBRL report
  // Switching between k2/k3 will break otherwise
  if (!isXBRLRender) {
    return null;
  }

  return (
    <HiddenHeader>
      <IX.Header>
        <IX.Hidden>
          <IX.NonNumeric
            name="se-cd-base:SprakHandlingUpprattadList"
            contextRef="period0"
          >
            se-mem-base:SprakSvenskaMember
          </IX.NonNumeric>
          <IX.NonNumeric
            name="se-cd-base:LandForetagetsSateList"
            contextRef="period0"
          >
            se-mem-base:LandSverigeMember
          </IX.NonNumeric>
          <IX.NonNumeric
            name="se-cd-base:RedovisningsvalutaHandlingList"
            contextRef="period0"
          >
            se-mem-base:ValutaSvenskaKronorMember
          </IX.NonNumeric>
          {documentConfig.reportType === 'k2' && (
            <IX.NonNumeric
              name="se-cd-base:BeloppsformatList"
              contextRef="period0"
            >
              se-mem-base:BeloppsformatNormalformMember
            </IX.NonNumeric>
          )}
          <IX.NonNumeric
            name="se-cd-base:RakenskapsarForstaDag"
            contextRef="period0"
          >
            {stringValue(settings.section.periodStart)}
          </IX.NonNumeric>
          <IX.NonNumeric
            name="se-cd-base:RakenskapsarSistaDag"
            contextRef="period0"
          >
            {stringValue(settings.section.periodEnd)}
          </IX.NonNumeric>
          {!isIndividual && (
            <IX.NonNumeric
              name="se-gen-base:FinansiellRapportList"
              contextRef="period0"
            >
              {boardTextSelection(boardText)}
            </IX.NonNumeric>
          )}
        </IX.Hidden>
        <IX.References>
          {documentConfig.reportType === 'k3' ? (
            <>
              <LinkSchemaRef
                xlinkType="simple"
                xlinkHref="http://xbrl.taxonomier.se/se/fr/gaap/coa/rplc/2020-12-01/se-coa-rplc-2020-12-01.xsd"
              />
              <LinkSchemaRef
                xlinkType="simple"
                xlinkHref="http://xbrl.taxonomier.se/se/fr/misc/race/2017-09-30/se-misc-race-2017-09-30.xsd"
              />
              {documentConfig.incomeStatement === 'functional' ? (
                <LinkSchemaRef
                  xlinkType="simple"
                  xlinkHref="http://xbrl.taxonomier.se/se/fr/gaap/k3/rfibs/2021-10-31/se-k3-rfibs-2021-10-31.xsd"
                />
              ) : (
                <LinkSchemaRef
                  xlinkType="simple"
                  xlinkHref="http://xbrl.taxonomier.se/se/fr/gaap/k3/risbs/2021-10-31/se-k3-risbs-2021-10-31.xsd"
                />
              )}
            </>
          ) : (
            <>
              <LinkSchemaRef
                xlinkType="simple"
                xlinkHref="http://xbrl.taxonomier.se/se/fr/gaap/k2/risbs/2021-10-31/se-k2-risbs-2021-10-31.xsd"
              />
              <LinkSchemaRef
                xlinkType="simple"
                xlinkHref="http://xbrl.taxonomier.se/se/fr/gaap/coa/rplc/2020-12-01/se-coa-rplc-2020-12-01.xsd"
              />
            </>
          )}
        </IX.References>
        <Resources settings={settings} documentConfig={documentConfig} />
      </IX.Header>
    </HiddenHeader>
  );
};

export default Header;

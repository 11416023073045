import { ApiError as AutogeneratedApiError } from '@agoy/api-sdk-core';

// The existing ApiError type needs to be extended, as our errors contain a types body
// as `$ref: '#/components/schemas/ErrorResponse'`
export interface ApiErrorType extends AutogeneratedApiError {
  body: {
    details?: any;
    message: string;
    code?: string;
  };

  /**
   * Marker for handled errors
   */
  handled?: boolean;
}

export function isApiErrorType(error): error is ApiErrorType {
  return (
    error &&
    error !== null &&
    typeof error === 'object' &&
    'body' in error &&
    'status' in error
  );
}

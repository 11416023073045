import { AgoyDocumentStructure } from '../document';
import updateDocument from './helpers/updateDocument';
import { OperationResult, State } from './types';

const updateMessageField = <T extends AgoyDocumentStructure>(
  structure: T,
  state: State<T>,
  id: string,
  message: string
): OperationResult<T> => {
  return updateDocument(structure, state, id, {
    field: (key, id, props) => {
      const node = props.node;
      const { type } = node;
      if (type === 'msg') {
        return {
          ...props,
          node: {
            ...props.node,
            type: 'msg',
            message,
            original: props.node.original || props.node,
            value: { error: 'notResolved' },
          },
          changes: {
            ...props.changes,
            type: 'msg',
            message,
          },
        };
      }

      return {
        ...props,
        error: 'INVALID_FIELD_TYPE',
      };
    },
  });
};

export default updateMessageField;

import React from 'react';
import { Typography } from '@material-ui/core';

type Props = {
  title: string;
  children?: React.ReactNode;
};

const Section = ({ title, children }): JSX.Element => {
  return (
    <>
      <Typography variant="h3">{title}</Typography>
      {children}
    </>
  );
};

export default Section;

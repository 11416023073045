import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  List,
  ListItem,
  Typography,
} from '@material-ui/core';
import styled from '@emotion/styled';
import { FormattedMessage } from 'react-intl';
import { colors as ThemeColors } from '@agoy/theme';
import Button from '../Buttons/Button';

const ColoredStatus = styled.span`
  color: ${(props: { statusColor: string }) => props.statusColor};
`;

type ApproveChangesDialogProps = {
  changes: { id: string; values: Record<string, string> }[];
  onCancel: () => void;
  onApproved: () => void;
};

const makeColoredStatus = (
  text: string | undefined,
  type: string | undefined
) =>
  text && type ? (
    <ColoredStatus statusColor={ThemeColors[type]}>{text}</ColoredStatus>
  ) : (
    text
  );

const ApproveChangesDialog = ({
  changes,
  onApproved,
  onCancel,
}: ApproveChangesDialogProps): JSX.Element => {
  return (
    <Dialog open maxWidth="lg">
      <DialogContent>
        <Typography variant="h3">
          För att genomföra statusändringen måste även följande ändringar ske
        </Typography>
        <List>
          {changes.map((change) => (
            <ListItem disableGutters key={change.id}>
              <Typography>
                <FormattedMessage
                  id={change.id}
                  values={{
                    ...change.values,
                    from: makeColoredStatus(
                      change.values.from,
                      change.values.fromType
                    ),
                    to: makeColoredStatus(
                      change.values.to,
                      change.values.toType
                    ),
                  }}
                />
              </Typography>
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button
          label="Genomför"
          onClick={() => {
            onApproved();
          }}
        />

        <Button
          label="Avbryt"
          variant="text"
          onClick={() => {
            onCancel();
          }}
        />
      </DialogActions>
    </Dialog>
  );
};

export default ApproveChangesDialog;

import React from 'react';
import Button from '_shared/components/Buttons/Button';
import { AddCircleOutline } from '@material-ui/icons';

interface AddNewItemProps {
  title: string;
  onClick: () => void;
}

const AddNewItem = ({ title, onClick }: AddNewItemProps) => {
  return (
    <Button
      label={title}
      onClick={onClick}
      variant="text"
      startIcon={<AddCircleOutline />}
    />
  );
};

export default AddNewItem;

import React from 'react';
import MaterialPaginator from '@material-ui/lab/Pagination';

const ELEMENTS_PER_PAGE = 10;

type Props = {
  pageNumber: number;
  totalElementCount: number;
  elementsPerPage?: number;
  handlePaginationChange: (newPageNumber) => void;
};

const Paginator = ({
  pageNumber,
  totalElementCount,
  elementsPerPage = ELEMENTS_PER_PAGE,
  handlePaginationChange,
}: Props): JSX.Element => {
  return (
    <MaterialPaginator
      count={Math.ceil(totalElementCount / elementsPerPage)}
      page={pageNumber}
      onChange={(e, newPageNumber) => handlePaginationChange(newPageNumber)}
    />
  );
};

export default Paginator;

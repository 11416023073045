import { ToggleFinancialReportField } from '_financial-report/redux/action-type-declarations';
import { FinancialReportClientPeriodState } from '../types';

const toggleFinancialReportField = (
  state: FinancialReportClientPeriodState,
  action: ToggleFinancialReportField
) => {
  const { part, section, field } = action;
  if (!state.report) {
    return state;
  }
  if (!state.report[part][section]) {
    return state;
  }
  return {
    ...state,
    report: {
      ...state.report,
      [part]: {
        ...state.report[part],
        [section]: {
          ...state.report[part][section],
          [field]: !state.report[part][section][field],
        },
      },
    },
    changes: {
      ...state.changes,
      [part]: {
        ...state.changes[part],
        [section]: {
          ...state.changes[part]?.[section],
          [field]: !state.report[part][section][field],
        },
      },
    },
  };
};
export default toggleFinancialReportField;

/* eslint-disable import/no-cycle */
import { RootState } from 'redux/reducers';
import { FinancialReport } from '@agoy/financial-report-document';
import {
  FinancialReportClientPeriodState,
  FinancialReportClientState,
} from './reducer/types';

type State = Pick<RootState, 'financialReport' | 'customerView'>;

export const selectClientPeriod = (
  state: State,
  clientId: string,
  year: string
): FinancialReportClientPeriodState | undefined =>
  state.financialReport.clients[clientId]?.periods[year];

export const currentFinancialClientState = (
  state: State
): FinancialReportClientState | undefined => {
  const { currentCustomer } = state.customerView;
  if (currentCustomer) {
    return state.financialReport.clients[currentCustomer];
  }
  return undefined;
};

export const currentFinancialState = (
  state: State
): FinancialReportClientPeriodState | undefined => {
  const { currentCustomer, currentPeriod } = state.customerView;
  if (currentCustomer && currentPeriod) {
    return selectClientPeriod(state, currentCustomer, currentPeriod);
  }
  return undefined;
};

export const currentFinancialReport = (
  state: State
): FinancialReport | undefined => {
  const { currentCustomer, currentPeriod } = state.customerView;
  if (currentCustomer && currentPeriod) {
    return selectClientPeriod(state, currentCustomer, currentPeriod)?.report;
  }
  return undefined;
};

export const currentFinancialReportByPeriod = (
  state: State,
  period: string
): FinancialReport | undefined => {
  const { currentCustomer } = state.customerView;
  if (currentCustomer && period) {
    return selectClientPeriod(state, currentCustomer, period)?.report;
  }
  return undefined;
};

import React from 'react';
import styled from '@emotion/styled';
import { Typography, Button } from '@material-ui/core';

import { useIntl } from 'react-intl';
import { PrimaryButton } from '_shared/components/Buttons';
import {
  SubscriptionPackages,
  SubscriptionPackagesQuantity,
} from '_payment/types';

const InformationWrapper = styled.div`
  margin-top: 1rem;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-bottom: ${(props) => props.theme.spacing(4)}px;

  overflow-y: auto;
`;

const SubscriptionCards = styled.div`
  margin-top: ${(props) => props.theme.spacing(2)}px;
  display: flex;
  gap: ${(props) => props.theme.spacing(4)}px;
`;

const SubscriptionCard = styled.div`
  height: 410px;
  width: 300px;

  display: flex;
  flex-direction: column;
  align-items: center;

  background: #e5e5e5;

  border-radius: ${(props) => props.theme.spacing(1)}px;
`;

const QuantityCards = styled.div`
  margin-top: ${(props) => props.theme.spacing(2)}px;

  display: flex;
  gap: ${(props) => props.theme.spacing(4)}px;
`;

const QuantityCard = styled.div`
  width: 300px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;

  background: #e5e5e5;
  border-radius: ${(props) => props.theme.spacing(1)}px;
`;

type Props = {
  subscriptions: Required<SubscriptionPackages>;
  handleCheckout: (subscriptions: SubscriptionPackagesQuantity) => void;
};

const Page = ({ subscriptions, handleCheckout }: Props) => {
  const { formatMessage } = useIntl();

  const [selectedSubscriptions, setSelectedSubscriptions] =
    React.useState<SubscriptionPackagesQuantity>({
      small: subscriptions.small,
      big: subscriptions.big,
    });

  const modifySubscriptionCount =
    (type: keyof SubscriptionPackages, operation: 'add' | 'remove') => () => {
      const existingQuantity = selectedSubscriptions[type].quantity;
      let newQuantity: number;

      if (operation === 'add') {
        newQuantity = existingQuantity + 1;
      } else if (operation === 'remove') {
        newQuantity = existingQuantity > 0 ? existingQuantity - 1 : 0;
      }

      setSelectedSubscriptions((subs) => ({
        ...subs,
        [type]: { ...subs[type], quantity: newQuantity },
      }));
    };

  return (
    <Wrapper>
      <Typography variant="h2">
        {formatMessage({
          id: 'checkout.title',
        })}
      </Typography>
      <Typography gutterBottom>
        {formatMessage({
          id: 'checkout.title.possibleToChange',
        })}
      </Typography>
      <SubscriptionCards>
        <SubscriptionCard>
          <Typography variant="h4">
            {formatMessage({
              id: 'checkout.bigSubcription.title',
            })}
          </Typography>
          <div style={{ margin: '0 16px', height: '100px' }}>
            <Typography variant="body2">
              {formatMessage({
                id: 'checkout.bigSubscription.body',
              })}
            </Typography>
          </div>
          <ul style={{ marginRight: 'auto' }}>
            <li>
              <Typography variant="body2">
                {formatMessage({
                  id: 'checkout.bigSubscription.point1',
                })}
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                {formatMessage({
                  id: 'checkout.bigSubscription.point2',
                })}
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                {formatMessage({
                  id: 'checkout.bigSubscription.point3',
                })}
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                {formatMessage({
                  id: 'checkout.bigSubscription.point4',
                })}
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                {formatMessage({
                  id: `checkout.bigSubscription.point5`,
                })}
              </Typography>
            </li>
          </ul>
          <Typography
            variant="h1"
            style={{ marginBottom: '0', marginTop: 'auto' }}
          >
            {subscriptions.big.price} {formatMessage({ id: 'curr.min' })}
          </Typography>
          <Typography variant="subtitle2" style={{ marginBottom: '16px' }}>
            {formatMessage({
              id: `checkout.pricePerMonth`,
            })}
          </Typography>
        </SubscriptionCard>
        <SubscriptionCard>
          <Typography variant="h4">
            {formatMessage({
              id: 'checkout.smallSubscription.title',
            })}
          </Typography>
          <div style={{ margin: '0 16px', height: '100px' }}>
            <Typography variant="body2">
              {formatMessage({
                id: 'checkout.smallSubscription.body',
              })}
            </Typography>
          </div>
          <ul style={{ marginRight: 'auto' }}>
            <li>
              <Typography variant="body2">
                {formatMessage({
                  id: `checkout.bigSubscription.point1`,
                })}
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                {formatMessage({
                  id: `checkout.bigSubscription.point2`,
                })}
              </Typography>
            </li>
          </ul>
          <Typography
            variant="h1"
            style={{ marginBottom: '0', marginTop: 'auto' }}
          >
            {subscriptions.small.price} {formatMessage({ id: 'curr.min' })}
          </Typography>
          <Typography variant="subtitle2" style={{ marginBottom: '16px' }}>
            {formatMessage({
              id: `checkout.pricePerMonth`,
            })}
          </Typography>
        </SubscriptionCard>
      </SubscriptionCards>

      <QuantityCards>
        <QuantityCard>
          <PrimaryButton onClick={modifySubscriptionCount('big', 'remove')}>
            -
          </PrimaryButton>
          <Typography variant="h1">
            {selectedSubscriptions.big.quantity}
          </Typography>
          <PrimaryButton onClick={modifySubscriptionCount('big', 'add')}>
            +
          </PrimaryButton>
        </QuantityCard>
        <QuantityCard>
          <PrimaryButton onClick={modifySubscriptionCount('small', 'remove')}>
            -
          </PrimaryButton>
          <Typography variant="h1">
            {selectedSubscriptions.small.quantity}
          </Typography>
          <PrimaryButton onClick={modifySubscriptionCount('small', 'add')}>
            +
          </PrimaryButton>
        </QuantityCard>
      </QuantityCards>
      <InformationWrapper>
        <Typography>
          {`${formatMessage({
            id: 'checkout.title.infoText',
          })} `}

          <a href="https://www.agoy.se/pris/" target="_blank" rel="noreferrer">
            {formatMessage({
              id: 'checkout.title.infoTextLink',
            })}
          </a>
        </Typography>
      </InformationWrapper>
      <Typography variant="h1" style={{ marginBottom: '0' }}>
        Total:{' '}
        {selectedSubscriptions.small.quantity *
          selectedSubscriptions.small.price +
          selectedSubscriptions.big.quantity *
            selectedSubscriptions.big.price}{' '}
        {formatMessage({ id: 'curr.min' })}
      </Typography>
      <Typography variant="subtitle2" style={{ marginBottom: '16px' }}>
        {formatMessage({
          id: `checkout.pricePerMonth`,
        })}
      </Typography>
      <Button
        onClick={() => handleCheckout(selectedSubscriptions)}
        variant="contained"
        disabled={
          selectedSubscriptions.small.quantity === 0 &&
          selectedSubscriptions.big.quantity === 0
        }
        color="secondary"
        disableElevation
      >
        {formatMessage({ id: 'checkout.button' })}
      </Button>
    </Wrapper>
  );
};

export default Page;

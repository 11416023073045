const helpStructureNEA = {
  type: 'part' as const,
  _type: 'NEA' as const,
  children: {
    improvements: { type: 'table' as const },
    renovation: { type: 'table' as const },
  },
};

export default helpStructureNEA;

import React from 'react';
import styled from '@emotion/styled';
import Typography from '../../../../UI/Typography/Typography';
import { IXElement } from '../../../../ARKeyToIX';
import { Field, stringValue } from '@agoy/document';
import { getStatementOptions } from './statementOptions';
import { AnnualReportVersion } from '@agoy/annual-report-document';
import { useIntl } from 'react-intl';

const Content = styled.div`
  margin: ${({ theme }) => theme.spacing(2)}px 0;
`;

// We need to wrap the element name in the IXElement but don't want
// to display them, hence the hiding div
const HiddenIXElement = styled.div`
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
`;

// This is the Påteckningsmening in the BV spreadsheet
const returnIxbrlField = (statement, pronoun?: string) => {
  const statementCopy = { ...statement };
  const optionItem = getStatementOptions(pronoun).find(
    (item) => item.label === stringValue(statementCopy)
  );

  const ixbrlObject = {
    ...statementCopy,
    ixbrl: {
      ...statementCopy.ixbrl,
      contextRef: 'period0',
      type: 'enumerationItemType',
      name: 'se-gen-base:RevisorspateckningRevisionsberattelseAvstyrktResultatrakningenBalansrakningenList',
    },
  };
  return optionItem ? ixbrlObject : 'notApplicable';
};

const idByLabel = (label, pronoun?: string): string => {
  const option = getStatementOptions(pronoun).find(
    (item) => item.label === label
  );

  return option ? option.id : 'notApplicable';
};

const statementTitle = (label, pronoun?: string): string => {
  const option = getStatementOptions(pronoun).find(
    (item) => item.label === label
  );
  return option ? option.title : 'undefined';
};

const statementName = (label, pronoun?: string): string => {
  const option = getStatementOptions(pronoun).find(
    (item) => item.label === label
  );
  return option ? option.name : 'undefined';
};

type SignaturesStatementProps = {
  value: Field;
  statementPronoun: Field;
  version: AnnualReportVersion;
};

const SignaturesStatement = ({
  value,
  statementPronoun,
  version,
}: SignaturesStatementProps): JSX.Element => {
  const { formatMessage } = useIntl();

  const statementPronounValue = stringValue(statementPronoun);

  const pronoun =
    version === '1' || !statementPronounValue
      ? undefined
      : formatMessage({
          id: `annualReport.signatures.section.statement.${statementPronounValue}`,
        });

  return (
    <Content>
      {idByLabel(stringValue(value), pronoun) !== 'notApplicable' && (
        <>
          <HiddenIXElement>
            <IXElement arPart={returnIxbrlField(value, pronoun)}>
              {statementName(stringValue(value), pronoun)}
            </IXElement>
          </HiddenIXElement>

          <Typography>{statementTitle(stringValue(value), pronoun)}</Typography>
        </>
      )}
    </Content>
  );
};

export default SignaturesStatement;

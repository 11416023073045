import { ref, table, value } from '@agoy/document';
import { NoteOneTableSection } from '../../../../common';
import { previousYear, year } from '../../../../common/utils/date-util';
import {
  active,
  field,
  ixbrlCell,
  ixbrlField,
} from '../../../../common/utils/util';
import {
  sumAccountsIBs,
  sumNotesRowsById,
} from '../../../../common/utils/accountSumsUtil';
import { NoteAccountRanges } from '../types';

export const note94Accounts: NoteAccountRanges[] = [
  {
    rowId: 'ingoingAcquisitionValues',
    ranges: [[1010, 1012]],
    cellLabel: 'Ingående anskaffningsvärden',
    action: 'sumAccountsIBs',
  },
  {
    rowId: 'ingoingDepreciation',
    ranges: [[1019]],
    cellLabel: 'Ingående avskrivningar',
    action: 'sumAccountsIBs',
  },
  {
    rowId: 'ingoingWriteDowns',
    ranges: [[1018]],
    cellLabel: 'Ingående nedskrivningar',
    action: 'sumAccountsIBs',
  },
];

export const getNote94 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null,
  documentTypeVersion = '1'
): NoteOneTableSection => {
  return {
    active: false,
    number: value(undefined),
    name: value(
      'Balanserade utgifter för utvecklingsarbeten och liknande arbeten'
    ),
    table: table(
      `notes.note94.table`,
      { id: 'label', label: '' },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      .addRows((rows) =>
        rows
          .addRow(
            'ingoingAcquisitionValues',
            noteLabel('Ingående anskaffningsvärden'),
            ixbrlCell(ref(sumAccountsIBs('1010:1012', false)), {
              type: 'monetaryItemType',
              name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenAnskaffningsvarden',
              contextRef: 'balans0',
              saldo: 'debit',
              standardRubrik:
                'Anskaffningsvärden balanserade utgifter för utvecklingsarbeten och liknande arbeten',
            }),
            ixbrlCell(ref(sumAccountsIBs('1010:1012', false, 'previousYear')), {
              type: 'monetaryItemType',
              name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenAnskaffningsvarden',
              contextRef: 'balans1',
              saldo: 'debit',
              standardRubrik:
                'Anskaffningsvärden balanserade utgifter för utvecklingsarbeten och liknande arbeten',
            })
          )
          .addRow(
            'changesInAcquisitionValues',
            noteLabel('Förändringar av anskaffningsvärden'),
            undefined,
            undefined
          )
          .addRow(
            'capitalizedExpenses',
            noteLabel('Aktiverade utgifter'),
            ixbrlCell(value(undefined), {
              type: 'monetaryItemType',
              name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringAnskaffningsvardenAktiveradeUtgifter',
              contextRef: 'period0',
              standardRubrik:
                'Ökning av anskaffningsvärden i samband med periodens aktiverade utgifter för balanserade utgifter',
            }),
            ixbrlCell(value(undefined), {
              type: 'monetaryItemType',
              name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringAnskaffningsvardenAktiveradeUtgifter',
              contextRef: 'period1',
              standardRubrik:
                'Ökning av anskaffningsvärden i samband med periodens aktiverade utgifter för balanserade utgifter',
            })
          )
          .addRow(
            'businessAcquisition',
            noteLabel('Rörelseförvärv'),
            ixbrlCell(value(undefined), {
              type: 'monetaryItemType',
              name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringAnskaffningsvardenRorelseforvarv',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden för balanserade utgifter för utvecklingsarbeten och liknande arbeten genom rörelseförvärv',
            }),
            ixbrlCell(value(undefined), {
              type: 'monetaryItemType',
              name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringAnskaffningsvardenRorelseforvarv',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden för balanserade utgifter för utvecklingsarbeten och liknande arbeten genom rörelseförvärv',
            })
          )
          .addRow(
            'fusion',
            noteLabel('Fusion'),
            ixbrlCell(value(undefined), {
              type: 'monetaryItemType',
              name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringAnskaffningsvardenFusion',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden för balanserade utgifter för utvecklingsarbeten och liknande arbeten genom fusion',
            }),
            ixbrlCell(value(undefined), {
              type: 'monetaryItemType',
              name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringAnskaffningsvardenFusion',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden för balanserade utgifter för utvecklingsarbeten och liknande arbeten genom fusion',
            })
          )
          .addRow(
            'salesAndDisposals',
            noteLabel('Försäljningar/utrangeringar'),
            ixbrlCell(value(undefined), {
              type: 'monetaryItemType',
              name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringAnskaffningsvardenForsaljningarUtrangeringar',
              contextRef: 'period0',
              standardRubrik:
                'Minskning av anskaffningsvärden i samband med försäljningar eller utrangeringar av balanserade utgifter för utvecklingsarbeten och liknande arbeten',
            }),
            ixbrlCell(value(undefined), {
              type: 'monetaryItemType',
              name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringAnskaffningsvardenForsaljningarUtrangeringar',
              contextRef: 'period1',
              standardRubrik:
                'Minskning av anskaffningsvärden i samband med försäljningar eller utrangeringar av balanserade utgifter för utvecklingsarbeten och liknande arbeten',
            })
          )
          .addRow(
            'reclassifications',
            noteLabel('Omklassificeringar'),
            ixbrlCell(value(undefined), {
              type: 'monetaryItemType',
              name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringAnskaffningsvardenOmklassificeringar',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden i samband med omklassificeringar av balanserade utgifter för utvecklingsarbeten och liknande arbeten',
            }),
            ixbrlCell(value(undefined), {
              type: 'monetaryItemType',
              name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringAnskaffningsvardenOmklassificeringar',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden i samband med omklassificeringar av balanserade utgifter för utvecklingsarbeten och liknande arbeten',
            })
          )
          .addRow(
            'translationDifferences',
            noteLabel('Omräkningsdifferenser'),
            ixbrlCell(value(undefined), {
              type: 'monetaryItemType',
              name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringAnskaffningsvardenOmrakningsdifferenser',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden i samband med omräkningsdifferenser av balanserade utgifter för utvecklingsarbeten och liknande arbeten',
            }),
            ixbrlCell(value(undefined), {
              type: 'monetaryItemType',
              name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringAnskaffningsvardenOmrakningsdifferenser',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden i samband med omräkningsdifferenser av balanserade utgifter för utvecklingsarbeten och liknande arbeten',
            })
          )
          .addRow(
            'closingAcquisitionValues',
            noteLabel('Utgående anskaffningsvärden'),
            ixbrlCell(
              sumNotesRowsById(
                94,
                [
                  'ingoingAcquisitionValues',
                  'capitalizedExpenses',
                  'businessAcquisition',
                  'fusion',
                  'salesAndDisposals',
                  'reclassifications',
                  'translationDifferences',
                ],
                'year'
              ),
              {
                type: 'monetaryItemType',
                name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenAnskaffningsvarden',
                contextRef: 'balans0',
                saldo: 'debit',
                standardRubrik:
                  'Anskaffningsvärden balanserade utgifter för utvecklingsarbeten och liknande arbeten',
              }
            ),
            ixbrlCell(
              sumNotesRowsById(
                94,
                [
                  'ingoingAcquisitionValues',
                  'capitalizedExpenses',
                  'businessAcquisition',
                  'fusion',
                  'salesAndDisposals',
                  'reclassifications',
                  'translationDifferences',
                ],
                'previousYear'
              ),
              {
                type: 'monetaryItemType',
                name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenAnskaffningsvarden',
                contextRef: 'balans1',
                saldo: 'debit',
                standardRubrik:
                  'Anskaffningsvärden balanserade utgifter för utvecklingsarbeten och liknande arbeten',
              }
            )
          )
          .addRow(
            'ingoingDepreciation',
            noteLabel('Ingående avskrivningar'),
            ixbrlCell(ref(sumAccountsIBs('1019', false)), {
              type: 'monetaryItemType',
              name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenAvskrivningar',
              contextRef: 'balans0',
              saldo: 'credit',
              standardRubrik:
                'Anskaffningsvärden balanserade utgifter för utvecklingsarbeten och liknande arbeten',
            }),
            ixbrlCell(ref(sumAccountsIBs('1019', false, 'previousYear')), {
              type: 'monetaryItemType',
              name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenAvskrivningar',
              contextRef: 'balans1',
              saldo: 'credit',
              standardRubrik:
                'Anskaffningsvärden balanserade utgifter för utvecklingsarbeten och liknande arbeten',
            })
          )
          .addRow(
            'changesInDepreciation',
            noteLabel('Förändringar av avskrivningar'),
            undefined,
            undefined
          )
          .addRow(
            'fusionDepreciation',
            noteLabel('Fusion'),
            ixbrlCell(value(undefined), {
              type: 'monetaryItemType',
              name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringAvskrivningarFusion',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av avskrivningar för balanserade utgifter för utvecklingsarbeten och liknande arbeten genom fusion',
            }),
            ixbrlCell(value(undefined), {
              type: 'monetaryItemType',
              name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringAvskrivningarFusion',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av avskrivningar för balanserade utgifter för utvecklingsarbeten och liknande arbeten genom fusion',
            })
          )
          .addRow(
            'salesAndDisposalsDepreciation',
            noteLabel('Försäljningar/utrangeringar'),
            ixbrlCell(value(undefined), {
              type: 'monetaryItemType',
              name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringAvskrivningarForsaljningarUtrangeringar',
              contextRef: 'period0',
              standardRubrik:
                'Återförda avskrivningar i samband med försäljningar eller utrangeringar av balanserade utgifter för utvecklingsarbeten och liknande arbeten',
            }),
            ixbrlCell(value(undefined), {
              type: 'monetaryItemType',
              name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringAvskrivningarForsaljningarUtrangeringar',
              contextRef: 'period1',
              standardRubrik:
                'Återförda avskrivningar i samband med försäljningar eller utrangeringar av balanserade utgifter för utvecklingsarbeten och liknande arbeten',
            })
          )
          .addRow(
            'reclassificationsDepreciation',
            noteLabel('Omklassificeringar'),
            ixbrlCell(value(undefined), {
              type: 'monetaryItemType',
              name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringAvskrivningarOmklassificeringar',
              contextRef: 'period0',
              standardRubrik:
                'Minskning (ökning) av avskrivningar i samband med omklassificeringar av balanserade utgifter för utvecklingsarbeten och liknande arbeten',
            }),
            ixbrlCell(value(undefined), {
              type: 'monetaryItemType',
              name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringAvskrivningarOmklassificeringar',
              contextRef: 'period1',
              standardRubrik:
                'Minskning (ökning) av avskrivningar i samband med omklassificeringar av balanserade utgifter för utvecklingsarbeten och liknande arbeten',
            })
          )
          .addRow(
            'yearsDepreciation',
            noteLabel('Årets avskrivningar'),
            ixbrlCell(value(undefined), {
              type: 'monetaryItemType',
              name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringAvskrivningarAretsAvskrivningar',
              contextRef: 'period0',
              standardRubrik:
                'Periodens avskrivningar balanserade utgifter för utvecklingsarbeten och liknande arbeten',
            }),
            ixbrlCell(value(undefined), {
              type: 'monetaryItemType',
              name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringAvskrivningarAretsAvskrivningar',
              contextRef: 'period1',
              standardRubrik:
                'Periodens avskrivningar balanserade utgifter för utvecklingsarbeten och liknande arbeten',
            })
          )
          .addRow(
            'translationDifferencesDepreciation',
            noteLabel('Omräkningsdifferenser'),
            ixbrlCell(value(undefined), {
              type: 'monetaryItemType',
              name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringAvskrivningarOmrakningsdifferenser',
              contextRef: 'period0',
              standardRubrik:
                'Minskning (ökning) av avskrivningar i samband med omräkningsdifferenser av balanserade utgifter för utvecklingsarbeten och liknande arbeten',
            }),
            ixbrlCell(value(undefined), {
              type: 'monetaryItemType',
              name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringAvskrivningarOmrakningsdifferenser',
              contextRef: 'period1',
              standardRubrik:
                'Minskning (ökning) av avskrivningar i samband med omräkningsdifferenser av balanserade utgifter för utvecklingsarbeten och liknande arbeten',
            })
          )
          .addRow(
            'closingDepreciation',
            noteLabel('Utgående avskrivningar'),
            ixbrlCell(
              sumNotesRowsById(
                94,
                [
                  'ingoingDepreciation',
                  'fusionDepreciation',
                  'salesAndDisposalsDepreciation',
                  'reclassificationsDepreciation',
                  'yearsDepreciation',
                  'translationDifferencesDepreciation',
                ],
                'year'
              ),
              {
                type: 'monetaryItemType',
                name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenAvskrivningar',
                contextRef: 'balans0',
                saldo: 'credit',
                standardRubrik:
                  'Ackumulerade avskrivningar balanserade utgifter för utvecklingsarbeten och liknande arbeten',
              }
            ),
            ixbrlCell(
              sumNotesRowsById(
                94,
                [
                  'ingoingDepreciation',
                  'fusionDepreciation',
                  'salesAndDisposalsDepreciation',
                  'reclassificationsDepreciation',
                  'yearsDepreciation',
                  'translationDifferencesDepreciation',
                ],
                'previousYear'
              ),
              {
                type: 'monetaryItemType',
                name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenAvskrivningar',
                contextRef: 'balans1',
                saldo: 'credit',
                standardRubrik:
                  'Ackumulerade avskrivningar balanserade utgifter för utvecklingsarbeten och liknande arbeten',
              }
            )
          )
          .addRow(
            'ingoingWriteUps',
            noteLabel('Ingående uppskrivningar'),
            ixbrlCell(value(undefined), {
              type: 'monetaryItemType',
              name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenUppskrivningar',
              contextRef: 'balans0',
              saldo: 'debit',
              standardRubrik:
                'Ackumulerade uppskrivningar balanserade utgifter för utvecklingsarbeten och liknande arbeten',
            }),
            ixbrlCell(value(undefined), {
              type: 'monetaryItemType',
              name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenUppskrivningar',
              contextRef: 'balans1',
              saldo: 'debit',
              standardRubrik:
                'Ackumulerade uppskrivningar balanserade utgifter för utvecklingsarbeten och liknande arbeten',
            })
          )
          .addRow(
            'changesInWriteUps',
            noteLabel('Förändringar av uppskrivningar'),
            undefined,
            undefined
          )
          .addRow(
            'fusionWriteUps',
            noteLabel('Fusion'),
            ixbrlCell(value(undefined), {
              type: 'monetaryItemType',
              name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringUppskrivningarFusion',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av uppskrivningar för balanserade utgifter för utvecklingsarbeten och liknande arbeten genom fusion',
            }),
            ixbrlCell(value(undefined), {
              type: 'monetaryItemType',
              name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringUppskrivningarFusion',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av uppskrivningar för balanserade utgifter för utvecklingsarbeten och liknande arbeten genom fusion',
            })
          )
          .addRow(
            'salesAndDisposalsWriteUps',
            noteLabel('Försäljningar/utrangeringar'),
            ixbrlCell(value(undefined), {
              type: 'monetaryItemType',
              name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringUppskrivningarForsaljningarUtrangeringar',
              contextRef: 'period0',
              standardRubrik:
                'Återförda uppskrivningar i samband med försäljningar eller utrangeringar av balanserade utgifter för utvecklingsarbeten och liknande arbeten',
            }),
            ixbrlCell(value(undefined), {
              type: 'monetaryItemType',
              name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringUppskrivningarForsaljningarUtrangeringar',
              contextRef: 'period1',
              standardRubrik:
                'Återförda uppskrivningar i samband med försäljningar eller utrangeringar av balanserade utgifter för utvecklingsarbeten och liknande arbeten',
            })
          )
          .addRow(
            'reclassificationsWriteUps',
            noteLabel('Omklassificeringar'),
            ixbrlCell(value(undefined), {
              type: 'monetaryItemType',
              name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringUppskrivningarOmklassificeringar',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av uppskrivningar i samband med omklassificeringar av balanserade utgifter för utvecklingsarbeten och liknande arbeten',
            }),
            ixbrlCell(value(undefined), {
              type: 'monetaryItemType',
              name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringUppskrivningarOmklassificeringar',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av uppskrivningar i samband med omklassificeringar av balanserade utgifter för utvecklingsarbeten och liknande arbeten',
            })
          )
          .addRow(
            'yearsWriteUps',
            noteLabel('Årets uppskrivningar'),
            ixbrlCell(value(undefined), {
              type: 'monetaryItemType',
              name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringUppskrivningarAretsUppskrivningar',
              contextRef: 'period0',
              standardRubrik:
                'Periodens uppskrivningar av balanserade utgifter för utvecklingsarbeten och liknande arbeten',
            }),
            ixbrlCell(value(undefined), {
              type: 'monetaryItemType',
              name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringUppskrivningarAretsUppskrivningar',
              contextRef: 'period1',
              standardRubrik:
                'Periodens uppskrivningar av balanserade utgifter för utvecklingsarbeten och liknande arbeten',
            })
          )
          .addRow(
            'writeDownsForTheYearOnTheDepreciatedAmount',
            noteLabel('Årets nedskrivningar på uppskrivet belopp'),
            ixbrlCell(value(undefined), {
              type: 'monetaryItemType',
              name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringUppskrivningarNedskrivningarUppskrivetBelopp',
              contextRef: 'period0',
              standardRubrik:
                'Nedskrivningar av uppskrivet belopp på balanserade utgifter för utvecklingsarbeten och liknande arbeten',
            }),
            ixbrlCell(value(undefined), {
              type: 'monetaryItemType',
              name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringUppskrivningarNedskrivningarUppskrivetBelopp',
              contextRef: 'period1',
              standardRubrik:
                'Nedskrivningar av uppskrivet belopp på balanserade utgifter för utvecklingsarbeten och liknande arbeten',
            })
          )
          .addRow(
            'yearsDepreciationOnWriteUpAmount',
            noteLabel('Årets avskrivningar på uppskrivet belopp'),
            ixbrlCell(value(undefined), {
              type: 'monetaryItemType',
              name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringUppskrivningarAvskrivningarUppskrivetBelopp',
              contextRef: 'period0',
              standardRubrik:
                'Avskrivningar av uppskrivet belopp på balanserade utgifter för utvecklingsarbeten och liknande arbeten',
            }),
            ixbrlCell(value(undefined), {
              type: 'monetaryItemType',
              name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringUppskrivningarAvskrivningarUppskrivetBelopp',
              contextRef: 'period1',
              standardRubrik:
                'Avskrivningar av uppskrivet belopp på balanserade utgifter för utvecklingsarbeten och liknande arbeten',
            })
          )
          .addRow(
            'translationDifferencesWriteUps',
            noteLabel('Omräkningsdifferenser'),
            ixbrlCell(value(undefined), {
              type: 'monetaryItemType',
              name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringUppskrivningarOmrakningsdifferenser',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av uppskrivningar i samband med omräkningsdifferenser av balanserade utgifter för utvecklingsarbeten och liknande arbeten',
            }),
            ixbrlCell(value(undefined), {
              type: 'monetaryItemType',
              name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringUppskrivningarOmrakningsdifferenser',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av uppskrivningar i samband med omräkningsdifferenser av balanserade utgifter för utvecklingsarbeten och liknande arbeten',
            })
          )
          .addRow(
            'closingWriteUps',
            noteLabel('Utgående uppskrivningar'),
            ixbrlCell(
              sumNotesRowsById(
                94,
                [
                  'ingoingWriteUps',
                  'fusionWriteUps',
                  'salesAndDisposalsWriteUps',
                  'reclassificationsWriteUps',
                  'yearsWriteUps',
                  'writeDownsForTheYearOnTheDepreciatedAmount',
                  'yearsDepreciationOnWriteUpAmount',
                  'translationDifferencesWriteUps',
                ],
                'year'
              ),
              {
                type: 'monetaryItemType',
                name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenUppskrivningar',
                contextRef: 'balans0',
                saldo: 'debit',
                standardRubrik:
                  'Ackumulerade avskrivningar balanserade utgifter för utvecklingsarbeten och liknande arbeten',
              }
            ),
            ixbrlCell(
              sumNotesRowsById(
                94,
                [
                  'ingoingWriteUps',
                  'fusionWriteUps',
                  'salesAndDisposalsWriteUps',
                  'reclassificationsWriteUps',
                  'yearsWriteUps',
                  'writeDownsForTheYearOnTheDepreciatedAmount',
                  'yearsDepreciationOnWriteUpAmount',
                  'translationDifferencesWriteUps',
                ],
                'previousYear'
              ),
              {
                type: 'monetaryItemType',
                name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenUppskrivningar',
                contextRef: 'balans1',
                saldo: 'debit',
                standardRubrik:
                  'Ackumulerade avskrivningar balanserade utgifter för utvecklingsarbeten och liknande arbeten',
              }
            )
          )

          .addRow(
            'ingoingWriteDowns',
            noteLabel('Ingående nedskrivningar'),
            ixbrlCell(ref(sumAccountsIBs('1018', false)), {
              type: 'monetaryItemType',
              name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenNedskrivningar',
              contextRef: 'balans0',
              saldo: 'credit',
              standardRubrik:
                'Ackumulerade nedskrivningar balanserade utgifter för utvecklingsarbeten och liknande arbeten',
            }),
            ixbrlCell(ref(sumAccountsIBs('1018', false, 'previousYear')), {
              type: 'monetaryItemType',
              name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenNedskrivningar',
              contextRef: 'balans1',
              saldo: 'credit',
              standardRubrik:
                'Ackumulerade nedskrivningar balanserade utgifter för utvecklingsarbeten och liknande arbeten',
            })
          )
          .addRow(
            'changesInWriteDowns',
            noteLabel('Förändringar av nedskrivningar'),
            undefined,
            undefined
          )
          .addRow(
            'fusionWriteDowns',
            noteLabel('Fusion'),
            ixbrlCell(value(undefined), {
              type: 'monetaryItemType',
              name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringNedskrivningarFusion',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av nedskrivningar för balanserade utgifter för utvecklingsarbeten och liknande arbeten genom fusion',
            }),
            ixbrlCell(value(undefined), {
              type: 'monetaryItemType',
              name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringNedskrivningarFusion',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av nedskrivningar för balanserade utgifter för utvecklingsarbeten och liknande arbeten genom fusion',
            })
          )
          .addRow(
            'salesAndDisposalsWriteDowns',
            noteLabel('Försäljningar/utrangeringar'),
            ixbrlCell(value(undefined), {
              type: 'monetaryItemType',
              name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringNedskrivningarForsaljningarUtrangeringar',
              contextRef: 'period0',
              standardRubrik:
                'Återförda nedskrivningar i samband med försäljningar eller utrangeringar av balanserade utgifter för utvecklingsarbeten och liknande arbeten',
            }),
            ixbrlCell(value(undefined), {
              type: 'monetaryItemType',
              name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringNedskrivningarForsaljningarUtrangeringar',
              contextRef: 'period1',
              standardRubrik:
                'Återförda nedskrivningar i samband med försäljningar eller utrangeringar av balanserade utgifter för utvecklingsarbeten och liknande arbeten',
            })
          )
          .addRow(
            'reclassificationsWriteDowns',
            noteLabel('Omklassificeringar'),
            ixbrlCell(value(undefined), {
              type: 'monetaryItemType',
              name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringNedskrivningarOmklassificeringar',
              contextRef: 'period0',
              standardRubrik:
                'Minskning (ökning) av nedskrivningar i samband med omklassificeringar av balanserade utgifter för utvecklingsarbeten och liknande arbeten',
            }),
            ixbrlCell(value(undefined), {
              type: 'monetaryItemType',
              name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringNedskrivningarOmklassificeringar',
              contextRef: 'period1',
              standardRubrik:
                'Minskning (ökning) av nedskrivningar i samband med omklassificeringar av balanserade utgifter för utvecklingsarbeten och liknande arbeten',
            })
          )
          .addRow(
            'reversalOFWriteDowns',
            noteLabel('Återförda nedskrivningar'),
            ixbrlCell(value(undefined), {
              type: 'monetaryItemType',
              name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringNedskrivningarAterfordaNedskrivningar',
              contextRef: 'period0',
              standardRubrik:
                'Periodens återförda nedskrivningar av balanserade utgifter för utvecklingsarbeten och liknande arbeten',
            }),
            ixbrlCell(value(undefined), {
              type: 'monetaryItemType',
              name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringNedskrivningarAterfordaNedskrivningar',
              contextRef: 'period1',
              standardRubrik:
                'Periodens återförda nedskrivningar av balanserade utgifter för utvecklingsarbeten och liknande arbeten',
            })
          )
          .addRow(
            'yearsWriteDowns',
            noteLabel('Årets nedskrivningar'),
            ixbrlCell(value(undefined), {
              type: 'monetaryItemType',
              name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringNedskrivningarAretsNedskrivningar',
              contextRef: 'period0',
              standardRubrik:
                'Periodens nedskrivningar balanserade utgifter för utvecklingsarbeten och liknande arbeten',
            }),
            ixbrlCell(value(undefined), {
              type: 'monetaryItemType',
              name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringNedskrivningarAretsNedskrivningar',
              contextRef: 'period1',
              standardRubrik:
                'Periodens nedskrivningar balanserade utgifter för utvecklingsarbeten och liknande arbeten',
            })
          )
          .addRow(
            'translationDifferencesWriteDowns',
            noteLabel('Omräkningsdifferenser'),
            ixbrlCell(value(undefined), {
              type: 'monetaryItemType',
              name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringNedskrivningarOmrakningsdifferenser',
              contextRef: 'period0',
              standardRubrik:
                'Minskning (ökning) av nedskrivningar i samband med omräkningsdifferenser av balanserade utgifter för utvecklingsarbeten och liknande arbeten',
            }),
            ixbrlCell(value(undefined), {
              type: 'monetaryItemType',
              name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenForandringNedskrivningarOmrakningsdifferenser',
              contextRef: 'period1',
              standardRubrik:
                'Minskning (ökning) av nedskrivningar i samband med omräkningsdifferenser av balanserade utgifter för utvecklingsarbeten och liknande arbeten',
            })
          )
          .addRow(
            'closingWriteDowns',
            noteLabel('Utgående nedskrivningar'),
            ixbrlCell(
              sumNotesRowsById(
                94,
                [
                  'ingoingWriteDowns',
                  'fusionWriteDowns',
                  'salesAndDisposalsWriteDowns',
                  'reclassificationsWriteDowns',
                  'reversalOFWriteDowns',
                  'yearsWriteDowns',
                  'translationDifferencesWriteDowns',
                ],
                'year'
              ),
              {
                type: 'monetaryItemType',
                name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenNedskrivningar',
                contextRef: 'balans0',
                saldo: 'credit',
                standardRubrik:
                  'Ackumulerade nedskrivningar balanserade utgifter för utvecklingsarbeten och liknande arbeten',
              }
            ),
            ixbrlCell(
              sumNotesRowsById(
                94,
                [
                  'ingoingWriteDowns',
                  'fusionWriteDowns',
                  'salesAndDisposalsWriteDowns',
                  'reclassificationsWriteDowns',
                  'reversalOFWriteDowns',
                  'yearsWriteDowns',
                  'translationDifferencesWriteDowns',
                ],
                'previousYear'
              ),
              {
                type: 'monetaryItemType',
                name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenNedskrivningar',
                contextRef: 'balans1',
                saldo: 'credit',
                standardRubrik:
                  'Ackumulerade nedskrivningar balanserade utgifter för utvecklingsarbeten och liknande arbeten',
              }
            )
          )
          .addRow(
            'recordedValue',
            noteLabel('Redovisat värde'),
            ixbrlCell(
              sumNotesRowsById(
                94,
                [
                  'closingAcquisitionValues',
                  'closingDepreciation',
                  'closingWriteUps',
                  'closingWriteDowns',
                ],
                'year'
              ),
              {
                type: 'monetaryItemType',
                name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknandeArbeten',
                contextRef: 'balans0',
                saldo: 'debit',
                standardRubrik:
                  'Balanserade utgifter för utvecklingsarbeten och liknande arbeten',
              }
            ),
            ixbrlCell(
              sumNotesRowsById(
                94,
                [
                  'closingAcquisitionValues',
                  'closingDepreciation',
                  'closingWriteUps',
                  'closingWriteDowns',
                ],
                'previousYear'
              ),
              {
                type: 'monetaryItemType',
                name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknandeArbeten',
                contextRef: 'balans1',
                saldo: 'debit',
                standardRubrik:
                  'Balanserade utgifter för utvecklingsarbeten och liknande arbeten',
              }
            )
          )
          .addRow(
            'publicContributionsThatReducedTheAcquisitionValue',
            noteLabel('Offentliga bidrag som reducerat anskaffningsvärdet'),
            ixbrlCell(value(undefined), {
              type: 'monetaryItemType',
              name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenOffentligaBidrag',
              contextRef: 'balans0',
              saldo: 'credit',
              standardRubrik:
                'Offentliga bidrag som reducerat anskaffningsvärdet på balanserade utgifter för utvecklingsarbeten och liknande arbeten',
            }),
            ixbrlCell(value(undefined), {
              type: 'monetaryItemType',
              name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenOffentligaBidrag',
              contextRef: 'balans1',
              saldo: 'credit',
              standardRubrik:
                'Offentliga bidrag som reducerat anskaffningsvärdet på balanserade utgifter för utvecklingsarbeten och liknande arbeten',
            })
          )
          .build()
      )
      .newRowTemplate(value(''), value(undefined), value(undefined))
      .build(),
    commentLabel: field('Kommentar till not'),
    comment: ixbrlField(active(field('')), {
      type: 'stringItemType',
      name: 'se-gen-base:NotBalanseradeUtgifterUtvecklingsarbetenLiknadeArbetenKommentar',
      contextRef: 'period0',
      standardRubrik:
        'Kommentar till specifikation av balanserade utgifter för utvecklingsarbeten och liknande arbeten',
    }),
    group: value(undefined),
    section: value(undefined),
    validForReportType: {
      k2: false,
      k3: documentTypeVersion !== '1',
    },
  };
};

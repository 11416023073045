import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { jsx } from '@emotion/react'; // Needed, see https://emotion.sh/docs/introduction
import config from '_shared/services/config';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import App from './App';

Sentry.init({
  dsn: 'https://86d9799886644842bcb7e52f7f7df336@o447691.ingest.sentry.io/5427878',
  environment: config.runningEnvironment,
  enabled:
    config.runningEnvironment === 'production' ||
    config.runningEnvironment === 'staging' ||
    config.runningEnvironment === 'test',
  release: config.appCommitId,
  autoSessionTracking: false,
});

console.log(`Agoy web-app version: v${config.appVersion}`);
console.log(`Running environment ${config.runningEnvironment}`);

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(<App />);

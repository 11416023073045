import { Settings } from '@agoy/annual-report-document';
import { booleanValue } from '@agoy/document';

/**
 * Helper to show if the user has selected an individual period within the financial year
 *
 * @param settings Annual report settings object
 * @returns boolean if single period is selected
 */
export const isSinglePeriodSelected = (
  settings: Settings | undefined
): boolean => {
  return booleanValue(settings?.section.isSinglePeriod);
};

export default isSinglePeriodSelected;

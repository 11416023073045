/* eslint-disable no-plusplus */
import React, { useContext, useState } from 'react';
import AdapterDateFns from '@date-io/date-fns';
import styled from '@emotion/styled';
import { closestTo, parse } from 'date-fns';
import {
  Box,
  Button,
  Grid,
  Typography,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import AddIcon from '@material-ui/icons/Add';
import { useIntl } from 'react-intl';
import { ccyFormat } from '@agoy/common';
import { referenceNumberValue, stringValue } from '@agoy/document';
import { BOARD_LABELS } from '@agoy/annual-report-view/src/types/enums';
import EditField from '_shared/components/Inputs/EditField';
import {
  AnnualGeneralMeeting as AnnualGeneralMeetingType,
  SharesCompanyManagementReport as ManagementReportType,
  EconomicAssociationManagementReport,
  IndividualCompanyManagementReport,
  LimitedCompaniesManagementReport,
  Settings,
  Signatures,
} from '@agoy/annual-report-document';

import DocumentViewServiceContext from '_shared/services/document/DocumentViewServiceContext';
import AnnualReportDataServiceContext from '_annual-report/service/AnnualReportDataServiceContext';
import When from '_shared/components/When/When';

import ResetContentButton from '../../UI/ResetContent/ResetContentButton';
import LocationField from './LocationField';
import BoardMembersParagraph from './BoardMembersParagraph';
import Fee from './Fee';
import MeetingDate from './MeetingDate';
import Paragraph from './Paragraph';
import VotingList from './VotingList';
import AuditorsParagraph from './AuditorsParagraph';
import CustomParagraph from './CustomParagraph';
import Heading from '../../UI/Heading';
import EditingIconButton from '../../UI/EditingIconButton';
import ResultDisposition from './ResultDisposition/ResultDisposition';
import {
  mapAdjusterNames,
  hasAdjuster,
  isMatchingResultDisposition,
} from './utils';
import useDocumentLocking from '../../useDocumentLocking';

export const TypographyPreview = styled(Typography)`
  font-size: inherit;
`;

const Content = styled.div`
  min-width: 800px;
  padding: ${({ theme }) => theme.spacing(4)}px;
`;

const TitleContent = styled.div`
  width: 800px;
`;

export const EditContent = styled.div`
  width: 800px;
  padding-top: ${({ theme }) => theme.spacing(4)}px;
`;

export const PaddingTop = styled.div`
  padding-top: ${({ theme }) => theme.spacing(4)}px;
`;

export const AnnualGreetingAddButton = styled(Button)`
  font-size: inherit;
`;

const TitleField = styled(({ disableUnderline, ...rest }) => (
  <TextField size="small" variant="outlined" {...rest} />
))`
  .MuiInputBase-root {
    font-size: inherit;
    width: 800px;
  }
`;

const GridItem = styled(Grid)`
  margin-top: ${(props) => props.theme.spacing(6)}px;
`;

export const StyledNameField = styled(({ disableUnderline, ...rest }) => (
  <TextField size="small" variant="outlined" fullWidth {...rest} />
))`
  .MuiInputBase-root {
    font-size: inherit;
    width: 100%;
  }
`;

type AnnualGeneralMeetingContentProps = {
  annualGeneralMeeting: AnnualGeneralMeetingType;
  settings: Settings;
  signatures: Signatures;
  isDigitalSubmission: boolean;
  managementReport:
    | ManagementReportType
    | IndividualCompanyManagementReport
    | EconomicAssociationManagementReport
    | LimitedCompaniesManagementReport;
  version: '1' | '2';
  print?: boolean;
};

const AnnualGeneralMeetingContent = ({
  annualGeneralMeeting,
  settings,
  signatures,
  isDigitalSubmission,
  managementReport,
  version = '1',
  print = false,
}: AnnualGeneralMeetingContentProps): JSX.Element | null => {
  const { formatMessage } = useIntl();

  const service = useContext(DocumentViewServiceContext);

  const { annualGeneralMeeting: dataService } = useContext(
    AnnualReportDataServiceContext
  );

  const [locked] = useDocumentLocking(dataService);

  const isLocked = locked === 'locked';

  const { isAuditReportRequired } = settings.section;

  const [editing, setEditing] = useState(false);

  const ceo = signatures.section.people.rows?.find((row) =>
    stringValue(row.cells?.role)?.includes(BOARD_LABELS.CEO)
  );
  const currentFinancialYear = stringValue(settings.section.period);
  if (!currentFinancialYear) {
    return null;
  }

  let paragraph = 1;
  const chairman = annualGeneralMeeting.main.votingList.rows.find(
    (row) =>
      stringValue(row.cells?.role) === 'chairman' ||
      stringValue(row.cells?.role) === 'chairman_protocol' ||
      stringValue(row.cells?.role) === 'chairmanAndRecordKeeper'
  );

  const recordKeeper = annualGeneralMeeting.main.votingList.rows.find(
    (row) =>
      stringValue(row.cells?.role) === 'recordKeeper' ||
      stringValue(row.cells?.role) === 'chairmanAndRecordKeeper'
  );
  const adjuster = annualGeneralMeeting.main.votingList.rows.filter(
    (row) => stringValue(row.cells?.role) === 'adjuster'
  );

  const handleAddParagraph = () => {
    service.addRow('annualGeneralMeeting.main.customParagraphs');
  };

  const result =
    annualGeneralMeeting.main.result.type === 'ref' &&
    ccyFormat(referenceNumberValue(annualGeneralMeeting.main.result));

  const handleEditedTitle = (e) => {
    service.updateField('annualGeneralMeeting.main.heading', e);
  };

  const title =
    stringValue(annualGeneralMeeting.main.heading) ||
    formatMessage({ id: 'annualReport.menu.annualGeneralMeeting' });

  const defaultHeadingText = formatMessage(
    {
      id: `annualReport.annualGeneralMeeting.main.header`,
    },
    {
      clientName: stringValue(settings.section.clientName),
      organisationNumber: stringValue(settings.section.organisationNumber),
    }
  );

  const HeadingLocation =
    annualGeneralMeeting.main.location.value !== ''
      ? `, ${stringValue(annualGeneralMeeting.main.location)}`
      : '';

  const HeadingDate =
    annualGeneralMeeting.main.date.value !== ''
      ? `, ${stringValue(annualGeneralMeeting.main.date)}`
      : '';

  const headingText =
    stringValue(annualGeneralMeeting.main.headingText) ||
    `${defaultHeadingText}${HeadingLocation}${HeadingDate}.`;

  const handleHeadingText = (newValue) => {
    service.updateField('annualGeneralMeeting.main.headingText', newValue);
  };

  const handleEditedVotingListText = (newText) => {
    service.updateField('annualGeneralMeeting.main.votingListText', newText);
  };

  const votingListText =
    stringValue(annualGeneralMeeting.main.votingListText) ||
    formatMessage({
      id: 'annualReport.annualGeneralMeeting.main.votingList.text2',
    });

  const FormattedCurrentFinancialYear = currentFinancialYear
    .replace(/[\r\n]+/gm, '')
    .replace(/ /g, '');

  // The dates from the signatures in the table in Underskrifter av årsredovisning
  const signatureDatesAsStrings = signatures.section.people.rows.map((row) =>
    stringValue(row.cells?.date)
  );
  const convertedDates = signatureDatesAsStrings
    .map((date) => {
      if (date) {
        return parse(date, 'yyyy-MM-dd', new Date());
      }
    })
    .filter((item): item is Date => !!item);
  // fetches the latest date from all the signatures
  const latestDate = closestTo(new Date(), convertedDates);

  const calculateMaxDate = () =>
    isDigitalSubmission && latestDate
      ? new Date()
      : parse('2100-01-01', 'yyyy-MM-dd', new Date());

  const calculateMinDate = () =>
    isDigitalSubmission && latestDate
      ? latestDate
      : parse('1900-01-01', 'yyyy-MM-dd', new Date());

  const { periodStart, periodEnd } = settings.section;
  const finYearStart = stringValue(periodStart);
  const finYearEnd = stringValue(periodEnd);

  const shortFinancialYear =
    finYearStart?.endsWith('-01-01') && finYearEnd?.endsWith('-12-31')
      ? finYearEnd?.substring(0, 4)
      : `${finYearStart?.substring(0, 4)}/${finYearEnd?.substring(0, 4)}`;

  return (
    <Content>
      {!editing ? (
        <TitleContent>
          <Heading
            variant="h1"
            title={title}
            controls={
              <When isTrue={!isLocked}>
                <EditingIconButton
                  value={editing}
                  onClick={() => setEditing((value) => !value)}
                />

                <ResetContentButton id="annualGeneralMeeting" what={title} />
              </When>
            }
          />
        </TitleContent>
      ) : (
        <Box
          ml=".1rem"
          mt="1.75rem"
          fontSize="1.75rem"
          display="flex"
          width="900px"
        >
          <EditField
            component={TitleField}
            onChange={handleEditedTitle}
            value={title}
            placeholder={formatMessage({
              id: `annualReport.menu.annualGeneralMeeting`,
            })}
          />
          <When isTrue={!isLocked}>
            <EditingIconButton
              value={editing}
              onClick={() => setEditing((value) => !value)}
            />

            <ResetContentButton id="annualGeneralMeeting" what={title} />
          </When>
        </Box>
      )}

      {/* Location */}
      <LocationField
        field={annualGeneralMeeting.main.location}
        editing={editing}
        disabled={isLocked}
      />

      {/* Date */}
      {annualGeneralMeeting.main.date.type === 'string' && (
        <MuiPickersUtilsProvider utils={AdapterDateFns}>
          <MeetingDate
            minDate={calculateMinDate()}
            maxDate={calculateMaxDate()}
            value={stringValue(annualGeneralMeeting.main.date) || ''}
            disabled={isLocked}
          />
        </MuiPickersUtilsProvider>
      )}
      {/* Content below also goes to edit mode when AGM title is editable */}
      {!editing ? (
        <Typography>{headingText}</Typography>
      ) : (
        <EditField
          component={StyledNameField}
          onChange={handleHeadingText}
          value={headingText}
          placeholder={`${defaultHeadingText}${HeadingLocation}${HeadingDate}.`}
        />
      )}

      {/* Voting list */}
      <Paragraph
        no={paragraph++}
        id="votingList"
        print={print}
        headingId="annualGeneralMeeting.main.votingListHeading"
        heading={
          stringValue(annualGeneralMeeting.main.votingListHeading) ||
          formatMessage({
            id: `annualReport.annualGeneralMeeting.main.votingList`,
          })
        }
        optionalComment
        defaultText={formatMessage({
          id: `comment`,
        })}
        textId="annualGeneralMeeting.main.votingListHeadingText"
        text={stringValue(annualGeneralMeeting.main.votingListHeadingText)}
        editable={!isLocked}
      >
        {(editingChildren) => (
          <>
            <VotingList
              votingList={annualGeneralMeeting.main.votingList}
              fullId="annualGeneralMeeting.main.votingList"
              print={print}
              editable={!isLocked}
            />
            {!editingChildren ? (
              <Typography>{votingListText}</Typography>
            ) : (
              <EditField
                component={StyledNameField}
                onChange={handleEditedVotingListText}
                value={votingListText}
                placeholder={formatMessage({
                  id: 'annualReport.annualGeneralMeeting.main.votingList.text2',
                })}
              />
            )}
          </>
        )}
      </Paragraph>

      {/* Chairman */}
      <Paragraph
        no={paragraph++}
        id="2"
        print={print}
        headingId="annualGeneralMeeting.main.chairmanHeading"
        heading={
          stringValue(annualGeneralMeeting.main.chairmanHeading) ||
          formatMessage({
            id: `annualReport.annualGeneralMeeting.main.2`,
          })
        }
        editable={!isLocked}
        textId="annualGeneralMeeting.main.chairmanHeadingText"
        text={stringValue(annualGeneralMeeting.main.chairmanHeadingText)}
        defaultText={formatMessage(
          {
            id: `annualReport.annualGeneralMeeting.main.2.text`,
          },
          { name: stringValue(chairman?.cells?.name) }
        )}
      />

      {/* Record Keeper */}
      <Paragraph
        no={paragraph++}
        id="3"
        print={print}
        headingId="annualGeneralMeeting.main.recordKeeperHeading"
        heading={
          stringValue(annualGeneralMeeting.main.recordKeeperHeading) ||
          formatMessage({
            id: `annualReport.annualGeneralMeeting.main.3`,
          })
        }
        editable={!isLocked}
        textId="annualGeneralMeeting.main.recordKeeperHeadingText"
        text={stringValue(annualGeneralMeeting.main.recordKeeperHeadingText)}
        defaultText={formatMessage(
          {
            id: `annualReport.annualGeneralMeeting.main.3.text`,
          },
          {
            name: stringValue(recordKeeper?.cells?.name),
            role: stringValue(recordKeeper?.cells?.role),
          }
        )}
      />

      {/* Adjuster */}
      {hasAdjuster(adjuster) && (
        <Paragraph
          no={paragraph++}
          id="4"
          print={print}
          headingId="annualGeneralMeeting.main.adjusterHeading"
          heading={
            stringValue(annualGeneralMeeting.main.adjusterHeading) ||
            formatMessage({
              id: `annualReport.annualGeneralMeeting.main.4`,
            })
          }
          editable={!isLocked}
          textId="annualGeneralMeeting.main.adjusterHeadingText"
          text={stringValue(annualGeneralMeeting.main.adjusterHeadingText)}
          defaultText={formatMessage(
            {
              id: `annualReport.annualGeneralMeeting.main.4.text`,
            },
            { name: mapAdjusterNames(adjuster) }
          )}
        />
      )}

      {/* Notice and Agenda */}
      <Paragraph
        no={paragraph++}
        id="5"
        print={print}
        headingId="annualGeneralMeeting.main.noticeAndAgendaHeading"
        heading={
          stringValue(annualGeneralMeeting.main.noticeAndAgendaHeading) ||
          formatMessage({
            id: `annualReport.annualGeneralMeeting.main.5`,
          })
        }
        editable={!isLocked}
        textId="annualGeneralMeeting.main.noticeAndAgendaHeadingText"
        text={stringValue(annualGeneralMeeting.main.noticeAndAgendaHeadingText)}
        defaultText={formatMessage({
          id: `annualReport.annualGeneralMeeting.main.5.text`,
        })}
      />

      {/* Auditor report */}
      <Paragraph
        no={paragraph++}
        id="6"
        print={print}
        headingId="annualGeneralMeeting.main.auditorReportHeading"
        heading={
          stringValue(annualGeneralMeeting.main.auditorReportHeading) ||
          formatMessage(
            {
              id: `annualReport.annualGeneralMeeting.main.6`,
            },
            {
              auditor: !!isAuditReportRequired.value,
            }
          )
        }
        editable={!isLocked}
        textId="annualGeneralMeeting.main.auditorReportHeadingText"
        text={stringValue(annualGeneralMeeting.main.auditorReportHeadingText)}
        defaultText={formatMessage(
          {
            id: `annualReport.annualGeneralMeeting.main.6.text`,
          },
          {
            auditor: !!isAuditReportRequired.value,
            year: shortFinancialYear || '',
          }
        )}
      />

      {/* Results and balance sheet */}
      <Paragraph
        no={paragraph++}
        id="7"
        print={print}
        headingId="annualGeneralMeeting.main.resultAndBalanceHeading"
        heading={
          stringValue(annualGeneralMeeting.main.resultAndBalanceHeading) ||
          formatMessage({
            id: `annualReport.annualGeneralMeeting.main.7`,
          })
        }
        editable={!isLocked}
        textId="annualGeneralMeeting.main.resultAndBalanceHeadingText"
        text={stringValue(
          annualGeneralMeeting.main.resultAndBalanceHeadingText
        )}
        defaultText={formatMessage({
          id: `annualReport.annualGeneralMeeting.main.7.text`,
        })}
      />

      {/* Result Disposition */}
      <ResultDisposition
        no={paragraph++}
        annualGeneralMeeting={annualGeneralMeeting}
        print={print}
        result={result}
        editable={!isLocked}
        isMatchingResultDisposition={isMatchingResultDisposition(
          managementReport,
          annualGeneralMeeting
        )}
      />

      {/* Discharge */}
      <Paragraph
        no={paragraph++}
        id="9"
        print={print}
        headingId="annualGeneralMeeting.main.dischargeHeading"
        heading={
          stringValue(annualGeneralMeeting.main.dischargeHeading) ||
          formatMessage({
            id: `annualReport.annualGeneralMeeting.main.9`,
          })
        }
        editable={!isLocked}
        textId="annualGeneralMeeting.main.dischargeHeadingText"
        text={stringValue(annualGeneralMeeting.main.dischargeHeadingText)}
        defaultText={formatMessage(
          {
            id: `annualReport.annualGeneralMeeting.main.9.text`,
          },
          {
            ceo: ceo ? 'true' : 'false',
            year: FormattedCurrentFinancialYear || '',
          }
        )}
      />

      {/* Fee */}
      <Fee
        fee={stringValue(annualGeneralMeeting.main.fee) || ''}
        heading={stringValue(annualGeneralMeeting.main.feeHeading)}
        text={stringValue(annualGeneralMeeting.main.feeHeadingText)}
        print={print}
        no={paragraph++}
        editable={!isLocked}
      />

      {/* Board members */}
      <BoardMembersParagraph
        no={paragraph++}
        table={annualGeneralMeeting.main.boardMembers}
        print={print}
        heading={stringValue(annualGeneralMeeting.main.boardMembersHeading)}
        text={stringValue(annualGeneralMeeting.main.boardMembersHeadingText)}
        editable={!isLocked}
      />

      {/* Auditors */}
      <AuditorsParagraph
        no={paragraph++}
        annualGeneralMeeting={annualGeneralMeeting}
        print={print}
        editable={!isLocked}
      />

      {/* Custom paragraphs */}
      {annualGeneralMeeting.main.customParagraphs.rows.map((p) => (
        <CustomParagraph
          key={p.id}
          no={paragraph++}
          id={`annualGeneralMeeting.main.customParagraphs.${p.id}`}
          heading={stringValue(p.cells?.heading) || ''}
          text={stringValue(p.cells?.text) || ''}
          print={print}
        />
      ))}
      <Box marginY={4}>
        <AnnualGreetingAddButton
          startIcon={<AddIcon />}
          onClick={handleAddParagraph}
          disabled={isLocked}
        >
          {formatMessage({
            id: 'annualReport.annualGeneralMeeting.add.paragraph',
          })}
        </AnnualGreetingAddButton>
      </Box>

      {/* Termination */}
      <Paragraph
        no={paragraph++}
        id="13"
        print={print}
        headingId="annualGeneralMeeting.main.terminationHeading"
        heading={
          stringValue(annualGeneralMeeting.main.terminationHeading) ||
          formatMessage({
            id: `annualReport.annualGeneralMeeting.main.13`,
          })
        }
        // now the default comment always appears for this section
        defaultText={formatMessage({
          id: `annualReport.annualGeneralMeeting.main.13.defaultComment`,
        })}
        textId="annualGeneralMeeting.main.terminationHeadingText"
        text={stringValue(annualGeneralMeeting.main.terminationHeadingText)}
        editable={!isLocked}
      />
      <Box>
        <Grid container>
          <GridItem item sm={4}>
            {chairman?.cells?.name && (
              <>
                <Tooltip
                  title={formatMessage({
                    id: 'annualReport.annualGeneralMeeting.role.chairman.tooltip',
                  })}
                >
                  <Typography variant="caption">
                    {formatMessage({
                      id: 'annualReport.annualGeneralMeeting.role.chairman',
                    })}
                  </Typography>
                </Tooltip>
                <Typography>{stringValue(chairman.cells.name)}</Typography>
              </>
            )}
          </GridItem>
          {chairman !== recordKeeper && (
            <GridItem item sm={4}>
              <Typography variant="caption">
                {formatMessage({
                  id: 'annualReport.annualGeneralMeeting.role.recordKeeper',
                })}
              </Typography>
              <Typography>{stringValue(recordKeeper?.cells?.name)}</Typography>
            </GridItem>
          )}
          {adjuster &&
            adjuster.map((person) => (
              <GridItem item sm={4} key={person.id}>
                <Typography variant="caption">
                  {formatMessage({
                    id: 'annualReport.annualGeneralMeeting.role.adjuster',
                  })}
                </Typography>
                <Typography>{stringValue(person.cells?.name)}</Typography>
              </GridItem>
            ))}
        </Grid>
      </Box>
    </Content>
  );
};

type AnnualGeneralMeetingProps = {
  annualGeneralMeeting: AnnualGeneralMeetingType | undefined;
  settings: Settings | undefined;
  signatures: Signatures | undefined;
  isDigitalSubmission: boolean;
  managementReport:
    | ManagementReportType
    | IndividualCompanyManagementReport
    | EconomicAssociationManagementReport
    | LimitedCompaniesManagementReport
    | undefined;
  version: '1' | '2';
  print?: boolean;
};

const AnnualGeneralMeeting = ({
  annualGeneralMeeting,
  isDigitalSubmission,
  signatures,
  settings,
  managementReport,
  version,
  print,
}: AnnualGeneralMeetingProps) => {
  if (
    annualGeneralMeeting == null ||
    isDigitalSubmission == null ||
    signatures == null ||
    settings == null ||
    managementReport == null
  ) {
    return null;
  }

  return (
    <AnnualGeneralMeetingContent
      annualGeneralMeeting={annualGeneralMeeting}
      settings={settings}
      signatures={signatures}
      isDigitalSubmission={isDigitalSubmission}
      managementReport={managementReport}
      version={version}
      print={print}
    />
  );
};

export default AnnualGeneralMeeting;

/* eslint-disable import/prefer-default-export */

/**
 * Convert array to a map indexed by the given key.
 *
 * @param array
 * @param key
 */
export const indexByKey = <T extends Record<string, any>, K extends keyof T>(
  array: T[] | undefined,
  key: K
): Record<string, T> => {
  return (
    array?.reduce(
      (prev, item) => ({
        ...prev,
        [item[key]]: item,
      }),
      {} as Record<string, T>
    ) || {}
  );
};

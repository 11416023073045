import { id, or, ref, sum, table, value } from '@agoy/document';
import { IxbrlCell, NoteTwoTableSectionWithSum } from '../../../../common';
import { previousYear, year } from '../../../../common/utils/date-util';
import {
  active,
  field,
  ixbrlCell,
  ixbrlField,
  tupleRef,
} from '../../../../common/utils/util';
import { sumAccountsUBs } from '../../../../common/utils/accountSumsUtil';
import { NoteAccountRanges } from '../types';

const MONETARY_ITEM_TYPE = 'monetaryItemType';

export const note82Accounts: NoteAccountRanges[] = [
  {
    rowId: '1',
    ranges: [[2450, 2450]],
    cellLabel: 'Fakturerad men ej upparbetad intäkt',
    action: 'sumAccountsUBs',
  },
  {
    rowId: '2',
    ranges: [[2910, 2910]],
    cellLabel: 'Upplupna löner',
    action: 'sumAccountsUBs',
  },
  {
    rowId: '3',
    ranges: [[2920, 2920]],
    cellLabel: 'Upplupna semesterlöner',
    action: 'sumAccountsUBs',
  },
  {
    rowId: '4',
    ranges: [
      [2940, 2941],
      [2950, 2950],
    ],
    cellLabel: 'Upplupna sociala avgifter',
    action: 'sumAccountsUBs',
  },
  {
    rowId: '5',
    ranges: [[2960, 2960]],
    cellLabel: 'Upplupna räntekostnader',
    action: 'sumAccountsUBs',
  },
  {
    rowId: '6',
    ranges: [[2970, 2970]],
    cellLabel: 'Förutbetalda intäkter',
    action: 'sumAccountsUBs',
  },
  {
    rowId: '7',
    ranges: [[2971, 2971]],
    cellLabel: 'Förutbetalda hyresintäkter',
    action: 'sumAccountsUBs',
  },
];

export const getNote82 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null
): NoteTwoTableSectionWithSum => {
  return {
    active: false,
    number: value(undefined),
    name: value('Upplupna kostnader och förutbetalda intäkter'),
    table: table<IxbrlCell>(
      'notes.note82.table',
      {
        id: 'label',
        label: 'Upplupna kostnader och förutbetalda intäkter',
      },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      .addRows((rows) =>
        rows
          .addRow(
            '1',
            noteLabel('Fakturerad men ej upparbetad intäkt'),
            ixbrlCell(ref(sumAccountsUBs('2450', true)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:FaktureradEjUpparbetadIntakt',
              contextRef: 'balans0',
              saldo: 'credit',
            }),
            ixbrlCell(ref(sumAccountsUBs('2450', true, 'previousYear')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:FaktureradEjUpparbetadIntakt',
              contextRef: 'balans1',
              saldo: 'credit',
            })
          )
          .addRow(
            '2',
            noteLabel('Upplupna löner'),
            ixbrlCell(ref(sumAccountsUBs('2910', true)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:UpplupnaKostnaderForutbetaldaIntakterUpplupnaLoner',
              contextRef: 'balans0',
              saldo: 'credit',
            }),
            ixbrlCell(ref(sumAccountsUBs('2910', true, 'previousYear')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:UpplupnaKostnaderForutbetaldaIntakterUpplupnaLoner',
              contextRef: 'balans1',
              saldo: 'credit',
            })
          )
          .addRow(
            '3',
            noteLabel('Upplupna semesterlöner'),
            ixbrlCell(ref(sumAccountsUBs('2920', true)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:UpplupnaKostnaderForutbetaldaIntakterUpplupnaSemesterloner',
              contextRef: 'balans0',
              saldo: 'credit',
            }),
            ixbrlCell(ref(sumAccountsUBs('2920', true, 'previousYear')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:UpplupnaKostnaderForutbetaldaIntakterUpplupnaSemesterloner',
              contextRef: 'balans1',
              saldo: 'credit',
            })
          )
          .addRow(
            '4',
            noteLabel('Upplupna sociala avgifter'),
            ixbrlCell(ref(sumAccountsUBs('2940:2941+2950', true)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:UpplupnaKostnaderForutbetaldaIntakterUpplupnaSocialaAvgifter',
              contextRef: 'balans0',
              saldo: 'credit',
            }),
            ixbrlCell(
              ref(sumAccountsUBs('2940:2941+2950', true, 'previousYear')),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:UpplupnaKostnaderForutbetaldaIntakterUpplupnaSocialaAvgifter',
                contextRef: 'balans1',
                saldo: 'credit',
              }
            )
          )
          .addRow(
            '5',
            noteLabel('Upplupna räntekostnader'),
            ixbrlCell(ref(sumAccountsUBs('2960', true)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:UpplupnaKostnaderForutbetaldaIntakterUpplupnaRantekostnader',
              contextRef: 'balans0',
              saldo: 'credit',
            }),
            ixbrlCell(ref(sumAccountsUBs('2960', true, 'previousYear')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:UpplupnaKostnaderForutbetaldaIntakterUpplupnaRantekostnader',
              contextRef: 'balans1',
              saldo: 'credit',
            })
          )
          .addRow(
            '6',
            noteLabel('Förutbetalda intäkter'),
            ixbrlCell(ref(sumAccountsUBs('2970', true)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:UpplupnaKostnaderForutbetaldaIntakterForutbetaldaIntakter',
              contextRef: 'balans0',
              saldo: 'credit',
            }),
            ixbrlCell(ref(sumAccountsUBs('2970', true, 'previousYear')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:UpplupnaKostnaderForutbetaldaIntakterForutbetaldaIntakter',
              contextRef: 'balans1',
              saldo: 'credit',
            })
          )
          .addRow(
            '7',
            noteLabel('Förutbetalda hyresintäkter'),
            ixbrlCell(ref(sumAccountsUBs('2971', true)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:UpplupnaKostnaderForutbetaldaIntakterForutbetaldaHyresintakter',
              contextRef: 'balans0',
              saldo: 'credit',
            }),
            ixbrlCell(ref(sumAccountsUBs('2971', true, 'previousYear')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:UpplupnaKostnaderForutbetaldaIntakterForutbetaldaHyresintakter',
              contextRef: 'balans1',
              saldo: 'credit',
            })
          )
          .build()
      )
      .build(),
    secondTable: table<IxbrlCell>(
      'notes.note82.secondTable',
      {
        id: 'label',
        label: 'Övriga upplupna kostnader och förutbetalda intäkter',
      },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      .addRows((rows) =>
        rows
          .addRow(
            'typeOfInterimPost',
            ixbrlCell(value(''), {
              type: 'stringItemType',
              name: 'se-gen-base:OvrigaUpplupnaKostnaderForutbetaldaIntakterInterimspost',
              contextRef: 'balans0',
              tuple: {
                name: 'se-gaap-ext:OvrigaUpplupnaKostnaderForutbetaldaIntakterTuple',
                id: `${rows.getBaseId()}.typeOfInterimPost.tuple`,
                tupleID: `${rows.getBaseId()}.typeOfInterimPost.tuple`,
              },
              previousYearTuple: true,
              tupleRef: tupleRef(
                1,
                `${rows.getBaseId()}.typeOfInterimPost.tuple`
              ),
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OvrigaUpplupnaKostnaderForutbetaldaIntakter',
              contextRef: 'balans0',
              saldo: 'credit',
              tupleRef: tupleRef(
                2,
                `${rows.getBaseId()}.typeOfInterimPost.tuple_balans0`
              ),
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OvrigaUpplupnaKostnaderForutbetaldaIntakter',
              contextRef: 'balans1',
              saldo: 'credit',
              tupleRef: tupleRef(
                3,
                `${rows.getBaseId()}.typeOfInterimPost.tuple_balans1`
              ),
            })
          )
          .build()
      )
      .newRowTemplateGenerator((id, baseId) => ({
        id,
        active: true,
        cells: {
          label: ixbrlCell(value('Typ av interimspost'), {
            type: 'stringItemType',
            name: 'se-gen-base:OvrigaUpplupnaKostnaderForutbetaldaIntakterInterimspost',
            contextRef: 'balans0',
            tuple: {
              name: 'se-gaap-ext:OvrigaUpplupnaKostnaderForutbetaldaIntakterTuple',
              id: `${baseId}.${id}.tuple`,
              tupleID: `${baseId}.${id}.tuple`,
            },
            previousYearTuple: true,
            tupleRef: tupleRef(1, `${baseId}.${id}.tuple`),
          }),
          year: ixbrlCell(value(undefined), {
            type: MONETARY_ITEM_TYPE,
            name: 'se-gen-base:OvrigaUpplupnaKostnaderForutbetaldaIntakter',
            contextRef: 'balans0',
            saldo: 'credit',
            tupleRef: tupleRef(2, `${baseId}.${id}.tuple_balans0`),
          }),
          previousYear: ixbrlCell(value(undefined), {
            type: MONETARY_ITEM_TYPE,
            name: 'se-gen-base:OvrigaUpplupnaKostnaderForutbetaldaIntakter',
            contextRef: 'balans1',
            saldo: 'credit',
            tupleRef: tupleRef(3, `${baseId}.${id}.tuple_balans1`),
          }),
        },
      }))
      .build(),

    sumTable: table<IxbrlCell>(
      'notes.note82.sumTable',
      {
        id: 'label',
        label: '',
      },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      .addRows((rows) =>
        rows
          .addRow(
            'sum',
            noteLabel('Summa'),
            ixbrlCell(
              ref(
                or(
                  sum(
                    id('notes.note82.table.*.year'),
                    id('notes.note82.secondTable.*.year')
                  ),
                  0
                )
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:UpplupnaKostnaderForutbetaldaIntakter',
                contextRef: 'balans0',
              }
            ),
            ixbrlCell(
              ref(
                or(
                  sum(
                    id('notes.note82.table.*.previousYear'),
                    id('notes.note82.secondTable.*.previousYear')
                  ),
                  0
                )
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:UpplupnaKostnaderForutbetaldaIntakter',
                contextRef: 'balans1',
              }
            )
          )
          .build()
      )
      .build(),
    commentLabel: field('Kommentar till not'),
    comment: ixbrlField(active(field('')), {
      type: 'stringItemType',
      name: 'se-gen-base:NotUpplupnaKostnaderForutbetaldaIntakterKommentar',
      contextRef: 'period0',
    }),
    group: value(undefined),
    section: value(undefined),
    validForReportType: {
      k2: false,
      k3: true,
    },
  };
};

import { AgoyTable } from '../..';
import { AgoyTableRow } from '../../table';

export const findRow = (
  id: string,
  table: AgoyTable
): AgoyTableRow | undefined => {
  const subIds = id.split('.');
  const { rows } = table;
  const findSubRow = (
    rows: AgoyTableRow[] | undefined,
    subIds: string[]
  ): AgoyTableRow | undefined => {
    if (!rows) return undefined;

    const subId = subIds[0];
    const row = rows.find((row) => row.id === subId);
    if (row) {
      if (subIds.length > 1) return findSubRow(row.rows, subIds.slice(1));
      return row;
    }
    return undefined;
  };

  return findSubRow(rows, subIds);
};

export default findRow;

import { AgoyTable, Field } from '@agoy/document';
import { AnnualReportPart, AnnualReportSection } from '../../document';
import {
  CustomNotes,
  HeadingsSection,
  Note28Section,
  Note2Section,
  NoteInputFieldSection,
  NoteInvertedTwoTableSection,
  NoteOneTableSection,
  NoteOneTableWithSum,
  NotesSection,
} from '../../common/types/noteSections';

const cell = { type: 'field' as const };
const boolean = { type: 'boolean' as const };

export interface LimitedCompaniesNote1Section
  extends AnnualReportSection,
    NotesSection {
  disclaimerK2: Field;
  disclaimerK3: Field;
  changeOfAccountingRegulationsLabel: Field;
  changeOfAccountingRegulations: Field;
  deviationFromBasicAccountingPrinciplesLabel: Field;
  deviationFromBasicAccountingPrinciples: Field;
  serviceAndContractingAssignmentsLabel: Field;
  serviceAndContractingAssignments: Field;
  serviceAndContractingAssignmentsParagraph1: Field;
  serviceAndContractingAssignmentsParagraph2: Field;
  tangibleFixedAssetsLabel: Field;
  tangibleFixedAssetsGeneral: Field;
  tangibleFixedAssets: AgoyTable;
  receivablesLabel: Field;
  receivables: Field;
  otherAssetsProvisionsAndLiabilitiesLabel: Field;
  otherAssetsProvisionsAndLiabilities: Field;
  revenueRecognitionLabel: Field;
  revenueRecognition: Field;
  saleOfGoodsLabel: Field;
  saleOfGoods: Field;
  servicesLabel: Field;
  services: Field;
  acquisitionGoodsLabel: Field;
  acquisitionGoods: Field;
  changedPrinciplesLabel: Field;
  changedPrinciplesHint: Field;
  changedPrinciples: Field;
  rentsLabel: Field;
  rents: Field;
  interestRoyaltyAndDividensLabel: Field;
  interestRoyaltyAndDividens: Field;
  intangibleAssetsGeneralLabel: Field;
  intangibleAssetsGeneral: Field;
  intangibleAssetsTable: AgoyTable;
  tangibleAssetsCommentLabel: Field;
  tangibleAssetsComment: Field;
  intangibleAssetsProjectsClosed: Field;
  incomeTaxLabel: Field;
  incomeTaxParagraph1: Field;
  incomeTaxParagraph2: Field;
  incomeTaxParagraph3: Field;
  incomeTaxParagraph4: Field;
  incomeTaxParagraph5: Field;
  commentLabel: Field;
  comment: Field;
}

export interface LimitedCompaniesNotes extends AnnualReportPart {
  headings: HeadingsSection;
  note1: LimitedCompaniesNote1Section;
  note2: Note2Section;
  note3: NoteOneTableSection;
  note4: NoteOneTableSection;
  note5: NoteOneTableSection;
  note6: NoteOneTableSection;
  note7: NoteOneTableSection;
  note8: NoteOneTableSection;
  note9: NoteOneTableSection;
  note10: NoteOneTableSection;
  note11: NoteOneTableSection;
  note12: NoteOneTableSection;
  note13: NoteOneTableSection;
  note14: NoteOneTableSection;
  note15: NoteOneTableSection;
  note16: NoteInvertedTwoTableSection;
  note17: NoteOneTableSection;
  note18: NoteInvertedTwoTableSection;
  note19: NoteOneTableSection;
  note20: NoteInvertedTwoTableSection;
  note21: NoteOneTableSection;
  note22: NoteOneTableSection;
  note23: NoteOneTableSection;
  note24: NoteOneTableSection;
  note25: NoteOneTableSection;
  note26: NoteOneTableWithSum;
  note27: NoteOneTableSection;
  note28: Note28Section;
  note29: NoteOneTableWithSum;
  note30: NoteOneTableWithSum;
  note31: NoteOneTableWithSum;
  note32: NoteOneTableWithSum;
  note33: NoteOneTableWithSum;
  note34: NoteOneTableSection;
  note35: NoteInputFieldSection;
  note36: NoteInputFieldSection;
  note37: NoteOneTableSection;
  note38: NoteOneTableSection;
  note39: NoteOneTableSection;
  note40: NoteOneTableSection;
  note41: NoteOneTableSection;
  note42: NoteOneTableSection;
  note43: NoteOneTableSection;
  note44: NoteOneTableSection;
  note45: NoteInputFieldSection;
  note47: NoteInputFieldSection;
  note48: NoteInputFieldSection;
  custom: CustomNotes;
}

export const limitedCompaniesNotes = {
  type: 'part' as const,
  children: {
    active: boolean,
    headings: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        notesIncomeStatementTitle: cell,
        notesBalanceSheetTitle: cell,
        notesOtherTitle: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note1: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        disclaimerK2: cell,
        disclaimerK3: cell,
        changeOfAccountingRegulationsLabel: cell,
        changeOfAccountingRegulations: cell,
        deviationFromBasicAccountingPrinciplesLabel: cell,
        deviationFromBasicAccountingPrinciples: cell,
        serviceAndContractingAssignmentsLabel: cell,
        serviceAndContractingAssignments: cell,
        serviceAndContractingAssignmentsParagraph1: cell,
        serviceAndContractingAssignmentsParagraph2: cell,
        tangibleFixedAssetsLabel: cell,
        tangibleFixedAssetsGeneral: cell,
        tangibleFixedAssets: { type: 'table' as const },
        receivablesLabel: cell,
        receivables: cell,
        otherAssetsProvisionsAndLiabilitiesLabel: cell,
        otherAssetsProvisionsAndLiabilities: cell,
        revenueRecognitionLabel: cell,
        revenueRecognition: cell,
        saleOfGoodsLabel: cell,
        saleOfGoods: cell,
        servicesLabel: cell,
        services: cell,
        acquisitionGoodsLabel: cell,
        acquisitionGoods: cell,
        changedPrinciplesLabel: cell,
        changedPrinciplesHint: cell,
        changedPrinciples: cell,
        rentsLabel: cell,
        rents: cell,
        interestRoyaltyAndDividensLabel: cell,
        interestRoyaltyAndDividens: cell,
        intangibleAssetsGeneralLabel: cell,
        intangibleAssetsGeneral: cell,
        intangibleAssetsTable: { type: 'table' as const },
        tangibleAssetsCommentLabel: cell,
        tangibleAssetsComment: cell,
        intangibleAssetsProjectsClosed: cell,
        incomeTaxLabel: cell,
        incomeTaxParagraph1: cell,
        incomeTaxParagraph2: cell,
        incomeTaxParagraph3: cell,
        incomeTaxParagraph4: cell,
        incomeTaxParagraph5: cell,
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note2: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        noEmployees: cell,
        avgNumberOfEmployees: cell,
        avgNumberOfEmployeesTable: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note3: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note4: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note5: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note45: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        inputField: cell,
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note48: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        inputField: cell,
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note43: {
      type: 'part' as const,
      children: {
        // from an accounting perspective, it should come here
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note6: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note7: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note8: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note9: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note10: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note11: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note12: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note13: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note14: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note15: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note16: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        secondTable: { type: 'table' as const },
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note17: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    // only secondTable to inherit tableId
    note18: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        secondTable: { type: 'table' as const },
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note19: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    // only secondTable to inherit tableId
    note20: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        secondTable: { type: 'table' as const },
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note21: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note22: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note23: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        // table was deleted
        // keeping secondTable's IDs
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note24: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note25: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note26: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        sumTable: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note27: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note28: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        general: cell,
        table: { type: 'table' as const },
        secondTable: { type: 'table' as const },
        sumTable: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note29: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        sumTable: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note30: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        sumTable: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note31: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        sumTable: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note32: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        sumTable: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    // 40-42 and 44 must come after note 32 and before 33
    // they are all balance sheet notes
    note40: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note41: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note42: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note44: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note47: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        inputField: cell,
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note33: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        sumTable: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note34: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note35: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        inputField: cell,
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note36: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        inputField: cell,
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note37: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note38: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    note39: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        table: { type: 'table' as const },
        commentLabel: cell,
        comment: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
      },
    },
    custom: {
      type: 'array' as const,
      children: {
        type: 'part' as const,
        children: {
          active: boolean,
          number: cell,
          name: cell,
          group: cell,
          section: cell,
          validForReportType: {
            type: 'part' as const,
            children: {
              k2: boolean,
              k3: boolean,
            },
          },
          commentTitle: cell,
          typeOne: cell,
          typeTwo: { type: 'table' as const },
          typeThree: { type: 'table' as const },
        },
      },
    },
  },
};

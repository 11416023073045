import {
  value,
  ref,
  table,
  or,
  sum,
  id,
  ReferenceAccountInformation,
  account,
  RowsBuilder,
  multiply,
} from '@agoy/document';
import {
  IxbrlCell,
  NoteFourTablesWithCommentsAndSum,
} from '../../../../common';
import {
  active,
  field,
  ixbrlCell,
  ixbrlField,
  range,
  tupleRef,
} from '../../../../common/utils/util';
import { previousYear, year } from '../../../../common/utils/date-util';
import { sumAccountsUBs } from '../../../../common/utils/accountSumsUtil';
import { NoteAccountRanges } from '../types';

const MONETARY_ITEM_TYPE = 'monetaryItemType';

export const note77Accounts: NoteAccountRanges[] = [
  {
    rowId: 'accumulatedDepreciation',
    ranges: [[2150, 2153]],
    cellLabel: 'Ackumulerade överavskrivningar',
    action: 'sumAccountsUBs',
  },
];

const addAccountRows = (
  rows: RowsBuilder,
  accounts: Record<string, ReferenceAccountInformation>,
  accountRange: [number, number],
  tupleElementName: string,
  labelElementName: string,
  valueElementName: string
): RowsBuilder => {
  range(accountRange[0], accountRange[1] + 1).forEach((n) => {
    const accountNumber = n.toString();
    const accountInformation = accounts[accountNumber];

    if (accountInformation) {
      rows.addRow(
        `@${accountNumber}`,
        ixbrlCell(value(`${accountNumber} ${accountInformation.accountName}`), {
          type: 'stringItemType',
          name: labelElementName,
          contextRef: 'balans0',
          tuple: {
            name: tupleElementName,
            id: `${rows.getBaseId()}.@${accountNumber}.tuple`,
            tupleID: `${rows.getBaseId()}.@${accountNumber}.tuple`,
          },
          previousYearTuple: true,
          tupleRef: tupleRef(1, `${rows.getBaseId()}.@${accountNumber}.tuple`),
        }),
        ixbrlCell(
          ref(or(multiply(-1, account(accountNumber, undefined, 'year')), 0)),
          {
            type: MONETARY_ITEM_TYPE,
            name: valueElementName,
            contextRef: 'balans0',
            saldo: 'credit',
            tupleRef: tupleRef(
              2,
              `${rows.getBaseId()}.@${accountNumber}.tuple_balans0`
            ),
          }
        ),
        ixbrlCell(
          ref(
            or(
              multiply(-1, account(accountNumber, undefined, 'previousYear')),
              0
            )
          ),
          {
            type: MONETARY_ITEM_TYPE,
            name: valueElementName,
            contextRef: 'balans1',
            saldo: 'credit',
            tupleRef: tupleRef(
              3,
              `${rows.getBaseId()}.@${accountNumber}.tuple_balans1`
            ),
          }
        )
      );
    }
  });

  return rows;
};

export const getNote77 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null,
  accounts: Record<string, ReferenceAccountInformation>
): NoteFourTablesWithCommentsAndSum => {
  return {
    active: false,
    number: value(undefined),
    name: value('Obeskattade reserver'),
    table: table<IxbrlCell>(
      'notes.note77.table',
      {
        id: 'label',
        label: 'Periodiseringsfonder',
      },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      .addRows((rows) => {
        rows.addRow(
          'sum',
          noteLabel('Summa'),
          ixbrlCell(ref(or(sum(id('notes.note77.table.@*.year')), 0)), {
            type: MONETARY_ITEM_TYPE,
            name: 'se-gen-base:Periodiseringsfonder',
            contextRef: 'balans0',
            saldo: 'credit',
          }),
          ixbrlCell(ref(or(sum(id('notes.note77.table.@*.previousYear')), 0)), {
            type: MONETARY_ITEM_TYPE,
            name: 'se-gen-base:Periodiseringsfonder',
            contextRef: 'balans1',
            saldo: 'credit',
          })
        );
        addAccountRows(
          rows,
          accounts,
          [2110, 2139],
          'se-gaap-ext:PerioderingsfonderSpecifikationTuple',
          'se-gen-base:PerioderingsfonderSpecifikationFondAr',
          'se-gen-base:PerioderingsfonderSpecifikation'
        );
        return rows.build();
      })
      .newRowTemplateGenerator((id, baseId) => ({
        id,
        active: true,
        cells: {
          label: ixbrlCell(value('Periodseringsfond år'), {
            type: 'stringItemType',
            name: 'se-gen-base:PerioderingsfonderSpecifikationFondAr',
            contextRef: 'balans0',
            tuple: {
              name: 'se-gaap-ext:PerioderingsfonderSpecifikationTuple',
              id: `${baseId}.${id}.tuple`,
              tupleID: `${baseId}.${id}.tuple`,
            },
            previousYearTuple: true,
            tupleRef: tupleRef(1, `${baseId}.${id}.tuple`),
          }),
          year: ixbrlCell(value(undefined), {
            type: MONETARY_ITEM_TYPE,
            name: 'se-gen-base:PerioderingsfonderSpecifikation',
            contextRef: 'balans0',
            saldo: 'credit',
            tupleRef: tupleRef(2, `${baseId}.${id}.tuple_balans0`),
          }),
          previousYear: ixbrlCell(value(undefined), {
            type: MONETARY_ITEM_TYPE,
            name: 'se-gen-base:PerioderingsfonderSpecifikation',
            contextRef: 'balans1',
            saldo: 'credit',
            tupleRef: tupleRef(3, `${baseId}.${id}.tuple_balans1`),
          }),
        },
      }))
      .build(),
    firstTableCommentLabel: field('Kommentar'),
    firstTableComment: ixbrlField(active(field('')), {
      type: 'stringItemType',
      name: 'se-gen-base:ObeskattadeReserverPeriodiseringsfonderKommentar',
      contextRef: 'period0',
    }),

    secondTable: table<IxbrlCell>(
      'notes.note77.secondTable',
      {
        id: 'label',
        label: 'Ersättningsfonder',
      },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      .addRows((rows) => {
        rows.addRow(
          'sum',
          noteLabel('Summa'),
          ixbrlCell(ref(or(sum(id('notes.note77.secondTable.@*.year')), 0)), {
            type: MONETARY_ITEM_TYPE,
            name: 'se-gen-base:Ersattningsfonder',
            contextRef: 'balans0',
            saldo: 'credit',
          }),
          ixbrlCell(
            ref(or(sum(id('notes.note77.secondTable.@*.previousYear')), 0)),
            {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:Ersattningsfonder',
              contextRef: 'balans1',
              saldo: 'credit',
            }
          )
        );
        addAccountRows(
          rows,
          accounts,
          [2160, 2169],
          'se-gaap-ext:ErsattningsfonderTuple',
          'se-gen-base:ErsattningsfonderTyp',
          'se-gen-base:ErsattningsfonderPerTyp'
        );

        return rows.build();
      })
      .newRowTemplateGenerator((id, baseId) => ({
        id,
        active: true,
        cells: {
          label: ixbrlCell(value('Typ av ersättningsfond'), {
            type: 'stringItemType',
            name: 'se-gen-base:ErsattningsfonderTyp',
            contextRef: 'balans0',
            tuple: {
              name: 'se-gaap-ext:ErsattningsfonderTuple',
              id: `${baseId}.${id}.tuple`,
              tupleID: `${baseId}.${id}.tuple`,
            },
            previousYearTuple: true,
            tupleRef: tupleRef(1, `${baseId}.${id}.tuple`),
          }),
          year: ixbrlCell(value(undefined), {
            type: MONETARY_ITEM_TYPE,
            name: 'se-gen-base:ErsattningsfonderPerTyp',
            contextRef: 'balans0',
            saldo: 'credit',
            tupleRef: tupleRef(2, `${baseId}.${id}.tuple_balans0`),
          }),
          previousYear: ixbrlCell(value(undefined), {
            type: MONETARY_ITEM_TYPE,
            name: 'se-gen-base:ErsattningsfonderPerTyp',
            contextRef: 'balans1',
            saldo: 'credit',
            tupleRef: tupleRef(3, `${baseId}.${id}.tuple_balans1`),
          }),
        },
      }))
      .build(),
    secondTableCommentLabel: field('Kommentar'),
    secondTableComment: ixbrlField(active(field('')), {
      type: 'stringItemType',
      name: 'se-gen-base:ObeskattadeReserverPeriodiseringsfonderKommentar',
      contextRef: 'period0',
    }),

    thirdTable: table<IxbrlCell>(
      'notes.note77.thirdTable',
      {
        id: 'label',
        label: 'Andra obeskattade reserver',
      },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      .addRows((rows) => {
        rows.addRow(
          'sum',
          noteLabel('Summa'),
          ixbrlCell(ref(or(sum(id('notes.note77.thirdTable.@*.year')), 0)), {
            type: MONETARY_ITEM_TYPE,
            name: 'se-gen-base:AndraObeskattadeReserver',
            contextRef: 'balans0',
            saldo: 'credit',
          }),
          ixbrlCell(
            ref(or(sum(id('notes.note77.thirdTable.@*.previousYear')), 0)),
            {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndraObeskattadeReserver',
              contextRef: 'balans1',
              saldo: 'credit',
            }
          )
        );

        addAccountRows(
          rows,
          accounts,
          [2190, 2199],
          'se-gaap-ext:AndraObeskattadeReserverTuple',
          'se-gen-base:AndraObeskattadeReserverTyp',
          'se-gen-base:AndraObeskattadeReserverPerTyp'
        );
        return rows.build();
      })
      .newRowTemplateGenerator((id, baseId) => ({
        id,
        active: true,
        cells: {
          label: ixbrlCell(value('Typ av annan obeskattad reserv'), {
            type: 'stringItemType',
            name: 'se-gen-base:AndraObeskattadeReserverTyp',
            contextRef: 'balans0',
            tuple: {
              name: 'se-gaap-ext:AndraObeskattadeReserverTuple',
              id: `${baseId}.${id}.tuple`,
              tupleID: `${baseId}.${id}.tuple`,
            },
            previousYearTuple: true,
            tupleRef: tupleRef(1, `${baseId}.${id}.tuple`),
          }),
          year: ixbrlCell(value(undefined), {
            type: MONETARY_ITEM_TYPE,
            name: 'se-gen-base:AndraObeskattadeReserverPerTyp',
            contextRef: 'balans0',
            saldo: 'credit',
            tupleRef: tupleRef(2, `${baseId}.${id}.tuple_balans0`),
          }),
          previousYear: ixbrlCell(value(undefined), {
            type: MONETARY_ITEM_TYPE,
            name: 'se-gen-base:AndraObeskattadeReserverPerTyp',
            contextRef: 'balans1',
            saldo: 'credit',
            tupleRef: tupleRef(3, `${baseId}.${id}.tuple_balans1`),
          }),
        },
      }))
      .build(),
    thirdTableCommentLabel: field('Kommentar'),
    thirdTableComment: ixbrlField(active(field('')), {
      type: 'stringItemType',
      name: 'se-gen-base:AndraObeskattadeReserverKommentar',
      contextRef: 'period0',
    }),

    fourthTable: table<IxbrlCell>(
      'notes.note77.fourthTable',
      {
        id: 'label',
        label: 'Ackumulerade överavskrivningar',
      },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      .addRows((rows) =>
        rows
          .addRow(
            'accumulatedDepreciation',
            noteLabel('Ackumulerade överavskrivningar'),
            ixbrlCell(ref(sumAccountsUBs('2150:2153', true)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AckumuleradeOveravskrivningar',
              contextRef: 'balans0',
              saldo: 'credit',
            }),
            ixbrlCell(ref(sumAccountsUBs('2150:2153', true, 'previousYear')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AckumuleradeOveravskrivningar',
              contextRef: 'balans1',
              saldo: 'credit',
            })
          )
          .build()
      )
      .build(),

    sumTable: table<IxbrlCell>(
      'notes.note77.sumTable',
      {
        id: 'label',
        label: 'Summa av obeskattade reserver',
      },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      .addRows((rows) =>
        rows
          .addRow(
            'sum',
            noteLabel('Summa obeskattade reserver'),
            ixbrlCell(
              ref(
                or(
                  sum(
                    id('notes.note77.table.sum.year'),
                    id('notes.note77.secondTable.sum.year'),
                    id('notes.note77.thirdTable.sum.year'),
                    id('notes.note77.fourthTable.*.year')
                  ),
                  0
                )
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:ObeskattadeReserver',
                contextRef: 'balans0',
                saldo: 'credit',
              }
            ),
            ixbrlCell(
              ref(
                or(
                  sum(
                    id('notes.note77.table.sum.previousYear'),
                    id('notes.note77.secondTable.sum.previousYear'),
                    id('notes.note77.thirdTable.sum.previousYear'),
                    id('notes.note77.fourthTable.*.previousYear')
                  ),
                  0
                )
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:ObeskattadeReserver',
                contextRef: 'balans1',
                saldo: 'credit',
              }
            )
          )
          .build()
      )
      .build(),
    commentLabel: field('Kommentar till not'),
    comment: ixbrlField(active(field('')), {
      type: 'stringItemType',
      name: 'se-gen-base:NotObeskattadeReserverPeriodiseringsfonderKommentar',
      contextRef: 'period0',
    }),
    group: value(undefined),
    section: value(undefined),
    validForReportType: {
      k2: false,
      k3: true,
    },
  };
};

import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';

import { useApiSdk } from 'api-sdk';
import {
  setUserAttributes,
  addGlobalMessage,
  insertUserAttributes,
} from 'redux/actions';
import { useSelector } from 'redux/reducers';
import config from '_shared/services/config';
import { authContext } from '_authentication/HOC/withAuth';
import useAuth from '_authentication/hooks/useAuth';
import ProfileEdit from '_users/components/organisms/ProfileEdit';
import { UserType } from '_users/types';

const Container = () => {
  const dispatch = useDispatch();
  const auth = useAuth();
  const sdk = useApiSdk();
  const { refreshSessionAWS } = useContext(authContext);

  const user = useSelector((state) => state.user);

  const sendErrorMessage = (message: string) => {
    dispatch(addGlobalMessage('error', message));
  };

  const handleProfileUpdate = async (newProfilePicture: File) => {
    const response = await sdk.uploadUserProfilePicture({
      requestBody: newProfilePicture,
    });
    dispatch(insertUserAttributes({ picture: response.url }));
    refreshSessionAWS();
  };

  const handleMemberUpdate = async (data: Partial<UserType>) => {
    await dispatch(
      setUserAttributes({
        givenName: data.givenName,
        familyName: data.familyName,
        phoneNumber: data.phoneNumber,
        personNr: data.personNr,
      })
    );
  };

  const handleUpdatePassword = async (
    oldPassword: string,
    newPassword: string
  ) => {
    await auth.changePasswordAWS({
      oldPassword,
      newPassword,
    });
  };

  return (
    <ProfileEdit
      isFortnoxWhiteLabel={config.whiteLabel === 'fortnox'}
      user={user}
      sendErrorMessage={sendErrorMessage}
      handleProfileUpdate={handleProfileUpdate}
      handleMemberUpdate={handleMemberUpdate}
      handleUpdatePassword={handleUpdatePassword}
    />
  );
};

export default Container;

import React, { useState, useMemo, useEffect } from 'react';
import styled from '@emotion/styled';
import { Redirect } from 'react-router-dom';

import useAuth from '_authentication/hooks/useAuth';
import useQuery from '_authentication/hooks/useQuery';
import LoginBackground from 'assets/login-background.jpg';
// @ts-ignore
import { ReactComponent as Logo } from 'assets/agoy_login.svg';
import ResendCodeContent from './ResendCodeContent';
import ConformationContent from './ConfirmationContent';

const AgoyLogo = styled(Logo)`
  position: absolute;
  top: 32px;
  left: 80px;
  width: 160px;
  color: ${(props) => props.theme.palette.secondary.main};
`;

const Wrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  height: 100vh;
`;

const AuthorizationWrapper = styled.div`
  background-color: #fff;
  width: 50vw;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AuthorizationImageContainer = styled.div`
  display: flex;
  background: url(${LoginBackground});
  background-size: cover;
  width: 50vw;
`;

const AdContainer = styled.div`
  margin: auto;
  border-radius: 4px;
  background-color: rgba(0, 49, 60, 0.6);
  padding: 1.5rem;
  color: white;
  max-width: 685px;
  & > h2 {
    font-size: 2.5rem;
    margin: 0;
    color: #00ce7c;
  }
  & > p:last-of-type {
    margin-bottom: 0;
  }
  & a {
    color: #00ce7c;
  }
`;

const ConfirmView = (props) => {
  const auth = useAuth();
  const [openView, setOpenView] = useState<'confirm' | 'resend'>('confirm');
  const emailQuery = useQuery().get('email');
  const [email, setEmail] = useState(emailQuery || '');

  const openConfirm = (data) => {
    if (data) {
      setEmail(data.username);
    }
    setOpenView('confirm');
  };

  const redirectWithEmail = (route, query?) => {
    const completeRoute = query
      ? `/${route}?email=${encodeURIComponent(query)}`
      : `/${route}`;
    props.history.push(completeRoute);
  };
  const openCreate = () => redirectWithEmail('register');
  const openLogin = (query) => redirectWithEmail('login', query);
  const openResend = () => setOpenView('resend');

  const currentView = useMemo(() => {
    switch (openView) {
      case 'confirm':
        return (
          <ConformationContent
            openResend={openResend}
            backToCreate={openCreate}
            backToLogin={openLogin}
            email={email}
          />
        );
      case 'resend':
        return <ResendCodeContent backToConfirm={openConfirm} email={email} />;
      default: {
        break;
      }
    }
  }, [openView]);

  useEffect(() => {
    // fetch current session
    auth.getCurrentSessionAWS();
  }, []);

  if (!auth.isLoading && auth.isAuthenticated) {
    return <Redirect to="/" />;
  }

  return (
    <Wrapper>
      <AuthorizationWrapper>
        <AgoyLogo />
        {currentView}
      </AuthorizationWrapper>
      <AuthorizationImageContainer>
        <AdContainer>
          <h2>Vill du vara med och revolutionera redovisningsvärlden?</h2>
          <p>Välkommen till Agoy!</p>
          <p>Registrera ditt konto nu.</p>
        </AdContainer>
      </AuthorizationImageContainer>
    </Wrapper>
  );
};

export default ConfirmView;

import React, { useState } from 'react';
import styled from '@emotion/styled';
import {
  Dialog,
  IconButton,
  TextField as MuiTextField,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';

import Button from '_shared/components/Buttons/Button';
import { useSelector } from 'redux/reducers';
import { ClientsRouteParams } from 'routes/types';
// @ts-ignore
import { ReactComponent as LockImage } from 'assets/Lock-illustration.svg';
// @ts-ignore
import { ReactComponent as UnLockImage } from 'assets/Unlock-illustration.svg';
import { getCurrentPeriodStatusAnnualReport } from '_annual-report/redux/annual-report/reducer/reducers/annualReportPeriodStatus';
import { setProgramStatus } from '_shared/components/StatusSelector/StatusSelector';
import { fetchProgramStatus } from '_shared/redux/actions';
import { addGlobalErrorMessage } from '_messages/redux/actions';
import { describeRequiredChanges } from '_shared/services/ProgramStatus/errorHandling';
import ApproveChangesDialog from '_shared/components/StatusSelector/ApproveChangesDialog';
import MissingClientStateDialog from '_shared/components/MissingClientStartDateDialog';
import { asResultClass, useApiSdk } from 'api-sdk';
import { stringValue } from '@agoy/document';
import { Settings } from '@agoy/annual-report-document';

const CloseButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
`;

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    padding: ${({ theme }) => theme.spacing(4)}px;
    display: grid;
    width: 800px;
  }
`;

const LockingTitle = styled(Typography)`
  font-weight: 600;
  justify-self: center;
`;

const LockingField = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto);
  margin: ${({ theme }) => theme.spacing(4)}px 0px;
`;

const LockingForm = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)}px;
`;

const LockingParagraph = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.primary};
  text-align: initial;
  white-space: pre-line;
`;

interface LockingModalProps {
  isOpen: boolean;
  onClose: () => void;
  customerName: string;
  submissionApproved?: boolean;
}

const LockingModal = ({
  isOpen,
  onClose,
  customerName,
  submissionApproved,
}: LockingModalProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const {
    params: { clientId },
  } = useRouteMatch<ClientsRouteParams>();

  const dispatch = useDispatch();
  const sdk = useApiSdk();

  const currentPeriodStatus = getCurrentPeriodStatusAnnualReport(
    useSelector((state) => state)
  );
  const { currentFinancialYear } = useSelector((state) => state.customerView);

  const isLocked = currentPeriodStatus === 'LOCKED';

  const [lockFieldValue, setLockFieldValue] = useState<string>('');

  const [isLocking, setLocking] = useState(false);

  const [approve, setApprove] = useState<{
    changes: ReturnType<typeof describeRequiredChanges>;
    status: string;
  } | null>(null);

  const [error, setError] = useState<string | null>(null);

  // Unlocking the report unchecks the signature checkbox in AnnualSubmission.tsx
  const uncheckSignature = async () => {
    if (!clientId || !currentFinancialYear) return;

    const documentType = 'annualReport';

    const getSignatureReq = await asResultClass(
      sdk.getAnnualReportSignature({
        clientid: clientId,
        financialYear: currentFinancialYear,
        documentType,
      })
    );

    if (getSignatureReq.err) {
      const getError = getSignatureReq.val.message;
      if (getError === 'Not found') {
        return;
      }
      dispatch(addGlobalErrorMessage('error'));
      return;
    }

    if (getSignatureReq.val.signature === true) {
      const setSignatureReq = await asResultClass(
        sdk.setAnnualReportSignature({
          clientid: clientId,
          financialYear: currentFinancialYear,
          requestBody: { signature: false, documentType },
        })
      );

      if (setSignatureReq.err) {
        dispatch(addGlobalErrorMessage('error'));
      }
    }
  };

  const lockSetup = {
    lockingTitle: formatMessage({ id: 'locking.report.title' }).toUpperCase(),
    lockingParagraph: formatMessage(
      { id: 'locking.report.paragraph' },
      { clientCompanyName: customerName }
    ),
    lockButtonText: formatMessage({ id: 'locking.lock' }),
    lockButtonCondition:
      lockFieldValue !== formatMessage({ id: 'locking.lock' }).toUpperCase(),
  };

  if (isLocked) {
    lockSetup.lockingTitle = formatMessage({
      id: 'unlocking.report.title',
    }).toUpperCase();
    lockSetup.lockingParagraph = !submissionApproved
      ? formatMessage(
          { id: 'unlocking.report.paragraph' },
          { clientCompanyName: customerName }
        )
      : formatMessage(
          { id: 'unlocking.report.paragraph.completed' },
          { clientCompanyName: customerName }
        );
    lockSetup.lockButtonText = formatMessage({ id: 'unlocking.lock' });
    lockSetup.lockButtonCondition = lockFieldValue === '';
  }

  const handlePeriodSetLockStatus = async (
    selectedStatus: string,
    approved: boolean
  ) => {
    if (currentFinancialYear) {
      setLocking(true);
      const result = await setProgramStatus(
        clientId,
        'AN_REPORT',
        'financialYear',
        currentFinancialYear,
        selectedStatus,
        formatMessage,
        approved,
        lockFieldValue !== formatMessage({ id: 'locking.lock' }).toUpperCase()
          ? lockFieldValue
          : undefined
      );

      if (result.ok) {
        await dispatch(fetchProgramStatus(clientId, 'AN_REPORT'));
        await uncheckSignature();
        onClose();
      } else if (typeof result.val === 'string') {
        switch (result.val) {
          case 'MISSING_CLIENT_START_DATE':
            setError(result.val);
            break;
          default:
            dispatch(addGlobalErrorMessage(undefined, result.val));
        }
      } else {
        setApprove({ changes: result.val, status: selectedStatus });
      }
      setLocking(false);
    }
  };

  const onApproved = () => {
    if (approve) {
      const { status } = approve;
      handlePeriodSetLockStatus(status, true);
    }
  };

  return (
    <>
      <StyledDialog open={isOpen} onClose={onClose}>
        <CloseButton onClick={onClose}>
          <CloseIcon />
        </CloseButton>
        <LockingTitle variant="h4">{lockSetup.lockingTitle}</LockingTitle>
        <LockingParagraph>{lockSetup.lockingParagraph}</LockingParagraph>
        <LockingField>
          {isLocked ? <UnLockImage /> : <LockImage />}
          <LockingForm>
            <MuiTextField
              size="small"
              variant="outlined"
              onChange={(event) => {
                setLockFieldValue(event.target.value);
              }}
              value={lockFieldValue}
              disabled={isLocking}
              InputProps={{
                style: { height: '42.25px', borderRadius: '4px 0px 0px 4px' },
              }}
            />

            <Button
              startIcon={isLocked ? <LockOpenIcon /> : <LockIcon />}
              onClick={() => {
                handlePeriodSetLockStatus(isLocked ? 'DONE' : 'LOCKED', false);
              }}
              disabled={lockSetup.lockButtonCondition}
              loading={isLocking}
              size="large"
              label={lockSetup.lockButtonText}
            />
          </LockingForm>
        </LockingField>
      </StyledDialog>
      {approve && (
        <ApproveChangesDialog
          changes={approve.changes}
          onCancel={() => {
            setApprove(null);
          }}
          onApproved={onApproved}
        />
      )}
      {error === 'MISSING_CLIENT_START_DATE' && (
        <MissingClientStateDialog onClose={() => setError(null)} />
      )}
    </>
  );
};

export default LockingModal;

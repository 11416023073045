import {
  AgoyTableRow,
  AgoyTableColumn,
  NumberCell,
  ReferenceCell,
  isResolveReferenceError,
} from '@agoy/document';
import { groupBy } from 'lodash';
import { defaultRolesForSignatureTable } from '@agoy/annual-report-document';

/**
 * Helper for UI table component which flattens rows ONE depth and creates id based on parent id
 * @param rows AgoyTable rows
 * @returns AgoyTableRow[]
 */
export const flattenRows = (rows: AgoyTableRow[]) => {
  const result: AgoyTableRow[] = [];
  if (!rows) return result;

  rows.forEach((row) => {
    if (row.cells) result.push(row);
    if (row.rows) {
      row.rows.forEach((subRow) => {
        result.push({ ...subRow, id: `${row.id}.${subRow.id}` });
      });
    }
  });

  return result;
};

/**
 * Checks if a table row in a Note should be included in printed annual report
 * @param row AgoyTableRow to check
 */
export const includeRowInPrint = (row: AgoyTableRow) => {
  if (!row.cells) return false;
  const groupedByType = groupBy(row.cells, 'type');

  /**
   * There are some rows which only have cell label with no other properties
   * these are like title rows and should be included in the print together
   * with the rows that are included in the print i.e. rows that have cell values in year and/or previousYear
   * */
  const keys = Object.keys(row.cells);
  if (keys?.length === 1 && keys[0] === 'label') {
    return true;
  }

  // Only returns row if not all cell values are undefined
  // having this one before the one below, allows edited ref values to appear
  // because they become numbers
  if (groupedByType.number) {
    return (
      groupedByType.number.filter(
        (cell) => (cell as NumberCell).value !== undefined
      ).length > 0
    );
  }

  // Always returns row if it contains cell reference unless it is undefined
  if (groupedByType.ref) {
    return (
      groupedByType.ref.length > 0 &&
      groupedByType.ref.some((cell) => {
        const { value } = cell as ReferenceCell;
        return value != undefined && !isResolveReferenceError(value);
      })
    );
  }

  return false;
};

/**
 * Includes a string row in the print if the second column of the row has a string.
 * @param row AgoyTableRow to check
 */
export const includeRowWithStringInSecondColumn = (row: AgoyTableRow) => {
  if (!row.cells) return false;
  const groupedByType = groupBy(row.cells, (row) =>
    row.type === 'label' ? 'string' : row.type
  );

  if (
    groupedByType.string.length >= 2 &&
    groupedByType.string[1].type === 'string' &&
    groupedByType.string[1].value !== ''
  ) {
    return groupedByType.string;
  }

  return false;
};

/**
 * Includes a string row in the print if the any of the value columns has string (first column not included)
 * @param row AgoyTableRow to check
 */
export const includeRowWithStringValues = (row: AgoyTableRow) => {
  if (!row.cells) return false;
  const groupedByType = groupBy(row.cells, 'type');

  if (
    groupedByType.string.length >= 2 &&
    groupedByType.string
      .slice(1)
      .some((cell) => cell.type === 'string' && !!cell.value)
  ) {
    return groupedByType.string;
  }

  return false;
};

/**
 * Includes a row if its of type string
 */
export const includeStringRow = (row: AgoyTableRow) => {
  if (!row.cells) return false;
  const groupedByType = groupBy(row.cells, 'type');

  if (groupedByType.string) {
    return groupedByType.string;
  }

  return false;
};

export const hasPreviousYearColumn = (column: AgoyTableColumn): boolean => {
  if (
    column.id === 'previousYear' ||
    column.id === 'carryingAmountPreviousYear'
  ) {
    return true;
  }
  return false;
};

export const hasCustomRole = (roleValues: string[]) => {
  const allRoles = Object.values(defaultRolesForSignatureTable).flat();
  return !!roleValues.filter((item) => !allRoles.includes(item));
};

import expandIds from '../expandIds';
import {
  ResolvedReference,
  ResolveReferenceContext,
  ResolveReferenceInput,
  Values,
} from '../types';

/**
 * A context that resolves values.
 *
 * The values can be prefixed by the context meaning that
 * an id like "prefix.my.value" will map to the key "my.values" when
 * the context is created with the name "prefix".
 *
 */
export class ValuesContext implements ResolveReferenceContext {
  /**
   * Prefix, this is the name with a dot (.) after.
   */
  prefix: string | null;

  /**
   * Values to resolve, with out the prefix
   */
  values: Values;

  /**
   * Not used by this contexrt
   */
  input: ResolveReferenceInput;

  /**
   * All keys from the values with the prefix added
   */
  ids: string[];

  constructor(
    name: string | null,
    values: Values,
    input: ResolveReferenceInput,
    parent?: ResolveReferenceContext
  ) {
    this.prefix = name && `${name}.`;
    this.values = values;
    this.input = input;
    this.parent = parent;
    this.ids = this.prefix
      ? Object.keys(values).map((key) => `${this.prefix}${key}`)
      : Object.keys(values);
  }

  resolveById(id: string, context: ResolveReferenceContext): ResolvedReference {
    if (this.prefix === null || id.startsWith(this.prefix)) {
      // Remove the prefix
      const subId = this.prefix ? id.substring(this.prefix.length) : id;

      // Check that the id is a key in the values
      if (subId in this.values) {
        // Return the value
        return this.values[subId];
      }
    }
    if (this.parent) {
      return this.parent.resolveById(id, context);
    }
    return {
      error: 'missingId',
    };
  }

  expandIds(ids: string): string[] {
    const matches = expandIds(this.ids)(ids);

    if (this.parent?.expandIds) {
      return matches.concat(this.parent.expandIds(ids));
    }

    return matches;
  }

  resolveConfig(
    id: string,
    context: ResolveReferenceContext
  ): ResolvedReference {
    return this.parent?.resolveConfig(id, context);
  }

  parent?: ResolveReferenceContext | undefined;
}

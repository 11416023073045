import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Card } from '@material-ui/core';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';

import { addGlobalErrorMessage } from '_messages/redux/actions';
import { ChecklistTemplate } from '_shared/redux/checklist/types';
import { createChecklistTemplate } from '_organization/redux/actions';
import Typography from '_shared/components/Typography/Typography';
import ChecklistForm from './Components/ChecklistForm/ChecklistForm';

const StyledCard = styled(Card)`
  padding: ${({ theme }) => theme.spacing(2)}px
    ${({ theme }) => theme.spacing(2)}px;
  min-width: 1000px;
  margin-bottom: ${({ theme }) => theme.spacing(9)}px;
`;

const CreateChecklist = (): JSX.Element => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const handleChangeChecklist = async (checklist: ChecklistTemplate) => {
    try {
      setLoading(true);
      dispatch(createChecklistTemplate(checklist));
      setLoading(false);
      history.push(`/organisation/checklists`);
    } catch (e) {
      setLoading(false);
      dispatch(addGlobalErrorMessage('error'));
    }
  };

  const handleCancel = async () => {
    history.push(`/organisation/checklists`);
  };

  return (
    <StyledCard>
      <Typography variant="h2">
        {formatMessage({ id: 'checklist.create.title' })}
      </Typography>
      <ChecklistForm
        onChangeChecklist={handleChangeChecklist}
        onCancel={handleCancel}
        loading={loading}
      />
    </StyledCard>
  );
};

export default CreateChecklist;

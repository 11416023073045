import styled from '@emotion/styled';
import shouldForwardProp from '@emotion/is-prop-valid';

const Input = styled.input`
  resize: none;
  border: none;
  outline: none;
  background: none;
  padding: 0;

  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
  vertical-align: top;

  font-size: inherit;
  font-weight: inherit;
  font-family: inherit;
  line-height: inherit;
  text-align: inherit;
  color: inherit;
`;

const TextArea = Input.withComponent('textarea');

const InputContainerStyle = styled.div`
  border-radius: ${({ theme }) => theme.spacing(1)}px;
  padding: 9px 8px 10px 8px; // to avoid Fortnox 8.5px paddings
  width: 100%;

  font-family: 'Exo 2';
  font-size: inherit;
  line-height: 21px;
  font-weight: inherit;
  word-break: break-word;
  letter-spacing: normal;
  color: ${({ theme }) => theme.palette.fortnoxText.onLight};

  svg {
    color: ${({ theme }) => theme.palette.fortnoxText.onLight};
  }

  &.editing {
    display: flex;
    flex-direction: row;
    background: #fff;
    outline: 1px solid ${({ theme }) => theme.palette.border.dark};

    &.selector,
    &.selector input {
      cursor: pointer;
    }
    :hover,
    :focus {
      outline-color: ${({ theme }) => theme.palette.border.darkGreen};
    }
  }

  input::placeholder,
  textarea::placeholder {
    color: ${({ theme }) => theme.palette.fortnoxText.placeholder};
  }

  &.disabled {
    background: ${({ theme }) => theme.palette.input.disabled};
    color: ${({ theme }) => theme.palette.fortnoxText.disabled};

    &.editing {
      outline: 1px solid ${({ theme }) => theme.palette.border.medium};
    }
  }
`;

const MenuItem = styled.li`
  font-size: inherit;
  font-weight: inherit;
  font-family: inherit;
  line-height: inherit;
  color: inherit;
  text-align: inherit;
  width: 100%;
`;

export { Input, TextArea, InputContainerStyle, MenuItem };

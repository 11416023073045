import React from 'react';
import styled from '@emotion/styled';
import { useSelector } from 'redux/reducers';

import { currentCustomer } from '_clients/redux/customer-view/selectors';
import { currentFinancialReport } from '_financial-report/redux/selectors';

type OrganisationLogoProps = {
  print?: boolean;
};

const OrganisationLogoWrapper = styled.div<OrganisationLogoProps>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ print }) => (print ? '60px' : '40px')};
  height: ${({ print }) => (print ? '60px' : '40px')};
  bottom: ${({ print }) => (print ? '0' : '20px')};
  right: ${({ print }) => (print ? '0' : '30px')};
`;

const Image = styled.img`
  max-width: 100%;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
`;

const OrganisationLogo = ({
  print = false,
}: OrganisationLogoProps): JSX.Element | null => {
  const financialReport = useSelector(currentFinancialReport);
  const customer = useSelector(currentCustomer);
  const useClientLogo = financialReport?.settings.section.useClientLogo;
  const organisationLogo =
    useSelector((state) => state.organisation.logo) || undefined;
  const clientLogo = customer?.logo || undefined;

  if ((!useClientLogo && !organisationLogo) || (useClientLogo && !clientLogo)) {
    return null;
  }

  return (
    <OrganisationLogoWrapper print={print}>
      <Image src={useClientLogo ? clientLogo : organisationLogo} />
    </OrganisationLogoWrapper>
  );
};

export default OrganisationLogo;

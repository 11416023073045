import { id, label, or, ref, sum, value } from '@agoy/document';
import {
  boolean,
  cell,
  CustomNotes,
  NoteInputFieldSection,
  noteInputFieldSectionStructure,
  NoteOneTableSection,
  noteOneTableSectionStructure,
  NoteOneTableWithSum,
  noteOneTableWithSumStructure,
} from '../../../common';
import { previousYear, year } from '../../../common/utils/date-util';
import { active, field, table } from '../../../common/utils/util';

export interface OtherNotes {
  note33: NoteOneTableWithSum;
  note34: NoteOneTableSection;
  note35: NoteInputFieldSection;
  note36: NoteInputFieldSection;
  note37: NoteOneTableSection;
  note38: NoteOneTableSection;
  note39: NoteOneTableSection;
  note50: NoteInputFieldSection;
  note51: NoteInputFieldSection;
  custom: CustomNotes;
}

export const otherNotesStructure = {
  note33: noteOneTableWithSumStructure,
  note34: noteOneTableSectionStructure,
  note35: noteInputFieldSectionStructure,
  note36: noteInputFieldSectionStructure,
  note37: noteOneTableSectionStructure,
  note38: noteOneTableSectionStructure,
  note39: noteOneTableSectionStructure,
  note50: noteInputFieldSectionStructure,
  note51: noteInputFieldSectionStructure,
  custom: {
    type: 'array' as const,
    children: {
      type: 'part' as const,
      children: {
        active: boolean,
        number: cell,
        name: cell,
        group: cell,
        section: cell,
        validForReportType: {
          type: 'part' as const,
          children: {
            k2: boolean,
            k3: boolean,
          },
        },
        commentTitle: cell,
        typeOne: cell,
        typeTwo: { type: 'table' as const },
        typeThree: { type: 'table' as const },
      },
    },
  },
};

export const getFoundationOtherNotes = (
  financialYears: string[],
  financialYear: string,
  period: string | null,
  isDigitalSubmission: boolean
): OtherNotes => {
  const getNoteLabel = (name: string) =>
    isDigitalSubmission ? label(name) : value(name);

  return {
    note33: {
      active: false,
      number: value(undefined),
      name: value('Ställda säkerheter'),
      table: table(
        'notes.note33.table',
        { id: 'label', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        .addRows((rows) =>
          rows
            // The IDs aren't ordered because the structure was changed
            // but old IDs were kept to persist old data where present
            .addRow(
              '5',
              getNoteLabel('Företagsinteckningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '6',
              getNoteLabel(
                'Varav till koncernföretag, intresseföretag eller gemensamt styrt företag'
              ),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '1',
              getNoteLabel('Fastighetsinteckningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '2',
              getNoteLabel(
                'Varav till koncernföretag, intresseföretag eller gemensamt styrt företag'
              ),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '9',
              getNoteLabel('Tillgångar med äganderättsförbehåll'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '10',
              getNoteLabel(
                'Varav till koncernföretag, intresseföretag eller gemensamt styrt företag'
              ),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '13',
              getNoteLabel('Belånade fordringar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '14',
              getNoteLabel(
                'Varav till koncernföretag, intresseföretag eller gemensamt styrt företag'
              ),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '17',
              getNoteLabel('Andra ställda säkerheter'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '18',
              getNoteLabel(
                'Varav till koncernföretag, intresseföretag eller gemensamt styrt företag'
              ),
              value(undefined),
              value(undefined)
            )
            .build()
        )
        .newRowTemplate(value(''), value(undefined), value(undefined))
        .build(),
      sumTable: table(
        'notes.note33.sumTable',
        { id: 'label', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        .addRows((rows) =>
          rows
            .addRow(
              'sum',
              getNoteLabel('Summa ställda säkerheter'),
              ref(or(sum(id('notes.note33.table.*.year')), 0)),
              ref(or(sum(id('notes.note33.table.*.previousYear')), 0))
            )
            .build()
        )
        .build(),
      commentLabel: field('Kommentar'),
      comment: active(field('')),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note34: {
      active: false,
      number: value(undefined),
      name: value('Eventualförpliktelser'),
      table: table(
        'notes.note34.table',
        { id: 'label', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        .addRows((rows) =>
          rows
            .addRow(
              '1',
              getNoteLabel('Eventualförpliktelser'),
              value(undefined),
              value(undefined)
            )
            .build()
        )
        .newRowTemplate(value(''), value(undefined), value(undefined))
        .build(),
      commentLabel: field('Kommentar'),
      comment: active(field('')),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note35: {
      active: false,
      number: value(undefined),
      name: value('Väsentliga händelser efter räkenskapsårets slut'),
      inputField: active(field('')),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note36: {
      active: false,
      number: value(undefined),
      name: value(
        'Ekonomiska arrangemang som inte redovisas i balansräkningen'
      ),
      inputField: active(field('')),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note37: {
      active: false,
      number: value(undefined),
      name: value('Koncernförhållanden'),
      table: table('notes.note37.table', { id: 'label', label: '' })
        .addRows((rows) =>
          rows
            .addRowActive(false)
            .addRow(
              '1',
              getNoteLabel(
                'Bolaget är helägt dotterbolag till XXX AB, Org.nr XXXXXXXXX-XXXX, säte XXX'
              )
            )
            .addRowActive(false)
            .addRow(
              '2',
              getNoteLabel(
                'Bolaget är moderbolag, men med stöd av ÅRL 7 kap 2§ upprättas inte någon koncernredovisning.'
              )
            )
            .addRowActive(false)
            .addRow(
              '3',
              getNoteLabel(
                'Bolaget är moderbolag, men med stöd av ÅRL 7 kap 3§ upprättas inte någon koncernredovisning.'
              )
            )
            .addRowActive(false)
            .addRow(
              '4',
              getNoteLabel(
                'Övergripande koncernredovisning upprättas av: Namn, Org.nr, säte'
              )
            )
            .addRowActive(false)
            .addRow(
              '5',
              getNoteLabel(
                'Minsta koncernredovisning upprättas av: Namn, Org.nr, säte'
              )
            )
            .build()
        )
        .build(),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note38: {
      active: false,
      number: value(undefined),
      name: value('Definition av nyckeltal'),
      table: table('notes.note38.table', { id: 'label', label: '' })
        .addRows((rows) =>
          rows
            .addRowActive(false)
            .addRow(
              '1',
              getNoteLabel(
                'Soliditet: Justerat eget kapital i procent av balansomslutning'
              )
            )
            .addRowActive(false)
            .addRow(
              '2',
              getNoteLabel(
                'Avkastning på eget kapital: Resultat efter finansiella poster i procent av genomsnittligt justerat eget kapital'
              )
            )
            .addRowActive(false)
            .addRow(
              '3',
              getNoteLabel(
                'Avkastning på totalt kapital: Rörelseresultat med tillägg för finansiella intäkter, i procent av genomsnittlig balansomslutning'
              )
            )
            .addRowActive(false)
            .addRow(
              '4',
              getNoteLabel(
                'Kassalikviditet: Omsättningstillgångar exklusive varulager i procent av kortfristiga fordringar'
              )
            )
            .build()
        )
        .build(),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note39: {
      active: false,
      number: value(undefined),
      name: value('Bokslutsrapport'),
      table: table('notes.note39.table', { id: 'label', label: '' })
        .addRows((rows) =>
          rows
            .addRowActive(false)
            .addRow(
              '1',
              getNoteLabel(
                'För denna årsredovisning har en bokslutsrapport upprättats av (Förnamn Efternamn, Redovisningsbyrån AB), som är auktoriserad redovisningskonsult genom medlemskap i (FAR/SRF)'
              )
            )
            .build()
        )
        .build(),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note50: {
      active: false,
      number: value(undefined),
      name: value('Långfristiga skulder'),
      inputField: active(
        field(
          'Summa som har förfallodatum 5 år eller längre efter räkenskapsårets slut.'
        )
      ),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note51: {
      active: false,
      number: value(undefined),
      name: value('Väsentliga händelser efter räkenskapsårets utgång'),
      inputField: active(field('')),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    custom: {
      type: 'sections',
      sections: [],
      newSectionTemplate: {
        active: false,
        number: { type: 'number', value: 0 },
        name: { type: 'string', value: 'Ny not' },
        group: { type: 'string', value: 'other' },
        section: { type: 'string', value: '' },
        validForReportType: {
          k2: true,
          k3: true,
        },
        commentTitle: { type: 'string', value: 'Kommentar' },
        typeOne: field('Egen fritext'),
        typeTwo: table(
          'notes.custom.typeTwoTable',
          { id: 'label', label: '' },
          {
            id: 'year',
            label: year(financialYear, financialYears, period) || '',
          },
          {
            id: 'previousYear',
            label: previousYear(financialYear, financialYears) || '',
          }
        )
          .newColumnTemplate((column) => column.setType('string').build())
          .newRowTemplate(value(''), value(undefined), value(undefined))
          .build(),
        typeThree: table(
          'notes.custom.typeThreeTable',
          { id: 'label', label: '' },
          { id: 'val1', label: '' },
          { id: 'val2', label: '' },
          { id: 'val3', label: '' },
          {
            id: 'year',
            label: year(financialYear, financialYears, period) || '',
          },
          {
            id: 'previousYear',
            label: previousYear(financialYear, financialYears) || '',
          }
        )
          .newColumnTemplate((column) => column.setType('string').build())
          .newRowTemplate(
            value(''),
            value(''),
            value(''),
            value(''),
            value(undefined),
            value(undefined)
          )
          .build(),
      },
    },
  };
};

import React from 'react';
import { Select, MenuItem, FormHelperText } from '@material-ui/core';
import { SelectProps } from '@material-ui/core/Select';
import { useIntl } from 'react-intl';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import styled from '@emotion/styled';
import GenericSimplefied, { LabelPlacement } from './GenericSimplefied';

interface SimplifiedSelectProps {
  dataCy?: string;
  listItems: Readonly<
    Array<{
      value: string | number;
      label: string;
      disabled?: boolean;
    }>
  >;
  editing?: boolean;
  labelPlacement?: LabelPlacement;
  width?: number;
}

const StyledExpandIcon = styled(ExpandMoreIcon)`
  fill: ${(props) => props.theme.palette.grey['900']};
  margin-right: ${(props) => props.theme.spacing(1)}px;
`;

const SimplifiedSelect = (props: SelectProps & SimplifiedSelectProps) => {
  const intl = useIntl();
  const { dataCy = '', listItems, editing, label, className, width } = props;
  const inputProps: { 'data-cy'?: string } = {};
  const strippedProps: any = { ...props };

  if (dataCy) {
    inputProps['data-cy'] = dataCy;
    delete strippedProps.dataCy;
  }

  if (props.labelPlacement) {
    delete strippedProps.labelPlacement;
  }

  if (label) {
    delete strippedProps.label;
  }

  if (className) {
    delete strippedProps.className;
  }

  if (strippedProps.editing) delete strippedProps.editing;
  delete strippedProps.listItems;

  const displayValue =
    listItems &&
    listItems.length &&
    listItems.filter((item) => item.value === props.value)[0]?.label;

  return (
    <GenericSimplefied
      label={label}
      required={strippedProps.required}
      value={displayValue}
      editing={editing}
      labelPlacement={props.labelPlacement}
      width={width}
      className={className}
    >
      <Select
        error={strippedProps.required && !props.value}
        data-cy={dataCy}
        variant="outlined"
        fullWidth={!!width}
        defaultValue={listItems && listItems.length && listItems[0].value}
        IconComponent={StyledExpandIcon}
        {...strippedProps}
      >
        {listItems.map((item) => (
          <MenuItem
            data-cy={dataCy ? `${dataCy}-item-${item.value}` : ''}
            key={item.value}
            value={item.value}
            disabled={!!item.disabled}
          >
            {item.label}
          </MenuItem>
        ))}
      </Select>
      {strippedProps.required && !displayValue && (
        <FormHelperText error>
          {intl.formatMessage({ id: 'form.fieldRequired' })}
        </FormHelperText>
      )}
    </GenericSimplefied>
  );
};

export default SimplifiedSelect;

import { AgoyDocumentStructure } from '../document';
import updateDocument from './helpers/updateDocument';
import { OperationResult, State } from './types';

const toggleTableActive = <T extends AgoyDocumentStructure>(
  structure: T,
  state: State<T>,
  id: string
): OperationResult<T> => {
  return updateDocument(structure, state, id, {
    table: (key, tableId, props) => {
      // active property can be either boolean or reference cell
      const currentActiveState =
        typeof props.node.active === 'object'
          ? props.node.active.value
          : props.node.active;

      return {
        ...props,
        node: {
          ...props.node,
          active: !currentActiveState,
        },
        changes: {
          type: 'update',
          ...props.changes,
          active: !currentActiveState,
        },
      };
    },
  });
};

export default toggleTableActive;

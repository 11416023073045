import {
  AnnualReport,
  DocumentConfiguration,
  annualGeneralMeetingContentDefinition,
  annualReportSignaturesContentDefinition,
  confirmationCertificateContentDefinition,
} from '@agoy/annual-report-document';
import { AgoyDocument } from '@agoy/document';

export type AnnualReportPrintState = {
  clientId: string;
  annualReport: {
    documentConfiguration: DocumentConfiguration;
    report: AnnualReport;
    documentId: string;
  };
  annualReportSignatures?: {
    documentId: string;
    document: AgoyDocument<typeof annualReportSignaturesContentDefinition>;
  };
  annualGeneralMeeting?: {
    documentId: string;
    document: AgoyDocument<typeof annualGeneralMeetingContentDefinition>;
  };
  confirmationCertificate?: {
    documentId: string;
    document: AgoyDocument<typeof confirmationCertificateContentDefinition>;
  };
};

export function isAnnualReportPrintState(
  obj: unknown
): obj is AnnualReportPrintState {
  return typeof obj === 'object' && obj !== null && 'annualReport' in obj;
}

import * as t from 'io-ts';

export const DocumentValuesUpdatedMessageCodec = t.type({
  clientId: t.string,
  topic: t.literal('document-values-updated'),
  documentId: t.string,
});

/**
 * Notification of update to document values
 *
 * generate-document-values lambda will report when values are updated.
 */
export type DocumentValuesUpdatedMessage = t.TypeOf<
  typeof DocumentValuesUpdatedMessageCodec
>;

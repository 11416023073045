import { AgoyTableRow, Cell, stringValue } from '@agoy/document';
import { useIntl } from 'react-intl';
import {
  AnnualReportVersion,
  defaultRolesForSignatureTable,
} from '@agoy/annual-report-document';
import { hasCustomRole } from './utils';

export const isAuditor = (
  row: AgoyTableRow<Cell>,
  version: AnnualReportVersion
): boolean => {
  const { formatMessage } = useIntl();

  const roleCell =
    version === '2' ? row?.cells?.role : row?.cells?.accountantRole;

  const roleValue = stringValue(roleCell);

  if (!roleValue) return false;

  const roleValues = roleValue.split('|');

  const translatedAuditorRoles = defaultRolesForSignatureTable.auditors.map(
    (role) => formatMessage({ id: `clientInformation.${role}` }).toLowerCase()
  );

  return (
    roleValues.some((role) =>
      defaultRolesForSignatureTable.auditors.includes(role)
    ) ||
    roleValues.some(
      (role) =>
        translatedAuditorRoles.includes(role.toLowerCase()) ||
        (hasCustomRole(roleValues) && version === '1')
    )
  );
};

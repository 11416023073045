/*
 * This is a GENERATED file, do not change manually!
 * Generated:
 * - from file k3-arsredovisning-2021-10-31-rev20230131 - Kassaflödesanalys indirekt met.csv
 * - Comments: AGOY-6689
 */

import {
  id,
  account,
  sum,
  sumAllowNull,
  or,
  multiply,
  label,
  ref,
  value,
  refs,
  ReferenceAccountInformation,
  TimePeriod,
  AccountValueType,
} from '@agoy/document';
import { IxbrlCell } from '../../../../common';
import {
  table,
  RowsBuilder,
  ixbrlCell,
} from '../../../../common/utils/util';
import { AnnualReportType, AnnualReportVersion } from '../../../../document';
import { range, StatementSection } from '@agoy/annual-report-document';

const accountRangeRows = (
  accounts: Record<string, ReferenceAccountInformation>,
  first: number,
  last: number,
  rows: RowsBuilder,
  switchSign: boolean,
  currentPeriod: string,
  previousPeriod: string,
  modifier?: AccountValueType | undefined
): RowsBuilder => {
  range(first, last + 1).forEach((n) => {
    const accountNumber = n.toString();
    const accountInformation = accounts[accountNumber];
    if (accountInformation) {
      rows
        .addRowWithType(
          accountNumber,
          'account',
          label(`${accountInformation.accountName}`),
          undefined,
          ref(
            switchSign
              ? multiply(
                  -1,
                  or(account(accountNumber, modifier, currentPeriod), 0)
                )
              : or(account(accountNumber, modifier, currentPeriod), 0)
          ),
          ref(
            switchSign
              ? multiply(
                  -1,
                  or(account(accountNumber, modifier, previousPeriod), 0)
                )
              : or(account(accountNumber, modifier, previousPeriod), 0)
          )
        )
        .setSortKey(n);
    }
  });
  return rows;
};

export const cashFlowStatementK3v2Config = (
  accounts: Record<string, ReferenceAccountInformation>,
  period: TimePeriod,
  previousPeriod: TimePeriod | null,
  type: AnnualReportType,
  isDigitalSubmission: boolean | null,
  documentTypeVersion: AnnualReportVersion = '2',
  accountModifier: AccountValueType = 'change'
): StatementSection => ({
  active: undefined,
  section: {
    active: undefined,
    table: table<IxbrlCell>(
      'cashFlowStatement.section.table',
      { id: 'label', dataType: 'text' },
      { id: 'notes', dataType: 'numeric' },
      {
        id: 'year',
        label: `${period.startDateISO} ${period.endDateISO}`,
        dataType: 'numeric',
      },
      previousPeriod
        ? {
            id: 'previousYear',
            label: `${previousPeriod.startDateISO} ${previousPeriod.endDateISO}`,
            dataType: 'numeric',
          }
        : undefined
    )
      .addRows((rows) => {
        rows
          .addRowWithType(
            'KassaflodesanalysLopandeVerksamhetenAbstract',
            'header',
            value('Den löpande verksamheten'),
            refs(),
            undefined,
            undefined
          )
          .addSubRows((rows) => {
            rows
              .addRowWithType(
                'KassaflodeIngangsvardeLopandeVerksamhetenAbstract',
                'header',
                value('Ingångsvärde löpande verksamheten'),
                refs(),
                undefined,
                undefined
              )
              .addSubRows((rows) => {
                rows.addRowWithType(
                  'Rorelseresultat',
                  'row',
                  value('Rörelseresultat'),
                  refs(),
                  ixbrlCell(
                    ref(
                      sumAllowNull(
                        id('incomeStatement.section.table.Rorelseresultat.year')
                      )
                    ),
                    {
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:Rorelseresultat',
                      contextRef: 'period0',
                      standardRubrik: 'Rörelseresultat',
                      saldo: 'credit',
                      negateValue: false,
                    }
                  ),
                  ixbrlCell(
                    ref(
                      sumAllowNull(
                        id(
                          'incomeStatement.section.table.Rorelseresultat.previousYear'
                        )
                      )
                    ),
                    {
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:Rorelseresultat',
                      contextRef: 'period1',
                      standardRubrik: 'Rörelseresultat',
                      saldo: 'credit',
                      negateValue: false,
                    }
                  )
                );
                rows.addRowWithType(
                  'ResultatEfterFinansiellaPoster',
                  'row',
                  value('Resultat efter finansiella poster'),
                  refs(),
                  ixbrlCell(
                    ref(
                      sumAllowNull(
                        id(
                          'incomeStatement.section.table.ResultatEfterFinansiellaPoster.year'
                        )
                      )
                    ),
                    {
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ResultatEfterFinansiellaPoster',
                      contextRef: 'period0',
                      standardRubrik: 'Resultat efter finansiella poster',
                      saldo: 'credit',
                      negateValue: false,
                    }
                  ),
                  ixbrlCell(
                    ref(
                      sumAllowNull(
                        id(
                          'incomeStatement.section.table.ResultatEfterFinansiellaPoster.previousYear'
                        )
                      )
                    ),
                    {
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ResultatEfterFinansiellaPoster',
                      contextRef: 'period1',
                      standardRubrik: 'Resultat efter finansiella poster',
                      saldo: 'credit',
                      negateValue: false,
                    }
                  )
                );
                rows.addRowWithType(
                  'AretsResultat',
                  'row',
                  value('Årets resultat'),
                  refs(),
                  ixbrlCell(
                    ref(
                      sumAllowNull(
                        id('incomeStatement.section.table.AretsResultat.year')
                      )
                    ),
                    {
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AretsResultat',
                      contextRef: 'period0',
                      standardRubrik: 'Periodens resultat',
                      saldo: 'credit',
                      negateValue: false,
                    }
                  ),
                  ixbrlCell(
                    ref(
                      sumAllowNull(
                        id(
                          'incomeStatement.section.table.AretsResultat.previousYear'
                        )
                      )
                    ),
                    {
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AretsResultat',
                      contextRef: 'period1',
                      standardRubrik: 'Periodens resultat',
                      saldo: 'credit',
                      negateValue: false,
                    }
                  )
                );
                rows
                  .addRowWithType(
                    'JusteringPosterSomInteIngarIKassaflodet',
                    'row',
                    value('Justering för poster som inte ingår i kassaflödet'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.KassaflodeIngangsvardeLopandeVerksamhetenAbstract.JusteringPosterSomInteIngarIKassaflodet.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:JusteringPosterSomInteIngarIKassaflodet',
                        contextRef: 'period0',
                        standardRubrik:
                          'Positiv (negativ) justering för poster som inte ingår i kassaflödet',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.KassaflodeIngangsvardeLopandeVerksamhetenAbstract.JusteringPosterSomInteIngarIKassaflodet.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:JusteringPosterSomInteIngarIKassaflodet',
                        contextRef: 'period1',
                        standardRubrik:
                          'Positiv (negativ) justering för poster som inte ingår i kassaflödet',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(or(account('$ub', accountModifier, 'year'), 0)),
                    ref(or(account('$ib', accountModifier, 'previousYear'), 0))
                  )
                  .addSubRows((rows) => {
                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'ErhallenRanta',
                    'row',
                    value('Erhållen ränta'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.KassaflodeIngangsvardeLopandeVerksamhetenAbstract.ErhallenRanta.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:ErhallenRanta',
                        contextRef: 'period0',
                        standardRubrik:
                          'Ökning av kassaflöde vid erhållen ränta',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.KassaflodeIngangsvardeLopandeVerksamhetenAbstract.ErhallenRanta.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:ErhallenRanta',
                        contextRef: 'period1',
                        standardRubrik:
                          'Ökning av kassaflöde vid erhållen ränta',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'ErhallenUtdelning',
                    'row',
                    value('Erhållen utdelning'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.KassaflodeIngangsvardeLopandeVerksamhetenAbstract.ErhallenUtdelning.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:ErhallenUtdelning',
                        contextRef: 'period0',
                        standardRubrik:
                          'Ökning av kassaflöde vid erhållen utdelning',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.KassaflodeIngangsvardeLopandeVerksamhetenAbstract.ErhallenUtdelning.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:ErhallenUtdelning',
                        contextRef: 'period1',
                        standardRubrik:
                          'Ökning av kassaflöde vid erhållen utdelning',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'ErlagdRanta',
                    'row',
                    value('Erlagd ränta'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.KassaflodeIngangsvardeLopandeVerksamhetenAbstract.ErlagdRanta.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:ErlagdRanta',
                        contextRef: 'period0',
                        standardRubrik:
                          'Minskning av kassaflöde vid erlagd ränta',
                        negateValue: true,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.KassaflodeIngangsvardeLopandeVerksamhetenAbstract.ErlagdRanta.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:ErlagdRanta',
                        contextRef: 'period1',
                        standardRubrik:
                          'Minskning av kassaflöde vid erlagd ränta',
                        negateValue: true,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'BetaldSkatt',
                    'row',
                    value('Betald inkomstskatt'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.KassaflodeIngangsvardeLopandeVerksamhetenAbstract.BetaldSkatt.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:BetaldSkatt',
                        contextRef: 'period0',
                        standardRubrik:
                          'Minskning av kassaflöde vid betald inkomstskatt',
                        negateValue: true,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.KassaflodeIngangsvardeLopandeVerksamhetenAbstract.BetaldSkatt.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:BetaldSkatt',
                        contextRef: 'period1',
                        standardRubrik:
                          'Minskning av kassaflöde vid betald inkomstskatt',
                        negateValue: true,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    return rows.build();
                  });
                return rows.build();
              });
            rows
              .addRowWithType(
                'KassaflodeLopandeVerksamhetenForeForandringarRorelsekapital',
                'sum',
                value(
                  'Kassaflöde från den löpande verksamheten före förändringar av rörelsekapital'
                ),
                undefined,
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.KassaflodeIngangsvardeLopandeVerksamhetenAbstract.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    name: 'se-gen-base:KassaflodeLopandeVerksamhetenForeForandringarRorelsekapital',
                    contextRef: 'period0',
                    standardRubrik:
                      'Ökning (minskning) av kassaflöde från den löpande verksamheten före förändringar av rörelsekapital',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.KassaflodeIngangsvardeLopandeVerksamhetenAbstract.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    name: 'se-gen-base:KassaflodeLopandeVerksamhetenForeForandringarRorelsekapital',
                    contextRef: 'period1',
                    standardRubrik:
                      'Ökning (minskning) av kassaflöde från den löpande verksamheten före förändringar av rörelsekapital',
                    negateValue: false,
                  }
                )
              )
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(or(account('$ub', accountModifier, 'year'), 0)),
                ref(or(account('$ib', accountModifier, 'previousYear'), 0))
              )
              .addSubRows((rows) => {
                return rows.build();
              });
            rows
              .addRowWithType(
                'ForandringKassaflodeRorelsekapitaletAbstract',
                'header',
                value('Kassaflöde från förändringar i rörelsekapital'),
                refs(),
                undefined,
                undefined
              )
              .addSubRows((rows) => {
                rows
                  .addRowWithType(
                    'ForandringKassaflodeVarulagerPagaendeArbeten',
                    'row',
                    value('Ökning/minskning varulager och pågående arbeten'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.ForandringKassaflodeRorelsekapitaletAbstract.ForandringKassaflodeVarulagerPagaendeArbeten.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:ForandringKassaflodeVarulagerPagaendeArbeten',
                        contextRef: 'period0',
                        standardRubrik:
                          'Ökning (minskning) av kassaflöde vid förändring av varulager och pågående arbeten',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.ForandringKassaflodeRorelsekapitaletAbstract.ForandringKassaflodeVarulagerPagaendeArbeten.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:ForandringKassaflodeVarulagerPagaendeArbeten',
                        contextRef: 'period1',
                        standardRubrik:
                          'Ökning (minskning) av kassaflöde vid förändring av varulager och pågående arbeten',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'ForandringKassaflodeRorelsefodringarAbstract',
                    'header',
                    value('Förändring rörelsefodringar'),
                    refs(),
                    undefined,
                    undefined
                  )
                  .addSubRows((rows) => {
                    rows
                      .addRowWithType(
                        'ForandringKassaflodeKundfordringar',
                        'row',
                        value('Ökning/minskning kundfordringar'),
                        refs(),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.ForandringKassaflodeRorelsekapitaletAbstract.ForandringKassaflodeRorelsefodringarAbstract.ForandringKassaflodeKundfordringar.*.year'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:ForandringKassaflodeKundfordringar',
                            contextRef: 'period0',
                            standardRubrik:
                              'Ökning (minskning) av kassaflöde vid förändring av kundfordringar',
                            negateValue: false,
                          }
                        ),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.ForandringKassaflodeRorelsekapitaletAbstract.ForandringKassaflodeRorelsefodringarAbstract.ForandringKassaflodeKundfordringar.*.previousYear'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:ForandringKassaflodeKundfordringar',
                            contextRef: 'period1',
                            standardRubrik:
                              'Ökning (minskning) av kassaflöde vid förändring av kundfordringar',
                            negateValue: false,
                          }
                        )
                      ) // <<< end row_empty_value ---
                      .newRowTemplateWithType(
                        'account',
                        label('$label'),
                        undefined,
                        ref(
                          multiply(
                            -1,
                            or(account('$ub', accountModifier, 'year'), 0)
                          )
                        ),
                        ref(
                          multiply(
                            -1,
                            or(
                              account('$ib', accountModifier, 'previousYear'),
                              0
                            )
                          )
                        )
                      )
                      .addSubRows((rows) => {
                        return rows.build();
                      });
                    rows
                      .addRowWithType(
                        'ForandringKassaflodeOvrigaKortfristigaFordringar',
                        'row',
                        value(
                          'Ökning/minskning övriga kortfristiga fordringar'
                        ),
                        refs(),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.ForandringKassaflodeRorelsekapitaletAbstract.ForandringKassaflodeRorelsefodringarAbstract.ForandringKassaflodeOvrigaKortfristigaFordringar.*.year'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:ForandringKassaflodeOvrigaKortfristigaFordringar',
                            contextRef: 'period0',
                            standardRubrik:
                              'Ökning (minskning) av kassaflöde vid förändring av övriga kortfristiga fordringar',
                            negateValue: false,
                          }
                        ),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.ForandringKassaflodeRorelsekapitaletAbstract.ForandringKassaflodeRorelsefodringarAbstract.ForandringKassaflodeOvrigaKortfristigaFordringar.*.previousYear'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:ForandringKassaflodeOvrigaKortfristigaFordringar',
                            contextRef: 'period1',
                            standardRubrik:
                              'Ökning (minskning) av kassaflöde vid förändring av övriga kortfristiga fordringar',
                            negateValue: false,
                          }
                        )
                      ) // <<< end row_empty_value ---
                      .newRowTemplateWithType(
                        'account',
                        label('$label'),
                        undefined,
                        ref(
                          multiply(
                            -1,
                            or(account('$ub', accountModifier, 'year'), 0)
                          )
                        ),
                        ref(
                          multiply(
                            -1,
                            or(
                              account('$ib', accountModifier, 'previousYear'),
                              0
                            )
                          )
                        )
                      )
                      .addSubRows((rows) => {
                        return rows.build();
                      });
                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'ForandringKassaflodeRorelsefodringar',
                    'sum',
                    value('Ökning/minskning av rörelsefodringar'),
                    undefined,
                    ixbrlCell(
                      ref(
                        sumAllowNull(
                          id(
                            'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.ForandringKassaflodeRorelsekapitaletAbstract.ForandringKassaflodeRorelsefodringarAbstract.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        name: 'se-gen-base:ForandringKassaflodeRorelsefodringar',
                        contextRef: 'period0',
                        standardRubrik:
                          'Ökning (minskning) av kassaflöde vid förändring rörelsefodringar',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sumAllowNull(
                          id(
                            'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.ForandringKassaflodeRorelsekapitaletAbstract.ForandringKassaflodeRorelsefodringarAbstract.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        name: 'se-gen-base:ForandringKassaflodeRorelsefodringar',
                        contextRef: 'period1',
                        standardRubrik:
                          'Ökning (minskning) av kassaflöde vid förändring rörelsefodringar',
                        negateValue: false,
                      }
                    )
                  )
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(or(account('$ub', accountModifier, 'year'), 0)),
                    ref(or(account('$ib', accountModifier, 'previousYear'), 0))
                  )
                  .addSubRows((rows) => {
                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'ForandringKassaflodeRorelseskulderAbstract',
                    'header',
                    value('Förändring rörelseskulder'),
                    refs(),
                    undefined,
                    undefined
                  )
                  .addSubRows((rows) => {
                    rows
                      .addRowWithType(
                        'ForandringKassaflodeLeverantorsskulder',
                        'row',
                        value('Ökning/minskning leverantörsskulder'),
                        refs(),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.ForandringKassaflodeRorelsekapitaletAbstract.ForandringKassaflodeRorelseskulderAbstract.ForandringKassaflodeLeverantorsskulder.*.year'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:ForandringKassaflodeLeverantorsskulder',
                            contextRef: 'period0',
                            standardRubrik:
                              'Ökning (minskning) av kassaflöde vid förändring av leverantörsskulder',
                            negateValue: false,
                          }
                        ),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.ForandringKassaflodeRorelsekapitaletAbstract.ForandringKassaflodeRorelseskulderAbstract.ForandringKassaflodeLeverantorsskulder.*.previousYear'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:ForandringKassaflodeLeverantorsskulder',
                            contextRef: 'period1',
                            standardRubrik:
                              'Ökning (minskning) av kassaflöde vid förändring av leverantörsskulder',
                            negateValue: false,
                          }
                        )
                      ) // <<< end row_empty_value ---
                      .newRowTemplateWithType(
                        'account',
                        label('$label'),
                        undefined,
                        ref(
                          multiply(
                            -1,
                            or(account('$ub', accountModifier, 'year'), 0)
                          )
                        ),
                        ref(
                          multiply(
                            -1,
                            or(
                              account('$ib', accountModifier, 'previousYear'),
                              0
                            )
                          )
                        )
                      )
                      .addSubRows((rows) => {
                        return rows.build();
                      });
                    rows
                      .addRowWithType(
                        'ForandringKassaflodeOvrigaKortfristigaSkulder',
                        'row',
                        value('Ökning/minskning övriga kortfristiga skulder'),
                        refs(),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.ForandringKassaflodeRorelsekapitaletAbstract.ForandringKassaflodeRorelseskulderAbstract.ForandringKassaflodeOvrigaKortfristigaSkulder.*.year'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:ForandringKassaflodeOvrigaKortfristigaSkulder',
                            contextRef: 'period0',
                            standardRubrik:
                              'Ökning (minskning) av kassaflöde vid förändring av övriga kortfristiga skulder',
                            negateValue: false,
                          }
                        ),
                        ixbrlCell(
                          ref(
                            sum(
                              id(
                                'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.ForandringKassaflodeRorelsekapitaletAbstract.ForandringKassaflodeRorelseskulderAbstract.ForandringKassaflodeOvrigaKortfristigaSkulder.*.previousYear'
                              )
                            )
                          ),
                          {
                            type: 'monetaryItemType',
                            unitRef: 'SEK',
                            name: 'se-gen-base:ForandringKassaflodeOvrigaKortfristigaSkulder',
                            contextRef: 'period1',
                            standardRubrik:
                              'Ökning (minskning) av kassaflöde vid förändring av övriga kortfristiga skulder',
                            negateValue: false,
                          }
                        )
                      ) // <<< end row_empty_value ---
                      .newRowTemplateWithType(
                        'account',
                        label('$label'),
                        undefined,
                        ref(
                          multiply(
                            -1,
                            or(account('$ub', accountModifier, 'year'), 0)
                          )
                        ),
                        ref(
                          multiply(
                            -1,
                            or(
                              account('$ib', accountModifier, 'previousYear'),
                              0
                            )
                          )
                        )
                      )
                      .addSubRows((rows) => {
                        return rows.build();
                      });
                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'ForandringKassaflodeRorelseskulder',
                    'sum',
                    value('Ökning/minskning av rörelseskulder'),
                    undefined,
                    ixbrlCell(
                      ref(
                        sumAllowNull(
                          id(
                            'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.ForandringKassaflodeRorelsekapitaletAbstract.ForandringKassaflodeRorelseskulderAbstract.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        name: 'se-gen-base:ForandringKassaflodeRorelseskulder',
                        contextRef: 'period0',
                        standardRubrik:
                          'Ökning (minskning) av kassaflöde vid förändring rörelseskulder',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sumAllowNull(
                          id(
                            'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.ForandringKassaflodeRorelsekapitaletAbstract.ForandringKassaflodeRorelseskulderAbstract.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        name: 'se-gen-base:ForandringKassaflodeRorelseskulder',
                        contextRef: 'period1',
                        standardRubrik:
                          'Ökning (minskning) av kassaflöde vid förändring rörelseskulder',
                        negateValue: false,
                      }
                    )
                  )
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(or(account('$ub', accountModifier, 'year'), 0)),
                    ref(or(account('$ib', accountModifier, 'previousYear'), 0))
                  )
                  .addSubRows((rows) => {
                    return rows.build();
                  });
                return rows.build();
              });
            return rows.build();
          });
        rows
          .addRowWithType(
            'KassaflodeLopandeVerksamheten',
            'sum',
            value('Kassaflöde från den löpande verksamheten'),
            undefined,
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.KassaflodeLopandeVerksamhetenForeForandringarRorelsekapital.year'
                  ),
                  id(
                    'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.ForandringKassaflodeRorelsekapitaletAbstract.ForandringKassaflodeVarulagerPagaendeArbeten.year'
                  ),
                  id(
                    'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.ForandringKassaflodeRorelsekapitaletAbstract.ForandringKassaflodeRorelsefodringar.year'
                  ),
                  id(
                    'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.ForandringKassaflodeRorelsekapitaletAbstract.ForandringKassaflodeRorelseskulder.year'
                  )
                )
              ),
              {
                type: 'monetaryItemType',
                name: 'se-gen-base:KassaflodeLopandeVerksamheten',
                contextRef: 'period0',
                standardRubrik:
                  'Ökning (minskning) av kassaflöde från den löpande verksamheten',
                negateValue: false,
              }
            ),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.KassaflodeLopandeVerksamhetenForeForandringarRorelsekapital.previousYear'
                  ),
                  id(
                    'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.ForandringKassaflodeRorelsekapitaletAbstract.ForandringKassaflodeVarulagerPagaendeArbeten.previousYear'
                  ),
                  id(
                    'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.ForandringKassaflodeRorelsekapitaletAbstract.ForandringKassaflodeRorelsefodringar.previousYear'
                  ),
                  id(
                    'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.ForandringKassaflodeRorelsekapitaletAbstract.ForandringKassaflodeRorelseskulder.previousYear'
                  )
                )
              ),
              {
                type: 'monetaryItemType',
                name: 'se-gen-base:KassaflodeLopandeVerksamheten',
                contextRef: 'period1',
                standardRubrik:
                  'Ökning (minskning) av kassaflöde från den löpande verksamheten',
                negateValue: false,
              }
            )
          )
          .newRowTemplateWithType(
            'account',
            label('$label'),
            undefined,
            ref(or(account('$ub', accountModifier, 'year'), 0)),
            ref(or(account('$ib', accountModifier, 'previousYear'), 0))
          )
          .addSubRows((rows) => {
            return rows.build();
          });
        rows
          .addRowWithType(
            'KassaflodesanalysInvesteringsverksamhetenAbstract',
            'header',
            value('Investeringsverksamheten'),
            refs(),
            undefined,
            undefined
          )
          .addSubRows((rows) => {
            rows
              .addRowWithType(
                'LamnadeKapitaltillskottInvesteringsverksamheten',
                'row',
                value('Lämnade tillskott'),
                refs(),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.LamnadeKapitaltillskottInvesteringsverksamheten.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:LamnadeKapitaltillskottInvesteringsverksamheten',
                    contextRef: 'period0',
                    standardRubrik:
                      'Minskning av kassaflöde vid lämnade tillskott',
                    negateValue: true,
                  }
                ),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.LamnadeKapitaltillskottInvesteringsverksamheten.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:LamnadeKapitaltillskottInvesteringsverksamheten',
                    contextRef: 'period1',
                    standardRubrik:
                      'Minskning av kassaflöde vid lämnade tillskott',
                    negateValue: true,
                  }
                )
              ) // <<< end row_empty_value ---
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(
                  multiply(-1, or(account('$ub', accountModifier, 'year'), 0))
                ),
                ref(
                  multiply(
                    -1,
                    or(account('$ib', accountModifier, 'previousYear'), 0)
                  )
                )
              )
              .addSubRows((rows) => {
                return rows.build();
              });
            rows
              .addRowWithType(
                'LamnadeKoncernbidragInvesteringsverksamheten',
                'row',
                value('Lämnade koncernbidrag'),
                refs(),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.LamnadeKoncernbidragInvesteringsverksamheten.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:LamnadeKoncernbidragInvesteringsverksamheten',
                    contextRef: 'period0',
                    standardRubrik:
                      'Minskning av kassaflöde vid lämnade koncernbidrag i investeringsverksamheten',
                    negateValue: true,
                  }
                ),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.LamnadeKoncernbidragInvesteringsverksamheten.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:LamnadeKoncernbidragInvesteringsverksamheten',
                    contextRef: 'period1',
                    standardRubrik:
                      'Minskning av kassaflöde vid lämnade koncernbidrag i investeringsverksamheten',
                    negateValue: true,
                  }
                )
              ) // <<< end row_empty_value ---
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(
                  multiply(-1, or(account('$ub', accountModifier, 'year'), 0))
                ),
                ref(
                  multiply(
                    -1,
                    or(account('$ib', accountModifier, 'previousYear'), 0)
                  )
                )
              )
              .addSubRows((rows) => {
                return rows.build();
              });
            rows
              .addRowWithType(
                'InvesteringImmateriellaAnlaggningstillgangar',
                'row',
                value('Förvärv av immateriella anläggningstillgångar'),
                refs(),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.InvesteringImmateriellaAnlaggningstillgangar.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:InvesteringImmateriellaAnlaggningstillgangar',
                    contextRef: 'period0',
                    standardRubrik:
                      'Minskning av kassaflöde vid förvärv av immateriella anläggningstillgångar',
                    negateValue: true,
                  }
                ),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.InvesteringImmateriellaAnlaggningstillgangar.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:InvesteringImmateriellaAnlaggningstillgangar',
                    contextRef: 'period1',
                    standardRubrik:
                      'Minskning av kassaflöde vid förvärv av immateriella anläggningstillgångar',
                    negateValue: true,
                  }
                )
              ) // <<< end row_empty_value ---
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(
                  multiply(-1, or(account('$ub', accountModifier, 'year'), 0))
                ),
                ref(
                  multiply(
                    -1,
                    or(account('$ib', accountModifier, 'previousYear'), 0)
                  )
                )
              )
              .addSubRows((rows) => {
                return rows.build();
              });
            rows
              .addRowWithType(
                'ForsaljningImmateriellaAnlaggningstillgangar',
                'row',
                value('Avyttring av immateriella anläggningstillgångar'),
                refs(),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForsaljningImmateriellaAnlaggningstillgangar.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:ForsaljningImmateriellaAnlaggningstillgangar',
                    contextRef: 'period0',
                    standardRubrik:
                      'Ökning av kassaflöde vid avyttring av immateriella anläggningstillgångar',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForsaljningImmateriellaAnlaggningstillgangar.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:ForsaljningImmateriellaAnlaggningstillgangar',
                    contextRef: 'period1',
                    standardRubrik:
                      'Ökning av kassaflöde vid avyttring av immateriella anläggningstillgångar',
                    negateValue: false,
                  }
                )
              ) // <<< end row_empty_value ---
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(
                  multiply(-1, or(account('$ub', accountModifier, 'year'), 0))
                ),
                ref(
                  multiply(
                    -1,
                    or(account('$ib', accountModifier, 'previousYear'), 0)
                  )
                )
              )
              .addSubRows((rows) => {
                return rows.build();
              });
            rows
              .addRowWithType(
                'ForvarvMateriellaAnlaggningstillgangarAbstract',
                'header',
                value('Förvärv av materiella anläggningstillgångar'),
                refs(),
                undefined,
                undefined
              )
              .addSubRows((rows) => {
                rows
                  .addRowWithType(
                    'ForvarvForvaltningsfastigheter',
                    'row',
                    value('Förvärv av förvaltningsfastigheter'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForvarvMateriellaAnlaggningstillgangarAbstract.ForvarvForvaltningsfastigheter.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:ForvarvForvaltningsfastigheter',
                        contextRef: 'period0',
                        standardRubrik:
                          'Minskning av kassaflöde vid förvärv av förvaltningsfastigheter',
                        negateValue: true,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForvarvMateriellaAnlaggningstillgangarAbstract.ForvarvForvaltningsfastigheter.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:ForvarvForvaltningsfastigheter',
                        contextRef: 'period1',
                        standardRubrik:
                          'Minskning av kassaflöde vid förvärv av förvaltningsfastigheter',
                        negateValue: true,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'ForvarvOvrigaMateriellaAnlaggningstillgangar',
                    'row',
                    value('Förvärv av övriga materiella anläggningstillgångar'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForvarvMateriellaAnlaggningstillgangarAbstract.ForvarvOvrigaMateriellaAnlaggningstillgangar.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:ForvarvOvrigaMateriellaAnlaggningstillgangar',
                        contextRef: 'period0',
                        standardRubrik:
                          'Minskning av kassaflöde vid förvärv av övriga materiella anläggningstillgångar',
                        negateValue: true,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForvarvMateriellaAnlaggningstillgangarAbstract.ForvarvOvrigaMateriellaAnlaggningstillgangar.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:ForvarvOvrigaMateriellaAnlaggningstillgangar',
                        contextRef: 'period1',
                        standardRubrik:
                          'Minskning av kassaflöde vid förvärv av övriga materiella anläggningstillgångar',
                        negateValue: true,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    return rows.build();
                  });
                return rows.build();
              });
            rows
              .addRowWithType(
                'InvesteringMateriellaAnlaggningstillgangar',
                'sum',
                value('Förvärv av materiella anläggningstillgångar'),
                undefined,
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForvarvMateriellaAnlaggningstillgangarAbstract.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    name: 'se-gen-base:InvesteringMateriellaAnlaggningstillgangar',
                    contextRef: 'period0',
                    standardRubrik:
                      'Minskning av kassaflöde vid förvärv av materiella anläggningstillgångar',
                    negateValue: true,
                  }
                ),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForvarvMateriellaAnlaggningstillgangarAbstract.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    name: 'se-gen-base:InvesteringMateriellaAnlaggningstillgangar',
                    contextRef: 'period1',
                    standardRubrik:
                      'Minskning av kassaflöde vid förvärv av materiella anläggningstillgångar',
                    negateValue: true,
                  }
                )
              )
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(or(account('$ub', accountModifier, 'year'), 0)),
                ref(or(account('$ib', accountModifier, 'previousYear'), 0))
              )
              .addSubRows((rows) => {
                return rows.build();
              });
            rows
              .addRowWithType(
                'ForsaljningMateriellaAnlaggningstillgangarAbstract',
                'header',
                value('Avyttring av materiella anläggningstillgångar'),
                refs(),
                undefined,
                undefined
              )
              .addSubRows((rows) => {
                rows
                  .addRowWithType(
                    'ForsaljningForvaltningsfastigheter',
                    'row',
                    value('Avyttring av förvaltningsfastigheter'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForsaljningMateriellaAnlaggningstillgangarAbstract.ForsaljningForvaltningsfastigheter.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:ForsaljningForvaltningsfastigheter',
                        contextRef: 'period0',
                        standardRubrik:
                          'Ökning av kassaflöde vid avyttring av förvaltningsfastigheter',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForsaljningMateriellaAnlaggningstillgangarAbstract.ForsaljningForvaltningsfastigheter.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:ForsaljningForvaltningsfastigheter',
                        contextRef: 'period1',
                        standardRubrik:
                          'Ökning av kassaflöde vid avyttring av förvaltningsfastigheter',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'ForsaljningOvrigaMateriellaAnlaggningstillgangar',
                    'row',
                    value(
                      'Avyttring av övriga materiella anläggningstillgångar'
                    ),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForsaljningMateriellaAnlaggningstillgangarAbstract.ForsaljningOvrigaMateriellaAnlaggningstillgangar.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:ForsaljningOvrigaMateriellaAnlaggningstillgangar',
                        contextRef: 'period0',
                        standardRubrik:
                          'Ökning av kassaflöde vid avyttring av övriga materiella anläggningstillgångar',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForsaljningMateriellaAnlaggningstillgangarAbstract.ForsaljningOvrigaMateriellaAnlaggningstillgangar.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:ForsaljningOvrigaMateriellaAnlaggningstillgangar',
                        contextRef: 'period1',
                        standardRubrik:
                          'Ökning av kassaflöde vid avyttring av övriga materiella anläggningstillgångar',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    return rows.build();
                  });
                return rows.build();
              });
            rows
              .addRowWithType(
                'ForsaljningMateriellaAnlaggningstillgangar',
                'sum',
                value('Avyttring av materiella anläggningstillgångar'),
                undefined,
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForsaljningMateriellaAnlaggningstillgangarAbstract.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    name: 'se-gen-base:ForsaljningMateriellaAnlaggningstillgangar',
                    contextRef: 'period0',
                    standardRubrik:
                      'Ökning av kassaflöde vid avyttring av materiella anläggningstillgångar',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForsaljningMateriellaAnlaggningstillgangarAbstract.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    name: 'se-gen-base:ForsaljningMateriellaAnlaggningstillgangar',
                    contextRef: 'period1',
                    standardRubrik:
                      'Ökning av kassaflöde vid avyttring av materiella anläggningstillgångar',
                    negateValue: false,
                  }
                )
              )
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(or(account('$ub', accountModifier, 'year'), 0)),
                ref(or(account('$ib', accountModifier, 'previousYear'), 0))
              )
              .addSubRows((rows) => {
                return rows.build();
              });
            rows
              .addRowWithType(
                'ForvarvRorelseInkram',
                'row',
                value('Förvärv av rörelse i form av inkråm'),
                refs(),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForvarvRorelseInkram.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:ForvarvRorelseInkram',
                    contextRef: 'period0',
                    standardRubrik:
                      'Minskning av kassaflöde vid förvärv av rörelse i form av inkråm',
                    negateValue: true,
                  }
                ),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForvarvRorelseInkram.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:ForvarvRorelseInkram',
                    contextRef: 'period1',
                    standardRubrik:
                      'Minskning av kassaflöde vid förvärv av rörelse i form av inkråm',
                    negateValue: true,
                  }
                )
              ) // <<< end row_empty_value ---
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(
                  multiply(-1, or(account('$ub', accountModifier, 'year'), 0))
                ),
                ref(
                  multiply(
                    -1,
                    or(account('$ib', accountModifier, 'previousYear'), 0)
                  )
                )
              )
              .addSubRows((rows) => {
                return rows.build();
              });
            rows
              .addRowWithType(
                'ForsaljningRorelseInkram',
                'row',
                value('Avyttring av rörelse i form av inkråm'),
                refs(),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForsaljningRorelseInkram.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:ForsaljningRorelseInkram',
                    contextRef: 'period0',
                    standardRubrik:
                      'Ökning av kassaflöde vid avyttring av rörelse i form av inkråm',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForsaljningRorelseInkram.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:ForsaljningRorelseInkram',
                    contextRef: 'period1',
                    standardRubrik:
                      'Ökning av kassaflöde vid avyttring av rörelse i form av inkråm',
                    negateValue: false,
                  }
                )
              ) // <<< end row_empty_value ---
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(
                  multiply(-1, or(account('$ub', accountModifier, 'year'), 0))
                ),
                ref(
                  multiply(
                    -1,
                    or(account('$ib', accountModifier, 'previousYear'), 0)
                  )
                )
              )
              .addSubRows((rows) => {
                return rows.build();
              });
            rows
              .addRowWithType(
                'ForvarvFinansiellaAnlaggningstillgangarAbstract',
                'header',
                value('Förvärv av finansiella anläggningstillgångar'),
                refs(),
                undefined,
                undefined
              )
              .addSubRows((rows) => {
                rows
                  .addRowWithType(
                    'ForvarvAndelarKoncernforetag',
                    'row',
                    value('Förvärv av andelar i koncernföretag'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForvarvFinansiellaAnlaggningstillgangarAbstract.ForvarvAndelarKoncernforetag.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:ForvarvAndelarKoncernforetag',
                        contextRef: 'period0',
                        standardRubrik:
                          'Minskning av kassaflöde vid förvärv av andelar i koncernföretag',
                        negateValue: true,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForvarvFinansiellaAnlaggningstillgangarAbstract.ForvarvAndelarKoncernforetag.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:ForvarvAndelarKoncernforetag',
                        contextRef: 'period1',
                        standardRubrik:
                          'Minskning av kassaflöde vid förvärv av andelar i koncernföretag',
                        negateValue: true,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'ForvarvIntresseforetagGemensamtStyrdaForetag',
                    'row',
                    value(
                      'Förvärv av andelar i intresseföretag och gemensamt styrda företag'
                    ),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForvarvFinansiellaAnlaggningstillgangarAbstract.ForvarvIntresseforetagGemensamtStyrdaForetag.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:ForvarvIntresseforetagGemensamtStyrdaForetag',
                        contextRef: 'period0',
                        standardRubrik:
                          'Minskning av kassaflöde vid förvärv av andelar i intresseföretag och gemensamt styrda företag',
                        negateValue: true,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForvarvFinansiellaAnlaggningstillgangarAbstract.ForvarvIntresseforetagGemensamtStyrdaForetag.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:ForvarvIntresseforetagGemensamtStyrdaForetag',
                        contextRef: 'period1',
                        standardRubrik:
                          'Minskning av kassaflöde vid förvärv av andelar i intresseföretag och gemensamt styrda företag',
                        negateValue: true,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'ForvarvAgarintressenOvrigaForetag',
                    'row',
                    value('Förvärv av ägarintressen i övriga företag'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForvarvFinansiellaAnlaggningstillgangarAbstract.ForvarvAgarintressenOvrigaForetag.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:ForvarvAgarintressenOvrigaForetag',
                        contextRef: 'period0',
                        standardRubrik:
                          'Minskning av kassaflöde vid förvärv av ägarintressen i övriga företag',
                        negateValue: true,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForvarvFinansiellaAnlaggningstillgangarAbstract.ForvarvAgarintressenOvrigaForetag.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:ForvarvAgarintressenOvrigaForetag',
                        contextRef: 'period1',
                        standardRubrik:
                          'Minskning av kassaflöde vid förvärv av ägarintressen i övriga företag',
                        negateValue: true,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'LamnadeLanKoncernforetag',
                    'row',
                    value('Lämnade lån till koncernföretag'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForvarvFinansiellaAnlaggningstillgangarAbstract.LamnadeLanKoncernforetag.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:LamnadeLanKoncernforetag',
                        contextRef: 'period0',
                        standardRubrik:
                          'Minskning av kassaflöde vid lämnade lån till koncernföretag',
                        negateValue: true,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForvarvFinansiellaAnlaggningstillgangarAbstract.LamnadeLanKoncernforetag.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:LamnadeLanKoncernforetag',
                        contextRef: 'period1',
                        standardRubrik:
                          'Minskning av kassaflöde vid lämnade lån till koncernföretag',
                        negateValue: true,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'ForvarvOvrigaFinansiellaAnlaggningstillgangar',
                    'row',
                    value(
                      'Förvärv av övriga finansiella anläggningstillgångar'
                    ),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForvarvFinansiellaAnlaggningstillgangarAbstract.ForvarvOvrigaFinansiellaAnlaggningstillgangar.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:ForvarvOvrigaFinansiellaAnlaggningstillgangar',
                        contextRef: 'period0',
                        standardRubrik:
                          'Minskning av kassaflöde vid förvärv av övriga finansiella anläggningstillgångar',
                        negateValue: true,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForvarvFinansiellaAnlaggningstillgangarAbstract.ForvarvOvrigaFinansiellaAnlaggningstillgangar.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:ForvarvOvrigaFinansiellaAnlaggningstillgangar',
                        contextRef: 'period1',
                        standardRubrik:
                          'Minskning av kassaflöde vid förvärv av övriga finansiella anläggningstillgångar',
                        negateValue: true,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    return rows.build();
                  });
                return rows.build();
              });
            rows
              .addRowWithType(
                'InvesteringFinansiellaAnlaggningstillgangar',
                'sum',
                value('Förvärv av finansiella anläggningstillgångar'),
                undefined,
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForvarvFinansiellaAnlaggningstillgangarAbstract.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    name: 'se-gen-base:InvesteringFinansiellaAnlaggningstillgangar',
                    contextRef: 'period0',
                    standardRubrik:
                      'Minskning av kassaflöde vid förvärv av finansiella anläggningstillgångar',
                    negateValue: true,
                  }
                ),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForvarvFinansiellaAnlaggningstillgangarAbstract.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    name: 'se-gen-base:InvesteringFinansiellaAnlaggningstillgangar',
                    contextRef: 'period1',
                    standardRubrik:
                      'Minskning av kassaflöde vid förvärv av finansiella anläggningstillgångar',
                    negateValue: true,
                  }
                )
              )
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(or(account('$ub', accountModifier, 'year'), 0)),
                ref(or(account('$ib', accountModifier, 'previousYear'), 0))
              )
              .addSubRows((rows) => {
                return rows.build();
              });
            rows
              .addRowWithType(
                'ForsaljningAmorteringFinansiellaAnlaggningstillgangarAbstract',
                'header',
                value(
                  'Avyttring/amortering av finansiella anläggningstillgångar'
                ),
                refs(),
                undefined,
                undefined
              )
              .addSubRows((rows) => {
                rows
                  .addRowWithType(
                    'ForsaljningKoncernforetag',
                    'row',
                    value('Avyttring av andelar i koncernföretag'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForsaljningAmorteringFinansiellaAnlaggningstillgangarAbstract.ForsaljningKoncernforetag.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:ForsaljningKoncernforetag',
                        contextRef: 'period0',
                        standardRubrik:
                          'Ökning av kassaflöde vid avyttring av andelar i koncernföretag',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForsaljningAmorteringFinansiellaAnlaggningstillgangarAbstract.ForsaljningKoncernforetag.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:ForsaljningKoncernforetag',
                        contextRef: 'period1',
                        standardRubrik:
                          'Ökning av kassaflöde vid avyttring av andelar i koncernföretag',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'ForsaljningIntresseforetagGemensamtStyrdaForetag',
                    'row',
                    value(
                      'Avyttring av andelar i intresseföretag och gemensamt styrda företag'
                    ),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForsaljningAmorteringFinansiellaAnlaggningstillgangarAbstract.ForsaljningIntresseforetagGemensamtStyrdaForetag.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:ForsaljningIntresseforetagGemensamtStyrdaForetag',
                        contextRef: 'period0',
                        standardRubrik:
                          'Ökning av kassaflöde vid avyttring av andelar i intresseföretag och gemensamt styrda företag',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForsaljningAmorteringFinansiellaAnlaggningstillgangarAbstract.ForsaljningIntresseforetagGemensamtStyrdaForetag.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:ForsaljningIntresseforetagGemensamtStyrdaForetag',
                        contextRef: 'period1',
                        standardRubrik:
                          'Ökning av kassaflöde vid avyttring av andelar i intresseföretag och gemensamt styrda företag',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'ForsaljningAgarintressenOvrigaForetag',
                    'row',
                    value('Avyttring av ägarintressen i övriga företag'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForsaljningAmorteringFinansiellaAnlaggningstillgangarAbstract.ForsaljningAgarintressenOvrigaForetag.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:ForsaljningAgarintressenOvrigaForetag',
                        contextRef: 'period0',
                        standardRubrik:
                          'Ökning av kassaflöde vid avyttring av ägarintressen i övriga företag',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForsaljningAmorteringFinansiellaAnlaggningstillgangarAbstract.ForsaljningAgarintressenOvrigaForetag.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:ForsaljningAgarintressenOvrigaForetag',
                        contextRef: 'period1',
                        standardRubrik:
                          'Ökning av kassaflöde vid avyttring av ägarintressen i övriga företag',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'ErhallnaAmorteringarKoncernforetag',
                    'row',
                    value('Erhållna amorteringar från koncernföretag'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForsaljningAmorteringFinansiellaAnlaggningstillgangarAbstract.ErhallnaAmorteringarKoncernforetag.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:ErhallnaAmorteringarKoncernforetag',
                        contextRef: 'period0',
                        standardRubrik:
                          'Ökning av kassaflöde vid erhållna amorteringar från koncernföretag',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForsaljningAmorteringFinansiellaAnlaggningstillgangarAbstract.ErhallnaAmorteringarKoncernforetag.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:ErhallnaAmorteringarKoncernforetag',
                        contextRef: 'period1',
                        standardRubrik:
                          'Ökning av kassaflöde vid erhållna amorteringar från koncernföretag',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'ForsaljningOvrigaFinansiellaAnlaggningstillgangar',
                    'row',
                    value(
                      'Avyttring av övriga finansiella anläggningstillgångar'
                    ),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForsaljningAmorteringFinansiellaAnlaggningstillgangarAbstract.ForsaljningOvrigaFinansiellaAnlaggningstillgangar.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:ForsaljningOvrigaFinansiellaAnlaggningstillgangar',
                        contextRef: 'period0',
                        standardRubrik:
                          'Ökning av kassaflöde vid avyttring av övriga finansiella anläggningstillgångar',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForsaljningAmorteringFinansiellaAnlaggningstillgangarAbstract.ForsaljningOvrigaFinansiellaAnlaggningstillgangar.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:ForsaljningOvrigaFinansiellaAnlaggningstillgangar',
                        contextRef: 'period1',
                        standardRubrik:
                          'Ökning av kassaflöde vid avyttring av övriga finansiella anläggningstillgångar',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    return rows.build();
                  });
                return rows.build();
              });
            rows
              .addRowWithType(
                'ForsaljningFinansiellaAnlaggningstillgangar',
                'sum',
                value(
                  'Avyttring/amortering av finansiella anläggningstillgångar'
                ),
                undefined,
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForsaljningAmorteringFinansiellaAnlaggningstillgangarAbstract.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    name: 'se-gen-base:ForsaljningFinansiellaAnlaggningstillgangar',
                    contextRef: 'period0',
                    standardRubrik:
                      'Ökning av kassaflöde vid avyttring eller amortering av finansiella anläggningstillgångar',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForsaljningAmorteringFinansiellaAnlaggningstillgangarAbstract.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    name: 'se-gen-base:ForsaljningFinansiellaAnlaggningstillgangar',
                    contextRef: 'period1',
                    standardRubrik:
                      'Ökning av kassaflöde vid avyttring eller amortering av finansiella anläggningstillgångar',
                    negateValue: false,
                  }
                )
              )
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(or(account('$ub', accountModifier, 'year'), 0)),
                ref(or(account('$ib', accountModifier, 'previousYear'), 0))
              )
              .addSubRows((rows) => {
                return rows.build();
              });
            rows
              .addRowWithType(
                'ForandringKassaflodeOvrigaKortfristigaPlaceringar',
                'row',
                value('Ökning/minskning av kortfristiga placeringar'),
                refs(),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForandringKassaflodeOvrigaKortfristigaPlaceringar.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:ForandringKassaflodeOvrigaKortfristigaPlaceringar',
                    contextRef: 'period0',
                    standardRubrik:
                      'Ökning (minskning) av kassaflöde vid förändring av kortfristiga placeringar',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForandringKassaflodeOvrigaKortfristigaPlaceringar.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:ForandringKassaflodeOvrigaKortfristigaPlaceringar',
                    contextRef: 'period1',
                    standardRubrik:
                      'Ökning (minskning) av kassaflöde vid förändring av kortfristiga placeringar',
                    negateValue: false,
                  }
                )
              ) // <<< end row_empty_value ---
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(
                  multiply(-1, or(account('$ub', accountModifier, 'year'), 0))
                ),
                ref(
                  multiply(
                    -1,
                    or(account('$ib', accountModifier, 'previousYear'), 0)
                  )
                )
              )
              .addSubRows((rows) => {
                return rows.build();
              });
            return rows.build();
          });
        rows
          .addRowWithType(
            'KassaflodeInvesteringsverksamheten',
            'sum',
            value('Kassaflöde från investeringsverksamheten'),
            undefined,
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.*.year'
                  )
                )
              ),
              {
                type: 'monetaryItemType',
                name: 'se-gen-base:KassaflodeInvesteringsverksamheten',
                contextRef: 'period0',
                standardRubrik:
                  'Ökning (minskning) av kassaflöde från investeringsverksamheten',
                negateValue: false,
              }
            ),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.*.previousYear'
                  )
                )
              ),
              {
                type: 'monetaryItemType',
                name: 'se-gen-base:KassaflodeInvesteringsverksamheten',
                contextRef: 'period1',
                standardRubrik:
                  'Ökning (minskning) av kassaflöde från investeringsverksamheten',
                negateValue: false,
              }
            )
          )
          .newRowTemplateWithType(
            'account',
            label('$label'),
            undefined,
            ref(or(account('$ub', accountModifier, 'year'), 0)),
            ref(or(account('$ib', accountModifier, 'previousYear'), 0))
          )
          .addSubRows((rows) => {
            return rows.build();
          });
        rows
          .addRowWithType(
            'KassaflodesanalysFinansieringsverksamhetenAbstract',
            'header',
            value('Finansieringsverksamheten'),
            refs(),
            undefined,
            undefined
          )
          .addSubRows((rows) => {
            rows
              .addRowWithType(
                'UtstalldaAktieoptioner',
                'row',
                value('Utställda aktieoptioner'),
                refs(),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.UtstalldaAktieoptioner.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:UtstalldaAktieoptioner',
                    contextRef: 'period0',
                    standardRubrik:
                      'Ökning av kassaflöde vid utställande av aktieoptioner',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.UtstalldaAktieoptioner.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:UtstalldaAktieoptioner',
                    contextRef: 'period1',
                    standardRubrik:
                      'Ökning av kassaflöde vid utställande av aktieoptioner',
                    negateValue: false,
                  }
                )
              ) // <<< end row_empty_value ---
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(
                  multiply(-1, or(account('$ub', accountModifier, 'year'), 0))
                ),
                ref(
                  multiply(
                    -1,
                    or(account('$ib', accountModifier, 'previousYear'), 0)
                  )
                )
              )
              .addSubRows((rows) => {
                return rows.build();
              });
            rows
              .addRowWithType(
                'InlostaAktieoptioner',
                'row',
                value('Inlösta aktieoptioner'),
                refs(),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.InlostaAktieoptioner.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:InlostaAktieoptioner',
                    contextRef: 'period0',
                    standardRubrik:
                      'Minskning av kassaflöde vid inlösning av aktieoptioner',
                    negateValue: true,
                  }
                ),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.InlostaAktieoptioner.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:InlostaAktieoptioner',
                    contextRef: 'period1',
                    standardRubrik:
                      'Minskning av kassaflöde vid inlösning av aktieoptioner',
                    negateValue: true,
                  }
                )
              ) // <<< end row_empty_value ---
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(
                  multiply(-1, or(account('$ub', accountModifier, 'year'), 0))
                ),
                ref(
                  multiply(
                    -1,
                    or(account('$ib', accountModifier, 'previousYear'), 0)
                  )
                )
              )
              .addSubRows((rows) => {
                return rows.build();
              });
            rows
              .addRowWithType(
                'NyemissionKassaflode',
                'row',
                value('Nyemission'),
                refs(),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.NyemissionKassaflode.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:NyemissionKassaflode',
                    contextRef: 'period0',
                    standardRubrik: 'Ökning av kassaflöde vid nyemission',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.NyemissionKassaflode.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:NyemissionKassaflode',
                    contextRef: 'period1',
                    standardRubrik: 'Ökning av kassaflöde vid nyemission',
                    negateValue: false,
                  }
                )
              ) // <<< end row_empty_value ---
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(
                  multiply(-1, or(account('$ub', accountModifier, 'year'), 0))
                ),
                ref(
                  multiply(
                    -1,
                    or(account('$ib', accountModifier, 'previousYear'), 0)
                  )
                )
              )
              .addSubRows((rows) => {
                return rows.build();
              });
            rows
              .addRowWithType(
                'Emissionsutgifter',
                'row',
                value('Emissionsutgifter'),
                refs(),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.Emissionsutgifter.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:Emissionsutgifter',
                    contextRef: 'period0',
                    standardRubrik:
                      'Minskning av kassaflöde till följd av emissionsutgifter',
                    negateValue: true,
                  }
                ),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.Emissionsutgifter.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:Emissionsutgifter',
                    contextRef: 'period1',
                    standardRubrik:
                      'Minskning av kassaflöde till följd av emissionsutgifter',
                    negateValue: true,
                  }
                )
              ) // <<< end row_empty_value ---
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(
                  multiply(-1, or(account('$ub', accountModifier, 'year'), 0))
                ),
                ref(
                  multiply(
                    -1,
                    or(account('$ib', accountModifier, 'previousYear'), 0)
                  )
                )
              )
              .addSubRows((rows) => {
                return rows.build();
              });
            rows
              .addRowWithType(
                'AterkopEgnaAktier',
                'row',
                value('Återköp av egna aktier inklusive transaktionsutgifter'),
                refs(),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.AterkopEgnaAktier.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:AterkopEgnaAktier',
                    contextRef: 'period0',
                    standardRubrik:
                      'Minskning av kassaflöde vid återköp av egna aktier inklusive transaktionsutgifter',
                    negateValue: true,
                  }
                ),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.AterkopEgnaAktier.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:AterkopEgnaAktier',
                    contextRef: 'period1',
                    standardRubrik:
                      'Minskning av kassaflöde vid återköp av egna aktier inklusive transaktionsutgifter',
                    negateValue: true,
                  }
                )
              ) // <<< end row_empty_value ---
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(
                  multiply(-1, or(account('$ub', accountModifier, 'year'), 0))
                ),
                ref(
                  multiply(
                    -1,
                    or(account('$ib', accountModifier, 'previousYear'), 0)
                  )
                )
              )
              .addSubRows((rows) => {
                return rows.build();
              });
            rows
              .addRowWithType(
                'OverlatelseEgnaAktier',
                'row',
                value('Överlåtelse av egna aktier'),
                refs(),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.OverlatelseEgnaAktier.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:OverlatelseEgnaAktier',
                    contextRef: 'period0',
                    standardRubrik:
                      'Ökning av kassaflöde vid överlåtelse av egna aktier',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.OverlatelseEgnaAktier.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:OverlatelseEgnaAktier',
                    contextRef: 'period1',
                    standardRubrik:
                      'Ökning av kassaflöde vid överlåtelse av egna aktier',
                    negateValue: false,
                  }
                )
              ) // <<< end row_empty_value ---
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(
                  multiply(-1, or(account('$ub', accountModifier, 'year'), 0))
                ),
                ref(
                  multiply(
                    -1,
                    or(account('$ib', accountModifier, 'previousYear'), 0)
                  )
                )
              )
              .addSubRows((rows) => {
                return rows.build();
              });
            rows
              .addRowWithType(
                'ForandringCheckrakningskredit',
                'row',
                value('Ökning/minskning av checkräkningskredit'),
                refs(),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.ForandringCheckrakningskredit.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:ForandringCheckrakningskredit',
                    contextRef: 'period0',
                    standardRubrik:
                      'Ökning (minskning) av kassaflöde vid förändring av checkräkningskredit',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.ForandringCheckrakningskredit.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:ForandringCheckrakningskredit',
                    contextRef: 'period1',
                    standardRubrik:
                      'Ökning (minskning) av kassaflöde vid förändring av checkräkningskredit',
                    negateValue: false,
                  }
                )
              ) // <<< end row_empty_value ---
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(
                  multiply(-1, or(account('$ub', accountModifier, 'year'), 0))
                ),
                ref(
                  multiply(
                    -1,
                    or(account('$ib', accountModifier, 'previousYear'), 0)
                  )
                )
              )
              .addSubRows((rows) => {
                return rows.build();
              });
            rows
              .addRowWithType(
                'ErhallnaAktieagartillskottKassaflode',
                'row',
                value('Erhållna tillskott'),
                refs(),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.ErhallnaAktieagartillskottKassaflode.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:ErhallnaAktieagartillskottKassaflode',
                    contextRef: 'period0',
                    standardRubrik:
                      'Ökning av kassaflöde vid erhållna tillskott',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.ErhallnaAktieagartillskottKassaflode.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:ErhallnaAktieagartillskottKassaflode',
                    contextRef: 'period1',
                    standardRubrik:
                      'Ökning av kassaflöde vid erhållna tillskott',
                    negateValue: false,
                  }
                )
              ) // <<< end row_empty_value ---
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(
                  multiply(-1, or(account('$ub', accountModifier, 'year'), 0))
                ),
                ref(
                  multiply(
                    -1,
                    or(account('$ib', accountModifier, 'previousYear'), 0)
                  )
                )
              )
              .addSubRows((rows) => {
                return rows.build();
              });
            rows
              .addRowWithType(
                'ForandringKassaflodeErhallnaKoncernbidragFinansieringsverksamheten',
                'row',
                value('Erhållna koncernbidrag'),
                refs(),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.ForandringKassaflodeErhallnaKoncernbidragFinansieringsverksamheten.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:ForandringKassaflodeErhallnaKoncernbidragFinansieringsverksamheten',
                    contextRef: 'period0',
                    standardRubrik:
                      'Ökning av kassaflöde vid erhållna koncernbidrag',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.ForandringKassaflodeErhallnaKoncernbidragFinansieringsverksamheten.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:ForandringKassaflodeErhallnaKoncernbidragFinansieringsverksamheten',
                    contextRef: 'period1',
                    standardRubrik:
                      'Ökning av kassaflöde vid erhållna koncernbidrag',
                    negateValue: false,
                  }
                )
              ) // <<< end row_empty_value ---
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(
                  multiply(-1, or(account('$ub', accountModifier, 'year'), 0))
                ),
                ref(
                  multiply(
                    -1,
                    or(account('$ib', accountModifier, 'previousYear'), 0)
                  )
                )
              )
              .addSubRows((rows) => {
                return rows.build();
              });
            rows
              .addRowWithType(
                'EmissionKonvertibellan',
                'row',
                value('Emission av konvertibellån'),
                refs(),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.EmissionKonvertibellan.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:EmissionKonvertibellan',
                    contextRef: 'period0',
                    standardRubrik:
                      'Ökning av kassaflöde vid emission av konvertibellån',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.EmissionKonvertibellan.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:EmissionKonvertibellan',
                    contextRef: 'period1',
                    standardRubrik:
                      'Ökning av kassaflöde vid emission av konvertibellån',
                    negateValue: false,
                  }
                )
              ) // <<< end row_empty_value ---
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(
                  multiply(-1, or(account('$ub', accountModifier, 'year'), 0))
                ),
                ref(
                  multiply(
                    -1,
                    or(account('$ib', accountModifier, 'previousYear'), 0)
                  )
                )
              )
              .addSubRows((rows) => {
                return rows.build();
              });
            rows
              .addRowWithType(
                'EmissionInlosningsbaraPreferensaktier',
                'row',
                value('Emission av inlösningsbara preferensaktier'),
                refs(),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.EmissionInlosningsbaraPreferensaktier.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:EmissionInlosningsbaraPreferensaktier',
                    contextRef: 'period0',
                    standardRubrik:
                      'Ökning av kassaflöde vid emission av inlösningsbara preferensaktier',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.EmissionInlosningsbaraPreferensaktier.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:EmissionInlosningsbaraPreferensaktier',
                    contextRef: 'period1',
                    standardRubrik:
                      'Ökning av kassaflöde vid emission av inlösningsbara preferensaktier',
                    negateValue: false,
                  }
                )
              ) // <<< end row_empty_value ---
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(
                  multiply(-1, or(account('$ub', accountModifier, 'year'), 0))
                ),
                ref(
                  multiply(
                    -1,
                    or(account('$ib', accountModifier, 'previousYear'), 0)
                  )
                )
              )
              .addSubRows((rows) => {
                return rows.build();
              });
            rows
              .addRowWithType(
                'ForandringKassaflodeLamnadeKoncernbidragFinansieringsverksamheten',
                'row',
                value('Lämnade koncernbidrag'),
                refs(),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.ForandringKassaflodeLamnadeKoncernbidragFinansieringsverksamheten.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:ForandringKassaflodeLamnadeKoncernbidragFinansieringsverksamheten',
                    contextRef: 'period0',
                    standardRubrik:
                      'Minskning av kassaflöde vid lämnade koncernbidrag i finansieringsverksamheten',
                    negateValue: true,
                  }
                ),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.ForandringKassaflodeLamnadeKoncernbidragFinansieringsverksamheten.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:ForandringKassaflodeLamnadeKoncernbidragFinansieringsverksamheten',
                    contextRef: 'period1',
                    standardRubrik:
                      'Minskning av kassaflöde vid lämnade koncernbidrag i finansieringsverksamheten',
                    negateValue: true,
                  }
                )
              ) // <<< end row_empty_value ---
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(
                  multiply(-1, or(account('$ub', accountModifier, 'year'), 0))
                ),
                ref(
                  multiply(
                    -1,
                    or(account('$ib', accountModifier, 'previousYear'), 0)
                  )
                )
              )
              .addSubRows((rows) => {
                return rows.build();
              });
            rows
              .addRowWithType(
                'UpptagnaLan',
                'row',
                value('Upptagna lån'),
                refs(),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.UpptagnaLan.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:UpptagnaLan',
                    contextRef: 'period0',
                    standardRubrik:
                      'Ökning av kassaflöde till följd av upptagna lån',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.UpptagnaLan.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:UpptagnaLan',
                    contextRef: 'period1',
                    standardRubrik:
                      'Ökning av kassaflöde till följd av upptagna lån',
                    negateValue: false,
                  }
                )
              ) // <<< end row_empty_value ---
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(
                  multiply(-1, or(account('$ub', accountModifier, 'year'), 0))
                ),
                ref(
                  multiply(
                    -1,
                    or(account('$ib', accountModifier, 'previousYear'), 0)
                  )
                )
              )
              .addSubRows((rows) => {
                return rows.build();
              });
            rows
              .addRowWithType(
                'AmorteringSkuld',
                'row',
                value('Amortering av skuld'),
                refs(),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.AmorteringSkuld.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:AmorteringSkuld',
                    contextRef: 'period0',
                    standardRubrik:
                      'Minskning av kassaflöde vid amortering av skuld',
                    negateValue: true,
                  }
                ),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.AmorteringSkuld.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:AmorteringSkuld',
                    contextRef: 'period1',
                    standardRubrik:
                      'Minskning av kassaflöde vid amortering av skuld',
                    negateValue: true,
                  }
                )
              ) // <<< end row_empty_value ---
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(
                  multiply(-1, or(account('$ub', accountModifier, 'year'), 0))
                ),
                ref(
                  multiply(
                    -1,
                    or(account('$ib', accountModifier, 'previousYear'), 0)
                  )
                )
              )
              .addSubRows((rows) => {
                return rows.build();
              });
            rows
              .addRowWithType(
                'AmorteringLeasingskuld',
                'row',
                value('Amortering av leasingskuld'),
                refs(),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.AmorteringLeasingskuld.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:AmorteringLeasingskuld',
                    contextRef: 'period0',
                    standardRubrik:
                      'Minskning av kassaflöde vid amortering av leasingskuld',
                    negateValue: true,
                  }
                ),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.AmorteringLeasingskuld.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:AmorteringLeasingskuld',
                    contextRef: 'period1',
                    standardRubrik:
                      'Minskning av kassaflöde vid amortering av leasingskuld',
                    negateValue: true,
                  }
                )
              ) // <<< end row_empty_value ---
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(
                  multiply(-1, or(account('$ub', accountModifier, 'year'), 0))
                ),
                ref(
                  multiply(
                    -1,
                    or(account('$ib', accountModifier, 'previousYear'), 0)
                  )
                )
              )
              .addSubRows((rows) => {
                return rows.build();
              });
            rows
              .addRowWithType(
                'GottgorelsePensionsstiftelse',
                'row',
                value('Gottgörelse från pensionsstiftelse'),
                refs(),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.GottgorelsePensionsstiftelse.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:GottgorelsePensionsstiftelse',
                    contextRef: 'period0',
                    standardRubrik:
                      'Ökning av kassaflöde vid gottgörelse från pensionsstiftelse',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.GottgorelsePensionsstiftelse.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:GottgorelsePensionsstiftelse',
                    contextRef: 'period1',
                    standardRubrik:
                      'Ökning av kassaflöde vid gottgörelse från pensionsstiftelse',
                    negateValue: false,
                  }
                )
              ) // <<< end row_empty_value ---
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(
                  multiply(-1, or(account('$ub', accountModifier, 'year'), 0))
                ),
                ref(
                  multiply(
                    -1,
                    or(account('$ib', accountModifier, 'previousYear'), 0)
                  )
                )
              )
              .addSubRows((rows) => {
                return rows.build();
              });
            rows
              .addRowWithType(
                'AmortoringPensionsskuld',
                'row',
                value('Amortering av pensionsskuld'),
                refs(),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.AmortoringPensionsskuld.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:AmortoringPensionsskuld',
                    contextRef: 'period0',
                    standardRubrik:
                      'Minskning av kassaflöde vid amortering av pensionsskuld',
                    negateValue: true,
                  }
                ),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.AmortoringPensionsskuld.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:AmortoringPensionsskuld',
                    contextRef: 'period1',
                    standardRubrik:
                      'Minskning av kassaflöde vid amortering av pensionsskuld',
                    negateValue: true,
                  }
                )
              ) // <<< end row_empty_value ---
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(
                  multiply(-1, or(account('$ub', accountModifier, 'year'), 0))
                ),
                ref(
                  multiply(
                    -1,
                    or(account('$ib', accountModifier, 'previousYear'), 0)
                  )
                )
              )
              .addSubRows((rows) => {
                return rows.build();
              });
            rows
              .addRowWithType(
                'KortfristigaFinansiellaSkulder',
                'row',
                value('Ökning/minskning av kortfristiga finansiella skulder'),
                refs(),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.KortfristigaFinansiellaSkulder.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:KortfristigaFinansiellaSkulder',
                    contextRef: 'period0',
                    standardRubrik:
                      'Ökning (minskning) av kassaflöde vid förändring av kortfristiga finansiella skulder',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.KortfristigaFinansiellaSkulder.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:KortfristigaFinansiellaSkulder',
                    contextRef: 'period1',
                    standardRubrik:
                      'Ökning (minskning) av kassaflöde vid förändring av kortfristiga finansiella skulder',
                    negateValue: false,
                  }
                )
              ) // <<< end row_empty_value ---
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(
                  multiply(-1, or(account('$ub', accountModifier, 'year'), 0))
                ),
                ref(
                  multiply(
                    -1,
                    or(account('$ib', accountModifier, 'previousYear'), 0)
                  )
                )
              )
              .addSubRows((rows) => {
                return rows.build();
              });
            rows
              .addRowWithType(
                'UtbetaldUtdelningKassaflode',
                'row',
                value('Utbetald utdelning'),
                refs(),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.UtbetaldUtdelningKassaflode.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:UtbetaldUtdelningKassaflode',
                    contextRef: 'period0',
                    standardRubrik:
                      'Minskning av kassaflöde till följd av utbetald utdelning',
                    negateValue: true,
                  }
                ),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.UtbetaldUtdelningKassaflode.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:UtbetaldUtdelningKassaflode',
                    contextRef: 'period1',
                    standardRubrik:
                      'Minskning av kassaflöde till följd av utbetald utdelning',
                    negateValue: true,
                  }
                )
              ) // <<< end row_empty_value ---
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(
                  multiply(-1, or(account('$ub', accountModifier, 'year'), 0))
                ),
                ref(
                  multiply(
                    -1,
                    or(account('$ib', accountModifier, 'previousYear'), 0)
                  )
                )
              )
              .addSubRows((rows) => {
                return rows.build();
              });
            return rows.build();
          });
        rows
          .addRowWithType(
            'KassaflodeFinansieringsverksamheten',
            'sum',
            value('Kassaflöde från finansieringsverksamheten'),
            undefined,
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.*.year'
                  )
                )
              ),
              {
                type: 'monetaryItemType',
                name: 'se-gen-base:KassaflodeFinansieringsverksamheten',
                contextRef: 'period0',
                standardRubrik:
                  'Ökning (minskning) av kassaflöde från finansieringsverksamheten',
                negateValue: false,
              }
            ),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.*.previousYear'
                  )
                )
              ),
              {
                type: 'monetaryItemType',
                name: 'se-gen-base:KassaflodeFinansieringsverksamheten',
                contextRef: 'period1',
                standardRubrik:
                  'Ökning (minskning) av kassaflöde från finansieringsverksamheten',
                negateValue: false,
              }
            )
          )
          .newRowTemplateWithType(
            'account',
            label('$label'),
            undefined,
            ref(or(account('$ub', accountModifier, 'year'), 0)),
            ref(or(account('$ib', accountModifier, 'previousYear'), 0))
          )
          .addSubRows((rows) => {
            return rows.build();
          });
        rows
          .addRowWithType(
            'AretsKassaflode',
            'alwaysDisplaySum',
            value('Årets kassaflöde'),
            undefined,
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'cashFlowStatement.section.table.KassaflodeLopandeVerksamheten.year'
                  ),
                  id(
                    'cashFlowStatement.section.table.KassaflodeInvesteringsverksamheten.year'
                  ),
                  id(
                    'cashFlowStatement.section.table.KassaflodeFinansieringsverksamheten.year'
                  )
                )
              ),
              {
                type: 'monetaryItemType',
                name: 'se-gen-base:AretsKassaflode',
                contextRef: 'period0',
                standardRubrik: 'Ökning (minskning) av kassaflöde',
                negateValue: false,
              }
            ),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'cashFlowStatement.section.table.KassaflodeLopandeVerksamheten.previousYear'
                  ),
                  id(
                    'cashFlowStatement.section.table.KassaflodeInvesteringsverksamheten.previousYear'
                  ),
                  id(
                    'cashFlowStatement.section.table.KassaflodeFinansieringsverksamheten.previousYear'
                  )
                )
              ),
              {
                type: 'monetaryItemType',
                name: 'se-gen-base:AretsKassaflode',
                contextRef: 'period1',
                standardRubrik: 'Ökning (minskning) av kassaflöde',
                negateValue: false,
              }
            )
          )
          .newRowTemplateWithType(
            'account',
            label('$label'),
            undefined,
            ref(or(account('$ub', accountModifier, 'year'), 0)),
            ref(or(account('$ib', accountModifier, 'previousYear'), 0))
          )
          .addSubRows((rows) => {
            return rows.build();
          });
        rows
          .addRowWithType(
            'LikvidaMedelBorjan',
            'alwaysDisplay',
            value('Likvida medel vid årets början'),
            refs(),
            ixbrlCell(
              ref(
                sum(
                  id(
                    'cashFlowStatement.section.table.LikvidaMedelBorjan.*.year'
                  )
                )
              ),
              {
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:LikvidaMedel',
                contextRef: 'balans1',
                standardRubrik: 'Likvida medel',
                saldo: 'debit',
                negateValue: false,
              }
            ),
            ixbrlCell(
              ref(
                sum(
                  id(
                    'cashFlowStatement.section.table.LikvidaMedelBorjan.*.previousYear'
                  )
                )
              ),
              {
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:LikvidaMedel',
                contextRef: 'balans2',
                standardRubrik: 'Likvida medel',
                saldo: 'debit',
                negateValue: false,
              }
            )
          ) // <<< end row_empty_value ---
          .newRowTemplateWithType(
            'account',
            label('$label'),
            undefined,
            ref(or(account('$ub', 'ib', 'year'), 0)),
            ref(or(account('$ib', 'ib', 'previousYear'), 0))
          )
          .addSubRows((rows) => {
            return rows.build();
          });
        rows
          .addRowWithType(
            'ForandringLikvidaMeldelAbstract',
            'header',
            value('Förändring av likvida medel'),
            refs(),
            undefined,
            undefined
          )
          .addSubRows((rows) => {
            rows
              .addRowWithType(
                'LikvidaMedelKursdifferens',
                'row',
                value('Kursdifferens i likvida medel'),
                refs(),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.ForandringLikvidaMeldelAbstract.LikvidaMedelKursdifferens.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:LikvidaMedelKursdifferens',
                    contextRef: 'period0',
                    standardRubrik:
                      'Ökning (minskning) av likvida medel vid kursdifferenser',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.ForandringLikvidaMeldelAbstract.LikvidaMedelKursdifferens.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:LikvidaMedelKursdifferens',
                    contextRef: 'period1',
                    standardRubrik:
                      'Ökning (minskning) av likvida medel vid kursdifferenser',
                    negateValue: false,
                  }
                )
              ) // <<< end row_empty_value ---
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(
                  multiply(-1, or(account('$ub', accountModifier, 'year'), 0))
                ),
                ref(
                  multiply(
                    -1,
                    or(account('$ib', accountModifier, 'previousYear'), 0)
                  )
                )
              )
              .addSubRows((rows) => {
                return rows.build();
              });
            return rows.build();
          });
        rows
          .addRowWithType(
            'LikvidaMedelSlut',
            'alwaysDisplaySum',
            value('Likvida medel vid årets slut'),
            undefined,
            ixbrlCell(
              ref(
                sumAllowNull(
                  id('cashFlowStatement.section.table.AretsKassaflode.year'),
                  id('cashFlowStatement.section.table.LikvidaMedelBorjan.year'),
                  id(
                    'cashFlowStatement.section.table.ForandringLikvidaMeldelAbstract.LikvidaMedelKursdifferens.year'
                  )
                )
              ),
              {
                type: 'monetaryItemType',
                name: 'se-gen-base:LikvidaMedel',
                contextRef: 'balans0',
                standardRubrik: 'Likvida medel',
                saldo: 'debit',
                negateValue: false,
              }
            ),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'cashFlowStatement.section.table.AretsKassaflode.previousYear'
                  ),
                  id(
                    'cashFlowStatement.section.table.LikvidaMedelBorjan.previousYear'
                  ),
                  id(
                    'cashFlowStatement.section.table.ForandringLikvidaMeldelAbstract.LikvidaMedelKursdifferens.previousYear'
                  )
                )
              ),
              {
                type: 'monetaryItemType',
                name: 'se-gen-base:LikvidaMedel',
                contextRef: 'balans1',
                standardRubrik: 'Likvida medel',
                saldo: 'debit',
                negateValue: false,
              }
            )
          )
          .newRowTemplateWithType(
            'account',
            label('$label'),
            undefined,
            ref(or(account('$ub', accountModifier, 'year'), 0)),
            ref(or(account('$ib', accountModifier, 'previousYear'), 0))
          )
          .addSubRows((rows) => {
            return rows.build();
          });
        rows
          .addRowWithType(
            'accountsNotAssigned',
            'accountsNotAssigned',
            label('Ej tilldelade konton'),
            undefined,
            undefined,
            undefined
          ) // >>> begin `add_button` (NL 0) [Id `cashFlowStatement.section.table`] ---
          .newRowTemplateWithType(
            'account',
            label('$label'),
            undefined,
            ref(or(account('$ub', accountModifier, 'year'), 0)),
            ref(or(account('$ib', accountModifier, 'previousYear'), 0))
          )
          .addSubRows((rows) => {
            accountRangeRows(
              accounts,
              1000,
              8999,
              rows,
              false,
              'year',
              'previousYear',
              accountModifier
            );

            return rows.build();
          });
        return rows.build();
      })
      .build(),
  },
});
export const cashFlowStatementK3v2References = (): Record<string, string> => ({
  'cashFlowStatement.Rorelseresultat.year': id(
    'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.KassaflodeIngangsvardeLopandeVerksamhetenAbstract.Rorelseresultat.year'
  ),
  'cashFlowStatement.Rorelseresultat.previousYear': id(
    'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.KassaflodeIngangsvardeLopandeVerksamhetenAbstract.Rorelseresultat.previousYear'
  ),
  'cashFlowStatement.ResultatEfterFinansiellaPoster.year': id(
    'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.KassaflodeIngangsvardeLopandeVerksamhetenAbstract.ResultatEfterFinansiellaPoster.year'
  ),
  'cashFlowStatement.ResultatEfterFinansiellaPoster.previousYear': id(
    'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.KassaflodeIngangsvardeLopandeVerksamhetenAbstract.ResultatEfterFinansiellaPoster.previousYear'
  ),
  'cashFlowStatement.AretsResultat.year': id(
    'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.KassaflodeIngangsvardeLopandeVerksamhetenAbstract.AretsResultat.year'
  ),
  'cashFlowStatement.AretsResultat.previousYear': id(
    'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.KassaflodeIngangsvardeLopandeVerksamhetenAbstract.AretsResultat.previousYear'
  ),
  'cashFlowStatement.JusteringPosterSomInteIngarIKassaflodet.year': id(
    'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.KassaflodeIngangsvardeLopandeVerksamhetenAbstract.JusteringPosterSomInteIngarIKassaflodet.year'
  ),
  'cashFlowStatement.JusteringPosterSomInteIngarIKassaflodet.previousYear': id(
    'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.KassaflodeIngangsvardeLopandeVerksamhetenAbstract.JusteringPosterSomInteIngarIKassaflodet.previousYear'
  ),
  'cashFlowStatement.ErhallenRanta.year': id(
    'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.KassaflodeIngangsvardeLopandeVerksamhetenAbstract.ErhallenRanta.year'
  ),
  'cashFlowStatement.ErhallenRanta.previousYear': id(
    'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.KassaflodeIngangsvardeLopandeVerksamhetenAbstract.ErhallenRanta.previousYear'
  ),
  'cashFlowStatement.ErhallenUtdelning.year': id(
    'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.KassaflodeIngangsvardeLopandeVerksamhetenAbstract.ErhallenUtdelning.year'
  ),
  'cashFlowStatement.ErhallenUtdelning.previousYear': id(
    'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.KassaflodeIngangsvardeLopandeVerksamhetenAbstract.ErhallenUtdelning.previousYear'
  ),
  'cashFlowStatement.ErlagdRanta.year': id(
    'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.KassaflodeIngangsvardeLopandeVerksamhetenAbstract.ErlagdRanta.year'
  ),
  'cashFlowStatement.ErlagdRanta.previousYear': id(
    'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.KassaflodeIngangsvardeLopandeVerksamhetenAbstract.ErlagdRanta.previousYear'
  ),
  'cashFlowStatement.BetaldSkatt.year': id(
    'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.KassaflodeIngangsvardeLopandeVerksamhetenAbstract.BetaldSkatt.year'
  ),
  'cashFlowStatement.BetaldSkatt.previousYear': id(
    'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.KassaflodeIngangsvardeLopandeVerksamhetenAbstract.BetaldSkatt.previousYear'
  ),
  'cashFlowStatement.KassaflodeLopandeVerksamhetenForeForandringarRorelsekapital.year':
    id(
      'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.KassaflodeLopandeVerksamhetenForeForandringarRorelsekapital.year'
    ),
  'cashFlowStatement.KassaflodeLopandeVerksamhetenForeForandringarRorelsekapital.previousYear':
    id(
      'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.KassaflodeLopandeVerksamhetenForeForandringarRorelsekapital.previousYear'
    ),
  'cashFlowStatement.ForandringKassaflodeVarulagerPagaendeArbeten.year': id(
    'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.ForandringKassaflodeRorelsekapitaletAbstract.ForandringKassaflodeVarulagerPagaendeArbeten.year'
  ),
  'cashFlowStatement.ForandringKassaflodeVarulagerPagaendeArbeten.previousYear':
    id(
      'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.ForandringKassaflodeRorelsekapitaletAbstract.ForandringKassaflodeVarulagerPagaendeArbeten.previousYear'
    ),
  'cashFlowStatement.ForandringKassaflodeKundfordringar.year': id(
    'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.ForandringKassaflodeRorelsekapitaletAbstract.ForandringKassaflodeRorelsefodringarAbstract.ForandringKassaflodeKundfordringar.year'
  ),
  'cashFlowStatement.ForandringKassaflodeKundfordringar.previousYear': id(
    'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.ForandringKassaflodeRorelsekapitaletAbstract.ForandringKassaflodeRorelsefodringarAbstract.ForandringKassaflodeKundfordringar.previousYear'
  ),
  'cashFlowStatement.ForandringKassaflodeOvrigaKortfristigaFordringar.year': id(
    'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.ForandringKassaflodeRorelsekapitaletAbstract.ForandringKassaflodeRorelsefodringarAbstract.ForandringKassaflodeOvrigaKortfristigaFordringar.year'
  ),
  'cashFlowStatement.ForandringKassaflodeOvrigaKortfristigaFordringar.previousYear':
    id(
      'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.ForandringKassaflodeRorelsekapitaletAbstract.ForandringKassaflodeRorelsefodringarAbstract.ForandringKassaflodeOvrigaKortfristigaFordringar.previousYear'
    ),
  'cashFlowStatement.ForandringKassaflodeRorelsefodringar.year': id(
    'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.ForandringKassaflodeRorelsekapitaletAbstract.ForandringKassaflodeRorelsefodringar.year'
  ),
  'cashFlowStatement.ForandringKassaflodeRorelsefodringar.previousYear': id(
    'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.ForandringKassaflodeRorelsekapitaletAbstract.ForandringKassaflodeRorelsefodringar.previousYear'
  ),
  'cashFlowStatement.ForandringKassaflodeLeverantorsskulder.year': id(
    'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.ForandringKassaflodeRorelsekapitaletAbstract.ForandringKassaflodeRorelseskulderAbstract.ForandringKassaflodeLeverantorsskulder.year'
  ),
  'cashFlowStatement.ForandringKassaflodeLeverantorsskulder.previousYear': id(
    'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.ForandringKassaflodeRorelsekapitaletAbstract.ForandringKassaflodeRorelseskulderAbstract.ForandringKassaflodeLeverantorsskulder.previousYear'
  ),
  'cashFlowStatement.ForandringKassaflodeOvrigaKortfristigaSkulder.year': id(
    'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.ForandringKassaflodeRorelsekapitaletAbstract.ForandringKassaflodeRorelseskulderAbstract.ForandringKassaflodeOvrigaKortfristigaSkulder.year'
  ),
  'cashFlowStatement.ForandringKassaflodeOvrigaKortfristigaSkulder.previousYear':
    id(
      'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.ForandringKassaflodeRorelsekapitaletAbstract.ForandringKassaflodeRorelseskulderAbstract.ForandringKassaflodeOvrigaKortfristigaSkulder.previousYear'
    ),
  'cashFlowStatement.ForandringKassaflodeRorelseskulder.year': id(
    'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.ForandringKassaflodeRorelsekapitaletAbstract.ForandringKassaflodeRorelseskulder.year'
  ),
  'cashFlowStatement.ForandringKassaflodeRorelseskulder.previousYear': id(
    'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.ForandringKassaflodeRorelsekapitaletAbstract.ForandringKassaflodeRorelseskulder.previousYear'
  ),
  'cashFlowStatement.KassaflodeLopandeVerksamheten.year': id(
    'cashFlowStatement.section.table.KassaflodeLopandeVerksamheten.year'
  ),
  'cashFlowStatement.KassaflodeLopandeVerksamheten.previousYear': id(
    'cashFlowStatement.section.table.KassaflodeLopandeVerksamheten.previousYear'
  ),
  'cashFlowStatement.LamnadeKapitaltillskottInvesteringsverksamheten.year': id(
    'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.LamnadeKapitaltillskottInvesteringsverksamheten.year'
  ),
  'cashFlowStatement.LamnadeKapitaltillskottInvesteringsverksamheten.previousYear':
    id(
      'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.LamnadeKapitaltillskottInvesteringsverksamheten.previousYear'
    ),
  'cashFlowStatement.LamnadeKoncernbidragInvesteringsverksamheten.year': id(
    'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.LamnadeKoncernbidragInvesteringsverksamheten.year'
  ),
  'cashFlowStatement.LamnadeKoncernbidragInvesteringsverksamheten.previousYear':
    id(
      'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.LamnadeKoncernbidragInvesteringsverksamheten.previousYear'
    ),
  'cashFlowStatement.InvesteringImmateriellaAnlaggningstillgangar.year': id(
    'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.InvesteringImmateriellaAnlaggningstillgangar.year'
  ),
  'cashFlowStatement.InvesteringImmateriellaAnlaggningstillgangar.previousYear':
    id(
      'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.InvesteringImmateriellaAnlaggningstillgangar.previousYear'
    ),
  'cashFlowStatement.ForsaljningImmateriellaAnlaggningstillgangar.year': id(
    'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForsaljningImmateriellaAnlaggningstillgangar.year'
  ),
  'cashFlowStatement.ForsaljningImmateriellaAnlaggningstillgangar.previousYear':
    id(
      'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForsaljningImmateriellaAnlaggningstillgangar.previousYear'
    ),
  'cashFlowStatement.ForvarvForvaltningsfastigheter.year': id(
    'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForvarvMateriellaAnlaggningstillgangarAbstract.ForvarvForvaltningsfastigheter.year'
  ),
  'cashFlowStatement.ForvarvForvaltningsfastigheter.previousYear': id(
    'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForvarvMateriellaAnlaggningstillgangarAbstract.ForvarvForvaltningsfastigheter.previousYear'
  ),
  'cashFlowStatement.ForvarvOvrigaMateriellaAnlaggningstillgangar.year': id(
    'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForvarvMateriellaAnlaggningstillgangarAbstract.ForvarvOvrigaMateriellaAnlaggningstillgangar.year'
  ),
  'cashFlowStatement.ForvarvOvrigaMateriellaAnlaggningstillgangar.previousYear':
    id(
      'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForvarvMateriellaAnlaggningstillgangarAbstract.ForvarvOvrigaMateriellaAnlaggningstillgangar.previousYear'
    ),
  'cashFlowStatement.InvesteringMateriellaAnlaggningstillgangar.year': id(
    'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.InvesteringMateriellaAnlaggningstillgangar.year'
  ),
  'cashFlowStatement.InvesteringMateriellaAnlaggningstillgangar.previousYear':
    id(
      'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.InvesteringMateriellaAnlaggningstillgangar.previousYear'
    ),
  'cashFlowStatement.ForsaljningForvaltningsfastigheter.year': id(
    'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForsaljningMateriellaAnlaggningstillgangarAbstract.ForsaljningForvaltningsfastigheter.year'
  ),
  'cashFlowStatement.ForsaljningForvaltningsfastigheter.previousYear': id(
    'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForsaljningMateriellaAnlaggningstillgangarAbstract.ForsaljningForvaltningsfastigheter.previousYear'
  ),
  'cashFlowStatement.ForsaljningOvrigaMateriellaAnlaggningstillgangar.year': id(
    'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForsaljningMateriellaAnlaggningstillgangarAbstract.ForsaljningOvrigaMateriellaAnlaggningstillgangar.year'
  ),
  'cashFlowStatement.ForsaljningOvrigaMateriellaAnlaggningstillgangar.previousYear':
    id(
      'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForsaljningMateriellaAnlaggningstillgangarAbstract.ForsaljningOvrigaMateriellaAnlaggningstillgangar.previousYear'
    ),
  'cashFlowStatement.ForsaljningMateriellaAnlaggningstillgangar.year': id(
    'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForsaljningMateriellaAnlaggningstillgangar.year'
  ),
  'cashFlowStatement.ForsaljningMateriellaAnlaggningstillgangar.previousYear':
    id(
      'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForsaljningMateriellaAnlaggningstillgangar.previousYear'
    ),
  'cashFlowStatement.ForvarvRorelseInkram.year': id(
    'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForvarvRorelseInkram.year'
  ),
  'cashFlowStatement.ForvarvRorelseInkram.previousYear': id(
    'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForvarvRorelseInkram.previousYear'
  ),
  'cashFlowStatement.ForsaljningRorelseInkram.year': id(
    'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForsaljningRorelseInkram.year'
  ),
  'cashFlowStatement.ForsaljningRorelseInkram.previousYear': id(
    'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForsaljningRorelseInkram.previousYear'
  ),
  'cashFlowStatement.ForvarvAndelarKoncernforetag.year': id(
    'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForvarvFinansiellaAnlaggningstillgangarAbstract.ForvarvAndelarKoncernforetag.year'
  ),
  'cashFlowStatement.ForvarvAndelarKoncernforetag.previousYear': id(
    'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForvarvFinansiellaAnlaggningstillgangarAbstract.ForvarvAndelarKoncernforetag.previousYear'
  ),
  'cashFlowStatement.ForvarvIntresseforetagGemensamtStyrdaForetag.year': id(
    'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForvarvFinansiellaAnlaggningstillgangarAbstract.ForvarvIntresseforetagGemensamtStyrdaForetag.year'
  ),
  'cashFlowStatement.ForvarvIntresseforetagGemensamtStyrdaForetag.previousYear':
    id(
      'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForvarvFinansiellaAnlaggningstillgangarAbstract.ForvarvIntresseforetagGemensamtStyrdaForetag.previousYear'
    ),
  'cashFlowStatement.ForvarvAgarintressenOvrigaForetag.year': id(
    'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForvarvFinansiellaAnlaggningstillgangarAbstract.ForvarvAgarintressenOvrigaForetag.year'
  ),
  'cashFlowStatement.ForvarvAgarintressenOvrigaForetag.previousYear': id(
    'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForvarvFinansiellaAnlaggningstillgangarAbstract.ForvarvAgarintressenOvrigaForetag.previousYear'
  ),
  'cashFlowStatement.LamnadeLanKoncernforetag.year': id(
    'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForvarvFinansiellaAnlaggningstillgangarAbstract.LamnadeLanKoncernforetag.year'
  ),
  'cashFlowStatement.LamnadeLanKoncernforetag.previousYear': id(
    'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForvarvFinansiellaAnlaggningstillgangarAbstract.LamnadeLanKoncernforetag.previousYear'
  ),
  'cashFlowStatement.ForvarvOvrigaFinansiellaAnlaggningstillgangar.year': id(
    'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForvarvFinansiellaAnlaggningstillgangarAbstract.ForvarvOvrigaFinansiellaAnlaggningstillgangar.year'
  ),
  'cashFlowStatement.ForvarvOvrigaFinansiellaAnlaggningstillgangar.previousYear':
    id(
      'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForvarvFinansiellaAnlaggningstillgangarAbstract.ForvarvOvrigaFinansiellaAnlaggningstillgangar.previousYear'
    ),
  'cashFlowStatement.InvesteringFinansiellaAnlaggningstillgangar.year': id(
    'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.InvesteringFinansiellaAnlaggningstillgangar.year'
  ),
  'cashFlowStatement.InvesteringFinansiellaAnlaggningstillgangar.previousYear':
    id(
      'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.InvesteringFinansiellaAnlaggningstillgangar.previousYear'
    ),
  'cashFlowStatement.ForsaljningKoncernforetag.year': id(
    'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForsaljningAmorteringFinansiellaAnlaggningstillgangarAbstract.ForsaljningKoncernforetag.year'
  ),
  'cashFlowStatement.ForsaljningKoncernforetag.previousYear': id(
    'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForsaljningAmorteringFinansiellaAnlaggningstillgangarAbstract.ForsaljningKoncernforetag.previousYear'
  ),
  'cashFlowStatement.ForsaljningIntresseforetagGemensamtStyrdaForetag.year': id(
    'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForsaljningAmorteringFinansiellaAnlaggningstillgangarAbstract.ForsaljningIntresseforetagGemensamtStyrdaForetag.year'
  ),
  'cashFlowStatement.ForsaljningIntresseforetagGemensamtStyrdaForetag.previousYear':
    id(
      'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForsaljningAmorteringFinansiellaAnlaggningstillgangarAbstract.ForsaljningIntresseforetagGemensamtStyrdaForetag.previousYear'
    ),
  'cashFlowStatement.ForsaljningAgarintressenOvrigaForetag.year': id(
    'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForsaljningAmorteringFinansiellaAnlaggningstillgangarAbstract.ForsaljningAgarintressenOvrigaForetag.year'
  ),
  'cashFlowStatement.ForsaljningAgarintressenOvrigaForetag.previousYear': id(
    'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForsaljningAmorteringFinansiellaAnlaggningstillgangarAbstract.ForsaljningAgarintressenOvrigaForetag.previousYear'
  ),
  'cashFlowStatement.ErhallnaAmorteringarKoncernforetag.year': id(
    'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForsaljningAmorteringFinansiellaAnlaggningstillgangarAbstract.ErhallnaAmorteringarKoncernforetag.year'
  ),
  'cashFlowStatement.ErhallnaAmorteringarKoncernforetag.previousYear': id(
    'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForsaljningAmorteringFinansiellaAnlaggningstillgangarAbstract.ErhallnaAmorteringarKoncernforetag.previousYear'
  ),
  'cashFlowStatement.ForsaljningOvrigaFinansiellaAnlaggningstillgangar.year':
    id(
      'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForsaljningAmorteringFinansiellaAnlaggningstillgangarAbstract.ForsaljningOvrigaFinansiellaAnlaggningstillgangar.year'
    ),
  'cashFlowStatement.ForsaljningOvrigaFinansiellaAnlaggningstillgangar.previousYear':
    id(
      'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForsaljningAmorteringFinansiellaAnlaggningstillgangarAbstract.ForsaljningOvrigaFinansiellaAnlaggningstillgangar.previousYear'
    ),
  'cashFlowStatement.ForsaljningFinansiellaAnlaggningstillgangar.year': id(
    'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForsaljningFinansiellaAnlaggningstillgangar.year'
  ),
  'cashFlowStatement.ForsaljningFinansiellaAnlaggningstillgangar.previousYear':
    id(
      'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForsaljningFinansiellaAnlaggningstillgangar.previousYear'
    ),
  'cashFlowStatement.ForandringKassaflodeOvrigaKortfristigaPlaceringar.year':
    id(
      'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForandringKassaflodeOvrigaKortfristigaPlaceringar.year'
    ),
  'cashFlowStatement.ForandringKassaflodeOvrigaKortfristigaPlaceringar.previousYear':
    id(
      'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForandringKassaflodeOvrigaKortfristigaPlaceringar.previousYear'
    ),
  'cashFlowStatement.KassaflodeInvesteringsverksamheten.year': id(
    'cashFlowStatement.section.table.KassaflodeInvesteringsverksamheten.year'
  ),
  'cashFlowStatement.KassaflodeInvesteringsverksamheten.previousYear': id(
    'cashFlowStatement.section.table.KassaflodeInvesteringsverksamheten.previousYear'
  ),
  'cashFlowStatement.UtstalldaAktieoptioner.year': id(
    'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.UtstalldaAktieoptioner.year'
  ),
  'cashFlowStatement.UtstalldaAktieoptioner.previousYear': id(
    'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.UtstalldaAktieoptioner.previousYear'
  ),
  'cashFlowStatement.InlostaAktieoptioner.year': id(
    'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.InlostaAktieoptioner.year'
  ),
  'cashFlowStatement.InlostaAktieoptioner.previousYear': id(
    'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.InlostaAktieoptioner.previousYear'
  ),
  'cashFlowStatement.NyemissionKassaflode.year': id(
    'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.NyemissionKassaflode.year'
  ),
  'cashFlowStatement.NyemissionKassaflode.previousYear': id(
    'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.NyemissionKassaflode.previousYear'
  ),
  'cashFlowStatement.Emissionsutgifter.year': id(
    'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.Emissionsutgifter.year'
  ),
  'cashFlowStatement.Emissionsutgifter.previousYear': id(
    'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.Emissionsutgifter.previousYear'
  ),
  'cashFlowStatement.AterkopEgnaAktier.year': id(
    'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.AterkopEgnaAktier.year'
  ),
  'cashFlowStatement.AterkopEgnaAktier.previousYear': id(
    'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.AterkopEgnaAktier.previousYear'
  ),
  'cashFlowStatement.OverlatelseEgnaAktier.year': id(
    'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.OverlatelseEgnaAktier.year'
  ),
  'cashFlowStatement.OverlatelseEgnaAktier.previousYear': id(
    'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.OverlatelseEgnaAktier.previousYear'
  ),
  'cashFlowStatement.ForandringCheckrakningskredit.year': id(
    'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.ForandringCheckrakningskredit.year'
  ),
  'cashFlowStatement.ForandringCheckrakningskredit.previousYear': id(
    'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.ForandringCheckrakningskredit.previousYear'
  ),
  'cashFlowStatement.ErhallnaAktieagartillskottKassaflode.year': id(
    'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.ErhallnaAktieagartillskottKassaflode.year'
  ),
  'cashFlowStatement.ErhallnaAktieagartillskottKassaflode.previousYear': id(
    'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.ErhallnaAktieagartillskottKassaflode.previousYear'
  ),
  'cashFlowStatement.ForandringKassaflodeErhallnaKoncernbidragFinansieringsverksamheten.year':
    id(
      'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.ForandringKassaflodeErhallnaKoncernbidragFinansieringsverksamheten.year'
    ),
  'cashFlowStatement.ForandringKassaflodeErhallnaKoncernbidragFinansieringsverksamheten.previousYear':
    id(
      'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.ForandringKassaflodeErhallnaKoncernbidragFinansieringsverksamheten.previousYear'
    ),
  'cashFlowStatement.EmissionKonvertibellan.year': id(
    'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.EmissionKonvertibellan.year'
  ),
  'cashFlowStatement.EmissionKonvertibellan.previousYear': id(
    'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.EmissionKonvertibellan.previousYear'
  ),
  'cashFlowStatement.EmissionInlosningsbaraPreferensaktier.year': id(
    'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.EmissionInlosningsbaraPreferensaktier.year'
  ),
  'cashFlowStatement.EmissionInlosningsbaraPreferensaktier.previousYear': id(
    'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.EmissionInlosningsbaraPreferensaktier.previousYear'
  ),
  'cashFlowStatement.ForandringKassaflodeLamnadeKoncernbidragFinansieringsverksamheten.year':
    id(
      'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.ForandringKassaflodeLamnadeKoncernbidragFinansieringsverksamheten.year'
    ),
  'cashFlowStatement.ForandringKassaflodeLamnadeKoncernbidragFinansieringsverksamheten.previousYear':
    id(
      'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.ForandringKassaflodeLamnadeKoncernbidragFinansieringsverksamheten.previousYear'
    ),
  'cashFlowStatement.UpptagnaLan.year': id(
    'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.UpptagnaLan.year'
  ),
  'cashFlowStatement.UpptagnaLan.previousYear': id(
    'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.UpptagnaLan.previousYear'
  ),
  'cashFlowStatement.AmorteringSkuld.year': id(
    'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.AmorteringSkuld.year'
  ),
  'cashFlowStatement.AmorteringSkuld.previousYear': id(
    'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.AmorteringSkuld.previousYear'
  ),
  'cashFlowStatement.AmorteringLeasingskuld.year': id(
    'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.AmorteringLeasingskuld.year'
  ),
  'cashFlowStatement.AmorteringLeasingskuld.previousYear': id(
    'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.AmorteringLeasingskuld.previousYear'
  ),
  'cashFlowStatement.GottgorelsePensionsstiftelse.year': id(
    'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.GottgorelsePensionsstiftelse.year'
  ),
  'cashFlowStatement.GottgorelsePensionsstiftelse.previousYear': id(
    'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.GottgorelsePensionsstiftelse.previousYear'
  ),
  'cashFlowStatement.AmortoringPensionsskuld.year': id(
    'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.AmortoringPensionsskuld.year'
  ),
  'cashFlowStatement.AmortoringPensionsskuld.previousYear': id(
    'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.AmortoringPensionsskuld.previousYear'
  ),
  'cashFlowStatement.KortfristigaFinansiellaSkulder.year': id(
    'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.KortfristigaFinansiellaSkulder.year'
  ),
  'cashFlowStatement.KortfristigaFinansiellaSkulder.previousYear': id(
    'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.KortfristigaFinansiellaSkulder.previousYear'
  ),
  'cashFlowStatement.UtbetaldUtdelningKassaflode.year': id(
    'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.UtbetaldUtdelningKassaflode.year'
  ),
  'cashFlowStatement.UtbetaldUtdelningKassaflode.previousYear': id(
    'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.UtbetaldUtdelningKassaflode.previousYear'
  ),
  'cashFlowStatement.KassaflodeFinansieringsverksamheten.year': id(
    'cashFlowStatement.section.table.KassaflodeFinansieringsverksamheten.year'
  ),
  'cashFlowStatement.KassaflodeFinansieringsverksamheten.previousYear': id(
    'cashFlowStatement.section.table.KassaflodeFinansieringsverksamheten.previousYear'
  ),
  'cashFlowStatement.AretsKassaflode.year': id(
    'cashFlowStatement.section.table.AretsKassaflode.year'
  ),
  'cashFlowStatement.AretsKassaflode.previousYear': id(
    'cashFlowStatement.section.table.AretsKassaflode.previousYear'
  ),
  'cashFlowStatement.LikvidaMedelBorjan.year': id(
    'cashFlowStatement.section.table.LikvidaMedelBorjan.year'
  ),
  'cashFlowStatement.LikvidaMedelBorjan.previousYear': id(
    'cashFlowStatement.section.table.LikvidaMedelBorjan.previousYear'
  ),
  'cashFlowStatement.LikvidaMedelKursdifferens.year': id(
    'cashFlowStatement.section.table.ForandringLikvidaMeldelAbstract.LikvidaMedelKursdifferens.year'
  ),
  'cashFlowStatement.LikvidaMedelKursdifferens.previousYear': id(
    'cashFlowStatement.section.table.ForandringLikvidaMeldelAbstract.LikvidaMedelKursdifferens.previousYear'
  ),
  'cashFlowStatement.LikvidaMedelSlut.year': id(
    'cashFlowStatement.section.table.LikvidaMedelSlut.year'
  ),
  'cashFlowStatement.LikvidaMedelSlut.previousYear': id(
    'cashFlowStatement.section.table.LikvidaMedelSlut.previousYear'
  ),
});

import {
  TaxDeclarationFormDefinition,
  TaxDeclarationFormDataConfig,
  PersonMetaData,
} from '../../types';
import { ref, value, id } from '@agoy/document';

import { mapFormId, toInitial, toStructure } from '../forms-util';

const configK2 = (
  form: TaxDeclarationFormDefinition,
  person: PersonMetaData,
  financialYearEnd: string
): TaxDeclarationFormDataConfig => {
  const isYear2022OrHigher = (financialYearEnd: string): boolean => {
    const year = parseInt(financialYearEnd.substring(0, 4), 10);
    return year >= 2022;
  };

  const is2022OrHigher = isYear2022OrHigher(financialYearEnd);

  const name = `${person?.firstName || ''} ${person?.lastName || ''}`;
  const K2 = mapFormId(form, 'K2');
  const initial = {
    K2: {
      partType: 'form' as const,
      id: K2,
      fields: {
        datum: value(''),
        name: value(name),
        personnummer: value(person.personNumber || ''),
        A_fastighetsbeteckning_eller_namn: value(''),
        A_bostdsrattsföreningens_organisationsnummer: value(''),
        A_lagenhetsbeteckning: value(''),
        B_fastighetsbeteckning: value(''),
        B_inkopsdatum_enligt_kopekontrakt: value(''),
        B_datum_bosattning: value(''),
        B_1_inkopspris_lagfartskostnad: value(undefined),
        B_1_andel_i_procent: value(undefined),
        B_2_utgift_för_ny_eller_ombyggnad: value(undefined),
        B_3_totalt_inkopspris: value(undefined),
        B_4_andel_i_procent: value(undefined),
        B_4_andel_i_kronor: value(undefined),
        C_bostadsrattsforeningens_namn: value(''),
        C_organisationsnummer: value(''),
        C_lagenhetsbeteckning_hos_bostadsrattsforeningen: value(''),
        C_inkopsdatum_enligt_kopekontrakt: value(''),
        C_datum_for_bosattning: value(''),
        C_1_inkopspris_minus_inre_reparationsfond: value(undefined),
        C_1_andel_procent: value(undefined),
        C_2_andel_procent: value(undefined),
        C_2_andel_kronor: value(undefined),
        D_utlandska_boendeformen_namn: value(''),
        D_landskod: value(''),
        D_gatuadress: value(''),
        D_postadress: value(''),
        E_vinst: value(undefined),
        E_inkopspris: value(undefined),
        E_forsaljningspris: value(undefined),
        E_vinst_x_inkopspris_forsaljningspris: value(undefined),
        F_preliminart_uppskovsbelopp: value(undefined),
        G_1_preliminart_uppskovsbelopp: value(undefined),
        G_2_slutligt_uppskovsbelopp: value(undefined),
        G_3_uppskovsbelopp_som_ska_aterforas_till_beskattning: value(undefined),
        H_fastighetsbeteckning_eller_namn: value(''),
        H_organisationsnummer: value(''),
        H_lagenhetsbeteckning: value(''),
        H_1_kvarvarande_uppskovsbelopp: value(undefined),
        H_2_uppskovsbelopp_till_beskattning: value(undefined),
        H_3_aterstaende_uppskovsbelopp: value(undefined),
        I_fastighetsbeteckning_eller_namn: value(''),
        I_organisationsnummer: value(''),
        I_lagenhetsbeteckning: value(''),
        I_kvarvarande_uppskovsbelopp: value(undefined),
        I_1_ny_agares_personnummer: value(''),
        I_1_namn_adress: value(''),
        I_1_andel_uppskovsbeloppet: value(undefined),
        I_2_ny_agares_personnummer: value(''),
        I_2_namn_adress: value(''),
        I_2_andel_uppskovsbeloppet: value(undefined),
        I_3_ny_agares_personnummer: value(''),
        I_3_namn_adress: value(''),
        I_3_andel_uppskovsbeloppet: value(undefined),
        I_4_ny_agares_personnummer: value(''),
        I_4_namn_adress: value(''),
        I_4_andel_uppskovsbeloppet: value(undefined),
        J_agt_bostaden_med_samma_agaforhallanden_som_2020: value(false),
        J_agaforhallanderna_andrats_sedan_2020: value(false),
        J_uppskosbeloppet_overfors_en_flera_agare: value(false),
      },
      sru: {
        '3800': ref(id('K2.fields.A_fastighetsbeteckning_eller_namn')),
        '3925': ref(
          id('K2.fields.A_bostdsrattsföreningens_organisationsnummer')
        ),
        '8911': ref(id('K2.fields.A_lagenhetsbeteckning')),
        '3840': ref(id('K2.fields.B_fastighetsbeteckning')),
        '3841': ref(id('K2.fields.B_inkopsdatum_enligt_kopekontrakt')),
        '3842': ref(id('K2.fields.B_datum_bosattning')),
        '3843': ref(id('K2.fields.B_1_inkopspris_lagfartskostnad')),
        '3844': ref(id('K2.fields.B_2_utgift_för_ny_eller_ombyggnad')),

        ...(is2022OrHigher
          ? undefined
          : { '3845': ref(id('K2.fields.B_3_totalt_inkopspris')) }),

        ...(is2022OrHigher
          ? { '3846': ref(id('K2.fields.B_1_andel_i_procent')) }
          : { '3846': ref(id('K2.fields.B_4_andel_i_procent')) }),

        ...(is2022OrHigher
          ? { '3847': ref(id('K2.fields.B_3_totalt_inkopspris')) }
          : { '3847': ref(id('K2.fields.B_4_andel_i_kronor')) }),

        '3850': ref(id('K2.fields.C_bostadsrattsforeningens_namn')),
        '3851': ref(id('K2.fields.C_organisationsnummer')),
        '3852': ref(
          id('K2.fields.C_lagenhetsbeteckning_hos_bostadsrattsforeningen')
        ),
        '3853': ref(id('K2.fields.C_inkopsdatum_enligt_kopekontrakt')),
        '3854': ref(id('K2.fields.C_datum_for_bosattning')),

        ...(is2022OrHigher
          ? undefined
          : {
              '3855': ref(
                id('K2.fields.C_1_inkopspris_minus_inre_reparationsfond')
              ),
            }),

        ...(is2022OrHigher
          ? { '3856': ref(id('K2.fields.C_1_andel_procent')) }
          : { '3856': ref(id('K2.fields.C_2_andel_procent')) }),

        ...(is2022OrHigher
          ? {
              '3857': ref(
                id('K2.fields.C_1_inkopspris_minus_inre_reparationsfond')
              ),
            }
          : { '3857': ref(id('K2.fields.C_2_andel_kronor')) }),

        '3862': ref(id('K2.fields.D_utlandska_boendeformen_namn')),
        '3863': ref(id('K2.fields.D_landskod')),
        '3864': ref(id('K2.fields.D_gatuadress')),
        '3858': ref(id('K2.fields.D_postadress')),
        '3696': ref(id('K2.fields.E_vinst_x_inkopspris_forsaljningspris')),
        '3911': ref(id('K2.fields.F_preliminart_uppskovsbelopp')),
        '3905': ref(id('K2.fields.G_1_preliminart_uppskovsbelopp')),
        '3912': ref(id('K2.fields.G_2_slutligt_uppskovsbelopp')),
        '3910': ref(
          id('K2.fields.G_3_uppskovsbelopp_som_ska_aterforas_till_beskattning')
        ),
        '3915': ref(id('K2.fields.H_fastighetsbeteckning_eller_namn')),
        '3914': ref(id('K2.fields.H_organisationsnummer')),
        '3926': ref(id('K2.fields.H_lagenhetsbeteckning')),
        '3916': ref(id('K2.fields.H_1_kvarvarande_uppskovsbelopp')),
        '3917': ref(id('K2.fields.H_2_uppskovsbelopp_till_beskattning')),
        '3918': ref(id('K2.fields.H_3_aterstaende_uppskovsbelopp')),
        '3930': ref(id('K2.fields.I_fastighetsbeteckning_eller_namn')),
        '3929': ref(id('K2.fields.I_organisationsnummer')),
        '3927': ref(id('K2.fields.I_lagenhetsbeteckning')),
        '3931': ref(id('K2.fields.I_kvarvarande_uppskovsbelopp')),
        '3932': ref(id('K2.fields.I_1_ny_agares_personnummer')),
        '3933': ref(id('K2.fields.I_1_namn_adress')),
        '3934': ref(id('K2.fields.I_1_andel_uppskovsbeloppet')),
        '3935': ref(id('K2.fields.I_2_ny_agares_personnummer')),
        '3936': ref(id('K2.fields.I_2_namn_adress')),
        '3937': ref(id('K2.fields.I_2_andel_uppskovsbeloppet')),
        '3938': ref(id('K2.fields.I_3_ny_agares_personnummer')),
        '3939': ref(id('K2.fields.I_3_namn_adress')),
        '3940': ref(id('K2.fields.I_3_andel_uppskovsbeloppet')),
        '3941': ref(id('K2.fields.I_4_ny_agares_personnummer')),
        '3942': ref(id('K2.fields.I_4_namn_adress')),
        '3943': ref(id('K2.fields.I_4_andel_uppskovsbeloppet')),
        '3944': ref(
          id('K2.fields.J_agt_bostaden_med_samma_agaforhallanden_som_2020')
        ),
        '3945': ref(id('K2.fields.J_agaforhallanderna_andrats_sedan_2020')),
        '3946': ref(id('K2.fields.J_uppskosbeloppet_overfors_en_flera_agare')),
      },
    },
  };
  return {
    initial: toInitial(initial, form),
    definition: toStructure(initial, form),
  };
};

export default configK2;

import { ccyFormat } from '@agoy/common';
import { AccountingBalances } from 'types/Accounting';
import OverviewTable from 'types/OverviewTable/types';

const CSV_SEPARATOR = ';';

/**
 * Generate string containing a CSV line, with a parametrised separator and line end
 * The row is a string array, containg the content of the cells.
 */
export const generateCsvRow = (
  row: string[],
  separator = CSV_SEPARATOR
): string => {
  if (row.length === 0) return '';

  return `${row.join(separator)}${separator}\n`;
};

/**
 * Convert numbers to strings, before contructing the CSV.
 */
export const stringifyNumber = (number: number): string => {
  return number.toFixed(2).toString().replace('.', ',');
};

/**
 * Format cell content before constructing the CSV. Required for percentages.
 */
export const formatCellValue = (
  value: number | undefined,
  type: OverviewTable.SummaryValueType = undefined
): string => {
  if (value === undefined) {
    return '';
  }
  if (type === '%') {
    return Number.isNaN(value) ? 'NaN' : `${ccyFormat(value * 100, 1)}%`;
  }
  return stringifyNumber(value);
};

// ACCOUNT GROUPS

/**
 * Generate account groups, ex. EGET KAPITAL OCH SKULDER
 */
export const generateAccountGroup = ({
  name,
  subGroups,
  periods,
}: OverviewTable.AccountGroup & Pick<AccountingBalances, 'periods'>):
  | string[]
  | null => {
  if (
    name &&
    !subGroups.every((subGroup) =>
      subGroup.subTabs.every((tab) => tab.accounts.length === 0)
    )
  ) {
    return [name, '', '', '', ...periods.map(() => '')];
  }

  return null;
};

// ACCOUNT GROUP SUMMARY

/**
 * Ex. SUMMA TILLGÅNGAR
 */
export const generateGroupSummary = ({
  summary,
}: Pick<OverviewTable.AccountGroup, 'summary'>): string[] | null => {
  if (!summary) {
    return null;
  }

  const summaryPeriods: string[] = summary.periods
    .map((p) => [
      formatCellValue(p.change, summary.type),
      formatCellValue(p.ub, summary.type),
    ])
    .flat();

  const yearIb = formatCellValue(summary.previousYearUb, summary.type);
  const yearUb = formatCellValue(summary.yearUb, summary.type);
  const yearChange = formatCellValue(summary.yearChange, summary.type);

  return ['', summary.title, yearIb, yearChange, yearUb, ...summaryPeriods];
};

// SUBGROUPS

const generateSubGroupTitle = ({ subGroup, periods }): string[] => {
  if (subGroup.title) {
    return ['', subGroup.title, '', '', '', ...periods.map(() => '')];
  }

  return [];
};

/**
 * Ex. Summa anläggningstillgångar
 */
const generateSubGroupSummary = ({
  subGroupSummary,
}: {
  subGroupSummary: OverviewTable.AccountGroupSummary | undefined;
}): string[] | null => {
  const generatedSubGroupSummary: string[] = [];

  if (!subGroupSummary) {
    return null;
  }

  const subGroupSummaryType = subGroupSummary.type;

  generatedSubGroupSummary.push(
    '',
    subGroupSummary.title,
    formatCellValue(subGroupSummary.previousYearUb, subGroupSummaryType),
    formatCellValue(subGroupSummary.yearChange, subGroupSummaryType),
    formatCellValue(subGroupSummary.yearUb, subGroupSummaryType)
  );

  const periodValues: string[] = subGroupSummary.periods
    .map((p) => [
      formatCellValue(p.change, subGroupSummaryType),
      formatCellValue(p.ub, subGroupSummaryType),
    ])
    .flat();

  generatedSubGroupSummary.push(...periodValues);

  return generatedSubGroupSummary;
};

/**
 * Generate subgroups, ex. Anläggningstillgångar
 */
export const generateCsvSubGroups = ({
  subGroups,
  periods,
}: Pick<OverviewTable.AccountGroup, 'subGroups'> &
  Pick<AccountingBalances, 'periods'>): string[][] => {
  const generatedSubGroups: string[][] = [];

  subGroups.forEach((subGroup) => {
    const subGroupTitle = generateSubGroupTitle({ subGroup, periods });

    generatedSubGroups.push(subGroupTitle);

    // generate subgroup - subtabs, ex. Immateriella anläggningstillgångar
    subGroup.subTabs
      .filter((subtab) => subtab.accounts.length)
      .forEach((subtab) => {
        if (subtab.title) {
          generatedSubGroups.push([
            '',
            subtab.title,
            '',
            '',
            '',
            ...periods.map(() => ''),
          ]);
        }

        // generate account rows, ex. 1012
        subtab.accounts.forEach((row) => {
          const accountRow: string[] = [];

          const accountNumber = row.account;
          const accountTitle = row.accountName.replace(/;/g, ',');
          const previousYearUb = formatCellValue(row.previousYearUb);
          const yearUb = formatCellValue(row.yearUb);
          const yearChange = formatCellValue(row.yearChange);

          // account headers
          accountRow.push(
            accountNumber,
            accountTitle,
            previousYearUb,
            yearChange,
            yearUb
          );

          // account periods
          row.periods.forEach((period) => {
            accountRow.push(formatCellValue(period.change));
            accountRow.push(formatCellValue(period.ub));
          });

          generatedSubGroups.push(accountRow);
        });

        // generate subtab summary
        if (subtab.summary) {
          const subtabSummary: string[] = [];

          subtabSummary.push(
            '',
            subtab.summary.title,
            formatCellValue(subtab.summary.previousYearUb, subtab.summary.type),
            formatCellValue(subtab.summary.yearChange, subtab.summary.type),
            formatCellValue(subtab.summary.yearUb, subtab.summary.type)
          );

          const periodData = subtab.summary.periods
            .map((p) => [
              formatCellValue(p.change, subtab.summary?.type),
              formatCellValue(p.ub, subtab.summary?.type),
            ])
            .flat();

          subtabSummary.push(...periodData);

          generatedSubGroups.push(subtabSummary);
        }
      });

    const subGroupSummary = generateSubGroupSummary({
      subGroupSummary: subGroup.summary,
    });
    if (subGroupSummary) {
      generatedSubGroups.push(subGroupSummary);
    }
  });

  return generatedSubGroups;
};

import React, { useCallback } from 'react';
import { AgoyTableRow, booleanValue, stringValue } from '@agoy/document';
import {
  Checkbox,
  IconButton,
  Typography,
  TextField as MuiTextField,
} from '@material-ui/core';
import styled from '@emotion/styled';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import CancelIcon from '@material-ui/icons/Cancel';
import { red, gray } from '@agoy/theme/src/colors';
import EditField from '_shared/components/Inputs/EditField';

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 512px;
  padding: 2px 0px 2px 0px;
  border-radius: 4px;
  gap: ${({ theme }) => theme.spacing(2)}px;

  &:hover {
    background: ${gray.HOVER};

    svg {
      opacity: 1;
    }
  }
`;

const StartRow = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
  flex: 1;
`;

const EndRow = styled.div`
  align-items: center;
`;

const DragIcon = styled(DragIndicatorIcon)`
  opacity: 0;
`;

const DeleteIcon = styled(IconButton)`
  padding: 9px;
  color: ${red.HIGH};
`;

const TextField = styled(({ disableUnderline, ...rest }) => (
  <MuiTextField size="small" variant="outlined" fullWidth {...rest} />
))`
  .MuiInputBase-root {
    font-size: inherit;
    width: 100%;
    background: #f2f4ff;
    border-radius: 2px;

    fieldset {
      border: none;
    }
  }
`;

interface Props {
  row: AgoyTableRow;
  onChecked?: (value?: boolean) => void;
  onNameChanged?: (_value?: string) => void;
  onDelete?: () => void;
}

const EditTableModalRow = ({
  row,
  onChecked = (_value?: boolean) => {},
  onNameChanged = (_value?: string) => {},
  onDelete = () => {},
}: Props) => {
  const { cells } = row;
  const name = stringValue(cells?.name);
  const isDefault = booleanValue(cells?.default);

  const renderCheckboxOrDelete = useCallback(() => {
    return isDefault ? (
      <Checkbox
        color="primary"
        checked={Boolean(row.active)}
        onChange={(_, value) => onChecked(value)}
      />
    ) : (
      <DeleteIcon color="secondary" onClick={() => onDelete()}>
        <CancelIcon />
      </DeleteIcon>
    );
  }, [isDefault, onChecked, onDelete, row.active]);

  const renderLabelOrInput = useCallback(() => {
    return isDefault ? (
      <Typography color="primary">{name}</Typography>
    ) : (
      <EditField
        value={stringValue(row.cells?.name) || ''}
        component={TextField}
        onChange={(e) => onNameChanged(e)}
      />
    );
  }, [isDefault, name, onNameChanged, row.cells?.name]);

  return (
    <Root>
      <StartRow>
        {renderCheckboxOrDelete()}
        {renderLabelOrInput()}
      </StartRow>

      <EndRow>
        <DragIcon />
      </EndRow>
    </Root>
  );
};

export default EditTableModalRow;

import { field } from '@agoy/annual-report-document';
import { FinancialReportStructure } from '../../types';

export const additionalTextsConfig =
  (): FinancialReportStructure['additionalText'] => {
    return {
      section: {
        active: true,
        text1Active: false,
        text1Title: field('Textsida'),
        text1: field(''),
        text2Active: false,
        text2Title: field('Textsida'),
        text2: field(''),
      },
    };
  };

import { DocumentConfiguration } from '../../document';
import { cashFlowStatementReferences } from '../cash-flow-statement/v1/cashFlowStatement';
import { managementReportReferences } from '../management-report/managementReport';
import {
  References,
  getBalanceStatementReferences,
  getIncomeStatementReferences,
} from './utils';

export const sharesCompanyReferences = (
  documentConfiguration: DocumentConfiguration
): References[] => {
  const { version, reportType, incomeStatement, isDigitalSubmission } =
    documentConfiguration;

  const partReferences = [
    managementReportReferences(),
    getIncomeStatementReferences(version, reportType, incomeStatement),
    getBalanceStatementReferences(version, reportType),
    cashFlowStatementReferences(!!isDigitalSubmission, 'previousYear'),
  ];

  return partReferences;
};

/*
 * This is a GENERATED file, do not change manually!
 * Generated:
 * - from file k2-arsredovisning-2021-10-31-rev20230131_sv - Kassaflödesanalys indirekt met.csv
 * - Comments: nan
 */

import {
  id,
  account,
  sum,
  sumAllowNull,
  or,
  multiply,
  label,
  ref,
  value,
  refs,
  ReferenceAccountInformation,
  TimePeriod,
  AccountValueType,
} from '@agoy/document';
import { IxbrlCell } from '../../../../common';
import {
  table,
  RowsBuilder,
  ixbrlCell,
} from '../../../../common/utils/util';
import { AnnualReportType, AnnualReportVersion } from '../../../../document';
import { range, StatementSection } from '@agoy/annual-report-document';

const accountRangeRows = (
  accounts: Record<string, ReferenceAccountInformation>,
  first: number,
  last: number,
  rows: RowsBuilder,
  switchSign: boolean,
  currentPeriod: string,
  previousPeriod: string,
  modifier?: AccountValueType | undefined
): RowsBuilder => {
  range(first, last + 1).forEach((n) => {
    const accountNumber = n.toString();
    const accountInformation = accounts[accountNumber];
    if (accountInformation) {
      rows
        .addRowWithType(
          accountNumber,
          'account',
          label(`${accountInformation.accountName}`),
          undefined,
          ref(
            switchSign
              ? multiply(
                  -1,
                  or(account(accountNumber, modifier, currentPeriod), 0)
                )
              : or(account(accountNumber, modifier, currentPeriod), 0)
          ),
          ref(
            switchSign
              ? multiply(
                  -1,
                  or(account(accountNumber, modifier, previousPeriod), 0)
                )
              : or(account(accountNumber, modifier, previousPeriod), 0)
          )
        )
        .setSortKey(n);
    }
  });
  return rows;
};

export const cashFlowStatementK2v2Config = (
  accounts: Record<string, ReferenceAccountInformation>,
  period: TimePeriod,
  previousPeriod: TimePeriod | null,
  type: AnnualReportType,
  isDigitalSubmission: boolean | null,
  documentTypeVersion: AnnualReportVersion = '2',
  accountModifier: AccountValueType = 'change'
): StatementSection => ({
  active: undefined,
  section: {
    active: undefined,
    table: table<IxbrlCell>(
      'cashFlowStatement.section.table',
      { id: 'label', dataType: 'text' },
      { id: 'notes', dataType: 'numeric' },
      {
        id: 'year',
        label: `${period.startDateISO} ${period.endDateISO}`,
        dataType: 'numeric',
      },
      previousPeriod
        ? {
            id: 'previousYear',
            label: `${previousPeriod.startDateISO} ${previousPeriod.endDateISO}`,
            dataType: 'numeric',
          }
        : undefined
    )
      .addRows((rows) => {
        rows
          .addRowWithType(
            'KassaflodesanalysLopandeVerksamhetenAbstract',
            'header',
            value('Den löpande verksamheten'),
            refs(),
            undefined,
            undefined
          )
          .addSubRows((rows) => {
            rows.addRowWithType(
              'Rorelseresultat',
              'row',
              value('Rörelseresultat'),
              refs(),
              ixbrlCell(
                ref(
                  sumAllowNull(
                    id('incomeStatement.section.table.Rorelseresultat.year')
                  )
                ),
                {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:Rorelseresultat',
                  contextRef: 'period0',
                  standardRubrik: 'Rörelseresultat',
                  saldo: 'credit',
                  negateValue: false,
                }
              ),
              ixbrlCell(
                ref(
                  sumAllowNull(
                    id(
                      'incomeStatement.section.table.Rorelseresultat.previousYear'
                    )
                  )
                ),
                {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:Rorelseresultat',
                  contextRef: 'period1',
                  standardRubrik: 'Rörelseresultat',
                  saldo: 'credit',
                  negateValue: false,
                }
              )
            );
            rows
              .addRowWithType(
                'JusteringPosterInteIngarKassaflodeAbstract',
                'header',
                value('Justering för poster som inte ingår i kassaflödet m.m.'),
                refs(),
                undefined,
                undefined
              )
              .addSubRows((rows) => {
                rows
                  .addRowWithType(
                    'JusteringPosterInteIngarKassaflodeAvskrivningarNedskrivningar',
                    'row',
                    value('Avskrivningar och nedskrivningar'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.JusteringPosterInteIngarKassaflodeAbstract.JusteringPosterInteIngarKassaflodeAvskrivningarNedskrivningar.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:JusteringPosterInteIngarKassaflodeAvskrivningarNedskrivningar',
                        contextRef: 'period0',
                        standardRubrik:
                          'Avskrivningar och nedskrivningar (inklusive återföringar) vid justering för poster som inte ingår i kassaflödet m.m.',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.JusteringPosterInteIngarKassaflodeAbstract.JusteringPosterInteIngarKassaflodeAvskrivningarNedskrivningar.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:JusteringPosterInteIngarKassaflodeAvskrivningarNedskrivningar',
                        contextRef: 'period1',
                        standardRubrik:
                          'Avskrivningar och nedskrivningar (inklusive återföringar) vid justering för poster som inte ingår i kassaflödet m.m.',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(or(account('$ub', accountModifier, 'year'), 0)),
                    ref(or(account('$ib', accountModifier, 'previousYear'), 0))
                  )
                  .addSubRows((rows) => {
                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'RealisationsVinstForlustRorelseresultat',
                    'row',
                    value('Realisationsvinst/realisationsförlust'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.JusteringPosterInteIngarKassaflodeAbstract.RealisationsVinstForlustRorelseresultat.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:RealisationsVinstForlustRorelseresultat',
                        contextRef: 'period0',
                        standardRubrik:
                          'Realisationsförlust (realisationsvinst) som ingår i rörelseresultatet',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.JusteringPosterInteIngarKassaflodeAbstract.RealisationsVinstForlustRorelseresultat.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:RealisationsVinstForlustRorelseresultat',
                        contextRef: 'period1',
                        standardRubrik:
                          'Realisationsförlust (realisationsvinst) som ingår i rörelseresultatet',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(or(account('$ub', accountModifier, 'year'), 0)),
                    ref(or(account('$ib', accountModifier, 'previousYear'), 0))
                  )
                  .addSubRows((rows) => {
                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'JusteringPosterInteIngarKassaflodeValutakursdifferenser',
                    'row',
                    value('Valutakursdifferenser'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.JusteringPosterInteIngarKassaflodeAbstract.JusteringPosterInteIngarKassaflodeValutakursdifferenser.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:JusteringPosterInteIngarKassaflodeValutakursdifferenser',
                        contextRef: 'period0',
                        standardRubrik:
                          'Valutakursförlust (valutakursvinst) i justering för poster som inte ingår i kassaflödet m.m.',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.JusteringPosterInteIngarKassaflodeAbstract.JusteringPosterInteIngarKassaflodeValutakursdifferenser.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:JusteringPosterInteIngarKassaflodeValutakursdifferenser',
                        contextRef: 'period1',
                        standardRubrik:
                          'Valutakursförlust (valutakursvinst) i justering för poster som inte ingår i kassaflödet m.m.',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(or(account('$ub', accountModifier, 'year'), 0)),
                    ref(or(account('$ib', accountModifier, 'previousYear'), 0))
                  )
                  .addSubRows((rows) => {
                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'JusteringPosterInteIngarKassaflodeAvsattningPensioner',
                    'row',
                    value('Avsättning för pensioner'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.JusteringPosterInteIngarKassaflodeAbstract.JusteringPosterInteIngarKassaflodeAvsattningPensioner.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:JusteringPosterInteIngarKassaflodeAvsattningPensioner',
                        contextRef: 'period0',
                        standardRubrik:
                          'Avsättning (återföring av avsättning) för pensioner i justering för poster som inte ingår i kassaflödet m.m.',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.JusteringPosterInteIngarKassaflodeAbstract.JusteringPosterInteIngarKassaflodeAvsattningPensioner.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:JusteringPosterInteIngarKassaflodeAvsattningPensioner',
                        contextRef: 'period1',
                        standardRubrik:
                          'Avsättning (återföring av avsättning) för pensioner i justering för poster som inte ingår i kassaflödet m.m.',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(or(account('$ub', accountModifier, 'year'), 0)),
                    ref(or(account('$ib', accountModifier, 'previousYear'), 0))
                  )
                  .addSubRows((rows) => {
                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'JusteringPosterInteIngarKassaflodeAvsattningGarantier',
                    'row',
                    value('Avsättning för garantier'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.JusteringPosterInteIngarKassaflodeAbstract.JusteringPosterInteIngarKassaflodeAvsattningGarantier.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:JusteringPosterInteIngarKassaflodeAvsattningGarantier',
                        contextRef: 'period0',
                        standardRubrik:
                          'Avsättning (återföring av avsättning) för garantier i justering för poster som inte ingår i kassaflödet m.m.',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.JusteringPosterInteIngarKassaflodeAbstract.JusteringPosterInteIngarKassaflodeAvsattningGarantier.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:JusteringPosterInteIngarKassaflodeAvsattningGarantier',
                        contextRef: 'period1',
                        standardRubrik:
                          'Avsättning (återföring av avsättning) för garantier i justering för poster som inte ingår i kassaflödet m.m.',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(or(account('$ub', accountModifier, 'year'), 0)),
                    ref(or(account('$ib', accountModifier, 'previousYear'), 0))
                  )
                  .addSubRows((rows) => {
                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'JusteringPosterInteIngarKassaflodeAvsattningOmstrukturering',
                    'row',
                    value('Avsättning för omstrukturering'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.JusteringPosterInteIngarKassaflodeAbstract.JusteringPosterInteIngarKassaflodeAvsattningOmstrukturering.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:JusteringPosterInteIngarKassaflodeAvsattningOmstrukturering',
                        contextRef: 'period0',
                        standardRubrik:
                          'Avsättning (återföring av avsättning) för omstrukturering i justering för poster som inte ingår i kassaflödet m.m.',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.JusteringPosterInteIngarKassaflodeAbstract.JusteringPosterInteIngarKassaflodeAvsattningOmstrukturering.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:JusteringPosterInteIngarKassaflodeAvsattningOmstrukturering',
                        contextRef: 'period1',
                        standardRubrik:
                          'Avsättning (återföring av avsättning) för omstrukturering i justering för poster som inte ingår i kassaflödet m.m.',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(or(account('$ub', accountModifier, 'year'), 0)),
                    ref(or(account('$ib', accountModifier, 'previousYear'), 0))
                  )
                  .addSubRows((rows) => {
                    return rows.build();
                  });
                return rows.build();
              });
            rows
              .addRowWithType(
                'ErhallenRanta',
                'row',
                value('Erhållen ränta'),
                refs(),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.ErhallenRanta.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:ErhallenRanta',
                    contextRef: 'period0',
                    standardRubrik: 'Ökning av kassaflöde vid erhållen ränta',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.ErhallenRanta.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:ErhallenRanta',
                    contextRef: 'period1',
                    standardRubrik: 'Ökning av kassaflöde vid erhållen ränta',
                    negateValue: false,
                  }
                )
              ) // <<< end row_empty_value ---
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(
                  multiply(-1, or(account('$ub', accountModifier, 'year'), 0))
                ),
                ref(
                  multiply(
                    -1,
                    or(account('$ib', accountModifier, 'previousYear'), 0)
                  )
                )
              )
              .addSubRows((rows) => {
                return rows.build();
              });
            rows
              .addRowWithType(
                'ErhallenUtdelning',
                'row',
                value('Erhållen utdelning'),
                refs(),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.ErhallenUtdelning.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:ErhallenUtdelning',
                    contextRef: 'period0',
                    standardRubrik:
                      'Ökning av kassaflöde vid erhållen utdelning',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.ErhallenUtdelning.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:ErhallenUtdelning',
                    contextRef: 'period1',
                    standardRubrik:
                      'Ökning av kassaflöde vid erhållen utdelning',
                    negateValue: false,
                  }
                )
              ) // <<< end row_empty_value ---
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(
                  multiply(-1, or(account('$ub', accountModifier, 'year'), 0))
                ),
                ref(
                  multiply(
                    -1,
                    or(account('$ib', accountModifier, 'previousYear'), 0)
                  )
                )
              )
              .addSubRows((rows) => {
                return rows.build();
              });
            rows
              .addRowWithType(
                'ErlagdRanta',
                'row',
                value('Erlagd ränta'),
                refs(),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.ErlagdRanta.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:ErlagdRanta',
                    contextRef: 'period0',
                    standardRubrik: 'Minskning av kassaflöde vid erlagd ränta',
                    negateValue: true,
                  }
                ),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.ErlagdRanta.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:ErlagdRanta',
                    contextRef: 'period1',
                    standardRubrik: 'Minskning av kassaflöde vid erlagd ränta',
                    negateValue: true,
                  }
                )
              ) // <<< end row_empty_value ---
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(
                  multiply(-1, or(account('$ub', accountModifier, 'year'), 0))
                ),
                ref(
                  multiply(
                    -1,
                    or(account('$ib', accountModifier, 'previousYear'), 0)
                  )
                )
              )
              .addSubRows((rows) => {
                return rows.build();
              });
            rows
              .addRowWithType(
                'BetaldSkatt',
                'row',
                value('Betald inkomstskatt'),
                refs(),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.BetaldSkatt.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:BetaldSkatt',
                    contextRef: 'period0',
                    standardRubrik:
                      'Minskning av kassaflöde vid betald inkomstskatt',
                    negateValue: true,
                  }
                ),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.BetaldSkatt.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:BetaldSkatt',
                    contextRef: 'period1',
                    standardRubrik:
                      'Minskning av kassaflöde vid betald inkomstskatt',
                    negateValue: true,
                  }
                )
              ) // <<< end row_empty_value ---
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(
                  multiply(-1, or(account('$ub', accountModifier, 'year'), 0))
                ),
                ref(
                  multiply(
                    -1,
                    or(account('$ib', accountModifier, 'previousYear'), 0)
                  )
                )
              )
              .addSubRows((rows) => {
                return rows.build();
              });
            rows
              .addRowWithType(
                'KassaflodeLopandeVerksamhetenForeForandringarRorelsekapital',
                'row',
                value(
                  'Kassaflöde från den löpande verksamheten före förändringar av rörelsekapital'
                ),
                refs(),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.KassaflodeLopandeVerksamhetenForeForandringarRorelsekapital.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:KassaflodeLopandeVerksamhetenForeForandringarRorelsekapital',
                    contextRef: 'period0',
                    standardRubrik:
                      'Ökning (minskning) av kassaflöde från den löpande verksamheten före förändringar av rörelsekapital',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.KassaflodeLopandeVerksamhetenForeForandringarRorelsekapital.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:KassaflodeLopandeVerksamhetenForeForandringarRorelsekapital',
                    contextRef: 'period1',
                    standardRubrik:
                      'Ökning (minskning) av kassaflöde från den löpande verksamheten före förändringar av rörelsekapital',
                    negateValue: false,
                  }
                )
              ) // <<< end row_empty_value ---
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(or(account('$ub', accountModifier, 'year'), 0)),
                ref(or(account('$ib', accountModifier, 'previousYear'), 0))
              )
              .addSubRows((rows) => {
                return rows.build();
              });
            rows
              .addRowWithType(
                'ForandringKassaflodeRorelsekapitaletAbstract',
                'header',
                value('Kassaflöde från förändringar i rörelsekapital'),
                refs(),
                undefined,
                undefined
              )
              .addSubRows((rows) => {
                rows
                  .addRowWithType(
                    'ForandringKassaflodeVarulagerPagaendeArbeten',
                    'row',
                    value('Ökning/minskning varulager och pågående arbeten'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.ForandringKassaflodeRorelsekapitaletAbstract.ForandringKassaflodeVarulagerPagaendeArbeten.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:ForandringKassaflodeVarulagerPagaendeArbeten',
                        contextRef: 'period0',
                        standardRubrik:
                          'Ökning (minskning) av kassaflöde vid förändring av varulager och pågående arbeten',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.ForandringKassaflodeRorelsekapitaletAbstract.ForandringKassaflodeVarulagerPagaendeArbeten.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:ForandringKassaflodeVarulagerPagaendeArbeten',
                        contextRef: 'period1',
                        standardRubrik:
                          'Ökning (minskning) av kassaflöde vid förändring av varulager och pågående arbeten',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'ForandringKassaflodeKundfordringar',
                    'row',
                    value('Ökning/minskning kundfordringar'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.ForandringKassaflodeRorelsekapitaletAbstract.ForandringKassaflodeKundfordringar.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:ForandringKassaflodeKundfordringar',
                        contextRef: 'period0',
                        standardRubrik:
                          'Ökning (minskning) av kassaflöde vid förändring av kundfordringar',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.ForandringKassaflodeRorelsekapitaletAbstract.ForandringKassaflodeKundfordringar.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:ForandringKassaflodeKundfordringar',
                        contextRef: 'period1',
                        standardRubrik:
                          'Ökning (minskning) av kassaflöde vid förändring av kundfordringar',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'ForandringKassaflodeOvrigaKortfristigaFordringar',
                    'row',
                    value('Ökning/minskning övriga kortfristiga fordringar'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.ForandringKassaflodeRorelsekapitaletAbstract.ForandringKassaflodeOvrigaKortfristigaFordringar.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:ForandringKassaflodeOvrigaKortfristigaFordringar',
                        contextRef: 'period0',
                        standardRubrik:
                          'Ökning (minskning) av kassaflöde vid förändring av övriga kortfristiga fordringar',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.ForandringKassaflodeRorelsekapitaletAbstract.ForandringKassaflodeOvrigaKortfristigaFordringar.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:ForandringKassaflodeOvrigaKortfristigaFordringar',
                        contextRef: 'period1',
                        standardRubrik:
                          'Ökning (minskning) av kassaflöde vid förändring av övriga kortfristiga fordringar',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'ForandringKassaflodeLeverantorsskulder',
                    'row',
                    value('Ökning/minskning leverantörsskulder'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.ForandringKassaflodeRorelsekapitaletAbstract.ForandringKassaflodeLeverantorsskulder.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:ForandringKassaflodeLeverantorsskulder',
                        contextRef: 'period0',
                        standardRubrik:
                          'Ökning (minskning) av kassaflöde vid förändring av leverantörsskulder',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.ForandringKassaflodeRorelsekapitaletAbstract.ForandringKassaflodeLeverantorsskulder.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:ForandringKassaflodeLeverantorsskulder',
                        contextRef: 'period1',
                        standardRubrik:
                          'Ökning (minskning) av kassaflöde vid förändring av leverantörsskulder',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'ForandringKassaflodeOvrigaKortfristigaSkulder',
                    'row',
                    value('Ökning/minskning övriga kortfristiga skulder'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.ForandringKassaflodeRorelsekapitaletAbstract.ForandringKassaflodeOvrigaKortfristigaSkulder.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:ForandringKassaflodeOvrigaKortfristigaSkulder',
                        contextRef: 'period0',
                        standardRubrik:
                          'Ökning (minskning) av kassaflöde vid förändring av övriga kortfristiga skulder',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.ForandringKassaflodeRorelsekapitaletAbstract.ForandringKassaflodeOvrigaKortfristigaSkulder.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:ForandringKassaflodeOvrigaKortfristigaSkulder',
                        contextRef: 'period1',
                        standardRubrik:
                          'Ökning (minskning) av kassaflöde vid förändring av övriga kortfristiga skulder',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    return rows.build();
                  });
                return rows.build();
              });
            return rows.build();
          });
        rows
          .addRowWithType(
            'KassaflodeLopandeVerksamheten',
            'sum',
            value('Kassaflöde från den löpande verksamheten'),
            undefined,
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.Rorelseresultat.year'
                  ),
                  id(
                    'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.JusteringPosterInteIngarKassaflodeAbstract.JusteringPosterInteIngarKassaflodeAvskrivningarNedskrivningar.year'
                  ),
                  id(
                    'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.JusteringPosterInteIngarKassaflodeAbstract.RealisationsVinstForlustRorelseresultat.year'
                  ),
                  id(
                    'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.JusteringPosterInteIngarKassaflodeAbstract.JusteringPosterInteIngarKassaflodeValutakursdifferenser.year'
                  ),
                  id(
                    'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.JusteringPosterInteIngarKassaflodeAbstract.JusteringPosterInteIngarKassaflodeAvsattningPensioner.year'
                  ),
                  id(
                    'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.JusteringPosterInteIngarKassaflodeAbstract.JusteringPosterInteIngarKassaflodeAvsattningGarantier.year'
                  ),
                  id(
                    'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.JusteringPosterInteIngarKassaflodeAbstract.JusteringPosterInteIngarKassaflodeAvsattningOmstrukturering.year'
                  ),
                  id(
                    'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.ErhallenRanta.year'
                  ),
                  id(
                    'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.ErhallenUtdelning.year'
                  ),
                  id(
                    'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.ErlagdRanta.year'
                  ),
                  id(
                    'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.BetaldSkatt.year'
                  ),
                  id(
                    'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.KassaflodeLopandeVerksamhetenForeForandringarRorelsekapital.year'
                  ),
                  id(
                    'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.ForandringKassaflodeRorelsekapitaletAbstract.ForandringKassaflodeVarulagerPagaendeArbeten.year'
                  ),
                  id(
                    'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.ForandringKassaflodeRorelsekapitaletAbstract.ForandringKassaflodeKundfordringar.year'
                  ),
                  id(
                    'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.ForandringKassaflodeRorelsekapitaletAbstract.ForandringKassaflodeOvrigaKortfristigaFordringar.year'
                  ),
                  id(
                    'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.ForandringKassaflodeRorelsekapitaletAbstract.ForandringKassaflodeLeverantorsskulder.year'
                  ),
                  id(
                    'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.ForandringKassaflodeRorelsekapitaletAbstract.ForandringKassaflodeOvrigaKortfristigaSkulder.year'
                  )
                )
              ),
              {
                type: 'monetaryItemType',
                name: 'se-gen-base:KassaflodeLopandeVerksamheten',
                contextRef: 'period0',
                standardRubrik:
                  'Ökning (minskning) av kassaflöde från den löpande verksamheten',
                negateValue: false,
              }
            ),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.Rorelseresultat.previousYear'
                  ),
                  id(
                    'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.JusteringPosterInteIngarKassaflodeAbstract.JusteringPosterInteIngarKassaflodeAvskrivningarNedskrivningar.previousYear'
                  ),
                  id(
                    'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.JusteringPosterInteIngarKassaflodeAbstract.RealisationsVinstForlustRorelseresultat.previousYear'
                  ),
                  id(
                    'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.JusteringPosterInteIngarKassaflodeAbstract.JusteringPosterInteIngarKassaflodeValutakursdifferenser.previousYear'
                  ),
                  id(
                    'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.JusteringPosterInteIngarKassaflodeAbstract.JusteringPosterInteIngarKassaflodeAvsattningPensioner.previousYear'
                  ),
                  id(
                    'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.JusteringPosterInteIngarKassaflodeAbstract.JusteringPosterInteIngarKassaflodeAvsattningGarantier.previousYear'
                  ),
                  id(
                    'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.JusteringPosterInteIngarKassaflodeAbstract.JusteringPosterInteIngarKassaflodeAvsattningOmstrukturering.previousYear'
                  ),
                  id(
                    'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.ErhallenRanta.previousYear'
                  ),
                  id(
                    'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.ErhallenUtdelning.previousYear'
                  ),
                  id(
                    'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.ErlagdRanta.previousYear'
                  ),
                  id(
                    'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.BetaldSkatt.previousYear'
                  ),
                  id(
                    'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.KassaflodeLopandeVerksamhetenForeForandringarRorelsekapital.previousYear'
                  ),
                  id(
                    'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.ForandringKassaflodeRorelsekapitaletAbstract.ForandringKassaflodeVarulagerPagaendeArbeten.previousYear'
                  ),
                  id(
                    'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.ForandringKassaflodeRorelsekapitaletAbstract.ForandringKassaflodeKundfordringar.previousYear'
                  ),
                  id(
                    'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.ForandringKassaflodeRorelsekapitaletAbstract.ForandringKassaflodeOvrigaKortfristigaFordringar.previousYear'
                  ),
                  id(
                    'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.ForandringKassaflodeRorelsekapitaletAbstract.ForandringKassaflodeLeverantorsskulder.previousYear'
                  ),
                  id(
                    'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.ForandringKassaflodeRorelsekapitaletAbstract.ForandringKassaflodeOvrigaKortfristigaSkulder.previousYear'
                  )
                )
              ),
              {
                type: 'monetaryItemType',
                name: 'se-gen-base:KassaflodeLopandeVerksamheten',
                contextRef: 'period1',
                standardRubrik:
                  'Ökning (minskning) av kassaflöde från den löpande verksamheten',
                negateValue: false,
              }
            )
          )
          .newRowTemplateWithType(
            'account',
            label('$label'),
            undefined,
            ref(or(account('$ub', accountModifier, 'year'), 0)),
            ref(or(account('$ib', accountModifier, 'previousYear'), 0))
          )
          .addSubRows((rows) => {
            return rows.build();
          });
        rows
          .addRowWithType(
            'KassaflodesanalysInvesteringsverksamhetenAbstract',
            'header',
            value('Investeringsverksamheten'),
            refs(),
            undefined,
            undefined
          )
          .addSubRows((rows) => {
            rows
              .addRowWithType(
                'LamnadeKapitaltillskottInvesteringsverksamheten',
                'row',
                value('Lämnade aktieägartillskott'),
                refs(),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.LamnadeKapitaltillskottInvesteringsverksamheten.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:LamnadeKapitaltillskottInvesteringsverksamheten',
                    contextRef: 'period0',
                    standardRubrik:
                      'Minskning av kassaflöde vid lämnade tillskott',
                    negateValue: true,
                  }
                ),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.LamnadeKapitaltillskottInvesteringsverksamheten.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:LamnadeKapitaltillskottInvesteringsverksamheten',
                    contextRef: 'period1',
                    standardRubrik:
                      'Minskning av kassaflöde vid lämnade tillskott',
                    negateValue: true,
                  }
                )
              ) // <<< end row_empty_value ---
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(
                  multiply(-1, or(account('$ub', accountModifier, 'year'), 0))
                ),
                ref(
                  multiply(
                    -1,
                    or(account('$ib', accountModifier, 'previousYear'), 0)
                  )
                )
              )
              .addSubRows((rows) => {
                return rows.build();
              });
            rows
              .addRowWithType(
                'LamnadeKoncernbidragInvesteringsverksamheten',
                'row',
                value('Lämnade koncernbidrag'),
                refs(),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.LamnadeKoncernbidragInvesteringsverksamheten.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:LamnadeKoncernbidragInvesteringsverksamheten',
                    contextRef: 'period0',
                    standardRubrik:
                      'Minskning av kassaflöde vid lämnade koncernbidrag i investeringsverksamheten',
                    negateValue: true,
                  }
                ),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.LamnadeKoncernbidragInvesteringsverksamheten.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:LamnadeKoncernbidragInvesteringsverksamheten',
                    contextRef: 'period1',
                    standardRubrik:
                      'Minskning av kassaflöde vid lämnade koncernbidrag i investeringsverksamheten',
                    negateValue: true,
                  }
                )
              ) // <<< end row_empty_value ---
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(
                  multiply(-1, or(account('$ub', accountModifier, 'year'), 0))
                ),
                ref(
                  multiply(
                    -1,
                    or(account('$ib', accountModifier, 'previousYear'), 0)
                  )
                )
              )
              .addSubRows((rows) => {
                return rows.build();
              });
            rows
              .addRowWithType(
                'InvesteringImmateriellaAnlaggningstillgangar',
                'row',
                value('Förvärv av immateriella anläggningstillgångar'),
                refs(),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.InvesteringImmateriellaAnlaggningstillgangar.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:InvesteringImmateriellaAnlaggningstillgangar',
                    contextRef: 'period0',
                    standardRubrik:
                      'Minskning av kassaflöde vid förvärv av immateriella anläggningstillgångar',
                    negateValue: true,
                  }
                ),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.InvesteringImmateriellaAnlaggningstillgangar.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:InvesteringImmateriellaAnlaggningstillgangar',
                    contextRef: 'period1',
                    standardRubrik:
                      'Minskning av kassaflöde vid förvärv av immateriella anläggningstillgångar',
                    negateValue: true,
                  }
                )
              ) // <<< end row_empty_value ---
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(
                  multiply(-1, or(account('$ub', accountModifier, 'year'), 0))
                ),
                ref(
                  multiply(
                    -1,
                    or(account('$ib', accountModifier, 'previousYear'), 0)
                  )
                )
              )
              .addSubRows((rows) => {
                return rows.build();
              });
            rows
              .addRowWithType(
                'ForsaljningImmateriellaAnlaggningstillgangar',
                'row',
                value('Avyttring av immateriella anläggningstillgångar'),
                refs(),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForsaljningImmateriellaAnlaggningstillgangar.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:ForsaljningImmateriellaAnlaggningstillgangar',
                    contextRef: 'period0',
                    standardRubrik:
                      'Ökning av kassaflöde vid avyttring av immateriella anläggningstillgångar',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForsaljningImmateriellaAnlaggningstillgangar.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:ForsaljningImmateriellaAnlaggningstillgangar',
                    contextRef: 'period1',
                    standardRubrik:
                      'Ökning av kassaflöde vid avyttring av immateriella anläggningstillgångar',
                    negateValue: false,
                  }
                )
              ) // <<< end row_empty_value ---
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(
                  multiply(-1, or(account('$ub', accountModifier, 'year'), 0))
                ),
                ref(
                  multiply(
                    -1,
                    or(account('$ib', accountModifier, 'previousYear'), 0)
                  )
                )
              )
              .addSubRows((rows) => {
                return rows.build();
              });
            rows
              .addRowWithType(
                'InvesteringMateriellaAnlaggningstillgangar',
                'row',
                value('Förvärv av materiella anläggningstillgångar'),
                refs(),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.InvesteringMateriellaAnlaggningstillgangar.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:InvesteringMateriellaAnlaggningstillgangar',
                    contextRef: 'period0',
                    standardRubrik:
                      'Minskning av kassaflöde vid förvärv av materiella anläggningstillgångar',
                    negateValue: true,
                  }
                ),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.InvesteringMateriellaAnlaggningstillgangar.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:InvesteringMateriellaAnlaggningstillgangar',
                    contextRef: 'period1',
                    standardRubrik:
                      'Minskning av kassaflöde vid förvärv av materiella anläggningstillgångar',
                    negateValue: true,
                  }
                )
              ) // <<< end row_empty_value ---
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(
                  multiply(-1, or(account('$ub', accountModifier, 'year'), 0))
                ),
                ref(
                  multiply(
                    -1,
                    or(account('$ib', accountModifier, 'previousYear'), 0)
                  )
                )
              )
              .addSubRows((rows) => {
                return rows.build();
              });
            rows
              .addRowWithType(
                'ForsaljningMateriellaAnlaggningstillgangar',
                'row',
                value('Avyttring av materiella anläggningstillgångar'),
                refs(),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForsaljningMateriellaAnlaggningstillgangar.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:ForsaljningMateriellaAnlaggningstillgangar',
                    contextRef: 'period0',
                    standardRubrik:
                      'Ökning av kassaflöde vid avyttring av materiella anläggningstillgångar',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForsaljningMateriellaAnlaggningstillgangar.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:ForsaljningMateriellaAnlaggningstillgangar',
                    contextRef: 'period1',
                    standardRubrik:
                      'Ökning av kassaflöde vid avyttring av materiella anläggningstillgångar',
                    negateValue: false,
                  }
                )
              ) // <<< end row_empty_value ---
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(
                  multiply(-1, or(account('$ub', accountModifier, 'year'), 0))
                ),
                ref(
                  multiply(
                    -1,
                    or(account('$ib', accountModifier, 'previousYear'), 0)
                  )
                )
              )
              .addSubRows((rows) => {
                return rows.build();
              });
            rows
              .addRowWithType(
                'InvesteringFinansiellaAnlaggningstillgangar',
                'row',
                value('Förvärv av finansiella anläggningstillgångar'),
                refs(),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.InvesteringFinansiellaAnlaggningstillgangar.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:InvesteringFinansiellaAnlaggningstillgangar',
                    contextRef: 'period0',
                    standardRubrik:
                      'Minskning av kassaflöde vid förvärv av finansiella anläggningstillgångar',
                    negateValue: true,
                  }
                ),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.InvesteringFinansiellaAnlaggningstillgangar.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:InvesteringFinansiellaAnlaggningstillgangar',
                    contextRef: 'period1',
                    standardRubrik:
                      'Minskning av kassaflöde vid förvärv av finansiella anläggningstillgångar',
                    negateValue: true,
                  }
                )
              ) // <<< end row_empty_value ---
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(
                  multiply(-1, or(account('$ub', accountModifier, 'year'), 0))
                ),
                ref(
                  multiply(
                    -1,
                    or(account('$ib', accountModifier, 'previousYear'), 0)
                  )
                )
              )
              .addSubRows((rows) => {
                return rows.build();
              });
            rows
              .addRowWithType(
                'ForsaljningFinansiellaAnlaggningstillgangar',
                'row',
                value(
                  'Avyttring/amortering av finansiella anläggningstillgångar'
                ),
                refs(),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForsaljningFinansiellaAnlaggningstillgangar.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:ForsaljningFinansiellaAnlaggningstillgangar',
                    contextRef: 'period0',
                    standardRubrik:
                      'Ökning av kassaflöde vid avyttring eller amortering av finansiella anläggningstillgångar',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForsaljningFinansiellaAnlaggningstillgangar.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:ForsaljningFinansiellaAnlaggningstillgangar',
                    contextRef: 'period1',
                    standardRubrik:
                      'Ökning av kassaflöde vid avyttring eller amortering av finansiella anläggningstillgångar',
                    negateValue: false,
                  }
                )
              ) // <<< end row_empty_value ---
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(
                  multiply(-1, or(account('$ub', accountModifier, 'year'), 0))
                ),
                ref(
                  multiply(
                    -1,
                    or(account('$ib', accountModifier, 'previousYear'), 0)
                  )
                )
              )
              .addSubRows((rows) => {
                return rows.build();
              });
            rows
              .addRowWithType(
                'ForandringKassaflodeOvrigaKortfristigaPlaceringar',
                'row',
                value('Ökning/minskning av kortfristiga placeringar'),
                refs(),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForandringKassaflodeOvrigaKortfristigaPlaceringar.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:ForandringKassaflodeOvrigaKortfristigaPlaceringar',
                    contextRef: 'period0',
                    standardRubrik:
                      'Ökning (minskning) av kassaflöde vid förändring av kortfristiga placeringar',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForandringKassaflodeOvrigaKortfristigaPlaceringar.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:ForandringKassaflodeOvrigaKortfristigaPlaceringar',
                    contextRef: 'period1',
                    standardRubrik:
                      'Ökning (minskning) av kassaflöde vid förändring av kortfristiga placeringar',
                    negateValue: false,
                  }
                )
              ) // <<< end row_empty_value ---
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(
                  multiply(-1, or(account('$ub', accountModifier, 'year'), 0))
                ),
                ref(
                  multiply(
                    -1,
                    or(account('$ib', accountModifier, 'previousYear'), 0)
                  )
                )
              )
              .addSubRows((rows) => {
                return rows.build();
              });
            return rows.build();
          });
        rows
          .addRowWithType(
            'KassaflodeInvesteringsverksamheten',
            'sum',
            value('Kassaflöde från investeringsverksamheten'),
            undefined,
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.*.year'
                  )
                )
              ),
              {
                type: 'monetaryItemType',
                name: 'se-gen-base:KassaflodeInvesteringsverksamheten',
                contextRef: 'period0',
                standardRubrik:
                  'Ökning (minskning) av kassaflöde från investeringsverksamheten',
                negateValue: false,
              }
            ),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.*.previousYear'
                  )
                )
              ),
              {
                type: 'monetaryItemType',
                name: 'se-gen-base:KassaflodeInvesteringsverksamheten',
                contextRef: 'period1',
                standardRubrik:
                  'Ökning (minskning) av kassaflöde från investeringsverksamheten',
                negateValue: false,
              }
            )
          )
          .newRowTemplateWithType(
            'account',
            label('$label'),
            undefined,
            ref(or(account('$ub', accountModifier, 'year'), 0)),
            ref(or(account('$ib', accountModifier, 'previousYear'), 0))
          )
          .addSubRows((rows) => {
            return rows.build();
          });
        rows
          .addRowWithType(
            'KassaflodesanalysFinansieringsverksamhetenAbstract',
            'header',
            value('Finansieringsverksamheten'),
            refs(),
            undefined,
            undefined
          )
          .addSubRows((rows) => {
            rows
              .addRowWithType(
                'NyemissionKassaflode',
                'row',
                value('Nyemission'),
                refs(),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.NyemissionKassaflode.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:NyemissionKassaflode',
                    contextRef: 'period0',
                    standardRubrik: 'Ökning av kassaflöde vid nyemission',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.NyemissionKassaflode.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:NyemissionKassaflode',
                    contextRef: 'period1',
                    standardRubrik: 'Ökning av kassaflöde vid nyemission',
                    negateValue: false,
                  }
                )
              ) // <<< end row_empty_value ---
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(
                  multiply(-1, or(account('$ub', accountModifier, 'year'), 0))
                ),
                ref(
                  multiply(
                    -1,
                    or(account('$ib', accountModifier, 'previousYear'), 0)
                  )
                )
              )
              .addSubRows((rows) => {
                return rows.build();
              });
            rows
              .addRowWithType(
                'ErhallnaAktieagartillskottKassaflode',
                'row',
                value('Erhållna aktieägartillskott'),
                refs(),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.ErhallnaAktieagartillskottKassaflode.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:ErhallnaAktieagartillskottKassaflode',
                    contextRef: 'period0',
                    standardRubrik:
                      'Ökning av kassaflöde vid erhållna tillskott',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.ErhallnaAktieagartillskottKassaflode.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:ErhallnaAktieagartillskottKassaflode',
                    contextRef: 'period1',
                    standardRubrik:
                      'Ökning av kassaflöde vid erhållna tillskott',
                    negateValue: false,
                  }
                )
              ) // <<< end row_empty_value ---
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(
                  multiply(-1, or(account('$ub', accountModifier, 'year'), 0))
                ),
                ref(
                  multiply(
                    -1,
                    or(account('$ib', accountModifier, 'previousYear'), 0)
                  )
                )
              )
              .addSubRows((rows) => {
                return rows.build();
              });
            rows
              .addRowWithType(
                'ForandringKassaflodeErhallnaKoncernbidragFinansieringsverksamheten',
                'row',
                value('Erhållna koncernbidrag'),
                refs(),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.ForandringKassaflodeErhallnaKoncernbidragFinansieringsverksamheten.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:ForandringKassaflodeErhallnaKoncernbidragFinansieringsverksamheten',
                    contextRef: 'period0',
                    standardRubrik:
                      'Ökning av kassaflöde vid erhållna koncernbidrag',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.ForandringKassaflodeErhallnaKoncernbidragFinansieringsverksamheten.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:ForandringKassaflodeErhallnaKoncernbidragFinansieringsverksamheten',
                    contextRef: 'period1',
                    standardRubrik:
                      'Ökning av kassaflöde vid erhållna koncernbidrag',
                    negateValue: false,
                  }
                )
              ) // <<< end row_empty_value ---
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(
                  multiply(-1, or(account('$ub', accountModifier, 'year'), 0))
                ),
                ref(
                  multiply(
                    -1,
                    or(account('$ib', accountModifier, 'previousYear'), 0)
                  )
                )
              )
              .addSubRows((rows) => {
                return rows.build();
              });
            rows
              .addRowWithType(
                'ForandringKassaflodeLamnadeKoncernbidragFinansieringsverksamheten',
                'row',
                value('Lämnade koncernbidrag'),
                refs(),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.ForandringKassaflodeLamnadeKoncernbidragFinansieringsverksamheten.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:ForandringKassaflodeLamnadeKoncernbidragFinansieringsverksamheten',
                    contextRef: 'period0',
                    standardRubrik:
                      'Minskning av kassaflöde vid lämnade koncernbidrag i finansieringsverksamheten',
                    negateValue: true,
                  }
                ),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.ForandringKassaflodeLamnadeKoncernbidragFinansieringsverksamheten.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:ForandringKassaflodeLamnadeKoncernbidragFinansieringsverksamheten',
                    contextRef: 'period1',
                    standardRubrik:
                      'Minskning av kassaflöde vid lämnade koncernbidrag i finansieringsverksamheten',
                    negateValue: true,
                  }
                )
              ) // <<< end row_empty_value ---
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(
                  multiply(-1, or(account('$ub', accountModifier, 'year'), 0))
                ),
                ref(
                  multiply(
                    -1,
                    or(account('$ib', accountModifier, 'previousYear'), 0)
                  )
                )
              )
              .addSubRows((rows) => {
                return rows.build();
              });
            rows
              .addRowWithType(
                'UpptagnaLan',
                'row',
                value('Upptagna lån'),
                refs(),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.UpptagnaLan.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:UpptagnaLan',
                    contextRef: 'period0',
                    standardRubrik:
                      'Ökning av kassaflöde till följd av upptagna lån',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.UpptagnaLan.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:UpptagnaLan',
                    contextRef: 'period1',
                    standardRubrik:
                      'Ökning av kassaflöde till följd av upptagna lån',
                    negateValue: false,
                  }
                )
              ) // <<< end row_empty_value ---
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(
                  multiply(-1, or(account('$ub', accountModifier, 'year'), 0))
                ),
                ref(
                  multiply(
                    -1,
                    or(account('$ib', accountModifier, 'previousYear'), 0)
                  )
                )
              )
              .addSubRows((rows) => {
                return rows.build();
              });
            rows
              .addRowWithType(
                'AmorteringSkuld',
                'row',
                value('Amortering av skuld'),
                refs(),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.AmorteringSkuld.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:AmorteringSkuld',
                    contextRef: 'period0',
                    standardRubrik:
                      'Minskning av kassaflöde vid amortering av skuld',
                    negateValue: true,
                  }
                ),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.AmorteringSkuld.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:AmorteringSkuld',
                    contextRef: 'period1',
                    standardRubrik:
                      'Minskning av kassaflöde vid amortering av skuld',
                    negateValue: true,
                  }
                )
              ) // <<< end row_empty_value ---
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(
                  multiply(-1, or(account('$ub', accountModifier, 'year'), 0))
                ),
                ref(
                  multiply(
                    -1,
                    or(account('$ib', accountModifier, 'previousYear'), 0)
                  )
                )
              )
              .addSubRows((rows) => {
                return rows.build();
              });
            rows
              .addRowWithType(
                'KortfristigaFinansiellaSkulder',
                'row',
                value('Ökning/minskning av kortfristiga finansiella skulder'),
                refs(),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.KortfristigaFinansiellaSkulder.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:KortfristigaFinansiellaSkulder',
                    contextRef: 'period0',
                    standardRubrik:
                      'Ökning (minskning) av kassaflöde vid förändring av kortfristiga finansiella skulder',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.KortfristigaFinansiellaSkulder.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:KortfristigaFinansiellaSkulder',
                    contextRef: 'period1',
                    standardRubrik:
                      'Ökning (minskning) av kassaflöde vid förändring av kortfristiga finansiella skulder',
                    negateValue: false,
                  }
                )
              ) // <<< end row_empty_value ---
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(
                  multiply(-1, or(account('$ub', accountModifier, 'year'), 0))
                ),
                ref(
                  multiply(
                    -1,
                    or(account('$ib', accountModifier, 'previousYear'), 0)
                  )
                )
              )
              .addSubRows((rows) => {
                return rows.build();
              });
            rows
              .addRowWithType(
                'UtbetaldUtdelningKassaflode',
                'row',
                value('Utbetald utdelning'),
                refs(),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.UtbetaldUtdelningKassaflode.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:UtbetaldUtdelningKassaflode',
                    contextRef: 'period0',
                    standardRubrik:
                      'Minskning av kassaflöde till följd av utbetald utdelning',
                    negateValue: true,
                  }
                ),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.UtbetaldUtdelningKassaflode.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:UtbetaldUtdelningKassaflode',
                    contextRef: 'period1',
                    standardRubrik:
                      'Minskning av kassaflöde till följd av utbetald utdelning',
                    negateValue: true,
                  }
                )
              ) // <<< end row_empty_value ---
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(
                  multiply(-1, or(account('$ub', accountModifier, 'year'), 0))
                ),
                ref(
                  multiply(
                    -1,
                    or(account('$ib', accountModifier, 'previousYear'), 0)
                  )
                )
              )
              .addSubRows((rows) => {
                return rows.build();
              });
            return rows.build();
          });
        rows
          .addRowWithType(
            'KassaflodeFinansieringsverksamheten',
            'sum',
            value('Kassaflöde från finansieringsverksamheten'),
            undefined,
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.*.year'
                  )
                )
              ),
              {
                type: 'monetaryItemType',
                name: 'se-gen-base:KassaflodeFinansieringsverksamheten',
                contextRef: 'period0',
                standardRubrik:
                  'Ökning (minskning) av kassaflöde från finansieringsverksamheten',
                negateValue: false,
              }
            ),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.*.previousYear'
                  )
                )
              ),
              {
                type: 'monetaryItemType',
                name: 'se-gen-base:KassaflodeFinansieringsverksamheten',
                contextRef: 'period1',
                standardRubrik:
                  'Ökning (minskning) av kassaflöde från finansieringsverksamheten',
                negateValue: false,
              }
            )
          )
          .newRowTemplateWithType(
            'account',
            label('$label'),
            undefined,
            ref(or(account('$ub', accountModifier, 'year'), 0)),
            ref(or(account('$ib', accountModifier, 'previousYear'), 0))
          )
          .addSubRows((rows) => {
            return rows.build();
          });
        rows
          .addRowWithType(
            'AretsKassaflode',
            'alwaysDisplaySum',
            value('Årets kassaflöde'),
            undefined,
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'cashFlowStatement.section.table.KassaflodeLopandeVerksamheten.year'
                  ),
                  id(
                    'cashFlowStatement.section.table.KassaflodeInvesteringsverksamheten.year'
                  ),
                  id(
                    'cashFlowStatement.section.table.KassaflodeFinansieringsverksamheten.year'
                  )
                )
              ),
              {
                type: 'monetaryItemType',
                name: 'se-gen-base:AretsKassaflode',
                contextRef: 'period0',
                standardRubrik: 'Ökning (minskning) av kassaflöde',
                negateValue: false,
              }
            ),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'cashFlowStatement.section.table.KassaflodeLopandeVerksamheten.previousYear'
                  ),
                  id(
                    'cashFlowStatement.section.table.KassaflodeInvesteringsverksamheten.previousYear'
                  ),
                  id(
                    'cashFlowStatement.section.table.KassaflodeFinansieringsverksamheten.previousYear'
                  )
                )
              ),
              {
                type: 'monetaryItemType',
                name: 'se-gen-base:AretsKassaflode',
                contextRef: 'period1',
                standardRubrik: 'Ökning (minskning) av kassaflöde',
                negateValue: false,
              }
            )
          )
          .newRowTemplateWithType(
            'account',
            label('$label'),
            undefined,
            ref(or(account('$ub', accountModifier, 'year'), 0)),
            ref(or(account('$ib', accountModifier, 'previousYear'), 0))
          )
          .addSubRows((rows) => {
            return rows.build();
          });
        rows
          .addRowWithType(
            'LikvidaMedelBorjan',
            'alwaysDisplay',
            value('Likvida medel vid årets början'),
            refs(),
            ixbrlCell(
              ref(
                sum(
                  id(
                    'cashFlowStatement.section.table.LikvidaMedelBorjan.*.year'
                  )
                )
              ),
              {
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:LikvidaMedel',
                contextRef: 'balans1',
                standardRubrik: 'Likvida medel',
                saldo: 'debit',
                negateValue: false,
              }
            ),
            ixbrlCell(
              ref(
                sum(
                  id(
                    'cashFlowStatement.section.table.LikvidaMedelBorjan.*.previousYear'
                  )
                )
              ),
              {
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:LikvidaMedel',
                contextRef: 'balans2',
                standardRubrik: 'Likvida medel',
                saldo: 'debit',
                negateValue: false,
              }
            )
          ) // <<< end row_empty_value ---
          .newRowTemplateWithType(
            'account',
            label('$label'),
            undefined,
            ref(or(account('$ub', 'ib', 'year'), 0)),
            ref(or(account('$ib', 'ib', 'previousYear'), 0))
          )
          .addSubRows((rows) => {
            return rows.build();
          });
        rows
          .addRowWithType(
            'LikvidaMedelKursdifferens',
            'row',
            value('Kursdifferens i likvida medel'),
            refs(),
            ixbrlCell(
              ref(
                sum(
                  id(
                    'cashFlowStatement.section.table.LikvidaMedelKursdifferens.*.year'
                  )
                )
              ),
              {
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:LikvidaMedelKursdifferens',
                contextRef: 'period0',
                standardRubrik:
                  'Ökning (minskning) av likvida medel vid kursdifferenser',
                negateValue: false,
              }
            ),
            ixbrlCell(
              ref(
                sum(
                  id(
                    'cashFlowStatement.section.table.LikvidaMedelKursdifferens.*.previousYear'
                  )
                )
              ),
              {
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:LikvidaMedelKursdifferens',
                contextRef: 'period1',
                standardRubrik:
                  'Ökning (minskning) av likvida medel vid kursdifferenser',
                negateValue: false,
              }
            )
          ) // <<< end row_empty_value ---
          .newRowTemplateWithType(
            'account',
            label('$label'),
            undefined,
            ref(multiply(-1, or(account('$ub', accountModifier, 'year'), 0))),
            ref(
              multiply(
                -1,
                or(account('$ib', accountModifier, 'previousYear'), 0)
              )
            )
          )
          .addSubRows((rows) => {
            return rows.build();
          });
        rows
          .addRowWithType(
            'LikvidaMedelSlut',
            'alwaysDisplaySum',
            value('Likvida medel vid årets slut'),
            undefined,
            ixbrlCell(
              ref(
                sumAllowNull(
                  id('cashFlowStatement.section.table.AretsKassaflode.year'),
                  id('cashFlowStatement.section.table.LikvidaMedelBorjan.year'),
                  id(
                    'cashFlowStatement.section.table.LikvidaMedelKursdifferens.year'
                  )
                )
              ),
              {
                type: 'monetaryItemType',
                name: 'se-gen-base:LikvidaMedel',
                contextRef: 'balans0',
                standardRubrik: 'Likvida medel',
                saldo: 'debit',
                negateValue: false,
              }
            ),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'cashFlowStatement.section.table.AretsKassaflode.previousYear'
                  ),
                  id(
                    'cashFlowStatement.section.table.LikvidaMedelBorjan.previousYear'
                  ),
                  id(
                    'cashFlowStatement.section.table.LikvidaMedelKursdifferens.previousYear'
                  )
                )
              ),
              {
                type: 'monetaryItemType',
                name: 'se-gen-base:LikvidaMedel',
                contextRef: 'balans1',
                standardRubrik: 'Likvida medel',
                saldo: 'debit',
                negateValue: false,
              }
            )
          )
          .newRowTemplateWithType(
            'account',
            label('$label'),
            undefined,
            ref(or(account('$ub', accountModifier, 'year'), 0)),
            ref(or(account('$ib', accountModifier, 'previousYear'), 0))
          )
          .addSubRows((rows) => {
            return rows.build();
          });
        rows
          .addRowWithType(
            'accountsNotAssigned',
            'accountsNotAssigned',
            label('Ej tilldelade konton'),
            undefined,
            undefined,
            undefined
          ) // >>> begin `add_button` (NL 0) [Id `cashFlowStatement.section.table`] ---
          .newRowTemplateWithType(
            'account',
            label('$label'),
            undefined,
            ref(or(account('$ub', accountModifier, 'year'), 0)),
            ref(or(account('$ib', accountModifier, 'previousYear'), 0))
          )
          .addSubRows((rows) => {
            accountRangeRows(
              accounts,
              1000,
              8999,
              rows,
              false,
              'year',
              'previousYear',
              accountModifier
            );

            return rows.build();
          });
        return rows.build();
      })
      .build(),
  },
});
export const cashFlowStatementK2v2References = (): Record<string, string> => ({
  'cashFlowStatement.Rorelseresultat.year': id(
    'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.Rorelseresultat.year'
  ),
  'cashFlowStatement.Rorelseresultat.previousYear': id(
    'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.Rorelseresultat.previousYear'
  ),
  'cashFlowStatement.JusteringPosterInteIngarKassaflodeAvskrivningarNedskrivningar.year':
    id(
      'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.JusteringPosterInteIngarKassaflodeAbstract.JusteringPosterInteIngarKassaflodeAvskrivningarNedskrivningar.year'
    ),
  'cashFlowStatement.JusteringPosterInteIngarKassaflodeAvskrivningarNedskrivningar.previousYear':
    id(
      'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.JusteringPosterInteIngarKassaflodeAbstract.JusteringPosterInteIngarKassaflodeAvskrivningarNedskrivningar.previousYear'
    ),
  'cashFlowStatement.RealisationsVinstForlustRorelseresultat.year': id(
    'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.JusteringPosterInteIngarKassaflodeAbstract.RealisationsVinstForlustRorelseresultat.year'
  ),
  'cashFlowStatement.RealisationsVinstForlustRorelseresultat.previousYear': id(
    'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.JusteringPosterInteIngarKassaflodeAbstract.RealisationsVinstForlustRorelseresultat.previousYear'
  ),
  'cashFlowStatement.JusteringPosterInteIngarKassaflodeValutakursdifferenser.year':
    id(
      'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.JusteringPosterInteIngarKassaflodeAbstract.JusteringPosterInteIngarKassaflodeValutakursdifferenser.year'
    ),
  'cashFlowStatement.JusteringPosterInteIngarKassaflodeValutakursdifferenser.previousYear':
    id(
      'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.JusteringPosterInteIngarKassaflodeAbstract.JusteringPosterInteIngarKassaflodeValutakursdifferenser.previousYear'
    ),
  'cashFlowStatement.JusteringPosterInteIngarKassaflodeAvsattningPensioner.year':
    id(
      'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.JusteringPosterInteIngarKassaflodeAbstract.JusteringPosterInteIngarKassaflodeAvsattningPensioner.year'
    ),
  'cashFlowStatement.JusteringPosterInteIngarKassaflodeAvsattningPensioner.previousYear':
    id(
      'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.JusteringPosterInteIngarKassaflodeAbstract.JusteringPosterInteIngarKassaflodeAvsattningPensioner.previousYear'
    ),
  'cashFlowStatement.JusteringPosterInteIngarKassaflodeAvsattningGarantier.year':
    id(
      'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.JusteringPosterInteIngarKassaflodeAbstract.JusteringPosterInteIngarKassaflodeAvsattningGarantier.year'
    ),
  'cashFlowStatement.JusteringPosterInteIngarKassaflodeAvsattningGarantier.previousYear':
    id(
      'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.JusteringPosterInteIngarKassaflodeAbstract.JusteringPosterInteIngarKassaflodeAvsattningGarantier.previousYear'
    ),
  'cashFlowStatement.JusteringPosterInteIngarKassaflodeAvsattningOmstrukturering.year':
    id(
      'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.JusteringPosterInteIngarKassaflodeAbstract.JusteringPosterInteIngarKassaflodeAvsattningOmstrukturering.year'
    ),
  'cashFlowStatement.JusteringPosterInteIngarKassaflodeAvsattningOmstrukturering.previousYear':
    id(
      'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.JusteringPosterInteIngarKassaflodeAbstract.JusteringPosterInteIngarKassaflodeAvsattningOmstrukturering.previousYear'
    ),
  'cashFlowStatement.ErhallenRanta.year': id(
    'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.ErhallenRanta.year'
  ),
  'cashFlowStatement.ErhallenRanta.previousYear': id(
    'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.ErhallenRanta.previousYear'
  ),
  'cashFlowStatement.ErhallenUtdelning.year': id(
    'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.ErhallenUtdelning.year'
  ),
  'cashFlowStatement.ErhallenUtdelning.previousYear': id(
    'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.ErhallenUtdelning.previousYear'
  ),
  'cashFlowStatement.ErlagdRanta.year': id(
    'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.ErlagdRanta.year'
  ),
  'cashFlowStatement.ErlagdRanta.previousYear': id(
    'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.ErlagdRanta.previousYear'
  ),
  'cashFlowStatement.BetaldSkatt.year': id(
    'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.BetaldSkatt.year'
  ),
  'cashFlowStatement.BetaldSkatt.previousYear': id(
    'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.BetaldSkatt.previousYear'
  ),
  'cashFlowStatement.KassaflodeLopandeVerksamhetenForeForandringarRorelsekapital.year':
    id(
      'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.KassaflodeLopandeVerksamhetenForeForandringarRorelsekapital.year'
    ),
  'cashFlowStatement.KassaflodeLopandeVerksamhetenForeForandringarRorelsekapital.previousYear':
    id(
      'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.KassaflodeLopandeVerksamhetenForeForandringarRorelsekapital.previousYear'
    ),
  'cashFlowStatement.ForandringKassaflodeVarulagerPagaendeArbeten.year': id(
    'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.ForandringKassaflodeRorelsekapitaletAbstract.ForandringKassaflodeVarulagerPagaendeArbeten.year'
  ),
  'cashFlowStatement.ForandringKassaflodeVarulagerPagaendeArbeten.previousYear':
    id(
      'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.ForandringKassaflodeRorelsekapitaletAbstract.ForandringKassaflodeVarulagerPagaendeArbeten.previousYear'
    ),
  'cashFlowStatement.ForandringKassaflodeKundfordringar.year': id(
    'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.ForandringKassaflodeRorelsekapitaletAbstract.ForandringKassaflodeKundfordringar.year'
  ),
  'cashFlowStatement.ForandringKassaflodeKundfordringar.previousYear': id(
    'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.ForandringKassaflodeRorelsekapitaletAbstract.ForandringKassaflodeKundfordringar.previousYear'
  ),
  'cashFlowStatement.ForandringKassaflodeOvrigaKortfristigaFordringar.year': id(
    'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.ForandringKassaflodeRorelsekapitaletAbstract.ForandringKassaflodeOvrigaKortfristigaFordringar.year'
  ),
  'cashFlowStatement.ForandringKassaflodeOvrigaKortfristigaFordringar.previousYear':
    id(
      'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.ForandringKassaflodeRorelsekapitaletAbstract.ForandringKassaflodeOvrigaKortfristigaFordringar.previousYear'
    ),
  'cashFlowStatement.ForandringKassaflodeLeverantorsskulder.year': id(
    'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.ForandringKassaflodeRorelsekapitaletAbstract.ForandringKassaflodeLeverantorsskulder.year'
  ),
  'cashFlowStatement.ForandringKassaflodeLeverantorsskulder.previousYear': id(
    'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.ForandringKassaflodeRorelsekapitaletAbstract.ForandringKassaflodeLeverantorsskulder.previousYear'
  ),
  'cashFlowStatement.ForandringKassaflodeOvrigaKortfristigaSkulder.year': id(
    'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.ForandringKassaflodeRorelsekapitaletAbstract.ForandringKassaflodeOvrigaKortfristigaSkulder.year'
  ),
  'cashFlowStatement.ForandringKassaflodeOvrigaKortfristigaSkulder.previousYear':
    id(
      'cashFlowStatement.section.table.KassaflodesanalysLopandeVerksamhetenAbstract.ForandringKassaflodeRorelsekapitaletAbstract.ForandringKassaflodeOvrigaKortfristigaSkulder.previousYear'
    ),
  'cashFlowStatement.KassaflodeLopandeVerksamheten.year': id(
    'cashFlowStatement.section.table.KassaflodeLopandeVerksamheten.year'
  ),
  'cashFlowStatement.KassaflodeLopandeVerksamheten.previousYear': id(
    'cashFlowStatement.section.table.KassaflodeLopandeVerksamheten.previousYear'
  ),
  'cashFlowStatement.LamnadeKapitaltillskottInvesteringsverksamheten.year': id(
    'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.LamnadeKapitaltillskottInvesteringsverksamheten.year'
  ),
  'cashFlowStatement.LamnadeKapitaltillskottInvesteringsverksamheten.previousYear':
    id(
      'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.LamnadeKapitaltillskottInvesteringsverksamheten.previousYear'
    ),
  'cashFlowStatement.LamnadeKoncernbidragInvesteringsverksamheten.year': id(
    'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.LamnadeKoncernbidragInvesteringsverksamheten.year'
  ),
  'cashFlowStatement.LamnadeKoncernbidragInvesteringsverksamheten.previousYear':
    id(
      'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.LamnadeKoncernbidragInvesteringsverksamheten.previousYear'
    ),
  'cashFlowStatement.InvesteringImmateriellaAnlaggningstillgangar.year': id(
    'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.InvesteringImmateriellaAnlaggningstillgangar.year'
  ),
  'cashFlowStatement.InvesteringImmateriellaAnlaggningstillgangar.previousYear':
    id(
      'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.InvesteringImmateriellaAnlaggningstillgangar.previousYear'
    ),
  'cashFlowStatement.ForsaljningImmateriellaAnlaggningstillgangar.year': id(
    'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForsaljningImmateriellaAnlaggningstillgangar.year'
  ),
  'cashFlowStatement.ForsaljningImmateriellaAnlaggningstillgangar.previousYear':
    id(
      'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForsaljningImmateriellaAnlaggningstillgangar.previousYear'
    ),
  'cashFlowStatement.InvesteringMateriellaAnlaggningstillgangar.year': id(
    'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.InvesteringMateriellaAnlaggningstillgangar.year'
  ),
  'cashFlowStatement.InvesteringMateriellaAnlaggningstillgangar.previousYear':
    id(
      'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.InvesteringMateriellaAnlaggningstillgangar.previousYear'
    ),
  'cashFlowStatement.ForsaljningMateriellaAnlaggningstillgangar.year': id(
    'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForsaljningMateriellaAnlaggningstillgangar.year'
  ),
  'cashFlowStatement.ForsaljningMateriellaAnlaggningstillgangar.previousYear':
    id(
      'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForsaljningMateriellaAnlaggningstillgangar.previousYear'
    ),
  'cashFlowStatement.InvesteringFinansiellaAnlaggningstillgangar.year': id(
    'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.InvesteringFinansiellaAnlaggningstillgangar.year'
  ),
  'cashFlowStatement.InvesteringFinansiellaAnlaggningstillgangar.previousYear':
    id(
      'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.InvesteringFinansiellaAnlaggningstillgangar.previousYear'
    ),
  'cashFlowStatement.ForsaljningFinansiellaAnlaggningstillgangar.year': id(
    'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForsaljningFinansiellaAnlaggningstillgangar.year'
  ),
  'cashFlowStatement.ForsaljningFinansiellaAnlaggningstillgangar.previousYear':
    id(
      'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForsaljningFinansiellaAnlaggningstillgangar.previousYear'
    ),
  'cashFlowStatement.ForandringKassaflodeOvrigaKortfristigaPlaceringar.year':
    id(
      'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForandringKassaflodeOvrigaKortfristigaPlaceringar.year'
    ),
  'cashFlowStatement.ForandringKassaflodeOvrigaKortfristigaPlaceringar.previousYear':
    id(
      'cashFlowStatement.section.table.KassaflodesanalysInvesteringsverksamhetenAbstract.ForandringKassaflodeOvrigaKortfristigaPlaceringar.previousYear'
    ),
  'cashFlowStatement.KassaflodeInvesteringsverksamheten.year': id(
    'cashFlowStatement.section.table.KassaflodeInvesteringsverksamheten.year'
  ),
  'cashFlowStatement.KassaflodeInvesteringsverksamheten.previousYear': id(
    'cashFlowStatement.section.table.KassaflodeInvesteringsverksamheten.previousYear'
  ),
  'cashFlowStatement.NyemissionKassaflode.year': id(
    'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.NyemissionKassaflode.year'
  ),
  'cashFlowStatement.NyemissionKassaflode.previousYear': id(
    'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.NyemissionKassaflode.previousYear'
  ),
  'cashFlowStatement.ErhallnaAktieagartillskottKassaflode.year': id(
    'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.ErhallnaAktieagartillskottKassaflode.year'
  ),
  'cashFlowStatement.ErhallnaAktieagartillskottKassaflode.previousYear': id(
    'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.ErhallnaAktieagartillskottKassaflode.previousYear'
  ),
  'cashFlowStatement.ForandringKassaflodeErhallnaKoncernbidragFinansieringsverksamheten.year':
    id(
      'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.ForandringKassaflodeErhallnaKoncernbidragFinansieringsverksamheten.year'
    ),
  'cashFlowStatement.ForandringKassaflodeErhallnaKoncernbidragFinansieringsverksamheten.previousYear':
    id(
      'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.ForandringKassaflodeErhallnaKoncernbidragFinansieringsverksamheten.previousYear'
    ),
  'cashFlowStatement.ForandringKassaflodeLamnadeKoncernbidragFinansieringsverksamheten.year':
    id(
      'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.ForandringKassaflodeLamnadeKoncernbidragFinansieringsverksamheten.year'
    ),
  'cashFlowStatement.ForandringKassaflodeLamnadeKoncernbidragFinansieringsverksamheten.previousYear':
    id(
      'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.ForandringKassaflodeLamnadeKoncernbidragFinansieringsverksamheten.previousYear'
    ),
  'cashFlowStatement.UpptagnaLan.year': id(
    'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.UpptagnaLan.year'
  ),
  'cashFlowStatement.UpptagnaLan.previousYear': id(
    'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.UpptagnaLan.previousYear'
  ),
  'cashFlowStatement.AmorteringSkuld.year': id(
    'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.AmorteringSkuld.year'
  ),
  'cashFlowStatement.AmorteringSkuld.previousYear': id(
    'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.AmorteringSkuld.previousYear'
  ),
  'cashFlowStatement.KortfristigaFinansiellaSkulder.year': id(
    'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.KortfristigaFinansiellaSkulder.year'
  ),
  'cashFlowStatement.KortfristigaFinansiellaSkulder.previousYear': id(
    'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.KortfristigaFinansiellaSkulder.previousYear'
  ),
  'cashFlowStatement.UtbetaldUtdelningKassaflode.year': id(
    'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.UtbetaldUtdelningKassaflode.year'
  ),
  'cashFlowStatement.UtbetaldUtdelningKassaflode.previousYear': id(
    'cashFlowStatement.section.table.KassaflodesanalysFinansieringsverksamhetenAbstract.UtbetaldUtdelningKassaflode.previousYear'
  ),
  'cashFlowStatement.KassaflodeFinansieringsverksamheten.year': id(
    'cashFlowStatement.section.table.KassaflodeFinansieringsverksamheten.year'
  ),
  'cashFlowStatement.KassaflodeFinansieringsverksamheten.previousYear': id(
    'cashFlowStatement.section.table.KassaflodeFinansieringsverksamheten.previousYear'
  ),
  'cashFlowStatement.AretsKassaflode.year': id(
    'cashFlowStatement.section.table.AretsKassaflode.year'
  ),
  'cashFlowStatement.AretsKassaflode.previousYear': id(
    'cashFlowStatement.section.table.AretsKassaflode.previousYear'
  ),
  'cashFlowStatement.LikvidaMedelBorjan.year': id(
    'cashFlowStatement.section.table.LikvidaMedelBorjan.year'
  ),
  'cashFlowStatement.LikvidaMedelBorjan.previousYear': id(
    'cashFlowStatement.section.table.LikvidaMedelBorjan.previousYear'
  ),
  'cashFlowStatement.LikvidaMedelKursdifferens.year': id(
    'cashFlowStatement.section.table.LikvidaMedelKursdifferens.year'
  ),
  'cashFlowStatement.LikvidaMedelKursdifferens.previousYear': id(
    'cashFlowStatement.section.table.LikvidaMedelKursdifferens.previousYear'
  ),
  'cashFlowStatement.LikvidaMedelSlut.year': id(
    'cashFlowStatement.section.table.LikvidaMedelSlut.year'
  ),
  'cashFlowStatement.LikvidaMedelSlut.previousYear': id(
    'cashFlowStatement.section.table.LikvidaMedelSlut.previousYear'
  ),
});

/**
 * Placeholder view  for when page are being loaded
 */
import React from 'react';
import styled from '@emotion/styled';

import LoadingLogo from '_shared/components/LoadingLogo';
import { Backdrop as MuiBackdrop } from '@material-ui/core';

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Backdrop = styled(MuiBackdrop)`
  z-index: 100;
`;

interface LoadingPlaceholderProps {
  className?: string;
  overlay?: boolean;
}

const LoadingPlaceholder = ({
  className,
  overlay,
}: LoadingPlaceholderProps): JSX.Element => {
  if (overlay) {
    return (
      <Backdrop open>
        <LoadingLogo size="medium" />
      </Backdrop>
    );
  }
  return (
    <Wrapper className={className}>
      <LoadingLogo size="medium" />
    </Wrapper>
  );
};
export default LoadingPlaceholder;

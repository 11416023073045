import React, { useState, useMemo } from 'react';
import { Paper } from '@material-ui/core';
import styled from '@emotion/styled';
import { Description as DocumentIcon } from '@material-ui/icons';
import {
  isImageDocument,
  isPdfDocument,
} from '_reconciliation/components/ReconciliationView/HiddenRow/HiddenGroupRow/utils/documentTypes';

import LoadingLogo from '_shared/components/LoadingLogo';
import Overlay from './DocumentPreviewOverlay';
import PdfPreviewBase from './PdfPreview';

const ImageComponentRoot = styled(Paper)`
  position: relative;
  display: flex;
  margin: 1rem;
  min-width: 250px;
  max-width: 250px;
  height: 250px;
  flex-direction: column;
  align-items: flex-end;
  cursor: pointer;
`;

const ImageContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  .documentIcon {
    font-size: 5rem;
  }
`;

const LoadingContainer = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const Image = styled.img`
  width: 100%;
  padding: 4px;
`;

const PdfPreview = styled(PdfPreviewBase)`
  height: 100%;
  width: 100%;
`;

interface DocumentPreviewProps {
  id: string | number;
  document: {
    url: string;
    name: string;
  };
  errorMessage?: string;
  loading?: boolean;
  uploading?: boolean;
  extraOverlayContent?: JSX.Element;
  columnButtons?: boolean;
  className?: string;

  onOpenPreviewModal: (id: string | number) => void;
  onEditName?: (value: string) => Promise<void>;
  onDelete?: () => Promise<void>;

  deleteDisabled?: boolean;
  editNameDisabled?: boolean;
}

const DocumentPreview = ({
  id,
  document,
  errorMessage = '',
  loading = false,
  uploading = false,
  extraOverlayContent,
  columnButtons = false,
  className,
  onOpenPreviewModal,
  onEditName = async () => {},
  onDelete = async () => {},
  deleteDisabled = false,
  editNameDisabled = false,
}: DocumentPreviewProps): JSX.Element => {
  const [isDocumentOverlayShown, setIsDocumentOverlayShown] = useState(false);

  const [documentNameWithoutExtension, documentExtension] = useMemo(() => {
    const { name } = document;
    const dotIndex = name.lastIndexOf('.');

    if (dotIndex === -1) {
      return [name, ''];
    }

    return [name.substring(0, dotIndex), name.substring(dotIndex)];
  }, [document]);

  const [newDocumentName, setNewDocumentName] = useState(
    documentNameWithoutExtension
  );

  // HANDLERS

  const handleMouseEnter = () => {
    setIsDocumentOverlayShown(true);
  };

  const handleMouseLeave = () => {
    setIsDocumentOverlayShown(false);
  };

  const renderDocumentPreview = () => {
    if (isImageDocument(documentExtension)) {
      return <Image src={document.url} />;
    }
    if (isPdfDocument(documentExtension)) {
      return <PdfPreview src={document.url} />;
    }
    return <DocumentIcon className="documentIcon" color="primary" />;
  };

  return (
    <ImageComponentRoot
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      elevation={2}
      className={className}
    >
      {isDocumentOverlayShown && (
        <Overlay
          currentDocumentName={documentNameWithoutExtension}
          documentName={newDocumentName}
          onDocumentNameChange={setNewDocumentName}
          errorMessage={errorMessage}
          isLoading={loading}
          onOpenDocumentModal={() => onOpenPreviewModal(id)}
          onDeleteDocument={onDelete}
          onDocumentNameSave={onEditName}
          extraContent={extraOverlayContent}
          columnButtons={columnButtons}
          deleteDisabled={deleteDisabled}
          editNameDisabled={editNameDisabled}
        />
      )}

      <ImageContainer>{!uploading && renderDocumentPreview()}</ImageContainer>

      {!isDocumentOverlayShown && (loading || uploading) && (
        <LoadingContainer>
          <LoadingLogo />
        </LoadingContainer>
      )}
    </ImageComponentRoot>
  );
};

export default DocumentPreview;

export const SET_ACCOUNT_LIST_DRAWER_EXPANDED =
  'account-list-drawer/SET_ACCOUNT_LIST_DRAWER_EXPANDED';
export const SET_ACCOUNT_LIST_DRAWER_MODE =
  'account-list-drawer/SET_ACCOUNT_LIST_DRAWER_MODE';
export const ACCOUNT_LIST_DRAWER_ADD_ACCOUNT =
  'account-list-drawer/ACCOUNT_LIST_DRAWER_ADD_ACCOUNT';
export const ACCOUNT_LIST_DRAWER_REMOVE_ACCOUNT =
  'account-list-drawer/ACCOUNT_LIST_DRAWER_REMOVE_ACCOUNT';
export const ACCOUNT_LIST_DRAWER_CLEAR_SELECTION =
  'account-list-drawer/ACCOUNT_LIST_DRAWER_CLEAR_SELECTION';
export const ACCOUNT_LIST_DRAWER_SET_ACCOUNTS =
  'account-list-drawer/ACCOUNT_LIST_DRAWER_SET_ACCOUNTS';
export const ACCOUNT_LIST_DRAWER_SET_CURRENT_ACCOUNT =
  'account-list-drawer/ACCOUNT_LIST_DRAWER_SET_CURRENT_ACCOUNT';
export const ACCOUNT_LIST_DRAWER_SET_ACCOUNT_DESELECTED =
  'account-list-drawer/ACCOUNT_LIST_DRAWER_SET_ACCOUNT_DESELECTED';

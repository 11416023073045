import { asResultClass, getApiSdk, useApiSdk } from 'api-sdk';
import React from 'react';
import { useDispatch } from 'react-redux';
import { setUiStatus } from 'redux/actions/UI';
import { Awaited } from '@agoy/common';
import { useAsync } from 'utils/hooks';
import {
  addOrganisationInfo,
  setOrganisation,
} from '_organization/redux/actions';
import { OrganisationType } from '_organization/types';

type Sdk = Awaited<ReturnType<typeof getApiSdk>>;
type UpdateOrganisationParams = Awaited<
  Parameters<Sdk['updateOrganisation']>
>[0]['requestBody'];

interface ContextType {
  isDataLoading: boolean;
  isPersisting: boolean;
  data: OrganisationType | null;
  updateOrganisationLogo: (newLogo: File) => Promise<void>;
  updateOrganisationInfo: (params: UpdateOrganisationParams) => Promise<void>;
}

const Context = React.createContext<ContextType | undefined>(undefined);

export const OrganisationServiceProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const dispatch = useDispatch();
  const sdk = useApiSdk();

  const {
    data: organisation,
    status: fetchOrgStatus,
    error: fetchOrgError,
    run: runFetchOrg,
  } = useAsync<OrganisationType>();
  const {
    status: updateLogoStatus,
    error: updateLogoError,
    run: runUpdateLogo,
  } = useAsync();
  const {
    status: updateOrgInfoStatus,
    error: updateOrgInfoError,
    run: runUpdateOrgInfo,
  } = useAsync();

  const isDataLoading =
    (fetchOrgStatus === 'idle' || fetchOrgStatus === 'pending') &&
    (updateOrgInfoStatus === 'idle' || updateLogoStatus === 'idle');
  const isPersisting =
    fetchOrgStatus === 'pending' ||
    updateLogoStatus === 'pending' ||
    updateOrgInfoStatus === 'pending';

  /**
   * Fetch org information
   */
  const getOrganisation = React.useCallback(async () => {
    dispatch(setUiStatus({ fetchingOrganisation: true }));
    const result = await asResultClass(sdk.getOrganisation());
    dispatch(setUiStatus({ fetchingOrganisation: false }));

    if (result.ok) {
      dispatch(setOrganisation(result.val));
      return result.val as unknown as OrganisationType;
    }

    throw new Error('Issues loading organisation');
  }, [sdk, dispatch]);

  React.useEffect(() => {
    runFetchOrg(getOrganisation()).catch(console.error);

    return () => {
      // perform cleanup if needed
    };
  }, []);

  const updateOrganisationLogo = React.useCallback<
    ContextType['updateOrganisationLogo']
  >(
    async (newLogo) => {
      await runUpdateLogo(
        sdk.updateOrganisationLogo({ requestBody: newLogo })
      ).catch(console.error);
      await runFetchOrg(getOrganisation()).catch(console.error);
    },
    [getOrganisation, runFetchOrg, runUpdateLogo, sdk]
  );

  const updateOrganisationInfo = React.useCallback<
    ContextType['updateOrganisationInfo']
  >(
    async (newInfo) => {
      try {
        const result = await asResultClass(
          sdk.updateOrganisation({
            requestBody: newInfo,
          })
        );

        if (result.ok) {
          dispatch(addOrganisationInfo(newInfo));
        } else {
          throw Error(result.val.message);
        }
      } finally {
        await runFetchOrg(getOrganisation()).catch(console.error);
      }
    },
    [sdk, dispatch, getOrganisation, runFetchOrg, runUpdateOrgInfo, sdk]
  );

  const value = React.useMemo(
    () => ({
      isDataLoading,
      isPersisting,
      data: organisation,
      updateOrganisationLogo,
      updateOrganisationInfo,
    }),
    [
      isDataLoading,
      isPersisting,
      organisation,
      updateOrganisationLogo,
      updateOrganisationInfo,
    ]
  );

  if (fetchOrgStatus === 'rejected')
    return <>Error fetching the org information: {fetchOrgError.message}</>;

  if (updateOrgInfoStatus === 'rejected')
    return <>Error updating org information: {updateOrgInfoError.message}</>;

  if (updateLogoStatus === 'rejected')
    return <>Error updating the logo: {updateLogoError.message}</>;

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

/**
 * Only to be used for tests, for example in storybook
 */
export const OrganisationServiceProviderTest = ({
  children,
  value,
}: {
  children: React.ReactNode;
  value: ContextType;
}) => {
  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useOrganisationService = () => {
  const context = React.useContext(Context);
  if (context === undefined) {
    throw new Error(
      'useOrganisationService must be used within a OrganisationService context provider'
    );
  }
  return context;
};

import React, { useState, useEffect } from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CloseIcon from '@material-ui/icons/Close';
import { Modal as MuiModal, IconButton, Paper } from '@material-ui/core';
import styled from '@emotion/styled';
import { fetchBlobAndValidate } from 'utils/fileUtils';

import DisplayImage from './DisplayImage';

const isImageFile = (fileContent: string): boolean => {
  return fileContent.startsWith('data:image');
};

const isPdfFile = (fileContent: string): boolean => {
  return fileContent.startsWith('data:application/pdf');
};

const InformationContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  column-gap: ${(props) => props.theme.spacing(2)}px;
`;

const Information = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  padding: 1rem;
  align-items: center;
`;

const Modal = styled(MuiModal)`
  display: flex;
  width: 100vw;
  height: 100vh;
  padding: ${(props) => props.theme.spacing(4)}px;
  backdrop-filter: blur(5px);
`;

const Column = styled.div`
  width: 25%;
  height: 100%;
`;

const LeftColumn = styled(Column)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: ${(props) => props.theme.spacing(4)}px;
`;

const MiddleColumn = styled(Column)`
  flex: 1;
  padding-top: ${(props) => props.theme.spacing(4)}px;
`;

const RightColumn = styled(Column)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: ${(props) => props.theme.spacing(4)}px;
`;

const StepRecordArrow = styled(IconButton)`
  background: ${(props) => props.theme.palette.common.white};
  font-size: 2.5rem;
  :hover {
    background: ${(props) => props.theme.palette.common.white};
    color: ${(props) => props.theme.palette.secondary.main};
  }
`;

const CloseModal = styled.div`
  margin-left: ${(props) => props.theme.spacing(2)}px;
  > * {
    background: ${(props) => props.theme.palette.common.white};
    &:hover {
      background: ${(props) => props.theme.palette.common.white};
    }
  }
`;

interface Props {
  fileContent: string;
  fileName: string;
  information: any;
  isOpen: boolean;
  onClose: () => void;
  onStepBackwards?: () => void;
  onStepForward?: () => void;
}

const PreviewFileModal = ({
  fileContent,
  fileName,
  information,
  isOpen,
  onClose,
  onStepBackwards,
  onStepForward,
}: Props): JSX.Element => {
  // STATE
  const [fileSrc, setFileSrc] = useState<string>('');
  useEffect(() => {
    fetchBlobAndValidate(fileContent).then((res) => {
      if (res.ok) {
        const url = window.URL.createObjectURL(res.val.blob);
        setFileSrc(url);
      }
    });
  }, [fileContent]);

  const isImage = isImageFile(fileContent);
  const isPdf = isPdfFile(fileContent);

  return (
    <Modal open={isOpen} onClose={onClose}>
      <>
        <LeftColumn>
          {onStepBackwards && (
            <StepRecordArrow disableRipple onClick={onStepBackwards}>
              <ArrowBackIcon fontSize="large" />
            </StepRecordArrow>
          )}
        </LeftColumn>
        <MiddleColumn>
          <InformationContainer>
            {information && <Information>{information}</Information>}
            <CloseModal>
              <IconButton disableRipple onClick={onClose}>
                <CloseIcon fontSize="large" />
              </IconButton>
            </CloseModal>
          </InformationContainer>
          {fileSrc && (
            <Paper elevation={2}>
              {isImage && <DisplayImage src={fileSrc} />}
              {isPdf && (
                <iframe
                  title="pdf"
                  style={{ height: '80vh', width: '100%' }}
                  src={fileSrc}
                />
              )}
            </Paper>
          )}
        </MiddleColumn>
        <RightColumn>
          {onStepForward && (
            <StepRecordArrow onClick={onStepForward}>
              <ArrowForwardIcon fontSize="large" />
            </StepRecordArrow>
          )}
        </RightColumn>
      </>
    </Modal>
  );
};

export default PreviewFileModal;

import React from 'react';
import { cloneDeep } from 'lodash';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';
import { useSelector } from 'redux/reducers';
import { numberValue, stringValue } from '@agoy/document';
import { FinancialReport } from '@agoy/financial-report-document';

import { parse, sub, differenceInMonths, startOfMonth } from 'date-fns';
import { currentClientYear } from '_reconciliation/redux/accounting-view/selectors';

import {
  AnnualReportChanges,
  IncomeStatement,
} from '@agoy/annual-report-document';

import Typography from '@material-ui/core/Typography';
import {
  formatChartTitleInThousands,
  formatTextInThousands,
  formatDataInThousands,
  ReportColors,
  annualReportIncomeChanged,
  calcMoveAccounts,
  applyRowsChanges,
} from '_financial-report/utils';

import EditableTextBlock from '../../UI/EditableTextBlock';
import BarChart from '../../UI/BarChart';
import OrganisationLogo from '../../UI/OrganisationLogo';
import {
  getComparePeriodData,
  getPeriodData,
  getYearData,
  prepareChart2Title,
  prepareKeyValue,
  prepareNote1,
  prepareNote2Period,
  prepareNote2Year,
  prepareSalesExpensesChange,
  prepareSalesGrossExpensesChange,
  prepareSalesPersonalExpensesChange,
} from './utils';

type ReportColorsProps = {
  customColor: string | undefined;
};

const A4Page = styled.div`
  position: relative;
  display: flex;
  height: 960px;
  width: 1400px;
`;

const Subheader = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'customColor',
})<ReportColorsProps>`
  margin: 0 0 20px 5px;
  font-size: 2rem;
  font-weight: bold;
  color: ${(props) => props.customColor || props.theme.palette.text.primary};
`;

const LeftBlock = styled('div', {
  shouldForwardProp: (prop) => prop !== 'customColor',
})<ReportColorsProps>`
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 50%;
  height: 100%;
  background-color: ${(props) =>
    props.customColor ||
    props.theme.palette.financialReportView.accentBackground};
`;

const RightBlock = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 50%;
  height: 100%;
`;

const ChartContainer = styled.div`
  height: 465px;
  overflow: hidden;
  justify-self: end;
`;

interface RRBRDiagramPageProps {
  diagramRRBR: FinancialReport['diagramRRBR'];
  reportColors: ReportColors;
  settings: FinancialReport['settings'];
  incomeStatement: IncomeStatement;
  annualReportChanges: AnnualReportChanges | undefined;
}

const RRBRDiagramPreview = ({
  diagramRRBR,
  reportColors,
  settings,
  incomeStatement,
  annualReportChanges,
}: RRBRDiagramPageProps): JSX.Element => {
  const { formatMessage } = useIntl();

  const title = formatMessage({ id: 'financialreport.RRBRDiagram' });

  const accountingBalances = useSelector(
    currentClientYear((state) => state.accountingBalances)
  );
  const { currentPeriod, currentCustomer, currentFinancialYear } = useSelector(
    (state) => state.customerView
  );
  const { givenName, familyName } = useSelector((state) => state.user);

  const customers = useSelector((state) => state.customers);
  if (!currentCustomer) {
    return <></>;
  }

  let userName;
  if (givenName && familyName) {
    userName = `${givenName} ${familyName}`;
  }
  const customerName = customers[currentCustomer].name;
  if (!currentPeriod) {
    return <></>;
  }
  if (!currentFinancialYear) {
    return <></>;
  }
  const [start] = currentFinancialYear.split('-');
  // calclulate current financial year start
  const periodDate = parse(currentPeriod, 'yyyyMMdd', new Date());
  const currentYear = parse(start, 'yyyyMMdd', new Date());
  const prevDate = sub(periodDate, { years: 1 });

  const periodCount =
    differenceInMonths(periodDate, startOfMonth(currentYear)) + 1;
  const prevYear = sub(currentYear, { years: 1 });

  const previousResult = diagramRRBR?.section.resultPrevious.rows;
  let currentResult = cloneDeep(diagramRRBR?.section.resultCurrent.rows);
  let previousYearDataDiagram = getYearData(
    prevYear,
    periodCount,
    previousResult,
    false
  );
  let rowChanges;
  // check if   accounts order in annual report was changed. May be need recalculate row in table
  if (
    incomeStatement &&
    annualReportChanges &&
    annualReportIncomeChanged(annualReportChanges)
  ) {
    rowChanges = calcMoveAccounts(incomeStatement.section.table);
  }
  if (accountingBalances && rowChanges) {
    const result = applyRowsChanges(
      diagramRRBR?.section.resultCurrent.columns,
      currentResult,
      accountingBalances,
      rowChanges
    );
    currentResult = result.rows;
  }
  let currentYearDataDiagram = getYearData(
    currentYear,
    periodCount,
    currentResult,
    true
  );

  let previousPeriodDataDiagram = getComparePeriodData(
    prevYear,
    prevDate,
    numberValue(settings.section.comparePeriod) || 1,
    previousResult
  );
  let currentPeriodDataDiagram = getComparePeriodData(
    currentYear,
    periodDate,
    numberValue(settings.section.comparePeriod) || 1,
    currentResult
  );

  /* Get template text. In case of disable random text alway return first */
  let note1 = settings.section.isTextRandomizationEnabled
    ? stringValue(diagramRRBR.section.note1) || ''
    : stringValue(diagramRRBR.section.note1Static) || '';
  let note2 = settings.section.isTextRandomizationEnabled
    ? stringValue(diagramRRBR.section.note2) || ''
    : stringValue(diagramRRBR.section.note2Static) || '';
  let note3 = stringValue(diagramRRBR.section.note3) || '';
  userName = stringValue(settings?.section.reportPerson) || userName || '';

  let currentPeriodDataToNote = currentPeriodDataDiagram;
  let previousPeriodDataToNote = previousPeriodDataDiagram;
  let currentYearDataToNote = currentYearDataDiagram;
  let previousYearDataToNote = previousYearDataDiagram;

  let chart1Title = stringValue(diagramRRBR.section.charter1) || '';
  let chart2Title = stringValue(diagramRRBR.section.charter2) || '';
  chart2Title = prepareChart2Title(
    chart2Title,
    currentFinancialYear,
    currentPeriod,
    numberValue(settings.section.comparePeriod) || 1
  );

  if (settings.section.formatDiagramValuesInThousands) {
    currentYearDataDiagram = formatDataInThousands(currentYearDataDiagram);
    previousYearDataDiagram = formatDataInThousands(previousYearDataDiagram);

    previousPeriodDataDiagram = formatDataInThousands(
      previousPeriodDataDiagram
    );
    currentPeriodDataDiagram = formatDataInThousands(currentPeriodDataDiagram);

    chart1Title = formatChartTitleInThousands(chart1Title);
    chart2Title = formatChartTitleInThousands(chart2Title);
  }

  let keyValue = prepareKeyValue(
    diagramRRBR,
    numberValue(settings.section.keyValuesPercent) || 0,
    numberValue(settings.section.keyValuesType) || 1
  );

  if (settings.section.formatTextValuesInThousands) {
    currentPeriodDataToNote = formatDataInThousands(currentPeriodDataToNote);
    previousPeriodDataToNote = formatDataInThousands(previousPeriodDataToNote);
    currentYearDataToNote = formatDataInThousands(currentYearDataToNote);
    previousYearDataToNote = formatDataInThousands(previousYearDataToNote);
    keyValue /= 1000;
  }
  /* Insert report user */
  note1 = prepareNote1(
    note1,
    userName,
    customerName,
    periodDate,
    numberValue(settings.section.comparePeriod) || 1,
    currentFinancialYear
  );
  /* Insert period comapre data */
  note2 = prepareNote2Period(
    note2,
    periodDate,
    currentPeriodDataToNote,
    previousPeriodDataToNote,
    numberValue(settings.section.comparePeriod) || 1,
    currentFinancialYear
  );
  /* Insert year comapre data */
  note2 = prepareNote2Year(
    note2,
    periodDate,
    currentYearDataToNote,
    previousYearDataToNote
  );
  /* Note 3 part 1. Convert in report text */
  if (note3 === '$note3_Reset_State$') {
    note3 = prepareSalesExpensesChange(
      keyValue,
      currentYearDataToNote,
      previousYearDataToNote,
      stringValue(diagramRRBR.section.query1) || '',
      stringValue(diagramRRBR.section.query4) || ''
    );
    /* Note 3 part 2. Convert in report text */
    note3 += prepareSalesGrossExpensesChange(
      keyValue,
      currentYearDataToNote,
      previousYearDataToNote,
      stringValue(diagramRRBR.section.query2) || '',
      stringValue(diagramRRBR.section.query5) || ''
    );
    /* Note 3 part 3. Convert in report text */
    note3 += prepareSalesPersonalExpensesChange(
      keyValue,
      currentYearDataToNote,
      previousYearDataToNote,
      stringValue(diagramRRBR.section.query3) || '',
      stringValue(diagramRRBR.section.query6) || ''
    );
  }
  /* Format amount in thousands inside text */
  if (settings.section.formatTextValuesInThousands) {
    note1 = formatTextInThousands(note1);
    note2 = formatTextInThousands(note2);
    note3 = formatTextInThousands(note3);
  }
  return (
    <A4Page>
      <LeftBlock customColor={reportColors.background}>
        <Subheader variant="h3" customColor={reportColors.textSecondary}>
          {title}
        </Subheader>
        <EditableTextBlock
          fieldId="diagramRRBR.section.note1"
          defaultText={note1}
          isEditing={false}
          styles={{ height: '64px', color: reportColors.textSecondary }}
          print
        />
        <EditableTextBlock
          fieldId="diagramRRBR.section.note2"
          defaultText={note2}
          isEditing={false}
          styles={{ height: '172px', color: reportColors.textSecondary }}
          print
        />
        <EditableTextBlock
          fieldId="diagramRRBR.section.charter1"
          defaultText={chart1Title}
          isEditing={false}
          styles={{
            height: '50px',
            border: 'none',
            padding: '5px 5px 0',
            color: reportColors.textMain,
          }}
          print
        />
        <ChartContainer>
          <BarChart
            currentYearData={currentYearDataDiagram}
            previousYearData={previousYearDataDiagram}
            height={465}
            print
          />
        </ChartContainer>
      </LeftBlock>
      <RightBlock>
        <EditableTextBlock
          fieldId="diagramRRBR.section.charter2"
          defaultText={chart2Title}
          isEditing={false}
          styles={{
            height: '35px',
            border: 'none',
            padding: '5px',
            color: reportColors.textMain,
          }}
          print
        />
        <ChartContainer>
          <BarChart
            currentYearData={currentPeriodDataDiagram}
            previousYearData={previousPeriodDataDiagram}
            height={465}
            print
          />
        </ChartContainer>
        <EditableTextBlock
          fieldId="diagramRRBR.section.note3"
          defaultText={note3}
          isEditing={false}
          styles={{ height: '315px' }}
          print
        />
      </RightBlock>
      <OrganisationLogo print />
    </A4Page>
  );
};

export default RRBRDiagramPreview;

import { value } from '@agoy/document';
import { NoteInvertedTwoTableSection, IxbrlCell } from '../../../../common';
import { sumNotesRowsById } from '../../../../common/utils/accountSumsUtil';
import { year, previousYear } from '../../../../common/utils/date-util';
import {
  field,
  ixbrlCell,
  ixbrlField,
  range,
  table,
  tupleRef,
} from '../../../../common/utils/util';

const MONETARY_ITEM_TYPE = 'monetaryItemType';

export const getNote16 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null,
  documentTypeVersion = '1'
): NoteInvertedTwoTableSection => {
  return {
    active: false,
    number: value(undefined),
    name: value('Andelar i koncernföretag'),
    // second table comes first
    secondTable: table<IxbrlCell>(
      'notes.note16.secondTable',
      { id: 'label', label: '' },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      .addRows((rows) =>
        rows
          // 'ingående' = previous period outgoing balance
          .addRow(
            '1',
            noteLabel('Ingående anskaffningsvärden'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndelarKoncernforetagAnskaffningsvarden',
              contextRef: 'balans1',
              saldo: 'debit',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndelarKoncernforetagAnskaffningsvarden',
              contextRef: 'balans2',
              saldo: 'debit',
            })
          )
          .addRow(
            '2',
            noteLabel('Inköp'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndelarKoncernforetagForandringAnskaffningsvardenInkop',
              contextRef: 'period0',
              standardRubrik:
                'Ökning av anskaffningsvärden i samband med inköp av andelar i koncernföretag',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndelarKoncernforetagForandringAnskaffningsvardenInkop',
              contextRef: 'period1',
              standardRubrik:
                'Ökning av anskaffningsvärden i samband med inköp av andelar i koncernföretag',
            })
          )
          .addRow(
            'anskaffningsvardenFusion',
            noteLabel('Fusion'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndelarKoncernforetagForandringAnskaffningsvardenFusion',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden för andelar i koncernföretag genom fusion',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndelarKoncernforetagForandringAnskaffningsvardenFusion',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden för andelar i koncernföretag genom fusion',
            })
          )
          .addRow(
            '3',
            noteLabel('Försäljningar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndelarKoncernforetagForandringAnskaffningsvardenForsaljningar',
              contextRef: 'period0',
              standardRubrik:
                'Minskning av anskaffningsvärden i samband med försäljningar av andelar i koncernföretag',
              negateValue: true,
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndelarKoncernforetagForandringAnskaffningsvardenForsaljningar',
              contextRef: 'period1',
              standardRubrik:
                'Minskning av anskaffningsvärden i samband med försäljningar av andelar i koncernföretag',
              negateValue: true,
            })
          )
          .addRow(
            'shareholderContributions',
            noteLabel('Lämnade aktieägartillskott'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndelarKoncernforetagForandringAnskaffningsvardenLamnadeAktieagartillskott',
              contextRef: 'period0',
              standardRubrik:
                'Ökning av anskaffningsvärden i samband lämnande aktieägartillskott till koncernföretag',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndelarKoncernforetagForandringAnskaffningsvardenLamnadeAktieagartillskott',
              contextRef: 'period1',
              standardRubrik:
                'Ökning av anskaffningsvärden i samband lämnande aktieägartillskott till koncernföretag',
            })
          )
          .addRow(
            'repaymentShareholderContributions',
            noteLabel('Återbetalning av lämnat aktieägartillskott'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndelarKoncernforetagForandringAnskaffningsvardenAterbetalningLamnatAktieagartillskott',
              contextRef: 'period0',
              standardRubrik:
                'Minskning av anskaffningsvärden i samband återbetalning av lämnat aktieägartillskott till koncernföretag',
              negateValue: true,
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndelarKoncernforetagForandringAnskaffningsvardenAterbetalningLamnatAktieagartillskott',
              contextRef: 'period1',
              negateValue: true,
            })
          )
          .addRow(
            'yearProfit',
            noteLabel('Årets resultatandel'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndelarKoncernforetagForandringAnskaffningsvardenAretsResultatAndel',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden i samband periodens resultatandel i handelsbolag som är koncernföretag',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndelarKoncernforetagForandringAnskaffningsvardenAretsResultatAndel',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden i samband periodens resultatandel i handelsbolag som är koncernföretag',
            })
          )
          .addRow(
            '4',
            noteLabel('Omklassificeringar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndelarKoncernforetagForandringAnskaffningsvardenOmklassificeringar',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden i samband med omklassificeringar av andelar i koncernföretag',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndelarKoncernforetagForandringAnskaffningsvardenOmklassificeringar',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden i samband med omklassificeringar av andelar i koncernföretag',
            })
          )
          .addRow(
            'anskaffningsvardenConversionDifferences',
            noteLabel('Omräkningsdifferenser'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndelarKoncernforetagForandringAnskaffningsvardenOmrakningsdifferenser',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden i samband med omräkningsdifferenser av andelar i koncernföretag',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndelarKoncernforetagForandringAnskaffningsvardenOmrakningsdifferenser',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden i samband med omräkningsdifferenser av andelar i koncernföretag',
            })
          )
          .addRow(
            '5',
            noteLabel('Utgående anskaffningsvärden'),
            ixbrlCell(
              sumNotesRowsById(
                16,
                [
                  ...range(1, 5),
                  'anskaffningsvardenFusion',
                  'shareholderContributions',
                  'repaymentShareholderContributions',
                  'yearProfit',
                  'anskaffningsvardenConversionDifferences',
                ],
                'year',
                'secondTable'
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:AndelarKoncernforetagAnskaffningsvarden',
                contextRef: 'balans0',
                saldo: 'debit',
              }
            ),
            ixbrlCell(
              sumNotesRowsById(
                16,
                [
                  ...range(1, 5),
                  'anskaffningsvardenFusion',
                  'shareholderContributions',
                  'repaymentShareholderContributions',
                  'yearProfit',
                  'anskaffningsvardenConversionDifferences',
                ],
                'previousYear',
                'secondTable'
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:AndelarKoncernforetagAnskaffningsvarden',
                contextRef: 'balans1',
                saldo: 'debit',
              }
            )
          )
          .addRow(
            '6',
            noteLabel('Ingående nedskrivningar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndelarKoncernforetagNedskrivningar',
              contextRef: 'balans1',
              saldo: 'credit',
              negateValue: true,
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndelarKoncernforetagNedskrivningar',
              contextRef: 'balans2',
              saldo: 'credit',
              negateValue: true,
            })
          )
          .addRow(
            'nedskrivningarFusion',
            noteLabel('Fusion'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndelarKoncernforetagForandringNedskrivningarFusion',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av nedskrivningar för andelar i koncernföretag genom fusion',
              negateValue: true,
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndelarKoncernforetagForandringNedskrivningarFusion',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av nedskrivningar för andelar i koncernföretag genom fusion',
              negateValue: true,
            })
          )
          .addRow(
            '7',
            noteLabel('Försäljningar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndelarKoncernforetagForandringNedskrivningarAterfordaNedskrivningarForsaljningar',
              contextRef: 'period0',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndelarKoncernforetagForandringNedskrivningarAterfordaNedskrivningarForsaljningar',
              contextRef: 'period1',
            })
          )
          .addRow(
            '8',
            noteLabel('Återförda nedskrivningar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndelarKoncernforetagForandringNedskrivningarAterfordaNedskrivningar',
              contextRef: 'period0',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndelarKoncernforetagForandringNedskrivningarAterfordaNedskrivningar',
              contextRef: 'period1',
            })
          )
          .addRow(
            '9',
            noteLabel('Omklassificeringar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndelarKoncernforetagForandringNedskrivningarAretsOmklassificeringar',
              contextRef: 'period0',
              standardRubrik:
                'Minskning (ökning) av nedskrivningar i samband med omklassificeringar av andelar i koncernföretag',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndelarKoncernforetagForandringNedskrivningarAretsOmklassificeringar',
              contextRef: 'period1',
              standardRubrik:
                'Minskning (ökning) av nedskrivningar i samband med omklassificeringar av andelar i koncernföretag',
            })
          )
          .addRow(
            '10',
            noteLabel('Årets nedskrivningar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndelarKoncernforetagForandringNedskrivningarAretsNedskrivningar',
              contextRef: 'period0',
              negateValue: true,
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndelarKoncernforetagForandringNedskrivningarAretsNedskrivningar',
              contextRef: 'period1',
              negateValue: true,
            })
          )
          .addRow(
            'nedskrivningarConversionDifferences',
            noteLabel('Omräkningsdifferenser'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndelarKoncernforetagForandringNedskrivningarAretsOmrakningsdifferenser',
              contextRef: 'period0',
              standardRubrik:
                'Minskning (ökning) av nedskrivningar i samband med omräkningsdifferenser av andelar i koncernföretag',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndelarKoncernforetagForandringNedskrivningarAretsOmrakningsdifferenser',
              contextRef: 'period1',
              standardRubrik:
                'Minskning (ökning) av nedskrivningar i samband med omräkningsdifferenser av andelar i koncernföretag',
            })
          )
          .addRow(
            '11',
            noteLabel('Utgående nedskrivningar'),
            ixbrlCell(
              sumNotesRowsById(
                16,
                [
                  ...range(6, 11),
                  'nedskrivningarFusion',
                  'nedskrivningarConversionDifferences',
                ],
                'year',
                'secondTable'
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:AndelarKoncernforetagNedskrivningar',
                contextRef: 'balans0',
                saldo: 'credit',
                negateValue: true,
              }
            ),
            ixbrlCell(
              sumNotesRowsById(
                16,
                [
                  ...range(6, 11),
                  'nedskrivningarFusion',
                  'nedskrivningarConversionDifferences',
                ],
                'previousYear',
                'secondTable'
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:AndelarKoncernforetagNedskrivningar',
                contextRef: 'balans1',
                saldo: 'credit',
                negateValue: true,
              }
            )
          )
          .addRow(
            '12',
            noteLabel('Redovisat värde'),
            ixbrlCell(sumNotesRowsById(16, [5, 11], 'year', 'secondTable'), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AndelarKoncernforetag',
              contextRef: 'balans0',
              saldo: 'debit',
            }),
            ixbrlCell(
              sumNotesRowsById(16, [5, 11], 'previousYear', 'secondTable'),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:AndelarKoncernforetag',
                contextRef: 'balans1',
                saldo: 'debit',
              }
            )
          )
          .build()
      )
      .newRowTemplate(value(''), value(undefined), value(undefined))
      .build(),
    table: table<IxbrlCell>(
      'notes.note16.table',
      { id: 'companyName', label: 'Företagets namn' },
      {
        id: 'organisationNumber',
        label: 'Organisationsnummer',
      },
      {
        id: 'place',
        label: 'Säte',
      },
      {
        id: 'capitalShare',
        label: 'Kapitalandel (%)',
      }
    )
      .addRows((rows) =>
        rows
          .addRow(
            '1',
            ixbrlCell(value(''), {
              type: 'stringItemType',
              name: 'se-cd-base:ForetagetsNamn',
              contextRef: 'period0',
              tuple: {
                name: 'se-gaap-ext:InnehavAndelarKoncernforetagInklusiveUppgiftForetagTuple',
                id: `${rows.getBaseId()}.1.tuple`,
                tupleID: `${rows.getBaseId()}.1.tuple`,
              },
              tupleRef: tupleRef(1, `${rows.getBaseId()}.1.tuple`),
            }),
            ixbrlCell(value(''), {
              type: 'stringItemType',
              name: 'se-cd-base:Organisationsnummer',
              contextRef: 'period0',
              tupleRef: tupleRef(2, `${rows.getBaseId()}.1.tuple`),
            }),
            ixbrlCell(value(''), {
              type: 'stringItemType',
              name: 'se-cd-base:ForetagetsSate',
              contextRef: 'period0',
              tupleRef: tupleRef(3, `${rows.getBaseId()}.1.tuple`),
            }),
            ixbrlCell(value(undefined), {
              type: 'pureItemType',
              name: 'se-gen-base:InnehavAndelarKoncernforetagKapitalandel',
              contextRef: 'balans0',
              tupleRef: tupleRef(7, `${rows.getBaseId()}.1.tuple`),
            })
          )
          .build()
      )
      .newRowTemplateGenerator((id, baseId) => ({
        id,
        active: true,
        cells: {
          companyName: ixbrlCell(value(''), {
            type: 'stringItemType',
            name: 'se-cd-base:ForetagetsNamn',
            contextRef: 'period0',
            tuple: {
              name: 'se-gaap-ext:InnehavAndelarKoncernforetagInklusiveUppgiftForetagTuple',
              id: `${baseId}.${id}.tuple`,
              tupleID: `${baseId}.${id}.tuple`,
            },
            tupleRef: tupleRef(1, `${baseId}.${id}.tuple`),
          }),
          organisationNumber: ixbrlCell(value(''), {
            type: 'stringItemType',
            name: 'se-cd-base:Organisationsnummer',
            contextRef: 'period0',
            tupleRef: tupleRef(2, `${baseId}.${id}.tuple`),
          }),
          place: ixbrlCell(value(''), {
            type: 'stringItemType',
            name: 'se-cd-base:ForetagetsSate',
            contextRef: 'period0',
            tupleRef: tupleRef(3, `${baseId}.${id}.tuple`),
          }),
          capitalShare: ixbrlCell(value(undefined), {
            type: 'pureItemType',
            name: 'se-gen-base:InnehavAndelarKoncernforetagKapitalandel',
            contextRef: 'balans0',
            tupleRef: tupleRef(7, `${baseId}.${id}.tuple`),
          }),
        },
      }))
      .build(),
    commentLabel: field('Kommentar'),
    comment: ixbrlField(field(''), {
      type: 'stringItemType',
      name: 'se-gen-base:NotAndelarKoncernforetagKommentar',
      contextRef: 'period0',
    }),
    group: value(undefined),
    section: value(undefined),
    validForReportType: {
      k2: true,
      k3: documentTypeVersion === '1',
    },
  };
};

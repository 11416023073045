import { AgoyTable } from '../table';
import { TableChange } from '../changes';
import { AgoyDocumentStructure } from '../document';
import updateTable from './helpers/updateTable';
import updateDocument from './helpers/updateDocument';
import { OperationResult, State } from './types';

const updateTableColumnActive = <T extends AgoyDocumentStructure>(
  structure: T,
  state: State<T>,
  id: string,
  active: boolean
): OperationResult<T> => {
  return updateDocument(structure, state, id, {
    table: (key, tableId, props) => {
      if (!key || key.length !== 1) {
        return props;
      }
      const [columnId] = key;

      const update = updateTable(
        props.node,
        props.changes,
        (table: AgoyTable, change: TableChange) => {
          const column = table.columns.find((col) => col.id === columnId);
          if (!column) {
            return 'INVALID_ID';
          }

          const newTable = {
            ...table,
            columns: table.columns.map((col) =>
              col.id === columnId ? { ...col, active } : col
            ),
          };

          if (change.columns) {
            const existingColumnChange = change.columns?.find(
              (col) => col.id === columnId
            );
            if (existingColumnChange) {
              return [
                newTable,
                {
                  ...change,
                  columns: change.columns.map((col) =>
                    col.id === columnId ? { ...col, active } : col
                  ),
                },
              ];
            }
            return [
              newTable,
              {
                ...change,
                columns: [
                  ...change.columns,
                  { type: 'update', id: columnId, active },
                ],
              },
            ];
          }
          return [
            newTable,
            { ...change, columns: [{ type: 'update', id: columnId, active }] },
          ];
        }
      );

      if (update) {
        if (Array.isArray(update)) {
          return {
            ...props,
            node: update[0],
            changes: update[1],
          };
        }
        return { ...props, error: update };
      }
      return props;
    },
  });
};

export default updateTableColumnActive;

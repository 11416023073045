/*
 * This is a GENERATED file, do not change manually!
 * Generated:
 * - from file k3-arsredovisning-2021-10-31-rev20230131 - Noter.csv
 * - Comments: AGOY-6860 (former Note 95)
 */

import {
  value,
  AgoyDocumentPart,
  SelectFormat,
} from '@agoy/document';
import { IxbrlCell } from '../../../../../common';
import {
  field,
  ixbrlCell,
  ixbrlField,
  table,
  tupleRef,
} from '../../../../../common/utils/util';



const cell = { type: 'field' as const };
const boolean = { type: 'boolean' as const };
const type = { type: 'type' as const };
const number = { type: 'number' as const };

const OvrigaAvsattningarList: SelectFormat = {
  type: 'select',
  options: [
    {
      value: 'se-mem-base:AvsattningarGarantiatagandenMember',
      label: 'Garantiåtaganden',
    },
    {
      value: 'se-mem-base:AvsattningarErsattningAnstalldaMember',
      label: 'Ersättning till anställda',
    },
    {
      value: 'se-mem-base:AvsattningarForlustkontraktMember',
      label: 'Förlustkontrakt',
    },
    {
      value: 'se-mem-base:AvsattningarOmstruktureringsatgarderMember',
      label: 'Omstruktureringsåtgärder',
    },
    {
      value: 'se-mem-base:AvsattningarTvisterMember',
      label: 'Tvister',
    },
  ],
};

export const getNote2074K3v2 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null
): AgoyDocumentPart<typeof note2074K3v2ContentDefinition> => ({
  active: false,
  number: value(undefined),
  name: noteLabel('Övriga avsättningar'),
  data: {
    active: true,
    OvrigaAvsattningarSpecifikationTuple: {
      type: 'group',
      active: true,
      level: 0,
      title: field('Specifikation av övriga avsättningar'),
      table: table<IxbrlCell>(
        'notes.note74.data.OvrigaAvsattningarSpecifikationTuple.table',
        {
          id: 'OvrigaAvsattningarList',
          label: '',
          dataType: 'text',
          active: true,
        },
        {
          id: 'OvrigaAvsattningarSpecifikationPeriodensAvsattningar',
          label: 'Årets avsättningar',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'OvrigaAvsattningarSpecifikationJusteringForandringNuvardet',
          label: 'Justeringar till följd av förändring av nuvärdet',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'OvrigaAvsattningarSpecifikationOvrigaVardeforandringar',
          label: 'Övriga värdeförändringar',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'OvrigaAvsattningarSpecifikationRorelseforvarv',
          label: 'Rörelseförvärv',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'OvrigaAvsattningarSpecifikationFusion',
          label: 'Fusion',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'OvrigaAvsattningarSpecifikationOmklassificeringar',
          label: 'Omklassificeringar',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'OvrigaAvsattningarSpecifikationOmrakningsdifferenser',
          label: 'Omräkningsdifferenser',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'OvrigaAvsattningarSpecifikationIanspraktagnaBelopp',
          label: 'Under året ianspråktagna belopp',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'OvrigaAvsattningarSpecifikationAterfordaBelopp',
          label: 'Under året återförda belopp',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'OvrigaAvsattningarSpecifikationBelopp',
          label: 'Redovisat värde',
          dataType: 'numeric',
          active: true,
        }
      )
        .addRows((rows) => {
          rows
            .addRowWithType(
              'OvrigaAvsattningarSpecifikationTuple',
              'hidden',
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined
            )
            .addSubRows((rows) => {
              rows.addRowWithType(
                '1',
                'row',
                ixbrlCell(
                  {
                    type: 'string',
                    value: OvrigaAvsattningarList.options[0].value,
                    format: OvrigaAvsattningarList,
                  },
                  {
                    type: 'enumerationItemType',
                    name: 'se-gen-base:OvrigaAvsattningarList',
                    contextRef: 'period0',
                    standardRubrik: 'Typ av övrig avsättning',
                    negateValue: false,
                    tuple: {
                      name: 'se-gaap-ext:OvrigaAvsattningarSpecifikationTuple',
                      id: `${rows.getBaseId()}.1.tuple`,
                      tupleID: `${rows.getBaseId()}.1.tuple`,
                    },
                    tupleRef: tupleRef(1, `${rows.getBaseId()}.1.tuple`),
                    previousYearTuple: false,
                  }
                ),
                ixbrlCell(value(), {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:OvrigaAvsattningarSpecifikationPeriodensAvsattningar',
                  contextRef: 'period0',
                  standardRubrik:
                    'Periodens avsättning avseende övriga avsättningar',
                  negateValue: false,
                  tupleRef: tupleRef(2, `${rows.getBaseId()}.1.tuple`),
                  previousYearTuple: false,
                }),
                ixbrlCell(value(), {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:OvrigaAvsattningarSpecifikationJusteringForandringNuvardet',
                  contextRef: 'period0',
                  standardRubrik:
                    'Ökning (minskning) av övriga avsättningar till följd av förändring av nuvärdet',
                  negateValue: false,
                  tupleRef: tupleRef(3, `${rows.getBaseId()}.1.tuple`),
                  previousYearTuple: false,
                }),
                ixbrlCell(value(), {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:OvrigaAvsattningarSpecifikationOvrigaVardeforandringar',
                  contextRef: 'period0',
                  standardRubrik:
                    'Ökning (minskning) av övriga avsättningar till följd av övriga värdeförändringar än förändring av nuvärdet',
                  negateValue: false,
                  tupleRef: tupleRef(4, `${rows.getBaseId()}.1.tuple`),
                  previousYearTuple: false,
                }),
                ixbrlCell(value(), {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:OvrigaAvsattningarSpecifikationRorelseforvarv',
                  contextRef: 'period0',
                  standardRubrik:
                    'Ökning (minskning) av övriga avsättningar vid rörelseförvärv',
                  negateValue: false,
                  tupleRef: tupleRef(5, `${rows.getBaseId()}.1.tuple`),
                  previousYearTuple: false,
                }),
                ixbrlCell(value(), {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:OvrigaAvsattningarSpecifikationFusion',
                  contextRef: 'period0',
                  standardRubrik:
                    'Ökning (minskning) av övriga avsättningar vid fusion',
                  negateValue: false,
                  tupleRef: tupleRef(6, `${rows.getBaseId()}.1.tuple`),
                  previousYearTuple: false,
                }),
                ixbrlCell(value(), {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:OvrigaAvsattningarSpecifikationOmklassificeringar',
                  contextRef: 'period0',
                  standardRubrik:
                    'Ökning (minskning) av övriga avsättningar vid omklassificeringar',
                  negateValue: false,
                  tupleRef: tupleRef(7, `${rows.getBaseId()}.1.tuple`),
                  previousYearTuple: false,
                }),
                ixbrlCell(value(), {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:OvrigaAvsattningarSpecifikationOmrakningsdifferenser',
                  contextRef: 'period0',
                  standardRubrik:
                    'Ökning (minskning) av övriga avsättningar vid omräkningsdifferenser',
                  negateValue: false,
                  tupleRef: tupleRef(8, `${rows.getBaseId()}.1.tuple`),
                  previousYearTuple: false,
                }),
                ixbrlCell(value(), {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:OvrigaAvsattningarSpecifikationIanspraktagnaBelopp',
                  contextRef: 'period0',
                  standardRubrik:
                    'Under perioden ianspråktagna belopp från övriga avsättningar',
                  negateValue: true,
                  tupleRef: tupleRef(9, `${rows.getBaseId()}.1.tuple`),
                  previousYearTuple: false,
                }),
                ixbrlCell(value(), {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:OvrigaAvsattningarSpecifikationAterfordaBelopp',
                  contextRef: 'period0',
                  standardRubrik:
                    'Under perioden återförda belopp från övriga avsättningar',
                  negateValue: true,
                  tupleRef: tupleRef(10, `${rows.getBaseId()}.1.tuple`),
                  previousYearTuple: false,
                }),
                ixbrlCell(value(), {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:OvrigaAvsattningarSpecifikationBelopp',
                  contextRef: 'balans0',
                  standardRubrik:
                    'Redovisat värde för specifik övrig avsättning',
                  saldo: 'credit',
                  negateValue: false,
                  tupleRef: tupleRef(11, `${rows.getBaseId()}.1.tuple`),
                  previousYearTuple: false,
                })
              );
              return rows.build();
            });
          rows.newRowTemplateGenerator((_id, baseId) => ({
            id: _id,
            active: true,
            type: 'row',
            cells: {
              OvrigaAvsattningarList: ixbrlCell(
                {
                  type: 'string',
                  value: OvrigaAvsattningarList.options[0].value,
                  format: OvrigaAvsattningarList,
                },
                {
                  type: 'enumerationItemType',
                  name: 'se-gen-base:OvrigaAvsattningarList',
                  contextRef: 'period0',
                  standardRubrik: 'Typ av övrig avsättning',
                  negateValue: false,
                  tuple: {
                    name: 'se-gaap-ext:OvrigaAvsattningarSpecifikationTuple',
                    id: `${baseId}.${_id}.tuple`,
                    tupleID: `${baseId}.${_id}.tuple`,
                  },
                  tupleRef: tupleRef(1, `${baseId}.${_id}.tuple`),
                  previousYearTuple: false,
                }
              ),
              OvrigaAvsattningarSpecifikationPeriodensAvsattningar: ixbrlCell(
                value(),
                {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:OvrigaAvsattningarSpecifikationPeriodensAvsattningar',
                  contextRef: 'period0',
                  standardRubrik:
                    'Periodens avsättning avseende övriga avsättningar',
                  negateValue: false,
                  tupleRef: tupleRef(2, `${baseId}.${_id}.tuple`),
                  previousYearTuple: false,
                }
              ),
              OvrigaAvsattningarSpecifikationJusteringForandringNuvardet:
                ixbrlCell(value(), {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:OvrigaAvsattningarSpecifikationJusteringForandringNuvardet',
                  contextRef: 'period0',
                  standardRubrik:
                    'Ökning (minskning) av övriga avsättningar till följd av förändring av nuvärdet',
                  negateValue: false,
                  tupleRef: tupleRef(3, `${baseId}.${_id}.tuple`),
                  previousYearTuple: false,
                }),
              OvrigaAvsattningarSpecifikationOvrigaVardeforandringar: ixbrlCell(
                value(),
                {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:OvrigaAvsattningarSpecifikationOvrigaVardeforandringar',
                  contextRef: 'period0',
                  standardRubrik:
                    'Ökning (minskning) av övriga avsättningar till följd av övriga värdeförändringar än förändring av nuvärdet',
                  negateValue: false,
                  tupleRef: tupleRef(4, `${baseId}.${_id}.tuple`),
                  previousYearTuple: false,
                }
              ),
              OvrigaAvsattningarSpecifikationRorelseforvarv: ixbrlCell(
                value(),
                {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:OvrigaAvsattningarSpecifikationRorelseforvarv',
                  contextRef: 'period0',
                  standardRubrik:
                    'Ökning (minskning) av övriga avsättningar vid rörelseförvärv',
                  negateValue: false,
                  tupleRef: tupleRef(5, `${baseId}.${_id}.tuple`),
                  previousYearTuple: false,
                }
              ),
              OvrigaAvsattningarSpecifikationFusion: ixbrlCell(value(), {
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:OvrigaAvsattningarSpecifikationFusion',
                contextRef: 'period0',
                standardRubrik:
                  'Ökning (minskning) av övriga avsättningar vid fusion',
                negateValue: false,
                tupleRef: tupleRef(6, `${baseId}.${_id}.tuple`),
                previousYearTuple: false,
              }),
              OvrigaAvsattningarSpecifikationOmklassificeringar: ixbrlCell(
                value(),
                {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:OvrigaAvsattningarSpecifikationOmklassificeringar',
                  contextRef: 'period0',
                  standardRubrik:
                    'Ökning (minskning) av övriga avsättningar vid omklassificeringar',
                  negateValue: false,
                  tupleRef: tupleRef(7, `${baseId}.${_id}.tuple`),
                  previousYearTuple: false,
                }
              ),
              OvrigaAvsattningarSpecifikationOmrakningsdifferenser: ixbrlCell(
                value(),
                {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:OvrigaAvsattningarSpecifikationOmrakningsdifferenser',
                  contextRef: 'period0',
                  standardRubrik:
                    'Ökning (minskning) av övriga avsättningar vid omräkningsdifferenser',
                  negateValue: false,
                  tupleRef: tupleRef(8, `${baseId}.${_id}.tuple`),
                  previousYearTuple: false,
                }
              ),
              OvrigaAvsattningarSpecifikationIanspraktagnaBelopp: ixbrlCell(
                value(),
                {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:OvrigaAvsattningarSpecifikationIanspraktagnaBelopp',
                  contextRef: 'period0',
                  standardRubrik:
                    'Under perioden ianspråktagna belopp från övriga avsättningar',
                  negateValue: true,
                  tupleRef: tupleRef(9, `${baseId}.${_id}.tuple`),
                  previousYearTuple: false,
                }
              ),
              OvrigaAvsattningarSpecifikationAterfordaBelopp: ixbrlCell(
                value(),
                {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:OvrigaAvsattningarSpecifikationAterfordaBelopp',
                  contextRef: 'period0',
                  standardRubrik:
                    'Under perioden återförda belopp från övriga avsättningar',
                  negateValue: true,
                  tupleRef: tupleRef(10, `${baseId}.${_id}.tuple`),
                  previousYearTuple: false,
                }
              ),
              OvrigaAvsattningarSpecifikationBelopp: ixbrlCell(value(), {
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:OvrigaAvsattningarSpecifikationBelopp',
                contextRef: 'balans0',
                standardRubrik: 'Redovisat värde för specifik övrig avsättning',
                saldo: 'credit',
                negateValue: false,
                tupleRef: tupleRef(11, `${baseId}.${_id}.tuple`),
                previousYearTuple: false,
              }),
            },
          }));
          return rows.build();
        })
        .build(),
    },
    AndraOvrigaAvsattningarSpecifikationTuple: {
      type: 'group',
      active: true,
      level: 0,
      title: field('Specifikation av andra övriga avsättningar'),
      table: table<IxbrlCell>(
        'notes.note74.data.AndraOvrigaAvsattningarSpecifikationTuple.table',
        {
          id: 'OvrigaAvsattningarSpecifikationAndraOvrigaAvsattningarTyp',
          label: 'Typ av andra övriga avsättningar',
          dataType: 'text',
          active: true,
        },
        {
          id: 'OvrigaAvsattningarSpecifikationPeriodensAvsattningar',
          label: 'Årets avsättningar',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'OvrigaAvsattningarSpecifikationJusteringForandringNuvardet',
          label: 'Justeringar till följd av förändring av nuvärdet',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'OvrigaAvsattningarSpecifikationOvrigaVardeforandringar',
          label: 'Övriga värdeförändringar',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'OvrigaAvsattningarSpecifikationRorelseforvarv',
          label: 'Rörelseförvärv',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'OvrigaAvsattningarSpecifikationFusion',
          label: 'Fusion',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'OvrigaAvsattningarSpecifikationOmklassificeringar',
          label: 'Omklassificeringar',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'OvrigaAvsattningarSpecifikationOmrakningsdifferenser',
          label: 'Omräkningsdifferenser',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'OvrigaAvsattningarSpecifikationIanspraktagnaBelopp',
          label: 'Under året ianspråktagna belopp',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'OvrigaAvsattningarSpecifikationAterfordaBelopp',
          label: 'Under året återförda belopp',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'OvrigaAvsattningarSpecifikationBelopp',
          label: 'Redovisat värde',
          dataType: 'numeric',
          active: true,
        }
      )
        .addRows((rows) => {
          rows
            .addRowWithType(
              'AndraOvrigaAvsattningarSpecifikationTuple',
              'hidden',
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined
            )
            .addSubRows((rows) => {
              rows.addRowWithType(
                '1',
                'row',
                ixbrlCell(value('Typ av andra övriga avsättningar'), {
                  type: 'stringItemType',
                  name: 'se-gen-base:OvrigaAvsattningarSpecifikationAndraOvrigaAvsattningarTyp',
                  contextRef: 'period0',
                  standardRubrik:
                    'Benämning på annan övrig avsättning i specifikation av förändring',
                  negateValue: false,
                  tuple: {
                    name: 'se-gaap-ext:AndraOvrigaAvsattningarSpecifikationTuple',
                    id: `${rows.getBaseId()}.1.tuple`,
                    tupleID: `${rows.getBaseId()}.1.tuple`,
                  },
                  tupleRef: tupleRef(1, `${rows.getBaseId()}.1.tuple`),
                  previousYearTuple: false,
                }),
                ixbrlCell(value(), {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:OvrigaAvsattningarSpecifikationPeriodensAvsattningar',
                  contextRef: 'period0',
                  standardRubrik:
                    'Periodens avsättning avseende övriga avsättningar',
                  negateValue: false,
                  tupleRef: tupleRef(2, `${rows.getBaseId()}.1.tuple`),
                  previousYearTuple: false,
                }),
                ixbrlCell(value(), {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:OvrigaAvsattningarSpecifikationJusteringForandringNuvardet',
                  contextRef: 'period0',
                  standardRubrik:
                    'Ökning (minskning) av övriga avsättningar till följd av förändring av nuvärdet',
                  negateValue: false,
                  tupleRef: tupleRef(3, `${rows.getBaseId()}.1.tuple`),
                  previousYearTuple: false,
                }),
                ixbrlCell(value(), {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:OvrigaAvsattningarSpecifikationOvrigaVardeforandringar',
                  contextRef: 'period0',
                  standardRubrik:
                    'Ökning (minskning) av övriga avsättningar till följd av övriga värdeförändringar än förändring av nuvärdet',
                  negateValue: false,
                  tupleRef: tupleRef(4, `${rows.getBaseId()}.1.tuple`),
                  previousYearTuple: false,
                }),
                ixbrlCell(value(), {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:OvrigaAvsattningarSpecifikationRorelseforvarv',
                  contextRef: 'period0',
                  standardRubrik:
                    'Ökning (minskning) av övriga avsättningar vid rörelseförvärv',
                  negateValue: false,
                  tupleRef: tupleRef(5, `${rows.getBaseId()}.1.tuple`),
                  previousYearTuple: false,
                }),
                ixbrlCell(value(), {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:OvrigaAvsattningarSpecifikationFusion',
                  contextRef: 'period0',
                  standardRubrik:
                    'Ökning (minskning) av övriga avsättningar vid fusion',
                  negateValue: false,
                  tupleRef: tupleRef(6, `${rows.getBaseId()}.1.tuple`),
                  previousYearTuple: false,
                }),
                ixbrlCell(value(), {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:OvrigaAvsattningarSpecifikationOmklassificeringar',
                  contextRef: 'period0',
                  standardRubrik:
                    'Ökning (minskning) av övriga avsättningar vid omklassificeringar',
                  negateValue: false,
                  tupleRef: tupleRef(7, `${rows.getBaseId()}.1.tuple`),
                  previousYearTuple: false,
                }),
                ixbrlCell(value(), {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:OvrigaAvsattningarSpecifikationOmrakningsdifferenser',
                  contextRef: 'period0',
                  standardRubrik:
                    'Ökning (minskning) av övriga avsättningar vid omräkningsdifferenser',
                  negateValue: false,
                  tupleRef: tupleRef(8, `${rows.getBaseId()}.1.tuple`),
                  previousYearTuple: false,
                }),
                ixbrlCell(value(), {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:OvrigaAvsattningarSpecifikationIanspraktagnaBelopp',
                  contextRef: 'period0',
                  standardRubrik:
                    'Under perioden ianspråktagna belopp från övriga avsättningar',
                  negateValue: true,
                  tupleRef: tupleRef(9, `${rows.getBaseId()}.1.tuple`),
                  previousYearTuple: false,
                }),
                ixbrlCell(value(), {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:OvrigaAvsattningarSpecifikationAterfordaBelopp',
                  contextRef: 'period0',
                  standardRubrik:
                    'Under perioden återförda belopp från övriga avsättningar',
                  negateValue: true,
                  tupleRef: tupleRef(10, `${rows.getBaseId()}.1.tuple`),
                  previousYearTuple: false,
                }),
                ixbrlCell(value(), {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:OvrigaAvsattningarSpecifikationBelopp',
                  contextRef: 'balans0',
                  standardRubrik:
                    'Redovisat värde för specifik övrig avsättning',
                  saldo: 'credit',
                  negateValue: false,
                  tupleRef: tupleRef(11, `${rows.getBaseId()}.1.tuple`),
                  previousYearTuple: false,
                })
              );
              return rows.build();
            });
          rows.newRowTemplateGenerator((_id, baseId) => ({
            id: _id,
            active: true,
            type: 'row',
            cells: {
              OvrigaAvsattningarSpecifikationAndraOvrigaAvsattningarTyp:
                ixbrlCell(value('Typ av andra övriga avsättningar'), {
                  type: 'stringItemType',
                  name: 'se-gen-base:OvrigaAvsattningarSpecifikationAndraOvrigaAvsattningarTyp',
                  contextRef: 'period0',
                  standardRubrik:
                    'Benämning på annan övrig avsättning i specifikation av förändring',
                  negateValue: false,
                  tuple: {
                    name: 'se-gaap-ext:AndraOvrigaAvsattningarSpecifikationTuple',
                    id: `${baseId}.${_id}.tuple`,
                    tupleID: `${baseId}.${_id}.tuple`,
                  },
                  tupleRef: tupleRef(1, `${baseId}.${_id}.tuple`),
                  previousYearTuple: false,
                }),
              OvrigaAvsattningarSpecifikationPeriodensAvsattningar: ixbrlCell(
                value(),
                {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:OvrigaAvsattningarSpecifikationPeriodensAvsattningar',
                  contextRef: 'period0',
                  standardRubrik:
                    'Periodens avsättning avseende övriga avsättningar',
                  negateValue: false,
                  tupleRef: tupleRef(2, `${baseId}.${_id}.tuple`),
                  previousYearTuple: false,
                }
              ),
              OvrigaAvsattningarSpecifikationJusteringForandringNuvardet:
                ixbrlCell(value(), {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:OvrigaAvsattningarSpecifikationJusteringForandringNuvardet',
                  contextRef: 'period0',
                  standardRubrik:
                    'Ökning (minskning) av övriga avsättningar till följd av förändring av nuvärdet',
                  negateValue: false,
                  tupleRef: tupleRef(3, `${baseId}.${_id}.tuple`),
                  previousYearTuple: false,
                }),
              OvrigaAvsattningarSpecifikationOvrigaVardeforandringar: ixbrlCell(
                value(),
                {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:OvrigaAvsattningarSpecifikationOvrigaVardeforandringar',
                  contextRef: 'period0',
                  standardRubrik:
                    'Ökning (minskning) av övriga avsättningar till följd av övriga värdeförändringar än förändring av nuvärdet',
                  negateValue: false,
                  tupleRef: tupleRef(4, `${baseId}.${_id}.tuple`),
                  previousYearTuple: false,
                }
              ),
              OvrigaAvsattningarSpecifikationRorelseforvarv: ixbrlCell(
                value(),
                {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:OvrigaAvsattningarSpecifikationRorelseforvarv',
                  contextRef: 'period0',
                  standardRubrik:
                    'Ökning (minskning) av övriga avsättningar vid rörelseförvärv',
                  negateValue: false,
                  tupleRef: tupleRef(5, `${baseId}.${_id}.tuple`),
                  previousYearTuple: false,
                }
              ),
              OvrigaAvsattningarSpecifikationFusion: ixbrlCell(value(), {
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:OvrigaAvsattningarSpecifikationFusion',
                contextRef: 'period0',
                standardRubrik:
                  'Ökning (minskning) av övriga avsättningar vid fusion',
                negateValue: false,
                tupleRef: tupleRef(6, `${baseId}.${_id}.tuple`),
                previousYearTuple: false,
              }),
              OvrigaAvsattningarSpecifikationOmklassificeringar: ixbrlCell(
                value(),
                {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:OvrigaAvsattningarSpecifikationOmklassificeringar',
                  contextRef: 'period0',
                  standardRubrik:
                    'Ökning (minskning) av övriga avsättningar vid omklassificeringar',
                  negateValue: false,
                  tupleRef: tupleRef(7, `${baseId}.${_id}.tuple`),
                  previousYearTuple: false,
                }
              ),
              OvrigaAvsattningarSpecifikationOmrakningsdifferenser: ixbrlCell(
                value(),
                {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:OvrigaAvsattningarSpecifikationOmrakningsdifferenser',
                  contextRef: 'period0',
                  standardRubrik:
                    'Ökning (minskning) av övriga avsättningar vid omräkningsdifferenser',
                  negateValue: false,
                  tupleRef: tupleRef(8, `${baseId}.${_id}.tuple`),
                  previousYearTuple: false,
                }
              ),
              OvrigaAvsattningarSpecifikationIanspraktagnaBelopp: ixbrlCell(
                value(),
                {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:OvrigaAvsattningarSpecifikationIanspraktagnaBelopp',
                  contextRef: 'period0',
                  standardRubrik:
                    'Under perioden ianspråktagna belopp från övriga avsättningar',
                  negateValue: true,
                  tupleRef: tupleRef(9, `${baseId}.${_id}.tuple`),
                  previousYearTuple: false,
                }
              ),
              OvrigaAvsattningarSpecifikationAterfordaBelopp: ixbrlCell(
                value(),
                {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:OvrigaAvsattningarSpecifikationAterfordaBelopp',
                  contextRef: 'period0',
                  standardRubrik:
                    'Under perioden återförda belopp från övriga avsättningar',
                  negateValue: true,
                  tupleRef: tupleRef(10, `${baseId}.${_id}.tuple`),
                  previousYearTuple: false,
                }
              ),
              OvrigaAvsattningarSpecifikationBelopp: ixbrlCell(value(), {
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:OvrigaAvsattningarSpecifikationBelopp',
                contextRef: 'balans0',
                standardRubrik: 'Redovisat värde för specifik övrig avsättning',
                saldo: 'credit',
                negateValue: false,
                tupleRef: tupleRef(11, `${baseId}.${_id}.tuple`),
                previousYearTuple: false,
              }),
            },
          }));
          rows.addRowWithType(
            'OvrigaAvsattningar',
            'sum',
            noteLabel('Summa'),
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined
          );
          return rows.build();
        })
        .build(),
    },
    NotOvrigaAvsattningarKommentar: {
      type: 'group',
      active: true,
      level: 0,
      title: field('Kommentar till specifikation av övriga avsättningar'),
      value: ixbrlField(field(''), {
        contextRef: 'period0',
        type: 'stringItemType',
        name: 'se-gen-base:NotOvrigaAvsattningarKommentar',
        standardRubrik: 'Kommentar till specifikation av övriga avsättningar',
      }),
    },
  },
  group: value(undefined),
  section: value(undefined),
  validForReportType: {
    k2: true,
    k3: true,
  },
});
export const note2074K3v2ContentDefinition = {
  type: 'part' as const,
  children: {
    active: boolean,
    number: cell,
    name: cell,
    data: {
      type: 'part' as const,
      children: {
        active: boolean,

        OvrigaAvsattningarSpecifikationTuple: {
          type: 'part' as const,
          children: {
            type: type,
            level: number,
            active: boolean,

            title: cell,

            table: { type: 'table' as const },
          },
        },

        AndraOvrigaAvsattningarSpecifikationTuple: {
          type: 'part' as const,
          children: {
            type: type,
            level: number,
            active: boolean,

            title: cell,

            table: { type: 'table' as const },
          },
        },

        NotOvrigaAvsattningarKommentar: {
          type: 'part' as const,
          children: {
            type: type,
            level: number,
            active: boolean,

            title: cell,

            value: cell,
          },
        },
      },
    },
    group: cell,
    section: cell,
    validForReportType: {
      type: 'part' as const,
      children: {
        k2: boolean,
        k3: boolean,
      },
    },
  },
};

import {
  Observable,
  combineLatest,
  map,
  filter,
  ReplaySubject,
  take,
  Subject,
  Subscription,
  distinct,
  BehaviorSubject,
  throttleTime,
} from 'rxjs';
import { addYears, endOfMonth } from 'date-fns';

import {
  AccountingBalancesAccountResolver,
  mapAccountsToReferenceAccountInformation,
  TimePeriod,
} from '@agoy/document';
import { isNotNull } from '@agoy/common';
import { AnnualReportDataService } from './AnnualReportDataService';
import {
  AnnualReport,
  AnnualReportChanges,
  DocumentConfiguration,
} from '../config/document';
import { AnnualReportCustomerType } from '../config/common';
import { config, references } from '../config';
import applyReportChanges from '../operations/applyChanges';
import { resolveAnnualReport } from '../operations/resolveAnnualReport';
import { References } from '../config/shares-company/references/utils';
import { ClientInformationTables } from '../config/shares-company/types';

type AccountingPeriod = Parameters<
  AccountingBalancesAccountResolver['addFinancialYear']
>[1][0];

type FinancialYear = { id: number; start: string; end: string };

type AccountingAccount = Parameters<
  AccountingBalancesAccountResolver['addFinancialYear']
>[2][0];

type AccountingBalances = {
  financialYears: FinancialYear[];
  periods: AccountingPeriod[];
  accounts: AccountingAccount[];
};

type ReportState = {
  dirty: boolean;
  report: AnnualReport;
};

export class ReactiveAnnualReportDataService
  implements AnnualReportDataService
{
  clientId: string;

  documentId: string;

  financialYear: TimePeriod;

  period: TimePeriod;

  changes: Subject<AnnualReportChanges>;

  sieData: Observable<AccountingBalances | null>;

  previousSieData: Observable<AccountingBalances | null>;

  report: Observable<AnnualReport>;

  latestReport: Observable<AnnualReport>;

  reportState: Subject<ReportState>;

  reportSubscription: Subscription;

  calculationSubscription: Subscription;

  forceNewConfig: Subject<true>;

  documentConfiguration: Subject<DocumentConfiguration>;

  locked: Subject<boolean>;

  /**
   * The latest value from this.locked, enables access to the value
   * synchronously.
   */
  latestLocked = false;

  lockedSubscription: Subscription;

  constructor(
    clientId: string,
    documentId: string,
    financialYear: TimePeriod,
    period: TimePeriod,
    changes: Observable<{ changes: AnnualReportChanges; locked: boolean }>,
    documentConfiguration: Observable<DocumentConfiguration>,
    client: Observable<AnnualReportCustomerType>,
    sieData: Observable<AccountingBalances | null>,
    previousSieData: Observable<AccountingBalances | null>,
    getBaseReferences?: (
      documentConfiguration: DocumentConfiguration
    ) => Record<string, string>,
    clientInformationTables?: ClientInformationTables
  ) {
    this.clientId = clientId;
    this.documentId = documentId;
    this.financialYear = financialYear;
    this.period = period;
    this.changes = new ReplaySubject(1);
    this.reportState = new ReplaySubject(1);
    this.locked = new ReplaySubject(1);
    this.documentConfiguration = new ReplaySubject(1);
    this.forceNewConfig = new BehaviorSubject(true);
    this.sieData = sieData;
    this.previousSieData = previousSieData;

    // Initialize the changes with the first observed one
    changes.pipe(filter(isNotNull), take(1)).subscribe((value) => {
      this.changes.next(value.changes);
      this.locked.next(value.locked);
    });
    documentConfiguration.pipe(take(1)).subscribe((value) => {
      this.documentConfiguration.next(value);
    });

    // Pick the previous financial year for the previous SIE-data (which contains a year only)
    const previousFinancialYear = previousSieData.pipe(
      map((value) => {
        if (value) {
          const { financialYearId } = value.periods[0];
          const year = value.financialYears.find(
            (finYear) => finYear.id === financialYearId
          );
          if (!year) {
            throw new Error(
              'Expected the previous financial year information to exist'
            );
          }
          return year;
        }
        return null;
      })
    );

    // Create the account resolver that only depends on SIE data
    const resolver = combineLatest({
      sieData,
      previousSieData,
      previousFinancialYear,
    }).pipe(
      map((value) => {
        const accountResolver = new AccountingBalancesAccountResolver(
          value.sieData?.financialYears.find(
            (y) =>
              TimePeriod.fromDates(y.start, y.end, 'yyyy-MM-dd').value ===
              financialYear.value
          )?.id ?? -1,
          value.sieData?.periods || [],
          value.sieData?.accounts || []
        );
        if (value.previousSieData && value.previousFinancialYear) {
          accountResolver.addFinancialYear(
            value.previousFinancialYear.id,
            value.previousSieData.periods,
            value.previousSieData.accounts
          );
        }
        return accountResolver;
      })
    );

    // The report config needs accounts
    const accounts = combineLatest({
      sieData,
      previousSieData,
    }).pipe(
      map((sie) => {
        return [
          ...(sie.previousSieData?.accounts || []),
          ...(sie.sieData?.accounts || []),
        ];
      })
    );

    // The initial config, without the changes applied, depends on
    // client information, report type and accounts.
    // To support the reset actions, we need to force a new initialConfig
    const initialConfig = combineLatest({
      accounts,
      client,
      force: this.forceNewConfig,
      documentConfiguration: this.documentConfiguration,
    }).pipe(
      map((value) => {
        // Periods are from the start of the financial year to the end of the selected period
        const displayPeriod = new TimePeriod(financialYear.start, period.end);

        // The previous period sent to the config is not
        // depending on actual financial years in the client.
        // Using the configured years to format the previous period
        const previousYearPeriod = value.documentConfiguration.financialYears[1]
          ? TimePeriod.fromISODates(
              value.documentConfiguration.financialYears[1].start,
              value.documentConfiguration.financialYears[1].end
            )
          : null;

        const previousPeriod = this.getPreviousPeriod(previousYearPeriod);
        const displayPreviousPeriod =
          previousPeriod && previousYearPeriod
            ? new TimePeriod(previousYearPeriod.start, previousPeriod.end)
            : null;

        return config(
          value.client,
          displayPeriod,
          displayPreviousPeriod,
          mapAccountsToReferenceAccountInformation(value.accounts),
          value.documentConfiguration,
          period.value !== financialYear.value,
          clientInformationTables
        );
      })
    );

    this.reportSubscription = combineLatest({
      initialConfig,
      documentConfiguration,
      changes: this.changes,
    })
      .pipe(
        distinct((value) => value.initialConfig),
        map((value): ReportState => {
          return {
            report: applyReportChanges(
              value.initialConfig,
              value.changes,
              value.documentConfiguration.reportType
            ),
            dirty: true,
          };
        })
      )
      .subscribe((value) => {
        this.reportState.next(value);
      });

    // The calculations are triggered when the report state is dirty
    // This will lead to a fresh report being published
    const getBaseReferencesFunc = getBaseReferences || references;
    this.calculationSubscription = combineLatest({
      reportState: this.reportState.pipe(filter((state) => state.dirty)),
      resolver,
      previousFinancialYear,
      client,
      documentConfiguration: this.documentConfiguration,
    })
      .pipe(
        // A throttle here to prevent immidiate re-rendering and recalculations
        // when multiple operations are updating the document, for example
        // when updating with ClientInformation
        throttleTime(10, undefined, { leading: true, trailing: true }),
        map((value) => {
          const report = resolveAnnualReport(
            value.reportState.report,
            getBaseReferencesFunc(value.documentConfiguration),
            {
              accountResolver: value.resolver,
              defaultPeriod: period,
              periods: {
                year: period,
                ...this.periods(value.previousFinancialYear),
              },
            },
            value.documentConfiguration.reportType
          );

          return report;
        })
      )
      .subscribe((report) => {
        this.reportState.next({ dirty: false, report });
      });

    // Expose the non-dirty report states as the report
    this.report = this.reportState.pipe(
      filter((state) => !state.dirty),
      map((state) => state.report)
    );

    // Expose the latest report state
    this.latestReport = this.reportState.pipe(map((state) => state.report));

    this.lockedSubscription = this.locked.subscribe((locked) => {
      this.latestLocked = locked;
    });
  }

  dispose(): void {
    this.reportSubscription.unsubscribe();
    this.calculationSubscription.unsubscribe();
    this.changes.complete();
    this.reportState.complete();
    this.lockedSubscription.unsubscribe();
  }

  update(report: AnnualReport, changes: AnnualReportChanges): void {
    if (this.latestLocked) {
      console.warn('Document is locked');
      return;
    }
    this.changes.next(changes);
    if (report) {
      this.reportState.next({
        dirty: true,
        report,
      });
    } else {
      this.forceNewConfig.next(true);
    }
  }

  updateLocked(locked: boolean): void {
    this.locked.next(locked);
  }

  async updateDocumentConfiguration(
    documentConfiguration: DocumentConfiguration
  ): Promise<void> {
    if (this.latestLocked) {
      console.warn('Document is locked');
      return;
    }

    this.documentConfiguration.next(documentConfiguration);
  }

  getPreviousPeriod(
    previousFinancialYear: TimePeriod | null
  ): TimePeriod | null {
    if (!previousFinancialYear) {
      return null;
    }

    if (this.period.end === this.financialYear.end) {
      return previousFinancialYear;
    }

    // Period bokslut, so return use a TimePeriod a year before
    return new TimePeriod(
      addYears(this.period.startDate, -1),
      endOfMonth(addYears(this.period.startDate, -1))
    );
  }

  periods(
    previousFinancialYear: FinancialYear | null
  ): Record<string, TimePeriod> {
    if (!previousFinancialYear) {
      return {};
    }
    const previousPeriod = this.getPreviousPeriod(
      TimePeriod.fromISODates(
        previousFinancialYear.start,
        previousFinancialYear.end
      )
    );
    if (!previousPeriod) {
      return {};
    }
    return {
      previousYear: previousPeriod,
    };
  }
}

export default ReactiveAnnualReportDataService;

import { endOfMonth } from 'date-fns';
import { format, parse } from '@agoy/dates';
import {
  id,
  account,
  sum,
  or,
  multiply,
  label,
  ref,
  value,
  refs,
  ReferenceAccountInformation,
  abs,
} from '@agoy/document';
import { ReportType, FinancialReportStructure } from '../../types';
import {
  table,
  RowsBuilder,
  range,
  year,
  getPreviousYearPeriod,
  getPreviousYear,
  AnnualReportType,
  IndividualCompanyIncomeStatementStructure,
  individualCompanyIncomeStatementAccounts,
} from '@agoy/annual-report-document';

const accountRangeRows = (
  accounts: Record<string, ReferenceAccountInformation>,
  first: number,
  last: number,
  rows: RowsBuilder,
  previousPeriod: string
): RowsBuilder => {
  range(first, last + 1).forEach((n) => {
    const accountNumber = n.toString();
    const accountInformation = accounts[accountNumber];
    if (accountInformation) {
      rows
        .addRow(
          accountNumber,
          label(`${n} ${accountInformation.accountName}`),
          undefined,
          ref(multiply(-1, account(accountNumber))),
          ref(
            multiply(
              -1,
              or(account(accountNumber, undefined, previousPeriod), 0)
            )
          )
        )
        .setSortKey(n);
    }
  });
  return rows;
};

const addPost =
  (
    accounts: Record<string, ReferenceAccountInformation>,
    rows: RowsBuilder,
    previousPeriod: string
  ) =>
  (
    rowId: string,
    name: string,
    accountNumbers: [number, number][]
  ): RowsBuilder => {
    return rows
      .addRow(
        rowId,
        value(name),
        refs(),
        ref(or(sum(id(`${rows.getBaseId()}.${rowId}.*.year`)), 0)),
        ref(or(sum(id(`${rows.getBaseId()}.${rowId}.*.previousYear`)), 0))
      )
      .newRowTemplateGenerator((id, baseId, { label: labelValue, ib, ub }) => ({
        id,
        type: 'account',
        active: true,
        cells: {
          label: label(labelValue),
          year: ub ? ref(multiply(-1, account(ub))) : value(0),
          previousYear: ib
            ? ref(multiply(-1, or(account(ib, undefined, previousPeriod), 0)))
            : value(0),
        },
        sortKey: ib ? parseInt(ib) : parseInt(ub),
      }))
      .addSubRows((rows) => {
        accountNumbers.forEach(([first, last]) =>
          accountRangeRows(accounts, first, last, rows, previousPeriod)
        );
        return rows.build();
      });
  };

const addGroup = (
  rows: RowsBuilder,
  rowId: string,
  name: string,
  sumName: string,
  subRowsBuilder: (rows: RowsBuilder) => void
): void => {
  rows
    .addRow(
      rowId,
      value(name),
      refs(),
      undefined,
      undefined,
      ref(id(`${rows.getBaseId()}.${rowId}Sum.hidden`))
    )
    .addSubRows((rows) => {
      subRowsBuilder(rows);
      return rows.build();
    });

  rows.addRow(
    `${rowId}Sum`,
    value(sumName),
    refs(),
    ref(or(sum(id(`${rows.getBaseId()}.${rowId}.*.year`)), 0)),
    ref(or(sum(id(`${rows.getBaseId()}.${rowId}.*.previousYear`)), 0)),
    ref(
      sum(
        or(
          id(`${rows.getBaseId()}.${rowId}.*.notes-0`),
          id(`${rows.getBaseId()}.${rowId}Sum.notes-0`),
          0
        ),
        abs(id(`${rows.getBaseId()}.${rowId}Sum.year`)),
        abs(id(`${rows.getBaseId()}.${rowId}Sum.previousYear`))
      )
    )
  );
};

const attachRows = (
  accounts: Record<string, ReferenceAccountInformation>,
  rows: RowsBuilder,
  previousPeriod: string,
  structureKey: keyof IndividualCompanyIncomeStatementStructure,
  // TODO: do we need account modifier here?
  // accountModifier: AccountValueType,
  reportType?: AnnualReportType
) => {
  const add = addPost(
    accounts,
    rows,
    previousPeriod
    // isDigitalSubmission,
    // accountModifier
  );
  individualCompanyIncomeStatementAccounts[structureKey].map((section) => {
    add(section.row, section.name, section.accounts);
  });
};

const addIncomes = (
  accounts: Record<string, ReferenceAccountInformation>,
  rows: RowsBuilder,
  previousPeriod: string
): void => {
  addGroup(rows, 'incomes', 'Intäkter m.m.', 'Summa intäkter', (rows) =>
    attachRows(
      accounts,
      rows,
      previousPeriod,
      'incomes'
      // accountModifier
    )
  );
};

const addExpenses = (
  accounts: Record<string, ReferenceAccountInformation>,
  rows: RowsBuilder,
  previousPeriod: string
): void => {
  addGroup(
    rows,
    'expenses',
    'Rörelsens kostnader',
    'Summa rörelsens kostnader',
    (rows) => attachRows(accounts, rows, previousPeriod, 'expenses')
  );
};

const addDepreciation = (
  accounts: Record<string, ReferenceAccountInformation>,
  rows: RowsBuilder,
  previousPeriod: string
): void => {
  addGroup(rows, 'taxes', 'Avskrivningar', 'Summa avskrivningar', (rows) =>
    attachRows(accounts, rows, previousPeriod, 'taxes')
  );
};

const addResults = (
  accounts: Record<string, ReferenceAccountInformation>,
  rows: RowsBuilder,
  previousPeriod: string
): void => {
  addGroup(rows, 'result', 'Årets resultat', '', (rows) =>
    attachRows(accounts, rows, previousPeriod, 'result')
  );
};

const addUnassignedAccounts = (
  accounts: Record<string, ReferenceAccountInformation>,
  rows: RowsBuilder,
  previousPeriod: string
): void => {
  addGroup(
    rows,
    'unassignedAccounts',
    'Ej tilldelade',
    'Ej tilldelade total',
    (rows) => attachRows(accounts, rows, previousPeriod, 'unassignedAccounts')
  );
};

export const incomeStatementConfig = (
  accounts: Record<string, ReferenceAccountInformation>,
  financialYear: string,
  financialYears: string[],
  period: string | null,
  type: ReportType
): FinancialReportStructure['incomeStatementTables'] => {
  const previousYearPeriod = getPreviousYearPeriod(
    period,
    getPreviousYear(financialYear, financialYears) || ''
  );
  const previousYearKey = previousYearPeriod
    ? format(parse(previousYearPeriod, 'yyyyMM'), 'MMMyy')
    : '';

  return {
    active: undefined,
    section: {
      active: undefined,
      table: table(
        'incomeStatement.section.table',
        'label',
        'notes',
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        previousYearPeriod
          ? {
              id: 'previousYear',
              label: format(
                endOfMonth(parse(previousYearPeriod, 'yyyyMM')),
                'yyyy-MM-dd'
              ),
            }
          : undefined,
        'hidden'
      )
        .addRows((rows) =>
          rows
            .addRow(
              'income',
              type === 'k3'
                ? value('Resultaträkning')
                : label('Resultaträkning')
            )
            .addSubRows((rows) => {
              rows.addRow('main').addSubRows((rows) => {
                addIncomes(accounts, rows, previousYearKey);
                addExpenses(accounts, rows, previousYearKey);

                return rows.build();
              });
              return rows.build();
            })
            .build()
        )
        .build(),
      secondTable: table(
        'incomeStatement.section.secondTable',
        'label',
        'notes',
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        previousYearPeriod
          ? {
              id: 'previousYear',
              label: format(
                endOfMonth(parse(previousYearPeriod, 'yyyyMM')),
                'yyyy-MM-dd'
              ),
            }
          : undefined,
        'hidden'
      )
        .addRows((rows) =>
          rows
            .addRow(
              'income',
              type === 'k3'
                ? value('Resultaträkning')
                : label('Resultaträkning')
            )
            .addSubRows((rows) => {
              rows.addRow('main').addSubRows((rows) => {
                addDepreciation(accounts, rows, previousYearKey);

                addUnassignedAccounts(accounts, rows, previousYearKey);

                addResults(accounts, rows, previousYearKey);

                return rows.build();
              });
              return rows.build();
            })
            .build()
        )
        .build(),
      periodTable: table(
        'incomeStatement.section.periodTable',
        'label',
        'notes',
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        previousYearPeriod
          ? {
              id: 'previousYear',
              label: format(
                endOfMonth(parse(previousYearPeriod, 'yyyyMM')),
                'yyyy-MM-dd'
              ),
            }
          : undefined,
        'hidden'
      ).build(),
      secondPeriodTable: table(
        'incomeStatement.section.secondPeriodTable',
        'label',
        'notes',
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        previousYearPeriod
          ? {
              id: 'previousYear',
              label: format(
                endOfMonth(parse(previousYearPeriod, 'yyyyMM')),
                'yyyy-MM-dd'
              ),
            }
          : undefined,
        'hidden'
      ).build(),
    },
  };
};

export const incomeStatementReferences = (): Record<string, string> => ({
  'incomeStatement.netSales.year': id(
    'incomeStatement.section.table.income.main.incomes.1.year'
  ),
  'incomeStatement.netSales.previousYear': id(
    'incomeStatement.section.table.income.main.incomes.1.previousYear'
  ),
  // TODO: this reference no longer exists here
  'incomeStatement.resultAfterFinancial.year': id(
    'incomeStatement.section.table.income.main.resultAfterFinancial.year'
  ),
  'incomeStatement.resultAfterFinancial.previousYear': id(
    'incomeStatement.section.table.income.main.resultAfterFinancial.previousYear'
  ),
});

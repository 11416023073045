import { AgoyTableRow } from '@agoy/document';
import { isActive } from '@agoy/annual-report-document';

const willRender = (row: AgoyTableRow): boolean => {
  return (
    (isActive(row.active) && row.cells !== undefined) ||
    (row.rows?.some(willRender) ?? false)
  );
};

/**
 * Checks if there are no rows that should be rendered.
 *
 * @param rows
 */
const noRows = (rows: AgoyTableRow[]): boolean => {
  return !rows.some(willRender);
};

export default noRows;

import React from 'react';
import styled from '@emotion/styled';

const Root = styled.div`
  display: flex;
  justify-content: center;
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Image = styled.img`
  max-height: calc(100vh - 64px);
  max-width: 100%;
`;

const DisplayImage = ({ src }) => (
  <Root>
    <ImageContainer>
      <Image alt="" src={src} />
    </ImageContainer>
  </Root>
);

export default DisplayImage;

/*
 * This is a GENERATED file, do not change manually!
 * Generated:
 * - from file k3-arsredovisning-2021-10-31-rev20230131 - Funktionsindelad resultaträkni.csv
 * - Comments: AGOY-6685
 */

import {
  id,
  account,
  sum,
  sumAllowNull,
  ifOrElse,
  or,
  multiply,
  label,
  ref,
  not,
  value,
  refs,
  ReferenceAccountInformation,
  TimePeriod,
  AccountValueType,
} from '@agoy/document';
import { IxbrlCell } from '../../../../common';
import {
  table,
  RowsBuilder,
  ixbrlCell,
} from '../../../../common/utils/util';
import { AnnualReportType, AnnualReportVersion } from '../../../../document';
import { range, StatementSection } from '@agoy/annual-report-document';

const accountRangeRows = (
  accounts: Record<string, ReferenceAccountInformation>,
  first: number,
  last: number,
  rows: RowsBuilder,
  switchSign: boolean,
  currentPeriod: string,
  previousPeriod: string,
  modifier?: AccountValueType | undefined
): RowsBuilder => {
  range(first, last + 1).forEach((n) => {
    const accountNumber = n.toString();
    const accountInformation = accounts[accountNumber];
    if (accountInformation) {
      rows
        .addRowWithType(
          accountNumber,
          'account',
          label(`${accountInformation.accountName}`),
          undefined,
          ref(
            switchSign
              ? multiply(
                  -1,
                  or(account(accountNumber, modifier, currentPeriod), 0)
                )
              : or(account(accountNumber, modifier, currentPeriod), 0)
          ),
          ref(
            switchSign
              ? multiply(
                  -1,
                  or(account(accountNumber, modifier, previousPeriod), 0)
                )
              : or(account(accountNumber, modifier, previousPeriod), 0)
          )
        )
        .setSortKey(n);
    }
  });
  return rows;
};

export const functionalIncomeStatementK3v2Config = (
  accounts: Record<string, ReferenceAccountInformation>,
  period: TimePeriod,
  previousPeriod: TimePeriod | null,
  type: AnnualReportType,
  isDigitalSubmission: boolean | null,
  documentTypeVersion: AnnualReportVersion = '2',
  accountModifier: AccountValueType = 'change'
): StatementSection => ({
  active: undefined,
  section: {
    active: undefined,
    table: table<IxbrlCell>(
      'incomeStatement.section.table',
      { id: 'label', dataType: 'text' },
      { id: 'notes', dataType: 'numeric' },
      {
        id: 'year',
        label: `${period.startDateISO} ${period.endDateISO}`,
        dataType: 'numeric',
      },
      previousPeriod
        ? {
            id: 'previousYear',
            label: `${previousPeriod.startDateISO} ${previousPeriod.endDateISO}`,
            dataType: 'numeric',
          }
        : undefined
    )
      .addRows((rows) => {
        rows
          .addRowWithType(
            'RorelseresultatAbstract',
            'header',
            value('Rörelseresultat'),
            refs(),
            undefined,
            undefined
          )
          .addSubRows((rows) => {
            rows
              .addRowWithType(
                'BruttoresultatAbstract',
                'header',
                value('Bruttoresultat'),
                refs(),
                undefined,
                undefined
              )
              .addSubRows((rows) => {
                rows
                  .addRowWithType(
                    'Nettoomsattning',
                    'alwaysDisplay',
                    value('Nettoomsättning'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.RorelseresultatAbstract.BruttoresultatAbstract.Nettoomsattning.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:Nettoomsattning',
                        contextRef: 'period0',
                        standardRubrik: 'Nettoomsättning',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.RorelseresultatAbstract.BruttoresultatAbstract.Nettoomsattning.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:Nettoomsattning',
                        contextRef: 'period1',
                        standardRubrik: 'Nettoomsättning',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    accountRangeRows(
                      accounts,
                      3001,
                      3001,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );
                    accountRangeRows(
                      accounts,
                      3740,
                      3740,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );

                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'KostnadSaldaVaror',
                    'row',
                    value('Kostnad för sålda varor'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.RorelseresultatAbstract.BruttoresultatAbstract.KostnadSaldaVaror.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:KostnadSaldaVaror',
                        contextRef: 'period0',
                        standardRubrik: 'Kostnad för sålda varor',
                        saldo: 'debit',
                        negateValue: true,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.RorelseresultatAbstract.BruttoresultatAbstract.KostnadSaldaVaror.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:KostnadSaldaVaror',
                        contextRef: 'period1',
                        standardRubrik: 'Kostnad för sålda varor',
                        saldo: 'debit',
                        negateValue: true,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    accountRangeRows(
                      accounts,
                      3870,
                      3870,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );
                    accountRangeRows(
                      accounts,
                      3991,
                      3991,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );
                    accountRangeRows(
                      accounts,
                      4000,
                      4000,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );
                    accountRangeRows(
                      accounts,
                      4960,
                      4960,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );
                    accountRangeRows(
                      accounts,
                      4961,
                      4961,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );

                    return rows.build();
                  });
                return rows.build();
              });
            rows
              .addRowWithType(
                'BruttoresultatFunktionsindelad',
                'alwaysDisplaySum',
                value('Bruttoresultat'),
                undefined,
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'incomeStatement.section.table.RorelseresultatAbstract.BruttoresultatAbstract.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    name: 'se-gen-base:BruttoresultatFunktionsindelad',
                    contextRef: 'period0',
                    standardRubrik:
                      'Bruttoresultat i funktionsindelad resultaträkning',
                    saldo: 'credit',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'incomeStatement.section.table.RorelseresultatAbstract.BruttoresultatAbstract.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    name: 'se-gen-base:BruttoresultatFunktionsindelad',
                    contextRef: 'period1',
                    standardRubrik:
                      'Bruttoresultat i funktionsindelad resultaträkning',
                    saldo: 'credit',
                    negateValue: false,
                  }
                )
              )
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(or(account('$ub', accountModifier, 'year'), 0)),
                ref(or(account('$ib', accountModifier, 'previousYear'), 0))
              )
              .addSubRows((rows) => {
                return rows.build();
              });
            rows
              .addRowWithType(
                'FunktionerMmAbstract',
                'header',
                value('Funktioner m.m.'),
                refs(),
                undefined,
                undefined
              )
              .addSubRows((rows) => {
                rows
                  .addRowWithType(
                    'Forsaljningskostnader',
                    'row',
                    value('Försäljningskostnader'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.RorelseresultatAbstract.FunktionerMmAbstract.Forsaljningskostnader.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:Forsaljningskostnader',
                        contextRef: 'period0',
                        standardRubrik: 'Försäljningskostnader',
                        saldo: 'debit',
                        negateValue: true,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.RorelseresultatAbstract.FunktionerMmAbstract.Forsaljningskostnader.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:Forsaljningskostnader',
                        contextRef: 'period1',
                        standardRubrik: 'Försäljningskostnader',
                        saldo: 'debit',
                        negateValue: true,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    accountRangeRows(
                      accounts,
                      5060,
                      5060,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );

                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'Administrationskostnader',
                    'row',
                    value('Administrationskostnader'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.RorelseresultatAbstract.FunktionerMmAbstract.Administrationskostnader.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:Administrationskostnader',
                        contextRef: 'period0',
                        standardRubrik: 'Administrationskostnader',
                        saldo: 'debit',
                        negateValue: true,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.RorelseresultatAbstract.FunktionerMmAbstract.Administrationskostnader.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:Administrationskostnader',
                        contextRef: 'period1',
                        standardRubrik: 'Administrationskostnader',
                        saldo: 'debit',
                        negateValue: true,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    accountRangeRows(
                      accounts,
                      5420,
                      5420,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );

                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'ForskningsUtvecklingskostnader',
                    'row',
                    value('Forsknings- och utvecklingskostnader'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.RorelseresultatAbstract.FunktionerMmAbstract.ForskningsUtvecklingskostnader.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:ForskningsUtvecklingskostnader',
                        contextRef: 'period0',
                        standardRubrik: 'Forsknings- och utvecklingskostnader',
                        saldo: 'debit',
                        negateValue: true,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.RorelseresultatAbstract.FunktionerMmAbstract.ForskningsUtvecklingskostnader.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:ForskningsUtvecklingskostnader',
                        contextRef: 'period1',
                        standardRubrik: 'Forsknings- och utvecklingskostnader',
                        saldo: 'debit',
                        negateValue: true,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    accountRangeRows(
                      accounts,
                      5800,
                      5800,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );

                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'OvrigaRorelseintakter',
                    'row',
                    value('Övriga rörelseintäkter'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.RorelseresultatAbstract.FunktionerMmAbstract.OvrigaRorelseintakter.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:OvrigaRorelseintakter',
                        contextRef: 'period0',
                        standardRubrik: 'Övriga rörelseintäkter',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.RorelseresultatAbstract.FunktionerMmAbstract.OvrigaRorelseintakter.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:OvrigaRorelseintakter',
                        contextRef: 'period1',
                        standardRubrik: 'Övriga rörelseintäkter',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    accountRangeRows(
                      accounts,
                      6210,
                      6210,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );

                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'OvrigaRorelsekostnaderFunktionsindelad',
                    'row',
                    value('Övriga rörelsekostnader'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.RorelseresultatAbstract.FunktionerMmAbstract.OvrigaRorelsekostnaderFunktionsindelad.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:OvrigaRorelsekostnaderFunktionsindelad',
                        contextRef: 'period0',
                        standardRubrik:
                          'Övriga rörelsekostnader i funktionsindelad resultaträkning',
                        saldo: 'debit',
                        negateValue: true,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.RorelseresultatAbstract.FunktionerMmAbstract.OvrigaRorelsekostnaderFunktionsindelad.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:OvrigaRorelsekostnaderFunktionsindelad',
                        contextRef: 'period1',
                        standardRubrik:
                          'Övriga rörelsekostnader i funktionsindelad resultaträkning',
                        saldo: 'debit',
                        negateValue: true,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    accountRangeRows(
                      accounts,
                      6310,
                      6310,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );

                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'AndelarIntresseforetagGemensamtStyrdaForetagResultat',
                    'row',
                    value(
                      'Andelar i intresseföretags och gemensamt styrda företags resultat'
                    ),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.RorelseresultatAbstract.FunktionerMmAbstract.AndelarIntresseforetagGemensamtStyrdaForetagResultat.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagResultat',
                        contextRef: 'period0',
                        standardRubrik:
                          'Andelar i intresseföretags och gemensamt styrda företags resultat',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.RorelseresultatAbstract.FunktionerMmAbstract.AndelarIntresseforetagGemensamtStyrdaForetagResultat.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetagResultat',
                        contextRef: 'period1',
                        standardRubrik:
                          'Andelar i intresseföretags och gemensamt styrda företags resultat',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    accountRangeRows(
                      accounts,
                      6420,
                      6420,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );
                    accountRangeRows(
                      accounts,
                      7210,
                      7210,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );
                    accountRangeRows(
                      accounts,
                      7510,
                      7510,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );
                    accountRangeRows(
                      accounts,
                      7710,
                      7710,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );
                    accountRangeRows(
                      accounts,
                      7810,
                      7810,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );
                    accountRangeRows(
                      accounts,
                      7832,
                      7832,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );
                    accountRangeRows(
                      accounts,
                      7834,
                      7834,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );

                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'RealisationsresultatForsaljningIntresseforetagGemensamtStyrdaForetag',
                    'row',
                    value(
                      'Realisationsresultat vid försäljning av intresseföretag och gemensamt styrda företag'
                    ),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.RorelseresultatAbstract.FunktionerMmAbstract.RealisationsresultatForsaljningIntresseforetagGemensamtStyrdaForetag.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:RealisationsresultatForsaljningIntresseforetagGemensamtStyrdaForetag',
                        contextRef: 'period0',
                        standardRubrik:
                          'Realisationsresultat vid försäljning av intresseföretag och gemensamt styrda företag',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.RorelseresultatAbstract.FunktionerMmAbstract.RealisationsresultatForsaljningIntresseforetagGemensamtStyrdaForetag.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:RealisationsresultatForsaljningIntresseforetagGemensamtStyrdaForetag',
                        contextRef: 'period1',
                        standardRubrik:
                          'Realisationsresultat vid försäljning av intresseföretag och gemensamt styrda företag',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    accountRangeRows(
                      accounts,
                      7973,
                      7973,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );

                    return rows.build();
                  });
                return rows.build();
              });
            rows
              .addRowWithType(
                'FunktionerMm',
                'sum',
                value('Summa funktioner m.m.'),
                undefined,
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'incomeStatement.section.table.RorelseresultatAbstract.FunktionerMmAbstract.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    name: 'se-gen-base:FunktionerMm',
                    contextRef: 'period0',
                    standardRubrik: 'Funktioner m.m.',
                    saldo: 'debit',
                    negateValue: true,
                  }
                ),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'incomeStatement.section.table.RorelseresultatAbstract.FunktionerMmAbstract.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    name: 'se-gen-base:FunktionerMm',
                    contextRef: 'period1',
                    standardRubrik: 'Funktioner m.m.',
                    saldo: 'debit',
                    negateValue: true,
                  }
                )
              )
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(or(account('$ub', accountModifier, 'year'), 0)),
                ref(or(account('$ib', accountModifier, 'previousYear'), 0))
              )
              .addSubRows((rows) => {
                return rows.build();
              });
            return rows.build();
          });
        rows
          .addRowWithType(
            'Rorelseresultat',
            'sum',
            value('Rörelseresultat'),
            undefined,
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'incomeStatement.section.table.RorelseresultatAbstract.*.year'
                  )
                )
              ),
              {
                type: 'monetaryItemType',
                name: 'se-gen-base:Rorelseresultat',
                contextRef: 'period0',
                standardRubrik: 'Rörelseresultat',
                saldo: 'credit',
                negateValue: false,
              }
            ),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'incomeStatement.section.table.RorelseresultatAbstract.*.previousYear'
                  )
                )
              ),
              {
                type: 'monetaryItemType',
                name: 'se-gen-base:Rorelseresultat',
                contextRef: 'period1',
                standardRubrik: 'Rörelseresultat',
                saldo: 'credit',
                negateValue: false,
              }
            )
          )
          .newRowTemplateWithType(
            'account',
            label('$label'),
            undefined,
            ref(or(account('$ub', accountModifier, 'year'), 0)),
            ref(or(account('$ib', accountModifier, 'previousYear'), 0))
          )
          .addSubRows((rows) => {
            return rows.build();
          });
        rows
          .addRowWithType(
            'FinansiellaPosterAbstract',
            'header',
            value('Resultat från finansiella poster'),
            refs(),
            undefined,
            undefined
          )
          .addSubRows((rows) => {
            rows
              .addRowActive(ref(id('settings.isNedskrivningEgenPost')))
              .addRowWithType(
                'FinansiellaPosterNedskrivningEgenPostAbstract',
                'header',
                value(
                  'Resultat från finansiella poster - med nedskrivningar i egen post'
                ),
                refs(),
                undefined,
                undefined
              )
              .addSubRows((rows) => {
                rows
                  .addRowWithType(
                    'ResultatAndelarKoncernforetag',
                    'row',
                    value('Resultat från andelar i koncernföretag'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningEgenPostAbstract.ResultatAndelarKoncernforetag.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:ResultatAndelarKoncernforetag',
                        contextRef: 'period0',
                        standardRubrik:
                          'Resultat från andelar i koncernföretag exklusive nedskrivningar',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningEgenPostAbstract.ResultatAndelarKoncernforetag.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:ResultatAndelarKoncernforetag',
                        contextRef: 'period1',
                        standardRubrik:
                          'Resultat från andelar i koncernföretag exklusive nedskrivningar',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    accountRangeRows(
                      accounts,
                      8000,
                      8069,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );

                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'ResultatAndelarIntresseforetagGemensamtStyrda',
                    'row',
                    value(
                      'Resultat från andelar i intresseföretag och gemensamt styrda företag'
                    ),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningEgenPostAbstract.ResultatAndelarIntresseforetagGemensamtStyrda.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:ResultatAndelarIntresseforetagGemensamtStyrda',
                        contextRef: 'period0',
                        standardRubrik:
                          'Resultat från andelar i intresseföretag och gemensamt styrda företag exklusive nedskrivningar',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningEgenPostAbstract.ResultatAndelarIntresseforetagGemensamtStyrda.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:ResultatAndelarIntresseforetagGemensamtStyrda',
                        contextRef: 'period1',
                        standardRubrik:
                          'Resultat från andelar i intresseföretag och gemensamt styrda företag exklusive nedskrivningar',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    accountRangeRows(
                      accounts,
                      8100,
                      8112,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );
                    accountRangeRows(
                      accounts,
                      8114,
                      8117,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );
                    accountRangeRows(
                      accounts,
                      8119,
                      8122,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );
                    accountRangeRows(
                      accounts,
                      8124,
                      8169,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );

                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'ResultatOvrigaforetagAgarintresse',
                    'row',
                    value(
                      'Resultat från övriga företag som det finns ett ägarintresse i'
                    ),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningEgenPostAbstract.ResultatOvrigaforetagAgarintresse.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:ResultatOvrigaforetagAgarintresse',
                        contextRef: 'period0',
                        standardRubrik:
                          'Resultat från övriga företag som det finns ett ägarintresse i exklusive nedskrivningar',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningEgenPostAbstract.ResultatOvrigaforetagAgarintresse.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:ResultatOvrigaforetagAgarintresse',
                        contextRef: 'period1',
                        standardRubrik:
                          'Resultat från övriga företag som det finns ett ägarintresse i exklusive nedskrivningar',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    accountRangeRows(
                      accounts,
                      8113,
                      8113,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );
                    accountRangeRows(
                      accounts,
                      8118,
                      8118,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );
                    accountRangeRows(
                      accounts,
                      8123,
                      8123,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );

                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'ResultatOvrigaFinansiellaAnlaggningstillgangar',
                    'row',
                    value(
                      'Resultat från övriga värdepapper och fordringar som är anläggningstillgångar'
                    ),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningEgenPostAbstract.ResultatOvrigaFinansiellaAnlaggningstillgangar.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:ResultatOvrigaFinansiellaAnlaggningstillgangar',
                        contextRef: 'period0',
                        standardRubrik:
                          'Resultat från övriga värdepapper och fordringar som är anläggningstillgångar exklusive nedskrivningar',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningEgenPostAbstract.ResultatOvrigaFinansiellaAnlaggningstillgangar.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:ResultatOvrigaFinansiellaAnlaggningstillgangar',
                        contextRef: 'period1',
                        standardRubrik:
                          'Resultat från övriga värdepapper och fordringar som är anläggningstillgångar exklusive nedskrivningar',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    accountRangeRows(
                      accounts,
                      8200,
                      8269,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );

                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'OvrigaRanteintakterLiknandeResultatposter',
                    'row',
                    value('Övriga ränteintäkter och liknande resultatposter'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningEgenPostAbstract.OvrigaRanteintakterLiknandeResultatposter.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:OvrigaRanteintakterLiknandeResultatposter',
                        contextRef: 'period0',
                        standardRubrik:
                          'Övriga ränteintäkter och liknande resultatposter exklusive nedskrivningar',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningEgenPostAbstract.OvrigaRanteintakterLiknandeResultatposter.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:OvrigaRanteintakterLiknandeResultatposter',
                        contextRef: 'period1',
                        standardRubrik:
                          'Övriga ränteintäkter och liknande resultatposter exklusive nedskrivningar',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    accountRangeRows(
                      accounts,
                      8300,
                      8399,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );

                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'NedskrivningarFinansiellaAnlaggningstillgangarKortfristigaPlaceringar',
                    'row',
                    value(
                      'Nedskrivningar av finansiella anläggningstillgångar och kortfristiga placeringar'
                    ),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningEgenPostAbstract.NedskrivningarFinansiellaAnlaggningstillgangarKortfristigaPlaceringar.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:NedskrivningarFinansiellaAnlaggningstillgangarKortfristigaPlaceringar',
                        contextRef: 'period0',
                        standardRubrik:
                          'Nedskrivningar av finansiella anläggningstillgångar och kortfristiga placeringar',
                        saldo: 'debit',
                        negateValue: true,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningEgenPostAbstract.NedskrivningarFinansiellaAnlaggningstillgangarKortfristigaPlaceringar.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:NedskrivningarFinansiellaAnlaggningstillgangarKortfristigaPlaceringar',
                        contextRef: 'period1',
                        standardRubrik:
                          'Nedskrivningar av finansiella anläggningstillgångar och kortfristiga placeringar',
                        saldo: 'debit',
                        negateValue: true,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    accountRangeRows(
                      accounts,
                      8070,
                      8099,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );
                    accountRangeRows(
                      accounts,
                      8170,
                      8199,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );
                    accountRangeRows(
                      accounts,
                      8270,
                      8299,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );

                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'RantekostnaderLiknandeResultatposterEgenPost',
                    'row',
                    value('Räntekostnader och liknande resultatposter'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningEgenPostAbstract.RantekostnaderLiknandeResultatposterEgenPost.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:RantekostnaderLiknandeResultatposter',
                        contextRef: 'period0',
                        standardRubrik:
                          'Räntekostnader och liknande resultatposter',
                        saldo: 'debit',
                        negateValue: true,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningEgenPostAbstract.RantekostnaderLiknandeResultatposterEgenPost.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:RantekostnaderLiknandeResultatposter',
                        contextRef: 'period1',
                        standardRubrik:
                          'Räntekostnader och liknande resultatposter',
                        saldo: 'debit',
                        negateValue: true,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    accountRangeRows(
                      accounts,
                      8400,
                      8799,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );

                    return rows.build();
                  });
                return rows.build();
              });
            rows
              .addRowActive(ref(not(id('settings.isNedskrivningEgenPost'))))
              .addRowWithType(
                'FinansiellaPosterNedskrivningRespektivePostAbstract',
                'header',
                value(
                  'Resultat från finansiella poster - med nedskrivningar i respektive post'
                ),
                refs(),
                undefined,
                undefined
              )
              .addSubRows((rows) => {
                rows
                  .addRowWithType(
                    'ResultatAndelarKoncernforetagInklNedskrivning',
                    'row',
                    value('Resultat från andelar i koncernföretag'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningRespektivePostAbstract.ResultatAndelarKoncernforetagInklNedskrivning.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:ResultatAndelarKoncernforetagInklNedskrivning',
                        contextRef: 'period0',
                        standardRubrik:
                          'Resultat från andelar i koncernföretag inklusive nedskrivningar',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningRespektivePostAbstract.ResultatAndelarKoncernforetagInklNedskrivning.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:ResultatAndelarKoncernforetagInklNedskrivning',
                        contextRef: 'period1',
                        standardRubrik:
                          'Resultat från andelar i koncernföretag inklusive nedskrivningar',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    accountRangeRows(
                      accounts,
                      8000,
                      8069,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );
                    accountRangeRows(
                      accounts,
                      8070,
                      8099,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );

                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'ResultatAndelarIntresseforetagGemensamtStyrdaInklNedskrivning',
                    'row',
                    value(
                      'Resultat från andelar i intresseföretag och gemensamt styrda företag'
                    ),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningRespektivePostAbstract.ResultatAndelarIntresseforetagGemensamtStyrdaInklNedskrivning.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:ResultatAndelarIntresseforetagGemensamtStyrdaInklNedskrivning',
                        contextRef: 'period0',
                        standardRubrik:
                          'Resultat från andelar i intresseföretag och gemensamt styrda företag inklusive nedskrivningar',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningRespektivePostAbstract.ResultatAndelarIntresseforetagGemensamtStyrdaInklNedskrivning.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:ResultatAndelarIntresseforetagGemensamtStyrdaInklNedskrivning',
                        contextRef: 'period1',
                        standardRubrik:
                          'Resultat från andelar i intresseföretag och gemensamt styrda företag inklusive nedskrivningar',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    accountRangeRows(
                      accounts,
                      8100,
                      8112,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );
                    accountRangeRows(
                      accounts,
                      8114,
                      8117,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );
                    accountRangeRows(
                      accounts,
                      8119,
                      8122,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );
                    accountRangeRows(
                      accounts,
                      8124,
                      8169,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );
                    accountRangeRows(
                      accounts,
                      8170,
                      8199,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );

                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'ResultatOvrigaforetagAgarintresseInklNedskrivning',
                    'row',
                    value(
                      'Resultat från övriga företag som det finns ett ägarintresse i'
                    ),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningRespektivePostAbstract.ResultatOvrigaforetagAgarintresseInklNedskrivning.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:ResultatOvrigaforetagAgarintresseInklNedskrivning',
                        contextRef: 'period0',
                        standardRubrik:
                          'Resultat från övriga företag som det finns ett ägarintresse i inklusive nedskrivningar',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningRespektivePostAbstract.ResultatOvrigaforetagAgarintresseInklNedskrivning.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:ResultatOvrigaforetagAgarintresseInklNedskrivning',
                        contextRef: 'period1',
                        standardRubrik:
                          'Resultat från övriga företag som det finns ett ägarintresse i inklusive nedskrivningar',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    accountRangeRows(
                      accounts,
                      8113,
                      8113,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );
                    accountRangeRows(
                      accounts,
                      8118,
                      8118,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );
                    accountRangeRows(
                      accounts,
                      8123,
                      8123,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );
                    accountRangeRows(
                      accounts,
                      8270,
                      8299,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );

                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'ResultatOvrigaFinansiellaAnlaggningstillgangarInklNedskrivning',
                    'row',
                    value(
                      'Resultat från övriga värdepapper och fordringar som är anläggningstillgångar'
                    ),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningRespektivePostAbstract.ResultatOvrigaFinansiellaAnlaggningstillgangarInklNedskrivning.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:ResultatOvrigaFinansiellaAnlaggningstillgangarInklNedskrivning',
                        contextRef: 'period0',
                        standardRubrik:
                          'Resultat från övriga värdepapper och fordringar som är anläggningstillgångar inklusive nedskrivningar',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningRespektivePostAbstract.ResultatOvrigaFinansiellaAnlaggningstillgangarInklNedskrivning.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:ResultatOvrigaFinansiellaAnlaggningstillgangarInklNedskrivning',
                        contextRef: 'period1',
                        standardRubrik:
                          'Resultat från övriga värdepapper och fordringar som är anläggningstillgångar inklusive nedskrivningar',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    accountRangeRows(
                      accounts,
                      8200,
                      8269,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );

                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'OvrigaRanteintakterLiknandeResultatposterInklNedskrivning',
                    'row',
                    value('Övriga ränteintäkter och liknande resultatposter'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningRespektivePostAbstract.OvrigaRanteintakterLiknandeResultatposterInklNedskrivning.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:OvrigaRanteintakterLiknandeResultatposterInklNedskrivning',
                        contextRef: 'period0',
                        standardRubrik:
                          'Övriga ränteintäkter och liknande resultatposter inklusive nedskrivningar',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningRespektivePostAbstract.OvrigaRanteintakterLiknandeResultatposterInklNedskrivning.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:OvrigaRanteintakterLiknandeResultatposterInklNedskrivning',
                        contextRef: 'period1',
                        standardRubrik:
                          'Övriga ränteintäkter och liknande resultatposter inklusive nedskrivningar',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    accountRangeRows(
                      accounts,
                      8300,
                      8399,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );

                    return rows.build();
                  });
                rows
                  .addRowWithType(
                    'RantekostnaderLiknandeResultatposterRespektivePost',
                    'row',
                    value('Räntekostnader och liknande resultatposter'),
                    refs(),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningRespektivePostAbstract.RantekostnaderLiknandeResultatposterRespektivePost.*.year'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:RantekostnaderLiknandeResultatposter',
                        contextRef: 'period0',
                        standardRubrik:
                          'Räntekostnader och liknande resultatposter',
                        saldo: 'debit',
                        negateValue: true,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sum(
                          id(
                            'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningRespektivePostAbstract.RantekostnaderLiknandeResultatposterRespektivePost.*.previousYear'
                          )
                        )
                      ),
                      {
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:RantekostnaderLiknandeResultatposter',
                        contextRef: 'period1',
                        standardRubrik:
                          'Räntekostnader och liknande resultatposter',
                        saldo: 'debit',
                        negateValue: true,
                      }
                    )
                  ) // <<< end row_empty_value ---
                  .newRowTemplateWithType(
                    'account',
                    label('$label'),
                    undefined,
                    ref(
                      multiply(
                        -1,
                        or(account('$ub', accountModifier, 'year'), 0)
                      )
                    ),
                    ref(
                      multiply(
                        -1,
                        or(account('$ib', accountModifier, 'previousYear'), 0)
                      )
                    )
                  )
                  .addSubRows((rows) => {
                    accountRangeRows(
                      accounts,
                      8400,
                      8799,
                      rows,
                      true,
                      'year',
                      'previousYear',
                      accountModifier
                    );

                    return rows.build();
                  });
                return rows.build();
              });
            return rows.build();
          });
        rows
          .addRowWithType(
            'FinansiellaPoster',
            'sum',
            value('Summa resultat från finansiella poster'),
            undefined,
            ixbrlCell(
              ref(
                ifOrElse(
                  id('settings.isNedskrivningEgenPost'),
                  sumAllowNull(
                    id(
                      'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningEgenPostAbstract.*.year'
                    )
                  ),
                  sumAllowNull(
                    id(
                      'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningRespektivePostAbstract.*.year'
                    )
                  )
                )
              ),
              {
                type: 'monetaryItemType',
                name: 'se-gen-base:FinansiellaPoster',
                contextRef: 'period0',
                standardRubrik: 'Resultat från finansiella poster',
                saldo: 'credit',
                negateValue: false,
              }
            ),
            ixbrlCell(
              ref(
                ifOrElse(
                  id('settings.isNedskrivningEgenPost'),
                  sumAllowNull(
                    id(
                      'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningEgenPostAbstract.*.previousYear'
                    )
                  ),
                  sumAllowNull(
                    id(
                      'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningRespektivePostAbstract.*.previousYear'
                    )
                  )
                )
              ),
              {
                type: 'monetaryItemType',
                name: 'se-gen-base:FinansiellaPoster',
                contextRef: 'period1',
                standardRubrik: 'Resultat från finansiella poster',
                saldo: 'credit',
                negateValue: false,
              }
            )
          )
          .newRowTemplateWithType(
            'account',
            label('$label'),
            undefined,
            ref(or(account('$ub', accountModifier, 'year'), 0)),
            ref(or(account('$ib', accountModifier, 'previousYear'), 0))
          )
          .addSubRows((rows) => {
            return rows.build();
          });
        rows
          .addRowWithType(
            'ResultatEfterFinansiellaPoster',
            'alwaysDisplaySum',
            value('Resultat efter finansiella poster'),
            undefined,
            ixbrlCell(
              ref(
                sumAllowNull(
                  id('incomeStatement.section.table.Rorelseresultat.year'),
                  id('incomeStatement.section.table.FinansiellaPoster.year')
                )
              ),
              {
                type: 'monetaryItemType',
                name: 'se-gen-base:ResultatEfterFinansiellaPoster',
                contextRef: 'period0',
                standardRubrik: 'Resultat efter finansiella poster',
                saldo: 'credit',
                negateValue: false,
              }
            ),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'incomeStatement.section.table.Rorelseresultat.previousYear'
                  ),
                  id(
                    'incomeStatement.section.table.FinansiellaPoster.previousYear'
                  )
                )
              ),
              {
                type: 'monetaryItemType',
                name: 'se-gen-base:ResultatEfterFinansiellaPoster',
                contextRef: 'period1',
                standardRubrik: 'Resultat efter finansiella poster',
                saldo: 'credit',
                negateValue: false,
              }
            )
          )
          .newRowTemplateWithType(
            'account',
            label('$label'),
            undefined,
            ref(or(account('$ub', accountModifier, 'year'), 0)),
            ref(or(account('$ib', accountModifier, 'previousYear'), 0))
          )
          .addSubRows((rows) => {
            return rows.build();
          });
        rows
          .addRowWithType(
            'BokslutsdispositionerAbstract',
            'header',
            value('Bokslutsdispositioner'),
            refs(),
            undefined,
            undefined
          )
          .addSubRows((rows) => {
            rows
              .addRowWithType(
                'ErhallnaKoncernbidrag',
                'row',
                value('Erhållna koncernbidrag'),
                refs(),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'incomeStatement.section.table.BokslutsdispositionerAbstract.ErhallnaKoncernbidrag.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:ErhallnaKoncernbidrag',
                    contextRef: 'period0',
                    standardRubrik: 'Erhållna koncernbidrag',
                    saldo: 'credit',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'incomeStatement.section.table.BokslutsdispositionerAbstract.ErhallnaKoncernbidrag.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:ErhallnaKoncernbidrag',
                    contextRef: 'period1',
                    standardRubrik: 'Erhållna koncernbidrag',
                    saldo: 'credit',
                    negateValue: false,
                  }
                )
              ) // <<< end row_empty_value ---
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(
                  multiply(-1, or(account('$ub', accountModifier, 'year'), 0))
                ),
                ref(
                  multiply(
                    -1,
                    or(account('$ib', accountModifier, 'previousYear'), 0)
                  )
                )
              )
              .addSubRows((rows) => {
                accountRangeRows(
                  accounts,
                  8811,
                  8811,
                  rows,
                  true,
                  'year',
                  'previousYear',
                  accountModifier
                );
                accountRangeRows(
                  accounts,
                  8820,
                  8820,
                  rows,
                  true,
                  'year',
                  'previousYear',
                  accountModifier
                );

                return rows.build();
              });
            rows
              .addRowWithType(
                'LamnadeKoncernbidrag',
                'row',
                value('Lämnade koncernbidrag'),
                refs(),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'incomeStatement.section.table.BokslutsdispositionerAbstract.LamnadeKoncernbidrag.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:LamnadeKoncernbidrag',
                    contextRef: 'period0',
                    standardRubrik: 'Lämnade koncernbidrag',
                    saldo: 'debit',
                    negateValue: true,
                  }
                ),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'incomeStatement.section.table.BokslutsdispositionerAbstract.LamnadeKoncernbidrag.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:LamnadeKoncernbidrag',
                    contextRef: 'period1',
                    standardRubrik: 'Lämnade koncernbidrag',
                    saldo: 'debit',
                    negateValue: true,
                  }
                )
              ) // <<< end row_empty_value ---
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(
                  multiply(-1, or(account('$ub', accountModifier, 'year'), 0))
                ),
                ref(
                  multiply(
                    -1,
                    or(account('$ib', accountModifier, 'previousYear'), 0)
                  )
                )
              )
              .addSubRows((rows) => {
                return rows.build();
              });
            rows
              .addRowWithType(
                'ForandringPeriodiseringsfond',
                'row',
                value('Förändring av periodiseringsfonder'),
                refs(),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'incomeStatement.section.table.BokslutsdispositionerAbstract.ForandringPeriodiseringsfond.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:ForandringPeriodiseringsfond',
                    contextRef: 'period0',
                    standardRubrik: 'Förändring av periodiseringsfonder',
                    saldo: 'credit',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'incomeStatement.section.table.BokslutsdispositionerAbstract.ForandringPeriodiseringsfond.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:ForandringPeriodiseringsfond',
                    contextRef: 'period1',
                    standardRubrik: 'Förändring av periodiseringsfonder',
                    saldo: 'credit',
                    negateValue: false,
                  }
                )
              ) // <<< end row_empty_value ---
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(
                  multiply(-1, or(account('$ub', accountModifier, 'year'), 0))
                ),
                ref(
                  multiply(
                    -1,
                    or(account('$ib', accountModifier, 'previousYear'), 0)
                  )
                )
              )
              .addSubRows((rows) => {
                accountRangeRows(
                  accounts,
                  8830,
                  8830,
                  rows,
                  true,
                  'year',
                  'previousYear',
                  accountModifier
                );

                return rows.build();
              });
            rows
              .addRowWithType(
                'ForandringOveravskrivningar',
                'row',
                value('Förändring av överavskrivningar'),
                refs(),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'incomeStatement.section.table.BokslutsdispositionerAbstract.ForandringOveravskrivningar.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:ForandringOveravskrivningar',
                    contextRef: 'period0',
                    standardRubrik: 'Förändring av överavskrivningar',
                    saldo: 'credit',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'incomeStatement.section.table.BokslutsdispositionerAbstract.ForandringOveravskrivningar.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:ForandringOveravskrivningar',
                    contextRef: 'period1',
                    standardRubrik: 'Förändring av överavskrivningar',
                    saldo: 'credit',
                    negateValue: false,
                  }
                )
              ) // <<< end row_empty_value ---
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(
                  multiply(-1, or(account('$ub', accountModifier, 'year'), 0))
                ),
                ref(
                  multiply(
                    -1,
                    or(account('$ib', accountModifier, 'previousYear'), 0)
                  )
                )
              )
              .addSubRows((rows) => {
                accountRangeRows(
                  accounts,
                  8853,
                  8853,
                  rows,
                  true,
                  'year',
                  'previousYear',
                  accountModifier
                );

                return rows.build();
              });
            rows
              .addRowWithType(
                'ForandringErsattningsfond',
                'row',
                value('Förändring av ersättningsfonder'),
                refs(),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'incomeStatement.section.table.BokslutsdispositionerAbstract.ForandringErsattningsfond.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:ForandringErsattningsfond',
                    contextRef: 'period0',
                    standardRubrik: 'Förändring av ersättningsfonder',
                    saldo: 'credit',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'incomeStatement.section.table.BokslutsdispositionerAbstract.ForandringErsattningsfond.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:ForandringErsattningsfond',
                    contextRef: 'period1',
                    standardRubrik: 'Förändring av ersättningsfonder',
                    saldo: 'credit',
                    negateValue: false,
                  }
                )
              ) // <<< end row_empty_value ---
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(
                  multiply(-1, or(account('$ub', accountModifier, 'year'), 0))
                ),
                ref(
                  multiply(
                    -1,
                    or(account('$ib', accountModifier, 'previousYear'), 0)
                  )
                )
              )
              .addSubRows((rows) => {
                accountRangeRows(
                  accounts,
                  8861,
                  8861,
                  rows,
                  true,
                  'year',
                  'previousYear',
                  accountModifier
                );

                return rows.build();
              });
            rows
              .addRowWithType(
                'AndraBokslutsdispositioner',
                'row',
                value('Andra bokslutsdispositioner'),
                refs(),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'incomeStatement.section.table.BokslutsdispositionerAbstract.AndraBokslutsdispositioner.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:AndraBokslutsdispositioner',
                    contextRef: 'period0',
                    standardRubrik:
                      'Andra bokslutsdispositioner än koncernbidrag, förändring av periodiseringsfonder, förändring av överavskrivningar och förändring av ersättningsfonder',
                    saldo: 'credit',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'incomeStatement.section.table.BokslutsdispositionerAbstract.AndraBokslutsdispositioner.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:AndraBokslutsdispositioner',
                    contextRef: 'period1',
                    standardRubrik:
                      'Andra bokslutsdispositioner än koncernbidrag, förändring av periodiseringsfonder, förändring av överavskrivningar och förändring av ersättningsfonder',
                    saldo: 'credit',
                    negateValue: false,
                  }
                )
              ) // <<< end row_empty_value ---
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(
                  multiply(-1, or(account('$ub', accountModifier, 'year'), 0))
                ),
                ref(
                  multiply(
                    -1,
                    or(account('$ib', accountModifier, 'previousYear'), 0)
                  )
                )
              )
              .addSubRows((rows) => {
                accountRangeRows(
                  accounts,
                  8890,
                  8890,
                  rows,
                  true,
                  'year',
                  'previousYear',
                  accountModifier
                );

                return rows.build();
              });
            return rows.build();
          });
        rows
          .addRowWithType(
            'Bokslutsdispositioner',
            'sum',
            value('Summa bokslutsdispositioner'),
            undefined,
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'incomeStatement.section.table.BokslutsdispositionerAbstract.*.year'
                  )
                )
              ),
              {
                type: 'monetaryItemType',
                name: 'se-gen-base:Bokslutsdispositioner',
                contextRef: 'period0',
                standardRubrik: 'Bokslutsdispositioner',
                saldo: 'credit',
                negateValue: false,
              }
            ),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'incomeStatement.section.table.BokslutsdispositionerAbstract.*.previousYear'
                  )
                )
              ),
              {
                type: 'monetaryItemType',
                name: 'se-gen-base:Bokslutsdispositioner',
                contextRef: 'period1',
                standardRubrik: 'Bokslutsdispositioner',
                saldo: 'credit',
                negateValue: false,
              }
            )
          )
          .newRowTemplateWithType(
            'account',
            label('$label'),
            undefined,
            ref(or(account('$ub', accountModifier, 'year'), 0)),
            ref(or(account('$ib', accountModifier, 'previousYear'), 0))
          )
          .addSubRows((rows) => {
            return rows.build();
          });
        rows
          .addRowWithType(
            'ResultatForeSkatt',
            'sum',
            value('Resultat före skatt'),
            undefined,
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'incomeStatement.section.table.ResultatEfterFinansiellaPoster.year'
                  ),
                  id('incomeStatement.section.table.Bokslutsdispositioner.year')
                )
              ),
              {
                type: 'monetaryItemType',
                name: 'se-gen-base:ResultatForeSkatt',
                contextRef: 'period0',
                standardRubrik: 'Resultat före skatt',
                saldo: 'credit',
                negateValue: false,
              }
            ),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'incomeStatement.section.table.ResultatEfterFinansiellaPoster.previousYear'
                  ),
                  id(
                    'incomeStatement.section.table.Bokslutsdispositioner.previousYear'
                  )
                )
              ),
              {
                type: 'monetaryItemType',
                name: 'se-gen-base:ResultatForeSkatt',
                contextRef: 'period1',
                standardRubrik: 'Resultat före skatt',
                saldo: 'credit',
                negateValue: false,
              }
            )
          )
          .newRowTemplateWithType(
            'account',
            label('$label'),
            undefined,
            ref(or(account('$ub', accountModifier, 'year'), 0)),
            ref(or(account('$ib', accountModifier, 'previousYear'), 0))
          )
          .addSubRows((rows) => {
            return rows.build();
          });
        rows
          .addRowWithType(
            'SkatterAbstract',
            'header',
            value('Skatter'),
            refs(),
            undefined,
            undefined
          )
          .addSubRows((rows) => {
            rows
              .addRowWithType(
                'SkattAretsResultat',
                'row',
                value('Skatt på årets resultat'),
                refs(),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'incomeStatement.section.table.SkatterAbstract.SkattAretsResultat.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:SkattAretsResultat',
                    contextRef: 'period0',
                    standardRubrik: 'Skatt på periodens resultat',
                    saldo: 'debit',
                    negateValue: true,
                  }
                ),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'incomeStatement.section.table.SkatterAbstract.SkattAretsResultat.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:SkattAretsResultat',
                    contextRef: 'period1',
                    standardRubrik: 'Skatt på periodens resultat',
                    saldo: 'debit',
                    negateValue: true,
                  }
                )
              ) // <<< end row_empty_value ---
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(
                  multiply(-1, or(account('$ub', accountModifier, 'year'), 0))
                ),
                ref(
                  multiply(
                    -1,
                    or(account('$ib', accountModifier, 'previousYear'), 0)
                  )
                )
              )
              .addSubRows((rows) => {
                accountRangeRows(
                  accounts,
                  8910,
                  8910,
                  rows,
                  true,
                  'year',
                  'previousYear',
                  accountModifier
                );

                return rows.build();
              });
            rows
              .addRowWithType(
                'OvrigaSkatter',
                'row',
                value('Övriga skatter'),
                refs(),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'incomeStatement.section.table.SkatterAbstract.OvrigaSkatter.*.year'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:OvrigaSkatter',
                    contextRef: 'period0',
                    standardRubrik: 'Övriga skattekostnader',
                    saldo: 'debit',
                    negateValue: true,
                  }
                ),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        'incomeStatement.section.table.SkatterAbstract.OvrigaSkatter.*.previousYear'
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:OvrigaSkatter',
                    contextRef: 'period1',
                    standardRubrik: 'Övriga skattekostnader',
                    saldo: 'debit',
                    negateValue: true,
                  }
                )
              ) // <<< end row_empty_value ---
              .newRowTemplateWithType(
                'account',
                label('$label'),
                undefined,
                ref(
                  multiply(-1, or(account('$ub', accountModifier, 'year'), 0))
                ),
                ref(
                  multiply(
                    -1,
                    or(account('$ib', accountModifier, 'previousYear'), 0)
                  )
                )
              )
              .addSubRows((rows) => {
                accountRangeRows(
                  accounts,
                  8920,
                  8920,
                  rows,
                  true,
                  'year',
                  'previousYear',
                  accountModifier
                );

                return rows.build();
              });
            return rows.build();
          });
        rows
          .addRowWithType(
            'AretsResultat',
            'sum',
            value('Årets resultat'),
            undefined,
            ixbrlCell(
              ref(
                sumAllowNull(
                  id('incomeStatement.section.table.ResultatForeSkatt.year'),
                  id(
                    'incomeStatement.section.table.SkatterAbstract.SkattAretsResultat.year'
                  ),
                  id(
                    'incomeStatement.section.table.SkatterAbstract.OvrigaSkatter.year'
                  )
                )
              ),
              {
                type: 'monetaryItemType',
                name: 'se-gen-base:AretsResultat',
                contextRef: 'period0',
                standardRubrik: 'Periodens resultat',
                saldo: 'credit',
                negateValue: false,
              }
            ),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'incomeStatement.section.table.ResultatForeSkatt.previousYear'
                  ),
                  id(
                    'incomeStatement.section.table.SkatterAbstract.SkattAretsResultat.previousYear'
                  ),
                  id(
                    'incomeStatement.section.table.SkatterAbstract.OvrigaSkatter.previousYear'
                  )
                )
              ),
              {
                type: 'monetaryItemType',
                name: 'se-gen-base:AretsResultat',
                contextRef: 'period1',
                standardRubrik: 'Periodens resultat',
                saldo: 'credit',
                negateValue: false,
              }
            )
          )
          .newRowTemplateWithType(
            'account',
            label('$label'),
            undefined,
            ref(or(account('$ub', accountModifier, 'year'), 0)),
            ref(or(account('$ib', accountModifier, 'previousYear'), 0))
          )
          .addSubRows((rows) => {
            accountRangeRows(
              accounts,
              8990,
              8999,
              rows,
              false,
              'year',
              'previousYear',
              accountModifier
            );

            return rows.build();
          });
        rows
          .addRowWithType(
            'accountsNotAssigned',
            'accountsNotAssigned',
            label('Ej tilldelade konton'),
            undefined,
            undefined,
            undefined
          ) // >>> begin `add_button` (NL 0) [Id `incomeStatement.section.table`] ---
          .newRowTemplateWithType(
            'account',
            label('$label'),
            undefined,
            ref(or(account('$ub', accountModifier, 'year'), 0)),
            ref(or(account('$ib', accountModifier, 'previousYear'), 0))
          )
          .addSubRows((rows) => {
            accountRangeRows(
              accounts,
              3000,
              3000,
              rows,
              true,
              'year',
              'previousYear',
              accountModifier
            );
            accountRangeRows(
              accounts,
              3002,
              3739,
              rows,
              true,
              'year',
              'previousYear',
              accountModifier
            );
            accountRangeRows(
              accounts,
              3741,
              3869,
              rows,
              true,
              'year',
              'previousYear',
              accountModifier
            );
            accountRangeRows(
              accounts,
              3871,
              3990,
              rows,
              true,
              'year',
              'previousYear',
              accountModifier
            );
            accountRangeRows(
              accounts,
              3992,
              3999,
              rows,
              true,
              'year',
              'previousYear',
              accountModifier
            );
            accountRangeRows(
              accounts,
              4001,
              4959,
              rows,
              true,
              'year',
              'previousYear',
              accountModifier
            );
            accountRangeRows(
              accounts,
              4962,
              5059,
              rows,
              true,
              'year',
              'previousYear',
              accountModifier
            );
            accountRangeRows(
              accounts,
              5061,
              5419,
              rows,
              true,
              'year',
              'previousYear',
              accountModifier
            );
            accountRangeRows(
              accounts,
              5421,
              5799,
              rows,
              true,
              'year',
              'previousYear',
              accountModifier
            );
            accountRangeRows(
              accounts,
              5801,
              6209,
              rows,
              true,
              'year',
              'previousYear',
              accountModifier
            );
            accountRangeRows(
              accounts,
              6211,
              6309,
              rows,
              true,
              'year',
              'previousYear',
              accountModifier
            );
            accountRangeRows(
              accounts,
              6311,
              6419,
              rows,
              true,
              'year',
              'previousYear',
              accountModifier
            );
            accountRangeRows(
              accounts,
              6421,
              7209,
              rows,
              true,
              'year',
              'previousYear',
              accountModifier
            );
            accountRangeRows(
              accounts,
              7211,
              7509,
              rows,
              true,
              'year',
              'previousYear',
              accountModifier
            );
            accountRangeRows(
              accounts,
              7511,
              7709,
              rows,
              true,
              'year',
              'previousYear',
              accountModifier
            );
            accountRangeRows(
              accounts,
              7711,
              7809,
              rows,
              true,
              'year',
              'previousYear',
              accountModifier
            );
            accountRangeRows(
              accounts,
              7811,
              7831,
              rows,
              true,
              'year',
              'previousYear',
              accountModifier
            );
            accountRangeRows(
              accounts,
              7833,
              7833,
              rows,
              true,
              'year',
              'previousYear',
              accountModifier
            );
            accountRangeRows(
              accounts,
              7835,
              7972,
              rows,
              true,
              'year',
              'previousYear',
              accountModifier
            );
            accountRangeRows(
              accounts,
              7974,
              7999,
              rows,
              true,
              'year',
              'previousYear',
              accountModifier
            );
            accountRangeRows(
              accounts,
              8800,
              8810,
              rows,
              true,
              'year',
              'previousYear',
              accountModifier
            );
            accountRangeRows(
              accounts,
              8812,
              8819,
              rows,
              true,
              'year',
              'previousYear',
              accountModifier
            );
            accountRangeRows(
              accounts,
              8821,
              8829,
              rows,
              true,
              'year',
              'previousYear',
              accountModifier
            );
            accountRangeRows(
              accounts,
              8831,
              8852,
              rows,
              true,
              'year',
              'previousYear',
              accountModifier
            );
            accountRangeRows(
              accounts,
              8854,
              8860,
              rows,
              true,
              'year',
              'previousYear',
              accountModifier
            );
            accountRangeRows(
              accounts,
              8862,
              8889,
              rows,
              true,
              'year',
              'previousYear',
              accountModifier
            );
            accountRangeRows(
              accounts,
              8891,
              8909,
              rows,
              true,
              'year',
              'previousYear',
              accountModifier
            );
            accountRangeRows(
              accounts,
              8911,
              8919,
              rows,
              true,
              'year',
              'previousYear',
              accountModifier
            );
            accountRangeRows(
              accounts,
              8921,
              8989,
              rows,
              true,
              'year',
              'previousYear',
              accountModifier
            );

            return rows.build();
          });
        return rows.build();
      })
      .build(),
  },
});
export const functionalIncomeStatementK3v2References = (): Record<
  string,
  string
> => ({
  'incomeStatement.Nettoomsattning.year': id(
    'incomeStatement.section.table.RorelseresultatAbstract.BruttoresultatAbstract.Nettoomsattning.year'
  ),
  'incomeStatement.Nettoomsattning.previousYear': id(
    'incomeStatement.section.table.RorelseresultatAbstract.BruttoresultatAbstract.Nettoomsattning.previousYear'
  ),
  'incomeStatement.KostnadSaldaVaror.year': id(
    'incomeStatement.section.table.RorelseresultatAbstract.BruttoresultatAbstract.KostnadSaldaVaror.year'
  ),
  'incomeStatement.KostnadSaldaVaror.previousYear': id(
    'incomeStatement.section.table.RorelseresultatAbstract.BruttoresultatAbstract.KostnadSaldaVaror.previousYear'
  ),
  'incomeStatement.BruttoresultatFunktionsindelad.year': id(
    'incomeStatement.section.table.RorelseresultatAbstract.BruttoresultatFunktionsindelad.year'
  ),
  'incomeStatement.BruttoresultatFunktionsindelad.previousYear': id(
    'incomeStatement.section.table.RorelseresultatAbstract.BruttoresultatFunktionsindelad.previousYear'
  ),
  'incomeStatement.Forsaljningskostnader.year': id(
    'incomeStatement.section.table.RorelseresultatAbstract.FunktionerMmAbstract.Forsaljningskostnader.year'
  ),
  'incomeStatement.Forsaljningskostnader.previousYear': id(
    'incomeStatement.section.table.RorelseresultatAbstract.FunktionerMmAbstract.Forsaljningskostnader.previousYear'
  ),
  'incomeStatement.Administrationskostnader.year': id(
    'incomeStatement.section.table.RorelseresultatAbstract.FunktionerMmAbstract.Administrationskostnader.year'
  ),
  'incomeStatement.Administrationskostnader.previousYear': id(
    'incomeStatement.section.table.RorelseresultatAbstract.FunktionerMmAbstract.Administrationskostnader.previousYear'
  ),
  'incomeStatement.ForskningsUtvecklingskostnader.year': id(
    'incomeStatement.section.table.RorelseresultatAbstract.FunktionerMmAbstract.ForskningsUtvecklingskostnader.year'
  ),
  'incomeStatement.ForskningsUtvecklingskostnader.previousYear': id(
    'incomeStatement.section.table.RorelseresultatAbstract.FunktionerMmAbstract.ForskningsUtvecklingskostnader.previousYear'
  ),
  'incomeStatement.OvrigaRorelseintakter.year': id(
    'incomeStatement.section.table.RorelseresultatAbstract.FunktionerMmAbstract.OvrigaRorelseintakter.year'
  ),
  'incomeStatement.OvrigaRorelseintakter.previousYear': id(
    'incomeStatement.section.table.RorelseresultatAbstract.FunktionerMmAbstract.OvrigaRorelseintakter.previousYear'
  ),
  'incomeStatement.OvrigaRorelsekostnaderFunktionsindelad.year': id(
    'incomeStatement.section.table.RorelseresultatAbstract.FunktionerMmAbstract.OvrigaRorelsekostnaderFunktionsindelad.year'
  ),
  'incomeStatement.OvrigaRorelsekostnaderFunktionsindelad.previousYear': id(
    'incomeStatement.section.table.RorelseresultatAbstract.FunktionerMmAbstract.OvrigaRorelsekostnaderFunktionsindelad.previousYear'
  ),
  'incomeStatement.AndelarIntresseforetagGemensamtStyrdaForetagResultat.year':
    id(
      'incomeStatement.section.table.RorelseresultatAbstract.FunktionerMmAbstract.AndelarIntresseforetagGemensamtStyrdaForetagResultat.year'
    ),
  'incomeStatement.AndelarIntresseforetagGemensamtStyrdaForetagResultat.previousYear':
    id(
      'incomeStatement.section.table.RorelseresultatAbstract.FunktionerMmAbstract.AndelarIntresseforetagGemensamtStyrdaForetagResultat.previousYear'
    ),
  'incomeStatement.RealisationsresultatForsaljningIntresseforetagGemensamtStyrdaForetag.year':
    id(
      'incomeStatement.section.table.RorelseresultatAbstract.FunktionerMmAbstract.RealisationsresultatForsaljningIntresseforetagGemensamtStyrdaForetag.year'
    ),
  'incomeStatement.RealisationsresultatForsaljningIntresseforetagGemensamtStyrdaForetag.previousYear':
    id(
      'incomeStatement.section.table.RorelseresultatAbstract.FunktionerMmAbstract.RealisationsresultatForsaljningIntresseforetagGemensamtStyrdaForetag.previousYear'
    ),
  'incomeStatement.FunktionerMm.year': id(
    'incomeStatement.section.table.RorelseresultatAbstract.FunktionerMm.year'
  ),
  'incomeStatement.FunktionerMm.previousYear': id(
    'incomeStatement.section.table.RorelseresultatAbstract.FunktionerMm.previousYear'
  ),
  'incomeStatement.Rorelseresultat.year': id(
    'incomeStatement.section.table.Rorelseresultat.year'
  ),
  'incomeStatement.Rorelseresultat.previousYear': id(
    'incomeStatement.section.table.Rorelseresultat.previousYear'
  ),
  'incomeStatement.ResultatAndelarKoncernforetag.year': id(
    'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningEgenPostAbstract.ResultatAndelarKoncernforetag.year'
  ),
  'incomeStatement.ResultatAndelarKoncernforetag.previousYear': id(
    'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningEgenPostAbstract.ResultatAndelarKoncernforetag.previousYear'
  ),
  'incomeStatement.ResultatAndelarIntresseforetagGemensamtStyrda.year': id(
    'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningEgenPostAbstract.ResultatAndelarIntresseforetagGemensamtStyrda.year'
  ),
  'incomeStatement.ResultatAndelarIntresseforetagGemensamtStyrda.previousYear':
    id(
      'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningEgenPostAbstract.ResultatAndelarIntresseforetagGemensamtStyrda.previousYear'
    ),
  'incomeStatement.ResultatOvrigaforetagAgarintresse.year': id(
    'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningEgenPostAbstract.ResultatOvrigaforetagAgarintresse.year'
  ),
  'incomeStatement.ResultatOvrigaforetagAgarintresse.previousYear': id(
    'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningEgenPostAbstract.ResultatOvrigaforetagAgarintresse.previousYear'
  ),
  'incomeStatement.ResultatOvrigaFinansiellaAnlaggningstillgangar.year': id(
    'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningEgenPostAbstract.ResultatOvrigaFinansiellaAnlaggningstillgangar.year'
  ),
  'incomeStatement.ResultatOvrigaFinansiellaAnlaggningstillgangar.previousYear':
    id(
      'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningEgenPostAbstract.ResultatOvrigaFinansiellaAnlaggningstillgangar.previousYear'
    ),
  'incomeStatement.OvrigaRanteintakterLiknandeResultatposter.year': id(
    'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningEgenPostAbstract.OvrigaRanteintakterLiknandeResultatposter.year'
  ),
  'incomeStatement.OvrigaRanteintakterLiknandeResultatposter.previousYear': id(
    'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningEgenPostAbstract.OvrigaRanteintakterLiknandeResultatposter.previousYear'
  ),
  'incomeStatement.NedskrivningarFinansiellaAnlaggningstillgangarKortfristigaPlaceringar.year':
    id(
      'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningEgenPostAbstract.NedskrivningarFinansiellaAnlaggningstillgangarKortfristigaPlaceringar.year'
    ),
  'incomeStatement.NedskrivningarFinansiellaAnlaggningstillgangarKortfristigaPlaceringar.previousYear':
    id(
      'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningEgenPostAbstract.NedskrivningarFinansiellaAnlaggningstillgangarKortfristigaPlaceringar.previousYear'
    ),
  'incomeStatement.RantekostnaderLiknandeResultatposterEgenPost.year': id(
    'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningEgenPostAbstract.RantekostnaderLiknandeResultatposterEgenPost.year'
  ),
  'incomeStatement.RantekostnaderLiknandeResultatposterEgenPost.previousYear':
    id(
      'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningEgenPostAbstract.RantekostnaderLiknandeResultatposterEgenPost.previousYear'
    ),
  'incomeStatement.ResultatAndelarKoncernforetagInklNedskrivning.year': id(
    'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningRespektivePostAbstract.ResultatAndelarKoncernforetagInklNedskrivning.year'
  ),
  'incomeStatement.ResultatAndelarKoncernforetagInklNedskrivning.previousYear':
    id(
      'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningRespektivePostAbstract.ResultatAndelarKoncernforetagInklNedskrivning.previousYear'
    ),
  'incomeStatement.ResultatAndelarIntresseforetagGemensamtStyrdaInklNedskrivning.year':
    id(
      'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningRespektivePostAbstract.ResultatAndelarIntresseforetagGemensamtStyrdaInklNedskrivning.year'
    ),
  'incomeStatement.ResultatAndelarIntresseforetagGemensamtStyrdaInklNedskrivning.previousYear':
    id(
      'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningRespektivePostAbstract.ResultatAndelarIntresseforetagGemensamtStyrdaInklNedskrivning.previousYear'
    ),
  'incomeStatement.ResultatOvrigaforetagAgarintresseInklNedskrivning.year': id(
    'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningRespektivePostAbstract.ResultatOvrigaforetagAgarintresseInklNedskrivning.year'
  ),
  'incomeStatement.ResultatOvrigaforetagAgarintresseInklNedskrivning.previousYear':
    id(
      'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningRespektivePostAbstract.ResultatOvrigaforetagAgarintresseInklNedskrivning.previousYear'
    ),
  'incomeStatement.ResultatOvrigaFinansiellaAnlaggningstillgangarInklNedskrivning.year':
    id(
      'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningRespektivePostAbstract.ResultatOvrigaFinansiellaAnlaggningstillgangarInklNedskrivning.year'
    ),
  'incomeStatement.ResultatOvrigaFinansiellaAnlaggningstillgangarInklNedskrivning.previousYear':
    id(
      'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningRespektivePostAbstract.ResultatOvrigaFinansiellaAnlaggningstillgangarInklNedskrivning.previousYear'
    ),
  'incomeStatement.OvrigaRanteintakterLiknandeResultatposterInklNedskrivning.year':
    id(
      'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningRespektivePostAbstract.OvrigaRanteintakterLiknandeResultatposterInklNedskrivning.year'
    ),
  'incomeStatement.OvrigaRanteintakterLiknandeResultatposterInklNedskrivning.previousYear':
    id(
      'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningRespektivePostAbstract.OvrigaRanteintakterLiknandeResultatposterInklNedskrivning.previousYear'
    ),
  'incomeStatement.RantekostnaderLiknandeResultatposterRespektivePost.year': id(
    'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningRespektivePostAbstract.RantekostnaderLiknandeResultatposterRespektivePost.year'
  ),
  'incomeStatement.RantekostnaderLiknandeResultatposterRespektivePost.previousYear':
    id(
      'incomeStatement.section.table.FinansiellaPosterAbstract.FinansiellaPosterNedskrivningRespektivePostAbstract.RantekostnaderLiknandeResultatposterRespektivePost.previousYear'
    ),
  'incomeStatement.FinansiellaPoster.year': id(
    'incomeStatement.section.table.FinansiellaPoster.year'
  ),
  'incomeStatement.FinansiellaPoster.previousYear': id(
    'incomeStatement.section.table.FinansiellaPoster.previousYear'
  ),
  'incomeStatement.ResultatEfterFinansiellaPoster.year': id(
    'incomeStatement.section.table.ResultatEfterFinansiellaPoster.year'
  ),
  'incomeStatement.ResultatEfterFinansiellaPoster.previousYear': id(
    'incomeStatement.section.table.ResultatEfterFinansiellaPoster.previousYear'
  ),
  'incomeStatement.ErhallnaKoncernbidrag.year': id(
    'incomeStatement.section.table.BokslutsdispositionerAbstract.ErhallnaKoncernbidrag.year'
  ),
  'incomeStatement.ErhallnaKoncernbidrag.previousYear': id(
    'incomeStatement.section.table.BokslutsdispositionerAbstract.ErhallnaKoncernbidrag.previousYear'
  ),
  'incomeStatement.LamnadeKoncernbidrag.year': id(
    'incomeStatement.section.table.BokslutsdispositionerAbstract.LamnadeKoncernbidrag.year'
  ),
  'incomeStatement.LamnadeKoncernbidrag.previousYear': id(
    'incomeStatement.section.table.BokslutsdispositionerAbstract.LamnadeKoncernbidrag.previousYear'
  ),
  'incomeStatement.ForandringPeriodiseringsfond.year': id(
    'incomeStatement.section.table.BokslutsdispositionerAbstract.ForandringPeriodiseringsfond.year'
  ),
  'incomeStatement.ForandringPeriodiseringsfond.previousYear': id(
    'incomeStatement.section.table.BokslutsdispositionerAbstract.ForandringPeriodiseringsfond.previousYear'
  ),
  'incomeStatement.ForandringOveravskrivningar.year': id(
    'incomeStatement.section.table.BokslutsdispositionerAbstract.ForandringOveravskrivningar.year'
  ),
  'incomeStatement.ForandringOveravskrivningar.previousYear': id(
    'incomeStatement.section.table.BokslutsdispositionerAbstract.ForandringOveravskrivningar.previousYear'
  ),
  'incomeStatement.ForandringErsattningsfond.year': id(
    'incomeStatement.section.table.BokslutsdispositionerAbstract.ForandringErsattningsfond.year'
  ),
  'incomeStatement.ForandringErsattningsfond.previousYear': id(
    'incomeStatement.section.table.BokslutsdispositionerAbstract.ForandringErsattningsfond.previousYear'
  ),
  'incomeStatement.AndraBokslutsdispositioner.year': id(
    'incomeStatement.section.table.BokslutsdispositionerAbstract.AndraBokslutsdispositioner.year'
  ),
  'incomeStatement.AndraBokslutsdispositioner.previousYear': id(
    'incomeStatement.section.table.BokslutsdispositionerAbstract.AndraBokslutsdispositioner.previousYear'
  ),
  'incomeStatement.Bokslutsdispositioner.year': id(
    'incomeStatement.section.table.Bokslutsdispositioner.year'
  ),
  'incomeStatement.Bokslutsdispositioner.previousYear': id(
    'incomeStatement.section.table.Bokslutsdispositioner.previousYear'
  ),
  'incomeStatement.ResultatForeSkatt.year': id(
    'incomeStatement.section.table.ResultatForeSkatt.year'
  ),
  'incomeStatement.ResultatForeSkatt.previousYear': id(
    'incomeStatement.section.table.ResultatForeSkatt.previousYear'
  ),
  'incomeStatement.SkattAretsResultat.year': id(
    'incomeStatement.section.table.SkatterAbstract.SkattAretsResultat.year'
  ),
  'incomeStatement.SkattAretsResultat.previousYear': id(
    'incomeStatement.section.table.SkatterAbstract.SkattAretsResultat.previousYear'
  ),
  'incomeStatement.OvrigaSkatter.year': id(
    'incomeStatement.section.table.SkatterAbstract.OvrigaSkatter.year'
  ),
  'incomeStatement.OvrigaSkatter.previousYear': id(
    'incomeStatement.section.table.SkatterAbstract.OvrigaSkatter.previousYear'
  ),
  'incomeStatement.AretsResultat.year': id(
    'incomeStatement.section.table.AretsResultat.year'
  ),
  'incomeStatement.AretsResultat.previousYear': id(
    'incomeStatement.section.table.AretsResultat.previousYear'
  ),
});

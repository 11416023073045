import { AgoyTableRow } from '@agoy/document';

const hasValue = (cell: any): cell is { value: number | null } =>
  'value' in cell;

const hasReferences = (cell: any): cell is { references: any[] } =>
  'references' in cell;

const isZeroOrRoundedToZero = (value: number | null): boolean =>
  value === 0 || (typeof value === 'number' && Math.round(value) === 0);

const isEmptyReferences = (cell: any): boolean =>
  !hasReferences(cell) || cell.references.length === 0;

// When a row has values in year and previousYear that is zero or rounded to zero
// And the row does not have any references (notes) return false
// Basically we don't want to show the row if it has no values and no notes
export const rowHaveValues = (row: AgoyTableRow): boolean => {
  if (row.cells) {
    // Check either year or value cell
    const year = row.cells.year ?? row.cells.value;
    const previousYear = row.cells.previousYear;
    const notes = row.cells.notes;

    const yearValue = year && hasValue(year) ? year.value : null;

    const previousYearValue =
      previousYear && hasValue(previousYear) ? previousYear.value : null;

    if (
      isZeroOrRoundedToZero(yearValue) &&
      (!previousYear || isZeroOrRoundedToZero(previousYearValue)) &&
      (!notes || isEmptyReferences(notes))
    ) {
      return false;
    }
  }

  return true;
};

import React, { useState, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { useSelector } from 'redux/reducers';
import {
  addGlobalErrorMessage,
  addGlobalMessage,
} from '_messages/redux/actions';
import { useAccountDocuments } from 'utils/usePeriodDocuments';
import { ReportData } from '_reconciliation/types';
import Button from '_shared/components/Buttons/Button';
import PeriodDataContext from '../PeriodDataContext';
import { SaldoHistoryContext } from '../../HiddenRowHistoryProvider';
import { usePrintState } from '../../utils';

interface PrintButtonProps {
  account: string;
  data: ReportData;
  hasSpecifications: boolean;
}

const PrintButton = ({
  account,
  data,
  hasSpecifications,
}: PrintButtonProps) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const printState = usePrintState();

  const { onSaldoChange } = useContext(SaldoHistoryContext);
  const periodData = useContext(PeriodDataContext);
  const { period, clientId } = periodData;

  const [loading, setLoading] = useState<boolean>(false);

  const customer = useSelector((state) => state.customers[clientId]);

  const { createAndAddDocument } = useAccountDocuments();

  const handlePrint = async () => {
    setLoading(true);

    if (customer) {
      try {
        const docName = `${formatMessage({
          id: 'hidden.invoices.documentNameBase',
        })} ${customer.name} ${period.end}.pdf`;

        await createAndAddDocument({
          name: docName,
          docType: 'accounting',
          footer: '',
          header: '',
          parts: ['customerInvoices'],
          printState,
        });

        if (!hasSpecifications) {
          onSaldoChange(account, period, data.totalSumSEK);
          dispatch(
            addGlobalMessage(
              'success',
              'hidden.invoices.saveToPeriodDocuments.success'
            )
          );
        } else {
          dispatch(
            addGlobalMessage(
              'warning',
              'hidden.invoices.saveToPeriodDocuments.warning'
            )
          );
        }
      } catch (e) {
        dispatch(addGlobalErrorMessage('error'));
      }
    }
    setLoading(false);
  };

  return (
    <Button
      label={formatMessage({ id: 'hidden.invoices.saveToPeriodDocuments' })}
      loading={loading}
      onClick={handlePrint}
      variant="outlined"
      size="medium"
    />
  );
};

export default PrintButton;

import React, { memo, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import styled from '@emotion/styled';
import { Backdrop as MuiBackdrop } from '@material-ui/core';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';

import {
  default as MuiSpeedDial,
  SpeedDialProps as MuiSpeedDialProps,
} from '@material-ui/lab/SpeedDial';

const StyledDial = styled(MuiSpeedDial)<MuiSpeedDialProps>`
  position: fixed;
  right: ${(props) => props.theme.spacing(4)}px;
  bottom: ${(props) => props.theme.spacing(4)}px;

  .MuiSpeedDial-actions {
    align-items: flex-end;
  }

  .MuiSpeedDialAction-staticTooltip {
    display: none;
  }

  .MuiSpeedDial-fab {
    margin-left: auto;
    margin-right: ${(props) => props.theme.spacing(1)}px;
  }

  .MuiSpeedDialAction-fab {
    width: auto;
    border-radius: ${(props) => props.theme.spacing(1)}px;
  }
`;

const Backdrop = styled(MuiBackdrop)`
  z-index: ${(props) => props.theme.zIndex.accountingView.speedDial};
`;

export type SpeedDialActionType = {
  icon: React.ReactNode;
  label: string;
  tooltipTitle?: string;
  clickHandler?: () => void;
};

type SpeedDialProps = {
  name: string;
  actions: SpeedDialActionType[];
  portalElement?: Element;
};

const SpeedDial = ({
  name,
  actions,
  portalElement = document.body,
}: SpeedDialProps): JSX.Element => {
  const [open, setOpen] = useState(false);
  const [container, setContainer] = useState<Element | null>(null);

  useEffect(() => {
    const div = document.createElement('div');
    portalElement.appendChild(div);
    setContainer(div);
    return () => {
      portalElement.removeChild(div);
    };
  }, [portalElement]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = (event, reason) => {
    // We disable focus (which will happen after action is selected) so
    // speed-dial isn't opened again after
    if (reason !== 'focus') setOpen(true);
  };

  const onActionClick = (action) => {
    setOpen(false);
    if (action.clickHandler) action.clickHandler();
  };

  if (!container) {
    return <></>;
  }

  return createPortal(
    <>
      <Backdrop open={open} />
      <StyledDial
        ariaLabel={name}
        icon={<SpeedDialIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        direction="up"
        FabProps={{ color: 'secondary' }}
      >
        {actions.map((action) => (
          <SpeedDialAction
            icon={action.icon}
            key={action.label}
            tooltipTitle={action.tooltipTitle || 'Välj'}
            onClick={(e) => onActionClick(action)}
          />
        ))}
      </StyledDial>
    </>,
    container,
    'speedDial'
  );
};

export default memo(SpeedDial);

import { Period } from '@agoy/api-sdk-core';
import React, { createContext, useMemo } from 'react';
import {
  PeriodDataContextType,
  usePeriodData,
} from '../HiddenGroupRow/utils/periodUtils';

/**
 * Context holding period related information.
 *
 * Uses the function `usePeriodData` as source.
 */
const PeriodDataContext = createContext<PeriodDataContextType>(
  {} as unknown as PeriodDataContextType
);

type PeriodDataContextProviderProps = React.PropsWithChildren<{
  clientId: string;
  period: Period | Period[];

  /**
   * Type of view the data is displayed in
   *
   * - 'year' is the yearly reconciliation
   * - 'quarter' is the quarterly reconciliation
   * - 'month' is the monthly reconciliation
   * - 'yearEnd' is the Bokslutsperiod
   * - 'dead' is a readonly month expanded in the new reconciliation
   * - 'period' is the old current reconciliation.
   */
  periodType:
    | 'period'
    | 'month'
    | 'quarter'
    | 'yearEnd'
    | 'financialYear'
    | 'dead';

  /**
   * In case of a 'dead' month, this indicates which type it is
   * expanded in.
   */
  parentPeriodType?: 'quarter' | 'financialYear';

  /**
   * Optional period to use as last period, only specified for "dead" months.
   */
  lastPeriod?: Period;
}>;

export const PeriodDataContextProvider = ({
  children,
  clientId,
  period,
  periodType,
  parentPeriodType,
  lastPeriod,
}: PeriodDataContextProviderProps) => {
  const currentPeriods = useMemo(
    () => (Array.isArray(period) ? period : [period]),
    [period]
  );
  const currentPeriod = useMemo(() => {
    if (Array.isArray(period)) {
      return period[period.length - 1];
    }
    return period;
  }, [period]);
  const value = usePeriodData(
    clientId,
    currentPeriod,
    currentPeriods,
    periodType,
    parentPeriodType,
    lastPeriod
  );

  return (
    <PeriodDataContext.Provider value={value}>
      {children}
    </PeriodDataContext.Provider>
  );
};

export default PeriodDataContext;

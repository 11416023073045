/*
 This component can probably be moved up a level and use din more places
*/

import styled from '@emotion/styled';

export const Section = styled.div`
  margin: 1rem 0 0 0;

  @media screen {
    min-width: 100%;
    max-width: 1200px;
  }

  @media print {
    page-break-inside: avoid;
    width: 100%;
  }
`;

export const TextBlock = styled.div`
  @media print {
    break-inside: avoid;
    break-before: avoid;
    break-after: avoid;
  }
`;

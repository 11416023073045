import React from 'react';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';
import {
  Card,
  Typography,
  Divider,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';

import { useSelector } from 'redux/reducers';
import { changeOrganizationsSettings } from '_organization/redux/actions';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.spacing(2)}px;
`;

const Icon = styled(InfoOutlined)`
  color: ${({ theme }) => theme.palette.text.secondary};
`;

const InfoCard = styled(Card)`
  width: 900px;
  padding: ${({ theme }) => theme.spacing(1)}px
    ${({ theme }) => theme.spacing(4)}px;
`;

const StyledCard = styled(InfoCard)`
  padding: ${({ theme }) => theme.spacing(1)}px
    ${({ theme }) => theme.spacing(4)}px;
  padding-bottom: ${({ theme }) => theme.spacing(4)}px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: ${({ theme }) => theme.spacing(1)}px;
`;

const StyledDivider = styled(Divider)`
  margin: ${({ theme }) => theme.spacing(4)}px 0;
`;

const Spacer = styled.div`
  margin: ${({ theme }) => theme.spacing(2)}px 0;
`;

const FormItem = styled(FormControlLabel)`
  align-items: flex-start;
`;

const ReconciliationContent = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const { settings } = useSelector((state) => state.organisation);

  const { reconciliation } = settings || {};

  const handleChange = (e) => {
    if (settings && reconciliation) {
      const { name } = e.target;

      dispatch(
        changeOrganizationsSettings(
          'reconciliation',
          'periodDoneAccountActions',
          name
        )
      );
    }
  };

  return (
    <Container>
      <InfoCard>
        <Row>
          <Icon />
          <Typography variant="subtitle1" color="textSecondary">
            {formatMessage({ id: 'organisation.settings.info' })}
          </Typography>
        </Row>
      </InfoCard>

      <StyledCard>
        <Typography variant="h4" color="textSecondary">
          {formatMessage({ id: 'RECONCILIATION' })}
        </Typography>
        <Typography variant="h3">
          {formatMessage({ id: 'organisation.settings.status' })}
        </Typography>
        <Typography>
          {formatMessage({ id: 'organisation.settings.subtitle1' })}
        </Typography>
        <Spacer />
        <Typography>
          {formatMessage({ id: 'organisation.settings.subtitle2' })}
        </Typography>

        <StyledDivider />

        <FormItem
          control={
            <Checkbox
              name="no_action"
              checked={reconciliation?.periodDoneAccountActions === 'no_action'}
              onChange={(event) => handleChange(event)}
            />
          }
          label={formatMessage({ id: 'organisation.settings.no_action' })}
        />
        <Spacer />
        <FormItem
          control={
            <Checkbox
              name="mark_all_accounts"
              checked={
                reconciliation?.periodDoneAccountActions === 'mark_all_accounts'
              }
              onChange={(event) => handleChange(event)}
            />
          }
          label={formatMessage({
            id: 'organisation.settings.mark_all_accounts',
          })}
        />
      </StyledCard>
    </Container>
  );
};

export default ReconciliationContent;

import {
  id,
  label,
  or,
  sum,
  ref,
  value,
  ifOrElse,
  abs,
  ReferenceCell,
} from '@agoy/document';
import { previousYear, year } from '../../common/utils/date-util';
import { table, field, active, range } from '../../common/utils/util';
import { IndividualCompanyNotes } from './types';

// TODO: Move strings to translation file (sv.json)?
// With the 2021 specs from BV, new ids are being added as strings now

/**
 * Returns the sum for a specified NoteNumber for the rowIds specified
 * in the current year or previous year.
 * @param NoteNumber: Ex: 1 = Redovisningsprinciper
 * @param rowIds: array of row Ids in a note.
 * @param endId: string that represents a column's id.
 * @param table = 'table' is default. This is the usually the case when there's only one table.
 */
const sumNotesRowsById = (
  NoteNumber: number,
  rowIds: (string | number)[],
  endId:
    | 'year'
    | 'previousYear'
    | 'reportedValue'
    | 'taxValue'
    | 'temporaryDifference',
  table = 'table'
): ReferenceCell =>
  ref(
    ifOrElse(
      sum(
        ...[...rowIds].map((idNumber) => {
          return abs(
            or(id(`notes.note${NoteNumber}.${table}.${idNumber}.${endId}`), 0)
          );
        })
      ),
      sum(
        ...[...rowIds].map((idNumber) => {
          return or(
            id(`notes.note${NoteNumber}.${table}.${idNumber}.${endId}`),
            0
          );
        })
      ),
      undefined
    )
  );

export const notesConfig = (
  financialYears: string[],
  financialYear: string,
  period: string | null,
  isDigitalSubmission: boolean
): IndividualCompanyNotes => {
  const noteLabel = (name: string) =>
    isDigitalSubmission ? label(name) : value(name);

  return {
    active: undefined,
    headings: {
      active: undefined,
      number: value(undefined),
      name: value(undefined),
      notesIncomeStatementTitle: active(field('Noter till resultaträkning')),
      notesBalanceSheetTitle: active(field('Noter till balansräkning')),
      notesOtherTitle: active(field('Övriga noter')),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note1: {
      active: true,
      number: value(1),
      name: field('Redovisningsprinciper'),
      disclaimerK1: field(
        'Bokslutet är upprättad i enlighet med bokföringslagen  och Bokföringsnämndens allmänna råd (BFNAR 2006:1) Enskilda näringsidkare som upprättar förenklat årsbokslut, K1.'
      ),
      disclaimerK2: field(
        'Årsbokslutet är upprättat i enlighet med bokföringslagens regler om årsbokslut och Bokföringsnämndens allmänna råd (BFNAR 2017:3) om årsbokslut. Eftersom nettoomsättningen är högre än tre miljoner kronor, finner bolaget även vägledning i regelverket K2.'
      ),
      disclaimerK3: field(
        'Årsbokslutet är upprättat i enlighet med bokföringslagens regler om årsbokslut och Bokföringsnämndens allmänna råd (BFNAR 2017:3) om årsbokslut. Eftersom nettoomsättningen är högre än tre miljoner kronor, finner bolaget även vägledning i regelverket K3.'
      ),
      intangibleAssetsGeneralLabel: field('Immateriella anläggningstillgångar'),
      intangibleAssetsGeneral: field(
        'Immateriella anläggningstillgångar redovisas till anskaffningsvärde med avdrag för ackumulerade avskrivningar och eventuella nedskrivningar. Tillgångarna skrivs av linjärt över tillgångarnas bedömda nyttjande period. Nyttjandeperioden omprövas per varje balansdag. Pågående projekt skrivs inte av utan nedskrivningsprövas årligen. Följande nyttjandeperioder tillämpas:'
      ),
      tangibleFixedAssetsLabel: field('Materiella anläggningstillgångar'),
      tangibleFixedAssetsGeneral: field(
        'Materiella anläggningstillgångar redovisas till anskaffningsvärde med avdrag för ackumulerade avskrivningar och eventuella nedskrivningar. Tillgångarna skrivs av linjärt över tillgångarnas bedömda nyttjandeperiod förutom mark som inte skrivs av. Nyttjandeperioden omprövas per varje balansdag. Följande nyttjandeperioder tillämpas:'
      ),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note56: {
      active: false,
      number: value(undefined),
      name: value('Bokslutsdispositioner'),
      table: table(
        'notes.note56.table',
        { id: 'label', label: 'Bokslutsdispositioner' },
        {
          id: 'column',
          label: '',
        }
      )
        .addRows((rows) =>
          rows
            .addRow('1', noteLabel('Expansionsfond'), value(undefined))
            .addRow('2', noteLabel('Ersätningfond'), value(undefined))
            .addRow(
              '3',
              noteLabel('Insatsemissioner, Skogskonto, upphovsmannakonto'),
              value(undefined)
            )
            .addRow(
              '4',
              noteLabel('Avbetalningsplan - skog o dyl.'),
              value(undefined)
            )
            .build()
        )
        .newRowTemplate(value(''), value(undefined))
        .build(),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note6: {
      active: false,
      number: value(undefined),
      name: value(
        'Koncessioner, patent, licenser, varumärken samt liknande rättigheter'
      ),
      table: table(
        `notes.note6.table`,
        { id: 'label', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        // 'ingående' = previous period outgoing balance
        .addRows((rows) =>
          rows
            .addRow(
              '1',
              noteLabel('Ingående anskaffningsvärden'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '2',
              noteLabel('Förändringar av anskaffningsvärden'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '3',
              noteLabel('Utgående anskaffningsvärden'),
              sumNotesRowsById(6, [...range(1, 3)], 'year'),
              sumNotesRowsById(6, [...range(1, 3)], 'previousYear')
            )
            .addRow(
              '4',
              noteLabel('Ingående avskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '5',
              noteLabel('Förändringar av avskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '6',
              noteLabel('Utgående avskrivningar'),
              sumNotesRowsById(6, [...range(4, 6)], 'year'),
              sumNotesRowsById(6, [...range(4, 6)], 'previousYear')
            )
            .addRow(
              '7',
              noteLabel('Ingående nedskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '8',
              noteLabel('Förändringar av nedskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '9',
              noteLabel('Utgående nedskrivningar'),
              sumNotesRowsById(6, [...range(7, 9)], 'year'),
              sumNotesRowsById(6, [...range(7, 9)], 'previousYear')
            )
            .addRow(
              '10',
              noteLabel('Redovisat värde'),
              sumNotesRowsById(6, [3, 6, 9], 'year'),
              sumNotesRowsById(6, [3, 6, 9], 'previousYear')
            )
            .build()
        )
        .newRowTemplate(value(''), value(undefined), value(undefined))
        .build(),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note7: {
      active: false,
      number: value(undefined),
      name: value('Hyresrätter och liknande rättigheter'),
      table: table(
        `notes.note7.table`,
        { id: 'label', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        // 'ingående' = previous period outgoing balance
        .addRows((rows) =>
          rows
            .addRow(
              '1',
              noteLabel('Ingående anskaffningsvärden'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '2',
              noteLabel('Förändringar av anskaffningsvärden'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '3',
              noteLabel('Utgående anskaffningsvärden'),
              sumNotesRowsById(7, [...range(1, 3)], 'year'),
              sumNotesRowsById(7, [...range(1, 3)], 'previousYear')
            )
            .addRow(
              '4',
              noteLabel('Ingående avskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '5',
              noteLabel('Förändringar av avskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '6',
              noteLabel('Utgående avskrivningar'),
              sumNotesRowsById(7, [...range(4, 6)], 'year'),
              sumNotesRowsById(7, [...range(4, 6)], 'previousYear')
            )
            .addRow(
              '7',
              noteLabel('Ingående nedskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '8',
              noteLabel('Förändringar av nedskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '9',
              noteLabel('Utgående nedskrivningar'),
              sumNotesRowsById(7, [...range(7, 9)], 'year'),
              sumNotesRowsById(7, [...range(7, 9)], 'previousYear')
            )
            .addRow(
              '10',
              noteLabel('Redovisat värde'),
              sumNotesRowsById(7, [3, 6, 9], 'year'),
              sumNotesRowsById(7, [3, 6, 9], 'previousYear')
            )
            .build()
        )
        .newRowTemplate(value(''), value(undefined), value(undefined))
        .build(),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note8: {
      active: false,
      number: value(undefined),
      name: value('Goodwill'),
      table: table(
        `notes.note8.table`,
        { id: 'label', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        .addRows((rows) =>
          rows
            .addRow(
              '1',
              noteLabel('Ingående anskaffningsvärden'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '2',
              noteLabel('Förändringar av anskaffningsvärden'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '3',
              noteLabel('Utgående anskaffningsvärden'),
              sumNotesRowsById(8, [...range(1, 3)], 'year'),
              sumNotesRowsById(8, [...range(1, 3)], 'previousYear')
            )
            .addRow(
              '4',
              noteLabel('Ingående avskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '5',
              noteLabel('Förändringar av avskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '6',
              noteLabel('Utgående avskrivningar'),
              sumNotesRowsById(8, [...range(4, 6)], 'year'),
              sumNotesRowsById(8, [...range(4, 6)], 'previousYear')
            )
            .addRow(
              '7',
              noteLabel('Ingående nedskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '8',
              noteLabel('Förändringar av nedskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '9',
              noteLabel('Utgående nedskrivningar'),
              sumNotesRowsById(8, [...range(7, 9)], 'year'),
              sumNotesRowsById(8, [...range(7, 9)], 'previousYear')
            )
            .addRow(
              '10',
              noteLabel('Redovisat värde'),
              sumNotesRowsById(8, [3, 6, 9], 'year'),
              sumNotesRowsById(8, [3, 6, 9], 'previousYear')
            )
            .build()
        )
        .newRowTemplate(value(''), value(undefined), value(undefined))
        .build(),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note9: {
      active: false,
      number: value(undefined),
      name: value('Förskott avseende immateriella anläggningstillgångar'),
      table: table(
        'notes.note9.table',
        { id: 'label', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        // 'ingående' = previous period outgoing balance
        .addRows((rows) =>
          rows
            .addRow(
              '1',
              noteLabel('Ingående anskaffningsvärden'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '2',
              noteLabel('Förändringar av anskaffningsvärden'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '3',
              noteLabel('Utgående anskaffningsvärden'),
              sumNotesRowsById(9, [...range(1, 3)], 'year'),
              sumNotesRowsById(9, [...range(1, 3)], 'previousYear')
            )
            .build()
        )
        .newRowTemplate(value(''), value(undefined), value(undefined))
        .build(),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note10: {
      active: false,
      number: value(undefined),
      name: value('Byggnader och mark'),
      table: table(
        `notes.note10.table`,
        { id: 'label', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        // 'ingående' = previous period outgoing balance
        .addRows((rows) =>
          rows
            .addRow(
              '1',
              noteLabel('Ingående anskaffningsvärden'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '2',
              noteLabel('Förändringar av anskaffningsvärden'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '3',
              noteLabel('Utgående anskaffningsvärden'),
              sumNotesRowsById(10, [...range(1, 3)], 'year'),
              sumNotesRowsById(10, [...range(1, 3)], 'previousYear')
            )
            .addRow(
              '4',
              noteLabel('Ingående avskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '5',
              noteLabel('Förändringar av avskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '6',
              noteLabel('Utgående avskrivningar'),
              sumNotesRowsById(10, [...range(4, 6)], 'year'),
              sumNotesRowsById(10, [...range(4, 6)], 'previousYear')
            )
            .addRow(
              '7',
              noteLabel('Ingående uppskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '8',
              noteLabel('Förändringar av uppskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '9',
              noteLabel('Utgående uppskrivningar'),
              sumNotesRowsById(10, [...range(7, 9)], 'year'),
              sumNotesRowsById(10, [...range(7, 9)], 'previousYear')
            )
            .addRow(
              '10',
              noteLabel('Ingående nedskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '11',
              noteLabel('Förändringar av nedskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '12',
              noteLabel('Utgående nedskrivningar'),
              sumNotesRowsById(10, [...range(10, 12)], 'year'),
              sumNotesRowsById(10, [...range(10, 12)], 'previousYear')
            )
            .addRow(
              '13',
              noteLabel('Redovisat värde'),
              sumNotesRowsById(10, [3, 6, 9, 12], 'year'),
              sumNotesRowsById(10, [3, 6, 9, 12], 'previousYear')
            )
            .build()
        )
        .newRowTemplate(value(''), value(undefined), value(undefined))
        .build(),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note11: {
      active: false,
      number: value(undefined),
      name: value('Maskiner och andra tekniska anläggningar'),
      table: table(
        `notes.note11.table`,
        { id: 'label', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        .addRows((rows) =>
          rows
            .addRow(
              '1',
              noteLabel('Ingående anskaffningsvärden'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '2',
              noteLabel('Förändringar av anskaffningsvärden'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '3',
              noteLabel('Utgående anskaffningsvärden'),
              sumNotesRowsById(11, [...range(1, 3)], 'year'),
              sumNotesRowsById(11, [...range(1, 3)], 'previousYear')
            )
            .addRow(
              '4',
              noteLabel('Ingående avskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '5',
              noteLabel('Förändringar av avskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '6',
              noteLabel('Utgående avskrivningar'),
              sumNotesRowsById(11, [...range(4, 6)], 'year'),
              sumNotesRowsById(11, [...range(4, 6)], 'previousYear')
            )
            .addRow(
              '7',
              noteLabel('Ingående nedskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '8',
              noteLabel('Förändringar av nedskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '9',
              noteLabel('Utgående nedskrivningar'),
              sumNotesRowsById(11, [...range(7, 9)], 'year'),
              sumNotesRowsById(11, [...range(7, 9)], 'previousYear')
            )
            .addRow(
              '10',
              noteLabel('Redovisat värde'),
              sumNotesRowsById(11, [3, 6, 9], 'year'),
              sumNotesRowsById(11, [3, 6, 9], 'previousYear')
            )
            .build()
        )
        .newRowTemplate(value(''), value(undefined), value(undefined))
        .build(),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note12: {
      active: false,
      number: value(undefined),
      name: value('Inventarier, verktyg och installationer'),
      table: table(
        `notes.note12.table`,
        { id: 'label', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        .addRows((rows) =>
          rows
            .addRow(
              '1',
              noteLabel('Ingående anskaffningsvärden'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '2',
              noteLabel('Förändringar av anskaffningsvärden'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '3',
              noteLabel('Utgående anskaffningsvärden'),
              sumNotesRowsById(12, [...range(1, 3)], 'year'),
              sumNotesRowsById(12, [...range(1, 3)], 'previousYear')
            )
            .addRow(
              '4',
              noteLabel('Ingående avskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '5',
              noteLabel('Förändringar av avskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '6',
              noteLabel('Utgående avskrivningar'),
              sumNotesRowsById(12, [...range(4, 6)], 'year'),
              sumNotesRowsById(12, [...range(4, 6)], 'previousYear')
            )
            .addRow(
              '7',
              noteLabel('Ingående nedskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '8',
              noteLabel('Förändringar av nedskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '9',
              noteLabel('Utgående nedskrivningar'),
              sumNotesRowsById(12, [...range(7, 9)], 'year'),
              sumNotesRowsById(12, [...range(7, 9)], 'previousYear')
            )
            .addRow(
              '10',
              noteLabel('Redovisat värde'),
              sumNotesRowsById(12, [3, 6, 9], 'year'),
              sumNotesRowsById(12, [3, 6, 9], 'previousYear')
            )
            .build()
        )
        .newRowTemplate(value(''), value(undefined), value(undefined))
        .build(),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note13: {
      active: false,
      number: value(undefined),
      name: value('Förbättringsutgifter på annans fastighet'),
      table: table(
        `notes.note13.table`,
        { id: 'label', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        .addRows((rows) =>
          rows
            .addRow(
              '1',
              noteLabel('Ingående anskaffningsvärden'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '2',
              noteLabel('Förändringar av anskaffningsvärden'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '3',
              noteLabel('Utgående anskaffningsvärden'),
              sumNotesRowsById(13, [...range(1, 3)], 'year'),
              sumNotesRowsById(13, [...range(1, 3)], 'previousYear')
            )
            .addRow(
              '4',
              noteLabel('Ingående avskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '5',
              noteLabel('Förändringar av avskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '6',
              noteLabel('Utgående avskrivningar'),
              sumNotesRowsById(13, [...range(4, 6)], 'year'),
              sumNotesRowsById(13, [...range(4, 6)], 'previousYear')
            )
            .addRow(
              '7',
              noteLabel('Ingående nedskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '8',
              noteLabel('Förändringar av nedskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '9',
              noteLabel('Utgående nedskrivningar'),
              sumNotesRowsById(13, [...range(7, 9)], 'year'),
              sumNotesRowsById(13, [...range(7, 9)], 'previousYear')
            )
            .addRow(
              '10',
              noteLabel('Redovisat värde'),
              sumNotesRowsById(13, [3, 6, 9], 'year'),
              sumNotesRowsById(13, [3, 6, 9], 'previousYear')
            )
            .build()
        )
        .newRowTemplate(value(''), value(undefined), value(undefined))
        .build(),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note14: {
      active: false,
      number: value(undefined),
      name: value('Övriga materiella anläggningstillgångar'),
      table: table(
        `notes.note14.table`,
        { id: 'label', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        .addRows((rows) =>
          rows
            .addRow(
              '1',
              noteLabel('Ingående anskaffningsvärden'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '2',
              noteLabel('Förändringar av anskaffningsvärden'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '3',
              noteLabel('Utgående anskaffningsvärden'),
              sumNotesRowsById(14, [...range(1, 3)], 'year'),
              sumNotesRowsById(14, [...range(1, 3)], 'previousYear')
            )
            .addRow(
              '4',
              noteLabel('Ingående avskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '5',
              noteLabel('Förändringar av avskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '6',
              noteLabel('Utgående avskrivningar'),
              sumNotesRowsById(14, [...range(4, 6)], 'year'),
              sumNotesRowsById(14, [...range(4, 6)], 'previousYear')
            )
            .addRow(
              '7',
              noteLabel('Ingående nedskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '8',
              noteLabel('Förändringar av nedskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '9',
              noteLabel('Utgående nedskrivningar'),
              sumNotesRowsById(14, [...range(7, 9)], 'year'),
              sumNotesRowsById(14, [...range(7, 9)], 'previousYear')
            )
            .addRow(
              '10',
              noteLabel('Redovisat värde'),
              sumNotesRowsById(14, [3, 6, 9], 'year'),
              sumNotesRowsById(14, [3, 6, 9], 'previousYear')
            )
            .build()
        )
        .newRowTemplate(value(''), value(undefined), value(undefined))
        .build(),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    note15: {
      active: false,
      number: value(undefined),
      name: value(
        'Pågående nyanläggningar och förskott avseende materiella anläggningstillgångar'
      ),
      table: table(
        'notes.note15.table',
        { id: 'label', label: '' },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) || '',
        }
      )
        .addRows((rows) =>
          rows
            .addRow(
              '1',
              noteLabel('Ingående anskaffningsvärden'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '2',
              noteLabel('Förändringar av anskaffningsvärden'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '3',
              noteLabel('Utgående anskaffningsvärden'),
              sumNotesRowsById(15, [...range(1, 3)], 'year'),
              sumNotesRowsById(15, [...range(1, 3)], 'previousYear')
            )
            .addRow(
              '4',
              noteLabel('Ingående nedskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '5',
              noteLabel('Förändringar av nedskrivningar'),
              value(undefined),
              value(undefined)
            )
            .addRow(
              '6',
              noteLabel('Utgående nedskrivningar'),
              sumNotesRowsById(15, [...range(4, 6)], 'year'),
              sumNotesRowsById(15, [...range(4, 6)], 'previousYear')
            )
            .addRow(
              '7',
              noteLabel('Redovisat värde'),
              sumNotesRowsById(15, [3, 6], 'year'),
              sumNotesRowsById(15, [3, 6], 'previousYear')
            )
            .build()
        )
        .newRowTemplate(value(''), value(undefined), value(undefined))
        .build(),
      commentLabel: field('Kommentar'),
      comment: field(''),
      group: value(undefined),
      section: value(undefined),
      validForReportType: {
        k2: true,
        k3: true,
      },
    },
    custom: {
      type: 'sections',
      sections: [],
      newSectionTemplate: {
        active: false,
        number: { type: 'number', value: 0 },
        name: { type: 'string', value: 'Ny not' },
        group: { type: 'string', value: 'other' },
        section: { type: 'string', value: '' },
        validForReportType: {
          k2: true,
          k3: true,
        },
        commentTitle: { type: 'string', value: 'Kommentar' },
        typeOne: field('Egen fritext'),
        typeTwo: table(
          'notes.custom.typeTwoTable',
          { id: 'label', label: '' },
          {
            id: 'year',
            label: year(financialYear, financialYears, period) || '',
          },
          {
            id: 'previousYear',
            label: previousYear(financialYear, financialYears) || '',
          }
        )
          .newColumnTemplate((column) => column.setType('string').build())
          .newRowTemplate(value(''), value(undefined), value(undefined))
          .build(),
        typeThree: table(
          'notes.custom.typeThreeTable',
          { id: 'label', label: '' },
          { id: 'val1', label: '' },
          { id: 'val2', label: '' },
          { id: 'val3', label: '' },
          {
            id: 'year',
            label: year(financialYear, financialYears, period) || '',
          },
          {
            id: 'previousYear',
            label: previousYear(financialYear, financialYears) || '',
          }
        )
          .newColumnTemplate((column) => column.setType('string').build())
          .newRowTemplate(
            value(''),
            value(''),
            value(''),
            value(''),
            value(undefined),
            value(undefined)
          )
          .build(),
      },
    },
  };
};

export const notesReferences = (): Record<string, string> => ({});

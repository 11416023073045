export const getSharesCompanyNotesGeneratedV2K2 = {
  generalNotes: ['note1'],
  incomeStatementNotes: [
    'note4',
    'note5',
    'note9',
    'note10',
    'note14',
    'note29',
    'note21',
    'note23',
    'note26',
    'note32',
  ],
  balanceSheetNotes: [
    'note34',
    'note35',
    'note36',
    'note37',
    'note38',
    'note40',
    'note41',
    'note42',
    'note44',
    'note43',
    'note46',
    'note47',
    'note48',
    'note49',
    'note50',
    'note51',
    'note53',
    'note59',
    'note61',
    'note60',
    'note63',
    'note67',
    'note75',
    'note77',
    'note81',
    'note85',
    'note87',
  ],
  otherNotes: ['note82', 'note83', 'note92', 'note95', 'note96', 'note100'],
};

import { AgoyDocumentStructure } from '../..';
import { Errors, isError, OperationResult, State } from '../types';

const chain = <T extends AgoyDocumentStructure>(
  state: State<T>,
  ...args: ((state: State<T>) => OperationResult<T>)[]
): OperationResult<T> => {
  const result = args.reduce((state: State<T> | Errors, fn) => {
    if (isError(state)) {
      return state;
    }
    const res = fn(state);
    return res || state;
  }, state);

  return result === state ? false : result;
};

export default chain;

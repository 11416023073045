import { table, value } from '@agoy/document';
import { NoteOneTableSection } from '../../../../common';
import { field } from '../../../../common/utils/util';

export const getNote37 = (
  noteLabel: any,
  documentTypeVersion = '1'
): NoteOneTableSection => {
  return {
    active: false,
    number: value(undefined),
    name: value('Koncernförhållanden'),
    table: table('notes.note37.table', { id: 'label', label: '' })
      .addRows((rows) =>
        rows
          .addRowActive(false)
          .addRow(
            '1',
            noteLabel(
              'Bolaget är helägt dotterbolag till XXX AB, Org.nr XXXXXXXXX-XXXX, säte XXX'
            )
          )
          .addRowActive(false)
          .addRow(
            '2',
            noteLabel(
              'Bolaget är moderbolag, men med stöd av ÅRL 7 kap 2§ upprättas inte någon koncernredovisning.'
            )
          )
          .addRowActive(false)
          .addRow(
            '3',
            noteLabel(
              'Bolaget är moderbolag, men med stöd av ÅRL 7 kap 3§ upprättas inte någon koncernredovisning.'
            )
          )
          .addRowActive(false)
          .addRow(
            '4',
            noteLabel(
              'Övergripande koncernredovisning upprättas av: Namn, Org.nr, säte'
            )
          )
          .addRowActive(false)
          .addRow(
            '5',
            noteLabel(
              'Minsta koncernredovisning upprättas av: Namn, Org.nr, säte'
            )
          )
          .build()
      )
      .build(),
    commentLabel: field('Kommentar'),
    comment: field(''),
    group: value(undefined),
    section: value(undefined),
    validForReportType: {
      k2: true,
      k3: documentTypeVersion === '1',
    },
  };
};

import React, { useRef, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Typography } from '@material-ui/core';
import styled from '@emotion/styled';

// @ts-ignore
import { ReactComponent as AgoyHeaderLogo } from 'assets/AgoyHeaderLogo.svg';
import facebookIcon from 'assets/facebook.svg';
import instagramIcon from 'assets/instagram.svg';
import linkedinIcon from 'assets/linkedin.svg';

const Wrapper = styled.div`
  height: 100vh;

  background-color: white;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const AgoyLogo = styled(AgoyHeaderLogo)`
  width: 200px;
  height: 45px;
  margin-top: 40px;
  margin-bottom: ${(props) => props.theme.spacing(7)}px;
`;

const SplashWrapper = styled.div`
  overflow: hidden;

  svg {
    max-width: 100%;
    height: 400px;
  }
`;

const SocialMediaWrapper = styled.div`
  display: flex;
  align-self: flex-end;
`;

const Image = styled.img`
  height: 40px;
  width: 40px;
  margin-bottom: 20px;
  margin-right: 20px;
  margin-top: 60px;
`;

const Text = styled(Typography)`
  margin: ${(props) => props.theme.spacing(5)}px;
  text-align: left;
`;

const MobileSplash = () => {
  const { formatMessage } = useIntl();

  return (
    <Wrapper>
      <LogoWrapper>
        <AgoyLogo />
      </LogoWrapper>
      <Text variant="h3">
        {formatMessage({ id: 'app.tsx.welcome.mobile.user' })}
      </Text>
    </Wrapper>
  );
};

export default MobileSplash;

import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import { useIntl } from 'react-intl';
import styled from '@emotion/styled';

import DeleteClientDialog from './DeleteClientDialog';

const StyledButton = styled(Button)`
  background-color: ${({ theme }) => theme.palette.error.main};
  color: ${({ theme }) => theme.palette.error.contrastText};
  margin-left: ${({ theme }) => theme.spacing(2)}px;
`;

type Props = {
  clientName: string;
  handleConfirm: () => Promise<void>;
};

const DeleteClientButton = ({
  clientName,
  handleConfirm,
}: Props): JSX.Element => {
  const { formatMessage } = useIntl();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onConfirm = async () => {
    // Trigger the loading logo animation
    setIsLoading(true);

    try {
      await handleConfirm();
      setIsOpen(false);
    } finally {
      setIsOpen(false);
      setIsLoading(false);
    }
  };

  return (
    <>
      <StyledButton
        onClick={() => setIsOpen(true)}
        color={isOpen ? 'primary' : undefined}
      >
        {formatMessage({ id: 'client.delete.dialog.button' })}
      </StyledButton>

      <DeleteClientDialog
        clientName={clientName}
        isOpen={isOpen}
        isLoading={isLoading}
        onClose={() => {
          setIsOpen(false);
        }}
        onConfirm={onConfirm}
      />
    </>
  );
};

export default DeleteClientButton;

import React from 'react';
import { NoteOneTableSection } from '@agoy/annual-report-document';
import { Field, numberValue, stringValue } from '@agoy/document';
import CheckboxTable from './CheckboxTable';
import Comment from './Comment';
import Header from './Header';
import { Note } from './Note';

type CheckboxesNoteProps = {
  noteId: string;
  title: string;
  commentLabel: Field;
} & NoteOneTableSection;

const CheckboxesNote = ({
  noteId,
  active,
  table,
  number,
  comment,
  title,
  commentLabel,
}: CheckboxesNoteProps) => {
  if (!active) return null;

  return (
    <Note id={noteId}>
      <Header noteId={noteId} noteNumber={numberValue(number)} title={title} />

      {/* Checkbox table */}
      <CheckboxTable tableId={`notes.${noteId}.table`} table={table} />

      {/* Comment */}
      <Comment
        field={comment}
        value={stringValue(comment) || ''}
        labelValue={stringValue(commentLabel) || ''}
      />
    </Note>
  );
};

export default CheckboxesNote;

import React from 'react';
import styled from '@emotion/styled';
import { Button, Dialog, IconButton, Typography } from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import CloseIcon from '@material-ui/icons/Close';
import { useIntl } from 'react-intl';
import LoadingLogo from '_shared/components/LoadingLogo';

const DangerButton = styled(Button)<{ loading: boolean }>`
  background-color: ${(props) =>
    props.loading
      ? `${props.theme.palette.grey[100]}`
      : `${props.theme.palette.error.main}`};
  color: ${({ theme }) => theme.palette.error.contrastText};
  margin-top: ${({ theme }) => theme.spacing(4)}px;
`;

const StyledDialog = styled(Dialog)`
  text-align: center;
  .MuiDialog-paper {
    padding: ${({ theme }) => theme.spacing(4)}px;
    width: 390px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }
`;

const CloseButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
`;

const StyledDeleteIcon = styled(DeleteForeverIcon)`
  font-size: 80px;
`;

type Props = {
  clientName: string;
  isOpen: boolean;
  isLoading: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

const DeleteClientDialog = ({
  clientName,
  isOpen,
  isLoading,
  onClose,
  onConfirm,
}: Props): JSX.Element => {
  const { formatMessage } = useIntl();
  return (
    <StyledDialog open={isOpen} onClose={onClose}>
      <CloseButton onClick={onClose}>
        <CloseIcon fontSize="large" />
      </CloseButton>
      <StyledDeleteIcon />
      <Typography variant="h4">
        {formatMessage({ id: 'client.delete.dialog.title' })}
      </Typography>
      <Typography color="textSecondary">
        {formatMessage({ id: 'client.delete.dialog.text' }, { clientName })}
      </Typography>
      <br />
      <Typography color="textSecondary">
        {formatMessage(
          { id: 'client.delete.dialog.textConfirm' },
          { clientName }
        )}
      </Typography>
      <DangerButton
        variant="contained"
        onClick={() => onConfirm()}
        loading={isLoading}
      >
        {!isLoading ? (
          formatMessage({ id: 'client.delete.dialog.button' })
        ) : (
          <LoadingLogo size="small" />
        )}
      </DangerButton>
    </StyledDialog>
  );
};

export default DeleteClientDialog;

import { useState, useEffect } from 'react';

import { useApiSdk, asResultClass } from 'api-sdk';
import { useSelector } from 'redux/reducers';
import { AccountPlanList, VoucherSeries } from 'types/Voucher';

type VoucherDataProps = {
  clientId: string;
  period: string;
};

const useVoucherData = ({ clientId, period }: VoucherDataProps) => {
  const sdk = useApiSdk();

  const [accountsList, setAccountsList] = useState<AccountPlanList[]>([]);
  const [seriesList, setSeriesList] = useState<VoucherSeries[]>([]);

  const integrationActive = useSelector(
    ({ customers }) => !!customers[clientId]?.integrations?.fortnox
  );

  useEffect(() => {
    const getVoucherSeries = async () => {
      const result = await asResultClass(
        sdk.getVoucherSeries({
          clientid: clientId,
          period,
        })
      );
      if (result.ok) {
        const series = result.val.map((item) => {
          return {
            series: item.series,
            label: `${item.series} - ${item.description}`,
          };
        });
        setSeriesList(series);
      }
    };
    getVoucherSeries();
  }, [period, clientId, sdk]);

  useEffect(() => {
    const getAccountsList = async () => {
      const source = integrationActive ? 'fortnox' : 'agoy';
      const result = await asResultClass(
        sdk.getAccountList({
          clientid: clientId,
          source,
        })
      );

      if (result.ok) {
        const accounts = result.val.map((item) => {
          return {
            value: item.number,
            label: item.name,
            noActive: !item.active,
          };
        });

        setAccountsList([
          { value: 0, label: ' ', noActive: false },
          ...accounts,
        ]);
      }
    };
    getAccountsList();
  }, [clientId, integrationActive, sdk]);

  return {
    accountsList,
    seriesList,
  };
};

export default useVoucherData;

import React from 'react';
import { Typography } from '@material-ui/core';
import styled from '@emotion/styled';

const Body = styled(Typography)`
  font-size: inherit;
  white-space: pre-line;
`;

type CommentProps = {
  value: string;
  title?: string;
};

const PrintCustomNoteComment = ({
  value,
  title,
}: CommentProps): JSX.Element | null => {
  const Title = <Typography variant="h4">{title || ''}</Typography>;

  return (
    <>
      {Title}
      <Body>{value}</Body>
    </>
  );
};

export default PrintCustomNoteComment;

import React from 'react';
import styled from '@emotion/styled';
import { Box } from '@material-ui/core';
import Typography from '@agoy/annual-report-view/src/UI/Typography/Typography';
import {
  AnnualGeneralMeeting,
  ConfirmationCertificate,
  DocumentConfiguration,
  Settings,
} from '@agoy/annual-report-document';

import { useIntl } from 'react-intl';
import { stringValue } from '@agoy/document';

const Content = styled.div`
  padding: ${({ theme }) => theme.spacing(4)}px;
`;

interface ConfirmationCertificatePreviewProps {
  documentConfiguration: DocumentConfiguration;
  confirmationCertificate: ConfirmationCertificate;
  annualGeneralMeeting: AnnualGeneralMeeting;
  settings: Settings;
}

const ConfirmationCertificatePreview = ({
  documentConfiguration,
  confirmationCertificate,
  annualGeneralMeeting,
  settings,
}: ConfirmationCertificatePreviewProps): JSX.Element => {
  const { formatMessage } = useIntl();

  const currentFinancialYear = stringValue(settings.section.period);
  const { version, isDigitalSubmission } = documentConfiguration;

  const {
    section: {
      dsIncomeStatementBalanceSheet,
      dsBoardApproval,
      dsBoardDisapproval,
      dsDispositionDecision,
      dsOriginalsMatchVerificaton,
      dsSignatureClarificationSurname,
      dsRole,
      statement,
      location,
      date,
      signatureClarification,
      heading,
      clientNameText,
      organisationNumberText,
    },
  } = confirmationCertificate;

  const title =
    stringValue(heading) ||
    formatMessage({
      id: 'annualReport.menu.confirmationCertificate',
    });

  const clientName =
    stringValue(clientNameText) ||
    `${stringValue(settings.section.clientName)} (${stringValue(
      settings.section.organisationNumber
    )})`;

  const organisationNumber =
    stringValue(organisationNumberText) ||
    `${formatMessage({
      id: 'financialyear',
    })} ${currentFinancialYear}`;

  const agmDate = stringValue(annualGeneralMeeting?.main?.date);

  return (
    <Content>
      <Typography variant="h1">{title}</Typography>

      <Typography>{clientName}</Typography>
      <Typography>{organisationNumber}</Typography>

      {/* Statement, manual submission */}
      {!isDigitalSubmission && (
        <Box mt="4rem" mb="8rem" whiteSpace="break-spaces">
          <Typography>{stringValue(statement)}</Typography>
        </Box>
      )}

      {isDigitalSubmission && (
        <Box mt="4rem" mb="8rem" whiteSpace="break-spaces">
          {dsIncomeStatementBalanceSheet && (
            <Box mb={1}>
              <Typography>
                {stringValue(dsIncomeStatementBalanceSheet)}{' '}
                {agmDate ? `${agmDate}.` : ''} {stringValue(dsBoardApproval)}
                {stringValue(dsBoardDisapproval)}
              </Typography>
            </Box>
          )}

          {stringValue(dsBoardDisapproval) && (
            <Box mb={2}>
              <Typography>
                {formatMessage({
                  id: 'annualReport.confirmationCertificate.dispositionDecisionPretext',
                })}{' '}
                {stringValue(dsDispositionDecision)}
              </Typography>
            </Box>
          )}

          {dsOriginalsMatchVerificaton && (
            <Typography>{stringValue(dsOriginalsMatchVerificaton)}</Typography>
          )}
        </Box>
      )}

      {/* Location & Date */}
      {!isDigitalSubmission && (
        <Box mb="4rem">
          <Typography>
            {stringValue(location)
              ? `${stringValue(location)}, ${stringValue(date)}`
              : `${stringValue(date) || ''}`}
          </Typography>
        </Box>
      )}

      <Box mb="2rem">
        {isDigitalSubmission && (
          <Typography>
            {formatMessage({
              id: 'annualReport.confirmationCertificate.section.signatureClarificationDigitalSubmission',
            })}
          </Typography>
        )}
        {version === '1' ? (
          <Typography>{`${stringValue(signatureClarification)}${
            isDigitalSubmission &&
            stringValue(dsSignatureClarificationSurname) !== ''
              ? ` ${stringValue(dsSignatureClarificationSurname)}`
              : ''
          }${
            isDigitalSubmission && stringValue(dsRole) !== ''
              ? `, ${stringValue(dsRole)}`
              : ''
          }`}</Typography>
        ) : (
          <>
            {isDigitalSubmission ? (
              <>
                <Typography>
                  {stringValue(signatureClarification)}{' '}
                  {stringValue(dsSignatureClarificationSurname)}
                  {stringValue(dsSignatureClarificationSurname) &&
                    stringValue(dsRole) &&
                    ', '}
                  {stringValue(dsRole)}
                </Typography>
                <Typography>{stringValue(date)}</Typography>
              </>
            ) : (
              <Typography>
                {stringValue(signatureClarification)}{' '}
                {stringValue(dsSignatureClarificationSurname)}
                {stringValue(dsSignatureClarificationSurname) &&
                  stringValue(dsRole) &&
                  ', '}
                {stringValue(dsRole)}
              </Typography>
            )}
          </>
        )}
      </Box>
    </Content>
  );
};

export default ConfirmationCertificatePreview;

import React from 'react';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';
import { Typography } from '@material-ui/core';
import {
  SharesCompanyManagementReport,
  ResultsDisposition as ResultsDispositionType,
  EconomicAssociationManagementReport,
  isActive,
} from '@agoy/annual-report-document';
import { stringValue } from '@agoy/document';
import Cell from '../../../../UI/CommonPrintTable/Cell/Cell';
import { renderResultsDispositionRows } from './util';

const GridTable = styled.div`
  width: 50%;
  display: block;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;

  > span {
    display: block;
    width: inherit;
  }

  > span:first-of-type {
    flex: 1;
  }
`;

type ExtraordinaryMeetingSectionProps = {
  managementReport:
    | SharesCompanyManagementReport
    | EconomicAssociationManagementReport;
  reportPart: ResultsDispositionType;
  isDigitalSubmission: boolean | null | undefined;
};

const DisposalSection = ({
  managementReport,
  reportPart,
  isDigitalSubmission,
}: ExtraordinaryMeetingSectionProps): JSX.Element | null => {
  const { formatMessage } = useIntl();

  const extraordinaryMeetingDividendHeader =
    stringValue(
      managementReport.resultsDisposition.extraordinaryMeetingDividendHeader
    ) ||
    formatMessage({
      id: 'annualReport.menu.managementReport.extraordinaryMeetingDividendHeader',
    });

  const extraordinarySum =
    stringValue(
      managementReport.resultsDisposition.extraordinaryMeetingDividendTotal
    ) ||
    formatMessage({
      id: 'sum',
    });

  /**
   * If digital submission to BV, rows added by user are not displayed.
   */
  const rowsToDisplay = renderResultsDispositionRows(
    reportPart.extraordinaryMeetingDividend.rows,
    isDigitalSubmission
  );

  return (
    <>
      {reportPart.extraordinaryMeetingDividendHeader.active ? (
        <Typography variant="h4">
          {extraordinaryMeetingDividendHeader}
        </Typography>
      ) : null}

      <GridTable>
        {rowsToDisplay.map(
          (row) =>
            isActive(row.active) &&
            row.cells?.label &&
            row.cells.value && (
              <Row key={row.id}>
                <Cell cell={row.cells?.label} />
                <Cell cell={row.cells?.value} />
              </Row>
            )
        )}

        {reportPart.extraordinaryMeetingDividendTotal.active ? (
          <Row>
            <span>
              <Typography variant="inherit">{extraordinarySum}</Typography>
            </span>
            <Cell cell={reportPart.extraordinaryMeetingDividendTotal} />
          </Row>
        ) : null}
      </GridTable>
    </>
  );
};

export default DisposalSection;

import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Typography, Button } from '@material-ui/core';
import { useForm } from 'react-hook-form';

import useAuth from '_authentication/hooks/useAuth';
import FormError from '_shared/components/Inputs/FormError';
import { CTAButton } from '_shared/components/Buttons';
import { LoginBox, ActionsWrapper, TextField } from './SharedComponents';

type Props = {
  backToLogin: () => void;
  goToResetPassword: () => void;
  queryEmail?: string;
};

const ForgotContent: React.FC<Props> = ({
  backToLogin,
  goToResetPassword,
  queryEmail,
}: Props) => {
  const intl = useIntl();
  const auth = useAuth();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const [submitError, setSubmitError] = useState('');

  const requestNewPassword = async (data) => {
    const { username } = data;
    try {
      await auth.forgotPasswordAWS(username);
      goToResetPassword();
    } catch (error: any) {
      setSubmitError(
        error.name === 'UserNotFoundException'
          ? intl.formatMessage({ id: 'forgot.doesnotexists' })
          : intl.formatMessage({ id: 'error' })
      );
    }
  };

  return (
    <LoginBox onSubmit={handleSubmit(requestNewPassword)}>
      <Typography variant="h2">
        {intl.formatMessage({ id: 'login.forgot' })}
      </Typography>
      <Typography>{intl.formatMessage({ id: 'forgot.text' })}</Typography>

      <TextField
        {...register('username', {
          required: intl.formatMessage({ id: 'form.fieldRequired' }),
        })}
        variant="outlined"
        size="small"
        name="username"
        defaultValue={queryEmail || ''}
        placeholder={intl.formatMessage({ id: 'email.placeholder' })}
      />

      {submitError && <FormError>{submitError}</FormError>}
      <FormError>{errors.username?.message}</FormError>

      <ActionsWrapper>
        <Button onClick={backToLogin}>
          {intl.formatMessage({ id: 'forgot.back' })}
        </Button>
        <CTAButton type="submit" loading={auth.isLoading}>
          {intl.formatMessage({ id: 'login.retrieve' })}
        </CTAButton>
      </ActionsWrapper>
    </LoginBox>
  );
};

export default ForgotContent;

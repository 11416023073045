import React from 'react';
import { InputAdornment, TextField } from '@material-ui/core';
import { Search } from '@material-ui/icons';

type Props = {
  placeholder: string;
  fullWidth?: boolean;
  onChange: (newValue: string) => void;
};

const SearchField = (props: Props): JSX.Element => {
  const { placeholder, fullWidth, onChange } = props;

  const SearchIcon = () => (
    <InputAdornment position="start">
      <Search />
    </InputAdornment>
  );

  return (
    <TextField
      variant="outlined"
      size="small"
      fullWidth={fullWidth}
      style={{ width: !fullWidth ? '50%' : undefined }}
      // TODO: maybe use debounce
      onChange={(e) => onChange(e.target.value)}
      placeholder={placeholder}
      InputProps={{
        startAdornment: <SearchIcon />,
      }}
    />
  );
};

export default SearchField;

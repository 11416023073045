import React, { useState } from 'react';
import {
  Tooltip,
  Typography as MuiTypography,
  makeStyles,
} from '@material-ui/core';
import styled from '@emotion/styled';
import Typography from './Typography/Typography';

const tooltipStyles = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.fortnoxTooltip?.background,
    borderRadius: theme.spacing(1),
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  arrow: {
    color: theme.palette.fortnoxTooltip?.background,
  },
}));

const HiddenDigits = styled.a`
  cursor: pointer;
  :hover,
  :focus,
  :focus-visible {
    text-decoration: underline;
    outline: none;
  }
`;

interface PersonalNumberProps {
  value: string | undefined;
  style?: React.CSSProperties;
  isNewTypography?: boolean;
}

/**
 * Personal number with hidden digits at the end. In compliance with GDPR regulations.
 * Accessible with Tab + Enter when focused.
 * Ready to use with new fonts (isNewTypography = true)
 *
 * @param value social security number with format YYYYMMDD-XXXX
 * @param style style object to alter Mui Typography.
 *              Many places that use this component custom styling which is hard to capture with props only.
 * @param isNewTypography should use new Typography component
 * @returns
 */
const PersonalNumber = ({
  value,
  style,
  isNewTypography = false,
}: PersonalNumberProps) => {
  const classes = tooltipStyles();
  const [tootipOpen, setTooltipOpen] = useState(false);
  const openTooltip = () => setTooltipOpen(true);
  const closeTooltip = () => setTooltipOpen(false);

  const Component = isNewTypography ? Typography : MuiTypography;

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      setTooltipOpen((oldValue) => !oldValue);
      return;
    }

    if (event.key === 'Escape') {
      closeTooltip();
    }
  };

  if (!value) {
    return null;
  }

  return (
    <Component style={style}>
      <span>{value.slice(0, -4)}</span>
      <Tooltip
        open={tootipOpen}
        /**
         * Plain string will be added as title prop to the tag.
         * Instead we add ReactNode.
         */
        title={<span>{value.slice(-4)}</span>}
        arrow
        classes={classes}
        placement="right"
        disableFocusListener
      >
        <HiddenDigits
          tabIndex={0}
          // onKeyPress doesn't capture Escape, most likely Mui related.
          onKeyUp={handleKeyPress}
          onBlur={closeTooltip}
          onMouseEnter={openTooltip}
          onMouseLeave={closeTooltip}
        >
          XXXX
        </HiddenDigits>
      </Tooltip>
    </Component>
  );
};

export default PersonalNumber;

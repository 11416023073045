import _ from 'lodash';
import { ACCOUNTANT_LABELS } from '../types/enums';
/**
 * Formats roles to be separated by comma (,).s
 * From a string that has roles with pipes (A|B|C)
 * @param rolesString Roles
 * @returns
 */
export const formatRolesByComma = (rolesString: string | undefined): string => {
  if (!rolesString || rolesString === '') return '';
  return _.uniq(rolesString.split('|')).join(', ');
};

/**
 * Formats the checkbox values options combinations {LABEL} into
 * more readable text
 * @param rolesString
 * @returns
 */
export const formatAccountantRoles = (
  rolesString: string | undefined
): string => {
  if (!rolesString || rolesString === '') return '';

  const roles = _.uniq(rolesString.split('|'));
  let roleString = '';

  // Authorized - Accountant, Authorized - Accountant - Main Accountant
  if (roles.includes(ACCOUNTANT_LABELS.AUTHORIZED)) {
    roleString += roles.splice(
      roles.indexOf(ACCOUNTANT_LABELS.AUTHORIZED),
      1
    )[0];
    // Authorized - Accountant,
    if (roles.includes(ACCOUNTANT_LABELS.ACCOUNTANT)) {
      roleString += ` ${roles
        .splice(roles.indexOf(ACCOUNTANT_LABELS.ACCOUNTANT), 1)[0]
        .toLocaleLowerCase()}`;

      // Authorized - Accountant - Main Accountant
      if (
        roles.find((role) =>
          role.includes(ACCOUNTANT_LABELS.MAIN_ACCOUNTANT_IS_ACCOUNTANT_FIRM)
        ) !== undefined
      ) {
        const MA = roles.splice(
          roles.indexOf(ACCOUNTANT_LABELS.MAIN_ACCOUNTANT_IS_ACCOUNTANT_FIRM),
          1
        );
        roleString += `, ${MA[0][0].toLocaleLowerCase()}` + MA[0].slice(1);
      }
    }

    // Authorized - Main Accountant
    if (
      roles.find((role) =>
        role.includes(ACCOUNTANT_LABELS.MAIN_ACCOUNTANT_IS_ACCOUNTANT_FIRM)
      ) !== undefined
    ) {
      const MA = roles.splice(
        roles.indexOf(ACCOUNTANT_LABELS.MAIN_ACCOUNTANT_IS_ACCOUNTANT_FIRM),
        1
      );
      roleString += ` ${MA[0][0].toLocaleLowerCase()}` + MA[0].slice(1);
    }
    return roleString;
  }

  // Approved - Accountant, Approved - Accountant - Main Accountant
  if (roles.includes(ACCOUNTANT_LABELS.APPROVED)) {
    roleString += roles.splice(roles.indexOf(ACCOUNTANT_LABELS.APPROVED), 1)[0];
    // Approved - Accountant,
    if (roles.includes(ACCOUNTANT_LABELS.ACCOUNTANT)) {
      roleString += ` ${roles
        .splice(roles.indexOf(ACCOUNTANT_LABELS.ACCOUNTANT), 1)[0]
        .toLocaleLowerCase()}`;

      // Authorized - Accountant - Main Accountant
      if (
        roles.find((role) =>
          role.includes(ACCOUNTANT_LABELS.MAIN_ACCOUNTANT_IS_ACCOUNTANT_FIRM)
        ) !== undefined
      ) {
        const MA = roles.splice(
          roles.indexOf(ACCOUNTANT_LABELS.MAIN_ACCOUNTANT_IS_ACCOUNTANT_FIRM),
          1
        );
        roleString += `, ${MA[0][0].toLocaleLowerCase()}` + MA[0].slice(1);
      }
    }

    // Approved - Main Accountant
    if (
      roles.find((role) =>
        role.includes(ACCOUNTANT_LABELS.MAIN_ACCOUNTANT_IS_ACCOUNTANT_FIRM)
      ) !== undefined
    ) {
      const MA = roles.splice(
        roles.indexOf(ACCOUNTANT_LABELS.MAIN_ACCOUNTANT_IS_ACCOUNTANT_FIRM),
        1
      );
      roleString += ` ${MA[0][0].toLocaleLowerCase()}` + MA[0].slice(1);
    }
    return roleString;
  }

  return formatRolesByComma(rolesString);
};

import React from 'react';
import HTMLComment from 'react-html-comment';

const isServer = !!process.env.DB_HOST;

const translateAttributeName = (name: string) => {
  if (name.startsWith('link')) {
    return `link:${name.substring(4).toLocaleLowerCase()}`;
  }
  return name;
};
const printProps = (props: { [key: string]: string }) =>
  Object.entries(props)
    .filter((entry) => entry[0] !== 'children')
    .map(([key, value]) => `${translateAttributeName(key)}="${value}"`)
    .join(' ');

type XMLProps = {
  tagName: string;
  namespace: string;
} & any;

const XML = ({ tagName, namespace, ...props }: XMLProps): JSX.Element => {
  if (isServer) {
    return React.createElement(
      `${namespace}:${tagName}`,
      props,
      props.children
    );
  }
  if (!props.children) {
    return (
      <HTMLComment text={`<${namespace}:${tagName} ${printProps(props)} />`} />
    );
  }
  return (
    <>
      <HTMLComment text={`<${namespace}:${tagName} ${printProps(props)}>`} />
      {props.children}
      <HTMLComment text={`</${namespace}:${tagName}>`} />
    </>
  );
};

export default XML;

import { value, ref } from '@agoy/document';
import { NoteOneTableSection, IxbrlCell } from '../../../../common';
import {
  sumAccountsIBs,
  sumPositiveTransactions,
  sumNegativeTransactions,
  sumNotesRowsById,
} from '../../../../common/utils/accountSumsUtil';
import { year, previousYear } from '../../../../common/utils/date-util';
import {
  field,
  ixbrlCell,
  ixbrlField,
  range,
  table,
} from '../../../../common/utils/util';
import { NoteAccountRanges } from '../types';

const MONETARY_ITEM_TYPE = 'monetaryItemType';

export const note21Accounts: NoteAccountRanges[] = [
  {
    rowId: '1',
    ranges: [[1346], [1673, 1679]],
    cellLabel: 'Ingående anskaffningsvärden',
    action: 'sumAccountsIBs',
  },
  {
    rowId: '2',
    ranges: [[1346], [1673, 1679]],
    cellLabel: 'Tillkommande fordringar',
    action: 'sumPositiveTransactions',
  },
  {
    rowId: '3',
    ranges: [[1346], [1673, 1679]],
    cellLabel: 'Reglerade fordringar',
    action: 'sumNegativeTransactions',
  },
  {
    rowId: '7',
    ranges: [[1347]],
    cellLabel: 'Ingående nedskrivningar',
    action: 'sumAccountsIBs',
  },
  {
    rowId: '8',
    ranges: [[1347]],
    cellLabel: 'Reglerade fordringar',
    action: 'sumPositiveTransactions',
  },
  {
    rowId: '11',
    ranges: [[1347]],
    cellLabel: 'Årets nedskrivningar',
    action: 'sumNegativeTransactions',
  },
];

export const getNote21 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null
): NoteOneTableSection => {
  return {
    active: false,
    number: value(undefined),
    name: value(
      'Fordringar hos övriga företag som det finns ett ägarintresse i'
    ),
    table: table<IxbrlCell>(
      'notes.note21.table',
      { id: 'label', label: '' },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      .addRows((rows) =>
        rows
          // 'ingående' = previous period outgoing balance
          .addRow(
            '1',
            noteLabel('Ingående anskaffningsvärden'),
            ixbrlCell(ref(sumAccountsIBs('1346+1673:1679', false)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:FordringarOvrigaForetagAgarintresseAnskaffningsvarden',
              contextRef: 'balans1',
              saldo: 'debit',
            }),
            ixbrlCell(
              ref(sumAccountsIBs('1346+1673:1679', false, 'previousYear')),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:FordringarOvrigaForetagAgarintresseAnskaffningsvarden',
                contextRef: 'balans2',
                saldo: 'debit',
              }
            )
          )
          .addRow(
            '2',
            noteLabel('Tillkommande fordringar'),
            ixbrlCell(ref(sumPositiveTransactions('1346+1673:1679')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:FordringarOvrigaForetagAgarintresseForandringAnskaffningsvardenTillkommandeFordringar',
              contextRef: 'period0',
              standardRubrik:
                'Ökning av anskaffningsvärden i samband med periodens tillkommande långfristiga fordringar hos övriga företag som det finns ägarintresse i',
            }),
            ixbrlCell(
              ref(sumPositiveTransactions('1346+1673:1679', 'previousYear')),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:FordringarOvrigaForetagAgarintresseForandringAnskaffningsvardenTillkommandeFordringar',
                contextRef: 'period1',
                standardRubrik:
                  'Ökning av anskaffningsvärden i samband med periodens tillkommande långfristiga fordringar hos övriga företag som det finns ägarintresse i',
              }
            )
          )
          .addRow(
            'anskaffningsvardenFusion',
            noteLabel('Fusion'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:FordringarOvrigaForetagAgarintresseForandringAnskaffningsvardenFusion',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden för långfristiga fordringar hos övriga företag som det finns ägarintresse i genom fusion',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:FordringarOvrigaForetagAgarintresseForandringAnskaffningsvardenFusion',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden för långfristiga fordringar hos övriga företag som det finns ägarintresse i genom fusion',
            })
          )
          .addRow(
            '3',
            noteLabel('Reglerade fordringar'),
            ixbrlCell(ref(sumNegativeTransactions('1346+1673:1679')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:FordringarOvrigaForetagAgarintresseForandringAnskaffningsvardenRegleradeFordringar',
              contextRef: 'period0',
              standardRubrik:
                'Minskning av anskaffningsvärden i samband med periodens reglerade långfristiga fordringar hos övriga företag som det finns ägarintresse i',
              negateValue: true,
            }),
            ixbrlCell(
              ref(sumNegativeTransactions('1346+1673:1679', 'previousYear')),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:FordringarOvrigaForetagAgarintresseForandringAnskaffningsvardenRegleradeFordringar',
                contextRef: 'period1',
                standardRubrik:
                  'Minskning av anskaffningsvärden i samband med periodens reglerade långfristiga fordringar hos övriga företag som det finns ägarintresse i',
                negateValue: true,
              }
            )
          )
          .addRow(
            'anskaffningsvardenWrittenReceivables',
            noteLabel('Bortskrivna fordringar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:FordringarOvrigaForetagAgarintresseForandringAnskaffningsvardenBortskrivnafordringar',
              contextRef: 'period0',
              standardRubrik:
                'Minskning av anskaffningsvärden i samband med periodens bortskrivning av långfristiga fordringar hos övriga företag som det finns ägarintresse i',
              negateValue: true,
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:FordringarOvrigaForetagAgarintresseForandringAnskaffningsvardenBortskrivnafordringar',
              contextRef: 'period1',
              standardRubrik:
                'Minskning av anskaffningsvärden i samband med periodens bortskrivning av långfristiga fordringar hos övriga företag som det finns ägarintresse i',
              negateValue: true,
            })
          )
          .addRow(
            '4',
            noteLabel('Omklassificeringar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:FordringarOvrigaForetagAgarintresseForandringAnskaffningsvardenOmklassificeringar',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden i samband med omklassificeringar av långfristiga fordringar hos övriga företag som det finns ägarintresse i',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:FordringarOvrigaForetagAgarintresseForandringAnskaffningsvardenOmklassificeringar',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden i samband med omklassificeringar av långfristiga fordringar hos övriga företag som det finns ägarintresse i',
            })
          )
          .addRow(
            'anskaffningsvardenConversionDifferences',
            noteLabel('Omräkningsdifferenser'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:FordringarOvrigaForetagAgarintresseForandringAnskaffningsvardenOmrakningsdifferenser',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden i samband med omräkningsdifferenser av långfristiga fordringar hos övriga företag som det finns ägarintresse i',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:FordringarOvrigaForetagAgarintresseForandringAnskaffningsvardenOmrakningsdifferenser',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden i samband med omräkningsdifferenser av långfristiga fordringar hos övriga företag som det finns ägarintresse i',
            })
          )
          .addRow(
            '5',
            noteLabel('Utgående  anskaffningsvärden'),
            ixbrlCell(
              sumNotesRowsById(
                21,
                [
                  ...range(1, 5),
                  'anskaffningsvardenFusion',
                  'anskaffningsvardenWrittenReceivables',
                  'anskaffningsvardenConversionDifferences',
                ],
                'year'
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:FordringarOvrigaForetagAgarintresseAnskaffningsvarden',
                contextRef: 'balans0',
                saldo: 'debit',
              }
            ),
            ixbrlCell(
              sumNotesRowsById(
                21,
                [
                  ...range(1, 5),
                  'anskaffningsvardenFusion',
                  'anskaffningsvardenWrittenReceivables',
                  'anskaffningsvardenConversionDifferences',
                ],
                'previousYear'
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:FordringarOvrigaForetagAgarintresseAnskaffningsvarden',
                contextRef: 'balans1',
                saldo: 'debit',
              }
            )
          )
          .addRow(
            '7',
            noteLabel('Ingående nedskrivningar'),
            ixbrlCell(ref(sumAccountsIBs('1347', false)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:FordringarOvrigaForetagAgarintresseNedskrivningar',
              contextRef: 'balans1',
              saldo: 'credit',
              negateValue: true,
            }),
            ixbrlCell(ref(sumAccountsIBs('1347', false, 'previousYear')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:FordringarOvrigaForetagAgarintresseNedskrivningar',
              contextRef: 'balans2',
              saldo: 'credit',
              negateValue: true,
            })
          )
          .addRow(
            '8',
            noteLabel('Reglerade fordringar'),
            ixbrlCell(ref(sumPositiveTransactions('1347')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:FordringarOvrigaForetagAgarintresseForandringNedskrivningarRegleradeFordringar',
              contextRef: 'period0',
            }),
            ixbrlCell(ref(sumPositiveTransactions('1347', 'previousYear')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:FordringarOvrigaForetagAgarintresseForandringNedskrivningarRegleradeFordringar',
              contextRef: 'period1',
            })
          )
          .addRow(
            'nedskrivningarFusion',
            noteLabel('Fusion'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:FordringarOvrigaForetagAgarintresseForandringNedskrivningarFusion',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av nedskrivningar för långfristiga fordringar hos övriga företag som det finns ägarintresse i genom fusion',
              negateValue: true,
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:FordringarOvrigaForetagAgarintresseForandringNedskrivningarFusion',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av nedskrivningar för långfristiga fordringar hos övriga företag som det finns ägarintresse i genom fusion',
              negateValue: true,
            })
          )
          .addRow(
            '9',
            noteLabel('Återförda nedskrivningar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:FordringarOvrigaForetagAgarintresseForandringNedskrivningarAterfordaNedskrivningar',
              contextRef: 'period0',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:FordringarOvrigaForetagAgarintresseForandringNedskrivningarAterfordaNedskrivningar',
              contextRef: 'period1',
            })
          )
          .addRow(
            'nedskrivningarWrittenReceivables',
            noteLabel('Bortskrivna fordringar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:FordringarOvrigaForetagAgarintresseForandringNedskrivningarBortskrivningfordringar',
              contextRef: 'period0',
              standardRubrik:
                'Minskning av nedskrivningar i samband med bortskrivning av långfristiga fordringar hos övriga företag som det finns ägarintresse i',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:FordringarOvrigaForetagAgarintresseForandringNedskrivningarBortskrivningfordringar',
              contextRef: 'period1',
              standardRubrik:
                'Minskning av nedskrivningar i samband med bortskrivning av långfristiga fordringar hos övriga företag som det finns ägarintresse i',
            })
          )
          .addRow(
            '10',
            noteLabel('Omklassificeringar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:FordringarOvrigaForetagAgarintresseForandringNedskrivningarOmklassificeringar',
              contextRef: 'period0',
              standardRubrik:
                'Minskning (ökning) av nedskrivningar i samband med omklassificeringar av långfristiga fordringar hos övriga företag som det finns ägarintresse i',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:FordringarOvrigaForetagAgarintresseForandringNedskrivningarOmklassificeringar',
              contextRef: 'period1',
              standardRubrik:
                'Minskning (ökning) av nedskrivningar i samband med omklassificeringar av långfristiga fordringar hos övriga företag som det finns ägarintresse i',
            })
          )
          .addRow(
            '11',
            noteLabel('Årets nedskrivningar'),
            ixbrlCell(ref(sumNegativeTransactions('1347')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:FordringarOvrigaForetagAgarintresseForandringNedskrivningarAretsNedskrivningar',
              contextRef: 'period0',
              negateValue: true,
            }),
            ixbrlCell(ref(sumNegativeTransactions('1347', 'previousYear')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:FordringarOvrigaForetagAgarintresseForandringNedskrivningarAretsNedskrivningar',
              contextRef: 'period1',
              negateValue: true,
            })
          )
          .addRow(
            'nedskrivningarConversionDifferences',
            noteLabel('Omräkningsdifferenser'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:FordringarOvrigaForetagAgarintresseForandringNedskrivningarOmrakningsdifferenser',
              contextRef: 'period0',
              standardRubrik:
                'Minskning (ökning) av nedskrivningar i samband med omräkningsdifferenser av långfristiga fordringar hos övriga företag som det finns ägarintresse i',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:FordringarOvrigaForetagAgarintresseForandringNedskrivningarOmrakningsdifferenser',
              contextRef: 'period1',
              standardRubrik:
                'Minskning (ökning) av nedskrivningar i samband med omräkningsdifferenser av långfristiga fordringar hos övriga företag som det finns ägarintresse i',
            })
          )
          .addRow(
            '12',
            noteLabel('Utgående  nedskrivningar'),
            ixbrlCell(
              sumNotesRowsById(
                21,
                [
                  ...range(7, 12),
                  'nedskrivningarFusion',
                  'nedskrivningarWrittenReceivables',
                  'nedskrivningarConversionDifferences',
                ],
                'year'
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:FordringarOvrigaForetagAgarintresseNedskrivningar',
                contextRef: 'balans0',
                saldo: 'credit',
                negateValue: true,
              }
            ),
            ixbrlCell(
              sumNotesRowsById(
                21,
                [
                  ...range(7, 12),
                  'nedskrivningarFusion',
                  'nedskrivningarWrittenReceivables',
                  'nedskrivningarConversionDifferences',
                ],
                'previousYear'
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:FordringarOvrigaForetagAgarintresseNedskrivningar',
                contextRef: 'balans1',
                saldo: 'credit',
                negateValue: true,
              }
            )
          )
          .addRow(
            '13',
            noteLabel('Redovisat värde'),
            ixbrlCell(sumNotesRowsById(21, [5, 12], 'year'), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:FordringarOvrigaForetagAgarintresseLangfristiga',
              contextRef: 'balans0',
              saldo: 'debit',
            }),
            ixbrlCell(sumNotesRowsById(21, [5, 12], 'previousYear'), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:FordringarOvrigaForetagAgarintresseLangfristiga',
              contextRef: 'balans1',
              saldo: 'debit',
            })
          )
          .build()
      )
      .newRowTemplate(value(''), value(undefined), value(undefined))
      .build(),
    commentLabel: field('Kommentar'),
    comment: ixbrlField(field(''), {
      type: 'stringItemType',
      name: 'se-gen-base:NotFordringarOvrigaForetagAgarintresseKommentar',
      contextRef: 'period0',
    }),
    group: value(undefined),
    section: value(undefined),
    validForReportType: {
      k2: true,
      k3: true,
    },
  };
};

import React from 'react';
import { Settings } from '@agoy/annual-report-document';

const SettingsContext = React.createContext<Settings | undefined>(undefined);

interface SettingsContextProviderProps {
  settings: Settings;
  children: JSX.Element | JSX.Element[];
}

export const SettingsContextProvider = ({
  settings,
  children,
}: SettingsContextProviderProps): JSX.Element => (
  <SettingsContext.Provider value={settings}>
    {children}
  </SettingsContext.Provider>
);

export default SettingsContext;

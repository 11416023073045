import React from 'react';
import styled from '@emotion/styled';
import { Box } from '@material-ui/core';
import Typography from '../../UI/Typography/Typography';
import {
  ConfirmationCertificate,
  Settings,
} from '@agoy/annual-report-document';

import { useIntl } from 'react-intl';
import { stringValue } from '@agoy/document';

const Content = styled.div`
  padding: 0 2rem;
  text-align: left;

  p {
    margin-bottom: 1rem;
  }
`;

interface ConfirmationCertificatePreviewProps {
  confirmationCertificate: ConfirmationCertificate;
  settings: Settings;
}

const ConfirmationCertificateManualSubmission = ({
  confirmationCertificate,
  settings,
}: ConfirmationCertificatePreviewProps): JSX.Element => {
  const { formatMessage } = useIntl();

  const currentFinancialYear = stringValue(settings.section.period);

  const {
    section: {
      dsRole,
      statement,
      location,
      date,
      signatureClarification,
      dsSignatureClarificationSurname,
      heading,
      clientNameText,
      organisationNumberText,
    },
  } = confirmationCertificate;

  const title =
    stringValue(heading) ||
    formatMessage({
      id: 'annualReport.menu.confirmationCertificate',
    });

  const clientName =
    stringValue(clientNameText) ||
    `${stringValue(settings.section.clientName)} (${stringValue(
      settings.section.organisationNumber
    )})`;

  const organisationNumber =
    stringValue(organisationNumberText) ||
    `${formatMessage({
      id: 'financialyear',
    })} ${currentFinancialYear}`;

  return (
    <Content>
      <Typography variant="h1">{title}</Typography>

      <Typography>{clientName}</Typography>
      <Typography>{organisationNumber}</Typography>

      {/* Statement, manual submission */}
      <Box mt="4rem" mb="8rem" whiteSpace="break-spaces">
        <Typography>{`${stringValue(statement)}`}</Typography>
      </Box>

      {/* Location & Date */}
      <Box mb="4rem">
        <Typography>
          {stringValue(location)
            ? `${stringValue(location)}, ${stringValue(date)}`
            : `${stringValue(date) || ''}`}
        </Typography>
      </Box>

      <Box mb="2rem">
        <Typography>
          {stringValue(signatureClarification)}{' '}
          {stringValue(dsSignatureClarificationSurname)}, {stringValue(dsRole)}
        </Typography>
      </Box>
    </Content>
  );
};

export default ConfirmationCertificateManualSubmission;

import { FinancialYear } from '@agoy/api-sdk-core';
import {
  SET_ACCOUNT_LIST_DRAWER_EXPANDED,
  SET_ACCOUNT_LIST_DRAWER_MODE,
  ACCOUNT_LIST_DRAWER_ADD_ACCOUNT,
  ACCOUNT_LIST_DRAWER_REMOVE_ACCOUNT,
  ACCOUNT_LIST_DRAWER_CLEAR_SELECTION,
  ACCOUNT_LIST_DRAWER_SET_ACCOUNTS,
  ACCOUNT_LIST_DRAWER_SET_CURRENT_ACCOUNT,
  ACCOUNT_LIST_DRAWER_SET_ACCOUNT_DESELECTED,
} from './action-types';
import { AccountListDrawerActions } from './actions';

export type AccountListDrawerMode =
  | DrawerModeViewing
  | DrawerModeSelectingAccounts
  | DrawerModeTogglingAccounts;

type DrawerModeViewing = 'viewing';
type DrawerModeSelectingAccounts = 'selecting-accounts';
type DrawerModeTogglingAccounts = 'toggling-accounts';

interface AccountListDrawerState {
  expanded: boolean;
  mode: AccountListDrawerMode;
  currentAccount?: string;
  selectedAccounts: string[];
  selectionOwner: string;
  financialYear?: FinancialYear;
  deselectedAccount?: string;
}

const initialAccountListDrawerState: AccountListDrawerState = {
  expanded: false,
  mode: 'viewing',
  selectedAccounts: [],
  selectionOwner: '',
};

export default (
  state: AccountListDrawerState = initialAccountListDrawerState,
  action: AccountListDrawerActions
): AccountListDrawerState => {
  switch (action.type) {
    // Global actions
    case SET_ACCOUNT_LIST_DRAWER_EXPANDED:
      return {
        ...state,
        expanded: action.expanded,
      };
    case SET_ACCOUNT_LIST_DRAWER_MODE:
      return {
        ...state,
        mode: action.mode,
        financialYear: action.financialYear,
        selectionOwner:
          action.mode === 'selecting-accounts' ||
          action.mode === 'toggling-accounts'
            ? action.selectionOwner
            : '',
      };
    case ACCOUNT_LIST_DRAWER_ADD_ACCOUNT:
      return {
        ...state,
        selectedAccounts: [
          ...new Set([...state.selectedAccounts]).add(action.account),
        ],
      };
    case ACCOUNT_LIST_DRAWER_SET_ACCOUNTS:
      return {
        ...state,
        selectedAccounts: action.accounts,
      };
    case ACCOUNT_LIST_DRAWER_REMOVE_ACCOUNT:
      return {
        ...state,
        selectedAccounts: state.selectedAccounts.filter(
          (account) => account !== action.account
        ),
      };
    case ACCOUNT_LIST_DRAWER_CLEAR_SELECTION:
      return {
        ...state,
        selectedAccounts: [],
      };
    case ACCOUNT_LIST_DRAWER_SET_CURRENT_ACCOUNT:
      return {
        ...state,
        currentAccount: action.account,
      };
    case ACCOUNT_LIST_DRAWER_SET_ACCOUNT_DESELECTED:
      return {
        ...state,
        deselectedAccount: action.deselectedAccount,
      };
    default:
      return state;
  }
};

import { AgoyDocumentPart, label, table, value } from '@agoy/document';
import { AnnualReportType } from '../../../document';
import {
  field,
  inactiveOnReportTypeK2,
  ixbrlCell,
  ixbrlField,
  tupleRef,
} from '../../../common/utils/util';
import { IxbrlCell } from '../../../common/types/ixbrl';

const getSharesCompanyNote1 = (
  reportType: AnnualReportType,
  isDigitalSubmission: boolean,
  documentTypeVersion = '1'
): AgoyDocumentPart<typeof note1ContentDefinition> => {
  const noteLabel = (name: string) =>
    isDigitalSubmission ? label(name) : value(name);

  return {
    active: true,
    number: value(1),
    name: ixbrlField(
      field(
        reportType === 'k3'
          ? 'Redovisnings- och värderingsprinciper'
          : 'Redovisningsprinciper'
      ),
      {
        type: 'stringItemType',
        name: 'se-gen-base:RedovisningsVarderingsprinciper',
        contextRef: 'period0',
      }
    ),
    disclaimerK2: field(
      'Årsredovisningen är upprättad i enlighet med årsredovisningslagen och bokföringsnämndens allmänna råd (BFNAR2016:10) om årsredovisning i mindre företag.'
    ),
    disclaimerK3: field(
      'Årsredovisningen har upprättats i enlighet med årsredovisningslagen och Bokföringsnämndens allmänna råd BFNAR 2012:1 Årsredovisning och koncernredovisning (K3).'
    ),
    tangibleFixedAssetsLabel: field('Materiella anläggningstillgångar'),
    tangibleFixedAssetsGeneral: ixbrlField(
      field(
        'Materiella anläggningstillgångar redovisas till anskaffningsvärde med avdrag för ackumulerade avskrivningar och eventuella nedskrivningar. Tillgångarna skrivs av linjärt över tillgångarnas bedömda nyttjandeperiod förutom mark som inte skrivs av. Nyttjandeperioden omprövas per varje balansdag. Följande nyttjandeperioder tillämpas:'
      ),
      {
        type: 'stringItemType',
        name: 'se-gen-base:RedovisningsprinciperMateriellaAnlaggningstillgangar',
        contextRef: 'period0',
      }
    ),
    tangibleFixedAssetsCaption: ixbrlField(field('Avskrivning'), {
      type: 'stringItemType',
      name: 'se-gen-base:RedovisningsprinciperAvskrivningMateriellaAnlaggningstillgangar',
      contextRef: 'period0',
    }),
    tangibleFixedAssets: table<IxbrlCell>(
      'notes.note1.tangibleFixedAssets',
      'appliedDeprecationPeriods',
      'procent',
      'numberOfYears'
    )
      .addRows((rows) => {
        return [
          {
            id: '1',
            label: 'Byggnader',
            ixTupleName:
              'se-gaap-ext:AvskrivningsprincipMateriellaAnlaggningstillgangarByggnaderTuple',
          },
          {
            id: '2',
            label: 'Maskiner och andra tekniska anläggningar',
            ixTupleName:
              'se-gaap-ext:AvskrivningsprincipMateriellaAnlaggningstillgangarMaskinerAndraTekniskaAnlaggningarTuple',
          },
          {
            id: '3',
            label: 'Inventarier, verktyg och installationer',
            ixTupleName:
              'se-gaap-ext:AvskrivningsprincipMateriellaAnlaggningstillgangarInventarierVerktygInstallationerTuple',
          },
          {
            id: '4',
            label: 'Förbättringsutgifter på annans fastighet',
            ixTupleName:
              'se-gaap-ext:AvskrivningsprincipMateriellaAnlaggningstillgangarForbattringsutgifterAnnansFastighetTuple',
          },
          {
            id: '5',
            label: 'Övriga materiella anläggningstillgångar',
            ixTupleName:
              'se-gaap-ext:AvskrivningsprincipMateriellaAnlaggningstillgangarOvrigaMateriellaAnlaggningstillgangarTuple',
          },
        ]
          .reduce(
            (rows, { id, label, ixTupleName }) =>
              rows.addRow(
                id,
                ixbrlCell(noteLabel(label), {
                  type: 'stringItemType',
                  name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangBenamning',
                  contextRef: 'period0',
                  tuple: {
                    name: ixTupleName,
                    id: `${rows.getBaseId()}.${id}.tuple`,
                    tupleID: `${rows.getBaseId()}.${id}.tuple`,
                  },
                  tupleRef: tupleRef(1, `${rows.getBaseId()}.${id}.tuple`),
                }),
                ixbrlCell(value(''), {
                  type: 'stringItemType',
                  name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangAvskrivningsprocent',
                  contextRef: 'period0',
                  tupleRef: tupleRef(2, `${rows.getBaseId()}.${id}.tuple`),
                }),
                ixbrlCell(value(''), {
                  type: 'stringItemType',
                  name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangNyttjandeperiod',
                  contextRef: 'period0',
                  tupleRef: tupleRef(3, `${rows.getBaseId()}.${id}.tuple`),
                })
              ),
            rows
          )
          .build();
      })
      .newRowTemplate(value(''), value(''), value(''))
      .build(),
    receivablesLabel: inactiveOnReportTypeK2(field('Fordringar'), reportType),
    receivables: inactiveOnReportTypeK2(
      field(
        'Fordringar har upptagits till de belopp var med de beräknas inflyta.'
      ),
      reportType
    ),
    otherAssetsProvisionsAndLiabilitiesLabel: inactiveOnReportTypeK2(
      field('Övriga tillgångar, avsättningar och skulder'),
      reportType
    ),
    otherAssetsProvisionsAndLiabilities: inactiveOnReportTypeK2(
      field(
        'Övriga tillgångar, avsättningar och skulder har värderats till anskaffningsvärden om inget annat anges nedan.'
      ),
      reportType
    ),
    revenueRecognitionLabel: inactiveOnReportTypeK2(
      field('Intäktsredovisning'),
      reportType
    ),
    revenueRecognition: inactiveOnReportTypeK2(
      field(
        'Inkomsten redovisas till det verkliga värdet av vad som erhållits eller kommer att erhållas. Företaget redovisar därför inkomsten till nominellt värde (fakturabelopp) om ersättningen erhålls i likvida medel direkt vid leverans. Avdrag görs för lämnade rabatter.'
      ),
      reportType
    ),
    saleOfGoodsLabel: inactiveOnReportTypeK2(
      field('Varuförsäljning'),
      reportType
    ),
    saleOfGoods: inactiveOnReportTypeK2(
      field(
        'Försäljning av varor redovisas när företaget till köparen har överfört de väsentliga risker och förmåner som är förknippade med ägandet, normalt när kunden har varorna i sin besittning. Intäkter från försäljning av varor som inte har några betydande serviceförpliktelser redovisas vid leverans.'
      ),
      reportType
    ),
    servicesLabel: inactiveOnReportTypeK2(field('Tjänster'), reportType),
    services: inactiveOnReportTypeK2(
      ixbrlField(
        field(
          'Intäkter från konsulttjänster intäktsredovisas när tjänsterna tillhandahålls.'
        ),
        {
          type: 'stringItemType',
          name: 'se-gen-base:RedovisningsprinciperTjansteOchEntreprenaduppdrag',
          contextRef: 'period0',
        }
      ),
      reportType
    ),
    acquisitionGoodsLabel: inactiveOnReportTypeK2(
      field('Anskaffningsvärde för egentillverkade varor'),
      reportType
    ),
    acquisitionGoods: inactiveOnReportTypeK2(
      ixbrlField(field(''), {
        type: 'stringItemType',
        name: 'se-gen-base:RedovisningsprinciperAnskaffningsvardeEgentillverkadevaror',
        contextRef: 'period0',
      }),
      reportType
    ),
    changedPrinciplesLabel: inactiveOnReportTypeK2(
      field('Bristande jämförbarhet vid ändrade principer'),
      reportType
    ),
    changedPrinciples: inactiveOnReportTypeK2(
      ixbrlField(field(''), {
        type: 'stringItemType',
        name: 'se-gen-base:RedovisningsprinciperBristandeJamforbarhetAndradePrinciper',
        contextRef: 'period0',
      }),
      reportType
    ),
    rentsLabel: inactiveOnReportTypeK2(field('Hyror'), reportType),
    rents: inactiveOnReportTypeK2(
      field(
        'Hyresintäkter från uthyrning av företagets lokaler redovisas linjärt över leasingperioden.'
      ),
      reportType
    ),
    interestRoyaltyAndDividensLabel: inactiveOnReportTypeK2(
      field('Ränta, royalty och utdelning'),
      reportType
    ),
    interestRoyaltyAndDividens: inactiveOnReportTypeK2(
      field(
        'Ränta redovisas med tillämpning av effektiv räntemetoden. Royalty periodiseras i enlighet med den aktuella överenskommelsens ekonomiska innebörd. Utdelning redovisas när aktieägarens rätt att få utdelning bedöms som säker.'
      ),
      reportType
    ),
    intangibleAssetsGeneralLabel: inactiveOnReportTypeK2(
      field('Immateriella anläggningstillgångar'),
      reportType
    ),
    tangibleAssetsCommentLabel: field('Kommentar'),
    tangibleAssetsComment: ixbrlField(field(''), {
      type: 'stringItemType',
      name: 'se-gen-base:AvskrivningarMateriellaAnlaggningstillgangarKommentar',
      contextRef: 'period0',
    }),
    intangibleAssetsGeneral: inactiveOnReportTypeK2(
      ixbrlField(
        field(
          'Immateriella anläggningstillgångar redovisas till anskaffningsvärde med avdrag för ackumulerade avskrivningar och eventuella nedskrivningar. Tillgångarna skrivs av linjärt över tillgångarnas bedömda nyttjande period. Nyttjandeperioden omprövas per varje balansdag. Pågående projekt skrivs inte av utan nedskrivningsprövas årligen. Följande nyttjande perioder tillämpas:'
        ),
        {
          type: 'stringItemType',
          name: 'se-gen-base:RedovisningsprinciperImmateriellaAnlaggningstillgangar',
          contextRef: 'period0',
        }
      ),
      reportType
    ),
    intangibleAssetsTableCaption: inactiveOnReportTypeK2(
      ixbrlField(field('Avskrivning'), {
        type: 'stringItemType',
        name: 'se-gen-base:RedovisningsprinciperAvskrivningImmateriellaAnlaggningstillgangar',
        contextRef: 'period0',
      }),
      reportType
    ),
    intangibleAssetsTable: inactiveOnReportTypeK2(
      table<IxbrlCell>(
        'notes.note1.intangibleAssetsTable',
        'empty',
        'percent',
        'numberOfYears'
      )
        .addRows((rows) => {
          return [
            ...(reportType === 'k2'
              ? []
              : [
                  {
                    id: '4',
                    label:
                      'Balanserade utgifter för utvecklingsarbeten och liknande arbeten',
                    ixTupleName:
                      'se-gaap-ext:AvskrivningsprincipImmateriellaAnlaggningstillgangarBalanseradeUtgifterUtvecklingsarbetenLiknandeArbetenTuple',
                  },
                ]),
            {
              id: '1',
              label:
                'Koncessioner, patent, licenser, varumärken samt liknande rättigheter',
              ixTupleName:
                'se-gaap-ext:AvskrivningsprincipImmateriellaAnlaggningstillgangarKoncessionerPatentLicenserVarumarkenLiknandeRattigheterTuple',
            },
            {
              id: '2',
              label: 'Hyresrätter och liknande rättigheter',
              ixTupleName:
                'se-gaap-ext:AvskrivningsprincipImmateriellaAnlaggningstillgangarHyresratterLiknandeRattigheterTuple',
            },
            {
              id: '3',
              label: 'Goodwill',
              ixTupleName:
                'se-gaap-ext:AvskrivningsprincipImmateriellaAnlaggningstillgangarGoodwillTuple',
            },
          ]
            .reduce(
              (rows, { id, label, ixTupleName }) =>
                rows.addRow(
                  id,
                  ixbrlCell(noteLabel(label), {
                    type: 'stringItemType',
                    name: 'se-gen-base:AvskrivningsprincipImmateriellAnlaggningstillgangBenamning',
                    contextRef: 'period0',
                    tuple: {
                      name: ixTupleName,
                      id: `${rows.getBaseId()}.${id}.tuple`,
                      tupleID: `${rows.getBaseId()}.${id}.tuple`,
                    },
                    tupleRef: tupleRef(1, `${rows.getBaseId()}.${id}.tuple`),
                  }),
                  ixbrlCell(value(''), {
                    type: 'stringItemType',
                    name: 'se-gen-base:AvskrivningsprincipImmateriellAnlaggningstillgangAvskrivningsprocent',
                    contextRef: 'period0',
                    tupleRef: tupleRef(2, `${rows.getBaseId()}.${id}.tuple`),
                  }),
                  ixbrlCell(value(''), {
                    type: 'stringItemType',
                    name: 'se-gen-base:AvskrivningsprincipImmateriellAnlaggningstillgangNyttjandeperiod',
                    contextRef: 'period0',
                    tupleRef: tupleRef(3, `${rows.getBaseId()}.${id}.tuple`),
                  })
                ),
              rows
            )
            .build();
        })
        .newRowTemplate(value(''), value(''), value(''))
        .build(),
      reportType
    ),
    intangibleAssetsProjectsClosed: inactiveOnReportTypeK2(
      ixbrlField(field(''), {
        type: 'stringItemType',
        name: 'se-gen-base:AvskrivningarImmateriellaAnlaggningstillgangarKommentar',
        contextRef: 'period0',
      }),
      reportType
    ),
    incomeTaxLabel: inactiveOnReportTypeK2(field('Inkomstskatt'), reportType),
    incomeTaxParagraph1: inactiveOnReportTypeK2(
      field(
        'Det förelåg inget skattepliktigt resultat efter att ej avdragsgilla kostnader och ej skattepliktiga intäkter lagts till bolagets negativa redovisningsmässiga resultat.'
      ),
      reportType
    ),
    incomeTaxParagraph2: inactiveOnReportTypeK2(
      field(
        'Uppskjuten skatt är inkomstskatt för skattepliktigt resultat avseende framtida räkenskapsår till följd av tidigare transaktioner eller händelser. Uppskjuten skatt beräknas på temporära skillnader. En temporär skillnad finns när det redovisade värdet på en tillgång eller skuld skiljer sig från det skattemässiga värdet. Temporära skillnader beaktas ej i skillnader hänförliga till investeringar i dotterföretag, filialer, intresseföretag eller joint venture om företaget kan styra tidpunkten för återföring av de temporära skillnaderna och det inte är uppenbart att den temporära skillnaden kommer att återföras inom en överskådlig framtid. Skillnader som härrör från den första redovisningen av goodwill eller vid den första redovisningen skuld eller tillgång såvida inte den hänförliga transaktionen är ett rörelseförvärv eller påverkar skatt eller redovisat resultat utgör inte heller temporära. Uppskjuten skattefordran avseende underskottsavdrag eller andra framtida skattemässiga avdrag redovisas i den omfattning det är sannolikt att avdragen kan avräknas mot framtida skattemässiga överskott.'
      ),
      reportType
    ),
    incomeTaxParagraph3: inactiveOnReportTypeK2(
      field(
        'Företaget har inga temporära skillnader för utom de som återspeglas i obeskattade reserver.'
      ),
      reportType
    ),
    incomeTaxParagraph4: inactiveOnReportTypeK2(
      field(
        'Uppskjutna skatteskulder som är hänförlig till obeskattade reserver särredovisas inte, obeskattade reserver redovisas med bruttobeloppet i balansräkningen.'
      ),
      reportType
    ),
    incomeTaxParagraph5: inactiveOnReportTypeK2(
      field(
        'För poster som redovisas i resultaträkningen redovisas där med sammanhängande skatt i resultaträkningen. För poster som redovisas direkt mot eget kapital redovisas även skatten direkt mot eget kapital.'
      ),
      reportType
    ),
    accountingPrincipleChangeLabel: field('Byte av redovisningsprincip'),
    accountingPrincipleChange: ixbrlField(field(''), {
      type: 'stringItemType',
      name: 'se-gen-base:RedovisningsprinciperByteRedovisningsprincip',
      contextRef: 'period0',
    }),
    taxLabel: field('Skatter'),
    tax: ixbrlField(field(''), {
      type: 'stringItemType',
      name: 'se-gen-base:RedovisningsprinciperSkatter',
      contextRef: 'period0',
    }),
    leasingLabel: field('Leasing'),
    leasing: ixbrlField(field(''), {
      type: 'stringItemType',
      name: 'se-gen-base:RedovisningsprinciperLeasing',
      contextRef: 'period0',
    }),
    deviationReasonsLabel: field(
      'Skälen för avvikelse om jämförelsesiffror ej räknats om vid byte av principer'
    ),
    deviationReasons: ixbrlField(field(''), {
      type: 'stringItemType',
      name: 'se-gen-base:RedovisningsprinciperUpplysningSkalAvvikelseJamforelsesiffror',
      contextRef: 'period0',
    }),
    notComparablePrincipleChangeLabel: field(
      'Bristande jämförbarhet vid ändrade principer'
    ),
    notComparablePrincipleChange: ixbrlField(field(''), {
      type: 'stringItemType',
      name: 'se-gen-base:RedovisningsprinciperBristandeJamforbarhetAndradePrinciper',
      contextRef: 'period0',
    }),
    generalAdviceDeviationLabel: field(
      'Avvikelse från allmänna råd eller rekommendationer'
    ),
    generalAdviceDeviation: ixbrlField(field(''), {
      type: 'stringItemType',
      name: 'se-gen-base:RedovisningsprinciperAvvikelseAllmannaRadRekommendationer',
      contextRef: 'period0',
    }),
    basicPrinciplesDeviationsLabel: field(
      'Avvikelser från grundläggande principer'
    ),
    basicPrinciplesDeviations: ixbrlField(field(''), {
      type: 'stringItemType',
      name: 'se-gen-base:RedovisningsprinciperAvvikelserGrundlaggandePrinciper',
      contextRef: 'period0',
    }),
    incomeStatementChangeLabel: field('Byte av uppställningsform för RR'),
    incomeStatementChange: ixbrlField(field(''), {
      type: 'stringItemType',
      name: 'se-gen-base:RedovisningsprinciperByteUppstallningsformResultatRakning',
      contextRef: 'period0',
    }),
    firstTimeK3Label: field('Upplysningar första gången K3 tillämpas'),
    firstTimeK3: ixbrlField(field(''), {
      type: 'stringItemType',
      name: 'se-gen-base:RedovisningsprinciperUpplysningForstaTillampningK3',
      contextRef: 'period0',
    }),
    employeeCompensationLabel: field('Ersättningar till anställda'),
    employeeCompensation: ixbrlField(field(''), {
      type: 'stringItemType',
      name: 'se-gen-base:RedovisningsprinciperErsattningAnstallda',
      contextRef: 'period0',
    }),
    publicContributionsLabel: field('Offentliga bidrag'),
    publicContributions: ixbrlField(field(''), {
      type: 'stringItemType',
      name: 'se-gen-base:RedovisningsprinciperOffentligaBidrag',
      contextRef: 'period0',
    }),
    loanExpensesLabel: field('Låneutgifter'),
    loanExpenses: ixbrlField(field(''), {
      type: 'stringItemType',
      name: 'se-gen-base:RedovisningsprinciperLaneutgifter',
      contextRef: 'period0',
    }),
    foreignCurrencyLabel: field('Utländsk valuta'),
    foreignCurrencyGeneral: field(''),
    foreignCurrencyConversionLabel: field('Omräkning till redovisningsvaluta'),
    foreignCurrencyConversion: ixbrlField(field(''), {
      type: 'stringItemType',
      name: 'se-gen-base:RedovisningsprinciperOmrakningRedovisningsvaluta',
      contextRef: 'period0',
    }),
    foreignCurrencyRecalculationLabel: field(
      'Omräkning av filialers tillgångar och skulder'
    ),
    foreignCurrencyRecalculation: ixbrlField(field(''), {
      type: 'stringItemType',
      name: 'se-gen-base:RedovisningsprinciperOmrakningFilialerTillgangarSkulder',
      contextRef: 'period0',
    }),
    k3IncomeLabel: field('Intäkter'),
    k3IncomeGeneral: ixbrlField(field(''), {
      type: 'stringItemType',
      name: 'se-gen-base:RedovisningsprinciperIntakter',
      contextRef: 'period0',
    }),
    k3SaleOfGoodsLabel: field('Försäljning av varor'),
    k3SaleOfGoods: ixbrlField(field(''), {
      type: 'stringItemType',
      name: 'se-gen-base:RedovisningsprinciperForsaljningVaror',
      contextRef: 'period0',
    }),
    k3ServicesLabel: field('Tjänste- och entreprenaduppdrag'),
    k3Services: ixbrlField(
      field(
        '1. Vid försäljning av varor redovisas intäkten vid leverans.\n2. Redovisning av intäkt vid försäljning av varor sker när:\n- Sannolikheten att intäkten kommer att tillfalla företaget är hög.\n- Det är trovärdigt att inkomsten kan beräknas.\n- Överföring av betydande risker och fördelarna som är kopplade med varornas ägande till köparen.\n- Företaget har inte längre ett sådant engagemang i den löpande förvaltningen som vanligtvis förknippas med ägande och utövar inte heller någon reell kontroll över de sålda varorna.\n- Utgifter som finns eller väntas på grund av transaktionen kan räknas ut på ett säkert sätt.'
      ),
      {
        type: 'stringItemType',
        name: 'se-gen-base:RedovisningsprinciperForsaljningVaror',
        contextRef: 'period0',
      }
    ),
    k3CustomerLoyaltyLabel: field('Kundlojalitetsprogram'),
    k3CustomerLoyalty: ixbrlField(field(''), {
      type: 'stringItemType',
      name: 'se-gen-base:RedovisningsprinciperIntakterKundlojalitetsprogram',
      contextRef: 'period0',
    }),
    k3InterestAndDividendLabel: field('Ränta, royalty och utdelning'),
    k3InterestAndDividend: ixbrlField(field(''), {
      type: 'stringItemType',
      name: 'se-gen-base:RedovisningsprinciperIntakterRantaRoyaltyUtdelning',
      contextRef: 'period0',
    }),
    k3WarrantyObligationsLabel: field('Garantiåtaganden'),
    k3WarrantyObligations: ixbrlField(field(''), {
      type: 'stringItemType',
      name: 'se-gen-base:RedovisningsprinciperIntakterGarantiataganden',
      contextRef: 'period0',
    }),
    fixedAssetsWriteDownsLabel: field(
      'Nedskrivningar av anläggningstillgångar'
    ),
    fixedAssetsWriteDowns: ixbrlField(field(''), {
      type: 'stringItemType',
      name: 'se-gen-base:RedovisningsprinciperNedskrivningarAnlaggningstillgangar',
      contextRef: 'period0',
    }),
    companiesSharesLabel: field(
      'Andelar i koncernföretag, intresseföretag, gemensamt styrda företag och företag som det finns ägarintresse i'
    ),
    companiesShares: ixbrlField(field(''), {
      type: 'stringItemType',
      name: 'se-gen-base:RedovisningsprinciperAndelarKoncernforetagIntresseforetagGemensamtStyrdaForetagAgarintressen',
      contextRef: 'period0',
    }),
    groupContributionsLabel: field('Koncernbidrag och aktieägartillskott'),
    groupContributions: ixbrlField(field(''), {
      type: 'stringItemType',
      name: 'se-gen-base:RedovisningsprinciperIntakterKoncernbidragAktieagartillskott',
      contextRef: 'period0',
    }),
    jointVentureLabel: field('Joint venture'),
    jointVenture: ixbrlField(field(''), {
      type: 'stringItemType',
      name: 'se-gen-base:RedovisningsprinciperJointVenture',
      contextRef: 'period0',
    }),
    financialInstrumentsLabel: field('Finansiella instrument'),
    financialInstruments: ixbrlField(field(''), {
      type: 'stringItemType',
      name: 'se-gen-base:RedovisningsprinciperFinansiellaInstrument',
      contextRef: 'period0',
    }),
    collateralAccountingLabel: field('Säkringsredovisning'),
    collateralAccounting: ixbrlField(field(''), {
      type: 'stringItemType',
      name: 'se-gen-base:RedovisningsprinciperSakringsredovisning',
      contextRef: 'period0',
    }),
    inventoryLabel: field('Varulager'),
    inventory: ixbrlField(field(''), {
      type: 'stringItemType',
      name: 'se-gen-base:RedovisningsprinciperVarulager',
      contextRef: 'period0',
    }),
    provisionsLabel: field('Avsättningar'),
    provisions: ixbrlField(field(''), {
      type: 'stringItemType',
      name: 'se-gen-base:RedovisningsprinciperAvsattningar',
      contextRef: 'period0',
    }),
    contingentAssetsAndLiabilitiesLabel: field(
      'Eventualtillgångar och eventualförpliktelser'
    ),
    contingentAssetsAndLiabilities: ixbrlField(field(''), {
      type: 'stringItemType',
      name: 'se-gen-base:RedovisningsprinciperEventualforpliktelser',
      contextRef: 'period0',
    }),
    debtAcquisitionLabel: field('Inkråmsförvärv'),
    debtAcquisition: ixbrlField(field(''), {
      type: 'stringItemType',
      name: 'se-gen-base:RedovisningsprinciperInkramsforvarv',
      contextRef: 'period0',
    }),
    cashflowAnalysisLabel: field('Kassaflödesanalys'),
    cashflowAnalysis: ixbrlField(field(''), {
      type: 'stringItemType',
      name: 'se-gen-base:RedovisningsprinciperKassaflodesanalys',
      contextRef: 'period0',
    }),
    commentLabel: field('Kommentar'),
    comment: field(''),
    group: value(undefined),
    section: value(undefined),
    validForReportType: {
      k2: true,
      k3: true,
    },
  };
};

const cell = { type: 'field' as const };
const boolean = { type: 'boolean' as const };

export const note1ContentDefinition = {
  type: 'part' as const,
  children: {
    active: boolean,
    number: cell,
    name: cell,
    disclaimerK2: cell,
    disclaimerK3: cell,
    tangibleFixedAssetsLabel: cell,
    tangibleFixedAssetsGeneral: cell,
    tangibleFixedAssetsCaption: cell,
    tangibleFixedAssets: { type: 'table' as const },
    receivablesLabel: cell,
    receivables: cell,
    otherAssetsProvisionsAndLiabilitiesLabel: cell,
    otherAssetsProvisionsAndLiabilities: cell,
    revenueRecognitionLabel: cell,
    revenueRecognition: cell,
    saleOfGoodsLabel: cell,
    saleOfGoods: cell,
    servicesLabel: cell,
    services: cell,
    acquisitionGoodsLabel: cell,
    acquisitionGoods: cell,
    changedPrinciplesLabel: cell,
    changedPrinciples: cell,
    rentsLabel: cell,
    rents: cell,
    interestRoyaltyAndDividensLabel: cell,
    interestRoyaltyAndDividens: cell,
    intangibleAssetsGeneralLabel: cell,
    tangibleAssetsCommentLabel: cell,
    tangibleAssetsComment: cell,
    intangibleAssetsGeneral: cell,
    intangibleAssetsTableCaption: cell,
    intangibleAssetsTable: { type: 'table' as const },
    intangibleAssetsProjectsClosed: cell,
    incomeTaxLabel: cell,
    incomeTaxParagraph1: cell,
    incomeTaxParagraph2: cell,
    incomeTaxParagraph3: cell,
    incomeTaxParagraph4: cell,
    incomeTaxParagraph5: cell,
    accountingPrincipleChangeLabel: cell,
    accountingPrincipleChange: cell,
    taxLabel: cell,
    tax: cell,
    leasingLabel: cell,
    leasing: cell,
    deviationReasonsLabel: cell,
    deviationReasons: cell,
    notComparablePrincipleChangeLabel: cell,
    notComparablePrincipleChange: cell,
    generalAdviceDeviationLabel: cell,
    generalAdviceDeviation: cell,
    basicPrinciplesDeviationsLabel: cell,
    basicPrinciplesDeviations: cell,
    incomeStatementChangeLabel: cell,
    incomeStatementChange: cell,
    firstTimeK3Label: cell,
    firstTimeK3: cell,
    employeeCompensationLabel: cell,
    employeeCompensation: cell,
    publicContributionsLabel: cell,
    publicContributions: cell,
    loanExpensesLabel: cell,
    loanExpenses: cell,
    foreignCurrencyLabel: cell,
    foreignCurrencyGeneral: cell,
    foreignCurrencyConversionLabel: cell,
    foreignCurrencyConversion: cell,
    foreignCurrencyRecalculationLabel: cell,
    foreignCurrencyRecalculation: cell,
    k3IncomeLabel: cell,
    k3IncomeGeneral: cell,
    k3SaleOfGoodsLabel: cell,
    k3SaleOfGoods: cell,
    k3ServicesLabel: cell,
    k3Services: cell,
    k3CustomerLoyaltyLabel: cell,
    k3CustomerLoyalty: cell,
    k3InterestAndDividendLabel: cell,
    k3InterestAndDividend: cell,
    k3WarrantyObligationsLabel: cell,
    k3WarrantyObligations: cell,
    fixedAssetsWriteDownsLabel: cell,
    fixedAssetsWriteDowns: cell,
    companiesSharesLabel: cell,
    companiesShares: cell,
    groupContributionsLabel: cell,
    groupContributions: cell,
    jointVentureLabel: cell,
    jointVenture: cell,
    financialInstrumentsLabel: cell,
    financialInstruments: cell,
    collateralAccountingLabel: cell,
    collateralAccounting: cell,
    inventoryLabel: cell,
    inventory: cell,
    provisionsLabel: cell,
    provisions: cell,
    contingentAssetsAndLiabilitiesLabel: cell,
    contingentAssetsAndLiabilities: cell,
    debtAcquisitionLabel: cell,
    debtAcquisition: cell,
    cashflowAnalysisLabel: cell,
    cashflowAnalysis: cell,
    commentLabel: cell,
    comment: cell,
    group: cell,
    section: cell,
    validForReportType: {
      type: 'part' as const,
      children: {
        k2: boolean,
        k3: boolean,
      },
    },
  },
};

export default getSharesCompanyNote1;

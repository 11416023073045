import {
  add,
  differenceInMonths,
  endOfMonth,
  startOfMonth,
  sub,
} from 'date-fns';

import { account, multiply, or, ref, value } from '@agoy/document';
import { FinancialReportStructure } from '../../types';

import { field, table } from '@agoy/annual-report-document';
import { getRandomInt } from './util';
import { parse, format, parseFormat } from '@agoy/dates';

const fieldATX1 = {
  1: 'Här kommer vår periodrapport. Periodrapporten är upprättad av {UserName} ',
  2: 'Här kommer vår periodrapport. Denna periodrapport är upprättad av {UserName}',
  3: 'Nu är vi klara med Periodrapporten. Rapporten är upprättad av {UserName}',
};

const fieldATX2 = {
  1: 'och gäller för {currentDate}',
  2: '. Periodrapporten är upprättad för {currentDate}',
  3: 'och avser {currentDate}',
};

const fieldATX3 = {
  1: 'Tveka inte att kontakta oss om ni har några frågor angående denna periodrapport som ni inte fått besvarade efter att ni läst igenom den.',
  2: 'Hör gärna av er om ni har funderingar angående denna periodrapport. Vi diskuterar mer än gärna dessa siffror med er.',
  3: 'Vi diskuterar gärna dessa siffror med er, så tveka inte att kontakta oss om ni har några frågor.',
};

const fieldBTX1 = {
  1: 'Diagrammet till höger visar totala intäkter och de olika kostnadsposterna för {currentDate}',
  2: 'Diagrammet till höger ger en bild av företagets intäkter samt kostnader för {currentDate}',
  3: 'Diagrammet högst upp till höger visar resultaträkningen uppdelad på kontogrupper för {currentDate}',
};

const fieldBTX2 = {
  1: 'Diagrammet till vänster visar samma poster fast för perioden ',
  2: 'Det vänstra diagrammet visar resultaträkningen för räkenskapsåret så här långt, dvs.',
  3: 'Det nedersta diagrammet ger oss resultaträkningen för perioden',
};

const fieldBTX3 = {
  1: 'Här kan vi exempelvis notera att nettoomsättning för {currentDate} var {currentSales} SEK och att era rörelsekostnader var {currentExpenses} SEK. Er nettoomsättning har {totalSalesSign} med {totalSales} SEK jämfört med samma månad föregående år.',
  2: 'Här kan vi exempelvis notera att nettoomsättning var {currentSales} SEK och att era personalkostnader var {personalExpenses} SEK.',
  3: 'Här kan vi exempelvis notera att nettoomsättning för {currentDate} var {currentSales} SEK, alltså {totalSalesSign} med {totalSales} SEK jämfört med samma månad föregående år.',
};

const fieldBTX4 = {
  1: 'För denna period uppgick er nettoomsättning till {periodSales} SEK, er nettoomsättning har {changingPeriodSalesSign} med {changingPeriodSales} SEK jämfört med samma period för 12 månader sen. Era rörelsekostnader har {changingPeriodExpensesSign} med {changingPeriodExpenses} SEK för samma period.',
  2: 'För denna period uppgick er nettoomsättning till {periodSales} SEK, er nettoomsättning har {changingPeriodSalesSign} med {changingPeriodSales} SEK jämfört med samma period för 12 månader sen. Era rörelsekostnader har {changingPeriodExpensesSign} med {changingPeriodExpenses} SEK för samma period.',
  3: 'För denna period uppgick er nettoomsättning till {periodSales} SEK, er nettoomsättning har {changingPeriodSalesSign} med {changingPeriodSales} SEK jämfört med samma period för 12 månader sen. Era rörelsekostnader har {changingPeriodExpensesSign} med {changingPeriodExpenses} SEK för samma period.',
};

const query1TXA = {
  1: 'Förhållandet mellan nettoomsättningen och totala rörelsekostnader har försämrats väsentligt för perioden',
  2: 'Nettoomsättningen i relation till rörelsekostnader har försämrats väsentligt för perioden',
  3: 'Relationen mellan nettoomsättningen och totala rörelsekostnader har försämrats väsentligt för perioden',
};
const query1TXB =
  'Nettoomsättningen har {SignChangingSales} med {ChangingSales} SEK och rörelsekostnaderna har {SignChangingExpenses} med {ChangingExpenses} SEK. Total förändring uppgår till {DiffSalesExpenses} SEK.';
const query1TXC =
  'En väsentligt försämrad lönsamhet ska alltid noteras, vi diskuterar gärna detta mer er.';

const query2TXA = {
  1: 'Bruttokostnaderna i relation till nettoomsättningen har försämrats väsentligt för perioden',
  2: 'Förhållandet mellan bruttokostnader och nettoomsättningen har försämrats väsentligt för perioden',
  3: 'Bruttokostnaderna som del av nettoomsättningen har försämrats väsentligt för perioden',
};

const query2TXB =
  'Nettoomsättningen har {SignChangingSales} med {ChangingSales} SEK och bruttokostnader har {SignChangingGrossExpenses} med {ChangingGrossExpenses} SEK. Totalt blir det en försämring på {DiffSaleGrosExpenses} SEK.';
const query2TXC =
  'Detta är ett tecken på minskad produktivitet och bör ses över, vi kan gärna hjälpa er med detta om ni önskar.';

const query3TXA = {
  1: 'Personalkostnaderna i relation till nettoomsättningen har försämrats väsentligt för perioden',
  2: 'Förhållandet mellan personalkostnaderna och nettoomsättningen har försämrats väsentligt för perioden',
  3: 'Personalkostnaderna som del av nettoomsättningen har försämrats väsentligt för perioden',
};

const query3TXB =
  'Nettoomsättningen har {SignChangingSales} med {ChangingSales} SEK och personalkostnader har {SignChangingPersonalExpenses} med {ChangingPersonalExpenses} SEK. Totalt blir det en försämring på {DiffSalePersonalExpenses} SEK.';
const query3TXC =
  'Detta är ett tecken på minskad effektivitet och bör ses över, vi kan gärna hjälpa er med detta om ni önskar.';

const query4TXA = {
  1: 'Förhållandet mellan nettoomsättningen och totala rörelsekostnader har förbättrats väsentligt för perioden',
  2: 'Nettoomsättningen i relation till rörelsekostnader har förbättrats väsentligt för perioden',
  3: 'Relationen mellan nettoomsättningen och totala rörelsekostnader har förbättrats väsentligt för perioden',
};

const query4TXB =
  'Nettoomsättningen har {SignChangingSales} med {ChangingSales} SEK och rörelsekostnaderna har {SignChangingExpenses} med {ChangingExpenses} SEK. Total förändring uppgår till {DiffSalesExpenses} SEK.';
const query4TXC = 'En väsentligt förbättrad lönsamhet är alltid positiv.';

const query5TXA = {
  1: 'Bruttokostnaderna i relation till nettoomsättningen har förbättrats väsentligt för perioden',
  2: 'Förhållandet mellan bruttokostnader och nettoomsättningen har förbättrats väsentligt för perioden',
  3: 'Bruttokostnaderna som del av nettoomsättningen har förbättrats väsentligt för perioden',
};

const query5TXB =
  'Nettoomsättningen har {SignChangingSales} med {ChangingSales} SEK och bruttokostnader har {SignChangingGrossExpenses} med {ChangingGrossExpenses} SEK. Totalt blir det en förbättring med {DiffSaleGrosExpenses} SEK.';
const query5TXC = 'Detta är ett tecken på förbättrad produktivitet.';

const query6TXA = {
  1: 'Personalkostnaderna i relation till nettoomsättningen har förbättrats väsentligt för perioden ',
  2: 'Förhållandet mellan personalkostnaderna och nettoomsättningen har förbättrats väsentligt för perioden ',
  3: 'Personalkostnaderna som del av nettoomsättningen har förbättrats väsentligt för perioden',
};

const query6TXB =
  'Nettoomsättningen har {SignChangingSales} med {ChangingSales} SEK och personalkostnader har {SignChangingPersonalExpenses} med {ChangingPersonalExpenses} SEK. Totalt blir det en förbättring med {DiffSalePersonalExpenses} SEK.';
const query6TXC =
  'Detta är ett tecken ökad effektivitet. D.v.s att ni genererar fler kronor i intäkter för varje krona ni lägger på löner. ';
export const diagramRRBRConfig = (
  userName: string,
  period: string,
  yearStart: string,
  yearEnd: string
): FinancialReportStructure['diagramRRBR'] => {
  const textANumber = getRandomInt(3);
  const textBNumber = getRandomInt(3);

  const query1Number = getRandomInt(3);
  const query2Number = getRandomInt(3);
  const query3Number = getRandomInt(3);
  const query4Number = getRandomInt(3);
  const query5Number = getRandomInt(3);
  const query6Number = getRandomInt(3);

  const periodEnd = parseFormat(period, 'MMM-yy').toLowerCase();
  const reportPeriod = parse(period, 'yyyy-MM-dd');

  let currentYearStart = startOfMonth(parse(yearStart, 'yyyy-MM-dd'));
  const currentYearEnd = endOfMonth(parse(yearEnd, 'yyyy-MM-dd'));
  // calculate start year in case more then 12 month
  if (differenceInMonths(reportPeriod, currentYearStart) >= 12) {
    currentYearStart = startOfMonth(sub(currentYearEnd, { months: 11 }));
  }

  const periodStart = parseFormat(currentYearStart, 'MMM-yy').toLowerCase();

  const previousYearStart = sub(currentYearStart, { years: 1 });
  const previousPeriods = Array.from(Array(12).keys()).map((item) => {
    const period = add(previousYearStart, { months: item });
    return { period: format(period, 'MMMyy'), label: format(period, 'yyy-MM') };
  });
  const currentPeriods = Array.from(Array(12).keys()).map((item) => {
    const period = add(currentYearStart, { months: item });
    return { period: format(period, 'MMMyy'), label: format(period, 'yyy-MM') };
  });
  const previousMonth = sub(parse(period, 'yyyy-MM-dd'), {
    years: 1,
  });
  const previousPeriodIdx = format(previousMonth, 'MMMyy');
  const currentPeriodIdx = format(parse(period, 'yyyy-MM-dd'), 'MMMyy');
  const previousPeriod = `${parseFormat(
    previousYearStart,
    'MMM-yy'
  ).toLowerCase()} till ${parseFormat(previousMonth, 'MMM-yy').toLowerCase()} `;
  const currentPeriod = `${periodStart.toLowerCase()} till ${periodEnd.toLowerCase()}`;
  return {
    section: {
      active: true,
      userName: field(userName),
      title: field('Resultat- & Balansräkning Diagram'),
      secondaryText: field('Översikt intäkter och kostnader'),
      note1Static: field(`${fieldATX1[1]} ${fieldATX2[1]}. ${fieldATX3[1]}`),
      note2Static: field(
        `${fieldBTX1[1]}. ${fieldBTX3[1]} ${fieldBTX2[1]} ${periodStart} till ${periodEnd}. ${fieldBTX4[1]}`
      ),
      charter1Static: field(
        `Nedanstående diagram visar en gruppering av resultaträkningen och avser perioden ${periodStart} till ${periodEnd}`
      ),
      charter2Static: field(
        `Nedanstående diagram visar en gruppering av resultaträkningen för ${periodEnd}`
      ),
      note1: field(
        `${fieldATX1[textANumber]} ${fieldATX2[textANumber]}. ${fieldATX3[textANumber]}`
      ),
      note2: field(
        `${fieldBTX1[textBNumber]}. ${fieldBTX3[textBNumber]} ${fieldBTX2[textBNumber]} ${periodStart} till ${periodEnd}. ${fieldBTX4[textBNumber]}`
      ),
      note3: field(`$note3_Reset_State$`),
      query1: field(
        `${query1TXA[query1Number]} ${previousPeriod} och för perioden ${currentPeriod}. ${query1TXB}  ${query1TXC} `
      ),
      query2: field(
        `${query2TXA[query2Number]} ${previousPeriod}  och för perioden ${currentPeriod}. ${query2TXB}  ${query2TXC} `
      ),
      query3: field(
        `${query3TXA[query3Number]} ${previousPeriod}  och för perioden ${currentPeriod}. ${query3TXB}  ${query3TXC} `
      ),
      query4: field(
        `${query4TXA[query4Number]} ${previousPeriod}  och för perioden ${currentPeriod}. ${query4TXB}  ${query4TXC} `
      ),
      query5: field(
        `${query5TXA[query5Number]} ${previousPeriod}  och för perioden ${currentPeriod}. ${query5TXB}  ${query5TXC} `
      ),
      query6: field(
        `${query6TXA[query6Number]} ${previousPeriod} och för perioden ${currentPeriod}. ${query6TXB}  ${query6TXC} `
      ),
      charter1: field(
        `Nedanstående diagram visar en gruppering av resultaträkningen och avser perioden ${periodStart} till ${periodEnd}`
      ),
      charter2: field(
        `Nedanstående diagram visar en gruppering av resultaträkningen för {periodCompare}`
      ),
      resultPrevious: table(
        'tableRRBR.section.table.resultPrevious',
        { id: 'nameLabel', label: 'Resultaträkning' },
        { id: previousPeriods[0].period, label: previousPeriods[0].label },
        { id: previousPeriods[1].period, label: previousPeriods[1].label },
        { id: previousPeriods[2].period, label: previousPeriods[2].label },
        { id: previousPeriods[3].period, label: previousPeriods[3].label },
        { id: previousPeriods[4].period, label: previousPeriods[4].label },
        { id: previousPeriods[5].period, label: previousPeriods[5].label },
        { id: previousPeriods[6].period, label: previousPeriods[6].label },
        { id: previousPeriods[7].period, label: previousPeriods[7].label },
        { id: previousPeriods[8].period, label: previousPeriods[8].label },
        { id: previousPeriods[9].period, label: previousPeriods[9].label },
        { id: previousPeriods[10].period, label: previousPeriods[10].label },
        { id: previousPeriods[11].period, label: previousPeriods[11].label }
      )
        .addRows((rows) => {
          const nettoCells = previousPeriods.map((previousPeriods) => {
            return ref(
              multiply(
                or(account('3000:3799', 'change', previousPeriods.period), 0),
                -1
              )
            );
          });
          rows.addRow(
            'nettos',
            value('Nettoomsättning'),
            nettoCells[0],
            nettoCells[1],
            nettoCells[2],
            nettoCells[3],
            nettoCells[4],
            nettoCells[5],
            nettoCells[6],
            nettoCells[7],
            nettoCells[8],
            nettoCells[9],
            nettoCells[10],
            nettoCells[11]
          );
          const revenueCells = previousPeriods.map((previousPeriods) => {
            return ref(
              multiply(
                or(account('3800:3999', 'change', previousPeriods.period), 0),
                -1
              )
            );
          });
          rows.addRow(
            'nettoaccounts',
            value('Övriga rörelseintäkter'),
            revenueCells[0],
            revenueCells[1],
            revenueCells[2],
            revenueCells[3],
            revenueCells[4],
            revenueCells[5],
            revenueCells[6],
            revenueCells[7],
            revenueCells[8],
            revenueCells[9],
            revenueCells[10],
            revenueCells[11]
          );
          const bruttokostnaderCells = previousPeriods.map(
            (previousPeriods) => {
              return ref(
                or(account('4000:4999', 'change', previousPeriods.period), 0)
              );
            }
          );
          rows.addRow(
            'bruttokostnader',
            value('Bruttokostnader'),
            bruttokostnaderCells[0],
            bruttokostnaderCells[1],
            bruttokostnaderCells[2],
            bruttokostnaderCells[3],
            bruttokostnaderCells[4],
            bruttokostnaderCells[5],
            bruttokostnaderCells[6],
            bruttokostnaderCells[7],
            bruttokostnaderCells[8],
            bruttokostnaderCells[9],
            bruttokostnaderCells[10],
            bruttokostnaderCells[11]
          );
          const ovrigaCells = previousPeriods.map((previousPeriods) => {
            return ref(
              or(account('5000:6999', 'change', previousPeriods.period), 0)
            );
          });
          rows.addRow(
            'ovrigaKostnader',
            value('Övriga kostnader'),
            ovrigaCells[0],
            ovrigaCells[1],
            ovrigaCells[2],
            ovrigaCells[3],
            ovrigaCells[4],
            ovrigaCells[5],
            ovrigaCells[6],
            ovrigaCells[7],
            ovrigaCells[8],
            ovrigaCells[9],
            ovrigaCells[10],
            ovrigaCells[11]
          );
          const personalCells = previousPeriods.map((previousPeriods) => {
            return ref(
              or(account('7000:7699', 'change', previousPeriods.period), 0)
            );
          });
          rows.addRow(
            'personalkostnader',
            value('Personalkostnader'),
            personalCells[0],
            personalCells[1],
            personalCells[2],
            personalCells[3],
            personalCells[4],
            personalCells[5],
            personalCells[6],
            personalCells[7],
            personalCells[8],
            personalCells[9],
            personalCells[10],
            personalCells[11]
          );
          const avskrivningarCells = previousPeriods.map((previousPeriods) => {
            return ref(
              or(account('7700:7899', 'change', previousPeriods.period), 0)
            );
          });
          rows.addRow(
            'avskrivningar',
            value('Avskrivningar etc.'),
            avskrivningarCells[0],
            avskrivningarCells[1],
            avskrivningarCells[2],
            avskrivningarCells[3],
            avskrivningarCells[4],
            avskrivningarCells[5],
            avskrivningarCells[6],
            avskrivningarCells[7],
            avskrivningarCells[8],
            avskrivningarCells[9],
            avskrivningarCells[10],
            avskrivningarCells[11]
          );
          const reaForlustCells = previousPeriods.map((previousPeriods) => {
            return ref(
              or(account('7900:7999', 'change', previousPeriods.period), 0)
            );
          });
          rows.addRow(
            'reaForlust',
            value('REA-förlust etc'),
            reaForlustCells[0],
            reaForlustCells[1],
            reaForlustCells[2],
            reaForlustCells[3],
            reaForlustCells[4],
            reaForlustCells[5],
            reaForlustCells[6],
            reaForlustCells[7],
            reaForlustCells[8],
            reaForlustCells[9],
            reaForlustCells[10],
            reaForlustCells[11]
          );
          return rows.build();
        })
        .build(),
      resultCurrent: table(
        'tableRRBR.section.table.resultCurrent',
        { id: 'nameLabel', label: 'Resultaträkning' },
        { id: currentPeriods[0].period, label: currentPeriods[0].label },
        { id: currentPeriods[1].period, label: currentPeriods[1].label },
        { id: currentPeriods[2].period, label: currentPeriods[2].label },
        { id: currentPeriods[3].period, label: currentPeriods[3].label },
        { id: currentPeriods[4].period, label: currentPeriods[4].label },
        { id: currentPeriods[5].period, label: currentPeriods[5].label },
        { id: currentPeriods[6].period, label: currentPeriods[6].label },
        { id: currentPeriods[7].period, label: currentPeriods[7].label },
        { id: currentPeriods[8].period, label: currentPeriods[8].label },
        { id: currentPeriods[9].period, label: currentPeriods[9].label },
        { id: currentPeriods[10].period, label: currentPeriods[10].label },
        { id: currentPeriods[11].period, label: currentPeriods[11].label }
      )
        .addRows((rows) => {
          const nettoCells = currentPeriods.map((currentPeriods) => {
            return ref(
              multiply(
                or(account('3000:3799', 'change', currentPeriods.period), 0),
                -1
              )
            );
          });
          rows.addRow(
            'nettos',
            value('Nettoomsättning'),
            nettoCells[0],
            nettoCells[1],
            nettoCells[2],
            nettoCells[3],
            nettoCells[4],
            nettoCells[5],
            nettoCells[6],
            nettoCells[7],
            nettoCells[8],
            nettoCells[9],
            nettoCells[10],
            nettoCells[11]
          );
          const revenueCells = currentPeriods.map((currentPeriods) => {
            return ref(
              multiply(
                or(account('3800:3999', 'change', currentPeriods.period), 0),
                -1
              )
            );
          });
          rows.addRow(
            'nettoaccounts',
            value('Övriga rörelseintäkter'),
            revenueCells[0],
            revenueCells[1],
            revenueCells[2],
            revenueCells[3],
            revenueCells[4],
            revenueCells[5],
            revenueCells[6],
            revenueCells[7],
            revenueCells[8],
            revenueCells[9],
            revenueCells[10],
            revenueCells[11]
          );
          const bruttokostnaderCells = currentPeriods.map((currentPeriods) => {
            return ref(
              or(account('4000:4999', 'change', currentPeriods.period), 0)
            );
          });
          rows.addRow(
            'bruttokostnader',
            value('Bruttokostnader'),
            bruttokostnaderCells[0],
            bruttokostnaderCells[1],
            bruttokostnaderCells[2],
            bruttokostnaderCells[3],
            bruttokostnaderCells[4],
            bruttokostnaderCells[5],
            bruttokostnaderCells[6],
            bruttokostnaderCells[7],
            bruttokostnaderCells[8],
            bruttokostnaderCells[9],
            bruttokostnaderCells[10],
            bruttokostnaderCells[11]
          );
          const ovrigaCells = currentPeriods.map((currentPeriods) => {
            return ref(
              or(account('5000:6999', 'change', currentPeriods.period), 0)
            );
          });
          rows.addRow(
            'ovrigaKostnader',
            value('Övriga kostnader'),
            ovrigaCells[0],
            ovrigaCells[1],
            ovrigaCells[2],
            ovrigaCells[3],
            ovrigaCells[4],
            ovrigaCells[5],
            ovrigaCells[6],
            ovrigaCells[7],
            ovrigaCells[8],
            ovrigaCells[9],
            ovrigaCells[10],
            ovrigaCells[11]
          );
          const personalCells = currentPeriods.map((currentPeriods) => {
            return ref(
              or(account('7000:7699', 'change', currentPeriods.period), 0)
            );
          });
          rows.addRow(
            'personalkostnader',
            value('Personalkostnader'),
            personalCells[0],
            personalCells[1],
            personalCells[2],
            personalCells[3],
            personalCells[4],
            personalCells[5],
            personalCells[6],
            personalCells[7],
            personalCells[8],
            personalCells[9],
            personalCells[10],
            personalCells[11]
          );
          const avskrivningarCells = currentPeriods.map((currentPeriods) => {
            return ref(
              or(account('7700:7899', 'change', currentPeriods.period), 0)
            );
          });
          rows.addRow(
            'avskrivningar',
            value('Avskrivningar etc.'),
            avskrivningarCells[0],
            avskrivningarCells[1],
            avskrivningarCells[2],
            avskrivningarCells[3],
            avskrivningarCells[4],
            avskrivningarCells[5],
            avskrivningarCells[6],
            avskrivningarCells[7],
            avskrivningarCells[8],
            avskrivningarCells[9],
            avskrivningarCells[10],
            avskrivningarCells[11]
          );
          const reaForlustCells = currentPeriods.map((currentPeriods) => {
            return ref(
              or(account('7900:7999', 'change', currentPeriods.period), 0)
            );
          });
          rows.addRow(
            'reaForlust',
            value('REA-förlust etc'),
            reaForlustCells[0],
            reaForlustCells[1],
            reaForlustCells[2],
            reaForlustCells[3],
            reaForlustCells[4],
            reaForlustCells[5],
            reaForlustCells[6],
            reaForlustCells[7],
            reaForlustCells[8],
            reaForlustCells[9],
            reaForlustCells[10],
            reaForlustCells[11]
          );
          return rows.build();
        })
        .build(),
      compareMonth: table(
        'tableRRBR.section.table.compareMoth',
        { id: 'nameLabel', label: 'Resultaträkning' },
        { id: 'previousPeriod', label: 'Föregående' },
        { id: 'currentPeriod', label: 'Aktuellt' }
      )
        .addRows((rows) => {
          let previous = ref(
            multiply(
              or(account('3000:3799', 'change', previousPeriodIdx), 0),
              -1
            )
          );
          let current = ref(
            multiply(
              or(account('3000:3799', 'change', currentPeriodIdx), 0),
              -1
            )
          );
          rows.addRow('nettos', value('Nettoomsättning'), previous, current);
          previous = ref(
            multiply(
              or(account('3800:3999', 'change', previousPeriodIdx), 0),
              -1
            )
          );
          current = ref(
            multiply(
              or(account('3800:3999', 'change', currentPeriodIdx), 0),
              -1
            )
          );
          rows.addRow(
            'nettoaccounts',
            value('Övriga rörelseintäkter'),
            previous,
            current
          );
          previous = ref(
            or(account('4000:4999', 'change', previousPeriodIdx), 0)
          );
          current = ref(
            or(account('4000:4999', 'change', currentPeriodIdx), 0)
          );
          rows.addRow(
            'bruttokostnader',
            value('Bruttokostnader'),
            previous,
            current
          );
          previous = ref(
            or(account('5000:6999', 'change', previousPeriodIdx), 0)
          );
          current = ref(
            or(account('5000:6999', 'change', currentPeriodIdx), 0)
          );
          rows.addRow(
            'ovrigaKostnader',
            value('Övriga kostnader'),
            previous,
            current
          );
          previous = ref(
            or(account('7000:7699', 'change', previousPeriodIdx), 0)
          );
          current = ref(
            or(account('7000:7699', 'change', currentPeriodIdx), 0)
          );
          rows.addRow(
            'personalkostnader',
            value('Personalkostnader'),
            previous,
            current
          );
          previous = ref(
            or(account('7700:7899', 'change', previousPeriodIdx), 0)
          );
          current = ref(
            or(account('7700:7899', 'change', currentPeriodIdx), 0)
          );
          rows.addRow(
            'avskrivningar',
            value('Avskrivningar etc.'),
            previous,
            current
          );
          previous = ref(
            or(account('7900:7999', 'change', previousPeriodIdx), 0)
          );
          current = ref(
            or(account('7900:7999', 'change', currentPeriodIdx), 0)
          );
          rows.addRow(
            'reaForlust',
            value('REA-förlust etc'),
            previous,
            current
          );
          previous = ref(or(account('1000:1999', 'ub', previousPeriodIdx), 0));
          current = ref(or(account('1000:1999', 'ub', currentPeriodIdx), 0));
          rows.addRow('assets', value('SUMMA TILLGÅNGAR'), previous, current);
          previous = ref(or(account('2000:2099', 'ub', previousPeriodIdx), 0));
          current = ref(or(account('2000:2099', 'ub', currentPeriodIdx), 0));
          rows.addRow(
            'totalEquity',
            value('SUMMA EGET KAPITAL'),
            previous,
            current
          );
          previous = ref(or(account('3000:3999', 'ub', previousPeriodIdx), 0));
          current = ref(or(account('3000:3999', 'ub', currentPeriodIdx), 0));
          rows.addRow(
            'totalRevenues',
            value('Summa intäkter'),
            previous,
            current
          );
          previous = ref(or(account('1000:1999', 'ub', previousPeriodIdx), 0));
          current = ref(or(account('1000:1999', 'ub', currentPeriodIdx), 0));
          rows.addRow('assets', value('SUMMA TILLGÅNGAR'), previous, current);
          previous = ref(or(account('2000:2099', 'ub', previousPeriodIdx), 0));
          current = ref(or(account('2000:2099', 'ub', currentPeriodIdx), 0));
          rows.addRow(
            'totalEquity',
            value('SUMMA EGET KAPITAL'),
            previous,
            current
          );
          previous = ref(or(account('3000:3999', 'ub', previousPeriodIdx), 0));
          current = ref(or(account('3000:3999', 'ub', currentPeriodIdx), 0));
          rows.addRow(
            'totalRevenues',
            value('Summa intäkter'),
            previous,
            current
          );
          previous = ref(or(account('1000:1999', 'ub', previousPeriodIdx), 0));
          current = ref(or(account('1000:1999', 'ub', currentPeriodIdx), 0));
          rows.addRow('assets', value('SUMMA TILLGÅNGAR'), previous, current);
          previous = ref(or(account('2000:2999', 'ub', previousPeriodIdx), 0));
          current = ref(or(account('2000:2099', 'ub', currentPeriodIdx), 0));
          rows.addRow(
            'totalCapital',
            value('SUMMA EGET KAPITAL'),
            previous,
            current
          );
          previous = ref(or(account('3000:3999', 'ub', previousPeriodIdx), 0));
          current = ref(or(account('3000:3999', 'ub', currentPeriodIdx), 0));
          rows.addRow(
            'totalRevenues',
            value('Summa intäkter'),
            previous,
            current
          );
          return rows.build();
        })
        .build(),
    },
  };
};

import { value } from '@agoy/document';
import { NoteOneTableSection, IxbrlCell } from '../../../../common';
import { sumNotesRowsById } from '../../../../common/utils/accountSumsUtil';
import { year, previousYear } from '../../../../common/utils/date-util';
import {
  field,
  ixbrlCell,
  ixbrlField,
  range,
  table,
} from '../../../../common/utils/util';

const MONETARY_ITEM_TYPE = 'monetaryItemType';

export const getNote14 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null,
  documentTypeVersion = '1'
): NoteOneTableSection => {
  return {
    active: false,
    number: value(undefined),
    name: value('Övriga materiella anläggningstillgångar'),
    table: table<IxbrlCell>(
      `notes.note14.table`,
      { id: 'label', label: '' },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      .addRows((rows) =>
        rows
          // 'ingående' = previous period outgoing balance
          .addRow(
            '1',
            noteLabel('Ingående anskaffningsvärden'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OvrigaMateriellaAnlaggningstillgangarAnskaffningsvarden',
              contextRef: 'balans1',
              saldo: 'debit',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OvrigaMateriellaAnlaggningstillgangarAnskaffningsvarden',
              contextRef: 'balans2',
              saldo: 'debit',
            })
          )
          .addRow(
            '2',
            noteLabel('Inköp'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OvrigaMateriellaAnlaggningstillgangarForandringAnskaffningsvardenInkop',
              contextRef: 'period0',
              standardRubrik:
                'Ökning av anskaffningsvärden i samband med inköp av övriga materiella anläggningstillgångar',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OvrigaMateriellaAnlaggningstillgangarForandringAnskaffningsvardenInkop',
              contextRef: 'period1',
              standardRubrik:
                'Ökning av anskaffningsvärden i samband med inköp av övriga materiella anläggningstillgångar',
            })
          )
          .addRow(
            'businessAcquisitions',
            noteLabel('Rörelseförvärv'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OvrigaMateriellaAnlaggningstillgangarForandringAnskaffningsvardenRorelseforvarv',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden för övriga materiella anläggningstillgångar genom rörelseförvärv',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OvrigaMateriellaAnlaggningstillgangarForandringAnskaffningsvardenRorelseforvarv',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden för övriga materiella anläggningstillgångar genom rörelseförvärv',
            })
          )
          .addRow(
            'anskaffningsvardenFusion',
            noteLabel('Fusion'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OvrigaMateriellaAnlaggningstillgangarForandringAnskaffningsvardenFusion',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden för övriga materiella anläggningstillgångar genom fusion',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OvrigaMateriellaAnlaggningstillgangarForandringAnskaffningsvardenFusion',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden för övriga materiella anläggningstillgångar genom fusion',
            })
          )
          .addRow(
            '3',
            noteLabel('Försäljningar/utrangeringar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OvrigaMateriellaAnlaggningstillgangarForandringAnskaffningsvardenForsaljningarUtrangeringar',
              contextRef: 'period0',
              standardRubrik:
                'Minskning av anskaffningsvärden i samband med försäljningar eller utrangeringar av övriga materiella anläggningstillgångar',
              negateValue: true,
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OvrigaMateriellaAnlaggningstillgangarForandringAnskaffningsvardenForsaljningarUtrangeringar',
              contextRef: 'period1',
              standardRubrik:
                'Minskning av anskaffningsvärden i samband med försäljningar eller utrangeringar av övriga materiella anläggningstillgångar',
              negateValue: true,
            })
          )
          .addRow(
            '4',
            noteLabel('Omklassificeringar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OvrigaMateriellaAnlaggningstillgangarForandringAnskaffningsvardenOmklassificeringar',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden i samband med omklassificeringar av övriga materiella anläggningstillgångar',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OvrigaMateriellaAnlaggningstillgangarForandringAnskaffningsvardenOmklassificeringar',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden i samband med omklassificeringar av övriga materiella anläggningstillgångar',
            })
          )
          .addRow(
            'anskaffningsvardenConversionDifferences',
            noteLabel('Omräkningsdifferenser'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OvrigaMateriellaAnlaggningstillgangarForandringAnskaffningsvardenOmrakningsdifferenser',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden i samband med omräkningsdifferenser av övriga materiella anläggningstillgångar',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OvrigaMateriellaAnlaggningstillgangarForandringAnskaffningsvardenOmrakningsdifferenser',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden i samband med omräkningsdifferenser av övriga materiella anläggningstillgångar',
            })
          )
          .addRow(
            '5',
            noteLabel('Utgående anskaffningsvärden'),
            ixbrlCell(
              sumNotesRowsById(
                14,
                [
                  ...range(1, 5),
                  'businessAcquisitions',
                  'anskaffningsvardenFusion',
                  'anskaffningsvardenConversionDifferences',
                ],
                'year'
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:OvrigaMateriellaAnlaggningstillgangarAnskaffningsvarden',
                contextRef: 'balans0',
                saldo: 'debit',
              }
            ),
            ixbrlCell(
              sumNotesRowsById(
                14,
                [
                  ...range(1, 5),
                  'businessAcquisitions',
                  'anskaffningsvardenFusion',
                  'anskaffningsvardenConversionDifferences',
                ],
                'previousYear'
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:OvrigaMateriellaAnlaggningstillgangarAnskaffningsvarden',
                contextRef: 'balans1',
                saldo: 'debit',
              }
            )
          )
          .addRow(
            '6',
            noteLabel('Ingående avskrivningar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OvrigaMateriellaAnlaggningstillgangarAvskrivningar',
              contextRef: 'balans1',
              saldo: 'credit',
              negateValue: true,
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OvrigaMateriellaAnlaggningstillgangarAvskrivningar',
              contextRef: 'balans2',
              saldo: 'credit',
              negateValue: true,
            })
          )
          .addRow(
            'avskrivningarFusion',
            noteLabel('Fusion'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OvrigaMateriellaAnlaggningstillgangarForandringAvskrivningarFusion',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av avskrivningar för övriga materiella anläggningstillgångar genom fusion',
              negateValue: true,
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OvrigaMateriellaAnlaggningstillgangarForandringAvskrivningarFusion',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av avskrivningar för övriga materiella anläggningstillgångar genom fusion',
              negateValue: true,
            })
          )
          .addRow(
            '7',
            noteLabel('Försäljningar/utrangeringar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OvrigaMateriellaAnlaggningstillgangarForandringAvskrivningarAterfordaAvskrivningarForsaljningarRespUtrangeringar',
              contextRef: 'period0',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OvrigaMateriellaAnlaggningstillgangarForandringAvskrivningarAterfordaAvskrivningarForsaljningarRespUtrangeringar',
              contextRef: 'period1',
            })
          )
          .addRow(
            '8',
            noteLabel('Omklassificeringar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OvrigaMateriellaAnlaggningstillgangarForandringAvskrivningarOmklassificeringar',
              contextRef: 'period0',
              standardRubrik:
                'Minskning (ökning) av avskrivningar i samband med omklassificeringar av övriga materiella anläggningstillgångar',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OvrigaMateriellaAnlaggningstillgangarForandringAvskrivningarOmklassificeringar',
              contextRef: 'period1',
              standardRubrik:
                'Minskning (ökning) av avskrivningar i samband med omklassificeringar av övriga materiella anläggningstillgångar',
            })
          )
          .addRow(
            '9',
            noteLabel('Årets avskrivningar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OvrigaMateriellaAnlaggningstillgangarForandringAvskrivningarAretsAvskrivningar',
              contextRef: 'period0',
              negateValue: true,
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OvrigaMateriellaAnlaggningstillgangarForandringAvskrivningarAretsAvskrivningar',
              contextRef: 'period1',
              negateValue: true,
            })
          )
          .addRow(
            'avskrivningarConversionDifferences',
            noteLabel('Omräkningsdifferenser'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OvrigaMateriellaAnlaggningstillgangarForandringAvskrivningarOmrakningsdifferenser',
              contextRef: 'period0',
              standardRubrik:
                'Minskning (ökning) av avskrivningar i samband med omräkningsdifferenser av övriga materiella anläggningstillgångar',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OvrigaMateriellaAnlaggningstillgangarForandringAvskrivningarOmrakningsdifferenser',
              contextRef: 'period1',
              standardRubrik:
                'Minskning (ökning) av avskrivningar i samband med omräkningsdifferenser av övriga materiella anläggningstillgångar',
            })
          )
          .addRow(
            '10',
            noteLabel('Utgående avskrivningar'),
            ixbrlCell(
              sumNotesRowsById(
                14,
                [
                  ...range(6, 10),
                  'avskrivningarFusion',
                  'avskrivningarConversionDifferences',
                ],
                'year'
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:OvrigaMateriellaAnlaggningstillgangarAvskrivningar',
                contextRef: 'balans0',
                saldo: 'credit',
                negateValue: true,
              }
            ),
            ixbrlCell(
              sumNotesRowsById(
                14,
                [
                  ...range(6, 10),
                  'avskrivningarFusion',
                  'avskrivningarConversionDifferences',
                ],
                'previousYear'
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:OvrigaMateriellaAnlaggningstillgangarAvskrivningar',
                contextRef: 'balans1',
                saldo: 'credit',
                negateValue: true,
              }
            )
          )
          .addRow(
            '11',
            noteLabel('Ingående nedskrivningar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OvrigaMateriellaAnlaggningstillgangarNedskrivningar',
              contextRef: 'balans1',
              saldo: 'credit',
              negateValue: true,
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OvrigaMateriellaAnlaggningstillgangarNedskrivningar',
              contextRef: 'balans2',
              saldo: 'credit',
              negateValue: true,
            })
          )
          .addRow(
            'nedskrivningarFusion',
            noteLabel('Fusion'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OvrigaMateriellaAnlaggningstillgangarForandringNedskrivningarFusion',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av nedskrivningar för övriga materiella anläggningstillgångar genom fusion',
              negateValue: true,
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OvrigaMateriellaAnlaggningstillgangarForandringNedskrivningarFusion',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av nedskrivningar för övriga materiella anläggningstillgångar genom fusion',
              negateValue: true,
            })
          )
          .addRow(
            '12',
            noteLabel('Försäljningar/utrangeringar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OvrigaMateriellaAnlaggningstillgangarForandringNedskrivningarAterfordaNedskrivningarForsaljningarRespUtrangeringar',
              contextRef: 'period0',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OvrigaMateriellaAnlaggningstillgangarForandringNedskrivningarAterfordaNedskrivningarForsaljningarRespUtrangeringar',
              contextRef: 'period1',
            })
          )
          .addRow(
            '13',
            noteLabel('Återförda nedskrivningar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OvrigaMateriellaAnlaggningstillgangarForandringNedskrivningarAterfordaNedskrivningar',
              contextRef: 'period0',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OvrigaMateriellaAnlaggningstillgangarForandringNedskrivningarAterfordaNedskrivningar',
              contextRef: 'period1',
            })
          )
          .addRow(
            '14',
            noteLabel('Omklassificeringar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OvrigaMateriellaAnlaggningstillgangarForandringNedskrivningarOmklassificeringar',
              contextRef: 'period0',
              standardRubrik:
                'Minskning (ökning) av nedskrivningar i samband med omklassificeringar av övriga materiella anläggningstillgångar',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OvrigaMateriellaAnlaggningstillgangarForandringNedskrivningarOmklassificeringar',
              contextRef: 'period1',
              standardRubrik:
                'Minskning (ökning) av nedskrivningar i samband med omklassificeringar av övriga materiella anläggningstillgångar',
            })
          )
          .addRow(
            '15',
            noteLabel('Årets nedskrivningar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OvrigaMateriellaAnlaggningstillgangarForandringNedskrivningarAretsNedskrivningar',
              contextRef: 'period0',
              negateValue: true,
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OvrigaMateriellaAnlaggningstillgangarForandringNedskrivningarAretsNedskrivningar',
              contextRef: 'period1',
              negateValue: true,
            })
          )
          .addRow(
            'nedskrivningarConversionDifferences',
            noteLabel('Omräkningsdifferenser'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OvrigaMateriellaAnlaggningstillgangarForandringNedskrivningarOmrakningsdifferenser',
              contextRef: 'period0',
              standardRubrik:
                'Minskning (ökning) av nedskrivningar i samband med omräkningsdifferenser av övriga materiella anläggningstillgångar',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OvrigaMateriellaAnlaggningstillgangarForandringNedskrivningarOmrakningsdifferenser',
              contextRef: 'period1',
              standardRubrik:
                'Minskning (ökning) av nedskrivningar i samband med omräkningsdifferenser av övriga materiella anläggningstillgångar',
            })
          )
          .addRow(
            '16',
            noteLabel('Utgående nedskrivningar'),
            ixbrlCell(
              sumNotesRowsById(
                14,
                [
                  ...range(11, 16),
                  'nedskrivningarFusion',
                  'nedskrivningarConversionDifferences',
                ],
                'year'
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:OvrigaMateriellaAnlaggningstillgangarNedskrivningar',
                contextRef: 'balans0',
                saldo: 'credit',
                negateValue: true,
              }
            ),
            ixbrlCell(
              sumNotesRowsById(
                14,
                [
                  ...range(11, 16),
                  'nedskrivningarFusion',
                  'nedskrivningarConversionDifferences',
                ],
                'previousYear'
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:OvrigaMateriellaAnlaggningstillgangarNedskrivningar',
                contextRef: 'balans1',
                saldo: 'credit',
                negateValue: true,
              }
            )
          )
          .addRow(
            '17',
            noteLabel('Redovisat värde'),
            ixbrlCell(sumNotesRowsById(14, [5, 10, 16], 'year'), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OvrigaMateriellaAnlaggningstillgangar',
              contextRef: 'balans0',
              saldo: 'debit',
            }),
            ixbrlCell(sumNotesRowsById(14, [5, 10, 16], 'previousYear'), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:OvrigaMateriellaAnlaggningstillgangar',
              contextRef: 'balans1',
              saldo: 'debit',
            })
          )
          .build()
      )
      .newRowTemplate(value(''), value(undefined), value(undefined))
      .build(),
    commentLabel: field('Kommentar'),
    comment: ixbrlField(field(''), {
      type: 'stringItemType',
      name: 'se-gen-base:NotOvrigaMateriellaAnlaggningstillgangarKommentar',
      contextRef: 'period0',
    }),
    group: value(undefined),
    section: value(undefined),
    validForReportType: {
      k2: true,
      k3: documentTypeVersion === '1',
    },
  };
};

import React from 'react';
import styled from '@emotion/styled';
import { AgoyTableColumn, AgoyTableRow } from '@agoy/document';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import RRBRTableRow from './TableRow';

const StyledTable = styled(Table)`
  margin-top: 10px;

  width: max-content;

  .MuiTableBody-root {
    tr {
      td {
        background-color: rgb(250, 250, 250);
      }
      & > td:nth-of-type(2n) {
        background-color: rgb(248, 255, 251);
      }
    }
    & > tr:nth-of-type(2n) {
      td {
        background-color: rgb(245, 245, 245);
      }
      & > td:nth-of-type(2n) {
        background-color: rgb(244, 250, 246);
      }
    }
  }
`;

const StyledRow = styled(TableRow)`
  & > th:nth-of-type(n + 2) {
    text-align: end;
  }
  & > th:first-of-type {
    background-color: rgb(248, 255, 251);
  }
  & > th:nth-of-type(2n) {
    background-color: rgb(248, 255, 251);
  }
`;

const StyledTableCell = styled(TableCell, {
  shouldForwardProp: (propName) => propName !== 'print',
})<{ print?: boolean }>`
  font-size: ${({ print }) => (print ? '1rem' : '0.5rem')};
  line-height: ${({ print }) => (print ? '1.4rem' : '0.6rem')};
  font-weight: bold;
  text-align: inherit;
  width: ${({ print }) => (print ? '84px' : '47px')};
  :first-of-type {
    width: ${({ print }) => (print ? '185px' : '110px')};
  }
`;

const StyledRRBRTableRow = styled(RRBRTableRow)<{ print?: boolean }>`
  td {
    width: ${({ print }) => (print ? '84px' : '47px')};
  }
  td:first-of-type {
    width: ${({ print }) => (print ? '185px' : '110px')};
  }
`;

interface ResultTable {
  columns: AgoyTableColumn[];
  rows: AgoyTableRow[];
}

interface RRBRTableProps {
  data: ResultTable;
  print?: boolean;
  hideHeader?: boolean;
  lastRowBorder?: boolean;
  className?: string;
  formatValueInThousands?: boolean;
}

const RRBRTable = ({
  data,
  print = false,
  hideHeader = false,
  lastRowBorder = false,
  className,
  formatValueInThousands = false,
}: RRBRTableProps): JSX.Element => {
  const { columns, rows } = data;
  return (
    <StyledTable className={className} size="small" padding="none">
      {!hideHeader && (
        <TableHead>
          <StyledRow>
            {columns.map((column, index) => (
              <StyledTableCell print={print} key={`${column.label}.${index}`}>
                {column.label}
              </StyledTableCell>
            ))}
          </StyledRow>
        </TableHead>
      )}
      <TableBody>
        {rows.map((row, index) => (
          <StyledRRBRTableRow
            key={row.id}
            row={row}
            columns={columns}
            isLastRow={lastRowBorder && rows.length - 1 === index}
            print={print}
            formatValueInThousands={formatValueInThousands}
          />
        ))}
      </TableBody>
    </StyledTable>
  );
};

export default RRBRTable;

/* eslint-disable no-console */
// eslint-disable-next-line import/no-cycle
import { operations, isError } from '@agoy/document';
import { TaxDeclarationFormData } from '@agoy/tax-declaration-document';
import { AddTaxDeclarationTableRow } from 'redux/actions';
import TaxDeclarationViewState from '../types';

const addTaxDeclarationFormTableRow = (
  state: TaxDeclarationViewState,
  action: AddTaxDeclarationTableRow
): TaxDeclarationViewState => {
  if (Object.keys(state.formData).length === 0) {
    return state;
  }

  const selectedFormId = state.viewFormId;
  if (!selectedFormId) return state;

  const formData = state.formData[selectedFormId];
  if (!formData) {
    return state;
  }
  const { partId, id, newId } = action;

  const result = operations.addTableRow(
    formData.definition,
    { document: formData.document, changes: formData.changes },
    `${partId}.${id}`,
    newId
  );

  if (!result || isError(result)) {
    console.warn(action, result);
    return state;
  }

  return {
    ...state,
    formData: {
      ...state.formData,
      [selectedFormId]: {
        ...formData,
        document: result.document as TaxDeclarationFormData,
        changes: result.changes,
      },
    },
  };
};

export default addTaxDeclarationFormTableRow;

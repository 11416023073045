import React from 'react';
import styled from '@emotion/styled';
import { AgoyTable } from '@agoy/document';
import { Table } from '../../../UI/CommonPrintTable';
import {
  flattenRows,
  hasPreviousYearColumn,
  includeRowInPrint,
} from '../../../utils/utils';
import {
  NumberFormatType,
  PercentageNumberFormatType,
  StandardNumberFormatType,
} from '@agoy/common';

interface FourColumnTableProps {
  table: AgoyTable;
  tableId: string;
  hasPreviousYear?: boolean;
}

const StyledTable = styled(Table)`
  .MuiTableCell-root {
    width: 200px;

    &:first-of-type {
      width: auto;
    }

    &:last-child {
      width: 200px;
    }
  }
`;

const FourColumnTable = ({
  table,
  tableId,
  hasPreviousYear = false,
}: FourColumnTableProps): JSX.Element => {
  const rows = flattenRows(table.rows).filter((row) => includeRowInPrint(row));

  const columns = table.columns.filter(
    (col) => hasPreviousYear || !hasPreviousYearColumn(col)
  );

  const handleFormatColumn = (colId: string): NumberFormatType => {
    return colId === 'capitalShare'
      ? { ...PercentageNumberFormatType, minValue: 0, maxValue: 1 }
      : StandardNumberFormatType;
  };

  return (
    <StyledTable
      baseId="annualReport"
      tableId={tableId}
      rows={rows}
      columns={columns}
      numberFormatCell={handleFormatColumn}
    />
  );
};

export default FourColumnTable;

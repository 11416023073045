import { value, ref } from '@agoy/document';
import { IxbrlCell, NoteOneTableSection } from '../../../../common';
import {
  sumAccountsIBs,
  sumPositiveTransactions,
  sumNegativeTransactions,
  sumNotesRowsById,
} from '../../../../common/utils/accountSumsUtil';
import { year, previousYear } from '../../../../common/utils/date-util';
import {
  field,
  ixbrlCell,
  ixbrlField,
  range,
  table,
} from '../../../../common/utils/util';
import { NoteAccountRanges } from '../types';

const MONETARY_ITEM_TYPE = 'monetaryItemType';

export const note7Accounts: NoteAccountRanges[] = [
  {
    rowId: '1',
    ranges: [[1060, 1067]],
    cellLabel: 'Ingående anskaffningsvärden',
    action: 'sumAccountsIBs',
  },
  {
    rowId: '2',
    ranges: [[1060, 1067]],
    cellLabel: 'Inköp',
    action: 'sumPositiveTransactions',
  },
  {
    rowId: '3',
    ranges: [[1060, 1067]],
    cellLabel: 'Försäljningar/utrangeringar',
    action: 'sumNegativeTransactions',
  },
  {
    rowId: '6',
    ranges: [[1069]],
    cellLabel: 'Ingående avskrivningar',
    action: 'sumAccountsIBs',
  },
  {
    rowId: '7',
    ranges: [[1069]],
    cellLabel: 'Försäljningar/utrangeringar',
    action: 'sumPositiveTransactions',
  },
  {
    rowId: '9',
    ranges: [[1069]],
    cellLabel: 'Årets avskrivningar',
    action: 'sumNegativeTransactions',
  },
  {
    rowId: '11',
    ranges: [[1068]],
    cellLabel: 'Ingående nedskrivningar',
    action: 'sumAccountsIBs',
  },
  {
    rowId: '12',
    ranges: [[1068]],
    cellLabel: 'Försäljningar/utrangeringar',
    action: 'sumPositiveTransactions',
  },
  {
    rowId: '15',
    ranges: [[1068]],
    cellLabel: 'Årets nedskrivningar',
    action: 'sumNegativeTransactions',
  },
];

export const getNote7 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null,
  documentTypeVersion = '1'
): NoteOneTableSection => {
  return {
    active: false,
    number: value(undefined),
    name: value('Hyresrätter och liknande rättigheter'),
    table: table<IxbrlCell>(
      `notes.note7.table`,
      { id: 'label', label: '' },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      // 'ingående' = previous period outgoing balance
      .addRows((rows) =>
        rows
          .addRow(
            '1',
            noteLabel('Ingående anskaffningsvärden'),
            ixbrlCell(ref(sumAccountsIBs('1060:1067', false)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:HyresratterLiknandeRattigheterAnskaffningsvarden',
              contextRef: 'balans1',
              saldo: 'debit',
            }),
            ixbrlCell(ref(sumAccountsIBs('1060:1067', false, 'previousYear')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:HyresratterLiknandeRattigheterAnskaffningsvarden',
              contextRef: 'balans2',
              saldo: 'debit',
            })
          )
          .addRow(
            '2',
            noteLabel('Inköp'),
            ixbrlCell(ref(sumPositiveTransactions('1060:1067')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:HyresratterLiknandeRattigheterForandringAnskaffningsvardenInkop',
              contextRef: 'period0',
              standardRubrik:
                'Ökning av anskaffningsvärden i samband med inköp av hyresrätter och liknande rättigheter',
            }),
            ixbrlCell(
              ref(sumPositiveTransactions('1060:1067', 'previousYear')),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:HyresratterLiknandeRattigheterForandringAnskaffningsvardenInkop',
                contextRef: 'period1',
                standardRubrik:
                  'Ökning av anskaffningsvärden i samband med inköp av hyresrätter och liknande rättigheter',
              }
            )
          )
          .addRow(
            'businessAcquisitions',
            noteLabel('Rörelseförvärv'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:HyresratterLiknandeRattigheterForandringAnskaffningsvardenRorelseforvarv',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden för hyresrätter och liknande rättigheter genom rörelseförvärv',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:HyresratterLiknandeRattigheterForandringAnskaffningsvardenRorelseforvarv',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden för hyresrätter och liknande rättigheter genom rörelseförvärv',
            })
          )
          .addRow(
            'anskaffningsvardenFusion',
            noteLabel('Fusion'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:HyresratterLiknandeRattigheterForandringAnskaffningsvardenFusion',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden för hyresrätter och liknande rättigheter genom fusion',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:HyresratterLiknandeRattigheterForandringAnskaffningsvardenFusion',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden för hyresrätter och liknande rättigheter genom fusion',
            })
          )
          .addRow(
            '3',
            noteLabel('Försäljningar/utrangeringar'),
            ixbrlCell(ref(sumNegativeTransactions('1060:1067')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:HyresratterLiknandeRattigheterForandringAnskaffningsvardenForsaljningarUtrangeringar',
              contextRef: 'period0',
              standardRubrik:
                'Minskning av anskaffningsvärden i samband med försäljningar eller utrangeringar av hyresrätter och liknande rättigheter',
              negateValue: true,
            }),
            ixbrlCell(
              ref(sumNegativeTransactions('1060:1067', 'previousYear')),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:HyresratterLiknandeRattigheterForandringAnskaffningsvardenForsaljningarUtrangeringar',
                contextRef: 'period1',
                standardRubrik:
                  'Minskning av anskaffningsvärden i samband med försäljningar eller utrangeringar av hyresrätter och liknande rättigheter',
                negateValue: true,
              }
            )
          )
          .addRow(
            '4',
            noteLabel('Omklassificeringar'), // no accounts
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:HyresratterLiknandeRattigheterForandringAnskaffningsvardenOmklassificeringar',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden i samband med omklassificeringar av hyresrätter och liknande rättigheter',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:HyresratterLiknandeRattigheterForandringAnskaffningsvardenOmklassificeringar',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden i samband med omklassificeringar av hyresrätter och liknande rättigheter',
            })
          )
          .addRow(
            'anskaffningsvardenConversionDifferences',
            noteLabel('Omräkningsdifferenser'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:HyresratterLiknandeRattigheterForandringAnskaffningsvardenOmrakningsdifferenser',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden i samband med omräkningsdifferenser av hyresrätter och liknande rättigheter',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:HyresratterLiknandeRattigheterForandringAnskaffningsvardenOmrakningsdifferenser',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av anskaffningsvärden i samband med omräkningsdifferenser av hyresrätter och liknande rättigheter',
            })
          )
          .addRow(
            '5',
            noteLabel('Utgående anskaffningsvärden'),
            ixbrlCell(
              sumNotesRowsById(
                7,
                [
                  ...range(1, 5),
                  'businessAcquisitions',
                  'anskaffningsvardenFusion',
                  'anskaffningsvardenConversionDifferences',
                ],
                'year'
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:HyresratterLiknandeRattigheterAnskaffningsvarden',
                contextRef: 'balans0',
                saldo: 'debit',
              }
            ),
            ixbrlCell(
              sumNotesRowsById(
                7,
                [
                  ...range(1, 5),
                  'businessAcquisitions',
                  'anskaffningsvardenFusion',
                  'anskaffningsvardenConversionDifferences',
                ],
                'previousYear'
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:HyresratterLiknandeRattigheterAnskaffningsvarden',
                contextRef: 'balans1',
                saldo: 'debit',
              }
            )
          )
          .addRow(
            '6',
            noteLabel('Ingående avskrivningar'),
            ixbrlCell(ref(sumAccountsIBs('1069', false)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:HyresratterLiknandeRattigheterAvskrivningar',
              contextRef: 'balans1',
              saldo: 'credit',
              negateValue: true,
            }),
            ixbrlCell(ref(sumAccountsIBs('1069', false, 'previousYear')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:HyresratterLiknandeRattigheterAvskrivningar',
              contextRef: 'balans2',
              saldo: 'credit',
              negateValue: true,
            })
          )
          .addRow(
            'avskrivningarFusion',
            noteLabel('Fusion'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:HyresratterLiknandeRattigheterForandringAvskrivningarAterfordaAvskrivningarFusion',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av avskrivningar för hyresrätter och liknande rättigheter genom fusion',
              negateValue: true,
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:HyresratterLiknandeRattigheterForandringAvskrivningarAterfordaAvskrivningarFusion',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av avskrivningar för hyresrätter och liknande rättigheter genom fusion',
              negateValue: true,
            })
          )
          .addRow(
            '7',
            noteLabel('Försäljningar/utrangeringar'),
            ixbrlCell(ref(sumPositiveTransactions('1069')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:HyresratterLiknandeRattigheterForandringAvskrivningarAterfordaAvskrivningarForsaljningarRespUtrangeringar',
              contextRef: 'period0',
            }),
            ixbrlCell(ref(sumPositiveTransactions('1069', 'previousYear')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:HyresratterLiknandeRattigheterForandringAvskrivningarAterfordaAvskrivningarForsaljningarRespUtrangeringar',
              contextRef: 'period1',
            })
          )
          .addRow(
            '8',
            noteLabel('Omklassificeringar'), // no accounts
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:HyresratterLiknandeRattigheterForandringAvskrivningarOmklassificeringar',
              contextRef: 'period0',
              standardRubrik:
                'Minskning (ökning) av avskrivningar i samband med omklassificeringar av hyresrätter och liknande rättigheter',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:HyresratterLiknandeRattigheterForandringAvskrivningarOmklassificeringar',
              contextRef: 'period1',
              standardRubrik:
                'Minskning (ökning) av avskrivningar i samband med omklassificeringar av hyresrätter och liknande rättigheter',
            })
          )
          .addRow(
            '9',
            noteLabel('Årets avskrivningar'),
            ixbrlCell(ref(sumNegativeTransactions('1069')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:HyresratterLiknandeRattigheterForandringAvskrivningarAretsAvskrivningar',
              contextRef: 'period0',
              negateValue: true,
            }),
            ixbrlCell(ref(sumNegativeTransactions('1069', 'previousYear')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:HyresratterLiknandeRattigheterForandringAvskrivningarAretsAvskrivningar',
              contextRef: 'period1',
              negateValue: true,
            })
          )
          .addRow(
            'avskrivningarConversionDifferences',
            noteLabel('Omräkningsdifferenser'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:HyresratterLiknandeRattigheterForandringAvskrivningarOmrakningsdifferenser',
              contextRef: 'period0',
              standardRubrik:
                'Minskning (ökning) av avskrivningar i samband med omräkningsdifferenser av hyresrätter och liknande rättigheter',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:HyresratterLiknandeRattigheterForandringAvskrivningarOmrakningsdifferenser',
              contextRef: 'period1',
              standardRubrik:
                'Minskning (ökning) av avskrivningar i samband med omräkningsdifferenser av hyresrätter och liknande rättigheter',
            })
          )
          .addRow(
            '10',
            noteLabel('Utgående avskrivningar'),
            ixbrlCell(
              sumNotesRowsById(
                7,
                [
                  ...range(6, 10),
                  'avskrivningarFusion',
                  'avskrivningarConversionDifferences',
                ],
                'year'
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:HyresratterLiknandeRattigheterAvskrivningar',
                contextRef: 'balans0',
                saldo: 'credit',
                negateValue: true,
              }
            ),
            ixbrlCell(
              sumNotesRowsById(
                7,
                [
                  ...range(6, 10),
                  'avskrivningarFusion',
                  'avskrivningarConversionDifferences',
                ],
                'previousYear'
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:HyresratterLiknandeRattigheterAvskrivningar',
                contextRef: 'balans1',
                saldo: 'credit',
                negateValue: true,
              }
            )
          )
          .addRow(
            '11',
            noteLabel('Ingående nedskrivningar'),
            ixbrlCell(ref(sumAccountsIBs('1068', false)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:HyresratterLiknandeRattigheterNedskrivningar',
              contextRef: 'balans1',
              saldo: 'credit',
              negateValue: true,
            }),
            ixbrlCell(ref(sumAccountsIBs('1068', false, 'previousYear')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:HyresratterLiknandeRattigheterNedskrivningar',
              contextRef: 'balans2',
              saldo: 'credit',
              negateValue: true,
            })
          )
          .addRow(
            'nedskrivningarFusion',
            noteLabel('Fusion'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:HyresratterLiknandeRattigheterForandringNedskrivningarFusion',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av nedskrivningar för hyresrätter och liknande rättigheter genom fusion',
              negateValue: true,
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:HyresratterLiknandeRattigheterForandringNedskrivningarFusion',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av nedskrivningar för hyresrätter och liknande rättigheter genom fusion',
              negateValue: true,
            })
          )
          .addRow(
            '12',
            noteLabel('Försäljningar/utrangeringar'),
            ixbrlCell(ref(sumPositiveTransactions('1068')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:HyresratterLiknandeRattigheterForandringNedskrivningarAterfordaNedskrivningarForsaljningarRespUtrangeringar',
              contextRef: 'period0',
            }),
            ixbrlCell(ref(sumPositiveTransactions('1068', 'previousYear')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:HyresratterLiknandeRattigheterForandringNedskrivningarAterfordaNedskrivningarForsaljningarRespUtrangeringar',
              contextRef: 'period1',
            }),
            value(undefined)
          )
          .addRow(
            '13',
            noteLabel('Återförda nedskrivningar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:HyresratterLiknandeRattigheterForandringNedskrivningarAterfordaNedskrivningar',
              contextRef: 'period0',
            }), // no accounts
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:HyresratterLiknandeRattigheterForandringNedskrivningarAterfordaNedskrivningar',
              contextRef: 'period1',
            })
          )
          .addRow(
            '14',
            noteLabel('Omklassificeringar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:HyresratterLiknandeRattigheterForandringNedskrivningarOmklassificeringar',
              contextRef: 'period0',
              standardRubrik:
                'Minskning (ökning) av nedskrivningar i samband med omklassificeringar av hyresrätter och liknande rättigheter',
            }), // no accounts
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:HyresratterLiknandeRattigheterForandringNedskrivningarOmklassificeringar',
              contextRef: 'period1',
              standardRubrik:
                'Minskning (ökning) av nedskrivningar i samband med omklassificeringar av hyresrätter och liknande rättigheter',
            })
          )
          .addRow(
            '15',
            noteLabel('Årets nedskrivningar'),
            ixbrlCell(ref(sumNegativeTransactions('1068')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:HyresratterLiknandeRattigheterForandringNedskrivningarAretsNedskrivningar',
              contextRef: 'period0',
              negateValue: true,
            }),
            ixbrlCell(ref(sumNegativeTransactions('1068', 'previousYear')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:HyresratterLiknandeRattigheterForandringNedskrivningarAretsNedskrivningar',
              contextRef: 'period1',
              negateValue: true,
            })
          )
          .addRow(
            'nedskrivningarConversionDifferences',
            noteLabel('Omräkningsdifferenser'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:HyresratterLiknandeRattigheterForandringNedskrivningarOmrakningsdifferenser',
              contextRef: 'period0',
              standardRubrik:
                'Minskning (ökning) av nedskrivningar i samband med omräkningsdifferenser av hyresrätter och liknande rättigheter',
              negateValue: true,
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:HyresratterLiknandeRattigheterForandringNedskrivningarOmrakningsdifferenser',
              contextRef: 'period1',
              standardRubrik:
                'Minskning (ökning) av nedskrivningar i samband med omräkningsdifferenser av hyresrätter och liknande rättigheter',
              negateValue: true,
            })
          )
          .addRow(
            '16',
            noteLabel('Utgående nedskrivningar'),
            ixbrlCell(
              sumNotesRowsById(
                7,
                [
                  ...range(11, 16),
                  'nedskrivningarFusion',
                  'nedskrivningarConversionDifferences',
                ],
                'year'
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:HyresratterLiknandeRattigheterNedskrivningar',
                contextRef: 'balans0',
                saldo: 'credit',
                negateValue: true,
              }
            ),
            ixbrlCell(
              sumNotesRowsById(
                7,
                [
                  ...range(11, 16),
                  'nedskrivningarFusion',
                  'nedskrivningarConversionDifferences',
                ],
                'previousYear'
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:HyresratterLiknandeRattigheterNedskrivningar',
                contextRef: 'balans1',
                saldo: 'credit',
                negateValue: true,
              }
            )
          )
          .addRow(
            '17',
            noteLabel('Redovisat värde'),
            ixbrlCell(sumNotesRowsById(7, [5, 10, 16], 'year'), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:HyresratterLiknandeRattigheter',
              contextRef: 'balans0',
              saldo: 'debit',
            }),
            ixbrlCell(sumNotesRowsById(7, [5, 10, 16], 'previousYear'), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:HyresratterLiknandeRattigheter',
              contextRef: 'balans1',
              saldo: 'debit',
            })
          )
          .build()
      )
      .newRowTemplate(value(''), value(undefined), value(undefined))
      .build(),
    commentLabel: field('Kommentar'),
    comment: ixbrlField(field(''), {
      type: 'stringItemType',
      name: 'se-gen-base:NotHyresratterLiknandeRattigheterKommentar',
      contextRef: 'period0',
    }),
    group: value(undefined),
    section: value(undefined),
    validForReportType: {
      k2: true,
      k3: documentTypeVersion === '1',
    },
  };
};

import {
  id,
  account,
  sum,
  or,
  multiply,
  label,
  ref,
  value,
  refs,
  ReferenceAccountInformation,
  TimePeriod,
  AccountValueType,
} from '@agoy/document';
import { IncomeStatement } from '../../common/types/incomeStatement';
import {
  addIncomeStatementGroup,
  getRowsFromAccountRanges,
} from '../../common/utils/accountsUtil';
import {
  table,
  RowsBuilder,
  getLinkToNote,
  SectionRow,
} from '../../common/utils/util';
import { AnnualReportType } from '../../document';

const addPost =
  (
    accounts: Record<string, ReferenceAccountInformation>,
    switchSign: boolean,
    rows: RowsBuilder,
    rowName: string,
    accountModifier: AccountValueType
  ) =>
  (
    rowId: string,
    name: string,
    accountNumbers: [number, number][],
    noteRef?: string
  ): RowsBuilder => {
    rows.addRow(
      rowId,
      value(name),
      noteRef ? refs(noteRef) : refs(), // notes can be linked here
      ref(or(sum(id(`${rows.getBaseId()}.${rowId}.*.year`)), 0)),
      ref(or(sum(id(`${rows.getBaseId()}.${rowId}.*.previousYear`)), 0))
    );
    rows.newRowTemplateGenerator(
      (id, baseId, { label: labelValue, ib, ub }) => ({
        id,
        active: true,
        type: 'account',
        cells: {
          label: label(labelValue),
          year: ub
            ? ref(
                switchSign
                  ? multiply(-1, account(ub, accountModifier, 'year'))
                  : account(ub, accountModifier, 'year')
              )
            : value(0),
          previousYear: ib
            ? ref(
                switchSign
                  ? multiply(-1, account(ib, accountModifier, 'previousYear'))
                  : account(ib, accountModifier, 'previousYear')
              )
            : value(0),
        },
        sortKey: ib ? parseInt(ib) : parseInt(ub),
      })
    );
    rows.addSubRows((rows) => {
      accountNumbers.forEach(([first, last]) =>
        getRowsFromAccountRanges(
          accounts,
          first,
          last,
          rows,
          switchSign,
          'previousYear',
          accountModifier,
          true
        )
      );
      return rows.build();
    });

    return rows;
  };

type IncomeStatementSectionReference =
  | 'incomes'
  | 'expenses'
  | 'taxes'
  | 'result'
  | 'unassignedAccounts';

export type IndividualCompanyIncomeStatementStructure = {
  [key in IncomeStatementSectionReference]: SectionRow[];
};

export const individualCompanyIncomeStatementAccounts: IndividualCompanyIncomeStatementStructure =
  {
    incomes: [
      {
        row: '1',
        name: 'Försäljning och utfört arbete samt övriga momspliktiga intäkter',
        accounts: [
          [3000, 3003],
          [3005, 3043],
          [3045, 3047],
          [3049, 3053],
          [3056, 3057],
          [3059, 3107],
          [3109, 3403],
          [3405, 3999],
        ],
      },
      {
        row: '2',
        name: 'Momsfria intäkter',
        accounts: [
          [3004, 3004],
          [3044, 3044],
          [3048, 3048],
          [3054, 3055],
          [3058, 3058],
          [3108, 3108],
          [3404, 3404],
        ],
      },
      // The two ranges below are also present in Anställd personal
      {
        row: '3',
        name: 'Bil- och bostadsförmån m.m.',
        accounts: [
          [7330, 7333],
          [7380, 7382],
        ],
      },
      {
        row: '4',
        name: 'Ränteintäkter m.m.',
        accounts: [[8300, 8399]],
      },
    ],
    expenses: [
      {
        row: '1',
        name: 'Varor, material och tjänster',
        accounts: [[4000, 4999]],
      },
      {
        row: '2',
        name: 'Övriga externa kostnader',
        accounts: [[5000, 6999]],
      },
      {
        row: '3',
        name: 'Anställd personal',
        accounts: [[7000, 7699]],
      },
      {
        row: '4',
        name: 'Räntekostnader m.m.',
        accounts: [[8400, 8499]],
      },
    ],
    taxes: [
      {
        row: '1',
        name: 'Av- och nedskrivningar av byggnader och markanläggningar',
        accounts: [
          [7720, 7729],
          [7770, 7779],
          [7820, 7829],
          [7840, 7899],
        ],
      },
      {
        row: '2',
        name: 'Av- och nedskrivningar av maskiner och inventarier och immateriella tillgångar',
        accounts: [
          [7700, 7719],
          [7730, 7739],
          [7740, 7749],
          [7760, 7769],
          [7780, 7789],
          [7810, 7819],
          [7830, 7839],
        ],
      },
    ],
    result: [
      {
        row: '1_HideInPreviewAndPrint',
        name: 'Bokfört resultat',
        accounts: [[8990, 8999]],
      },
    ],
    unassignedAccounts: [
      {
        row: '1',
        name: 'Ej tilldelade konton',
        accounts: [
          [7750, 7759],
          [7790, 7809],
          [7900, 8299],
          [8500, 8989],
        ],
      },
    ],
  };

const attachRows = (
  accounts: Record<string, ReferenceAccountInformation>,
  switchSign: boolean,
  rows: RowsBuilder,
  structureKey: keyof IndividualCompanyIncomeStatementStructure,
  accountModifier: AccountValueType
) => {
  const add = addPost(
    accounts,
    switchSign,
    rows,
    structureKey,
    accountModifier
  );
  individualCompanyIncomeStatementAccounts[structureKey].map((section) => {
    const link = getLinkToNote(section, accounts);

    add(section.row, section.name, section.accounts, link);
  });
};

const addIncomes = (
  accounts: Record<string, ReferenceAccountInformation>,
  rows: RowsBuilder,
  accountModifier: AccountValueType
): void => {
  addIncomeStatementGroup(
    rows,
    'incomes',
    'Intäkter m.m.',
    (rows) => attachRows(accounts, true, rows, 'incomes', accountModifier),
    'Summa intäkter'
  );
};

const addExpenses = (
  accounts: Record<string, ReferenceAccountInformation>,
  rows: RowsBuilder,
  type: AnnualReportType,
  accountModifier: AccountValueType
): void => {
  addIncomeStatementGroup(
    rows,
    'expenses',
    'Kostnader',
    (rows) => attachRows(accounts, true, rows, 'expenses', accountModifier),
    'Summa kostnader'
  );
};

const addDepreciation = (
  accounts: Record<string, ReferenceAccountInformation>,
  rows: RowsBuilder,
  accountModifier: AccountValueType
): void => {
  addIncomeStatementGroup(
    rows,
    'taxes',
    'Avskrivningar',
    (rows) => attachRows(accounts, true, rows, 'taxes', accountModifier),
    'Summa avskrivningar'
  );
};

const addResults = (
  accounts: Record<string, ReferenceAccountInformation>,
  rows: RowsBuilder,
  accountModifier: AccountValueType
): void => {
  addIncomeStatementGroup(
    rows,
    'result',
    'Årets resultat',
    // In incomeStatement we usually invert the sign for the UBs from avstämning here
    // but we should not do this for Årets resultat of incomeStatement (accounts 8990 until 8999)
    (rows) => attachRows(accounts, false, rows, 'result', accountModifier),
    ''
  );
};

const addUnassignedAccounts = (
  accounts: Record<string, ReferenceAccountInformation>,
  rows: RowsBuilder,
  accountModifier: AccountValueType
): void => {
  const rowId = 'unassignedAccounts';
  addIncomeStatementGroup(
    rows,
    rowId,
    'Ej tilldelade',
    (rows) => attachRows(accounts, true, rows, rowId, accountModifier),
    'Ej tilldelade total',
    undefined,
    false
  );
};

export const incomeStatementConfig = (
  accounts: Record<string, ReferenceAccountInformation>,
  period: TimePeriod,
  previousPeriod: TimePeriod | null,
  type: AnnualReportType,
  accountModifier: AccountValueType = 'ub'
): IncomeStatement => ({
  active: undefined,
  section: {
    active: undefined,
    table: table(
      'incomeStatement.section.table',
      'label',
      'notes',
      { id: 'year', label: `${period.startDateISO} ${period.endDateISO}` },
      previousPeriod
        ? {
            id: 'previousYear',
            label: `${previousPeriod.startDateISO} ${previousPeriod.endDateISO}`,
          }
        : undefined,
      'hidden'
    )
      .addRows((rows) =>
        rows
          .addRow('income')
          .addSubRows((rows) => {
            rows.addRowWithType('main', 'header').addSubRows((rows) => {
              addIncomes(accounts, rows, accountModifier);

              addExpenses(accounts, rows, type, accountModifier);

              addDepreciation(accounts, rows, accountModifier);

              // Ej tilldelade AKA unassigned account
              addUnassignedAccounts(accounts, rows, accountModifier);

              addResults(accounts, rows, accountModifier);

              return rows.build();
            });
            return rows.build();
          })
          .build()
      )
      .build(),
  },
});

export const incomeStatementReferences = (): Record<string, string> => ({
  'incomeStatement.netSales.year': id(
    'incomeStatement.section.table.income.main.incomes.1.year'
  ),
  'incomeStatement.netSales.previousYear': id(
    'incomeStatement.section.table.income.main.incomes.1.previousYear'
  ),
  'incomeStatement.MomsfriaIntakter.year': id(
    'incomeStatement.section.table.income.main.incomes.2.year'
  ),
  'incomeStatement.BilBostadsforman.year': id(
    'incomeStatement.section.table.income.main.incomes.3.year'
  ),
  'incomeStatement.Ranteintakter.year': id(
    'incomeStatement.section.table.income.main.incomes.4.year'
  ),
  'incomeStatement.VarorMaterialTjanster.year': id(
    'incomeStatement.section.table.income.main.expenses.1.year'
  ),
  'incomeStatement.OvrigaExternaKostnader.year': id(
    'incomeStatement.section.table.income.main.expenses.2.year'
  ),
  'incomeStatement.AnstalldPersonal.year': id(
    'incomeStatement.section.table.income.main.expenses.3.year'
  ),
  'incomeStatement.Rantekostnader.year': id(
    'incomeStatement.section.table.income.main.expenses.4.year'
  ),
  'incomeStatement.NedskrivningarByggnader.year': id(
    'incomeStatement.section.table.income.main.taxes.1.year'
  ),
  'incomeStatement.NedskrivningarMaskiner.year': id(
    'incomeStatement.section.table.income.main.taxes.2.year'
  ),
  'incomeStatement.AretsResultat.year': id(
    'incomeStatement.section.table.income.main.result.1_HideInPreviewAndPrint.year'
  ),
});

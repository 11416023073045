import React from 'react';
import styled from '@emotion/styled';
import { Typography, Button } from '@material-ui/core';

import { useIntl } from 'react-intl';
import {
  SubscriptionPackages,
  SubscriptionProrationReturnType,
} from '_payment/types';
import { PrimaryButton } from '_shared/components/Buttons';
import LoadingLogo from '_shared/components/LoadingLogo';

const Wrapper = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  overflow-y: true;

  width: 600px;
`;

const InformationWrapper = styled.div`
  margin-top: 1rem;
`;

const SubscriptionCardsWrapper = styled.div`
  display: flex;
  gap: ${(props) => props.theme.spacing(4)}px;
  height: 410px;
`;

const SubscriptionCard = styled.div`
  flex-basis: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  background: #e5e5e5;

  border-radius: ${(props) => props.theme.spacing(1)}px;
`;

const QuantityCards = styled.div`
  margin-top: ${(props) => props.theme.spacing(2)}px;
  width: 100%;

  display: flex;
  gap: ${(props) => props.theme.spacing(4)}px;
`;

const QuantityCard = styled.div`
  width: 300px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;

  background: #e5e5e5;
  border-radius: ${(props) => props.theme.spacing(1)}px;
`;

const LoadingLogoWrapper = styled.div`
  height: 250px;

  display: flex;
  align-items: center;
`;

const SubscriptionCards = ({
  subscriptions,
}: {
  subscriptions: Required<SubscriptionPackages>;
}) => {
  const { formatMessage } = useIntl();

  return (
    <SubscriptionCardsWrapper>
      <SubscriptionCard>
        <Typography variant="h4">
          {formatMessage({
            id: 'checkout.bigSubcription.title',
          })}
        </Typography>
        <div style={{ margin: '0 16px', height: '100px' }}>
          <Typography variant="body2">
            {formatMessage({
              id: 'checkout.bigSubscription.body',
            })}
          </Typography>
        </div>
        <ul style={{ marginRight: 'auto' }}>
          <li>
            <Typography variant="body2">
              {formatMessage({
                id: 'checkout.bigSubscription.point1',
              })}
            </Typography>
          </li>
          <li>
            <Typography variant="body2">
              {formatMessage({
                id: 'checkout.bigSubscription.point2',
              })}
            </Typography>
          </li>
          <li>
            <Typography variant="body2">
              {formatMessage({
                id: 'checkout.bigSubscription.point3',
              })}
            </Typography>
          </li>
          <li>
            <Typography variant="body2">
              {formatMessage({
                id: 'checkout.bigSubscription.point4',
              })}
            </Typography>
          </li>
          <li>
            <Typography variant="body2">
              {formatMessage({
                id: `checkout.bigSubscription.point5`,
              })}
            </Typography>
          </li>
        </ul>
        <Typography
          variant="h1"
          style={{ marginBottom: '0', marginTop: 'auto' }}
        >
          {subscriptions.big.price} {formatMessage({ id: 'curr.min' })}
        </Typography>
        <Typography variant="subtitle2" style={{ marginBottom: '16px' }}>
          {formatMessage({
            id: `checkout.pricePerMonth`,
          })}
        </Typography>
      </SubscriptionCard>
      <SubscriptionCard>
        <Typography variant="h4">
          {formatMessage({
            id: 'checkout.smallSubscription.title',
          })}
        </Typography>
        <div style={{ margin: '0 16px', height: '100px' }}>
          <Typography variant="body2">
            {formatMessage({
              id: 'checkout.smallSubscription.body',
            })}
          </Typography>
        </div>
        <ul style={{ marginRight: 'auto' }}>
          <li>
            <Typography variant="body2">
              {formatMessage({
                id: `checkout.bigSubscription.point1`,
              })}
            </Typography>
          </li>
          <li>
            <Typography variant="body2">
              {formatMessage({
                id: `checkout.bigSubscription.point2`,
              })}
            </Typography>
          </li>
        </ul>
        <Typography
          variant="h1"
          style={{ marginBottom: '0', marginTop: 'auto' }}
        >
          {subscriptions.small.price} {formatMessage({ id: 'curr.min' })}
        </Typography>
        <Typography variant="subtitle2" style={{ marginBottom: '16px' }}>
          {formatMessage({
            id: `checkout.pricePerMonth`,
          })}
        </Typography>
      </SubscriptionCard>
    </SubscriptionCardsWrapper>
  );
};

type Props = {
  subscriptions: Required<SubscriptionPackages> & { total: number };
  isLoadingProration: boolean;
  proration: SubscriptionProrationReturnType | null;
  handleUpdateSubscriptionsCount: (
    type: keyof SubscriptionPackages,
    operation: 'add' | 'remove'
  ) => void;
  handleConfirmProration: () => Promise<void>;
};

const Page = ({
  subscriptions,
  proration,
  isLoadingProration,
  handleUpdateSubscriptionsCount,
  handleConfirmProration,
}: Props) => {
  const { formatMessage } = useIntl();

  if (isLoadingProration) {
    return (
      <Wrapper>
        <Typography variant="h3">
          {formatMessage({
            id: 'subscription.seats.title',
          })}
        </Typography>
        <SubscriptionCards subscriptions={subscriptions} />

        <QuantityCards>
          <QuantityCard>
            <PrimaryButton style={{ fontSize: '1.5rem' }} disabled>
              -
            </PrimaryButton>
            <Typography variant="h1">{subscriptions.big.quantity}</Typography>
            <PrimaryButton style={{ fontSize: '1.5rem' }} disabled>
              +
            </PrimaryButton>
          </QuantityCard>
          <QuantityCard>
            <PrimaryButton style={{ fontSize: '1.5rem' }} disabled>
              -
            </PrimaryButton>
            <Typography variant="h1">{subscriptions.small.quantity}</Typography>
            <PrimaryButton style={{ fontSize: '1.5rem' }} disabled>
              +
            </PrimaryButton>
          </QuantityCard>
        </QuantityCards>

        <LoadingLogoWrapper>
          <LoadingLogo size="medium" />
        </LoadingLogoWrapper>
      </Wrapper>
    );
  }

  if (proration) {
    return (
      <Wrapper>
        <Typography variant="h3">
          {formatMessage({
            id: 'subscription.seats.title',
          })}
        </Typography>
        <SubscriptionCards subscriptions={subscriptions} />
        <QuantityCards>
          <QuantityCard>
            <PrimaryButton
              style={{ fontSize: '1.5rem' }}
              disabled={subscriptions.big.quantity < 1 || isLoadingProration}
              onClick={() => handleUpdateSubscriptionsCount('big', 'remove')}
            >
              -
            </PrimaryButton>
            <Typography variant="h1">{subscriptions.big.quantity}</Typography>
            <PrimaryButton
              style={{ fontSize: '1.5rem' }}
              disabled={isLoadingProration}
              onClick={() => handleUpdateSubscriptionsCount('big', 'add')}
            >
              +
            </PrimaryButton>
          </QuantityCard>
          <QuantityCard>
            <PrimaryButton
              style={{ fontSize: '1.5rem' }}
              disabled={subscriptions.small.quantity < 1 || isLoadingProration}
              onClick={() => handleUpdateSubscriptionsCount('small', 'remove')}
            >
              -
            </PrimaryButton>
            <Typography variant="h1">{subscriptions.small.quantity}</Typography>
            <PrimaryButton
              style={{ fontSize: '1.5rem' }}
              disabled={isLoadingProration}
              onClick={() => handleUpdateSubscriptionsCount('small', 'add')}
            >
              +
            </PrimaryButton>
          </QuantityCard>
        </QuantityCards>

        <InformationWrapper>
          <Typography>
            {`${formatMessage({
              id: 'checkout.title.infoText',
            })} `}

            <a
              href="https://www.agoy.se/pris/"
              target="_blank"
              rel="noreferrer"
            >
              {formatMessage({
                id: 'checkout.title.infoTextLink',
              })}
            </a>
          </Typography>
        </InformationWrapper>

        <Typography variant="h4">
          {formatMessage({ id: 'subscription.seats.information' })}
        </Typography>
        <Typography variant="h2" style={{ margin: '0 0' }}>
          {proration.nextInvoiceTotal} {formatMessage({ id: 'curr.min' })}
        </Typography>

        <Typography variant="h4">
          {formatMessage({ id: 'subscription.seats.monthly-charge' })}{' '}
          {new Date(proration.nextBillingCycle * 1000).toLocaleDateString(
            'sv-SE'
          )}
          :{' '}
        </Typography>
        <Typography variant="h2" style={{ marginTop: 0 }}>
          {proration.nextBillingCycleTotal} {formatMessage({ id: 'curr.min' })}
        </Typography>

        <Button
          onClick={handleConfirmProration}
          variant="contained"
          color="secondary"
          disableElevation
        >
          {formatMessage({ id: 'confirm' })}
        </Button>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Typography variant="h3">
        {formatMessage({
          id: 'subscription.seats.title',
        })}
      </Typography>
      <SubscriptionCards subscriptions={subscriptions} />

      <QuantityCards>
        <QuantityCard>
          <PrimaryButton
            disabled={subscriptions.big.quantity < 1 || isLoadingProration}
            onClick={() => handleUpdateSubscriptionsCount('big', 'remove')}
            style={{ fontSize: '1.5rem' }}
          >
            -
          </PrimaryButton>
          <Typography variant="h1">{subscriptions.big.quantity}</Typography>
          <PrimaryButton
            disabled={isLoadingProration}
            onClick={() => handleUpdateSubscriptionsCount('big', 'add')}
            style={{ fontSize: '1.5rem' }}
          >
            +
          </PrimaryButton>
        </QuantityCard>
        <QuantityCard>
          <PrimaryButton
            disabled={subscriptions.small.quantity < 1 || isLoadingProration}
            onClick={() => handleUpdateSubscriptionsCount('small', 'remove')}
            style={{ fontSize: '1.5rem' }}
          >
            -
          </PrimaryButton>
          <Typography variant="h1">{subscriptions.small.quantity}</Typography>
          <PrimaryButton
            disabled={isLoadingProration}
            onClick={() => handleUpdateSubscriptionsCount('small', 'add')}
            style={{ fontSize: '1.5rem' }}
          >
            +
          </PrimaryButton>
        </QuantityCard>
      </QuantityCards>

      <InformationWrapper>
        <Typography>
          {`${formatMessage({
            id: 'checkout.title.infoText',
          })} `}

          <a href="https://www.agoy.se/pris/">
            {formatMessage({
              id: 'checkout.title.infoTextLink',
            })}
          </a>
        </Typography>
      </InformationWrapper>

      <Typography variant="h1" style={{ marginBottom: '0' }}>
        Total: {subscriptions.total} {formatMessage({ id: 'curr.min' })}
      </Typography>
      <Typography variant="subtitle2" style={{ marginBottom: '16px' }}>
        {formatMessage({
          id: `checkout.pricePerMonth`,
        })}
      </Typography>
    </Wrapper>
  );
};

export default Page;

import React, { useCallback, useContext, useState } from 'react';
import { Box, IconButton, Paper, Tooltip, Typography } from '@material-ui/core';
import PrintIcon from '@material-ui/icons/Print';
import ResetIcon from '@material-ui/icons/Restore';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { currentParticularSalaryOtherPensionCommitments } from '_tax/redux/tax-view/selectors';
import ResetContentDialog from '_shared/components/ResetContent/ResetContentDialog';
import EditingIconButton from '_shared/components/Buttons/EditingIconButton';
import { printDocument } from '_shared/components/PrintedDocument/utils';
import TaxesDataContext from '_tax/service/TaxesDataContext';
import usePrintState from '_tax/hooks/usePrintState';
import { addGlobalErrorMessage } from 'redux/actions';

import { Row } from '../../ParticularSalaryPanels/Layout';
import Header from '../../Header';
import TaxDocumentTable from '../../TaxDocumentTable';
import TaxDocumentNoteRow from '../../TaxDocumentNoteRow';

const PrintButton = styled(IconButton)`
  &:hover {
    color: ${(props) => props.theme.palette.secondary.main};
  }
`;

const Container = styled.div`
  padding: 0 20px;
`;

type ViewPensionCommitmentsProps = {
  isPrint?: boolean;
};

const ViewPensionCommitments = ({
  isPrint = false,
}: ViewPensionCommitmentsProps) => {
  const { formatMessage } = useIntl();
  const printState = usePrintState();
  const dispatch = useDispatch();

  const [editing, setEditing] = useState(false);
  const [printLoading, setPrintLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const { taxYearData, service, clientId, financialYear } =
    useContext(TaxesDataContext);

  const toggleEditing = useCallback(() => {
    setEditing((value) => !value);
  }, [setEditing]);

  const onHandleReset = useCallback(() => {
    service.resetDocumentPart('particularSalaryTax.otherPensionCommitments');
  }, [service]);

  const handlePrint = useCallback(async () => {
    try {
      setPrintLoading(true);
      await printDocument(
        clientId,
        financialYear,
        'taxes',
        formatMessage({
          id: 'tax.view.other.pension.tittle',
        }),
        ['pensionCommitments'],
        printState
      );
    } catch (e) {
      dispatch(addGlobalErrorMessage('error'));
    } finally {
      setPrintLoading(false);
    }
  }, [clientId, dispatch, financialYear, formatMessage, printState]);

  const onConfirm = () => {
    onHandleReset();
    setOpen(false);
  };

  const state = useSelector(currentParticularSalaryOtherPensionCommitments);

  if (!state) {
    return null;
  }

  const {
    paymentPensionFoundation,
    paymentPensionFoundationNote,
    compensationPensionCommitments,
    compensationPensionCommitmentsNote,
    pensionProvisions,
    pensionProvisionsNote,
  } = state;

  return (
    <Box maxWidth={650} marginTop={5}>
      <Paper>
        <Header
          mainTable
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p={2}
        >
          <Typography variant="h2">
            {formatMessage({ id: `tax.view.other.pension.tittle` })}
          </Typography>
          {!isPrint && (
            <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
              <EditingIconButton value={editing} onClick={toggleEditing} />
              <Tooltip title={formatMessage({ id: 'tax.table.reset.tooltip' })}>
                <IconButton
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  <ResetIcon />
                </IconButton>
              </Tooltip>
              <ResetContentDialog
                what={formatMessage({ id: `tax.particularSalaryTax.title` })}
                open={open}
                onClose={() => {
                  setOpen(false);
                }}
                onConfirm={onConfirm}
              />

              <PrintButton onClick={handlePrint} disabled={printLoading}>
                <PrintIcon />
              </PrintButton>
            </Box>
          )}
        </Header>
        <Container>
          <Typography
            style={{ marginTop: '5px', paddingLeft: '8px' }}
            variant="h6"
          >
            {formatMessage({ id: 'tax.view.other.pension.row.label' })}
          </Typography>

          {taxYearData?.state && (
            <Row noMargin>
              <TaxDocumentTable
                baseId="taxViewDocument"
                tableId="particularSalaryTax.otherPensionCommitments.otherPensionInsurances"
                editing={editing}
                rows={
                  taxYearData.state.document.particularSalaryTax
                    .otherPensionCommitments.otherPensionInsurances.rows
                }
                columns={
                  taxYearData.state.document.particularSalaryTax
                    .otherPensionCommitments.otherPensionInsurances.columns
                }
                isPrint={isPrint}
              />
            </Row>
          )}

          <Typography
            style={{ marginTop: '5px', paddingLeft: '8px' }}
            variant="h6"
          >
            {formatMessage({ id: 'tax.view.foreign.pension.row.label' })}
          </Typography>

          {taxYearData?.state && (
            <Row noMargin>
              <TaxDocumentTable
                baseId="taxViewDocument"
                tableId="particularSalaryTax.otherPensionCommitments.foreignPensionInsurances"
                editing={editing}
                rows={
                  taxYearData.state.document.particularSalaryTax
                    .otherPensionCommitments.foreignPensionInsurances.rows
                }
                columns={
                  taxYearData.state.document.particularSalaryTax
                    .otherPensionCommitments.foreignPensionInsurances.columns
                }
                isPrint={isPrint}
              />
            </Row>
          )}
          <div>
            <TaxDocumentNoteRow
              inputCellId="particularSalaryTax.otherPensionCommitments.paymentPensionFoundation"
              inputCell={paymentPensionFoundation}
              noteCellId="particularSalaryTax.otherPensionCommitments.paymentPensionFoundationNote"
              noteCell={paymentPensionFoundationNote}
              editing={editing}
              labelId="tax.view.payment.pension.value.label"
            />

            <TaxDocumentNoteRow
              inputCellId="particularSalaryTax.otherPensionCommitments.compensationPensionCommitments"
              inputCell={compensationPensionCommitments}
              noteCellId="particularSalaryTax.otherPensionCommitments.compensationPensionCommitmentsNote"
              noteCell={compensationPensionCommitmentsNote}
              editing={editing}
              labelId="tax.view.compensation.value.label"
            />

            <TaxDocumentNoteRow
              inputCellId="particularSalaryTax.otherPensionCommitments.pensionProvisions"
              inputCell={pensionProvisions}
              noteCellId="particularSalaryTax.otherPensionCommitments.pensionProvisionsNote"
              noteCell={pensionProvisionsNote}
              editing={editing}
              labelId="tax.view.pension.provision.value.label"
            />
          </div>
        </Container>
      </Paper>
    </Box>
  );
};

export default ViewPensionCommitments;

import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useSelector } from 'redux/reducers';
import { setCurrentPeriod } from 'redux/actions';
import { PeriodTimeline, PeriodItem } from '_shared/components/PeriodTimeline';
import { colors as ThemeColors } from '@agoy/theme';
import { ProgramStatusName } from '@agoy/program-status';
import { Period } from '@agoy/api-sdk-core';
import { parseFormat } from '@agoy/dates';
import { IntlShape, useIntl } from 'react-intl';
import { Program } from '_shared/types';

/**
 * Generates the periods (months) between start and end
 *
 * @param periods Map of period with status
 * @param icon The icon used in the program
 * @returns Array of periods with statusColor and label
 */
const decoratePeriods = (
  program: Program,
  periods: Array<Period & { status: ProgramStatusName }>,
  formatMessage: IntlShape['formatMessage'],
  individualCompany?: boolean
): PeriodItem[] => {
  if (!Object.keys(periods).length) return [];

  const decorated: PeriodItem[] = [];
  // eslint-disable-next-line array-callback-return
  periods.map((period) => {
    const statusColor = ThemeColors[period.status];
    const monthLabel = parseFormat(period.start, 'MMM-yy');
    const yearLabelId =
      individualCompany && program === 'AN_REPORT'
        ? `header.timeline.AN_REPORT.yearEndPeriodIndividual`
        : `header.timeline.${program}.yearEndPeriod`;
    decorated.push({
      period: parseFormat(period.start, 'yyyyMMdd'),
      statusColor,
      label:
        period.type === 'month'
          ? monthLabel
          : formatMessage({ id: yearLabelId }),
      locked: period.status === 'LOCKED',
    });
  });

  return decorated;
};
interface PeriodSelectorProps {
  program: Program;
  programPeriods: Array<Period & { status: ProgramStatusName }>;
  icon: JSX.Element;
  individualCompany?: boolean;
}

export const TimelinePeriodSelector = ({
  program,
  programPeriods,
  icon,
  individualCompany,
}: PeriodSelectorProps): JSX.Element => {
  const { currentPeriod } = useSelector((state) => state.customerView);

  const { formatMessage } = useIntl();

  const dispatch = useDispatch();
  const handleChangePeriod = useCallback(
    (period) => {
      dispatch(setCurrentPeriod(period));
    },
    [dispatch]
  );

  if (!programPeriods) return <></>;
  const periods = decoratePeriods(
    program,
    programPeriods,
    formatMessage,
    individualCompany
  );

  return (
    <PeriodTimeline
      periods={periods}
      selectedPeriod={currentPeriod}
      onPeriodClick={handleChangePeriod}
      icon={icon}
    />
  );
};

export default TimelinePeriodSelector;

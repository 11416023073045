import React from 'react';
import { useIntl } from 'react-intl';
import { Field } from '@agoy/document';
import Heading from '../../../UI/Heading';
import { IXElement } from '../../../ARKeyToIX';

type HeaderProps = {
  noteId: string;
  noteNumber: number | undefined;
  title?: string;
  field?: Field;
};

const Header = ({ noteId, noteNumber, title, field }: HeaderProps) => {
  const { formatMessage } = useIntl();

  const titleValue = `${noteNumber || ''} ${
    title ||
    formatMessage({
      id: `annualReport.menu.notes.${noteId}`,
    })
  }`;

  return field && title ? (
    <IXElement arPart={field}>
      <Heading title={titleValue} />
    </IXElement>
  ) : (
    <Heading title={titleValue} />
  );
};

export default Header;

import { value, table } from '@agoy/document';
import { NoteOneTableSection, IxbrlCell } from '../../../../common';
import {
  active,
  field,
  ixbrlCell,
  ixbrlField,
  tupleRef,
} from '../../../../common/utils/util';

export const getNote68 = (documentTypeVersion = '1'): NoteOneTableSection => {
  return {
    active: false,
    number: value(undefined),
    name: value('Upplysning om moderföretag'),
    table: table<IxbrlCell>(
      `notes.note68.table`,
      {
        id: 'companyName',
        label: 'Namn',
      },
      {
        id: 'orgNumber',
        label: 'Organisationsnummer',
      },
      {
        id: 'companyPlace',
        label: 'Säte',
      }
    )
      .addRows((rows) =>
        rows
          .addRow(
            '1',
            ixbrlCell(value(''), {
              type: 'stringItemType',
              name: 'se-cd-base:ForetagetsNamn',
              contextRef: 'period0',
              tuple: {
                name: 'se-gaap-ext:UppgiftNarmasteModerforetagTuple',
                id: `${rows.getBaseId()}.1.tuple`,
                tupleID: `${rows.getBaseId()}.1.tuple`,
              },
              tupleRef: tupleRef(1, `${rows.getBaseId()}.1.tuple`),
            }),
            ixbrlCell(value(''), {
              type: 'stringItemType',
              name: 'se-cd-base:Organisationsnummer',
              contextRef: 'period0',
              tupleRef: tupleRef(2, `${rows.getBaseId()}.1.tuple`),
            }),
            ixbrlCell(value(''), {
              type: 'stringItemType',
              name: 'se-cd-base:ForetagetsSate',
              contextRef: 'period0',
              tupleRef: tupleRef(3, `${rows.getBaseId()}.1.tuple`),
            })
          )
          .build()
      )
      .newRowTemplateGenerator((id, baseId) => ({
        id,
        active: true,
        cells: {
          companyName: ixbrlCell(value(''), {
            type: 'stringItemType',
            name: 'se-cd-base:ForetagetsNamn',
            contextRef: 'period0',
            tuple: {
              name: 'se-gaap-ext:UppgiftNarmasteModerforetagTuple',
              id: `${baseId}.${id}.tuple`,
              tupleID: `${baseId}.${id}.tuple`,
            },
            tupleRef: tupleRef(1, `${baseId}.${id}.tuple`),
          }),
          orgNumber: ixbrlCell(value(''), {
            type: 'stringItemType',
            name: 'se-cd-base:Organisationsnummer',
            contextRef: 'period0',
            tupleRef: tupleRef(2, `${baseId}.${id}.tuple`),
          }),
          companyPlace: ixbrlCell(value(''), {
            type: 'stringItemType',
            name: 'se-cd-base:ForetagetsSate',
            contextRef: 'period0',
            tupleRef: tupleRef(3, `${baseId}.${id}.tuple`),
          }),
        },
      }))
      .build(),
    commentLabel: field('Kommentar'),
    comment: ixbrlField(active(field('')), {
      type: 'stringItemType',
      name: 'se-gen-base:NotUpplysningModerforetagKommentar',
      contextRef: 'period0',
    }),
    group: value(undefined),
    section: value(undefined),
    validForReportType: {
      k2: true,
      k3: documentTypeVersion === '1',
    },
  };
};

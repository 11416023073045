import React, { useCallback, useState, useEffect, useContext } from 'react';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';
import { useApiSdk } from 'api-sdk';
import { Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { useDispatch } from 'react-redux';

import { Period } from '@agoy/api-sdk-core';
import { addGlobalErrorMessage, addGlobalMessage } from 'redux/actions';
import EditingIconButton from '_shared/components/Buttons/EditingIconButton';
import ResetContentButton from '_shared/components/ResetContent/ResetContentButton';
import CommonPreviewModal from '_shared/components/CommonPreviewModal';
import PrintButton from '_shared/components/Buttons/PrintButton';
import { useAccountDocuments } from 'utils/usePeriodDocuments';
import { useSelector } from 'redux/reducers';
import Button from '_shared/components/Buttons/Button';
import { printDocument } from '_shared/components/PrintedDocument/utils';
import { parseFormat } from '@agoy/dates';

import LagerjusteringTable from './LagerjusteringTable';
import LagerjusteringTablePreview from './LagerjusteringTablePreview';
import { InventoryObsolescenceDataType } from './types';
import PeriodDataContext from '../PeriodDataContext';
import { usePrintState } from '../../utils';

type Props = {
  defaultData: InventoryObsolescenceDataType;
  data: InventoryObsolescenceDataType;
  onChangeData: (data: InventoryObsolescenceDataType) => void;
  ub: number | undefined;
  period: Period;
  handleStockValueChange: (value: number) => void;
};

const Wrapper = styled.div`
  display: flex;
  min-width: 1000px;
  width: 100%;
  flex-direction: column;
  border-right: 1px solid ${(props) => props.theme.palette.grey[400]};
  flex: 1;
`;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px 30px;
  gap: ${({ theme }) => theme.spacing(1)}px;
`;

const MessageWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  border: 2px solid #eeeeee;
  background-color: ${(props) => props.theme.palette.grey[100]};
  margin-bottom: ${({ theme }) => theme.spacing(3)}px;
  padding: 10px;
  border-radius: 4px;
`;

const ErrorAlert = styled(Alert)`
  margin-bottom: 20px;
`;

const Message = styled(Typography)`
  color: ${(props) => props.theme.palette.grey[600]};
`;

const TableTitle = styled(Typography)`
  font-size: 1.5rem;
  font-weight: 500;
  color: ${(props) => props.theme.palette.accountingView.headers.main};
`;

const TableHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
`;

const InfoIcon = styled(InfoOutlinedIcon)`
  height: 20px;
  margin-right: 10px;
  color: ${(props) => props.theme.palette.grey[600]};
`;

const LagerjusteringTableView = ({
  defaultData,
  data,
  onChangeData,
  ub,
  period,
  handleStockValueChange,
}: Props) => {
  const { formatMessage } = useIntl();
  const printState = usePrintState();
  const { createAndAddDocument } = useAccountDocuments();

  const [isError, setError] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isEditing, setEditing] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const [isDataUpdated, setIsDataUpdated] = useState(false);
  const [isPrintLoading, setIsPrintLoading] = useState(false);

  const sdk = useApiSdk();
  const dispatch = useDispatch();

  const { clientId, financialYear } = useContext(PeriodDataContext);

  const customer = useSelector((state) => state.customers[clientId]);

  const handleUpdateLagerjusteringData = useCallback(
    async (rowId, cell, value) => {
      if (rowId && cell) {
        if (
          cell === 'reference' &&
          rowId === '2' &&
          (value > 0.03 || value < 0)
        ) {
          setIsDataUpdated(false);
          onChangeData({
            ...data,
            rate: data.rate,
          });
          if (data.rate !== value) {
            setIsDataUpdated(true);
          } else {
            setIsDataUpdated(false);
          }
          setError(true);
          return;
        }
        if (cell === 'reference' && rowId === '2') {
          onChangeData({
            ...data,
            rate: value,
          });
          if (data.rate !== value) {
            setIsDataUpdated(true);
          } else {
            setIsDataUpdated(false);
          }
          setError(false);
        } else {
          onChangeData({
            ...data,
            [cell]: value,
          });
          if (data[cell] !== value) {
            setIsDataUpdated(true);
          } else {
            setIsDataUpdated(false);
          }
        }
      }
    },
    [data, onChangeData]
  );

  useEffect(() => {
    setError(false);
  }, [isEditing]);

  useEffect(() => {
    if (!isEditing) {
      setIsDataUpdated(false);
    }
    if (!isEditing && isDataUpdated && !isError) {
      sdk.updateInventoryObsolescence({
        clientId,
        requestBody: data,
      });
    }
  }, [isEditing, isDataUpdated]);

  const handleReset = useCallback(async () => {
    onChangeData(defaultData);
    setError(false);
    setIsDataUpdated(true);
    await sdk.updateInventoryObsolescence({
      clientId,
      requestBody: defaultData,
    });
  }, []);

  const handleShowPreview = (value: boolean) => {
    setOpenPreview(value);
  };

  const handlePrint = async () => {
    if (!customer) {
      return;
    }

    try {
      setIsPrintLoading(true);

      const finYearInterval = `${parseFormat(
        financialYear.start,
        'yyyyMM'
      )}-${parseFormat(financialYear.end, 'yyyyMM')}`;

      const docName = `${formatMessage({
        id: 'hidden.lagerjustering.title',
      })} ${data.account} ${customer.name} ${period.start}.pdf`;

      await printDocument(
        clientId,
        finYearInterval,
        'accounting',
        docName,
        ['lagerjustering'],
        { ...printState, inventoryObsolescenceData: { data, ub } }
      );
    } catch (e) {
      dispatch(addGlobalErrorMessage('error'));
    } finally {
      setIsPrintLoading(false);
    }
  };

  const handleSavePdf = async () => {
    if (!customer) {
      return;
    }

    try {
      setIsSaving(true);
      const docName = `${formatMessage({
        id: 'hidden.lagerjustering.title',
      })} ${data.account} ${customer.name} ${period.start}.pdf`;

      await createAndAddDocument({
        name: docName,
        docType: 'accounting',
        footer: '',
        header: '',
        parts: ['lagerjustering'],
        printState: { ...printState, inventoryObsolescenceData: { data, ub } },
      });

      const messageKey =
        'inventoryObsolescence.table.saveToPeriodDocuments.success';
      dispatch(addGlobalMessage('success', messageKey));
    } catch (e) {
      dispatch(addGlobalErrorMessage('error'));
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <>
      {openPreview && (
        <CommonPreviewModal
          isOpen={openPreview}
          loading={isPrintLoading}
          handleShow={handleShowPreview}
          onPrint={handlePrint}
        >
          <LagerjusteringTablePreview data={data} ub={ub} />
        </CommonPreviewModal>
      )}
      <Wrapper>
        <TableWrapper>
          <TableHeaderWrapper>
            <TableTitle>
              {formatMessage({
                id: `hidden.lagerjustering.view`,
              })}
            </TableTitle>
            <Buttons>
              <EditingIconButton
                value={isEditing}
                onClick={() => setEditing(!isEditing)}
              />
              <PrintButton
                onClick={() => setOpenPreview(true)}
                tooltip="hidden.lagerjustering.print"
              />
              <ResetContentButton what="Lagerjustering" onReset={handleReset} />
            </Buttons>
          </TableHeaderWrapper>
          <MessageWrapper>
            <InfoIcon />
            <Message>
              {formatMessage({
                id: `hidden.lagerjustering.message`,
              })}
            </Message>
          </MessageWrapper>
          {isError && (
            <ErrorAlert severity="error">
              {formatMessage({
                id: 'hidden.lagerjustering.rate.error',
              })}
            </ErrorAlert>
          )}
          <LagerjusteringTable
            data={data}
            handleUpdateData={handleUpdateLagerjusteringData}
            ub={ub}
            isEditing={isEditing}
            handleStockValueChange={handleStockValueChange}
          />
          <Button
            label={formatMessage({
              id: 'inventoryObsolescence.table.saveToPeriodDocuments',
            })}
            onClick={() => handleSavePdf()}
            disabled={isError}
            loading={isSaving}
            startIcon={<AttachFileIcon />}
          />
        </TableWrapper>
      </Wrapper>
    </>
  );
};

export default LagerjusteringTableView;

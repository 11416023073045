import * as React from 'react';

function useSafeDispatch(dispatch) {
  const mounted = React.useRef(false);

  // @ts-ignore
  React.useLayoutEffect(() => {
    mounted.current = true;
    // eslint-disable-next-line no-return-assign
    return () => (mounted.current = false);
  }, []);

  return React.useCallback(
    (...args) => (mounted.current ? dispatch(...args) : null),
    [dispatch]
  );
}

type AnyFunction = (...args: any[]) => any;

export type AsyncHookReturn<T> = {
  isIdle: boolean;
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  setData: (data: T) => void;
  setError: (error: Error) => void;
  error: Error;
  status: 'idle' | 'pending' | 'rejected' | 'resolved';
  data: T;
  run: (promise: Promise<T>) => Promise<T>;
  reset: () => void;
};

const defaultInitialState = { status: 'idle', data: null, error: null };

/**
 * Inspired by https://github.com/kentcdodds/bookshelf
 */
function useAsync<T>(initialState?: T) {
  const initialStateRef = React.useRef({
    ...defaultInitialState,
    ...initialState,
  });
  const [{ status, data, error }, setState] = React.useReducer(
    (s, a) => ({ ...s, ...a }),
    initialStateRef.current
  );

  const safeSetState = useSafeDispatch(setState);

  const setData = React.useCallback(
    (data) => safeSetState({ data, status: 'resolved' }),
    [safeSetState]
  );
  const setError = React.useCallback(
    (error) => safeSetState({ error, status: 'rejected' }),
    [safeSetState]
  );
  const reset = React.useCallback(
    () => safeSetState(initialStateRef.current),
    [safeSetState]
  );

  /**
   * Run a promise and set data to the promise's return value.
   */
  const run = React.useCallback(
    (promise: Promise<T>) => {
      if (!promise || !promise.then) {
        throw new Error(
          `The argument passed to useAsync().run must be a promise. Maybe a function that's passed isn't returning anything?`
        );
      }
      safeSetState({ status: 'pending' });
      return promise.then(
        (data: T) => {
          setData(data);
          return data;
        },
        (error) => {
          setError(error);
          return Promise.reject(error);
        }
      );
    },
    [safeSetState, setData, setError]
  );

  const ret: AsyncHookReturn<T> = {
    // using the same names that react-query uses for convenience
    isIdle: status === 'idle',
    isLoading: status === 'pending',
    isError: status === 'rejected',
    isSuccess: status === 'resolved',

    setData,
    setError,
    error,
    status,
    data,
    run,
    reset,
  };

  return ret;
}

export { useAsync };

import { AgoyTable, AgoyTableRow, stringValue } from '@agoy/document';
import {
  TaxDeclarationFormData,
  TaxDeclarationFormPartData,
} from '@agoy/tax-declaration-document';
import { CompanyType } from '_clients/types/types';

export type TableWithId = { table: AgoyTable; id: string };

export const getTableInnerRows = (table: TableWithId) => {
  return table.table.rows[0].rows?.filter((row) => row.active) ?? [];
};

export const getTableInnerRowsCount = (table?: TableWithId) => {
  return table ? getTableInnerRows(table).length ?? 0 : 0;
};

export const sortTableRows = (currentRows: AgoyTableRow[]) => {
  const newRows: AgoyTableRow[] = [];
  const newRowsWithSortKey: AgoyTableRow[] = [];

  currentRows.forEach((item) => {
    if (typeof item.sortKey === 'number') {
      newRowsWithSortKey.push({
        ...item,
      });
    } else {
      newRows.push({
        ...item,
      });
    }
  });

  newRowsWithSortKey.sort((a, b) => (a.sortKey || 0) - (b.sortKey || 0));
  newRowsWithSortKey.forEach((item) => {
    newRows.splice(item.sortKey || 0, 0, item);
  });

  return newRows;
};

export const copyTaxTableWithNewRows = (
  table: TableWithId,
  rows: AgoyTableRow[]
) => {
  return {
    ...table,
    table: {
      ...table.table,
      rows: [
        {
          ...table.table.rows[0],
          rows: [...rows],
        },
        table.table.rows[1],
      ],
    },
  };
};

export const copyTaxTableAndSortRows = (table: TableWithId) => {
  return copyTaxTableWithNewRows(
    table,
    sortTableRows(getTableInnerRows(table))
  );
};

export const takeNRows = (table: TableWithId, n: number, start = 0) => {
  return {
    rest: copyTaxTableWithNewRows(
      table,
      table.table.rows[0].rows?.slice(start + n) ?? []
    ),
    result: copyTaxTableWithNewRows(
      table,
      table.table.rows[0].rows?.slice(start, start + n) ?? []
    ),
  };
};

export const calculateTaxTableBuffer = (
  tables: TableWithId[],
  table: TableWithId,
  minItems: number,
  maxItems: number
) => {
  const rawTable = tables.find((raw) => raw.id === table.id);
  const innerRowsTotalCount = getTableInnerRowsCount(rawTable);

  if (innerRowsTotalCount % maxItems < minItems) {
    return minItems - (innerRowsTotalCount % maxItems);
  }

  return 0;
};

export const splitTaxTableInChunks = (
  tables: TableWithId[],
  rawTable: TableWithId,
  minItems: number,
  maxItems: number
) => {
  const chunks: TableWithId[] = [];
  const { table } = copyTaxTableAndSortRows(rawTable);

  if (table.rows[0].rows) {
    let chunkIdx = 0;
    const buffer = calculateTaxTableBuffer(
      tables,
      rawTable,
      minItems,
      maxItems
    );

    return table.rows[0].rows.reduce((acc, r) => {
      if (!acc[chunkIdx]) acc[chunkIdx] = [];

      if (acc[chunkIdx].length < maxItems - buffer) {
        acc[chunkIdx].push(r);
      } else {
        chunkIdx += 1;
        acc[chunkIdx] = [r];
      }

      return acc;
    }, [] as AgoyTableRow[][]);
  }

  return chunks;
};

export const generateTaxCalculationPages = (
  tables: TableWithId[],
  minItems: number,
  maxItems: number,
  isPrint = false
) => {
  const newTables: TableWithId[] = [];
  const newPages: TableWithId[][] = [];

  tables.forEach((table) => {
    const chunks = splitTaxTableInChunks(tables, table, minItems, maxItems);

    if (chunks.length > 0 || isPrint) {
      newTables.push(
        ...chunks.map((chunk) => {
          return copyTaxTableWithNewRows(table, chunk);
        })
      );
    } else {
      newTables.push(...[copyTaxTableWithNewRows(table, [])]);
    }
  });

  let pageIdx = 0;
  let pageCurrentCapacity = 0;

  newTables.forEach((table) => {
    const innerRowsCount = getTableInnerRowsCount(table);
    const buffer = calculateTaxTableBuffer(tables, table, minItems, maxItems);
    const spaceLeft = maxItems - pageCurrentCapacity - buffer;

    if (!newPages[pageIdx]) {
      newPages[pageIdx] = [];
    }

    if (innerRowsCount <= spaceLeft) {
      newPages[pageIdx].push(table);
      pageCurrentCapacity += innerRowsCount;
    } else {
      const split = takeNRows(table, spaceLeft);

      if (
        getTableInnerRowsCount(split.result) < minItems ||
        getTableInnerRowsCount(split.rest) < minItems
      ) {
        pageIdx += 1;
        newPages[pageIdx] = [table];
        pageCurrentCapacity = innerRowsCount;
      } else {
        newPages[pageIdx].push(split.result);
        pageIdx += 1;
        newPages[pageIdx] = [split.rest];
        pageCurrentCapacity = getTableInnerRowsCount(split.rest);
      }
    }
  });

  return newPages.filter((page) => page.length);
};

export const isTableLastAcrossPages = (
  pages: TableWithId[][],
  tableId: string,
  pageIdx: number
) => {
  return !pages
    .slice(pageIdx + 1)
    .some((page) => page.map((table) => table.id).includes(tableId));
};

const extractFieldFromDocument = (
  document: TaxDeclarationFormData,
  field: string
) => {
  return stringValue(
    (document.finalTaxCalculations as TaxDeclarationFormPartData)[field]
  );
};

export const extractCompanyTypeFromDocument = (
  document: TaxDeclarationFormData
): CompanyType => {
  return (
    (extractFieldFromDocument(document, 'companyType') as CompanyType) ??
    'other'
  );
};

export const extractDatesFromDocument = (document: TaxDeclarationFormData) => {
  return {
    startDate: extractFieldFromDocument(document, 'financialYearStart'),
    endDate: extractFieldFromDocument(document, 'financialYearEnd'),
  };
};

export const extractOrgNumberFromDocument = (
  document: TaxDeclarationFormData
) => {
  return extractFieldFromDocument(document, 'orgNumber');
};

export const extractMetadataFromDocument = (
  document: TaxDeclarationFormData
) => {
  return {
    orgNumber: extractOrgNumberFromDocument(document),
    companyType: extractCompanyTypeFromDocument(document),
    ...extractDatesFromDocument(document),
  };
};

import React, { useMemo, useContext } from 'react';
import { AccountingPeriod } from '@agoy/api-sdk-core';
import PrintStateContext from '_shared/components/PrintedDocument/PrintStateContext';
import PropertyTaxTable from './PropertyTaxTable';

type PropertyTaxTablePrintProps = {
  currentPeriod: AccountingPeriod;
};

const PropertyTaxTablePrint = ({
  currentPeriod,
}: PropertyTaxTablePrintProps) => {
  const year = useMemo(
    () => currentPeriod?.start.slice(0, 4),
    [currentPeriod?.start]
  );

  const {
    state: { propertyTaxData },
  } = useContext(PrintStateContext);

  if (!propertyTaxData) {
    return null;
  }

  const {
    total,
    currentYearRows,
    currentYearColumns,
    mainFhsValue,
    alternativeFhsValue,
  } = propertyTaxData;

  return (
    <PropertyTaxTable
      isPrint
      isEditing={false}
      rows={currentYearRows}
      columns={currentYearColumns}
      currentFinancialYear={year}
      totalAmount={total}
      mainFhsAmount={mainFhsValue}
      alternativeFhsAmount={alternativeFhsValue}
      onUploadRowsUpdate={() => {}}
      setCurrentYearRows={() => {}}
      onDataChange={() => {}}
      onColumnsUpdate={() => {}}
      handleAmountCalculations={() => {}}
    />
  );
};

export default PropertyTaxTablePrint;

import React, { useContext, useMemo, useRef } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import styled from '@emotion/styled';
import PrintStateContext from '_shared/components/PrintedDocument/PrintStateContext';
import AdditionalPage from '_tax/components/TaxDeclarationView/EditForm/AdditionalPages/AdditionalPage';
import {
  TableWithId,
  extractMetadataFromDocument,
  generateTaxCalculationPages,
  isTableLastAcrossPages,
} from '_tax/components/TaxDeclarationView/EditForm/AdditionalPages/utils';
import RenovationAccountPrint from '_tax/components/TaxDeclarationView/EditForm/HelperView/RenovationAccountPrint';
import RenovationTablePrint from '_tax/components/TaxDeclarationView/EditForm/HelperView/RenovationTablePrint';
import ImprovementsAccountPrint from '_tax/components/TaxDeclarationView/EditForm/HelperView/ImprovementsAccountPrint';
import ImprovementsTablePrint from '_tax/components/TaxDeclarationView/EditForm/HelperView/ImprovementsTablePrint';

const Page = styled(({ children, ...props }) =>
  React.Children.count(children) > 0 ? <div {...props}>{children}</div> : null
)`
  page-break-before: always;
  width: 842px;

  @media screen {
    border-bottom: 1px dashed ${(props) => props.theme.palette.grey['500']};
    padding: 32px 32px;
    width: 864px;
  }
`;

interface TaxesFormPrintProps {
  pageNumbers: Record<string, number>;
}

const TaxesFormPrint = ({
  pageNumbers,
}: TaxesFormPrintProps): JSX.Element | null => {
  const { path } = useRouteMatch();
  const {
    state: { additionalPages, taxFormHelpData },
  } = useContext(PrintStateContext);
  const paper = useRef<HTMLDivElement | null>(null);

  const paperWidth = paper?.current?.clientWidth ?? 900;
  const maxAvailableHeight = (paperWidth - 200) * 1.41;
  const maxItems = Math.floor(maxAvailableHeight / 45);

  const pages = useMemo(() => {
    const result: TableWithId[] = [];

    if (!additionalPages) {
      return [];
    }
    const {
      document: { finalTaxCalculations },
    } = additionalPages;

    Object.keys(finalTaxCalculations).forEach((key) => {
      if (
        typeof finalTaxCalculations[key] === 'object' &&
        'rows' in finalTaxCalculations[key] &&
        'columns' in finalTaxCalculations[key]
      ) {
        result.push({
          id: `finalTaxCalculations.${key}`,
          table: finalTaxCalculations[key],
        });
      }
    });

    return generateTaxCalculationPages(result, 3, maxItems, true);
  }, [maxItems, additionalPages]);

  return (
    <Switch>
      <Route path={`${path}/index`}>
        <Page>
          {!!additionalPages &&
            pages.map((page, i) => {
              const { startDate, endDate, companyType, orgNumber } =
                extractMetadataFromDocument(additionalPages.document);
              return (
                <Page
                  key={`tables_${i}_${page.map((table) => table.id).join('-')}`}
                  ref={paper}
                >
                  <AdditionalPage
                    page={page}
                    currentPageNumber={i + 1}
                    totalPageNumber={pages.length}
                    showSumRow={(table) =>
                      isTableLastAcrossPages(pages, table.id, i)
                    }
                    isPrint
                    orgNr={orgNumber}
                    companyType={companyType}
                    startDate={startDate}
                    endDate={endDate}
                  />
                </Page>
              );
            })}
        </Page>
      </Route>
      <Route path={`${path}/renovationAccount`}>
        {taxFormHelpData && (
          <Page>
            <RenovationAccountPrint {...taxFormHelpData} />
          </Page>
        )}
      </Route>
      <Route path={`${path}/renovationTable`}>
        {taxFormHelpData && (
          <Page>
            <RenovationTablePrint {...taxFormHelpData} />
          </Page>
        )}
      </Route>
      <Route path={`${path}/improvementsAccount`}>
        {taxFormHelpData && (
          <Page>
            <ImprovementsAccountPrint {...taxFormHelpData} />
          </Page>
        )}
      </Route>
      <Route path={`${path}/improvementsTable`}>
        {taxFormHelpData && (
          <Page>
            <ImprovementsTablePrint {...taxFormHelpData} />
          </Page>
        )}
      </Route>

      <Route path={path} exact>
        {'renovationAccount' in pageNumbers && taxFormHelpData && (
          <Page>
            <RenovationAccountPrint {...taxFormHelpData} />
          </Page>
        )}
        {'renovationTable' in pageNumbers && taxFormHelpData && (
          <Page>
            <RenovationTablePrint {...taxFormHelpData} />
          </Page>
        )}
        {'improvementsAccount' in pageNumbers && taxFormHelpData && (
          <Page>
            <ImprovementsAccountPrint {...taxFormHelpData} />
          </Page>
        )}
        {'improvementsTable' in pageNumbers && taxFormHelpData && (
          <Page>
            <ImprovementsTablePrint {...taxFormHelpData} />
          </Page>
        )}
      </Route>
    </Switch>
  );
};

export default TaxesFormPrint;

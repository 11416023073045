import { AgoyTableRow, NumberCell, numberValue } from '@agoy/document';
import { StockInventoryTable } from '@agoy/api-sdk-core';
import { DEFAULT_DATA, defaultColumnTypes } from './types';

export const generateDefaultDataWithNEmptyRows = (
  n: number
): StockInventoryTable => {
  return {
    headers: DEFAULT_DATA.headers,
    rows: Array(n).fill(
      Object.keys(defaultColumnTypes).reduce(
        (acc, c) => {
          acc[c] = defaultColumnTypes[c] === 'number' ? 0 : '';

          return acc;
        },
        {
          additionalColumns: {},
        }
      )
    ),
  };
};

export const calculateTotalCellValue = (
  tableRows?: AgoyTableRow[]
): AgoyTableRow[] => {
  return (
    tableRows?.map((row) => {
      const { amount, valuePerUnit } = row.cells as {
        amount: NumberCell;
        valuePerUnit: NumberCell;
      };

      return {
        ...row,
        cells: {
          ...row.cells,
          total: {
            type: 'number',
            value:
              (numberValue(amount) ?? 0) * (numberValue(valuePerUnit) ?? 0),
          },
        },
      };
    }) ?? []
  );
};

import {
  id,
  account,
  sum,
  or,
  multiply,
  label,
  ref,
  value,
  refs,
  ReferenceAccountInformation,
  TimePeriod,
  abs,
} from '@agoy/document';
import { IxbrlCell, IxbrlDataDefinition } from '../../../common';
import { BalanceSheet } from '../../../common/types/balanceSheet';
import { getRowsFromAccountRanges } from '../../../common/utils/accountsUtil';
import {
  table,
  RowsBuilder,
  ixbrlCell,
  getLinkToNote,
  SectionRow,
  AccountRange,
} from '../../../common/utils/util';
import { AnnualReportType, AnnualReportVersion } from '../../../document';
import { getWarningCell, getReferenceIdByValue } from '../../settings/settings';

/**
 *
 *********** LOOKUPS FOR IXBLR ***********
 *
 */

const addIntangibleAssetsIxbrl = (
  rowId: string,
  contextRef: string,
  type: AnnualReportType
): IxbrlDataDefinition | null => {
  switch (rowId) {
    case '1':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:BalanseradeUtgifterUtvecklingsarbetenLiknandeArbeten',
        contextRef,
        saldo: 'debit',
      };
    case '2':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:KoncessionerPatentLicenserVarumarkenLiknandeRattigheter',
        contextRef,
        saldo: 'debit',
      };
    case '3':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:HyresratterLiknandeRattigheter',
        contextRef,
        saldo: 'debit',
      };
    case '4':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:Goodwill',
        contextRef,
        saldo: 'debit',
      };
    case '5':
      if (type === 'k3') return null;
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:ForskottImmateriellaAnlaggningstillgangar',
        contextRef,
        saldo: 'debit',
      };
    case '7':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangar',
        contextRef,
        saldo: 'debit',
      };

    default:
      return null;
  }
};

const addTangibleAssetsIxbrl = (
  rowId: string,
  contextRef: string
): IxbrlDataDefinition | null => {
  switch (rowId) {
    case '1':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:ByggnaderMark',
        contextRef,
        saldo: 'debit',
      };
    case '2':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:MaskinerAndraTekniskaAnlaggningar',
        contextRef,
        saldo: 'debit',
      };
    case '3':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:InventarierVerktygInstallationer',
        contextRef,
        saldo: 'debit',
      };
    case '4':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:ForbattringsutgifterAnnansFastighet',
        contextRef,
        saldo: 'debit',
      };
    case '5':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangar',
        contextRef,
        saldo: 'debit',
      };
    case '6':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:OvrigaMateriellaAnlaggningstillgangar',
        contextRef,
        saldo: 'debit',
      };
    default:
      return null;
  }
};

const addFinancialAssetsIxbrl = (
  rowId: string,
  contextRef: string
): IxbrlDataDefinition | null => {
  switch (rowId) {
    case '1':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:AndelarKoncernforetag',
        contextRef,
        saldo: 'debit',
      };
    case '2':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:FordringarKoncernforetagLangfristiga',
        contextRef,
        saldo: 'debit',
      };
    case '3':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:AndelarIntresseforetagGemensamtStyrdaForetag',
        contextRef,
        saldo: 'debit',
      };
    case '4':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:FordringarIntresseforetagGemensamtStyrdaForetagLangfristiga',
        contextRef,
        saldo: 'debit',
      };
    case '5':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:AgarintressenOvrigaForetag',
        contextRef,
        saldo: 'debit',
      };
    case '6':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:FordringarOvrigaForetagAgarintresseLangfristiga',
        contextRef,
        saldo: 'debit',
      };
    case '7':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:AndraLangfristigaVardepappersinnehav',
        contextRef,
        saldo: 'debit',
      };
    case '8':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:LanDelagareNarstaende',
        contextRef,
        saldo: 'debit',
      };
    case '9':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:UppskjutenSkattefordran',
        contextRef,
        saldo: 'debit',
      };
    case '10':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:AndraLangfristigaFordringar',
        contextRef,
        saldo: 'debit',
      };
    default:
      return null;
  }
};

const addInventoryIxbrl = (
  rowId: string,
  contextRef: string
): IxbrlDataDefinition | null => {
  switch (rowId) {
    case '1':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:LagerRavarorFornodenheter',
        contextRef,
        saldo: 'debit',
      };
    case '2':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:LagerVarorUnderTillverkning',
        contextRef,
        saldo: 'debit',
      };
    case '3':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:LagerFardigaVarorHandelsvaror',
        contextRef,
        saldo: 'debit',
      };
    case '4':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:PagaendeArbetenAnnansRakningOmsattningstillgangar',
        contextRef,
        saldo: 'debit',
      };
    case '5':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:ForskottTillLeverantorer',
        contextRef,
        saldo: 'debit',
      };
    case '6':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:OvrigaLagertillgangar',
        contextRef,
        saldo: 'debit',
      };
    default:
      return null;
  }
};

const addShortTermReceivablesIxbrl = (
  rowId: string,
  contextRef: string
): IxbrlDataDefinition | null => {
  switch (rowId) {
    case '1':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:Kundfordringar',
        contextRef,
        saldo: 'debit',
      };
    case '2':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:FordringarKoncernforetagKortfristiga',
        contextRef,
        saldo: 'debit',
      };
    case '3':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:FordringarIntresseforetagGemensamtStyrdaForetagKortfristiga',
        contextRef,
        saldo: 'debit',
      };
    case '4':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:FordringarOvrigaforetagAgarintresseKortfristiga',
        contextRef,
        saldo: 'debit',
      };
    case '5':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:AktuellSkattefodran',
        contextRef,
        saldo: 'debit',
      };
    case '6':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:OvrigaFordringarKortfristiga',
        contextRef,
        saldo: 'debit',
      };
    case '7':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:UpparbetadEjFaktureradIntakt',
        contextRef,
        saldo: 'debit',
      };
    case '8':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:ForutbetaldaKostnaderUpplupnaIntakter',
        contextRef,
        saldo: 'debit',
      };
    default:
      return null;
  }
};

const addShortTermInvestmentsIxbrl = (
  rowId: string,
  contextRef: string
): IxbrlDataDefinition | null => {
  switch (rowId) {
    case '1':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:AndelarKoncernforetagKortfristiga',
        contextRef,
        saldo: 'debit',
      };
    case '2':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:OvrigaKortfristigaPlaceringar',
        contextRef,
        saldo: 'debit',
      };
    default:
      return null;
  }
};

const addCashAndBankBalancesIxbrl = (
  rowId: string,
  contextRef: string
): IxbrlDataDefinition | null => {
  switch (rowId) {
    case '1':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:KassaBankExklRedovisningsmedel',
        contextRef,
        saldo: 'debit',
      };
    case '2':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:Redovisningsmedel',
        contextRef,
        saldo: 'debit',
      };
    default:
      return null;
  }
};

const addRestrictedEquityIxbrl = (
  rowId: string,
  contextRef: string
): IxbrlDataDefinition | null => {
  switch (rowId) {
    case '2':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:Aktiekapital',
        contextRef,
        saldo: 'credit',
      };
    case '3':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:EjRegistreratAktiekapital',
        contextRef,
        saldo: 'credit',
      };
    case '4':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:Uppskrivningsfond',
        contextRef,
        saldo: 'credit',
      };
    case '5':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:FondUtvecklingsutgifter',
        contextRef,
        saldo: 'credit',
      };
    case '6':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:OverkursfondBunden',
        contextRef,
        saldo: 'credit',
      };
    case '7':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:Reservfond',
        contextRef,
        saldo: 'credit',
      };
    case '8':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:Kapitalandelsfond',
        contextRef,
        saldo: 'credit',
      };
    default:
      return null;
  }
};

const addUnrestrictedEquityIxbrl = (
  rowId: string,
  contextRef: string
): IxbrlDataDefinition | null => {
  switch (rowId) {
    case '1':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:Overkursfond',
        contextRef,
        saldo: 'credit',
      };
    case '2':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:BalanseratResultat',
        contextRef,
        saldo: 'credit',
      };

    case '4':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:AretsResultatEgetKapital',
        contextRef,
        saldo: 'credit',
      };
    case '5':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:FondVerkligtVarde',
        contextRef,
        saldo: 'credit',
      };
    default:
      return null;
  }
};

const addUntaxedReservesIxbrl = (
  rowId: string,
  contextRef: string
): IxbrlDataDefinition | null => {
  switch (rowId) {
    case '1':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:Periodiseringsfonder',
        contextRef,
        saldo: 'credit',
      };
    case '2':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:AckumuleradeOveravskrivningar',
        contextRef,
        saldo: 'credit',
      };
    case '3':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:Ersattningsfonder',
        contextRef,
        saldo: 'credit',
      };
    case '4':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:OvrigaObeskattadeReserver',
        contextRef,
        saldo: 'credit',
      };
    case '5':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:AndraObeskattadeReserver',
        contextRef,
        saldo: 'credit',
      };
    default:
      return null;
  }
};

const addAllocationsIxbrl = (
  rowId: string,
  contextRef: string
): IxbrlDataDefinition | null => {
  switch (rowId) {
    case '1':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:AvsattningarPensionerLiknandeForpliktelserEnligtLag',
        contextRef,
        saldo: 'credit',
      };
    case '2':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:UppskjutenSkatteskuld',
        contextRef,
        saldo: 'credit',
      };
    case '3':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:OvrigaAvsattningar',
        contextRef,
        saldo: 'credit',
      };
    case '4':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:OvrigaAvsattningarPensionerLiknandeForpliktelser',
        contextRef,
        saldo: 'credit',
      };
    case '5':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:AvsattningarPensionerLiknandeForpliktelser',
        contextRef,
        saldo: 'credit',
      };
    case '6':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:AvsattningarOvrigSkatt',
        contextRef,
        saldo: 'credit',
      };
    default:
      return null;
  }
};

const addLongTermLiabilitiesIxbrl = (
  rowId: string,
  contextRef: string
): IxbrlDataDefinition | null => {
  switch (rowId) {
    case '1':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:Obligationslan',
        contextRef,
        saldo: 'credit',
      };

    case '3':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:CheckrakningskreditLangfristig',
        contextRef,
        saldo: 'credit',
      };

    case '5':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:OvrigaLangfristigaSkulderKreditinstitut',
        contextRef,
        saldo: 'credit',
      };
    case '6':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:SkulderKoncernforetagLangfristiga',
        contextRef,
        saldo: 'credit',
      };
    case '7':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:SkulderIntresseforetagGemensamtStyrdaForetagLangfristiga',
        contextRef,
        saldo: 'credit',
      };
    case '8':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:SkulderOvrigaForetagAgarintresseLangfristiga',
        contextRef,
        saldo: 'credit',
      };
    case '9':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:OvrigaLangfristigaSkulder',
        contextRef,
        saldo: 'credit',
      };
    case '10':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:SkulderKreditinstitutLangfristiga',
        contextRef,
        saldo: 'credit',
      };
    default:
      return null;
  }
};

const addShortTermLiabilitiesIxbrl = (
  rowId: string,
  contextRef: string
): IxbrlDataDefinition | null => {
  switch (rowId) {
    case '1':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:CheckrakningskreditKortfristig',
        contextRef,
        saldo: 'credit',
      };
    case '2':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:OvrigaKortfristigaSkulderKreditinstitut',
        contextRef,
        saldo: 'credit',
      };
    case '3':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:ForskottFranKunder',
        contextRef,
        saldo: 'credit',
      };
    case '4':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:PagaendeArbetenAnnansRakningKortfristigaSkulder',
        contextRef,
        saldo: 'credit',
      };
    case '5':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:Leverantorsskulder',
        contextRef,
        saldo: 'credit',
      };
    case '6':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:Vaxelskulder',
        contextRef,
        saldo: 'credit',
      };
    case '7':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:SkulderKoncernforetagKortfristiga',
        contextRef,
        saldo: 'credit',
      };
    case '8':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:SkulderIntresseforetagGemensamtStyrdaForetagKortfristiga',
        contextRef,
        saldo: 'credit',
      };
    case '9':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:SkulderOvrigaForetagAgarintresseKortfristiga',
        contextRef,
        saldo: 'credit',
      };
    case '10':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:Skatteskulder',
        contextRef,
        saldo: 'credit',
      };
    case '11':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:OvrigaKortfristigaSkulder',
        contextRef,
        saldo: 'credit',
      };
    case '12':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:FaktureradEjUpparbetadIntakt',
        contextRef,
        saldo: 'credit',
      };
    case '13':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:UpplupnaKostnaderForutbetaldaIntakter',
        contextRef,
        saldo: 'credit',
      };
    case '14':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:SkulderKreditinstitutKortfristiga',
        contextRef,
        saldo: 'credit',
      };
    default:
      return null;
  }
};

type BalanceSheetSectionReferences =
  | 'unpaidCapitalVisuallyHidden'
  | 'intangibleAssets'
  | 'tangibleFixedAssets'
  | 'financialAssets'
  | 'inventory'
  | 'shortTermReceivables'
  | 'shortTermInvestments'
  | 'cashAndBankBalances'
  | 'restrictedEquity'
  | 'unrestrictedEquity'
  | 'untaxedReserves'
  | 'allocations'
  | '1'
  | 'shortTermLiabilities'
  | 'unassignedAccounts';

export type SharesCompanyBalanceSheetStructure = {
  [key in BalanceSheetSectionReferences]: SectionRow[];
};

export const sharesCompanyBalanceSheetAccounts = (
  reportType: AnnualReportType,
  isDigitalSubmission?: boolean,
  documentTypeVersion?: AnnualReportVersion
): SharesCompanyBalanceSheetStructure => {
  const getIntangibleAssets = (): SectionRow[] => {
    const intangibleAssets: SectionRow[] = [];
    if (reportType === 'k3') {
      intangibleAssets.push({
        row: '1',
        name: 'Balanserade utgifter för utvecklingsarbeten och liknande arbeten',
        accounts: [[1000, 1019]],
        noteNumber: 43,
      });
    }
    intangibleAssets.push({
      row: '2',
      name: 'Koncessioner, patent, licenser, varumärken samt liknande rättigheter',
      accounts: reportType === 'k3' ? [[1020, 1059]] : [[1000, 1059]],
      noteNumber: 6,
    });
    intangibleAssets.push({
      row: '3',
      name: 'Hyresrätter och liknande rättigheter',
      accounts: [[1060, 1069]],
      noteNumber: 7,
    });
    intangibleAssets.push({
      row: '4',
      name: 'Goodwill',
      accounts: [[1070, 1079]],
      noteNumber: 8,
    });
    intangibleAssets.push({
      row: '5',
      name: 'Förskott avseende immateriella anläggningstillgångar',
      accounts: [[1080, 1089]],
      noteNumber: 9,
    });
    intangibleAssets.push({
      row: '6',
      name: 'Övriga immateriella anläggningstillgångar',
      accounts: [[1090, 1099]],
    });
    if (reportType === 'k3') {
      intangibleAssets.push({
        row: '7',
        name: 'Pågående utvecklingsarbeten och förskott avseende immateriella anläggningstillgångar',
        accounts: [],
      });
    }

    return intangibleAssets;
  };

  const getFinancialAssets = (): SectionRow[] => {
    const financialAssets: SectionRow[] = [
      {
        row: '1',
        name: 'Andelar i koncernföretag',
        accounts: [[1300, 1319]],
      },
      {
        row: '2',
        name: 'Fordringar hos koncernföretag',
        accounts: [[1320, 1329]],
        noteNumber: 17,
      },
      {
        row: '3',
        name: 'Andelar i intresseföretag och gemensamt styrda företag',
        accounts: [
          [1330, 1335],
          [1337, 1339],
        ],
        noteNumber: 18,
      },
      {
        row: '4',
        name: 'Fordringar hos intresseföretag och gemensamt styrda företag',
        accounts: [
          [1340, 1345],
          [1348, 1349],
        ],
        noteNumber: 19,
      },
      {
        row: '5',
        name: 'Ägarintressen i övriga företag',
        accounts: [[1336, 1336]],
      },
      {
        row: '6',
        name: 'Fordringar hos övriga företag som det finns ett ägarintresse i',
        accounts: [[1346, 1347]],
        noteNumber: 21,
      },
      {
        row: '7',
        name: 'Andra långfristiga värdepappersinnehav',
        accounts: [[1350, 1359]],
        noteNumber: 22,
      },
      {
        row: '8',
        name: 'Lån till delägare eller till delägare närstående',
        accounts: [[1360, 1369]],
        noteNumber: 23,
      },
    ];

    if (reportType === 'k3') {
      financialAssets.push({
        row: '9',
        name: 'Uppskjuten skattefordran',
        accounts: [[1370, 1379]],
      });
    }

    financialAssets.push({
      row: '10',
      name: 'Andra långfristiga fordringar',
      accounts: reportType === 'k3' ? [[1380, 1399]] : [[1370, 1399]],
      noteNumber: 24,
    });

    return financialAssets;
  };

  const getShortTermReceivables = (): SectionRow[] => {
    const shortTermReceivables: SectionRow[] = [
      {
        row: '1',
        name: 'Kundfordringar',
        accounts: [[1500, 1599]],
      },
      {
        row: '2',
        name: 'Fordringar hos koncernföretag',
        accounts: [[1660, 1669]],
        noteNumber: 17,
      },
      {
        row: '3',
        name: 'Fordringar hos intresseföretag och gemensamt styrda företag',
        accounts: [[1670, 1672]],
        noteNumber: 19,
      },
      {
        row: '4',
        name: 'Fordringar hos övriga företag som det finns ett ägarintresse i',
        accounts: [[1673, 1679]],
        noteNumber: 21,
      },
    ];

    if (reportType === 'k3') {
      shortTermReceivables.push({
        row: '5',
        name: 'Aktuell skattefordran',
        accounts: [[1640, 1649]],
      });
    }

    shortTermReceivables.push({
      row: '6',
      name: 'Övriga fordringar',
      accounts:
        reportType === 'k2'
          ? [
              [1600, 1619],
              [1630, 1639],
              [1640, 1649],
              [1650, 1659],
              [1680, 1689],
            ]
          : [
              [1600, 1619],
              [1630, 1639],
              [1650, 1659],
              [1680, 1689],
            ],
    });

    shortTermReceivables.push({
      row: '7',
      name: 'Upparbetad men ej fakturerad intäkt',
      accounts: [[1620, 1629]],
    });

    shortTermReceivables.push({
      row: '8',
      name: 'Förutbetalda kostnader och upplupna intäkter',
      accounts: [[1700, 1799]],
    });

    return shortTermReceivables;
  };

  const getRestrictedEquity = (): SectionRow[] => {
    const restrictedEquity: SectionRow[] = [];

    if (!isDigitalSubmission) {
      restrictedEquity.push({
        row: '1',
        name: 'Eget kapital',
        accounts: [
          [2000, 2080],
          [2083, 2084],
          [2088, reportType === 'k3' ? 2088 : 2089],
        ],
      });
    }

    const manualSubmissionShareCapitalAccounts: AccountRange[] =
      reportType === 'k3'
        ? [[2000, 2081]]
        : [
            [2000, 2081],
            [2089, 2089],
          ];
    restrictedEquity.push({
      row: '2',
      name: 'Aktiekapital',
      accounts: isDigitalSubmission
        ? manualSubmissionShareCapitalAccounts
        : [[2081, 2081]],
    });

    restrictedEquity.push({
      row: '3',
      name:
        reportType === 'k2'
          ? 'Ej registrerat aktiekapital'
          : 'Pågående ny- och fondemission',
      accounts: !isDigitalSubmission ? [[2082, 2082]] : [[2082, 2084]],
    });

    // AGOY-4125 Moved the rows below, but kept IDs to keep the data saved here
    restrictedEquity.push({
      row: '6',
      name: 'Bunden överkursfond',
      accounts: isDigitalSubmission ? [[2087, 2088]] : [[2087, 2087]],
    });

    restrictedEquity.push({
      row: '4',
      name: 'Uppskrivningsfond',
      accounts: [[2085, 2085]],
      noteNumber: 26,
    });

    restrictedEquity.push({
      row: '7',
      name: 'Reservfond',
      accounts: [[2086, 2086]],
    });

    if (reportType === 'k3') {
      restrictedEquity.push({
        row: '8',
        name: 'Kapitalandelsfond',
        accounts: [],
      });

      restrictedEquity.push({
        row: '5',
        name: 'Fond för utvecklingsutgifter',
        accounts: [[2089, 2089]],
      });
    }

    return restrictedEquity;
  };

  const getUnrestrictedEquity = (): SectionRow[] => {
    const unrestrictedEquity: SectionRow[] = [
      {
        row: '1',
        name: 'Fri överkursfond',
        accounts:
          reportType === 'k2' && isDigitalSubmission
            ? [[2096, 2097]]
            : [[2097, 2097]],
      },
      {
        row: '2',
        name: 'Balanserat resultat',
        accounts: isDigitalSubmission
          ? [
              [2090, 2095],
              [2098, 2098],
            ]
          : [
              [2090, 2092],
              [2094, 2096],
              [2098, 2098],
            ],
      },
    ];

    if (!isDigitalSubmission) {
      unrestrictedEquity.push({
        row: '3',
        name: 'Erhållet aktieägartillskott',
        accounts: [[2093, 2093]],
      });
    }

    unrestrictedEquity.push({
      row: '4',
      name: 'Årets resultat',
      accounts: [[2099, 2099]],
    });

    if (reportType === 'k3' && isDigitalSubmission) {
      unrestrictedEquity.push({
        row: '5',
        name: 'Fond för verkligt värde',
        accounts: [[2096, 2096]],
      });
    }

    return unrestrictedEquity;
  };

  const getUntaxedReserves = (): SectionRow[] => {
    const untaxedReserves: SectionRow[] = [
      {
        row: '1',
        name: 'Periodiseringsfond',
        accounts: [[2100, 2149]],
      },
      {
        row: '2',
        name: 'Ackumulerade överavskrivningar',
        accounts: [[2150, 2159]],
      },
      {
        row: '3',
        name: 'Ersättningsfond',
        accounts: [[2160, 2169]],
      },
      {
        row: '4',
        name: 'Övriga obeskattade reserver',
        accounts: [[2170, 2199]],
      },
    ];

    if (reportType === 'k3') {
      untaxedReserves.push({
        row: '5',
        name: 'Andra obeskattade reserver',
        accounts: [],
      });
    }

    return untaxedReserves;
  };

  const getAllocations = (): SectionRow[] => {
    const allocations: SectionRow[] = [
      {
        row: '1',
        name: 'Pensioner och andra liknande förpliktelser',
        accounts: [[2200, 2239]],
      },
    ];

    if (reportType === 'k3') {
      allocations.push({
        row: '2',
        name: 'Uppskjuten skatteskuld',
        accounts: [[2240, 2259]],
      });
    }

    allocations.push({
      row: '3',
      name: 'Övriga avsättningar',
      accounts: reportType === 'k3' ? [[2260, 2299]] : [[2240, 2299]],
    });

    if (isDigitalSubmission) {
      allocations.push({
        row: '4',
        name: 'Övriga avsättningar för pensioner och liknande förpliktelser exklusive avsättningar enligt tryggandelagen',
        accounts: [],
      });
    }

    if (reportType === 'k3') {
      allocations.push({
        row: '5',
        name: 'Summa avsättningar för pensioner och liknande förpliktelser',
        accounts: [],
      });

      allocations.push({
        row: '6',
        name: 'Avsättningar för övriga skatter',
        accounts: [],
      });
    }

    return allocations;
  };

  const getOne = (): SectionRow[] => {
    const one: SectionRow[] = [
      {
        row: '1',
        name: 'Obligationslån',
        accounts: isDigitalSubmission ? [[2300, 2329]] : [[2300, 2319]],
        noteNumber: !isDigitalSubmission ? 28 : undefined,
      },
    ];

    // no tag in digital sub, then this row has cant be included
    if (!isDigitalSubmission) {
      one.push({
        row: '2',
        name: 'Konvertibla lån',
        accounts: [[2320, 2329]],
        noteNumber: 29,
      });
    }

    one.push({
      row: '3',
      name: 'Checkräkningskredit',
      accounts: [[2330, 2339]],
      noteNumber: !isDigitalSubmission ? 30 : undefined,
    });

    if (!isDigitalSubmission) {
      one.push({
        row: '4',
        name: 'Byggnadskreditiv',
        accounts: [[2340, 2349]],
        noteNumber: 31,
      });
    }

    one.push({
      row: '5',
      name: 'Övriga skulder till kreditinstitut',
      accounts: isDigitalSubmission ? [[2340, 2359]] : [[2350, 2359]],
      noteNumber: !isDigitalSubmission ? 28 : undefined,
    });

    one.push({
      row: '6',
      name: 'Skulder till koncernföretag',
      accounts: [[2360, 2369]],
      noteNumber: !isDigitalSubmission ? 28 : undefined,
    });

    one.push({
      row: '7',
      name: 'Skulder till intresseföretag och gemensamt styrda företag',
      accounts: [[2370, 2372]],
      noteNumber: !isDigitalSubmission ? 28 : undefined,
    });

    one.push({
      row: '8',
      name: 'Skulder till övriga företag som det finns ett ägarintresse i',
      accounts: [[2373, 2379]],
      noteNumber: !isDigitalSubmission ? 28 : undefined,
    });

    one.push({
      row: '9',
      name: 'Övriga långfristiga skulder',
      accounts: [[2380, 2399]],
      noteNumber: !isDigitalSubmission ? 28 : undefined,
    });

    if (reportType === 'k3') {
      one.push({
        row: '10',
        name: 'Summa skulder till kreditinstitut',
        accounts: [],
      });
    }

    return one;
  };

  const getshortTermLiabilities = (): SectionRow[] => {
    const shortTermLiabilities: SectionRow[] = [
      {
        row: '1',
        name: 'Checkräkningskredit',
        accounts: [[2480, 2489]],
      },
      {
        row: '2',
        name: 'Övriga skulder till kreditinstitut',
        accounts: [[2400, 2419]],
        noteNumber: !isDigitalSubmission ? 28 : undefined,
      },
      {
        row: '3',
        name: 'Förskott från kunder',
        accounts: [[2420, 2429]],
      },
      {
        row: '4',
        name: 'Pågående arbete för annans räkning',
        accounts: [[2430, 2439]],
        noteNumber: 25,
      },
      // AGOY-4125 Moved the row, but kept ID to keep the data saved here
      {
        row: '12',
        name: 'Fakturerad men ej upparbetad intäkt',
        accounts: [[2450, 2459]],
      },
      {
        row: '5',
        name: 'Leverantörsskulder',
        accounts: [[2440, 2449]],
      },
      {
        row: '6',
        name: 'Växelskulder',
        accounts: [[2492, 2492]],
      },
      {
        row: '7',
        name: 'Skulder till koncernföretag',
        accounts: [
          [2460, 2469],
          [2860, 2869],
        ],
        noteNumber: !isDigitalSubmission ? 28 : undefined,
      },
      {
        row: '8',
        name: 'Skulder till intresseföretag och gemensamt styrda företag',
        accounts: [
          [2870, 2872],
          [2470, 2472],
        ],
        noteNumber: !isDigitalSubmission ? 28 : undefined,
      },
      {
        row: '9',
        name: 'Skulder till övriga företag som det finns ett ägarintresse i',
        accounts: [
          [2473, 2479],
          [2873, 2879],
        ],
        noteNumber: !isDigitalSubmission ? 28 : undefined,
      },
      {
        row: '10',
        name: 'Skatteskulder',
        accounts: [[2500, 2599]],
      },
      {
        row: '11',
        name: 'Övriga skulder',
        accounts: [
          [2490, 2491],
          [2493, 2499],
          [2600, 2799],
          [2800, 2859],
          [2880, 2899],
        ],
      },
      {
        row: '13',
        name: 'Upplupna kostnader och förutbetalda intäkter',
        accounts: [[2900, 2999]],
        noteNumber: 66,
        noteAccountsOverride: [[2900, 2919]],
      },
    ];

    if (reportType === 'k3') {
      shortTermLiabilities.push({
        row: '14',
        name: 'Summa skulder till kreditinstitut',
        accounts: [],
      });
    }

    return shortTermLiabilities;
  };

  return {
    unpaidCapitalVisuallyHidden: [
      {
        row: 'visuallyHidden',
        name: '',
        accounts: [[1690, 1699]],
      },
    ],
    intangibleAssets: getIntangibleAssets(),
    tangibleFixedAssets: [
      {
        row: '1',
        name: 'Byggnader och mark',
        accounts: [
          [1100, 1119],
          [1130, 1199],
        ], // included more accounts in one interval belowm
        noteNumber: 10,
      },
      {
        row: '2',
        name: 'Maskiner och andra tekniska anläggningar',
        accounts: [[1200, 1219]],
        noteNumber: 11,
      },
      {
        row: '3',
        name: 'Inventarier, verktyg och installationer',
        accounts: [[1220, 1279]],
        noteNumber: 12,
      },
      {
        row: '4',
        name: 'Förbättringsutgifter på annans fastighet',
        accounts: [[1120, 1129]],
        noteNumber: 13,
      },
      // AGOY-4125 Moved the row, but kept ID to keep the data saved here
      {
        row: '6',
        name: 'Övriga materiella anläggningstillgångar',
        accounts: [[1290, 1299]],
      },
      {
        row: '5',
        name: 'Pågående nyanläggningar och förskott avseende materiella anläggningstillgångar',
        accounts: [[1280, 1289]],
        noteNumber: 15,
      },
    ],
    financialAssets: getFinancialAssets(),
    inventory: [
      {
        row: '1',
        name: 'Råvaror och förnödenheter',
        accounts: [[1400, 1439]],
      },
      {
        row: '2',
        name: 'Varor under tillverkning',
        accounts: [[1440, 1449]],
      },
      {
        row: '3',
        name: 'Färdiga varor och handelsvaror',
        accounts: [[1450, 1469]],
      },
      // AGOY-4125 Moved the row, but kept ID to keep the data saved here
      {
        row: '6',
        name: 'Övriga lagertillgångar',
        accounts: [[1490, 1499]],
      },
      {
        row: '4',
        name: 'Pågående arbete för annans räkning',
        accounts: [[1470, 1479]],
      },
      {
        row: '5',
        name: 'Förskott till leverantörer',
        accounts: [[1480, 1489]],
      },
    ],
    shortTermReceivables: getShortTermReceivables(),
    shortTermInvestments: [
      {
        row: '1',
        name: 'Andelar i koncernföretag',
        accounts: [[1860, 1869]],
      },
      {
        row: '2',
        name: 'Övriga kortfristiga placeringar',
        accounts: [
          [1800, 1859],
          [1870, 1899],
        ],
      },
    ],
    cashAndBankBalances: [
      {
        row: '1',
        name: 'Kassa och bank',
        accounts: [[1900, 1989]],
      },
      {
        row: '2',
        name: 'Redovisningsmedel',
        accounts: [[1990, 1999]],
      },
    ],
    restrictedEquity: getRestrictedEquity(),
    unrestrictedEquity: getUnrestrictedEquity(),
    untaxedReserves: getUntaxedReserves(),
    allocations: getAllocations(),
    1: getOne(),
    shortTermLiabilities: getshortTermLiabilities(),
    unassignedAccounts: [],
  };
};

const attachRows = (
  accounts: Record<string, ReferenceAccountInformation>,
  switchSign: boolean,
  rows: RowsBuilder,
  previousPeriod: string,
  structureKey: keyof SharesCompanyBalanceSheetStructure,
  isDigitalSubmission: boolean,
  type: AnnualReportType,
  documentTypeVersion: AnnualReportVersion
) => {
  const add = addPost(
    accounts,
    switchSign,
    rows,
    previousPeriod,
    structureKey,
    isDigitalSubmission,
    type
  );
  sharesCompanyBalanceSheetAccounts(
    type,
    isDigitalSubmission,
    documentTypeVersion
  )[structureKey].map((section: SectionRow) => {
    const link = getLinkToNote(
      section,
      accounts,
      isDigitalSubmission,
      type,
      documentTypeVersion
    );
    add(section.row, section.name, section.accounts, link);
  });
};
// Main lookup to find the rowName's lookup
const addPostIxbrl = (
  rowName: string,
  rowId: string,
  contextRef: string,
  type: AnnualReportType
): IxbrlDataDefinition | null => {
  switch (rowName) {
    case 'intangibleAssets':
      return addIntangibleAssetsIxbrl(rowId, contextRef, type);
    case 'tangibleFixedAssets':
      return addTangibleAssetsIxbrl(rowId, contextRef);
    case 'financialAssets':
      return addFinancialAssetsIxbrl(rowId, contextRef);
    case 'inventory':
      return addInventoryIxbrl(rowId, contextRef);
    case 'shortTermReceivables':
      return addShortTermReceivablesIxbrl(rowId, contextRef);
    case 'shortTermInvestments':
      return addShortTermInvestmentsIxbrl(rowId, contextRef);
    case 'cashAndBankBalances':
      return addCashAndBankBalancesIxbrl(rowId, contextRef);
    case 'restrictedEquity':
      return addRestrictedEquityIxbrl(rowId, contextRef);
    case 'unrestrictedEquity':
      return addUnrestrictedEquityIxbrl(rowId, contextRef);
    case 'untaxedReserves':
      return addUntaxedReservesIxbrl(rowId, contextRef);
    case 'allocations':
      return addAllocationsIxbrl(rowId, contextRef);
    case '1':
      return addLongTermLiabilitiesIxbrl(rowId, contextRef);
    case 'shortTermLiabilities':
      return addShortTermLiabilitiesIxbrl(rowId, contextRef);
    default:
      return null;
  }
};

// Lookup for sums, called in addGroup()
const addGroupIxbrl = (
  rowId: string,
  contextRef: string
): IxbrlDataDefinition | null => {
  switch (rowId) {
    case 'intangibleAssets':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:ImmateriellaAnlaggningstillgangar',
        contextRef,
        saldo: 'debit',
      };
    case 'tangibleFixedAssets':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:MateriellaAnlaggningstillgangar',
        contextRef,
        saldo: 'debit',
      };
    case 'financialAssets':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:FinansiellaAnlaggningstillgangar',
        contextRef,
        saldo: 'debit',
      };
    case 'inventory':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:VarulagerMm',
        contextRef,
        saldo: 'debit',
      };
    case 'shortTermReceivables':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:KortfristigaFordringar',
        contextRef,
        saldo: 'debit',
      };
    case 'shortTermInvestments':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:KortfristigaPlaceringar',
        contextRef,
        saldo: 'debit',
      };
    case 'cashAndBankBalances':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:KassaBank',
        contextRef,
        saldo: 'debit',
      };
    case 'restrictedEquity':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:BundetEgetKapital',
        contextRef,
        saldo: 'credit',
      };
    case 'unrestrictedEquity':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:FrittEgetKapital',
        contextRef,
        saldo: 'credit',
      };
    case 'untaxedReserves':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:ObeskattadeReserver',
        contextRef,
        saldo: 'credit',
      };
    case 'allocations':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:Avsattningar',
        contextRef,
        saldo: 'credit',
      };
    case '1':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:LangfristigaSkulder',
        contextRef,
        saldo: 'credit',
      };
    case 'shortTermLiabilities':
      return {
        type: 'monetaryItemType',
        name: 'se-gen-base:KortfristigaSkulder',
        contextRef,
        saldo: 'credit',
      };
    default:
      return null;
  }
};

const addPost =
  (
    accounts: Record<string, ReferenceAccountInformation>,
    switchSign: boolean,
    rows: RowsBuilder,
    previousPeriod: string,
    rowName: string,
    isDigitalSubmission: boolean,
    type: AnnualReportType
  ) =>
  (
    rowId: string,
    name: string,
    accountNumbers: [number, number][],
    noteRef?: string
  ): RowsBuilder => {
    const references = balanceSheetReferences();

    const defaultWarningCellIdYear = `${rows.getBaseId()}.${rowId}.year`;
    const defaultWarningCellIdPreviousYear = `${rows.getBaseId()}.${rowId}.previousYear`;

    const warningCellIdYear = getReferenceIdByValue(
      references,
      defaultWarningCellIdYear
    );

    const warningCellIdPreviousYear = getReferenceIdByValue(
      references,
      defaultWarningCellIdPreviousYear
    );

    rows.addRowWithType(
      rowId,
      'row',
      value(name), // user-editable name for a row
      noteRef ? refs(noteRef) : refs(), // this is where the notes are added
      isDigitalSubmission && type
        ? ixbrlCell(
            ref(or(sum(id(`${rows.getBaseId()}.${rowId}.*.year`)), 0)),
            addPostIxbrl(rowName, rowId, 'balans0', type)
          )
        : ref(or(sum(id(`${rows.getBaseId()}.${rowId}.*.year`)), 0)),
      isDigitalSubmission && type
        ? ixbrlCell(
            ref(or(sum(id(`${rows.getBaseId()}.${rowId}.*.previousYear`)), 0)),
            addPostIxbrl(rowName, rowId, 'balans1', type)
          )
        : ref(or(sum(id(`${rows.getBaseId()}.${rowId}.*.previousYear`)), 0)),
      undefined,
      getWarningCell(warningCellIdYear || defaultWarningCellIdYear),
      getWarningCell(
        warningCellIdPreviousYear || defaultWarningCellIdPreviousYear
      )
    );

    rows.newRowTemplateGenerator(
      (id, baseId, { label: labelValue, ib, ub }) => ({
        id,
        type: 'account',
        active: true,
        cells: {
          label: label(labelValue),
          year: ub
            ? ref(
                switchSign
                  ? multiply(-1, or(account(ub), 0))
                  : or(account(ub), 0)
              )
            : value(0),
          previousYear: ib
            ? ref(
                switchSign
                  ? multiply(-1, or(account(ib, undefined, previousPeriod), 0))
                  : or(account(ib, undefined, previousPeriod), 0)
              )
            : value(0),
        },
        sortKey: ib ? parseInt(ib) : parseInt(ub),
      })
    );
    rows.addSubRows((rows) => {
      accountNumbers.forEach(([first, last]) =>
        getRowsFromAccountRanges(
          accounts,
          first,
          last,
          rows,
          switchSign,
          previousPeriod
        )
      );
      return rows.build();
    });
    return rows;
  };

const addSubscribedUnpaidCapitalSubrows = (
  rows: RowsBuilder,
  rowId: string,
  name: string | undefined,
  sumName: string | undefined,
  subRowBuilder: (rows: RowsBuilder) => void,
  isDigitalSubmission: boolean,
  noteRef?: string
): void => {
  rows
    .addRowWithType(
      rowId,
      'header',
      name === undefined ? undefined : value(name),
      noteRef ? refs(noteRef) : refs(),
      undefined,
      undefined,
      ref(id(`${rows.getBaseId()}.${rowId}Sum.hidden`))
    )
    .addSubRows((rows) => {
      subRowBuilder(rows);
      return rows.build();
    });

  rows.addRowWithType(
    `${rowId}Sum`,
    'sum',
    value(sumName),
    refs(),
    isDigitalSubmission
      ? ixbrlCell(
          ref(or(sum(id(`${rows.getBaseId()}.${rowId}.*.year`)), 0)),
          addGroupIxbrl(rowId, 'balans0')
        )
      : ref(or(sum(id(`${rows.getBaseId()}.${rowId}.*.year`)), 0)),
    isDigitalSubmission
      ? ixbrlCell(
          ref(or(sum(id(`${rows.getBaseId()}.${rowId}.*.previousYear`)), 0)),
          addGroupIxbrl(rowId, 'balans1')
        )
      : ref(or(sum(id(`${rows.getBaseId()}.${rowId}.*.previousYear`)), 0))
  );
};

const addGroup = (
  rows: RowsBuilder,
  rowId: string,
  name: string,
  sumName: string,
  subRowBuilder: (rows: RowsBuilder) => void,
  isDigitalSubmission: boolean,
  noteRef?: string
): void => {
  rows
    .addRowWithType(
      rowId,
      'header',
      name === undefined ? undefined : value(name),
      noteRef ? refs(noteRef) : refs(),
      undefined,
      undefined,
      ref(id(`${rows.getBaseId()}.${rowId}Sum.hidden`))
    )
    .addSubRows((rows) => {
      subRowBuilder(rows);
      return rows.build();
    });

  rows.newRowTemplateBuilder((template) =>
    template
      .addRow(
        '',
        value(''),
        refs(),
        ref(or(sum(id('$id.*.year')), 0)),
        ref(or(sum(id('$id.*.previousYear')), 0))
      )
      .newRowTemplate(label('$label'), label(''), ref('$ub'), ref('$ib'))
  );

  const references = balanceSheetReferences();

  const defaultWarningCellIdYear = `${rows.getBaseId()}.${rowId}Sum.year`;

  const warningCellIdYear = getReferenceIdByValue(
    references,
    defaultWarningCellIdYear
  );

  rows.addRowWithType(
    `${rowId}Sum`,
    'sum',
    value(sumName),
    refs(),
    isDigitalSubmission
      ? ixbrlCell(
          ref(or(sum(id(`${rows.getBaseId()}.${rowId}.*.year`)), 0)),
          addGroupIxbrl(rowId, 'balans0')
        )
      : ref(or(sum(id(`${rows.getBaseId()}.${rowId}.*.year`)), 0)),
    isDigitalSubmission
      ? ixbrlCell(
          ref(or(sum(id(`${rows.getBaseId()}.${rowId}.*.previousYear`)), 0)),
          addGroupIxbrl(rowId, 'balans1')
        )
      : ref(or(sum(id(`${rows.getBaseId()}.${rowId}.*.previousYear`)), 0)),
    ref(
      sum(
        // The accounts with notes linked to them should not be hidden
        or(
          id(`${rows.getBaseId()}.${rowId}.*.notes-0`),
          id(`${rows.getBaseId()}.${rowId}Sum.notes-0`),
          0
        ),
        abs(id(`${rows.getBaseId()}.${rowId}Sum.year`)),
        abs(id(`${rows.getBaseId()}.${rowId}Sum.previousYear`))
      )
    ),
    getWarningCell(warningCellIdYear || defaultWarningCellIdYear)
  );
};

const addSubscribedUnpaidCapital = (
  accounts: Record<string, ReferenceAccountInformation>,
  rows: RowsBuilder,
  type: AnnualReportType,
  previousPeriod: string,
  isDigitalSubmission?: boolean | null,
  documentTypeVersion: AnnualReportVersion = '1'
): void => {
  addSubscribedUnpaidCapitalSubrows(
    rows,
    'unpaidCapitalVisuallyHidden',
    '', // same as name. No row should be present in this level
    '', // same as sumName. Sum row is not displayed in this case. Hidden in BalanceSheetTable
    (rows) =>
      attachRows(
        accounts,
        false,
        rows,
        previousPeriod,
        'unpaidCapitalVisuallyHidden',
        !!isDigitalSubmission,
        type,
        documentTypeVersion
      ),
    !!isDigitalSubmission
  );
};

const addIntangibleAssets = (
  accounts: Record<string, ReferenceAccountInformation>,
  rows: RowsBuilder,
  type: AnnualReportType,
  previousPeriod: string,
  isDigitalSubmission: boolean,
  documentTypeVersion: AnnualReportVersion = '1'
): void => {
  addGroup(
    rows,
    'intangibleAssets',
    'Immateriella anläggningstillgångar',
    'Summa immateriella anläggningstillgångar',
    (rows) =>
      attachRows(
        accounts,
        false,
        rows,
        previousPeriod,
        'intangibleAssets',
        isDigitalSubmission,
        type,
        documentTypeVersion
      ),
    !!isDigitalSubmission
  );
};

const addTangibleFixedAssets = (
  accounts: Record<string, ReferenceAccountInformation>,
  rows: RowsBuilder,
  type: AnnualReportType,
  previousPeriod: string,
  isDigitalSubmission: boolean,
  documentTypeVersion: AnnualReportVersion = '1'
): void => {
  addGroup(
    rows,
    'tangibleFixedAssets',
    'Materiella anläggningstillgångar',
    'Summa materiella anläggningstillgångar',
    (rows) =>
      attachRows(
        accounts,
        false,
        rows,
        previousPeriod,
        'tangibleFixedAssets',
        isDigitalSubmission,
        type,
        documentTypeVersion
      ),
    isDigitalSubmission
  );
};

const addFinancialAssets = (
  accounts: Record<string, ReferenceAccountInformation>,
  rows: RowsBuilder,
  type: AnnualReportType,
  previousPeriod: string,
  isDigitalSubmission: boolean,
  documentTypeVersion: AnnualReportVersion = '1'
): void => {
  addGroup(
    rows,
    'financialAssets',
    'Finansiella anläggningstillgångar',
    'Summa finansiella anläggningstillgångar',
    (rows) =>
      attachRows(
        accounts,
        false,
        rows,
        previousPeriod,
        'financialAssets',
        isDigitalSubmission,
        type,
        documentTypeVersion
      ),
    isDigitalSubmission
  );
};

const addInventory = (
  accounts: Record<string, ReferenceAccountInformation>,
  rows: RowsBuilder,
  type: AnnualReportType,
  previousPeriod: string,
  isDigitalSubmission: boolean,
  documentTypeVersion: AnnualReportVersion = '1'
): void => {
  addGroup(
    rows,
    'inventory',
    'Varulager m.m.',
    'Summa varulager m.m.',
    (rows) =>
      attachRows(
        accounts,
        false,
        rows,
        previousPeriod,
        'inventory',
        isDigitalSubmission,
        type,
        documentTypeVersion
      ),
    isDigitalSubmission
  );
};

const addShortTermReceivables = (
  accounts: Record<string, ReferenceAccountInformation>,
  rows: RowsBuilder,
  previousPeriod: string,
  type: AnnualReportType,
  isDigitalSubmission?: boolean | null,
  documentTypeVersion: AnnualReportVersion = '1'
) => {
  addGroup(
    rows,
    'shortTermReceivables',
    'Kortfristiga fordringar',
    'Summa kortfristiga fordringar',

    (rows) =>
      attachRows(
        accounts,
        false,
        rows,
        previousPeriod,
        'shortTermReceivables',
        !!isDigitalSubmission,
        type,
        documentTypeVersion
      ),
    !!isDigitalSubmission
  );
};

const addShortTermInvestments = (
  accounts: Record<string, ReferenceAccountInformation>,
  rows: RowsBuilder,
  previousPeriod: string,
  isDigitalSubmission: boolean,
  type: AnnualReportType,
  documentTypeVersion: AnnualReportVersion = '1'
) => {
  addGroup(
    rows,
    'shortTermInvestments',
    'Kortfristiga placeringar',
    'Summa kortfristiga placeringar',
    (rows) =>
      attachRows(
        accounts,
        false,
        rows,
        previousPeriod,
        'shortTermInvestments',
        isDigitalSubmission,
        type,
        documentTypeVersion
      ),
    isDigitalSubmission
  );
};

const addCashAndBankBalances = (
  accounts: Record<string, ReferenceAccountInformation>,
  rows: RowsBuilder,
  previousPeriod: string,
  isDigitalSubmission: boolean,
  type: AnnualReportType,
  documentTypeVersion: AnnualReportVersion = '1'
) => {
  addGroup(
    rows,
    'cashAndBankBalances',
    'Kassa och bank',
    'Summa kassa och bank',
    (rows) =>
      attachRows(
        accounts,
        false,
        rows,
        previousPeriod,
        'cashAndBankBalances',
        isDigitalSubmission,
        type,
        documentTypeVersion
      ),
    isDigitalSubmission
  );
};

const addRestrictedEquity = (
  accounts: Record<string, ReferenceAccountInformation>,
  rows: RowsBuilder,
  type: AnnualReportType,
  previousPeriod: string,
  isDigitalSubmission?: boolean | null,
  documentTypeVersion: AnnualReportVersion = '1'
) => {
  addGroup(
    rows,
    'restrictedEquity',
    'Bundet eget kapital',
    'Summa bundet eget kapital',
    (rows) =>
      attachRows(
        accounts,
        true,
        rows,
        previousPeriod,
        'restrictedEquity',
        !!isDigitalSubmission,
        type,
        documentTypeVersion
      ),
    !!isDigitalSubmission
  );
};

const addUnrestrictedEquity = (
  accounts: Record<string, ReferenceAccountInformation>,
  rows: RowsBuilder,
  previousPeriod: string,
  type: AnnualReportType,
  isDigitalSubmission?: boolean | null,
  documentTypeVersion: AnnualReportVersion = '1'
) => {
  addGroup(
    rows,
    'unrestrictedEquity',
    'Fritt eget kapital',
    'Summa fritt eget kapital',
    (rows) =>
      attachRows(
        accounts,
        true,
        rows,
        previousPeriod,
        'unrestrictedEquity',
        !!isDigitalSubmission,
        type,
        documentTypeVersion
      ),
    !!isDigitalSubmission
  );
};

const addUntaxedReserves = (
  accounts: Record<string, ReferenceAccountInformation>,
  rows: RowsBuilder,
  previousPeriod: string,
  type: AnnualReportType,
  isDigitalSubmission?: boolean | null,
  documentTypeVersion: AnnualReportVersion = '1'
) => {
  addGroup(
    rows,
    'untaxedReserves',
    'Obeskattade reserver',
    'Summa obeskattade reserver',
    (rows) =>
      attachRows(
        accounts,
        true,
        rows,
        previousPeriod,
        'untaxedReserves',
        !!isDigitalSubmission,
        type,
        documentTypeVersion
      ),
    !!isDigitalSubmission
  );
};

const addAllocations = (
  accounts: Record<string, ReferenceAccountInformation>,
  rows: RowsBuilder,
  type: AnnualReportType,
  previousPeriod: string,
  isDigitalSubmission?: boolean | null
) => {
  addGroup(
    rows,
    'allocations',
    'Avsättningar',
    'Summa avsättningar',
    (rows) => {
      const add = addPost(
        accounts,
        true,
        rows,
        previousPeriod,
        'allocations',
        !!isDigitalSubmission,
        type
      );

      rows.addRowWithType(
        'subtitleAvsättningar',
        'header',
        isDigitalSubmission
          ? label('Avsättningar för pensioner och liknande förpliktelser')
          : value('Avsättningar för pensioner och liknande förpliktelser')
      );

      const structure = sharesCompanyBalanceSheetAccounts(type)['allocations'];
      structure.map((section) => {
        const link = getLinkToNote(section, accounts, !!isDigitalSubmission);
        add(section.row, section.name, section.accounts, link);
      });
    },
    !!isDigitalSubmission
  );
};

const addLongTermLiabilities = (
  accounts: Record<string, ReferenceAccountInformation>,
  rows: RowsBuilder,
  previousPeriod: string,
  type: AnnualReportType,
  isDigitalSubmission?: boolean | null,
  documentTypeVersion: AnnualReportVersion = '1'
) => {
  addGroup(
    rows,
    // should have been longTermLiabilities as ID
    // WARNING: Don't change it now, as it can lead to DATA LOSS IN PRODUCTION
    '1',
    'Långfristiga skulder',
    'Summa långfristiga skulder',
    (rows) =>
      attachRows(
        accounts,
        true,
        rows,
        previousPeriod,
        '1',
        !!isDigitalSubmission,
        type,
        documentTypeVersion
      ),
    !!isDigitalSubmission
  );
};

const addShortTermLiabilities = (
  accounts: Record<string, ReferenceAccountInformation>,
  rows: RowsBuilder,
  previousPeriod: string,
  type: AnnualReportType,
  isDigitalSubmission: boolean,
  documentTypeVersion: AnnualReportVersion = '1'
) => {
  addGroup(
    rows,
    'shortTermLiabilities',
    'Kortfristiga skulder',
    'Summa kortfristiga skulder',
    (rows) =>
      attachRows(
        accounts,
        true,
        rows,
        previousPeriod,
        'shortTermLiabilities',
        !!isDigitalSubmission,
        type,
        documentTypeVersion
      ),
    isDigitalSubmission
  );
};

/**
 *
 *********** CONFIG ***********
 *
 */

export const balanceSheetConfig = (
  accounts: Record<string, ReferenceAccountInformation>,
  period: TimePeriod,
  previousPeriod: TimePeriod | null,
  type: AnnualReportType,
  previousYearKey: string,
  isDigitalSubmission?: boolean | null | undefined,
  documentTypeVersion: AnnualReportVersion = '1'
): BalanceSheet => ({
  active: undefined,
  section: {
    active: undefined,
    assets: table<IxbrlCell>(
      'balanceSheet.section.assets',
      'label',
      'notes',
      { id: 'year', label: period.endDateISO },
      previousPeriod
        ? {
            id: 'previousYear',
            label: previousPeriod.endDateISO,
          }
        : undefined,
      'hidden',
      'yearWarning',
      'previousYearWarning'
    )
      .addRows((rows) =>
        rows
          .addRowWithType(
            'assets',
            'header',
            type === 'k3' ? value('Tillgångar') : label('Tillgångar')
          )
          .addSubRows((rows) => {
            rows
              .addRowWithType(
                'subscribedUnpaidCapital',
                'row',
                value('Tecknat men ej inbetalt kapital'),
                refs(),
                ixbrlCell(
                  ref(
                    id(
                      `${rows.getBaseId()}.subscribedUnpaidCapital.unpaidCapitalVisuallyHiddenSum.year`
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    name: 'se-gen-base:TecknatEjInbetaltKapital',
                    contextRef: 'balans0',
                    saldo: 'debit',
                  }
                ),
                ixbrlCell(
                  ref(
                    id(
                      `${rows.getBaseId()}.subscribedUnpaidCapital.unpaidCapitalVisuallyHiddenSum.previousYear`
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    name: 'se-gen-base:TecknatEjInbetaltKapital',
                    contextRef: 'balans1',
                    saldo: 'debit',
                  }
                )
              )
              .addSubRows((rows) => {
                addSubscribedUnpaidCapital(
                  accounts,
                  rows,
                  type,
                  previousYearKey,
                  !!isDigitalSubmission,
                  documentTypeVersion
                );
                return rows.build();
              })
              .addRowWithType(
                'fixedAssets',
                'header',
                value('Anläggningstillgångar')
              )
              .addSubRows((rows) => {
                addIntangibleAssets(
                  accounts,
                  rows,
                  type,
                  previousYearKey,
                  !!isDigitalSubmission,
                  documentTypeVersion
                );
                addTangibleFixedAssets(
                  accounts,
                  rows,
                  type,
                  previousYearKey,
                  !!isDigitalSubmission,
                  documentTypeVersion
                );
                addFinancialAssets(
                  accounts,
                  rows,
                  type,
                  previousYearKey,
                  !!isDigitalSubmission,
                  documentTypeVersion
                );
                return rows.build();
              })
              .addRowWithType(
                'sumFixedAssets',
                'sum',
                value('Summa anläggningstillgångar'),
                refs(),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        `${rows.getBaseId()}.fixedAssets.intangibleAssetsSum.year`
                      ),
                      id(
                        `${rows.getBaseId()}.fixedAssets.tangibleFixedAssetsSum.year`
                      ),
                      id(
                        `${rows.getBaseId()}.fixedAssets.financialAssetsSum.year`
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    name: 'se-gen-base:Anlaggningstillgangar',
                    contextRef: 'balans0',
                    saldo: 'debit',
                  }
                ),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        `${rows.getBaseId()}.fixedAssets.intangibleAssetsSum.previousYear`
                      ),
                      id(
                        `${rows.getBaseId()}.fixedAssets.tangibleFixedAssetsSum.previousYear`
                      ),
                      id(
                        `${rows.getBaseId()}.fixedAssets.financialAssetsSum.previousYear`
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    name: 'se-gen-base:Anlaggningstillgangar',
                    contextRef: 'balans1',
                    saldo: 'debit',
                  }
                )
              );

            rows
              .addRowWithType(
                'currentAssets',
                'header',
                value('Omsättningstillgångar')
              )
              .addSubRows((rows) => {
                addInventory(
                  accounts,
                  rows,
                  type,
                  previousYearKey,
                  !!isDigitalSubmission,
                  documentTypeVersion
                );
                addShortTermReceivables(
                  accounts,
                  rows,
                  previousYearKey,
                  type,
                  !!isDigitalSubmission,
                  documentTypeVersion
                );
                addShortTermInvestments(
                  accounts,
                  rows,
                  previousYearKey,
                  !!isDigitalSubmission,
                  type,
                  documentTypeVersion
                );
                addCashAndBankBalances(
                  accounts,
                  rows,
                  previousYearKey,
                  !!isDigitalSubmission,
                  type,
                  documentTypeVersion
                );
                return rows.build();
              })
              .addRowWithType(
                'sumCurrentAssets',
                'sum',
                value('Summa omsättningstillgångar'),
                refs(),
                ixbrlCell(
                  ref(
                    sum(
                      id(`${rows.getBaseId()}.currentAssets.inventorySum.year`),
                      id(
                        `${rows.getBaseId()}.currentAssets.shortTermReceivablesSum.year`
                      ),
                      id(
                        `${rows.getBaseId()}.currentAssets.shortTermInvestmentsSum.year`
                      ),
                      id(
                        `${rows.getBaseId()}.currentAssets.cashAndBankBalancesSum.year`
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    name: 'se-gen-base:Omsattningstillgangar',
                    contextRef: 'balans0',
                    saldo: 'debit',
                  }
                ),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        `${rows.getBaseId()}.currentAssets.inventorySum.previousYear`
                      ),
                      id(
                        `${rows.getBaseId()}.currentAssets.shortTermReceivablesSum.previousYear`
                      ),
                      id(
                        `${rows.getBaseId()}.currentAssets.shortTermInvestmentsSum.previousYear`
                      ),
                      id(
                        `${rows.getBaseId()}.currentAssets.cashAndBankBalancesSum.previousYear`
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    name: 'se-gen-base:Omsattningstillgangar',
                    contextRef: 'balans1',
                    saldo: 'debit',
                  }
                )
              );

            return rows.build();
          })
          .addRowWithType(
            'sumAssets',
            'sum',
            value('Summa tillgångar'),
            refs(),
            ixbrlCell(
              ref(
                or(
                  sum(
                    id(`${rows.getBaseId()}.assets.fixedAssets.*Sum.year`),
                    id(`${rows.getBaseId()}.assets.currentAssets.*Sum.year`),
                    id(
                      `${rows.getBaseId()}.assets.subscribedUnpaidCapital.year`
                    )
                  ),
                  0
                )
              ),
              {
                type: 'monetaryItemType',
                name: 'se-gen-base:Tillgangar',
                contextRef: 'balans0',
                saldo: 'debit',
              }
            ),
            ixbrlCell(
              ref(
                or(
                  sum(
                    id(
                      `${rows.getBaseId()}.assets.fixedAssets.*Sum.previousYear`
                    ),
                    id(
                      `${rows.getBaseId()}.assets.currentAssets.*Sum.previousYear`
                    ),
                    id(
                      `${rows.getBaseId()}.assets.subscribedUnpaidCapital.previousYear`
                    )
                  ),
                  0
                )
              ),
              {
                type: 'monetaryItemType',
                name: 'se-gen-base:Tillgangar',
                contextRef: 'balans1',
                saldo: 'debit',
              }
            ),
            undefined,
            getWarningCell('balanceSheet.Tillgangar.year'),
            getWarningCell('balanceSheet.Tillgangar.previousYear')
          )
          .build()
      )
      .build(),
    equityAndLiabilities: table<IxbrlCell>(
      'balanceSheet.section.equityAndLiabilities',
      'label',
      'notes',
      { id: 'year', label: period.endDateISO },
      previousPeriod
        ? {
            id: 'previousYear',
            label: previousPeriod.endDateISO,
          }
        : undefined,
      'hidden',
      'yearWarning',
      'previousYearWarning'
    )
      .addRows((rows) =>
        rows
          .addRowWithType(
            'equityAndLiabilities',
            'header',
            value('Eget kapital och skulder')
          )
          .addSubRows((rows) => {
            rows
              .addRowWithType('equity', 'header', value('Eget kapital'))
              .addSubRows((rows) => {
                addRestrictedEquity(
                  accounts,
                  rows,
                  type,
                  previousYearKey,
                  !!isDigitalSubmission,
                  documentTypeVersion
                );
                addUnrestrictedEquity(
                  accounts,
                  rows,
                  previousYearKey,
                  type,
                  !!isDigitalSubmission,
                  documentTypeVersion
                );
                return rows.build();
              })
              .addRowWithType(
                'sumEquity',
                'sum',
                value('Summa eget kapital'),
                refs(),
                ixbrlCell(
                  ref(
                    sum(
                      id(`${rows.getBaseId()}.equity.restrictedEquitySum.year`),
                      id(
                        `${rows.getBaseId()}.equity.unrestrictedEquitySum.year`
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    name: 'se-gen-base:EgetKapital',
                    contextRef: 'balans0',
                    saldo: 'credit',
                  }
                ),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        `${rows.getBaseId()}.equity.restrictedEquitySum.previousYear`
                      ),
                      id(
                        `${rows.getBaseId()}.equity.unrestrictedEquitySum.previousYear`
                      )
                    )
                  ),
                  {
                    type: 'monetaryItemType',
                    name: 'se-gen-base:EgetKapital',
                    contextRef: 'balans1',
                    saldo: 'credit',
                  }
                ),
                undefined,
                getWarningCell(`${rows.getBaseId()}.sumEquity.year`)
              );

            rows
              .addRowWithType('untaxedReserves', 'header')
              .addSubRows((rows) => {
                addUntaxedReserves(
                  accounts,
                  rows,
                  previousYearKey,
                  type,
                  !!isDigitalSubmission,
                  documentTypeVersion
                );
                return rows.build();
              });

            rows.addRowWithType('allocations', 'header').addSubRows((rows) => {
              addAllocations(
                accounts,
                rows,
                type,
                previousYearKey,
                !!isDigitalSubmission
              );
              return rows.build();
            });

            rows
              .addRowWithType('longTermLiabilities', 'header')
              .addSubRows((rows) => {
                addLongTermLiabilities(
                  accounts,
                  rows,
                  previousYearKey,
                  type,
                  !!isDigitalSubmission,
                  documentTypeVersion
                );
                return rows.build();
              });
            rows
              .addRowWithType('shortTermLiabilities', 'header')
              .addSubRows((rows) => {
                addShortTermLiabilities(
                  accounts,
                  rows,
                  previousYearKey,
                  type,
                  !!isDigitalSubmission,
                  documentTypeVersion
                );
                return rows.build();
              });

            return rows.build();
          })
          .addRowWithType(
            'sumEquityAndLiabilities',
            'sum',
            value('Summa eget kapital och skulder'),
            refs(),
            ixbrlCell(
              ref(
                sum(
                  id(
                    `${rows.getBaseId()}.equityAndLiabilities.equity.*Sum.year`
                  ),
                  id(
                    `${rows.getBaseId()}.equityAndLiabilities.untaxedReserves.*Sum.year`
                  ),
                  id(
                    `${rows.getBaseId()}.equityAndLiabilities.allocations.*Sum.year`
                  ),
                  id(
                    `${rows.getBaseId()}.equityAndLiabilities.longTermLiabilities.*Sum.year`
                  ),
                  id(
                    `${rows.getBaseId()}.equityAndLiabilities.shortTermLiabilities.*Sum.year`
                  )
                )
              ),
              {
                type: 'monetaryItemType',
                name: 'se-gen-base:EgetKapitalSkulder',
                contextRef: 'balans0',
                saldo: 'credit',
              }
            ),
            ixbrlCell(
              ref(
                sum(
                  id(
                    `${rows.getBaseId()}.equityAndLiabilities.equity.*Sum.previousYear`
                  ),
                  id(
                    `${rows.getBaseId()}.equityAndLiabilities.untaxedReserves.*Sum.previousYear`
                  ),
                  id(
                    `${rows.getBaseId()}.equityAndLiabilities.allocations.*Sum.previousYear`
                  ),
                  id(
                    `${rows.getBaseId()}.equityAndLiabilities.longTermLiabilities.*Sum.previousYear`
                  ),
                  id(
                    `${rows.getBaseId()}.equityAndLiabilities.shortTermLiabilities.*Sum.previousYear`
                  )
                )
              ),
              {
                type: 'monetaryItemType',
                name: 'se-gen-base:EgetKapitalSkulder',
                contextRef: 'balans1',
                saldo: 'credit',
              }
            ),
            undefined,
            getWarningCell('balanceSheet.EgetKapitalSkulder.year'),
            getWarningCell('balanceSheet.EgetKapitalSkulder.previousYear')
          )
          .build()
      )
      .build(),
  },
});

export const balanceSheetReferences = (): Record<string, string> => ({
  'balanceSheet.EgetKapital.year': id(
    'balanceSheet.section.equityAndLiabilities.equityAndLiabilities.sumEquity.year'
  ),
  'balanceSheet.EgetKapital.previousYear': id(
    'balanceSheet.section.equityAndLiabilities.equityAndLiabilities.sumEquity.previousYear'
  ),
  'balanceSheet.ObeskattadeReserver.year': id(
    'balanceSheet.section.equityAndLiabilities.equityAndLiabilities.untaxedReserves.untaxedReservesSum.year'
  ),
  'balanceSheet.ObeskattadeReserver.previousYear': id(
    'balanceSheet.section.equityAndLiabilities.equityAndLiabilities.untaxedReserves.untaxedReservesSum.previousYear'
  ),
  'balanceSheet.EgetKapitalSkulder.year': id(
    'balanceSheet.section.equityAndLiabilities.sumEquityAndLiabilities.year'
  ),
  'balanceSheet.EgetKapitalSkulder.previousYear': id(
    'balanceSheet.section.equityAndLiabilities.sumEquityAndLiabilities.previousYear'
  ),
  'balanceSheet.Tillgangar.year': id(
    'balanceSheet.section.assets.sumAssets.year'
  ),
  'balanceSheet.Tillgangar.previousYear': id(
    'balanceSheet.section.assets.sumAssets.previousYear'
  ),
  'balanceSheet.Aktiekapital.year': id(
    'balanceSheet.section.equityAndLiabilities.equityAndLiabilities.equity.restrictedEquity.2.year'
  ),
  'balanceSheet.Aktiekapital.previousYear': id(
    'balanceSheet.section.equityAndLiabilities.equityAndLiabilities.equity.restrictedEquity.2.previousYear'
  ),
  'balanceSheet.FrittEgetKapital.year': id(
    'balanceSheet.section.equityAndLiabilities.equityAndLiabilities.equity.unrestrictedEquitySum.year'
  ),
  'balanceSheet.FrittEgetKapital.previousYear': id(
    'balanceSheet.section.equityAndLiabilities.equityAndLiabilities.equity.unrestrictedEquitySum.previousYear'
  ),
  'balanceSheet.AretsResultatEgetKapital.year': id(
    'balanceSheet.section.equityAndLiabilities.equityAndLiabilities.equity.unrestrictedEquity.4.year'
  ),
  'balanceSheet.AretsResultatEgetKapital.previousYear': id(
    'balanceSheet.section.equityAndLiabilities.equityAndLiabilities.equity.unrestrictedEquity.4.previousYear'
  ),
  'balanceSheet.ErhalletAktieAgartillskott.year': id(
    'balanceSheet.section.equityAndLiabilities.equityAndLiabilities.equity.unrestrictedEquity.3.year'
  ),
  'balanceSheet.BalanseratResultat.year': id(
    'balanceSheet.section.equityAndLiabilities.equityAndLiabilities.equity.unrestrictedEquity.2.year'
  ),
  'balanceSheet.BalanseratResultat.previousYear': id(
    'balanceSheet.section.equityAndLiabilities.equityAndLiabilities.equity.unrestrictedEquity.2.previousYear'
  ),
  'balanceSheet.BalanseradeUtgifterUtvecklingsarbetenLiknandeArbeten.year': id(
    'balanceSheet.section.assets.assets.fixedAssets.intangibleAssets.1.year'
  ),
  'balanceSheet.KoncessionerPatentLicenserVarumarkenLiknandeRattigheter.year':
    id(
      'balanceSheet.section.assets.assets.fixedAssets.intangibleAssets.2.year'
    ),
  'balanceSheet.HyresratterLiknandeRattigheter.year': id(
    'balanceSheet.section.assets.assets.fixedAssets.intangibleAssets.3.year'
  ),
  'balanceSheet.Goodwill.year': id(
    'balanceSheet.section.assets.assets.fixedAssets.intangibleAssets.4.year'
  ),
  'balanceSheet.ForskottImmateriellaAnlaggningstillgangar.year': id(
    'balanceSheet.section.assets.assets.fixedAssets.intangibleAssets.5.year'
  ),
  'balanceSheet.otherIntangibleAssets.year': id(
    'balanceSheet.section.assets.assets.fixedAssets.intangibleAssets.6.year'
  ),
  'balanceSheet.PagaendeUtvecklingsarbetenForskottImmateriellaAnlaggningstillgangar.year':
    id(
      'balanceSheet.section.assets.assets.fixedAssets.intangibleAssets.7.year'
    ),
  'balanceSheet.ByggnaderMark.year': id(
    'balanceSheet.section.assets.assets.fixedAssets.tangibleFixedAssets.1.year'
  ),
  'balanceSheet.MaskinerAndraTekniskaAnlaggningar.year': id(
    'balanceSheet.section.assets.assets.fixedAssets.tangibleFixedAssets.2.year'
  ),
  'balanceSheet.InventarierVerktygInstallationer.year': id(
    'balanceSheet.section.assets.assets.fixedAssets.tangibleFixedAssets.3.year'
  ),
  'balanceSheet.OvrigaMateriellaAnlaggningstillgangar.year': id(
    'balanceSheet.section.assets.assets.fixedAssets.tangibleFixedAssets.6.year'
  ),
  'balanceSheet.ForbattringsutgifterAnnansFastighet.year': id(
    'balanceSheet.section.assets.assets.fixedAssets.tangibleFixedAssets.4.year'
  ),
  'balanceSheet.PagaendeNyanlaggningarForskottMateriellaAnlaggningstillgangar.year':
    id(
      'balanceSheet.section.assets.assets.fixedAssets.tangibleFixedAssets.5.year'
    ),
  'balanceSheet.AndelarKoncernforetag.year': id(
    'balanceSheet.section.assets.assets.fixedAssets.financialAssets.1.year'
  ),
  'balanceSheet.AndelarIntresseforetagGemensamtStyrdaForetag.year': id(
    'balanceSheet.section.assets.assets.fixedAssets.financialAssets.3.year'
  ),
  'balanceSheet.AgarintressenOvrigaForetag.year': id(
    'balanceSheet.section.assets.assets.fixedAssets.financialAssets.5.year'
  ),
  'balanceSheet.AndraLangfristigaVardepappersinnehav.year': id(
    'balanceSheet.section.assets.assets.fixedAssets.financialAssets.7.year'
  ),
  'balanceSheet.FordringarKoncernforetagLangfristiga.year': id(
    'balanceSheet.section.assets.assets.fixedAssets.financialAssets.2.year'
  ),
  'balanceSheet.FordringarIntresseforetagGemensamtStyrdaForetagLangfristiga.year':
    id('balanceSheet.section.assets.assets.fixedAssets.financialAssets.4.year'),
  'balanceSheet.FordringarOvrigaForetagAgarintresseLangfristiga.year': id(
    'balanceSheet.section.assets.assets.fixedAssets.financialAssets.6.year'
  ),
  'balanceSheet.LanDelagareNarstaende.year': id(
    'balanceSheet.section.assets.assets.fixedAssets.financialAssets.8.year'
  ),
  'balanceSheet.AndraLangfristigaFordringar.year': id(
    'balanceSheet.section.assets.assets.fixedAssets.financialAssets.10.year'
  ),
  'balanceSheet.UppskjutenSkattefordran.year': id(
    'balanceSheet.section.assets.assets.fixedAssets.financialAssets.9.year'
  ),
  'balanceSheet.LagerRavarorFornodenheter.year': id(
    'balanceSheet.section.assets.assets.currentAssets.inventory.1.year'
  ),
  'balanceSheet.LagerVarorUnderTillverkning.year': id(
    'balanceSheet.section.assets.assets.currentAssets.inventory.2.year'
  ),
  'balanceSheet.LagerFardigaVarorHandelsvaror.year': id(
    'balanceSheet.section.assets.assets.currentAssets.inventory.3.year'
  ),
  'balanceSheet.OvrigaLagertillgangar.year': id(
    'balanceSheet.section.assets.assets.currentAssets.inventory.6.year'
  ),
  'balanceSheet.PagaendeArbetenAnnansRakningOmsattningstillgangar.year': id(
    'balanceSheet.section.assets.assets.currentAssets.inventory.4.year'
  ),
  'balanceSheet.ForskottTillLeverantorer.year': id(
    'balanceSheet.section.assets.assets.currentAssets.inventory.5.year'
  ),
  'balanceSheet.Kundfordringar.year': id(
    'balanceSheet.section.assets.assets.currentAssets.shortTermReceivables.1.year'
  ),
  'balanceSheet.FordringarKoncernforetagKortfristiga.year': id(
    'balanceSheet.section.assets.assets.currentAssets.shortTermReceivables.2.year'
  ),
  'balanceSheet.FordringarIntresseforetagGemensamtStyrdaForetagKortfristiga.year':
    id(
      'balanceSheet.section.assets.assets.currentAssets.shortTermReceivables.3.year'
    ),
  'balanceSheet.FordringarOvrigaforetagAgarintresseKortfristiga.year': id(
    'balanceSheet.section.assets.assets.currentAssets.shortTermReceivables.4.year'
  ),
  'balanceSheet.OvrigaFordringarKortfristiga.year': id(
    'balanceSheet.section.assets.assets.currentAssets.shortTermReceivables.5.year'
  ),
  'balanceSheet.AktuellSkattefodran.year': id(
    'balanceSheet.section.assets.assets.currentAssets.shortTermReceivables.6.year'
  ),
  'balanceSheet.UpparbetadEjFaktureradIntakt.year': id(
    'balanceSheet.section.assets.assets.currentAssets.shortTermReceivables.7.year'
  ),
  'balanceSheet.ForutbetaldaKostnaderUpplupnaIntakter.year': id(
    'balanceSheet.section.assets.assets.currentAssets.shortTermReceivables.8.year'
  ),
  'balanceSheet.AndelarKoncernforetagKortfristiga.year': id(
    'balanceSheet.section.assets.assets.currentAssets.shortTermInvestments.1.year'
  ),
  'balanceSheet.OvrigaKortfristigaPlaceringar.year': id(
    'balanceSheet.section.assets.assets.currentAssets.shortTermInvestments.2.year'
  ),
  'balanceSheet.KassaBankExklRedovisningsmedel.year': id(
    'balanceSheet.section.assets.assets.currentAssets.cashAndBankBalances.1.year'
  ),
  'balanceSheet.Redovisningsmedel.year': id(
    'balanceSheet.section.assets.assets.currentAssets.cashAndBankBalances.2.year'
  ),
  'balanceSheet.equityManualK2.year': id(
    'balanceSheet.section.equityAndLiabilities.equityAndLiabilities.equity.restrictedEquity.1.year'
  ),
  'balanceSheet.EjRegistreratAktiekapital.year': id(
    'balanceSheet.section.equityAndLiabilities.equityAndLiabilities.equity.restrictedEquity.3.year'
  ),
  'balanceSheet.OverkursfondBunden.year': id(
    'balanceSheet.section.equityAndLiabilities.equityAndLiabilities.equity.restrictedEquity.6.year'
  ),
  'balanceSheet.Uppskrivningsfond.year': id(
    'balanceSheet.section.equityAndLiabilities.equityAndLiabilities.equity.restrictedEquity.4.year'
  ),
  'balanceSheet.Reservfond.year': id(
    'balanceSheet.section.equityAndLiabilities.equityAndLiabilities.equity.restrictedEquity.7.year'
  ),
  'balanceSheet.Kapitalandelsfond.year': id(
    'balanceSheet.section.equityAndLiabilities.equityAndLiabilities.equity.restrictedEquity.8.year'
  ),
  'balanceSheet.FondUtvecklingsutgifter.year': id(
    'balanceSheet.section.equityAndLiabilities.equityAndLiabilities.equity.restrictedEquity.5.year'
  ),
  'balanceSheet.Overkursfond.year': id(
    'balanceSheet.section.equityAndLiabilities.equityAndLiabilities.equity.unrestrictedEquity.1.year'
  ),
  'balanceSheet.FondVerkligtVarde.year': id(
    'balanceSheet.section.equityAndLiabilities.equityAndLiabilities.equity.unrestrictedEquity.5.year'
  ),
  'balanceSheet.Periodiseringsfonder.year': id(
    'balanceSheet.section.equityAndLiabilities.equityAndLiabilities.untaxedReserves.untaxedReserves.1.year'
  ),
  'balanceSheet.AckumuleradeOveravskrivningar.year': id(
    'balanceSheet.section.equityAndLiabilities.equityAndLiabilities.untaxedReserves.untaxedReserves.2.year'
  ),
  'balanceSheet.OvrigaObeskattadeReserver.year': id(
    'balanceSheet.section.equityAndLiabilities.equityAndLiabilities.untaxedReserves.untaxedReserves.4.year'
  ),
  'balanceSheet.Ersattningsfonder.year': id(
    'balanceSheet.section.equityAndLiabilities.equityAndLiabilities.untaxedReserves.untaxedReserves.3.year'
  ),
  'balanceSheet.AvsattningarPensionerLiknandeForpliktelserEnligtLag.year': id(
    'balanceSheet.section.equityAndLiabilities.equityAndLiabilities.allocations.allocations.1.year'
  ),
  'balanceSheet.OvrigaAvsattningar.year': id(
    'balanceSheet.section.equityAndLiabilities.equityAndLiabilities.allocations.allocations.3.year'
  ),
  'balanceSheet.Obligationslan.year': id(
    'balanceSheet.section.equityAndLiabilities.equityAndLiabilities.longTermLiabilities.1.1.year'
  ),
  'balanceSheet.CheckrakningskreditLangfristig.year': id(
    'balanceSheet.section.equityAndLiabilities.equityAndLiabilities.longTermLiabilities.1.3.year'
  ),
  'balanceSheet.OvrigaLangfristigaSkulderKreditinstitut.year': id(
    'balanceSheet.section.equityAndLiabilities.equityAndLiabilities.longTermLiabilities.1.5.year'
  ),
  'balanceSheet.SkulderKoncernforetagLangfristiga.year': id(
    'balanceSheet.section.equityAndLiabilities.equityAndLiabilities.longTermLiabilities.1.6.year'
  ),
  'balanceSheet.SkulderIntresseforetagGemensamtStyrdaForetagLangfristiga.year':
    id(
      'balanceSheet.section.equityAndLiabilities.equityAndLiabilities.longTermLiabilities.1.7.year'
    ),
  'balanceSheet.SkulderOvrigaForetagAgarintresseLangfristiga.year': id(
    'balanceSheet.section.equityAndLiabilities.equityAndLiabilities.longTermLiabilities.1.8.year'
  ),
  'balanceSheet.OvrigaLangfristigaSkulder.year': id(
    'balanceSheet.section.equityAndLiabilities.equityAndLiabilities.longTermLiabilities.1.9.year'
  ),
  'balanceSheet.CheckrakningskreditKortfristig.year': id(
    'balanceSheet.section.equityAndLiabilities.equityAndLiabilities.shortTermLiabilities.shortTermLiabilities.1.year'
  ),
  'balanceSheet.OvrigaKortfristigaSkulderKreditinstitut.year': id(
    'balanceSheet.section.equityAndLiabilities.equityAndLiabilities.shortTermLiabilities.shortTermLiabilities.2.year'
  ),
  'balanceSheet.ForskottFranKunder.year': id(
    'balanceSheet.section.equityAndLiabilities.equityAndLiabilities.shortTermLiabilities.shortTermLiabilities.3.year'
  ),
  'balanceSheet.PagaendeArbetenAnnansRakningKortfristigaSkulder.year': id(
    'balanceSheet.section.equityAndLiabilities.equityAndLiabilities.shortTermLiabilities.shortTermLiabilities.4.year'
  ),
  'balanceSheet.FaktureradEjUpparbetadIntakt.year': id(
    'balanceSheet.section.equityAndLiabilities.equityAndLiabilities.shortTermLiabilities.shortTermLiabilities.12.year'
  ),
  'balanceSheet.Leverantorsskulder.year': id(
    'balanceSheet.section.equityAndLiabilities.equityAndLiabilities.shortTermLiabilities.shortTermLiabilities.5.year'
  ),
  'balanceSheet.Vaxelskulder.year': id(
    'balanceSheet.section.equityAndLiabilities.equityAndLiabilities.shortTermLiabilities.shortTermLiabilities.6.year'
  ),
  'balanceSheet.SkulderKoncernforetagKortfristiga.year': id(
    'balanceSheet.section.equityAndLiabilities.equityAndLiabilities.shortTermLiabilities.shortTermLiabilities.7.year'
  ),
  'balanceSheet.SkulderIntresseforetagGemensamtStyrdaForetagKortfristiga.year':
    id(
      'balanceSheet.section.equityAndLiabilities.equityAndLiabilities.shortTermLiabilities.shortTermLiabilities.8.year'
    ),
  'balanceSheet.SkulderOvrigaForetagAgarintresseKortfristiga.year': id(
    'balanceSheet.section.equityAndLiabilities.equityAndLiabilities.shortTermLiabilities.shortTermLiabilities.9.year'
  ),
  'balanceSheet.Skatteskulder.year': id(
    'balanceSheet.section.equityAndLiabilities.equityAndLiabilities.shortTermLiabilities.shortTermLiabilities.10.year'
  ),
  'balanceSheet.OvrigaKortfristigaSkulder.year': id(
    'balanceSheet.section.equityAndLiabilities.equityAndLiabilities.shortTermLiabilities.shortTermLiabilities.11.year'
  ),
  'balanceSheet.UpplupnaKostnaderForutbetaldaIntakter.year': id(
    'balanceSheet.section.equityAndLiabilities.equityAndLiabilities.shortTermLiabilities.shortTermLiabilities.13.year'
  ),
});

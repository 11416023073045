import React from 'react';
import { useIntl } from 'react-intl';
import {
  SharesCompanyManagementReport,
  EconomicAssociationManagementReport,
  IndividualCompanyManagementReport,
  AnnualReportType,
  AnnualGeneralMeeting,
  LimitedCompaniesManagementReport,
  FoundationManagementReport,
  CommonNotes,
  Settings,
} from '@agoy/annual-report-document';

import { Field, booleanValue, stringValue } from '@agoy/document';
import styled from '@emotion/styled';
import Typography from '../../UI/Typography/Typography';

import Activities from './Parts/Activities';
import Business from './Parts/Business';
import MultiYearOverview from './Parts/MultiYearOverview/MultiYearOverview';
import ProfitProposal from './Parts/ProfitProposal';
import ResultsDisposition from './Parts/ResultsDisposition';
import ChangesInEquity from './Parts/ChangesInEquity';
import TextSection from './Parts/TextSection';
import { Heading, Section } from '../../UI';
import { IXElement } from '../../ARKeyToIX';
import DefinitionOfKey from './Parts/DefinitionOfKey/DefinitionOfKey';
import ProfitProposalK3 from './Parts/ProfitProposalK3';
import { FormattingContextProvider } from '@agoy/formatting';

const Container = styled.div`
  @media print {
    page-break-before: always;
  }
`;

const OffScreenContainer = styled.div`
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
`;

const textSections = [
  'viability',
  'futureDevelopment',
  'rAndD',
  'subsidiariesAbroad',
  'sustainabilityInfo',
  'sustainabilityReport',
  'permitActivities',
  'simplifiedField',
  'ownShares',
  'useOfFinancialInstruments',
];

interface ManagementReportContentProps {
  annualGeneralMeeting?: AnnualGeneralMeeting;
  managementReport?:
    | SharesCompanyManagementReport
    | EconomicAssociationManagementReport
    | IndividualCompanyManagementReport
    | LimitedCompaniesManagementReport
    | FoundationManagementReport;
  notes: CommonNotes;
  transitionK3toK2: boolean;
  isDigitalSubmission: boolean | null | undefined;
  reportType: AnnualReportType;
  settings: Settings;
  version: '1' | '2';
}

const ManagementReportPage = ({
  annualGeneralMeeting,
  managementReport,
  notes,
  transitionK3toK2,
  isDigitalSubmission,
  reportType,
  settings,
  version,
}: ManagementReportContentProps): JSX.Element | null => {
  const { formatMessage } = useIntl();

  if (managementReport == null) {
    return null;
  }

  const getTitle = () => {
    if ('activities' in managementReport) {
      return (
        stringValue(managementReport.activities.heading) ||
        formatMessage({
          id: 'annualReport.menu.managementReport',
        })
      );
    }

    return formatMessage({
      id: 'annualReport.menu.managementReport',
    });
  };

  const shouldHideTitle = (id: string) => {
    return id === 'simplifiedField' ? true : false;
  };

  const shouldDisplayInThousands = (type: Field) =>
    booleanValue(settings?.section.formatting.displayInThousands) ||
    booleanValue(type) ||
    false;

  const shouldDisplayLabel = (type: Field) =>
    !booleanValue(settings?.section.formatting.displayInThousands) &&
    booleanValue(type);

  return (
    <Container>
      {'simplifiedField' in managementReport && (
        <Section>
          <Heading title={getTitle()} variant="h1" />
        </Section>
      )}
      {'activities' in managementReport && (
        <>
          {isDigitalSubmission &&
            !managementReport.activities.boardText.active && (
              <Typography margin="bottom">
                {stringValue(managementReport.activities.boardText)}
              </Typography>
            )}
          {!managementReport.activities.boardText.active &&
            !isDigitalSubmission &&
            !managementReport.activities.boardTextWithCeo.active &&
            !managementReport.activities.boardWithoutCeo.active &&
            !managementReport.activities.liquidatorText.active && (
              <Typography margin="bottom">
                {stringValue(managementReport.activities.boardText)}
              </Typography>
            )}
          {managementReport.activities.boardTextWithCeo?.active &&
            !isDigitalSubmission && (
              <Typography margin="bottom">
                {stringValue(managementReport.activities.boardTextWithCeo)}
              </Typography>
            )}
          {managementReport.activities.boardWithoutCeo?.active &&
            !isDigitalSubmission && (
              <Typography margin="bottom">
                {stringValue(managementReport.activities.boardWithoutCeo)}
              </Typography>
            )}
          {managementReport.activities.liquidatorText?.active &&
            !isDigitalSubmission && (
              <Typography margin="bottom">
                {stringValue(managementReport.activities.liquidatorText)}
              </Typography>
            )}
          {!managementReport.activities.currencyText.active &&
            (reportType === 'k2' ? (
              <>
                <OffScreenContainer>
                  <IXElement arPart={managementReport.activities.currencyText}>
                    se-mem-base:BeloppsformatNormalformMember
                  </IXElement>
                </OffScreenContainer>
                <Typography margin="bottom">
                  {stringValue(managementReport.activities.currencyText)}
                </Typography>
              </>
            ) : (
              <Typography>
                <IXElement arPart={managementReport.activities.currencyText}>
                  {stringValue(managementReport.activities.currencyText)}
                </IXElement>
              </Typography>
            ))}
          <Section>
            <Heading title={getTitle()} variant="h1" />
          </Section>
          {managementReport.activities.active && (
            <Activities
              reportPart={managementReport.activities}
              title={stringValue(managementReport.activities.activityLabel)}
              generallyLabel={stringValue(
                managementReport.activities.generallyLabel
              )}
            />
          )}
        </>
      )}

      {'business' in managementReport && managementReport.business.active && (
        <Business
          reportPart={managementReport.business}
          title={stringValue(managementReport.business.businessLabel)}
        />
      )}

      {textSections
        .filter(
          (id) =>
            managementReport[id] != null &&
            managementReport[id]?.active !== false
        )
        .map((id) => (
          <Section key={id}>
            <TextSection
              fullId={`managementReport.${id}`}
              id={id}
              reportPart={managementReport[id]}
              hideTitle={shouldHideTitle(id)}
            />
          </Section>
        ))}
      {'multiYearOverview' in managementReport &&
        managementReport.multiYearOverview.active && (
          <FormattingContextProvider
            displayInThousands={shouldDisplayInThousands(
              settings?.section.formatting.displayMultiYearOverviewInThousands
            )}
            labelDisplayInThousands={shouldDisplayLabel(
              settings?.section.formatting.displayMultiYearOverviewInThousands
            )}
          >
            <MultiYearOverview
              settings={settings}
              reportPart={managementReport.multiYearOverview}
              title={stringValue(
                managementReport.multiYearOverview.multiYearLabel
              )}
              commentLabel={stringValue(
                managementReport.multiYearOverview.multiYearTextLabel
              )}
              isDigitalSubmission={isDigitalSubmission}
            />
          </FormattingContextProvider>
        )}
      {'definitionOfKey' in managementReport &&
        managementReport.definitionOfKey.active && (
          <DefinitionOfKey
            reportPart={managementReport.definitionOfKey}
            title={stringValue(
              managementReport.definitionOfKey.definitionOfKeyLabel
            )}
          />
        )}
      {'changesInEquity' in managementReport &&
        managementReport.changesInEquity.active && (
          <FormattingContextProvider
            displayInThousands={shouldDisplayInThousands(
              settings?.section.formatting.displayChangesInEquityInThousands
            )}
            labelDisplayInThousands={shouldDisplayLabel(
              settings?.section.formatting.displayChangesInEquityInThousands
            )}
          >
            <ChangesInEquity
              managementReport={managementReport}
              reportPart={managementReport.changesInEquity}
              notes={notes}
              transitionK3toK2={transitionK3toK2}
              isDigitalSubmission={isDigitalSubmission}
              version={version}
              reportType={reportType}
            />
          </FormattingContextProvider>
        )}
      {'resultsDisposition' in managementReport &&
        managementReport.resultsDisposition.active && (
          <FormattingContextProvider
            displayInThousands={shouldDisplayInThousands(
              settings?.section.formatting.displayResultsDispositionInThousands
            )}
            labelDisplayInThousands={shouldDisplayLabel(
              settings?.section.formatting.displayResultsDispositionInThousands
            )}
          >
            <ResultsDisposition
              managementReport={managementReport}
              reportPart={managementReport.resultsDisposition}
              isDigitalSubmission={isDigitalSubmission}
            />
          </FormattingContextProvider>
        )}
      {'profitProposal' in managementReport &&
        managementReport.profitProposal.active &&
        reportType !== 'k3' && (
          <ProfitProposal
            annualGeneralMeeting={annualGeneralMeeting}
            managementReport={managementReport}
            reportPart={managementReport.profitProposal}
          />
        )}
      {'profitProposalK3' in managementReport &&
        managementReport.profitProposalK3.active &&
        reportType === 'k3' && (
          <ProfitProposalK3
            managementReport={managementReport}
            reportPart={managementReport.profitProposalK3}
          />
        )}
    </Container>
  );
};

export default ManagementReportPage;

import React from 'react';
import { getClasses } from '@agoy/common';
import Cell from '_annual-report/components/AnnualReportView/UI/Cell';
import {
  isRowWithoutLabel,
  isRowWithoutYearValueAndSubRows,
  isSumRowWithZeroValue,
} from '_financial-report/utils';
import When from '_shared/components/When/When';
import { TableBodyCell, TableRow } from './TableComponents';
import { ReportTableRowProps } from './types';

const ReportTableRow = (props: ReportTableRowProps) => {
  const { baseId, row, columns, level, className = '' } = props;

  const renderedSubRows = row.rows ?? [];
  const sumRowWithZeroValue = isSumRowWithZeroValue(row);
  const rowWithoutYearValueAndSubRows = isRowWithoutYearValueAndSubRows(row);
  const rowWithoutLabel = isRowWithoutLabel(row);

  const shouldHideRow =
    sumRowWithZeroValue || rowWithoutYearValueAndSubRows || rowWithoutLabel;

  return (
    <When isTrue={!shouldHideRow}>
      <TableRow key={row.id} className={className}>
        {columns.map((col) => {
          const cell = row.cells?.[col.id];
          const key = `${row.id}.${col.id}`;
          if (!cell) {
            return <TableBodyCell key={key} />;
          }

          const isNotHeaderOrSumRow = !(
            row.type && ['header', 'sum'].includes(row.type)
          );
          const isRowWithoutSubRows =
            !('rows' in row) || (row.rows && row.rows.length === 0);

          const cellId = `${baseId}.${key}`;
          const cellClassNames = getClasses({
            notes: col.id === 'notes',
            'regular-row': !!(isRowWithoutSubRows && isNotHeaderOrSumRow),
          });

          return (
            <TableBodyCell
              className={`${cellClassNames} ${row.type ?? ''}`}
              level={level}
              key={key}
            >
              <Cell cell={cell} id={cellId} />
            </TableBodyCell>
          );
        })}
      </TableRow>
      {renderedSubRows.map((subRow) => (
        <React.Fragment key={subRow.id}>
          <ReportTableRow
            {...props}
            baseId={`${baseId}.${row.id}`}
            row={subRow}
            level={level + 1}
            className={`${row.id}-child`}
          />
        </React.Fragment>
      ))}
    </When>
  );
};

export default ReportTableRow;

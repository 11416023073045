import React from 'react';
import styled from '@emotion/styled';
import { Typography, TextField, TextFieldProps } from '@material-ui/core';
import { getClasses } from '@agoy/common';
import EditField from './EditField';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

const Label = styled(Typography)`
  position: absolute;
  top: ${(props) => props.theme.spacing(1)}px;
  left: ${(props) => props.theme.spacing(2)}px;
  font-family: 'Exo 2';
  font-size: 10.5px;
  line-height: 15.75px;
  color: ${({ theme }) => theme.palette.grey[500]};
  z-index: 40;

  &.required-field::after {
    content: ' *';
    vertical-align: top;
  }
`;

const StyledField = styled(TextField)`
  .MuiOutlinedInput-root {
    padding-top: ${(props) => props.theme.spacing(2)}px;
    font-family: 'Exo 2';

    border-radius: 8px;
  }
  .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${({ theme }) => theme.palette.grey[500]};
  }
`;

const StyledTextField = ({ label, ...props }) => {
  const classNames = getClasses({
    'required-field': props.required,
  });

  return (
    <Wrapper>
      <Label className={classNames}>{label}</Label>
      <StyledField variant="outlined" size="small" fullWidth {...props} />
    </Wrapper>
  );
};

type LabelFieldProps = {
  onChange?: (value: string) => void;
  label?: string;
  value?: string;
  disabled?: boolean;
  className?: string;
  inputProps?: TextFieldProps;
};

const LabelField = ({
  onChange,
  label,
  value,
  disabled,
  className,
  inputProps,
}: LabelFieldProps) => {
  return (
    <EditField
      component={StyledTextField}
      onChange={onChange}
      value={value}
      disabled={disabled}
      InputProps={{ label, className, ...inputProps }}
    />
  );
};

export default LabelField;

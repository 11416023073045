import { table, value } from '@agoy/document';
import { NoteOneTableSection } from '../../../../common';
import { previousYear, year } from '../../../../common/utils/date-util';
import { field, range } from '../../../../common/utils/util';
import { sumNotesRowsById } from '../../../../common/utils/accountSumsUtil';

export const getNote42 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null,
  documentTypeVersion = '1'
): NoteOneTableSection => {
  return {
    active: false,
    number: value(undefined),
    name: value('Övriga immateriella anläggningstillgångar'),
    table: table(
      'notes.note42.table',
      { id: 'label', label: '' },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      .addRows((rows) =>
        rows
          .addRow(
            '1',
            noteLabel('Ingående anskaffningsvärden'),
            value(undefined), // no acc ranges specified for this note
            value(undefined)
          )
          .addRow('2', noteLabel('Inköp'), value(undefined), value(undefined))
          .addRow(
            '3',
            noteLabel('Försäljningar/utrangeringar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '4',
            noteLabel('Omklassificeringar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '5',
            noteLabel('Utgående ackumulerade anskaffningsvärden'),
            sumNotesRowsById(42, range(1, 5), 'year'),
            sumNotesRowsById(42, range(1, 5), 'previousYear')
          )
          .addRow(
            '6',
            noteLabel('Ingående avskrivningar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '7',
            noteLabel('Försäljningar/utrangeringar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '8',
            noteLabel('Omklassificeringar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '9',
            noteLabel('Årets avskrivningar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '10',
            noteLabel('Utgående ackumulerade avskrivningar'),
            sumNotesRowsById(42, range(6, 10), 'year'),
            sumNotesRowsById(42, range(6, 10), 'previousYear')
          )
          .addRow(
            '11',
            noteLabel('Ingående uppskrivningar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '12',
            noteLabel('Försäljningar/utrangeringar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '13',
            noteLabel('Omklassificeringar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '14',
            noteLabel('Årets uppskrivningar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '15',
            noteLabel('Årets nedskrivningar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '16',
            noteLabel('Årets avskrivningar på uppskrivet belopp'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '17',
            noteLabel('Utgående ackumulerade uppskrivningar netto'),
            sumNotesRowsById(42, range(11, 17), 'year'),
            sumNotesRowsById(42, range(11, 17), 'previousYear')
          )
          .addRow(
            '18',
            noteLabel('Ingående nedskrivningar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '19',
            noteLabel('Försäljningar/utrangeringar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '20',
            noteLabel('Återförd nedskrivning'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '21',
            noteLabel('Omklassificeringar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '22',
            noteLabel('Årets nedskrivningar'),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '23',
            noteLabel('Utgående ackumulerade nedskrivningar'),
            sumNotesRowsById(42, range(18, 23), 'year'),
            sumNotesRowsById(42, range(18, 23), 'previousYear')
          )
          .addRow(
            '24',
            noteLabel('Utgående redovisat värde'),
            sumNotesRowsById(42, [5, 10, 17, 23], 'year'),
            sumNotesRowsById(42, [5, 10, 17, 23], 'previousYear')
          )
          .addRow(
            '25',
            noteLabel(
              'Tillgångar anskaffade med offentligt bidrag ingår med redovisat anskaffningsvärde'
            ),
            value(undefined),
            value(undefined)
          )
          .addRow(
            '26',
            noteLabel(
              'I ovanstående redovisat värde ingår leasade tillgångar med'
            ),
            value(undefined),
            value(undefined)
          )
          .build()
      )
      .newRowTemplate(value(''), value(''), value(undefined))
      .build(),
    commentLabel: field('Kommentar'),
    comment: field(''),
    group: value(undefined),
    section: value(undefined),
    validForReportType: {
      k2: true,
      k3: documentTypeVersion === '1',
    },
  };
};

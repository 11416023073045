import { Cell } from '@agoy/document';
import { TaxCalculationPart } from '@agoy/tax-document';
import { TableServiceType } from '_shared/components/CommonTable';
import {
  updateTaxTableRowReference,
  resetGenericTax,
  deleteTaxTableRow,
  updateTaxTableRowLabel,
  updateTaxTableRowValue,
  addEmptyTaxTableRow,
  updateTransactionRowAccount,
  updateYearEndPlanningChecksum,
  updateTaxDocumentLabel,
  addEmptyContractualPensionTableRow,
  deleteContractualPensionTableRow,
  updateContractualPensionLabel,
  updateContractualPensionValue,
  updateContractualPensionTableRowReference,
  updateForaField,
  updateTaxViewDocumentField,
  updateDocumentCell,
  deleteDocumentRow,
  toggleTaxSectionActive,
  addTaxSections,
  addDocumentRow,
  resetDocumentPart,
} from '_tax/redux/tax-view/actions';

export interface TaxViewService extends TableServiceType {
  updateTaxTableRowReference(
    part: TaxCalculationPart | 'header' | string,
    rowId: string,
    label: string,
    reference?: string
  ): Promise<void>;
  resetGenericTax(actionType: string, keep?: string[]): Promise<void>;
  resetDocumentPart(id: string): Promise<void>;
  toggleTaxSection(part: string, id: string): Promise<void>;
  addTaxMenuSections(sections: {
    [key: string]: {
      [key: string]: {
        active?: boolean;
        title: string;
        section: string;
      };
    };
  }): Promise<void>;
  deleteTaxTableRow(part: TaxCalculationPart, rowId: string): Promise<void>;
  updateTaxTableRowLabel(
    part: TaxCalculationPart,
    rowId: string,
    label: string
  ): Promise<void>;
  updateTaxViewDocumentField(
    clientId: string,
    id: string,
    value: string | number | boolean | undefined
  ): Promise<void>;
  updateDocumentCell(
    clientId: string,
    id: string,
    value: string | number | boolean | Cell | undefined
  ): Promise<void>;
  updateTaxTableRowValue(
    part: TaxCalculationPart | string,
    rowId: string,
    value: number
  ): Promise<void>;
  addEmptyTaxTableRow(part: TaxCalculationPart, rowId: string): Promise<void>;
  updateTransactionRowAccount(rowId: string, account: string): Promise<void>;
  updateYearEndPlanningChecksum(checksum?: string): Promise<void>;
  updateTaxDocumentLabel(value: string, label: string): Promise<void>;

  addContractualPensionRow(part: string, rowId: string): Promise<void>;
  deleteContractualPensionRow(part: string, rowId: string): Promise<void>;
  updateContractualPensionRowLabel(
    part: string,
    rowId: string,
    label: string
  ): Promise<void>;
  updateContractualPensionRowValue(
    part: string,
    rowId: string,
    value: number
  ): Promise<void>;
  updateContractualPensionRowReference(
    part: string,
    rowId: string,
    label: string,
    reference: string
  ): Promise<void>;
  updateForaField(
    part: string,
    field: string,
    value: string | number
  ): Promise<void>;
  deleteDocumentRow(customerId: string, rowId: string): Promise<void>;
  addDocumentRow(customerId: string, rowId: string): Promise<void>;
}

const taxViewService = (
  clientId: string,
  financialYear: string,
  period: string,
  dispatch
): TaxViewService => {
  const service: Omit<TaxViewService, keyof TableServiceType> = {
    updateTaxTableRowReference: async (part, rowId, label, reference) => {
      await dispatch(
        updateTaxTableRowReference(
          financialYear,
          period,
          part,
          rowId,
          label,
          reference
        )
      );
    },
    updateTaxViewDocumentField: async (clientId, id, value) => {
      await dispatch(
        updateTaxViewDocumentField(clientId, financialYear, id, value)
      );
    },
    updateDocumentCell: async (clientId, id, value) => {
      await dispatch(updateDocumentCell(clientId, financialYear, id, value));
    },
    resetGenericTax: async (actionType, keep) => {
      await dispatch(resetGenericTax(financialYear, period, actionType, keep));
    },
    resetDocumentPart: async (id: string) => {
      await dispatch(resetDocumentPart(clientId, financialYear, period, id));
    },
    toggleTaxSection: async (part: string, id: string) => {
      await dispatch(
        toggleTaxSectionActive(clientId, financialYear, period, part, id)
      );
    },
    addTaxMenuSections: async (sections: {
      [key: string]: {
        [key: string]: { active?: boolean; title: string; section: string };
      };
    }) => {
      await dispatch(addTaxSections(clientId, financialYear, period, sections));
    },
    deleteTaxTableRow: async (part, rowId) => {
      await dispatch(deleteTaxTableRow(financialYear, period, part, rowId));
    },
    updateTaxTableRowLabel: async (part, rowId, label) => {
      await dispatch(
        updateTaxTableRowLabel(financialYear, period, part, rowId, label)
      );
    },
    updateTaxTableRowValue: async (part, rowId, value) => {
      await dispatch(
        updateTaxTableRowValue(financialYear, period, part, rowId, value)
      );
    },
    addEmptyTaxTableRow: async (part, rowId) => {
      await dispatch(addEmptyTaxTableRow(financialYear, period, part, rowId));
    },
    updateTransactionRowAccount: async (rowId, account) => {
      await dispatch(
        updateTransactionRowAccount(financialYear, period, rowId, account)
      );
    },
    updateYearEndPlanningChecksum: async (checksum) => {
      await dispatch(
        updateYearEndPlanningChecksum(financialYear, period, checksum)
      );
    },
    updateTaxDocumentLabel: async (value, label) => {
      await dispatch(
        updateTaxDocumentLabel(financialYear, period, value, label)
      );
    },

    addContractualPensionRow: async (part, rowId) => {
      await dispatch(
        addEmptyContractualPensionTableRow(financialYear, period, part, rowId)
      );
    },

    deleteContractualPensionRow: async (part, rowId) => {
      await dispatch(
        deleteContractualPensionTableRow(financialYear, period, part, rowId)
      );
    },

    updateContractualPensionRowLabel: async (part, rowId, label) => {
      await dispatch(
        updateContractualPensionLabel(financialYear, period, part, rowId, label)
      );
    },

    updateContractualPensionRowValue: async (part, rowId, value) => {
      await dispatch(
        updateContractualPensionValue(financialYear, period, part, rowId, value)
      );
    },

    updateContractualPensionRowReference: async (
      part,
      rowId,
      label,
      reference
    ) => {
      await dispatch(
        updateContractualPensionTableRowReference(
          financialYear,
          period,
          part,
          rowId,
          label,
          reference
        )
      );
    },

    updateForaField: async (part, field, value) => {
      await dispatch(
        updateForaField(financialYear, period, part, field, value)
      );
    },
    deleteDocumentRow: async (customerId, rowId) => {
      await dispatch(deleteDocumentRow(customerId, financialYear, rowId));
    },
    addDocumentRow: async (customerId, rowId) => {
      await dispatch(addDocumentRow(customerId, financialYear, rowId));
    },
  };

  return {
    ...service,
    addRow: (rowId) => service.addDocumentRow(clientId, rowId),
    deleteRow: (rowId) => service.deleteDocumentRow(clientId, rowId),
    updateCellValue: (cellId, value) =>
      service.updateDocumentCell(clientId, cellId, value),
    updateField: (fieldId, value) =>
      service.updateTaxViewDocumentField(clientId, fieldId, value),
  } as TaxViewService;
};

export default taxViewService;

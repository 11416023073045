import { Cell, Field } from '@agoy/document';

type IxbrlDataType =
  | 'stringItemType'
  | 'monetaryItemType'
  | 'pureItemType'
  | 'decimalItemType'
  | 'dateItemType'
  | 'booleanItemType'
  | 'enumerationItemType'
  | 'sharesItemType'
  | 'domainItemType';

type SaldoType = 'debit' | 'credit';

type IxbrlFormat =
  | 'ixt:numspacecomma'
  | 'ixt:numcomma'
  | 'ixt:numcommadot'
  | 'ixt:numdash'
  | 'ixt:numdotcomma';

const TUPLE_ORDERS = [
  '1.0',
  '2.0',
  '3.0',
  '4.0',
  '5.0',
  '6.0',
  '7.0',
  '8.0',
  '9.0',
  '10.0',
  '11.0',
  '12.0',
  '13.0',
  '14.0',
] as const;

type TupleOrder = typeof TUPLE_ORDERS[number];

export type IxbrlDataDefinition = {
  type: IxbrlDataType;
  name: string;
  contextRef: string;
  saldo?: SaldoType;
  tuple?: {
    id?: string;
    tupleID: string;
    name: string;
  };
  previousYearTuple?: boolean;
  tupleRef?: {
    tupleRef: string;
    order: TupleOrder;
  };
  standardRubrik?: string;
  unitRef?: string;
  negateValue?: boolean;
  format?: IxbrlFormat;
};

type IxbrlData = {
  ixbrl?: IxbrlDataDefinition;
};

export type IxbrlField = Field & IxbrlData;
export type IxbrlCell = Cell & IxbrlData;

export function isIxbrlCell(cell: Cell): cell is IxbrlCell {
  return cell['ixbrl'] !== undefined;
}

export const order = (n: number): TupleOrder => {
  if (n > 0 && n < TUPLE_ORDERS.length) {
    return TUPLE_ORDERS[n - 1];
  }
  throw new Error('Invalid order ' + n);
};

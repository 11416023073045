import { programStatusMapSchema, agoyStatuses } from '@agoy/program-status';
import { formatStartOfMonth, parse, parseFinancialYear } from '@agoy/dates';
import { getMonth } from 'date-fns';
import { ClosingMonthType, ProgramStatus } from '../../../../types/types';

export const defaultPeriodProgramStatuses = {
  FIN_STATEMENT: 'NOT_STARTED',
  AN_REPORT: 'NOT_STARTED',
  FIN_STATEMENT_REPORT: 'NOT_STARTED',
  RECONCILIATION: 'NOT_STARTED',
};

export const defaultYearProgramStatuses = {
  FIN_STATEMENT: 'NOT_STARTED',
  AN_REPORT: 'NOT_STARTED',
  FIN_STATEMENT_REPORT: 'NOT_STARTED',
  TAX_DECLARATION: 'NOT_STARTED',
  RECONCILIATION: 'NOT_STARTED',
};

export const programStatusesForLicense = {
  none: [''],
  small: ['FIN_STATEMENT_REPORT', 'RECONCILIATION'],
  big: [
    'FIN_STATEMENT',
    'AN_REPORT',
    'FIN_STATEMENT_REPORT',
    'TAX_DECLARATION',
    'RECONCILIATION',
  ],
};

export type PeriodStatusesType = Record<
  keyof typeof programStatusMapSchema,
  typeof agoyStatuses[number]
>;

const filterByLicense = (
  programStatuses:
    | typeof defaultPeriodProgramStatuses
    | typeof defaultYearProgramStatuses,
  license: keyof typeof programStatusesForLicense
) => {
  const filteredProgramStatuses = {};
  Object.keys(programStatuses)
    .filter((item) => programStatusesForLicense[license]?.includes(item))
    .forEach((item) => {
      filteredProgramStatuses[item] = programStatuses[item];
    });

  return filteredProgramStatuses;
};

export const getProgramStatusesForPeriods = (
  programStatus: ProgramStatus[],
  periods: { date: string; hasData: boolean }[],
  closingMonth: ClosingMonthType | null | undefined,
  license: keyof typeof programStatusesForLicense
): { [period: string]: PeriodStatusesType } => {
  const periodProgramStatus = {};

  periods.forEach((item) => {
    if (closingMonth && getMonth(parse(item.date)) + 1 === closingMonth) {
      periodProgramStatus[item.date] = filterByLicense(
        defaultYearProgramStatuses,
        license
      );
    } else {
      periodProgramStatus[item.date] = filterByLicense(
        defaultPeriodProgramStatuses,
        license
      );
    }
  });

  programStatus.forEach((item) => {
    if (item.program === 'TAX_DECLARATION') {
      const { end: financialYearEnd } = parseFinancialYear(item.financialYear);
      const period = formatStartOfMonth(financialYearEnd, 'yyyyMMdd');

      if (periodProgramStatus[period]?.[item.program]) {
        periodProgramStatus[period][item.program] = item.status;
      }
    }

    if (item.financialYear) {
      return;
    }

    const [period] = item.period.split('-');

    if (periodProgramStatus[period]?.[item.program]) {
      periodProgramStatus[period][item.program] = item.status;
    }
  });

  return periodProgramStatus;
};

import {
  ReferenceCell,
  ref,
  value,
  or,
  id,
  sum,
  account,
  multiply,
} from '@agoy/document';
import { AnnualReportType } from '../../../document';
import { IxbrlCell } from '../../../common/types/ixbrl';
import { table, ixbrlCell } from '../../../common/utils/util';
import { getWarningCell } from '../../settings/settings';
import { changesInEquityIxbrl } from '../managementReport';

export const v2ChangesInEquityK2OrTransitionK3toK2 = (
  reportType: AnnualReportType,
  transitionK3toK2: boolean
) => {
  const sumRowValues = (
    baseId: string,
    reportType: AnnualReportType,
    transitioning = false
  ): ReferenceCell => {
    return ref(
      or(
        sum(
          or(id(`${baseId}.shareCapital`), 0),
          or(id(`${baseId}.ejRegistreratAktiekapital`), 0),
          or(id(`${baseId}.bundenOverkursfond`), 0),
          or(id(`${baseId}.uppskrivingsFond`), 0),
          or(id(`${baseId}.reservFond`), 0),
          transitioning ? or(id(`${baseId}.fondForUtvecklingsutgifter`), 0) : 0, // used when transitioning from K3 to K2
          transitioning ? or(id(`${baseId}.ovrigaBundnaFonder`), 0) : 0, // used when transitioning from K3 to K2
          or(id(`${baseId}.friOverkursfond`), 0),
          transitioning ? or(id(`${baseId}.fondForVerkligtVarde`), 0) : 0, // used when transitioning from K3 to K2
          transitioning ? or(id(`${baseId}.ovrigaFriaFonder`), 0) : 0, // used when transitioning from K3 to K2
          or(id(`${baseId}.balanseratResultat`), 0),
          or(id(`${baseId}.yearResult`), 0)
        ),
        0
      )
    );
  };

  return table<IxbrlCell>(
    'managementReport.changesInEquity.table',
    { id: 'label', label: '' },
    { id: 'shareCapital', label: 'Aktiekapital' },
    {
      id: 'ejRegistreratAktiekapital',
      label: 'Ej registrerat aktiekapital',
    },
    // These undefined are to compensate for the missing columns in K2
    undefined,
    { id: 'bundenOverkursfond', label: 'Bunden överkursfond' },
    { id: 'uppskrivingsFond', label: 'Uppskrivningsfond' },
    { id: 'reservFond', label: 'Reservfond' },
    undefined,
    transitionK3toK2
      ? {
          id: 'fondForUtvecklingsutgifter',
          label: 'Fond för utvecklingsutgifter',
        }
      : undefined,
    transitionK3toK2
      ? {
          id: 'ovrigaBundnaFonder',
          label: 'Övriga bundna fonder',
        }
      : undefined,
    { id: 'friOverkursfond', label: 'Fri överkursfond' },
    transitionK3toK2
      ? {
          id: 'fondForVerkligtVarde',
          label: 'Fond för verkligt värde',
        }
      : undefined,
    transitionK3toK2
      ? {
          id: 'ovrigaFriaFonder',
          label: 'Övriga fria fonder',
        }
      : undefined,
    undefined,
    { id: 'balanseratResultat', label: 'Balanserat resultat' },
    { id: 'yearResult', label: 'Årets resultat' },
    { id: 'total', label: 'Totalt' },
    { id: 'balanseratResultatWarning' },
    { id: 'yearResultWarning' }
  )
    .addRows((rows) =>
      rows
        // Rows to display if transition from K3 is toggled
        .addRow('transitionToK2')
        .addSubRows((rows) =>
          rows
            .addRowActive(true)
            .addRow(
              'amountPrevYear',
              value('Belopp vid årets ingång'),
              ixbrlCell(
                ref(or(multiply(-1, account('2081', 'ib')), 0)),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2Aktiekapital',
                  'balans1',
                  'credit'
                )
              ),
              ixbrlCell(
                ref(or(multiply(-1, account('2082', 'ib')), 0)),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2EjRegistreratAktiekapital',
                  'balans1',
                  'credit'
                )
              ),
              undefined,
              ixbrlCell(
                ref(or(multiply(-1, account('2087', 'ib')), 0)),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2BundenOverkursfond',
                  'balans1',
                  'credit'
                )
              ),
              ixbrlCell(
                ref(or(multiply(-1, account('2085', 'ib')), 0)),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2Uppskrivningsfond',
                  'balans1',
                  'credit'
                )
              ),
              ixbrlCell(
                ref(or(multiply(-1, account('2086', 'ib')), 0)),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2Reservfond',
                  'balans1',
                  'credit'
                )
              ),
              undefined,
              ixbrlCell(
                value(0),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2FondUtvecklingsutgift',
                  'balans1',
                  'credit'
                )
              ),
              ixbrlCell(
                value(0),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2OvrigaBundnaFonder',
                  'balans1',
                  'credit'
                )
              ),
              ixbrlCell(
                ref(or(multiply(-1, account('2097', 'ib')), 0)),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2Overkursfond',
                  'balans1',
                  'credit'
                )
              ),
              ixbrlCell(
                value(0),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2FondVerkligtVarde',
                  'balans1',
                  'credit'
                )
              ),
              ixbrlCell(
                value(0),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2OvrigaFriaFonder',
                  'balans1',
                  'credit'
                )
              ),
              undefined,
              ixbrlCell(
                ref(or(multiply(-1, account('2091', 'ib')), 0)),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2BalanseratResultat',
                  'balans1',
                  'credit'
                )
              ),
              ixbrlCell(
                ref(or(multiply(-1, account('2099', 'ib')), 0)),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2AretsResultat',
                  'balans1',
                  'credit'
                )
              ),
              ixbrlCell(
                sumRowValues(
                  'managementReport.changesInEquity.table.transitionToK2.amountPrevYear',
                  'k2',
                  true
                ),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2',
                  'balans1',
                  'credit'
                )
              )
            )
            .addRow(
              'deferredTaxLiability',
              value('Uppskjuten skatteskuld'),
              value(0),
              value(0),
              undefined,
              value(0),
              ixbrlCell(
                value(0),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2UppskrivningsfondKorrigeringUppskjutenSkatteskuld',
                  'balans0',
                  undefined,
                  'Ökning av ingående uppskrivningsfond till följd av korrigering vid övergång till K2 avseende uppskjuten skatteskuld till följd av att en anläggningstillgång tidigare har skrivits upp'
                )
              ),
              value(0),
              undefined,
              value(0),
              value(0),
              value(0),
              value(0),
              value(0),
              undefined,
              ixbrlCell(
                value(0),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2BalanseratResultatKorrigeringUppskjutenSkatteskuld',
                  'balans0',
                  undefined,
                  'Ökning av ingående balanserat resultat till följd av korrigering vid övergång till K2 avseende uppskjuten skatteskuld'
                )
              ),
              value(0),
              ixbrlCell(
                sumRowValues(
                  'managementReport.changesInEquity.table.transitionToK2.deferredTaxLiability',
                  'k2',
                  true
                ),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2KorrigeringResultatUppskjutenSkatteskuld',
                  'balans0',
                  undefined,
                  'Ökning av eget kapital vid övergång till K2 avseende uppskjuten skatteskuld'
                )
              )
            )
            .addRow(
              'otherFixedAssets',
              value(
                'Andra uppskrivna anläggningstillgångar än byggnader och mark'
              ),
              value(0),
              value(0),
              undefined,
              value(0),
              ixbrlCell(
                value(0),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2UppskrivningsfondKorrigeringAndraUppskrivnaAnlaggningstillgangarAnByggnadMark',
                  'balans0',
                  undefined,
                  'Minskning av ingående uppskrivningsfond till följd av korrigering vid övergång till K2 avseende andra uppskrivna anläggningstillgångar än byggnad och mark',
                  true
                )
              ),
              value(0),
              undefined,
              value(0),
              value(0),
              value(0),
              value(0),
              value(0),
              undefined,
              ixbrlCell(
                value(0),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2BalanseratResultatKorrigeringAndraUppskrivnaAnlaggningstillgangarAnByggnadMark',
                  'balans0',
                  undefined,
                  'Minskning av ingående balanserat resultat till följd av korrigering vid övergång till K2 avseende andra uppskrivna anläggningstillgångar än byggnad och mark',
                  true
                )
              ),
              value(0),
              ixbrlCell(
                sumRowValues(
                  'managementReport.changesInEquity.table.transitionToK2.otherFixedAssets',
                  'k2',
                  true
                ),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2KorrigeringAndraUppskrivnaAnlaggningstillgangarAnByggnadMark',
                  'balans0',
                  undefined,
                  'Minskning av eget kapital vid övergång till K2 avseende andra uppskrivna anläggningstillgångar än byggnad och mark',
                  true
                )
              )
            )
            .addRowActive(false)
            .addRow(
              'selfProcessedIntangibleAssets',
              value('Egenupparbetad immateriell anläggningstillgång'),
              value(0),
              value(0),
              undefined,
              value(0),
              value(0),
              value(0),
              undefined,
              ixbrlCell(
                value(0),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2FondUtvecklingsutgiftEgenupparbetadImmateriellAnlaggningstillgang',
                  'balans0',
                  undefined,
                  'Minskning av ingående fond för utvecklingsutgifter till följd av korrigering vid övergång till K2 avseende egenupparbetad immateriell anläggningstillgång',
                  true
                )
              ),
              value(0),
              value(0),
              value(0),
              value(0),
              undefined,
              ixbrlCell(
                value(0),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2BalanseratResultatKorrigeringEgenupparbetadImmateriellAnlaggningstillgang',
                  'balans0',
                  undefined,
                  'Minskning av ingående balanserat resultat till följd av korrigering vid övergång till K2 avseende egen upparbetad immateriell anläggningstillgång',
                  true
                )
              ),
              value(0),
              ixbrlCell(
                sumRowValues(
                  'managementReport.changesInEquity.table.transitionToK2.selfProcessedIntangibleAssets',
                  'k2',
                  true
                ),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2KorrigeringEgenupparbetadImmateriellAnlaggningstillgang',
                  'balans0',
                  undefined,
                  'Minskning av eget kapital vid övergång till K2 avseende egenupparbetad immateriell anläggningstillgång',
                  true
                )
              )
            )
            .addRow(
              'otherCorrections',
              value('Övriga korrigeringar'),
              value(0),
              value(0),
              undefined,
              value(0),
              value(0),
              value(0),
              undefined,
              value(0),
              ixbrlCell(
                value(0),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2OvrigaBundnaFonderKorrigeratOvrigaBundnaFonderOvrigaJusteringar',
                  'balans0',
                  undefined,
                  'Minskning av ingående övriga bundna fonder till följd av korrigering vid övergång till K2 avseende övriga korrigeringar',
                  true
                )
              ),
              value(0),
              value(0),
              ixbrlCell(
                value(0),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2OvrigaFriaFonderKorrigeringOvrigaJusteringar',
                  'balans0',
                  undefined,
                  'Minskning av ingående övriga fria fonder till följd av korrigering vid övergång till K2 avseende övriga korrigeringar',
                  true
                )
              ),
              undefined,
              ixbrlCell(
                value(0),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2BalanseratResultatKorrigeringOvrigaJusteringar',
                  'balans0',
                  undefined,
                  'Ökning av ingående balanserat resultat till följd av korrigering vid övergång till K2 avseende övriga korrigeringar'
                )
              ),
              value(0),
              ixbrlCell(
                sumRowValues(
                  'managementReport.changesInEquity.table.transitionToK2.otherCorrections',
                  'k2',
                  true
                ),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2KorrigeringResultatOvrigaJusteringar',
                  'balans0'
                )
              )
            )
            .addRow(
              'financialInstruments',
              value(
                'Finansiella instrument värderade till verkligt värde enligt 4 kap. 14 a § årsredovisningslagen (1995:1554)'
              ),
              value(0),
              value(0),
              undefined,
              value(0),
              value(0),
              value(0),
              undefined,
              value(0),
              value(0),
              value(0),
              ixbrlCell(
                value(0),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2FondVerkligtVardeKorrigeringFinansiellaInstrument',
                  'balans0',
                  undefined,
                  'Minskning av ingående fond för verkligt värde till följd av korrigering vid övergång till K2 avseende finansiella instrument',
                  true
                )
              ),
              value(0),
              undefined,
              ixbrlCell(
                value(0),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2BalanseratResultatKorrigeringFinansiellaInstrument',
                  'balans0',
                  undefined,
                  'Minskning av ingående balanserat resultat till följd av korrigering vid övergång till K2 avseende finansiella instrument värderade till verkligt värde',
                  true
                )
              ),
              value(0),
              ixbrlCell(
                sumRowValues(
                  'managementReport.changesInEquity.table.transitionToK2.financialInstruments',
                  'k2',
                  true
                ),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2KorrigeringFinansiellaInstrument',
                  'balans0',
                  undefined,
                  'Minskning av eget kapital vid övergång till K2 avseende finansiella instrument värderade till verkligt värde',
                  true
                )
              )
            )
            .addRow(
              'tangibleFixedAssets',
              value(
                'Materiell anläggningstillgång till följd av att ett leasingavtal redovisats som finansiell leasing'
              ),
              value(0),
              value(0),
              undefined,
              value(0),
              value(0),
              value(0),
              undefined,
              value(0),
              value(0),
              value(0),
              value(0),
              value(0),
              undefined,
              ixbrlCell(
                value(0),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2BalanseratResultatKorrigeringMateriellAnlaggningstillgangFinansiellLeasing',
                  'balans0',
                  undefined,
                  'Minskning av ingående balanserat resultat till följd av korrigering vid övergång till K2 avseende materiell anläggningstillgång till följd av att ett leasingavtal redovisats som finansiell leasing',
                  true
                )
              ),
              value(0),
              ixbrlCell(
                sumRowValues(
                  'managementReport.changesInEquity.table.transitionToK2.tangibleFixedAssets',
                  'k2',
                  true
                ),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2KorrigeringMateriellAnlaggningstillgangFinansiellLeasing',
                  'balans0',
                  undefined,
                  'Minskning av eget kapital vid övergång till K2 avseende materiell anläggningstillgång till följd av att ett leasingavtal redovisats som finansiell leasing',
                  true
                )
              )
            )
            .addRow(
              'leasingDebt',
              value(
                'Skuld till följd av att ett leasingavtal redovisats som finansiell leasing'
              ),
              value(0),
              value(0),
              undefined,
              value(0),
              value(0),
              value(0),
              undefined,
              value(0),
              value(0),
              value(0),
              value(0),
              value(0),
              undefined,
              ixbrlCell(
                value(0),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2BalanseratResultatKorrigeringSkuldFinansiellLeasing',
                  'balans0',
                  undefined,
                  'Ökning av ingående balanserat resultat till följd av korrigering vid övergång till K2 avseende skuld till följd av att ett leasingavtal redovisats som finansiell leasing'
                )
              ),
              value(0),
              ixbrlCell(
                sumRowValues(
                  'managementReport.changesInEquity.table.transitionToK2.leasingDebt',
                  'k2',
                  true
                ),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2KorrigeringSkuldFinansiellLeasing',
                  'balans0',
                  undefined,
                  'Ökning av eget kapital vid övergång till K2 avseende skuld till följd av att ett leasingavtal redovisats som finansiell leasing'
                )
              )
            )
            .addRow(
              'deferredTaxClaim',
              value('Uppskjuten skattefordran'),
              value(0),
              value(0),
              undefined,
              value(0),
              value(0),
              value(0),
              undefined,
              value(0),
              value(0),
              value(0),
              value(0),
              value(0),
              undefined,
              ixbrlCell(
                value(0),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2BalanseratResultatKorrigeringUppskjutenSkattefordran',
                  'balans0',
                  undefined,
                  'Minskning av ingående balanserat resultat till följd av korrigering vid övergång till K2 avseende uppskjuten skattefordran',
                  true
                )
              ),
              value(0),
              ixbrlCell(
                sumRowValues(
                  'managementReport.changesInEquity.table.transitionToK2.deferredTaxClaim',
                  'k2',
                  true
                ),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2KorrigeringUppskjutenSkattefordran',
                  'balans0',
                  undefined,
                  'Minskning av eget kapital vid övergång till K2 avseende uppskjuten skattefordran',
                  true
                )
              )
            )
            .addRow(
              'financialFixedAssets',
              // financialFixedAssets
              value('Finansiell anläggningstillgång'),
              value(0),
              value(0),
              undefined,
              value(0),
              value(0),
              value(0),
              undefined,
              value(0),
              value(0),
              value(0),
              value(0),
              value(0),
              undefined,
              ixbrlCell(
                value(0),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2BalanseratResultatKorrigeringFinansiellAnlaggningstillgang',
                  'balans0',
                  undefined,
                  'Minskning av ingående balanserat resultat till följd av korrigering vid övergång till K2 avseende finansiella anläggningstillgångar',
                  true
                )
              ),
              value(0),
              ixbrlCell(
                sumRowValues(
                  'managementReport.changesInEquity.table.transitionToK2.financialFixedAssets',
                  'k2',
                  true
                ),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2KorrigeringResultatFinansiellAnlaggningstillgang',
                  'balans0',
                  undefined,
                  'Minskning av eget kapital vid övergång till K2 avseende finansiella anläggningstillgångar',
                  true
                )
              )
            )
            .addRow(
              'Commitments',
              value('Åtaganden'),
              value(0),
              value(0),
              undefined,
              value(0),
              value(0),
              value(0),
              undefined,
              value(0),
              value(0),
              value(0),
              value(0),
              value(0),
              undefined,
              ixbrlCell(
                value(0),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2BalanseratResultatKorrigeringAtaganden',
                  'balans0',
                  undefined,
                  'Ökning av ingående balanserat resultat till följd av korrigering vid övergång till K2 avseende åtaganden'
                )
              ),
              value(0),
              ixbrlCell(
                sumRowValues(
                  'managementReport.changesInEquity.table.transitionToK2.Commitments',
                  'k2',
                  true
                ),
                changesInEquityIxbrl(
                  'ForandringIngaendeEgetKapitalOvergangK2KorrigeringResultatAtaganden',
                  'balans0',
                  undefined,
                  'Ökning av eget kapital vid övergång till K2 avseende åtaganden'
                )
              )
            )
            .build()
        )
        // These rows are displayed for K2
        .addRow('main')
        .addSubRows((rows) =>
          rows
            .addRow('content')
            .addSubRows((rows) =>
              rows
                .addRowManagementReport(
                  !transitionK3toK2
                    ? {
                        id: 'incomingAmount',
                        cells: [
                          value('Belopp vid årets ingång'),
                          ixbrlCell(
                            ref(or(multiply(-1, account('2081', 'ib')), 0)),
                            changesInEquityIxbrl(
                              'Aktiekapital',
                              'balans1',
                              'credit'
                            )
                          ),
                          ixbrlCell(
                            ref(or(multiply(-1, account('2082', 'ib')), 0)),
                            changesInEquityIxbrl(
                              'EjRegistreratAktiekapital',
                              'balans1',
                              'credit'
                            )
                          ),
                          undefined,
                          ixbrlCell(
                            ref(or(multiply(-1, account('2087', 'ib')), 0)),
                            changesInEquityIxbrl(
                              'OverkursfondBunden',
                              'balans1',
                              'credit'
                            )
                          ),
                          ixbrlCell(
                            ref(or(multiply(-1, account('2085', 'ib')), 0)),
                            changesInEquityIxbrl(
                              'Uppskrivningsfond',
                              'balans1',
                              'credit'
                            )
                          ),
                          ixbrlCell(
                            ref(or(multiply(-1, account('2086', 'ib')), 0)),
                            changesInEquityIxbrl(
                              'Reservfond',
                              'balans1',
                              'credit'
                            )
                          ),
                          undefined,
                          transitionK3toK2 ? value(0) : undefined,
                          transitionK3toK2 ? value(0) : undefined,
                          ixbrlCell(
                            ref(or(multiply(-1, account('2097', 'ib')), 0)),
                            changesInEquityIxbrl(
                              'Overkursfond',
                              'balans1',
                              'credit'
                            )
                          ),
                          transitionK3toK2 ? value(0) : undefined,
                          transitionK3toK2 ? value(0) : undefined,
                          undefined,
                          ixbrlCell(
                            ref(or(multiply(-1, account('2091', 'ib')), 0)),
                            changesInEquityIxbrl(
                              'BalanseratResultat',
                              'balans1',
                              'credit'
                            )
                          ),

                          ixbrlCell(
                            ref(or(multiply(-1, account('2099', 'ib')), 0)),
                            changesInEquityIxbrl(
                              'AretsResultatEgetKapital',
                              'balans1',
                              'credit'
                            )
                          ),
                          ixbrlCell(
                            sumRowValues(
                              'managementReport.changesInEquity.table.main.content.incomingAmount',
                              'k2',
                              transitionK3toK2
                            ),
                            changesInEquityIxbrl(
                              'ForandringEgetKapitalTotalt',
                              'balans1',
                              'credit'
                            )
                          ),
                          getWarningCell(
                            `${rows.getBaseId()}.incomingAmount.balanseratResultat`
                          ),
                          getWarningCell(
                            `${rows.getBaseId()}.incomingAmount.yearResult`
                          ),
                        ],
                        isRowActive: true,
                      }
                    : undefined
                )
                .addRowManagementReport(
                  !transitionK3toK2
                    ? {
                        id: 'dividendToShareHolders',
                        cells: [
                          value('Utdelning'),
                          value(0),
                          reportType === 'k2' ? value(0) : undefined,
                          value(0),
                          value(0),
                          value(0),
                          value(0),
                          value(0),
                          value(0),
                          reportType === 'k2' && transitionK3toK2
                            ? value(0)
                            : undefined,
                          ixbrlCell(
                            value(0),
                            changesInEquityIxbrl(
                              'ForandringEgetKapitalOverkursfondUtdelning',
                              undefined,
                              undefined,
                              'Minskning av fri överkursfond vid utdelning',
                              true
                            )
                          ),
                          value(0),
                          reportType === 'k2' && transitionK3toK2
                            ? value(0)
                            : undefined,
                          ixbrlCell(
                            value(0),
                            changesInEquityIxbrl(
                              'ForandringEgetKapitalBalanseratResultatInklusivePeriodensResultatUtdelning'
                            )
                          ),
                          ixbrlCell(
                            // TODO: I don't think we can fetch profitProposal values from last year...
                            value(0),
                            changesInEquityIxbrl(
                              'ForandringEgetKapitalBalanseratResultatUtdelning',
                              undefined,
                              undefined,
                              'Minskning av balanserat resultat vid utdelning',
                              true
                            )
                          ),
                          ixbrlCell(
                            value(0),
                            changesInEquityIxbrl(
                              'ForandringEgetKapitalAretsResultatUtdelning',
                              undefined,
                              undefined,
                              'Minskning av periodens resultat vid utdelning',
                              true
                            )
                          ),
                          ixbrlCell(
                            sumRowValues(
                              'managementReport.changesInEquity.table.main.content.dividendToShareHolders',
                              reportType,
                              transitionK3toK2
                            ),
                            changesInEquityIxbrl(
                              'ForandringEgetKapitalTotaltUtdelning',
                              undefined,
                              undefined,
                              'Minskning av eget kapital vid utdelning',
                              true
                            )
                          ),
                        ],
                        isRowActive: true,
                      }
                    : undefined
                )
                .addRowManagementReport(
                  !transitionK3toK2
                    ? {
                        id: 'shareholderContributions',
                        cells: [
                          value('Erhållna aktieägartillskott'),
                          value(0),
                          reportType === 'k2' ? value(0) : undefined,
                          value(0),
                          value(0),
                          value(0),
                          value(0),
                          value(0),
                          value(0),
                          reportType === 'k2' && transitionK3toK2
                            ? value(0)
                            : undefined,
                          value(0),
                          value(0),
                          reportType === 'k2' && transitionK3toK2
                            ? value(0)
                            : undefined,
                          ixbrlCell(
                            value(0),
                            changesInEquityIxbrl(
                              'ForandringEgetKapitalBalanseratResultatInklusivePeriodensResultatErhallnaAktieagartillskott'
                            )
                          ),
                          ixbrlCell(
                            ref(
                              or(
                                multiply(
                                  -1,
                                  account('2093', 'creditWithoutInverse')
                                ),
                                0
                              )
                            ),
                            changesInEquityIxbrl(
                              'ForandringEgetKapitalBalanseratResultatErhallnaAktieagartillskott',
                              undefined,
                              undefined,
                              'Ökning av balanserat resultat vid erhållna aktieägartillskott'
                            )
                          ),
                          value(0),
                          ixbrlCell(
                            sumRowValues(
                              'managementReport.changesInEquity.table.main.content.shareholderContributions',
                              reportType,
                              transitionK3toK2
                            ),
                            changesInEquityIxbrl(
                              'ForandringEgetKapitalTotaltErhallnaAktieagartillskott',
                              undefined,
                              undefined,
                              'Ökning av eget kapital vid erhållna aktieägartillskott'
                            )
                          ),
                        ],
                        isRowActive: false,
                      }
                    : undefined
                )
                .addRowManagementReport(
                  !transitionK3toK2
                    ? {
                        id: 'refundShareholders',
                        cells: [
                          value('Återbetalning av aktieägartillskott'),
                          value(0),
                          reportType === 'k2' ? value(0) : undefined,
                          value(0),
                          value(0),
                          value(0),
                          value(0),
                          value(0),
                          value(0),
                          reportType === 'k2' && transitionK3toK2
                            ? value(0)
                            : undefined,
                          ixbrlCell(
                            value(0),
                            changesInEquityIxbrl(
                              'ForandringEgetKapitalOverkursfondAterbetalningAktieagartillskott',
                              undefined,
                              undefined,
                              'Minskning av fri överkursfond vid återbetalning av aktieägartillskott',
                              true
                            )
                          ),
                          value(0),
                          reportType === 'k2' && transitionK3toK2
                            ? value(0)
                            : undefined,
                          ixbrlCell(
                            value(0),
                            changesInEquityIxbrl(
                              'ForandringEgetKapitalBalanseratResultatInklusivePeriodensResultatAterbetalningAktieagartillskott'
                            )
                          ),
                          ixbrlCell(
                            ref(
                              or(
                                multiply(
                                  -1,
                                  account('2093', 'debitWithoutInverse')
                                ),
                                0
                              )
                            ),
                            changesInEquityIxbrl(
                              'ForandringEgetKapitalBalanseratResultatAterbetalningAktieagartillskott',
                              undefined,
                              undefined,
                              'Minskning av balanserat resultat vid återbetalning av aktieägartillskott',
                              true
                            )
                          ),
                          ixbrlCell(
                            value(0),
                            changesInEquityIxbrl(
                              'ForandringEgetKapitalAretsResultatAterbetalningAktieagartillskott',
                              undefined,
                              undefined,
                              'Minskning av periodens resultat vid återbetalning av aktieägartillskott',
                              true
                            )
                          ),
                          ixbrlCell(
                            sumRowValues(
                              'managementReport.changesInEquity.table.main.content.refundShareholders',
                              reportType,
                              transitionK3toK2
                            ),
                            changesInEquityIxbrl(
                              'ForandringEgetKapitalTotaltAterbetalningAktieagartillskott',
                              undefined,
                              undefined,
                              'Minskning av eget kapital vid återbetalning av aktieägartillskott',
                              true
                            )
                          ),
                        ],
                        isRowActive: false,
                      }
                    : undefined
                )
                .addRowManagementReport(
                  !transitionK3toK2
                    ? {
                        id: 'balanserasINyRakning',
                        cells: [
                          value('Balanseras i ny räkning'),
                          value(0),
                          reportType === 'k2' ? value(0) : undefined,
                          value(0),
                          value(0),
                          value(0),
                          value(0),
                          value(0),
                          value(0),
                          reportType === 'k2' && transitionK3toK2
                            ? value(0)
                            : undefined,
                          ixbrlCell(
                            value(0),
                            changesInEquityIxbrl(
                              'ForandringEgetKapitalOverkursfondBalanserasNyRakning',
                              undefined,
                              undefined,
                              'Minskning av fri överkursfond vid balansering i ny räkning',
                              true
                            )
                          ),
                          value(0),
                          reportType === 'k2' && transitionK3toK2
                            ? value(0)
                            : undefined,
                          ixbrlCell(
                            value(0),
                            changesInEquityIxbrl(
                              'ForandringEgetKapitalBalanseratResultatInklusivePeriodensResultatBalanserasNyRakning'
                            )
                          ),
                          ixbrlCell(
                            ref(or(multiply(-1, account('2099', 'ib')), 0)),
                            changesInEquityIxbrl(
                              'ForandringEgetKapitalBalanseratResultatBalanserasNyRakning',
                              undefined,
                              undefined,
                              'Ökning (minskning) av balanserat resultat vid balansering i ny räkning'
                            )
                          ),
                          ixbrlCell(
                            ref(or(account('2099', 'ib'), 0)),
                            changesInEquityIxbrl(
                              'ForandringEgetKapitalAretsResultatBalanserasNyRakning',
                              undefined,
                              undefined,
                              'Ökning (minskning) av periodens resultat vid balansering i ny räkning'
                            )
                          ),
                          ixbrlCell(
                            sumRowValues(
                              'managementReport.changesInEquity.table.main.content.balanserasINyRakning',
                              reportType,
                              transitionK3toK2
                            ),
                            changesInEquityIxbrl(
                              'ForandringEgetKapitalTotaltBalanserasNyRakning'
                            )
                          ),
                        ],
                        isRowActive: true,
                      }
                    : undefined
                )
                .addRowManagementReport(
                  !transitionK3toK2
                    ? {
                        id: 'forandringAvReservfond',
                        cells: [
                          value('Förändring av reservfond'),
                          value(0),
                          reportType === 'k2' ? value(0) : undefined,
                          value(0),
                          value(0),
                          value(0),
                          ixbrlCell(
                            // TODO: incomplete spec: Sum of debet and kredit d...?
                            value(0),
                            changesInEquityIxbrl(
                              'ForandringEgetKapitalReservfondForandringReservfond',
                              undefined,
                              undefined,
                              'Ökning (minskning) av reservfond'
                            )
                          ),
                          value(0),
                          value(0),
                          reportType === 'k2' && transitionK3toK2
                            ? value(0)
                            : undefined,
                          ixbrlCell(
                            value(0),
                            changesInEquityIxbrl(
                              'ForandringEgetKapitalOverkursfondForandringReservfond',
                              undefined,
                              undefined,
                              'Minskning av fri överkursfond vid förändring av reservfond',
                              true
                            )
                          ),
                          value(0),
                          reportType === 'k2' && transitionK3toK2
                            ? value(0)
                            : undefined,
                          ixbrlCell(
                            value(0),
                            changesInEquityIxbrl(
                              'ForandringEgetKapitalBalanseratResultatInklusivePeriodensResultatForandringReservfond'
                            )
                          ),
                          ixbrlCell(
                            value(0),
                            changesInEquityIxbrl(
                              'ForandringEgetKapitalBalanseratResultatForandringReservfond',
                              undefined,
                              undefined,
                              'Ökning (minskning) av balanserat resultat vid förändring av reservfond'
                            )
                          ),
                          ixbrlCell(
                            value(0),
                            changesInEquityIxbrl(
                              'ForandringEgetKapitalAretsResultatReservfond',
                              undefined,
                              undefined,
                              'Ökning (minskning) av periodens resultat vid förändring av reservfond'
                            )
                          ),
                          ixbrlCell(
                            sumRowValues(
                              'managementReport.changesInEquity.table.main.content.forandringAvReservfond',
                              reportType,
                              transitionK3toK2
                            ),
                            changesInEquityIxbrl(
                              'ForandringEgetKapitalTotaltForandringReservfond'
                            )
                          ),
                        ],
                        isRowActive: false,
                      }
                    : undefined
                )
                .addRowManagementReport(
                  !transitionK3toK2
                    ? {
                        id: 'tackningAvForlust',
                        cells: [
                          value('Täckning av förlust'),
                          ixbrlCell(
                            value(0),
                            changesInEquityIxbrl(
                              'ForandringEgetKapitalAktiekapitalTackningForlust',
                              undefined,
                              undefined,
                              'Minskning av aktiekapitalet vid täckning av förlust',
                              true
                            )
                          ),
                          reportType === 'k2' ? value(0) : undefined,
                          value(0),
                          ixbrlCell(
                            value(0),
                            changesInEquityIxbrl(
                              'ForandringEgetKapitalBundenOverkursfondTackningForlust',
                              undefined,
                              undefined,
                              'Minskning av bunden överkursfond vid täckning av förlust',
                              true
                            )
                          ),
                          ixbrlCell(
                            value(0),
                            changesInEquityIxbrl(
                              'ForandringEgetKapitalUppskrivningsfondTackningForlust',
                              undefined,
                              undefined,
                              'Minskning av uppskrivningsfond vid täckning av förlust',
                              true
                            )
                          ),
                          ixbrlCell(
                            value(0),
                            changesInEquityIxbrl(
                              'ForandringEgetKapitalReservfondTackningForlust',
                              undefined,
                              undefined,
                              'Minskning av reservfond vid täckning av förlust',
                              true
                            )
                          ),
                          value(0),
                          ixbrlCell(
                            value(0),
                            changesInEquityIxbrl(
                              'ForandringEgetKapitalFondUtvecklingsutgifterTackningForlust'
                            )
                          ),
                          reportType === 'k2' && transitionK3toK2
                            ? value(0)
                            : undefined,
                          ixbrlCell(
                            value(0),
                            changesInEquityIxbrl(
                              'ForandringEgetKapitalOverkursfondTackningForlust',
                              undefined,
                              undefined,
                              'Minskning av fri överkursfond vid täckning av förlust',
                              true
                            )
                          ),
                          value(0),
                          reportType === 'k2' && transitionK3toK2
                            ? value(0)
                            : undefined,
                          ixbrlCell(
                            value(0),
                            changesInEquityIxbrl(
                              'ForandringEgetKapitalBalanseratResultatInklusivePeriodensResultatTackningForlust'
                            )
                          ),
                          ixbrlCell(
                            value(0),
                            changesInEquityIxbrl(
                              'ForandringEgetKapitalBalanseratResultatTackningForlust',
                              undefined,
                              undefined,
                              'Ökning av balanserat resultat vid täckning av förlust'
                            )
                          ),
                          value(0),
                          ixbrlCell(
                            sumRowValues(
                              'managementReport.changesInEquity.table.main.content.tackningAvForlust',
                              reportType,
                              transitionK3toK2
                            ),
                            changesInEquityIxbrl(
                              'ForandringEgetKapitalTotaltTackningForlust'
                            )
                          ),
                        ],
                        isRowActive: false,
                      }
                    : undefined
                )
                .addRowManagementReport(
                  !transitionK3toK2
                    ? {
                        id: 'fundEmission',
                        cells: [
                          value('Fondemission'),
                          ixbrlCell(
                            value(0),
                            changesInEquityIxbrl(
                              'ForandringEgetKapitalAktiekapitalFondemission',
                              undefined,
                              undefined,
                              'Ökning av aktiekapital vid fondemission'
                            )
                          ),
                          ixbrlCell(
                            value(0),
                            changesInEquityIxbrl(
                              'ForandringEgetKapitalEjRegistreratAktiekapitalFondemission',
                              undefined,
                              undefined,
                              'Ökning (minskning) av ej registrerat aktiekapital vid fondemission'
                            )
                          ),
                          undefined,
                          ixbrlCell(
                            value(0),
                            changesInEquityIxbrl(
                              'ForandringEgetKapitalBundenOverkursfondFondemission',
                              undefined,
                              undefined,
                              'Minskning av bunden överkursfond vid fondemission',
                              true
                            )
                          ),
                          ixbrlCell(
                            value(0),
                            changesInEquityIxbrl(
                              'ForandringEgetKapitaUppskrivningsfondFondemission',
                              undefined,
                              undefined,
                              'Minskning av uppskrivningsfond vid fondemission',
                              true
                            )
                          ),
                          ixbrlCell(
                            value(0),
                            changesInEquityIxbrl(
                              'ForandringEgetKapitalReservfondFondemission',
                              undefined,
                              undefined,
                              'Minskning av reservfond vid fondemission',
                              true
                            )
                          ),
                          value(0),
                          ixbrlCell(
                            value(0),
                            changesInEquityIxbrl(
                              'ForandringEgetKapitalFondUtvecklingsutgifterFondemission'
                            )
                          ),
                          reportType === 'k2' && transitionK3toK2
                            ? value(0)
                            : undefined,
                          ixbrlCell(
                            value(0),
                            changesInEquityIxbrl(
                              'ForandringEgetKapitalOverkursfondFondemission',
                              undefined,
                              undefined,
                              'Minskning av fri överkursfond vid fondemission',
                              true
                            )
                          ),
                          value(0),
                          reportType === 'k2' && transitionK3toK2
                            ? value(0)
                            : undefined,
                          ixbrlCell(
                            value(0),
                            changesInEquityIxbrl(
                              'ForandringEgetKapitalBalanseratResultatInklusivePeriodensResultatFondemission'
                            )
                          ),
                          ixbrlCell(
                            value(0),
                            changesInEquityIxbrl(
                              'ForandringEgetKapitalBalanseratResultatFondemission',
                              undefined,
                              undefined,
                              'Minskning av balanserat resultat vid fondemission',
                              true
                            )
                          ),
                          ixbrlCell(
                            value(0),
                            changesInEquityIxbrl(
                              'ForandringEgetKapitalAretsResultatFondemission',
                              undefined,
                              undefined,
                              'Minskning av periodens resultat vid fondemission',
                              true
                            )
                          ),
                          ixbrlCell(
                            sumRowValues(
                              'managementReport.changesInEquity.table.main.content.fundEmission',
                              reportType,
                              transitionK3toK2
                            ),
                            changesInEquityIxbrl(
                              'ForandringEgetKapitalTotaltFondemission'
                            )
                          ),
                        ],
                        isRowActive: false,
                      }
                    : undefined
                )
                .addRowManagementReport(
                  !transitionK3toK2
                    ? {
                        id: 'newSplit',
                        cells: [
                          value('Nyemission'),
                          ixbrlCell(
                            ref(
                              or(
                                multiply(
                                  -1,
                                  account('2081', 'creditWithoutInverse')
                                ),
                                0
                              )
                            ),
                            changesInEquityIxbrl(
                              'ForandringEgetKapitalAktiekapitalNyemission',
                              undefined,
                              undefined,
                              'Ökning av aktiekapital vid nyemission'
                            )
                          ),
                          ixbrlCell(
                            value(0),
                            changesInEquityIxbrl(
                              'ForandringEgetKapitalEjRegistreratAktiekapitalNyemission',
                              undefined,
                              undefined,
                              'Ökning (minskning) av ej registrerat aktiekapital vid nyemission'
                            )
                          ),
                          undefined,
                          ixbrlCell(
                            value(0),
                            changesInEquityIxbrl(
                              'ForandringEgetKapitalBundenOverkursfondNyemission',
                              undefined,
                              undefined,
                              'Ökning av bunden överkursfond vid nyemission'
                            )
                          ),
                          value(0),
                          value(0),
                          value(0),
                          value(0),
                          reportType === 'k2' && transitionK3toK2
                            ? value(0)
                            : undefined,
                          ixbrlCell(
                            value(0),
                            changesInEquityIxbrl(
                              'ForandringEgetKapitalOverkursfondNyemission',
                              undefined,
                              undefined,
                              'Ökning av fri överkursfond vid nyemission'
                            )
                          ),
                          value(0),
                          reportType === 'k2' && transitionK3toK2
                            ? value(0)
                            : undefined,
                          value(0),
                          value(0),
                          value(0),
                          ixbrlCell(
                            sumRowValues(
                              'managementReport.changesInEquity.table.main.content.newSplit',
                              reportType,
                              transitionK3toK2
                            ),
                            changesInEquityIxbrl(
                              'ForandringEgetKapitalTotaltNyemission',
                              undefined,
                              undefined,
                              'Ökning av eller förändring inom eget kapital vid nyemission'
                            )
                          ),
                        ],
                        isRowActive: false,
                      }
                    : undefined
                )
                .addRowManagementReport(
                  !transitionK3toK2
                    ? {
                        id: 'minskningAvAktiekaptial',
                        cells: [
                          value('Minskning av aktiekapital'),
                          ixbrlCell(
                            ref(
                              or(
                                multiply(
                                  -1,
                                  account('2081', 'debitWithoutInverse')
                                ),
                                0
                              )
                            ),
                            changesInEquityIxbrl(
                              'ForandringEgetKapitalAktiekapitalOverforingFrittEgetKapitalAterbetalningAktieagare',
                              undefined,
                              undefined,
                              'Minskning av aktiekapital vid överföring till fritt eget kapital eller återbetalning till aktieägarna',
                              true
                            )
                          ),
                          reportType === 'k2' ? value(0) : undefined,
                          value(0),
                          value(0),
                          value(0),
                          value(0),
                          value(0),
                          value(0),
                          reportType === 'k2' && transitionK3toK2
                            ? value(0)
                            : undefined,
                          value(0),
                          value(0),
                          reportType === 'k2' && transitionK3toK2
                            ? value(0)
                            : undefined,
                          ixbrlCell(
                            value(0),
                            changesInEquityIxbrl(
                              'ForandringEgetKapitalBalanseratResultatInklusivePeriodensResultatMinskningAktiekapital'
                            )
                          ),
                          ixbrlCell(
                            value(0),
                            changesInEquityIxbrl(
                              'ForandringEgetKapitalBalanseratResultatMinskningAktiekapital',
                              undefined,
                              undefined,
                              'Ökning av balanserat resultat vid minskat aktiekapital'
                            )
                          ),
                          value(0),
                          ixbrlCell(
                            sumRowValues(
                              'managementReport.changesInEquity.table.main.content.minskningAvAktiekaptial',
                              reportType,
                              transitionK3toK2
                            ),
                            changesInEquityIxbrl(
                              'ForandringEgetKapitalTotaltMinskningAktiekapital'
                            )
                          ),
                        ],
                        isRowActive: false,
                      }
                    : undefined
                )
                .addRowManagementReport(
                  !transitionK3toK2
                    ? {
                        id: 'minskningAvBundenOverkursfond',
                        cells: [
                          value('Minskning av bunden överkursfond'),
                          value(0),
                          reportType === 'k2' ? value(0) : undefined,
                          value(0),
                          ixbrlCell(
                            ref(
                              or(
                                multiply(
                                  -1,
                                  account('2087', 'debitWithoutInverse')
                                ),
                                0
                              )
                            ),
                            changesInEquityIxbrl(
                              'ForandringEgetKapitalBundenOverkursfondMinskningBundenOverskursfond',
                              undefined,
                              undefined,
                              'Minskning av bunden överkursfond',
                              true
                            )
                          ),
                          value(0),
                          value(0),
                          value(0),
                          value(0),
                          reportType === 'k2' && transitionK3toK2
                            ? value(0)
                            : undefined,
                          value(0),
                          value(0),
                          reportType === 'k2' && transitionK3toK2
                            ? value(0)
                            : undefined,
                          ixbrlCell(
                            value(0),
                            changesInEquityIxbrl(
                              'ForandringEgetKapitalBalanseratResultatInklusivePeriodensResultatMinskningBundenOverkursfond'
                            )
                          ),
                          ixbrlCell(
                            value(0),
                            changesInEquityIxbrl(
                              'ForandringEgetKapitalBalanseratResultatMinskningBundenOverskursfond',
                              undefined,
                              undefined,
                              'Ökning av balanserat resultat vid minskning av bunden överkursfond'
                            )
                          ),
                          value(0),
                          ixbrlCell(
                            sumRowValues(
                              'managementReport.changesInEquity.table.main.content.minskningAvBundenOverkursfond',
                              reportType,
                              transitionK3toK2
                            ),
                            changesInEquityIxbrl(
                              'ForandringEgetKapitalTotaltMinskningBundenOverkursfond'
                            )
                          ),
                        ],
                        isRowActive: false,
                      }
                    : undefined
                )
                .addRowManagementReport(
                  !transitionK3toK2
                    ? {
                        id: 'forvarvAvEgnaAktier',
                        cells: [
                          value('Förvärv av egna aktier'),
                          value(0),
                          value(0),
                          value(0),
                          value(0),
                          value(0),
                          value(0),
                          value(0),
                          value(0),
                          value(0),
                          value(0),
                          value(0),
                          value(0),
                          value(0),
                          ixbrlCell(
                            ref(
                              or(
                                multiply(
                                  -1,
                                  account('2094', 'debitWithoutInverse')
                                ),
                                0
                              )
                            ),
                            changesInEquityIxbrl(
                              'ForandringEgetKapitalBalanseratResultatEgnaAktier',
                              undefined,
                              undefined,
                              'Minskning av balanserat resultat vid förvärv av egna aktier',
                              true
                            )
                          ),
                          value(0),
                          ixbrlCell(
                            sumRowValues(
                              'managementReport.changesInEquity.table.main.content.forvarvAvEgnaAktier',
                              'k2',
                              transitionK3toK2
                            ),
                            changesInEquityIxbrl(
                              'ForandringEgetKapitalTotaltForandringEgnaAktier',
                              undefined,
                              undefined,
                              'Minskning av eget kapital vid förvärv av egna aktier',
                              true
                            )
                          ),
                        ],
                        isRowActive: false,
                      }
                    : undefined
                )
                .addRowManagementReport(
                  !transitionK3toK2
                    ? {
                        id: 'overlatelseAvEgnaAktier',
                        cells: [
                          value('Överlåtelse av egna aktier'),
                          value(0),
                          reportType === 'k2' ? value(0) : undefined,
                          value(0),
                          value(0),
                          value(0),
                          value(0),
                          value(0),
                          value(0),
                          reportType === 'k2' && transitionK3toK2
                            ? value(0)
                            : undefined,
                          value(0),
                          value(0),
                          reportType === 'k2' && transitionK3toK2
                            ? value(0)
                            : undefined,
                          ixbrlCell(
                            value(0),
                            changesInEquityIxbrl(
                              'ForandringEgetKapitalBalanseratResultatInklusivePeriodensResultatOverlatelseEgnaAktier'
                            )
                          ),
                          ixbrlCell(
                            ref(
                              or(
                                multiply(
                                  -1,
                                  account('2094', 'creditWithoutInverse')
                                ),
                                0
                              )
                            ),
                            changesInEquityIxbrl(
                              'ForandringEgetKapitalBalanseratResultatOverlatelseEgnaAktier',
                              undefined,
                              undefined,
                              'Ökning av balanserat resultat vid överlåtelse av egna aktier'
                            )
                          ),
                          value(0),
                          ixbrlCell(
                            sumRowValues(
                              'managementReport.changesInEquity.table.main.content.overlatelseAvEgnaAktier',
                              reportType,
                              transitionK3toK2
                            ),
                            changesInEquityIxbrl(
                              'ForandringEgetKapitalTotaltOverlatelseEgnaAktier',
                              undefined,
                              undefined,
                              'Ökning av eget kapital vid överlåtelse av egna aktier'
                            )
                          ),
                        ],
                        isRowActive: false,
                      }
                    : undefined
                )
                .addRowManagementReport(
                  !transitionK3toK2
                    ? {
                        id: 'uppskrivningAvAnlaggningstillgång',
                        cells: [
                          value('Uppskrivning av anläggningstillgång'),
                          value(0),
                          reportType === 'k2' ? value(0) : undefined,
                          value(0),
                          value(0),
                          ixbrlCell(
                            value(0),
                            changesInEquityIxbrl(
                              'ForandringEgetKapitalUppskrivningsfondUppskrivningAnlaggningstillgang',
                              undefined,
                              undefined,
                              'Ökning av uppskrivningsfond vid uppskrivning av anläggningstillgång'
                            )
                          ),
                          value(0),
                          value(0),
                          value(0),
                          reportType === 'k2' && transitionK3toK2
                            ? value(0)
                            : undefined,
                          value(0),
                          value(0),
                          reportType === 'k2' && transitionK3toK2
                            ? value(0)
                            : undefined,
                          value(0),
                          value(0),
                          value(0),
                          ixbrlCell(
                            sumRowValues(
                              'managementReport.changesInEquity.table.main.content.uppskrivningAvAnlaggningstillgång',
                              reportType,
                              transitionK3toK2
                            ),
                            changesInEquityIxbrl(
                              'ForandringEgetKapitalTotaltUppskrivningAnlaggningstillgang',
                              undefined,
                              undefined,
                              'Ökning av eget kapital vid uppskrivning av anläggningstillgång'
                            )
                          ),
                        ],
                        isRowActive: false,
                      }
                    : undefined
                )
                .addRowManagementReport(
                  !transitionK3toK2
                    ? {
                        id: 'upplosningAvUppskrivningsfond',
                        cells: [
                          value('Upplösning av uppskrivningsfond'),
                          value(0),
                          reportType === 'k2' ? value(0) : undefined,
                          value(0),
                          value(0),
                          ixbrlCell(
                            value(0),
                            changesInEquityIxbrl(
                              'ForandringEgetKapitalUppskrivningsfondUpplosningUppskrivningsfond',
                              undefined,
                              undefined,
                              'Minskning av uppskrivningsfond vid upplösning',
                              true
                            )
                          ),
                          value(0),
                          value(0),
                          value(0),
                          reportType === 'k2' && transitionK3toK2
                            ? value(0)
                            : undefined,
                          value(0),
                          value(0),
                          reportType === 'k2' && transitionK3toK2
                            ? value(0)
                            : undefined,
                          ixbrlCell(
                            value(0),
                            changesInEquityIxbrl(
                              'ForandringEgetKapitalBalanseratResultatInklusivePeriodensResultatUpplosningUppskrivningsfond'
                            )
                          ),
                          ixbrlCell(
                            value(0),
                            changesInEquityIxbrl(
                              'ForandringEgetKapitalBalanseratResultatUpplosningUppskrivningsfond',
                              undefined,
                              undefined,
                              'Ökning av balanserat resultat vid upplösning av uppskrivningsfond'
                            )
                          ),
                          value(0),
                          ixbrlCell(
                            sumRowValues(
                              'managementReport.changesInEquity.table.main.content.upplosningAvUppskrivningsfond',
                              reportType,
                              transitionK3toK2
                            ),
                            changesInEquityIxbrl(
                              'ForandringEgetKapitalTotaltUpplosningUppskrivningsfond'
                            )
                          ),
                        ],
                        isRowActive: false,
                      }
                    : undefined
                )
                .addRowManagementReport(
                  !transitionK3toK2
                    ? {
                        // AGOY-3783: did not fix typo in id below to not risk losing users data
                        id: 'fussionsdifferens',
                        cells: [
                          value('Fusionsdifferens'),
                          value(0),
                          reportType === 'k2' ? value(0) : undefined,
                          value(0),
                          value(0),
                          ixbrlCell(
                            value(0),
                            changesInEquityIxbrl(
                              'ForandringEgetKapitalUppskrivningsfondFusionsdifferens',
                              undefined,
                              undefined,
                              'Ökning av uppskrivningsfond vid fusionsdifferens'
                            )
                          ),
                          value(0),
                          value(0),
                          value(0),
                          reportType === 'k2' && transitionK3toK2
                            ? value(0)
                            : undefined,
                          value(0),
                          value(0),
                          reportType === 'k2' && transitionK3toK2
                            ? value(0)
                            : undefined,
                          ixbrlCell(
                            value(0),
                            changesInEquityIxbrl(
                              'ForandringEgetKapitalBalanseratResultatInklusivePeriodensResultatFusionsdifferens'
                            )
                          ),
                          ixbrlCell(
                            value(0),
                            changesInEquityIxbrl(
                              'ForandringEgetKapitalBalanseratResultatFusionsdifferens',
                              undefined,
                              undefined,
                              'Ökning (minskning) av balanserat resultat vid fusionsdifferens'
                            )
                          ),
                          value(0),
                          ixbrlCell(
                            sumRowValues(
                              'managementReport.changesInEquity.table.main.content.fussionsdifferens',
                              reportType,
                              transitionK3toK2
                            ),
                            changesInEquityIxbrl(
                              'ForandringEgetKapitalTotaltFusionsdifferens',
                              undefined,
                              undefined,
                              'Ökning (minskning) av eget kapital vid fusionsdifferens'
                            )
                          ),
                        ],
                        isRowActive: false,
                      }
                    : undefined
                )
                .addRowManagementReport(
                  !transitionK3toK2
                    ? {
                        id: 'yearResult',
                        cells: [
                          value('Årets resultat'),
                          value(0),
                          reportType === 'k2' ? value(0) : undefined,
                          value(0),
                          value(0),
                          value(0),
                          value(0),
                          value(0),
                          value(0),
                          reportType === 'k2' && transitionK3toK2
                            ? value(0)
                            : undefined,
                          value(0),
                          value(0),
                          reportType === 'k2' && transitionK3toK2
                            ? value(0)
                            : undefined,
                          ixbrlCell(
                            value(0),
                            changesInEquityIxbrl(
                              'ForandringEgetKapitalBalanseratResultatInklusivePeriodensResultatAretsResultat'
                            )
                          ),
                          value(0),
                          ixbrlCell(
                            // TODO: unclear requirement below. Try with ifOrElse helper
                            // Sum of all verifications on 2099 during the year
                            // that has account 2089 or/ and 2091 in the verification.
                            // Same value as årets resultat for Balanseras i ny räkning?
                            ref(multiply(-1, account('2099'))),
                            changesInEquityIxbrl(
                              'ForandringEgetKapitalAretsResultatAretsResultat',
                              undefined,
                              undefined,
                              'Ökning (minskning) av periodens resultat till följd av periodens resultat'
                            )
                          ),
                          ixbrlCell(
                            sumRowValues(
                              'managementReport.changesInEquity.table.main.content.yearResult',
                              reportType,
                              transitionK3toK2
                            ),
                            changesInEquityIxbrl(
                              'ForandringEgetKapitalTotaltAretsResultat',
                              undefined,
                              undefined,
                              'Ökning (minskning) av eget kapital till följd av periodens resultat'
                            )
                          ),
                          getWarningCell(
                            `${rows.getBaseId()}.yearResult.balanseratResultat`
                          ),
                          getWarningCell(
                            `${rows.getBaseId()}.yearResult.yearResult`
                          ),
                        ],
                        isRowActive: true,
                      }
                    : undefined
                )
                .build()
            )
            // final row, sums up each column for K2 or for transitionK3toK2
            .addRowManagementReport({
              id: 'sumByYearEnd',
              cells: [
                value('Belopp vid årets utgång'),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        `managementReport.changesInEquity.table.main.content.*.shareCapital`
                      ),
                      transitionK3toK2
                        ? id(
                            `managementReport.changesInEquity.table.transitionToK2.*.shareCapital`
                          )
                        : 0
                    )
                  ),
                  changesInEquityIxbrl('Aktiekapital', 'balans0', 'credit')
                ),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        `managementReport.changesInEquity.table.main.content.*.ejRegistreratAktiekapital`
                      ),
                      transitionK3toK2
                        ? id(
                            `managementReport.changesInEquity.table.transitionToK2.*.ejRegistreratAktiekapital`
                          )
                        : 0
                    )
                  ),
                  changesInEquityIxbrl(
                    'EjRegistreratAktiekapital',
                    'balans0',
                    'credit'
                  )
                ),
                undefined,
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        `managementReport.changesInEquity.table.main.content.*.bundenOverkursfond`
                      ),
                      transitionK3toK2
                        ? id(
                            `managementReport.changesInEquity.table.transitionToK2.*.bundenOverkursfond`
                          )
                        : 0
                    )
                  ),
                  changesInEquityIxbrl(
                    'OverkursfondBunden',
                    'balans0',
                    'credit'
                  )
                ),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        `managementReport.changesInEquity.table.main.content.*.uppskrivingsFond`
                      ),
                      transitionK3toK2
                        ? id(
                            `managementReport.changesInEquity.table.transitionToK2.*.uppskrivingsFond`
                          )
                        : 0
                    )
                  ),
                  changesInEquityIxbrl('Uppskrivningsfond', 'balans0', 'credit')
                ),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        `managementReport.changesInEquity.table.main.content.*.reservFond`
                      ),
                      transitionK3toK2
                        ? id(
                            `managementReport.changesInEquity.table.transitionToK2.*.reservFond`
                          )
                        : 0
                    )
                  ),
                  changesInEquityIxbrl('Reservfond', 'balans0', 'credit')
                ),
                undefined,
                transitionK3toK2
                  ? ref(
                      sum(
                        id(
                          `managementReport.changesInEquity.table.main.content.*.fondForUtvecklingsutgifter`
                        ),
                        id(
                          `managementReport.changesInEquity.table.transitionToK2.*.fondForUtvecklingsutgifter`
                        )
                      )
                    )
                  : undefined,
                transitionK3toK2
                  ? ref(
                      sum(
                        id(
                          `managementReport.changesInEquity.table.main.content.*.ovrigaBundnaFonder`
                        ),
                        id(
                          `managementReport.changesInEquity.table.transitionToK2.*.ovrigaBundnaFonder`
                        )
                      )
                    )
                  : undefined,
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        `managementReport.changesInEquity.table.main.content.*.friOverkursfond`
                      ),
                      transitionK3toK2
                        ? id(
                            `managementReport.changesInEquity.table.transitionToK2.*.friOverkursfond`
                          )
                        : 0
                    )
                  ),
                  changesInEquityIxbrl('Overkursfond', 'balans0', 'credit')
                ),
                transitionK3toK2
                  ? ref(
                      sum(
                        id(
                          `managementReport.changesInEquity.table.main.content.*.fondForVerkligtVarde`
                        ),
                        id(
                          `managementReport.changesInEquity.table.transitionToK2.*.fondForVerkligtVarde`
                        )
                      )
                    )
                  : undefined,
                transitionK3toK2
                  ? ref(
                      sum(
                        id(
                          `managementReport.changesInEquity.table.main.content.*.ovrigaFriaFonder`
                        ),
                        id(
                          `managementReport.changesInEquity.table.transitionToK2.*.ovrigaFriaFonder`
                        )
                      )
                    )
                  : undefined,
                undefined,
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        `managementReport.changesInEquity.table.main.content.*.balanseratResultat`
                      ),
                      transitionK3toK2
                        ? id(
                            `managementReport.changesInEquity.table.transitionToK2.*.balanseratResultat`
                          )
                        : 0
                    )
                  ),
                  changesInEquityIxbrl(
                    'BalanseratResultat',
                    'balans0',
                    'credit'
                  )
                ),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        `managementReport.changesInEquity.table.main.content.*.yearResult`
                      ),
                      transitionK3toK2
                        ? id(
                            `managementReport.changesInEquity.table.transitionToK2.*.yearResult`
                          )
                        : 0
                    )
                  ),
                  changesInEquityIxbrl(
                    'AretsResultatEgetKapital',
                    'balans0',
                    'credit'
                  )
                ),
                ixbrlCell(
                  ref(
                    sum(
                      id(
                        `managementReport.changesInEquity.table.main.content.*.total`
                      ),
                      transitionK3toK2
                        ? id(
                            `managementReport.changesInEquity.table.transitionToK2.*.total`
                          )
                        : 0
                    )
                  ),
                  changesInEquityIxbrl(
                    'ForandringEgetKapitalTotalt',
                    'balans0',
                    'credit'
                  )
                ),
              ],
              isRowActive: true,
            })
            .build()
        )
        .build()
    )
    .build();
};

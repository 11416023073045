import React, { useState, useCallback, useEffect, useContext } from 'react';
import { TextField, TextFieldProps, Tooltip } from '@material-ui/core';
import AccountSelector from '_shared/components/AccountSelector';
import styled from '@emotion/styled';
import TaxesDataContext from '_tax/service/TaxesDataContext';

interface EditLabelProps {
  rowId: string;
  value: string;
  onChange: (label: string) => void;
  onAccountSelected?: (
    selectedAccount: string | undefined,
    selectedAccountName: string | undefined,
    accountId: string,
    close?: () => void
  ) => void;
  tooltip?: string | JSX.Element;
  tableSelectedAccounts?: string[] | [];
}

const LabelField = styled(TextField)`
  width: 50%;
  .MuiOutlinedInput-adornedEnd {
    padding-right: ${(props) => props.theme.spacing(0.5)}px;
  }
`;

const EditLabel = React.forwardRef(
  (
    {
      rowId,
      value,
      onChange,
      onAccountSelected,
      tooltip,
      tableSelectedAccounts,
      ...textFieldProps
    }: EditLabelProps & Omit<TextFieldProps, 'onChange'>,
    ref
  ) => {
    const [label, setLabel] = useState(value);
    const { financialYearObj: financialYear, clientId } =
      useContext(TaxesDataContext);

    const handleChange: React.ChangeEventHandler<HTMLInputElement> =
      useCallback((event) => {
        setLabel(event.currentTarget.value);
      }, []);

    useEffect(() => {
      setLabel(value);
    }, [value, setLabel]);

    const handleBlur = useCallback(
      (event) => {
        if (label !== value) {
          onChange(label);
        }
      },
      [onChange, label, value]
    );

    const handleEnter = useCallback(
      (event) => {
        if (event.key === 'Enter') {
          if (label !== value) {
            onChange(label);
          }
        }
      },
      [onChange, label, value]
    );

    useEffect(() => {
      setLabel(value);
    }, [value, setLabel]);

    const field = (
      <LabelField
        {...textFieldProps}
        variant="outlined"
        value={label}
        size="small"
        inputRef={ref}
        onChange={handleChange}
        onBlur={handleBlur}
        onKeyPress={handleEnter}
        InputProps={
          onAccountSelected && {
            endAdornment: (
              <AccountSelector
                clientId={clientId}
                financialYear={financialYear}
                rowId={rowId}
                onAccountSelected={onAccountSelected}
                tableSelectedAccounts={tableSelectedAccounts}
              />
            ),
          }
        }
      />
    );

    if (tooltip) {
      return (
        <Tooltip title={tooltip} placement="bottom-start">
          {field}
        </Tooltip>
      );
    }
    return field;
  }
);

export default EditLabel;

import { format } from '@agoy/dates';
import { subYears } from 'date-fns';
import { ShareOwners } from './types';

export const getFullOwnersList = (
  owners: ShareOwners[],
  currentStock: number | undefined,
  otherOwnersName: string
): ShareOwners[] => {
  const ownersList = [...owners];
  const sumOfShares = ownersList.reduce(
    (sum, owner) => (owner.ownedShares ? sum + owner.ownedShares : sum),
    0
  );

  if (currentStock && sumOfShares < currentStock) {
    ownersList.push({
      relationId: -1,
      ownerType: 'other',
      ownerName: otherOwnersName,
      ownerNumber: '',
      sharePercentage: ((currentStock - sumOfShares) / currentStock) * 100,
      ownedShares: currentStock - sumOfShares,
      visible: false,
    });
  }
  return ownersList;
};

export const getLastFinancialYear = () => {
  const lastYear = subYears(new Date(), 1);
  const formattedFirstDay = `${format(lastYear, 'yyyy')}0101`;
  const formattedLastDay = `${format(lastYear, 'yyyy')}1231`;

  return `${formattedFirstDay}-${formattedLastDay}`;
};

import React from 'react';
import { Tooltip, Typography, makeStyles } from '@material-ui/core';
import { green } from '@agoy/theme/src/colors';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';
import { stringValue } from '@agoy/document';
import { TaxDeclarationFormPartFields } from '@agoy/tax-declaration-document';
import When from '../When/When';

const useStyles = makeStyles(() => ({
  customTooltip: {
    backgroundColor: `${green.DARK}`,
    maxWidth: 400,
    fontSize: 14,
  },
  customArrow: {
    color: `${green.DARK}`,
  },
}));

type TooltipPropsType = {
  children: JSX.Element;
  percent: string;
  formPartFields?: TaxDeclarationFormPartFields;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 5px;
`;

const calculatePercent = (orgNumber: string) => {
  const number =
    orgNumber.length === 11
      ? Number(orgNumber.slice(0, 2))
      : Number(orgNumber.slice(2, 4));

  if (number <= 37) return 0;
  if (number >= 38 && number <= 56) return 0.1;
  return 0.25;
};

const CalculatedInfoTooltip = ({
  children,
  percent,
  formPartFields,
}: TooltipPropsType) => {
  const classes = useStyles();

  const r42Value = formPartFields?.R42.value;
  const orgNumberPercentage =
    formPartFields &&
    calculatePercent(stringValue(formPartFields.companyOrgNumber) || '');

  const percentage = percent || orgNumberPercentage;

  const sum = Number(r42Value || 0) * Number(percentage);

  const { formatMessage } = useIntl();
  const formattedPercent = Number(percentage) * 100;

  return (
    <Tooltip
      title={
        <Container>
          <b>{formatMessage({ id: 'R43.field.title' })}</b>

          <When isTrue={percentage !== undefined}>
            <Typography>
              {formatMessage({
                id: `R43.field.tooltip.${percentage}.percent`,
              })}
            </Typography>
          </When>
          {`Summa = ${formattedPercent || 0}% * R42`}

          <When
            isTrue={percentage !== undefined}
            fallback={
              <Typography>
                {formatMessage({ id: 'R43.field.tooltip.missingData' })}
              </Typography>
            }
          >
            <Typography>{`${Math.round(sum)} = ${percentage} * ${
              r42Value || 0
            }`}</Typography>
          </When>

          {String(percentage) === '0.25' && (
            <Typography>
              {formatMessage({ id: 'R43.field.tooltip.percent.extra.info' })}
            </Typography>
          )}
        </Container>
      }
      placement="right"
      arrow
      classes={{
        tooltip: classes.customTooltip,
        arrow: classes.customArrow,
      }}
    >
      {children}
    </Tooltip>
  );
};

export default CalculatedInfoTooltip;

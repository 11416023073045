import React from 'react';
import styled from '@emotion/styled';
import content from './content.json';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${({ theme }) => theme.spacing(0.5)}px;
  padding-bottom: 40px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;

  :not(:nth-of-type(1)) {
    div {
      border-top: none;
    }
  }
`;

const EmptyRow = styled.div`
  height: 40px;
  border-bottom: 2px solid #eeeeee;
`;

const Cell = styled.div`
  padding: ${({ theme }) => theme.spacing(1)}px
    ${({ theme }) => theme.spacing(2)}px;
  border: 2px solid #eeeeee;
  border-left: none;
  flex: 1;
`;

const Legend = () => {
  return (
    <Container>
      {content.map((row) =>
        row ? (
          <Row key={`${row.label}+${row.calculation}}`}>
            <Cell>{row.label}</Cell>
            <Cell>{row.calculation}</Cell>
          </Row>
        ) : (
          <EmptyRow key={Math.random() * 1000} />
        )
      )}
    </Container>
  );
};

export default Legend;

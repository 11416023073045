/* eslint-disable no-console */
// eslint-disable-next-line import/no-cycle
import { UpdateTaxDeclarationTableRows } from 'redux/actions';
import { isError, operations } from '@agoy/document';
import { TaxDeclarationFormData } from '@agoy/tax-declaration-document';
import TaxDeclarationViewState from '../types';

const updateTaxDeclarationFormTableRows = (
  state: TaxDeclarationViewState,
  action: UpdateTaxDeclarationTableRows
): TaxDeclarationViewState => {
  if (Object.keys(state.formData).length === 0) {
    return state;
  }

  const selectedFormId = state.viewFormId;
  if (!selectedFormId) return state;

  const formData = state.formData[selectedFormId];
  if (!formData) {
    return state;
  }

  const { rows } = action;

  const result = operations.updateTableRows(
    formData.definition,
    {
      document: formData.document,
      changes: formData.changes,
    },
    rows
  );

  if (!result || isError(result)) {
    console.warn(action, result);
    return state;
  }

  return {
    ...state,
    formData: {
      ...state.formData,
      [selectedFormId]: {
        ...formData,
        document: result.document as TaxDeclarationFormData,
        changes: result.changes,
      },
    },
  };
};

export default updateTaxDeclarationFormTableRows;

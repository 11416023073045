import { AgoyDocument, AgoyDocumentChanges } from '@agoy/document';
import { balanceSheet } from '../common/types/balanceSheet';
import { confirmationCertificate } from '../common/types/confirmationCertificate';
import { incomeStatement } from '../common/types/incomeStatement';
import { manualSubmission } from '../common/types/manualSubmission';
import { settings } from '../common/types/settings';
import { signatures } from '../common/types/signatures';
import { annualGeneralMeeting } from './annual-general-meeting/types';
import { managementReport } from './management-report/types';
import { notes } from './notes/v1/types';
import { generatedV2K2NotesType } from './notes/v2/k2/types';
import { generatedV2K3NotesType } from './notes/v2/k3/types';
import {
  cashFlowStatement,
  statementSectionV2,
} from './cash-flow-statement/v1/types';
import { digitalSubmission } from './digital-submission/types';
import { AnnualReportType } from '../document';

export const sharesCompanyContentDefinition = {
  type: 'document' as const,
  children: {
    settings,
    confirmationCertificate,
    managementReport,
    incomeStatement,
    balanceSheet,
    cashFlowStatement,
    notes,
    signatures,
    annualGeneralMeeting,
    digitalSubmission,
    manualSubmission,
  },
  version: '1' as const,
  documentType: 'shares' as const,
};

type DocumentPartUnion =
  | typeof generatedV2K3NotesType
  | typeof generatedV2K2NotesType;

export const sharesCompanyContentDefinitionK2V2 = {
  type: 'document' as const,
  children: {
    settings,
    managementReport,
    incomeStatement,
    balanceSheet,
    cashFlowStatement: statementSectionV2,
    notes: generatedV2K2NotesType as DocumentPartUnion,
  },
  version: '2' as const,
  documentType: 'shares' as const,
};

export const sharesCompanyContentDefinitionK3V2 = {
  type: 'document' as const,
  children: {
    settings,
    managementReport,
    incomeStatement,
    balanceSheet,
    cashFlowStatement: statementSectionV2,
    notes: generatedV2K3NotesType as DocumentPartUnion,
  },
  version: '2' as const,
  documentType: 'shares' as const,
};

const sharesCompanyFirstNote = ['note1'];

export const sharesIncomeStatementNotes = [
  'note2',
  'note3',
  'note4',
  'note5',
  'note59',
  'note60',
  'note61',
  'note62',
  'note63',
  'note64',
  'note74',
  'note79',
  'note81',
  'note85',
  'note89',
  'note90',
  'note91',
  'note93',
  'note101',
];

export const sharesBalanceSheetNotes = [
  'note43',
  'note6',
  'note7',
  'note8',
  'note9',
  'note10',
  'note11',
  'note12',
  'note13',
  'note14',
  'note15',
  'note16',
  'note17',
  'note18',
  'note19',
  'note20',
  'note21',
  'note22',
  'note23',
  'note24',
  'note25',
  'note26',
  'note27',
  'note28',
  'note29',
  'note30',
  'note31',
  'note32',
  'note33',
  'note34',
  // 40-42 and 44 come after 32 and before 33
  'note40',
  'note41',
  'note42',
  'note44',
  'note58',
  'note65',
  'note66',
  'note69',
  'note70',
  'note71',
  'note72',
  'note73',
  'note75',
  'note76',
  'note77',
  'note78',
  'note82',
  'note83',
  'note87',
  'note92',
  'note94',
  'note95',
  'note96',
  'note97',
  'note98',
  'note99',
  'note100',
  'note401',
  'note402',
];

export const getSharesOtherNotes = (
  reportType: AnnualReportType,
  documentTypeVersion = '1'
) => {
  if (reportType === 'k3') {
    if (documentTypeVersion === '1') {
      return [
        'note1',
        'note2',
        'note33',
        'note34',
        'note35',
        'note36',
        'note37',
        'note38',
        'note39',
        'note67',
        'note68',
        'note80',
        'note84',
        'note86',
        'custom',
      ];
    }

    return [
      'note1',
      'note35',
      'note36',
      'note67',
      'note80',
      'note84',
      'note86',
      'note88',
    ];
  }

  // k2
  return ['note1', 'note2', 'note35', 'note36', 'note67', 'note68', 'custom'];
};

export type SharesCompanyAnnualReport = AgoyDocument<
  typeof sharesCompanyContentDefinition
>;

export type SharesCompanyAnnualReportK3V2 = AgoyDocument<
  typeof sharesCompanyContentDefinitionK3V2
>;

export type SharesCompanyAnnualReportK2V2 = AgoyDocument<
  typeof sharesCompanyContentDefinitionK2V2
>;

export type SharesCompanyAnnualReportChanges = AgoyDocumentChanges<
  typeof sharesCompanyContentDefinition
>;

export type SharesCompanyAnnualReportK3V2Changes = AgoyDocumentChanges<
  typeof sharesCompanyContentDefinitionK3V2
>;

export type SharesCompanyAnnualReportK2V2Changes = AgoyDocumentChanges<
  typeof sharesCompanyContentDefinitionK2V2
>;

export type SharesCompanyAnnualReportStructure =
  typeof sharesCompanyContentDefinition;

export type SharesCompanyAnnualReportK3V2Structure =
  typeof sharesCompanyContentDefinitionK3V2;

export type SharesCompanyAnnualReportK2V2Structure =
  typeof sharesCompanyContentDefinitionK2V2;

import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import styled from '@emotion/styled';
import {
  Box,
  Checkbox,
  FormControlLabel as MuiFormControlLabel,
} from '@material-ui/core';
import {
  AnnualGeneralMeeting as AnnualGeneralMeetingType,
  isActive,
} from '@agoy/annual-report-document';
import DocumentViewServiceContext from '_shared/services/document/DocumentViewServiceContext';
import { stringValue } from '@agoy/document';
import NameTable from './NameTable';
import Paragraph from './Paragraph';
import { TypographyPreview } from './AnnualGeneralMeeting';

const Typography = styled(TypographyPreview)`
  font-size: 1rem;
  font-weight: 400;
`;

const FormControlLabel = styled(MuiFormControlLabel)`
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: ${(props) => props.theme.spacing(1.5)}px;
`;

interface AuditorsParagraphProps {
  no: number;
  annualGeneralMeeting: AnnualGeneralMeetingType;
  isPreview?: boolean;
  print: boolean;
  editable?: boolean;
}

const AuditorsParagraph = ({
  no,
  annualGeneralMeeting,
  print,
  isPreview,
  editable,
}: AuditorsParagraphProps): JSX.Element => {
  const { formatMessage } = useIntl();

  const { auditors, noAuditor, deputyAuditors, auditFirm } =
    annualGeneralMeeting.main;

  const noAuditorValue = stringValue(annualGeneralMeeting.main.noAuditor) || '';

  const heading = stringValue(
    annualGeneralMeeting.main.auditorsParagraphHeading
  );
  const text = stringValue(
    annualGeneralMeeting.main.auditorsParagraphHeadingText
  );

  const auditorTitleLabel = stringValue(annualGeneralMeeting.main.auditorTitle);
  const deputyAuditorTitleLabel = stringValue(
    annualGeneralMeeting.main.deputyAuditorTitle
  );

  const numberOfAuditors = auditors.rows.filter((row) =>
    isActive(row.active)
  ).length;
  const service = useContext(DocumentViewServiceContext);

  const handleNoAuditor = (event) => {
    const { value } = event.target;
    service.updateField(
      'annualGeneralMeeting.main.noAuditor',
      value === noAuditor ? '' : value
    );
  };

  /* If no selection is made in either assigning accountant 
  or choosing a checkbox option we render empty paragraph */
  if (
    auditors.rows.length === 0 &&
    deputyAuditors.rows.length === 0 &&
    !noAuditor &&
    print
  ) {
    return (
      <Paragraph
        no={no}
        id="12"
        headingId="annualGeneralMeeting.main.auditorsParagraphHeading"
        heading={
          heading ||
          formatMessage({
            id: `annualReport.annualGeneralMeeting.main.12`,
          })
        }
        optionalComment
        defaultText={formatMessage({
          id: `comment`,
        })}
        textId="annualGeneralMeeting.main.auditorsParagraphHeadingText"
        text={text}
        editable={!print && editable}
        isPreview={isPreview}
      />
    );
  }

  return (
    <Paragraph
      no={no}
      id="12"
      headingId="annualGeneralMeeting.main.auditorsParagraphHeading"
      heading={
        heading ||
        formatMessage({
          id: `annualReport.annualGeneralMeeting.main.12`,
        })
      }
      optionalComment
      defaultText={formatMessage({
        id: `comment`,
      })}
      editable={!print && editable}
      textId="annualGeneralMeeting.main.auditorsParagraphHeadingText"
      text={text}
      isPreview={isPreview}
    >
      {(editing) => (
        <>
          <Box marginTop={2}>
            <NameTable
              editing={editing}
              print={print}
              table={auditors}
              tableId="annualGeneralMeeting.main.auditors"
              addLabelId="annualReport.annualGeneralMeeting.add.auditor"
              titleLabel={
                auditorTitleLabel ||
                formatMessage(
                  {
                    id: 'annualReport.annualGeneralMeeting.main.12.text',
                  },
                  {
                    deputy: auditors.rows.some(
                      (row) => stringValue(row.cells?.role) === 'deputy_auditor'
                    ),
                  }
                )
              }
              editTitle={editing}
              titleId="auditorTitle"
              additionalRowId={auditFirm?.rows[0]?.id}
            />
          </Box>
          {deputyAuditors.rows.length > 0 && (
            <Box marginTop={2}>
              <NameTable
                editing={editing}
                print={print}
                table={deputyAuditors}
                tableId="annualGeneralMeeting.main.deputyAuditors"
                addLabelId="annualReport.annualGeneralMeeting.add.deputyauditor"
                titleLabel={
                  deputyAuditorTitleLabel ||
                  formatMessage({
                    id: 'annualReport.annualGeneralMeeting.main.12.text2',
                  })
                }
                editTitle={editing}
                titleId="deputyAuditorTitle"
              />
            </Box>
          )}
          {numberOfAuditors === 0 && !print && (
            <Box maxWidth={800}>
              {['1', '2', '3'].map((option) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={noAuditorValue === option}
                      value={option}
                      onChange={handleNoAuditor}
                      disabled={!editable}
                    />
                  }
                  label={formatMessage({
                    id: `annualReport.annualGeneralMeeting.no.auditor.${option}`,
                  })}
                  contentEditable={editing}
                  suppressContentEditableWarning
                  labelPlacement="end"
                  key={option}
                />
              ))}
            </Box>
          )}

          {numberOfAuditors === 0 &&
            print &&
            ['1', '2', '3'].map((option) => {
              if (!noAuditorValue) return null;
              if (noAuditorValue !== '' && noAuditorValue !== option)
                return null;
              return (
                <Typography key={`auditors-nr-${option}`}>
                  {formatMessage({
                    id: `annualReport.annualGeneralMeeting.no.auditor.${noAuditorValue}`,
                  })}
                </Typography>
              );
            })}
        </>
      )}
    </Paragraph>
  );
};

export default AuditorsParagraph;

import React from 'react';
import { Typography } from '@material-ui/core';
import styled from '@emotion/styled';
import { AgoyTableRow, AgoyTableColumn } from '@agoy/document';
import ExpandableTable from '_shared/components/Table/ExpandableTable';
import { PropertyTaxTableDataType } from '_reconciliation/types';
import {
  NumberFormatType,
  PercentageNumberFormatType,
  StandardNumberFormatType,
} from '@agoy/common';
import { propertyTypeSelects } from './propertyTaxUtil';

export type ExpandableTableData = {
  columns: AgoyTableColumn[];
  rows: AgoyTableRow[];
};

type Props = {
  data: PropertyTaxTableDataType | undefined;
};

const TableWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.palette.grey[300]};
  border-radius: 5px;
  border-bottom: none;
`;

const columnKeys = [
  'name',
  'type',
  'owned',
  'numberOfOwners',
  'area',
  'taxValue',
  'ownedTaxValue',
  'taxPercentage',
  'taxSum',
  'taxExempt',
  'ceilingAmount',
  'numberOfLGH',
  'fixedAmount',
];

const defaultCells = {};

columnKeys.forEach((key) => {
  if (key === 'type') {
    defaultCells[key] = {
      type: 'select',
      selects: propertyTypeSelects,
      value: {
        select: '',
        option: '',
      },
      align: 'left',
    };
  } else if (key === 'owned' || key === 'taxPercentage') {
    defaultCells[key] = {
      type: 'ref',
      value: undefined,
      align: 'left',
    };
  } else if (key === 'name') {
    defaultCells[key] = {
      type: 'string',
      value: '',
      align: 'left',
    };
  } else if (key === 'taxExempt') {
    defaultCells[key] = {
      type: 'boolean',
      value: '',
      align: 'left',
    };
  } else {
    defaultCells[key] = {
      type: 'number',
      value: undefined,
      align: 'left',
    };
  }
});

const SumRow = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: ${({ theme }) => theme.spacing(2)}px
    ${({ theme }) => theme.spacing(4)}px;
  width: 100%;
  background-color: #fff;
  box-shadow: ${({ theme }) => theme.shadows[1]};
`;

const SumRowTitle = styled(Typography)`
  font-size: 20px;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.text.primary};
  text-transform: capitalize;
`;

const SumRowTotal = styled(Typography)`
  margin-left: 30px;
  font-size: 20px;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.text.primary};
  text-transform: capitalize;
`;

const usePercentageForColumn = (colId: string): NumberFormatType =>
  colId === 'owned' || colId === 'taxPercentage'
    ? PercentageNumberFormatType
    : StandardNumberFormatType;

const PropertyTaxPrintTable = ({ data }: Props) => {
  return (
    <TableWrapper>
      {data && (
        <>
          <SumRow>
            <SumRowTitle>Total fastighetsskatt</SumRowTitle>
            <SumRowTotal>{data.total}</SumRowTotal>
          </SumRow>
          <ExpandableTable
            isEditing={false}
            baseId="propertyTax"
            tableId="propertyTaxTable"
            defaultColumnKeys={columnKeys}
            initialData={data.rows}
            initialColumns={data.columns}
            numberFormatColumn={usePercentageForColumn}
            widthFitContent
          />
        </>
      )}
    </TableWrapper>
  );
};

export default PropertyTaxPrintTable;

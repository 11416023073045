import React, { useState } from 'react';
import styled from '@emotion/styled';

import { useSelector } from 'redux/reducers';
import { addGlobalErrorMessage } from '_messages/redux/actions';

import {
  addOrganisationInfo,
  attachAuthorisedPersons,
  deleteMember,
  makeMemberAdmin,
  removeMemberAdmin,
  toggleMemberAuthorisationForPerson,
} from '_organization/redux/actions';
import MembersList from '_organization/components/organisms/MembersList';
import EditMemberModal, {
  calculateAuthorisedClients,
  Clients,
} from '_organization/components/organisms/ModalEditMember';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { asResultClass, getApiSdk, useApiSdk } from 'api-sdk';
import { getClients } from 'redux/actions';
import MemberInvites from '_organization/components/organisms/MemberInvites';
import { Awaited } from '@agoy/common';
import { InviteOfOrganisation } from '_organization/types';
import { getPersons } from '_person/redux/persons/actions';

type Sdk = Awaited<ReturnType<typeof getApiSdk>>;

const MembersWrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

const MembersContainer = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const sdk = useApiSdk();

  const [selectedMember, setSelectedMember] = useState<
    Member.MemberType | undefined
  >();
  const [clients, setClients] = useState<Clients>({});
  const persons = useSelector((state) => state.persons);
  const [invites, setInvites] =
    useState<Awaited<ReturnType<Sdk['getOrganisationInvites']>>>();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState({
    members: false,
    invites: false,
  });
  const [addInviteError, setInviteError] = useState('');

  const allClients = useSelector((state) => state.customers) || [];
  const orgMembers = useSelector((state) => state.organisation.users);
  const isAdmin =
    useSelector((state) => state.user.roles)?.includes('OrganisationAdmin') ||
    false;
  const loggedInUserEmail = useSelector((state) => state.user.email) || '';

  const isThisUserAdmin =
    selectedMember?.roles?.includes('OrganisationAdmin') || false;

  // API
  const fetchMembers = async () => {
    setLoading((l) => ({ ...l, members: true }));
    const response = await asResultClass(sdk.getOrganisationMembers());
    setLoading((l) => ({ ...l, members: false }));
    if (response.ok) {
      const users = await attachAuthorisedPersons(
        response.val as Member.MemberType[]
      );

      dispatch(addOrganisationInfo({ users }));
    } else {
      throw Error(response.val.message);
    }
  };

  const fetchInvites = async () => {
    if (!isAdmin) return setInvites([]);

    setLoading((l) => ({ ...l, invites: true }));
    const result = await asResultClass(sdk.getOrganisationInvites());
    setLoading((l) => ({ ...l, invites: false }));

    if (result.ok) {
      setInvites(result.val);
    } else {
      throw Error(result.val.message);
    }
  };

  const createInvite = async (email: string) => {
    setLoading((l) => ({ ...l, invites: true }));

    const result = await asResultClass(
      sdk.createInvite({ requestBody: { email: email.toLocaleLowerCase() } })
    );

    if (result.err) {
      setInviteError(JSON.stringify(result.val.message));
    }

    setLoading((l) => ({ ...l, invites: false }));
  };

  const deleteInvite = async (inviteId: string) => {
    setLoading((l) => ({ ...l, invites: true }));

    const result = await asResultClass(
      sdk.deleteOrganisationInvite({
        inviteId,
      })
    );

    if (result.err) {
      setInviteError(JSON.stringify(result.val));
    }

    setLoading((l) => ({ ...l, invites: false }));
  };

  // EFFECTS
  React.useEffect(() => {
    fetchMembers();
    fetchInvites();
    dispatch(getClients());
    dispatch(getPersons());
  }, []);

  const handleDeleteMember = async () => {
    if (!selectedMember) return;

    try {
      await dispatch(deleteMember(selectedMember));
      setIsModalOpen(false);
    } catch (error) {
      dispatch(addGlobalErrorMessage('error'));
    }
  };

  const handleSelectMember = async (userId: string) => {
    setSelectedMember(orgMembers.find((m) => m.userId === userId));

    const authorizedClients = await sdk.getAuthorizedClientsForUser({
      userId,
    });
    setClients(calculateAuthorisedClients(allClients, authorizedClients));

    setIsModalOpen(true);
  };

  const handleModifyRole = async (newRole: string) => {
    if (!selectedMember) return;

    if (newRole === 'OrganisationAdmin') {
      try {
        await dispatch(makeMemberAdmin(selectedMember));
        setSelectedMember({ ...selectedMember, roles: ['OrganisationAdmin'] });
      } catch (error) {
        dispatch(
          addGlobalErrorMessage('dashboard.members.edit.grantAccessFailed')
        );
        return;
      }
    }

    if (newRole === 'AccountingConsultant') {
      try {
        await dispatch(removeMemberAdmin(selectedMember));
        setSelectedMember({
          ...selectedMember,
          roles: ['AccountingConsultant'],
        });
      } catch (error) {
        dispatch(
          addGlobalErrorMessage('dashboard.members.edit.grantAccessFailed')
        );
      }
    }
  };

  const handleToggleClientAuthorisation = async (clientId: string) => {
    if (!isAdmin) {
      dispatch(addGlobalErrorMessage('Only admins can edit permissions'));
      return;
    }

    if (!selectedMember) {
      dispatch(addGlobalErrorMessage('A member must be selected'));
      return;
    }

    if (isThisUserAdmin && clients[clientId].isAuthorisedEditedValue) {
      dispatch(
        addGlobalErrorMessage('dashboard.members.edit.cannotRemoveAdmin')
      );
      return;
    }

    try {
      if (clients[clientId].isAuthorisedEditedValue) {
        await sdk.deleteAuthorizedClientsForUser({
          userId: selectedMember.userId,
          clientId,
        });
      } else {
        await sdk.authorizeClientForUser({
          userId: selectedMember.userId,
          clientId,
        });
      }

      setClients({
        ...clients,
        [clientId]: {
          ...clients[clientId],
          isAuthorisedEditedValue: !clients[clientId].isAuthorisedEditedValue,
        },
      });
    } catch (e) {
      const message = formatMessage({
        id: clients[clientId].isAuthorisedEditedValue
          ? 'dashboard.members.edit.grantAccessFailed'
          : 'dashboard.members.edit.removeAccessFailed',
      });
      dispatch(addGlobalErrorMessage(message));
    }
  };

  const handleTogglePersonAuthorisation = async (personId: string) => {
    if (!isAdmin) {
      const id = 'dashboard.members.authorisation.notAdmin';
      dispatch(addGlobalErrorMessage(formatMessage({ id })));
      return;
    }

    if (!selectedMember || selectedMember?.authorisedPersons == null) {
      dispatch(addGlobalErrorMessage('Du måste välja en medlem.'));
      return;
    }

    const isAuthorised =
      selectedMember?.authorisedPersons.find((p) => p.id === personId)?.id !=
      null;

    await dispatch(
      toggleMemberAuthorisationForPerson(selectedMember, personId, isAuthorised)
    );

    if (isAuthorised) {
      const authorisedPersons = selectedMember?.authorisedPersons.filter(
        (p) => p.id !== personId
      );

      setSelectedMember({ ...selectedMember, authorisedPersons });
    } else {
      setSelectedMember({
        ...selectedMember,
        authorisedPersons: [
          ...selectedMember.authorisedPersons,
          { id: personId },
        ],
      });
    }
  };

  const handleSendInvite = async (email: string) => {
    if (!email) return;

    setInviteError('');

    await createInvite(email);
    await fetchInvites();
  };

  const handleDeleteInvite = async (invite: InviteOfOrganisation) => {
    setInviteError('');

    await deleteInvite(invite.id);
    await fetchInvites();
  };

  return (
    <MembersWrapper>
      {isModalOpen && selectedMember && (
        <EditMemberModal
          isAdmin={isAdmin}
          member={selectedMember}
          clients={clients}
          persons={persons}
          loggedInUserEmail={loggedInUserEmail}
          handleClose={() => setIsModalOpen(false)}
          handleModifyRole={handleModifyRole}
          handleDeleteMember={handleDeleteMember}
          handleToggleClientAuthorisation={handleToggleClientAuthorisation}
          handleTogglePersonAuthorisation={handleTogglePersonAuthorisation}
        />
      )}
      <MembersList
        isAdmin={isAdmin}
        isLoading={loading.members}
        orgMembers={orgMembers}
        handleSelectMember={handleSelectMember}
        MemberInvitations={
          <MemberInvites
            isLoading={loading.invites}
            error={addInviteError}
            invites={invites}
            handleSendInvite={handleSendInvite}
            handleDeleteInvite={handleDeleteInvite}
          />
        }
      />
    </MembersWrapper>
  );
};

export default MembersContainer;

import { AgoyDocumentStructure } from '../document';
import updateCell from './helpers/updateCell';
import updateDocument from './helpers/updateDocument';
import { OperationResult, State } from './types';

const updateCellReferences = <T extends AgoyDocumentStructure>(
  structure: T,
  state: State<T>,
  id: string,
  references: string[]
): OperationResult<T> => {
  return updateDocument(structure, state, id, {
    table: (key, id, props) => {
      if (!key) {
        return props;
      }
      const updater = updateCell(
        key.slice(0, key.length - 1),
        key[key.length - 1],
        (cell) => {
          if (!cell || cell.type === 'refs') {
            return {
              type: 'refs',
              ...cell,
              references,
              values: references.map((_) => ({
                error: 'notResolved',
              })),
            };
          }
          console.error(
            `Trying to update a cell of type ${cell.type} with a reference`
          );
          return cell;
        }
      );
      const changes = props.changes || { type: 'update' };
      const result = updater(props.node, changes);
      if (Array.isArray(result)) {
        const [updatedTable, updatedChanges] = result;
        if (props.node !== updatedTable || changes !== updatedChanges) {
          return {
            ...props,
            node: updatedTable,
            changes: updatedChanges,
          };
        }
      }
      return props;
    },
  });
};

export default updateCellReferences;

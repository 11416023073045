export const intervalTypes = ['period', 'financialYear'] as const;

export const agoyStatuses = [
  'NOT_STARTED',
  'AW_COMPL',
  'STARTED',
  'AW_REVIEW',
  'DONE',
  'LOCKED',
] as const;

export const translations = {
  NOT_STARTED: { id: 'status.not.started' },
  STARTED: { id: 'status.started' },
  AW_COMPL: { id: 'status.awaiting.records' },
  AW_REVIEW: { id: 'status.awaiting.review' },
  DONE: { id: 'status.done' },
  LOCKED: { id: 'status.locked' },
};

export const programStatusMapSchema = {
  RECONCILIATION: {
    period: {
      NOT_STARTED: true,
      STARTED: true,
      DONE: true,
      LOCKED: true,
    },
    financialYear: {
      NOT_STARTED: true,
      STARTED: true,
      DONE: true,
      LOCKED: true,
    },
  },
  FIN_STATEMENT: {
    period: {
      NOT_STARTED: true,
      AW_COMPL: true,
      STARTED: true,
      AW_REVIEW: true,
      DONE: true,
    },
    financialYear: {
      NOT_STARTED: true,
      AW_COMPL: true,
      STARTED: true,
      AW_REVIEW: true,
      DONE: true,
    },
  },
  FIN_STATEMENT_REPORT: {
    period: {
      NOT_STARTED: true,
      AW_COMPL: true,
      STARTED: true,
      AW_REVIEW: true,
      DONE: true,
    },
    financialYear: {
      NOT_STARTED: true,
      AW_COMPL: true,
      STARTED: true,
      AW_REVIEW: true,
      DONE: true,
    },
  },
  AN_REPORT: {
    period: {
      NOT_STARTED: true,
      AW_COMPL: true,
      STARTED: true,
      AW_REVIEW: true,
      DONE: true,
      LOCKED: true,
    },
    financialYear: {
      NOT_STARTED: true,
      STARTED: true,
      AW_COMPL: true,
      AW_REVIEW: true,
      DONE: true,
      LOCKED: true,
    },
  },
  TAX_DECLARATION: {
    financialYear: {
      NOT_STARTED: true,
      AW_COMPL: true,
      STARTED: true,
      AW_REVIEW: true,
      DONE: true,
    },
  },
};

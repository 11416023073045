import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Dialog } from '@material-ui/core';
import { useIntl } from 'react-intl';
import Button from '_shared/components/Buttons/Button';
import Typography from '_shared/components/Typography/Typography';
import Alert from '_shared/components/AlertBanners/Alert';

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    padding: ${({ theme }) => theme.spacing(4)}px;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-radius: 8px;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 12px;
`;

interface Props {
  currentValue: string;
  confirmValue: string;
  onApprove: () => void;
  onClose: () => void;
}

const ClientCardInformationDialog = ({
  currentValue,
  confirmValue,
  onApprove,
  onClose,
}: Props) => {
  const { formatMessage } = useIntl();

  function getTextId(v) {
    switch (v) {
      case 'yearquarter':
      case 'yearmonth':
        return 'clientInformation.dialog.yearquartermonth';
      case 'monthyear':
      case 'monthquarter':
        return 'clientInformation.dialog.monthyearquarter';
      case 'quarteryear':
        return 'clientInformation.dialog.quarteryear';
      case 'quartermonth':
        return 'clientInformation.dialog.quartermonth';
      default:
        return 'clientInformation.dialog.yearquartermonth';
    }
  }

  return (
    <StyledDialog open={!!confirmValue} onClose={onClose}>
      <Header>
        <Typography variant="h2">
          {formatMessage({ id: 'clientInformation.dialog.title' })}
        </Typography>
      </Header>

      <Alert type="warning">
        <Typography variant="body1">
          {formatMessage({
            id: getTextId(currentValue + confirmValue),
          })}
        </Typography>
      </Alert>

      <ButtonContainer>
        <Button
          label={formatMessage({ id: 'cancel' })}
          variant="text"
          size="large"
          onClick={onClose}
        />
        <Button
          label={formatMessage({ id: 'clientInformation.dialog.accept' })}
          variant="contained"
          color="danger"
          size="large"
          onClick={onApprove}
        />
      </ButtonContainer>
    </StyledDialog>
  );
};

export default ClientCardInformationDialog;

import styled from '@emotion/styled';
import React from 'react';
import { useDrag, useDrop, DragSourceMonitor } from 'react-dnd';

const ItemType = 'TABLE_ITEM';

export const Td = styled.td`
  text-align: start;
  padding: ${(props) => props.theme.spacing(2)}px
    ${(props) => props.theme.spacing(2)}px;
  border-bottom: 1px solid #ccc;
  border-left: 1px solid #ccc;
  min-width: 240px;
  cursor: move;
`;

type DragItem = {
  rowIndex: number;
  columnIndex: number;
  isRow: boolean;
};

type DraggableCellProps = {
  children: React.ReactNode;
  columnIndex: number;
  rowIndex: number;
  moveRow: (fromIndex: number, toIndex: number, columnIndex: number) => void;
  moveColumn: (
    fromRowIndex: number,
    fromColumnIndex: number,
    toRowIndex: number,
    toColumnIndex: number
  ) => void;
};

const DraggableCell = ({
  children,
  columnIndex,
  rowIndex,
  moveRow,
  moveColumn,
}: DraggableCellProps) => {
  const [{ isDragging }, drag] = useDrag({
    type: ItemType,
    item: { rowIndex, columnIndex, isRow: false },
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: ItemType,
    hover: (item: DragItem) => {
      if (item.isRow) {
        moveRow(item.rowIndex, rowIndex, columnIndex);
      } else {
        moveColumn(item.rowIndex, item.columnIndex, rowIndex, columnIndex);
      }
    },
  });

  return (
    <Td
      ref={(node) => drag(drop(node))}
      style={{ opacity: isDragging ? 0.5 : 1 }}
    >
      {children}
    </Td>
  );
};

export default DraggableCell;

import React from 'react';
import styled from '@emotion/styled';

export const DefaultContainer = styled.div`
  padding: ${(props) => props.theme.spacing(2)}px;
`;

interface ContainerProps {
  children?: React.ReactNode;
}

function Container(props: ContainerProps) {
  const { children } = props;

  return <DefaultContainer>{children}</DefaultContainer>;
}

export default Container;

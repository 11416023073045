import { balanceSheetReferences } from '../balance-sheet/limitedCompaniesBalanceSheet';
import { incomeStatementReferences } from '../income-statement/limitedCompaniesIncomeStatement';

type References = {
  [key: string]: string;
};

export const limitedCompaniesReferences = (): References[] => {
  const partReferences = [
    balanceSheetReferences(),
    incomeStatementReferences(),
  ];

  return partReferences;
};

import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { AgoyTable } from '@agoy/document';
import { Settings } from '@agoy/annual-report-document';
import { isSinglePeriodSelected } from '../../utils/isSinglePeriodSelected';
import { rowHaveValues } from '../../utils/rowHaveValues';
import {
  HierarchicalPrintTableRowProps,
  RowLevel1,
  RowLevel2,
  RowLevel3,
} from '../HierarchicalPrintTable/HierarchicalPrintTableRow';
import HierarchicalTable from '../HierarchicalPrintTable';

type CashFlowPrintTableProps = {
  settings: Settings;
  className?: string;
  table: AgoyTable;
  tableId: string;
};

const CashFlowStatementRowLevel1 = (props: HierarchicalPrintTableRowProps) => {
  if (props.row.id === 'topDifference') {
    return null;
  }
  if (props.row.id === 'difference') {
    return null;
  }
  return <RowLevel1 {...props} />;
};

const CashFlowStatementRowLevel2 = (props: HierarchicalPrintTableRowProps) => {
  if (
    props.baseId === 'cashFlowStatement.section.main.changeLiquidAssets' &&
    props.row.id === '1'
  ) {
    // "Förändring av likvida medel enligt denna kassaflödesanalys" should be hidden if the calculated difference is zero and red otherwise
    // The solution is to use a MessageCell with a parameter referencing the difference.
    // In print this is always hidden
    const labelCell = props.row.cells?.label;
    if (labelCell?.type === 'msg') {
      const diff = labelCell.parameterValues?.diff;
      if ((typeof diff === 'number' && Math.abs(diff) === 0) || props.print) {
        return null;
      }
    }
  }
  return <RowLevel2 {...props} />;
};

const HideZerosLevel3 = (props: HierarchicalPrintTableRowProps) => {
  const { row } = props;

  if (!rowHaveValues(row)) {
    return null;
  }

  return <RowLevel3 {...props} />;
};

const rowComponents = [
  CashFlowStatementRowLevel1,
  CashFlowStatementRowLevel2,
  HideZerosLevel3,
];

const CashFlowPrintTable = ({
  settings,
  className,
  table,
  tableId,
}: CashFlowPrintTableProps) => {
  const modTable = useMemo((): AgoyTable | undefined => {
    if (table) {
      const newTable: AgoyTable = { ...table };

      if (isSinglePeriodSelected(settings)) {
        newTable.columns = table.columns.filter((col) => col.id !== 'notes');
      }

      if (table) {
        newTable.columns = newTable.columns.filter(
          (col) => col.id !== 'adjustment'
        );
      }

      return newTable;
    }
    return undefined;
  }, [settings, table]);

  if (!modTable) {
    return null;
  }

  return (
    <HierarchicalTable
      className={className}
      table={modTable}
      tableId={tableId}
      rowComponents={rowComponents}
      hideTableHeadLabel
    />
  );
};

export default styled(CashFlowPrintTable)`
  overflow: hidden;

  // label comes first so we restrict the size so that the rest of
  // of the columns doesnt get squished and wraps
  thead tr th:first-of-type {
    width: 65%;
  }

  thead tr th:last-of-type,
  tbody tr td:last-of-type {
    padding-right: ${({ theme }) =>
      theme.spacing(1 / 3)}px; // remember spacing default is 6 in print
  }

  .MuiTableCell-root:nth-of-type(2) {
    text-align: right;
    width: max-content;
  }
  .MuiTableCell-root:nth-of-type(3) {
    text-align: right;
  }
  .MuiTableCell-root:nth-of-type(4) {
    text-align: right;
  }
`;

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useRef } from 'react';

/**
 * Utility hook that avoids concurrent calls.
 *
 * **Note, the callback must not change between calls, use `useCallback` for this argument**
 *
 * Repeated calls are buffers, first by using a delay.
 * After the delay from the first call, all received calls
 * are merged into a single call. The default implementation
 * only uses the last call.
 *
 * Any call during the async callback is executed is buffered and
 * submitted after it the callback finishes.
 *
 * @param fn The callback function, **Important: must be a consistent function, created from useCallback or similar**
 * @param delay The delay in milliseconds before the first call is made
 * @param mergeCalls Function to merge multiple calls into one.
 *                   At least one call will exist when this is called.
 *                   Default is to use the last call
 * @returns
 */
const useSequentialCall = <T extends any[]>(
  fn: (...args: T) => Promise<void>,
  delay = 0,
  mergeCalls: (calls: T[]) => T = (calls) => calls[calls.length - 1]
): ((...args: T) => void) => {
  const busy = useRef(false);
  const calls = useRef<T[]>([]);

  const dispatchCalls = useCallback(async () => {
    if (calls.current.length > 0) {
      // We are busy now, no calls to dispatchCalls will be made until it is done
      busy.current = true;

      setTimeout(async () => {
        try {
          // After the delay we merge all calls so far.
          const call = mergeCalls(calls.current);

          // Clear the calls
          calls.current = [];

          // Execute the real call
          await fn(...call);
        } finally {
          busy.current = false;
        }
        // If there were new calls while we were busy,
        // dispatch those.
        if (calls.current.length > 0) {
          dispatchCalls();
        }
      }, delay);
    }
  }, [delay, fn, mergeCalls]);

  return useCallback(
    (...args: T) => {
      calls.current.push(args);
      if (!busy.current) {
        dispatchCalls();
      }
    },
    [dispatchCalls]
  );
};

export default useSequentialCall;

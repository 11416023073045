import { Field } from '@agoy/document';
import { AnnualReportPart, AnnualReportSection } from '../../document';
import { field } from '../utils/util';

export interface ManualSubmissionSection extends AnnualReportSection {
  text: Field;
}

export interface ManualSubmission extends AnnualReportPart {
  main: ManualSubmissionSection;
}

const cell = { type: 'field' as const };
const boolean = { type: 'boolean' as const };

export const manualSubmission = {
  type: 'part' as const,
  children: {
    active: boolean,
    main: {
      type: 'part' as const,
      children: {
        active: boolean,
        text: cell,
      },
    },
  },
};

export const manualSubmissionConfig = (): ManualSubmission => ({
  active: undefined,
  main: {
    active: undefined,
    text: field(''),
  },
});

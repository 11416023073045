import { endOfMonth } from 'date-fns';
import { format, parse } from '@agoy/dates';
import {
  id,
  account,
  sum,
  or,
  multiply,
  label,
  ref,
  value,
  refs,
  ReferenceAccountInformation,
  abs,
} from '@agoy/document';
import { ReportType, FinancialReportStructure } from '../../types';
import {
  table,
  RowsBuilder,
  range,
  year,
  getPreviousYearPeriod,
  getPreviousYear,
} from '@agoy/annual-report-document';

const accountRangeRows = (
  accounts: Record<string, ReferenceAccountInformation>,
  first: number,
  last: number,
  rows: RowsBuilder,
  previousPeriod: string
): RowsBuilder => {
  range(first, last + 1).forEach((n) => {
    const accountNumber = n.toString();
    const accountInformation = accounts[accountNumber];
    if (accountInformation) {
      rows
        .addRow(
          accountNumber,
          label(`${n} ${accountInformation.accountName}`),
          undefined,
          ref(multiply(-1, account(accountNumber))),
          ref(
            multiply(
              -1,
              or(account(accountNumber, undefined, previousPeriod), 0)
            )
          )
        )
        .setSortKey(n);
    }
  });
  return rows;
};

const addPost =
  (
    accounts: Record<string, ReferenceAccountInformation>,
    rows: RowsBuilder,
    previousPeriod: string
  ) =>
  (
    rowId: string,
    name: string,
    accountNumbers: [number, number][]
  ): RowsBuilder => {
    return rows
      .addRow(
        rowId,
        value(name),
        refs(),
        ref(or(sum(id(`${rows.getBaseId()}.${rowId}.*.year`)), 0)),
        ref(or(sum(id(`${rows.getBaseId()}.${rowId}.*.previousYear`)), 0))
      )
      .newRowTemplateGenerator((id, baseId, { label: labelValue, ib, ub }) => ({
        id,
        active: true,
        cells: {
          label: label(labelValue),
          year: ub ? ref(multiply(-1, account(ub))) : value(0),
          previousYear: ib
            ? ref(multiply(-1, or(account(ib, undefined, previousPeriod), 0)))
            : value(0),
        },
        sortKey: ib ? parseInt(ib) : parseInt(ub),
      }))
      .addSubRows((rows) => {
        accountNumbers.forEach(([first, last]) =>
          accountRangeRows(accounts, first, last, rows, previousPeriod)
        );
        return rows.build();
      });
  };

const addGroup = (
  rows: RowsBuilder,
  rowId: string,
  name: string,
  sumName: string,
  subRowsBuilder: (rows: RowsBuilder) => void
): void => {
  rows
    .addRow(
      rowId,
      value(name),
      refs(),
      undefined,
      undefined,
      ref(id(`${rows.getBaseId()}.${rowId}Sum.hidden`))
    )
    .addSubRows((rows) => {
      subRowsBuilder(rows);
      return rows.build();
    });

  rows.addRow(
    `${rowId}Sum`,
    value(sumName),
    refs(),
    ref(or(sum(id(`${rows.getBaseId()}.${rowId}.*.year`)), 0)),
    ref(or(sum(id(`${rows.getBaseId()}.${rowId}.*.previousYear`)), 0)),
    ref(
      sum(
        or(
          id(`${rows.getBaseId()}.${rowId}.*.notes-0`),
          id(`${rows.getBaseId()}.${rowId}Sum.notes-0`),
          0
        ),
        abs(id(`${rows.getBaseId()}.${rowId}Sum.year`)),
        abs(id(`${rows.getBaseId()}.${rowId}Sum.previousYear`))
      )
    )
  );
};

const addIncomes = (
  accounts: Record<string, ReferenceAccountInformation>,
  rows: RowsBuilder,
  previousPeriod: string
): void => {
  addGroup(
    rows,
    'incomes',
    'Rörelsens intäkter m.m.',
    'Summa rörelsens intäkter m.m.',
    (rows) => {
      const add = addPost(accounts, rows, previousPeriod);
      add('1', 'Nettoomsättning', [[3000, 3799]]);
      add('2', 'Förändring av varulager', [[4900, 4999]]);
      add('3', 'Aktiverat arbete för egen räkning', [[3800, 3899]]);
      add('4', 'Övriga rörelseintäkter', [[3900, 3999]]);
    }
  );
};

const addExpenses = (
  accounts: Record<string, ReferenceAccountInformation>,
  rows: RowsBuilder,
  previousPeriod: string
): void => {
  addGroup(
    rows,
    'expenses',
    'Rörelsens kostnader',
    'Summa rörelsens kostnader',
    (rows) => {
      const add = addPost(accounts, rows, previousPeriod);
      add('1', 'Råvaror och förnödenheter', [[4000, 4899]]);
      add('2', 'Handelsvaror', []);
      add('3', 'Övriga externa kostnader', [[5000, 6999]]);
      add('4', 'Personalkostnader', [[7000, 7699]]);
      add(
        '5',
        'Avskrivningar och nedskrivningar av materiella och immateriella anläggningstillgångar',
        [[7800, 7899]]
      );
      add(
        '6',
        'Nedskrivningar av omsättningstillgångar utöver normala nedskrivningar',
        [[7700, 7799]]
      );
      add('9', 'Övriga rörelsekostnader', [[7900, 7999]]);
    }
  );
};

const addResultsFromFinancial = (
  accounts: Record<string, ReferenceAccountInformation>,
  rows: RowsBuilder,
  previousPeriod: string
): void => {
  addGroup(
    rows,
    'resultsFromFinancial',
    'Resultat från finansiella poster',
    'Summa resultat från finansiella poster',
    (rows) => {
      const add = addPost(accounts, rows, previousPeriod);
      add('1', 'Intäkter från andelar i koncernföretag', [[8000, 8069]]);
      add(
        '2',
        'Intäkter från andelar i intresseföretag och gemensamt styrda företag',
        [
          [8100, 8112],
          [8114, 8117],
          [8119, 8122],
          [8124, 8169],
        ]
      );
      add(
        '3',
        'Intäkter från övriga företag som det finns ett ägarintresse i',
        [
          [8113, 8113],
          [8118, 8118],
          [8123, 8123],
        ]
      );
      add(
        '4',
        'Intäkter från övriga värdepapper och fordringar som är anläggningstillgångar',
        [[8200, 8269]]
      );
      add('5', 'Övriga ränteintäkter och liknande intäkter', [[8300, 8399]]);
      add(
        '6',
        'Nedskrivningar av finansiella anläggningstillgångar och kortfristiga placeringar',
        [
          [8270, 8279],
          [8070, 8099],
          [8170, 8199],
        ]
      );
      add('7', ' Räntekostnader och liknande kostnader', [[8400, 8499]]);
    }
  );
};

const addAppropriations = (
  accounts: Record<string, ReferenceAccountInformation>,
  rows: RowsBuilder,
  previousPeriod: string
): void => {
  addGroup(
    rows,
    'appropriations',
    'Bokslutsdispositioner',
    'Summa bokslutsdispositioner',
    (rows) => {
      const add = addPost(accounts, rows, previousPeriod);
      add('1', 'Erhållna koncernbidrag', [[8820, 8829]]);
      add('2', 'Lämnade koncernbidrag', [[8830, 8839]]);
      add('3', 'Förändring av periodiseringsfonder', [[8800, 8819]]);
      add('4', 'Förändring av överavskrivningar', [[8850, 8859]]);
      add('5', 'Övriga bokslutsdispositioner', [[8860, 8899]]);
    }
  );
};

const addTaxes = (
  accounts: Record<string, ReferenceAccountInformation>,
  rows: RowsBuilder,
  previousPeriod: string
): void => {
  addGroup(rows, 'taxes', 'Skatter', 'Summa skatter', (rows) => {
    const add = addPost(accounts, rows, previousPeriod);
    add('1', 'Skatt på årets resultat', [[8900, 8919]]);
    add('2', 'Övriga skatter', [[8920, 8989]]);
  });
};

export const incomeStatementConfig = (
  accounts: Record<string, ReferenceAccountInformation>,
  financialYear: string,
  financialYears: string[],
  period: string | null,
  type: ReportType
): FinancialReportStructure['incomeStatementTables'] => {
  const previousYearPeriod = getPreviousYearPeriod(
    period,
    getPreviousYear(financialYear, financialYears) || ''
  );
  const previousYearKey = previousYearPeriod
    ? format(parse(previousYearPeriod, 'yyyyMM'), 'MMMyy')
    : '';

  return {
    active: undefined,
    section: {
      active: undefined,
      table: table(
        'incomeStatement.section.table',
        'label',
        'notes',
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        previousYearPeriod
          ? {
              id: 'previousYear',
              label: format(
                endOfMonth(parse(previousYearPeriod, 'yyyyMM')),
                'yyyy-MM-dd'
              ),
            }
          : undefined,
        'hidden'
      )
        .addRows((rows) =>
          rows
            .addRow(
              'income',
              type === 'k3'
                ? value('Resultaträkning')
                : label('Resultaträkning')
            )
            .addSubRows((rows) => {
              rows.addRow('main').addSubRows((rows) => {
                addIncomes(accounts, rows, previousYearKey);
                addExpenses(accounts, rows, previousYearKey);

                rows.addRow(
                  'sumOperatingProfit',
                  value('Rörelseresultat'),
                  refs(),
                  ref(
                    sum(
                      id(`${rows.getBaseId()}.incomesSum.year`),
                      id(`${rows.getBaseId()}.expensesSum.year`)
                    )
                  ),
                  ref(
                    sum(
                      id(`${rows.getBaseId()}.incomesSum.previousYear`),
                      id(`${rows.getBaseId()}.expensesSum.previousYear`)
                    )
                  )
                );
                return rows.build();
              });
              return rows.build();
            })
            .build()
        )
        .build(),
      secondTable: table(
        'incomeStatement.section.secondTable',
        'label',
        'notes',
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        previousYearPeriod
          ? {
              id: 'previousYear',
              label: format(
                endOfMonth(parse(previousYearPeriod, 'yyyyMM')),
                'yyyy-MM-dd'
              ),
            }
          : undefined,
        'hidden'
      )
        .addRows((rows) =>
          rows
            .addRow(
              'income',
              type === 'k3'
                ? value('Resultaträkning')
                : label('Resultaträkning')
            )
            .addSubRows((rows) => {
              rows.addRow('main').addSubRows((rows) => {
                addResultsFromFinancial(accounts, rows, previousYearKey);

                rows.addRow(
                  'resultAfterFinancial',
                  value('Resultat efter finansiella poster'),
                  refs(),
                  ref(
                    sum(
                      id(
                        `incomeStatement.section.table.income.main.sumOperatingProfit.year`
                      ),
                      id(`${rows.getBaseId()}.resultsFromFinancialSum.year`)
                    )
                  ),
                  ref(
                    sum(
                      id(
                        `incomeStatement.section.table.income.main.sumOperatingProfit.previousYear`
                      ),
                      id(
                        `${rows.getBaseId()}.resultsFromFinancialSum.previousYear`
                      )
                    )
                  )
                );

                addAppropriations(accounts, rows, previousYearKey);

                rows.addRow(
                  'resultBeforeTaxes',
                  value('Resultat före skatt'),
                  refs(),
                  ref(
                    sum(
                      id(`${rows.getBaseId()}.resultAfterFinancial.year`),
                      id(`${rows.getBaseId()}.appropriationsSum.year`)
                    )
                  ),
                  ref(
                    sum(
                      id(
                        `${rows.getBaseId()}.resultAfterFinancial.previousYear`
                      ),
                      id(`${rows.getBaseId()}.appropriationsSum.previousYear`)
                    )
                  )
                );

                addTaxes(accounts, rows, previousYearKey);

                rows.addRow(
                  'result',
                  value('Årets resultat'),
                  refs(),
                  ref(or(sum(account('8990:8999')), 0)),
                  ref(
                    or(sum(account('8990:8999', undefined, previousYearKey)), 0)
                  )
                );
                return rows.build();
              });
              return rows.build();
            })
            .build()
        )
        .build(),
      periodTable: table(
        'incomeStatement.section.periodTable',
        'label',
        'notes',
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        previousYearPeriod
          ? {
              id: 'previousYear',
              label: format(
                endOfMonth(parse(previousYearPeriod, 'yyyyMM')),
                'yyyy-MM-dd'
              ),
            }
          : undefined,
        'hidden'
      ).build(),
      secondPeriodTable: table(
        'incomeStatement.section.secondPeriodTable',
        'label',
        'notes',
        {
          id: 'year',
          label: year(financialYear, financialYears, period) || '',
        },
        previousYearPeriod
          ? {
              id: 'previousYear',
              label: format(
                endOfMonth(parse(previousYearPeriod, 'yyyyMM')),
                'yyyy-MM-dd'
              ),
            }
          : undefined,
        'hidden'
      ).build(),
    },
  };
};

export const incomeStatementReferences = (): Record<string, string> => ({
  'incomeStatement.netSales.year': id(
    'incomeStatement.section.table.income.main.incomes.1.year'
  ),
  'incomeStatement.netSales.previousYear': id(
    'incomeStatement.section.table.income.main.incomes.1.previousYear'
  ),
  'incomeStatement.resultAfterFinancial.year': id(
    'incomeStatement.section.table.income.main.resultAfterFinancial.year'
  ),
  'incomeStatement.resultAfterFinancial.previousYear': id(
    'incomeStatement.section.table.income.main.resultAfterFinancial.previousYear'
  ),
});

import React, { useState, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';

import { useSelector } from 'redux/reducers';
import {
  addGlobalErrorMessage,
  addGlobalMessage,
} from '_messages/redux/actions';
import { ReportSupplierData } from '_reconciliation/types';
import { useAccountDocuments } from 'utils/usePeriodDocuments';
import Button from '_shared/components/Buttons/Button';
import PeriodDataContext from '../PeriodDataContext';
import { SaldoHistoryContext } from '../../HiddenRowHistoryProvider';
import { usePrintState } from '../../utils';

interface PrintButtonProps {
  account: string;
  data: ReportSupplierData;
  hasSpecifications: boolean;
}

const PrintButton = ({
  account,
  data,
  hasSpecifications,
}: PrintButtonProps) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const printState = usePrintState();

  const { onSaldoChange } = useContext(SaldoHistoryContext);
  const { period, clientId } = useContext(PeriodDataContext);

  const [loading, setLoading] = useState(false);

  const customer = useSelector((state) => state.customers[clientId]);

  const { createAndAddDocument } = useAccountDocuments();

  const handlePrint = async () => {
    setLoading(true);

    try {
      const docName = `${formatMessage({
        id: 'hidden.supplierInvoices.documentNameBase',
      })} ${customer.name} ${period.end}.pdf`;

      await createAndAddDocument({
        name: docName,
        docType: 'accounting',
        footer: '',
        header: '',
        parts: ['supplierInvoices'],
        printState,
      });

      if (!hasSpecifications) {
        // Note: we calculate the totalSumSEK without the sign (that's the way how its done in accounting)
        // but the UB of account 2440 is negative (since its a debt). Therefore we need to adjust here.
        // Note: for account 1510, its not needed to invert the sign.
        onSaldoChange(account, period, -data.totalSumSEK);
        dispatch(
          addGlobalMessage(
            'success',
            'hidden.supplierInvoices.saveToPeriodDocuments.success'
          )
        );
      } else {
        dispatch(
          addGlobalMessage(
            'warning',
            'hidden.invoices.saveToPeriodDocuments.warning'
          )
        );
      }
    } catch (e) {
      dispatch(addGlobalErrorMessage('error'));
    }

    setLoading(false);
  };

  return (
    <Button
      label={formatMessage({
        id: 'hidden.supplierInvoices.saveToPeriodDocuments',
      })}
      loading={loading}
      onClick={handlePrint}
      size="medium"
      variant="outlined"
    />
  );
};

export default PrintButton;

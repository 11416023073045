import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';
import { PrivatePerson } from '_person/_types/person';
import {
  addGlobalErrorMessage,
  addGlobalMessage,
} from '_messages/redux/actions';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { orderBy } from 'lodash';
import { deletePerson, getPersons } from '_person/redux/persons/actions';
import { useSelector } from 'redux/reducers';
import { useIntl } from 'react-intl';
import {
  getOrganisationMembers,
  toggleMemberAuthorisationForPerson,
} from '_organization/redux/actions';
import PersonAvatar from './_organisms/PersonAvatar';
import ModalEditPerson from './_organisms/ModalEditPerson';
import SearchField from './_organisms/SearchField';

const Wrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

const StyledSkeleton = styled(Skeleton)`
  margin-top: ${(props) => props.theme.spacing(2)}px;
`;

const LoadingSkeleton = () => (
  <StyledSkeleton variant="rect" animation="wave" width="100%" height="40px" />
);

const PersonsListView = (): JSX.Element => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const [filteredPersons, setFilteredPersons] = useState<PrivatePerson[]>([]);
  const [selectedPerson, setSelectedPerson] = useState<PrivatePerson | null>(
    null
  );
  const [search, setSearch] = useState('');

  const members = useSelector((state) => state.organisation.users);
  const persons = useSelector((state) => state.persons || {});
  const isFetching = useSelector((state) => state.ui.fetchingPersons);
  const isAdmin =
    useSelector((state) => state.user.roles)?.includes('OrganisationAdmin') ||
    false;

  const isAuthorised = (member: Member.MemberType) => {
    const authorisedPerson = member.authorisedPersons?.find(
      (authorized) => authorized.id === selectedPerson?.id
    );

    return authorisedPerson != null;
  };

  const handleDeletePerson = async () => {
    if (selectedPerson?.id == null) return;

    try {
      await dispatch(deletePerson(selectedPerson.id));
      dispatch(addGlobalMessage('success', 'person.deleted.success'));
      setSelectedPerson(null);
    } catch (error) {
      dispatch(addGlobalErrorMessage('error', 'person.delete.error'));
    }
  };

  useEffect(() => {
    dispatch(getPersons());
    dispatch(getOrganisationMembers(true));
  }, [dispatch]);

  useEffect(() => {
    const filteredListIds = Object.keys(persons).filter(
      (id) =>
        persons[id].firstName.toLowerCase().includes(search.toLowerCase()) ||
        persons[id].lastName.toLowerCase().includes(search.toLowerCase())
    );

    const filteredList = filteredListIds.map((key) => persons[key]);
    const orderedPersons = orderBy(
      filteredList,
      ['firstName', 'lastName'],
      'asc'
    );

    setFilteredPersons(orderedPersons);
  }, [persons, search]);

  const handleListItemClicked = (person: PrivatePerson) => {
    setSelectedPerson(person);
  };

  const handleToggleMemberAuthorisation = async (member: Member.MemberType) => {
    if (selectedPerson?.id == null) return;

    if (!isAdmin) {
      const id = 'dashboard.members.authorisation.notAdmin';
      dispatch(addGlobalErrorMessage(formatMessage({ id })));
      return;
    }

    dispatch(
      toggleMemberAuthorisationForPerson(
        member,
        selectedPerson.id,
        isAuthorised(member)
      )
    );
  };

  return (
    <Wrapper>
      <ModalEditPerson
        isOpen={selectedPerson != null}
        person={selectedPerson}
        members={members}
        isAdmin={isAdmin}
        onClose={() => setSelectedPerson(null)}
        onDelete={handleDeletePerson}
        onToggle={handleToggleMemberAuthorisation}
      />
      <SearchField
        placeholder={formatMessage({ id: 'person.search.placeholder' })}
        fullWidth
        onChange={(newValue: string) => setSearch(newValue)}
      />
      {isFetching && filteredPersons.length === 0 && <LoadingSkeleton />}
      <List>
        {filteredPersons.map((person) => (
          <ListItem
            key={person.id}
            button
            onClick={() => handleListItemClicked(person)}
          >
            <ListItemIcon>
              <PersonAvatar small personNumber={person.personNumber} />
            </ListItemIcon>
            <ListItemText>
              <Typography>{`${person.firstName} ${person.lastName}`}</Typography>
            </ListItemText>
          </ListItem>
        ))}
      </List>
    </Wrapper>
  );
};

export default PersonsListView;

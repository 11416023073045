import { FinancialReport, contentDefinition } from './types';
import {
  collectReferences,
  collectValues,
  expandIds,
  resolveReference,
  ResolveReferenceContext,
  ResolveReferenceInput,
  ResolvedReference,
} from '@agoy/document';
import updateReportValues from './updateReportValues';

const collectBokslutReportValues = collectValues(contentDefinition);
const collectBokslutReportReferences = collectReferences(contentDefinition);

export const resolveById =
  (
    calculatedReferences: Record<string, ResolvedReference>,
    references: Record<string, string>,
    values: Record<string, ResolvedReference>
  ) =>
  (id, context): ResolvedReference => {
    if (id in values) {
      return values[id];
    }
    const ref = references[id];

    if (ref) {
      if (calculatedReferences[ref] !== undefined) {
        return calculatedReferences[ref];
      }
      const refValue = resolveReference(ref, context);
      calculatedReferences[ref] = refValue;
      return refValue;
    }
    return { error: 'missingId' };
  };

export const calculateReferences = (
  data: FinancialReport,
  baseReferences: Record<string, string>,
  input: ResolveReferenceInput,
  referencesToCalculate?: Record<string, string>
): Record<string, ResolvedReference> => {
  const values: Record<string, ResolvedReference> =
    collectBokslutReportValues(data);
  const references = collectBokslutReportReferences(data, baseReferences);
  const allIds = [
    ...new Set([...Object.keys(values), ...Object.keys(references)]),
  ];

  const calculatedReferences: Record<string, ResolvedReference> = {};

  const context: ResolveReferenceContext = {
    resolveById: resolveById(calculatedReferences, references, values),
    resolveConfig: (name, context) => undefined,
    expandIds: expandIds(allIds),
    input,
  };

  Object.entries(referencesToCalculate || references).forEach(
    ([id, reference]) => {
      if (calculatedReferences[reference] === undefined) {
        values[id] = calculatedReferences[reference] = resolveReference(
          reference,
          context
        );
      }
    }
  );

  return calculatedReferences;
};

/**
 * Extracts the ids of referenced notes, the references must be "id(notes.[the note].number)"
 */
export const extractReferencedNotes = (
  references: Record<string, ResolvedReference>
): string[] => {
  const all = Object.keys(references)
    .filter((ref) => ref.startsWith('id(notes.'))
    .filter((ref) => ref.endsWith('.number)'))
    .map((ref) => {
      return ref.substring(3, ref.length - 8);
    })
    .sort();

  return all;
};

/**
 * resolveFinancialReport calculates all references and updates the report.
 *
 * It also extracts the ids of all referenced notes
 */
export const resolveFinancialReport = (
  report: FinancialReport,
  baseReferences: Record<string, string>,
  input: ResolveReferenceInput
): [FinancialReport] => {
  const references = calculateReferences(report, baseReferences, input);
  return [updateReportValues(report, references)];
};

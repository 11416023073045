import {
  SET_USER_LOGGED_IN,
  SET_USER_ATTRIBUTES,
  SET_USER_LOGGED_OUT,
  USER_IS_AUTHENTICATING,
  USER_NOT_AUTHENTICATING,
  SET_USER_FIRST_LOGIN,
} from './action-types';
import { UserType } from '../types';

const initialUserState = {
  loggedIn: false,
  isAuthenticating: true,
  organisationId: undefined,
  firstLogin: false,
  supportUser: false,
};

export default (state: UserType = initialUserState, action): UserType => {
  switch (action.type) {
    case SET_USER_LOGGED_IN:
      return {
        ...state,
        loggedIn: true,
        isAuthenticating: false,
      };
    case SET_USER_ATTRIBUTES:
      return {
        ...state,
        ...action.userAttributes,
      };
    case SET_USER_LOGGED_OUT:
      return {
        ...initialUserState,
        isAuthenticating: false,
      };
    case USER_IS_AUTHENTICATING:
      return {
        ...state,
        isAuthenticating: true,
      };
    case USER_NOT_AUTHENTICATING:
      return {
        ...state,
        isAuthenticating: false,
      };
    case SET_USER_FIRST_LOGIN:
      return {
        ...state,
        firstLogin: true,
      };

    default:
      return state;
  }
};

export class UserError extends Error {
  messageId: string;

  messageParams?: Record<string, string>;

  constructor(messageId: string, messageParams?: Record<string, string>) {
    super(messageId);
    this.messageId = messageId;
    this.messageParams = messageParams;
  }
}

export const isUserError = (err: Error): err is UserError =>
  'messageId' in err && 'messageParams' in err;

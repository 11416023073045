import React, { useEffect, useMemo } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { booleanValue } from '@agoy/document';
import { Settings } from '@agoy/annual-report-document';

import useAnnualReport from '_annual-report/components/AnnualReportView/useAnnualReport';
import useAnnualReportChanges from '_annual-report/components/AnnualReportView/useAnnualReportChanges';
import {
  currentCustomer,
  currentPeriod,
} from '_clients/redux/customer-view/selectors';

import { useSelector } from '../../../redux/reducers';

import { currentFinancialState } from '../../redux/selectors';
import {
  getIncomeStatementTableByVersion,
  getIncomeStatementTableFromReport,
  getReportColors,
  numberOfTableRows,
} from '../../utils';

import Page from './UI/Print/Page';
import FrontPagePreview from './Parts/FrontPageView/FrontPagePreview';
import TableOfContentsPreview from './Parts/TableOfContents/TableOfContentsPreview';
import RRBRDiagramPreview from './Parts/RRBRDiagramPage/RRBRDiagramPreview';
import CashFlowStatementPreview from './Parts/CashFlowStatement/CashFlowStatementPreview';
import RRBRTablePreview from './Parts/RRBRTablePage/RRBRTablePreview';
import DocumentsViewPreview from './Parts/DocumentsView/DocumentsViewPreview';
import AdditionalText from './Parts/AdditionalText';
import AdditionalTextPreview from './Parts/AdditionalText/AdditinalTextPreview';
import BalanceSheet from './Parts/BalanceSheet';
import IncomeStatement from './Parts/IncomeStatement';

interface FinancialReportPrintProps {
  pageNumbers: Record<string, number>;
  onLoaded?: () => void;
}

const FinancialReportPrint = ({
  pageNumbers,
  onLoaded = () => {},
}: FinancialReportPrintProps): JSX.Element | null => {
  const { path } = useRouteMatch();
  const annualReport = useAnnualReport();
  const annualReportChanges = useAnnualReportChanges();

  const period = useSelector(currentPeriod);
  const client = useSelector(currentCustomer);
  const companyType = client?.type;

  const financialState = useSelector(currentFinancialState);
  const financialReport = financialState?.report;
  const reportColors = getReportColors(financialReport?.settings);

  const annualReportIncomeStatementTable =
    getIncomeStatementTableFromReport(annualReport);

  const incomeStatementTable = getIncomeStatementTableByVersion(
    annualReportIncomeStatementTable,
    annualReport?.version
  );

  const financialReportIncomeStatementTable =
    getIncomeStatementTableFromReport(financialReport);

  const incomeStatementPeriodTable = getIncomeStatementTableByVersion(
    financialReportIncomeStatementTable,
    annualReport?.version
  );

  const shouldShowZerosInTables = annualReport?.version === '2';

  const splitIncomeStatementPage = useMemo(() => {
    if (incomeStatementTable) {
      return (
        numberOfTableRows(incomeStatementTable, shouldShowZerosInTables) > 0
      );
    }
    return false;
  }, [incomeStatementTable, shouldShowZerosInTables]);

  const splitBalanceSheetPage = useMemo(() => {
    if (financialReport && 'assets' in financialReport.balanceSheet.section) {
      return (
        numberOfTableRows(
          financialReport.balanceSheet.section.assets,
          shouldShowZerosInTables
        ) > 0
      );
    }
    return false;
  }, [financialReport, shouldShowZerosInTables]);

  const splitIncomeStatementPeriodPage = useMemo(() => {
    if (incomeStatementPeriodTable) {
      return (
        numberOfTableRows(incomeStatementPeriodTable, shouldShowZerosInTables) >
        0
      );
    }
    return false;
  }, [incomeStatementPeriodTable, shouldShowZerosInTables]);

  useEffect(() => {
    if (!Object.keys(pageNumbers).includes('documents')) {
      onLoaded();
    }
  }, [onLoaded, pageNumbers]);

  if (
    !financialReport ||
    !financialState ||
    !annualReport ||
    !incomeStatementPeriodTable ||
    !incomeStatementTable ||
    !period ||
    !companyType
  ) {
    return null;
  }

  const {
    cashFlowDiagram,
    balanceSheet,
    frontPage,
    diagramRRBR,
    tableRRBR,
    settings,
    documents,
  } = financialReport;

  return (
    <Switch>
      <Route path={`${path}/index`}>
        <FrontPagePreview frontPage={frontPage} settings={settings} />
      </Route>
      <Route path={`${path}/tableOfContents`}>
        <Page>
          <TableOfContentsPreview
            financialState={financialState}
            reportColors={reportColors}
            splitIncomeStatementPage={splitIncomeStatementPage}
            splitIncomeStatementPeriodPage={splitIncomeStatementPeriodPage}
            splitBalanceSheetPage={splitBalanceSheetPage}
            companyType={companyType}
            period={period}
          />
        </Page>
      </Route>
      <Route path={`${path}/additionalTextOne`} exact>
        <Page>
          <AdditionalText
            additionalText={financialReport?.additionalText}
            reportColors={reportColors}
            pageId="page1"
          />
        </Page>
      </Route>
      <Route path={`${path}/additionalTextTwo`} exact>
        <Page>
          <AdditionalText
            additionalText={financialReport?.additionalText}
            reportColors={reportColors}
            pageId="page2"
          />
        </Page>
      </Route>
      <Route path={`${path}/diagramRRBR`}>
        <Page>
          <RRBRDiagramPreview
            diagramRRBR={diagramRRBR}
            reportColors={reportColors}
            settings={settings}
            incomeStatement={annualReport.incomeStatement}
            annualReportChanges={annualReportChanges}
          />
        </Page>
      </Route>
      {annualReport && (
        <Route path={`${path}/tableRRBR`}>
          <Page>
            <RRBRTablePreview
              tableRRBR={tableRRBR}
              reportColors={reportColors}
              settings={settings}
              isTextRandomizationEnabled={booleanValue(
                financialReport.settings.section.isTextRandomizationEnabled
              )}
              incomeStatement={annualReport.incomeStatement}
              annualReportChanges={annualReportChanges}
            />
          </Page>
        </Route>
      )}
      <Route path={`${path}/cashFlowStatement`}>
        <Page>
          <CashFlowStatementPreview
            cashFlowDiagram={cashFlowDiagram}
            reportColors={reportColors}
            settings={settings}
          />
        </Page>
      </Route>
      <Route path={`${path}/balanceSheet`}>
        <Page>
          <BalanceSheet
            balanceSheet={annualReport.balanceSheet}
            reportColors={reportColors}
            /* TODO: Separate the settings in financialReport from annualReport */
            settings={settings as unknown as Settings}
            shouldSplitTable={false}
            shouldShowZeros={shouldShowZerosInTables}
            print
          />
        </Page>
      </Route>
      <Route path={`${path}/balance-sheet-assets-part1`}>
        <Page>
          <BalanceSheet
            balanceSheet={balanceSheet}
            reportColors={reportColors}
            settings={settings as unknown as Settings}
            shouldSplitTable={splitBalanceSheetPage}
            shouldShowZeros={shouldShowZerosInTables}
            part="part1"
            print
          />
        </Page>
      </Route>
      <Route path={`${path}/balance-sheet-assets-part2`}>
        <Page>
          <BalanceSheet
            balanceSheet={balanceSheet}
            reportColors={reportColors}
            settings={settings as unknown as Settings}
            shouldSplitTable={splitBalanceSheetPage}
            shouldShowZeros={shouldShowZerosInTables}
            part="part2"
            print
          />
        </Page>
      </Route>
      <Route path={`${path}/income-statement-period-part1`}>
        <Page>
          <IncomeStatement
            incomeStatement={incomeStatementPeriodTable}
            reportColors={reportColors}
            settings={settings}
            shouldSplitTable={splitIncomeStatementPage}
            shouldShowZeros={shouldShowZerosInTables}
            print
            part="part1"
          />
        </Page>
      </Route>
      <Route path={`${path}/income-statement-period-part2`}>
        <Page>
          <IncomeStatement
            incomeStatement={incomeStatementPeriodTable}
            reportColors={reportColors}
            settings={settings}
            shouldSplitTable={splitIncomeStatementPage}
            shouldShowZeros={shouldShowZerosInTables}
            isPeriod
            print
            part="part2"
          />
        </Page>
      </Route>
      {annualReport && (
        <Route path={`${path}/income-statement-part1`}>
          <Page>
            <IncomeStatement
              incomeStatement={incomeStatementTable}
              reportColors={reportColors}
              settings={settings}
              shouldSplitTable={splitIncomeStatementPeriodPage}
              shouldShowZeros={shouldShowZerosInTables}
              print
              part="part1"
            />
          </Page>
        </Route>
      )}
      {annualReport && (
        <Route path={`${path}/income-statement-part2`}>
          <Page>
            <IncomeStatement
              incomeStatement={incomeStatementTable}
              reportColors={reportColors}
              settings={settings}
              shouldSplitTable={splitIncomeStatementPeriodPage}
              shouldShowZeros={shouldShowZerosInTables}
              print
              part="part2"
            />
          </Page>
        </Route>
      )}
      <Route path={`${path}/documents`}>
        <Page>
          <DocumentsViewPreview
            documentsData={documents}
            documents={financialState.documents}
            reportColors={reportColors}
            onDocumentsReady={onLoaded}
          />
        </Page>
      </Route>
      <Route path={path} exact>
        <FrontPagePreview frontPage={frontPage} settings={settings} />
        {'tableOfContents' in pageNumbers && (
          <Page>
            <TableOfContentsPreview
              financialState={financialState}
              reportColors={reportColors}
              splitIncomeStatementPage={splitIncomeStatementPage}
              splitIncomeStatementPeriodPage={splitIncomeStatementPeriodPage}
              splitBalanceSheetPage={splitBalanceSheetPage}
              companyType={companyType}
              period={period}
            />
          </Page>
        )}
        {'additionalTextOne' in pageNumbers && (
          <Page>
            <AdditionalTextPreview
              additionalText={financialReport?.additionalText}
              reportColors={reportColors}
              pageId="page1"
            />
          </Page>
        )}
        {'diagramRRBR' in pageNumbers && (
          <Page>
            <RRBRDiagramPreview
              diagramRRBR={diagramRRBR}
              reportColors={reportColors}
              settings={settings}
              incomeStatement={annualReport.incomeStatement}
              annualReportChanges={annualReportChanges}
            />
          </Page>
        )}
        {'tableRRBR' in pageNumbers && annualReport && (
          <Page>
            <RRBRTablePreview
              tableRRBR={tableRRBR}
              reportColors={reportColors}
              settings={settings}
              isTextRandomizationEnabled={booleanValue(
                financialReport.settings.section.isTextRandomizationEnabled
              )}
              incomeStatement={annualReport.incomeStatement}
              annualReportChanges={annualReportChanges}
            />
          </Page>
        )}
        {'cashFlowDiagram' in pageNumbers && (
          <Page>
            <CashFlowStatementPreview
              cashFlowDiagram={cashFlowDiagram}
              reportColors={reportColors}
              settings={settings}
            />
          </Page>
        )}
        {'income-statement-period-part1' in pageNumbers && (
          <Page>
            <IncomeStatement
              incomeStatement={incomeStatementPeriodTable}
              reportColors={reportColors}
              settings={settings}
              shouldSplitTable={splitIncomeStatementPeriodPage}
              shouldShowZeros={shouldShowZerosInTables}
              isPeriod
              print
              part="part1"
            />
          </Page>
        )}
        {'income-statement-period-part2' in pageNumbers && (
          <Page>
            <IncomeStatement
              incomeStatement={incomeStatementPeriodTable}
              reportColors={reportColors}
              settings={settings}
              shouldSplitTable={splitIncomeStatementPeriodPage}
              shouldShowZeros={shouldShowZerosInTables}
              isPeriod
              print
              part="part2"
            />
          </Page>
        )}
        {'income-statement-part1' in pageNumbers && annualReport && (
          <Page>
            <IncomeStatement
              incomeStatement={incomeStatementTable}
              reportColors={reportColors}
              settings={settings}
              shouldSplitTable={splitIncomeStatementPage}
              shouldShowZeros={shouldShowZerosInTables}
              print
              part="part1"
            />
          </Page>
        )}
        {'income-statement-part2' in pageNumbers && annualReport && (
          <Page>
            <IncomeStatement
              incomeStatement={incomeStatementTable}
              reportColors={reportColors}
              settings={settings}
              shouldSplitTable={splitIncomeStatementPage}
              shouldShowZeros={shouldShowZerosInTables}
              print
              part="part2"
            />
          </Page>
        )}

        {'balance-sheet-assets-part1' in pageNumbers && (
          <Page>
            <BalanceSheet
              balanceSheet={annualReport.balanceSheet}
              reportColors={reportColors}
              /* TODO: Separate the settings in financialReport from annualReport */
              settings={settings as unknown as Settings}
              shouldSplitTable={splitBalanceSheetPage}
              part="part1"
              shouldShowZeros={shouldShowZerosInTables}
              print
            />
          </Page>
        )}
        {'balance-sheet-assets-part2' in pageNumbers && (
          <Page>
            <BalanceSheet
              balanceSheet={annualReport.balanceSheet}
              reportColors={reportColors}
              /* TODO: Separate the settings in financialReport from annualReport */
              settings={settings as unknown as Settings}
              shouldSplitTable={splitBalanceSheetPage}
              part="part2"
              shouldShowZeros={shouldShowZerosInTables}
              print
            />
          </Page>
        )}
        {'balanceSheet' in pageNumbers && (
          <Page>
            <BalanceSheet
              balanceSheet={annualReport.balanceSheet}
              reportColors={reportColors}
              settings={settings as unknown as Settings}
              shouldSplitTable={false}
              shouldShowZeros={shouldShowZerosInTables}
              print
            />
          </Page>
        )}
        {'additionalTextTwo' in pageNumbers && (
          <Page>
            <AdditionalTextPreview
              additionalText={financialReport?.additionalText}
              reportColors={reportColors}
              pageId="page2"
            />
          </Page>
        )}
        {'documents' in pageNumbers && (
          <Page>
            <DocumentsViewPreview
              documentsData={documents}
              documents={financialState.documents}
              reportColors={reportColors}
              onDocumentsReady={onLoaded}
            />
          </Page>
        )}
      </Route>
    </Switch>
  );
};

export default FinancialReportPrint;

import React from 'react';
import styled from '@emotion/styled';
import { CustomNotesSection } from '@agoy/annual-report-document';
import { numberValue, stringValue } from '@agoy/document';
import { useIntl } from 'react-intl';
import Heading from '../../../UI/Heading';
import PrintCustomNoteComment from './PrintCustomNoteComment';
import PrintCustomThreeColumnTable from './PrintCustomThreeColumnTable';
import PrintCustomSixColumnTable from './PrintCustomSixColumnTable';
import { Note } from '../UI/Note';

const Spacer = styled.div`
  height: ${({ theme }) => theme.spacing(4)}px;
`;

interface CustomNoteProps {
  note: CustomNotesSection;
  noteId: string;
  hasPreviousYear?: boolean;
}

const PrintCustomNote = ({
  note,
  noteId,
  hasPreviousYear = false,
}: CustomNoteProps): JSX.Element | null => {
  const { formatMessage } = useIntl();

  const noteNumber = numberValue(note.number) || '';

  const noteTitle =
    stringValue(note.name) ||
    formatMessage({ id: 'annualReport.menu.notes.custom.addSection' });

  // If the CustomNote does not have a number assigned to it
  // the preview and print should not display it
  if (!noteNumber) {
    return null;
  }

  return (
    <Note id={noteId}>
      <Heading title={`${noteNumber} ${noteTitle}`} />

      <PrintCustomNoteComment
        value={stringValue(note.typeOne) || ''}
        title={stringValue(note.commentTitle) || ''}
      />

      <Spacer />

      <PrintCustomThreeColumnTable
        hasPreviousYear={hasPreviousYear}
        table={note.typeTwo}
        tableId={`notes.${noteId}.typeTwo`}
      />

      <Spacer />

      <PrintCustomSixColumnTable
        hasPreviousYear={hasPreviousYear}
        table={note.typeThree}
        tableId={`notes.${noteId}.typeThree`}
      />
    </Note>
  );
};

export default PrintCustomNote;

import React from 'react';
import { useIntl } from 'react-intl';
import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';
import { ListAltOutlined } from '@material-ui/icons';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  column-gap: 12px;
  cursor: pointer;
`;

interface VoucherListFilterProps {
  onFilterClick: () => void;
}

const VoucherListFilter = ({ onFilterClick }: VoucherListFilterProps) => {
  const { formatMessage } = useIntl();

  return (
    <Container onClick={onFilterClick}>
      <ListAltOutlined />
      <Typography>
        {formatMessage({
          id: 'reconciliation.speedDial.voucherList.filter',
        })}
      </Typography>
    </Container>
  );
};

export default VoucherListFilter;

import React from 'react';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';
import { addGlobalMessage } from '_messages/redux/actions';
import LoadingLogo from '_shared/components/LoadingLogo';
import ReceiptIcon from '@material-ui/icons/Receipt';
import { InvoiceAttachmentResponse } from '_reconciliation/types';
import IconButton from '@material-ui/core/IconButton/IconButton';
import { useApiSdk } from 'api-sdk';

interface Props {
  clientId: string;
  invoiceId: number;
  onFetchDone: (invoice: InvoiceAttachmentResponse) => void;
}

const Wrapper = styled.div`
  min-width: 40px;
`;

const InvoicePreviewButton = ({
  clientId,
  invoiceId,
  onFetchDone,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const sdk = useApiSdk();

  const [isLoading, setIsLoading] = React.useState(false);

  const fetchAttachment = async () => {
    try {
      setIsLoading(true);
      const file = await sdk.getSupplierInvoiceAttachment({
        clientid: clientId,
        invoiceid: invoiceId.toString(),
      });
      onFetchDone(file);
    } catch (err) {
      dispatch(
        addGlobalMessage('error', 'hidden.invoices.InvoiceAttachmentView.Error')
      );
    }
    setIsLoading(false);
  };

  return (
    <Wrapper>
      <IconButton onClick={fetchAttachment} size="small">
        {isLoading ? (
          <LoadingLogo />
        ) : (
          <ReceiptIcon fontSize="small" color="primary" />
        )}
      </IconButton>
    </Wrapper>
  );
};

export default InvoicePreviewButton;

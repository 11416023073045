import { value, ref, label, or, sum, id } from '@agoy/document';
import { NoteOneTableWithSum, IxbrlCell } from '../../../../common';
import {
  sumAccountsIBs,
  sumNegativeTransactions,
} from '../../../../common/utils/accountSumsUtil';
import { year, previousYear } from '../../../../common/utils/date-util';
import {
  field,
  ixbrlCell,
  ixbrlField,
  table,
} from '../../../../common/utils/util';
import { NoteAccountRanges } from '../types';

const MONETARY_ITEM_TYPE = 'monetaryItemType';

export const note26Accounts: NoteAccountRanges[] = [
  {
    rowId: '1',
    ranges: [[2085]],
    cellLabel: 'Belopp vid årets ingång',
    action: 'sumAccountsIBs',
  },
  {
    rowId: '2',
    ranges: [[2085]],
    cellLabel: 'Avsättning till fonden under räkenskapsåret',
    action: 'sumNegativeTransactions',
  },
];

export const getNote26 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null
): NoteOneTableWithSum => {
  return {
    active: false,
    number: value(undefined),
    name: value('Uppskrivningsfond'),
    table: table<IxbrlCell>(
      'notes.note26.table',
      { id: 'label', label: '' },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      },
      'hidden'
    )
      .addRows((rows) =>
        rows
          // 'ingång' = previous period outgoing balance
          .addRow(
            '1',
            noteLabel('Belopp vid årets ingång'),
            ixbrlCell(ref(sumAccountsIBs('2085', true)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:Uppskrivningsfond',
              contextRef: 'balans1',
              saldo: 'credit',
            }),
            ixbrlCell(ref(sumAccountsIBs('2085', true, 'previousYear')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:Uppskrivningsfond',
              contextRef: 'balans2',
              saldo: 'credit',
            })
          )
          .addRow(
            '2',
            noteLabel('Avsättning till fonden under räkenskapsåret'),
            ixbrlCell(ref(sumNegativeTransactions('2085')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForandringAvsattningFondUnderRakenskapsaret',
              contextRef: 'period0',
              saldo: 'credit',
            }),
            ixbrlCell(ref(sumNegativeTransactions('2085', 'previousYear')), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForandringAvsattningFondUnderRakenskapsaret',
              contextRef: 'period1',
              saldo: 'credit',
            })
          )
          .addRow(
            '3',
            noteLabel('Avskrivning av uppskrivna tillgångar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForandringAvskrivningUppskrivnaTillgangar',
              contextRef: 'period0',
              saldo: 'debit',
              standardRubrik:
                'Minskning av uppskrivningsfond i samband med avskrivning av uppskrivna tillgångar',
              negateValue: true,
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForandringAvskrivningUppskrivnaTillgangar',
              contextRef: 'period1',
              saldo: 'debit',
              standardRubrik:
                'Minskning av uppskrivningsfond i samband med avskrivning av uppskrivna tillgångar',
              negateValue: true,
            })
          )
          .addRow(
            '4',
            noteLabel('Nedskrivning av uppskrivna tillgångar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForandringNedskrivningUppskrivnaTillgangar',
              contextRef: 'period0',
              saldo: 'debit',
              standardRubrik:
                'Minskning av uppskrivningsfond i samband med nedskrivning av uppskrivna tillgångar',
              negateValue: true,
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForandringNedskrivningUppskrivnaTillgangar',
              contextRef: 'period1',
              saldo: 'debit',
              standardRubrik:
                'Minskning av uppskrivningsfond i samband med nedskrivning av uppskrivna tillgångar',
              negateValue: true,
            })
          )
          .addRow(
            '5',
            noteLabel('Försäljning/utrangering av uppskrivna tillgångar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForandringForsaljningUtrangeringUppskrivnaTillgangar',
              contextRef: 'period0',
              saldo: 'debit',
              standardRubrik:
                'Minskning av uppskrivningsfond i samband med försäljning eller utrangering av uppskrivna tillgångar',
              negateValue: true,
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForandringForsaljningUtrangeringUppskrivnaTillgangar',
              contextRef: 'period1',
              saldo: 'debit',
              standardRubrik:
                'Minskning av uppskrivningsfond i samband med försäljning eller utrangering av uppskrivna tillgångar',
              negateValue: true,
            })
          )
          .addRow(
            '6',
            noteLabel('Ianspråktaget för fondemission'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForandringIanspraktagetFondemission',
              contextRef: 'period0',
              saldo: 'debit',
              negateValue: true,
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForandringIanspraktagetFondemission',
              contextRef: 'period1',
              saldo: 'debit',
              negateValue: true,
            })
          )
          // row 'Nyemission' with ID 7 was removed, since it does not fit in current BV specs
          .addRow(
            '8',
            noteLabel('Ianspråktaget för täckande av förlust'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForandringIanspraktagetFondemissionTackForlust',
              contextRef: 'period0',
              saldo: 'debit',
              negateValue: true,
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:ForandringIanspraktagetFondemissionTackForlust',
              contextRef: 'period1',
              saldo: 'debit',
              negateValue: true,
            })
          )
          .build()
      )
      .newRowTemplate(value(''), value(undefined), value(undefined))
      .build(),
    sumTable: table(
      'notes.note26.sumTable',
      { id: 'label', label: '' },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      .addRows((rows) =>
        rows
          .addRow(
            'sum',
            label('Belopp vid årets utgång'),
            ixbrlCell(ref(or(sum(id('notes.note26.table.*.year')), 0)), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:Uppskrivningsfond',
              contextRef: 'balans0',
              saldo: 'credit',
            }),
            ixbrlCell(
              ref(or(sum(id('notes.note26.table.*.previousYear')), 0)),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:Uppskrivningsfond',
                contextRef: 'balans1',
                saldo: 'credit',
              }
            )
          )
          .addRow(
            'revaluedAssetsExcludingRevaluation',
            label('Uppskrivna tillgångarnas värde exkl. uppskrivning'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:UppskrivnaTillgangarVardeExklUppskrivning',
              contextRef: 'balans0',
              saldo: 'debit',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:UppskrivnaTillgangarVardeExklUppskrivning',
              contextRef: 'balans1',
              saldo: 'debit',
            })
          )
          .build()
      )
      .build(),
    commentLabel: field('Kommentar'),
    comment: ixbrlField(field(''), {
      type: 'stringItemType',
      name: 'se-gen-base:NotUppskrivningsfondKommentar',
      contextRef: 'period0',
    }),
    group: value(undefined),
    section: value(undefined),
    validForReportType: {
      k2: true,
      k3: true,
    },
  };
};

import React, { useMemo, useContext } from 'react';
import { useIntl } from 'react-intl';
import { ccyFormat } from '@agoy/common';
import { findAccrualFundValue, findYearEndValue } from './helpers';
import TaxTableRow, { TaxTableRowProps } from './TaxTableRow';
import TaxesDataContext from '../../../service/TaxesDataContext';

const ChosenReversalOfAccrualFundRow = (props: TaxTableRowProps) => {
  const { formatMessage } = useIntl();
  const {
    taxYearData: { state },
  } = useContext(TaxesDataContext);

  const P15 = findAccrualFundValue(state, 'totalAccrualFunds') || 0;
  const D23 = findYearEndValue(state, 'forcedReversalOfAccrualFund') || 0;

  const maxValue = -P15 - D23;

  const label = formatMessage(
    { id: 'tax.chosenReversalOfAccrualFund.label' },
    { value: ccyFormat(maxValue - (props.row.value || 0)) }
  );

  const modifiedRow = useMemo(() => {
    return { ...props.row, label };
  }, [props.row, label]);

  return (
    <TaxTableRow
      {...props}
      row={modifiedRow}
      onChangeLabel={undefined}
      minValue={0}
      maxValue={maxValue}
    />
  );
};

export default ChosenReversalOfAccrualFundRow;

import React from 'react';

const AreYouSure = () => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="55px"
    viewBox="0 0 19.6 25"
    xmlSpace="preserve"
  >
    <style type="text/css">
      {`
	.st0{fill:#CCCCCC;}
	.st1{fill:#FFFFFF;}
	.st2{fill:#EA5160;}`}
    </style>
    <g>
      <g id="Layer_1_1_">
        <path
          className="st0"
          d="M10.1,0.2C10,0.1,9.8,0,9.6,0H2C0.9,0,0,0.9,0,2v16c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V6.4
			c0-0.3-0.1-0.6-0.3-0.8L10.1,0.2z M8.8,15.9C6.4,16.3,4.2,15,3.3,13H5c0.8,1.1,2.1,1.7,3.6,1.4c1.3-0.3,2.3-1.3,2.6-2.6
			c0.5-2.3-1.2-4.3-3.4-4.3c-1.4,0-2.5,0.8-3.1,1.9L6.3,11h-4V7l1.3,1.3C4.5,6.9,6,6,7.8,6c3.1,0,5.6,2.8,4.9,6.1
			C12.4,14,10.8,15.6,8.8,15.9z"
        />
      </g>
      <g id="Layer_2_1_">
        <rect x="11.2" y="17.2" className="st1" width="3.1" height="6.4" />
        <path
          className="st2"
          d="M6.5,25h12.4c0.5,0,0.8-0.6,0.6-1l-6.2-10.7c-0.3-0.4-0.9-0.4-1.2,0L6,24C5.7,24.4,6,25,6.5,25z M12.7,23
			L12.7,23c-0.4,0-0.7-0.3-0.7-0.7l0,0c0-0.4,0.3-0.7,0.7-0.7l0,0c0.4,0,0.7,0.3,0.7,0.7l0,0C13.4,22.7,13.1,23,12.7,23z M12.7,20.3
			L12.7,20.3c-0.4,0-0.7-0.3-0.7-0.7v-1.3c0-0.4,0.3-0.7,0.7-0.7l0,0c0.4,0,0.7,0.3,0.7,0.7v1.3C13.4,20,13.1,20.3,12.7,20.3z"
        />
      </g>
    </g>
  </svg>
);

export default AreYouSure;

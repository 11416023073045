import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { NoteFourTablesWithCaptionsCommentsAndSum } from '@agoy/annual-report-document';
import { numberValue, stringValue } from '@agoy/document';
import { Note } from '../../UI/Note';
import Comment from '../../UI/Comment';
import Header from '../../UI/Header';
import NoteTable from '../../UI/NoteTable';
import { useIntl } from 'react-intl';

type Note99Props = {
  noteId: string;
  active: boolean;
  title: string;
  hasPreviousYear?: boolean;
  isXBRLRender?: boolean;
} & NoteFourTablesWithCaptionsCommentsAndSum;

const Note99 = ({
  noteId,
  active,
  number,
  title,
  subTitle,
  tableCaption,
  table,
  firstTableCommentLabel,
  firstTableComment,
  secondTableCaption,
  secondTable,
  secondTableCommentLabel,
  secondTableComment,
  thirdTableCaption,
  thirdTable,
  thirdTableCommentLabel,
  thirdTableComment,
  fourthTableCaption,
  fourthTable,
  fourthTableCommentLabel,
  fourthTableComment,
  sumTable,
  commentLabel,
  comment,
  hasPreviousYear,
  isXBRLRender = false,
}: Note99Props): JSX.Element | null => {
  const { formatMessage } = useIntl();

  if (!active) return null;
  return (
    <Note id={noteId}>
      <Header noteId={noteId} noteNumber={numberValue(number)} title={title} />

      {subTitle && (
        <Typography variant="h4">{stringValue(subTitle)}</Typography>
      )}

      <Box mt={1} mb={1}>
        <NoteTable
          table={table}
          caption={stringValue(tableCaption) || ''}
          hasPreviousYear={hasPreviousYear}
          tableId={`notes.${noteId}.table`}
          isXBRLRender={isXBRLRender}
        />
      </Box>

      <Comment
        field={firstTableComment}
        labelValue={stringValue(firstTableCommentLabel) || ''}
        value={stringValue(firstTableComment) || ''}
      />

      <Box mt={1} mb={1}>
        <NoteTable
          table={secondTable}
          caption={stringValue(secondTableCaption) || ''}
          hasPreviousYear={hasPreviousYear}
          tableId={`notes.${noteId}.secondTable`}
          isXBRLRender={isXBRLRender}
        />
      </Box>

      <Comment
        field={secondTableComment}
        labelValue={stringValue(secondTableCommentLabel) || ''}
        value={stringValue(secondTableComment) || ''}
      />

      <Box mt={1} mb={1}>
        <NoteTable
          table={thirdTable}
          caption={stringValue(thirdTableCaption) || ''}
          hasPreviousYear={hasPreviousYear}
          tableId={`notes.${noteId}.thirdTable`}
          isXBRLRender={isXBRLRender}
        />
      </Box>

      <Comment
        field={thirdTableComment}
        labelValue={stringValue(thirdTableCommentLabel) || ''}
        value={stringValue(thirdTableComment) || ''}
      />

      <Box mt={1} mb={1}>
        <NoteTable
          table={fourthTable}
          caption={stringValue(fourthTableCaption) || ''}
          hasPreviousYear={hasPreviousYear}
          tableId={`notes.${noteId}.fourthTable`}
          isXBRLRender={isXBRLRender}
        />
      </Box>

      {fourthTableComment && (
        <Comment
          field={fourthTableComment}
          labelValue={stringValue(fourthTableCommentLabel) || ''}
          value={stringValue(fourthTableComment) || ''}
        />
      )}

      <Box mt={1} mb={1}>
        <NoteTable
          table={sumTable}
          hasPreviousYear={hasPreviousYear}
          tableId={`notes.${noteId}.sumTable`}
          isXBRLRender={isXBRLRender}
        />
      </Box>

      <Comment
        field={comment}
        labelValue={stringValue(commentLabel) || ''}
        value={stringValue(comment) || ''}
      />
    </Note>
  );
};

export default Note99;

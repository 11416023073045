import {
  value,
  table,
  BooleanCell,
  NumberCell,
  StringCell,
  LabelCell,
  or,
  ref,
  sum,
  id,
} from '@agoy/document';
import { IxbrlCell, NoteFiveTableTwoCommentSection } from '../../../../common';
import {
  active,
  field,
  ixbrlCell,
  ixbrlField,
  tupleRef,
} from '../../../../common/utils/util';
import { year, previousYear } from '../../../../common/utils/date-util';
import { sumNotesRowsById } from '../../../../common/utils/accountSumsUtil';

const MONETARY_ITEM_TYPE = 'monetaryItemType';

export const getNote96 = (
  noteLabel: (
    name: string
  ) => BooleanCell | NumberCell | StringCell | LabelCell,
  financialYear: string,
  financialYears: string[],
  period: string | null
): NoteFiveTableTwoCommentSection => {
  return {
    active: false,
    number: value(undefined),
    name: value('Avsättningar för pensioner och liknande förpliktelser'),

    table: table<IxbrlCell>(
      'notes.note96.table',
      {
        id: 'label',
        label: 'Avsättningar för pensioner och liknande förpliktelser',
      },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      .addRows((rows) =>
        rows
          .addRow(
            'ingoingBalance',
            noteLabel('Ingående balans'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AvsattningarPensionerLiknandeForpliktelser',
              contextRef: 'balans0',
              standardRubrik:
                'Avsättningar för pensioner och liknande förpliktelser',
              saldo: 'credit',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AvsattningarPensionerLiknandeForpliktelser',
              contextRef: 'balans1',
              standardRubrik:
                'Avsättningar för pensioner och liknande förpliktelser',
              saldo: 'credit',
            })
          )
          .addRow(
            'changesToProvisionsForPensionAndSimilarObligations',
            noteLabel(
              'Förändringar av avsättningar för pensioner och liknande förpliktelser'
            ),
            undefined,
            undefined
          )
          .addRow(
            'provisionsForTheYear',
            noteLabel('Årets avsättningar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AvsattningarPensionerLiknandeForpliktelserForandringAvsattningar',
              contextRef: 'period0',
              standardRubrik:
                'Periodens avsättningar för pensioner och liknande förpliktelser',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AvsattningarPensionerLiknandeForpliktelserForandringAvsattningar',
              contextRef: 'period1',
              standardRubrik:
                'Periodens avsättningar för pensioner och liknande förpliktelser',
            })
          )
          .addRow(
            'adjustmentsFromChangesInPresentValue',
            noteLabel('Justeringar till följd av förändring av nuvärdet'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AvsattningarPensionerLiknandeForpliktelserForandringJusteringarForandringNuvarde',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av avsättningar för pensioner och liknande förpliktelser till följd av förändring av nuvärdet',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AvsattningarPensionerLiknandeForpliktelserForandringJusteringarForandringNuvarde',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av avsättningar för pensioner och liknande förpliktelser till följd av förändring av nuvärdet',
            })
          )
          .addRow(
            'otherChangesInValue',
            noteLabel('Övriga värdeförändringar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AvsattningarPensionerLiknandeForpliktelserOvrigaVardeforandringar',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av avsättningar för pensioner och liknande förpliktelser till följd av övriga värdeförändringar än förändring av nuvärdet',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AvsattningarPensionerLiknandeForpliktelserOvrigaVardeforandringar',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av avsättningar för pensioner och liknande förpliktelser till följd av övriga värdeförändringar än förändring av nuvärdet',
            })
          )
          .addRow(
            'businessAcquisitions',
            noteLabel('Rörelseförvärv'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AvsattningarPensionerLiknandeForpliktelserForandringRorelseforvarv',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av avsättningar för pensioner och liknande förpliktelser i samband med rörelseförvärv',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AvsattningarPensionerLiknandeForpliktelserForandringRorelseforvarv',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av avsättningar för pensioner och liknande förpliktelser i samband med rörelseförvärv',
            })
          )
          .addRow(
            'fusion',
            noteLabel('Fusion'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AvsattningarPensionerLiknandeForpliktelserForandringFusion',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av avsättningar för pensioner och liknande förpliktelser vid fusion',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AvsattningarPensionerLiknandeForpliktelserForandringFusion',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av avsättningar för pensioner och liknande förpliktelser vid fusion',
            })
          )
          .addRow(
            'reclassifications',
            noteLabel('Omklassificeringar'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AvsattningarPensionerLiknandeForpliktelserForandringOmklassificeringar',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av avsättningar för pensioner och liknande förpliktelser i samband med omklassificeringar',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AvsattningarPensionerLiknandeForpliktelserForandringOmklassificeringar',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av avsättningar för pensioner och liknande förpliktelser i samband med omklassificeringar',
            })
          )
          .addRow(
            'translationDifferences',
            noteLabel('Omräkningsdifferenser'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AvsattningarPensionerLiknandeForpliktelserForandringOmrakningsdifferenser',
              contextRef: 'period0',
              standardRubrik:
                'Ökning (minskning) av avsättningar för pensioner och liknande förpliktelser i samband med omräkningsdifferenser',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AvsattningarPensionerLiknandeForpliktelserForandringOmrakningsdifferenser',
              contextRef: 'period1',
              standardRubrik:
                'Ökning (minskning) av avsättningar för pensioner och liknande förpliktelser i samband med omräkningsdifferenser',
            })
          )
          .addRow(
            'amountClaimedDuringTheYear',
            noteLabel('Under året ianspråktagna belopp'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AvsattningarPensionerLiknandeForpliktelserForandringIanspraktagnaBelopp',
              contextRef: 'period0',
              standardRubrik:
                'Under perioden ianspråktagna belopp från avsättningar för pensioner och liknande förpliktelser',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AvsattningarPensionerLiknandeForpliktelserForandringIanspraktagnaBelopp',
              contextRef: 'period1',
              standardRubrik:
                'Under perioden ianspråktagna belopp från avsättningar för pensioner och liknande förpliktelser',
            })
          )
          .addRow(
            'amountReturnedDuringTheYear',
            noteLabel('Under året återförda belopp'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AvsattningarPensionerLiknandeForpliktelserForandringAterfordaBelopp',
              contextRef: 'period0',
              standardRubrik:
                'Under perioden återförda belopp från avsättningar för pensioner och liknande förpliktelser',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AvsattningarPensionerLiknandeForpliktelserForandringAterfordaBelopp',
              contextRef: 'period1',
              standardRubrik:
                'Under perioden återförda belopp från avsättningar för pensioner och liknande förpliktelser',
            })
          )
          .addRow(
            'outgoingBalance',
            noteLabel('Utgående balans'),
            ixbrlCell(
              sumNotesRowsById(
                96,
                [
                  'ingoingBalance',
                  'provisionsForTheYear',
                  'adjustmentsFromChangesInPresentValue',
                  'otherChangesInValue',
                  'businessAcquisitions',
                  'fusion',
                  'reclassifications',
                  'translationDifferences',
                  'amountClaimedDuringTheYear',
                  'amountReturnedDuringTheYear',
                ],
                'year'
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:AvsattningarPensionerLiknandeForpliktelser',
                contextRef: 'balans0',
                saldo: 'credit',
                standardRubrik:
                  'Avsättningar för pensioner och liknande förpliktelser',
              }
            ),
            ixbrlCell(
              sumNotesRowsById(
                96,
                [
                  'ingoingBalance',
                  'provisionsForTheYear',
                  'adjustmentsFromChangesInPresentValue',
                  'otherChangesInValue',
                  'businessAcquisitions',
                  'fusion',
                  'reclassifications',
                  'translationDifferences',
                  'amountClaimedDuringTheYear',
                  'amountReturnedDuringTheYear',
                ],
                'previousYear'
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:AvsattningarPensionerLiknandeForpliktelser',
                contextRef: 'balans1',
                saldo: 'credit',
                standardRubrik:
                  'Avsättningar för pensioner och liknande förpliktelser',
              }
            )
          )
          .build()
      )
      .build(),
    tableCommentLabel: field('Kommentar'),
    tableComment: ixbrlField(active(field('')), {
      type: 'stringItemType',
      name: 'se-gen-base:AvsattningarPensionerLiknandeForpliktelserForandringKommentar',
      contextRef: 'period0',
      standardRubrik:
        'Kommentar till specifikation av förändringar av avsättningar till pensioner och liknande förpliktelser',
    }),

    secondTable: table<IxbrlCell>(
      'notes.note96.secondTable',
      {
        id: 'label',
        label: 'Pensionsavsättningar som omfattas av tryggandelagen',
      },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      .addRows((rows) =>
        rows
          .addRow(
            'amount',
            noteLabel('Pensionsavsättningar som omfattas av tryggandelagen'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AvsattningarPensionerLiknandeForpliktelserEnligtLag',
              saldo: 'credit',
              contextRef: 'balans0',
              standardRubrik:
                'Avsättningar för pensioner och liknande förpliktelser enligt lagen om tryggande av pensionsutfästelse m.m.',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AvsattningarPensionerLiknandeForpliktelserEnligtLag',
              saldo: 'credit',
              contextRef: 'balans1',
              standardRubrik:
                'Avsättningar för pensioner och liknande förpliktelser enligt lagen om tryggande av pensionsutfästelse m.m.',
            })
          )
          .build()
      )
      .build(),
    secondTableCommentLabel: field('Kommentar'),
    secondTableComment: ixbrlField(active(field('')), {
      type: 'stringItemType',
      name: 'se-gen-base:UpplysningPensionsavsattningarnaTryggandelagenKommentar',
      contextRef: 'period0',
      standardRubrik:
        'Kommentar till upplysning om pensionsavsättningar som omfattas av tryggandelagen',
    }),

    thirdTable: table<IxbrlCell>(
      'notes.note96.thirdTable',
      {
        id: 'label',
        label: 'Förmånsbestämda planer redovisade enligt IAS 19',
      },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      .addRows((rows) =>
        rows
          .addRow(
            'actuarialAssumptions',
            noteLabel('Aktuariella antaganden'),
            undefined,
            undefined
          )
          .addRow(
            'discountRate',
            noteLabel('Diskonteringsränta'),
            ixbrlCell(value(undefined), {
              type: 'pureItemType',
              name: 'se-gen-base:AktuariellaAntagandenPensionsplanerEnligtIASDiskonteringsranta',
              contextRef: 'period0',
              standardRubrik:
                'Diskonteringsränta för pensionsplaner redovisade enligt IAS 19',
            }),
            ixbrlCell(value(undefined), {
              type: 'pureItemType',
              name: 'se-gen-base:AktuariellaAntagandenPensionsplanerEnligtIASDiskonteringsranta',
              contextRef: 'period1',
              standardRubrik:
                'Diskonteringsränta för pensionsplaner redovisade enligt IAS 19',
            })
          )
          .addRow(
            'expectedSalaryIncreaseRate',
            noteLabel('Förväntad löneökningstakt'),
            ixbrlCell(value(undefined), {
              type: 'pureItemType',
              name: 'se-gen-base:AktuariellaAntagandenPensionsplanerEnligtIASForvantadLoneokningstakt',
              contextRef: 'period0',
              standardRubrik:
                'Förväntad löneökningstakt för pensionsplaner redovisade enligt IAS 19',
            }),
            ixbrlCell(value(undefined), {
              type: 'pureItemType',
              name: 'se-gen-base:AktuariellaAntagandenPensionsplanerEnligtIASForvantadLoneokningstakt',
              contextRef: 'period1',
              standardRubrik:
                'Förväntad löneökningstakt för pensionsplaner redovisade enligt IAS 19',
            })
          )
          .addRow(
            'expectedReturnOnInvestmentAssets',
            noteLabel('Förväntad avkastning på förvaltningstillgångar'),
            ixbrlCell(value(undefined), {
              type: 'pureItemType',
              name: 'se-gen-base:AktuariellaAntagandenPensionsplanerEnligtIASForvantadAvkastningForvaltningstillgangar',
              contextRef: 'period0',
              standardRubrik:
                'Förväntad avkastning på förvaltningstillgångar för pensionsplaner redovisade enligt IAS 19',
            }),
            ixbrlCell(value(undefined), {
              type: 'pureItemType',
              name: 'se-gen-base:AktuariellaAntagandenPensionsplanerEnligtIASForvantadAvkastningForvaltningstillgangar',
              contextRef: 'period1',
              standardRubrik:
                'Förväntad avkastning på förvaltningstillgångar för pensionsplaner redovisade enligt IAS 19',
            })
          )
          .addRow(
            'otherActuarialAssumptions',
            noteLabel('Övriga aktuariella antaganden'),
            ixbrlCell(value(undefined), {
              type: 'stringItemType',
              name: 'se-gen-base:AktuariellaAntagandenPensionsplanerEnligtIASOvrigaAktuariellaAntagande',
              contextRef: 'period0',
              standardRubrik:
                'Övriga aktuariella antaganden för pensionsplaner redovisade enligt IAS 19',
            }),
            ixbrlCell(value(undefined), {
              type: 'stringItemType',
              name: 'se-gen-base:AktuariellaAntagandenPensionsplanerEnligtIASOvrigaAktuariellaAntagande',
              contextRef: 'period1',
              standardRubrik:
                'Övriga aktuariella antaganden för pensionsplaner redovisade enligt IAS 19',
            })
          )
          .build()
      )
      .build(),
    thirdTableCommentLabel: field('Kommentar'),
    thirdTableComment: ixbrlField(active(field('')), {
      type: 'stringItemType',
      name: 'se-gen-base:AktuariellaAntagandenPensionsplanerEnligtIASKommentar',
      contextRef: 'period0',
    }),

    fourthTable: table<IxbrlCell>(
      'notes.note96.fourthTable',
      {
        id: 'label',
        label: 'Redovisad pensions- och räntekostnad',
      },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      .addRows((rows) =>
        rows
          .addRow(
            'sum',
            noteLabel('Summa'),
            ixbrlCell(
              ref(
                or(
                  sum(
                    id('notes.note96.fourthTable.@*.year'),
                    or(
                      id(
                        'notes.note96.fourthTable.itemInTheIncomeStatement.year'
                      ),
                      0
                    )
                  ),
                  0
                )
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:RedovisadPensionskostnadErsattningarAvslutadAnstallning',
                standardRubrik:
                  'Summa pensionskostnader och räntekostnader för pensioner avseende planer redovisade enligt IAS 19',
                saldo: 'debit',
                contextRef: 'period0',
              }
            ),
            ixbrlCell(
              ref(
                or(
                  sum(
                    id('notes.note96.fourthTable.@*.previousYear'),
                    or(
                      id(
                        'notes.note96.fourthTable.itemInTheIncomeStatement.previousYear'
                      ),
                      0
                    )
                  ),
                  0
                )
              ),
              {
                type: MONETARY_ITEM_TYPE,
                name: 'se-gen-base:RedovisadPensionskostnadErsattningarAvslutadAnstallning',
                standardRubrik:
                  'Summa pensionskostnader och räntekostnader för pensioner avseende planer redovisade enligt IAS 19',
                saldo: 'debit',
                contextRef: 'period1',
              }
            )
          )
          .addRow(
            'itemInTheIncomeStatement',
            ixbrlCell(value('Post i resultaträkningen'), {
              type: 'stringItemType',
              name: 'se-gen-base:RedovisadPensionskostnadEnligtIASPost',
              standardRubrik:
                'Post i resultaträkningen innehållande pensionskostnad eller räntekostnad för pensioner avseende planer redovisade enligt IAS 19',
              contextRef: 'period0',
              tuple: {
                name: 'se-gaap-ext:RedovisadPensionskostnadEnligtIASTuple',
                id: `${rows.getBaseId()}.itemInTheIncomeStatement.tuple`,
                tupleID: `${rows.getBaseId()}.itemInTheIncomeStatement.tuple`,
              },
              previousYearTuple: true,
              tupleRef: tupleRef(
                1,
                `${rows.getBaseId()}.itemInTheIncomeStatement.tuple`
              ),
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:RedovisadPensionskostnadEnligtIAS',
              standardRubrik:
                'Belopp per post i resultaträkningen innehållande pensionskostnad eller räntekostnad för pensioner avseende planer redovisade enligt IAS 19',
              contextRef: 'period0',
              saldo: 'debit',
              tupleRef: tupleRef(
                2,
                `${rows.getBaseId()}.itemInTheIncomeStatement.tuple_period0`
              ),
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:RedovisadPensionskostnadEnligtIAS',
              standardRubrik:
                'Belopp per post i resultaträkningen innehållande pensionskostnad eller räntekostnad för pensioner avseende planer redovisade enligt IAS 19',
              contextRef: 'period0',
              saldo: 'debit',
              tupleRef: tupleRef(
                3,
                `${rows.getBaseId()}.itemInTheIncomeStatement.tuple_period1`
              ),
            })
          )
          .build()
      )
      .newRowTemplateGenerator((id, baseId) => ({
        id,
        active: true,
        cells: {
          label: ixbrlCell(value(''), {
            type: 'stringItemType',
            name: 'se-gen-base:RedovisadPensionskostnadEnligtIASPost',
            standardRubrik:
              'Post i resultaträkningen innehållande pensionskostnad eller räntekostnad för pensioner avseende planer redovisade enligt IAS 19',
            contextRef: 'period0',
            tuple: {
              name: 'se-gaap-ext:RedovisadPensionskostnadEnligtIASTuple',
              id: `${baseId}.${id}.itemInTheIncomeStatement.tuple`,
              tupleID: `${baseId}.${id}.itemInTheIncomeStatement.tuple`,
            },
            previousYearTuple: true,
            tupleRef: tupleRef(
              1,
              `${baseId}.${id}.itemInTheIncomeStatement.tuple`
            ),
          }),
          year: ixbrlCell(value(undefined), {
            type: MONETARY_ITEM_TYPE,
            name: 'se-gen-base:RedovisadPensionskostnadEnligtIAS',
            standardRubrik:
              'Belopp per post i resultaträkningen innehållande pensionskostnad eller räntekostnad för pensioner avseende planer redovisade enligt IAS 19',
            contextRef: 'period0',
            saldo: 'debit',
            tupleRef: tupleRef(
              2,
              `${baseId}.${id}.itemInTheIncomeStatement.tuple_period0`
            ),
          }),
          previousYear: ixbrlCell(value(undefined), {
            type: MONETARY_ITEM_TYPE,
            name: 'se-gen-base:RedovisadPensionskostnadEnligtIAS',
            standardRubrik:
              'Belopp per post i resultaträkningen innehållande pensionskostnad eller räntekostnad för pensioner avseende planer redovisade enligt IAS 19',
            contextRef: 'period0',
            saldo: 'debit',
            tupleRef: tupleRef(
              3,
              `${baseId}.${id}.itemInTheIncomeStatement.tuple_period1`
            ),
          }),
        },
      }))
      .build(),
    fourthTableCommentLabel: field('Kommentar'),
    fourthTableComment: ixbrlField(active(field('')), {
      type: 'stringItemType',
      name: 'se-gen-base:RedovisadPensionskostnadEnligtIASKommentar',
      contextRef: 'period0',
    }),

    fifthTable: table<IxbrlCell>(
      'notes.note96.fifthTable',
      {
        id: 'label',
        label: 'Poster redovisade mot eget kapital',
      },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      .addRows((rows) =>
        rows
          .addRow(
            'mailAgainstEquity',
            ixbrlCell(value('Post mot eget kapital'), {
              type: 'stringItemType',
              name: 'se-gen-base:AktuariellaIntakterKostnaderAndraPosterRedovisasMotEgetKapitalEnligtIASTyp',
              standardRubrik:
                'Typ av post som har redovisats mot eget kapital för pensionsplaner redovisade enligt IAS 19',
              contextRef: 'period0',
              tuple: {
                name: 'se-gaap-ext:AktuariellaIntakterKostnaderAndraPosterRedovisasMotEgetKapitalEnligtIASTuple',
                id: `${rows.getBaseId()}.mailAgainstEquity.tuple`,
                tupleID: `${rows.getBaseId()}.mailAgainstEquity.tuple`,
              },
              previousYearTuple: true,
              tupleRef: tupleRef(
                1,
                `${rows.getBaseId()}.mailAgainstEquity.tuple`
              ),
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AktuariellaIntakterKostnaderAndraPosterRedovisasMotEgetKapitalEnligtIAS',
              standardRubrik:
                'Aktuariella intäkter eller kostnader per typ av post som har redovisats mot eget kapital för pensionsplaner redovisade enligt IAS 19',
              contextRef: 'period0',
              saldo: 'credit',
              tupleRef: tupleRef(
                2,
                `${rows.getBaseId()}.mailAgainstEquity.tuple_period0`
              ),
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:AktuariellaIntakterKostnaderAndraPosterRedovisasMotEgetKapitalEnligtIAS',
              standardRubrik:
                'Aktuariella intäkter eller kostnader per typ av post som har redovisats mot eget kapital för pensionsplaner redovisade enligt IAS 19',
              contextRef: 'period1',
              saldo: 'credit',
              tupleRef: tupleRef(
                3,
                `${rows.getBaseId()}.mailAgainstEquity.tuple_period1`
              ),
            })
          )
          .build()
      )
      .newRowTemplateGenerator((id, baseId) => ({
        id,
        active: true,
        cells: {
          label: ixbrlCell(value(''), {
            type: 'stringItemType',
            name: 'se-gen-base:AktuariellaIntakterKostnaderAndraPosterRedovisasMotEgetKapitalEnligtIASTyp',
            standardRubrik:
              'Typ av post som har redovisats mot eget kapital för pensionsplaner redovisade enligt IAS 19',
            contextRef: 'period0',
            tuple: {
              name: 'se-gaap-ext:AktuariellaIntakterKostnaderAndraPosterRedovisasMotEgetKapitalEnligtIASTuple',
              id: `${baseId}.${id}.mailAgainstEquity.tuple`,
              tupleID: `${baseId}.${id}.mailAgainstEquity.tuple`,
            },
            previousYearTuple: true,
            tupleRef: tupleRef(1, `${baseId}.${id}.mailAgainstEquity.tuple`),
          }),
          year: ixbrlCell(value(undefined), {
            type: MONETARY_ITEM_TYPE,
            name: 'se-gen-base:AktuariellaIntakterKostnaderAndraPosterRedovisasMotEgetKapitalEnligtIAS',
            standardRubrik:
              'Aktuariella intäkter eller kostnader per typ av post som har redovisats mot eget kapital för pensionsplaner redovisade enligt IAS 19',
            contextRef: 'period0',
            saldo: 'credit',
            tupleRef: tupleRef(
              2,
              `${baseId}.${id}.mailAgainstEquity.tuple_period0`
            ),
          }),
          previousYear: ixbrlCell(value(undefined), {
            type: MONETARY_ITEM_TYPE,
            name: 'se-gen-base:AktuariellaIntakterKostnaderAndraPosterRedovisasMotEgetKapitalEnligtIAS',
            standardRubrik:
              'Aktuariella intäkter eller kostnader per typ av post som har redovisats mot eget kapital för pensionsplaner redovisade enligt IAS 19',
            contextRef: 'period1',
            saldo: 'credit',
            tupleRef: tupleRef(
              3,
              `${baseId}.${id}.mailAgainstEquity.tuple_period1`
            ),
          }),
        },
      }))
      .build(),
    fifthTableCommentLabel: field('Kommentar'),
    fifthTableComment: ixbrlField(active(field('')), {
      type: 'stringItemType',
      name: 'se-gen-base:AktuariellaIntakterKostnaderAndraPosterRedovisasMotEgetKapitalKommentar',
      contextRef: 'period0',
    }),

    sixthCommentLabel: field('Kommentar'),
    sixthComment: ixbrlField(active(field('')), {
      type: 'stringItemType',
      name: 'se-gen-base:SpecifikationFormansbestamdaPlanerRedovisadeEnligtIASKommentar',
      contextRef: 'period0',
    }),

    commentLabel: field('Kommentar till not'),
    comment: ixbrlField(active(field('')), {
      type: 'stringItemType',
      name: 'se-gen-base:NotAvsattningarPensionerLiknandeForpliktelserKommentar',
      contextRef: 'period0',
    }),
    group: value(undefined),
    section: value(undefined),
    validForReportType: {
      k2: false,
      k3: true,
    },
  };
};

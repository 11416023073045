import { createContext } from 'react';
import { RootState } from 'redux/reducers';
import { Observable } from 'rxjs';
import { isLeft } from 'fp-ts/lib/Either';
import {
  AnnualReportChanges,
  DocumentConfiguration,
  annualReportChangesCodec,
} from '@agoy/annual-report-document';
import { getContext } from 'utils/AgoyAppClient/contextHolder';
import { asResultClass, getApiSdk, isApiErrorType } from 'api-sdk';
import AnnualReportState from '_annual-report/redux/annual-report/reducer/types';
import { Dispatch } from 'redux';
import { setAnnualReportChanges } from '_annual-report/redux/annual-report/actions';
import { addGlobalErrorMessage } from '_messages/redux/actions';

import { createProvider, ReduxDataSource, DataSource } from './ReduxSource';

export type ChangesAndReportType = {
  changes: AnnualReportChanges;
  locked: boolean;
  documentConfiguration: DocumentConfiguration;
  documentId: string;
};

export type ChangesDataSource = DataSource<ChangesAndReportType>;

class ReduxAnnualReportChangesDataSource extends ReduxDataSource<
  ChangesAndReportType,
  AnnualReportState
> {
  dispatch: Dispatch<any>;

  readOnly: boolean;

  constructor(
    state: Observable<AnnualReportState>,
    dispatch: Dispatch<any>,
    readOnly: boolean
  ) {
    super(state, 'AnnualReportChanges');
    this.dispatch = dispatch;
    this.readOnly = readOnly;
  }

  // eslint-disable-next-line class-methods-use-this
  mapStateToData(
    clientId: string,
    period: string
  ): (state: AnnualReportState) => ChangesAndReportType | undefined {
    return (state) => {
      const { changes, documentConfiguration, locked, documentId } =
        state.clients[clientId]?.years[period];

      return changes &&
        documentConfiguration &&
        locked !== undefined &&
        documentId
        ? { changes, documentConfiguration, locked, documentId }
        : undefined;
    };
  }

  async onMissingData(clientId: string, period: string): Promise<void> {
    const context = getContext();
    const api = await getApiSdk(context);
    const storedAnnualReportResult = await asResultClass(
      api.getAnnualReportChanges({
        clientid: clientId,
        financialYear: period,
      })
    );

    if (storedAnnualReportResult.ok) {
      const storedAnnualReport = storedAnnualReportResult.val;
      const decoded = annualReportChangesCodec.decode(
        storedAnnualReport.changes
      );
      if (isLeft(decoded)) {
        // eslint-disable-next-line no-console
        console.error('Invalid content');
        this.dispatch(addGlobalErrorMessage('error'));
        return;
      }
      const { documentConfiguration, ...changes } = decoded.right;

      this.dispatch(
        setAnnualReportChanges(
          clientId,
          period,
          decoded.right.documentConfiguration,
          changes,
          storedAnnualReport.documentId,
          storedAnnualReport.locked
        )
      );
    } else {
      // eslint-disable-next-line no-console
      this.dispatch(addGlobalErrorMessage('error'));
    }
  }

  async set(
    clientId: string,
    period: string,
    value: ChangesAndReportType
  ): Promise<void> {
    try {
      const api = getApiSdk(getContext());

      this.dispatch(
        setAnnualReportChanges(
          clientId,
          period,
          value.documentConfiguration,
          value.changes,
          undefined,
          value.locked
        )
      );

      if (this.readOnly) {
        // Skip sending updates to backend when readOnly (support)
        return;
      }

      const result = await asResultClass(
        api.putAnnualReportChanges({
          clientid: clientId,
          financialYear: period,
          requestBody: {
            documentConfiguration: value.documentConfiguration,
            ...value.changes,
          },
        })
      );

      if (result.err) {
        if (isApiErrorType(result.val)) {
          if (result.val.handled || result.val.status === 402) {
            return;
          }

          if (
            result.val.body.code === 'AN_REPORT.LOCKED' ||
            result.val.body.code === 'DOCUMENT_LOCKED'
          ) {
            this.dispatch(addGlobalErrorMessage('error.AN_REPORT.LOCKED'));
            return;
          }
        } else {
          // eslint-disable-next-line no-console
          console.error(result.val);
        }
        this.dispatch(addGlobalErrorMessage('error'));
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      this.dispatch(addGlobalErrorMessage('error'));
    }
  }
}

export const ChangesSourceContext = createContext<
  DataSource<ChangesAndReportType>
>({} as unknown as DataSource<ChangesAndReportType>);

const selectState = (rootState: RootState) => rootState.annualReport;

export const Provider = createProvider(
  ChangesSourceContext,
  selectState,
  (subject, dispatch, readOnly) => {
    return new ReduxAnnualReportChangesDataSource(subject, dispatch, readOnly);
  }
);

import { AgoyDocumentStructure } from '../document';
import { OperationResult, State } from './types';
import updateRow from './helpers/updateRow';
import updateDocument from './helpers/updateDocument';
import { mapRecord } from '@agoy/common';

/*
 * resetTableRow
 *
 * @param structure       AgoyDocumentStructure (contentDefinition)
 * @param state           The document and changes to update
 * @param id              Field id
 */
const resetTableRow = <T extends AgoyDocumentStructure>(
  structure: T,
  state: State<T>,
  id: string,
  updateSubRows?: boolean
): OperationResult<T> => {
  return updateDocument(structure, state, id, {
    table: (key, id, props) => {
      if (!key) {
        return props;
      }
      const updater = updateRow(
        key,
        (row, update) => {
          if (
            !row.cells ||
            Object.values(row.cells).every((cell) => !cell.original)
          ) {
            // No changes to reset to
            return [row, update];
          }
          const newCells = mapRecord(
            row.cells,
            (cell) => cell.original ?? cell
          );
          return [
            { ...row, cells: newCells },
            { ...update, row: undefined },
          ];
        },
        updateSubRows
      );
      const changes = props.changes || { type: 'update' };
      const result = updater(props.node, changes);
      if (Array.isArray(result)) {
        const [updatedTable, updatedChanges] = result;
        if (props.node !== updatedTable || changes !== updatedChanges) {
          return {
            ...props,
            node: updatedTable,
            changes: updatedChanges,
          };
        }
      }
      return props;
    },
  });
};

export default resetTableRow;

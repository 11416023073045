import React, { useState, useCallback } from 'react';
import { Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import styled from '@emotion/styled';
import {
  formatOrganisationNumber,
  isValidOrgNumber,
  isValidPostalCode,
} from '@agoy/common';
import LoadingLogo from '_shared/components/LoadingLogo';
import { CTAButton } from '_shared/components/Buttons';
import TextField from '_shared/components/Inputs/SimplifiedTextfield';
import { sanitizeOrgNumber } from 'utils/Client/client-util';

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: ${(props) => props.theme.spacing(1)}px;
`;

interface FormValues {
  name: string;
  orgNumber: string;
  country: string;
  city: string;
  address: string;
  postalCode: string;
}

interface FormErrors {
  name?: string;
  orgNumber?: string;
  postalCode?: string;
}

interface CreateOrganisationFormProps {
  onCreateOrganization: (values: FormValues) => void;
  loading: boolean;
}

const formErrorMessages = {
  name: 'company.name.error',
  orgNumber: 'company.orgNumber.error',
  postalCode: 'company.zipcode.error',
};

const CreateOrganisationForm = ({
  onCreateOrganization,
  loading,
}: CreateOrganisationFormProps): JSX.Element => {
  const intl = useIntl();

  const [formValues, setFormValues] = useState<FormValues>({
    name: '',
    orgNumber: '',
    country: 'Sweden',
    city: '',
    address: '',
    postalCode: '',
  });
  const [formErrors, setFormErrors] = useState<FormErrors>({} as FormErrors);

  const setFormValue = useCallback(
    (field: string, value: string) => {
      setFormValues({ ...formValues, [field]: value });
    },
    [formValues]
  );

  const setFormError = useCallback(
    (field: string, error: string) => {
      setFormErrors({ ...formErrors, [field]: error });
    },
    [formErrors]
  );

  const validateField = (field: string) => {
    if (
      (field === 'orgNumber' && !isValidOrgNumber(formValues[field] || '')) ||
      (field === 'name' && !formValues[field]) ||
      (field === 'postalCode' &&
        formValues[field] &&
        !isValidPostalCode(formValues[field]))
    ) {
      return intl.formatMessage({ id: formErrorMessages[field] });
    }

    return null;
  };

  const validateForm = () => {
    const errors = {} as FormErrors;

    Object.keys(formValues).forEach((item) => {
      const error = validateField(item);

      if (error) {
        errors[item] = error;
      }
    });

    setFormErrors(errors);

    return !Object.keys(errors).length;
  };

  const handleOnBlur = (field: string) => {
    const error = validateField(field);
    if (error) {
      setFormError(field, error);
    } else {
      if (field === 'orgNumber') {
        setFormValue(
          field,
          formatOrganisationNumber(formValues.orgNumber, true, 'ifNeeded')
        );
      }
      setFormError(field, '');
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (validateForm()) {
      onCreateOrganization({
        ...formValues,
        orgNumber: sanitizeOrgNumber(formValues.orgNumber),
      });
    }
  };

  const disableSubmit = () => {
    let disabled = false;

    Object.keys(formErrors).forEach((item) => {
      if (formErrors[item]) {
        disabled = true;
      }
    });

    return disabled;
  };

  return (
    <>
      <Typography variant="h5">
        {intl.formatMessage({ id: 'company.create.desc' })}
      </Typography>
      <TextField
        label={intl.formatMessage({ id: 'company.orgNumber' })}
        labelPlacement="left"
        editing
        value={formValues.orgNumber}
        onChange={(e) => {
          setFormValue('orgNumber', e.target.value);
        }}
        onBlur={() => handleOnBlur('orgNumber')}
        error={!!formErrors.orgNumber}
        helperText={formErrors.orgNumber}
        required
        fullWidth
      />
      <TextField
        label={intl.formatMessage({ id: 'company.name' })}
        labelPlacement="left"
        editing
        value={formValues.name}
        onChange={(e) => setFormValue('name', e.target.value)}
        onBlur={() => handleOnBlur('name')}
        error={!!formErrors.name}
        helperText={formErrors.name}
        required
        fullWidth
      />
      <TextField
        label={intl.formatMessage({ id: 'company.address' })}
        labelPlacement="left"
        editing
        value={formValues.address}
        onChange={(e) => {
          setFormValue('address', e.target.value);
        }}
        fullWidth
      />
      <TextField
        label={intl.formatMessage({ id: 'company.city' })}
        labelPlacement="left"
        editing
        value={formValues.city}
        onChange={(e) => {
          setFormValue('city', e.target.value);
        }}
        fullWidth
      />

      <TextField
        label={intl.formatMessage({ id: 'company.zipcode' })}
        labelPlacement="left"
        editing
        value={formValues.postalCode}
        onChange={(e) => {
          setFormValue('postalCode', e.target.value);
        }}
        onBlur={() => handleOnBlur('postalCode')}
        required
        error={!!formErrors.postalCode}
        helperText={formErrors.postalCode}
        fullWidth
      />

      <TextField
        label={intl.formatMessage({ id: 'company.country' })}
        labelPlacement="left"
        editing
        value={formValues.country}
        onChange={(e) => {
          setFormValue('country', e.target.value);
        }}
        fullWidth
        disabled
      />

      <ButtonsContainer>
        {loading ? (
          <LoadingLogo />
        ) : (
          <CTAButton
            disabled={disableSubmit()}
            onClick={handleSubmit}
            type="submit"
          >
            {intl.formatMessage({ id: 'company.create' })}
          </CTAButton>
        )}
      </ButtonsContainer>
    </>
  );
};

export default CreateOrganisationForm;

/*
 * This is a GENERATED file, do not change manually!
 * Generated:
 * - from file k3-arsredovisning-2021-10-31-rev20230131 - Noter.csv
 * - Comments: AGOY-6852
 */

import {
  value,
  ref,
  AgoyDocumentPart,
} from '@agoy/document';
import { IxbrlCell } from '../../../../../common';
import {
  field,
  ixbrlCell,
  ixbrlField,
  table,
} from '../../../../../common/utils/util';
import { previousYear, year } from '../../../../../common/utils/date-util';
import {
  sumAccountsUBs,
} from '../../../../../common/utils/accountSumsUtil';

const cell = { type: 'field' as const };
const boolean = { type: 'boolean' as const };
const type = { type: 'type' as const };
const number = { type: 'number' as const };

export const getNote2004K3v2 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null
): AgoyDocumentPart<typeof note2004K3v2ContentDefinition> => ({
  active: false,
  number: value(undefined),
  name: noteLabel('Nettoomsättning i resultaträkning i förkortad form'),
  data: {
    active: true,
    ResultatrakningForkortadFormAbstract: {
      type: 'group',
      active: true,
      level: 0,
      title: field('Nettoomsättning i resultaträkning i förkortad form'),
      table: table<IxbrlCell>(
        'notes.note4.data.ResultatrakningForkortadFormAbstract.table',
        {
          id: 'ResultatrakningForkortadFormAbstract',
          label: '',
          dataType: 'text',
          active: true,
        },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) ?? '',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) ?? '',
          dataType: 'numeric',
          active: true,
        }
      )
        .addRows((rows) => {
          rows
            .addRowWithType(
              'ResultatrakningForkortadFormAbstract',
              'hidden',
              undefined,
              undefined,
              undefined
            )
            .addSubRows((rows) => {
              rows.addRowWithType(
                'Nettoomsattning',
                'sum_accounts',
                noteLabel('Nettoomsättning'),
                ixbrlCell(ref(sumAccountsUBs('3000:3799', true)), {
                  contextRef: 'period0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:Nettoomsattning',
                  standardRubrik: 'Nettoomsättning',
                  saldo: 'credit',
                  negateValue: false,
                }),
                ixbrlCell(
                  ref(sumAccountsUBs('3000:3799', true, 'previousYear')),
                  {
                    contextRef: 'period1',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:Nettoomsattning',
                    standardRubrik: 'Nettoomsättning',
                    saldo: 'credit',
                    negateValue: false,
                  }
                )
              );
              return rows.build();
            });
          return rows.build();
        })
        .build(),
      NotResultatrakningForkortadFormKommentar: {
        type: 'group',
        active: true,
        level: 0,
        title: field(
          'Kommentar till nettoomsättning i resultaträkning i förkortad form'
        ),
        value: ixbrlField(field(''), {
          contextRef: 'period0',
          type: 'stringItemType',
          name: 'se-gen-base:NotResultatrakningForkortadFormKommentar',
          standardRubrik:
            'Kommentar till nettoomsättning i resultaträkning i förkortad form',
        }),
      },
    },
  },
  group: value(undefined),
  section: value(undefined),
  validForReportType: {
    k2: true,
    k3: true,
  },
});
export const note2004K3v2ContentDefinition = {
  type: 'part' as const,
  children: {
    active: boolean,
    number: cell,
    name: cell,
    data: {
      type: 'part' as const,
      children: {
        active: boolean,

        ResultatrakningForkortadFormAbstract: {
          type: 'part' as const,
          children: {
            type: type,
            level: number,
            active: boolean,

            title: cell,

            table: { type: 'table' as const },

            NotResultatrakningForkortadFormKommentar: {
              type: 'part' as const,
              children: {
                type: type,
                level: number,
                active: boolean,

                title: cell,

                value: cell,
              },
            },
          },
        },
      },
    },
    group: cell,
    section: cell,
    validForReportType: {
      type: 'part' as const,
      children: {
        k2: boolean,
        k3: boolean,
      },
    },
  },
};

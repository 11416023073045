import {
  OperationResult,
  operations,
  State,
  Errors,
  findRow,
  isError,
  labelValue,
  AgoyTableRow,
  splitId,
} from '@agoy/document';
import { traverseDocument } from '@agoy/document';
import { AnnualReportStructure } from '../config/document';
import { BALANCE_SHEET_ACCOUNT_ROW_SPLIT_SUFFIX } from '../constants';

type AnnualReportErrors =
  | Errors
  | 'MOVE_ROWS_TO_EXISTING'
  | 'MOVE_ROWS_MULTIPLE_ACCOUNT_ROWS'
  | 'MOVE_ROWS_INVALID_COLUMN_TYPE';

const parentId = (id: string) => id.substring(0, id.lastIndexOf('.'));

const createAccountRowId = (account: string, columns: 'all' | 'ib' | 'ub') => {
  return columns === 'all'
    ? account
    : `${account}${BALANCE_SHEET_ACCOUNT_ROW_SPLIT_SUFFIX}${columns}`;
};

const findRowInDocument = (
  structure: AnnualReportStructure,
  id: string,
  state: State<AnnualReportStructure>
): AgoyTableRow | undefined => {
  let row: AgoyTableRow | undefined;

  traverseDocument(splitId(id), structure, state.document, undefined, {
    table: (subKey, tableId, props) => {
      if (subKey) {
        row = findRow(subKey?.join('.'), props.node);
      }
      return props;
    },
  });
  return row;
};

const moveAccountRow = <T extends AnnualReportStructure>(
  structure: T,
  state: State<T>,
  id: string,
  targetId: string,
  columns: 'all' | 'ib' | 'ub' = 'all'
): OperationResult<AnnualReportStructure, AnnualReportErrors> => {
  if (targetId === parentId(id)) {
    // Moving to same parent, ignore
    return false;
  }

  let currentState = state;
  const accountRow = findRowInDocument(structure, id, state);
  const targetRow = findRowInDocument(structure, targetId, state);
  if (accountRow && targetRow?.rows) {
    const getColumnsFromRow = (id: string): 'all' | 'ib' | 'ub' => {
      if (id.endsWith('ib')) {
        return 'ib';
      }
      if (id.endsWith('ub')) {
        return 'ub';
      }
      return 'all';
    };

    const actualColumns =
      columns === 'all' ? getColumnsFromRow(accountRow.id) : columns;

    const account = accountRow.id.split(
      BALANCE_SHEET_ACCOUNT_ROW_SPLIT_SUFFIX
    )[0];
    const label = labelValue(accountRow.cells?.label) || 'ERROR';

    if (actualColumns !== 'all' && !accountRow.id.endsWith(actualColumns)) {
      // Add row for the remaining columns
      const result = operations.addTableRow(
        structure,
        currentState,
        parentId(id),
        createAccountRowId(account, actualColumns === 'ib' ? 'ub' : 'ib'),
        {
          label,
          ib: actualColumns === 'ib' ? '' : account,
          ub: actualColumns === 'ib' ? account : '',
        },
        undefined,
        parseInt(account)
      );
      if (result) {
        if (isError(result)) {
          return result;
        }
        currentState = result;
      }
    }

    // Check if this account already exists in the target
    const existingAccountRows = targetRow.rows?.filter((row) =>
      row.id.includes(account)
    );

    if (actualColumns === 'all') {
      if (existingAccountRows.length > 0) {
        console.warn('Existing account rows', existingAccountRows);
        return 'MOVE_ROWS_TO_EXISTING';
      }
      const result = operations.chain(
        currentState,
        (state) =>
          operations.addTableRow(
            structure,
            state,
            targetId,
            account,
            {
              label,
              ib: account,
              ub: account,
              account,
            },
            undefined,
            parseInt(account)
          ),
        (state) => {
          if (state.changes !== currentState.changes) {
            // If the add didn't result in a change, we should not delete the row.
            return operations.deleteTableRow(structure, state, id);
          }
          return state;
        }
      );
      if (result) {
        if (isError(result)) {
          console.warn(`moveAccountRow: deleteTableRow failed with ${result}`);
          return result;
        }
        currentState = result;
      }
    } else if (existingAccountRows.length > 0) {
      if (existingAccountRows.length > 1) {
        console.error(
          'Multiple rows of same account found in target',
          existingAccountRows
        );
        return 'MOVE_ROWS_MULTIPLE_ACCOUNT_ROWS';
      }
      const existingColumns = getColumnsFromRow(existingAccountRows[0].id);
      if (
        (actualColumns === 'ib' && existingColumns === 'ub') ||
        (actualColumns === 'ub' && existingColumns === 'ib')
      ) {
        // Right type of existing row, merge them.
        const result = operations.chain(
          currentState,
          (state) =>
            operations.addTableRow(
              structure,
              state,
              targetId,
              account,
              {
                label,
                ib: account,
                ub: account,
              },
              undefined,
              parseInt(account)
            ),
          (state) => operations.deleteTableRow(structure, state, id),
          (state) =>
            operations.deleteTableRow(
              structure,
              state,
              `${targetId}.${existingAccountRows[0].id}`
            )
        );
        if (result) {
          if (isError(result)) {
            return result;
          }
          currentState = result;
        }
      } else {
        console.error(
          `Wrong type (${existingColumns} of existing account row in target for source type ${actualColumns}`
        );
        return 'MOVE_ROWS_INVALID_COLUMN_TYPE';
      }
    } else {
      // No existing account row in target
      const result = operations.chain(
        currentState,
        (state) =>
          operations.addTableRow(
            structure,
            state,
            targetId,
            createAccountRowId(account, actualColumns),
            {
              label,
              ib: actualColumns === 'ib' ? account : '',
              ub: actualColumns === 'ib' ? '' : account,
            },
            undefined,
            parseInt(account)
          ),
        (state) => operations.deleteTableRow(structure, state, id)
      );
      if (result) {
        if (isError(result)) {
          return result;
        }
        currentState = result;
      }
    }
  }
  if (currentState === state) {
    return false;
  }

  return currentState;
};

export default moveAccountRow;

export function groupBy<T, K>(
  list: T[],
  keyFn: <U extends T>(item: U) => K
): Map<K, T[]> {
  const result = new Map<K, T[]>();
  list.forEach((item: T) => {
    const key = keyFn(item);
    if (!result.has(key)) {
      result.set(key, []);
    }
    result.get(key)?.push(item);
  });
  return result;
}

export function group<T, R, K>(
  list: T[],
  keyFn: <U extends T>(item: U) => K,
  valuesFn: <U extends T>(item: U[]) => R
): Map<K, R> {
  const temp: Map<K, T[]> = new Map();
  list.forEach((item) => {
    const key = keyFn(item);
    if (!temp.has(key)) {
      temp.set(key, []);
    }
    temp.get(key)?.push(item);
  });
  const result: Map<K, R> = new Map();
  for (const [key, value] of temp.entries()) {
    result.set(key, valuesFn(value));
  }
  return result;
}

/**
 * Default action for handling duplicate keys in mapOf.
 * Throws an error.
 *
 * @param key
 * @param previousItem
 * @param nextItem
 * @param nextSource
 */
function throwDuplicateKeyError<T, R, K>(
  key: K,
  previousItem: R,
  nextItem: R,
  nextSource: T
): R {
  console.error(`Duplicate key ${key} with values`, previousItem, nextSource);
  throw Error(`Duplicate key ${key}`);
}
type DuplicateResolverFn<T, R, K> = (
  key: K,
  previousItem: R,
  nextItem: R,
  nextSource: T
) => R;

export function mapOf<T, R, K>(
  list: T[],
  keyFn: (item: T) => K,
  valueFn: (item: T) => R = (item) => item as unknown as R,
  duplicateResolver: DuplicateResolverFn<T, R, K> = throwDuplicateKeyError
): Map<K, R> {
  const result: Map<K, R> = new Map();
  list.forEach((item) => {
    const key = keyFn(item);
    const existingValue = result.get(key);
    let value = valueFn ? valueFn(item) : (item as unknown as R);
    if (existingValue !== undefined) {
      value = duplicateResolver(key, existingValue, value, item);
    }
    result.set(key, value);
  });
  return result;
}

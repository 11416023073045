import React from 'react';
import {
  AppBar as MuiAppBar,
  Divider as MuiDivider,
  Toolbar as MuiToolbar,
} from '@material-ui/core';
import styled from '@emotion/styled';

const AppBar = styled(MuiAppBar)`
  background: ${({ theme }) => theme.palette.grey[200]};
`;

const Toolbar = styled(MuiToolbar)`
  display: flex;
  justify-content: space-between;
  padding-top: ${({ theme }) => theme.spacing(1)}px;
  padding-bottom: ${({ theme }) => theme.spacing(1)}px;
`;

const Grouper = styled.div`
  display: flex;
  align-items: center;
`;

const Divider = styled(MuiDivider)`
  margin: ${({ theme }) => theme.spacing(1 / 2, 2, 1 / 2, 1)};
`;

const SubMenuToolBar = ({ children }) => (
  <AppBar position="static" elevation={2}>
    <Toolbar>{children}</Toolbar>
  </AppBar>
);

SubMenuToolBar.Grouper = Grouper;
SubMenuToolBar.Divider = () => <Divider flexItem orientation="vertical" />;

export default SubMenuToolBar;

import React from 'react';
import { IconButton, Tooltip, makeStyles } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';

type Props = {
  title: JSX.Element | '';
};

const tooltipStyles = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.fortnoxTooltip.background,
    borderRadius: theme.spacing(1),
    padding: `${theme.spacing(2)}px ${theme.spacing(1.5)}px`,
    marginRight: 8,
    minWidth: 400,
  },
  arrow: {
    color: theme.palette.fortnoxTooltip.background,
  },
}));

const TooltipComponent = ({ title }: Props) => {
  return (
    <Tooltip
      title={title}
      arrow
      placement="right"
      classes={tooltipStyles()}
      tabIndex={0}
    >
      <IconButton size="small">
        <InfoOutlined />
      </IconButton>
    </Tooltip>
  );
};

export default TooltipComponent;

import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Dialog } from '@material-ui/core';
import { useIntl } from 'react-intl';
import Button from '_shared/components/Buttons/Button';
import Typography from '_shared/components/Typography/Typography';

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    padding: ${({ theme }) => theme.spacing(4)}px;
    max-width: 450px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-radius: 8px;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
`;

interface Props {
  isOpen: boolean;
  isLeavingPage: boolean;
  onApprove: () => Promise<void>;
  onIgnoreChanges: () => void;
  onClose: () => void;
}

const SaveDialog = ({
  isOpen,
  isLeavingPage,
  onApprove,
  onIgnoreChanges,
  onClose,
}: Props) => {
  const { formatMessage } = useIntl();

  const [loading, setLoading] = useState(false);

  const handleApprove = async () => {
    try {
      setLoading(true);
      await onApprove();
    } catch {
      onClose();
    } finally {
      setLoading(false);
    }
  };

  return (
    <StyledDialog open={isOpen} onClose={onClose}>
      <Header>
        <Typography variant="h2">
          {formatMessage({
            id: 'reconciliation.movedAccount.saveDialog.header',
          })}
        </Typography>
      </Header>

      <Typography variant="body1">
        {formatMessage({
          id: isLeavingPage
            ? 'reconciliation.movedAccount.saveDialog.bodyLeavePage'
            : 'reconciliation.movedAccount.saveDialog.body',
        })}
      </Typography>

      <ButtonContainer>
        <Button
          label={formatMessage({
            id: 'reconciliation.movedAccount.saveDialog.save',
          })}
          variant="contained"
          size="large"
          loading={loading}
          onClick={handleApprove}
        />
        <Button
          label={formatMessage({
            id: 'reconciliation.movedAccount.saveDialog.ignore',
          })}
          variant="outlined"
          size="large"
          onClick={onIgnoreChanges}
        />
        <Button
          label={formatMessage({ id: 'cancel' })}
          variant="text"
          size="large"
          onClick={onClose}
        />
      </ButtonContainer>
    </StyledDialog>
  );
};

export default SaveDialog;

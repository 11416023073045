import { table, value } from '@agoy/document';
import { IxbrlCell, Note100 } from '../../../../common';
import { previousYear, year } from '../../../../common/utils/date-util';
import {
  active,
  field,
  ixbrlCell,
  ixbrlField,
  tupleRef,
} from '../../../../common/utils/util';

const MONETARY_ITEM_TYPE = 'monetaryItemType';

export const getNote100 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null
): Note100 => {
  const getTable = (
    sectionId: string,
    sYear: string,
    sPreviousYear: string,
    name: string
  ) => {
    return table<IxbrlCell>(
      `${sectionId}.${name}`,
      {
        id: 'label',
        label: '',
      },
      {
        id: 'year',
        label: year(financialYear, financialYears, period) || '',
      },
      {
        id: 'previousYear',
        label: previousYear(financialYear, financialYears) || '',
      }
    )
      .addRows((rows) =>
        rows
          .addRow(
            '1',
            noteLabel(
              'Karaktären på de risker och fördelar som är förknippade med innehavet och som företaget har kvar'
            ),
            ixbrlCell(value(''), {
              type: 'stringItemType',
              name: 'se-gen-base:FinansiellaTillgangarInteTagitsBortBalansrakningenTillgangenRiskFordelarBeskrivning',
              contextRef: 'balans0',
              tupleRef: tupleRef(3, `${sectionId}.${sYear}.tuple`),
            }),
            ixbrlCell(value(''), {
              type: 'stringItemType',
              name: 'se-gen-base:FinansiellaTillgangarInteTagitsBortBalansrakningenTillgangenRiskFordelarBeskrivning',
              contextRef: 'balans1',
              tupleRef: tupleRef(3, `${sectionId}.${sPreviousYear}.tuple`),
            })
          )
          .addRow(
            '2',
            noteLabel('Redovisat värde på tillgången'),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:FinansiellaTillgangarInteTagitsBortBalansrakningenTillgangenRedovisatVarde',
              contextRef: 'balans0',
              saldo: 'debit',
              tupleRef: tupleRef(4, `${sectionId}.${sYear}.tuple`),
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:FinansiellaTillgangarInteTagitsBortBalansrakningenTillgangenRedovisatVarde',
              contextRef: 'balans1',
              saldo: 'debit',
              tupleRef: tupleRef(4, `${sectionId}.${sPreviousYear}.tuple`),
            })
          )
          .addRow(
            '3',
            noteLabel(
              'Redovisat värde på skulden som är knuten till tillgången'
            ),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:FinansiellaTillgangarInteTagitsBortBalansrakningenSkuldRedovisatVarde',
              tupleRef: tupleRef(5, `${sectionId}.${sYear}.tuple`),
              saldo: 'credit',
              contextRef: 'balans0',
            }),
            ixbrlCell(value(undefined), {
              type: MONETARY_ITEM_TYPE,
              name: 'se-gen-base:FinansiellaTillgangarInteTagitsBortBalansrakningenSkuldRedovisatVarde',
              contextRef: 'balans1',
              saldo: 'credit',
              tupleRef: tupleRef(5, `${sectionId}.${sPreviousYear}.tuple`),
            })
          )
          .build()
      )
      .build();
  };

  return {
    active: false,
    number: value(undefined),
    name: value(
      'Finansiella tillgångar som inte tagits bort från balansräkningen'
    ),
    tables: {
      type: 'sections',
      sections: [],
      newSectionTemplate: (sectionId) => {
        return {
          type: ixbrlField(
            {
              type: 'string',
              value: '',
            },
            {
              type: 'stringItemType',
              name: '',
              contextRef: 'period0',
            }
          ),
          year: ixbrlField(value(''), {
            type: 'stringItemType',
            name: 'se-gen-base:FinansiellaTillgangarInteTagitsBortBalansrakningenTillgangensTyp',
            tuple: {
              name: 'se-gaap-ext:FinansiellaTillgangarInteTagitsBortBalansrakningenTuple',
              id: `${sectionId}.year.tuple`,
              tupleID: `${sectionId}.year.tuple`,
            },
            tupleRef: tupleRef(1, `${sectionId}.year.tuple`),
            contextRef: 'balans0',
          }),
          previousYear: ixbrlField(value(''), {
            type: 'stringItemType',
            name: 'se-gen-base:FinansiellaTillgangarInteTagitsBortBalansrakningenTillgangensTyp',
            tuple: {
              name: 'se-gaap-ext:FinansiellaTillgangarInteTagitsBortBalansrakningenTuple',
              id: `${sectionId}.previousYear.tuple`,
              tupleID: `${sectionId}.previousYear.tuple`,
            },
            tupleRef: tupleRef(2, `${sectionId}.previousYear.tuple`),
            contextRef: 'balans1',
          }),
          table: getTable(sectionId, 'year', 'previousYear', 'table'),
        };
      },
    },
    commentLabel: field('Kommentar till not'),
    comment: ixbrlField(active(field('')), {
      type: 'stringItemType',
      name: 'se-gen-base:NotFinansiellaTillgangarInteTagitsBortBalansrakningenKommentar',
      contextRef: 'period0',
    }),
    group: value(undefined),
    section: value(undefined),
    validForReportType: {
      k2: false,
      k3: true,
    },
  };
};

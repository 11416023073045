import {
  account,
  max,
  multiply,
  not,
  ref,
  round,
  sum,
  value,
} from '@agoy/document';
import { table } from '../../common/utils/util';
import { DigitalSubmission } from './types';

export const digitalSubmissionConfig = (): DigitalSubmission => ({
  active: undefined,
  main: {
    active: undefined,
    signature: table(
      'digitalSubmission.signature',
      'fullName',
      'socialSecurityNumber',
      'email',
      'role'
    )
      .addRows((rows) =>
        rows.addRow('1', value(''), value(''), value(''), value('')).build()
      )
      .build(),
    needsAuditReport: {
      // Only using the active attribute for now. Pure boolean field do not seem to work.
      type: 'boolean',
      value: !!ref(
        not(
          not(
            multiply(
              round(max(sum(account('3000:3999'), -3000000), 0)),
              round(max(sum(account('1000:1999'), -1500000), 0))
            )
          )
        )
      ).value,
    },
  },
});

import { AgoyDocumentStructure } from '../document';
import splitId from './helpers/splitId';
import updateDocument from './helpers/updateDocument';
import { OperationResult, State } from './types';

const deleteAnnualReportSection = <T extends AgoyDocumentStructure>(
  structure: T,
  state: State<T>,
  id: string
): OperationResult<T> => {
  const keys = splitId(id);
  const sectionId = keys[keys.length - 1];
  const sectionIndex = parseInt(sectionId, 10);

  if (typeof sectionIndex !== 'number' || isNaN(sectionIndex)) {
    return 'INVALID_ID';
  }
  const parentId = keys.slice(0, keys.length - 1).join('.');

  return updateDocument(structure, state, parentId, {
    array: (key, arrayId, props) => {
      const sections = [...props.node.sections];
      sections[sectionIndex] = null;
      return {
        ...props,
        node: { ...props.node, sections },
        changes: {
          ...props.changes,
          [sectionIndex]: null,
        },
      };
    },
  });
};

export default deleteAnnualReportSection;

import React from 'react';
import { useIntl } from 'react-intl';
import styled from '@emotion/styled';
import Typography from '../../UI/Typography/Typography';
import { Typography as MuiTypography } from '@material-ui/core';
import {
  AnnualReportType,
  AnnualReport,
  AnnualReportVersion,
} from '@agoy/annual-report-document';
import { groupBy } from '@agoy/common';

import { Note as NoteType } from './GenericNoteRender/types';
import Note1 from './ByNumber/1st/Note1';
import Note1ForK3 from './ByNumber/1st/Note1ForK3';
import OneTableThreeColumnNote from './UI/single-table/ThreeColumnNote';
import { stringValue } from '@agoy/document';
import { Note } from './UI/Note';
import IncomeStatementNotes from './IncomeStatementNotes';
import BalanceSheetNotes from './BalanceSheetNotes';
import OtherNotes from './OtherNotes';
import NoteComponent from './GenericNoteRender/Note';
import useNotesToRender from '../../utils/notes/useNotesToRender';

const Content = styled.div`
  @media screen {
    min-width: 100%;
  }

  @media print {
    break-before: avoid;
  }
`;

interface NotesPageProps {
  annualReport: AnnualReport;
  reportType: AnnualReportType;
  isDigitalSubmission: boolean;
  isXBRLRender?: boolean;
  annualReportVersion: AnnualReportVersion;
}

// k3 and AR version higher than 1 are the conditions

const NotesPage = ({
  annualReport,
  reportType,
  isDigitalSubmission,
  isXBRLRender = false,
  annualReportVersion,
}: NotesPageProps): JSX.Element => {
  const { formatMessage } = useIntl();

  const { notes, settings } = annualReport;
  const {
    section: { previousPeriodEnd },
  } = settings;

  const hasPreviousYear = !!previousPeriodEnd.value;

  const filteredAndSortedNotes = useNotesToRender(
    notes,
    ([_, note]) => stringValue(note.group) !== undefined
  );

  /**
   * Group notes to render:
   * - income statement
   * - balance sheet
   * - others
   *
   * By numbering rules notes are already implicitly sorted by group, so no need to re-sort each group
   */
  const groupedNotes = groupBy(filteredAndSortedNotes, ([_, note]) =>
    stringValue(note.group)
  );

  const Note1Component =
    annualReportVersion !== '1' && reportType === 'k3' ? Note1ForK3 : Note1;

  if (annualReportVersion === '2') {
    const notesToRender: [string, NoteType][] = [
      ...(groupedNotes.get('') ?? []),
      ...(groupedNotes.get('incomeStatement') ?? []),
      ...(groupedNotes.get('balanceSheet') ?? []),
      ...(groupedNotes.get('other') ?? []),
    ];

    return (
      <div>
        <Typography variant="h1">
          {formatMessage({
            id: 'annualReport.menu.notes',
          })}
        </Typography>
        {notesToRender.map(([noteId, note]) => (
          <NoteComponent
            key={noteId}
            noteId={noteId}
            note={
              noteId.startsWith('custom')
                ? notes.custom.sections[noteId.split('-')[1]]
                : note
            }
            hasPreviousYear={hasPreviousYear}
          />
        ))}
      </div>
    );
  }

  return (
    <Content id="notes">
      <MuiTypography variant="h1">
        {formatMessage({
          id: 'annualReport.menu.notes',
        })}
      </MuiTypography>
      {/* Except for note1 and custom note, all notes should be children of one of the following
      three ToggleTitle components */}
      <Note1Component
        isDigitalSubmission={isDigitalSubmission}
        notes={notes}
        reportType={reportType}
      />
      {/* Note56 is like Note1 it does not belong to balance sheet or income statement and is specific to individual company */}
      {'note56' in notes && 'table' in notes.note56 && (
        <Note id="note56">
          <OneTableThreeColumnNote
            noteId="note56"
            {...notes.note56}
            title={stringValue(notes.note56.name) || ''}
            hasPreviousYear={hasPreviousYear}
            commentLabel={notes.note56.commentLabel}
          />
        </Note>
      )}
      {/* Noter till resultaträkning */}
      <IncomeStatementNotes
        notes={notes}
        incomeStatementNotes={groupedNotes.get('incomeStatement') ?? []}
        hasPreviousYear={hasPreviousYear}
        isDigitalSubmission
        isXBRLRender={isXBRLRender}
      />

      {/* Noter till balansräkning */}
      <BalanceSheetNotes
        notes={notes}
        balanceSheetNotes={groupedNotes.get('balanceSheet') ?? []}
        hasPreviousYear={hasPreviousYear}
        isDigitalSubmission={isDigitalSubmission}
        isXBRLRender={isXBRLRender}
      />

      {/* Warning: All notes from BR and RR should be added here as well */}
      {/* And all notes specified as belonging to övriga group, should also be added in balanceSheet and IncomeStatement */}
      {/* Övriga noter */}
      <OtherNotes
        notes={notes}
        otherNotes={groupedNotes.get('other') ?? []}
        hasPreviousYear={hasPreviousYear}
        isDigitalSubmission={isDigitalSubmission}
        isXBRLRender={isXBRLRender}
      />
    </Content>
  );
};

export default NotesPage;

import { Transaction } from '_shared/types';

export const transactionMangling = (transactions: any[]): Transaction[] => {
  const retTrans: Transaction[] = [];
  transactions.forEach((t) => {
    retTrans.push({
      account: String(t.accountNumber),
      amount: t.debit + t.credit,
      dimText: t.objects ? t.objects.map((o) => o.name) : [],
      saldo: t.balance,
      series: t.voucher.series,
      transactionDate: t.date,
      transactionText: t.text,
      verificationDate: t.voucher.regdate,
      verificationNumber: String(t.voucher.number),
      verificationText: t.voucher.description || '',
      voucherId: t.voucher.id || null,
    });
  });
  return retTrans;
};

export const PAGE_SIZE = 10;

export const paginateArray = ({
  pageNumber,
  pageSize = PAGE_SIZE,
  array,
}: {
  pageNumber: number;
  pageSize?: number;
  array: any[];
}) => {
  return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
};
export interface SkatteverketTransactionFilter {
  periods: { [period: string]: boolean };
}

export type VoucherTransactionType = 'debit' | 'credit';
// VoucherSourceType should match server's ENUM type
export type VoucherSourceType = 'fortnox' | 'visma' | 'agoy';
export type VoucherTransactionKind = 'KS' | 'PR';

export enum VoucherSeriesType {
  VOUCHER_SERIES_I = 'I',
  VOUCHER_SERIES_A = 'A',
}

export interface Voucher {
  id?: number;
  series: string;
  serNumber?: number;
  date?: string;
  locked?: false;
  sourceComments?: string;
  transDate: string;
  source: VoucherSourceType;
  description?: string;
  transactions: VoucherTransaction[];
  attachments?: {
    id: number;
    name: string;
    path?: string;
  }[];
  preliminary?: boolean;
  reference?: ReferencesTypes | null;
}

export interface VoucherTransaction {
  id: number | string;
  sourceInfo: string;
  kind: string;
  typeAmount: VoucherTransactionType;
  amount: number;
  account: number;
  pr?: number;
  ks?: number;
  debit: number;
  credit: number;
  editing?: boolean;
}

export interface AccountData {
  number: number;
  name: string;
  active: boolean;
}

export interface AccountPlanList {
  value: number;
  label: string;
  noActive: boolean;
}

export interface VoucherSeries {
  series: string;
  label: string;
}

export enum VoucherStatusType {
  SUGGESTION = 'suggestion',
  PRELIMINARY = 'preliminary',
  FINAL = 'final',
}
export enum ReferencesTypes {
  GLIDER = 'glider',
  LAGERJUSTERING = 'lagerjustering',
  ACCRUAL_FUNDS = 'accrual_funds',
  YEAR_TAX = 'year_tax',
  YEAR_RESULT = 'year_result',
  PARTICULAR_SALARY_TAX = 'particular_salary_tax',
  PROPERTY_TAX = 'property_tax',
  PROPERTY_TAX_PART_2 = 'property_tax_part_2',
  OTHER_ADJUSTMENTS = 'other_adjustments',
  GROUP_CONTRIBUTION = 'group_contribution',
  INTANGIBLE_ASSETS = 'intangible_assets',
  BUILDINGS_AND_GROUND_FACILITIES = 'buildings_and_ground_facilities',
  MACHINES_AND_INVENTORY = 'machines_and_inventory',
}

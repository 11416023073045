import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import styled from '@emotion/styled';
import { TextField, Popover, IconButton } from '@material-ui/core';
import { useIntl } from 'react-intl';
import CancelIcon from '@material-ui/icons/Cancel';

import { getClasses } from '@agoy/common';
import { numberValue, stringValue } from '@agoy/document';
import Button from '_shared/components/Buttons/Button';
import AnnualReportViewServiceContext from '_annual-report/service/AnnualReportViewServiceContext';
import When from '_shared/components/When/When';
import Typography from '_shared/components/Typography/Typography';
import Checkbox from '_shared/components/Controls/Checkbox';
import { GroupedNotes, NoteItem } from '../../../../../utils/notesUtils/types';
import { filterNotes } from '../../../../../utils/notesUtils/utils';

const SearchListItem = styled.div`
  display: flex;
  align-items: center;
  padding: 0;
  background-color: white;
  border-bottom: 1px solid ${({ theme }) => theme.palette.border.darkGreen};

  button {
    position: absolute;
    align-self: center;
    right: 16px;
  }
`;

const StyledPopover = styled(Popover)`
  .MuiPaper-root {
    width: 500px;
    max-height: calc(100vh - 160px);
    border-radius: 8px;
    display: grid;
    grid-template-rows: 40px 1fr 58px;
    overflow: hidden;
  }

  p,
  h4 {
    margin: 0;
  }
`;

const SearchField = styled(TextField)`
  width: 100%;

  .MuiInput-underline {
    :hover:before,
    :after {
      border: none;
    }
  }

  input {
    box-sizing: border-box;
    height: 40px;
    padding: 8px 16px;
    font-family: 'Exo 2';
    font-size: 14px;
  }
`;

const NoResultText = styled.div`
  padding: ${({ theme }) => `${theme.spacing(1)}px ${theme.spacing(2)}px`};
`;

const ListContainer = styled.div`
  overflow-y: scroll;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => `${theme.spacing(1.5)}px ${theme.spacing(1)}px`};
  box-shadow: 0 -1px 0 ${({ theme }) => theme.palette.border.light} inset;

  :not(.disabled, .heading) {
    cursor: pointer;
    :hover {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }

  &.heading {
    position: sticky;
    z-index: 1;
    top: 0;
    background-color: white;
  }
`;

const ButtonsContainer = styled.div`
  background-color: white;
  padding: 8px 16px;
  border-top: 2px solid #dde1e0;
  display: flex;
  column-gap: 8px;
`;

interface SelectPopoverProps {
  selectedNotes: string[];
  noteGroups: {
    incomeStatementNotes: NoteItem[];
    balanceSheetNotes: NoteItem[];
    otherNotes: NoteItem[];
  };
  open: boolean;
  onClose: () => void;
  onChange: (newValue: string[]) => void;
}

interface SelectPopoverGroupProps {
  groupId: string;
  selectedNotes: string[];
  notes: NoteItem[];
  onSelect: (value: string[]) => void;
}

const SelectPopoverGroup = memo(
  ({ groupId, selectedNotes, notes, onSelect }: SelectPopoverGroupProps) => {
    const { formatMessage } = useIntl();

    const handleSelect = useCallback(
      (id: string) => {
        const updatedSelectedNotes = [...selectedNotes];
        const noteIndex = selectedNotes.indexOf(id);

        if (noteIndex !== -1) {
          updatedSelectedNotes.splice(noteIndex, 1);
        } else {
          updatedSelectedNotes.push(id);
        }

        onSelect(updatedSelectedNotes);
      },
      [onSelect, selectedNotes]
    );

    return (
      <>
        <When isTrue={!!notes.length}>
          <Row className="heading">
            <Typography variant="h4" textStyle="bold" color="placeholder">
              {formatMessage({ id: `notes.${groupId}` })}
            </Typography>
          </Row>
        </When>

        {notes.map(({ id, note, disabled }) => {
          const noteNumber = numberValue(note.number) || '';
          return (
            <Row
              className={getClasses({ disabled })}
              onClick={() => !disabled && handleSelect(id)}
              key={id}
            >
              <Checkbox
                checked={selectedNotes.includes(id)}
                disabled={disabled}
              >
                <span style={{ fontWeight: 700 }}>
                  {noteNumber ? `${noteNumber}. ` : ''}
                </span>
                {stringValue(note.name) || ''}
              </Checkbox>
            </Row>
          );
        })}
      </>
    );
  }
);

const SelectPopover = memo(
  ({
    open,
    selectedNotes,
    noteGroups,
    onClose,
    onChange,
  }: SelectPopoverProps) => {
    const { formatMessage } = useIntl();

    const [searchValue, setSearchValue] = useState('');
    const [noteDisplayGroups, setNotesDisplayGroups] =
      useState<GroupedNotes>(noteGroups);

    useEffect(() => {
      const notes = searchValue
        ? {
            incomeStatementNotes: filterNotes(
              noteGroups.incomeStatementNotes,
              searchValue
            ),
            balanceSheetNotes: filterNotes(
              noteGroups.balanceSheetNotes,
              searchValue
            ),
            otherNotes: filterNotes(noteGroups.otherNotes, searchValue),
          }
        : noteGroups;

      setNotesDisplayGroups(notes);
    }, [noteGroups, searchValue]);

    const handleSearchChange = useCallback((event) => {
      setSearchValue(event.target.value.toLocaleLowerCase());
    }, []);

    const handleSelect = useCallback(
      (updatedSelectedNotes) => {
        onChange(updatedSelectedNotes.map((item) => `id(${item}.number)`));
      },
      [onChange]
    );

    const handleUnselect = useCallback(() => {
      onChange([]);
    }, [onChange]);

    return (
      <StyledPopover
        open={open}
        anchorReference="anchorPosition"
        anchorPosition={{ top: 140, left: 9999 }}
        disableRestoreFocus
        onClose={onClose}
      >
        <SearchListItem>
          <SearchField
            placeholder={formatMessage({
              id: 'annualReport.menu.notes.filter',
            })}
            value={searchValue}
            onChange={handleSearchChange}
            autoFocus
          />
          <When isTrue={!!searchValue}>
            <IconButton size="small" onClick={() => setSearchValue('')}>
              <CancelIcon />
            </IconButton>
          </When>
        </SearchListItem>

        <ListContainer>
          <When
            isTrue={
              !noteDisplayGroups.incomeStatementNotes.length &&
              !noteDisplayGroups.balanceSheetNotes.length &&
              !noteDisplayGroups.otherNotes.length
            }
          >
            <NoResultText>
              <Typography>
                {formatMessage({
                  id: 'annualReport.menu.notes.noResult',
                })}
              </Typography>
            </NoResultText>
          </When>

          {Object.keys(noteDisplayGroups).map((group) => (
            <SelectPopoverGroup
              key={group}
              groupId={group}
              notes={noteDisplayGroups[group]}
              selectedNotes={selectedNotes}
              onSelect={handleSelect}
            />
          ))}
        </ListContainer>

        <ButtonsContainer>
          <Button
            onClick={onClose}
            label={formatMessage({
              id: 'annualReport.menu.notes.close',
            })}
          />
          <When isTrue={!searchValue}>
            <Button
              variant="text"
              onClick={handleUnselect}
              label={formatMessage({
                id: 'annualReport.menu.notes.reset',
              })}
            />
          </When>
        </ButtonsContainer>
      </StyledPopover>
    );
  }
);

export default SelectPopover;

import React, { useContext } from 'react';
import DocumentViewServiceContext from '_shared/services/document/DocumentViewServiceContext';
import ResetButton from '_shared/components/ResetContent/ResetContentButton';

interface ResetContentButtonProps {
  className?: string;
  id: string;
  what: string;
  disabled?: boolean;
  onReset?: () => void;
}

const ResetContentButton = ({
  className,
  id,
  what,
  disabled = false,
  onReset,
}: ResetContentButtonProps) => {
  const service = useContext(DocumentViewServiceContext);

  const onConfirm = async () => {
    await service.resetContent(id);
    if (onReset) {
      onReset();
    }
  };

  return (
    <ResetButton
      className={className}
      what={what}
      disabled={disabled}
      onReset={onConfirm}
    />
  );
};

export default ResetContentButton;

import React from 'react';
import StringInput from './StringInput';

interface StringFieldProps {
  className?: string;
  value: string;
  disabled?: boolean;
}

const StringField = ({
  className,
  value,
  disabled,
}: StringFieldProps): JSX.Element => {
  return (
    <StringInput className={className} value={value} disabled={disabled} />
  );
};

export default StringField;

import { SelectFormat, value } from '@agoy/document';
import { NoteOneTableSection, IxbrlCell } from '../../../../common';
import {
  field,
  ixbrlCell,
  ixbrlField,
  table,
  tupleRef,
} from '../../../../common/utils/util';

const parentCompanyInputFormat: SelectFormat = {
  type: 'select',
  options: [
    {
      value: 'se-mem-base:ForetagTypModerforetagIStorstaKoncernenMember',
      label: 'Moderföretag i den största koncernen',
    },
    {
      value:
        'se-mem-base:ForetagTypModerforetagNarmasteUpprattarKoncernredovisningMember',
      label: 'Närmaste moderföretag som upprättar koncernredovisning',
    },
    {
      value: 'se-mem-base:ForetagTypModerforetagMember',
      label: 'Moderföretag',
    },
  ],
};

export const getNote88 = (documentTypeVersion = '1'): NoteOneTableSection => {
  return {
    active: false,
    number: value(undefined),
    name: value('Upplysning om moderföretag'),
    table: table<IxbrlCell>(
      'notes.note88.table',
      {
        id: 'typeOfParentCompany',
        label: 'Typ av moderföretag',
      },
      {
        id: 'companyName',
        label: 'Företagets namn',
      },
      {
        id: 'orgNumber',
        label: 'Organisationsnummer',
      },
      {
        id: 'seating',
        label: 'Säte',
      }
    )
      .addRows((rows) =>
        rows
          .addRow(
            'parentCompany',
            ixbrlCell(
              {
                type: 'string',
                value: 'Moderföretag i den största koncernen',
                format: parentCompanyInputFormat,
              },
              {
                type: 'stringItemType',
                name: 'se-gen-base:ModerforetagTypList',
                standardRubrik: 'Typ av moderföretag (Vallista)',
                contextRef: 'period0',
                tuple: {
                  name: 'se-gaap-ext:UppgiftModerforetagTuple',
                  id: `${rows.getBaseId()}.parentCompany.tuple`,
                  tupleID: `${rows.getBaseId()}.parentCompany.tuple`,
                },
                tupleRef: tupleRef(
                  1,
                  `${rows.getBaseId()}.parentCompany.tuple`
                ),
              }
            ),
            ixbrlCell(value(''), {
              type: 'stringItemType',
              name: 'se-cd-base:ForetagetsNamn',
              contextRef: 'period0',
              tupleRef: tupleRef(2, `${rows.getBaseId()}.parentCompany.tuple`),
            }),
            ixbrlCell(value(''), {
              type: 'stringItemType',
              name: 'se-cd-base:Organisationsnummer',
              contextRef: 'period0',
              tupleRef: tupleRef(3, `${rows.getBaseId()}.parentCompany.tuple`),
            }),
            ixbrlCell(value(''), {
              type: 'stringItemType',
              name: 'se-cd-base:ForetagetsSate',
              contextRef: 'period0',
              tupleRef: tupleRef(4, `${rows.getBaseId()}.parentCompany.tuple`),
            })
          )
          .build()
      )
      .newRowTemplateGenerator((id, baseId) => ({
        id,
        active: true,
        cells: {
          typeOfParentCompany: ixbrlCell(
            {
              type: 'string',
              value: 'Moderföretag i den största koncernen',
              format: parentCompanyInputFormat,
            },
            {
              type: 'stringItemType',
              name: 'se-gen-base:ModerforetagTypList',
              contextRef: 'period0',
              standardRubrik: 'Typ av moderföretag (Vallista)',
              tuple: {
                name: 'se-gaap-ext:UppgiftModerforetagTuple',
                id: `${baseId}.${id}.tuple`,
                tupleID: `${baseId}.${id}.tuple`,
              },
              tupleRef: tupleRef(1, `${baseId}.${id}.tuple`),
            }
          ),
          companyName: ixbrlCell(value(''), {
            type: 'stringItemType',
            name: 'se-cd-base:ForetagetsNamn',
            contextRef: 'period0',
            standardRubrik: 'Företagets nuvarande namn',
            tupleRef: tupleRef(2, `${baseId}.${id}.tuple`),
          }),
          orgNumber: ixbrlCell(value(''), {
            type: 'stringItemType',
            name: 'se-cd-base:Organisationsnummer',
            contextRef: 'period0',
            tupleRef: tupleRef(3, `${baseId}.${id}.tuple`),
          }),
          seating: ixbrlCell(value(''), {
            type: 'stringItemType',
            name: 'se-cd-base:ForetagetsSate',
            contextRef: 'period0',
            tupleRef: tupleRef(4, `${baseId}.${id}.tuple`),
          }),
        },
      }))
      .build(),
    commentLabel: field('Kommentar'),
    comment: ixbrlField(field(''), {
      type: 'stringItemType',
      name: 'se-gen-base:NotLangfristigaSkulderKommentar',
      contextRef: 'balans0',
    }),
    group: value(undefined),
    section: value(undefined),
    validForReportType: {
      k2: false,
      k3: documentTypeVersion !== '1',
    },
  };
};

import {
  resolveAnnualReport,
  applyReportChanges,
  config as annualReportConfig,
  references as annualReportBaseReferences,
  getContentDefinition,
  annualReportChangesCodec,
} from '@agoy/annual-report-document';
import {
  ResolveReferenceInput,
  collectResolvedValues,
  TimePeriod,
  mapAccountsToReferenceAccountInformation,
  AccountingBalancesAccountResolver,
} from '@agoy/document';

import { DocumentValues } from '_tax/types';

import { ClientCompanyType } from '_clients/types/types';
import {
  taxCalculation,
  getTaxConfig,
  TaxView,
  TaxCalculationRow,
  TaxCalculationInput,
} from '@agoy/tax-document';

import { mapRecord, filterRecord } from 'utils/records';
import { getAccountingBalances } from 'Api/Client/accounting';
import { AccountingBalancesResult } from 'types/Accounting';
import { getApiSdk } from 'api-sdk';
import { getContext } from 'utils/AgoyAppClient/contextHolder';
import { parseFinancialYear, parseFormat } from '@agoy/dates';
import { add, format, isBefore, startOfMonth } from 'date-fns';
import { isLeft } from 'fp-ts/lib/Either';

const generatePeriods = (financialYear: string): string[] => {
  const { start, end } = parseFinancialYear(financialYear);

  const periods: string[] = [];

  let startDate = start;
  while (isBefore(startDate, startOfMonth(end))) {
    periods.push(format(startDate, 'yyyyMMdd'));
    startDate = add(startDate, { months: 1 });
  }

  periods.push(format(startOfMonth(end), 'yyyyMMdd'));

  return periods;
};

export const collectTaxViewValues = (
  taxView: TaxView
): Record<string, number> => {
  return filterRecord(
    mapRecord(taxView.rowsById, (row: TaxCalculationRow) => row.value),
    (value): value is number => typeof value === 'number'
  );
};

export const getInitialTaxDeclarationData = async (
  customer: ClientCompanyType,
  financialYear: string
): Promise<
  [DocumentValues | null, TaxView | null, AccountingBalancesResult | null]
> => {
  const clientId = customer.id;
  const companyType = customer.type;
  const periods = generatePeriods(financialYear);

  const api = await getApiSdk(getContext());

  const [accountingBalancesResult, reportChangesResponse, taxConfig] =
    await Promise.all([
      getAccountingBalances(clientId, financialYear),
      api.getAnnualReportChanges({ clientid: clientId, financialYear }),
      getTaxConfig(
        (clientid, finYear) =>
          api.getClientsTax({ clientid, financialYear: finYear }),
        clientId,
        financialYear,
        periods,
        companyType
      ),
    ]);

  if (accountingBalancesResult === null) {
    return [null, null, null];
  }
  const { accountingBalances, updatedAt } = accountingBalancesResult;

  const unvalidatedReportChanges = reportChangesResponse.changes;

  // Validate the changes, especially the documentConfiguration which in
  // the OpenAPI is a bit flexible in the typing.
  // To get the documentType and version right we use io-ts for checking.
  const decoded = annualReportChangesCodec.decode(unvalidatedReportChanges);
  if (isLeft(decoded)) {
    throw new Error('Invalid changes');
  }
  const reportChanges = decoded.right;

  const annualConfig = annualReportConfig(
    customer,
    TimePeriod.fromFinancialYear(financialYear),
    null,
    mapAccountsToReferenceAccountInformation(accountingBalances.accounts),
    decoded.right.documentConfiguration,
    false
  );

  const annualReport = applyReportChanges(
    annualConfig,
    reportChanges,
    decoded.right.documentConfiguration.reportType
  );

  const { start, end } = parseFinancialYear(financialYear);
  const startDate = parseFormat(start, 'yyyyMMdd');
  const endDate = parseFormat(end, 'yyyyMMdd');
  const period = new TimePeriod(startDate, endDate);
  const financialYearObj = accountingBalances.financialYears.find(
    (y) => y.id === accountingBalances.periods[0].financialYearId
  );

  if (!financialYearObj) {
    console.warn('Failed to find the financial year');
    return [null, null, null];
  }

  const accountResolver = new AccountingBalancesAccountResolver(
    financialYearObj.id,
    accountingBalances.periods,
    accountingBalances.accounts
  );

  const input: ResolveReferenceInput = {
    accountResolver,
    defaultPeriod: period,
    periods: { year: period },
  };

  const annualReportWithValues = resolveAnnualReport(
    annualReport,
    annualReportBaseReferences(reportChanges.documentConfiguration),
    input,
    reportChanges.documentConfiguration.reportType
  );

  const financialYearPeriod = TimePeriod.fromDates(
    financialYearObj.start,
    financialYearObj.end,
    'yyyy-MM-dd'
  );

  const taxInput: TaxCalculationInput = {
    accountResolver,
    defaultPeriod: period,
    periods: { financialYear: financialYearPeriod },
    sieUpdated: new Date(updatedAt).toISOString(),
    lastPeriod: true,
    yearPercentage: 1,
  };

  const taxView = taxCalculation(taxConfig, taxInput, null, {});

  return [
    collectResolvedValues(
      getContentDefinition(
        reportChanges.documentConfiguration,
        decoded.right.documentConfiguration.reportType
      ),
      annualReportWithValues
    ),
    taxView,
    accountingBalancesResult,
  ];
};

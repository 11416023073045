import {
  id,
  sum,
  or,
  ref,
  value,
  refs,
  ReferenceAccountInformation,
  TimePeriod,
} from '@agoy/document';
import { BalanceSheet } from '../../..';
import { addBalanceSheetGroup, addPost } from '../../common/utils/accountsUtil';
import {
  table,
  RowsBuilder,
  SectionRow,
  getLinkToNote,
} from '../../common/utils/util';
import { getWarningCell } from '../settings/economicAssociationSettings';

type BalanceSheetSectionReferences =
  | 'intangibleAssets'
  | 'tangibleFixedAssets'
  | 'financialAssets'
  | 'inventory'
  | 'shortTermReceivables'
  | 'cashAndBankBalances'
  | 'shortTermInvestments'
  | 'restrictedEquity'
  | 'unrestrictedEquity'
  | 'untaxedReserves'
  | 'allocations'
  | 'longTermLiabilities'
  | 'shortTermLiabilities'
  | 'unassignedAccounts';

export type EconomicAssociationBalanceSheetStructure = {
  [key in BalanceSheetSectionReferences]: SectionRow[];
};

export const economicAssociationBalanceSheetAccounts: EconomicAssociationBalanceSheetStructure =
  {
    intangibleAssets: [
      {
        row: '1',
        name: 'Koncessioner, patent, licenser, varumärken samt liknande rättigheter',
        accounts: [
          [1000, 1059],
          [1080, 1087],
          [1089, 1089],
        ],
        noteNumber: 6,
      },
      {
        row: '2',
        name: 'Hyresrätter och liknande rättigheter',
        accounts: [[1060, 1069]],
        noteNumber: 7,
      },
      {
        row: '3',
        name: 'Goodwill',
        accounts: [[1070, 1079]],
        noteNumber: 8,
      },
      {
        row: '4',
        name: 'Förskott avseende immateriella anläggningstillgångar',
        accounts: [[1088, 1088]],
        noteNumber: 9,
      },
      {
        row: '5',
        name: 'Övriga immateriella anläggningstillgångarr',
        accounts: [[1090, 1099]],
        noteNumber: 42,
      },
    ],
    tangibleFixedAssets: [
      {
        row: '1',
        name: 'Byggnader och mark',
        accounts: [
          [1100, 1119],
          [1130, 1179],
          [1190, 1199],
        ],
        noteNumber: 10,
      },
      {
        row: '2',
        name: 'Maskiner och andra tekniska anläggningar',
        accounts: [[1200, 1219]],
        noteNumber: 11,
      },
      {
        row: '3',
        name: 'Inventarier, verktyg och installationer',
        accounts: [[1220, 1279]],
        noteNumber: 12,
      },
      {
        row: '4',
        name: 'Förbättringsutgifter på annans fastighet',
        accounts: [[1120, 1129]],
        noteNumber: 13,
      },
      {
        row: '5',
        name: 'Pågående nyanläggningar och förskott avseende materiella anläggningstillgångar',
        accounts: [
          [1180, 1189],
          [1280, 1289],
        ],
        noteNumber: 15,
      },
      {
        row: '6',
        name: 'Övriga materiella anläggningstillgångar',
        accounts: [[1290, 1299]],
        noteNumber: 14,
      },
    ],
    financialAssets: [
      {
        row: '1',
        name: 'Andelar i koncernföretag',
        accounts: [[1300, 1319]],
      },
      {
        row: '2',
        name: 'Fordringar hos koncernföretag',
        accounts: [[1320, 1329]],
      },
      {
        row: '3',
        name: 'Andelar i intresseföretag och gemensamt styrda företag',
        accounts: [
          [1330, 1335],
          [1338, 1339],
        ],
      },
      {
        row: '4',
        name: 'Fordringar hos intresseföretag och gemensamt styrda företag',
        accounts: [
          [1340, 1345],
          [1348, 1349],
        ],
      },
      {
        row: '5',
        name: 'Ägarintressen i övriga företag',
        accounts: [[1336, 1337]],
      },
      {
        row: '6',
        name: 'Fordringar hos övriga företag som det finns ett ägarintresse i',
        accounts: [[1346, 1347]],
      },
      {
        row: '7',
        name: 'Andra långfristiga värdepappersinnehav',
        accounts: [[1350, 1359]],
      },
      {
        row: '8',
        name: 'Lån till delägare eller närstående',
        accounts: [[1360, 1369]],
        noteNumber: 23,
      },
      {
        row: '9',
        name: 'Andra långfristiga fordringar',
        accounts: [[1380, 1389]],
      },
    ],
    inventory: [
      {
        row: '1',
        name: 'Råvaror och förnödenheter',
        accounts: [[1400, 1431]],
      },
      {
        row: '2',
        name: 'Varor under tillverkning',
        accounts: [[1432, 1449]],
      },
      {
        row: '3',
        name: 'Färdiga varor och handelsvaror',
        accounts: [[1450, 1469]],
      },
      {
        row: '4',
        name: 'Pågående arbete för annans räkning',
        accounts: [[1470, 1479]],
        noteNumber: 25,
      },
      {
        row: '5',
        name: 'Förskott till leverantörer',
        accounts: [[1480, 1489]],
      },
      {
        row: '6',
        name: 'Övriga lagertillgångar',
        accounts: [[1490, 1499]],
      },
    ],
    shortTermReceivables: [
      {
        row: '1',
        name: 'Kundfordringar',
        accounts: [
          [1500, 1559],
          [1580, 1589],
        ],
      },
      {
        row: '2',
        name: 'Fordringar hos koncernföretag',
        accounts: [
          [1660, 1669],
          [1560, 1569],
        ],
      },
      {
        row: '3',
        name: 'Fordringar hos intresseföretag och gemensamt styrda företag',
        accounts: [
          [1570, 1572],
          [1574, 1579],
          [1670, 1672],
          [1674, 1679],
        ],
      },
      {
        row: '4',
        name: 'Fordringar hos övriga företag som det finns ett ägarintresse i',
        accounts: [
          [1573, 1573],
          [1673, 1673],
        ],
      },
      {
        row: '5',
        name: 'Aktuell skattefordran',
        accounts: [[1640, 1640]],
      },
      {
        row: '6',
        name: 'Övriga fordringar',
        accounts: [
          [1590, 1619],
          [1630, 1659],
          [1680, 1689],
        ],
      },
      {
        row: '7',
        name: 'Upparbetad men ej fakturerad intäkt',
        accounts: [[1620, 1629]],
      },
      {
        row: '8',
        name: 'Förutbetalda kostnader och upplupna intäkter',
        accounts: [[1700, 1799]],
      },
    ],
    shortTermInvestments: [
      {
        row: '1',
        name: 'Andelar i koncernföretag',
        accounts: [[1860, 1869]],
      },
      {
        row: '2',
        name: 'Övriga kortfristiga placeringar',
        accounts: [
          [1800, 1859],
          [1870, 1899],
        ],
      },
    ],
    cashAndBankBalances: [
      {
        row: '1',
        name: 'Kassa och bank',
        accounts: [[1900, 1989]],
      },
      {
        row: '2',
        name: 'Redovisningsmedel',
        accounts: [[1990, 1999]],
      },
    ],
    restrictedEquity: [
      {
        row: '1',
        name: 'Medlemsinsatser',
        accounts: [[2083, 2083]],
      },
      {
        row: '2',
        name: 'Förlagsinsatser',
        accounts: [[2084, 2084]],
      },
      {
        row: '3',
        name: 'Uppskrivningsfond',
        accounts: [[2085, 2085]],
        noteNumber: 26,
      },
      {
        row: '4',
        name: 'Reservfond',
        accounts: [[2086, 2086]],
      },
      {
        row: '5',
        name: 'Insatsemission',
        accounts: [[2087, 2087]],
      },
      {
        row: '6',
        name: 'Fond för yttre underhåll',
        accounts: [[2088, 2088]],
      },
    ],
    unrestrictedEquity: [
      {
        row: '1',
        name: 'Balanserat resultat',
        accounts: [[2090, 2098]],
      },
      {
        row: '2',
        name: 'Årets resultat',
        accounts: [[2099, 2099]],
      },
    ],
    untaxedReserves: [
      {
        row: '1',
        name: 'Periodiseringsfonder',
        accounts: [[2100, 2149]],
      },
      {
        row: '2',
        name: 'Ackumulerade överavskrivningar',
        accounts: [[2150, 2159]],
      },
      {
        row: '3',
        name: 'Ersättningsfond',
        accounts: [[2160, 2164]],
      },
      {
        row: '4',
        name: 'Övriga obeskattade reserver',
        accounts: [[2190, 2199]],
      },
    ],
    allocations: [
      {
        row: '1',
        name: 'Avsättningar för pensioner och liknande förpliktelser enligt lagen om tryggande av pensionsutfästelse m.m.',
        accounts: [[2200, 2219]],
      },
      {
        row: '2',
        name: 'Övriga avsättningar för pensioner och liknande förpliktelser exklusive avsättningar enligt tryggandelagen',
        accounts: [[2230, 2239]],
      },
      {
        row: '3',
        name: 'Övriga avsättningar',
        accounts: [[2220, 2229]],
      },
    ],
    longTermLiabilities: [
      {
        row: '1',
        name: 'Obligationslån',
        accounts: [[2310, 2319]],
        noteNumber: 27,
      },
      {
        row: '2',
        name: 'Konvertibla lån',
        accounts: [[2320, 2329]],
        noteNumber: 27,
      },
      {
        row: '3',
        name: 'Checkräkningskredit',
        accounts: [[2330, 2339]],
      },
      {
        row: '4',
        name: 'Byggkreditiv',
        accounts: [[2340, 2349]],
      },
      {
        row: '5',
        name: 'Övriga skulder till kreditinstitut',
        accounts: [[2350, 2359]],
        noteNumber: 27,
      },
      {
        row: '6',
        name: 'Skulder till koncernföretag',
        accounts: [[2360, 2369]],
        noteNumber: 27,
      },
      {
        row: '7',
        name: 'Skulder till intresseföretag och gemensamt styrda företag',
        accounts: [
          [2370, 2372],
          [2374, 2379],
        ],
        noteNumber: 27,
      },
      {
        row: '8',
        name: 'Skulder till övriga företag som det finns ett ägarintresse i',
        accounts: [[2373, 2373]],
        noteNumber: 27,
      },
      {
        row: '9',
        name: 'Övriga långfristiga skulder',
        accounts: [[2390, 2399]],
        noteNumber: 27,
      },
    ],
    shortTermLiabilities: [
      {
        row: '1',
        name: 'Checkräkningskredit',
        accounts: [[2480, 2489]],
      },
      {
        row: '2',
        name: 'Övriga skulder till kreditinstitut',
        accounts: [[2400, 2419]],
      },
      {
        row: '3',
        name: 'Förskott från kunder',
        accounts: [[2420, 2429]],
      },
      {
        row: '4',
        name: 'Pågående arbete för annans räkning',
        accounts: [[2430, 2439]],
        noteNumber: 46,
      },
      {
        row: '5',
        name: 'Leverantörsskulder',
        accounts: [[2440, 2449]],
      },
      {
        row: '6',
        name: 'Fakturerad men ej upparbetad intäkt',
        accounts: [[2450, 2459]],
      },
      {
        row: '7',
        name: 'Växelskulder',
        accounts: [[2492, 2492]],
      },
      {
        row: '8',
        name: 'Skulder till koncernföretag',
        accounts: [
          [2460, 2469],
          [2860, 2869],
        ],
        noteNumber: 27,
      },
      {
        row: '9',
        name: 'Skulder till intresseföretag och gemensamt styrda företag',
        accounts: [
          [2470, 2472],
          [2474, 2479],
          [2870, 2872],
          [2874, 2879],
        ],
        noteNumber: 27,
      },
      {
        row: '10',
        name: 'Skulder till övriga företag som det finns ett ägarintresse i',
        accounts: [
          [2473, 2473],
          [2873, 2879],
        ],
        noteNumber: 27,
      },
      {
        row: '11',
        name: 'Skatteskulder',
        accounts: [[2500, 2599]],
      },
      {
        row: '12',
        name: 'Övriga skulder',
        accounts: [
          [2600, 2859],
          [2880, 2899],
        ],
      },
      {
        row: '13',
        name: 'Fakturerad men ej upparbetad intäkt',
        accounts: [[2450, 2459]],
      },
      {
        row: '14',
        name: 'Upplupna kostnader och förutbetalda intäkter',
        accounts: [[2900, 2999]],
      },
    ],
    unassignedAccounts: [
      {
        row: '1',
        name: 'Ej tilldelade konton',
        accounts: [
          [1370, 1379],
          [1390, 1399],
          [1690, 1699],
          [1690, 1699],
          [2000, 2082],
          [2089, 2089],
          [2165, 2189],
          [2240, 2309],
          [2380, 2389],
          [2490, 2491],
          [2493, 2499],
        ],
      },
    ],
  };

const attachRows = (
  accounts: Record<string, ReferenceAccountInformation>,
  switchSign: boolean,
  rows: RowsBuilder,
  previousPeriod: string,
  structureKey: keyof EconomicAssociationBalanceSheetStructure
) => {
  const add = addPost(accounts, switchSign, rows, previousPeriod);
  economicAssociationBalanceSheetAccounts[structureKey].map((section) => {
    const link = getLinkToNote(section, accounts);

    add(section.row, section.name, section.accounts, link);
  });
};

const addIntangibleAssets = (
  accounts: Record<string, ReferenceAccountInformation>,
  rows: RowsBuilder,
  previousPeriod: string
): void => {
  addBalanceSheetGroup(
    rows,
    'intangibleAssets',
    'Immateriella anläggningstillgångar',
    (rows) =>
      attachRows(accounts, false, rows, previousPeriod, 'intangibleAssets'),
    getWarningCell,
    'Summa Immateriella anläggningstillgångar'
  );
};

const addTangibleFixedAssets = (
  accounts: Record<string, ReferenceAccountInformation>,
  rows: RowsBuilder,
  previousPeriod: string
): void => {
  addBalanceSheetGroup(
    rows,
    'tangibleFixedAssets',
    'Materiella anläggningstillgångar',
    (rows) =>
      attachRows(accounts, false, rows, previousPeriod, 'tangibleFixedAssets'),
    getWarningCell,
    'Summa materiella anläggningstillgångar'
  );
};

const addFinancialAssets = (
  accounts: Record<string, ReferenceAccountInformation>,
  rows: RowsBuilder,
  previousPeriod: string
): void => {
  addBalanceSheetGroup(
    rows,
    'financialAssets',
    'Finansiella anläggningstillgångar',
    (rows) =>
      attachRows(accounts, false, rows, previousPeriod, 'financialAssets'),
    getWarningCell,
    'Summa finansiella anläggningstillgångar'
  );
};

const addInventory = (
  accounts: Record<string, ReferenceAccountInformation>,
  rows: RowsBuilder,
  previousPeriod: string
): void => {
  addBalanceSheetGroup(
    rows,
    'inventory',
    'Varulager m.m.',
    (rows) => attachRows(accounts, false, rows, previousPeriod, 'inventory'),
    getWarningCell,
    'Summa Varulager m.m.'
  );
};

const addShortTermReceivables = (
  accounts: Record<string, ReferenceAccountInformation>,
  rows: RowsBuilder,
  previousPeriod: string
): void => {
  addBalanceSheetGroup(
    rows,
    'shortTermReceivables',
    'Kortfristiga fordringar',
    (rows) =>
      attachRows(accounts, false, rows, previousPeriod, 'shortTermReceivables'),
    getWarningCell,
    'Summa kortfristiga fordringar'
  );
};

const addShortTermInvestments = (
  accounts: Record<string, ReferenceAccountInformation>,
  rows: RowsBuilder,
  previousPeriod: string
): void => {
  addBalanceSheetGroup(
    rows,
    'shortTermInvestments',
    'Kortfristiga placeringar',
    (rows) =>
      attachRows(accounts, false, rows, previousPeriod, 'shortTermInvestments'),
    getWarningCell,
    'Summa kortfristiga placeringar'
  );
};

const addCashAndBankBalances = (
  accounts: Record<string, ReferenceAccountInformation>,
  rows: RowsBuilder,
  previousPeriod: string
): void => {
  addBalanceSheetGroup(
    rows,
    'cashAndBankBalances',
    'Kassa och bank',
    (rows) =>
      attachRows(accounts, false, rows, previousPeriod, 'cashAndBankBalances'),
    getWarningCell,
    'Summa kassa och bank'
  );
};

const addRestrictedEquity = (
  accounts: Record<string, ReferenceAccountInformation>,
  rows: RowsBuilder,
  previousPeriod: string
) => {
  addBalanceSheetGroup(
    rows,
    'restrictedEquity',
    'Bundet eget kapital',
    (rows) =>
      attachRows(accounts, true, rows, previousPeriod, 'restrictedEquity'),
    getWarningCell,
    'Summa bundet eget kapital'
  );
};

const addUnrestrictedEquity = (
  accounts: Record<string, ReferenceAccountInformation>,
  rows: RowsBuilder,
  previousPeriod: string
) => {
  addBalanceSheetGroup(
    rows,
    'unrestrictedEquity',
    'Fritt eget kapital',
    (rows) =>
      attachRows(accounts, true, rows, previousPeriod, 'unrestrictedEquity'),
    getWarningCell,
    'Summa fritt eget kapital'
  );
};

const addUntaxedReserves = (
  accounts: Record<string, ReferenceAccountInformation>,
  rows: RowsBuilder,
  previousPeriod: string
) => {
  addBalanceSheetGroup(
    rows,
    'untaxedReserves',
    'Obeskattade reserver',
    (rows) =>
      attachRows(accounts, true, rows, previousPeriod, 'untaxedReserves'),
    getWarningCell,
    'Summa obeskattade reserver'
  );
};

const addAllocations = (
  accounts: Record<string, ReferenceAccountInformation>,
  rows: RowsBuilder,
  previousPeriod: string
) => {
  addBalanceSheetGroup(
    rows,
    'allocations',
    'Avsättningar',
    (rows) => attachRows(accounts, true, rows, previousPeriod, 'allocations'),
    getWarningCell,
    'Summa avsättningar'
  );
};

const addLongTermLiabilities = (
  accounts: Record<string, ReferenceAccountInformation>,
  rows: RowsBuilder,
  previousPeriod: string
) => {
  addBalanceSheetGroup(
    rows,
    'longTermLiabilities',
    'Långfristiga skulder',
    (rows) =>
      attachRows(accounts, true, rows, previousPeriod, 'longTermLiabilities'),
    getWarningCell,
    'Summa långfristiga skulder'
  );
};

const addShortTermLiabilities = (
  accounts: Record<string, ReferenceAccountInformation>,
  rows: RowsBuilder,
  previousPeriod: string
) => {
  addBalanceSheetGroup(
    rows,
    'shortTermLiabilities',
    'Kortfristiga skulder',
    (rows) =>
      attachRows(accounts, true, rows, previousPeriod, 'shortTermLiabilities'),
    getWarningCell,
    'Summa kortfristiga skulder'
  );
};

const addUnassignedAccounts = (
  accounts: Record<string, ReferenceAccountInformation>,
  rows: RowsBuilder,
  previousPeriod: string
) => {
  addBalanceSheetGroup(
    rows,
    'unassignedAccounts',
    'Ej tilldelade',
    (rows) =>
      attachRows(accounts, false, rows, previousPeriod, 'unassignedAccounts'),
    getWarningCell,
    'Ej tilldelade total'
  );
};

/**
 *
 *********** CONFIG ***********
 *
 */

export const balanceSheetConfig = (
  accounts: Record<string, ReferenceAccountInformation>,
  period: TimePeriod,
  previousPeriod: TimePeriod | null,
  previousYearKey: string
): BalanceSheet => ({
  active: undefined,
  section: {
    active: undefined,
    assets: table(
      'balanceSheet.section.assets',
      'label',
      'notes',
      { id: 'year', label: period.endDateISO },
      previousPeriod
        ? {
            id: 'previousYear',
            label: previousPeriod.endDateISO,
          }
        : undefined,
      'hidden',
      'yearWarning'
    )
      .addRows((rows) =>
        rows
          .addRow('assets', value('Tillgångar'))
          .addSubRows((rows) => {
            rows
              .addRow('fixedAssets', value('Anläggningstillgångar'))
              .addSubRows((rows) => {
                // Immateriella anläggningstillgångar
                addIntangibleAssets(accounts, rows, previousYearKey);
                // Materiella anläggningstillgångar
                addTangibleFixedAssets(accounts, rows, previousYearKey);
                // Finansiella anläggningstillgångar
                addFinancialAssets(accounts, rows, previousYearKey);
                return rows.build();
              })
              .addRow(
                'sumFixedAssets',
                value('Summa anläggningstillgångar'),
                refs(),
                ref(
                  sum(
                    id(
                      `${rows.getBaseId()}.fixedAssets.intangibleAssetsSum.year`
                    ),
                    id(
                      `${rows.getBaseId()}.fixedAssets.tangibleFixedAssetsSum.year`
                    ),
                    id(
                      `${rows.getBaseId()}.fixedAssets.financialAssetsSum.year`
                    )
                  )
                ),
                ref(
                  sum(
                    id(
                      `${rows.getBaseId()}.fixedAssets.intangibleAssetsSum.previousYear`
                    ),
                    id(
                      `${rows.getBaseId()}.fixedAssets.tangibleFixedAssetsSum.previousYear`
                    ),
                    id(
                      `${rows.getBaseId()}.fixedAssets.financialAssetsSum.previousYear`
                    )
                  )
                )
              );

            rows
              .addRow('currentAssets', value('Omsättningstillgångar'))
              .addSubRows((rows) => {
                // Varulager m.m.
                addInventory(accounts, rows, previousYearKey);
                // Kortfristiga fordringar
                addShortTermReceivables(accounts, rows, previousYearKey);
                // Kortfristiga placeringar
                addShortTermInvestments(accounts, rows, previousYearKey);
                // Kassa och bank
                addCashAndBankBalances(accounts, rows, previousYearKey);

                return rows.build();
              })
              .addRow(
                'sumCurrentAssets',
                value('Summa omsättningstillgångar'),
                refs(),
                ref(
                  sum(
                    id(`${rows.getBaseId()}.currentAssets.inventorySum.year`),
                    id(
                      `${rows.getBaseId()}.currentAssets.shortTermReceivablesSum.year`
                    ),
                    id(
                      `${rows.getBaseId()}.currentAssets.shortTermInvestmentsSum.year`
                    ),
                    id(
                      `${rows.getBaseId()}.currentAssets.cashAndBankBalancesSum.year`
                    )
                  )
                ),
                ref(
                  sum(
                    id(
                      `${rows.getBaseId()}.currentAssets.inventorySum.previousYear`
                    ),
                    id(
                      `${rows.getBaseId()}.currentAssets.shortTermReceivablesSum.previousYear`
                    ),
                    id(
                      `${rows.getBaseId()}.currentAssets.shortTermInvestmentsSum.previousYear`
                    ),
                    id(
                      `${rows.getBaseId()}.currentAssets.cashAndBankBalancesSum.previousYear`
                    )
                  )
                )
              );

            return rows.build();
          })
          .addRow(
            'sumAssets',
            value('Summa tillgångar'),
            refs(),
            ref(
              or(
                sum(
                  id(`${rows.getBaseId()}.assets.fixedAssets.*Sum.year`),
                  id(`${rows.getBaseId()}.assets.currentAssets.*Sum.year`)
                ),
                0
              )
            ),
            ref(
              or(
                sum(
                  id(
                    `${rows.getBaseId()}.assets.fixedAssets.*Sum.previousYear`
                  ),
                  id(
                    `${rows.getBaseId()}.assets.currentAssets.*Sum.previousYear`
                  )
                ),
                0
              )
            )
          )
          .build()
      )
      .build(),
    equityAndLiabilities: table(
      'balanceSheet.section.equityAndLiabilities',
      'label',
      'notes',
      { id: 'year', label: period.endDateISO },
      previousPeriod
        ? {
            id: 'previousYear',
            label: previousPeriod.endDateISO,
          }
        : undefined,
      'hidden',
      'yearWarning'
    )
      .addRows((rows) =>
        rows
          .addRow('equityAndLiabilities', value('Eget kapital och skulder'))
          .addSubRows((rows) => {
            rows
              .addRow('equity', value('Eget kapital'))
              .addSubRows((rows) => {
                // Bundet eget kapital
                addRestrictedEquity(accounts, rows, previousYearKey);
                // Fritt eget kapital
                addUnrestrictedEquity(accounts, rows, previousYearKey);
                return rows.build();
              })

              .addRow(
                'sumEquity',
                value('Summa eget kapital'),
                refs(),
                ref(
                  sum(
                    id(`${rows.getBaseId()}.equity.restrictedEquitySum.year`),
                    id(`${rows.getBaseId()}.equity.unrestrictedEquitySum.year`)
                  )
                ),
                ref(
                  sum(
                    id(
                      `${rows.getBaseId()}.equity.restrictedEquitySum.previousYear`
                    ),
                    id(
                      `${rows.getBaseId()}.equity.unrestrictedEquitySum.previousYear`
                    )
                  )
                )
              );

            // Obeskattade reserver
            rows.addRow('untaxedReserves').addSubRows((rows) => {
              addUntaxedReserves(accounts, rows, previousYearKey);
              return rows.build();
            });

            // Avsättningar
            rows.addRow('allocations').addSubRows((rows) => {
              addAllocations(accounts, rows, previousYearKey);
              return rows.build();
            });

            // Långfristiga skulder
            rows.addRow('longTermLiabilities').addSubRows((rows) => {
              addLongTermLiabilities(accounts, rows, previousYearKey);
              return rows.build();
            });

            // Kortfristiga skulder
            rows.addRow('shortTermLiabilities').addSubRows((rows) => {
              addShortTermLiabilities(accounts, rows, previousYearKey);
              return rows.build();
            });

            // Ej tilldelade AKA unassigned accounts
            rows.addRow('unassignedAccounts').addSubRows((rows) => {
              addUnassignedAccounts(accounts, rows, previousYearKey);
              return rows.build();
            });

            return rows.build();
          })
          .addRow(
            'sumEquityAndLiabilities',
            value('Summa eget kapital och skulder'),
            refs(),
            ref(
              sum(
                id(`${rows.getBaseId()}.equityAndLiabilities.equity.*Sum.year`),
                id(
                  `${rows.getBaseId()}.equityAndLiabilities.untaxedReserves.*Sum.year`
                ),
                id(
                  `${rows.getBaseId()}.equityAndLiabilities.allocations.*Sum.year`
                ),
                id(
                  `${rows.getBaseId()}.equityAndLiabilities.longTermLiabilities.*Sum.year`
                ),
                id(
                  `${rows.getBaseId()}.equityAndLiabilities.shortTermLiabilities.*Sum.year`
                )
              )
            ),
            ref(
              sum(
                id(
                  `${rows.getBaseId()}.equityAndLiabilities.equity.*Sum.previousYear`
                ),
                id(
                  `${rows.getBaseId()}.equityAndLiabilities.untaxedReserves.*Sum.previousYear`
                ),
                id(
                  `${rows.getBaseId()}.equityAndLiabilities.allocations.*Sum.previousYear`
                ),
                id(
                  `${rows.getBaseId()}.equityAndLiabilities.longTermLiabilities.*Sum.previousYear`
                ),
                id(
                  `${rows.getBaseId()}.equityAndLiabilities.shortTermLiabilities.*Sum.previousYear`
                )
              )
            )
          )
          .build()
      )
      .build(),
  },
});

export const balanceSheetReferences = (): Record<string, string> => ({
  'balanceSheet.sumEquity.year': id(
    'balanceSheet.section.equityAndLiabilities.equityAndLiabilities.sumEquity.year'
  ),
  'balanceSheet.sumEquity.previousYear': id(
    'balanceSheet.section.equityAndLiabilities.equityAndLiabilities.sumEquity.previousYear'
  ),
  'balanceSheet.sumUntaxedReserves.year': id(
    'balanceSheet.section.equityAndLiabilities.equityAndLiabilities.untaxedReserves.untaxedReservesSum.year'
  ),
  'balanceSheet.sumUntaxedReserves.previousYear': id(
    'balanceSheet.section.equityAndLiabilities.equityAndLiabilities.untaxedReserves.untaxedReservesSum.previousYear'
  ),
  'balanceSheet.sumEquityAndLiabilities.year': id(
    'balanceSheet.section.equityAndLiabilities.sumEquityAndLiabilities.year'
  ),
  'balanceSheet.sumEquityAndLiabilities.previousYear': id(
    'balanceSheet.section.equityAndLiabilities.sumEquityAndLiabilities.previousYear'
  ),
  'balanceSheet.sumAssets.year': id(
    'balanceSheet.section.assets.sumAssets.year'
  ),
  'balanceSheet.sumAssets.previousYear': id(
    'balanceSheet.section.assets.sumAssets.previousYear'
  ),
});

import React from 'react';
import styled from '@emotion/styled';

const Container = styled.footer`
  margin-top: 5px;
`;

const FooterRow = styled.div`
  width: 320px;
  display: flex;
  justify-content: space-between;
`;

const SummeryKey = styled.p`
  margin: 0;
  font-weight: bold;
  text-align: left;
`;

const SummeryValue = styled.p`
  margin: 0;
  font-weight: bold;
  text-align: right;
`;

type SummeryItem = {
  id: string;
  key: string;
  value: string;
};
type FooterProps = {
  data: SummeryItem[];
};

const Footer = ({ data }: FooterProps): JSX.Element => {
  return (
    <Container>
      {data.map((item) => (
        <FooterRow key={item.key}>
          <SummeryKey>{item.key}</SummeryKey>
          <SummeryValue>{item.value}</SummeryValue>
        </FooterRow>
      ))}
    </Container>
  );
};

export default Footer;

import React, { memo, useCallback, useContext, useState } from 'react';
import styled from '@emotion/styled';
import {
  Typography,
  Button,
  ButtonGroup,
  ClickAwayListener,
} from '@material-ui/core';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import { StringCell, Option } from '@agoy/document/src/Cell/types';
import When from '_shared/components/When/When';
import ServiceContext from '../../Context/TableServiceContext';

interface OptionSelectorProps {
  className?: string;
  cellId: string;
  cell: StringCell;
  editing?: boolean;
  active?: boolean;
  onValueChange?: (key: string, newValue: string) => void;
}

const SelectorWrapper = styled.div`
  position: relative;
  width: 12rem;
`;

const SelectorButton = styled(Button)`
  display: flex;
  flex-direction: row;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.palette.grey[300]};
  &:hover {
    border-color: ${({ theme }) => theme.palette.grey[400]};
    background-color: ${(props) => props.theme.palette.common.white};
  }
`;

const ArrowDown = styled(ArrowDropDown)`
  color: ${(props) => props.theme.palette.grey[700]};
`;

const ArrowUp = styled(ArrowDropUp)`
  color: ${(props) => props.theme.palette.grey[700]};
`;

const ArrowRightIcon = styled(ArrowDropUp)`
  transform: rotate(90deg);
  color: ${(props) => props.theme.palette.grey[700]};
`;

const SelectButtonGroup = styled(ButtonGroup)`
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 1;
  background-color: ${(props) => props.theme.palette.common.white};
  border: none;
  box-shadow: ${(props) => props.theme.shadows[4]};
`;

const SelectorTitle = styled(Typography)`
  flex: 1;
  font-size: 14px;
  text-align: left;
  text-transform: capitalize;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const SelectButton = styled(Button)`
  display: flex;
  flex-direction: row;
  position: relative;
  background-color: ${(props) => props.theme.palette.common.white};
  width: 100%;
  border: none;
  z-index: 2;
  & .inner-select {
    display: none;
  }
  &:hover .inner-select {
    display: block;
  }
`;

const OptionButton = styled(Button)`
  display: flex;
  flex-direction: row;
  position: relative;
  background-color: ${(props) => props.theme.palette.common.white};
  width: 100%;
  border: none;
  z-index: 14;
`;

const InnerSelect = styled.div`
  position: absolute;
  top: 0;
  left: 12rem;
  background-color: ${(props) => props.theme.palette.common.white};
  box-shadow: ${(props) => props.theme.shadows[4]};
  z-index: 15;
`;

interface SelectorProps {
  cellId: string;
  cellTitle: string | number | undefined;
  allSelects: Option[] | undefined;
  onValueChange?: (key: string, newValue: string) => void;
}

const Selector = memo(
  ({ cellId, cellTitle, allSelects, onValueChange }: SelectorProps) => {
    const service = useContext(ServiceContext);
    const [menuOpen, setMenuOpen] = useState(false);

    const getLabelForOption = useCallback((value) => {
      if (value?.label != null) {
        return value?.label;
      }

      return value?.value;
    }, []);

    const onSelect = useCallback(
      (value) => {
        if (onValueChange != null) {
          onValueChange(cellId, getLabelForOption(value));
        }

        if (value?.group == null) {
          service?.updateCellValue(cellId, getLabelForOption(value));
        } else {
          service?.updateField(cellId, `${value.group}-${value.value}`);
        }
        setMenuOpen(false);
      },
      [onValueChange, cellId, getLabelForOption, service]
    );

    const hasSubOptions = useCallback((value) => {
      return value?.options != null && value?.options?.length > 0;
    }, []);

    return (
      <ClickAwayListener onClickAway={() => setMenuOpen(false)}>
        <SelectorWrapper>
          <SelectorButton
            disableRipple
            color="inherit"
            onClick={() => setMenuOpen(!menuOpen)}
            endIcon={menuOpen ? <ArrowUp /> : <ArrowDown />}
          >
            <SelectorTitle>{cellTitle}</SelectorTitle>
          </SelectorButton>
          {menuOpen && (
            <>
              <SelectButtonGroup orientation="vertical">
                {allSelects?.map((item) => (
                  <SelectButton
                    key={item.value}
                    fullWidth
                    endIcon={
                      hasSubOptions(item) ? <ArrowRightIcon /> : undefined
                    }
                    onClick={() => {
                      if (!hasSubOptions(item)) {
                        onSelect(item);
                      }
                    }}
                  >
                    <SelectorTitle>{getLabelForOption(item)}</SelectorTitle>
                    <When isTrue={hasSubOptions(item)}>
                      <InnerSelect className="inner-select">
                        {item?.options?.map((option) => (
                          <OptionButton
                            key={option.value}
                            onClick={() => {
                              onSelect(option);
                            }}
                          >
                            <SelectorTitle>
                              {getLabelForOption(option)}
                            </SelectorTitle>
                          </OptionButton>
                        ))}
                      </InnerSelect>
                    </When>
                  </SelectButton>
                ))}
              </SelectButtonGroup>
            </>
          )}
        </SelectorWrapper>
      </ClickAwayListener>
    );
  }
);

const EditingMultipleSelector = (props: OptionSelectorProps) => {
  const { cell, cellId, onValueChange } = props;

  const getValue = useCallback(() => {
    if (cell.value.includes('-')) {
      return cell.value.split('-')[1];
    }

    return cell.value;
  }, [cell.value]);

  return (
    <Selector
      cellId={cellId}
      cellTitle={getValue()}
      allSelects={cell?.format?.options}
      onValueChange={onValueChange}
    />
  );
};

const OptionSelector = (props: OptionSelectorProps) => {
  const {
    editing,
    className,
    cell: { value },
  } = props;

  const getValue = useCallback(() => {
    if (value.includes('-')) {
      return value.split('-')[1];
    }

    return value;
  }, [value]);

  return (
    <When isTrue={!editing} fallback={<EditingMultipleSelector {...props} />}>
      <Typography className={className}>{getValue()}</Typography>
    </When>
  );
};

export default OptionSelector;

import React, { useCallback, useContext } from 'react';
import EditField from '_shared/components/Inputs/EditField';
import ServiceContext from '../../Context/TableServiceContext';
import StringInput from './StringInput';

interface StringFieldProps {
  className?: string;
  id: string;
  value: string;
  disabled?: boolean;
  placeholder?: string;
  isField?: boolean;
  onBlur?: (newValue: string) => void;
}

const StringField = ({
  className,
  id,
  value,
  disabled,
  placeholder,
  isField,
  onBlur,
}: StringFieldProps): JSX.Element => {
  const service = useContext(ServiceContext);

  const onChange = useCallback(
    (newValue: string) => {
      if (
        (isField === undefined && id.split('.').length > 3) ||
        isField === false
      ) {
        service?.updateCellValue(id, newValue);
      } else {
        service?.updateField(id, newValue);
      }
    },
    [service, id]
  );

  return (
    <EditField
      className={className}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      component={StringInput}
      disabled={disabled}
      placeholder={placeholder}
    />
  );
};

export default StringField;

import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import { AccountingBalances } from 'types/Accounting';
import { StockInventoryTable } from '@agoy/api-sdk-core';
import { SET_CURRENT_CUSTOMER } from '_clients/redux/customer-view/action-types';
import type { SetCurrentCustomerAction } from '_clients/redux/customer-view/actions';
import { SkatteverketTransaction } from '_clients/redux/customer-view/types';
import {
  INIT_STATE,
  RESET_UNSAFE_CLIENT_DATA,
  SET_NO_SIE_DATA,
  SET_PROGRAM_FINANCIAL_YEAR_STATUS,
  SET_PROGRAM_PERIODS_STATUS,
} from '_shared/redux/action-types';
import type { GlobalActions } from '_shared/redux/actions';
import {
  ProgramStatusPeriodState,
  ProgramStatusYearState,
  Transaction,
} from '_shared/types';
import {
  updateProgramStatusPeriodState,
  updateProgramStatusYearState,
} from '_shared/redux/program-status/reducer';
import type {
  AdjustmentData,
  CustomerInvoicesResponse,
  InventoryObsolescenceData,
  PropertyTaxTableDataType,
  Routines,
  savingUserDataStatus,
  SupplierInvoicesResponse,
  UserInput,
  UserInputDocuments,
  VacationDebt,
} from '../../types';
import {
  PATCH_USER_INPUT_DATA,
  PATCH_USER_INPUT_ROUTINE_DATA,
  SAVING_USER_DATA_FAILED,
  SAVING_USER_DATA_SUCCESS,
  SET_ACCOUNTING_BALANCES,
  SET_CUSTOMER_INVOICES,
  SET_NUM_DOCUMENTS,
  SET_ROUTINES_DATA,
  SET_SUPPLIER_INVOICES,
  SET_USER_INPUT_DATA,
  SET_USER_INPUT_DOCUMENTS,
  STARTED_SAVING_USER_DATA,
  SET_PERIOD_CHANGE_VISIBILITY,
  TOGGLE_PERIOD_DONE_VISIBILITY,
  TOGGLE_PERIOD_LOCKED_VISIBILITY,
  SET_PERIOD_UB_VISIBILITY,
  SET_SINGLE_PERIOD,
  SET_VACATION_DEBT,
  ADD_PROGRAM_PERIODS_STATUS,
  SET_STOCK_INVENTORY_TABLE,
  SET_MOVING_ACCOUNTS_MODE,
} from './action-types';
import type { AccountingViewAction } from './actions';

const periodLockedVisibleValue = localStorage.getItem('periodLockedVisible');

export type AccountingViewClientYear = {
  userInput?: UserInput;
  userInputDocuments?: UserInputDocuments;
  adjustmentData?: AdjustmentData;
  filteredTransactions: Transaction[];
  customerInvoices: CustomerInvoicesResponse;
  supplierInvoices: SupplierInvoicesResponse;
  accountingBalances?: AccountingBalances | null;
  accountingBalancesUpdatedAt?: number;
  skatteverketTransactions?: SkatteverketTransaction[];
  /**
   * Period status, key is periodId
   */
  financialYearStatus?: ProgramStatusYearState | null;
};

export type AccountingViewClient = {
  routines?: Routines;
  years: Record<string, AccountingViewClientYear | null>;
  periodStatus?: Record<number, ProgramStatusPeriodState>;
  yearStatus?: Record<number, ProgramStatusYearState>;
};

export type AccountingViewState = {
  clients: Record<string, AccountingViewClient>;
  showSinglePeriod?: string;
  showSinglePeriodType?: string;
  savingUserDataStatus: savingUserDataStatus;
  periodChangeVisible: boolean;
  periodUBVisible: boolean;
  periodDoneVisible: boolean;
  periodLockedVisible: boolean;
  movingAccountsMode: boolean;
  vacationDebt?: VacationDebt;
  propertyTaxTableData?: PropertyTaxTableDataType;
  stockInventoryTable?: StockInventoryTable;
};

export const initialState: AccountingViewState = {
  savingUserDataStatus: 'SAVED',
  showSinglePeriod: undefined,
  showSinglePeriodType: undefined,
  periodChangeVisible: true,
  periodUBVisible: true,
  movingAccountsMode: false,
  periodDoneVisible: false,
  periodLockedVisible:
    periodLockedVisibleValue === null || !!periodLockedVisibleValue,
  clients: {},
  vacationDebt: undefined,
  propertyTaxTableData: undefined,
  stockInventoryTable: undefined,
};

const initialClient: AccountingViewClient = {
  years: {},
};

export const initialYear: AccountingViewClientYear = {
  filteredTransactions: [],
  customerInvoices: {
    statusList: [],
    invoices: [],
    reportData: {
      totalInvoices: 0,
      totalSumSEK: 0,
      sumSEK: 0,
      updateDate: '',
      updateState: 'completed',
      sums: {},
      customerInvoices: [],
    },
  },

  supplierInvoices: {
    statusList: [],
    invoices: [],
    reportData: {
      totalInvoices: 0,
      totalSumSEK: 0,
      sumSEK: 0,
      sums: {},
      updateDate: '',
      updateState: 'completed',
      supplierInvoices: [],
    },
  },
};

const updateClient = (
  state: AccountingViewState,
  clientId: string,
  updater: (
    clientState: AccountingViewClient
  ) => AccountingViewClient | undefined
): AccountingViewState => {
  const newClientState = updater(state.clients[clientId] || initialClient);
  if (newClientState === undefined) {
    if (state.clients[clientId] !== undefined) {
      const newState = { ...state, clients: { ...state.clients } };
      delete newState.clients[clientId];
      return newState;
    }
  } else if (newClientState !== state.clients[clientId]) {
    return {
      ...state,
      clients: {
        ...state.clients,
        [clientId]: newClientState,
      },
    };
  }
  return state;
};

const updateClientYear = (
  state: AccountingViewState,
  clientId: string,
  year: string,
  updater: (
    clientYearState: AccountingViewClientYear
  ) => AccountingViewClientYear | null
): AccountingViewState => {
  if (year.length !== 17) {
    throw new Error(`Got financial year ${year}`);
  }
  return updateClient(state, clientId, (client) => {
    const newYearState = updater(client.years[year] || initialYear);
    if (newYearState !== client.years[year]) {
      return {
        ...client,
        years: {
          ...client.years,
          [year]: newYearState,
        },
      };
    }
    return client;
  });
};

export default (
  state: AccountingViewState = initialState,
  action: GlobalActions | SetCurrentCustomerAction | AccountingViewAction // FIXME: refactoring with: https://redux-toolkit.js.org/tutorials/typescript#define-slice-state-and-action-types
): AccountingViewState => {
  switch (action.type) {
    // Global actions
    case INIT_STATE:
      return action.state.accountingView || state;
    case SET_ACCOUNTING_BALANCES:
      return updateClientYear(
        state,
        action.clientId,
        action.financialYear,
        (clientYearState) => ({
          ...clientYearState,
          accountingBalances: action.accountingBalances,
          accountingBalancesUpdatedAt: action.updatedAt,
        })
      );
    case SET_NO_SIE_DATA:
      return updateClientYear(state, action.clientId, action.year, () => null);

    // Reducer specific actions
    case STARTED_SAVING_USER_DATA:
      return {
        ...state,
        savingUserDataStatus: 'SAVING',
      };

    case SAVING_USER_DATA_FAILED:
      return {
        ...state,
        savingUserDataStatus: 'FAILED',
      };

    case SAVING_USER_DATA_SUCCESS:
      return {
        ...state,
        savingUserDataStatus: 'SAVED',
      };

    case SET_USER_INPUT_DATA:
      return updateClientYear(
        state,
        action.clientId,
        action.financialYear,
        (state) => {
          return {
            ...state,
            userInput: action.userInput,
          };
        }
      );

    case SET_USER_INPUT_DOCUMENTS:
      return updateClientYear(
        state,
        action.clientId,
        action.financialYear,
        (state) => {
          return {
            ...state,
            userInputDocuments: action.userInputDocuments,
          };
        }
      );

    case SET_ROUTINES_DATA:
      return updateClient(state, action.clientId, (state) => {
        return {
          ...state,
          routines: action.routines,
        };
      });

    case PATCH_USER_INPUT_DATA:
      return updateClientYear(
        state,
        action.clientId,
        action.financialYear,
        (state) => {
          const userInput = state.userInput ?? {};
          return {
            ...state,
            userInput: {
              ...userInput,
              [`account${action.accountNumber}`]: {
                ...userInput[`account${action.accountNumber}`],
                [action.formattedPeriod]: {
                  ...(userInput[`account${action.accountNumber}`] &&
                    userInput[`account${action.accountNumber}`][
                      action.formattedPeriod
                    ]),
                  ...action.newUserInput,
                },
              },
            },
          };
        }
      );

    case PATCH_USER_INPUT_ROUTINE_DATA:
      return updateClient(state, action.clientId, (state) => {
        const routines = state.routines ?? {};
        return {
          ...state,
          routines: {
            ...routines,
            [`account${action.accountNumber}`]: {
              ...routines[`account${action.accountNumber}`],
              ...action.newUserInput,
            },
          },
        };
      });

    case SET_NUM_DOCUMENTS:
      return updateClientYear(
        state,
        action.clientId,
        action.financialYear,
        (state) => {
          if (!state.userInputDocuments) {
            console.error('No userInputDocuments');
            return state;
          }
          const numDocuments = get(
            state,
            `userInputDocuments[account${action.accountNumber}documents][${action.formattedPeriod}]`
          );
          if (numDocuments !== action.numImages) {
            return {
              ...state,
              userInputDocuments: {
                ...state.userInputDocuments,
                [`account${action.accountNumber}documents`]: {
                  ...state.userInputDocuments[
                    `account${action.accountNumber}documents`
                  ],
                  [action.periodId]: action.numImages,
                },
              },
            };
          }
          return state;
        }
      );

    case SET_SINGLE_PERIOD:
      return {
        ...state,
        showSinglePeriod: action.period,
        showSinglePeriodType: action.periodType,
      };

    case SET_PERIOD_CHANGE_VISIBILITY:
      return {
        ...state,
        periodChangeVisible: action.value,
      };

    case SET_PERIOD_UB_VISIBILITY:
      return {
        ...state,
        periodUBVisible: action.value,
      };

    case SET_MOVING_ACCOUNTS_MODE:
      return {
        ...state,
        movingAccountsMode: action.value,
      };

    case TOGGLE_PERIOD_DONE_VISIBILITY:
      return {
        ...state,
        periodDoneVisible: !state.periodDoneVisible,
        showSinglePeriod: undefined,
      };

    case TOGGLE_PERIOD_LOCKED_VISIBILITY:
      return {
        ...state,
        periodLockedVisible: !state.periodLockedVisible,
        showSinglePeriod: undefined,
      };

    case SET_CUSTOMER_INVOICES:
      return updateClientYear(
        state,
        action.clientId,
        action.financialYear,
        (state) => {
          return {
            ...state,
            customerInvoices: action.payload,
          };
        }
      );

    case SET_SUPPLIER_INVOICES:
      return updateClientYear(
        state,
        action.clientId,
        action.financialYear,
        (state) => {
          return {
            ...state,
            supplierInvoices: action.payload,
          };
        }
      );

    case SET_CURRENT_CUSTOMER: {
      if (action.currentCustomer === undefined) {
        return state;
      }
      const otherClients = Object.keys(state.clients).filter(
        (clientId) => clientId !== action.currentCustomer
      );
      if (otherClients.length === 0) {
        return state;
      }
      return {
        ...state,
        clients: {
          [action.currentCustomer]: state.clients[action.currentCustomer],
        },
      };
    }
    case RESET_UNSAFE_CLIENT_DATA: {
      return { ...state, showSinglePeriod: undefined };
    }

    case SET_PROGRAM_PERIODS_STATUS:
      return updateClient(state, action.clientId, (clientState) =>
        updateProgramStatusPeriodState('RECONCILIATION', clientState, action)
      );

    case SET_PROGRAM_FINANCIAL_YEAR_STATUS:
      return updateClient(state, action.clientId, (clientState) =>
        updateProgramStatusYearState('RECONCILIATION', clientState, action)
      );

    case ADD_PROGRAM_PERIODS_STATUS:
      return updateClient(state, action.clientId, (clientState) => {
        const existingStatus =
          clientState.periodStatus?.[action.status.periodId];
        const newHistory = orderBy(
          [action.status].concat(existingStatus?.history ?? []),
          (item) => new Date(item.createdAt),
          'desc'
        );
        return {
          ...clientState,
          periodStatus: {
            ...clientState.periodStatus,
            [action.status.periodId]: {
              status: newHistory[0].status,
              history: newHistory,
            },
          },
        };
      });

    case SET_VACATION_DEBT:
      return {
        ...state,
        vacationDebt: action.payload,
      };

    case SET_STOCK_INVENTORY_TABLE:
      return {
        ...state,
        stockInventoryTable: action.payload,
      };

    default:
      return state;
  }
};

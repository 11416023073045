import { TimePeriod } from '@agoy/document';
import { WSNotification } from '@agoy/messages';
import { asResultClass, getApiSdk, isApiErrorType } from 'api-sdk';
import { ReplaySubject, Subject, Subscription } from 'rxjs';
import { Err, Ok, Result } from 'ts-results';
import { AccountingBalancesResult } from 'types/Accounting';
import { getContext } from 'utils/AgoyAppClient/contextHolder';
import { NotificationService } from '_shared/services/Notifications/types';
import retryImport from './methods/retryImport';
import updateSieFromFortnox from './methods/updateSieFromFortnox';
import uploadSieFile from './methods/uploadSieFile';
import {
  AccountingBalancesDataLayer,
  GetAccountingBalancesErrors,
  REQUIRE_IMMIDIATE_UPDATE,
} from './types';

interface AccountingBalancesDataLayerInternal
  extends AccountingBalancesDataLayer {
  updateAccountingBalances(
    financialYear: number
  ): Promise<
    Result<AccountingBalancesResult | null, GetAccountingBalancesErrors>
  >;
}

class AccountingBalancesDataLayerService
  implements AccountingBalancesDataLayerInternal
{
  /**
   * The current client
   */
  clientId: string;

  notificationService: NotificationService | null;

  /**
   * Use getter sdk
   */
  _sdk: ReturnType<typeof getApiSdk> | undefined;

  data: Map<
    number,
    Subject<
      Result<AccountingBalancesResult | null, GetAccountingBalancesErrors>
    >
  > = new Map();

  accoutingBalancesChangedSubscription: Subscription | undefined;

  constructor(
    clientId: string,
    notificationService: NotificationService | null
  ) {
    this.clientId = clientId;
    this.notificationService = notificationService;
    if (notificationService) {
      this.accoutingBalancesChangedSubscription =
        this.notificationService?.subscribe(
          {
            topic: 'accounting-balances-changed',
            clientId,
          },
          (result) => {
            this.onAccountingBalancesChanged(result);
          }
        );
    }
  }

  public get sdk(): ReturnType<typeof getApiSdk> {
    if (!this._sdk) {
      this._sdk = getApiSdk(getContext());
    }
    return this._sdk;
  }

  release(): void {
    if (this.accoutingBalancesChangedSubscription && this.notificationService) {
      this.notificationService.unsubscribe(
        this.accoutingBalancesChangedSubscription
      );
      this.accoutingBalancesChangedSubscription = undefined;
    }
    this.data.forEach((subject) => {
      subject.complete();
    });
  }

  async onAccountingBalancesChanged(msg: Result<WSNotification, string>) {
    if (msg.ok) {
      if (msg.val.topic === 'accounting-balances-changed') {
        this.updateAccountingBalances(msg.val.financialYearId);
      }
    }
  }

  /**
   * Implementation of the getAccoungingBalances
   *
   * @param financialYearId
   * @returns
   */
  getAccountingBalances(
    financialYearId: number | string
  ): ReturnType<AccountingBalancesDataLayer['getAccountingBalances']> {
    if (typeof financialYearId === 'string') {
      const subject = new ReplaySubject<
        Result<AccountingBalancesResult | null, GetAccountingBalancesErrors>
      >(1);
      this.updateAccountingBalances(financialYearId)
        .then((res) => {
          subject.next(res);
          subject.complete();

          // Publish the result with the financialYearId as key
          if (res.ok && res.val) {
            // Find the year id in the result by comparing the string with the dates.
            const year = res.val.accountingBalances.financialYears.find(
              (financialYear) => {
                return (
                  financialYearId ===
                  TimePeriod.fromISODates(
                    financialYear.start,
                    financialYear.end
                  ).value
                );
              }
            );
            if (year) {
              const existing = this.data.get(year.id);
              const otherSubject = existing || new ReplaySubject(1);
              if (!existing) {
                this.data.set(year.id, otherSubject);
              }
              // Publish the result.
              otherSubject.next(res);
            }
          }
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err);
          subject.next(Err('error'));
          subject.complete();
        });
      return subject;
    }

    const existing = this.data.get(financialYearId);
    if (existing) {
      return existing;
    }

    // Set up a subject and initiate the fetching of accounting balances.
    const subject = new ReplaySubject<
      Result<AccountingBalancesResult | null, GetAccountingBalancesErrors>
    >(1);
    this.data.set(financialYearId, subject);
    this.updateAccountingBalances(financialYearId);
    return subject;
  }

  /**
   * Implementation of uploadSieFile
   *
   * @param file The SIE content.
   */
  async uploadSieFile(
    file: Buffer
  ): ReturnType<AccountingBalancesDataLayer['uploadSieFile']> {
    const result = await uploadSieFile(
      this.sdk,
      this.notificationService,
      this.clientId,
      file
    );

    if (
      result.err &&
      result.val.errorCode === REQUIRE_IMMIDIATE_UPDATE &&
      'financialYear' in result.val.errorDetails
    ) {
      const { financialYear } = result.val.errorDetails;
      const updateResult = await this.updateAccountingBalances(financialYear);
      if (updateResult.ok) {
        return Ok([]);
      }
      return Err({ errorCode: updateResult.val, errorDetails: {} });
    }

    return result;
  }

  /**
   * Implementation of updateSieFromFortnox
   *
   * @param financialYear Financial year `yyyyMMdd-yyyyMMdd`
   * @param financialYearId Financial year's id
   */
  async updateSieFromFortnox(
    financialYear: string,
    financialYearId: number
  ): ReturnType<AccountingBalancesDataLayer['updateSieFromFortnox']> {
    const result = await updateSieFromFortnox(
      this.sdk,
      this.notificationService,
      this.clientId,
      financialYear
    );

    if (result.err && result.val.errorCode === REQUIRE_IMMIDIATE_UPDATE) {
      const updateResult = await this.updateAccountingBalances(financialYearId);
      if (updateResult.ok) {
        return Ok([]);
      }
      return Err({ errorCode: updateResult.val, errorDetails: {} });
    }

    return result;
  }

  /**
   * "private" method to update the accounting balances behind the scenes.
   *
   * @param financialYear The id of the financial year if number, otherwise financial year string.
   */
  async updateAccountingBalances(
    financialYearId: number | string
  ): ReturnType<
    AccountingBalancesDataLayerInternal['updateAccountingBalances']
  > {
    const requestedAt = new Date();
    const result = await asResultClass(
      this.sdk.getAccountingBalances({
        clientid: this.clientId,
        financialYearId:
          typeof financialYearId === 'number' ? financialYearId : undefined,
        financialYear:
          typeof financialYearId === 'string' ? financialYearId : undefined,
      })
    );

    if (
      result.ok ||
      (isApiErrorType(result.val) && result.val.status === 404)
    ) {
      const value = result.ok
        ? { accountingBalances: result.val, updatedAt: requestedAt.getTime() }
        : null;
      if (typeof financialYearId === 'number') {
        const d = this.data.get(financialYearId);
        if (!d) {
          const subject = new ReplaySubject<
            Result<AccountingBalancesResult | null, GetAccountingBalancesErrors>
          >(1);
          this.data.set(financialYearId, subject);
          subject.next(Ok(value));
        } else {
          d.next(Ok(value));
        }
      }
      const resultFinancialYear = value?.accountingBalances.financialYears[0];
      if (resultFinancialYear && resultFinancialYear.id !== financialYearId) {
        // The financial year id is not a real id when feature DB_ACCOUNTS is off
        // but when SIE_IMPORT is enabled, the notification will point to the real id
        // So in this case we need to notify the subscribe of the fake id also.
        this.data.get(resultFinancialYear.id)?.next(Ok(value));
      }
      return Ok(value);
    }
    if (isApiErrorType(result.val)) {
      return Err(result.val.body?.code || `${result.val.status}` || 'error');
    }
    return Err('error');
  }

  async retryImport(
    financialYear: string
  ): ReturnType<AccountingBalancesDataLayerInternal['retryImport']> {
    return retryImport(this.sdk, this.clientId, financialYear);
  }
}

export default AccountingBalancesDataLayerService;

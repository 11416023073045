import React, { useState, useRef, useContext, useCallback } from 'react';
import styled from '@emotion/styled';
import {
  Tooltip,
  List,
  Popover,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import { useIntl } from 'react-intl';
import { last } from 'lodash';
import {
  PrintOutlined,
  VisibilityOffOutlined,
  VisibilityOutlined,
  MoreHoriz,
} from '@material-ui/icons';

import { translations } from '@agoy/program-status';
import { colors } from '@agoy/theme';

import {
  setAccountingBalances,
  setUserInputData,
  setUserInputDocuments,
} from '_reconciliation/redux/accounting-view/actions';
import PrintSpecificationsContext from '_reconciliation/components/ReconciliationView/PrintSpecifications/PrintSpecificationsContext';
// @ts-ignore
import { ReactComponent as FinancialStatementIcon } from 'assets/program/FinancialStatementIcon.svg';
import { StatusIcon } from '_shared/components/PeriodStatusButton/StatusIcon';
import { useSelector } from 'redux/reducers';
import { useDispatch } from 'react-redux';
import { firstValueFrom } from 'rxjs';
import useClientDataLayer from 'data/client/useClientDataLayer';
import { mapUserInputData } from '_clients/services/mappings';
import { asResultClass, useApiSdk } from 'api-sdk';
import LoadingLogo from '_shared/components/LoadingLogo';

import { PeriodType, ReconciliationPeriod } from '@agoy/reconciliation';

const PrintIcon = styled(PrintOutlined)`
  color: white;
`;

const VisibilityOffIcon = styled(VisibilityOffOutlined)`
  color: white;
`;

const VisibilityIcon = styled(VisibilityOutlined)`
  color: white;
`;

const PrintIconContainer = styled.div`
  width: 24px;
`;

const MoreHorizIcon = styled(MoreHoriz)`
  color: white;
  &:hover {
    cursor: pointer;
  }
`;

const StyledListItemText = styled(ListItemText)`
  margin-left: 10px;
`;

const StyledListItem = styled(ListItem)`
  padding-top: 0;
  padding-bottom: 0;
`;

const StyledFinancialStatementIcon = styled(FinancialStatementIcon)`
  color: black;
  border: none;
`;

export type PeriodHeaderMenuProps = {
  clientId: string;
  showOnlyThis: boolean;
  allOptionsVisible: boolean;
  notActive: boolean;
  type: PeriodType;
  period: ReconciliationPeriod;
  onRedirectTaxPeriod: () => void;
  onClickVisibility: () => void;
};

const PeriodHeaderMenu = ({
  clientId,
  showOnlyThis,
  allOptionsVisible,
  notActive,
  type,
  period,
  onRedirectTaxPeriod,
  onClickVisibility,
}: PeriodHeaderMenuProps) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const sdk = useApiSdk();

  const { printSpecifications } = useContext(PrintSpecificationsContext);

  const [isPopupShown, setPopupShown] = useState(false);
  const [showEyeTooltip, setShowEyeTooltip] = useState(false);
  const [printLoading, setPrintLoading] = useState(false);

  const anchorRef = useRef<SVGSVGElement>(null);

  const lastPeriod = last(period.periods);
  const clientDataLayer = useClientDataLayer(clientId);

  const trimYear = (year?: string) => year?.replaceAll('-', '');

  const financialYearObj = lastPeriod?.financialYear;
  const currentFullYear = `${trimYear(financialYearObj?.start)}-${trimYear(
    financialYearObj?.end
  )}`;

  const updateAccountingBalances = useCallback(async () => {
    if (clientDataLayer && currentFullYear && clientId) {
      const result = await firstValueFrom(
        clientDataLayer.accountingBalances.getAccountingBalances(
          currentFullYear
        )
      );

      if (result.ok) {
        await dispatch(
          setAccountingBalances(
            clientId,
            currentFullYear,
            result.val?.accountingBalances ?? null,
            result.val?.updatedAt ?? Date.now()
          )
        );
      }
    }
  }, [currentFullYear, clientDataLayer, clientId, dispatch]);

  const fetchUserInput = useCallback(async () => {
    if (!financialYearObj) {
      return;
    }

    const year = financialYearObj.start.substring(0, 4);

    const userInputResult = await asResultClass(
      sdk.getUserInput({
        clientId,
        accountingYear: year,
        financialYearId: financialYearObj.id,
      })
    );

    if (userInputResult.ok) {
      const { inputData } = userInputResult.val;
      const parsed = JSON.parse(inputData);
      const { userInput, userInputDocuments } = mapUserInputData(parsed);

      await dispatch(setUserInputData(clientId, financialYearObj, userInput));
      await dispatch(
        setUserInputDocuments(clientId, financialYearObj, userInputDocuments)
      );
    }
  }, [clientId, dispatch, financialYearObj, sdk]);

  const taxPeriodStatus =
    useSelector((state) => {
      return lastPeriod
        ? state.taxView.clients[clientId]?.periodStatus?.[lastPeriod.id]?.status
        : undefined;
    }) || 'NOT_STARTED';

  const handleCloseTooltip = () => {
    setShowEyeTooltip(false);
  };

  const handleOpenTooltip = () => {
    setShowEyeTooltip(true);
  };

  const togglePopup = () => {
    setPopupShown((value) => !value);
  };

  const handleClickVisibility = () => {
    setShowEyeTooltip(false);
    togglePopup();
    onClickVisibility();
  };

  const print = useCallback(async () => {
    if (lastPeriod) {
      setPrintLoading(true);

      await updateAccountingBalances();
      await fetchUserInput();

      printSpecifications(lastPeriod.financialYear, lastPeriod);
      setPrintLoading(false);
    }
  }, [
    lastPeriod,
    updateAccountingBalances,
    fetchUserInput,
    printSpecifications,
  ]);

  const taxStatusTranslation = formatMessage(translations[taxPeriodStatus]);
  const taxStatusColor = colors[taxPeriodStatus];

  if (allOptionsVisible) {
    return (
      <>
        {!notActive && (
          <Tooltip
            title={formatMessage(
              { id: 'overview.taxSalary' },
              { status: taxStatusTranslation }
            )}
          >
            <div>
              <StatusIcon
                statusColor={taxStatusColor}
                detailIcon
                icon={
                  <FinancialStatementIcon
                    onClick={onRedirectTaxPeriod}
                    cursor="pointer"
                  />
                }
              />
            </div>
          </Tooltip>
        )}

        <Tooltip title={formatMessage({ id: 'overview.printPeriod' })}>
          <PrintIconContainer>
            {printLoading ? (
              <LoadingLogo size="tiny" onDark />
            ) : (
              <PrintIcon onClick={print} cursor="pointer" />
            )}
          </PrintIconContainer>
        </Tooltip>

        {!notActive && (
          <Tooltip
            title={formatMessage({ id: `overview.showOnlyThis.${type}` })}
            open={showEyeTooltip}
            onOpen={handleOpenTooltip}
            onClose={handleCloseTooltip}
          >
            {showOnlyThis ? (
              <VisibilityOffIcon
                onClick={handleClickVisibility}
                cursor="pointer"
              />
            ) : (
              <VisibilityIcon
                onClick={handleClickVisibility}
                cursor="pointer"
              />
            )}
          </Tooltip>
        )}
      </>
    );
  }

  return (
    <>
      <MoreHorizIcon ref={anchorRef} onClick={togglePopup} />

      <Popover
        open={isPopupShown}
        anchorEl={anchorRef.current}
        onClose={togglePopup}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <List>
          {!notActive && (
            <StyledListItem button onClick={onRedirectTaxPeriod}>
              <StatusIcon
                statusColor={colors.NOT_STARTED_POPUP}
                icon={<StyledFinancialStatementIcon />}
              />
              <StyledListItemText>
                {formatMessage(
                  { id: 'overview.taxSalary' },
                  { status: taxStatusTranslation }
                )}
              </StyledListItemText>
            </StyledListItem>
          )}

          <StyledListItem button onClick={print}>
            {printLoading ? <LoadingLogo size="tiny" /> : <PrintOutlined />}
            <StyledListItemText>
              {formatMessage({ id: 'overview.printPeriod' })}
            </StyledListItemText>
          </StyledListItem>

          {!notActive && (
            <StyledListItem button onClick={handleClickVisibility}>
              {showOnlyThis ? (
                <VisibilityOffOutlined />
              ) : (
                <VisibilityOutlined />
              )}
              <StyledListItemText>
                {formatMessage({ id: `overview.showOnlyThis.${type}` })}
              </StyledListItemText>
            </StyledListItem>
          )}
        </List>
      </Popover>
    </>
  );
};

export default PeriodHeaderMenu;

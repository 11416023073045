import React, { useMemo } from 'react';

export type RowHoverType = {
  accountId: string;
  groupId: string;
} | null;

/**
 * The context that manages the setting function to hold the id of the account cell
 * that is hovered. This is then used in to style the header column
 * to indicate that the row is hovered.
 */
type RowHoverContextType = {
  setHoverRow: (rowHover: RowHoverType) => void;
};

type RowHoverContextProviderProps =
  React.PropsWithChildren<RowHoverContextType>;

const RowHoverContext = React.createContext<RowHoverContextType>({
  setHoverRow: () => {},
});

export const RowHoverContextProvider = ({
  setHoverRow,
  children,
}: RowHoverContextProviderProps) => {
  const value = useMemo(() => ({ setHoverRow }), [setHoverRow]);

  return (
    <RowHoverContext.Provider value={value}>
      {children}
    </RowHoverContext.Provider>
  );
};

export default RowHoverContext;

import { CompaniesAnnualReports } from '@agoy/annual-report-document';
import { CompanyType } from '_clients/types/types';
import { activeFeatureFlags } from '_shared/HOC/withFeatureFlags';

const economicAssociationSupport = activeFeatureFlags.get(
  'feature_economicAssociationSupport'
);

const limitedCompaniesSupport = activeFeatureFlags.get(
  'feature_limitedCompaniesSupport'
);

const nonProfitOrgSupport = activeFeatureFlags.get(
  'feature_nonProfitOrgCompaniesSupport'
);

const foundationCompanySupport = activeFeatureFlags.get(
  'feature_foundationCompanySupport'
);

/**
 * This makes sure to return the correct company type when the flags are enabled
 * otherwise returns shares (Aktiebolag) as default
 * WARNING: when this is removed, make sure to also update modules/document-service/src/methods/createDefaultAnnualReportChanges/index.ts
 * for the company that is fully supported
 */
const getCompanyType = (
  companyType: CompanyType = 'shares'
): keyof CompaniesAnnualReports => {
  // No more feature flag for this one. Should be fully enabled now
  if (companyType === 'individual') {
    return 'individual';
  }
  if (companyType === 'economic_association' && economicAssociationSupport) {
    return 'economic_association';
  }
  if (companyType.includes('limited') && limitedCompaniesSupport) {
    return 'limited_companies';
  }
  if (companyType === 'non_profit_association' && nonProfitOrgSupport) {
    return 'non_profit_association';
  }
  if (companyType === 'foundation' && foundationCompanySupport) {
    return 'foundation';
  }

  return 'shares';
};

export default getCompanyType;

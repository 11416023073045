import React, { useContext, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { isEqual } from 'lodash';

import { formatPeriodToMonthAndYear } from 'utils';
import { InputData } from '_reconciliation/types';
import PeriodSummary from '_shared/components/PeriodSummary';
import { useSelector } from 'redux/reducers';
import { clientYear } from '_reconciliation/redux/accounting-view/selectors';

import { TransferSpecification } from './TransferSpecification';
import SpecificationTable from './SpecificationTable';
import SummaryRow from './SummaryRow';
import SpecificationPrint from './SpecificationPrintModal';
import PeriodDataContext from '../PeriodDataContext';
import { legacySpecificationSum } from '../../HiddenGroupRow/utils';

const Container = styled.div`
  display: flex;
  width: 100%;
  margin-top: ${(props) => props.theme.spacing(2)}px;
`;

const PeriodSummaryWrapper = styled.div`
  min-width: 230px;
`;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  padding-right: ${({ theme }) => theme.spacing(3)}px;
`;

const StyledSummaryRow = styled(SummaryRow)`
  margin-right: 74px;
  margin-top: ${({ theme }) => theme.spacing(1)}px;
  text-align: right;
`;

interface Props {
  userData: InputData;
  accountNumber: string;
  accountName: string;
}

// Default state is three rows, hence the name EMPTY
const EMPTY = [
  {
    id: 1,
    description: '',
    amount: '0',
    account: '',
    reference: '',
  },
  {
    id: 2,
    description: '',
    amount: '0',
    account: '',
    reference: '',
  },
  {
    id: 3,
    description: '',
    amount: '0',
    account: '',
    reference: '',
  },
];

const LegacySpecificationView = ({
  userData,
  accountNumber,
  accountName,
}: Props): JSX.Element => {
  const {
    ib,
    ub,
    psaldo,
    saldo: userDataSaldo,
    legacySpecifications = EMPTY,
  } = userData || {};
  const saldo = userDataSaldo ? parseInt(userDataSaldo, 10) : 0;
  const { clientId, financialYear, period, periodType } =
    useContext(PeriodDataContext);

  const userInputData = useSelector(
    clientYear(clientId, financialYear, (state) => state.userInput)
  );
  const [currentSpecification, setCurrentSpecification] =
    useState(legacySpecifications);

  const [isPrintModalVisible, setPrintModalVisible] = useState(false);

  const togglePrintModalVisible = () => {
    setPrintModalVisible((currentValue) => !currentValue);
  };

  useEffect(() => {
    if (!userInputData) return;
    const selectedAccount =
      userInputData?.[`account${accountNumber}`]?.[period.id];

    const selectedSpecification =
      selectedAccount?.legacySpecifications?.length > 0
        ? selectedAccount?.legacySpecifications
        : EMPTY;

    setCurrentSpecification(selectedSpecification);
  }, [accountNumber, period, periodType, legacySpecifications, userInputData]);

  useEffect(() => {
    setCurrentSpecification((value) => {
      return isEqual(value, legacySpecifications)
        ? value
        : legacySpecifications;
    });
  }, [legacySpecifications]);

  return (
    <Container>
      <SpecificationPrint
        specification={currentSpecification}
        account={accountNumber}
        accountName={accountName}
        open={isPrintModalVisible}
        userData={userData}
        handleShow={togglePrintModalVisible}
      />
      <PeriodSummaryWrapper>
        <PeriodSummary
          date={formatPeriodToMonthAndYear(period)}
          periodIb={ib}
          periodSaldo={psaldo}
          periodUb={ub}
          saldo={saldo}
        />
      </PeriodSummaryWrapper>
      <TableWrapper>
        <SpecificationTable
          specification={currentSpecification}
          accountNumber={accountNumber}
        />
        <StyledSummaryRow sum={legacySpecificationSum(currentSpecification)} />
      </TableWrapper>
      <TransferSpecification
        specification={currentSpecification}
        accountNumber={accountNumber}
        onClickPrint={togglePrintModalVisible}
        setCurrentSpecification={setCurrentSpecification}
      />
    </Container>
  );
};

export default LegacySpecificationView;

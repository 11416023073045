import React from 'react';
import XML from './XML';

type WithChildren<T = React.ReactNode | React.ReactNodeArray> = {
  children: T;
};

const XBRLI = ({
  tagName,
  children,
  ...props
}: Record<string, string | React.ReactNode>): React.ReactElement => (
  <XML namespace="xbrli" tagName={tagName} {...props}>
    {children}
  </XML>
);

type ContextProps = WithChildren & {
  id: string;
};

export const Context = ({ id, children }: ContextProps): React.ReactElement => (
  <XBRLI tagName="context" id={id}>
    {children}
  </XBRLI>
);

type EntityProps = WithChildren;

export const Entity = ({ children }: EntityProps): React.ReactElement => (
  <XBRLI tagName="entity">{children}</XBRLI>
);

type IdentifierProps = WithChildren & {
  scheme?: string;
};

export const Identifier = ({
  scheme = 'http://www.bolagsverket.se',
  children,
}: IdentifierProps): React.ReactElement => (
  <XBRLI tagName="identifier" scheme={scheme}>
    {children}
  </XBRLI>
);

type PeriodProps = WithChildren;

export const Period = ({ children }: PeriodProps): React.ReactElement => (
  <XBRLI tagName="period">{children}</XBRLI>
);

type StartDateProps = WithChildren<string> & {
  scheme?: string;
};

export const StartDate = ({ children }: StartDateProps): React.ReactElement => (
  <XBRLI tagName="startDate">{children}</XBRLI>
);

type EndDateProps = WithChildren<string> & {
  scheme?: string;
};

export const EndDate = ({ children }: EndDateProps): React.ReactElement => (
  <XBRLI tagName="endDate">{children}</XBRLI>
);

type InstantProps = WithChildren & {
  scheme?: string;
};

export const Instant = ({ children }: InstantProps): React.ReactElement => (
  <XBRLI tagName="instant">{children}</XBRLI>
);

type UnitProps = WithChildren & {
  id: string;
  scheme?: string;
};

export const Unit = ({ id, children }: UnitProps): React.ReactElement => (
  <XBRLI tagName="unit" id={id}>
    {children}
  </XBRLI>
);

type MeasureProps = WithChildren<string> & {
  scheme?: string;
};

export const Measure = ({ children }: MeasureProps): React.ReactElement => (
  <XBRLI tagName="measure">{children}</XBRLI>
);

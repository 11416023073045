import React from 'react';
import { Box } from '@material-ui/core';
import { NoteOneTableSection } from '@agoy/annual-report-document';
import {
  AgoyTable,
  Cell,
  Field,
  numberValue,
  stringValue,
} from '@agoy/document';
import Comment from '../../UI/Comment';
import Header from '../../UI/Header';
import { Note } from '../../UI/Note';
import NoteTable from '../../UI/NoteTable';

type Note98Props = {
  noteId: string;
  rowsInGroupsOf?: number;
  print?: boolean;
  isDigitalSubmission?: boolean;
  title: string;
  sumTable: AgoyTable;
  secondTable: AgoyTable;
  secondSumTable: AgoyTable;
  commentLabel: Field;
  accountRanges?: any;
} & NoteOneTableSection;

const Note98 = ({
  active,
  noteId,
  number,
  table,
  sumTable,
  secondSumTable,
  rowsInGroupsOf,
  title,
  secondTable,
  commentLabel,
  comment,
}: Note98Props) => {
  if (!active) return null;

  return (
    <Note id={noteId}>
      <Header noteId={noteId} noteNumber={numberValue(number)} title={title} />

      {/* First Table */}
      <Box mt={1} mb={1}>
        <NoteTable
          table={table}
          tableId={`notes.${noteId}.table`}
          groupRowsInterval={rowsInGroupsOf}
        />
      </Box>

      <Box mt={1} mb={1}>
        <NoteTable
          table={sumTable}
          tableId={`notes.${noteId}.sumTable`}
          groupRowsInterval={rowsInGroupsOf}
        />
      </Box>

      {/* Second Table */}
      <Box mt={1} mb={1}>
        <NoteTable
          table={secondTable}
          tableId={`notes.${noteId}.secondTable`}
          groupRowsInterval={rowsInGroupsOf}
        />
      </Box>

      <Box mt={1} mb={1}>
        <NoteTable
          table={secondSumTable}
          tableId={`notes.${noteId}.secondSumTable`}
          groupRowsInterval={rowsInGroupsOf}
        />
      </Box>

      {/* Comment */}
      <Comment
        field={comment}
        value={stringValue(comment) || ''}
        labelValue={stringValue(commentLabel) || ''}
      />
    </Note>
  );
};

export default Note98;
